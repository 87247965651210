import React from 'react';
import {connect} from "react-redux";

/* Components */
import { NavPrimaryWrapper, NavPrimaryMobileWrapper } from '../../consumer2/navbars/navprimary';
import { NavQuaternaryWrapper } from '../../consumer2/navbars/navquaternary';
import {PageFull, PageContent, PageContentContainer, Background} from '../../../consumer/navbars/components/navlayout';

/* Static */
import {Message} from "../../../common/components/messages";

import AllSharedVault from '../../../common/containers/vault/containers/allsharedvault';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const Vaults = ({referralType}) => (
    <>
        <NavPrimaryWrapper data={env.CONSUMER.NAV} />
        <PageFull>
            <div>
                <NavQuaternaryWrapper data={env.CONSUMER.NAV} />
                <PageContent>
                    <PageContentContainer>
                        {/*<Message text={"Talk to your team about shared vaults!"} />*/}
                        <AllSharedVault consumer={true}/>
                    </PageContentContainer>
                </PageContent>
                <Background />
            </div>
            <NavPrimaryMobileWrapper data={env.CONSUMER.NAV} />
            {/*<NavActionWrapper />*/}
        </PageFull>
    </>
);

const mapStateToProps = state => ({
    referralType: state.common.user.get("referralType")
});
export default connect(mapStateToProps, null)(Vaults);