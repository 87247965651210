import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/* Components */
import { FontSubtitle24, FontHeader16, FontTitle24 } from "../../../../common/components/fonts";
import { images } from "../../../../common/components/images";
import { LogoLoading } from "../../../../common/components/loading";
import FirmMarketingProspectRecord from '../components/prospectrecord';

/* Middleware */
import { tryGetProspects, tryGetLeadConversation, tryUpdateStatus } from '../middleware/prospects';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
`;
const ColProspectParent = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
const DropdownRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
`;

const Dropdown = styled.div`
  padding: 11px 30px 9px 30px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  cursor: pointer;
`;

const Arrow = styled.div`
  background-image: url(${images.chevronDown});
  background-size: cover;
  background-repeat: no-repeat;
  width: 15px;
  height: 9px;
`;

const DropdownList = styled.div`
  padding: 20px 30px 15px 30px;
  box-shadow: 0 3px 10px 0 rgb(0, 0, 0, 25%);
  border-radius: 20px;
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  background-color: white;
`;
const ListButton = styled(FontHeader16)`
    padding: 7px 0;
    cursor: pointer;
`;
const DropdownTitle = styled(FontHeader16)`
    width: 80px;
`;
const Relative = styled.div`
    position: relative;
`;
const Background = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  z-index: 1;
`;
const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

class FirmMarketingProspects extends React.Component {
    state = {
        "filter": "Pending", // Pending, Won, Lost
        "filterOpen": false,
        "prospects": [],
        "filtered": [],
        "isLoading": true,
        "showPopup": false,
        "conversation": [],
        "pending": ["Lead information sent to advisor", "Follow up sent", "Follow up with advisor completed"]
    }

    componentDidMount() {
        this.init();
    }

    init = async () => {
        const prospects = await this.props.tryGetProspects();
        this.setState({
            "prospects": prospects?.leads ? prospects.leads : [],
            "filtered": prospects?.leads && prospects?.leads.length > 0 ? prospects.leads.filter(p => this.state.pending.includes(p.status)) : [],
            "isLoading": false
        })
    }

    setShowPopup = (showPopup) => {
        this.setState({ showPopup });
    }

    setConversation = (conversation) => {
        this.setState({ conversation });
    }

    toggleFilterChange = (value) => {
        this.setState({
            "filter": value,
            "filtered": this.state.prospects && this.state.prospects?.length > 0 ?
                value === "Won" ? this.state.prospects.filter(p => (p.status === "Matched")) :
                    value === "Lost" ? this.state.prospects.filter(p => (p.status === "Advisor did not win lead")) :
                        this.state.prospects.filter(p => this.state.pending.includes(p.status)) : [],
            "filterOpen": false
        })
    }

    toggleFilterOpen = () => {
        this.setState({ "filterOpen": true })
    }

    toggleFilterClose = () => {
        this.setState({ "filterOpen": false })
    }

    selectStatus = async (leadId, leadStatus) => {
        this.setState({ "isLoading": true });
        await this.props.tryUpdateStatus(leadId, leadStatus);
        this.setState({ "isLoading": false });
        this.init();
    }

    getConvo = async (userID) => {
        const APIConversation = await this.props.tryGetLeadConversation(userID);
        this.setConversation(APIConversation);
        this.init();
    }

    render() {
        if (this.state.isLoading) {
            return (<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return (
                <>
                    {this.state.prospects && this.state.prospects?.length > 0 ?
                        <>
                            <Row>
                                <FontSubtitle24 spaced={true}>{this.state.filter.toUpperCase()} PROSPECTS ({this.state.filtered.length})</FontSubtitle24>
                                <Relative>
                                    <Dropdown onClick={this.toggleFilterOpen}>
                                        <DropdownRow>
                                            <DropdownTitle>{this.state.filter}</DropdownTitle>
                                            <Arrow />
                                        </DropdownRow>
                                    </Dropdown>
                                    {this.state.filterOpen &&
                                        <>
                                            <DropdownList>
                                                <ListButton onClick={() => this.toggleFilterChange("Pending")}>Pending</ListButton>
                                                <ListButton onClick={() => this.toggleFilterChange("Won")}>Won</ListButton>
                                                <ListButton onClick={() => this.toggleFilterChange("Lost")}>Lost</ListButton>
                                            </DropdownList>
                                            <Background onClick={this.toggleFilterClose} />
                                        </>
                                    }
                                </Relative>
                            </Row>
                            <ColProspectParent>
                                {this.state.filtered.map((p, pIndex) => {
                                    return (
                                        <FirmMarketingProspectRecord
                                            key={pIndex}
                                            prospect={p}
                                            selectStatus={this.selectStatus}
                                            index={pIndex}
                                            getConvo={this.getConvo}
                                            showPopup={this.state.showPopup}
                                            setShowPopup={this.setShowPopup}
                                            conversation={this.state.conversation}
                                        />
                                    )
                                })}
                            </ColProspectParent>
                        </>
                        : <FontTitle24>No prospects found</FontTitle24>
                    }
                </>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    // tryGetAdvisorProspects: () => dispatch(tryGetAdvisorProspects()),
    // tryPostAdvisorProspectsAccept: () => dispatch(tryPostAdvisorProspectsAccept()),
    tryGetProspects: () => dispatch(tryGetProspects()),
    tryGetLeadConversation: (leadId) => dispatch(tryGetLeadConversation(leadId)),
    tryUpdateStatus: (leadId, leadStatus) => dispatch(tryUpdateStatus(leadId, leadStatus))
})

export default connect(null, mapDispatchToProps)(withRouter(FirmMarketingProspects));