import React from 'react';
import styled from 'styled-components';

/* Components */
import SchedulerFlow from "../../scheduler/containers/flow";

const Container = styled.div`
    max-width: 768px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 60px auto 0px auto;
`;

class Availability extends React.Component {
    render() {
        return (
            <Container>
                <SchedulerFlow
                    userId={this.props.userId}
                    userFirstName={this.props.userFirstName}
                    userLastName={this.props.userLastName}
                    userPhotoUrl={this.props.userPhotoUrl}
                    eventTitle={this.props.eventTitle}
                    eventDescription={this.props.eventDescription}
                    eventCustomMinutes={this.props.eventCustomMinutes}
                    confirmCloseBtnLabel={this.props.confirmCloseBtnLabel}
                    confirmOnClose={this.props.confirmOnClose}
                    purchaseHistoryId={this.props.purchaseHistoryId}
                />
            </Container>
        )
    }
}

export default Availability;
