import React, { Component } from 'react';
import styled from 'styled-components';
import { select, selectAll, text } from 'd3-selection';
import { hierarchy, partition } from 'd3-hierarchy';
import { arc } from 'd3-shape';
import { transition } from 'd3-transition';

import { colors } from './colors';
import { fontFamily } from './fonts';

const SunburstContainer = styled.div`
	position: sticky;
	top: 20px;
	width: 100%;
	font-family: ${fontFamily.body};
	font-size: 12px;
	font-weight: normal;
`;
const SunburstMain = styled.div`
	text {
		font-family: ${fontFamily.header};
		fill: ${colors.white};
	}
`;
const SunburstSequence = styled.div`
	width: 100%;
	height: 50px;
`;
const SunburstChart = styled.div`
	position: relative;
	.chart {
		display: block;
		margin: 0 auto;
	}
	.explanation {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 140px;
		height: 60px;
		margin-left: -70px;
		margin-top: -30px;
		text-align: center;
		line-height: 1.4em;
		color: ${colors.secondary100};
		z-index: -1;
	}
	.percentage {
		font-size: 2.5em;
	}
	path {
		stroke-width: 2px;
		stroke: ${colors.background100};
	}
`;

const width = Math.min(window.innerWidth - 50, 280);
const height = window.innerWidth < 500 ? 350 : 350;
const radius = Math.min(width, height) / 2;
const BREADCRUMB = {
    w: 150, h: 30, s: 3, t: 10
};

class Sunburst extends Component {
    static defaultProps = {
        explanationText: 'of your spending is in ',
    }

    constructor(props) {
        super(props);
        this.explanationHtml = `
			<div class="explanation" style="visibility: hidden">
				<span class="percentage"></span><br />
				<span class="explanationText">${props.explanationText}</span>
			</div>`;
    }

    componentDidMount() {
        this.renderChart.bind(this, this.props.data)();
    }
    componentDidUpdate() {
        this.renderChart.bind(this, this.props.data)();
    }

    renderChart(data) {
        this.totalSize = 0;
        this.children = [];
        this.path = [];
        this.nodes = [];

        this.vis = select('.chartWrapper').html(this.explanationHtml).append('svg:svg')
            .attr('width', width)
            .attr('height', height)
            .attr('class', 'chart')
            .append('svg:g')
            .attr('class', 'container')
            .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

        this.partition = partition()
            .size([2 * Math.PI, radius * radius]);

        this.arc = arc()
            .startAngle(function(d) { return d.x0; })
            .endAngle(function(d) { return d.x1; })
            .innerRadius(function(d) { return Math.sqrt(d.y0); })
            .outerRadius(function(d) { return Math.sqrt(d.y1); });

        this.createVisualization.bind(this, data)();
    }

    createVisualization(json) {
        this.initializeBreadcrumbTrail();
        if(this.props.showLegend) {
            this.drawLegend();
        }

        // Bounding circle underneath the sunburst, to make it easier to detect
        // when the mouse leaves the parent g.
        this.vis.append('svg:circle')
            .attr('r', radius)
            .style('opacity', 0);

        // Turn the data into a d3 hierarchy and calculate the sums.
        const root = hierarchy(json)
            .sum(function(d) { return d.size; })
            .sort(function(a, b) { return BREADCRUMB.value - a.value; });

        // For efficiency, filter nodes to keep only those large enough to see.
        const nodes = this.partition(root).descendants()
            .filter(function(d) {
                return (d.x1 - d.x0 > 0.005); // 0.005 radians = 0.29 degrees
            });


        const path = this.vis.data([json]).selectAll('path')
            .data(nodes)
            .enter().append('svg:path')
            .attr('display', function(d) { return d.depth ? null : 'none'; })
            .attr('d', this.arc)
            .attr('fill-rule', 'evenodd')
            .attr('size', function(d) { return (d.data.size !== undefined ? d.data.size : d.value) })
            .attr('name', function(d) { return d.data.name || 'this untitled category' })
            .style('fill', function(d) { return d.data.color || '#c1c1c3'; })
            .style('opacity', 1)
            .on('mouseover', this.handleMouseover.bind(this));

        // Add the mouseleave handler to the bounding circle.
        select('.sunburst-container').on('mouseleave', this.handleMouseleave.bind(this));

        // Get total size of the tree = value of root node from partition.
        this.totalSize = path.datum().value;
        this.children = path.datum().data.children;
        this.path = path;
        this.nodes = nodes;

    };

    // Fade all but the current sequence, and show it in the breadcrumb trail.
    handleMouseover(d) {
        if(this.deactivateMouseover) { return; }
        const percentage = (100 * parseInt(d.path[0].getAttribute('size'), 10) / this.totalSize).toFixed(1);
        let percentageString = percentage + '%';
        if (percentage < 0.1) {
            percentageString = '< 0.1%';
        }

        select('.percentage')
            .text(percentageString);

        let categoryName = d.path[0].getAttribute('name');

        select('.explanationText')
            .text(this.props.explanationText + categoryName);

        select('.explanation')
            .style('visibility', '');

        // // const sequenceArray = d.ancestors().reverse();
        // // sequenceArray.shift(); // remove root node from the array
        // // this.updateBreadcrumbs(sequenceArray, percentageString);

        // const sequenceArray = this.nodes.reverse();
        // sequenceArray.shift(); // remove root node from the array
        // this.updateBreadcrumbs(sequenceArray, percentageString);

        // // Fade all the segments.
        // this.vis.selectAll('path')
        //     .style('opacity', 0.3);

        // // Then highlight only those that are an ancestor of the current segment.
        // this.vis.selectAll('path')
        //     .filter(function(node) {
        //         return (sequenceArray.indexOf(node) >= 0);
        //     })
        //     .style('opacity', 1);
    }

    // Restore everything to full opacity when moving off the visualization.
    handleMouseleave(d) {

        // Hide the breadcrumb trail
        select('.trail')
            .style('visibility', 'hidden');

        // Deactivate all segments during transition.
        this.deactivateMouseover = true;

        // Transition each segment to full opacity and then reactivate it.
        this.vis.selectAll('path')
            .transition()
            .duration(400)
            .style('opacity', 1)
            .on('end', () => {
                this.deactivateMouseover = false;
            });

        select('.explanation')
            .style('visibility', 'hidden');
    }

    initializeBreadcrumbTrail() {
        // Add the svg area.
        const trail = select('.sequence').html('').append('svg:svg')
            .attr('width', width)
            .attr('height', 50)
            .attr('class', 'trail');
        // Add the label at the end, for the percentage.
        trail.append('svg:text')
            .attr('class', 'endlabel')
            .style('fill', '#000');
    }

    // Generate a string that describes the points of a breadcrumb polygon.
    breadcrumbPoints(d, i) {
        const points = [];
        points.push('0,0');
        points.push(BREADCRUMB.w + ',0');
        points.push(BREADCRUMB.w + BREADCRUMB.t + ',' + (BREADCRUMB.h / 2));
        points.push(BREADCRUMB.w + ',' + BREADCRUMB.h);
        points.push('0,' + BREADCRUMB.h);
        if (i > 0) { // Leftmost breadcrumb; don't include 6th vertex.
            points.push(BREADCRUMB.t + ',' + (BREADCRUMB.h / 2));
        }
        return points.join(' ');
    }

    // Update the breadcrumb trail to show the current sequence and percentage.
    updateBreadcrumbs(nodeArray, percentageString) {

        // Data join; key function combines name and depth (= position in sequence).
        const trail = select('.trail')
            .selectAll('g')
            .data(nodeArray, function(d) { return d.data.name + d.depth; });

        // Remove exiting nodes.
        trail.exit().remove();

        // Add breadcrumb and label for entering nodes.
        const entering = trail.enter().append('svg:g');

        entering.append('svg:polygon')
            .attr('points', this.breadcrumbPoints)
            .style('fill', function(d) { return d.data.color || '#c1c1c3'; });

        entering.append('svg:text')
            .attr('x', (BREADCRUMB.w + BREADCRUMB.t) / 2)
            .attr('y', BREADCRUMB.h / 2)
            .attr('dy', '0.35em')
            .attr('text-anchor', 'middle')
            .text(function(d) { return d.data.name; });

        // Merge enter and update selections; set position for all nodes.
        entering.merge(trail).attr('transform', function(d, i) {
            return 'translate(' + i * (BREADCRUMB.w + BREADCRUMB.s) + ', 0)';
        });

        // Now move and update the percentage at the end.
        select('.trail').select('.endlabel')
            .attr('x', (nodeArray.length + 0.5) * (BREADCRUMB.w + BREADCRUMB.s))
            .attr('y', BREADCRUMB.h / 2)
            .attr('dy', '0.35em')
            .attr('text-anchor', 'middle')
            .text(percentageString);

        // Make the breadcrumb trail visible, if it's hidden.
        select('.trail')
            .style('visibility', '');

    }

    render() {
        return (
            <SunburstContainer className="sunburst-container">
                <SunburstMain className="main">
                    <SunburstSequence className="sequence" dangerouslySetInnerHTML={{ __html: '' }} />
                    <SunburstChart className="chartWrapper" dangerouslySetInnerHTML={{ __html: this.explanationHtml }} />
                </SunburstMain>
            </SunburstContainer>
        );
    }
}

export default Sunburst;