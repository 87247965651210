import {fromJS, Map} from 'immutable';

/* Immutable */

const RESCHEDULE_SESSION_CREATING = 'RESCHEDULE_SESSION_CREATING';
const RESCHEDULE_SESSION_CREATING_ERROR = 'RESCHEDULE_SESSION_CREATING_ERROR';
const RESCHEDULE_SESSION_DELETING = 'RESCHEDULE_SESSION_DELETING';
const RESCHEDULE_SESSION_DELETING_ERROR = 'RESCHEDULE_SESSION_DELETING_ERROR';
const RESCHEDULE_SESSION = 'RESCHEDULE_SESSION';
const RESCHEDULE_SESSION_FETCHING = 'RESCHEDULE_SESSION_FETCHING';
const SCHEDULE_STORE_RESET = 'SCHEDULE_STORE_RESET';

/* Actions */

export const setRescheduleSessionCreating = (status) => ({"type": RESCHEDULE_SESSION_CREATING, 'status': status});
export const setRescheduleSessionCreatingError = (error) => ({"type": RESCHEDULE_SESSION_CREATING_ERROR, 'error': error});
export const setRescheduleSessionDeleting = (status) => ({"type": RESCHEDULE_SESSION_DELETING, 'status': status});
export const setRescheduleSessionDeletingError = (error) => ({"type": RESCHEDULE_SESSION_DELETING_ERROR, 'error': error});
export const setRescheduleSession = (session) => ({"type": RESCHEDULE_SESSION, 'session': session});
export const setRescheduleSessionFetching = (status) => ({"type": RESCHEDULE_SESSION_FETCHING, 'status': status});
export const setRescheduleStoreReset = () => ({"type": SCHEDULE_STORE_RESET});

/* Initial State */

const initialState = Map({
    rescheduleSessionCreating: false,
    rescheduleSessionCreatingError: null,
    rescheduleSessionDeleting: false,
    rescheduleSessionDeletingError: null,
    rescheduleSession: null,
    rescheduleSessionFetching: null,
});

/* Reducer */

const rescheduleReducer = (state=initialState, action) => {
    switch (action.type) {
        case RESCHEDULE_SESSION_CREATING:
            return state.merge({'rescheduleSessionCreating': action.status});
        case RESCHEDULE_SESSION_CREATING_ERROR:
            return state.merge({'rescheduleSessionCreatingError': action.error});
        case RESCHEDULE_SESSION_DELETING:
            return state.merge({'rescheduleSessionDeleting': action.status});
        case RESCHEDULE_SESSION_DELETING_ERROR:
            return state.merge({'rescheduleSessionDeletingError': action.error});
        case RESCHEDULE_SESSION:
            return state.merge({'rescheduleSession': action.session === null ? null : fromJS(action.session)});
        case RESCHEDULE_SESSION_FETCHING:
            return state.merge({'rescheduleSessionFetching': action.status});
        case SCHEDULE_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default rescheduleReducer;
