import { Map, List, fromJS } from 'immutable';

/* Immutable */

const CONTACTS_EDIT_SELECTED = 'CONTACTS_EDIT_SELECTED';
const CONTACTS_CREATING = 'CONTACTS_CREATING';
const CONTACTS_UPDATING = 'CONTACTS_UPDATING';
const CONTACTS_ARCHIVING = 'CONTACTS_ARCHIVING';
const CONTACTS_ALL = 'CONTACTS_ALL';
const CONTACTS_ALL_FETCHING = 'CONTACTS_ALL_FETCHING';
const CONTACTS_ERROR = 'CONTACTS_ERROR';
const CONTACTS_GUESTS_FETCHING = 'CONTACTS_GUESTS_FETCHING';
const CONTACTS_ALL_FETCHED = 'CONTACTS_ALL_FETCHED';
const CONTACTS_ONE_FETCHING = 'CONTACTS_ONE_FETCHING';
const CONTACTS_ONE = 'CONTACTS_ONE';
const CONTACTS_ALL_EDIT_EXISTING_CONTACT = 'CONTACTS_ALL_EDIT_EXISTING_CONTACT';
const CONTACTS_ALL_ARCHIVE_EXISTING_CONTACT = 'CONTACTS_ALL_ARCHIVE_EXISTING_CONTACT';
const CONTACTS_ALL_ADD_NEW_CONTACT = 'CONTACTS_ALL_ADD_NEW_CONTACT';
const CONTACTS_STORE_RESET = 'CONTACTS_STORE_RESET';

/* Actions */

export const setContactsEditSelected = (contact) => ({'type': CONTACTS_EDIT_SELECTED, 'contact': contact});
export const setContactsCreating = (status) => ({'type': CONTACTS_CREATING, 'status': status});
export const setContactsUpdating = (status) => ({'type': CONTACTS_UPDATING, 'status': status});
export const setContactsArchiving = (status) => ({'type': CONTACTS_ARCHIVING, 'status': status});
export const setAllContacts = (contacts, isWMC) => ({'type': CONTACTS_ALL, 'contacts': contacts, 'isWMC': isWMC});
export const setAllContactsFetching = (status) => ({'type': CONTACTS_ALL_FETCHING, 'status': status});
export const setAllContactsFetched = () => ({'type': CONTACTS_ALL_FETCHED});
export const setContactsError = (error) => ({'type': CONTACTS_ERROR, 'error': error});
export const setContactsGuestsDownloading = (status) => ({'type': CONTACTS_GUESTS_FETCHING, 'status': status});
export const setContactsOneFetching = (status) => ({'type': CONTACTS_ONE_FETCHING, 'status': status});
export const setContactsOne = (contact) => ({'type': CONTACTS_ONE, 'contact': contact});
export const setAllContactsArchiveExistingContact = (contactId, isArchived) => ({'type': CONTACTS_ALL_ARCHIVE_EXISTING_CONTACT, 'contactId': contactId, 'isArchived': isArchived});
export const setAllContactsEditExistingContact = (contactId, edit, formatContact) => ({'type': CONTACTS_ALL_EDIT_EXISTING_CONTACT, 'contactId': contactId, 'edit': edit, 'formatContact': formatContact});
export const setAllContactsAddNewContact = (newContact) => ({'type': CONTACTS_ALL_ADD_NEW_CONTACT, 'newContact': newContact});
export const setContactsStoreReset = () => ({'type': CONTACTS_STORE_RESET});

/* Initial State */

const initialState = Map({
    contactsEditSelected: Map(),
    contactsDeleting: false,
    contactsCreating: false,
    contactsUpdating: false,
    contactsError: null,
    contactsInvitingToConference: false,
    contactsGuestsFetching: false,
    contactsAll: List(),
    contactsAllFetching: false,
    contactsAllFetched: false,
    contactsOne: Map(),
    contactsOneFetching: false,
    contactsOneFetched: null
});

/* Reducer */

const contactsReducer = (state=initialState, action) => {
    switch (action.type) {
        case CONTACTS_EDIT_SELECTED:
            return state.merge({'contactsEditSelected': fromJS(action.contact)});
        case CONTACTS_CREATING:
            return state.merge({'contactsCreating': action.status});
        case CONTACTS_UPDATING:
            return state.merge({'contactsUpdating': action.status});
        case CONTACTS_ARCHIVING:
            return state.merge({'contactsDeleting': action.status});
        case CONTACTS_ALL:
            return state.merge({
                'contactsAll': fromJS(action.contacts.map(c => {
                    return(fromJS({
                        ...c,
                        "lastName": (!action.isWMC && c.referralSource === "willow") ? c.lastName.charAt(0)+"." : c.lastName
                    }))
                }))
            });
        case CONTACTS_ALL_FETCHED:
            return state.merge({'contactsAllFetched': true});
        case CONTACTS_ALL_ADD_NEW_CONTACT:
            return state.merge({
                'contactsAll': fromJS(state.get("contactsAll").push(fromJS(action.newContact)))
            });
        case CONTACTS_ALL_EDIT_EXISTING_CONTACT:
            return state.merge({
                'contactsAll': fromJS(state.get("contactsAll").map(c => {
                    if(c.get("contactId") === action.contactId) {
                        return(fromJS(action.formatContact({...c.toJS(), ...action.edit})))
                    } else {
                        return(fromJS(c))
                    }
                })),
                'contactsOne': fromJS({...state.get("contactsEditSelected").toJS(), ...action.edit})
            });
        case CONTACTS_ALL_ARCHIVE_EXISTING_CONTACT:
            return state.merge({
                'contactsAll': fromJS(state.get("contactsAll").map(c => {
                    if(c.get("contactId") === action.contactId) {
                        return(fromJS({...c.toJS(), "isArchived": !action.isArchived}))
                    } else {
                        return(fromJS(c))
                    }
                }))
            });
        case CONTACTS_ALL_FETCHING:
            return state.merge({'contactsAllFetching': action.status});
        case CONTACTS_ERROR:
            return state.merge({'contactsError': action.error});
        case CONTACTS_GUESTS_FETCHING:
            return state.merge({'contactsGuestsFetching': action.status});
        case CONTACTS_ONE:
            return state.merge({'contactsOne': fromJS(action.contact)});
        case CONTACTS_ONE_FETCHING:
            return state.merge({'contactsOneFetching': action.status});
        case CONTACTS_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default contactsReducer;
