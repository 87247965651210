import React from 'react';
import styled from "styled-components";

/* Components */
import { colors } from '../../../common/components/colors';

/* Utility */
import { hexToRGB, brightness } from '../../../common/utils/colors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    border-radius: 25px 0 25px 0;
    background-color: ${props => props.backgroundColor};
    color: ${props => brightness(hexToRGB(props.backgroundColor, false)) < 130 ? colors.white : colors.primary100};
    cursor: pointer;
`;
Container.defaultProps = {
    backgroundColor: colors.backgroundColor4
}
export const ContainerTop = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 20px 20px 0 20px;
`;
export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
export const BookButton = styled.div`
    border-radius: 25px 0 25px 0;
    color: ${colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.action100};
    cursor: pointer;
    padding: 20px 0;
    box-shadow: 0px 3px 10px 0px #00000040;
`;

export const BookButtonDesktop = styled.div`
    border-radius: 25px;
    color: ${colors.white};
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: ${colors.action100};
    cursor: pointer;
    padding: 10px;
    box-shadow: 2px 5px 8px 0px rgba(57, 73, 80, 0.2);
    width: 60%;
    margin: auto;
    margin-top: 20px;
`

export const Button = styled.div`
    border-radius: 25px;
    background-color: ${props => props.primary ? colors.action100 : colors.backgroundColor3};
    color: ${colors.white};
    cursor: pointer;
    padding: 10px;
    text-align: center;
    width: 150px;
`;
export const Background = styled.div`
    background-color: rgba(0,0,0,0.6);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 12;
`;
export const VideoContainer = styled.div`
    width: 80%;
    z-index: 13;
    height: auto;
    background-color: ${colors.white};
    border-radius: 15px;
    padding: 10px 20px 20px 20px;
`;
export const VideoPlayer = styled.video`
    z-index: 13;
    width: 100%;
    height: auto;
    @media screen and (max-width: 460px) {
        width: 100%;
    }
`;
export const Flex = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 11;
`;

export const BackSection = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 15px;
    padding: 15px 0px 0px 0px;
    width: calc(100% - 25px);
    @media screen and (max-width: 460px) {
        padding: 15px 0px 0px 15px;
        width: calc(100% - 15px);
        margin-bottom: 15px;
    }
`;

export const Back = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
`;

export const DashboardContainer = styled.div`
    border-radius: 28px;
    background-color: ${colors.white};
    box-shadow: 4px 4px 10px 4px rgba(57, 73, 80, 0.06);
    padding: 25px;
    justify-content: center;
`