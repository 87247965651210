import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

/* Pages */
import ProgramsFlow from '../../../iframes/programs/containers/flow';

/* Utils */
import {trackViewProgramCatalogue} from "../../../common/utils/tracking";

class ProgramsCatalogue extends React.Component {

    componentDidMount() {
        this.init();
    }

    init = async () => {
        this.props.trackViewProgramCatalogue()
    }

    render() {
        return(
            <ProgramsFlow
                entryPoint={"consumer"}
                programId={this.props.selectedProgramId}
                urlList={"/programs"}
                urlRequest={"/programs/request"}
                urlDetails={"/programs/details"}
                urlCheckout={"/programs/checkout"}
                urlRegistrationComplete={"/programs/registration-completed"}
                urlRegistrationCompleteClose={"/home"}
                onSuccessClose={() => {this.props.history.push('/home')}}
            />
        )
    }
}

const mapStateToProps = state => ({
    selectedProgramId: state.consumer.programs.groupPrograms.get('selectedProgramId')
});

const mapDispatchToProps = dispatch => ({
    trackViewProgramCatalogue: () => dispatch(trackViewProgramCatalogue())
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProgramsCatalogue));


