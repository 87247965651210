import {fromJS, Map, List, merge} from 'immutable';

/* Immutable */
const COMMUNITY_SET_FORUM_SELECTED = 'COMMUNITY_SET_FORUM_SELECTED';
const COMMUNITY_SET_SUBCATEGORY_SELECTED = 'COMMUNITY_SET_SUBCATEGORY_SELECTED';
const COMMUNITY_SET_POSTS = 'COMMUNITY_SET_POSTS';
const COMMUNITY_SET_POSTS_FETCHING = 'COMMUNITY_SET_POSTS_FETCHING';
const COMMUNITY_PARENT_CATEGORIES_FETCHING = 'COMMUNITY_PARENT_CATEGORIES_FETCHING';
const COMMUNITY_PARENT_CATEGORIES = 'COMMUNITY_PARENT_CATEGORIES';
const COMMUNITY_SUB_CATEGORIES_FETCHING = 'COMMUNITY_SUB_CATEGORIES_FETCHING';
const COMMUNITY_SUB_CATEGORIES = 'COMMUNITY_SUB_CATEGORIES';
const COMMUNITY_SET_COMMENTS = 'COMMUNITY_SET_COMMENTS';
const COMMUNITY_SET_NEW_COMMENT = 'COMMUNITY_SET_NEW_COMMENT';
const COMMUNITY_SET_COMMENTS_FETCHING = 'COMMUNITY_SET_COMMENTS_FETCHING';
const COMMUNITY_SET_POST_SELECTED = 'COMMUNITY_SET_POST_SELECTED';
const COMMUNITY_FORUM_STORE_RESET = 'COMMUNITY_FORUM_STORE_RESET';
const COMMUNITY_SET_SEARCH_TERM = 'COMMUNITY_SET_SEARCH_TERM';
const COMMUNITY_SEARCH_RESULTS = 'COMMUNITY_SEARCH_RESULTS';
/* Actions */
export const setCommunityForumSelected = (category) => ({'type': COMMUNITY_SET_FORUM_SELECTED, 'category': category});
export const setSubcategorySelected = (category) =>  ({'type': COMMUNITY_SET_SUBCATEGORY_SELECTED, 'category': category});
export const setCommunityPosts = (posts) => ({'type': COMMUNITY_SET_POSTS, 'posts': posts});
export const setCommunityPostsFetching = (status) => ({'type': COMMUNITY_SET_POSTS_FETCHING, 'status': status});
export const setCommunityForumParentCategoriesFetching = (status) => ({'type': COMMUNITY_PARENT_CATEGORIES_FETCHING, 'status': status});
export const setCommunityForumParentCategories = (categories) => ({'type': COMMUNITY_PARENT_CATEGORIES, 'categories': categories});
export const setCommunityForumSubCategoriesFetching = (status) => ({'type': COMMUNITY_SUB_CATEGORIES_FETCHING, 'status': status});
export const setCommunityForumSubCategories = (categories) => ({'type': COMMUNITY_SUB_CATEGORIES, 'categories': categories});
export const setCommunityPostComments = (comments) => ({'type': COMMUNITY_SET_COMMENTS, 'comments': comments});
export const setCommunityPostCommentsFetching = (status) => ({'type': COMMUNITY_SET_COMMENTS_FETCHING, 'status': status});
export const setCommunityPostSelected = (post) => ({'type': COMMUNITY_SET_POST_SELECTED, 'post': post});
export const setCommunityStoreReset = () => ({'type': COMMUNITY_FORUM_STORE_RESET});
export const setCommunityPostNewComment = (comment) => ({'type': COMMUNITY_SET_NEW_COMMENT, 'comment': comment})

export const setForumSearchTerm = (term) => ({'type': COMMUNITY_SET_SEARCH_TERM, 'term': term});
export const setForumSearchResults = (results) => ({'type': COMMUNITY_SEARCH_RESULTS, 'results': results})

/* Initial State */
const initialState = Map({
    forumOptionSelected: Map(),
    posts: List(),
    postsFetching: false,
    parentCategoriesFetching: false,
    parentCategories: List(),
    subCategoriesFetching: false,
    subCategories: List(),
    comments: List(),
    commentsFetching: false,
    postSelected: Map(),
    searchTerm: null,
    forumSearchResults: null
});

/* Reducer */
const forumReducer = (state=initialState, action) => {
    switch (action.type) {
        case COMMUNITY_SET_FORUM_SELECTED:
            return state.merge({'forumOptionSelected': fromJS(action.category)});
        case COMMUNITY_SET_SUBCATEGORY_SELECTED:
            return state.merge({'categorySelected': fromJS(action.category)});
        case COMMUNITY_SET_POSTS:
            return state.merge({'posts': fromJS(action.posts)});
        case COMMUNITY_SET_POSTS_FETCHING:
            return state.merge({'postsFetching': action.status});
        case COMMUNITY_PARENT_CATEGORIES_FETCHING:
            return state.merge({'parentCategoriesFetching': action.status});
        case COMMUNITY_PARENT_CATEGORIES:
            return state.merge({'parentCategories': fromJS(action.categories)});
        case COMMUNITY_SUB_CATEGORIES_FETCHING:
            return state.merge({'subCategoriesFetching': action.status});
        case COMMUNITY_SUB_CATEGORIES:
            return state.merge({'subCategories': fromJS(action.categories)});
        case COMMUNITY_SET_COMMENTS:
            return state.merge({'comments': fromJS(action.comments)})
        case COMMUNITY_SET_COMMENTS_FETCHING:
            return state.merge({'commentsFetching': fromJS(action.status)})
        case COMMUNITY_SET_NEW_COMMENT:{
            let newComment = fromJS(action.comment)
            let mergedComments = state.get('comments').push(newComment)
            return state.merge({'comments': mergedComments})
        }
        case COMMUNITY_SET_POST_SELECTED:
            return state.merge({'postSelected': fromJS(action.post)});
        case COMMUNITY_FORUM_STORE_RESET:
            return state.merge(initialState);
        case COMMUNITY_SET_SEARCH_TERM:
            return state.merge({'searchTerm': action.term})
        case COMMUNITY_SEARCH_RESULTS:
            return state.merge({'forumSearchResults': fromJS(action.results)})
        default:
            return state;
    }
};

export default forumReducer;
