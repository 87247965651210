import React from 'react';

/* Container */
import Success from "../success/containers/success";

export default function IFrameAdvisorProfileV2Scheduled() {

    return (
        <Success
            buttonShow={false}
            title={'CALL REQUEST SENT!'}
            subtitle={"A member of our team will follow up with you shortly. Please check your spam folder if you do not see a response within 1-2 business days."}
        />
    )
}