import React from 'react';
import {connect} from 'react-redux';
import {v4 as uuid4} from 'uuid';
import Board from 'react-trello';

/* Components */
import {colors} from '../../../common/components/colors';
import {images} from '../../../common/components/images';
import {LogoLoading} from "../../../common/components/loading";
import {FontBody16, FontSubtitle24To20, FontTitle16} from "../../../common/components/fonts";
import {Container, ContainerTop, FlexRow} from "../components/createcourse";
import {EyeOutlined, EyeInvisibleOutlined, CaretDownFilled, PlusCircleOutlined, CaretRightFilled, DeleteOutlined, MinusCircleOutlined, CaretUpFilled} from '@ant-design/icons'
import {StyledBoard, Element} from '../components/layers';
import { SearchInputUncontrolled } from '../../../common/components/inputs';

/* Store */
import {setCoursesAddElement, setTasksForCourse, setCurrentPageEditing, setCourseCreate} from '../store/courses';

/* Containers */
import LayerPanelElement from './layerpanelelement';

/* Utils */
import {articleElements, quizElements} from '../static/elementtypes';

/* Middleware */
import { tryGetInsightParentCategories, tryGetInsightSubCategories } from '../../../consumer/learn/middleware/insights';

const whoOptions = (who) => {
    return who.map(w => {
        return({
            value: {
                categoryId: w.categoryId
            },
            label: w.categoryName
        })
    });
};

class CourseLayersPanel extends React.Component {
    state={
        isLoading: true, 
        hidden: false,
        elements: [],
        data: {},
        constData: [],
        categories: [],
        selectedCategories: [],
        selectCategories: false,
        selected: null
    }

    componentDidMount() {
        this.setPreviousCategories()
        this.getCategories()
        return this.init()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.course !== this.props.course 
            || prevProps.tasks !== this.props.tasks 
            || prevProps.task !== this.props.task
            || prevProps.pageEditing !== this.props.pageEditing
        ) {
            return this.init()
        } 
    }

    init = async () => {
        const reqs = 
        [{"title": "Introduction",
            "id": 0,
            "contents":[
                {"name": "Cover", "image": "", "id": "img", "type": null, "key": uuid4(), "complete": (this.props.course.get("imageUrl") !== "" && this.props.course.get("imageUrl") && this.props.course.get("imageUrl") !== images.grayBackgroundImage)},
                {"name": "Title", "image": "", "id": "title", "type": null, "key": uuid4(), "complete": (this.props.course.get("title") !== "" && this.props.course.get("title") && this.props.course.get("title") !== "Untitled")}, 
                {"name": "Description", "image": "", "id": "desc", "type": null, "key": uuid4(), "complete": (this.props.course.get("description") !== "" && this.props.course.get("description") && this.props.course.get("description") !== "Description")}
            ]}]
        let data = {lanes: [{id: 'lane1', cards: []}]}
        if (this.props.task.get("id")) {
            data = {lanes: [
                {id: 'lane1', 
                cards: this.props.task.get("pages").get(this.props.pageEditing).get("elements").toJS().map(e => {
                      const title = this.typeToElement(e.type)
                      return ({id: e.id, title: title})
                  })
                },
              ]
            } 
        }
        this.setState({"constData": reqs, "data": data, "isLoading": false})
    }

    getCategories = async  () => {
        const parentCategories = await this.props.tryGetInsightParentCategories()
        const subCategories = await Promise.all(parentCategories.map(o => this.getChildCategories(o)));
        let h = []
        subCategories.forEach(c => {
            h.push(c.childCategories)
        })
        const formattedSubCategories = h.flat().map(c => {return ({categoryId: c.id, categoryName: c.title})})
        this.setState({"categories": formattedSubCategories})
    }

    setPreviousCategories = async () => {
        let cats = []
        if (this.props.course.get("categories")) {
            cats = await this.props.course.get("categories").map(c => {return ({categoryId: c.get("id"), categoryName: c.get("title")})}).toJS()
        }
        this.setState({'selectedCategories': whoOptions(cats)})

        const categoryIds = this.state.selectedCategories.map(c => c.value.categoryId)
        this.props.setCourseCreate({...this.props.course.toJS(), "categoryIds": categoryIds})
    }

    getChildCategories = async (o) => {
        return await this.props.tryGetInsightSubCategories(o.id)
    }

    setHidden = () => {
        this.setState({"hidden": !this.state.hidden})
    }

    setSelected = async (id, saved) => {
        await this.props.setCurrentPageEditing(0)
        if (!saved) {
            await this.saveCurrentTask()
        }
        const s = this.state.selected === id ? null : id
        this.setState({"selected": s})
        if (s === null) {
            const task = {
                "id": null, "title": "", "description": "", "imageUrl": "", "pages": [], "isActive": true,
                "externalAuthor": {"firstName": "", "lastName": "", "additionalInformation": {}},
            }
            await this.props.setSelected(task)
        } else {
            await this.props.setSelected(this.props.tasks.get(s))
        }
    }

    addWeek = async () => {
        await this.saveCurrentTask()
        await this.props.addWeek()
        this.setState({"selected": this.props.tasks.size - 1})
        await this.props.setCurrentPageEditing(0)
    }

    deleteWeek = async (index) => {
        if (index === 0) {
            await this.setState({'selected': null})
        } else if (this.state.selected === index) {
            await this.props.setCurrentPageEditing(0)
            await this.setState({'selected': index - 1})
        } else {
            await this.props.setCurrentPageEditing(0)
        }
        await this.props.deleteWeek(index)
    }

    addPage = async (type) => {
        console.log("task page count: ", this.props.task.get("pages").size);
        await this.props.addPage(type)
        await this.saveCurrentTask()
        await this.changePage(this.props.task.get("pages").size - 1)
    }

    changePage = async (page) => {
        await this.props.setCurrentPageEditing(page)
    }

    deletePage = async (page) => {
        if (this.props.pageEditing === page) {
            await this.changePage(page - 1)
        } else if (this.props.pageEditing > page) {
            await this.changePage(this.props.pageEditing - 1)
        }
        await this.props.deletePage(page)
    }

    saveCurrentTask = async () => {
        if (this.props.task.get("id")) {
            let newTasks = this.props.tasks.toJS()
            const tk = this.props.task.toJS()
            const i = await newTasks.indexOf(newTasks.find(t => t.id === tk.id))
            newTasks = await newTasks.filter(t => {return t.id !== tk.id}) //remove from list
            await newTasks.splice(i, 0, tk).shift()
            await this.props.setTasksForCourse(newTasks)
        }
    }

    deleteElement = async (cardId, laneId) => {
        await this.arrangeElements(cardId, null, false)
    }

    handleDragEnd = async (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
        await this.arrangeElements(cardId, position, true)
    }

    arrangeElements = async (cardId, position, move) => {
        const pages = this.props.task.get("pages")
        let elements = pages.get(this.props.pageEditing).get("elements").toJS()
        if (move) {
            const el = await elements.filter(e => {return cardId === e.id})[0] //get element to be reordered
            elements = await elements.filter(e => {return cardId !== e.id}) //remove from list
            await elements.splice(position, 0, el)
        } else {
            elements = await elements.filter(e => {return cardId !== e.id})
        }
        elements = await elements.map((e, i) => {
            return {...e, "id": i}
        })
        const page = {
            ...pages.get(this.props.pageEditing).toJS(),
            "elements": elements,
        }
        let pagesJS = pages.toJS()
        await pagesJS.splice(this.props.pageEditing, 1, page)
        await this.props.setCurrentPageEditing(pages.get(this.props.pageEditing).get("pageNum"))
        await this.props.setCoursesAddElement(pagesJS)
    }

    typeToElement = (type) => {
        const pages = this.props.task.get("pages")
        if (pages.get(this.props.pageEditing).get("pageType") === 'page') {
            return articleElements.filter(a => {return a.type === type})[0].name
        } else {
            return quizElements.filter(a => {return a.type === type})[0].name
        }
    }

    handleChangeCategories = async (e) => {
        const categoryIds = this.state.selectedCategories.map(c => c.value.categoryId)
        this.props.setCourseCreate({...this.props.course.toJS(), "categoryIds": [...categoryIds, e.value.categoryId]})
        await this.setState({selectedCategories: [...this.state.selectedCategories, e]})
    };

    deleteCategory = async (i) => {
        let categories = this.state.selectedCategories
        categories.splice(i, 1)
        const categoryIds = categories.map(c => c.value.categoryId)
        this.props.setCourseCreate({...this.props.course.toJS(), "categoryIds": categoryIds})
        await this.setState({selectedCategories: categories})
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <Container backgroundColor={colors.white} border={colors.backgroundColor2} style={{rowGap: 0}}>
                    <ContainerTop style={{backgroundColor: colors.backgroundColor2, borderRadius: this.state.hidden ? '25px' : '25px 25px 0px 0px', paddingBottom: '10px'}}>
                        <FlexRow>
                            <FontSubtitle24To20>{'LAYERS'}</FontSubtitle24To20>
                            <div style={{cursor: 'pointer'}} onClick={() => {this.setHidden()}}>
                                <FontBody16>
                                    {this.state.hidden ? <><EyeOutlined />&nbsp;&nbsp;Show</> : <><EyeInvisibleOutlined />&nbsp;&nbsp;Hide</>}
                                </FontBody16>
                            </div>
                        </FlexRow>
                    </ContainerTop>
                    {!this.state.hidden &&
                    <>
                        <br />
                        <div style={{marginLeft: '10px'}}>
                            {this.state.constData.map((c, i) => {
                                return(
                                    <div key={i} style={{color: colors.backgroundColor5}} onClick={() => {this.setSelected(null, false)}}>
                                        <FontTitle16 style={{cursor: 'pointer'}}>{this.state.selected === null ? <CaretDownFilled /> : <CaretRightFilled />} {c.title}</FontTitle16>
                                        {this.state.selected === null && 
                                        <>
                                            {c.contents.map((e, ind) => {
                                                return (
                                                    <Element key={ind} style={{cursor: 'default'}}>
                                                        <FontBody16 style={{display: 'flex', alignItems: 'center'}}>    
                                                            {e.complete ? <img src={images.progressCircleCompletedNavy} alt="Complete" /> : <img src={images.progressCircleNotCompletedNavy} alt="Incomplete" />}
                                                            &nbsp;&nbsp;
                                                            {e.name}
                                                        </FontBody16>
                                                    </Element>
                                                )
                                            })}
                                            <div key={uuid4()} style={{display: 'inline-flex', alignItems: 'center', justifyContent: "space-between", maxWidth: '250px', minWidth: '230px', color: colors.backgroundColor5, margin: "0px 10px"}}>
                                                <FontBody16 style={{display: 'flex', alignItems: 'center'}}>    
                                                    <img src={this.state.selectedCategories.length > 0 ? images.progressCircleCompletedNavy : images.progressCircleNotCompletedNavy} alt="Incomplete" />
                                                    <div onClick={() => {return this.setState({"selectCategories": !this.state.selectCategories})}} style={{cursor: 'pointer'}}>
                                                        &nbsp;
                                                        Select Categories
                                                        &nbsp;
                                                        {this.state.selectCategories ? <CaretUpFilled /> : <CaretDownFilled />}
                                                    </div>
                                                </FontBody16>
                                            </div>
                                            {this.state.selectCategories &&
                                            <>
                                                <SearchInputUncontrolled
                                                    title={"Categories"}
                                                    options={whoOptions(this.state.categories)}
                                                    id={'categories'}
                                                    onChange={this.handleChangeCategories}
                                                    placeholder={'Search categories'}
                                                    value={""}
                                                    containerStyles={{width: 'calc(100% - 10px)', marginTop: '15px'}}
                                                />
                                                {this.state.selectedCategories.map((c, i) => {
                                                    return (
                                                        <Element key={i} style={{cursor: 'default'}}>
                                                            <FontBody16 style={{display: 'flex', alignItems: 'center'}}>    
                                                                <div style={{cursor: 'pointer'}} onClick={() => {this.deleteCategory(i)}}><DeleteOutlined /></div>
                                                                &nbsp;&nbsp;
                                                                {c.label}
                                                            </FontBody16>
                                                        </Element>
                                                    )
                                                })}
                                            </>
                                            }
                                        </>
                                        }
                                    </div>
                                )
                                
                            })}
                            {this.props.tasks.map((t, i) => {
                                return (
                                    <div key={i} style={{color: colors.backgroundColor5}}>
                                        <br />
                                        <FontTitle16 style={{cursor: 'pointer'}} onClick={() => {this.setSelected(i, false)}}>{this.state.selected === i ? <CaretDownFilled /> : <CaretRightFilled />} Week {i + 1}</FontTitle16>
                                        {this.state.selected === i &&
                                            <>
                                                <>  
                                                    {t.get("pages").map((p, ind) => (
                                                        <React.Fragment key={ind}>
                                                            <Element>
                                                                <FontBody16 style={{alignItems: 'center'}}>   
                                                                    <div style={{
                                                                        backgroundColor: this.props.pageEditing === ind ? colors.secondary100 : "inherit",
                                                                        color: this.props.pageEditing === ind ? colors.white : "inherit",
                                                                        padding: '5px',
                                                                        width: 'calc(100% - 75px)',
                                                                        display: 'inline-flex',
                                                                        justifyContent: 'space-between'
                                                                    }}>
                                                                        <div onClick={() => {this.changePage(ind)}}>&nbsp;&nbsp;Page {ind + 1}</div>
                                                                        {ind !== 0 && <div onClick={() => {this.deletePage(ind)}}><DeleteOutlined /></div>}
                                                                    </div>
                                                                </FontBody16>
                                                            </Element>
                                                            {(this.props.pageEditing === ind && ind !== 0) &&
                                                                <StyledBoard bottom={true} style={{marginLeft: '5px'}}>
                                                                    <Board
                                                                        data={this.state.data}
                                                                        cardDraggable={true}
                                                                        onCardDelete={this.deleteElement}
                                                                        laneDraggable={false}
                                                                        hideCardDeleteIcon={false}
                                                                        components={{Card: LayerPanelElement}}
                                                                        handleDragEnd={this.handleDragEnd}
                                                                    />
                                                                </StyledBoard>
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                                </>
                                                <Element>
                                                    <FontBody16 style={{display: 'flex', alignItems: 'center'}} onClick={() => {this.addPage("page")}}> 
                                                        &nbsp;&nbsp;<PlusCircleOutlined />&nbsp;&nbsp;Learning Material
                                                    </FontBody16>
                                                </Element>
                                                <Element>
                                                    <FontBody16 style={{display: 'flex', alignItems: 'center'}} onClick={() => {this.addPage("quiz")}}>
                                                        &nbsp;&nbsp;<PlusCircleOutlined />&nbsp;&nbsp;Quiz
                                                    </FontBody16>
                                                </Element>
                                                <FontTitle16 style={{display: 'flex', alignItems: 'center', color: colors.alert100, cursor: 'pointer'}} onClick={() => {this.deleteWeek(i)}}>
                                                    &nbsp;&nbsp;<MinusCircleOutlined />&nbsp;&nbsp;Delete week
                                                </FontTitle16>
                                            </>
                                        }
                                    </div>
                                )
                            })}
                            <br />
                            <Element>
                                <FontTitle16 style={{display: 'flex', alignItems: 'center'}} onClick={() => {this.addWeek()}}>    
                                    <PlusCircleOutlined />&nbsp;&nbsp;Week
                                </FontTitle16>
                            </Element>
                        </div>
                    </>
                    }
                </Container>
            )
        }
    }

}

const mapStateToProps = state => ({
    course: state.enterprise.curriculum.curriculum.get("courseCreate"),
    task: state.enterprise.curriculum.curriculum.get("taskCreate"),
    tasks: state.enterprise.curriculum.curriculum.get("tasks"),
    pageEditing: state.enterprise.curriculum.curriculum.get("pageEditing"),
});

const mapDispatchToProps = dispatch => ({
    setCoursesAddElement: (element) => dispatch(setCoursesAddElement(element)),
    setTasksForCourse: (tasks) => dispatch(setTasksForCourse(tasks)),
    setCurrentPageEditing: (pageNum) => dispatch(setCurrentPageEditing(pageNum)),
    tryGetInsightParentCategories: () => dispatch(tryGetInsightParentCategories()),
    tryGetInsightSubCategories: (id) => dispatch(tryGetInsightSubCategories(id)),
    setCourseCreate: (course) => dispatch(setCourseCreate(course))
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseLayersPanel);