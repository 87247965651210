import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import {Container, LoadingCenter, ContentBodySection} from "../components/details";
import {LogoLoading} from "../../../common/components/loading";

import {setTaskCreate, setCoursesAddElement, setCurrentPageEditing} from '../store/courses';
import FormsFlow from "../../../iframes/forms/containers/flow";

class CourseTaskDisplay extends React.Component {
    state={
        form: [],
        isLoading: true,
        updatePaused: false,
        imageUpload: false,
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "form": this.props.course.get("pages").toJS(), 
            "isLoading": false})
    };

    componentDidUpdate(prevProps) {
        if ((prevProps.course !== this.props.course || prevProps.pageEditing !== this.props.pageEditing) && !this.state.updatePaused) {
            this.setState({"isLoading": true})
            return this.init()
        }
    }

    onReturnForm = (form) => {
        return this.setState({"form": form})
    }

    autoSave = async (answers, isCompleted, lastPageCompleted) => {
        await this.setState({"updatePaused": true})
        await this.props.setCurrentPageEditing(lastPageCompleted)
        await this.props.setCoursesAddElement(answers)
        if (lastPageCompleted === 0) {
            const elements = this.props.course.get("pages").get(0).get("elements")
            const task = {
                ...this.props.course.toJS(),
                "description": elements.get(2).get("text"),
                "imageUrl": elements.get(1).get("text"),
                "title": elements.get(0).get("text")
            }
            await this.props.setTaskCreate(task)
        }
        this.setState({"updatePaused": false})
    };

    render() {
        if (this.state.isLoading) {
            return (<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return (
                <Container>
                    <br />
                    <div style={{position: 'relative'}}>
                        <ContentBodySection>
                            <FormsFlow
                                form={this.state.form}
                                skipAuth={true}
                                onCompleteFormMethod={"return"}
                                onReturnForm={this.onReturnForm}
                                formHasOnePage={false}
                                editDisabled={false}
                                autoSaveOn={true}
                                autoSaveTry={this.autoSave}
                                allowFormToChange={true}
                                lastPageCompleted={this.props.pageEditing}
                            />
                        </ContentBodySection>
                    </div>       
                </Container>
            )
        }
    }
}

const mapStateToProps = state => ({
    course: state.enterprise.curriculum.curriculum.get("taskCreate"),
    pageEditing: state.enterprise.curriculum.curriculum.get("pageEditing")
});

const mapDispatchToProps = dispatch => ({
    setTaskCreate: (course) => dispatch(setTaskCreate(course)),
    setCoursesAddElement: (element) => dispatch(setCoursesAddElement(element)),
    setCurrentPageEditing: (pageNum) => dispatch(setCurrentPageEditing(pageNum))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CourseTaskDisplay))