import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import ReactQuill from "react-quill";

/* Components */
import {FontBody16, FontHeader21, FontTitle18} from '../../../common/components/fonts';
import {ButtonSecondary} from "../../../common/components/buttons";
import {LogoLoading} from "../../../common/components/loading";
import {EditOutlined, PlusOutlined, DeleteOutlined} from "@ant-design/icons";
import {Portfolios, LoadingCenter, FitContent, Border, Btn, Inline, TextLeft, Description, SectionSub}from "../components/faqs";
import {Quill} from "../../../common/components/richtexteditor";
import {colors} from "../../../common/components/colors";

/* Store */
import {setShopItemFAQ} from "../store/shopitems";

/* Middleware */
import {tryUpdateExpertProfile} from "../middleware/expertprofile";

class ProfileFAQs extends React.Component {
    state = {"faqs": [], "profile": {}, "quillComponent": ""};

    componentDidMount() {
        return this.init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.profile !== this.props.profile) {
            return this.init()
        }
    }

    init = async () => {
        await this.setState({"profile": this.props.profile});
        await this.initDescription();
        await this.setState({"isLoading": false});
    };

    initDescription = async () => {
        await this.setState({"quillComponent": ""});
        this.setState({
            "quillComponent": [this.props.profile.get("faqs").map((f, index) => {
                return(
                    <div key={index}>
                        <SectionSub isOpen={true}>
                            <FontHeader21>{f.get("title")}</FontHeader21>
                        </SectionSub>
                        <Description>
                            <Quill bodyBorderColor={colors.white}>
                                <ReactQuill
                                    readOnly={true}
                                    theme={null}
                                    value={f.get("body")}
                                    bounds={'.app'}
                                />
                            </Quill>
                            <Border />
                            <TextLeft>
                                <Inline>
                                    <FitContent>
                                        <Btn>
                                            <FontBody16 onClick={() => {return this.edit(f)}}><EditOutlined/>&nbsp;Edit</FontBody16>
                                        </Btn>
                                    </FitContent>
                                </Inline>
                                <Inline>
                                    <FitContent>
                                        <Btn>
                                            <FontBody16 onClick={() => {return this.delete(f)}}><DeleteOutlined />&nbsp;Delete</FontBody16>
                                        </Btn>
                                    </FitContent>
                                </Inline>
                            </TextLeft>
                        </Description>
                    </div>
                )
            })]
        })
    };

    add = async () => {
        await this.props.setShopItemFAQ({});
        this.props.history.push("/"+(this.props.isWMC ? "profile" : "shop")+"/faqs/create");
    };

    edit = async (item) => {
        await this.props.setShopItemFAQ(item);
        this.props.history.push("/"+(this.props.isWMC ? "profile" : "shop")+"/faqs/edit");
    };

    delete = async (item) => {
        let faqs = this.props.profile.get("faqs").toJS();
        const saveData = {
            // "bio": this.props.profile.get("bio"),
            // "whyBecameCoach": this.props.profile.get("whyBecameCoach"),
            // "freeTime": this.props.profile.get("freeTime"),
            // "endorsement1": this.props.profile.get("endorsement1"),
            // "endorsement2": this.props.profile.get("endorsement2"),
            // "endorsement3": this.props.profile.get("endorsement3"),
            "faqs": await faqs.filter(p => {return(p.id !== item.get("id"))}),
        };
        await this.props.tryUpdateExpertProfile(saveData, "deleteFAQ");
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <FontTitle18>My FAQs</FontTitle18>
                    <br />
                    <FitContent>
                        <FontBody16 onClick={() => {return this.add()}}>
                            <ButtonSecondary canSubmit={false} label={<><PlusOutlined />&nbsp;Add FAQ</>} />
                        </FontBody16>
                    </FitContent>
                    <br/>
                    <Portfolios>
                        {this.state.quillComponent}
                    </Portfolios>
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    isWMC: state.common.wmc.get("isWMC"),
    profile: state.enterprise.shop.expertProfile.get("expertProfile"),
});

const mapDispatchToProps = dispatch => ({
    setShopItemFAQ: (item) => dispatch(setShopItemFAQ(item)),
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileFAQs));
