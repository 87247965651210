import React from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';

/* Components */
import ConsumerNav from '../navbars/containers/nav';
import { PageFull, PageContentContainer } from '../navbars/components/navlayout';

const Height54px = styled.div`
  height: 54px;
  width: 100%;
`;

/* Pages */
import MoneySpending from "../../common/containers/mymoney/containers/moneyspending";
import MoneySnapshot from "../../common/containers/mymoney/containers/moneysnapshot";
import MoneySaving from "../../common/containers/mymoney/containers/moneysaving";
import FinancialProfile from "./containers/myfinancialprofile";

const MyMoney = ({location}) => (
    <>
        <ConsumerNav />
        <PageFull>
            <PageContentContainer>
                <Height54px />
                {location.pathname === "/my-financial-profile/spending" && <MoneySpending useNewDesign={true}/>}
                {location.pathname === "/my-financial-profile/snapshot" && <MoneySnapshot useNewDesign={true}/>}
                {location.pathname === "/my-financial-profile/saving" && <MoneySaving useNewDesign={true}/>}
                {location.pathname === "/my-financial-profile" && <FinancialProfile/>}
            </PageContentContainer>
        </PageFull>
    </>
);

export default withRouter(MyMoney);

