import { combineReducers } from 'redux';

/* Reducers */
import notesReducer from './store/notes';

const noteReducer = combineReducers({
    notes: notesReducer,
});

export default noteReducer;
