import React from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import deepmerge from 'deepmerge';
import {arrayToTree} from 'performant-array-to-tree';
import cleaner from 'deep-cleaner';
import {v4 as uuid4} from 'uuid';

/* Components */
import { colors } from "../../../components/colors";
import {FontHeader21, FontHeader16, FontTitle18} from "../../../components/fonts";
import {CloseOutlined} from '@ant-design/icons';
import {InlineBlock, SaveSection, SubmitInputWithValidation} from "../../../../enterprise/components/clientscreate";
import {ButtonTertiary} from "../../../components/buttons";
import {RadioBoxWithLabel} from "../../../components/inputs";
import { UploadFiles, UploadFolder } from '../../../components/dropzone';

/* Middleware */
import {tryGetFolderList, tryUploadFile, tryCreateNewFolder} from '../middleware/filevault';

/* Store */
import {setFolderOrFileUploadModal} from "../store/filevault";

/* Icons */
import {images} from '../../../components/images';

const Modal = styled.div`
    position: relative;
    margin: 0px auto;
    top: 110px;
    z-index: 10;
    background-color: ${colors.white};
    border: 1px solid ${colors.border100};
    border-radius: 6px;
    width: 100%;
    max-width: 550px;
    @media screen and (max-width: 640px) {
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        position: fixed;
        overflow-y: scroll;
        max-width: 100%;
    }
`;
const Fixed = styled.div`
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 8;
`;
const Background = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9;
`;
const Title = styled.div`
    padding: 25px;
`;
const Inline = styled.div`
    width: calc(100% - 77px);
    display: inline-block;
    vertical-align: top;
`;
const Image = styled.img`
    width: 32px;
    margin-right: 20px;
    vertical-align: top;
    display: inline-block;
`;
const Close = styled.div`
    width: 25px;
    vertical-align: top;
    display: inline-block;
    color: ${colors.secondary100};
    cursor: pointer;
`;
const Form = styled.form`
    padding: 0px 25px 25px 25px;
`;
const Success = styled.div`
    padding: 40px 25px;
    text-align: center;
`;
const Radios = styled.div`
    @media screen and (max-width: 460px) {
        margin: 20px 0px;
    }
`;
const Half = styled.div`
    width: calc(50% - 60px);
    padding: 30px;
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 460px) {
        display: block;
        width: calc(100% - 20px);
        padding: 0px 10px;
    }
`;
const UploadSection = styled.div`
    padding: 0px 30px;
    @media screen and (max-width: 460px) {
        padding: 0px 10px;
    }
`;

const initState = {"folderOrFileUploadModal": false, "uploadType": "files", "documentType": "File", "isChanged": false, "isSaved": false, "isSaving": false, "selected": []};

class VaultUpload extends React.Component {
    state=initState;

    componentDidMount() {
        this.setState({"folderOrFileUploadModal": this.props.folderOrFileUploadModal})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.folderOrFileUploadModal !== this.props.folderOrFileUploadModal && this.props.folderOrFileUploadModal) {
            this.setState({"folderOrFileUploadModal": this.props.folderOrFileUploadModal})
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    timeout = 0;

    close = async () => {
        await this.props.setFolderOrFileUploadModal(false);
        await this.setState(initState);
    };

    // readFile = async (file) => {
    //     let reader = new FileReader();
    //     return new Promise((resolve, reject) => {
    //         reader.onerror = () => {reader.abort();reject(new DOMException("Problem parsing file."))};
    //         reader.onload = () => {resolve(reader.result)};
    //         reader.readAsText(file);
    //     });
    // };

    createTree = async () => {
        let tree = await Promise.all(this.state.selected.map(async (file, index, arr) => {
            let path = file.path.replaceAll(".", " ").split("/");
            path.pop();
            let ids = await Promise.all(path.map(p => {return Promise.resolve(uuid4())}));
            let results = [];
            if(ids.length === 0) {
                await results.push({
                    "id": uuid4(),
                    "parentId": null,
                    "name": this.props.folderList.get("name"),
                    "files": [{
                        "name": file.name,
                        "file": file.preview
                    }],
                    "folders": []
                })
            } else {
                await Promise.all(ids.map(async (id, index) => {
                    if(index === 0) {
                        await results.push({
                            "id": id,
                            "parentId": null,
                            "name": path[index] === undefined ? this.props.folderList.get("name") : path[index],
                            "files": (ids.length === 1)
                                ?
                                [{
                                    "name": file.name,
                                    "file": file.preview
                                }]
                                :
                                []
                            ,
                            "folders": []
                        })
                    } else {
                        await results.push({
                            "id": id,
                            "parentId": ids[(index-1)],
                            "name": path[index] === undefined ? this.props.folderList.get("name") : path[index],
                            "files": (ids.length === (index+1))
                                ?
                                [{
                                    "name": file.name,
                                    "file": file.preview // await this.readFile(file)
                                }]
                                :
                                []
                            ,
                            "folders": []
                        })
                    }
                    return Promise.resolve();
                }));
            }
            return Promise.resolve(arrayToTree(results, {id: "id", parentId: "parentId", dataField: null, childrenField: "folders"}));
        }));
        let cleaned = await cleaner(tree, ["id", "parentId"]);
        let firstMerge = await deepmerge.all(deepmerge.all(cleaned));
        let fullMerge = await this.deepMergeArray(firstMerge, this.deepMergeArray);
        return(fullMerge)
    };

    deepMergeArray = async (object, callback) => {
        let newObject = object;
        if(object.folders.length > 1) {
            let distinctNames = [...new Set(object.folders.map(x => x.name))];
            if(distinctNames.length > 1) {
                let newArray = [];
                await Promise.all(distinctNames.map(async (name) => {
                    let arrayOfName = object.folders.filter(f => {return(f.name === name)});
                    let newFolders = deepmerge.all(arrayOfName);
                    if(newFolders.folders.length > 1) {
                        newArray.push(await callback(newFolders, callback));
                    } else {
                        newArray.push(newFolders);
                    }
                    newObject = {...object, "folders": newArray};
                }));
            } else {
                let newFolder = deepmerge.all(object.folders);
                if(newFolder.folders.length > 1) {
                    newObject = {...object, "folders": [await callback(newFolder, callback)]};
                } else {
                    newObject = {...object, "folders": [newFolder]};
                }
            }
        }
        return Promise.resolve(newObject);
    };

    uploadFile = async (files, folderId) => {
        return Promise.all(files.map(f => {
            let file = this.state.selected.filter(s => {return(f.file === s.preview)})[0];
            return Promise.resolve(this.props.tryUploadFile(folderId, file, this.state.uploadType === "files", this.props.folderList.get("driveId"), f.name));
        }));
    };

    readFolders = async (folders, currentFolderId) => {
        if(folders.length === 0) {
            return null;
        } else {
            await Promise.all(folders.map(async f => {
                let createdFolder = await this.props.tryCreateNewFolder(f.name, currentFolderId, this.props.folderList.get("driveId"), "return");
                let createdFolderId = createdFolder.id;
                if(f.files.length > 0) {
                    await this.uploadFile(f.files, createdFolderId);
                }
                await this.readFolders(f.folders, createdFolderId);
                return Promise.resolve();
            }));
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({'isSaving': true});
        let tree = await this.createTree();
        let currentFolderId = this.props.folderList.get("folderId");
        if(this.state.uploadType === "folder") {
            let createdFolder = await this.props.tryCreateNewFolder(tree.name, currentFolderId, this.props.folderList.get("driveId"), "return");
            await this.uploadFile(tree.files, createdFolder.id);
            await this.readFolders(tree.folders, createdFolder.id);
            // await this.props.tryGetFolderList(currentFolderId, false);
        } else {
            await this.uploadFile(tree.files, currentFolderId);
        }
        this.setState({'isSaving': false, 'isSaved': true, "isChanged": false});
        this.timeout = setTimeout(this.close, 1000);
    };

    clearSelected = async () => {
        await this.setState({"selected": [], "isChanged": false});
    };

    handleSelected = async (e) => {
        let selected = this.state.selected;
        selected.push(e);
        await this.setState({"selected": selected, "isChanged": true});
    };

    changeToUploadFiles = () => {
        this.setState({"uploadType": "files"});
        return this.clearSelected();
    };

    changeToUploadAFolder = () => {
        this.setState({"uploadType": "folder"});
        return this.clearSelected();
    };

    render() {
        if(!this.state.folderOrFileUploadModal) {
            return null
        } else {
            return (
                <Fixed>
                    <Modal>
                        {this.state.isSaved
                            ? <Success><FontTitle18>Upload complete!</FontTitle18></Success>
                            : <>
                                <Title>
                                    <Image src={images.vaultFileFolderPrivate} alt={""} />
                                    <Inline><FontHeader21>Upload</FontHeader21></Inline>
                                    <Close onClick={this.close}><FontHeader16><CloseOutlined /></FontHeader16></Close>
                                </Title>
                                <Form method={"post"} onSubmit={this.handleSubmit}>
                                    <Radios>
                                        <Half>
                                            <RadioBoxWithLabel id={1} checked={this.state.uploadType === "files"} action={this.changeToUploadFiles} label={"Upload Files"} />
                                        </Half>
                                        <Half>
                                            <RadioBoxWithLabel id={2} checked={this.state.uploadType === "folder"} action={this.changeToUploadAFolder} label={"Upload Folder"} />
                                        </Half>
                                    </Radios>
                                    <UploadSection>
                                        {this.state.uploadType === "files" && <UploadFiles clearSelected={this.clearSelected} handleSelected={this.handleSelected} />}
                                        {this.state.uploadType === "folder" && <UploadFolder clearSelected={this.clearSelected} handleSelected={this.handleSelected} />}
                                    </UploadSection>
                                    <SaveSection>
                                        <InlineBlock><SubmitInputWithValidation label={"Upload"} isChanged={this.state.isChanged} isValid={true} isSaving={this.state.isSaving} /></InlineBlock>
                                        <InlineBlock><div onClick={this.close}><ButtonTertiary canSubmit={false} label={"Cancel"} /></div></InlineBlock>
                                    </SaveSection>
                                </Form>
                            </>
                        }
                    </Modal>
                    {this.state.isSaved && <Background onClick={this.close} />}
                </Fixed>
            )
        }
    }
}

const mapStateToProps = state => ({
    folderOrFileUploadModal: state.common.fileVault.get("folderOrFileUploadModal"),
    folderList: state.common.fileVault.get("folderList"),
});

const mapDispatchToProps = dispatch => ({
    setFolderOrFileUploadModal: (status) => dispatch(setFolderOrFileUploadModal(status)),
    tryUploadFile: (parentFolderId, file, uploadedFiles, driveId, name) => dispatch(tryUploadFile(parentFolderId, file, uploadedFiles, driveId, name)),
    tryGetFolderList: (folderId, traverseFolder, storeBreadcrumb) => dispatch(tryGetFolderList(folderId, traverseFolder, storeBreadcrumb)),
    tryCreateNewFolder: (name, parentFolderId, driveId, storeOrReturn) => dispatch(tryCreateNewFolder(name, parentFolderId, driveId, storeOrReturn)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VaultUpload);