import React from 'react';

/* Components */
import {colors} from '../../../common/components/colors';
import {FontTitle21, FontHeader18, FontBody16, FontBody12 } from "../../../common/components/fonts";
import {SubmitButton} from '../../../common/components/inputs';
import { Page, NoteActions, Label, ActionLeft, ActionRight, Modal, NoteTitle, NoteSubtitle, Delete } from '../components/confirmchange';

const ConfirmChange = ({close, remove, data, type, closePrior}) => (
    <div>
        <Modal>
            <NoteTitle><FontTitle21>Please confirm</FontTitle21></NoteTitle>
            {type === 'availability'
                ?
                    <NoteSubtitle><FontBody16>Are you sure you would like to remove this availability?</FontBody16></NoteSubtitle>
                :
                    <>
                        <NoteSubtitle><FontBody16>Do you want your client to reschedule this session?</FontBody16></NoteSubtitle>
                        <NoteSubtitle><FontBody12>If you choose yes, an email will be sent to your client with a link to reschedule the session.</FontBody12></NoteSubtitle>
                        <NoteSubtitle><FontBody12>If you choose delete session, an email will be sent to your client that the call was deleted. This email will not include a reschedule link.</FontBody12></NoteSubtitle>
                    </>
            }
            <NoteActions>
                <ActionLeft>
                    <span onClick={() => {close(false)}}>
                        <Label color={colors.primary30}><FontHeader18>No</FontHeader18></Label>
                    </span>
                </ActionLeft>
                <ActionRight>
                    <span onClick={() => {
                        if(type === 'availability') {remove(data);}
                        else {remove(data, true);}
                        closePrior(false);
                        close(false);
                    }}>
                        <SubmitButton backgroundColor={colors.action100} iconComponent={null} color={colors.primary100} label={"Yes"}/>
                    </span>
                </ActionRight>
            </NoteActions>
        </Modal>
        {type === 'session' && <Delete onClick={() => {remove(data, false);closePrior(false);close(false);}}><FontBody16>Delete Session</FontBody16></Delete>}
    </div>
);

export default ConfirmChange;
