import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from "styled-components";

/* Components */
import {LogoLoading} from "../../../../common/components/loading";
import {ConsumerNavTop} from "../components/navtop";
import {ExportOutlined} from "@ant-design/icons";

/* Middleware */
import {trySubmitForm} from '../../../../iframes/forms/middleware/forms';

/* Containers */
import SchedulerFlow from "../../../../iframes/scheduler/containers/flow";
import FormsFlow from "../../../../iframes/forms/containers/flow";

/* Domain */
import getDomainEnv from '../../../../domains/utils';
const env = getDomainEnv();

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;

const Container = styled.div`
    max-width: 768px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 60px auto 0px auto;
`;

class ConsumerSignUpFlow extends React.Component {
    state={
        "step": 1,
        "form": [],
        "isLoading": true,
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({"isLoading": false})
    };

    setStep = async (step) => {
        return this.setState({"step": step})
    };

    onReturnForm = async (form) => {
        await this.setState({"form": form});
        return this.setStep(2);
    };

    onMeetingScheduled = async (meeting) => {
        this.onSubmitForm(meeting.remoteUserId);
    };

    onSubmitForm = async (userId) => {
        this.props.trySubmitForm(this.state.form, userId)
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if(this.state.step === 1) {
                return(
                    <>
                        <ConsumerNavTop title={env.CONSUMER.SIGN_UP.MATCH_AND_SCHEDULE.MATCHING_TITLE} alignCenter={true} />
                        <Container>
                            <FormsFlow
                                form={env.CONSUMER.SIGN_UP.MATCH_AND_SCHEDULE.MATCHING_FORM}
                                skipAuth={true}
                                onCompleteFormMethod={"return"}
                                onReturnForm={this.onReturnForm}
                            />
                        </Container>
                    </>
                )
            } else if(this.state.step === 2) {
                return(
                    <>
                        <ConsumerNavTop title={env.CONSUMER.SIGN_UP.MATCH_AND_SCHEDULE.SCHEDULE_TITLE} alignCenter={true} />
                        <Container>
                            <SchedulerFlow
                                purchaseHistoryId={null}
                                eventCustomMinutes={env.CONSUMER.SIGN_UP.MATCH_AND_SCHEDULE.SCHEDULE_SESSION_MINUTES}
                                signupShowLoginBtn={false}
                                confirmCloseBtnLabel={<><Inline><ExportOutlined /></Inline>&nbsp;Dashboard</>}
                                confirmOnClose={() => {this.props.history.push("/verify")}}
                                confirmOnScheduled={this.onMeetingScheduled}
                            />
                        </Container>
                    </>
                )
            } else {
                return null
            }
        }
    }

}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    trySubmitForm: (formData, userId) => dispatch(trySubmitForm(formData, userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConsumerSignUpFlow));