import React from 'react';
import styled from 'styled-components';

/* Components */
import {MovableCardWrapper, CardHeader, CardRightContent, CardTitle, Detail, Footer} from 'react-trello/dist/styles/Base';
import Tag from 'react-trello/dist/components/Card/Tag';
import {colors} from '../../common/components/colors';
import {FontHeader12, FontHeader16} from '../../common/components/fonts';
import {MenuInputKeyPairs, UsersInput} from "../../common/components/inputs";

const Options = styled.div`
    font-size: 14px;
    color: ${colors.secondary70};
    cursor: pointer;
    position: absolute;
    right: 5px;
`;
const Title = styled.div`
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    cursor: pointer;
    width: calc(100% - 30px);
    text-overflow: ellipsis;
    overflow: hidden;
`;
const PercentGreen = styled.div`
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background-color: ${colors.stoplightGo30};
    color: ${colors.stoplightGo100};
    position: absolute;
    right: 10px;
    bottom: 10px;
    text-align: center;
    padding: 8px 4px;
    font-weight: 900 !important;
`;
const PercentYellow = styled.div`
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background-color: ${colors.stoplightSlow30};
    color: ${colors.stoplightSlow100};
    }
    position: absolute;
    right: 10px;
    bottom: 10px;
    text-align: center;
    padding: 8px 4px;
    font-weight: 900 !important;
`;
const PercentRed = styled.div`
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background-color: ${colors.stoplightStop30};
    color: ${colors.stoplightStop100};
    position: absolute;
    right: 10px;
    bottom: 10px;
    text-align: center;
    padding: 8px 4px;
    font-weight: 900 !important;
`;
const PercentGray = styled.div`
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background-color: ${colors.secondary30};
    color: ${colors.primary100};
    position: absolute;
    right: 10px;
    bottom: 10px;
    text-align: center;
    padding: 8px 4px;
    font-weight: 900 !important;
`;
const TeamIcon = styled.span`
    & > div {
        display: inline-block;
        vertical-align: top;
        position: relative;
        width: 20px;
        & div:nth-of-type(2) {
            left: -63px;
        }
    }
`;

class ProspectCustomCard extends React.Component {
    state={
        showDeleteButton: true,
        style: {},
        tagStyle: {},
        onClick: () => {},
        title: '',
        description: '',
        label: '',
        tags: [],
        className: '',
        id: '',
        isCoachHasAdvisor: false,
        menuOptions: {},
        menuLabels: {},
        allData: {},
        probability: 0,
        isLost: false,
        users: []
    };

    componentDidMount() {
        this.setState({
            showDeleteButton: true,
            style: {"cursor": "grab"},
            tagStyle: {},
            title: this.props.title,
            description: this.props.description,
            label: this.props.label,
            tags: [],
            className: this.props.className,
            id: this.props.id,
            onClick: this.props.onClick,
            isCoachHasAdvisor: this.props.isCoachHasAdvisor,
            menuLabels: this.props.menuLabels,
            menuOptions: this.props.menuOptions,
            allData: this.props.allData,
            probability: this.props.allData.probability,
            isLost: this.props.allData.isArchived,
            users: this.props.users
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.title !== this.props.title) {
            this.setState({"title": this.props.title})
        }
    }

    render() {
        return (
            <MovableCardWrapper
                data-id={this.state.id}
                style={this.state.style}
                className={this.state.className}
            >
                <CardHeader>
                    <Title onClick={this.state.onClick} draggable={this.state.cardDraggable}>{this.state.title}</Title>
                    {/*<CardRightContent onClick={this.state.onClick} >{this.state.label}</CardRightContent>*/}
                    <Options><MenuInputKeyPairs actions={this.state.menuOptions} labels={this.state.menuLabels} allData={this.state.allData}/></Options>
                </CardHeader>
                <Detail onClick={() => {}/*this.state.onClick*/} >
                    {this.state.description}
                    <br />
                    {this.state.users.length === 0
                        ? "Team:       ---"
                        : <><span>Team:&nbsp;&nbsp;&nbsp;&nbsp;</span><TeamIcon><UsersInput users={this.state.users}/></TeamIcon></>
                    }
                </Detail>
                {!this.state.isCoachHasAdvisor &&
                    <>
                        {(!this.state.isLost && this.state.probability >= 70) &&
                            <PercentGreen title={"% probability to win"}><FontHeader12>{this.state.probability}</FontHeader12></PercentGreen>
                        }
                        {(!this.state.isLost && this.state.probability > 30 && this.state.probability < 70) &&
                            <PercentYellow title={"% probability to win"}><FontHeader12>{this.state.probability}</FontHeader12></PercentYellow>
                        }
                        {(!this.state.isLost && this.state.probability <= 30) &&
                            <PercentRed title={"% probability to win"}><FontHeader12>{this.state.probability}</FontHeader12></PercentRed>
                        }
                        {(!this.state.isLost && this.state.probability === 0) &&
                            <PercentGray title={"Unknown probability to win"} style={{"padding": "6px 4px"}}><FontHeader16>?</FontHeader16></PercentGray>
                        }
                        {this.state.isLost &&
                            <PercentGray title={"Prospect lost"} style={{"padding": "8px 4px"}}><FontHeader12>L</FontHeader12></PercentGray>
                        }
                    </>
                }
                {this.state.tags && this.state.tags.length > 0 && (
                    <Footer onClick={this.state.onClick} >
                        {this.state.tags.map(t => (
                            <Tag key={t.title} {...t} tagStyle={this.state.tagStyle} />
                        ))}
                    </Footer>
                )}
            </MovableCardWrapper>
        )
    }
}

export default ProspectCustomCard;