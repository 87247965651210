import React from 'react';
import styled from 'styled-components';

/* Components */
import {FontBody16, FontSubtitle28} from "../../../common/components/fonts";

const Intro = styled.div`
  width: calc(100% - 60px);
  padding: 0 30px; 
  display:flex;
  flex-direction:column;
  gap: 10px;
`;

export default function IFrameAdvisorDirectoryIntro() {
    return (
        <Intro>
            <FontSubtitle28 spaced={true}>WILLOW DIRECTORY</FontSubtitle28>
            <FontBody16>Please review each advisors’s profile before selecting your top choice. You will only be able to book time with one advisor.</FontBody16>
            {/*<FontBody16>Get to know advisors who specialize in serving women, next generation, underrepresented, and LGBTQ+ clients.</FontBody16>*/}
        </Intro>
    );
};