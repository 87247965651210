import {axiosReq} from "../../common/utils/axios";

/* Store */
// import { setClientsAbout, setClientsAboutDownloadPending, setClientsAboutDownloadComplete } from "../store/clientsabout";

/* Static */
// import { devEnterpriseClientAboutData } from '../static/devdata';

// export const tryGetEnterpriseClientAbout = () => {
//     return async (dispatch, getState) => {
//         dispatch(setClientsAboutDownloadPending());
//         let clientId = getState().enterprise.clientsDetail.get("client").get("consumerId");
//         const about = await axiosReq('apiv1/coach/clients/'+clientId+'/about', "GET", {}, devEnterpriseClientAboutData);
//         await dispatch(setClientsAbout(about.aboutData));
//         return dispatch(setClientsAboutDownloadComplete());
//     }
// };

export const tryGetCoachCategories = (coachId) => {
    return async (dispatch) => {
        // dispatch(setClientsAboutDownloadPending());
        const categories = await axiosReq('apiv1/coach/settings/advising-categories/' + coachId, "GET", {}, {});
        // dispatch(setClientsAboutDownloadComplete());
        return categories.expertCategories;
    }
};
