const willow = {

    // REQUIRED - START //

    "favicon16px": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615072185/kssqtw8ay0unfojoy6xq.png",
    "favicon32px": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615072210/zidcbheodata5jukv3xo.png",
    "favicon256px": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615072225/ayutmwszjxnlfnh9jeen.png",

    "logo": "https://docs.trustwillow.com/willowlogotransparent.png",
    "logoTransparent": "https://docs.trustwillow.com/logotransparent.png",
    "logoTransparentWhite": "https://docs.trustwillow.com/logotransparentwhite.png",
    "logoIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615061609/ltahv3jg7dbabxc0eblc.png",

    "error1": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615061902/j5wbelwjtfg4gjjvoom3.png",
    "error2": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615061948/tdg8knv9isuqiodkl6hx.png",
    "error3": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615061980/uzxpckc4cyd8mhuerllf.png",

    "calSyncBrand": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615065796/bap1uqa5cfa56vwr9tz8.png",

    "checkBoxEmpty": "https://docs.trustwillow.com/CheckboxEmpty.png",
    "checkBoxChecked": "https://docs.trustwillow.com/CheckboxChecked.png",

    "backgroundBlur": "https://docs.trustwillow.com/BackgroundBlur",

    "feedbackBackgroundDesktop": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635519883/q56kjvbwhnvslvd6kzbi.png",
    "feedbackBackgroundMobile": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635520695/te0dl2giasjgmmjmym1m.png",
    "feedbackBackgroundDesktopInverted": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635823020/mjey9dyi11lfjv95qku2.png",
    "feedbackBackgroundMobileInverted": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635822977/pyr2dfxoemkyl2srlogz.png",

    // REQUIRED - END //

    //

    // DOMAIN SPECIFIC - START //

    "teamCategoryAccounting": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066562/qhe0k9m810nndhi7rzgv.png",
    "teamCategoryCoaching": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066590/mzd6rk3jfhk90tq94jvw.png",
    "teamCategoryLegal": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066607/uabd3mzkwveugsuax9d3.png",
    "teamCategoryPlanning": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066625/kgewwaflcpfm54lacdmc.png",

    "coachingStyleEmotional": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067604/upkssfnpmabw8ytzbkf0.svg",
    "coachingStyleMilitary": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067652/bohgv5c9z0vzos3zsjgt.svg",
    "coachingStyleNoNonsense": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067673/minz7ya97hhzm1lx2bwb.svg",
    "coachingStyleSpiritual": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067697/akagcyiorgfftlarqzah.svg",
    "coachingStyleUnderServed": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067718/ritld46iwlf7tz5nk7lf.svg",

    "willowTalkIconInfo": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068143/beaieaowozbjqmp6px5w.svg",
    "willowTalkIconLearn": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068171/irvodxwmeeccoctutpl3.svg",
    "willowTalkIconListen": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068190/iuvweq81kcttvtlfaaoi.svg",

    "consumerSignUpIconBalances": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068558/vjnldvirhkzcdv8cx5ek.svg",
    "consumerSignUpIconGoals": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068575/en6cxlxhgdehtcycqzzr.svg",
    "consumerSignUpIconGuidance": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068594/lrfq8o7oihlt8brafvlj.svg",
    "consumerSignUpIconVerify": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068629/fbkfvu7ijbkcs4a8qqoo.svg",
    "consumerSignUpIconBooked": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068613/x54kvub91o1lypxavbzf.svg",

    "coachResourcesWillowLogo": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615071667/nmmozdrepyelums1nlx1.png",

    "willowTeamGabbyAvatar": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633402700/wiyhcppbfbllfzzndmzp.jpg",
    "willowTeamLacyAvatar": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1602605069/chyjbqe6ql6foprctnww.png",

    "emailEmojiCheck": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1619591388/wwnm8tvjbmv2foogepce.png",
    "emailEmojiBook": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1619591370/vgrtjwavld7dc4jcijwj.png",
    "emailEmojiBrain": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1619591354/dzfz6ia99h8dgy6trrfa.png",

    "progressToDosInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260241/t3n4pwoclcvkjwcotmhp.png",
    "progressToDosActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260335/rthkodhg0e8fdoqcsz3o.png",
    "progressGoalsInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260376/lljigr4ygntla9ddwf2n.png",
    "progressGoalsActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260356/xkna34x9i9onpoxfxazd.png",
    "progressBookmarksInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260397/uaghc9uev36fdsoijmie.png",
    "progressBookmarksActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260417/dzp5qnvpnekthvbymygf.png",
    "progressCompletedInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260442/zqnhr4teadihfcedhyzh.png",
    "progressCompletedActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260461/wed9ze9oldx1fcvsrhby.png",
    "progressFinancesActive": "https://docs.trustwillow.com/progressFinancesActive.png",
    "progressFinancesInactive": "https://docs.trustwillow.com/progressFinancesInactive.png",

    "learnBookmarkContent": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633571358/rrnj2eox7fxd55mfxm01.png",
    "learnUnbookmarkContent": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633571455/sqsmwirh82cse6kp6duu.png",

    "navProgramsActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634917780/y0vhzlvrusa1wh6lc7lw.png",
    "navProgramsInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634917812/ys8nky67azm6w6gw4ay5.png",
    "navLearnActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635008894/x0w88pzdkqlo0cwgnc2i.png",
    "navLearnInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634917858/ihzf1cshqvpujgx2n8fu.png",
    "navHomeActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634917888/hf9wsqtfqfhpctdrhozx.png",
    "navHomeInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634917905/sjujc0lp9iky2tdpzl0k.png",
    "navProgressActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634917923/usodp4i2uqf1ggsxilkx.png",
    "navProgressInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634917943/nunj01ulgoso36tnewvp.png",
    "navMoreInactive": "https://docs.trustwillow.com/MoreIconSmall.png",
    "navTeamActive": "https://docs.trustwillow.com/TeamIconActiveSmall.png",
    "navTeamInactive": "https://docs.trustwillow.com/TeamIconInactiveSmall.png",

    "navResourcesIconActive": "https://docs.trustwillow.com/resourcesActiveIcon.png",
    "navResourcesIconInactive": "https://docs.trustwillow.com/resourcesInactiveIcon.png",
    "navDashboardIconActive": "https://docs.trustwillow.com/DashboardIconActive.png",
    "navDashboardIconInactive": "https://docs.trustwillow.com/DashboardIconInactive.png",
    "navAppointmentIconActive": "https://docs.trustwillow.com/ApptsIconActive.png",
    "navAppointmentIconInactive": "https://docs.trustwillow.com/ApptsIconInactive.png",
    "navTeamIconActive": "https://docs.trustwillow.com/TeamIconActive.png",
    "navTeamIconInactive": "https://docs.trustwillow.com/TeamIconInactive.png",
    "navProgressIconActive": "https://docs.trustwillow.com/ProgressIconActive.png",
    "navProgressIconInactive": "https://docs.trustwillow.com/ProgressIconInactive.png",
    "navFinanceIconActive": "https://docs.trustwillow.com/FinancesIconActive.png",
    "navFinanceIconInactive": "https://docs.trustwillow.com/FinancesIconInactive.png",
    "navSelfServeIconActive": "https://docs.trustwillow.com/LearnIconActive.png",
    "navSelfServeIconInactive": "https://docs.trustwillow.com/LearnIconInactive.png",
    "navProgramsIconActive": "https://docs.trustwillow.com/ProgramsIconActive.png",
    "navProgramsIconInactive": "https://docs.trustwillow.com/ProgramsIconInactive.png",
    "navCommunityIconActive": "https://docs.trustwillow.com/CommunityIconActive.png",
    "navCommunityIconInactive": "https://docs.trustwillow.com/CommunityIconInactive.png",
    "navHelpIconActive": "https://docs.trustwillow.com/HelpContantIconActive.png",
    "navHelpIconInactive": "https://docs.trustwillow.com/HelpContactIconInactive.png",
    "navFeedbackIconActive": "https://docs.trustwillow.com/FeedbackIconActive.png",
    "navFeedbackIconInactive": "https://docs.trustwillow.com/FeedbackIconInactive.png",
    "navSettingsIconActive": "https://docs.trustwillow.com/SettingsIconActive.png",
    "navSettingsIconInactive": "https://docs.trustwillow.com/SettingsIconInactive.png",
    "navFirmSupportIconActive": "https://docs.trustwillow.com/FirmSupportIconActive.png",
    "navFirmSupportIconInactive": "https://docs.trustwillow.com/FirmSupportIconInactive.png",
    "navFirmSettingsIconActive": "https://docs.trustwillow.com/FirmSettingsIconActive.png",
    "navFirmSettingsIconInactive": "https://docs.trustwillow.com/FirmSettingsIconInactive.png",
    "navFirmReferralIconActive": "https://docs.trustwillow.com/FirmReferralIconActive.png",
    "navFirmReferralIconInactive": "https://docs.trustwillow.com/FirmReferralIconInactive.png",
    "navFirmDashboardIconActive": "https://docs.trustwillow.com/FirmDashboardIconActive.png",
    "navFirmDashboardIconInactive": "https://docs.trustwillow.com/FirmDashboardIconInactive.png",
    "navFirmUpgradeIconActive": "https://docs.trustwillow.com/FirmUpgradeIconActive.png",
    "navFirmUpgradeIconInactive": "https://docs.trustwillow.com/FirmUpgradeIconInactive.png",
    "navFirmEmployeesIconActive": "https://docs.trustwillow.com/FirmEmployeesIconActive.png",
    "navFirmEmployeesIconInactive": "https://docs.trustwillow.com/FirmEmployeesIconInactive.png",
    "navFirmIntegrationsIconActive": "https://docs.trustwillow.com/FirmIntegrationIconActive.png",
    "navFirmIntegrationsIconInactive": "https://docs.trustwillow.com/FirmIntegrationIconInactive.png",
    "navFirmProgramsIconActive": "https://docs.trustwillow.com/FirmProgramsIconActive.png",
    "navFirmProgramsIconInactive": "https://docs.trustwillow.com/FirmProgramsIconInactive.png",
    "navFirmCoachingIconActive": "https://docs.trustwillow.com/FirmCoachingIconActive.png",
    "navFirmCoachingIconInactive": "https://docs.trustwillow.com/FirmCoachingIconInactive.png",
    "navFirmAcquisitionIconActive": "https://docs.trustwillow.com/FirmAcquisitionIconActive.png",
    "navFirmAcquisitionIconInactive": "https://docs.trustwillow.com/FirmAcquisitionIconInactive.png",
    "firmBackground": "https://docs.trustwillow.com/firmBackground.png",
    "firmBackgroundTop": "https://docs.trustwillow.com/firmBackgroundTop.png",
    "firmAddIcon": "https://docs.trustwillow.com/firmNewEmployeeIcon.png",
    "firmAddIconOutline": "https://docs.trustwillow.com/FirmNewEmployeeIconOutline.png",
    "firmUploadIcon": "https://docs.trustwillow.com/FirmUploadIcon.png",
    "firmDownloadIcon": "https://docs.trustwillow.com/firmDownloadIcon.png",
    "firmEditIcon": "https://docs.trustwillow.com/FirmEditIcon.png",
    "firmEditIconOutline": "https://docs.trustwillow.com/FirmEditIconOutline.png",
    "firmEditIconOutlineGreen": "https://docs.trustwillow.com/FirmEditIconOutlineGreen.png",
    "firmEditIconOnlyBlack": "https://docs.trustwillow.com/firmEditIconOnlyBlack.png",
    "firmDeleteIcon": "https://docs.trustwillow.com/firmTrashIcon.png",
    "firmXIcon": "https://docs.trustwillow.com/firmXIcon.png",
    "firmSupportRM": "https://docs.trustwillow.com/firmSupportRM.png",
    "firmSupportGeneral": "https://docs.trustwillow.com/firmSupportGeneral.png",
    "firmSupportTechnical": "https://docs.trustwillow.com/firmSupportTechnical.png",
    "firmSupportFAQ": "https://docs.trustwillow.com/firmSupportFAQ.png",
    "firmSupportFeedback": "https://docs.trustwillow.com/firmSupportFeedback.png",
    "firmIconCalendarWhite": "https://docs.trustwillow.com/firmIconCalendarWhite.png",
    "firmIconCECredit": "https://docs.trustwillow.com/ce_credit_icon.png",



    "certificateIcon": "https://docs.trustwillow.com/certificationicon.png",
    "checkboxCheckedNone": "https://docs.trustwillow.com/CheckNoBackground.png",
    "checkboxCheckedWhite": "https://docs.trustwillow.com/CheckWhiteBackground.png",
    "checkboxEmptyGold": "https://docs.trustwillow.com/GoldEmptyCheckbox.png",
    "checkboxEmptyWhite": "https://docs.trustwillow.com/WhiteEmptyCheckbox.png",
    "willowCFABadgeOld": "https://docs.trustwillow.com/BlueCertificateIcon.png",
    "willowCFABadge": "https://docs.trustwillow.com/CertificationBadge4.png",
    "willowCANBadge": "https://docs.trustwillow.com/certified_advisor_for_nextgen_badge.png", //here
    "willowCAWBadge": "https://docs.trustwillow.com/certified_advisor_for_women_badge.png", //here
    "checkboxEmptyBlue": "https://docs.trustwillow.com/GoldBlueOutlineEmptyCheckbox.png",
    "certificationIconActive": "https://docs.trustwillow.com/NewVersionIconActive.png",
    "certificationIconInactive": "https://docs.trustwillow.com/NewVersionIcon.png",
    "servicesIcon":"https://docs.trustwillow.com/Services.png",
    "expertiseIcon":"https://docs.trustwillow.com/Expertise.png",
    "videoIcon":"https://docs.trustwillow.com/Video.png",
    "homeIcon":"https://docs.trustwillow.com/Group 781.png",
    "consumerHomeIcon": "https://docs.trustwillow.com/home icon.png",
    "consumerActivePageIcon" : "https://docs.trustwillow.com/page selected.png",
    "consumerProfileIcon" : "https://docs.trustwillow.com/Profile.png",
    "employersIcon": "https://docs.trustwillow.com/employers_icon.png",
    // "nextGenBadge" : "https://docs.trustwillow.com/nextGenBadge.png",
    // "womenBadge" : "https://docs.trustwillow.com/womenBadge.png",
    "nextGenBadge" : "https://docs.trustwillow.com/Certificate_Program_Badge_NextGen.png",
    "womenBadge" : "https://docs.trustwillow.com/Certificate_Program_Badge_Women.png",
    "willowContentServicesIcon":"https://docs.trustwillow.com/willowContentIcon.png",



//"https://docs.trustwillow.com/"    

    "menuAlarm": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636478996/ng5bfvcm9ntjrzyqztwi.png",
    "menuConversation": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636480012/h9e5uokkrdmq4d1shndh.png",
    "menuMoney": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636480038/eshlhdinz6j9vh7j27mp.png",
    "menuPhoneQuestion": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636480059/fb3tsyhusrczndfjrjmm.png",
    "menuSettings": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636480200/em3iwcqkqzihcixch2fe.png",

    "logoTraditionsBank": "https://docs.trustwillow.com/TB_HerTraditions_Horiz_Color.png",
    "logoTraditionsBankIcon": "https://docs.trustwillow.com/TB_HerTraditions_Horiz_Color_Icon.png",

    // DOMAIN SPECIFIC - END //

    //

    // DEPRECATED - START //

    "teamIconConnect": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066450/bkqwawv86gifdyhfaei9.png",
    "teamIconGuidelines": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066479/lwjbqqpathnjp3rpctqe.png",
    "teamIconVideo": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066498/qr7upesgeobgdgec3zw1.png",
    "teamIconReport": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066523/bgthg5uklxzc8fllsrpk.png",
    "teamIconWoman": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066539/azjjtjtvi8xyxwzusg60.png",

    // DEPRECATED - END //

};

const principal = {

    // REQUIRED - START //

    "favicon16px": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615072185/kssqtw8ay0unfojoy6xq.png",
    "favicon32px": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615072210/zidcbheodata5jukv3xo.png",
    "favicon256px": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615072225/ayutmwszjxnlfnh9jeen.png",

    "logo": "https://docs.trustwillow.com/willowlogotransparent.png",
    "logoTransparent": "https://docs.trustwillow.com/logotransparent.png",
    "logoTransparentWhite": "https://docs.trustwillow.com/[Principal]logotransparentwhite.png",
    "logoIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615061609/ltahv3jg7dbabxc0eblc.png",

    "error1": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615061902/j5wbelwjtfg4gjjvoom3.png",
    "error2": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615061948/tdg8knv9isuqiodkl6hx.png",
    "error3": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615061980/uzxpckc4cyd8mhuerllf.png",

    "calSyncBrand": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615065796/bap1uqa5cfa56vwr9tz8.png",

    "checkBoxEmpty": "https://docs.trustwillow.com/[Principal]CheckboxEmpty.png",
    "checkBoxChecked": "https://docs.trustwillow.com/[Principal]CheckboxChecked.png",

    "backgroundBlur": "https://docs.trustwillow.com/BackgroundBlur",

    "feedbackBackgroundDesktop": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635519883/q56kjvbwhnvslvd6kzbi.png",
    "feedbackBackgroundMobile": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635520695/te0dl2giasjgmmjmym1m.png",
    "feedbackBackgroundDesktopInverted": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635823020/mjey9dyi11lfjv95qku2.png",
    "feedbackBackgroundMobileInverted": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635822977/pyr2dfxoemkyl2srlogz.png",

    // REQUIRED - END //

    //

    // DOMAIN SPECIFIC - START //

    "teamCategoryAccounting": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066562/qhe0k9m810nndhi7rzgv.png",
    "teamCategoryCoaching": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066590/mzd6rk3jfhk90tq94jvw.png",
    "teamCategoryLegal": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066607/uabd3mzkwveugsuax9d3.png",
    "teamCategoryPlanning": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066625/kgewwaflcpfm54lacdmc.png",

    "coachingStyleEmotional": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067604/upkssfnpmabw8ytzbkf0.svg",
    "coachingStyleMilitary": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067652/bohgv5c9z0vzos3zsjgt.svg",
    "coachingStyleNoNonsense": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067673/minz7ya97hhzm1lx2bwb.svg",
    "coachingStyleSpiritual": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067697/akagcyiorgfftlarqzah.svg",
    "coachingStyleUnderServed": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067718/ritld46iwlf7tz5nk7lf.svg",

    "willowTalkIconInfo": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068143/beaieaowozbjqmp6px5w.svg",
    "willowTalkIconLearn": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068171/irvodxwmeeccoctutpl3.svg",
    "willowTalkIconListen": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068190/iuvweq81kcttvtlfaaoi.svg",

    "consumerSignUpIconBalances": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068558/vjnldvirhkzcdv8cx5ek.svg",
    "consumerSignUpIconGoals": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068575/en6cxlxhgdehtcycqzzr.svg",
    "consumerSignUpIconGuidance": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068594/lrfq8o7oihlt8brafvlj.svg",
    "consumerSignUpIconVerify": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068629/fbkfvu7ijbkcs4a8qqoo.svg",
    "consumerSignUpIconBooked": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068613/x54kvub91o1lypxavbzf.svg",

    "coachResourcesWillowLogo": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615071667/nmmozdrepyelums1nlx1.png",

    "willowTeamGabbyAvatar": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633402700/wiyhcppbfbllfzzndmzp.jpg",
    "willowTeamLacyAvatar": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1602605069/chyjbqe6ql6foprctnww.png",

    "emailEmojiCheck": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1619591388/wwnm8tvjbmv2foogepce.png",
    "emailEmojiBook": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1619591370/vgrtjwavld7dc4jcijwj.png",
    "emailEmojiBrain": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1619591354/dzfz6ia99h8dgy6trrfa.png",

    "progressToDosInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260241/t3n4pwoclcvkjwcotmhp.png",
    "progressToDosActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260335/rthkodhg0e8fdoqcsz3o.png",
    "progressGoalsInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260376/lljigr4ygntla9ddwf2n.png",
    "progressGoalsActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260356/xkna34x9i9onpoxfxazd.png",
    "progressBookmarksInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260397/uaghc9uev36fdsoijmie.png",
    "progressBookmarksActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260417/dzp5qnvpnekthvbymygf.png",
    "progressCompletedInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260442/zqnhr4teadihfcedhyzh.png",
    "progressCompletedActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260461/wed9ze9oldx1fcvsrhby.png",
    "progressFinancesActive": "https://docs.trustwillow.com/progressFinancesActive.png",
    "progressFinancesInactive": "https://docs.trustwillow.com/progressFinancesInactive.png",

    "learnBookmarkContent": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633571358/rrnj2eox7fxd55mfxm01.png",
    "learnUnbookmarkContent": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633571455/sqsmwirh82cse6kp6duu.png",

    "navProgramsActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634917780/y0vhzlvrusa1wh6lc7lw.png",
    "navProgramsInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634917812/ys8nky67azm6w6gw4ay5.png",
    "navLearnActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635008894/x0w88pzdkqlo0cwgnc2i.png",
    "navLearnInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634917858/ihzf1cshqvpujgx2n8fu.png",
    "navHomeActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634917888/hf9wsqtfqfhpctdrhozx.png",
    "navHomeInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634917905/sjujc0lp9iky2tdpzl0k.png",
    "navProgressActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634917923/usodp4i2uqf1ggsxilkx.png",
    "navProgressInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634917943/nunj01ulgoso36tnewvp.png",
    "navMoreInactive": "https://docs.trustwillow.com/MoreIconSmall.png",
    "navTeamActive": "https://docs.trustwillow.com/TeamIconActiveSmall.png",
    "navTeamInactive": "https://docs.trustwillow.com/TeamIconInactiveSmall.png",

    "navResourcesIconActive": "https://docs.trustwillow.com/resourcesActiveIcon.png",
    "navResourcesIconInactive": "https://docs.trustwillow.com/resourcesInactiveIcon.png",
    "navDashboardIconActive": "https://docs.trustwillow.com/DashboardIconActive.png",
    "navDashboardIconInactive": "https://docs.trustwillow.com/DashboardIconInactive.png",
    "navAppointmentIconActive": "https://docs.trustwillow.com/ApptsIconActive.png",
    "navAppointmentIconInactive": "https://docs.trustwillow.com/ApptsIconInactive.png",
    "navTeamIconActive": "https://docs.trustwillow.com/TeamIconActive.png",
    "navTeamIconInactive": "https://docs.trustwillow.com/TeamIconInactive.png",
    "navProgressIconActive": "https://docs.trustwillow.com/ProgressIconActive.png",
    "navProgressIconInactive": "https://docs.trustwillow.com/ProgressIconInactive.png",
    "navFinanceIconActive": "https://docs.trustwillow.com/FinancesIconActive.png",
    "navFinanceIconInactive": "https://docs.trustwillow.com/FinancesIconInactive.png",
    "navSelfServeIconActive": "https://docs.trustwillow.com/LearnIconActive.png",
    "navSelfServeIconInactive": "https://docs.trustwillow.com/LearnIconInactive.png",
    "navProgramsIconActive": "https://docs.trustwillow.com/ProgramsIconActive.png",
    "navProgramsIconInactive": "https://docs.trustwillow.com/ProgramsIconInactive.png",
    "navCommunityIconActive": "https://docs.trustwillow.com/CommunityIconActive.png",
    "navCommunityIconInactive": "https://docs.trustwillow.com/CommunityIconInactive.png",
    "navHelpIconActive": "https://docs.trustwillow.com/HelpContantIconActive.png",
    "navHelpIconInactive": "https://docs.trustwillow.com/[Principal]HelpContactIconInactive.png",
    "navFeedbackIconActive": "https://docs.trustwillow.com/FeedbackIconActive.png",
    "navFeedbackIconInactive": "https://docs.trustwillow.com/FeedbackIconInactive.png",
    "navSettingsIconActive": "https://docs.trustwillow.com/SettingsIconActive.png",
    "navSettingsIconInactive": "https://docs.trustwillow.com/SettingsIconInactive.png",
    "navFirmSupportIconActive": "https://docs.trustwillow.com/FirmSupportIconActive.png",
    "navFirmSupportIconInactive": "https://docs.trustwillow.com/[Principal]FirmSupportIconInactive.png",
    "navFirmSettingsIconActive": "https://docs.trustwillow.com/FirmSettingsIconActive.png",
    "navFirmSettingsIconInactive": "https://docs.trustwillow.com/[Principal]FirmSettingsIconInactive.png",
    "navFirmReferralIconActive": "https://docs.trustwillow.com/FirmReferralIconActive.png",
    "navFirmReferralIconInactive": "https://docs.trustwillow.com/FirmReferralIconInactive.png",
    "navFirmDashboardIconActive": "https://docs.trustwillow.com/[Principal]FirmDashboardIconActive.png",
    "navFirmDashboardIconInactive": "https://docs.trustwillow.com/[Principal]FirmDashboardIconInactive.png",
    "navFirmUpgradeIconActive": "https://docs.trustwillow.com/FirmUpgradeIconActive.png",
    "navFirmUpgradeIconInactive": "https://docs.trustwillow.com/[Principal]FirmUpgradeIconInactive.png",
    "navFirmEmployeesIconActive": "https://docs.trustwillow.com/FirmEmployeesIconActive.png",
    "navFirmEmployeesIconInactive": "https://docs.trustwillow.com/[Principal]FirmEmployeesIconInactive.png",
    "navFirmIntegrationsIconActive": "https://docs.trustwillow.com/FirmIntegrationIconActive.png",
    "navFirmIntegrationsIconInactive": "https://docs.trustwillow.com/[Principal]FirmIntegrationIconInactive.png",
    "certificationIconActive": "https://docs.trustwillow.com/[Principal]FirmProgramsIconActive.png",
    "certificationIconInactive": "https://docs.trustwillow.com/[Principal]FirmProgramsIconInactive.png",
    "navFirmCoachingIconActive": "https://docs.trustwillow.com/FirmCoachingIconActive.png",
    "navFirmCoachingIconInactive": "https://docs.trustwillow.com/[Principal]FirmCoachingIconInactive.png",
    "navFirmAcquisitionIconActive": "https://docs.trustwillow.com/FirmAcquisitionIconActive.png",
    "navFirmAcquisitionIconInactive": "https://docs.trustwillow.com/FirmAcquisitionIconInactive.png",
    "navFirmCertificationIconActive": "https://docs.trustwillow.com/[Principal]FirmCertificationIconActive.png",
    "navFirmCertificationIconInactive": "[Principal]FirmCertificationIconInactive.png",
    "firmBackground": "https://docs.trustwillow.com/[Principal]firmBackground.png",
    "firmBackgroundTop": "https://docs.trustwillow.com/[Principal]firmBackgroundTop.png",
    "firmAddIcon": "https://docs.trustwillow.com/firmNewEmployeeIcon.png",
    "firmAddIconOutline": "https://docs.trustwillow.com/FirmNewEmployeeIconOutline.png",
    "firmUploadIcon": "https://docs.trustwillow.com/FirmUploadIcon.png",
    "firmDownloadIcon": "https://docs.trustwillow.com/firmDownloadIcon.png",
    "firmEditIcon": "https://docs.trustwillow.com/FirmEditIcon.png",
    "firmEditIconOutline": "https://docs.trustwillow.com/FirmEditIconOutline.png",
    "firmEditIconOutlineGreen": "https://docs.trustwillow.com/FirmEditIconOutlineGreen.png",
    "firmEditIconOnlyBlack": "https://docs.trustwillow.com/firmEditIconOnlyBlack.png",
    "firmDeleteIcon": "https://docs.trustwillow.com/firmTrashIcon.png",
    "firmXIcon": "https://docs.trustwillow.com/firmXIcon.png",
    "firmSupportRM": "https://docs.trustwillow.com/firmSupportRM.png",
    "firmSupportGeneral": "https://docs.trustwillow.com/firmSupportGeneral.png",
    "firmSupportTechnical": "https://docs.trustwillow.com/firmSupportTechnical.png",
    "firmSupportFAQ": "https://docs.trustwillow.com/firmSupportFAQ.png",
    "firmSupportFeedback": "https://docs.trustwillow.com/firmSupportFeedback.png",
    "firmIconCalendarWhite": "https://docs.trustwillow.com/firmIconCalendarWhite.png",
    "firmIconCECredit": "https://docs.trustwillow.com/[Principal]ce_credit_icon.png",





//"https://docs.trustwillow.com/"

    "menuAlarm": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636478996/ng5bfvcm9ntjrzyqztwi.png",
    "menuConversation": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636480012/h9e5uokkrdmq4d1shndh.png",
    "menuMoney": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636480038/eshlhdinz6j9vh7j27mp.png",
    "menuPhoneQuestion": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636480059/fb3tsyhusrczndfjrjmm.png",
    "menuSettings": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636480200/em3iwcqkqzihcixch2fe.png",

    "logoTraditionsBank": "https://docs.trustwillow.com/TB_HerTraditions_Horiz_Color.png",
    "logoTraditionsBankIcon": "https://docs.trustwillow.com/TB_HerTraditions_Horiz_Color_Icon.png",

    // DOMAIN SPECIFIC - END //

    //

    // DEPRECATED - START //

    "teamIconConnect": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066450/bkqwawv86gifdyhfaei9.png",
    "teamIconGuidelines": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066479/lwjbqqpathnjp3rpctqe.png",
    "teamIconVideo": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066498/qr7upesgeobgdgec3zw1.png",
    "teamIconReport": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066523/bgthg5uklxzc8fllsrpk.png",
    "teamIconWoman": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066539/azjjtjtvi8xyxwzusg60.png",

    // DEPRECATED - END //

};

const edwardjones = {

    // REQUIRED - START //

    "favicon16px": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615072185/kssqtw8ay0unfojoy6xq.png",
    "favicon32px": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615072210/zidcbheodata5jukv3xo.png",
    "favicon256px": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615072225/ayutmwszjxnlfnh9jeen.png",

    "logo": "https://docs.trustwillow.com/[edwardjones]logo.png",
    "logoTransparent": "https://docs.trustwillow.com/[edwardjones]logo.png",
    "logoTransparentWhite": "https://docs.trustwillow.com/[edwardjones]logo.png",
    "logoIcon": "https://docs.trustwillow.com/[edwardjones]logoicon.png",

    "error1": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615061902/j5wbelwjtfg4gjjvoom3.png",
    "error2": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615061948/tdg8knv9isuqiodkl6hx.png",
    "error3": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615061980/uzxpckc4cyd8mhuerllf.png",

    "calSyncBrand": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615065796/bap1uqa5cfa56vwr9tz8.png",

    "checkBoxEmpty": "https://docs.trustwillow.com/[edwardjones]CheckboxEmpty.png",
    "checkBoxChecked": "https://docs.trustwillow.com/[edwardjones]CheckboxChecked.png",

    "backgroundBlur": "https://docs.trustwillow.com/[edwardjones]firmBackground.png",

    "feedbackBackgroundDesktop": "https://docs.trustwillow.com/[edwardjones]helpbackground.png",
    "feedbackBackgroundMobile": "https://docs.trustwillow.com/[edwardjones]helpbackgroundmobile.png",
    "feedbackBackgroundDesktopInverted": "https://docs.trustwillow.com/[edwardjones]helpbackground.png",
    "feedbackBackgroundMobileInverted": "https://docs.trustwillow.com/[edwardjones]helpbackgroundmobile.png",

    // REQUIRED - END //

    //

    // DOMAIN SPECIFIC - START //

    "teamCategoryAccounting": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066562/qhe0k9m810nndhi7rzgv.png",
    "teamCategoryCoaching": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066590/mzd6rk3jfhk90tq94jvw.png",
    "teamCategoryLegal": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066607/uabd3mzkwveugsuax9d3.png",
    "teamCategoryPlanning": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066625/kgewwaflcpfm54lacdmc.png",

    "coachingStyleEmotional": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067604/upkssfnpmabw8ytzbkf0.svg",
    "coachingStyleMilitary": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067652/bohgv5c9z0vzos3zsjgt.svg",
    "coachingStyleNoNonsense": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067673/minz7ya97hhzm1lx2bwb.svg",
    "coachingStyleSpiritual": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067697/akagcyiorgfftlarqzah.svg",
    "coachingStyleUnderServed": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067718/ritld46iwlf7tz5nk7lf.svg",

    "willowTalkIconInfo": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068143/beaieaowozbjqmp6px5w.svg",
    "willowTalkIconLearn": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068171/irvodxwmeeccoctutpl3.svg",
    "willowTalkIconListen": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068190/iuvweq81kcttvtlfaaoi.svg",

    "consumerSignUpIconBalances": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068558/vjnldvirhkzcdv8cx5ek.svg",
    "consumerSignUpIconGoals": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068575/en6cxlxhgdehtcycqzzr.svg",
    "consumerSignUpIconGuidance": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068594/lrfq8o7oihlt8brafvlj.svg",
    "consumerSignUpIconVerify": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068629/fbkfvu7ijbkcs4a8qqoo.svg",
    "consumerSignUpIconBooked": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068613/x54kvub91o1lypxavbzf.svg",

    "coachResourcesWillowLogo": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615071667/nmmozdrepyelums1nlx1.png",

    "willowTeamGabbyAvatar": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633402700/wiyhcppbfbllfzzndmzp.jpg",
    "willowTeamLacyAvatar": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1602605069/chyjbqe6ql6foprctnww.png",

    "emailEmojiCheck": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1619591388/wwnm8tvjbmv2foogepce.png",
    "emailEmojiBook": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1619591370/vgrtjwavld7dc4jcijwj.png",
    "emailEmojiBrain": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1619591354/dzfz6ia99h8dgy6trrfa.png",

    "progressToDosInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260241/t3n4pwoclcvkjwcotmhp.png",
    "progressToDosActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260335/rthkodhg0e8fdoqcsz3o.png",
    "progressGoalsInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260376/lljigr4ygntla9ddwf2n.png",
    "progressGoalsActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260356/xkna34x9i9onpoxfxazd.png",
    "progressBookmarksInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260397/uaghc9uev36fdsoijmie.png",
    "progressBookmarksActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260417/dzp5qnvpnekthvbymygf.png",
    "progressCompletedInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260442/zqnhr4teadihfcedhyzh.png",
    "progressCompletedActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633260461/wed9ze9oldx1fcvsrhby.png",
    "progressFinancesActive": "https://docs.trustwillow.com/progressFinancesActive.png",
    "progressFinancesInactive": "https://docs.trustwillow.com/progressFinancesInactive.png",

    "learnBookmarkContent": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633571358/rrnj2eox7fxd55mfxm01.png",
    "learnUnbookmarkContent": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633571455/sqsmwirh82cse6kp6duu.png",

    "navProgramsActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634917780/y0vhzlvrusa1wh6lc7lw.png",
    "navProgramsInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634917812/ys8nky67azm6w6gw4ay5.png",
    "navLearnActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635008894/x0w88pzdkqlo0cwgnc2i.png",
    "navLearnInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634917858/ihzf1cshqvpujgx2n8fu.png",
    "navHomeActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634917888/hf9wsqtfqfhpctdrhozx.png",
    "navHomeInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634917905/sjujc0lp9iky2tdpzl0k.png",
    "navProgressActive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634917923/usodp4i2uqf1ggsxilkx.png",
    "navProgressInactive": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634917943/nunj01ulgoso36tnewvp.png",
    "navMoreInactive": "https://docs.trustwillow.com/MoreIconSmall.png",
    "navTeamActive": "https://docs.trustwillow.com/TeamIconActiveSmall.png",
    "navTeamInactive": "https://docs.trustwillow.com/TeamIconInactiveSmall.png",

    "navResourcesIconActive": "https://docs.trustwillow.com/resourcesActiveIcon.png",
    "navResourcesIconInactive": "https://docs.trustwillow.com/resourcesInactiveIcon.png",
    "navDashboardIconActive": "https://docs.trustwillow.com/FirmDashboardIconActive.png",
    "navDashboardIconInactive": "https://docs.trustwillow.com/FirmDashboardIconInactive.png",
    "navAppointmentIconActive": "https://docs.trustwillow.com/ApptsIconActive.png",
    "navAppointmentIconInactive": "https://docs.trustwillow.com/ApptsIconInactive.png",
    "navTeamIconActive": "https://docs.trustwillow.com/FirmCoachingIconActive.png",
    "navTeamIconInactive": "https://docs.trustwillow.com/FirmCoachingIconInactive.png",
    "navProgressIconActive": "https://docs.trustwillow.com/FirmUpgradeIconActive.png",
    "navProgressIconInactive": "https://docs.trustwillow.com/FirmUpgradeIconInactive.png",
    "navFinanceIconActive": "https://docs.trustwillow.com/FinancesIconActive.png",
    "navFinanceIconInactive": "https://docs.trustwillow.com/FinancesIconInactive.png",
    "navSelfServeIconActive": "https://docs.trustwillow.com/FirmEmployeesIconActive.png",
    "navSelfServeIconInactive": "https://docs.trustwillow.com/FirmEmployeesIconInactive.png",
    "navProgramsIconActive": "https://docs.trustwillow.com/FirmProgramsIconActive.png",
    "navProgramsIconInactive": "https://docs.trustwillow.com/FirmProgramsIconInactive.png",
    "navCommunityIconActive": "https://docs.trustwillow.com/CommunityIconActive.png",
    "navCommunityIconInactive": "https://docs.trustwillow.com/CommunityIconInactive.png",
    "navHelpIconActive": "https://docs.trustwillow.com/FirmSupportIconActive.png",
    "navHelpIconInactive": "https://docs.trustwillow.com/FirmSupportIconInactive.png",
    "navFeedbackIconActive": "https://docs.trustwillow.com/FeedbackIconActive.png",
    "navFeedbackIconInactive": "https://docs.trustwillow.com/FeedbackIconInactive.png",
    "navSettingsIconActive": "https://docs.trustwillow.com/FirmSettingsIconActive.png",
    "navSettingsIconInactive": "https://docs.trustwillow.com/FirmSettingsIconInactive.png",
    "navFirmSupportIconActive": "https://docs.trustwillow.com/FirmSupportIconActive.png",
    "navFirmSupportIconInactive": "https://docs.trustwillow.com/FirmSupportIconInactive.png",
    "navFirmSettingsIconActive": "https://docs.trustwillow.com/FirmSettingsIconActive.png",
    "navFirmSettingsIconInactive": "https://docs.trustwillow.com/FirmSettingsIconInactive.png",
    "navFirmReferralIconActive": "https://docs.trustwillow.com/FirmReferralIconActive.png",
    "navFirmReferralIconInactive": "https://docs.trustwillow.com/FirmReferralIconInactive.png",
    "navFirmDashboardIconActive": "https://docs.trustwillow.com/FirmDashboardIconActive.png",
    "navFirmDashboardIconInactive": "https://docs.trustwillow.com/FirmDashboardIconInactive.png",
    "navFirmUpgradeIconActive": "https://docs.trustwillow.com/FirmUpgradeIconActive.png",
    "navFirmUpgradeIconInactive": "https://docs.trustwillow.com/FirmUpgradeIconInactive.png",
    "navFirmEmployeesIconActive": "https://docs.trustwillow.com/FirmEmployeesIconActive.png",
    "navFirmEmployeesIconInactive": "https://docs.trustwillow.com/FirmEmployeesIconInactive.png",
    "navFirmIntegrationsIconActive": "https://docs.trustwillow.com/FirmIntegrationIconActive.png",
    "navFirmIntegrationsIconInactive": "https://docs.trustwillow.com/FirmIntegrationIconInactive.png",
    "navFirmProgramsIconActive": "https://docs.trustwillow.com/FirmProgramsIconActive.png",
    "navFirmProgramsIconInactive": "https://docs.trustwillow.com/FirmProgramsIconInactive.png",
    "navFirmCoachingIconActive": "https://docs.trustwillow.com/FirmCoachingIconActive.png",
    "navFirmCoachingIconInactive": "https://docs.trustwillow.com/FirmCoachingIconInactive.png",
    "navFirmAcquisitionIconActive": "https://docs.trustwillow.com/FirmAcquisitionIconActive.png",
    "navFirmAcquisitionIconInactive": "https://docs.trustwillow.com/FirmAcquisitionIconInactive.png",
    "firmBackground": "https://docs.trustwillow.com/[edwardjones]firmBackground.png",
    "firmBackgroundTop": "https://docs.trustwillow.com/[edwardjones]firmBackgroundTop.png",
    "firmAddIcon": "https://docs.trustwillow.com/[edwardjones]firmNewEmployeeIcon.png",
    "firmAddIconOutline": "https://docs.trustwillow.com/FirmNewEmployeeIconOutline.png",
    "firmUploadIcon": "https://docs.trustwillow.com/FirmUploadIcon.png",
    "firmDownloadIcon": "https://docs.trustwillow.com/firmDownloadIcon.png",
    "firmEditIcon": "https://docs.trustwillow.com/FirmEditIcon.png",
    "firmEditIconOutline": "https://docs.trustwillow.com/FirmEditIconOutline.png",
    "firmEditIconOutlineGreen": "https://docs.trustwillow.com/FirmEditIconOutlineGreen.png",
    "firmEditIconOnlyBlack": "https://docs.trustwillow.com/firmEditIconOnlyBlack.png",
    "firmDeleteIcon": "https://docs.trustwillow.com/firmTrashIcon.png",
    "firmXIcon": "https://docs.trustwillow.com/firmXIcon.png",
    "firmSupportRM": "https://docs.trustwillow.com/firmSupportRM.png",
    "firmSupportGeneral": "https://docs.trustwillow.com/firmSupportGeneral.png",
    "firmSupportTechnical": "https://docs.trustwillow.com/firmSupportTechnical.png",
    "firmSupportFAQ": "https://docs.trustwillow.com/firmSupportFAQ.png",
    "firmSupportFeedback": "https://docs.trustwillow.com/firmSupportFeedback.png",
    "firmIconCalendarWhite": "https://docs.trustwillow.com/firmIconCalendarWhite.png",
    "firmIconCECredit": "https://docs.trustwillow.com/[edwardjones]ce_credit_icon.png",





//"https://docs.trustwillow.com/"

    "menuAlarm": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636478996/ng5bfvcm9ntjrzyqztwi.png",
    "menuConversation": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636480012/h9e5uokkrdmq4d1shndh.png",
    "menuMoney": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636480038/eshlhdinz6j9vh7j27mp.png",
    "menuPhoneQuestion": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636480059/fb3tsyhusrczndfjrjmm.png",
    "menuSettings": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636480200/em3iwcqkqzihcixch2fe.png",

    "logoTraditionsBank": "https://docs.trustwillow.com/TB_HerTraditions_Horiz_Color.png",
    "logoTraditionsBankIcon": "https://docs.trustwillow.com/TB_HerTraditions_Horiz_Color_Icon.png",

    // DOMAIN SPECIFIC - END //

    //

    // DEPRECATED - START //

    "teamIconConnect": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066450/bkqwawv86gifdyhfaei9.png",
    "teamIconGuidelines": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066479/lwjbqqpathnjp3rpctqe.png",
    "teamIconVideo": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066498/qr7upesgeobgdgec3zw1.png",
    "teamIconReport": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066523/bgthg5uklxzc8fllsrpk.png",
    "teamIconWoman": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066539/azjjtjtvi8xyxwzusg60.png",

    // DEPRECATED - END //


};

const chooseImages = () => {
    if(window.location.href.includes("principal.trustwillow.com")) {
        return(principal)
    }
    if(window.location.href.includes("jpmorgan.trustwillow.com")) {
        return(principal)
    }
    if(window.location.href.includes("edwardjones.trustwillow.com")) {
        return(edwardjones)
    }
    return(willow)
}

export default chooseImages();