import React from 'react';
import styled from "styled-components";

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {colors} from '../../../common/components/colors';
import {FontHeader16} from "../../../common/components/fonts";

/* Containers */
import TeamPartnerships from "./teampartnerships";
import TeamIntroduce from "./teamintroduce";
import TeamMemberProfile from "./teammemberprofile";
import SharedVaultList from '../../../common/containers/vault/containers/sharedvaultlist';
import TeamNotes from "./teamnotes";

const Section = styled.div`
    padding: 30px 0px 20px 0px;
    width: calc(100% - 0px);
    background-color: ${colors.white};
    @media screen and (max-width: 460px) {
        padding: 20px 10px;
        width: calc(100% - 20px);
    }
`;

const Indent = styled.div`
    padding: 20px 30px;
    background-color: ${colors.border30};
    @media screen and (max-width: 460px) {
        padding: 20px 10px;
        width: calc(100% - 20px);
    }
`;

const TitleSection = styled.div`
    width: 100%;
    margin-bottom: 40px;
    background-color: ${colors.border100};
`;

const Inline = styled.div`
    display: inline-block;
    padding: 10px 25px;
    vertical-align: top;
    margin-right: 20px;
    ${props => props.active !== true} {
        color: ${colors.action100};
    }
    ${props => props.active !== false} {
        color: ${colors.secondary70};
        cursor: pointer;
        &:hover {
            color: ${colors.action100};
        }
    }
    @media screen and (max-width: 460px) {
        display: block;
        margin-right: 0px;
    }
`;

const WhiteBox = styled.div`
    padding: 20px;
    background-color: ${colors.white};
`;

class TeamDetails extends React.Component {
    state={"isLoading": true, "page": "partnerships"};

    componentDidMount() {
        return this.setState({"isLoading": false});
    }

    introduce = async () => {
        this.setState({"page": "introduce"})
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <Section>
                    <TitleSection>
                        {/*<Inline onClick={this.introduce} active={this.state.page === "introduce"}><FontHeader16>Introduce Clients</FontHeader16></Inline>*/}
                        <Inline onClick={() => {this.setState({"page": "partnerships"})}} active={this.state.page === "partnerships"}><FontHeader16>Assigned Clients</FontHeader16></Inline>
                        <Inline onClick={() => {this.setState({"page": "vault"})}} active={this.state.page === "vault"}><FontHeader16>Shared Vault</FontHeader16></Inline>
                        <Inline onClick={() => {this.setState({"page": "profile"})}} active={this.state.page === "profile"}><FontHeader16>Profile</FontHeader16></Inline>
                    </TitleSection>
                    <Indent>
                        {this.state.page === "partnerships" &&
                            <TeamPartnerships coach={this.props.coach} introduce={this.introduce} />
                        }
                        {/*{this.state.page === "introduce" &&*/}
                        {/*    <TeamIntroduce coach={this.props.coach} />*/}
                        {/*}*/}
                        {this.state.page === "profile" &&
                            <TeamMemberProfile coachId={this.props.coach.coachId} />
                        }
                        {this.state.page === "vault" &&
                            <WhiteBox><SharedVaultList /></WhiteBox>
                        }
                    </Indent>
                </Section>
            )
        }
    }
}

export default TeamDetails;
