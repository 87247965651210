import {images} from "../../../common/components/images";

const randomNumber = () => {return Math.floor(Math.random() * 100)+1};

export const devSignupExpertCategories = {
    "expertCategories": [
        {
            "categoryId": 6,
            "categoryName": "Career Coach"
        },
        {
            "categoryId": 7,
            "categoryName": "Interior Designer"
        }
    ]
};

export const devSignupExpertCreate = (data) => ({
    "id": randomNumber(),
    "email": data.email,
    "firstName": data.first,
    "lastName": data.last,
    "phone": data.phone,
    "timeZone": data.timeZone,
    "photo": images.devDataTeamJasonAvatar,
    "bio": '<p>In an age of pandemic, what true value do any of our backgrounds hold? It’s not like we can look at precedents and say, people who have done ABC tend to be really successful at accomplishing XYZ in a pandemic. My expf</p>',
    "whyBecameCoach": 'Test 2',
    "companyName": 'Coaching, LLC',
    "crd": 'test 5',
    "endorsement1": 'Test 1',
    "endorsement2": 'Test 2',
    "endorsement3": null,
    "freeTime": 'Test 7',
    "address1": 'test 3',
    "address2": 'test 6',
    "city": 'Boston',
    "state": 'MA',
    "zipCode": '23443',
    "introVideoUrl": 'https://google.com',
    "facebookUrl": 'https://facebook.com',
    "linkedinUrl": 'https://linkedin.com',
    "twitterUrl": 'https://twitter.com',
    "bookingAvailability": data.bookingAvailability === undefined ? "available" : data.bookingAvailability,
    "screeningCallLength": 30,
    "defaultCallLength": 30,
});
