import React from "react";
import {connect} from 'react-redux';
import styled from "styled-components";

/* Containers */
import CourseSummary from './coursesummary';
import CoursesModule from './coursemodule';
import CoursesVideo from './video';
import CoursesArticle from './article';
import CoursesComplete from './coursecomplete';

/* Middleware */
import {tryGetCourseOne} from '../middleware/courses';

/* Components */
import {LogoLoading} from "../../../common/components/loading";

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

class CoursesFlow extends React.Component {
    static defaultProps = {
        courseId: null,
        backFromArticle: () => {},
        backFromVideo: () => {},
        backFromCourseSummary: () => {},
        backFromCourseModule: () => {},
        backFromCourseComplete: () => {},
        openCourseTask: () => {},
        openCourseComplete: () => {},
        coursePathName: "",
        openMyCoaches: () => {},
        openFindACoach: () => {},
        openViewCoach: () => {},
    }

    state = {
        "isLoading": true,
        "course": null,
        "task": null,
        "showCoursePreview": true,
        "showComplete": false
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.courseOneChanged !== this.props.courseOneChanged) {
            return this.init();
        }
    }

    init = async () => {
        this.setState({
            "course": await this.props.tryGetCourseOne(this.props.courseId),
            "isLoading": false
        });
    };

    openTask = async (task) => {
        await this.setState({"task": task, "showComplete": false, "showCoursePreview": false})
        this.props.openCourseTask(task.taskAssignmentId)
    }

    openNextTask = async () => {
        const tasks = this.state.course.tasks.filter(t => {return(!t.isCompleted)})
        await this.setState({"task": tasks[0], "showComplete": false, "showCoursePreview": false})
        this.props.openCourseTask(tasks[0].taskAssignmentId)
    }

    openSummary = async () => {
        await this.setState({"showCoursePreview": true, "showComplete": false, "task": null})
        this.props.backFromCourseModule()
    }

    startCourseComplete = async () => {
        await this.setState({"showComplete": true})
        this.props.openCourseComplete();
    }

    startBackFromCourseComplete = async () => {
        await this.setState({"showCoursePreview": true, "showComplete": false})
        this.props.backFromCourseComplete();
    }

    render() {
        if(this.state.isLoading) {
            return (<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if(this.state.course.course.isReadingMaterial) {
                if(this.state.course.course.isVideo) {
                    return(<CoursesVideo course={this.state.course} back={this.props.backFromVideo} openMyCoaches={this.props.openMyCoaches} openFindACoach={this.props.openFindACoach} openViewCoach={this.props.openViewCoach}/>)
                } else {
                    return (<CoursesArticle course={this.state.course} back={this.props.backFromArticle} openMyCoaches={this.props.openMyCoaches} openFindACoach={this.props.openFindACoach} openViewCoach={this.props.openViewCoach}/>)
                }
            } else {
                if(this.state.showComplete) {
                    return (<CoursesComplete course={this.state.course} back={this.startBackFromCourseComplete} openNextTask={this.openNextTask}/>)
                } else {
                    if(this.state.showCoursePreview) {
                        return(<CourseSummary course={this.state.course} back={this.props.backFromCourseSummary} openTask={this.openTask}/>)
                    } else {
                        return(<CoursesModule coursePathname={this.props.coursePathname} task={this.state.task} back={this.openSummary} openCourseComplete={this.startCourseComplete}/>)
                    }
                }
            }
        }
    }
}

const mapStateToProps = state => ({
    courseOneChanged: state.iframe.courses.courses.get("courseOneChanged")
})

const mapDispatchToProps = (dispatch) => ({
    tryGetCourseOne: (id) => dispatch(tryGetCourseOne(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(CoursesFlow);
