import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from "styled-components";

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FontTitle18} from "../../../common/components/fonts";

/* Containers */
import FormsFlow from "../../forms/containers/flow";

/* Domain */
import getDomainEnv from '../../../domains/utils';
import { ButtonPrimary, ButtonSecondary } from '../../../common/components/buttons';
const env = getDomainEnv();

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

const Container = styled.div`
    max-width: 768px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 60px auto 0px auto;
`;

const Center = styled.div`
    text-align: center;
`;

const Btn = styled.div`
    display: inline-flex;
    padding: 10px;
`;

class TraditionsMatchingFlow extends React.Component {
    static defaultProps = {
        matchingFormSelected: "matchingFormStandard"
    }

    state={
        "form": [],
        "isLoading": true,
        "step": 1,
        "coach": "",
        "matchingFormSelected": "matchingFormStandard"
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "matchingFormSelected": this.props.matchingFormSelected,
            "isLoading": false
        })
    };

    onReturnForm = async (form) => {
        await this.setState({"form": form});
        await this.setState({"step": 2})
        await this.findMatch();
        await this.setCoachHandle();
    };

    findMatch = async () => {
        const answers0 = await this.state.form[0].elements[0].options.filter(a => {return a.active}).map(a => {return a.id});
        const answers1 = await this.state.form[1].elements[0].options.filter(a => {return a.active}).map(a => {return a.id});
        const answers2 = await this.state.form[2].elements[0].options.filter(a => {return a.active}).map(a => {return a.id});
        const answers3 = await this.state.form[3].elements[0].options.filter(a => {return a.active}).map(a => {return a.id});

        let coaches = ['Paula Birmingham', 'Jennifer Scheffer', 'Allison Bishop', 'Rich Wallner', 'Candice McGarvey', 'Heather Fraser', 'Megan Scipione']

        if (await answers3.includes(1)) { // female coach
            coaches = await coaches.filter(a => {return a !== 'Rich Wallner'})
        }

        if (await answers0.includes(1) || await answers0.includes(2) || await answers0.includes(6)) { // divorce / single parent client
            coaches = await coaches.filter(a => {return a === 'Candice McGarvey' || a === 'Jennifer Scheffer'})
            if (answers2.includes(4)) {
                return this.setState({"coach": "Candice McGarvey", "step": 3})
            } else if (answers2.includes(4)) {
                return this.setState({"coach": "Jennifer Scheffer", "step": 3})
            } else {
                return this.setState({'coach': coaches[Math.floor(Math.random() * coaches.length)], 'step': 3}); // select random from remaining
            }
        } else {
            coaches = await coaches.filter(a => {return a !== 'Jennifer Scheffer' && a !== 'Candice McGarvey'})
        }

        if (await answers0.includes(8)) { // starting out financially
            return this.setState({'coach': 'Paula Birmingham', 'step': 3}); 
        }

        if (await answers2.includes(4) && !answers2.includes(2)) { // emphasizes emotions
            coaches = await coaches.filter(a => {return a === 'Paula Birmingham' || a === 'Heather Fraser' || a === 'Candice McGarvey'})
            if (await answers0.includes(1) || await answers0.includes(2) || await answers0.includes(6)) { // divorce or single parent
                return this.setState({"coach": "Candice McGarvey", "step": 3})
            } else if (await answers2.includes(5)) { // minority community
                return this.setState({'coach': 'Heather Fraser', 'step': 3}); 
            } else if (await answers0.includes(8)) { // starting out financially
                return this.setState({'coach': 'Paula Birmingham', 'step': 3}); 
            } else {
                return this.setState({'coach': coaches[Math.floor(Math.random() * coaches.length)], 'step': 3}); // select random from remaining
            }
        } else if (await answers2.includes(2) && !answers2.includes(2)) { // no nonsense
            coaches = await coaches.filter(a => {return a === 'Jennifer Scheffer' || a === 'Allison Bishop' || a === 'Rich Wallner' || a === 'Megan Scipione'})
            if (await answers0.includes(1) || await answers0.includes(2) || await answers0.includes(6)) { // divorce or single parent
                return this.setState({"coach": "Jennifer Scheffer", "step": 3})
            } else {
                coaches = await coaches.filter(a => {return a !== 'Jennifer Scheffer'});
                return this.setState({'coach': coaches[Math.floor(Math.random() * coaches.length)], 'step': 3}); // select random from remaining
            }
        } else {
            if (await answers2.includes(5)) { // minority community
                return this.setState({'coach': 'Heather Fraser', 'step': 3}); 
            } else if (await answers0.includes(8)) { // starting out financially
                return this.setState({'coach': 'Paula Birmingham', 'step': 3}); 
            } else {
                return this.setState({'coach': coaches[Math.floor(Math.random() * coaches.length)], 'step': 3}); // select random from remaining
            }
        }

    };

    reset = async () => {
        await this.setState({
            "isLoading": true,
            "form": [],
            "step": 1,
            "coach": "",
            "matchingFormSelected": "matchingFormStandard"
        });
        this.init()
    };

    openProfile = async () => {
        window.open("https://"+env.URL.DOMAIN+"/willow-profile/"+this.state.handle, "_blank");
    };

    setCoachHandle = async () => {
        switch(this.state.coach) {
            case 'Paula Birmingham':
                this.setState({'handle': 'paula'});
                break;
            case 'Jennifer Scheffer':
                this.setState({'handle': 'jscheffer'});
                break;
            case 'Allison Bishop':
                this.setState({'handle': 'abishop'});
                break;
            case 'Rich Wallner':
                this.setState({'handle': 'rwallner'});
                break;
            case 'Candice McGarvey':
                this.setState({'handle': 'cmcgarvey'});
                break;
            case 'Heather Fraser':
                this.setState({'handle': 'hfraser'});
                break;
            case 'Megan Scipione':
                this.setState({'handle': 'meganscipione'});
                break;
            default:
                ''
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if (this.state.step === 1) {
                return(
                    <>
                        <Container>
                            <FormsFlow
                                form={env.CONSUMER.SIGN_UP.MATCH_PUBLIC_PAGE.MATCHING_FORMS[this.state.matchingFormSelected]}
                                skipAuth={true}
                                onCompleteFormMethod={"return"}
                                onReturnForm={this.onReturnForm}
                            />
                        </Container>
                    </>
                )
            } else if (this.state.step === 2) {
                return(
                    <>
                        <Container>
                            <Center>
                                <FontTitle18>Determining match...</FontTitle18>
                                <LoadingCenter><LogoLoading /></LoadingCenter>
                            </Center>
                        </Container>
                    </>
                )
            } else if (this.state.step === 3) {
                return(
                    <>
                        <Container>
                            <Center>
                                <FontTitle18>Willow Coach Recommendation: {this.state.coach}</FontTitle18>
                                <Btn onClick={this.reset}><ButtonSecondary canSubmit={false} label={"Restart"}/></Btn>
                                <Btn onClick={this.openProfile}><ButtonPrimary canSubmit={false} label={"Coach Profile"}/></Btn>
                            </Center>
                        </Container>
                    </>
                )
            }
        }
    }

}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TraditionsMatchingFlow));