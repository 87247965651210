import { Map} from 'immutable';

/* Immutable */
const PASSWORD_RESET_EMAIL_SENDING = 'PASSWORD_RESET_EMAIL_SENDING';
const PASSWORD_RESET_TWO_FACTOR_VERIFYING = 'PASSWORD_RESET_TWO_FACTOR_VERIFYING'
const PASSWORD_RESET_STORE_RESET = 'PASSWORD_RESET_STORE_RESET';
const PASSWORD_RESET_SIGNUP_SUCCESS_EMAIL_SENDING = 'PASSWORD_RESET_SIGNUP_SUCCESS_EMAIL_SENDING';

/* Actions */
export const setResetPasswordEmailSending = (status) => ({'type': PASSWORD_RESET_EMAIL_SENDING, 'status': status});
export const setTwoFactorVerifying = (status) => ({'type': PASSWORD_RESET_TWO_FACTOR_VERIFYING, 'status': status});
export const setResetPasswordStoreReset = () => ({'type': PASSWORD_RESET_STORE_RESET});
export const setSignUpSuccessEmailSending = (status) => ({'type': PASSWORD_RESET_SIGNUP_SUCCESS_EMAIL_SENDING, 'status': status});

/* Initial State */
const initialState = Map({
    emailSending: false,
    signupSuccessEmailSending: false,
    verifying: false
});

/* Reducer */

const changePasswordReducer = (state=initialState, action) => {
    switch (action.type) {
        case PASSWORD_RESET_EMAIL_SENDING:
            return state.merge({'emailSending': action.status});
        case PASSWORD_RESET_TWO_FACTOR_VERIFYING:
            return state.merge({'verifying': action.status});
        case PASSWORD_RESET_STORE_RESET:
            return state.merge(initialState);
        case PASSWORD_RESET_SIGNUP_SUCCESS_EMAIL_SENDING:
            return state.merge({'signupSuccessEmailSending': action.status});
        default:
            return state;
    }
};

export default changePasswordReducer;
