import { Map, fromJS, List } from 'immutable';

/* Immutable */

const CHECKOUT_PAYMENT_METHODS = 'CHECKOUT_PAYMENT_METHODS';
const CHECKOUT_PAYMENT_METHODS_FETCHING = 'CHECKOUT_PAYMENT_METHODS_FETCHING';
const CHECKOUT_PRODUCT = 'CHECKOUT_PRODUCT';
const CHECKOUT_PRODUCT_FETCHING = 'CHECKOUT_PRODUCT_FETCHING';
const CHECKOUT_STRIPE_ID = 'CHECKOUT_STRIPE_ID';
const CHECKOUT_STRIPE_ID_FETCHING = 'CHECKOUT_STRIPE_ID_FETCHING';
const CHECKOUT_REGISTER_USER = 'CHECKOUT_REGISTER_USER';
const CHECKOUT_REGISTER_USER_FETCHING = 'CHECKOUT_REGISTER_USER_FETCHING';
const CHECKOUT_PURCHASE_HISTORY = 'CHECKOUT_PURCHASE_HISTORY';
const CHECKOUT_PURCHASE_HISTORY_FETCHING = 'CHECKOUT_PURCHASE_HISTORY_FETCHING';
const CHECKOUT_PURCHASE_FETCHING = 'CHECKOUT_PURCHASE_FETCHING';
const CHECKOUT_REPURCHASE_FETCHING = 'CHECKOUT_REPURCHASE_FETCHING';
const CHECKOUT_PURCHASE_INTENT_FETCHING = 'CHECKOUT_PURCHASE_INTENT_FETCHING';
const CHECKOUT_STORE_RESET = 'CHECKOUT_STORE_RESET';

/* Actions */

export const setCheckoutPaymentMethods = (paymentMethods) => ({"type": CHECKOUT_PAYMENT_METHODS, 'paymentMethods': paymentMethods});
export const setCheckoutPaymentMethodsFetching = (status) => ({"type": CHECKOUT_PAYMENT_METHODS_FETCHING, 'status': status});
export const setCheckoutProduct = (product) => ({"type": CHECKOUT_PRODUCT, 'product': product});
export const setCheckoutProductFetching = (status) => ({"type": CHECKOUT_PRODUCT_FETCHING, 'status': status});
export const setCheckoutStripeId = (stripeId) => ({"type": CHECKOUT_STRIPE_ID, 'stripeId': stripeId});
export const setCheckoutStripeIdFetching = (status) => ({"type": CHECKOUT_STRIPE_ID_FETCHING, 'status': status});
export const setCheckoutRegisterUser = (stripeId) => ({"type": CHECKOUT_REGISTER_USER, 'stripeId': stripeId});
export const setCheckoutRegisterUserFetching = (status) => ({"type": CHECKOUT_REGISTER_USER_FETCHING, 'status': status});
export const setCheckoutPurchaseHistory = (history) => ({"type": CHECKOUT_PURCHASE_HISTORY, 'history': history});
export const setCheckoutPurchaseHistoryFetching = (status) => ({"type": CHECKOUT_PURCHASE_HISTORY_FETCHING, 'status': status});
export const setCheckoutPurchaseFetching = (status) => ({"type": CHECKOUT_PURCHASE_FETCHING, 'status': status});
export const setCheckoutRepurchaseFetching = (status) => ({"type": CHECKOUT_REPURCHASE_FETCHING, 'status': status});
export const setCheckoutPurchaseIntentFetching = (status) => ({"type": CHECKOUT_PURCHASE_INTENT_FETCHING, 'status': status});
export const setCheckoutStoreReset = () => ({"type": CHECKOUT_STORE_RESET});

/* Initial State */

const initialState = Map({
    checkoutPaymentMethods: null,
    checkoutPaymentMethodsFetching: false,
    checkoutProduct: null,
    checkoutProductFetching: false,
    checkoutStripeId: Map(),
    checkoutStripeIdFetching: false,
    checkoutPurchaseHistory: List(),
    checkoutPurchaseHistoryFetching: false,
    checkoutRegisterUserFetching: false,
    checkoutPurchaseFetching: false,
    checkoutRepurchaseFetching: false,
    checkoutPurchaseIntentFetching: false,
});

/* Reducer */

const checkoutReducer = (state=initialState, action) => {
    switch (action.type) {
        case CHECKOUT_PAYMENT_METHODS:
            return state.merge({'checkoutPaymentMethods': fromJS(action.paymentMethods)});
        case CHECKOUT_PAYMENT_METHODS_FETCHING:
            return state.merge({'checkoutPaymentMethodsFetching': action.status});
        case CHECKOUT_PRODUCT:
            return state.merge({'checkoutProduct': fromJS(action.product)});
        case CHECKOUT_PRODUCT_FETCHING:
            return state.merge({'checkoutProductFetching': action.status});
        case CHECKOUT_STRIPE_ID:
            return state.merge({'checkoutStripeId': fromJS(action.stripeId)});
        case CHECKOUT_REGISTER_USER:
            return state.merge({'checkoutStripeId': fromJS(action.stripeId)});
        case CHECKOUT_STRIPE_ID_FETCHING:
            return state.merge({'checkoutStripeIdFetching': action.status});
        case CHECKOUT_PURCHASE_HISTORY:
            return state.merge({'checkoutPurchaseHistory': fromJS(action.history)});
        case CHECKOUT_PURCHASE_HISTORY_FETCHING:
            return state.merge({'checkoutPurchaseHistoryFetching': action.status});
        case CHECKOUT_REGISTER_USER_FETCHING:
            return state.merge({'checkoutRegisterUserFetching': action.status});
        case CHECKOUT_PURCHASE_FETCHING:
            return state.merge({'checkoutPurchaseFetching': action.status});
        case CHECKOUT_REPURCHASE_FETCHING:
            return state.merge({'checkoutRepurchaseFetching': action.status});
        case CHECKOUT_PURCHASE_INTENT_FETCHING:
            return state.merge({'checkoutPurchaseIntentFetching': action.status});
        case CHECKOUT_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default checkoutReducer;
