import { images } from '../../../common/components/images';


export const devConsumerCommunityParentCategories = {
    "parentCategory": {},
    "categories": [
        {
            "id": 1,
            "title": "Career",
            "subtitle": "Something about careers",
            "description": "Test",
            "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089764/kqvetvetvbeuffyetota.png",
            "parentCategoryId": null
        },
        {
            "id": 2,
            "title": "Retirement",
            "subtitle": "Something about finances",
            "description": "Test",
            "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089788/jgum0huzefh5a2zjiznh.png",
            "parentCategoryId": null
        },
        {
            "id": 3,
            "title": "Dating & Marriage",
            "subtitle": "Something about finances",
            "description": "Test",
            "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089807/tceqco11kedlukuz0f9z.png",
            "parentCategoryId": null
        },
        {
            "id": 4,
            "title": "Divorce",
            "subtitle": "Something about finances",
            "description": "Test",
            "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089822/fjkbuzjzwhs10ct7cvll.png",
            "parentCategoryId": null
        },
        {
            "id": 5,
            "title": "Family Building",
            "subtitle": "Something about finances",
            "description": "Test",
            "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089838/t0ykpoigjmrqovjk3iop.png",
            "parentCategoryId": null
        },
        {
            "id": 6,
            "title": "Career",
            "subtitle": "Something about careers",
            "description": "Test",
            "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089764/kqvetvetvbeuffyetota.png",
            "parentCategoryId": null
        },
        {
            "id": 7,
            "title": "Retirement",
            "subtitle": "Something about finances",
            "description": "Test",
            "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089788/jgum0huzefh5a2zjiznh.png",
            "parentCategoryId": null
        },
        {
            "id": 8,
            "title": "Dating & Marriage",
            "subtitle": "Something about finances",
            "description": "Test",
            "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089807/tceqco11kedlukuz0f9z.png",
            "parentCategoryId": null
        },
        {
            "id": 9,
            "title": "Health",
            "subtitle": "Something about finances",
            "description": "Test",
            "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089853/jno2lqxcniwz4r6zolaj.png",
            "parentCategoryId": null
        },
        {
            "id": 10,
            "title": "Grief & Loss",
            "subtitle": "Something about finances",
            "description": "Test",
            "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636738052/qnghe6myev2trzymbjel.jpg",
            "parentCategoryId": null
        },
        {
            "id": 11,
            "title": "Financial",
            "subtitle": "Something about finances",
            "description": "test",
            "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089868/sob4gvhdrpgbk73kcxcg.png",
            "parentCategoryId": null
        }
    ]
};

export const devConsumerPostsFromCategory = [
    {
        "id": 123,
        "title": "Very new to the adult world, and I'm struggling. Hard. Any advice from someone who has been where I am is greatly appreciated",
        "body": "What are some absolute, must do personal finance moves one should make before their 30s?",
        "createdOn": "2021-03-08T05:00:00+00:00",
        "author": {
            "id": 12,
            "fullName": "Milan Jackson"
        }
    },
    {
        "id": 345,
        "title": "I'm turning 30 years old in 4 months. No savings, no retirement. No 401k. What do I do?",
        "body": "Hi everyone. I have a stable security job at the moment. I have no savings, no retirement fund, no 401k, no stocks (don't understand how they work). I need help or resources. I'm turning 30 in 4 months and I have worked for 12 years with next to nothing to show for it. I have no higher schooling or high debt. I have a 2012 Honda that I'm upside down in. Pre-Pandemic it was worth $4000. I still owe 6700$ on the car. It's reliable and gets me around. My car is the only major debt that I currently have.\n\nWhat should I do? What can I do or what resources can I get to save enough for a retirement fund, or a 401k, or a Roth IRA, to ensure I have a future?\n\nThank you.",
        "createdOn": "2021-11-09T05:00:00+00:00",
        "author": { }
    },
    {
        "id": 546,
        "title": "Advice for retirement",
        "body": "Looking for retirement advice.",
        "createdOn": "2022-01-08T05:00:00+00:00",
        "author": {
            "id": 12,
            "fullName": "Barbara Johnson"
        }
    },
    {
        "id": 987,
        "title": "What do you wish you'd done before getting pregnant?",
        "body": "SO and I have decided that we are going to start trying for a baby before I am thirty (I am only twenty-five now). I want to be sure that I am well prepared for this since I have plenty of time. SO and I writing lists of things we want to before even trying. So far our list items seem to fit into three categories:\n\nExperiential-places we want to travel to and things we want to do that would be difficult with a child.\n\nfinancial-things we think we should do to get our finances in order to not be COMPLETELY overwhelmed.\n\nmental/physical health-things we want to do to be sure we are fit to be active parents (and pregnancy goes smoothly), keep our relationship strong, and deal with anxiety and body image issues.\n\nWhat do you wish you had done/are doing before getting pregnant?",
        "createdOn": "2021-09-08T05:00:00+00:00",
        "author": {
            "id": 12,
            "fullName": "Michaela Chiu"
        }
    }
]

export const devConsumerPostComments = {
    "post": {},
    "comments": [
        {
            "body": "As always, saving is powerful but earning more is much more so. Look into investing in yourself so that you can double or triple your income over the next 5-10 years.",
            "author": {
                "id": 12,
                "fullName": "Jonna Smith",
                "profilePhoto": images.devDataTeamGabbyAvatar
            },
            "createdOn": "2022-01-09T05:00:00+00:00"
        },
        {
            "body": "First off, major kudos to you for having the courage to ask questions. There's no shame in that whatsoever. Second, you still have plenty of time if you're disciplined. I highly recommend a Roth IRA or 401k because the money you invest in them is after taxes and therefore the growth and withdrawals are tax free. ",
            "author": {
                "id": 12,
                "fullName": "Mei Lei",
                "profilePhoto": images.devDataCoachJenniferAvatar
            },
            "createdOn": "2022-01-09T05:00:00+00:00"
        },
        {
            "body": "No!",
            "author": {},
            "createdOn": "2022-01-09T05:00:00+00:00"
        }
    ]
}

export const getDevNewConsumerPostComment = (commentContent) => {
    return {
        "body": commentContent.comment,
        "author": commentContent.isAnonymous ? {} : {
            "id": 12,
            "fullName": "Jason Aronson",
            "profilePhoto": images.devDataTeamJasonAvatar
        },
        "createdOn": "2022-01-09T05:00:00+00:00"
    };
}