import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {ArrowLeftOutlined} from '@ant-design/icons';

import { colors } from '../../../common/components/colors';
import { FontSubtitle21, FontBody16 } from '../../../common/components/fonts';

// const NavTopNoLinks = styled.div`
//     height: calc(120px - 38px);
//     margin-bottom: 30px;
//     border-bottom: 1px solid ${colors.border100};
//     @media screen and (max-width: 1440px) {
//         height: auto;
//         border-bottom: none;
//     }
// `;
const NavTopNoLinks = styled.div`
    border-bottom: 1px solid ${colors.border100};
`;
const BackStyled = styled.div`
    margin-bottom: 10px;
    display: block;
    height: 22px;
    @media screen and (max-width: 1088px) {
        margin-bottom: 5px;
    }
`;
const BackButton = styled(Link)`
    cursor: pointer;
`;
const BackIcon = styled.div`
    display: inline-block;
    span {
        font-size: 14px;
        padding: 4px 0px;
        color: ${colors.primary30};
        display: inline-block;
        vertical-align: top;
        margin-right: 5px;
    }
`;
const BackLabel = styled.div`
    display: inline-block;
    vertical-align: top;
    color: ${colors.primary30};
`;

export const NavTertiaryNoLinks = ({ title }) => (
    <NavTopNoLinks>
        <BackStyled>
            <BackButton to={"/meetings"}>
                <BackIcon><ArrowLeftOutlined /></BackIcon>
                <BackLabel><FontBody16>Back</FontBody16></BackLabel>
            </BackButton>
        </BackStyled>
        {/*<FontSubtitle21>{title}</FontSubtitle21>*/}
    </NavTopNoLinks>
);
