import React from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

/* Components */
import {colors} from '../../../common/components/colors';
import {LogoLoading, LogoLoadingSmall} from "../../../common/components/loading";
import {FontTitle18, FontHeader16, FontHeader21} from "../../../common/components/fonts";
import {Card, Icon, Body, Title, Action, Inline, ActionLink, BottomBorder5, Content} from '../components/affirmations';
import { SmileOutlined } from '@ant-design/icons';

/* Components */
const Section = styled.div`
    padding: 20px;
    background-color: ${colors.border30};
    max-height: 100%;
    @media screen and (max-width: 460px) {
        padding: 5px;
    }
`;

export const InlineAction = styled.div`
    display: inline-block;
    vertical-align: top;
`;

export const DateItem = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-top: 3px;
    text-align: left;
    width: 150px;
    @media screen and (max-width: 1440px) {
        margin-top: 8px;
    }
    @media screen and (max-width: 1088px) {
        margin-top: 3px;
    }
`;

const Arrow = styled.div`
    display: inline;
    color: ${colors.secondary100};
    text-align: left;
    vertical-align: middle;
`;

class Welcome extends React.Component {
    state={
        "isLoading": true,
    };

    componentDidMount() {
        return this.setState({'isLoading': false})
    }

    componentWillUnmount() {
        
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <Section>
                    <Card>
                        <Icon><SmileOutlined/></Icon>
                            <Body>
                                <div>
                                    <FontTitle18>{"Welcome to Your New Homepage!"}</FontTitle18>
                                </div>
                            <Content>
                                <BottomBorder5>
                                    <FontHeader16>{""}</FontHeader16>
                                </BottomBorder5>
                                <br />
                                <br />
                                <Arrow>
                                    <FontHeader21>
                                        Welcome!
                                    </FontHeader21>
                                    <br />
                                    <FontHeader21>
                                        You now have quick and easy access to all of your most important coaching information. 
                                        You'll be able to see the latest resources from Willow, including blog posts, checklists, and guides to enhance your practice,
                                        daily affirmations and quotes, announcements from Willow and your partner institutions, and upcoming meeting reminders.
                                    </FontHeader21>
                                </Arrow>
                                <br />
                            </Content>
                            </Body>
                        </Card>
                </Section>
            )
        }
    }
}

const mapStateToProps = state => ({
    isWMC: state.common.wmc.get("isWMC"),
    user: state.common.user
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Welcome));