import React from "react";
import styled from "styled-components";

/* Components */
import {colors} from "../../../common/components/colors";
import {FontSubtitle28, FontBody16, FontHeader14} from "../../../common/components/fonts";

export const SettingsCard = styled.div`
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    background-color: ${colors.white};
    border-radius: 7px;
    border: 1px solid ${colors.primary30};
    padding: 30px;
    color: ${colors.primary100};
    max-width: ${props => props.maxWidth};
    margin: 0px auto 40px auto;
    @media screen and (max-width: 748px) {
        margin: 40px auto 0px auto;
    }
    @media screen and (max-width: 460px) {
        margin: 20px auto 0px auto;
        padding: 30px 20px;
    }
    ${props => props.active !== true} {
        &:hover {
            -webkit-box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }
    
`;
export const Extra54px = styled.div`
    height: 24px;
    width: 100%;
`;
export const WriteToUsBtn = styled.div`
    min-width: 138px;
`;
export const ChevronLeftRight = styled.img`
    padding: 3.5px 7.5px 5.5px 7.5px;
    vertical-align: top;
    display: block;
    object-fit: contain;
`;
export const SmallCard = styled.div`
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    background-color: ${colors.white};
    border-radius: 7px;
    border: 1px solid ${colors.primary30};
    padding: 30px;
    color: ${colors.primary100};
    width: 200px;
    margin: 0px 10px 40px 10px;
    @media screen and (max-width: 748px) {
        margin: 40px auto 0px auto;
    }
    @media screen and (max-width: 460px) {
        margin: 20px auto 0px auto;
        padding: 30px 20px;
    }
    ${props => props.active !== true} {
        &:hover {
            -webkit-box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }
    display: inline-flex;
    justify-content: center;
    align-items: center;
    
`;

export const SmallCardRow = styled.div`
    background-color: ${colors.backgroundColor1};
    border-radius: ${props => props.even ? '0px 20px 0px 20px' : '20px 0px 20px 0px'};
    padding: 13px;
    color: ${colors.primary100};
    width: calc(100% - 24px);
    margin: 0px 0px 15px 0px;
    display: inline-flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    column-gap: 40px;
`;

export const SmallCardRowImage = styled.img`
    height: calc(80% - 200px);
    width: 10%;
    vertical-align: top;
    display: inline-block;
    object-fit: cover;
    border-radius: 10px;
`;

export const Bar = styled.div`
    width: 100%;
    height: 1px;
    border-bottom: 1px solid ${colors.secondary30};
`;

export const Center = styled.div`
    text-align: center;
`;

export const Text = styled.div`
    margin: 10px 0px;
    
    ${props => props.active !== true} {
        overflow: revert;
        display: revert;
        -webkit-line-clamp: revert;
        -webkit-box-orient: revert;
    }
    ${props => props.active !== false} {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
`;

export const Image = styled.img`
    height: 150px;
    width: 100%;
    vertical-align: top;
    display: inline-block;
    margin: 10px 0px;
    object-fit: contain;
`;

export const Icon = styled.div`
    display: inline-block;
    vertical-align: top;
    ${props => props.active !== true} {
        color: ${colors.white};
    }
    ${props => props.active !== false} {
        color: ${colors.action100};
    }
    & svg {
        width: 16px;
        height: 16px;
        margin-top: 3px;
    }
    @media screen and (max-width: 460px) {
        display: none;
    }

`;

export const InlineBlock = styled.div`
    display: inline-block;
`;

export const SpaceBetween = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
    color: ${colors.secondary100};
    ${props => props.direction !== "column"} {
        flex-direction: column;
    }
    ${props => props.direction !== "row"} {

    }
`;

export const TopBox = styled.div`
    cursor: pointer;
`;

export const OpenLink = styled.div`
    cursor: pointer;
`;

export const BackTop = styled.div`
    color: ${colors.secondary70};
    cursor: pointer;
    position: relative;
    text-align: left;
    display: block;
    width: fit-content;
`;

export const LoadingCenter = styled.div`
    margin: 60px auto;
`;

export const BulletArea = styled.div`
    margin: 0px 20px;
`;

export const Bullet = styled.div`
    padding: 10px;
`;

export const FAQSection = styled.div`
    border-top: 1px solid ${colors.primary30};
    padding: 20px;
`;

export const MovingCard = styled.div`
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    display: inline-block;
    background-color: ${colors.white};
    border-radius: 7px;
    border: 1px solid ${colors.primary30};
    padding: 30px;
    color: ${colors.primary100};
    max-width: ${props => props.maxWidth};
    min-height: 300px;
    vertical-align: middle;
    width: 80%;
    margin: 0px 10px;
    @media screen and (max-width: 748px) {
        min-height: revert;
        margin: 0px 5px;
    }
    @media screen and (max-width: 460px) {
        padding: 30px 10px;
    }
    ${props => props.active !== true} {
        &:hover {
            -webkit-box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }
    
`;

export const LeftMovingCard = styled.div`
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    filter: brightness(0.7);
    display: inline-block;
    background-color: ${colors.white};
    border: 1px solid ${colors.primary30};
    border-left: revert;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    color: ${colors.primary100};
    height: 300px;
    vertical-align: middle;
    @media screen and (max-width: 748px) {
        min-height: revert;
    }
    ${props => props.active !== true} {
        &:hover {
            -webkit-box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }
    ${props => props.full !== false} {
        width: 5%;
    }
    ${props => props.full !== true} {
        width: 3%;
    }
    
`;

export const RightMovingCard = styled.div`
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    display: inline-block;
    filter: brightness(0.7);
    background-color: ${colors.white};
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    border: 1px solid ${colors.primary30};
    border-right: revert;
    color: ${colors.primary100};
    width: 5%;
    height: 300px;
    vertical-align: middle;
    @media screen and (max-width: 748px) {
        min-height: revert;
    }
    ${props => props.active !== true} {
        &:hover {
            -webkit-box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }
    ${props => props.full !== false} {
        width: 5%;
    }
    ${props => props.full !== true} {
        width: 3%;
    }
    
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 0px auto;
    @media screen and (max-width: 800px) {
        max-width: 450px;
    }
`;

export const ArticleBox = styled.div`
    width: 100%;
    border-radius: 25px;
`;

export const AllContent = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
`;

export const FeaturedMaterial = styled.div`
    flex-display: flex;
    flex-direction: column;
    width: 45%;
    justify-content: start;
    padding: 10px 0px 0px 20px;
    @media screen and (max-width: 800px) {
        width: 95%;
        justify-content: center;
    }
`;

export const FeaturedMaterialDivider = styled.div`
    border-bottom: 3px solid ${colors.action100}; 
    margin: 37px 0px;
    width: 95%;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    float: left;
    @media screen and (max-width: 800px) {
        display: none
    }
`;

export const HeaderMobile = styled.div`
    display: none;
    @media screen and (max-width: 800px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const PageTitle = styled(FontSubtitle28)`
    text-align: center;
`;

export const PageSubtitle = styled(FontBody16)`
    margin-bottom: 10px;
`;

export const BackSection = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const Back = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
`;
export const Olive = styled.div`
    color: ${colors.secondary100};
`;
export const GutterTitle = styled.div`
    margin: 0px 25px 25px 25px;
`;

export const CTA = styled.div`
    margin: 60px 25px 0px 25px;
`;

export const Tags = styled.div`
    display: flex;
    color: white;
    flex-direction: row;
    justify-content: start;
    align-items: start; 
    margin: 10px 0px;
    flex-wrap: wrap;
    gap: 30px;
    @media screen and (max-width: 800px) {
        justify-content: center;
        margin: 10px auto;
    }
`;

export const TagsMobile = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end; 
    margin: 10px 0;
    flex-wrap: wrap;
    gap: 30px;
`;

export const Tag = styled(FontBody16)`
    cursor: pointer;
    width: fit-content;
    background-color: #e6e6e6;
    padding: 10px 20px;
    border-radius: 20px;
    align-items: end;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    @media screen and (max-width: 800px) {
        width: 100%;
    }
`;
export const BookmarkIcon = styled.img`
    height: 24px;
    width: auto;
    vertical-align: top;
`;

export const CenterHighlighted = styled.div`
    width: 90%;
    @media screen and (max-width: 800px) {
        max-width: 450px;
    }
`;

export const CenterContent = styled.div`
    flex-display: flex;
    flex-direction: row;
    align-items: start;
    max-width: 50%;
    margin: 0px auto;
    @media screen and (max-width: 748px) {
        max-width: 100%;
        min-width: 60%;
    }
`;

export const SmallFeatured = styled.div`
    color: ${colors.primary100};
    width: 100%;
    ${props => props.active !== true} {
        &:hover {
            -webkit-box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
`;

export const SmallFeaturedRow = styled.div`
    color: ${colors.primary100};
    width: fit-content;
    margin: 0px 5px 27px 0px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    column-gap: 13px;
`;

export const SmallFeaturedCol = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
`;


export const SmallImageContainer = styled.div`
    height: 6vw;
    width: 8vw;
    margin-right: 5px;
    & > img {
        object-fit: cover;
    }
`;
export const SmallFeaturedRowImage = styled.img`
    height: 6vw;
    width: 8vw;
    vertical-align: top;
    display: inline-block;
    object-fit: cover;
    margin-top: 10px;
`;

export const HoverLink = styled.div`
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export const UnderlinedLink = styled.div`
    cursor: pointer;
    text-decoration: underline;
`;

export const LatestSection = styled.div`
    max-width: 40%;
    margin: 10px auto;
    @media screen and (max-width: 800px) {
        max-width: 100%;
        width: 100%;
    }
`;

export const HighlightedTextContainer = styled.div`
    padding: 20px;
    background-color: ${colors.backgroundColor1};
    border-radius: 0px 20px 0px 20px;
`;

export const NavMenuDesktop = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    margin: 15px auto;
    width: 100%;
    @media screen and (max-width: 800px) {
        flex-wrap: wrap;
    }
`;

export const NavMenuItem = styled.div`
    height: fit-content;
    width: fit-content;
    position: relative;
    margin-top: 15px;
    &:hover {
        cursor: pointer;
        color: ${props => props.hoverColor};;
    }
`;

export const NavMenuItemText = styled(FontHeader14)`
    background-color: ${props => props.background};
    border-radius: 30px;
`;

export const HoverDropdown = styled.div`
    background-color: white;
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    width: fit-content;
    position: absolute;
    z-index: 5;
`;

export const DropdownItem = styled.div`
    padding: 12px 25px 12px 25px;
    position: relative;
    color: black;
    &:hover {
        color: ${colors.action100}
    }
`;

export const DropdownDivider = styled.div`
    border-bottom: 1px solid ${colors.action100}; 
    margin: auto;
    width: 80%;
`;

export const NavMenuDivider = styled.div`
    border-left: 1px solid ${colors.action100};
    height: 18px;
`;

export const NavMenuMobile = styled.div`
    display: none;
    @media screen and (max-width: 800px) {
        display: block;
        background-color: ${colors.white};
        padding: 20px 40px;
        right: 0;
        overflow-y: auto;
        position: absolute;
        z-index: 21;
        border: 1px dashed ${colors.primary100};
        width: calc(100% - 80px);
    }
`;

export const MobileIcon = styled.div`
    display: none;
    position: relative;
    @media screen and (max-width: 800px) {
        display: block;
        text-align: right;
        font-size: 30px;
        cursor: pointer;
    }
`;

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`