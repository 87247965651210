import { combineReducers } from 'redux';

/* Common */
import settingsReducers from './store/settings';

const settingsAllReducers = combineReducers({
    settings: settingsReducers,
});

export default settingsAllReducers;
