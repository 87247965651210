import { Map, List, fromJS } from 'immutable';

/* Immutable */
const GROUP_PROGRAM_MY_SUBSCRIPTIONS = 'GROUP_PROGRAM_MY_SUBSCRIPTIONS';
const GROUP_PROGRAM_SET_SELECTED_PROGRAM_ID = 'GROUP_PROGRAM_SET_SELECTED_PROGRAM_ID';

/* Actions */
export const setMySubscribedGroupPrograms = (myPrograms) => ({'type': GROUP_PROGRAM_MY_SUBSCRIPTIONS, 'myPrograms': myPrograms});
export const setSelectedProgramId = (selectedProgramId) => ({'type': GROUP_PROGRAM_SET_SELECTED_PROGRAM_ID, 'selectedProgramId': selectedProgramId});

/* Initial State */
const initialState = Map({
    mySubscribedGroupPrograms: List(),
    selectedProgramId: null
});

/* Reducer */
const groupsReducer = (state=initialState, action) => {
    switch (action.type) {
        case GROUP_PROGRAM_MY_SUBSCRIPTIONS:
            return state.merge({
                'mySubscribedGroupPrograms': fromJS(action.myPrograms)
            });
        case GROUP_PROGRAM_SET_SELECTED_PROGRAM_ID:
            return state.merge({
                'selectedProgramId': action.selectedProgramId
            });
        default:
            return state;
    }
};

export default groupsReducer;
