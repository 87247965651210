import React from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

/* Components */
import {colors} from '../../../common/components/colors';
import {FontBody18, FontSubtitle28} from '../../../common/components/fonts';
import {images} from "../../../common/components/images";
import {Facebook, Globe, Instagram, Linkedin, Rss, Twitter, Youtube, Edit} from "react-feather";
import {LogoLoading} from "../../../common/components/loading";



const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const RowLeadership = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
`;

const RowItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 75px;
  flex-wrap: wrap;
  `;

  const SchoolItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  flex-wrap: wrap;
  margin-top: 100px;
    
  `;

const RowInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 100px;
  column-gap: 200px;
  @media screen and (max-width: 1180px) {
    flex-wrap: wrap;
  }
`;

const SchedulerDiv = styled.div`
min-width: 500px;

`;
 
const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  column-gap: 50px;
  justify-content: flex-start;
`;

const ColTeam = styled.div`
    text-align: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  column-gap: 50px;
  justify-content: center;
`;

const ProfileEditContainer = styled.div`
  background: #39495026;
  padding: 25px 25px 50px 25px;
  display: flex;
  flex-direction: column;
  gap: 100px;
`;
const ProfileEditBox = styled.div`
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  background: ${colors.white};
  padding: 20px 40px;
`;
const EditButtonStyles = styled.div`
    & > div {
      color: ${colors.primary70} !important;
      border-color: ${colors.primary70} !important;
    }
`;
const ProfileHeaderContainer = styled.div`
  background: ${colors.primary30};
  padding: 25px 25px;
  border-radius: 30px 30px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const ProfileNameBox = styled.div`
  border-radius: 0 25px 0 0;
  background: ${colors.white};
  padding: 20px 40px 20px 90px;
  max-width: calc(600px - 130px);
  width: calc(100% - 130px);
  position: relative;
  margin-left: 75px;
  min-height: calc(150px - 40px);
`;
const Options = styled.div`
    
`;
const Name = styled(FontSubtitle28)`
  letter-spacing: 2.8px;
`;

const SectionTitle = styled(FontBody18)`
    font-weight: bold;
`;

const SectionHeader = styled.div`
display: flex;
gap: 20px;
flex-direction: column;
justify-content: center;
min-width: 150px;
`;

const SeeMore = styled(FontBody18)`
    display: flex;
    justify-content: flex-end;
    text-decoration: underline;
    cursor: pointer;
`;

const Circle = styled.div`
    width: 150px;
    height: 150px;
    border-radius: 150px;
    background-color: ${colors.primary100};
    position: absolute;
    top: 0;
    left: -75px;
`;

const Badge = styled.div`
    width: 150px;
    min-width: 150px;
    height: 150px;
    border-radius: 150px;
    background-image: url(${images.willowBadge});
    background-size: cover;
    background-repeat: no-repeat;
`;

const CareIcon = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: fit-content;
    margin: 0 auto;
    min-width: 100px;
    height: 100px;
    background-image: url(${images.careIcon});
    background-size: cover;
    background-repeat: no-repeat;
`;

const EmployeesIcon = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: fit-content;
    margin: 0 auto; 
    min-width: 100px;
    height: 100px;
    background-image: url(${images.employeesIcon});
    background-size: cover;
    background-repeat: no-repeat;
`;

const CirclePhoto = styled.div`
    width: 150px;
    min-width: 150px;
    height: 150px;
    border-radius: 150px;
    background-color: ${colors.primary100};
`;

const Tag = styled.span`
    margin-right: 15px;
`;
const SocialIcon = styled.div`
    display: inline-block;
    padding: 10px 5px 0 0;
    color: ${colors.secondary70};
    cursor: pointer;
    font-size: 20px;
    vertical-align: top;
    & svg {
        width: 20px;
        height: 20px;
    }
`;
const EditIcon = styled.div`
    color: ${colors.primary70};
    cursor: pointer;
    font-size: 35px;
    height: 35px;
    & svg {
        width: 35px;
        height: 35px;
    }
`;

const TeamText = styled(FontBody18)`
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 5px;
    column-gap: 50px;
    justify-content: center;
`;

const AboutMeText = styled(FontBody18)`
    min-width: 150px;
`;


class AdvisorProfileNameCard extends React.Component {
    state = {
        p: {
            website: "https://google.com",
            blogUrl: "https://google.com",
            instagramUrl: "https://google.com",
            facebookUrl: "https://google.com",
            twitterUrl: "https://google.com",
            linkedinUrl: "https://google.com",
            youtubeUrl: "https://google.com",
        }
        
    }


    
    render() {  
      if(this.state.isLoading) {
        return(<LoadingCenter><LogoLoading /></LoadingCenter>)
    } else {
        return (          
            <>
                    <ProfileNameBox>
                        <Circle />
                        <Row>
                            <Col>
                                <Name spaced={false}>NAME OF ADVISOR</Name>
                                <FontBody18>title</FontBody18>
                                <div>
                                    {(this.state.p.website !== "" && this.state.p.website !== null) && <Tag><a target={"_blank"} href={this.state.p.website}><SocialIcon><Globe /></SocialIcon></a></Tag>}
                                    {(this.state.p.blogUrl !== "" && this.state.p.blogUrl !== null) && <Tag><a target={"_blank"} href={this.state.p.blogUrl}><SocialIcon><Rss /></SocialIcon></a></Tag>}
                                    {(this.state.p.instagramUrl !== "" && this.state.p.instagramUrl !== null) && <Tag><a target={"_blank"} href={this.state.p.instagramUrl}><SocialIcon><Instagram /></SocialIcon></a></Tag>}
                                    {(this.state.p.facebookUrl !== "" && this.state.p.facebookUrl !== null) && <Tag><a target={"_blank"} href={this.state.p.facebookUrl}><SocialIcon><Facebook /></SocialIcon></a></Tag>}
                                    {(this.state.p.twitterUrl !== "" && this.state.p.twitterUrl !== null) && <Tag><a target={"_blank"} href={this.state.p.twitterUrl}><SocialIcon><Twitter /></SocialIcon></a></Tag>}
                                    {(this.state.p.linkedinUrl !== "" && this.state.p.linkedinUrl !== null) && <Tag><a target={"_blank"} href={this.state.p.linkedinUrl}><SocialIcon><Linkedin /></SocialIcon></a></Tag>}
                                    {(this.state.p.youtubeUrl !== "" && this.state.p.youtubeUrl !== null) && <Tag><a target={"_blank"} href={this.state.p.youtubeUrl}><SocialIcon><Youtube /></SocialIcon></a></Tag>}
                                </div>
                            </Col>
                            <EditIcon onClick={() => {this.props.history.push("/my-growth/profile/edit-intro")}}><Edit /></EditIcon>
                        </Row>
                    </ProfileNameBox>
                   </>
        )
                  
}
    
}
}



export default withRouter(AdvisorProfileNameCard);