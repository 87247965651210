import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment/moment';
import styled from 'styled-components';

/* Components */
import Appointment from '../components/appointment';
import {FontTitle24, FontTitle18} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";
import {LogoLoading} from "../../../common/components/loading";
import {ButtonPrimary, ButtonSecondary, ButtonTertiary} from '../../../common/components/buttons';

/* Middleware */
import {tryGetAllAppointments, tryJoinWaitlist} from '../middleware/appointment';

const Container = styled.div`
    width: 100%;
    padding: 0px;
    @media screen and (max-width: 748px) {
        padding: 0px;
        width: 100%;
    }
    margin: 0px auto;
`;
const Modal = styled.div`
    position: absolute;
    top: 320px;
    text-align: center;
    width: calc(100% - 80px);
    margin: 0px auto;
    max-width: 450px;
    left: 0;
    right: 0;
    z-index: 21;
    padding: 20px 40px;
    background-color: ${colors.white};
    color: ${colors.primary100}; 
    border-radius: 6px;
    -webkit-box-shadow: 0px 15px 70px 0px rgba(0, 0, 0, 0.7);
    box-shadow: 0px 15px 70px 0px rgba(0, 0, 0, 0.7);
    @media screen and (max-width: 640px) {
        top: 200px;
        max-width: 185px !important;
    }
`;
const LoadingCenter = styled.div`
    margin: 60px auto;
`;
const ButtonCentered = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    width: fit-content;
`;

class AppointmentContainer extends React.Component {
    static defaultProps = {
        title: "",
        subtitle: "",
        coachId: null,
        coachFirst: null,
        coachLast: null,
        coachImg: null,
        onSelectTime: () => {},
        meetingLength: null,
        error: null
    };

    state = {
        selectedDay: null,
        selectedTime: null,
        availableDays: [],
        availableTimesToday: [],
        sessions: [],
        isLoading: true,
        coachId: null,
        coachFirst: null,
        coachLast: null,
        coachImg: null,
        meetingLength: null,
        error: null
    };

    componentDidMount() {
        if(this.props.error !== null) {
            this.showError(this.props.error);
        }
        return this.tryGetAllAvailableTimes();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.error !== this.props.error && this.props.error !== null) {
            this.showError(this.props.error);
        }
    }

    tryGetAllAvailableTimes = async () => {
        await this.props.tryGetAllAppointments(this.props.coachId, this.props.meetingLength);
        const appointments = await this.props.appointments.get("sessions").map(a => {
            return new Date(moment(a).toString())
        }).toJS();
        const times = await this.tryGetAvailableTimesToday(appointments, appointments[0]);
        const days = await this.tryGetAvailableDays(appointments);
        console.log(times)
        console.log(days)
        await this.setState({
            'sessions': appointments,
            'selectedDay': appointments[0],
            'selectedTime': null,
            'availableTimesToday': times,
            'availableDays': days,
            "coachId": (this.props.coachId === null || this.props.coachId === undefined || this.props.coachId === "") ? null : this.props.coachId,
            "coachFirst": (this.props.coachFirst === null || this.props.coachFirst === undefined || this.props.coachFirst === "") ? null : this.props.coachFirst,
            "coachLast": (this.props.coachLast === null || this.props.coachLast === undefined || this.props.coachLast === "") ? null : this.props.coachLast,
            "coachImg": (this.props.coachImg === null || this.props.coachImg === undefined || this.props.coachImg === "") ? null : this.props.coachImg,
            "onSelectTime": this.props.onSelectTime,
            "meetingLength": this.props.meetingLength,
            "isLoading": false
        });
    };

    tryGetAvailableTimesToday = (appointments, selectedDay) => {
        return appointments.filter(s => {
            return moment(s).format("YYYY-MM-DD") === moment(selectedDay).format("YYYY-MM-DD");
        });
    };

    tryGetAvailableDays = (sessions) => {
        return [...new Set(sessions.map(s => {
            return moment(s).format("YYYY-MM-DD")
        }))]
    };

    handleColorDays = (days) => {
        if(this.state.availableDays.includes(moment(days).format("YYYY-MM-DD"))) {
            return 'show'
        } else {
            return 'hide'
        }
    };

    handleHideTimes = (time) => {
        if(this.state.sessions.includes(time)) {
            return 'show'
        } else {
            return 'hide'
        }
    };

    handleChangeDay = async date => {
        await this.setState({
            'selectedDay': date,
            'availableTimesToday': await this.tryGetAvailableTimesToday(this.state.sessions, date)
        });
    };

    handleChangeTime = async selected => {
        const date = moment(this.state.selectedDay).format().substring(0, 10);
        const time = moment(selected).format().substring(10);
        const makeDateTime = date.concat(time);
        await this.setState({'selectedTime': new Date(makeDateTime)});
        await this.props.onSelectTime(this.state);
    };

    componentWillUnmount() {
        clearInterval(this.timeout);
    }

    showError = (error) => {
        this.setState({'error': error});
        this.timeout = setTimeout(() => {
            this.setState({'error': null});
        }, 2000);
        return this.tryGetAllAvailableTimes();
    };

    render() {
        if(this.state.isLoading) {
            return (<LoadingCenter><LogoLoading/></LoadingCenter>)
        } else {
            return(
                <Container>
                    <Appointment
                        allCoaches={!(this.state.coachFirst === null || this.state.coachLast === null || this.state.coachId === null || this.state.coachImg === null)}
                        expertName={this.state.coachFirst}
                        title={this.props.title}
                        selectedDay={this.state.selectedDay}
                        minDate={new Date()}
                        handleChangeDay={this.handleChangeDay}
                        handleColorDays={this.handleColorDays}
                        selectedTime={this.state.selectedTime}
                        handleChangeTime={this.handleChangeTime}
                        availableTimesToday={this.state.availableTimesToday}
                        handleHideTimes={this.handleHideTimes}
                        expertPhoto={this.state.coachImg}
                        subtitle={this.props.subtitle}
                        meetingLength={this.state.meetingLength}
                    />
                    {this.state.sessions.length === 0 &&
                        <Modal>
                            <FontTitle24>Sorry, no times available...</FontTitle24>
                            <br />
                            <ButtonCentered onClick={() => {this.props.tryJoinWaitlist(this.state.coachId)}}>
                                <ButtonPrimary canSubmit={false} label={"Join the waitlist"} />
                            </ButtonCentered>
                        </Modal>
                    }
                    {this.state.error !== null &&
                        <Modal>
                            <FontTitle18>{this.state.error}</FontTitle18>
                        </Modal>
                    }
                </Container>
            )
        }
    }
}

const mapStateToProps = state => ({
    appointments: state.iframe.appointments.appointment.get("appointmentsData"),
});

const mapDispatchToProps = dispatch => ({
    tryGetAllAppointments: (coachId, minutes) => dispatch(tryGetAllAppointments(coachId, minutes)),
    tryJoinWaitlist: (coachId) => dispatch(tryJoinWaitlist(coachId))
});

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentContainer);


