import React from 'react';
import styled from 'styled-components';

/* Components */
import {FontSubtitle28, FontTitle24, FontTitle21, FontBody18} from '../../../common/components/fonts';
import {colors} from '../../../common/components/colors';
import {ButtonPrimary} from '../../../common/components/buttons';
import {DropdownInput} from "../../../common/components/inputs";

const Title = styled(FontSubtitle28)`
    padding: 30px 25px;
    background-color: ${colors.primary100};
    color: ${colors.white};
    border-radius: 30px 0px 30px 0px;
    width: calc(100% - 90px);
    margin: 40px auto 0px auto;
    @media screen and (max-width: 768px) {
        margin: 20px auto 0px auto;
    }
    @media screen and (max-width: 460px) {
        width: calc(100% - 50px);
        padding: 30px 25px;
    }
    @media screen and (max-width: 460px) {
        margin: 10px auto 0px auto;
    }
    text-align: center;
`;

const Price = styled.div`
    background-color: ${colors.backgroundColor1};
    border-radius: 0px 0px 30px 0px;
    padding: 15px 25px 15px 25px;
    position: relative;
    text-align: center;
    width: calc(100% - 42px);
    left: -4px;
    top: -4px;
    color: ${colors.secondary100};
    &::before {
        content: "";
        position: absolute;
        background-color: transparent;
        top: -90px;
        height: 90px;
        width: 90px;
        right: 0px;
        border-top-right-radius: 30px;
        box-shadow: 0 -30px 0 0 ${colors.backgroundColor1};
        transform: rotate(180deg);
        transform: scaleY(-1);
    }
`;

const Box = styled.div`
    border: 4px solid ${colors.backgroundColor1};
    border-radius: 0px 0px 30px 30px;
    margin: 0px auto 50px auto;
    width: calc(100% - 48px);
    @media screen and (max-width: 460px) {
        width: calc(100% - 8px);
    }
`;
const Content = styled.div`
    width: calc(100% - 80px);
    padding: 40px 40px 25px 40px;
    @media screen and (max-width: 460px) {
        width: calc(100% - 40px);
        padding: 20px 20px 15px 20px;
    }
`;
const PriceText = styled(FontTitle24)`
    position: relative;
    z-index: 1;
`;
const FitContent = styled.div`
    width: fit-content;
    @media screen and (max-width: 460px) {
        margin: 25px auto 0px auto;
    }
    margin: 40px auto 0px auto;
    & > div {
        min-width: 180px;
        text-align: center;
    }
`;
const DropdownStyling = styled.div`
    display: inline-block;
    & > div {
        margin: 0px;
        width: 75px;
    }
    & .Dropdown-control {
        background-color: ${colors.backgroundColor6};
        border-radius: 30px;
        padding: 4px 20px;
        color: white;
        border: none;
    }
    & .Dropdown-placeholder.is-selected {
        color: ${colors.white};
        font-weight: bold;
        font-size: 18px;
    }
    & .Dropdown-menu {
        background-color: ${colors.backgroundColor6};
        color: white;
        font-weight: bold;
        border-radius: 6px;
    }
    & .Dropdown-option:hover {
        color: ${colors.white};
    }
    & .Dropdown-option {
        font-size: 18px;
    }
`;

class ConsumerShopWillowListing extends React.Component {
    static defaultProps = {
        quantity: null,
        useCheckoutFunction: () => {},
        pricePerSession: 150,
        submitLabel: "CHECKOUT",
        enableDiscount: false
    }

    state={
        "quantity": "1",
        "quantityOptions": [],
        "totalCost": 0,
        "isLoading": true,
        "price": 0,
        "discount": 0
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.setState({
            "quantityOptions": await Promise.all([...Array(50)].map((a, idx) => {
                return ({"value": (idx+1), "label": (idx+1).toString()})
            })),
        });
        const initQuantity = {"value": this.props.quantity === null ? 1 : this.props.quantity};
        await this.changeQuantity(initQuantity);
        await this.setState({
            "isLoading": false
        });
    };

    changeQuantity = async (e) => {
        const newQuantity = Math.max(1, e.value);
        let price = this.props.pricePerSession;
        if(newQuantity > 5 && newQuantity <= 10 && this.props.enableDiscount) {
            price = this.props.pricePerSession * (1-(1/3/10)); // 3.33% discount per session
        } else if(newQuantity > 10 && this.props.enableDiscount) {
            price = this.props.pricePerSession * (1-(2/3/10)); // 6.66% discount per session
        }
        await this.setState({
            "quantity": newQuantity.toString(),
            "totalCost": price*newQuantity,
            "price": price,
            "discount": (((price - this.props.pricePerSession) / this.props.pricePerSession) * 100 * -1).toFixed(1)
        });
    };

    purchase = () => this.props.useCheckoutFunction("willow_session", parseInt(this.state.quantity));

    render() {
        if(this.state.isLoading) {
            return null
        } else {
            return(
                <>
                    <Title>WILLOW FINANCIAL LIFE COACHING</Title>
                    <Box>
                        <Price>
                            <PriceText>${this.state.price.toFixed(0)}/appointment</PriceText>
                            {this.state.discount > 0 && <FontBody18>{this.state.discount}% Discount</FontBody18>}
                        </Price>
                        <Content>
                            <FontTitle21>What's included:</FontTitle21>
                            <FontBody18>
                                <ul>
                                    <li>
                                        <DropdownStyling>
                                            <DropdownInput
                                                title={null}
                                                options={this.state.quantityOptions}
                                                id={'quantityWillowSessions'}
                                                onChange={this.changeQuantity}
                                                placeholder={'1'}
                                                value={this.state.quantity}
                                            />
                                        </DropdownStyling>
                                        &nbsp;&nbsp;45-minute appointment{parseInt(this.state.quantity) === 1 ? "" : "s"}
                                    </li>
                                    <li>Text message support</li>
                                </ul>
                            </FontBody18>
                            <FontTitle21>Popular schedules:</FontTitle21>
                            <FontBody18>
                                <ul>
                                    <li>1 appointment/month for 6 months</li>
                                    <li>2 appointments/month for 3 months</li>
                                </ul>
                            </FontBody18>
                            <FontTitle21>Total: ${this.state.totalCost.toFixed(0)}</FontTitle21>
                            <FitContent onClick={this.purchase}>
                                <ButtonPrimary canSubmit={false} label={this.props.submitLabel} />
                            </FitContent>
                        </Content>
                    </Box>
                </>
            )
        }
    }
}

export default ConsumerShopWillowListing;
