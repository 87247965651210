import React from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FontSubtitle18, FontTitle28} from '../../../common/components/fonts';
import {colors} from "../../../common/components/colors";
import {images} from "../../../common/components/images";
import { ChevronUp, ChevronDown } from 'react-feather';
import CoachingCategoriesHeader from '../components/headerpage';

/* Middleware */
import {tryGetCoachingSubCategories, tryGetCoachingParentCategories} from '../middleware/coaching';

/* Store */
import {setCareCoachingSelected, setCareSubCoachingSelected} from '../store/coaching';

/* Utils */
import {trackViewGetCareCoachParentJourneys, trackViewGetCareCoachSubJourneys} from '../../../common/utils/tracking';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const PageFull = styled.div`
    height: 100%;
    width: 100%;
    min-height: 100vh;
    background-image: url(${images.backgroundBlur});
    background-size: cover;
    background-repeat: no-repeat;
`;

const ContainerCategories = styled.div`
    position: relative;
    width: 100%;
    display: flex;
	flex-direction: column;
    flex-wrap: wrap;
    gap: 40px;
    align-content: space-between;
    @media screen and (min-width: 748px) {
        gap: 2%;
        ${props => props.isOpen !== true} {
            height: 1560px;
        }
        ${props => props.isOpen !== false} {
            height: calc(1300px - 401px);
        }
        &::before, &::after {
            content: "";
            flex-basis: 100%;
            width: 0;
            order: 2;
        }
  }
`

const CategoryCard = styled.div`
    width: 100%;
    border-radius: 25px;
    z-index: 1;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    vertical-align: top;
    @media screen and (min-width: 748px) {
        width: 48%;
        &:nth-child(2n+1) { order: 1; }
        &:nth-child(2n) { order: 2; }
    }

`;

const FlexRowImage = styled.div`
    width: calc(100% - 60px);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 30px;
    color: ${colors.white};
    justify-content: space-between;
    height: 182px;
    top: 0;
    position: absolute;
    @media screen and (max-width: 800px) {
        height: 130px;
    }
    z-index: 1;
`;

export const Image = styled.img`
    height: 182px;
    width: 100%;
    vertical-align: top;
    display: inline-block;
    object-fit: cover;
    border-radius: 25px 25px 25px 25px;
    filter: drop-shadow(0px 7px 10px rgba(0, 0, 0, 0.25));
    @media screen and (max-width: 800px) {
        height: 130px;
    }
`;

const NavOptions = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: -1;
    & > div:first-child {
        margin-top: -30px;
    }
    b:not(:last-of-type) > div {
        border-bottom: 2px solid ${colors.border70};
    }
    & > div {
        color: ${colors.primary100};
        cursor: pointer;
        border-radius: 0 0 25px 25px;
        background-color: ${colors.backgroundColor1};
        padding: 55px 30px 25px 30px;
        margin-top: -35px;
        z-index: 3;
    }
    b:last-of-type > div {
        align-items: flex-start;
        border-radius: 25px 25px 0px 0px;
        min-height: 150px;
        position: fixed;
        left: 0;
        right: 0;
    }
`;
const NavMenuItem = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border: 2px solid white;
    border-top: 0px solid white;
    margin-bottom: 5px;
`;

const ContainerCentered = styled.div`
    width: calc(100% - 120px);
    padding: 60px 60px 120px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 210px);
    align-items: center;
    @media screen and (max-width: 748px) {
        width: calc(100% - 60px);
        padding: 60px 30px 120px;
    }
    @media screen and (max-width: 460px) {
        width: calc(100% - 40px);
        padding: 60px 20px 80px;
    }
`;

class CoachingCategories extends React.Component {
    state = {"isLoading": true, "options": [], "subcategories": [], "isOpen": null};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "options": await this.props.tryGetCoachingParentCategories(),
            "isLoading": false
        })
        this.props.trackViewGetCareCoachParentJourneys();
    };

    getSubcategories = async (a) => {
        if (this.state.isOpen === a.id) {
            this.setState({"subcategories": await this.props.tryGetCoachingSubCategories(a.id), "isOpen": null});
        } else {
            this.setState({"subcategories": await this.props.tryGetCoachingSubCategories(a.id), "isOpen": a.id});
            this.props.setCareCoachingSelected(a)
        }
    }

    viewCoaches = async (b) => {
        if (b !== undefined) {
            await this.props.setCareSubCoachingSelected(b);
            const expertCategoryId = env.CONSUMER.CARE.CONVERT_COURSE_ID_TO_EXPERT_ID[b.id];
            this.props.history.push("/my-team/find/"+expertCategoryId);
        }
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <PageFull>
                    <CoachingCategoriesHeader
                        backUrl={"/my-team"}
                        title={"FIND A COACH"}
                        subTitle={"Choose a journey and book a 15-minute introductory appointment with a Willow certified financial life coach"}
                    />
                    <ContainerCentered>
                        <ContainerCategories isOpen={this.state.isOpen !== null}>
                        {this.state.options.map((a, aIndex) => {
                            return(
                                <CategoryCard key={aIndex}>
                                    <Image src={a.photoUrl} alt={""}/>
                                    <FlexRowImage onClick={() => {this.getSubcategories(a)}}>
                                        <FontTitle28>{a.title}</FontTitle28>
                                        {this.state.isOpen === a.id ? <ChevronUp /> : <ChevronDown />}
                                    </FlexRowImage>
                                    {this.state.isOpen === a.id &&
                                        <NavOptions>
                                            {this.state.subcategories.map((b, bIndex) => (
                                                <NavMenuItem
                                                    key={bIndex}
                                                    style={{zIndex: '-1' + bIndex}}
                                                    onClick={() => {this.viewCoaches(b)}}
                                                >
                                                    <FontSubtitle18>{b.title}</FontSubtitle18>
                                                </NavMenuItem>
                                            ))}
                                        </NavOptions>
                                    }
                                </CategoryCard>
                            )
                        })}
                        </ContainerCategories>
                    </ContainerCentered>
                </PageFull>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    setCareCoachingSelected: (category) => dispatch(setCareCoachingSelected(category)),
    setCareSubCoachingSelected: (category) => dispatch(setCareSubCoachingSelected(category)),
    tryGetCoachingParentCategories: () => dispatch(tryGetCoachingParentCategories()),
    tryGetCoachingSubCategories: (parentId) => dispatch(tryGetCoachingSubCategories(parentId)),
    trackViewGetCareCoachParentJourneys: () => dispatch(trackViewGetCareCoachParentJourneys()),
    trackViewGetCareCoachSubJourneys: (parentJourneyDetails) => dispatch(trackViewGetCareCoachSubJourneys(parentJourneyDetails)),
});

export default connect(null, mapDispatchToProps)(withRouter(CoachingCategories));