import React from 'react';
import {withRouter} from 'react-router-dom';

import NavSecondaryTop from "../../navbars/containers/secondarytop";

import AvailabilityRecurringStatus from '../recurring/containers/recurringstatus';
import AvailabilityAppointments from '../scheduler/page';
import AvailabilityPage from "../../availability/page";

const availabilityTabs = [
    {
        title: "Calendar Sync",
        label: "Calendar Sync",
        href: "/settings/availability/calendar",
        alwaysActiveForSubRoutes: false
    },
    {
        title: "Appointments",
        label: "Appointments",
        href: "/settings/availability/appointments",
        alwaysActiveForSubRoutes: true
    },
    {
        title: 'Calendar',
        label: 'Calendar',
        href: '/settings/availability/calendar/set',
        alwaysActiveForSubRoutes: true
    }
];

class SettingsAvailability extends React.Component {
   render() {
       return(
            <>
                <NavSecondaryTop title={"Availability"} desc={"Manage your appointments and calendar sync settings"} tabs={availabilityTabs} />
                {this.props.location.pathname === "/settings/availability/calendar" &&
                    <AvailabilityRecurringStatus />
                }
                {(this.props.location.pathname === "/settings/availability/appointments" ||
                    this.props.location.pathname === "/settings/availability/appointments/create" ||
                    this.props.location.pathname === "/settings/availability/appointments/edit") &&
                    <AvailabilityAppointments />
                }
                {(this.props.location.pathname === '/settings/availability/calendar/set') &&
                    <AvailabilityPage />
                }
            </>
       )
    }
}

export default withRouter(SettingsAvailability);
