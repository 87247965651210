import React from 'react';
import styled from "styled-components";

import {colors} from '../../../common/components/colors';

export const Right = styled.div`
    width: calc(100% - 60px);
    padding: 10px 30px;
    border-top: 1px solid ${colors.secondary30};
    border-bottom: 1px solid ${colors.secondary30};
    background-color: ${colors.white};
    text-align: right;
`;
export const Button = styled.div`
    width: fit-content;
    ${props => props.canRegister !== true} {
        cursor: pointer;
    }
    ${props => props.canRegister !== false} {
        cursor: default;
    }
    & > div {
        width: 260px;
        text-align: center;
    }
    margin: 0px 0px 0px auto;
    @media screen and (max-width: 1220px) {
        margin: 0px auto 0px auto;
    }
    @media screen and (max-width: 1088px) {
        margin: 0px 0px 0px auto;
    }
    @media screen and (max-width: 800px) {
        margin: 0px auto 0px auto;
    }
    @media screen and (max-width: 640px) {
        & > div {
            width: 235px;
            text-align: center;
        }
    }
    @media screen and (max-width: 460px) {
        margin: 0px;
        width: calc(100% - 47px);
        & > div {
            width: 100%;
            text-align: center;
        }
    }
`;
export const Cost = styled.div`
    display: none;
    @media screen and (max-width: 1220px) {
        display: block;
        color: ${colors.primary100};
        text-align: center;
        width: 100%;
        padding: 0px 0px 5px 0px;
    }
    @media screen and (max-width: 1088px) {
        display: none;
    }
    @media screen and (max-width: 800px) {
        display: block;
    }
`;