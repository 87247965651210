import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';

/* Components */
import {DropdownInput, TextInput, PhoneInput, SearchInputUncontrolledDisplay} from "../../common/components/inputs";
import {colors} from "../../common/components/colors";
import {images} from '../../common/components/images'
import {FontTitle18, FontTitle32To27} from "../../common/components/fonts";
import {InlineBlock, SaveSection, Width, SubmitInputWithValidation} from "./components/signupexpert";

/* Middleware */
import {tryCreateUserExpert, tryGetCoachCategories} from './middleware/signupexpert';

/* Utils */
import {timeZoneOptions} from '../../common/utils/timezone2';

const Centered = styled.div`
    text-align: center;
`;

const Padding = styled.div`
    padding-top: 15px;
`;

const LeftTitle = styled.div`
    vertical-align: middle;
    display: inline-block;
    z-index: 2;
    padding-left: 10px;
    @media screen and (max-width: 640px) {
        display: block;
        width: 100%;
    }
`;
const RightTitle = styled.div`
    vertical-align: middle;
    text-align: center;
    padding: 5px 0px;
    @media screen and (max-width: 640px) {
        display: block;
        width: 100%;
        text-align: left;
        margin: 5px 0px;
        padding: 5px 10px;
    }
`;

const TitleSection = styled.div`
    padding-bottom: 15px;
    border-bottom: 1px solid ${colors.primary30};
    margin-bottom: 30px;
`;

const Left = styled.div`
    width: 100%;
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 640px) {
        width: 100%;
    }
`;

const whoOptions = (who) => {
    return who.map(w => {
        return({
            value: {
                categoryId: w.categoryId
            },
            label: w.categoryName
        })
    });
};

class PrivateExpertCreateUser extends React.Component {
    state = {
        first: '', firstValid: true,
        last: '', lastValid: true,
        phone: '', phoneValid: true,
        email: '', emailValid: true,
        expertCategoryId: '', expertCategoryIdValid: true, allCategories: [], newCategoryLabel: "",
        timeZone: '',
        isSaving: false,
        isSaved: false,
        error: '',
        isChanged: false,
        isValid: true
    };

    componentDidMount() {
        return this.init()
    }
    componentWillUnmount() {}

    init = async () => {
        await this.setState({
            "allCategories": await this.props.tryGetCoachCategories(),
        });
    };

    handleChangeFirst = async e => {
        this.setState({'first': e.target.value});
        await this.setState({'firstValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeLast = async e => {
        this.setState({'last': e.target.value});
        await this.setState({'lastValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeExpertCategoryId = async e => {
        this.setState({'expertCategoryId': e.value.categoryId});
        this.setState({'newCategoryLabel': e.label});
        await this.setState({'expertCategoryIdValid': true});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeEmail = async e => {
        this.setState({'email': e.target.value});
        await this.setState({'emailValid': validator.isEmail(e.target.value)});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangePhone = async e => {
        this.setState({'phone': e.target.value.replace('(', '').replace(')', '').replace(' ', '').replace('-', '')});
        await this.setState({'phoneValid': validator.isMobilePhone(e.target.value, 'en-US')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeTimeZone = async e => {
        await this.setState({'timeZone': e.value});
        this.setState({'isChanged': true});
        this.checkValid();
    };

    checkValid = () => {
        if(this.state.firstValid && this.state.lastValid /* && this.state.phoneValid */ && this.state.emailValid && this.state.timeZone !== "" && this.state.expertCategoryIdValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            this.setState({'isSaving': true, 'error': ''});
            await this.props.tryCreateUserExpert(this.state);
            if(this.props.error !== "") {
                this.setState({'error': this.props.error, 'isSaving': false, 'isSaved': true, isChanged: false});
            } else {
                this.setState({'isSaving': false, 'isSaved': true, isChanged: false});
            }
        }
    };

    render() {
        return(
            <>
                <Padding>
                    <LeftTitle><img src={images.logo} height="40" width="200" alt={""}/></LeftTitle>
                    <RightTitle>
                        <TitleSection>
                            <Left>
                                <FontTitle32To27>Create Expert</FontTitle32To27>
                            </Left>
                        </TitleSection>
                    </RightTitle>
                </Padding>
                <form method={"post"} onSubmit={this.handleSubmit}>
                    <Centered>
                        {this.state.isSaved
                            ?
                            <>
                                {this.state.error === '' && <FontTitle18>Created!</FontTitle18>}
                                {this.state.error !== '' && <FontTitle18>{this.state.error}</FontTitle18>}
                            </>
                            :
                            <>
                                <Width>
                                    <TextInput
                                        title={"First Name*"}
                                        valid={this.state.firstValid}
                                        warning={"Required"}
                                        id={"first"}
                                        onChange={this.handleChangeFirst}
                                        placeholder={"First Name"}
                                        value={this.state.first}
                                    />
                                    <TextInput
                                        title={"Last Name*"}
                                        valid={this.state.lastValid}
                                        warning={"Required"}
                                        id={"last"}
                                        onChange={this.handleChangeLast}
                                        placeholder={"Last Name"}
                                        value={this.state.last}
                                    />
                                    <PhoneInput
                                        title={"Mobile Phone"}
                                        valid={this.state.phoneValid}
                                        warning={"Enter a valid mobile phone"}
                                        id={"phone"}
                                        onChange={this.handleChangePhone}
                                        placeholder={'(###) ###-####'}
                                        value={this.state.phone}
                                        options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}}
                                    />
                                    <TextInput
                                        title={"Email*"}
                                        valid={this.state.emailValid}
                                        warning={"Enter a valid email address"}
                                        id={"email"}
                                        onChange={this.handleChangeEmail}
                                        placeholder={"Email"}
                                        value={this.state.email}
                                    />
                                    <DropdownInput
                                        title={"Time Zone*"}
                                        options={timeZoneOptions}
                                        id={'timeZone'}
                                        onChange={this.handleChangeTimeZone}
                                        placeholder={'Time Zone'}
                                        value={this.state.timeZone}
                                    />
                                    <SearchInputUncontrolledDisplay
                                        title={"Expert Category*"}
                                        options={whoOptions(this.state.allCategories)}
                                        id={'expertCategoryId'}
                                        onChange={this.handleChangeExpertCategoryId}
                                        placeholder={'Select Category'}
                                        value={this.state.newCategoryLabel}
                                    />
                                </Width>
                                <SaveSection>
                                    <Centered>
                                        <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                    </Centered>
                                </SaveSection>
                            </>
                        }
                    </Centered>
                </form>
            </>
        )
    }
}

const mapStateToProps = state => ({
    error: state.iframe.signupExperts.signupExpert.get("signupExpertError")
});

const mapDispatchToProps = dispatch => ({
    tryCreateUserExpert: (data) => dispatch(tryCreateUserExpert(data)),
    tryGetCoachCategories: () => dispatch(tryGetCoachCategories())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrivateExpertCreateUser));
