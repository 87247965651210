import React from 'react';
import {connect} from 'react-redux';
import {fromJS} from 'immutable';
import styled from "styled-components";
import moment from 'moment/moment';

/* Containers */
import Availability from './availability';
import SignupFlow from '../../signup/containers/flow';
import Confirm from './confirm';
import Events from './events';

import IframeStoreProducts from "./products";
import IframeStoreEmailer from "../../shop/containers/emailer";
import Success from '../../success/containers/success';

/* Middleware */
import {tryPostCreateSessionAppointment} from "../middleware/scheduler";
import {tryGetEventsAll} from "../middleware/events";

/* Store */
import {setScheduleSessionCreatingError} from "../store/scheduler";

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {CloseOutlined} from "@ant-design/icons";

/* Utility */
import {adjForDST} from '../../../common/utils/timezone';
import {timeZoneOptionsByRegion} from "../../../common/utils/timezone2";

const LoadingCenter = styled.div`
    margin: 60px auto;
`;
const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;
const Subtitle = styled.div`
    max-width: 80%;
    margin: 0px auto;
`;

const trimPhone = (phone) => {
    return phone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
};

class SchedulerFlow extends React.Component {
    static defaultProps = {
        userId: null, // scheduleId
        userFirstName: null, // scheduleFirstName
        userLastName: null, // scheduleLastName
        userPhotoUrl: null, // schedulePhoto
        purchaseHistoryId: null, // associate meeting with purchase
        isWillowSession: false, // general session
        eventCloseBtn: false, // isPreview
        eventOnClose: () => {},
        eventSelectedId: null, // scheduleEvent
        eventCustomTitle: "", // Not used yet
        eventCustomDescription: "", // Not used yet
        eventCustomMinutes: null, // scheduleDefaultEventLength
        availabilityCalendarOnly: false,
        availabilityCalendarOnlySelectTime: () => {},
        availabilityCloseBtn: false,
        availabilityOnClose: () => {},
        signupShowLoginBtn: true,
        signupOnClose: () => {},
        confirmCloseBtn: true,
        confirmCloseBtnLabel: <><Inline><CloseOutlined /></Inline>&nbsp;Close</>,
        confirmOnClose: () => {location.reload()},
        confirmOnScheduled: () => {},
        showArrowClose: false,
        useNewDesign: false,
        allowTimeToBeSelected: true,
        isAdvisor: false,
        isAdvisorCertVetting: false,
    };

    state={
        "step": 1,
        "selectedTime": null,
        "firstName": "",
        "lastName": "",
        "email": "",
        "phone": "",
        "timeZone": "",
        "purchaseHistoryId": null,
        "meetingLength": 0,
        "eventTitle": "",
        "eventDescription": "",
        "availabilityBackBtn": true,
        "signUpHasAccount": false,
        "signUpError": null,
        "isLoading": true,
        "loginShowUserExists": false,
        "showProducts": false,
        "showEmailer": false
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.eventsAll !== this.props.eventsAll) {
            return this.update();
        }
    }

    init = async () => {
        if(this.props.userId !== null) {
            console.log("Scheduler: "+1);
            await this.props.tryGetEventsAll(this.props.userId);
        }
        return this.update();
    };

    update = async () => {
        if(this.props.eventSelectedId !== null) {
            console.log("Scheduler: "+2);
            const checkEvents = this.props.eventsAll.filter(f => {
                return(f.get("id").toString() === this.props.eventSelectedId.toString())
            });
            const eventIdExists = checkEvents.size === 1;
            if(eventIdExists) {
                await this.setState({
                    "meetingLength": checkEvents.get(0).get("sessionLength"),
                    "eventTitle": checkEvents.get(0).get("title"),
                    "eventDescription": checkEvents.get(0).get("description"),
                    "step": 2,
                    "availabilityBackBtn": false,
                    "purchaseHistoryId": this.props.purchaseHistoryId,
                    "isLoading": false
                })
            }
        } else if(this.props.eventCustomMinutes !== null) {
            console.log("Scheduler: "+3);
            await this.setState({
                "meetingLength": this.props.eventCustomMinutes,
                "eventTitle": this.props.eventCustomTitle === undefined ? "" : this.props.eventCustomTitle,
                "eventDescription": this.props.eventCustomDescription === undefined ? "" : this.props.eventCustomDescription,
                "step": 2,
                "availabilityBackBtn": false,
                "purchaseHistoryId": this.props.purchaseHistoryId,
                "isLoading": false
            })
        } else {
            console.log("Scheduler: "+4);
            this.setState({
                "purchaseHistoryId": this.props.purchaseHistoryId,
                "isLoading": false
            })
        }
    };

    changeShowProducts = async (status) => {
        this.setState({"showProducts": status});
        console.log("click")
    };

    changeShowEmailer = async (status) => {
        this.setState({"showEmailer": status});
    };

    setIsLoading = async (status) => {
        await this.setState({"isLoading": status})
    };

    setStep = async (step) => {
        await this.setState({"step": step});
    };

    onSelectTime = async (data) => {
        console.log("Scheduler: "+5);
        console.log('before adjforDST data', data);
        let tzone = (this.props.user.get("timeZone") === null || this.props.user.get("timeZone") === undefined) ? timeZoneOptionsByRegion(Intl.DateTimeFormat().resolvedOptions().timeZone) : this.props.user.get("timeZone")
        let selectedTime = adjForDST(data.selectedTime, tzone);
        console.log('after adjForDST', selectedTime);
        await this.props.setScheduleSessionCreatingError(null);
        await this.setState({"selectedTime": selectedTime});
        if(this.props.allowTimeToBeSelected) {
            if(this.props.availabilityCalendarOnly) {
                this.props.availabilityCalendarOnlySelectTime(selectedTime)
            } else {
                this.setStep(3); // signup
                // this.signupClose(); // Turn this one on and setStep(3) off to edit styling of the appointment scheduled page
            }
        }
    };

    selectEvent = async (event) => {
        console.log("Scheduler: "+30);
        const eventAdj = fromJS(event);
        this.setState({
            "meetingLength": eventAdj.get("sessionLength"),
            "eventTitle": eventAdj.get("title"),
            "eventDescription": eventAdj.get("description"),
            "step": 2
        })
    };

    signupClose = async () => {
        console.log("Scheduler: "+27);
        await this.setIsLoading(true);
        await this.props.signupOnClose(this.state);
        let description = ""
        if(this.props.isAdvisor) {
            if(this.props.isAdvisorCertVetting) {
                description = "advisorCertVetting"
            } else {
                description = "advisorSession"
            }
        } else {
            description = "consumerSession"
        }
        let topic = ""
        if (window.location.pathname.includes('get-care/journey/providers')) {
            topic = this.props.category.get("title") + ": " +  this.props.subcategory.get("title")
        }
        console.log(topic)
        const session = await this.props.tryPostCreateSessionAppointment(this.state.selectedTime, this.state.meetingLength, this.props.userId, this.state.purchaseHistoryId, this.props.isWillowSession, description, topic);
        if(this.props.availabilityError !== null) {
            console.log("Scheduler: "+28);
            await this.setStep(2); // availability
            await this.setIsLoading(false);
        } else {
            console.log("Scheduler: "+29);
            await this.props.confirmOnScheduled(session);
            await this.setStep(4); // confirm
            await this.setIsLoading(false);
        }
    }

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if(this.state.step === 1) {
                return(
                    <>
                        <Events
                            selectEvent={this.selectEvent}
                            firstName={this.props.userFirstName}
                            lastName={this.props.userLastName}
                            photo={this.props.userPhotoUrl}
                            eventCloseBtn={this.props.eventCloseBtn}
                            eventOnClose={this.props.eventOnClose}
                            changeShowProducts={this.changeShowProducts}
                            changeShowEmailer={this.changeShowEmailer}
                        />
                        {this.state.showProducts &&
                            <>
                                <IframeStoreProducts changeShowProducts={this.changeShowProducts} profile={this.props.profile} />
                            </>
                        }
                        {this.state.showEmailer &&
                            <IframeStoreEmailer changeShowEmailer={this.changeShowEmailer} profile={this.props.profile} />
                        }
                    </>
                )
            } else if(this.state.step === 2) {
                return(
                    <Availability
                        userId={this.props.userId}
                        onSelectTime={this.onSelectTime}
                        userFirstName={this.props.userFirstName}
                        userLastName={this.props.userLastName}
                        userPhotoUrl={this.props.userPhotoUrl}
                        meetingLength={this.state.meetingLength}
                        meetingTitle={this.state.eventTitle}
                        meetingDescription={this.state.eventDescription}
                        availabilityCalendarOnly={this.props.availabilityCalendarOnly}
                        availabilityBackBtn={this.state.availabilityBackBtn}
                        availabilityOnBack={() => {return this.setStep(1)}}
                        availabilityCloseBtn={this.props.availabilityCloseBtn}
                        availabilityOnClose={this.props.availabilityOnClose}
                        availabilityError={this.props.availabilityError}
                        showArrowClose={this.props.showArrowClose}
                        useNewDesign={this.props.useNewDesign}
                        confirmOnClose={this.props.confirmOnClose}
                        allowTimeToBeSelected={this.props.allowTimeToBeSelected}
                    />
                )
            } else if(this.state.step === 3) {
                return (
                    <SignupFlow
                        back={() => {return this.setStep(2)}}
                        signupClose={() => {return this.signupClose()}}
                        signupShowLoginBtn={this.props.signupShowLoginBtn}
                    />
                )
            } else if(this.state.step === 4) {
                return(
                    this.props.useNewDesign ? 
                    <Success
                        back={this.props.confirmOnClose}
                        title={'APPOINTMENT SCHEDULED'}
                        subtitle={<Subtitle>You are confirmed with {this.props.userFirstName} on {moment(this.state.selectedTime).format("LL")} at {moment(this.state.selectedTime).format("LT")}</Subtitle>}
                        // subtitle={`${moment(this.state.selectedTime).format("LLLL")} (${this.state.meetingLength}-minutes)`}
                        buttonLabel={'Close'}
                    /> : 
                    <Confirm
                        confirmCloseBtn={this.props.confirmCloseBtn}
                        confirmCloseBtnLabel={this.props.confirmCloseBtnLabel}
                        confirmOnClose={this.props.confirmOnClose}
                        selectedTime={this.state.selectedTime}
                        meetingLength={this.state.meetingLength}
                    />
                )
            } else {
                return null
            }
            
        }
    }

}

const mapStateToProps = state => ({
    iframeAuthUpdateUserError: state.iframe.scheduler.auth.get("iframeAuthUpdateUserError"),
    iframeAuthCheckHasAccountError: state.iframe.scheduler.auth.get("iframeAuthCheckHasAccountError"),
    availabilityError: state.iframe.scheduler.schedule.get("scheduleSessionCreatingError"),
    eventsAll: state.iframe.scheduler.events.get("eventsAll"),
    user: state.common.user,
    profile: state.iframe.scheduler.profile.get("profile"),
    category: state.consumer.care.coaching.get("coachingOptionSelected"),
    subcategory: state.consumer.care.coaching.get("coachingSubCategorySelected")
});

const mapDispatchToProps = dispatch => ({
    tryGetEventsAll: (id) => dispatch(tryGetEventsAll(id)),
    tryPostCreateSessionAppointment: (start, meetingLength, scheduleId, purchaseHistoryId, isWillowSession, description, topic) => dispatch(tryPostCreateSessionAppointment(start, meetingLength, scheduleId, purchaseHistoryId, isWillowSession, description, topic)),
    setScheduleSessionCreatingError: (error) => dispatch(setScheduleSessionCreatingError(error))
});

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerFlow);
