import React  from "react";
import styled from "styled-components";
import Cleave from "cleave.js/dist/cleave-react-node";

import {colors} from "../../../common/components/colors";
import {InputSectionNoMargin} from "../../../common/components/inputs";

const Input = styled.div`
    & input {
        background-color: ${colors.border70};
        border: 1px solid ${colors.primary30};
        color: ${colors.primary100};
        border-radius: 4px;
        width: calc(100% - 40px);
        padding: 10px 20px;
        font-family: GraphicWebRegular;
        font-size: 16px;
        line-height: 22px;
        &::placeholder {
            color: ${colors.primary70};
        }
        &:focus {
            outline: none;
        }
    }
`;

const Label = styled.div`
    color: ${colors.secondary100};
    display: inline-block;
`;

const Invalid = styled.div`
    ${props => props.valid !== true} {display: none;}
    ${props => props.valid !== false} {display: inline-block;}
    color: ${colors.alert100};
    margin-left: 10px;
`;

const CreditExpiration = ({title, valid, warning, id, onChange, placeholder, value, disabled, error}) => (
    <InputSectionNoMargin>
        <div>
            {(title !== undefined && title !== null  && title !== "") && <Label>{title}</Label>}
            {(warning !== undefined && warning !== null  && warning !== "") && <Invalid valid={valid}>{warning}</Invalid>}
            {(error !== undefined && error !== null  && error !== "") && <Invalid valid={false}>{error}</Invalid>}
        </div>
        <Input>
            <Cleave id={id} options={{"date": true, "datePattern": ["m", "y"]}} onChange={onChange} placeholder={placeholder} value={value} disabled={disabled} />
        </Input>
    </InputSectionNoMargin>
);

export default CreditExpiration;