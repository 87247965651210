import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";

/* Components */
import {CourseSummaryContainer, PageTitle, Text, Image, OpenLink, BackSectionCourse, Container, LoadingCenter} from "../components/courses";
import {FontBody16, FontBody24, FontHeader16, FontTitle20} from '../../../common/components/fonts';
import {images} from '../../../common/components/images';
import {LogoLoading} from "../../../common/components/loading";

/* Containers */
import CourseSummaryModule from './coursesummarymodules';
// import CourseUnassignModal from './courseunassign';

/* Middleware */
import {tryGetCourseOne, tryPostAssignCourse, tryPostUnassignCourse} from "../middleware/courses";

const Back = styled.div`
  width: fit-content;
  cursor: pointer;
  display: flex;
  padding: 0 5px;
  align-items: center;
`;

class CourseSummary extends React.Component {
    static defaultProps = {
        back: () => {},
        course: {},
        openTask: () => {}
    };

    state={
        "course": {},
        "showMore": false,
        "courseAssignmentId": null,
        "isLoading": true,
        "summaryModuleOpen": null,
        // "showUnAssignModal": false
    };

    componentDidMount() {
        return this.init();


    }

    componentDidUpdate(prevProps) {
        if (prevProps.course !== this.props.course) {
            return this.init();
        }
    }



    init = async () => {
        this.setState({
            "course": this.props.course,
            "courseAssignmentId": this.props.course.id === undefined ? null : this.props.course.id,
            "isLoading": false
        })

    };

    showMoreDesc = () => {
        this.setState({"showMore": !this.state.showMore})
    };

    summaryModuleOpenStatus = (id) => {
        this.setState({"summaryModuleOpen": this.state.summaryModuleOpen === id ? null : id})
    };

    startOpeningTask = async (task) => {
        // console.log("original course: ", this.state.course);
        // console.log("original task: ", task);
        if (this.state.courseAssignmentId === null) {
            const assignment = await this.props.tryPostAssignCourse(this.props.course.course.id, this.props.userId);
            const course = await this.props.tryGetCourseOne(this.props.course.course.id);
            await this.setState({
                "courseAssignmentId": assignment.id,
                "course": course
            })
            // console.log("after course: ", this.state.course);
            task = course.tasks.find(t => (t.task.id === task.task.id));
            // console.log("after task: ", task);
        }




        this.props.openTask(task);



    };

    // onUnassign = async () => {
    //     await this.props.tryPostUnassignCourse(this.state.courseAssignmentId);
    //     this.setState({"courseAssignmentId": null});
    // };

    // changeShowUnAssignModal = async () => {
    //     this.setState({"showUnAssignModal": !this.state.showUnAssignModal});
    // };
    render() {
        console.log(this.state.course.tasks);
        if (this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <Container>
                    <BackSectionCourse>
                        <FontBody24><Back onClick={this.props.back}><img src={images.chevronLeft} alt={"<"} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Back</Back></FontBody24>
                    </BackSectionCourse>
                    <PageTitle>COURSE</PageTitle>
                    <CourseSummaryContainer>
                        <Image src={this.state.course.course.imageUrl} alt={""}/>
                        <FontTitle20>{this.state.course.course.title}</FontTitle20>
                        <Text active={this.state.showMore}><FontBody16>{this.state.course.course.description}</FontBody16></Text>
                        <OpenLink><FontHeader16 onClick={this.showMoreDesc}>Show {this.state.showMore ? "Less" : "More"}</FontHeader16></OpenLink>
                    </CourseSummaryContainer>
                    <div>
                        {this.state.course.tasks.map((task, index) => {
                            return(
                                <CourseSummaryModule
                                    key={index}
                                    index={index}
                                    task={task}
                                    summaryModuleOpen={this.state.summaryModuleOpen}
                                    summaryModuleOpenStatus={this.summaryModuleOpenStatus}
                                    numTasksTotal={this.state.course.tasks.length}
                                    startOpeningTask={this.startOpeningTask}
                                />
                            )
                        })}
                    </div>
                    {/*{this.state.courseAssignmentId !== null &&*/}
                    {/*    <Pointer onClick={this.changeShowUnAssignModal}>*/}
                    {/*        <ButtonSecondary submit={false} label={"Remove course"} />*/}
                    {/*    </Pointer>*/}
                    {/*}*/}
                    {/*{this.state.showUnAssignModal &&*/}
                    {/*    <CourseUnassignModal*/}
                    {/*        no={this.changeShowUnAssignModal}*/}
                    {/*        yes={this.onUnassign}*/}
                    {/*    />*/}
                    {/*}*/}
                </Container>
            )
        }
    }
}

const mapStateToProps = state => ({
    userId: state.common.user.get("userId")
});

const mapDispatchToProps = dispatch => ({
    tryPostAssignCourse: (courseId, userId) => dispatch(tryPostAssignCourse(courseId, userId)),
    tryPostUnassignCourse: (courseAssignmentId) => dispatch(tryPostUnassignCourse(courseAssignmentId)),
    tryGetCourseOne: (courseId) => dispatch(tryGetCourseOne(courseId))
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseSummary);
