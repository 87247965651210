import React from 'react';
import styled from 'styled-components';
import {connect} from "react-redux";

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FontTitle38, FontBody18, FontHeader21, FontBody14} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";
import {ButtonPrimary} from "../../../common/components/buttons";
import {LoadingCenter} from '../components/flow';
import {CalendarOutlined, CloudDownloadOutlined, MailOutlined} from "@ant-design/icons";

const Margin2 = styled.div`
    margin-bottom: 25px;
`;

const Center = styled.div`
    text-align: center;
    padding: 80px 20px;
    width: calc(100% - 40px);
    max-width: 600px;
    margin: 0px auto;
    @media screen and (max-width: 640px) {
        padding: 40px 20px;
    }
`;

const Middle = styled.div`
    margin: 0px auto;
    margin-top: 40px;
    padding: 50px 20px;
    background-color: ${colors.border100};
    border-radius: 6px;
    width: calc(100% - 40px);
    text-align: center;
`;

const Image = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 40px;
    object-fit: cover;
    object-position: 0 0;
    @media screen and (max-width: 640px) {
        width: 30px;
        height: 30px;
        border-radius: 30px;
    }
`;

const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;
const Name = styled.div`
    margin-top: 6px;
    margin-left: 6px;
    @media screen and (max-width: 640px) {
        font-size: 18px;
        margin-top: 2px;
    }
`;
const ThankYou = styled.div`
    font-size: 60px;
    margin-top: 40px;
    @media screen and (max-width: 640px) {
        font-size: 38px;
        margin-top: 20px;
    }
    @media screen and (max-width: 460px) {
        font-size: 30px;
        margin-top: 20px;
    }
`;
const ReceiptMargin = styled.div`
    margin-top: 80px;
`;
const FitContent = styled.div`
    width: fit-content;
    margin: 0px auto;
`;
const Margin = styled.div`
    margin-top: 15px;
    @media screen and (max-width: 640px) {
        margin-top: 0px;
        font-size: 16px;
    }
    @media screen and (max-width: 460px) {
        margin-top: -10px;
        font-size: 16px;
    }
`;

class Receipt extends React.Component {
    state={
        "plan": null,
        "product": null,
        "isLoading": true
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.props.getPaymentHistoryId();
        await this.setState({
            "plan": this.props.plan.toJS(),
            "product": this.props.product.toJS(),
            "isLoading": false
        });
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <Center>
                    <div>
                        <Inline><Image src={this.state.product.expert.profilePhotoUrl} /></Inline>
                        <Inline><FontHeader21><Name>{this.state.product.expert.fullName}</Name></FontHeader21></Inline>
                    </div>
                    <FontTitle38><ThankYou>Thanks {this.props.user.get("first")}!</ThankYou><Margin2 /></FontTitle38>
                    {this.props.planType === "flatFee" &&
                        <FontBody18><Margin>Thank you for purchasing</Margin></FontBody18>
                    }
                    {(this.props.planType === "subscription" || this.props.planType === "limitedPayments") &&
                        <FontBody18><Margin>Thank you for subscribing</Margin></FontBody18>
                    }
                    {this.state.product.serviceType === "online" &&
                        <Middle>
                            <FitContent onClick={() => {return this.props.setStep(4)}}>
                                <ButtonPrimary canSubmit={false} label={<><CalendarOutlined />&nbsp;Schedule Meeting</>} />
                            </FitContent>
                        </Middle>
                    }
                    {this.state.product.serviceType === "offline" &&
                        <Middle>
                            <FontBody18>Stay tuned for next steps</FontBody18>
                        </Middle>
                    }
                    {this.state.product.serviceType === "download" &&
                        <Middle>
                            <FitContent>
                                <a href={this.state.product.downloadUrl} target={"_blank"}>
                                    <ButtonPrimary canSubmit={false} label={<><CloudDownloadOutlined />&nbsp;Download File</>} />
                                </a>
                            </FitContent>
                        </Middle>
                    }
                    <ReceiptMargin>
                        {(this.props.planType === "free" || this.props.planType === "invoicing")
                            ? <Margin><FontBody14><MailOutlined />&nbsp;A confirmation has been sent to the email address provided.</FontBody14></Margin>
                            : <Margin><FontBody14><MailOutlined />&nbsp;A receipt has been sent to the email address provided.</FontBody14></Margin>
                        }
                    </ReceiptMargin>
                </Center>
            )
        }
    }

}

const mapStateToProps = state => ({
    user: state.common.user,
});

export default connect(mapStateToProps, null)(Receipt);
