import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from "styled-components";
import { CheckCircleFilled } from "@ant-design/icons";
import moment from 'moment-timezone';

import { colors } from "../../../common/components/colors";
import { fontFamily, FontBody18} from "../../../common/components/fonts";

import {setMyGoalSelected} from "../store/progress"

const GoalContainer = styled.div`
    border: 0;
    border-radius: 20px 20px 0 20px;
    width: 225px;
    outline: none;
    padding: 15px 25px;
    text-align: left;
    font-family:${fontFamily.body};
    font-size: 16px;
    background-color: ${colors.backgroundColor3};
    color: ${colors.white};
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 225px;
    position: relative;
    z-index: 1;
    border-bottom: 4px ${colors.white} solid;
`

const CheckInButton = styled.div`
    border: 0px;
    border-radius: 0px 0px 20px 20px;
    cursor: pointer;
    outline: none;
    background-color: ${colors.action100};
    color: ${colors.white};
    padding: 20px;
    margin-top: -20px;
    padding-top: 30px;
    width: 100%
    position: relative;
    justify-content: center;
    align-items: center;
    display:flex;
    gap: 10px
`
const FlexCol = styled.div`
    display: flex;
    border-radius: 20px;
    flex-direction: column;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
`

const FontTitle = styled.div`
    font-family: ${fontFamily.subtitle};
    font-size: 24px;
`

const GoalProgressRing = styled.div`
    width: 150px;
    height: 150px;
    position: absolute;
    left: 0;
    top: 0;

    place-content: center;
    
    border-radius: 50%;
    
    mask: radial-gradient(farthest-side, #0000 calc(99% - 15px), #000 calc(100% - 15px));
    
    background:
        radial-gradient(farthest-side, ${colors.action100} 98%, #0000) top/15px 15px no-repeat,
        conic-gradient(${colors.action100} calc(${props => props.val}*1%), ${colors.primary100} 0);
    background-position: 0% 0%
`

const GoalProgress = styled.div`
    width: 150px;
    height: 150px;
    position: relative;
    margin: 20px auto 0px;
`

const ProgressPercent = styled.div`
    color: white;
    font-family: ${fontFamily.body};
    font-size: 35px;
    width: 150px;
    text-align: center;
    position: absolute;
    top: 54px;
`

export const SmallGoalPreview = withRouter(({history, goal}) => {
    const dispatch = useDispatch();

    const getGoalTitle = (g) => {
        let goalTitle = g.get("title")
        // if (goalTitle.length > 15)
        //     goalTitle = goalTitle.substring(0,12) + '...'
        return goalTitle
    }

    const getGoalDate = (g) => {
        let goalDate = g.get("pages").get("2").get("elements").get("1").get("value");

        let goalDateFormatted = moment(goalDate).format("MM/DD/YYYY");
        return goalDateFormatted;
    }

    let goToGoalCheckIn = (selectedGoal) => {
        dispatch(setMyGoalSelected(selectedGoal))
        history.push('/progress')
        //history.push('/progress/goals/update')
    }

    return (
        <FlexCol>
            <GoalContainer>
                {/*getGoalTitle(goal)*/}
                <FontTitle>{getGoalTitle(goal)}</FontTitle>
                <FontBody18>By {getGoalDate(goal)}</FontBody18>
                <GoalProgress>
                    <ProgressPercent> 60% </ProgressPercent>
                    <GoalProgressRing val={60} />
                </GoalProgress>
            </GoalContainer>
            {/*<CheckInButton onClick={ () => goToGoalCheckIn(goal)}>*/}
            {/*    <CheckCircleFilled style={{"marginRight": "5px"}}/>*/}
            {/*    <FontTitle>Check In</FontTitle>*/}
            {/*</CheckInButton>*/}
        </FlexCol>
    )
});