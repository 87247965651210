import {axiosReq} from "../../../common/utils/axios";
import {fromJS} from 'immutable';
import moment from "moment";

/* Store */
import { setMessagesDownloading, setMessagesSending, setPurchaseLinkDownloading } from "../store/messages";
import { setClientsActivityAddRecord } from "../../store/clientsactivity";

/* Static */
import { devMessagesAddData, devMessagesData, devMessagesDataConferenceId, devMessagesDataPurchaseLink } from '../static/devdata';

/* Supporting Functions */

export const tryGetCoachClientPurchaseLink = (consumerId) => {
    return async dispatch => {
        dispatch(setPurchaseLinkDownloading(true));
        const pastSessions = await axiosReq('apiv1/coach/clients/'+consumerId+'/program', "GET", {}, devMessagesDataConferenceId);
        let purchaseLink;
        const numPastCalls = fromJS(pastSessions).get("pastCalls").size;
        if(numPastCalls > 0) {
            const sessionId = fromJS(pastSessions).get("pastCalls").get(-1).get("id");
            purchaseLink = await axiosReq('apiv1/coach/clients/'+sessionId+'/get-purchase-link', "POST", {}, devMessagesDataPurchaseLink);
        } else {
            purchaseLink = {"purchaseToken": null, "purchaseUrl": null};
        }
        dispatch(setPurchaseLinkDownloading(false));
        return purchaseLink.purchaseToken;
    }
};



/* Dash - Advisor|Coach to Consumer */

export const tryGetCoachClientMessagesCompose = (consumerId) => {
    return async dispatch => {
        dispatch(setMessagesDownloading(true));
        const messages = await axiosReq('apiv1/coach/clients/'+consumerId+'/messages', "GET", {}, devMessagesData);
        dispatch(setMessagesDownloading(false));
        return messages.messages.filter(a => {return(a.channel === "sms")});
    }
};

export const tryPostCoachClientMessages = (mediaType, content, subject, consumerId) => {
    return async dispatch => {
        dispatch(setMessagesSending(true));
        const messagesAdd = await axiosReq('apiv1/coach/clients/'+consumerId+'/messages', "POST", {content: content, subject: subject, channel: mediaType}, devMessagesAddData(true, content, subject, mediaType));
        dispatch(setClientsActivityAddRecord({...messagesAdd, "activityType": mediaType, "orderDatetime": moment()}));
        dispatch(setMessagesSending(false));
        return messagesAdd;
    }
};



/* Dash - Advisor|Coach to Advisor|coach */

export const tryGetCoachCoachMessagesCompose = (consumerId) => {
    return async dispatch => {
        dispatch(setMessagesDownloading(true));
        const messages = await axiosReq('apiv1/coach/coach/'+consumerId+'/messages', "GET", {}, devMessagesData);
        dispatch(setMessagesDownloading(false));
        return messages.messages.filter(a => {return(a.channel === "sms")});
    }
};

export const tryPostCoachCoachMessages = (mediaType, content, subject, consumerId) => {
    return async dispatch => {
        dispatch(setMessagesSending(true));
        const messagesAdd = await axiosReq('apiv1/coach/coach/'+consumerId+'/messages', "POST", {content: content, subject: subject, channel: mediaType}, devMessagesAddData(true, content, subject, mediaType));
        dispatch(setMessagesSending(false));
        return messagesAdd;
    }
};