import { Map, List, fromJS } from 'immutable';

/* Immutable */

const TEAM_MEMBER_PRODUCTS_WILLOW = 'TEAM_MEMBER_PRODUCTS_WILLOW';
const TEAM_MEMBER_PRODUCTS_WILLOW_FETCHING = 'TEAM_MEMBER_PRODUCTS_WILLOW_FETCHING';
const TEAM_MEMBER_PRODUCTS_COACH = 'TEAM_MEMBER_PRODUCTS_COACH';
const TEAM_MEMBER_PRODUCTS_COACH_FETCHING = 'TEAM_MEMBER_PRODUCTS_COACH_FETCHING';
const TEAM_MEMBERS_PRODUCTS_WMC = 'TEAM_MEMBERS_PRODUCTS_WMC';
const TEAM_MEMBERS_PRODUCTS_WMC_FETCHING = 'TEAM_MEMBERS_PRODUCTS_WMC_FETCHING';
const TEAM_MEMBERS_PRODUCTS_GENERAL = 'TEAM_MEMBERS_PRODUCTS_GENERAL';
const TEAM_MEMBERS_PRODUCTS_GENERAL_FETCHING = 'TEAM_MEMBERS_PRODUCTS_GENERAL_FETCHING';
const TEAM_MEMBER_PRODUCTS_STORE_RESET = 'TEAM_MEMBER_PRODUCTS_STORE_RESET';

/* Actions */

export const setTeamMemberProductsWillow = (productsWillow) => ({'type': TEAM_MEMBER_PRODUCTS_WILLOW, 'productsWillow': productsWillow});
export const setTeamMemberProductsWillowFetching = (status) => ({'type': TEAM_MEMBER_PRODUCTS_WILLOW_FETCHING, 'status': status});
export const setTeamMemberProductsCoach = (productsCoach) => ({'type': TEAM_MEMBER_PRODUCTS_COACH, 'productsCoach': productsCoach});
export const setTeamMemberProductsCoachFetching = (status) => ({'type': TEAM_MEMBER_PRODUCTS_COACH_FETCHING, 'status': status});
export const setTeamMemberProductsWMC = (members) => ({'type': TEAM_MEMBERS_PRODUCTS_WMC, 'members': members});
export const setTeamMemberProductsWMCFetching = (status) => ({'type': TEAM_MEMBERS_PRODUCTS_WMC_FETCHING, 'status': status});
export const setTeamMemberProductsGeneral = (productsGeneral) => ({'type': TEAM_MEMBERS_PRODUCTS_GENERAL, 'productsGeneral': productsGeneral});
export const setTeamMemberProductsGeneralFetching = (status) => ({'type': TEAM_MEMBERS_PRODUCTS_GENERAL_FETCHING, 'status': status});
export const setTeamMemberProductsStoreReset = () => ({'type': TEAM_MEMBER_PRODUCTS_STORE_RESET});

/* Initial State */

const initialState = Map({
    productsWillow: List(),
    productsWillowFetched: false,
    productsWillowFetching: false,
    productsCoach: List(),
    productsCoachFetched: false,
    productsCoachFetching: false,
    productsWMC: List(),
    productsWMCFetching: false,
    productsGeneral: List(),
    productsGeneralFetched: false,
    productsGeneralFetching: false
});

/* Reducer */

const productsReducer = (state=initialState, action) => {
    switch (action.type) {
        case TEAM_MEMBER_PRODUCTS_WILLOW:
            return state.merge({
                'productsWillow': fromJS(action.productsWillow),
                'productsWillowFetched': true
            });
        case TEAM_MEMBER_PRODUCTS_WILLOW_FETCHING:
            return state.merge({'productsWillowFetching': action.status});
        case TEAM_MEMBER_PRODUCTS_COACH:
            return state.merge({
                'productsCoach': fromJS(action.productsCoach),
                'productsCoachFetched': true
            });
        case TEAM_MEMBER_PRODUCTS_COACH_FETCHING:
            return state.merge({'productsCoachFetching': action.status});
        case TEAM_MEMBERS_PRODUCTS_WMC:
            return state.merge({'productsWMC': fromJS(action.members)});
        case TEAM_MEMBERS_PRODUCTS_WMC_FETCHING:
            return state.merge({'productsWMCFetching': action.status});
        case TEAM_MEMBERS_PRODUCTS_GENERAL:
            return state.merge({
                'productsGeneral': fromJS(action.productsGeneral),
                'productsGeneralFetched': true
            });
        case TEAM_MEMBERS_PRODUCTS_GENERAL_FETCHING:
            return state.merge({'productsGeneralFetching': action.status});
        case TEAM_MEMBER_PRODUCTS_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default productsReducer;
