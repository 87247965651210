import React from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import moment from "moment/moment";
import {List, fromJS} from 'immutable';

/* Components */
import {FontHeader16} from '../../common/components/fonts';
import {colors} from '../../common/components/colors';
import {LogoLoading, LogoLoadingSmall} from "../../common/components/loading";
import {Message} from "../../common/components/messages";

/* Containers */
import NotesForCoach from './contactnotesforcoach';
import AdvisorSessionStep from './contactadvisorsessionstep';

/* Middleware */
import {tryGetAnnouncementsAll, tryGetAdvisorSessionStep} from "../homepage/middleware/announcements";
import InstructionsFlow from "./instructionsflow";
import SessionsBox from "./sessionsbox";
import AdvisorNotes from "../containers/contactadvisorsessionstep";

/* Components */
const Section = styled.div`
    padding: 20px;
    background-color: ${colors.border30};
    max-height: 100%;
    @media screen and (max-width: 460px) {
        padding: 5px;
    }
`;
const Empty = styled.div`
    margin: 30px 30px 20px 30px;
    color: ${colors.primary70};
    text-align: center;
`;

class ContactInstructions extends React.Component {
    state={
        "isLoading": true,
        "prospectStatus": null,
        "notes": "",
        "announcementsSorted": List(),
        "userId": null,
        "advisorData": {
            "isAdvisor": false,
            "hasPaidForCert": false,
            "hasPassedSessionStep": false,
            "advisorCertVettingConferenceId": 0
        },
        "wmcId": null
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.prospectStatus !== this.props.prospectStatus || prevProps.clientCoaches !== this.props.clientCoaches || prevProps.prospectCoaches !== this.props.prospectCoaches) {
            return this.init();
        }
    }

    init = async () => {
        if(this.props.prospectStatus !== "Contact") {
            let coach;
            let noteForCoach = null;
            if (this.props.prospectStatus === "Client") {
                await this.setState({"userId": this.props.clientCoaches.get("consumerId")});
                if(Object.keys(this.props.clientCoaches.toJS()).length > 0) {
                    if(this.props.clientCoaches.get("coaches").size > 0) {
                        coach = await this.props.clientCoaches.get("coaches").filter(a => {
                            return(a.get("coachId") === this.props.coachId)
                        });
                        await this.setState({"wmcId": this.props.clientCoaches.get("wmcId")});
                        if(coach.size > 0) {
                            noteForCoach = await coach.get(0).get("noteForCoach")
                        }
                    }
                }
            } else {
                await this.setState({"userId": this.props.prospectCoaches.get("consumerId")});
                if(Object.keys(this.props.prospectCoaches.toJS()).length > 0) {
                    if(this.props.prospectCoaches.get("coaches").size > 0) {
                        coach = await this.props.prospectCoaches.get("coaches").filter(a => {
                            return(a.get("coachId") === this.props.coachId)
                        });
                        await this.setState({"wmcId": this.props.prospectCoaches.get("wmcId")});
                        if(coach.size > 0) {
                            noteForCoach = await coach.get(0).get("noteForCoach")
                        }
                    }
                }
            }
            await this.getAnnouncements();
            let advisorData = {"isAdvisor": false, "hasPaidForCert": false, "hasPassedSessionStep": false, "coachReviewSessions": [], "hasBeenReviewed":false};
            if(this.state.userId !== undefined) {
                advisorData = await this.props.tryGetAdvisorSessionStep(this.state.userId);
                await this.setState({"advisorData": advisorData})
            }
            if (noteForCoach !== null) {
                await this.setState({"notes": noteForCoach})
            }
            this.setState({"prospectStatus": this.props.prospectStatus, "advisorData": advisorData, "isLoading": false});
        } else {
            this.setState({"prospectStatus": this.props.prospectStatus, "isLoading": false});
        }
    };

    updateSessionStep = async () => {
        await this.setState({"isLoading": true});
        const advisorData = await this.props.tryGetAdvisorSessionStep(this.state.userId);
        this.setState({"advisorData": advisorData, "isLoading": false});
    }

    // createHTML = (title, body, name, company, created) => {
    //     return(
    //         <div>
    //             <FontHeader21>{title}</FontHeader21>
    //             <FontBody16>{body}</FontBody16>
    //             <ReadOnlyValueInput title={'Created By'} value={name+" at "+company}/>
    //             <ReadOnlyValueInput title={'Created On'} value={created} />
    //         </div>
    //     )
    // }

    createHTML = (title, body, name, company, created) => {
        return(
            // "<p><b>"+title+"</b></p>" +
            // "<br />" +
            "<p>"+body+"</p>" +
            "<br /><br />" +
            "<p>Author: <b>"+name+"</b></p>" +
            "<p>Date: <b>"+created+"</b></p>"
        )
    }

    getAnnouncements = async () => {
        await this.props.tryGetAnnouncementsAll();
        const activityFiltered = await Promise.all(this.props.announcements.filter(a => {
            if(a.get("wmc").get("id") === this.state.wmcId && this.state.wmcId !== null) {
                return(a)
            }
        }));
        if(activityFiltered.size > 0) {
            const activityOrdered = await Promise.all(activityFiltered.sort((a, b) => {
                return moment(b.get("created")).diff(moment(a.get("created")))
            }));
            const activityHTML = await Promise.all(activityOrdered.map(a => {
                let title = a.get("title");
                let body = a.get("body");
                let name = a.get("sender").get("fullName");
                let company = a.get("wmc").get("companyName");
                let titleAdj = company + " - " + title;
                let created = moment(a.get("created")).format("LL");
                return(fromJS({
                    ...a.toJS(),
                    "html": this.createHTML(titleAdj, body, name, company, created)
                }))
            }))
            await this.setState({"announcementsSorted": activityHTML});
        }
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            if(this.state.prospectStatus === "Contact") {
                return(<Section><Message text={"Change relationship to prospect or client to receive instructions."} /></Section>)
            } else {
                return(
                    <Section>
                        {(this.state.notes === "" && this.state.announcementsSorted.size === 0 && (!this.state.advisorData.hasPaidForCert || (this.state.advisorData.hasPaidForCert && this.state.advisorData.hasPassedSessionStep))) ?
                            <>
                                <Empty><FontHeader16>No instructions about {this.props.client.get("firstName") + " " + (this.props.client.get("referralSource") === 'wmc' && !this.props.isWMC ? this.props.client.get("lastName").charAt(0) + "." : this.props.client.get("lastName"))}</FontHeader16></Empty>
                            </>
                        :
                        <>
                            {(this.state.advisorData.hasPaidForCert && !this.state.advisorData.hasPassedSessionStep) &&
                                <>
                                    {this.state.advisorData.coachReviewSessions.map(c => {
                                        if(c.hasBeenReviewed === false) {
                                            return(
                                                <AdvisorSessionStep
                                                    userId={this.state.userId}
                                                    conferenceId={c.conferenceId}
                                                    advisorName={this.props.client.get("firstName")}
                                                    updateSessionStep={this.updateSessionStep}
                                                />
                                            )
                                        }
                                    })}

                                </>
                            }
                            {this.state.advisorData.coachReviewSessions.length === 0 &&
                                !this.state.advisorData.isAdvisor && <SessionsBox />     }
                            {this.state.announcementsSorted.size > 0 &&
                                <>
                                    {this.state.announcementsSorted.map((a, index) => (
                                        <NotesForCoach key={index} data={a.get("html")} category={"Announcement"} title={a.get("title")} />
                                    ))}
                                </>
                            }
                            {this.state.notes !== "" &&
                                <LazyLoad offset={200} key={0} placeholder={<LogoLoadingSmall />} >
                                    <div>
                                        <NotesForCoach data={this.state.notes} category={"Instructions"} title={"Please note:"} />
                                    </div>
                                </LazyLoad>
                            }
                        </>
                        }
                    </Section>
                )
            }
        }
    }
}

const mapStateToProps = state => ({
    prospectStatus: state.enterprise.clientsDetail.get("client").get("prospectStatus"),
    consumerId: state.enterprise.clientsDetail.get("client").get("consumerId"),
    client: state.enterprise.clientsDetail.get("client"),
    clientCoaches: state.enterprise.clientsList.get("clientsOne"),
    prospectCoaches: state.enterprise.prospects.get("prospectsOne"),
    coachId: state.common.user.get("userId"),
    isWMC: state.common.wmc.get("isWMC"),
    announcements: state.enterprise.homepage.announcements.get("announcements").get("announcements")
});

const mapDispatchToProps = dispatch => ({
    tryGetAnnouncementsAll: () => dispatch(tryGetAnnouncementsAll()),
    tryGetAdvisorSessionStep: (advisorId) => dispatch(tryGetAdvisorSessionStep(advisorId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactInstructions));