import React from 'react';
import {connect} from "react-redux";

/* Containers */
import CheckoutFlow from "../../../iframes/checkout/containers/flow";

/* Middleware */
import {setCheckoutConsumerPaymentSuccessFired} from '../store/checkout';

class TeamCheckout extends React.Component {
    render() {
        return(
            <CheckoutFlow
                planId={this.props.planId}
                inModal={true}
                fromDashboard={true}
                onBack={this.props.onBack}
                onPaymentSuccess={this.props.setCheckoutConsumerPaymentSuccessFired}
                onCloseCheckoutSuccess={this.props.onCloseCheckoutSuccess}
                quantity={this.props.quantity}
                showBackBtn={this.props.showBackBtn}
            />
        )
    }
}

const mapDispatchToProps = dispatch => ({
    setCheckoutConsumerPaymentSuccessFired: () => dispatch(setCheckoutConsumerPaymentSuccessFired())
});

export default connect(null, mapDispatchToProps)(TeamCheckout);
