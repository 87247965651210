export const timeZoneOptions = [
    {value: 'pacific', label: 'Pacific Time'},
    {value: 'mountain', label: 'Mountain Time'},
    {value: 'central', label: 'Central Time'},
    {value: 'eastern', label: 'Eastern Time'},
];

export const timeZoneOptionsByRegion = (region) => {
    console.log(region);
    if(region === "America/Los_Angeles") {return("pacific")}
    else if(region === "America/Denver") {return("mountain")}
    else if(region === "America/Chicago") {return("central")}
    else {return("eastern")}
};

export const timeZoneGreetings = (momentTimeZone) => {
    const currentHour = parseInt(momentTimeZone.format("HH"));
    if (currentHour >= 3 && currentHour < 12){
        return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 17){
        return "Good Afternoon";
    }   else if (currentHour >= 17 || currentHour < 3){
        return "Good Evening";
    } else {
        return "Hello"
    }
}