import React from 'react';
import {connect} from "react-redux";

/* Middleware */
import {tryGetBillingCurrentPlan} from "../middleware/billing";
import {LogoLoading} from "../../../common/components/loading";

class BillingCurrentPlan extends React.Component {
    state={"isLoading": true, "currentPlan": {}};

    componentDidMount() {
        return this.init()
    }

    init = async () => {
        await this.props.tryGetBillingCurrentPlan();
        this.setState({
            "currentPlan": this.props.currentPlan.toJS(),
            "isLoading": false
        });
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <>
                    <div>PAYMENT METHOD</div>
                    <div>{this.state.currentPlan.product.title}</div>
                    <div>${(parseInt(this.state.currentPlan.amount)/100).toFixed(2)}</div>
                    <div onClick={() => {return this.props.showCancel(true)}}>Cancel Plan</div>
                </>
            )
        }
    }

}

const mapStateToProps = state => ({
    currentPlan: state.enterprise.billing.billing.get("currentPlan")
});

const mapDispatchToProps = dispatch => ({
    tryGetBillingCurrentPlan: () => dispatch(tryGetBillingCurrentPlan())
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingCurrentPlan);
