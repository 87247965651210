import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setSettingsEnterpriseClientsReading, setSettingsEnterpriseClientsUpdating, setSettingsEnterpriseClients} from "../store/settings";

/* Static */
import {devSettingsEnterpriseClientsData, devSettingsEnterpriseClientsUpdateData} from '../../static/devdata';

export const tryGetSettingsEnterpriseClients = () => {
    return async dispatch => {
        dispatch(setSettingsEnterpriseClientsReading(true));
        const clients = await axiosReq('apiv1/coach/settings/clients', "GET", {}, devSettingsEnterpriseClientsData);
        dispatch(setSettingsEnterpriseClients({...clients, "errorJourney": "", "errorGoal": "", "errorApproach": ""}));
        return dispatch(setSettingsEnterpriseClientsReading(false));
    }
};

let errCategory;

export const tryUpdateSettingsEnterpriseClients = (data, category) => {
    return async (dispatch, getState) => {
        dispatch(setSettingsEnterpriseClientsUpdating(true));
        let storeJourney = getState().enterprise.settings.get("settingsEnterpriseClients").get("journey")
        let storeGoal = getState().enterprise.settings.get("settingsEnterpriseClients").get("goal")
        let storeApproach = getState().enterprise.settings.get("settingsEnterpriseClients").get("approach")
        let req = {
            "journey": (category === "journey" || storeJourney === "" || storeJourney === undefined) ? data.journey : storeJourney.toJS(),
            "goal": (category === "goal" || storeGoal === "" || storeGoal === undefined) ? data.goal : storeGoal.toJS(),
            "approach": (category === "approach" || storeApproach === "" || storeApproach === undefined) ? data.approach : storeApproach.toJS()
        };
        errCategory = "error".concat(category.charAt(0).toUpperCase() + category.slice(1));
        const clients = await axiosReq('apiv1/coach/settings/clients', "PUT", req, devSettingsEnterpriseClientsUpdateData(req));
        if(clients.hasOwnProperty("error")) {
            dispatch(setSettingsEnterpriseClients({...getState().enterprise.settingsPersonal.get("settingsPersonalInfo").toJS(), errCategory: clients.error}));
        } else {
            dispatch(setSettingsEnterpriseClients({...data, errCategory: ""}));
        }
        return dispatch(setSettingsEnterpriseClientsUpdating(false));
    }
};








