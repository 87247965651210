import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';

/* Middleware */
import {tryGetCertifiedAdvisors} from './middleware/directory';

/* Components */
// import IFrameAdvisorDirectoryFilter from "./containers/filters";
import IFrameAdvisorDirectoryIntro from "./containers/intro";
import IFrameAdvisorDirectoryNav from "./containers/nav";
import {FontBody16, FontTitle18, FontBody14} from "../../common/components/fonts";

const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 60px;
`;
export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 60px);
  padding: 0 30px;
  gap: 60px;
  flex-wrap: wrap;
`;

const StyledTable = styled.table`
    width: 70%;
    border: 3px solid #ddd;
    text-align: left;
    border-collapse: collapse;
    margin: 30px;
`;

const StyledTableHead = styled.thead`
    background-color: #f2f2f2;
    border: 3px solid #ddd;
    font-size: 18px;
    text-align: center;
    
`;

const StyledTableBody = styled.tbody`
    border: 3px solid #ddd;
`;

const StyledTableData = styled.td`
    border: 1px solid #ddd;
    padding: 10px;
`;

export default function IFrameBadActorAdvisorDirectory() {
    const [cardList, setCardList] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        initNonRedirect()
    }, []);

    const shuffle = async (array) => {
        let currentIndex = array.length,  randomIndex;
        // While there remain elements to shuffle.
        while (currentIndex > 0) {
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
        return array;
    }

    const initNonRedirect = async () => {
        const advisors = await dispatch(tryGetCertifiedAdvisors());
        const advisorsShuffled = await shuffle(advisors);
        let count = 1;
        const advisorsCleaned = await advisorsShuffled.reduce((filtered, a) => {
            let photos = a.advisor.additionalProfileInformation.photos.filter(p => {return(p !== null)})
            let logos = a.advisor.additionalProfileInformation.logos.filter(l => {return(l !== null)})
            let logo = logos.length === 0 ? a.advisor.firmLogo : logos[0];
            let date = a.advisor.badActorDate;
            let advisorRecord = {
                ...a,
                ...a.advisor,
                ...a.advisor.additionalProfileInformation,
                photos: photos.length > 0 ? photos[0] : null,
                logos: logos.length > 0 ? logos[0] : null,
                priority: false, // is sponsored advisor
                active: true, // is advisor active (expiration not hit)
                minAssets: parseInt(a.advisor.minimumInvestableAssets),
                gr: a.advisor.gender,
            };
            if (date) {
                filtered.push(advisorRecord)
            }
            return filtered;
        }, []);
        setCardList(advisorsCleaned)
    }

    // const handleCardListChange = () => {
    //     const advisorFilter = advisors.filter((el =>
    //             ` ${(el.firstName.toLowerCase() + " " + el.lastName.toLowerCase())} ${el.firm_name.toLowerCase()}`.includes(nameSearch.toLowerCase())
    //     ))
    //     const categoryFilter = checkboxes.length > 0 ? advisorFilter.filter(el =>
    //         el.specialities.some(r => checkboxes.includes(r))
    //     ) : advisorFilter;
    //     const zipcodeFilter = categoryFilter.filter(el => el.firm_address.includes(zipCode));
    //     const priority = advisorFilter.map((a) => a.priority)
    //     const highestPriority = Math.max(...priority);
    //     const priorityFilter = zipcodeFilter.sort((a, b) => b.priority - a.priority);
    //     setCardList(priorityFilter.map((advisor, index) => ({
    //         ...advisor,
    //         advisor_priority: advisor.priority >= highestPriority && advisor.priority > 1
    //     })))
    // };

    return (
        <Page>
            <IFrameAdvisorDirectoryNav />
            <IFrameAdvisorDirectoryIntro />
            {/*<IFrameAdvisorDirectoryFilter handleCardListChange={handleCardListChange} />*/}
            <StyledTable>
                <StyledTableHead>
                    <tr>
                        <th><FontTitle18>Name</FontTitle18></th>
                        <th><FontTitle18>Date</FontTitle18></th>
                        <th><FontTitle18>Disciplinary Action</FontTitle18></th>
                    </tr>
                </StyledTableHead>
                <StyledTableBody>
                    {cardList.map((advisor, index) => (
                        <tr key={index}>
                            <StyledTableData><FontBody14>{advisor.firstName + " " + advisor.lastName}</FontBody14></StyledTableData>
                            <StyledTableData><FontBody14>{advisor.advisor.badActorDate}</FontBody14></StyledTableData>
                            <StyledTableData><FontBody14>action</FontBody14></StyledTableData>
                        </tr>
                    ))}
                </StyledTableBody>
            </StyledTable>
         </Page>
    );
};