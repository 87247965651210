import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import styled from 'styled-components';

/* Components */
import {RadioBoxWithLabelWhite, ReadOnlyInput, TextInput} from "../../../../common/components/inputs";
import {SavedSettings} from '../../../../common/components/cards';
import {FontBody16, FontTitle18} from '../../../../common/components/fonts';
import {ButtonTertiary, ButtonSecondary} from "../../../../common/components/buttons";
import {LogoLoading} from "../../../../common/components/loading";
import {InlineBlock, SaveSection, SubmitInputWithValidation, FitContent} from "../../components/myprofile";

/* Middleware */
import {tryUpdateExpertProfile} from '../../middleware/expertprofile';
import {EditIcon} from "../../components/pagescontact";
import {CloseCircleOutlined, EditOutlined} from "@ant-design/icons";
import {InlineCheckbox} from "../../../listings/components/listings";
import {colors} from "../../../../common/components/colors";
import PhotoUploadSection from "../../../containers/photoupload";
import {tryPostImage} from "../../../../common/containers/imageupload/middleware/images";

const LoadingCenter = styled.div`
    margin: 60px auto;
`;
const Red = styled.div`
    color: ${colors.alert100};
    display: inline-block;
    vertical-align: top;
`;

export const HeroImg = styled.img`
    width: 100%;
    object-fit: cover;
    object-position: 0 0;
    max-width: 600px;
    max-height: 480px;
`;
export const HeroVid = styled.iframe`
    width: 100%;
    max-width: 600px;
    max-height: 480px;
    min-height: 440px;
    @media screen and (max-width: 800px) {
        min-height: 360px;
    }
    @media screen and (max-width: 460px) {
        min-height: 240px;
    }
`;

class ProfileHero extends React.Component {
    state = {
        imgURL: '', imgURLValid: true, imgURLPrevious: "",
        videoURL: '', videoURLValid: true, videoURLPrevious: "",
        photoULChecked: false, imgURLChecked: false, videoURLChecked: false,
        isSaving: false,
        isSaved: false,
        error: '',
        isValid: true,
        isChanged: false,
        isLoading: true,
        isEdit: false,
        "photo": null,
        "photoFile": null,
        "photoBlob": null,
        "photoChanged": false
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.profile !== prevProps.profile) {
            return this.init();
        }
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    init = async () => {
        this.setState({
            "imgURL": this.props.profile.get("heroImageUrl") === null ? "" : this.props.profile.get("heroImageUrl"),
            "imgURLPrevious": this.props.profile.get("heroImageUrl") === null ? "" : this.props.profile.get("heroImageUrl"),
            "videoURL": this.props.profile.get("introVideoUrl") === null ? "" : this.props.profile.get("introVideoUrl"),
            "videoURLPrevious": this.props.profile.get("introVideoUrl") === null ? "" : this.props.profile.get("introVideoUrl"),
            "imgURLChecked": (this.props.profile.get("heroImageUrl") !== null && this.props.profile.get("heroImageUrl") !== ""),
            "videoURLChecked": (this.props.profile.get("introVideoUrl") !== null && this.props.profile.get("introVideoUrl") !== ""),
            "isLoading": false
        });
    };

    handleChangeVideoURL = async e => {
        await this.setState({
            'videoURL': e.target.value,
            'videoURLValid': (validator.isURL(e.target.value) || e.target.value === ""),
            'imgURL': "",
            'isChanged': true
        });
        this.checkValid();
    };

    handleChangeImgURL = async e => {
        await this.setState({
            'imgURL': e.target.value,
            'imgURLValid': (validator.isURL(e.target.value) || e.target.value === ""),
            'videoURL': "",
            'isChanged': true
        });
        this.checkValid();
    };

    handlePhotoUL = async () => {
        await this.setState({"photoULChecked": true, "imgURLChecked": false, "videoURLChecked": false, 'isChanged': true});
        this.checkValid();
    };

    handleImgURL = async () => {
        await this.setState({"photoULChecked": false, "imgURLChecked": true, "videoURLChecked": false, 'isChanged': true});
        this.checkValid();
    };

    handleVideoURL = async () => {
        await this.setState({"photoULChecked": false, "imgURLChecked": false, "videoURLChecked": true, 'isChanged': true});
        this.checkValid();
    };

    updatePhoto = async (photo) => {
        await this.setState({
            "photo": photo.photo,
            "photoFile": photo.photoFile,
            "photoBlob": photo.photoBlob,
            "photoChanged": true,
            'isChanged': true
        });
        console.log(this.state)
        this.checkValid();
    };
    
    checkValid = async () => {
        if((this.state.photoULChecked && this.state.photoChanged) ||
            (this.state.imgURLChecked && this.state.imgURLValid) ||
            (this.state.videoURLChecked && this.state.videoURLValid)) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    edit = async (status) => {
        this.setState({"isEdit": status});
    };

    reset = async () => {
        this.setState({
            "videoURL": this.state.videoURLPrevious,
            "imgURL": this.state.imgURLPrevious,
            "imgURLChecked": (this.state.imgURLPrevious !== null && this.state.imgURLPrevious !== ""),
            "videoURLChecked": (this.state.videoURLPrevious !== null && this.state.videoURLPrevious !== ""),
            "photoULChecked": false,
            "photo": null,
            "photoFile": null,
            "photoBlob": null,
            "photoChanged": false,
            "isChanged": false,
            "isEdit": false
        });
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            await this.setState({'isSaving': true, "error": ""});
            let photoUrl;
            if(this.state.photoChanged) {
                console.log(this.state.photoFile)
                if(this.state.photoFile === "") {
                    photoUrl = null
                } else {
                    photoUrl = await this.props.tryPostImage(this.state.photoFile);
                }
            } else {
                photoUrl = null
            }
            const hero = {
                // "bio": this.props.profile.get("bio"),
                "introVideoUrl": this.state.videoURLChecked ? this.state.videoURL : "",
                "heroImageUrl": this.state.videoURLChecked ? "" : (this.state.photoULChecked ? photoUrl : (this.state.imgURLChecked ? this.state.imgURL : ""))
            };
            await this.props.tryUpdateExpertProfile(hero, "profileHeroError");
            if(this.props.profile.get("profileHeroError") === "") {
                this.setState({
                    'isSaving': false,
                    "isSaved": true,
                    "videoURLPrevious": this.state.videoURL,
                    "imgURLPrevious": this.state.imgURL,
                    "imgURLChecked": !this.state.videoURLChecked,
                    "videoURLChecked": this.state.videoURLChecked,
                    "photoULChecked": false,
                    "photo": null,
                    "photoFile": null,
                    "photoBlob": null,
                    "photoChanged": false,
                    "isChanged": false,
                    "isEdit": false
                });
                this.timeout = setTimeout(() => {this.setState({'isSaved': false, "isChanged": false})}, 3000);
            } else {
                this.setState({'isSaved': true, 'isSaving': false, "error": this.props.profile.get("profileHeroError")});
                this.timeout = setTimeout(() => {this.setState({'isSaved': false, "error": ""})}, 3000);
            }
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    {this.state.isSaved
                        ?
                            <>
                                {this.state.error === '' && <SavedSettings height={"99px"} error={false} message={"Saved"}/>}
                                {this.state.error !== '' && <SavedSettings height={"99px"} error={true} message={this.state.error}/>}
                            </>
                        :
                            <>
                                {!this.state.isEdit &&
                                    <>
                                        <FontTitle18>Web Banner</FontTitle18>
                                        {!this.state.videoURLChecked &&
                                            <>
                                                {this.state.imgURL === ""
                                                    ? <>
                                                        <br/>
                                                        <FontBody16><Red><CloseCircleOutlined /></Red>&nbsp;No image or video</FontBody16>
                                                        <br/>
                                                    </>
                                                    : <><br/><HeroImg src={this.state.imgURL} alt={""} /><br/><br/></>
                                                }
                                            </>
                                        }
                                        {this.state.videoURLChecked &&
                                            <><br/><HeroVid src={this.state.videoURL} /><br/><br/></>
                                        }
                                        <br/>
                                        <FitContent><FontBody16 onClick={() => {return this.edit(true)}}><ButtonSecondary canSubmit={false} label={<><EditOutlined/>&nbsp;Change Web Banner</>} /></FontBody16></FitContent>
                                    </>
                                }
                                {this.state.isEdit &&
                                    <form method={"post"} onSubmit={this.handleSubmit}>
                                        <div>
                                            <FontTitle18>Web Banner</FontTitle18>
                                            <br />
                                            <div>
                                                <InlineCheckbox>
                                                    <RadioBoxWithLabelWhite
                                                        id={6}
                                                        action={this.handlePhotoUL}
                                                        checked={this.state.photoULChecked}
                                                        label={"Photo Upload"}
                                                        subtext={"Upload a new photo"}
                                                        disabled={false}
                                                    />
                                                </InlineCheckbox>
                                                <InlineCheckbox>
                                                    <RadioBoxWithLabelWhite
                                                        id={6}
                                                        action={this.handleImgURL}
                                                        checked={this.state.imgURLChecked}
                                                        label={"Photo URL"}
                                                        subtext={"URL of a photo online"}
                                                        disabled={false}
                                                    />
                                                </InlineCheckbox>
                                                <InlineCheckbox>
                                                    <RadioBoxWithLabelWhite
                                                        id={7}
                                                        action={this.handleVideoURL}
                                                        checked={this.state.videoURLChecked}
                                                        label={"Video URL"}
                                                        subtext={"Youtube, Vimeo, other embed URL"}
                                                        disabled={false}
                                                    />
                                                </InlineCheckbox>
                                            </div>
                                            {this.state.photoULChecked &&
                                                <><br/><PhotoUploadSection
                                                    labelTitle={"Photo"}
                                                    labelAdd={"Add a photo"}
                                                    labelChange={"Change photo"}
                                                    labelRemove={"Remove photo"}
                                                    grayBackground={true}
                                                    photoMaxHeight={400}
                                                    action={this.updatePhoto}
                                                    photo={this.state.photo}
                                                    isUrl={!this.state.photoChanged}
                                                /><br/><br/></>
                                            }
                                            {this.state.imgURLChecked &&
                                                <TextInput
                                                    title={"Photo URL"}
                                                    valid={this.state.imgURLValid}
                                                    warning={""}
                                                    id={"imgURL"}
                                                    onChange={this.handleChangeImgURL}
                                                    placeholder={"https://..."}
                                                    value={this.state.imgURL}
                                                />
                                            }
                                            {this.state.videoURLChecked &&
                                                <TextInput
                                                    title={"Video URL (Embed Link)"}
                                                    valid={this.state.videoURLValid}
                                                    warning={""}
                                                    id={"videoURL"}
                                                    onChange={this.handleChangeVideoURL}
                                                    placeholder={"https://..."}
                                                    value={this.state.videoURL}
                                                />
                                            }
                                        </div>
                                        <SaveSection>
                                            <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                            <InlineBlock><div onClick={this.reset}><ButtonTertiary canSubmit={false} label={"Cancel"} /></div></InlineBlock>
                                        </SaveSection>
                                    </form>
                                }
                            </>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    profile: state.enterprise.profile.expertProfile.get("expertProfile")
});

const mapDispatchToProps = dispatch => ({
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey)),
    tryPostImage: (file) => dispatch(tryPostImage(file))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileHero);
