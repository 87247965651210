import React from 'react';
import {connect} from 'react-redux';
import 'moment-timezone/builds/moment-timezone-with-data-2012-2022';
import moment from 'moment-timezone';

/* Components */
import {colors} from '../../../common/components/colors';
import {FontTitle21, FontHeader18, FontBody16 } from "../../../common/components/fonts";
import {SubmitButton, CalendarDay, ClockTime, SubmitInput} from '../../../common/components/inputs';
import {formatTZ} from '../../../common/utils/timezone';
import { NoteActions, Label, ActionLeft, ActionRight, Modal, NoteTitle, Delete } from '../components/changeavailability';

/* Containers */
import ConfirmChange from "./confirmchange";

/* Store */
import {setManualAvailabilityModal} from '../store/manual';

const setOneHrMinNoMoment = (start, end) => {
    if(moment.duration(moment(end).diff(moment(start))).asMinutes() < 60) {
        return (moment(start).add(1, "hour").format());
    } else {
        return (moment(end).format());
    }
};

class ChangeAvailability extends React.Component {
    state={confirmModalOpen: false, id: null, day: null, start: null, end: null, endAfterStart: true, atLeastOneHr: true, validTimes: true, lockStart: false, endAlreadyPast: true};
    componentDidMount() {
        if(this.props.action === "create") {
            this.setState({
                id: this.props.availabilitySelected.get("id"),
                day: this.props.availabilitySelected.get("start"),
                start: this.props.availabilitySelected.get("start"),
                end: setOneHrMinNoMoment(this.props.availabilitySelected.get("start"), this.props.availabilitySelected.get("end"))
            })
        } else if(this.props.action === "update") {
            this.setState({
                id: this.props.availabilitySelected.id,
                day: this.props.availabilitySelected.start,
                start: this.props.availabilitySelected.start,
                end: setOneHrMinNoMoment(this.props.availabilitySelected.start, this.props.availabilitySelected.end)
            });
            if(moment(this.props.availabilitySelected.start).isBefore(moment())) {
                this.setState({
                    lockStart: true
                }); // If original start has past, cannot change the start
            }
        }

    }
    handleConfirmModal = status => {
        this.setState({confirmModalOpen: status});
    };
    handleDayChange = async day => {
        if(day !== null) {
            this.setState({"day" : day});
            this.setState({'start': moment(moment(day).format("YYYY-MM-DD[T]")+moment(this.state.start).format("HH:mm:00")).tz(formatTZ(this.props.timeZone)).toISOString()});
            this.setState({'end': moment(moment(day).format("YYYY-MM-DD[T]")+moment(this.state.end).format("HH:mm:00")).tz(formatTZ(this.props.timeZone)).toISOString()});
        }
    };
    handleTimeChange = async (newTime, period) => {
        let hour = newTime.hour.toString();
        if(hour.length === 1) {hour = "0"+hour;}
        let minute = newTime.minute.toString();
        if(minute.length === 1) {minute = "0"+minute;}
        await this.setState({[period] : moment(moment(this.state.day).format("YYYY-MM-DD[T]")+hour+":"+minute).tz(formatTZ(this.props.timeZone)).toISOString()});
        if(moment(this.state.end).isBefore(moment(this.state.start))) {
            await this.setState({endAfterStart: false})
        } else {
            await this.setState({endAfterStart: true})
        }
        if(moment.duration(moment(this.state.end).diff(moment(this.state.start))).asMinutes() < 60) {
            await this.setState({atLeastOneHr: false})
        } else {
            await this.setState({atLeastOneHr: true})
        }
        if(moment(this.state.end).isBefore(moment())) {
            await this.setState({endAlreadyPast: false})
        } else {
            await this.setState({endAlreadyPast: true})
        }
        if(this.state.endAfterStart && this.state.atLeastOneHr && this.state.endAlreadyPast) {
            this.setState({validTimes: true})
        } else {
            this.setState({validTimes: false})
        }
    };
    handleCancel = async () => {
        await this.props.setManualAvailabilityModal(false, null, null);
        await this.props.extraCancelAction();
    };
    render() {
        if(this.state.confirmModalOpen) {
            return(
                <ConfirmChange
                    close={this.handleConfirmModal}
                    closePrior={() => {this.props.setManualAvailabilityModal(false, null, null)}}
                    remove={this.props.deleteAvailability}
                    data={{id: this.state.id, start: this.state.start, end: this.state.end}}
                    type={'availability'}
                />
            )
        } else {
            return(
                <div>
                    <Modal>
                        <NoteTitle><FontTitle21>{this.props.action === "update" ? <>Update availability</> : <>Create availability</>}</FontTitle21></NoteTitle>
                        <>
                            <CalendarDay disabled={this.state.lockStart} id={"date"} title={"Date"} onChange={this.handleDayChange} value={this.state.day} />
                            <ClockTime endTooltip={false} disabled={this.state.lockStart} id={"start"} title={"Start time"} valid={this.state.endAfterStart} warning={"After the end time"} validEnd={true} warningEnd={""} handleTimeChange={this.handleTimeChange} value={this.state.start} />
                            <ClockTime endTooltip={false} disabled={false} id={"end"} title={"End time"} valid={this.state.atLeastOneHr} warning={"Minimum 1 hour"} validEnd={this.state.endAlreadyPast} warningEnd={"has already past"} handleTimeChange={this.handleTimeChange} value={this.state.end} />
                        </>
                        <NoteActions>
                            <ActionLeft onClick={this.handleCancel}>
                                <Label color={colors.primary30}><FontHeader18>Cancel</FontHeader18></Label>
                            </ActionLeft>
                            <ActionRight>
                                <>
                                    {this.state.validTimes === true
                                        ?
                                        <>
                                            {this.props.action === "create" && <div onClick={() => {this.props.createAvailability({id: this.state.id, start: this.state.start, end: this.state.end})}}><SubmitButton backgroundColor={colors.action100} iconComponent={null} color={colors.primary100} label={"Save"}/></div>}
                                            {this.props.action === "update" && <div onClick={() => {this.props.updateAvailability({id: this.state.id, start: this.state.start, end: this.state.end})}}><SubmitButton backgroundColor={colors.action100} iconComponent={null} color={colors.primary100} label={"Save"}/></div>}
                                        </>
                                        :
                                        <SubmitInput iconComponent={null} backgroundColor={colors.border70} disabled={true} color={colors.primary30} label={"Save"}/>
                                    }
                                </>
                            </ActionRight>
                        </NoteActions>
                    </Modal>
                    {this.props.action === "update" && <Delete onClick={() => {this.handleConfirmModal(true)}}><FontBody16>Remove</FontBody16></Delete>}
                </div>
            )
        }
    }
}
const mapStateToProps = state => ({
    availabilityModal: state.enterprise.availability.get("manualAvailabilityModal"),
    availabilitySelected: state.enterprise.availability.get("manualAvailabilitySelected"),
    timeZone: state.enterprise.settingsPersonal.get("settingsPersonalInfo").get("timeZone")
});
const mapDispatchToProps = dispatch => ({
    setManualAvailabilityModal: (status, availability, action) => dispatch(setManualAvailabilityModal(status, availability, action))
});
export default connect(mapStateToProps, mapDispatchToProps)(ChangeAvailability);
