import { combineReducers } from 'redux';

/* Common */
import schedulerReducer from './scheduler/reducers';
import reschedulerReducer from './rescheduler/reducers';
import listingsReducer from './listings/reducers';
import webinarsReducer from './webinars/reducers';
import shopReducer from './shop/reducers';
import checkoutReducer from './checkout/reducers';
import signUpAssignReducer from './signupassign/reducers';
import appointmentsReducer from './appointments/reducers';
// import videoReducers from '../deprecated/iframes/video/reducers';
import checkoutWillowReducer from './checkoutwillow/reducers';
import profileReducer from './profile/reducers';
import willowProfileReducer from './willowprofile/reducers';
import smsByTokenReducer from './smsbytoken/reducers';
import emailerReducer from './emailer/reducers';
import ratingsReducer from './ratings/reducers';
import changePasswordsReducer from './changepassword/reducers';
import signupReducer from './signup/reducers';
import cardReducer from './cards/reducers';
import signupExperts from './signupexpert/reducers';
import signupWMCUsersReducer from './signupwmcuser/reducers';
import programReducer from './programs/reducers';
import signupMatchReducer from './signupmatch/reducers';
import smsByIdReducer from "./smsbyid/reducers";
import courseReducer from '../iframes/courses/reducers';

const iframeReducer = combineReducers({
    scheduler: schedulerReducer,
    listings: listingsReducer,
    webinars: webinarsReducer,
    shop: shopReducer,
    checkout: checkoutReducer,
    rescheduler: reschedulerReducer,
    signUpAssign: signUpAssignReducer,
    appointments: appointmentsReducer,
    // video: videoReducers,
    checkoutWillow: checkoutWillowReducer,
    profile: profileReducer,
    willowProfile: willowProfileReducer,
    sms: smsByTokenReducer,
    emailer: emailerReducer,
    ratings: ratingsReducer,
    changePasswords: changePasswordsReducer,
    signup: signupReducer,
    cards: cardReducer,
    signupExperts: signupExperts,
    signupWMCUsers: signupWMCUsersReducer,
    programs: programReducer,
    signupMatch: signupMatchReducer,
    smsById: smsByIdReducer,
    courses: courseReducer,
});

export default iframeReducer;
