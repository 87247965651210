import React from 'react';

/* Local */
import AvailabilityManualCalendar from "./containers/manualcalendar";
import AvailabilityOptionBox from "./components/optionbox";

/* Navs */
import {PageContent, PageFull, PageRight, CardOrNot, Right, Width, WidthRight} from "../navbars/components/navlayout";
import NavPrimaryAll from "../navbars/containers/primaryall";
import NavSecondaryTop from "../navbars/containers/secondarytop";
import styled from "styled-components";

const CenterPageFull = styled(PageFull)`
  margin-left: 0 !important;

`;
class AvailabilityPage extends React.Component {
    state={"showRightBar": true};

    showRightBar = async (status) => {
        this.setState({"showRightBar": status})
    };

    render() {
        return(
            <>
                {/*<NavPrimaryAll />*/}
                <CenterPageFull>
                    {/*<PageRight>*/}
                    {/*    <NavSecondaryTop title={"Availability"} desc={"Sync your calendar, set your availability, and offer appointment types"}/>*/}
                        <PageContent>

                                <Width fullWidth={true}>
                                    <AvailabilityManualCalendar showRightBar={false} />
                                </Width>
                                {/*{this.state.showRightBar &&*/}
                                {/*    <WidthRight fullWidth={true}>*/}
                                {/*        <Right fullWidth={true}>*/}
                                {/*            <CardOrNot>*/}
                                {/*                <AvailabilityOptionBox*/}
                                {/*                    title={"Appointments"}*/}
                                {/*                    subTitle={"Offer specific types of appointments"}*/}
                                {/*                    href={"/settings/availability/appointments"}*/}
                                {/*                    label={"Manage"}*/}
                                {/*                />*/}
                                {/*            </CardOrNot>*/}
                                {/*            <br />*/}
                                {/*            <CardOrNot>*/}
                                {/*                <AvailabilityOptionBox*/}
                                {/*                    title={"Calendar Sync"}*/}
                                {/*                    subTitle={"Set recurring weekly schedule"}*/}
                                {/*                    href={"/settings/availability/calendar"}*/}
                                {/*                    label={"Manage"}*/}
                                {/*                />*/}
                                {/*            </CardOrNot>*/}
                                {/*        </Right>*/}
                                {/*    </WidthRight>*/}
                                {/*}*/}

                        </PageContent>
                    {/*</PageRight>*/}
                </CenterPageFull>
            </>
        )
    }
}

export default AvailabilityPage;