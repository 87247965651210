import React, {useState, useEffect, useRef} from 'react';
import validator from "validator";
import {useDispatch, useSelector} from 'react-redux';
// import styled from "styled-components";

/* Utils */
import {useEffectUpdate} from '../../../common/utils/hooks';

/* Components */
import {PasswordInput, TextInput} from "../../../common/components/inputs";
import {FontBody14, FontHeader21, FontBody16, FontHeader16} from "../../../common/components/fonts";
import {ButtonPrimary} from "../../../common/components/buttons";
import {Email, Container, Password, Error, FullWidth, Left, LoginBtn, Right} from '../components/payment';
// import {LogoLoading} from "../../../common/components/loading";

/* Middleware */
import {tryCheckoutLogin} from '../middleware/auth';

/* Store */
import {setIframeAuthLoginError} from '../store/auth';

// const LoadingCenter = styled.div`
//     margin: 60px auto;
// `;

export default function Login(props) {
    const dispatch = useDispatch();
    const iframeAuthLoginError = useSelector(state => state.iframe.scheduler.auth.get("iframeAuthLoginError"));

    const [email, setEmail] = useState("");
    const [emailValid, setEmailValid] = useState(true);
    const [password, setPassword] = useState("");
    const [passwordValid, setPasswordValid] = useState(true);
    const [isValid, setIsValid] = useState(false);
    // const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setEmail(props.email);
        // setIsLoading(false);
        return function cleanup() {
            dispatch(setIframeAuthLoginError(null));
        }
    }, []);

    useEffectUpdate(() => {
        setEmailValid(validator.isEmail(email));
    }, [email]);

    useEffect(() => {
        setPasswordValid((!validator.isEmpty(password) && password !== ''));
    }, [password]);

    useEffect(() => {
        if(passwordValid && emailValid) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    }, [emailValid, passwordValid]);

    const handleChangeEmail = e => setEmail(e.target.value);
    const handleChangePassword = e => setPassword(e.target.value);

    const handleSubmit = async e => {
        e.preventDefault();
        await dispatch(tryCheckoutLogin(email, password));
        if(iframeAuthLoginError === null) {
            await props.loginSuccess();
        }
    };

    // if(isLoading) {
    //     return(<LoadingCenter><LogoLoading /></LoadingCenter>)
    // }

    return(
        <Container inModal={props.inModal}>
            <form method={"post"} onSubmit={handleSubmit}>
                <div>
                    <Left><FontHeader21>Log in</FontHeader21></Left>
                    <Right><FontBody16>Need an account?&nbsp;<LoginBtn onClick={props.signup}><FontHeader16>Create account</FontHeader16></LoginBtn></FontBody16></Right>
                </div>
                <Email>
                    <TextInput
                        title={"Email"}
                        valid={email === "" ? true : emailValid}
                        warning={"Enter a valid email address"}
                        id={"email"}
                        onChange={handleChangeEmail}
                        placeholder={"Email"}
                        value={email}
                    />
                </Email>
                <Password>
                    <PasswordInput
                        title={"Password"}
                        valid={password === "" ? true : passwordValid}
                        warning={"Required"}
                        id={"password"}
                        onChange={handleChangePassword}
                        placeholder={"Password"}
                        value={password}
                    />
                </Password>
                <FullWidth oneLevel={isValid}>
                    <ButtonPrimary canSubmit={true} label={"Log in"}/>
                </FullWidth>
                {iframeAuthLoginError !== null &&
                    <Error><FontBody14>{iframeAuthLoginError}</FontBody14></Error>
                }
                <br/>
                <br/>
            </form>
        </Container>
    )
}