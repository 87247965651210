import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import moment from "moment";

import DatePicker from "react-datepicker";
import { FontSubtitle18, FontSubtitle40, FontBody24, FontHeader26, FontHeader14, FontBody12, FontTitle24, fontFamily } from "../../../../common/components/fonts";
import { colors } from "../../../../common/components/colors";
import { FirmModal, FirmModalResponsive } from "../../../../common/components/modalv2";
import { TableWrapperV2 } from "../../../../common/components/tablev2";

import { tryGetReportsForDateRange } from "../middleware/reports"

//#region Components
const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;
const Subtitle = styled(FontBody24)`
    margin: 30px 0 0 0;
    min-height: 108px;
`;
const IntroWithNav = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 60px;
`;

const ModalContent = styled(FontBody24)`
    text-align: left;
`;

const Browse = styled.div`
    background-color: white;
    padding: 40px;
    border-radius: 30px;
    box-shadow: 0 10px 25px 0 rgb(0, 0, 0, 0.15);
    width: calc(100% - 80px);
`;
const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
    padding-bottom: 30px;
`;

const Button = styled.div`
    ${props => props.isSelected !== "true"} {
        background-color: ${colors.backgroundColor3};
        color: ${colors.white};
    }
    ${props => props.isSelected !== "false"} {
        background-color: ${colors.white};
        color: ${colors.primary100};
    }
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
    border-radius: 20px;
    padding: 15px;
    user-select: none;
`;

const TableContainer = styled.div`
    width: calc(100% - 10px);
    overflow-y: auto;
    max-height: 400px;
    & table {
        & tr {
            padding: 0px;
            @media screen and (max-width: 640px) {
                padding: 5px 0px;
            }
        }
        & th {
            padding: 10px 10px 10px 10px;
        }
        & td {
            padding: 10px 10px 10px 10px;
        }
    }
`;

const NotesReportsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 12px;
`;

const Category = ({ handleUpdateCategories, category, isSelected }) => (
    <Button
        onClick={() => { return handleUpdateCategories(category) }}
        isSelected={isSelected.toString()}
    >
        <FontSubtitle18>{category}</FontSubtitle18>
    </Button>
)

import "react-datepicker/dist/react-datepicker.css";
const DateWrapper = styled.div`
    & .react-datepicker-wrapper {
        width: 100%;
        & input {
            ${props => props.disabled === true} {cursor: pointer;}
            ${props => props.disabled !== true} {
                cursor: default;
                color: ${colors.primary30};
            }
        }
    }
    & .react-datepicker__day--keyboard-selected {
        background-color: white !important;
        color: black !important;
    }
    input {
        max-width: 90px;
        border: none;
    }
`;
//#endregion

const FilterTypes = {
    PreviousMonth: 1,
    Last30Days: 2,
    Last7Days: 3,
    Custom: 4
}

const FilterButtons = [
    {type: FilterTypes.PreviousMonth, title: "Previous month"},
    {type: FilterTypes.Last30Days, title: "Last 30 Days"},
    {type: FilterTypes.Last7Days, title: "Last 7 Days"},
    {type: FilterTypes.Custom, title: "Custom"},
]

const NotesReport = withRouter((props) => {
    const [isLoading, setLoading] = useState(true);
    const [noteReport, setNoteReport] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedNoteReports, setSelectedNoteReport] = useState(null);
    const [noteReportTableData, setTableData] = useState([])

    const [startFilterDate, setStartFilterDate] = useState(null);
    const [endFilterDate, setEndFilterDate] = useState(null);
    const [selectedFilterType, setFilterType] = useState(FilterTypes.Last7Days)
    const [isCustomDateDisabled, setIsCustomDateDisabled] = useState(true)


    useEffect(() => {
        filterTypeSelected(selectedFilterType);
    }, [])

    useEffect(() => {
        setLoading(true);
        if(startFilterDate && endFilterDate)
            getNotesReportResponse();
    }, [startFilterDate, endFilterDate])

    const filterTypeSelected = (filterType) => {
        setFilterType(filterType);
        switch (filterType) {
            case FilterTypes.PreviousMonth:
                setStartFilterDate(moment().subtract(1, 'months').startOf('month').toDate())
                setEndFilterDate(moment().subtract(1, 'months').endOf('month').toDate())
                setIsCustomDateDisabled(true);
                break;
            case FilterTypes.Last30Days:
                setStartFilterDate(moment().subtract(30, 'days').toDate())
                setEndFilterDate(moment().toDate())
                setIsCustomDateDisabled(true);
                break;
            case FilterTypes.Last7Days:
                setStartFilterDate(moment().subtract(7, 'days').toDate())
                setEndFilterDate(moment().toDate())
                setIsCustomDateDisabled(true);
                break;
            case FilterTypes.Custom:
                setIsCustomDateDisabled(false);
            default:
                break;
        }
    }

    const getNotesReportResponse = async () => {
        const notesReportResponse = await tryGetReportsForDateRange(startFilterDate, endFilterDate);
        let notesGroupedByClient = {};
        notesReportResponse.forEach(note => {
            let noteClient = note.client.firstName + " " + note.client.lastName;
            if (!notesGroupedByClient[note.client.id])
                notesGroupedByClient[note.client.id] = {
                    client: noteClient,
                    clientId: note.client.id,
                    notes: [note]
                }
            else {
                notesGroupedByClient[note.client.id].notes.push(note)
            }
        });
        setNoteReport(notesGroupedByClient)
        setLoading(false);
    }

    const setCustomStartDate = (newDate) => {
        setStartFilterDate(newDate);
    }

    const setCustomEndDate = (newDate) => {
        setEndFilterDate(newDate);
    }

    const openModal = (selectedNote) => {
        setSelectedNoteReport(selectedNote)
        const tableData = selectedNote.notes.map(note => {
            return {
                'id': note.id,
                'sort': note.datetime,
                'author': note.author.firstName + " " + note.author.lastName,
                'title': note.title.length > 23 ? note.title.substring(0, 20) + '...' : note.title,
                'datetime': moment(note.datetime).format("MMM D, YYYY"),
                'numberOfComments': note.numberOfComments.toString()
            }
        });
        setTableData(tableData)
        setShowModal(true)
    }

    const closeModal = () => {
        setTableData([])
        setShowModal(false);
    }

    const thStyles = { "fontSize": "14px", "color": colors.primary100, "fontFamily": fontFamily.title, "border": "none", "backgroundColor": colors.white, "borderCollapse": "collapse", "borderSpading": 0 };
    const tdStyles = { "fontSize": "14px", "color": colors.primary100, "fontFamily": fontFamily.body, "height": "16px" };
    const tableColumns = {
        "0": { "key": "id", "title": "Id", "titleShowOnDesktop": false, "canSort": false, "sort": "desc", "sortType": "int", "sortKey": null, "component": "hidden", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": null, "onDoubleClick": null },
        "1": { "key": "sort", "title": "Sort", "titleShowOnDesktop": false, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "hidden", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": null, "onDoubleClick": null },
        "2": { "key": "author", "title": "Author", "titleShowOnDesktop": true, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "string", "thStyles": thStyles, "tdStyles": tdStyles, "ifNull": "", "onClick": null, "onDoubleClick": null },
        "3": { "key": "title", "title": "Title", "titleShowOnDesktop": true, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "string", "thStyles": thStyles, "tdStyles": tdStyles, "ifNull": "", "onClick": null, "onDoubleClick": null },
        "4": { "key": "datetime", "title": "Created on", "titleShowOnDesktop": true, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "string", "thStyles": thStyles, "tdStyles": tdStyles, "ifNull": "", "onClick": null, "onDoubleClick": null },
        "5": { "key": "numberOfComments", "title": "Comments", "titleShowOnDesktop": true, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "string", "thStyles": thStyles, "tdStyles": tdStyles, "ifNull": "", "onClick": null, "onDoubleClick": null },
    }

    const buildNotesReportComponent = () => {
        if (isLoading)
            return <LoadingCenter><FontHeader26>Loading...</FontHeader26></LoadingCenter>
        else if (!isLoading && noteReport.length === 0)
            return <FontHeader26>No notes were created in selected time period!</FontHeader26>
        return Object.keys(noteReport).map((key, index) => {
            let note = noteReport[key]
            return (
                <Button key={"notereport-clientid-" + key} onClick={() => openModal(note)}>
                    <FontHeader14>{note.client}</FontHeader14>
                    <FontBody12>{note.notes.length} {note.notes.length > 1 ? "notes" : "note"} created</FontBody12>
                </Button>
            );
        });
    }

    return <>
        <IntroWithNav>
            <div>
                <FontSubtitle40 spaced={true}>NOTE REPORT</FontSubtitle40>
                <Subtitle>Get information about notes created in your company for time period.</Subtitle>
            </div>
        </IntroWithNav>
        <Browse>
            <Buttons>
                {FilterButtons.map((b, index) => (
                    <Category
                        key={index}
                        handleUpdateCategories={() => filterTypeSelected(b.type)}
                        category={b.title}
                        isSelected={selectedFilterType == b.type}
                    />
                ))}
                <Button key={'startFilterDateButton'} hidden={isCustomDateDisabled}>
                    <DateWrapper disabled={false}>
                        <DatePicker
                            disabled={isCustomDateDisabled}
                            id={"startDatePicker"}
                            onChange={setCustomStartDate}
                            selected={startFilterDate}
                            maxDate={new Date(moment().toString())}
                        />
                    </DateWrapper>
                </Button>
                <Button key={'endFilterDateButton'} hidden={isCustomDateDisabled}>
                    <DateWrapper disabled={false}>
                        <DatePicker
                            disabled={isCustomDateDisabled}
                            id={"endDatePicker"}
                            onChange={setCustomEndDate}
                            selected={endFilterDate}
                            maxDate={new Date(moment().toString())}
                        />
                    </DateWrapper>
                </Button>
            </Buttons>
            {/* TODO: Add date pickers for custom category */}
            <NotesReportsContainer>
                {buildNotesReportComponent()}
            </NotesReportsContainer>
            {showModal && selectedNoteReports &&
                <FirmModalResponsive>
                    <FirmModal
                        message={<ModalContent>
                            <FontTitle24 spaced={true}>Client: {selectedNoteReports.client}</FontTitle24>
                            <TableContainer>
                                <TableWrapperV2
                                    responsive={true}
                                    tableColumns={tableColumns}
                                    tableData={noteReportTableData}
                                    initSelected={null}
                                    tableStyles={{}}
                                    theadStyles={{ "boxShadow": "0 4px 10px -7px rgba(0, 0, 0, 0.15)" }}
                                    spaceBeforeTbody={true}
                                />
                            </TableContainer>
                        </ModalContent>}
                        cancelShow={false}
                        confirmShow={false}
                        closeShow={true}
                        closeAction={() => closeModal()}
                        modalStyle={{ "backgroundColor": colors.white }}
                    />
                </FirmModalResponsive>
            }
        </Browse>

    </>
});

export default connect(null, null)(NotesReport);