import {axiosReq} from "../../../../common/utils/axios";

/* DevData */
import {devConsumerCourses} from "../../../../consumer/progress/static/devdata";
import {devCompleteTask, devConsumerCourseOneUnassigned} from "../../../../iframes/courses/static/devdata";
import {devFailedTask} from "../static/devdata";
import {devPurchaseHistoryData} from "../../../../iframes/checkout/static/devdata";

export const tryGetOneTask = (courseId) => {
    return async dispatch => {
        return axiosReq('apiv1/consumer/courses/details/' + courseId, "GET", {}, devConsumerCourseOneUnassigned); // devConsumerCourseOneUnassigned, devArticleWithCoach
    }
};

export const tryGetAllTasks = () => {
    return async dispatch => {
        return axiosReq('apiv1/consumer/courses/all-my-content', "GET", {}, devConsumerCourses);
    }
};

export const tryAnswerTask = (id, answers, isCompleted, lastPageCompleted) => {
    return async dispatch => {
        return axiosReq('apiv1/consumer/courses/answer-task', "POST", {"taskAssignmentId": id, "answers": answers, "isCompleted": isCompleted, "lastPageCompleted": lastPageCompleted}, devCompleteTask);
    }
};

export const tryPostAssignTask = (courseId, userId, isCharged) => {
    return async dispatch => {
        return axiosReq('apiv1/coach/courses/consumers/assign-course', "POST", {"courseId": courseId, "assignedToUserId": userId, "isCharged": isCharged}, {});
    }
};

export const tryGetSurveyAttemptsToday = () => {
    return async dispatch => {
        return axiosReq('apiv1/consumer/courses/completed-surveys-today', "GET", {}, devFailedTask);
    }
};

export const tryGetPurchaseHistory = () => {
    return async dispatch => {
        return axiosReq('apiv1/consumer/purchase/get-purchase-history', "GET", {}, devPurchaseHistoryData);
    }
};
