import { axiosReq } from "../../../common/utils/axios";
import { Map } from "immutable";

/* Middleware */
import { tryUpdateProspectStage } from "../../middleware/prospectsstages";

/* Store */
import { setAllClientsArchiveExistingClient, setAllClientsEditExistingClient, setAllClientsAddNewClient, setClientsOneFetching, setClientsOne, setAllClientsFetched, setClientError, setClientCreating, setAllClientsFetching, setAllClients, setClientsArchiving, setClientUpdatingError, setClientUpdating } from "../store/clients";
import { setAllContactsAddNewContact } from "../../store/contacts";

/* Static */
import {
    devEnterpriseClientsistV2,
    devEnterpriseCreateProspectData,
    devEnterpriseClientV2,
    devEnterpriseProspectsListV3
} from '../../static/devdata';

export const tryGetAllClients = () => {
    return async (dispatch, getState) => {
        dispatch(setAllClientsFetching(true));
        const clients = await axiosReq('apiv1/coach/clients/with-meetings', "GET", {}, devEnterpriseClientsistV2);
        const prospects = await axiosReq('apiv1/coach/prospects/with-meetings', "GET", {}, devEnterpriseProspectsListV3);
        const all = [...new Set(clients.prospects.concat(prospects.prospects))];
        const isWMC = getState().common.wmc.get("isWMC");
        await dispatch(setAllClients(all, isWMC));
        dispatch(setAllClientsFetched());
        return dispatch(setAllClientsFetching(false));
    }
};

export const tryGetOneClient = (prospectId, storeOrReturn) => {
    return async (dispatch, getState) => {
        dispatch(setClientsOneFetching(true));
        const client = await axiosReq('apiv1/coach/clients/with-meetings/'+prospectId, "GET", {}, devEnterpriseClientV2);
        const isWMC = getState().common.wmc.get("isWMC");
        let clientAdj = {...client, "lastName": (!isWMC && client.referralSource === "willow") ? client.lastName.charAt(0)+"." : client.lastName};
        if(storeOrReturn === "store") {
            dispatch(setClientsOne(clientAdj));
            return dispatch(setClientsOneFetching(false));
        } else {
            dispatch(setClientsOneFetching(false));
            return clientAdj;
        }
    }
};

const formatClientsContact = (data, isWMC, inputData, user) => (Map({
    "contactId": data.contactId,
    "coachAssignments": [],
    "firstName": data.firstName,
    "lastName": data.lastName,
    "isArchived": data.isArchived,
    "businessUserId": null,
    "prospectId": data.prospectId,
    "consumerId": data.consumerId,
    "email": inputData.email,
    "phone": inputData.phone,
    "prospectStatus": "Client",
    "memberOfGroups": null,
    "referralSource": isWMC ? "wmc" : user.first + " " + user.last,
    "timeZone": data.timeZone,
    "companyName": data.companyName,
    "coaches": []
}));

const formatClient = (data, isWMC, inputData, user) => (Map({
    "advisor": isWMC ? Map({"advisorId": user.userId, "firstName": user.first, "lastName": user.last}) : null,
    "coach": isWMC ? null : user.first + " " + user.last,
    "consumerId": data.consumerId,
    "contactId": data.contactId,
    "firstName": data.firstName,
    "lastName": data.lastName,
    "isArchived": data.isArchived,
    "lastSession": null,
    "nextSession": null,
    "memberOfGroups": null,
    "prospectId": data.prospectId,
    "prospectStageId": inputData.stageId,
    "prospectStageName": "client",
    "revenue": data.revenue,
    "probabilityOfClosing": data.probabilityOfClosing,
    "companyName": data.companyName,
    "coaches": []
}));

export const tryCreateClient = (data) => {
    return async (dispatch, getState) => {
        dispatch(setClientCreating(true));
        let request = {firstName: data.first, lastName: data.last, email: data.email, phone: data.phone, timeZone: data.timeZone, companyName: data.companyName};
        if(data.fromContactId !== undefined) {
            request = {...request, contactId: data.fromContactId};
        }
        const newClient = await axiosReq('apiv1/coach/prospects', "PUT", request, devEnterpriseCreateProspectData(data.first, data.last, data.email, data.phone, data.timeZone, data.companyName));
        if(newClient.hasOwnProperty("error")) {
            dispatch(setClientError(newClient.error));
        } else {
            dispatch(setClientError(null));
            const isWMC = getState().common.wmc.get("isWMC");
            const user = getState().common.user.toJS();
            await dispatch(setAllClientsAddNewClient(formatClient(newClient, isWMC, data, user)));
            await dispatch(tryUpdateProspectStage({"prospectId": newClient.prospectId, "stageId": data.stageId, "fromStatus": "Client", "toStatus": "Client"}));
            dispatch(setAllContactsAddNewContact(formatClientsContact(newClient, isWMC, data, user)));
        }
        return dispatch(setClientCreating(false));
    }
};

export const tryEditClient = (data) => {
    return async dispatch => {
        dispatch(setClientUpdating(true));
        let probabilityOfClosing = data.probabilityOfClosing === undefined ? "0.00" : data.probabilityOfClosing.toString();
        let revenue = data.revenue === undefined ? "0.00" : (data.revenue.toString().charAt(0) === "$" ? data.revenue.toString().substring(1) : data.revenue.toString()).replaceAll(",", "");
        const request = {"probabilityOfClosing": probabilityOfClosing, "revenue": revenue};
        const editedClient = await axiosReq('apiv1/coach/prospects/'+data.prospectId, "PUT", request, {});
        if(editedClient.hasOwnProperty("error")) {
            dispatch(setClientUpdatingError(editedClient.error));
        } else {
            dispatch(setClientUpdatingError(null));
            await dispatch(setAllClientsEditExistingClient(data.prospectId, request));
        }
        dispatch(setClientUpdating(false));
    }
};

export const tryArchiveClient = (data) => {
    return async dispatch => {
        dispatch(setClientsArchiving(true));
        await axiosReq('apiv1/coach/clients/'+data.id+'/status', "PUT", {"isArchived": !data.isArchived}, {});
        await dispatch(setAllClientsArchiveExistingClient(data.id, data.isArchived));
        return dispatch(setClientsArchiving(false));
    }
};

export const getOneClientByConsumerId = (consumerId) => {
    return async (dispatch, getState) => {
        if(getState().enterprise.clientsList.get("clientsAll").size === 0) {
            await dispatch(tryGetAllClients());
        }
        return getState().enterprise.clientsList.get("clientsAll").filter(c => {return(c.get("consumerId") === consumerId)});
    }
};
