import React from 'react';
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import styled from "styled-components";

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FontBody21, FontSubtitle28} from "../../../common/components/fonts";
import BackBtn from "../components/back";
import {colors} from "../../../common/components/colors";

/* Containers */
// import BookAppointments from '../../appointments/containers/bookappointments';

/* Middleware */
import {tryGetAllTeamMembers} from '../middleware/team';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const Container = styled.div`
    width: calc(100% - 120px);
    padding: 90px 60px 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 210px);
    background-color: ${colors.white};
    @media screen and (max-width: 750px) {
        max-width: 650px;
        width: 100%;
        padding: 0;
    }
`;
const AptContainer = styled.div`
    padding: 20px 50px;
    width: calc(100% - 100px);
    @media screen and (max-width: 750px) {
        width: 100%;
        padding: 20px 0 0 0;
    }
`;
const Title = styled(FontSubtitle28)`
    margin-bottom: 60px;
    text-align: center;
`;
const ScheduleOptionsCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    row-gap: 20px;
    cursor: pointer;
    margin-bottom: 20px;
`;
const Profile = styled.div`
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    gap: 10px 30px;
    padding: 20px;
    flex-wrap: wrap;
`;
const ProfileIcon = styled.img`
    border-radius: 50%;
    height: 132px;
    width: 132px;
    object-fit: cover;
`;
const Position = styled(FontBody21)`
    color: ${colors.secondary100};
`;

class TeamAppointments extends React.Component {
    state = {
        'isLoading': true,
        'members': [],
    }

    componentDidMount() {
        this.init();
    }

    init = async () => {
        await this.props.tryGetAllTeamMembers();
        let members = this.props.members.filter((m) => {return(m.get("wmcId") === null && !(m.get("notATeamMember")))});
        await this.checkSpecialCase(members);
        this.setState({
            'members': await this.setUpMembers(members.toJS()),
            'isLoading': false,
        });
    }

    setUpMembers = async (data) => {
        return data.map(coach => {
            let title = env.CONSUMER.CARE.PRINT_EXPERT_CATEGORIES(coach.expertCategories, true);
            return { ...coach, "id": coach.coachId, "first": coach.first, "last": coach.last, "title": title, "photo": coach.photo };
        });
    }

    checkSpecialCase = (members) => {
        if(members.size === 1) {
            this.props.history.push("/my-team/"+members.get(0).get("coachId")+"/appointment")
        } else if(members.size === 0) {
            this.props.history.push("/my-team/find");
        }
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <Container>
                    <BackBtn onBack={() => {this.props.history.goBack()}} />
                    <AptContainer>
                        <Title>SELECT A TEAM MEMBER TO SCHEDULE WITH</Title>
                        {this.state.members.map((m, idx) => (
                            <ScheduleOptionsCard key={idx} onClick={() => this.props.history.push("/my-team/"+m.id+"/appointment")}>
                                <Profile>
                                    <ProfileIcon src={m.photo} alt={''}/>
                                    <div>
                                        <FontSubtitle28>{m.first + " " + m.last}</FontSubtitle28>
                                        <Position>{m.title}</Position>
                                    </div>
                                </Profile>
                            </ScheduleOptionsCard>
                        ))}
                    </AptContainer>
                </Container>
            )
        }
    }
}

const mapStateToProps = state => ({
    members: state.consumer.team.team.get("members"),
});

const mapDispatchToProps = dispatch => ({
    tryGetAllTeamMembers: () => dispatch(tryGetAllTeamMembers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TeamAppointments));
