import React from 'react';
import styled from 'styled-components';
import {Map, fromJS} from 'immutable';
import {connect} from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import { colors } from '../../../common/components/colors';
import { FontHeader18, FontSubtitle38To30, FontTitle24, FontBody18, FontBody16, FontSubtitle21} from '../../../common/components/fonts';
import {BackTop} from "../components/care";

import {tryGetInsightSubCategories} from '../middleware/insights';
import {insightSubCategorySelected} from '../store/insights'

const NavOptions = styled.div`
    padding: 20px 0;
    a:not(:last-of-type) > div {
        border-bottom: 2px solid ${colors.border70};
    }
    a > div {
        color: ${colors.primary100};
        cursor: pointer;
    }
`;
const NavMenuItem = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
`;

class InsightSubcategories extends React.Component {
    state={isLoading: true};

    componentDidMount() {
        return this.init()
    }

    componentDidUpdate(prevProps) {

    }

    init = async () => {
        await this.props.tryGetInsightSubCategories(this.props.parentCategory.get("id"))
        console.log("category", this.props.parentCategory)
        console.log("subcategories", this.props.tryGetInsightSubCategories(this.props.parentCategory.get("id")))
        return this.setState({'isLoading': false})
    }

    back = () => {
        this.props.history.push('/learn')
    }

    sendTo = async (c) => {
        await this.props.insightSubCategorySelected(c);
        console.log("subcats", this.props.insightSubCategorySelected(c))
        return this.props.history.push('/learn/content');
    };

    render() {
        if (this.state.isLoading) {
            return null
        } else {
            return(
                <>
                    <BackTop onClick={this.back}><FontBody16><LeftOutlined /> Back</FontBody16></BackTop>
                    <br />
                    <FontSubtitle38To30>{this.props.parentCategory.get("title")}</FontSubtitle38To30>
                    <br />
                    <FontHeader18 style={{color: colors.primary30}}>{this.props.parentCategory.get("subtitle")}</FontHeader18>
                    <NavOptions>
                        {this.props.subCategories.get("childCategories").map((c, index) => {
                            return (
                                <a key={index} onClick={() => {return this.sendTo(c)}}>
                                    <NavMenuItem key={index}>
                                        <FontBody18>{c.get("title")}</FontBody18>
                                        <RightOutlined/>
                                    </NavMenuItem>
                                </a>
                            )
                        })}
                    </NavOptions>
                </>
            )
        }
        
    }
}

const mapStateToProps = state => ({
    parentCategory: state.consumer.learn.insights.get("insightParentCategorySelected"),
    subCategories: state.consumer.learn.insights.get("insightSubCategories")
});

const mapDispatchToProps = dispatch => ({
    tryGetInsightSubCategories: (id) => dispatch(tryGetInsightSubCategories(id)),
    insightSubCategorySelected: (category) => dispatch(insightSubCategorySelected(category))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InsightSubcategories));