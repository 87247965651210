import React from 'react';
import {withRouter} from 'react-router-dom';

/* Components */
import {PageContent, PageFullSettings, PageRight} from "../navbars/components/navlayout";

/* Containers */
import SettingsBasicInfo from "./containers/basicinfo";
import SettingsSecurity from "./containers/security";
import SettingsCoaching from "./containers/coaching";
import SettingsProspects from './containers/prospects';
import SettingsUsers from './containers/users';
import SettingsUsersCreate from './containers/userscreate';
import SettingsUsersEdit from './containers/usersedit';
import SettingsBranding from './containers/branding';
import SettingsAvailability from './containers/availability';

/* Navbars */
import NavPrimaryAllSettings from "../navbars/containers/primaryallsettings";
import ProfilePage from "../profile/page";

const SettingsEnterprise = ({location}) => (
    <>
        <NavPrimaryAllSettings />
        <PageFullSettings>
            <PageRight>
                <PageContent>

                    {location.pathname === "/settings/basic-info" && <SettingsBasicInfo />}
                    {location.pathname === "/settings/branding" && <SettingsBranding />}
                    {location.pathname === "/settings/security" && <SettingsSecurity />}

                    {location.pathname === "/settings/coaching" && <SettingsCoaching />}
                    {location.pathname === "/settings/users" && <SettingsUsers />}
                    {location.pathname === "/settings/users/create" && <SettingsUsersCreate />}
                    {location.pathname === "/settings/users/edit" && <SettingsUsersEdit />}

                    {location.pathname === "/settings/prospects" && <SettingsProspects />}
                    {(location.pathname === "/settings/availability/calendar"
                        || location.pathname === "/settings/availability/appointments"
                        || location.pathname === "/settings/availability/appointments/create"
                        || location.pathname === "/settings/availability/appointments/edit"
                        || location.pathname === '/settings/availability/calendar/set') &&
                        <SettingsAvailability />
                    }
                    {(location.pathname === "/settings/profile/about/experience/create"
                    || location.pathname === "/settings/profile/about/experience/edit"
                        || location.pathname === "/settings/profile/about/education/create"
                        || location.pathname === "/settings/profile/about/education/edit"
                        || location.pathname === "/settings/profile/about/license/create"
                        || location.pathname === "/settings/profile/about/license/create"
                        || location.pathname === "/settings/profile/about/license/edit"
                        || location.pathname === "/settings/profile"
                        || location.pathname === "/settings/profile/about")
                        && <ProfilePage />
                    }

                </PageContent>
            </PageRight>
        </PageFullSettings>
    </>
);
export default withRouter(SettingsEnterprise);
