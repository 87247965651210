import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from "styled-components";

/* Middleware */
import {tryGetAllTasks} from "../middleware/tasks";

/* Components */
import {LogoLoading} from "../../../../common/components/loading";

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

class FirmTasksAll extends React.Component {
    state = {"isLoading": true, "tasks": []}

    componentDidMount() {
        this.init();
    }

    init = async () => {
        this.setState({
            "tasks": await this.props.tryGetAllTasks(),
            "isLoading": false
        })
    }

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    {this.state.tasks.map((t, tIndex) => (
                        <div key={tIndex}>

                        </div>
                    ))}
                </>
            );
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryGetAllTasks: () => dispatch(tryGetAllTasks()),
})

export default connect(null, mapDispatchToProps)(withRouter(FirmTasksAll));