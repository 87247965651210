import React from 'react';
import styled from 'styled-components';

import {FontHeader16} from './fonts';
import {colors} from './colors';

const Primary = styled.div`
    border: 1.5px solid ${colors.action100};
    background-color: ${colors.action100};
    color: ${colors.white};
    border-radius: 50px;
    padding: 9px 22px;
    width: fit-content;
    cursor: pointer;
    outline: none;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
`;
const Secondary = styled.div`
    border: 1.5px solid ${colors.action100};
    background-color: ${colors.white};
    color: ${colors.action100};
    border-radius: 50px;
    padding: 9px 22px;
    width: fit-content;
    cursor: pointer;
    outline: none;
`;
const Tertiary = styled.div`
    border: 1.5px solid ${colors.backgroundColor3};
    background-color: ${colors.backgroundColor3};
    color: ${colors.white};
    border-radius: 50px;
    padding: 9px 22px;
    width: fit-content;
    cursor: pointer;
    outline: none;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
`;
const Inactive = styled.div`
    border: 1.5px solid ${colors.secondary30};
    background-color: ${colors.secondary30};
    color: ${colors.secondary100};
    border-radius: 50px;
    padding: 9px 22px;
    width: fit-content;
    cursor: default;
    outline: none;
`;
const InactiveLight = styled.div`
    border: 1.5px solid ${colors.border100};
    background-color: ${colors.border100};
    color: ${colors.secondary100};
    border-radius: 50px;
    padding: 9px 22px;
    width: fit-content;
    cursor: pointer;
    outline: none;
`;
const Btn = styled.button`
    border: none;
    padding: 0px;
    outline: none;
    background-color: transparent;
`;
const Link = styled.div`
    color: ${colors.action100};
    padding: 7px 22px;
    width: fit-content;
    cursor: pointer;
`;
const LinkProgress = styled.div`
    color: ${colors.progress100};
    padding: 7px 22px;
    width: fit-content;
`;
const LinkInactive = styled.div`
    color: ${colors.secondary30};
    padding: 7px 22px;
    width: fit-content;
`;
const TertiaryAlert = styled.div`
    border: 1.5px solid ${colors.alert70};
    background-color: ${colors.white};
    color: ${colors.alert100};
    border-radius: 50px;
    padding: 9px 22px;
    width: fit-content;
    cursor: pointer;
    outline: none;
`;
const Alert = styled.div`
    border: 1.5px solid ${colors.alert100};
    background-color: ${colors.alert100};
    color: ${colors.white};
    border-radius: 50px;
    padding: 9px 22px;
    width: fit-content;
    cursor: pointer;
    outline: none;
`;
const TertiaryProgress = styled.div`
    border: 1.5px solid ${colors.progress100};
    background-color: ${colors.white};
    color: ${colors.progress100};
    border-radius: 50px;
    padding: 9px 22px;
    width: fit-content;
    cursor: pointer;
    outline: none;
`;
const LinkSecondary = styled.div`
    color: ${colors.action100};
    padding-left: 30px;
    width: fit-content;
    cursor: pointer;
`;
export const IconButton = styled.div`
    background-image: url(${props => props.image});
    background-size: contain;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    cursor: pointer;
    align-self: center;
    justify-self: center;
`; 
export const IconButton24To14 = styled(IconButton)`
    @media screen and (max-width: 460px) {
        width: 14px;
        height: 20px;
    }
`;
export const ButtonPrimary = ({canSubmit, label}) => {
    if(canSubmit) {
        return(<Btn><Primary><FontHeader16>{label}</FontHeader16></Primary></Btn>)
    } else {
        return(<Primary><FontHeader16>{label}</FontHeader16></Primary>)
    }
};
export const ButtonSecondary = ({canSubmit, label}) => {
    if(canSubmit) {
        return(<Btn><Secondary><FontHeader16>{label}</FontHeader16></Secondary></Btn>)
    } else {
        return(<Secondary><FontHeader16>{label}</FontHeader16></Secondary>)
    }
};
export const ButtonTertiary = ({canSubmit, label}) => {
    if(canSubmit) {
        return(<Btn><Tertiary><FontHeader16>{label}</FontHeader16></Tertiary></Btn>)
    } else {
        return(<Tertiary><FontHeader16>{label}</FontHeader16></Tertiary>)
    }
};
export const ButtonInactive = ({canSubmit, label}) => {
    if(canSubmit) {
        return(<Btn disabled={true}><Inactive><FontHeader16>{label}</FontHeader16></Inactive></Btn>)
    } else {
        return(<Inactive><FontHeader16>{label}</FontHeader16></Inactive>)
    }
};
export const ButtonInactiveLight = ({canSubmit, label}) => {
    if(canSubmit) {
        return(<Btn><InactiveLight><FontHeader16>{label}</FontHeader16></InactiveLight></Btn>)
    } else {
        return(<InactiveLight><FontHeader16>{label}</FontHeader16></InactiveLight>)
    }
};
export const ButtonLink = ({label}) => (
    <Link><FontHeader16>{label}</FontHeader16></Link>
);
export const ButtonLinkSecondary = ({label}) => (
    <LinkSecondary><FontHeader16>{label}</FontHeader16></LinkSecondary>
);
export const ButtonLinkProgress = ({label}) => (
    <LinkProgress><FontHeader16>{label}</FontHeader16></LinkProgress>
);
export const ButtonLinkInactive = ({label}) => (
    <LinkInactive><FontHeader16>{label}</FontHeader16></LinkInactive>
);
export const ButtonTertiaryAlert = ({canSubmit, label}) => {
    if(canSubmit) {
        return(<Btn><TertiaryAlert><FontHeader16>{label}</FontHeader16></TertiaryAlert></Btn>)
    } else {
        return(<TertiaryAlert><FontHeader16>{label}</FontHeader16></TertiaryAlert>)
    }
};
export const ButtonAlert = ({canSubmit, label}) => {
    if(canSubmit) {
        return(<Btn><Alert><FontHeader16>{label}</FontHeader16></Alert></Btn>)
    } else {
        return(<Alert><FontHeader16>{label}</FontHeader16></Alert>)
    }
};
export const ButtonTertiaryProgress = ({canSubmit, label}) => {
    if(canSubmit) {
        return(<Btn><TertiaryProgress><FontHeader16>{label}</FontHeader16></TertiaryProgress></Btn>)
    } else {
        return(<TertiaryProgress><FontHeader16>{label}</FontHeader16></TertiaryProgress>)
    }
};