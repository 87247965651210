import {axiosReq} from "../../../common/utils/axios";

/* Store */
import { setMessagesDownloading, setMessagesSending, setMessagesSettingsPersonalInfo, setMessagesSettingsPersonalInfoReading, setMessagesSettingsPersonalInfoUpdating } from "../store/messages";

/* Static */
import { devMessagesAddData, devMessagesData, devSettingsPersonalInfoData, devSettingsPersonalInfoUpdateData } from '../static/devdata';

export const tryGetMessages = (from, to, coachId) => {
    return async dispatch => {
        dispatch(setMessagesDownloading(true));
        const messages = await axiosReq('apiv1/'+from+'/'+to+'/'+coachId+'/messages', "GET", {}, devMessagesData);
        dispatch(setMessagesDownloading(false));
        return messages.messages.filter(a => {return(a.channel === "sms")});
    }
};

export const tryPostMessage = (from, to, mediaType, content, subject, coachId) => {
    return async dispatch => {
        dispatch(setMessagesSending(true));
        const messagesAdd = await axiosReq('apiv1/'+from+'/'+to+'/'+coachId+'/messages', "POST", {content: content, subject: subject, channel: mediaType}, devMessagesAddData(true, content, subject, mediaType));
        dispatch(setMessagesSending(false));
        return messagesAdd;
    }
};

export const tryGetSettingsPersonalInfo = () => {
    return async dispatch => {
        dispatch(setMessagesSettingsPersonalInfoReading(true));
        const personalInfo = await axiosReq('apiv1/settings/general', "GET", {}, devSettingsPersonalInfoData);
        dispatch(setMessagesSettingsPersonalInfoReading(false));
        return({...personalInfo, "error": ""})
    }
};

export const tryUpdateSettingsPersonalInfo = (data) => {
    return async dispatch => {
        dispatch(setMessagesSettingsPersonalInfoUpdating(true));
        const personalInfo = await axiosReq('apiv1/settings/general', "PUT", {companyName: data.companyName, first: data.first, last: data.last, phone: data.phone, email: data.email, timeZone: data.timeZone}, devSettingsPersonalInfoUpdateData(data));
        dispatch(setMessagesSettingsPersonalInfoUpdating(false));
        if(personalInfo.hasOwnProperty("error")) {
            return({"error": personalInfo.error});
        } else {
            return({"error": ""});
        }
    }
};