import { combineReducers } from 'redux';

/* Common */
import appointmentReducer from "./store/appointment";

const appointmentsReducer = combineReducers({
    appointment: appointmentReducer
});

export default appointmentsReducer;
