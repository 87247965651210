import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

/* Components */
import {ButtonSecondary, ButtonTertiary} from '../../../../common/components/buttons';
import {Toggle2Lables} from "../../../../common/components/inputs";
import {FontBody16, FontHeader16, FontTitle18} from '../../../../common/components/fonts';
import {colors} from '../../../../common/components/colors';
import {LogoLoading} from "../../../../common/components/loading";
import {CheckOutlined, EditOutlined, CloseCircleOutlined} from "@ant-design/icons";
import {InlineBlock, SaveSection, SubmitInputWithValidation, EditIcon, InlineItemLeft, InlineItemRight} from "../../components/pagescontact";

/* Middleware */
import {tryGetExpertProfile, tryUpdateExpertProfile} from "../../middleware/expertprofile";
import {FitContent} from "../../components/myprofile";

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

export const Invalid = styled.div`
    color: ${colors.alert100};
`;
const Green = styled.div`
    color: ${colors.progress100};
    display: inline-block;
    vertical-align: top;
`;
const Red = styled.div`
    color: ${colors.alert100};
    display: inline-block;
    vertical-align: top;
`;
const GrayColor = styled.div`
    color: ${colors.primary70};
`;

const RemoveMargin = styled.div`
    margin-bottom: -30px;
`;


class ContactOptions extends React.Component {
    state = {
        "isLoading": true,
        "scheduler": true,
        "schedulerPrevious": true,
        "schedulerIsChanged": false,
        "emailer": false,
        "emailerPrevious": false,
        "emailerIsChanged": false,
        "contactEdit": false,
        "isSaving": false,
        "isSaved": false,
        "warning": ""
    };

    componentDidMount() {
        return this.initiate();
    }

    initiate = async () => {
        await this.props.tryGetExpertProfile();
        await this.setState({
            "emailer": this.props.profile.get(this.props.emailerKey),
            "emailerPrevious": this.props.profile.get(this.props.emailerKey),
            "scheduler": this.props.profile.get(this.props.schedulerKey),
            "schedulerPrevious": this.props.profile.get(this.props.schedulerKey),
            "isLoading": false,
        });
    };

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    changeEmailer = async () => {
        await this.setState({"emailer": !this.state.emailer, "emailerIsChanged": this.state.emailerPrevious !== !this.state.emailer});
    };

    changeScheduler = async () => {
        await this.setState({"scheduler": !this.state.scheduler, "schedulerIsChanged": this.state.schedulerPrevious !== !this.state.scheduler});
    };

    edit = async (status) => {
        this.setState({"contactEdit": status});
    };

    cancel = async () => {
        this.setState({"emailer": this.state.emailerPrevious, "scheduler": this.state.schedulerPrevious, "contactEdit": false});
    };

    submitContact = async e => {
        e.preventDefault();
        this.setState({'isSaving': true, "warning": ""});
        const contact = {
            // "bio": this.props.profile.get("bio"),
            [this.props.emailerKey]: this.state.emailer,
            [this.props.schedulerKey]: this.state.scheduler
        };
        await this.props.tryUpdateExpertProfile(contact, this.props.errorKey);
        if(this.props.profile.get(this.props.errorKey) === "") {
            this.setState({
                'isSaving': false,
                'isSaved': true,
                "emailerPrevious": this.state.emailer,
                "schedulerPrevious": this.state.scheduler,
                "schedulerIsChanged": false,
                "emailerIsChanged": false,
                "contactEdit": false
            });
            this.timeout = setTimeout(() => {this.setState({"isSaved": false, 'isSaving': false})}, 3000);
        } else {
            this.setState({'isSaving': false, "warning": this.props.profile.get(this.props.errorKey)});
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if(this.state.contactEdit) {
                return(
                    <form method={"post"} onSubmit={this.submitContact}>
                        <FontTitle18>Contact Buttons</FontTitle18>
                        {/*<GrayColor><FontBody16>How would you like visitors to contact you?</FontBody16></GrayColor>*/}
                        <br />
                        <br />
                        <Toggle2Lables leftLabel={""} rightLabel={"Schedule a Meeting"} id={"toggle"} onChange={this.changeScheduler} value={this.state.scheduler} offColor={colors.primary70} onColor={colors.action100} />
                        <br />
                        <br />
                        <Toggle2Lables leftLabel={""} rightLabel={"Email Me"} id={"toggle"} onChange={this.changeEmailer} value={this.state.emailer} offColor={colors.primary70} onColor={colors.action100} />
                        <RemoveMargin />
                        <SaveSection>
                            <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.emailerIsChanged || this.state.schedulerIsChanged} isValid={true} isSaving={this.state.isSaving} /></InlineBlock>
                            <InlineBlock><span onClick={() => {return this.cancel()}}><ButtonTertiary canSubmit={false} label={"Cancel"} /></span></InlineBlock>
                        </SaveSection>
                    </form>
                )
            } else {
                if(this.state.isSaved) {
                    return(
                        <>
                            <FontTitle18>Contact Buttons</FontTitle18>
                            <br />
                            <br />
                            <br />
                            <FontHeader16><Green>Saved!</Green></FontHeader16>
                            <br />
                        </>
                    )
                } else {
                    return(
                        <>
                            <FontTitle18>Contact Buttons</FontTitle18>
                            {/*<GrayColor><FontBody16>How would you like visitors to contact you?</FontBody16></GrayColor>*/}
                            <br />
                            <br />
                            {this.state.scheduler
                                ? <><FontBody16><Green><CheckOutlined /></Green>&nbsp;Schedule a Meeting</FontBody16></>
                                : <><FontBody16><Red><CloseCircleOutlined /></Red>&nbsp;Schedule a Meeting</FontBody16></>
                            }
                            <br />
                            {this.state.emailer
                                ? <><FontBody16><Green><CheckOutlined /></Green>&nbsp;Email Me</FontBody16></>
                                : <><FontBody16><Red><CloseCircleOutlined /></Red>&nbsp;Email Me</FontBody16></>
                            }
                            <br />
                            <br />
                            <FitContent><FontBody16 onClick={() => {return this.edit(true)}}><ButtonSecondary canSubmit={false} label={<><EditOutlined/>&nbsp;Change Contact Options</>} /></FontBody16></FitContent>
                        </>
                    )
                }
            }
        }
    }
}

const mapStateToProps = state => ({
    profile: state.enterprise.profile.expertProfile.get("expertProfile")
});

const mapDispatchToProps = dispatch => ({
    tryGetExpertProfile: () => dispatch(tryGetExpertProfile()),
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactOptions));
