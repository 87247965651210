import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useParams, Redirect, useHistory } from "react-router-dom";
import styled from "styled-components";

/* Components */
import { colors } from "../../common/components/colors";
import { LogoLoading } from "../../common/components/loading";

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

const Background = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: ${colors.background100};
`;

const videoHandleMapping = {
    p1gmcq: "https://player.vimeo.com/video/894340415?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    "p1gmcq-test":
        "https://player.vimeo.com/video/894340415?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
};

const PlayerWrapper = styled.div`
    //position: relative;
    //padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    background-color: #000000;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    & .react-player {
        position: absolute;
        top: 0;
        left: 0;
    }
`;
const IFrameWebinarTrainingVideo = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [shouldRedirectToHome, setShouldRedirect] = useState(false);
    const { handle } = useParams();
    let history = useHistory();

    const init = () => {
        if (!(handle in videoHandleMapping)) setShouldRedirect(true);
        setIsLoading(false);
    };

    useEffect(() => init(), []);

    const onEnded = () => {
        history.push(`/advisor-signup?partnerCode=${handle}`);
    };

    if (isLoading)
        return (
            <Background>
                <LoadingCenter>
                    <LogoLoading />
                </LoadingCenter>
            </Background>
        );

    if (shouldRedirectToHome)
        return <Redirect to={{ pathName: "/verify", state: { from: "*" } }} />;

    return (
        <PlayerWrapper>
            <ReactPlayer
                className={"react-player"}
                url={videoHandleMapping[handle]}
                onEnded={onEnded}
                width={"100%"}
                height={"100vh"}
                controls={true}
            />
        </PlayerWrapper>
    );
};
export default IFrameWebinarTrainingVideo;
