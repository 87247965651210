import React, {useState} from 'react';
import styled from 'styled-components';

/* Components */
import {TextareaInputV2} from '../../../common/components/inputs';
import {ContactBottom, ContactContent, Level1, Level2, Level3} from "../components/contactcontainer";
import ContactTop from "../components/contacttop";
import ContactButtons from '../components/contactbuttons';

const StyledTextArea = styled.div`
  width: 100% !important;
  & > div {
    margin: 0;
  }
  #story {
    min-height: 325px;
    font-size: 20px;
    line-height: 1.5;
    @media screen and (max-width: 748px) {
      font-size: 16px;
    }
  }
`;

ContactPage3.defaultProps = {
    firstName: "the advisor",
    setJourneys: () => {},
    setJourneyDetails: () => {},
    setStep: () => {},
    show: false,
}

export default function ContactPage3(props) {
    const show = props.show;
    const setStep = props.setStep;
    const firstName = props.firstName;
    const setJourneyDetails = props.setJourneyDetails;
    const [changed, setChanged] = useState(false);
    const [value, setValue] = useState('');

    return (
        <Level1 toggle={show}>
            <Level2 animate={show}>
                <Level3>
                    <ContactTop title={"Share your life journeys"} />
                    <ContactBottom>
                        <ContactContent>
                            <StyledTextArea>
                                <TextareaInputV2
                                    warning={"Please complete this information"}
                                    maxLength={1000}
                                    id={"story"}
                                    placeholder={"What are your financial goals and unique life journeys so we can connect you with the best financial advisor for you? You may want to include your profession (doctor, lawyer, CEO of the household), if you support anyone (children, parents, pets), where you live, and what you envision for your future!"}
                                    count={0}
                                    minLength={0}
                                    required={false}
                                    minRows={5}
                                    onChange={(e) => {
                                        const newVal = e.target.value;
                                        setChanged(true);
                                        setValue(newVal === "" ? null : newVal);
                                        setJourneyDetails(newVal === "" ? null : newVal);
                                    }}
                                    cols={60}
                                    valid={!changed ? true : (value === null ? false : value.length > 0)}
                                    value={value}
                                />
                            </StyledTextArea>
                        </ContactContent>
                        <ContactButtons disabledNext={value === null || value === ""} setStep={setStep} />
                    </ContactBottom>
                </Level3>
            </Level2>
        </Level1>
    );
}