import React from 'react';
import styled from "styled-components";
import {FontSubtitle28, FontBody16, FontTitle24} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";

export const PageTitle = styled(FontSubtitle28)`
    text-align: center;
`;

export const Extra54px = styled.div`
    height: 54px;
    width: 100%;
`;
export const PageSubtitle = styled(FontBody16)`
    text-align: center;
    margin: 20px auto 50px auto;
    color: ${colors.secondary100};
    max-width: 325px;
`;

export const SwiperContainer = styled.div`
    .swiperslide {
        width: 400px;
        max-width: 70%;
        @media screen and (max-width: 1088px) {
            max-width: 80%;
        }
        justify-content: center;
        align-items: center;
        display: flex;
        flex-shrink: 0;
        height: 100%;
        position: relative;
        padding-bottom: 20px;
    }
`;
export const SlideContainer = styled.div`
    max-width: calc(100% - 32px);
    position: relative;

    ${({active}) => active && `{
        cursor: pointer;
    }`}

    ${({ active, isNext, isPrev }) => !active && (isNext || isPrev) && `{
        opacity: 0.5;
    }`}

    ${({ active, isNext, isPrev }) => !active && !(isNext || isPrev) && `{
        opacity: 0.4;
    }`}

    ${({ active, isNext, isPrev }) => !active &&`{
        transform: scale(0.8);
        @media screen and (max-width: 1088px) {
            transform: scale(0.95);
        }
    }`}

    transition: all 0.8s;
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 90%;
        left: 0;
        bottom: 0;
        border-radius: 8px;
        z-index: 1;
        transform-origin: center bottom;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, .7);
    }
`;

export const SlideImage = styled.img`
    width: 100%;
    height: 50vh;
    object-fit:cover;
    border-radius: 8px;
    display: block;
    margin: 0;
    position: relative;
    z-index: 2;
`;

export const SlideImageOverlay = styled.div`
    position: absolute;
    bottom: 0;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    z-index: 3;
`;

export const ProgramTextContainer = styled.div`
    box-sizing: border-box;
    padding: 8px 16px;
    color: #fff;
    position: absolute;
    left:0%;
    bottom: 20%;
    width: 100%;
    z-index: 10;
`;

export const ProgramTitle = styled.div`
    font-size: 48px;
    text-align: left;
    font-weight: 600;

    @media screen and (max-width: 748px) {
        font-size: 36px;
    }
    @media screen and (max-width: 460px) {
        font-size: 26px;
    }
`;

export const ProgramTime = styled.div`
    font-size: 20px;
    text-align: left;
    margin-top: 20px;

    @media screen and (max-width: 748px) {
        font-size: 18px;
    }
    @media screen and (max-width: 460px) {
        font-size: 14px;
    }
`;

export const Flex = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0px 0px 40px 0px;
    cursor: pointer;
`;

export const ListImage = styled.img`
    aspect-ratio: 4 / 3;
    width: 45%;
    height auto;
    object-fit: cover;
    border-radius: 8px;
    margin: 0;
    position: relative;
    z-index: 2;
    margin-right: 5%;
`;

export const ListTitle = styled(FontTitle24)`
    @media screen and (max-width: 460px) {
        font-size: 22px;
        line-height: 28px
    }
`;

export const ListTime = styled(FontBody16)`
    @media screen and (max-width: 460px) {
        font-size: 14px;
    }
`;