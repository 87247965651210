import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/* Store */
import { setNavPageStatus } from '../../../common/containers/navexpanded/store/nav';
import {setSupportOpen} from "../../support/store/support";

/* Components */
import { FontBody18, FontSubtitle38To30 } from '../../../common/components/fonts';
import {NavPage, Main, LinkStyled, ModalStyled} from '../components/primarymobile';

const NavPrimaryMobile = ({ showSettings, data, location, pageStatusActive, setNavPageStatus, setSupportOpen, isWMC, user }) => (
    <NavPage status={pageStatusActive}>
        <>
            {data[(showSettings ? "settings" : "main")].map((d, dIndex) => {
                let array = showSettings ? d.options : d;
                return(
                    <Main key={dIndex}>
                        {array.map((p, index) => {
                            let status = "inactive";
                            if(location.pathname === p.href) {status = "active"}
                            if(isWMC && p.showWMC) {
                                if(p.href === "modalSupport" || p.href === "modalFeedback") {
                                    let page = p.href === "modalFeedback" ? 6 : 1;
                                    return(
                                        <ModalStyled
                                            key={index}
                                            onClick={() => {setSupportOpen(true, page);setNavPageStatus(!pageStatusActive)}}
                                        >
                                            <FontBody18>{p.label}</FontBody18>
                                        </ModalStyled>
                                    )
                                } else {
                                    return(
                                        <LinkStyled
                                            key={index}
                                            status={status}
                                            to={p.href}
                                            onClick={() => setNavPageStatus(!pageStatusActive)}
                                        >
                                            <FontBody18>{p.label}</FontBody18>
                                        </LinkStyled>
                                    )
                                }
                            } else if(!isWMC && p.showCoach) {
                                if ((user.get("hasPartners") && (p.href === "/partners")) || (p.href !== "/partners")) {
                                    if(p.href === "modalSupport" || p.href === "modalFeedback") {
                                        let page = p.href === "modalFeedback" ? 6 : 1;
                                        return(
                                            <ModalStyled
                                                key={index}
                                                onClick={() => {setSupportOpen(true, page);setNavPageStatus(!pageStatusActive)}}
                                            >
                                                <FontBody18>{p.label}</FontBody18>
                                            </ModalStyled>
                                        )
                                    } else {
                                        if(p.showCoachCategoryIds === "all") {
                                            return (
                                                <LinkStyled
                                                    key={index}
                                                    status={status}
                                                    to={p.href}
                                                    onClick={() => setNavPageStatus(!pageStatusActive)}
                                                >
                                                    <FontBody18>{p.label}</FontBody18>
                                                </LinkStyled>
                                            )
                                        } else {
                                            const overlap = p.showCoachCategoryIds.filter(value => {
                                                const userCategoryIds = user.get("expertCategories").map(c => {
                                                    return (c.get("categoryId"))
                                                });
                                                return userCategoryIds.includes(value)
                                            });
                                            if (overlap.length > 0) {
                                                return(
                                                    <LinkStyled
                                                        key={index}
                                                        status={status}
                                                        to={p.href}
                                                        onClick={() => setNavPageStatus(!pageStatusActive)}
                                                    >
                                                        <FontBody18>{p.label}</FontBody18>
                                                    </LinkStyled>
                                                )
                                            } else {
                                                return null
                                            }
                                        }
                                    }
                                } else {
                                    return null
                                }
                            } else {
                                return null
                            }
                        })}
                    </Main>
                )
            })}
        </>
    </NavPage>
);

const mapStateToProps = state => ({
    pageStatusActive: state.common.nav.get('pageStatusActive'),
    isWMC: state.common.wmc.get("isWMC"),
    user: state.common.user
});

const mapDispatchToProps = dispatch => ({
    setNavPageStatus: (status) => dispatch(setNavPageStatus(status)),
    setSupportOpen: (isOpen, page) => dispatch(setSupportOpen(isOpen, page))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavPrimaryMobile));
