import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import validator from "validator";

/* Components */
import {ButtonPrimary, ButtonInactive} from "../../../common/components/buttons";
import {FontBody14, FontHeader16, FontBody16, FontHeader21, FontBody21} from "../../../common/components/fonts";
import {DisclosureText, ProductTermPrivacy, FullWidthNotButton, LoginBtn, Left, Right, PhoneEmpty, Mobile, Container, Email, Top, Bottom, Phone, FullWidth, Disclosure, LoaderStyle, FullWidthNotButtonText, Skip} from '../components/payment';
import {PhoneInput, TextInput} from "../../../common/components/inputs";
import {LogoLoadingSmall} from "../../../common/components/loading";

/* Middleware */

function HRCode(props) {
    const user = useSelector(state => state.common.user);

    const [inModal, setInModal] = useState(false);
    const [fromDashboard, setFromDashboard] = useState(false);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [userExists, setUserExists] = useState(false);
    const [code, setCode] = useState("");
    const [codeValid, setCodeValid] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const [isPurchasing, setIsPurchasing] = useState(false);

    const [error, setError] = useState(null);

    useEffect(() => {
        const init = async () => {
            setInModal(props.inModal);
            setFromDashboard(props.fromDashboard);
            setUserLoggedIn(props.userLoggedIn);
        };
        init();
    }, []);

    useEffect(() => {
        setCodeValid((!validator.isEmpty(code) && code !== ''));
        setIsValid((!validator.isEmpty(code) && code !== ''));
    }, [code]);

    const handleChangeCode = e => setCode(e.target.value);

    const handleSubmit = async e => {
        e.preventDefault();
        const valid = await props.checkCode(code)
        setIsPurchasing(true);
        if (valid) {
            setIsPurchasing(true);
            setError(null);
            await props.codeSuccess();
        } else {
            setError("Invalid code.")
        }
        setIsPurchasing(false);
    };

    return(
        <form method={"post"} onSubmit={handleSubmit}>
            <Container inModal={inModal}>
                <>
                    <>
                        <div>
                            <Left><FontHeader21>Enter Code</FontHeader21></Left>
                            <Right><FontBody16>Don't have one?&nbsp;<LoginBtn onClick={props.skip}><FontHeader16>Skip</FontHeader16></LoginBtn></FontBody16></Right>
                        </div>
                        {userLoggedIn && !fromDashboard &&
                            <Email><FontBody21>Welcome back {user.get("first")}!</FontBody21></Email>
                        }
                        <PhoneEmpty />
                    </>
                    <Email>
                        <TextInput
                            title={"HR Code"}
                            valid={codeValid && error === null}
                            warning={"Enter a valid code"}
                            id={"code"}
                            onChange={handleChangeCode}
                            placeholder={"HR Code"}
                            value={code}
                        />
                    </Email>
                    <br />
                    {isPurchasing
                        ? <LoaderStyle><LogoLoadingSmall /></LoaderStyle>
                        : <>
                            {isValid
                                ? <FullWidth oneLevel={isValid}><ButtonPrimary canSubmit={true} label={"Continue"}/></FullWidth>
                                : <FullWidthNotButtonText><ButtonInactive canSubmit={false} label={"Continue"}/></FullWidthNotButtonText>
                            }
                        </>
                    }
                </>
            </Container>
        </form>
    )
}

export default HRCode;