import React from 'react';
import styled from 'styled-components';

import {colors} from './colors';
import {FontHeader18, FontTitle21, FontBody16 } from "./fonts";

/* Utility */
import { hexToRGB, brightness } from '../utils/colors';

export const ShadowCard = styled.div`
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
`;
export const ShadowPopup = styled.div`
    -webkit-box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
`;
export const Dark = styled.div`
    background-color: ${colors.secondary100};
    border-radius: 4px;
    padding: 30px;
    color: ${colors.white};
`;
export const White = styled.div`
    background-color: ${colors.white};
    border-radius: 4px;
    padding: 30px;
    color: ${colors.primary100};
`;



export const SettingsCard = styled.div`
    -webkit-box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    background-color: ${colors.white};
    border-radius: 4px;
    padding: 30px 50px;
    color: ${colors.primary100};
    max-width: ${props => props.maxWidth};
    margin: 0px auto 40px auto;
    @media screen and (max-width: 748px) {
        margin: 40px auto 0px auto;
    }
    @media screen and (max-width: 460px) {
        margin: 20px auto 0px auto;
        padding: 30px 20px;
    }
`;


const Title = styled.div`
    display: inline-block;
    margin-bottom: 20px;
`;
const Saved = styled.div`
    ${props => props.error === true} {color: ${colors.progress100};}
    ${props => props.error !== true} {color: ${colors.alert100};}
    margin: ${props => props.height} auto ${props => props.height} auto;
    text-align: center;
`;

export const SettingsCardTitle = ({title}) => (
    <Title><FontTitle21>{title}</FontTitle21></Title>
);

export const SavedSettings = ({height, message, error}) => (
    <Saved height={height} error={error}><FontHeader18>{message}</FontHeader18></Saved>
);

export const MyMoneyCard = styled.div`
    -webkit-box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    background-color: ${colors.white};
    border-radius: 4px;
    padding: ${props => props.noTitle ? '20px' : '0'} 20px 20px 20px;
    color: ${colors.primary100};
    max-width: ${props => props.maxWidth};
    margin: 0px auto 20px auto;
    border-left: ${props => props.useNewDesign ? 'none' : '6px solid ' + props.color};
    background-color: ${props => props.useNewDesign && props.backgroundColor ? props.backgroundColor : colors.white};
    border-radius: ${props => props.useNewDesign ? '30px 0px' : '4px'};
    color: ${props => props.backgroundColor ? (brightness(hexToRGB(props.backgroundColor, false)) < 130 ? colors.white : colors.primary100) : colors.white};
`;
const MyMoneyTitle = styled(FontBody16)`
    position: ${props => props.useNewDesign ? 'relative' : 'sticky'};
    top: 0;
    padding-top: 20px;
    padding-bottom: 15px;
    margin-bottom: 5px;
    background: ${props => props.useNewDesign ? 'none' : colors.white};
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: ${props => props.useNewDesign ? 'auto' : 2};
`;
export const MyMoneyCardTitle = ({children, useNewDesign=false}) => (
    <MyMoneyTitle useNewDesign={useNewDesign}>{children}</MyMoneyTitle>
);