// Default
const willow = {

    alert100: '#FF9180',
    alert70: '#FFBDB3',
    alert30: '#FFDED9',
    alert5: '#ffe9e6',

    notification100: '#E3DA6A',
    notification70: '#EEE9A6',
    notification30: '#F7F4D2',
    notification15: '#FDF8E7',

    action100: '#D6B88B',
    action70: '#e4d1b4',
    action30: '#f2e8d9',
    action15: '#f8f3ec',

    progress100: '#469B77',
    progress70: '#90C3AD',
    progress30: '#C7E1D6',
    progress5: '#F6FAF8',

    border100: '#E9ECEF',
    border70: '#F2F4F5',
    border30: '#F8F9FA',

    primary100: '#394950',
    primary70: '#748386',
    primary30: '#959CA3',

    secondary100: '#777A69',
    secondary70: '#C1BAAF',
    secondary30: '#CFD5D9',

    background100: '#FDFBFB',
    background30: '#E9ECEF',
    background15: '#F8F9FA',

    white: '#FFFFFF',
    black: '#000000',
    brand: '#D3BB6F',

    stoplightStop100: '#ff644d',
    stoplightStop30: '#FFDED9',

    stoplightSlow100: '#aba221',
    stoplightSlow30: '#F7F4D2',

    stoplightGo100: '#469B77',
    stoplightGo30: '#C7E1D6',

    // Serve these as strings
    learnCenterBackgrounds: [
        '#e3e7e5',  // career
        '#f2edeb',  // dating
        '#dde0e0',  // divorce
        '#f2ede3',  // family
        '#ddddd9',  // financial
        '#f9f8f5',  // grief
        '#dde0e0',  // health
        '#eeedeb'   // retirement
    ],

    graphColors: [
        '#38726c',
        '#e9d758',
        '#ff8552',
        '#c14953',
        '#e06c9f',
        '#966b9d',
        '#2b3a67',
        '#2c91a0',
        '#b07156',
        '#bfd7ea',
        '#009FFD',
        '#5D5D74',
        '#BFC3C6',
    ],

    graphColors2: [
        '#ECE5DA',
        '#748386',
        '#D6B88B',
        '#8D9F97',
        '#EADDD9',
        '#3F5873',
        '#C1BAAF',
        '#394950',
        '#D3BB6F',
        '#777A69',
        '#EEE9A6',
        '#FFFFFF',
        '#000000',
    ],

    cellColors: [
        '#ECE5DA',
        '#394950',
        '#E9E9E9',
        '#EADDD9',
    ],
    profileColors: [
        '#3F5873',
        '#748386',
        '#8D9F97',
    ],
    prospectColors: [
        '#777A69',
        '#8D9F97',
        '#748386',
    ],
    backgroundColor1: '#ECE5DA',
    backgroundColor2: '#748386',
    backgroundColor3: '#8D9F97',
    backgroundColor4: '#EADDD9',
    backgroundColor5: '#3F5873',
    backgroundColor6: '#C1BAAF',
    backgroundColor7: '#777A68',
    backgroundColorProgressInactive: '#EADDD9',
    backgroundColorProgressPartial: '#C1C9C5',
    backgroundChatBubble: '#E9E9E9',

    canvaGold: '#d6b88b',
    canvaBrown: '#c1baaf',
    canvaSand: '#ece5da',
    canvaBlush: '#eaddd9',
    canvaDarkGreen: '#748386',
    canvaGreen: '#8d9f97',
    canvaDarkBlue: '#394950',
    canvaBlue: '#3f5873',
    canvaGray: '#e9e9e9',

};

// Principal
const principal = {

    alert100: '#FF9180',
    alert70: '#FFBDB3',
    alert30: '#FFDED9',
    alert5: '#ffe9e6',

    notification100: '#E3DA6A',
    notification70: '#EEE9A6',
    notification30: '#F7F4D2',
    notification15: '#FDF8E7',

    action100: '#00C4D9',
    action70: '#94D8FA',
    action30: '#BBE6FC',
    action15: '#E3F5FD',

    progress100: '#469B77',
    progress70: '#90C3AD',
    progress30: '#C7E1D6',
    progress5: '#F6FAF8',

    border100: '#E9ECEF',
    border70: '#F2F4F5',
    border30: '#F8F9FA',

    primary100: '#002855',
    primary70: '#003865',
    primary30: '#0061A0',

    secondary100: '#315470',
    secondary70: '#007FA3',
    secondary30: '#00C4D9',

    background100: '#FDFBFB',
    background30: '#E9ECEF',
    background15: '#F8F9FA',

    white: '#FFFFFF',
    black: '#000000',
    brand: '#D3BB6F',

    stoplightStop100: '#ff644d',
    stoplightStop30: '#FFDED9',

    stoplightSlow100: '#aba221',
    stoplightSlow30: '#F7F4D2',

    stoplightGo100: '#469B77',
    stoplightGo30: '#C7E1D6',

    // Serve these as strings
    learnCenterBackgrounds: [
        '#e3e7e5',  // career
        '#f2edeb',  // dating
        '#dde0e0',  // divorce
        '#f2ede3',  // family
        '#ddddd9',  // financial
        '#f9f8f5',  // grief
        '#dde0e0',  // health
        '#eeedeb'   // retirement
    ],

    graphColors: [
        '#38726c',
        '#e9d758',
        '#ff8552',
        '#c14953',
        '#e06c9f',
        '#966b9d',
        '#2b3a67',
        '#2c91a0',
        '#b07156',
        '#bfd7ea',
        '#009FFD',
        '#5D5D74',
        '#BFC3C6',
    ],

    graphColors2: [
        '#ECE5DA',
        '#748386',
        '#D6B88B',
        '#8D9F97',
        '#D2F1FD',
        '#3F5873',
        '#C1BAAF',
        '#394950',
        '#D3BB6F',
        '#777A69',
        '#EEE9A6',
        '#FFFFFF',
        '#000000',
    ],

    cellColors: [
        '#ECE5DA',
        '#394950',
        '#E9E9E9',
        '#D2F1FD',
    ],
    profileColors: [
        '#3F5873',
        '#748386',
        '#8D9F97',
    ],
    prospectColors: [
        '#777A69',
        '#8D9F97',
        '#748386',
    ],
    backgroundColor1: '#D2F1FD',
    backgroundColor2: '#748386',
    backgroundColor3: '#00C4D9',
    backgroundColor4: '#D2F1FD',
    backgroundColor5: '#3F5873',
    backgroundColor6: '#62b5e5',
    backgroundColor7: '#777A68',
    backgroundColorProgressInactive: '#D2F1FD',
    backgroundColorProgressPartial: '#C1C9C5',
    backgroundChatBubble: '#E9E9E9'

};


const jpmorgan = {
    alert100: '#FF9180',
    alert70: '#FFBDB3',
    alert30: '#FFDED9',
    alert5: '#ffe9e6',

    notification100: '#E3DA6A',
    notification70: '#EEE9A6',
    notification30: '#F7F4D2',
    notification15: '#FDF8E7',


    primary100: '#00477b',
    primary70: '#005075',

    secondary100:'#212529',

    background100: '#fff',
    background70: '#f1f0ef',

    action100: '#1a7b99',


    backgroundColor1: '#535251',
    backgroundColor2: '#5f5d5d',
    backgroundColor3: '#d5d3d3',
    backgroundColor4: '#f2f0f1',

    stoplightGo100:'#41770d',

    cellColors: [
        '#ECE5DA',
        '#394950',
        '#E9E9E9',
        '#EADDD9',
    ],
    profileColors: [
        '#3F5873',
        '#748386',
        '#8D9F97',
    ],
    prospectColors: [
        '#777A69',
        '#8D9F97',
        '#748386',
    ],

    // Serve these as strings
    learnCenterBackgrounds: [
        '#e3e7e5',  // career
        '#f2edeb',  // dating
        '#dde0e0',  // divorce
        '#f2ede3',  // family
        '#ddddd9',  // financial
        '#f9f8f5',  // grief
        '#dde0e0',  // health
        '#eeedeb'   // retirement
    ],

    graphColors: [
        '#38726c',
        '#e9d758',
        '#ff8552',
        '#c14953',
        '#e06c9f',
        '#966b9d',
        '#2b3a67',
        '#2c91a0',
        '#b07156',
        '#bfd7ea',
        '#009FFD',
        '#5D5D74',
        '#BFC3C6',
    ],

    graphColors2: [
        '#ECE5DA',
        '#748386',
        '#D6B88B',
        '#8D9F97',
        '#D2F1FD',
        '#3F5873',
        '#C1BAAF',
        '#394950',
        '#D3BB6F',
        '#777A69',
        '#EEE9A6',
        '#FFFFFF',
        '#000000',
    ],

};

const edwardjones = {
    alert100: '#ef674a',
    alert70: '#f59c89',
    alert30: '#efc4bb',
    alert5: '#f8edea',

    notification100: '#0076A0',
    notification70: '#389cc0',
    notification30: '#87c1d5',
    notification15: '#c0d9e3',

    action100: '#f7ca3d',
    action70: '#f6e59e',
    action30: '#fdf3c9',
    action15: '#f6f3e5',

    progress100: '#009e7d',
    progress70: '#8ec5b3',
    progress30: '#c6ece0',
    progress5: '#ebfaf5',

    border100: '#dedede',
    border70: '#eaeaea',
    border30: '#f8f8f8',

    primary100: '#3b3d3f',
    primary70: '#767576',
    primary30: '#c0bec0',

    secondary100: '#3b3d3f',
    secondary70: '#767576',
    secondary30: '#c0bec0',

    background100: '#dedede',
    background30: '#eaeaea',
    background15: '#f8f8f8',

    white: '#FFFFFF',
    black: '#000000',
    brand: '#fad141',

    stoplightStop100: '#ef674a',
    stoplightStop30: '#efc4bb',

    stoplightSlow100: '#f7ca3d',
    stoplightSlow30: '#fff7dd',

    stoplightGo100: '#009e7d',
    stoplightGo30: '#c6ece0',

    // Serve these as strings
    learnCenterBackgrounds: [
        '#dedede',  // career
        '#dedede',  // dating
        '#dedede',  // divorce
        '#dedede',  // family
        '#dedede',  // financial
        '#dedede',  // grief
        '#dedede',  // health
        '#dedede'   // retirement
    ],

    graphColors: [
        '#38726c',
        '#e9d758',
        '#ff8552',
        '#c14953',
        '#e06c9f',
        '#966b9d',
        '#2b3a67',
        '#2c91a0',
        '#b07156',
        '#bfd7ea',
        '#009FFD',
        '#5D5D74',
        '#BFC3C6',
    ],

    graphColors2: [
        '#ECE5DA',
        '#748386',
        '#D6B88B',
        '#8D9F97',
        '#D2F1FD',
        '#3F5873',
        '#C1BAAF',
        '#394950',
        '#D3BB6F',
        '#777A69',
        '#EEE9A6',
        '#FFFFFF',
        '#000000',
    ],

    cellColors: [
        '#3b3d3f',
        '#767576',
        '#c0bec0',
    ],
    profileColors: [
        '#3b3d3f',
        '#767576',
        '#c0bec0',
    ],
    prospectColors: [
        '#3b3d3f',
        '#767576',
        '#c0bec0',
    ],
    backgroundColor1: '#dedede',
    backgroundColor2: '#3b3d3f', // #fef8db on advisor
    backgroundColor3: '#fad141',
    backgroundColor4: '#f5f5f5',
    backgroundColor5: '#fad141',
    backgroundColor6: '#dedede',
    backgroundColor7: '#fef8db',
    backgroundColorProgressInactive: '#f1f3f4',
    backgroundColorProgressPartial: '#e2eef5',
    backgroundChatBubble: '#e2eef5',

    canvaGold: '#d6b88b',
    canvaBrown: '#c1baaf',
    canvaSand: '#ece5da',
    canvaBlush: '#eaddd9',
    canvaDarkGreen: '#748386',
    canvaGreen: '#8d9f97',
    canvaDarkBlue: '#394950',
    canvaBlue: '#3f5873',
    canvaGray: '#e9e9e9',
};


const chooseColors = () => {
    if(window.location.href.includes("principal.trustwillow.com")) {
        return(principal)
    }
    else if(window.location.href.includes("jpmorgan.trustwillow.com")) {
        return(jpmorgan)
    }
    else if(window.location.href.includes("edwardjones.trustwillow.com")) {
        return(edwardjones)
    }
    return(willow)
}

export default chooseColors();