import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import styled from "styled-components";

/* Components */
import {DropdownInput, TextInput, PhoneInput} from "../../../common/components/inputs";
import {SavedSettings} from '../../../common/components/cards';

/* Middleware */
import {tryUpdateSettingsPersonalInfo, tryGetSettingsPersonalInfo} from '../middleware/settingspersonalinfo';

/* Utils */
import {timeZoneOptions} from '../../../common/utils/timezone2';
import {colors} from "../../../common/components/colors";
import {ButtonPrimary, ButtonInactive} from "../../../common/components/buttons";

const SettingsCard = styled.div`
    background-color: ${colors.backgroundColor1};
    border-radius: 20px;
    padding: 0px 30px 30px 30px;
    max-width: 450px;
    width: calc(100% - 60px);
    margin: 0px auto 0px auto;
    & input {
        border-radius: 20px;
        background-color: ${colors.white};
        border: none;
        line-height: 25px;
    }
    & .Dropdown-control {
        border-radius: 20px;
        background-color: ${colors.white};
        border: none;
    }
    & .Dropdown-menu {
        background-color: ${colors.white};
        border: 1px solid ${colors.backgroundColor3};
        border-radius: 6px;
    }
    & form > div > div {
        color: ${colors.primary100};
    }
    & Button {
        margin: 20px 0px 0px auto;
    }
`;

const ButtonSection = styled.div`
    width: 100%;
    text-align: right;
`;

class SettingsPersonalInfo extends React.Component {
    state = {
        first: '', firstValid: true,
        last: '', lastValid: true,
        phone: '', phoneValid: true,
        email: '', emailValid: true,
        companyName: '', companyNameValid: true,
        timeZone: '',
        isSaving: false,
        isSaved: false,
        error: '',
        isChanged: false,
        isValid: true
    };

    componentDidMount() {
        Promise.resolve(this.props.tryGetSettingsPersonalInfo())
            .then(() => {
                if(this.props.settingsPersonalInfo.get("last") === "") {
                    this.setState({...this.props.settingsPersonalInfo.toJS(), "first": ""})
                } else {
                    this.setState(this.props.settingsPersonalInfo.toJS())
                }
            })
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    handleChangeFirst = async e => {
        this.setState({'first': e.target.value});
        await this.setState({'firstValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeLast = async e => {
        this.setState({'last': e.target.value});
        await this.setState({'lastValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeCompanyName = async e => {
        this.setState({'companyName': e.target.value});
        await this.setState({'companyNameValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeEmail = async e => {
        this.setState({'email': e.target.value});
        await this.setState({'emailValid': validator.isEmail(e.target.value)});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangePhone = async e => {
        this.setState({'phone': e.target.value});
        await this.setState({'phoneValid': validator.isMobilePhone(e.target.value, 'en-US')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeTimeZone = e => {
        this.setState({'timeZone': e.value});
        this.setState({'isChanged': true});
    };

    checkValid = () => {
        if(this.state.firstValid && this.state.lastValid /* && this.state.phoneValid */ && this.state.emailValid && this.state.companyNameValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleSubmit = e => {
        e.preventDefault();
        if(this.state.firstValid && this.state.lastValid /* && this.state.phoneValid */ && this.state.emailValid && (this.props.platform !== "consumer" ? this.state.companyName : true)) {
            this.setState({'isSaving': true, 'error': ''});
            Promise.resolve(this.props.tryUpdateSettingsPersonalInfo(this.state))
                .then(() => {
                    if(this.props.settingsPersonalInfo.get("error") !== '') {
                        this.setState(this.props.settingsPersonalInfo.toJS());
                    }
                    this.setState({'isSaving': false, 'isSaved': true, isChanged: false});
                    this.timeout = setTimeout(() => {this.setState({'isSaved': false})}, 3000);
                })
        }
    };

    render() {
        return(
            <SettingsCard maxWidth={"460px"}>
                {/*<SettingsCardTitle title={"Personal info"} />*/}
                {this.state.isSaved
                    ?
                        <>
                            {this.state.error === '' &&
                                <SavedSettings height={"273px"} error={false} message={"Saved"}/>
                            }
                            {this.state.error !== '' &&
                                <SavedSettings height={"273px"} error={true} message={this.state.error}/>
                            }
                        </>
                    :
                    <>
                        <form method={"post"} onSubmit={this.handleSubmit}>
                            <TextInput
                                title={"First Name"}
                                valid={this.state.firstValid}
                                warning={"Required"}
                                id={"first"}
                                onChange={this.handleChangeFirst}
                                placeholder={"First Name"}
                                value={this.state.first}
                            />
                            <TextInput
                                title={"Last Name"}
                                valid={this.state.lastValid}
                                warning={"Required"}
                                id={"last"}
                                onChange={this.handleChangeLast}
                                placeholder={"Last Name"}
                                value={this.state.last}
                            />
                            {this.props.platform !== "consumer" &&
                                <TextInput
                                    title={"Company Name"}
                                    valid={this.state.companyNameValid}
                                    warning={"Required"}
                                    id={"companyName"}
                                    onChange={this.handleChangeCompanyName}
                                    placeholder={"Company Name"}
                                    value={this.state.companyName}
                                />
                            }
                            <PhoneInput
                                title={"Phone"}
                                valid={this.state.phoneValid}
                                warning={"Enter a valid mobile phone"}
                                id={"phone"}
                                onChange={this.handleChangePhone}
                                placeholder={'(###) ###-####'}
                                value={this.state.phone}
                                options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}}
                            />
                            <TextInput
                                title={"Email"}
                                valid={this.state.emailValid}
                                warning={"Enter a valid email address"}
                                id={"email"}
                                onChange={this.handleChangeEmail}
                                placeholder={"Email"}
                                value={this.state.email}
                            />
                            <DropdownInput
                                title={"Time zone"}
                                options={timeZoneOptions}
                                id={'timeZone'}
                                onChange={this.handleChangeTimeZone}
                                placeholder={'Time Zone'}
                                value={this.state.timeZone}
                            />
                            <ButtonSection>
                                {(this.state.isSaving || !this.state.isChanged || (this.state.isChanged && !this.state.isValid))
                                    ? <ButtonInactive canSubmit={true} label={"Update"} />
                                    : <ButtonPrimary canSubmit={true} label={"Update"} />
                                }
                            </ButtonSection>
                        </form>
                    </>
                }
            </SettingsCard>
        )
    }
}

const mapStateToProps = state => ({
    platform: state.common.user.get("platform"),
    settingsPersonalInfo: state.consumer.settings.settings.get("settingsPersonalInfo")
});
const mapDispatchToProps = dispatch => ({
    tryUpdateSettingsPersonalInfo: (data) => dispatch(tryUpdateSettingsPersonalInfo(data)),
    tryGetSettingsPersonalInfo: () => dispatch(tryGetSettingsPersonalInfo())
});
export default connect(mapStateToProps, mapDispatchToProps)(SettingsPersonalInfo);
