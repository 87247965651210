import React from "react";
import styled from 'styled-components';

/* Components */
import {ActionBox} from "./clientsdetail";
import {FontTitle18} from "../../common/components/fonts";
import {LogoLoadingSmall} from "../../common/components/loading";
import {colors} from '../../common/components/colors';

export const Logo = styled.div`
    text-align: center;
    margin: 30px 0px 10px 0px;
    & img {
        width: 75px;
        height: auto;
    }
`;
export const Photo = styled.div`
    text-align: center;
    margin: 30px 0px 10px 0px;
    & img {
        width: 150px;
        height: auto;
        border-radius: 6px;
    }
`;
export const Center = styled.div`
    text-align: center;
    & div {
        margin: 0px auto;
    }
`;
export const Advertisement = styled.div`
    margin-bottom: 20px;
    text-align: center;
    padding: 0px 10px;
    width: calc(100% - 20px);
`;
export const SessionCount = styled.div`
    margin: 15px auto;
    width: 200px;
`;
export const Green = styled.div`
    color: ${colors.progress100};
`;
export const Gray = styled.div`
    color: ${colors.secondary70};
`;
export const Border = styled.div`
    border-bottom: 1px solid ${colors.border100};
    width: 100%;
    margin: 20px 0px;
`;
export const SourceTitle = styled.div`
    margin: 20px 0px -15px 0px;
    color: ${colors.primary70};
`;
export const Cursor = styled.div`
    & div {
        cursor: pointer;
    }
`;
export const HideCoach = styled.div`
    ${props => props.show === false} {display: block;}
    ${props => props.show === true} {display: none;}
`;

export const CoachingLoading = () => (
    <ActionBox>
        <FontTitle18>Coaching</FontTitle18>
        <br />
        <br />
        <LogoLoadingSmall />
        <br />
    </ActionBox>
);
