import React from "react";
import styled from "styled-components";
import moment from 'moment/moment';

/* Components */
import {FontBody16, FontBody18, FontTitle30, FontSubtitle38To30, FontSubtitle21, FontHeader16} from "../../../common/components/fonts";
import {ButtonLinkInactive, ButtonTertiaryAlert} from "../../../common/components/buttons";
import {StopOutlined, ArrowLeftOutlined} from "@ant-design/icons";

/* Static */
import {images} from "../../../common/components/images";
import {colors} from "../../../common/components/colors";

const Title = styled.div`
    margin-bottom: 15px;
`;

const Center = styled.div`
    text-align: center;
    color: ${colors.primary100};
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
`;
const BackTop = styled.div`
    font-size: 20px;
    display: flex;
    justify-content: flex-start;
    color: ${colors.secondary70};
    width: 100%;
    cursor: pointer;
    position: relative;
    z-index: 25;
`;
const Button = styled.div`
    border-radius: 25px;
    background-color: ${props => props.primary ? colors.action100 : colors.secondary100};
    color: ${colors.white};
    cursor: pointer;
    padding: 10px 50px;
`;
const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
`;


const DeleteV2 = ({deleteSession, selectedTime, meetingLength, back, showBack}) => (
    <Center>
        {showBack && <BackTop onClick={back}><ArrowLeftOutlined/></BackTop>}
        <FontSubtitle38To30>{'Cancel Appointment'}</FontSubtitle38To30>
        <br/>
        <FontSubtitle21>{'Are you sure you want to cancel your appointment?'}</FontSubtitle21>
        <FontBody16>{moment(selectedTime).format("LLLL")} ({meetingLength}-minutes)</FontBody16>
        <FlexRow>
            <Button primary={false} onClick={back}><FontHeader16>{'No'}</FontHeader16></Button>
            <Button primary={true} onClick={deleteSession}><FontHeader16>{'Yes'}</FontHeader16></Button>
        </FlexRow>
    </Center>
);

export default DeleteV2;