import React from 'react';
import styled from 'styled-components';

/* Components */
import {colors} from "../../../common/components/colors";
import {Section, Body, ContactTitle, PreviewWhite} from './components';
import {Star} from 'react-feather';
import { FontBody24 } from '../../../common/components/fonts';

const ServiceName = styled(FontBody24)`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  color: ${props => props.color}B3;
  @media screen and (max-width: 1680px) {
    font-size: 16px;
    line-height: 2;
  }
`;
const ServiceList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0;
  list-style: none;
  padding: 0;
`;

Expertise2.defaultProps = {
    expertise: [],
}

export default function Expertise2(props) {
    const expertise = props.expertise;

    return (
        <Section backgroundColor={colors.canvaGreen} style={{position:"relative"}}>
            <ContactTitle color={colors.white}>Specialties</ContactTitle>
            <Body>
                <PreviewWhite color={colors.white}>My specialties include: </PreviewWhite>
                <ServiceList>
                    {expertise.map((e, index) => (
                        <ServiceName key={index} color={colors.white}><Star />{e}</ServiceName>
                    ))}
                </ServiceList>
            </Body>
        </Section>

    )
}
