import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setProgressCourses, setProgressCoursesFetching, setMyGoals} from "../store/progress";

/* Static */
import { devConsumerCourses, devMyGoals } from "../static/devdata";

export const tryGetCourses = () => {
    return async dispatch => {
        dispatch(setProgressCoursesFetching(true));
        // const coursesAll = await axiosReq('apiv1/consumer/courses/my-courses', "GET", {}, devConsumerCourses);
        const coursesAll = await axiosReq('apiv1/consumer/courses/all-my-content', "GET", {}, devConsumerCourses);
        await dispatch(setProgressCourses(coursesAll));
        dispatch(setProgressCoursesFetching(false));
        return coursesAll;
    }
};

export const tryGetMyGoals = () => {
    return async dispatch => {
        const goalsAll = await axiosReq('apiv1/consumer/my-goals', "GET", {}, devMyGoals);
        await dispatch(setMyGoals(goalsAll));
        return goalsAll;
    }
}

export const tryCreateMyGoal = (myGoalData) => {
    return async dispatch => {
        const myGoalCourse = await axiosReq('apiv1/consumer/my-goals/create', "POST", myGoalData, {});
        return myGoalCourse;
    }
}

export const tryUpdateMyGoal = (myGoalData) => {
    return async dispatch => {
        const myGoalCourse = await axiosReq('apiv1/consumer/my-goals/update', 'PUT', myGoalData, {});
        return myGoalCourse;
    }
}