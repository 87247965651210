/* Client Matching Rules */
export const testCheckbox = (array, e, question, status) => {
    return (array.map(j => {
        if(e === j.id) {
            let specialist = j.specialist;
            let comfortable = j.comfortable;
            let personal = j.personal;
            let exclude = j.exclude;
            if(question === "specialist" && status === true) {if(j.comfortable === true) { comfortable = false; }}
            if(question === "comfortable" && status === true) {if(j.specialist === true) { specialist = false; }}
            if(question === "exclude" && status === true) {specialist = false; comfortable = false; personal = false;}
            if(question !== "exclude" && status === true) {exclude = false;}
            if(question === "specialist" && status === false) {
                if(j.comfortable === false && j.personal === false && j.exclude === false) { status = true; }
            }
            if(question === "comfortable" && status === false) {
                if(j.specialist === false && j.personal === false && j.exclude === false) { status = true; }
            }
            if(question === "personal" && status === false) {
                if(j.comfortable === false && j.specialist === false && j.exclude === false) { status = true; }
            }
            if(question === "exclude" && status === false) {
                if(j.comfortable === false && j.personal === false && j.specialist === false) { status = true; }
            }
            return({
                "id": j.id,
                "question": j.question,
                "specialist": (question==="specialist") ? status : specialist,
                "comfortable": (question==="comfortable") ? status : comfortable,
                "personal": (question==="personal") ? status : personal,
                "exclude": (question==="exclude") ? status : exclude
            });
        } else {return j;}
    }))
};

