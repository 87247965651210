import React, {useEffect, useState} from "react";
import {Option, Plan, PlanDetails, CertImage, PlansList, ButtonWide} from "./plansgrid";
import {FontBody16, FontBody18, FontHeader18, FontTitle24, FontTitle34} from "../../../../common/components/fonts";
import {images} from "../../../../common/components/images";
import {ButtonPrimary, ButtonSecondary} from "../../../../common/components/buttons";
import {tryPostHelp} from "../../../support/middleware/help";
import dispatch from "d3-selection/src/selection/dispatch";
import {PopupContent, PopupOverlay} from "./firmplanclientacquisition";
import styled from "styled-components";
import {Link} from "react-router-dom";

const ButtonDiv = styled.div`
    display: flex;
    width: fit-content;
`;
export default function FirmPlanContent(){
    const [isLoading, setLoading] = useState(false);
    const title = "Willow Content & Marketing Services"
    const [modal, setModal] = useState(false)
    // const [certCoupon, setCertCoupon] = useState(false)
    // const [noBuy, setNoBuy] = useState(false)
    // const userId = useSelector((state) => state.common.user.get("userId"));

    // useEffect(() => {
    //     init()
    // },[])

    const sendLearnMore = async () => {
        setModal(true)
        await tryPostHelp("interested in learning more about client content")
    }

    const openPdf = async() => {
        await tryPostHelp("opened usage guide for client content")
    }

    return (
        <Option>
            <FontTitle24 style={{textAlign: "center"}}>{title}</FontTitle24>
            <CertImage src={images.willowContentServicesIcon}/>
            <FontBody16>At Willow we want to serve our advisors in the ways that best support their business.
                Talk to our team about creating a custom content solutions bundle.
                Additionally, Willow can create custom materials and/or packages to meet your specific goals and objectives.</FontBody16>
            <Plan>
                <PlanDetails>Details:</PlanDetails>
                <PlansList>
                    <li>
                        <a href={"https://docs.trustwillow.com/Willow+Content+%26+Events_Int+(2).pdf"} target={"_blank"} onClick={() => openPdf()}><FontBody16>Willow Content & Services Usage Guide</FontBody16></a>
                    </li>
                    <li><FontBody16>Comprehensive event content
                        and marketing support</FontBody16></li>
                    <li><FontBody16>Client seminars/workshops,
                        delivered by a Willow expert
                        speaker</FontBody16></li>
                    <li><FontBody16>Marketing kit to drive
                        attendance and post-event
                        engagement</FontBody16></li>
                </PlansList>
            </Plan>

            <>
                <ButtonWide onClick={() => sendLearnMore()}>
                    <ButtonPrimary label={"Learn more"} canSubmit={false}/>
                </ButtonWide>


            </>

            {modal &&
                <PopupOverlay>
                    <PopupContent>

                            <>
                                <FontTitle34>Willow Content & Services</FontTitle34>
                                <br />
                                <br />
                                <FontBody16>Thank you for your interest in growing your relationship with Willow! Your customer success representative has been notified and will be in touch with you shortly to set a date to hear more about your content needs!</FontBody16>
                                <br />
                                <ButtonDiv onClick={() => setModal(false)}>
                                    <ButtonPrimary label={"Close"} canSubmit={false} />
                                </ButtonDiv>
                            </>

                    </PopupContent>
                </PopupOverlay>}
        </Option>
    );
}