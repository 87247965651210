import React from "react";
import styled from "styled-components";
import {Link} from 'react-router-dom';

/* Components */
import {FontTitle18, FontHeader16, FontBody14} from "../../../../common/components/fonts";
import {colors} from "../../../../common/components/colors";
import {ButtonPrimary} from "../../../../common/components/buttons";
import {PlusOutlined} from '@ant-design/icons';

const Left = styled.div`
    display: inline-block;
    vertical-align: bottom;
    width: calc(100% - 160px);
    @media screen and (max-width: 640px) {    
        display: block;
        width: calc(100% - 40px);
        text-align: center;
        padding: 0px 20px;
    }
`;
const Right = styled.div`
    display: inline-block;
    vertical-align: bottom;
    width: calc(100% - 160px);
    width: 160px;
    @media screen and (max-width: 640px) {    
        display: block;
        width: calc(100% - 40px);
        text-align: center;
        padding: 0px 20px;
    }
`;
const ButtonFit = styled.div`
    width: fit-content;
    margin: 0px 0px 0px auto;
    @media screen and (max-width: 640px) {        
        margin: 20px auto -10px auto;
    }
`;
const TitleSection = styled.div`
    margin-bottom: 35px;
`;
const Gray = styled.div`
    color: ${colors.primary70};
`;
const Background = styled.div`
    background-color: ${colors.border100};
    padding: 20px;
    @media screen and (max-width: 640px) {    
        padding: 10px;
    }
    margin-bottom: 30px;
`;

const ShowAllEvents = ({events}) => (
    <>
        {/*<TitleSection>*/}
        {/*    <Left><FontTitle18>Events</FontTitle18></Left>*/}
        {/*    /!*<Right><Link to={"/services/create"}><ButtonLink canSubmit={false} label={<FontHeader16><PlusOutlined /> New Service</FontHeader16>} /></Link></Right>*!/*/}
        {/*</TitleSection>*/}
        <Background>
            <TitleSection>
                <Left>
                    <FontTitle18>Appointment Types</FontTitle18>
                    <Gray><FontBody14>These are free appointments that anyone can book with you.</FontBody14></Gray>
                </Left>
                <Right>
                    <ButtonFit>
                        <Link to={"/settings/availability/appointments/create"}>
                            <ButtonPrimary canSubmit={false} label={<FontHeader16><PlusOutlined /> Create</FontHeader16>} />
                        </Link>
                    </ButtonFit>
                </Right>
            </TitleSection>
            {events}
        </Background>
    </>
);

export default ShowAllEvents;