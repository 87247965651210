import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import styled from 'styled-components';

/* Components */
import {ReadOnlyInput, TextQuillInputControlCount} from "../../../../common/components/inputs";
import {SavedSettings} from '../../../../common/components/cards';
import {FontBody16, FontTitle18} from '../../../../common/components/fonts';
import {ButtonSecondary, ButtonTertiary} from "../../../../common/components/buttons";
import {LogoLoading} from "../../../../common/components/loading";
import {colors} from "../../../../common/components/colors";
import {FitContent, InlineBlock, SaveSection, SubmitInputWithValidation, Text, Gray} from "../../components/myprofile";

/* Middleware */
import {tryUpdateExpertProfile} from '../../middleware/expertprofile';
import {EditOutlined} from "@ant-design/icons";
import {v4 as uuid4} from "uuid";
import ReactQuill from "react-quill";

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

class ProfileBackground extends React.Component {
    state = {
        bio: '', bioReadOnly: "", bioValid: true, bioCount: 0, bioComponent: '', bioPrevious: "", bioCountPrevious: 0,
        whyBecameCoach: '', whyBecameCoachValid: true, whyBecameCoachCount: 0, whyBecameCoachComponent: '', whyBecameCoachPrevious: "", whyBecameCoachCountPrevious: 0,
        freeTime: '', freeTimeValid: true, freeTimeCount: 0, freeTimeComponent: '', freeTimePrevious: "", freeTimeCountPrevious: 0,
        isSaving: false,
        isSaved: false,
        error: '',
        isValid: true,
        isChanged: false,
        isLoading: true,
        "isEdit": false
    };

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    init = async () => {
        const bio = this.props.profile.get("bio");
        const whyBecameCoach = this.props.profile.get("whyBecameCoach");
        const freeTime = this.props.profile.get("freeTime");
        await this.setState({
            "bio": (bio === null || bio === undefined) ? "" : bio,
            "bioPrevious": (bio === null || bio === undefined) ? "" : bio,
            "bioCount": (bio === null || bio === undefined || bio === '') ? 0 : bio.replace( /(<([^>]+)>)/ig, '').length,
            "bioCountPrevious": (bio === null || bio === undefined || bio === '') ? 0 : bio.replace( /(<([^>]+)>)/ig, '').length,
            "whyBecameCoach": (whyBecameCoach === null || whyBecameCoach === undefined) ? "" : whyBecameCoach,
            "whyBecameCoachPrevious": (whyBecameCoach === null || whyBecameCoach === undefined) ? "" : whyBecameCoach,
            "whyBecameCoachCount": (whyBecameCoach === null || whyBecameCoach === undefined || whyBecameCoach === '') ? 0 : whyBecameCoach.replace( /(<([^>]+)>)/ig, '').length,
            "whyBecameCoachCountPrevious": (whyBecameCoach === null || whyBecameCoach === undefined || whyBecameCoach === '') ? 0 : whyBecameCoach.replace( /(<([^>]+)>)/ig, '').length,
            "freeTime": (freeTime === null || freeTime === undefined) ? "" : freeTime,
            "freeTimePrevious": (freeTime === null || freeTime === undefined) ? "" : freeTime,
            "freeTimeCount": (freeTime === null || freeTime === undefined || freeTime === '') ? 0 : freeTime.replace( /(<([^>]+)>)/ig, '').length,
            "freeTimeCountPrevious": (freeTime === null || freeTime === undefined || freeTime === '') ? 0 : freeTime.replace( /(<([^>]+)>)/ig, '').length,
        });
        await this.updateBio();
        await this.updateWhyBecome();
        await this.updateFreTime();
        await this.setState({"isLoading": false});
        await this.setState({"isChanged": false});
    };

    handleChangeBio = async e => {
        await this.setState({
            'bio': e,
            'bioValid': (!validator.isEmpty(e) && e !== '<p><br></p>' && e !== ''), // && e.replace( /(<([^>]+)>)/ig, '').length > 500)
            'bioCount': (validator.isEmpty(e) || e === '<p><br></p>' || e === '') ? 0 : e.replace( /(<([^>]+)>)/ig, '').length,
            "isChanged": true
        });
        await this.updateBio();
        this.checkValid();
    };

    updateBio = async () => {
        await this.setState({
            'bioComponent': <Text><TextQuillInputControlCount
                title={"Biography*"}
                valid={this.state.bioValid}
                warning={"Required"}
                id={"bio"}
                onChange={this.handleChangeBio}
                placeholder={"Share your background"}
                value={this.state.bio}
                maxLength={500}
                count={this.state.bioCount}
                minRows={8}
                required={true}
                countColorAbove={colors.white}
                countColorBelow={colors.white}
            /></Text>,
            "bioReadOnly": <Text><Gray><FontBody16>Biography</FontBody16></Gray><ReactQuill
                id={uuid4()}
                readOnly={true}
                theme={null}
                value={this.state.bio}
                bounds={'.app'}
            /></Text>
        })
    };

    handleChangeWhyBecameEnterprise = async e => {
        await this.setState({
            'whyBecameCoach': e,
            'whyBecameCoachValid': (!validator.isEmpty(e) && e !== '<p><br></p>' && e !== ''), // && e.replace( /(<([^>]+)>)/ig, '').length > 500)
            'whyBecameCoachCount': (validator.isEmpty(e) || e === '<p><br></p>' || e === '') ? 0 : e.replace( /(<([^>]+)>)/ig, '').length,
            "isChanged": true
        });
        await this.updateWhyBecome();
        // this.checkValid();
    };

    updateWhyBecome = async () => {
        await this.setState({
            'whyBecameCoachComponent': <Text><TextQuillInputControlCount
                title={"Why I became a coach"}
                valid={this.state.whyBecameCoachValid}
                warning={""}
                id={"whyBecameCoach"}
                onChange={this.handleChangeWhyBecameEnterprise}
                placeholder={"Tell your story"}
                value={this.state.whyBecameCoach}
                maxLength={500}
                count={this.state.whyBecameCoachCount}
                minRows={8}
                required={true}
                countColorAbove={colors.white}
                countColorBelow={colors.white}
            /></Text>
        })
    };

    handleChangeFreeTime = async e => {
        await this.setState({
            'freeTime': e,
            'freeTimeValid': (!validator.isEmpty(e) && e !== '<p><br></p>' && e !== ''), // && e.replace( /(<([^>]+)>)/ig, '').length > 100)
            'freeTimeCount': (validator.isEmpty(e) || e === '<p><br></p>' || e === '') ? 0 : e.replace( /(<([^>]+)>)/ig, '').length,
            "isChanged": true
        });
        await this.updateFreTime();
        // this.checkValid();
    };

    updateFreTime = async () => {
        await this.setState({
            'freeTimeComponent': <Text><TextQuillInputControlCount
                title={"What people do not know about me"}
                valid={this.state.freeTimeValid}
                warning={""}
                id={"freeTime"}
                onChange={this.handleChangeFreeTime}
                placeholder={"What makes you unique"}
                value={this.state.freeTime}
                maxLength={100}
                count={this.state.freeTimeCount}
                minRows={3}
                required={true}
                countColorAbove={colors.white}
                countColorBelow={colors.white}
            /></Text>
        })
    };

    reset = async () => {
        await this.setState({
            "bio": this.state.bioPrevious,
            "bioCount": this.state.bioCountPrevious,
            "bioComponent": "",
            "whyBecameCoach": this.state.whyBecameCoachPrevious,
            "whyBecameCoachCount": this.state.whyBecameCoachCountPrevious,
            "whyBecameCoachComponent": "",
            "freeTime": this.state.freeTimePrevious,
            "freeTimeCount": this.state.freeTimeCountPrevious,
            "freeTimeComponent": "",
            "isChanged": false,
            "isEdit": false
        });
        await this.updateBio();
        await this.updateWhyBecome();
        await this.updateFreTime();
        await this.setState({"isChanged": false});
    };

    edit = async (status) => {
        this.setState({"isEdit": status});
    };

    checkValid = async () => {
        if(this.state.bioValid) { // && this.state.highlightsValid && this.state.whyBecameCoachValid && this.state.coachPhilosophyValid && this.state.freeTimeValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            await this.setState({'isSaving': true, "error": ""});
            const background = {
                bio: this.state.bio,
                whyBecameCoach: this.state.whyBecameCoach,
                freeTime: this.state.freeTime,
                // endorsement1: this.props.profile.get("endorsement1"),
                // endorsement2: this.props.profile.get("endorsement2"),
                // endorsement3: this.props.profile.get("endorsement3"),
            };
            await this.props.tryUpdateExpertProfile(background, "errorBackground");
            if(this.props.profile.get("errorBackground") === "") {
                await this.setState({
                    'isSaving': false,
                    "isSaved": true,
                    "bioPrevious": this.state.bio,
                    "bioCountPrevious": this.state.bioCount,
                    "bioComponent": "",
                    "whyBecameCoachPrevious": this.state.whyBecameCoach,
                    "whyBecameCoachCountPrevious": this.state.whyBecameCoachCount,
                    "whyBecameCoachComponent": "",
                    "freeTimePrevious": this.state.freeTime,
                    "freeTimeCountPrevious": this.state.freeTimeCount,
                    "freeTimeComponent": "",
                    "isEdit": false
                });
                await this.updateBio();
                await this.updateWhyBecome();
                await this.updateFreTime();
                this.timeout = setTimeout(() => {this.setState({'isSaved': false, "isChanged": false})}, 3000);
            } else {
                this.setState({'isSaved': true, 'isSaving': false, "error": this.props.profile.get("errorBackground")});
                this.timeout = setTimeout(() => {this.setState({'isSaved': false, "error": ""})}, 3000);
            }
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    {this.state.isSaved
                        ?
                            <>
                                {this.state.error === '' && <SavedSettings height={"99px"} error={false} message={"Saved"}/>}
                                {this.state.error !== '' && <SavedSettings height={"99px"} error={true} message={this.state.error}/>}
                            </>
                        :
                            <>
                                {!this.state.isEdit &&
                                    <>
                                        <FontTitle18>About Me</FontTitle18>
                                        {this.state.bioReadOnly}
                                        <br/>
                                        <FitContent><FontBody16 onClick={() => {return this.edit(true)}}><ButtonSecondary canSubmit={false} label={<><EditOutlined/>&nbsp;Change Biography</>} /></FontBody16></FitContent>
                                    </>
                                }
                                {this.state.isEdit &&
                                    <form method={"post"} onSubmit={this.handleSubmit}>
                                        <div>
                                            <FontTitle18>About Me</FontTitle18>
                                            {this.state.bioComponent}
                                            {/*{!this.props.isWMC && <>{this.state.whyBecameCoachComponent}</>}*/}
                                            {/*{this.state.freeTimeComponent}*/}
                                        </div>
                                        <SaveSection>
                                            <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                            <InlineBlock><div onClick={this.reset}><ButtonTertiary canSubmit={false} label={"Cancel"} /></div></InlineBlock>
                                        </SaveSection>
                                    </form>
                                }
                            </>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    profile: state.enterprise.profile.expertProfile.get("expertProfile"),
});

const mapDispatchToProps = dispatch => ({
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileBackground);
