import {axiosReq} from "../../../common/utils/axios";

/* Store */
import { setFeedackSendPending, setFeedackSendComplete } from "../store/feedback";

export const tryPostFeedback = (message) => {
    return async dispatch => {
        dispatch(setFeedackSendPending(true));
        await axiosReq('apiv1/feedback', "POST", {message: message}, null);
        return dispatch(setFeedackSendComplete(false));
    }
};

