import { combineReducers } from 'redux';

/* Reducers */
import teamMembersReducer from './store/team';
import productsReducer from './store/products';

const teamReducer = combineReducers({
    team: teamMembersReducer,
    products: productsReducer,
});

export default teamReducer;
