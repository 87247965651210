import {axiosReq} from "../../../common/utils/axios";

/* Static */
import {devAdvisors} from "../static/devdata";

export const tryGetCertifiedAdvisors = () => {
    return async dispatch => {
        return axiosReq('apiv1/public/coach/get-certified', "GET", {}, devAdvisors);
    }
};
