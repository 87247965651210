import React from 'react';
import {withRouter} from 'react-router-dom';

/* Navs */
import Nav from "../navbars/containers/nav";
import {PageContentContainer, PageFull, PageContent} from "../navbars/components/navlayouts";

/* Containers */
import FirmTaskCourseVideo from './containers/coursevideo';
import FirmTaskCourseQuiz from './containers/coursequiz';
import FirmTaskCourseQuizPass from './containers/coursequizpass';
import FirmTaskCourseQuizFailed from './containers/coursequizfail';
import FirmTaskCourseQuizCompleted from './containers/coursequizcompleted';
import FirmTaskGeneric from './containers/generictask';
import FirmTasksAll from './containers/alltasks';

const FirmTrainings = ({location}) => {
    if(location.pathname.includes("course")) {
        if(location.pathname.includes("video")) {
            return(<FirmTaskCourseVideo />)
        } else if(location.pathname.includes("quiz")) {
            return(<FirmTaskCourseQuiz />)
        } else if(location.pathname.includes("passed")) {
            return(<FirmTaskCourseQuizPass />)
        } else if(location.pathname.includes("failed")) {
            return(<FirmTaskCourseQuizFailed />)
        } else if(location.pathname.includes("completed")) {
            return(<FirmTaskCourseQuizCompleted />)
        } else {
            return null
        }
    } else if(location.pathname === "/my-tasks") {
        return(
            <>
                <Nav />
                <PageFull>
                    <PageContentContainer fullPage={true}>
                        <PageContent>
                            <FirmTasksAll />
                        </PageContent>
                    </PageContentContainer>
                </PageFull>
            </>
        );
    } else {
        return(<FirmTaskGeneric />);
    }
}

export default withRouter(FirmTrainings);