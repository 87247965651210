import React from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import {Map} from 'immutable';
import moment from 'moment/moment';

/* Components */
import {ActionBox, FitWidthButton, InlineItemLeft} from "../../components/clientsdetail";
import {FontHeader18, FontTitle18, FontHeader16} from "../../../common/components/fonts";
import {LogoLoading, LogoLoadingSmall} from "../../../common/components/loading";
import {ButtonPrimary, ButtonSecondary} from "../../../common/components/buttons";
import {Center, Photo, Border} from '../../components/contactcoaching';
import {ReadOnlyValueInput} from "../../../common/components/inputs";
import {colors} from '../../../common/components/colors';
import {QuillSlim} from "../../../common/components/richtexteditor";

/* Middleware */
import {tryGetAnnouncementsAll, tryGetAnnouncementsLimited, tryCreateAnnouncement} from '../middleware/announcements';
import {setAnnouncementOneModalOpen} from '../store/announcements';
import ReactQuill from "react-quill";
import {v4 as uuid4} from "uuid";

const Announcement = styled.div`
    width: 100%;
    font-family: MontserratRegular;
    font-size: 16px;
    line-height: 22px;
    margin: 10px 0px;
    ${props => props.color === "progress100"} {color: ${colors.primary100};}
    ${props => props.color !== "progress100"} {color: ${colors.progress100};}
    border: none;
    &:focus {
        outline: none;
    }
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 95px;
`;

class Announcements extends React.Component {
    state={
        "isLoading": true,
        "announcements": Map(),
        "announcementsSorted": [],
        "viewNumber": 0,
        "quill": ""
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps) {
        
    }

    init = async () => {
        await this.props.tryGetAnnouncementsAll();
        await this.setState({"announcements": this.props.announcements});
        return this.update()
    };

    createQuill = (a) => (
        <ReactQuill
            id={uuid4()}
            readOnly={true}
            theme={null}
            value={a}
            bounds={'.app'}
        />
    );

    update = async () => {
        await this.setState({"isLoading": true});
        await this.filterActivity();
        return this.setState({"isLoading": false});
    };

    filterActivity = async () => {
        await this.setState({"announcementsSorted": []});
        const activityOrdered = await this.state.announcements.sort((a, b) => {
            return moment(b.get("created")).diff(moment(a.get("created")))
        });
        const announcementsSorted = []
        for (let i = 0; i < activityOrdered.size && i <= this.state.viewNumber; i++) {
            announcementsSorted.push(activityOrdered.get(i))
        }
        await this.setState({"announcementsSorted": announcementsSorted});
    };

    viewMore = async () => {
        await this.setState({"viewNumber": this.state.viewNumber + 1})
        this.update()
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <ActionBox>
                    <div>
                        <InlineItemLeft><FontTitle18>Announcements</FontTitle18></InlineItemLeft>
                    </div>
                    <br />
                    {this.state.announcements.size === 0 ?
                        <Center><FontHeader16>{"No announcements"}</FontHeader16></Center>
                    :
                        <>
                            {this.state.announcementsSorted.map((a, index) => {
                                return(
                                    <div key={index}>
                                        <Center><FontHeader18>{a.get("wmc").get("companyName") + " - " + a.get("title")}</FontHeader18></Center>
                                        <Announcement><QuillSlim bodyBorderColor={colors.white}>{this.createQuill(a.get("body"))}</QuillSlim></Announcement>
                                        <ReadOnlyValueInput 
                                            title={"Author"}
                                            value={a.get("sender").get("fullName")} />
                                        <ReadOnlyValueInput title={"Date"} value={moment(a.get("created")).format("LL")} />
                                        <br />
                                        <FitWidthButton onClick={() => {return this.props.setAnnouncementOneModalOpen(true, a)}}>
                                            <ButtonSecondary canSubmit={false} label={"View"} />
                                        </FitWidthButton>
                                        <Border />
                                    </div>
                                )
                            })}
                            <FitWidthButton onClick={() => {return this.viewMore()}}><ButtonPrimary canSubmit={false} label={"More"} /></FitWidthButton>
                        </>
                    }
                </ActionBox>
            )
        }
    }
}

const mapStateToProps = state => ({
    isWMC: state.common.wmc.get("isWMC"),
    user: state.common.user,
    announcements: state.enterprise.homepage.announcements.get("announcements").get("announcements")
});

const mapDispatchToProps = dispatch => ({
    tryGetAnnouncementsAll: () => dispatch(tryGetAnnouncementsAll()),
    tryGetAnnouncementsLimited: (number) => dispatch(tryGetAnnouncementsLimited(number)),
    tryCreateAnnouncement: (wmcId, title, body) => dispatch(tryCreateAnnouncement(wmcId, title, body)),
    setAnnouncementOneModalOpen: (status, announcement) => dispatch(setAnnouncementOneModalOpen(status, announcement))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Announcements));