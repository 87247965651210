import {axiosReq} from "../../../../common/utils/axios";
// import {devPurchaseHistoryData} from "../../../../iframes/checkout/static/devdata";
//
// export const tryGetPurchaseHistory = () => {
//     return async dispatch => {
//         return axiosReq('apiv1/consumer/purchase/get-purchase-history', "GET", {}, devPurchaseHistoryData);
//     }
// };
//
// export const tryPostAssignAdvisorWillowSessions = (sessions) => {
//     return async (dispatch, getState) => {
//         let userId = getState().common.user.get("userId");
//         return axiosReq('apiv1/coach/clients/'+userId+'/assign-willow-sessions', "POST", {"willowSessionAmount": sessions}, {});
//     }
// };

export const tryGetBadActor = () => {
    return async dispatch => {
        return axiosReq('apiv1/coach/settings/profile/bad-actor?hasBadActor=true', "GET", {}, {});
    }
};

export const tryGetStatus = async () => {
        return axiosReq('apiv1/coach/certification/subscriptions/status', "GET", {}, getStatusDevData)
}

export const getStatusDevData =
    {
        "userId": 5374,
        "womenCertRenewsOn": "2025-06-10T17:28:03.995592Z",
        "womenCertPurchasedOn": "2024-06-10T17:25:02Z",
        "womenCertCompletedOn": "2024-08-22T18:14:35.058569Z",
        "nextGenCertRenewsOn": null,
        "nextGenCertPurchasedOn": null,
        "nextGenCertCompletedOn": null,
        "womenCertStatus": "completed",
        "nextGenCertStatus": "no_purchase",
        "clientAcqPurchasedOn": null,
        "clientAcqRenewsOn": null
    }
