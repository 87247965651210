const trialTrainings = {
    // "logo": "logoWillow",
    // "logoIcon": "logoWillowIcon",
    // "url": "https://trustwillow.com",
    "discount": "certification_session_cert1200",
    "wmcEmployeeId": null,
    "isReferredByPartner": false,
    "assignAdvisorSessionsAfterSignup": 0,
    "assignTrainingSessionsAfterSignup": 1,
}

const trainingsWithPrecompletedVideoTest = { 
    /*
    This is hack, we're giving training video for free public/webinar-training/free/p1gmcq
    On the end of training they go to subscribe. After subscription backend should precomplete their video training
    */
    // "logo": "logoWillow",
    // "logoIcon": "logoWillowIcon",
    // "url": "https://trustwillow.com",
    "discount": "certification_session_cert0",
    "wmcEmployeeId": "p1gmcq-test",
    "isReferredByPartner": false,
    "assignAdvisorSessionsAfterSignup": 0,
    "assignTrainingSessionsAfterSignup": 1,
}

const trainingsWithPrecompletedVideo = {
    /*
    This is hack, we're giving training video for free public/webinar-training/free/p1gmcq
    On the end of training they go to subscribe. After subscription backend should precomplete their video training
    */
    // "logo": "logoWillow",
    // "logoIcon": "logoWillowIcon",
    // "url": "https://trustwillow.com",
    "discount": "certification_session_cert1200",
    "wmcEmployeeId": "p1gmcq",
    "isReferredByPartner": false,
    "assignAdvisorSessionsAfterSignup": 0,
    "assignTrainingSessionsAfterSignup": 1,
}

const trial = {
    // "logo": "logoWillow",
    // "logoIcon": "logoWillowIcon",
    // "url": "https://trustwillow.com",
    "discount": "certification_session_cert1200",
    "wmcEmployeeId": null,
    "isReferredByPartner": false,
    "assignAdvisorSessionsAfterSignup": 1,
    "assignTrainingSessionsAfterSignup": 1,
}

const testers = {
    // "logo": "logoWillow",
    // "logoIcon": "logoWillowIcon",
    // "url": "https://trustwillow.com",
    "discount": "certification_session_cert1200",
    "wmcEmployeeId": "testers",
    "isReferredByPartner": true,
    "assignAdvisorSessionsAfterSignup": 1,
    "assignTrainingSessionsAfterSignup": 0,
}

const jpmorgan = {
    // "logo": "logoBlackRockBank",
    // "logoIcon": "logoBlackRockIcon",
    // "url": "https://blackrock.com",
    "discount": "certification_session_cert1200",
    "wmcEmployeeId": "jpmorgan",
    "isReferredByPartner": true,
    "assignAdvisorSessionsAfterSignup": 1,
    "assignTrainingSessionsAfterSignup": 0,
}

const principal = {
    // "logo": "logoPrincipal",
    // "logoIcon": "logoPrincipal",
    // "url": "https://principal.com",
    "discount": "certification_session_cert1200",
    "wmcEmployeeId": "principal", // will only have access to co-branded principal seminar using this code as flag, make first on the list
    "isReferredByPartner": false,
    "assignAdvisorSessionsAfterSignup": 0,
    "assignTrainingSessionsAfterSignup": 0,
}

const blackRock = {
    // "logo": "logoBlackRockBank",
    // "logoIcon": "logoBlackRockIcon",
    // "url": "https://blackrock.com",
    "discount": "certification_session_cert0",
    "wmcEmployeeId": "blackrock",
    "isReferredByPartner": false,
    "assignAdvisorSessionsAfterSignup": 1,
    "assignTrainingSessionsAfterSignup": 3,
}


const freecerttrial = {
    // "logo": "logoBlackRockBank",
    // "logoIcon": "logoBlackRockIcon",
    // "url": "https://blackrock.com",
    "discount": "certification_session_cert0",
    "wmcEmployeeId": "freecerttrial",
    "isReferredByPartner": false,
    "assignAdvisorSessionsAfterSignup": 1,
    "assignTrainingSessionsAfterSignup": 3,
}


const edwardjones = {
    // "logo": "logoBlackRockBank",
    // "logoIcon": "logoBlackRockIcon",
    // "url": "https://blackrock.com",
    "discount": "certification_session_cert1200",
    "wmcEmployeeId": "edwardjones",
    "isReferredByPartner": false,
    "assignAdvisorSessionsAfterSignup": 1,
    "assignTrainingSessionsAfterSignup": 4,
}

const cert1 = {
    // "logo": "logoBlackRockBank",
    // "logoIcon": "logoBlackRockIcon",
    // "url": "https://blackrock.com",
    "discount": "certification_session_cert1",
    "wmcEmployeeId": "cert1",
    "isReferredByPartner": false,
    "assignAdvisorSessionsAfterSignup": 0,
    "assignTrainingSessionsAfterSignup": 0,
}

const cert400 = {
    // "logo": "logoBlackRockBank",
    // "logoIcon": "logoBlackRockIcon",
    // "url": "https://blackrock.com",
    "discount": "certification_session_cert400",
    "wmcEmployeeId": "cert400",
    "isReferredByPartner": false,
    "assignAdvisorSessionsAfterSignup": 0,
    "assignTrainingSessionsAfterSignup": 0,
}

const cert600 = {
    // "logo": "logoBlackRockBank",
    // "logoIcon": "logoBlackRockIcon",
    // "url": "https://blackrock.com",
    "discount": "certification_session_cert600",
    "wmcEmployeeId": "cert600",
    "isReferredByPartner": false,
    "assignAdvisorSessionsAfterSignup": 0,
    "assignTrainingSessionsAfterSignup": 0,
}

const cert800 = {
    // "logo": "logoBlackRockBank",
    // "logoIcon": "logoBlackRockIcon",
    // "url": "https://blackrock.com",
    "discount": "certification_session_cert800",
    "wmcEmployeeId": "cert800",
    "isReferredByPartner": false,
    "assignAdvisorSessionsAfterSignup": 0,
    "assignTrainingSessionsAfterSignup": 0,
}

const cert900 = {
    // "logo": "logoBlackRockBank",
    // "logoIcon": "logoBlackRockIcon",
    // "url": "https://blackrock.com",
    "discount": "certification_session_cert900",
    "wmcEmployeeId": "cert900",
    "isReferredByPartner": false,
    "assignAdvisorSessionsAfterSignup": 0,
    "assignTrainingSessionsAfterSignup": 0,
}

const cert1000 = {
    // "logo": "logoBlackRockBank",
    // "logoIcon": "logoBlackRockIcon",
    // "url": "https://blackrock.com",
    "discount": "certification_session_cert1000",
    "wmcEmployeeId": "cert1000",
    "isReferredByPartner": false,
    "assignAdvisorSessionsAfterSignup": 0,
    "assignTrainingSessionsAfterSignup": 0,
}

const cert1200 = {
    // "logo": "logoBlackRockBank",
    // "logoIcon": "logoBlackRockIcon",
    // "url": "https://blackrock.com",
    "discount": "certification_session_cert1200",
    "wmcEmployeeId": "cert1200",
    "isReferredByPartner": false,
    "assignAdvisorSessionsAfterSignup": 0,
    "assignTrainingSessionsAfterSignup": 0,
}


export const promotionalCodes = {
    "c4xr6": blackRock,
    "fd93hd": freecerttrial,
    "x1apz": jpmorgan,
    "vdb79": testers,
    "iczhmv": principal,
    "r0g881": trial,
    "p1gmcq": trialTrainings,
    "p1gmcq-test": trainingsWithPrecompletedVideoTest,
    "umbkl7": edwardjones,
    "lej493": cert1,
    "zsm41d": cert400,
    "le5dqb": cert600,
    "vc3u79": cert800,
    "7hw8df": cert900,
    "8prvzq": cert1000,
    "5oe5h3": cert1200,
}

export const shouldAllowPurchase = (wmcEmployeeId) => {
    return true
    // return(wmcEmployeeId !== "blackrock")
}

export const ceritificationDiscounts = {
    "blackRock": "cert400",
    "freecerttrial": "cert400",
    "jpmorgan": "cert400",
    "cert1": "cert1",
    "cert400": "cert400",
    "cert600": "cert600",
    "cert800": "cert800",
    "cert900": "cert900",
    "cert1000": "cert1000",
    "cert1200": "cert1200",
}
// https://secure.trustwillow.com/advisor-signup?partnerCode=zsm41d&utm_source=facebook

// "Blackrock utm_source=facebook"