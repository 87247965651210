import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {tryPostStripeCheckoutSession} from './middleware/checkout';

export default function CheckoutStripe () {
    const dispatch = useDispatch();

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        console.log("loaded")
        const urlParams = new URLSearchParams(window.location.search);
        console.log("urlParams", urlParams)
        const stripeCheckoutSessionId = urlParams.get('stripeCheckoutSessionId');
        console.log("stripeCheckoutSessionId", stripeCheckoutSessionId)
        const res = await dispatch(tryPostStripeCheckoutSession(stripeCheckoutSessionId));
        console.log("completed api", res)
        window.location.replace("https://secure.trustwillow.com/verify");
    }

    return null

}