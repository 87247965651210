export const messagesTemplates = (remoteName) => ([
    {
        "value": 1,
        "label": "I'm 5-minutes late",
        "body": "Hi "+remoteName+", I'm going to be 5-minutes late today. Talk soon!"
    },
    {
        "value": 2,
        "label": "I look forward to call",
        "body": "Hi "+remoteName+", I look forward to speaking with you!"
    },
    {
        "value": 3,
        "label": "Hanging up soon",
        "body": "Hi "+remoteName+", I’ll stay on the line for a few more minutes. Please dial (617) 380-4313 to join now."
    }
]);