import {images} from '../../../common/components/images';

export const devEnterpriseExistingCourses = [
    {
        "id": 70,
        "title": "Week #1",
        "description": "<p>Here is a description of this amazing course!</p>",
        "imageUrl": images.devDataStoreProduct,
        "pages": [],
        "name": "week1",
        "isActive": true,
        "tags": []
    },
    {
        "id": 71,
        "title": "Week #2",
        "description": "<p>Here is a description of this amazing course!</p>",
        "imageUrl": images.devDataStoreProduct,
        "pages": [],
        "name": "week2",
        "isActive": true,
        "tags": []
    },
    {
        "id": 74,
        "title": "Week #2",
        "description": "<p>Here is a description of this amazing course! is a description of this amazing course! is a description of this amazing course! is a description of this amazing course! is a description of this amazing course!</p>",
        "imageUrl": images.devDataStoreProduct,
        "pages": [],
        "name": "week2",
        "isActive": true,
        "tags": []
    },
    {
        "id": 75,
        "title": "Week #2",
        "description": "<p>Here is a description of this amazing course!</p>",
        "imageUrl": images.devDataStoreProduct,
        "pages": [],
        "name": "week2",
        "isActive": true,
        "tags": []
    },
    {
        "id": 76,
        "title": "Week #2",
        "description": "<p>Here is a description of this amazing course!</p>",
        "imageUrl": images.devDataStoreProduct,
        "pages": [],
        "name": "week2",
        "isActive": true,
        "tags": []
    }
];

export const devEnterpriseNewCourse = {
    "id": 70,
    "title": "Week #1",
    "description": "<p>Here is a description of this amazing course!</p>",
    "imageUrl": images.devDataStoreProduct,
    "pages": [],
    "name": "week1",
    "isActive": true,
    "tags": []
};

export const devEnterpriseEditedCourse = (courseId, courseData, getState) => {
    const course = getState().enterprise.forms.courses.get("coursesAll").filter(c => {return(c.get("id") === courseId)}).get(0);
    return({
        ...course.toJS(),
        ...courseData
    })
};

export const devEnterpriseCourseReport = {
    "id": 70,

};