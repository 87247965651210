import React from 'react';

/* Components */
import {Header, Body} from "../components/cards";
import {LogoLoading} from "../../../common/components/loading";

/* Containers */
import Title from "./commontitle";
import TimeLeft from "./commontimeleft";
import CheckoutCardsAddStripe from "./cardsaddstripe";

class CheckoutCardsAdd extends React.Component {
    state={"isLoading": true};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "isLoading": false
        });
    };

    onSaveCard = async (paymentMethod) => {
        await this.setState({"isLoading": true})
        await this.props.onCardSelect(paymentMethod);
        await this.setState({"isLoading": false})
    };

    render() {
        if(this.state.isLoading) {
            return(<><br /><br /><LogoLoading /></>)
        } else {
            return(
                <>
                    <Header>
                        <Title
                            title={"New Card"}
                            onBack={this.props.onBack}
                        />
                        <TimeLeft
                            secondsAvailable={this.props.secondsAvailable}
                        />
                    </Header>
                    <Body>
                        <CheckoutCardsAddStripe
                            onSaveCard={this.onSaveCard}
                        />
                    </Body>
                </>
            )
        }
    }
}

export default CheckoutCardsAdd;
