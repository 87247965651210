import React from 'react';
import {connect} from "react-redux";
import 'moment-timezone/builds/moment-timezone-with-data-2012-2022';
import moment from "moment-timezone";
import styled from 'styled-components';

/* Components */
import {DropdownInput, CalendarDay, ClockTime, TextInput} from "../../../common/components/inputs";
import {FontTitle18, FontBody12} from '../../../common/components/fonts';
import {LogoLoading} from "../../../common/components/loading";
import {colors} from "../../../common/components/colors";
import {CardOrNot} from '../components/clientscreate';

/* Middleware */
import {tryGetSettingsPersonalInfo} from '../../middleware/settingspersonalinfo';

/* Utils */
import {timeZoneOptions} from '../../../common/utils/timezone2';
import {formatTZ} from '../../../common/utils/timezone';
import {ButtonTertiaryAlert} from "../../../common/components/buttons";

const Gray = styled.div`
    color: ${colors.alert100};
    padding: 0px 15px;
    width: calc(100% - 30px);
`;
const FitContent = styled.div`
    width: fit-content;
    cursor: pointer;   
    margin: 0px auto;
`;

const InputColor = styled.div`
    & input {
        color: ${colors.primary30};
    }
`;

const calcOffset = (tz) => {
    if(tz === "eastern") {return 4}
    else if(tz === "central") {return 5}
    else if(tz === "mountain") {return 6}
    else if(tz === "pacific") {return 7}
};

const calcTimeZoneOffset = (startTZ, endTZ) => {
    let startOffset = calcOffset(startTZ);
    let endOffset = calcOffset(endTZ);
    return (endOffset - startOffset);
};

class MeetingsCreateWhen extends React.Component {
    static defaultProps={
           isEdit: false,
           isCreate: false, 
    }

    state = {
        timeZone: '',
        startAlreadyPast: true,
        endAlreadyPast: true,
        atLeastOneHr: true,
        endAfterStart: true,
        day: new Date(),
        start: new Date(),
        end: new Date(),
        validTimes: true,
        isLoadingWhen: true,
        startInTZ: null,
        endInTZ: null,
        isChanged: false,
        previousWhen: {
            start: new Date(),
            end: new Date(),
        }
    };

    componentDidMount() {
        return this.init(true)
    }

    init = async (savePrevious) => {
        if(savePrevious) {
            await this.setState({"previousWhen": this.props.when});
        }
        if(this.props.timeZone === "") {
            await this.props.tryGetSettingsPersonalInfo();
        }
        await this.setState({"timeZone": this.props.timeZone});
        if(this.props.isEdit) {
            await this.setState({
                "day": new Date(moment(moment(this.state.previousWhen.start).format("YYYY-MM-DD[T]")+moment(this.state.previousWhen.start).format("HH:mm:00")).tz(formatTZ(this.state.timeZone)).toISOString()),
                "start": new Date(moment(moment(this.state.previousWhen.start).format("YYYY-MM-DD[T]")+moment(this.state.previousWhen.start).format("HH:mm:00")).tz(formatTZ(this.state.timeZone)).toISOString()),
                "end": new Date(moment(moment(this.state.previousWhen.end).format("YYYY-MM-DD[T]")+moment(this.state.previousWhen.end).format("HH:mm:00")).tz(formatTZ(this.state.timeZone)).toISOString()),
                "isChanged": false
            });
        } else {
            if(this.props.sessionSelected === null) {
                let end = new Date(moment(moment().add(1, 'hours').format("YYYY-MM-DD[T]") + moment(moment().add(1, "minute").add(1, 'hours')).format("HH:mm:00")).tz(formatTZ(this.state.timeZone)).toISOString());
                await this.setState({
                    "day": new Date(moment(moment().format("YYYY-MM-DD[T]") + moment().add(1, "minute").format("HH:mm:00")).tz(formatTZ(this.state.timeZone)).toISOString()),
                    "start": new Date(moment(moment().format("YYYY-MM-DD[T]") + moment().add(1, "minute").format("HH:mm:00")).tz(formatTZ(this.state.timeZone)).toISOString()),
                    "end": end,
                    "isChanged": false
                });
            } else {
                await this.setState({
                    "day": new Date(moment(moment(this.props.sessionSelected.get("start")).format("YYYY-MM-DD[T]")+moment(this.props.sessionSelected.get("start")).format("HH:mm:00")).tz(formatTZ(this.state.timeZone)).toISOString()),
                    "start": new Date(moment(moment(this.props.sessionSelected.get("start")).format("YYYY-MM-DD[T]")+moment(this.props.sessionSelected.get("start")).format("HH:mm:00")).tz(formatTZ(this.state.timeZone)).toISOString()),
                    "end": new Date(moment(moment(this.props.sessionSelected.get("end")).format("YYYY-MM-DD[T]")+moment(this.props.sessionSelected.get("end")).format("HH:mm:00")).tz(formatTZ(this.state.timeZone)).toISOString()),
                    "isChanged": false
                });
            }
        }
        await this.setState({"isLoadingWhen": false});
        await this.validateTimes();
    };

    handleChangeTimeZone = async e => {
        await this.setState({
            'timeZone': e.value,
            'isChanged': true
        });
        this.validateTimes();
    };

    handleDayChange = async day => {
        if(day !== null) {
            await this.setState({
                "day" : day,
                'start': new Date(moment(moment(day).format("YYYY-MM-DD[T]")+moment(this.state.start).format("HH:mm:00")).tz(formatTZ(this.state.timeZone)).toISOString()),
                'end': new Date(moment(moment(day).format("YYYY-MM-DD[T]")+moment(this.state.end).format("HH:mm:00")).tz(formatTZ(this.state.timeZone)).toISOString()),
                'isChanged': true
            });
            this.validateTimes();
        }
    };

    timeWithZero = (t) => {
        if(t.length === 1) {return("0"+t)}
        else {return(t)}
    };

    handleTimeChange = async (newTime, period) => {
        let hour = this.timeWithZero(newTime.hour.toString());
        let minute = this.timeWithZero(newTime.minute.toString());
        if(period === "start") {
            let hourEnd = this.timeWithZero((newTime.hour+1).toString());
            let minuteEnd = this.timeWithZero(newTime.minute.toString());
            await this.setState({
                "start" : new Date(moment(moment(this.state.day).format("YYYY-MM-DD[T]")+hour+":"+minute).tz(formatTZ(this.state.timeZone)).toISOString()),
                "end" : new Date(moment(moment(this.state.day).format("YYYY-MM-DD[T]")+hourEnd+":"+minuteEnd).tz(formatTZ(this.state.timeZone)).toISOString()),
                'isChanged': true
            });
        } else {
            await this.setState({
                "end" : new Date(moment(moment(this.state.day).format("YYYY-MM-DD[T]")+hour+":"+minute).tz(formatTZ(this.state.timeZone)).toISOString()),
                'isChanged': true
            });
        }
        this.validateTimes();
    };

    validateTimes = async () => {
        const hoursOffset = await calcTimeZoneOffset(this.props.timeZone, this.state.timeZone);
        await this.setState({
            "endAfterStart": !(moment(this.state.end).isBefore(moment(this.state.start))),
            // "atLeastOneHr": !(moment.duration(moment(this.state.end).diff(moment(this.state.start))).asMinutes() < 60),
            "startAlreadyPast": !(moment(this.state.start).subtract(hoursOffset, "hours").isBefore(moment())),
            "endAlreadyPast": !(moment(this.state.end).subtract(hoursOffset, "hours").isBefore(moment()))
        });
        const isValid = (this.state.endAfterStart /* && this.state.atLeastOneHr */ && this.state.endAlreadyPast && this.state.startAlreadyPast);
        await this.setState({
            "startInTZ": isValid ? new Date(moment(this.state.start).subtract(hoursOffset, "hours").tz(formatTZ(this.state.timeZone)).toISOString()) : null,
            "endInTZ": isValid ? new Date(moment(this.state.end).subtract(hoursOffset, "hours").tz(formatTZ(this.state.timeZone)).toISOString()) : null,
            "validTimes": isValid
        });
        return this.props.onValidate(this.state);
    };

    render() {
        if(this.state.isLoadingWhen) {
            return (<LogoLoading />)
        } else {
            return(
                <>
                    <CardOrNot>
                        <FontTitle18>When</FontTitle18>
                        <CalendarDay disabled={!(this.props.isEdit || this.props.isCreate)} id={"date"} title={"Date*"} onChange={this.handleDayChange} value={this.state.day} />
                        <ClockTime endTooltip={false} zIndex={2} absolute={true} disabled={!(this.props.isEdit || this.props.isCreate)} id={"start"} title={"Start time*"} valid={this.state.endAfterStart} warning={"After the end time"} validEnd={this.state.startAlreadyPast} warningEnd={"has already past"} handleTimeChange={this.handleTimeChange} value={this.state.start} />
                        <ClockTime endTooltip={true} zIndex={3} absolute={true} disabled={!(this.props.isEdit || this.props.isCreate)} id={"end"} title={"End time*"} valid={this.state.atLeastOneHr} warning={"Minimum 1 hour"} validEnd={this.state.endAlreadyPast} warningEnd={"has already past"} handleTimeChange={this.handleTimeChange} value={this.state.end} />
                        {this.props.isEdit || this.props.isCreate
                        ?
                            <DropdownInput
                                title={"Time zone*"}
                                options={timeZoneOptions}
                                id={'timeZone'}
                                onChange={this.handleChangeTimeZone}
                                placeholder={'Time Zone'}
                                value={this.state.timeZone}
                            />
                        :
                            <>
                                {/*primary30*/}
                                <InputColor>
                                    <TextInput
                                        title={"Time zone*"}
                                        valid={true}
                                        warning={""}
                                        id={"timeZone"}
                                        onChange={() => {}}
                                        placeholder={"Time Zone"}
                                        value={this.state.timeZone.charAt(0).toUpperCase()+this.state.timeZone.slice(1)+" Time"}
                                        disabled={true}
                                    />
                                </InputColor>
                            </>
                        }
                        <br />
                        {(this.props.isEdit && !this.props.isCreate) &&
                            <Gray>
                                <FontBody12>Note: All registered participants will be emailed regarding any changes to meeting day/time.</FontBody12>
                            </Gray>
                        }
                        
                        {/*{(this.props.isEdit && this.state.isChanged) &&*/}
                        {/*    <>*/}
                        {/*        <br />*/}
                        {/*        <Gray>*/}
                        {/*            <FontBody12>Caution, all registered participants will be notified of changes to the day/time by email.</FontBody12>*/}
                        {/*        </Gray>*/}
                        {/*        <br />*/}
                        {/*        <FitContent onClick={() => {return this.init(false)}}>*/}
                        {/*            <ButtonTertiaryAlert canSubmit={false} label={"Undo Change"} />*/}
                        {/*        </FitContent>*/}
                        {/*    </>*/}
                        {/*}*/}
                    </CardOrNot>
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    timeZone: state.enterprise.settingsPersonal.get("settingsPersonalInfo").get("timeZone"),
    sessionSelected: state.enterprise.sessions.get("sessionSelected"),
});

const mapDispatchToProps = dispatch => ({
    tryGetSettingsPersonalInfo: () => dispatch(tryGetSettingsPersonalInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MeetingsCreateWhen);
