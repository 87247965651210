import React from 'react';

/* Components */
import {Title, Back, InlineLabel} from "../components/terms";
import {FontTitle24} from "../../../common/components/fonts";
import {ArrowLeft} from 'react-feather';

class CheckoutTermsTitle extends React.Component {
    state={"isLoading": true, };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "title": this.props.title,
            "isLoading": false
        });
    };

    render() {
        if(this.state.isLoading) {
            return null
        } else {
            return(
                <>
                    <Back onClick={this.props.onBack}><ArrowLeft />&nbsp;<InlineLabel>Back</InlineLabel></Back>
                    <Title><FontTitle24>{this.state.title}</FontTitle24></Title>
                </>
            )
        }
    }
}

export default CheckoutTermsTitle;
