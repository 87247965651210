import React from 'react';
import {connect} from "react-redux";
import { Route, Redirect, Switch } from 'react-router-dom';

/* Containers */
import Verify from './containers/verify';
import Logout from "./containers/logout";
import SecureRoute from "./containers/routesecure";

/* Domain */
import getDomainEnv from '../domains/utils';
const env = getDomainEnv();

/* Domain Routes */
import RoutesTrustWillow, {trustwillowRoutesPublic, trustwillowRoutesSecure} from '../domains/trustwillow.com/routes/routes.js'
import RoutesStarsPlease, {starspleaseRoutesPublic, starspleaseRoutesSecure} from '../domains/starsplease.com/routes/routes.js'
import RoutesLuxAide, {luxaideRoutesPublic, luxaideRoutesSecure} from '../domains/luxaide.com/routes/routes.js'

const Routes = ({isUserAuthenticated}) => (
    <Switch>
        <Route path={'/verify'} component={Verify} />
        <SecureRoute exact path={'/logout'} component={Logout} />

        {env.URL.DOMAIN === "trustwillow.com" &&
            <Route
                path={isUserAuthenticated ? trustwillowRoutesSecure : trustwillowRoutesPublic}
                component={RoutesTrustWillow}
            />
        }

        {env.URL.DOMAIN === "starsplease.com" &&
            <Route
                path={isUserAuthenticated ? starspleaseRoutesSecure : starspleaseRoutesPublic}
                component={RoutesStarsPlease}
            />
        }

        {env.URL.DOMAIN === "luxaide.com" &&
            <Route
                path={isUserAuthenticated ? luxaideRoutesSecure : luxaideRoutesPublic}
                component={RoutesLuxAide}
            />
        }

        <Redirect from={"*"} to={'/verify'} />
    </Switch>
);

const mapStateToProps = state => ({
    isUserAuthenticated: state.common.user.get('isUserAuthenticated')
});

export default connect(mapStateToProps, null)(Routes);
