import React from 'react';
import styled from 'styled-components';

/* Components */
import {colors} from '../../common/components/colors';
import {FontHeader14, FontBody12, FontHeader16} from '../../common/components/fonts';
import {CaretDownOutlined, CloseOutlined} from '@ant-design/icons';
import {PercentInputFilter} from "./inputs";

const Container = styled.div`
    margin-top: 10px;
    width: 250px;
    max-height: 350px;
    position: absolute;
    z-index: 10;
    border: 1px solid ${colors.primary30};
    -webkit-box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
`;
const TableContainer = styled.div`
    position: relative;
    vertical-align: top;
`;
const FilterButton = styled.div`
    position: relative;
`;
const Background = styled.div`
    z-index: 9;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`;
const Label = styled.div`
    width: fit-content;
    color: ${colors.action100};
    cursor: pointer;
    padding: 7px 15px;
    ${props => props.background === false} {
        background-color: ${colors.action15};
        border-radius: 2px;
    }
`;
const Arrow = styled.div`
    vertical-align: middle;
    display: inline-block;
    padding-right: 5px;
`;
const LabelText = styled.div`
    vertical-align: top;
    display: inline-block;
    padding-right: 3px;
`;
const Close = styled.div`
    z-index: 8;
    display: inline-block;
    vertical-align: middle;
`;
const RangeBox = styled.div`
    background-color: ${colors.white};
    padding: 10px;
    color: ${colors.secondary100};
`;
const InputBox = styled.div`
    margin-top: 20px;
`;
const Input = styled.div`
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 15px);
`;
const To = styled.div`
    display: inline-block;
    width: 30px;
    vertical-align: top;
    text-align: center;
    margin-top: 10px;
`;

// this.props.title = title of dropdown contents
// this.props.action = function to fire when checked
// this.props.minMax = max and min
// this.props.initSelected = max and min
// this.props.label = box text
// this.props.type = $ or %

class FilterRange extends React.Component {
    state = {"showFilter": false, "isLoading": true, "data": {"min": null, "max": null}, "selected": {"min": null, "max": null}, "isChanged": false};

    componentDidMount() {
        return this.update();
    }

    update = async () => {
        await this.setState({
            "data": this.props.minMax,
            "selected": this.props.initSelected,
            "isLoading": false
        });
    };

    close = async (e) => {
        e.stopPropagation();
        await this.setState({
            "selected": this.state.data,
            "isChanged": false,
            "showFilter": false
        });
        this.save();
    };

    save = async () => {
        this.props.action(this.props.title, this.state.selected);
    };

    showFilter = () => {
        this.setState({"showFilter": !this.state.showFilter})
    };

    isChanged = async () => {
        await this.setState({"isChanged": !(this.state.selected.max === this.state.data.max && this.state.selected.min === this.state.data.min)});
    };

    handleChangeMin = async (e) => {
        await this.setState({"selected": {"min": e, "max": this.state.selected.max}});
        await this.isChanged();
        this.save();
    };

    handleChangeMax = async (e) => {
        await this.setState({"selected": {"max": e, "min": this.state.selected.min}});
        await this.isChanged();
        this.save();
    };

    render() {
        if(this.state.isLoading) {
            return null
        } else {
            return (
                <FilterButton>
                    <Label onClick={this.showFilter} background={this.state.isChanged}>
                        <LabelText><FontHeader14>{this.props.title}</FontHeader14></LabelText>
                        <Arrow><FontBody12><CaretDownOutlined /></FontBody12></Arrow>
                        {this.state.isChanged && <Close onClick={this.close}><FontHeader14><CloseOutlined /></FontHeader14></Close>}
                    </Label>
                    {this.state.showFilter &&
                        <>
                            <Container>
                                <TableContainer>
                                    <RangeBox>
                                        <FontHeader16>{this.props.label}</FontHeader16>
                                        <InputBox>
                                            <Input>
                                                <PercentInputFilter
                                                    title={"Min"}
                                                    id={"min"}
                                                    onChange={this.handleChangeMin}
                                                    placeholder={this.state.selected.min}
                                                    value={this.state.selected.min}
                                                    min={this.state.data.min}
                                                    max={this.state.data.max}
                                                    type={this.props.type}
                                                    suffix={this.props.suffix}
                                                />
                                            </Input>
                                            <To>to</To>
                                            <Input>
                                                <PercentInputFilter
                                                    title={"Max"}
                                                    id={"max"}
                                                    onChange={this.handleChangeMax}
                                                    placeholder={this.state.selected.max}
                                                    value={this.state.selected.max}
                                                    min={this.state.data.min}
                                                    max={this.state.data.max}
                                                    type={this.props.type}
                                                    suffix={this.props.suffix}
                                                />
                                            </Input>
                                        </InputBox>
                                    </RangeBox>
                                </TableContainer>
                            </Container>
                            <Background onClick={this.showFilter} />
                        </>
                    }
                </FilterButton>
            )
        }
    }
}

export default FilterRange;