import React from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from "styled-components";
import validator from "validator";

/* Components */
import { colors } from "../../../../common/components/colors";
import { FontBody24, FontSubtitle40, FontBody14, FontTitle28, FontTitle38, FontTitle21, FontBody21} from "../../../../common/components/fonts";
import { TextInputWhite } from "../../../../common/components/inputs";
import { ButtonPrimary, ButtonTertiary } from "../../../../common/components/buttons";
import { tryPostReferral } from "../middleware/referralpage";
import { images } from "../../../../common/components/images";

const Subtitle = styled(FontBody24)`
    margin: 20px 0 40px 0;
    text-align: center;
  @media screen and (max-width: 748px) {
    margin: 20px 0 42px 0;
  }
`;
const ButtonDiv = styled.div`
    display: flex;
    justify-items: left;
    width: fit-content;
`;
const CopyButtonDiv = styled.div`
    display: flex;
    margin-top: 30px;
    justify-items: left;
    width: fit-content;
`;
const InputDiv = styled.div`
    width: fit-content;
    min-width: 300px;
    margin-top: 10px;
`;

const CenterDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  text-align: center;
`;

const Description = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    flex-wrap: wrap;
      @media screen and (max-width: 748px) {
        //flex-direction: column;
        gap: 20px;
      }
`;
const Step = styled.div`
    text-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;
  justify-content: center;
  width: 250px;
  @media screen and (max-width: 1440px) {
    width: 200px;
  }
  @media screen and (max-width: 1088px) {
    width: 190px;
  }
  @media screen and (max-width: 748px) {
    width: 110px;
  }
`;
const Circle = styled.div`
    width: 210px;
    height: 210px;
    border-radius: 210px;
    background-color: ${props => props.backgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  @media screen and (max-width: 1440px) {
    width: 180px;
    height: 180px;
    border-radius: 180px;
  }
  @media screen and (max-width: 748px) {
  //    display: none;
      width: 100px;
      height: 100px;
      border-radius: 100px;
  }
`;
const ShareBubble = styled.img`
    width: 75px;
    height: auto;
  @media screen and (max-width: 1440px) {
    width: 60px;
  }
  @media screen and (max-width: 748px) {
    width: 30px;
  }
`;
const CircleText = styled(FontTitle38)`
  color: white;
  @media screen and (max-width: 1440px) {
    font-size: 30px;
    line-height: 36px;
  }
  @media screen and (max-width: 748px) {
    font-size: 21px;
    line-height: 24px;
  }
`;
const CircleTitle = styled(FontTitle21)`
  display: none;
  text-align: center;
  @media screen and (max-width: 748px) {
    //display: block;
  }
`;
const BelowCircle = styled(FontBody21)`
  text-align: center;
  margin-top: 20px;
  @media screen and (max-width: 1440px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
const ErrorMessage = styled(FontBody14)`
    margin-top: 10px;
    text-align: center;
    width: fit-content;
`;
const Form = styled.form`
    text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Border = styled.div`
  position: absolute;
  height: 5px;
  background-color: ${colors.action100};
  width: 100px;
  right: -100px;
  top: 50%;
  @media screen and (max-width: 415px) {
    display: none;
  }
`;


export const StyledModal = styled.div`
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  padding: 50px;
  border-radius: 30px;
  background-color: ${colors.white};
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  max-width: 390px;
  width: calc(100% - 100px);
  z-index: 20;
  word-wrap: break-word;
  tab-index: 1;
  @media screen and (max-width: 748px) {
    max-width: calc(100% - 100px);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform: translate(0%, 0%);
    border-radius: 0;
    overflow-y: auto;
  }
`;
export const Close = styled.div`
  width: fit-content;
  margin-left: auto;
  position: relative;
  height: 36px;
`;
export const CloseIcon = styled(FontBody24)`
  padding: 10px;
  position: absolute;
  top: -25px;
  right: -10px;
  line-height: 24px;
  cursor: pointer;
`;

class ReferralPage extends React.Component {
    static defaultProps = {
    }

    state = {
        "isLoading": true,
        "email": "",
        "showModal": false,
        "modalInfo": {},
        "clipboard": "Copy to Clipboard",
        "code": "",
        "errorEmail": false
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutID);
    }

    handleModal = async (e) => {
        e.preventDefault();
        if(!validator.isEmail(this.state.email)) {
            await this.setState({"errorEmail": true})
            return this.timeoutID = setTimeout(() => {this.setState({"errorEmail": false})}, 3000);
        }
        const code = await this.props.tryPostReferral(this.state.email);
        this.setState({
            modalInfo:
                ({
                    subject: "Customize & send this draft referral email to the advisor",
                    signature: "All the best,",
                    intro: "Hi [insert name],",
                    typeform: <a href={"https://www.trustwillow.com/choose-certificate?referralCode=" + code.referralCode}> https://www.trustwillow.com/choose-certificate?referralCode={code.referralCode}</a>,
                    text: "I enjoyed talking to you about my experience with Willow’s certificate and client acquisition programs. To get started on your certificate use this referral link:",
                    signupLink: "",
                    name: this.props.firstName,

                }),
            showModal: true,
            code: code.referralCode,
        })
    }


    componentDidMount() {
        this.init();
    }

    init = async () => {
        this.setState({ isLoading: false });
    };

    render() {
        if (this.state.isLoading) {
            return (
                <></>
            )
        }
        return (
            <>
                <CenterDiv>
                    <FontSubtitle40 spaced={true}>REFERRAL PROGRAM</FontSubtitle40>
                    <Subtitle>Get rewarded by referring Willow to other advisors</Subtitle>
                    <Description>
                        <Step>
                            <Circle backgroundColor={colors.backgroundColor5}>
                                <ShareBubble src={images.shareImage1} /><CircleText>Share</CircleText>
                                <Border />
                            </Circle>
                            <CircleTitle>Share</CircleTitle>
                            <BelowCircle>Generate a referral code & send it to an advisor</BelowCircle>
                        </Step>
                        <Step>
                            <Circle backgroundColor={colors.backgroundColor3}>
                                <ShareBubble src={images.shareImage2} /><CircleText>Sign Up</CircleText>
                                <Border />
                            </Circle>
                            <CircleTitle>Sign Up</CircleTitle>
                            <BelowCircle>Advisor signs up & purchases a certificate</BelowCircle>
                        </Step>
                        <Step>
                            <Circle backgroundColor={colors.secondary100}>
                                <ShareBubble src={images.shareImage3} /><CircleText>Earn</CircleText>
                            </Circle>
                            <CircleTitle>Earn</CircleTitle>
                            <BelowCircle>You receive $80 off your next bill</BelowCircle>
                        </Step>
                    </Description>
                    <Form onSubmit={this.handleModal}>
                        <InputDiv>
                            <TextInputWhite label="Email" placeholder="Enter an advisor's email" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} />
                        </InputDiv>
                        <ButtonDiv>
                            <ButtonPrimary label={"Generate Referral Code"} canSubmit={true}/>
                        </ButtonDiv>
                        {this.state.errorEmail && <ErrorMessage>Please enter a valid email address</ErrorMessage>}
                    </Form>
                </CenterDiv>
                {this.state.showModal &&
                    <StyledModal>
                        <Close onClick={(e) => {
                            this.setState({ showModal: false });
                            this.setState({ clipboard: "Copy to Clipboard" });
                        }}><CloseIcon>x</CloseIcon></Close>

                        <FontTitle28><b>{this.state.modalInfo.subject}</b></FontTitle28>
                        <br /> <br />
                        <FontBody14>{this.state.modalInfo.intro}</FontBody14>
                        <br />
                        <FontBody14>{this.state.modalInfo.text}</FontBody14>
                        <br />
                        <FontBody14>{this.state.modalInfo.typeform}</FontBody14>
                        <br />
                        <FontBody14>{this.state.modalInfo.signature}</FontBody14>
                        <FontBody14>{this.state.modalInfo.name}</FontBody14>
                        <CopyButtonDiv onClick={(e) => {
                            const { modalInfo } = this.state;
                            const formattedText =
                                // `${modalInfo.subject}\n\n` +
                                `${modalInfo.intro}\n\n` +
                                `${modalInfo.text}\n\n` +
                                `https://www.trustwillow.com/choose-certificate?referralCode=${this.state.code}\n\n` +
                                `${modalInfo.signature}\n` +
                                `${modalInfo.name}`;

                            navigator.clipboard.writeText(formattedText);
                            this.setState({ clipboard: "Copied!" })
                        }}>
                            <ButtonTertiary canSubmit={true} label={this.state.clipboard} />
                        </CopyButtonDiv>
                    </StyledModal>}
            </>
        )
    }
}

const mapStateToProps = state => ({
    firstName: state.common.user.get("first"),
});

const mapDispatchToProps = dispatch => ({
    tryPostReferral: (email) => dispatch(tryPostReferral(email)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReferralPage));
