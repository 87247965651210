import React from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';

import {LogoLoading} from "../../../common/components/loading";

import {tryGetUsersAdvisors, tryEditEmployee, tryEditEmployeeFromMenu} from '../middleware/settingsusers';
import { TableWrapper } from "../../../common/components/table";

import NavSecondaryTop from "../../navbars/containers/secondarytop";

/* Components */
import {PlusOutlined} from "@ant-design/icons";

const userButtons = [
    {
        "pathname": "/settings/users/create",
        "label": <><PlusOutlined /> Create</>,
        "buttonType": "primary"
    }
];

/* Table data */
const order = ["name", "email", "access", "action"];
const title = ["Name", "Email", "Access", "Actions"];
const styles = [{}, {}, {}, {}];
const format = [{element: "linkIfDataAllows", href: null, ifNull: null}, {element: "text", href: null, ifNull: null}, {element: "text", href: null, ifNull: null}, {element: "menuarray", href: null, ifNull: null}];
const actions = (actions, passToActions, extras) => ([
    {action: actions.editEmployee, passToAction: passToActions.editEmployee, extra: null},
    {action: null, passToAction: null, extra: null},
    {action: null, passToAction: null, extra: null},
    {action: [actions.editEmployeeFromMenu], passToAction: passToActions.editEmployee, extra: [extras.editEmployee]}
]);

class SettingsUsers extends React.Component {
    state = {isLoading: true, };

    componentDidMount() {
        return this.initiate();
    }

    initiate = async () => {
        await this.props.tryGetUsersAdvisors();
        this.setState({"isLoading": false})
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <>
                    <NavSecondaryTop title={"Users"} buttons={userButtons} />
                    {this.props.settingsUsers.size > 0 &&
                        <TableWrapper
                            responsive={true}
                            title={null}
                            tableData={{
                                order: order,
                                title: title,
                                data: this.props.settingsUsers.toJS(),
                                styles: styles,
                                format: format,
                                actions: actions({"editEmployee": this.props.tryEditEmployee, "editEmployeeFromMenu": this.props.tryEditEmployeeFromMenu}, {"editEmployee": this.props.history}, {"editEmployee": "Edit"})
                            }}
                        />
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    settingsUsers: state.enterprise.settingsUsers.get("settingsUsers")
});
const mapDispatchToProps = dispatch => ({
    tryGetUsersAdvisors: () => dispatch(tryGetUsersAdvisors()),
    tryEditEmployee: (history, data) => dispatch(tryEditEmployee(history, data)),
    tryEditEmployeeFromMenu: (data) => dispatch(tryEditEmployeeFromMenu(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SettingsUsers));
