import {axiosReq} from "../../../common/utils/axios";
import {setResetPasswordEmailSending, setTwoFactorVerifying, setSignUpSuccessEmailSending} from '../store/resetpassword';

export const trySendPasswordResetEmail = (email) => {
    return async dispatch => {
        dispatch(setResetPasswordEmailSending(true));
        await axiosReq('api-password-reset', "POST", {"email": email}, {})
        return dispatch(setResetPasswordEmailSending(false));
    }
};

export const trySendSignUpSuccessEmail = (email) => {
    return async dispatch => {
        dispatch(setSignUpSuccessEmailSending(true));
        await axiosReq('api-signup-success', "POST", {"email": email}, {})
        return dispatch(setSignUpSuccessEmailSending(false));
    }
}

export const tryTwoFactorVerify = (code, email) => {
    return async dispatch => {
        dispatch(setTwoFactorVerifying(true));
        const isValid = await axiosReq('is-token-valid', "POST", {"token": code, "email": email}, {"isValid": true})
        dispatch(setTwoFactorVerifying(false));
        return isValid.isValid
    }
};

export const tryScheduleLogin = (email, password) => {
    return async dispatch => {
        await axiosReq('api-login', "POST", {"email": email, "password": password}, {});
    }
};