import React from 'react';
import {connect} from "react-redux";
import NavSecondaryTop from "../../navbars/containers/secondarytop";
import {FontTitle18, FontBody16} from "../../../common/components/fonts";
import {InlineBlock, SaveSection, SubmitInputWithValidation, Width} from "../../components/clientscreate";
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import validator from "validator";
import styled from 'styled-components';
import {colors} from '../../../common/components/colors';
import {images} from '../../../common/components/images';
import {DeleteFilled} from '@ant-design/icons';
import {ButtonLink} from '../../../common/components/buttons';
import {v4 as uuid4} from 'uuid';

import {tryGetProspectStages, trySaveProspectStages} from '../../middleware/prospectsstages';

const Icon = styled.img`
    padding: 14px 15px;
    vertical-align: top;
    cursor: grab;
`;
const Item = styled.div`
    margin-bottom: 10px;
`;
const Box = styled.div`
    border: 1px solid ${colors.secondary70};
    display: inline-block;
    width: calc(100% - 48px);
    vertical-align: top;
`;
const Input = styled.input`
    width: calc(100% - 65px);
    padding: 0px 4px;
    margin: 9px 0px;
    outline: none;
    vertical-align: top;
    border: 3px solid transparent;
    font-family: GraphicWebRegular;
    font-size: 16px;
    line-height: 24px;
    &:focus {
        border: 3px solid ${colors.action100};
        border-radius: 4px;
    }
`;
const Trash = styled.div`
    display: inline-block;
    font-size: 16px;
    padding: 17px 15px;
    vertical-align: top;
    cursor: pointer;
    color: ${colors.secondary70};
`;
const StagesBlock = styled.div`
    margin: 20px 0px;
`;

const DragHandle = sortableHandle(() => <Icon src={images.prospectIconDrag} height={20} width={8.5} />);
const SortableItem = sortableElement(({stage, action, deleteStage}) => (
    <Item>
        <Box><DragHandle /><Input value={stage.title} onChange={(e) => action(e, stage)} /></Box>
        <Trash onClick={() => deleteStage(stage.id)}><DeleteFilled /></Trash>
    </Item>
));
const SortableContainer = sortableContainer(({children}) => {
    return <StagesBlock>{children}</StagesBlock>;
});

class SettingsProspects extends React.Component {
    state = {
        stages: [],
        stagesBackup: [],
        isSaving: false,
        isSaved: false,
        error: '',
        isChanged: false,
        isValid: true,
        isUnique: true,
        toDelete: [],
        toUpdateTitle: [],
        toAdd: [],
        toOrder: []
    };

    componentDidMount() {
        return this.updateProspectStages()
    }

    updateProspectStages = async () => {
        await this.props.tryGetProspectStages();
        const stages = this.props.prospects.toJS();
        this.setState({'stages': stages, 'stagesBackup': stages, "toDelete": [], "toUpdateTitle": [], "toAdd": [], "toOrder": []});
    };

    deleteProspectStage = async (id) => {
        let toDelete = this.state.toDelete;
        const stages = await this.state.stages.filter(s => {
            if(s.id === id) {
                if(!s.isNew) {toDelete = [...toDelete, s]}
                return false;
            } else {
                return true;
            }
        });
        const newStages = await this.state.toAdd.filter(n => {
            return n.id !== id;
        });
        await this.setState({
            'stages': stages,
            'isChanged': true,
            'toDelete': toDelete,
            'toAdd': newStages
        });
        console.log(this.state);
        this.checkIsTitleUnique();
    };

    createProspectStage = async () => {
        let toAdd = this.state.toAdd;
        const newStage = {"id": uuid4(), "title": "New stage", "orderIndex": this.state.stages.length+1, "isDelete": false, "isNew": true};
        await this.setState({
            'stages': [...this.state.stages, newStage],
            'isChanged': true,
            'toAdd': [...toAdd, newStage]
        });
        console.log(this.state);
        this.checkIsTitleUnique();
    };

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    onSortEnd = async ({oldIndex, newIndex}) => {
        await this.setState(({stages}) => ({stages: arrayMove(stages, oldIndex, newIndex)}));
        this.setState({"isChanged": true});
        console.log(this.state);
    };

    handleChangeInput = async (e, stage) => {
        const newTitle = e.target.value;
        const stages = await this.state.stages.map(s => {
            if(s.id === stage.id) {
                return {...s, "title": newTitle};
            } else {
                return s;
            }
        });
        let toUpdateTitle = this.state.toUpdateTitle;
        let before = await this.state.stagesBackup.filter(b => {return b.id === stage.id;})[0];
        if(!stage.hasOwnProperty("isNew")) {
            if(toUpdateTitle.length > 0) {
                if(before.title !== newTitle) {
                    // Add to change name if not equal to previous name
                    toUpdateTitle = await toUpdateTitle.map(d => {
                        if(d.id === stage.id) {
                            return({...d, "title": newTitle});
                        } else {
                            return(d);
                        }
                    });
                } else {
                    // Remove from change name if equal to previous name
                    toUpdateTitle = await toUpdateTitle.filter(d => {return d.id !== stage.id;});
                }
            } else {
                toUpdateTitle = [{...stage , "title": newTitle}]
            }
        }
        let toAdd = this.state.toAdd;
        if(stage.isNew) {
            toAdd = await toAdd.map(n => {
                if(n.id === stage.id) {
                    return {...n, "title": newTitle};
                } else {
                    return n;
                }
            });
        }
        await this.setState({
            'stages': stages,
            'isChanged': true,
            'toAdd': toAdd,
            'toUpdateTitle': toUpdateTitle
        });
        this.checkValid(newTitle);
        this.checkIsTitleUnique();
        console.log(this.state)
    };

    checkValid = (newTitle) => {
        if(!validator.isEmpty(newTitle) && newTitle !== '') {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    checkIsTitleUnique = async () => {
        const titles = await this.state.stages.map(s => {return s.title});
        const titlesSet = new Set(titles);
        if(titles.length !== titlesSet.size) {
            this.setState({'isUnique': false})
        } else {
            this.setState({'isUnique': true})
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({'isSaving': true, 'error': ''});
        await this.props.trySaveProspectStages(this.state);
        if(this.props.prospectStagesError !== '') {
            this.setState({"error": this.props.prospectStagesError});
        } else {
            console.log("refresh stages");
            this.updateProspectStages();
        }
        this.setState({'isSaving': false, 'isSaved': true, 'isChanged': false});
        this.timeout = setTimeout(() => {this.setState({'isSaved': false})}, 3000);
    };

    render() {
        const {stages} = this.state;
        return(
            <>
                <NavSecondaryTop title={"Prospects"} />
                <form method={"post"} onSubmit={this.handleSubmit}>
                    {this.state.isSaved
                        ?
                            <>
                                {this.state.error === '' && <FontTitle18>Saved!</FontTitle18>}
                                {this.state.error !== '' && <FontTitle18>{this.state.error}</FontTitle18>}
                            </>
                        :
                            <>
                                <Width>
                                    <FontTitle18>Stages</FontTitle18>
                                    <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
                                        {stages.map((s, index) => {
                                            if(s.title !== "undefined" && s.title !== "client") {
                                                return (<SortableItem key={`item-${s.id}`} index={index} stage={s} action={this.handleChangeInput} deleteStage={this.deleteProspectStage} />)
                                            }
                                        })}
                                    </SortableContainer>
                                    <span onClick={this.createProspectStage}><ButtonLink label={"+ Add a stage"} /></span>
                                </Width>
                                <SaveSection>
                                    <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={(this.state.isValid && this.state.isUnique)} isSaving={this.state.isSaving} /></InlineBlock>
                                </SaveSection>
                            </>
                    }
                </form>
            </>
        )
    }
}

const mapStateToProps = state => ({
    prospectStagesError: state.enterprise.prospects.get("prospectStagesError"),
    prospects: state.enterprise.prospects.get("prospectStages")
});
const mapDispatchToProps = dispatch => ({
    tryGetProspectStages: () => dispatch(tryGetProspectStages()),
    trySaveProspectStages: (data) => dispatch(trySaveProspectStages(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SettingsProspects);