import React from 'react';
import styled from "styled-components";
import {withRouter} from "react-router-dom";

/* Navs */
import Nav from "../navbars/containers/nav";
import {PageContentContainer, PageFull, PageContent} from "../navbars/components/navlayouts";

/* Containers */
import FirmSettingsProfile from "./containers/profile";
// import FirmSettingsCompany from "./containers/company";
// import FirmSettingsUsers from "./containers/users";
// import FirmSettingsUserCreate from "./containers/usercreate";
// import FirmSettingsUserEdit from "./containers/useredit";
// import FirmSettingsBilling from "./containers/billing";

/* Components */
// import FirmSettingsNav from "./components/settingsnav";
import {colors} from "../../../common/components/colors";
import {FontSubtitle40} from "../../../common/components/fonts";

const Foreground = styled.div`
  position: relative;
  z-index: 2;
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.white};
  z-index: 1;
`;
const Title = styled(FontSubtitle40)`
  margin-bottom: 50px;
`;

const FirmSettings = ({location}) => (
    <>
        <Nav />
        <PageFull>
            <PageContentContainer fullPage={true}>
                <PageContent>

                    <Foreground>
                        <Title spaced={true}>SETTINGS</Title>

                        {/*<FirmSettingsNav />*/}

                        {/*{location.pathname === "/my-settings" && */}
                            <FirmSettingsProfile />
                            {/*}*/}

                        {/*{location.pathname === "/my-settings/company" && <FirmSettingsCompany />}*/}

                        {/*{location.pathname === "/my-settings/users" && <FirmSettingsUsers />}*/}

                        {/*{location.pathname === "/my-settings/users/create" && <FirmSettingsUserCreate />}*/}

                        {/*{location.pathname === "/my-settings/users/edit" && <FirmSettingsUserEdit />}*/}

                        {/*{location.pathname === "/my-settings/billing" && <FirmSettingsBilling />}*/}

                    </Foreground>
                    <Background />

                </PageContent>
            </PageContentContainer>
        </PageFull>
    </>
);

export default withRouter(FirmSettings);