import React from 'react';
import styled from 'styled-components';
import {Map, fromJS} from 'immutable';
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';

/* Components */
import { colors } from '../../../common/components/colors';
import { FontBody24} from '../../../common/components/fonts';
import {LogoLoading} from "../../../common/components/loading";
import {ButtonSecondary} from "../../../common/components/buttons";
import CoachingCategoriesHeader from "../components/headerpage";
import HeaderCoach from "../components/headercoach";
import {FooterCoach} from "../components/footercoach";

/* Middleware */
import {tryGetAllCoachesFromCategory} from '../middleware/coaching';
import {tryGetExpertProfileFromToken} from '../../../iframes/profile/middleware/profile';
import {tryGetAllTeamMembers} from "../../team/middleware/team";

/* Containers */
import CoachOne from "./coachprofile";

/* Utility */
import { hexToRGB, brightness } from '../../../common/utils/colors';
import {trackViewGetCareCoachProfile} from "../../../common/utils/tracking";

const PageFull = styled.div`
    height: 100vh;
    width: 100%;
    min-height: 100vh;
    overflow-y: auto;
`;

const CareTeamInfo = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: flex-start;
    align-items: center;
    padding: 60px 0 120px 0;
    background-color: ${colors.white};
`;
const ContainerCoach = styled.div`
    display: flex;
    width: 95%;
    flex-direction: column;
    border-radius: 30px;
    background-color: ${props => props.backgroundColor};
    color: ${props => brightness(hexToRGB(props.backgroundColor, false)) < 130 ? colors.white : colors.primary100};
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
`;
const FitContent = styled.div`
    width: fit-content;
    margin: 0 auto;
    text-align: center;
`;
const OpenCoach = styled.div`
    cursor: pointer;
`;

class CoachingCategoryOne extends React.Component {
    static defaultProps = {
        backgroundColor: null
    };

    state={category: Map(), coachesShow: [], selectedCoach: null, selectedCoachId: null, isLoading: true, forceSortCoaches: false};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const coaches = await this.coachesFormatted();
        const members = await this.membersIds()
        const newCoaches = coaches.filter(c => {return(!members.includes(c.coachId.toString()))});
        const sortCoaches = await this.sortCoaches(newCoaches);
        this.setState({
            "coachesShow": sortCoaches,
            'isLoading': false
        })
    }

    deselectCoach = () => {
        this.setState({"selectedCoach": null, "selectedCoachId": null});
    }

    selectCoach = async (coach) => {
        const profile = await this.props.tryGetExpertProfileFromToken(coach.id)
        if (this.state.selectedCoachId === null || this.state.selectedCoachId !== coach.id) {
            this.setState({ "profile": fromJS(profile), "selectedCoach": coach, "selectedCoachId": coach.id});
            this.props.trackViewGetCareCoachProfile({"coachId": coach.id, "coachName": coach.first+" "+coach.last})
        } else {
            this.deselectCoach();
        }
    }

    coachesFormatted = async () => {
        const path = window.location.pathname.split("/");
        let subCategoryId = path[path.length - 1];
        let subCategoryIds;
        if(subCategoryId.toString() === "4") {
            subCategoryIds = [1, 4]
        } else {
            subCategoryIds = [subCategoryId]
        }
        const coaches = await this.props.tryGetAllCoachesFromCategory({"ids": subCategoryIds, "wmcId": this.props.wmcId})
        const coachesFiltered = await coaches.filter(m => {
            let expertCategoryIds = m.expertCategories.filter(c => {
                return (c.categoryId.toString() === "6" || c.categoryId.toString() === "7")
            });
            return(expertCategoryIds.length === 0)
        });
        return this.formatData(coachesFiltered);
    }

    formatData = async (coaches) => {
        return Promise.all(
            coaches.map(c => {
                let c1 = c;
                c1['coachId'] = c.id;
                c1['first'] = c.firstName;
                c1['last'] = c.lastName;
                return Promise.resolve(c1);
            })
        )
    }

    /* Temporary function to check if coach id is of the coaches we want to show for video */
    // checkInCoaches = (coachId) => {
    //     return /*coachId === 3452 || coachId === 1 || */coachId === 40 || coachId === 49 || coachId === 3452 || coachId === 37 || coachId === 76;
    // }

    sortCoaches = (coaches) => {
        /** TEMPORARY: Manually sort "Heather Conner" and diverse coaches to top of coach list */
        // let prefix = [];
        // prefix = coaches.filter(c => this.checkInCoaches(c['id']));
        // let rest = coaches.filter(c => !this.checkInCoaches(c['id'])).sort().reverse();
        // coaches = this.state.forceSortCoaches ? (prefix ? prefix.concat(rest) : rest) : coaches;
        // return coaches;
        /** END TEMPORARY CHANGE */
        return coaches.sort((a, b) => 0.5 - Math.random());
    }

    membersIds = async () => {
        await this.props.tryGetAllTeamMembers();
        return this.props.members.reduce((filtered, m) => {
            if(m.get("wmcId") === null && !(m.get("notATeamMember"))) {
                filtered.push(m.get("coachId").toString())
            }
            return filtered;
        }, []);
    }

    render() {
        if (this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <PageFull>
                    <CoachingCategoriesHeader
                        backUrl={"/my-team/find"}
                        title={this.props.subcategory.get("title").toUpperCase()}
                        subTitle={this.props.category.get("title").toUpperCase()}
                    />
                    <CareTeamInfo>
                        {this.state.coachesShow.map((data, index) => (
                            <ContainerCoach
                                key={index}
                                backgroundColor={this.props.backgroundColor === null ? colors.cellColors[index % colors.cellColors.length] : this.props.backgroundColor}
                            >
                                <OpenCoach onClick={() => {return this.selectCoach(data)}}>
                                    <HeaderCoach
                                        data={data}
                                        forceSortCoaches={this.state.forceSortCoaches}
                                    />
                                </OpenCoach>
                                {this.state.selectedCoachId !== null &&
                                    <>
                                        {this.state.selectedCoach.coachId.toString() === data.id.toString() &&
                                            <CoachOne
                                                coachId={this.state.selectedCoach.coachId}
                                                onClose={this.deselectCoach}
                                                subcategory={this.state.category}
                                            />
                                        }
                                    </>
                                }
                                <OpenCoach onClick={() => {return this.selectCoach(data)}}>
                                    <FooterCoach
                                        backgroundColor={this.props.backgroundColor}
                                        index={index}
                                        isOpen={this.state.selectedCoach === null ? false : this.state.selectedCoach.coachId.toString() === data.id.toString()}
                                    />
                                </OpenCoach>
                            </ContainerCoach>
                        ))}
                        {this.state.coachesShow.length === 0 &&
                            <FitContent>
                                <FontBody24>No coaches available</FontBody24>
                                <br />
                                <FitContent onClick={() => {this.props.history.push('/my-team/find')}}>
                                    <ButtonSecondary canSubmit={false} label={"Choose another journey"} />
                                </FitContent>
                            </FitContent>
                        }
                    </CareTeamInfo>
                </PageFull>
            )
        }
    }

}

const mapStateToProps = state => ({
    category: state.consumer.care.coaching.get("coachingOptionSelected"),
    subcategory: state.consumer.care.coaching.get("coachingSubCategorySelected"),
    coaches: state.consumer.care.coaching.get("coaches"),
    members: state.consumer.team.team.get("members"),
    wmcId: state.common.user.get("prospectWmcId"),
});

const mapDispatchToProps = dispatch => ({
    tryGetAllTeamMembers: () => dispatch(tryGetAllTeamMembers()),
    tryGetAllCoachesFromCategory: (ids) => dispatch(tryGetAllCoachesFromCategory(ids)),
    tryGetExpertProfileFromToken: (id) => dispatch(tryGetExpertProfileFromToken(id)),
    trackViewGetCareCoachProfile: (coachDetails) => dispatch(trackViewGetCareCoachProfile(coachDetails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CoachingCategoryOne));
