import {fromJS, Map, List} from 'immutable';

/* Immutable */
const CARE_INSIGHTS_PARENT_CATEGORIES_FETCHING = 'CARE_INSIGHTS_PARENT_CATEGORIES_FETCHING';
const CARE_INSIGHTS_PARENT_CATEGORIES = 'CARE_INSIGHTS_PARENT_CATEGORIES';
const CARE_INSIGHTS_PARENT_CATEGORY_SELECTED = 'CARE_INSIGHTS_PARENT_CATEGORY_SELECTED';
const CARE_INSIGHTS_SUB_CATEGORIES_FETCHING = 'CARE_INSIGHTS_SUB_CATEGORIES_FETCHING';
const CARE_INSIGHTS_SUB_CATEGORIES = 'CARE_INSIGHTS_SUB_CATEGORIES';
const CARE_INSIGHTS_SUB_CATEGORY_SELECTED = 'CARE_INSIGHTS_SUB_CATEGORY_SELECTED';
const CARE_INSIGHTS_FETCHING = 'CARE_INSIGHTS_FETCHING';
const CARE_INSIGHTS = 'CARE_INSIGHTS';
const CARE_INSIGHTS_SEARCHING = 'CARE_INSIGHTS_SEARCHING';
const CARE_INSIGHTS_SEARCH_RESULTS = 'CARE_INSIGHTS_SEARCH_RESULTS';
const CARE_INSIGHTS_SEARCH_TERM = 'CARE_INSIGHTS_SEARCH_TERM';
const CARE_INSIGHTS_STORE_RESET = 'CARE_INSIGHTS_STORE_RESET';

/* Actions */
export const setInsightParentCategoriesFetching = (status) => ({'type': CARE_INSIGHTS_PARENT_CATEGORIES_FETCHING, 'status': status});
export const setInsightParentCategories = (categories) => ({'type': CARE_INSIGHTS_PARENT_CATEGORIES, 'categories': categories});
export const insightParentCategorySelected = (category) => ({'type': CARE_INSIGHTS_PARENT_CATEGORY_SELECTED, 'category': category});
export const setInsightSubCategoriesFetching = (status) => ({'type': CARE_INSIGHTS_SUB_CATEGORIES_FETCHING, 'status': status});
export const setInsightSubCategories = (categories) => ({'type': CARE_INSIGHTS_SUB_CATEGORIES, 'categories': categories});
export const insightSubCategorySelected = (category) => ({'type': CARE_INSIGHTS_SUB_CATEGORY_SELECTED, 'category': category});
export const setInsightsFetching = (status) => ({'type': CARE_INSIGHTS_FETCHING, 'status': status});
export const setInsights = (insights) => ({'type': CARE_INSIGHTS, 'insights': insights});
export const setInsightsSearching = (status) => ({'type': CARE_INSIGHTS_SEARCHING, 'status': status});
export const setInsightsSearchResults = (results) => ({'type': CARE_INSIGHTS_SEARCH_RESULTS, 'results': results});
export const setInsightsSearchTerm = (term) => ({'type': CARE_INSIGHTS_SEARCH_TERM, 'term': term});
export const setCareStoreReset = () => ({'type': CARE_INSIGHTS_STORE_RESET});

/* Initial State */
const initialState = Map({
    insightParentCategoriesFetching: false,
    insightParentCategories: List(),
    insightParentCategorySelected: Map(),
    insightSubCategoriesFetching: false,
    insightSubCategories: List(),
    insightSubCategorySelected: Map(),
    insightsFetching: false,
    insights: List(),
    insightsSearching: false,
    insightsSearchResults: List(),
    insightsSearchTerm: null
});

/* Reducer */

const insightsReducer = (state=initialState, action) => {
    switch (action.type) {
        case CARE_INSIGHTS_PARENT_CATEGORIES_FETCHING:
            return state.merge({'insightParentCategoriesFetching': action.status});
        case CARE_INSIGHTS_PARENT_CATEGORIES:
            return state.merge({'insightParentCategories': fromJS(action.categories)});
        case CARE_INSIGHTS_PARENT_CATEGORY_SELECTED:
            return state.merge({'insightParentCategorySelected': fromJS(action.category)});
        case CARE_INSIGHTS_SUB_CATEGORIES_FETCHING:
            return state.merge({'insightSubCategoriesFetching': action.status});
        case CARE_INSIGHTS_SUB_CATEGORIES:
            return state.merge({'insightSubCategories': fromJS(action.categories)});
        case CARE_INSIGHTS_SUB_CATEGORY_SELECTED:
            return state.merge({'insightSubCategorySelected': fromJS(action.category)});
        case CARE_INSIGHTS_FETCHING:
            return state.merge({'insightsFetching': action.status});
        case CARE_INSIGHTS:
            return state.merge({'insights': fromJS(action.insights)});
        case CARE_INSIGHTS_SEARCHING:
            return state.merge({'insightsSearching': action.status});
        case CARE_INSIGHTS_SEARCH_RESULTS:
            return state.merge({'insightsSearchResults': fromJS(action.results)}); 
        case CARE_INSIGHTS_SEARCH_TERM:
            return state.merge({'insightsSearchTerm': action.term}); 
        case CARE_INSIGHTS_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default insightsReducer;
