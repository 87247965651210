import { Map, List, fromJS } from 'immutable';

/* Immutable */

const RATING_ERROR = 'RATING_ERROR';
const RATING_NOT_RATED_CONSUMERS = 'RATING_NOT_RATED_CONSUMERS';
const RATING_DOWNLOAD = 'RATING_CALL_DOWNLOAD';
const RATING_STORE_RESET = 'RATING_CALL_STORE_RESET';

/* Actions */

export const setRateError = () => ({'type': RATING_ERROR});
export const setNotRatedClients = (ratings) => ({'type': RATING_NOT_RATED_CONSUMERS, 'ratings': ratings});
export const setRatingDownload = (status) => ({'type': RATING_DOWNLOAD, 'status': status});
export const setRatingStoreReset = () => ({'type': RATING_STORE_RESET});

/* Initial State */

const initialState = Map({
    rating: Map({
        token: null,
        id: null,
        name: null,
        datetime: null
    }),
    ratingNotRatedClients: List(),
    ratingDownloading: false,
});

/* Reducer */

const ratingReducer = (state=initialState, action) => {
    switch (action.type) {
        case RATING_ERROR:
            return state.merge({
                "rating": Map({
                    "token": null,
                    "id": null,
                    "name": null,
                    "datetime": null
                })
            });
        case RATING_NOT_RATED_CONSUMERS:
            return state.merge({"ratingNotRatedClients": fromJS(action.ratings)});
        case RATING_DOWNLOAD:
            return state.merge({'ratingDownloading': action.status});
        case RATING_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default ratingReducer;