import {images} from '../../../common/components/images';

export const clientAcquisition = (price) => ({
    "id": "client_acquisition",
    "productId": "client_acquisition",
    "orderIndex": 0,
    "description": "",
    "numberOfSessions": 0,
    "amount": price*100,
    "isPrivate": false,
    "planType": "one_time",
    "recurringInterval": null,
    "subscriptionDurationInMonths": null,
    "status": "Active",
    "product": {
        "id": "client_acquisition",
        "title": "Client Acqusition",
        "description": "1 year",
        "coverPhoto": images.employersIcon,
        "serviceType": "online",
        "termsOfUseUrl": "https://www.trustwillow.com/terms-of-use/",
        "privacyPolicyUrl": "https://www.trustwillow.com/privacy-policy/",
        "downloadUrl": "",
        "sessionLength": 60,
        "status": "Active",
        "expert": {
            "id": null,
            "fullName": "Willow",
            "firstName": "Willow",
            "lastName": "",
            "storeHandle": "",
            "publicHandle": "",
            "profilePhotoUrl": ""
        }
    }
});
