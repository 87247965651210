import React from 'react';
import {connect} from 'react-redux';
import ReactQuill from "react-quill";

/* Components */
import {Card, Quote, QuoteEnd, Name, Container, Title, Body, Gray, NameText} from "../components/testimonials";
import {FontTitle30} from "../../../common/components/fonts";
import {QuillSlimNoHeight} from "../../../common/components/richtexteditor";
import {colors} from "../../../common/components/colors";
import {LogoLoading} from "../../../common/components/loading";
import {Message} from "../../../common/components/messages";

class IframeTestimonials extends React.Component {
    state={"isLoading": true, "profile": {}, "quillComponent": ""};

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.profile !== this.props.profile) {
            return this.init();
        }
    }

    init = async () => {
        await this.setState({"profile": this.props.profile});
        await this.initDescription();
        this.setState({"isLoading": false});
    };

    initDescription = async () => {
        await this.setState({"quillComponent": ""});
        this.setState({
            "quillComponent": [this.state.profile.get("testimonials").map((e, index) => (
                <Card key={index}>
                    <Quote>&ldquo;</Quote>
                    <Body>
                        <ReactQuill
                            readOnly={true}
                            theme={null}
                            value={e.get("body")}
                            bounds={'.app'}
                        />
                    </Body>
                    <QuoteEnd>&bdquo;</QuoteEnd>
                    <Name><Gray>&ndash;</Gray>&nbsp;<NameText>{e.get("person")}</NameText></Name>
                </Card>
            ))]
        })
    };

    render() {
        let profile = this.state.profile;
        if(this.state.isLoading) {
            return(<LogoLoading />)
        } else {
            if(profile.get("testimonials").size === 0) {
                return (<Message text={"No Customer Stories"}/>)
            } else {
                return(
                    <>
                        <Title><FontTitle30>Customer Stories!</FontTitle30></Title>
                        <Container>
                            {this.state.quillComponent}
                        </Container>
                    </>
                )
            }
        }
    }
}

const mapStateToProps = state => ({
    profile: state.iframe.shop.profile.get("profile"),
});

export default connect(mapStateToProps, null)(IframeTestimonials);
