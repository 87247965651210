import React from 'react';
import styled, { ThemeConsumer } from "styled-components";
import ReactQuill from "react-quill";
import {v4 as uuid4} from "uuid";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

/* Components */
import {colors} from "../../../common/components/colors";
import {editorFormatsLimited, editorModulesLimited} from "../../../common/components/richtexteditor";

export const Body = styled.div`
    background-color: ${colors.white};
    margin-bottom: 10px;
    & > div {
        ${props => props.disabled !== true} {
            border: 2px solid ${colors.white} !important;
        }
        ${props => props.disabled !== false} {
            ${props => props.focused !== true} {
                border: 2px solid ${colors.border100} !important;
            }
            padding: 5px;
        }
    }
    .ql-editor {
        padding: 0px !important;
        font-family: ${env.STYLES.FONTS.body.fontFamily};
        font-size: 18px;
        line-height: 27px;
    }
    .ql-toolbar {
        ${props => props.focused !== true} {
            border-bottom: 3px solid ${colors.border100} !important;
        }
        ${props => props.focused !== false} {
            display: none;
        }
        border-top: 0px solid transparent !important;
        border-left: 0px solid transparent !important;
        border-right: 0px solid transparent !important;
    }
    .ql-container {
        margin-top: 10px;
        border: 0px solid transparent !important;
    }
    div a {
        color: ${colors.action100};
    }
`;

class ElementBodyEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            "text": "",
            "focus": false,
            "disabled": true,
            "isLoading": true
        }
    }

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.elementId !== this.props.elementId) {
            return this.init();
        }
    }

    init = async () => {
        await this.setState({"disabled": true})
        await this.setState({
            "text": this.props.text
        })
        await this.setState({"focus": false, "disabled": false})

    }


    onChange = async e => {
        this.setState({
            "text": e
        })
    }

    save = async () => {
        let isValid = true;
        if (!this.props.disabled) {
            this.props.onSaveElement(this.props.elementId, {"text": this.state.text}, isValid)
        }
        
    }

    render() {
        return (
            <Body 
                style={this.props.style} 
                focused={this.state.focus && !this.state.disabled && !this.props.disabled} 
                disabled={this.props.disabled || this.state.disabled} 
                autoFocus={false}
                onBlur={() => {this.save();}}
                onClick={() => {return this.setState({"focus": true})}}
            >
                <ReactQuill
                    id={uuid4()}
                    readOnly={this.props.disabled}
                    theme={this.props.disabled ? null : "snow"}
                    value={this.state.text}
                    bounds={'.app'}
                    onChange={this.onChange}
                    modules={this.props.disabled ? {} : editorModulesLimited}
                    onFocus={() => {return this.setState({"focus": true})}}
                    onBlur={() => {this.save(); return this.setState({'focus': false})}}
                    placeholder={"Enter text here"}
                    autoFocus={false}
                />
            </Body>
        )
    }
}

export default ElementBodyEdit;