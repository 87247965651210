import { Map, List, fromJS } from 'immutable';

/* Immutable */

const CLIENTS_CURRICULUM = 'CLIENTS_CURRICULUM';
const CLIENTS_CURRICULUM_DOWNLOAD = 'CLIENTS_CURRICULUM_DOWNLOAD';
const CLIENTS_CURRICULUM_TASKS_DATA = 'CLIENTS_CURRICULUM_TASKS_DATA';
const CLIENTS_CURRICULUM_TASKS_DATA_START_PAGE = 'CLIENTS_CURRICULUM_TASKS_DATA_START_PAGE';
const CLIENTS_CURRICULUM_TASKS_DATA_FAKE_STARTED = 'CLIENTS_CURRICULUM_TASKS_DATA_FAKE_STARTED';
const CLIENTS_CURRICULUM_STORE_RESET = 'CLIENTS_CURRICULUM_STORE_RESET';

/* Actions */

export const setClientsCurriculum = (curriculum) => ({'type': CLIENTS_CURRICULUM, 'curriculum': curriculum});
export const setClientsCurriculumDownload = (status) => ({'type': CLIENTS_CURRICULUM_DOWNLOAD, 'status': status});
export const setClientsCurriculumTasksData = (data) => ({'type': CLIENTS_CURRICULUM_TASKS_DATA, 'data': data});
export const setClientsCurriculumTasksDataStartPage = () => ({'type': CLIENTS_CURRICULUM_TASKS_DATA_START_PAGE});
export const setClientsCurriculumTasksDataFakeStarted = (task) => ({'type': CLIENTS_CURRICULUM_TASKS_DATA_FAKE_STARTED, 'task': task});
export const setClientsCurriculumStoreReset = () => ({'type': CLIENTS_CURRICULUM_STORE_RESET});

/* Initial State */

const initialState = Map({
    clientCurriculum: List(),
    clientCurriculumTasksData: Map(),
    clientCurriculumDownloading: false,
});

/* Reducer */

const clientsCurriculumReducer = (state=initialState, action) => {
    switch (action.type) {
        case CLIENTS_CURRICULUM:
            return state.merge({"clientCurriculum": fromJS(action.curriculum)});
        case CLIENTS_CURRICULUM_DOWNLOAD:
            return state.merge({'clientCurriculumDownloading': action.status});
        case CLIENTS_CURRICULUM_TASKS_DATA:
            return state.merge({"clientCurriculumTasksData": fromJS(action.data)});
        case CLIENTS_CURRICULUM_TASKS_DATA_FAKE_STARTED:
            return state.merge({
                "clientCurriculumTasksData": fromJS({
                    ...state.get("clientCurriculumTasksData").toJS(),
                    "userTaskAnswer": action.task
                })
            });
        case CLIENTS_CURRICULUM_TASKS_DATA_START_PAGE:
            return state.merge({
                "clientCurriculumTasksData": fromJS({
                    ...state.get("clientCurriculumTasksData").toJS(),
                    "userTaskStatus": {
                        ...state.get("clientCurriculumTasksData").get("userTaskStatus").toJS(),
                        "page": 1
                    }
                })
            });
        case CLIENTS_CURRICULUM_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default clientsCurriculumReducer;
