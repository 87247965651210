import React from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

/* Components */
import {colors} from '../../../common/components/colors';
import {LogoLoading, LogoLoadingSmall} from "../../../common/components/loading";
import {FontTitle18, FontHeader16, FontHeader21} from "../../../common/components/fonts";
import {Card, Icon, Body, Title, Action, Inline, ActionLink, BottomBorder5, Content} from '../components/affirmations';
import {images} from "../../../common/components/images";
import {FitWidthButton} from "../../components/clientsdetail";
import {ButtonPrimary, ButtonSecondary} from "../../../common/components/buttons";

/* Components */
const Section = styled.div`
    padding: 20px;
    background-color: ${colors.border30};
    max-height: 100%;
    @media screen and (max-width: 460px) {
        padding: 5px;
    }
`;

export const InlineAction = styled.div`
    display: inline-block;
    vertical-align: top;
`;

export const DateItem = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-top: 3px;
    text-align: left;
    width: 150px;
    @media screen and (max-width: 1440px) {
        margin-top: 8px;
    }
    @media screen and (max-width: 1088px) {
        margin-top: 3px;
    }
`;

export const Logo = styled.div`
    text-align: left;
    & img {
        width: 23.98px;
        height: auto;
    }
`;

export const Center = styled.div`
    text-align: center;
    width: calc(100% - 30px);
`;

const Text = styled.div`
    color: ${colors.action100};
    cursor: pointer;
    margin-bottom: 5px;
    &:hover {
        text-decoration: underline;
    }
`;

const Image = styled.img`
    height: 300px;
    object-fit: cover;
    width: calc(100% - 30px);
    @media screen and (max-width: 1088px) {
        height: 100px;
    }
`;

export const BlogButton = styled.div`
    & div {
        width: calc(100% - 75px);
    }
    & div div {
        width: 100%;
        text-align: center;
    }
`;


class WillowBlog extends React.Component {
    state={
        "isLoading": true,
        // "posts": [{'title': '7 Lessons COVID-19 Taught Us About Helping Our Widowed Friends and Planning for Our Own Futures', 'image': "https://www.trustwillow.com/wp-content/uploads/2021/06/7-Lessons-COVID-19-Taught-Us-about-Helping-Our-Widowed-Friends-and-Planning-for-Our-Own-Futures-3-1024x641.png", "link": 'https://trustwillow.com'}]
        "posts": []
    };

    intervalID = 0;

    componentDidMount() {
        return this.init()
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    init = async () => {
        await fetch('https://cors-truwstillow.herokuapp.com/https://trustwillow.com/feed')
            .then(response => response.text())
            .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
            .then(data => {
                const items = data.querySelectorAll("item");
                let list = []
                items.forEach(el => {
                    const linkStart = el.querySelector("description").innerHTML.search('http://www.trustwillow.com/wp-content/uploads') !== -1 ? el.querySelector("description").innerHTML.search('http://www.trustwillow.com/wp-content/uploads') : el.querySelector("description").innerHTML.search('https://www.trustwillow.com/wp-content/uploads')
                    const linkEnd = el.querySelector("description").innerHTML.search('.png') === (-1) ? el.querySelector("description").innerHTML.search('.jpg') + 4 : el.querySelector("description").innerHTML.search('.png') + 4
                    const link = linkStart !== (-1) && linkEnd !== (-1) ? el.querySelector("description").innerHTML.substring(linkStart, linkEnd).replace("&#215;", "x") : ""
                    list.push({'title': el.querySelector("title").innerHTML, 'link': el.querySelector("link").innerHTML, 'image': link})
                });
                this.setState({"posts": list})
            })
            .catch(error => console.error('Error: ', error))
        await this.setState({"isLoading": false})
    };

    openPost = async (link) => {
        window.open(link, "_blank");
    };

    openBlog = async () => {
        window.open('https://www.trustwillow.com/resources-center/', "_blank");
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <Section>
                    <Card>
                        <Icon><Logo><img src={images.logoIcon} /></Logo></Icon>
                            <Body>
                                <div>
                                    <Title><FontTitle18>Latest from Willow</FontTitle18></Title>
                                </div>
                            <Content>
                                <BottomBorder5>
                                    <FontHeader16>{""}</FontHeader16>
                                </BottomBorder5>
                                {this.state.posts.length === 0 ?
                                    <>
                                        <br />
                                        <Center><FontHeader16>{"Oops, posts aren't loading right now. Try again later."}</FontHeader16></Center>
                                    </>
                                    :
                                    <>
                                        {this.state.posts.map((p, i) => {
                                            if (i < 2) {
                                                return (
                                                    <Center key={i}>
                                                        <br />
                                                        <Text onClick={() => {return this.openPost(p.link)}}><FontHeader16>{p.title}</FontHeader16></Text>
                                                        <Image src={p.image} />
                                                    </Center>
                                                )
                                            } else {
                                                return null
                                            }
                                        })}
                                    </>
                                }
                                <br />
                                <BlogButton onClick={() => {return this.openBlog()}}><ButtonPrimary canSubmit={false} label={"Visit the blog"} /></BlogButton>
                            </Content>
                            </Body>
                        </Card>
                </Section>
            )
        }
    }
}

const mapStateToProps = state => ({
    isWMC: state.common.wmc.get("isWMC"),
    user: state.common.user
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WillowBlog));