import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from "styled-components";

/* Component */
import {FontSubtitle28, FontBody16} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";
import {images} from "../../../common/components/images";
import {ButtonPrimary} from "../../../common/components/buttons";

const PageTitle = styled(FontSubtitle28)`
    text-align: center;
    margin-bottom: 30px;
`;
const PageSubtitle = styled(FontBody16)`
    text-align: center;
    color: ${colors.secondary100};
`;
const Center = styled.div`
    margin-top: 60px;
    text-align: center;
    color: ${colors.primary100};
`;
const SummaryIcon = styled.img`
    width: auto;
    margin: 60px auto 90px auto;
    height: 260px;
`;
const FitContent = styled.div`
    width: fit-content;
    cursor: pointer;
    margin: 0px auto 25px auto;
    & > div {
        min-width: 87px;
    }
`;

const formStyleSystem = {
    pageTitle: "PASSWORD SET",
    pageIntroText: "Login with your new password",
    submitLabel: "Login"
};

const pageStyleSystem = (employerLogin) => ({
    pageTitle: employerLogin ? "WELCOME TO WILLOW!" : "PASSWORD SET",
    pageIntroText: employerLogin ? "Your account is now ready" : "Your password has been changed",
    submitLabel: employerLogin ? "View dashboard" : "View dashboard"
});

class Confirm extends React.Component {
    static defaultProps = {
        styleSystem: "form",
        employerLogin: false,
        onSuccess: () => {}
    };

    state=formStyleSystem;

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState(this.props.styleSystem === "page" ? pageStyleSystem(this.props.employerLogin) : formStyleSystem)
    };

    render() {
        return (
            <Center>
                <PageTitle>{this.state.pageTitle}</PageTitle>
                <PageSubtitle>{this.state.pageIntroText}</PageSubtitle>
                <SummaryIcon src={images.completeIcon}/>
                <FitContent onClick={this.props.onSuccess}>
                    <ButtonPrimary submit={false} label={this.state.submitLabel} />
                </FitContent>
            </Center>
        )
    }
}

export default withRouter(Confirm);