import React  from "react";
import styled from "styled-components";
import getDomainEnv from '../../domains/utils';
const env = getDomainEnv();
/* Components */
import {colors} from "./colors";

const InputLabel = styled.div`
    color: ${colors.secondary100};
    display: inline-block;
`;

const CheckboxLabel = styled.label`
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 25px;
    width: 25px;
    &:hover input ~ span {
        background-color: ${colors.primary30};
    }
    & input:checked ~ span {
        background-color: ${colors.action100};
    }
    & input:checked ~ span:after {
        display: block;
    }
`;

const CheckboxInput = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`;

const EachCheckboxLabel = styled.div`
  font-family: ${env.STYLES.FONTS.body.fontFamily};

  display: inline-block;
    vertical-align: top;
    padding-left: 10px;
    padding-top: 2px;
    cursor: pointer;
    user-select: none;
`;

const Subtext = styled.div`
    margin-left: 37px;
    @media screen and (max-width: 640px) {
        margin-top: -15px;
    }
    user-select: none;
`;

const CheckboxMark = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: ${colors.border100};
    border: 1px solid ${colors.secondary70};
    border-radius: 4px;
    &:after {
        content: "";
        position: absolute;
        display: none;
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid ${colors.white};
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;

const Checkbox = ({ action, checked, id, label, subtext }) => (
    <>
        <InputLabel>
            <CheckboxLabel>
                <CheckboxInput id={id} type={"checkbox"} checked={checked} onChange={action} onClick={action} />
                <CheckboxMark />
            </CheckboxLabel>
        </InputLabel>
        <EachCheckboxLabel onClick={action}>{label}</EachCheckboxLabel>
        {(subtext !== undefined && subtext !== null && subtext !== "") && <Subtext>{subtext}</Subtext>}
    </>
);

export default Checkbox;