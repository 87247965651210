import React from 'react';
import styled from 'styled-components';

/* Components */
import {FontBody16, FontSubtitle28} from "../../../common/components/fonts";

const Intro = styled.div`
  width: calc(100% - 60px);
  padding: 0 30px; 
  display:flex;
  flex-direction:column;
  gap: 10px;
`;

export default function IFrameAdvisorDirectoryMatchIntro() {
    return (
        <Intro>
            <FontSubtitle28 spaced={true}>YOUR ADVISOR MATCHES</FontSubtitle28>
            <FontBody16>Please review each advisor’s profile before selecting your top choice. Once you select an advisor, a member of the Willow team will reach out to review your choice before giving your information to the advisor.</FontBody16>
        </Intro>
    );
};