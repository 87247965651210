import React from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';

/* Containers */
import ClientsActivity from '../containers/contactactivity';
import ClientsFinances from '../containers/contactfinances';
import ClientsNotes from '../containers/contactnotes';
import ClientsMeetings from '../containers/contactmeetings';
import ClientsMessages from '../containers/contactmessages';
import ClientsInstructions from '../containers/contactinstructions';
import ClientsVault from '../containers/contactvault';
import ContactActions from '../containers/contactactions';
import ContactInfo from '../containers/contactinfo';
import ContactRelationship from '../containers/contactrelationship';
import ContactCoaching from '../containers/contactcoaching';
import ContactNav, {ContactNavLinksOnly, /*ActivitySubBar*/} from "../containers/contactnav";
import ClientIntroduceModal from "../team/containers/clientintroducemodal";
import CoachingProducts from "../containers/coachingproducts";
import PromoteProspect from "../containers/promoteprospect";
import PromoteProspectModal from "../containers/promoteprospectmodal";
import CategoryChangeModal from "../partnerships/containers/coachcategorymodal";

/* Navs */
import {PageContent, PageFull, PageRight} from "../navbars/components/navlayout";
import NavPrimaryAll from "../navbars/containers/primaryall";
import {PageTabs, PageActions} from '../components/clientsdetail';

const ClientsDetail = ({ location, user, isWMC, contact }) => (
    <>
        <NavPrimaryAll />
        <PageFull>
            <PageRight>
                <PageTabs>
                    <ContactNav displayLargeResolution={true} />
                    <PageActions>
                        {user.get("isSupport") && <PromoteProspect />}
                        <ContactActions />
                        {!user.get("isSupport") && <ContactCoaching />}
                        {!isWMC && contact.get("referralSource") === "coach" && <CoachingProducts />}
                        {/*<ContactRelationship />*/}
                        {/*<ContactInfo />*/}
                    </PageActions>
                    <ContactNavLinksOnly displayLargeResolution={false} />
                    {/*{(location.pathname === "/contact/activity" || location.pathname === "/contact/meetings" ||*/}
                    {/*    location.pathname === "/contact/tasks" || location.pathname === "/contact/emails" ||*/}
                    {/*    location.pathname === "/contact/sms" || location.pathname === "/contact/chats") &&*/}
                    {/*    <ActivitySubBar page={location.pathname} />*/}
                    {/*}*/}
                    <PageContent>
                        {(location.pathname === "/contact/messages") && <ClientsMessages />}
                        {(location.pathname === "/contact/meetings") && <ClientsMeetings />}
                        {(location.pathname === "/contact/activity") && <ClientsActivity />}
                        {(location.pathname === "/contact/notes") && <ClientsNotes />}
                        {location.pathname === "/contact/vault" && <ClientsVault />}
                        {location.pathname === "/contact/finances" && <ClientsFinances />}
                        {location.pathname === "/contact/instructions" && <ClientsInstructions />}
                    </PageContent>
                </PageTabs>
                <ClientIntroduceModal />
                {user.get("isSupport") && <PromoteProspectModal />}
                {user.get("isSupport") && <CategoryChangeModal />}
            </PageRight>
        </PageFull>
    </>
);

const mapStateToProps = state => ({
    user: state.common.user,
    isWMC: state.common.wmc.get("isWMC"),
    client: state.enterprise.clientsDetail.get("client"),
    contact: state.enterprise.contacts.get("contactsOne"),
});

export default connect(mapStateToProps, null)(withRouter(ClientsDetail));
