import React from 'react';
import styled from "styled-components";

/* Components */
import {colors} from "../../../common/components/colors";

export const Page = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 5;
`;

export const Background = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.50);
    @media screen and (max-width: 460px) {
        background-color: ${colors.white};
    }
    z-index: 6;
`;

export const Modal = styled.div`
    -webkit-box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    background-color: ${colors.white};
    border-radius: 4px;
    padding: 30px;
    color: ${colors.primary100};
    max-width: 658px;
    z-index: 7;
    position: relative;
    margin: 230px auto 0px auto;
    @media screen and (max-width: 748px) {
        margin: 130px 0px 0px 0px;
    }
    @media screen and (max-width: 460px) {
        margin: 20px 0px;
        padding: 10px;
    }
`;

export const ModalDelete = styled.div`
    -webkit-box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    background-color: ${colors.white};
    border-radius: 4px;
    padding: 30px;
    color: ${colors.primary100};
    max-width: 300px;
    z-index: 7;
    position: relative;
    margin: 230px auto 0px auto;
    @media screen and (max-width: 460px) {
        padding: 20px;
        max-width: none;
        margin: auto;
        top: 50%;
        transform: translateY(-50%);
    }
`;


export const User = styled.div`
    color: ${colors.primary30};
`;

export const Title = styled.div`
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.border100};
    color: ${colors.secondary100};
    margin-bottom: 20px;
`;

export const Actions = styled.div`
    padding-top: 30px;
    display: flex;
    justify-content: space-around;
`;

export const ActionLeft = styled.div`
    display: inline-block;
    cursor: pointer;
    width: fit-content;
`;

export const ActionRight = styled.div`
    display: inline-block;
    width: calc(100% - 85px);
    text-align: right;
`;

export const ActionCancelPlan = styled.div`
    cursor: pointer;
    width: fit-content;
    margin: 0px 0px 0px auto;
`;

export const Label = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 4px 0px;
    width: 65px;
    color: ${props => props.color};
    text-align:left
`;

export const Center = styled.div`
    text-align: center; 
`;

export const SpacingBar = styled.div`
    border-top: 1px solid ${colors.primary30}; 
`;

export const CancelButton = styled.div`
    text-align: center;
    margin: 0px auto;
    width: fit-content;
    cursor: pointer;
    width: fit-content;
`;