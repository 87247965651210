import React from "react";
import styled from "styled-components";

/* Components */
import {colors} from "../../../common/components/colors";
import {ButtonInactive, ButtonPrimary} from "../../../common/components/buttons";

export const Width = styled.div`
    max-width: 536px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
`;

export const InlineBlock = styled.div`
    display: inline-block;
`;

export const SaveSection = styled.div`
    border-top: 1px solid ${colors.border100};
    text-align: left;
    padding-top: 20px;
    margin-top: 50px;
`;

const ButtonRight = styled.div`
    margin-right: 10px;
`;

export const SubmitInputWithValidation = ({ isChanged, isValid, isSaving, label }) => {
    if(isChanged && isValid) {
        if(isSaving) {
            return (<ButtonRight><ButtonInactive canSubmit={true} label={"Saving"} /></ButtonRight>)
        } else {
            return (<ButtonRight><ButtonPrimary canSubmit={true} label={label}/></ButtonRight>)
        }
    } else {
        return(<ButtonRight><ButtonInactive canSubmit={true} label={label}/></ButtonRight>)
    }
};