import React from "react";
import {Map} from "immutable";

/* Components */
import {ActionBox, EditIcon, FitWidthButton, InlineItemLeft, InlineItemRight} from "../components/clientsdetail";
import {FontBody14, FontHeader21, FontTitle18, FontBody16} from "../../common/components/fonts";
import {EditOutlined, PlusOutlined} from "@ant-design/icons";
import {ButtonLink, ButtonPrimary} from "../../common/components/buttons";
import {Center, Photo, Logo, Advertisement, HideCoach, Border} from '../components/contactcoaching';

/* Containers */
import {ChangeSessions} from "./contactcoachingchangesessions";

/* Static */
import {images} from "../../common/components/images";
import styled from "styled-components";
import {colors} from "../../common/components/colors";



export const NoCoachForWMCContact = () => (
    <ActionBox>
        <div>
            <InlineItemLeft><FontTitle18>Assigned Team</FontTitle18></InlineItemLeft>
        </div>
        <Logo><img src={images.logoIcon} alt={"Willow"} /></Logo>
        <Advertisement><FontBody14>To introduce team members, change your contact's relationship to prospect or client.</FontBody14></Advertisement>
    </ActionBox>
);

export const NoCoachAssignedToWMC = ({addCoachToWMC, prospectStatus}) => (
    <ActionBox>
        <div>
            <InlineItemLeft><FontTitle18>Assigned Team</FontTitle18></InlineItemLeft>
            <InlineItemRight><EditIcon onClick={() => addCoachToWMC()}><PlusOutlined /></EditIcon></InlineItemRight>
        </div>
        <Logo><img src={images.logoIcon} alt={"Willow"} /></Logo>
        <Advertisement><FontBody14>{"Learn how a team can help your "+prospectStatus.toLowerCase()}</FontBody14></Advertisement>
        <FitWidthButton onClick={() => {addCoachToWMC()}}><ButtonPrimary canSubmit={false} label={"Build My Team"} /></FitWidthButton>
    </ActionBox>
);



export class NoCoachAssignedToProspect extends React.Component {
    state={"showAll": false, "allCoaches": []};

    componentDidMount() {
        this.setState({"allCoaches": this.props.allCoaches});
    }

    addCoach = async (coachId, count, coachName) => {
        await this.props.addCoachToProspect(coachId, count, coachName);
        const allCoaches = await this.state.allCoaches.map(a => {
            if(a.get("coachId") === coachId) {
                return (Map({...a.toJS(), "assigned": true, "remainingSessions": count}))
            } else {
                return (a)
            }
        });
        this.setState({"allCoaches": allCoaches});
    };

    update = async (coachId, count, coachName) => {
        await this.props.updateSessions(coachId, count);
        const allCoaches = await this.state.allCoaches.map(a => {
            if(a.get("coachId") === coachId) {
                return (Map({...a.toJS(), "remainingSessions": (a.get("remainingSessions") + count)}))
            } else {
                return (a)
            }
        });
        this.setState({"allCoaches": allCoaches});
    };

    addCoachToClient = async () => {
        await this.props.setModal();
    }

    render() {
        return(
            <ActionBox>
                <div>
                    <InlineItemLeft><FontTitle18>Assigned Team</FontTitle18></InlineItemLeft>
                    <InlineItemRight><EditIcon onClick={() => {return this.props.settings()}}><EditOutlined /></EditIcon></InlineItemRight>
                </div>
                <Logo><img src={images.logoIcon} alt={"Willow"} /></Logo>
                <Advertisement><FontBody14>{"Assign members of your team to support your "+this.props.prospectStatus.toLowerCase()}</FontBody14></Advertisement>
                <FitWidthButton onClick={() => {return(this.addCoachToClient())}}><ButtonPrimary canSubmit={false} label={"Assign Team Member"} /></FitWidthButton>
            </ActionBox>
        )
    }
}



const IconChar = styled.div`
    display: inline-block;
    vertical-align: top;
    font-family: CooperLight;
    font-size: 24px;
    height: 26px;
`;

const Spacer = styled.div`
    margin-top: 10px;
    border-top: 1px solid ${colors.primary30};
`;

export const CoachAssignedToWMCAndProspect = ({settings, updateSessions, coachesAssigned, coachesAll, addCoachToClient}) => (
    <ActionBox>
        <div>
            <InlineItemLeft><FontTitle18>Assigned Team</FontTitle18></InlineItemLeft>
            <InlineItemRight><EditIcon onClick={() => {settings()}}><EditOutlined /></EditIcon></InlineItemRight>
        </div>
        {coachesAssigned.map((c, index) => (
            <div key={index}>
                <Photo><img src={c.get("photo")} alt={""} /></Photo>
                <Center><FontHeader21>{c.get("first")+" "+c.get("last")}</FontHeader21></Center>
                {c.get("remainingSessions") > 1000
                    ?
                        <Center><FontBody16><br /><><IconChar active={false}>&#x221E;</IconChar>&nbsp;Infinite Sessions</></FontBody16></Center>
                    :
                        <ChangeSessions
                            initialCount={c.get("remainingSessions")}
                            expertId={c.get("coachId")}
                            expertName={c.get("first")+" "+c.get("last")}
                            notifyFirstName={c.get("first")}
                            updateSessions={updateSessions}
                            subTitle={"Sessions Remaining"}
                            useSessionDelta={true}
                            showNoticeCoach={true}
                            closeAction={() => {}}
                            saveTimer={3000}
                            alwaysShowSave={false}
                            saveButtonLabel={"Save"}
                            saveButtonFullWidth={false}
                        />
                }
                {(coachesAssigned.size > 1 && coachesAssigned.size !== (index+1)) &&
                    <Border />
                }

            </div>
        ))}
        <Border />
        <FitWidthButton onClick={() => {addCoachToClient()}}><ButtonPrimary canSubmit={false} label={"Assign Team Member"} /></FitWidthButton>

    </ActionBox>
);