import styled from "styled-components";
import {colors} from "../../../common/components/colors";

export const Specialty = styled.div`
    display: inline-block;
    margin-right: 5px;
    vertical-align: top;
    padding: 5px 15px;
    border-radius: 20px;
    background-color: ${colors.border100};
    margin-bottom: 10px;
`;
export const Icon14 = styled.div`
    display: inline-block;
    vertical-align: top;
    & svg {
        width: 14px;
        height: 14px;
        margin-top: 4px;
    }
`;