import React from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import moment from "moment";
import styled from "styled-components";

/* Components */
import {FontTitle18} from '../../../common/components/fonts';
import {AudioFilled} from '@ant-design/icons';
import {Center, CardPage, Body, Icon, Title} from "../components/contactactivity";
import {MeetingNoActionsLarge, MeetingActionJoinLarge, ModalConfirmation, ModalSaved, MeetingLargeActionOptions} from '../components/contactactivitymeeting';
import {LogoLoading} from "../../../common/components/loading";
import {NavTertiaryNoLinks} from "../components/navtertiary";

/* Containers */
import IframeMeetingPageStructure from '../../../iframes/webinars/containers/structure';
import MeetingLinkEmbed from './linkorembed';
import MeetingParticipants from './participants';

/* Middleware */
import {tryEditSessionLarge} from "../middleware/group";

const Background = styled.div`
    padding: 20px;
    min-height: calc(100vh - 320px);
    max-height: 100%;
    @media screen and (max-width: 460px) {
        padding: 15px 5px;
    }
`;

export class MeetingsInfoPage extends React.Component {
    state = {linkOrEmbedModal: false, isLoading: true, data: {}, status: null, cancel: false, isSaved: false, participantsModal: false};

    componentDidMount() {
        return this.init();
    }
   
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.groupSessionOne !== this.props.groupSessionOne) {
            return this.init();
        }
    }

    init = async () => {
        await this.setState({"data": this.props.groupSessionOne});
        const start = this.state.data.get("start");
        const end = this.state.data.get("end");
        if (moment().isBefore(moment(start))) {
            await this.setState({"status": "Upcoming"})
        } else if (moment().isAfter(moment(start)) && moment().isBefore(moment(end))) {
            await this.setState({"status": "Ongoing"})
        } else if (moment().isAfter(moment(end))) {
            await this.setState({"status": "Past"})
        }
        return this.setState({"isLoading": false});
    };

    cancelExecute = async () => {
        this.setState({"cancel": false});
        const request = {
            "id": this.state.data.get("id"),
            "title": this.state.data.get("title"),
            "description": this.state.data.get("description"),
            "flashSaleStartDateTime": this.state.data.get("flashSaleStartDateTime"),
            "flashSaleEndDateTime": this.state.data.get("flashSaleEndDateTime"),
            "coverPhoto": this.state.data.get("coverPhoto") === null ? "" : this.state.data.get("coverPhoto"),
            "announcementVideoUrl": this.state.data.get("announcementVideoUrl") === null ? "" : this.state.data.get("announcementVideoUrl"),
            "ticketCost": this.state.data.get("ticketCost"),
            "purchaseLimitNumber": this.state.data.get("purchaseLimitNumber"),
            "isOnlyForClients": this.state.data.get("isOnlyForClients"),
            "type": this.state.data.get("type"),
            "isActive": false
        };
        console.log(request);
        await this.props.tryEditSessionLarge(request);
        this.props.history.push("/meetings")
    };

    cancel = () => {this.setState({"cancel": true})};
    cancelClose = () => {this.setState({"cancel": false})};

    edit = () => {
        this.props.history.push("/meetings/edit/webinar");
    };

    linkOrEmbedModalOpen = () => {this.setState({"linkOrEmbedModal": true})};
    linkOrEmbedModalClose = () => {this.setState({"linkOrEmbedModal": false})};

    participantsModalOpen = () => {this.setState({"participantsModal": true})};
    participantsModalClose = () => {this.setState({"participantsModal": false})};

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <>
                    <NavTertiaryNoLinks title={"Meeting Page"} />
                    <Background>
                        <CardPage>
                            <Icon><AudioFilled /></Icon>
                            <Body>
                                <Title><FontTitle18>{this.state.data.get("type") === "group_session" ? "Group Program" : "Webinar"}</FontTitle18></Title>
                                {this.state.status === "Ongoing" &&
                                    <MeetingActionJoinLarge
                                        videoUrl={this.state.data.get("videoUrl")}
                                        start={this.state.data.get("start")}
                                        numParticipants={this.state.data.get("registrationCount")}
                                        numParticipantsMax={this.state.data.get("purchaseLimitNumber")}
                                        isActive={this.state.data.get("isActive")}
                                        isOnlyForClients={this.state.data.get("isOnlyForClients")}
                                        ticketCost={this.state.data.get("ticketCost")}
                                        linkOrEmbedModalOpen={this.linkOrEmbedModalOpen}
                                        participantsModalOpen={this.participantsModalOpen}
                                    />
                                }
                                {(this.state.status === "Upcoming") &&
                                    <MeetingLargeActionOptions
                                        reschedule={this.reschedule}
                                        cancel={this.cancel}
                                        edit={this.edit}
                                        start={this.state.data.get("start")}
                                        numParticipants={this.state.data.get("registrationCount")}
                                        numParticipantsMax={this.state.data.get("purchaseLimitNumber")}
                                        isActive={this.state.data.get("isActive")}
                                        isOnlyForClients={this.state.data.get("isOnlyForClients")}
                                        ticketCost={this.state.data.get("ticketCost")}
                                        status={this.state.status}
                                        linkOrEmbedModalOpen={this.linkOrEmbedModalOpen}
                                        participantsModalOpen={this.participantsModalOpen}
                                    />
                                }
                                {this.state.status === "Past" &&
                                    <MeetingNoActionsLarge
                                        start={this.state.data.get("start")}
                                        numParticipants={this.state.data.get("registrationCount")}
                                        numParticipantsMax={this.state.data.get("purchaseLimitNumber")}
                                        isActive={this.state.data.get("isActive")}
                                        isOnlyForClients={this.state.data.get("isOnlyForClients")}
                                        ticketCost={this.state.data.get("ticketCost")}
                                        linkOrEmbedModalOpen={this.linkOrEmbedModalOpen}
                                        participantsModalOpen={this.participantsModalOpen}
                                    />
                                }
                            </Body>
                        </CardPage>
                        <Center>
                            <IframeMeetingPageStructure
                                data={this.state.data}
                                isPast={this.state.status === "Past"}
                                isCanceled={!this.state.data.get("isActive")}
                                canRegister={false}
                                coachView={true}
                                handle={this.state.data.get("expert").get("publicHandle")}
                            />
                        </Center>
                    </Background>
                    {this.state.linkOrEmbedModal &&
                        <MeetingLinkEmbed
                            linkEmbed={this.linkOrEmbedModalClose}
                            linkEmbedId={this.state.data.get("id")}
                            linkEmbedTitle={this.state.data.get("title")}
                        />
                    }
                    {this.state.cancel &&
                        <ModalConfirmation
                            actionTitle={"cancel"}
                            leftAction={this.cancelClose}
                            rightAction={this.cancelExecute}
                            rightLabel={"Confirm"}
                            eventTitle={"webinar"}
                            within24Hours={moment().isAfter(moment(this.state.data.get("start")).subtract({hours: 24})) && moment().isBefore(moment(this.state.data.get("start")))}
                        />
                    }
                    {this.state.participantsModal &&
                        <MeetingParticipants
                            meetingId={this.state.data.get("id")}
                            onClose={this.participantsModalClose}
                        />
                    }
                    {this.state.isSaved && <ModalSaved />}
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    groupSessionOne: state.enterprise.meetings.group.get("groupSessionOne"),
});

const mapDispatchToProps = dispatch => ({
    tryEditSessionLarge: (request) => dispatch(tryEditSessionLarge(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MeetingsInfoPage));