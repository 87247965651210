/* Middleware - tryPostMessage */
import {v4 as uuid4} from "uuid";

let userId;
export const devMessagesAddData = (isAdvisor, content, subject, mediaType) => {
    if(isAdvisor) {userId = 24324} else {userId = 32432}
    return ({
        "id": uuid4(),
        "fromUserId": userId,
        "isFromAdvisor": isAdvisor,
        "isFromLily": !isAdvisor,
        "content": content,
        "formattedDatetime": "2020-05-16T16:00:00+00:00",
        "channel": mediaType,
        "subject": subject
    })
};

export const devMessagesDataConferenceId = {
    "pastCalls": [
        {
            "id": 1,
            "date": "2020-04-12T10:00:00+00:00",
            "length": "30 min"
        }
    ]
};

export const devMessagesDataPurchaseLink = {
    "purchaseToken": "123",
    "purchaseUrl": "https://google.com/123"
};