import React from "react";
import { withRouter } from 'react-router-dom';
import styled from "styled-components";

/* Containers */
import CoursesFlow from '../../iframes/courses/containers/flow';

/* Components */
import {LogoLoading} from "../../common/components/loading";

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

class LearnCourseEntry extends React.Component {
    state = {"isLoading": true};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "courseId": window.location.pathname.split('/')[3],
            "isLoading": false
        });
    };

    render() {
        if(this.state.isLoading) {
            return (<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return (
                <CoursesFlow
                    courseId={this.state.courseId}
                    backFromArticle={() => {this.props.history.goBack()}}
                    backFromVideo={() => {this.props.history.goBack()}}
                    backFromCourseSummary={() => {this.props.history.push("/learn")}}
                    backFromCourseModule={() => {this.props.history.push("/learn/course/"+this.state.courseId)}}
                    backFromCourseComplete={() => {this.props.history.push("/learn/course/"+this.state.courseId)}}
                    openCourseTask={(taskId) => {this.props.history.push("/learn/course/"+this.state.courseId+"/module/"+taskId)}}
                    openCourseComplete={() => {this.props.history.push("/learn/course/"+this.state.courseId+"/complete")}}
                    coursePathname={"/learn/course/"+this.state.courseId}
                    openMyCoaches={() => {this.props.history.push("/my-team")}}
                    openFindACoach={() => {this.props.history.push("/my-team/find")}}
                    openViewCoach={(coachId) => {this.props.history.push("/my-team/"+coachId)}}
                />
            )
        }
    }
}

export default withRouter(LearnCourseEntry);
