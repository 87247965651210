import React from 'react';
import validator from 'validator';
import {connect} from "react-redux";
import styled from 'styled-components';

/* Components */
import {PasswordInput, InputSection, InputLabel, TextInput, CheckboxWithLabel, LinkStyled} from "../../../common/components/inputs";
import {SavedSettings, SettingsCardTitle} from '../../../common/components/cards';
import {CheckCircleFilled, CheckCircleOutlined, CloseOutlined} from '@ant-design/icons';
import {FontBody14, FontBody16, FontTitle18} from "../../../common/components/fonts";
import {Validation, Icon, InlineBlock, SaveSection, SubmitInputWithValidation, Test} from '../components/changepassword';
import {ButtonInactive, ButtonPrimary, ButtonTertiary} from "../../../common/components/buttons";

/* Middleware */
import {tryChangePassword, trySetNewPassword} from '../middleware/changepassword';
import {colors} from "../../../common/components/colors";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

export const HalfAlign = styled.div`
    display: inline-block;
    vertical-align: top;
    ${props => props.side !== "left"} {
        width: 49%;
        text-align: left;
        margin-right: 2%;
    }
    ${props => props.side !== "right"} {
        width: 49%;
        text-align: right;
    }
    margin-bottom: -15px;
`;

const FitContent = styled.div`
    width: fit-content;
`;

const ButtonSection = styled.div`
    width: 100%;
    text-align: right;
`;

const OnlySubmit = styled.div`
    margin: 30px auto 0px auto;
    text-align: center;
    & button > div {
        min-width: 160px;
    }
`;

const GoldLink = styled.div`
    color: ${colors.action100};
    display: inline-block;
    vertical-align: top;
    text-decoration: underline;
    font-weight: bold;
`;

const formStyleSystem = {
    inputsPassword1Label: "Password",
    inputsPassword1Placeholder: "Enter new password",
    inputsPassword1Warning: "Required",
    inputsPassword2Label: "Confirm password",
    inputsPassword2Placeholder: "Confirm password",
    inputsPassword2Warning: "Required",
    inputsContainersStyles: {},
    inputsStyles: {},
    onlyShowSubmit: false
};

const pageStyleSystem = {
    inputsPassword1Label: "",
    inputsPassword1Placeholder: "Enter a password",
    inputsPassword1Warning: "",
    inputsPassword2Label: "",
    inputsPassword2Placeholder: "Confirm password",
    inputsPassword2Warning: "",
    inputsContainersStyles: {margin: "0px 0px 20px 0px"},
    inputsStyles: {borderRadius: "25px", lineHeight: "36px", backgroundColor: colors.white},
    onlyShowSubmit: true
};

class ChangePassword extends React.Component {
    static defaultProps = {
        title: "Change my password",
        subTitle: null,
        isInsideCard: false,
        onSuccess: () => {},
        onSuccessMessage: "Saved",
        styleSystem: "form" // form or page
    };

    state = {
        ...formStyleSystem,
        password1: '',
        password2: '',
        password1Valid: true,
        password2Valid: true,
        charValid: false,
        numValid: false,
        letterValid: false,
        matchValid: false,
        isSaving: false,
        isSaved: false,
        error: '',
        isChanged: false,
        isValid: true,
        checkboxAgreed: false,
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            ...(this.props.styleSystem === "page" ? pageStyleSystem : formStyleSystem),
            'isLoading': false
        })
    };

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    handleChangePassword1 = e => {
        this.setState({'password1': e.target.value});
        this.setState({'password1Valid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        if(!validator.isEmpty(e.target.value)) {
            this.setState({'charValid': validator.isLength(e.target.value, {min: 8})});
            this.setState({'numValid': !validator.isAlpha(e.target.value)});
            this.setState({'letterValid': !validator.isNumeric(e.target.value)});
            this.setState({'matchValid': (e.target.value === this.state.password2)});
        } else {
            this.setState({'charValid': false});
            this.setState({'numValid': false});
            this.setState({'letterValid': false});
            this.setState({'matchValid': false});
        }
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangePassword2 = e => {
        this.setState({'password2': e.target.value});
        this.setState({'password2Valid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        if(!validator.isEmpty(e.target.value)) {
            this.setState({'matchValid': (e.target.value === this.state.password1)});
        } else if(validator.isEmpty(e.target.value)) {
            this.setState({'charValid': false});
            this.setState({'numValid': false});
            this.setState({'letterValid': false});
            this.setState({'matchValid': false});
        }
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleToggleCheckbox = async (id, checked) => {
        await this.setState({"checkboxAgreed": checked});
        this.checkValid();
    }

    checkValid = () => {
        if(this.state.password1Valid && this.state.password2Valid && this.state.charValid && this.state.numValid && this.state.letterValid && this.state.matchValid && (this.props.isIframe ? this.state.checkboxAgreed : true)) {
            if (this.props.isIframe) {
                console.log('this.state.checkboxAgreed', this.state.checkboxAgreed)
            }
            console.log('isValid true true')
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleSubmit = e => {
        e.preventDefault();
        if(this.state.password1Valid && this.state.password2Valid && this.state.charValid && this.state.numValid && this.state.letterValid && this.state.matchValid && (this.props.isIframe ? this.state.checkboxAgreed : true)) {
            this.setState({'isSaving': true, 'error': ''});
            if (this.props.isIframe) {
                Promise.resolve(this.props.trySetNewPassword(this.props.token, this.state.password1, this.state.password2))
                .then(() => {
                    if(this.props.error !== '') {
                        this.setState({"error": this.props.error});
                    } else {
                        this.props.onSuccess(this.state.password1);
                    }
                    this.setState({'isSaving': false, 'isSaved': true, password1: '', password2: '', charValid: false, numValid: false, letterValid: false, matchValid: false, isChanged: false});
                    this.timeout = setTimeout(() => {this.setState({'isSaved': false})}, 3000);
                })
            } else {
                Promise.resolve(this.props.tryChangePassword(this.state.password1))
                .then(() => {
                    if(this.props.error !== '') {
                        this.setState({"error": this.props.error});
                    } else {
                        this.props.onSuccess(this.state.password1);
                    }
                    this.setState({'isSaving': false, 'isSaved': true, password1: '', password2: '', charValid: false, numValid: false, letterValid: false, matchValid: false, isChanged: false});
                    this.timeout = setTimeout(() => {this.setState({'isSaved': false})}, 3000);
                })
            }
        }
    };

    render() {
        return(
            <>
                {(this.props.title !== null && this.props.title !== "") &&
                    <>
                        {this.props.isInsideCard
                            ? <SettingsCardTitle title={this.props.title}/>
                            : <FontTitle18>{this.props.title}</FontTitle18>
                        }
                    </>
                }
                {this.props.subTitle !== null && <FontBody16>{this.props.subTitle}</FontBody16>}
                {this.state.isSaved
                    ?
                    <>
                        {this.state.error === '' &&
                        <SavedSettings height={"185.5px"} error={false} message={this.props.onSuccessMessage}/>
                        }
                        {this.state.error !== '' &&
                        <SavedSettings height={"185.5px"} error={true} message={this.state.error}/>
                        }
                    </>
                    :
                    <>
                        <form method={"post"} onSubmit={this.handleSubmit}>
                            <PasswordInput
                                title={this.state.inputsPassword1Label}
                                valid={this.state.password1Valid}
                                warning={this.state.inputsPassword1Warning}
                                id={"password1"}
                                onChange={async (e) => {
                                    await this.handleChangePassword1(e);
                                    this.checkValid();
                                }}
                                placeholder={this.state.inputsPassword1Placeholder}
                                value={this.state.password1}
                                containerStyles={this.state.inputsContainersStyles}
                                inputStyles={this.state.inputsStyles}
                            />
                            <PasswordInput
                                title={this.state.inputsPassword2Label}
                                valid={this.state.password2Valid}
                                warning={this.state.inputsPassword2Warning}
                                id={"password2"}
                                onChange={async (e) => {
                                    await this.handleChangePassword2(e);
                                    this.checkValid();
                                }}
                                placeholder={this.state.inputsPassword2Placeholder}
                                value={this.state.password2}
                                containerStyles={this.state.inputsContainersStyles}
                                inputStyles={this.state.inputsStyles}
                            />
                            <InputSection>
                                <InputLabel><FontBody16>Password must have</FontBody16></InputLabel>
                                <Validation>
                                    <>
                                        <Test><Icon status={this.state.charValid}>
                                            {this.state.charValid
                                                ? <CheckCircleFilled />
                                                : <CheckCircleOutlined />
                                            }
                                        </Icon> 8 characters</Test>
                                        <Test><Icon status={this.state.numValid}>
                                            {this.state.numValid
                                                ? <CheckCircleFilled />
                                                : <CheckCircleOutlined />
                                            }
                                        </Icon> One number</Test>
                                    </>
                                    <>
                                        <Test><Icon status={this.state.letterValid}>
                                            {this.state.letterValid
                                                ? <CheckCircleFilled />
                                                : <CheckCircleOutlined />
                                            }
                                        </Icon> One letter</Test>
                                        <Test><Icon status={this.state.matchValid}>
                                            {this.state.matchValid
                                                ? <CheckCircleFilled />
                                                : <CheckCircleOutlined />
                                            }
                                        </Icon> Passwords match</Test>
                                    </>
                                </Validation>
                            </InputSection>
                            {this.props.isIframe && 
                                <CheckboxWithLabel
                                    action={this.handleToggleCheckbox}
                                    id={"termsCheckbox"}
                                    label={<FontBody14>By checking this box, I confirm I have read and agree to the <LinkStyled target={"_blank"} href={"https://"+env.URL.DOMAIN+"/privacy-policy/"}><GoldLink>Privacy Policy</GoldLink></LinkStyled> and <LinkStyled target={"_blank"} href={"https://"+env.URL.DOMAIN+"/terms-of-use/"}><GoldLink>Terms of Use</GoldLink></LinkStyled>.</FontBody14>}
                                    checked={this.state.checkboxAgreed}
                                    styleLabel={{paddingTop: "0px", paddingLeft: "20px", width: "calc(100% - 45px)"}}
                                />
                            }
                            {this.props.isInsideCard
                                ?
                                    this.props.styleSystem === "form"
                                        ?
                                            <ButtonSection>
                                                {(this.state.isSaving || !this.state.isChanged || (this.state.isChanged && !this.state.isValid))
                                                    ? <ButtonInactive canSubmit={true} label={"Update"} />
                                                    : <ButtonPrimary canSubmit={true} label={"Update"} />
                                                }
                                            </ButtonSection>
                                        :
                                            <OnlySubmit>
                                                <SubmitInputWithValidation
                                                    label={"Confirm"}
                                                    isChanged={this.state.isChanged}
                                                    isValid={this.state.isValid}
                                                    isSaving={this.state.isSaving}
                                                />
                                            </OnlySubmit>
                                :
                                    this.props.hasBack
                                        ?
                                            <SaveSection>
                                                <HalfAlign side={"left"}>
                                                    <InlineBlock><FitContent onClick={this.props.back}><ButtonTertiary canSubmit={false} label={"Back"} /></FitContent></InlineBlock>
                                                </HalfAlign>
                                                <HalfAlign side={"right"}>
                                                    <InlineBlock><SubmitInputWithValidation label={"Confirm"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                                </HalfAlign>
                                            </SaveSection>
                                        :
                                            <SaveSection>
                                                <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                            </SaveSection>
                            }
                        </form>
                    </>
                }
            </>
        )
    }
}

const mapStateToProps = state => ({
    error: state.iframe.changePasswords.changePassword.get("error")
});

const mapDispatchToProps = dispatch => ({
    tryChangePassword: (data) => dispatch(tryChangePassword(data)),
    trySetNewPassword: (token, password1, password2) => dispatch(trySetNewPassword(token, password1, password2))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);