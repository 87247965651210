import {axiosReq} from "../../../common/utils/axios";

/* Static */
import {profileDetailsData} from "../static/devdata";

export const tryGetCertifiedAdvisor = (advisorId) => {
    return async dispatch => {
        return axiosReq('apiv1/public/coach/'+advisorId+'/profile-information', "GET", {}, profileDetailsData);
    }
};

export const tryClientRequest = (data) => {
    return async dispatch => {
        return axiosReq('advertising/public/leads/create-request', "POST", data, {});
    }
};
