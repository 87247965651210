import React from 'react';
import styled from 'styled-components';
import {Link, withRouter} from 'react-router-dom';

/* Components */
import {colors} from '../../../common/components/colors';
import {FontSubtitle28} from '../../../common/components/fonts';

const NavRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    column-gap: 60px;
    row-gap: 30px;
    padding-bottom: 40px;
`;
const Nav = styled(Link)`
    ${props => props.active !== "true"} {
        border-bottom: 3px solid ${colors.action100};
        cursor: default;
        & div {
          font-weight: bold;
        }
    }
    ${props => props.active !== "false"} {
        border-bottom: none;
        cursor: pointer;
    }
    padding: 10px 0;
    width: fit-content;
    color: ${colors.primary100};
`;
const Bar = styled.div`
    width: 2px;
    margin: 4px 0;
    background-color: ${colors.primary100};
`;

class MemberOnePageHeaderNav extends React.Component {
    static defaultProps = {
        "coachId": 0
    }

    render() {
        return (
            <NavRow>
                <Nav
                    active={(!this.props.location.pathname.includes("documents") && !this.props.location.pathname.includes("profile")).toString()}
                    to={"/my-team/"+this.props.coachId}
                >
                    <FontSubtitle28 spaced={false}>Overview</FontSubtitle28>
                </Nav>
                <Bar />
                <Nav
                    active={this.props.location.pathname.includes("documents").toString()}
                    to={"/my-team/"+this.props.coachId+"/documents"}
                >
                    <FontSubtitle28 spaced={false}>Shared documents</FontSubtitle28>
                </Nav>
                <Bar />
                <Nav
                    active={this.props.location.pathname.includes("profile").toString()}
                    to={"/my-team/"+this.props.coachId+"/profile"}
                >
                    <FontSubtitle28 spaced={false}>Profile</FontSubtitle28>
                </Nav>
            </NavRow>
        )
    }
}

export default withRouter(MemberOnePageHeaderNav);