import { Map, List, fromJS } from 'immutable';
import { v4 as uuid4 } from 'uuid';
import { defaultMymoneyState, emptyPlan, emptyCategory, emptyLineitem } from '../static/mymoneydefaultstate';

/* Immutable */

const MYMONEY_RESET = 'MYMONEY_RESET';

const MYMONEY_SPENDING_ACTIVEPLAN = 'MYMONEY_SPENDING_ACTIVEPLAN';
const MYMONEY_SPENDING_NEWPLAN = 'MYMONEY_SPENDING_NEWPLAN';
const MYMONEY_SPENDING_ADDCATEGORY = 'MYMONEY_SPENDING_ADDCATEGORY';
const MYMONEY_SPENDING_ADDLINEITEM = 'MYMONEY_SPENDING_ADDLINEITEM';
const MYMONEY_SPENDING_UPDATECATEGORY = 'MYMONEY_SPENDING_UPDATECATEGORY';
const MYMONEY_SPENDING_UPDATELINEITEM = 'MYMONEY_SPENDING_UPDATELINEITEM';
const MYMONEY_SPENDING_DELETELINEITEM = 'MYMONEY_SPENDING_DELETELINEITEM';
const MYMONEY_SPENDING_SAVE = 'MYMONEY_SPENDING_SAVE';
const MYMONEY_SPENDING_DOWNLOADING = 'MYMONEY_SPENDING_DOWNLOADING';
const MYMONEY_SPENDING_UPDATING = 'MYMONEY_SPENDING_UPDATING';

const MYMONEY_SNAPSHOT_ADDLINEITEM = 'MYMONEY_SNAPSHOT_ADDLINEITEM';
const MYMONEY_SNAPSHOT_UPDATELINEITEM = 'MYMONEY_SNAPSHOT_UPDATELINEITEM';
const MYMONEY_SNAPSHOT_DELETELINEITEM = 'MYMONEY_SNAPSHOT_DELETELINEITEM';
const MYMONEY_SNAPSHOT_UPDATECREDITSCORE = 'MYMONEY_SNAPSHOT_UPDATECREDITSCORE';
const MYMONEY_SNAPSHOT_SAVE = 'MYMONEY_SNAPSHOT_SAVE';
const MYMONEY_SNAPSHOT_DOWNLOADING = 'MYMONEY_SNAPSHOT_DOWNLOADING';
const MYMONEY_SNAPSHOT_UPDATING = 'MYMONEY_SNAPSHOT_UPDATING';

const MYMONEY_SAVING_ADDLINEITEM = 'MYMONEY_SAVING_ADDLINEITEM';
const MYMONEY_SAVING_UPDATELINEITEM = 'MYMONEY_SAVING_UPDATELINEITEM';
const MYMONEY_SAVING_DELETELINEITEM = 'MYMONEY_SAVING_DELETELINEITEM';
const MYMONEY_SAVING_SAVE = 'MYMONEY_SAVING_SAVE';
const MYMONEY_SAVING_DOWNLOADING = 'MYMONEY_SAVING_DOWNLOADING';
const MYMONEY_SAVING_UPDATING = 'MYMONEY_SAVING_UPDATING';

/* Actions */

export const setMymoneyReset = () => ({ 'type': MYMONEY_RESET });

export const setMymoneySpendingActiveplan = (pid) => ({ 'type': MYMONEY_SPENDING_ACTIVEPLAN, pid });
export const setMymoneySpendingNewplan = (title) => ({ 'type': MYMONEY_SPENDING_NEWPLAN, title });
export const setMymoneySpendingAddcategory = () => ({ 'type': MYMONEY_SPENDING_ADDCATEGORY });
export const setMymoneySpendingAddlineitem = (cid) => ({ 'type': MYMONEY_SPENDING_ADDLINEITEM, cid });
export const setMymoneySpendingUpdatecategory = (cid, field, val) => ({ 'type': MYMONEY_SPENDING_UPDATECATEGORY, cid, field, val });
export const setMymoneySpendingUpdatelineitem = (cid, i, field, val) => ({ 'type': MYMONEY_SPENDING_UPDATELINEITEM, cid, i, field, val });
export const setMymoneySpendingDeletelineitem = (cid, i) => ({ 'type': MYMONEY_SPENDING_DELETELINEITEM, cid, i });
export const tryMymoneySpendingSave = (spendingPlan) => ({ 'type': MYMONEY_SPENDING_SAVE, spendingPlan });
export const tryMymoneySpendingDownloading = (status) => ({ 'type': MYMONEY_SPENDING_DOWNLOADING, status });
export const tryMymoneySpendingUpdating = (status) => ({ 'type': MYMONEY_SPENDING_UPDATING, status });

export const setMymoneySnapshotAddlineitem = (catType, cid) => ({ 'type': MYMONEY_SNAPSHOT_ADDLINEITEM, catType, cid });
export const setMymoneySnapshotUpdatelineitem = (catType, cid, i, field, val) => ({ 'type': MYMONEY_SNAPSHOT_UPDATELINEITEM, catType, cid, i, field, val });
export const setMymoneySnapshotDeletelineitem = (catType, cid, i) => ({ 'type': MYMONEY_SNAPSHOT_DELETELINEITEM, catType, cid, i });
export const setMymoneySnapshotUpdatecreditscore = (field, val) => ({ 'type': MYMONEY_SNAPSHOT_UPDATECREDITSCORE, field, val });
export const tryMymoneySnapshotSave = (snapshot) => ({ 'type': MYMONEY_SNAPSHOT_SAVE, snapshot });
export const tryMymoneySnapshotDownloading = (status) => ({ 'type': MYMONEY_SNAPSHOT_DOWNLOADING, status });
export const tryMymoneySnapshotUpdating = (status) => ({ 'type': MYMONEY_SNAPSHOT_UPDATING, status });

export const setMymoneySavingAddlineitem = () => ({ 'type': MYMONEY_SAVING_ADDLINEITEM });
export const setMymoneySavingUpdatelineitem = (i, field, val) => ({ 'type': MYMONEY_SAVING_UPDATELINEITEM, i, field, val });
export const setMymoneySavingDeletelineitem = (i) => ({ 'type': MYMONEY_SAVING_DELETELINEITEM, i });
export const tryMymoneySavingSave = (saving) => ({ 'type': MYMONEY_SAVING_SAVE, saving });
export const tryMymoneySavingDownloading = (status) => ({ 'type': MYMONEY_SAVING_DOWNLOADING, status });
export const tryMymoneySavingUpdating = (status) => ({ 'type': MYMONEY_SAVING_UPDATING, status });

/* Initial State */

const savedState = JSON.parse(JSON.stringify(defaultMymoneyState));
// if(window.localStorage.getItem('mymoney-spendingPlan')) {
//     Object.assign(savedState, { spendingPlan: JSON.parse(window.localStorage.getItem('mymoney-spendingPlan')) });
// }
// if(window.localStorage.getItem('mymoney-snapshot')) {
//     Object.assign(savedState, { snapshot: JSON.parse(window.localStorage.getItem('mymoney-snapshot')) });
// }
// if(window.localStorage.getItem('mymoney-saving')) {
//     Object.assign(savedState, { saving: JSON.parse(window.localStorage.getItem('mymoney-saving')) });
// }
const initialState = fromJS(savedState);

/* Reducer */

const mymoneyReducer = (state=initialState, action) => {
    //
    // My Spending
    //
    const activePlanIndex = state.getIn([ 'spendingPlan', 'plans' ]).findIndex(plan => plan.get('pid') === state.getIn([ 'spendingPlan', 'activePlan' ]));
    const activePlan = state.getIn([ 'spendingPlan', 'plans', activePlanIndex ])
    const expenses = activePlan.get('expenses');
    switch (action.type) {
        case MYMONEY_SPENDING_ACTIVEPLAN:
            return state.setIn([ 'spendingPlan', 'activePlan' ], action.pid);
        case MYMONEY_SPENDING_NEWPLAN:
            const newPlan = fromJS({ ...emptyPlan, pid: uuid4(), title: action.title })
            return state.withMutations(function(map) {
                map
                    .updateIn([ 'spendingPlan', 'plans' ], list => list.push(newPlan))
                    .setIn([ 'spendingPlan', 'activePlan' ], newPlan.get('pid'))
                    .setIn([ 'spendingPlan', 'unsaved' ], true);
            });
        case MYMONEY_SPENDING_ADDCATEGORY:
            const newCat = fromJS({ ...emptyCategory, cid: uuid4() });
            return state.withMutations(function(map) {
                map
                    .updateIn([ 'spendingPlan', 'plans', activePlanIndex, 'expenses' ], list => list.push(newCat))
                    .setIn([ 'spendingPlan', 'unsaved' ], true);
            });
        case MYMONEY_SPENDING_ADDLINEITEM:
            const indexToAdd = expenses.findIndex(expense => {
                return expense.get('cid') === action.cid;
            });
            if(indexToAdd < 0) {
                if(action.cid === 'INCOME') {
                    return state.withMutations(function(map) {
                        map
                            .updateIn([ 'spendingPlan', 'plans', activePlanIndex, 'income' ], list => list.push(fromJS({ ...emptyLineitem, lid: uuid4() })))
                            .setIn([ 'spendingPlan', 'unsaved' ], true);
                    });
                }
                return state;
            }
            return state.withMutations(function(map) {
                map
                    .updateIn([ 'spendingPlan', 'plans', activePlanIndex, 'expenses', indexToAdd, 'lineItems' ], list => list.push({ ...emptyLineitem, lid: uuid4() }))
                    .setIn([ 'spendingPlan', 'unsaved' ], true);
            });
        case MYMONEY_SPENDING_UPDATECATEGORY:
            const indexToUpdate = expenses.findIndex(expense => expense.get('cid') === action.cid);
            if(indexToUpdate < 0) {
                return state;
            }
            return state.withMutations(function(map) {
                map
                    .setIn([ 'spendingPlan', 'plans', activePlanIndex, 'expenses', indexToUpdate, action.field ], action.val)
                    .setIn([ 'spendingPlan', 'unsaved' ], true);
            });
        case MYMONEY_SPENDING_UPDATELINEITEM:
            const indexToUpdateItem = expenses.findIndex(expense => expense.get('cid') === action.cid);
            if(indexToUpdateItem < 0) {
                if(action.cid === 'INCOME') {
                    return state.withMutations(function(map) {
                        map
                            .setIn([ 'spendingPlan', 'plans', activePlanIndex, 'income', action.i, action.field ], action.val)
                            .setIn([ 'spendingPlan', 'unsaved' ], true);
                    });
                }
                return state;
            }
            return state.withMutations(function(map) {
                map
                    .setIn([ 'spendingPlan', 'plans', activePlanIndex, 'expenses', indexToUpdateItem, 'lineItems', action.i, action.field ], action.val)
                    .setIn([ 'spendingPlan', 'unsaved' ], true);
            });
        case MYMONEY_SPENDING_DELETELINEITEM:
            const indexToRemoveItem = expenses.findIndex(expense => expense.get('cid') === action.cid);
            if(indexToRemoveItem < 0) {
                if(action.cid === 'INCOME') {
                    return state.withMutations(function(map) {
                        map
                            .removeIn([ 'spendingPlan', 'plans', activePlanIndex, 'income', action.i ])
                            .setIn([ 'spendingPlan', 'unsaved' ], true);
                    });
                }
                return state;
            }
            if(expenses.getIn([ indexToRemoveItem, 'lineItems' ]).size === 1) {
                return state.withMutations(function(map) { // Last item, so delete whole category
                    map
                        .removeIn([ 'spendingPlan', 'plans', activePlanIndex, 'expenses', indexToRemoveItem ])
                        .setIn([ 'spendingPlan', 'unsaved' ], true);
                });
            }
            return state.withMutations(function(map) {
                map
                    .removeIn([ 'spendingPlan', 'plans', activePlanIndex, 'expenses', indexToRemoveItem, 'lineItems', action.i ])
                    .setIn([ 'spendingPlan', 'unsaved' ], true);
            });
        case MYMONEY_SPENDING_UPDATING:
            return state.merge({'spendingPlanUpdating': action.status});
        case MYMONEY_SPENDING_DOWNLOADING:
            return state.merge({'spendingPlanDownloading': action.status});
        case MYMONEY_SPENDING_SAVE:
            return state.merge(Map({
                'spendingPlan': Map({
                    "activePlan": action.spendingPlan.activePlan,
                    "plans": fromJS(action.spendingPlan.plans),
                    "unsaved": false
                })
            }));
            // const savedSpendingStateAdj = savedSpendingState.setIn([ 'spendingPlan', 'unsaved' ], false);
            // window.localStorage.setItem('mymoney-spendingPlan', JSON.stringify(savedSpendingState.get('spendingPlan')));
            // return savedSpendingState;

        //
        // My Money Snapshot
        //
        case MYMONEY_SNAPSHOT_ADDLINEITEM:
            const indexToAddSnapItem = state.getIn([ 'snapshot', action.catType ]).findIndex(cat => cat.get('cid') === action.cid);
            return state.withMutations(function(map) {
                map
                    .updateIn([ 'snapshot', action.catType, indexToAddSnapItem, 'lineItems' ], list => list.push(fromJS({ ...emptyLineitem, lid: uuid4() })))
                    .setIn([ 'snapshot', 'unsaved' ], true);
            });
        case MYMONEY_SNAPSHOT_UPDATELINEITEM:
            const indexToUpdateSnapItem = state.getIn([ 'snapshot', action.catType ]).findIndex(cat => cat.get('cid') === action.cid);
            return state.withMutations(function(map) {
                map
                    .setIn([ 'snapshot', action.catType, indexToUpdateSnapItem, 'lineItems', action.i, action.field ], action.val)
                    .setIn([ 'snapshot', 'unsaved' ], true);
            });
        case MYMONEY_SNAPSHOT_DELETELINEITEM:
            const indexToRemoveSnapItem = state.getIn([ 'snapshot', action.catType ]).findIndex(cat => cat.get('cid') === action.cid);
            return state.withMutations(function(map) {
                map
                    .removeIn([ 'snapshot', action.catType, indexToRemoveSnapItem, 'lineItems', action.i ])
                    .setIn([ 'snapshot', 'unsaved' ], true);
            });
        case MYMONEY_SNAPSHOT_UPDATECREDITSCORE:
            return state.withMutations(function(map) {
                map
                    .setIn([ 'snapshot', 'credtScore', action.field ], action.val)
                    .setIn([ 'snapshot', 'unsaved' ], true);
            });
        case MYMONEY_SNAPSHOT_UPDATING:
            return state.merge({'snapshotUpdating': action.status});
        case MYMONEY_SNAPSHOT_DOWNLOADING:
            return state.merge({'snapshotDownloading': action.status});
        case MYMONEY_SNAPSHOT_SAVE:
            return state.merge(Map({
                'snapshot': Map({
                    "assets": fromJS(action.snapshot.assets),
                    "liabilities": fromJS(action.snapshot.liabilities),
                    "credtScore": fromJS(action.snapshot.credtScore),
                    "unsaved": false
                })
            }));
            // const savedSnapshotState = state.setIn([ 'snapshot', 'unsaved' ], false);
            // window.localStorage.setItem('mymoney-snapshot', JSON.stringify(savedSnapshotState.get('snapshot')));
            // return savedSnapshotState;

        //
        // What I'm Saving For
        //
        case MYMONEY_SAVING_ADDLINEITEM:
            return state.withMutations(function(map) {
                map
                    .updateIn([ 'saving', 'goals' ], list => list.push(fromJS({ ...emptyLineitem, lid: uuid4() })))
                    .setIn([ 'saving', 'unsaved' ], true);
            });
        case MYMONEY_SAVING_UPDATELINEITEM:
            return state.withMutations(function(map) {
                map
                    .setIn([ 'saving', 'goals', action.i, action.field ], action.val)
                    .setIn([ 'saving', 'unsaved' ], true);
            });
        case MYMONEY_SAVING_DELETELINEITEM:
            return state.withMutations(function(map) {
                map
                    .removeIn([ 'saving', 'goals', action.i ])
                    .setIn([ 'saving', 'unsaved' ], true);
            });
        case MYMONEY_SAVING_UPDATING:
            return state.merge({'savingUpdating': action.status});
        case MYMONEY_SAVING_DOWNLOADING:
            return state.merge({'savingDownloading': action.status});
        case MYMONEY_SAVING_SAVE:
            return state.merge(Map({
                'saving': Map({
                    "goals": fromJS(action.saving.goals),
                    "unsaved": false
                })
            }));
            // const savedSavingState = state.setIn([ 'saving', 'unsaved' ], false);
            // window.localStorage.setItem('mymoney-saving', JSON.stringify(savedSavingState.get('saving')));
            // return savedSavingState;

        //
        // Other
        //
        case MYMONEY_RESET:
            // window.localStorage.removeItem('mymoney-spendingPlan');
            // window.localStorage.removeItem('mymoney-snapshot');
            // window.localStorage.removeItem('mymoney-saving');
            return state.merge(defaultState);
        default:
            return state;
    }
};

export default mymoneyReducer;