import React, {useState} from "react";
import {useDispatch} from "react-redux";
import styled from "styled-components";

/* Components */
import {StarFilled} from "@ant-design/icons";
import {FontHeader16, FontTitle18} from "../../common/components/fonts";
import {Card, Icon, Body, Title, BottomBorder5, Content} from '../components/contactactivity';
import {ButtonTertiary, ButtonPrimary} from "../../common/components/buttons";
import {tryPostAdvisorSessionStep} from "../homepage/middleware/announcements";
import {LogoLoading} from "../../common/components/loading";
import {SubmitButton} from "../../common/components/inputs";
import {colors} from "../../common/components/colors";

const Loader = styled.div`
    & > div {
      text-align: left;
    }
    & img {
      width: 50px;
      height: auto;
    }
`;
const Radios = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  font-size: 20px;
  align-items: flex-start;
`;
const TextArea = styled.textarea`
  width: 100%;
  margin: 20px 0;
  border-radius: 10px;
  font-size: 20px;
`;


export default function NotesForCoach(props) {
    const userId = props.userId;
    const advisorName = props.advisorName;
    const conferenceId = props.conferenceId;
    const updateSessionStep = props.updateSessionStep;
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState(false);
    const [reason, setReason] = useState("");
    const dispatch = useDispatch();

    const handleSubmit = async () => {
        setIsLoading(true)
        const response = {
            'conferenceId': conferenceId,
            'advisorId': userId,
            'passed': status,
            'reason': reason
        }
        await dispatch(tryPostAdvisorSessionStep(response))
        setIsLoading(false)
        return updateSessionStep(status, reason)
    }

    return(
        <>
            <Icon><StarFilled /></Icon>
            <>
                <div>
                    <Title><FontTitle18>Advisor Certification</FontTitle18></Title>
                </div>
                <>
                    <BottomBorder5>
                        <FontHeader16>Is {advisorName} qualified to earn their certification?</FontHeader16>
                    </BottomBorder5>
                    <Radios>
                        <label>
                            <input type={"radio"} name={"passfail"} id={"Pass"} onClick={() => {setStatus(true)}}  />
                            Yes
                        </label>
                        <label>
                            <input type={"radio"} name={"passfail"} id={"Fail"} onClick={() => {setStatus(false)}} />
                            No, (please list details that contributed to your decision below)
                        </label>
                    </Radios>
                    <TextArea rows={4} onChange={(e) => {setReason(e.target.value)}} />
                    {isLoading
                        ? <Loader><LogoLoading /></Loader>
                        : <>
                            {(status || (!status && reason !== ""))
                                ? <div onClick={handleSubmit}>                            <SubmitButton
                                    backgroundColor={colors.action100}
                                    iconComponent={null}
                                    color={colors.primary100}
                                    label={"Submit"}
                                /></div>
                                : <div>                        <SubmitButton
                                    iconComponent={null}
                                    backgroundColor={colors.border70} disabled={true}
                                    color={colors.primary30} label={"Submit"}/></div>
                            }
                        </>
                    }
                </>
            </>
        </>
    )
}