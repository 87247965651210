import {images} from '../../../common/components/images';

/* Middleware - tryAuth */
export const devIframeAuthData = {
    "email": "jason.aronson@gmail.com",
    "first": "Willow",
    "last": "",
    "subscription": true,
    "platform": "consumer", // coach, advisor, advisor_and_coach, consumer, enterprise
    "userId": 24324,
    "uid": "74d1a449-ba40-4ad0-9989-f148807f22ed",
    "onboardingCompleted": true,
    "phone": null,
    "newUser": false,
    "companyName": "Company Name, LLC",
    "photo": images.devDataTeamJasonAvatar,
    // "wmc": null,
    "wmc": {
        "id": 1,
        "address1": "",
        "address2": "",
        "city": "Boston",
        "companyLogoUrl": images.logo,
        "companyName": "Willow Network, Inc.",
        "contactPersonCellPhone": "",
        "contactPersonEmail": "",
        "contactPersonFirstName": "",
        "contactPersonLastName": "",
        "contactPersonWorkPhone": "",
        "email": "",
        "facebookUrl": "",
        "linkedinUrl": "",
        "phone": "",
        "state": "MA",
        "twitterUrl": "",
        "zipCode": ""
    },
    "referralType": "willow", // "wmc" or "willow" or "coach"
    "expertCategories": [{"categoryId": 1, "categoryName": "Financial Coach"}],
};

export const devIframeVerificationStatus = {
    "status": "verified" // "no_verification_code_sent", "not_verified", "verified"
};

export const devIframeSession = {
    id: 1,
    remoteUserName: "Gabby Coach",
    remoteUserId: 1,
    thisUserName: "Jason Consumer",
    thisUserPlatform: "consumer",
    start: "2020-06-14T13:00:00+00:00",
    end: "2020-06-14T13:30:00+00:00",
    phone: "617-380-4313",
    room: "277861",
    videoStatus: "conference_ongoing", // conference_canceled, conference_ended, conference_has_not_yet_started, conference_ongoing
    videoUrl: "924fcf54447903ab9ea558886af67543", // if status !== conference_ongoing then videoUrl = null
    videoAccessToken: "abc", // if status !== conference_ongoing then videoAccessToken = null
    title: 'Jason Consumer',
};

export const devIframeHandleData = (isFound, handle) => {
    if(isFound) {
        return({
            "id": 12,
            "publicHandle": handle,
            "expertCategoryName": "coach", // coach, advisor, etc
            "expertCategoryId": 1
        })
    } else {
        return({
            "error": "Public handle can not be found",
            "errorDetails": "Expert does not exist"
        })
    }
};

/* Middleware - tryGetSettingsEnterpriseProfile */
// coachingcert, advancedDegree, otherEducation
export const devIframeProfileData = {
    id: '32',

    firstName: "Jason",
    lastName: "Aronson",
    companyName: 'Coaching, LLC',
    crd: 'test 5',

    photo: images.devDataTeamJasonAvatar,
    introVideoUrl: 'https://www.youtube.com/embed/RZAF1DAqHPk',
    facebookUrl: 'https://facebook.com',
    linkedinUrl: 'https://linkedin.com',
    twitterUrl: 'https://twitter.com',
    address1: 'test 3',
    address2: 'test 6',
    city: 'Boston',
    state: 'MA',
    zipCode: '23443',
    bio: '<p>In an age of pandemic, what true value do any of our backgrounds hold? It’s not like we can look at precedents and say, people who have done ABC tend to be really successful at accomplishing XYZ in a pandemic. My expf</p>',

    // BEG COACH ONLY FIELDS
    endorsement1: 'Test 1',
    endorsement2: 'Test 2',
    endorsement3: null,
    freeTime: 'Test 7',
    whyBecameCoach: 'Test 2',
    coachPhilosophy: 'Test 3',
    highlights: 'Test 1',

    coachingCertification: null,
    coachingCertificationYear: null,

    // END COACH ONLY FIELDS

    bookingAvailability: 'available',

    screeningCallLength: 30,
    defaultCallLength: 30,

    advancedDegree: null,
    advancedDegreeCity: null,
    advancedDegreeInstitution: null,
    advancedDegreeState: null,
    advancedDegreeYear: null,

    otherEducation: null,

    expertCategories: [],

    profileActive: true,
    profileShowEmailer: true,
    profileShowScheduler: true,
    schedulerActive: true,
    schedulerHandle: "sampleadvisor",
    storeActive: true,
    storeHandle: "sampleadvisor",
    storePhoto: null,
    storeShowEmailer: true,
    storeShowScheduler: true,
    storeTitle: "",
    website: ""
};

export const devIframeLogin = (success) => {
    if(success) {
        return({"success": true})
    } else {
        return({"error": "Something happened."})
    }
};

export const devIframeEvents = {
    "coachingProducts": [
        {
            "id": 8,
            "title": "TestPlan 2",
            "description": "Lorem ipsum...",
            "coverPhoto": null,
            "termsOfUseUrl": "Lorem ipsum www.google.com",
            "privacyPolicyUrl": "www.google.com",
            "downloadUrl": "www.download.com",
            "sessionLength": 15,
            "serviceType": "event",
            "status": "Active",
            "plans": [
                {
                    "id": 4,
                    "orderIndex": 0,
                    "description": "Buy this plan for 2 meetings, 1 free",
                    "amount": 4000,
                    "planType": "one_time",
                    "recurringInterval": null
                },
                {
                    "id": 5,
                    "orderIndex": 1,
                    "description": "Buy this plan for year of meetings, 4 meetings per month, paid annualy, cancel anytime",
                    "amount": 50000,
                    "planType": "recurring",
                    "recurringInterval": "year"
                },
                {
                    "id": 6,
                    "orderIndex": 2,
                    "description": "Buy this plan for 3 monthly meetings, charged monthly for 4 months",
                    "amount": 2500,
                    "planType": "recurring",
                    "subscriptionDurationInMonths" : 4,
                    "recurringInterval": "month"
                }
            ]
        },
        {
            "id": 9,
            "title": "TestPlan 3",
            "description": "Lorem ipsum...",
            "coverPhoto": null,
            "termsOfUseUrl": "Lorem ipsum www.google.com",
            "privacyPolicyUrl": "www.google.com",
            "downloadUrl": "www.download.com",
            "sessionLength": 60,
            "serviceType": "event",
            "status": "Active",
            "plans": [
                {
                    "id": 7,
                    "orderIndex": 0,
                    "description": "Buy this plan for 2 meetings, 1 free",
                    "amount": 4000,
                    "planType": "one_time",
                    "recurringInterval": null
                },
                {
                    "id": 8,
                    "orderIndex": 1,
                    "description": "Buy this plan for year of meetings, 4 meetings per month, paid annualy, cancel anytime",
                    "amount": 50000,
                    "planType": "recurring",
                    "recurringInterval": "year"
                },
                {
                    "id": 9,
                    "orderIndex": 2,
                    "description": "Buy this plan for 3 monthly meetings, charged monthly for 4 months",
                    "amount": 2500,
                    "planType": "recurring",
                    "subscriptionDurationInMonths" : 4,
                    "recurringInterval": "month"
                }
            ]
        },
        {
            "id": 3,
            "title": "TestPlan 4",
            "description": "Lorem ipsum...",
            "coverPhoto": null,
            "termsOfUseUrl": "Lorem ipsum www.google.com",
            "privacyPolicyUrl": "www.google.com",
            "downloadUrl": "www.download.com",
            "sessionLength": 0,
            "serviceType": "download",
            "status": "Disabled",
            "plans": [
                {
                    "id": 90,
                    "orderIndex": 0,
                    "description": "Buy this plan for 2 meetings, 1 free",
                    "amount": 4000,
                    "planType": "one_time",
                    "recurringInterval": null
                },
                {
                    "id": 85,
                    "orderIndex": 1,
                    "description": "Buy this plan for year of meetings, 4 meetings per month, paid annualy, cancel anytime",
                    "amount": 50000,
                    "planType": "recurring",
                    "recurringInterval": "year"
                },
                {
                    "id": 95,
                    "orderIndex": 2,
                    "description": "Buy this plan for 3 monthly meetings, charged monthly for 4 months",
                    "amount": 2500,
                    "planType": "recurring",
                    "subscriptionDurationInMonths" : 4,
                    "recurringInterval": "month"
                }
            ]
        }
    ]
};

export const devUpdateUserData = (firstName, lastName, email, phone, timeZone) => ({
    "first": firstName,
    "last": lastName,
    "phone": phone,
    "email": email,
    "timeZone": timeZone,
    "companyName": ""
});
