import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import { animateScroll } from "react-scroll";
import moment from 'moment/moment';

import {colors} from '../../../common/components/colors';
import {FontBody16,FontBody12, FontHeader18 } from '../../../common/components/fonts';
import { Message } from "../../../common/components/messages";
import {DropdownInput, InputSection} from '../../../common/components/inputs';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

/* Static */
import {messagesTemplates} from '../static/templates';

const MessageContainer = styled.div`
    max-width: 290px;
    padding: 15px 25px;
    margin: 20px 0px;
    border-radius: 20px;
    ${props => props.isFromMe === true} {
        background-color: ${colors.backgroundChatBubble};
        color: ${colors.primary100};
    }
    ${props => props.isFromMe === false} {
        margin-left: calc(100% - 340px);
        background-color: ${colors.backgroundColor1};
        color: ${colors.primary100};
        @media screen and (max-width: 460px) {
            margin-left: calc(100% - 240px);
        }
    }
    @media screen and (max-width: 460px) {
        border-radius: 10px;
        padding: 7px 10px;
        max-width: 220px;
    }
    & a {
        text-decoration: underline;
        ${props => props.isFromMe === true} {
            color: ${colors.primary100};
        }
        ${props => props.isFromMe === false} {
            color: ${colors.primary100};
        }
    }
`;
const Time = styled.div`
    margin-top: 10px;
    ${props => props.isFromMe === true} {
        color: ${colors.secondary100} !important;
    }
    ${props => props.isFromMe === false} {
        color: ${colors.primary30} !important;
    }
`;


const Recipient = ({ isFromMe, content, datetime, sendPurchaseLink, purchaseToken }) => (
    <MessageContainer isFromMe={isFromMe} >
        {sendPurchaseLink
            ? <FontBody16>Here is a <a target={"_blank"} href={"https://"+env.URL.HOSTNAME+"/package/"+purchaseToken}>link to purchase</a></FontBody16>
            : <FontBody16>{content}</FontBody16>
        }
        <Time isFromMe={isFromMe}><FontBody12>{moment(datetime).format('lll').toString()}</FontBody12></Time>
    </MessageContainer>
);

const Compose = styled.div`
    margin-top: 60px;
`;
export const TextArea = styled.textarea`
    background-color: ${colors.white};
    border-radius: 20px;
    padding: 15px 20px;
    resize: none;
    -webkit-box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    font-family: GraphicWebRegular;
    font-size: 16px;
    line-height: 22px;
    width: calc(100% - 42px);
    border: 1px solid transparent;
    @media screen and (max-width: 460px) {
        padding: 10px 10px;
        border-radius: 10px;
        min-height: 68px;
        max-width: 260px;
        width: 100%;
    }
    &:focus {
        outline: 0;
    }
`;
const Buttons = styled.div`
    margin-top: 40px;
    text-align: center;
`;
const Button = styled.button`
    background-color: ${colors.background100};   
    border-radius: 6px;
    margin: 0px 20px;
    min-width: 185px;
    padding: 12px 30px;
    cursor: pointer;
    ${props => props.disabled !== true} {
        border: 1px solid ${colors.primary30};
        color: ${colors.primary30};
    }
    ${props => props.disabled !== false} {
        background-color: ${colors.action100};
        border: 1px solid ${colors.action100};
    }
    @media screen and (max-width: 748px) {
        margin: 0px auto;
    }
`;
const Templates = styled.div`
    display: inline-block;
    vertical-align: top;
    & .Dropdown-control {
        height: 28px;
    }
    & > div {
        text-align: center;
        margin: 0px auto 10px auto;
    }
`;
const DropdownSize = styled.div`
    width: 300px;
    @media screen and (max-width: 748px) {
        margin: 0px auto;
    }
    @media screen and (max-width: 460px) {
        width: 278px;
    }
    & > div {
        margin: 0px auto 0px auto;
    }
`;
const Flex = styled.div`
    @media screen and (max-width: 748px) {
        display: flex;
        flex-direction: column;
    }
`;

/* MESSAGE ROOM & VIDEO ROOM (note was used for legacy coach and consumer dash) */

function MessengerWrapper(props) {
    const [content, setNewContent] = useState('');

    useEffect(() => {animateScroll.scrollToBottom();}, [props.activity]);

    const send = useCallback((mediaType, content, subject, messageThreadId, sendMessage) =>{
        return Promise.resolve(sendMessage(mediaType, content, subject, messageThreadId))
            .then(() => {
                return(setNewContent(''))
            })
    }, []);

    const selectTemplate = useCallback(async (event, messagesTemplates, remoteName) => {
        const templates = await messagesTemplates(remoteName);
        templates.map(m => {
            if(m.value === event) {return setNewContent(m.body)}
        });
    }, []);

    return(
        <div>
            {props.activity.size === 0 &&
                <Message text={"No messages yet"}/>
            }
            {props.activity.map(message => (
                <div key={message.get("id")}>
                    <Recipient isFromMe={props.userId === message.get("fromUserId")} content={message.get("content")} datetime={message.get("formattedDatetime")} sendPurchaseLink={message.get("content") === "PURCHASE_LINK"} purchaseToken={props.purchaseToken} />
                </div>
            ))}
            <Compose>
                <TextArea
                    value={content}
                    onChange={event => setNewContent(event.target.value)}
                    placeholder={"Type a " + (props.channel === "sms" ? "SMS" : "") + " message..."}
                />
                <Buttons>
                    {props.userPlatform !== "consumer" &&
                        <Flex>
                            {/*<Button onClick={() => {send('email', content, props.messageThreadId, props.sendMessage)}} disabled={!content || !content.trim()}><FontHeader18>Send as Email</FontHeader18></Button>*/}
                            {props.showTemplates === true && <Templates><DropdownSize><DropdownInput dropUpwards={true} id={"templates"} placeholder={"Templates"} value={"Templates"} options={messagesTemplates(props.messagesConsumerName)} onChange={(event) => selectTemplate(event.value, messagesTemplates, props.messagesConsumerName)} /></DropdownSize></Templates>}
                            <Templates><InputSection><Button onClick={() => {send(props.channel, content, null, props.messageThreadId, props.sendMessage)}} disabled={!content}><FontHeader18>Send</FontHeader18></Button></InputSection></Templates>
                        </Flex>
                    }
                    {props.userPlatform === "consumer" &&
                        <Button onClick={() => {send(props.channel, content, null, props.messageThreadId, props.sendMessage)}} disabled={!content}><FontHeader18>Send</FontHeader18></Button>
                    }
                </Buttons>
            </Compose>
        </div>
    )
}

export default MessengerWrapper;
