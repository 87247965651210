import React from 'react';
import {connect} from "react-redux";
import moment from "moment-timezone";
import {v4 as uuid4} from 'uuid';
import { withRouter} from 'react-router-dom';
import styled from "styled-components";

/* Components */
import { Message } from "../../../common/components/messages";
import {LogoLoading} from "../../../common/components/loading";
import {FontBody21To16} from '../../../common/components/fonts';
import { ContainerApptNav } from '../components/progress';
import { colors } from "../../../common/components/colors"

/* Containers */
import ProgressAppointmentsList from './progressappointmentslist';
import SchedulingRescheduleCancel from "../../../iframes/reschedulermodal/containers/reschedulecancel";

/* Middleware */
import {tryGetTeamMemberMeetings} from '../../appointments/middleware/meetings';
import {tryGetAllTeamMembers} from '../../team/middleware/team';
import {tryGetMySubscribedGroupPrograms} from "../../programs/middleware/programs";
import {setTeamMemberMeetingsChanged} from "../../appointments/store/meetings";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const BookButton = styled(FontBody21To16)`
    width: fit-content;
    cursor: pointer;
    border: 2px solid white;
    color: ${colors.white};
    border-radius: 15px;
    height: 22px;
    line-height: 25px;
    padding: 2px 8px 5px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-around;
    margin-bottom: 100px;
    padding: 0px 50px;
    margin-top: 25px;
`;

const NavOption = styled(FontBody21To16)`
    ${props => props.active !== true} {
        border-bottom: 3px solid ${colors.white};
        cursor: default;
    }
    ${props => props.active !== false} {
        border-bottom: none;
        cursor: pointer;
    }
    width: fit-content;
    color: ${colors.white};
}
`;

const Bar = styled.div`
    width:2px;
    margin: 4px 0;
    background-color: ${colors.white}
`;

class ProgressAppointments extends React.Component {
    state = {
        "isLoading": true,
        "meetings": [],
        "members": [],
        "selectedMember": {},
        "showRescheduler": false,
        "selected": {},
        "containerId": uuid4(),
        "currentTab": null, /* upcoming, past, missed */
        "groupPrograms": [],
        "programs": {
            "upcoming": [],
            "past": []
        },
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.props.tryGetAllTeamMembers();
        await this.props.tryGetMySubscribedGroupPrograms();
        let members = this.props.members.filter((m) => {return(m.get("wmcId") === null)});
        await this.props.tryGetTeamMemberMeetings();
        await this.setState({
            "members": await this.setUpMembers(members.toJS()),
            "meetings": await this.setUpMeetings(this.props.meetings),
            'groupPrograms': this.props.myGroupPrograms.toJS(),
            'programs': {
                "upcoming": await this.filterPrograms("upcoming"),
                "past": await this.filterPrograms("past")
            },
            "currentTab": 'upcoming',
            "isLoading": false,
        });
    };

    setUpMembers = (data) => {
        return data.map(coach => {
            let title = env.CONSUMER.CARE.PRINT_EXPERT_CATEGORIES(coach.expertCategories, true);
            return { "id": coach.coachId, "first": coach.first, "last": coach.last, "title": title, "photo": coach.photo };
        });
    }

    // Organize all meetings by upcoming, past, missed
    setUpMeetings = (data) => {
        let obj = {
            'upcoming': [],
            'past': [],
            'missed': [],
        };
        this.addTimeInfo(data).forEach(m => {
            obj[m.type].push(m);
        })
        return obj;
    }

    // Add type, length, and date to each meeting datum
    addTimeInfo = (meetings) => {
        return meetings.reduce((filtered, c) => {
            let isPast = moment(c.get("end")).isBefore(moment());
            let isUpcoming = moment(c.get("start")).isAfter(moment());
            let isOngoing = (moment(c.get("start")).isBefore(moment()) && moment(c.get("end")).isAfter(moment()));
            let isMissed = !c.get("isMissed") === undefined ? false : c.get("isMissed");
            let end = (c.get("expected_end") === undefined || c.get("expected_end") === null) ? c.get("end") : c.get("expected_end");
            let d = c.get('start');
            filtered.push({
                ...c.toJS(),
                "type": ((isUpcoming || isOngoing) ? "upcoming" : (isMissed ? "missed" : "past")),
                "length": moment.duration(moment(end).diff(moment(c.get("start")))).asMinutes().toString(),
                "date": moment(d).format("ll").toString() + ", " + moment(d).format("h:mma").toString() + " - " + moment(end).format("h:mma").toString(),
                "tag": "meeting"
            })
            return filtered;
        }, []);
    };

    filterPrograms = async (tab) => {
        const programsAll = this.props.myGroupPrograms.toJS();
        let lecturesAll = await Promise.all(programsAll.map(p => {
            return Promise.resolve(Promise.all(p.groupProgram.lectures.map(l => {
                return Promise.resolve({
                    ...l,
                    ...l.webinar,
                    "program": p,
                    "author": p.groupProgram.author,
                    "programTitle": p.groupProgram.title,
                    "tag": "program"
                })
            })));
        }));
        let filtered = lecturesAll.flat().filter(a => {
            if(tab === "upcoming") {
                return(moment().isBefore(moment(a.flashSaleStartDateTime)))
            } else {
                return(moment().isAfter(moment(a.flashSaleStartDateTime)))
            }
        });
        return filtered.sort((a, b) => {return(new Date(a.flashSaleStartDateTime) - new Date(b.flashSaleStartDateTime))});
    };

    bookAppointment = async () => {
        this.props.history.push("/my-team/appointment")
    };

    hideRescheduler = async () => {
        await this.setState({"showRescheduler": false, "selected": {}});
    };

    reschedule = async (data) => {
        this.setState({"selected": data, "showRescheduler": true});
    };

    changeTab = async (tabToSwitchTo) => {
        await this.setState({"currentTab": tabToSwitchTo});
    };

    executeCancel = async () => {
        console.log('resched confirmOnCancelled')
        return this.init();
    }

    executeReschedule = async () => {
        console.log('resched confirmOnScheduled')
        await this.init();
        this.props.history.push("/my-team/"+this.state.selected.remoteUserId+"/appointment")
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            if(this.state.meetings.length === 0) {
                return(<Message text={"No appointments scheduled"} />)
            } else {
                return(
                    <>
                        <ContainerApptNav>
                            <NavOption active={this.state.currentTab === 'upcoming'} onClick={() => {this.changeTab('upcoming');}}>
                                {'Upcoming'}
                            </NavOption>
                            <Bar />
                            <NavOption active={this.state.currentTab === 'past'} onClick={() => {this.changeTab('past');}}>
                                {'Past'}
                            </NavOption>
                            <Bar />
                            <NavOption active={this.state.currentTab === 'missed'} onClick={() => {this.changeTab('missed');}}>
                                {'Missed'}
                            </NavOption>
                            <Bar />
                            <BookButton onClick={this.bookAppointment}>{'Book an appointment'}</BookButton>
                        </ContainerApptNav>
                        <Container id={this.state.containerId}>
                            {this.state.currentTab === 'upcoming' &&
                                <ProgressAppointmentsList
                                    meetings={this.state.meetings['upcoming']}
                                    members={this.state.members}
                                    reschedule={this.reschedule}
                                    programs={this.state.programs['upcoming']}
                                    newDesign={true}
                                />
                            }
                            {this.state.currentTab === 'past' &&
                                <ProgressAppointmentsList
                                    meetings={this.state.meetings['past']}
                                    members={this.state.members}
                                    reschedule={this.reschedule}
                                    programs={this.state.programs['past']}
                                    newDesign={true}
                                />
                            }
                            {this.state.currentTab === 'missed' &&
                                <ProgressAppointmentsList
                                    meetings={this.state.meetings['missed']}
                                    members={this.state.members}
                                    reschedule={this.reschedule}
                                    programs={[]}
                                    newDesign={true}
                                />
                            }
                        </Container>
                        <SchedulingRescheduleCancel
                            hideModal={this.hideRescheduler}
                            onExecuteCancel={this.executeCancel}
                            onExecuteReschedule={this.executeReschedule}
                            sessionSelected={this.state.selected}
                            showModal={this.state.showRescheduler}
                        />
                    </>
                )
            }
        }
    }
}

const mapStateToProps = state => ({
    members: state.consumer.team.team.get("members"),
    meetings: state.consumer.appointments.meetings.get("meetings"),
    meetingsFetched: state.consumer.appointments.meetings.get("meetingsFetched"),
    meetingsChanged: state.consumer.appointments.meetings.get("meetingsChanged"),
    myGroupPrograms: state.consumer.programs.groupPrograms.get("mySubscribedGroupPrograms")
});

const mapDispatchToProps = dispatch => ({
    tryGetAllTeamMembers: () => dispatch(tryGetAllTeamMembers()),
    tryGetTeamMemberMeetings: () => dispatch(tryGetTeamMemberMeetings()),
    tryGetMySubscribedGroupPrograms: () => dispatch(tryGetMySubscribedGroupPrograms()),
    setTeamMemberMeetingsChanged: (datetime) => dispatch(setTeamMemberMeetingsChanged(datetime)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProgressAppointments));
