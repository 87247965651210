import styled from "styled-components";
import {FontSubtitle28} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";

export const PageTitle = styled(FontSubtitle28)`
    text-align: center;
`;
export const Extra54px = styled.div`
    height: 24px;
    width: 100%;
`;
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-around;
    margin: auto auto 100px auto;
    max-width: 450px;
    padding-bottom: 10px;
`;
export const BookButton = styled.div`
    width: fit-content;
    cursor: pointer;
    margin: 20px auto 0px auto;
`;
export const PillBar = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
`;
// TODO: change color
export const Pill = styled.div`
    cursor: pointer;
    padding: 5px 10px;
    text-align: center;
    ${props => props.active === false} {
        background-color: ${colors.backgroundColor4};
        border-radius: 25px;
    }
`;
