import React from 'react';
import styled from 'styled-components';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import moment from 'moment/moment';
import {v4 as uuid4} from 'uuid';


import { colors } from './colors';
import {FontBody16, FontSubtitle21} from './fonts';
import {Checkbox, DropdownInput, MenuInput, ReadOnlyPhoneInputNoStyle} from './inputs';

export const InlineFonts = {
    "FontHeader16": {fontFamily: "inherit", fontSize: "16px", lineHeight: "24px"}
};

const TitleSection = styled.div`
    margin-bottom: 25px;
`;
const Title = styled.div`
    margin-bottom: 5px;
`;
const SubTitle = styled.div`
    color: ${colors.secondary100};
`;
const TableResponsive = styled.div`
    & table {
        width: 100%;
        text-align: left;
        border-collapse: collapse;
        background-color: ${colors.white};
        border-radius: 4px;
        margin-bottom: 50px;
        & thead {
            background-color: ${colors.border30};
            height: 50px;
            border-bottom: 2px solid ${colors.border100};
            @media screen and (max-width: 640px) {
                display: none;
            }
        }
        & tr {
            border-right: 0px solid transparent;
            border-left: 0px solid transparent;
            border-top: 0px solid transparent;
            padding: 20px;
            border-bottom: 1px solid ${colors.border100};
            & .tdBefore {
                font-weight: 500;
                @media screen and (max-width: 460px) {
                    left: 0px;
                }
            }
        }
        & th {
            padding: 14px 10px 12px 20px;
            color: ${colors.secondary100};
            font-family: GraphicWebMedium;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
        }
        & td {
            padding: 21px 10px 20px 20px;
            font-family: GraphicWebRegular;
            font-size: 16px;
            line-height: 22px;
            color: ${colors.primary70};
            @media screen and (max-width: 640px) {
                padding: 10px 0px;
                & .tdBefore {
                    color: ${colors.secondary100};
                    font-family: GraphicWebMedium;
                }
            }
            @media screen and (max-width: 460px) {
                & .tdBefore {
                    overflow-wrap: normal !important;
                }
            }
        }
    }
`;


const TableNonResponsive = styled.table`
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    background-color: ${colors.white};
    border-radius: 4px;
    margin-bottom: 50px;
`;
const TheadNonResponsive = styled.thead`
    height: 50px;
    border-bottom: 2px solid ${colors.border100};
`;
const TbodyNonResponsive = styled.tbody`
    height: 64px;
`;
const TrNonResponsive = styled.tr`
    border-bottom: 1px solid ${colors.border100};
`;
const ThNonResponsive = styled.th`
    padding: 14px 10px 12px 20px;
    font-weight: 500;
    color: ${colors.secondary100};
    @media screen and (max-width: 460px) {
        padding: 14px 5px 12px 5px;
    }
`;
const TdNonResponsive = styled.td`
    padding: 21px 10px 20px 20px;
    @media screen and (max-width: 460px) {
        padding: 21px 5px 20px 5px;
    }
`;

const TDLink = styled.div`
    color: ${colors.primary100};
    cursor: pointer;
`;
const Center = styled.div`
    text-align: center;
    @media screen and (max-width: 640px) {
        text-align: left;
    }
`;

export const TableWrapper = ({ responsive, title, subtitle, tableData }) => {
    if(responsive) {
        return (<TableResponsive><TableContainer
            title={title}
            subtitle={subtitle}
            tableData={tableData}
            TABLE={Table}
            THEAD={Thead}
            TBODY={Tbody}
            TR={Tr}
            TH={Th}
            TD={Td}
        /></TableResponsive>)
    } else {
        return (<FontBody16><TableContainer
            title={title}
            subtitle={subtitle}
            tableData={tableData}
            TABLE={TableNonResponsive}
            THEAD={TheadNonResponsive}
            TBODY={TbodyNonResponsive}
            TR={TrNonResponsive}
            TH={ThNonResponsive}
            TD={TdNonResponsive}
        /></FontBody16>)
    }
};

const TableContainer = ({ title, subtitle, tableData, TABLE, THEAD, TBODY, TR, TH, TD }) => (
    <>
        <TitleSection>
            <Title><FontSubtitle21>{title}</FontSubtitle21></Title>
            <SubTitle><FontBody16>{subtitle}</FontBody16></SubTitle>
        </TitleSection>
        <TABLE>
            <THEAD><TR>
                {tableData.title.map((item, index) => {
                    if (tableData.format[index]["element"] === "menu") {
                        return(<TH key={index}><Center>{item}</Center></TH>)
                    } else {
                        return(<TH key={index}>{item}</TH>)
                    }
                })}
            </TR></THEAD>
            <TBODY>
                {tableData.data.map((row, rowIndex) => (
                    <TR key={rowIndex}>
                        {tableData.order.map((col, colIndex) => {
                            if (row[col] === null) {
                                if (tableData.format[colIndex]["element"] === "dayspast") {
                                    return (<TD key={colIndex} style={tableData.styles[colIndex]}>{row.canceledFirstCall ? "Trial canceled" : tableData.format[colIndex]["ifNull"]}</TD>)
                                } else {
                                    return (<TD key={colIndex} style={tableData.styles[colIndex]}>{tableData.format[colIndex]["ifNull"]}</TD>)
                                }
                            } else {
                                if (tableData.format[colIndex]["element"] === "link") {
                                    return (
                                        <TD key={colIndex}>
                                            <TDLink onClick={() => {
                                                tableData.actions[colIndex]["action"](tableData.actions[colIndex]["passToAction"], row)
                                            }} style={tableData.styles[colIndex]}>{row[col]}</TDLink>
                                        </TD>
                                    )
                                } else if (tableData.format[colIndex]["element"] === "linkIfDataAllows") {
                                    if(!row.linkOnTable) {
                                        return (<TD key={colIndex} style={tableData.styles[colIndex]}>{row[col]}</TD>)
                                    } else {
                                        return (
                                            <TD key={colIndex}>
                                                <TDLink onClick={() => {
                                                    tableData.actions[colIndex]["action"](tableData.actions[colIndex]["passToAction"], row)
                                                }} style={{"color": colors.action100, ...InlineFonts.FontHeader16, ...tableData.styles[colIndex]}}>{row[col]}</TDLink>
                                            </TD>
                                        )
                                    }
                                } else if (tableData.format[colIndex]["element"] === "text") {
                                    return (<TD key={colIndex} style={tableData.styles[colIndex]}>{row[col]}</TD>)
                                } else if (tableData.format[colIndex]["element"] === "phone") {
                                    return (<TD key={colIndex} style={tableData.styles[colIndex]}><ReadOnlyPhoneInputNoStyle id={uuid4()} value={row[col]} /></TD>)
                                } else if (tableData.format[colIndex]["element"] === "textUppercase") {
                                    if(row[col] === "wmc") {
                                        return (<TD key={colIndex} style={tableData.styles[colIndex]}>Me</TD>)
                                    } else {
                                        return (<TD key={colIndex} style={tableData.styles[colIndex]}>{row[col].charAt(0).toUpperCase()+row[col].slice(1)}</TD>)
                                    }
                                } else if (tableData.format[colIndex]["element"] === "sessionStart") {
                                    return (<TD key={colIndex} style={tableData.styles[colIndex]}>{moment(row[col].start).format("MMM. D, YYYY [at] h:mm A z")}</TD>)
                                } else if (tableData.format[colIndex]["element"] === "datetime") {
                                    return (<TD key={colIndex} style={tableData.styles[colIndex]}>{moment(row[col]).format("MMM. D, YYYY [at] h:mm A z")}</TD>)
                                } else if (tableData.format[colIndex]["element"] === "dayspast") {
                                    return (<TD key={colIndex} style={tableData.styles[colIndex]}>{moment(row[col]).fromNow()}</TD>)
                                } else if (tableData.format[colIndex]["element"] === "dayspastStart") {
                                    return (<TD key={colIndex} style={tableData.styles[colIndex]}>{moment(row[col].end).fromNow()}</TD>)
                                } else if (tableData.format[colIndex]["element"] === "checkbox") {
                                    return (<TD key={colIndex} style={tableData.styles[colIndex]}><Checkbox action={tableData.actions[colIndex]["action"]} question={col} passToAction={row.id} checked={row[col]}/></TD>)
                                } else if (tableData.format[colIndex]["element"] === "dropdown") {
                                    return (<TD key={colIndex} style={tableData.styles[colIndex]}><DropdownInput title={null} options={tableData.actions[colIndex]["extra"]} id={colIndex+'Action'} onChange={() => tableData.actions[colIndex]["action"](row)} placeholder={null} value={null}/></TD>)
                                } else if (tableData.format[colIndex]["element"] === "menu") {
                                    return (<TD key={colIndex} style={tableData.styles[colIndex]}><MenuInput actions={tableData.actions[colIndex]["action"]} passToProps={row} labels={tableData.actions[colIndex]["extra"]}/></TD>)
                                } else if (tableData.format[colIndex]["element"] === "menuarray") {
                                    return (<TD key={colIndex} style={tableData.styles[colIndex]}><MenuInput actions={tableData.actions[colIndex]["action"]} passToProps={{"history": tableData.actions[colIndex]["passToAction"], "data": row}} labels={tableData.actions[colIndex]["extra"]}/></TD>)
                                }
                            }
                        })}
                    </TR>
                ))}
            </TBODY>
        </TABLE>
    </>
);
//"2019-12-24T10:00:00+00:00"