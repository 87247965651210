import React from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import styled from "styled-components";

import {FontBody16, FontHeader12, FontHeader16} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";

const MainSettings = styled.div`
    margin-bottom: 40px;
`;
const NavSideSettings = styled.div`
    width: 189px;
    background-color: ${colors.background100};
    padding: 40px 30px;
    @media screen and (max-width: 1088px) {
        display: none;
    }
    display: inline-block;
`;
const NavSettingsTitle = styled.div`
    margin-bottom: 10px;
    color: ${colors.secondary70};
    padding-left: 20px;
`;
const NavSettingsHomeButton = styled.div`
    padding-left: 20px;
    cursor: pointer;
    & a {
        text-decoration: none;
        color: ${colors.secondary100};
    }
`;
const ButtonSettings = styled.div`
    ${props => props.status === 'inactive'} {background-color: ${colors.border100};}
    color: ${colors.secondary100};
    padding: 8px 20px;
    cursor: pointer;
    border-radius: 4px;
`;
const ContainerFixed = styled.div`
    width: 189px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    background-color: ${colors.background100};
    border-right: 1px solid ${colors.border100};
    padding: 40px 30px;
    @media screen and (max-width: 1088px) {
        display: none;
    }
`;
const Container = styled.div`
    height: auto;
    position: relative;
`;

const NavSection = ({d, isWMC, user, location}) => (
    <MainSettings>
        {d.title !== null && <NavSettingsTitle><FontHeader12>{d.title}</FontHeader12></NavSettingsTitle>}
        {d.options.map((p, index) => {
            let status = "inactive";
            if(location.pathname.split("/")[2] === p.href.split("/")[2]) {status = "active"}
            if(isWMC && p.showWMC) {
                return(<Link key={index} to={p.href}><ButtonSettings status={status}><FontBody16>{p.label}</FontBody16></ButtonSettings></Link>)
            } else if(!isWMC && p.showCoach) {
                if(p.showCoachCategoryIds === "all") {
                    return(<Link key={index} to={p.href}><ButtonSettings status={status}><FontBody16>{p.label}</FontBody16></ButtonSettings></Link>)
                } else {
                    const overlap = p.showCoachCategoryIds.filter(value => {
                        const userCategoryIds = user.get("expertCategories").map(c => {return(c.get("categoryId"))});
                        return userCategoryIds.includes(value)
                    });
                    if(overlap.length > 0) {
                        return(<Link key={index} to={p.href}><ButtonSettings status={status}><FontBody16>{p.label}</FontBody16></ButtonSettings></Link>)
                    } else {
                        return null
                    }
                }
            } else {
                return null
            }
        })}
    </MainSettings>
);

const NavPrimarySideSettings = ({ data, location, isWMC, user }) => (
    <NavSideSettings>
        <Container>
            {/*<MainSettings>*/}
            {/*    <NavSettingsHomeButton><Link to={"/verify"}><FontHeader16>{"<- Home"}</FontHeader16></Link></NavSettingsHomeButton>*/}
            {/*</MainSettings>*/}
            {data.settings.map((d, dIndex) => {
                if(isWMC && d.showWMC) {
                    return (<NavSection key={dIndex} d={d} isWMC={isWMC} user={user} location={location} />)
                } else if(!isWMC && d.showCoach) {
                    if(d.showCoachCategoryIds === "all") {
                        return (<NavSection key={dIndex} d={d} isWMC={isWMC} user={user} location={location} />)
                    } else {
                        const overlap = d.showCoachCategoryIds.filter(value => {
                            const userCategoryIds = user.get("expertCategories").map(c => {return(c.get("categoryId"))});
                            return userCategoryIds.includes(value)
                        });
                        if(overlap.length > 0) {
                            return (<NavSection key={dIndex} d={d} isWMC={isWMC} user={user} location={location} />)
                        } else {
                            return null
                        }
                    }
                } else {
                    return null
                }
            })}
        </Container>
        <ContainerFixed />
    </NavSideSettings>
);

const mapStateToProps = state => ({
    isWMC: state.common.wmc.get("isWMC"),
    user: state.common.user
});

export default connect(mapStateToProps, null)(withRouter(NavPrimarySideSettings));
