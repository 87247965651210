import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

/* Components */
import {colors} from '../../../common/components/colors';
import {images} from '../../../common/components/images';
import {FontTitle40, FontTitle20, FontBody18} from '../../../common/components/fonts';

const DropdownTitle = styled.div`
    padding: 15px 0;
    color: ${colors.primary100};
    cursor: pointer !important;
    ${props => props.lastRecord !== false} {
      border-bottom: 1px solid ${colors.white};
    }
    margin: 0 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
`;
const Box = styled.div`
    width: 11px;
    height: 11px;
    padding: 8px;
    min-height: 11px;
    min-width: 11px;
`;
const Icon = styled.img`
  width:100%;
  height:100%;
  object-fit:contain;
`;
const Divider = styled.div`
    background-color: white;
    margin: 0 20px 20px 20px;
    padding: 20px;
    border-radius: 0 20px 0 20px;
`;
const LinkStyle = styled.div`
    display: inline-block;
    & a {
        color: ${colors.action100};
        text-decoration: underline !important;
    }
`;
const PageStyle = styled.span`
    display: inline-block;
    word-break: break-word;
    & div {
        display: inline-block;
        color: ${colors.action100};
        text-decoration: underline !important;
        cursor: pointer;
        word-break: break-word;
    }
`;

class OneFAQModal extends React.Component {
    state={"expanded": false}

    handleClick = e => {this.setState({expanded: !this.state.expanded})};

    htmlParserTransform = (node, index) => {
        if (node.type === "tag" && node.name === "a") {
            const {href} = node.attribs;
            if(node.attribs["data-link-type"] === 'external') {
                return (<LinkStyle key={index}><FontBody18><a target={node.attribs["target"]} href={href}>{node.attribs["data-label"]}</a></FontBody18></LinkStyle>)
            } else if(node.attribs["data-link-type"] === 'internal') {
                return (<LinkStyle key={index}><FontBody18 onClick={() => {return this.props.action(null, false)}}><Link to={href}>{node.attribs["data-label"]}</Link></FontBody18></LinkStyle>)
            }
        } else if(node.type === "tag" && node.name === "span") {
            if(node.attribs["data-link-type"] === 'page') {
                return (<PageStyle key={index} onClick={() => {return this.props.action(node.attribs["data-label-action-props"], true)}}><FontBody18>{node.attribs["data-label"]}</FontBody18></PageStyle>)
            }
        } else if(node.type === "tag" && node.name === "br") {
            return(<br />)
        }
    };

    render() {
        return(
            <>
                <DropdownTitle onClick={this.handleClick} lastRecord={this.props.lastRecord || this.state.expanded}>
                    <FontBody18>{this.props.title}</FontBody18>
                    <Box>
                        {this.state.expanded
                            ? <Icon src={images.chevronDown} alt={">"}/>
                            : <Icon src={images.chevronRightDark} alt={">"}/>
                        }
                    </Box>
                </DropdownTitle>
                {this.state.expanded &&
                    <Divider><FontBody18>{ReactHtmlParser(this.props.body, {transform: this.htmlParserTransform})}</FontBody18></Divider>
                }
            </>
        )
    }
}


const Section = styled.div`
    margin-bottom: 40px;
    background-color: ${colors.backgroundColor1};
    border-radius: 20px 0 20px 0;
    padding: 10px;
    margin-right: 20px;
`;
const SectionTitle = styled(FontTitle20)`
    padding: 15px 20px;
`;

const SectionTop = styled.div`
  margin-top: 40px;
  overflow-y: auto;
  height: calc(100% - 132px);
`;

const TitleFAQ = styled(FontTitle40)`
  margin-Bottom: 40px;
`;

const HelpFAQsModal = ({ data, action }) => (
    <SectionTop>
        <TitleFAQ spaced={false}>Common Questions</TitleFAQ>
        {data.map(section => {
            return(
                <Section key={section.id}>
                    {section.title !== "" && <SectionTitle>{section.title}</SectionTitle>}
                    {section.faqs.map((question, index) => {
                        return(
                            <div key={question.id}>
                                <OneFAQModal title={question.title} body={question.body} action={action} lastRecord={index === (section.faqs.length-1)}/>
                            </div>
                        )
                    })}
                </Section>
            )
        })}
    </SectionTop>
);
export default HelpFAQsModal;