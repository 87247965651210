import { combineReducers } from 'redux';

/* Common */
import coachingReducer from './store/coaching';

const careReducers = combineReducers({
    coaching: coachingReducer,
});

export default careReducers;
