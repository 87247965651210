import React from 'react';
import styled from 'styled-components';
import {connect} from "react-redux";

/* Components */
import {FontBody14, FontTitle21} from '../../../common/components/fonts';
import {colors} from '../../../common/components/colors';

/* Static */
import {images} from '../../../common/components/images';

const Row = styled.div`
    margin: 0px 0px 10px 0px;
    @media screen and (max-width: 460px) {
        text-align: center;
    }
`;
// const Row = styled.div`
//     margin: 30px 0px;
//     border-bottom: 2px solid ${colors.border100};
//     @media screen and (max-width: 460px) {
//         text-align: center;
//     }
// `;
const Col = styled.div`
    display: inline-block;
    width: 180px;
    margin-right: 30px;
    vertical-align: top;
    margin-bottom: 20px;
    text-align: left;
    @media screen and (max-width: 1088px) {
        margin-right: 20px;
    }
    @media screen and (max-width: 640px) {
        margin-right: 12px;
    }
    @media screen and (max-width: 460px) {
        margin-right: 10px;
    }
`;
const Image = styled.img`
    border-radius: 4px;
    vertical-align: top;
    width: 180px;
    height: 120px;
`;
const List = styled.div`
    padding: 10px;
    width: calc(100% - 20px);
`;
const Title = styled.div`
    Margin-bottom: 15px;
`;
const Category = styled.div`
    margin-bottom: 10px;
    color: ${colors.secondary100};
`;

const OneCategory = ({category}) => (
    <Col>
        <Image src={category.image} alt={category.name} />
        <List>
            <Title><FontTitle21>{category.name+" ("+category.count+")"}</FontTitle21></Title>
            {category.subcategories.map(s => (
                <Category key={s.name}><FontBody14>{s.name+" ("+s.count+")"}</FontBody14></Category>
            ))}
        </List>
    </Col>
);

class TeamCategories extends React.Component {
    state={"isLoading": true, "categories": []};

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        this.setState({"categories": []})
    }

    init = async () => {
        const createCategories = [
            {"name": "Coaching", "image": images.teamCategoryCoaching, "count": 0, "subcategories": [], "id": 1},
            {"name": "Planning", "image": images.teamCategoryPlanning, "count": 0, "subcategories": [], "id": 2},
            {"name": "Accounting", "image": images.teamCategoryAccounting, "count": 0, "subcategories": [], "id": 3},
            {"name": "Legal", "image": images.teamCategoryLegal, "count": 0, "subcategories": [], "id": 4},
        ];
        const financialCategories = [
            {"name": "Financial Coaches", "id": [1, 4], "parentId": 1},
            {"name": "Career Coaches", "id": [], "parentId": 1},
            {"name": "Life Coaches", "id": [], "parentId": 1},
            {"name": "Financial Planners", "id": [], "parentId": 2},
            {"name": "Insurance Agents", "id": [], "parentId": 2},
            {"name": "Accountants", "id": [], "parentId": 3},
            {"name": "Bookkeepers", "id": [], "parentId": 3},
            {"name": "Estate Attorneys", "id": [], "parentId": 4},
            {"name": "Divorce Attorneys", "id": [], "parentId": 4},
            {"name": "Corporate Attorneys", "id": [], "parentId": 4},
        ];
        let eachCategory = await Promise.all(financialCategories.map(async c => {
            let coaches = await Promise.all(this.props.allCoaches.map(async a => {
                let oneCoach = await Promise.all(c.id.map(async i => {
                    return Promise.resolve(a.get("expertCategories").filter(f => {return(f.get("categoryId") === i)}).size);
                }));
                return Promise.resolve(oneCoach.reduce((a, b) => a + b, 0));
            }));
            return Promise.resolve({...c, "count": coaches.reduce((a, b) => a + b, 0)});
        }));
        let allCategories = await Promise.all(createCategories.map(async m => {
            let parentSubcategories = m.subcategories;
            let childCount = 0;
            await Promise.all(eachCategory.map(async b => {
                if(m.id === b.parentId) {
                    parentSubcategories.push(b);
                    childCount = childCount + b.count;
                }
            }));
            return Promise.resolve({...m, "count": childCount, "subcategories": parentSubcategories});
        }));
        this.setState({"categories": allCategories, "isLoading": false})
    };

    render() {
        return(
            <Row>
                {this.state.categories.map(c => (<OneCategory key={c.name} category={c} />))}
            </Row>
        )
    }
}


const mapStateToProps = state => ({
    allCoaches: state.enterprise.team.coaching.get("coachesAssignedToWMC"),
});

export default connect(mapStateToProps, null)(TeamCategories);
