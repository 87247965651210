import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

import {colors} from '../../../common/components/colors';
import {FontBody16, FontTitle21, FontBody21, FontHeader18} from '../../../common/components/fonts';

import {CaretDownOutlined, CaretRightOutlined} from '@ant-design/icons';
import {LogoLoading} from "../../../common/components/loading";



const DropdownBox = styled.div`
    -webkit-box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    background-color: ${colors.white};
    border-radius: 10px;
    margin: ${props => props.marginTopBottom} 0px ${props => props.marginTopBottom} 0px;
`;
const DropdownTitle = styled.div`
    padding: 15px 20px;
    color: ${colors.primary100};
    cursor: pointer !important;
`;
const Title = styled.div`
    display: inline-block;
    vertical-align: top;
    text-align: left;
    width: calc(100% - 16px);
`;
const Icon = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-top: 3px;
    text-align: right;
`;
const Divider = styled.div`
    border-top: 1px solid ${colors.border100};
    padding: 15px 20px;
`;
const LinkStyle = styled.div`
    display: inline-block;
    & a {
        color: ${colors.action100};
        text-decoration: underline !important;
    }
`;
const LoadingCenter = styled.div`
    margin: 60px auto;
`;

class OneFAQ extends React.Component {
    constructor(props) {
        super(props);
        this.state={expanded: false}
    }

    handleClick = e => {this.setState({expanded: !this.state.expanded})};

    htmlParserTransform = (node, index) => {
        if (node.type === "tag" && node.name === "a") {
            const {href} = node.attribs;
            if(node.attribs["data-link-type"] === 'external') {
                return (<LinkStyle key={index}><FontBody16><a target={node.attribs["target"]} href={href}>{node.attribs["data-label"]}</a></FontBody16></LinkStyle>)
            } else if(node.attribs["data-link-type"] === 'internal') {
                return (<LinkStyle key={index}><FontBody16><Link to={href}>{node.attribs["data-label"]}</Link></FontBody16></LinkStyle>)
            }
        } else if(node.type === "tag" && node.name === "br") {
            return(<br />)
        }
    };

    render() {
        return(
            <>
                {this.state.expanded
                    ?
                    <DropdownBox marginTopBottom={"40px"}>
                        <DropdownTitle onClick={this.handleClick}>
                            <Title><FontBody21>{this.props.title}</FontBody21></Title>
                            <Icon>
                                {this.state.expanded
                                    ? <CaretDownOutlined/>
                                    : <CaretRightOutlined/>
                                }
                            </Icon>
                        </DropdownTitle>
                        <Divider><FontBody16>{ReactHtmlParser(this.props.body, {transform: this.htmlParserTransform})}</FontBody16></Divider>
                    </DropdownBox>
                    :
                    <DropdownBox marginTopBottom={"5px"}>
                        <DropdownTitle onClick={this.handleClick}>
                            <Title><FontBody16>{this.props.title}</FontBody16></Title>
                            <Icon>
                                {this.state.expanded
                                    ? <CaretDownOutlined/>
                                    : <CaretRightOutlined/>
                                }
                            </Icon>
                        </DropdownTitle>
                    </DropdownBox>
                }
            </>
        )
    }
}


const Section = styled.div`
    margin-bottom: 40px;
`;

class HelpFAQ extends React.Component {
    state={"isLoading": true, "wmcs": [], "data": []};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.setState({
            "wmcs": await Promise.all(this.props.wmcs.map(w => {return(w.get("id"))})),
            "data": this.props.data,
            "isLoading": false
        });
        console.log(this.state);
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    {this.state.data.map(section => {
                        const overlap = section.showWMCPartners === null ? true : section.showWMCPartners.filter(value => this.state.wmcs.includes(value)).length > 0;
                        if(overlap) {
                            return(
                                <Section key={section.id}>
                                    <FontTitle21>{section.title}</FontTitle21>
                                    {section.faqs.map(question => {
                                        return(
                                            <div key={question.id}>
                                                <OneFAQ title={question.title} body={question.body} />
                                            </div>
                                        )
                                    })}
                                </Section>
                            )
                        } else {
                            return null
                        }
                    })}
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    wmcs: state.enterprise.partners.partners.get("wmcsAssignedToCoach")
});

export default connect(mapStateToProps, null)(HelpFAQ);
