import { Map } from 'immutable';

/* Immutable */

const IMAGES_UPLOADING = 'IMAGES_UPLOADING';
const IMAGES_STORE_RESET = 'IMAGES_STORE_RESET';


/* Actions */

export const setImagesUploading = (status) => ({'type': IMAGES_UPLOADING, 'status': status});
export const setImagesStoreReset = () => ({'type': IMAGES_STORE_RESET});

/* Initial State */

const initialState = Map({
    imagesUploading: false,
});

/* Reducer */

const imagesReducer = (state=initialState, action) => {
    if (action.type === IMAGES_UPLOADING) {
        return state.merge({'imagesUploading': action.status});
    } else {
        return state;
    }
};

export default imagesReducer;
