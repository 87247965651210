import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';

import {colors} from './colors';

/* Icons */
import {images} from './images';
import {fileTypeImage} from './filetypes';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    height: '100%',
    width: 'auto'
};

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return colors.action100;
    }
    return colors.primary30;
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  &:hover {
      border-color: ${colors.action100};
      cursor: pointer;
  }
`;

export function UploadFiles(props) {
    const [files, setFiles] = useState([]);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        multiple: true,
        onDrop: async acceptedFiles => {
            await props.clearSelected();
            setFiles(acceptedFiles.map(file => {
                let newFile = Object.assign(file, {preview: URL.createObjectURL(file)});
                props.handleSelected(newFile);
                return(newFile);
            }));
        }
    });

    const thumbs = files.map(file => {
        return(
            <div style={thumb} key={file.name}>
                <div style={thumbInner}>
                    <img
                        src={fileTypeImage(file, false)}
                        style={img}
                        alt={""}
                    />
                    {file.name}
                </div>
            </div>
        )
    });

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <section className="container">
            <Container {...getRootProps({isDragActive, className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>Drop files here, or click to select some</p>
            </Container>
            <aside style={thumbsContainer}>
                {thumbs}
            </aside>
        </section>
    );
}


export function UploadFolder(props) {
    const [files, setFiles] = useState([]);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        multiple: true,
        onDrop: async acceptedFiles => {
            await props.clearSelected();
            setFiles(acceptedFiles.map(file => {
                let newFile = Object.assign(file, {preview: URL.createObjectURL(file)});
                props.handleSelected(newFile);
                return(newFile);
            }));
        }
    });

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <section className="container">
            <Container {...getRootProps({isDragActive, className: 'dropzone'})}>
                <input {...getInputProps()} directory={""} webkitdirectory={""} type={"file"} />
                <p>Drop a folder here, or click to select one</p>
            </Container>
            <aside style={thumbsContainer}>
                {files.length > 0 &&
                    <div style={thumb}>
                        <div style={thumbInner}>
                            <img
                                src={images.vaultFolderPrivate}
                                style={img}
                                alt={""}
                            />
                        </div>
                    </div>
                }
            </aside>
        </section>
    );
}
