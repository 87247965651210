import { combineReducers } from 'redux';

/* Reducers */
import clientsDetailReducer from './clientsdetail';
// import clientsNotesReducer from "./clientsnotes";
// import clientsAboutReducer from "../../deprecated/enterprise/store/clientsabout";
// import clientsBalancesReducer from '../../deprecated/enterprise/store/clientsbalances';
import clientsProgramReducer from './clientsprogram';
import clientsListReducer from "../clients/store/clients";
// import clientsGoalsReducer from "../../deprecated/enterprise/store/clientsgoals";
import availabilityReducer from "../availability/store/manual";
import recurringAvailabilityReducer from "../settings/recurring/store/recurring";
import sessionsReducer from "../meetings/store/sessions";
import settingsEnterpriseReducer from '../settings/store/settings';
import clientsCurriculumReducer from "./clientscurriculum";
import prospectsReducer from './prospects';
// import journeyTasksReducer from '../../deprecated/common/store/tasks';
// import toDoReducer from "../../deprecated/common/store/todos";
// import appointmentReducer from "../../deprecated/common/store/appointment";
// import expertProfileReducer from "../../deprecated/common/store/expertprofile";
import clientsActivityReducer from './clientsactivity';
// import expertMWPReducer from '../../deprecated/enterprise/store/mwp';
import settingsUsersReducer from "../settings/store/settingsusers";
import contactsReducer from './contacts';
import teamReducer from '../team/reducers';
import listingsReducer from "../listings/reducers";
import schedulerReducer from "../settings/scheduler/reducers";
import shopReducer from "../shop/reducers";
import profileReducer from "../profile/reducers";
import supportReducer from "../support/reducers";
import billingsReducer from "../billing/reducers";
import partnershipReducer from "../partnerships/reducers";
import smsReducer from "../sms/reducers";
import enailReducer from "../email/reducers";
import meetingsReducer from "../meetings/reducers";
import commentsReducer from "../comments/reducers";
import notesReducer from "../notes/reducers";
import settingsReducer from "./settings";
import homepageReducer from '../homepage/reducers';
import formsReducer from "../forms/reducers";
import curriculumReducer from "../curriculum/reducers";
import toolTipReducer from "../firm/tooltip/store/tooltip";

const enterpriseReducer = combineReducers({
    clientsDetail: clientsDetailReducer,
    // clientsNotes: clientsNotesReducer,
    // clientsAbout: clientsAboutReducer,
    clientsCurriculum: clientsCurriculumReducer,
    clientsProgram: clientsProgramReducer,
    // clientsBalances: clientsBalancesReducer,
    clientsList: clientsListReducer,
    // clientsGoals: clientsGoalsReducer,
    availability: availabilityReducer,
    recurring: recurringAvailabilityReducer,
    sessions: sessionsReducer,
    settings: settingsEnterpriseReducer,
    prospects: prospectsReducer,
    clientsActivity: clientsActivityReducer,
    // expertMWP: expertMWPReducer,
    settingsUsers: settingsUsersReducer,
    settingsPersonal: settingsReducer,
    contacts: contactsReducer,
    team: teamReducer,
    services: listingsReducer,
    scheduler: schedulerReducer,
    shop: shopReducer,
    profile: profileReducer,
    billing: billingsReducer,
    support: supportReducer,
    // journeyTasks: journeyTasksReducer("enterpriseTasks"),
    // toDos: toDoReducer("enterpriseToDos"),
    // appointment: appointmentReducer("enterpriseAppointments"),
    // expertProfile: expertProfileReducer("enterpriseExpertProfile"),
    partners: partnershipReducer,
    sms: smsReducer,
    email: enailReducer,
    meetings: meetingsReducer,
    comments: commentsReducer,
    notes: notesReducer,
    homepage: homepageReducer,
    forms: formsReducer,
    curriculum: curriculumReducer,
    tooltip: toolTipReducer,
});

export default enterpriseReducer;
