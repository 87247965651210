import { Map, fromJS } from 'immutable';

/* Immutable */

const SHOP_ITEM_EXPERIENCE = 'SHOP_ITEM_EXPERIENCE';
const SHOP_ITEM_EDUCATION = 'SHOP_ITEM_EDUCATION';
const SHOP_ITEM_LICENSE = 'SHOP_ITEM_LICENSE';
const SHOP_ITEM_STORE_RESET = 'SHOP_ITEM_STORE_RESET';

/* Actions */

export const setShopItemExperience = (item) => ({'type': SHOP_ITEM_EXPERIENCE, 'item': item});
export const setShopItemEducation = (item) => ({'type': SHOP_ITEM_EDUCATION, 'item': item});
export const setShopItemLicense = (item) => ({'type': SHOP_ITEM_LICENSE, 'item': item});
export const setShopItemStoreReset = () => ({'type': SHOP_ITEM_STORE_RESET});

/* Initial State */

const initialState = Map({
    experience: Map(),
    education: Map(),
    license: Map()
});

/* Reducer */

const profileItemsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOP_ITEM_EXPERIENCE:
            return state.merge({'experience': fromJS(action.item)});
        case SHOP_ITEM_EDUCATION:
            return state.merge({'education': fromJS(action.item)});
        case SHOP_ITEM_LICENSE:
            return state.merge({'license': fromJS(action.item)});
        case SHOP_ITEM_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default profileItemsReducer;
