import React from 'react';
import {connect} from "react-redux";

/* Components */
import {SearchInputUncontrolled} from "../../../components/inputs";
import { LogoLoading } from '../../../components/loading';

/* Store */
import { setDriveSearchResult, setDriveSearchString } from "../store/filevault";

const whoOptions = (who, isWMC) => {
    return who.map(w => {
        return({
            ...w,
            type: w['driveType'] ? 'Drive' : w['files'] && w['folders'] ? 'Folder' : 'File',
            label: w['name'],
            isArchived: false,
        })
    });
};

class VaultSearch extends React.Component {
    state = {
        searchResults: [],
        isLoading: true,
    }

    static defaultProps = { driveList: [] };
    
    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            isLoading: false,
        })
    }

    handleReturnResult = async (e) => {
        this.props.setDriveSearchResult(e);
    }

    saveSearchString = async (e) => {
        this.props.setDriveSearchString(e);
    }

    // Utility function that takes an array of Drives (with nested files and folders) and returns an array of Drives, Folders, and Files at the same level
    flatten = (nestedDriveList) => {
        let everythingList = []
        for (const node of [...nestedDriveList]) {
            everythingList.push(node);
            const theFiles = node['files']
            everythingList.push(...theFiles);
            if (node['driveId']) {
                node['folders'].forEach(folder => { folder['driveId'] = node['driveId'] })
            }
            everythingList.push(...this.flatten(node['folders']))
        }
        return everythingList;
    }

    render() {
        if(this.state.isLoading) { 
            return (<LogoLoading />);
        }
        return (
            <>
                <SearchInputUncontrolled 
                    options={whoOptions(this.flatten(this.props.driveList.toJS()))}
                    id={'search-vault'}
                    placeholder={'Search'}
                    value={''}
                    shouldShowResults={false}
                    onReturnResults={this.handleReturnResult}
                    onSearchMade={this.saveSearchString}
                />
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    setDriveSearchResult: (results) => dispatch(setDriveSearchResult(results)),
    setDriveSearchString: (searchString) => dispatch(setDriveSearchString(searchString))
});

export default connect(null, mapDispatchToProps)(VaultSearch);
