import {FontBody16, FontTitle21} from "../../../common/components/fonts";
import {SubmitButton} from "../../../common/components/inputs";
import {colors} from "../../../common/components/colors";
import React from "react";
import {withRouter} from 'react-router-dom';
import styled from "styled-components";

const Page = styled.div`
    ${props => props.status !== false} {display: none;}
    ${props => props.status !== true} {display: block;}
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
    background-color: ${colors.background100};
`;
const Modal = styled.div`
    -webkit-box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    background-color: ${colors.white};
    border-radius: 4px;
    padding: 50px 50px 30px 50px;
    color: ${colors.primary100};
    max-width: 320px;
    z-index: 5;
    position: relative;
    margin: 60px auto 0px auto;
    text-align: center;
    @media screen and (max-width: 460px) {
        margin: 40px 20px 20px 20px;
        padding: 30px 20px;
    }
`;
const Body = styled.div`
    margin: 20px 0px; 
`;
const NoteActions = styled.div`
    padding-top: 20px;
`;

const MessagesUnableToJoin = ({history}) => (
    <Page >
        <Modal>
            <FontTitle21>Unable to join</FontTitle21>
            <Body><FontBody16>The messenger is not currently available.</FontBody16></Body>
            <NoteActions>
                <div onClick={() => {history.push("/clients/current")}}><SubmitButton backgroundColor={colors.action100} iconComponent={null} color={colors.primary100} label={"Back to dashboard"} /></div>
            </NoteActions>
        </Modal>
    </Page>
);
export const MessagesUnableToJoinWrapper = withRouter(MessagesUnableToJoin);

