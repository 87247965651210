export default {
    "response": [
        {
            "program": "55",
            "courseVideo": "374",
            "courseQuiz": "375"
        },
        {
            "program": "56",
            "courseVideo": "376",
            "courseQuiz": "377"
        },
        {
            "program": "53",
            "courseVideo": "380",
            "courseQuiz": "381"
        },
        {
            "program": "54",
            "courseQuiz": "379",
            "courseVideo": "378"
        },
        {
            "program": "61",
            "courseVideo": "382",
            "courseQuiz": "383"
        },
        {
            "program": "72",
            "courseVideo": "451",
            "courseQuiz": "455"
        },
        {
            "program": "67",
            "courseVideo": "388",
            "courseQuiz": "389"
        },
        {
            "program": "68",
            "courseVideo": "390",
            "courseQuiz": "391"
        },
        {
            "program": "62",
            "courseVideo": "372",
            "courseQuiz": "373"
        },
        {
            "program": "69",
            "courseVideo": "419",
            "courseQuiz": "420"
        },
        {
            "program": "65",
            "courseVideo": "393",
            "courseQuiz": "394"
        },
        {
            "program": "63",
            "courseVideo": "384",
            "courseQuiz": "385"
        },
        {
            "program": "66",
            "courseVideo": "386",
            "courseQuiz": "387"
        },
        {
            "program": "64",
            "courseQuiz": "450",
            "courseVideo": "449"
        },
        {
            "program": "75",
            "courseVideo": "454",
            "courseQuiz": "458"
        },
        {
            "program": "77",
            "courseVideo": "454",
            "courseQuiz": "458"
        },
        {
            "program": "74",
            "courseVideo": "453",
            "courseQuiz": "457"
        },
        {
            "program": "73",
            "courseVideo": "452",
            "courseQuiz": "456"
        }
    ],
    "matchProgramAndVideo": {
        "55": "374",
        "56": "376",
        "53": "380",
        "54": "378",
        "61": "382",
        "72": "451",
        "67": "388",
        "68": "390",
        "62": "372",
        "69": "419",
        "65": "393",
        "63": "384",
        "66": "386",
        "64": "449",
        "75": "454",
        "77": "454",
        "74": "453",
        "73": "452"
    },
    "matchVideoAndQuiz": {
        "374": "375",
        "376": "377",
        "380": "381",
        "378": "379",
        "382": "383",
        "451": "455",
        "388": "389",
        "390": "391",
        "372": "373",
        "419": "420",
        "393": "394",
        "384": "385",
        "386": "387",
        "449": "450",
        "454": "458",
        "453": "457",
        "452": "456"
    },
    "matchQuizAndVideo": {
        "375": "374",
        "377": "376",
        "381": "380",
        "379": "378",
        "383": "382",
        "455": "451",
        "389": "388",
        "391": "390",
        "373": "372",
        "420": "419",
        "394": "393",
        "385": "384",
        "387": "386",
        "450": "449",
        "458": "454",
        "457": "453",
        "456": "452"
    }
}