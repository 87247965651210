import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {
    setIntroduceTokenFetching,
    setIntroduceToken,
    setIntroduceTokenConsuming
}
from "../store/coaching";

/* Static */

export const tryGetIntroduceToken = (prospectId) => {
    return async dispatch => {
        dispatch(setIntroduceTokenFetching(true));
        const token = await axiosReq('apiv1/coach/prospects/get-token/' + prospectId, "GET", {}, {});
        await dispatch(setIntroduceToken(token));
        dispatch(setIntroduceTokenFetching(false));
        return token;
    }
};