import React from "react";
import styled from "styled-components";

/* Components */
import ContactIntro from "../components/contactintro";

/* Containers */
import SchedulerFlow from "../../scheduler/containers/flow";

const Show = styled.div`
    display: ${({ toggle }) => (toggle ? "block" : "none")};
`;
const Wrapper = styled.div`
    margin: 0 auto;
    width: 760px;
    .react-datepicker__week {
        margin: 5px 0;
    }
    @media screen and (max-width: 1680px) {
        width: 640px !important;
    }
    @media screen and (max-width: 748px) {
        width: 580px !important;
        .react-datepicker__time-list {
            max-height: 200px !important;
        }
    }
    @media screen and (max-width: 650px) {
        width: 100% !important;
    }
`;

// ContactPage1.defaultProps = {
//     advisorId: null,
//     setDatetime: () => {},
//     show: true,
// }

export default function ContactPage1(props) {
    const advisorId = props.advisorId;
    const show = props.show;
    const setDatetime = props.setDatetime;

    return(
        <Show toggle={show}>
            <ContactIntro title={"Get matched"} subtitle={<>Schedule a 15-minute video call to get matched with a <b>Advisor for Women™ Certificate Holder</b> or an <b>Advisor for NextGen™ Certificate Holder</b></>} />
            <Wrapper>
                <SchedulerFlow
                    userId={advisorId}
                    eventCustomMinutes={15}
                    useNewDesign={true}
                    availabilityCalendarOnly={true}
                    availabilityCalendarOnlySelectTime={(datetime) => setDatetime(datetime)}
                />
            </Wrapper>
        </Show>
    );
}