import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {Link, withRouter} from 'react-router-dom';

/* Components */
import {FontSubtitle18, FontHeader26, FontBody24} from "../../../../common/components/fonts";
import {colors} from "../../../../common/components/colors";
import {LogoLoading} from "../../../../common/components/loading";
import FirmCoachPreview from "../components/coachpreview";

/* Middleware */
import {tryGetAllCoaches} from '../middleware/coaches';

/* Domain */
import getDomainEnv from '../../../../domains/utils';
import {ToolTipCreator} from "../../dashboard/components/tooltips";

const env = getDomainEnv();

const LoadingCenter = styled.div`
  margin: 60px auto;
  text-align: center;
`;
const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-bottom: 90px;
  padding-bottom: 30px;
  box-shadow: 0 4px 10px -7px rgb(0, 0, 0, 0.15)
`;
const Button = styled.div`
  ${props => props.isSelected !== "true"} {
    background-color: ${colors.backgroundColor3};
    color: ${colors.white};
  }

  ${props => props.isSelected !== "false"} {
    background-color: ${colors.white};
    color: ${colors.primary100};
  }

  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  border-radius: 20px;
  padding: 20px;
  user-select: none;
`;
const Coaches = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  row-gap: 40px;
  column-gap: 90px;
`;
const Browse = styled.div`
  background-color: white;
  padding: 40px;
  border-radius: 30px;
  box-shadow: 0 10px 25px 0 rgb(0, 0, 0, 0.15);
  width: calc(100% - 80px);
`;
const ButtonRow = styled.div`
  height: 52px;

  ${props => props.hasCoaches !== true} {
    margin: 70px 0 30px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  width: calc(100% - 40px);
`;
const FindACoach = styled(Link)`
  color: ${colors.primary100};
`;
const FindACoachButton = styled(FontBody24)`
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
`;


const Category = ({handleUpdateCategories, category, categoriesShown}) => (
    <Button
        onClick={() => {
            return handleUpdateCategories(category)
        }}
        isSelected={categoriesShown.includes(category).toString()}
    >
        <FontSubtitle18>{category}</FontSubtitle18>
    </Button>
)

class FirmCoachesBrowse extends React.Component {
    static defaultProps = {
        hasCoaches: true
    }

    state = {
        "allCoaches": [],
        "showCoaches": [],
        "showCoachCategories": [],
        "isLoading": true
    }

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const allCoaches = await this.props.tryGetAllCoaches();
        const allActiveCoaches = await allCoaches.filter(f => {
            return (f.isActive)
        });
        const onlyCoaches = await allActiveCoaches.filter(coach => {
            const shouldShowCoach = coach.expertCategories.filter(e => {
                return (e.categoryId === 6 || e.categoryId === 7)
            });
            return (shouldShowCoach.length === 0)
        })
        await this.setState({
            "allCoaches": onlyCoaches,
            "showCoaches": onlyCoaches,
            "isLoading": false
        });
        console.log("showCoaches", onlyCoaches)
    };

    view = async (data) => {
        this.props.history.push("/my-certificates/my-coaches/" + data.id);
    };

    handleUpdateCategories = async (category) => {
        if (this.state.showCoachCategories.includes(category)) {
            await this.setState({
                "showCoachCategories": this.state.showCoachCategories.filter(s => {
                    return (s !== category)
                })
            })
        } else {
            await this.setState({"showCoachCategories": [...this.state.showCoachCategories, category]})
        }
        return this.filterCoaches();
    }

    filterCoaches = async () => {
        this.setState({
            "showCoaches": this.state.showCoachCategories.map(selectedCategory => {
                return this.state.allCoaches.filter(coach => {
                    const shouldShowCoach = coach.expertCategories.filter(e => {
                        return (env.ENTERPRISE.COMPANY.CARE.PARENT_COURSE_CATEGORIES[e.categoryId] === selectedCategory)
                    });
                    return (shouldShowCoach.length > 0)
                })
            }).flat().filter((value, index, self) => {
                return self.indexOf(value) === index;
            })
        })
    }

    // pushToTrainings = () => this.props.history.push("/my-certificates/my-trainings")
    // pushToStep3 = () => this.props.history.push("/my-certificates?step=3")

    render() {
        if (this.state.isLoading) {
            return (<LoadingCenter><LogoLoading/></LoadingCenter>)
        } else {
            return (
                <>
                    <ButtonRow hasCoaches={this.props.hasCoaches}>
                        {this.props.hasCoaches &&
                            <FindACoach to={"/my-certificates/my-coaches"}>
                                <FindACoachButton>View My Coaches</FindACoachButton>
                            </FindACoach>
                        }
                    </ButtonRow>
                    <Browse>
                        <Buttons>
                            {["Divorce", "Family Building", "Career", "Financial Wellness",
                                "Grief & Loss", "Dating & Marriage", "Retirement", "NextGen", "LGBTQ+"].map((b, index) => (
                                <Category
                                    key={index}
                                    handleUpdateCategories={this.handleUpdateCategories}
                                    category={b}
                                    categoriesShown={this.state.showCoachCategories}
                                />
                            ))}
                        </Buttons>
                        <Coaches>
                            {this.state.showCoachCategories.length === 0
                                ? <>
                                    {this.state.allCoaches.map((c, index) => {
                                        // if (index !== 0) {
                                            return <FirmCoachPreview key={index} coach={c}/>
                                        // }
                                        // if (this.props.showToolTip && this.props.toolTipPage === 5) {
                                        // return (
                                        //      <div key={index} style={{
                                        //         position: "relative",
                                        //         boxShadow: `${this.props.showToolTip && this.props.toolTipPage === 5 ? "0 0 0 3000px rgba(1,1,1,0.5)" : "unset"}`,
                                        //         padding: "50px"
                                        //     }}>
                                        //         <ToolTipCreator
                                        //             text={"lorem ipsum text about coaches"}
                                        //             show={true}
                                        //             style={{top: "-80px", right: "50px"}}
                                        //             linkToNext={this.pushToStep3}
                                        //             linkToBack={this.pushToTrainings}/>
                                        //         <FirmCoachPreview key={index} coach={c}
                                        //
                                        //         />
                                        //
                                        //     </div>)};

                                    })}
                                </>
                                : <>
                                    {this.state.showCoaches.length === 0 &&
                                        <FontHeader26>No coaches meet your filter</FontHeader26>
                                    }
                                    {this.state.showCoaches.map((c, index) => (
                                        <FirmCoachPreview key={index} coach={c}/>
                                    ))}
                                </>
                            }
                        </Coaches>
                    </Browse>
                </>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryGetAllCoaches: () => dispatch(tryGetAllCoaches())
});

const mapStateToProps = state => ({
    showToolTip: state.enterprise.tooltip.get('showToolTip'),
    toolTipPage: state.enterprise.tooltip.get('toolTipPage')
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FirmCoachesBrowse));