import React from 'react';
import {withRouter} from 'react-router-dom';

/* Components */
import { PageFull, PageContentContainer } from '../navbars/components/navlayout';
import ConsumerNav from '../navbars/containers/nav';

/* Pages */
import MyGroupPrograms from './containers/myprograms';
import ProgramsFeedback from './containers/programsfeedback';
import ProgramsCatalogue from './containers/programscatalogue';

const Care = ({location}) => {
    return(
        <>
            <ConsumerNav />
            <PageFull>
                <PageContentContainer>
                    {location.pathname.includes("/my-programs") && <MyGroupPrograms/>}
                    {location.pathname === "/programs/request" && <ProgramsFeedback/>}
                    {(location.pathname.includes("/programs") && location.pathname !== "/programs/request") &&
                        <ProgramsCatalogue />
                    }
                </PageContentContainer>
            </PageFull>
        </>
    )
};

export default withRouter(Care);


