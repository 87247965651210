import images from '../styles/images';

const createEmailTemplates = (remoteName, localName, purchaseUrl, isWMC, wmcsAssignedToCoach) => {
    let emailList = [
        {
            "value": 9342,
            "label": "Partner: Willow Research: Welcome Message",
            "subject": "Welcome from your Willow Financial Life Coach!",
            "wmcId": 27,
            "body": "<p>Hi "+remoteName+",</p>" +
                "<p><br></p>" +
                "<p>Thank you for scheduling your coaching session! I&rsquo;m excited to meet with you and guide you along your path toward financial wellness.</p>" +
                "<p><br></p>" +
                "<p>You&rsquo;ve already made the excellent choice to invest in your financial wellness. Let&rsquo;s build on this momentum and get started! To make the most out of our upcoming time together, please complete these next steps:</p>" +
                "<p><br></p>" +
                "<ol>" +
                "<li>Download your <em>Roadmap to Financial Wellness</em> [<a href='https://docs.trustwillow.com/Roadmap+to+Financial+Wellness_Session_01.pdf'>link</a>] and print it out. When we handwrite, we engage our body and our mind. Plus, research indicates handwriting boosts memory and information retention.</li>" +
                "<li>Complete pages 4&ndash;6. If you&rsquo;re unsure of answers, that&rsquo;s ok. Give it your best before our session and we&rsquo;ll talk it through together</li>" +
                "<li>Throughout our engagement, you can always feel free to upload pages from your <em>Roadmap</em> or other information you&rsquo;d like to confidentially share to your Vault, the Willow secure document sharing tool in your Willow account.</li>" +
                "</ol>" +
                "<p><br></p>" +
                "<p>You have a Willow account! Here&rsquo;s how you can access it:</p>" +
                "<p><br></p>" +
                "<ol>" +
                "<li>Go to <a href='https://trustwillow.com/login'>trustwillow.com/login</a></li>" +
                "<li>Log in with the password you created when scheduling your first session</li>" +
                "<li>If needed, click on Forgot Password to reset your password, then log in</li>" +
                "</ol>" +
                "<p><br></p>" +
                "<p>By doing this work ahead of time, you&rsquo;ll be:</p>" +
                "<p><br></p>" +
                "<ul>" +
                "<li><img src='"+images.emailEmojiBrain+"' alt='' />&nbsp;More mentally prepared for our session</li>" +
                "<li><img src='"+images.emailEmojiBook+"' alt='' />&nbsp;Able to build your roadmap more quickly and thoroughly</li>" +
                "<li><img src='"+images.emailEmojiCheck+"' alt='' />&nbsp;Helping me prepare!</li>" +
                "</ul>" +
                "<p><br></p>" +
                "<p>If you have any questions, please don&rsquo;t hesitate to email me using your Willow account.</p>" +
                "<p><br></p>" +
                "<p>I look forward to seeing you soon!</p>" +
                "<p><br></p>" +
                "<p>Best,</p>"
        },
        {
            "value": 55,
            "label": "Partner: Her Traditions: Welcome Message",
            "subject": "Welcome from your Willow Financial Life Coach!",
            "wmcId": 25,
            "body": "<p>Hi "+remoteName+",</p>" +
                "<p><br></p>" +
                "<p>Thank you for scheduling your coaching session! I&rsquo;m excited to meet with you and guide you along your path toward financial wellness.</p>" +
                "<p><br></p>" +
                "<p>You&rsquo;ve already made the excellent choice to invest in your financial wellness. Let&rsquo;s build on this momentum and get started! To make the most out of our upcoming time together, please complete these next steps:</p>" +
                "<p><br></p>" +
                "<ol>" +
                    "<li>Download your <em>Roadmap to Financial Wellness</em> [<a href='https://docs.trustwillow.com/Your-Roadmap-to-Financial-Wellness-York-Pilot.pdf'>link</a>] and print it out. When we handwrite, we engage our body and our mind. Plus, research indicates handwriting boosts memory and information retention.</li>" +
                    "<li>Complete pages 4&ndash;6. If you&rsquo;re unsure of answers, that&rsquo;s ok. Give it your best before our session and we&rsquo;ll talk it through together</li>" +
                    "<li>Throughout our engagement, you can always feel free to upload pages from your <em>Roadmap</em> or other information you&rsquo;d like to confidentially share to your Vault, the Willow secure document sharing tool in your Willow account.</li>" +
                "</ol>" +
                "<p><br></p>" +
                "<p>You have a Willow account! Here&rsquo;s how you can access it:</p>" +
                "<p><br></p>" +
                "<ol>" +
                    "<li>Go to <a href='https://trustwillow.com/login'>trustwillow.com/login</a></li>" +
                    "<li>Log in with the password you created when scheduling your first session</li>" +
                    "<li>If needed, click on Forgot Password to reset your password, then log in</li>" +
                "</ol>" +
                "<p><br></p>" +
                "<p>By doing this work ahead of time, you&rsquo;ll be:</p>" +
                "<p><br></p>" +
                "<ul>" +
                    "<li><img src='"+images.emailEmojiBrain+"' alt='' />&nbsp;More mentally prepared for our session</li>" +
                    "<li><img src='"+images.emailEmojiBook+"' alt='' />&nbsp;Able to build your roadmap more quickly and thoroughly</li>" +
                    "<li><img src='"+images.emailEmojiCheck+"' alt='' />&nbsp;Helping me prepare!</li>" +
                "</ul>" +
                "<p><br></p>" +
                "<p>If you have any questions, please don&rsquo;t hesitate to email me using your Willow account.</p>" +
                "<p><br></p>" +
                "<p>I look forward to seeing you soon!</p>" +
                "<p><br></p>" +
                "<p>Best,</p>"
        },
        {
            "value": 545,
            "label": "Partner: Her Traditions: 1st Session Follow-Up Message",
            "subject": "Financial Life Coaching Session Follow-Up",
            "wmcId": 25,
            "body": "<p>Hi "+remoteName+",</p>" +
                "<p><br></p>" +
                "<p>Thank you for meeting with me for our first financial life coaching session!</p>" +
                "<p><br></p>" +
                "<p>I appreciate all that you shared about your journey and coaching goals. I&rsquo;ve recapped a few key points from our conversation and outlined a few next steps for you to take before our next session.</p>" +
                "<p><br></p>" +
                "<p>Your Goals:</p>" +
                "<p><br></p>" +
                "<ul>" +
                    "<li>Ex:&nbsp;</li>" +
                    "<li>Ex:&nbsp;</li>" +
                    "<li>Ex:&nbsp;</li>" +
                "</ul>" +
                "<p><br></p>" +
                "<p>Next Steps:</p>" +
                "<p><br></p>" +
                "<ul>" +
                    "<li>As we discussed, during our next session we will dive deeper into spending. To make the most of our time together, please head into your Willow account and <b>complete the Monthly Spending Plan tab in your My Money tool.</b></li>" +
                "</ul>" +
                "<p><br></p>" +
                "<p>You can also read through pages 12–17 in your Roadmap to be even better prepared for our session. Feel free to upload any documents you&rsquo;d like me to review in advance of our session to the secure Vault.</p>" +
                "<p><br></p>" +
                "<p>As always, please don&rsquo;t hesitate to reach out with any questions.</p>" +
                "<p><br></p>" +
                "<p>Best,</p>"
        },
        {
            "value": 5454,
            "label": "Partner: Her Traditions: Final Follow-Up Message",
            "subject": "Congrats! You completed your checkup",
            "wmcId": 25,
            "body": "<p>Hi "+remoteName+",</p>" +
                "<p><br></p>" +
                "<p>You did it!</p>" +
                "<p><br></p>" +
                "<p>It&rsquo;s been a pleasure to support you with your Financial Wellness Checkup.</p>" +
                "<p><br></p>" +
                "<p>You&rsquo;ve already taken steps forward with::</p>" +
                "<p><br></p>" +
                "<ul>" +
                    "<li>Ex:&nbsp;</li>" +
                    "<li>Ex:&nbsp;</li>" +
                    "<li>Ex:&nbsp;</li>" +
                "</ul>" +
                "<p><br></p>" +
                "<p>Keep up the great work! If you&rsquo;d like to continue coaching, please send me a note and we can talk about the options available. From defining your charitable giving strategy, to creating savings strategies to build your cash cushion, to ensuring you protect the wealth you&rsquo;ve built, I&rsquo;m here to support you.</p>" +
                "<p><br></p>" +
                "<p>Thank you for the opportunity to work together. Wishing you the greatest success along your financial wellness journey!</p>" +
                "<p><br></p>" +
                "<p>Best,</p>"
        },
        {
            "value": 1,
            "label": "Complimentary call follow-up",
            "subject": "Complimentary call follow-up",
            "wmcId": null,
            "body": "<p>Dear "+remoteName+",</p><p><br></p><p>Thank you for speaking with me yesterday. With our call you&rsquo;ve already taken an important step to prioritize your financial wellness!</p><p><br></p><p>I appreciate all that you shared about your journey and coaching goals. I&rsquo;ve recapped our conversation below and offered a few suggested next steps.</p><p><br></p><p><strong>Your Journey</strong></p><ul><li>Recap specific life journeys shared by prospect here</li><li>Ex: you shared you&rsquo;re looking for support as you navigate a divorce</li><li>Etc.</li></ul><p><br></p><p><strong>Your Coaching Goals</strong></p><ul><li>Recap specific financial coaching shared by prospect here</li><li>Ex: Rebuild your credit score</li><li>Ex: Set a budget and spending plan you&rsquo;ll stick to</li><li>Ex: Strengthen your financial confidence</li></ul><p><br></p><p><strong>Suggested Next Steps</strong></p><ul><li>Based on our conversation, you may find the [insert coaching package type here].&nbsp;</li><li>Insert details about that package here</li><li>Should you decide that you&rsquo;d like to move forward with Willow Financial Coaching, do this xxx</li></ul><p><br></p><p>Should you have any questions, you can reach out to me (email) or to support@trustwillow.com.</p><p><br></p><p>Thank you,&nbsp;</p><p><br></p><p>"+localName+"</p>"
        },
        {
            "value": 2,
            "label": "Next steps",
            "subject": "Next steps",
            "wmcId": null,
            "body": "<p>Dear "+remoteName+",</p><p><br></p><p>Thank you for taking the time yesterday to focus on your financial wellness!</p><p><br></p><p>I&rsquo;ve recapped our conversation below and offered a few suggested next steps.</p><p><br></p><p><strong>Your Coaching Goals</strong></p><ul><li>Recap specific financial coaching shared by client here</li><li>Recapping goals in every follow-up communication helps reinforce your coaching and keeps clients on track!</li><li>For specific savings goals, encourage clients to use the Willow Goal Tracker (link)</li></ul><p><br></p><p><strong>We discussed…</strong></p><ul><li>Recap important items shared during conversation</li><li>Acknowledge your client&rsquo;s concerns, questions and feedback&nbsp;</li><li>Record material information you feel is important to note</li></ul><p><br></p><p><br></p><p><strong>Suggested Next Steps before our next call</strong></p><p>During our session, we discussed several next steps and actions you can take before our next call. You&rsquo;re already making great progress - keep it up!</p><ul><li>Ex: request your credit report from at least one agency at annualcreditreport.com</li><li>Ex: research high yield savings account options, including those we discussed</li></ul><p><br></p><p>Please don&rsquo;t hesitate to reach out with any questions or additional thoughts before our next session. You can use the text and email messaging tab within your Dashboard to reach out!&nbsp;</p><p><br></p><p>Thank you,&nbsp;</p><p><br></p><p>"+localName+"</p>"
        },
        {
            "value": 3,
            "label": "Commitment Statement",
            "subject": "Commitment Statement",
            "wmcId": null,
            "body": "<p>Dear "+remoteName+",</p><p><br></p><p>I&rsquo;m so excited that you&rsquo;re ready to commit to your financial wellness! Congratulations!</p><p><br></p><p>By investing in yourself, you&rsquo;ve already shown you&rsquo;re ready to take charge of your financial life. Use this Commitment Statement to document your promise to you! Print it out, sign it, and put in a place that will remind you that you&rsquo;re on your way to more freedom on your life journey!</p><p><br></p><p>Remember, you can always use the text and email messaging tab within your Dashboard to ask me questions. I&rsquo;m here to help you stay on track!</p><p><br></p><p><a target='_blank' href='https://docs.trustwillow.com/editable_coach_commitment_statement__1_.pdf'>Download the Commitment Statement</a><p><br></p><p>You&#39;ve got this!</p><p><br></p><p>"+localName+"</p>"
        },
        {
            "value": 4,
            "label": "Purchase Reminder",
            "subject": "Purchase Reminder",
            "wmcId": null,
            "body": "<p>Dear "+remoteName+",</p><p><br></p><p>I&rsquo;m so excited to work with you. Please follow this link to purchase the 3 month package!</p><p><br></p><p><a target='_blank' href='https://secure.trustwillow.com/package/"+purchaseUrl+"'>Purchase Link</a><p><br></p><p>You&#39;ve got this!</p><p><br></p><p>"+localName+"</p>"
        },
        {
            "value": 5,
            "label": "No-Show follow-up",
            "subject": "No-Show follow-up",
            "wmcId": null,
            "body": "<p>Dear "+remoteName+",</p><p><br></p><p>I hope all is okay with you today. You reached out to discuss your financial questions and I&rsquo;m here to support you whenever you&rsquo;re ready to commit to your financial journey. Please follow this link to reschedule your coaching call on your Willow Dashboard.</p><p><br></p><p><a target='_blank' href='http://secure.trustwillow.com/'>Reschedule</a></p><p><br></p><p>Taking the first step is often the hardest part. I&rsquo;m here to help!</p><p><br></p><p>"+localName+"</p>"
        },
        {
            "value": 6,
            "label": "Get STILL workbook",
            "subject": "Get STILL workbook",
            "wmcId": null,
            "body": "<p>Dear "+remoteName+",</p><p><br></p><p>As promised, I&#39;m sending a link to the Get STILL workbook. Right now is undoubtedly a challenging time. Use this guide to keep feelings of overwhelm at bay and take the action steps you want on your financial journey. Please follow this link to download your workbook.</p><p><br></p><p><a target='_blank' href='http://docs.trustwillow.com/Get-STILL.pdf'>Click here</a></p><p><br></p><p>Taking the first step is often the hardest part. I&rsquo;m here to help!</p><p><br></p><p>"+localName+"</p>"
        },
    ];
    let emailListAdj;
    if(!isWMC) {
        emailListAdj = emailList.filter(f => {
            return(f.wmcId === null || wmcsAssignedToCoach.includes(f.wmcId))
        })
    } else {
        emailListAdj = emailList.filter(f => {
            return(f.wmcId === null)
        })
    }
    if(purchaseUrl === null) {
        return emailListAdj.filter(e => {
            return e.label !== "Purchase Reminder";   
        });
    } else {
        return emailListAdj;
    }
};

export default createEmailTemplates;