import {axiosReq} from "../../../common/utils/axios";

/* Static */
const resData = {

}

export const tryPostStripeCheckoutSession = (stripeCheckoutSessionId) => {
    return async dispatch => {
        return axiosReq('apiv1/consumer/purchase/stripe-checkout-session/complete-flow', "POST", {"stripeCheckoutSessionId": stripeCheckoutSessionId}, resData);
    }
};
