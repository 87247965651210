import React from 'react';
import {connect} from 'react-redux';
import styled from "styled-components";
import {withRouter} from 'react-router-dom';

import {tryGetMessagesFromToken, tryPostMessagesFromToken} from './middleware/messages';
import {MessagesUnableToJoinWrapper} from './components/messagestatusmodals';
import {images} from "../../common/components/images";
import MessengerWrapper from "./containers/messenger";

const Logo = styled.div`
    height: 40px;
    padding: 30px 70px 30px 50px;
    text-align: center;
`;
export const Room = styled.div`
    height: 100%;
    width: 100%;
`;
export const RoomContent = styled.div`
    width: 100%;
    @media screen and (max-width: 1088px) {
        padding-bottom: 80px;
    }
    @media screen and (max-width: 748px) {
        height: calc(100% - 165px);
    }
    @media screen and (max-width: 460px) {
        padding-bottom: 0px;
    }
`;
export const RoomContentContainer = styled.div`
    width: 694px;
    margin: 0px auto 120px auto;
    padding: 00px 20px 0px 20px;
    @media screen and (max-width: 748px) {
        padding: 0px 20px 0px 20px;
        width: calc(100% - 40px);
    }
`;


class MessageRoom extends React.Component {
    constructor(props) {
        super(props)
    }

    intervalID = 0;

    componentDidMount() {
        if (this.props.messageThreadId === null) {
            this.props.tryGetMessagesFromToken(this.props.location.pathname.split('/')[2]);
            this.intervalID = setInterval(() => {this.props.tryGetMessagesFromToken(this.props.location.pathname.split('/')[2])}, 1000 * 60 * 1)
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    render() {
        return (
            <>
                {this.props.messageThreadId !== null &&
                    <Room>
                        <RoomContent>
                            <RoomContentContainer>
                                <Logo><img src={images.logo} height="40" width="200" /></Logo>
                                <MessengerWrapper
                                    showTemplates={true}
                                    channel={"sms"}
                                    activity={this.props.messages.get("messagesActivity").filter(a => {return (a.get("channel") === "sms")})}
                                    userId={this.props.messages.get("messagesThisUserId")}
                                    userPlatform={this.props.messages.get("messagesThisUserPlatform")}
                                    sendMessage={this.props.tryPostMessagesFromToken}
                                    messageThreadId={this.props.messages.get("messageThreadId")}
                                    messagesConsumerName={this.props.messages.get("messagesConsumerName")}
                                    messagesCoachName={this.props.messages.get("messagesCoachName")}
                                />
                                {console.log(this.props.messages.get("messagesThisUserId"))}
                            </RoomContentContainer>
                        </RoomContent>
                    </Room>
                }
                {this.props.messageThreadId === 'invalid' &&
                    <MessagesUnableToJoinWrapper />
                }
            </>
        )
    }
}
const mapStateToProps = state => ({
    messageThreadId: state.iframe.sms.messages.get("messageThreadId"),
    messages: state.iframe.sms.messages
});
const mapDispatchToProps = dispatch => ({
    tryGetMessagesFromToken: (token) => dispatch(tryGetMessagesFromToken(token)),
    tryPostMessagesFromToken: (mediaType, content, subject, token) => dispatch(tryPostMessagesFromToken(mediaType, content, subject, token))
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MessageRoom));
