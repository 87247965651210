import React from 'react';
import styled from 'styled-components';
import {colors} from "../../../common/components/colors";

export const PageFull = styled.div`
    margin-left: 250px;
    width: calc(100% - 250px);
    height: 100%;
    @media screen and (max-width: 1088px) {
        margin-left: 0px;
        width: 100%;
    }
`;

export const PageFullSettings = styled.div`
    width: calc(100% - 250px);
    height: 100%;
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 1088px) {
        margin-left: 0px;
        width: 100%;
    }
`;

export const PageRight = styled.div`
    margin: 0px auto;
    width: calc(100% - 120px);
    padding: 40px 60px;
    @media screen and (max-width: 1088px) {
        padding: 30px;
        width: calc(100% - 60px);
    }
    @media screen and (max-width: 460px) {
        padding: 30px 15px;
        width: calc(100% - 30px);
    }
`;

export const PageContent = styled.div`
    width: 100%;
    @media screen and (max-width: 748px) {
        height: calc(100% - 165px);
    }
`;

export const PageContentContainer = styled.div`
    width: 100%;
    margin: 0px auto;
    @media screen and (max-width: 748px) {
        padding: 20px 20px 0px 20px;
        width: calc(100% - 40px);
    }
    @media screen and (max-width: 460px) {
        padding: 20px 10px 0px 10px;
        width: calc(100% - 20px);
    }
`;

export const Block = styled.div`
    margin-top: 30px;
`;

export const Width = styled.div`
    ${props => props.fullWidth !== true} {
        width: calc(100% - 480px);
        @media screen and (max-width: 1610px) {
            width: 100%;
        }
    }
    ${props => props.fullWidth !== false} {
        max-width: 800px;
        width: 100%;
        @media screen and (max-width: 1088px) {
            max-width: 100%;
        }
    }
    display: inline-block;
    vertical-align: top;
`;

export const CardOrNot = styled.div`
    vertical-align: top;
    width: calc(100% - 60px);
    padding: 30px;
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    margin-top: 0px;
    @media screen and (max-width: 1610px) {
        margin-top: 30px;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-top: 1px solid ${colors.primary30};    
        padding: 30px 0px 30px 0px;
        width: 100%;
    }
`;

export const WidthRight = styled.div`
    ${props => props.fullWidth !== true} {
        width: 480px;
    }
    ${props => props.fullWidth !== false} {
        width: calc(100% - 800px);
    }
    display: inline-block;
    vertical-align: top;
    position: relative;
    min-height: 550px;
    @media screen and (max-width: 1610px) {
        display: block;
        width: 100%;
        min-height: auto;
    }
`;

export const Right = styled.div`
    ${props => props.fullWidth !== true} {
        @media screen and (max-width: 1610px) {
            max-width: 100%;
        }
    }
    ${props => props.fullWidth !== false} {
        @media screen and (max-width: 1610px) {
            max-width: 800px;
        }
        @media screen and (max-width: 1088px) {
            max-width: 100%;
        }
    }
    width: 100%;
    max-width: 360px;
    margin: 0px;
    position: absolute;
    left: 120px;
    @media screen and (max-width: 1610px) {
        margin: 0px;
        right: 0px;
        left: 0px;
        position: relative;
    }
`;

export const Border = styled.div`
    height: 25px;
    border-bottom: 1px solid ${colors.primary30};
    margin-bottom: 40px;
`;