// Default
const willow = {
    body: {
        fontFamily: "MontserratRegular",
        fontFileName: "Montserrat-Regular.ttf",
        fontFormat: "truetype"
    },
    header: {
        fontFamily: "MontserratSemiBold",
        fontFileName: "Montserrat-SemiBold.ttf",
        fontFormat: "truetype"
    },
    subtitle: {
        fontFamily: "LoraMedium",
        fontFileName: "Lora-Medium.ttf",
        fontFormat: "truetype"
    },
    title: {
        fontFamily: "LoraBold",
        fontFileName: "Lora-Bold.ttf",
        fontFormat: "truetype"
    }
};

// Principal
const principal = {
    body: {
        fontFamily: "FSElliotWebRegular",
        fontFileName: "FSElliotWeb-Regular.ttf",
        fontFormat: "truetype"
    },
    header: {
        fontFamily: "FSElliotWebBold",
        fontFileName: "FSElliotWeb-Bold.ttf",
        fontFormat: "truetype"
    },
    subtitle: {
        fontFamily: "FSElliotWebRegular",
        fontFileName: "FSElliotWeb-Regular.ttf",
        fontFormat: "truetype"
    },
    title: {
        fontFamily: "FSElliotWebBold",
        fontFileName: "FSElliotWeb-Bold.ttf",
        fontFormat: "truetype"
    }
};

const jpmorgan = {
    backup: 'Arial Narrow, sans-serif',
    body: {
        fontFamily: 'Amplitude-Book',
        fontFileName: 'amplitude-book.ttf',
        fontFormat: 'truetype'
    },
    header: {
        fontFamily: "'Open Sans',sans-serif",
        fontFileName: 'opensans.ttf',
        fontFormat: 'truetype'
    },
    subtitle : {
        fontFamily: 'Amplitude-Regular',
        fontFileName: 'amplitude-regular.ttf',
        fontFormat: 'truetype'
    },
    title : {
        fontFamily: 'Amplitude-Medium',
        fontFileName: 'amplitude-medium.ttf',
        fontFormat: 'truetype'
    }
}

const edwardjones = {
    body: {
        fontFamily: 'Whitney-Book',
        fontFileName: 'whitneybook.ttf',
        fontFormat: 'truetype'
    },
    header: {
        fontFamily: "Whitney-Semibold",
        fontFileName: 'whitneysemibold.ttf',
        fontFormat: 'truetype'
    },
    subtitle : {
        fontFamily: 'Whitney-Medium',
        fontFileName: 'whitneymedium.ttf',
        fontFormat: 'truetype'
    },
    title : {
        fontFamily: 'Whytney-Bold',
        fontFileName: 'whitneybold.ttf',
        fontFormat: 'truetype'
    }
}

const chooseFonts = () => {

    if(window.location.href.includes("principal.trustwillow.com")) {
        return(principal)
    }
    else if(window.location.href.includes("jpmorgan.trustwillow.com")) {
        return(jpmorgan)
    }
    else if(window.location.href.includes("edwardjones.trustwillow.com")) {
        return(edwardjones)
    }
    return(willow)
}

export default chooseFonts();