import React from 'react';
import styled from 'styled-components';

/* Components */
import {colors} from "../../../common/components/colors";
import {FontSubtitle28, FontBody16} from '../../../common/components/fonts';

const ContainerTop = styled.div`
  background-color: ${colors.backgroundColor1};
  width: calc(100% - 60px);
  display: block;
  margin: 0;
  padding: 30px;
  font-weight: bold;
  border-radius: 25px 25px 0 0;
  text-align:center;
`;
const Subtitle = styled(FontBody16)`
  color: ${colors.primary70};
  margin-top: 5px;
`;

ContactTop.defaultProps = {
    title: "",
    subtitle: null,
}

export default function ContactTop(props) {
    const title = props.title;
    const subtitle = props.subtitle;

    return (
        <ContainerTop>
            <FontSubtitle28 spaced={false}>{title}</FontSubtitle28>
            {subtitle !== null && <Subtitle>{subtitle}</Subtitle>}
        </ContainerTop>
    );
}