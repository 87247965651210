import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setChangePasswordUpdating, setChangePassword} from "../store/changepassword";

export const tryChangePassword = (password) => {
    return async dispatch => {
        dispatch(setChangePasswordUpdating(true));
        const security = await axiosReq('apiv1/settings/security', "PUT", {password: password}, {});
        if(security.hasOwnProperty("error")) {
            dispatch(setChangePassword(security.error));
        } else {
            dispatch(setChangePassword(""));
        }
        return dispatch(setChangePasswordUpdating(false));
    }
};

export const trySetNewPassword = (code, password1, password2) => {
    return async dispatch => {
        dispatch(setChangePasswordUpdating(true));
        const security = await axiosReq('api-set-new-password', "POST", {"token": code, "password1": password1, "password2": password2}, {})
        if(security.hasOwnProperty("error")) {
            dispatch(setChangePassword(security.error));
        } else {
            dispatch(setChangePassword(""));
        }
        return dispatch(setChangePasswordUpdating(false));
    }
}
