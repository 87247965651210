import React from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components'

/* Components */
import { PageFull } from '../navbars/components/navlayout';
import ConsumerNav from '../navbars/containers/nav';

/* Containers */
import ForumCategories from './containers/forumcategories';
import ForumCategoryOne from './containers/forumcategoryone';
import ForumPost from './containers/forumpost';
import ForumPostCreate from './containers/forumcreate';

/* Domain */
import getDomainEnv from '../../domains/utils';
const env = getDomainEnv();

const PageContentContainer = styled.div`
    width: 80%;
    margin: 0px auto 120px auto;
    padding: 60px 20px 0px 20px;
    @media screen and (max-width: 748px) {
        padding: 20px 20px 0px 20px;
        width: calc(100% - 40px);
    }
`;

const Forum = ({location}) => {
    return(
        <>
            <ConsumerNav />
            <PageFull>
                <PageContentContainer>
                    {location.pathname === "/community" && <ForumCategories/>}
                    {(location.pathname.includes("/community/category")) && <ForumCategoryOne/>}
                    {location.pathname === "/community/posts/post" && <ForumPost/>}
                    {location.pathname === "/community/posts/create" && <ForumPostCreate/>}
                    {/* {location.pathname === ("/community/posts/edit" || "/community/posts/delete") && <ForumPostEdit/>} */}
                </PageContentContainer>
            </PageFull>
        </>
    )
};
export default withRouter(Forum);