import React from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {List} from 'immutable';

/* Containers */
import {ChangeSessions} from "../../containers/contactcoachingchangesessions";
import {ChangeSessionsUnlimited} from "../../containers/contactcoachingchangesessionsunlimited";
import IntroduceLink from "./introducelinks";
import {editorFormats, editorModules} from "../../../common/components/richtexteditor";

/* Middleware */
import {tryGetCoachesAssignedToProspect, tryPostAssignCoachToProspect, tryPostUpdateProspectsSessions} from "../middleware/coaching";
import {tryGetAllContacts} from "../../middleware/contacts";

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FontBody18, FontTitle30, FontHeader12, FontBody16, FontHeader21, FontBody14, FontHeader14} from "../../../common/components/fonts";
import {SearchInput, Toggle2Lables} from "../../../common/components/inputs";
import {CommonBackground, CommonModal} from "../../../common/components/modal";
import {colors} from "../../../common/components/colors";
import {ButtonSecondary, ButtonLink, ButtonInactive, ButtonPrimary} from "../../../common/components/buttons";
import {Calendar, Clock} from 'react-feather';
import {CloseOutlined, ArrowLeftOutlined} from "@ant-design/icons";
import {setCoachModalStatus} from "../store/coaching";
import {ActionBox} from "./teamintroduce";
import SchedulerFlow from "../../../iframes/scheduler/containers/flow";
import ReactQuill from "react-quill";
import {v4 as uuid4} from "uuid";

/* Utils */
import {disableBodyScrolling, enableBodyScrolling} from '../../../common/utils/scrolling';

export const Body = styled.div`
    .ql-editor {
        min-height: 200px;
        max-height: 400px;
        @media screen and (max-width: 768px) {
            max-height: 100%;
        }
        blockquote {
            margin-top: 0px !important;
            margin-bottom: 0px !important;
        }
    } 
`;

const Center = styled.div`
    margin: 20px auto 0px auto;
    width: fit-content;
    text-align: center;
`;
const StepTitle = styled.div`
    margin-bottom: -20px;
`;
const Padding = styled.div`
    margin-top: 35px;
`;

const Constrain = styled.div`
    margin-top: 26px;
`;

const Gray = styled.div`
    margin-bottom: 10px;
    color: ${colors.secondary70};
`;

const Width = styled.div`
    max-width: 500px;
    margin-bottom: 30px;
`;

const Title = styled.div`
    margin: 20px 0px 5px 0px;
`;
const Btn = styled.span`
    color: ${colors.action100};
    cursor: pointer;
`;
const IntroduceHeader = styled.div`
    display: none;
    @media screen and (max-width: 460px) {
        display: block;
    }
`;

const IconChar = styled.div`
    display: inline-block;
    vertical-align: top;
    font-family: CooperLight;
    font-size: 24px;
    ${props => props.active !== true} {
        color: ${colors.white};
    }
    ${props => props.active !== false} {
        color: ${colors.action100};
    }
    height: 26px;
`;
const Icon = styled.div`
    display: inline-block;
    vertical-align: top;
    ${props => props.active !== true} {
        color: ${colors.white};
    }
    ${props => props.active !== false} {
        color: ${colors.action100};
    }
    & svg {
        width: 16px;
        height: 16px;
        margin-top: 2px;
        margin-bottom: -2px;
    }
`;

const SearchInputEdit = styled.div`
    & > div {
        margin: 25px 0px 15px 0px;
    }
    & input {
        width: calc(100% - 42px);
    }
    ${props => props.contactSelected !== false} {
        & input {
            -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
        }
        & input::placeholder {
            color: ${colors.primary70};
        }
    }
`;

const InlineButton = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-right: 7px;
    margin-bottom: 10px;
    @media screen and (max-width: 460px) {
        display: block;
        text-align: center;
        margin: 0px auto 10px auto;
        & div {
            margin: 0px auto;
        }
    }
`;

const Modal = styled.div`
    margin: 0px auto;
    max-width: 380px;
    padding: 30px;
    border-radius: 6px;
    background-color: ${colors.white};
    position: fixed;
    top: 100px;
    left: 0px;
    right: 0px;
    ${props => props.big !== true} {max-height: 347px;}
    ${props => props.big !== false} {max-height: 280px;}
    height: 100%;
    @media screen and (max-width: 460px) {
        top: 20px;
        width: calc(100% - 20px);
        padding: 0px 10px;
    }
    z-index: 6;
`;
const QuillPadding = styled.div`
    padding-bottom: 30px;
`;
const BigModal = styled.div`
    margin: 0px auto;
    max-width: 380px;
    padding: 30px;
    border-radius: 6px;
    background-color: ${colors.white};
    position: fixed;
    top: 100px;
    left: 0px;
    right: 0px;
    max-height: 647px;
    height: 100%;
    @media screen and (max-width: 460px) {
        top: 20px;
        width: calc(100% - 20px);
        padding: 0px 10px;
    }
    z-index: 6;
`;
const ModalScheduler = styled.div`
    margin: 0px auto;
    width: 748px;
    padding: 10px 30px;
    border-radius: 6px;
    background-color: ${colors.white};
    position: fixed;
    left: 0px;
    right: 0px;
    top: 100px;
    @media screen and (max-width: 800px) {
        width: 100%;
        padding: 0px;
    }
    z-index: 6;
`;
const Background = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.50);
    @media screen and (max-width: 460px) {
        background-color: ${colors.white};
    }
    z-index: 5;
`;

const FitContent = styled.div`
    width: fit-content;
`;
const FitContentFullWidth = styled.div`
    width: 100%;
    & > div {
        width: calc(100% - 47px);
        text-align: center;
        margin: 0px auto;
    }
`;
const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;
const Right = styled.div`
    color: ${colors.secondary70};
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 10px;
    display: block;
    cursor: pointer;
    position: absolute;
    z-index: 25;
    right: 30px;
    top: 25px;
    @media screen and (max-width: 460px) {
        top: 0px;
        right: 5px;
    }
`;
const Left = styled.div`
    text-align: left;
    width: 100%;
`;
const Indent = styled.div`
    width: calc(100% - 40px);
    padding: 0px 20px;
    position: relative;
    overflow-y: auto;
    max-height: calc(100vh - 120px);
`;
const PaddingMeeting = styled.div`
    padding-top: 101px;
`;
const Header = styled.div`
    border-bottom: 1px solid ${colors.secondary30};
    padding-bottom: 10px;
    margin-top: -10px;
    margin-bottom: 30px;
`;
const Image = styled.img`
    width: 44px;
    height: 44px;
    border-radius: 44px;
    object-fit: cover;
    object-position: 0 0;
    display: inline-block;
    vertical-align: top;
`;
const CoachName = styled.div`
    margin-left: 15px;
    margin-top: 10px;
    display: inline-block;
    vertical-align: top;
`;
export const Close = styled.div`
    position: relative;
    cursor: pointer;
    width: 75px;
    @media screen and (max-width: 640px) {
        width: 20px;
    }
`;
export const Margin67 = styled.div`
    margin-bottom: 25px;
`;

const whoOptions = (who) => {
    return who.map(w => {
        return({
            contactType: w.get("prospectId") === null ? "Contact" : w.get("prospectStatus"),
            value: {
                consumerId: w.get("consumerId"),
                prospectId: w.get("prospectId"),
                contactId: w.get("contactId"),
                businessUserId: w.get("businessUserId")
            },
            label: w.get("isArchived") ? w.get("firstName") + " " + w.get("lastName") + " (previous)" : w.get("firstName") + " " + w.get("lastName"),
            isArchived: w.get("isArchived")
        })
    }).toJS();
};

const initState = {
    "photo": null,
    "firstName": null,
    "lastName": null,
    "userId": null,
    "showScheduler": false,
    "isLoading": false,
    "coach": {},
    "contactsAll": [],
    'whoObject': {},
    "who": "",
    'whoLabel': "",
    "whoValid": false,
    "assigned": false,
    "remainingSessions": 0,
    'isChanged': false,
    'inf': "Limited",
    'isInf': false,
    "page": 1,
    "instructions": null,
    "quill": ""
};

class TeamIntroduceModal extends React.Component {
    state=initState;

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.coachSelected !== this.props.coachSelected) {
            disableBodyScrolling();
            return this.init();
        }
    }

    init = async () => {
        // if(!this.props.contactsAllFetched) {
            await this.props.tryGetAllContacts();
        // }
        await this.setState({
            "coach": this.props.coachSelected.toJS(),
            "contactsAll": this.props.contactsAll,
            "quill": ""
        });
        this.handleNewQuill()
    };

    handleInstructions = e => this.setState({"instructions": e});
    handleNewQuill = () => {
        this.setState({
            "quill": <ReactQuill
                id={uuid4()}
                theme={"snow"}
                onChange={this.handleInstructions}
                value={this.state.instructions}
                modules={editorModules}
                formats={editorFormats}
                bounds={'.app'}
                placeholder={"Write your instructions here"}
            />,
            "isLoading": false
        });
    };

    addCoachAndContinue = async (coachId, count, coachName, instructions) => {
        await this.addCoach(coachId, count, coachName, instructions);
        this.setState({"remainingSessions": count, "page": 2});
    };

    updateSessions = async (coachId, sessionsDelta) => {
        this.props.tryPostUpdateProspectsSessions(coachId, sessionsDelta, this.state.who, this.state.whoObject.value.prospectId);
    };

    addCoach = async (coachId, numSessions, coachName, instructions) => {
        const consumerId = this.state.whoObject.value.consumerId;
        const prospectId = this.state.whoObject.value.prospectId;
        const contactType = this.state.whoObject.contactType;
        await this.props.tryPostAssignCoachToProspect(coachId, numSessions, coachName, consumerId, prospectId, contactType, instructions);
        this.setState({'assigned': true});
    };

    handleResetSearchContents = async () => {
        let searchProspectsAddWho;
        if(this.state.who !== '') {
            if(this.state.whoObject.contactType === "Contact") {
                searchProspectsAddWho = await this.props.contactsAll.filter(s => {return(s.get("contactId") === this.state.who)}).get(0);
            } else {
                searchProspectsAddWho = await this.props.contactsAll.filter(s => {return(s.get("consumerId") === this.state.who)}).get(0);
            }
            this.setState({'contactsAll': this.state.contactsAll.push(searchProspectsAddWho)})
        }
    };

    handleDeleteWho = async () => {
        this.setState({'whoObject': {}, "who": "", 'whoLabel': "", "whoValid": false, 'isChanged': false, "assigned": false, "remainingSessions": 0})
    };

    handleChangeWho = async (e) => {
        await this.handleResetSearchContents();
        if(e === null) {
            await this.handleDeleteWho();
            await this.changeSessionAmount(null);
        } else {
            if(e.contactType === "Contact") {
                await this.setState({
                    'whoObject': e,
                    "who": e.value.contactId,
                    'whoLabel': e.label,
                    "whoValid": this.props.contactsAll.filter(p => {return p.get("contactId") === e.value.contactId}).size === 1,
                    'isChanged': true,
                    "assigned": false,
                    "remainingSessions": 0,
                    'contactsAll': this.state.contactsAll.filter(s => {return(s.get("contactId") !== e.value.contactId)})
                });
            } else {
                let assignedCoaches = await this.props.tryGetCoachesAssignedToProspect(e.value.prospectId, "return");
                let assignedCoach = assignedCoaches.filter(a => {return(a.coachId === this.state.coach.coachId)});
                await this.setState({
                    'whoObject': e,
                    "who": e.value.consumerId,
                    'whoLabel': e.label,
                    "whoValid": this.props.contactsAll.filter(p => {return p.get("consumerId") === e.value.consumerId}).size === 1,
                    'isChanged': true,
                    "assigned": assignedCoach.length > 0,
                    "remainingSessions": assignedCoach.length > 0 ? assignedCoach[0].remainingSessions : 0,
                    'contactsAll': this.state.contactsAll.filter(s => {return(s.get("consumerId") !== e.value.consumerId)})
                });
            }
        }
    };

    checkAssigned = async (e) => {
        if (e.get("prospectId") !== null) {
            let assignedCoaches = await this.props.tryGetCoachesAssignedToProspect(e.get("prospectId"), "return");
            let assignedCoach = assignedCoaches.filter(a => {return(a.coachId === this.state.coach.coachId)});
            return assignedCoach.length > 0;
        } else if (e.get("consumerId") !== this.props.userId && e.get("businessUserId") === null) {
            return false;
        } else {
            return true;
        }
    };

    changeSessionAmount = async (e) => {
        this.setState({'inf': e});
    };

    changeSessionAmountIsInf = async () => {
        this.setState({'isInf': !this.state.isInf});
    };

    close = async () => {
        await this.props.setCoachModalStatus(false, {});
        await this.setState({
            ...initState,
            "coach": this.props.coachSelected.toJS(),
            "contactsAll": this.props.contactsAll,
            "isLoading": false
        });
        this.changeShowScheduler(false);
        enableBodyScrolling();
    };

    createVideoMeetingFromScheduler = async (coach) => {
        await this.setState({"photo": coach.photo, "firstName": coach.first, "lastName": coach.last, "userId": coach.consumerId});
        this.changeShowScheduler(true);
    };

    changeShowScheduler = async (status) => {
        this.setState({"showScheduler": status});
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            if(this.props.modalOpen) {
                if(this.state.showScheduler) {
                    return (
                        <>
                            <CommonBackground/>
                            <CommonModal>
                                <SchedulerFlow
                                    userId={this.state.userId}
                                    userFirstName={this.state.firstName}
                                    userLastName={this.state.lastName}
                                    userPhotoUrl={this.state.photo}
                                    eventCustomMinutes={30}
                                    availabilityCloseBtn={true}
                                    availabilityOnClose={() => {return this.close()}}
                                    confirmOnClose={() => {return this.close()}}
                                />
                            </CommonModal>
                        </>
                    )
                } else {
                    if(this.state.page === 1) {

                        return(
                            <>
                                <BigModal>
                                    <Header>
                                        <Left>
                                            <FontTitle30>Assign</FontTitle30>
                                        </Left>
                                        <Right onClick={() => {return this.close()}}>
                                            <FontHeader14><CloseOutlined /><Inline>&nbsp;Close</Inline></FontHeader14>
                                        </Right>
                                    </Header>
                                    <Indent>
                                        <FontBody14>Only {this.props.coachSelected.get("first")} will be notified. By default, two sessions will be assigned.</FontBody14>
                                        <Margin67 />
                                        <div>
                                            <Image src={this.props.coachSelected.get("photo")} alt={""} />
                                            <CoachName><FontBody18>{this.props.coachSelected.get("first")} {this.props.coachSelected.get("last")}</FontBody18></CoachName>
                                        </div>
                                        {/*<StepTitle><FontBody18>Select Client to Introduce</FontBody18></StepTitle>*/}
                                        <Width>
                                            <SearchInputEdit contactSelected={this.state.whoValid}>
                                                <SearchInput
                                                    title={null}
                                                    options={whoOptions(this.state.contactsAll.filter(
                                                        s => {return(s.get("consumerId") !== this.props.userId
                                                            && s.get("consumerId") !== null
                                                            && s.get("businessUserId") === null
                                                            && s.get("coachAssignments").filter(c => {return(c.get("coachId") === this.state.coach.coachId)}) === List()
                                                        )}))}
                                                    id={'who'}
                                                    onChange={this.handleChangeWho}
                                                    placeholder={'Select Client'}
                                                    value={this.state.whoLabel}
                                                />
                                            </SearchInputEdit>
                                        </Width>
                                        <Body><QuillPadding>{this.state.quill}</QuillPadding></Body>
                                        <FitContentFullWidth onClick={() => {return this.addCoachAndContinue(this.props.coachSelected.get("consumerId"), 2, this.props.coachSelected.get("first")+" "+this.props.coachSelected.get("last"), this.state.instructions)}}>
                                            {this.state.whoValid
                                                ? <ButtonPrimary canSubmit={false} label={"Confirm"} />
                                                : <ButtonInactive canSubmit={false} label={"Confirm"} />
                                            }
                                        </FitContentFullWidth>
                                        <br />
                                    </Indent>
                                </BigModal>
                                <Background />
                            </>
                        )

                    } else if(this.state.page === 2) {

                        return(
                            <>
                                <Modal big={false}>
                                    <Header>
                                        <Left>
                                            <FontTitle30>Set Limits</FontTitle30>
                                        </Left>
                                        <Right onClick={() => {return this.setState({"page": 3})}}>
                                            <FontHeader14>Skip</FontHeader14>
                                        </Right>
                                    </Header>
                                    <Indent>
                                        <FontBody14>Choose how many times {this.state.coach.first} and {(this.state.who !== "") ? this.state.whoLabel : "a prospect or client"} can meet. <Btn onClick={this.changeSessionAmountIsInf}>{this.state.isInf ? "Switch to limited session" : "Switch to unlimited sessions"}</Btn>.</FontBody14>
                                        {!this.state.isInf &&
                                            <Constrain>
                                                <ChangeSessions
                                                    initialCount={this.state.remainingSessions}
                                                    expertId={this.state.coach.coachId}
                                                    expertName={this.state.coach.first+" "+this.state.coach.last}
                                                    notifyFirstName={this.state.coach.first}
                                                    updateSessions={this.state.assigned ? this.updateSessions : this.addCoach}
                                                    subTitle={this.state.assigned ? "Sessions" : "Sessions"}
                                                    useSessionDelta={this.state.assigned}
                                                    showNoticeCoach={false}
                                                    closeAction={() => {return this.setState({"page": 3})}}
                                                    saveTimer={0}
                                                    alwaysShowSave={true}
                                                    saveButtonLabel={"Confirm"}
                                                    saveButtonFullWidth={true}
                                                />
                                            </Constrain>
                                        }
                                        {this.state.isInf &&
                                            <Padding>
                                                <Center><FontBody14>Unlimited Sessions</FontBody14></Center>
                                                <ChangeSessionsUnlimited
                                                    expertId={this.state.coach.coachId}
                                                    expertName={this.state.coach.first+" "+this.state.coach.last}
                                                    updateSessions={this.state.assigned ? this.updateSessions : this.addCoach}
                                                    notifyFirstName={this.state.coach.first}
                                                    selected={this.state.isInf ? "Infinite" : "Limited"}
                                                    showNoticeCoach={false}
                                                    closeAction={() => {return this.setState({"page": 3})}}
                                                    saveTimer={0}
                                                    alwaysShowSave={true}
                                                    saveButtonLabel={"Confirm"}
                                                    saveButtonFullWidth={true}
                                                />
                                            </Padding>
                                        }
                                        <br />
                                    </Indent>
                                </Modal>
                                <Background />
                            </>
                        )
                    } else if(this.state.page === 3) {
                        return(
                            <>
                                <Modal big={true}>
                                    <Header>
                                        <Left>
                                            <FontTitle30>Introduction Link</FontTitle30>
                                        </Left>
                                        <Right onClick={() => {return this.setState({"page": 4})}}>
                                            <FontHeader14>Skip</FontHeader14>
                                        </Right>
                                    </Header>
                                    <Indent>
                                        {/*<FontBody14>Send this link to {(this.state.who !== "") ? this.state.whoLabel : "a prospect or client"} so they can get started with {this.state.coach.first}</FontBody14>*/}
                                        <IntroduceLink
                                            clientName={(this.state.who !== "") ? this.state.whoLabel : "a prospect or client"}
                                            coachFirst={this.state.coach.first}
                                            prospectId={this.state.whoObject.value.prospectId}
                                            coachId={this.props.coachSelected.get("consumerId")}
                                        />
                                        <FitContentFullWidth onClick={() => {return this.setState({"page": 4})}}>
                                            <ButtonPrimary canSubmit={false} label={<><Icon active={true}></Icon>&nbsp;&nbsp;<Inline>Continue</Inline></>} />
                                        </FitContentFullWidth>
                                        <br />
                                    </Indent>
                                </Modal>
                                <Background />
                            </>
                        )
                    } else if(this.state.page === 4) {

                        return(
                            <>
                                <Modal big={false}>
                                    <Header>
                                        <Left>
                                            <FontTitle30>Prep Call</FontTitle30>
                                        </Left>
                                        <Right onClick={() => {return this.close()}}>
                                            <FontHeader14>Skip</FontHeader14>
                                        </Right>
                                    </Header>
                                    <Indent>
                                        <FontBody14>Meet with {this.state.coach.first} to set guidelines working with {(this.state.who !== "") ? this.state.whoLabel : "a prospect or client"}</FontBody14>
                                        <PaddingMeeting />
                                        <FitContentFullWidth onClick={() => {return this.createVideoMeetingFromScheduler(this.state.coach)}}>
                                            <ButtonPrimary canSubmit={false} label={<><Icon active={true}><Calendar /></Icon>&nbsp;&nbsp;<Inline>Meeting</Inline></>} />
                                        </FitContentFullWidth>
                                        <br />
                                    </Indent>
                                </Modal>
                                <Background />
                            </>
                        )

                    } else {
                        return null
                    }
                }

            } else {
                return null
            }
        }
    }
}

const mapStateToProps = state => ({
    stages: state.enterprise.prospects.get("prospectStages"),
    userId: state.common.user.get("userId"),
    contactsAll: state.enterprise.contacts.get("contactsAll"),
    contactsAllFetched: state.enterprise.contacts.get("contactsAllFetched"),
    prospectsOne: state.enterprise.prospects.get("prospectsOne"),
    clientsOne: state.enterprise.clientsList.get("clientsOne"),
    modalOpen: state.enterprise.team.coaching.get("modalOpen"),
    coachSelected: state.enterprise.team.coaching.get("modalCoachSelected")
});
const mapDispatchToProps = dispatch => ({
    tryPostUpdateProspectsSessions: (coachId, sessionsDelta, consumerId, prospectId) => dispatch(tryPostUpdateProspectsSessions(coachId, sessionsDelta, consumerId, prospectId)),
    tryGetAllContacts: () => dispatch(tryGetAllContacts()),
    tryPostAssignCoachToProspect: (coachId, numSessions, coachName, consumerId, prospectId, prospectStatus, instructions) => dispatch(tryPostAssignCoachToProspect(coachId, numSessions, coachName, consumerId, prospectId, prospectStatus, instructions)),
    tryGetCoachesAssignedToProspect: (prospectId, storeOrReturn) => dispatch(tryGetCoachesAssignedToProspect(prospectId, storeOrReturn)),
    setCoachModalStatus: (status, coach) => dispatch(setCoachModalStatus(status, coach)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TeamIntroduceModal));
