import styled from "styled-components";
import {colors} from "../../../common/components/colors";

export const Container = styled.div`
    max-width: 1100px;
    width: calc(100% - 120px);
    padding: 0px 60px;
    margin: 80px auto 0px auto;
    color: ${colors.primary100};
    @media screen and (max-width: 1440px) {
        max-width: 1008px;
        width: calc(100% - 80px);
        padding: 0px 40px;
    }
    @media screen and (max-width: 1088px) {
        width: calc(100% - 20px);
        padding: 0px 10px;
    }
    @media screen and (max-width: 460px) {
        margin: 20px auto 0px auto;
    }
`;

export const Title = styled.div`
    margin-bottom: 15px;
`;
export const EachSectionTitle = styled.div`
    margin-bottom: 20px;
`;
export const TitleList = styled.div`
    margin-bottom: 5px;
`;
export const Specialty = styled.div`
    display: inline-block;
    margin-right: 5px;
    vertical-align: top;
    padding: 5px 15px;
    border-radius: 20px;
    background-color: ${colors.border100};
    margin-bottom: 10px;
`;
export const CardWhite = styled.div`
    vertical-align: top;
    padding: 40px;
    border-radius: 4px;
    background-color: ${colors.white};
    border: 1px solid ${colors.border100};
    @media screen and (max-width: 768px) {
        padding: 20px;
    }
    @media screen and (max-width: 460px) {
        padding: 10px;
    }
`;

export const Card = styled.div`
    vertical-align: top;
    padding: 40px;
    border-radius: 4px;
    background-color: ${colors.border100};
    @media screen and (max-width: 768px) {
        padding: 20px;
    }
    @media screen and (max-width: 460px) {
        padding: 10px;
    }
`;

export const Quote = styled.div`
    color: ${colors.secondary70};
    font-weight: 900;
    font-size: 70px;
    margin: 10px 0px -40px 10px;
    text-align: left;
`;
export const QuoteEnd = styled.div`
    color: ${colors.secondary70};
    font-weight: 900;
    font-size: 70px;
    margin: -50px 10px 0px auto;
    text-align: right;
`;
export const Body = styled.div`
    & p {
        font-family: GraphicWebRegular;
        font-size: 18px;
        line-height: 27px;
    }
    & .ql-editor {
        padding-left: 0px;
        padding-right: 0px;
    }
`;
export const Name = styled.div`
    margin-top: 40px;
    text-align: right;
    font-family: CooperLight;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 5px;
    margin-right: 5px;
`;
export const Gray = styled.div`
    color: ${colors.secondary70};
    font-size: 50px;
    display: inline-block;
    vertical-align: top;
    font-weight: 900;
    line-height: 27px;
`;
export const NameText = styled.div`
    display: inline-block;
    vertical-align: middle;
    color: ${colors.white};
`;

export const Left = styled.div`
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 380px);
    padding: 0px 60px 0px 0px;
    @media screen and (max-width: 1088px) {
        display: block;
        width: 100%;
        max-width: 800px;
        padding-right: 0px;
    }
    @media screen and (max-width: 800px) {
        width: 100%;
        padding-right: 0px;
    }
`;
export const Right = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 320px;
    @media screen and (max-width: 1088px) {
        display: block;
        width: 100%;
        max-width: 800px;
    }
    @media screen and (max-width: 800px) {
        display: block;
        width: 100%;
    }
`;
export const Location = styled.div`
    display: none;
    @media screen and (max-width: 1088px) {
        display: block;
    }
`;
export const Icon24 = styled.div`
    display: inline-block;
    vertical-align: top;
    & svg {
        width: 24px;
        height: 24px;
        margin-top: 2px;
    }
`;
export const Icon16 = styled.div`
    display: inline-block;
    vertical-align: top;
    height: 24px;
    & svg {
        width: 20px;
        height: 20px;
        margin-top: 2px;
    }
`;
export const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;
export const Icon14 = styled.div`
    display: inline-block;
    vertical-align: top;
    & svg {
        width: 14px;
        height: 14px;
        margin-top: 4px;
    }
`;


export const LeftSub = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 80px;
    margin-right: 20px;
    @media screen and (max-width: 640px) {
        display: block;
        margin-right: 0px;
        margin-bottom: 10px;
    }
`;
export const RightSub = styled.div`
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 100px);
    @media screen and (max-width: 640px) {
        display: block;
        width: 100%;
    }
`;
export const ImageSub = styled.img`
    width: 80px;
    max-height: 80px;
    object-fit: cover;
    object-position: 0 0;
`;

export const BorderBottom = styled.div`
    margin-bottom: 30px;
    padding-bottom: 15px;
    width: calc(100% - 100px);
    margin-left: 100px;
    border-bottom: 1px solid ${colors.secondary30};
    @media screen and (max-width: 640px) {
        width: 100%;
        margin-left: 0px;
    }
`;
export const SectionSub = styled.div`
    margin-bottom: 15px;
    border: 1px solid ${colors.border100};
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 10px;
`;
export const Description = styled.div`
    margin-top: 10px;
    & p {
        font-size: 18px;
    }
`;
export const SubTitle = styled.div`
    margin-bottom: -5px;
`;
export const Spacing = styled.div`
    margin-bottom: 5px;
`;
export const BigSpacing = styled.div`
    margin-bottom: 40px;
`;
export const GrayColor = styled.div`
    display: inline-block;
    vertical-align: top;
    color: ${colors.primary70};
`;
export const ImagePlaceholder = styled.div`
    width: 80px;
    height: 80px;
    background-color: ${colors.secondary100};
`;
export const EachSection = styled.div`
    margin-bottom: 60px;
`;