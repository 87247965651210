import React from 'react';
import {connect} from 'react-redux';

/* Components */
import { ButtonPrimary, ButtonTertiary } from '../../../common/components/buttons';
import {FontTitle21, FontBody16, FontTitle18} from "../../../common/components/fonts";
import { Page, ModalDelete, Title, Actions, ActionLeft, ActionRight, Background, Center, ActionCancelPlan } from '../components/cancel';

/* Middleware */
import {tryGetBillingCancelPlan, tryGetBillingHistory} from "../middleware/billing";

class BillingCancel extends React.Component {
    state={"isSaved": false, "isSaving": false};

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.planId !== this.props.planId) {
            return this.init()
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    init = async () => {
        this.setState({"isSaved": false, "isSaving": false})
    };

    timeout = 0;

    close = async () => {
        this.props.showCancel(false, "", "")
    };

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({'isSaving': true});
        console.log(this.state);
        await this.props.tryGetBillingCancelPlan(this.props.planId);
        await this.props.tryGetBillingHistory();
        this.setState({'isSaving': false, 'isSaved': true});
        this.timeout = setTimeout(this.close, 1000);
    };

    render() {
        return (
            <Page>
                <ModalDelete>
                    {this.state.isSaved
                        ? <Center><FontTitle18>Your plan has been canceled</FontTitle18></Center>
                    :
                    <>
                        <Title><FontTitle21>Are you sure?</FontTitle21></Title>
                        <FontBody16>Do you really want to cancel {this.props.planName}? This cannot be undone.</FontBody16>
                        <Actions>
                            <ActionLeft onClick={() => {this.props.showCancel(false, "", "")}}>
                                <ButtonTertiary submit={false} label={"Back"} />
                            </ActionLeft>
                            <ActionRight>
                                <ActionCancelPlan onClick={this.handleSubmit}>
                                    <ButtonPrimary submit={false} label={"Cancel Plan"}/>
                                </ActionCancelPlan>
                            </ActionRight>
                        </Actions>
                    </>
                    }
                </ModalDelete>
                {this.state.isSaved ? 
                    <Background onClick={this.close}/>
                :
                    <Background />
                }
            </Page>
        )
    }
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => ({
    tryGetBillingCancelPlan: (planId) => dispatch(tryGetBillingCancelPlan(planId)),
    tryGetBillingHistory: () => dispatch(tryGetBillingHistory())
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingCancel);