import React from 'react';
import {connect} from "react-redux";

/* Containers */
import SchedulerFlow from "../../../../iframes/scheduler/containers/flow";

/* Components */
import {PreviewBar, Close} from '../components/preview';
import {CloseOutlined} from '@ant-design/icons';
import {FontHeader18} from '../../../../common/components/fonts';
import {LogoLoading} from "../../../../common/components/loading";

class PreviewScheduler extends React.Component {
    state={"isLoading": true};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        if(this.props.profileFetched) {
            this.setState({"isLoading": false})
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.profileFetched && this.props.profileFetched) {
            this.setState({"isLoading": false});
        }
    }

    render() {
        if(this.state.isLoading) {
            return(<LogoLoading />)
        } else {
            return(
                <PreviewBar isActive={this.props.preview} isSupport={this.props.user.get("isSupport")}>
                    <Close onClick={() => {return this.props.updatePreview(false)}}>
                        <FontHeader18><CloseOutlined />&nbsp;Preview</FontHeader18>
                    </Close>
                    <SchedulerFlow
                        userId={this.props.profile.get("id")}
                        userFirstName={this.props.profile.get("firstName")}
                        userLastName={this.props.profile.get("lastName")}
                        userPhotoUrl={this.props.profile.get("photo")}
                    />
                </PreviewBar>
            )
        }
    }
}

const mapStateToProps = state => ({
    profile: state.enterprise.scheduler.expertProfile.get("expertProfile"),
    profileFetched: state.enterprise.scheduler.expertProfile.get("expertProfileFetched"),
    user: state.common.user
});

export default connect(mapStateToProps, null)(PreviewScheduler);
