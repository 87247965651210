import { Map, List, fromJS } from 'immutable';

/* Immutable */

const PROGRESS_COURSES = 'PROGRESS_COURSES';
const PROGRESS_COURSES_FETCHING = 'PROGRESS_COURSES_FETCHING';
const PROGRESS_STORE_RESET = 'PROGRESS_STORE_RESET';
const MY_GOALS = 'MY_GOALS';
const MY_GOAL_SELECTED = 'MY_GOAL_SELECTED';

/* Actions */

export const setProgressCourses = (courses) => ({ 'type': PROGRESS_COURSES, 'courses': courses });
export const setProgressCoursesFetching = (status) => ({ 'type': PROGRESS_COURSES_FETCHING, 'status': status });
export const setCoursesStoreReset = () => ({ 'type': PROGRESS_STORE_RESET });
export const setMyGoals = (myGoals) => ({ 'type': MY_GOALS, 'myGoals': myGoals })
export const setMyGoalSelected = (goal) => ({ 'type': MY_GOAL_SELECTED, 'selectedGoal': goal })

/* Initial State */

const initialState = Map({
    courses: List(),
    coursesFetched: false,
    coursesFetching: false,
    myGoals: List(),
    myGoalSelected: Map(),
});

/* Reducer */

const ProgressReducer = (state = initialState, action) => {
    switch (action.type) {
        case PROGRESS_COURSES:
            return state.merge({
                'courses': fromJS(action.courses),
                'coursesFetched': true
            });
        case MY_GOALS:
            return state.merge({
                'myGoals': fromJS(action.myGoals)
            })
        case MY_GOAL_SELECTED:
            return state.merge({
                'myGoalSelected': fromJS(action.selectedGoal)
            })
        case PROGRESS_COURSES_FETCHING:
            return state.merge({ 'coursesFetching': action.status });
        case PROGRESS_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default ProgressReducer;
