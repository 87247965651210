import React from "react";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';

/* Components */
import {LogoLoading} from "../../../common/components/loading";

/* Containers */
import MeetingRecordFromCalendar from './meetingfromcal';

/* Middleware */
import {tryGetOneSession} from '../middleware/sessions';

/* Store */
import {setSessionsModal, setOneSession} from "../store/sessions";

class MeetingsInfo extends React.Component {
    state = {isLoading: true, sessionOne: null, isRescheduling: false};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        if (this.props.sessionSelected === null) {
            this.props.history.push('/meetings');
        } else {
            if (this.props.sessionFromEventApi) {
                const sessionId = this.props.sessionSelected.id;
                const consumerId = this.props.sessionSelected.extendedProps.remoteUserId;
                await this.props.tryGetOneSession(consumerId, sessionId);
            } else {
                const sessionId = this.props.sessionSelected.get("id");
                const consumerId = this.props.sessionSelected.get("extendedProps").get("remoteUserId");
                await this.props.tryGetOneSession(consumerId, sessionId);
            }
            this.setState({"sessionOne": this.props.sessionOne, "isLoading": false});
        }
    };

    update = async () => {
        this.setState({"sessionOne": this.props.sessionOne});
    };

    componentWillUnmount() {
        if(!this.state.isRescheduling) {
            this.props.setSessionsModal(false, null, null, null, null, null);
        }
        this.props.setOneSession({});
    }

    onRescheduleSession = async () => {
        await this.setState({"isRescheduling": true});
    };

    onCancelSession = async () => {
        return this.props.history.push("/meetings");
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <MeetingRecordFromCalendar
                    data={this.state.sessionOne}
                    onRescheduleSession={this.onRescheduleSession}
                    onCancelSession={this.onCancelSession}
                    forceUpdate={this.update}
                />
            )
        }
    }
}

const mapStateToProps = state => ({
    sessionSelected: state.enterprise.sessions.get("sessionSelected"),
    sessionOne: state.enterprise.sessions.get("sessionOne"),
    sessionFromEventApi: state.enterprise.sessions.get("sessionFromEventApi")
});

const mapDispatchToProps = dispatch => ({
    setSessionsModal: (status, session, action, who, details, guests) => dispatch(setSessionsModal(status, session, action, who, details, guests)),
    tryGetOneSession: (consumerId, sessionId) => dispatch(tryGetOneSession(consumerId, sessionId)),
    setOneSession: (session) => dispatch(setOneSession(session)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MeetingsInfo));
