import React, {useState, useEffect} from 'react';
import styled, {keyframes, css} from 'styled-components';
import validator from "validator";

/* Components */
import {colors} from "../../../common/components/colors";
import {FontBody14, FontBody24, FontBody16} from '../../../common/components/fonts';
import ContactButtons from './contactbuttons'
import {ContactTitle} from "./components";
import {PhoneInput} from "../../../common/components/inputs";

const SignupFlowContainer = styled.div`
  perspective: 1000px;
  margin-top: 30px;
  background-color:transparent;

  animation: ${props =>
    props.animate ?
        css`
    ${nextPage} 1s ease-in-out` : css`${backPage} 1s ease-in-out`};
  & > div:nth-child(2) {
    border-radius: 0 0 20px 20px !important;
    background-color: white !important;
    color: black;
  }

  & .disclosureLabel, .disclosureLabel a {
    color: black !important;
  }
  
  //& .password-input {
  //  display:none;
  //}

  position: relative;

  & .prevButton {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0;
  }

`;

const nextPage = keyframes`
  0% {
    opacity: 0;
    transform:rotateY(180deg);
  }
  100% {
    opacity: 1;
    transform:rotateY(0deg);
  }
`;

const backPage = keyframes`
  0% {
    opacity: 0;
    transform:rotateY(180deg);
  }
  100% {
    opacity: 1;
    transform:rotateY(0deg);
  }
`;

const Show = styled.div`
  display: ${({toggle}) => toggle ? 'flex' : 'none'};
  flex-direction: column;
  gap: 20px;
`;
const Container = styled.form`
    max-width: 768px;
    width: calc(100% - 20px);
    padding: 0 10px;
    margin: 0 auto 0 auto;
`;
const Warning = styled(FontBody14)`
  margin: -10px 0 10px 22px;
  color: ${colors.alert100};
`;
const Instructions = styled(FontBody24)`
  margin: 0 0 30px 0;
  color: white;
`;
const Disclosure = styled(FontBody16)`
  margin: 20px 0 30px 0;
  color: rgba(255, 255, 255, 0.5);
`;
const inputsContainersStyles = {
    "margin": "0px 0px 30px 0px"
};
const inputsStyles = {
    "border": "none",
    "borderRadius": "40px",
    "lineHeight": "36px",
    "backgroundColor": colors.white,
    "boxShadow": "0 3px 10px 0 rgba(0, 0, 0, 0.15)",
    "fontSize": "24px"
};

ContactPage2.defaultProps = {
    show: false,
    onSubmit: () => {},
}

export default function ContactPage2(props) {
    const show = props.show;
    const onSubmit = props.onSubmit;
    const [phone, setPhone] = useState("");
    const [phoneValid, setPhoneValid] = useState(true);
    const [phoneChanged, setPhoneChanged] = useState(false);


    // useEffect(() => {
    //     setPhoneValid(validator.isMobilePhone(phone, 'en-US'));
    // }, [phone])

    const handleChangePhone = async e => {
        e.preventDefault();
        setPhone(e.target.value);
        setPhoneValid(true);
        setPhoneChanged(true);
    };

    const onSubmitForm = (e) => {
        e.preventDefault();
        const checkPhoneValid = validator.isMobilePhone(phone, 'en-US');
        if(checkPhoneValid && phone !== "") {
            onSubmit(phone);
        } else {
            setPhoneValid(false);
        }
    }

    return(
        <Show toggle={show}>
            <ContactTitle color={colors.white}>Let’s Chat</ContactTitle>
            <SignupFlowContainer animate={show}>
                <Container
                    onSubmit={onSubmitForm}
                    style={{backgroundColor: colors.backgroundColor2, borderRadius: "20px", padding: "30px 45px", width: "calc(100% - 90px)"}}
                >
                    <Instructions>Provide your number for a quick consultation with a Willow Matching Specialist</Instructions>
                    <PhoneInput
                        title={"Phone"}
                        valid={phoneValid}
                        warning={""}
                        id={"phone"}
                        onChange={handleChangePhone}
                        placeholder={'(###) ###-####'}
                        value={phone}
                        options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}}
                        containerStyles={inputsContainersStyles}
                        inputStyles={inputsStyles}
                    />
                    <Warning>{(!phoneValid && phoneChanged) ? "Enter a valid mobile phone" : <div>&nbsp;</div>}</Warning>
                    <ContactButtons />
                </Container>
            </SignupFlowContainer>
            <Disclosure>By giving us your number a member of the Willow team will reach out and discuss your matches before passing your information on to the advisor.</Disclosure>
        </Show>

    );
}