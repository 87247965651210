import React from 'react';
import styled from "styled-components";

/* Components */
import {FontBody16, FontBody18, FontBody21, FontBody24, FontTitle40} from "../../../common/components/fonts";
import {ArrowLeftOutlined, CloseOutlined} from "@ant-design/icons";
import {colors} from "../../../common/components/colors";
import {images} from "../../../common/components/images";

const Close = styled.div`
    background-color: ${colors.secondary100};
    width: 100%;
    border-radius: 6px 6px 0 0;
    line-height: 60px;
`;
const CloseMessage = styled.div`
    display: inline-block;
    color: ${colors.border100};
    padding: 0 0 0 15px;
    width: calc(100% - 63px);
`;
const CloseButton = styled.div`
    padding: 10px 15px;
    color: ${colors.border100};
    cursor: pointer;
    font-weight: bold;
    width: 18px;
    font-size: 14px;
    display: inline-block;
`;
const BackButton = styled.div`
    cursor: pointer;
    width: fit-content;
`;

export const ActionBarModal = ({currentPage, changePage, closeSupport}) => (
    <Close>
        <CloseMessage>
            {currentPage !== 1
                ? <BackButton onClick={() => {return changePage(1)}}>
                    <FontBody16><ArrowLeftOutlined/>&nbsp;Back</FontBody16>
                </BackButton>
                : <FontBody16>Need Help?</FontBody16>
            }
        </CloseMessage>
        <CloseButton onClick={closeSupport} >
            <FontBody18><CloseOutlined /></FontBody18>
        </CloseButton>
    </Close>
)

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 52px;
  gap: 20px;
`;
const Back = styled.div`
  width: fit-content;
  color: ${colors.primary100};
  cursor: pointer;
`;
const CloseIcon = styled.img`
  width: 26px;
  height: 29px;
  cursor: pointer;
`;


export const ActionBarLeftBar = ({currentPage, changePage, closeSupport}) => (
    <Top>
        <div>
            {currentPage !== 1
                ? <FontBody24><Back onClick={() => {return changePage(1)}}><img src={images.chevronLeft} alt={"<"} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Back</Back></FontBody24>
                : <FontTitle40 spaced={false}>How can we help?</FontTitle40>
            }
        </div>
        <div onClick={closeSupport} >
            <CloseIcon src={images.firmXIcon} alt={"X"} />
        </div>
    </Top>
)

const TopSelection = styled.div`
  border-bottom: 1px solid ${colors.border100};
  padding: 10px 0;
  cursor: pointer;
`;
const Icon = styled.div`
    padding: 10px;
    background-color: ${colors.border100};
    border-radius: 60px;
    width: 32px;
    text-align: center;
    color: ${colors.primary70};
`;
const Inline = styled.div`    
    display: inline-block;
    vertical-align: top;
`;
const Option = styled.div`
    padding-top: 14px;
    padding-left: 10px;
    width: calc(100% - 10px);
`;

const LeftBarSelection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  border-bottom: 1px solid ${colors.border100};
  padding: 26px 0;
  margin-right: 50px;
  justify-content: flex-start;
  align-items: center;
  font-size: 28px;
  cursor: pointer;
`;
const LeftBarIcon = styled.img`
  width: 32px;
  height: 32px;
`;

export const SelectionModal = ({onClick, page, icon, label, leftBar}) => {
    if(leftBar) {
        return(
            <LeftBarSelection onClick={() => {return onClick(page)}}>
                <LeftBarIcon src={icon} alt={""}/>
                <FontBody24>{label}</FontBody24>
            </LeftBarSelection>
        )
    } else {
        return(
            <TopSelection onClick={() => {return onClick(page)}}>
                <Inline><Icon><FontBody21>{icon}</FontBody21></Icon></Inline>
                <Inline><Option><FontBody16>{label}</FontBody16></Option></Inline>
            </TopSelection>
        )
    }
}