import React from 'react';
import { withRouter } from "react-router-dom";
import styled from 'styled-components';

/* Components */
import {colors} from "../../../common/components/colors";
import {images} from "../../../common/components/images";
import { FontBody21, FontBody16, FontTitle24 } from '../../../common/components/fonts';
import { FlexRow, DashboardContainer } from '../components/home';
import { IconButton24To14 } from '../../../common/components/buttons';

const Team = styled.div`
    display: flex;
    justify-content: space-evenly;
`;
const TeamIcons = styled(FlexRow)`
    justify-content: space-evenly;
    flex-direction: column;
    gap: 10px;
`;
const MemberIcon = styled.img`
    border-radius: 50%;
    height: 80px;
    width: 80px;
    object-fit: cover;
    cursor: pointer;
`;
const FindAnotherButton = styled.div`
    align-self: center;
    margin: auto;
    background-color: ${colors.action100};
    border-radius: 25px;
    color: ${colors.white};
    cursor: pointer;
    padding: 10px 30px;
    width: 150px;
    text-align: center;
    font-weight: bold;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
`;

class ConsumerTeam extends React.Component {
    static defaultProps = {
        members: [],
        selectMember: () => {}
    }

    render() {
        return(
            <DashboardContainer>
                <FlexRow>
                    <FontTitle24>{'My Team'}</FontTitle24>
                    <IconButton24To14 onClick={() => this.props.history.push('/my-team')} image={images.chevronRightDark} />
                </FlexRow>
                <br />
                {this.props.members.length > 0
                    ?
                    <Team>
                        {this.props.members.slice(0, Math.min(3, this.props.members.length)).map((m, idx) => {
                            return (
                                <TeamIcons key={idx}>
                                    <MemberIcon
                                        src={m.photo}
                                        alt={m.first + ' ' + m.last}
                                        onClick={() => this.props.selectMember(m)}
                                        style={{height: '100px', width: '100px'}}
                                    />
                                    <FontBody21>{m.first + ' ' + m.last}</FontBody21>
                                </TeamIcons>
                            )
                        })}
                    </Team>
                    :
                    <>
                        {/*<FontBody16>{'No team members'}</FontBody16>*/}
                        <FindAnotherButton onClick={() => this.props.history.push('/my-team/find')}>
                            <FontBody16>{'Find A Coach'}</FontBody16>
                        </FindAnotherButton>
                    </>
                }
            </DashboardContainer>
        )
    }
}

export default withRouter(ConsumerTeam);