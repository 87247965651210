import React from 'react';
import styled from 'styled-components';

import {colors} from '../../../common/components/colors';

export const Card = styled.div`
    -webkit-box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.1);
    background-color: ${colors.white};
    color: ${colors.primary100};
    width: 70%;
    border-radius: 25px;
    margin: auto;
    padding: 40px;
    @media screen and (max-width: 800px) {
        padding-bottom: 75px;
    }
`;

export const Bookmarks = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    row-gap: 25px;
    flex-wrap: wrap;
    position: relative;
    justify-content: center;
    align-items: left;
`;

export const OneCourse = styled.div`
    cursor: pointer;
    width: fit-content;
    height: fit-content;
`;
export const Title = styled.div`
    padding: 0px 20px 40px;
`;
export const CourseImage = styled.img`
    height: 169px;
    width: 169px;
    @media screen and (max-width: 500px) {
        height: 154px;
        width: 154px;
    }
    @media screen and (max-width: 460px) {
        height: calc(100% - 3px);
        width: calc(100% - 3px);
    }
    object-fit: cover;
    border-radius: 30px 30px 0px 30px;
    border-left: 3px solid ${colors.white};
    border-bottom: 3px solid ${colors.white};
    position: relative;
    z-index: 1;
`;
export const TitleBox = styled.div`
    padding: 40px 30px 15px 30px;
    width: calc(169px - 60px);
    @media screen and (max-width: 500px) {
        padding: 40px 30px 15px 30px;
        width: calc(154px - 60px);
    }
    @media screen and (max-width: 460px) {
        padding: 40px 20px 15px 20px;
        width: calc(100% - 43px);
    }
    margin-left: 3px;
    margin-top: -30px;
    background-color: ${colors.backgroundColor1};
    position: relative;
    border-radius: 0px 0px 20px 20px;
`;


export const SettingsCardMissingInfo = styled.div`
    -webkit-box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.1);
    background-color: ${colors.white};
    color: ${colors.primary100};
    max-width: 300px;
    margin: 0px auto 40px auto;
    position: relative;
    padding: 40px 20px;
    width: calc(100% - 40px);
    border-radius: 20px;
`;

export const SpaceBetweenMissingInfo = styled.div`
    display: inline-flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
    color: ${colors.secondary100};
    ${props => props.direction !== "column"} {
        flex-direction: column;
    }
    ${props => props.direction !== "row"} {

    }
`;