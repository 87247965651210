
/* Middleware - tryGetSettingsPersonalInfo */
export const devSettingsPersonalInfoData = {
    "first": 'Jason',
    "last": 'Aronson',
    "phone": '5552523253',
    "email": 'jason@coolcoaches.com',
    "timeZone": 'eastern',
    "companyName": "Willow Network, Inc."
};

export const devSettingsPersonalInfoUpdateData = (data) => ({
    "first": data.first,
    "last": data.last,
    "phone": data.phone,
    "email": data.email,
    "timeZone": data.timeZone,
    "companyName": data.companyName
});
