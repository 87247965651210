import React from 'react';

/* Form Elements */
import ElementBorder from '../components/elementborder';
import ElementSpacer from '../components/elementspacer';
import ElementTitle from '../components/elementtitle';
import ElementSubtitle from '../components/elementsubtitle';
import ElementBody from '../components/elementbody';
import ElementBodyEdit from '../components/elementbodyedit';
import ElementDisclosure from '../components/elementdisclosure';
import ElementHTML from '../components/elementhtml';
import ElementHTMLEdit from '../components/elementhtmledit';
import ElementTextArea from '../components/elementtextarea';
import ElementSpecialCoaching from "../components/elementspecialcoaching";
import ElementCheckbox from '../components/elementcheckbox';
import ElementRadioBox from '../components/elementradiobox';
import ElementImageBox from '../components/elementimagebox';
import ElementVideo from '../components/elementvideo';
import ElementImage from '../components/elementimage';
import ElementSlider from '../components/elementslider';
import ElementCalendar from '../components/elementcalendar';
import ElementMultiSelect from '../components/elementselect';

// import {ToDo1InputHorizontal, ToDo2InputsHorizontal, ToDoInputsPhone, ToDoInputDropdown} from '../components/todoinputs';
// import ToDoColumnIcons from '../components/todocolumnicons';

const Elements = ({elements, onSaveElement, disabled}) => (
    <>
        {elements.map((e, index) => {

            // Spacers
            if(e.type === 'border') {
                return (<ElementBorder key={index} style={e.style} elementId={e.id} />)
            } else if(e.type === "spacer") {
                return (<ElementSpacer key={index} style={e.style} elementId={e.id} />)
            }

            // HTML
            if(e.type === "html" && disabled) {
                return(<ElementHTML key={index} text={e.text} style={e.style} elementId={e.id} disabled={disabled} onSaveElement={onSaveElement} />)
            } else if(e.type === "html" && !disabled) {
                return(<ElementHTMLEdit key={index} text={e.text} style={e.style} elementId={e.id} disabled={disabled} onSaveElement={onSaveElement} />)
            }

            // Text
            if(e.type === "title") {
                return (<ElementTitle key={index} text={e.text} style={e.style} elementId={e.id} disabled={disabled} onSaveElement={onSaveElement}/>)
            } else if(e.type === "subtitle") {
                return (<ElementSubtitle key={index} text={e.text} style={e.style} disabled={disabled} onSaveElement={onSaveElement} elementId={e.id}/>)
            } else if(e.type === "body" && disabled) {
                return (<ElementBody key={index} text={e.text} style={e.style} elementId={e.id} disabled={disabled} onSaveElement={onSaveElement}/>)
            } else if(e.type === "body" && !disabled) {
                return (<ElementBodyEdit key={index} text={e.text} style={e.style} elementId={e.id} disabled={disabled} onSaveElement={onSaveElement}/>) 
            } else if (e.type === "disclosure") {
                return (<ElementDisclosure key={index} text={e.text} style={e.style} elementId={e.id} disabled={disabled} onSaveElement={onSaveElement}/>)
            }

            // Media
            if(e.type === "video") {
                return (<ElementVideo key={index} videoUrl={e.videoUrl} videoControls={e.videoControls} style={e.style} elementId={e.id} disabled={disabled} onSaveElement={onSaveElement}/>)
            } else if(e.type === "image") {
                return (<ElementImage key={index} imageUrl={e.imageUrl} style={e.style} elementId={e.id} disabled={disabled} onSaveElement={onSaveElement}/>)
            }

            // Inputs
            if (e.type === 'textArea') {
                return (
                    <ElementTextArea
                        key={index}
                        onSaveElement={onSaveElement}
                        elementId={e.id}
                        subtitle={e.subtitle}
                        canWrite={disabled}
                        placeholder={e.placeholder}
                        text={e.text}
                        isRequired={e.isRequired}
                        minLength={e.minLength}
                        minRows={e.minRows}
                        style={e.style}
                    />
                )
            }
            // if (r.get("sectionType") === "input1horizontal") {
            //     return (<ToDo1InputHorizontal key={r.get("id")} renderId={r.get("id")} pageNum={p.get("pageNum")} checkPageCanContinue={checkPageCanContinue} canContinue={changeCanContinue} updateDataProps={onSaveElement} data={r.get("data")}/>)
            // }
            // if (r.sectionType === "input2horizontal") {
            //     return (<ToDo2InputsHorizontal key={r.get("id")} renderId={r.get("id")} pageNum={p.get("pageNum")} checkPageCanContinue={checkPageCanContinue} canContinue={changeCanContinue} updateDataProps={onSaveElement} data={r.get("data")}/>)
            // }
            // if (r.sectionType === 'inputphone') {
            //     return (<ToDoInputsPhone key={r.get("id")} renderId={r.get("id")} pageNum={p.get("pageNum")} checkPageCanContinue={checkPageCanContinue} canContinue={changeCanContinue} updateDataProps={onSaveElement} data={r.get("data")} />)
            // }

            // Selectors
            if(e.type === "checkBox") {
                return (
                    <ElementCheckbox
                        key={index}
                        onSaveElement={onSaveElement}
                        elementId={e.id}
                        subtitle={e.subtitle}
                        disclosure={e.disclosure}
                        warning={e.warning}
                        options={e.options}
                        maxCheckable={e.maxCheckable === null ? e.options.length : e.maxCheckable}
                        isRequired={e.isRequired}
                        style={e.style}
                    />
                )
            }
            if (e.type === "radioBox") {
                return (
                    <ElementRadioBox
                        key={index}
                        onSaveElement={onSaveElement}
                        elementId={e.id}
                        subtitle={e.subtitle}
                        disclosure={e.disclosure}
                        warning={e.warning}
                        options={e.options}
                        isRequired={e.isRequired}
                        style={e.style}
                    />
                )
            }
            // if (r.get("sectionType") === "ToDoInputDropdown") {
            //     return (<ToDoInputDropdown key={r.get("id")} renderId={r.get("id")} canContinue={changeCanContinue} pageNum={p.get("pageNum")} checkPageCanContinue={checkPageCanContinue}  updateDataProps={onSaveElement} data={r.get("data")}/>)
            // }

            // Special
            if (e.type === "specialCoaching") {
                return (
                    <ElementSpecialCoaching
                        key={index}
                        onSaveElement={onSaveElement}
                        elementId={e.id}
                        icon={e.icon}
                        subtitle={e.subtitle}
                        disclosure={e.disclosure}
                        options={e.options}
                        isRequired={e.isRequired}
                        style={e.style}
                    />
                )
            }  else if (e.type === "imageBox") {
                return (
                    <ElementImageBox
                        key={index}
                        title={e.title}
                        imageUrl={e.imageUrl}
                        body={e.body}
                        style={e.style}
                        elementId={e.id}
                    />)
            }
            if (e.type === 'slider') {
                return (
                    <ElementSlider
                        key={index}
                        elementId={e.id}
                        title={e.title}
                        value={e.value}
                        onSaveElement={onSaveElement}
                        containerStyle={e.style.containerStyle}
                        handleStyle={e.style.handleStyle}
                        trackStyle={e.style.trackStyle}
                        railStyle={e.style.railStyle}
                    />)
            } else if (e.type === 'calendar') {
                return (
                    <ElementCalendar
                        key={index}
                        elementId={e.id}
                        onSaveElement={onSaveElement}
                        title={e.title}
                        value={e.value}
                    />
                )
            } else if (e.type === 'multiSelect') {
                return (
                    <ElementMultiSelect
                        key={index}
                        onSaveElement={onSaveElement}
                        elementId={e.id}
                        disabled={disabled}
                        options={e.options}
                        isRequired={e.isRequired}
                        style={e.style}
                        maxSelectable={e.maxSelectable}
                    />
                )
            }
            // if (r.sectionType === "columnicons") {
            //     return (<ToDoColumnIcons key={r.get("id")} renderId={r.get("id")} title={r.get("title")} columns={r.get("columns")}/>)
            // }

        })}
    </>
);

export default Elements;