import { Map } from 'immutable';
import moment from "moment";

/* Immutable */

const NOTICE_CALL = 'NOTICE_CALL';
const NOTICE_CALL_DOWNLOAD_PENDING = 'NOTICE_CALL_DOWNLOAD_PENDING';
const NOTICE_CALL_DOWNLOAD_COMPLETE = 'NOTICE_CALL_DOWNLOAD_COMPLETE';
const NOTICE_CALL_STORE_RESET = 'NOTICE_CALL_STORE_RESET';

/* Actions */

export const setNoticeCall = (notice) => ({'type': NOTICE_CALL, 'notice': notice});
export const setNoticeCallDownloadPending = () => ({'type': NOTICE_CALL_DOWNLOAD_PENDING});
export const setNoticeCallDownloadComplete = () => ({'type': NOTICE_CALL_DOWNLOAD_COMPLETE});
export const setNoticeCallStoreReset = () => ({'type': NOTICE_CALL_STORE_RESET});

/* Initial State */

const initialState = Map({
    noticeCall: Map({
        id: null,
        remoteUserName: null,
        start: null,
        phone: null,
        room: null,
        videoStatus: "",
        videoUrl: ""
    }),
    noticeCallDownloading: false
});

/* Reducer */

const noticeCallReducer = (state=initialState, action) => {
    switch (action.type) {
        case NOTICE_CALL:
            return state.merge({"noticeCall": Map({
                    id: action.notice.id,
                    remoteUserName: action.notice.remoteUserName,
                    remoteUserReferralSource: action.notice.remoteUserReferralSource,
                    remoteUserFirstName: action.notice.remoteUserFirstName,
                    remoteUserLastName: action.notice.remoteUserLastName,
                    start: action.notice.start,
                    phone: action.notice.phone,
                    room: action.notice.room,
                    videoStatus: action.notice.videoStatus,
                    videoUrl: action.notice.videoUrl
                })});
        case NOTICE_CALL_DOWNLOAD_PENDING:
            return state.merge({'noticeCallDownloading': true});
        case NOTICE_CALL_DOWNLOAD_COMPLETE:
            return state.merge({'noticeCallDownloading': false});
        case NOTICE_CALL_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default noticeCallReducer;
