import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {
    setCoachesForWmcFetching,
    setCoachesAssignedToWmc,
    setCoachesForWmcFetched,
    setClientsForCoachFetching,
    setClientsAssignedToCoach,
    setClientsForCoachFetched
} from '../store/coaching';

export const tryGetCoachesForWmc = (wmcId) => {
    return async dispatch => {
        dispatch(setCoachesForWmcFetching(true));
        const coaches = await axiosReq('apiv1/wmc/' + wmcId + '/coach/get-all-wmc-coaches', "GET", {}, {});
        await dispatch(setCoachesAssignedToWmc(coaches));
        dispatch(setCoachesForWmcFetched(true));
        return dispatch(setCoachesForWmcFetching(false));
    }
};

export const tryGetClientsForCoach = (wmcId, coachId) => {
    return async dispatch => {
        dispatch(setClientsForCoachFetching(true));
        const clients = await axiosReq('apiv1/wmc/' + wmcId + '/coach/' + coachId + '/get-assigned-prospects', "GET", {}, {});
        await dispatch(setClientsAssignedToCoach(clients));
        dispatch(setClientsForCoachFetched(true));
        return dispatch(setClientsForCoachFetching(false));
    }
};

export const tryUpdateExpertCategories = (data, coachId) => {
    return async dispatch => {
        dispatch(setClientsForCoachFetching(true));
        await axiosReq('apiv1/coach/settings/advising-categories/' + coachId, "PUT", {"expertCategoryIds": data}, {});
        return dispatch(setClientsForCoachFetching(false));
    }
};
