import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {
    setCourses,
    setCoursesFetching,
    setCoursesAdd,
    setCoursesCreating,
    setCoursesUpdate,
    setCoursesUpdating,
    setCoursesDeleted,
    setCoursesDeleting,
    setCoursesReport,
    setCoursesReportFetching,
    setCoursesAssigned,
    setCoursesAssigning
} from '../store/courses';

/* Static */
import {
    devEnterpriseExistingCourses,
    devEnterpriseNewCourse,
    devEnterpriseEditedCourse,
    devEnterpriseCourseReport,
} from '../static/devdata';

export const tryGetCourses = () => {
    return async dispatch => {
        dispatch(setCoursesFetching(true));
        const coursesAll = await axiosReq('apiv1/coach/tasks', "GET", {}, devEnterpriseExistingCourses);
        await dispatch(setCourses(coursesAll));
        return dispatch(setCoursesFetching(false));
    }
};

export const tryCreateCourse = (courseData) => {
    return async dispatch => {
        dispatch(setCoursesCreating(true));
        const newCourse = await axiosReq('apiv1/coach/tasks', "POST", courseData, devEnterpriseNewCourse);
        await dispatch(setCoursesAdd(newCourse));
        return dispatch(setCoursesCreating(false));
    }
};

export const tryUpdateCourse = (courseId, courseData) => {
    return async (dispatch, getState) => {
        dispatch(setCoursesUpdating(true));
        const editedCourse = await axiosReq('apiv1/coach/tasks/'+courseId, "PUT", courseData, devEnterpriseEditedCourse(courseId, courseData, getState));
        await dispatch(setCoursesUpdate(editedCourse));
        return dispatch(setCoursesUpdating(false));
    }
};

export const tryDeleteCourse = (courseId) => {
    return async dispatch => {
        dispatch(setCoursesDeleting(true));
        const deletedCourse = await axiosReq('apiv1/coach/tasks/'+courseId, "DELETE", {}, {});
        await dispatch(setCoursesDeleted(courseId));
        return dispatch(setCoursesDeleting(false));
    }
};

export const tryAssignCourseToConsumer = (courseId, consumerId) => {
    return async dispatch => {
        dispatch(setCoursesAssigning(true));
        const assignedCourse = await axiosReq('apiv1/coach/tasks/'+courseId+'/assign/'+consumerId, "POST", {}, {});
        await dispatch(setCoursesAssigned(courseId, consumerId));
        return dispatch(setCoursesAssigning(false));
    }
};

export const tryGetCourseReport = (courseId) => {
    return async dispatch => {
        dispatch(setCoursesReportFetching(true));
        const report = await axiosReq('apiv1/coach/tasks/'+courseId+'/reports', "GET", {}, devEnterpriseCourseReport);
        await dispatch(setCoursesReport(report));
        return dispatch(setCoursesReportFetching(false));
    }
};
