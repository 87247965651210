import { Map, List, fromJS } from 'immutable';

/* Transformation */

import {listGuestNames} from "../meetings/middleware/sessions";

/* Immutable */

const CLIENTS_ACTIVITY = 'CLIENTS_ACTIVITY';
const CLIENTS_ACTIVITY_GET = 'CLIENTS_ACTIVITY_GET';
const CLIENTS_ACTIVITY_FUTURE_SESSIONS_GET = 'CLIENTS_ACTIVITY_FUTURE_SESSIONS_GET';
const CLIENTS_ACTIVITY_FUTURE_SESSIONS = 'CLIENTS_ACTIVITY_FUTURE_SESSIONS';
const CLIENTS_ACTIVITY_PROSPECT_ID_LOADED = 'CLIENTS_ACTIVITY_PROSPECT_ID_LOADED';
const CLIENTS_ACTIVITY_ADD_RECORD = 'CLIENTS_ACTIVITY_ADD_RECORD';
const CLIENTS_ACTIVITY_UPDATE_RECORD = 'CLIENTS_ACTIVITY_UPDATE_RECORD';
const CLIENTS_ACTIVITY_DELETE_RECORD = 'CLIENTS_ACTIVITY_DELETE_RECORD';
const CLIENTS_ACTIVITY_UPDATE_RECORD_ADD_MEETING_GUESTS = 'CLIENTS_ACTIVITY_UPDATE_RECORD_ADD_MEETING_GUESTS';
const CLIENTS_ACTIVITY_PAUSE_COMPONENT_UPDATE = 'CLIENTS_ACTIVITY_PAUSE_COMPONENT_UPDATE';
const CLIENTS_ACTIVITY_STORE_RESET = 'CLIENTS_ACTIVITY_STORE_RESET';
const TEAM_ACTIVITY_GET = 'TEAM_ACTIVITY_GET';
const TEAM_ACTIVITY = 'TEAM_ACTIVITY';
const TEAM_ACTIVITY_NOTES = 'TEAM_ACTIVITY_NOTES';
const TEAM_ACTIVITY_NOTES_UPDATE_RECORD = 'TEAM_ACTIVITY_NOTES_UPDATE_RECORD';
const TEAM_ACTIVITY_NOTES_DELETE_RECORD = 'TEAM_ACTIVITY_NOTES_DELETE_RECORD';
const TEAM_ACTIVITY_ADD_RECORD = 'TEAM_ACTIVITY_ADD_RECORD';

/* Actions */

export const setClientsActivity = (activity, append, newMonth) => ({'type': CLIENTS_ACTIVITY, 'activity': activity, 'append': append, 'newMonth': newMonth});
export const setClientsActivityFutureSessions = (activity, newMonths) => ({'type': CLIENTS_ACTIVITY_FUTURE_SESSIONS, 'activity': activity, 'newMonths': newMonths});
export const setClientsActivityProspectIdLoaded = (prospectId) => ({'type': CLIENTS_ACTIVITY_PROSPECT_ID_LOADED, 'prospectId': prospectId});
export const setClientsActivityAddRecord = (newRecord) => ({'type': CLIENTS_ACTIVITY_ADD_RECORD, 'newRecord': newRecord});
export const setClientsActivityUpdateRecord = (activityType, id, update) => ({'type': CLIENTS_ACTIVITY_UPDATE_RECORD, 'activityType': activityType, 'id': id, 'update': update});
export const setClientsActivityDeleteRecord = (activityType, id) => ({'type': CLIENTS_ACTIVITY_DELETE_RECORD, 'activityType': activityType, 'id': id});
export const setClientsActivityUpdateRecordAddMeetingGuest = (sessionId, newGuest) => ({'type': CLIENTS_ACTIVITY_UPDATE_RECORD_ADD_MEETING_GUESTS, 'sessionId': sessionId, 'newGuest': newGuest});
export const setClientsActivityGet = (status) => ({'type': CLIENTS_ACTIVITY_GET, 'status': status});
export const setClientsActivityFutureSessionsGet = (status) => ({'type': CLIENTS_ACTIVITY_FUTURE_SESSIONS_GET, 'status': status});
export const setClientsActivityPauseComponentUpdate = (status) => ({'type': CLIENTS_ACTIVITY_PAUSE_COMPONENT_UPDATE, 'status': status});
export const setClientsActivityStoreReset = () => ({'type': CLIENTS_ACTIVITY_STORE_RESET});
export const setTeamNotes = (notes) => ({'type': TEAM_ACTIVITY, 'notes': notes});
export const setTeamActivityNotes = (notes) => ({'type': TEAM_ACTIVITY_NOTES, 'notes': notes});
export const setTeamActivityNotesUpdateRecord = (activityType, id, update) => ({'type': TEAM_ACTIVITY_NOTES_UPDATE_RECORD, 'activityType': activityType, 'id': id, 'update': update});
export const setTeamActivityNotesDeleteRecord = (activityType, id) => ({'type': TEAM_ACTIVITY_NOTES_DELETE_RECORD, 'activityType': activityType, 'id': id});
export const setTeamNotesGet = (status) => ({'type': TEAM_ACTIVITY_GET, 'status': status});
export const setTeamActivityAddRecord = (newRecord, noteId) => ({'type': TEAM_ACTIVITY_ADD_RECORD, 'newRecord': newRecord, 'noteId': noteId});

/* Initial State */

const initialState = Map({
    clientActivity: List(),
    clientActivityMonths: List(),
    clientActivityGet: false,
    clientActivityFutureSessionsGet: false,
    clientActivityProspectIdLoaded: null,
    clientActivityPauseComponentUpdate: false,
    teamNotes: List(),
    comments: List(),
    teamNotesGet: false,
});

/* Reducer */

const clientsActivityReducer = (state=initialState, action) => {
    switch (action.type) {
        case CLIENTS_ACTIVITY_PROSPECT_ID_LOADED:
            return state.merge({"clientActivityProspectIdLoaded": action.prospectId});
        case CLIENTS_ACTIVITY:
            return state.merge({
                "clientActivity": action.append
                    ? fromJS([...state.get("clientActivity").toJS(), ...action.activity])
                    : fromJS(action.activity),
                "clientActivityMonths": fromJS([...state.get("clientActivityMonths").toJS(), action.newMonth])
            });
        case CLIENTS_ACTIVITY_FUTURE_SESSIONS:
            return state.merge({
                "clientActivity": fromJS(action.activity),
                "clientActivityMonths": fromJS(action.newMonths)
            });
        case CLIENTS_ACTIVITY_ADD_RECORD:
            return state.merge({
                "clientActivity": fromJS(state.get("clientActivity").push(fromJS(action.newRecord)))
            });
        case CLIENTS_ACTIVITY_UPDATE_RECORD:
            return state.merge({
                "clientActivity": state.get("clientActivity").size === 0
                    ? state.get("clientActivity")
                    : fromJS(state.get("clientActivity").map(a => {
                        if(a.get("activityType") === action.activityType && a.get("id") === action.id) {
                            return fromJS({...a.toJS(), ...action.update})
                        } else {
                            return fromJS(a);
                        }
                    }))
            });
        case CLIENTS_ACTIVITY_UPDATE_RECORD_ADD_MEETING_GUESTS:
            return state.merge({
                "clientActivity": state.get("clientActivity").size === 0
                    ? state.get("clientActivity")
                    : fromJS(state.get("clientActivity").map(a => {
                        if((a.get("activityType") === "upcomingConferences" || a.get("activityType") === "ongoingConferences" ||
                            a.get("activityType") === "pastConferences" || a.get("activityType") === "canceledConferences") && a.get("id") === action.sessionId) {
                            let newGuests = fromJS(a.get("guests").push(fromJS(action.newGuest)));
                            return fromJS({
                                ...a.toJS(),
                                "title": (a.get("title") === "Internal meeting" || a.get("title") === "") ? listGuestNames(newGuests.toJS(), false) : a.get("remoteUserName") + listGuestNames(newGuests.toJS(), true),
                                "guests": newGuests
                            })
                        } else {
                            return fromJS(a);
                        }
                    }))
            });
        case CLIENTS_ACTIVITY_DELETE_RECORD:
            return state.merge({
                "clientActivity": state.get("clientActivity").size === 0
                    ? state.get("clientActivity")
                    : fromJS(state.get("clientActivity").filter((a) => {
                        return !(a.get("activityType") === action.activityType && a.get("id") === action.id);
                    }))
            });
        case CLIENTS_ACTIVITY_GET:
            return state.merge({'clientActivityGet': action.status});
        case CLIENTS_ACTIVITY_FUTURE_SESSIONS_GET:
            return state.merge({'clientActivityFutureSessionsGet': action.status});
        case CLIENTS_ACTIVITY_PAUSE_COMPONENT_UPDATE:
            return state.merge({'clientActivityPauseComponentUpdate': action.status});
        case CLIENTS_ACTIVITY_STORE_RESET:
            return state.merge(initialState);
        case TEAM_ACTIVITY:
            return state.merge({'comments': fromJS([...state.get("comments").toJS(), ...action.notes])}); 
        case TEAM_ACTIVITY_GET:
            return state.merge({'teamNotesGet': action.status});
        case TEAM_ACTIVITY_ADD_RECORD:
            return state.merge({
                "comments": fromJS(state.get("comments").unshift(fromJS(action.newRecord)))
            });
        case TEAM_ACTIVITY_NOTES:
            return state.merge({'teamNotes': fromJS(action.notes)});
        case TEAM_ACTIVITY_NOTES_UPDATE_RECORD:
            return state.merge({
                "teamNotes": state.get("teamNotes").size === 0
                    ? state.get("teamNotes")
                    : fromJS(state.get("teamNotes").map(a => {
                        if(a.get("id") === action.id) {
                            return fromJS({...a.toJS(), ...action.update})
                        } else {
                            return fromJS(a);
                        }
                    }))
            });
        case TEAM_ACTIVITY_NOTES_DELETE_RECORD:
            return state.merge({
                "teamNotes": state.get("teamNotes").size === 0
                    ? state.get("teamNotes")
                    : fromJS(state.get("teamNotes").filter((a) => {
                        return !(a.get("id") === action.id);
                    }))
            });
        default:
            return state;
    }
};

export default clientsActivityReducer;
