import React from 'react';
import { Switch } from 'react-router-dom';

/* Containers */
import SecureRoute from '../../../routes/containers/routesecure';

/* Pages - Consumer */
import ConsumerMoney from '../../../deprecated/consumer/mymoney/page';
import ConsumerSettings from '../../../consumer/settings/page';
import ConsumerFeedback from '../../../consumer/feedback/page';
import ConsumerHelp from '../../../consumer/help/page';
import ConsumerVault from '../../../deprecated/consumer/vault/page';
import ConsumerOneDrive from '../../../deprecated/consumer/vaultdrive/page';
import ConsumerMyTeam from '../../../consumer/team/page';
import ConsumerBilling from '../../../deprecated/consumer2/billing/page';
import ConsumerSignUp from '../../../deprecated/consumer2/signup/page';
import ConsumerSettingsNav from "../../../deprecated/consumer2/settingsmobilenav/page";

export const consumerRoutes = [
        '/my-money/spending',
        '/my-money/snapshot',
        '/my-money/saving',
        '/settings/billing',
        '/vaults',
        '/vaults/drive',
        '/my-shops',
        '/feedback',
        '/support/faq',
        '/support/contact',
        '/settings/general',
        '/signup',
        '/settings/menu'
];

const RoutesTrustWillowConsumer = () => (
    <Switch>
        <SecureRoute exact path={'/my-money/spending'} component={ConsumerMoney} />
        <SecureRoute exact path={'/my-money/snapshot'} component={ConsumerMoney} />
        <SecureRoute exact path={'/my-money/saving'} component={ConsumerMoney} />
        <SecureRoute exact path={'/settings/billing'} component={ConsumerBilling} />
        <SecureRoute exact path={'/vaults'} component={ConsumerVault} />
        <SecureRoute exact path={'/vaults/drive'} component={ConsumerOneDrive} />
        <SecureRoute exact path={'/my-shops'} component={ConsumerMyTeam} />
        <SecureRoute exact path={'/feedback'} component={ConsumerFeedback} />
        <SecureRoute exact path={'/support/faq'} component={ConsumerHelp} />
        <SecureRoute exact path={'/support/contact'} component={ConsumerHelp} />
        <SecureRoute exact path={'/settings/general'} component={ConsumerSettings} />
        <SecureRoute exact path={'/signup'} component={ConsumerSignUp} />
        <SecureRoute exact path={'/settings/menu'} component={ConsumerSettingsNav} />
    </Switch>
);

export default RoutesTrustWillowConsumer;
