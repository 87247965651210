import { combineReducers } from 'redux';

/* Reducers */
// import sessionsReducer from './store/sessions';
import groupReducer from './store/group';
import expertProfileReducer from "./store/expertprofile";

const meetingsReducer = combineReducers({
    // sessions: sessionsReducer,
    expertProfile: expertProfileReducer,
    group: groupReducer
});

export default meetingsReducer;
