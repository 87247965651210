import React from "react";
import ReactQuill from "react-quill";
import {v4 as uuid4} from "uuid";
import {connect} from "react-redux";
import styled from "styled-components";

/* Components */
import {QuillSlim} from "../../common/components/richtexteditor";
import {colors} from "../../common/components/colors";
import {FileTextFilled} from "@ant-design/icons";
import {FontBody12, FontHeader16, FontTitle18} from "../../common/components/fonts";
import {Card, Icon, Body, Title, Action, Inline, ActionLink, BottomBorder5, Content} from '../components/contactactivity';
import {LogoLoading} from "../../common/components/loading";

export const QuillMedium = styled.div`
    background-color: ${colors.border30};
    margin-top: 20px;
    margin-bottom: 10px;
    border: 1px solid ${props => props.bodyBorderColor} !important;
    .ql-editor {
        margin: 10px;
        padding: 20px;
        font-family: MontserratRegular;
        font-size: 14px;
        line-height: 21px;
    }
    .ql-toolbar {
        border-top: 0px solid transparent !important;
        border-left: 0px solid transparent !important;
        border-right: 0px solid transparent !important;
        border-bottom: 1px solid ${colors.border100} !important;
    }
    .ql-container {
        margin-top: 10px;
        border: 0px solid transparent !important;
        min-height: 70px;
    }
`;

class NotesForCoach extends React.Component {
    state={isLoading: true, data: {}, quill: ''};

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.data !== this.props.data) {
            return this.init();
        }
    }

    init = async () => {
        await this.setState({
            "data": this.props.data,
            'quill' : <ReactQuill
                id={uuid4()}
                readOnly={true}
                theme={null}
                value={this.props.data}
                bounds={'.app'}
            />
        });
        return this.setState({"isLoading": false});
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <Card>
                    <Icon><FileTextFilled /></Icon>
                    <Body>
                        <div>
                            <Title><FontTitle18>{this.props.category}</FontTitle18></Title>
                        </div>
                        <Content>
                            <BottomBorder5>
                                <FontHeader16>{this.props.title}</FontHeader16>
                            </BottomBorder5>
                            <QuillSlim bodyBorderColor={colors.white}>{this.state.quill}</QuillSlim>
                        </Content>
                    </Body>
                </Card>
            )
        }
    }
}

const mapStateToProps = state => ({
    client: state.enterprise.clientsDetail.get("client"),
    userId: state.common.user.get("userId"),
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesForCoach);

