import React, {useEffect, useState} from 'react';

/* Container */
import Contact from "./containers/contact";

export default function IFrameAdvisorRequest() {
    const [utm, setUtm] = useState({
        "utm_source": null,
        "utm_medium": null,
        "utm_campaign": null,
        "utm_content": null,
        "utm_term": null,
        "isLoading": true
    });

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        const searchParams = await new URLSearchParams(window.location.search);
        const utm_source = searchParams.get("utm_source");
        const utm_medium = searchParams.get("utm_medium");
        const utm_campaign = searchParams.get("utm_campaign");
        const utm_content = searchParams.get("utm_content");
        const utm_term = searchParams.get("utm_term");
        setUtm({
            "utm_source": utm_source === undefined ? null : utm_source,
            "utm_medium": utm_medium === undefined ? null : utm_medium,
            "utm_campaign": utm_campaign === undefined ? null : utm_campaign,
            "utm_content": utm_content === undefined ? null : utm_content,
            "utm_term": utm_term === undefined ? null : utm_term,
            "isLoading": false
        })
    }

    if(utm.isLoading) {
        return null
    }

    return (
        <Contact
            advisorId={900}
            firstName={""}
            utm={utm}
        />
    )
}