import React from 'react';
import { Switch } from 'react-router-dom';

/* Containers */
import SecureRoute from '../../../routes/containers/routesecure';

/* Pages - Consumer */
import ConsumerMoney from '../../../consumer/financialprofile/page';
import ConsumerSettings from '../../../consumer/settings/page';
import ConsumerFeedback from '../../../consumer/feedback/page';
import ConsumerHelp from '../../../consumer/help/page';
// import ConsumerVault from '../../../deprecated/consumer/vault/page';
// import ConsumerOneDrive from '../../../deprecated/consumer/vaultdrive/page';
// import ConsumerMyTeam from '../../../consumer/team/page';
// import ConsumerBilling from '../../../consumer/billing/page';
// import ConsumerSignUp from '../../../consumer/signup/page';
import ConsumerNavPage from "../../../consumer/navbars/page";
import ConsumerProgress from "../../../consumer/progress/page";
// import ConsumerGetCare from '../../../deprecated/consumer2/getcare/page';
import ConsumerLearn from '../../../consumer/learn/page';
import ConsumerPrograms from '../../../consumer/programs/page';
import ConsumerMyTeam2 from '../../../consumer/team/page'
// import MyAppointments from '../../../consumer/appointments/page';
import Home from '../../../consumer/home/page';
import Messages from '../../../consumer/sms/page';
import Forum from '../../../consumer/forum/page';

export const consumerRoutes = [
        '/my-financial-profile/spending',
        '/my-financial-profile/snapshot',
        '/my-financial-profile/saving',
        '/my-financial-profile',
        '/settings/billing',
        // '/vaults',
        // '/vaults/drive',
        '/my-team',
        '/my-team/:id',
        '/my-team/:id/documents',
        '/my-team/:id/profile',
        '/my-team/:id/messages',
        '/my-team/:id/appointment',
        '/my-team/:id/appointment/book',
        '/my-team/:id/add-appointment',
        '/my-team/:id/add-appointment/checkout',
        '/my-team/add-appointments',
        '/my-team/add-appointments/checkout',
        '/my-team/appointment',
        '/my-team/find',
        '/my-team/find/:subCategoryId',
        '/feedback',
        '/support',
        '/support/feedback',
        '/support/contact',
        '/support/contact/message-sent',
        '/support/feedback/message-sent',
        // '/settings/general',
        '/settings',
        '/settings/security',
        // '/signup',
        '/menu',
        '/progress',
        '/progress/completed',
        '/progress/appointments',
        '/progress/bookmarks',
        '/progress/goals',
        '/progress/goals/create',
        '/progress/goals/create/page/:page',
        '/progress/finances',
        '/progress/finances/spending',
        '/progress/finances/snapshot',
        '/progress/finances/saving',
        // '/progress/course',
        // '/progress/course/module',
        // '/progress/course/module/:page',
        // '/progress/course/complete',
        // '/progress/article',
        // '/progress/video',
        // '/get-care',
        '/learn',
        '/learn/search',
        '/learn/bookmarks',
        '/learn/content',
        '/learn/content/search',
        '/learn/course/:id',
        '/learn/course/:id/module/:id',
        '/learn/course/:id/module/:id/page/:id',
        '/learn/course/:id/complete',
        '/learn/article/:id',
        '/learn/video/:id',
        '/learn/insights/details',
        '/learn/request',
        '/my-programs',
        '/my-programs/upcoming',
        '/my-programs/past',
        '/programs',
        '/programs/details',
        '/programs/checkout',
        '/programs/registration-completed',
        '/programs/request',
        // '/get-care',
        // '/get-care/journey',
        // '/get-care/journey/providers',
        // '/get-care/request',
        // '/appointments',
        // '/appointments/upcoming',
        // '/appointments/past',
        // '/appointments/missed',
        '/home',
        '/messages',
        '/community',
        '/community/posts/create',
        '/community/posts/edit',
        '/community/posts/delete',
        // '/community/posts/:id',
        '/community/posts/post',
        // '/community/:category',
        '/community/category',
        // '/community/:category/:subcategory',
        '/community/category/subcategory',
];

const RoutesTrustWillowConsumer = () => (
    <Switch>
        <SecureRoute exact path={'/my-financial-profile/spending'} component={ConsumerMoney} />
        <SecureRoute exact path={'/my-financial-profile/snapshot'} component={ConsumerMoney} />
        <SecureRoute exact path={'/my-financial-profile/saving'} component={ConsumerMoney} />
        <SecureRoute exact path={'/my-financial-profile'} component={ConsumerMoney} />
        {/*<SecureRoute exact path={'/vaults'} component={ConsumerVault} />*/}
        {/*<SecureRoute exact path={'/vaults/drive'} component={ConsumerOneDrive} />*/}
        <SecureRoute exact path={'/my-team'} component={ConsumerMyTeam2} />
        <SecureRoute exact path={'/my-team/:id'} component={ConsumerMyTeam2} />
        <SecureRoute exact path={'/my-team/:id/documents'} component={ConsumerMyTeam2} />
        <SecureRoute exact path={'/my-team/:id/profile'} component={ConsumerMyTeam2} />
        <SecureRoute exact path={'/my-team/:id/messages'} component={ConsumerMyTeam2} />
        <SecureRoute exact path={'/my-team/:id/appointment'} component={ConsumerMyTeam2} />
        <SecureRoute exact path={'/my-team/:id/appointment/book'} component={ConsumerMyTeam2} />
        <SecureRoute exact path={'/my-team/:id/add-appointments'} component={ConsumerMyTeam2} />
        <SecureRoute exact path={'/my-team/:id/add-appointments/checkout'} component={ConsumerMyTeam2} />
        <SecureRoute exact path={'/my-team/add-appointments'} component={ConsumerMyTeam2} />
        <SecureRoute exact path={'/my-team/add-appointments/checkout'} component={ConsumerMyTeam2} />
        <SecureRoute exact path={'/my-team/appointment'} component={ConsumerMyTeam2} />
        <SecureRoute exact path={'/my-team/find'} component={ConsumerMyTeam2} />
        <SecureRoute exact path={'/my-team/find/:subCategoryId'} component={ConsumerMyTeam2} />
        <SecureRoute exact path={'/feedback'} component={ConsumerFeedback} />
        <SecureRoute exact path={'/support'} component={ConsumerHelp} />
        <SecureRoute exact path={'/support/feedback'} component={ConsumerHelp} />
        <SecureRoute exact path={'/support/feedback/message-sent'} component={ConsumerHelp} />
        <SecureRoute exact path={'/support/contact'} component={ConsumerHelp} />
        <SecureRoute exact path={'/support/contact/message-sent'} component={ConsumerHelp} />
        {/*<SecureRoute exact path={'/settings/general'} component={ConsumerSettings} />*/}
        <SecureRoute exact path={'/settings'} component={ConsumerSettings} />
        <SecureRoute exact path={'/settings/security'} component={ConsumerSettings} />
        <SecureRoute exact path={'/settings/billing'} component={ConsumerSettings} />
        {/*<SecureRoute exact path={'/signup'} component={ConsumerSignUp} />*/}
        <SecureRoute exact path={'/menu'} component={ConsumerNavPage} />
        <SecureRoute exact path={'/progress'} component={ConsumerProgress} />
        <SecureRoute exact path={'/progress/completed'} component={ConsumerProgress} />
        <SecureRoute exact path={'/progress/appointments'} component={ConsumerProgress} />
        <SecureRoute exact path={'/progress/bookmarks'} component={ConsumerProgress} />
        <SecureRoute exact path={'/progress/goals'} component={ConsumerProgress} />
        <SecureRoute exact path={'/progress/goals/create'} component={ConsumerProgress} />
        <SecureRoute exact path={'/progress/goals/create/page/:page'} component={ConsumerProgress} />
        <SecureRoute exact path={'/progress/finances'} component={ConsumerProgress} />
        <SecureRoute exact path={'/progress/finances/spending'} component={ConsumerProgress} />
        <SecureRoute exact path={'/progress/finances/snapshot'} component={ConsumerProgress} />
        <SecureRoute exact path={'/progress/finances/saving'} component={ConsumerProgress} />
        <SecureRoute exact path={'/progress/finances'} component={ConsumerProgress} />
        <SecureRoute exact path={'/progress/finances'} component={ConsumerProgress} />
        {/*<SecureRoute exact path={'/progress/course'} component={ConsumerProgress} />*/}
        {/*<SecureRoute exact path={'/progress/course/module'} component={ConsumerProgress} />*/}
        {/*<SecureRoute exact path={'/progress/course/module/:page'} component={ConsumerProgress} />*/}
        {/*<SecureRoute exact path={'/progress/course/complete'} component={ConsumerProgress} />*/}
        {/*<SecureRoute exact path={'/progress/article'} component={ConsumerProgress} />*/}
        {/*<SecureRoute exact path={'/progress/video'} component={ConsumerProgress} />*/}
        {/*<SecureRoute exact path={'/get-care'} component={ConsumerCare} />*/}
        <SecureRoute exact path={'/learn'} component={ConsumerLearn} />
        <SecureRoute exact path={'/learn/search'} component={ConsumerLearn} />
        <SecureRoute exact path={'/learn/bookmarks'} component={ConsumerLearn} />
        <SecureRoute exact path={'/learn/content'} component={ConsumerLearn} />
        <SecureRoute exact path={'/learn/content/search'} component={ConsumerLearn} />
        <SecureRoute exact path={'/learn/course/:id'} component={ConsumerLearn} />
        <SecureRoute exact path={'/learn/course/:id/module/:id'} component={ConsumerLearn} />
        <SecureRoute exact path={'/learn/course/:id/module/:id/page/:id'} component={ConsumerLearn} />
        <SecureRoute exact path={'/learn/course/:id/complete'} component={ConsumerLearn} />
        <SecureRoute exact path={'/learn/article/:id'} component={ConsumerLearn} />
        <SecureRoute exact path={'/learn/video/:id'} component={ConsumerLearn} />
        <SecureRoute exact path={'/learn/insights/details'} component={ConsumerLearn} />
        <SecureRoute exact path={'/learn/request'} component={ConsumerLearn} />
        <SecureRoute exact path={'/my-programs'} component={ConsumerPrograms} />
        <SecureRoute exact path={'/my-programs/upcoming'} component={ConsumerPrograms} />
        <SecureRoute exact path={'/my-programs/past'} component={ConsumerPrograms} />
        <SecureRoute exact path={'/programs'} component={ConsumerPrograms} />
        <SecureRoute exact path={'/programs/details'} component={ConsumerPrograms} />
        <SecureRoute exact path={'/programs/checkout'} component={ConsumerPrograms} />
        <SecureRoute exact path={'/programs/registration-completed'} component={ConsumerPrograms} />
        <SecureRoute exact path={'/programs/request'} component={ConsumerPrograms} />
        {/*<SecureRoute exact path={'/get-care'} component={ConsumerGetCare} />*/}
        {/*<SecureRoute exact path={'/get-care/journey'} component={ConsumerGetCare} />*/}
        {/*<SecureRoute exact path={'/get-care/journey/providers'} component={ConsumerGetCare} />*/}
        {/*<SecureRoute exact path={'/get-care/request'} component={ConsumerGetCare} />*/}
        {/*<SecureRoute exact path={'/appointments'} component={MyAppointments} />*/}
        {/*<SecureRoute exact path={'/appointments/upcoming'} component={MyAppointments} />*/}
        {/*<SecureRoute exact path={'/appointments/past'} component={MyAppointments} />*/}
        {/*<SecureRoute exact path={'/appointments/missed'} component={MyAppointments} />*/}
        <SecureRoute exact path={'/home'} component={Home} />
        <SecureRoute exact path={'/messages'} component={Messages} />
        <SecureRoute exact path={'/community'} component={Forum}/>
        <SecureRoute exact path={'/community/category'} component={Forum}/>
        <SecureRoute exact path={'/community/category/subcategory'} component={Forum}/>
        <SecureRoute exact path={'/community/posts/post'} component={Forum}/>
        <SecureRoute exact path={'/community/posts/create'} component={Forum}/>
        {/* <SecureRoute exact path={'/community/posts/edit'} component={Forum}/> */}
    </Switch>
);

export default RoutesTrustWillowConsumer;
