import React from 'react';
import { withRouter } from 'react-router-dom';

class RedirectWithoutLogin extends React.Component {
    componentDidMount() {
        (() => {
            return (async () => {
                let path = window.location.pathname;
                let url = this.props.data.redirect;
                if(this.props.data.token) {
                    let pathAdj = path.charAt(path.length-1) === "/" ? path.slice(0, -1) : path;
                    let pathSplit = pathAdj.split("/");
                    let token = pathSplit[pathSplit.length - 1];
                    url = this.props.data.redirect + (this.props.data.token ? token : "");
                }
                window.open(url,"_self");
            })()
        })()
    }
    render() {return null}
}

export default withRouter(RedirectWithoutLogin);