import {v4 as uuid4} from "uuid";
import colors from "../styles/colors"

const consumerSignUpForm = [
    {
        "pageNum": 0,
        "header": {
            "title": "What's your goal?",
        },
        "actions": {
            "backShowOnTop": true,
            "pageNumVisible": false,
            "backLabel": "",
            "nextLabel": "Next",
            "canContinueAutomatically": false,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "spacer",
            },
            {
                "id": uuid4(),
                "type": "textArea",
                "subtitle": "What's your goal?",
                "placeholder": "Add a title of your goal here.",
                "isRequired": true,
                "isValid": false,
                "text": "",
                "minLength": 0,
                "minRows": 1,
                "style": {
                    "subtitle": {
                        "textAlign": "left",
                        "color": colors.secondary100
                    },
                    "textArea": {
                        "borderRadius": "20px",
                        "border": "none",
                        "backgroundColor": "white",
                        "outline": "none",
                        "WebkitBoxShadow": "0px 3px 10px 0px rgba(0, 0, 0, 0.25)",
                        "boxShadow": "0px 3px 10px 0px rgba(0, 0, 0, 0.25)"
                    },
                    "warning": {},
                }
            }
        ]
    },
    {
        "pageNum": 1,
        "header": {
            "title": "How far along are you already with this goal?",
        },
        "actions": {
            "backShowOnTop": true,
            "pageNumVisible": false,
            "backLabel": "",
            "nextLabel": "Next",
            "canContinueAutomatically": false,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "spacer",
            },
            {
                "id": uuid4(),
                "type": "title",
                "text": "How far along are you already with this goal?",
                "style": {
                    "textAlign": "left",
                    "color": colors.secondary100
                }
            },
            {
                "id": uuid4(),
                "type": "slider",
                "isValid": false,
                "title": "",
                "isRequired": true,
                "value": 0,
                "style": {
                    "containerStyle": {
                        "paddingLeft": 20,
                        "paddingRight": 20,
                        "marginTop": 40
                    },
                    "handleStyle": {
                        "height": 28,
                        "width": 28,
                        "marginTop": -11,
                        "backgroundColor": colors.primary100,
                        "borderColor": colors.primary100
                    },
                    "trackStyle": {
                        "backgroundColor": colors.primary70
                    },
                    "railStyle": {}
                }
            }
        ]
    },
    {
        "pageNum": 2,
        "header": {
            "title": "When would you like to achieve this goal by?",
        },
        "actions": {
            "backShowOnTop": true,
            "pageNumVisible": false,
            "backLabel": "",
            "nextLabel": "Next",
            "canContinueAutomatically": false,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "spacer",
            },
            {
                "id": uuid4(),
                "type": "title",
                "text": "When would you like to achieve this goal by?",
                "style": {
                    "textAlign": "left",
                    "color": colors.secondary100
                }
            },
            {
                "id": uuid4(),
                "type": "calendar",
                "isValid": false,
                "isRequired": true,
                "value": new Date(),
                "subtitle": "When would you like to achieve this goal by?",
                "style": {
                    "subtitle": {},
                    "textArea": {},
                    "warning": {},
                }
            }
        ]
    },
    {
        "pageNum": 3,
        "header": {
            "title": "How are you feeling about this goal?",
        },
        "actions": {
            "backShowOnTop": true,
            "pageNumVisible": false,
            "backLabel": "",
            "nextLabel": "Next",
            "canContinueAutomatically": false,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "spacer",
            },
            {
                "id": uuid4(),
                "type": "title",
                "text": "How are you feeling about this goal?",
                "style": {
                    "textAlign": "left",
                    "color": colors.secondary100
                }
            },
            {
                "id": uuid4(),
                "type": "multiSelect",
                "title": "",
                "isRequired": true,
                "isValid": false,
                "maxSelectable": 4,
                "options": [
                    {
                        "id": uuid4(),
                        "active": false,
                        "label": "Apprehensive",
                    },
                    {
                        "id": uuid4(),
                        "active": false,
                        "label": "Excited",
                    },
                    {
                        "id": uuid4(),
                        "active": false,
                        "label": "Overwhelmed",
                    },
                    {
                        "id": uuid4(),
                        "active": false,
                        "label": "Etc.",
                    }
                ],
                "style": {
                    "subtitle": {},
                    "textArea": {},
                    "warning": {},
                }
            }
        ]
    },
    {
        "pageNum": 4,
        "header": {
            "title": "Would the support of a coach help you achieve your goal?",
        },
        "actions": {
            "backShowOnTop": true,
            "pageNumVisible": false,
            "backLabel": "",
            "nextLabel": "Done!",
            "canContinueAutomatically": false,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "spacer",
            },
            {
                "id": uuid4(),
                "type": "title",
                "text": "Would the support of a coach help you achieve your goal?",
                "style": {
                    "textAlign": "left",
                    "color": colors.secondary100
                }
            },
            {
                "id": uuid4(),
                "type": "multiSelect",
                "isValid": false,
                "isRequired": true,
                "maxSelectable": 1,
                "options": [
                    {
                        "id": uuid4(),
                        "active": false,
                        "label": "Yes"
                    },
                    {
                        "id": uuid4(),
                        "active": false,
                        "label": "No"
                    }
                ],
                "style": {
                    "subtitle": {},
                    "disclosure": {},
                    "warning": {},
                    "options": {},
                    "optionLabel": {},
                    "optionIcon": {}
                }
            }
        ]
    },
    // {
    //     "pageNum": 5,
    //     "header": {
    //         "title": "Select all that apply to your ongoing personal growth.",
    //     },
    //     "actions": {
    //         "backShowOnTop": true,
    //         "pageNumVisible": false,
    //         "backLabel": "",
    //         "nextLabel": "Next",
    //         "canContinueAutomatically": false,
    //     },
    //     "elements": [
    //         {
    //             "id": uuid4(),
    //             "type": "title",
    //             "text": "Select all that apply to your ongoing personal growth.",
    //             "style": {}
    //         },
    //         {
    //             "id": uuid4(),
    //             "type": "multiSelect",
    //             "title": "Select all that apply to your ongoing personal growth.",
    //             "isRequired": true,
    //             "isValid": false,
    //             "options": [
    //                 {
    //                     "id": 1,
    //                     "active": false,
    //                     "label": "I've taken steps to protect my mental health",
    //                 },
    //                 {
    //                     "id": 2,
    //                     "active": false,
    //                     "label": "My finances are impacting my mental health less",
    //                 },
    //                 {
    //                     "id": 3,
    //                     "active": false,
    //                     "label": "I'm working adequately towards my goals",
    //                 }
    //             ],
    //             "style": {
    //                 "subtitle": {},
    //                 "textArea": {},
    //                 "warning": {},
    //             }
    //         }
    //     ]
    // },
    // {
    //     "pageNum": 6,
    //     "header": {
    //         "title": "Add any notes for yourself. What do you think you need to do?",
    //     },
    //     "actions": {
    //         "pageNumVisible": true,
    //         "backLabel": "Back",
    //         "nextLabel": "Done!",
    //         "canContinueAutomatically": false,
    //     },
    //     "elements": [
    //         {
    //             "id": uuid4(),
    //             "type": "title",
    //             "text": "Add any notes for yourself. What do you think you need to do?",
    //             "style": {}
    //         },
    //         {
    //             "id": uuid4(),
    //             "type": "textArea",
    //             "isValid": false,
    //             "isRequired": true,
    //             "title": "Add any notes for yourself. What do you think you need to do?",
    //             "subtitle": "Share your questions",
    //             "placeholder": "Add a title of your goal here.",
    //             "text": "",
    //             "minLength": 0,
    //             "minRows": 3,
    //             "style": {
    //                 "textArea": {
    //                     "borderRadius": "20px",
    //                     "border": "none",
    //                     "backgroundColor": "white",
    //                     "outline": "none",
    //                     "WebkitBoxShadow": "0px 3px 10px 0px rgba(0, 0, 0, 0.25)",
    //                     "boxShadow": "0px 3px 10px 0px rgba(0, 0, 0, 0.25)"
    //                 }
    //             }
    //         }
    //     ]
    // }
];

export default consumerSignUpForm;