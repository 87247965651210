import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

/* Components */
import {LogoLoading} from "../../../../common/components/loading";
import CreateFirstEvent from '../components/createfirstevent';
import ShowAllEvents from '../components/showallevents';

/* Containers */
import EventsOne from './eventsone';

/* Middleware */
import {tryGetListingsAll} from '../middleware/listings';

class EventsList extends React.Component {
    state={"isLoading": true};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.props.tryGetListingsAll();
        this.setState({"isLoading": false})
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            if(this.props.listings.filter(l => {return((l.get("serviceType") === "event" && l.get("status").toLowerCase() !== "deleted"))}).size === 0) {
                return (<CreateFirstEvent />)
            } else {
                return (
                    <ShowAllEvents
                        events={this.props.listings.map(l => {
                            if(l.get("serviceType") === "event" && l.get("status").toLowerCase() !== "deleted") {
                                return(<EventsOne key={l.get("id")} l={l} setLinkEmbedId={this.props.setLinkEmbedId} />)
                            } else {
                                return null
                            }
                        })}
                    />
                )
            }
        }
    }
}

const mapStateToProps = state => ({
    listings: state.enterprise.scheduler.listings.get("listingsAll")
});

const mapDispatchToProps = dispatch => ({
    tryGetListingsAll: () => dispatch(tryGetListingsAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EventsList));