import React from "react";
import styled from "styled-components";

/* Components */
import {FontSubtitle28} from "../../../common/components/fonts";
import {ButtonPrimary, ButtonTertiary} from "../../../common/components/buttons";
import {LogoLoading} from "../../../common/components/loading";
import {images} from "../../../common/components/images";
import {colors} from "../../../common/components/colors";
import {LoadingCenter} from "../components/courses";

const Center = styled.div`
    margin-top: 60px;
    text-align: center;
    color: ${colors.primary100};
`;

const SummaryIcon = styled.img`
    width: auto;
    margin: 60px auto 120px auto;
    height: 260px;
`;

const FitContent = styled.div`
    width: fit-content;
    cursor: pointer;
    margin: 0 auto 25px auto;
`;

class CourseComplete extends React.Component {
    static defaultProps = {
        back: () => {},
        course: {},
        openNextTask: () => {}
    }

    state={
        "isLoading": true,
        "completed": "course"
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.course !== this.props.course) {
            return this.init()
        }
    }

    init = async () => {
        const tasksLeft = this.props.course.tasks.filter(t => {return(!t.isCompleted)})
        this.setState({
            "completed": tasksLeft.length === 0 ? "course" : 'task',
            "isLoading": false
        })
    };

    render() {
        if (this.state.isLoading) {
            return (<LoadingCenter><LogoLoading/></LoadingCenter>)
        } else {
            return(
                <Center>
                    <FontSubtitle28>{this.state.completed === "course" ? "COURSE COMPLETED!" : "TASK COMPLETED"}</FontSubtitle28>
                    <SummaryIcon src={images.completeIcon}/>
                    {this.state.completed === "task" &&
                        <FitContent onClick={this.props.openNextTask}>
                            <ButtonTertiary submit={false} label={"Next Task"}/>
                        </FitContent>
                    }
                    <FitContent onClick={this.props.back}>
                        <ButtonPrimary submit={false} label={"Back to Course"} />
                    </FitContent>
                </Center>
            )
        }
    }
}

export default CourseComplete;