import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setIframeAuthTestUserExists, setIframeAuthTestUserExistsError, setIframeAuthLogin, setIframeAuthLoginError,} from "../store/auth";
import {setLoggedIn} from "../../../routes/store/user";

/* Static */
import {devIframeAuthData, devIframeLogin} from "../static/devdata";

export const trySignUpTestExists = (email, phone) => {
    return async dispatch => {
        dispatch(setIframeAuthTestUserExists(true));
        const signUp = await axiosReq('apiv1/consumer/exists', "POST", {"email": email, "phone": phone}, {});
        if(signUp.alreadyRegistered) {
            dispatch(setIframeAuthTestUserExistsError("User already exists."));
        } else {
            dispatch(setIframeAuthTestUserExistsError(null));
        }
        return dispatch(setIframeAuthTestUserExists(false));
    }
};

export const tryCheckoutLogin = (email, password) => {
    return async dispatch => {
        dispatch(setIframeAuthLogin(true));
        const login = await axiosReq('api-login', "POST", {"email": email, "password": password}, devIframeLogin(false));
        if(login.hasOwnProperty("error")) {
            await dispatch(setIframeAuthLoginError(login.error));
        } else {
            await dispatch(setIframeAuthLoginError(null));
        }
        dispatch(setIframeAuthLogin(false));
    }
};
