import React from 'react';
import {connect} from 'react-redux';
import styled from "styled-components";
import {Link} from "react-router-dom";

/* Middleware */
import {tryGetCoachesAssignedToWMC} from "../middleware/coaching";

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {PageActions, PageContent, PageTabs} from "../components/teamone";
import {Message} from "../../../common/components/messages";
import {ButtonPrimary} from "../../../common/components/buttons";
import {PlusOutlined} from "@ant-design/icons";

/* Containers */
import TeamList from "./teamlist";
import TeamSupport from "./teamsupport";
// import TeamHowItWorks from "./containers/teamhowitworks";
import TeamCategories from './teamcategories';
import SupportMembers from "./supportmembers";
import Announcements from './announcements';

const InlineBtn = styled.div`
    width: fit-content;
    margin: -25px auto 0px auto;
`;

class Team extends React.Component {
    state={"isLoading": true, "allCoaches": []};

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.allCoaches !== this.props.allCoaches) {
            return this.update();
        }
    }

    init = async () => {
        if(!this.props.allCoachesFetched) {
            await this.props.tryGetCoachesAssignedToWMC()
        }
        this.update();
    };

    update = async () => {
        this.setState({"allCoaches": this.props.allCoaches, "isLoading": false});
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            // if(this.state.allCoaches.size > 0) {
                return(
                    <PageTabs>
                        <PageActions>
                            {/* <TeamHowItWorks /> */}
                            {/* <TeamSupport /> */}
                            {/* <SupportMembers /> */}
                        </PageActions>
                        <PageContent>
                            <TeamCategories />
                            {this.state.allCoaches.size === 0
                                ? <>
                                    <Message text={"Add Your First Team Member!"}/>
                                    <InlineBtn><Link to={"/experts"}><ButtonPrimary canSubmit={false} label={<><PlusOutlined /> Build My Team</>} /></Link></InlineBtn>
                                </>
                                : <TeamList />
                            }
                        </PageContent>
                    </PageTabs>
                )
            // } else {
            //     return(<MWCIntroduction />)
            // }
        }
    }

}

const mapStateToProps = state => ({
    allCoaches: state.enterprise.team.coaching.get("coachesAssignedToWMC"),
    allCoachesFetched: state.enterprise.team.coaching.get("coachesAssignedToWMCFetched"),
});

const mapDispatchToProps = dispatch => ({
    tryGetCoachesAssignedToWMC: () => dispatch(tryGetCoachesAssignedToWMC()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Team);
