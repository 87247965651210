import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import styled from "styled-components";

/* Components */
import {PhoneInput} from "../../../common/components/inputs";
import {SavedSettings} from '../../../common/components/cards';
import {Notice} from "../../../common/components/messages";
import {colors} from "../../../common/components/colors";
import {ButtonPrimary, ButtonInactive} from "../../../common/components/buttons";

/* Middleware */
import {tryUpdateSettingsPersonalInfo} from '../middleware/messages';

const SettingsCard = styled.div`
    background-color: ${colors.backgroundColor1};
    border-radius: 20px;
    padding: 0px 30px 30px 30px;
    max-width: 450px;
    width: calc(100% - 60px);
    margin: 0px auto 0px auto;
    & input {
        border-radius: 20px;
        background-color: ${colors.white};
        border: none;
        line-height: 25px;
    }
    & .Dropdown-control {
        border-radius: 20px;
        background-color: ${colors.white};
        border: none;
    }
    & .Dropdown-menu {
        background-color: ${colors.white};
        border: 1px solid ${colors.backgroundColor3};
        border-radius: 6px;
    }
    & form > div > div {
        color: ${colors.primary100};
    }
    & Button {
        margin: 20px 0px 0px auto;
    }
`;

const ButtonSection = styled.div`
    width: 100%;
    text-align: right;
`;

class PhonePrompt extends React.Component {
    static defaultProps = {
        "handleUpdatePhone": () => {},
        "personalInfo": {
            "first": '',
            "last": '',
            "phone": '',
            "email": '',
            "companyName": '',
            "timeZone": ''
        }
    }

    state = {
        "first": '', "firstValid": true,
        "last": '', "lastValid": true,
        "phone": '', "phoneValid": true,
        "email": '', "emailValid": true,
        "companyName": '', "companyNameValid": true,
        "timeZone": '',
        "backup": {
            "first": '',
            "last": '',
            "phone": '',
            "email": '',
            "companyName": '',
            "timeZone": ''
        },
        "isSaving": false,
        "isSaved": false,
        "error": '',
        "isChanged": false,
        "isValid": true
    };

    componentDidMount() {
        this.setState({...this.props.personalInfo, "backup": this.props.personalInfo})
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    
    handleChangePhone = async e => {
        this.setState({
            'phone': e.target.value,
            'phoneValid': await validator.isMobilePhone(e.target.value, 'en-US'),
            'isChanged': true,
        });
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.phoneValid) {
            this.setState({'isSaving': true, 'error': ''});
            const updateStatus = await this.props.tryUpdateSettingsPersonalInfo(this.state);
            if(updateStatus.error !== '') {
                this.setState({...this.state.backup, "error": updateStatus.error})
                this.timeout = setTimeout(() => {
                    this.setState({'isSaved': false})
                }, 3000);
            } else {
                this.timeout = setTimeout(() => {
                    this.setState({'isSaved': false})
                    this.props.handleUpdatePhone();
                }, 3000);
            }
            this.setState({'isSaving': false, 'isSaved': true, 'isChanged': false})
        } else {
            this.setState({"error": "Enter a valid mobile phone number", "isSaved": true})
            this.timeout = setTimeout(() => {this.setState({"isSaved": false, 'error': ""})}, 3000);
        }
    };

    render() {
        return(
            <SettingsCard maxWidth={"460px"}>
                {this.state.isSaved
                    ?
                        <>
                            {this.state.error === '' &&
                                <SavedSettings height={"273px"} error={false} message={"Saved"}/>
                            }
                            {this.state.error !== '' &&
                                <SavedSettings height={"273px"} error={true} message={this.state.error}/>
                            }
                        </>
                    :
                    <>
                        <form method={"post"} onSubmit={this.handleSubmit}>
                            <Notice text={"Enter Your Phone Number"} subtitle={"You need to add a phone number to your account before you can send messages. This number will only be used for messaging with coaches and appointment reminders."}/>
                            <PhoneInput
                                title={""}
                                valid={this.state.phoneValid}
                                warning={"Enter a valid mobile phone"}
                                id={"phone"}
                                onChange={this.handleChangePhone}
                                placeholder={'(###) ###-####'}
                                value={this.state.phone}
                                options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}}
                            />
                            
                            <ButtonSection>
                                {(this.state.isSaving || !this.state.isChanged || (this.state.isChanged && !this.state.isValid))
                                    ? <ButtonInactive canSubmit={true} label={"Save"}/>
                                    : <ButtonPrimary canSubmit={true} label={"Save"}/>
                                }
                            </ButtonSection>
                        </form>
                    </>
                }
            </SettingsCard>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    tryUpdateSettingsPersonalInfo: (data) => dispatch(tryUpdateSettingsPersonalInfo(data)),
});

export default connect(null, mapDispatchToProps)(PhonePrompt);
