import { Map } from 'immutable';

/* Immutable */

const HELP_CONTACT_SEND_PENDING = 'HELP_CONTACT_SEND_PENDING';
const HELP_CONTACT_SEND_COMPLETE = 'HELP_CONTACT_SEND_COMPLETE';
const HELP_STORE_RESET = 'HELP_STORE_RESET';

/* Actions */

export const setHelpContactSendPending = () => ({'type': HELP_CONTACT_SEND_PENDING});
export const setHelpContactSendComplete = () => ({'type': HELP_CONTACT_SEND_COMPLETE});
export const setSendStoreReset = () => ({'type': HELP_STORE_RESET});

/* Initial State */

const initialState = Map({
    helpContactSending: false
});

/* Reducer */

const helpReducer = (state=initialState, action) => {
    switch (action.type) {
        case HELP_CONTACT_SEND_PENDING:
            return state.merge({'helpContactSending': true});
        case HELP_CONTACT_SEND_COMPLETE:
            return state.merge({'helpContactSending': false});
        case HELP_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default helpReducer;
