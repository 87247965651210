import React from "react";
import {connect} from 'react-redux';
import styled from 'styled-components';

/* Components */
import {LogoLoading} from "../../../../common/components/loading";
import { colors } from "../../../../common/components/colors";

/* Middleware */
import {tryGetSharedDriveList, tryCreateNewSharedDrive} from '../../../../common/containers/vault/middleware/filevault';

/* Store */
import {setSharedDrive} from '../../../../common/containers/vault/store/filevault';

/* Containers */
import AllSharedVaultList from "../../../../common/containers/vault/containers/allsharedvaultlist";

const randomNumber = () => {return Math.floor(Math.random() * 100)+1};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    color: ${colors.primary100};
    @media screen and (max-width: 748px) {
        margin-top: 10px;
    }
    height: 100%;
`;

class FirmCoachRecordSharedDocuments extends React.Component {
    static defaultProps = {
        "profile": null
    };

    state = {
        "drive": null,
        "isLoading": true,
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.props.tryGetSharedDriveList();
        if(!this.props.drive.toJS().driveId) {
            await this.createDrive();
            await this.props.tryGetSharedDriveList();
        }
        await Promise.all(this.props.driveList.toJS().map(async drive => {
            if (drive.driveType === 'shared_drive' && drive.users) {
                for (const user of drive.users) {
                    if (this.props.profile.id === user.userId) {
                        await this.props.setSharedDrive({
                            "driveId": drive.driveId,
                            "driveType": drive.driveType,
                            "files": drive.files,
                            "folders": drive.folders,
                            "name": drive.name,
                            "parentFolderId": drive.parentFolderId
                        });
                    }
                }
            }
        }))
        this.setState({"isLoading": false});
    }

    createDrive = async () => {
        const driveName = this.props.userName + " and " + this.props.profile.firstName + " " + randomNumber();
        await this.props.tryCreateNewSharedDrive(driveName, [this.props.profile.id]);
    }

    render() {
        if (this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <Container>
                    <AllSharedVaultList showBackButton={false} showIconButtons={true} showWhiteBackground={true} />
                </Container>
            )
        }
    }

}

const mapStateToProps = state => ({
    driveList: state.common.fileVault.get("sharedDriveList"),
    drive: state.common.fileVault.get("sharedDrive"),
    userName: state.common.user.get("first"),
});

const mapDispatchToProps = dispatch => ({
    tryCreateNewSharedDrive: (name, users) => dispatch(tryCreateNewSharedDrive(name, users)),
    tryGetSharedDriveList: () => dispatch(tryGetSharedDriveList()),
    setSharedDrive: (sharedDrive) => dispatch(setSharedDrive(sharedDrive)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FirmCoachRecordSharedDocuments);
