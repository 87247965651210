import React from 'react';

/* Components */
import ConsumerNav from '../navbars/containers/nav';
import { PageFull, PageContentContainer } from '../navbars/components/navlayout';

/* Containers */
import MessagesConsumer from "./containers/messageslist";

const Messages = () => (
    <div>
        <ConsumerNav />
        <PageFull>
            <PageContentContainer>
                <MessagesConsumer />
            </PageContentContainer>
        </PageFull>
    </div>
);

export default Messages;