import React from 'react';
import moment from 'moment/moment';
import {connect} from "react-redux";

/* Containers */
import SchedulerFlow from "../../../iframes/scheduler/containers/flow";

/* Store */
import {setTeamMemberMeetingsChanged} from "../../appointments/store/meetings";

/* Middleware */
import {trackScheduleComplimentaryCall, trackSchedulePaidCall, /*trackAvailabilityComplimentaryCall, trackAvailabilityPaidCall*/} from "../../../common/utils/tracking";

class TeamScheduler extends React.Component {

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        // const coachDetails = {
        //     "coachId": this.props.profile.id,
        //     "coachName": this.props.profile.firstName+" "+this.props.profile.lastName
        // }
        // if(this.props.profile.defaultCallLength === 15 && this.props.purchaseHistoryId === null && !this.props.isWillowSession) {
        //     this.props.trackAvailabilityComplimentaryCall(coachDetails)
        // } else {
        //     this.props.trackAvailabilityPaidCall(coachDetails)
        // }
    }

    render() {
        return(
            <SchedulerFlow
                userId={this.props.profile.id}
                userFirstName={this.props.profile.firstName}
                userLastName={this.props.profile.lastName}
                userPhotoUrl={this.props.profile.photo}
                purchaseHistoryId={this.props.purchaseHistoryId}
                isWillowSession={this.props.isWillowSession}
                eventCustomMinutes={this.props.profile.defaultCallLength}
                availabilityCloseBtn={false}
                availabilityOnClose={this.props.onBack}
                confirmOnClose={this.props.onClose}
                confirmOnScheduled={() => {
                    console.log('scheduler confirmOnScheduled called');
                    const thisMoment = moment();
                    console.log(thisMoment);
                    this.props.setTeamMemberMeetingsChanged(thisMoment)
                    const coachDetails = {
                        "coachId": this.props.profile.id,
                        "coachName": this.props.profile.firstName+" "+this.props.profile.lastName
                    }
                    if(this.props.profile.defaultCallLength === 15 && this.props.purchaseHistoryId === null && !this.props.isWillowSession) {
                        this.props.trackScheduleComplimentaryCall(coachDetails)
                    } else {
                        this.props.trackSchedulePaidCall(coachDetails)
                    }
                    this.props.onSchedule();
                }}
                showArrowClose={false}
                useNewDesign={true}
            />
        )
    }
}

const mapDispatchToProps = dispatch => ({
    setTeamMemberMeetingsChanged: (datetime) => dispatch(setTeamMemberMeetingsChanged(datetime)),
    // trackAvailabilityComplimentaryCall: (coachDetails) => dispatch(trackAvailabilityComplimentaryCall(coachDetails)),
    // trackAvailabilityPaidCall: (coachDetails) => dispatch(trackAvailabilityPaidCall(coachDetails)),
    trackScheduleComplimentaryCall: (coachDetails) => dispatch(trackScheduleComplimentaryCall(coachDetails)),
    trackSchedulePaidCall: (coachDetails) => dispatch(trackSchedulePaidCall(coachDetails)),
});

export default connect(null, mapDispatchToProps)(TeamScheduler);
