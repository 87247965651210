export const devAdvisors = [
    {
        "advisorId": 4982,
        "advisorFullName": "Diana Yanez",
        "advisor": {
            "id": 4982,
            "firstName": "Diana",
            "lastName": "Yanez",
            "onlyLastName": "Yanez",
            "businessTitle": null,
            "wmcEmployeeId": null,
            "photo": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1692750236/kzglee5epswktcuhd0ec.png",
            "heroImageUrl": null,
            "signUpDate": "2023-08-22T23:32:00.045121+00:00",
            "additionalProfileInformation": {
                "id": 4982,
                "firstName": "Diana",
                "lastName": "Yanez",
                "photos": [],
                "intro": {
                    "video": "",
                    "description": ""
                },
                "expertise": [],
                "specialities": [],
                "services": [],
                "disclosure": null,
                "logos": [],
                "firmSec": null,
                "advisorSec": null,
                "firmName": null,
                "firmBio": null,
                "firmLogo": null,
                "firmAddress": {},
                "legalConfirmationDate": null,
            },
            "badActorDate": "12/12/2024",
            "bio": "",
            "gender": null,
            "whyBecameCoach": "N/A",
            "freeTime": "N/A",
            "endorsement1": null,
            "endorsement2": null,
            "endorsement3": null,
            "endorsements": [],
            "interests": [],
            "skills": [],
            "portfolio": [],
            "faqs": [],
            "jobs": [],
            "testimonials": [],
            "degrees": [],
            "licenses": [],
            "crd": null,
            "companyName": null,
            "address1": null,
            "address2": null,
            "zipCode": null,
            "city": "",
            "state": "",
            "bookingAvailability": "available",
            "facebookUrl": "",
            "twitterUrl": "",
            "linkedinUrl": "",
            "instagramUrl": null,
            "blogUrl": null,
            "youtubeUrl": null,
            "introVideoUrl": "",
            "screeningCallLength": 30,
            "defaultCallLength": 30,
            "expertCategories": [],
            "advancedDegree": "",
            "advancedDegreeInstitution": "",
            "advancedDegreeCity": "",
            "advancedDegreeState": "",
            "advancedDegreeYear": null,
            "coachingCertification": "",
            "coachingCertificationYear": null,
            "otherEducation": "",
            "iwiNumber": "NUMBERISBLANK",
            "cfpNumber": "271040",
            "legalConfirmationDate": null,
            "isWMCEmployee": true,
            "storeActive": false,
            "storeShowScheduler": false,
            "storeShowEmailer": false,
            "storeTitle": "",
            "storePhoto": null,
            "storeHandle": "dyanez1",
            "storeDescription": null,
            "highlights": null,
            "publicHandle": "dyanez1",
            "profileActive": true,
            "profileShowScheduler": false,
            "profileShowEmailer": false,
            "website": "",
            "schedulerActive": false,
            "schedulerHandle": "dyanez1",
            "callNotificationsOn": true,
            "minimumInvestableAssets": "0"
        },
        "completedOn": "2023-10-05T10:11:36.291235+00:00",
        "certificationDefinitionId": 3,
        "designation": "CAW\u2122",
        "name": "CERTIFIED ADVISOR FOR WOMEN\u2122",
        "certificationProgressId": 133,
        "purchaseId": 316
    },
    {
        "advisorId": 4872,
        "advisorFullName": "Harrison Hinz",
        "advisor": {
            "id": 4872,
            "firstName": "Harrison",
            "lastName": "Hinz",
            "onlyLastName": "Hinz",
            "businessTitle": null,
            "wmcEmployeeId": "partner:cert1200 utm_source:null utm_medium:null utm_campaign:null utm_content:null utm_term:null",
            "photo": "https://res.cloudinary.com/blz-lilyriche/image/upload/yb3esjcmdyfstf4udqpv.jpg",
            "heroImageUrl": null,
            "signUpDate": "2023-06-07T21:50:01.714942+00:00",
            "additionalProfileInformation": {
                "id": 4872,
                "firstName": "Harrison",
                "lastName": "Hinz",
                "photos": [
                    "https://fp-financial.com/wp-content/uploads/2018/10/HarrisonH_HS.jpg"
                ],
                "intro": {
                    "video": "",
                    "description": "My name is Harrison Hinz! My wife, our sons, and I hale from the Beehive State where I graduated from Utah Valley University in Personal Financial Planning.  Before joining First Pacific, I worked six years in the banking industry as a personal banker.  I have always enjoyed working with numbers and people. I love crunching numbers and knew I wanted to be in an industry where I can help individuals. Now I have the best of both! I take a holistic approach to financial planning taking into consideration all of your life journeys to create a plan right for you. \n\n At home, I can be found practicing different sports with my sons, studying up on the latest and greatest dad jokes, and mapping out the family\u2019s next Northwest adventure.  I am fluent in Italian and dabble in the sport of pickleball."
                },
                "expertise": [
                    "Nurses",
                    "NextGen",
                    "Financial Planning",
                    "Investment Management",
                    "Family Planning",
                    "Retirement Planning"
                ],
                "specialities": [],
                "services": [],
                "disclosure": "",
                "logos": [
                    "https://fp-financial.com/wp-content/uploads/2018/09/homepage_logo.png"
                ],
                "firmSec": "284736",
                "advisorSec": "6761216",
                "firmName": "Spark by First Pacific Financial",
                "firmBio": "",
                "firmLogo": "",
                "firmAddress": {
                    "address": "610 Esther St, Suite 100 Vancouver, WA 98660"
                },
                "legalConfirmationDate": null
            },
            "bio": null,
            "badActorDate": "12/12/2024",
            "gender": null,
            "whyBecameCoach": null,
            "freeTime": null,
            "endorsement1": null,
            "endorsement2": null,
            "endorsement3": null,
            "endorsements": null,
            "interests": [],
            "skills": [],
            "portfolio": [],
            "faqs": [],
            "jobs": [],
            "testimonials": [],
            "degrees": [],
            "licenses": [],
            "crd": null,
            "companyName": null,
            "address1": null,
            "address2": null,
            "zipCode": null,
            "city": "",
            "state": "",
            "bookingAvailability": "available",
            "facebookUrl": "",
            "twitterUrl": "",
            "linkedinUrl": "",
            "instagramUrl": null,
            "blogUrl": null,
            "youtubeUrl": null,
            "introVideoUrl": "",
            "screeningCallLength": 30,
            "defaultCallLength": 30,
            "expertCategories": [],
            "advancedDegree": null,
            "advancedDegreeInstitution": null,
            "advancedDegreeCity": null,
            "advancedDegreeState": null,
            "advancedDegreeYear": null,
            "coachingCertification": null,
            "coachingCertificationYear": null,
            "otherEducation": null,
            "iwiNumber": "NUMBERISBLANK",
            "cfpNumber": "306125",
            "legalConfirmationDate": null,
            "isWMCEmployee": true,
            "storeActive": false,
            "storeShowScheduler": false,
            "storeShowEmailer": false,
            "storeTitle": "",
            "storePhoto": null,
            "storeHandle": "hhinz",
            "storeDescription": null,
            "highlights": null,
            "publicHandle": "hhinz",
            "profileActive": true,
            "profileShowScheduler": false,
            "profileShowEmailer": false,
            "website": "",
            "schedulerActive": false,
            "schedulerHandle": "hhinz",
            "callNotificationsOn": true
        },
        "completedOn": "2023-09-28T18:16:25.408332+00:00",
        "certificationDefinitionId": 4,
        "designation": "CAN\u2122",
        "name": "CERTIFIED ADVISOR FOR NEXTGEN\u2122",
        "certificationProgressId": 100,
        "purchaseId": null
    },
    {
        "advisorId": 4762,
        "advisorFullName": "Rich Wallner",
        "advisor": {
            "id": 4762,
            "firstName": "Rich",
            "lastName": "Wallner",
            "onlyLastName": "Wallner",
            "businessTitle": null,
            "wmcEmployeeId": "partner:testers utm_source:null utm_medium:null utm_campaign:null utm_content:null utm_term:null",
            "photo": "https://res.cloudinary.com/blz-lilyriche/image/upload/yb3esjcmdyfstf4udqpv.jpg",
            "heroImageUrl": null,
            "signUpDate": "2023-03-18T18:28:54.374248+00:00",
            "additionalProfileInformation": {
                "id": 4762,
                "firstName": "Rich",
                "lastName": "Wallner",
                "photos": [
                    "https://res.cloudinary.com/blz-lilyriche/image/upload/v1685114854/qcuxrjwjxjthpl9cgioj"
                ],
                "intro": {
                    "video": "",
                    "description": "Rich is a CFP, CLU, Financial Planner & Advisor who works with individuals, families and small business owners who want to improve their financial lives - no minimum assets required. Helping you work toward your financial goals, by carefully developing a plan that aligns with your specific needs, experience and time horizon, is an honor and my highest priority. Working together, we will create a customized plan focused on your key financial goals. Regardless of where you are in life, I have the tools and financial products that, along with your collaboration, we can combine into an appropriate assessment for you."
                },
                "expertise": [
                    "Financial Planning",
                    "Aging In Place Planning",
                    "Lifecycle Planning",
                    "Retirement Planning",
                    "Estate Planning"
                ],
                "specialities": [],
                "services": [],
                "disclosure": "The financial professionals at Service Financial Group are registered representatives with, and securities and advisory services are offered through LPL Financial (LPL), a registered investment advisor and broker-dealer (member FINRA/SIPC). Insurance products are offered through LPL or its licensed affiliates. Service Credit Union and Service Financial Group are not registered as a broker-dealer or investment advisor. Registered representatives of LPL offer products and services using Service Financial Group, and may also be employees of Service Credit Union. These products and services are being offered through LPL or its affiliates, which are separate entities from, and not affiliates of, Service Credit Union or Service Financial Group. Securities and insurance offered through LPL or its affiliates are:\r\nNot Insured by NCUA or Any Other Government Agency\tNot Credit Union Guaranteed\tNot Credit Union Deposits or Obligations\tMay Lose Value",
                "logos": [],
                "firmSec": "",
                "advisorSec": "",
                "firmName": "Service Financial Group",
                "firmBio": "The Service Financial Group Program can help you plan for life\u2019s events\u2014expected and unexpected. Experienced Service Financial Group professionals located at most stateside Service Credit Union branches will listen to your needs and help you make the right financial decisions for you and your family. The Service Financial Group Program is offered through LPL Financial, a broker/dealer focused on serving credit union members. For more information about LPL Financial, please visit www.lpl.com.",
                "firmLogo": "https://cdn.servicecu.org//wp-content/uploads/2021/01/ServiceFinancial.jpg",
                "firmAddress": {
                    "address": "Service CU, 158 N Broadway Salem, NH 03079"
                },
                "legalConfirmationDate": null
            },
            "bio": null,
            "gender": null,
            "whyBecameCoach": null,
            "freeTime": null,
            "endorsement1": null,
            "endorsement2": null,
            "endorsement3": null,
            "endorsements": null,
            "interests": [],
            "skills": [],
            "portfolio": [],
            "faqs": [],
            "jobs": [],
            "testimonials": [],
            "degrees": [],
            "licenses": [],
            "crd": null,
            "companyName": null,
            "address1": null,
            "address2": null,
            "zipCode": null,
            "city": "",
            "state": "",
            "bookingAvailability": "available",
            "facebookUrl": "",
            "twitterUrl": "",
            "linkedinUrl": "",
            "instagramUrl": null,
            "blogUrl": null,
            "youtubeUrl": null,
            "introVideoUrl": "",
            "screeningCallLength": 30,
            "defaultCallLength": 30,
            "expertCategories": [],
            "advancedDegree": null,
            "advancedDegreeInstitution": null,
            "advancedDegreeCity": null,
            "advancedDegreeState": null,
            "advancedDegreeYear": null,
            "coachingCertification": null,
            "coachingCertificationYear": null,
            "otherEducation": null,
            "iwiNumber": null,
            "cfpNumber": null,
            "legalConfirmationDate": null,
            "isWMCEmployee": true,
            "storeActive": false,
            "storeShowScheduler": false,
            "storeShowEmailer": false,
            "storeTitle": "",
            "storePhoto": null,
            "storeHandle": "rwallner1",
            "storeDescription": null,
            "highlights": null,
            "publicHandle": "rwallner1",
            "profileActive": true,
            "profileShowScheduler": false,
            "profileShowEmailer": false,
            "website": "",
            "schedulerActive": false,
            "schedulerHandle": "rwallner1",
            "callNotificationsOn": true
        },
        "completedOn": "2023-09-22T14:14:12.047957+00:00",
        "certificationDefinitionId": 3,
        "designation": "CAW\u2122",
        "name": "CERTIFIED ADVISOR FOR WOMEN\u2122",
        "certificationProgressId": 136,
        "purchaseId": 303
    },
    {
        "advisorId": 4866,
        "advisorFullName": "Marguerita Cheng",
        "advisor": {
            "id": 4866,
            "firstName": "Marguerita",
            "lastName": "Cheng",
            "onlyLastName": "Cheng",
            "businessTitle": null,
            "wmcEmployeeId": "partner:cert1 utm_source:null utm_medium:null utm_campaign:null utm_content:null utm_term:null",
            "photo": "https://res.cloudinary.com/blz-lilyriche/image/upload/yb3esjcmdyfstf4udqpv.jpg",
            "heroImageUrl": null,
            "signUpDate": "2023-05-23T04:23:41.539691+00:00",
            "additionalProfileInformation": {
                "id": 4866,
                "firstName": "Marguerita",
                "lastName": "Cheng",
                "photos": [
                    "https://www.blueoceanglobalwealth.com/sites/blueoceanglobalwealth.us1.advisor.ws/files/images/2022.09.28_MargueritaCheng_FINAL-5747%20copy.jpg"
                ],
                "intro": {
                    "video": "",
                    "description": "My name is Marguerita Cheng! My friends call me \u201cRita\u201d and I\u2019m an INFJ with an Amiable Driver communication style. In other words, I help you get things done but I\u2019m really nice about it. \n\nMy family is multi-ethnic and multi-cultural with relatives all over the world. I recently visited my sister in Australia where I got to enjoy their beautiful beaches, amazing coffee culture, and the people who have a unique vibe that somehow manages to seem both British and Hawaiian. While I was there, I explored the city in my favorite tourist mode, which is running. I participated in a marathon there that was the hardest, hottest, and hilliest course I have ever experienced. All the running gave me a lot of need for great food which I also enjoyed thoroughly. I also got to pet a Koala! \n\nMy favorite food is noodles, of any kind: ramen, udon, pho, you name it. \n\nIn my professional life, I have had several leadership positions with the CFP Board and have been a CERTIFIED FINANCIAL PLANNER\u2122 for about 20 years. I\u2019m the CEO of Blue Ocean Global Wealth which is a certified Women\u2019s Business Enterprise (www.wbenc.org) where we foster an inclusive culture that respects the diversity of talent and values brought to our team by each member. \n\nI am honored to have received numerous awards for my work on behalf of my clients and our industry, including being named #2 on the Investopedia 100 Top Financial Advisors of 2023.  I wrote a book to help women flex their financial muscle called Diary of a CFP\u00ae Pro and I host a weekly podcast called Margaritas with Marguerita."
                },
                "expertise": [
                    "Women",
                    "Families",
                    "Entrepreneurs",
                    "Wealth Management",
                    "Financial Planning",
                    "Risk Management"
                ],
                "specialities": [],
                "services": [],
                "disclosure": "Advisory and Brokerage Services offered through Private Client Services LLC, member FINRA/SIPC.  Private Client Services LLC and Blue Ocean Global Wealth are unaffiliated entities. Financial Advisors do not provide specific tax/legal advice and this information should not be considered as such. You should always consult your tax/legal advisor regarding your own specific tax/legal situation. Separate from the financial plan and our role as a financial planner, we may recommend the purchase of specific investment or insurance products or account. These product recommendations are not part of the financial plan and you are under no obligation to follow them. Life insurance products contain fees, such as mortality and expense charges (which may increase over time), and may contain restrictions, such as surrender periods.",
                "logos": [
                    "https://www.blueoceanglobalwealth.com/sites/blueoceanglobalwealth.us1.advisor.ws/files/styles/max_2600x2600/public/images/Dark%20Blue.png?itok=SCpQ064Q"
                ],
                "firmSec": "120222",
                "advisorSec": "3149803",
                "firmName": "Blue Ocean Global Wealth",
                "firmBio": "",
                "firmLogo": "",
                "firmAddress": {
                    "address": "9841 Washingtonian Boulevard Suite 200 Gaithersburg MD 20878"
                },
                "legalConfirmationDate": null
            },
            "bio": null,
            "gender": null,
            "whyBecameCoach": null,
            "freeTime": null,
            "endorsement1": null,
            "endorsement2": null,
            "endorsement3": null,
            "endorsements": null,
            "interests": [],
            "skills": [],
            "portfolio": [],
            "faqs": [],
            "jobs": [],
            "testimonials": [],
            "degrees": [],
            "licenses": [],
            "crd": null,
            "companyName": null,
            "address1": null,
            "address2": null,
            "zipCode": null,
            "city": "",
            "state": "",
            "bookingAvailability": "available",
            "facebookUrl": "",
            "twitterUrl": "",
            "linkedinUrl": "",
            "instagramUrl": null,
            "blogUrl": null,
            "youtubeUrl": null,
            "introVideoUrl": "",
            "screeningCallLength": 30,
            "defaultCallLength": 30,
            "expertCategories": [],
            "advancedDegree": null,
            "advancedDegreeInstitution": null,
            "advancedDegreeCity": null,
            "advancedDegreeState": null,
            "advancedDegreeYear": null,
            "coachingCertification": null,
            "coachingCertificationYear": null,
            "otherEducation": null,
            "iwiNumber": null,
            "cfpNumber": null,
            "legalConfirmationDate": null,
            "isWMCEmployee": true,
            "storeActive": false,
            "storeShowScheduler": false,
            "storeShowEmailer": false,
            "storeTitle": "",
            "storePhoto": null,
            "storeHandle": "mcheng",
            "storeDescription": null,
            "highlights": null,
            "publicHandle": "mcheng",
            "profileActive": true,
            "profileShowScheduler": false,
            "profileShowEmailer": false,
            "website": "",
            "schedulerActive": false,
            "schedulerHandle": "mcheng",
            "callNotificationsOn": true
        },
        "completedOn": "2023-09-22T14:14:11.895495+00:00",
        "certificationDefinitionId": 3,
        "designation": "CAW\u2122",
        "name": "CERTIFIED ADVISOR FOR WOMEN\u2122",
        "certificationProgressId": 132,
        "purchaseId": 302
    },
    {
        "advisorId": 4716,
        "advisorFullName": "Meghan  Dwyer",
        "advisor": {
            "id": 4716,
            "firstName": "Meghan ",
            "lastName": "Dwyer",
            "onlyLastName": "Dwyer",
            "businessTitle": null,
            "wmcEmployeeId": " utm_source:hs_email utm_medium:email utm_campaign:Q1 23' Advisor RIA Database utm_content:248483649 utm_term:null",
            "photo": "https://res.cloudinary.com/blz-lilyriche/image/upload/yb3esjcmdyfstf4udqpv.jpg",
            "heroImageUrl": null,
            "signUpDate": "2023-03-02T18:57:37.997635+00:00",
            "additionalProfileInformation": {
                "id": 4716,
                "firstName": "Meghan ",
                "lastName": "Dwyer",
                "photos": [
                    "https://docs.trustwillow.com/MD_headshots_23-2.jpg",
                    "https://docs.trustwillow.com/MD-Headshots-15-1-min.jpg"
                ],
                "intro": {
                    "video": "",
                    "description": "My name is Meghan Dwyer. As the daughter of a firefighter and a nurse, I always knew that I wanted to help people. I realized that I could help people in my own way through personal connection and support. By combining my empathy and concern for others with my passion or financial planning, I've found my stride as a planner so that I can make a serious impact in my client\u2019s lives. \nPersonally, I have a huge passion for empowering women, and started the \u201cMoney Isn\u2019t Scary\u201d podcast, where I shares my own emotional journey with money. Through sharing personal stories and beliefs, my goal is to inspire other women to explore the sometimes-uncomfortable roles that money plays in our lives. Ultimately, I aspire to remove the stigma and fear around money that exists in our culture.  \nI am a CERTIFIED FINANCIAL PLANNER\u2122 and Certified Divorce Financial Analyst, at Single Point Partners in Boston, MA. Prior to working at SPP, I worked for, Modera Wealth Management, where I worked directly with individuals and families to build holistic, comprehensive financial strategies tailored to their unique needs. Prior to Modera, I worked in BNY Mellon\u2019s Wealth Management division for nearly 11 years. \nI live in Milton, MA with my husband Craig, my two boys - Colin, age 7, and Ryan, age 5, and my goldendoodle, Teddy (age 10)."
                },
                "expertise": [
                    "Women",
                    "Financial Planning",
                    "Wealth Management"
                ],
                "specialities": [],
                "services": [],
                "disclosure": "",
                "logos": [
                    "https://spcfo.com/wp-content/uploads/2018/02/SPP_Logo_2.png"
                ],
                "firmSec": "163793",
                "advisorSec": "6787614",
                "firmName": "Single Point Partners",
                "firmBio": "Single Point Partners is a fee-only financial planning firm located in Boston, MA.  We act as a Project Manager for your financial life. Clients who work with us are looking for more than just advice, they want a partner to make sure things get done.\r\n\r\nSingle Point was founded for families who strive to make decisions that are better than \u201cgood enough\u201d. We provide context and clarity to the planning, investing, and organization of your entire balance sheet. Our flat fee is the cornerstone of our belief that clients shouldn\u2019t have to wonder \u201cwhat\u2019s in it for their advisor?\u201d when making decisions.",
                "firmLogo": "https://spcfo.com/wp-content/uploads/2018/02/SPP_Logo_2.png",
                "firmAddress": {
                    "address": "277 Dartmouth Street, Boston, MA 02116"
                },
                "legalConfirmationDate": null
            },
            "bio": null,
            "gender": null,
            "whyBecameCoach": null,
            "freeTime": null,
            "endorsement1": null,
            "endorsement2": null,
            "endorsement3": null,
            "endorsements": null,
            "interests": [],
            "skills": [],
            "portfolio": [],
            "faqs": [],
            "jobs": [],
            "testimonials": [],
            "degrees": [],
            "licenses": [],
            "crd": null,
            "companyName": null,
            "address1": null,
            "address2": null,
            "zipCode": null,
            "city": "",
            "state": "",
            "bookingAvailability": "available",
            "facebookUrl": "",
            "twitterUrl": "",
            "linkedinUrl": "",
            "instagramUrl": null,
            "blogUrl": null,
            "youtubeUrl": null,
            "introVideoUrl": "",
            "screeningCallLength": 30,
            "defaultCallLength": 30,
            "expertCategories": [],
            "advancedDegree": null,
            "advancedDegreeInstitution": null,
            "advancedDegreeCity": null,
            "advancedDegreeState": null,
            "advancedDegreeYear": null,
            "coachingCertification": null,
            "coachingCertificationYear": null,
            "otherEducation": null,
            "iwiNumber": "NUMBERISBLANK",
            "cfpNumber": "152193",
            "legalConfirmationDate": null,
            "isWMCEmployee": true,
            "storeActive": false,
            "storeShowScheduler": false,
            "storeShowEmailer": false,
            "storeTitle": "",
            "storePhoto": null,
            "storeHandle": "mdwyer",
            "storeDescription": null,
            "highlights": null,
            "publicHandle": "mdwyer",
            "profileActive": true,
            "profileShowScheduler": false,
            "profileShowEmailer": false,
            "website": "",
            "schedulerActive": false,
            "schedulerHandle": "mdwyer",
            "callNotificationsOn": true
        },
        "completedOn": "2023-09-22T14:14:11.637485+00:00",
        "certificationDefinitionId": 3,
        "designation": "CAW\u2122",
        "name": "CERTIFIED ADVISOR FOR WOMEN\u2122",
        "certificationProgressId": 76,
        "purchaseId": null
    },
    {
        "advisorId": 4985,
        "advisorFullName": "Alana Santarelli",
        "advisor": {
            "id": 4985,
            "firstName": "Alana",
            "lastName": "Santarelli",
            "onlyLastName": "Santarelli",
            "businessTitle": null,
            "wmcEmployeeId": null,
            "photo": "https://res.cloudinary.com/blz-lilyriche/image/upload/yb3esjcmdyfstf4udqpv.jpg",
            "heroImageUrl": null,
            "signUpDate": "2023-09-06T13:26:33.676186+00:00",
            "additionalProfileInformation": {
                "id": 4985,
                "firstName": "Alana",
                "lastName": "Santarelli",
                "photos": [
                    "https://res.cloudinary.com/blz-lilyriche/image/upload/v1694201983/pdoxadcjtaron0xqo9pc.jpg"
                ],
                "intro": {
                    "video": "",
                    "description": "Alana began her career in finance at Merrill Lynch as a Registered Wealth Management Client Associate before joining Willow in 2023."
                },
                "expertise": [
                    "women",
                    "nextgen"
                ],
                "specialities": [],
                "services": [],
                "disclosure": "XYZ",
                "logos": [],
                "firmSec": "1243",
                "advisorSec": "1234",
                "firmName": "Willow",
                "firmBio": "Willow helps financial professionals to acquire & grow business with New Majority Clients.",
                "firmLogo": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1694201984/o24cxbjorqnptsvzbg0w.jpg",
                "firmAddress": {
                    "address": "123 Main St"
                },
                "legalConfirmationDate": "2023-09-11T15:53:49.464Z"
            },
            "bio": null,
            "gender": null,
            "whyBecameCoach": null,
            "freeTime": null,
            "endorsement1": null,
            "endorsement2": null,
            "endorsement3": null,
            "endorsements": null,
            "interests": [],
            "skills": [],
            "portfolio": [],
            "faqs": [],
            "jobs": [],
            "testimonials": [],
            "degrees": [],
            "licenses": [],
            "crd": null,
            "companyName": null,
            "address1": null,
            "address2": null,
            "zipCode": null,
            "city": "",
            "state": "",
            "bookingAvailability": "available",
            "facebookUrl": "",
            "twitterUrl": "",
            "linkedinUrl": "",
            "instagramUrl": null,
            "blogUrl": null,
            "youtubeUrl": null,
            "introVideoUrl": "",
            "screeningCallLength": 30,
            "defaultCallLength": 30,
            "expertCategories": [],
            "advancedDegree": "",
            "advancedDegreeInstitution": "",
            "advancedDegreeCity": "",
            "advancedDegreeState": "",
            "advancedDegreeYear": null,
            "coachingCertification": "",
            "coachingCertificationYear": null,
            "otherEducation": "",
            "iwiNumber": "NUMBERISBLANK",
            "cfpNumber": "NUMBERISBLANK",
            "legalConfirmationDate": "2023-09-11T15:53:49.464514+00:00",
            "isWMCEmployee": true,
            "storeActive": false,
            "storeShowScheduler": false,
            "storeShowEmailer": false,
            "storeTitle": "",
            "storePhoto": null,
            "storeHandle": "asantarelli",
            "storeDescription": null,
            "highlights": null,
            "publicHandle": "asantarelli",
            "profileActive": true,
            "profileShowScheduler": false,
            "profileShowEmailer": false,
            "website": "",
            "schedulerActive": false,
            "schedulerHandle": "asantarelli",
            "callNotificationsOn": true
        },
        "completedOn": "2023-09-13T07:40:37.666949+00:00",
        "certificationDefinitionId": 3,
        "designation": "CAW\u2122",
        "name": "CERTIFIED ADVISOR FOR WOMEN\u2122",
        "certificationProgressId": 103,
        "purchaseId": 318
    },
    {
        "advisorId": 4889,
        "advisorFullName": "Ise O",
        "advisor": {
            "id": 4889,
            "firstName": "Ise",
            "lastName": "O",
            "onlyLastName": "O",
            "businessTitle": null,
            "wmcEmployeeId": "partner:blackrock utm_source:null utm_medium:null utm_campaign:null utm_content:null utm_term:null",
            "photo": "https://res.cloudinary.com/blz-lilyriche/image/upload/yb3esjcmdyfstf4udqpv.jpg",
            "heroImageUrl": null,
            "signUpDate": "2023-06-15T14:12:36.266195+00:00",
            "additionalProfileInformation": {
                "id": 4889,
                "firstName": "Ise",
                "lastName": "O",
                "photos": [
                    "https://res.cloudinary.com/blz-lilyriche/image/upload/v1686838883/d1zuihgpxwfuxtlksvov.png"
                ],
                "intro": {
                    "video": "link",
                    "description": "bio"
                },
                "expertise": [
                    "expertise"
                ],
                "specialities": [],
                "services": [],
                "disclosure": "disclosure",
                "logos": [],
                "firmSec": "333",
                "advisorSec": "333",
                "firmName": "name",
                "firmBio": "dsc",
                "firmLogo": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1686838884/xmqhazc7gfeclek9xscf.png",
                "firmAddress": {
                    "address": "address"
                },
                "legalConfirmationDate": "2023-06-15T14:30:05.301Z"
            },
            "bio": null,
            "gender": null,
            "whyBecameCoach": null,
            "freeTime": null,
            "endorsement1": null,
            "endorsement2": null,
            "endorsement3": null,
            "endorsements": null,
            "interests": [],
            "skills": [],
            "portfolio": [],
            "faqs": [],
            "jobs": [],
            "testimonials": [],
            "degrees": [],
            "licenses": [],
            "crd": "",
            "companyName": "",
            "address1": "",
            "address2": "",
            "zipCode": "",
            "city": "",
            "state": "",
            "bookingAvailability": "available",
            "facebookUrl": "",
            "twitterUrl": "",
            "linkedinUrl": "",
            "instagramUrl": null,
            "blogUrl": null,
            "youtubeUrl": null,
            "introVideoUrl": "",
            "screeningCallLength": 30,
            "defaultCallLength": 30,
            "expertCategories": [
                {
                    "categoryId": 1,
                    "categoryName": "Willow Financial Coach"
                }
            ],
            "advancedDegree": "",
            "advancedDegreeInstitution": "",
            "advancedDegreeCity": "",
            "advancedDegreeState": "",
            "advancedDegreeYear": null,
            "coachingCertification": "",
            "coachingCertificationYear": null,
            "otherEducation": "",
            "iwiNumber": "NUMBERISBLANK",
            "cfpNumber": "NUMBERISBLANK",
            "legalConfirmationDate": "2023-06-15T14:30:05.301963+00:00",
            "isWMCEmployee": true,
            "storeActive": false,
            "storeShowScheduler": false,
            "storeShowEmailer": false,
            "storeTitle": "",
            "storePhoto": null,
            "storeHandle": "iseqa5",
            "storeDescription": null,
            "highlights": "",
            "publicHandle": "iseqa5",
            "profileActive": true,
            "profileShowScheduler": false,
            "profileShowEmailer": false,
            "website": "",
            "schedulerActive": false,
            "schedulerHandle": "iseqa5",
            "callNotificationsOn": true
        },
        "completedOn": "2023-09-13T07:40:37.661772+00:00",
        "certificationDefinitionId": 3,
        "designation": "CAW\u2122",
        "name": "CERTIFIED ADVISOR FOR WOMEN\u2122",
        "certificationProgressId": 99,
        "purchaseId": null
    },
    {
        "advisorId": 4880,
        "advisorFullName": "ise+qa2 o",
        "advisor": {
            "id": 4880,
            "firstName": "ise+qa2",
            "lastName": "o",
            "onlyLastName": "o",
            "businessTitle": null,
            "wmcEmployeeId": "partner:cert1 utm_source:null utm_medium:null utm_campaign:null utm_content:null utm_term:null",
            "photo": "https://res.cloudinary.com/blz-lilyriche/image/upload/yb3esjcmdyfstf4udqpv.jpg",
            "heroImageUrl": null,
            "signUpDate": "2023-06-12T13:14:20.544988+00:00",
            "additionalProfileInformation": {
                "id": 4880,
                "firstName": "ise+qa2",
                "lastName": "o",
                "photos": [
                    "https://res.cloudinary.com/blz-lilyriche/image/upload/v1686576123/vhlbra8drp2riibh9zg0.png"
                ],
                "intro": {
                    "video": "video",
                    "description": "bio"
                },
                "expertise": [
                    "ex"
                ],
                "specialities": [],
                "services": [],
                "disclosure": "dis",
                "logos": [],
                "firmSec": "999",
                "advisorSec": "999",
                "firmName": "firm",
                "firmBio": "desc",
                "firmLogo": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1686687887/jkgv1tqsldp9eyatr1eo.png",
                "firmAddress": {
                    "address": "addrress"
                },
                "legalConfirmationDate": "2023-06-28T15:48:30.689Z"
            },
            "bio": null,
            "gender": null,
            "whyBecameCoach": null,
            "freeTime": null,
            "endorsement1": null,
            "endorsement2": null,
            "endorsement3": null,
            "endorsements": null,
            "interests": [],
            "skills": [],
            "portfolio": [],
            "faqs": [],
            "jobs": [],
            "testimonials": [],
            "degrees": [],
            "licenses": [],
            "crd": "",
            "companyName": "",
            "address1": "",
            "address2": "",
            "zipCode": "",
            "city": "",
            "state": "",
            "bookingAvailability": "available",
            "facebookUrl": "",
            "twitterUrl": "",
            "linkedinUrl": "",
            "instagramUrl": null,
            "blogUrl": null,
            "youtubeUrl": null,
            "introVideoUrl": "",
            "screeningCallLength": 30,
            "defaultCallLength": 30,
            "expertCategories": [
                {
                    "categoryId": 4,
                    "categoryName": "Financial Coach"
                }
            ],
            "advancedDegree": "",
            "advancedDegreeInstitution": "",
            "advancedDegreeCity": "",
            "advancedDegreeState": "",
            "advancedDegreeYear": null,
            "coachingCertification": "",
            "coachingCertificationYear": null,
            "otherEducation": "",
            "iwiNumber": "NUMBERISBLANK",
            "cfpNumber": "NUMBERISBLANK",
            "legalConfirmationDate": "2023-06-28T15:48:30.689598+00:00",
            "isWMCEmployee": true,
            "storeActive": false,
            "storeShowScheduler": false,
            "storeShowEmailer": false,
            "storeTitle": "",
            "storePhoto": null,
            "storeHandle": "io32",
            "storeDescription": null,
            "highlights": "",
            "publicHandle": "io32",
            "profileActive": true,
            "profileShowScheduler": false,
            "profileShowEmailer": false,
            "website": "",
            "schedulerActive": false,
            "schedulerHandle": "io32",
            "callNotificationsOn": true
        },
        "completedOn": "2023-09-13T07:40:37.653189+00:00",
        "certificationDefinitionId": 3,
        "designation": "CAW\u2122",
        "name": "CERTIFIED ADVISOR FOR WOMEN\u2122",
        "certificationProgressId": 78,
        "purchaseId": null
    }
]

export const devAdvisor = {
    "id":900,
    "firstName":"Jason",
    "lastName":"Advisor 900",
    "photos":[
        null,
        null,
        "https://caycepollard-demo.carrd.co/assets/images/image01.jpg?v68863967512051"
    ],
    "intro":{
        "video":"yrd",
        "description":"fdsgfdg"
    },
    "expertise":[],
    "specialities":[],
    "services":[],
    "disclosure":"yrd",
    "logos":[
        null,
        null,
        "https://res.cloudinary.com/blz-lilyriche/image/upload/v1682006139/nk0wuylxf3kktgilbger.png"
    ],
    "firmSec":"yrd",
    "advisorSec":"yrd",
    "firmName":"yrd",
    "firmBio":"fdgfdg",
    "firmLogo":null,
    "firmAddress":{
        "address":"yrd"
    },
    "legalConfirmationDate":"2023-04-22T16:20:29.941Z"
}