import React from "react";
import ReactQuill from "react-quill";
import {v4 as uuid4} from "uuid";
import moment from "moment";
import {connect} from "react-redux";
import styled from "styled-components";

/* Components */
import {QuillSlim} from "../../common/components/richtexteditor";
import {colors} from "../../common/components/colors";
import {ProfileFilled} from "@ant-design/icons";
import {FontBody12, FontHeader16, FontTitle18} from "../../common/components/fonts";
import {Card, Icon, Body, Title, Action, BottomBorder5, Content} from '../components/contactactivity';
import {LogoLoading} from "../../common/components/loading";

/* Utils */
import {createNote} from '../../iframes/forms/utils/forms';

export const DateItem = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-top: 3px;
    text-align: left;
    width: 150px;
    @media screen and (max-width: 1440px) {
        margin-top: 8px;
    }
    @media screen and (max-width: 1088px) {
        margin-top: 3px;
    }
`;

class TeamForm extends React.Component {
    state={isLoading: true, data: {}, quill: ''};

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.data !== this.props.data) {
            return this.init();
        }
    }

    init = async () => {
        console.log(this.props.data.content)
        const note = await createNote(JSON.parse(this.props.data.content));
        console.log(note);
        await this.setState({
            "data": this.props.data,
            'quill' : <ReactQuill
                id={uuid4()}
                readOnly={true}
                theme={null}
                value={note}
                bounds={'.app'}
            />
        });
        return this.setState({"isLoading": false});
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <Card>
                    <Icon><ProfileFilled /></Icon>
                    <Body>
                        <div>
                            <Title><FontTitle18>{this.props.client.get("firstName")} {this.props.client.get("lastName")}</FontTitle18></Title>
                            <Action>
                                {this.state.data.datetime !== null &&
                                    <DateItem><FontBody12>{moment(this.state.data.datetime).format("MMM D, YYYY [at] h:mm a")}</FontBody12></DateItem>
                                }
                            </Action>
                        </div>
                        <Content>
                            <BottomBorder5>
                                <FontHeader16>{this.props.data.subject}</FontHeader16>
                            </BottomBorder5>
                            <QuillSlim bodyBorderColor={colors.white}>{this.state.quill}</QuillSlim>
                        </Content>
                    </Body>
                </Card>
            )
        }
    }
}

const mapStateToProps = state => ({
    client: state.enterprise.clientsDetail.get("client"),
});

export default connect(mapStateToProps, null)(TeamForm);

