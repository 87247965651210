import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FontTitle16, FontBody21To16} from "../../../common/components/fonts";
import {ButtonPrimary} from "../../../common/components/buttons";
import {SpaceBetweenMissingInfo, SettingsCardMissingInfo, ContainerApptNav} from "../components/progress";
import {colors} from "../../../common/components/colors";

/* Middleware */
import {tryGetCourses} from '../middleware/progress';

/* Containers */
import CourseOne from './progresscourseone';

const NoCoursesToShow = ({history}) => (
    <SettingsCardMissingInfo>
        <SpaceBetweenMissingInfo direction={"column"}>
            <FontTitle16>Nothing to do right now.<br/>Meet with coaches or learn on your own!</FontTitle16>
            <div onClick={() => {return history.push('/my-team')}}>
                <ButtonPrimary submit={false} label={"Coaches"} />
            </div>
        </SpaceBetweenMissingInfo>
    </SettingsCardMissingInfo>
)

const ToDosContainer = styled.div`
    padding-bottom: 120px;
`;
const NavOption = styled(FontBody21To16)`
    ${props => props.active !== true} {
        border-bottom: 3px solid ${colors.white};
        cursor: default;
    }
    ${props => props.active !== false} {
        border-bottom: none;
        cursor: pointer;
    }
    width: fit-content;
    color: ${colors.white};
}
`;

const Bar = styled.div`
    width:2px;
    margin: 4px 0;
    background-color: ${colors.white}
`;


class CoursesListToDo extends React.Component {
    state = {
        "isLoading": true,
        "coursesToDo": [],
        "coursesCompleted": [],
        "currentTab": 'outstanding'
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const coursesAll = await this.props.tryGetCourses()
        const coursesActive = await coursesAll.filter(a => {return a.course.isActive});
        await this.setState({
            "coursesToDo": await coursesActive.filter(a => {return !a.isCompleted}),
            "coursesCompleted": await coursesActive.filter(a => {return a.isCompleted}),
            "isLoading": false
        });
    };

    changeTab = async (tabToSwitchTo) => {
        await this.setState({"currentTab": tabToSwitchTo});
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <>
                    <ContainerApptNav>
                        <NavOption active={this.state.currentTab === 'outstanding'} onClick={() => {this.changeTab('outstanding');}}>
                            {'Outstanding'}
                        </NavOption>
                        <Bar />
                        <NavOption active={this.state.currentTab === 'completed'} onClick={() => {this.changeTab('completed');}}>
                            {'Completed'}
                        </NavOption>
                    </ContainerApptNav>
                    <ToDosContainer>
                        {this.state.currentTab === "outstanding" &&
                            <>{this.state.coursesToDo.length === 0
                                ? <NoCoursesToShow history={this.props.history} />
                                : <>
                                    {this.state.coursesToDo.map((a, index) => {
                                        return (<CourseOne key={index} data={a}/>)
                                    })}
                                </>
                            }</>
                        }
                        {this.state.currentTab === "completed" &&
                            <>{this.state.coursesCompleted.length === 0
                                ? <NoCoursesToShow history={this.props.history} />
                                : <>
                                    {this.state.coursesCompleted.map((a, index) => {
                                        return (<CourseOne key={index} data={a}/>)
                                    })}
                                </>
                            }</>
                        }
                    </ToDosContainer>
                </>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryGetCourses: () => dispatch(tryGetCourses())
});

export default connect(null, mapDispatchToProps)(withRouter(CoursesListToDo));