import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

/* Components */
import {colors} from '../../../common/components/colors';
import {LogoLoading} from "../../../common/components/loading";
import {FontBody16, FontSubtitle24To20} from "../../../common/components/fonts";
import {Container, ContainerTop, FlexRow} from "../components/createcourse";
import {IconButton24To14 } from '../../../common/components/buttons';

import {articleElements, quizElements} from '../static/elementtypes';

import {setCoursesAddElement, setCurrentPageEditing} from '../store/courses';

export const Element = styled.div`
    background-color: ${colors.backgroundColor1};
    width: 25%;
    display: inline-flex;
    margin: 10px 20px;
    height: 0;
    padding-bottom: 25%;
    cursor: pointer;
    @media screen and (max-width: 2000px) {
        width: 40%;
        padding-bottom: 40%;
        margin: 10px;
    }
`;

export const ElementContent = styled.div`
    display: block;
    margin: 30% auto;
    color: ${colors.backgroundColor5}
`;

class ArticleElements extends React.Component {
    state={isLoading: true, pageType: articleElements}

    componentDidMount() {
        this.setState({"isLoading": false})
    }

    componentDidUpdate(prevProps) {
        if (this.props.course !== prevProps.course || this.props.pageEditing !== prevProps.pageEditing) {
            if (this.props.course.get("id") !== null) {
                this.setState({"pageType": 
                    this.props.course.get("pages").get(this.props.pageEditing).get("pageType") === 'quiz' ?
                    quizElements
                    :
                    articleElements
                })
            }
        }
    }

    addElement = async (e) => {
        const pages = this.props.course.get("pages")
        const elements = pages.get(this.props.pageEditing).get("elements").toJS()
        const id = elements.length + (1000 * this.props.pageEditing)
        let element = {"id": id, "text": "", "type": e.type,  "style": {"textAlign": "left"}}
        if (e.type === "textArea") {
            element = {...element, "minLength": 0, "minRows": 5}
        } else if (e.type === "multiSelect") {
            element = {
                "id": id,
                "type": e.type,
                "isValid": true,
                "options": [
                    {"id": 1, "label": "", "active": true},
                    {"id": 2, "label": "", "active": false},
                    {"id": 3, "label": "Option 3", "active": false},
                    {"id": 4, "label": "Option 4", "active": false}
                ],
                "maxSelectable": 1,
                "isRequired": true
            }
        } else if (e.type === "slider") {
            element = {...element, "value": 0}
        } else if (e.type === "calendar") {
            element = {"id": id, "type": e.type, "value": new Date()}
        }
        elements.push(element)
        const page = {
            ...pages.get(this.props.pageEditing).toJS(),
            "elements": elements,
        }
        if (this.props.type === 'course') {
            let pagesJS = pages.toJS()
            await pagesJS.splice(this.props.pageEditing, 1, page)
            await this.props.setCurrentPageEditing(pages.get(this.props.pageEditing).get("pageNum"))
            this.props.setCoursesAddElement(pagesJS)
        } else {
            this.props.setCoursesAddElement([page])
        }
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <>
                    <Container backgroundColor={colors.backgroundColor2} style={{width: '27%', minWidth: '27%', maxWidth: '27%'}}>
                        <ContainerTop>
                            <FlexRow>
                                <FontSubtitle24To20>{'ELEMENTS'}</FontSubtitle24To20>
                                <IconButton24To14 />
                            </FlexRow>
                            <FontBody16>{'Click an element to add it to the canvas'}</FontBody16>
                            <FontBody16>{"Select an element in the layers panel to move or delete the element"}</FontBody16>
                            {(this.props.type === 'course' && (this.props.course.get("id") === null || this.props.pageEditing === 0)) &&
                                <FontBody16 style={{textAlign: 'center'}}><br />{"No elements can be added to this page"}</FontBody16>
                            }
                        </ContainerTop>
                        {(this.props.type === 'course' && (this.props.course.get("id") === null || this.props.pageEditing === 0)) ?
                            <><br /></>
                            :
                            <div style={{textAlign: 'center', paddingBottom: '10px'}}>
                                {this.state.pageType.map((e, i) => {
                                    return (
                                        <Element key={i} onClick={() => {return this.addElement(e)}}>
                                            <ElementContent>
                                                <img src={e.image} style={{maxHeight: '40px', maxWidth: '90%'}}/>
                                                <div style={{paddingTop: '5px'}}><FontBody16>{e.name}</FontBody16></div>
                                            </ElementContent>
                                        </Element>
                                    )
                                })}
                            </div>
                        }
                    </Container>
                </>
            )
        }
    }

}

const mapStateToProps = state => ({
    course: state.enterprise.curriculum.curriculum.get("taskCreate"),
    pageEditing: state.enterprise.curriculum.curriculum.get("pageEditing")
});

const mapDispatchToProps = dispatch => ({
    setCoursesAddElement: (element) => dispatch(setCoursesAddElement(element)),
    setCurrentPageEditing: (pageNum) => dispatch(setCurrentPageEditing(pageNum))
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleElements);