import React from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import validator from "validator";
import {Map, List} from 'immutable';

/* Components */
import { colors } from "../../../components/colors";
import {FontHeader21, FontHeader16, FontTitle18, FontBody16} from "../../../components/fonts";
import {CloseOutlined, DeleteFilled} from '@ant-design/icons';
import {SearchInputUncontrolled} from "../../../components/inputs";
import {InlineBlock, SaveSection, SubmitInputWithValidation} from "../../../../enterprise/components/clientscreate";
import {ButtonTertiary} from "../../../components/buttons";
import {tryGetAllContacts} from "../../../../enterprise/middleware/contacts";

/* Middleware */
import { tryAddUser, tryGetSharedDriveList } from '../middleware/filevault';

/* Store */
import {setDriveAddUserModal, setSharedDrive} from "../store/filevault";

/* Icons */
import {images} from '../../../components/images';

const Modal = styled.div`
    position: relative;
    margin: 0px auto;
    top: 110px;
    z-index: 10;
    background-color: ${colors.white};
    border: 1px solid ${colors.border100};
    border-radius: 6px;
    width: 100%;
    height: auto;
    max-width: 550px;
    @media screen and (max-width: 640px) {
        top: 80px;
        width: calc(100% - 20px);
    }
`;
const Fixed = styled.div`
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 8;
`;
const Background = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9;
`;
const Title = styled.div`
    padding: 25px;
`;
const Inline = styled.div`
    width: calc(100% - 77px);
    display: inline-block;
    vertical-align: top;
`;
const Image = styled.img`
    width: 32px;
    margin-right: 20px;
    vertical-align: top;
    display: inline-block;
`;
const Close = styled.div`
    width: 25px;
    vertical-align: top;
    display: inline-block;
    color: ${colors.secondary100};
    cursor: pointer;
`;
const Form = styled.form`
    padding: 0px 25px 25px 25px;
`;
const Success = styled.div`
    padding: 40px 25px;
    text-align: center;
`;
const Search = styled.div`
    display: inline-block;
    width: calc(100% - 88px);
    vertical-align: top;
    padding: 14px 20px;
`;
const Trash = styled.div`
    display: inline-block;
    font-size: 16px;
    padding: 17px 15px;
    vertical-align: top;
    cursor: pointer;
    color: ${colors.secondary70};
`;
const AddButton = styled.div`
    width: fit-content;
`;
const Green = styled.div`
    color: ${colors.progress100};
`;
const Red = styled.div`
    color: ${colors.alert100};
`;

const whoOptions = (who, isWMC) => {
    return who.map(w => {
        return({
            contactType: (w.get("prospectId") === null && w.get("businessUserId") === null) ? "Contact" : (w.get("prospectStatus") !== null ? w.get("prospectStatus") : "Team Member"),
            value: {
                consumerId: w.get("consumerId"),
                prospectId: w.get("prospectId"),
                contactId: w.get("contactId"),
                businessUserId: w.get("businessUserId")
            },
            label: (w.get("prospectId") === null && w.get("businessUserId") === null) ? w.get("firstName") + " " + w.get("lastName") 
                : 
                ((!isWMC && w.get("referralSource") === "wmc") ? 
                    w.get("firstName") + " " + w.get("lastName").charAt(0) + "."
                    : 
                    w.get("firstName") + " " + w.get("lastName")),
            isArchived: w.get("isArchived")
        })
    }).toJS();
};

class VaultAddUser extends React.Component {
    state={"driveAddUserModal": false, "sharedDrive": Map(), "guests": [], "searchProspects": [], "isValid": true, "guestsShow": true, "isChanged": false, "isSaved": false, "isSaving": false};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.props.tryGetAllContacts();
        await this.setState({"driveAddUserModal": this.props.driveAddUserModal});
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.driveAddUserModal !== this.props.driveAddUserModal && this.props.driveAddUserModal) {
            return this.update();
        }
    }

    update = async () => {
        await this.setState({
            "guests": [],
            "isValid": true,
            "guestsShow": true,
            "isChanged": false,
            "isSaved": false,
            "isSaving": false,
            "driveAddUserModal": this.props.driveAddUserModal,
            "sharedDrive": this.props.sharedDrive,
            "searchProspects": this.props.contactsAll,
        });
    };

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    timeout = 0;

    close = async () => {
        await this.setState({
            "driveAddUserModal": false,
            "sharedDrive": Map(),
            "guests": [],
            "searchProspects": [],
            "isValid": true,
            "guestsShow": true,
            "isChanged": false,
            "isSaved": false,
            "isSaving": false
        });
        await this.props.setDriveAddUserModal(false);
        this.props.setSharedDrive(Map());
        await this.props.tryGetSharedDriveList();
    };

    handleAddGuest = async (e) => {
        let guests = this.state.guests;
        guests.push(e);
        let searchProspectsLessGuest;
        if(e.contactType === "Team Member") {
            searchProspectsLessGuest = this.state.searchProspects.filter(s => {return(s.get("businessUserId") !== e.value.businessUserId)});
        } else {
            searchProspectsLessGuest = this.state.searchProspects.filter(s => {return(s.get("consumerId") !== e.value.consumerId)});
        }
        await this.setState({'guests': guests, 'isChanged': true, 'searchProspects': searchProspectsLessGuest});
    };

    handleDeleteGuest = async (num) => {
        let guests = this.state.guests;
        let searchProspectsAddGuest;
        if(guests[num].contactType === "Team Member") {
            searchProspectsAddGuest = await this.props.contactsAll.filter(s => {return(s.get("businessUserId") === this.state.guests[num].value.businessUserId)}).get(0);
        } else {
            searchProspectsAddGuest = await this.props.contactsAll.filter(s => {return(s.get("consumerId") === this.state.guests[num].value.consumerId)}).get(0);
        }
        await guests.splice(num, 1);
        await this.setState({"guests": guests, "guestsShow": true, 'searchProspects': this.state.searchProspects.push(searchProspectsAddGuest)});
        if (this.state.guests.length === 0) {
            this.setState({"isChanged": false});
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            this.setState({'isSaving': true, 'error': ''});
            let users = this.state.guests.filter(a => {return(a.contactType !== "Contact")}).map(a => {return(a.contactType === "Team Member" ? a.value.businessUserId : a.value.consumerId)});
            await this.props.tryAddUser(this.props.sharedDrive.get("driveId"), users);
            this.setState({'isSaving': false, 'isSaved': true, "isChanged": false});
            this.timeout = setTimeout(this.close, 1000);
        }
    };

    render() {
        if(!this.state.driveAddUserModal) {
            return null
        } else {
            return (
                <Fixed>
                    <Modal>
                        {this.state.isSaved
                            ? <Success><FontTitle18>{"User added!"}</FontTitle18></Success>
                            : <>
                                <Title>
                                    <Image src={images.vaultFolderPrivate} alt={""} />
                                    <Inline><FontHeader21>{"Add users"}</FontHeader21></Inline>
                                    <Close onClick={this.close}><FontHeader16><CloseOutlined /></FontHeader16></Close>
                                </Title>
                                <Form method={"post"} onSubmit={this.handleSubmit}>
                                    <>
                                        <SearchInputUncontrolled
                                            title={"Users"}
                                            options={whoOptions(this.state.searchProspects.filter(
                                                s => {return(s.get("consumerId") !== this.props.userId 
                                                && s.get("businessUserId") !== this.props.userId 
                                                && (s.get("businessUserId") !== null || s.get("consumerId") !== null)
                                                && (this.props.sharedDrive.get("users") === undefined ? true : !this.props.sharedDrive.get("users").some(e => {return(
                                                    e.get("userId") === s.get("consumerId") || e.get("userId") === s.get("businessUserId")
                                                )}))
                                            )}), this.props.isWMC)}
                                            id={'guests'}
                                            onChange={this.handleAddGuest}
                                            placeholder={'Search contacts'}
                                            value={""}
                                        />
                                        {this.state.guests.length > 0 && 
                                            <>
                                                {this.state.guests.map((g, index) => {
                                                    return(
                                                        <div key={index}>
                                                            <Search><FontBody16>{(index+1).toString()+". "+g.label}</FontBody16></Search>
                                                            <Trash onClick={() => this.handleDeleteGuest(index)}><DeleteFilled /></Trash>
                                                        </div>
                                                    )
                                                })}
                                            </>
                                        }
                                    </>
                                    <SaveSection>
                                        <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                        <InlineBlock><div onClick={this.close}><ButtonTertiary canSubmit={false} label={"Cancel"} /></div></InlineBlock>
                                    </SaveSection>
                                </Form>
                            </>
                        }
                    </Modal>
                    {this.state.isSaved && <Background onClick={this.close} />}
                </Fixed>
            )
        }
    }
}

const mapStateToProps = state => ({
    driveAddUserModal: state.common.fileVault.get("driveAddUserModal"),
    sharedDrive: state.common.fileVault.get("sharedDrive"),
    contactsAll: state.enterprise.contacts.get("contactsAll"),
    userId: state.common.user.get("userId"),
    isWMC: state.common.wmc.get("isWMC")
});

const mapDispatchToProps = dispatch => ({
    setDriveAddUserModal: (status) => dispatch(setDriveAddUserModal(status)),
    setSharedDrive: (status) => dispatch(setSharedDrive(status)),
    tryAddUser: (driveId, newUser) => dispatch(tryAddUser(driveId, newUser)),
    tryGetSharedDriveList: () => dispatch(tryGetSharedDriveList()),
    tryGetAllContacts: () => dispatch(tryGetAllContacts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VaultAddUser);