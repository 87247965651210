import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import { Widget } from '@typeform/embed-react';

import { tryLogout } from './middleware/auth';

import Routes from './routes';
import EmailCompose from "../enterprise/email/containers/emailercompose";
import SMSCompose from "../enterprise/sms/containers/smscompose";
import NoteCompose from '../enterprise/notes/containers/notecompose';
import CommentCompose from '../enterprise/comments/containers/commentcompose';
import SupportMembers from '../enterprise/support/containers/flow';
import { NoticeCallWrapper } from "../common/containers/noticemeeting/containers/noticecall";
import { NoticeRateCallWrapper } from "../common/containers/noticerating/containers/noticerating";

class IdleTimerWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.idleTimer = null;
        // this.onAction = this._onAction.bind(this);
        // this.onActive = this._onActive.bind(this);
        this.onIdle = this._onIdle.bind(this);
        this.state = { "showBars": true };
    }

    // _onAction(e) {
    //     console.log('user did something', e);
    // }
    //
    // _onActive(e) {
    //     console.log('user is active', e);
    //     console.log('time remaining', this.idleTimer.getRemainingTime());
    // }
    //
    _onIdle(e) {
        //     console.log('user is idle', e);
        //     console.log('last active', this.idleTimer.getLastActiveTime());
        //     this.props.tryLogout(this.props.history)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            return this.testLocation();
        }
    }

    testLocation = () => {
        const p = this.props.location.pathname;
        if (p.includes('/error') /*|| p.includes('/video-room')*/ || p.includes('/settings/menu') || p.includes('/private') || p.includes('/message-room') || p.includes('/client-rating') || p === '/coach' || p.includes('/package') || p.includes('/thank-you') || p.includes('/rating') || p.includes('/public')) {
            return this.setState({ "showBars": false });
        } else {
            return this.setState({ "showBars": true });
        }
    };


    render() {
        return (
            <>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    // onActive={this.onActive}
                    onIdle={this.onIdle}
                    // onAction={this.onAction}
                    debounce={250}
                    timeout={1000 * 60 * 120}
                />
                <Routes />
                {this.state.showBars &&
                    <>
                        <NoteCompose />
                        <EmailCompose />
                        <SMSCompose />
                        <NoticeCallWrapper />
                        <NoticeRateCallWrapper />
                        <CommentCompose />
                        <SupportMembers />
                    </>
                }
            </>
        )
    }

}

const mapDispatchToProps = dispatch => ({
    tryLogout: (history) => dispatch(tryLogout(history))
});

export default connect(null, mapDispatchToProps)(withRouter(IdleTimerWrapper));
