const expertFAQ = [
    {
        "id": 1,
        "title": "Common Questions",
        "faqs": [
            {
                "id": 1,
                "title": "Are calls with clients audio or video?",
                "body": "<div>Both are available! Although video calls often provide a platform for a more immediate connection, we know that it may not always be possible to be on camera. <br /><br />" +
                    "All calls start as audio and as a coach you can switch the call to video once you and your client connect. Once the video starts you can hang up the phone and start talking! The Willow video capability sits within our platform so you don’t need to open another window (how convenient is that?)</div>"
            },
            {
                "id": 2,
                "title": "What should I do if I feel uncomfortable working with a client referred to me by Willow?",
                "body": "<div>While we hope that won’t happen, know that we’re here to support you. Please contact us at <a data-link-type='external' target='_blank' data-label='coaches@trustwillow.com' href='mailto:coaches@trustwillow.com'>coaches@trustwillow.com</a> if you need assistance.</div>"
            },
            {
                "id": 3,
                "title": "I have new content that I’d like to showcase on the Willow platform. Can I do that?",
                "body": "<div>Sure! We love working with Willow Coaches to share their articles, podcasts and other content. If you have an idea, let us know! Email <a data-link-type='external' target='_blank' data-label='coaches@trustwillow.com' href='mailto:coaches@trustwillow.com'>coaches@trustwillow.com</a>.</div>"
            },
            {
                "id": 5,
                "title": "I need technical support. Who do I contact?",
                "body": "<div>Reach out to <a data-link-type='external' target='_blank' data-label='coaches@trustwillow.com' href='mailto:coaches@trustwillow.com'>coaches@trustwillow.com</a> and we’ll get back to you ASAP (within 24 hours) to help troubleshoot technical issues.</div>"
            },
            {
                "id": 6,
                "title": "Can I change my profile image?",
                "body": "<div>Of course! You can always feel free to update your Profile photo on your Dashboard. We use the information you enter on your Profile page to promote you with prospective clients so we encourage you to show your best self!</div>"
            },
            {
                "id": 7,
                "title": "Why do we ask about life journeys?",
                "body": "<div>Research shows that people tend to engage most with their money when going through life transitions and major moments. While financial challenges can arise for all genders during such moments, we know there are unique challenges experienced by women. That’s why Willow is committed to helping women navigate their life journeys. By learning about the journeys of our coaches and clients, we’re able to connect people that we hope can relate to each other - which fosters loyalty and motivation in coaching engagements!</div>"
            },
            {
                "id": 8,
                "title": "How does the coach to client matching process work?",
                "body": "<div>When joining the Willow platform, each coach completes a matrix of questions to demonstrate their areas of knowledge, expertise and personal interests. As prospective coaching clients start engaging with the Willow website and request a referral to a coach, they are also asked to share information about their background and coaching goals. The Willow proprietary matching process uses the answers from these inquiries to bring together both clients and coaches. Willow leverages technology to build and enhance human relationships!</div>"
            },
            {
                "id": 9,
                "title": "How will I know when I have a new client?",
                "body": "<div>You’ll receive an email from Willow whenever we have a client to refer to you. If your prospect converts to a paying client during your initial consult, encourage them to set up your first appointment while you’re on the phone.</div>"
            },
            {
                "id": 10,
                "title": "My schedule is getting busy. How do I tell Willow about my capacity for new clients?",
                "body": "<div>You can update your availability and the maximum number of clients Willow will refer to you in the Scheduling tab of your Coaching Dashboard.</div>"
            },
            {
                "id": 11,
                "title": "Where do I view the latest pricing for Willow coaches?",
                "body": "<div>Up-to-date pricing information can always be found in the General tab of your Coaching Dashboard.</div>"
            },
            {
                "id": 22,
                "title": "Where can I find more information on coaching packages and pricing?",
                "body": "<div>Detailed information on all coaching packages can be found in the Resources tab in the Coach Dashboard.</div>"
            },
            {
                "id": 23,
                "title": "What are Willow’s coaching expectations?",
                "body": "<div>All coaches are expected to uphold Willow’s core values in all interactions with prospects and values. These values are trust, transparency, empathy and respect. <br /><br />" +
                    "Though we acknowledge that coaching styles vary, all coaches are expected to adhere to the 6 step coaching process. All clients will receive a personalized action-oriented roadmap and the know-how to integrate their plan into their lives using tools, habits and frameworks that will stick.  <br /><br />" +
                    "Willow coaches are also expected to have discussions with women across these critical areas: goals, the strengths/weaknesses/opportunities/threats of their current financial situation, cash flow management, spending plans, cash cushion emergency funds, debt & credit management, savings & wealth building, risk management & protection and accountability. <br /><br />" +
                    "Reference the Willow Coach Playbook for more detail.</div>"
            },
            {
                "id": 13,
                "title": "How does the Willow brand work with my existing brand?",
                "body": "<div>When you become a Financial Coach on the Willow platform, you are amplifying your practice and broadening your reach to help more women with their financial wellness. Your work with Willow is complementary to your existing practice. Plus, as a coach on the Willow platform you get access to all kinds of tools and resources. Willow coaching materials are designed to be simple, intuitive and support 1-1 coaching engagements. All tools and resources are built to reinforce the Willow coaching experience and critical conversation expectations.</div>"
            },
            {
                "id": 12,
                "title": "Does the Willow calendar sync with my Outlook or Google calendar?",
                "body": "<div>Not yet! We’ve heard from coaches and will be adding this feature as soon as we can.</div>"
            }
        ]
    },
    {
        "id": 2,
        "title": "Best Practices",
        "faqs": [
            {
                "id": 14,
                "title": "Do I need to have an LLC to be a Willow Coach?",
                "body": "<div>The majority of Financial Coaches in the Willow network are Limited Liability Companies (LLCs). If you operate your coaching practice as a sole proprietor, we can discuss if Willow is the right platform for you. We know the steps to form an LLC can vary from state to state, but generally set up is straightforward and can provide you an additional layer of business protection!</div>"
            },
            {
                "id": 15,
                "title": "What kind of insurance do I need?",
                "body": "<div>Willow asks that Financial Coaches have a current Errors & Omissions (E&O) insurance policy in place for us to refer business to you. E&O coverage, also called professional liability insurance, protects your business should a customer file a lawsuit for mistakes in omissions in your services. Of course, we hope that will never happen! However, we ask that all coaches upload their current policy to your dashboard.<br /><br />" +
                    "Plus, as a Willow Financial Coaches you may work with clients helping them to minimize risk - shouldn’t we walk the walk too?</div>"
            },
            {
                "id": 16,
                "title": "How do I log notes?",
                "body": "<div>Notes can be written in the Clients section of your Dashboard on the Notes tab so that you conveniently reference them at any time.</div>"
            },
            {
                "id": 17,
                "title": "How do I send follow up emails to clients and prospects after calls?",
                "body": "<div>That’s easy! Willow provides you templates as well as the ability to write your note. Visit the Message tab under the Clients section of your Dashboard. You can also reply directly to messages received via SMS.</div>"
            },
            {
                "id": 24,
                "title": "What happens if a prospect does not show up to the call?",
                "body": "<div>Unfortunately we know this can happen. All prospects are asked to notify coaches if they cannot attend their scheduled session in the email and text reminders leading up to the call. We honor and respect your time as a coach, and encourage you to do the same. In your Dashboard, you can send the templated SMS text “Hanging up soon...” 5 minutes after the session’s start time. If you feel you’ve given your prospect a fair grace period, we know that the next step to move on. Please let us know if we can help.</div>"
            },
            {
                "id": 25,
                "title": "What do I do before an introductory call with a prospect?",
                "body": "<div>First, check out the Willow Coach Playbook to ensure you know all of the tools and resources you have to support you as you engage with your prospect. Prior to your call, review the Willow 1st Call Guide and develop your game plan for before, during and after the call.</div>"
            },
            {
                "id": 26,
                "title": "Where can I find a list of the Willow tools available for coaches?",
                "body": "<div>The Willow Coach Playbook is your guide. View the Playbook to see the resources you have to use in coaching engagements, as well as best practices when using them!</div>"
            }
        ]
    },
    {
        "id": 3,
        "title": "Account & Privacy",
        "faqs": [
            {
                "id": 18,
                "title": "How do I change my password?",
                "body": "<div>You can conveniently update your password at any time on your Dashboard. <a data-link-type='internal' data-label='click here' href='/settings/security'>click here</a>.</div>"
            },
            {
                "id": 19,
                "title": "How does Willow keep my bank account information confidential?",
                "body": "<div>We take data security seriously. While it’s necessary for you to share your bank account information so we can pay you, rest assured this information is immediately deleted. Willow does not collect or store your bank account info! To view our full Privacy Policy <a data-link-type='external' target='_blank' data-label='click here' href='https://www.trustwillow.com/privacy'>click here</a>.</div>"
            },
            {
                "id": 27,
                "title": "How does Willow keep client information confidential?",
                "body": "<div>When using the Willow My Money section of the client dashboard , clients have the opportunity to view all of their personal finances in one spot. It’s a convenient tool to bring various parts of your financial life together! The information kept in the client dashboard is protected with 256-bit encryption, complex passwords, and forced logouts.  We really do take data security seriously. To view our full Privacy Policy <a data-link-type='external' target='_blank' data-label='click here' href='https://www.trustwillow.com/privacy'>click here</a>.</div>"
            },
            {
                "id": 20,
                "title": "Do you record coaching calls?",
                "body": "<div>Yes. Willow records all audio and video calls between coaches and clients. The recordings are not publicly accessible and are used to ensure we’re providing high-quality service to Willow clients. From time to time, Willow may also review recordings to help us improve our platform. To view our full Privacy Policy <a data-link-type='external' data-label='click here' target='_blank'  href='https://www.trustwillow.com/privacy'>click here</a>.</div>"
            },
            {
                "id": 21,
                "title": "How are clients made aware that they are on a recorded line?",
                "body": "<div>At the start of each call there is a recording stating that lines are recorded. Clients must also review and accept the Privacy Policy.</div>"
            }
        ]
    }
];

export default expertFAQ;