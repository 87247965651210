import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import styled from 'styled-components';

/* Components */
import {ReadOnlyInput, TextInput} from "../../../common/components/inputs";
import {SavedSettings} from '../../../common/components/cards';
import {FontBody16, FontTitle18} from '../../../common/components/fonts';
import {ButtonSecondary, ButtonTertiary} from "../../../common/components/buttons";
import {LogoLoading} from "../../../common/components/loading";
import {FitContent, InlineBlock, SaveSection, SubmitInputWithValidation} from "../components/myprofile";
import {EditIcon} from "../components/pagescontact";
import {EditOutlined} from "@ant-design/icons";

/* Middleware */
import {tryUpdateExpertProfile} from '../middleware/expertprofile';

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

class ProfileLinks extends React.Component {
    state = {
        website: '', websiteValid: true, websitePrevious: "",
        blogUrl: '', blogUrlValid: true, blogUrlPrevious: "",
        instagramUrl: '', instagramUrlValid: true, instagramUrlPrevious: "",
        linkedinUrl: '', linkedinUrlValid: true, linkedinUrlPrevious: "",
        facebookUrl: '', facebookUrlValid: true, facebookUrlPrevious: "",
        twitterUrl: '', twitterUrlValid: true, twitterUrlPrevious: "",
        youtubeUrl: '', youtubeUrlValid: true, youtubeUrlPrevious: "",
        isSaving: false,
        isSaved: false,
        error: '',
        isValid: true,
        isChanged: false,
        isLoading: true,
        isEdit: false
    };

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    init = async () => {
        await this.setState({
            "website": this.props.profile.get("website"),
            "blogUrl": this.props.profile.get("blogUrl"),
            "instagramUrl": this.props.profile.get("instagramUrl"),
            "linkedinUrl": this.props.profile.get("linkedinUrl"),
            "facebookUrl": this.props.profile.get("facebookUrl"),
            "twitterUrl": this.props.profile.get("twitterUrl"),
            "youtubeUrl": this.props.profile.get("youtubeUrl"),
            "websitePrevious": this.props.profile.get("website"),
            "linkedinUrlPrevious": this.props.profile.get("linkedinUrl"),
            "facebookUrlPrevious": this.props.profile.get("facebookUrl"),
            "twitterUrlPrevious": this.props.profile.get("twitterUrl"),
            "youtubeUrlPrevious": this.props.profile.get("youtubeUrl"),
            "isLoading": false
        });
    };

    handleChangeWebsite = async e => {
        await this.setState({'website': e.target.value, 'websiteValid': (validator.isURL(e.target.value) || e.target.value === ""), 'isChanged': true});
        this.checkValid();
    };

    handleChangeBlogUrl = async e => {
        await this.setState({'blogUrl': e.target.value, 'blogUrlValid': (validator.isURL(e.target.value) || e.target.value === ""), 'isChanged': true});
        this.checkValid();
    };

    handleChangeInstagramUrl = async e => {
        await this.setState({'instagramUrl': e.target.value, 'instagramUrlValid': (validator.isURL(e.target.value) || e.target.value === ""), 'isChanged': true});
        this.checkValid();
    };

    handleChangeLinkedinUrl = async e => {
        await this.setState({'linkedinUrl': e.target.value, 'linkedinUrlValid': (validator.isURL(e.target.value) || e.target.value === ""), 'isChanged': true});
        this.checkValid();
    };

    handleChangeFacebookUrl = async e => {
        await this.setState({'facebookUrl': e.target.value, 'facebookUrlValid': (validator.isURL(e.target.value) || e.target.value === ""), 'isChanged': true});
        this.checkValid();
    };

    handleChangeTwitterUrl = async e => {
        await this.setState({'twitterUrl': e.target.value, 'twitterUrlValid': (validator.isURL(e.target.value) || e.target.value === ""), 'isChanged': true});
        this.checkValid();
    };

    handleChangeYoutubeUrl = async e => {
        await this.setState({'youtubeUrl': e.target.value, 'youtubeUrlValid': (validator.isURL(e.target.value) || e.target.value === ""), 'isChanged': true});
        this.checkValid();
    };

    checkValid = async () => {
        if(this.state.websiteValid && this.state.instagramUrlValid && this.state.blogUrlValid && this.state.linkedinUrlValid && this.state.facebookUrlValid && this.state.twitterUrlValid && this.state.youtubeUrlValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    edit = async (status) => {
        this.setState({"isEdit": status});
    };

    reset = async () => {
        this.setState({
            "website": this.state.websitePrevious,
            "instagramUrl": this.state.instagramUrlPrevious,
            "blogUrl": this.state.blogUrlPrevious,
            "linkedinUrl": this.state.linkedinUrlPrevious,
            "facebookUrl": this.state.facebookUrlPrevious,
            "twitterUrl": this.state.twitterUrlPrevious,
            "youtubeUrl": this.state.youtubeUrlPrevious,
            "isChanged": false,
            "isEdit": false
        });
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            await this.setState({'isSaving': true, "error": ""});
            const links = {
                "website": this.state.website,
                "blogUrl": this.state.blogUrl,
                "instagramUrl": this.state.instagramUrl,
                "linkedinUrl": this.state.linkedinUrl,
                "facebookUrl": this.state.facebookUrl,
                "twitterUrl": this.state.twitterUrl,
                "youtubeUrl": this.state.youtubeUrl
            };
            await this.props.tryUpdateExpertProfile(links, "profileLinksError");
            if(this.props.profile.get("profileLinksError") === "") {
                this.setState({
                    'isSaving': false,
                    "isSaved": true,
                    "websitePrevious": this.state.website,
                    "blogUrlPrevious": this.state.blogUrl,
                    "instagramUrlPrevious": this.state.instagramUrl,
                    "linkedinUrlPrevious": this.state.linkedinUrl,
                    "facebookUrlPrevious": this.state.facebookUrl,
                    "twitterUrlPrevious": this.state.twitterUrl,
                    "youtubeUrl": this.state.youtubeUrl,
                    "isEdit": false
                });
                this.timeout = setTimeout(() => {this.setState({'isSaved': false, "isChanged": false})}, 3000);
            } else {
                this.setState({'isSaved': true, 'isSaving': false, "error": this.props.profile.get("profileLinksError")});
                this.timeout = setTimeout(() => {this.setState({'isSaved': false, "error": ""})}, 3000);
            }
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    {this.state.isSaved
                        ?
                            <>
                                {this.state.error === '' && <SavedSettings height={"99px"} error={false} message={"Saved"}/>}
                                {this.state.error !== '' && <SavedSettings height={"99px"} error={true} message={this.state.error}/>}
                            </>
                        :
                            <>
                                {!this.state.isEdit &&
                                    <>
                                        <FontTitle18>Links</FontTitle18>
                                        <ReadOnlyInput
                                            title={"Website URL"}
                                            value={this.state.website}
                                        />
                                        <ReadOnlyInput
                                            title={"Blog URL"}
                                            value={this.state.blogUrl}
                                        />
                                        <ReadOnlyInput
                                            title={"Instagram URL"}
                                            value={this.state.instagramUrl}
                                        />
                                        <ReadOnlyInput
                                            title={"Facebook URL"}
                                            value={this.state.facebookUrl}
                                        />
                                        <ReadOnlyInput
                                            title={"Twitter URL"}
                                            value={this.state.twitterUrl}
                                        />
                                        <ReadOnlyInput
                                            title={"LinkedIn URL"}
                                            value={this.state.linkedinUrl}
                                        />
                                        <ReadOnlyInput
                                            title={"YouTube URL"}
                                            value={this.state.youtubeUrl}
                                        />
                                        <br/>
                                        <FitContent><FontBody16 onClick={() => {return this.edit(true)}}><ButtonSecondary canSubmit={false} label={<><EditOutlined/>&nbsp;Change Links</>} /></FontBody16></FitContent>
                                    </>
                                }
                                {this.state.isEdit &&
                                    <form method={"post"} onSubmit={this.handleSubmit}>
                                        <div>
                                            <FontTitle18>Links</FontTitle18>
                                            <TextInput
                                                title={"Website URL"}
                                                valid={this.state.websiteValid}
                                                warning={""}
                                                id={"website"}
                                                onChange={this.handleChangeWebsite}
                                                placeholder={"https://..."}
                                                value={this.state.website}
                                            />
                                            <TextInput
                                                title={"Blog URL"}
                                                valid={this.state.blogUrlValid}
                                                warning={""}
                                                id={"blogUrl"}
                                                onChange={this.handleChangeBlogUrl}
                                                placeholder={"https://..."}
                                                value={this.state.blogUrl}
                                            />
                                            <TextInput
                                                title={"Instagram URL"}
                                                valid={this.state.instagramUrlValid}
                                                warning={""}
                                                id={"instagramUrl"}
                                                onChange={this.handleChangeInstagramUrl}
                                                placeholder={"https://instagram.com/..."}
                                                value={this.state.instagramUrl}
                                            />
                                            <TextInput
                                                title={"Facebook URL"}
                                                valid={this.state.facebookUrlValid}
                                                warning={""}
                                                id={"facebookUrl"}
                                                onChange={this.handleChangeFacebookUrl}
                                                placeholder={"https://facebook.com/..."}
                                                value={this.state.facebookUrl}
                                            />
                                            <TextInput
                                                title={"Twitter URL"}
                                                valid={this.state.twitterUrlValid}
                                                warning={""}
                                                id={"twitterUrl"}
                                                onChange={this.handleChangeTwitterUrl}
                                                placeholder={"https://twitter.com/..."}
                                                value={this.state.twitterUrl}
                                            />
                                            <TextInput
                                                title={"LinkedIn URL"}
                                                valid={this.state.linkedinUrlValid}
                                                warning={""}
                                                id={"linkedinUrl"}
                                                onChange={this.handleChangeLinkedinUrl}
                                                placeholder={"https://linkedin.com/..."}
                                                value={this.state.linkedinUrl}
                                            />
                                            <TextInput
                                                title={"YouTube URL"}
                                                valid={this.state.youtubeUrlValid}
                                                warning={""}
                                                id={"youtubeUrl"}
                                                onChange={this.handleChangeYoutubeUrl}
                                                placeholder={"https://youtu.be/..."}
                                                value={this.state.youtubeUrl}
                                            />
                                        </div>
                                        <SaveSection>
                                            <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                            <InlineBlock><div onClick={this.reset}><ButtonTertiary canSubmit={false} label={"Cancel"} /></div></InlineBlock>
                                        </SaveSection>
                                    </form>
                                }
                            </>

                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    profile: state.enterprise.shop.expertProfile.get("expertProfile")
});

const mapDispatchToProps = dispatch => ({
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileLinks);
