import { Map, fromJS } from 'immutable';

/* Immutable */

const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
const CHANGE_PASSWORD_UPDATE = 'CHANGE_PASSWORD_UPDATE';
const CHANGE_PASSWORD_STORE_RESET = 'CHANGE_PASSWORD_STORE_RESET';

/* Actions */

export const setChangePassword = (password) => ({'type': CHANGE_PASSWORD, 'password': password});
export const setChangePasswordUpdating = (status) => ({'type': CHANGE_PASSWORD_UPDATE, 'status': status});
export const setChangePasswordStoreReset = () => ({'type': CHANGE_PASSWORD_STORE_RESET});

/* Initial State */

const initialState = Map({
    error: Map({error: ''}),
    updating: false
});

/* Reducer */

const changePasswordReducer = (state=initialState, action) => {
    switch (action.type) {
        case CHANGE_PASSWORD:
            return state.merge({'error': action.password});
        case CHANGE_PASSWORD_UPDATE:
            return state.merge({'updating': action.status});
        case CHANGE_PASSWORD_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default changePasswordReducer;
