import React from "react";
import ReactQuill from "react-quill";
import {v4 as uuid4} from "uuid";
import moment from "moment";
import {connect} from "react-redux";

/* Components */
import {MailFilled} from "@ant-design/icons";
import {FontBody12, FontHeader16, FontTitle18} from "../../common/components/fonts";
import {QuillSlim} from "../../common/components/richtexteditor";
import {colors} from "../../common/components/colors";
import {Card, Icon, Body, Title, Action, ActionLink, Content, DateItem, Inline, MarginBottom15, BottomBorder5} from '../components/contactactivity';
import {Attendees} from '../components/contactactivity';
import {LogoLoading} from "../../common/components/loading";

/* Store */
import {setMessagesEmailOpen} from "../email/store/messages";

class Email extends React.Component {
    state={isLoading: true, data: {}, quill: ''};

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.data !== this.props.data) {
            return this.init();
        }
    }

    init = async () => {
        await this.setState({
            "data": this.props.data,
            'quill' : <ReactQuill
                id={uuid4()}
                readOnly={true}
                theme={null}
                value={this.props.data.get("content")}
                bounds={'.app'}
            />
        });
        return this.setState({"isLoading": false});
    };

    createEmail = async () => {
        // Set user to have message to based on thread id
        const consumerId = this.props.client.get("consumerId");
        const firstName = this.props.client.get("firstName");
        const lastName = (this.props.client.get("referralSource") === "willow" || (!this.props.isWMC && this.props.client.get("referralSource") === "wmc")) ? (this.props.client.get("lastName").charAt(0).toUpperCase()+".") : this.props.client.get("lastName");
        const subject = this.state.data.get("subject");
        const content = this.state.data.get("content");
        this.props.setMessagesEmailOpen(true, consumerId, "consumer", firstName, lastName, true, subject, content);
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <Card>
                    <Icon><MailFilled /></Icon>
                    <Body>
                        <div>
                            <Title><FontTitle18>Email</FontTitle18></Title>
                            <Action>
                                {!this.props.isWMC && <Inline><ActionLink onClick={this.createEmail}><FontHeader16>Reply</FontHeader16></ActionLink></Inline>}
                                <DateItem><FontBody12>{moment(this.state.data.get("formattedDatetime")).format("MMM D, YYYY [at] h:mm a")}</FontBody12></DateItem>
                            </Action>
                        </div>
                        <Content>
                            <MarginBottom15>
                                <FontHeader16>{this.state.data.get("subject")}</FontHeader16>
                            </MarginBottom15>
                            {this.state.data.get("isFromLily") &&
                                <Attendees
                                    senderName={this.props.client.get("firstName")+" "+
                                    (!this.props.isWMC && this.props.client.get("referralSource") === "wmc" ? this.props.client.get("lastName").charAt(0)+"." : this.props.client.get("lastName"))}
                                    recipientName={this.props.user.get("first")+" "+this.props.user.get("last")}
                                />
                            }
                            {this.state.data.get("isFromAdvisor") &&
                                <Attendees
                                    senderName={this.props.user.get("first")+" "+this.props.user.get("last")}
                                    recipientName={this.props.client.get("firstName")+" "+
                                    (!this.props.isWMC && this.props.client.get("referralSource") === "wmc" ? this.props.client.get("lastName").charAt(0)+"." : this.props.client.get("lastName"))}
                                />
                            }
                            <BottomBorder5 />
                            <QuillSlim bodyBorderColor={colors.white}>{this.state.quill}</QuillSlim>
                        </Content>
                    </Body>
                </Card>
            )
        }
    }
}

const mapStateToProps = state => ({
    client: state.enterprise.clientsDetail.get("client"),
    user: state.common.user,
    isWMC: state.common.wmc.get("isWMC"),
});

const mapDispatchToProps = dispatch => ({
    setMessagesEmailOpen: (open, recipientId, recipientType, recipientFirstName, recipientLastName, isReply, subject, content) => dispatch(setMessagesEmailOpen(open, recipientId, recipientType, recipientFirstName, recipientLastName, isReply, subject, content)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Email);
