import { axiosReq } from "../../../common/utils/axios";

// Store
import { setMySubscribedGroupPrograms} from '../store/programs'

// Static
import { devMyRegisteredPrograms, devMyRegisteredProgram } from "../static/devdata";
import {devActiveGroupPrograms} from "../../../iframes/programs/static/devdata";

export const tryGetMySubscribedGroupPrograms = () => {
    return async dispatch => {
        const mySubscribedGroupPrograms = await axiosReq('apiv1/consumer/group-program/subscriptions', "GET", {}, devMyRegisteredPrograms)
        await dispatch(setMySubscribedGroupPrograms(mySubscribedGroupPrograms));
        return mySubscribedGroupPrograms
    }
};

export const tryGetMySubscribedGroupProgram = (subscriptionId) => {
    return async dispatch => {
        return axiosReq('apiv1/consumer/group-program/subscriptions/'+subscriptionId, "GET", {}, devMyRegisteredProgram)
    }
};

export const tryGetAllPrograms = () => {
    return async dispatch => {
        return axiosReq('apiv1/consumer/group-program/catalogue', "GET", {}, devActiveGroupPrograms)
    }
}