import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Map } from 'immutable';
import styled from 'styled-components';

/* Components */
import { ActionBox, InlineItemLeft, ActionItem, ActionItems, ActionIcon } from "../components/clientsdetail";
import { FontTitle18, FontBody12, FontHeader16 } from '../../common/components/fonts';
import { CalendarFilled, PhoneFilled, MailFilled, MobileFilled, FileTextFilled, VideoCameraFilled } from '@ant-design/icons';
import { LogoLoadingSmall } from "../../common/components/loading";
import {ReadOnlyValueInput} from "../../common/components/inputs";
import {colors} from "../../common/components/colors";

/* Middleware */
import {tryGetPurchaseHistory} from "../middleware/coachingproducts";

/* Store */
import {tryGetOneContact} from "../middleware/contacts";

const CardOrNot = styled.div`
    
    -webkit-box-shadow: none;
    box-shadow: none;
    ${props => props.top !== true} {

    }
    ${props => props.top === true} {
        border-top: 1px solid ${colors.primary30}; 
        margin-top: 30px;
    }
    padding: 30px 0px 0px 0px;
    width: 100%;
`;

const Center = styled.div`
    text-align: center;
`;

class CoachingProducts extends React.Component {
    state = {"isLoading": true, "contact": Map(), "contactsOne": Map(), "hasPurchases": false, "purchases": Map()};

    componentDidMount() {
        return this.refresh();
    }

    refresh = async () => {
        const purchases = await this.props.tryGetPurchaseHistory(this.props.contact.get("prospectId"));
        if (purchases.length !== 0) {
            await this.setState({"hasPurchases": true, "purchases": purchases})
        }
        const contactId = this.props.contact.get("contactId");
        await this.props.tryGetOneContact(contactId, "store");
        this.setState({"contact": this.props.contact, "contactsOne": this.props.contactsOne, "isLoading": false})
    };

    render() {
        if(this.state.isLoading) {
            return null
        } else if (this.state.hasPurchases) {
            return(
                <ActionBox>
                        <div>
                            <InlineItemLeft><FontTitle18>Products</FontTitle18></InlineItemLeft>
                        </div>
                        {this.state.purchases.map((p, i) => {
                            if (p.downloadUrl !== "") {
                                return(
                                    <CardOrNot top={i === 0}>
                                        {p.coverPhotoProduct !== null && <img width={"200"} height={"200"} src={p.coverPhotoProduct}/>}
                                        <Center><FontHeader16>{p.productTitle}</FontHeader16></Center>
                                        <ReadOnlyValueInput title={"Download Url"} value={p.downloadUrl} />
                                    </CardOrNot>
                                )
                            } else if (p.sessionLengthInMinutes !== 0 && p.numberOfSessions !== 0) {
                                return(
                                    <CardOrNot top={i === 0}>
                                        {p.coverPhotoProduct !== null &&  <img width={"200"} height={"200"} src={p.coverPhotoProduct}/>}
                                        <Center><FontHeader16>{p.productTitle}</FontHeader16></Center>
                                        <ReadOnlyValueInput title={"Session Length"} value={p.sessionLengthInMinutes + "-minutes"} />
                                        <ReadOnlyValueInput title={"Number of Sessions"} value={p.numberOfSessions} />
                                    </CardOrNot>
                                )
                            } else {
                                return(
                                    <CardOrNot top={i === 0}>
                                        {p.coverPhotoProduct !== null &&  <img width={"200"} height={"200"} src={p.coverPhotoProduct}/>}
                                        <Center><FontHeader16>{p.productTitle}</FontHeader16></Center>
                                        <ReadOnlyValueInput title={"Product Type"} value={"Offline"} />
                                    </CardOrNot>
                                )
                            }
                        })}
                    </ActionBox>
            )
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => ({
    contact: state.enterprise.clientsDetail.get("client"),
    contactsOne: state.enterprise.contacts.get("contactsOne"),
    isWMC: state.common.wmc.get("isWMC")
});
const mapDispatchToProps = dispatch => ({
    tryGetOneContact: (contactId, storeOrReturn) => dispatch(tryGetOneContact(contactId, storeOrReturn)),
    tryGetPurchaseHistory: (prospectId) => dispatch(tryGetPurchaseHistory(prospectId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CoachingProducts));
