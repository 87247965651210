import React from 'react';

/* Containers */
import MeetingRecord from '../meetings/containers/meeting';

export class ContactActivityMeeting extends React.Component {
    render() {
        return(
            <MeetingRecord
                data={this.props.data}
                forceUpdate={this.props.forceUpdate}
                onRescheduleSession={this.props.init}
                onCancelSession={this.props.init}
            />
        )
    }
}

export default ContactActivityMeeting;

