import React from 'react'
import styled from 'styled-components';

/* Components */
import InlineInput from 'react-trello/dist/widgets/InlineInput';
import LaneMenu from 'react-trello/dist/components/Lane/LaneHeader/LaneMenu';
import {colors} from '../../common/components/colors';
import {FontBody14} from '../../common/components/fonts';

const LaneHeader2 = styled.div`
    padding: 15px;
    border-bottom: 1px solid ${colors.background30};
    background-color: ${colors.background15};
    font-family: GraphicWebRegular;
    font-size: 16px;
    & + div {
        margin-top: 0px;
    }
`;
const TitleSection = styled.div`
    display: block;
    width: 100%;
    margin-bottom: 5px;
`;
const Title2 = styled.div`
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    cursor: auto;
    width: calc(100% - 35px);
    display: inline-block;
`;
const RightContent2 = styled.div`   
    width: 30px;
    display: inline-block;
    text-align: right;
    padding-right: 5px;
    font-size: 13px;
`;

class LaneHeaderComponent extends React.Component {
    state={
        updateTitle: () => {},
        editLaneTitle: false,
        canAddLanes: false
    };

    componentDidMount() {
        this.setState({
            updateTitle: this.props.updateTitle,
            editLaneTitle: this.props.editLaneTitle,
            canAddLanes: this.props.canAddLanes,
            laneDraggable: this.props.laneDraggable,
            label: this.props.label,
            title: this.props.title,
            onDelete: this.props.onDelete,
            onDoubleClick: this.props.onDoubleClick,
            t: this.props.t,
            extraTitle: this.props.extra.title,
            extraRevenue: this.props.extra.revenue
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.label !== this.props.label) {
            this.setState({"label": this.props.label})
        }
        if(prevProps.extra.revenue !== this.props.extra.revenue) {
            this.setState({"extraRevenue": this.props.extra.revenue})
        }
    }

    render() {
        return(
            <LaneHeader2 onDoubleClick={this.state.onDoubleClick} editLaneTitle={this.state.editLaneTitle}>
                <TitleSection>
                    <Title2 draggable={this.state.laneDraggable} style={this.state.titleStyle}>
                        {this.state.editLaneTitle ?
                            <InlineInput value={this.state.title} border placeholder={this.state.t('placeholder.title')} resize='vertical' onSave={this.state.updateTitle} /> :
                            this.state.extraTitle
                        }
                    </Title2>
                    {this.state.label && (
                        <RightContent2>
                            <span style={this.state.labelStyle}>{this.state.label}</span>
                        </RightContent2>
                    )}
                </TitleSection>
                <FontBody14>{this.state.extraRevenue}</FontBody14>
                {this.state.canAddLanes && <LaneMenu t={this.state.t} onDelete={this.state.onDelete}/>}
            </LaneHeader2>
        )
    }
}

export default LaneHeaderComponent;