import React from 'react';
import {connect} from 'react-redux';

/* Components */
import {FontTitle21, FontBody16, FontTitle18} from "../../../common/components/fonts";
import {Page, ModalDelete, Title, Actions, Center, ActionLeft, ActionRight, Background, ActionCancelPlan} from '../components/cancel';
import {ButtonPrimary, ButtonTertiary} from "../../../common/components/buttons";

/* Middleware */
import {tryDeleteBillingPaymentMethod, tryGetBillingPaymentMethod} from "../middleware/billing";

class DeleteModal extends React.Component {
    state={"isSaved": false, "isSaving": false};

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.id !== this.props.id) {
            return this.init()
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    init = async () => {
        this.setState({"isSaved": false, "isSaving": false})
    };

    timeout = 0;

    close = async () => {
        await this.props.tryGetBillingPaymentMethod();
        this.props.showDelete(false, "")
    };

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({'isSaving': true});
        console.log(this.state)
        this.props.tryDeleteBillingPaymentMethod(this.props.id);
        this.setState({'isSaving': false, 'isSaved': true});
        this.timeout = setTimeout(this.close, 1000);
    };

    render() {
        return (
            <Page>
                <ModalDelete>
                    {this.state.isSaved
                        ? <Center><FontTitle18>Your card has been removed</FontTitle18></Center>
                    :
                    <>
                        <Title><FontTitle21>Are you sure?</FontTitle21></Title>
                        <FontBody16>Do you really want to remove this payment method? This cannot be undone.</FontBody16>
                        <Actions>
                            <ActionLeft onClick={() => {this.props.showDelete(false, "")}}>
                                <ButtonTertiary submit={false} label={"Back"} />
                            </ActionLeft>
                            <ActionRight>
                                <ActionCancelPlan onClick={this.handleSubmit}>
                                    <ButtonPrimary submit={false} label={"Delete Card"}/>
                                </ActionCancelPlan>
                            </ActionRight>
                        </Actions>
                    </>
                    }
                </ModalDelete>
                {this.state.isSaved ? 
                    <Background onClick={this.close}/>
                :
                    <Background />
                }
            </Page>
        )
    }
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => ({
    tryDeleteBillingPaymentMethod: (id) => dispatch(tryDeleteBillingPaymentMethod(id)),
    tryGetBillingPaymentMethod: () => dispatch(tryGetBillingPaymentMethod())
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);