import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

/* Components */
import {colors} from "../../../common/components/colors";
import {FontHeader16} from "../../../common/components/fonts";

/* Store */
import {setPlatformTo} from '../../../routes/store/user';

const Center = styled.div`
    background-color: ${colors.primary100};
    color: ${colors.backgroundColor4};
    border-radius: 30px;
    padding: 15px;
    width: calc(100% - 60px);
    margin: 0px auto;
    cursor: pointer;
`;
const SettingsCard = styled.div`
    text-align: center;
    max-width: 450px;
`;

class SettingsSecurity extends React.Component {

    openCoachDashboard = async () => {
        console.log("click");
        await this.props.setPlatformTo("enterprise");
        this.props.history.push("/verify");
    };

    render() {
        return(
            <SettingsCard>
                <Center onClick={() => {return this.openCoachDashboard()}}>
                    <FontHeader16>Switch to Business Account</FontHeader16>
                </Center>
            </SettingsCard>
        )
    }

}

const mapDispatchToProps = dispatch => ({
    setPlatformTo: (platform) => dispatch(setPlatformTo(platform))
});

export default connect(null, mapDispatchToProps)(withRouter(SettingsSecurity));
