import {images} from "../../../common/components/images";

export const devProgramData = {
    "id": 1,
    "first": "Jason",
    "last": "Consumer",
    "email": "jason.aronson@gmail.com",
    "phone": 1231231234,
    "coach_id": 12,
    "coach_first": "Gabby",
    "coach_last": "Coach",
    "coachPhotoUrl": images.devDataTeamJasonAvatar
};

export const devProgramCheckoutData = {
    "stripeCheckoutSessionId": "cs_test_8oD0BpoaCJXuBXAmpmEw5gnDEurgb0kiAuFtV1t8hlgavioGVkhKYJDO",
    "stripeApiPublishableKey": "pk_test_DTBcykoWC8hwcNHGNBoBdoVD",
    "priceId": "price_1GvmnCBPDoWM9BMeC56nmCHI",
    "productCost": 27500
};

export const devProgramReceiptData = {
    "id": 1,
    "first": "Jason",
    "last": "Consumer",
    "email": "jason.aronson@gmail.com",
    "phone": 1231231234,
    "coach_id": 12,
    "coach_first": "Gabby",
    "coach_last": "Coach",
    "coachPhotoUrl": images.devDataTeamJasonAvatar,
    "priceId": "price_1GvmnCBPDoWM9BMeC56nmCHI",
    "productCost": 27500
};