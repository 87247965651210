import React from 'react';
import {connect} from "react-redux";
import moment from "moment";

/* Components */
import {FontBody16, FontBody14, FontTitle18} from "../../../../common/components/fonts";
import {ButtonSecondary} from "../../../../common/components/buttons";
import {Success, FitContent} from '../components/forcesync';

/* Middleware */
import { tryPostRecurringAvailabilitySync } from "../middleware/recurring";
import {LogoLoading} from "../../../../common/components/loading";

class ForceSync extends React.Component {
    state = {"isLoading": true, "time": "Re-sync complete!"};

    intervalID = 0;

    componentDidMount() {
        this.intervalID = setTimeout(() => {this.testTime()}, 1000 * 60 * .5);
        this.setState({"isLoading": false});
    }

    testTime = () => {
        if(moment().diff(this.props.availabilitySync, 'seconds') < 15) {
            this.setState({"time": "Re-sync complete!"});
        } else if(moment().diff(this.props.availabilitySync, 'seconds') >= 15 && moment().diff(this.props.availabilitySync, 'seconds') < 60) {
            this.setState({"time": "Updated less than 1 minute ago"});
        } else if(moment().diff(this.props.availabilitySync, 'seconds') >= 60 && moment().diff(this.props.availabilitySync, 'minutes') < 2) {
            this.setState({"time": "Updated 1 minute ago"});
        } else if(moment().diff(this.props.availabilitySync, 'minutes') > 2) {
            this.setState({"time": "Updated "+moment().diff(this.props.availabilitySync, 'minutes')+" minutes ago"});
        }
    };

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading/>)
        } else {
            return(
                <>
                    <br/>
                    <br/>
                    <FontTitle18>Force Sync</FontTitle18>
                    <br/>
                    <FontBody14>
                        See something wrong with your calendar? This may be fixed by re-syncing. 
                        Please note that we update calendar data once a day.
                    </FontBody14>
                    <br/>
                    {(this.props.availabilitySync === null || this.props.availabilitySync.isBefore(moment().subtract(30, 'minutes')))
                        ? <FitContent onClick={() => {this.props.tryPostRecurringAvailabilitySync()}}><ButtonSecondary canSubmit={false} label={"Re-sync"}/></FitContent>
                        : <Success><FontBody16>{this.state.time}</FontBody16></Success>
                    }
                    <br/>
                    <br/>
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    availabilitySync: state.enterprise.recurring.get("recurringAvailabilitySync")
});

const mapDispatchToProps = dispatch => ({
    tryPostRecurringAvailabilitySync: () => dispatch(tryPostRecurringAvailabilitySync())
});

export default connect(mapStateToProps, mapDispatchToProps)(ForceSync);
