import React from 'react';
import {connect} from 'react-redux';
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

/* Components */
import { FontTitle24, FontSubtitle28, FontBody18, FontBody16, FontHeader16, FontHeader18 } from '../../../common/components/fonts';
import { RightOutlined }  from "@ant-design/icons";
import { colors } from '../../../common/components/colors';

/* Utility */
import { hexToRGB, brightness } from '../../../common/utils/colors';

const FlexColumn30 = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 30px;
    text-align: center;
    width: 100%;
`;
const FlexItem = styled.div`
    align-items: center;
    background-color: ${props => props.backgroundColor};
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    color: ${props => brightness(hexToRGB(props.backgroundColor, false)) < 130 ? colors.white : colors.primary100};
    display: flex;
    flex-direction: row;
    cursor: pointer;
    width: 400px;
    @media screen and (max-width: 640px) {
        width: 100%;
    }
    @media screen and (min-width: 640px) {
        height: 200px;
    }
    & div {
        width: 60%;
    }
`;
const ImageOuter = styled.div`
    width: 115px;
    display: block;
    @media screen and (max-width: 640px) {
        width: 100%;
    }
`;
const Image2 = styled.img`
    width: 100%;
    object-fit: cover;
    object-position: 0 0;
    max-height: 200px;
    @media screen and (max-width: 640px) {
        max-width: 150px;
    }
`
const Image = styled.img`
    height: 100%;
    width: 40%;
    vertical-align: top;
    display: inline-block;
    object-fit: cover;
    border-radius: 25px 0 0 25px;
    @media screen and (max-width: 425px) {
        height: 150px;
    }
`;

class FinancialProfile extends React.Component {

    // type: 'spending', 'snapshot', or 'saving'
    selectMoney = (type) => {
        this.props.history.push(`/my-financial-profile/${type}`)
        // Add to back navigation stack
    }

    MyMoneyItems = [
        {
            'title': 'Monthly spending plan', 
            'imageSrc': 'https://res.cloudinary.com/blz-lilyriche/image/upload/v1636550852/g6pf5c9kgvykhih9q6db.jpg',
            'type': 'spending',
            'backgroundColor': colors.backgroundColor4,
        }, 
        {
            'title': 'Money snapshot', 
            'imageSrc': 'https://res.cloudinary.com/blz-lilyriche/image/upload/v1636550878/xhtzpftxzgra7tmssv7v.jpg',
            'type': 'snapshot',
            'backgroundColor': colors.primary100,
        }, 
        {
            'title': 'Goals', 
            'imageSrc': 'https://res.cloudinary.com/blz-lilyriche/image/upload/v1636551020/he2iftpuq0jpyc1tnog2.jpg',
            'type': 'saving',
            'backgroundColor': colors.backgroundColor3,
        }, 
    ];

    render() {
        return (
            <FlexColumn30>
                <FontSubtitle28 spaced={true}>{'My financial profile'.toUpperCase()}</FontSubtitle28>
                {/*<FontBody16>{'Choose a topic to book a video call or message an expert'}</FontBody16>*/}
                <FlexColumn30>
                    {this.MyMoneyItems.map((i, idx) => (
                        <FlexItem key={idx} onClick={() => this.selectMoney(i.type)} backgroundColor={i.backgroundColor}>
                            <Image src={i.imageSrc} alt={i.title}/>
                            <FontHeader18>{i.title}</FontHeader18>
                        </FlexItem>
                    ))}
                </FlexColumn30>
            </FlexColumn30>
        )
    }
}

export default withRouter(FinancialProfile);