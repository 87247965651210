import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from "styled-components";

/* Navs */
import {PageContentContainer, PageContent} from "../../navbars/components/navlayouts";

/* Middleware */
import {tryGetAllTasks, tryGetOneTask} from "../middleware/tasks";

/* Containers */
import FormsFlow from "../../../../iframes/forms/containers/flow";
import Success from "../../../../iframes/success/containers/success";

/* Components */
import {LogoLoading} from "../../../../common/components/loading";

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

class FirmTasks extends React.Component {
    state = {"isLoading": true, "myTask": null}

    componentDidMount() {
        this.init();
    }

    init = async () => {
        const myTasks = await this.props.tryGetAllTasks();
        const path = window.location.pathname.split("/");
        const taskId = path[path.length - 1];
        const isAssignedTask = await myTasks.filter(t => {
            return(t.courseAssignmentId.toString() === taskId.toString())
        })
        if(isAssignedTask.length > 0) {
            this.setState({
                "isCompleted": isAssignedTask.isCompleted,
                "myTask": await this.props.tryGetOneTask(isAssignedTask[0].course.id),
                "isLoading": false
            })
        } else {
            return this.toDashboard()
        }
    }

    onReturnForm = async (form) => {
        console.log("done", form)
        this.setState({"isCompleted": true});
    }

    toDashboard = async () => {
        return this.props.history.push("/dashboard");
    }

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if(this.state.isCompleted) {
                return(
                    <Success
                        title={"TASK COMPLETED!"}
                        subtitle={"You have completed this task"}
                        back={this.toDashboard}
                        buttonLabel={"Dashboard"}
                    />
                )
            } else {
                return(
                    <PageContentContainer fullPage={true}>
                        <PageContent>
                            <FormsFlow
                                form={this.state.myTask.tasks[0].task.pages}
                                skipAuth={true}
                                onCompleteFormMethod={"return"}
                                onReturnForm={this.onReturnForm}
                                formHasOnePage={true}
                                confirmOnClose={this.toDashboard}
                            />
                        </PageContent>
                    </PageContentContainer>
                );
            }
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryGetAllTasks: () => dispatch(tryGetAllTasks()),
    tryGetOneTask: (courseId) => dispatch(tryGetOneTask(courseId))
})

export default connect(null, mapDispatchToProps)(withRouter(FirmTasks));