import {v4 as uuid4} from "uuid";
import {images} from "../../../components/images";

const randomNumber = () => {return Math.floor(Math.random() * 100)+1};

export const devEnterpriseFolderTree = {
    "id": null,
    "parentFolderId": null,
    "name": "Root",
    "files": [
        {
            "id": 1,
            "file_name": "a.json",
            "parent_folder_id": null
        }
    ],
    "folders": [
        {
            "id": 13,
            "parentFolderId": null,
            "name": "Renamed folder 43",
            "files": [],
            "folders": [
                {
                    "id": 14,
                    "parentFolderId": 13,
                    "name": "Small folder 43",
                    "files": [],
                    "folders": []
                },
                {
                    "id": 15,
                    "parentFolderId": 13,
                    "name": "Small folder 42",
                    "files": [
                        {
                            "id": 11,
                            "file_name": "Engagedly.postman_collection.json",
                            "parent_folder_id": 15
                        }
                    ],
                    "folders": [
                        {
                            "id": 16,
                            "parentFolderId": 15,
                            "name": "Small folder 42",
                            "files": [],
                            "folders": []
                        },
                        {
                            "id": 17,
                            "parentFolderId": 15,
                            "name": "Small folder 43",
                            "files": [],
                            "folders": []
                        },
                        {
                            "id": 18,
                            "parentFolderId": 15,
                            "name": "Small folder 44",
                            "files": [],
                            "folders": []
                        }
                    ]
                }
            ]
        },
        {
            "id": 19,
            "parentFolderId": null,
            "name": "Test folder for parent",
            "files": [],
            "folders": []
        }
    ]
};

export const devEnterpriseDriveList = [
    {
        "driveId":100,
        "driveType":"private_drive",
        "name":"Private Drive",
        "files":[
            {
                "id":1,
                "name":"Engagedly.postman_collection.json",
                "parentFolderId":null,
                "driveId":100
            }
        ],
        "folders":[
            {
                "id":19,
                "parentFolderId":null,
                "name":"Test folder for parent",
                "files":[],
                "folders":[]
            }
        ]
    },
    {
        "driveId":101,
        "driveType":"wmc_drive",
        "name":"WMC Drive - test",
        "files":[],
        "folders":[]
    },
    {
        "driveId":104,
        "driveType":"shared_drive",
        "name":"Shared drive for Lily",
        "users":[
            {
                "userId":61,
                "firstName":"",
                "lastName":"",
                "isAdvisor":false,
                "isLily":false
            },
            {
                "userId":12,
                "firstName":"Mark",
                "lastName":"Advisor",
                "isAdvisor":true,
                "isLily":false
            }
        ],
        "files":[
            {
                "id":14,
                "name":"MirkoBojcic.jpg",
                "parentFolderId":null,
                "driveId":104
            }
        ],
        "folders":[]
    }
];

export const devEnterpriseFolderList = async (traverseFolder, parentFolderId) => ({
    "folderId": traverseFolder ? randomNumber() : null,
    "driveId": 898,
    "driveType": "private_drive",
    "parentFolderId": traverseFolder ? parentFolderId : null,
    "name": traverseFolder ? randomNumber() : null,
    "folders": !traverseFolder
        ? [
            {"id": 13, "name": "Renamed folder 43", "contentType": "folder"},
            {"id": 19, "name": "Test folder for parent", "contentType": "folder"}
        ]
        : [{"id": 134, "name": "Test folder 5", "contentType": "folder"}]
    ,
    "files": !traverseFolder
        ? [
            {"id": 1, "name": "Engagedly.postman_collection.json", "contentType": null, "size": 54678544},
            {"id": 3, "name": "Something.img", "contentType": "image/png", "size": 32434}
        ]
        : [{"id": 5, "name": "Second file", "contentType": "image/png", "size": 124564}]
});

export const devEnterpriseCreateNewFolder = (name, parentFolderId) => ({
    "id": 8,
    "name": name,
    "parentFolderId": parentFolderId,
    "contentType": "folder"
});

export const devEnterpriseEditFolderName = (folderId, name) => ({
    "id": folderId,
    "name": name,
    "parentFolderId": 1
});

export const devEnterpriseMoveFolder = (folderId, newParentFolderId) => ({
    'id': folderId,
    'name': "Folder name",
    'parentFolderId': newParentFolderId
});

export const devEnterpriseCreateNewFile = (name, parentFolderId, fileType, size) => ({
    "id": 11,
    "name": name,
    "parentFolderId": parentFolderId,
    "contentType": fileType,
    "size": size
});

export const devEnterpriseFileRenamed = (fileId, newName) => ({
    "id": fileId,
    "name": newName,
    "parentFolderId": 4
});

export const devEnterpriseSharedDriveCreate = (name) => ({
    "id": uuid4(),
    "name": name,
    "type": "shared_drive"
});

export const devEnterpriseSharedDriveList = ([
    {
        "driveId":100,
        "driveType":"private_drive",
        "name":"Private Drive",
        "files":[
            {
                "id":1,
                "name":"Engagedly.postman_collection.json",
                "parentFolderId":null,
                "driveId":100
            }
        ],
        "folders":[
            {
                "id":19,
                "parentFolderId":null,
                "name":"Test folder for parent",
                "files":[],
                "folders":[]
            }
        ]
    },
    {
        "driveId":101,
        "driveType":"wmc_drive",
        "name":"WMC Drive - test",
        "files":[],
        "folders":[]
    },
    {
        "driveId":104,
        "driveType":"shared_drive",
        "name":"Shared drive for Lily",
        "users":[
            {
                "userId":61,
                "firstName":"",
                "lastName":"",
                "isAdvisor":false,
                "isLily":false
            },
            {
                "userId":12,
                "firstName":"Mark",
                "lastName":"Advisor",
                "isAdvisor":true,
                "isLily":false
            }
        ],
        "files":[
            {
                "id":14,
                "name":"MirkoBojcic.jpg",
                "parentFolderId":null,
                "driveId":104
            }
        ],
        "folders":[]
    }
]);

export const devEnterpriseSharedDrive = ({
    "driveId": 104,
    "driveType": "shared_drive",
    "name": "Shared drive for Lily",
    "parentFolderId": null,
    "folders": [],
    "files": [{
        "id": 14,
        "name": "MirkoBojcic.jpg"
    }]
});

export const devEnterpriseSharedDriveFolderList = (folderId) => ({
    "folderId": folderId,
    "driveId": 100,
    "driveType": "shared_drive",
    "parentFolderId": null,
    "folders":[
        {
            "id": 19,
            "name": "Folder"
        },
        {
            "id": 134, "name": "Test folder 5", "contentType": "folder"
        }
    ],
    "files": [
        {
            "id": 13,
            "name": "test.jpg"
        }
    ]
});

export const devEnterpriseDriveRenamed = (driveId, name) => {

};