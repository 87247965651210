import React from "react";
import styled from "styled-components";
import moment from 'moment/moment';

/* Components */
import {FontBody16, FontTitle30, FontSubtitle38To30} from "../../../common/components/fonts";
import {ButtonPrimary, ButtonLinkInactive} from "../../../common/components/buttons";
import {CalendarOutlined, CloseOutlined} from "@ant-design/icons";

/* Static */
import {images} from "../../../common/components/images";
import {colors} from "../../../common/components/colors";

export const Center = styled.div`
    text-align: center;
    color: ${colors.primary100};
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
`;

const BackTop = styled.div`
    font-size: 20px;
    display: flex;
    justify-content: flex-end;
    color: ${colors.secondary70};
    cursor: pointer;
    position: relative;
    z-index: 25;
    align-self: flex-start;
`;
const Button = styled.div`
    border-radius: 25px;
    background-color: ${props => props.primary ? colors.action100 : colors.backgroundColor3};
    color: ${colors.white};
    cursor: pointer;
    padding: 10px;
    width: 250px;
`;
const MarginY10 = styled.div`
    margin: 10px 0;
`;

const Menu = ({onClose, onCloseBtn, reschedule, selectedTime, meetingLength, showCancel, cancel}) => (
    <Center>
        {onCloseBtn &&
            <BackTop onClick={onClose}><CloseOutlined/></BackTop>
        }
        <FontSubtitle38To30>{'Change your appointment'}</FontSubtitle38To30>
        <MarginY10>
            <FontBody16>{moment(selectedTime).format("LLLL")} ({meetingLength}-minutes)</FontBody16>
        </MarginY10>
        <Button primary={true} onClick={reschedule}>
            <FontBody16><CalendarOutlined/>&nbsp;Reschedule Appointment</FontBody16>
        </Button>
        <Button primary={false} onClick={cancel}>
            <FontBody16><CloseOutlined/>&nbsp;Cancel Appointment</FontBody16>
        </Button>
    </Center>
);

export default Menu;
