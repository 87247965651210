import React, {useEffect, useRef, useState} from "react";
import InstructionsPageOne from "./instructionspageone";
import InstructionsPageTwo from "./instructionspagetwo";
import InstructionsPageThree from "./instructionspagethree";
import InstructionsPageFour from "./instructionspagefour";
import InstructionsPageFive from "./instructionspagefive";
import {ButtonPrimary, ButtonTertiary} from "../../common/components/buttons";
import styled from "styled-components";
import {colors} from "../../common/components/colors";
import {tryPostCreateSessionAppointment} from "../../iframes/scheduler/middleware/scheduler";
import {useDispatch} from "react-redux";
import {tryGetCertifiedAdvisors} from "../../iframes/advisordirectory/middleware/directory";
import {getAdvisorFromSearch} from "../../routes/middleware/auth";

export const BtnDiv = styled.div`
    margin:0;
    padding:0;
`;

export const BtnContainer = styled.div`
  justify-content: space-between;
  display: flex;
  max-width:100%;
  bottom:25px;
`;

const Container = styled.div`
  background-color: ${colors.white};
  margin:0 auto;
  padding:0;
  display:block;
  overflow-y: auto;
  width:100%;
  height: 50vh;
  ${BtnContainer} {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
  }
  
  & .back {
    position: fixed;
    bottom:25px;
    left: 20px;


  }
  
  & .next {
    position: fixed;
    bottom:25px;
    right:20px;

  }

`;
export default function InstructionsFlow({clientId, pageNum}) {
    const [page, setPage] = useState(pageNum);
    const fullscreenRef = useRef(null);
    const dispatch = useDispatch();
    const [datetime, setDatetime] = useState(null);
    const [advisors, setAdvisors] = useState([]);
    const [id, setId] = useState(0);
    const handleNext = () => {
        setPage(page + 1);
    }

    useEffect(() => {
        const elem = fullscreenRef.current;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        }

    },[])

    const handleBack = () => {
        setPage(page - 1);
    }

    const handleSubmit = () => {
        dispatch(tryPostCreateSessionAppointment(datetime, 15, id, null, false, "consumerSeekingAdvisorSession", null, advisorRequest))
        setPage(page + 1)
    }

    const handleDatetime = (d) => {
        setDatetime(d);
        setPage(3);
    }

    const handleAdvisorId = (id) => {
        setId(id);
    }

    useEffect( () => {

        getAdvisors();
    }, []);
    const getAdvisors = async () => {
        const allAdvisors = await dispatch(getAdvisorFromSearch(""));
        const advisorsCleaned = await Promise.all(allAdvisors.map(a => {
            let photos = a.advisor.additionalProfileInformation.photos.filter(p => {return(p !== null)})
            let logos = a.advisor.additionalProfileInformation.logos.filter(l => {return(l !== null)})
            return({
                ...a,
                ...a.advisor,
                ...a.advisor.additionalProfileInformation,
                photos: photos.length > 0 ? photos[0] : null,
                logos: logos.length > 0 ? logos[0] : null,
                priority: false, // is sponsored advisor
                active: true, // is advisor active (expiration not hit)
            })
        }))
        setAdvisors([advisorsCleaned[0],advisorsCleaned[1],advisorsCleaned[2]]);
    }

    return (
        <Container ref={fullscreenRef}>
            {page === 1 && <InstructionsPageOne page={page} handleBack={handleBack} handleNext={handleNext} />}
            {page === 2 && <InstructionsPageTwo setDatetime={handleDatetime} advisors={advisors} setId={handleAdvisorId}
                                                page={page} handleBack={handleBack} handleNext={handleNext}/>}
            {page === 3 && <InstructionsPageThree page={page} handleBack={handleBack} handleNext={handleNext}/>}
            {page === 4 && <InstructionsPageFour submit={handleSubmit} page={page} handleBack={handleBack} handleNext={handleNext}/>}
            {page === 5 &&  <InstructionsPageFive />}

        </Container>
    );
}