import { Map } from 'immutable';

/* Immutable */

const FEEDBACK_SEND_PENDING = 'FEEDBACK_SEND_PENDING';
const FEEDBACK_SEND_COMPLETE = 'FEEDBACK_SEND_COMPLETE';
const FEEDBACK_STORE_RESET = 'FEEDBACK_STORE_RESET';

/* Actions */

export const setFeedackSendPending = () => ({'type': FEEDBACK_SEND_PENDING});
export const setFeedackSendComplete = () => ({'type': FEEDBACK_SEND_COMPLETE});
export const setSendStoreReset = () => ({'type': FEEDBACK_STORE_RESET});

/* Initial State */

const initialState = Map({
    feedbackSending: false
});

/* Reducer */

const feedbackReducer = (state=initialState, action) => {
    switch (action.type) {
        case FEEDBACK_SEND_PENDING:
            return state.merge({'feedbackSending': true});
        case FEEDBACK_SEND_COMPLETE:
            return state.merge({'feedbackSending': false});
        case FEEDBACK_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default feedbackReducer;
