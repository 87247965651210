import React from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {connect} from "react-redux";
import moment from 'moment-timezone';

/* Components */
import {FontTitle28, FontBody21, FontTitle24, FontBody18} from '../../../common/components/fonts';
import {colors} from '../../../common/components/colors';
import {ButtonPrimary, ButtonTertiary} from '../../../common/components/buttons';
import {images} from "../../../common/components/images";

/* Middleware */
import {tryGetMySubscribedGroupPrograms, tryGetAllPrograms} from "../../programs/middleware/programs";

const Preview = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 30px;
    cursor: pointer;
    background-color: ${colors.backgroundColor4};
    border-radius: 30px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
`;
const TitleSection = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 10px;
    justify-content: space-between;
    align-items: flex-start;
`;
const TitleSubtitle = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
`;
const Photo = styled.img`
    width: 100%;
    height: auto;
    max-height: 350px;
    object-fit: cover;
    border-radius: 30px 30px 0 0;
`;
const Button = styled.div`
    & div {
        cursor: pointer;
        font-size: 18px;
    }
`;
const ButtonOff = styled.div`
    & div {
        cursor: default;
        font-size: 18px;
    }
`;
const Description = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 30px 30px 30px;
`;
const DashboardContainer = styled.div`
    border-radius: 30px;
    background-color: ${colors.white};
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    padding: 30px;
    justify-content: center;
    gap: 20px;
    display: flex;
    flex-direction: column;
`;
const FlexRow = styled.div`
  flex-direction: row;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-evenly;
  @media screen and (max-width: 460px) {
    gap: 15px;
  }
`;
const ImgReverse = styled.img`
    transform: rotate(180deg);
  cursor: pointer;
`;
const ImgRegular = styled.img`
    cursor: pointer;
`;
const CECredit = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    & img {
      height: 32px;
      align-items: center;
      justify-content: flex-start;
    }
`;

class ConsumerDashPrograms extends React.Component {
    state = {
        "trainingsShown": [],
        "isLoading": true,
        "cardShown": 0,
        "cardsTotal": 0,
    }

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const trainingsRegistered = await this.props.tryGetMySubscribedGroupPrograms();
        const trainingsRegisteredIds = await this.trainingsRegisteredIds(trainingsRegistered);
        const allTrainings = await this.props.tryGetAllPrograms();
        const trainingsUpcomingWithStatus = await this.trainingStatuses(trainingsRegisteredIds, allTrainings);
        let trainingsShown = await this.filter(trainingsUpcomingWithStatus, false);
        if(trainingsShown.length === 0) {
            trainingsShown = await this.filter(trainingsUpcomingWithStatus, true);
        }
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        await this.setState({
            "trainingsShown": trainingsShown,
            "cardsTotal": trainingsShown.length,
            "timeZone": timeZone,
            "isLoading": false
        })
    }

    trainingsRegisteredIds = async (trainingsRegistered) => {
        return trainingsRegistered.map(t => {
            return (t.groupProgram.id)
        })
    }

    trainingStatuses = async (trainingsRegisteredIds, trainingsAll) => {
        return trainingsAll.map(t => {
            return({...t, "canRegister": !trainingsRegisteredIds.includes(t.id)})
        })
    }

    filter = async (trainingsUpcomingWithStatus, showPromotional) => {
        return trainingsUpcomingWithStatus.filter(t => {
            if(!t.isAssetManagement && (showPromotional ? t.isPromotionalProgram : !t.isPromotionalProgram)) {
                return(t)
            }
        })
    }

    backOne = () => {
        this.setState({"cardShown": ((this.state.cardShown - 1) < 0) ? (this.state.cardsTotal - 1) : (this.state.cardShown - 1)})
    }
    forwardOne = () => {
        this.setState({"cardShown": ((this.state.cardShown + 1) > (this.state.cardsTotal - 1)) ? 0 : (this.state.cardShown + 1)})
    }

    render() {
        return(
            <DashboardContainer>
                <FontTitle24>Upcoming Programs</FontTitle24>
                {this.state.trainingsShown.length === 0
                    ? <FontBody18>No upcoming programs</FontBody18>
                    : <FlexRow>
                        {this.state.trainingsShown.length > 1 &&
                            <ImgReverse onClick={this.backOne} src={images.chevronRightDark} alt={"<"}/>
                        }
                        {this.state.trainingsShown.map((t, index) => {
                            if(index === this.state.cardShown) {
                                return(
                                    <Preview key={index} onClick={() => {this.props.history.push("/programs")}}>
                                        <Photo src={t.imageUrl} alt={""} />
                                        <Description>
                                            <TitleSection>
                                                <TitleSubtitle>
                                                    <FontTitle28 spaced={false}>{t.title}</FontTitle28>
                                                    <div>
                                                        {!t.isPromotionalProgram &&
                                                            <FontBody21>{moment(t.startsOn).tz(this.state.timeZone).format("MMM D, YYYY [at] h:mm a") + " - " + moment(t.startsOn).add(50, "minutes").tz(this.state.timeZone).format("h:mm a z")}</FontBody21>
                                                        }
                                                    </div>
                                                </TitleSubtitle>
                                                {t.canRegister
                                                    ? <Button><ButtonPrimary canSubmit={false} label={"See more"}/></Button>
                                                    : <ButtonOff><ButtonTertiary canSubmit={false} label={"Registered"}/></ButtonOff>
                                                }
                                            </TitleSection>
                                        </Description>
                                    </Preview>
                                )
                            } else {
                                return null
                            }
                        })}
                        {this.state.trainingsShown.length > 1 &&
                            <ImgRegular onClick={this.forwardOne} src={images.chevronRightDark} alt={">"}/>
                        }
                    </FlexRow>
                }
            </DashboardContainer>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    tryGetMySubscribedGroupPrograms: () => dispatch(tryGetMySubscribedGroupPrograms()),
    tryGetAllPrograms: () => dispatch(tryGetAllPrograms())
});

export default connect(null, mapDispatchToProps)(withRouter(ConsumerDashPrograms));