const traditionsBank = {
    "logo": "logoTraditionsBank",
    "logoIcon": "logoTraditionsBankIcon",
    "url": "https://traditions.bank",
    "registrationCode": "2m6ikx",
    "matchingFormSelected": "matchingFormTraditions"
}

const hrCodes = {
    "2m6ikx": traditionsBank
}

const wmcIds = {
    "35": traditionsBank
}

export default {
    "HR_CODES": hrCodes,
    "WMC_IDS": wmcIds
}