import { Map } from 'immutable';

/* Immutable */

const SUPPORT_OPEN = 'SUPPORT_OPEN';
const SUPPORT_STORE_RESET = 'SUPPORT_STORE_RESET';

/* Actions */

export const setSupportOpen = (isOpen, page) => ({'type': SUPPORT_OPEN, 'isOpen': isOpen, 'page': page});
export const setSupportStoreReset = () => ({'type': SUPPORT_STORE_RESET});

/* Initial State */

const initialState = Map({
    isOpen: false,
    page: 1
});

/* Reducer */

const supportReducer = (state=initialState, action) => {
    switch (action.type) {
        case SUPPORT_OPEN:
            return state.merge({
                'isOpen': action.isOpen,
                'page': action.page === null ? 1 : action.page
            });
        default:
            return state;
    }
};

export default supportReducer;
