import { Map, List, fromJS } from 'immutable';

/* Immutable */

const COURSES = 'COURSES';
const COURSES_FETCHING = 'COURSES_FETCHING';
const COURSES_ADD = 'COURSES_ADD';
const COURSES_CREATING = 'COURSES_CREATING';
const COURSES_UPDATE = 'COURSES_UPDATE';
const COURSES_UPDATING = 'COURSES_UPDATING';
const COURSES_DELETE = 'COURSES_DELETE';
const COURSES_DELETING = 'COURSES_DELETING';
const COURSES_REPORT = 'COURSES_REPORT';
const COURSES_REPORT_FETCHING = 'COURSES_REPORT_FETCHING';
const COURSES_ASSIGNED = 'COURSES_ASSIGNED';
const COURSES_ASSIGNING = 'COURSES_ASSIGNING';
const COURSES_STORE_RESET = 'COURSES_STORE_RESET';

/* Actions */

export const setCourses = (coursesAll) => ({'type': COURSES, 'coursesAll': coursesAll});
export const setCoursesFetching = (status) => ({'type': COURSES_FETCHING, 'status': status});
export const setCoursesAdd = (newCourse) => ({'type': COURSES_ADD, 'newCourse': newCourse});
export const setCoursesCreating = (status) => ({'type': COURSES_CREATING, 'status': status});
export const setCoursesUpdate = (editedCourse) => ({'type': COURSES_UPDATE, 'editedCourse': editedCourse});
export const setCoursesUpdating = (status) => ({'type': COURSES_UPDATING, 'status': status});
export const setCoursesDeleted = (courseId) => ({'type': COURSES_DELETE, 'courseId': courseId});
export const setCoursesDeleting = (status) => ({'type': COURSES_DELETING, 'status': status});
export const setCoursesReport = (report) => ({'type': COURSES_REPORT, 'report': report});
export const setCoursesReportFetching = (status) => ({'type': COURSES_REPORT_FETCHING, 'status': status});
export const setCoursesAssigned = (courseId, consumerId) => ({'type': COURSES_ASSIGNED, 'courseId': courseId, 'consumerId': consumerId});
export const setCoursesAssigning = (status) => ({'type': COURSES_ASSIGNING, 'status': status});
export const setCoursesStoreReset = () => ({'type': COURSES_STORE_RESET});

/* Initial State */

const initialState = Map({
    coursesAll: List(),
    coursesFetching: false,
    coursesCreating: false,
    coursesUpdating: false,
    coursesDeleting: false,
    coursesReport: Map(),
    coursesReportFetching: false,
    coursesAssigning: false,
});

/* Reducer */

const coursesReducer = (state=initialState, action) => {
    switch (action.type) {
        case COURSES:
            return state.merge({'coursesAll': fromJS(action.coursesAll)});
        case COURSES_FETCHING:
            return state.merge({'coursesFetching': action.status});
        case COURSES_ADD:
            return state; // FIX
        case COURSES_CREATING:
            return state.merge({'coursesCreating': action.status});
        case COURSES_UPDATE:
            return state; // FIX
        case COURSES_UPDATING:
            return state.merge({'coursesUpdating': action.status});
        case COURSES_DELETE:
            return state; // FIX
        case COURSES_DELETING:
            return state.merge({'coursesDeleting': action.status});
        case COURSES_REPORT:
            return state.merge({'coursesReport': fromJS(action.report)});
        case COURSES_REPORT_FETCHING:
            return state.merge({'coursesReportFetching': action.status});
        case COURSES_ASSIGNED:
            return state; // FIX
        case COURSES_ASSIGNING:
            return state.merge({'coursesAssigning': action.status});
        case COURSES_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default coursesReducer;
