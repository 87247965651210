import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from "styled-components";

/* Components */
import {ButtonAlert, ButtonTertiaryAlert} from "../../../common/components/buttons";
import FirmModal from "../../../common/components/modalv2";
import {LogoLoading} from "../../../common/components/loading";

/* Middleware */
import {tryDeleteAppointment} from "../middleware/scheduling";

const LoadingCenter = styled.div`
    margin: 0px auto;
`;

class FirmSchedulingRescheduleCancel extends React.Component {
    static defaultProps = {
        hideModal: () => {},
        onExecuteCancel: () => {},
        onExecuteReschedule: () => {},
        sessionSelected: {},
        showModal: false
    }

    state = {
        "sessionSelected": {},
        "showModal": false,
        "showModalReschedule": false,
        "showModalCancel": false,
        "isLoading": false
    }

    componentDidMount() {
        this.init();
    }

    init = async () => {
        await this.setState({
                                "sessionSelected": this.props.sessionSelected, 
                                "showModal": this.props.showModal,
                                "showModalReschedule": false,
                                "showModalCancel": false
                            });
        console.log("within componentdidMount");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.sessionSelected !== this.props.sessionSelected) {
            console.log("within componentDIdUpdate")
            this.init();
        }
    }

    componentWillUnmount() {
        this.setState({"showModal": false})
    }

    hideModalCancel = async () => {
        await this.setState({"showModal": false, "showModalCancel": false})
        this.props.hideModal();
    };
    showModalCancel = async () => this.setState({"showModal": false, "showModalCancel": true});
    executeCancel = async () => {
        await this.setState({"isLoading": true})
        console.log("Diana cancel", this.props.sessionSelected)
        await this.props.tryDeleteAppointment(this.props.sessionSelected.cancelToken)
        await this.props.onExecuteCancel(this.props.sessionSelected);
        this.setState({"showModal": false, "showModalCancel": false, "isLoading": false})
    }

    hideModalReschedule = async () => {
        await this.setState({"showModal": false, "showModalReschedule": false})
        this.props.hideModal();
    };
    showModalReschedule = async () => this.setState({"showModal": false, "showModalReschedule": true});
    executeReschedule = async () => {
        await this.setState({"isLoading": true})
        await this.props.tryDeleteAppointment(this.props.sessionSelected.cancelToken)
        await this.props.onExecuteReschedule(this.props.sessionSelected)
        this.setState({"showModal": false, "showModalReschedule": false, "isLoading": false})
    }

    render() {
        if(this.state.isLoading) {
            return(
                <FirmModal
                    message={<LoadingCenter><LogoLoading /></LoadingCenter>}
                    confirmShow={false}
                    cancelShow={false}
                />
            )
        } else {
            return (
                <>
                    {this.state.showModal &&
                        <FirmModal
                            closeShow={true}
                            closeAction={this.props.hideModal}
                            message={"Would you like to change this appointment?"}
                            cancelAction={this.showModalCancel}
                            cancelLabel={"Cancel It"}
                            cancelButtonType={ButtonTertiaryAlert}
                            confirmAction={this.showModalReschedule}
                            confirmLabel={"Reschedule"}
                            confirmButtonType={ButtonTertiaryAlert}
                        />
                    }
                    {this.state.showModalCancel &&
                        <FirmModal
                            closeShow={true}
                            closeAction={this.hideModalCancel}
                            message={"Please confirm you would like to cancel this appointment"}
                            cancelShow={false}
                            confirmAction={this.executeCancel}
                            confirmLabel={"Confirm"}
                            confirmButtonType={ButtonAlert}
                        />
                    }
                    {this.state.showModalReschedule &&
                        <FirmModal
                            closeShow={true}
                            closeAction={this.hideModalReschedule}
                            message={"Please confirm you would like to reschedule this appointment"}
                            cancelShow={false}
                            confirmAction={this.executeReschedule}
                            confirmLabel={"Confirm"}
                            confirmButtonType={ButtonAlert}
                        />
                    }
                </>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryDeleteAppointment: (cancelToken) => dispatch(tryDeleteAppointment(cancelToken))
});

export default connect(null, mapDispatchToProps)(withRouter(FirmSchedulingRescheduleCancel));