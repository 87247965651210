import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import ReactQuill from "react-quill";

/* Components */
import {FontBody16, FontBody18, FontHeader16, FontHeader21, FontTitle18} from '../../../common/components/fonts';
import {ButtonSecondary} from "../../../common/components/buttons";
import {LogoLoading} from "../../../common/components/loading";
import {EditOutlined, PlusOutlined, DeleteOutlined} from "@ant-design/icons";
import {Gray, Image, Portfolio, Text, Portfolios, LoadingCenter, FitContent, Border, Btn, Inline, TextLeft} from "../components/portfolios";
import {Quill} from "../../../common/components/richtexteditor";
import {colors} from "../../../common/components/colors";

/* Store */
import {setShopItemPortfolio} from "../store/shopitems";

/* Middleware */
import {tryUpdateExpertProfile} from "../middleware/expertprofile";

const pickMonth = (num) => {
    if(num === 1) {return("Jan")}
    else if(num === 2) {return("Feb")}
    else if(num === 3) {return("Mar")}
    else if(num === 4) {return("Apr")}
    else if(num === 5) {return("May")}
    else if(num === 6) {return("Jun")}
    else if(num === 7) {return("Jul")}
    else if(num === 8) {return("Aug")}
    else if(num === 9) {return("Sep")}
    else if(num === 10) {return("Oct")}
    else if(num === 11) {return("Nov")}
    else if(num === 12) {return("Dec")}
};

class ProfileHighlights extends React.Component {
    state = {"portfolio": []};

    componentDidMount() {
        return this.init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.profile !== this.props.profile) {
            return this.init()
        }
    }

    init = async () => {
        this.setState({
            "portfolio": this.props.profile.get("portfolio").toJS(),
            "isLoading": false
        });
    };

    add = async () => {
        await this.props.setShopItemPortfolio({});
        this.props.history.push("/"+(this.props.isWMC ? "profile" : "shop")+"/portfolio/create");
    };

    edit = async (item) => {
        await this.props.setShopItemPortfolio(item);
        this.props.history.push("/"+(this.props.isWMC ? "profile" : "shop")+"/portfolio/edit");
    };

    delete = async (item) => {
        let portfolios = this.props.profile.get("portfolio").toJS();
        const saveData = {
            // "bio": this.props.profile.get("bio"),
            // "whyBecameCoach": this.props.profile.get("whyBecameCoach"),
            // "freeTime": this.props.profile.get("freeTime"),
            // "endorsement1": this.props.profile.get("endorsement1"),
            // "endorsement2": this.props.profile.get("endorsement2"),
            // "endorsement3": this.props.profile.get("endorsement3"),
            "portfolio": await portfolios.filter(p => {return(p.id !== item.id)}),
        };
        await this.props.tryUpdateExpertProfile(saveData, "deletePortfolio");
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <FontTitle18>My Portfolio</FontTitle18>
                    <br />
                    <FitContent>
                        <FontBody16 onClick={() => {return this.add()}}>
                            <ButtonSecondary canSubmit={false} label={<><PlusOutlined />&nbsp;Add To Portfolio</>} />
                        </FontBody16>
                    </FitContent>
                    <br/>
                    <Portfolios>
                        {this.state.portfolio.map(f => (
                            <Portfolio key={f.id}>
                                <FontHeader21>{f.title}</FontHeader21>
                                <Gray><FontHeader16>
                                    {f.startMonth !== null && <span>{pickMonth(f.startMonth)}</span>}
                                    {(f.startMonth !== null && f.startYear !== null) && <span>,&nbsp;</span>}
                                    {f.startYear !== null && <span>{f.startYear}</span>}
                                    {((f.startMonth !== null || f.startYear !== null) && (f.endMonth !== null || f.endYear !== null || f.endOngoing)) &&
                                        <span>&nbsp;-&nbsp;</span>
                                    }
                                    {f.endOngoing
                                        ?
                                            <span>Ongoing</span>
                                        :
                                            <>
                                                {f.endMonth !== null && <span>{pickMonth(f.endMonth)}</span>}
                                                {(f.endMonth !== null && f.endYear !== null) && <span>,&nbsp;</span>}
                                                {f.endYear !== null && <span>{f.endYear}</span>}
                                            </>
                                    }
                                </FontHeader16></Gray>
                                {f.photo !== null &&
                                    <>
                                        <br />
                                        <Image src={f.photo} alt={""} />
                                    </>
                                }
                                <Text>
                                    <Quill bodyBorderColor={colors.white}>
                                        <ReactQuill
                                            readOnly={true}
                                            theme={null}
                                            value={f.body}
                                            bounds={'.app'}
                                        />
                                    </Quill>
                                </Text>
                                <Border />
                                <TextLeft>
                                    <Inline>
                                        <FitContent>
                                            <Btn>
                                                <FontBody16 onClick={() => {return this.edit(f)}}><EditOutlined/>&nbsp;Edit</FontBody16>
                                            </Btn>
                                        </FitContent>
                                    </Inline>
                                    <Inline>
                                        <FitContent>
                                            <Btn>
                                                <FontBody16 onClick={() => {return this.delete(f)}}><DeleteOutlined />&nbsp;Delete</FontBody16>
                                            </Btn>
                                        </FitContent>
                                    </Inline>
                                </TextLeft>
                            </Portfolio>
                        ))}
                    </Portfolios>
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    isWMC: state.common.wmc.get("isWMC"),
    profile: state.enterprise.shop.expertProfile.get("expertProfile"),
});

const mapDispatchToProps = dispatch => ({
    setShopItemPortfolio: (item) => dispatch(setShopItemPortfolio(item)),
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileHighlights));
