import React from "react";
import styled from "styled-components";
import moment from 'moment/moment';

/* Components */
import {FontBody16, FontTitle30} from "../../../common/components/fonts";
import {ButtonPrimary} from "../../../common/components/buttons";
import {ArrowLeftOutlined, CloseOutlined, CheckCircleOutlined} from "@ant-design/icons";

/* Static */
import {colors} from "../../../common/components/colors";

const Title = styled.div`
    margin-bottom: 15px;
`;

export const Center = styled.div`
    margin-top: 30px;
    text-align: center;
    color: ${colors.primary100};
`;

export const SummaryIcon = styled.div`
    width: 100px;
    margin: 20px auto;
    height: 100px;
`;

export const SummaryTitle = styled.div`
    padding-bottom: 30px;
`;
export const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;

export const FitContent = styled.div`
    width: fit-content;
    cursor: pointer;
    margin: 0px auto;
`;

const GrayDark = styled.div`
    color: ${colors.secondary100};
    @media screen and (max-width: 460px) {
        margin-top: 20px;
        margin-bottom: 10px;
    }
`;

const Text100 = styled.div`
    font-size: 100px;
`;

const Confirm = ({close}) => (
    <Center>
        <FontTitle30><Text100><CheckCircleOutlined /></Text100></FontTitle30>
        <SummaryTitle>
            <FontTitle30>Message sent!</FontTitle30>
        </SummaryTitle>
        <FitContent onClick={close}>
            <ButtonPrimary submit={false} label={<><Inline><CloseOutlined /></Inline>&nbsp;Close</>} />
        </FitContent>
        <br />
        <br />
    </Center>
);

export default Confirm;