import {axiosReq} from "../../../../common/utils/axios";

/* Data */
import {devConsumerTeamMembers, devTeamMemberMeetingsData} from "../../../../consumer/team/static/devdata";
import {devMyRegisteredPrograms} from "../../../../consumer/programs/static/devdata";
import {devEnterpriseClientsistV2} from "../../../static/devdata";
import {devActiveGroupPrograms} from "../../../../iframes/programs/static/devdata";
import {devCECredits} from '../static/devdata';

export const tryGetCoachingAppointments = () => {
    return async dispatch => {
        return axiosReq('apiv1/consumer/journey/sessions', "GET", {}, devTeamMemberMeetingsData);
    }
}

export const tryGetUpcomingTrainingsRegisteredFor = () => {
    return async dispatch => {
        return axiosReq('apiv1/consumer/group-program/subscriptions', "GET", {}, devMyRegisteredPrograms)
    }
};

export const tryGetAllClients = () => {
    return async (dispatch) => {
        const clients = await axiosReq('apiv1/coach/clients-and-prospects/with-meetings', "GET", {}, devEnterpriseClientsistV2);
        return clients.prospects;
    }
};

export const tryGetAllMyCoaches = () => {
    return async (dispatch, getState) => {
        if(getState().common.user.get("isLily")) {
            const team = await axiosReq('apiv1/consumer/coach/all-my-coaches', "GET", {}, devConsumerTeamMembers);
            return team.allCoaches;
        } else {
            return []
        }
    }
};

export const tryGetAllTrainings = () => {
    return async dispatch => {
        return axiosReq('apiv1/consumer/group-program/catalogue', "GET", {}, devActiveGroupPrograms)
    }
}

export const tryGetCECredits = () => {
    return async dispatch => {
        const credits = await axiosReq('apiv1/consumer/courses/ce-scores', "GET", {}, devCECredits)
        return credits.ceScore;
    }
}

export const tryGetJourneySessions = () => {
    return async dispatch => {
        return  axiosReq('apiv1/consumer/journey/sessions', "GET", {}, devTeamMemberMeetingsData)
    }
}