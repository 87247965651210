import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import { CloudUploadOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import { colors } from '../../../components/colors';
import { DropdownInputMyMoney, IconButton, SubmitButtonMyMoney } from '../../../components/inputs';
import { MyMoneyCard, MyMoneyCardTitle } from '../../../components/cards';
import {LogoLoading} from "../../../../common/components/loading";
import {
    MyMoneyTab,
    MyMoneyTabHeading,
    MyMoneyTabDescrip,
    MyMoneyFormWrapper,
    MyMoneyGraphWrapper,
    MyMoneySectionTitle,
    CardTitleInput,
    DeleteButton,
    LineItemList,
    LineItem,
    LineItemNameInput,
    LineItemValueInput,
    FteTooltip,
    GetCoachCTA,
} from '../components/mymoney';
import Sunburst from '../../../components/sunburst';

import {
    setMymoneySpendingActiveplan,
    setMymoneySpendingNewplan,
    setMymoneySpendingAddcategory,
    setMymoneySpendingAddlineitem,
    setMymoneySpendingUpdatecategory,
    setMymoneySpendingUpdatelineitem,
    setMymoneySpendingDeletelineitem,
} from '../store/mymoney';

import {tryUpdateMyMoneyIncomeStatement, tryGetMyMoneyIncomeStatement} from '../middleware/mymoney';
import { FontSubtitle21, FontTitle16 } from '../../../components/fonts';
import { TextAlignCenter } from '../../../components/layout';

const BtnCenter = styled.div`
   display: inherit;
   text-align: center;
`;

class MoneySpending extends Component {
    static defaultProps = { "useNewDesign": false, };

    state = {
        focusedLineitem: null,
        activePlan: null,
        income: 0,
        spending: 0,
        total: 0,
        isLoading: true,
        // eligableForFte: !document.cookie.split(';').some((cookie) => cookie.trim().startsWith('seenMymoneyFte=')),
        savedTime: moment(),
        savedTimeFromNow: '',
        unsavedChanges: null,
        hasCoach: true,
    };

    timerID = 0;

    componentDidMount() {
        // this.timerID = setInterval(() => this.refreshTimer(), 45000);
        return this.init()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.client !== this.state.client) {
            return this.init()
        }
        if (prevState.unsavedChanges !== this.state.unsavedChanges) {
            this.timeoutID = setTimeout(this.handleSave, 1500);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
        this.timerID = 0;
        clearTimeout(this.timeoutID);
    }

    init = async () => {
        const empty ={};
        //await this.reset()
        const results = await this.props.tryGetMyMoneyIncomeStatement();
        console.log(results)

        // if (results.hasOwnProperty('error') || results.incomeStatement === null || results.incomeStatement === undefined || balanceSheet.balanceSheet === empty) {
        //     return this.setState({hasCoach: false, isLoading: false});
        // }
        const activePlan = this.props.plans.find(plan => plan.pid === this.props.activePlanId);
        const income = activePlan.income.map(a => {return parseInt(a.amount)}).reduce((a, b) => a + b, 0);
        const spending = activePlan.expenses.map(a => {return a.lineItems}).map(a => {return a.map(b => {return parseInt(b.amount)}).reduce((a, b) => a + b, 0)}).reduce((a, b) => a + b, 0);
        const total = income - spending;
        
        return this.setState({activePlan: activePlan, income: income, spending: spending, total: total, isLoading: false,});
    }

    reset = async () => {
        return this.setState({
            focusedLineitem: null,
            activePlan: null,
            income: 0,
            spending: 0,
            total: 0,
            isLoading: true,
        });
    }

    refreshTimer = async () => {
        await this.setState({
            savedTimeFromNow: 'Autosaved ' + this.state.savedTime.fromNow(),
        });
    }

    handleChangeActivePlan({ value, label }) {
        if(value === 'NEW') {
            const newPlanTitle = prompt('What is the title of you new plan?');
            if(newPlanTitle) {
                this.props.setMymoneySpendingNewplan(newPlanTitle);
            }
        } else {
            this.props.setMymoneySpendingActiveplan(value);
        }
    }
    handleSave = async (e) => {
        this.timerID && await clearInterval(this.timerID);
        await this.props.tryUpdateMyMoneyIncomeStatement();
        await this.setState({savedTime: moment(), unsavedChanges: false})
        this.refreshTimer();
        this.timerID = setInterval(() => this.refreshTimer(), 45000);
        // document.cookie = 'seenMymoneyFte=1; max-age=' + 60*60*24*2;
        // this.setState({ eligableForFte: false });
    }
    handleCloseSaveTooltip() {
        // document.cookie = 'seenMymoneyFte=1; max-age=' + 60*60*24*2;
        // this.setState({ eligableForFte: false });
    }
    handleAddCategory(e) {
        this.props.setMymoneySpendingAddcategory();
        this.setState({
            activePlan: this.props.plans.find(plan => plan.pid === this.props.activePlanId),
            unsavedChanges: true,
        })
    }
    handleCategoryUpdate(cid, field, e) {
        this.props.setMymoneySpendingUpdatecategory(cid, field, field === 'amount' ? parseFloat(e.currentTarget.value) : e.currentTarget.value);
        this.setState({unsavedChanges: true,})
    }
    handleAddLineItem(cid, e) {
        this.props.setMymoneySpendingAddlineitem(cid);
        this.setState({
            activePlan: this.props.plans.find(plan => plan.pid === this.props.activePlanId),
            unsavedChanges: true,
        })
    }
    handleLineItemUpdate(cid, i, field, e) {
        this.props.setMymoneySpendingUpdatelineitem(cid, i, field, e.currentTarget.value);
        this.setState({unsavedChanges: true,})
    }
    handleLineItemFocus(cid, lid) {
        this.setState({ focusedLineitem: `${cid},${lid}` });
    }
    handleLineItemBlur() {
        this.setState({ focusedLineitem: null });
    }
    handleLineItemDelete(cid, i) {
        this.setState({ focusedLineitem: null });
        this.props.setMymoneySpendingDeletelineitem(cid, i);
        this.setState({
            activePlan: this.props.plans.find(plan => plan.pid === this.props.activePlanId),
            unsavedChanges: true,
        })
    }

    buildSunburstData(plan) {
        return {
            name: 'root',
            children: plan.expenses.map((cat, i) => {
                if(!cat.lineItems) { return; }
                return {
                    name: cat.title || 'New Category',
                    color: colors.graphColors2[i % colors.graphColors.length],
                    children: cat.lineItems.map(line => ({
                        name: line.title,
                        size: line.amount,
                        color: colors.graphColors[i % colors.graphColors.length] + '77',
                    }))
                }
            }),
        };
    }
    renderCard({ cid='CATEGORY_ID', title='New Category', lineItems=[], color='#c1c1c3', backgroundColor }) {
        return (
            <MyMoneyCard key={`Cat-${cid}`} color={color} useNewDesign={this.props.useNewDesign} backgroundColor={this.props.useNewDesign ? (backgroundColor ? backgroundColor : colors.backgroundColor4) : null}>
                <MyMoneyCardTitle useNewDesign={this.props.useNewDesign}>
                    {cid === 'INCOME' ? <span>{title}</span> : <CardTitleInput value={title} useNewDesign={this.props.useNewDesign} onChange={this.handleCategoryUpdate.bind(this, cid, 'title')} />}
                    <IconButton
                        backgroundColor={colors.white}
                        iconComponent={PlusOutlined}
                        color={colors.primary100}
                        label="Add Line Item"
                        style={{ margin: '-3px 0', flex: 0 }}
                        onClick={this.handleAddLineItem.bind(this, cid)}
                    />
                </MyMoneyCardTitle>
                <LineItemList>
                    {lineItems.map((line, i) => (
                        <LineItem key={`LI-${cid}-${line.lid}`}>
                            <LineItemNameInput
                                defaultValue={line.title}
                                onChange={this.handleLineItemUpdate.bind(this, cid, i, 'title')}
                                onFocus={this.handleLineItemFocus.bind(this, cid, line.lid)}
                                onBlur={this.handleLineItemBlur.bind(this)}
                                placeholder={cid === 'INCOME' ? 'New Income Source' : 'New Expense' }
                            />
                            <LineItemValueInput
                                defaultValue={line.amount}
                                onChange={this.handleLineItemUpdate.bind(this, cid, i, 'amount')}
                                onFocus={this.handleLineItemFocus.bind(this, cid, line.lid)}
                                onBlur={this.handleLineItemBlur.bind(this)}
                                min="0"
                            />
                            {this.state.focusedLineitem === `${cid},${line.lid}` && <DeleteButton onMouseDown={this.handleLineItemDelete.bind(this, cid, i)} />}
                        </LineItem>
                    ))}
                </LineItemList>
            </MyMoneyCard>
        );
    }
    renderTotal({ cid='TOTAL', lineItems=[], color='#000000' }) {
        return (
            <MyMoneyCard key={`Cat-${cid}`} color={color} useNewDesign={this.props.useNewDesign} backgroundColor={this.props.useNewDesign ? colors.backgroundColor6 : null}>
                <MyMoneyCardTitle useNewDesign={this.props.useNewDesign}>
                    <span>{"Total Monthly Income – Total Monthly Expenses = Monthly Cash Flow"}</span>
                </MyMoneyCardTitle>
                <LineItemList>
                    {lineItems.map((line, i) => (
                        <LineItem key={`LI-${cid}-${line.lid}`}>
                            <LineItemNameInput
                                defaultValue={line.title}
                                placeholder={''}
                                disabled={true}
                            />
                            <LineItemValueInput
                                defaultValue={line.amount}
                                disabled={true}
                            />
                        </LineItem>
                    ))}
                </LineItemList>
            </MyMoneyCard>
        );
    }
    render() {
        if (this.state.isLoading) {
            return(<LogoLoading />)
        } else if (!this.state.hasCoach) {
            return <GetCoachCTA history={this.props.history}/>
        } else {
            const activePlan = this.props.plans.find(plan => plan.pid === this.props.activePlanId);
            const totals = [
                {"lid": 1, "title": "Total Monthly Income", "amount": this.state.income.toFixed(2)},
                {"lid": 2, "title": "Total Monthly Expenses", "amount": this.state.spending.toFixed(2)},
                {"lid": 3, "title": "Monthly Cash Flow", "amount": this.state.total.toFixed(2)}
            ]
            // const planOptions = this.props.plans.map(plan => ({ value: plan.pid, label: plan.title }));
            // planOptions.push({ value: 'NEW', label: '+ Create New' });
            return (
                <MyMoneyTab>
                    {this.props.useNewDesign && <TextAlignCenter>
                        {/*<FontSubtitle21>Monthly Spending Plan</FontSubtitle21>*/}
                        {/*<br/>*/}
                    </TextAlignCenter>}
                    <MyMoneyTabHeading>Get to know your money inflows and outflows!</MyMoneyTabHeading>
                    <MyMoneyTabDescrip>Use the Willow Spending Plan to track how much money you’re spending. Track your monthly expenses by category below. Use the spending chart to review where you’re money is going and ensure your spending choices are aligned to your values.</MyMoneyTabDescrip>
                    <MyMoneyFormWrapper>
                        <MyMoneySectionTitle center={true}>
                            <BtnCenter>
                                {/*<DropdownInputMyMoney*/}
                                {/*    className="dropdownContainer"*/}
                                {/*    options={planOptions}*/}
                                {/*    id={'timeZone'}*/}
                                {/*    onChange={this.handleChangeActivePlan.bind(this)}*/}
                                {/*    placeholder={'This Month'}*/}
                                {/*    value={this.props.activePlanId}*/}
                                {/*/>*/}
                                {/* <SubmitButtonMyMoney
                                    iconComponent={CloudUploadOutlined}
                                    backgroundColor={colors.progress100}
                                    color={colors.white}
                                    label={this.props.unsaved ? 'Save' : 'Saved'}
                                    onClick={this.handleSave.bind(this)}
                                    disabled={!this.props.unsaved}
                                /> */}
                                {/*{this.props.unsaved && this.state.eligableForFte && <FteTooltip*/}
                                {/*    title="Don't Forget To Save"*/}
                                {/*    text="Saved changes will be here next time you come to Willow and will be accessible to your coach"*/}
                                {/*    onClose={this.handleCloseSaveTooltip.bind(this)}*/}
                                {/*/>}*/}
                            </BtnCenter>
                            {this.state.unsavedChanges !== null && <span>{this.state.savedTimeFromNow}</span>}
                        </MyMoneySectionTitle>
                        {this.renderCard.call(this, { cid: 'INCOME', title: 'Income', lineItems: activePlan.income, backgroundColor: colors.backgroundColor3 })}
                        {activePlan.expenses.map((cat, i) => this.renderCard.call(this, {...cat, color: colors.graphColors[i % colors.graphColors.length]}))}
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>
                            <IconButton
                                iconComponent={PlusOutlined}
                                backgroundColor={colors.white}
                                borderColor={colors.primary100}
                                color={colors.primary100}
                                label="Add Category"
                                size={42}
                                onClick={this.handleAddCategory.bind(this)}
                            />
                        </div>
                        {this.renderTotal.call(this, { lineItems: totals })}
                    </MyMoneyFormWrapper>
                    <MyMoneyGraphWrapper>
                        <Sunburst data={this.buildSunburstData(activePlan)} />
                    </MyMoneyGraphWrapper>
                </MyMoneyTab>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        activePlanId: state.common.mymoney.getIn([ 'spendingPlan', 'activePlan' ]),
        plans: state.common.mymoney.getIn([ 'spendingPlan', 'plans' ]).toJS(),
        unsaved: state.common.mymoney.getIn([ 'spendingPlan', 'unsaved' ]),
        client: state.enterprise.clientsDetail.get("client")
    };
};
const mapDispatchToProps = dispatch => ({
    setMymoneySpendingActiveplan: (pid) => dispatch(setMymoneySpendingActiveplan(pid)),
    setMymoneySpendingNewplan: (title) => dispatch(setMymoneySpendingNewplan(title)),
    setMymoneySpendingAddcategory: () => dispatch(setMymoneySpendingAddcategory()),
    setMymoneySpendingAddlineitem: (cid) => dispatch(setMymoneySpendingAddlineitem(cid)),
    setMymoneySpendingUpdatecategory: (cid, field, val) => dispatch(setMymoneySpendingUpdatecategory(cid, field, val)),
    setMymoneySpendingUpdatelineitem: (cid, i, field, val) => dispatch(setMymoneySpendingUpdatelineitem(cid, i, field, val)),
    setMymoneySpendingDeletelineitem: (cid, lid) => dispatch(setMymoneySpendingDeletelineitem(cid, lid)),
    tryUpdateMyMoneyIncomeStatement:  () => dispatch(tryUpdateMyMoneyIncomeStatement()),
    tryGetMyMoneyIncomeStatement: () => dispatch(tryGetMyMoneyIncomeStatement())
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MoneySpending));