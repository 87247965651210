import {fromJS, Map} from 'immutable';

/* Immutable */

const SMS_IFRAMES_DOWNLOADING = 'SMS_IFRAMES_DOWNLOADING';
const SMS_IFRAMES_SENDING = 'SMS_IFRAMES_SENDING';
const SMS_IFRAMES_SETTINGS_PERSONAL_INFO = 'SMS_IFRAMES_SETTINGS_PERSONAL_INFO';
const SMS_IFRAMES_SETTINGS_PERSONAL_INFO_READ = 'SMS_IFRAMES_SETTINGS_PERSONAL_INFO_READ';
const SMS_IFRAMES_SETTINGS_PERSONAL_INFO_UPDATE = 'SMS_IFRAMES_SETTINGS_PERSONAL_INFO_UPDATE';
const SMS_IFRAMES_STORE_RESET = 'SMS_IFRAMES_STORE_RESET';

/* Actions */

export const setMessagesDownloading = (status) => ({'type': SMS_IFRAMES_DOWNLOADING, 'status': status});
export const setMessagesSending = (status) => ({'type': SMS_IFRAMES_SENDING, 'status': status});
export const setMessagesSettingsPersonalInfo = (personalInfo) => ({'type': SMS_IFRAMES_SETTINGS_PERSONAL_INFO, 'personalInfo': personalInfo});
export const setMessagesSettingsPersonalInfoReading = (status) => ({'type': SMS_IFRAMES_SETTINGS_PERSONAL_INFO_READ, 'status': status});
export const setMessagesSettingsPersonalInfoUpdating = (status) => ({'type': SMS_IFRAMES_SETTINGS_PERSONAL_INFO_UPDATE, 'status': status});
export const setMessagesReset = () => ({'type': SMS_IFRAMES_STORE_RESET});

/* Initial State */

const initialState = Map({
    isSending: false,
    isDownloading: false,
    settingsPersonalInfo: Map({first: '', last: '', phone: '', email: '', timeZone: '', error: ''}),
    settingsPersonalInfoReading: false,
    settingsPersonalInfoUpdating: false
});

/* Reducer */

const messagesReducerById = (state=initialState, action) => {
    switch (action.type) {
        case SMS_IFRAMES_DOWNLOADING:
            return state.merge({'isDownloading': action.status});
        case SMS_IFRAMES_SENDING:
            return state.merge({'isSending': action.status});
        case SMS_IFRAMES_SETTINGS_PERSONAL_INFO:
            return state.merge({'settingsPersonalInfo': fromJS(action.personalInfo)});
        case SMS_IFRAMES_SETTINGS_PERSONAL_INFO_READ:
            return state.merge({'settingsPersonalInfoReading': action.status});
        case SMS_IFRAMES_SETTINGS_PERSONAL_INFO_UPDATE:
            return state.merge({'settingsPersonalInfoUpdating': action.status});
        case SMS_IFRAMES_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default messagesReducerById;
