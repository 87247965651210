import styled from "styled-components";
import {colors} from "../../../common/components/colors";

export const Container = styled.div`
    ${props => props.entryPoint !== "page"} {
            max-width: 500px;
            width: calc(100% - 120px);
            padding: 0px 60px;
            margin: 60px auto 0px auto;
            color: ${colors.primary100};
            @media screen and (max-width: 1440px) {
                width: calc(100% - 80px);
                ${props => props.bigTop === true} {
                    padding: 0px 40px;
                }
                ${props => props.bigTop !== true} {
                   
                }
            }
            @media screen and (max-width: 768px) {
                width: calc(100% - 40px);
                ${props => props.bigTop === true} {
                    padding: 0px 20px;
                }
                ${props => props.bigTop !== true} {
                   
                }
            }
            @media screen and (max-width: 460px) {
                margin: 20px auto 0px auto;
                width: calc(100% - 20px);
                ${props => props.bigTop === true} {
                    padding: 0px 10px;
                }
                ${props => props.bigTop !== true} {
                   
                }
            }
    }
`;
