import React from "react";
import {connect} from "react-redux";

/* Components */
import {FontHeader16} from "../../../common/components/fonts";
import {NavTopMobile, MenuIcon} from '../components/secondarymobile';

/* Store */
import {setNavPageStatus} from "../../../common/containers/navexpanded/store/nav";

const NavSecondaryMobile = ({ pageStatusActive, setNavPageStatus }) => (
    <NavTopMobile onClick={() => {setNavPageStatus(!pageStatusActive)}}>
        <MenuIcon>
            <FontHeader16>Menu</FontHeader16>
        </MenuIcon>
    </NavTopMobile>
);

const mapStateToProps = state => ({
    pageStatusActive: state.common.nav.get('pageStatusActive')
});

const mapDispatchToProps = dispatch => ({
    setNavPageStatus: (status) => dispatch(setNavPageStatus(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(NavSecondaryMobile);
