import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import styled from 'styled-components';

/* Components */
import {ReadOnlyInput, TextInput} from "../../../common/components/inputs";
import {SavedSettings} from '../../../common/components/cards';
import {FontBody16, FontTitle18} from '../../../common/components/fonts';
import {ButtonSecondary, ButtonTertiary} from "../../../common/components/buttons";
import {LogoLoading} from "../../../common/components/loading";
import {FitContent, InlineBlock, SaveSection, SubmitInputWithValidation} from "../components/myprofile";

/* Middleware */
import {tryUpdateExpertProfile} from '../middleware/expertprofile';
import {EditOutlined} from "@ant-design/icons";

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

class ProfileLocation extends React.Component {
    state = {
        address1: '', address1Valid: true, address1Previous: "",
        address2: '', address2Valid: true, address2Previous: "",
        city: '', cityValid: true, cityPrevious: "",
        state: '', stateValid: true, statePrevious: "",
        zipCode: '', zipCodeValid: true, zipCodePrevious: "",
        isSaving: false,
        isSaved: false,
        error: '',
        isValid: true,
        isChanged: false,
        isLoading: true,
        isEdit: false
    };

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    init = async () => {
        await this.setState({
            "address1": this.props.profile.get("address1"),
            "address2": this.props.profile.get("address2"),
            "city": this.props.profile.get("city"),
            "state": this.props.profile.get("state"),
            "zipCode": this.props.profile.get("zipCode"),
            "address1Previous": this.props.profile.get("address1"),
            "address2Previous": this.props.profile.get("address2"),
            "cityPrevious": this.props.profile.get("city"),
            "statePrevious": this.props.profile.get("state"),
            "zipCodePrevious": this.props.profile.get("zipCode"),
            "isLoading": false
        });
    };

    handleChangeAddress1 = async e => {
        await this.setState({'address1': e.target.value, 'address1Valid': (!validator.isEmpty(e.target.value) && e.target.value !== ''), 'isChanged': true});
        // this.checkValid();
    };

    handleChangeAddress2 = async e => {
        await this.setState({'address2': e.target.value, 'address2Valid': (!validator.isEmpty(e.target.value) && e.target.value !== ''), 'isChanged': true});
        // this.checkValid();
    };

    handleChangeCity = async e => {
        await this.setState({'city': e.target.value, 'cityValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''), 'isChanged': true});
        // this.checkValid();
    };

    handleChangeState = async e => {
        await this.setState({'state': e.target.value, 'stateValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''), 'isChanged': true});
        // this.checkValid();
    };

    handleChangeZipCode = async e => {
        await this.setState({'zipCode': e.target.value, 'zipCodeValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''), 'isChanged': true});
        // this.checkValid();
    };

    checkValid = async () => {
        if(this.state.address1Valid && this.state.cityValid && this.state.stateValid && this.state.zipCodeValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    reset = async () => {
        this.setState({
            "address1": this.state.address1Previous,
            "address2": this.state.address2Previous,
            "city": this.state.cityPrevious,
            "state": this.state.statePrevious,
            "zipCode": this.state.zipCodePrevious,
            "isChanged": false,
            "isEdit": false
        });
    };

    edit = async (status) => {
        this.setState({"isEdit": status});
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            await this.setState({'isSaving': true, "error": ""});
            const address = {
                "address1": this.state.address1,
                "address2": this.state.address2,
                "city": this.state.city,
                "state": this.state.state,
                "zipCode": this.state.zipCode,
            };
            await this.props.tryUpdateExpertProfile(address, "profileLocationError");
            if(this.props.profile.get("profileLocationError") === "") {
                this.setState({
                    'isSaving': false,
                    "isSaved": true,
                    "address1Previous": this.state.address1,
                    "address2Previous": this.state.address2,
                    "cityPrevious": this.state.city,
                    "statePrevious": this.state.state,
                    "zipCodePrevious": this.state.zipCode,
                    "isEdit": false
                });
                this.timeout = setTimeout(() => {this.setState({'isSaved': false, "isChanged": false})}, 3000);
            } else {
                this.setState({'isSaved': true, 'isSaving': false, "error": this.props.profile.get("profileLocationError")});
                this.timeout = setTimeout(() => {this.setState({'isSaved': false, "error": ""})}, 3000);
            }
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    {this.state.isSaved
                        ?
                            <>
                                {this.state.error === '' && <SavedSettings height={"99px"} error={false} message={"Saved"}/>}
                                {this.state.error !== '' && <SavedSettings height={"99px"} error={true} message={this.state.error}/>}
                            </>
                        :
                        <>
                            {!this.state.isEdit &&
                                <>
                                    <FontTitle18>Location</FontTitle18>
                                    <ReadOnlyInput
                                        title={"Address 1"}
                                        value={this.state.address1}
                                    />
                                    <ReadOnlyInput
                                        title={"Address 2"}
                                        value={this.state.address2}
                                    />
                                    <ReadOnlyInput
                                        title={"City"}
                                        value={this.state.city}
                                    />
                                    <ReadOnlyInput
                                        title={"State"}
                                        value={this.state.state}
                                    />
                                    <ReadOnlyInput
                                        title={"Zip Code"}
                                        value={this.state.zipCode}
                                    />
                                    <br/>
                                    <FitContent><FontBody16 onClick={() => {return this.edit(true)}}><ButtonSecondary canSubmit={false} label={<><EditOutlined/>&nbsp;Change Location</>} /></FontBody16></FitContent>
                                </>
                            }
                            {this.state.isEdit &&
                                <form method={"post"} onSubmit={this.handleSubmit}>
                                    <div>
                                        <FontTitle18>Location</FontTitle18>
                                        <TextInput
                                            title={"Address 1"}
                                            valid={this.state.address1Valid}
                                            warning={"Required"}
                                            id={"address1"}
                                            onChange={this.handleChangeAddress1}
                                            placeholder={"Address 1"}
                                            value={this.state.address1}
                                        />
                                        <TextInput
                                            title={"Address 2"}
                                            valid={this.state.address2Valid}
                                            warning={""}
                                            id={"address2"}
                                            onChange={this.handleChangeAddress2}
                                            placeholder={"Address 2"}
                                            value={this.state.address2}
                                        />
                                        <TextInput
                                            title={"City"}
                                            valid={this.state.cityValid}
                                            warning={"Required"}
                                            id={"city"}
                                            onChange={this.handleChangeCity}
                                            placeholder={"City"}
                                            value={this.state.city}
                                        />
                                        <TextInput
                                            title={"State"}
                                            valid={this.state.stateValid}
                                            warning={"Required"}
                                            id={"state"}
                                            onChange={this.handleChangeState}
                                            placeholder={"State"}
                                            value={this.state.state}
                                        />
                                        <TextInput
                                            title={"Zip Code"}
                                            valid={this.state.zipCodeValid}
                                            warning={"Required"}
                                            id={"zipCode"}
                                            onChange={this.handleChangeZipCode}
                                            placeholder={"Zip Code"}
                                            value={this.state.zipCode}
                                        />
                                    </div>
                                    <SaveSection>
                                        <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                        <InlineBlock><div onClick={this.reset}><ButtonTertiary canSubmit={false} label={"Cancel"} /></div></InlineBlock>
                                    </SaveSection>
                                </form>
                            }
                        </>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    profile: state.enterprise.shop.expertProfile.get("expertProfile")
});

const mapDispatchToProps = dispatch => ({
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileLocation);
