import React, {useState} from 'react';
import styled, {keyframes, css} from 'styled-components';
import validator from "validator";

/* Components */
import {colors} from "../../../common/components/colors";
import {FontBody14, FontBody24, FontBody16} from '../../../common/components/fonts';
import ContactButtons from './contactbuttons'
import {ContactTitle} from "./components";
import {ArrowLeftOutlined} from "@ant-design/icons";

const SignupFlowContainer = styled.div`
  perspective: 1000px;
  margin-top: 30px;
  background-color:transparent;

  animation: ${props =>
          props.animate ?
                  css`
    ${nextPage} 1s ease-in-out` : css`${backPage} 1s ease-in-out`};
  & > div:nth-child(2) {
    border-radius: 0 0 20px 20px !important;
    background-color: white !important;
    color: black;
  }

  & .disclosureLabel, .disclosureLabel a {
    color: black !important;
  }
  
  //& .password-input {
  //  display:none;
  //}

  position: relative;

  & .prevButton {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0;
  }

`;

const nextPage = keyframes`
  0% {
    opacity: 0;
    transform:rotateY(180deg);
  }
  100% {
    opacity: 1;
    transform:rotateY(0deg);
  }
`;

const backPage = keyframes`
  0% {
    opacity: 0;
    transform:rotateY(180deg);
  }
  100% {
    opacity: 1;
    transform:rotateY(0deg);
  }
`;

const Show = styled.div`
  display: ${({toggle}) => toggle ? 'flex' : 'none'};
  flex-direction: column;
  gap: 20px;
`;
const PreviewWhite = styled(FontBody16)`
  color: rgba(255, 255, 255, 0.5) !important;
  width: fit-content;
  line-height: 1.5;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  & strong {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  @media screen and (max-width: 1680px) {
    font-size: 20px !important;
  }
  @media screen and (max-width: 748px) {
    font-size: 16px !important;
  }
  margin-top: 30px;
`;
const Icon = styled.div`
  display: inline-block;
  span {
    font-size: 16px;
    padding: 7px 0;
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
  }
  & path {
    stroke: rgba(255, 255, 255, 0.5) !important;
  }
`;
const Container = styled.form`
    max-width: 768px;
    width: calc(100% - 20px);
    padding: 0 10px;
    margin: 0 auto 0 auto;
`;
const EmailInput= styled.input`
    background-color: ${colors.border70};
    border: 1px solid ${colors.primary30};
    color: ${colors.primary100};
    border-radius: 4px;
    width: calc(100% - 40px);
    padding: 10px 20px;
    font-size: 16px;
    line-height: 22px;
    &::placeholder {
        color: ${colors.primary70};
    }
`;
const Warning = styled(FontBody14)`
  margin: 10px 0 0 22px;
  color: white;
`;
const Instructions = styled(FontBody24)`
  margin: 0 0 30px 0;
  color: white;
`;

ContactPage2.defaultProps = {
    show: false,
    onSubmit: () => {},
    onBack: () => {},
}

export default function ContactPage2(props) {
    const show = props.show;
    const onSubmit = props.onSubmit;
    const onBack = props.onBack;
    const [email, setEmail] = useState("");
    const [emailValid, setEmailValid] = useState(true);

    const onChangeEmail = (e) => {
        setEmailValid(true);
        setEmail(e.target.value);
    }

    const onSubmitEmail = (e) => {
        e.preventDefault();
        if(validator.isEmail(email)) {
            onSubmit(email);
        } else {
            setEmailValid(false);
        }
    }

    return(
        <Show toggle={show}>
            <ContactTitle color={colors.white}>Confirm Your Time</ContactTitle>
            <SignupFlowContainer animate={show}>
                <Container
                    onSubmit={onSubmitEmail}
                    style={{backgroundColor: colors.backgroundColor2, borderRadius: "20px", padding: "30px 45px", width: "calc(100% - 90px)"}}
                >
                    <Instructions>Where can we reach you?</Instructions>
                    <EmailInput
                        id={"email"}
                        onChange={onChangeEmail}
                        placeholder={"Email"}
                        value={email}
                        style={{borderRadius: "25px", lineHeight: "36px", backgroundColor: colors.white}}
                    />
                    <Warning>{!emailValid ? "Invalid email" : <div>&nbsp;</div>}</Warning>
                    <ContactButtons />
                </Container>
            </SignupFlowContainer>
            <PreviewWhite onClick={onBack}><Icon><ArrowLeftOutlined/></Icon>Change time</PreviewWhite>
        </Show>

    );
}