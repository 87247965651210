import React from 'react';

/* Components */
import {Right, Button, Cost} from '../components/register';
import {ButtonPrimary, ButtonInactive} from '../../../common/components/buttons';
import {FontBody18} from "../../../common/components/fonts";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

class IframeMeetingPageBody extends React.Component {
    render() {
        let d = this.props.data;
        console.log(d)
        return(
            <Right>
                <Cost><FontBody18>{d.get("ticketCost") === 0 ? "Free" : ("$" + d.get("ticketCost"))}</FontBody18></Cost>
                {this.props.isPast &&
                    <Button canRegister={false}><ButtonInactive canSubmit={false} label={"Event Completed"} /></Button>
                }
                {this.props.isCanceled &&
                    <Button canRegister={false}><ButtonInactive canSubmit={false} label={"Event Canceled"} /></Button>
                }
                {(this.props.registered || this.props.error === 'User already registered for event') &&
                    <>
                        {this.props.isOngoing
                            ? <Button canRegister={false} onClick={() => {window.location.replace(this.props.joinUrl)}}><ButtonPrimary canSubmit={false} label={"Join!"} /></Button>
                            : <Button canRegister={false}><ButtonInactive canSubmit={false} label={"Already registered!"} /></Button>
                        }
                    </>
                }
                {!(this.props.isPast || this.props.isCanceled || this.props.registered || this.props.error === 'User already registered for event') &&
                    <Button canRegister={true} onClick={this.props.canRegister ? () => {return this.props.register()} : () => {window.open("https://"+env.URL.DOMAIN+"/webinars/"+d.get("expert").get("publicHandle")+"/"+d.get("id"), "_blank")}}>
                        <ButtonPrimary canSubmit={false} label={"Register"} />
                    </Button>
                }
            </Right>
        )
    }
}

export default IframeMeetingPageBody;