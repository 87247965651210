import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setTeamActivityAddRecord} from "../../store/clientsactivity";
import {setCommentsGetting, setCommentSelected, setCommentModal, setCommentPosting,} from "../store/comments";

/* Static */
import {devEnterpriseTeamCommentsData, devEnterpriseTeamNotesComment} from '../static/devdata';

export const tryGetEnterpriseComments = (noteId) => {
    return async (dispatch) => {
        dispatch(setCommentsGetting(true));
        const activity = await axiosReq('apiv1/notes/'+noteId+'/comments', "GET", {}, devEnterpriseTeamCommentsData);
        dispatch(setCommentsGetting(false));
        return activity;
    }
};

export const shouldCommentNote = (note) => {
    return async (dispatch) => {
        if(note === null) {
            await dispatch(setCommentSelected({"noteId": '', "body": '', "datetime": '', "comment": false}));
            dispatch(setCommentModal(true));
        } else {
            await dispatch(setCommentSelected(note));
            dispatch(setCommentModal(true));
            return true;
        }
    }
};

export const tryPostComment = (noteId, content) => {
    return async dispatch => {
        dispatch(setCommentPosting(true));
        const commentAdd = await axiosReq('apiv1/notes/'+noteId+"/comments", "POST", {"content": content}, devEnterpriseTeamNotesComment(content));
        dispatch(setTeamActivityAddRecord({...commentAdd}));
        dispatch(setCommentPosting(false));
        return await dispatch(setCommentModal(false));
        
    }
};
