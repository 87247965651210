import React from "react";
import styled from "styled-components";

/* Components */
import {colors} from "../../common/components/colors";
import {FontBody16, FontTitle24} from "../../common/components/fonts";

export const StyledBoard = styled.div`
    .react-trello-board {
        background-color: ${colors.white};
        padding: 0px;
        height: calc(100vh - 330px);
        @media screen and (max-width: 460px) {
            height: 500px;
        }
    }
    .react-trello-lane {
        padding: 0px;
        border-radius: 0px;
        border: 1px solid ${colors.background30};
        background-color: ${colors.background15};
        margin: 0px -1px 0px 0px;
        height: 100%;
    }
    .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
        overflow: visible;
    }
    .smooth-dnd-container.vertical {
        height: calc(100vh - 422px);
        cursor: default !important;
        @media screen and (max-width: 460px) {
            height: 418px;
        }
    }
    .react-trello-card {
        margin: 4px;
        border: 1px solid ${colors.background30};
        background-color: ${colors.white};
        font-family: GraphicWebRegular;
        font-size: 16px;
        &:hover {
            -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
        }
        &:active {
            cursor: grab !important;
        }
    }
    .react-trello-card header {
        padding: 0px;
        border-bottom: none;
        color: ${colors.action100};
        margin-bottom: 5px;
        &:hover {
            text-decoration: underline;
            text-decoration-color: ${colors.action100};
        }
        & span {
            cursor: pointer;
        }
    }
`;

const Modal = styled.div`
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    padding: 30px;
    border-radius: 4px;
    background-color: ${colors.white};
    -webkit-box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    max-width: 300px;
    width: calc(100% - 80px);
    z-index: 20;
`;
const Title = styled.div`
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.background130};
    margin-bottom: 15px;
`;

export const Notice = ({title, body}) => (
    <Modal>
        <Title><FontTitle24>{title}</FontTitle24></Title>
        <FontBody16>{body}</FontBody16>
    </Modal>
);

export const FilterSection = styled.div`
    margin: 20px 0px;
`;

export const OneFilter = styled.div`
    margin: 5px 20px 5px 0px;
    display: inline-block;
`;