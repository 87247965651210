import { Map, List, fromJS } from 'immutable';

/* Immutable */

const IFRAME_WEBINARS_ALL = 'IFRAME_WEBINARS_ALL';
const IFRAME_WEBINARS_ALL_FETCHING = 'IFRAME_WEBINARS_ALL_FETCHING';
const IFRAME_WEBINARS_STORE_RESET = 'IFRAME_WEBINARS_STORE_RESET';
const IFRAME_WEBINAR_REGISTERING = 'IFRAME_WEBINAR_REGISTERING';
const IFRAME_WEBINAR_REGISTRATION_FETCHING = 'IFRAME_WEBINAR_REGISTRATION_FETCHING';

/* Actions */

export const setWebinarsAll = (webinars) => ({"type": IFRAME_WEBINARS_ALL, 'webinars': webinars});
export const setWebinarsAllFetching = (status) => ({"type": IFRAME_WEBINARS_ALL_FETCHING, 'status': status});
export const setWebinarsStoreReset = () => ({"type": IFRAME_WEBINARS_STORE_RESET});
export const setWebinarRegistering = (status) => ({"type": IFRAME_WEBINAR_REGISTERING, 'status': status});
export const setWebinarRegistrationFetching = (status) => ({'type': IFRAME_WEBINAR_REGISTRATION_FETCHING, 'status': status})


/* Initial State */

const initialState = Map({
    webinarsAll: List(),
    webinarsAllFetching: false,
    webinarRegistering: false,
    webinarRegistrationFetching: false
});

/* Reducer */

const webinarsReducer = (state=initialState, action) => {
    switch (action.type) {
        case IFRAME_WEBINARS_ALL:
            return state.merge({'webinarsAll': fromJS(action.webinars)});
        case IFRAME_WEBINARS_ALL_FETCHING:
            return state.merge({'webinarsFetching': action.status});
        case IFRAME_WEBINAR_REGISTERING:
            return state.merge({'webinarRegistering': action.status});
        case IFRAME_WEBINAR_REGISTRATION_FETCHING:
            return state.merge({'webinarRegistrationFetching': action.status});
        case IFRAME_WEBINARS_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default webinarsReducer;
