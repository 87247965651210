import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {colors} from "../../../common/components/colors";

/* Containers */
import ContactOptions from "./intro/profilecontactme";
import ProfileLinks from "./intro/profilelinks";
import ProfilePhoto from "./intro/profilephoto";
import ProfileHero from "./intro/profilehero";
import ProfileName from "./intro/profilename";
import ProfileHighlights from "./intro/profilehighlights";
import SideBarContactInput from "./intro/sidebarcontactinput";

/* Middleware */
import {tryGetExpertProfile} from '../middleware/expertprofile';

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

const Border = styled.div`
    height: 25px;
    border-bottom: 1px solid ${colors.primary30};
    margin-bottom: 40px;
`;

class ShopPageHome extends React.Component {
    state = {"isLoading": true};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.props.tryGetExpertProfile();
        this.setState({"isLoading": false});
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <br />
                    <ProfilePhoto />
                    <Border />
                    <ProfileName />
                    <Border />
                    <ProfileHighlights />
                    <Border />
                    <ProfileHero />
                    <Border />
                    <ProfileLinks />
                    <Border />
                    <ContactOptions
                        emailerKey={"profileShowEmailer"}
                        schedulerKey={"profileShowScheduler"}
                        errorKey={"profileContactError"}
                    />
                    <Border />
                    <SideBarContactInput />
                </>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryGetExpertProfile: () => dispatch(tryGetExpertProfile()),
});

export default connect(null, mapDispatchToProps)(ShopPageHome);
