import React from "react";
import {connect} from 'react-redux';
import styled from "styled-components";
import {fromJS} from 'immutable';
import {v4 as uuid4} from 'uuid';
import {withRouter} from 'react-router-dom';

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {colors} from "../../../common/components/colors";
import BackBtn from "../components/back";

/* Containers */
import MemberOnePageHeader from "./memberheader";
import MyCare from './mycare';
import SharedDrive from './sharedrive';
import ProfileAbout from './profile';
import MemberAppointmentOptions from './memberappointmentoptions';
import SMSById from '../../../iframes/smsbyid/containers/smscompose';

/* Middleware */
import {tryGetCombinedProducts} from "../middleware/products";
import {tryGetExpertProfileFromToken} from '../../../iframes/profile/middleware/profile';
import {tryGetTeamMemberMeetings} from "../../appointments/middleware/meetings";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const Container = styled.div`
    width: calc(100% - 120px);
    padding: 90px 60px 120px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 210px);
    background-color: ${colors.white};
    align-items: flex-start;
    @media screen and (max-width: 750px) {
        max-width: 650px;
        width: 100%;
        padding: 0;
    }
`;
const ContainerCentered = styled.div`
    width: calc(100% - 120px);
    padding: 90px 60px 120px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 210px);
    background-color: ${colors.white};
    align-items: center;
    @media screen and (max-width: 750px) {
        max-width: 650px;
        width: 100%;
        padding: 0;
    }
`;
const ProfileContainer = styled.div`
    border-radius: 30px;
    background-color: ${colors.backgroundColor1};
    justify-content: center;
    padding: 35px;
    align-self: center;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.12);
    width: calc(100% - 70px);
    @media screen and (max-width: 460px) {
        padding: 25px;
        width: calc(100% - 50px);
    }
    & > div {
      @media screen and (min-width: 450px) {
        padding: 20px 0 35px 0;
      }
      @media screen and (max-width: 450px) {
        padding: 0 0 35px 0;
      }
    } 
`;
const SharedDocsContainer = styled.div`
    border-radius: 30px;
    background-color: ${colors.backgroundColor1};
    justify-content: center;
    padding: 35px;
    align-self: center;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.12);
    width: calc(100% - 70px);
    @media screen and (max-width: 460px) {
        padding: 25px;
        width: calc(100% - 50px);
    }
    & > div {
        margin-top: 0;
        box-shadow: none;
        @media screen and (min-width: 450px) {
            padding: 20px 0 35px 0;
        }
        @media screen and (max-width: 450px) {
            padding: 0 0 35px 0;
        }
    }
`;
const OverviewContainer = styled.div`
    border-radius: 30px;
    background-color: ${colors.backgroundColor1};
    justify-content: center;
    padding: 35px;
    align-self: center;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.12);
    width: calc(100% - 70px);
    @media screen and (max-width: 460px) {
        padding: 25px;
        width: calc(100% - 50px);
    }
    & > div > div {
        margin-top: 0;
        box-shadow: none;
        margin-bottom: 0 !important;
        @media screen and (min-width: 450px) {
            padding: 0 0 35px 0;
        }
        @media screen and (max-width: 450px) {
            padding: 0 0 35px 0;
        }
    }
`;
const SMSContainer = styled.div`
  margin: 50px 24px 0 24px;
  width: calc(100% - 40px);
  padding: 0 20px;
  @media screen and (max-width: 748px) {
    margin: 50px 0 0 0;
  }
`;

class MemberOnePage extends React.Component {
    state = {
        "isLoading": true,
        "allProducts": [],
        "allSessionProducts": [],
        "sessionsSchedulable": [],
        "profile": null,
        "memberCategory": null,
        "elementId": uuid4(),
        "hasNoSessionProducts": false,
        "isNewCoach": false,
        "hasUpcomingTrialCall": false
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.meetingsChanged !== this.props.meetingsChanged) {
            return this.init();
        }
    }

    init = async () => {
        const coachId = window.location.pathname.split("/")[2];
        const profile = await this.props.tryGetExpertProfileFromToken(coachId);
        await this.props.tryGetTeamMemberMeetings();
        const combinedProducts = await this.props.tryGetCombinedProducts(this.props.referralType, parseInt(coachId), this.props.meetings);
        const sessionsSchedulable = combinedProducts.allSessionProducts.filter(s => s.sessionsSchedulable > 0)
        const hasUpcomingTrialCall = this.props.meetings.filter(m => m.get("meetingIsTrial")).size > 0
        const memberCategory = await env.CONSUMER.CARE.PRINT_EXPERT_CATEGORIES(profile.expertCategories, true);
        const isNewCoach = await this.checkIsNewCoach(coachId);

        await this.setState({
            ...combinedProducts,
            "sessionsSchedulable": sessionsSchedulable,
            "hasUpcomingTrialCall": hasUpcomingTrialCall,
            'memberCategory': memberCategory,
            "isNewCoach": isNewCoach,
            "profile": profile,
            "isLoading": false
        });
        console.log("memberonepageafterinit", this.state)
        console.log(this.state.hasNoSessionProducts && !this.state.hasUpcomingTrialCall)
        console.log("allproducts", this.state.allProducts)
        console.log("allSessionProducts", this.state.allSessionProducts)
    };

    checkIsNewCoach = async (coachId) => {
        const members = await this.props.members.filter(m => {
            if(m.get("coachId").toString() === coachId.toString()) {
                return(m)
            }
        });
        return(members.size === 0)
    };

    render() {
        if (this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            if(this.props.location.pathname.includes("appointment")) {
                return(
                    <ContainerCentered>
                        <BackBtn onBack={() => {this.props.history.push("/my-team/"+this.state.profile.id)}} />
                        <MemberAppointmentOptions
                            coach={this.state.profile}
                            category={this.state.memberCategory}
                            products={this.state.allSessionProducts}
                            isNewCoach={this.state.isNewCoach}
                            bookTrialCall={this.state.hasNoSessionProducts && !this.state.hasUpcomingTrialCall}
                            onSchedule={this.init}
                        />
                    </ContainerCentered>
                )
            } else if(this.props.location.pathname.includes("messages")) {
                return(
                    <ContainerCentered>
                        <BackBtn onBack={() => {this.props.history.goBack()}} />
                        <SMSContainer>
                            <SMSById
                                showClose={false}
                                viewInBar={false}
                                recipientId={this.state.profile.id}
                                recipientFirstName={this.state.profile.firstName}
                                recipientLastName={this.state.profile.lastName}
                                from={"consumer"}
                                to={"coach"}
                            />
                        </SMSContainer>
                    </ContainerCentered>
                )
            } else {
                return(
                    <Container>
                        <BackBtn onBack={() => {this.props.history.push("/my-team")}} />
                        <MemberOnePageHeader
                            profile={this.state.profile}
                            memberCategory={this.state.memberCategory}
                            sessionsSchedulable={this.state.sessionsSchedulable.length > 0}
                            hasNoSessionProducts={this.state.hasNoSessionProducts}
                            hasNoUpcomingTrialCall={!this.state.hasUpcomingTrialCall}
                            isNew={this.state.isNewCoach}
                        />
                        {(this.props.location.pathname.includes("documents") && !this.state.isNewCoach) &&
                            <SharedDocsContainer>
                                <SharedDrive
                                    member={this.state.profile}
                                    fullPage={true}
                                />
                            </SharedDocsContainer>
                        }
                        {(this.props.location.pathname.includes("profile") || this.state.isNewCoach) &&
                            <ProfileContainer>
                                <ProfileAbout
                                    member={fromJS(this.state.profile)}
                                />
                            </ProfileContainer>
                        }
                        {(!this.props.location.pathname.includes("documents") && !this.props.location.pathname.includes("profile") && !this.state.isNewCoach) &&
                            <OverviewContainer>
                                <MyCare
                                    member={this.state.profile}
                                    products={this.state.allProducts}
                                    parentId={this.state.elementId}
                                    showShop={() => {window.location.href = 'https://pay.trustwillow.com/b/8wM15u7Iy4nD0Lu8wR?client_reference_id='+this.props.userId}}
                                    onReschedule={this.init}
                                    onCancel={this.init}
                                />
                            </OverviewContainer>
                        }
                    </Container>
                )
            }
        }
    }
}

const mapStateToProps = state => ({
    referralType: state.common.user.get("referralType"),
    meetingsChanged: state.consumer.appointments.meetings.get("meetingsChanged"),
    members: state.consumer.team.team.get("members"),
    meetings: state.consumer.appointments.meetings.get("meetings"),
    userId: state.common.user.get("userId"),
});

const mapDispatchToProps = dispatch => ({
    tryGetCombinedProducts: (referralType, coachId) => dispatch(tryGetCombinedProducts(referralType, coachId)),
    tryGetExpertProfileFromToken: (id) => dispatch(tryGetExpertProfileFromToken(id)),
    tryGetTeamMemberMeetings: () => dispatch(tryGetTeamMemberMeetings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MemberOnePage));
