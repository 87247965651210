import React from 'react';

const BillingCancel = () => (
    <>
        Cancel
    </>
);

export default BillingCancel;

// showCancel