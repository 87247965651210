import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment/moment';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Map } from 'immutable';

/* Components */
import Appointment from '../components/appointmentv3';
import {FontTitle24, FontTitle18} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";
import {LogoLoading} from "../../../common/components/loading";
import {ButtonPrimary, ButtonSecondary, ButtonTertiary} from '../../../common/components/buttons';

/* Middleware */
import {tryGetAllAppointments, tryJoinWaitlist} from '../middleware/appointment';
import {tryGetInsightSubCategories} from '../../../consumer/learn/middleware/insights';
import {insightParentCategorySelected, insightSubCategorySelected} from '../../../consumer/learn/store/insights';
import Success from '../../success/containers/success';

const Container = styled.div`
    width: calc(100% - 60px);
    padding: 30px;
    @media screen and (max-width: 748px) {
        padding: 0px;
        width: 100%;
    }
    margin: 0px auto;
    border-radius: 25px 25px 0 0;
`;
const Relative = styled.div`
  position: relative;
`;
const Modal = styled.div`
    position: absolute;
    top: 320px;
    text-align: center;
    width: calc(100% - 80px);
    margin: 0px auto;
    max-width: 400px;
    left: 0;
    right: 0;
    z-index: 21;
    padding: 20px 40px;
    background-color: ${colors.white};
    color: ${colors.primary100}; 
    border-radius: 6px;
    -webkit-box-shadow: 0px 15px 70px 0px rgba(0, 0, 0, 0.7);
    box-shadow: 0px 15px 70px 0px rgba(0, 0, 0, 0.7);
    @media screen and (max-width: 640px) {
        top: 200px;
        max-width: 185px !important;
    }
`;
const LoadingCenter = styled.div`
    margin: 60px auto;
`;
const ButtonCentered = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    width: fit-content;
`;
const Subtitle = styled.div`
    max-width: 80%;
    margin: 0px auto;
`;

class AppointmentContainer extends React.Component {
    static defaultProps = {
        title: "",
        subtitle: "",
        coachId: null,
        coachFirst: null,
        coachLast: null,
        coachImg: null,
        onSelectTime: () => {},
        meetingLength: null,
        error: null,
        findCoach: () => {},
        viewContent: () => {},
        allowTimeToBeSelected: true,
        calendarOnly: false
    };

    state = {
        selectedDay: null,
        selectedTime: null,
        availableDays: [],
        availableTimesToday: [],
        sessions: [],
        isLoading: true,
        coachId: null,
        coachFirst: null,
        coachLast: null,
        coachImg: null,
        meetingLength: null,
        error: null,
        joinedWaitlist: false
    };

    componentDidMount() {
        if(this.props.error !== null) {
            this.showError(this.props.error);
        }
        return this.tryGetAllAvailableTimes();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.error !== this.props.error && this.props.error !== null) {
            this.showError(this.props.error);
        }
    }

    tryGetAllAvailableTimes = async () => {
        await this.props.tryGetAllAppointments(this.props.coachId, this.props.meetingLength);
        const appointments = await this.props.appointments.get("sessions").map(a => {
            return new Date(moment(a).toString())
        }).toJS();
        console.log(appointments)
        const times = await this.tryGetAvailableTimesToday(appointments, appointments[0]);
        const days = await this.tryGetAvailableDays(appointments);
        await this.setState({
            'sessions': appointments,
            'selectedDay': appointments[0],
            'selectedTime': null,
            'availableTimesToday': times,
            'availableDays': days,
            "coachId": (this.props.coachId === null || this.props.coachId === undefined || this.props.coachId === "") ? null : this.props.coachId,
            "coachFirst": (this.props.coachFirst === null || this.props.coachFirst === undefined || this.props.coachFirst === "") ? null : this.props.coachFirst,
            "coachLast": (this.props.coachLast === null || this.props.coachLast === undefined || this.props.coachLast === "") ? null : this.props.coachLast,
            "coachImg": (this.props.coachImg === null || this.props.coachImg === undefined || this.props.coachImg === "") ? null : this.props.coachImg,
            "onSelectTime": this.props.onSelectTime,
            "meetingLength": this.props.meetingLength,
            "isLoading": false
        });
    };

    tryGetAvailableTimesToday = (appointments, selectedDay) => {
        return appointments.filter(s => {
            return moment(s).format("YYYY-MM-DD") === moment(selectedDay).format("YYYY-MM-DD");
        });
    };

    tryGetAvailableDays = (sessions) => {
        return [...new Set(sessions.map(s => {
            return moment(s).format("YYYY-MM-DD")
        }))]
    };

    handleColorDays = (days) => {
        if(this.state.availableDays.includes(moment(days).format("YYYY-MM-DD"))) {
            return 'show'
        } else {
            return 'hide'
        }
    };

    handleHideTimes = (time) => {
        if(this.state.sessions.includes(time)) {
            return 'show'
        } else {
            return 'hide'
        }
    };

    handleChangeDay = async date => {
        await this.setState({
            'selectedDay': date,
            'availableTimesToday': await this.tryGetAvailableTimesToday(this.state.sessions, date)
        });
    };

    handleChangeTime = async selected => {
        await this.setState({'selectedTime': selected, 'selectedDay': selected});
        await this.props.onSelectTime(this.state);
    };

    handleSelectDayTime = async selected => {
        await this.setState({'selectedTime': selected});
        this.props.onSelectTime(this.state);
    };

    componentWillUnmount() {
        clearInterval(this.timeout);
    }

    showError = (error) => {
        this.setState({'error': error});
        this.timeout = setTimeout(() => {
            this.setState({'error': null});
        }, 2000);
        return this.tryGetAllAvailableTimes();
    };

    joinWaitlist = async () => {
        await this.props.tryJoinWaitlist(this.state.coachId)
        return this.setState({'joinedWaitlist': true})
    }

    viewRelatedContent = async () => {
        if (this.props.subcategory && this.props.subcategory !== Map() && window.location.pathname.includes('get-care/journey/providers')) {
            await this.props.insightParentCategorySelected(this.props.category)
            await this.props.tryGetInsightSubCategories(this.props.category.get("id"))
            await this.props.insightSubCategorySelected(this.props.subcategory)
            this.props.history.push('/learn/content')
        } else {
            this.props.history.push('/learn')
        }
    }

    findAnotherCoach = async () => {
        if (this.props.user.get("isLily") && this.props.user.get("userId")) {
            if (this.props.subcategory && this.props.subcategory !== Map()) {
                this.props.closeSidebar()
            } else {
                this.props.history.push('/my-team/find')
            }
        } else {
            this.props.history.push('/login')
        }
    }

    render() {
        if(this.state.isLoading) {
            return (<LoadingCenter><LogoLoading/></LoadingCenter>)
        } else {
            return(
                <Container>
                    {
                        !this.state.joinedWaitlist ?
                        <Relative>
                            <Appointment
                                allCoaches={!(this.state.coachFirst === null || this.state.coachLast === null || this.state.coachId === null || this.state.coachImg === null)}
                                expertName={this.state.coachFirst + ' ' + this.state.coachLast}
                                title={this.props.title}
                                selectedDay={this.state.selectedDay}
                                minDate={new Date()}
                                handleChangeDay={this.handleChangeDay}
                                handleColorDays={this.handleColorDays}
                                selectedTime={this.state.selectedTime}
                                handleChangeTime={this.handleChangeTime}
                                availableTimesToday={this.state.availableTimesToday}
                                handleHideTimes={this.handleHideTimes}
                                expertPhoto={this.state.coachImg}
                                subtitle={this.props.subtitle}
                                meetingLength={this.state.meetingLength}
                                showTimes={this.state.sessions.length > 0}
                                allowTimeToBeSelected={this.props.allowTimeToBeSelected}
                                calendarOnly={this.props.calendarOnly}

                                sessions={this.state.sessions}
                                handleSelectDayTime={this.handleSelectDayTime}
                            />
                            {this.state.sessions.length === 0 &&
                                <Modal>
                                    <FontTitle24>Sorry, no times available...</FontTitle24>
                                </Modal>
                            }
                            {/*{this.state.sessions.length === 0 &&*/}
                            {/*    <Modal style={{margin: '0 auto'}}>*/}
                            {/*        <FontTitle24>Sorry, no times available...</FontTitle24>*/}
                            {/*        <br />*/}
                            {/*        {!window.location.pathname.includes('get-care/journey/providers') ?*/}
                            {/*            <div style={{textAlign: 'center', display: 'flex', margin: '0 auto'}}>*/}
                            {/*                <div onClick={() => {this.joinWaitlist()}}>*/}
                            {/*                    <ButtonPrimary canSubmit={false} label={"Join the waitlist"} />*/}
                            {/*                </div>*/}
                            {/*                &nbsp;&nbsp;*/}
                            {/*                <div onClick={() => {this.findAnotherCoach()}}>*/}
                            {/*                    <ButtonPrimary canSubmit={false} label={"Find another coach"} />*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            :*/}
                            {/*            <ButtonCentered onClick={() => {this.joinWaitlist()}}>*/}
                            {/*                <ButtonPrimary canSubmit={false} label={"Join the waitlist"} />*/}
                            {/*            </ButtonCentered>*/}
                            {/*        }*/}
                            {/*        /!* View related content: only if a category has been selected *!/*/}
                            {/*        {*/}
                            {/*            (this.props.subcategory && this.props.subcategory !== Map() && window.location.pathname.includes('get-care/journey/providers')) ?*/}
                            {/*            <>*/}
                            {/*                /!* <br />*/}
                            {/*                <ButtonCentered onClick={() => {this.viewRelatedContent()}}>*/}
                            {/*                    <ButtonSecondary canSubmit={false} label={"View related content"} style={{margin: '0 auto'}}/>*/}
                            {/*                </ButtonCentered> *!/*/}
                            {/*            </>*/}
                            {/*            :*/}
                            {/*            <>  */}
                            {/*                {*/}
                            {/*                    (this.props.user.get("isLily") && this.props.user.get("userId")) &&*/}
                            {/*                    <>*/}
                            {/*                        <br />*/}
                            {/*                        <ButtonCentered onClick={() => {this.viewRelatedContent()}}>*/}
                            {/*                            <ButtonSecondary canSubmit={false} label={"View content"} style={{margin: '0 auto'}}/>*/}
                            {/*                        </ButtonCentered>*/}
                            {/*                    </>*/}
                            {/*                }*/}
                            {/*                */}
                            {/*            </>*/}
                            {/*        }*/}
                            {/*    </Modal>*/}
                            {/*}*/}
                            {this.state.error !== null &&
                                <Modal>
                                    <FontTitle18>{this.state.error}</FontTitle18>
                                </Modal>
                            }
                            </Relative>
                        :

                        <>
                            <Success
                                back={this.props.closeSidebar}
                                title={'THANKS FOR JOINING THE WAITLIST'}
                                subtitle={<Subtitle>We will be in touch soon to get you started with Willow</Subtitle>}
                                buttonLabel={'Close'}
                            />
                        </>
                    }
                    
                </Container>
            )
        }
    }
}

const mapStateToProps = state => ({
    appointments: state.iframe.appointments.appointment.get("appointmentsData"),
    category: state.consumer.care.coaching.get("coachingOptionSelected"),
    subcategory: state.consumer.care.coaching.get("coachingSubCategorySelected"),
    user: state.common.user
});

const mapDispatchToProps = dispatch => ({
    tryGetAllAppointments: (coachId, minutes) => dispatch(tryGetAllAppointments(coachId, minutes)),
    tryJoinWaitlist: (coachId) => dispatch(tryJoinWaitlist(coachId)),
    tryGetInsightSubCategories: (id) => dispatch(tryGetInsightSubCategories(id)),
    insightParentCategorySelected: (category) => dispatch(insightParentCategorySelected(category)),
    insightSubCategorySelected: (category) => dispatch(insightSubCategorySelected(category))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppointmentContainer));


