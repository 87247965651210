import React  from "react";
import styled from "styled-components";

import {colors} from "./colors";

const Input = styled.input`
    background-color: ${colors.border70};
    border: 1px solid ${colors.primary30};
    color: ${colors.primary100};
    border-radius: 4px;
    width: calc(100% - 42px);
    padding: 10px 20px;
    font-family: GraphicWebRegular;
    font-size: 16px;
    line-height: 22px;
    &::placeholder {
        color: ${colors.primary70};
    }
    &:focus {
        outline: none;
    }
`;

const Label = styled.div`
    color: ${colors.secondary100};
    display: inline-block;
`;

const Invalid = styled.div`
    ${props => props.valid !== true} {display: none;}
    ${props => props.valid !== false} {display: inline-block;}
    color: ${colors.alert100};
    margin-left: 10px;
`;

const TextInput = ({title, valid, warning, id, onChange, placeholder, value, disabled, error}) => (
    <>
        <div>
            {(title !== undefined && title !== null  && title !== "") && <Label>{title}</Label>}
            {(warning !== undefined && warning !== null  && warning !== "") && <Invalid valid={valid}>{warning}</Invalid>}
            {(error !== undefined && error !== null  && error !== "") && <Invalid valid={false}>{error}</Invalid>}
        </div>
        <Input id={id} onChange={onChange} placeholder={placeholder} value={value} disabled={disabled} />
    </>
);

export default TextInput;