import React from "react";
import { withRouter } from 'react-router-dom';

/* Components */
import {SmallFeaturedRow, SmallFeaturedRowImage, SmallFeaturedCol, SmallImageContainer} from "../components/care";
import {FontHeader14, FontTitle16} from '../../../common/components/fonts';

class SmallFeatured extends React.Component {
    state = {
        "content": this.props.data
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data != this.props.data) {
            this.setState({"content": this.props.data})
        }
    }

    viewCourse = async (data) => {
        if(data.isReadingMaterial) {
            if(data.isVideo) {
                return this.props.history.push("/learn/video/" + data.id)
            } else {
                return this.props.history.push("/learn/article/" + data.id)
            }
        } else {
            return this.props.history.push("/learn/course/" + data.id)
        }
    };

    render() {
        if (this.state.content != null)
        {
            return(
                <SmallFeaturedRow onClick={() => {this.viewCourse(this.state.content)}}>
                    <SmallImageContainer>
                        <SmallFeaturedRowImage src={this.state.content.imageUrl}/>
                    </SmallImageContainer>
                    <SmallFeaturedCol>
                        {this.state.content.categories.length > 0 &&
                            <FontHeader14 style={{color: '#8D9F97'}}>{this.state.content.categories[0].title.toUpperCase()}</FontHeader14>
                        }
                        <FontTitle16>{this.state.content.title}</FontTitle16>
                        {/*<FontHeader12>{i.get("description")}</FontHeader12>*/}
                    </SmallFeaturedCol>
                </SmallFeaturedRow>
            )
        } else {
            return null
        }
    }
}

export default withRouter(SmallFeatured);
