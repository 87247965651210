import React from 'react';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';

/* Components */
import {images} from "../../../common/components/images";
import { FontBody16, FontTitle24 } from '../../../common/components/fonts';
import { FlexRow, DashboardContainer } from '../components/home';

export const Item = styled.div`
    margin-right: 30px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 25px;
    cursor: default;
`
export const Icon = styled.div`
    background-image: url(${props => props.image});
    background-size: contain;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    align-self: center;
    justify-self: center;
`;

export const IconPointer = styled(Icon)`
    cursor: pointer; 
    width: 18px;
    height: 18px;
`

export const HomeTasks = ({newUser, meetings, goals, isExclusive}) => {
    let history = useHistory();
    return (
        <>
        <DashboardContainer>
            <FontTitle24>{'Tasks to do'}</FontTitle24>
            <br />
            {(meetings['past'].length === 0 && !isExclusive) &&
                <FlexRow>
                    <Item>
                        <Icon image={meetings['upcoming'].length > 0 ? images.checkBoxChecked : images.checkBoxEmpty}/>
                        <FontBody16>{'Book your first complimentary appointment'}</FontBody16>                
                    </Item>
                    <IconPointer onClick={() => history.push('/my-team/find')} image={images.chevronRightDark}/>
                </FlexRow>
            }
            {/** Don't think this one show just appear for new users */}
            {newUser &&
                <FlexRow>
                    <Item>
                        <Icon image={images.checkBoxEmpty}/>
                        <FontBody16>{'Read/watch videos about your life journeys'}</FontBody16>
                    </Item>
                    <IconPointer onClick={() => history.push('learn')} image={images.chevronRightDark}/>
                </FlexRow>
            }
            {/*<FlexRow>*/}
            {/*    <Item>*/}
            {/*        <Icon image={goals.size > 0 ? images.checkBoxChecked : images.checkBoxEmpty}/>*/}
            {/*        <FontBody16>{'Track your progress by creating goals'}</FontBody16>*/}
            {/*    </Item>*/}
            {/*    <IconPointer onClick={() => history.push('/progress/goals')} image={images.chevronRightDark}/>*/}
            {/*</FlexRow>*/}
        </DashboardContainer>
        </>
    );
}