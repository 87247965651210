import React from 'react';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import {connect} from "react-redux";
import {Map} from 'immutable';

/* Components */
import { colors } from '../../../../common/components/colors';
import { FontBody18 } from '../../../../common/components/fonts';

/* Middleware */
import { tryLogout } from '../../../../routes/middleware/auth';

/* Store */
import {setSupportOpen} from "../../../support/store/support";

/* Domain */
import getDomainEnv from '../../../../domains/utils';
const env = getDomainEnv();

const NavSection = styled.div`
    padding: 10px 10px;
    width: fit-content;
    
`;
const Button = styled.div`
    ${props => props.status !== 'active'} {
        color: ${colors.backgroundColor3}; 
        font-weight: bold;
    } 
    ${props => props.status !== 'inactive'} {
        color: ${colors.white};
    }
    color: ${colors.primary100};
    padding-left: 10px;
    width: fit-content;
    cursor: pointer;
`;
const LogOut = styled.div`
    border-style: solid;
    border-color: white;
    border-radius: 30px;
    border-width: thin;
    margin: 35px auto 0 auto;
    width: fit-content;
    padding: 10px 60px;
    text-align: center;
    color: ${colors.white};
    cursor: pointer;
`;
const IconTextContainer = styled.div`
    display:flex; 
    flex-direction:row;
    align-items: center;
    padding-left: 15px;
    
    width: fit-content;
`;
const SidebarIcon = styled.img`
    width: 40px;
    height: 40px;
    cursor: pointer;
`;

const SideIconActiveOrNot = ({data, status}) => {
    if(status === "active") {
        return(<SidebarIcon src={data.iconActive} alt={""} />)
    } else {
        return(<SidebarIcon src={data.iconInactive} alt={""} />)
    }
};

const LinkSection = ({data, status}) => (
    <IconTextContainer>
        <SideIconActiveOrNot data={data} status={status}/>
        <Button status={status}><FontBody18>{data.label}</FontBody18>
        </Button>
    </IconTextContainer>
)

class FirmNavMenu extends React.Component {
    static defaultProps = {
        logoutButtonStyle: {},
        onOpenSupport: () => {}
    }

    onOpenSupport = async () => {
        await this.props.setSupportOpen(true, 1);
        this.props.onOpenSupport();
    }

    render() {
        return(
            <div>
                {env.ENTERPRISE.NAV.view.map((navSection, navSectionIndex) => (
                    <NavSection key={navSectionIndex}>
                        {navSection.map((d, index) => {
                            let status = "inactive";
                            if (this.props.location.pathname.split("/")[1] === d.href.split("/")[1]) {status = "active"}
                            if(d.showWMC) {
                                if(d.href === "modalSupport") {
                                    return(
                                        <div key={index} onClick={this.onOpenSupport} style={{cursor: "default"}}>
                                            <LinkSection data={d} status={status} />
                                        </div>
                                    )
                                } else if((d.href === "/my-certificates" || d.href === "/upgrade" || d.href === "/my-growth") && this.props.hideCert) {
                                    return null
                                } else {
                                    return(
                                        <Link key={index} to={d.href} style={{cursor: "default"}}>
                                            <LinkSection data={d} status={status} />
                                        </Link>
                                    )
                                }
                            } else {
                                return null
                            }
                        })}
                    </NavSection>
                ))}
                <LogOut style={this.props.logoutButtonStyle} onClick={this.props.tryLogout}><FontBody18>Log Out</FontBody18></LogOut>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    isReferredByPartner: state.common.user.get("isReferredByPartner"),
    isReferredByPartnerWMC: state.common.wmc.get("wmc").get("isReferredByPartner"),
    hideCert: state.common.user.get("directoryListings") === Map() ? false : state.common.user.get("directoryListings").get("practiceManagementUntil") !== null
});

const mapDispatchToProps = dispatch => ({
    tryLogout: () => dispatch(tryLogout()),
    setSupportOpen: (isOpen, page) => dispatch(setSupportOpen(isOpen, page))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FirmNavMenu));