import React from 'react';
import { connect } from "react-redux";
import validator from 'validator';
import { Link, withRouter } from 'react-router-dom';

/* Components */
import { DropdownInput, TextInput, PhoneInput } from "../../common/components/inputs";
import { InlineBlock, SaveSection, Width, SubmitInputWithValidation } from "../components/clientscreate";
import { ButtonTertiary } from "../../common/components/buttons";
import { FontTitle18 } from "../../common/components/fonts";

/* Middleware */
import {tryEditContact, tryGetOneContact} from '../middleware/contacts';
// import { tryEditProspect } from "../middleware/prospects";
// import { tryEditClient } from '../middleware/clients';

/* Utils */
import {timeZoneOptions} from '../../common/utils/timezone2';

class ContactsEdit extends React.Component {
    state = {
        first: '', firstValid: true,
        last: '', lastValid: true,
        phone: '', phoneValid: true,
        email: '', emailValid: true,
        companyName: '',
        timeZone: '',
        isSaving: false,
        isSaved: false,
        error: '',
        isChanged: false,
        isValid: true
    };

    componentDidMount() {
        return this.initiate();
    }

    initiate = async () => {
        const contactId = this.props.clientsDetail.get("contactId");
        await this.props.tryGetOneContact(contactId, "store");
        await this.setState({
            ...this.state,
            ...this.props.contactsOne.toJS(),
            "first": this.props.contactsOne.get("firstName"),
            "last": this.props.contactsOne.get("lastName")
        });
        this.checkValid();
    };

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    handleChangeFirst = async e => {
        await this.setState({'first': e.target.value, 'firstValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''), 'isChanged': true});
        this.checkValid();
    };
    handleChangeLast = async e => {
        await this.setState({'last': e.target.value, 'lastValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''), 'isChanged': true});
        this.checkValid();
    };
    handleChangeEmail = async e => {
        await this.setState({'email': e.target.value, 'emailValid': validator.isEmail(e.target.value), 'isChanged': true});
        this.checkValid();
    };
    handleChangePhone = async e => {
        await this.setState({'phone': e.target.value, 'phoneValid': validator.isMobilePhone(e.target.value, 'en-US'), 'isChanged': true});
        this.checkValid();
    };
    handleChangeTimeZone = async e => {
        await this.setState({'timeZone': e.value, 'isChanged': true});
        this.checkValid();
    };
    handleChangeCompanyName = async e => {
        this.setState({
            'companyName': e.target.value,
            'isChanged': true
        });
        this.checkValid();
    };

    checkValid = () => {
        // if(this.state.firstValid && this.state.lastValid && this.state.phoneValid && this.state.emailValid /*&& this.state.timeZone !== ""*/) {
        if(this.state.firstValid && this.state.lastValid /* && this.state.phoneValid */ && this.state.emailValid && this.state.timeZone !== "") {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    checkErrorExists = async (error) => {
        if(error !== "" && error !== null) {
            this.setState({'error': error, 'isSaving': false, 'isSaved': true, 'isChanged': false});
            this.timeout = setTimeout(() => {this.setState({'error': null, 'isSaving': false, 'isSaved': false, 'isChanged': true})}, 3000);
        }
    };

    returnToContactRecord = (contactError, prospectError, clientError) => {
        if((contactError === "" || contactError === null) &&
            (prospectError === "" || prospectError === null) &&
            (clientError === "" || clientError === null)) {
            this.setState({'isSaving': false, 'isSaved': true, 'isChanged': false});
            this.timeout = setTimeout(() => {this.props.history.push(/*this.props.clientsDetail.get("isCoachNoAdvisor") ? "/contact/notes" : */"/contact/instructions")}, 3000);
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        console.log(this.state.prospectStatus);
        if(this.state.isValid) {
            // if(this.props.isWMC) {
                let phoneAdj = this.state.phone.toString().replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
                await this.props.tryEditContact({...this.state, "phone": phoneAdj}, null);
                this.checkErrorExists(this.props.contactError);
            // } else {
            //     if(this.state.prospectStatus === "Prospect") {
            //         await this.props.tryEditProspect(this.state);
            //         this.checkErrorExists(this.props.prospectError);
            //     } else if(this.state.prospectStatus === "Client") {
            //         await this.props.tryEditClient(this.state);
            //         this.checkErrorExists(this.props.clientError);
            //     }
            // }
            this.setState({'isSaving': true, 'error': ''});
            // this.returnToContactRecord(this.props.contactError, this.props.prospectError, this.props.clientError)
            this.returnToContactRecord(this.props.contactError, null, null)
        }
    };

    render() {
        return(
            <>
                <form method={"post"} onSubmit={this.handleSubmit}>
                    {this.state.isSaved
                        ?
                        <>
                            {(this.state.error === '' || this.state.error === null) && <FontTitle18>Saved!</FontTitle18>}
                            {(this.state.error !== '' && this.state.error !== null) && <FontTitle18>{this.state.error}</FontTitle18>}
                        </>
                        :
                        <>
                            <div>
                                <Width>
                                    <FontTitle18>About</FontTitle18>
                                    <TextInput
                                        title={"First Name*"}
                                        valid={this.state.firstValid}
                                        warning={"Required"}
                                        id={"first"}
                                        onChange={this.handleChangeFirst}
                                        placeholder={"First Name"}
                                        value={this.state.first}
                                    />
                                    <TextInput
                                        title={"Last Name*"}
                                        valid={this.state.lastValid}
                                        warning={"Required"}
                                        id={"last"}
                                        onChange={this.handleChangeLast}
                                        placeholder={"Last Name"}
                                        value={this.state.last}
                                    />
                                    <TextInput
                                        title={"Company Name"}
                                        valid={true}
                                        warning={""}
                                        id={"companyName"}
                                        onChange={this.handleChangeCompanyName}
                                        placeholder={"Company Name"}
                                        value={this.state.companyName}
                                    />
                                    <PhoneInput
                                        title={"Phone"}
                                        valid={this.state.phoneValid}
                                        warning={"Enter a valid mobile phone"}
                                        id={"phone"}
                                        onChange={this.handleChangePhone}
                                        placeholder={'(###) ###-####'}
                                        value={this.state.phone}
                                        options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}}
                                    />
                                    <TextInput
                                        title={"Email*"}
                                        valid={this.state.emailValid}
                                        warning={"Enter a valid email address"}
                                        id={"email"}
                                        onChange={this.handleChangeEmail}
                                        placeholder={"Email"}
                                        value={this.state.email}
                                    />
                                    <DropdownInput
                                        title={"Time zone*"}
                                        options={timeZoneOptions}
                                        id={'timeZone'}
                                        onChange={this.handleChangeTimeZone}
                                        placeholder={'Time Zone'}
                                        value={this.state.timeZone}
                                    />
                                </Width>
                            </div>
                            <SaveSection>
                                <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                <InlineBlock><Link to={"/contact/notes"}><ButtonTertiary canSubmit={false} label={"Cancel"} /></Link></InlineBlock>
                            </SaveSection>
                        </>
                    }
                </form>
            </>
        )
    }
}

const mapStateToProps = state => ({
    clientsDetail: state.enterprise.clientsDetail.get("client"),
    contactsOne: state.enterprise.contacts.get("contactsOne"),
    contactError: state.enterprise.contacts.get("contactsError"),
    // prospectError: state.enterprise.prospects.get("prospectsUpdatingError"),
    // clientError: state.enterprise.clientsList.get("clientsUpdatingError"),
    isWMC: state.common.wmc.get("isWMC"),
});
const mapDispatchToProps = dispatch => ({
    tryEditContact: (data, prospectId) => dispatch(tryEditContact(data, prospectId)),
    tryGetOneContact: (contactId, storeOrReturn) => dispatch(tryGetOneContact(contactId, storeOrReturn)),
    // tryEditProspect: (data) => dispatch(tryEditProspect(data)),
    // tryEditClient: (data) => dispatch(tryEditClient(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactsEdit));
