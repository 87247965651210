import React from 'react';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";

/* Containers */
import SchedulerFlow from "../../scheduler/containers/flow";
import { colors } from "../../../common/components/colors";
import { FontBody24 } from "../../../common/components/fonts";
import { ContactTitle } from "./components";
import { ButtonPrimary, ButtonSecondary, ButtonTertiary } from '../../../common/components/buttons';

const Show = styled.div`
  display: 'flex';
  flex-direction: column;
  gap: 20px;
  align-items: left;
`;
const PreviewWhite = styled(FontBody24)`
  color: ${props => props.color}B3;
  line-height: 1.5;
  padding-top: 10px;
  & strong {
    color: ${props => props.color};
  }
  @media screen and (max-width: 1680px) {
    font-size: 20px !important;
  }
  @media screen and (max-width: 748px) {
    font-size: 16px !important;
  }
`;
const StyleOverride = styled.div`
    margin-top: 30px;
    & > div > div {
      padding: 0 !important;
      margin: 0 !important;
      width: 100% !important;
    }
`;

const BtnDiv = styled.div`
  display: flex;
  margin-top: 30px;
  justify-items: left;
`;

export default function FunnelPage() {
  let history = useHistory();
  let t1 = sessionStorage.getItem("t1");
  let t2 = sessionStorage.getItem("t2");

   t1 = t1 !== null ? "t1=" + t1 : "";
  t2 = t2 !== null ? "t2=" + t2 : "";
  const urlParams = [t1, t2].filter(Boolean).join("&");

  const getMatched = async () => {
    window.location.href = ("https://www.trustwillow.com/ai/" + (urlParams ? "?" + t1 + "&" + t2 : ""));
  };

  return (
    <Show>
      <ContactTitle color={colors.white}>Get Matched</ContactTitle>
      <PreviewWhite color={colors.white}>Get connected with the advisor best suited to serve you.</PreviewWhite>
      <BtnDiv onClick={getMatched}>
        <ButtonTertiary canSubmit={true} label={"Get Started"}>
        </ButtonTertiary>
      </BtnDiv>
    </Show>
  );
}