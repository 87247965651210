import React from 'react';
import {Helmet} from "react-helmet";
import {withRouter} from 'react-router-dom';

/* Components */
import {images} from '../../common/components/images';

/* Containers */
import GetStarted from './containers/getstarted';
import GetMatched from './containers/getmatched';
import GetScheduled from './containers/getscheduled';

class IFrameSignupMatch extends React.Component {
    render() {
        return(
            <>
                <Helmet>
                    <link rel={"icon"} type={"image/png"} href={images.favicon16px} sizes={"16x16"} />
                    <meta charSet={"utf-8"} />
                    <title>Willow</title>
                    <link rel={"canonical"} href={window.location.href} />
                    <meta property={"og:type"} content={"website"} />
                    <meta property={"og:url"} content={window.location.href} />
                    <meta property={"og:site_name"} content={"Willow"} />
                </Helmet>

                {window.location.pathname === "/get-started" && <GetStarted />}
                {window.location.pathname === "/get-matched" && <GetMatched />}
                {window.location.pathname === "/get-scheduled" && <GetScheduled />}

            </>
        )
    }

}

export default withRouter(IFrameSignupMatch);

//http://localhost:3000/get-started?registrationCode=2m6ikx&firstName=Jason&lastName=Aronson&email=jason.aronson%40gmail.com&id=123