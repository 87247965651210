import React from 'react';
import styled from 'styled-components';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import moment from 'moment/moment';
import prettyBytes from 'pretty-bytes';
import {v4 as uuid4} from 'uuid';


import { colors } from './colors';
import {FontBody16} from './fonts';
import {CheckBox, MenuInputKeyPairs, ReadOnlyPhoneInputNoStyle, RadioBox, FileOnList, UsersInput} from './inputs';
import {SortAscendingOutlined, SortDescendingOutlined, TeamOutlined, DashOutlined} from '@ant-design/icons';

const TableResponsive = styled.div`
    & table {
        width: 100%;
        text-align: left;
        border-collapse: collapse;
        background-color: ${props => props.tableColor ? props.tableColor : colors.white};
        & thead {
            height: 50px;
            background-color: ${props => props.theadColor ? props.theadColor : colors.background15};
            @media screen and (max-width: 640px) {
                display: none;
            }
        }
        & tr {
            border: none;
            padding: 20px;
            @media screen and (max-width: 640px) {
                padding: 20px 0px;
                border-bottom: 2px solid;
                border-color: ${props => props.consumer ? colors.action100 : colors.background30};
            }
            & .tdBefore {
                font-weight: 500;
                @media screen and (max-width: 460px) {
                    left: 0px;
                }
            }
            &:not(:last-child) {
                & td {
                    border-bottom: 1px solid;
                    border-color: ${props => props.consumer ? colors.action100 : colors.background30};
                }
            }
        }
        & th {
            padding: 14px 10px 12px 10px;
            color: ${colors.secondary100};
            font-family: GraphicWebMedium;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            border-bottom: 2px solid;
            border-color: ${props => props.consumer ? colors.action100 : colors.background30};
        }
        & td {
            padding: 21px 10px 20px 10px;
            font-family: GraphicWebRegular;
            font-size: 16px;
            line-height: 22px;
            color: ${colors.primary70};
            @media screen and (max-width: 640px) {
                padding: 10px 0px;
                & .tdBefore {
                    color: ${colors.secondary100};
                    font-family: GraphicWebMedium;
                }
            }
            @media screen and (max-width: 460px) {
                & .tdBefore {
                    overflow-wrap: normal !important;
                }
            }
        }
    }
`;


const TableNonResponsive = styled.table`
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    background-color: ${colors.white};
    border-radius: 4px;
`;
const TheadNonResponsive = styled.thead`
    height: 50px;
    border-bottom: 2px solid;
    border-color: ${props => props.consumer ? colors.action100 : colors.background30};
    background-color: ${colors.background15};
`;
const TbodyNonResponsive = styled.tbody`
    height: 64px;
`;
const TrNonResponsive = styled.tr`
    border-bottom: 1px solid;
    border-color: ${props => props.consumer ? colors.action100 : colors.background30};
`;
const ThNonResponsive = styled.th`
    padding: 14px 10px 12px 10px;
    color: ${colors.secondary100};
    font-family: GraphicWebMedium;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
`;
const TdNonResponsive = styled.td`
    padding: 21px 10px 20px 10px;
    font-family: GraphicWebRegular;
    font-size: 16px;
    line-height: 22px;
    color: ${colors.primary70};
    @media screen and (max-width: 460px) {
        padding: 21px 5px 20px 10px;
    }
`;
const Center = styled.div`
    text-align: center;
    @media screen and (max-width: 640px) {
        text-align: left;
    }
`;
const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;
const Show = styled.div`
    ${props => props.show === false} {
        @media screen and (min-width: 640px) {
            display: block;
        }
    }
    ${props => props.show === true} {
        @media screen and (min-width: 640px) {
            display: none;
        }
    }
`;
const ColumnIcons = styled.div`
    margin-left: 5px;
    display: block;
    @media screen and (max-width: 640px) {
        display: none;
    }
`;
const HideOnMobile = styled.div`
    display: block;
    @media screen and (max-width: 640px) {
        display: none;
    }
`;
const ShowOnMobile = styled.div`
    display: none;
    @media screen and (max-width: 640px) {
        display: block;
    }
`;
const MenuWidth = styled.div` 
    width: 36px;
    @media screen and (max-width: 640px) {
        width: auto;
    }
`;
const TeamWidth = styled.div` 
    padding: 0px 5px;
`;

export const TableWrapperV2 = ({ responsive, title, subtitle, tableColumns, tableData, initSelected, hideHeader = false, tableColor = colors.white, theadColor = colors.background15, tableStyles={}, theadStyles={}, tbodyStyles={}, spaceBeforeTbody=false, consumer=false}) => {
    if(responsive) {
        return (
            <TableResponsive tableColor={tableColor} theadColor={theadColor} consumer={consumer}>
                <TableContainerV2
                    title={title}
                    subtitle={subtitle}
                    tableData={tableData}
                    tableColumns={tableColumns}
                    initSelected={initSelected}
                    hideHeader={hideHeader}
                    tableColor={tableColor}
                    TABLE={Table}
                    THEAD={Thead}
                    TBODY={Tbody}
                    TR={Tr}
                    TH={Th}
                    TD={Td}
                    tableStyles={tableStyles}
                    theadStyles={theadStyles}
                    tbodyStyles={tbodyStyles}
                    spaceBeforeTbody={spaceBeforeTbody}
                    consumer={consumer}
                />
            </TableResponsive>
        )
    } else {
        return (<FontBody16><TableContainerV2
            title={title}
            subtitle={subtitle}
            tableData={tableData}
            tableColumns={tableColumns}
            initSelected={initSelected}
            hideHeader={hideHeader}
            TABLE={TableNonResponsive}
            THEAD={TheadNonResponsive}
            TBODY={TbodyNonResponsive}
            TR={TrNonResponsive}
            TH={ThNonResponsive}
            TD={TdNonResponsive}
            tableStyles={tableStyles}
            theadStyles={theadStyles}
            tbodyStyles={tbodyStyles}
            spaceBeforeTbody={spaceBeforeTbody}
        /></FontBody16>)
    }
};

class TableContainerV2 extends React.Component {
    state={"tableColumns": {}, "tableData": [], "columnKeysSorted": [], "selected": [], "allChecked": false};

    componentDidMount() {
        return this.init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.tableColumns !== this.props.tableColumns || prevProps.tableData !== this.props.tableData) {
            this.setState({
                "tableColumns": this.props.tableColumns,
                "tableData": this.props.tableData,
                "selected": [],
                "allChecked": false
            });
        }
    }

    init = async () => {
        await this.setState({
            "tableColumns": this.props.tableColumns,
            "tableData": this.props.tableData,
            "columnKeysSorted": Object.keys(this.props.tableColumns).sort(),
        });
        const columnData = await this.findSortedColumn();
        await this.sortColumn(columnData, true);
        if(this.props.initSelected !== null) {
            this.selectSome(this.props.initSelected)
        }
    };

    findSortedColumn = async () => {
        const sortedColumnKey = this.state.columnKeysSorted.filter(col => {
            return(this.state.tableColumns[col].sort !== null)
        })[0];
        return this.state.tableColumns[sortedColumnKey];
    };

    sortColumn = async (columnData, initiate) => {
        const canSort = columnData.canSort;
        if(canSort) {
            let currentSort = columnData.sort;
            let newSort = initiate ? currentSort : (currentSort === null ? "asc" : (currentSort === "asc" ? "desc" : "asc"));
            let newColumnData = {};
            await Promise.all(this.state.columnKeysSorted.map(col => {
                if(this.state.tableColumns[col].sortKey === columnData.sortKey) {
                    newColumnData = {...newColumnData, [col]: {...this.state.tableColumns[col], "sort": newSort}};
                } else {
                    newColumnData = {...newColumnData, [col]: {...this.state.tableColumns[col], "sort": null}};
                }
                Promise.resolve();
            }));
            const newTableData = await Promise.all(this.state.tableData.sort((a, b) => {

                let aRecord;
                let bRecord;
                if(columnData.sortType === "string" || columnData.sortType === "number") {
                    aRecord = a[columnData.sortKey] === null ? null : a[columnData.sortKey].toString();
                    bRecord = b[columnData.sortKey] === null ? null : b[columnData.sortKey].toString();
                } else if(columnData.sortType === "date") {
                    aRecord = new Date(a[columnData.sortKey]);
                    bRecord = new Date(b[columnData.sortKey]);
                }

                if((columnData.component !== "datetimeFromNow" && newSort === "asc") || (columnData.component === "datetimeFromNow" && newSort === "desc")) {
                    if(aRecord === bRecord) {return 0;}
                    if(aRecord === null) {return 1;}
                    if(bRecord === null) {return -1;}
                    if(aRecord < bRecord) {return -1;}
                    if(aRecord > bRecord) {return 1;}
                    return 0;
                } else if((columnData.component !== "datetimeFromNow" && newSort === "desc") || (columnData.component === "datetimeFromNow" && newSort === "asc")) {
                    if(aRecord === bRecord) {return 0;}
                    if(aRecord === null) {return -1;}
                    if(bRecord === null) {return 1;}
                    if(aRecord < bRecord) {return 1;}
                    if(aRecord > bRecord) {return -1;}
                    return 0;
                }
            }));
            await this.setState({
                "tableColumns": newColumnData,
                "tableData": newTableData
            });
        }
    };

    selectOnlyOne = async (row, onClick) => {
        let isSelected = this.state.tableData.filter(s => {return(s.id === row.id)})[0].id;
        await this.setState({
            "tableData": this.state.tableData.map(d => {
                if(d.id === row.id) {
                    return({...d, "isChecked": true});
                } else {
                    return({...d, "isChecked": false});
                }
            }),
            "selected": [isSelected]
        });
        onClick(this.state.selected);
        await this.checkAllSelected();
    };

    selectOne = async (row, onClick) => {
        let selected = this.state.selected;
        let isSelected = selected.filter(s => {return(s === row.id)});
        if(isSelected.length === 0) {
            await selected.push(row.id);
            onClick(selected);
        } else {
            selected = await selected.filter(s => {return(s !== row.id)});
            if(selected.length > 0) {
                onClick(selected);
            } else {
                onClick(selected);
            }
        }
        await this.setState({
            "tableData": this.state.tableData.map(d => {
                if(d.id === row.id) {
                    return({...d, "isChecked": !d.isChecked});
                } else {
                    return(d);
                }
            }),
            "selected": selected
        });
        this.checkAllSelected();
    };

    selectSome = async (initSelected) => {
        await this.setState({
            "tableData": this.state.tableData.map(d => {
                if(initSelected.includes(d.id)) {
                    return({...d, "isChecked": true});
                } else {
                    return(d);
                }
            }),
            "selected": initSelected
        });
    };

    selectNone = async () => {
        await this.setState({
            "tableData": this.state.tableData.map(d => {
                return({...d, "isChecked": false});
            }),
            "selected": []
        });
        this.checkAllSelected();
    };

    selectAll = async () => {
        this.setState({
            "tableData": this.state.tableData.map(d => {
                return({...d, "isChecked": true});
            }),
            "selected": this.state.tableData.map(d => {
                return d.id
            })
        });
    };

    checkAllSelected = async () => {
        if(this.state.selected.length === this.state.tableData.length) {
            await this.setState({"allChecked": true})
        } else {
            await this.setState({"allChecked": false})
        }
    };

    selectAllOrNone = async (onClick) => {
        if(this.state.selected.length === this.state.tableData.length) {
            await this.selectNone();
            onClick(this.state.selected);
        } else {
            await this.selectAll();
            onClick(this.state.selected);
        }
        await this.checkAllSelected();
    };

    render() {

        let TABLE = this.props.TABLE;
        let THEAD = this.props.THEAD;
        let TBODY = this.props.TBODY;
        let TR = this.props.TR;
        let TH = this.props.TH;
        let TD = this.props.TD;

        if(this.state.columnKeysSorted.length === 0) {
            return null
        } else {
            return(
                <TABLE style={this.props.tableStyles}>
                    {!this.props.hideHeader && <THEAD style={this.props.theadStyles}><TR>
                        {this.state.columnKeysSorted.map(col => {

                            let component = this.state.tableColumns[col].component;
                            let key = this.state.tableColumns[col].key;
                            let title = this.state.tableColumns[col].title;
                            let titleShowOnDesktop = this.state.tableColumns[col].titleShowOnDesktop;
                            let canSort = this.state.tableColumns[col].canSort;
                            let sort = this.state.tableColumns[col].sort;
                            let columnData = this.state.tableColumns[col];
                            let onClick = this.state.tableColumns[col].onClick;
                            let thStyles = this.state.tableColumns[col].thStyles;

                            if (component === "hidden") {
                                return null
                            } else if (component === "menu") {
                                return(<TH key={key} style={{"textAlign": "right", "width": "36px", ...thStyles}}><Center><Show show={titleShowOnDesktop}>{title}</Show></Center></TH>)
                            } else if (component === "checkbox") {
                                return(<TH key={key} style={{"width": "25px", "height": "25px",...thStyles}}><ShowOnMobile>Select</ShowOnMobile><HideOnMobile><Center><Show show={!titleShowOnDesktop}><CheckBox action={() => {return this.selectAllOrNone(onClick)}} checked={this.state.allChecked} allData={{"id": "all"}} /></Show></Center></HideOnMobile></TH>)
                            } else if (component === "radio") {
                                return(<TH key={key} style={{"width": "25px", "height": "25px", ...thStyles}}><Center><Show show={!titleShowOnDesktop}>{title}</Show></Center></TH>)
                            } else {
                                return(
                                    <TH key={key}
                                        style={canSort ? {"cursor": "pointer", "MozUserSelect": "none", "WebkitUserSelect": "none", "msUserSelect": "none", "userSelect": "none", ...thStyles} : {...thStyles}}
                                        onClick={() => {return this.sortColumn(columnData, false)}}
                                    >
                                        <Inline><Show show={titleShowOnDesktop}>{title}</Show></Inline>
                                        {sort === "asc" && <Inline><ColumnIcons><SortAscendingOutlined /></ColumnIcons></Inline>}
                                        {sort === "desc" && <Inline><ColumnIcons><SortDescendingOutlined /></ColumnIcons></Inline>}
                                    </TH>
                                )
                            }

                        })}
                    </TR></THEAD>}
                    <TBODY style={this.props.tbodyStyles}>
                        {this.props.spaceBeforeTbody && <TR><TD style={{"border": "none", "padding": 0}} /></TR>}
                        {this.state.tableData.map((row, rowIndex) => (
                            <TR key={rowIndex} style={{...row.trStyles}}>
                                {this.state.columnKeysSorted.map((col, colIndex) => {

                                    let keyString = colIndex+"-"+rowIndex;
                                    let component = this.state.tableColumns[col].component;
                                    let label = row[this.state.tableColumns[col].key];
                                    let ifNull = this.state.tableColumns[col].ifNull;
                                    let onClick = this.state.tableColumns[col].onClick;
                                    let onDoubleClick = this.state.tableColumns[col].onDoubleClick;
                                    let tdStyles = this.state.tableColumns[col].tdStyles;
                                    let allData = row.allData;
                                    let users = row.users;
                                    let selectedStyle = row.isChecked ? {"backgroundColor": this.props.consumer ? colors.action70 : colors.notification15} : {}; //

                                    if (component === "link") {
                                        return (<TD key={keyString} style={{"cursor": "pointer", ...selectedStyle, ...tdStyles}} onClick={() => {onClick(allData)}}>{label}</TD>)
                                    } else if (component === "linkWithIcon") {
                                        return (<TD key={keyString} style={{"cursor": "pointer", ...selectedStyle, ...tdStyles}} onClick={() => {onClick(allData)}}>{ifNull}</TD>)
                                    } else if (component === "linkWithDoubleClick") {
                                        return (<TD key={keyString} style={{"cursor": "pointer", "MozUserSelect": "none", "WebkitUserSelect": "none", "msUserSelect": "none", "userSelect": "none", ...selectedStyle, ...tdStyles}} onClick={() => {onClick(allData)}} onDoubleClick={() => onDoubleClick(allData)}>{label}</TD>)
                                    } else if (component === "linkIfDataAllows") {
                                        return (<TD key={keyString} style={allData.linkAllowed ? {"cursor": "pointer", ...selectedStyle, ...tdStyles} : {}} onClick={allData.linkAllowed ? () => {onClick(allData)} : () => {}}>{label}</TD>)
                                    } else if (component === "fileOrFolder") {
                                        return (<TD key={keyString} style={{"cursor": "pointer", "MozUserSelect": "none", "WebkitUserSelect": "none", "msUserSelect": "none", "userSelect": "none", "padding": "17px 10px 16px 10px", ...selectedStyle, ...tdStyles}} onClick={() => {return this.selectOnlyOne(allData, onClick)}} onDoubleClick={() => onDoubleClick(allData)}><FileOnList name={label} icon={allData.documentIcon} /></TD>)
                                    } else if (component === "filter") {
                                        return (<TD key={keyString} style={{"cursor": "pointer", "MozUserSelect": "none", "WebkitUserSelect": "none", "msUserSelect": "none", "userSelect": "none", "padding": "17px 10px 16px 10px", ...selectedStyle, ...tdStyles}} onClick={() => {return this.selectOnlyOne(allData, onClick)}}>{label}</TD>)
                                    } else if (component === "string") {
                                        return (<TD key={keyString} style={{ ...selectedStyle, ...tdStyles}}>{label === null ? ifNull : label}</TD>)
                                    } else if (component === "fileSize") {
                                        return (<TD key={keyString} style={{ ...selectedStyle, ...tdStyles}}>{label === null ? ifNull : prettyBytes(label).toUpperCase()}</TD>)
                                    } else if (component === "phone") {
                                        return (<TD key={keyString} style={{ ...selectedStyle, ...tdStyles}}>{label === null ? ifNull : <ReadOnlyPhoneInputNoStyle id={uuid4()} value={label} />}</TD>)
                                    } else if (component === "datetime") {
                                        return (<TD key={keyString} style={{ ...selectedStyle, ...tdStyles}}>{label === null ? ifNull : moment(label).format("lll").toString()}</TD>)
                                    } else if (component === "datetimeFromNow") {
                                        return (<TD key={keyString} style={{ ...selectedStyle, ...tdStyles}}>{label === null ? ifNull : moment(label).fromNow()}</TD>)
                                    } else if (component === "menu") {
                                        return (<TD key={keyString} style={{"textAlign": "right", ...selectedStyle, ...tdStyles}}><MenuWidth><MenuInputKeyPairs actions={onClick} labels={label} allData={allData}/></MenuWidth></TD>)
                                    } else if (component === "checkbox") {
                                        return (<TD key={keyString} style={{"width": "25px", "height": "25px", ...tdStyles}}><CheckBox action={() => {return this.selectOne(allData, onClick)}} checked={label} allData={allData} /></TD>)
                                    } else if (component === "radio") {
                                        return (<TD key={keyString} style={{"width": "25px", "height": "25px", ...tdStyles}}><RadioBox action={() => {return this.selectOnlyOne(allData, onClick)}} checked={label} allData={allData} /></TD>)
                                    } else if (component === "team" && !allData.team) {
                                        return (<TD key={keyString} style={{"textAlign": "right", ...selectedStyle, ...tdStyles}}><MenuWidth><TeamOutlined /></MenuWidth></TD>)
                                    } else if (component === "team" && allData.team) {
                                        return (<TD key={keyString} style={{"textAlign": "right", ...selectedStyle, ...tdStyles}}><MenuWidth></MenuWidth></TD>)
                                    } else if (component === "users" && users !== null && users.length > 0) {
                                        return (<TD key={keyString} style={{"textAlign": "left", ...selectedStyle, ...tdStyles}}><UsersInput users={users}/></TD>)
                                    } else if (component === "users" && (users === null || users.length === 0)) {
                                        return (<TD key={keyString} style={{"textAlign": "left", ...selectedStyle, ...tdStyles}}><TeamWidth><DashOutlined /></TeamWidth></TD>)
                                    } else if (component === "introLink") {
                                        return (<TD key={keyString} style={{"textAlign": "left", ...selectedStyle, ...tdStyles}}>{label === null ? ifNull : label}</TD>)
                                    } else if (component === "isAdvisorOrInvestor") {
                                        return (<TD key={keyString} style={{"textAlign": "left", ...selectedStyle, ...tdStyles}}>{label === null ? ifNull : label}</TD>)

                                    }
                                })}
                            </TR>
                        ))}
                    </TBODY>
                </TABLE>
            )
        }
    }
}