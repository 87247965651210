import 'moment-timezone/builds/moment-timezone-with-data-2012-2022';
import moment from "moment-timezone";

export const setOneHrMin = async (start, end) => {
    const setStart = moment(start);
    const setEnd = moment(end);
    const setMin = moment.duration(setEnd.diff(setStart)).asMinutes();
    if(setMin < 60) {
        return ({start: setStart.format().toString(), end: setStart.add(1, "hour").format().toString()});
    } else {
        return ({start: setStart.format().toString(), end: setEnd.format().toString()});
    }
};