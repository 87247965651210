import { Map } from 'immutable';

/* Immutable */

const IFRAME_AUTH__TEST_USER_EXISTS = 'IFRAME_AUTH__TEST_USER_EXISTS';
const IFRAME_AUTH__TEST_USER_EXISTS_ERROR = 'IFRAME_AUTH__TEST_USER_EXISTS_ERROR';
const IFRAME_AUTH__LOGIN = 'IFRAME_AUTH__LOGIN';
const IFRAME_AUTH__LOGIN_ERROR = 'IFRAME_AUTH__LOGIN_ERROR';
const IFRAME_AUTH__STORE_RESET = 'IFRAME_AUTH__STORE_RESET';

/* Actions */

export const setIframeAuthTestUserExists = (status) => ({"type": IFRAME_AUTH__TEST_USER_EXISTS, 'status': status});
export const setIframeAuthTestUserExistsError = (error) => ({"type": IFRAME_AUTH__TEST_USER_EXISTS_ERROR, 'error': error});
export const setIframeAuthLogin = (status) => ({"type": IFRAME_AUTH__LOGIN, 'status': status});
export const setIframeAuthLoginError = (error) => ({"type": IFRAME_AUTH__LOGIN_ERROR, 'error': error});
export const setIframeAuthStoreReset = () => ({"type": IFRAME_AUTH__STORE_RESET});

/* Initial State */

const initialState = Map({
    iframeAuthTestUserExists: false,
    iframeAuthTestUserExistsError: null,
    iframeAuthLogin: false,
    iframeAuthLoginError: null,
});

/* Reducer */

const iframeAuthReducer = (state=initialState, action) => {
    switch (action.type) {
        case IFRAME_AUTH__TEST_USER_EXISTS:
            return state.merge({'iframeAuthTestUserExists': action.status});
        case IFRAME_AUTH__TEST_USER_EXISTS_ERROR:
            return state.merge({'iframeAuthTestUserExistsError': action.error});
        case IFRAME_AUTH__LOGIN:
            return state.merge({'iframeAuthLogin': action.status});
        case IFRAME_AUTH__LOGIN_ERROR:
            return state.merge({'iframeAuthLoginError': action.error});
        case IFRAME_AUTH__STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default iframeAuthReducer;
