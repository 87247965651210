import React from 'react';
import moment from "moment";
import {connect} from 'react-redux';

/* Components */
import {
    FontBody12,
    FontBody14, FontBody16,
    FontHeader14,
    FontHeader16,
    FontTitle18,
    FontTitle24
} from "../../../common/components/fonts";
import {
    MarginBottom15,
    Action,
    ActionLink,
    ActionNoLink,
    DateItem,
    EmailSenders,
    Indent,
    Inline,
    InlineToWide,
    Letter,
    SenderIcon,
    SenderNames,
    SenderRecipient,
    VideoLink,
    Modal,
    ConfirmTitle,
    ConfirmActions,
    ConfirmLeft,
    ConfirmBtnFitLeft,
    ConfirmRight,
    ConfirmBtnFitRight, Search, Trash, Card
} from "../components/contactactivity";
import {ButtonPrimary, ButtonTertiary, ButtonAlert} from "../../../common/components/buttons";
import {SearchInputUncontrolled} from "../../../common/components/inputs";
import {DeleteFilled, EditOutlined, EyeInvisibleOutlined, EyeOutlined, CheckCircleOutlined, StopOutlined, LinkOutlined} from "@ant-design/icons";
import {SavedSettings} from "../../../common/components/cards";

/* Middleware */
import {setSupportOpen} from "../../support/store/support";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

/* Meeting Body Items */

const MeetingDatetime = ({title, start, end}) => (
    <MarginBottom15>
        <MarginBottom15><FontHeader14>{title}</FontHeader14></MarginBottom15>
        <Indent><FontBody14>Day: <b>{moment(start).format("MMMM D, YYYY")}</b></FontBody14></Indent>
        <Indent><FontBody14>Start: <b>{moment(start).format("h:mm a")}</b></FontBody14></Indent>
        <Indent><FontBody14>End: <b>{moment(end).format("h:mm a")}</b></FontBody14></Indent>
    </MarginBottom15>
);

const MeetingJoinVideo = ({videoUrl}) => (
    <MarginBottom15>
        <MarginBottom15><FontBody14><b>Join by video:</b></FontBody14></MarginBottom15>
        <Indent><FontBody14><b><VideoLink href={"https://live."+env.URL.DOMAIN+"/room/"+videoUrl}>{"https://live."+env.URL.DOMAIN+"/room/"+videoUrl}</VideoLink></b></FontBody14></Indent>
        <Indent><FontBody14>For security purposes, participants receive a unique link. If you forward or share yours, it will not work.</FontBody14></Indent>
    </MarginBottom15>
);

const MeetingJoinPhone = ({phone, room}) => (
    <MarginBottom15>
        <MarginBottom15><FontBody14><b>Join by phone:</b></FontBody14></MarginBottom15>
        <Indent><FontBody14>Phone number: <b><VideoLink href={"tel:"+phone.replace("-", "").replace("-", "")}>{phone}</VideoLink></b></FontBody14></Indent>
        <Indent><FontBody14>Meeting ID: <b>{room}</b></FontBody14></Indent>
    </MarginBottom15>
);

const MeetingDescription = ({description}) => (
    <>
        <MarginBottom15><FontHeader14>Description</FontHeader14></MarginBottom15>
        <Indent><FontBody14>{(description !== null && description !== "" && description !== undefined) ? description : "---"}</FontBody14></Indent>
    </>
);

/* Meeting Body Combinations */

export const MeetingBodyFuture = ({title, data}) => (
    <>
        <MeetingDatetime title={title+" Meeting"} start={data.get("start")} end={data.get("expected_end") === null ? data.get("end") : data.get("expected_end")} />
        <MeetingJoinVideo videoUrl={data.get("videoUrl")} />
        <MeetingJoinPhone phone={data.get("phone")} room={data.get("room")} />
        <MeetingDescription description={data.get("description")} />
    </>
);

export const MeetingBodyPast = ({title, data}) => (
    <>
        <MeetingDatetime title={title+" Meeting"} start={data.get("start")} end={data.get("expected_end") === null ? data.get("end") : data.get("expected_end")} />
        <MeetingDescription description={data.get("description")} />
    </>
);

/* Meeting Header Attendees Items */

const FirstInitial = ({name}) => (
    <SenderIcon><Letter><FontTitle18>{name.charAt(0).toUpperCase()}</FontTitle18></Letter></SenderIcon>
);

const MeetingWith = ({status, missed, guests, remoteUserName, isWMC}) => (
    <div>
        <Inline>
            <SenderRecipient>
                <FontBody12>{(status === "Past" ? (missed ? "missed a" : "held a") : "is")+" meeting with "+(remoteUserName === "Contact" ? "" : remoteUserName)}</FontBody12>
            </SenderRecipient>
        </Inline>
        {guests.size > 0 && <MeetingGuests guests={guests} internal={remoteUserName === "Contact"} isWMC={isWMC}/>}
    </div>
);

const MeetingGuests = ({guests, internal, isWMC}) => (
    <>
        {guests.map((g, index) => {
            return(
                <Inline key={index}>
                    <SenderRecipient>
                        {(internal && index === 0) &&
                            <FontBody12>&nbsp;{g.get("referralType") === "willow" || (!isWMC && g.get("referralType") === 'wmc') ? g.get("firstName")+" "+g.get("lastName").charAt(0).toUpperCase()+"." : g.get("firstName")+" "+g.get("lastName")}</FontBody12>
                        }
                        {(!internal || (internal && index > 0)) &&
                            <FontBody12>{g.get("referralType") === "willow" || (!isWMC && g.get("referralType") === 'wmc') ? ", "+g.get("firstName")+" "+g.get("lastName").charAt(0).toUpperCase()+"." : ", "+g.get("firstName")+" "+g.get("lastName")}</FontBody12>
                        }
                    </SenderRecipient>
                </Inline>
            )
        })}
    </>
);

/* Meeting Header Attendees */

export const MeetingAttendees = ({thisUserName, remoteUserName, status, missed, guests, isWMC}) => (
    <EmailSenders>
        <FirstInitial name={thisUserName} />
        <SenderNames>
            <FontHeader16>{thisUserName}</FontHeader16>
            <MeetingWith status={status} missed={missed} guests={guests} remoteUserName={remoteUserName} isWMC={isWMC}/>
        </SenderNames>
    </EmailSenders>
);

/* Meeting Header Action Bars */

export const MeetingActionJoin = ({videoUrl, start}) => (
    <Action>
        <Inline><ActionLink><FontHeader16><VideoLink href={videoUrl}>Join video</VideoLink></FontHeader16></ActionLink></Inline>
        <DateItem><FontBody12>{moment(start).format("MMM D, YYYY [at] h:mm a")}</FontBody12></DateItem>
    </Action>
);
export const MeetingActionJoinLarge = ({videoUrl, start, participantsModalOpen, numParticipants, numParticipantsMax, isOnlyForClients, isActive, ticketCost, linkOrEmbedModalOpen}) => (
    <Action>
        <InlineToWide><ActionNoLink><FontHeader16>{isOnlyForClients ? <><EyeInvisibleOutlined />&nbsp;Private</> : <><EyeOutlined />&nbsp;Public</>}</FontHeader16></ActionNoLink></InlineToWide>
        {ticketCost > 0 && <InlineToWide><ActionNoLink><FontHeader16>Sold: ${ticketCost*numParticipants}</FontHeader16></ActionNoLink></InlineToWide>}
        <InlineToWide><ActionLink onClick={() => {participantsModalOpen()}}><FontHeader16>Registrations: {numParticipants}/{numParticipantsMax}</FontHeader16></ActionLink></InlineToWide>
        {!isActive && <InlineToWide><ActionNoLink><FontHeader16><><StopOutlined />&nbsp;Canceled</></FontHeader16></ActionNoLink></InlineToWide>}
        <Inline><ActionLink><FontHeader16><VideoLink href={videoUrl}>Join video</VideoLink></FontHeader16></ActionLink></Inline>
        <InlineToWide><ActionLink onClick={() => {linkOrEmbedModalOpen()}}><FontHeader16><LinkOutlined />&nbsp;Link or Embed</FontHeader16></ActionLink></InlineToWide>
        {/*<DateItem><FontBody12>{moment(start).format("MMM D, YYYY [at] h:mm a")}</FontBody12></DateItem>*/}
    </Action>
);

export const MeetingActionOptions = ({invite, reschedule, cancel, start, isWMC, numParticipants}) => (
    <Action>
        {(isWMC && numParticipants < 4) && <InlineToWide><ActionLink onClick={() => {invite()}}><FontHeader16>Invite</FontHeader16></ActionLink></InlineToWide>}
        {(isWMC && numParticipants === 4) && <InlineToWide><ActionNoLink><FontHeader16>Invite</FontHeader16></ActionNoLink></InlineToWide>}
        <InlineToWide><ActionLink onClick={() => {reschedule()}}><FontHeader16>Reschedule</FontHeader16></ActionLink></InlineToWide>
        <InlineToWide><ActionLink onClick={() => {cancel()}}><FontHeader16>Cancel</FontHeader16></ActionLink></InlineToWide>
        <DateItem><FontBody12>{moment(start).format("MMM D, YYYY [at] h:mm a")}</FontBody12></DateItem>
    </Action>
);

export const MeetingLargeActionOptions = ({reschedule, cancel, edit, start, status, participantsModalOpen, linkOrEmbedModalOpen, numParticipants, numParticipantsMax, isActive, isOnlyForClients, ticketCost}) => (
    <Action>
        <InlineToWide><ActionNoLink><FontHeader16>{isOnlyForClients ? <><EyeInvisibleOutlined />&nbsp;Private</> : <><EyeOutlined />&nbsp;Public</>}</FontHeader16></ActionNoLink></InlineToWide>
        {ticketCost > 0 && <InlineToWide><ActionNoLink><FontHeader16>Sold: ${ticketCost*numParticipants}</FontHeader16></ActionNoLink></InlineToWide>}
        <InlineToWide><ActionLink onClick={() => {participantsModalOpen()}}><FontHeader16>Registrations: {numParticipants}/{numParticipantsMax}</FontHeader16></ActionLink></InlineToWide>
        {!isActive && <InlineToWide><ActionNoLink><FontHeader16><><StopOutlined />&nbsp;Canceled</></FontHeader16></ActionNoLink></InlineToWide>}
        <InlineToWide><ActionLink onClick={() => {linkOrEmbedModalOpen()}}><FontHeader16><LinkOutlined />&nbsp;Link or Embed</FontHeader16></ActionLink></InlineToWide>
        {(status !== "Past" && isActive) && <InlineToWide><ActionLink onClick={() => {edit()}}><FontHeader16><EditOutlined />&nbsp;Modify</FontHeader16></ActionLink></InlineToWide>}
        {/*{status !== "Canceled" && <InlineToWide><ActionLink onClick={() => {reschedule()}}><FontHeader16>Reschedule</FontHeader16></ActionLink></InlineToWide>}*/}
        {(status === "Upcoming" && isActive) && <InlineToWide><ActionLink onClick={() => {cancel()}}><FontHeader16><><StopOutlined />&nbsp;Cancel</></FontHeader16></ActionLink></InlineToWide>}
        {/*<DateItem><FontBody12>{moment(start).format("MMM D, YYYY [at] h:mm a")}</FontBody12></DateItem>*/}
    </Action>
);

export const MeetingNoActions = ({start}) => (
    <Action>
        <DateItem><FontBody12>{moment(start).format("MMM D, YYYY [at] h:mm a")}</FontBody12></DateItem>
    </Action>
);
export const MeetingNoActionsLarge = ({start, numParticipants, numParticipantsMax, linkOrEmbedModalOpen, isOnlyForClients, isActive, ticketCost, participantsModalOpen}) => (
    <Action>
        <InlineToWide><ActionNoLink><FontHeader16>{isOnlyForClients ? <><EyeInvisibleOutlined />&nbsp;Private</> : <><EyeOutlined />&nbsp;Public</>}</FontHeader16></ActionNoLink></InlineToWide>
        {ticketCost > 0 && <InlineToWide><ActionNoLink><FontHeader16>Sold: ${ticketCost*numParticipants}</FontHeader16></ActionNoLink></InlineToWide>}
        <InlineToWide><ActionLink onClick={() => {participantsModalOpen()}}><FontHeader16>Registrations: {numParticipants}/{numParticipantsMax}</FontHeader16></ActionLink></InlineToWide>
        {!isActive && <InlineToWide><ActionNoLink><FontHeader16><><StopOutlined />&nbsp;Canceled</></FontHeader16></ActionNoLink></InlineToWide>}
        <InlineToWide><ActionLink onClick={() => {linkOrEmbedModalOpen()}}><FontHeader16><LinkOutlined />&nbsp;Link or Embed</FontHeader16></ActionLink></InlineToWide>
    </Action>
);

/* Meeting Modals Items */

const ModalHeader = ({title, subTitle}) => (
    <>
        <ConfirmTitle><FontTitle24>{title}</FontTitle24></ConfirmTitle>
        <FontBody16>{subTitle}</FontBody16>
    </>
);


const ModalActions = ({leftAction, rightAction, rightLabel}) => (
    <ConfirmActions>
        <ConfirmLeft>
            <ConfirmBtnFitLeft onClick={() => {leftAction()}}><ButtonTertiary label={"No"} canSubmit={true} /></ConfirmBtnFitLeft>
        </ConfirmLeft>
        <ConfirmRight>
            <ConfirmBtnFitRight onClick={() => {rightAction()}}><ButtonAlert label={rightLabel} canSubmit={true} /></ConfirmBtnFitRight>
        </ConfirmRight>
    </ConfirmActions>
);

/* Meeting Modals */

const ModalBefore24Hours = ({actionTitle, leftAction, rightAction, rightLabel, eventTitle}) => (
    <Modal>
        <ModalHeader
            title={"Are you sure?"}
            subTitle={"Confirm you want to "+actionTitle+" this "+eventTitle+". Participants will receive a confirmation email."}
        />
        <ModalActions
            leftAction={leftAction}
            rightAction={rightAction}
            rightLabel={rightLabel}
        />
    </Modal>
);

class ModalWithin24Hours extends React.Component {

    onOpenSupport = () => {
        this.props.setSupportOpen(true, 1);
        this.props.leftAction();
    }

    render() {
        return(
            <Modal>
                <ModalHeader
                    title={"Are you sure?"}
                    subTitle={"Your session is scheduled for less than 24 hours from now. Please contact support if you need to cancel or reschedule."}
                />
                <ModalActions
                    leftAction={this.props.leftAction}
                    rightAction={this.onOpenSupport}
                    rightLabel={"Contact us"}
                />
            </Modal>
        )
    }
}
const mapDispatchToProps = dispatch => ({
    setSupportOpen: (isOpen, page) => dispatch(setSupportOpen(isOpen, page))
});
const ModalWithin24HoursWrapper = connect(null, mapDispatchToProps)(ModalWithin24Hours);

export const ModalConfirmation = ({actionTitle, leftAction, rightAction, rightLabel, eventTitle, within24Hours}) => {
    if(within24Hours) {
        return (
            <ModalWithin24HoursWrapper
                leftAction={leftAction}
            />
        )
    } else {
        return(
            <ModalBefore24Hours
                actionTitle={actionTitle}
                leftAction={leftAction}
                rightAction={rightAction}
                rightLabel={rightLabel}
                eventTitle={eventTitle}
            />
        )
    }
}

/* Meeting Invite Items */

const whoOptions = (who, isWMC) => {
    return who.map(w => {
        console.log(w)
        return({
            contactType: (w.get("prospectId") === null && w.get("businessUserId") === null) ? "Contact" : (w.get("prospectStatus") !== null ? w.get("prospectStatus") : "Team Member"),
            value: {
                consumerId: w.get("consumerId"),
                prospectId: w.get("prospectId"),
                contactId: w.get("contactId"),
                businessUserId: w.get("businessUserId")
            },
            label: (w.get("prospectId") === null && w.get("businessUserId") === null) ? w.get("firstName") + " " + w.get("lastName") 
                : 
                ((!isWMC && w.get("referralSource") === "wmc") ? 
                    w.get("firstName") + " " + w.get("lastName").charAt(0) + "."
                    : 
                    w.get("firstName") + " " + w.get("lastName")),
            isArchived: w.get("isArchived")
        })
    }).toJS();
};

/* Meeting Invite */

export const ModalInvite = ({inviteClose, inviteGuest, searchProspects, guests, handleAddGuest, handleDeleteGuest, numParticipants, isWMC}) => (
    <Modal>
        <ModalHeader
            title={"Invite guest"}
            subTitle={"Select a contact or team member to join your meeting."}
        />
        <>
            <SearchInputUncontrolled
                title={"Guests & Team Members"}
                options={whoOptions(searchProspects, isWMC)}
                id={'guests'}
                onChange={handleAddGuest}
                placeholder={'Search contacts (optional)'}
                value={""}
                disabled={(numParticipants + guests.length) === 4}
            />
            {guests.length > 0 &&
                guests.map((g, index) => {
                    return(
                        <div key={index}>
                            <Search><FontBody16>{(index+1).toString()+". "+g.label}</FontBody16></Search>
                            <Trash onClick={() => {handleDeleteGuest(index)}}><DeleteFilled /></Trash>
                        </div>
                    )
                })
            }
            {((numParticipants + guests.length) === 4) &&
                <Search><FontBody12>Max participants reached</FontBody12></Search>
            }
        </>
        <ModalActions
            leftAction={inviteClose}
            rightAction={inviteGuest}
            rightLabel={"Invite"}
        />
    </Modal>
);

export const ModalSaved = () => (
    <Modal>
        <SavedSettings height={"20px"} error={false} message={"Saved"}/>
    </Modal>
);

