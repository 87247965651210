import React from 'react';
import styled from 'styled-components';

/* Components */
import {colors} from "../../common/components/colors";
import {images} from '../../common/components/images'
import {FontTitle18, FontTitle32To27} from "../../common/components/fonts";

import Flow from "./containers/flow";

const Padding = styled.div`
    padding-top: 15px;
`;

const LeftTitle = styled.div`
    vertical-align: middle;
    display: inline-block;
    z-index: 2;
    padding-left: 10px;
    @media screen and (max-width: 640px) {
        display: block;
        width: 100%;
    }
`;
const RightTitle = styled.div`
    vertical-align: middle;
    text-align: center;
    padding: 5px 0px;
    @media screen and (max-width: 640px) {
        display: block;
        width: 100%;
        text-align: left;
        margin: 5px 0px;
        padding: 5px 10px;
    }
`;

const TitleSection = styled.div`
    padding-bottom: 15px;
    border-bottom: 1px solid ${colors.primary30};
    margin-bottom: 30px;
`;

const Left = styled.div`
    width: 100%;
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 640px) {
        width: 100%;
    }
`;

const IframeMatching = () => (
    <>
        <Padding>
            <LeftTitle><img src={images.logo} height="40" width="200" alt={""}/></LeftTitle>
            <RightTitle>
                <TitleSection>
                    <Left>
                        <FontTitle32To27>Coach Matching</FontTitle32To27>
                    </Left>
                </TitleSection>
            </RightTitle>
            <Flow />
        </Padding>
    </>
);


export default IframeMatching;
