import moment from "moment-timezone";
import {axiosReq} from "../../../../common/utils/axios";

/* Store */
import { setRecurringAvailabilitySyncing, setRecurringAvailabilitySync, setRecurringAvailabilityReading, setRecurringAvailabilityUpdating, setRecurringAvailability, setRecurringAvailabilitySchedule, setRecurringAvailabilityScheduleReading, setRecurringAvailabilityScheduleUpdating } from "../store/recurring";

/* Static */
import {devRecurringAvailabilityData, devRecurringAvailabilityUpdateData, devRecurringAvailabilityScheduleData, devRecurringAvailabilityScheduleUpdateData} from '../static/devdata';

export const tryGetRecurringAvailability = () => {
    return async dispatch => {
        dispatch(setRecurringAvailabilityReading(true));
        const sync = await axiosReq('apiv1/coach/settings/calendar-preferences', "GET", {}, devRecurringAvailabilityData);
        dispatch(setRecurringAvailability({"availability_calendar_update_method": sync.availability_calendar_update_method, "error": ''}));
        return dispatch(setRecurringAvailabilityReading(false));
    }
};

export const tryUpdateRecurringAvailability = (syncMethod) => {
    return async (dispatch, getState) => {
        dispatch(setRecurringAvailabilityUpdating(true));
        const enterpriseId = await getState().common.user.get("id");
        const sync = await axiosReq('apiv1/coach/settings/calendar-preferences', "PUT", {"id": enterpriseId, "availability_calendar_update_method": syncMethod}, devRecurringAvailabilityUpdateData(syncMethod));
        if(sync.hasOwnProperty("error")) {
            await dispatch(setRecurringAvailability({...getState().enterprise.recurring.get("recurringAvailability").toJS(), "error": sync.error}));
        } else {
            await dispatch(setRecurringAvailability({"availability_calendar_update_method": syncMethod, "error": ''}));
            await dispatch(tryGetRecurringAvailability());
        }
        return dispatch(setRecurringAvailabilityUpdating(false));
    }
};

export const tryDisconnectCalendarIntegration = () => {
    return async (dispatch, getState) => {
        dispatch(setRecurringAvailabilityUpdating(true));
        const sync = await axiosReq('apiv1/coach/settings/calendar-disconnect', "POST", {}, devRecurringAvailabilityUpdateData('willow'));
        if(sync.hasOwnProperty("error")) {
            await dispatch(setRecurringAvailability({...getState().enterprise.recurring.get("recurringAvailability").toJS(), "error": sync.error}));
        } else {
            console.log(sync)
            await dispatch(setRecurringAvailability({"availability_calendar_update_method": sync.availability_calendar_update_method, "error": ''}));
        }
        return dispatch(setRecurringAvailabilityUpdating(false));
    }
}

export const tryGetRecurringAvailabilitySchedule = () => {
    return async dispatch => {
        dispatch(setRecurringAvailabilityScheduleReading(true));
        const schedule = await axiosReq('apiv1/coach/settings/work-schedule', "GET", {}, devRecurringAvailabilityScheduleData);
        let scheduleAdj = await schedule.workSchedule.map((s, index) => {
            return({"id": index, "dayOfWeek": s.dayOfWeek, "startTime": s.startTime, "endTime": s.endTime, "startAfterEnd": true, "atLeastOneHr": true})
        });
        dispatch(setRecurringAvailabilitySchedule({"workSchedule": scheduleAdj, "error": ''}));
        return dispatch(setRecurringAvailabilityScheduleReading(false));
    }
};

export const tryUpdateRecurringAvailabilitySchedule = (workSchedule) => {
    return async (dispatch, getState) => {
        dispatch(setRecurringAvailabilityScheduleUpdating(true));
        let scheduleModified = await workSchedule.map(s => {return({"dayOfWeek": s.dayOfWeek, "startTime": s.startTime, "endTime": s.endTime})});
        const schedule = await axiosReq('apiv1/coach/settings/work-schedule', "POST", {"workSchedule": scheduleModified}, devRecurringAvailabilityScheduleUpdateData(workSchedule));
        if(schedule.hasOwnProperty("error")) {
            dispatch(setRecurringAvailabilitySchedule({...getState().enterprise.recurring.get("recurringAvailabilitySchedule").toJS(), "error": workSchedule.error}));
        } else {
            dispatch(setRecurringAvailabilitySchedule({"workSchedule": workSchedule, "error": ''}));
            dispatch(tryGetRecurringAvailability());
        }
        return dispatch(setRecurringAvailabilityScheduleUpdating(false));
    }
};

export const tryUpdateRecurringAvailabilityScheduleOutlookEmail = (email) => {
    return async dispatch => {
        dispatch(setRecurringAvailabilityUpdating(true));
        await axiosReq('apiv1/coach/settings/calendar-preferences-outlook-email', "PUT", {"email": email}, {});
        return dispatch(setRecurringAvailabilityUpdating(false));
    }
};

export const tryPostRecurringAvailabilitySync = () => {
    return async dispatch => {
        dispatch(setRecurringAvailabilitySyncing(true));
        const sync = await axiosReq('apiv1/coach/settings/run-calendar-sync', "POST", {}, {});
        if(sync.hasOwnProperty("error")) {

        }
        await dispatch(setRecurringAvailabilitySync(moment()));
        return dispatch(setRecurringAvailabilitySyncing(false));
    }
};




