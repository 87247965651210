import React from "react";
import styled from "styled-components";
import {colors} from "../../../common/components/colors";

const HTML = styled.div`
    background-color: ${colors.primary100};
    width: 100%;
    height: 1px;
    margin: 20px 0px;
`;

const ElementHTML = ({text, style}) => (
    <HTML style={style}>{decodeURI(text)}</HTML>
);

export default ElementHTML;