import * as Sentry from "@sentry/browser";

/* Domains */
import getDomainEnv from "../../domains/utils";
const env = getDomainEnv();

export const throwError = (error) => {
    Sentry.captureException(error);
    let error_url = "";
    if (env.ENVIRONMENT === "DEVELOPMENT") error_url += "http://";
    else error_url += "https://";

    error_url += `${env.URL.HOSTNAME}/error`;
    if (
        error.response &&
        error.response.data &&
        error.response.data.errorDetails
    )
        error_url += `?message=${error.response.data.errorDetails}`;
    window.location.replace(error_url);
};