import React from 'react';
import {connect} from 'react-redux';
import styled from "styled-components";
import {Link} from "react-router-dom";

/* Middleware */
import {tryGetWmcsForCoach} from "../middleware/coaching";

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {PageContent, PageTabs} from "../components/teamone";
import {Message} from "../../../common/components/messages";

/* Containers */
import TeamList from "./teamlist";

const InlineBtn = styled.div`
    width: fit-content;
    margin: -25px auto 0px auto;
`;

class Team extends React.Component {
    state={"isLoading": true, "allWmcs": []};

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.allWmcs !== this.props.allWmcs) {
            return this.update();
        }
    }

    init = async () => {
        if(!this.props.allWmcsFetched) {
            await this.props.tryGetWmcsForCoach()
        }
        this.update();
    };

    update = async () => {
        this.setState({"allWmcs": this.props.allWmcs, "isLoading": false});
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
                return(
                    <PageTabs>
                        <PageContent>
                            {this.state.allWmcs.size === 0
                                ? <>
                                    {this.props.user.get("isSupport")
                                        ? <Message text={"You are not assigned to any firms"}/>
                                        : <Message text={"Contact support to start partnering with firms!"}/>
                                    }
                                </>
                                : <TeamList type={this.props.type}/>
                            }
                        </PageContent>
                    </PageTabs>
                )
        }
    }

}

const mapStateToProps = state => ({
    user: state.common.user,
    allWmcs: state.enterprise.partners.partners.get("wmcsAssignedToCoach"),
    allWmcsFetched: state.enterprise.partners.partners.get("wmcsAssignedToCoachFetched"),
});

const mapDispatchToProps = dispatch => ({
    tryGetWmcsForCoach: () => dispatch(tryGetWmcsForCoach()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Team);
