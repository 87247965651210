import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {Helmet} from "react-helmet";

/* Components */
import {FontTitle30} from '../../common/components/fonts';
import {colors} from '../../common/components/colors';
import {images} from '../../common/components/images';
import {LogoLoading} from "../../common/components/loading";

/* Containers */
import SchedulerFlow from './containers/flow';

/* Middleware */
import {tryGetExpertIdFromPublicHandle, tryGetExpertProfileFromToken} from './middleware/profile';
import {tryCheckWMCById} from "../../common/containers/company/middleware/wmc";

/* Store */
import {setSignUpIsExclusive} from "../../routes/store/user";

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

const MessageCenter = styled.div`
    margin: 140px auto;
    text-align: center;
`;

const Background = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: ${colors.background100};
`;

const Container = styled.div`
    max-width: 768px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 60px auto 0px auto;
`;

class IFrameSchedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "isLoading": true,
            "expertFound": false,
            "event": null,
            "backgroundColorHex": '#ffffff'
        }
    }

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        let path = window.location.pathname;
        let pathAdj = path.charAt(path.length-1) === "/" ? path.slice(0, -1) : path;
        let pathSplit = pathAdj.split("/");

        if(pathSplit.length > 6) {
            return this.setState({"expertFound": false, "event": null, "isLoading": false});
        } else {
            let handle;
            let event;
            let styles;
            if(pathSplit.length === 6) {
                handle = pathSplit[pathSplit.length - 3];
                event = pathSplit[pathSplit.length - 2];
                styles = pathSplit[pathSplit.length - 1];

                let stylesAll = styles.split("&").reduce((result, token)=>{
                    let [key, value] = token.split('=');
                    result[key] = value;
                    return result;
                }, {});
                await this.setState({
                    "backgroundColorHex": stylesAll.backgroundColorHex === undefined ? this.state.backgroundColorHex : stylesAll.backgroundColorHex
                });

            } else {
                handle = pathSplit.length === 5 ? pathSplit[pathSplit.length - 2] : pathSplit[pathSplit.length - 1];
                event = pathSplit.length === 5 ? pathSplit[pathSplit.length - 1] : null;
                styles = null;
            }
            await this.props.tryGetExpertIdFromPublicHandle("scheduler", handle);
            if(this.props.schedulerHandle.get("error") === null) {
                const userId = this.props.schedulerHandle.get("id");
                await this.props.tryGetExpertProfileFromToken(userId);
                await this.props.tryCheckWMCById(userId);
                await this.props.setSignUpIsExclusive();
                return this.setState({"expertFound": this.props.profile.get("schedulerActive"), "event": event, "isLoading": false});
            } else {
                return this.setState({"expertFound": false, "event": event, "isLoading": false});
            }
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<Background><LoadingCenter><LogoLoading /></LoadingCenter></Background>)
        } else {
            if(this.state.expertFound) {
                return(
                    <>
                        <Helmet>
                            <link rel={"icon"} type={"image/png"} href={images.favicon16px} sizes={"16x16"} />
                            <meta charSet={"utf-8"} />
                            <title>{this.props.profile.get("firstName")}&nbsp;{this.props.profile.get("lastName")}&nbsp;|&nbsp;Willow</title>
                            <link rel={"canonical"} href={window.location.href} />
                            <meta name={"description"} content={this.props.profile.get("highlights")} />
                            <meta property={"og:type"} content={"website"} />
                            <meta property={"og:title"} content={this.props.profile.get("firstName")+" "+this.props.profile.get("lastName")+" | Willow"} />
                            <meta property={"og:description"} content={this.props.profile.get("highlights")} />
                            <meta property={"og:image"} content={this.props.profile.get("heroImageUrl")} />
                            <meta property={"og:url"} content={window.location.href} />
                            <meta property={"og:site_name"} content={"Willow"} />
                            <style type={"text/css"}>{`
                                body {
                                    background-color: #${this.state.backgroundColorHex} !important;
                                }    
                            `}</style>
                        </Helmet>
                        <Container>
                            <SchedulerFlow
                                userId={this.props.profile.get("id")}
                                userFirstName={this.props.profile.get("firstName")}
                                userLastName={this.props.profile.get("lastName")}
                                userPhotoUrl={this.props.profile.get("photo")}
                                eventSelectedId={this.state.event}
                            />
                        </Container>
                    </>
                )
            } else {
                return(<Background><MessageCenter><FontTitle30>No schedule found</FontTitle30></MessageCenter></Background>)
            }
        }
    }

}

const mapStateToProps = state => ({
    profile: state.iframe.scheduler.profile.get("profile"),
    schedulerHandle: state.iframe.scheduler.profile.get("profileHandle"),
});

const mapDispatchToProps = dispatch => ({
    tryGetExpertProfileFromToken: (id) => dispatch(tryGetExpertProfileFromToken(id)),
    tryGetExpertIdFromPublicHandle: (type, handle) => dispatch(tryGetExpertIdFromPublicHandle(type, handle)),
    tryCheckWMCById: (id) => dispatch(tryCheckWMCById(id)),
    setSignUpIsExclusive: () => dispatch(setSignUpIsExclusive())
});

export default connect(mapStateToProps, mapDispatchToProps)(IFrameSchedule);