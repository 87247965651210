import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setSettingsUsers, setSettingsUsersError, setSettingsUsersCreating, setSettingsUsersDownloading, setSettingsUsersSelect, setSettingsUsersUpdating} from "../store/settingsusers";

/* Static */
import {devSettingsUsersEmployees, devSettingsUsersEmployeesCreate} from '../../static/devdata';

export const tryGetUsersAdvisors = () => {
    return async (dispatch, getState) => {
        dispatch(setSettingsUsersDownloading(true));
        const wmcId = getState().common.user.get("wmc").get("id");
        const users = await axiosReq('apiv1/wmc/'+wmcId+'/employees', "GET", {}, devSettingsUsersEmployees);
        const employees = users.employees.map(u => {
            return({...u, "name": u.firstName+" "+u.lastName, "access": u.isActive ? "Admin" : "Deactivated", "linkOnTable": u.isActive})
        });
        dispatch(setSettingsUsers(employees));
        return dispatch(setSettingsUsersDownloading(false));
    }
};

export const tryCreateUserAdvisor = (data) => {
    return async dispatch => {
        dispatch(setSettingsUsersCreating(true));
        const createUser = await axiosReq('apiv1/wmc/add-employee', "POST", {firstName: data.first, lastName: data.last, phone: data.phone, email: data.email, timeZone: data.timeZone, wmcId: data.wmcId}, devSettingsUsersEmployeesCreate(data));
        if(createUser.hasOwnProperty("error")) {
            dispatch(setSettingsUsersError(createUser.error));
        } else {
            dispatch(setSettingsUsersError(""));
        }
        return dispatch(setSettingsUsersCreating(false));
    }
};

export const tryEditEmployee = (history, data) => {
    return async dispatch => {
        await dispatch(setSettingsUsersSelect(data));
        return history.push("/settings/users/edit");
    }
};

export const tryEditEmployeeFromMenu = (data) => {
    return async dispatch => {
        console.log(data);
        await dispatch(setSettingsUsersSelect(data.data));
        return data.history.push("/settings/users/edit");
    }
};

export const tryUpdateEditUserAdvisor = (data) => {
    return async dispatch => {
        dispatch(setSettingsUsersUpdating(true));
        const editUser = await axiosReq('apiv1/wmc/edit-employee/'+data.id, "PUT", {firstName: data.first, lastName: data.last, phone: data.phone.replace('(', '').replace(')', '').replace(' ', '').replace('-', ''), email: data.email, timeZone: data.timeZone, wmcId: data.wmcId}, devSettingsUsersEmployeesCreate(data));
        if(editUser.hasOwnProperty("error")) {
            dispatch(setSettingsUsersError(editadmUser.error));
        } else {
            dispatch(setSettingsUsersError(""));
        }
        return dispatch(setSettingsUsersUpdating(false));
    }
};


/*


Enterprise Settings Users
-----
POST apiv1/wmc/add-employee (add WMC employee)
GET apiv1/wmc/<int:wmc_id>/employees (get all employees of this WMC)
PUT apiv1/wmc/edit-employee/<int:employee_id> (update info on this employee), returns the same response as POST apiv1/wmc/add-employee (add employee)

Common WMC
-----
GET apiv1/wmc/by-employee/<int:employee_id> (GET WMC data by one of its employees ID)
GET apiv1/wmc/<int:wmc_id> (GET WMC data by its ID)

 */



