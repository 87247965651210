import { Map } from 'immutable';
import moment from "moment/moment";

/* Immutable */

const COURSE_ONE_CHANGED = 'COURSE_ONE_CHANGED';
const COURSES_STORE_RESET = 'COURSES_STORE_RESET';

/* Actions */

export const setCourseOneChanged = (datetime) => ({'type': COURSE_ONE_CHANGED, 'datetime': datetime});
export const setCoursesStoreReset = () => ({'type': COURSES_STORE_RESET});

/* Initial State */

const initialState = Map({
    courseOneChanged: moment(),
});

/* Reducer */

const coursesReducer = (state=initialState, action) => {
    switch (action.type) {
        case COURSE_ONE_CHANGED:
            return state.merge({'courseOneChanged': action.datetime});
        case COURSES_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default coursesReducer;
