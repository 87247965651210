import createEmailTemplates from "./templates/emails";
import createSMSTemplates from "./templates/sms";
import consumerFAQ from "./faqs/faqconsumer";
import expertFAQ from "./faqs/faqexpert";
import companyFAQ from "./faqs/faqcompany";
import expertResources from "./resources/resourcesexpert";
import enterpriseNav from "./nav/naventerprise";
import consumerNav from "./nav/navconsumer";
import consumerSignUpForm from "./forms/consumersignupform";
import matchingForm from "./forms/matchingform";
import images from "./styles/images";
import colors from "./styles/colors";
import fonts from "./styles/fonts";

export default {
    ENVIRONMENT: "DEVELOPMENT",
    URL: {
        DOMAIN: "luxaide.com",
        HOSTNAME: 'secure.luxaide.com',
        API: "api.luxaide.com",
        AUTH: "app.luxaide.com",
    },
    TRACKING: {
        SENTRY_DSN_STAGING: null,
        SENTRY_DSN_PRODUCTION: null,
        GOOGLE_TAG_MANAGER_ID: null,
        GOOGLE_ANALYTICS_ID: null,
        MIXPANEL_ID: null,
        FACEBOOK_ID: null,
        FULLSTORY_ORG_ID: null,
        TABOOLA: null,
    },
    HEAD: {
        TITLE: "Lux Aide",
        FAVICON_16: images.favicon16px,
        FAVICON_32: images.favicon32px,
        FAVICON_256: images.favicon256px,
        COLOR: colors.primary100,
        BACKGROUND_COLOR: colors.white,
        FONT_BODY: {FAMILY: fonts.body.fontFamily, URL: fonts.body.fontFileName, FORMAT: fonts.body.fontFormat},
        FONT_HEADER: {FAMILY: fonts.header.fontFamily, URL: fonts.header.fontFileName, FORMAT: fonts.header.fontFormat},
        FONT_SUBTITLE: {FAMILY: fonts.subtitle.fontFamily, URL: fonts.subtitle.fontFileName, FORMAT: fonts.subtitle.fontFormat},
        FONT_TITLE: {FAMILY: fonts.title.fontFamily, URL: fonts.title.fontFileName, FORMAT: fonts.title.fontFormat},
    },
    STYLES: {
        IMAGES: images,
        COLORS: colors,
        FONTS: fonts,
    },
    ENTERPRISE: {
        NAV: enterpriseNav,
        EXPERT: {
            AUTH: {
                CHECK_PARTNERED_WITH_COMPANY: false,
                SUPPORT_URL: "/firms",
                PARTNER_URL: "/partners",
                HOME_URL: "/prospects",
            },
            FAQS: expertFAQ,
            TEMPLATES: {
                EMAIL: createEmailTemplates,
                SMS: createSMSTemplates,
            },
            RESOURCES: {
                TITLE: "Coaching Resources",
                DATA: expertResources
            },
        },
        COMPANY: {
            AUTH: {
                HOME_URL: "/team",
            },
            FAQS: companyFAQ,
        }
    },
    CONSUMER: {
        AUTH: {
            REQUIRE_TEAM_TO_ACCESS_DASHBOARD: false,
            HOME_URL: "/my-shops",
            SIGN_UP_URL: "",
        },
        NAV: consumerNav,
        FAQS: consumerFAQ,
        SIGN_UP: {
            MATCH_AND_SCHEDULE: {
                MATCHING_TITLE: "Help Your Financial Coach Prepare",
                MATCHING_FORM: consumerSignUpForm,
                SCHEDULE_TITLE: "Schedule a Free 15-Minute Call",
                SCHEDULE_SESSION_MINUTES: 15
            },
            MATCH_PUBLIC_PAGE: {
                MATCHING_FORM: matchingForm
            }
        },
        PROGRESS: {
            MY_GOAL_FORM: []
        },
        TEAM: {
            PRODUCTS: {
                DOMAIN_SOLD: {
                    SHOW: false,
                    PRODUCT_TITLE: "",
                    TRIAL_TITLE: "",
                    TRIAL_SESSION_MINUTES: 0,
                    TRIAL_CHANGE_BUTTON_TITLE: "",
                    PAID_SESSION_MINUTES: 0
                },
                COMPANY_SOLD: {
                    SHOW: false,
                    PRODUCT_TITLE: "",
                    PAID_SESSION_MINUTES: 0
                },
                EXPERT_SOLD: {
                    SHOW: true
                },
            }
        }
    }
};