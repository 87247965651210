import React from "react";
import styled from "styled-components";

/* Components */
import { FontBody16 } from "../../../../common/components/fonts";
import { ButtonTertiaryAlert } from "../../../../common/components/buttons";

/* Static */
import { images } from "../../../../common/components/images";

export const Width = styled.div`
    max-width: 700px;
    width: 100%;
`;

const ConnectBox = styled.div`
    margin: 35px 0px 15px 0px;
    height: 50px;
    @media screen and (max-width: 460px) {
        height: auto;
    }
`;

const ConnectDetails = styled.div`
    display: inline-block;
    width: calc(100% - 200px);
    height: 50px;
    @media screen and (max-width: 460px) {
        width: 100%;
        height: auto;
    }
`;

const ConnectButton = styled.div`
    display: inline-block;
    width: 200px;
    vertical-align: top;
    text-align: right;
    margin-top: 9px;
    height: 50px;
    @media screen and (max-width: 460px) {
        display: block;
        margin: 35px 0px 15px 0px;
        width: 100%;
        height: auto;
    }
`;

const ConnectImg = styled.img`
    display: inline-block;
    height: 50px;
`;

const ConnectCTA = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 0px 0px 0px 10px;
    width: calc(100% - 60px);
    height: 50px;
    max-width: 250px;
    @media screen and (max-width: 460px) {
        height: auto;
    }
`;

const FitContent = styled.div`
    width: fit-content;
`;

export const Disconnect = ({ disconnect, type, outlookProxy }) => {
    let connectImg = images.callSyncBrand;
    let ctaText = "Recurring weekly schedule enabled";
    if (type === "google") {
        connectImg = images.calSyncBrandGoogle;
        ctaText = "Connected to your Google Calendar";
    } else if (type === "outlook") {
        connectImg = images.calSyncBrandMicrosoft;
        ctaText = `Connected to your Outlook Calendar ${
            outlookProxy !== null && outlookProxy
        }`;
    } else if (type === "calendly") {
        connectImg = images.calSyncBrandCalendly;
        ctaText = "Connected to your Calendly";
    }

    return (
        <ConnectBox>
            <ConnectDetails>
                <ConnectImg src={connectImg} height={50} width={50} />
                <ConnectCTA>
                    <FontBody16>{ctaText}</FontBody16>
                </ConnectCTA>
            </ConnectDetails>
            <ConnectButton>
                <FitContent onClick={() => disconnect()}>
                    <ButtonTertiaryAlert
                        canSubmit={false}
                        label={type === "willow" ? "Disable" : "Disconnect"}
                    />
                </FitContent>
            </ConnectButton>
        </ConnectBox>
    );
};
