import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {Link, withRouter} from 'react-router-dom';

/* Components */
import {LogoLoading} from "../../../../common/components/loading";
import FirmCoachPreview from '../components/coachpreview';
import {FontBody24, FontHeader26} from "../../../../common/components/fonts";
import {colors} from "../../../../common/components/colors";

/* Middleware */
import {tryGetAllMyCoaches} from "../middleware/coaches";

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;
const Coaches = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 40px;
    background-color: white;
    padding: 40px;
    border-radius: 30px;
    box-shadow: 0 10px 25px 0 rgb(0, 0, 0, 0.15);
    width: calc(100% - 80px);
`;
const ButtonRow = styled.div`
    height: 52px;
    margin: 70px 0 30px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
    width: calc(100% - 40px);
`;
const FindACoach = styled(Link)`
    color: ${colors.primary100};
`;
const FindACoachButton = styled(FontBody24)`
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
`;

class FirmCoachesMyCoaches extends React.Component {
    state = {
        "coaches": [],
        "isLoading": true
    }

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.setState({
            "coaches": await this.props.tryGetAllMyCoaches(),
            "isLoading": false
        });
    };

    view = async (data) => {
        this.props.history.push("/my-certificates/my-coaches/"+data.id);
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <ButtonRow>
                        <FindACoach to={"/my-certificates/my-coaches/browse"}>
                            <FindACoachButton>Find A Coach</FindACoachButton>
                        </FindACoach>
                    </ButtonRow>
                    <Coaches>
                        {this.state.coaches.length === 0 &&
                            <FontHeader26>You are not working with any coaches yet. Find a coach to begin.</FontHeader26>
                        }
                        {this.state.coaches.map((c, index) => (
                            <FirmCoachPreview key={index} coach={c} />
                        ))}
                    </Coaches>
                </>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryGetAllMyCoaches: () => dispatch(tryGetAllMyCoaches())
});

export default connect(null, mapDispatchToProps)(withRouter(FirmCoachesMyCoaches));
