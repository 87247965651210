import {axiosReq} from "../../../common/utils/axios";

/* Store */
import { setRateConsumer, setRateCoach, setRatingDownload, setRateError, setAlreadyRated, setRatingSubmitted, setRatingCreating } from "../store/rating";

/* Static */
import {devCoachRatingClientData, devCoachRatingCoachData} from '../static/devdata';

export const tryGetRatingToken = (token, isCoach) => {
    return async dispatch => {
        dispatch(setRatingDownload(true));
        let rating;
        if(isCoach) {
            rating = await axiosReq('apiv1/coach/clients/ratings/purchase/'+token, "GET", {}, devCoachRatingClientData);
        } else {
            rating = await axiosReq('apiv1/consumer/ratings/session/'+token, "GET", {}, devCoachRatingCoachData);
        }
        if(rating.hasOwnProperty("error")) {
            dispatch(setRateError());
            if(rating.error === "Already rated.") {
                dispatch(setAlreadyRated(true))
            }
        } else {
            if(isCoach) {
                dispatch(setRateConsumer(rating));
            } else {
                dispatch(setRateCoach(rating));
            }
        }
        return dispatch(setRatingDownload(false));
    }
};

export const tryPostRatingOfConsumer = (data) => {
    return async (dispatch, getState) => {
        dispatch(setRatingCreating(true));
        const clientId = getState().iframe.ratings.rating.get("rating").get("id");
        const rating = await axiosReq('apiv1/coach/clients/'+clientId+'/ratings/purchase', "POST", data, {});
        if(rating.hasOwnProperty("error")) {
            dispatch(setRateError());
            if(rating.error === "Rating already submitted.") {
                dispatch(setAlreadyRated(true))
            }
            // Handle error on ratings post of consumer
            dispatch(setRateConsumer({...data, "error": rating.error}));
        } else {
            dispatch(setRateConsumer({...data, "error": ""}));
            dispatch(setRatingSubmitted(true));
        }
        return dispatch(setRatingCreating(false));
    }
};

export const tryPostRatingOfCoach = (data) => {
    return async dispatch => {
        dispatch(setRatingCreating(true));
        const rating = await axiosReq('apiv1/consumer/ratings/session', "POST", data, {});
        // if(rating.hasOwnProperty("error")) {
        //     dispatch(setRateCoach({...data, "error": rating.error}));
        // } else {
            dispatch(setRateCoach({...data, "error": ""}));
            dispatch(setRatingSubmitted(true));
        // }
        return dispatch(setRatingCreating(false));
    }
};
