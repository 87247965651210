import React from "react";
import { Link, withRouter } from "react-router-dom";
import moment from "moment-timezone";
import styled from "styled-components";
import { connect } from "react-redux";

/* Components */
import { FontBody18, FontBody21, FontTitle28 } from "../../../../common/components/fonts";
import { images } from "../../../../common/components/images";
import { ButtonPrimary, ButtonTertiary } from "../../../../common/components/buttons";
import { ToolTipCreator } from "../../dashboard/components/tooltips";
import { colors } from "../../../../common/components/colors";

/* Domain */
import getDomainEnv from "../../../../domains/utils";
const env = getDomainEnv();

const PreviewGold = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 60px;
    row-gap: 30px;
    margin-bottom: 90px;

    border: 2px solid ${colors.action100};
    box-shadow: 0 0 20px ${colors.action100};
    padding: 90px 20px 90px 20px;
    @media screen and (max-width: 748px) {
        flex-direction: column;
    }
`;
const TitleSection = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 10px;
    justify-content: space-between;
    align-items: flex-start;
`;
const TitleSubtitle = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
`;
const Photo = styled.img`
    margin-top: 10px;
    width: 250px;
    height: 190px;
    object-fit: cover;
    border-radius: 30px;
`;
const Button = styled.div`
    & div {
        cursor: pointer;
        font-size: 18px;
    }
`;
const ButtonOff = styled.div`
    & div {
        cursor: default;
        font-size: 18px;
    }
`;
const SeeMore = styled(Link)`
    text-decoration: underline;
    color: ${colors.primary100};
    width: fit-content;
`;
const Description = styled.div`
    width: calc(100% - 310px);
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 1088px;
    @media screen and (max-width: 1440px) {
        width: 100%;
    }
    @media screen and (max-width: 748px) {
        width: auto;
    }
`;
const CECredit = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;

    & img {
        height: 32px;
        align-items: center;
        justify-content: flex-start;
    }
`;
const ButtonComplete = styled.div`
    & div {
        cursor: default;
    }
`;
const SponsoredText = styled(FontBody18)`
    margin-top: 20px;
    color: ${(props) => props.backgroundColorOverride};
`;

class PreviewEventsRequired extends React.Component {
    pushToCoaches = () => {
        this.props.history.push("/my-certificates/my-coaches");
    };

    pushToCert = () => {
        this.props.history.push("/my-certificates/activate");
    };

    getPromotionalProgramTime = () =>
        moment(this.props.t.startsOn).tz(this.props.timeZone).format("MMM D, YYYY [at] h:mm a") +
        " - " +
        moment(this.props.t.startsOn).add(50, "minutes").tz(this.props.timeZone).format("h:mm a z");

    getDescriptionFormatted = (description) => {
        let result = description;
        if (description.startsWith("<")) var span = document.createElement("span");
        span.innerHTML = description;
        result = span.textContent || span.innerText;
        if (result.length > 200) return result.slice(0, 197) + "...";
        return result;
    };

    shouldShowTooltip = this.props.showToolTip && this.props.toolTipPage === 4;

    getTrainingWatchButton = () => {
        if (this.props.isCompletedCertificationProgram)
            return (
                <ButtonComplete>
                    <ButtonTertiary canSubmit={false} label={"Program completed"} />
                </ButtonComplete>
            );
        if (!this.props.canTrainingBeTaken)
            return (
                <ButtonComplete>
                    <ButtonTertiary canSubmit={false} label={"Prerequisite not met!"} />
                </ButtonComplete>
            );
        if (this.props.t.hasCourse && this.props.t.isCourseCompleted && this.props.t.isQuizCompleted)
            return (
                <ButtonComplete>
                    <ButtonTertiary canSubmit={false} label={"Training completed"} />
                </ButtonComplete>
            );
        if (this.props.t.hasCourse && this.props.t.isCourseCompleted && !this.props.t.isQuizCompleted)
            return (
                <div
                    onClick={() => {
                        this.props.history.push("/my-tasks/course/" + this.props.t.quizId + "/quiz");
                    }}
                >
                    <ButtonPrimary canSubmit={false} label={"Take quiz"} />
                </div>
            );
        if (this.props.t.isCourseAssigned)
            return (
                <div
                    onClick={() => {
                        console.log("req trainings");
                        this.props.history.push("/my-tasks/course/" + this.props.t.courseId + "/video");
                    }}
                >
                    <ButtonPrimary canSubmit={false} label={"Continue video"} />
                </div>
            );
        else
            return (
                <div style={{ position: "relative" }}>
                    {this.props.index === 0 && this.shouldShowTooltip && (
                        <ToolTipCreator
                            text={"lorem ipsum text about training"}
                            show={true}
                            style={{ top: "-100px", right: "-50px" }}
                            linkToNext={this.pushToCoaches}
                            linkToBack={this.pushToCert}
                        />
                    )}

                    <div
                        onClick={() => {
                            this.props.history.push("/my-tasks/course/" + this.props.t.courseId + "/video");
                        }}
                    >
                        <ButtonPrimary canSubmit={false} label={"Watch video & take quiz"} />
                    </div>
                </div>
            );
    };

    render() {
        return (
            <PreviewGold
                ref={this.shouldShowTooltip && this.props.index === 0 && this.props.myRef}
                style={{
                    boxShadow: `${this.shouldShowTooltip ? "0 0 0 3HG`000px rgba(1,1,1,0.5)" : "unset"}`,
                }}
            >
                <Photo src={this.props.t.imageUrl} alt={""} />
                <Description>
                    <TitleSection>
                        <TitleSubtitle>
                            <FontTitle28 spaced={false}>{this.props.t.title}</FontTitle28>
                            <div>
                                {!this.props.t.isPromotionalProgram && (
                                    <FontBody21>{this.getPromotionalProgramTime()}</FontBody21>
                                )}
                                {this.props.t.ceCredits && (
                                    <CECredit>
                                        <img src={images.firmIconCECredit} alt={""} />
                                        <FontBody21>
                                            {this.props.t.ceCredits} Credit{this.props.t.ceCredits === 1 ? "" : "s"}
                                        </FontBody21>
                                    </CECredit>
                                )}
                            </div>
                        </TitleSubtitle>
                        {this.getTrainingWatchButton()}
                    </TitleSection>
                    <FontBody18>{this.getDescriptionFormatted(this.props.t.description)}</FontBody18>
                    <SeeMore to={"/my-certificates/my-trainings/" + this.props.t.id}>
                        <FontBody21>See more</FontBody21>
                    </SeeMore>
                    <SponsoredText backgroundColorOverride={colors.action100}>
                        {this.props.requiredMessage}
                    </SponsoredText>
                </Description>
            </PreviewGold>
        );
    }
}

const mapStateToProps = (state) => ({
    showToolTip: state.enterprise.tooltip.get("showToolTip"),
    toolTipPage: state.enterprise.tooltip.get("toolTipPage"),
});

export default connect(mapStateToProps, null)(withRouter(PreviewEventsRequired));
