import React from 'react';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';

import { RightOutlined, DownOutlined } from '@ant-design/icons';

import { colors } from '../../../common/components/colors';
import { FontHeader16, FontBody18, FontTitle21, FontBody16} from '../../../common/components/fonts';
import ReactHtmlParser from 'react-html-parser';

const NavOptions = styled.div`
    padding: 20px 30px 30px 30px;
    width: calc(100% - 60px);
    background-color: ${colors.backgroundColor1};
    border-radius: 20px 0px 20px 0px;
    a > div {
        border-top: 1px solid ${colors.white};
    }
    a:last-of-type > div {
        border-bottom: 1px solid ${colors.white};
    }
    a > div {
        color: ${colors.primary100};
        cursor: pointer;
    }
`;
const SectionTitle = styled(FontTitle21)`
    margin-bottom: 10px;
`;
const NavMenuItem = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
`;
const FAQ = styled.div`
    padding: 10px 0px 30px 30px;
    width: calc(100% - 30px);
    color: ${colors.primary100};
    & > div {
        background-color: ${colors.white};
        padding: 15px;
        border-radius: 0px 20px 0px 20px;
        width: calc(100% - 30px);
    }
`;
const LinkStyle = styled.div`
    display: inline-block;
    & a {
        color: ${colors.action100};
        text-decoration: underline !important;
    }
`;

import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

class OneFAQ extends React.Component {
    state={"open": false};

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps) {

    }

    htmlParserTransform = (node, index) => {
        if (node.type === "tag" && node.name === "a") {
            const {href} = node.attribs;
            if(node.attribs["data-link-type"] === 'external') {
                return (<LinkStyle key={index}><FontHeader16><a target={node.attribs["target"]} href={href}>{node.attribs["data-label"]}</a></FontHeader16></LinkStyle>)
            } else if(node.attribs["data-link-type"] === 'internal') {
                return (<LinkStyle key={index}><FontHeader16><Link to={href}>{node.attribs["data-label"]}</Link></FontHeader16></LinkStyle>)
            }
        } else if(node.type === "tag" && node.name === "br") {
            return(<br />)
        }
    };

    render() {
        return(
            <>
                <a onClick={() => {return this.setState({"open": !this.state.open})}}>
                    <NavMenuItem>
                        <FontBody18>{this.props.title}</FontBody18>
                        {this.state.open ?
                            <>
                                <DownOutlined/>
                            </>
                        :
                            <RightOutlined/>
                        }
                    </NavMenuItem>
                </a>
                {this.state.open && 
                    <FAQ>
                        <FontBody16>{ReactHtmlParser(this.props.body, {transform: this.htmlParserTransform})}</FontBody16>
                    </FAQ>
                }
            </>
        )
    }
}

class CoachingFAQs extends React.Component {
    state={};

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps) {

    }

    back = () => {
        this.props.history.push('/my-team/find')
    }

    render() {
        return(
            <>
                {/*<BackTop onClick={this.back}><FontBody16><LeftOutlined /> Back</FontBody16></BackTop>*/}
                {/*<br />*/}
                {/*<FontSubtitle38To30>{"FAQs"}</FontSubtitle38To30>*/}
                {/*<br />*/}
                {env.CONSUMER.FAQS.map((c, idx) => (
                    <NavOptions key={idx}>
                        <SectionTitle>{c.title}</SectionTitle>
                        {c.faqs.map((f, index) => {
                            return (<OneFAQ key={index} title={f.title} body={f.body} />)
                        })}
                    </NavOptions>
                ))}
            </>
        )
    }
}
export default withRouter(CoachingFAQs);