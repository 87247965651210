export const devDataCFPNumber = {
    "id": 1,
    "cfpNumber": null,
}

export const devDataIWINumber = {
    "id": 1,
    "iwiNumber": null,
}

export const devDataCDFANumber = {
    "id": 1,
    "cdfaIdNumber": null,
}