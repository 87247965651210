export const checkVanityWmcId = async (wmcId) => {
    const href = window.location.href;
    if(href.includes("localhost:3000")) {
        return("localhost:3000")
    } else if(href.includes("126thgqwgeqywe.trustwillow.com")) {
        return("126thgqwgeqywe.trustwillow.com")
    } else {
        if(wmcId === 59) {
            return("principal.trustwillow.com")
        } else if(wmcId === 37) {
            return("jpmorgan.trustwillow.com")
        } else if(wmcId === 156) {
            return("edwardjones.trustwillow.com")
        } else {
            return("secure.trustwillow.com")
        }
    }

}