import {FontBody16, FontTitle21} from "../../../components/fonts";
import {PhoneOutlined, VideoCameraOutlined} from "@ant-design/icons";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import React from "react";

import {CloseOutlined} from '@ant-design/icons';

import {NoticeRating, Title, CTAs, CTA, Icon, Subtitle, Pointer, Close, Content} from "../../noticerating/containers/noticerating";

/* Domain */
import getDomainEnv from '../../../../domains/utils';
const env = getDomainEnv();

const VideoAction = ({videoUrl, label}) => (
    <CTA onClick={() => {window.open('https://live.'+env.URL.DOMAIN+'/room/'+videoUrl, '_blank')}}><Pointer>
        <FontBody16>
            <Icon><VideoCameraOutlined /></Icon>
            <Subtitle underline={"underline"}>{label}</Subtitle>
        </FontBody16>
    </Pointer></CTA>
);

class NoticeCall extends React.Component {
    state = {"isShown": true};

    render() {
        return(
            <>
                {(this.props.noticeCall.get("id") !== undefined && this.props.noticeCall.get("id") !== null && this.state.isShown) &&
                <>
                    <NoticeRating zIndex={99}>
                        <Content>
                            {this.props.noticeCall.get("remoteUserName") === "Contact"
                                ? <Title><FontTitle21>{"Start your internal meeting"}</FontTitle21></Title>
                                : <Title><FontTitle21>{this.props.noticeCall.get("remoteUserReferralSource") === "willow" || (this.props.noticeCall.get("remoteUserReferralSource") === "wmc" && !this.props.isWMC) ? ("Start your meeting with "+this.props.noticeCall.get("remoteUserFirstName")+" "+this.props.noticeCall.get("remoteUserLastName").charAt(0).toUpperCase()+".") : ("Start your meeting with "+this.props.noticeCall.get("remoteUserName"))}</FontTitle21></Title>
                            }
                            <CTAs>
                                {/*<CTA>*/}
                                {/*    <FontBody16>*/}
                                {/*        <Icon><PhoneOutlined /></Icon>*/}
                                {/*        <Subtitle underline={"none"}>Call {this.props.noticeCall.get("phone")} room {this.props.noticeCall.get("room")}</Subtitle>*/}
                                {/*    </FontBody16>*/}
                                {/*</CTA>*/}
                                {/*{this.props.noticeCall.get("videoLinkSent") === false &&*/}
                                {/*    <>*/}
                                {/*        {this.props.noticeCall.get("videoThisUserPlatform") !== "consumer" &&*/}
                                {/*            <VideoAction videoUrl={"start"} label={"Start video call"} />*/}
                                {/*        }*/}
                                {/*    </>*/}
                                {/*}*/}
                                {/*{this.props.noticeCall.get("videoLinkSent") === true &&*/}
                                <>
                                    {this.props.noticeCall.get("videoStatus") === "conference_ongoing" &&
                                        <VideoAction videoUrl={this.props.noticeCall.get("videoUrl")} label={"Join by video"} />
                                    }
                                    {this.props.noticeCall.get("videoStatus") === "conference_has_not_yet_started" &&
                                        <VideoAction videoUrl={this.props.noticeCall.get("videoUrl")} label={"Join by video"} />
                                    }
                                    {this.props.noticeCall.get("videoStatus") === "conference_ended" &&
                                        null
                                    }
                                    {this.props.noticeCall.get("videoStatus") === "conference_canceled" &&
                                        null
                                    }
                                </>
                                {/*}*/}
                            </CTAs>
                        </Content>
                        <Close onClick={() => {this.setState({"isShown": false})}}><CloseOutlined /></Close>
                    </NoticeRating>
                </>
                }
            </>
        )
    }

}
const mapStateToProps = state => ({
    noticeCall: state.common.noticeCall.get("noticeCall"),
    isWMC: state.common.wmc.get("isWMC")
});

export const NoticeCallWrapper = connect(mapStateToProps, null)(withRouter(NoticeCall));
