import React from 'react';
import {withRouter} from 'react-router-dom';

/* Container */
// import FirmMarketingProfileAbout from './profileabout';
// import FirmMarketingProfileEdit from './profileedit';
import FirmMarketingCriteriaQuestions from '../components/criteriaquestions';

class FirmMarketingCriteria extends React.Component {
    render() {
        return (
            <div>
                <FirmMarketingCriteriaQuestions />
            </div>
        )
    }
}

export default withRouter(FirmMarketingCriteria);