import React from 'react';
import {withRouter} from 'react-router-dom';

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FontBody16} from '../../../common/components/fonts';
import {PageTitle, Container, Extra54px, Pill, PillBar} from '../components/help';

/* Containers */
import HelpContact from "./helpcontact";
import HelpFAQ from "./helpfaqv2";
import Success from "../../../iframes/success/containers/success";
import Feedback from "./helpfeedback";

class HelpSelector extends React.Component {
    state = {
        "isLoading": true,
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({"isLoading": false})
    };

    changeTab = async (tabToSwitchTo) => {
        await this.props.history.push("/support"+tabToSwitchTo);
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            if(this.props.location.pathname === "/support/contact/message-sent") {
                return(
                    <Success
                        back={() => {this.changeTab("/contact")}}
                        title={"MESSAGE SENT!"}
                        subtitle={"Thanks for letting us know how we can help you through your life journeys. A member of our team will get back to you."}
                        buttonLabel={"Close"}
                    />
                )
            } else if(this.props.location.pathname === "/support/feedback/message-sent") {
                return(
                    <Success
                        back={() => {this.changeTab("/feedback")}}
                        title={"FEEDBACK SENT!"}
                        subtitle={"Thanks for letting us know how we can help you through your life journeys. A member of our team will get back to you."}
                        buttonLabel={"Close"}
                    />
                )
            } else {
                return(
                    <Container>
                        <Extra54px />
                        <PageTitle>{'HOW CAN WE HELP?'}</PageTitle>
                        <PillBar>
                            <Pill active={this.props.location.pathname === '/support'} onClick={() => {this.changeTab('');}}>
                                <FontBody16>{'FAQs'}</FontBody16>
                            </Pill>
                            <Pill active={this.props.location.pathname === '/support/feedback'} onClick={() => {this.changeTab('/feedback');}}>
                                <FontBody16>{'Feedback'}</FontBody16>
                            </Pill>
                            <Pill active={this.props.location.pathname === '/support/contact'} onClick={() => {this.changeTab('/contact');}}>
                                <FontBody16>{'Contact Us'}</FontBody16>
                            </Pill>
                        </PillBar>
                        {this.props.location.pathname === '/support' &&
                            <HelpFAQ />
                        }
                        {this.props.location.pathname === '/support/feedback' &&
                            <Feedback
                                inputPlaceholder={"We love feedback! Tell us what you like or what we can do better."}
                                inputButtonLabel={"Send"}
                                successTitle={"FEEDBACK SENT!"}
                                successSubtitle={"Thanks for letting us know how we can help you through your life journeys. A member of our team will get back to you."}
                                successButtonLabel={"Close"}
                            />
                        }
                        {this.props.location.pathname === '/support/contact' &&
                            <HelpContact />
                        }
                    </Container>
                )
            }
        }
    }
}

export default withRouter(HelpSelector);
