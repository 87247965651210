import React from 'react'
import {MenuIcon, NavTopMobile} from "../../../enterprise/navbars/components/secondarymobile";
import {FontHeader16} from "../../../common/components/fonts";
import {setNavPageStatus} from "../../../common/containers/navexpanded/store/nav";
import {connect} from "react-redux";


    const ConsumerNavBottomUpdated = ({ pageStatusActive, setNavPageStatus }) => (
        <NavTopMobile onClick={() => {setNavPageStatus(!pageStatusActive)}}>
            <MenuIcon>
                <FontHeader16>Menu</FontHeader16>
            </MenuIcon>
        </NavTopMobile>
    );

    const mapStateToProps = state => ({
        pageStatusActive: state.common.nav.get('pageStatusActive')
    });

    const mapDispatchToProps = dispatch => ({
        setNavPageStatus: (status) => dispatch(setNavPageStatus(status))
    });

    export default connect(mapStateToProps, mapDispatchToProps)(ConsumerNavBottomUpdated);
