import React from 'react';
import styled from "styled-components";

import {CheckboxWithIconTitleBody} from '../../../common/components/inputs';
import {FontBody14, FontHeader18} from "../../../common/components/fonts";
import {images} from "../../../common/components/images";

const Subtitle = styled(FontHeader18)`
    margin-bottom: 10px;
`;

const Disclosure = styled(FontBody14)`
    margin-bottom: 10px;
`;

const Options = styled.div`
    margin: 10px 0px;
`;

const chooseIcon = (filename) => {
    if(filename === "summarypage_stars") {return images.consumerSignUpIconBooked;}
    else if(filename === "verifypage_phone") {return images.consumerSignUpIconVerify;}
    else if(filename === "coachstyle_emotional") {return images.coachingStyleEmotional;}
    else if(filename === "coachstyle_military") {return images.coachingStyleMilitary;}
    else if(filename === "coachstyle_nononsense") {return images.coachingStyleNoNonsense;}
    else if(filename === "coachstyle_spiritual") {return images.coachingStyleSpiritual;}
    else if(filename === "coachstyle_underserved") {return images.coachingStyleUnderServed;}
    else if(filename === "icon_balances") {return images.consumerSignUpIconBalances;}
    else if(filename === "icon_connect") {return images.willowTalkIconInfo;}
    else if(filename === "icon_goals") {return images.consumerSignUpIconGoals;}
    else if(filename === "icon_guidance") {return images.consumerSignUpIconGuidance;}
    else if(filename === "icon_learn") {return images.willowTalkIconLearn;}
    else if(filename === "icon_listen") {return images.willowTalkIconListen;}
};

class ElementSpecialCoaching extends React.Component {
    constructor(props) {
        super(props);
        this.state = {options: [], isLoading: true}
    }

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.elementId !== this.props.elementId) {
            return this.init();
        }
    }

    init = async () => {
        this.setState({"options": this.props.options});
    };

    onSelect = async (id, status) => {
        await this.setState({
            "options": this.state.options.map(d => {
                if(d.id === id) {
                    return ({...d, active: status});
                } else {
                    return d;
                }
            }),
        });
        // Does not need isRequired
        return this.props.onSaveElement(this.props.elementId, {"options": this.state.options}, true)
    };

    render() {
        return(
            <>
                <Subtitle style={this.props.style.subtitle}>{this.props.subtitle}</Subtitle>
                <Disclosure style={this.props.style.disclosure}>{this.props.disclosure}</Disclosure>
                <Options style={this.props.style.options}>
                    {this.state.options.map(j => (
                        <CheckboxWithIconTitleBody
                            key={j.id}
                            id={j.id}
                            icon={chooseIcon(j.icon)}
                            title={j.title}
                            body={j.body}
                            onClick={this.onSelect}
                            checked={j.active}
                            style={this.props.style}
                        />
                    ))}
                </Options>
            </>
        )
    }
}
export default ElementSpecialCoaching;
