import React from 'react';
import styled from "styled-components";
import {connect} from "react-redux";

/* Middleware */
import {tryGetExpertProfile} from "../middleware/expertprofile";

/* Components */
import {LogoLoading} from "../../../common/components/loading";

/* Containers */
import ProfileEndorsements from "./profileendorsements";

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

class ShopPageTestimonials extends React.Component {
    state = {"isLoading": true};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.props.tryGetExpertProfile();
        this.setState({"isLoading": false});
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return (
                <>
                    <br />
                    <ProfileEndorsements />
                </>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryGetExpertProfile: () => dispatch(tryGetExpertProfile()),
});

export default connect(null, mapDispatchToProps)(ShopPageTestimonials);
