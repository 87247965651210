import moment from "moment";
import {axiosReq} from "../../../../common/utils/axios";
import {notesReportResponse} from "../static/devdata"

export const tryGetReportsForDateRange = async (startDate, endDate) => {
    const reports = await axiosReq('apiv1/wmc/reports/notes', "POST", {
        "startDateTime": moment(startDate).toISOString(),
        "endDateTime": moment(endDate).toISOString()
    }, notesReportResponse)
    return reports
}