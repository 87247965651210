// https://stripe.com/docs/payments/integration-builder
// https://stripe.com/docs/billing/subscriptions/fixed-price

import React, {useEffect, useMemo, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import validator from "validator";
import styled from "styled-components";

/* Stripe */
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
const stripePromise = loadStripe("pk_live_SrxO9EO9za9GbiIrCpNIdriZ00IiNcMrBr");

/* Components */
import {ButtonPrimary, ButtonInactive} from "../../../common/components/buttons";
import {colors} from "../../../common/components/colors";
import {FontBody14, FontHeader18, FontHeader16, FontBody21, FontBody16, FontHeader21} from "../../../common/components/fonts";
import {Inline, EmailLow, DisclosureComponent, Box, PurchaseButton, ModalTitle, ProductTermPrivacy, LoaderStyle, FullWidthNotButtonText, CardInfoTitle, Action, FullWidthNotButton, Red, LoginBtn, Left, Right, PhoneEmpty, Gray, Mobile, Container, StripeComponents, Email, Top, Bottom, Phone, FullWidth, Disclosure} from '../components/payment';
import {PhoneInput, TextInput} from "../../../common/components/inputs";
import {LogoLoading, LogoLoadingSmall} from "../../../common/components/loading";

/* Middleware */
import {tryRepurchase, tryPurchase, tryPurchaseIntent} from '../middleware/checkout';
import CardsOne from "../../cards/containers/cardsone";

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

const useResponsiveFontSize = () => {
    const getFontSize = () => (window.innerWidth < 450 ? "16px" : "16px");  //"18px");
    const [fontSize, setFontSize] = useState(getFontSize);
    useEffect(() => {
        const onResize = () => {setFontSize(getFontSize());};
        window.addEventListener("resize", onResize);
        return () => {window.removeEventListener("resize", onResize);};
    });
    return fontSize;
};

const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    return useMemo(
        () => ({
            iconStyle: 'solid',
            style: {
                base: {
                    iconColor: colors.primary70,
                    fontSize,
                    color: colors.primary100,
                    letterSpacing: "0.025em",
                    fontFamily: "Source Code Pro, monospace",
                    "::placeholder": {
                        color: colors.primary70
                    }
                },
                invalid: {
                    iconColor: colors.alert100,
                    color: colors.primary100
                }
            },
        }),
        [fontSize]
    );
};

function PaymentCardComponents(props) {
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    const dispatch = useDispatch();
    const user = useSelector(state => state.common.user);

    const intentSecret = useRef("");

    const [metadata, setMetadata] = useState(null);
    const [inModal, setInModal] = useState(false);
    const [fromDashboard, setFromDashboard] = useState(false);
    const [planId, setPlanId] = useState(null);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [showUserLogin, setShowUserLogin] = useState(true);
    const [userExists, setUserExists] = useState(false);
    const allowPublicDomainSignups = props.allowPublicDomainSignups;
    const [emailIsPersonal, setEmailIsPersonal] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [firstNameValid, setFirstNameValid] = useState(true);
    const [lastName, setLastName] = useState("");
    const [lastNameValid, setLastNameValid] = useState(true);
    const [email, setEmail] = useState("");
    const [emailValid, setEmailValid] = useState(true);
    const [phone, setPhone] = useState("");
    const [phoneValid, setPhoneValid] = useState(true);
    const [credit, setCredit] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isPurchasing, setIsPurchasing] = useState(false);
    const [paymentMethodSelected, setPaymentMethodSelected] = useState({});
    const [showPaymentMethods, setShowPaymentMethods] = useState(false);

    const [error, setError] = useState(null);
    const [latestInvoiceId, setLatestInvoiceId] = useState(null);
    const [latestInvoicePaymentIntentStatus, setLatestInvoicePaymentIntentStatus] = useState(null);

    useEffect(() => {
        const init = async () => {
            setMetadata(props.metadata);
            setPlanId(props.planId);
            setInModal(props.inModal);
            setFromDashboard(props.fromDashboard);
            setUserLoggedIn(props.userLoggedIn);
            setShowUserLogin(props.showUserLogin);
            if(props.paymentMethods.length > 0) {
                setPaymentMethodSelected(props.paymentMethods[0])
            }
            // setIsLoading(false);
        };
        init();
    }, []);

    useEffect(() => {
        setFirstNameValid((!validator.isEmpty(firstName) && firstName !== ''));
    }, [firstName]);

    useEffect(() => {
        setLastNameValid((!validator.isEmpty(lastName) && lastName !== ''));
    }, [lastName]);

    useEffect(() => {
        setEmailValid(validator.isEmail(email));
        const personalDomains = ['outlook.com', 'gmail.com', 'yahoo.com', 'inbox.com', 'icloud.com', 'mail.com', 'gmx.com', 'yandex.com', 'proton.me']
        const isPersonalEmail = personalDomains.includes(email.split("@")[1]);
        setEmailIsPersonal(isPersonalEmail)
    }, [email]);

    useEffect(() => {
        setPhoneValid(validator.isMobilePhone(phone, 'en-US'));
    }, [phone]);

    useEffect(() =>{
        setUserExists(false);
    }, [firstName, lastName, email, phone]);

    useEffect(() => {
        if(!userLoggedIn && firstNameValid && lastNameValid && emailValid && /*phoneValid &&*/ credit) {
            setIsValid(true);
        } else if(userLoggedIn) {
            if(!showPaymentMethods && Object.keys(paymentMethodSelected).length === 0 && firstNameValid && lastNameValid && credit) {
                setIsValid(true);
            } else if(!showPaymentMethods && Object.keys(paymentMethodSelected).length > 0) {
                setIsValid(true);
            } else {
                setIsValid(false);
            }
        } else {
            setIsValid(false);
        }
    }, [userLoggedIn, firstNameValid, lastNameValid, emailValid, phoneValid, credit, userExists, showPaymentMethods, paymentMethodSelected]);

    const handleChangeFirstName = e => setFirstName(e.target.value);
    const handleChangeLastName = e => setLastName(e.target.value);
    const handleChangeEmail = e => setEmail(e.target.value);
    const handleChangePhone = e => setPhone(e.target.value);
    const handleChangeCreditCard = e => setCredit(e.complete);
    const handleNotUserLoggedIn = e => setUserLoggedIn(false);
    const handleChangeShowPaymentMethods = e => {setError(null);setShowPaymentMethods(true);setPaymentMethodSelected({})};
    const handleChangeAddPaymentMethod = e => {setError(null);setShowPaymentMethods(false);setPaymentMethodSelected({})};
    const handleChangeSelectPaymentMethod = e => {setError(null);setShowPaymentMethods(false);setPaymentMethodSelected(e)};

    function handlePaymentThatRequiresCustomerAction({subscription, invoice, paymentMethodId, isRetry,}) {
        console.log("PaymentCard - handlePaymentThatRequiresCustomerAction")

        if (subscription && subscription.status === 'active') {
            return { subscription, paymentMethodId };
        }
        let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;
        if (paymentIntent.status === 'requires_action' || (isRetry === true && paymentIntent.status === 'requires_payment_method')) {
            return stripe
                .confirmCardPayment(paymentIntent.client_secret, {payment_method: paymentMethodId,})
                .then((result) => {
                    console.log("PaymentCard - handlePaymentThatRequiresCustomerAction confirmCardPayment result", result)
                    if (result.error) {
                        throw result;
                    } else {
                        if (result.paymentIntent.status === 'succeeded') {
                            return {
                                subscription: subscription,
                                invoice: invoice,
                                paymentMethodId: paymentMethodId,
                            };
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setError(error);
                    setIsPurchasing(false);
                });
        } else {
            return { subscription, paymentMethodId };
        }
    }

    function handleRequiresPaymentMethod({subscription, paymentMethodId}) {
        console.log("PaymentCard - handleRequiresPaymentMethod")

        if (subscription.status === 'active') {
            return { subscription, paymentMethodId };
        } else if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
            console.log("PaymentCard - requires_payment_method")

            setLatestInvoiceId(subscription.latest_invoice.id);
            setLatestInvoicePaymentIntentStatus(subscription.latest_invoice.payment_intent.status);
            throw { error: { message: 'Your card was declined.' } };
        } else {
            return { subscription, paymentMethodId };
        }
    }

    function onSubscriptionComplete(result) {
        console.log("PaymentCard - onSubscriptionComplete")
        console.log(result.subscription)

        if (result.subscription.status === 'active') {
            console.log("PaymentCard - onSubscriptionComplete")
            return props.paymentSuccessful();
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();
        if (!stripe || !elements) {
            console.log("PaymentCard - stripe || elements missing", !stripe, !elements)
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        setIsPurchasing(true);
        setError(null);

        let checkUser;
        if(!userLoggedIn) {
            console.log("PaymentCard - user not logged in")
            let userEmail = email;
            console.log(phone);
            let userPhone = parseInt(phone.replace('(', '').replace(')', '').replace(' ', '').replace('-', ''));
            checkUser = await props.checkUserExists(firstName, lastName, userEmail, userPhone);
            console.log("PaymentCard - checkUserExists", checkUser)
            if (checkUser.statusExists) {
                setUserExists(true);
                setIsValid(false);
                setIsPurchasing(false);
                return;
            }
        } else {
            console.log("PaymentCard - user logged in")
            checkUser = {
                "user": {
                    "first": user.get("first"),
                    "last": user.get("last"),
                    "email": user.get("email"),
                    "phone": user.get("phone")
                }
            }
        }

        let selectedPaymentMethodId = null;
        let newPaymentMethodData = null;

        if(Object.keys(paymentMethodSelected).length > 0) {
            console.log("PaymentCard - paymentMethodSelected has keys", paymentMethodSelected)
            selectedPaymentMethodId = paymentMethodSelected.id
        } else {
            console.log("PaymentCard - newPaymentMethodData")
            newPaymentMethodData = {
                type: "card",
                card: elements.getElement(CardElement),
                billing_details: {
                    name: checkUser.user.first + " " + checkUser.user.last,
                    email: checkUser.user.email,
                    phone: checkUser.user.phone
                }
            }
        }

        if(props.planType === "one_time") {
            console.log("PaymentCard - planType == one_time")
            if(intentSecret.current === "") {
                console.log("PaymentCard - requesting purchase intent")
                const paymentIntent = await dispatch(tryPurchaseIntent(props.planId, selectedPaymentMethodId, props.quantity, props.webinarProgramId, metadata));
                intentSecret.current = paymentIntent.client_secret
                console.log("PaymentCard - purchase intent secret received")
            }

            let confirm;
            console.log("PaymentCard - confirming card payment")
            if(selectedPaymentMethodId === null) {
                console.log("PaymentCard - selectedPaymentMethodId === null")
                confirm = await stripe.confirmCardPayment(intentSecret.current, {payment_method: newPaymentMethodData});
            } else {
                console.log("PaymentCard - selectedPaymentMethodId !== null")
                confirm = await stripe.confirmCardPayment(intentSecret.current);
            }

            if (confirm.hasOwnProperty("error")) {
                console.log("PaymentCard - confirmed card has error", confirm.error)
                setError(confirm.error.message);
                setIsPurchasing(false);
                return;
            }

            console.log("PaymentCard - confirmed card payment", confirm.paymentIntent.id)
            props.paymentSuccessful();
            setIsPurchasing(false);

        } else if(props.planType === "recurring") {
            console.log("PaymentCard - planType == recurring")

            if(selectedPaymentMethodId === null) {
                console.log("PaymentCard - creating payment method")
                const newPaymentMethod = await stripe.createPaymentMethod(newPaymentMethodData);
                console.log("PaymentCard - [PaymentMethod]", newPaymentMethod);

                if (newPaymentMethod.hasOwnProperty("error")) {
                console.log("PaymentCard - creating payment method error")
                setError(newPaymentMethod.error.message);
                    setIsPurchasing(false);
                    return;
                }

                selectedPaymentMethodId = newPaymentMethod.paymentMethod.id;
            }


            // if (latestInvoiceId !== null && latestInvoicePaymentIntentStatus==='requires_payment_method'){
            //     retryInvoiceWithNewPaymentMethod(selectedPaymentMethodId, latestInvoiceId)
            // } else {
                return dispatch(tryPurchase(selectedPaymentMethodId, planId))
                    .then((result) => {
                        console.log("PaymentCard - purchase result")
                        console.log(result);
                        if (result.error) {throw result;}
                        return ({subscription: result, paymentMethodId: selectedPaymentMethodId,});
                    })
                    .then(handlePaymentThatRequiresCustomerAction)
                    .then(handleRequiresPaymentMethod)
                    .then(onSubscriptionComplete)
                    .then(() => {setIsPurchasing(false);})
                    .catch((error) => {setError(error.message);setIsPurchasing(false);})
            // }

        }
    };

    function retryInvoiceWithNewPaymentMethod({paymentMethodId, invoiceId}) {
        return dispatch(tryRepurchase(paymentMethodId, invoiceId))
            .then((result) => {
                console.log(result);
                if (result.error) {throw result;}
                return ({invoice: result, paymentMethodId: paymentMethodId, isRetry: true,});
            })
            .then(handlePaymentThatRequiresCustomerAction)
            .then(onSubscriptionComplete)
            .then(() => {setIsPurchasing(false);})
            .catch((error) => {setError(error.message);setIsPurchasing(false);});
    }

    // if(isLoading) {
    //     return(<LoadingCenter><LogoLoading /></LoadingCenter>)
    // }

    return(
        <form method={"post"} onSubmit={handleSubmit}>
            <Container inModal={inModal}>
                <div>
                    {fromDashboard
                        ? <ModalTitle><FontHeader18>Pay with card</FontHeader18></ModalTitle>
                        : <>
                            <Left><FontHeader21>Pay with card</FontHeader21></Left>
                            {showUserLogin &&
                                <>
                                    {!userLoggedIn
                                        ? <Right><FontBody16>Have an account?&nbsp;<LoginBtn onClick={props.login}><FontHeader16>Log in</FontHeader16></LoginBtn></FontBody16></Right>
                                        : <Right><LoginBtn onClick={handleNotUserLoggedIn}><FontHeader16>Not {user.get("first")}?</FontHeader16></LoginBtn></Right>
                                    }
                                </>
                            }
                        </>
                    }
                </div>
                {(userLoggedIn && !inModal && !fromDashboard) &&
                    <Email><FontBody21>Welcome back {user.get("first")}!</FontBody21></Email>
                }
                <Box inModal={inModal}>
                {(userLoggedIn && showPaymentMethods) &&
                    <>
                        <CardInfoTitle inModal={inModal}>
                            <Gray inModal={inModal}><FontBody16>Select a saved card</FontBody16></Gray>
                            <Action inModal={inModal} onClick={handleChangeAddPaymentMethod}><FontHeader16>New card</FontHeader16></Action>
                        </CardInfoTitle>
                        {props.paymentMethods.map((p, i) => (
                            <CardsOne
                                key={p.id}
                                data={p}
                                canSelect={true}
                                onCardSelect={() => {handleChangeSelectPaymentMethod(p)}}
                                canEdit={false}
                                onViewCardEdit={() => {}}
                                backgroundColor={inModal ? colors.border70 : colors.white}
                            />
                        ))}
                    </>
                }
                {(userLoggedIn && !showPaymentMethods && Object.keys(paymentMethodSelected).length > 0) &&
                    <div>
                        <CardInfoTitle inModal={inModal}>
                            <Gray inModal={inModal}><FontBody16>Card selected</FontBody16></Gray>
                            <Action inModal={inModal} onClick={handleChangeShowPaymentMethods}><FontHeader16>Change card</FontHeader16></Action>
                        </CardInfoTitle>
                        <CardsOne
                            key={paymentMethodSelected.id}
                            data={paymentMethodSelected}
                            canSelect={false}
                            onCardSelect={() => {}}
                            canEdit={false}
                            onViewCardEdit={() => {}}
                            backgroundColor={inModal ? colors.border70 : colors.white}
                        />
                        {error !== null && <Red><FontBody16>{error}</FontBody16></Red>}
                    </div>
                }
                {(!showPaymentMethods && Object.keys(paymentMethodSelected).length === 0) &&
                    <>
                        <StripeComponents>
                            <CardInfoTitle inModal={inModal}>
                                <Gray inModal={inModal}><FontBody16>Card information</FontBody16></Gray>
                                {(userLoggedIn && props.paymentMethods.length > 0) && <Action inModal={inModal} onClick={handleChangeShowPaymentMethods}><FontHeader16>Use saved card</FontHeader16></Action>}
                            </CardInfoTitle>
                            <CardElement
                                options={options}
                                onChange={handleChangeCreditCard}
                            />
                            {error !== null && <Red><FontBody16>{error}</FontBody16></Red>}
                        </StripeComponents>
                        <Top>
                            <TextInput
                                title={"Name on card"}
                                valid={(firstName === "" && lastName === "") ? true : (firstNameValid && lastNameValid)}
                                warning={"Required"}
                                id={"firstName"}
                                onChange={handleChangeFirstName}
                                placeholder={"First name"}
                                value={firstName}
                            />
                        </Top>
                        <Bottom>
                            <TextInput
                                title={undefined}
                                valid={true}
                                warning={undefined}
                                id={"lastName"}
                                onChange={handleChangeLastName}
                                placeholder={"Last name"}
                                value={lastName}
                            />
                        </Bottom>
                    </>
                }
                {!userLoggedIn
                    ?
                    <EmailLow>
                        <TextInput
                            title={"Email"}
                            valid={userExists ? false : ((!allowPublicDomainSignups && emailIsPersonal) ? false : (email === "" ? true : emailValid))}
                            warning={userExists ? "Email registered, log in to purchase" : ((!allowPublicDomainSignups && emailIsPersonal) ? "Company email required" : "Enter a valid email address")}
                            id={"email"}
                            onChange={handleChangeEmail}
                            placeholder={"Email"}
                            value={email}
                        />
                    </EmailLow>
                    :
                    <PhoneEmpty />
                }
                {/*{!userLoggedIn*/}
                {/*    ?*/}
                {/*        <Phone>*/}
                {/*            <PhoneInput*/}
                {/*                title={"Mobile phone"}*/}
                {/*                valid={phone === "" ? true : phoneValid}*/}
                {/*                warning={"Enter a valid mobile phone"}*/}
                {/*                id={"phone"}*/}
                {/*                onChange={handleChangePhone}*/}
                {/*                placeholder={'(###) ###-####'}*/}
                {/*                value={phone}*/}
                {/*                options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}}*/}
                {/*            />*/}
                {/*        </Phone>*/}
                {/*    :*/}
                {/*        <PhoneEmpty />*/}
                {/*}*/}
                {!showPaymentMethods &&
                    <>
                        {isPurchasing
                            ? <LoaderStyle><LogoLoadingSmall /></LoaderStyle>
                            : <PurchaseButton inModal={inModal}>
                                {isValid
                                    ? <FullWidth oneLevel={isValid}><ButtonPrimary canSubmit={true} label={props.planType === "one_time" ? "PURCHASE" : "PURCHASE"}/></FullWidth>
                                    : <FullWidthNotButtonText><ButtonInactive canSubmit={false} label={props.planType === "one_time" ? "PURCHASE" : "PURCHASE"}/></FullWidthNotButtonText>
                                }
                            </PurchaseButton>
                        }
                        <DisclosureComponent
                            termsOfUseUrl={inModal ? props.product.get("termsOfUseUrl") : null}
                            planType={props.planType}
                            pmtAuthorizeName={inModal ? "Willow Network, Inc." : props.product.get("expert").get("fullName")}
                        />
                        {!inModal &&
                            <Mobile>
                                <ProductTermPrivacy
                                    termsOfUseUrl={props.product.get("termsOfUseUrl")}
                                    privacyPolicyUrl={props.product.get("privacyPolicyUrl")}
                                />
                            </Mobile>
                        }
                    </>
                }
                </Box>
            </Container>
        </form>
    )
}


const PaymentCard = ({checkUserExists, paymentSuccessful, inModal, planId, login, userLoggedIn, planType, product, paymentMethods, quantity, webinarProgramId, fromDashboard, showUserLogin}) => (
    <Elements stripe={stripePromise}>
        <PaymentCardComponents
            checkUserExists={checkUserExists}
            paymentSuccessful={paymentSuccessful}
            inModal={inModal}
            planId={planId}
            login={login}
            userLoggedIn={userLoggedIn}
            showUserLogin={showUserLogin}
            planType={planType}
            product={product}
            paymentMethods={paymentMethods}
            quantity={quantity}
            webinarProgramId={webinarProgramId}
            fromDashboard={fromDashboard}
        />
    </Elements>
);

export default PaymentCard;