export const devFailedTask = {
    "userId":4470,
    "completedSurveys":[
        {
            "id":7,
            "surveyId":'XeBmERhQ',
            "completedOn":"2022-09-01T16:32:33.825210+00:00",
            "answers":[],
            "quizResults":{
                "score":20
            },
            "surveyRawResponse": {
                "form_id": "XeBmERhQ"
            }
        }
    ]
}