import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-image: url(${props => props.photoUrl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 30%;
  transition: background 0.5s linear;
  height: 100%;
  width: 100%;
  aspect-ratio: 16 / 9;
`;

Photo.defaultProps = {
    photos: []
}

export default function Photo(props) {
    let interval;
    const photos = props.photos;
    const [count, setCount] = useState(0);

    const updateCount = () => {
        interval = !interval && setInterval(() => {
            setCount(prevCount => {
                if((prevCount + 1) === photos.length) {
                    return(0)
                } else {
                    return(prevCount + 1)
                }
            })
        }, 5000);
    };

    useEffect(() => {
        updateCount()
        return () => clearInterval(interval)
    }, [count]);

    return (
        <Container photoUrl={photos[count]}/>
    )
}