export const disableBodyScrolling = () => {
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    const body = document.body;
    body.style.position = 'fixed';
    body.style.width = '100%';
    body.style.auto = 'auto';
    body.style.top = `-${scrollY}`;
};

export const enableBodyScrolling = () => {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.width = '';
    body.style.auto = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
};

export const disableIdScrolling = (id) => {
    const element = document.getElementById(id);
    element.style.overflow = 'hidden';
}

export const enableIdScrolling = (id) => {
    const element = document.getElementById(id);
    element.style.overflow = 'auto';
}
