import React from 'react';
import styled from "styled-components";

/* Components */
import {colors} from "../../../../common/components/colors";

export const Success = styled.div`
    margin: 9px 0px 10px 0px;
    color: ${colors.progress100};
`;

export const FitContent = styled.div`
    width: fit-content;
`;