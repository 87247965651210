import {images} from '../../../common/components/images';

export const devConsumerCourses =
[
    {
        "id": 1, //course assignment,
        "course": {
            "id": 372,
            "title": "Get support through your divorce",
            "description": "Welcome to the Divorce Journey. This program is geared towards helping you understand the divorce process, having clear expectations and making sure that you\u2019re setting yourself and your family up for success post divorce.",
            "imageUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615072582/shqog59xptiewlhclinc.jpg",
            "isActive": true,
            "isReadingMaterial":false,
            "isVideo": false,
            "isGoal":false,
        },
        "assignedToUser":
        {
            "id": 12,
            "firstName": "Jason",
            "lastName": "Aronson"
        },
        "assignedByUser":
        {
            "id": 24324,
            "firstName": "Lacy",
            "lastName": "Garcia"
        },
        "isCompleted": false,
        "lastUpdatedOn": '2021-03-08T05:00:00+00:00',
        "completedOn": null
    },
    {
        "id": 2, //course assignment,
        "course": {
            "id": 2,
            "title": "Financial Markets",
            "description": "An overview of the ideas, methods, and institutions that permit human society to manage risks and foster enterprise.  Emphasis on financially-savvy leadership skills. Description of practices today and analysis of prospects for the future. Introduction to risk management and behavioral finance principles to understand the real-world functioning of securities, insurance, and banking industries.  The ultimate goal of this course is using such industries effectively and towards a better society.",
            "imageUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633262997/chddvehcto1wlilbdgyy.png",
            "isActive": true,
            "isReadingMaterial":true,
            "isVideo": true,
            "isGoal":false,
        },
        "assignedToUser":
        {
            "id": 12,
            "firstName": "Jason",
            "lastName": "Aronson"
        },
        "assignedByUser":
        {
            "id": 24324,
            "firstName": "Lacy",
            "lastName": "Garcia"
        },
        "isCompleted": true,
        "lastUpdatedOn": '2021-03-08T05:00:00+00:00',
        "completedOn": '2021-08-08T05:00:00+00:00'
    },
    {
        "id": 44, //course assignment,
        "course": {
            "id": 2,
            "title": "Financial Markets",
            "description": "An overview of the ideas, methods, and institutions that permit human society to manage risks and foster enterprise.  Emphasis on financially-savvy leadership skills. Description of practices today and analysis of prospects for the future. Introduction to risk management and behavioral finance principles to understand the real-world functioning of securities, insurance, and banking industries.  The ultimate goal of this course is using such industries effectively and towards a better society.",
            "imageUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633262997/chddvehcto1wlilbdgyy.png",
            "isActive": true,
            "isReadingMaterial":true,
            "isVideo": true,
            "isGoal":false,
        },
        "assignedToUser":
        {
            "id": 12,
            "firstName": "Jason",
            "lastName": "Aronson"
        },
        "assignedByUser":
        {
            "id": 24324,
            "firstName": "Lacy",
            "lastName": "Garcia"
        },
        "isCompleted": true,
        "lastUpdatedOn": '2021-03-08T05:00:00+00:00',
        "completedOn": '2021-08-08T05:00:00+00:00'
    },
    {
        "id": 55, //course assignment,
        "course": {
            "id": 2,
            "title": "Financial Markets",
            "description": "An overview of the ideas, methods, and institutions that permit human society to manage risks and foster enterprise.  Emphasis on financially-savvy leadership skills. Description of practices today and analysis of prospects for the future. Introduction to risk management and behavioral finance principles to understand the real-world functioning of securities, insurance, and banking industries.  The ultimate goal of this course is using such industries effectively and towards a better society.",
            "imageUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633262997/chddvehcto1wlilbdgyy.png",
            "isActive": true,
            "isReadingMaterial":true,
            "isVideo": true,
            "isGoal":false,
        },
        "assignedToUser":
        {
            "id": 12,
            "firstName": "Jason",
            "lastName": "Aronson"
        },
        "assignedByUser":
        {
            "id": 24324,
            "firstName": "Lacy",
            "lastName": "Garcia"
        },
        "isCompleted": true,
        "lastUpdatedOn": '2021-03-08T05:00:00+00:00',
        "completedOn": '2021-08-08T05:00:00+00:00'
    },
    {
        "id": 3, //course assignment,
        "course": {
            "id": 3,
            "title": "Entering Widowhood",
            "description": "Description",
            "imageUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633262997/chddvehcto1wlilbdgyy.png",
            "isActive": true,
            "isReadingMaterial":true,
            "isVideo": false,
            "isGoal":false,
        },
        "assignedToUser":
        {
            "id": 12,
            "firstName": "Jason",
            "lastName": "Aronson"
        },
        "assignedByUser":
        {
            "id": 24324,
            "firstName": "Gwendolyn",
            "lastName": "Payne"
        },
        "isCompleted": false,
        "lastUpdatedOn": '2021-03-08T05:00:00+00:00',
        "completedOn": null
    },
];

export const devMyGoals = [
    {
        'id': 1,
        "title": "Retirement plan",
        "pages": [
            {
                "header": {
                    "title": "What's your goal?"
                },
                "actions": {
                    "backLabel": "",
                    "nextLabel": "Next",
                    "pageNumVisible": true,
                    "canContinueAutomatically": false
                },
                "pageNum": 0,
                "elements": [
                    {
                        "id": "14294004-ce1d-4391-ad42-b0eaa444e4ba",
                        "text": "What's your goal?",
                        "type": "title",
                        "style": {}
                    },
                    {
                        "id": "2696bfdc-a797-4009-8dce-0d9e08cc55b8",
                        "text": "adsa",
                        "type": "textArea",
                        "style": {
                            "textArea": {
                                "border": "none",
                                "outline": "none",
                                "boxShadow": "0px 3px 10px 0px rgba(0, 0, 0, 0.25)",
                                "borderRadius": "20px",
                                "WebkitBoxShadow": "0px 3px 10px 0px rgba(0, 0, 0, 0.25)",
                                "backgroundColor": "white"
                            }
                        },
                        "title": "What's your goal?",
                        "isValid": true,
                        "minRows": 1,
                        "subtitle": "Share your questions",
                        "minLength": 0,
                        "isRequired": true,
                        "placeholder": "Add a title of your goal here."
                    }
                ]
            },
            {
                "header": {
                    "title": "How far along are you already with this goal?"
                },
                "actions": {
                    "backLabel": "Back",
                    "nextLabel": "Next",
                    "pageNumVisible": true,
                    "canContinueAutomatically": false
                },
                "pageNum": 1,
                "elements": [
                    {
                        "id": "d23bb3a5-99ba-4e48-973a-db787febaa47",
                        "text": "How far along are you already with this goal?",
                        "type": "title",
                        "style": {}
                    },
                    {
                        "id": "abb841f2-1816-4024-8896-1662d4e69074",
                        "type": "slider",
                        "style": {
                            "railStyle": {},
                            "trackStyle": {
                                "backgroundColor": "#959CA3"
                            },
                            "handleStyle": {
                                "width": 28,
                                "height": 28,
                                "marginTop": -11,
                                "borderColor": "#394950",
                                "backgroundColor": "#394950"
                            },
                            "containerStyle": {
                                "marginTop": 40,
                                "paddingLeft": 20,
                                "paddingRight": 20
                            }
                        },
                        "title": "How far along are you already with this goal?",
                        "value": 69,
                        "isValid": true,
                        "isRequired": true
                    }
                ]
            },
            {
                "header": {
                    "title": "When would you like to achieve this goal by?"
                },
                "actions": {
                    "backLabel": "Back",
                    "nextLabel": "Next",
                    "pageNumVisible": true,
                    "canContinueAutomatically": false
                },
                "pageNum": 2,
                "elements": [
                    {
                        "id": "e1fb03b8-f8d9-4e98-a192-afb5ae7cc950",
                        "text": "When would you like to achieve this goal by?",
                        "type": "title",
                        "style": {}
                    },
                    {
                        "id": "60f3cc92-a836-4ea7-bf82-459ca52af60c",
                        "type": "calendar",
                        "style": {
                            "warning": {},
                            "subtitle": {},
                            "textArea": {}
                        },
                        "value": "2021-10-30T15:26:44.000Z",
                        "isValid": true,
                        "subtitle": "When would you like to achieve this goal by?",
                        "isRequired": true
                    }
                ]
            },
            {
                "header": {
                    "title": "How are you feeling about this goal?"
                },
                "actions": {
                    "backLabel": "Back",
                    "nextLabel": "Next",
                    "pageNumVisible": true,
                    "canContinueAutomatically": false
                },
                "pageNum": 3,
                "elements": [
                    {
                        "id": "7a574230-0948-46b8-987a-736e3424909a",
                        "text": "How are you feeling about this goal?",
                        "type": "title",
                        "style": {}
                    },
                    {
                        "id": "394fe336-c303-49ef-9c44-4bb991c90d5e",
                        "type": "multiSelect",
                        "style": {
                            "warning": {},
                            "subtitle": {},
                            "textArea": {}
                        },
                        "title": "How are you feeling about this goal?",
                        "isValid": true,
                        "options": [
                            {
                                "id": 1,
                                "label": "Apprehensive",
                                "active": false
                            },
                            {
                                "id": 2,
                                "label": "Excited",
                                "active": false
                            },
                            {
                                "id": 3,
                                "label": "Overwhelmed",
                                "active": true
                            },
                            {
                                "id": 4,
                                "label": "Etc.",
                                "active": true
                            }
                        ],
                        "isRequired": true
                    }
                ]
            },
            {
                "header": {
                    "title": "Would the support of a coach help you achieve your goal?"
                },
                "actions": {
                    "backLabel": "Back",
                    "nextLabel": "Done!",
                    "pageNumVisible": true,
                    "canContinueAutomatically": false
                },
                "pageNum": 4,
                "elements": [
                    {
                        "id": "70d73321-4ba5-491b-86cb-4684aabe0e37",
                        "text": "Need any help?",
                        "type": "title",
                        "style": {}
                    },
                    {
                        "id": "25e82292-56df-4033-9ef2-608e8c5130a0",
                        "type": "singleSelect",
                        "style": {
                            "options": {},
                            "warning": {},
                            "subtitle": {},
                            "disclosure": {},
                            "optionIcon": {},
                            "optionLabel": {}
                        },
                        "isValid": true,
                        "options": [
                            {
                                "id": 1,
                                "label": "Yes",
                                "active": false
                            },
                            {
                                "id": 2,
                                "label": "No",
                                "active": true
                            }
                        ],
                        "subtitle": "How are you feeling about this goal?",
                        "isRequired": true,
                        "maxCheckable": 1
                    }
                ]
            }
        ],
        "isCompleted": false,
        "createdOn": "2021-10-28T15:30:42.865385+00:00",
        "updatedOn": "2021-10-28T15:30:42.865385+00:00"
    },
    {
        'id': 2,
        "title": "Up credit score",
        "pages": [
            {
                "header": {
                    "title": "What's your goal?"
                },
                "actions": {
                    "backLabel": "",
                    "nextLabel": "Next",
                    "pageNumVisible": true,
                    "canContinueAutomatically": false
                },
                "pageNum": 0,
                "elements": [
                    {
                        "id": "14294004-ce1d-4391-ad42-b0eaa444e4ba",
                        "text": "What's your goal?",
                        "type": "title",
                        "style": {}
                    },
                    {
                        "id": "2696bfdc-a797-4009-8dce-0d9e08cc55b8",
                        "text": "adsa",
                        "type": "textArea",
                        "style": {
                            "textArea": {
                                "border": "none",
                                "outline": "none",
                                "boxShadow": "0px 3px 10px 0px rgba(0, 0, 0, 0.25)",
                                "borderRadius": "20px",
                                "WebkitBoxShadow": "0px 3px 10px 0px rgba(0, 0, 0, 0.25)",
                                "backgroundColor": "white"
                            }
                        },
                        "title": "What's your goal?",
                        "isValid": true,
                        "minRows": 1,
                        "subtitle": "Share your questions",
                        "minLength": 0,
                        "isRequired": true,
                        "placeholder": "Add a title of your goal here."
                    }
                ]
            },
            {
                "header": {
                    "title": "How far along are you already with this goal?"
                },
                "actions": {
                    "backLabel": "Back",
                    "nextLabel": "Next",
                    "pageNumVisible": true,
                    "canContinueAutomatically": false
                },
                "pageNum": 1,
                "elements": [
                    {
                        "id": "d23bb3a5-99ba-4e48-973a-db787febaa47",
                        "text": "How far along are you already with this goal?",
                        "type": "title",
                        "style": {}
                    },
                    {
                        "id": "abb841f2-1816-4024-8896-1662d4e69074",
                        "type": "slider",
                        "style": {
                            "railStyle": {},
                            "trackStyle": {
                                "backgroundColor": "#959CA3"
                            },
                            "handleStyle": {
                                "width": 28,
                                "height": 28,
                                "marginTop": -11,
                                "borderColor": "#394950",
                                "backgroundColor": "#394950"
                            },
                            "containerStyle": {
                                "marginTop": 40,
                                "paddingLeft": 20,
                                "paddingRight": 20
                            }
                        },
                        "title": "How far along are you already with this goal?",
                        "value": 69,
                        "isValid": true,
                        "isRequired": true
                    }
                ]
            },
            {
                "header": {
                    "title": "When would you like to achieve this goal by?"
                },
                "actions": {
                    "backLabel": "Back",
                    "nextLabel": "Next",
                    "pageNumVisible": true,
                    "canContinueAutomatically": false
                },
                "pageNum": 2,
                "elements": [
                    {
                        "id": "e1fb03b8-f8d9-4e98-a192-afb5ae7cc950",
                        "text": "When would you like to achieve this goal by?",
                        "type": "title",
                        "style": {}
                    },
                    {
                        "id": "60f3cc92-a836-4ea7-bf82-459ca52af60c",
                        "type": "calendar",
                        "style": {
                            "warning": {},
                            "subtitle": {},
                            "textArea": {}
                        },
                        "value": "2021-10-30T15:26:44.000Z",
                        "isValid": true,
                        "subtitle": "When would you like to achieve this goal by?",
                        "isRequired": true
                    }
                ]
            },
            {
                "header": {
                    "title": "How are you feeling about this goal?"
                },
                "actions": {
                    "backLabel": "Back",
                    "nextLabel": "Next",
                    "pageNumVisible": true,
                    "canContinueAutomatically": false
                },
                "pageNum": 3,
                "elements": [
                    {
                        "id": "7a574230-0948-46b8-987a-736e3424909a",
                        "text": "How are you feeling about this goal?",
                        "type": "title",
                        "style": {}
                    },
                    {
                        "id": "394fe336-c303-49ef-9c44-4bb991c90d5e",
                        "type": "multiSelect",
                        "style": {
                            "warning": {},
                            "subtitle": {},
                            "textArea": {}
                        },
                        "title": "How are you feeling about this goal?",
                        "isValid": true,
                        "options": [
                            {
                                "id": 1,
                                "label": "Apprehensive",
                                "active": false
                            },
                            {
                                "id": 2,
                                "label": "Excited",
                                "active": false
                            },
                            {
                                "id": 3,
                                "label": "Overwhelmed",
                                "active": true
                            },
                            {
                                "id": 4,
                                "label": "Etc.",
                                "active": true
                            }
                        ],
                        "isRequired": true
                    }
                ]
            },
            {
                "header": {
                    "title": "Would the support of a coach help you achieve your goal?"
                },
                "actions": {
                    "backLabel": "Back",
                    "nextLabel": "Done!",
                    "pageNumVisible": true,
                    "canContinueAutomatically": false
                },
                "pageNum": 4,
                "elements": [
                    {
                        "id": "70d73321-4ba5-491b-86cb-4684aabe0e37",
                        "text": "Need any help?",
                        "type": "title",
                        "style": {}
                    },
                    {
                        "id": "25e82292-56df-4033-9ef2-608e8c5130a0",
                        "type": "singleSelect",
                        "style": {
                            "options": {},
                            "warning": {},
                            "subtitle": {},
                            "disclosure": {},
                            "optionIcon": {},
                            "optionLabel": {}
                        },
                        "isValid": true,
                        "options": [
                            {
                                "id": 1,
                                "label": "Yes",
                                "active": false
                            },
                            {
                                "id": 2,
                                "label": "No",
                                "active": true
                            }
                        ],
                        "subtitle": "How are you feeling about this goal?",
                        "isRequired": true,
                        "maxCheckable": 1
                    }
                ]
            }
        ],
        "isCompleted": false,
        "createdOn": "2021-10-28T15:30:42.865385+00:00",
        "updatedOn": "2021-10-28T15:30:42.865385+00:00"
    }
]