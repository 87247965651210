import {axiosReq} from "../../../common/utils/axios";

const devData = [
    {
        "id": 5078,
        "firstName": "Bonnie",
        "lastName": "Maize",
        "signUpDate": "2023-10-16T20:55:04.632065+00:00",
        "legalConfirmationDate": "2023-12-27T18:56:58+00:00",
        "additionalProfileInformation": {
            "photos": [
                "https://res.cloudinary.com/blz-lilyriche/image/upload/v1699846666/pxzuviaduxzydlubfygk.jpg"
            ],
            "logos": [
                "https://res.cloudinary.com/blz-lilyriche/image/upload/v1699846667/sypmln8czona8ioudapv.png"
            ],
            "minimumInvestableAssets": 0,
            "intro": {
                "mbti": "ISFJ",
                "hobby": "Reading",
                "video": "",
                "animal": "Bird",
                "description": "I am a financial coach, planner, and investment advisor based in Topeka, Kansas. With a practice that embraces clients from all backgrounds and corners of the country, I welcome and serve them with a unique blend of down-to-earth friendliness and financial expertise.\nI am passionate about working with a diverse clientele. I am committed to serving those who have historically been marginalized in the financial sector, with special focus on women, LGBTQ+ individuals, and bridging the racial wealth gap. My practice is a safe space for all, reflecting my deep-seated values of diversity and equity.\nAs a financial ally, I am dedicated to guiding my clients through their financial journeys, ensuring that each plan reflects their personal values and life goals."
            }
        },
        "explanation": "Kathleen Barlow is an excellent match for women who are between 50-75 and facing significant life transitions such as divorce, widowhood, or retirement. Kathleen has personal experience with financial setbacks during the 2008 Recession and now focuses on helping women overcome financial challenges and achieve success. Her empathetic approach and lack of undesirable client restrictions make her a supportive and understanding choice for women needing comprehensive financial guidance.",
        "title": "test",
        "gender": "Female",
        "bio": "N/A",
        "certifications": [
            "CERTIFIED ADVISOR FOR WOMEN\u2122"
        ]
    },
    {
        "id": 5090,
        "firstName": "Angela",
        "lastName": "Lessor",
        "signUpDate": "2023-11-01T18:47:57.852371+00:00",
        "legalConfirmationDate": "2023-11-21T21:07:20+00:00",
        "additionalProfileInformation": {
            "photos": [
                "https://res.cloudinary.com/blz-lilyriche/image/upload/v1700600779/ya1tmrj3c6d8ozz65nwr.jpg"
            ],
            "logos": [],
            "minimumInvestableAssets": 500000,
            "intro": {
                "video": "",
                "description": "I never planned to be divorced. No one does. But life happens and I, like so many others, found my marriage ending. As a divorced and single mother, with many friends who fall in the same category, I can relate to the fear of the unknown, and the financial uncertainty that comes from this life journey. I have seen the devastation - both financial and emotional - that divorce can bring. \nThrough empathetic understanding and professional expertise as a Certified Divorce Financial Analyst\u00ae professional and a CERTIFIED FINANCIAL PLANNER\u00ae\u00a0professional, I help women navigate their financial futures so they do not find themselves trapped in their situation due to financial circumstances, or financially destroyed due to a divorce.\nIn my spare time, I\u2019m honing in on my pickleball skills, going to the lake, beach, and mountains, or visiting a winery with friends. I am a mom to a wonderful college-age son and an adorable chocolate lab mix rescue dog who I enjoy spending time with at our home in North Carolina."
            }
        },
        "explanation": "Sarah Baker is well-suited for women in transition and those who prefer to delegate their financial planning to an expert. She enjoys simplifying complex financial topics and is dedicated to helping high net worth delegators make sense of their financial goals. Sarah's passion for the intersection of people and money ensures a personal and meaningful advisory relationship. However, she may not be ideal for clients seeking highly technical advice or DIY solutions.",
        "title": "test",
        "gender": "Female",
        "bio": null,
        "certifications": [
            "CERTIFIED ADVISOR FOR WOMEN\u2122"
        ]
    }
]

export const tryPostAdvisorDirectoryRedirect = (data) => {
    return async dispatch => {
        return axiosReq('advertising/public/leads/get-advisors', "POST", data,  devData);
    }
};
