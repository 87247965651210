import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { useQuery } from "../../common/utils/hooks";
/* Local */
import { images } from "../../common/components/images";

/* Common */
import {
    FontBody16,
    FontSubtitle38To30,
    FontTitle21,
} from "../../common/components/fonts";
import { SubmitButton } from "../../common/components/inputs";
import { colors } from "../../common/components/colors";

const Logo = styled.div`
  height: 50px;
  padding: 30px 0px 30px 0px;
  text-align: center;
`;
export const Room = styled.div`
  height: 100%;
  width: 100%;
`;
export const RoomContent = styled.div`
  width: 100%;
  @media screen and (max-width: 1088px) {
    padding-bottom: 80px;
  }
  @media screen and (max-width: 748px) {
    height: calc(100% - 165px);
  }
  @media screen and (max-width: 460px) {
    padding-bottom: 0px;
  }
`;
export const RoomContentContainer = styled.div`
  width: 748px;
  margin: 0px auto 120px auto;
  padding: 00px 20px 0px 20px;
  @media screen and (max-width: 748px) {
    padding: 0px 20px 0px 20px;
    width: calc(100% - 40px);
  }
`;
const Center = styled.div`
  text-align: center;
  margin: 0px auto;
`;
const Doggy = styled.img`
  width: 100%;
  max-width: 400px;
  height: auto;
`;
const Title = styled.div`
  margin: 20px 0px;
`;
const Body = styled.div`
  max-width: 360px;
  margin: 0px auto 20px auto;
`;

const ErrorText = styled(FontBody16)`
    color: ${colors.alert100};
    font-weight: bold;
`;

const ErrorRoom = (props) => {
    let queryParams = useQuery();
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [randVal, setRandVal] = useState(Math.random());

    const getTitle = () => {
        if (randVal < 0.33) return "Gone to the beach!";
        else if (randVal < 0.66)
            return "I've had a ruff day, just taking a paws!";
        else return "Someone is blocking your path!";
    };

    const getImage = () => {
        if (randVal < 0.33) return images.error1;
        else if (randVal < 0.66) return images.error2;
        else return images.error3;
    };

    useEffect(() => {
        const maybeErrorMessage = queryParams.get("message");
        if (maybeErrorMessage)
            setErrorMessage(`Error message: ${maybeErrorMessage}`);
        setIsLoading(false);
    }, []);

    return (
        <Room>
            <RoomContent>
                <RoomContentContainer>
                    <Logo>
                        <img
                            src={images.logo}
                            height="50"
                            width="280"
                            alt={""}
                        />
                    </Logo>
                    {isLoading ? (
                        <Center>
                            <FontTitle21>Loading!</FontTitle21>
                        </Center>
                    ) : (
                        <Center>
                            <Doggy
                                src={getImage()}
                                width={"400"}
                                height={"400"}
                            />

                            <Title>
                                <FontSubtitle38To30>
                                    {getTitle()}
                                </FontSubtitle38To30>
                            </Title>
                            <Body>
                                <FontBody16>
                                    Looks like you hit an error. Sorry! We're
                                    working to fix it as fast as we can.
                                </FontBody16>

                                <ErrorText>{errorMessage}</ErrorText>
                            </Body>
                            <SubmitButton
                                iconComponent={null}
                                color={colors.primary100}
                                label={"Go to dashboard"}
                                link={"/verify"}
                                backgroundColor={colors.action100}
                            />
                        </Center>
                    )}
                </RoomContentContainer>
            </RoomContent>
        </Room>
    );
};

export default withRouter(ErrorRoom);