import React from "react";
import {withRouter} from "react-router-dom";
import styled from 'styled-components'

/* Components */
import Nav from "../navbars/containers/nav";
import {PageContentContainer, PageFull, PageContent} from "../navbars/components/navlayouts";

/* Containers */
import FirmRoundtablesAll from './containers/all';
import FirmRoundtablesList from './containers/list';
import FirmRoundtablesRecord from "./containers/record";

const RoundtablesNav = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  & > div {
    width: fit-content;
    cursor: pointer;
  }
`;
function FirmRoundtables({location, history}) {

    return (
        <>
            <Nav />
            <PageFull>
                <PageContentContainer fullPage={true}>
                    <PageContent>
                        <RoundtablesNav>
                            <div onClick={() => {history.push("/my-roundtables")}}>My Roundtables</div>
                            <div onClick={() => {history.push("/my-roundtables/browse")}}>Browse Roundtables</div>
                        </RoundtablesNav>
                        <br />
                        <br />
                        {location.pathname === "/my-roundtables" &&
                            <FirmRoundtablesList />
                        }
                        {location.pathname === "/my-roundtables/browse" &&
                            <FirmRoundtablesAll />
                        }
                        {(location.pathname !== "/my-roundtables" && location.pathname !== "/my-roundtables/browse") &&
                            <FirmRoundtablesRecord />
                        }
                    </PageContent>
                </PageContentContainer>
            </PageFull>
        </>
    );
}

export default withRouter(FirmRoundtables);

