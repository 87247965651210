import React from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';

/* Components */
import { NavPrimaryWrapper, NavPrimaryMobileWrapper } from '../../consumer2/navbars/navprimary';
import { PageFull, Background, PageContent } from '../../../consumer/navbars/components/navlayout';

/* Pages */
// import MoneyGoals from "../containers/moneygoals";
// import MoneyBalances from "../containers/moneybalances";
import MoneySpending from "../../../common/containers/mymoney/containers/moneyspending";
import MoneySnapshot from "../../../common/containers/mymoney/containers/moneysnapshot";
import MoneySaving from "../../../common/containers/mymoney/containers/moneysaving";
// import MoneyOwed from "../../common/containers/moneyowed";

/* Static */
import {NavSecondaryWrapper} from "../../consumer2/navbars/navsecondary";

const PageContentContainer = styled.div`
    width: 694px;
    margin: 0px auto 120px auto;
    padding: 60px 20px 0px 20px;
    @media screen and (max-width: 748px) {
        padding: 20px 0px;
        width: fit-content;
        max-width: calc(100% - 40px);
    }
`;

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const MyMoney = ({location}) => (
    <>
        <NavPrimaryWrapper data={env.CONSUMER.NAV} />
        <PageFull>
            <div>
                <NavSecondaryWrapper data={env.CONSUMER.NAV} />
                <PageContent>
                    <PageContentContainer>
                        {/*{location.pathname === "/my-money/goals" && <MoneyGoals />}*/}
                        {/*{location.pathname === "/my-money/balances" && <MoneyBalances />}*/}
                        {location.pathname === "/my-money/spending" && <MoneySpending />}
                        {location.pathname === "/my-money/snapshot" && <MoneySnapshot />}
                        {location.pathname === "/my-money/saving" && <MoneySaving />}
                        {/*{location.pathname === "/my-money/owed" && <MoneyOwed />}*/}
                    </PageContentContainer>
                </PageContent>
                <Background />
            </div>
            <NavPrimaryMobileWrapper data={env.CONSUMER.NAV} />
            {/*<NavActionWrapper />*/}
        </PageFull>
    </>
);
export default withRouter(MyMoney);

