import { Map, List, fromJS } from 'immutable';

/* Immutable */

const COACHES_ASSIGNED_TO_WMC = 'COACHES_ASSIGNED_TO_WMC';
const COACHES_ASSIGNED_TO_WMC_FETCHING = 'COACHES_ASSIGNED_TO_WMC_FETCHING';
const COACHES_ASSIGNED_TO_WMC_FETCHED = 'COACHES_ASSIGNED_TO_WMC_FETCHED';
const COACHES_ASSIGN_TO_PROSPECT_FETCHING = 'COACHES_ASSIGN_TO_PROSPECT_FETCHING';
const COACHES_ASSIGNED_TO_PROSPECT = 'COACHES_ASSIGNED_TO_PROSPECT';
const COACHES_ASSIGNED_TO_PROSPECT_FETCHING = 'COACHES_ASSIGNED_TO_PROSPECT_FETCHING';
const COACHES_UPDATE_PROSPECTS_SESSIONS_UPDATING = 'COACHES_UPDATE_PROSPECTS_SESSIONS_UPDATING';
const COACHES_PROGRAM = 'COACHES_PROGRAM';
const COACHES_PROGRAM_FETCHING = 'COACHES_PROGRAM_FETCHING';
const COACHES_SELECT_ONE = 'COACHES_SELECT_ONE';
const COACHES_ASSIGN_MODAL = 'COACHES_ASSIGN_MODAL';
const CLIENTS_ASSIGN_ONLY_MODAL = 'CLIENTS_ASSIGN_ONLY_MODAL';
const COACHES_STORE_RESET = 'COACHES_STORE_RESET';
const INTRODUCE_TOKEN_FETCHING = 'INTRODUCE_TOKEN_FETCHING';
const INTRODUCE_TOKEN_CONSUMING = 'INTRODUCE_TOKEN_CONSUMING';
const INTRODUCE_TOKEN = 'INTRODUCE_TOKEN';
const CLIENTS_ASSIGN_MODAL = 'CLIENTS_ASSIGN_MODAL';
const ANNOUNCEMENT_MODAL = 'ANNOUNCEMENT_MODAL';

/* Actions */

export const setSelectCoach = (coach) => ({'type': COACHES_SELECT_ONE, 'coach': coach});
export const setCoachesAssignedToWMC = (coaches) => ({'type': COACHES_ASSIGNED_TO_WMC, 'coaches': coaches});
export const setCoachesAssignedToWMCFetching = (status) => ({'type': COACHES_ASSIGNED_TO_WMC_FETCHING, 'status': status});
export const setCoachesAssignedToWMCFetched = (status) => ({'type': COACHES_ASSIGNED_TO_WMC_FETCHED, 'status': status});
export const setCoachesAssignedToProspect = (coaches) => ({'type': COACHES_ASSIGNED_TO_PROSPECT, 'coaches': coaches});
export const setCoachesAssignedToProspectFetching = (status) => ({'type': COACHES_ASSIGNED_TO_PROSPECT_FETCHING, 'status': status});
export const setCoachAssignToProspectFetching = (status) => ({'type': COACHES_ASSIGN_TO_PROSPECT_FETCHING, 'status': status});
export const setUpdateProspectsSessionsFetching = (status) => ({'type': COACHES_UPDATE_PROSPECTS_SESSIONS_UPDATING, 'status': status});
export const setCoachProgram = (program) => ({'type': COACHES_PROGRAM, 'program': program});
export const setCoachProgramFetching = (status) => ({'type': COACHES_PROGRAM_FETCHING, 'status': status});
export const setCoachModalStatus = (status, coach) => ({'type': COACHES_ASSIGN_MODAL, 'status': status, 'coach': coach});
export const setClientModalStatus = (status, client) => ({'type': CLIENTS_ASSIGN_MODAL, 'status': status, 'client': client});
export const setAnnouncementModalStatus = (status) => ({'type': ANNOUNCEMENT_MODAL, 'status': status});
export const setClientAssignModalStatus = (status, client) => ({'type': CLIENTS_ASSIGN_ONLY_MODAL, 'status': status, 'client': client});
export const setCoachesStoreReset = () => ({'type': COACHES_STORE_RESET});

export const setIntroduceTokenFetching = (status) => ({'type': INTRODUCE_TOKEN_FETCHING, 'status': status});
export const setIntroduceTokenConsuming = (status) => ({'type': INTRODUCE_TOKEN_CONSUMING, 'status': status});
export const setIntroduceToken = (token) => ({'type': INTRODUCE_TOKEN, 'token': token});

/* Initial State */

const initialState = Map({
    coachesAssignedToWMC: List(),
    coachesAssignedToWMCFetching: false,
    coachesAssignedToWMCFetched: false,
    coachAssigningToProspectUpdating: false,
    coachesAssignedToProspect: List(),
    coachesAssignedToProspectFetching: false,
    coachesUpdateProspectsSessionsUpdating: false,
    coachProgram: Map(),
    coachSelected: Map(),
    coachProgramFetching: false,
    modalOpen: false,
    clientModalOpen: false,
    clientAssignModalOpen: false,
    modalCoachSelected: Map(),
    modalClientSelected: Map(),
    introduceTokenFetching: false,
    introduceTokenConsuming: false,
    introduceToken: Map(),
    announcementModalOpen: false
});

/* Reducer */

const coachingReducer = (state=initialState, action) => {
    switch (action.type) {
        case COACHES_ASSIGNED_TO_WMC:
            return state.merge({'coachesAssignedToWMC': fromJS(action.coaches)});
        case COACHES_ASSIGNED_TO_WMC_FETCHING:
            return state.merge({'coachesAssignedToWMCFetching': action.status});
        case COACHES_ASSIGNED_TO_WMC_FETCHED:
            return state.merge({'coachesAssignedToWMCFetched': action.status});
        case COACHES_ASSIGN_TO_PROSPECT_FETCHING:
            return state.merge({'coachAssigningToProspectUpdating': action.status});
        case COACHES_ASSIGNED_TO_PROSPECT:
            return state.merge({'coachesAssignedToProspect': fromJS(action.coaches)});
        case COACHES_ASSIGNED_TO_PROSPECT_FETCHING:
            return state.merge({'coachesAssignedToProspectFetching': action.status});
        case COACHES_UPDATE_PROSPECTS_SESSIONS_UPDATING:
            return state.merge({'coachesUpdateProspectsSessionsUpdating': action.status});
        case COACHES_PROGRAM:
            return state.merge({'coachProgram': fromJS(action.program)});
        case COACHES_PROGRAM_FETCHING:
            return state.merge({'coachProgramFetching': action.status});
        case COACHES_ASSIGN_MODAL:
            return state.merge({
                'modalOpen': action.status,
                'modalCoachSelected': fromJS(action.coach)
            });
        case CLIENTS_ASSIGN_MODAL:
            return state.merge({
                'clientModalOpen': action.status,
                'modalClientSelected': fromJS(action.client)
            });
        case CLIENTS_ASSIGN_ONLY_MODAL:
            return state.merge({
                'clientAssignModalOpen': action.status,
                'modalClientSelected': fromJS(action.client)
            });
        case COACHES_SELECT_ONE:
            return state.merge({'coachSelected': fromJS(action.coach)});
        case INTRODUCE_TOKEN_FETCHING:
            return state.merge({'introduceTokenFetching': action.status});
        case INTRODUCE_TOKEN_CONSUMING:
            return state.merge({'introduceTokenConsuming': action.status});
        case INTRODUCE_TOKEN:
            return state.merge({'introduceToken': fromJS(action.token)});
        case ANNOUNCEMENT_MODAL:
            return state.merge({'announcementModalOpen': action.status});
        case COACHES_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default coachingReducer;
