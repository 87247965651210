import React from 'react';
import {withRouter} from 'react-router-dom';

/* Navs */
import Nav from "../navbars/containers/nav";
import {PageContentContainer, PageFull, PageContent} from "../navbars/components/navlayouts";

/* Containers */
import FirmMarketingStructure from './containers/structure';
// import FirmMarketingStructureArticleEdit from './containers/structurearticleedit';

const FirmMarketing = ({location}) => {

    // if(location.pathname.includes("/my-growth/articles/") && ((location.pathname.includes("edit"))
    // ||
    // (location.pathname.includes("edit-compliance")
    // )
    // ||
    // (location.pathname.includes("feedback")
    // )
    // ||
    // (location.pathname.includes("edit-published")
    // ))){
    //
    //  return (
    //     <FirmMarketingStructureArticleEdit/>
    //  )
    //
    // }
    // else{
    return (
        <>
            <Nav />
            <PageFull>
                <PageContentContainer fullPage={true/*(location.pathname === "/my-growth" || location.pathname === "/my-growth/prospects" || location.pathname === "/my-growth/articles" || location.pathname === "/my-growth/profile")*/}>
                    <PageContent>

                        {/*{(location.pathname === "/my-growth" ||*/}
                        {/*    location.pathname === "/my-growth/profile" ||*/}
                        {/*    location.pathname === "/my-growth/articles" ||*/}
                        {/*    location.pathname === "/my-growth/prospects"*/}
                        {/*) && */}
                            <FirmMarketingStructure />
                        {/*}*/}

                        {/*{(location.pathname === "/my-growth/profile/edit-intro" ||*/}
                        {/*    location.pathname === "/my-growth/profile/edit-profile"*/}
                        {/*) && <FirmMarketingStructureEdit />}*/}

                    </PageContent>
                </PageContentContainer>
            </PageFull>
        </>
    );
// }
}

export default withRouter(FirmMarketing);