import { combineReducers } from 'redux';

/* Reducers */
import supportModalReducer from './store/support';

const supportReducer = combineReducers({
    support: supportModalReducer,
});

export default supportReducer;
