import React from 'react';
import styled from "styled-components";

import {colors} from '../../../common/components/colors';

export const HeaderSection = styled.div`
    display: table;
    width: 100%;
    min-height: 450px;
    @media screen and (max-width: 800px) {
        min-height: 0px;
        display: flex;
        flex-direction: column;
    }
`;
export const Left = styled.div`
    display: table-cell;
    vertical-align: top;
    width: 500px;
    padding-top: 50px;
    padding-right: 60px;
    color: ${colors.primary100};
    @media screen and (max-width: 1220px) {
        width: 400px;
        padding-right: 30px;
    }
    @media screen and (max-width: 1088px) {
        width: 380px;
        padding-right: 20px;
    }
    @media screen and (max-width: 800px) {
        padding-top: 20px;
        order: 2;
        width: 100%;
        max-width: 600px;
        padding-right: 0px;
    }
`;
export const Right = styled.div`
    display: table-cell;
    vertical-align: top;
    width: calc(100% - 560px);
    position: relative;
    text-align: center;
    @media screen and (max-width: 1220px) {
        width: calc(100% - 430px);
    }
    @media screen and (max-width: 1088px) {
        width: calc(100% - 400px);
    }
    @media screen and (max-width: 800px) {
        order: 1;
        width: 100%;
    }
`;
export const ProfileImg = styled.img`
    display: block;
    width: 85px;
    height: 85px;
    border-radius: 85px;
    object-fit: cover;
    object-position: 0 0;
    margin-bottom: 15px;
    @media screen and (max-width: 800px) {
        margin-bottom: 5px;
    }
`;
export const FillContainer = styled.div`
    padding-top: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    @media screen and (max-width: 1088px) {
        display: none;
    }
`;
export const HeroImg = styled.img`
    display: none;
    @media screen and (max-width: 1088px) {
        display: block;
        width: 100%;
        object-fit: cover;
        object-position: 0 0;
        margin: 30px auto 0px auto;
        max-width: 600px;
        max-height: 480px;
    }
    @media screen and (max-width: 800px) {
        margin: 0px 0px 30px 0px;
    }
    @media screen and (max-width: 460px) {
        margin: 10px 0px 20px 0px;
    }
`;

export const HeroVid = styled.iframe`
    display: none;
    @media screen and (max-width: 1088px) {
        display: block;
        width: 100%;
        margin: 30px auto 0px auto;
        max-width: 600px;
        max-height: 480px;
        min-height: 400px;
    }
    @media screen and (max-width: 800px) {
        margin: 0px 0px 30px 0px;
        min-height: 360px;
    }
    @media screen and (max-width: 460px) {
        margin: 10px 0px 20px 0px;
        min-height: 240px;
    }
`;
export const HeroVidBackground = styled.iframe`
    position: relative;
    width: 100%;
    max-width: 600px;
    flex-grow: 1;
    margin: 0px auto;
    max-height: 480px;
    z-index: 1;
`;

export const HeroImgBackground = styled.div`
    background-image: url(${props => props.imgObj});
    position: relative;
    width: 100%;
    max-width: 600px;
    background-size: cover;
    background-position: center;
    flex-grow: 1;
    margin: 0px auto;
    max-height: 480px;
    z-index: 1;
`;

export const Black = styled.div`
    position: absolute;
    right: 10%;
    top: 0;
    height: 450px;
    width: 350px;
    background-color: ${colors.secondary100};
    @media screen and (max-width: 1440px) {
        right: 0;
    }
`;







export const Name = styled.div`
    margin: 0px;
    @media screen and (max-width: 460px) {
        & > div {
            font-size: 30px;
        }
    }
`;
export const CompanyName = styled.div`
    margin-top: -15px;
    color: ${colors.primary70};
`;
export const Handle = styled.div`
    margin: 10px 0px 0px 0px;
    color: ${colors.brand};
`;
export const Intro = styled.div`
    margin: 15px 0px 0px 0px;
`;
export const Tags = styled.div`
    margin: 20px 0px 0px 0px
`;
export const Tag = styled.span`
    margin-right: 15px;
`;
export const SocialIcon = styled.div`
    display: inline-block;
    padding: 10px 10px 10px 0px;
    color: ${colors.secondary70};
    cursor: pointer;
    font-size: 20px;
    vertical-align: top;
    & svg {
        width: 20px;
        height: 20px;
    }
`;
export const CTAs = styled.div`
    margin: 25px 0px 0px 0px;
`;
export const Icon14 = styled.div`
    display: inline-block;
    vertical-align: top;
    & svg {
        width: 16px;
        height: 16px;
        margin-top: 4px;
    }
`;
export const Icon16 = styled.div`
    display: inline-block;
    vertical-align: top;
    height: 24px;
    & svg {
        width: 20px;
        height: 20px;
        margin-top: 2px;
    }
`;
export const Rotate = styled.div`
    transform: rotate(45deg);
    transform-origin: center center;  
`;
export const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;
export const InlineBtn = styled.div`
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 460px) {
        & > div {
            padding: 7px 16px;    
        }
        margin-bottom: 10px;
    }
`;
export const Spacing = styled.div`
    margin-right: 15px;
`;