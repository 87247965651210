import React from 'react'
import {connect} from 'react-redux';
import moment from 'moment/moment';
import {withRouter} from 'react-router-dom';

/* Full Calendar */
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid'
import '@fullcalendar/core/main.css';
import '@fullcalendar/timegrid/main.css';

/* Components */
import { ForcedStyles } from "../components/scheduler";
import { LogoLoading } from "../../../common/components/loading";

/* Middleware */
import { tryGetAllSessions } from '../middleware/sessions';
import { tryGetAllSessionsLarge } from '../middleware/group';
import { tryGetSettingsPersonalInfo } from "../../middleware/settingspersonalinfo";

/* Store */
import { setSessionsModal } from '../store/sessions';
import { setGroupSessionOne } from '../store/group';

class MeetingsCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {fullCalendar: "", isLoading: true};
    }

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        if(this.props.timeZone === "") {
            await this.props.tryGetSettingsPersonalInfo();
        }
        if(this.props.sessions.length === 0) {
            await this.props.tryGetAllSessions();
        }
        if(this.props.groupSessionsAll.length === 0) {
            await this.props.tryGetAllSessionsLarge();
        }
        await this.updateCalendar();
        this.setState({"isLoading": false});
    };

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.sessions !== this.props.sessions || prevProps.groupSessionsAll !== this.props.groupSessionsAll) {
            return this.updateCalendar();
        }
    }

    handleSelect = async info => {
        console.log(info);
        if(!moment(info.end).isBefore(moment())) {
            await this.props.setSessionsModal(false, info, "create", null, null, null, true);
            this.props.history.push("/meetings/create");
        }
    };

    handleClick = async info => {
        console.log(info);
        if(info.event.extendedProps.meetingType === "session") {
            await this.props.setSessionsModal(true, info.event, "view", null, null, null, true);
            this.props.history.push("/meetings/details");
        } else if(info.event.extendedProps.meetingType === "groupSession") {
            const eventCombine = {...info.event.extendedProps, "start": info.event.start, "end": info.event.end, "id": info.event.id, "title": info.event.title};
            await this.props.setGroupSessionOne(eventCombine);
            this.props.history.push("/meetings/page");
        }
    };

    updateCalendar = () => {
        const meetings = this.props.sessions.concat(this.props.groupSessionsAll);
        console.log(meetings)
        this.setState({fullCalendar: <FullCalendar
                header={{left: 'prev,today,next', center: 'title', right: 'timeGridDay,timeGridWeek,dayGridMonth'}}
                titleRangeSeparator={"\u2013"}
                defaultView={'timeGridWeek'}
                height={"parent"}
                view={["DAY", "WEEK", "MONTH"]}
                timezone={"local"}
                slotDuration={'00:30:00'}
                minTime={"00:00:00"}
                maxTime={"24:00:00"}
                scrollTime={'07:00:00'}
                allDaySlot={false}
                longPressDelay={500}
                displayEventTime={false}
                columnHeaderHtml={(date) => {
                    let eachDay = moment(date).format("YYYY-MM-DD[T]00:00:00Z");
                    let today = moment().format("YYYY-MM-DD[T]00:00:00Z");
                    let day = moment(date).format("ddd");
                    let dayNum = moment(date).format("D");
                    if(eachDay === today) {return "<div><div>"+day+"</div><div>"+dayNum+"</div></div>"}
                    else {return "<div><div>"+day+"</div><div class='currentDay'>"+dayNum+"</div></div>"}
                }}
                eventRender={function (event) {event.el.classList.add("pointer");}}
                events={meetings}
                plugins={[timeGridPlugin, interactionPlugin, dayGridPlugin]}
                selectable={true}
                select={this.handleSelect}
                eventClick={this.handleClick}
            />
        })
    };

    render() {
        if(this.state.isLoading || this.state.fullCalendar === '') {
            return (<LogoLoading />)
        } else {
            return (<ForcedStyles>{this.state.fullCalendar}</ForcedStyles>)
        }
    }
}

const mapStateToProps = state => ({
    timeZone: state.enterprise.settingsPersonal.get("settingsPersonalInfo").get("timeZone"),
    sessions: state.enterprise.sessions.get("sessions").toJS(),
    groupSessionsAll: state.enterprise.meetings.group.get("groupSessionsAll").toJS(),
});

const mapDispatchToProps = dispatch => ({
    tryGetAllSessions: () => dispatch(tryGetAllSessions()),
    tryGetAllSessionsLarge: () => dispatch(tryGetAllSessionsLarge()),
    setGroupSessionOne: (session) => dispatch(setGroupSessionOne(session)),
    setSessionsModal: (status, session, action, who, details, guests, eventApi) => dispatch(setSessionsModal(status, session, action, who, details, guests, eventApi)),
    tryGetSettingsPersonalInfo: () => dispatch(tryGetSettingsPersonalInfo())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MeetingsCalendar));