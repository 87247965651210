import React from 'react';

/* Components */
import {PoweredBy, Gray, PoweredByLogo, Width} from '../components/poweredby';
import {images} from '../../../common/components/images';
import {FontBody16} from '../../../common/components/fonts';

class IframeMeetingPageStructure extends React.Component {
    render() {
        return(
            <Width>
                <PoweredBy target={"_blank"} href={"https://www.trustwillow.com/"}>
                    <FontBody16><Gray>Powered by</Gray>&nbsp;<PoweredByLogo src={images.logo} alt={""} /></FontBody16>
                </PoweredBy>
            </Width>
        )
    }
}

export default IframeMeetingPageStructure;