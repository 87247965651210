import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {
    setCourses,
    setCoursesFetching,
    setCoursesAdd,
    setCoursesCreating,
    setCoursesUpdate,
    setCoursesUpdating,
    setCoursesDeleted,
    setCoursesDeleting,
    setCoursesReport,
    setCoursesReportFetching,
    setCoursesAssigned,
    setCoursesAssigning,
    setCoursesOne
} from '../store/courses';

/* Static */
import {
    devEnterpriseExistingCourses,
    devEnterpriseNewCourse,
    devEnterpriseEditedCourse,
    devEnterpriseCourseReport,
    devEnterpriseAddTaskToCourse
} from '../static/devdata';

export const tryGetCourses = () => {
    return async dispatch => {
        dispatch(setCoursesFetching(true));
        const coursesAll = await axiosReq('apiv1/coach/courses', "GET", {}, devEnterpriseExistingCourses);
        await dispatch(setCourses(coursesAll));
        return dispatch(setCoursesFetching(false));
    }
};

export const tryGetCoursesOne = (id) => {
    return async dispatch => {
        dispatch(setCoursesFetching(true));
        const course = await axiosReq('apiv1/coach/courses/'+id, "GET", {}, devEnterpriseAddTaskToCourse);
        await dispatch(setCoursesOne(course));
        return dispatch(setCoursesFetching(false));
    }
};

export const tryCreateCourse = (courseData) => {
    return async dispatch => {
        dispatch(setCoursesCreating(true));
        const newCourse = await axiosReq('apiv1/coach/courses', "POST", courseData, devEnterpriseNewCourse);
        await dispatch(setCoursesAdd(newCourse));
        dispatch(setCoursesCreating(false));
        return newCourse
    }
};

export const tryAddTasksToCourse = (tasks, courseId) => {
    return async dispatch => {
        dispatch(setCoursesUpdating(true));
        const newCourse = await axiosReq('apiv1/coach/courses/assign-tasks', "POST", {tasks: tasks, courseId: courseId}, devEnterpriseAddTaskToCourse);
        await dispatch(setCoursesUpdate(newCourse));
        return dispatch(setCoursesUpdating(false));
    }
};

export const tryUpdateCourse = (courseId, courseData) => {
    return async (dispatch, getState) => {
        dispatch(setCoursesUpdating(true));
        const editedCourse = await axiosReq('apiv1/coach/courses/'+courseId, "PUT", courseData, devEnterpriseNewCourse);
        await dispatch(setCoursesUpdate(editedCourse));
        return dispatch(setCoursesUpdating(false));
    }
};

// export const tryDeleteCourse = (courseId) => {
//     return async dispatch => {
//         dispatch(setCoursesDeleting(true));
//         const deletedCourse = await axiosReq('apiv1/coach/tasks/'+courseId, "DELETE", {}, {});
//         await dispatch(setCoursesDeleted(courseId));
//         return dispatch(setCoursesDeleting(false));
//     }
// };

// export const tryAssignCourseToConsumer = (courseId, consumerId) => {
//     return async dispatch => {
//         dispatch(setCoursesAssigning(true));
//         const assignedCourse = await axiosReq('apiv1/coach/tasks/'+courseId+'/assign/'+consumerId, "POST", {}, {});
//         await dispatch(setCoursesAssigned(courseId, consumerId));
//         return dispatch(setCoursesAssigning(false));
//     }
// };

// export const tryGetCourseReport = (courseId) => {
//     return async dispatch => {
//         dispatch(setCoursesReportFetching(true));
//         const report = await axiosReq('apiv1/coach/tasks/'+courseId+'/reports', "GET", {}, devEnterpriseCourseReport);
//         await dispatch(setCoursesReport(report));
//         return dispatch(setCoursesReportFetching(false));
//     }
// };
