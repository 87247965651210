import React from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";

/* Components */
import {LogoLoading} from "../../../../common/components/loading";
import {colors} from "../../../../common/components/colors";
import FirmCoachesIntro from "./listintro";

/* Containers */
import FirmCoachesMyCoaches from "./mycoaches";
import FirmCoachesBrowse from "./browse";

/* Middleware */
import {tryGetAllMyCoaches} from "../middleware/coaches";
import {images} from "../../../../common/components/images";
import {FontBody24} from "../../../../common/components/fonts";
import {CertBack} from "../../certification/containers/previewevents";


const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;
// const Foreground = styled.div`
//     position: relative;
//     z-index: 2;
// `;
// const Background = styled.div`
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: ${colors.white};
//     z-index: 1;
// `;

class FirmCoachesList extends React.Component {
    state = {
        "hasCoaches": false,
        "isLoading": true
    }

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const myCoaches = await this.props.tryGetAllMyCoaches();
        const hasCoaches = myCoaches.length > 0;
        // if(!hasCoaches) {
        //     await this.props.history.push("/my-certificates/my-coaches/browse")
        // }
        await this.setState({
            "hasCoaches": hasCoaches,
            "isLoading": false
        });
    };



    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if(prevProps.location.pathname !== this.props.location.pathname && this.props.location.pathname === "/my-certificates/my-coaches" && !this.state.hasCoaches) {
    //         this.props.history.go(-1)
    //     }
    // }

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    {/*<Foreground>*/}
                    <FontBody24 style={{"justifyContent":"space-evenly", "marginBottom":"2em"}}><CertBack to={"/my-certificates"}><img src={images.chevronLeft} alt={"<"} /> Back</CertBack></FontBody24>
                        <FirmCoachesIntro
                            hasCoaches={this.state.hasCoaches}
                        />
                        {(this.props.location.pathname === "/my-certificates/my-coaches" && this.state.hasCoaches) &&
                            <FirmCoachesMyCoaches />
                        }
                        {(this.props.location.pathname === "/my-certificates/my-coaches/browse" || !this.state.hasCoaches) &&
                            <FirmCoachesBrowse
                                hasCoaches={this.state.hasCoaches}
                            />
                        }
                    {/*</Foreground>*/}
                    {/*<Background />*/}
                </>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryGetAllMyCoaches: () => dispatch(tryGetAllMyCoaches())
});

export default connect(null, mapDispatchToProps)(withRouter(FirmCoachesList));