import {axiosReq, axiosReqJSON} from "../../../common/utils/axios";

import {setInsightParentCategoriesFetching, setInsightParentCategories, setInsightSubCategoriesFetching, setInsightSubCategories, setInsightsFetching, setInsights, setInsightsSearching, setInsightsSearchResults} from '../store/insights';
import {devConsumerInsightParentCategories, devConsumerInsightSubCategories, devConsumerInsights, devConsumerInsightsSearch, devConsumerInsightsFive} from '../static/devdata';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

export const tryGetInsightParentCategories = () => {
    return async dispatch => {
        dispatch(setInsightParentCategoriesFetching(true));
        const categories = await axiosReq('apiv1/consumer/courses/get-parent-categories', "GET", {}, devConsumerInsightParentCategories);
        // REPLACE_APIS_FOR_TODAY_SHOW
        // const categories = await axiosReqJSON(env.CONSUMER.API_RESPONSES.LEARN_CATEGORIES);
        await dispatch(setInsightParentCategories(categories));
        dispatch(setInsightParentCategoriesFetching(false));
        return categories
    }
};

export const tryGetInsightSubCategories = (id) => {
    return async dispatch => {
        dispatch(setInsightSubCategoriesFetching(true));
        const categories = await axiosReq('apiv1/consumer/courses/get-child-categories/' + id, "GET", {}, devConsumerInsightSubCategories);
        await dispatch(setInsightSubCategories(categories));
        dispatch(setInsightSubCategoriesFetching(false));
        return categories
    }
};

export const tryGetInsights = (id, limit) => {
    return async dispatch => {
        dispatch(setInsightsFetching(true));
        const url = 'apiv1/consumer/courses/get-courses-by-category-id/' + id + (limit ? "?limit=" + limit : "")
        const courses = await axiosReq(url, "GET", {}, devConsumerInsights);
        // REPLACE_APIS_FOR_TODAY_SHOW
        // const courses = await axiosReqJSON(env.CONSUMER.API_RESPONSES.LEARN_CONTENT[id.toString()])
        await dispatch(setInsights(courses));
        dispatch(setInsightsFetching(false));
        return courses
    }
};

export const tryGetAllInsights = (limit) => {
    return async dispatch => {
        dispatch(setInsightsFetching(true));
        const courses = await axiosReq('apiv1/consumer/courses/get-all-content?limit=' + limit, "GET", {}, devConsumerInsights);
        // REPLACE_APIS_FOR_TODAY_SHOW
        // const courses = await axiosReqJSON(env.CONSUMER.API_RESPONSES.LEARN_CONTENT[id.toString()])
        await dispatch(setInsights(courses));
        dispatch(setInsightsFetching(false));
        return courses
    }
};

export const tryGetMostPopular = (limit) => {
    return async dispatch => {
        dispatch(setInsightsFetching(true));
        const courses = await axiosReq('apiv1/consumer/courses/get-most-subscribed?limit=' + limit, "GET", {}, devConsumerInsightsFive);
        // REPLACE_APIS_FOR_TODAY_SHOW
        // const courses = await axiosReqJSON(env.CONSUMER.API_RESPONSES.LEARN_CONTENT[id.toString()])
        await dispatch(setInsights(courses));
        dispatch(setInsightsFetching(false));
        return courses
    }
};

export const trySearchInsights = (term) => {
    return async dispatch => {
        dispatch(setInsightsSearching(true));
        const results = await axiosReq('apiv1/consumer/courses/search-courses', "POST", {"term": term}, devConsumerInsightsSearch);
        await dispatch(setInsightsSearchResults(results));
        dispatch(setInsightsSearching(false));
    }
};

// GET apiv1/consumer/courses/all-content                   - UNUSED, KEEPING LEGACY
// GET apiv1/consumer/courses/all-content/<category id>     - UNUSED, KEEPING LEGACY