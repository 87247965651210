import { Map, List, fromJS } from 'immutable';
import {listGuestNames} from "../middleware/sessions";

/* Immutable */

const GROUP_SESSIONS = 'GROUP_SESSIONS';
const GROUP_SESSIONS_ONE = 'GROUP_SESSIONS_ONE';
const GROUP_SESSIONS_READING = 'GROUP_SESSIONS_READING';
const GROUP_SESSIONS_CREATING = 'GROUP_SESSIONS_CREATING';
const GROUP_SESSIONS_CREATED = 'GROUP_SESSIONS_CREATED';
const GROUP_SESSIONS_UPDATING = 'GROUP_SESSIONS_UPDATING';
const GROUP_SESSIONS_UPDATED = 'GROUP_SESSIONS_UPDATED';
const GROUP_SESSIONS_WHEN_UPDATING = 'GROUP_SESSIONS_WHEN_UPDATING'
const GROUP_SESSIONS_WHEN_UPDATED = 'GROUP_SESSIONS_WHEN_UPDATED';
const GROUP_SESSIONS_PARTICIPANTS = 'GROUP_SESSIONS_PARTICIPANTS';
const GROUP_SESSIONS_PARTICIPANTS_READING = 'GROUP_SESSIONS_PARTICIPANTS_READING';
const GROUP_SESSIONS_EMAIL_COMPLETE = 'GROUP_SESSIONS_EMAIL_COMPLETE';
const GROUP_SESSIONS_EMAIL_SENDING = 'GROUP_SESSIONS_EMAIL_SENDING';
// const GROUP_SESSIONS_DELETING = 'GROUP_SESSIONS_DELETING';
// const GROUP_SESSIONS_DELETE = 'GROUP_SESSIONS_DELETE';
const GROUP_SESSIONS_STORE_RESET = 'GROUP_SESSIONS_STORE_RESET';

/* Actions */

export const setGroupSessions = (sessions) => ({'type': GROUP_SESSIONS, 'sessions': sessions});
export const setGroupSessionOne = (session) => ({'type': GROUP_SESSIONS_ONE, 'session': session});
export const setGroupSessionParticipants = (session) => ({'type': GROUP_SESSIONS_PARTICIPANTS, 'session': session});
export const setGroupSessionParticipantsDownloading = (status) => ({'type': GROUP_SESSIONS_PARTICIPANTS_READING, 'status': status});
export const setGroupSessionsDownloading = (status) => ({'type': GROUP_SESSIONS_READING, 'status': status});
export const setGroupSessionCreating = (status) => ({'type': GROUP_SESSIONS_CREATING, 'status': status});
export const setGroupSessionCreated = (newSession) => ({'type': GROUP_SESSIONS_CREATED, 'newSession': newSession});
export const setGroupSessionUpdating = (status) => ({'type': GROUP_SESSIONS_UPDATING, 'status': status});
export const setGroupSessionUpdated = (editedSession, sessionId) => ({'type': GROUP_SESSIONS_UPDATED, 'editedSession': editedSession, 'sessionId': sessionId});
export const setGroupSessionWhenUpdating = (status) => ({'type': GROUP_SESSIONS_WHEN_UPDATING, 'status': status});
export const setGroupSessionWhenUpdated = (editedSession, sessionId) => ({'type': GROUP_SESSIONS_WHEN_UPDATED, 'editedSession': editedSession, 'sessionId': sessionId});
// export const setGroupSessionsDeleting = (status) => ({'type': GROUP_SESSIONS_DELETING, 'status': status});
// export const setGroupSessionsDelete = (sessionId) => ({'type': GROUP_SESSIONS_DELETE, 'sessionId': sessionId});
export const setGroupSessionEmailSending = () => ({'type': GROUP_SESSIONS_EMAIL_SENDING})
export const setGroupSessionEmailComplete = () => ({'type': GROUP_SESSIONS_EMAIL_COMPLETE})
export const setGroupSessionsStoreReset = () => ({'type': GROUP_SESSIONS_STORE_RESET});

/* Initial State */

const initialState = Map({
    groupSessionsAll: List(),
    groupSessionOne: Map(),
    groupSessionsReading: false,
    groupSessionCreating: false,
    groupSessionUpdating: false,
    groupSessionWhenUpdating: false,
    groupSessionParticipants: Map(),
    groupSessionParticipantsReading: false,
    groupSessionEmailSending: false,
    // groupSessionsDeleting: false,
});

/* Reducer */

const groupReducer = (state=initialState, action) => {
    switch (action.type) {
        case GROUP_SESSIONS:
            return state.merge({"groupSessionsAll": fromJS(action.sessions)});
        case GROUP_SESSIONS_ONE:
            return state.merge({"groupSessionOne": fromJS(action.session)});
        case GROUP_SESSIONS_PARTICIPANTS:
            return state.merge({"groupSessionParticipants": fromJS(action.session)});
        case GROUP_SESSIONS_PARTICIPANTS_READING:
            return state.merge({"groupSessionParticipantsReading": action.status});
        case GROUP_SESSIONS_READING:
            return state.merge({'groupSessionsReading': action.status});
        case GROUP_SESSIONS_CREATING:
            return state.merge({'groupSessionCreating': action.status});
        case GROUP_SESSIONS_CREATED:
            return state.merge({
                'groupSessionsAll': fromJS(state.get("groupSessionsAll").push(fromJS(action.newSession)))
            });
        case GROUP_SESSIONS_UPDATING:
            return state.merge({'groupSessionUpdating': action.status});
        case GROUP_SESSIONS_UPDATED:
            return state.merge({
                'groupSessionsAll': fromJS(state.get("groupSessionsAll").map(s => {
                    if(s.get("id") === action.sessionId) {
                        return fromJS({
                            ...s.toJS(),
                            ...action.editedSession
                        });
                    } else {
                        return(fromJS(s))
                    }
                })),
                'groupSessionOne': state.get("groupSessionOne").size > 0
                    ? state.get("groupSessionOne").get("id") === action.sessionId
                        ? fromJS({
                            ...state.get("groupSessionOne").toJS(),
                            ...action.editedSession
                        })
                        : fromJS(state.get("groupSessionOne"))
                    : Map()
            });
        case GROUP_SESSIONS_WHEN_UPDATING:
            return state.merge({'groupSessionWhenUpdating': action.status});
        case GROUP_SESSIONS_WHEN_UPDATED:
            return state.merge({
                'groupSessionsAll': fromJS(state.get("groupSessionsAll").map(s => {
                    if(s.get("id") === action.sessionId) {
                        return fromJS({
                            ...s.toJS(),
                            ...action.editedSession
                        });
                    } else {
                        return(fromJS(s))
                    }
                })),
                'groupSessionOne': state.get("groupSessionOne").size > 0
                    ? state.get("groupSessionOne").get("id") === action.sessionId
                        ? fromJS({
                            ...state.get("groupSessionOne").toJS(),
                            ...action.editedSession 
                        })
                        : fromJS(state.get("groupSessionOne"))
                    : Map()
            });
        // case GROUP_SESSIONS_DELETING:
        //     return state.merge({'groupSessionsDeleting': action.status});
        // case GROUP_SESSIONS_DELETE:
        //     return state.merge({
        //         "groupSessionsAll": state.get("groupSessionsAll").size === 0
        //             ? state.get("groupSessionsAll")
        //             : fromJS(state.get("groupSessionsAll").filter(s => {
        //                 return !(s.get("id") === action.sessionId);
        //             }))
        //     });
        case GROUP_SESSIONS_EMAIL_SENDING:
            return state.merge({'groupSessionEmailSending': true});
        case GROUP_SESSIONS_EMAIL_COMPLETE:
            return state.merge({'groupSessionEmailSending': false});
        case GROUP_SESSIONS_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default groupReducer;
