import React from 'react';
import {withRouter} from 'react-router-dom';

/* Local */
import CreateService from "./containers/createservice";
import EditService from "./containers/editservice";
import Introduction from "./containers/introduction";
import ServicesList from "./containers/serviceslist";
import AllServicesPage from "./containers/allservicespage";
import PreviewServices from "./containers/previewservices";
import LinkEmbed from "./containers/linkembed";

/* Navs */
import {PageContent, PageFull, PageRight, Block, CardOrNot, Right, Width, WidthRight, Border} from "../navbars/components/navlayout";
import NavPrimaryAll from "../navbars/containers/primaryall";
import NavSecondaryTop from "../navbars/containers/secondarytop";

/* Components */
import {PlusOutlined} from "@ant-design/icons";

class ServicesPage extends React.Component {
    state={"preview": false, "linkEmbed": false, "linkEmbedId": null};

    previewStatus = async (status) => {
        this.setState({"preview": status});
    };

    linkEmbed = async (status) => {
        this.setState({
            "linkEmbedId": null,
            "linkEmbed": status,
        })
    };

    setLinkEmbedId = async (id) => {
        this.setState({
            "linkEmbedId": id.toString(),
            "linkEmbed": true
        })
    };

    render() {
        return (
            <>
                <NavPrimaryAll />
                <PageFull>
                    <PageRight>

                        {this.props.location.pathname === "/products" &&
                            <>
                                <NavSecondaryTop title={"Products"} desc={"Create and edit your products available for purchase"}/>
                                <PageContent>
                                    <Block>
                                        <Width fullWidth={false}>
                                            <ServicesList setLinkEmbedId={this.setLinkEmbedId} />
                                        </Width>
                                        <WidthRight fullWidth={false}>
                                            <Right fullWidth={false}>
                                                <CardOrNot>
                                                    <AllServicesPage
                                                        title={"Products Page"}
                                                        subtitle={null}
                                                        type={"page"}
                                                        url={"products"}
                                                        handleKey={"storeHandle"}
                                                        activeKey={"storeActive"}
                                                        errorKey={"storeHandleError"}
                                                        showLinkEmbed={true}
                                                        linkEmbed={this.linkEmbed}
                                                        showOpenLink={false}
                                                        showPreview={true}
                                                        preview={this.previewStatus}
                                                    />
                                                </CardOrNot>
                                            </Right>
                                        </WidthRight>
                                    </Block>
                                </PageContent>
                                <PreviewServices
                                    preview={this.state.preview}
                                    updatePreview={this.previewStatus}
                                />
                                {this.state.linkEmbed &&
                                    <LinkEmbed
                                        linkEmbed={this.linkEmbed}
                                        linkEmbedId={this.state.linkEmbedId}
                                    />
                                }
                            </>
                        }

                        {this.props.location.pathname === "/products/create" &&
                            <>
                                <NavSecondaryTop title={"Create Product"} />
                                <PageContent>
                                    <CreateService />
                                </PageContent>
                            </>
                        }

                        {this.props.location.pathname === "/products/edit" &&
                            <>
                                <NavSecondaryTop title={"Edit Product"} />
                                <PageContent>
                                    <EditService />
                                </PageContent>
                            </>
                        }

                    </PageRight>
                </PageFull>
            </>
        )
    }

}

export default withRouter(ServicesPage);
