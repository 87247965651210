import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {
    setCoachesAssignedToWMCFetching,
    setCoachesAssignedToWMC,
    setCoachAssignToProspectFetching,
    setCoachesAssignedToWMCFetched,
    setCoachesAssignedToProspectFetching,
    setCoachesAssignedToProspect,
    setUpdateProspectsSessionsFetching,
    setCoachProgramFetching,
    setCoachProgram
} from '../store/coaching';
import {setAllClientsEditExistingClient, setClientsOneEditExisting} from "../../clients/store/clients";
import {setAllProspectsEditExistingProspect, setProspectsOneEditExisting} from "../../store/prospects";

/* Static */
import { devEnterpriseCoachesAssignedToWMCData, devEnterpriseWMCProspectsCoachingData, devEnterpriseCoachProgramData } from '../../static/devdata';

export const tryGetCoachesAssignedToWMC = () => {
    return async dispatch => {
        dispatch(setCoachesAssignedToWMCFetching(true));
        const coaches = await axiosReq('apiv1/wmc/all-coaches', "GET", {}, devEnterpriseCoachesAssignedToWMCData);
        await dispatch(setCoachesAssignedToWMC(coaches.allCoaches));
        dispatch(setCoachesAssignedToWMCFetched(true));
        return dispatch(setCoachesAssignedToWMCFetching(false));
    }
};

export const tryGetCoachesAssignedToProspect = (prospectId, storeOrReturn) => {
    return async dispatch => {
        dispatch(setCoachesAssignedToProspectFetching(true));
        const assignedCoaches = await axiosReq('apiv1/wmc/all-coaches/'+prospectId, "GET", {}, devEnterpriseWMCProspectsCoachingData);
        if(storeOrReturn === "store") {
            await dispatch(setCoachesAssignedToProspect(assignedCoaches.allCoaches));
            return dispatch(setCoachesAssignedToProspectFetching(false));
        } else {
            dispatch(setCoachesAssignedToProspectFetching(false));
            return assignedCoaches.allCoaches;
        }
    }
};

export const tryPostUpdateProspectsSessions = (coachId, sessionsDelta, consumerId, prospectId) => {
    return async (dispatch, getState) => {
        dispatch(setUpdateProspectsSessionsFetching(true));
        console.log(consumerId)
        console.log(prospectId)
        if (consumerId === null || prospectId === null) {
            const consumerId2 = getState().enterprise.clientsDetail.get("client").get("consumerId");
            const prospectId2 = getState().enterprise.clientsDetail.get("client").get("prospectId");
            await axiosReq('apiv1/wmc/coach/assign-lily-sessions', "POST", {"sessionsDelta": sessionsDelta, "coachId": coachId, "lilyId": consumerId2, "lilyProspectId": prospectId2}, {});
            return dispatch(setUpdateProspectsSessionsFetching(false));
        } else {
            await axiosReq('apiv1/wmc/coach/assign-lily-sessions', "POST", {"sessionsDelta": sessionsDelta, "coachId": coachId, "lilyId": consumerId, "lilyProspectId": prospectId}, {});
            return dispatch(setUpdateProspectsSessionsFetching(false));
        }
    }
};

export const tryGetCoachProgramCoachProspect = (prospectId, storeOrReturn) => {
    return async dispatch => {
        dispatch(setCoachProgramFetching(true));
        const program = await axiosReq('apiv1/coach/willow-prospects/'+prospectId, "GET", {}, devEnterpriseCoachProgramData("coach"));
        dispatch(setCoachProgramFetching(false));
        if(storeOrReturn === "store") {
            return dispatch(setCoachProgram(program));
        } else {
            return(program);
        }
    }
};

export const tryGetCoachProgramWMCProspect = (prospectId, storeOrReturn) => {
    return async dispatch => {
        dispatch(setCoachProgramFetching(true));
        const program = await axiosReq('apiv1/coach/wmc-prospects/'+prospectId, "GET", {}, devEnterpriseCoachProgramData("advisor"));
        dispatch(setCoachProgramFetching(false));
        if(storeOrReturn === "store") {
            return dispatch(setCoachProgram(program));
        } else {
            return(program);
        }
    }
};

export const tryPostAssignCoachToProspect = (coachId, numSessions, coachName, consumerId, prospectId, prospectStatus, instructions) => {
    return async (dispatch, getState) => {
        dispatch(setCoachAssignToProspectFetching(true));
        console.log("coachId:" + coachId + " lilyId:" + consumerId + " lilyProspectId:" + prospectId + " numberOfSessions:" + numSessions + " instructions:" + instructions);
        let instructionsSend = instructions === null ? {} : {"noteForCoach": instructions};
        await axiosReq('apiv1/wmc/coach/assign-lily-prospect', "POST", {"coachId": coachId, "lilyId": consumerId, "lilyProspectId": prospectId, "numberOfSessions": numSessions, ...instructionsSend}, {});
        if(prospectStatus === "Prospect") {
            let coach = getState().enterprise.prospects.get("prospectsOne").get("coach");
            let edit = {"coach": coach === null ? coachName : "Multiple"};
            dispatch(setAllProspectsEditExistingProspect(prospectId, edit));
            dispatch(setProspectsOneEditExisting(edit));
        } else if(prospectStatus === "Client") {
            let coach = getState().enterprise.clientsList.get("clientsOne").get("coach");
            let edit = {"coach": coach === null ? coachName : "Multiple"};
            dispatch(setAllClientsEditExistingClient(prospectId, edit));
            dispatch(setClientsOneEditExisting(edit));
        }
        return dispatch(setCoachAssignToProspectFetching(false));
    }
};
