import styled from "styled-components";
import {colors} from "../../../common/components/colors";
import React from "react";
import {Link} from "react-router-dom";
import getDomainEnv from '../../../domains/utils';
import {images} from "../../../common/components/images";
import NavTopMenu from "./navtopmenu";
const env = getDomainEnv();



const NavTop = styled.div`
    -webkit-box-shadow: 6px 0 18px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 6px 0 18px 0 rgba(0, 0, 0, 0.06);
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 4;
    background-color: ${colors.white};
    display: block;
    height:150px;
    border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  justify-content: center;
    @media screen and (max-width: 1088px) {
        display: none;
    }

  @media
  (prefers-reduced-motion: no-preference) {
    html {
      scroll-behavior: smooth;
    }
  }
`;
const Container = styled.div`
    height: auto;
    position: relative;
  width:100%;
`;
const LogoDate = styled(Link)`
    height: 40px;
    padding: 60px 10px 20px 25px;
    display:flex; 
    flex-direction:row;
    align-items: center;
  justify-content: flex-start;

`;



export default function ConsumerNavTop() {
    return(
        <NavTop>
            <Container>
                <LogoDate to={env.CONSUMER.AUTH.HOME_URL}>
                    <img src={images.logo} height="32" width="160" alt={"Willow"} />
                    <NavTopMenu/>

                </LogoDate>
            </Container>
        </NavTop>
    )

}