import {images} from '../../../common/components/images';

export const devTeamMemberMeetingsData = {
    "program": {
        "package": "3-month", // "1-month", "3-month", "6-month", "12-month", or "trial"
        "purchaseDate": "2020-03-06T10:00:00+00:00",
        "completedSessions": 3,
        "remainingSessions": 0,
        "hoursInCoaching": 1.5,
        "coachId": 1033
    },
    "upcomingCalls": [
        {
            id: 1,
            remoteUserName: "Gabby Coach",
            remoteUserId: 1033,
            thisUserName: "Jason Consumer",
            thisUserPlatform: "consumer",
            start: "2022-11-12T20:00:00+00:00",
            end: "2022-11-12T20:30:00+00:00",
            expected_end: "2022-11-12T20:30:00+00:00",
            phone: "617-380-4313",
            room: "277861",
            videoStatus: "conference_ongoing", // conference_canceled, conference_ended, conference_has_not_yet_started, conference_ongoing
            videoUrl: "924fcf54447903ab9ea558886af67543", // if status !== conference_ongoing then videoUrl = null
            videoAccessToken: "abc", // if status !== conference_ongoing then videoAccessToken = null
            title: 'Jason Consumer',
            "remoteUserFirstName": "Gabby",
            "remoteUserLastName": "Baker",
            "remoteUserPhotoUrl": images.devDataTeamGabbyAvatar,
            "cancelToken": "abcd",
            "purchaseToken":"3447-L3mmvyh3N3RbPMrrXjCPdyyptXewmNer3kR60A-2187",
            "meetingIsTrial":true,
            "purchaseHistoryId":null,
        },
        {
            id: 2,
            remoteUserName: "Lacy Garcia",
            remoteUserId: 1033,
            thisUserName: "Jason Consumer",
            thisUserPlatform: "consumer",
            start: "2020-09-12T17:00:00+00:00",
            end: "2020-09-12T17:30:00+00:00",
            expected_end: "2020-09-12T17:30:00+00:00",
            phone: "617-380-4313",
            room: "277861",
            videoStatus: "conference_ongoing", // conference_canceled, conference_ended, conference_has_not_yet_started, conference_ongoing
            videoUrl: "924fcf54447903ab9ea558886af67543", // if status !== conference_ongoing then videoUrl = null
            videoAccessToken: "abc", // if status !== conference_ongoing then videoAccessToken = null
            title: 'Jason Consumer',
            "remoteUserFirstName": "Lacy",
            "remoteUserLastName": "Garcia",
            "remoteUserPhotoUrl": images.devDataTeamLacyAvatar,
            "purchaseToken":"3447-L3mmvyh3N3RbPMrrXjCPdyyptXewmNer3kR60A-2187",
            "meetingIsTrial":true,
            "purchaseHistoryId": null //"wmcPaid",
        }
    ],
    "pastCalls": [
        {
            "id": 8,
            "date": "2019-04-08T05:00:00+00:00",
            "length": "60 min",
            "isOngoing": true,
            "isMissed": false,
            // Add start, end, remoteUserId
            "start": "2019-04-08T01:00:00+00:00",
            "end": "2019-04-08T07:00:00+00:00",
            "expected_end": "2019-04-08T07:00:00+00:00",
            "remoteUserId": 1033,
            "coachId":  1032,
            "remoteUserFirstName": "Gabby",
            "remoteUserLastName": "Baker",
            "remoteUserPhotoUrl": images.devDataTeamGabbyAvatar,
            "isCanceled":false,
            "purchaseToken":"3447-L3mmvyh3N3RbPMrrXjCPdyyptXewmNer3kR60A-2187",
            "meetingIsTrial":true,
            "purchaseHistoryId": null //"wmcPaid",
        },
        {
            "id": 4,
            "date": "2019-03-08T05:00:00+00:00",
            "length": "60 min",
            "isOngoing": true,
            "isMissed": false,
            // Add start, end, remoteUserId
            "start": "2019-03-08T01:00:00+00:00",
            "end": "2019-03-08T07:00:00+00:00",
            "expected_end": "2019-03-08T07:00:00+00:00",
            "remoteUserId": 1032,
            "coachId":  1032,
            "remoteUserFirstName": "Gabby",
            "remoteUserLastName": "Baker",
            "remoteUserPhotoUrl": images.devDataTeamGabbyAvatar,
            "isCanceled":false,
            "purchaseToken":"3447-L3mmvyh3N3RbPMrrXjCPdyyptXewmNer3kR60A-2187",
            "meetingIsTrial":true,
            "purchaseHistoryId":null,
        },
        {
            "id": 1,
            "date": "2020-08-10T17:00:00+00:00",
            "length": "30 min",
            "isOngoing": true,
            "isMissed": false,
            // Add start, end, remoteUserId
            "start": "2020-08-10T17:00:00+00:00",
            "end": "2020-08-10T17:45:00+00:00",
            "expected_end": "2020-08-10T17:45:00+00:00",
            "remoteUserId": 1032,
            "coachId":  1032,
            "remoteUserFirstName": "Gabby",
            "remoteUserLastName": "Baker",
            "remoteUserPhotoUrl": images.devDataTeamGabbyAvatar,
            "isCanceled":false,
            "purchaseToken":"3447-L3mmvyh3N3RbPMrrXjCPdyyptXewmNer3kR60A-2187",
            "meetingIsTrial":true,
            "purchaseHistoryId": null //"willowPaid",
        },
        {
            "id": 2,
            "date": "2020-03-06T10:00:00+00:00",
            "length": "30 min",
            "isOngoing": false,
            "isMissed": true,
            // Add start, end, remoteUserId
            "start": "2020-03-06T10:00:00+00:00",
            "end": "2020-03-06T13:00:00+00:00",
            "expected_end": "2020-03-06T13:00:00+00:00",
            "remoteUserId": 1032,
            "coachId":  1033,
            "remoteUserFirstName": "Gabby",
            "remoteUserLastName": "Baker",
            "remoteUserPhotoUrl": images.devDataTeamGabbyAvatar,
            "isCanceled":false,
            "purchaseToken":"3447-L3mmvyh3N3RbPMrrXjCPdyyptXewmNer3kR60A-2187",
            "meetingIsTrial":false,
            "purchaseHistoryId":58,
        },
        {
            "id": 3,
            "date": "2020-02-25T10:00:00+00:00",
            "length": "30 min",
            "isOngoing": false,
            "isMissed": false,
            // Add start, end, remoteUserId
            "start": "2020-02-25T10:00:00+00:00",
            "end": "2020-02-25T11:00:00+00:00",
            "expected_end": "2020-02-25T11:00:00+00:00",
            "remoteUserId": 1032,
            "coachId": 1033,
            "remoteUserFirstName": "Lacy",
            "remoteUserLastName": "Garcia",
            "remoteUserPhotoUrl": images.devDataTeamLacyAvatar,
            "isCanceled":false,
            "purchaseToken":"3447-L3mmvyh3N3RbPMrrXjCPdyyptXewmNer3kR60A-2187",
            "meetingIsTrial":false,
            "purchaseHistoryId":58,
        }
    ]
};