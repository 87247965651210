import React from "react";
import styled from "styled-components";
import {colors} from "../../../common/components/colors";

const Border = styled.div`
    background-color: ${colors.primary100};
    width: 100%;
    height: 1px;
    margin: 20px 0px;
`;

const ElementBorder = ({text, style}) => (
    <Border style={style}>{text}</Border>
);

export default ElementBorder;