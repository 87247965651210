import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import styled from "styled-components";

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {colors} from '../../../common/components/colors';
import {DeleteOutlined, LinkOutlined} from '@ant-design/icons';

/* Containers */
import {TableWrapperV2} from "../../../common/components/tablev2";
import TeamIntroduceLinksModal from './introducelinksmodal';

/* Middleware */
import {tryGetAllClients} from "../../clients/middleware/clients";
import {tryGetAllProspects} from "../../middleware/prospects";

/* Store */
import {setClientsDetailSelected} from "../../store/clientsdetail";
import {Message} from "../../../common/components/messages";
import {ButtonPrimary} from "../../../common/components/buttons";
import {PlusOutlined} from "@ant-design/icons";
import {setSupportOpen} from "../../support/store/support";
import {setClientAssignModalStatus} from "../store/coaching";

const InlineBtn = styled.div`
    width: fit-content;
    margin: -25px auto 0px auto;
`;

class TeamPartnerships extends React.Component {
    state={"isLoading": true, "data": []};

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.modalOpen !== this.props.modalOpen) {
            return this.init();
        }
    }

    init = async () => {
        // if(!this.props.clientsAllFetched) {
            await this.props.tryGetAllClients();
        // }
        // if(!this.props.prospectsAllFetched) {
            await this.props.tryGetAllProspects();
        // }
        await this.setState({
            "isLoading": false,
            "data": await this.createData(this.props.clientsAll.concat(this.props.prospectsAll).toJS())
        });
        console.log(this.state.data)
    };

    view = async (data) => {
        await this.selectClient(data);
        this.props.history.push(/*data.isCoachNoAdvisor ? "/contact/notes" : */"/contact/instructions");
    };

    contactRM = async (data) => {
        this.props.setSupportOpen(true, 2);
    };

    selectClient = async (data) => {
        this.props.setClientsDetailSelected({
            "contactId": data.contactId,
            "prospectId": data.prospectId,
            "consumerId": data.consumerId,
            "businessUserId": data.businessUserId === undefined ? null : data.businessUserId,
            "firstName": data.firstName,
            "lastName": data.lastName,
            "prospectStatus": data.prospectStageName === "client" ? "Client" : (data.prospectStageName === null ? null : "Prospect"),
            "revenue": data.revenue,
            "probabilityOfClosing": data.probabilityOfClosing,
            "companyName": data.companyName,
            "isCoachHasAdvisor": data.isCoachHasAdvisor,
            "isCoachNoAdvisor": data.isCoachNoAdvisor,
            "isAdvisorHasCoach": data.isAdvisorHasCoach,
            "isAdvisorNoCoach": data.isAdvisorNoCoach,
            "referralSource": data.referralSource
        })
    };

    getAssignLink = async (data) => {
        await this.props.setClientAssignModalStatus(true, data);
    };

    createColumns = {
        "0": {"key": "id", "title": "Id", "titleShowOnDesktop": false, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "hidden", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": null, "onDoubleClick": null},
        "1": {"key": "name", "title": "Name", "titleShowOnDesktop": true, "canSort": true, "sort": "asc", "sortType": "string", "sortKey": "name", "component": "link", "thStyles": {}, "tdStyles": {"color": colors.action100}, "ifNull": "", "onClick": this.view, "onDoubleClick": null},
        "2": {"key": "prospectStatus", "title": "Relationship", "titleShowOnDesktop": true, "canSort": true, "sort": null, "sortType": "string", "sortKey": "prospectStatus", "component": "string", "thStyles": {}, "tdStyles": {}, "ifNull": '---', "onClick": null, "onDoubleClick": null},
        "3": {"key": "remainingSessions", "title": "Remaining Sessions", "titleShowOnDesktop": true, "canSort": true, "sort": null, "sortType": "number", "sortKey": "sessions", "component": "string", "thStyles": {}, "tdStyles": {}, "ifNull": '---', "onClick": null, "onDoubleClick": null},
        "4": {"key": "introLink", "title": "Intro Link", "titleShowOnDesktop": true, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "linkWithIcon", "thStyles": {}, "tdStyles": {}, "ifNull": <LinkOutlined />, "onClick": this.getAssignLink, "onDoubleClick": null},
        "5": {"key": "remove", "title": "", "titleShowOnDesktop": true, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "linkWithIcon", "thStyles": {}, "tdStyles": {}, "ifNull": <DeleteOutlined />, "onClick": this.contactRM, "onDoubleClick": null},
    };

    createData = async (data) => {
        return data.reduce((filtered, d) => {
            if(d.coaches.length > 0) {
                let checkCoaches = d.coaches.filter(c => {return(c.coachId === this.props.coach.coachId)});
                let advisorNotWMC = d.advisor === null ? null : d.advisor.advisorId === this.props.userId ? null : d.advisor.firstName + " " + d.advisor.lastName;
                let isCoachHasAdvisor = (!this.props.isWMC && advisorNotWMC !== null);
                let isCoachNoAdvisor = (!this.props.isWMC && advisorNotWMC === null);
                let hasCoach = d.coaches === undefined ? false : (d.coaches.size === 0 ? false : (d.coaches.filter(coach => {return(coach.wmcId === null)}).size > 0));
                let isAdvisorHasCoach = (this.props.isWMC && hasCoach);
                let isAdvisorNoCoach = (this.props.isWMC && !hasCoach);
                if(checkCoaches.length > 0) {
                    let coachName = d.coaches.length === 1 ? checkCoaches[0].fullName : "Multiple";
                    filtered.push({
                        ...d,
                        "id": d.contactId,
                        "name": d.firstName + " " + d.lastName,
                        "coachName": coachName,
                        "prospectStatus": d.prospectStageName === "client" ? "Client" : (d.prospectStageName === null ? null : "Prospect"),
                        "allData": d,
                        "isCoachHasAdvisor": isCoachHasAdvisor,
                        "isCoachNoAdvisor": isCoachNoAdvisor,
                        "isAdvisorHasCoach": isAdvisorHasCoach,
                        "isAdvisorNoCoach": isAdvisorNoCoach,
                        "remainingSessions": checkCoaches[0].remainingSessions,
                        "introLink": ""
                    });
                }
            }
            return filtered
        }, []);
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <>
                    <TableWrapperV2
                        responsive={true}
                        title={"Assigned"}
                        tableColumns={this.createColumns}
                        tableData={this.state.data}
                        initSelected={null}
                    />
                    <TeamIntroduceLinksModal />
                    {this.state.data.length === 0 &&
                        <>
                            <br />
                            <Message text={"No assigned clients"}/>
                            {/*<Message text={"Partner With "+this.props.coach.first}/>*/}
                            {/*<InlineBtn><div onClick={this.props.introduce}><ButtonPrimary canSubmit={false} label={<><PlusOutlined /> Make Introduction</>} /></div></InlineBtn>*/}
                        </>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    clientsAllFetched: state.enterprise.clientsList.get("clientsAllFetched"),
    clientsAll: state.enterprise.clientsList.get("clientsAll"),
    prospectsAllFetched: state.enterprise.prospects.get("prospectsAllFetched"),
    prospectsAll: state.enterprise.prospects.get("prospectsAll"),
    isWMC: state.common.wmc.get("isWMC"),
    userId: state.common.user.get("userId"),
    modalOpen: state.enterprise.team.coaching.get("modalOpen")
});

const mapDispatchToProps = dispatch => ({
    setClientsDetailSelected: (data) => dispatch(setClientsDetailSelected(data)),
    tryGetAllClients: () => dispatch(tryGetAllClients()),
    tryGetAllProspects: () => dispatch(tryGetAllProspects()),
    setSupportOpen: (isOpen, page) => dispatch(setSupportOpen(isOpen, page)),
    setClientAssignModalStatus: (status, client) => dispatch(setClientAssignModalStatus(status, client)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TeamPartnerships));
