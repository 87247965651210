import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

/* Components */
import {ButtonSecondary, ButtonTertiary} from '../../../common/components/buttons';
import {FontBody16, FontTitle18} from '../../../common/components/fonts';
import {colors} from '../../../common/components/colors';
import {LogoLoading} from "../../../common/components/loading";
import {EditOutlined} from "@ant-design/icons";
import {SavedSettings} from "../../../common/components/cards";
import {InlineBlock, SaveSection, SubmitInputWithValidation, EditIcon, InlineItemLeft, InlineItemRight} from "../components/pagescontact";

/* Middleware */
import {tryUpdateExpertProfile} from '../middleware/expertprofile';
import {FitContent} from "../components/myprofile";

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

const Label = styled.div`
    padding: 3px 3px 3px 0px;
    background-color: ${colors.white};
    border: none;
    color: ${colors.primary100};
    font-family: GraphicWebRegular;
    font-size: 16px;
    line-height: 22px;
    word-break: break-word;
    @media screen and (max-width: 460px) {
        font-size: 14px;
        line-height: 20px;
    }
`;

export const Invalid = styled.div`
    color: ${colors.alert100};
`;

const InputSection = styled.input`
    background-color: ${colors.border70};
    border: 1px solid ${colors.primary30};
    color: ${colors.primary100};
    border-radius: 4px;
    width: calc(100% - 40px);
    padding: 10px 20px;
    font-family: GraphicWebRegular;
    font-size: 16px;
    line-height: 22px;
    &::placeholder {
        color: ${colors.primary30};
    }
    &:focus {
        outline: none;
    }
`;

const RemoveMargin = styled.div`
    margin-bottom: -30px;
`;

class SideBarContactInput extends React.Component {
    state = {
        isLoading: true,
        callLength: 0,
        callLengthPrevious: 0,
        callLengthPreviousValid: true,
        callLengthValid: true,
        error: "",
        isChanged: false,
        isValid: false,
        isSaving: false,
        isSaved: false,
        isEdit: false,
    };

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    init = async () => {
        const callLength = this.props.profile.get("defaultCallLength");
        await this.setState({
            "callLength": callLength,
            "callLengthPrevious": callLength,
            "isLoading": false
        });
    };

    reset = async () => {
        await this.setState({
            "callLength": this.state.callLengthPrevious,
            "isChanged": false,
            "isEdit": false
        });
    };

    edit = async (status) => {
        this.setState({"isEdit": status});
    };

    changeCallLength = async e => {
        await this.setState({
            'callLength': e.target.value,
            'callLengthValid': validator.isNumeric(e.target.value),
            "isChanged": this.state.callLengthPrevious !== e.target.value
        });
        this.checkValid();
    };

    checkValid = async () => {
        if(this.state.callLengthValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    submitCallLength = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            this.setState({'isSaving': true, "error": ""});
            await this.props.tryUpdateExpertProfile({"defaultCallLength": this.state.callLength}, "defaultCallLengthError");
            if(this.props.profile.get("defaultCallLengthError") === "") {
                this.setState({'isSaving': false, 'isSaved': true, "callLengthPrevious": this.state.callLength, "isEdit": false});
                this.timeout = setTimeout(() => {this.setState({'isSaved': false, "isChanged": false})}, 3000);
            } else {
                this.setState({'isSaved': true, "isSaving": false, "error": this.props.profile.get("defaultCallLengthError")});
                this.timeout = setTimeout(() => {this.setState({'isSaved': false, "error": ""})}, 3000);
            }
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if(this.state.isSaved) {
                return(
                    <>
                        {this.state.error === '' && <SavedSettings height={"30px"} error={false} message={"Saved"}/>}
                        {this.state.error !== '' && <SavedSettings height={"30px"} error={true} message={this.state.error}/>}
                    </>
                )
            } else {
                if(this.state.isEdit) {
                    return(
                        <form method={"post"} onSubmit={this.submitCallLength}>
                            <div>
                                <FontTitle18>Default Meeting (minutes)</FontTitle18>
                                <br />
                                <InputSection
                                    id={"callLength"}
                                    onChange={this.changeCallLength}
                                    placeholder={"Minutes"}
                                    value={this.state.callLength}
                                />
                            </div>
                            <SaveSection>
                                <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                <InlineBlock><span onClick={() => {return this.reset()}}><ButtonTertiary canSubmit={false} label={"Cancel"} /></span></InlineBlock>
                            </SaveSection>
                        </form>
                    )
                } else {
                    return(
                        <>
                            <FontTitle18>Default Meeting</FontTitle18>
                            <br />
                            <Label>{this.state.callLength}-minutes long</Label>
                            <br />
                            <FitContent><FontBody16 onClick={() => {return this.edit(true)}}><ButtonSecondary canSubmit={false} label={<><EditOutlined/>&nbsp;Change Default Length</>} /></FontBody16></FitContent>
                        </>
                    )
                }
            }
        }
    }
}

const mapStateToProps = state => ({
    profile: state.enterprise.shop.expertProfile.get("expertProfile")
});

const mapDispatchToProps = dispatch => ({
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideBarContactInput));
