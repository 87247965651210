import React from 'react';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import moment from 'moment/moment';

/* Components */
import {colors} from "../../../common/components/colors";
import { images } from '../../../common/components/images';
import { FontSubtitle21, FontBody16, FontSubtitle18 } from '../../../common/components/fonts';
import { IconButton } from '../../../common/components/buttons';

/* Utility */
import { hexToRGB, brightness } from '../../../common/utils/colors';

const Appointments = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
`;
const CoachCell = styled.div`
    background-color: ${props => props.backgroundColor};
     ${props => props.shapeStartTopLeftRound !== true} {
        border-radius: ${props => props.even ? '0px 20px 0px 20px' : '20px 0px 20px 0px'};
    }
     ${props => props.shapeStartTopLeftRound !== false} {
        border-radius: ${props => props.even ? '20px 0px 20px 0px' : '0px 20px 0px 20px'};
    }
    box-sizing: border-box;
    ${props => props.enableClick === false} {
        cursor: pointer;
    }
    color: ${props => brightness(hexToRGB(props.backgroundColor, false)) < 130 ? colors.white : colors.primary100};
    padding: 25px;
    width: 100%;
    row-gap: 20px;
    display: flex;
    flex-direction: column;
    text-align: start;
`;
const FlexRow = styled.div`
    column-gap: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const FlexRB = styled(FlexRow)`
    justify-content: space-between;
`;
const FlexRowDash = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    column-gap: 20px;
    margin-bottom: 15px;
`;
const ProfileIcon = styled.img`
    border-radius: 50%;
    height: 50px;
    width: 50px;
    object-fit: cover;
`;
const FontSubtitle21CC = styled(FontSubtitle21)`
    align-self: center;
`;
const ColumnFlex = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
`
const ProgramCover = styled.img`
    border-radius: 10%;
    height: 90px;
    width: 120px;
    object-fit: cover;
    min-width: 120px;
`;
const MoreInfoButton = styled.div`
    border-radius: 25px;
    color: ${colors.white};
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: ${colors.action100};
    cursor: pointer;
    padding: 5px 10px;
    box-shadow: 2px 5px 8px 0px rgba(57, 73, 80, 0.2);
    margin-top: 5px;
`
export const ProgramsList = ({programs = [], hideEmpty = false, enableClick = false, shapeStartTopLeftRound = true, backgroundColor = null, dashboard = false, enrolled=true, seeDetails = () => {}}) => {
    let history = useHistory();
    console.log('props.meetings', programs);
    if (programs.length === 0 && !hideEmpty) {
        return <FontSubtitle21CC>{'No programs found'}</FontSubtitle21CC>;
    } else {
        return (
            <>
            {dashboard 
            ? 
                <>
                {enrolled
                ?
                    <ColumnFlex>
                        {programs.map((program, idx) => {
                            return (
                                <FlexRowDash key={idx}>
                                    <ProgramCover src={program.coverPhoto == null ? program.imageUrl : program.coverPhoto}/>
                                    <ColumnFlex>
                                        <FontSubtitle18>{program.programTitle}</FontSubtitle18>
                                        <FontBody16>{program.title}</FontBody16>
                                        <FontBody16>{moment(program.flashSaleStartDateTime).format("MMM Do, YYYY")}, {moment(program.flashSaleStartDateTime).format("h:mm")} - {moment(program.flashSaleEndDateTime).format("LT")}</FontBody16>
                                    </ColumnFlex>
                                </FlexRowDash>
                            )
                        })}
                    </ColumnFlex>
                :
                    <ColumnFlex>
                        {programs.map((program, idx) => {
                            return (
                                <FlexRowDash key={idx}>
                                    <ProgramCover src={program.get('imageUrl')}/>
                                    <ColumnFlex>
                                        <FontSubtitle18>{program.get('title')}</FontSubtitle18>
                                        <MoreInfoButton onClick={() => seeDetails(program.get('id'))}>
                                            <FontBody16>More Info</FontBody16>
                                        </MoreInfoButton>
                                    </ColumnFlex>
                                </FlexRowDash>
                            )
                        })}
                    </ColumnFlex>
                }
                </>
            :
                <Appointments>
                    {programs.map((program, idx) => {
                        return (
                            <CoachCell
                                key={idx}
                                backgroundColor={backgroundColor === null ? colors.cellColors[idx % colors.cellColors.length] : backgroundColor}
                                even={idx % 2 !== 0}
                                onClick={() => {}}
                                enableClick={enableClick}
                                shapeStartTopLeftRound={shapeStartTopLeftRound}
                            >
                                <FlexRow>
                                    {program.coverPhoto !== null && <ProfileIcon src={program.coverPhoto} alt={''}/>}
                                    <div>
                                        <FontSubtitle21>{program.programTitle}</FontSubtitle21>
                                        <FontBody16>{program.title}</FontBody16>
                                    </div>
                                </FlexRow>
                                <FlexRB>
                                    <FontBody16>{moment(program.flashSaleStartDateTime).format("MMM Do, YYYY")}, {moment(program.flashSaleStartDateTime).format("h:mm")} - {moment(program.flashSaleEndDateTime).format("LT")}</FontBody16>
                                   {enableClick && <IconButton image={images.rescheduleDark} />}
                                </FlexRB>
                            </CoachCell>
                        )
                    })}
                </Appointments>
            }
            </>
        );
    }
};