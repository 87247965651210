import {axiosReq} from "../../../common/utils/axios";

/* Middleware */
import {setMessagesSendComplete, setMessagesSendPending} from "../store/emailer";

/* Static */
import {devMessagesAddData} from "../static/devdata";

export const tryPostMessagesAll = (mediaType, content, subject, id) => {
    return async (dispatch, getState) => {
        dispatch(setMessagesSendPending());
        const platform = getState().common.user.get("platform");
        const url = platform === "consumer" ? 'apiv1/consumer/coach/'+id+'/messages' : 'apiv1/coach/coach/'+id+'/messages';
        const message = await axiosReq(url, "POST",  {content: content, subject: subject, channel: mediaType}, devMessagesAddData(true, content, subject, mediaType));
        let messageResult;
        if(message.hasOwnProperty("error")) {
            messageResult = message;
        } else {
            messageResult = {"error": null}
        }
        dispatch(setMessagesSendComplete());
        return(messageResult)
    }
};
