import { combineReducers } from 'redux';

/* Reducers */
import curriculumReducer from './store/courses';

const curriculumReducers = combineReducers({
    curriculum: curriculumReducer,
});

export default curriculumReducers;
