import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {
    setWmcsAssignedToCoach,
    setWmcsForCoachFetching,
    setWmcsForCoachFetched,
    setPartnersForCoachFetching,
    setPartnersAssignedToCoach,
    setPartnersForCoachFetched,
    setSupportForWmcFetching,
    setSupportAssignedToWmc,
    setSupportForWmcFetched,
    setClientsAssignedToAdvisor,
    setClientsForAdvisorFetching,
    setClientsForAdvisorFetched
} from '../store/coaching';

/* Static */
import {devClientsForAdvisor, devWMCsForCoach, devWMCAdvisorsForCoach, devWillowRMForCoach} from '../static/devdata';

export const tryGetWmcsForCoach = () => {
    return async dispatch => {
        dispatch(setWmcsForCoachFetching(true));
        const wmcs = await axiosReq('apiv1/wmc/coach/get-wmc-partners', "GET", {}, devWMCsForCoach);
        await dispatch(setWmcsAssignedToCoach(wmcs));
        dispatch(setWmcsForCoachFetched(true));
        dispatch(setWmcsForCoachFetching(false));
        return wmcs;
    }
};

export const tryGetPartnersForCoach = (wmcId) => {
    return async dispatch => {
        dispatch(setPartnersForCoachFetching(true));
        const advisors = await axiosReq('apiv1/wmc/' + wmcId + '/coach/get-wmc-advisors', "GET", {}, devWMCAdvisorsForCoach);
        await dispatch(setPartnersAssignedToCoach(advisors));
        dispatch(setPartnersForCoachFetched(true));
        return dispatch(setPartnersForCoachFetching(false));
    }
};

export const tryGetSupportForWmc = (wmcId) => {
    return async dispatch => {
        dispatch(setSupportForWmcFetching(true));
        const support = await axiosReq('apiv1/wmc/' + wmcId + '/coach/get-all-willow-rm', "GET", {}, devWillowRMForCoach);
        await dispatch(setSupportAssignedToWmc(support));
        dispatch(setSupportForWmcFetched(true));
        return dispatch(setSupportForWmcFetching(false));
    }
};


export const tryGetClientsForAdvisor = (advisorId) => {
    return async dispatch => {
        dispatch(setClientsForAdvisorFetching(true));
        const clients = await axiosReq('apiv1/coach/advisor/' + advisorId + '/get-assigned-prospects', "GET", {}, devClientsForAdvisor);
        await dispatch(setClientsAssignedToAdvisor(clients));
        dispatch(setClientsForAdvisorFetched(true));
        return dispatch(setClientsForAdvisorFetching(false));
    }
};


export const tryGetCoachesAssignedToProspect = (prospectId, storeOrReturn) => {
    // return async dispatch => {
    //     dispatch(setCoachesAssignedToProspectFetching(true));
    //     const assignedCoaches = await axiosReq('apiv1/wmc/all-coaches/'+prospectId, "GET", {}, devEnterpriseWMCProspectsCoachingData);
    //     if(storeOrReturn === "store") {
    //         await dispatch(setCoachesAssignedToProspect(assignedCoaches.allCoaches));
    //         return dispatch(setCoachesAssignedToProspectFetching(false));
    //     } else {
    //         dispatch(setCoachesAssignedToProspectFetching(false));
    //         return assignedCoaches.allCoaches;
    //     }
    // }
};

export const tryPostUpdateProspectsSessions = (coachId, sessionsDelta) => {
    // return async (dispatch, getState) => {
    //     dispatch(setUpdateProspectsSessionsFetching(true));
    //     const consumerId = getState().enterprise.clientsDetail.get("client").get("consumerId");
    //     const prospectId = getState().enterprise.clientsDetail.get("client").get("prospectId");
    //     await axiosReq('apiv1/wmc/coach/assign-lily-sessions', "POST", {"sessionsDelta": sessionsDelta, "coachId": coachId, "lilyId": consumerId, "lilyProspectId": prospectId}, {});
    //     return dispatch(setUpdateProspectsSessionsFetching(false));
    // }
};

export const tryGetCoachProgramCoachProspect = (prospectId, storeOrReturn) => {
    // return async dispatch => {
    //     dispatch(setCoachProgramFetching(true));
    //     const program = await axiosReq('apiv1/coach/willow-prospects/'+prospectId, "GET", {}, devEnterpriseCoachProgramData("coach"));
    //     dispatch(setCoachProgramFetching(false));
    //     if(storeOrReturn === "store") {
    //         return dispatch(setCoachProgram(program));
    //     } else {
    //         return(program);
    //     }
    // }
};

export const tryGetCoachProgramWMCProspect = (prospectId, storeOrReturn) => {
    // return async dispatch => {
    //     dispatch(setCoachProgramFetching(true));
    //     const program = await axiosReq('apiv1/coach/wmc-prospects/'+prospectId, "GET", {}, devEnterpriseCoachProgramData("advisor"));
    //     dispatch(setCoachProgramFetching(false));
    //     if(storeOrReturn === "store") {
    //         return dispatch(setCoachProgram(program));
    //     } else {
    //         return(program);
    //     }
    // }
};

export const tryPostAssignCoachToProspect = (coachId, numSessions, coachName, consumerId, prospectId, prospectStatus) => {
    // return async (dispatch, getState) => {
    //     dispatch(setCoachAssignToProspectFetching(true));
    //     await axiosReq('apiv1/wmc/coach/assign-lily-prospect', "POST", {"coachId": coachId, "lilyId": consumerId, "lilyProspectId": prospectId, "numberOfSessions": numSessions}, {});
    //     if(prospectStatus === "Prospect") {
    //         let coach = getState().enterprise.prospects.get("prospectsOne").get("coach");
    //         let edit = {"coach": coach === null ? coachName : "Multiple"};
    //         dispatch(setAllProspectsEditExistingProspect(prospectId, edit));
    //         dispatch(setProspectsOneEditExisting(edit));
    //     } else if(prospectStatus === "Client") {
    //         let coach = getState().enterprise.clientsList.get("clientsOne").get("coach");
    //         let edit = {"coach": coach === null ? coachName : "Multiple"};
    //         dispatch(setAllClientsEditExistingClient(prospectId, edit));
    //         dispatch(setClientsOneEditExisting(edit));
    //     }
    //     return dispatch(setCoachAssignToProspectFetching(false));
    // }
};
