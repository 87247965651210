import React from 'react';
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {v4 as uuid4} from 'uuid';
import styled from "styled-components";
import validator from "validator";
import moment from "moment/moment";

/* Store */
import {setShopItemLicense} from "../store/shopitems";

/* Components */
import {DropdownInput, TextInput} from "../../../common/components/inputs";
import {FontBody16, FontTitle18} from "../../../common/components/fonts";
import {InlineBlock, SaveSection, SubmitInputWithValidation} from "../../settings/scheduler/components/allservicespage";
import {ButtonTertiary} from "../../../common/components/buttons";

/* Containers */
import PhotoUploadSection from '../../containers/photoupload';

/* Middleware */
import {tryUpdateExpertProfile} from '../middleware/expertprofile';
import {colors} from "../../../common/components/colors";

export const Width = styled.div`
    max-width: 536px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
`;
const TitleMargin = styled.div`
    margin-top: -15px;
`;
const Gray = styled.div`
    color: ${colors.secondary100};
`;
const Red = styled.div`
    color: ${colors.alert100};
`;
const InlineWithMargin = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 250px;
    margin-right: 15px;
    margin-top: -35px;
`;
const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;

const monthOptions = [
    {value: "00", label: 'Month'},
    {value: "01", label: 'January'},
    {value: "02", label: 'February'},
    {value: "03", label: 'March'},
    {value: "04", label: 'April'},
    {value: "05", label: 'May'},
    {value: "06", label: 'June'},
    {value: "07", label: 'July'},
    {value: "08", label: 'August'},
    {value: "09", label: 'September'},
    {value: "10", label: 'October'},
    {value: "11", label: 'November'},
    {value: "12", label: 'December'}
];
const generateRange = (min, max, step) => {
    let arr = [];
    for(let i = min; i <= max; i += step) {
        arr.push({
            "value": i.toString(),
            "label": i.toString()
        });
    }
    return arr;
};

const years = generateRange(1950, parseInt(moment().format("yyyy")), 1);
const yearOptions = years.sort((a, b) => (a.value < b.value) ? 1 : -1);
yearOptions.unshift({"value": "00", "label": "Year"});

const setMonth = (num) => {
    if(num === null) {return("00")}
    else if(num === 1) {return("01")}
    else if(num === 2) {return("02")}
    else if(num === 3) {return("03")}
    else if(num === 4) {return("04")}
    else if(num === 5) {return("05")}
    else if(num === 6) {return("06")}
    else if(num === 7) {return("07")}
    else if(num === 8) {return("08")}
    else if(num === 9) {return("09")}
    else if(num === 10) {return("10")}
    else if(num === 11) {return("11")}
    else if(num === 12) {return("12")}
};

class OneLicense extends React.Component {
    state={
        "id": uuid4(),
        "title": "", "titleValid": true,
        "institute": "", "instituteValid": true,
        "licenseNumber": "", "licenseNumberValid": true,
        "isSaving": false,
        "isSaved": false,
        "error": '',
        "isValid": true,
        "isChanged": false,
        "endMonth": "00",
        "endYear": "00",
        "photo": null,
        "photoFile": null,
        "photoBlob": null,
        "photoChanged": false
    };

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    init = async () => {
        if(Object.keys(this.props.license.toJS()).length > 0) {
            await this.setState({
                "id": this.props.license.get("id"),
                "photo": this.props.license.get("photo"),
                "title": this.props.license.get("title") === null ? "" : this.props.license.get("title"),
                "institute": this.props.license.get("institute") === null ? "" : this.props.license.get("institute"),
                "licenseNumber": this.props.license.get("licenseNumber") === null ? "" : this.props.license.get("licenseNumber"),
                "endMonth": await setMonth(this.props.license.get("endMonth")),
                "endYear": this.props.license.get("endYear") === null ? "00" : this.props.license.get("endYear"),
            });
        }
        this.checkValid();
    };

    handleChangeTitle = async e => {
        await this.setState({
            'title': e.target.value,
            'titleValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''),
            'isChanged': true
        });
        this.checkValid();
    };

    handleChangeInstitute = async e => {
        await this.setState({
            'institute': e.target.value,
            'instituteValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''),
            'isChanged': true
        });
        this.checkValid();
    };

    handleChangeLicenseNumber = async e => {
        await this.setState({
            'licenseNumber': e.target.value,
            'licenseNumberValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''),
            'isChanged': true
        });
        this.checkValid();
    };

    endMonth = async (e) => {
        await this.setState({
            "endMonth": e.value,
            'isChanged': true
        });
        this.checkValid();
    };

    endYear = async (e) => {
        await this.setState({
            "endYear": e.value,
            'isChanged': true
        });
        this.checkValid();
    };

    updatePhoto = async (photo) => {
        await this.setState({
            "photo": photo.photo,
            "photoFile": photo.photoFile,
            "photoBlob": photo.photoBlob,
            "photoChanged": true,
            'isChanged': true
        });
        console.log(this.state)
    };

    checkValid = () => {
        let titleValid = (!validator.isEmpty(this.state.title) && this.state.title !== '');
        let endValid = !(this.state.endYear === "00" && this.state.endMonth !== "00");
        if(titleValid && endValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            this.setState({'isSaving': true});
            const data = {
                "id": this.state.id,
                "photo": null,
                "title": this.state.title,
                "institute": this.state.institute,
                "licenseNumber": this.state.licenseNumber,
                "endMonth": this.state.endMonth === "00" ? null : parseInt(this.state.endMonth),
                "endYear": this.state.endYear === "00" ? null : parseInt(this.state.endYear),
            };
            let licenses = this.props.profile.get("licenses").toJS();
            if(this.props.location.pathname === "/"+(this.props.isWMC ? "profile" : "shop")+"/about/license/create") {
                await licenses.push(data);
            } else {
                licenses = licenses.map(p => {
                    if(p.id === this.state.id) {
                        return(data)
                    } else {
                        return(p)
                    }
                })
            }
            const saveData = {
                // "bio": this.props.profile.get("bio"),
                // "whyBecameCoach": this.props.profile.get("whyBecameCoach"),
                // "freeTime": this.props.profile.get("freeTime"),
                // "endorsement1": this.props.profile.get("endorsement1"),
                // "endorsement2": this.props.profile.get("endorsement2"),
                // "endorsement3": this.props.profile.get("endorsement3"),
                "licenses": licenses
            };
            console.log(saveData);
            await this.props.tryUpdateExpertProfile(saveData, "oneLicense");
            this.setState({'isSaving': false, 'isSaved': true});
            this.timeout = setTimeout(() => {
                this.setState({'isSaved': false});
                this.props.setShopItemLicense({});
                this.props.history.push("/"+(this.props.isWMC ? "profile" : "shop")+"/about");
            }, 3000);
        }
    };

    render() {
        if(this.state.isSaved) {
            if(this.state.error === "") {
                if(this.props.location.pathname === "/"+(this.props.isWMC ? "profile" : "shop")+"/about/license/create") {
                    return(<FontTitle18>Created!</FontTitle18>)
                } else {
                    return(<FontTitle18>Saved!</FontTitle18>)
                }
            } else if(this.state.error !== '') {
                return(<FontTitle18>{this.state.error}</FontTitle18>)
            }
        } else {
            return(
                <Width>
                    <form method={"post"} onSubmit={this.handleSubmit}>
                        <TitleMargin>
                            <TextInput
                                title={"Title*"}
                                valid={this.state.titleValid}
                                warning={"Required"}
                                id={"title"}
                                onChange={this.handleChangeTitle}
                                placeholder={"Add title"}
                                value={this.state.title}
                            />
                        </TitleMargin>
                        <TextInput
                            title={"Institute"}
                            valid={this.state.instituteValid}
                            warning={""}
                            id={"institute"}
                            onChange={this.handleChangeInstitute}
                            placeholder={"Add institute"}
                            value={this.state.institute}
                        />
                        <br />
                        <FontBody16>
                            <Inline><Gray>End Date</Gray></Inline>
                            {(this.state.endYear === "00" && this.state.endMonth !== "00") &&
                            <Inline><Red><FontBody16>&nbsp;&nbsp;Please enter a year</FontBody16></Red></Inline>
                            }
                        </FontBody16>
                        <div>
                            <InlineWithMargin>
                                <DropdownInput
                                    title={null}
                                    options={monthOptions}
                                    id={"endMonth"}
                                    onChange={this.endMonth}
                                    placeholder={null}
                                    value={this.state.endMonth === null ? "00" : this.state.endMonth.toString()}
                                />
                            </InlineWithMargin>
                            <InlineWithMargin>
                                <DropdownInput
                                    title={null}
                                    options={yearOptions}
                                    id={"endYear"}
                                    onChange={this.endYear}
                                    placeholder={null}
                                    value={this.state.endYear === null ? "00" : this.state.endYear.toString()}
                                />
                            </InlineWithMargin>
                        </div>
                        <TextInput
                            title={"License Number"}
                            valid={this.state.licenseNumberValid}
                            warning={""}
                            id={"licenseNumber"}
                            onChange={this.handleChangeLicenseNumber}
                            placeholder={"Add license number"}
                            value={this.state.licenseNumber}
                        />
                        <SaveSection>
                            <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                            <InlineBlock><Link to={"/"+(this.props.isWMC ? "profile" : "shop")+"/about"}><ButtonTertiary canSubmit={false} label={"Cancel"} /></Link></InlineBlock>
                        </SaveSection>
                    </form>
                </Width>
            )
        }
    }
}

const mapStateToProps = state => ({
    isWMC: state.common.wmc.get("isWMC"),
    license: state.enterprise.shop.shopItems.get("license"),
    profile: state.enterprise.shop.expertProfile.get("expertProfile"),
});

const mapDispatchToProps = dispatch => ({
    setShopItemLicense: (item) => dispatch(setShopItemLicense(item)),
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OneLicense));
