import React from 'react';

/* Components */
import {PageContentContainer2, PageFull} from '../navbars/components/navlayout';
import ConsumerNav from '../navbars/containers/nav';

/* Containers */
import HomeContainer from "./containers/homecontainer";
import styled from "styled-components";

export const PageContentContainerV2 = styled(PageContentContainer2)`
    padding: 90px 0 120px 0;
    @media screen and (max-width: 748px) {
        padding: 30px 0 120px 0;
    }
    @media screen and (max-width: 450px) {
        padding: 30px 0 120px 0;
    }
`;

export const PageFullV2 = styled(PageFull)`
    margin-left: 50px;
    width: calc(100% - 50px);
    min-height: 100vh;
`;
const Home = () => (
    <>
        <ConsumerNav />
        <PageFullV2 showBackground={true}>
            <PageContentContainerV2>
                <HomeContainer />
            </PageContentContainerV2>
        </PageFullV2>
    </>
);

export default Home;