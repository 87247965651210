import styled from "styled-components";
import {colors} from "../../../common/components/colors";
import {FontBody16, FontBody12} from "../../../common/components/fonts";
import moment from "moment";
import React from "react";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

export const NoteTitle = styled.div`
    padding: 15px 0px 10px 0px;
    ${props => props.isFromMe === true} {
        color: ${colors.secondary100};
    }
    ${props => props.isFromMe === false} {
        color: ${colors.white};
    }
`;
export const Date = styled.div`
    color: ${colors.primary30};
    margin-top: 20px;
`;
export const ComposeWrapper = styled.div`
    border-radius: 6px 6px 0px 0px;
    z-index: 15;
    position: fixed;
    bottom: 0;
    right: 100px;
    max-width: 340px;
    width: 100%;
    -webkit-box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    @media screen and (max-width: 1088px) {
        right: 60px;
        max-width: 340px;
    }
    @media screen and (max-width: 768px) {
        -webkit-box-shadow: none;
        box-shadow: none;
        ${props => props.minimized !== false} {
            top: 0;
            height: 100%;
            overflow-y: auto;
        }
        background-color: ${colors.white};
        right: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
    }
`;
export const Compose = styled.div`
    padding: 20px 0px 0px 0px;
    background-color: ${colors.white};
    @media screen and (max-width: 768px) {
        height: calc(100vh - 56px);
        width: 100%;
    }
`;
export const Buttons = styled.div`
    margin-top: 20px;
`;
export const ComposeArea = styled.div`
    background-color: ${colors.border100};
    padding: 20px 0px;
    border-top: 1px solid ${colors.primary30};
    @media screen and (max-width: 460px) {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 16;
        padding: 10px 0px;
    }
`;
export const Templates = styled.div`
    display: inline-block;
    vertical-align: top;
    & .Dropdown-control {
        height: 19px;
        width: calc(100% - 20px);
        margin-left: 10px;
    }
    @media screen and (max-width: 768px) {
        & .Dropdown-control {
            height: 19px;
            margin-left: 14px;
            width: auto;
            margin-bottom: 10px;
        }
    }
    @media screen and (max-width: 460px) {
        display: block;
        text-align: center;
        margin-bottom: 20px;
        & .Dropdown-control {
            height: 19px;
            margin: 0px auto 10px auto;
            width: auto;
        }
        & > div {
            margin: 0px auto 20px auto;
        }
    }
`;
export const DropdownSize = styled.div`
    width: 300px;
    @media screen and (max-width: 748px) {
        margin: 0px auto;
    }
    @media screen and (max-width: 460px) {
        width: 238px;
    }
    & > div {
        margin: 0px auto 0px auto;
    }
    & .Dropdown-menu {
        width: calc(100% - 20px);
        margin-left: 10px;
        bottom: 40px;
    }
    @media screen and (max-width: 460px) {
        & .Dropdown-menu {
            width: auto;
            margin-left: 0px;
            bottom: 40px;
        }
    }
`;

export const Close = styled.div`
    background-color: ${colors.primary100};
    width: 100%;
    border-radius: 6px 6px 0px 0px;
    height: 36px;
`;
export const CloseMessage = styled.div`
    display: inline-block;
    color: ${colors.border100};
    padding: 7px 0px 0px 15px;
    width: calc(100% - 91px);
    cursor: pointer;
`;
export const CloseButton = styled.div`
    padding: 10px;
    color: ${colors.border100};
    cursor: pointer;
    font-weight: bold;
    width: 18px;
    font-size: 14px;
    display: inline-block;
`;

export const Subject = styled.input`
    border: 1px solid ${colors.primary30};
    padding: 4px 14px;
    width: calc(100% - 30px);
    margin: 5px 0px 10px 0px;
    &:active, &:focus {
        outline: none;
    }
`;
export const Body = styled.div`
    overflow-y: scroll;
    padding: 0px 10px;
    min-height: 300px;
    max-height: min(500px, calc(100vh - 500px));
    @media screen and (max-width: 768px) {
        height: calc(100vh - 85px - ${props => props.textareaHeight}px);
    }
    @media screen and (max-width: 350px) {
        min-height:200px;
    }
`;
export const BodyEmpty = styled.div`
    text-align: center;
    margin-top: 40px;
    color: ${colors.primary70};
`;

export const To = styled.div`
    margin-bottom: 5px;
    padding: 0px 20px;
`;
export const Who = styled.div`
    display: inline-block;
    color: ${colors.primary30};
    width: 30px;
`;
export const Receipt = styled.div`
    display: inline-block;
    ${props => props.isFromMe === true} {
        color: ${colors.primary100};
    }
    ${props => props.isFromMe === false} {
        color: ${colors.primary100};
    }
`;
export const ToFrom = ({who, recipient, isFromMe}) => (
    <div>
        <Who><FontBody16>{who+":"}</FontBody16></Who>
        <Receipt isFromMe={isFromMe}><FontBody16>{recipient}</FontBody16></Receipt>
    </div>
);

export const Modal = styled.div`
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    padding: 30px;
    border-radius: 4px;
    background-color: ${colors.white};
    -webkit-box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    max-width: 300px;
    width: calc(100% - 80px);
    z-index: 20;
`;
export const ConfirmTitle = styled.div`
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.border100};
    margin-bottom: 15px;
`;
export const ConfirmActions = styled.div`
    margin-top: 20px;
`;
export const ConfirmLeft = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
`;
export const ConfirmRight = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
`;
export const ConfirmBtnFitLeft = styled.div`
    width: fit-content;
`;
export const ConfirmBtnFitRight = styled.div`
    width: fit-content;
    margin: 0px 0px 0px auto;
`;
export const Sent = styled.div`
    color: ${colors.progress100};
    text-align: center;
    padding: 30px 0px;
`;


export const MessageContainer = styled.div`
    max-width: 200px;
    padding: 10px 15px;
    margin: 20px 0px;
    border-radius: 20px;
    ${props => props.isFromMe === true} {
        background-color: ${colors.backgroundChatBubble};
        color: ${colors.primary100};
    }
    ${props => props.isFromMe === false} {
        margin-left: calc(100% - 230px);
        background-color: ${colors.backgroundColor1};
        color: ${colors.primary100};
        @media screen and (max-width: 460px) {
            margin-left: calc(100% - 240px);
        }
    }
    @media screen and (max-width: 460px) {
        border-radius: 10px;
        padding: 7px 10px;
        max-width: 220px;
    }
    & a {
        text-decoration: underline;
        ${props => props.isFromMe === true} {
            color: ${colors.primary100};
        }
        ${props => props.isFromMe === false} {
            color: ${colors.primary100};
        }
    }
`;
export const Time = styled.div`
    margin-top: 10px;
    ${props => props.isFromMe === true} {
        color: ${colors.secondary100} !important;
    }
    ${props => props.isFromMe === false} {
        color: ${colors.primary30} !important;
    }
`;

export const TextArea = styled.textarea`
    background-color: ${colors.white};
    display: inline-block;
    border-radius: 15px;
    padding: 10px 15px;
    resize: none;
    font-family: GraphicWebRegular;
    font-size: 16px;
    line-height: 21px;
    margin-left: 10px;
    width: calc(100% - 139px);
    max-height: 126px;
    vertical-align: top;
    border: 1px solid ${colors.primary30};
    &:focus {
        outline: 0;
    }
`;
export const SendBtn = styled.div`
    display: inline-block;
    width: 87px;
    vertical-align: top;
`;
export const MessageBody16 = styled(FontBody16)`
    overflow-wrap: break-word;
`


export const Recipient = ({ isFromMe, content, datetime, sendPurchaseLink, purchaseToken }) => (
    <MessageContainer isFromMe={isFromMe} >
        {sendPurchaseLink
            ? <MessageBody16>Here is a <a target={"_blank"} href={"https://"+env.URL.HOSTNAME+"/package/"+purchaseToken}>link to purchase</a></MessageBody16>
            : <MessageBody16>{content}</MessageBody16>
        }
        <Time isFromMe={isFromMe}><FontBody12>{moment(datetime).format('lll').toString()}</FontBody12></Time>
    </MessageContainer>
);
