import {axiosReq} from "../../common/utils/axios";
import moment from 'moment/moment';

/* Store */
import {setClientsActivityFutureSessionsGet, setClientsActivityGet, setClientsActivityFutureSessions} from "../store/clientsactivity";

/* Static */
import {devEnterpriseClientActivityData, devEnterpriseScheduleSessionsForwardData} from '../static/devdata';

/* Transform Activity */

const selectDateTime = (activityType) => {
    if(activityType === "userNotes") {return("datetime")}
    else if(activityType === "currentTasks") {return("userTaskAssigned")}
    else if(activityType === "completedTasks") {return("userTaskAssigned")}
    else if(activityType === "pastConferences") {return("start")}
    else if(activityType === "ongoingConferences") {return("start")}
    else if(activityType === "upcomingConferences") {return("start")}
    else if(activityType === "messages") {return("formattedDatetime")}
    else if(activityType === "email") {return("formattedDatetime")}
    else if(activityType === "sms") {return("formattedDatetime")}
    else if(activityType === "chat") {return("formattedDatetime")}
    else if(activityType === "form") {return("formattedDatetime")}
    else if(activityType === "canceledConferences") {return("start")}
};

const activityForOneType = (activity, activityType) => {
    return Promise.all(activity.map(async n => {
        return Promise.resolve({
            ...n,
            "activityType": activityType,
            "orderDatetime": moment(n[selectDateTime(activityType)])
        });
    }))
};

const checkActivityDayType = (activityDayOneType, activityType) => {
    if(activityDayOneType.length > 0) {
        return(activityForOneType(activityDayOneType, activityType));
    } else {
        return([])
    }
};

const separateMessagesForOneDay = (activityDay, subtype) => {
    let messages = [];
    if(subtype === "messages") {
        messages = ["email", "sms", "chat"];
    } else if(subtype === "forms") {
        messages = ["form"];
    }
    return Promise.all(messages.map(async m => {
        let messagesFiltered = activityDay.filter(f => {return f.channel === m});
        return Promise.resolve(checkActivityDayType(messagesFiltered, m));
    }));
};

const activityForOneDay = (activityDay, activityType) => {
    let types;
    let subtypes;
    if(activityType === "meeting") {
        types = ["pastConferences", "ongoingConferences", "upcomingConferences", "canceledConferences"];
    } else if(activityType === "message") {
        types = ["messages"];
        subtypes = "messages";
    } else if(activityType === "form") {
        types = ["messages"];
        subtypes = "forms";
    } else {
        types = ["userNotes", "currentTasks", "completedTasks", "pastConferences", "ongoingConferences", "upcomingConferences", "messages", "canceledConferences"];
    }
    return Promise.all(types.map(async k => {
        if(k === "messages") {
            return Promise.resolve(separateMessagesForOneDay(activityDay[k], subtypes));
        } else {
            return Promise.resolve(checkActivityDayType(activityDay[k], k));
        }
    }));
};

const activityForAllDays = (activityAll, activityType) => {
    return Promise.all(activityAll.map(async a => {
        return Promise.resolve(activityForOneDay(a, activityType));
    }));
};

const uniqueMeetings = async (activityFlat) => {
    const ongoing = await activityFlat.reduce((filtered, a) => {
        if(a.activityType === "ongoingConferences") {
            filtered.push(a.id)
        }
        return filtered;
    }, []);
    const past = await activityFlat.reduce((filtered, a) => {
        if(a.activityType === "pastConferences") {
            filtered.push(a.id)
        }
        return filtered;
    }, []);
    const overlap = await ongoing.filter(on => {
        return (past.indexOf(on) > -1);
    });
    if(overlap.length > 0) {
        const reduced = await activityFlat.filter(f => {
            return (!(f.activityType === "pastConferences" && f.id === overlap[0]));
        });
        return (reduced);
    } else {
        return activityFlat;
    }
};

const activityModified = async (activity, activityType) => {
    const activityCombined = await activityForAllDays(activity, activityType);
    const activityFlat = await activityCombined.flat(3);
    const activityFixed = await uniqueMeetings(activityFlat);
    return(activityFixed);
};

export const tryGetEnterpriseClientActivity = (date, activityType) => {
    return async (dispatch, getState) => {
        dispatch(setClientsActivityGet(true));
        let clientId = getState().enterprise.clientsDetail.get("client").get("consumerId");
        let activityTypeObj = activityType === null ? {} : {"activityType": activityType};
        const activity = await axiosReq('apiv1/coach/clients/'+clientId+'/activity', "POST", {"endDate": date, ...activityTypeObj}, devEnterpriseClientActivityData);
        const activityAll = await activityModified(activity.days, activityType);
        dispatch(setClientsActivityGet(false));
        return activityAll;
    }
};

const activityFutureMonths = async (activityFuture) => {
    const allMonths = await Promise.all(activityFuture.map(a => {
        return Promise.resolve(moment(a.start).endOf("month").subtract(new Date().getTimezoneOffset(), "minutes").format("YYYY-MM-DD"))
    }));
    const uniqueMonths = await allMonths.filter((value, index, self) => {
        return self.indexOf(value) === index;
    });
    return uniqueMonths.sort((a, b) => {return(new Date(b) - new Date(a))})
};

export const tryGetEnterpriseClientActivityFutureSessions = (date, prospectId) => {
    return async dispatch => {
        dispatch(setClientsActivityFutureSessionsGet(true));
        const activityFuture = await axiosReq('apiv1/coach/schedule/sessions/'+date+'/'+prospectId, "GET", {}, devEnterpriseScheduleSessionsForwardData);
        const activityFutureAll = await checkActivityDayType(activityFuture.sessions, "upcomingConferences");
        const uniqueMonths = await activityFutureMonths(activityFutureAll);
        await dispatch(setClientsActivityFutureSessions(activityFutureAll, uniqueMonths));
        dispatch(setClientsActivityFutureSessionsGet(false));
        console.log(activityFutureAll)
        console.log(uniqueMonths)
        console.log({"activity": activityFutureAll, "months": uniqueMonths})
        return({"activity": activityFutureAll, "months": uniqueMonths});
    }
};