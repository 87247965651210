import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import {Link, withRouter} from 'react-router-dom';

/* Components */
import { DropdownInput, TextInput, PhoneInput, CheckboxWithLabel } from "../../common/components/inputs";
import { FontTitle18 } from "../../common/components/fonts";
import { InlineBlock, SaveSection, Width, SubmitInputWithValidation, WidthRight, Right, CardOrNot } from "../components/clientscreate";
import { ButtonTertiary } from "../../common/components/buttons";

/* Middleware */
import {tryCreateContact} from '../middleware/contacts';
import {tryCreateProspect} from "../middleware/prospects";
import {tryCreateClient} from '../clients/middleware/clients';
import {tryGetSettingsPersonalInfo} from "../middleware/settingspersonalinfo";
import {stageOptions} from "../middleware/prospectsstages";

/* Utils */
import {timeZoneOptions } from '../../common/utils/timezone2';

class ContactsCreate extends React.Component {
    state = {
        first: '', firstValid: true,
        last: '', lastValid: true,
        phone: '', phoneValid: true,
        email: '', emailValid: true,
        companyName: '',
        timeZone: '',
        isSaving: false,
        isSaved: false,
        error: '',
        isChanged: false,
        isValid: true,
        expertId: null,
        wmcId: null,
        stageId: '',
        stageTitle: '',
        stageValid: false,
        prospectChecked: false,
        clientChecked: false,
        contactChecked: false
    };

    componentDidMount() {
        return this.init()
    }

    init = async () => {
        if(this.props.timeZone === "") {
            await this.props.tryGetSettingsPersonalInfo();
        }
        this.setState({
            "expertId": this.props.userId,
            "wmcId": this.props.wmcId,
            "timeZone": this.props.timeZone
        });
    };

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    timeout = 0;

    handleChangeFirst = async e => {
        this.setState({'first': e.target.value});
        await this.setState({'firstValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeLast = async e => {
        this.setState({'last': e.target.value});
        await this.setState({'lastValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeEmail = async e => {
        this.setState({'email': e.target.value});
        await this.setState({'emailValid': validator.isEmail(e.target.value)});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangePhone = async e => {
        this.setState({'phone': e.target.value.replace('(', '').replace(')', '').replace(' ', '').replace('-', '')});
        await this.setState({'phoneValid': validator.isMobilePhone(e.target.value, 'en-US')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeTimeZone = async e => {
        await this.setState({'timeZone': e.value});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeCompanyName = async e => {
        this.setState({
            'companyName': e.target.value,
            'isChanged': true
        });
        this.checkValid();
    };

    checkValid = () => {
        if(this.state.firstValid && this.state.lastValid && /* this.state.phoneValid && */ this.state.emailValid && this.state.timeZone !== "") {
            // if(this.state.prospectChecked && this.state.stageValid) {
            //     this.setState({'isValid': true})
            // } else if(!this.state.prospectChecked && (this.state.clientChecked || this.state.contactChecked)) {
                this.setState({'isValid': true})
            // } else {
            //     this.setState({'isValid': false})
            // }
        } else {
            this.setState({'isValid': false})
        }
    };
    handleChangeStage = async e => {
        await this.setState({
            'stageId': e.value,
            'stageTitle': e.label,
            "stageValid": (!validator.isEmpty(e.label) && e.label !== ''),
            'isChanged': true
        });
        this.checkValid();
    };

    handleMakeProspect = async (id, status) => {
        await this.setState({
            prospectChecked: status,
            clientChecked: false,
            contactChecked: false,
            stageId: '',
            stageTitle: '',
            stageValid: false,
            isChanged: true
        });
        this.checkValid();
    };
    handleMakeClient = async (id, status) => {
        await this.setState({
            prospectChecked: false,
            clientChecked: status,
            contactChecked: false,
            stageId: status ? this.props.stages.filter(s => {return(s.get("title") === "client")}).get(0).get("id") : '',
            stageTitle: status ? 'client' : '',
            stageValid: false,
            isChanged: true
        });
        this.checkValid();
    };
    handleMakeContact = async (id, status) => {
        await this.setState({
            prospectChecked: false,
            clientChecked: false,
            contactChecked: status,
            stageId: '',
            stageTitle: '',
            stageValid: false,
            isChanged: true
        });
        this.checkValid();
    };

    checkErrorExists = async (error) => {
        if(error !== "" && error !== null) {
            this.setState({'error': error, 'isSaving': false, 'isSaved': true, isChanged: false});
            this.timeout = setTimeout(() => {this.setState({'error': null, 'isSaving': false, 'isSaved': false, isChanged: true})}, 3000);
        } else {
            this.setState({'isSaving': false, 'isSaved': true, isChanged: false});
            this.timeout = setTimeout(() => {this.props.history.push("/contacts")}, 3000);
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        await this.handleMakeContact(3, true);
        if(this.state.isValid) {
            this.setState({'isSaving': true, 'error': ''});
            if(this.state.stageId === "") {
                await this.props.tryCreateContact(this.state);
                this.checkErrorExists(this.props.contactsError)
            } else {
                if(this.state.stageTitle === "client") {
                    await this.props.tryCreateClient(this.state);
                    this.checkErrorExists(this.props.clientsError)
                } else {
                    await this.props.tryCreateProspect(this.state);
                    this.checkErrorExists(this.props.prospectCreatedError)
                }
            }

        }
    };

    render() {
        return(
            <>
                <form method={"post"} onSubmit={this.handleSubmit}>
                    {this.state.isSaved
                        ?
                        <>
                            {(this.state.error === '' || this.state.error === null) && <FontTitle18>Created!</FontTitle18>}
                            {(this.state.error !== '' && this.state.error !== null) && <FontTitle18>{this.state.error}</FontTitle18>}
                        </>
                        :
                        <>
                            <div>
                                <Width>
                                    <FontTitle18>About</FontTitle18>
                                    <TextInput
                                        title={"First Name*"}
                                        valid={this.state.firstValid}
                                        warning={"Required"}
                                        id={"first"}
                                        onChange={this.handleChangeFirst}
                                        placeholder={"First Name"}
                                        value={this.state.first}
                                    />
                                    <TextInput
                                        title={"Last Name*"}
                                        valid={this.state.lastValid}
                                        warning={"Required"}
                                        id={"last"}
                                        onChange={this.handleChangeLast}
                                        placeholder={"Last Name"}
                                        value={this.state.last}
                                    />
                                    <TextInput
                                        title={"Company Name"}
                                        valid={true}
                                        warning={""}
                                        id={"companyName"}
                                        onChange={this.handleChangeCompanyName}
                                        placeholder={"Company Name"}
                                        value={this.state.companyName}
                                    />
                                    <PhoneInput
                                        title={"Mobile Phone"}
                                        valid={this.state.phoneValid}
                                        warning={"Enter a valid mobile phone"}
                                        id={"phone"}
                                        onChange={this.handleChangePhone}
                                        placeholder={'(###) ###-####'}
                                        value={this.state.phone}
                                        options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}}
                                    />
                                    <TextInput
                                        title={"Email*"}
                                        valid={this.state.emailValid}
                                        warning={"Enter a valid email address"}
                                        id={"email"}
                                        onChange={this.handleChangeEmail}
                                        placeholder={"Email"}
                                        value={this.state.email}
                                    />
                                    <DropdownInput
                                        title={"Time zone*"}
                                        options={timeZoneOptions}
                                        id={'timeZone'}
                                        onChange={this.handleChangeTimeZone}
                                        placeholder={'Time Zone'}
                                        value={this.state.timeZone}
                                    />
                                </Width>
                                {/*<WidthRight><Right>*/}
                                {/*    <CardOrNot>*/}
                                {/*        <FontTitle18>Relationship</FontTitle18>*/}
                                {/*        <br />*/}
                                {/*        <br />*/}
                                {/*        <CheckboxWithLabel*/}
                                {/*            id={1}*/}
                                {/*            action={this.handleMakeProspect}*/}
                                {/*            checked={this.state.prospectChecked}*/}
                                {/*            label={"Prospect"}*/}
                                {/*        />*/}
                                {/*        {this.state.prospectChecked &&*/}
                                {/*        <DropdownInput*/}
                                {/*            title={"Stage*"}*/}
                                {/*            options={stageOptions(this.props.stages)}*/}
                                {/*            id={'stage'}*/}
                                {/*            onChange={this.handleChangeStage}*/}
                                {/*            placeholder={'Stage'}*/}
                                {/*            value={this.state.stageTitle}*/}
                                {/*        />*/}
                                {/*        }*/}
                                {/*        <br />*/}
                                {/*        <CheckboxWithLabel*/}
                                {/*            id={2}*/}
                                {/*            action={this.handleMakeClient}*/}
                                {/*            checked={this.state.clientChecked}*/}
                                {/*            label={"Client"}*/}
                                {/*        />*/}
                                {/*        <br />*/}
                                {/*        <CheckboxWithLabel*/}
                                {/*            id={3}*/}
                                {/*            action={this.handleMakeContact}*/}
                                {/*            checked={this.state.contactChecked}*/}
                                {/*            label={"Contact"}*/}
                                {/*        />*/}
                                {/*    </CardOrNot>*/}
                                {/*</Right></WidthRight>*/}
                            </div>
                            <SaveSection>
                                <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                <InlineBlock><Link to={"/contacts"}><ButtonTertiary canSubmit={false} label={"Cancel"} /></Link></InlineBlock>
                            </SaveSection>
                        </>
                    }
                </form>
            </>
        )
    }
}

const mapStateToProps = state => ({
    contactsError: state.enterprise.contacts.get("contactsError"),
    prospectCreatedError: state.enterprise.prospects.get("prospectCreatedError"),
    clientsError: state.enterprise.clientsList.get("clientsError"),
    userId: state.common.user.get("userId"),
    wmcId: state.common.wmc.get("wmc").get("id"),
    stages: state.enterprise.prospects.get("prospectStages"),
    timeZone: state.enterprise.settingsPersonal.get("settingsPersonalInfo").get("timeZone")
});
const mapDispatchToProps = dispatch => ({
    tryCreateContact: (data) => dispatch(tryCreateContact(data)),
    tryCreateProspect: (data) => dispatch(tryCreateProspect(data)),
    tryCreateClient: (data) => dispatch(tryCreateClient(data)),
    tryGetSettingsPersonalInfo: () => dispatch(tryGetSettingsPersonalInfo())
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactsCreate));
