import { Map, List, fromJS } from 'immutable';

/* Immutable */

const CLIENTS_CREATING = 'CLIENTS_CREATING';
const CLIENTS_ARCHIVING = 'CLIENTS_ARCHIVING';
const CLIENTS_ERROR = 'CLIENTS_ERROR';
const CLIENTS_ALL = 'CLIENTS_ALL';
const CLIENTS_ALL_FETCHING = 'CLIENTS_ALL_FETCHING';
const CLIENTS_ALL_FETCHED = 'CLIENTS_ALL_FETCHED';
const CLIENTS_ONE_FETCHING = 'CLIENTS_ONE_FETCHING';
const CLIENTS_ONE = 'CLIENTS_ONE';
const CLIENTS_ONE_EDIT_EXISTING = 'CLIENTS_ONE_EDIT_EXISTING';
const CLIENTS_UPDATING = 'CLIENTS_UPDATING';
const CLIENTS_UPDATING_ERROR = 'CLIENTS_UPDATING_ERROR';
const CLIENTS_ALL_EDIT_EXISTING_CLIENT = 'CLIENTS_ALL_EDIT_EXISTING_CLIENT';
const CLIENTS_ALL_ARCHIVE_EXISTING_CLIENT = 'CLIENTS_ALL_ARCHIVE_EXISTING_CLIENT';
const CLIENTS_ALL_UPDATING_NEXT_SESSION = 'CLIENTS_ALL_UPDATING_NEXT_SESSION';
const CLIENTS_ALL_ADD_NEW_CLIENT = 'CLIENTS_ALL_ADD_NEW_CLIENT';
const CLIENTS_ALL_REMOVE_CLIENT = 'CLIENTS_ALL_REMOVE_CLIENT';
const CLIENTS_ALL_SET_NEXT_SESSION = 'CLIENTS_ALL_SET_NEXT_SESSION';
const CLIENTS_LIST_STORE_RESET = 'CLIENTS_LIST_STORE_RESET';

/* Actions */

export const setAllClients = (clients, isWMC) => ({'type': CLIENTS_ALL, 'clients': clients, 'isWMC': isWMC});
export const setClientCreating = (status) => ({'type': CLIENTS_CREATING, 'status': status});
export const setAllClientsFetching = (status) => ({'type': CLIENTS_ALL_FETCHING, 'status': status});
export const setAllClientsFetched = () => ({'type': CLIENTS_ALL_FETCHED});
export const setClientsArchiving = (status) => ({'type': CLIENTS_ARCHIVING, 'status': status});
export const setClientError = (error) => ({'type': CLIENTS_ERROR, 'error': error});
export const setClientsOneFetching = (status) => ({'type': CLIENTS_ONE_FETCHING, 'status': status});
export const setClientsOne = (contact) => ({'type': CLIENTS_ONE, 'contact': contact});
export const setClientsOneEditExisting = (edit) => ({'type': CLIENTS_ONE_EDIT_EXISTING, 'edit': edit});
export const setClientUpdating = (status) => ({'type': CLIENTS_UPDATING, 'status': status});
export const setClientUpdatingError = (error) => ({'type': CLIENTS_UPDATING_ERROR, 'error': error});
export const setAllClientsArchiveExistingClient = (consumerId, isArchived) => ({'type': CLIENTS_ALL_ARCHIVE_EXISTING_CLIENT, 'consumerId': consumerId, 'isArchived': isArchived});
export const setAllClientsEditExistingClient = (prospectId, edit) => ({'type': CLIENTS_ALL_EDIT_EXISTING_CLIENT, 'prospectId': prospectId, 'edit': edit});
export const setAllClientsAddNewClient = (newClient) => ({'type': CLIENTS_ALL_ADD_NEW_CLIENT, 'newClient': newClient});
export const setAllClientsRemoveClient = (prospectId) => ({'type': CLIENTS_ALL_REMOVE_CLIENT, 'prospectId': prospectId});
export const setAllClientsAddNextSession = (prospectId, sessionId, sessionStart) => ({'type': CLIENTS_ALL_UPDATING_NEXT_SESSION, 'prospectId': prospectId, 'sessionId': sessionId, 'sessionStart': sessionStart});
export const setAllClientsSetNextSession = (consumerId, nextSession) => ({'type': CLIENTS_ALL_SET_NEXT_SESSION, 'consumerId': consumerId, 'nextSession': nextSession});
export const setClientsListStoreReset = () => ({'type': CLIENTS_LIST_STORE_RESET});

/* Initial State */

const initialState = Map({
    clientsArchiving: false,
    clientsError: null,
    clientsAll: List(),
    clientsAllFetching: false,
    clientsAllFetched: false,
    clientsCreating: false,
    clientsUpdating: false,
    clientsUpdatingError: '',
    clientsOneFetching: false,
    clientsOneFetched: null,
    clientsOne: Map(),
});

/* Reducer */

const clientsListReducer = (state=initialState, action) => {
    switch (action.type) {
        case CLIENTS_ARCHIVING:
            return state.merge({'clientsArchiving': action.status});
        case CLIENTS_ERROR:
            return state.merge({'clientsError': action.error});
        case CLIENTS_ALL:
            return state.merge({
                'clientsAll': fromJS(action.clients.map(c => {
                    return(fromJS({
                        ...c,
                        "lastName": (!action.isWMC && c.referralSource === "willow") ? c.lastName.charAt(0)+"." : c.lastName
                    }))
                }))
            });
        case CLIENTS_ALL_FETCHED:
            return state.merge({'clientsAllFetched': true});
        case CLIENTS_ALL_FETCHING:
            return state.merge({'clientsAllFetching': action.status});
        case CLIENTS_ALL_ADD_NEW_CLIENT:
            return state.merge({
                'clientsAll': fromJS(state.get("clientsAll").push(fromJS(action.newClient)))
            });
        case CLIENTS_ALL_REMOVE_CLIENT:
            return state.merge({
                'clientsAll': fromJS(state.get("clientsAll").filter(f => {return(f.get("prospectId") !== action.prospectId)}))
            });
        case CLIENTS_ALL_EDIT_EXISTING_CLIENT:
            return state.merge({
                'clientsAll': fromJS(state.get("clientsAll").map(c => {
                    if(c.get("prospectId") === action.prospectId) {
                        return(fromJS({...c.toJS(), ...action.edit}))
                    } else {
                        return(fromJS(c))
                    }
                }))
            });
        case CLIENTS_ALL_ARCHIVE_EXISTING_CLIENT:
            return state.merge({
                'clientsAll': fromJS(state.get("clientsAll").map(c => {
                    if(c.get("consumerId") === action.consumerId) {
                        return(fromJS({...c.toJS(), "isArchived": !action.isArchived}))
                    } else {
                        return(fromJS(c))
                    }
                }))
            });
        case CLIENTS_ALL_UPDATING_NEXT_SESSION:
            return state.merge({
                'clientsAll': fromJS(state.get("clientsAll").map(c => {
                    if(c.get("prospectId") === action.prospectId) {
                        return(fromJS({
                            ...c.toJS(),
                            "nextSession": Map({
                                "sessionId": action.sessionId,
                                "start": action.sessionStart
                            })
                        }))
                    } else {
                        return(fromJS(c))
                    }
                }))
            });
        case CLIENTS_ALL_SET_NEXT_SESSION:
            return state.merge({
                'clientsAll': fromJS(state.get("clientsAll").map(c => {
                    if(c.get("consumerId") === action.consumerId) {
                        return(fromJS({...c.toJS(), "nextSession": fromJS(action.nextSession)}))
                    } else {
                        return(fromJS(c))
                    }
                }))
            });
        case CLIENTS_CREATING:
            return state.merge({'clientsCreating': action.status});
        case CLIENTS_UPDATING:
            return state.merge({'clientsUpdating': action.status});
        case CLIENTS_ONE:
            return state.merge({'clientsOne': fromJS(action.contact)});
        case CLIENTS_ONE_FETCHING:
            return state.merge({'clientsOneFetching': action.status});
        case CLIENTS_ONE_EDIT_EXISTING:
            return state.merge({
                'clientsOne': fromJS({
                    ...state.get("clientsOne").toJS(),
                    ...action.edit
                })
            });
        case CLIENTS_UPDATING_ERROR:
            return state.merge({'clientsUpdatingError': action.error});
        case CLIENTS_LIST_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default clientsListReducer;
