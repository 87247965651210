import React from 'react';
import styled from "styled-components";

import {colors} from '../../../common/components/colors';

export const Page = styled.div`
    width: 1536px;
    padding: 0px 72px;
    margin: 50px auto;
    background-color: ${colors.white};
    position: relative;
    @media screen and (max-width: 1680px) {
        width: calc(100% - 80px);
        padding: 0px 40px;
    }
    @media screen and (max-width: 1088px) {
        width: calc(100% - 60px);
        padding: 0px 30px;
    }
    @media screen and (max-width: 768px) {
        width: calc(100% - 40px);
        padding: 0px 20px;
    }
    @media screen and (max-width: 460px) {
        width: calc(100% - 20px);
        padding: 0px 10px;
        margin: 0px auto 30px auto;
    }
`;
export const Menu = styled.div`
    margin: 60px 0px;
    padding: 00px 0px 20px 0px;
    border-bottom: 1px solid ${colors.primary30};
`;
export const InlineBtn = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-right: 30px;
    cursor: pointer;
    margin-top: 20px;
`;
export const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;
export const InlineLink = styled.div`
    display: inline-block;
    vertical-align: top;
    ${props => props.isActive !== true} {
        color: ${colors.primary100};
        font-weight: 700;
    }
    ${props => props.isActive !== false} {
        color: ${colors.secondary70};
    }
`;
export const Count = styled.div`
    color: ${colors.primary70};
`;
export const PoweredBy = styled.a`
    margin-top: 100px;
    margin-bottom: 50px;
    text-decoration: none;
    color: ${colors.primary100};
    display: inline-block;
    vertical-align: top;
`;
export const Gray = styled.div`
    display: inline-block;
    vertical-align: top;
    color: ${colors.primary70};
`;
export const PoweredByLogo = styled.img`
    display: inline-block;
    vertical-align: top;
    height: 24px;
    width: auto;
`;