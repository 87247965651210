/* 
 * Given a hex color string (e.g. '#ECE5DA'), return a string representing the same color in RGB format (e.g. 'rgb(236,229,218)').
 * Source: https://css-tricks.com/converting-color-spaces-in-javascript/
 */
export const hexToRGB = (h, asString = true) => {
    let r = 0, g = 0, b = 0;
    if (h.length == 4) {
      r = "0x" + h[1] + h[1];
      g = "0x" + h[2] + h[2];
      b = "0x" + h[3] + h[3];
    } else if (h.length == 7) {
      r = "0x" + h[1] + h[2];
      g = "0x" + h[3] + h[4];
      b = "0x" + h[5] + h[6];
    }
    if (asString) {
        return "rgb("+ +r + "," + +g + "," + +b + ")";
    } else {
        return [+r, +g, +b];
    }
    
}

/**
 * Given RGB values in an array, calculate the color's perceived brightness in a range of 0 (black) to 255 (white).
 * Source: https://www.nbdtech.com/Blog/archive/2008/04/27/Calculating-the-Perceived-Brightness-of-a-Color.aspx
 */
export const brightness = (rgb) => {
    let r = rgb[0];
    let g = rgb[1];
    let b = rgb[2];
    return Math.trunc(Math.sqrt(r ** 2 * 0.241 + g ** 2 * 0.691 + b ** 2 * 0.068));
}
