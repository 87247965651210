import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from "styled-components";
import {fromJS} from 'immutable';

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FontBody14, FontSubtitle24To20, FontTitle30, FontBody16, FontHeader16} from "../../../common/components/fonts";
import {images} from "../../../common/components/images";
import {TopLogo} from "../components/match";
import {colors} from "../../../common/components/colors";
import {VideoCameraOutlined} from "@ant-design/icons";

/* Containers */
import IframeAbout from "../../profile/containers/about";
import SchedulerFlow from "../../scheduler/containers/flow";

/* Middleware */
import {tryGetExpertProfileFromToken} from "../../profile/middleware/profile";
import {setShowDemo} from "../../../routes/store/user";
import {trackSchedulePaidCall, trackScheduleComplimentaryCall, trackViewGetCareCoachProfile, /*trackAvailabilityComplimentaryCall*/} from "../../../common/utils/tracking";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

const Container = styled.div`
    max-width: 768px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 60px auto 0px auto;
`;

const ContainerScheduler = styled.div`
    max-width: 500px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 60px auto 0px auto;
`;

const Center = styled.div`
    text-align: center;
`;

const ProfileBackground = styled.div`
    padding: 40px;
    box-shadow: 0px 3px 10px 0px #00000040;
    // background-color: ${colors.backgroundColor1};
    height: calc(100vh - 550px);
    overflow-y: auto;
    border-radius: 50px 0px 0px 50px;
    width: calc(80% - 80px);
    margin: 0px auto;
    @media screen and (max-width: 748px) {
        height: calc(100vh - 615px);
    }
    @media screen and (max-width: 460px) {
        height: calc(100vh - 580px);
        width: calc(90% - 80px);
    }
`;

const Profile = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 20px;
    margin-bottom: 10px;
    justify-content: center;
    @media screen and (max-width: 748px) {
        flex-direction: column;
    }
`;

const ProfileTop = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    flex-grow: 1;
`;

const ProfileImage = styled.img`
    border-radius: 50%;
    height: 120px;
    margin-bottom: 10px;
    object-fit: cover;
    width: 120px;
    @media screen and (max-width: 460px) {
        height: 80px;
        width: 80px;
    }
`;

const FooterButton = styled.div`
    align-items: center;
    display: flex;
    gap: 5px;
    justify-content: center;
    flex-grow: 1;
`;

const Button = styled.div`
    border-radius: 25px;
    box-shadow: 0px 3px 10px 0px #00000040;
    cursor: pointer;
    padding: 10px 20px;
    background-color: ${colors.action100};
    color: ${colors.white};
    width: fit-content;
    margin: 0px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
`;

class GetScheduled extends React.Component {
    static defaultProps = {
        matchingFormSelected: "matchingFormStandard"
    }

    state={
        "form": [],
        "matchingFormSelected": "matchingFormStandard",
        "partnerLogo": null,
        "isLoading": true,
        "step": 1,
        "profile": {},
        "memberCategory": null,
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        // if(this.props.signupMatchCoaches.size === 0) {
        //     return this.props.history.push("/get-matched");
        // } else {
        const user = await this.props.tryAuthOnly(null, false, false);
        if(user === null) {
            this.props.history.push("/get-started")
        } else {
            const partner = env.CONSUMER.SIGN_UP.MATCH_PUBLIC_PAGE.CLIENT_DATA.WMC_IDS[this.props.wmcId]
            const coachesAll = this.props.signupMatchCoaches.toJS();
            let selectedCoachId;
            if (coachesAll.length > 0) {
                selectedCoachId = coachesAll[Math.floor(Math.random() * coachesAll.length)].id // random selection from results
                if (selectedCoachId === null || selectedCoachId === undefined) {
                    selectedCoachId = 40; // If no coaches are appropriate, give them candice
                }
            } else {
                selectedCoachId = 40;
            }
            const profile = await this.props.tryGetExpertProfileFromToken(selectedCoachId);
            const coachCategories = await env.CONSUMER.CARE.PRINT_EXPERT_CATEGORIES(profile.expertCategories, true);
            await this.setState({
                "partnerLogo": partner === undefined ? null : images[partner.logoIcon],
                "profile": fromJS(profile),
                'memberCategory': coachCategories,
                "isLoading": false
            })
            this.props.trackViewGetCareCoachProfile({
                "coachId": profile.id,
                "coachName": profile.firstName + " " + profile.lastName
            })
        }
        // }
    };

    goBack = () => this.setState({"step": 1})

    goSchedule = () => {
        this.setState({"step": 2})
        // const coachDetails = {
        //     "coachId": this.state.profile.get("id"),
        //     "coachName": this.state.profile.get("firstName")+" "+this.state.profile.get("lastName")
        // }
        // this.props.trackAvailabilityComplimentaryCall(coachDetails)
    }

    goHome = async () => {
        await this.props.setShowDemo(true);
        this.props.history.push("/home")
    }

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if (this.state.step === 1) {
                return(
                    <Container>
                        <TopLogo partnerLogo={this.state.partnerLogo} willowLogoIcon={true} />
                        <br />
                        <Center>
                            <FontTitle30>Recommended coach</FontTitle30>
                            <FontBody16>Based on your life journey and preferences, we recommend you work with {this.state.profile.get("firstName")}!</FontBody16>
                        </Center>
                        <br />
                        <Profile>
                            <ProfileTop>
                                <ProfileImage src={this.state.profile.get("photo")}/>
                                <div>
                                    <FontSubtitle24To20>{this.state.profile.get("firstName").toUpperCase()}</FontSubtitle24To20>
                                    <FontSubtitle24To20>{this.state.profile.get("lastName").toUpperCase()}</FontSubtitle24To20>
                                    <FontBody14>{this.state.memberCategory}</FontBody14>
                                </div>
                            </ProfileTop>
                            <FooterButton>
                                <Button onClick={this.goSchedule}>
                                    <VideoCameraOutlined/>
                                    <FontHeader16>{'Book an appointment'}</FontHeader16>
                                </Button>
                            </FooterButton>
                        </Profile>
                        <ProfileBackground>
                            <IframeAbout profile={this.state.profile} viewVerticalOnly={true}/>
                        </ProfileBackground>
                    </Container>
                )
            } else if(this.state.step === 2) {
                return(
                    <ContainerScheduler>
                        <TopLogo partnerLogo={this.state.partnerLogo} willowLogoIcon={true} />
                        <br />
                        <SchedulerFlow
                            userId={this.state.profile.get("id")}
                            userFirstName={this.state.profile.get("firstName")}
                            userLastName={this.state.profile.get("lastName")}
                            userPhotoUrl={this.state.profile.get("photo")}
                            purchaseHistoryId={null}
                            isWillowSession={true}
                            eventCustomMinutes={45}
                            availabilityCloseBtn={true}
                            availabilityOnClose={this.goBack}
                            confirmOnClose={this.goHome}
                            confirmOnScheduled={() => {
                                const coachDetails = {
                                    "coachId": this.state.profile.get("id"),
                                    "coachName": this.state.profile.get("firstName")+" "+this.state.profile.get("lastName")
                                }
                                // this.props.trackScheduleComplimentaryCall(coachDetails)
                                this.props.trackSchedulePaidCall(coachDetails)
                            }}
                            showArrowClose={true}
                            useNewDesign={true}
                        />
                    </ContainerScheduler>
                )
            }
        }
    }

}

const mapStateToProps = state => ({
    signupMatchCoaches: state.iframe.signupMatch.signupMatch.get("signupMatchCoaches"),
    wmcId: state.common.user.get("prospectWmcId")
});

const mapDispatchToProps = dispatch => ({
    tryGetExpertProfileFromToken: (id) => dispatch(tryGetExpertProfileFromToken(id)),
    setShowDemo: (status) => dispatch(setShowDemo(status)),
    // trackAvailabilityComplimentaryCall: (coachDetails) => dispatch(trackAvailabilityComplimentaryCall(coachDetails)),
    trackScheduleComplimentaryCall: (coachDetails) => dispatch(trackScheduleComplimentaryCall(coachDetails)),
    trackSchedulePaidCall: (coachDetails) => dispatch(trackSchedulePaidCall(coachDetails)),
    trackViewGetCareCoachProfile: (coachDetails) => dispatch(trackViewGetCareCoachProfile(coachDetails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GetScheduled));
