import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import styled from 'styled-components'

/* Components */
import {FontHeader21, FontBody16, FontBody12} from "../../../common/components/fonts";
import {ButtonPrimary, ButtonTertiary} from "../../../common/components/buttons";
import {colors} from "../../../common/components/colors";
import {LogoLoading} from "../../../common/components/loading";
import {ActionIcon, ActionItem} from "../components/teamlist";
import {CalendarFilled, MailFilled, MobileFilled, DashOutlined, RightOutlined, ArrowLeftOutlined} from "@ant-design/icons";
import {InlineBlock, SaveSection, SubmitInputWithValidation} from "../../components/clientscreate";
import { Message } from "../../../common/components/messages";

/* Middleware */
import {tryGetPartnersForCoach, tryGetSupportForWmc, tryGetClientsForAdvisor} from "../middleware/coaching";
import {tryGetCoachesForWmc, tryGetClientsForCoach} from "../middleware/rm";

/* Store */
import {setMessagesSMSOpen} from "../../sms/store/messages";
import {setMessagesEmailOpen} from "../../email/store/messages";
import {setSessionsModal} from "../../meetings/store/sessions";
import {setSelectPartner, setSelectWmc, setSelectWmcAdvisor} from "../store/coaching";
import SchedulerFlow from "../../../iframes/scheduler/containers/flow";
import {CommonBackground, CommonModal} from "../../../common/components/modal";

export const ActionBox = styled.div`
    border: 1px solid ${colors.secondary30};
    padding: 25px 0px 0px 0px;
    max-width: calc(700px - 2px);
    width: 100%;
    border-radius: 6px;
    margin-bottom: 20px;
    background-color: ${colors.border70};
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    @media screen and (max-width: 768px) {
        text-align: center;
    }
`;
export const Photo = styled.img`
    display: inline-block;
    vertical-align: top;
    width: 150px;
    height: auto;
    border-radius: 6px;
    margin-right: 30px;
    @media screen and (max-width: 768px) {
        display: block;
        margin: 0px auto;
    }
`;
const TitleSection = styled.div`
    display: inline-block;
    vertical-align: top;
    padding-top: 25px;
    width: calc(100% - 180px);
    @media screen and (max-width: 768px) {
        width: 100%;
        display: block;
    }
`;
const Top = styled.div`
    padding: 0px 30px 30px 30px;
    @media screen and (max-width: 460px) {    
        padding: 0px 20px 30px 20px;
    }
`;
const Category = styled.div`
    color: ${colors.primary100};
    margin-bottom: 16px;
`;
const ActionSection = styled.div`
    display: inline-block;
    vertical-align: middle;
    @media screen and (max-width: 768px) {
        display: block;
        margin-top: 20px;
    }
`;
const ExpandSection = styled.div`
    display: inline-block;
    vertical-align: bottom;
    color: ${colors.primary100};
`;
const Left = styled.div`
    width: 100%;
    display: inline-block;
    vertical-align: bottom;
`;
const IntroButton = styled.div`
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    width: fit-content;
    @media screen and (max-width: 768px) {
        display: block;
        margin: 0px auto;
    }
`;
const Right = styled.div`
    display: inline-block;
    vertical-align: bottom;
    text-align: right;
    color: ${colors.primary70};
    cursor: pointer;
`;
const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;
const More = styled.div`
    font-size: 18px;
    margin-top: -1px;
`;
const Modal = styled.div`
    margin: 0px auto;
    width: 748px;
    padding: 30px;
    border-radius: 6px;
    background-color: ${colors.white};
    position: fixed;
    top: 100px;
    left: 0px;
    right: 0px;
    @media screen and (max-width: 800px) {
        width: 100%;
        padding: 0px;
    }
    z-index: 6;
`;
const Background = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.50);
    @media screen and (max-width: 800px) {
        background-color: ${colors.white};
    }
    z-index: 5;
`;
const RightAligned = styled.div`
    display: inline-block;
    vertical-align: bottom;
    text-align: right;
`;
const BackTop = styled.div`
    width: fit-content;
    color: ${colors.secondary70};
    cursor: pointer;
    position: relative;
    z-index: 2;
    text-align: left;
    display: block;
    padding-bottom: 20px;
    @media screen and (max-width: 748px) {
        margin-left: 60px;
    }
    @media screen and (max-width: 460px) {
        margin-left: 0px;
    }
`;
const SupportSection = styled.div`
    display: inline-block;
    vertical-align: bottom;
    color: ${colors.primary100};
    cursor: pointer;
    padding-bottom: 10px;
`;
const Padding = styled.div`
    padding-right: 15px;
`;

class TeamList extends React.Component {
    state = {
        "photo": null,
        "firstName": null,
        "lastName": null,
        "userId": null,
        "showScheduler": false,
        "isLoading": true,
        "showAll": false,
        "allPartners": [],
        "allWmcs": [],
        "isRm": false,
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.allPartnersFetching !== this.props.allPartnersFetching || prevProps.allCoachesFetching !== this.props.allCoachesFetching || prevProps.allWmcsFetching !== this.props.allWmcsFetching || prevProps.wmcFetching !== this.props.wmcFetching) {
            return this.init();
        }
    }

    init = async () => {
        this.setState({"isRm": this.props.user.get("isSupport")});
        if (this.props.type === "wmc") {
            this.setState({
                "allWmcs": this.props.allWmcs.map(a => {
                    return({...a.toJS(), "initPage": "partnerships"})
                }),
                "isLoading": false
            })
        } else {
            this.setState({
                "allPartners": this.props.allPartners.map(a => {
                    return({...a.toJS(), "expanded": false, "consumerId": a.get("coachId"), "initPage": "partnerships"})
                }),
                "isLoading": false
            })
        }
    };

    selectPartner = async (onePartner) => {
        await this.props.setSelectPartner(onePartner);
        if (this.state.isRm) {
            this.props.history.push("/firms/employees/member");
        } else {
            this.props.history.push("/partners/employees/member");
        }
    };

    selectWmc = async (oneWmc) => {
        await this.props.setSelectWmc(oneWmc);
        await this.props.tryGetPartnersForCoach(oneWmc.id);
        if (this.state.isRm) {
            this.props.history.push("/firms/employees");
        } else {
            this.props.history.push("/partners/employees");
        }
    };

    selectWmcCoaches = async (oneWmc) => {
        await this.props.setSelectWmc(oneWmc);
        await this.props.tryGetCoachesForWmc(oneWmc.id);
        this.props.history.push("/firms/experts");
    }

    viewSupport = async (wmc) => {
        await this.props.setSelectWmc(wmc);
        await this.props.tryGetSupportForWmc(wmc.id);
        if (this.state.isRm) {
            this.props.history.push("/firms/support");
        } else {
            this.props.history.push("/partners/support");
        }
    };

    viewClients = async (c) => {
        await this.props.setSelectWmcAdvisor({
            "id": c.coachId,
            "first": c.first,
            "last": c.last
        });
        await this.props.tryGetClientsForAdvisor(c.coachId);
        this.props.history.push("/partners/employees/clients");
    }

    back = () => {
        if (this.state.isRm) {
            this.props.history.push("/firms");
        } else {
            this.props.history.push("/partners");
        }
    }

    createVideoMeeting = async (c) => {
        this.scheduleMeeting(c.photo, c.first, c.last, c.consumerId)
    };

    createEmail = async (c) => {
        this.props.setMessagesEmailOpen(true, c.coachId, "expert", c.first, c.last, false, null, null);
    };

    createSMS = async (c) => {
        this.props.setMessagesSMSOpen(true, c.coachId, "expert", c.first, c.last, null, null);
    };

    scheduleMeeting = async (photo, firstName, lastName, userId) => {
        await this.setState({"photo": photo, "firstName": firstName, "lastName": lastName, "userId": userId});
        this.changeShowScheduler(true);
    };

    changeShowScheduler = async (status) => {
        this.setState({"showScheduler": status});
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <>
                    {this.props.type === "wmc" ?
                    <>
                        {this.state.allWmcs.map((c, index) => (
                            <ActionBox key={index}>
                                <Top>
                                    <Photo src={c.companyLogoUrl} alt={""} />
                                    <TitleSection>
                                        <FontHeader21>{c.companyName}</FontHeader21>
                                        <div>
                                            <Left> </Left>
                                            <Right>
                                                {!this.state.isRm ?
                                                    <>
                                                        <InlineBlock><Padding><div onClick={() => {return this.selectWmc(c)}}><ButtonPrimary canSubmit={false} label={"Employees"} /></div></Padding></InlineBlock>
                                                        <InlineBlock><Padding><div onClick={() => {return this.viewSupport(c)}}><ButtonPrimary canSubmit={false} label={"Support"} /></div></Padding></InlineBlock>
                                                    </>
                                                :
                                                <>
                                                    <InlineBlock><Padding><div onClick={() => {return this.selectWmc(c)}}><ButtonPrimary canSubmit={false} label={"Employees"} /></div></Padding></InlineBlock>
                                                    <InlineBlock><Padding><div onClick={() => {return this.selectWmcCoaches(c)}}><ButtonPrimary canSubmit={false} label={"Experts"} /></div></Padding></InlineBlock>
                                                    <InlineBlock><Padding><div onClick={() => {return this.viewSupport(c)}}><ButtonPrimary canSubmit={false} label={"Support"} /></div></Padding></InlineBlock>
                                                </>
                                                }
                                                
                                            </Right>
                                        </div>
                                    </TitleSection>
                                </Top>
                            </ActionBox>
                        ))}
                    </> : 
                    <>
                    {/*<BackTop onClick={() => {return(this.back())}}><ArrowLeftOutlined /> Back</BackTop>*/}
                    {/*{!this.state.isRm && */}
                    {/*    <SupportSection onClick={() => {return this.viewSupport(this.props.wmc)}}>*/}
                    {/*        <FontHeader21>View this company's support team <RightOutlined /></FontHeader21>*/}
                    {/*    </SupportSection>*/}
                    {/*}*/}
                    {this.state.allPartners.length === 0 && <Message text={"No partners within this company yet!"} />}
                    {this.state.allPartners.map((c, index) => (
                        <ActionBox key={index}>
                            <Top>
                                <Photo src={c.photo} alt={""} />
                                <TitleSection>
                                    <FontHeader21>{c.first+" "+c.last}</FontHeader21>
                                    <Category>
                                        {c.expertCategories.map((e, index) => (
                                            <Inline key={e.categoryId}>
                                                <Inline><FontBody16>{e.categoryName}</FontBody16></Inline>
                                                {(index !== (c.expertCategories.length - 1)) && <Inline><FontBody16>,&nbsp;</FontBody16></Inline>}
                                            </Inline>
                                        ))}
                                    </Category>
                                    <div>
                                        <Left>
                                            {!this.state.isRm &&
                                                <IntroButton>
                                                    <div onClick={() => {return(this.viewClients(c))}}>
                                                        <ButtonPrimary label={"Assigned Clients"} canSubmit={false} />
                                                    </div>
                                                </IntroButton>
                                            }
                                            <ActionSection>
                                                <ActionItem disabled={false} onClick={() => {return this.createVideoMeeting(c)}} extraWidth={true}>
                                                    <ActionIcon disabled={false}><CalendarFilled /></ActionIcon>
                                                    <FontBody12>Meeting</FontBody12>
                                                </ActionItem>
                                                <ActionItem disabled={false} onClick={() => {return this.createEmail(c)}} extraWidth={false}>
                                                    <ActionIcon disabled={false}><MailFilled /></ActionIcon>
                                                    <FontBody12>Email</FontBody12>
                                                </ActionItem>
                                                <ActionItem disabled={false} onClick={() => {return this.createSMS(c)}} extraWidth={false}>
                                                    <ActionIcon disabled={false}><MobileFilled /></ActionIcon>
                                                    <FontBody12>SMS</FontBody12>
                                                </ActionItem>
                                                {/*{!this.props.user.get("isSupport") &&*/}
                                                {/*    <ActionItem disabled={false} onClick={() => {return this.selectPartner(c)}} extraWidth={false}>*/}
                                                {/*        <ActionIcon disabled={false}><More><DashOutlined /></More></ActionIcon>*/}
                                                {/*        <FontBody12>More</FontBody12>*/}
                                                {/*    </ActionItem>*/}
                                                {/*}*/}
                                            </ActionSection>
                                        </Left>
                                    </div>
                                </TitleSection>
                            </Top>
                        </ActionBox>
                    ))}
                    {this.state.showScheduler &&
                        <>
                            <CommonBackground/>
                            <CommonModal>
                                <SchedulerFlow
                                    userId={this.state.userId}
                                    userFirstName={this.state.firstName}
                                    userLastName={this.state.lastName}
                                    userPhotoUrl={this.state.photo}
                                    eventCustomMinutes={30}
                                    availabilityCloseBtn={true}
                                    availabilityOnClose={() => {return this.changeShowScheduler(false)}}
                                    confirmOnClose={() => {return this.changeShowScheduler(false)}}
                                />
                            </CommonModal>
                        </>
                    }
                    </>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    allPartners: state.enterprise.partners.partners.get("partnersAssignedToCoach"),
    allPartnersFetching: state.enterprise.partners.partners.get("partnersAssignedToCoachFetching"),
    allWmcs: state.enterprise.partners.partners.get("wmcsAssignedToCoach"),
    allWmcsFetching: state.enterprise.partners.partners.get("wmcsAssignedToCoachFetching"),
    wmc: state.enterprise.partners.partners.get("wmcSelected"),
    wmcFetching: state.enterprise.partners.partners.get("wmcsAssignedToCoachFetching"),
    user: state.common.user,
    allCoaches: state.enterprise.partners.partners.get("coachesAssignedToWmc"),
    allCoachesFetching: state.enterprise.partners.partners.get("coachesAssignedToWmcFetching"),
});

const mapDispatchToProps = dispatch => ({
    setSessionsModal: (status, session, action, who, details, guests) => dispatch(setSessionsModal(status, session, action, who, details, guests)),
    setMessagesEmailOpen: (open, recipientId, recipientType, recipientFirstName, recipientLastName, isReply, subject, content) => dispatch(setMessagesEmailOpen(open, recipientId, recipientType, recipientFirstName, recipientLastName, isReply, subject, content)),
    setMessagesSMSOpen: (open, recipientId, recipientType, recipientFirstName, recipientLastName, subject, content) => dispatch(setMessagesSMSOpen(open, recipientId, recipientType, recipientFirstName, recipientLastName, subject, content)),
    setSelectPartner: (onePartner) => dispatch(setSelectPartner(onePartner)),
    setSelectWmc: (oneWmc) => dispatch(setSelectWmc(oneWmc)),
    setSelectWmcAdvisor: (oneWmcAdvisor) => dispatch(setSelectWmcAdvisor(oneWmcAdvisor)),
    tryGetPartnersForCoach: (wmcId) => dispatch(tryGetPartnersForCoach(wmcId)),
    tryGetSupportForWmc: (wmcId) => dispatch(tryGetSupportForWmc(wmcId)),
    tryGetCoachesForWmc: (wmcId) => dispatch(tryGetCoachesForWmc(wmcId)),
    tryGetClientsForCoach: (wmcId, coachId) => dispatch(tryGetClientsForCoach(wmcId, coachId)),
    tryGetClientsForAdvisor: (advisorId) => dispatch(tryGetClientsForAdvisor(advisorId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TeamList));
