import {axiosReq} from "../../../common/utils/axios";

/* Store */
import { setHelpContactSendPending, setHelpContactSendComplete } from "../store/help";

export const tryPostHelp = (message) => {
    return async dispatch => {
        dispatch(setHelpContactSendPending(true));
        await axiosReq('apiv1/support', "POST", {message: message}, null);
        return dispatch(setHelpContactSendComplete(false));
    }
};
