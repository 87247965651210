import React from 'react';
import {connect} from 'react-redux';

/* Components */
import {Header, Body} from "../components/cards";

/* Containers */
import Title from "./commontitle";
import TimeLeft from "./commontimeleft";
import CheckoutCardsEditStripe from "./cardseditsform";

/* Middleware */
import {tryDeletePaymentMethod, tryUpdatePaymentMethod} from '../middleware/cards';

class CheckoutCardsEdit extends React.Component {
    state={"isLoading": true, "cardEditing": {}, "paymentMethods": []};

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.paymentMethods !== this.props.paymentMethods) {
            this.setState({"paymentMethods": this.props.paymentMethods})
        }
        if(prevProps.cardEditing !== this.props.cardEditing) {
            this.setState({"cardEditing": this.props.cardEditing})
        }
    }

    init = async () => {
        this.setState({
            "paymentMethods": this.props.paymentMethods,
            "cardEditing": this.props.cardEditing,
            "isLoading": false
        });
    };

    onEditCard = async (request) => {
        await this.props.tryUpdatePaymentMethod(request);
        const paymentMethod = this.state.paymentMethods.filter(p => {return(p.get("id") === request.paymentMethodId.toString())}).get(0);
        this.props.onCardSelect(paymentMethod.toJS());
    };

    onDeleteCard = async () => {
        await this.props.tryDeletePaymentMethod(this.state.cardEditing.id);
        this.props.onBack();
    };

    render() {
        if(this.state.isLoading) {
            return null
        } else {
            return(
                <>
                    <Header>
                        <Title
                            title={"Edit Card"}
                            onBack={this.props.onBack}
                        />
                        <TimeLeft
                            secondsAvailable={this.props.secondsAvailable}
                        />
                    </Header>
                    <Body>
                        <CheckoutCardsEditStripe
                            onEditCard={this.onEditCard}
                            cardEditing={this.state.cardEditing}
                            onDeleteCard={this.onDeleteCard}
                            showDeleteButton={true}
                            onClose={() => {}}
                        />
                    </Body>
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    paymentMethods: state.iframe.cards.cards.get("paymentMethods")
});

const mapDispatchToProps = dispatch => ({
    tryDeletePaymentMethod: (id) => dispatch(tryDeletePaymentMethod(id)),
    tryUpdatePaymentMethod: (request) => dispatch(tryUpdatePaymentMethod(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutCardsEdit);
