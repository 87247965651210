import React from 'react';
import 'moment-timezone/builds/moment-timezone-with-data-2012-2022';
import moment from 'moment-timezone';
import ReactQuill from "react-quill";

/* Components */
import {Body, Left, Right, DateTime, Block, Icon, MiniTitle, Padding, Relative, FillContainer, HeroVidBackground} from '../components/body';
import {FontBody16, FontHeader16} from '../../../common/components/fonts';
import {BookOpen, Calendar, MapPin, Video} from 'react-feather';

class IframeMeetingPageBody extends React.Component {
    render() {
        let d = this.props.data;
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return(
            <Body>
                <Left>
                    <Block>
                        {(d.get("announcementVideoUrl") !== "" && d.get("announcementVideoUrl") !== null) &&
                            <>
                                <MiniTitle><FontBody16><Icon><Video /></Icon>&nbsp;Video</FontBody16></MiniTitle>
                                <Padding>
                                    <Relative>
                                        <FillContainer>
                                            <HeroVidBackground src={d.get("announcementVideoUrl")} />
                                        </FillContainer>
                                    </Relative>
                                </Padding>
                            </>
                        }
                        <br />
                        <br />
                        <MiniTitle><FontBody16><Icon><BookOpen /></Icon>&nbsp;Description</FontBody16></MiniTitle>
                        <Padding>
                            <ReactQuill
                                readOnly={true}
                                theme={null}
                                value={d.get("description")}
                                bounds={'.app'}
                            />
                        </Padding>
                    </Block>
                </Left>
                <Right>
                    <Block>
                        <FontBody16><Icon><Calendar /></Icon>&nbsp;Date & time</FontBody16>
                        <Padding>
                            <DateTime>
                                <FontHeader16>{moment(d.get("start")).format("ddd")}, {moment(d.get("start")).format("LL")}</FontHeader16>
                                <FontHeader16>{moment(d.get("start")).format("LT")} - {moment(d.get("end")).format("LT")} {moment(d.get("end")).tz(timeZone).format("z")}</FontHeader16>
                            </DateTime>
                        </Padding>
                        <br />
                        <FontBody16><Icon><MapPin /></Icon>&nbsp;Location</FontBody16>
                        <Padding>
                            <DateTime>
                                <FontHeader16>Online</FontHeader16>
                            </DateTime>
                        </Padding>
                    </Block>
                </Right>
            </Body>
        )
    }
}

export default IframeMeetingPageBody;