import React from 'react';
import { connect } from "react-redux";
import { arrayToTree } from 'performant-array-to-tree'
import styled from 'styled-components';

/* Middleware */
import { tryGetFolderList, tryGetSharedDriveFolderList } from '../middleware/filevault';

/* Store */
import { setBreadcrumbUpdate } from '../store/filevault';

/* Components */
import { LogoLoading } from '../../../components/loading';
import { colors } from '../../../components/colors';
import { FontHeader21, FontBody14 } from '../../../components/fonts';
import { RightOutlined } from '@ant-design/icons';

const Breadcrumbs = styled.div`
    margin-bottom: 10px;
`;
const Layer = styled.div`
    display: inline-block;
    vertical-align: top;
`;
const Border = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-top: 4px;
    color: ${colors.secondary70};
`;
const Folder = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 0px 10px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    ${props => props.active === true} {
        color: ${colors.secondary70};
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
    ${props => props.active === false} {
        color: inherit;
    }
`;

const Level = ({id, name, openFolder, index, currentFolderId}) => (
    <Layer key={id}>
        {index > 1 && <Border><FontBody14><RightOutlined /></FontBody14></Border>}
        <Folder
            active={currentFolderId === id || (currentFolderId === null && index === 1)}
            onClick={(currentFolderId === id || (currentFolderId === null && index === 1)) ? () => {} : () => {return openFolder(id)}}
        >
            <FontHeader21>{name}</FontHeader21>
        </Folder>
    </Layer>
);

const initState = {"isLoading": true, "breadcrumb": []};

class VaultBreadcrumb extends React.Component {
    state = initState;

    componentDidMount() {
        return this.init(this.props.folderBreadcrumb.toJS());
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.folderBreadcrumb !== this.props.folderBreadcrumb) {
            return this.init(this.props.folderBreadcrumb.toJS());
        }
    }

    componentWillUnmount() {
        this.props.setBreadcrumbUpdate([]);
        return this.setState(initState);
    }

    init = async (breadcrumbs) => {
        const tree = await arrayToTree(breadcrumbs, { dataField: null });
        this.update(tree);
    };

    update = async (tree) => {
        const hierarchy = await this.hierarchy(tree, [], 0, this.hierarchy);
        await this.setState({"breadcrumb": hierarchy, "isLoading": false});
    };

    hierarchy = async (tree, breadcrumb, index, callback) => {
        let newIndex = index + 1;
        let currentFolder = <Level key={tree[0].id} id={tree[0].id} name={tree[0].name} openFolder={this.openFolder} index={newIndex} currentFolderId={this.props.folderList.get("folderId")} />;
        let newBreadcrumb = [...breadcrumb, currentFolder];
        if(tree[0].children.length > 0) {
            return(callback(tree[0].children, newBreadcrumb, newIndex, callback));
        } else {
            return(<>{newBreadcrumb}</>);
        }
    };

    hierarchyFromOpenFolder = async (tree) => {
        return Promise.all(tree.map((m, index) => {
            return Promise.resolve(<Level key={m.id} id={m.id} name={m.name} openFolder={this.openFolder} index={index+1} currentFolderId={this.props.folderList.get("folderId")} />)
        }));
    };

    openFolder = async (id) => {
        let breadcrumbs = this.props.folderBreadcrumb;
        let selectedFolderId = id;
        let done = false;
        do {
            selectedFolderId = breadcrumbs.filter(f => {return(f.get("parentId") === selectedFolderId)});
            if(selectedFolderId.size > 0) {
                breadcrumbs = breadcrumbs.filter(f => {return(f.get("id") !== selectedFolderId.get(0).get("id"))});
                selectedFolderId = selectedFolderId.get(0).get("id")
            } else {
                breadcrumbs = breadcrumbs.filter(f => {return(f.get("id") !== "root")});
                done = true;
            }
        } while (!done);
        if(breadcrumbs.size === 0) {
            breadcrumbs = [{"id": "root", "parentId": null, "name": "Drive"}];
        } else {
            breadcrumbs = [{"id": "root", "parentId": null, "name": "Drive"}, ...breadcrumbs.toJS()];
        }
        if (this.props.type === "shared") {
            await this.props.tryGetSharedDriveFolderList(this.props.drive.get("driveId"), id === "root" ? null : id, false);
        } else {
            await this.props.tryGetFolderList(id === "root" ? null : id, id !== "root", false);
        }
        await this.props.setBreadcrumbUpdate(breadcrumbs);
        const hierarchy = await this.hierarchyFromOpenFolder(breadcrumbs);
        await this.setState({"breadcrumb": hierarchy, "isLoading": false});
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <Breadcrumbs>{this.state.breadcrumb}</Breadcrumbs>
            )
        }
    }
}

const mapStateToProps = state => ({
    folderBreadcrumb: state.common.fileVault.get("folderBreadcrumb"),
    folderListFetching: state.common.fileVault.get("folderListFetching"),
    folderList: state.common.fileVault.get("folderList"),
    drive: state.common.fileVault.get("sharedDrive"),
});

const mapDispatchToProps = dispatch => ({
    tryGetFolderList: (folderId, traverseFolder, storeBreadcrumb) => dispatch(tryGetFolderList(folderId, traverseFolder, storeBreadcrumb)),
    setBreadcrumbUpdate: (breadcrumbs) => dispatch(setBreadcrumbUpdate(breadcrumbs)),
    tryGetSharedDriveFolderList: (driveId, folderId, storeBreadcrumb) => dispatch(tryGetSharedDriveFolderList(driveId, folderId, storeBreadcrumb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VaultBreadcrumb);

/*
List[2]
0:
Map[3]
{"id" => "root"}
{"name" => "Drive"}
{"parentId" => null}
1:
Map[3]
{"id" => 90}
{"name" => 60}
{"parentId" => "root"}
 */
// const tree = arrayToTree([
//     { id: '4', parentId: null, "name": "dsf"},
//     { id: '31', parentId: '4', "name": "hfgh"},
//     { id: '1941', parentId: '418', "name": "ljkk"},
//     { id: '1', parentId: '418', "name": "zyds"},
//     { id: '418', parentId: null, "name": "srew"},
// ], { dataField: null });
//
// const res = [
//     {
//         id: '4',
//         parentId: null,
//         name: 'abc',
//         children: [
//             {id: '31', parentId: '4', name: '12', children: []},
//         ]
//     },
//     {
//         id: '418',
//         parentId: null,
//         name: 'ü',
//         children: [
//             {id: '1941', parentId: '418', name: 'de', children: []},
//             {id: '1', parentId: '418', name: 'ZZZz', children: []},
//         ]
//     },
// ]


