import styled from "styled-components";
import {colors} from "../../../common/components/colors";

export const LoadingCenter = styled.div`
    margin: 60px auto;
    overflow: hidden;
    & img {
        padding: 20px;
    }
`;
export const Left = styled.div`
    vertical-align: top;
    ${props => props.inModal !== true} {
        width: 100%;
        display: block;
        position: relative;
        background-color: ${colors.white};
        overflow-y: auto;
        z-index: auto;
    }
    ${props => props.inModal !== false} {
        width: 50%;
        display: inline-block;
        position: fixed;
        background-color: ${colors.border30};
        z-index: 1;
        overflow-y: auto;
    }
    height: 100%;
    @media screen and (max-width: 1220px) {    
        width: 100%;
        position: relative;
        background-color: ${colors.white};
    }
`;
export const Right = styled.div`
    vertical-align: top;
    ${props => props.inModal !== true} {
        width: 100%;
        margin-left: 0px;
        display: block;
        position: relative;
        box-shadow: none;
        overflow-y: auto;
        z-index: auto;
    }
    ${props => props.inModal !== false} {
        width: 50%;
        margin-left: 50%;
        display: inline-block;
        position: fixed;
        box-shadow: -10px 0px 10px 1px ${colors.border70};
        overflow-y: auto;
        z-index: 2;
    }
    height: 100%;
    @media screen and (max-width: 1220px) {    
        width: 100%;
        position: relative;
        margin-left: 0px;
        box-shadow: none;
    }
`;
export const Subtitle = styled.div`
    margin: 0px auto;
    width: 80%;
`;