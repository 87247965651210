import styled from "styled-components";
import {colors} from "../../../common/components/colors";

export const Container = styled.div`
    max-width: 1100px;
    ${props => props.bigTop === true} {
        padding: 0px 60px;
        width: calc(100% - 120px);
    }
    ${props => props.bigTop !== true} {
        padding: 0px 20px;
        width: calc(100% - 40px);
    }
    margin: 60px auto 0px auto;
    color: ${colors.primary100};
    @media screen and (max-width: 1440px) {
        max-width: 1008px;
        ${props => props.bigTop === true} {
            padding: 0px 40px;
            width: calc(100% - 80px);
        }
        ${props => props.bigTop !== true} {
            padding: 0px 20px;
            width: calc(100% - 40px);
        }
    }
    @media screen and (max-width: 768px) {
        ${props => props.bigTop === true} {
            padding: 0px 20px;
            width: calc(100% - 40px);
        }
        ${props => props.bigTop !== true} {
            padding: 0px 20px;
            width: calc(100% - 40px);
        }
    }
    @media screen and (max-width: 460px) {
        margin: 20px auto 0px auto;
        ${props => props.bigTop === true} {
            padding: 0px 10px;
            width: calc(100% - 20px);
        }
        ${props => props.bigTop !== true} {
            padding: 0px 20px;
            width: calc(100% - 40px);
        }
    }
`;

export const Title = styled.div`
    width: fit-content;
    @media screen and (max-width: 460px) {
        width: 100%;
    }
`;
export const Type = styled.div`
    margin-bottom: 15px;
`;
export const Body = styled.div`
    padding: 0px 20px;
    width: calc(100% - 40px);
    @media screen and (max-width: 640px) {
        width: calc(100% - 20px);
        padding: 0px 10px;
    }
    & p {
        cursor: pointer;
    }
`;
export const Image = styled.img`
    width: 150px;
    border-radius: 4px;
    height: 150px;
    object-fit: cover;
    object-position: 0 0;
`;
export const Header = styled.div`
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 3px solid ${colors.border70};
`;
export const Name = styled.div`
    padding-left: 10px;
    @media screen and (max-width: 460px) {
        padding-left: 0px;
    }
`;
export const Right = styled.div`
    display: inline-block;
    vertical-align: bottom;
    width: calc(100% - 170px);
    @media screen and (max-width: 460px) {
        width: 100%;
        display: block;
        text-align: center;
    }
`;
export const Left = styled.div`
    display: inline-block;
    vertical-align: bottom;
    width: 170px;
    @media screen and (max-width: 460px) {
        width: 100%;
        display: block;
        text-align: center;
    }
`;
export const SubName = styled.div`
    color: ${colors.secondary100};
`;
