import { Map } from 'immutable';

/* Immutable */

const MESSAGES_SEND_PENDING = 'MESSAGES_SEND_PENDING';
const MESSAGES_SEND_COMPLETE = 'MESSAGES_SEND_COMPLETE';
const MESSAGES_STORE_RESET = 'MESSAGES_STORE_RESET';

/* Actions */

export const setMessagesSendPending = () => ({'type': MESSAGES_SEND_PENDING});
export const setMessagesSendComplete = () => ({'type': MESSAGES_SEND_COMPLETE});
export const setMessagesReset = () => ({'type': MESSAGES_STORE_RESET});

/* Initial State */

const initialState = Map({
    messagesSending: false,
});

/* Reducer */

const messagesReducer = (state=initialState, action) => {
    switch (action.type) {
        case MESSAGES_SEND_PENDING:
            return state.merge({'messagesSending': true});
        case MESSAGES_SEND_COMPLETE:
            return state.merge({'messagesSending': false});
        case MESSAGES_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default messagesReducer;
