import React from 'react';
import {connect} from 'react-redux';
import styled from "styled-components";
import {withRouter} from 'react-router-dom';

/* Components */
import {LogoLoading} from "../../../common/components/loading";

/* Containers */
import ResetPassword from "./resetpassword";
import EmailInput from './email';
import EmailSent from './emailsent';
import ResetCode from './resetcode';
import Confirm from './confirm';

/* Middleware */
import {tryScheduleLogin, trySendSignUpSuccessEmail} from '../middleware/passwordreset';
import {tryAuthOnly, tryAuth} from '../../../routes/middleware/auth';

/* Store */
import {setShowDemo} from '../../../routes/store/user'

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

class ResetFlow extends React.Component {
    static defaultProps = {
        back: null,
        showBack: false,
        resetSystem: "form", // form or page
        employerLogin: false,
    };

    state={
        "step": 1,
        "isLoading": true,
        "email": "",
        "token": "",
        "back": () => {},
        "password": ""
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "back": this.props.back === null ? () => this.props.history.push("/login") : this.props.back,
            'isLoading': false
        })
    };

    setPassword = async (password) => {
        await this.setState({"password": password});
        this.setState({'step': 5});
    };

    onSuccess = async () => {
        await this.props.tryScheduleLogin(this.state.email, this.state.password);
        // await this.props.tryAuthOnly(null);
        if(this.props.employerLogin) {
            await this.props.setShowDemo(true);
        }
        await this.props.tryAuth(this.props.history);
        //await this.props.trySendSignUpSuccessEmail(this.state.email);
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if(this.state.step === 1) {
                return (
                    <EmailInput 
                        getEmail={(email) => {return this.setState({'email': email})}}
                        next={() => {return this.setState({'step': 3})}}
                        back={this.state.back}
                        login={this.state.back}
                        showBack={this.props.showBack}
                        styleSystem={this.props.resetSystem}
                        employerLogin={this.props.employerLogin}
                    />
                )
            } else if(this.state.step === 2) {
                return(
                    <EmailSent 
                        next={() => {return this.setState({'step': 3})}}
                        back={() => {return this.setState({'step': 1})}}
                        login={this.state.back}
                        email={this.state.email}
                    />
                )
            } else if (this.state.step === 3) {
                return (
                    <ResetCode
                        next={() => {return this.setState({'step': 4})}}
                        back={() => {return this.setState({'step': 1})}}
                        login={this.state.back}
                        getToken={(token) => {return this.setState({'token': token})}}
                        email={this.state.email}
                        styleSystem={this.props.resetSystem}
                        employerLogin={this.props.employerLogin}
                    />
                )
            } else if(this.state.step === 4) {
                return(
                    <ResetPassword
                        passwordChangeSuccess={this.setPassword}
                        back={() => {return this.setState({'step': 3})}}
                        login={this.state.back}
                        token={this.state.token}
                        styleSystem={this.props.resetSystem}
                    />
                )
            } else if (this.state.step === 5) {
                return(
                    <Confirm
                        onSuccess={this.onSuccess}
                        styleSystem={this.props.resetSystem}
                        employerLogin={this.props.employerLogin}
                    />
                )
            } else {
                return null
            }
            
        }
    }

}

const mapStateToProps = state => ({
    user: state.common.user
});

const mapDispatchToProps = dispatch => ({
    tryScheduleLogin: (email, password) => dispatch(tryScheduleLogin(email, password)),
    tryAuthOnly: (email, trackNewRegistration) => dispatch(tryAuthOnly(email, trackNewRegistration)),
    tryAuth: (history) => dispatch(tryAuth(history)),
    setShowDemo: (status) => dispatch(setShowDemo(status)),
    trySendSignUpSuccessEmail: (email) => dispatch(trySendSignUpSuccessEmail(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetFlow));