import { combineReducers } from 'redux';

/* Reducers */
import partnersReducer from './store/coaching';

const partnershipReducer = combineReducers({
    partners: partnersReducer,
});

export default partnershipReducer;

