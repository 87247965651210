import React from 'react';
import { withRouter, Link } from 'react-router-dom';

/* Components */
import {CoachingLoading, Logo, ActionBox, FitWidthButton} from '../components/teamsupport';
import {FontTitle18} from "../../../common/components/fonts";
import {ButtonSecondary} from "../../../common/components/buttons";

/* Static */
import {images} from "../../../common/components/images";

class TeamSupport extends React.Component {
    state = {"isLoading": true};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({"isLoading": false})
    };

    render() {
        if(this.state.isLoading) {
            return(<CoachingLoading />)
        } else {
            return(
                <>
                    <ActionBox>
                        <FontTitle18>Need Help?</FontTitle18>
                        <Logo><img src={images.logoIcon} alt={"Willow"} /></Logo>
                        <br />
                        <FitWidthButton><Link to={"/support/faq"}><ButtonSecondary label={"Read FAQs"} canSubmit={false} /></Link></FitWidthButton>
                        <br />
                        <FitWidthButton><Link to={"/support/contact"}><ButtonSecondary label={"Contact Support"} canSubmit={false} /></Link></FitWidthButton>
                    </ActionBox>
                </>
            )
        }
    }
}

export default withRouter(TeamSupport);
