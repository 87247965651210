import React from "react";
import {connect} from "react-redux";
import styled from 'styled-components';
import {Map} from "immutable";
import moment from 'moment/moment';
import ReactQuill from "react-quill";
import {v4 as uuid4} from "uuid";

/* Components */
import {FontTitle30, FontBody14, FontHeader18} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";
import {ButtonPrimary} from "../../../common/components/buttons";

import {setAnnouncementOneModalOpen} from '../store/announcements';
import {QuillSlim} from "../../../common/components/richtexteditor";

import {disableBodyScrolling, enableBodyScrolling} from '../../../common/utils/scrolling';

const Modal = styled.div`
    margin: 0px auto;
    max-width: 500px;
    padding: 30px;
    border-radius: 6px;
    background-color: ${colors.white};
    position: fixed;
    top: 100px;
    left: 0px;
    right: 0px;
    height: 100%;
    ${props => props.big !== true} {max-height: 450px;}
    ${props => props.big !== false} {
        max-height: 450px;
        height: fit-content;
        overflow-y: auto;
    }
    @media screen and (max-width: 460px) {
        top: 20px;
        width: calc(100% - 20px);
        padding: 0px 10px;
        overflow-y: auto;
        max-height: calc(100% + 30px);
        height: 100%;
    }
    z-index: 6;
`;
const Background = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.50);
    @media screen and (max-width: 460px) {
        background-color: ${colors.white};
    }
    z-index: 5;
`;

const FitContentFullWidth = styled.div`
    width: 100%;
    & > div {
        width: calc(100% - 47px);
        text-align: center;
        margin: 0px auto;
    }
`;
const Indent = styled.div`
    width: calc(100% - 40px);
    padding: 0px 20px;
`;
const Header = styled.div`
    border-bottom: 1px solid ${colors.secondary30};
    padding-bottom: 10px;
    margin-top: -10px;
    margin-bottom: 30px;
`;
const Inline = styled.div`
    width: calc(100% - 25px);
    display: inline-block;
    vertical-align: top;
`;

class AnnouncementOneModal extends React.Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.announcementSelected !== this.props.announcementSelected && this.props.announcementSelected !== Map()) {
            disableBodyScrolling();
        }
    }

    componentWillUnmount() {
        enableBodyScrolling();
    }

    close = async () => {
        await this.props.setAnnouncementOneModalOpen(false, Map())
        enableBodyScrolling();
    }

    render() {
        if(!this.props.announcementModalOpen) {
            return null
        } else {
            if(this.props.announcementSelected !== Map()) {
                return(
                    <>
                        <Modal big={false}>
                            <Header>
                                <Inline><FontTitle30>{"Announcement"}</FontTitle30></Inline>
                            </Header>
                            <Indent>
                                <>
                                    <FontHeader18>{this.props.announcementSelected.get("wmc").get("companyName") + " - " + this.props.announcementSelected.get("title")}</FontHeader18>
                                    <br />
                                    <QuillSlim bodyBorderColor={colors.white}>
                                        <ReactQuill
                                            id={uuid4()}
                                            readOnly={true}
                                            theme={null}
                                            value={this.props.announcementSelected.get("body")}
                                            bounds={'.app'}
                                        />
                                    </QuillSlim>
                                    <br />
                                    <FontBody14>{"Author: " + this.props.announcementSelected.get("sender").get("fullName")}</FontBody14>
                                    <FontBody14>{"Date: " + moment(this.props.announcementSelected.get("created")).format('LL')}</FontBody14>
                                </>
                                <br />
                                <br />
                                <FitContentFullWidth onClick={() => {return this.close()}}>
                                    <ButtonPrimary canSubmit={false} label={"Close"} />
                                </FitContentFullWidth>
                                <br />
                            </Indent>
                        </Modal>
                        <Background />
                    </>
                )
            } else {
                return null
            }
        }
    }
}

const mapStateToProps = state => ({
    announcementModalOpen: state.enterprise.homepage.announcements.get("announcementOneModalOpen"),
    announcementSelected: state.enterprise.homepage.announcements.get("announcementSelected")
});

const mapDispatchToProps = dispatch => ({
    setAnnouncementOneModalOpen: (status, announcement) => dispatch(setAnnouncementOneModalOpen(status, announcement)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementOneModal);
