import React from 'react';
import styled from 'styled-components';
import {images} from "../../../../common/components/images";
import {colors} from "../../../../common/components/colors";
import {FontHeader26, FontBody16} from "../../../../common/components/fonts";

const PopUpContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
position: fixed;
top: 0;
bottom: 0;
left: 0;
right: 0;
z-index: 1;
`
const Background = styled.div`
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
z-index: 2;
`
const Form = styled.form`
background-color: white;
padding: 20px;
max-width: 600px;
border-radius: 30px;
border: none;
position: relative;
z-index: 3;
display: flex;
flex-direction: column;
align-self: center;
gap: 20px;
@media screen and (max-width: 650px){
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
overflow-y: auto;
border-radius: 0px;
}
`

const Button = styled.button`
background-color: ${colors.action100};
color: white;
border-radius: 20px;
border: none;
font-size: 14px;
font-weight: bold;
position: relative;
cursor: pointer;
filter: drop-shadow(0px 2px 5px grey);
display: flex;
align-self: end;
padding: 10px;
justify-content: center;
`
const Logo = styled.img`
  width: 100px;
`


export default class PopUp extends React.Component {

  static defaultProps = {
    handleOpen: () => {},
    isQuizCompleted: false
  }


  render() {
    return (
      <PopUpContainer>
          <Background onClick={this.props.handleOpen}/>
          <Form>
              <Logo src={images.logo} alt={"logoIcon"}/>
              <FontHeader26>Advisor Training Video</FontHeader26>
              {this.props.isQuizCompleted
                  ? <FontBody16>Please enjoy this practice management training. At the end of the video you will automatically be redirected back to the list of Willow's trainings.</FontBody16>
                  : <FontBody16>Please enjoy this practice management training. At the end of the video you will automatically be redirected to take the quiz for CE credits. Do not close the window before being redirected or you will not be able to take the quiz.</FontBody16>
              }
            <Button onClick={this.props.handleOpen}>Watch Video</Button>
          </Form>
      </PopUpContainer>
    );
  };
}
