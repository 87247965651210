import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setShopExpertProfile, setShopExpertProfileEdit, setShopExpertProfileGet, setShopExpertProfilePut} from "../store/expertprofile";

/* Static */
import {devShopExpertProfileData, devShopExpertProfileUpdateData, devShopGetCoachCategories} from "../static/devdata";

/* Utils */
import {testPhoto, removeOldImageUrl} from '../utils/images';

export const tryGetExpertProfile = () => {
    return async dispatch => {
        dispatch(setShopExpertProfileGet(true));
        const profile = await axiosReq('apiv1/coach/settings/profile', "GET", {}, devShopExpertProfileData);
        dispatch(setShopExpertProfile(profile));
        return dispatch(setShopExpertProfileGet(false));
    }
};

export const tryUpdateExpertProfile = (data, errorKey) => {
    return async (dispatch, getState) => {
        dispatch(setShopExpertProfilePut(true));
        let photo = await testPhoto(data.photoBlob, data.photoChanged);
        let request = photo === null ? data : {...data, profile: photo};
        const profile = await axiosReq('apiv1/coach/settings/profile', "PUT", request, devShopExpertProfileUpdateData(data));
        if(profile.hasOwnProperty("error")) {
            dispatch(setShopExpertProfileEdit({...getState().enterprise.profile.expertProfile.get("expertProfile").toJS(), [errorKey]: profile.error}));
        } else {
            await dispatch(tryGetExpertProfile());
            const dataNoPhoto = await removeOldImageUrl(data, ["photo"]);
            dispatch(setShopExpertProfileEdit({...dataNoPhoto, [errorKey]: "", "photo": getState().enterprise.profile.expertProfile.get("expertProfile").get("photo")}));
        }
        return dispatch(setShopExpertProfilePut(false));
    }
};

export const tryUpdateExpertCategories = (data) => {
    return async (dispatch, getState) => {
        dispatch(setShopExpertProfilePut(true));
        const categories = await axiosReq('apiv1/coach/settings/advising-categories', "PUT", {"expertCategoryIds": data}, {});
        await dispatch(tryGetExpertProfile());
        dispatch(setShopExpertProfileEdit({...getState().enterprise.profile.expertProfile.get("expertProfile").toJS(), "expertCategories": categories.expertCategories}));
        return dispatch(setShopExpertProfilePut(false));
    }
};

export const tryGetCoachCategories = () => {
    return async dispatch => {
        dispatch(setShopExpertProfilePut(true));
        const categories = await axiosReq('apiv1/coach/settings/get-all-advising-categories', "GET", {}, devShopGetCoachCategories);
        dispatch(setShopExpertProfilePut(false));
        return categories.expertCategories;
    }
}