import { combineReducers } from 'redux';

/* Common */
import profileReducer from './store/profile';
import webinarsReducer from "./store/webinars";

const webinarReducer = combineReducers({
    profile: profileReducer,
    webinars: webinarsReducer,
});

export default webinarReducer;
