import { combineReducers } from 'redux';

/* Reducers */
import coachingReducer from './store/coaching';

const teamReducer = combineReducers({
    coaching: coachingReducer,
});

export default teamReducer;
