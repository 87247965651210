import {v4 as uuid4} from "uuid";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

let userId;
export const devMessagesAddData = (isAdvisor, content, subject, mediaType) => {
    if(isAdvisor) {userId = 24324} else {userId = 32432}
    return ({
        "id": uuid4(),
        "fromUserId": userId,
        "isFromAdvisor": isAdvisor,
        "isFromLily": !isAdvisor,
        "content": content,
        "formattedDatetime": "2020-05-16T16:00:00+00:00",
        "channel": mediaType,
        "subject": subject
    })
};

export const devMessagesData = {
    "messageThreadId": "sdaflkjdsklfds",
    "userId": 24324,
    "userPlatform": "coach",
    'consumerName': "Sarah",
    'coachName': "Gabby",
    'remoteUserFirstName': "Sarah",
    'remoteUserLastName': "Something",
    'remoteUserReferralSource': "wmc",
    "messages": [
        {
            "id": 1,
            "fromUserId": 32432,
            "isFromAdvisor": false,
            "isFromLily": true,
            "content": "I'm going to be late for our 6:30pm",
            "formattedDatetime": "2020-05-16T16:00:00+00:00",
            "channel": "sms",
            "subject": null
        },
        {
            "id": 2,
            "fromUserId": 24324,
            "isFromAdvisor": true,
            "isFromLily": false,
            "content": "Ok no problem!",
            "formattedDatetime": "2020-05-16T16:00:00+00:00",
            "channel": "sms",
            "subject": null
        },
        {
            "id": 3,
            "fromUserId": 32432,
            "isFromAdvisor": false,
            "isFromLily": true,
            "content": "<p>Dear Sarah,</p><p><br></p><p>Thank you for speaking with me yesterday. With our call you’ve already taken an important step to prioritize your financial wellness!</p><p><br></p><p>I appreciate all that you shared about your journey and coaching goals. I’ve recapped our conversation below and offered a few suggested next steps.</p><p><br></p><p><strong>Your Journey</strong></p><ul><li>Recap specific life journeys shared by prospect here</li><li>Ex: you shared you’re looking for support as you navigate a divore</li><li>Etc.</li></ul><p><br></p><p><strong>Your Coaching Goals</strong></p><ul><li>Recap specific financial coaching shared by prospect here</li><li>Ex: Rebuild your credit score</li><li>Ex: Set a budget and spending plan you’ll stick to</li><li>Ex: Strengthen your financial confidence</li></ul><p><br></p><p><strong>Suggested Next Steps</strong></p><ul><li>Based on our conversation, you may find the [insert coaching package type here].&nbsp;</li><li>Insert details about that package here</li><li>Should you decide that you’d like to move forward with Willow Financial Coaching, do this xxx</li></ul><p><br></p><p>Should you have any questions, you can reach out to me (email) or to support@"+env.URL.DOMAIN+".</p><p><br></p><p>Thank you,&nbsp;</p><p><br></p><p>Gabby</p>",
            "formattedDatetime": "2020-05-16T16:00:00+00:00",
            "channel": "email",
            "subject": "Test subject"
        },
        {
            "id": 4,
            "fromUserId": 24324,
            "isFromAdvisor": true,
            "isFromLily": false,
            "content": "I think so too, let me think about the pricing",
            "formattedDatetime": "2020-05-16T16:00:00+00:00",
            "channel": "sms",
            "subject": "Test subject2"
        }
    ]
};