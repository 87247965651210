import React from 'react';
import {connect} from 'react-redux';
import styled from "styled-components";
import Cookies from 'js-cookie';

/* Containers */
import SignUp from './signup';
import Login from './login';
import Verify from './verify';
import ChangePassword from "../../changepassword/containers/changepassword";
import ResetFlow from '../../resetpassword/containers/flow';

/* Middleware */
import {trySignUpTestExists, tryRegisterUser, tryUpdateUser} from "../middleware/auth";
import {tryAuthOnly} from '../../../routes/middleware/auth';

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {timeZoneOptionsByRegion} from "../../../common/utils/timezone2";

const LoadingCenter = styled.div`
    margin: 60px auto;
`;
const Container = styled.div`
    max-width: 768px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 0px auto 0px auto;
`;

const trimPhone = (phone) => {
    return phone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
};

class SignupFlow extends React.Component {
    static defaultProps = {
        signupSystem: "form", // form or page
        signupShowLoginBtn: true,
        signupClose: () => {},
        signupPageOnSubmit: null,
        signupSubmitLabel: "Register",
        back: () => {},
        loginSystem: "form", // form or page
        loginForgotPasswordLink: undefined,
        hrCode: "",
        firstName: "",
        lastName: "",
        email: "",
        wmcEmployeeId: "",
        hidePrefilledInputs: true,
        signUpErrorLogin: null,
        showhrCode: true,
        showLastName: true,
        showPassword: true,
        showAttstationNoCheckbox: false,
        signupIsAdvertising: false,
        attestationDisclosure: "",
    };

    state={
        "step": 2,
        "firstName": "",
        "lastName": "",
        "email": "",
        "phone": "",
        "hrCode": "",
        "wmcEmployeeId": "",
        "timeZone": "",
        "availabilityBackBtn": true,
        "signUpHasAccount": false,
        "signUpError": null,
        "signUpSubmitLabel": "Register",
        "isLoading": true,
        "loginShowUserExists": false,
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        
    }

    init = async () => {
        if(this.props.signupSystem === "page") {
            await this.setState({
                "hrCode": this.props.hrCode,
                "firstName": this.props.firstName,
                "lastName": this.props.lastName,
                "email": this.props.email,
                "wmcEmployeeId": this.props.wmcEmployeeId,
                "signUpSubmitLabel": this.props.signupSubmitLabel,
                "showhrCode": this.props.showhrCode,
            });
            await this.setStep(1); // signup
            return this.setIsLoading(false)
        } else if(this.props.loginSystem === "page") {
            await this.setStep(2); // login
            return this.setIsLoading(false)
        } else {
            await this.preCheckHasAccount();
        }
    };

    preCheckHasAccount = async () => {
        const sessionId = Cookies.get("sessionid");
        if(sessionId !== null && sessionId !== undefined) {
            const user = await this.props.tryAuthOnly(null, false, true);
            if(user !== null) {
                await this.setState({
                    "firstName": user.first === "Willow" ? "" : user.first,
                    "lastName": user.last,
                    "email": user.email.toLowerCase(),
                    "phone": user.phone === null ? ""  : user.phone,
                    "signUpHasAccount": true
                });
                if(user.first === "Willow" || user.last === ""/* || user.phone === null*/) {
                    this.setStep(2); // login
                    return this.setIsLoading(false)
                } else {
                    console.log('cookie')
                    this.loginSuccess();
                }
            } else {
                this.setStep(1); // signup
                return this.setIsLoading(false)
            }
        } else {
            this.setStep(2); // login
            return this.setIsLoading(false)
        }
    };

    setIsLoading = async (status) => {
        await this.setState({"isLoading": status})
    };

    setStep = async (step) => {
        await this.setState({"step": step});
    };

    openLoginWithoutUserExists = async () => {
        await this.setState({"loginShowUserExists": false});
        this.setStep(2); // login
    };

    openLoginWithUserExists = async () => {
        await this.setState({"loginShowUserExists": true});
        this.setStep(2); // login
    };

    signUpFormOnSubmit = async (data) => {
        if(this.state.signUpHasAccount) {
            return this.signUpUpdateAccount(data);
        } else {
            return this.signUpCheckHasAccount(data);
        }
    };

    signUpUpdateAccount = async (data) => {
        await this.setIsLoading(true);
        await this.setState({...data});
        const region = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timeZone = timeZoneOptionsByRegion(region);
        // store data
        await this.props.tryUpdateUser(this.state.firstName, this.state.lastName, this.state.email.toLowerCase(), trimPhone(this.state.phone), timeZone);
        if(this.props.iframeAuthUpdateUserError === null) {
            await this.setState({"signUpError": null});
            const user = await this.props.tryAuthOnly(this.state.email.toLowerCase(), false, false);
            if(user !== null) {
                console.log('signup update')
                return this.loginSuccess()
            }
        } else {
            await this.setState({"signUpError": this.props.iframeAuthUpdateUserError});
            await this.setStep(1); // signup
            await this.setIsLoading(false); 
        }
    };

    signUpCheckHasAccount = async (data) => {
        console.log('signupcheck')
        await this.setIsLoading(true);
        await this.setState({...data});
        await this.props.trySignUpTestExists(this.state.email.toLowerCase(), trimPhone(this.state.phone));
        if (this.props.iframeAuthCheckHasAccountError !== null) {
            const user = await this.props.tryAuthOnly(this.state.email, false, true);
            if(user !== null) {
                console.log('su user !== null')
                return this.loginSuccess()
            } else {
                console.log('su login')
                await this.openLoginWithUserExists(); // login user exists
                await this.setIsLoading(false);
            }
        } else {
            console.log('su no error')
            await this.verifyCreateNewUser()
        }
    };

    verifyCreateNewUser = async () => {
        await this.setIsLoading(true);
        console.log('verify')
        const region = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timeZone = timeZoneOptionsByRegion(region);
        const register = await this.props.tryRegisterUser(this.state.firstName, this.state.lastName, this.state.email.toLowerCase(), this.state.phone, timeZone, this.state.password, this.state.hrCode, this.state.wmcEmployeeId);
        if(register.error === null) {
            await this.setState({"signUpError": null});
            const user = await this.props.tryAuthOnly(null, true, true);
            if(user !== null) {
                console.log('verify step 3')
                await this.setStep(3);
                await this.setIsLoading(false);
            } else {
                console.log('verify login')
                await this.openLoginWithUserExists(); // login user exists
                await this.setIsLoading(false);
            }
        } else {
            console.log('verify step 1')
            await this.setState({"signUpError": register.error});
            await this.setStep(1); // signup
            await this.setIsLoading(false);
        }
    };

    loginSuccess = async () => {
        console.log('login suc')
        this.props.signupClose()
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if(this.state.step === 1) {
                return (
                    <SignUp
                        firstName={this.state.firstName}
                        lastName={this.state.lastName}
                        email={this.state.email} //.toLowerCase() causing problems
                        phone={this.state.phone}
                        hrCode={this.state.hrCode} // pass hr here
                        wmcEmployeeId={this.state.wmcEmployeeId}
                        back={() => {return this.props.back()}}
                        signUpFormOnSubmit={this.signUpFormOnSubmit}
                        signUpPageOnSubmit={this.props.signupPageOnSubmit}
                        signUpHasAccount={this.state.signUpHasAccount}
                        signUpSubmitLabel={this.state.signUpSubmitLabel}
                        showLoginBtn={this.props.signupShowLoginBtn}
                        signUpError={this.state.signUpError}
                        signUpErrorLogin={this.props.signUpErrorLogin}
                        viewLogin={this.openLoginWithoutUserExists}
                        close={this.props.signupClose}
                        styleSystem={this.props.signupSystem}
                        hidePrefilledInputs={this.props.hidePrefilledInputs}
                        showhrCode={this.props.showhrCode}
                        showLastName={this.props.showLastName}
                        showPassword={this.props.showPassword}
                        showAttstationNoCheckbox={this.props.showAttstationNoCheckbox}
                        attestationDisclosure={this.props.attestationDisclosure}
                        signupIsAdvertising={this.props.signupIsAdvertising}
                    />
                )
            } else if(this.state.step === 2) {
                return(
                    <Login
                        email={this.state.email.toLowerCase()}
                        back={() => {return this.props.back()}}
                        loginSuccess={() => {return this.loginSuccess()}}
                        viewSignUp={() => {return this.setStep(1)}}
                        loginShowUserExists={this.state.loginShowUserExists}
                        styleSystem={this.props.loginSystem}
                        forgotPasswordLink={(this.props.loginForgotPasswordLink === undefined) ? () => {return this.setStep(5)} : this.props.loginForgotPasswordLink}
                    />
                )
            } else if (this.state.step === 3) {
                return (
                    <Container>
                        <ChangePassword
                            title={""}
                            subTitle={"Create a password for your account"}
                            isInsideCard={false}
                            onSuccess={() => {return this.setStep(4)}}
                            onSuccessMessage={""}
                            hasBack={true}
                            back={() => {return this.setStep(2)}}
                        />
                    </Container>
                )
            } else if(this.state.step === 4) {
                return(
                    <Verify
                        back={() => {return this.setStep(2)}}
                        verifyCreateNewUser={() => {return this.loginSuccess()}}
                    />
                )
            } else if (this.state.step === 5) {
                return (
                    <ResetFlow
                        back={() => {return this.setStep(2)}}
                        onSuccess={() => {return this.setStep(2)}}
                        showBack={true}
                    />
                )
            } else {
                return null
            }
            
        }
    }

}

const mapStateToProps = state => ({
    iframeAuthUpdateUserError: state.iframe.signup.auth.get("iframeAuthUpdateUserError"),
    iframeAuthCheckHasAccountError: state.iframe.signup.auth.get("iframeAuthCheckHasAccountError"),
    user: state.common.user,
});

const mapDispatchToProps = dispatch => ({
    trySignUpTestExists: (email, phone) => dispatch(trySignUpTestExists(email, phone)),
    tryAuthOnly: (email, trackNewRegistration, forceAuth) => dispatch(tryAuthOnly(email, trackNewRegistration, forceAuth)),
    tryRegisterUser: (firstName, lastName, email, phone, timeZone, password, hrCode) => dispatch(tryRegisterUser(firstName, lastName, email, phone, timeZone, password, hrCode)),
    tryUpdateUser: (firstName, lastName, email, phone, timeZone) => dispatch(tryUpdateUser(firstName, lastName, email, phone, timeZone)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupFlow);