import { Map } from 'immutable';

/* Immutable */

const SCHEDULE_SESSION_CREATING = 'SCHEDULE_SESSION_CREATING';
const SCHEDULE_SESSION_CREATING_ERROR = 'SCHEDULE_SESSION_CREATING_ERROR';
const SCHEDULE_STORE_RESET = 'SCHEDULE_STORE_RESET';

/* Actions */

export const setScheduleSessionCreating = (status) => ({"type": SCHEDULE_SESSION_CREATING, 'status': status});
export const setScheduleSessionCreatingError = (error) => ({"type": SCHEDULE_SESSION_CREATING_ERROR, 'error': error});
export const setScheduleStoreReset = () => ({"type": SCHEDULE_STORE_RESET});

/* Initial State */

const initialState = Map({
    scheduleSessionCreating: false,
    scheduleSessionCreatingError: null,
});

/* Reducer */

const scheduleReducer = (state=initialState, action) => {
    switch (action.type) {
        case SCHEDULE_SESSION_CREATING:
            return state.merge({'scheduleSessionCreating': action.status});
        case SCHEDULE_SESSION_CREATING_ERROR:
            return state.merge({'scheduleSessionCreatingError': action.error});
        case SCHEDULE_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default scheduleReducer;
