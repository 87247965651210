import React from "react";
import {v4 as uuid4} from "uuid";
import TextareaAutosize from 'react-textarea-autosize';
import styled from "styled-components";

/* Components */
import {colors} from "../../../common/components/colors";
import {FontBody12, FontHeader18} from "../../../common/components/fonts";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const PlaceholderColor = styled.div`
    & textarea::placeholder {
        color: ${colors.primary70};
    }
`;

const Primary = styled.div`
    color: ${colors.primary100};
`;

const InputSection = styled.div`
    textarea {
        background-color: ${colors.border70};
        border: 1px solid ${colors.primary30};
        color: ${colors.primary100};
        border-radius: 4px;
        width: calc(100% - 40px);
        padding: 10px 20px;
        font-family: ${env.STYLES.FONTS.body.fontFamily};
        font-size: 16px;
        line-height: 22px;
        &::placeholder {
            color: ${colors.primary70};
        }
        &:focus {
            outline: none;
        }
    }
`;

const Invalid = styled.div`
    ${props => props.valid !== true} {display: none;}
    ${props => props.valid !== false} {display: inline-block;}
    color: ${colors.alert100};
    margin-left: 10px;
`;

const InputLabel = styled.div`
    color: ${colors.secondary100};
    display: inline-block;
`;

const TextAreaAutosize = styled(TextareaAutosize)`
    resize: none;
`;

class ElementTextArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {"subtitle": "", "text": '', "placeholder": "", "isValid": true, "minRows": 3, "minLength": 0}
    }

    componentDidMount() {
        return this.init()
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.elementId !== this.props.elementId) {
            return this.init();
        }
    }

    init = async () => {
        let isValid = this.props.text === "" ? {} : {"isValid": true};
        await this.setState({
            "subtitle": this.props.subtitle,
            "text": this.props.text,
            "placeholder": this.props.placeholder,
            "minLength": this.props.minLength,
            "minRows": this.props.minRows,
            ...isValid
        });

    };

    onChange = async e => {
        let text = e.target.value;
        await this.setState({
            "text": text,
            "isValid": text !== ""
        });
        let isValid = this.props.isRequired ? this.state.isValid : true;
        return this.props.onSaveElement(this.props.elementId, {"text": text}, isValid)
    };

    render() {
        return(
            <InputSection>
                {this.state.subtitle !== null && <InputLabel><FontHeader18><Primary style={this.props.style.subtitle === undefined ? {} : this.props.style.subtitle}>{this.state.subtitle}</Primary></FontHeader18></InputLabel>}
                {this.props.isRequired && <div><Invalid valid={this.state.isValid}><FontBody12 style={this.props.style.warning === undefined ? {} : this.props.style.warning}>{this.props.isRequired ? "Required" : ""}</FontBody12></Invalid></div>}
                <PlaceholderColor>
                    <TextAreaAutosize style={this.props.style.textArea === undefined ? {} : this.props.style.textArea} id={uuid4()} onChange={this.onChange} placeholder={this.state.placeholder} value={this.state.text} minLength={this.state.minLength.toString()} minRows={this.state.minRows} disabled={!this.props.canWrite}/>
                </PlaceholderColor>
            </InputSection>
        )
    }
}

export default ElementTextArea;