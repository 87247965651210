import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setSignupExpertCreating, setSignupExpertError, setSignupExpertCategoriesDownloading} from "../store/signupexpert";

/* Static */
import {devSignupExpertCategories, devSignupExpertCreate} from "../static/devdata";

export const tryCreateUserExpert = (data) => {
    return async dispatch => {
        dispatch(setSignupExpertCreating(true));
        const createUser = await axiosReq('apiv1/willow/add-expert', "POST", {firstName: data.first, lastName: data.last, phone: data.phone, email: data.email, timeZone: data.timeZone, expertCategoryId: data.expertCategoryId}, devSignupExpertCreate(data));
        if(createUser.hasOwnProperty("error")) {
            dispatch(setSignupExpertError(createUser.error));
        } else {
            dispatch(setSignupExpertError(""));
        }
        return dispatch(setSignupExpertCreating(false));
    }
};

export const tryGetCoachCategories = () => {
    return async dispatch => {
        dispatch(setSignupExpertCategoriesDownloading(true));
        const categories = await axiosReq('apiv1/coach/settings/get-all-advising-categories', "GET", {}, devSignupExpertCategories);
        dispatch(setSignupExpertCategoriesDownloading(false));
        return categories.expertCategories;
    }
};