import React from 'react';
import styled from 'styled-components';

/* Components */
import {colors} from '../../../common/components/colors';

/* Containers */
import IframeProfileAbout from '../../../iframes/profile/containers/about';

const WhiteBox = styled.div`
    padding: 20px;
    background-color: ${colors.white};
`;

class TeamMemberProfile extends React.Component {
    render() {
        return(
            <WhiteBox>
                <IframeProfileAbout
                    fetchProfileId={this.props.coachId}
                    viewVerticalOnly={true}
                />
            </WhiteBox>
        )
    }
}

export default TeamMemberProfile;
