import React from 'react';
import styled from 'styled-components';

/* Components */
import { TableWrapperV2 } from "../components/tablev2";
import {colors} from '../../common/components/colors';
import {FontHeader14, FontBody12} from '../../common/components/fonts';
import {CaretDownOutlined, CloseOutlined} from '@ant-design/icons';

const Container = styled.div`
    margin-top: 10px;
    width: 250px;
    max-height: 350px;
    position: absolute;
    z-index: 10;
    border: 1px solid ${colors.primary30};
    -webkit-box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
`;
const TableContainer = styled.div`
    position: relative;
    vertical-align: top;
`;
const FilterButton = styled.div`
    position: relative;
`;
const Background = styled.div`
    z-index: 9;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`;
const Label = styled.div`
    width: fit-content;
    color: ${colors.action100};
    cursor: pointer;
    padding: 7px 15px;
    ${props => props.background === false} {
        background-color: ${colors.action15};
        border-radius: 2px;
    }
`;
const Arrow = styled.div`
    vertical-align: middle;
    display: inline-block;
    padding-right: 5px;
`;
const LabelText = styled.div`
    vertical-align: top;
    display: inline-block;
    padding-right: 3px;
`;
const Close = styled.div`
    z-index: 8;
    display: inline-block;
    vertical-align: middle;
`;

// this.props.title = title of dropdown contents
// this.props.action = function to fire when checked
// this.props.list = list of data with [..., {"id", 1, "name": "string"}]
// this.props.label = button text
// this.props.initSelected = initial selected records

class FilterDropdown extends React.Component {
    state = {"showFilter": false, "isLoading": true, "data": [], "selected": []};

    componentDidMount() {
        return this.update();
    }

    update = async () => {
        await this.setState({
            "data": await this.createData(this.props.list),
            "isLoading": false,
            "selected": this.props.initSelected
        });
    };

    isAnySelected = async (selected) => {
        await this.setState({"selected": selected});
        this.save();
    };

    close = async (e) => {
        e.stopPropagation();
        await this.setState({"showFilter": false, "selected": []});
        this.save();
    };

    save = async () => {
        this.props.action(this.props.title, this.state.selected);
    };

    createColumns = {
        "0": {"key": "id", "title": "Id", "titleShowOnDesktop": false, "canSort": true, "sort": "asc", "sortType": "string", "sortKey": "id", "component": "hidden", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": null, "onDoubleClick": null},
        "2": {"key": "isChecked", "title": "Select", "titleShowOnDesktop": false, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "checkbox", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": this.isAnySelected, "onDoubleClick": null},
        "3": {"key": "name", "title": this.props.title, "titleShowOnDesktop": true, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "filter", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": this.isAnySelected, "onDoubleClick": null},
    };

    createData = async (list) => {
        return Promise.all(list.map(d => {
            return Promise.resolve({
                "isChecked": false,
                "id": d.id,
                "name": d.name,
                "allData": d
            })
        }));
    };

    showFilter = () => {
        this.setState({"showFilter": !this.state.showFilter})
    };

    render() {
        if(this.state.isLoading) {
            return null
        } else {
            return (
                <FilterButton>
                    <Label onClick={this.showFilter} background={this.state.selected.length > 0}>
                        <LabelText><FontHeader14>{this.props.label}</FontHeader14></LabelText>
                        <Arrow><FontBody12><CaretDownOutlined /></FontBody12></Arrow>
                        {this.state.selected.length > 0 && <Close onClick={this.close}><FontHeader14><CloseOutlined /></FontHeader14></Close>}
                    </Label>
                    {this.state.showFilter &&
                        <>
                            <Container className={"container"}>
                                <TableContainer>
                                    <TableWrapperV2
                                        responsive={false}
                                        tableColumns={this.createColumns}
                                        tableData={this.state.data}
                                        initSelected={this.props.initSelected}
                                    />
                                </TableContainer>
                            </Container>
                            <Background onClick={this.showFilter} />
                        </>
                    }
                </FilterButton>
            )
        }
    }
}

export default FilterDropdown;