import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {
    setProfile,
    setProfileFetching,
    setProfileHandle,
    setProfileHandleFetching
} from "../store/profile";

/* Static */
import {devIframeHandleData, devIframeProfileData} from "../static/devdata";

// Types can be "scheduler, store, and handle
export const tryGetExpertIdFromPublicHandle = (type, handle) => {
    return async dispatch => {
        dispatch(setProfileHandleFetching(true));
        const handleResponse = await axiosReq('apiv1/public/coach/'+type+'/'+handle, "GET", {}, devIframeHandleData(true, handle));
        dispatch(setProfileHandle(handleResponse));
        return dispatch(setProfileHandleFetching(false));
    }
};

export const tryGetExpertProfileFromToken = (id) => {
    return async dispatch => {
        dispatch(setProfileFetching(true));
        const profile = await axiosReq('apiv1/public/coach/'+id+'/profile', "GET", {}, devIframeProfileData);
        await dispatch(setProfile(profile));
        await dispatch(setProfileFetching(false));
        return profile;
    }
};