import React from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import {Map} from 'immutable';

/* Components */
import {ActionBox, FitWidthButton, InlineItemLeft} from "../../components/clientsdetail";
import {FontHeader21, FontTitle18, FontHeader16} from "../../../common/components/fonts";
import {LogoLoading, LogoLoadingSmall} from "../../../common/components/loading";
import {ButtonPrimary, ButtonSecondary} from "../../../common/components/buttons";
import {Center, Photo, Border} from '../../components/contactcoaching';
import {colors} from '../../../common/components/colors';

/* Middleware */
import {tryCreateAnnouncement} from '../../homepage/middleware/announcements';
import {setAnnouncementModalStatus} from '../store/coaching';

class Announcements extends React.Component {
    state={
        "isLoading": true,
        "announcements": Map()
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps) {
        
    }

    init = async () => {
        this.setState({"isLoading": false})
    };

    setAnnouncementModal = async (status) => {
        return this.props.setAnnouncementModalStatus(status)
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <ActionBox>
                    <div>
                        <InlineItemLeft><FontTitle18>Announcements</FontTitle18></InlineItemLeft>
                    </div>
                    <br />
                    <Center><FontHeader16>{"Create announcements and reminders for your team."}</FontHeader16></Center>
                    <Border />
                    <FitWidthButton onClick={() => {return this.setAnnouncementModal(true)}}><ButtonPrimary canSubmit={false} label={"Send Announcement"} /></FitWidthButton>
                </ActionBox>
            )
        }
    }
}

const mapStateToProps = state => ({
    isWMC: state.common.wmc.get("isWMC"),
    user: state.common.user,
});

const mapDispatchToProps = dispatch => ({
    tryCreateAnnouncement: (wmcId, title, body) => dispatch(tryCreateAnnouncement(wmcId, title, body)),
    setAnnouncementModalStatus: (status) => dispatch(setAnnouncementModalStatus(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Announcements));