import {axiosReq} from "../../common/utils/axios";

/* Store */
import {setSettingsPersonalInfoReading, setSettingsPersonalInfoUpdating, setSettingsPersonalInfo} from "../store/settings";

/* Static */
import {devSettingsPersonalInfoData, devSettingsPersonalInfoUpdateData} from '../static/devdata';

export const tryGetSettingsPersonalInfo = () => {
    return async dispatch => {
        dispatch(setSettingsPersonalInfoReading(true));
        const personalInfo = await axiosReq('apiv1/settings/general', "GET", {}, devSettingsPersonalInfoData);
        dispatch(setSettingsPersonalInfo({...personalInfo, "error": ""}));
        return dispatch(setSettingsPersonalInfoReading(false));
    }
};

export const tryUpdateSettingsPersonalInfo = (data) => {
    return async (dispatch, getState) => {
        dispatch(setSettingsPersonalInfoUpdating(true));
        const personalInfo = await axiosReq('apiv1/settings/general', "PUT", {companyName: data.companyName, first: data.first, last: data.last, phone: data.phone, email: data.email, timeZone: data.timeZone}, devSettingsPersonalInfoUpdateData(data));
        if(personalInfo.hasOwnProperty("error")) {
            dispatch(setSettingsPersonalInfo({...getState().enterprise.settingsPersonal.get("settingsPersonalInfo").toJS(), "error": personalInfo.error}));
        } else {
            dispatch(setSettingsPersonalInfo({...data, "error": ""}));
        }
        return dispatch(setSettingsPersonalInfoUpdating(false));
    }
};








