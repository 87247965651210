import {images} from "../../../common/components/images";

export const devEndorsedCoachArticles = {


    "allArticles": [
        {
            "id": 1,
            "image": images.logo,
            "category": "Category 2",
            "title": "Divorce",
            "advisor": "Sadie",
            "status": "pending"
        },
        {
           
            "id": 2,
            "image": images.logo,
            "category": "Investments",
            "title": "401K",
            "advisor": "Jason",
            "status": "accepted"
        },
        {
            
            "id": 3,
            "image": images.logo,
            "category": "Category 4",
            "title": "Learning about xyz",
            "advisor": "Amy",
            "status": "accepted"
        },
        {
           
            "id": 4,
            "image": images.logo,
            "category": "Category 4",
            "title": "Learning about ABC",
            "advisor": "John",
            "status": "accepted"
        }
    ]
}

