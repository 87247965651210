import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

// import { NavPageWrapper } from '../../../consumer/navbars/page';

import { colors } from '../../../common/components/colors';
import { FontHeader18, FontSubtitle38To30, FontSubtitle21 } from '../../../common/components/fonts';

import {MenuOutlined} from '@ant-design/icons';

import {setNavPageStatus} from "../../../common/containers/navexpanded/store/nav";

const NavTop = styled.div`
    height: calc(160px - 31px);
    background-color: ${colors.white};
    width: calc(100% - 60px);
    padding: 30px 0px 0px 60px;
    border-bottom: 1px solid ${colors.border100};
    @media screen and (max-width: 1088px) {
        width: calc(100% - 40px);
        height: calc(160px - 46px);
        padding: 10px 20px 0px 20px;
    }
    @media screen and (max-width: 748px) {
        display: none;
    }
`;
const Links = styled.div`
    margin-top: 32px;
    @media screen and (max-width: 1088px) {
        margin-top: 15px;
    }
`;
const LinkStyled = styled(Link)`
    display: inline-block;
    margin-right: 50px;
    text-align: center;
    cursor: pointer;
    padding: 10px 20px;
    ${props => props.status !== 'active'} {
        color: ${colors.primary100};
        border-bottom: 3px solid ${colors.action100};
    }
    ${props => props.status !== 'inactive'} {
        color: ${colors.primary30};
    }
    @media screen and (max-width: 1440px) {
        margin-right: 0px;
    }
    @media screen and (max-width: 1088px) {
        margin-top: 15px;
    }
`;

const NavSecondary = ({ data, location }) => {
    const navItem = data.nav.filter(d => {return(location.pathname.includes(d.rootHref))})[0];
    return(
        <NavTop >
            <FontSubtitle38To30>{navItem.navTopTitle}</FontSubtitle38To30>
            <Links>
                {navItem.navTopTabs.map((t, index) => {
                    let status = "inactive";
                    if(location.pathname === t.navTabHref) {status = "active"}
                    return(<LinkStyled key={index} status={status} to={t.navTabHref}><FontHeader18>{t.navTabTitle}</FontHeader18></LinkStyled>)
                })}
            </Links>
        </NavTop>
    );
};

const NavTopMobile = styled.div`
    display: none;
    @media screen and (max-width: 748px) {
        width: calc(100% - 40px);
        padding: 10px 20px;
        background-color: ${colors.white};
        border-bottom: 1px solid ${colors.border100};
        display: block;
    }
`;
const MenuIcon = styled.div`
    @media screen and (max-width: 748px) {
        height: 20px;
        width: 20px;
        display: inline-block;
        margin: 6px 0px;
        text-align: right;
        cursor: pointer;
        font-size: 20px;
        padding: 5px;
    }
`;
const Title = styled.div`
    @media screen and (max-width: 748px) {
        display: inline-block;
        vertical-align: top;
        margin: 6px 0px;
        width: calc(100% - 35px);
    }
`;

const NavSecondaryMobile = ({ data, location, pageStatusActive, setNavPageStatus }) => {
    const navItem = data.nav.filter(d => {return(location.pathname.includes(d.rootHref))})[0];
    return(
        <NavTopMobile>
            <Title><FontSubtitle21>{navItem.navTopTabs.map(t => {if(location.pathname === t.navTabHref) {return(t.navTabTitle)}})}</FontSubtitle21></Title>
            <MenuIcon onClick={() => {setNavPageStatus(!pageStatusActive)}}><MenuOutlined /></MenuIcon>
        </NavTopMobile>
    );
};
const mapStateToProps = state => ({
    pageStatusActive: state.common.nav.get('pageStatusActive')
});
const mapDispatchToProps = dispatch => ({
    setNavPageStatus: (status) => dispatch(setNavPageStatus(status))
});
const NavSecondaryMobileWrapper = connect(mapStateToProps, mapDispatchToProps)(NavSecondaryMobile);

const NavSecondaryCombined = ({ data, location }) => (
    <>
        <NavSecondary data={data} location={location} />
        <NavSecondaryMobileWrapper data={data} location={location} />
        {/*<NavPageWrapper data={data} location={location} pageStatusActive={false} />*/}
    </>
);
export const NavSecondaryWrapper = withRouter(NavSecondaryCombined);
