import React from 'react';
import {withRouter} from 'react-router-dom';

/* Navs */
import {PageContent, PageFull, PageRight} from "../navbars/components/navlayout";
import NavPrimaryAll from "../navbars/containers/primaryall";
import NavSecondaryTop from '../navbars/containers/secondarytop';

/* Containers */
import MeetingsCalendar from "./containers/meetingscalendar";
// import MeetingsList from '../containers/meetingslist';
import MeetingsCreate from "./containers/meetingscreatenew";
import MeetingsCreateSmall from "./containers/meetingscreatesmall";
import MeetingsCreateLarge from "./containers/meetingscreatelarge";
import MeetingsEditLarge from "./containers/meetingseditlarge";
import MeetingsInfo from "./containers/meetingsinfo";
import MeetingsInfoPage from "./containers/meetingsinfopage";

/* Components */
import {PlusOutlined} from "@ant-design/icons";

const meetingButtons = [
    {
        "pathname": "/meetings/create/call",
        "label": <><PlusOutlined /> Create</>,
        "buttonType": "primary"
    }
];

const Availability = () => (
    <>
        <NavPrimaryAll />
        <PageFull>
            <PageRight>

                {location.pathname === "/meetings" &&
                    <>
                        <NavSecondaryTop title={"Meetings"} buttons={meetingButtons} desc={"Keep track of your upcoming meetings and create new meetings with your contacts"}/>
                        <PageContent>
                            <MeetingsCalendar />
                        </PageContent>
                    </>
                }

                {location.pathname === "/meetings/create" &&
                    <>
                        <NavSecondaryTop title={"Create"} />
                        <PageContent>
                            <MeetingsCreate />
                        </PageContent>
                    </>
                }

                {location.pathname === "/meetings/create/call" &&
                    <>
                        <NavSecondaryTop title={"Create Video Call"} />
                        <PageContent>
                            <MeetingsCreateSmall />
                        </PageContent>
                    </>
                }

                {location.pathname === "/meetings/create/webinar" &&
                    <>
                        <NavSecondaryTop title={"Create Webinar"} />
                        <PageContent>
                            <MeetingsCreateLarge />
                        </PageContent>
                    </>
                }

                {location.pathname === "/meetings/edit/webinar" &&
                    <>
                        <NavSecondaryTop title={"Edit Webinar"} />
                        <PageContent>
                            <MeetingsEditLarge />
                        </PageContent>
                    </>
                }

                {location.pathname === "/meetings/details" &&
                    <>
                        {/*<NavSecondaryTop title={"Meeting Info"} />*/}
                        <PageContent>
                            <MeetingsInfo />
                        </PageContent>
                    </>
                }

                {location.pathname === "/meetings/page" &&
                    <>
                        {/*<NavSecondaryTop title={"Meeting Info"} />*/}
                        <PageContent>
                            <MeetingsInfoPage />
                        </PageContent>
                    </>
                }

            </PageRight>
        </PageFull>
    </>
);

export default withRouter(Availability);
