import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

/* Components */
import {ButtonTertiary} from '../../../common/components/buttons';
import {Toggle2Lables} from "../../../common/components/inputs";
import {FontBody16, FontTitle18} from '../../../common/components/fonts';
import {colors} from '../../../common/components/colors';
import {LogoLoading} from "../../../common/components/loading";
import {InlineBlock, SaveSection, SubmitInputWithValidation} from "../components/pagescontact";

/* Middleware */
import {tryUpdateExpertProfile} from '../middleware/expertprofile';
import {SavedSettings} from "../../../common/components/cards";

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

const RemoveMargin = styled.div`
    margin-bottom: -30px;
`;

const GrayColor = styled.div`
    color: ${colors.primary70};
`;

class PagesPublishSidebar extends React.Component {
    state = {
        "isLoading": true,
        "active": false,
        "activePrevious": false,
        "activeIsChanged": false,
        "activeIsChanging": false,
        "activeIsSaved": false,
        "error": "",
    };

    componentDidMount() {
        return this.initiate();
    }

    initiate = async () => {
        await this.setState({
            "active": this.props.profile.get(this.props.activeKey),
            "activePrevious": this.props.profile.get(this.props.activeKey),
            "isLoading": false,
        });
    };

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    changeActive = async () => {
        await this.setState({"active": !this.state.active, "activeIsChanged": this.state.activePrevious !== !this.state.active});
    };

    resetActive = async () => {
        await this.setState({"active": this.state.activePrevious, "activeIsChanged": false});
        this.props.handleEdit(false);
    };

    submitActive = async e => {
        e.preventDefault();
        this.setState({'activeIsChanging': true, "error": ""});
        await this.props.tryUpdateExpertProfile({[this.props.activeKey]: this.state.active}, this.props.errorKey);
        if(this.props.profile.get(this.props.errorKey) === "") {
            this.setState({
                'activeIsChanging': false,
                "activePrevious": this.state.active,
                "activeIsSaved": true,
            });
            this.timeout = setTimeout(() => {
                this.setState({'activeIsSaved': false, "activeIsChanged": false});
                this.props.handleEdit(false);
            }, 3000);
        } else {
            this.setState({'activeIsSaved': true, 'activeIsChanging': false, "error": this.props.profile.get(this.props.errorKey)});
            this.timeout = setTimeout(() => {this.setState({'activeIsSaved': false, "error": ""})}, 3000);
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    {this.state.activeIsSaved
                        ?
                            <>
                                {this.state.error === '' && <SavedSettings height={"99px"} error={false} message={"Saved"}/>}
                                {this.state.error !== '' && <SavedSettings height={"99px"} error={true} message={this.state.error}/>}
                            </>
                        :
                            <>
                                <form method={"post"} onSubmit={this.submitActive}>
                                    {this.props.title !== null && <FontTitle18>{this.props.title}</FontTitle18>}
                                    {this.props.subtitle !== null && <GrayColor><FontBody16>{this.props.subtitle}</FontBody16></GrayColor>}
                                    <br />
                                    <br />
                                    <Toggle2Lables leftLabel={this.props.leftLabel} rightLabel={this.props.rightLabel} id={"toggle"} onChange={this.changeActive} value={this.state.active} offColor={colors.primary70} onColor={colors.progress100} />
                                    <RemoveMargin />
                                    <SaveSection>
                                        <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.activeIsChanged} isValid={true} isSaving={this.state.activeIsChanging} /></InlineBlock>
                                        <InlineBlock><span onClick={this.resetActive}><ButtonTertiary canSubmit={false} label={"Cancel"} /></span></InlineBlock>
                                    </SaveSection>
                                </form>
                            </>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    profile: state.enterprise.profile.expertProfile.get("expertProfile"),
});

const mapDispatchToProps = dispatch => ({
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PagesPublishSidebar));
