import { Map, fromJS } from 'immutable';

/* Immutable */

const IFRAME_AUTH__TEST_USER_EXISTS = 'IFRAME_AUTH__TEST_USER_EXISTS';
const IFRAME_AUTH_CHECK_HAS_ACCOUNT_ERROR = 'IFRAME_AUTH_CHECK_HAS_ACCOUNT_ERROR';
const IFRAME_AUTH_USER_EXISTING_INFO = 'IFRAME_AUTH_USER_EXISTING_INFO';
const IFRAME_AUTH_USER_EXISTS_INFO_READING = 'IFRAME_AUTH_USER_EXISTS_INFO_READING';
const IFRAME_AUTH_UPDATE_USER_ERROR = 'IFRAME_AUTH_UPDATE_USER_ERROR';
const IFRAME_AUTH_CREATE_USER = 'IFRAME_AUTH_CREATE_USER';
const IFRAME_AUTH_UPDATE_USER = 'IFRAME_AUTH_UPDATE_USER';
const IFRAME_AUTH__LOGIN = 'IFRAME_AUTH__LOGIN';
const IFRAME_AUTH__LOGIN_ERROR = 'IFRAME_AUTH__LOGIN_ERROR';
const IFRAME_AUTH__SIGNING_UP = 'IFRAME_AUTH__SIGNING_UP';
const IFRAME_AUTH__SIGNING_UP_ERROR = 'IFRAME_AUTH__SIGNING_UP_ERROR';
const IFRAME_AUTH__NAME_SAVING = 'IFRAME_AUTH__NAME_SAVING';
const IFRAME_AUTH__TOPIC_PHONE = 'IFRAME_AUTH__TOPIC_PHONE';
const IFRAME_AUTH__PHONE_VERIFY_RESEND = 'IFRAME_AUTH__PHONE_VERIFY_RESEND';
const IFRAME_AUTH__PHONE_VERIFYING = 'IFRAME_AUTH__PHONE_VERIFYING';
const IFRAME_AUTH__PHONE_VERIFIED = 'IFRAME_AUTH__PHONE_VERIFIED';
const IFRAME_AUTH__SESSION_CREATING = 'IFRAME_AUTH__SESSION_CREATING';
const IFRAME_AUTH__SESSION_CREATING_ERROR = 'IFRAME_AUTH__SESSION_CREATING_ERROR';
const IFRAME_AUTH__STORE_RESET = 'IFRAME_AUTH__STORE_RESET';
const IFRAME_AUTH_SIGNUP_SUCCESS_EMAIL_SENDING = 'IFRAME_AUTH_SIGNUP_SUCCESS_EMAIL_SENDING';

/* Actions */

export const setIframeAuthTestUserExists = (status) => ({"type": IFRAME_AUTH__TEST_USER_EXISTS, 'status': status});
export const setIframeAuthCheckHasAccountError = (error) => ({"type": IFRAME_AUTH_CHECK_HAS_ACCOUNT_ERROR, 'error': error});
export const setIframeAuthUserExistingInfo = (info) => ({"type": IFRAME_AUTH_USER_EXISTING_INFO, 'info': info});
export const setIframeAuthUserExistsInfoReading = (status) => ({"type": IFRAME_AUTH_USER_EXISTS_INFO_READING, 'status': status});
export const setIframeAuthUpdateUserError = (error) => ({"type": IFRAME_AUTH_UPDATE_USER_ERROR, 'error': error});
export const setIframeAuthLogin = (status) => ({"type": IFRAME_AUTH__LOGIN, 'status': status});
export const setIframeAuthLoginError = (error) => ({"type": IFRAME_AUTH__LOGIN_ERROR, 'error': error});
export const setIframeAuthSigningUp = (status) => ({"type": IFRAME_AUTH__SIGNING_UP, 'status': status});
export const setIframeAuthSigningUpError = (error) => ({"type": IFRAME_AUTH__SIGNING_UP_ERROR, 'error': error});
export const setIframeAuthNameSaving = (status) => ({"type": IFRAME_AUTH__NAME_SAVING, 'status': status});
export const setIframeAuthUpdateUser = (status) => ({"type": IFRAME_AUTH_UPDATE_USER, 'status': status});
export const setIframeAuthCreateUser = (status) => ({"type": IFRAME_AUTH_CREATE_USER, 'status': status});
export const setIframeAuthTopicPhoneSaving = (status) => ({"type": IFRAME_AUTH__TOPIC_PHONE, 'status': status});
export const setIframeAuthPhoneVerifyResending = (status) => ({"type": IFRAME_AUTH__PHONE_VERIFY_RESEND, 'status': status});
export const setIframeAuthPhoneVerifying = (status) => ({"type": IFRAME_AUTH__PHONE_VERIFYING, 'status': status});
export const setIframeAuthPhoneVerified = (status) => ({"type": IFRAME_AUTH__PHONE_VERIFIED, 'status': status});
export const setIframeAuthStoreReset = () => ({"type": IFRAME_AUTH__STORE_RESET});
export const setIframeAuthSignUpSuccessEmailSending = (status) => ({'type': IFRAME_AUTH_SIGNUP_SUCCESS_EMAIL_SENDING, 'status': status});

/* Initial State */

const initialState = Map({
    iframeAuthTestUserExists: false,
    iframeAuthCheckHasAccountError: null,
    iframeAuthUserExistingInfo: null,
    iframeAuthUserExistsInfoReading: false,
    iframeAuthUpdateUserError: null,
    iframeAuthLogin: false,
    iframeAuthLoginError: null,
    iframeAuthSigningUp: false,
    iframeAuthSigningUpError: null,
    iframeAuthNameSaving: false,
    iframeAuthTopicPhoneSaving: false,
    iframeAuthPhoneVerifyResending: false,
    iframeAuthPhoneVerifying: false,
    iframeAuthPhoneVerified: "not_verified",
    iframeAuthSessionCreating: false,
    iframeAuthUpdateUserStatus: false,
    iframeAuthCreateUserStatus: false,
    iframeAuthSessionCreatingError: null,
    iframeAuthSignUpSuccessEmailSending: false, 
});

/* Reducer */

const iframeAuthReducer = (state=initialState, action) => {
    switch (action.type) {
        case IFRAME_AUTH__TEST_USER_EXISTS:
            return state.merge({'iframeAuthTestUserExists': action.status});
        case IFRAME_AUTH_CHECK_HAS_ACCOUNT_ERROR:
            return state.merge({'iframeAuthCheckHasAccountError': action.error});
        case IFRAME_AUTH_USER_EXISTING_INFO:
            return state.merge({'iframeAuthUserExistingInfo': fromJS(action.info)});
        case IFRAME_AUTH_USER_EXISTS_INFO_READING:
            return state.merge({'iframeAuthUserExistsInfoReading': action.status});
        case IFRAME_AUTH_UPDATE_USER:
            return state.merge({'iframeAuthCreateUserStatus': action.error});
        case IFRAME_AUTH_CREATE_USER:
            return state.merge({'iframeAuthUpdateUserStatus': action.error});
        case IFRAME_AUTH_UPDATE_USER_ERROR:
            return state.merge({'iframeAuthUpdateUserError': action.error});
        case IFRAME_AUTH__LOGIN:
            return state.merge({'iframeAuthLogin': action.status});
        case IFRAME_AUTH__LOGIN_ERROR:
            return state.merge({'iframeAuthLoginError': action.error});
        case IFRAME_AUTH__SIGNING_UP:
            return state.merge({'iframeAuthSigningUp': action.status});
        case IFRAME_AUTH__SIGNING_UP_ERROR:
            return state.merge({'iframeAuthSigningUpError': action.error});
        case IFRAME_AUTH__NAME_SAVING:
            return state.merge({'iframeAuthNameSaving': action.status});
        case IFRAME_AUTH__TOPIC_PHONE:
            return state.merge({'iframeAuthTopicPhoneSaving': action.status});
        case IFRAME_AUTH__PHONE_VERIFY_RESEND:
            return state.merge({'iframeAuthPhoneVerifyResending': action.status});
        case IFRAME_AUTH__PHONE_VERIFYING:
            return state.merge({'iframeAuthPhoneVerifying': action.status});
        case IFRAME_AUTH__PHONE_VERIFIED:
            return state.merge({'iframeAuthPhoneVerified': action.status});
        case IFRAME_AUTH__SESSION_CREATING:
            return state.merge({'iframeAuthSessionCreating': action.status});
        case IFRAME_AUTH__SESSION_CREATING_ERROR:
            return state.merge({'iframeAuthSessionCreatingError': action.error});
        case IFRAME_AUTH__STORE_RESET:
            return state.merge(initialState);
        case IFRAME_AUTH_SIGNUP_SUCCESS_EMAIL_SENDING:
            return state.merge({'iframeAuthSignUpSuccessEmailSending': action.status});
        default:
            return state;
    }
};

export default iframeAuthReducer;
