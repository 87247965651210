import { Map, List, fromJS } from 'immutable';

/* Immutable */

const PROGRAM_DATA = 'PROGRAM_DATA';
const PROGRAM_DOWNLOADING = 'PROGRAM_DOWNLOADING';
const PROGRAM_CHECKOUT_DATA = 'PROGRAM_CHECKOUT_DATA';
const PROGRAM_RECEIPT_DATA = 'PROGRAM_RECEIPT_DATA';
const PROGRAM_RECEIPT_DOWNLOADING = 'PROGRAM_RECEIPT_DOWNLOADING';
const PROGRAM_CHECKOUT_DOWNLOADING = 'PROGRAM_CHECKOUT_DOWNLOADING';
const PROGRAM_STORE_RESET = 'PROGRAM_STORE_RESET';

/* Actions */

export const setProgramData = (data) => ({'type': PROGRAM_DATA, 'data': data});
export const setProgramDownloading = (status) => ({'type': PROGRAM_DOWNLOADING, 'status': status});
export const setProgramCheckoutData = (data) => ({'type': PROGRAM_CHECKOUT_DATA, 'data': data});
export const setProgramCheckoutReceiptData = (data) => ({'type': PROGRAM_RECEIPT_DATA, 'data': data});
export const setProgramCheckoutDownloading = (status) => ({'type': PROGRAM_CHECKOUT_DOWNLOADING, 'status': status});
export const setProgramCheckoutReceiptDownloading = (status) => ({'type': PROGRAM_RECEIPT_DOWNLOADING, 'status': status});
export const setProgramStoreReset = () => ({'type': PROGRAM_STORE_RESET});

/* Initial State */

const initialState = Map({
    programData: Map({
        id: null,
        first: null,
        last: null,
        email: null,
        phone: null,
        coach_id: null,
        coach_first: null,
        coach_last: null,
        token: null
    }),
    programDownloading: false,
    programCheckoutData: Map({
        "stripeCheckoutSessionId": null,
        "stripeApiPublishableKey": null,
        "priceId": null,
        "productCost": null
    }),
    programReceiptData: Map({
        id: null,
        first: null,
        last: null,
        email: null,
        phone: null,
        coach_id: null,
        coach_first: null,
        coach_last: null,
        token: null,
        priceId: null,
        productCost: null
    }),
    programCheckoutDownloading: false,
    programCheckoutReceiptDownloading: false
});

/* Reducer */

const programReducer = (state=initialState, action) => {
    switch (action.type) {
        case PROGRAM_DATA:
            return state.merge({"programData": fromJS(action.data)});
        case PROGRAM_DOWNLOADING:
            return state.merge({'programDownloading': action.status});
        case PROGRAM_CHECKOUT_DATA:
            return state.merge({"programCheckoutData": fromJS(action.data)});
        case PROGRAM_CHECKOUT_DOWNLOADING:
            return state.merge({'programCheckoutDownloading': action.status});
        case PROGRAM_RECEIPT_DATA:
            return state.merge({"programReceiptData": fromJS(action.data)});
        case PROGRAM_RECEIPT_DOWNLOADING:
            return state.merge({'programCheckoutReceiptDownloading': action.status});
        case PROGRAM_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default programReducer;
