import { combineReducers } from 'redux';

/* Common */
import insightsReducer from './store/insights';

const learnReducers = combineReducers({
    insights: insightsReducer,
});

export default learnReducers;
