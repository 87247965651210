import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import {withRouter} from 'react-router-dom';

/* Components */
import {DropdownInput, TextInput, PhoneInput} from "../../../common/components/inputs";
import NavSecondaryTop from "../../navbars/containers/secondarytop";

/* Middleware */
import {tryCreateUserAdvisor} from '../middleware/settingsusers';
import {FontTitle18} from "../../../common/components/fonts";
import {InlineBlock, SaveSection, Width, SubmitInputWithValidation} from "../../components/clientscreate";

/* Store */
import {Link} from "react-router-dom";
import {ButtonTertiary} from "../../../common/components/buttons";

/* Utils */
import {timeZoneOptions} from '../../../common/utils/timezone2';

class SettingsUsersCreate extends React.Component {
    state = {
        first: '', firstValid: true,
        last: '', lastValid: true,
        phone: '', phoneValid: true,
        email: '', emailValid: true,
        timeZone: '',
        isSaving: false,
        isSaved: false,
        error: '',
        isChanged: false,
        isValid: true
    };

    componentDidMount() {}
    componentWillUnmount() {}

    handleChangeFirst = async e => {
        this.setState({'first': e.target.value});
        await this.setState({'firstValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeLast = async e => {
        this.setState({'last': e.target.value});
        await this.setState({'lastValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeEmail = async e => {
        this.setState({'email': e.target.value});
        await this.setState({'emailValid': validator.isEmail(e.target.value)});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangePhone = async e => {
        this.setState({'phone': e.target.value.replace('(', '').replace(')', '').replace(' ', '').replace('-', '')});
        await this.setState({'phoneValid': validator.isMobilePhone(e.target.value, 'en-US')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeTimeZone = async e => {
        await this.setState({'timeZone': e.value});
        this.setState({'isChanged': true});
        this.checkValid();
    };

    checkValid = () => {
        if(this.state.firstValid && this.state.lastValid && this.state.phoneValid && this.state.emailValid && this.state.timeZone !== "") {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            this.setState({'isSaving': true, 'error': ''});
            await this.props.tryCreateUserAdvisor({...this.state, "wmcId": this.props.wmcId});
            if(this.props.settingsUsers.get("settingsUsersError") !== "") {
                this.setState({'error': this.props.settingsUsers.get("settingsUsersError"), 'isSaving': false, 'isSaved': true, isChanged: false});
                this.timeout = setTimeout(() => {this.setState({'error': null, 'isSaving': false, 'isSaved': false, isChanged: true})}, 3000);
            } else {
                this.setState({'isSaving': false, 'isSaved': true, isChanged: false});
                this.timeout = setTimeout(() => {this.props.history.push("/settings/users")}, 3000);
            }
        }
    };

    render() {
        return(
            <>
                <NavSecondaryTop title={"Create User"} />
                <form method={"post"} onSubmit={this.handleSubmit}>
                    {this.state.isSaved
                        ?
                        <>
                            {this.state.error === '' && <FontTitle18>Created!</FontTitle18>}
                            {this.state.error !== '' && <FontTitle18>{this.state.error}</FontTitle18>}
                        </>
                        :
                        <>
                            <Width>
                                <TextInput
                                    title={"First Name"}
                                    valid={this.state.firstValid}
                                    warning={"Required"}
                                    id={"first"}
                                    onChange={this.handleChangeFirst}
                                    placeholder={"First Name"}
                                    value={this.state.first}
                                />
                                <TextInput
                                    title={"Last Name"}
                                    valid={this.state.lastValid}
                                    warning={"Required"}
                                    id={"last"}
                                    onChange={this.handleChangeLast}
                                    placeholder={"Last Name"}
                                    value={this.state.last}
                                />
                                <PhoneInput
                                    title={"Mobile Phone"}
                                    valid={this.state.phoneValid}
                                    warning={"Enter a valid mobile phone"}
                                    id={"phone"}
                                    onChange={this.handleChangePhone}
                                    placeholder={'(###) ###-####'}
                                    value={this.state.phone}
                                    options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}}
                                />
                                <TextInput
                                    title={"Email"}
                                    valid={this.state.emailValid}
                                    warning={"Enter a valid email address"}
                                    id={"email"}
                                    onChange={this.handleChangeEmail}
                                    placeholder={"Email"}
                                    value={this.state.email}
                                />
                                <DropdownInput
                                    title={"Time zone"}
                                    options={timeZoneOptions}
                                    id={'timeZone'}
                                    onChange={this.handleChangeTimeZone}
                                    placeholder={'Time Zone'}
                                    value={this.state.timeZone}
                                />
                            </Width>
                            <SaveSection>
                                <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                <InlineBlock><Link to={"/settings/users"}><ButtonTertiary canSubmit={false} label={"Cancel"} /></Link></InlineBlock>
                            </SaveSection>
                        </>
                    }
                </form>
            </>
        )
    }
}

const mapStateToProps = state => ({
    settingsUsers: state.enterprise.settingsUsers,
    wmcId: state.common.wmc.get("wmc").get("id")
});
const mapDispatchToProps = dispatch => ({
    tryCreateUserAdvisor: (data) => dispatch(tryCreateUserAdvisor(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SettingsUsersCreate));
