import React from 'react';
import {connect} from 'react-redux';
import styled from "styled-components";
import { withRouter } from 'react-router-dom';

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FontSubtitle40, FontTitle40, FontBody21, FontHeader16, FontHeader18, FontBody24,} from '../../../common/components/fonts';
import { RightOutlined }  from "@ant-design/icons";
import { colors } from '../../../common/components/colors';
import {images} from "../../../common/components/images";

/* Middleware */
import {tryGetAllTeamMembers} from '../middleware/team';
import {tryGetTeamMemberProductsGeneral} from '../middleware/products'

/* Utility */
import { hexToRGB, brightness } from '../../../common/utils/colors';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const Container = styled.div`
    @media screen and (min-width: 750px) {
        background-image: url(${images.backgroundBlur});
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 100vh;
    }
`
const MemberListItem = styled.div`
    align-items: center;
    background-color: ${props => props.backgroundColor};
    border-radius: ${props => props.even ? '0px 20px 0px 20px' : '20px 0px 20px 0px'};
    color: ${props => brightness(hexToRGB(props.backgroundColor, false)) < 130 ? colors.white : colors.primary100};
    cursor: pointer;
    display: flex;
    gap: 15px;
    padding: 20px;
`;
const FlexWrap = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    gap: 10px 30px;
    align-items: center;
`;
const MemberListItemNameTitle = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
`;
const ProfileIcon = styled.img`
    border-radius: 50%;
    height: 132px;
    width: 132px;
    object-fit: cover;
`;
const RightOutlined32 = styled(RightOutlined)`
    font-size: 32px;
`;
const CareTeamInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-around;
    margin: 0px auto;
    text-align: center;
    width: calc(100% - 120px);
    padding: 90px 60px 120px;
    @media screen and (max-width: 750px) {
        max-width: 650px;
        width: 100%;
        padding: 0;
    }
`;
const MemberList = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin: 40px 0;
`;
const FindAnotherButton = styled.div`
    background-color: ${colors.action100};
    border-radius: 25px;
    color: ${colors.white};
    cursor: pointer;
    padding: 15px 30px;
    box-shadow: 0px 3px 10px 0px #00000040;
    width: fit-content;
    margin: 0 auto;
    @media screen and (min-width: 750px) {
        align-self: flex-end;
        right: 20px;
        margin: 0 0 0 auto;
    }
`;

const FindAnotherButton2 = styled.div`
    background-color: ${colors.action100};
    border-radius: 25px;
    color: ${colors.white};
    cursor: pointer;
    padding: 15px 30px;
    box-shadow: 0px 3px 10px 0px #00000040;
`;

const FindContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: ${colors.white};
    padding: 40px 20px;
    border-radius: 35px;
    margin-top: 40px;
    max-width: 360px;  
    gap: 30px;
    align-self: center;
    
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
`

const PageTitle = styled(FontSubtitle40)`
    letter-spacing: 5.6px;
    @media screen and (min-width: 750px) {
        text-align: left;
    }
    text-align: center;
`;
const PageSubtitle = styled(FontBody24)`
    @media screen and (min-width: 750px) {
        text-align: left;
    }
    text-align: center;
    & a {
        text-decoration: underline;
        color: ${colors.primary100};
        font-weight: bold;
    }
`;

class MembersList extends React.Component {
    state = {"isLoading": true, "hasCoach": true, "members": ""};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.props.tryGetAllTeamMembers();
        let members = this.props.members.filter((m) => {return(m.get("wmcId") === null && !(m.get("notATeamMember")))});
        // console.log('memberlist members', members)
        // console.log('memberlist propmembers', members)
        await this.props.tryGetTeamMemberProductsGeneral();
        let hasCoach = !(members.size === 0);
        // if(hasCoach) {
            this.setState({"hasCoach": hasCoach, "members": members, "isLoading": false,})
        // } else {
        //     this.props.history.push("/my-team/find");
        // }
    };

    toggleSelectMember = (member) => this.props.history.push("/my-team/"+member.coachId);
    findAnotherCoach = () => this.props.history.push('/my-team/find');

    render() {
        console.log('members', this.state.members)
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <Container>
                    <CareTeamInfo>
                        <PageTitle>YOUR COACH TEAM</PageTitle>
                        {this.props.isExclusive
                            ? <PageSubtitle>Get support on your life journey from your certified Willow Financial Life Coach.</PageSubtitle>
                            : <PageSubtitle>Get support on your life journey from a certified Willow Financial Life Coach. You have [{this.props.availableSessions}] coaching appointments available. <a onClick={() => { window.location.href = 'https://pay.trustwillow.com/b/8wM15u7Iy4nD0Lu8wR?client_reference_id='+this.props.userId }}>Add more appointments.</a></PageSubtitle>
                        }
                        {this.state.hasCoach
                        ?
                            <>
                            <MemberList>
                                {this.state.members.map((a, index) => {
                                    const data = a.toJS();
                                    return (
                                        <MemberListItem
                                            key={index}
                                            onClick={() => this.toggleSelectMember(data)}
                                            backgroundColor={colors.cellColors[index % colors.cellColors.length]}
                                            even={index % 2 !== 0}
                                        >
                                            <FlexWrap>
                                                <ProfileIcon src={data.photo} alt={"Willow"} />
                                                <MemberListItemNameTitle>
                                                    <FontTitle40>{data.first + ' ' + data.last}</FontTitle40>
                                                    <FontBody21>
                                                        {env.CONSUMER.CARE.PRINT_EXPERT_CATEGORIES(data.expertCategories, true)}
                                                    </FontBody21>
                                                </MemberListItemNameTitle>
                                            </FlexWrap>
                                            <RightOutlined32/>
                                        </MemberListItem>
                                    );
                                })}
                            </MemberList>
                            {!this.props.isExclusive &&
                                <FindAnotherButton onClick={this.findAnotherCoach}><FontHeader16>{'Find Another Coach'}</FontHeader16></FindAnotherButton>
                            }
                            </>
                        :
                            <FindContainer>
                            <FontBody24>To book your first session, find a coach.</FontBody24>
                            <FindAnotherButton2 onClick={this.findAnotherCoach}><FontHeader18>{'Find A Coach'}</FontHeader18></FindAnotherButton2>
                            </FindContainer>
                        }
                    </CareTeamInfo>
                </Container>
            )
        }
    }
}

const mapStateToProps = state => ({
    members: state.consumer.team.team.get("members"),
    user: state.common.user,
    isExclusive: state.common.user.get("isExclusive"),
    referralType: state.common.user.get("referralType"),
    availableSessions: state.consumer.team.products.get("productsGeneral"),
    userId: state.common.user.get("userId"),
});

const mapDispatchToProps = dispatch => ({
    tryGetAllTeamMembers: () => dispatch(tryGetAllTeamMembers()),
    tryGetTeamMemberProductsGeneral: () => dispatch(tryGetTeamMemberProductsGeneral())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MembersList));
