import React from 'react';
import styled from 'styled-components';
import {Link, withRouter} from 'react-router-dom';

/* Components */
import {colors} from '../../../../common/components/colors';
import {FontSubtitle28} from '../../../../common/components/fonts';

const NavRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 60px;
    row-gap: 30px;
    padding-bottom: 40px;
    margin-top: 53px;
`;
const Nav = styled(Link)`
    ${props => props.active !== "true"} {
        border-bottom: 3px solid ${colors.action100};
        cursor: default;
        & div {
          font-weight: bold;
        }
    }
    ${props => props.active !== "false"} {
        border-bottom: none;
        cursor: pointer;
    }
    padding: 10px 0;
    width: fit-content;
    color: ${colors.primary100};
`;
const Bar = styled.div`
    width: 2px;
    margin: 4px 0;
    background-color: ${colors.primary100};
`;

class FirmProspectsNav extends React.Component {
    render() {
        return (
            <NavRow>
                {/* <Nav
                    active={(this.props.location.pathname === "/my-growth").toString()}
                    to={"/my-growth"}
                >
                    <FontSubtitle28 spaced={false}>Overview</FontSubtitle28>
                </Nav>
                <Bar /> */}
                <Nav
                    active={(this.props.location.pathname === "/my-growth").toString()}
                    to={"/my-growth"}
                >
                    <FontSubtitle28 spaced={false}>Prospects</FontSubtitle28>
                </Nav>
                <Bar />
                {/* <Nav
                    active={(this.props.location.pathname === "/my-growth/articles").toString()}
                    to={"/my-growth/articles"}
                >
                    <FontSubtitle28 spaced={false}>Articles</FontSubtitle28>
                </Nav>
                <Bar /> */}
                <Nav
                    active={(this.props.location.pathname === "/my-growth/profile").toString()}
                    to={"/my-growth/profile"}
                >
                    <FontSubtitle28 spaced={false}>Matching Criteria</FontSubtitle28>
                </Nav>
                <Bar />
                <Nav
                    active={(this.props.location.pathname === "/my-growth/marketing-profile").toString()}
                    to={"/my-growth/marketing-profile"}
                >
                    <FontSubtitle28 spaced={false}>Marketing Profile</FontSubtitle28>
                </Nav>
            </NavRow>
        )
    }
}

export default withRouter(FirmProspectsNav);