import React from 'react';
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {v4 as uuid4} from 'uuid';
import styled from "styled-components";
import validator from "validator";
import moment from "moment/moment";

/* Store */
import {setShopItemPortfolio} from "../store/shopitems";

/* Components */
import {DropdownInput, TextInput, TextQuillInputControlCount, CheckboxWithLabel} from "../../../common/components/inputs";
import {colors} from "../../../common/components/colors";
import {FontTitle18, FontBody16} from "../../../common/components/fonts";
import {InlineBlock, SaveSection, SubmitInputWithValidation} from "../../settings/scheduler/components/allservicespage";
import {ButtonTertiary} from "../../../common/components/buttons";

/* Containers */
import PhotoUploadSection from '../../containers/photoupload';

/* Middleware */
import {tryUpdateExpertProfile} from '../middleware/expertprofile';
import {tryPostImage} from '../../../common/containers/imageupload/middleware/images';

export const Width = styled.div`
    max-width: 536px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
`;
const TitleMargin = styled.div`
    margin-top: -15px;
`;
const Gray = styled.div`
    color: ${colors.secondary100};
`;
const Red = styled.div`
    color: ${colors.alert100};
`;
const InlineWithMargin = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 250px;
    margin-right: 15px;
    margin-top: -35px;
`;
const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;

const monthOptions = [
    {value: "00", label: 'Month'},
    {value: "01", label: 'January'},
    {value: "02", label: 'February'},
    {value: "03", label: 'March'},
    {value: "04", label: 'April'},
    {value: "05", label: 'May'},
    {value: "06", label: 'June'},
    {value: "07", label: 'July'},
    {value: "08", label: 'August'},
    {value: "09", label: 'September'},
    {value: "10", label: 'October'},
    {value: "11", label: 'November'},
    {value: "12", label: 'December'}
];
const generateRange = (min, max, step) => {
    let arr = [];
    for(let i = min; i <= max; i += step) {
        arr.push({
            "value": i.toString(),
            "label": i.toString()
        });
    }
    return arr;
};

const years = generateRange(1950, parseInt(moment().format("yyyy")), 1);
const yearOptions = years.sort((a, b) => (a.value < b.value) ? 1 : -1);
yearOptions.unshift({"value": "00", "label": "Year"});

const setMonth = (num) => {
    if(num === null) {return("00")}
    else if(num === 1) {return("01")}
    else if(num === 2) {return("02")}
    else if(num === 3) {return("03")}
    else if(num === 4) {return("04")}
    else if(num === 5) {return("05")}
    else if(num === 6) {return("06")}
    else if(num === 7) {return("07")}
    else if(num === 8) {return("08")}
    else if(num === 9) {return("09")}
    else if(num === 10) {return("10")}
    else if(num === 11) {return("11")}
    else if(num === 12) {return("12")}
};

class OnePortfolio extends React.Component {
    state={
        "id": uuid4(),
        "title": "", "titleValid": true,
        "body": "", "bodyValid": true, "bodyCount": 0, "bodyComponent": "",
        "isSaving": false,
        "isSaved": false,
        "error": '',
        "isValid": true,
        "isChanged": false,
        "endAfterStart": false,
        "endOngoingChecked": false,
        "startMonth": "00",
        "startYear": "00",
        "endMonth": "00",
        "endYear": "00",
        "photo": null,
        "photoFile": null,
        "photoBlob": null,
        "photoChanged": false
    };

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    init = async () => {
        if(Object.keys(this.props.portfolio.toJS()).length > 0) {
            await this.setState({
                "id": this.props.portfolio.get("id"),
                "photo": this.props.portfolio.get("photo"),
                "startMonth": await setMonth(this.props.portfolio.get("startMonth")),
                "startYear": this.props.portfolio.get("startYear") === null ? "00" : this.props.portfolio.get("startYear"),
                "endMonth": await setMonth(this.props.portfolio.get("endMonth")),
                "endYear": this.props.portfolio.get("endYear") === null ? "00" : this.props.portfolio.get("endYear"),
                "endOngoingChecked": this.props.portfolio.get("endOngoing"),
                "title": this.props.portfolio.get("title") === null ? "" : this.props.portfolio.get("title"),
                "body": this.props.portfolio.get("body"),
                "bodyCount": this.props.portfolio.get("body").length,
            });
        }
        await this.updateBody();
        this.checkValid();
    };

    updateBody = () => {
        this.setState({
            bodyComponent: <TextQuillInputControlCount
                title={"Description*"}
                valid={this.state.bodyValid}
                warning={"Required"}
                id={"body"}
                onChange={this.handleChangeBody}
                placeholder={"Add a description"}
                value={this.state.body}
                maxLength={3000}
                count={this.state.bodyCount}
                minRows={8}
                required={true}
                countColorAbove={colors.white}
                countColorBelow={colors.white}
            />
        })
    };

    handleChangeBody = async e => {
        await this.setState({
            'body': e,
            'bodyValid': (!validator.isEmpty(e) && e !== '<p><br></p>' && e !== ''),
            'bodyCount': (validator.isEmpty(e) || e === '<p><br></p>' || e === '') ? 0 : e.replace( /(<([^>]+)>)/ig, '').length,
            'isChanged': true
        });
        await this.updateBody();
        this.checkValid();
    };

    handleChangeTitle = async e => {
        await this.setState({
            'title': e.target.value,
            'titleValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''),
            'isChanged': true
        });
        this.checkValid();
    };

    endMonth = async (e) => {
        await this.setState({
            "endMonth": e.value,
            'isChanged': true
        });
        this.checkValid();
    };

    endYear = async (e) => {
        await this.setState({
            "endYear": e.value,
            'isChanged': true
        });
        this.checkValid();
    };

    startMonth = async (e) => {
        await this.setState({
            "startMonth": e.value,
            'isChanged': true
        });
        this.checkValid();
    };

    startYear = async (e) => {
        await this.setState({
            "startYear": e.value,
            'isChanged': true
        });
        this.checkValid();
    };

    handleChangeSetEndCurrent = async (id, status) => {
        this.setState({
            "endMonth": "00",
            "endYear": "00",
            "endOngoingChecked": status,
            'isChanged': true
        });
        this.checkValid();
    };

    updatePhoto = async (photo) => {
        await this.setState({
            "photo": photo.photo,
            "photoFile": photo.photoFile,
            "photoBlob": photo.photoBlob,
            "photoChanged": true,
            'isChanged': true
        });
        console.log(this.state)
    };

    checkValid = () => {
        let titleValid = (!validator.isEmpty(this.state.title) && this.state.title !== '');
        let bodyValid = (!validator.isEmpty(this.state.body) && this.state.body !== '<p><br></p>' && this.state.body !== '');
        let startValid = !(this.state.startYear === "00" && this.state.startMonth !== "00");
        let endValid = !(this.state.endYear === "00" && this.state.endMonth !== "00");
        let startDate;
        if(this.state.startMonth !== "00" && this.state.startYear !== "00") {
            startDate = this.state.startYear+this.state.startMonth+"01"
        } else if(this.state.startYear !== "00") {
            startDate = this.state.startYear+"-01-01"
        }
        let endDate;
        if(this.state.endMonth !== "00" && this.state.endYear !== "00") {
            endDate = this.state.endYear+this.state.endMonth+"01"
        } else if(this.state.endYear !== "00") {
            endDate = this.state.endYear+"-01-01"
        }
        let startAfterEnd = moment(endDate).isBefore(moment(startDate));
        if(bodyValid && titleValid && startValid && endValid) {
            if(startAfterEnd) {
                this.setState({
                    'endAfterStart': true,
                    'isValid': false
                })
            } else {
                this.setState({
                    'endAfterStart': false,
                    'isValid': true
                })
            }
        } else {
            this.setState({'isValid': false})
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            this.setState({'isSaving': true});
            let photoUrl;
            if(this.state.photoChanged) {
                if(this.state.photoFile === "") {
                    photoUrl = null
                } else {
                    photoUrl = await this.props.tryPostImage(this.state.photoFile);
                }
            } else {
                photoUrl = this.props.portfolio.get("photo")
            }
            const data = {
                "id": this.state.id,
                "photo": photoUrl,
                "startMonth": this.state.startMonth === "00" ? null : parseInt(this.state.startMonth),
                "startYear": this.state.startYear === "00" ? null : parseInt(this.state.startYear),
                "endMonth": this.state.endMonth === "00" ? null : parseInt(this.state.endMonth),
                "endYear": this.state.endYear === "00" ? null : parseInt(this.state.endYear),
                "endOngoing": this.state.endOngoingChecked,
                "title": this.state.title,
                "body": this.state.body
            };
            let portfolios = this.props.profile.get("portfolio").toJS();
            if(this.props.location.pathname === "/"+(this.props.isWMC ? "profile" : "shop")+"/portfolio/create") {
                await portfolios.push(data);
            } else {
                portfolios = portfolios.map(p => {
                    if(p.id === this.state.id) {
                        return(data)
                    } else {
                        return(p)
                    }
                })
            }
            const saveData = {
                // "bio": this.props.profile.get("bio"),
                // "whyBecameCoach": this.props.profile.get("whyBecameCoach"),
                // "freeTime": this.props.profile.get("freeTime"),
                // "endorsement1": this.props.profile.get("endorsement1"),
                // "endorsement2": this.props.profile.get("endorsement2"),
                // "endorsement3": this.props.profile.get("endorsement3"),
                "portfolio": portfolios
            };
            console.log(saveData);
            await this.props.tryUpdateExpertProfile(saveData, "onePortfolio");
            this.setState({'isSaving': false, 'isSaved': true});
            this.timeout = setTimeout(() => {
                this.setState({'isSaved': false});
                this.props.setShopItemPortfolio({});
                this.props.history.push("/"+(this.props.isWMC ? "profile" : "shop")+"/portfolio");
            }, 3000);
        }
    };

    render() {
        if(this.state.isSaved) {
            if(this.state.error === "") {
                if(this.props.location.pathname === "/"+(this.props.isWMC ? "profile" : "shop")+"/portfolio/create") {
                    return(<FontTitle18>Created!</FontTitle18>)
                } else {
                    return(<FontTitle18>Saved!</FontTitle18>)
                }
            } else if(this.state.error !== '') {
                return(<FontTitle18>{this.state.error}</FontTitle18>)
            }
        } else {
            return(
                <Width>
                    <form method={"post"} onSubmit={this.handleSubmit}>
                        <TitleMargin>
                            <TextInput
                                title={"Title*"}
                                valid={this.state.titleValid}
                                warning={"Required"}
                                id={"title"}
                                onChange={this.handleChangeTitle}
                                placeholder={"Add a title"}
                                value={this.state.title}
                            />
                        </TitleMargin>
                        {this.state.bodyComponent}
                        <br />
                        <PhotoUploadSection
                            labelTitle={"Photo"}
                            labelAdd={"Add a photo"}
                            labelChange={"Change photo"}
                            labelRemove={"Remove photo"}
                            grayBackground={true}
                            photoMaxHeight={400}
                            action={this.updatePhoto}
                            photo={this.state.photo}
                            isUrl={!this.state.photoChanged}
                        />
                        {this.state.photo && <><br /><br /></>}
                        <br />
                        <br />
                        <FontBody16>
                            <Inline><Gray>Start Date</Gray></Inline>
                            {(this.state.startYear === "00" && this.state.startMonth !== "00")
                                ?
                                    <Inline><Red><FontBody16>&nbsp;&nbsp;Please enter a year</FontBody16></Red></Inline>
                                :
                                    <>
                                        {this.state.endAfterStart &&
                                            <Inline><Red><FontBody16>&nbsp;&nbsp;After end date</FontBody16></Red></Inline>
                                        }
                                    </>
                            }
                        </FontBody16>
                        <div>
                            <InlineWithMargin>
                                <DropdownInput
                                    title={null}
                                    options={monthOptions}
                                    id={"startMonth"}
                                    onChange={this.startMonth}
                                    placeholder={null}
                                    value={this.state.startMonth === null ? "00" : this.state.startMonth.toString()}
                                />
                            </InlineWithMargin>
                            <InlineWithMargin>
                                <DropdownInput
                                    title={null}
                                    options={yearOptions}
                                    id={"startYear"}
                                    onChange={this.startYear}
                                    placeholder={null}
                                    value={this.state.startYear === null ? "00" : this.state.startYear.toString()}
                                />
                            </InlineWithMargin>
                        </div>
                        <br />
                        <br />
                        <FontBody16>
                            <Inline><Gray>End Date</Gray></Inline>
                            {(this.state.endYear === "00" && this.state.endMonth !== "00") &&
                                <Inline><Red><FontBody16>&nbsp;&nbsp;Please enter a year</FontBody16></Red></Inline>
                            }
                        </FontBody16>
                        {!this.state.endOngoingChecked &&
                            <div>
                                <InlineWithMargin>
                                    <DropdownInput
                                        title={null}
                                        options={monthOptions}
                                        id={"endMonth"}
                                        onChange={this.endMonth}
                                        placeholder={null}
                                        value={this.state.endMonth === null ? "00" : this.state.endMonth.toString()}
                                    />
                                </InlineWithMargin>
                                <InlineWithMargin>
                                    <DropdownInput
                                        title={null}
                                        options={yearOptions}
                                        id={"endYear"}
                                        onChange={this.endYear}
                                        placeholder={null}
                                        value={this.state.endYear === null ? "00" : this.state.endYear.toString()}
                                    />
                                </InlineWithMargin>
                            </div>
                        }
                        <CheckboxWithLabel
                            action={this.handleChangeSetEndCurrent}
                            id={"endStillChecked"}
                            label={"I am currently working on this"}
                            checked={this.state.endOngoingChecked}
                        />
                        <SaveSection>
                            <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                            <InlineBlock><Link to={"/"+(this.props.isWMC ? "profile" : "shop")+"/portfolio"}><ButtonTertiary canSubmit={false} label={"Cancel"} /></Link></InlineBlock>
                        </SaveSection>
                    </form>
                </Width>
            )
        }
    }
}

const mapStateToProps = state => ({
    isWMC: state.common.wmc.get("isWMC"),
    portfolio: state.enterprise.shop.shopItems.get("portfolio"),
    profile: state.enterprise.shop.expertProfile.get("expertProfile"),
});

const mapDispatchToProps = dispatch => ({
    setShopItemPortfolio: (item) => dispatch(setShopItemPortfolio(item)),
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey)),
    tryPostImage: (file) => dispatch(tryPostImage(file))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OnePortfolio));
