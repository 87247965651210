import React from 'react';
import {connect} from "react-redux";

/* Containers */
import ListingsFlow from "../../../iframes/listings/containers/flow";

/* Components */
import {PreviewBar, Close} from '../components/preview';
import {CloseOutlined} from '@ant-design/icons';
import {FontHeader18} from '../../../common/components/fonts';
import {LogoLoading} from "../../../common/components/loading";

class PreviewServices extends React.Component {
    state={"isLoading": true, "storeTitle": "", "storeDescription": ""};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.updateStoreTitleDescription();
        if(this.props.profileFetched) {
            this.setState({"isLoading": false})
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.profileFetched && this.props.profileFetched) {
            this.setState({"isLoading": false});
        }
        if(prevProps.profile.get("storeTitle") !== this.props.profile.get("storeTitle") || prevProps.profile.get("storeDescription") !== this.props.profile.get("storeDescription")) {
            this.updateStoreTitleDescription();
        }
    }

    updateStoreTitleDescription = async () => {
        this.setState({
            "storeTitle": this.props.profile.get("storeTitle"),
            "storeDescription": this.props.profile.get("storeDescription"),
        })
    };

    render() {
        if(this.state.isLoading) {
            return(<LogoLoading />)
        } else {
            return(
                <PreviewBar isActive={this.props.preview} isSupport={this.props.user.get("isSupport")}>
                    <Close onClick={() => {return this.props.updatePreview(false)}}>
                        <FontHeader18><CloseOutlined />&nbsp;Preview</FontHeader18>
                    </Close>
                    <ListingsFlow
                        userId={this.props.profile.get("id")}
                        title={this.state.storeTitle}
                        description={this.state.storeDescription}
                        listingId={null}
                        isPreview={true}
                        forceImagesVertical={true}
                    />
                </PreviewBar>
            )
        }
    }
}

const mapStateToProps = state => ({
    profile: state.enterprise.services.expertProfile.get("expertProfile"),
    profileFetched: state.enterprise.services.expertProfile.get("expertProfileFetched"),
    user: state.common.user
});

export default connect(mapStateToProps, null)(PreviewServices);
