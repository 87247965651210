import React from "react";
import {connect} from "react-redux";
import styled from 'styled-components';

import {LogoLoading} from "../../../common/components/loading";
import {FontTitle18, FontHeader12, FontHeader21, FontHeader18, FontBody14} from "../../../common/components/fonts";

const StepTitle = styled.div`
    margin-bottom: -20px;
`;

class TeamNotes extends React.Component {
    state={"isLoading": true};

    componentDidMount() {
        return this.setup();
    }

    setup = async () => {
        await this.setState({
            "isLoading": false
        });
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(<StepTitle><FontTitle18>Notes</FontTitle18></StepTitle>)
        }
    }
}

export default TeamNotes;