import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';

/* Components */
import {FontBody24, FontTitle40, fontFamily} from "../../../common/components/fonts";
import {Checkbox} from "../../../common/components/inputs";
import {ButtonPrimary, ButtonInactive} from "../../../common/components/buttons";
import {colors} from "../../../common/components/colors";

/* Middleware */
import {tryPostHelp} from '../middleware/help';

const ButtonWide = styled.div`
  & > button > div {
    padding: 13px 65px;
    margin: 0 auto;
  }
  margin: 0 auto;
  text-align: center;
`;
const ButtonWideInactive = styled.div`
  & > button, & > div {
    padding: 13px 65px;
    margin: 0 auto;
  }
  margin: 0 auto;
  text-align: center;
`;
const TextArea = styled.textarea`
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.12);
    background-color: ${colors.white};
    margin: 40px 0 50px 0;
    border-radius: 30px;
    padding: 30px 40px;
    resize: none;
    font-family: ${fontFamily.body};
    font-size: 24px;
    line-height: 28px;
    min-height: 260px;
    border: none;
    width: calc(100% - 80px);
    @media screen and (max-width: 460px) {
        padding: 10px 15px;
        width: calc(100% - 30px);
    }
    &:focus {
        outline: 0;
    }
`;
const CheckboxRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin: 0 30px 80px 30px;
    justify-content: flex-start;
    align-items: center;
    & > label, & > label > span {
        height: 38px;
        width: 38px;
    }
    span::after {
      left: 15px !important;
      top: 5px !important;
      width: 6px !important;
      height: 20px !important;
    }
`;
const Section = styled.div`
  margin-top: 40px;
  overflow: visible;
  height: calc(100% - 132px);
`;
const ThankYou = styled(FontBody24)`
    margin-top: 80px;
    text-align: center;
`;

class HelpContactLeftBar extends React.Component {
    state = {submitted: false, message: '', questionType: '', urgent: false}

    handleChange = e => this.setState({message: e.target.value});
    handleChangeUrgent = (passToAction, question, e) => {this.setState({urgent: e})};
    handleSubmit = e => {
        e.preventDefault();
        if(this.state.message !== ''/* && this.state.questionType !== ''*/) {
            let urgentText = this.state.urgent ? 'URGENT ' : '';
            this.props.tryPostHelp(urgentText.concat(/*'CATEGORY: ', this.state.questionType, */"MESSAGE: ", this.state.message));
            this.setState({submitted: true, message: ''/*, questionType: ''*/});
        }
    };

    render() {
        return(
            <Section>
                <FontTitle40 spaced={false}>General Support</FontTitle40>
                <form method={"post"} onSubmit={this.handleSubmit}>
                    <TextArea
                        value={this.state.message}
                        onChange={this.handleChange}
                        placeholder={"How can we help?"}
                    />
                    <CheckboxRow>
                        <Checkbox action={this.handleChangeUrgent} passToAction={null} question={null} checked={this.state.urgent} id={"urgent"} />
                        <FontBody24>Urgent</FontBody24>
                    </CheckboxRow>
                    {this.state.message !== ""
                        ? <ButtonWide><ButtonPrimary canSubmit={true} label={"Send"} /></ButtonWide>
                        : <ButtonWideInactive><ButtonInactive canSubmit={false} label={"Send"} /></ButtonWideInactive>
                    }
                </form>
                {this.state.submitted && <ThankYou>Thanks for your message. Some one will be in contact with you soon!</ThankYou>}
            </Section>
        )
    }
}

const mapStateToProps = state => ({
    isWMC: state.common.wmc.get("isWMC"),
    platform: state.common.user.get("platform")
});
const mapDispatchToProps = dispatch => ({
    tryPostHelp: (message) => dispatch(tryPostHelp(message))
});
export default connect(mapStateToProps, mapDispatchToProps)(HelpContactLeftBar);
