import {images} from "../../../common/components/images";

export const elementTypes = [
    {"name": "Title", "image": images.contentVideoIcon, "id": 1,},
    {"name": "Subtitle", "image": images.contentVideoIcon, "id": 2},
    // {"name": "1 Column", "image": images.contentVideoIcon, "id": 3},
    // {"name": "2 Columns", "image": images.contentVideoIcon, "id": 4},
    {"name": "Image", "image": images.contentVideoIcon, "id": 5},
    {"name": "Video", "image": images.contentVideoIcon, "id": 6},
    {"name": "Disclosure", "image": images.contentVideoIcon, "id": 8},
    {"name": "Spacer", "image": images.contentVideoIcon, "id": 9},
    {"name": "Divider", "image": images.contentVideoIcon, "id": 10},
    {"name": "Border", "image": images.contentVideoIcon, "id": 11},
    {"name": "Dropdown", "image": images.contentVideoIcon, "id": 12},
    {"name": "Checkbox", "image": images.contentVideoIcon, "id": 13},
    // {"name": "Social", "image": images.contentVideoIcon, "id": 14},
]

export const articleElements = [
    {"name": "Header", "image": images.courseTitleIcon, "type": "title"},
    {"name": "Subheader", "image": images.courseSubtitleIcon, "type": "subtitle"},
    {"name": "Body", "image": images.courseBodyIcon, "type": "body"},
    {"name": "Disclosure", "image": images.courseDisclosureIcon, "type": "disclosure"},
    {"name": "Spacer", "image": images.courseSpacerIcon, "type": "spacer"},
    {"name": "Divider", "image": images.courseBorderIcon, "type": "border"},
    {"name": "Image", "image": images.courseImageIcon, "type": "image"},
    {"name": "Video", "image": images.courseVideoIcon, "type": "video"},
    {"name": "HTML", "image": images.courseHTMLIcon, "type": "html"},
    // {"name": "Social", "image": images.contentVideoIcon, "id": 9},
]

export const quizElements = [
    {"name": "Selector", "image": images.courseMultipleChoiceIcon, "type": "multiSelect"},
    {"name": "Text Input", "image": images.courseOpenResponseIcon, "type": "textArea"},
    {"name": "Scale slider", "image": images.courseSliderIcon, "type": "slider"},
    {"name": "Calendar", "image": images.courseCalendarIcon, "type": "calendar"},   
    {"name": "Header", "image": images.courseTitleIcon, "type": "title"},
    {"name": "Body", "image": images.courseBodyIcon, "type": "body"},
    {"name": "Image", "image": images.courseImageIcon, "type": "image"},
    {"name": "Video", "image": images.courseVideoIcon, "type": "video"}, 
]