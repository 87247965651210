import React from 'react';
import styled from "styled-components";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

/* Containers */
import ProgramsFlow from "../../../../iframes/programs/containers/flow";

/* Components */
import {LogoLoading} from "../../../../common/components/loading";
import {colors} from "../../../../common/components/colors";
import {FontBody18, FontBody24, FontSubtitle28} from "../../../../common/components/fonts";
import {images} from "../../../../common/components/images";

/* Middleware */
import {tryGetOneTask} from "../../tasks/middleware/tasks";
import {tryGetTrainingMapping} from '../middleware/trainings';
import {certificationValidation} from "../../certification/middleware/certificationValidation";

/* Domain */
// import getDomainEnv from '../../../../domains/utils';
// const env = getDomainEnv();

const LoadingCenter = styled.div`
    margin: 60px auto;
`;
const Background = styled.div`    
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.white};
    z-index: 1;
`;
const Foreground = styled.div`    
    position: relative;
    z-index: 2;
`;
const Back = styled.div`
  width: fit-content;
  color: ${colors.primary100};
  cursor: pointer;
`;
const TopCheckout = styled.div`
    margin: 50px 0;
`;
const Total = styled(FontBody18)`
    margin-top: 10px;
`;
const Padding = styled.div`
    padding-top: 90px;
    padding-left: 60px;
`;
const Margin = styled.div`
    ${props => props.shouldAddMargin !== true} {
        margin: 0 auto;
        width: fit-content;
    }
`;

class FirmTrainingsRecord extends React.Component {
    state = {"isLoading": true, "programId": null, "shouldAllowToTakeCert": true, "openToCheckout": false, "price": 0, "courseId": null, "isCourseCompleted": null, "isCourseAssigned": null, "courseData": null}

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        let path = window.location.pathname;
        let pathAdj = path.charAt(path.length-1) === "/" ? path.slice(0, -1) : path;
        let pathSplit = pathAdj.split("/");
        let programId;
        let openToCheckout;
        if(!this.props.location.pathname.includes("checkout")) {
            programId = pathSplit[pathSplit.length-1];
            openToCheckout = false;
        } else {
            programId = pathSplit[pathSplit.length-2];
            openToCheckout = true;
        }
        const mapping = await this.props.tryGetTrainingMapping();
        const courseId = mapping.matchProgramAndVideo[programId.toString()] === undefined ? null : mapping.matchProgramAndVideo[programId.toString()]
        let isCourseCompleted = null;
        let isCourseAssigned = null;
        let courseData = null;
        let quizId;
        let quizData
        let isQuizCompleted = null;
        if(courseId !== null) {
            courseData = await this.props.tryGetOneTask(courseId);
            isCourseCompleted = courseData.isCompleted;
            isCourseAssigned = courseData.courseAssignmentId !== undefined && courseData.courseAssignmentId !== null;
            quizId = mapping.matchVideoAndQuiz[courseId.toString()] === undefined ? null : mapping.matchVideoAndQuiz[courseId.toString()];
            if(quizId !== null) {
                quizData = await this.props.tryGetOneTask(quizId);
                isQuizCompleted = quizData.isCompleted;
            }
        }
        const validation = await this.props.certificationValidation();
        let shouldAllowToTakeCert = true;
        if(
            validation.currentProgress !== null &&
            (
                (parseInt(programId) === 64 && !validation.currentProgress?.certificationDefinition.designation.includes("CAW")) ||
                (
                    parseInt(programId) === 77 &&
                    (
                        !validation.currentProgress?.certificationDefinition.designation.includes("CAW") ||
                        !validation.currentProgress?.step1Pieces.mainTrainingCompletedWomen
                    )
                ) ||
                (parseInt(programId) === 56 && !validation.currentProgress?.certificationDefinition.designation.includes("CAN")) ||
                (
                    parseInt(programId) === 75 &&
                    (
                        !validation.currentProgress?.certificationDefinition.designation.includes("CAN") ||
                        !validation.currentProgress?.step1Pieces.mainTrainingCompletedNextGen
                    )
                )
            )
        ) {
            shouldAllowToTakeCert = false;
        }
        await this.setState({
            "shouldAllowToTakeCert": shouldAllowToTakeCert,
            "openToCheckout": openToCheckout,
            "programId": programId,
            "courseId": courseId,
            "quizId": quizId,
            "isQuizCompleted": isQuizCompleted,
            "courseData": courseData,
            "isCourseCompleted": isCourseCompleted,
            "isCourseAssigned": isCourseAssigned,
            "isLoading": false
        })
    }

    // onBackToCart = async () => {
    //     this.props.history.push("/my-trainings/"+this.state.programId)
    // }

    onTryRegister = async (price) => {
        this.props.history.push("/my-certificates/my-trainings/"+this.state.programId+"/register")
    }

    savePricing = async (programDetails) => {
        this.setState({"price": programDetails.price})
    }

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <Foreground>
                        {/*{this.props.location.pathname === "/my-trainings/"+this.state.programId+"/checkout" &&*/}
                        {/*    <Padding>*/}
                        {/*        <FontBody24><Back onClick={this.onBackToCart}><img src={images.chevronLeft} alt={"<"} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Back</Back></FontBody24>*/}
                        {/*        <TopCheckout>*/}
                        {/*            <FontSubtitle28 spaced={true}>CHECKOUT TRAINING</FontSubtitle28>*/}
                        {/*            <Total>Total: ${(this.state.price/100).toFixed(0)}</Total>*/}
                        {/*        </TopCheckout>*/}
                        {/*    </Padding>*/}
                        {/*}*/}
                        {/*<Margin shouldAddMargin={this.props.location.pathname === "/my-certificates/my-trainings/"+this.state.programId+"/checkout"}>*/}
                            <ProgramsFlow
                                entryPoint={"advisor"}
                                programId={this.state.programId}
                                openToCheckout={this.state.openToCheckout}
                                urlList={"/my-certificates/my-trainings"}
                                urlCourse={this.state.courseId === null ? null : "/my-tasks/course/"+this.state.courseId+"/video"}
                                urlQuiz={this.state.quizId === null ? null : "/my-tasks/course/"+this.state.quizId+"/quiz"}
                                isQuizCompleted={this.state.isQuizCompleted}
                                isCourseCompleted={this.state.isCourseCompleted}
                                isCourseAssigned={this.state.isCourseAssigned}
                                courseData={this.state.courseData}
                                urlRequest={"/my-certificates/my-trainings/"+this.state.programId+"/requested"}
                                urlDetails={"/my-certificates/my-trainings/"+this.state.programId}
                                urlCheckout={"/my-certificates/my-trainings/"+this.state.programId+"/checkout"}
                                urlRegistrationComplete={"/my-certificates/my-trainings/"+this.state.programId+"/registration-completed"}
                                urlRegistrationCompleteClose={"/my-certificates/my-trainings"}
                                onInitProductData={this.savePricing}
                                onTryRegisterIsCustom={true}
                                onTryRegister={this.onTryRegister}
                                shouldAllowToTakeCert={this.state.shouldAllowToTakeCert}
                            />
                        {/*</Margin>*/}
                    </Foreground>
                    <Background />
                </>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryGetOneTask: (courseId) => dispatch(tryGetOneTask(courseId)),
    tryGetTrainingMapping: () => dispatch(tryGetTrainingMapping()),
    certificationValidation: () => dispatch(certificationValidation()),
})

export default connect(null, mapDispatchToProps)(withRouter(FirmTrainingsRecord));