import React from 'react';
import {connect} from 'react-redux';
import styled from "styled-components";
import {withRouter} from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import {CheckCircleOutlined, CalendarOutlined} from '@ant-design/icons';

/* Middleware */
import {tryGetProgramToken, tryPostCheckoutData} from '../middleware/program';

/* Common */
import {images} from "../../../common/components/images";
import {FontBody16, FontTitle21, FontBody50, FontHeader18, FontBody12, FontSubtitle38To30, FontBody21} from "../../../common/components/fonts";
import {Toggle2Lables} from "../../../common/components/inputs";
import {colors} from "../../../common/components/colors";
import {throwError} from "../../../common/utils/error";

const Logo = styled.div`
    height: 40px;
    padding: 15px 70px 15px 50px;
    text-align: center;
    background-color: ${colors.white};
    border-bottom: 10px solid ${colors.border100};
`;
const Room = styled.div`
    height: 100%;
    width: 100%;
`;
const RoomContent = styled.div`
    width: 100%;
    @media screen and (max-width: 1088px) {
        padding-bottom: 80px;
    }
    @media screen and (max-width: 748px) {
        height: calc(100% - 165px);
    }
    @media screen and (max-width: 460px) {
        padding-bottom: 0px;
    }
`;
const RoomContentContainer = styled.div`
    margin: 20px auto 120px auto;
    padding: 00px 20px 0px 20px;
    @media screen and (max-width: 748px) {
        padding: 0px 20px 0px 20px;
        width: calc(100% - 40px);
    }
`;

const Modal = styled.div`
    ${props => props.popular === true} {
        margin: 30px 10px 0px 10px;
        border: 1px solid ${colors.border100};
    }
    ${props => props.popular === false} {
        margin: 30px 10px 0px 10px;
        border: 2px solid ${colors.border100};
    }
    vertical-align: top;
    background-color: ${colors.white};
    border-radius: 4px;
    color: ${colors.primary100};
    width: 310px;
    position: relative;
    @media screen and (max-width: 1250px) {
        width: 250px;
    }
    @media screen and (max-width: 1088px) {
        display: block;
        margin: 30px auto 0px auto;
        width: 410px;
    }
    @media screen and (max-width: 748px) {
        margin: 30px auto 0px auto;
        width: 310px;
    }
    @media screen and (max-width: 460px) {
        margin: 20px auto;
        width: 240px;
    }
    text-align: left;
    display: inline-block;
`;
const TitleBox1 = styled.div`
    width: calc(100% - 60px);
    padding: 15px 30px;
    text-align: center;
    background-color: ${colors.primary100};
    color: ${colors.white};
    position: relative;
`;
const TitleBox2 = styled.div`
    width: calc(100% - 60px);
    padding: 15px 30px;
    text-align: center;
    background-color: ${colors.secondary100};
    color: ${colors.white};
    position: relative;
`;
const TitleBox3 = styled.div`
    width: calc(100% - 60px);
    padding: 15px 30px;
    text-align: center;
    background-color: ${colors.primary70};
    color: ${colors.white};
    position: relative;
`;
const ButtonBox = styled.div`
    background-color: ${colors.border30};
    padding: 5px 0px 20px 0px;
`;
const BodyBox = styled.div`
    background-color: ${colors.border30};
    padding: 0px 10px;
    @media screen and (max-width: 460px) {
        padding: 0px 20px;
    }
`;
const Price = styled.div`
    text-align: center;
    padding: 30px 30px 10px 30px;
    color: ${colors.primary100};
    background-color: ${colors.border30};
`;
const Top = styled.div`
    vertical-align: top;
    display: inline-block;
    margin-top: 7px;
    margin-right: 4px;
`;
const NoteTitle = styled.div`
    color: ${colors.primary100};
    text-align: center;
`;
const Subtitle = styled.div`
    margin-top: 5px;
    margin-bottom: 10px;
`;
const PackageButton = styled.div`
    cursor: pointer;
    ${props => props.popular === false} {
        background-color: ${colors.action100};
        margin: 0px auto;
        padding: 14px 30px;
    }
    ${props => props.popular === true} {
        background-color: ${colors.action100};
        margin: 0px auto;
        padding: 8px 30px;
    }
    &:hover {
        background-color: ${colors.action100};
    }
    border: 1px solid ${colors.action100};
    color: ${colors.white};
    border-radius: 4px;
    vertical-align: top;
    width: fit-content;
    text-align: center;
`;
const Options = styled.div`
    text-align: center;
`;
const Popular = styled.div`
    color: ${colors.action100};
    margin-bottom: 10px;
`;
const PriceBorder = styled.div`
    width: 70px;
    border-bottom: 1px solid ${colors.action100};
    height: 1px;
    margin-bottom: 20px;
    padding-bottom: 10px;
`;
const DetailsBorder = styled.div`
    width: calc(100% - 40px);
    border-bottom: 1px solid ${colors.primary30};
    height: 1px;
    margin: 0px 20px;
`;
const Detail = styled.div`
    padding: 15px 10px;
    text-align: center;
`;
const Inline = styled.div`
    display: inline-block;
`;
const Icon = styled.div`
    display: inline-block;
    margin-right: 10px;
    color: ${colors.action100};
`;
const LineSpace = styled.div`
    margin-bottom: 3px;
`;
const Discount = styled.div`
    color: ${colors.alert100};    
`;

class PackageRoom extends React.Component {
    constructor(props) {
        super(props);
        this.state={"isLoading": true, "error": false, "token": null, "payMonthly": true}
    }

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const token = await this.props.location.pathname.split('/')[2];
        const tokenInfo = await this.props.tryGetProgramToken(token);
        this.setState({"token": token, "error": tokenInfo.didError, "isLoading": false});
    };

    changeToggle = () => this.setState({"payMonthly": !this.state.payMonthly});

    submit = async (e) => {
        await this.props.tryPostCheckoutData(this.state.token, e);
        const stripePromise = loadStripe(this.props.checkout.get("stripeApiPublishableKey"));
        const stripe = await stripePromise;
        stripe.redirectToCheckout({
            // Make the id field from the Checkout Session creation API response
            // available to this file, so you can provide it as parameter here
            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
            sessionId: this.props.checkout.get("stripeCheckoutSessionId")
        }).then(function (result) {
            return throwError(result);
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        });
    };

    render() {
        return (
            <Room>
                <RoomContent>
                    <Logo><img src={images.logo} height="40" width="200" alt={""}/></Logo>
                    <RoomContentContainer>
                        {this.state.isLoading
                            ?
                                <Modal>
                                    <FontTitle21>Loading!</FontTitle21>
                                </Modal>
                            :
                                <>
                                    {(this.state.token === null || this.state.error === true)
                                        ?
                                            <Modal>
                                                <FontTitle21>No token</FontTitle21>
                                            </Modal>
                                        :
                                            <>
                                                <NoteTitle>
                                                    {/*<FontSubtitle38To30>Packages</FontSubtitle38To30>*/}
                                                    <Subtitle><FontBody16>Choose one of three options:</FontBody16></Subtitle>
                                                    <Toggle2Lables leftLabel={"Monthly Billing"} rightLabel={"Annual billing"} id={"toggle"} onChange={this.changeToggle} value={!this.state.payMonthly} offColor={colors.primary70} onColor={colors.action100} />
                                                </NoteTitle>
                                                <Options>
                                                    <Modal popular={false}>
                                                        <TitleBox1>
                                                            <FontBody21><b>CONCIERGE</b></FontBody21>
                                                            <FontBody21><b>PROGRAM</b></FontBody21>
                                                        </TitleBox1>
                                                        <Price>
                                                            {this.state.payMonthly
                                                                ? <>
                                                                    <Top><FontBody16>$</FontBody16></Top>
                                                                    <Inline><FontBody50><b>165</b></FontBody50></Inline>
                                                                    <Inline><FontBody16>/month</FontBody16></Inline>
                                                                    <Discount><FontBody12>44% DISCOUNT</FontBody12></Discount>
                                                                </>
                                                                : <>
                                                                    <Top><FontBody16>$</FontBody16></Top>
                                                                    <Inline><FontBody50><b>1,895</b></FontBody50></Inline>
                                                                </>
                                                            }
                                                        </Price>
                                                        <BodyBox>
                                                            <Detail>
                                                                <Icon><CheckCircleOutlined /></Icon>
                                                                <Inline><FontBody12>15-minute complimentary coaching call</FontBody12></Inline>
                                                            </Detail>
                                                            <DetailsBorder />
                                                            <Detail>
                                                                <Icon><CheckCircleOutlined /></Icon>
                                                                <Inline><FontBody12>1 kickoff call (45 mins)</FontBody12></Inline>
                                                            </Detail>
                                                            <DetailsBorder />
                                                            <Detail>
                                                                <Icon><CheckCircleOutlined /></Icon>
                                                                <Inline><FontBody12>14 follow-up sessions (45 mins)</FontBody12></Inline>
                                                            </Detail>
                                                            <DetailsBorder />
                                                            <Detail>
                                                                <Icon><CheckCircleOutlined /></Icon>
                                                                <Inline><FontBody12>Unlimited text support</FontBody12></Inline>
                                                            </Detail>
                                                            <DetailsBorder />
                                                            <Detail>
                                                                <div>
                                                                    <Icon><CalendarOutlined /></Icon>
                                                                    <Inline><FontBody12><b>Client favorite:</b></FontBody12></Inline>
                                                                </div>
                                                                <LineSpace/>
                                                                <FontBody12>1 session/month for 12 months +</FontBody12>
                                                                <LineSpace/>
                                                                <FontBody12>a mid-year and end-of-year session</FontBody12>
                                                            </Detail>
                                                            <DetailsBorder />
                                                            <Detail><FontBody12>*Monthly payment option available</FontBody12></Detail>
                                                        </BodyBox>
                                                        <ButtonBox>
                                                            <PackageButton onClick={() => this.submit(this.state.payMonthly ? "months_12_subscription" : "months_12")} popular={false}><FontBody16><b>Get Started</b></FontBody16></PackageButton>
                                                        </ButtonBox>
                                                    </Modal>
                                                    <Modal popular={false}>
                                                        {/*<Popular><FontBody16>Most Popular</FontBody16></Popular>*/}
                                                        <TitleBox2>
                                                            <FontBody21><b>MOMENTUM</b></FontBody21>
                                                            <FontBody21><b>PROGRAM</b></FontBody21>
                                                        </TitleBox2>
                                                        <Price>
                                                            {this.state.payMonthly
                                                                ? <>
                                                                    <Top><FontBody16>$</FontBody16></Top>
                                                                    <Inline><FontBody50><b>278</b></FontBody50></Inline>
                                                                    <Inline><FontBody16>/month</FontBody16></Inline>
                                                                    <Discount><FontBody12>6% DISCOUNT</FontBody12></Discount>
                                                                </>
                                                                : <>
                                                                    <Top><FontBody16>$</FontBody16></Top>
                                                                    <Inline><FontBody50><b>795</b></FontBody50></Inline>
                                                                </>
                                                            }
                                                        </Price>
                                                        <BodyBox>
                                                            <Detail>
                                                                <Icon><CheckCircleOutlined /></Icon>
                                                                <Inline><FontBody12>15-minute complimentary coaching call</FontBody12></Inline>
                                                            </Detail>
                                                            <DetailsBorder />
                                                            <Detail>
                                                                <Icon><CheckCircleOutlined /></Icon>
                                                                <Inline><FontBody12>1 kickoff call (45 mins)</FontBody12></Inline>
                                                            </Detail>
                                                            <DetailsBorder />
                                                            <Detail>
                                                                <Icon><CheckCircleOutlined /></Icon>
                                                                <Inline><FontBody12>5 follow-up sessions (45 mins)</FontBody12></Inline>
                                                            </Detail>
                                                            <DetailsBorder />
                                                            <Detail>
                                                                <Icon><CheckCircleOutlined /></Icon>
                                                                <Inline><FontBody12>Unlimited text support</FontBody12></Inline>
                                                            </Detail>
                                                            <DetailsBorder />
                                                            <Detail>
                                                                <div>
                                                                    <Icon><CalendarOutlined /></Icon>
                                                                    <Inline><FontBody12><b>Client favorites:</b></FontBody12></Inline>
                                                                </div>
                                                                <LineSpace/>
                                                                <FontBody12>1 session/month for 6 months</FontBody12>
                                                                <LineSpace/>
                                                                <FontBody12>OR</FontBody12>
                                                                <LineSpace/>
                                                                <FontBody12>2 sessions/month for 3 months</FontBody12>
                                                            </Detail>
                                                            <DetailsBorder />
                                                            <Detail><FontBody12>*Monthly payment option available</FontBody12></Detail>
                                                        </BodyBox>
                                                        <ButtonBox>
                                                            <PackageButton onClick={() => this.submit(this.state.payMonthly ? "months_3_subscription" : "months_3")} popular={false}><FontHeader18>Get Started</FontHeader18></PackageButton>
                                                        </ButtonBox>
                                                    </Modal>
                                                    <Modal popular={false}>
                                                        <TitleBox3>
                                                            <FontBody21><b>FINANCIAL</b></FontBody21>
                                                            <FontBody21><b>CHECKUP</b></FontBody21>
                                                        </TitleBox3>
                                                        <Price>
                                                            {this.state.payMonthly
                                                                ? <>
                                                                    <Top><FontBody16>$</FontBody16></Top>
                                                                    <Inline><FontBody50><b>295</b></FontBody50></Inline>
                                                                    <br />
                                                                    <br />
                                                                </>
                                                                : <>
                                                                    <Top><FontBody16>$</FontBody16></Top>
                                                                    <Inline><FontBody50><b>295</b></FontBody50></Inline>
                                                                </>
                                                            }
                                                        </Price>
                                                        <BodyBox>
                                                            <Detail>
                                                                <Icon><CheckCircleOutlined /></Icon>
                                                                <Inline><FontBody12>15-minute complimentary coaching call</FontBody12></Inline>
                                                            </Detail>
                                                            <DetailsBorder />
                                                            <Detail>
                                                                <Icon><CheckCircleOutlined /></Icon>
                                                                <Inline><FontBody12>1 kickoff call (45 mins)</FontBody12></Inline>
                                                            </Detail>
                                                            <DetailsBorder />
                                                            <Detail>
                                                                <Icon><CheckCircleOutlined /></Icon>
                                                                <Inline><FontBody12>1 follow-up session (45 mins)</FontBody12></Inline>
                                                            </Detail>
                                                            <DetailsBorder />
                                                            <Detail>
                                                                <Icon><CheckCircleOutlined /></Icon>
                                                                <Inline><FontBody12>Unlimited text support</FontBody12></Inline>
                                                            </Detail>
                                                        </BodyBox>
                                                        <ButtonBox>
                                                            <PackageButton onClick={() => this.submit("months_1")} popular={false}><FontBody16><b>Get Started</b></FontBody16></PackageButton>
                                                        </ButtonBox>
                                                    </Modal>
                                                </Options>
                                            </>
                                    }
                                </>
                        }
                    </RoomContentContainer>
                </RoomContent>
            </Room>
        )
    }
}
const mapStateToProps = state => ({
    checkout: state.iframe.checkoutWillow.program.get("programCheckoutData")
});
const mapDispatchToProps = dispatch => ({
    tryGetProgramToken: (token) => dispatch(tryGetProgramToken(token)),
    tryPostCheckoutData: (programToken, subscriptionPlan) => dispatch(tryPostCheckoutData(programToken, subscriptionPlan))
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PackageRoom));
