import React from 'react';
import styled from "styled-components";

/* Components */
import {Left, Right, ProfileImg, Name, Black, InlineBtn, CompanyName, Handle, HeroImgBackground, HeroVidBackground, HeroVid, FillContainer, HeaderSection, Intro, Tags, Tag, SocialIcon, CTAs, Inline, Icon16, Icon14, Spacing, HeroImg} from '../components/header';
import {FontTitle38, FontBody21, FontBody18, FontHeader16} from '../../../common/components/fonts';
import {ButtonPrimary, ButtonInactiveLight} from '../../../common/components/buttons';
import { AtSign, Rss, Globe, Linkedin, Facebook, Twitter, Instagram, Mail, Video, Youtube } from 'react-feather';

const Margin = styled.div`
    margin-bottom: 25px;
`;

class IframeStoreHeader extends React.Component {
    render() {
        let p = this.props.profile;
        return(
            <HeaderSection>
                <Left>
                    <ProfileImg src={p.get("photo")} alt={""} />
                    <Name><FontTitle38>{p.get("firstName")+" "+p.get("lastName")}</FontTitle38><Margin /></Name>
                    {p.get("companyName") !== "" && <CompanyName><FontBody21>{p.get("companyName")}</FontBody21></CompanyName>}
                    <Handle><FontHeader16><Icon14><AtSign /></Icon14>{p.get("publicHandle")}</FontHeader16></Handle>
                    <Intro><FontBody18>{p.get("highlights")}</FontBody18></Intro>
                    <Tags>
                        {(p.get("website") !== "" && p.get("website") !== null) && <Tag><a target={"_blank"} href={p.get("website")}><SocialIcon><Globe /></SocialIcon></a></Tag>}
                        {(p.get("blogUrl") !== "" && p.get("blogUrl") !== null) && <Tag><a target={"_blank"} href={p.get("blogUrl")}><SocialIcon><Rss /></SocialIcon></a></Tag>}
                        {(p.get("instagramUrl") !== "" && p.get("instagramUrl") !== null) && <Tag><a target={"_blank"} href={p.get("instagramUrl")}><SocialIcon><Instagram /></SocialIcon></a></Tag>}
                        {(p.get("facebookUrl") !== "" && p.get("facebookUrl") !== null) && <Tag><a target={"_blank"} href={p.get("facebookUrl")}><SocialIcon><Facebook /></SocialIcon></a></Tag>}
                        {(p.get("twitterUrl") !== "" && p.get("twitterUrl") !== null) && <Tag><a target={"_blank"} href={p.get("twitterUrl")}><SocialIcon><Twitter /></SocialIcon></a></Tag>}
                        {(p.get("linkedinUrl") !== "" && p.get("linkedinUrl") !== null) && <Tag><a target={"_blank"} href={p.get("linkedinUrl")}><SocialIcon><Linkedin /></SocialIcon></a></Tag>}
                        {(p.get("youtubeUrl") !== "" && p.get("youtubeUrl") !== null) && <Tag><a target={"_blank"} href={p.get("youtubeUrl")}><SocialIcon><Youtube /></SocialIcon></a></Tag>}
                    </Tags>
                    {(p.get("profileShowEmailer") || p.get("profileShowScheduler")) &&
                        <CTAs>
                            {p.get("profileShowEmailer") &&
                                <InlineBtn onClick={() => {return this.props.changeShowEmailer(true)}}>
                                    {p.get("profileShowScheduler")
                                        ? <ButtonInactiveLight submit={false} label={<><Icon16><Mail /></Icon16><Inline>&nbsp;&nbsp;Message</Inline></>} />
                                        : <ButtonPrimary submit={false} label={<><Icon16><Mail /></Icon16><Inline>&nbsp;&nbsp;Message</Inline></>} />
                                    }
                                </InlineBtn>
                            }
                            {(p.get("profileShowEmailer") && p.get("profileShowScheduler")) &&
                                <Inline><Spacing/></Inline>
                            }
                            {p.get("profileShowScheduler") &&
                                <InlineBtn onClick={() => {return this.props.changeShowScheduler(true)}}><ButtonPrimary submit={false} label={<><Icon16><Video /></Icon16><Inline>&nbsp;&nbsp;Schedule Meeting</Inline></>} /></InlineBtn>
                            }
                        </CTAs>
                    }
                </Left>
                <Right>
                    <FillContainer>
                        {(p.get("heroImageUrl") !== "" && p.get("heroImageUrl") !== null) &&
                            <HeroImgBackground imgObj={p.get("heroImageUrl")} />
                        }
                        {(p.get("introVideoUrl") !== "" && p.get("introVideoUrl") !== null) &&
                            <HeroVidBackground src={p.get("introVideoUrl")} />
                        }
                        <Black />
                    </FillContainer>
                    {(p.get("heroImageUrl") !== "" && p.get("heroImageUrl") !== null) &&
                        <HeroImg src={p.get("heroImageUrl")} alt={""} />
                    }
                    {(p.get("introVideoUrl") !== "" && p.get("introVideoUrl") !== null) &&
                        <HeroVid src={p.get("introVideoUrl")} />
                    }
                </Right>
            </HeaderSection>
        )
    }
}

export default IframeStoreHeader;