import React from 'react';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';

/* Components */
import { colors } from '../../../common/components/colors';
import { FontBody14 } from '../../../common/components/fonts';

/* Domain */
import getDomainEnv from '../../../domains/utils';
import {Main} from "../../../enterprise/navbars/components/primaryside";
import {NavPage} from "../../../enterprise/navbars/components/primarymobile";
const env = getDomainEnv();

const NavBottom = styled.div`
    display: none;
    @media screen and (max-width: 1088px) {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        -webkit-box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.1);
        box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.1);
        height: 49px;
        width: calc(100% - 40px);
        padding: 13px 20px 5px 20px;
        z-index: 3;
        text-align: center;
        position: fixed;
        bottom: 0;
        right: 0;
        border-radius: 20px 20px 0 0;
        background-color: ${colors.white};
    }
    @media screen and (max-width: 460px) {
        width: 100%;
        padding: 13px 0 5px 0;
    }
`;
const ButtonMobile = styled.div`
    height: 30px;
    display: none;
    cursor: pointer;
    text-align: center;
    ${props => props.status !== 'active'} {color: ${colors.primary100};}
    ${props => props.status !== 'inactive'} {color: ${colors.primary100};}
    @media screen and (max-width: 1088px) {
        display: inline-block;
        // width: 20%;
        & div {
            font-size: 16px;
            line-height: 16px;
        }
    }
    @media screen and (max-width: 460px) {
        // width: 24%;
        & div {
            font-size: 14px;
            line-height: 14px;
        }
    }
`;
const Icon = styled.div`
    height: 30px;
    span {
        font-size: 24px;
        padding: 2px 0;
        display: block;
        vertical-align: top;
        margin: 0 auto;
    }
`;

const IconImage = styled.img`
    width: 40px;
    height: 30px;
`;

const MobileIconOrImage = ({data, pathname}) => {
    if(pathname.includes(data.href)) {
        return(<IconImage src={data.navBottomIconActive} alt={""} />)
    } else {
        return(<IconImage src={data.navBottomIconInactive} alt={""} />)
    }
};

class ConsumerNavBottom extends React.Component {
    render() {
        return(
            <NavPage>
                <>
                {env.CONSUMER.NAV.nav.map((navSection, navSectionIndex) => (
                    <Main key={navSectionIndex}>
                        {navSection.map((d, index) => {
                            let status = "inactive";
                            if (this.props.location.pathname.split("/")[1] === d.href.split("/")[1]) {status = "active"}
                            if(d.showMobile) {
                                return(
                                    <Link key={index} to={d.href}>
                                        <ButtonMobile status={status}>
                                            <Icon>
                                                <MobileIconOrImage data={d} pathname={this.props.location.pathname}  />
                                            </Icon>
                                            {/*<FontBody14>{d.label}</FontBody14>*/}
                                        </ButtonMobile>
                                    </Link>
                                )
                            }
                        })}
                    </Main>
                ))}
                </>
            </NavPage>
        )
    }
}


export default withRouter(ConsumerNavBottom);
