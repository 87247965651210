import localhostEnv from './localhost/env';

const getDomainEnv = () => {
    const hostname = window.location.hostname;
    let domain;
    let env;
    if(hostname === "localhost") {
        domain = localhostEnv;
        env = 'env.dev'
    } else {
        const hostnameArray = hostname.split(".");
        hostnameArray.shift();
        domain = hostnameArray.join(".");
        env = 'env'
    }
    return require('./'+domain+'/'+env).default;
};

export default getDomainEnv;