import { combineReducers } from 'redux';

/* Reducers */
import commonReducer from './common/reducers';
// import coachReducer from './coach/store/reducers';
import consumerReducer from './consumer/reducers';
import enterpriseReducer from './enterprise/store/reducers';
import iframeReducer from './iframes/reducers';

const rootReducer = combineReducers({
  common: commonReducer,
  // coach: coachReducer,
  consumer: consumerReducer,
  enterprise: enterpriseReducer,
  iframe: iframeReducer
});

export default rootReducer;
