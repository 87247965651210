import React from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import validator from "validator";
import {Map, List} from 'immutable';

/* Components */
import { colors } from "../../../components/colors";
import {FontHeader21, FontHeader16, FontTitle18, FontBody16} from "../../../components/fonts";
import {CloseOutlined} from '@ant-design/icons';
import {SearchInputUncontrolledDisplay} from "../../../components/inputs";
import {InlineBlock, SaveSection, SubmitInputWithValidation} from "../../../../enterprise/components/clientscreate";
import {ButtonTertiary} from "../../../components/buttons";

/* Middleware */
import { tryRemoveUser, tryGetSharedDriveList } from '../middleware/filevault';

/* Store */
import {setDriveRemoveUserModal, setSharedDrive} from "../store/filevault";

/* Icons */
import {images} from '../../../components/images';

const Modal = styled.div`
    position: relative;
    margin: 0px auto;
    top: 110px;
    z-index: 10;
    background-color: ${colors.white};
    border: 1px solid ${colors.border100};
    border-radius: 6px;
    width: 100%;
    height: auto;
    max-width: 550px;
    @media screen and (max-width: 640px) {
        top: 80px;
        width: calc(100% - 20px);
    }
`;
const Fixed = styled.div`
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 8;
`;
const Background = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9;
`;
const Title = styled.div`
    padding: 25px;
`;
const Inline = styled.div`
    width: calc(100% - 77px);
    display: inline-block;
    vertical-align: top;
`;
const Image = styled.img`
    width: 32px;
    margin-right: 20px;
    vertical-align: top;
    display: inline-block;
`;
const Close = styled.div`
    width: 25px;
    vertical-align: top;
    display: inline-block;
    color: ${colors.secondary100};
    cursor: pointer;
`;
const Form = styled.form`
    padding: 0px 25px 25px 25px;
`;
const Success = styled.div`
    padding: 40px 25px;
    text-align: center;
`;

const whoOptions = (who, isWMC) => {
    return who.map(w => {
        return({
            contactType: (w.get("prospectId") === null && w.get("businessUserId") === null) ? "Contact" : (w.get("prospectStatus") !== null ? w.get("prospectStatus") : "Team Member"),
            value: {
                consumerId: w.get("consumerId"),
                prospectId: w.get("prospectId"),
                contactId: w.get("contactId"),
                businessUserId: w.get("businessUserId")
            },
            label: (w.get("prospectId") === null && w.get("businessUserId") === null) ? w.get("firstName") + " " + w.get("lastName") 
                : 
                ((!isWMC && w.get("referralSource") === "wmc") ? 
                    w.get("firstName") + " " + w.get("lastName").charAt(0) + "."
                    : 
                    w.get("firstName") + " " + w.get("lastName")),
            isArchived: w.get("isArchived")
        })
    }).toJS();
};

const initState = {"driveRemoveUserModal": false, "sharedDrive": Map(), "who": '', "whoObject": '', "whoLabel": '', "isValid": true, "isChanged": false, "isSaved": false, "isSaving": false};

class VaultRemoveUser extends React.Component {
    state=initState;

    componentDidMount() {
        this.setState({"driveRemoveUserModal": this.props.driveRemoveUserModal})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.driveRemoveUserModal !== this.props.driveRemoveUserModal && this.props.driveRemoveUserModal) {
            this.setState({
                "driveRemoveUserModal": this.props.driveRemoveUserModal,
                "sharedDrive": this.props.sharedDrive,
                "contactsAll": this.props.contactsAll,
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    timeout = 0;

    close = async () => {
        await this.props.setDriveRemoveUserModal(false);
        await this.setState(initState);
        this.props.setSharedDrive(Map());
        await this.props.tryGetSharedDriveList();
    };

    handleDeleteWho = async () => {
        this.setState({'whoObject': {}, "who": "", 'whoLabel': "", 'isChanged': false})
    };

    handleResetSearchContents = async () => {
        let searchProspectsAddWho;
        if(this.state.who !== '') {
            if(this.state.whoObject.contactType === "Team Member") {
                searchProspectsAddWho = await this.state.contactsAll.filter(s => {return(s.get("businessUserId") === this.state.who)}).get(0);
            } else {
                searchProspectsAddWho = await this.state.contactsAll.filter(s => {return(s.get("consumerId") === this.state.who)}).get(0);
            }
            this.setState({'contactsAll': this.state.contactsAll.push(searchProspectsAddWho)})
        }
    };

    handleChangeWho = async (e) => {
        await this.handleResetSearchContents();
        if(e === null) {
            await this.handleDeleteWho();
        } else {
            if(e.contactType === "Team Member") {
                await this.setState({
                    'whoObject': e,
                    "who": e.value.businessUserId,
                    'whoLabel': e.label,
                    'isChanged': true,
                });
            } else {
                await this.setState({
                    'whoObject': e,
                    "who": e.value.consumerId,
                    'whoLabel': e.label,
                    'isChanged': true,
                });
            }
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            this.setState({'isSaving': true, 'error': ''});
            await this.props.tryRemoveUser(this.props.sharedDrive.get("driveId"), [this.state.who]);
            this.setState({'isSaving': false, 'isSaved': true, "isChanged": false});
            this.timeout = setTimeout(this.close, 1000);
        }
    };

    render() {
        if(!this.state.driveRemoveUserModal) {
            return null
        } else {
            return (
                <Fixed>
                    <Modal>
                        {this.state.isSaved
                            ? <Success><FontTitle18>{"User removed!"}</FontTitle18></Success>
                            : <>
                                <Title>
                                    <Image src={images.vaultFolderPrivate} alt={""} />
                                    <Inline><FontHeader21>{"Remove user"}</FontHeader21></Inline>
                                    <Close onClick={this.close}><FontHeader16><CloseOutlined /></FontHeader16></Close>
                                </Title>
                                <Form method={"post"} onSubmit={this.handleSubmit}>
                                    <SearchInputUncontrolledDisplay
                                        title={"User"}
                                        options={whoOptions(this.props.contactsAll.filter(
                                            s => {return(s.get("consumerId") !== this.props.userId 
                                            && s.get("businessUserId") !== this.props.userId 
                                            && (s.get("businessUserId") !== null || s.get("consumerId") !== null)
                                            && (this.props.sharedDrive.get("users") === undefined ? true : this.props.sharedDrive.get("users").some(e => {return(
                                                (e.get("userId") === s.get("businessUserId") || e.get("userId") === s.get("consumerId"))
                                            )}))
                                            )}), this.props.isWMC)}
                                        id={'who'}
                                        onChange={this.handleChangeWho}
                                        placeholder={'Search contacts'}
                                        value={this.state.whoLabel}
                                    />
                                    <SaveSection>
                                        <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                        <InlineBlock><div onClick={this.close}><ButtonTertiary canSubmit={false} label={"Cancel"} /></div></InlineBlock>
                                    </SaveSection>
                                </Form>
                            </>
                        }
                    </Modal>
                    {this.state.isSaved && <Background onClick={this.close} />}
                </Fixed>
            )
        }
    }
}

const mapStateToProps = state => ({
    driveRemoveUserModal: state.common.fileVault.get("driveRemoveUserModal"),
    sharedDrive: state.common.fileVault.get("sharedDrive"),
    contactsAll: state.enterprise.contacts.get("contactsAll"),
    userId: state.common.user.get("userId"),
    isWMC: state.common.wmc.get("isWMC")
});

const mapDispatchToProps = dispatch => ({
    setDriveRemoveUserModal: (status) => dispatch(setDriveRemoveUserModal(status)),
    setSharedDrive: (status) => dispatch(setSharedDrive(status)),
    tryRemoveUser: (driveId, userId) => dispatch(tryRemoveUser(driveId, userId)),
    tryGetSharedDriveList: () => dispatch(tryGetSharedDriveList())
});

export default connect(mapStateToProps, mapDispatchToProps)(VaultRemoveUser);