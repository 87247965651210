import { combineReducers } from 'redux';

/* Common */
import changePasswordReducer from "./store/changepassword";

const changePasswordsReducer = combineReducers({
    changePassword: changePasswordReducer,
});

export default changePasswordsReducer;
