import React, {useState} from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {connect, useDispatch} from "react-redux";

/* Components Common */
import { FontHeader18, FontBody16 } from '../../../common/components/fonts';
import {NavSide, Button, Container, ContainerFixed, DesktopSideLink, FeedbackBtn, Main} from '../components/primaryside';
import {tryLogout} from "../../../routes/middleware/auth";
import {setSupportOpen} from "../../support/store/support";
import {setPlatformTo} from "../../../routes/store/user";



const NavPrimarySide = ({ data, location, isWMC, user }) => {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const openSupport = async () => {
        dispatch(setSupportOpen(true, 1));
        // await this.props.setSupportOpen(true, 1);
        const open = modalOpen
        setModalOpen(!open);
    };
    return(
        <NavSide isSupport={user.get("isSupport") || user.get("isWMCDemo") || user.get("isExpertDemo")}>
            <Container>
                {data.main.map((d, dIndex) => (
                    <Main key={dIndex}>
                        {d.map((p, index) => {
                            let status = "inactive";
                            if(location.pathname.split("/")[1] === p.href.split("/")[1]) {status = "active"}
                            if(isWMC && p.showWMC) {
                                return(
                                    <DesktopSideLink key={index} onlyMobile={p.onlyMobile}>
                                        <Link to={p.href}>
                                            <Button status={status}>
                                                <FontBody16>{p.label}</FontBody16>
                                            </Button>
                                        </Link>
                                    </DesktopSideLink>
                                )
                            } else if(!isWMC && p.showCoach) {
                                if(p.href === 'modalSupport') {
                                    return(
                                        <DesktopSideLink key={index} onlyMobile={p.onlyMobile}>
                                            <div onClick={() => {return openSupport()}}>
                                                <Button status={status}>
                                                    <FontBody16>{p.label}</FontBody16>
                                                </Button>
                                            </div>
                                        </DesktopSideLink>
                                    )
                                }
                                if ((user.get("hasPartners") && (p.href === "/partners")) || (p.href !== "/partners")) {
                                    if(p.showCoachCategoryIds === "all") {
                                        return(
                                            <DesktopSideLink key={index} onlyMobile={p.onlyMobile}>
                                                <Link to={p.href}>
                                                    <Button status={status}>
                                                        <FontBody16>{p.label}</FontBody16>
                                                    </Button>
                                                </Link>
                                            </DesktopSideLink>
                                        )
                                    } else {
                                        const overlap = p.showCoachCategoryIds.filter(value => {
                                            const userCategoryIds = user.get("expertCategories").map(c => {return(c.get("categoryId"))});
                                            return userCategoryIds.includes(value)
                                        });
                                        if(overlap.length > 0) {
                                            return(
                                                <DesktopSideLink key={index} onlyMobile={p.onlyMobile}>
                                                    <Link to={p.href}>
                                                        <Button status={status}>
                                                            <FontBody16>{p.label}</FontBody16>
                                                        </Button>
                                                    </Link>
                                                </DesktopSideLink>
                                            )
                                        } else {
                                            return null
                                        }
                                    }
                                } else {
                                    return null;
                                }
                            } else {
                                return null
                            }
                        })}
                    </Main>
                ))}
            </Container>
            <ContainerFixed />
            {/*<ContainerFixed>*/}
            {/*    <Link to={"/feedback"}>*/}
            {/*        <FeedbackBtn>*/}
            {/*            <FontHeader18>Feedback</FontHeader18>*/}
            {/*        </FeedbackBtn>*/}
            {/*    </Link>*/}
            {/*</ContainerFixed>*/}
        </NavSide>
    );
}

const mapStateToProps5 = state => ({
    isWMC: state.common.wmc.get("isWMC"),
    user: state.common.user
});


export default connect(mapStateToProps5, null)(withRouter(NavPrimarySide));