import React from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

/* Containers */

/* Middleware */
import { tryGetAllContacts } from '../../middleware/contacts';
import {setCategoryModal} from '../store/coaching';
import { tryUpdateExpertCategories } from '../middleware/rm';

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FontBody18, FontTitle30, FontHeader14, FontBody16} from "../../../common/components/fonts";
import {SearchInput, SearchInputUncontrolled, SearchInputUncontrolledDisplay, TextInput} from "../../../common/components/inputs";
import {colors} from "../../../common/components/colors";
import {ButtonInactive, ButtonPrimary} from "../../../common/components/buttons";
import {CloseOutlined, DeleteFilled} from "@ant-design/icons";

const Width = styled.div`
    max-width: 500px;
    margin-bottom: 30px;
`;


const SearchInputEdit = styled.div`
    & > div {
        margin: 25px 0px 15px 0px;
    }
    & input {
        width: calc(100% - 42px);
    }
    ${props => props.contactSelected !== false} {
        & input {
            -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
        }
        & input::placeholder {
            color: ${colors.primary70};
        }
    }
`;

const Modal = styled.div`
    margin: 0px auto;
    max-width: 380px;
    padding: 30px;
    border-radius: 6px;
    background-color: ${colors.white};
    position: fixed;
    top: 100px;
    left: 0px;
    right: 0px;
    ${props => props.big !== false} {max-height: 280px;}
    @media screen and (max-width: 460px) {
        top: 20px;
        width: calc(100% - 20px);
        padding: 0px 10px;
    }
    z-index: 6;
`;
const Background = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.50);
    @media screen and (max-width: 460px) {
        background-color: ${colors.white};
    }
    z-index: 5;
`;

const FitContentFullWidth = styled.div`
    width: 100%;
    & > div {
        width: calc(100% - 47px);
        text-align: center;
        margin: 0px auto;
    }
`;
const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;
const Right = styled.div`
    color: ${colors.secondary70};
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 10px;
    display: block;
    cursor: pointer;
    position: absolute;
    z-index: 25;
    right: 30px;
    top: 25px;
    @media screen and (max-width: 460px) {
        top: 0px;
        right: 5px;
    }
`;
const Left = styled.div`
    text-align: left;
    width: 100%;
`;
const Indent = styled.div`
    width: calc(100% - 40px);
    padding: 0px 20px;
`;
const Header = styled.div`
    border-bottom: 1px solid ${colors.secondary30};
    padding-bottom: 10px;
    margin-top: -10px;
    margin-bottom: 30px;
`;
const CoachName = styled.div`
    margin-left: 15px;
    margin-top: 10px;
    display: inline-block;
    vertical-align: top;
`;
export const Close = styled.div`
    position: relative;
    cursor: pointer;
    width: 75px;
    @media screen and (max-width: 640px) {
        width: 20px;
    }
`;
export const Margin67 = styled.div`
    margin-bottom: 25px;
`;
const Trash = styled.div`
    margin-left: 10px;
    display: inline-block;
    font-size: 16px;
    vertical-align: top;
    cursor: pointer;
    color: ${colors.secondary70};
`;
const Search = styled.div`
    display: inline-block;
    vertical-align: top;
`;

const initState = {
    "isLoading": false,
    'label': "",
    "whoValid": false,
    'isChanged': false,
    "category": [],
    "coachCategories": [{value: {categoryId: 1}, label: "Willow Financial Coach"}, {value: {categoryId: 2}, label: "Financial Advisor"}, {value: {categoryId: 4}, label: "Financial Coach"}],
    "searchCategories": [{value: {categoryId: 1}, label: "Willow Financial Coach"}, {value: {categoryId: 2}, label: "Financial Advisor"}, {value: {categoryId: 4}, label: "Financial Coach"}],
};

class CategoryChangeModal extends React.Component {
    state=initState;

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.modalOpen !== this.props.modalOpen && this.props.modalOpen) {
            return this.init();
        }
    }

    init = async () => {
        await this.setState({
            "isLoading": false,
            'label': "",
            "whoValid": false,
            'isChanged': false,
            "category": [],
            "coachCategories": [{value: {categoryId: 1}, label: "Willow Financial Coach"}, {value: {categoryId: 2}, label: "Financial Advisor"}, {value: {categoryId: 4}, label: "Financial Coach"}],
            "searchCategories": [{value: {categoryId: 1}, label: "Willow Financial Coach"}, {value: {categoryId: 2}, label: "Financial Advisor"}, {value: {categoryId: 4}, label: "Financial Coach"}],
        });
    };

    handleAddCategory = async (e) => {
        let categories = this.state.category;
        let addCategory;
        categories.push(e);
        addCategory = await this.state.coachCategories.filter(s => {return(s.value.categoryId !== e.value.categoryId)});
        await this.setState({"category": categories, 'coachCategories': addCategory, 'isChanged': true, 'whoValid': true});
        console.log(this.state)
    };

    handleDeleteCategory = async (num) => {
        let categories = this.state.category;
        let addCategory;
        addCategory = await this.state.searchCategories.filter(s => {return(s.value.categoryId === this.state.category[num].value.categoryId)})[0];
        console.log(addCategory)
        await categories.splice(num, 1);
        let cc = [...this.state.coachCategories];
        cc.push(addCategory)
        await this.setState({"category": categories, 'coachCategories': cc, 'isChanged': this.state.category.length > 0, 'whoValid': this.state.category.length > 0});
        console.log(this.state)
    };

    close = async () => {
        await this.props.tryGetAllContacts(); 
        await this.props.setCategoryModal(false);
        await this.setState({
            ...initState,
            "isLoading": false
        });
    };

    handleSubmit = async () => {
        console.log("tryUpdate(category, coachId)")
        let categories = this.state.category.map(a => {return(a.value.categoryId)})
        await this.props.tryUpdateExpertCategories(categories, this.props.clientSelected.get("consumerId"))
        this.close()
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            if(this.props.modalOpen) {
                return(
                    <>
                        <Modal big={true}>
                            <Header>
                                <Left>
                                    <FontTitle30>Expert Category</FontTitle30>
                                </Left>
                                <Right onClick={() => {return this.close()}}>
                                    <FontHeader14><CloseOutlined /><Inline>&nbsp;Close</Inline></FontHeader14>
                                </Right>
                            </Header>
                            <Indent>
                                <Margin67 />
                                <div>
                                    <CoachName><FontBody18>{this.props.clientSelected.get("firstName")} {this.props.clientSelected.get("lastName")}</FontBody18></CoachName>
                                </div>
                                <Width>
                                    <SearchInputEdit contactSelected={this.state.whoValid}>
                                        <SearchInputUncontrolled
                                            title={"Categories"}
                                            options={this.state.coachCategories}
                                            id={'expertcategory'}
                                            onChange={this.handleAddCategory}
                                            placeholder={'Select Categories'}
                                            value={""}
                                        />
                                    </SearchInputEdit>
                                    {this.state.category.length > 0 &&
                                        this.state.category.map((g, index) => {
                                            return(
                                                <div key={index}>
                                                    <Search><FontBody16>{(index+1).toString()+". "+g.label}</FontBody16></Search>
                                                    <Trash onClick={() => this.handleDeleteCategory(index)}><DeleteFilled /></Trash>
                                                </div>
                                            )
                                        })
                                    }
                                </Width>
                                <FitContentFullWidth onClick={this.handleSubmit}>
                                    {this.state.whoValid
                                        ? <ButtonPrimary canSubmit={false} label={"Confirm"} />
                                        : <ButtonInactive canSubmit={false} label={"Confirm"} />
                                    }
                                </FitContentFullWidth>
                                <br />
                            </Indent>
                        </Modal>
                        <Background />
                    </>
                )
            } else {
                return null
            }
        }
    }
}

const mapStateToProps = state => ({
    clientSelected: state.enterprise.clientsDetail.get("client"),
    modalOpen: state.enterprise.partners.partners.get("categoryModal"),
});
const mapDispatchToProps = dispatch => ({
    setCategoryModal: (status) => dispatch(setCategoryModal(status)),
    tryGetAllContacts: () => dispatch(tryGetAllContacts()),
    tryUpdateExpertCategories: (data, coachId) => dispatch(tryUpdateExpertCategories(data, coachId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CategoryChangeModal));
