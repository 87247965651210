/* Images */
import {images} from './images';

export const fileTypeImage = (file, sendingType) => {
    let fileType;
    if(sendingType) {
        fileType = file
    } else {
        fileType = file.type
    }
    if(fileType === null || fileType === undefined) {
        return (images.vaultFileGeneric)
    } else if(fileType === "application/msword" || fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        return(images.vaultFileWord)
    } else if(fileType === "application/vnd.ms-powerpoint" || fileType === "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
        return(images.vaultFilePowerPoint)
    } else if(fileType === "application/vnd.ms-excel" || fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        return(images.vaultFileExcel)
    } else if(fileType.startsWith("image")) {
        return(images.vaultFileImage)
        // return(file.preview)
        // } else if(fileType.split("/")[0] === "video") {
        //     return()
        // } else if(fileType.split("/")[0] === "audio") {
        //     return()
        // } else if(fileType === "application/vnd.rar") {
        //     return()
        // } else if(fileType === "application/zip") {
        //     return()
        // } else if(fileType === "text/csv") {
        //     return()
    } else if(fileType === "application/pdf") {
        return(images.vaultFilePDF)
        // } else if(fileType === "text/plain") {
        //     return()
    } else if(fileType === "folder") {
        return(images.vaultFolderPrivate)
    } else {
        return(images.vaultFileGeneric)
    }
};


/*
MIME types = http://www.iana.org/assignments/media-types/media-types.xhtml


MICROSOFT OFFICE

Ext	    Kind of document	                                            MIME Type
------------------------------------------------------------------------------------------------------------------------
.doc	Microsoft Word	                                                application/msword
.docx	Microsoft Word (OpenXML)	                                    application/vnd.openxmlformats-officedocument.wordprocessingml.document
.ppt	Microsoft PowerPoint	                                        application/vnd.ms-powerpoint
.pptx	Microsoft PowerPoint (OpenXML)	                                application/vnd.openxmlformats-officedocument.presentationml.presentation
.xls	Microsoft Excel	                                                application/vnd.ms-excel
.xlsx	Microsoft Excel (OpenXML)	                                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet



IMAGE FILES

Ext	    Kind of document	                                            MIME Type
------------------------------------------------------------------------------------------------------------------------
.bmp	Windows OS/2 Bitmap Graphics	                                image/bmp
.gif	Graphics Interchange Format (GIF)                               image/gif
.ico	Icon format	                                                    image/vnd.microsoft.icon
.jpeg   JPEG images                                                     image/jpeg
.jpg	JPEG images	                                                    image/jpeg
.png	Portable Network Graphics	                                    image/png
.svg	Scalable Vector Graphics (SVG)	                                image/svg+xml
.tif    Tagged Image File Format (TIFF)                               	image/tiff
.tiff	Tagged Image File Format (TIFF)	                                image/tiff
.webp	WEBP image	                                                    image/webp



VIDEO FILES

Ext	    Kind of document	                                            MIME Type
------------------------------------------------------------------------------------------------------------------------
.avi	AVI: Audio Video Interleave	                                    video/x-msvideo
.mpeg	MPEG Video	                                                    video/mpeg
.ogv	OGG video	                                                    video/ogg
.ts	    MPEG transport stream	                                        video/mp2t
.webm	WEBM video	                                                    video/webm
.3gp	3GPP audio/video container	                                    video/3gpp if contains video
.3g2	3GPP2 audio/video container	                                    video/3gpp2 if contains video



AUDIO FILES

Ext	    Kind of document	                                            MIME Type
------------------------------------------------------------------------------------------------------------------------
.aac	AAC audio	                                                    audio/aac
.mid    Musical Instrument Digital Interface (MIDI)	                    audio/midi audio/x-midi
.midi	Musical Instrument Digital Interface (MIDI)	                    audio/midi audio/x-midi
.mp3	MP3 audio	                                                    audio/mpeg
.oga	OGG audio	                                                    audio/ogg
.opus	Opus audio	                                                    audio/opus
.wav	Waveform Audio Format	                                        audio/wav
.weba	WEBM audio                                                      audio/webm
.3gp	3GPP audio/video container	                                    audio/3gpp if it doesn't contain video
.3g2	3GPP2 audio/video container	                                    audio/3gpp2 if it doesn't contain video



ARCHIVE FILES

Ext	    Kind of document	                                            MIME Type
------------------------------------------------------------------------------------------------------------------------
.arc	Archive document (multiple files embedded)	                    application/x-freearc
.bz	    BZip archive	                                                application/x-bzip
.bz2	BZip2 archive	                                                application/x-bzip2
.gz	    GZip Compressed Archive                                     	application/gzip
.rar	RAR archive	                                                    application/vnd.rar
.tar	Tape Archive (TAR)	                                            application/x-tar
.zip	ZIP archive	                                                    application/zip
.7z	    7-zip archive	                                                application/x-7z-compressed



SPECIAL FILES

Ext	    Kind of document	                                            MIME Type
------------------------------------------------------------------------------------------------------------------------
.csv	Comma-separated values (CSV)	                                text/csv
.pdf	Adobe Portable Document Format (PDF)	                        application/pdf
.txt	Text, (generally ASCII or ISO 8859-n)	                        text/plain



OTHER FILES

Ext     Kind of document	                                            MIME Type
------------------------------------------------------------------------------------------------------------------------
.abw	AbiWord document	                                            application/x-abiword
.azw	Amazon Kindle eBook format	                                    application/vnd.amazon.ebook
.bin	Any kind of binary data	                                        application/octet-stream
.csh	C-Shell script	                                                application/x-csh
.css	Cascading Style Sheets (CSS)	                                text/css
.eot	MS Embedded OpenType fonts	                                    application/vnd.ms-fontobject
.epub	Electronic publication (EPUB)	                                application/epub+zip
.htm    HyperText Markup Language (HTML)                               	text/html
.html	HyperText Markup Language (HTML)	                            text/html
.ics	iCalendar format	                                            text/calendar
.jar	Java Archive (JAR)	                                            application/java-archive
.js	    JavaScript                                                      text/javascript, per the following specifications: https://html.spec.whatwg.org/multipage/#scriptingLanguages, https://html.spec.whatwg.org/multipage/#dependencies:willful-violation, https://datatracker.ietf.org/doc/draft-ietf-dispatch-javascript-mjs/
.json	JSON format	                                                    application/json
.jsonld	JSON-LD format	                                                application/ld+json
.mjs	JavaScript module	                                            text/javascript
.mpkg	Apple Installer Package	                                        application/vnd.apple.installer+xml
.odp	OpenDocument presentation document	                            application/vnd.oasis.opendocument.presentation
.ods	OpenDocument spreadsheet document	                            application/vnd.oasis.opendocument.spreadsheet
.odt	OpenDocument text document	                                    application/vnd.oasis.opendocument.text
.ogx	OGG	                                                            application/ogg
.otf	OpenType font	                                                font/otf
.php	Hypertext Preprocessor (Personal Home Page)	                    application/x-httpd-php
.rtf	Rich Text Format (RTF)	                                        application/rtf
.sh	    Bourne shell script	                                            application/x-sh
.swf	Small web format (SWF) or Adobe Flash document	                application/x-shockwave-flash
.ttf	TrueType Font	                                                font/ttf
.vsd	Microsoft Visio	                                                application/vnd.visio
.woff	Web Open Font Format (WOFF)                                     font/woff
.woff2	Web Open Font Format (WOFF)	                                    font/woff2
.xhtml	XHTML	                                                        application/xhtml+xml
.xml	XML	                                                            application/xml if not readable from casual users (RFC 3023, section 3) text/xml if readable from casual users (RFC 3023, section 3)
.xul	XUL	                                                            application/vnd.mozilla.xul+xml


 */