import { combineReducers } from 'redux';

/* Reducers */
import expertProfileReducer from './store/expertprofile';
import shopItemsReducer from './store/shopitems';

const shopReducer = combineReducers({
    expertProfile: expertProfileReducer,
    shopItems: shopItemsReducer
});

export default shopReducer;
