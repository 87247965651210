import React from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';

/* Components */
import { colors } from '../../../common/components/colors';
import { FontSubtitle24To20 } from '../../../common/components/fonts';

const NavTop = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-self: flex-start;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 40px;
    margin-top: 15px;    
`;

const Nav = styled(Link)`
    ${props => props.active !== "true"} {
        border-bottom: 3px solid ${colors.action100};
        cursor: default;
        & div {
          font-weight: bold;
        }
    }
    ${props => props.active !== "false"} {
        border-bottom: none;
        cursor: pointer;
    }
    padding: 10px 0;
    width: fit-content;
    color: ${colors.primary100};
`;
const Bar = styled.div`
    width: 2px;
    margin: 4px 0;
    background-color: ${colors.primary100};
     &:last-child {
        display: none;    
    }
`;

const navTopTabs = [
    // {
    //     navTopTitle: "To-dos",
    //     navTopSubtitle: null,
    //     navTabTitle: "To-dos",
    //     navTabHref: "/progress"
    // },
    {
        navTopTitle: "Appointments",
        navTopSubtitle: null,
        navTabTitle: "Appointments",
        navTabHref: "/progress/appointments"
    },
    // {
    //     navTopTitle: "Bookmarks",
    //     navTopSubtitle: null,
    //     navTabTitle: "Bookmarks",
    //     navTabHref: "/progress/bookmarks"
    // },
    // {
    //     navTopTitle: "Goals",
    //     navTopSubtitle: null,
    //     navTabTitle: "Goals",
    //     navTabHref: "/progress/goals"
    // },
    {
        navTopTitle: "Finances",
        navTopSubtitle: null,
        navTabTitle: "Finances",
        navTabHref: "/progress/finances"
    },
];

const NavProgress = ({ location }) => {
    return(
        <NavTop>
            {navTopTabs.map((t, index) => {
                let status = "false";
                if(location.pathname === t.navTabHref) {status = "true"}
                return(
                    <React.Fragment key={index}>
                        <Nav active={status} to={t.navTabHref}>
                            <FontSubtitle24To20 spaced={false}>{t.navTabTitle}</FontSubtitle24To20>
                        </Nav>
                        <Bar />
                    </React.Fragment>
                )
            })}
        </NavTop>
    );
};



export default withRouter(NavProgress);
