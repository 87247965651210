import React from 'react';
import {withRouter} from 'react-router-dom';

/* Navs */
import {PageContent, PageFull, PageRight} from "../navbars/components/navlayout";
import NavPrimaryAll from "../navbars/containers/primaryall";
import NavSecondaryTop from '../navbars/containers/secondarytop';

/* Containers */
import Courses from "./containers/courses";
import CreateCourse from "./containers/createcourse";
// import MeetingsEditLarge from "./containers/meetingseditlarge";
// import MeetingsInfoPage from "./containers/meetingsinfopage";

/* Components */
import {PlusOutlined} from "@ant-design/icons";

const createButton = [
    {
        "pathname": "/curriculum/create",
        "label": <><PlusOutlined /> Create</>,
        "buttonType": "primary"
    }
];

const Curriculum = () => (
    <>
        <NavPrimaryAll />
        <PageFull>
            <PageRight>

                {location.pathname === "/curriculum" &&
                    <>
                        <NavSecondaryTop title={"Curriculum"} buttons={createButton} desc={"Create and assign courses and assessments to your clients and prospects"}/>
                        <PageContent>
                            <Courses />
                        </PageContent>
                    </>
                }

                {location.pathname === "/curriculum/create" &&
                    <>
                        <NavSecondaryTop title={"Create Content"}/>
                        <PageContent>
                            <CreateCourse isEdit={false}/>
                        </PageContent>
                    </>
                }

                {location.pathname === "/curriculum/edit" &&
                    <>
                        <NavSecondaryTop title={"Create Content"}/>
                        <PageContent>
                            <CreateCourse isEdit={true}/>
                        </PageContent>
                    </>
                }

            </PageRight>
        </PageFull>
    </>
);

export default withRouter(Curriculum);
