import React from 'react';
import {withRouter} from 'react-router-dom';

/* Components */
import ConsumerNav from '../navbars/containers/nav';
import {PageFull, PageContentContainer3, PageFullNoNav} from '../navbars/components/navlayout';

/* Containers */
import MembersList from "./containers/memberslist";
import MemberOnePage from "./containers/memberonepage";
import MemberAddAppointments from "./containers/memberaddappointments";
import MembersListAddAppointments from "./containers/memberslistaddappointments";
import TeamAppointments from "./containers/teamappointments";
import CoachingCategories from "../getcare/containers/coaching";
import CoachingCategoryOne from "../getcare/containers/coachingcategoryone";
import {PageFullV2} from "../home/page";

const MyTeam = ({location}) => {
    if(location.pathname.includes("find")) {
        return(
            <PageFullNoNav>
                {location.pathname === "/my-team/find" && <CoachingCategories />}
                {location.pathname !== "/my-team/find" && <CoachingCategoryOne />}
            </PageFullNoNav>
        )
    } else {
        return(
            <div>
                <ConsumerNav
                    hideBottomNav={location.pathname !== "/my-team"}
                />
                <PageFullV2>
                    <PageContentContainer3>

                        {location.pathname === "/my-team" && <MembersList />}

                        {location.pathname === "/my-team/appointment" && <TeamAppointments />}

                        {(location.pathname === "/my-team/add-appointments" || location.pathname === "/my-team/add-appointments/checkout")  && <MembersListAddAppointments />}

                        {(location.pathname.includes("add-appointments") && location.pathname !== "/my-team/add-appointments" && location.pathname !== "/my-team/add-appointments/checkout") && <MemberAddAppointments />}

                        {(location.pathname !== "/my-team" && !location.pathname.includes("add-appointments") && location.pathname !== "/my-team/appointment") &&
                            <MemberOnePage />
                        }

                    </PageContentContainer3>
                </PageFullV2>
            </div>
        );
    }
}

export default withRouter(MyTeam);