import React from 'react';
import styled from 'styled-components';

/* Containers */
import SchedulerFlow from "../../scheduler/containers/flow";
import {colors} from "../../../common/components/colors";
import {FontBody24} from "../../../common/components/fonts";
import {ContactTitle} from "./components";

const Show = styled.div`
  display: ${({toggle}) => toggle ? 'flex' : 'none'};
  flex-direction: column;
  gap: 20px;
`;
const PreviewWhite = styled(FontBody24)`
  color: ${props => props.color}B3;
  line-height: 1.5;
  & strong {
    color: ${props => props.color};
  }
  @media screen and (max-width: 1680px) {
    font-size: 20px !important;
  }
  @media screen and (max-width: 748px) {
    font-size: 16px !important;
  }
`;
const StyleOverride = styled.div`
    margin-top: 30px;
    & > div > div {
      padding: 0 !important;
      margin: 0 !important;
      width: 100% !important;
    }
`;

ContactPage1.defaultProps = {
    advisorId: null,
    setDatetime: () => {},
    show: true
}

export default function ContactPage1(props) {
    const advisorId = props.advisorId;
    const show = props.show;
    const setDatetime = props.setDatetime;

    return(
        <Show toggle={show}>
            <ContactTitle color={colors.white}>Let's Get Started</ContactTitle>
            <PreviewWhite color={colors.white}>Request a 15 minute video call </PreviewWhite>
            <StyleOverride>
                <SchedulerFlow
                    userId={advisorId}
                    eventCustomMinutes={15}
                    useNewDesign={true}
                    availabilityCalendarOnly={true}
                    availabilityCalendarOnlySelectTime={(datetime) => setDatetime(datetime)}
                />
            </StyleOverride>
        </Show>
    );
}