import {v4 as uuid4} from 'uuid';
import {images} from '../../../common/components/images';

export const devShopExpertProfileData = {
    id: '32',

    firstName: "Jason",
    lastName: "Aronson",
    companyName: 'Coaching, LLC',
    crd: 'test 5',

    photo: images.devDataTeamJasonAvatar,
    introVideoUrl: 'https://www.youtube.com/embed/RZAF1DAqHPk',
    facebookUrl: 'https://facebook.com',
    linkedinUrl: 'https://linkedin.com',
    twitterUrl: 'https://twitter.com',
    address1: 'test 3',
    address2: 'test 6',
    city: 'Boston',
    state: 'MA',
    zipCode: '23443',
    bio: '<p>In an age of pandemic, what true value do any of our backgrounds hold? It’s not like we can look at precedents and say, people who have done ABC tend to be really successful at accomplishing XYZ in a pandemic. My expf</p>',

    // BEG COACH ONLY FIELDS
    endorsement1: 'Test 1',
    endorsement2: 'Test 2',
    endorsement3: null,
    freeTime: 'Test 7',
    whyBecameCoach: 'Test 2',
    coachPhilosophy: 'Test 3',
    highlights: 'Test 1',

    coachingCertification: null,
    coachingCertificationYear: null,

    // END COACH ONLY FIELDS

    bookingAvailability: 'available',

    screeningCallLength: 30,
    defaultCallLength: 30,

    advancedDegree: null,
    advancedDegreeCity: null,
    advancedDegreeInstitution: null,
    advancedDegreeState: null,
    advancedDegreeYear: null,

    otherEducation: null,

    expertCategories: [
        {
            "categoryId": 123,
            "categoryName": "Financial Coach"
        },
        {
            "categoryId": 5434,
            "categoryName": "Career Coach"
        }
    ],

    storeActive: false,
    storeShowScheduler: true,
    storeShowEmailer: true,
    storeTitle: "",
    storePhoto: null,
    storeHandle: "samplestore",

    publicHandle: "sampleadvisor",
    profileActive: true,
    profileShowScheduler: true,
    profileShowEmailer: true,
    website: "fromjason.c",

    schedulerActive: true,
    schedulerHandle: "sampleschedule",
    storeDescription: "",

    "heroImageUrl":null,
    "signUpDate":"2020-10-22T14:55:00.857405+00:00",
    "testimonials":[
        {
            "id": uuid4(),
            "person": "Jane",
            "body": "<p>This endorsement is really good</p>"
        }
    ],
    "interests": ["Ellevate Network"],
    "skills": ["Financial Guidance", "Savings Plans"],
    "portfolio": [
        {
            "id": uuid4(),
            "photo": images.devDataTeamJasonAvatar,
            "startMonth": 4,
            "startYear": 2009,
            "endMonth": 9,
            "endYear": 2010,
            "endOngoing": true,
            "title": "Title String",
            "body": "<p>Body String</p>"
        }
    ],
    "faqs": [
        {
            "id": uuid4(),
            "title": "Are you a financial advisor?",
            "body": "<p>I am a financial wellness coach. I show clients how to organize and manage their household finances so that they are in a position to build wealth over time. I do not manage clients’ investments, nor do I provide investment advice.</p>",
        },
        {
            "id": uuid4(),
            "title": "Who are your typical clients?",
            "body": "<p>Typical clients are busy professionals who are successful in their chosen fields but who lack the time and interest to put together a household financial plan that will allow them to achieve the dual objectives of living their best lives today and securing their future.</p>",
        },
    ],
    "jobs": [
        {
            "id": uuid4(),
            "photo": "https://media-exp1.licdn.com/dms/image/C4D0BAQFsFWO07OTIxg/company-logo_100_100/0/1603145743085?e=1620259200&v=beta&t=431vOGXnhu_6G0-9FACBBlik2I-LPVRAcSq6B4jVe9k",
            "company": "Inkberry Financial",
            "title": "Financial Wellness Coach and Consultant",
            "body": "I teach busy professionals a framework for managing household finances, so that they can confidently navigate through every life stage and achieve money goals.",
            "startMonth": 4,
            "startYear": 2009,
            "endMonth": 9,
            "endYear": 2010,
            "endOngoing": true,
            "city": "Boston",
            "state": "MA",
        },
        {
            "id": uuid4(),
            "photo": "https://media-exp1.licdn.com/dms/image/C4D0BAQF0ee--6W7Eyw/company-logo_100_100/0/1596115019816?e=1620259200&v=beta&t=5uUyFMmtBrpwa2kPdZ6ig0cvq5iZ4SZhpjQ5fxshwN4",
            "company": "State Street Global Advisors",
            "title": "Vice President, Relationship Manager",
            "body": "Relationship Manager responsible for Institutional clients.",
            "startMonth": null,
            "startYear": 2009,
            "endMonth": null,
            "endYear": null,
            "endOngoing": false,
            "city": null,
            "state": null,
        }
    ],
    "degrees": [
        {
            "id": uuid4(),
            "photo": "https://media-exp1.licdn.com/dms/image/C4E0BAQFrAD-nJ1qQrg/company-logo_100_100/0/1564001325127?e=1620259200&v=beta&t=Lgjg7UF5MHd9Cv0ksOf8I-HZ6xrWcEN-D4o5ObGb3tY",
            "title": "Tulane University",
            "body": "<p>I did xyz in school</p>",
            "degree": "MS in Everything!",
            "startMonth": 4,
            "startYear": 2009,
            "endMonth": 9,
            "endYear": 2010,
            "endOngoing": false,
            "city": "Boston",
            "state": "MA",
        }
    ],
    "licenses": [
        {
            "id": uuid4(),
            photo: "https://media-exp1.licdn.com/dms/image/C510BAQFnaGYkNVvCbw/company-logo_100_100/0/1519912453241?e=1620259200&v=beta&t=-IZjv5PMQkDegqJHSbE0trQmxHx1PFUVi7cTlh4ij2A",
            // photo: null, //licdn.com/dms/image/C510BAQFnaGYkNVvCbw/company-logo_100_100/0/1519912453241?e=1620259200&v=beta&t=-IZjv5PMQkDegqJHSbE0trQmxHx1PFUVi7cTlh4ij2A",
            title: "Chartered Financial Analyst",
            institute: "CFA Institute",
            "endMonth": null,
            "endYear": null,
            licenseNumber: 545645447
        },
        {
            "id": uuid4(),
            photo: "https://media-exp1.licdn.com/dms/image/C4D0BAQH437ahaQvGfw/company-logo_100_100/0/1594318404147?e=1620259200&v=beta&t=Zb0sCA3mzvLc5gXWL6CgU9MPjzC0QclPT3_u6BpE-Bo",
            title: "Series 3, 7, 63 (expired)",
            institute: "FINRA",
            "endMonth": 4,
            "endYear": 2009,
            licenseNumber: null
        }
    ],
    "instagramUrl":null,
    "blogUrl":null,
    "isWMCEmployee": false

};

export const devShopExpertProfileUpdateData = (data) => ({
    'photo': images.devDataTeamJasonAvatar,
    // "highlights": data.highlights,
    "bio": data.bio,
    "whyBecameCoach": data.whyBecameCoach,
    // "coachPhilosophy": data.coachPhilosophy,
    "companyName": data.companyName,
    "crd": data.crd,
    "endorsement1": data.endorsement1,
    "endorsement2": data.endorsement2,
    "endorsement3": data.endorsement3,
    "freeTime": data.freeTime,
    "address1": data.address1,
    "address2": data.address2,
    "city": data.city,
    "state": data.state,
    "zipCode": data.zipCode,
    "introVideoUrl": data.introVideoUrl,
    "facebookUrl": data.facebookUrl,
    "linkedinUrl": data.linkedinUrl,
    "twitterUrl": data.twitterUrl,
    "bookingAvailability": data.bookingAvailability,
    ...data
});

export const devShopGetCoachCategories = {
    "expertCategories": [
        {
            "categoryId": 6,
            "categoryName": "Career Coach"
        },
        {
            "categoryId": 7,
            "categoryName": "Interior Designer"
        }
    ]
}