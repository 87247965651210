import React from 'react';
import styled from 'styled-components';
import {colors} from "./colors";
import {FontSubtitle38To30, FontBody16, FontHeader21, FontSubtitle16} from './fonts';

const Center = styled.div`
    text-align: center;
    margin: 40px 0px;
`;
const Spacing = styled.div`
    margin-bottom: 20px;
`;

export const Message = ({ text, subtitle }) => (
    <Center>
        <Spacing><FontSubtitle38To30>{text}</FontSubtitle38To30></Spacing>
        <FontBody16><i>{subtitle}</i></FontBody16>
    </Center>
);

export const Notice = ({ text, subtitle }) => (
    <Center>
        <Spacing><FontHeader21>{text}</FontHeader21></Spacing>
        <FontBody16><i>{subtitle}</i></FontBody16>
    </Center>
);




export const ToolTipBody = styled.div`
  border-radius: 10px;
  background-color: ${colors.white};
  position: absolute;
  width: 300px;
  display: ${props => props.show ? 'flex' : 'none'};

  & div {
    max-width: 300px;
  }

  flex-direction: column;
  z-index: 11;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  bottom:50px;
  left:45px;
 

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 21px solid transparent;
    border-top-color: ${colors.background30};;
    border-bottom: 0;
    border-left: 0;
    margin-left: -10.5px;
    margin-bottom: -21px;
  }

  ${FontSubtitle16} {
    align-self: center;
    margin: 20px 20px 5px 20px;
    width: calc(100% - 40px);
  }



`;




export const ToolTip = (props) => (
        <ToolTipBody show={props.show} >
            <FontSubtitle16>{props.text}</FontSubtitle16>
        </ToolTipBody>
    );

