import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import styled from 'styled-components'

/* Components */
import {FontHeader21, FontBody16} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";
import {LogoLoading} from "../../../common/components/loading";
import { Message } from "../../../common/components/messages";

export const ActionBox = styled.div`
    border: 1px solid ${colors.secondary30};
    padding: 25px 0px 0px 0px;
    max-width: calc(700px - 2px);
    width: 100%;
    border-radius: 6px;
    margin-bottom: 20px;
    background-color: ${colors.border70};
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    @media screen and (max-width: 768px) {
        text-align: center;
    }
`;
export const Photo = styled.img`
    display: inline-block;
    vertical-align: top;
    width: 150px;
    height: auto;
    border-radius: 6px;
    margin-right: 30px;
    @media screen and (max-width: 768px) {
        display: block;
        margin: 0px auto;
    }
`;
const TitleSection = styled.div`
    display: inline-block;
    vertical-align: top;
    padding-top: 25px;
    width: calc(100% - 180px);
    @media screen and (max-width: 768px) {
        width: 100%;
        display: block;
    }
`;
const Top = styled.div`
    padding: 0px 30px 30px 30px;
    @media screen and (max-width: 460px) {    
        padding: 0px 20px 30px 20px;
    }
`;
const Left = styled.div`
    width: 100%;
    display: inline-block;
    vertical-align: bottom;
`;
const BackTop = styled.div`
    width: fit-content;
    color: ${colors.secondary70};
    cursor: pointer;
    position: relative;
    z-index: 2;
    text-align: left;
    display: block;
    padding-bottom: 20px;
    @media screen and (max-width: 748px) {
        margin-left: 60px;
    }
    @media screen and (max-width: 460px) {
        margin-left: 0px;
    }
`;

class TeamList extends React.Component {
    state = {
        "photo": null,
        "firstName": null,
        "lastName": null,
        "userId": null,
        "isLoading": true,
        "showAll": false,
        "allSupport": []
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "allClients": this.props[this.props.entry === "support" ? "allClients" : "allClientsAdvisor"].map(a => {
                return({...a.toJS()})
            }),
            "isLoading": false
        })
    };

    back = () => {
        this.props.history.push("/firms/experts");
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <> 
                    {/*<BackTop onClick={() => {return(this.back())}}><ArrowLeftOutlined /> Back</BackTop>*/}
                    {this.state.allClients.length === 0 && <Message text={"No assigned clients yet!"} />}
                    {this.state.allClients.map((c, index) => (
                        <ActionBox key={index}>
                            <Top>
                                {/* <Photo src={c.photo} alt={""} /> */}
                                <TitleSection>
                                    <FontHeader21>{c.firstName+" "+c.lastName.charAt(0)+"."}</FontHeader21>
                                    <div>
                                        <Left>
                                            {(parseInt(c.sessionsRemaining) === 0)
                                                ? <FontBody16>No sessions remaining</FontBody16>
                                                : <>
                                                    {(parseInt(c.sessionsRemaining) > 1000)
                                                        ? <FontBody16>Unlimited sessions remaining</FontBody16>
                                                        : <FontBody16>{c.sessionsRemaining} sessions remaining</FontBody16>
                                                    }
                                                </>
                                            }
                                            
                                        </Left>
                                    </div>
                                </TitleSection>
                            </Top>
                        </ActionBox>
                    ))}
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    allClients: state.enterprise.partners.partners.get("clientsAssignedToCoach"),
    allClientsAdvisor: state.enterprise.partners.partners.get("clientsAssignedToAdvisor"),
});

export default connect(mapStateToProps, null)(withRouter(TeamList));
