import { Map, List, fromJS } from 'immutable';

/* Immutable */

const AVAILABILITY = 'AVAILABILITY';
const MANUAL_AVAILABILITY_CREATING = 'MANUAL_AVAILABILITY_CREATING';
const MANUAL_AVAILABILITY_READING = 'MANUAL_AVAILABILITY_READING';
const MANUAL_AVAILABILITY_UPDATING = 'MANUAL_AVAILABILITY_UPDATING';
const MANUAL_AVAILABILITY_DELETING = 'MANUAL_AVAILABILITY_DELETING';
const MANUAL_AVAILABILITY_MODAL = 'MANUAL_AVAILABILITY_MODAL';
const MANUAL_AVAILABILITY_STORE_RESET = 'MANUAL_AVAILABILITY_STORE_RESET';

/* Actions */

export const setManualAvailability = (availability) => ({'type': AVAILABILITY, 'availability': availability});
export const setManualAvailabilityCreating = (status) => ({'type': MANUAL_AVAILABILITY_CREATING, 'status': status});
export const setManualAvailabilityReading = (status) => ({'type': MANUAL_AVAILABILITY_READING, 'status': status});
export const setManualAvailabilityUpdating = (status) => ({'type': MANUAL_AVAILABILITY_UPDATING, 'status': status});
export const setManualAvailabilityDeleting = (status) => ({'type': MANUAL_AVAILABILITY_DELETING, 'status': status});
export const setManualAvailabilityModal = (status, availability, action) => ({'type': MANUAL_AVAILABILITY_MODAL, 'status': status, 'availability': availability, 'action': action});
export const setManualAvailabilityStoreReset = () => ({'type': MANUAL_AVAILABILITY_STORE_RESET});

/* Initial State */

const initialState = Map({
    manualAvailability: List(),
    manualAvailabilityCreating: false,
    manualAvailabilityReading: false,
    manualAvailabilityUpdating: false,
    manualAvailabilityDeleting: false,
    manualAvailabilityModal: false,
    manualAvailabilityModalAction: null,
    manualAvailabilitySelected: null
});

/* Reducer */

const manualAvailabilityReducer = (state=initialState, action) => {
    switch (action.type) {
        case AVAILABILITY:
            return state.merge({'manualAvailability': fromJS(action.availability)});
        case MANUAL_AVAILABILITY_CREATING:
            return state.merge({'manualAvailabilityCreating': action.status});
        case MANUAL_AVAILABILITY_READING:
            return state.merge({'manualAvailabilityReading': action.status});
        case MANUAL_AVAILABILITY_UPDATING:
            return state.merge({'manualAvailabilityUpdating': action.status});
        case MANUAL_AVAILABILITY_DELETING:
            return state.merge({'manualAvailabilityDeleting': action.status});
        case MANUAL_AVAILABILITY_MODAL:
            return state.merge({
                'manualAvailabilityModal': action.status,
                'manualAvailabilitySelected': fromJS(action.availability),
                'manualAvailabilityModalAction': action.action
            });
        case MANUAL_AVAILABILITY_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default manualAvailabilityReducer;
