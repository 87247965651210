import React from 'react';
import styled  from 'styled-components';

/* Domain */
import getDomainEnv from '../../domains/utils';
const env = getDomainEnv();



/* FONT FAMILY */

export const fontFamily = {
  body: env.STYLES.FONTS.body.fontFamily+', sans-serif',
  header: env.STYLES.FONTS.header.fontFamily+', sans-serif',
  subtitle: env.STYLES.FONTS.subtitle.fontFamily+', serif',
  title: env.STYLES.FONTS.title.fontFamily+', serif',
};



/* TITLE */

export const FontTitle40 = styled.div`
    font-family: ${env.STYLES.FONTS.title.fontFamily};
    font-size: 40px;
    line-height: 52px;
`;
export const FontTitle38 = styled.div`
    font-family: ${env.STYLES.FONTS.title.fontFamily};
    font-size: 38px;
    line-height: 50px;
`;
export const FontTitle34 = styled.div`
    font-family: ${env.STYLES.FONTS.title.fontFamily};
    font-size: 34px;
    line-height: 45px;
`;
export const FontTitle32To27 = styled.div`
    font-family: ${env.STYLES.FONTS.title.fontFamily};
    font-size: 32px;
    line-height: 48px;
    @media screen and (max-width: 768px) {
        font-size: 27px;
        line-height: 41px;
    }
`;
export const FontTitle30 = styled.div`
    font-family: ${env.STYLES.FONTS.title.fontFamily};
    font-size: 30px;
    line-height: 45px;
`;
export const FontTitle28 = styled.div`
    font-family: ${env.STYLES.FONTS.title.fontFamily};
    font-size: 28px;
    line-height: 36px;
`;
export const FontTitle24 = styled.div`
    font-family: ${env.STYLES.FONTS.title.fontFamily};
    font-size: 24px;
    line-height: 36px;
`;
export const FontTitle22 = styled.div`
    font-family: ${env.STYLES.FONTS.title.fontFamily};
    font-size: 22px;
    line-height: 36px;
`; 
export const FontTitle21 = styled.div`
    font-family: ${env.STYLES.FONTS.title.fontFamily};
    font-size: 21px;
    line-height: 32px;
`;
export const FontTitle21To16 = styled.div`
    font-family: ${env.STYLES.FONTS.title.fontFamily};
    font-size: 21px;
    line-height: 32px;
    @media screen and (max-width: 748px) {
      font-size: 16px;
      line-height: 24px;
    }
`;
export const FontTitle20 = styled.div`
    font-family: ${env.STYLES.FONTS.title.fontFamily};
    font-size: 20px;
    line-height: 26px;
`;
export const FontTitle18 = styled.div`
    font-family: ${env.STYLES.FONTS.title.fontFamily};
    font-size: 18px;
    line-height: 27px;
`;
export const FontTitle16 = styled.div`
    font-family: ${env.STYLES.FONTS.title.fontFamily};
    font-size: 16px;
    line-height: 24px;
`;



/* SUBTITLE */

export const FontSubtitle40 = styled.div`
    font-family: ${env.STYLES.FONTS.subtitle.fontFamily};
    font-size: 40px;
    line-height: 52px;
    letter-spacing: ${props => props.spaced === true ? '5.6px' : 'normal'};
`;
export const FontSubtitle38To30 = styled.div`
    font-family: ${env.STYLES.FONTS.subtitle.fontFamily};
    font-size: 38px;
    line-height: 50px;
    @media screen and (max-width: 1088px) {
        font-size: 30px;
        line-height: 38px;
    }
`;
export const FontSubtitle34 = styled.div`
    font-family: ${env.STYLES.FONTS.subtitle.fontFamily};
    font-size: 34px;
    line-height: 38px;
    font-weight: medium;
`;

export const FontSubtitle28 = styled.div`
    font-family: ${env.STYLES.FONTS.subtitle.fontFamily};
    font-size: 28px;
    line-height: 36px;
    letter-spacing: ${props => props.spaced === true ? '5.6px' : 'normal'};
    font-weight: medium;
`;
FontSubtitle28.defaultProps = {
    spaced: true,
}
export const FontSubtitle24 = styled.div`
    font-family: ${env.STYLES.FONTS.subtitle.fontFamily};
    font-size: 24px;
    line-height: 31px;
    letter-spacing: ${props => props.spaced === true ? '5.6px' : 'normal'};
    font-weight: medium;
`;
FontSubtitle24.defaultProps = {
    spaced: true,
}

export const FontSubtitle24To20 = styled(FontSubtitle24)`
    @media screen and (max-width: 460px) {
        font-size: 20px;
        line-height: 26px;
        letter-spacing: ${props => props.spaced === true ? '4.6px' : 'normal'};
    }
`;

export const FontSubtitle21 = styled.div`
    font-family: ${env.STYLES.FONTS.subtitle.fontFamily};
    font-size: 21px;
    line-height: 32px;
`;
export const FontSubtitle18 = styled.div`
    font-family: ${env.STYLES.FONTS.subtitle.fontFamily};
    font-size: 18px;
    line-height: 27px;
`;
export const FontSubtitle16 = styled.div`
    font-family: ${env.STYLES.FONTS.subtitle.fontFamily};
    font-size: 16px;
    line-height: 24px;
`;

export const FontSubtitle16To14 = styled.div`
  font-family: ${env.STYLES.FONTS.subtitle.fontFamily};
  font-size: 16px;
  line-height: 24px;
  @media screen and (max-width: 735px) {
    font-size: 14px;
    line-height:21px;
  }
`;
export const FontSubtitle14 = styled.div`
    font-family: ${env.STYLES.FONTS.subtitle.fontFamily};
    font-size: 14px;
    line-height: 21px;
`;



/* HEADER */

export const FontHeader26 = styled.div`
    font-family: ${env.STYLES.FONTS.header.fontFamily};
    font-size: 26px;
    line-height: 34px;
`;
export const FontHeader24 = styled.div`
    font-family: ${env.STYLES.FONTS.header.fontFamily};
    font-size: 24px;
    line-height: 36px;
`;
export const FontHeader21 = styled.div`
    font-family: ${env.STYLES.FONTS.header.fontFamily};
    font-size: 21px;
    line-height: 32px;
`;
export const FontHeader18 = styled.div`
    font-family: ${env.STYLES.FONTS.header.fontFamily};
    font-size: 18px;
    line-height: 27px;
`;
export const FontHeader16 = styled.div`
    font-family: ${env.STYLES.FONTS.header.fontFamily};
    font-size: 16px;
    line-height: 24px;
`;
export const FontHeader14 = styled.div`
    font-family: ${env.STYLES.FONTS.header.fontFamily};
    font-size: 14px;
    line-height: 21px;
`;
export const FontHeader12 = styled.div`
    font-family: ${env.STYLES.FONTS.header.fontFamily};
    font-size: 12px;
    line-height: 18px;
`;



/* BODY */

export const FontBody50 = styled.div`
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    font-size: 50px;
    line-height: 60px;
`;
export const FontBody24 = styled.div`
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    font-size: 24px;
    line-height: 36px;
`;
export const FontBody21 = styled.div`
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    font-size: 21px;
    line-height: 32px;
`;
export const FontBody21To16 = styled.div`
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    font-size: 21px;
    line-height: 32px;
    @media screen and (max-width: 748px) {
      font-size: 16px;
      line-height: 24px;
    }
`;
export const FontBody18 = styled.div`
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    font-size: 18px;
    line-height: 27px;
`;
export const FontBody16 = styled.div`
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    font-size: 16px;
    line-height: 24px;
`;
export const FontBody16To14 = styled.div`
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    font-size: 16px;
    line-height: 24px;
    @media screen and (max-width: 748px) {
        font-size: 14px;
        line-height: 21px;
    }
`;
export const FontBody14 = styled.div`
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    font-size: 14px;
    line-height: 21px;
`;
export const FontBody12 = styled.div`
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    font-size: 12px;
    line-height: 18px;
`;