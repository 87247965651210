import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

/* Components */
import {Spacer, ButtonWide, StripeComponents, HalfLeft, HalfRight, Form, Hidden, Delete} from '../components/cards';
import {ButtonPrimary, ButtonInactive} from "../../../common/components/buttons";
import TextInput from "../../../common/components/textinput";
import CreditExpiration from "../components/cardexpiration";
import {FontBody14, FontBody16, FontHeader16} from "../../../common/components/fonts";
import {LogoLoading} from "../../../common/components/loading";
import {Trash} from "react-feather";

function CheckoutCardsEditStripe(props) {
    const user = useSelector(state => state.common.user);

    const [name, setName] = useState('');
    const [postal, setPostal] = useState('');
    const [creditExp, setCreditExp] = useState("");
    const [creditExpMonth, setCreditExpMonth] = useState("");
    const [creditExpYear, setCreditExpYear] = useState("");
    const [isValid, setIsValid] = useState(false);
    const [isSavingCard, setIsSavingCard] = useState(false);

    useEffect(() => {
        const exp_month = props.cardEditing.card.exp_month.toString().length === 1 ? ("0"+props.cardEditing.card.exp_month) : props.cardEditing.card.exp_month.toString();
        const exp_year = props.cardEditing.card.exp_year.toString().substring(2);
        setName(props.cardEditing.billing_details.name);
        setPostal(props.cardEditing.billing_details.address.postal_code);
        setCreditExp((exp_month+"/"+exp_year));
        setCreditExpMonth(exp_month);
        setCreditExpYear(exp_year);
    }, []);

    useEffect(() => {
        if(creditExpMonth.length === 2 && creditExpYear.length === 2 && name !== "" && postal !== "") {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [creditExpMonth, creditExpYear, postal, name]);

    const handleChangeName = e => setName(e.target.value);
    const handleChangePostal = e => setPostal(e.target.value);
    const handleChangeExpiration = e => {
        setCreditExp(e.target.value);
        setCreditExpMonth(e.target.value.split("/")[0]);
        setCreditExpYear(e.target.value.split("/")[1]);
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setIsSavingCard(true);
        const request = {
            paymentMethodId: props.cardEditing.id,
            name: name,
            email: user.get("email"),
            phone: user.get("phone"),
            city: "",
            country: "",
            line1: "",
            line2: "",
            postalCode: postal,
            state: "",
            expirationMonth: creditExpMonth,
            expirationYear: creditExpYear,
        };
        return props.onEditCard(request)
    };

    if(isSavingCard) {
        return(<><br /><br /><LogoLoading /></>)
    }

    return(
        <Form method={"post"} onSubmit={handleSubmit}>
            <StripeComponents>
                <FontHeader16>Card Number*</FontHeader16>
                <Hidden><FontBody16>**** **** **** {props.cardEditing.card.last4}</FontBody16></Hidden>
                <Spacer />
                <div>
                    <HalfLeft>
                        <CreditExpiration
                            title={<FontHeader16>Expiration*</FontHeader16>}
                            valid={true}
                            warning={""}
                            placeholder={"MM/YY"}
                            value={creditExp}
                            onChange={handleChangeExpiration}
                        />
                    </HalfLeft>
                    <HalfRight>
                        <FontHeader16>CVV Code*</FontHeader16>
                        <Hidden><FontBody16>***</FontBody16></Hidden>
                    </HalfRight>
                </div>
                <Spacer />
                <TextInput
                    title={<FontHeader16>Card Holder*</FontHeader16>}
                    valid={true}
                    warning={"Required"}
                    placeholder={"Card Holder"}
                    value={name}
                    onChange={handleChangeName}
                />
                <Spacer />
                <TextInput
                    title={<FontHeader16>Billing Zip*</FontHeader16>}
                    valid={true}
                    warning={"Required"}
                    placeholder={"Zip Code"}
                    value={postal}
                    onChange={handleChangePostal}
                />
                <Spacer />
                <ButtonWide>
                    {isValid
                        ? <ButtonPrimary canSubmit={true} label={"Save"} />
                        : <ButtonInactive canSubmit={false} label={"Save"} />
                    }
                </ButtonWide>
            </StripeComponents>
            {props.showDeleteButton
                ? <FontBody14><Delete onClick={props.onDeleteCard}><Trash />&nbsp;<u>Delete card</u></Delete></FontBody14>
                : <FontBody14><Delete onClick={props.onClose}><u>Close</u></Delete></FontBody14>
            }
        </Form>
    )
}

export default CheckoutCardsEditStripe;