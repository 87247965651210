/*
This is pollyfill for matches.
There is bug in combination of react-trello/trello-smooth-dnd pollyfill and swiper.js
Problem occurs when user clicks on swiper slide, it is searching on all parents of clicked element for slide.
One of parent elements is document. Error occurs on line this.document.querySelectorAll.
To fix this, we're just checking if this === document.
*/
(function(constructor) {
    if (constructor && constructor.prototype && !constructor.prototype.mathces) {
      constructor.prototype.matches =
        constructor.prototype.matchesSelector ||
        constructor.prototype.mozMatchesSelector ||
        constructor.prototype.msMatchesSelector ||
        constructor.prototype.oMatchesSelector ||
        constructor.prototype.webkitMatchesSelector ||
        function(s) {
            if (this === document)
                var doc = this;
            else
                var doc = (this.document || this.ownerDocument)
          var matches = doc.querySelectorAll(s),
            i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) {}
          return i > -1;
        };
    }
  })(global.Node || global.Element);