import styled from "styled-components";
import { colors } from "../../../common/components/colors";

export const BackTop = styled.div`
    color: ${colors.secondary70};
    cursor: pointer;
    position: relative;
    text-align: left;
    display: block;
    width: fit-content;
    margin-bottom: 20px;
    margin-left: 20px;
`;

export const SettingsCard = styled.div`
    border-radius: 30px;
    background-color: ${colors.white};
    color: ${colors.primary100};
    margin: 0px auto 40px auto;
    position: relative;
    width: 100%;
    ${props => props.entryPoint !== "advisor"} {
        -webkit-box-shadow: none;
        box-shadow: none;
        width: 100%;
    }
    ${props => props.entryPoint === "advisor"} {
        -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    }
`;

export const ActionBox = styled.div`
    position: fixed;
    bottom: 0;
    left: 360px;
    right: 40px;
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    border-radius: 30px 30px 0px 0px;
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.1);
    z-index: 3;
    @media screen and (max-width: 1088px) {
        left: 0;
        right: 0;
    }
`;

export const PurchaseBox = styled.div`
    ${props => props.clickable !== true} {
        background-color: ${colors.white};
        color: ${colors.white};
        cursor: pointer;
        margin: 30px 40px;
        @media screen and (max-width: 460px) {
            margin: 20px 40px;
        }
        & > div {
            width: calc(100% - 47px);
        }
    }
    ${props => props.clickable !== false} {
        background-color: ${colors.white};
        color: ${colors.white};
        padding: 30px 40px;
        @media screen and (max-width: 460px) {
            color: 20px 40px;
        }
        & > div {
            width: calc(100% - 47px);
        }
    }
    border-radius: 30px 30px 0px 0px;
    width: calc(100% - 80px);
    text-align: center;
    position: relative;
`;

export const LoadingCenter = styled.div`
    margin: 60px auto;
`;