import React from 'react';
import styled from 'styled-components';

/* Containers */
import Success from "../success/containers/success";

const Container = styled.div`
    max-width: 500px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 100px auto 0px auto;
    @media screen and (max-width: 460px) {
      margin: 40px auto 0px auto;
    }
`;

class IFrameSignupAdvisorSuccess extends React.Component {
    render() {
        return(
            <Container>
                <Success
                    title={"CHECK YOUR EMAIL TO ACTIVATE YOUR ACCOUNT"}
                    subtitle={"We sent a verification link to your email that will remain valid for 24 hours."}
                    body={<i>If you do not receive the email within a few minutes, please check your spam folder.</i>}
                    buttonShow={false}
                />
            </Container>
        )
    }
}

export default IFrameSignupAdvisorSuccess;