import React from 'react';
import styled from 'styled-components';

/* Components */
import {FontTitle40, FontBody24, FontBody18, FontBody21} from "../../../common/components/fonts";
import {IconButton} from "../../../common/components/inputs";
import {colors} from "../../../common/components/colors";
import {ArrowUpOutlined} from "@ant-design/icons";

export const Section = styled.div`
  width: calc(100% - 100px);
  padding: 60px 50px;
  gap: 60px;
  @media screen and (max-width: 1680px) {
    width: calc(100% - 120px);
    padding: 40px 60px;
    gap: 40px;
  }
  @media screen and (max-width: 720px) {
    width: calc(100% - 60px);
    padding: 60px 30px;
  }
  display: flex;
  flex-direction: column;
  background-color: ${props => props.backgroundColor};
`;
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
export const ContactTitle = styled(FontTitle40)`
  font-size: 60px !important;
  @media screen and (max-width: 748px) {
    font-size: 40px !important;
  }
  line-height: 1;
  color: ${props => props.color};
`;
export const PreviewBlack = styled(FontBody21)`
  color: ${props => props.color};
  line-height: 2;
  @media screen and (max-width: 748px) {
    font-size: 18px !important;
  }
`;
export const PreviewBlackSmall = styled(FontBody18)`
  color: ${props => props.color};
  line-height: 2;
`;
export const PreviewWhite = styled(FontBody24)`
  color: ${props => props.color}B3;
  line-height: 2;
  & strong {  
    color: ${props => props.color};
  }
  @media screen and (max-width: 1680px) {
    font-size: 16px !important;
  }
`;
export const ButtonToTop = ({scrollToTop}) => (
    <IconButton
        backgroundColor={colors.primary70}
        iconComponent={ArrowUpOutlined}
        color={colors.white}
        label="hi"
        style={{ display: 'block', margin: '0 auto 0' ,position:"fixed", bottom:"20px", right:"25px"}}
        onClick={scrollToTop}
    />
)