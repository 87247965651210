import {images} from '../../../common/components/images';

export const devConsumerCoachesFromCategory = (isErrored) => {
    if(isErrored) {
        return({"error": "No Advisors for selected category", "errorDetails": "No Advisors for selected category"})
    } else {
        return(
            [
                {
                    "id": 1,
                    "firstName": "Candice",
                    "lastName": "McGarvey",
                    "photo": images.devDataTeamIsabelleAvatar,
                    "publicHandle": "asdfa",
                    "storeHandle": null,
                    "schedulerHandle": null,
                    "expertCategories": [
                        {
                            "categoryId": 1,
                            "categoryName": "Willow Financial Coach"
                        },
                        {
                            "categoryId": 11,
                            "categoryName": "Divorce Coach"
                        }
                    ]
                },
                {
                    "id": 2,
                    "firstName": "Paula",
                    "lastName": "Birmingham",
                    "photo": images.devDataTeamDanielleAvatar,
                    "publicHandle": "asdfa",
                    "storeHandle": null,
                    "schedulerHandle": null,
                    "expertCategories": [
                        {
                            "categoryId": 1,
                            "categoryName": "Willow Financial Coach"
                        },
                        {
                            "categoryId": 12,
                            "categoryName": "Life Coach"
                        }
                    ]
                },
            ]
        )
    }
};

export const devConsumerCareParentCategories = [
    {
        "id": 5,
        "title": "Career",
        "subtitle": "Something about careers",
        "description": "Test",
        "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089764/kqvetvetvbeuffyetota.png",
        "parentCategoryId": null
    },
    // {
    //     "id": 2,
    //     "title": "Retirement",
    //     "subtitle": "Something about finances",
    //     "description": "Test",
    //     "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089788/jgum0huzefh5a2zjiznh.png",
    //     "parentCategoryId": null
    // },
    {
        "id": 14,
        "title": "Dating & Marriage",
        "subtitle": "Something about finances",
        "description": "Test",
        "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089807/tceqco11kedlukuz0f9z.png",
        "parentCategoryId": null
    },
    {
        "id": 2,
        "title": "Divorce",
        "subtitle": "Something about finances",
        "description": "Test",
        "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089822/fjkbuzjzwhs10ct7cvll.png",
        "parentCategoryId": null
    },
    {
        "id": 4,
        "title": "Family Building",
        "subtitle": "Something about finances",
        "description": "Test",
        "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089838/t0ykpoigjmrqovjk3iop.png",
        "parentCategoryId": null
    },
    {
        "id": 23,
        "title": "Health",
        "subtitle": "Something about finances",
        "description": "Test",
        "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089853/jno2lqxcniwz4r6zolaj.png",
        "parentCategoryId": null
    },
    {
        "id": 3,
        "title": "Grief & Loss",
        "subtitle": "Something about finances",
        "description": "Test",
        "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636738052/qnghe6myev2trzymbjel.jpg",
        "parentCategoryId": null
    },
    {
        "id": 1,
        "title": "Financial",
        "subtitle": "Something about finances",
        "description": "test",
        "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089868/sob4gvhdrpgbk73kcxcg.png",
        "parentCategoryId": null
    }
];

export const devConsumerCareSubCategories = {
    "parentCategory": {
        "id": 1,
        "title": "Financial",
        "subtitle": "Financial",
        "description": "Financial",
        "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089868/sob4gvhdrpgbk73kcxcg.png",
        "parentCategoryIds": []
    },
    "childCategories": [
        {
            "id": 8,
            "title": "Savings",
            "subtitle": "Savings",
            "description": "Savings",
            "photoUrl": "https://picsum.photos/200/300",
            "parentCategoryId": 1
        },
        {
            "id": 13,
            "title": "Investing & 401k",
            "subtitle": "Investing & 401k",
            "description": "Investing & 401k",
            "photoUrl": "https://picsum.photos/200/300",
            "parentCategoryId": 1
        }
    ]
};