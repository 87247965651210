import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment/moment';

/* Middleware */
import {tryGetBillingHistory} from '../middleware/billing';
import {LogoLoading} from "../../../common/components/loading";

class BillingHistory extends React.Component {
    state={"isLoading": true, "history": []};

    componentDidMount() {
        return this.init()
    }

    init = async () => {
        await this.props.tryGetBillingHistory();
        this.setState({
            "history": this.props.history.toJS(),
            "isLoading": false
        });
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <>
                    <div>BILLING HISTORY</div>
                    {this.state.history.map(h => (
                        <div key={h.id}>
                            <span>{moment(h.datetime).format('ll')}</span>
                            <span>${(parseInt(h.amount)/100).toFixed(2)}</span>
                            <span>{h.product}</span>
                        </div>
                    ))}
                </>
            )
        }
    }

}

const mapStateToProps = state => ({
    history: state.enterprise.billing.billing.get("history")
});

const mapDispatchToProps = dispatch => ({
    tryGetBillingHistory: () => dispatch(tryGetBillingHistory())
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingHistory);
