import { Map, fromJS } from 'immutable';

/* Immutable */

const CLIENTS_DETAIL_SELECTED = 'CLIENTS_DETAIL_SELECTED';
const CLIENTS_DETAIL_SELECTED_EDIT = 'CLIENTS_DETAIL_SELECTED_EDIT';
const CLIENTS_DETAIL_STORE_RESET = 'CLIENTS_DETAIL_STORE_RESET';

/* Actions */

export const setClientsDetailSelected = (data) => ({'type': CLIENTS_DETAIL_SELECTED, 'client': data});
export const setClientsDetailSelectedEdit = (edit) => ({'type': CLIENTS_DETAIL_SELECTED_EDIT, 'edit': edit});
export const setClientsDetailStoreReset = () => ({'type': CLIENTS_DETAIL_STORE_RESET});

/* Initial State */

const initialState = Map({
    client: Map()
});

/* Reducer */

const clientsDetailReducer = (state=initialState, action) => {
    switch (action.type) {
        case CLIENTS_DETAIL_SELECTED:
            console.log(action.client);
            console.log(action.client.contactId);
            return state.merge({'client': fromJS(action.client)});
        case CLIENTS_DETAIL_SELECTED_EDIT:
            return state.merge({
                'client': fromJS({...state.get("client").toJS(), ...action.edit})
            });
        case CLIENTS_DETAIL_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default clientsDetailReducer;
