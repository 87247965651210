import React from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import moment from "moment";
import {Sun, Sunset, Sunrise, ArrowRight} from 'react-feather';

/* Components */
import {colors} from '../../../common/components/colors';
import {LogoLoading, LogoLoadingSmall} from "../../../common/components/loading";
import {FontTitle18, FontHeader16, FontHeader21} from "../../../common/components/fonts";
import {Card, Icon, Body, Title, Action, Inline, ActionLink, BottomBorder5, Content} from '../components/affirmations';
import {QuillSlim} from "../../../common/components/richtexteditor";
import affirmationsList from '../static/affirmations';
import { BulbOutlined } from '@ant-design/icons';

/* Components */
const Section = styled.div`
    padding: 20px;
    background-color: ${colors.border30};
    max-height: 100%;
    @media screen and (max-width: 460px) {
        padding: 5px;
    }
`;

export const InlineAction = styled.div`
    display: inline-block;
    vertical-align: top;
`;

export const DateItem = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-top: 3px;
    text-align: left;
    width: 150px;
    @media screen and (max-width: 1440px) {
        margin-top: 8px;
    }
    @media screen and (max-width: 1088px) {
        margin-top: 3px;
    }
`;

const NoPadding = styled.div`
    margin: 0px;
    padding: 0px;
    cursor: default;
`;

const Arrow = styled.div`
    display: inline;
    color: ${colors.action100};
    text-align: center;
    vertical-align: middle;
`;



class Affirmations extends React.Component {
    state={
        "isLoading": true,
        "quote": "",
        "time": "It is currently " + moment().format('h:mm A') + " on " + moment().format('MMMM Do, YYYY')
    };

    intervalID = 0;

    componentDidMount() {
        return this.init()
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    init = async () => {
        const quote = await affirmationsList[Math.floor(Math.random()*affirmationsList.length)];
        this.updateTime()
        await this.setState({"quote": quote, "isLoading": false})
    };

    updateTime = () => {
    this.intervalID = setInterval(() => {
        this.setState({"time": "It is currently " + moment().format('h:mm A') + " on " + moment().format('MMMM Do, YYYY')})
    }, 1000 * 1)
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {

            let timeOfDay;
            const currentHour = parseFloat(moment().format("HH"));
            if(currentHour >= 12 && currentHour < 17) {
                timeOfDay = "afternoon";
            } else if(currentHour >= 17) {
                timeOfDay = "evening";
            } else {
                timeOfDay = "morning";
            }

            return(
                <Section>
                    <Card>
                        <Icon>{timeOfDay === "morning" ? <Sunrise/> : timeOfDay === "afternoon" ? <Sun/> : <Sunset/>}</Icon>
                            <Body>
                                <div>
                                    <Title><FontTitle18>{"Good " + timeOfDay + ", " + this.props.user.get("first") + "!"}</FontTitle18></Title>
                                    <Action>
                                        <InlineAction>
                                            <Inline><ActionLink><NoPadding><FontHeader16>{this.state.time}</FontHeader16></NoPadding></ActionLink></Inline>
                                        </InlineAction>
                                    </Action>
                                </div>
                            <Content>
                                <BottomBorder5>
                                    <FontHeader16>{""}</FontHeader16>
                                </BottomBorder5>
                                <br />
                                <br />
                                <Arrow>
                                    <FontHeader21>
                                        <BulbOutlined />&nbsp;{this.state.quote}
                                    </FontHeader21>
                                </Arrow>
                                <br />
                            </Content>
                            </Body>
                        </Card>
                </Section>
            )
        }
    }
}

const mapStateToProps = state => ({
    isWMC: state.common.wmc.get("isWMC"),
    user: state.common.user
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Affirmations));