import { combineReducers } from 'redux';

/* Common */
import signupExpertReducer from "./store/signupexpert";

const signupExpertsReducer = combineReducers({
    signupExpert: signupExpertReducer,
});

export default signupExpertsReducer;
