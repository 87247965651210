import React from "react";
import { withRouter } from 'react-router-dom';

/* Components */
import {ImageHighlighted, TitleBox2, HighlightedContainer, Olive} from "../components/courseone";
import {FontTitle24} from '../../../common/components/fonts';

class LearnContentLargePhoto extends React.Component {

    viewCourse = async () => {
        if(this.props.data.isReadingMaterial) {
            if(this.props.data.isVideo) {
                return this.props.history.push("/learn/video/"+this.props.data.id)
            } else {
                return this.props.history.push("/learn/article/"+this.props.data.id)
            }
        } else {
            return this.props.history.push("/learn/course/"+this.props.data.id)
        }
    };

    render() {
        return(
            <HighlightedContainer onClick={this.viewCourse}>
                <ImageHighlighted src={this.props.data.imageUrl} alt={""}/>
                <TitleBox2>
                    <FontTitle24><Olive>{this.props.data.title}</Olive></FontTitle24>
                </TitleBox2>
            </HighlightedContainer>
        )
    }
}

export default withRouter(LearnContentLargePhoto);
