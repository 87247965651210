import { combineReducers } from 'redux';

/* Reducers */
import messagesByIdReducer from './store/messages';

const smsByIdReducer = combineReducers({
    messages: messagesByIdReducer,
});

export default smsByIdReducer;
