import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {
    setAnnouncements,
    setAnnouncementsFetching,
    setAnnouncementsPosting,
    setAnnouncementCreated
} from "../store/announcements";

/* Static */
import {
    devEnterpriseAnnouncements
} from '../static/devdata';

export const tryGetAnnouncementsAll = () => {
    return async dispatch => {
        dispatch(setAnnouncementsFetching(true));
        const announcements = await axiosReq('apiv1/coach/get-announcements', "GET", {}, devEnterpriseAnnouncements);
        await dispatch(setAnnouncements(announcements, 'all'));
        return dispatch(setAnnouncementsFetching(false));
    }
};

export const tryGetAnnouncementsLimited = (number) => {
    return async dispatch => {
        dispatch(setAnnouncementsFetching(true));
        const announcements = await axiosReq('apiv1/coach/get-announcements/' + number, "GET", {}, devEnterpriseAnnouncements);
        await dispatch(setAnnouncements(announcements, 'limited'));
        return dispatch(setAnnouncementsFetching(false));
    }
};

export const tryCreateAnnouncement = (title, body) => {
    return async (dispatch, getState) => {
        dispatch(setAnnouncementsPosting(true));
        const listing = await axiosReq('apiv1/coach/announcements', "POST", {"title": title, "body": body}, devEnterpriseAnnouncements);
        await dispatch(setAnnouncementCreated(listing));
        dispatch(setAnnouncementsPosting(false));
    }
};

export const tryCreateAnnouncementSupport = (title, body, wmcId) => {
    return async (dispatch, getState) => {
        dispatch(setAnnouncementsPosting(true));
        const listing = await axiosReq('apiv1/coach/announcements/' + wmcId, "POST", {"title": title, "body": body}, devEnterpriseAnnouncements);
        await dispatch(setAnnouncementCreated(listing));
        dispatch(setAnnouncementsPosting(false));
    }
};

export const tryGetAdvisorSessionStep = (advisorId) => {
    return async dispatch => {
        return axiosReq('apiv1/coach/certification/'+advisorId+'/can-make-session-review', "GET", {}, {"isAdvisor": true, "hasPaidForCert": true, "hasPassedSessionStep": false, "coachReviewSessions": [{"conferenceId": 0, "passed":null}]});
    }
};

export const tryPostAdvisorSessionStep = (response) => {
    return async dispatch => {
        return axiosReq('apiv1/coach/certifcation/session/review', "POST", response, {});
    }
};