
/* Middleware - tryGetNoticesSessions */
export const devNoticesSessionsData = {
    // "id": 1,
    // "remoteUserName": "Gabby Coach",
    // "thisUserName": "Jason Aronson",
    // "thisUserPlatform": "coach",
    // "start": "2020-05-15T04:30:00+00:00",
    // "end": "2020-05-15T05:00:00+00:00",
    // "phone": "617-380-4313",
    // "room": "277861",
    // "videoStatus": "conference_ongoing", // conference_canceled, conference_ended, conference_has_not_yet_started, conference_ongoing
    // "videoUrl": "3e779494336c3a077d781b7c43377396", // If videoStatus !== conference_ongoing, videoUrl = null
    // "videoAccessToken": null, // If videoStatus !== conference_ongoing, videoAccessToken = null
    // "videoLinkSent": false,
    // "videoLinkClicked": false
};