import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setEventsAll, setEventsAllFetching} from "../store/events";

/* Static */
import {devIframeEvents} from "../static/devdata";

export const tryGetEventsAll = (userId) => {
    return async dispatch => {
        dispatch(setEventsAllFetching(true));
        const listings = await axiosReq('apiv1/public/coach/'+userId+'/expert-coaching-products', "GET", {}, devIframeEvents);
        const events = listings.coachingProducts.filter(f => {return(f.serviceType === "event" && f.status.toLowerCase() === "active")});
        dispatch(setEventsAll(events));
        return dispatch(setEventsAllFetching(false));
    }
};
