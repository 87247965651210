import React from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';

/* Components */
import {FontTitle18} from '../../../common/components/fonts';

/* Containers */
import ListingType from './listingtype';
import ListingDetails from './listingdetails';
import ListingPrice from './listingprice';

/* Middleware */
import {tryCreateListing, tryUpdateListingAddPlan, tryUpdateListingDeletePlan, tryUpdateListing, tryUpdateListingStatus} from '../middleware/listings';

class Editservice extends React.Component {
    state = {
        page: "details",
        type: {
            "downloadChecked": false,
            "numSessions": 0,
            "lengthSessions": 30,
            "serviceChecked": true,
            "serviceOfflineChecked": false,
            "tangibleChecked": false,
            "fileURL": ""
        },
        details: {
            'title': "",
            'price': 0,
            'numMonths': 0,
            'numSessions': 0,
            'photo': null,
            "photoChanged": false,
            'description': "",
            'activeChecked': true,
            'deactiveChecked': false,
            'terms': "",
            "termsValid": true,
            'privacy': "",
            'privacyValid': true,
            'isValid': true,
            'isChanged': true
        },
        price: {
            "plans": [],
            "newPlans": [],
            "deletedPlans": [],
            "showAddPlan": true,
            "description": '',
            'descriptionValid': true,
            "onlinePurchaseChecked": false,
            'freeChecked': true,
            'customPriceChecked': false,
            "initOnlinePurchaseChecked": false,
            'initFreeChecked': true,
            'initCustomPriceChecked': false,
            'isValid': true,
            'isChanged': true,
            "edit": false
        },
        isSaving: false,
        isSaved: false,
        error: '',
        isValid: true,
        isChanged: false
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.setState({
            ...this.state,
            "type": {
                ...this.state.type,
                "downloadChecked": this.props.listing.get("serviceType") === "download",
                "numSessions": this.props.listing.get("numberOfSessions"),
                "lengthSessions": this.props.listing.get("sessionLength"),
                "downloadUrl": this.props.listing.get("downloadUrl"),
                "serviceChecked": this.props.listing.get("serviceType") === "online",
                "serviceOfflineChecked": this.props.listing.get("serviceType") === "offline"
            },
            "details": {
                ...this.state.details,
                'title': this.props.listing.get("title"),
                'photo': this.props.listing.get("coverPhoto"),
                'description': this.props.listing.get("description"),
                'activeChecked': this.props.listing.get("status") === "Active",
                'deactiveChecked': this.props.listing.get("status") !== "Active",
                'terms': this.props.listing.get("termsOfUseUrl"),
                "termsValid": true,
                'privacy': this.props.listing.get("privacyPolicyUrl"),
                'privacyValid': true,
                'isValid': true,
                'isChanged': true
            }
        });
        await this.convertPlans();
    };

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    convertPlans = async () => {
        return Promise.all(this.props.listing.get("plans").map(async p => {
            let planId = p.get("id");
            let planType = p.get("planType");
            let amount = parseInt(p.get("amount"))/100;
            let description = p.get("description");
            let subscriptionDurationInMonths = p.get("subscriptionDurationInMonths");
            let planBase = {
                "planOpen": false,
                "upfrontPriceValid": true,
                "scheduledMonthlyPrice": 0,
                "scheduledMonthlyPriceValid": true,
                "scheduledNumMonths": 1,
                "scheduledNumMonthsValid": true,
                "subscriptionFrequency": "month",
                "subscriptionPrice": 0,
                "subscriptionPriceValid": true,
                "isValid": false,
                "upfrontChecked": true,
                "upfrontPrice": 0,
                "scheduledChecked": false,
                "subscriptionChecked": false,
                "newPlan": false,
                "deletedPlan": false,
            };
            if(planType === "one_time") {
                if(amount === 0 && description === "") {
                    await this.setState({
                        "price": {
                            ...this.state.price,
                            "onlinePurchaseChecked": false,
                            'freeChecked': true,
                            'customPriceChecked': false,
                            "initOnlinePurchaseChecked": false,
                            'initFreeChecked': true,
                            'initCustomPriceChecked': false,
                            "description": "",
                            "plans": [
                                ...this.state.price.plans,
                                {
                                    ...planBase,
                                    "id": planId,
                                    "upfrontChecked": true,
                                    "scheduledChecked": false,
                                    "subscriptionChecked": false
                                }
                            ]
                        }
                    })

                } else if(amount === 0 && description !== "") {
                    await this.setState({
                        "price": {
                            ...this.state.price,
                            "onlinePurchaseChecked": false,
                            'freeChecked': false,
                            'customPriceChecked': true,
                            "initOnlinePurchaseChecked": false,
                            'initFreeChecked': false,
                            'initCustomPriceChecked': true,
                            "description": description,
                            "plans": [
                                ...this.state.price.plans,
                                {
                                    ...planBase,
                                    "id": planId,
                                    "upfrontChecked": true,
                                    "scheduledChecked": false,
                                    "subscriptionChecked": false
                                }
                            ]
                        }
                    })
                } else if(amount !== 0) {
                    await this.setState({
                        "price": {
                            ...this.state.price,
                            "onlinePurchaseChecked": true,
                            'freeChecked': false,
                            'customPriceChecked': false,
                            "initOnlinePurchaseChecked": true,
                            'initFreeChecked': false,
                            'initCustomPriceChecked': false,
                            "description": "",
                            "plans": [
                                ...this.state.price.plans,
                                {
                                    ...planBase,
                                    "id": planId,
                                    "upfrontChecked": true,
                                    "scheduledChecked": false,
                                    "subscriptionChecked": false,
                                    "upfrontPrice": amount
                                }
                            ]
                        }
                    })
                }
            } else if(planType === "recurring") {
                if(subscriptionDurationInMonths !== null) {
                    await this.setState({
                        "price": {
                            ...this.state.price,
                            "onlinePurchaseChecked": true,
                            'freeChecked': false,
                            'customPriceChecked': false,
                            "initOnlinePurchaseChecked": true,
                            'initFreeChecked': false,
                            'initCustomPriceChecked': false,
                            "description": "",
                            "plans": [
                                ...this.state.price.plans,
                                {
                                    ...planBase,
                                    "id": planId,
                                    "upfrontChecked": false,
                                    "scheduledChecked": true,
                                    "subscriptionChecked": false,
                                    "scheduledMonthlyPrice": amount,
                                    "scheduledNumMonths": p.get("subscriptionDurationInMonths"),
                                }
                            ]
                        }
                    })
                } else {
                    await this.setState({
                        "price": {
                            ...this.state.price,
                            "onlinePurchaseChecked": true,
                            'freeChecked': false,
                            'customPriceChecked': false,
                            "initOnlinePurchaseChecked": true,
                            'initFreeChecked': false,
                            'initCustomPriceChecked': false,
                            "description": "",
                            "plans": [
                                ...this.state.price.plans,
                                {
                                    ...planBase,
                                    "id": planId,
                                    "upfrontChecked": false,
                                    "scheduledChecked": false,
                                    "subscriptionChecked": true,
                                    "subscriptionFrequency": p.get("recurringInterval"),
                                    "subscriptionPrice": amount,
                                }
                            ]
                        }
                    })
                }
            }
            return Promise.resolve();
        }))
    };

    updateListingDetails = async (data) => {
        await this.setState({"details": data, "page": "price"});
    };

    updateListingPrice = async (data) => {
        await this.setState({"price": data});
    };

    updatePage = async (page) => {this.setState({"page": page})};

    createPlans = async () => {
        let sessionBased = this.state.type.serviceChecked ? {"numberOfSessions": this.state.type.numSessions} : {"numberOfSessions": 0};
        if(this.state.price.freeChecked && !this.state.price.initFreeChecked) {
            return([{
                "description": "",
                "orderIndex": 0,
                "amount": 0,
                "newPlan": true,
                "deletedPlan": false,
                ...sessionBased
            }])
        } else if(this.state.price.customPriceChecked && !this.state.price.initCustomPriceChecked) {
            return([{
                "description": this.state.price.description,
                "orderIndex": 0,
                "amount": 0,
                "newPlan": true,
                "deletedPlan": false,
                ...sessionBased
            }])
        } else {
            return Promise.all(this.state.price.newPlans.map((p, index) => {
                if(p.upfrontChecked) {
                    return Promise.resolve({
                        "description": "",
                        "orderIndex": index,
                        "amount": typeof p.upfrontPrice === "string" ? (parseInt(p.upfrontPrice.substring(1))*100) : p.upfrontPrice*100,
                        "newPlan": true,
                        "deletedPlan": false,
                        ...sessionBased
                    });
                }
                if(p.scheduledChecked) {
                    return Promise.resolve({
                        "description": "",
                        "orderIndex": index,
                        "amount": typeof p.scheduledMonthlyPrice === "string" ? (parseInt(p.scheduledMonthlyPrice.substring(1))*100) : p.scheduledMonthlyPrice*100,
                        "subscriptionDurationInMonths" : p.scheduledNumMonths,
                        "recurringInterval": "month",
                        "newPlan": true,
                        "deletedPlan": false,
                        ...sessionBased
                    });
                }
                if(p.subscriptionChecked) {
                    return Promise.resolve({
                        "description": "",
                        "orderIndex": index,
                        "amount": typeof p.subscriptionPrice === "string" ? (parseInt(p.subscriptionPrice.substring(1))*100) : p.subscriptionPrice*100,
                        "recurringInterval": p.subscriptionFrequency,
                        "newPlan": true,
                        "deletedPlan": false,
                        ...sessionBased
                    });
                }
            }));
        }
    };

    deletePlans = async () => {
        if((this.state.price.freeChecked && !this.state.price.initFreeChecked) ||
            (this.state.price.customPriceChecked && !this.state.price.initCustomPriceChecked)) {
            const deletedPlans = this.state.price.deletedPlans;
            const plans = this.state.price.plans.filter(n => {return(!n.newPlan)});
            Array.prototype.push.apply(deletedPlans, plans);
            return(deletedPlans);
        } else {
            return(this.state.price.deletedPlans);
        }
    };

    handleSubmit = async () => {
        if(this.state.isValid) {
            this.setState({'isSaving': true});
            // Update details
            const data = {
                "title": this.state.details.title,
                "description": this.state.details.description,
                "coverPhotoBase64": this.state.details.photo,
                "photoChanged": this.state.details.photoChanged,
                "termsOfUseUrl": this.state.details.terms,
                "privacyPolicyUrl": this.state.details.privacy,
                "downloadUrl": this.state.type.downloadChecked ? this.state.type.fileURL : "", // this.state.type.downloadChecked ? this.state.type.fileBlob : "",
                "sessionLength": this.state.type.serviceChecked ? this.state.type.lengthSessions : 0,
                "serviceType": (this.state.type.serviceChecked ? "online" : (this.state.type.downloadChecked ? "download" : "offline")),
            };
            await this.props.tryUpdateListing(this.props.listing.get("id"), data);
            // Update status
            const newStatus = {"status": this.state.details.activeChecked ? "active" : "disabled"};
            await this.props.tryUpdateListingStatus(this.props.listing.get("id"), newStatus);
            // Add plans
            const newPlans = await this.createPlans();
            if(newPlans.length > 0) {
                await Promise.all(newPlans.map(p => {
                    this.props.tryUpdateListingAddPlan(this.props.listing.get("id"), p);
                    return Promise.resolve();
                }));
            }
            // Delete plans
            const deletedPlans = await this.deletePlans();
            if(deletedPlans.length > 0) {
                await Promise.all(deletedPlans.map(p => {
                    this.props.tryUpdateListingDeletePlan(p.id, {"status": "deleted"});
                    return Promise.resolve();
                }));
            }
            // Finish
            this.setState({'isSaving': false, 'isSaved': true});
            this.timeout = setTimeout(() => {
                this.setState({'isSaved': false});
                this.props.history.push("/products");
            }, 3000);
        }
    };

    render() {
        if(this.state.isSaved) {
            if(this.state.error === "") {
                return(<FontTitle18>Updated!</FontTitle18>)
            } else if(this.state.error !== '') {
                return(<FontTitle18>{this.state.error}</FontTitle18>)
            }
        } else {
            return(
                <>
                    {this.state.page === "details" &&
                        <ListingDetails
                            back={() => {this.props.history.push("/products")}}
                            next={this.updateListingDetails}
                            data={this.state.details}
                        />
                    }
                    {this.state.page === "price" &&
                        <ListingPrice
                            back={() => {return this.updatePage("details")}}
                            save={this.updateListingPrice}
                            next={this.handleSubmit}
                            data={this.state.price}
                            downloadChecked={this.state.type.downloadChecked}
                            createPlan={false}
                        />
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    listing: state.enterprise.services.listings.get("listingsOne")
 });

const mapDispatchToProps = dispatch => ({
    tryUpdateListing: (listingId, data) => dispatch(tryUpdateListing(listingId, data)),
    tryUpdateListingStatus: (listingId, data) => dispatch(tryUpdateListingStatus(listingId, data)),
    tryUpdateListingAddPlan: (listingId, data) => dispatch(tryUpdateListingAddPlan(listingId, data)),
    tryUpdateListingDeletePlan: (planId, data) => dispatch(tryUpdateListingDeletePlan(planId, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Editservice));
