import React from "react";
import {connect} from "react-redux";
import moment from 'moment/moment';
import styled from "styled-components";

/* Components */
import {Background, ContentBodySection, Flex, VideoContainer, VideoStartStopIcon, VideoHotspot, BackSection, ContentTypeIcon, Container, AuthorDateSection, IntroSection, TypeSection, Bookmark, BookmarkIcon, TitleSection, ContentTitle, ImageArticle} from "../components/courses";
import {FontBody14, FontTitle34, FontHeader18, FontBody24} from '../../../common/components/fonts';
import {images} from '../../../common/components/images';
import {LogoLoading} from "../../../common/components/loading";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

/* Containers */
import FormsFlow from "../../forms/containers/flow";

/* Middleware */
import {tryPostAssignCourse, tryPostUnassignCourse} from '../middleware/courses';

const AuthorLink = styled.div`
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    font-size: 14px;
    line-height: 18px;
    width: fit-content;
    text-decoration: underline;
    cursor: pointer;
`;
const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;
const Back = styled.div`
  width: fit-content;
  cursor: pointer;
  display: flex;
  padding: 0 5px;
  align-items: center;
`;

class Video extends React.Component {
    static defaultProps = {
        back: () => {},
        openMyCoaches: () => {},
        openFindACoach: () => {},
        openViewCoach: () => {},
        course: {}
    };

    state = {
        "courseAssignmentId": null,
        "willowTeam": false,
        "isLoading": true,
        "videoOpen": false,
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "willowTeam": this.props.course.course.externalAuthor.firstName.includes("Willow"),
            "courseAssignmentId": this.props.course.id === undefined ? null : this.props.course.id,
            "isLoading": false
        });
    };

    openVideo = async () => {
        this.setState({"videoOpen": !this.state.videoOpen})
    };

    onAssign = async () => {
        const assignment = await this.props.tryPostAssignCourse(this.props.course.course.id, this.props.userId);
        this.setState({"courseAssignmentId": assignment.id});
    };

    onUnassign = async () => {
        await this.props.tryPostUnassignCourse(this.state.courseAssignmentId);
        this.setState({"courseAssignmentId": null});
    };

    render() {
        if(this.state.isLoading) {
            return (<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return (
                <Container>
                    <BackSection>
                        <FontBody24><Back onClick={this.props.back}><img src={images.chevronLeftWhite} alt={"<"} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Back</Back></FontBody24>
                    </BackSection>
                    <ImageArticle src={this.props.course.course.imageUrl} alt={""}/>
                    <VideoHotspot onClick={this.openVideo}>
                        <VideoStartStopIcon src={images.contentVideoStartStopIcon} alt={""} />
                    </VideoHotspot>
                    <IntroSection>
                        <TitleSection>
                            <ContentTitle><FontTitle34>{this.props.course.course.title}</FontTitle34></ContentTitle>
                            <Bookmark onClick={this.state.courseAssignmentId === null ? this.onAssign : this.onUnassign}>
                                <BookmarkIcon src={this.state.courseAssignmentId === null ? images.learnBookmarkContent : images.learnUnbookmarkContent} alt={""} />
                            </Bookmark>
                        </TitleSection>
                        <TypeSection>
                            <ContentTypeIcon src={images.contentVideoIcon} alt={""} />
                            <AuthorDateSection>
                                <FontHeader18><b>{this.state.willowTeam ? "Willow Editorial Team" : this.props.course.course.externalAuthor.firstName+" "+this.props.course.course.externalAuthor.lastName}</b></FontHeader18>
                                {(!this.props.isExclusive && !this.state.willowTeam) && <AuthorLink onClick={() => {this.props.openViewCoach(this.props.course.coach.coachId)}}>Schedule an appointment</AuthorLink>}
                                <FontBody14><i>{moment(this.props.course.course.createdOn).format("LL").toString()}</i></FontBody14>
                                {(!this.props.isExclusive && this.state.willowTeam) && <AuthorLink onClick= {this.props.openFindACoach}>Speak with a coach</AuthorLink>}
                                {this.props.isExclusive && <AuthorLink onClick= {this.props.openMyCoaches}>Speak with my coach</AuthorLink>}
                            </AuthorDateSection>
                        </TypeSection>
                    </IntroSection>
                    <ContentBodySection>
                        <FormsFlow
                            form={this.props.course.tasks[0].task.pages}
                            skipAuth={true}
                            onCompleteFormMethod={"return"}
                            onReturnForm={this.props.back}
                            formHasOnePage={true}
                            confirmOnClose={this.props.back}
                        />
                    </ContentBodySection>
                    {this.state.videoOpen &&
                        <Flex>
                            <VideoContainer
                                src={this.props.course.tasks[0].task.pages[0].elements[0].value}
                                controls={true}
                            />
                            <Background onClick={this.openVideo} />
                        </Flex>
                    }
                </Container>
            )
        }
    }
}

const mapStateToProps = state => ({
    userId: state.common.user.get("userId"),
    isExclusive: state.common.user.get("isExclusive"),
});

const mapDispatchToProps = dispatch => ({
    tryPostAssignCourse: (courseId, userId) => dispatch(tryPostAssignCourse(courseId, userId)),
    tryPostUnassignCourse: (courseAssignmentId) => dispatch(tryPostUnassignCourse(courseAssignmentId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Video);
