import React from 'react';
import styled from 'styled-components';

/* Components */
import {colors} from '../../../common/components/colors';

export const Page = styled.div`
    ${props => props.status !== false} {display: none;}
    ${props => props.status !== true} {display: block;}
    // position: fixed;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // z-index: 4;
    background-color: ${colors.background100};
`;

export const Modal = styled.div`
    -webkit-box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    background-color: ${colors.white};
    border-radius: 4px;
    padding: 30px;
    color: ${colors.primary100};
    max-width: 320px;
    // z-index: 5;
    position: relative;
    margin: 60px auto 0px auto;
    @media screen and (max-width: 748px) {
        margin: 60px 20px 0px 20px;
    }
    @media screen and (max-width: 460px) {
        margin: 20px 0px;
        padding: 20px;
    }
`;

export const NoteTitle = styled.div`
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.border100};
    color: ${colors.secondary100};
`;

export const NoteActions = styled.div`
    padding-top: 30px;
`;

export const ActionLeft = styled.div`
    display: inline-block;
    cursor: pointer;
`;

export const ActionRight = styled.div`
    display: inline-block;
    width: calc(100% - 65px);
    text-align: right;
`;

export const Label = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 4px 0px;
    width: 65px;
    color: ${props => props.color};
    text-align:left
`;

export const Delete = styled.div`
    text-align: center;
    padding: 5px 25px;
    color: ${colors.primary30};
    width: calc(100% - 50px);
    margin: 40px auto 0px auto;
    cursor: pointer;
`;

export const NoteSubtitle = styled.div`
    margin-top: 20px;
`;