import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';

/* Components */
import {UploadAvatar} from "../../../../common/components/inputs";
import {SavedSettings} from '../../../../common/components/cards';
import {ButtonInactive, ButtonPrimary, ButtonTertiary} from "../../../../common/components/buttons";
import {LogoLoading} from "../../../../common/components/loading";

/* Middleware */
import {tryUpdateExpertProfile} from '../middleware/expertprofile';

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

const SaveSection = styled.div`
    text-align: left;
    padding-top: 20px;
`;
const InlineBlock = styled.div`
    display: inline-block;
`;
const ButtonRight = styled.div`
    margin-right: 10px;
`;
const SubmitInputWithValidation = ({ isChanged, isValid, isSaving, label }) => {
    if(isChanged && isValid) {
        if(isSaving) {
            return (<ButtonRight><ButtonInactive canSubmit={true} label={"Saving"} /></ButtonRight>)
        } else {
            return (<ButtonRight><ButtonPrimary canSubmit={true} label={label}/></ButtonRight>)
        }
    } else {
        return(<ButtonRight><ButtonInactive canSubmit={true} label={label}/></ButtonRight>)
    }
};

class SchedulerAvatarChange extends React.Component {
    state = {
        photo: '', photoComponent: '', photoChanged: false, photoBlob: '', photoPrevious: "", photoComponentPrevious: "",
        isSaving: false,
        isSaved: false,
        error: '',
        isValid: true,
        isChanged: false,
        isLoading: true,
    };

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    init = async () => {
        await this.setState({
            "photo": this.props.profile.get("photo"),
            "photoComponent": <UploadAvatar photo={this.props.profile.get("photo")} onChange={this.handleChangePhoto} />,
            "photoPrevious": this.props.profile.get("photo"),
            "photoComponentPrevious": <UploadAvatar photo={this.props.profile.get("photo")} onChange={this.handleChangePhoto} />,
            "isLoading": false
        });
    };

    handleChangePhoto = async e => {
        this.setState({'photoChanged': true, 'photoBlob': e, 'isChanged': true});
    };

    reset = async () => {
        this.setState({
            "photo": this.state.photoPrevious,
            "photoComponent": <UploadAvatar photo={this.state.photoPrevious} onChange={this.handleChangePhoto} />,
            "photoChanged": false,
            "isChanged": false
        });
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            await this.setState({'isSaving': true, "error": ""});
            const photo = {
                "photoBlob": this.state.photoBlob,
                "photoChanged": this.state.photoChanged
            };
            await this.props.tryUpdateExpertProfile(photo, "profilePhotoError");
            if(this.props.profile.get("profilePhotoError") === "") {
                this.setState({
                    'isSaving': false,
                    "isSaved": true,
                    "photo": this.props.profile.get("photo"),
                    "photoComponent": <UploadAvatar photo={this.props.profile.get("photo")} onChange={this.handleChangePhoto} />,
                    "photoBlob": "",
                    "photoChanged": false,
                    "photoPrevious": this.props.profile.get("photo"),
                    "photoComponentPrevious": <UploadAvatar photo={this.props.profile.get("photo")} onChange={this.handleChangePhoto} />,
                });
                this.timeout = setTimeout(() => {
                    this.setState({'isSaved': false, "isChanged": false});
                    this.props.handleEdit(false);
                }, 3000);
            } else {
                this.setState({'isSaved': true, 'isSaving': false, "error": this.props.profile.get("profilePhotoError")});
                this.timeout = setTimeout(() => {this.setState({'isSaved': false, "error": ""})}, 3000);
            }
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if(this.state.isSaved) {
                return(
                    <>
                        {this.state.error === '' && <SavedSettings height={"99px"} error={false} message={"Saved"}/>}
                        {this.state.error !== '' && <SavedSettings height={"99px"} error={true} message={this.state.error}/>}
                    </>
                )
            } else {
                return(
                    <form method={"post"} onSubmit={this.handleSubmit}>
                        {this.state.photoComponent}
                        <SaveSection>
                            <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                            <InlineBlock><div onClick={() => {return this.props.handleEdit(false)}}><ButtonTertiary canSubmit={false} label={"Cancel"} /></div></InlineBlock>
                        </SaveSection>
                    </form>
                )
            }
        }
    }
}

const mapStateToProps = state => ({
    profile: state.enterprise.scheduler.expertProfile.get("expertProfile"),
});

const mapDispatchToProps = dispatch => ({
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey))
});

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerAvatarChange);
