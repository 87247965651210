import React from 'react';
import validator from 'validator';

/* Components */
import {TextQuillInputWhite, TextInputWhite, RadioBoxWithLabelWhite, InputLabel} from "../../../common/components/inputs";
import {SaveSection, SubmitInputWithValidation} from '../../components/clientscreate';
import {FontBody16, FontBody14, FontTitle18, FontBody12} from '../../../common/components/fonts';
import {CameraOutlined} from '@ant-design/icons';
import {ButtonTertiary} from "../../../common/components/buttons";
import {Width, FitContent, PhotoButton, PhotoMargin, PhotoUpload, Section, SplitCenter, SplitLeft, SplitRight, Inline, InlineCheckbox, Photo, Type2} from '../components/listings';

class ListingDetails extends React.Component {
    state = {
        title: '', titleValid: true,
        photo: '', photoChanged: false, photoBlob: '',
        description: '', descriptionValid: true, descriptionCount: 0, descriptionComponent: '',
        activeChecked: true, deactiveChecked: false,
        terms: "", termsValid: true,
        privacy: "", privacyValid: true,
        isValid: true,
        isChanged: false
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.data !== this.props.data) {
            return this.init();
        }
    }

    init = async () => {
        if(this.props.data.length !== 0) {
            await this.setState({...this.props.data})
        }
        await this.updateDescription();
    };

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    tryChangePhoto = async () => {
        document.getElementById('selectedFile').click()
    };

    tryRemovePhoto = async () => {
        await this.setState({photo: '', photoChanged: false, photoBlob: ''});
        this.checkValid();
    };

    handleFetchImageBlob = () => {
        this.editor.getImageScaledToCanvas().toBlob(blob => {this.props.onChange(blob);});
    };

    handleChangePhoto = async (e) => {
        // await this.handleFetchImageBlob();
        await this.setState({
            'photo': e.target.files[0],
            'photoChanged': true,
            'photoBlob': e,
            'isChanged': true
        });
        this.checkValid();
    };

    updateDescription = () => {
        this.setState({descriptionComponent: <TextQuillInputWhite
                title={"Description*"}
                valid={this.state.descriptionValid}
                warning={"Required"}
                id={"description"}
                onChange={this.handleChangeDescription}
                placeholder={"Describe your listing"}
                value={this.state.description}
                maxLength={3000}
                count={this.state.descriptionCount}
                minRows={8}
                required={true}
            />
        })
    };

    handleChangeDescription = async e => {
        await this.setState({
            'description': e,
            'descriptionValid': (!validator.isEmpty(e) && e !== '<p><br></p>' && e !== ''),
            'descriptionCount': (validator.isEmpty(e) || e === '<p><br></p>' || e === '') ? 0 : e.replace( /(<([^>]+)>)/ig, '').length,
            'isChanged': true
        });
        await this.updateDescription();
        this.checkValid();
    };

    handleChangeTitle = async e => {
        await this.setState({
            'title': e.target.value,
            'titleValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''),
            'isChanged': true
        });
        this.checkValid();
    };

    handleChangeTerms = async e => {
        await this.setState({
            'terms': e.target.value,
            'termsValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''),
            'isChanged': true
        });
        this.checkValid();
    };

    handleChangePrivacy = async e => {
        await this.setState({
            'privacy': e.target.value,
            'privacyValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''),
            'isChanged': true
        });
        this.checkValid();
    };

    handleActivate = async () => {
        await this.setState({"activeChecked": true, "deactiveChecked": false, 'isChanged': true});
        this.checkValid();
    };

    handleDeactivate = async () => {
        await this.setState({"activeChecked": false, "deactiveChecked": true, 'isChanged': true});
        this.checkValid();
    };

    checkValid = () => {
        let titleValid = (!validator.isEmpty(this.state.title) && this.state.title !== '');
        let descriptionValid = (!validator.isEmpty(this.state.description) && this.state.description !== '<p><br></p>' && this.state.description !== '');
        if(descriptionValid && titleValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            return this.props.next(this.state)
        }
    };

    render() {
        return(
            <Width>
                <form method={"post"} onSubmit={this.handleSubmit}>
                    <Section>
                        <InputLabel><FontTitle18>Tell the world why they'll love your product!</FontTitle18></InputLabel>
                        <br />
                        <TextInputWhite
                            title={"Title*"}
                            valid={this.state.titleValid}
                            warning={"Required"}
                            id={"title"}
                            onChange={this.handleChangeTitle}
                            placeholder={"Give it a name"}
                            value={this.state.title}
                        />
                        {this.state.descriptionComponent}
                        <br />
                        {(this.state.photo === "" || this.state.photo === null) &&
                            <>
                                <PhotoMargin><FontBody16>Photo</FontBody16></PhotoMargin>
                                <PhotoButton onClick={this.tryChangePhoto}>
                                    <CameraOutlined />
                                    <FontBody14>Add a photo</FontBody14>
                                </PhotoButton>
                                <PhotoUpload id={"selectedFile"} type={"file"} onChange={this.handleChangePhoto} accept={".png,.jpeg,.jpg,.gif"} />
                            </>
                        }
                        {(this.state.photo !== "" && this.state.photo !== null) &&
                            <>
                                <FontBody16>Photo</FontBody16>
                                <Photo src={this.state.photoChanged ? URL.createObjectURL(this.state.photo) : this.state.photo} alt={"Photo"} />
                                <Inline>
                                    <PhotoButton onClick={this.tryChangePhoto}>
                                        <CameraOutlined />
                                        <FontBody14>Change photo</FontBody14>
                                    </PhotoButton>
                                    <PhotoUpload id={"selectedFile"} type={"file"} onChange={this.handleChangePhoto} accept={".png,.jpeg,.jpg,.gif"} />
                                </Inline>
                                <Inline>
                                    <PhotoButton onClick={this.tryRemovePhoto}>
                                        <CameraOutlined />
                                        <FontBody14>Remove photo</FontBody14>
                                    </PhotoButton>
                                </Inline>
                            </>
                        }
                        <TextInputWhite
                            title={"Terms of Use"}
                            valid={this.state.termsValid}
                            warning={""}
                            id={"terms"}
                            onChange={this.handleChangeTerms}
                            placeholder={"https://..."}
                            value={this.state.terms}
                        />
                        <TextInputWhite
                            title={"Privacy Policy"}
                            valid={this.state.privacyValid}
                            warning={""}
                            id={"privacy"}
                            onChange={this.handleChangePrivacy}
                            placeholder={"https://..."}
                            value={this.state.privacy}
                        />
                        <Type2><InputLabel><FontBody16>Show on Products Page*</FontBody16></InputLabel></Type2>
                        <div>
                            <InlineCheckbox>
                                <RadioBoxWithLabelWhite
                                    id={6}
                                    action={this.handleActivate}
                                    checked={this.state.activeChecked}
                                    label={"Yes"}
                                    subtext={"Anyone can see this product."}
                                    disabled={false}
                                />
                            </InlineCheckbox>
                            <InlineCheckbox>
                                <RadioBoxWithLabelWhite
                                    id={7}
                                    action={this.handleDeactivate}
                                    checked={this.state.deactiveChecked}
                                    label={"No"}
                                    subtext={"Only people with the unique link can see this product."}
                                    disabled={false}
                                />
                            </InlineCheckbox>
                        </div>
                    </Section>
                    <SaveSection>
                        <SplitLeft>
                            <FitContent onClick={this.props.back}><ButtonTertiary canSubmit={false} label={"Back"} /></FitContent>
                        </SplitLeft>
                        <SplitCenter><FontBody12> </FontBody12></SplitCenter>
                        <SplitRight>
                            <FitContent><SubmitInputWithValidation label={"Next"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={false} /></FitContent>
                        </SplitRight>
                    </SaveSection>
                </form>
            </Width>
        )
    }
}

export default ListingDetails;
