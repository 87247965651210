import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setPaymentMethods, setPaymentMethodSetupIntentFetching, setPaymentMethodsFetching, setPaymentMethodDeleting, setPaymentMethodDelete, setPaymentMethodUpdating, setPaymentMethodUpdate} from '../store/cards';

/* Static */
import {devGetPaymentMethods, devDetachPaymentMethod, devUpdatePaymentMethod, devSetupIntentSecret} from '../static/devdata';

export const tryGetPaymentMethods = () => {
    return async dispatch => {
        dispatch(setPaymentMethodsFetching(true));
        const paymentMethods = await axiosReq('apiv1/consumer/settings/payments/get-payment-methods', "GET", {}, devGetPaymentMethods);
        await dispatch(setPaymentMethods(paymentMethods.data));
        dispatch(setPaymentMethodsFetching(false));
        return(paymentMethods.data);
    }
};

export const tryGetPaymentMethodSetupIntent = () => {
    return async dispatch => {
        dispatch(setPaymentMethodSetupIntentFetching(true));
        const setupIntent = await axiosReq('apiv1/consumer/settings/payments/setup-intent', "GET", {}, devSetupIntentSecret);
        dispatch(setPaymentMethodSetupIntentFetching(false));
        return setupIntent;
    }
};

export const tryUpdatePaymentMethod = (data) => {
    return async (dispatch, getState) => {
        dispatch(setPaymentMethodUpdating(true));
        const paymentMethod = await axiosReq('apiv1/consumer/settings/payments/update-payment-method', "POST", data, devUpdatePaymentMethod);
        await dispatch(setPaymentMethodUpdate(paymentMethod));
        const paymentMethodAdj = getState().iframe.cards.cards.get("paymentMethods").filter(p => {return(p.get("id") === data.paymentMethodId)})[0];
        dispatch(setPaymentMethodUpdating(false));
        return paymentMethodAdj;
    }
};

export const tryDeletePaymentMethod = (id) => {
    return async dispatch => {
        dispatch(setPaymentMethodDeleting(true));
        await axiosReq('apiv1/consumer/settings/payments/detach-payment-method', "POST", {"paymentMethodId": id}, devDetachPaymentMethod);
        await dispatch(setPaymentMethodDelete(id));
        return dispatch(setPaymentMethodDeleting(false));
    }
};
