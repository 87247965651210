import React from 'react';
import { Switch } from 'react-router-dom';

/* Containers */
import PublicRoute from '../../../routes/containers/routepublic';

/* Pages - Redirects */
import RedirectAfterLogin from '../../../routes/containers/routeredirectafterlogin';
import RedirectWithoutLogin from '../../../routes/containers/routeredirectwithoutlogin';
import IFrameSignUpAssign from '../../../iframes/signupassign/page';

export const redirectRoutes = [
        '/redirect/availability',
        '/redirect/prospect/:prospectId/notes',
        '/redirect/get-started',
        '/video-room/:roomId',
        '/public/my-willow-page/shop/:handle',
        '/public/my-willow-page/coach/:handle',
        '/public/my-willow-page/advisor/:handle',
        '/public/my-willow-page/schedule/:handle',
        '/public/my-willow-page/firm/:firmId'
];

const RoutesTrustWillowRedirects = () => (
    <Switch>
        <PublicRoute exact path={'/redirect/availability'} component={RedirectAfterLogin} data={{"redirect": '/availability'}}/>
        <PublicRoute exact path={'/redirect/prospect/:prospectId/notes'} component={RedirectAfterLogin} data={{"redirect": '/contact/notes'}}/>
        <PublicRoute exact path={'/redirect/get-started'} component={IFrameSignUpAssign} />
        <PublicRoute exact path={'/video-room/:roomId'} component={RedirectWithoutLogin} data={{"redirect": 'https://live.trustwillow.com/room/', "token": true}} />
        <PublicRoute exact path={'/public/my-willow-page/shop/:handle'} component={RedirectWithoutLogin} data={{"redirect": 'https://secure.trustwillow.com/public/shop/', "token": true}} />
        <PublicRoute exact path={'/public/my-willow-page/coach/:handle'} component={RedirectWithoutLogin} data={{"redirect": 'https://secure.trustwillow.com/public/profile/', "token": true}} />
        <PublicRoute exact path={'/public/my-willow-page/advisor/:handle'} component={RedirectWithoutLogin} data={{"redirect": 'https://secure.trustwillow.com/public/profile/', "token": true}} />
        <PublicRoute exact path={'/public/my-willow-page/schedule/:handle'} component={RedirectWithoutLogin} data={{"redirect": 'https://secure.trustwillow.com/public/schedule/', "token": true}} />
        <PublicRoute exact path={'/public/my-willow-page/firm/:firmId'} component={RedirectWithoutLogin} data={{"redirect": 'https://secure.trustwillow.com/public/profile/lgarcia', "token": false}} />
    </Switch>
);

export default RoutesTrustWillowRedirects;
