import React, { useRef } from "react";
import styled from "styled-components";
import {colors} from "../../../common/components/colors";


/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

import {SearchOutlined} from "@ant-design/icons";

const Search = styled.input`
    font-family: ${env.STYLES.FONTS.header.fontFamily};
    font-size: 16px;
    line-height: 22px;
    color: ${colors.primary100};
    border: none;
    padding: 12px 50px;
    width: calc(100% - 100px);
    borderRadius: 30px;
    backgroundColor: ${colors.border30};
    placeholderColor: ${colors.primary30};
    fontSize: 14px;
    &:focus {
        outline: none;
    } 
`;

const SearchIconEnd = styled.div`
    position: absolute;
    top: 15px;
    right: 18px;
    color: ${colors.primary100};
    &:hover {
        cursor: pointer;
    }
`;
const SearchBar = styled.div`
    position: relative;
`;

const SearchResults = styled.div`
    position: absolute;
    padding: 20px 0px;
    width: 100%;
    background-color: ${colors.border70};
    z-index: 11;
    max-height: 250px;
    overflow-y: scroll;
`;
const SearchResultsEmpty = styled.div`
    position: absolute;
    padding: 20px 0px;
    width: 100%;
    border: 1px solid ${colors.primary30};
    background-color: ${colors.border70};
    z-index: 11;
`;
const SearchResult = styled.div`
    padding: 10px 20px;
    cursor: pointer;
    &:hover {
        color: ${colors.action100};
    }
`;
const BackgroundSearch = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10;
`;

export const InputSection = styled.div`
    margin: '0px'
    text-align: left;
    input, textarea, .ql-container {
        background-color: ${colors.border70};
        color: ${colors.primary100};
        border-radius: 15px;
        width: calc(100% - 40px);
        padding: 10px 20px;
        font-family: ${env.STYLES.FONTS.body.fontFamily};
        font-size: 16px;
        line-height: 22px;
        &::placeholder {
            color: ${colors.primary70};
        }
        &:focus {
            outline: none;
        }
    }
    & .ql-container {
        width: 100% !important;
    }
`;


export class SearchInputUncontrolled extends React.Component {
    static defaultProps = {
        shouldShowResults: true,
        onReturnResults: () => {},
        onChange: () => {},
        onSearchMade: () => {},
        onClick: () => {},
        change: () => {}
    };

    state = {"searchOpen": false, "options": [], "numOptions": 0, input: "", value: ""};

    componentDidMount() {
        this.setState({
            "options": this.props.options,
            "numOptions": this.props.options.length
        })
    }

    handleChange = async (e) => {
        if (e.key == 'Enter') {
            this.search;
        } else {
            await this.setState({"value": e.target.value})
        }
    }

    handleKeyDown = async (e) => {
        if (e.key == 'Enter') {
            console.log("hai")
            this.search(this.state.value)
        }
    }

    search = async (e) => {
        // Send search string to store
        console.log("value", this.state.value)
        if (this.state.value != null) {
            this.props.onSearchMade(this.state.value)
            if(this.state.value === "") {
                await this.setState({
                    "options": this.props.options.filter(l => {
                        return(!l.isArchived)
                    }),
                    "numOptions": this.props.options.length,
                    "searchOpen": true
                });
            } else {
                let options = this.props.options.filter(f => {
                    if(value !== undefined) {
                        return(f.label.toLowerCase().includes(value.toLowerCase()))
                    }
                });
                await this.setState({
                    "options": options,
                    "numOptions": options.length,
                    "searchOpen": true
                });
                this.props.onReturnResults(options);
            }
        }
    };

    selectOption = async (o) => {
        this.props.onChange(o);
        this.setState({
            "searchOpen": false,
            "options": this.props.options,
            "numOptions": this.props.options.length
        })
    };

    closeSearch = async () => {
        this.setState({
            "searchOpen": false,
            "options": this.props.options,
            "numOptions": this.props.options.length
        });
    };

    render() {
        return (
            <InputSection>
                <SearchBar>
                    <Search
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        placeholder={this.props.value != "" ? this.props.value : "Search"}
                    />
                    <SearchIconEnd canClick={this.props.searchIconOnClick} onClick={this.props.searchIconOnClick ? this.search : () => {}}><SearchOutlined /></SearchIconEnd>
                </SearchBar>

            </InputSection>
        )
    }
}