import React from 'react';
import styled from "styled-components";

/* Components */
import {FontBody14, FontBody16, FontHeader21} from "../../../common/components/fonts";
import {ButtonPrimary, ButtonInactiveLight} from "../../../common/components/buttons";
import {VideoCameraOutlined, MailOutlined} from '@ant-design/icons';

/* Static */
import {colors} from "../../../common/components/colors";

const Center = styled.div`
    text-align: center;
`;
const FitContent = styled.div`
    width: fit-content;
    margin: -10px auto 0 auto;
`;
const Category = styled.div`
    color: ${colors.primary100};
    margin-bottom: 16px;
`;

export const Photo = styled.img`
    display: inline-block;
    vertical-align: top;
    width: 150px;
    height: 150px;
    border-radius: 6px;
    object-fit: cover;
    object-position: 0 0;
`;
const Section = styled.div`
    padding: 10px 20px;
    overflow-y: auto;
    height: calc(100% - 80px);
`;

class MemberByCategoryIdModal extends React.Component {
    state = {
        "isLoading": true,
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({"isLoading": false})
    };

    render() {
        if(this.state.isLoading) {
            return null
        } else {
            return(
                <Section>
                    <br />
                    <br />
                    <Center><Photo src={this.props.expert.photo} alt={""} /></Center>
                    <br />
                    <Center><FontHeader21>{this.props.expert.first} {this.props.expert.last}</FontHeader21></Center>
                    <Center><Category><FontBody16>{this.props.title}</FontBody16></Category></Center>
                    <br />
                    <FitContent onClick={() => {return this.props.scheduleMeeting(this.props.expert.photo, this.props.expert.first, this.props.expert.last, this.props.expert.coachId)}}>
                        <ButtonPrimary canSubmit={false} label={<><VideoCameraOutlined />&nbsp;Schedule Meeting</>} />
                    </FitContent>
                    <br />
                    <FitContent onClick={() => {return this.props.writeMessage(this.props.expert.photo, this.props.expert.first, this.props.expert.last, this.props.expert.coachId)}}>
                        <ButtonInactiveLight canSubmit={false} label={<><MailOutlined />&nbsp;Message</>} />
                    </FitContent>
                    <br />
                </Section>
            )
        }
    }
}

export default MemberByCategoryIdModal;
