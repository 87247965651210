import React from 'react';
import {connect} from "react-redux";
import { Route, Redirect } from 'react-router-dom';

export const SecureRoute = ({ component: Component, isUserAuthenticated }) => {
    // Placeholder for tracking PageView or tracking identification of user
    return  <Route render={() => {
        if(isUserAuthenticated === true) {return(<Component />)}
        else {return(<Redirect to={'/verify'} />)}
    }}/>
};

const mapStateToProps = state => ({
    isUserAuthenticated: state.common.user.get('isUserAuthenticated')
});

export default connect(mapStateToProps, null)(SecureRoute);