import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setTeamMemberMeetings, setTeamMemberMeetingsFetching} from "../store/meetings";

/* Static */
import {devTeamMemberMeetingsData} from "../static/devdata";

export const tryGetTeamMemberMeetings = () => {
    return async dispatch => {
        dispatch(setTeamMemberMeetingsFetching(true));
        const meetings = await axiosReq('apiv1/consumer/journey/sessions', "GET", {}, devTeamMemberMeetingsData);
        const meetingsAll = meetings.upcomingCalls.concat(meetings.pastCalls);
        await dispatch(setTeamMemberMeetings(meetingsAll));
        dispatch(setTeamMemberMeetingsFetching(false));
        return meetings;
    }
};