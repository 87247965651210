import React from 'react';
import {withRouter} from 'react-router-dom';

/* Components */
import {PageContentContainer2ShowHide, PageContentContainer4, PageFull, PageFullShowHide} from '../navbars/components/navlayout';
import ConsumerNav from '../navbars/containers/nav';

/* Containers */
import ContentOverview from './containers/contentoverview'
import InsightQuestions from './containers/insightquestions';
import InsightSubcategories from './containers/insightsubcategories';
import ContentAll from './containers/contentall';

/* Pages */
import Course from "../courses/courseentry";

class Learn extends React.Component {
    state = {
        "isContent": false,
        "isCourse": false,
        "isCourseModule": false,
        "isLoading": true
    }

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.location.pathname !== this.props.location.pathname) {
            return this.init();
        }
    }

    init = async () => {
        const path = this.props.location.pathname;
        this.setState({
            "isContent": path.includes('/learn/article') || path.includes('/learn/video'),
            "isCourse": path.includes('/learn/course') && !path.includes('module'),
            "isCourseModule": path.includes('/learn/course') && path.includes('module'),
            "isLoading": false
        })
    }

    render() {
        if(this.state.isLoading) {
            return null
        } else {
            if(this.state.isCourse || this.state.isCourseModule || this.state.isContent) {
                return (
                    <>
                        {!this.state.isCourseModule && <ConsumerNav/>}
                        <PageFullShowHide show={!this.state.isCourseModule}>
                            <PageContentContainer2ShowHide show={!this.state.isContent && !this.state.isCourseModule}>
                                <Course/>
                            </PageContentContainer2ShowHide>
                        </PageFullShowHide>
                    </>
                )
            } else {
                return (
                    <>
                        <ConsumerNav />
                        <PageFull>
                            <PageContentContainer4>
                                {(this.props.location.pathname === "/learn" || this.props.location.pathname === "/learn/search" || this.props.location.pathname === "/learn/bookmarks") && <ContentOverview/>}
                                {this.props.location.pathname === "/learn/insights/details" && <InsightSubcategories/>}
                                {this.props.location.pathname === "/learn/request" && <InsightQuestions/>}
                                {(this.props.location.pathname === "/learn/content" || this.props.location.pathname === "/learn/content/search") && <ContentAll/>}
                            </PageContentContainer4>
                        </PageFull>
                    </>
                )
            }
        }
    }
}

export default withRouter(Learn);
