import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

/* Components */
import { Desktop,  Mobile, BackSection, BackSectionIcon, ProductImage, ProductPrice, ProductTermPrivacy, ProductTitle, Container, Inline, Arrow, Avatar, Back, BackButton } from '../components/product';
import {ArrowLeftOutlined} from "@ant-design/icons";
import {FontHeader16} from '../../../common/components/fonts';
import {ChevronLeft} from "react-feather";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

class Product extends React.Component {
    componentDidMount() {console.log(this.props.plan)}
    render() {
        return(
            <Container inModal={this.props.inModal}>
                {(this.props.showBackBtn && this.props.inModal) &&
                    <BackSection>
                        <BackSectionIcon onClick={this.props.onBack}><ChevronLeft/></BackSectionIcon>
                    </BackSection>
                }
                {(this.props.showBackBtn && !this.props.inModal) &&
                    <BackButton onClick={() => {window.location.replace("https://"+env.URL.DOMAIN+"/shop/"+this.props.expert.get("storeHandle"))}}>
                        <Inline><Arrow><ArrowLeftOutlined/></Arrow></Inline>
                        {!(this.props.expert.get("profilePhotoUrl") === null || this.props.expert.get("profilePhotoUrl") === undefined || this.props.expert.get("profilePhotoUrl") === "") &&
                            <Inline><Avatar src={this.props.expert.get("profilePhotoUrl")} alt={""} /></Inline>
                        }
                        <Inline><Back><FontHeader16>{this.props.expert.get("fullName")}</FontHeader16></Back></Inline>
                    </BackButton>
                }
                <Desktop inModal={this.props.inModal}>
                    <ProductTitle
                        title={this.props.product.get("title")}
                        planType={this.props.plan.get("planType")}
                        description={this.props.product.get("description")}
                        inModal={this.props.inModal}
                    />
                    {this.props.showPrice && 
                        <ProductPrice
                            description={this.props.plan.get("description")}
                            amount={this.props.plan.get("amount")}
                            planType={this.props.plan.get("planType")}
                            recurringInterval={this.props.plan.get("recurringInterval")}
                            subscriptionDurationInMonths={this.props.plan.get("subscriptionDurationInMonths")}
                            inModal={this.props.inModal}
                        />
                    }
                    <ProductImage
                        coverPhoto={this.props.product.get("coverPhoto")}
                        inModal={this.props.inModal}
                    />
                    <ProductTermPrivacy
                        termsOfUseUrl={this.props.product.get("termsOfUseUrl")}
                        privacyPolicyUrl={this.props.product.get("privacyPolicyUrl")}
                        inModal={this.props.inModal}
                    />
                </Desktop>
                <Mobile inModal={this.props.inModal}>
                    <ProductImage
                        coverPhoto={this.props.product.get("coverPhoto")}
                        inModal={this.props.inModal}
                    />
                    <ProductTitle
                        title={this.props.product.get("title")}
                        planType={this.props.plan.get("planType")}
                        description={this.props.product.get("description")}
                        inModal={this.props.inModal}
                    />
                    {this.props.showPrice && 
                        <ProductPrice
                            description={this.props.plan.get("description")}
                            amount={this.props.plan.get("amount")}
                            planType={this.props.plan.get("planType")}
                            recurringInterval={this.props.plan.get("recurringInterval")}
                            subscriptionDurationInMonths={this.props.plan.get("subscriptionDurationInMonths")}
                            inModal={this.props.inModal}
                        />
                    }
                </Mobile>
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    plan: state.iframe.checkout.checkout.get("checkoutProduct"),
    product: state.iframe.checkout.checkout.get("checkoutProduct").get("product"),
    expert: state.iframe.checkout.checkout.get("checkoutProduct").get("product").get("expert")
});

export default connect(mapStateToProps, null)(withRouter(Product));