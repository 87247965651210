import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setAppointmentsDownloading, setAppointmentsData} from "../store/appointment";

/* Static */
import {devJourneyToDoGetAvailability} from "../static/devdata";

let singleCoach = "";

export const tryGetAllAppointments = (coachId, minutes) => {
    return async dispatch => {
        dispatch(setAppointmentsDownloading(true));
        if(coachId !== null) {
            if(minutes === null) {
                singleCoach = "/"+coachId;
            } else {
                singleCoach = "/"+coachId+"/length/"+minutes;
            }
        } else {
            singleCoach = "";
        }
        const availability = await axiosReq('apiv1/consumer/sessions/all-available-timeslots'+singleCoach, "GET", {}, devJourneyToDoGetAvailability);
        if(availability.hasOwnProperty("error")) {
            //
        }
        const dataObj = {
            "selectedDay": null,
            "selectedTime": null,
            "availableDays": [],
            "availableTimesToday": [],
            "sessions": availability
        };
        dispatch(setAppointmentsData(dataObj));
        dispatch(setAppointmentsDownloading(false));
        return dataObj;
    }
};

export const tryJoinWaitlist = (coachId) => {
    return async dispatch => {
        if (coachId !== null) {
            const join = await axiosReq('apiv1/public/waitlist/join', "POST", {coachId: coachId}, {});
            console.log(join)
            return true
        } else {
            return false
        }
    }
}