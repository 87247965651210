import { combineReducers } from 'redux';

/* Common */
import signupWMCUserReducer from "./store/signupwmcuser";

const signupWMCUsersReducer = combineReducers({
    signupWMCUser: signupWMCUserReducer,
});

export default signupWMCUsersReducer;
