import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import {Link, withRouter} from 'react-router-dom';
import 'moment-timezone/builds/moment-timezone-with-data-2012-2022';
import moment from "moment-timezone";
import {formatTZ} from '../../../utils/timezone';
import styled from 'styled-components';

/* Components */
import {DropdownInput, TextInput, SearchInput, SearchInputUncontrolled, PhoneInput, CalendarDay, ClockTime} from "../../../components/inputs";
import {Width, Border, SaveSection, SubmitInputWithValidation, InlineBlock, CardOrNot, WidthRight, Right} from '../../../../enterprise/components/clientscreate';
import {FontTitle18, FontBody16, FontBody12} from '../../../components/fonts';
import {ButtonTertiary, ButtonSecondary} from '../../../components/buttons';
import {LogoLoading} from "../../../components/loading";
import {DeleteFilled} from "@ant-design/icons";
import {colors} from "../../../components/colors";

/* Middleware */
import {tryCreateNewSharedDrive} from '../middleware/filevault';
import {tryGetAllContacts} from '../../../../enterprise/middleware/contacts';

const Search = styled.div`
    display: inline-block;
    width: calc(100% - 88px);
    vertical-align: top;
    padding: 14px 20px;
`;
const Trash = styled.div`
    display: inline-block;
    font-size: 16px;
    padding: 17px 15px;
    vertical-align: top;
    cursor: pointer;
    color: ${colors.secondary70};
`;
const AddButton = styled.div`
    width: fit-content;
`;
const Green = styled.div`
    color: ${colors.progress100};
`;
const Red = styled.div`
    color: ${colors.alert100};
`;

const whoOptions = (who, isWMC) => {
    return who.map(w => {
        return({
            contactType: (w.get("prospectId") === null && w.get("businessUserId") === null) ? "Contact" : (w.get("prospectStatus") !== null ? w.get("prospectStatus") : "Team Member"),
            value: {
                consumerId: w.get("consumerId"),
                prospectId: w.get("prospectId"),
                contactId: w.get("contactId"),
                businessUserId: w.get("businessUserId")
            },
            label: (w.get("prospectId") === null && w.get("businessUserId") === null) ? w.get("firstName") + " " + w.get("lastName") 
                : 
                ((!isWMC && w.get("referralSource") === "wmc") ? 
                    w.get("firstName") + " " + w.get("lastName").charAt(0) + "."
                    : 
                    w.get("firstName") + " " + w.get("lastName")),
            isArchived: w.get("isArchived")
        })
    }).toJS();
};

class MeetingsCreate extends React.Component {
    state = {
        topic: '', topicValid: true,
        guests: [], guestsShow: true,
        isSaving: false,
        isSaved: false,
        error: '',
        isChanged: false,
        isValid: true,
        isLoading: true,
        searchProspects: [],
        clientOfAdvisor: false
    };

    componentDidMount() {
        return this.setupForm()
    }

    setupForm = async () => {
        if(!this.props.contactsAllFetched) {
            await this.props.tryGetAllContacts();
        }
        await this.setState({"searchProspects": this.props.contacts});
        await this.checkValid();
        this.setState({"isLoading": false});
    };

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    handleChangeTopic = async e => {
        this.setState({'topic': e.target.value});
        await this.setState({'topicValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        this.setState({'isChanged': true});
        this.checkValid();
    };

    checkValid = () => {
        if(this.state.topic !== "" && this.state.topicValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.topicValid) {
            this.setState({'isSaving': true, 'error': ''});
            let users = this.state.guests.filter(a => {return(a.contactType !== "Contact")}).map(a => {return(a.contactType === "Team Member" ? a.value.businessUserId : a.value.consumerId)});
            await this.props.tryCreateNewSharedDrive(this.state.topic, users);
            this.setState({'isSaving': false, 'isSaved': true, isChanged: false});
            this.timeout = setTimeout(() => {this.props.history.push("/vaults")}, 3000);
        }
    };

    handleShowGuests = async () => {
        this.setState({"guestsShow": true})
    };

    handleAddGuest = async (e) => {
        let guests = this.state.guests;
        guests.push(e);
        let searchProspectsLessGuest;
        if(e.contactType === "Team Member") {
            searchProspectsLessGuest = this.state.searchProspects.filter(s => {return(s.get("businessUserId") !== e.value.businessUserId)});
        } else {
            searchProspectsLessGuest = this.state.searchProspects.filter(s => {return(s.get("consumerId") !== e.value.consumerId)});
        }
        await this.setState({'guests': guests, 'isChanged': true, 'searchProspects': searchProspectsLessGuest});
    };

    handleDeleteGuest = async (num) => {
        let guests = this.state.guests;
        let searchProspectsAddGuest;
        if(guests[num].contactType === "Team Member") {
            searchProspectsAddGuest = await this.props.contacts.filter(s => {return(s.get("businessUserId") === this.state.guests[num].value.businessUserId)}).get(0);
        } else {
            searchProspectsAddGuest = await this.props.contacts.filter(s => {return(s.get("consumerId") === this.state.guests[num].value.consumerId)}).get(0);
        }
        guests.splice(num, 1);
        this.setState({"guests": guests, "guestsShow": true, 'searchProspects': this.state.searchProspects.push(searchProspectsAddGuest)});
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <form method={"post"} onSubmit={this.handleSubmit}>
                    {this.state.isSaved
                        ?
                        <>
                            {this.state.error === '' && <FontTitle18>Saved!</FontTitle18>}
                            {this.state.error !== '' && <FontTitle18>{this.state.error}</FontTitle18>}
                        </>
                        :
                        <>
                            <div>
                                <Width>
                                    <FontTitle18>Details</FontTitle18>
                                    <TextInput
                                        title={"Title*"}
                                        valid={this.state.topicValid}
                                        warning={"Required"}
                                        id={"topic"}
                                        onChange={this.handleChangeTopic}
                                        placeholder={"Drive Name"}
                                        value={this.state.topic}
                                    />
                                    {this.state.guestsShow &&
                                    <>
                                        <SearchInputUncontrolled
                                            title={"Members"}
                                            options={whoOptions(this.state.searchProspects.filter(s => {return(s.get("consumerId") !== this.props.userId && s.get("businessUserId") !== this.props.userId && (s.get("businessUserId") !== null || s.get("consumerId") !== null))}), this.props.isWMC)}
                                            id={'guests'}
                                            onChange={this.handleAddGuest}
                                            placeholder={'Search contacts'}
                                            value={""}
                                        />
                                        {this.state.guests.length > 0 &&
                                        this.state.guests.map((g, index) => {
                                            return(
                                                <div key={index}>
                                                    <Search><FontBody16>{(index+1).toString()+". "+g.label}</FontBody16></Search>
                                                    <Trash onClick={() => this.handleDeleteGuest(index)}><DeleteFilled /></Trash>
                                                </div>
                                            )
                                        })
                                        }
                                    </>
                                    }
                                </Width>
                            </div>
                            <SaveSection>
                                <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                <InlineBlock><Link to={"/vaults"}><ButtonTertiary canSubmit={false} label={"Cancel"} /></Link></InlineBlock>
                            </SaveSection>
                        </>
                    }
                </form>
            )
        }
    }
}
const mapStateToProps = state => ({
    contactsAllFetched: state.enterprise.contacts.get("contactsAllFetched"),
    contacts: state.enterprise.contacts.get("contactsAll"),
    prospectsOne: state.enterprise.prospects.get("prospectsOne"),
    clientsOne: state.enterprise.clientsList.get("clientsOne"),
    isWMC: state.common.wmc.get("isWMC"),
    userId: state.common.user.get("userId"),
    sessionGuests: state.enterprise.sessions.get("sessionGuests"),
});
const mapDispatchToProps = dispatch => ({
    tryCreateNewSharedDrive: (name, users) => dispatch(tryCreateNewSharedDrive(name, users)),
    tryGetAllContacts: () => dispatch(tryGetAllContacts()),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MeetingsCreate));
