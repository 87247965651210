import { Map } from 'immutable';
import moment from 'moment/moment';

/* Immutable */

const CHECKOUT_CONSUMER_PAYMENT_SUCCESS_FIRED = 'CHECKOUT_CONSUMER_PAYMENT_SUCCESS_FIRED';
const CHECKOUT_CONSUMER_STORE_RESET = 'CHECKOUT_CONSUMER_STORE_RESET';

/* Actions */

export const setCheckoutConsumerPaymentSuccessFired = () => ({'type': CHECKOUT_CONSUMER_PAYMENT_SUCCESS_FIRED});
export const setCheckoutConsumerStoreReset = () => ({'type': CHECKOUT_CONSUMER_STORE_RESET});

/* Initial State */

const initialState = Map({
    paymentSuccessDatetime: null
});

/* Reducer */

const checkoutReducer = (state=initialState, action) => {
    switch (action.type) {
        case CHECKOUT_CONSUMER_PAYMENT_SUCCESS_FIRED:
            return state.merge({'paymentSuccessDatetime': moment()});
        case CHECKOUT_CONSUMER_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default checkoutReducer;
