import React from 'react';
import moment from 'moment/moment';

/* Components */
import {Left, Right, Month, Day, Title, Expert, Cost, HeroImgBackground, HeroVidBackground, FillContainer} from '../components/header';
import {FontHeader14, FontBody18, FontBody14, FontHeader18} from '../../../common/components/fonts';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

class IframeMeetingPageHeader extends React.Component {
    render() {
        let d = this.props.data;
        return(
            <>
                <Left>
                    <FillContainer>
                        {/*{(d.get("announcementVideoUrl") !== "" && d.get("announcementVideoUrl") !== null)*/}
                        {/*    ? <HeroVidBackground src={d.get("announcementVideoUrl")} />*/}
                        {/*    : <HeroImgBackground imgObj={d.get("coverPhoto")} />*/}
                        {/*}*/}
                        <HeroImgBackground imgObj={d.get("coverPhoto")} />
                    </FillContainer>
                </Left>
                <Right>
                    <Month><FontBody14>{moment(d.get("start")).format("MMM").toUpperCase()}</FontBody14></Month>
                    <Day><FontBody18>{moment(d.get("start")).format("D")}</FontBody18></Day>
                    <Title><FontHeader18>{d.get("title")}</FontHeader18></Title>
                    <Expert href={"https://"+env.URL.DOMAIN+"/webinars/"+this.props.handle} target={"_self"}><FontBody14>by {d.get("expert").get("full_name")}</FontBody14></Expert>
                    <Cost><FontHeader14>{d.get("ticketCost") === 0 ? "Free" : ("$" + d.get("ticketCost"))}</FontHeader14></Cost>
                </Right>
            </>
        )
    }
}

export default IframeMeetingPageHeader;