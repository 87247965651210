import { axiosReq } from "../../../../common/utils/axios";

const devRes = [
    {
        "id": 1,
        "title": "Test title",
        "time": "2024-06-30T14:00:00+00:00",
        "isCompleted": false,
        "maxNumberOfAttendees": 5,
        "coverPhoto": "https://www.theadvisorcoach.com/uploads/4/0/6/5/40653169/27-financial-advisor-marketing-header_orig.png",
        "numberOfAttendees": 2,
        "driveId": null,
        "joined": null
    },
    {
        "id": 2,
        "title": "Test title2",
        "time": "2024-06-30T14:00:00+00:00",
        "isCompleted": false,
        "maxNumberOfAttendees": 5,
        "coverPhoto": "https://www.bankbound.com/wp-content/uploads/2020/09/post_7.jpg",
        "numberOfAttendees": 2,
        "driveId": null,
        "joined": null
    }
]

export const tryGetMyRoundtables = () => {
    return async dispatch => {
        return axiosReq(`apiv1/coach/round-table/participating`, 'GET', {}, devRes);
    }
};

export const tryGetAllRoundtables = () => {
    return async dispatch => {
        return axiosReq(`apiv1/public/round-table`, 'GET', {}, devRes);
    }
};

const devResOne = {
    "id": 2,
    "title": "Test title2",
    "time": "2024-06-30T14:00:00+00:00",
    "isCompleted": false,
    "maxNumberOfAttendees": 5,
    "coverPhoto": "https://www.bankbound.com/wp-content/uploads/2020/09/post_7.jpg",
    "numberOfAttendees": 2,
    "driveId": null,
    "joined": null
}

export const tryGetMyRoundtableData = (id) => {
    return async dispatch => {
        return axiosReq(`apiv1/coach/round-table/participating/`+id, 'GET', {}, devResOne);
    }
};

const devResPublicOne = {
    "id": 1,
    "title": "How to market your advisory practice",
    "description": "<div>Here is a description of the rounddtable</div>",
    "time": "2024-06-30T10:00:00.338Z",
    "coverPhoto": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1587558036/sjfnugai4lphufah0q61.jpg",
    "meetingUrl": "https://google.com",
    "isCompleted": false,
    "canceledOn": null,
    "bio": "<div>Here is why I am an expert</div>",
    "maxNumberOfAttendees": 5,
    "numberOfAttendees": 0,
    "driveId": null,
    "files": []
}

export const tryGetAllRoundtablesOneDetail = (id) => {
    return async dispatch => {
        return axiosReq(`apiv1/public/round-table/`+id, 'GET', {}, devResPublicOne);
    }
};

export const tryPostJoinRoundtable = (id) => {
    return async dispatch => {
        return axiosReq(`apiv1/coach/round-table/join`, 'POST', {"roundTableId": id}, devResOne);
    }
};