import { v4 as uuid4 } from 'uuid';

export const defaultMymoneyState = {
    spendingPlan: {
        activePlan: 'EXAMPLE',
        plans: [
            {
                pid: 'EXAMPLE',
                title: 'My Spending Plan',
                income: [
                    { lid: uuid4(), title: 'Salary', amount: 0 },
                ],
                expenses: [
                    {
                        cid: uuid4(),
                        title: 'Debt Payments',
                        lineItems: [
                            { lid: uuid4(), title: 'Mortgage', amount: 0 },
                            { lid: uuid4(), title: 'Student Loan', amount: 0 },
                            { lid: uuid4(), title: 'Car Loan', amount: 0 },
                            { lid: uuid4(), title: 'MasterCard', amount: 0 },
                        ],
                    },
                    {
                        cid: uuid4(),
                        title: 'Utilities',
                        lineItems: [
                            { lid: uuid4(), title: 'Cable & Internet', amount: 0 },
                            { lid: uuid4(), title: 'Phone', amount: 0 },
                            { lid: uuid4(), title: 'Electricity', amount: 0 },
                            { lid: uuid4(), title: 'Water', amount: 0 },
                        ],
                    },
                    {
                        cid: uuid4(),
                        title: 'Food',
                        lineItems: [
                            { lid: uuid4(), title: 'Groceries', amount: 0 },
                            { lid: uuid4(), title: 'Dining Out', amount: 0 },
                            { lid: uuid4(), title: 'Bars', amount: 0 },
                        ],
                    },
                    {
                        cid: uuid4(),
                        title: 'Shopping',
                        lineItems: [
                            { lid: uuid4(), title: 'Clothes', amount: 0 },
                            { lid: uuid4(), title: 'Beauty', amount: 0 },
                            { lid: uuid4(), title: 'Subscriptions', amount: 0 },
                        ],
                    },
                    {
                        cid: uuid4(),
                        title: 'Transportation',
                        lineItems: [
                            { lid: uuid4(), title: 'Gas', amount: 0 },
                            { lid: uuid4(), title: 'Car Insurance', amount: 0 },
                        ],
                    },
                    {
                        cid: uuid4(),
                        title: 'Other',
                        lineItems: [
                            { lid: uuid4(), title: 'Pet Supplies', amount: 0 },
                            { lid: uuid4(), title: 'Charity', amount: 0 },
                        ],
                    },
                ],
            },
        ],
        unsaved: false,
    },
    spendingPlanDownloading: false,
    spendingPlanUpdating: false,
    snapshot: {
        assets: [
            {
                cid: 'ACCOUNTS',
                title: 'Bank Accounts',
                lineItems: [
                    { lid: uuid4(), title: 'Checking Account', amount: 0 },
                    { lid: uuid4(), title: 'Savings Account', amount: 0 },
                ]
            },
            {
                cid: 'INVESTMENTS',
                title: 'Investments',
                lineItems: [
                    { lid: uuid4(), title: '401k', amount: 0 },
                    { lid: uuid4(), title: 'IRA', amount: 0 },
                    { lid: uuid4(), title: '529 Plan', amount: 0 },
                ]
            },
            {
                cid: 'ASSETS',
                title: 'Assets',
                lineItems: [
                    { lid: uuid4(), title: 'House', amount: 0 },
                    { lid: uuid4(), title: 'Car', amount: 0 },
                ]
            },
            {
                cid: 'CUSHION',
                title: 'Emergency Fund',
                lineItems: [
                    { lid: uuid4(), title: 'Cash Cushion Account', amount: 0 },
                ]
            },
        ],
        liabilities: [
            {
                cid: 'CREDITCARDS',
                title: 'Credit Cards',
                lineItems: [
                    { lid: uuid4(), title: 'MasterCard', amount: 0 },
                    { lid: uuid4(), title: 'Visa', amount: 0 },
                ]
            },
            {
                cid: 'LOANS',
                title: 'Loans',
                lineItems: [
                    { lid: uuid4(), title: 'Mortgage', amount: 0 },
                    { lid: uuid4(), title: 'Student Loan', amount: 0 },
                    { lid: uuid4(), title: 'Car Loan', amount: 0 },
                ]
            },
        ],
        credtScore: {
            score: null,
            lastChecked: null,
        },
        unsaved: false,
    },
    snapshotDownloading: false,
    snapshotUpdating: false,
    saving: {
        goals: [
            { lid: uuid4(), title: '', amount: 0, target: '' },
            { lid: uuid4(), title: '', amount: 0, target: '' },
            { lid: uuid4(), title: '', amount: 0, target: '' },
            { lid: uuid4(), title: '', amount: 0, target: '' },
            { lid: uuid4(), title: '', amount: 0, target: '' },
            { lid: uuid4(), title: '', amount: 0, target: '' },
        ],
        unsaved: false,
    },
    savingDownloading: false,
    savingUpdating: false,
};

export const emptyPlan = {
    pid: uuid4(),
    title: 'New Plan',
    income: [
        { lid: uuid4(), title: 'Salary', amount: 0 },
    ],
    expenses: [{
        cid: uuid4(),
        title: 'Housing',
        lineItems: [
            { lid: uuid4(), title: 'Rent', amount: 0 },
            { lid: uuid4(), title: 'Renters Insurance', amount: 0 },
        ],
    }],
};

export const emptyCategory = {
    cid: uuid4(),
    title: 'New Category',
    lineItems: [
        { lid: uuid4(), title: '', amount: 0 },
    ],
};

export const emptyLineitem = { lid: uuid4(), title: '', amount: 0 };