import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import styled from "styled-components";

/* Containers */
import ShopPageHome from "./containers/pagehome";
import ShopPageProducts from "./containers/pageproducts";
import ShopPagePortfolio from "./containers/pageportfolio";
import ShopPageTestimonials from "./containers/pagetestimonials";
import ShopPageFAQs from "./containers/pagefaqs";
import ShopPageAbout from "./containers/pageabout";
import PagesHandle from "./containers/sidebarhandle";
import PreviewShop from "./containers/previewshop";
import ShopPagePortfolioOne from './containers/oneportfolio';
import ShopPageTestimonialsOne from './containers/onetestimonial';
import ShopPageFAQSOne from './containers/onefaq';
import ShopPageAboutExperienceOne from './containers/oneexperience';
import ShopPageAboutEducationOne from './containers/oneeducation';
import ShopPageAboutLicenseOne from './containers/onelicense';

/* Navs */
import {PageContent, PageFull, PageRight, Block, CardOrNot, Right, Width, WidthRight} from "../navbars/components/navlayout";
import NavPrimaryAll from "../navbars/containers/primaryall";
import NavSecondaryTop from '../navbars/containers/secondarytop';

/* Components */
import {FontHeader16} from "../../common/components/fonts";
import {colors} from "../../common/components/colors";
import CreateEvent from "../settings/scheduler/containers/createevent";

const Bar = styled.div`
    width: 100%;
    margin-bottom: 40px;
    background-color: ${colors.border100};
`;
const Button = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 10px 25px;
    ${props => props.active === true} {color: ${colors.primary70};}
    ${props => props.active === false} {color: ${colors.action100};}
    margin-right: 20px;
    cursor: pointer;
    @media screen and (max-width: 460px) {
        display: block;
    }
`;

class ShopPage extends React.Component {
    state={"preview": false};

    previewStatus = async (status) => {
        this.setState({"preview": status})
    };

    render() {
        return(
            <>
                <NavPrimaryAll />
                <PageFull>
                    <PageRight>

                        {(this.props.location.pathname === "/shop/portfolio/create" || this.props.location.pathname === "/shop/portfolio/edit") &&
                            <>
                                <NavSecondaryTop title={this.props.location.pathname === "/shop/portfolio/create" ? "Add To Portfolio" : "Edit Portfolio"} />
                                <PageContent>
                                    <ShopPagePortfolioOne />
                                </PageContent>
                            </>
                        }

                        {(this.props.location.pathname === "/shop/testimonials/create" || this.props.location.pathname === "/shop/testimonials/edit") &&
                            <>
                                <NavSecondaryTop title={this.props.location.pathname === "/shop/testimonials/create" ? "Create Testimonial" : "Edit Testimonial"} />
                                <PageContent>
                                    <ShopPageTestimonialsOne />
                                </PageContent>
                            </>
                        }

                        {(this.props.location.pathname === "/shop/faqs/create" || this.props.location.pathname === "/shop/faqs/edit") &&
                            <>
                                <NavSecondaryTop title={this.props.location.pathname === "/shop/faqs/create" ? "Create FAQ" : "Edit FAQ"} />
                                <PageContent>
                                    <ShopPageFAQSOne />
                                </PageContent>
                            </>
                        }

                        {(this.props.location.pathname === "/shop/about/experience/create" || this.props.location.pathname === "/shop/about/experience/edit") &&
                            <>
                                <NavSecondaryTop title={this.props.location.pathname === "/shop/about/experience/create" ? "Create Experience" : "Edit Experience"} />
                                <PageContent>
                                    <ShopPageAboutExperienceOne />
                                </PageContent>
                            </>
                        }

                        {(this.props.location.pathname === "/shop/about/education/create" || this.props.location.pathname === "/shop/about/education/edit") &&
                            <>
                                <NavSecondaryTop title={this.props.location.pathname === "/shop/about/education/create" ? "Create Education" : "Edit Education"} />
                                <PageContent>
                                    <ShopPageAboutEducationOne />
                                </PageContent>
                            </>
                        }

                        {(this.props.location.pathname === "/shop/about/license/create" || this.props.location.pathname === "/shop/about/license/edit") &&
                            <>
                                <NavSecondaryTop title={this.props.location.pathname === "/shop/about/license/create" ? "Create License, Certification" : "Edit License, Certification"} />
                                <PageContent>
                                    <ShopPageAboutLicenseOne />
                                </PageContent>
                            </>
                        }

                        {(this.props.location.pathname === "/shop" || this.props.location.pathname === "/shop/products" ||
                            this.props.location.pathname === "/shop/portfolio" || this.props.location.pathname === "/shop/testimonials" ||
                            this.props.location.pathname === "/shop/faqs" || this.props.location.pathname === "/shop/about") &&
                            <>
                                <NavSecondaryTop title={"My Shop"} desc={"Give your prospects and clients a better sense of who you are and the services you provide"}/>
                                <PageContent>
                                    <Block>
                                        <Width fullWidth={false}>

                                            <Bar>
                                                <Button active={this.props.location.pathname === "/shop"} onClick={() => this.props.history.push("/shop")}><FontHeader16>Intro</FontHeader16></Button>
                                                {!this.props.isWMC &&
                                                    <Button active={this.props.location.pathname === "/shop/products"} onClick={() => this.props.history.push("/shop/products")}><FontHeader16>Products</FontHeader16></Button>
                                                }
                                                <Button active={this.props.location.pathname === "/shop/portfolio"} onClick={() => this.props.history.push("/shop/portfolio")}><FontHeader16>Portfolio</FontHeader16></Button>
                                                {!this.props.isWMC &&
                                                    <Button active={this.props.location.pathname === "/shop/testimonials"} onClick={() => this.props.history.push("/shop/testimonials")}><FontHeader16>Testimonials</FontHeader16></Button>
                                                }
                                                <Button active={this.props.location.pathname === "/shop/faqs"} onClick={() => this.props.history.push("/shop/faqs")}><FontHeader16>FAQs</FontHeader16></Button>
                                                <Button active={this.props.location.pathname === "/shop/about"} onClick={() => this.props.history.push("/shop/about")}><FontHeader16>About</FontHeader16></Button>
                                            </Bar>

                                            {this.props.location.pathname === "/shop" && <ShopPageHome/>}
                                            {this.props.location.pathname === "/shop/products" && <ShopPageProducts/>}
                                            {this.props.location.pathname === "/shop/portfolio" && <ShopPagePortfolio/>}
                                            {this.props.location.pathname === "/shop/testimonials" && <ShopPageTestimonials/>}
                                            {this.props.location.pathname === "/shop/faqs" && <ShopPageFAQs/>}
                                            {this.props.location.pathname === "/shop/about" && <ShopPageAbout/>}

                                        </Width>
                                        <WidthRight fullWidth={false}>
                                            <Right fullWidth={false}>
                                                <CardOrNot>
                                                    <PagesHandle
                                                        title={"Shop Page"}
                                                        subtitle={null}
                                                        type={"page"}
                                                        url={"shop"}
                                                        handleKey={"publicHandle"}
                                                        activeKey={"profileActive"}
                                                        errorKey={"profileHandleError"}
                                                        showLinkEmbed={false}
                                                        showOpenLink={true}
                                                        showPreview={true}
                                                        preview={this.previewStatus}
                                                    />
                                                    {/*<PagesPublish*/}
                                                    {/*    title={"Publish"}*/}
                                                    {/*    subtitle={"Control access to your page"}*/}
                                                    {/*    leftLabel={""}*/}
                                                    {/*    rightLabel={"Publish"}*/}
                                                    {/*    activeKey={"profileActive"}*/}
                                                    {/*    errorKey={"profileActiveError"}*/}
                                                    {/*/>*/}
                                                    {/*<AllEventsPage*/}
                                                    {/*    title={"Scheduler Page"}*/}
                                                    {/*    subtitle={null}*/}
                                                    {/*    type={"page"}*/}
                                                    {/*    url={"schedule"}*/}
                                                    {/*    handleKey={"schedulerHandle"}*/}
                                                    {/*    activeKey={"schedulerActive"}*/}
                                                    {/*    errorKey={"schedulerHandleError"}*/}
                                                    {/*    showLinkEmbed={true}*/}
                                                    {/*    linkEmbed={this.linkEmbed}*/}
                                                    {/*    showOpenLink={false}*/}
                                                    {/*    showPreview={true}*/}
                                                    {/*    preview={this.previewStatus}*/}
                                                    {/*/>*/}
                                                </CardOrNot>
                                            </Right>
                                        </WidthRight>
                                    </Block>
                                </PageContent>
                                <PreviewShop
                                    preview={this.state.preview}
                                    updatePreview={this.previewStatus}
                                />
                            </>
                        }

                    </PageRight>
                </PageFull>
            </>
        )
    }
}

const mapStateToProps = state => ({
    isWMC: state.common.wmc.get("isWMC")
});

export default connect(mapStateToProps, null)(withRouter(ShopPage));