import React from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import moment from "moment-timezone";
import { v4 as uuid4 } from 'uuid';
import { fromJS } from 'immutable';

/* Components */
import { colors } from '../../../common/components/colors';
import { FontBody16, FontBody18, FontTitle22, FontSubtitle21, FontHeader18, FontTitle16 } from '../../../common/components/fonts';
import { LogoLoading } from "../../../common/components/loading";
import { Back, BackSection, ChevronLeftRight, InlineBlock, PageTitle, SpaceBetween } from "../components/community";
import { images } from "../../../common/components/images";
import { ButtonPrimary } from "../../../common/components/buttons";
import Checkbox from '../../../common/components/checkbox';
import TextInput from '../../../common/components/textinput';

/* Middleware */
import { tryGetForumCommentsByPostId, tryCreateForumPostComment } from '../middleware/forum';
import { tryGetExpertProfileFromToken} from '../../../iframes/profile/middleware/profile';

// import MemberOne from "../../team/containers/memberone";

const Container = styled.div`
    margin: 0 auto;
    max-width: 950px;
    display: flex;
    gap: 30px;
    flex-direction: column;
`;
const FlexRow = styled.div`
    display: flex;
    justify-content: left;
    gap: 15px;
    align-items: center;
`;
const FlexRowJCSB = styled(FlexRow)`
    justify-content: space-between;
    @media screen and (max-width: 425px) {
        flex-direction: column;
        align-items: flex-start
    }
`;
const ProfileIconInitial = styled(FontHeader18)`
    background-color: ${colors.primary30};
    border-radius: 50%;
    color: ${colors.white};
    cursor: pointer;
    display: inline-block;
    height: 60px;
    line-height: 60px;
    text-align: center;
    vertical-align: middle;
    width: 60px;
`;

const ProfileImage = styled.img`
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 60px;
    width: 60px;
`;

const PostInner = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    gap: 15px;
`;
const Comments = styled(PostInner)`
    border-top: 2px solid ${colors.primary100};
    margin-left: 50px;
    padding: 40px 0;
    gap: 30px;
`;
const CommentsInner = styled.div`
`;
const CommentForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`;
const WriteToUsBtn = styled.div`
    min-width: 138px;
    justify-self: right;
    align-self: flex-end;
    @media screen and (max-width: 425px) {
        align-self: flex-start;
    }
`;

class ForumPostOne extends React.Component {
    static defaultProps = { 'showBack': true, }

    state = {
        'isLoading': true, 'comments': [], 'isAnonymous': false, 'commentDraft': '',
        'selectedAuthor': null
    };

    componentDidMount() {
        this.init();
    }

    init = async () => {
        await this.setState({
            'isLoading': false,
            'comments': await this.props.tryGetForumCommentsByPostId(this.props.post.get('id')),
        })
    }

    backToSubCategory = () => {
        /* If it is new post preview, we dont have subcategory selected
        Return to root screen */
        if (this.props.selectedCategory.get("id"))
            this.props.history.push('/community/category/subcategory')
        else
            this.props.history.push('/community')
    }

    toggleAnonymous = () => {
        this.setState({ 'isAnonymous': !this.state.isAnonymous, });
    }

    editComment = async (e) => {
        await this.setState({ 'commentDraft': e.target.value })
    }

    submitComment = async () => {
        let commentPostPayload = {
            'comment': this.state.commentDraft,
            'isAnonymous': this.state.isAnonymous
        }
        let newComment = await this.props.tryCreateForumComment(this.props.post.get('id'), commentPostPayload);
        this.setState({
            'commentDraft': '',
            'isAnonymous': false,
            'comments': [...this.state.comments, newComment]
        })
    }

    name = () => this.state.isAnonymous ? 'Anonymous' : `${this.props.user.get('first')} ${this.props.user.get('last')}`;

    // toggleSelectAuthor = async (author) => {
    //     await this.setState({ "selectedAuthor": author });
    // }

    selectAuthor = async (author) => {
        this.props.history.push("/my-team/"+author.id)
        // const profile = await this.props.tryGetExpertProfileFromToken(author.id)
        // this.setState({ "profile": fromJS(profile), "selectedAuthor": author});
    }

    getProfileImage = (author) => {
        // Author doesn't exist return Anonymous
        if (!author || !author.fullName) {
            return <ProfileIconInitial>A</ProfileIconInitial>
        }

        let author_profile_image = author.profilePhoto
        if (author_profile_image)
            return <ProfileImage onClick={ () => this.selectAuthor(author) } src={author_profile_image} />

        let authorFirstLetter = "A"
        if (author.fullName)
            authorFirstLetter = author.fullName.substring(0, 1)

        if (authorFirstLetter) {
            return <ProfileIconInitial>{authorFirstLetter}</ProfileIconInitial>
        }
    }

    getAuthorFullName = (author) => {
        if(author && author.fullName)
            return author.fullName
        else
            return 'Anonymous'
    }

    render() {
        if (this.state.isLoading) {
            return (<LogoLoading />)
        }
        else {
            let p = this.props.post;
            let commentAuthorImage = this.getProfileImage({})
            if(!this.state.isAnonymous){
                commentAuthorImage = this.getProfileImage({
                    "id": this.props.user.get('id'),
                    "fullName": this.props.user.get('first'),
                    "profilePhoto": this.props.user.get('photo')
                })
            }

            return (
                <Container>
                    {this.props.showBack && <BackSection>
                        <Back onClick={this.backToSubCategory}>
                            <ChevronLeftRight src={images.chevronLeft} alt={""} />
                        </Back>
                    </BackSection>}
                    <FlexRow>
                        {this.getProfileImage(p.get('author').toJS())}
                        <div>
                            <FontSubtitle21>{this.getAuthorFullName(p.get('author').toJS())}</FontSubtitle21>
                            <FontBody16>{moment(p.get('createdOn')).format('ll').toString()}</FontBody16>
                        </div>
                    </FlexRow>
                    <PostInner>
                        <FontSubtitle21>{p.get('title')}</FontSubtitle21>
                        <FontBody18>{p.get('body')}</FontBody18>
                    </PostInner>
                    <Comments>
                        {this.state.comments && this.state.comments.length > 0 
                        ? this.state.comments.map((c, idx) => {
                            return (
                                <CommentsInner key={idx}>
                                    <FlexRow>
                                        {this.getProfileImage(c.author)}
                                        <div>
                                            <FontSubtitle21>{c.author.fullName || 'Anonymous'}</FontSubtitle21>
                                            <FontBody16>{moment(c.createdOn).format('ll').toString()}</FontBody16>
                                        </div>
                                    </FlexRow>
                                    <PostInner>
                                        <FontBody18>{c.body}</FontBody18>
                                    </PostInner>
                                </CommentsInner>
                            )
                        }) :
                        <SpaceBetween direction={"column"}>
                            <FontTitle16>No answers for this questions. Our coaches will answer as soon as possible.</FontTitle16>
                        </SpaceBetween>
                    }
                        {!this.props.isCoach ? null :
                            <CommentForm>
                                <TextInput
                                    id={uuid4()}
                                    onChange={this.editComment}
                                    value={this.state.commentDraft}
                                    title={<FontTitle22>{'Post a comment'}</FontTitle22>}
                                    placeholder={'Comment'}
                                />
                                <FlexRowJCSB>
                                    <FlexRow>
                                        {commentAuthorImage}
                                        <FontSubtitle21>{this.name()}</FontSubtitle21>
                                    </FlexRow>
                                    <div>
                                        <Checkbox
                                            action={this.toggleAnonymous}
                                            checked={this.state.isAnonymous}
                                            id={uuid4()}
                                            label={(<FontBody16>{'Anonymous'}</FontBody16>)}
                                        />
                                    </div>
                                    <WriteToUsBtn onClick={() => this.submitComment()}>
                                        <ButtonPrimary submit={false} label={<FontBody16>{"Post comment"}</FontBody16>} />
                                    </WriteToUsBtn>
                                </FlexRowJCSB>
                            </CommentForm>
                        }
                        {/*{!this.state.selectedAuthor ? null :*/}
                        {/*    <MemberOne*/}
                        {/*        coachId={this.state.selectedAuthor.coachId}*/}
                        {/*        onClose={() => {this.toggleSelectAuthor(null)}}*/}
                        {/*    />*/}
                        {/*}*/}
                    </Comments>
                </Container>
            )
        }
    }
}

const mapStateToProps = state => ({
    post: state.consumer.forum.forum.get("postSelected"),
    selectedCategory: state.consumer.forum.forum.get("forumOptionSelected"),
    user: state.common.user,
    isCoach: state.common.user.get('isCoach'),
});

const mapDispatchToProps = dispatch => ({
    tryGetForumCommentsByPostId: (postId) => dispatch(tryGetForumCommentsByPostId(postId)),
    tryCreateForumComment: (postId, comment) => dispatch(tryCreateForumPostComment(postId, comment)),
    tryGetExpertProfileFromToken: (id) => dispatch(tryGetExpertProfileFromToken(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForumPostOne));