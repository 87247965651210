import React from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux";

/* Components */
import {FontSubtitle40, FontBody24, FontTitle30} from "../../../../common/components/fonts";
import { colors } from "../../../../common/components/colors";
import { LogoLoading } from "../../../../common/components/loading";
// import {images} from "../../../../common/components/images";
// import FirmCoachesNav from "./listnav";

/* Middleware */
import { tryGetCoachingGeneralSessions } from "../middleware/coaches";

/* Store */
import { setSupportOpen } from "../../../support/store/support";

// const TopSegment = styled.div`
//     background-image: url(${images.firmBackgroundTop});
//     background-position: top;
//     background-size: cover;
//     background-repeat: no-repeat;
//     position: relative;
//     left: -60px;
//     right: 0;
//     top: -90px;
//     bottom: 0;
//     padding: 90px 60px 0 60px;
//     width: 100%;
//     @media screen and (max-width: 748px) {
//        left: -30px;
//        right: -30px;
//        top: -30px;
//        bottom: 0;
//        padding: 30px 30px 0 30px;
//     }
// `;
const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;
const Subtitle = styled(FontBody24)`
    margin: 30px 0 0 0;
    //min-height: 108px;
`;
const Upgrade = styled.div`
    display: inline-block;
    text-decoration: underline;
    font-weight: bold;
    color: ${colors.primary100};
    cursor: pointer;
`;
const IntroWithNav = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 60px;
`;

class FirmCoachesIntro extends React.Component {
    static defaultProps = {
        hasCoaches: true
    }

    state = { isLoading: true, sessionsPrePurchased: 0 }


    componentDidMount() {
        return this.init();
    }

    init = async () => {
        window.scrollTo(0, 0)

        this.setState({
            "sessionsPrePurchased": await this.props.tryGetCoachingGeneralSessions(),
            "isLoading": false
        })
    }

    requestMoreCoaches = () => {
        this.props.setSupportOpen(true, 6);
    }

    // addMoreAppointments = () => {
    //     this.props.history.push("/my-certificates/my-coaches/add-appointments")
    // }

    render() {
        if (this.state.isLoading) {
            return (<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return (
                // <TopSegment>
                <IntroWithNav>
                    <div>
                        <FontTitle30 spaced={false}>
                            {(!this.props.hasCoaches || this.props.location.pathname.includes("browse"))
                                ? "Find a Coach"
                                : "Your Coach Team"
                            }
                        </FontTitle30>
                        {/*{(!this.props.hasCoaches || this.props.location.pathname.includes("browse"))*/}
                        {/*    ? <Subtitle>Learn how to support clients going through life journeys. You have [{this.state.sessionsPrePurchased}] pre-paid coaching appointments to book. View profiles by clicking a coach below.</Subtitle>*/}
                        {/*    : */}
                        <Subtitle>You have [{this.state.sessionsPrePurchased}] pre-paid coaching appointments to book. View profiles by clicking a coach below or <Upgrade onClick={() => { window.location.href = 'https://pay.trustwillow.com/b/14k29y5Aq3jzcucdR9?client_reference_id='+this.props.userId }}><b>buy more.</b></Upgrade></Subtitle>
                        {/*}*/}
                    </div>
                    {/*<div>*/}
                    {/*    {this.props.hasCoaches && <FirmCoachesNav/>}*/}
                    {/*</div>*/}
                </IntroWithNav>
                // </TopSegment>
            )
        }
    }
}

const mapStateToProps = state => ({
    userId: state.common.user.get("userId"),
})

const mapDispatchToProps = dispatch => ({
    tryGetCoachingGeneralSessions: () => dispatch(tryGetCoachingGeneralSessions()),
    setSupportOpen: (isOpen, page) => dispatch(setSupportOpen(isOpen, page))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FirmCoachesIntro));

