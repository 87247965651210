import React from 'react';
import {connect} from 'react-redux';
import {fromJS, List} from 'immutable';
import ReactQuill from "react-quill";

import {Container, Title, Description, SectionSub, LeftSub, RightSub, Icon24} from "../components/faqs";
import {FontTitle30, FontHeader21, FontBody18} from "../../../common/components/fonts";
import {ChevronDown, ChevronUp} from 'react-feather';
import {colors} from "../../../common/components/colors";
import {Quill} from "../../../common/components/richtexteditor";

class IframeFAQs extends React.Component {
    state={"faqs": List()};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "faqs": this.props.profile.get("faqs").map((f, indexF) => {
                return(fromJS({...f.toJS(), "isOpen": false, "id": indexF}))
            })
        })
    };

    select = async (id) => {
        this.setState({
            "faqs": this.state.faqs.map(c => {
                if(c.get("id") === id) {
                    return(fromJS({...c.toJS(), "isOpen": !c.get("isOpen")}))
                } else {
                    return(fromJS({...c.toJS(), "isOpen": false}))
                }
            })
        })
    };

    render() {
        return(
            <Container>
                <Title><FontTitle30>Learn More!</FontTitle30></Title>
                <>
                    {this.state.faqs.map((f, indexF) => (
                        <div key={indexF}>
                            <SectionSub isOpen={f.get("isOpen")} onClick={() => {return this.select(f.get("id"))}}>
                                <LeftSub>
                                    <FontHeader21>{f.get("title")}</FontHeader21>
                                </LeftSub>
                                <RightSub>
                                    {f.get("isOpen")
                                        ? <Icon24><ChevronUp /></Icon24>
                                        : <Icon24><ChevronDown /></Icon24>
                                    }
                                </RightSub>
                            </SectionSub>
                            {f.get("isOpen") &&
                                <Description>
                                    <Quill bodyBorderColor={colors.white}>
                                        <ReactQuill
                                            readOnly={true}
                                            theme={null}
                                            value={f.get("body")}
                                            bounds={'.app'}
                                        />
                                    </Quill>
                                </Description>
                            }
                        </div>
                    ))}
                </>
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    profile: state.iframe.shop.profile.get("profile"),
});

export default connect(mapStateToProps, null)(IframeFAQs);