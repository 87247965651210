import moment from "moment";
import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setScheduleSessionCreating, setScheduleSessionCreatingError} from "../store/scheduler";

/* Static */
import {devIframeSession} from "../static/devdata";

// Used for iframe scheduler only
// Platform set by user logged in to determine if expert->expert or consumer->expert
export const tryPostCreateSessionAppointment = (start, meetingLength, userId, purchaseHistoryId, isWillowSession, description, topic, advisorRequest=null) => {
    return async (dispatch, getState) => {
        dispatch(setScheduleSessionCreating(true));
        const isLily = advisorRequest !== null ? true : getState().common.user.get("isLily");
        const startTime = moment.utc(start).format("YYYY-MM-DD[T]HH:mm:ss[+]00:00");
        const endTime = moment.utc(start).add(meetingLength, "minutes").format("YYYY-MM-DD[T]HH:mm:ss[+]00:00");
        const url = isLily ? ((userId === null) ? 'apiv1/consumer/sessions/create' : 'apiv1/consumer/sessions/create/'+userId) : 'apiv1/coach/coach/'+userId+'/sessions';
        const isAdvertisingPurchase = advisorRequest !== null;
        const lilyFeedbackResponse = advisorRequest !== null ? JSON.stringify(advisorRequest) : null;
        const request = /*userId === null ? {"start": startTime} :*/ {"start": startTime, "expected_end": endTime, "topic": topic, "description": description, "purchaseHistoryId": purchaseHistoryId, "isWillowSession": isWillowSession, "isAdvertisingPurchase": isAdvertisingPurchase, "lilyFeedbackResponse": lilyFeedbackResponse};
        const session = await axiosReq(url, "POST", request, devIframeSession);
        if(session.hasOwnProperty("error")) {
            await dispatch(setScheduleSessionCreatingError(session.error));
        } else {
            await dispatch(setScheduleSessionCreatingError(null));
        }
        dispatch(setScheduleSessionCreating(false));
        return session;
    }
};
