const affirmationsList = [
    "I am always headed in the right direction.",
    "If people never did silly things, nothing intelligent would ever get done. - Ludwig Wittgenstein",
    "Instead of worrying about what you cannot control, shift your energy to what you can create. - Roy T. Bennett",
    "Courage starts with showing up and letting ourselves be seen. - Brene Brown",
    "The perfect moment is this one. - Jon Kabat-Zinn",
    "Have a wonderful day.",
    "Nothing can dim the light that shines from within. - Maya Angelou",
    "Root for yourself and those around you.",
    "Believe you can and you're halfway there. - Theodore Roosevelt",
    "Life is like riding a bicycle. To keep your balance, you must keep moving. - Albert Einstein",
    "Nothing is impossible. The word itself says 'I'm possible!' - Audrey Hepburn",
    "You must do the things you think you cannot do. - Eleanor Roosevelt",
    "My mind is full of brilliant ideas.",
    "My drive and ambition allow me to achieve my goals.",
    "I make a difference in the world by simply existing in it.",
    "I am learning valuable lessons from myself every day."
];

export default affirmationsList;
