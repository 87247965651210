import React from 'react';

import {HoverLink, CenterHighlighted, CenterContent, LatestSection, HighlightedTextContainer, UnderlinedLink} from "../components/care";
import ContentHighlighted from './contenthighlighted';
import ContentLargePhoto from './contentlargephoto';
import {FontBody16, FontTitle21, FontTitle34, FontBody18} from '../../../common/components/fonts';
import {colors} from "../../../common/components/colors";
import {RightOutlined} from '@ant-design/icons'

class ContentOverviewStyles extends React.Component {

    componentDidMount() {
        
    }

    render() {
        if (this.props.style % 3 === 0 || this.props.style % 3 === 1) {
            return (
                <div>
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        {this.props.data.slice(0, 2).map(a => {
                            return(
                                <CenterContent key={a.get("id")}>
                                    <ContentLargePhoto data={a.toJS()}/>
                                </CenterContent>
                            )
                        })}
                    </div>
                    <br />
                    <HighlightedTextContainer>
                        <UnderlinedLink style={{cursor: 'pointer', textAlign: 'center'}}><FontTitle34 onClick={() => {return this.props.viewCourse(this.props.data.get(2))}}>{this.props.data.get(2).get("title")}</FontTitle34></UnderlinedLink>
                    </HighlightedTextContainer>
                    <br />
                    <br />
                    <br />
                    <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
                        {this.props.style % 3 === 0 ?
                            <>
                                <LatestSection style={{display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-evenly'}}>
                                    {
                                        this.props.data.slice(3, 5).map((m, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    style={{border: '10px solid' + colors.backgroundColor5, 
                                                        padding: '20px', 
                                                        margin: '10px',
                                                        textAlign: 'center'}}>
                                                    <HoverLink style={{cursor: 'pointer'}}><FontBody18 onClick={() => {return this.props.viewCourse(m)}}>{m.get("title")}</FontBody18></HoverLink>
                                                </div>
                                                
                                            )
                                        })
                                    }
                                </LatestSection>
                                <div style={{margin: '5px'}}> </div>
                                <CenterHighlighted>
                                    <ContentHighlighted data={this.props.data.get(5).toJS()} noMargin={true}/>
                                </CenterHighlighted>
                            </>
                            :
                            <>
                                <CenterHighlighted>
                                    <ContentHighlighted data={this.props.data.get(3).toJS()} noMargin={true}/>
                                </CenterHighlighted>
                                <div style={{margin: '5px'}}> </div>
                                <LatestSection style={{display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'space-evenly'}}>
                                    {
                                        this.props.data.slice(4, 6).map((m, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    style={{border: '10px solid' + colors.backgroundColor3, 
                                                        padding: '20px', 
                                                        margin: '10px',
                                                        textAlign: 'center'}}>
                                                    <HoverLink style={{cursor: 'pointer'}}><FontBody18 onClick={() => {return this.props.viewCourse(m)}}>{m.get("title")}</FontBody18></HoverLink>
                                                </div>
                                                
                                            )
                                        })
                                    }
                                </LatestSection>
                            </>
                        }
                    </div>
                    <br />
                    <br />
                    <div style={{width: 'fit-content', margin: '0 auto'}}>
                        <FontTitle21 onClick={this.props.seeMore} style={{cursor: 'pointer'}}>More {this.props.title} <RightOutlined /></FontTitle21>
                        {
                            this.props.data.slice(6, 9).map((m, i) => {
                                return (
                                    <HoverLink key={i} style={{cursor: 'pointer'}}><br /><FontBody18 onClick={() => {return this.props.viewCourse(m)}}>{m.get("title")}</FontBody18></HoverLink>
                                    
                                )
                            })
                        }
                    </div>
                    <br />
                    <br />
                </div>
            )
        } else {
            return (
                <div>
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        {this.props.data.slice(0, 2).map(a => {
                            return(
                                <CenterContent key={a.get("id")}>
                                    <ContentLargePhoto data={a.toJS()}/>
                                </CenterContent>
                            )
                        })}
                    </div>
                    <br />
                    <HighlightedTextContainer>
                        <UnderlinedLink style={{cursor: 'pointer', textAlign: 'center'}}><FontTitle34 onClick={() => {return this.props.viewCourse(this.props.data.get(2))}}>{this.props.data.get(2).get("title")}</FontTitle34></UnderlinedLink>
                    </HighlightedTextContainer>
                    <br />
                    <br />
                    <br />
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <CenterHighlighted>
                            <ContentHighlighted data={this.props.data.get(3).toJS()} noMargin={true}/>
                        </CenterHighlighted>
                        <div style={{margin: '5px'}}> </div>
                        <LatestSection>
                            <FontTitle21 onClick={this.props.seeMore} style={{cursor: 'pointer'}}>More {this.props.title} <RightOutlined /></FontTitle21>
                            {this.props.data.slice(4, 9).map((a, i) => {
                                return (
                                    <HoverLink key={i} style={{cursor: 'pointer'}}><br /><FontBody16 onClick={() => {return this.props.viewCourse(a)}}>{a.get("title")}</FontBody16></HoverLink>
                                )
                            })}
                        </LatestSection>
                    </div>
                    <br />
                    <br />
                </div>
            )
        }
    }
}

export default ContentOverviewStyles;