export const willowOnDemand = () => ({
    "id": "willow_ondemand",
    "productId": "willow_ondemand",
    "orderIndex": 0,
    "description": "",
    "numberOfSessions": 0,
    "amount": 20000, // 20000,
    "isPrivate": false,
    "planType": "recurring", // "one_time",
    "recurringInterval": "year", // null",
    "subscriptionDurationInMonths": null,
    "status": "Active",
    "product": {
        "id": "willow_ondemand",
        "title": "Willow On-Demand",
        "description": "",
        "coverPhoto": "",
        "serviceType": "online",
        "termsOfUseUrl": "https://trustwillow.com/terms-of-use",
        "privacyPolicyUrl": "",
        "downloadUrl": "",
        "sessionLength": 45,
        "status": "Active",
        "expert": {
            "id": null,
            "fullName": "Willow",
            "firstName": "Willow",
            "lastName": "",
            "storeHandle": "",
            "publicHandle": "",
            "profilePhotoUrl": ""
        }
    }
});
