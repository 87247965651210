import React from 'react';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';

/* Components */
import {Page, PoweredBy, Gray, PoweredByLogo, Menu} from '../components/structure';
import {FontBody16} from '../../../common/components/fonts';
import {images} from '../../../common/components/images';
import {LogoLoading} from "../../../common/components/loading";

/* Containers */
import IframeStoreHeader from "./header";
import IframeStoreVideoCall from "./videocall";
import IframeStoreEmailer from "./emailer";
import IframeAbout from "./about";

/* Middleware */
import { tryGetExpertProfileFromToken} from '../middleware/profile';

class IframeProfileStructure extends React.Component {
    static defaultProps = {
        needsProfileInfo: false,
        coachId: '',
        hideCompany: false,
        alwaysShowContactBtns: false,
        purchaseHistoryId: null,
        defaultCallLength: 45,
        profile: {
            id: '',
            firstName: "",
            lastName: "",
            companyName: "",
            crd: "",
            photo: null,
            introVideoUrl: "",
            facebookUrl: "",
            linkedinUrl: "",
            twitterUrl: "",
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            bio: "",
            endorsement1: "",
            endorsement2: "",
            endorsement3: "",
            testimonials: [],
            freeTime: '',
            whyBecameCoach: '',
            coachPhilosophy: '',
            highlights: "",
            coachingCertification: null,
            coachingCertificationYear: null,
            bookingAvailability: 'available',
            screeningCallLength: 30,
            defaultCallLength: 15,
            advancedDegree: null,
            advancedDegreeCity: null,
            advancedDegreeInstitution: null,
            advancedDegreeState: null,
            advancedDegreeYear: null,
            otherEducation: null,
            expertCategories: [],
            portfolio: [],
            degrees: [],
            jobs: [],
            licenses: [],
            faqs: [],
            interests: [],
            skills: [],
            profileActive: true,
            profileShowEmailer: true,
            profileShowScheduler: true,
            schedulerActive: true,
            schedulerHandle: "",
            storeActive: true,
            storeHandle: "",
            storePhoto: null,
            storeShowEmailer: true,
            storeShowScheduler: true,
            storeTitle: "",
            storeDescription: "",
            website: "",
            instagramUrl: "",
            blogUrl: "",
            heroImageUrl: null,
            publicHandle: "",
            signUpDate: "",
            isWMCEmployee: false
        }
    };

    state={"isLoading": true, "showScheduler": false, "showEmailer": false};

    componentDidMount() {
        this.init()
    }

    init = async () => {
        let profile;
        if (this.props.needsProfileInfo) {
            profile = await this.props.tryGetExpertProfileFromToken(this.props.coachId);
        }
        this.setState({
            "profile": profile ? fromJS(profile) : this.props.profile,
            "isLoading": false
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.profile !== this.props.profile) {
            this.setState({
                "profile": this.props.profile,
                "isLoading": false
            })
        }
    }

    changeShowScheduler = async (status) => {
        this.setState({"showScheduler": status});
    };

    changeShowEmailer = async (status) => {
        this.setState({"showEmailer": status});
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <Page>
                    <IframeStoreHeader
                        changeShowScheduler={this.changeShowScheduler}
                        changeShowEmailer={this.changeShowEmailer}
                        profile={this.state.profile}
                        hideCompany={this.props.hideCompany}
                        alwaysShowContactBtns={this.props.alwaysShowContactBtns}
                    />
                    <Menu />
                    <IframeAbout
                        profile={this.state.profile}
                    />
                    <PoweredBy target={"_blank"} href={"https://www.trustwillow.com/"}>
                        <FontBody16><Gray>Powered by</Gray>&nbsp;<PoweredByLogo src={images.logo} alt={""} /></FontBody16>
                    </PoweredBy>
                    {this.state.showScheduler &&
                        <IframeStoreVideoCall
                            defaultCallLength={this.props.defaultCallLength}
                            changeShowScheduler={this.changeShowScheduler}
                            profile={this.state.profile}
                            purchaseHistoryId={this.props.purchaseHistoryId}
                        />
                    }
                    {this.state.showEmailer &&
                        <IframeStoreEmailer changeShowEmailer={this.changeShowEmailer} profile={this.state.profile} />
                    }
                </Page>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryGetExpertProfileFromToken: (id) => dispatch(tryGetExpertProfileFromToken(id)),
})

export default connect(null, mapDispatchToProps)(IframeProfileStructure);