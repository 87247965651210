import React from 'react';
import styled from 'styled-components';
import validator from "validator";
import {connect} from 'react-redux';

/* Components */
import {PasswordInput, TextInput} from "../../../common/components/inputs";
import {InlineBlock, SaveSection, SubmitInputWithValidation} from "../../../enterprise/components/clientscreate";
import {FontHeader18, FontBody14, FontBody16, FontHeader16, FontSubtitle28} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";
import {ButtonTertiary} from "../../../common/components/buttons";

import {trySendPasswordResetEmail, tryTwoFactorVerify} from '../middleware/passwordreset';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

export const Left = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 150px;
    @media screen and (max-width: 460px) {
        width: 100%;
        display: block;
    }
`;
export const Right = styled.div`
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 150px);
    text-align: right;
    margin-top: 8px;
    color: ${colors.primary70};
    @media screen and (max-width: 460px) {
        width: 100%;
        display: block;
        text-align: left;
    }
`;
export const LoginBtn = styled.div`
    color: ${colors.action100};
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
`;

const Red = styled.div`
    color: ${colors.alert100};
`;

const Green = styled.div`
    color: ${colors.progress100};
`;

const LinkReset = styled.a`
    color: ${colors.primary100};
`;

const Container = styled.div`
    max-width: 768px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 0px auto 0px auto;
`;

export const HalfAlign = styled.div`
    display: inline-block;
    vertical-align: top;
    ${props => props.side !== "left"} {
        width: 49%;
        text-align: left;
        margin-right: 2%;
    }
    ${props => props.side !== "right"} {
        width: 49%;
        text-align: right;
    }
    margin-bottom: -15px;
`;

const FitContent = styled.div`
    width: fit-content;
`;

const OnlySubmit = styled.div`
    margin: 3px auto 0px auto;
    text-align: center;
    & button > div {
        min-width: 160px;
    }
`;

const Cancel = styled(FontBody14)`
    margin: 20px auto 0px auto;
    text-align: center;
    color: ${colors.white};
    cursor: pointer;
    width: fit-content;
`;

const Message = styled(FontSubtitle28)`
    margin: 85px auto 50px auto;
    width: fit-content;
    letter-spacing: normal;
`;

const Intro = styled(FontBody14)`
    margin: 0px auto 20px auto;
    text-align: center;
    color: ${colors.white};
`;

const formStyleSystem = {
    showBackBtn: false,
    pageTitle: "Check your email!",
    pageIntroText: "We’ve sent you a verification code",
    showTitle: true,
    showLoginLink: true,
    submitLabel: "Confirm",
    inputsCodeLabel: "Verification Code",
    inputsCodePlaceholder: "XXXXXX",
    inputsCodeWarning: "Invalid or incorrect code.",
    inputsContainersStyles: {},
    inputsStyles: {},
    containerStyles: {},
    onlyShowSubmit: false
};

const pageStyleSystem = (employerLogin) => ({
    showBackBtn: false,
    pageTitle: employerLogin ? "Verify your account" : "Check your email!",
    pageIntroText: employerLogin ? "We've emailed you a verification code" : "We’ve sent you a verification code",
    showTitle: false,
    showLoginLink: false,
    submitLabel: "Confirm",
    inputsCodeLabel: "",
    inputsCodePlaceholder: "Verification code",
    inputsCodeWarning: "",
    containerStyles: {backgroundColor: colors.backgroundColor5, borderRadius: "20px", padding: "30px 45px", width: "calc(100% - 90px)"},
    inputsContainersStyles: {margin: "0px 0px 20px 0px"},
    inputsStyles: {borderRadius: "25px", lineHeight: "36px", backgroundColor: colors.white},
    onlyShowSubmit: true
});

class ResetCode extends React.Component {
    static defaultProps = {
        email: "",
        next: () => {},
        getToken: () => {},
        login: () => {},
        back: () => {},
        employerLogin: false,
        styleSystem: "form" // form or page
    };

    state={
        ...formStyleSystem,
        "code": "",
        "codeValid": true,
        "isChanged": false,
        "isSaving": false,
        "isLoading": true,
        "codeSent": false
    };

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        clearInterval(this.timeout);
    }

    init = async () => {
        this.setState({
            ...(this.props.styleSystem === "page" ? pageStyleSystem(this.props.employerLogin) : formStyleSystem),
            'isLoading': false
        })
    };

    handleChangeCode = async e => {
        const code = e.target.value;
        await this.setState({
            'code': code,
            // 'codeValid': validator.isAlphanumeric(code) && !validator.isEmpty(code),
            'isChanged': true,
        });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = await this.props.tryTwoFactorVerify(this.state.code, this.props.email);
        if (isValid) {
            await this.props.getToken(this.state.code);
            this.props.next()
        } else {
            this.setState({"codeValid": false});
            this.timeout = setTimeout(() => {this.setState({"codeValid": true})}, 2000);
        }
    };

    sendAnotherCode = async () => {
        this.props.trySendPasswordResetEmail(this.props.email);
        this.setState({"codeSent": true});
        this.timeout = setTimeout(() => {this.setState({"codeSent": false})}, 2000);
    };

    render() {
        return(
            <>
                {this.props.styleSystem === "page" && <Message>{this.state.pageTitle}</Message>}
                <Container style={this.state.containerStyles}>
                    <form method={"post"} onSubmit={this.handleSubmit}>
                        {(this.state.showTitle || this.state.showLoginLink) &&
                            <div>
                                <Left>{this.state.showTitle && <FontHeader18>Set Your Password</FontHeader18>}</Left>
                                <Right>{this.state.showLoginLink && <FontBody16>Know your password?&nbsp;<LoginBtn onClick={this.props.login}><FontHeader16>Login</FontHeader16></LoginBtn></FontBody16>}</Right>
                            </div>
                        }
                        {this.props.styleSystem === "page" && <Intro>{this.state.pageIntroText}</Intro>}
                        <TextInput
                            title={this.state.inputsCodeLabel}
                            valid={this.state.codeValid}
                            warning={this.state.inputsCodeWarning}
                            id={"code"}
                            onChange={this.handleChangeCode}
                            placeholder={this.state.inputsCodePlaceholder}
                            value={this.state.code}
                            containerStyles={this.state.inputsContainersStyles}
                            inputStyles={this.state.inputsStyles}
                        />
                        <Red>{!this.state.codeValid ? "Invalid or incorrect code" : ""}</Red>
                        <br />
                        {this.state.onlyShowSubmit
                            ?
                                <OnlySubmit>
                                    <SubmitInputWithValidation
                                        label={this.state.submitLabel}
                                        isChanged={this.state.isChanged}
                                        isValid={true/*this.state.codeValid*/}
                                        isSaving={this.state.isSaving}
                                    />
                                    {this.state.codeSent
                                        ? <><br /><Red>Code sent!</Red></>
                                        : <Cancel onClick={this.sendAnotherCode}>Send another code</Cancel>
                                    }
                                </OnlySubmit>
                            :
                                <SaveSection>
                                    <HalfAlign side={"left"}>
                                        <InlineBlock><FitContent onClick={this.props.back}><ButtonTertiary canSubmit={false} label={"Back"} /></FitContent></InlineBlock>
                                    </HalfAlign>
                                    <HalfAlign side={"right"}>
                                        <InlineBlock>
                                            <SubmitInputWithValidation
                                                label={"Confirm"}
                                                isChanged={this.state.isChanged}
                                                isValid={true/*this.state.codeValid*/}
                                                isSaving={this.state.isSaving}
                                            />
                                        </InlineBlock>
                                    </HalfAlign>
                                </SaveSection>
                        }
                    </form>
                </Container>
            </>
        )
    }
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => ({
    tryTwoFactorVerify: (code, email) => dispatch(tryTwoFactorVerify(code, email)),
    trySendPasswordResetEmail: (email) => dispatch(trySendPasswordResetEmail(email))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetCode);
