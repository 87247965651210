import {images} from '../../../common/components/images';

/* Middleware - tryAuth */
export const devIframeAuthData = {
    "email": "jason.aronson@gmail.com",
    "first": "Jason",
    "last": "Aronson",
    "subscription": true,
    "platform": "coach", // coach, advisor, advisor_and_coach, consumer, enterprise
    "userId": 24324,
    "uid": "74d1a449-ba40-4ad0-9989-f148807f22ed",
    "onboardingCompleted": true,
    "phone": 1111111111,
    "newUser": false,
    "companyName": "Company Name, LLC",
    "photo": images.devDataTeamJasonAvatar,
    // "wmc": null,
    "wmc": {
        "id": 1,
        "address1": "",
        "address2": "",
        "city": "Boston",
        "companyLogoUrl": images.logo,
        "companyName": "Willow Network, Inc.",
        "contactPersonCellPhone": "",
        "contactPersonEmail": "",
        "contactPersonFirstName": "",
        "contactPersonLastName": "",
        "contactPersonWorkPhone": "",
        "email": "",
        "facebookUrl": "",
        "linkedinUrl": "",
        "phone": "",
        "state": "MA",
        "twitterUrl": "",
        "zipCode": ""
    },
    "referralType": "wmc", // "wmc" or "willow"
    "expertCategoryName": "Financial Coach",
    "expertCategoryId": 1
};

export const devIframeVerificationStatus = {
    "status": "verified" // "no_verification_code_sent", "not_verified", "verified"
};

export const devIframeSession = {
    id: 1,
    remoteUserName: "Gabby Coach",
    remoteUserId: 1,
    thisUserName: "Jason Consumer",
    thisUserPlatform: "consumer",
    start: "2020-06-14T13:00:00+00:00",
    end: "2020-06-14T13:30:00+00:00",
    phone: "617-380-4313",
    room: "277861",
    videoStatus: "conference_ongoing", // conference_canceled, conference_ended, conference_has_not_yet_started, conference_ongoing
    videoUrl: "924fcf54447903ab9ea558886af67543", // if status !== conference_ongoing then videoUrl = null
    videoAccessToken: "abc", // if status !== conference_ongoing then videoAccessToken = null
    title: 'Jason Consumer',
};

export const devIframeHandleData = (isFound, handle) => {
    if(isFound) {
        return({
            "id": 12,
            "publicHandle": handle,
            "expertCategoryName": "coach", // coach, advisor, etc
            "expertCategoryId": 1
        })
    } else {
        return({
            "error": "Public handle can not be found",
            "errorDetails": "Expert does not exist"
        })
    }
};

/* Middleware - tryGetSettingsEnterpriseProfile */
// coachingcert, advancedDegree, otherEducation
export const devIframeProfileData = {
    id: '32',

    firstName: "Jason",
    lastName: "Aronson",
    companyName: 'Coaching, LLC',
    crd: 'test 5',

    photo: images.devDataTeamJasonAvatar,
    introVideoUrl: 'https://www.youtube.com/embed/RZAF1DAqHPk',
    facebookUrl: 'https://facebook.com',
    linkedinUrl: 'https://linkedin.com',
    twitterUrl: 'https://twitter.com',
    address1: 'test 3',
    address2: 'test 6',
    city: 'Boston',
    state: 'MA',
    zipCode: '23443',
    bio: '<p>In an age of pandemic, what true value do any of our backgrounds hold? It’s not like we can look at precedents and say, people who have done ABC tend to be really successful at accomplishing XYZ in a pandemic. My expf</p>',

    // BEG COACH ONLY FIELDS
    endorsement1: "<p>I worked with Megan to get my finances on the right track. Now, even though I am furloughed from work due to Covid-19, I feel calm and I am not stressed about money. If this had happened two years ago, I'd be panicked. - Riane</p>",
    endorsement2: "<p>Megan helped get my husband and me on the same page about dealing with our finances and planning for our future. The real world examples and scenarios for each recommendation helped us bring it back to our own financial plans and see how important it was to making our plans real. - Melissa</p>",
    endorsement3: "<p>Megan was tremendously helpful to me as I took steps towards financial wellness. She listened well, offered helpful guidance and road maps, and responded quickly to questions between sessions. I highly recommend her to anyone who wants more financial wellness.- Glen</p>",
    freeTime: 'Test 7',
    whyBecameCoach: 'Test 2',
    coachPhilosophy: 'Test 3',
    highlights: 'Test 1',

    coachingCertification: null,
    coachingCertificationYear: null,

    // END COACH ONLY FIELDS

    bookingAvailability: 'available',

    screeningCallLength: 30,
    defaultCallLength: 30,

    advancedDegree: null,
    advancedDegreeCity: null,
    advancedDegreeInstitution: null,
    advancedDegreeState: null,
    advancedDegreeYear: null,

    otherEducation: null,

    expertCategories: [],

    profileActive: true,
    profileShowEmailer: true,
    profileShowScheduler: true,
    schedulerActive: true,
    schedulerHandle: "sampleadvisor",
    storeActive: true,
    storeHandle: "sampleadvisor",
    storePhoto: null,
    storeShowEmailer: true,
    storeShowScheduler: true,
    storeTitle: "My Store!",
    storeDescription: "<p>Here is a description of my services</p>",
    website: ""
};

export const devIframeLogin = (success) => {
    if(success) {
        return({"success": true})
    } else {
        return({"error": "Something happened."})
    }
};

export const devIframeListings = {
    "coachingProducts": [
        {
            "id": 8,
            "title": "TestPlan 2",
            "description": "Lorem ipsum...",
            "coverPhoto": images.devDataStoreProduct,
            "termsOfUseUrl": "Lorem ipsum www.google.com",
            "privacyPolicyUrl": "www.google.com",
            "downloadUrl": "www.download.com",
            "sessionLength": 15,
            "serviceType": "online",
            "status": "Active",
            "plans": [
                {
                    "id": 4,
                    "orderIndex": 0,
                    "description": "Buy this plan for 2 meetings, 1 free",
                    "amount": 4000,
                    "planType": "one_time",
                    "subscriptionDurationInMonths" : null,
                    "recurringInterval": null
                },
                {
                    "id": 5,
                    "orderIndex": 1,
                    "description": "Buy this plan for year of meetings, 4 meetings per month, paid annualy, cancel anytime",
                    "amount": 50000,
                    "planType": "recurring",
                    "subscriptionDurationInMonths" : null,
                    "recurringInterval": "year"
                },
                {
                    "id": 6,
                    "orderIndex": 2,
                    "description": "Buy this plan for 3 monthly meetings, charged monthly for 4 months",
                    "amount": 2500,
                    "planType": "recurring",
                    "subscriptionDurationInMonths" : 4,
                    "recurringInterval": "month"
                }
            ]
        },
        {
            "id": 9,
            "title": "TestPlan 3",
            "description": "Lorem ipsum...",
            "coverPhoto": images.devDataStoreProduct,
            "termsOfUseUrl": "Lorem ipsum www.google.com",
            "privacyPolicyUrl": "www.google.com",
            "downloadUrl": "www.download.com",
            "sessionLength": 60,
            "serviceType": "offline",
            "status": "Disabled",
            "plans": [
                {
                    "id": 7,
                    "orderIndex": 0,
                    "description": "Buy this plan for 2 meetings, 1 free",
                    "amount": 4000,
                    "planType": "one_time",
                    "subscriptionDurationInMonths" : null,
                    "recurringInterval": null
                },
                {
                    "id": 8,
                    "orderIndex": 1,
                    "description": "Buy this plan for year of meetings, 4 meetings per month, paid annualy, cancel anytime",
                    "amount": 50000,
                    "planType": "recurring",
                    "subscriptionDurationInMonths" : null,
                    "recurringInterval": "year"
                },
                {
                    "id": 9,
                    "orderIndex": 2,
                    "description": "Buy this plan for 3 monthly meetings, charged monthly for 4 months",
                    "amount": 2500,
                    "planType": "recurring",
                    "subscriptionDurationInMonths" : 4,
                    "recurringInterval": "month"
                }
            ]
        },
        // {
        //     "id": 3,
        //     "title": "Clarity Meeting",
        //     "description": "<p>A 2-hour session via video conference that provides an overview of your current financial picture. You’ll finally understand which aspects of your personal finances are on track and what needs more attention. We’ll take a deep dive into your spending and show you where your money is going.</p><br/><p>You’ll receive customized recommendations related to savings, cash flow, debt, and protection that are designed to build wealth, improve your money confidence, and financially prepare you for wherever life takes you.</p><br/><p>You’ll leave the meeting with a Wellness Plan that will strengthen your financial foundation.</p>",
        //     "coverPhoto": null, // images.devDataStorePortfolio,
        //     "termsOfUseUrl": "",
        //     "privacyPolicyUrl": "",
        //     "downloadUrl": "",
        //     "sessionLength": 120,
        //     "serviceType": "online",
        //     "status": "Active",
        //     "plans": [
        //         {
        //             "id": 90,
        //             "orderIndex": 0,
        //             "description": "",
        //             "amount": 38900,
        //             "planType": "one_time",
        //             "subscriptionDurationInMonths" : null,
        //             "recurringInterval": null
        //         }
        //     ]
        // },
        // {
        //     "id": 4,
        //     "title": "Clarity Meeting",
        //     "description": "<p>A 2-hour session via video conference that provides an overview of your current financial picture. You’ll finally understand which aspects of your personal finances are on track and what needs more attention. We’ll take a deep dive into your spending and show you where your money is going.</p><br/><p>You’ll receive customized recommendations related to savings, cash flow, debt, and protection that are designed to build wealth, improve your money confidence, and financially prepare you for wherever life takes you.</p><br/><p>You’ll leave the meeting with a Wellness Plan that will strengthen your financial foundation.</p>",
        //     "coverPhoto": null, //images.devDataStorePortfolio,
        //     "termsOfUseUrl": "",
        //     "privacyPolicyUrl": "",
        //     "downloadUrl": "",
        //     "sessionLength": 120,
        //     "serviceType": "online",
        //     "status": "Active",
        //     "plans": [
        //         {
        //             "id": 70,
        //             "orderIndex": 0,
        //             "description": "",
        //             "amount": 38900,
        //             "planType": "one_time",
        //             "subscriptionDurationInMonths" : null,
        //             "recurringInterval": null
        //         }
        //     ]
        // },
        // {
        //     "id": 5,
        //     "title": "Clarity Meeting",
        //     "description": "<p>A 2-hour session via video conference that provides an overview of your current financial picture. You’ll finally understand which aspects of your personal finances are on track and what needs more attention. We’ll take a deep dive into your spending and show you where your money is going.</p><br/><p>You’ll receive customized recommendations related to savings, cash flow, debt, and protection that are designed to build wealth, improve your money confidence, and financially prepare you for wherever life takes you.</p><br/><p>You’ll leave the meeting with a Wellness Plan that will strengthen your financial foundation.</p>",
        //     "coverPhoto": images.devDataStorePortfolio,
        //     "termsOfUseUrl": "",
        //     "privacyPolicyUrl": "",
        //     "downloadUrl": "",
        //     "sessionLength": 120,
        //     "serviceType": "online",
        //     "status": "Active",
        //     "plans": [
        //         {
        //             "id": 70,
        //             "orderIndex": 0,
        //             "description": "",
        //             "amount": 38900,
        //             "planType": "one_time",
        //             "subscriptionDurationInMonths" : null,
        //             "recurringInterval": null
        //         }
        //     ]
        // },
        {
            "id": 6,
            "title": "Clarity Meeting",
            "description": "<p>A 2-hour session via video conference that provides an overview of your current financial picture. You’ll finally understand which aspects of your personal finances are on track and what needs more attention. We’ll take a deep dive into your spending and show you where your money is going.</p><br/><p>You’ll receive customized recommendations related to savings, cash flow, debt, and protection that are designed to build wealth, improve your money confidence, and financially prepare you for wherever life takes you.</p><br/><p>You’ll leave the meeting with a Wellness Plan that will strengthen your financial foundation.</p>",
            "coverPhoto": images.devDataStorePortfolio,
            "termsOfUseUrl": "",
            "privacyPolicyUrl": "",
            "downloadUrl": "",
            "sessionLength": 120,
            "serviceType": "online",
            "status": "Active",
            "plans": [
                {
                    "id": 70,
                    "orderIndex": 0,
                    "description": "",
                    "amount": 38900,
                    "planType": "one_time",
                    "subscriptionDurationInMonths" : null,
                    "recurringInterval": null
                }
            ]
        }
    ]
};