import React from 'react';
import { render } from 'react-dom';
import FullStory from 'react-fullstory';
import {initiateGoogleAnalytics, initiateGoogleTagManager, initiateMixpanel, initiateSentry, initiateTaboola, initiateFacebookPixel} from './common/utils/tracking';
import {Helmet} from "react-helmet";
import './polyfills';

/* Domain */
import getDomainEnv from './domains/utils';
const env = getDomainEnv();

/* Redux */
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import rootReducer  from './reducers';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
const store = createStore(rootReducer, applyMiddleware(thunk, logger));

/* Router */
import { BrowserRouter } from 'react-router-dom';
import Auth from './routes/auth';

(initiateGoogleTagManager());
// (initiateGoogleAnalytics());
// (initiateMixpanel());
(initiateSentry());
// (initiateTaboola());
(initiateFacebookPixel());

const AppStore = () => (
    <Provider store={store}>
        <BrowserRouter>
            <FullStory org={env.TRACKING.FULLSTORY_ORG_ID} />
            <Helmet>
                <title>{env.HEAD.TITLE}</title>
                <link rel={"icon"} type={"image/png"} href={env.HEAD.FAVICON_16} sizes={"16x16"} />
                <link rel={"icon"} type={"image/png"} href={env.HEAD.FAVICON_32} sizes={"32x32"} />
                <link rel={"icon"} type={"image/png"} href={env.HEAD.FAVICON_256} sizes={"256x256"} />
                <style type="text/css">{`
                    body {color:${env.HEAD.COLOR};background-color:${env.HEAD.BACKGROUND_COLOR};}
                    @font-face {font-family: '${env.HEAD.FONT_BODY.FAMILY}';  src: url('/fonts/${env.HEAD.FONT_BODY.URL}') format('${env.HEAD.FONT_BODY.FORMAT}');}
                    @font-face {font-family: '${env.HEAD.FONT_HEADER.FAMILY}';  src: url('/fonts/${env.HEAD.FONT_HEADER.URL}') format('${env.HEAD.FONT_HEADER.FORMAT}');}
                    @font-face {font-family: '${env.HEAD.FONT_SUBTITLE.FAMILY}';  src: url('/fonts/${env.HEAD.FONT_SUBTITLE.URL}') format('${env.HEAD.FONT_SUBTITLE.FORMAT}');}
                    @font-face {font-family: '${env.HEAD.FONT_TITLE.FAMILY}';  src: url('/fonts/${env.HEAD.FONT_TITLE.URL}') format('${env.HEAD.FONT_TITLE.FORMAT}');}
                `}</style>
            </Helmet>
            <Auth />
        </BrowserRouter>
    </Provider>
);

render(<AppStore />, document.getElementById('secure'));
