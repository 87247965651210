import React from "react";
import styled from 'styled-components';

/* Components */
import {colors} from '../../../common/components/colors';
import {FontBody16, FontTitle24} from '../../../common/components/fonts';
import {ButtonPrimary, ButtonTertiary} from '../../../common/components/buttons';

const Modal = styled.div`
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    padding: 30px;
    border-radius: 30px;
    background-color: ${colors.white};
    -webkit-box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    max-width: 300px;
    width: calc(100% - 80px);
    z-index: 20;
`;
const ConfirmTitle = styled.div`
    margin-bottom: 20px;
    text-align: center;
`;
const ConfirmActions = styled.div`
    margin-top: 20px;
`;
const ConfirmLeft = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
`;
const ConfirmRight = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
`;
const ConfirmBtnFitLeft = styled.div`
    width: fit-content;
`;
const ConfirmBtnFitRight = styled.div`
    width: fit-content;
    margin: 0px 0px 0px auto;
`;
export const Background = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.50);
    @media screen and (max-width: 460px) {
        background-color: ${colors.white};
    }
    z-index: 6;
`;

const TaskModuleExit = ({cancel, yes}) => (
    <>
        <Modal>
            <ConfirmTitle><FontTitle24>Exit Course</FontTitle24></ConfirmTitle>
            <FontBody16>Are you sure you want to go back? Your answers may not be saved.</FontBody16>
            <ConfirmActions>
                <ConfirmLeft>
                    <ConfirmBtnFitLeft onClick={cancel}>
                        <ButtonTertiary label={"Cancel"} canSubmit={false}/>
                    </ConfirmBtnFitLeft>
                </ConfirmLeft>
                <ConfirmRight>
                    <ConfirmBtnFitRight onClick={yes}>
                        <ButtonPrimary label={"Yes"} canSubmit={false}/>
                    </ConfirmBtnFitRight>
                </ConfirmRight>
            </ConfirmActions>
        </Modal>
        <Background onClick={cancel}/>
    </>
);

export default TaskModuleExit;
