import React from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { v4 as uuid4 } from 'uuid';

/* Components */
import { colors } from '../../../common/components/colors';
import { FontBody16, FontTitle22, FontSubtitle21, FontHeader18 } from '../../../common/components/fonts';
import { LogoLoading } from "../../../common/components/loading";
import { PageTitle } from "../components/community";
import { ButtonPrimary } from "../../../common/components/buttons";
import Checkbox from '../../../common/components/checkbox';
import { TextInput, TextareaInput, DropdownInput } from '../../../common/components/inputs';

/* Middleware */
import { tryCreateNewPost} from '../middleware/forum';
import { setCommunityPostSelected } from '../store/forum';

//#region Utiliy
const Container = styled.div`
    margin: 0 auto;
    max-width: 950px;
    display: flex;
    gap: 30px;
    flex-direction: column;
`;
const FlexRow = styled.div`
    display: flex;
    justify-content: left;
    gap: 15px;
    align-items: center;
`;
const FlexRowJCSB = styled(FlexRow)`
    justify-content: space-between;
    @media screen and (max-width: 425px) {
        flex-direction: column;
        align-items: flex-start
    }
`;
const ProfileIconInitial = styled(FontHeader18)`
    background-color: ${colors.primary30};
    border-radius: 50%;
    color: ${colors.white};
    cursor: pointer;
    display: inline-block;
    height: 60px;
    line-height: 60px;
    text-align: center;
    vertical-align: middle;
    width: 60px;
`;
const PostInner = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    gap: 15px;
`;
//#endregion


class ForumCreatePost extends React.Component {
    state = {
        'isLoading': true,
        'isSubmit': false,
        'isAnonymous': false,
        'postTitle': '',
        'postBody': '',
        'selectedCategoryId': null,
        'subCategories': null,
        'selectedCategory': null,
        'selectedChildCategory': null,

        'topicValid': false,
        'titleValid': false,
        'descriptionValid': false
    };

    componentDidMount() {
        this.init();
    }

    init = async () => {
        this.setState({
            'isLoading': false,
            'selectedCategory': this.props.parentCategory.get('title'),
            'selectedCategoryId': this.props.parentCategory.get('id'),
            'topicValid': !!this.props.parentCategory.get('id')
        })
    }

    name = () => this.state.isAnonymous ? 'Anonymous' : `${this.props.user.get('first')} ${this.props.user.get('last')}`;

    editParentCategory = async (category) => {
        this.setState({
            'selectedCategory': category.label,
            'selectedCategoryId': category.value,
            'topicValid': !!category
        });
    };

    editPostBody = async (e) => {
        await this.setState({ 'postBody': e.target.value, 'descriptionValid': !!e.target.value })
    }

    editPostTitle = async (e) => {
        this.setState({
            'postTitle': e.target.value,
            'titleValid': !!e.target.value
        })
    }

    toggleAnonymous = () => {
        this.setState({ 'isAnonymous': !this.state.isAnonymous, });
    }

    isValid = () => this.state.topicValid && this.state.titleValid && this.state.descriptionValid

    submitPost = async () => {
        let createdPost = await this.props.tryCreateNewPost(this.state.postTitle, this.state.postBody, this.state.selectedCategoryId, this.state.isAnonymous);
        this.props.setCommunityPostSelected(createdPost)
        this.props.history.push('/community/posts/post');
    }

    goToCommunity = () => {
        this.props.history.push('/community');
    }

    render() {
        if (this.state.isLoading) {
            return (<LogoLoading />)
        }
        else {
            return (
                <>
                {/* {modalToShow} */}
                <Container>
                    <PageTitle>{'Create a post'.toUpperCase()}</PageTitle>
                    <FlexRowJCSB>
                        <FlexRow>
                            <ProfileIconInitial>{this.state.isAnonymous ? 'A' : this.props.user.get('first').substring(0, 1)}</ProfileIconInitial>
                            <FontSubtitle21>{this.name()}</FontSubtitle21>
                        </FlexRow>
                        <div>
                            <Checkbox
                                action={this.toggleAnonymous}
                                checked={this.state.isAnonymous}
                                id={uuid4()}
                                label={(<FontBody16>{'Anonymous'}</FontBody16>)}
                            />
                        </div>
                    </FlexRowJCSB>
                    <TextInput
                        id={uuid4()}
                        onChange={this.editPostTitle}
                        value={this.state.postTitle}
                        title={<FontTitle22>{'Title*'}</FontTitle22>}
                        placeholder={'Write a headline for your post'}
                        valid={this.state.titleValid}
                        warning='Post title is required!'
                    />
                    <TextareaInput
                        id={uuid4()}
                        onChange={this.editPostBody}
                        value={this.state.postBody}
                        title={<FontTitle22>{'Details*'}</FontTitle22>}
                        placeholder={'Add more details about your post'}
                        count={0}
                        minLength={100}
                        required={true}
                        valid={this.state.descriptionValid}
                        warning='Post details are required!'
                    />
                    <div onClick={() => this.submitPost()}>
                        <ButtonPrimary submit={false} label={<FontBody16>{"Submit Post"}</FontBody16>} />
                    </div>
                </Container>
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.common.user,
    parentCategory: state.consumer.forum.forum.get("forumOptionSelected"),
});

const mapDispatchToProps = dispatch => ({
    setCommunityPostSelected: (post) => dispatch(setCommunityPostSelected(post)),
    tryCreateNewPost: (title, body, categoryId, isAnonymous) => dispatch(tryCreateNewPost(title, body, categoryId, isAnonymous))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForumCreatePost));