import styled from "styled-components";
import {colors} from "../../../common/components/colors";

export const Container = styled.div`
    max-width: 1100px;
    width: calc(100% - 120px);
    padding: 0px 60px;
    margin: 80px auto 0px auto;
    color: ${colors.primary100};
    @media screen and (max-width: 1440px) {
        max-width: 1008px;
        width: calc(100% - 80px);
        padding: 0px 40px;
    }
    @media screen and (max-width: 1088px) {
        width: calc(100% - 20px);
        padding: 0px 10px;
    }
    @media screen and (max-width: 460px) {
        margin: 20px auto 0px auto;
    }
`;

export const Title = styled.div`
    margin-bottom: 50px;
`;
export const LeftSub = styled.div`
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 25px);
`;
export const RightSub = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 25px;
`;
export const SectionSub = styled.div`
    margin-bottom: 15px;
    ${props => props.isOpen !== true} {
        background-color: ${colors.secondary100};
        color: ${colors.white};
    }
    ${props => props.isOpen !== false} {
        background-color: ${colors.border100};
        color: ${colors.secondary100};
    }
    padding: 20px 30px;
    cursor: pointer;
`;
export const Description = styled.div`
    & p {
        font-size: 18px;
    }
    margin: 20px 200px 40px 30px;
    @media screen and (max-width: 1088px) {
        margin: 20px 120px 40px 30px;
    }
    @media screen and (max-width: 640px) {
        margin: 20px 30px 40px 30px;
    }
    @media screen and (max-width: 460px) {
        margin: 10px 15px 20px 15px;
    }
`;
export const Icon24 = styled.div`
    display: inline-block;
    vertical-align: top;
    & svg {
        width: 24px;
        height: 24px;
        margin-top: 2px;
    }
`;