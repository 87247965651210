import {images} from '../../../common/components/images';

export const devIframeSession = {
    id: 1,
    remoteUserName: "Gabby Coach",
    remoteUserId: 1,
    thisUserName: "Jason Consumer",
    thisUserPlatform: "consumer",
    start: "2020-06-14T13:00:00+00:00",
    end: "2020-06-14T13:30:00+00:00",
    phone: "617-380-4313",
    room: "277861",
    videoStatus: "conference_ongoing", // conference_canceled, conference_ended, conference_has_not_yet_started, conference_ongoing
    videoUrl: "924fcf54447903ab9ea558886af67543", // if status !== conference_ongoing then videoUrl = null
    videoAccessToken: "abc", // if status !== conference_ongoing then videoAccessToken = null
    title: 'Jason Consumer',

    cancelToken: "3501-jz4-PJGNV3bIs8hWzTnrHzOu89pkBYUkwMUCuQ-2187",
    description: "",
    expected_end: "2021-04-29T17:30:00+00:00",
    isInternalCall: false,
    meetingIsTrial: true,
    missedByClient: true,
    missedByCoach: true,
    purchaseHistoryId: 63,
    purchaseToken: "3501-0S9lMNnRE6YT1ETFodCIduAUH00UMUQ7b3AXtw-2187",
    remoteUserFirstName: "Isabelle",
    remoteUserLastName: "Coach",
    remoteUserPhotoUrl: images.devDataTeamIsabelleAvatar,
    remoteUserReferralSource: null,
    thisUserId: 3501,
    topic: "",
    videoLinkClicked: false,
    videoLinkSent: false,
    wmcId: null
};

export const devIframeSessionFromToken = {
    "id":1566,
    "start":"“2021-10-29T00:20:00+00",
    "end":"2021-10-29T01:05:00+00",
    "expected_end":"2021-10-29T01:05:00+00",
    "phone":"617-380-4313",
    "videoStatus":"conference_has_not_yet_started",
    "title":"Duoo H.",
    "videoLinkSent":false,
    "videoLinkClicked":false,
    "topic":"",
    "purchaseToken":"3800-Lxil-N4AH4dPulkhjAYmLrQLrdPiVaZllDHkCA-3452",
    "purchaseHistoryId": null,
    "description": "", "missedByClient": true,
    "missedByCoach": true,
    "wmcId": null,
    "isInternalCall": false,
    "meetingIsTrial": true,
    "coachLogo": null,
    "remoteUserId": 3452,
    "remoteUserName": "Mary Conner",
    "remoteUserFirstName": "Mary",
    "remoteUserLastName": "Conner",
    "remoteUserPhotoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1616769850/kmwrc0xpnfqbwfqeqjnw.png",
    "cancelToken": "3800-fvegZ5jsLpNmyMIdJC_JJ7jJnV0O2AkNrTA2Ww-3452",
    "remoteUserReferralSource":null,
    "thisUserId":3800,
    "thisUserName":"Duoo H.",
    "thisUserPlatform":"consumer",
    "room":"142928",
    "videoAccessToken": null,
    "videoUrl": "3800-fvegZ5jsLpNmyMIdJC_JJ7jJnV0O2AkNrTA2Ww-3452",
    "conversationSid": null,
    "participantSid": null,
    "accessToken": null,
    "isWillowSession": true
};