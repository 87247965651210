import React from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FontBody16} from '../../../common/components/fonts';
import {PageTitle, Back, BackSection, Container, ContainerJCFS, ModifyListings} from '../components/shop';
import {PillBar, Pill, Background, SidebarRight, FlexColPadding} from '../../../common/components/layout';
import {ChevronLeft} from "react-feather";
import WillowListing from '../../../iframes/listingswillow/components/willowlisting';

/* Containers */
import ConsumerCheckout from './checkout';
import ListingsFlow from "../../../iframes/listings/containers/flow";

/* Middleware */
import {tryGetListingsAll} from '../../../iframes/listings/middleware/listings';

/* Utils */
import {trackViewProductsToPurchase} from '../../../common/utils/tracking';

class ConsumerShop extends React.Component {
    static defaultProps = {
        "coachId": null,
        "onClose": () => {},
        "onBack": () => {},
        "onCloseCheckoutSuccess": () => {},
        "onCheckout": () => {},
        "onBackToCart": () => {},
        "shouldCheckCoachListings": true,
        "showCartBackground": true,
        "showCartBackBtn": true
    };

    state = {
        "isLoading": true,
        "currentTab": "willow", /* willow, coach */
        "coachId": null,
        "planId": null,
        "quantity": null,
        "hasListings": false
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.location.pathname !== this.props.location.pathname && !this.props.location.pathname.includes("checkout")) {
            this.setState({"showCheckout": false})
        }
    }

    init = async () => {
        let listings;
        if(this.props.coachId !== null && this.props.shouldCheckCoachListings) {
            listings = await this.props.tryGetListingsAll(this.props.coachId);
        } else {
            listings = []
        }
        await this.setState({
            "currentTab": this.props.isExclusive ? "coach" : 'willow',
            "quantity": null,
            "hasListings": listings.length !== 0,
            "isLoading": false
        });
        this.props.trackViewProductsToPurchase();
    };

    changeTab = (tab) => this.setState({"currentTab": tab});
    
    changeShowCheckout = async () => {
        await this.props.onBackToCart();
        this.setState({"showCheckout": false})
    };

    useCheckoutFunction = async (planId, quantity) => {
        await this.setState({
            "planId": this.state.currentTab === "coach" ? planId : "willow_session",
            "quantity": this.state.currentTab === "coach" ? null : quantity
        });
        await this.props.onCheckout();
        this.setState({"showCheckout": true})
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            if(this.state.showCheckout) {
                return(
                    <>
                        {this.props.showCartBackground && <Background onClick={this.props.onClose}/>}
                        <SidebarRight>
                            <FlexColPadding>
                                <ConsumerCheckout
                                    onBack={this.changeShowCheckout}
                                    onClose={this.props.onClose}
                                    onCloseCheckoutSuccess={this.props.onCloseCheckoutSuccess}
                                    planId={this.state.planId}
                                    quantity={this.state.quantity}
                                    showBackBtn={this.props.showCartBackBtn}
                                />
                            </FlexColPadding>
                        </SidebarRight>
                    </>
                )
            } else {
                return(
                    <>
                        {this.props.showCartBackground && <Background onClick={this.props.onClose}/>}
                        <SidebarRight>
                            <FlexColPadding>
                                <Container>
                                    {this.props.showCartBackBtn &&
                                        <BackSection>
                                            <Back onClick={this.props.onBack}><ChevronLeft/></Back>
                                        </BackSection>
                                    }
                                    <ContainerJCFS>
                                        <PageTitle>{'SELECT A PROGRAM THAT FITS YOUR NEEDS'}</PageTitle>
                                        {(this.state.hasListings && !this.props.isExclusive) &&
                                            <PillBar>
                                                <Pill active={this.state.currentTab === 'willow'} onClick={() => {this.changeTab('willow');}}>
                                                    <FontBody16>{'Willow'}</FontBody16>
                                                </Pill>
                                                <Pill active={this.state.currentTab === 'coach'} onClick={() => {this.changeTab('coach');}}>
                                                    <FontBody16>{'Coach'}</FontBody16>
                                                </Pill>
                                            </PillBar>
                                        }
                                    </ContainerJCFS>
                                    {this.state.currentTab === 'willow' &&
                                        <WillowListing
                                            useCheckoutFunction={this.useCheckoutFunction}
                                            quantity={this.state.quantity}
                                            pricePerSession={150}
                                            enableDiscount={false}
                                        />
                                    }
                                    {this.state.currentTab === 'coach' &&
                                        <ModifyListings>
                                            <ListingsFlow
                                                userId={this.props.coachId}
                                                title={""}
                                                description={""}
                                                listingId={null}
                                                isPreview={true}
                                                forceImagesVertical={true}
                                                useCheckoutFunction={this.useCheckoutFunction}
                                            />
                                        </ModifyListings>
                                    }
                                </Container>
                            </FlexColPadding>
                        </SidebarRight>
                    </>
                )
            }
        }
    }
}

const mapStateToProps = state => ({
    isExclusive: state.common.user.get("isExclusive")
});

const mapDispatchToProps = dispatch => ({
    tryGetListingsAll: (userId) => dispatch(tryGetListingsAll(userId)),
    trackViewProductsToPurchase: () => dispatch(trackViewProductsToPurchase()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConsumerShop));
