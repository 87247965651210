import {v4 as uuid4} from 'uuid';

const companyFAQ = [
    {
        "id": uuid4(),
        "title": "",
        "faqs": [
            {
                "id": uuid4(),
                "title": "Can Willow showcase my new content?",
                "body": "<div>Yes! We love working with partners to share their articles, podcasts and other content. Or if you have an idea for new content, let us know and we may be able to help. <span data-link-type='page' data-label='Contact your RM' data-label-action-props='2'>Contact your RM</span>.</div>"
            },
            {
                "id": uuid4(),
                "title": "How does the Willow brand work with my existing brand?",
                "body": "<div>By partnering with Willow, you are amplifying your practice and broadening your reach to help more women with their financial wellness.</div>"
            },
            {
                "id": uuid4(),
                "title": "Can Willow compliantly advertise my firm?",
                "body": "<div>Yes! We can market you while staying in compliance of regulatory agencies, <span data-link-type='page' data-label='Contact your RM' data-label-action-props='2'>Contact your RM</span>.</div>"
            },
            {
                "id": uuid4(),
                "title": "What are the guidelines for using the Willow Certificate & Badge?",
                "body": "<div>Please find the enclosed guidelines that describe the allowed usage of Willow's Certificates & Badges. <a data-link-type='external' data-label='click here' target='_blank'  href='https://docs.trustwillow.com/Willow_Certificate_Usage_Guide.pdf'>click here</a>.</div>"
            },
        ]
    },
    {
        "id": uuid4(),
        "title": "My Coaches",
        "faqs": [
            {
                "id": uuid4(),
                "title": "Can I partner with multiple experts?",
                "body": "<div>Yes! We have partnerships with exceptional experts in the areas of financial coaching, life & career coaching, financial planning, investing, insurance, banking, and legal. Let us know what your prospects and clients need and we can help connect you. <span data-link-type='page' data-label='Contact your RM' data-label-action-props='2'>Contact your RM</span>.</div>"
            },
            {
                "id": uuid4(),
                "title": "What is the vetting process for experts?",
                "body": "<div>Each partner completes a matrix of questions to demonstrate their areas of knowledge, expertise and personal interests. Our team performs deep diligence including background checks, interviews, mock client calls, client references, and more. To learn more <span data-link-type='page' data-label='Contact support' data-label-action-props='3'>Contact support</span>.</div>"
            },
            {
                "id": uuid4(),
                "title": "How are partners matched to my firm?",
                "body": "<div>Willow matches you with partners who meet the service needs of your firm and clients. Criteria includes area of expertise, life journey experiences, and personal interests.</div>"
            },
            {
                "id": uuid4(),
                "title": "Why do we ask about life journeys?",
                "body": "<div>Research shows that people tend to engage most with their money when going through life transitions and major moments. By learning about the life journeys of our experts, we’re able to connect people that we believe can relate to each other - which fosters stronger relationships and success.</div>"
            },
            {
                "id": uuid4(),
                "title": "What are Willow’s partnership expectations?",
                "body": "<div>All partners are expected to uphold Willow’s core values in all interactions. These values are trust, transparency, empathy and respect. Experts </div>"
            },
            // {
            //     "id": uuid4(),
            //     "title": "Where do I view the latest pricing information?",
            //     "body": "<div>Willow is updating its pricing. To receive up-to-date pricing <span data-link-type='page' data-label='Contact your RM' data-label-action-props='2'>Contact your RM</span>.</div>"
            // },
        ]
    },
    // {
    //     "id": uuid4(),
    //     "title": "Prospects, Clients",
    //     "faqs": []
    // },
    // {
    //     "id": uuid4(),
    //     "title": "Contacts",
    //     "faqs": [
    //         {
    //             "id": uuid4(),
    //             "title": "How do I create a contact note?",
    //             "body": "<div>Click on the name of your contact. Then in the actions box on the top right corner of the screen, click the Note button.</div>"
    //         },
    //     ]
    // },
    // {
    //     "id": uuid4(),
    //     "title": "Email",
    //     "faqs": [
    //         {
    //             "id": uuid4(),
    //             "title": "Can I send emails from my email address?",
    //             "body": "<div>No. Emails are sent from messages@trustwillow.com with your name as the Sender. </div>"
    //         },
    //         {
    //             "id": uuid4(),
    //             "title": "How do recipients reply to emails?",
    //             "body": "<div>Recipients can reply directly to the email. Replies are automatically archived on Willow and sent to your Gmail, Outlook, or other inbox.</div>"
    //         },
    //         {
    //             "id": uuid4(),
    //             "title": "Can I use my Gmail, Outlook or other inbox?",
    //             "body": "<div>To archiving emails on Willow, initiate an email with a client from the contact's details page. You can then reply to this email thread from your Gmail, Outlook, or other inbox.</div>"
    //         },
    //         {
    //             "id": uuid4(),
    //             "title": "Can I create my own email templates?",
    //             "body": "<div>Not yet. If you need this functionality, please <span data-link-type='page' data-label='Contact tech support' data-label-action-props='4'>Contact tech support</span>.</div>"
    //         },
    //     ]
    // },
    {
        "id": uuid4(),
        "title": "Appointments",
        "faqs": [
            {
                "id": uuid4(),
                "title": "Can I join meetings by video or phone?",
                "body": "<div>Yes! Video meetings often produce stronger connections - although we understand it may not always be possible to be on camera.</div>"
            },
            {
                "id": uuid4(),
                "title": "Are calendar invitations automatically sent?",
                "body": "<div>Yes! Each meeting participant will receive a calendar invitation from meetings@trustwillow.com.</div>"
            },
            {
                "id": uuid4(),
                "title": "Can I share my calendar invitation?",
                "body": "<div>No. As a security measure to protect your meetings from intrusion, tach meeting participant receives their own unique video link which can only be used by one participant at a time.</div>"
            },
            // {
            //     "id": uuid4(),
            //     "title": "Can I invite more participants to a meeting?",
            //     "body": "<div>Yes! Navigate to the Meetings page. Find and click on the meeting on your calendar to view the details. Finally, click Invite to search for and invite more contacts to your meeting.</div>"
            // },
            // {
            //     "id": uuid4(),
            //     "title": "Can I invite more than 3 participants to a meeting?",
            //     "body": "<div>Currently 4 participants (which includes you) can be in a meeting. If you require more participants, please <span data-link-type='page' data-label='Contact tech support' data-label-action-props='4'>Contact tech support</span>.</div>"
            // },
            {
                "id": uuid4(),
                "title": "Are meetings recorded?",
                "body": "<div>Yes. Recordings are not publicly accessible and are used for service and support. From time to time, Willow may also review recordings to help us improve our platform. To view our full Privacy Policy <a data-link-type='external' data-label='click here' target='_blank'  href='https://trustwillow.com/privacy-policy'>click here</a>.</div>"
            },
        ]
    },
    {
        "id": uuid4(),
        "title": "SMS Messages",
        "faqs": [
            {
                "id": uuid4(),
                "title": "Can I send SMS messages from my phone number?",
                "body": "<div>No. SMS messages are sent from a Willow phone number with your name as the sender.</div>"
            },
            {
                "id": uuid4(),
                "title": "How do recipients reply to SMS messages?",
                "body": "<div>Recipients can reply directly to the SMS. Replies are automatically archived on Willow and sent to your mobile phone. You can open the link on your mobile phone to reply to incoming SMS messages or reply on the contact's details page.</div>"
            },
            {
                "id": uuid4(),
                "title": "Can I reply to SMS messages directly?",
                "body": "<div>No. When you receive an SMS message reply it will contain a link to a secure message portal. Click this link to open a web page where you can reply to the message. Alternatively, you can reply from the contact's details page.</div>"
            },
            // {
            //     "id": uuid4(),
            //     "title": "Can I create my own SMS templates?",
            //     "body": "<div>Not yet. If you need this functionality, please <span data-link-type='page' data-label='Contact tech support' data-label-action-props='4'>Contact tech support</span>.</div>"
            // },
        ]
    },
    // {
    //     "id": uuid4(),
    //     "title": "My Profile",
    //     "faqs": [
    //         {
    //             "id": uuid4(),
    //             "title": "Can I change my profile image?",
    //             "body": "<div>Yes! You can update your Profile photo on the My Profile page's profile tab.</div>"
    //         },
    //     ]
    // },
    // {
    //     "id": uuid4(),
    //     "title": "Scheduler",
    //     "faqs": [
    //         {
    //             "id": uuid4(),
    //             "title": "Can I sync my Outlook or Google calendar?",
    //             "body": "<div>Yes! You can connect your Outlook and Google calendars to automatically set your availability.</div>"
    //         },
    //         {
    //             "id": uuid4(),
    //             "title": "Do I have to sync my Outlook or Google calendar?",
    //             "body": "<div>No. You can create availability for specific days and times.</div>"
    //         },
    //         {
    //             "id": uuid4(),
    //             "title": "Can I set myself as busy at specific days or times?",
    //             "body": "<div>Not yet. If you need this functionality, please <span data-link-type='page' data-label='Contact tech support' data-label-action-props='4'>Contact tech support</span>.</div>"
    //         },
    //         {
    //             "id": uuid4(),
    //             "title": "Can I set myself on vacation?",
    //             "body": "<div>Not yet. Currently you can stop offering availability for the days you will be on vacation. If you require more customization, please <span data-link-type='page' data-label='Contact tech support' data-label-action-props='4'>Contact tech support</span>.</div>"
    //         },
    //     ]
    // },
    {
        "id": uuid4(),
        "title": "Account & Privacy",
        "faqs": [
            {
                "id": uuid4(),
                "title": "How does Willow keep information confidential?",
                "body": "<div>We take data security seriously. Willow is protected with 256-bit encryption and complex passwords. To view our full Privacy Policy <a data-link-type='external' target='_blank' data-label='click here' href='https://trustwillow.com/privacy-policy'>click here</a>.</div>"
            },
            {
                "id": uuid4(),
                "title": "How do I change my password?",
                "body": "<div>You can conveniently update your password at any time on your Dashboard. <a data-link-type='internal' data-label='click here' href='/my-settings'>click here</a>.</div>"
            },
        ]
    }
];

export default companyFAQ;