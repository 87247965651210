import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import moment from "moment";
import styled from "styled-components";

/* Components */
import {
    FontBody16,
    FontBody18,
    FontHeader18,
    FontTitle18
} from '../../../../common/components/fonts';
import {ButtonSecondary} from "../../../../common/components/buttons";
import {LogoLoading} from "../../../../common/components/loading";
import {EditOutlined, PlusOutlined, DeleteOutlined} from "@ant-design/icons";
import {LoadingCenter, FitContent, Border, Btn, Inline, TextLeft} from "../../components/portfolios";

/* Store */
import {setShopItemLicense} from "../../store/profileitems";

/* Middleware */
import {tryUpdateExpertProfile} from "../../middleware/expertprofile";
import {
    GrayColor,
    ImagePlaceholder,
    ImageSub,
    LeftSub,
    RightSub, SectionSub,
    Spacing,
    SubTitle,
    BigSpacing
} from "../../components/experience";

const Bullet = styled.div`
    display: inline-block;
`;
const BulletSpacing = styled.div`
    padding-left: 15px;
`;
const ActionsSpacing = styled.div`
    padding-bottom: 15px;
`;

const pickMonth = (num) => {
    if(num === 1) {return("Jan")}
    else if(num === 2) {return("Feb")}
    else if(num === 3) {return("Mar")}
    else if(num === 4) {return("Apr")}
    else if(num === 5) {return("May")}
    else if(num === 6) {return("Jun")}
    else if(num === 7) {return("Jul")}
    else if(num === 8) {return("Aug")}
    else if(num === 9) {return("Sep")}
    else if(num === 10) {return("Oct")}
    else if(num === 11) {return("Nov")}
    else if(num === 12) {return("Dec")}
};

class ProfileCertifications extends React.Component {
    state = {"licenses": []};

    componentDidMount() {
        return this.init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.profile !== this.props.profile) {
            return this.init()
        }
    }

    init = async () => {
        this.setState({
            "licenses": this.props.profile.get("licenses").toJS(),
            "isLoading": false
        });
    };

    add = async () => {
        await this.props.setShopItemLicense({});
        this.props.history.push("/settings/profile/about/license/create");
    };

    edit = async (item) => {
        await this.props.setShopItemLicense(item);
        this.props.history.push("/settings/profile/about/license/edit");
    };

    delete = async (item) => {
        let licenses = this.props.profile.get("licenses").toJS();
        const saveData = {
            // "bio": this.props.profile.get("bio"),
            // "whyBecameCoach": this.props.profile.get("whyBecameCoach"),
            // "freeTime": this.props.profile.get("freeTime"),
            // "endorsement1": this.props.profile.get("endorsement1"),
            // "endorsement2": this.props.profile.get("endorsement2"),
            // "endorsement3": this.props.profile.get("endorsement3"),
            "licenses": await licenses.filter(p => {return(p.id !== item.id)}),
        };
        await this.props.tryUpdateExpertProfile(saveData, "deleteLicense");
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <FontTitle18>Licenses, Certifications</FontTitle18>
                    <br/>
                    <br/>
                    <>
                        {this.state.licenses.map((j, index) => (
                            <div key={j.id}>
                                <div>
                                    <RightSub>
                                        <SubTitle><FontHeader18><Bullet>&#8226;&nbsp;&nbsp;</Bullet>{j.title}</FontHeader18></SubTitle>
                                        {j.institute !== null && <BulletSpacing><FontBody18>{j.institute}</FontBody18></BulletSpacing>}
                                        <Spacing />
                                        {(j.endYear !== null && j.endMonth !== null) &&
                                            <BulletSpacing>
                                                <FontBody16>
                                                    <GrayColor>
                                                        {j.endMonth !== null && <span>{pickMonth(j.endMonth)}</span>}
                                                        {(j.endMonth !== null && j.endYear !== null) && <span>,&nbsp;</span>}
                                                        {j.endYear !== null && <span>{j.endYear}</span>}
                                                    </GrayColor>
                                                </FontBody16>
                                            </BulletSpacing>
                                        }
                                        {j.licenseNumber !== null && 
                                        <>
                                            <BulletSpacing><GrayColor><FontBody16>{j.licenseNumber}</FontBody16></GrayColor></BulletSpacing>
                                        </>}
                                        <ActionsSpacing />
                                    </RightSub>
                                </div>
                                <TextLeft>
                                    <Inline>
                                        <FitContent>
                                            <Btn>
                                                <FontBody16 onClick={() => {return this.edit(j)}}><EditOutlined/>&nbsp;Edit</FontBody16>
                                            </Btn>
                                        </FitContent>
                                    </Inline>
                                    <Inline>
                                        <FitContent>
                                            <Btn>
                                                <FontBody16 onClick={() => {return this.delete(j)}}><DeleteOutlined />&nbsp;Delete</FontBody16>
                                            </Btn>
                                        </FitContent>
                                    </Inline>
                                </TextLeft>
                                {index + 1 !== this.state.licenses.length && <BigSpacing />}
                            </div>
                        ))}
                    </>
                    <br />
                    <FitContent>
                        <FontBody16 onClick={() => {return this.add()}}>
                            <ButtonSecondary canSubmit={false} label={<><PlusOutlined />&nbsp;Add License, Certification</>} />
                        </FontBody16>
                    </FitContent>
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    isWMC: state.common.wmc.get("isWMC"),
    profile: state.enterprise.profile.expertProfile.get("expertProfile"),
});

const mapDispatchToProps = dispatch => ({
    setShopItemLicense: (item) => dispatch(setShopItemLicense(item)),
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileCertifications));
