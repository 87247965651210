import React from 'react';
import styled from 'styled-components';

/* Components */
import { FontSubtitle40, FontBody18 } from '../../../common/components/fonts';
import { colors } from '../../../common/components/colors';

const Welcome = styled.div`
    color: ${colors.primary100};
`;
const Subtitle = styled(FontBody18)`
    margin-top: 10px;
`;

class ConsumerDashboardWelcome extends React.Component {
    render() {
        return (
            <Welcome>
                <FontSubtitle40 spaced={true}>
                    {"WELCOME"+(this.props.user.get("newUser") ? "" : " BACK")+", "+this.props.user.get('first').toUpperCase()}
                </FontSubtitle40>
                <Subtitle>Get the guidance you need on your life journey</Subtitle>
            </Welcome>
        )
    }
}

export default ConsumerDashboardWelcome;
