import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {
    setBillingHistory,
    setBillingHistoryFetching,
    setBillingPaymentMethod,
    setBillingPaymentMethodFetching,
    setBillingPaymentMethodUpdate,
    setBillingPaymentMethodUpdating,
    // setBillingCurrentPlan,
    // setBillingCurrentPlanFetching,
    setBillingCurrentPlanCanceling
} from '../store/billing';

/* Static */
import {
    devEnterpriseBillingHistoryData,
    devEnterpriseBillingPaymentMethodData
    // devEnterpriseBillingCurrentPlanData
} from '../static/devdata';

export const tryGetBillingHistory = () => {
    return async dispatch => {
        dispatch(setBillingHistoryFetching(true));
        const history = await axiosReq('apiv1/consumer/purchase/get-purchase-history', "GET", {}, devEnterpriseBillingHistoryData);
        await dispatch(setBillingHistory(history));
        return dispatch(setBillingHistoryFetching(false));
    }
};

// export const tryGetBillingHistory = () => {
//     return async dispatch => {
//         dispatch(setBillingHistoryFetching(true));
//         const history = await axiosReq('apiv1/billing/history', "GET", {}, devEnterpriseBillingHistoryData);
//         await dispatch(setBillingHistory(history));
//         return dispatch(setBillingHistoryFetching(false));
//     }
// };

export const tryGetBillingPaymentMethod = () => {
    return async dispatch => {
        dispatch(setBillingPaymentMethodFetching(true));
        const paymentMethod = await axiosReq('apiv1/consumer/settings/payments/get-payment-methods', "GET", {}, devEnterpriseBillingPaymentMethodData);
        await dispatch(setBillingPaymentMethod(paymentMethod));
        return dispatch(setBillingPaymentMethodFetching(false));
    }
};

export const tryDeleteBillingPaymentMethod = (id) => {
    return async dispatch => {
        dispatch(setBillingPaymentMethodFetching(true));
        await axiosReq('apiv1/consumer/settings/payments/detach-payment-method', "POST", {"paymentMethodId": id}, {});
        return dispatch(setBillingPaymentMethodFetching(false));
    }
};

export const tryGetBillingPaymentMethodUpdate = (data) => {
    return async dispatch => {
        dispatch(setBillingPaymentMethodUpdating(true));
        const paymentMethod = await axiosReq('apiv1/consumer/settings/payments/update-payment-method', "POST", data, {});
        // await dispatch(setBillingPaymentMethodUpdate(paymentMethod));
        return dispatch(setBillingPaymentMethodUpdating(false));
    }
};
//
// export const tryGetBillingCurrentPlan = () => {
//     return async dispatch => {
//         dispatch(setBillingCurrentPlanFetching(true));
//         const currentPlan = await axiosReq('apiv1/billing/current-plan', "GET", {}, devEnterpriseBillingCurrentPlanData);
//         await dispatch(setBillingCurrentPlan(currentPlan));
//         return dispatch(setBillingCurrentPlanFetching(false));
//     }
// };

export const tryGetBillingCancelPlan = (planId) => {
    return async dispatch => {
        dispatch(setBillingCurrentPlanCanceling(true));
        await axiosReq('apiv1/consumer/purchase/cancel-subscription/' + planId, "POST", {}, {});
        return dispatch(setBillingCurrentPlanCanceling(false));
    }
};