import React from 'react';
import {connect} from 'react-redux';
import styled from "styled-components";
import { withRouter } from 'react-router-dom';

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FontBody14, FontSubtitle21, FontSubtitle28, FontHeader16, FontBody16} from '../../../common/components/fonts';
import { colors } from '../../../common/components/colors';
import { Container, FlexCol } from '../../../common/components/layout';
import {Message} from "../../../common/components/messages";

/* Containers */
import SMSById from '../../../iframes/smsbyid/containers/smscompose';

/* Middleware */
import {tryGetAllTeamMembers} from '../../team/middleware/team';

import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const Extra54px = styled.div`
    height: 24px;
    width: 100%;
`;
const MessageContact = styled.div`
    align-items: center;
    cursor: pointer;
    display: grid;
    grid-column-gap: 15px;
    grid-template-columns: 0.5fr 4.5fr;
    padding: 30px 0px;
    &:not(:last-child) {
        border-bottom: 1px solid ${colors.secondary70};
    }
`;
const NameTitle = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
`;
const ProfileIcon = styled.img`
    border-radius: 50%;
    height: 50px;
    width: 50px;
    object-fit: cover;
`;
const FindAnotherButton = styled.div`
    align-self: center;
    background-color: ${colors.action100};
    border-radius: 25px;
    color: ${colors.white};
    cursor: pointer;
    padding: 15px 30px;
    box-shadow: 0px 3px 10px 0px #00000040;
    margin-top: 60px;
`;
const PageTitle = styled(FontSubtitle28)`
    text-align: center;
    margin-bottom: 10px;
`;
const Subtext = styled(FontBody16)`
    color: ${colors.secondary100};
    margin-bottom: 30px;
    text-align: center;
`;
const Position = styled(FontBody14)`
    color: ${colors.secondary100};
`;

class MessagesList extends React.Component {
    state = {"isLoading": true, "members": null, "selectedMember": null,};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.props.tryGetAllTeamMembers();
        let members = await this.props.members.filter((m) => {return(m.get("wmcId") === null)});
        // let hasCoach = !(members.size === 0);
        // if(hasCoach) {
            this.setState({"members": members, "isLoading": false})
        // } else {
        //     this.props.history.push("/my-team/find");
        // }
    };

    toggleSelectMember = (member) => {
        this.setState({ "selectedMember": member });
    }

    findAnotherCoach = () => this.props.history.push('/my-team/find');

    render() {
        if (this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            if (this.state.members.size > 0) {
                return (
                    <>
                        <Container>
                            <Extra54px />
                            <PageTitle>{'MESSAGES'}</PageTitle>
                            <FlexCol>
                                {this.state.members.map((a, index) => {
                                    const data = a.toJS();
                                    return (
                                        <MessageContact 
                                            key={index} 
                                            onClick={() => this.toggleSelectMember(data)}
                                        >
                                            <ProfileIcon src={data.photo} alt={"Willow"} />
                                            <NameTitle>
                                                <FontSubtitle21>{data.first + ' ' + data.last}</FontSubtitle21>
                                                <Position>
                                                    {env.CONSUMER.CARE.PRINT_EXPERT_CATEGORIES(data.expertCategories, true)}
                                                </Position>
                                            </NameTitle>
                                        </MessageContact>
                                    );
                                })}
                            </FlexCol>
                            <FindAnotherButton onClick={this.findAnotherCoach}><FontHeader16>{'Find Another Coach'}</FontHeader16></FindAnotherButton>
                        </Container>
                        {this.state.selectedMember && 
                            <SMSById
                                onClose={() => this.toggleSelectMember(null)}
                                recipientId={this.state.selectedMember.coachId}
                                recipientFirstName={this.state.selectedMember.first}
                                recipientLastName={this.state.selectedMember.last}
                                from={"consumer"}
                                to={"coach"}
                            />
                        }
                    </>
                )
            } else {
                return (
                    <Container>
                        <Extra54px />
                        <PageTitle>{'MESSAGES'}</PageTitle>
                        <Subtext>To message your team,<br />first book an appointment with an<br />expert.</Subtext>
                        <FindAnotherButton onClick={this.findAnotherCoach}><FontHeader16>{'Find A Coach'}</FontHeader16></FindAnotherButton>
                    </Container>
                )
            }
        }
    }
}

const mapStateToProps = state => ({
    members: state.consumer.team.team.get("members"),
});

const mapDispatchToProps = dispatch => ({
    tryGetAllTeamMembers: () => dispatch(tryGetAllTeamMembers())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MessagesList));