import React from "react";
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import styled from "styled-components";
import moment from "moment/moment";

/* Components */
import {LogoLoading} from "../../../../common/components/loading";
import {images} from "../../../../common/components/images";
import Success from "../../../../iframes/success/containers/success";
import {ButtonLink} from "../../../../common/components/buttons";

/* Middleware */
import {tryGetSurveyAttemptsToday} from "../middleware/tasks";

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;
const FitContent = styled.div`
    width: fit-content;
    cursor: pointer;
    margin: 0 auto 25px auto;
    & > div {
        min-width: 87px;
    }
`;

class TasksFailed extends React.Component {
    state = {"isLoading": true, "canAttemptAgain": true, "courseIdQuiz": null};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const courseIdQuiz = window.location.pathname.split('/')[3];
        const attempts = await this.props.tryGetSurveyAttemptsToday();
        const attemptsFailed = attempts.completedSurveys.filter(r => {
            let isToday = moment(r.completedOn).isSame(moment(), 'day')
            return isToday && r.quizResults.score < 70 && r.courseId.toString() === courseIdQuiz.toString();
        })
        if(attemptsFailed.length < 2) {
            this.setState({"canAttemptAgain": true, "courseIdQuiz": courseIdQuiz, "isLoading": false});
        } else {
            this.setState({"canAttemptAgain": false, "isLoading": false});
        }
    };

    render() {
        if(this.state.isLoading) {
            return (<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if(this.state.canAttemptAgain) {
                return(
                    <>
                        <Success
                            title={"YOU DID NOT PASS"}
                            subtitle={"To earn CE credits, please try again. "}
                            icon={images.failedIcon}
                            buttonLabel={"Take quiz again"}
                            back={() => {this.props.history.push("/my-tasks/course/"+this.state.courseIdQuiz+"/quiz")}}
                        />
                        <FitContent onClick={() => {this.props.history.push("/my-certificates/my-trainings")}}>
                            <ButtonLink label={"All Trainings"}/>
                        </FitContent>
                    </>
                )
            } else {
                return(
                    <Success
                        title={"MAXED OUT ATTEMPTS"}
                        subtitle={"You can only attempt to take the quiz twice in one day. Try taking the quiz tomorrow to earn your CE credits."}
                        icon={images.failedIcon}
                        buttonLabel={"All Trainings"}
                        back={() => {this.props.history.push("/my-certificates/my-trainings")}}
                    />
                )
            }
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryGetSurveyAttemptsToday: () => dispatch(tryGetSurveyAttemptsToday()),
})

export default connect(null, mapDispatchToProps)(withRouter(TasksFailed));
