import React from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

/* Components */
import {FontTitle38, FontBody16, FontSubtitle18} from '../../common/components/fonts';
import {colors} from "../../common/components/colors";
import {images} from '../../common/components/images';
import {LogoLoading} from "../../common/components/loading";
import {TopLogo} from "./components/toplogo";

/* Containers */
import AdvisorSignUp from './containers/signup';
import SignupFlow from '../signup/containers/flow';
import Success from "../success/containers/success";

/* Middleware */
// import {tryPostAssignCoachWillowSessions, tryPostAssignCoachTrainingSessions} from "./middleware/signup";

/* Domain */
import getDomainEnv from '../../domains/utils';
const env = getDomainEnv();

const LoadingCenter = styled.div`
  margin: 60px auto;
  text-align: center;
`;
const Container = styled.div`
  background-color: #${props => props.backgroundColor};
  ${props => props.showRegOnly !== true} {
    & > div {
      max-width: calc(100% - 90px) !important;
    }
  }
  ${props => props.showRegOnly !== false} {
    max-width: 500px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 100px auto 0px auto;
    @media screen and (max-width: 460px) {
      margin: 40px auto 0px auto;
    }
  }
`;
const NavBar = styled.div`
  margin: 45px 0px 25px 0px;
  text-align: center;
`;
const NavBarOptions = styled.div`
  margin: 0px 0px 50px 0px;
  display: flex;
  flex-direction: row;
  gap: 0px;
`;
const NavOption = styled(FontSubtitle18)`
  display: inline-flex;
  ${props => props.active !== true} {
    border-bottom: 7px solid ${colors.action100};
  }
  ${props => props.active !== false} {
    border-bottom: 7px solid ${colors.backgroundChatBubble};
  }
  padding: 20px 0px;
  width: 100%;
  justify-content: center;
  cursor: pointer;
`;
const LoginLink = styled.div`
    color: ${colors.white};
    text-decoration: underline;
    display: inline-block;
    cursor: pointer;
`;
const Title = styled(FontTitle38)`
    margin-bottom: 5px;
`;


class IFrameSignupAdvisor extends React.Component {
    state = {
        "isLoading": true,
        "firstName": "",
        "lastName": "",
        "email": "",
        "wmcEmployeeId": null,
        "partnerLogo": null,
        "page": "signup",
        "showRegOnly": false,
        "backgroundColor": "ffffff",
        "redirectToVerify": false,
        "isReferredByPartner": false,
        "partnerUrl": null,
        "partnerPrepaidAdvisorSessions": 0,
        "partnerPrepaidTrainingSessions": 0
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const searchParams = await new URLSearchParams(window.location.search);
        const firstName = searchParams.get("firstName");
        const lastName = searchParams.get("lastName");
        const email = searchParams.get("email");
        const showRegOnly = searchParams.get("showRegOnly");
        const backgroundColor = searchParams.get("backgroundColor");
        const redirectToVerify = searchParams.get("redirectToVerify");
        const partnerCode = searchParams.get("partnerCode");
        const promotion = env.ENTERPRISE.COMPANY.SIGN_UP.IS_REFERRED_BY_PARTNER[partnerCode]
        const wmcEmployeeId = promotion === undefined ? "" : (promotion.wmcEmployeeId === undefined ? "" : "partner:"+promotion.wmcEmployeeId);
        const utm_source = searchParams.get("utm_source") === undefined ? "" : "utm_source:"+searchParams.get("utm_source");
        const utm_medium = searchParams.get("utm_medium") === undefined ? "" : "utm_medium:"+searchParams.get("utm_medium");
        const utm_campaign = searchParams.get("utm_campaign") === undefined ? "" : "utm_campaign:"+searchParams.get("utm_campaign");
        const utm_content = searchParams.get("utm_content") === undefined ? "" : "utm_content:"+searchParams.get("utm_content");
        const utm_term = searchParams.get("utm_term") === undefined ? "" : "utm_term:"+searchParams.get("utm_term");
        const wmcEmployeeIdPlusUTM = wmcEmployeeId+" "+utm_source+" "+utm_medium+" "+utm_campaign+" "+utm_content+" "+utm_term;
        await this.setState({
            "firstName": firstName !== null ? firstName : "",
            "lastName": lastName !== null ? lastName : "",
            "email": email !== null ? decodeURIComponent(email) : "",
            "wmcEmployeeId": wmcEmployeeIdPlusUTM,
            "partnerLogo": promotion === undefined ? null : (promotion.logo === undefined ? null : images[promotion.logo]),
            "partnerUrl": promotion === undefined ? null : (promotion.url === undefined ? null : promotion.url),
            "showRegOnly": showRegOnly !== null ? (showRegOnly === "true") : false,
            "backgroundColor": backgroundColor !== null ? backgroundColor : "ffffff",
            "redirectToVerify": redirectToVerify !== null ? (redirectToVerify === "true") : false,
            "isReferredByPartner": promotion === undefined ? false : promotion.isReferredByPartner,
            "partnerPrepaidAdvisorSessions": promotion === undefined ? 0 : (promotion.assignAdvisorSessionsAfterSignup === undefined ? 0 : promotion.assignAdvisorSessionsAfterSignup),
            "partnerPrepaidTrainingSessions": promotion === undefined ? 0 : (promotion.assignTrainingSessionsAfterSignup === undefined ? 0 : promotion.assignTrainingSessionsAfterSignup),
            'isLoading': false
        });
    };

    home = () => window.open("https://"+env.URL.DOMAIN,"_blank");
    partnerHome = () => window.open(this.state.partnerUrl, "_blank");

    login = () => {this.setState({"page": "login"})}
    signup = () => {this.setState({"page": "signup"})}
    success = async (register) => {
        // if(this.state.partnerPrepaidAdvisorSessions > 0) {
        //     await this.props.tryPostAssignCoachWillowSessions(register.userId, this.state.partnerPrepaidAdvisorSessions)
        // }
        // if(this.state.partnerPrepaidTrainingSessions > 0) {
        //     await this.props.tryPostAssignCoachTrainingSessions(register.userId, this.state.partnerPrepaidTrainingSessions)
        // }
        if(this.state.redirectToVerify) {
            this.props.history.push("/advisor-verify")
        } else {
            this.setState({"page": "success"})
        }
    }

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <Container showRegOnly={this.state.showRegOnly} backgroundColor={this.state.backgroundColor}>
                    {(!this.state.showRegOnly && this.state.page !== "success") &&
                        <>
                            <TopLogo
                                partnerLogo={this.state.partnerLogo}
                                onClickPartnerLogo={this.partnerHome}
                                onClickWillowLogo={this.home}
                            />
                            <NavBar>
                                <Title>Financial Professionals</Title>
                                <FontBody16>Learn to serve tomorrow's clients & earn CE credits!</FontBody16>
                            </NavBar>
                            <NavBarOptions>
                                <NavOption active={this.state.page === "login"} onClick={this.login}>Sign in</NavOption>
                                <NavOption active={this.state.page === "signup"} onClick={this.signup}>Create account</NavOption>
                            </NavBarOptions>
                        </>
                    }
                    {this.state.page === "signup" &&
                        <AdvisorSignUp
                            firstName={this.state.firstName}
                            lastName={this.state.lastName}
                            email={this.state.email}
                            wmcEmployeeId={this.state.wmcEmployeeId}
                            isReferredByPartner={this.state.isReferredByPartner}
                            signUpErrorLogin={<LoginLink onClick={this.login}>Login</LoginLink>}
                            registerComplete={this.success}
                            partnerPrepaidAdvisorSessions={this.state.partnerPrepaidAdvisorSessions}
                            partnerPrepaidTrainingSessions={this.state.partnerPrepaidTrainingSessions}
                        />
                    }
                    {this.state.page === "login" &&
                        <SignupFlow
                            loginSystem={"page"}
                            loginForgotPasswordLink={() => {this.props.history.push("/password-reset")}}
                        />
                    }
                    {this.state.page === "success" &&
                        <Success
                            title={"CHECK YOUR EMAIL TO ACTIVATE YOUR ACCOUNT"}
                            subtitle={"We sent a verification link to your email that will remain valid for 24 hours."}
                            body={<i>If you do not receive the email within a few minutes, please check your spam folder.</i>}
                            buttonShow={false}
                        />
                    }
                </Container>
            )
        }
    }

}

const mapDispatchToProps = dispatch => ({
    // tryPostAssignCoachTrainingSessions: (userId, sessions) => dispatch(tryPostAssignCoachTrainingSessions(userId, sessions)),
    // tryPostAssignCoachWillowSessions: (userId, sessions) => dispatch(tryPostAssignCoachWillowSessions(userId, sessions))
})

export default connect(null, mapDispatchToProps)(withRouter(IFrameSignupAdvisor));