import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';

/* Components */
import {TextInput} from "../../../common/components/inputs";
import {FontTitle18} from "../../../common/components/fonts";
import {InlineBlock, Width, SubmitInputWithValidation} from "../../components/clientscreate";

/* Middleware */

class SettingsBrandingFirmInfo extends React.Component {
    state = {
        companyName: '', companyNameValid: true,
        address1: '', address1Valid: true,
        address2: '', address2Valid: true,
        city: '', cityValid: true,
        state: '', stateValid: true,
        zipCode: '', zipCodeValid: true,
        isSaving: false,
        isSaved: false,
        error: '',
        isChanged: false,
        isValid: true
    };

    componentDidMount() {
        this.setState({...this.state, ...this.props.wmcFirm.toJS()})
    }

    handleChangeTextInput = async (input, e) => {
        this.setState({[input]: e.target.value});
        await this.setState({[input+"Valid"]: (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        this.setState({'isChanged': true});
        this.checkValid();
    };

    handleChangeCompanyName = async e => {return this.handleChangeTextInput("companyName", e)};
    handleChangeAddress1 = async e => {return this.handleChangeTextInput("address1", e)};
    handleChangeAddress2 = async e => {return this.handleChangeTextInput("address2", e)};
    handleChangeCity = async e => {return this.handleChangeTextInput("city", e)};
    handleChangeState = async e => {return this.handleChangeTextInput("state", e)};
    handleChangeZip = async e => {return this.handleChangeTextInput("zipCode", e)};

    checkValid = () => {
        if(this.state.companyNameValid && this.state.address1Valid && this.state.cityValid && this.state.cityValid && this.state.stateValid && this.state.zipCodeValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleSubmit = e => {
        e.preventDefault();
        console.log("submit company info update");
    //     // if(this.state.firstValid && this.state.lastValid && this.state.phoneValid && this.state.emailValid && this.state.companyName) {
    //     if(this.state.firstValid && this.state.lastValid && this.state.phoneValid && this.state.emailValid) {
    //         this.setState({'isSaving': true, 'error': ''});
    //         Promise.resolve(this.props.tryUpdateSettingsPersonalInfo(this.state))
    //             .then(() => {
    //                 if(this.props.settingsPersonalInfo.get("error") !== '') {
    //                     this.setState(this.props.settingsPersonalInfo.toJS());
    //                 }
    //                 // } else {
    //                 //     this.props.setCompanyName(this.state.companyName);
    //                 // }
    //                 this.setState({'isSaving': false, 'isSaved': true, isChanged: false});
    //                 this.timeout = setTimeout(() => {this.setState({'isSaved': false})}, 3000);
    //             })
    //     }
    };

    render() {
        return(
            <>
                <form method={"post"} onSubmit={this.handleSubmit}>
                    {this.state.isSaved
                        ?
                        <>
                            {this.state.error === '' && <FontTitle18>Saved!</FontTitle18>}
                            {this.state.error !== '' && <FontTitle18>{this.state.error}</FontTitle18>}
                        </>
                        :
                        <>
                            <Width>
                                <FontTitle18>Firm info</FontTitle18>
                                <TextInput
                                    title={"Firm Name"}
                                    valid={this.state.companyNameValid}
                                    warning={"Required"}
                                    id={"companyName"}
                                    onChange={this.handleChangeCompanyName}
                                    placeholder={"Firm Name"}
                                    value={this.state.companyName}
                                />
                                <TextInput
                                    title={"Address"}
                                    valid={this.state.address1Valid}
                                    warning={"Required"}
                                    id={"address1"}
                                    onChange={this.handleChangeAddress1}
                                    placeholder={"Address"}
                                    value={this.state.address1}
                                />
                                <TextInput
                                    title={"Address line 2"}
                                    valid={this.state.address2Valid}
                                    warning={""}
                                    id={"address2"}
                                    onChange={this.handleChangeAddress2}
                                    placeholder={"Address line 2"}
                                    value={this.state.address2}
                                />
                                <TextInput
                                    title={"City"}
                                    valid={this.state.cityValid}
                                    warning={"Required"}
                                    id={"city"}
                                    onChange={this.handleChangeCity}
                                    placeholder={"City"}
                                    value={this.state.city}
                                />
                                <TextInput
                                    title={"State"}
                                    valid={this.state.stateValid}
                                    warning={"Required"}
                                    id={"state"}
                                    onChange={this.handleChangeState}
                                    placeholder={"State"}
                                    value={this.state.state}
                                />
                                <TextInput
                                    title={"Zip Code"}
                                    valid={this.state.zipCodeValid}
                                    warning={"Required"}
                                    id={"zipCode"}
                                    onChange={this.handleChangeZip}
                                    placeholder={"Zip Code"}
                                    value={this.state.zipCode}
                                />
                            </Width>
                            {/*<br />*/}
                            {/*<InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>*/}
                        </>
                    }
                </form>
            </>
        )
    }
}

const mapStateToProps = state => ({
    wmcFirm: state.common.wmc.get("wmcFirm")
});
const mapDispatchToProps = dispatch => ({
    // tryUpdateSettingsPersonalInfo: (data) => dispatch(tryUpdateSettingsPersonalInfo(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SettingsBrandingFirmInfo);
