import React from 'react';
import {withRouter} from 'react-router-dom';
import {fromJS, List} from 'immutable';
import {connect} from "react-redux";
import moment from "moment";

/* Components */
import {Page, Menu, Inline, Count, InlineBtn, InlineLink, PoweredBy, Gray, PoweredByLogo} from '../components/structure';
import {FontHeader16, FontBody16} from '../../../common/components/fonts';
import {images} from '../../../common/components/images';

/* Containers */
import IframeStoreHeader from "./header";
import IframeStoreVideoCall from "./videocall";
import IframeStoreEmailer from "./emailer";
import IframeTestimonials from "./testimonials";
import IframeAbout from "../../profile/containers/about";
import IframeFAQs from "./faqs";
import IframePortfolio from "./portfolio";
import ListingsFlow from "../../listings/containers/flow";
import WebinarFlow from "../../webinars/containers/flow";

/* Middleware */
import {tryGetListingsAll} from "../../listings/middleware/listings";
import {tryGetWebinarsAll} from "../../webinars/middleware/webinars";

class IframeStoreStructure extends React.Component {
    state={"showScheduler": false, "showEmailer": false, "listingsAll": List(), "webinarsAll": List(), "webinarsAllUpcoming": List(), "webinarsAllPast": List()};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        if((this.props.location.pathname === "/public/"+(this.props.profile.get("isWMCEmployee") ? "profile" : "shop")+"/"+this.props.profile.get("publicHandle")+"/testimonials" && this.props.profile.get("isWMCEmployee")) ||
            (this.props.location.pathname === "/public/"+(this.props.profile.get("isWMCEmployee") ? "profile" : "shop")+"/"+this.props.profile.get("publicHandle")+"/products" && this.props.profile.get("isWMCEmployee"))
        ) {
            await this.changePage("about")
        }
        await this.props.tryGetListingsAll(this.props.profile.get("id"));
        await this.props.tryGetWebinarsAll(this.props.profile.get("id"));
        let webinarsAllModified = await Promise.all(this.props.webinarsAll.map(l => {
            if (moment().isBefore(moment(l.get("start")))) {
                return Promise.resolve(fromJS({...l.toJS(), "status": "Upcoming"}))
            } else if (moment().isAfter(moment(l.get("start"))) && moment().isBefore(moment(l.get("end")))) {
                return Promise.resolve(fromJS({...l.toJS(), "status": "Ongoing"}))
            } else if (moment().isAfter(moment(l.get("end")))) {
                return Promise.resolve(fromJS({...l.toJS(), "status": "Past"}))
            }
        }));
        console.log(webinarsAllModified);
        await this.setState({
            "listingsAll": this.props.listingsAll,
            "webinarsAll": this.props.webinarsAll,
            "webinarsAllUpcoming": await fromJS(webinarsAllModified).filter(w => {return((w.get("status") !== "Past" && !w.get("isOnlyForClients")))}),
            "webinarsAllPast": await fromJS(webinarsAllModified).filter(w => {return((w.get("status") === "Past" && !w.get("isOnlyForClients")))}),
        });
        console.log(this.state)
        if(this.state.listingsAll.size === 0) {
            if(this.props.profile.get("portfolio").size === 0) {
                return this.changePage("about")
            } else {
                return this.changePage("portfolio")
            }
        }
    };

    changePage = async (page) => {
        this.props.history.push("/public/"+(this.props.profile.get("isWMCEmployee") ? "profile" : "shop")+"/"+this.props.profile.get("publicHandle")+"/"+page);
    };

    changeShowScheduler = async (status) => {
        this.setState({"showScheduler": status});
    };

    changeShowEmailer = async (status) => {
        this.setState({"showEmailer": status});
    };

    render() {
        let pathname = this.props.location.pathname;
        let pathnameAdj = pathname.charAt(pathname.length-1) === "/" ? pathname.slice(0, -1) : pathname;
        let pathnameHome = "/public/"+(this.props.profile.get("isWMCEmployee") ? "profile" : "shop")+"/"+this.props.profile.get("publicHandle");
        let pathnameProducts = "/public/"+(this.props.profile.get("isWMCEmployee") ? "profile" : "shop")+"/"+this.props.profile.get("publicHandle")+"/products";
        let pathnameWebinars = "/public/"+(this.props.profile.get("isWMCEmployee") ? "profile" : "shop")+"/"+this.props.profile.get("publicHandle")+"/webinars";
        let pathnameTestimonials = "/public/"+(this.props.profile.get("isWMCEmployee") ? "profile" : "shop")+"/"+this.props.profile.get("publicHandle")+"/testimonials";
        let pathnameAbout = "/public/"+(this.props.profile.get("isWMCEmployee") ? "profile" : "shop")+"/"+this.props.profile.get("publicHandle")+"/about";
        let pathnamePortfolio = "/public/"+(this.props.profile.get("isWMCEmployee") ? "profile" : "shop")+"/"+this.props.profile.get("publicHandle")+"/portfolio";
        let pathnameFAQ = "/public/"+(this.props.profile.get("isWMCEmployee") ? "profile" : "shop")+"/"+this.props.profile.get("publicHandle")+"/faqs";
        let productsCount = this.state.listingsAll.filter(f => {return(f.get("status").toLowerCase() === "active")}).size;
        return(
            <Page>
                <IframeStoreHeader
                    changeShowScheduler={this.changeShowScheduler}
                    changeShowEmailer={this.changeShowEmailer}
                    profile={this.props.profile}
                />
                <Menu>
                    {this.props.profile.get("isWMCEmployee") &&
                        <InlineBtn onClick={() => {return this.changePage("about")}}>
                            <InlineLink isActive={(pathnameAdj === pathnameAbout)}>
                                <FontHeader16>About&nbsp;</FontHeader16>
                            </InlineLink>
                        </InlineBtn>
                    }
                    {(productsCount > 0 && !this.props.profile.get("isWMCEmployee")) &&
                        <InlineBtn onClick={() => {return this.changePage("products")}}>
                            <InlineLink isActive={(pathnameAdj === pathnameHome || pathnameAdj === pathnameProducts)}>
                                <FontHeader16>Products&nbsp;</FontHeader16>
                            </InlineLink>
                            <Inline><FontBody16><Count>{productsCount}</Count></FontBody16></Inline>
                        </InlineBtn>
                    }
                    {this.state.webinarsAll.size > 0 &&
                        <InlineBtn onClick={() => {return this.changePage("webinars")}}>
                            <InlineLink isActive={(pathnameAdj === pathnameWebinars)}>
                                <FontHeader16>Webinars&nbsp;</FontHeader16>
                            </InlineLink>
                            <Inline><FontBody16><Count>{this.state.webinarsAllUpcoming.size}</Count></FontBody16></Inline>
                        </InlineBtn>
                    }
                    {(this.props.profile.get("portfolio").size > 0) &&
                        <InlineBtn onClick={() => {return this.changePage("portfolio")}}>
                            <InlineLink isActive={(pathnameAdj === pathnamePortfolio)}>
                                <FontHeader16>Portfolio&nbsp;</FontHeader16>
                            </InlineLink>
                            <Inline><FontBody16><Count>{this.props.profile.get("portfolio").size}</Count></FontBody16></Inline>
                        </InlineBtn>
                    }
                    {(this.props.profile.get("testimonials").size > 0 && !this.props.profile.get("isWMCEmployee")) &&
                        <InlineBtn onClick={() => {return this.changePage("testimonials")}}>
                            <InlineLink isActive={(pathnameAdj === pathnameTestimonials)}>
                                <FontHeader16>Testimonials&nbsp;</FontHeader16>
                            </InlineLink>
                            <Inline><FontBody16><Count>{this.props.profile.get("testimonials").size}</Count></FontBody16></Inline>
                        </InlineBtn>
                    }
                    {(this.props.profile.get("faqs").size > 0) &&
                        <InlineBtn onClick={() => {return this.changePage("faqs")}}>
                            <InlineLink isActive={(pathnameAdj === pathnameFAQ)}>
                                <FontHeader16>FAQs&nbsp;</FontHeader16>
                            </InlineLink>
                            <Inline><FontBody16><Count>{this.props.profile.get("faqs").size}</Count></FontBody16></Inline>
                        </InlineBtn>
                    }
                    {!this.props.profile.get("isWMCEmployee") &&
                        <InlineBtn onClick={() => {return this.changePage("about")}}>
                            <InlineLink isActive={(pathnameAdj === pathnameAbout)}>
                                <FontHeader16>About&nbsp;</FontHeader16>
                            </InlineLink>
                        </InlineBtn>
                    }
                </Menu>
                {(pathnameAdj === pathnameHome || pathnameAdj === pathnameProducts) &&
                    <ListingsFlow
                        userId={this.props.profile.get("id")}
                        title={""}
                        description={""}
                        listingId={null}
                        isPreview={false}
                        forceImagesVertical={false}
                    />
                }
                {(pathnameAdj === pathnameWebinars) &&
                    <WebinarFlow
                        userId={this.props.profile.get("id")}
                        title={null}
                        description={null}
                        webinarId={null}
                        profile={this.props.profile}
                        webinarsAllShowHeader={false}
                    />
                }
                {(pathnameAdj === pathnamePortfolio) &&
                    <IframePortfolio />
                }
                {(pathnameAdj === pathnameTestimonials) &&
                    <IframeTestimonials />
                }
                {(pathnameAdj === pathnameFAQ) &&
                    <IframeFAQs />
                }
                {(pathnameAdj === pathnameAbout) &&
                    <IframeAbout profile={this.props.profile} />
                }
                <PoweredBy target={"_blank"} href={"https://www.trustwillow.com/"}>
                    <FontBody16><Gray>Powered by</Gray>&nbsp;<PoweredByLogo src={images.logo} alt={""} /></FontBody16>
                </PoweredBy>
                {this.state.showScheduler &&
                    <IframeStoreVideoCall changeShowScheduler={this.changeShowScheduler} profile={this.props.profile} />
                }
                {this.state.showEmailer &&
                    <IframeStoreEmailer changeShowEmailer={this.changeShowEmailer} profile={this.props.profile} />
                }
            </Page>
        )
    }
}

const mapStateToProps = state => ({
    webinarsAll: state.iframe.webinars.webinars.get("webinarsAll"),
    listingsAll: state.iframe.listings.listings.get("listingsAll"),
});

const mapDispatchToProps = dispatch => ({
    tryGetListingsAll: (id) => dispatch(tryGetListingsAll(id)),
    tryGetWebinarsAll: (id) => dispatch(tryGetWebinarsAll(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IframeStoreStructure));