import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import styled from "styled-components";

/* Middleware */
import { tryGetAllContacts, tryArchiveContact } from '../middleware/contacts';
import { tryArchiveClient } from "../clients/middleware/clients";
import { tryArchiveProspect } from '../middleware/prospects';

/* Store */
import { setClientsDetailSelected } from "../store/clientsdetail";

/* Components */
import { Message } from "../../common/components/messages";
import { TableWrapperV2 } from "../../common/components/tablev2";
import { LogoLoading } from '../../common/components/loading';
import {colors} from "../../common/components/colors";
import {EditOutlined, MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";

const Margin = styled.div`
    margin-bottom: 50px;
`;

class ContactsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {"isLoading": true, "data": []};
    }

    componentDidMount() {
        if(!this.props.contactsAllFetched) {
            return this.refresh();
        } else {
            return this.update();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.location.pathname !== this.props.location.pathname || prevProps.contactsAll !== this.props.contactsAll) {
            return this.didUpdate();
        }
    }

    didUpdate = async () => {
        this.setState({
            "data": await this.createData(this.filter(
                this.props.contactsAll,
                this.props.location.pathname === '/contacts/previous'
            ))
        })
    };

    refresh = async () => {
        await this.props.tryGetAllContacts();
        return this.update();
    };

    update = async () => {
        this.setState({
            "data": await this.createData(this.filter(this.props.contactsAll, false)),
            "isLoading": false
        })
    };

    filter = (contacts, isArchived) => {
        return contacts.reduce((filtered, c) => {
            if(c.get("businessUserId") !== this.props.userId && c.get("isArchived") === isArchived) {
                let hasCoach = c.get("coachAssignments") === undefined ? false : (c.get("coachAssignments").size > 0);
                let isAdvisorHasCoach = hasCoach;
                let isAdvisorNoCoach = !hasCoach;
                let isCoachHasAdvisor = false;
                let isCoachNoAdvisor = false;
                filtered.push({
                    ...c.toJS(),
                    "name": ((c.get("referralSource") === "willow" && !this.props.isWMC) || (c.get("referralSource") === "wmc" && !this.props.isWMC)) ? c.get("firstName") + " " + c.get("lastName").charAt(0) + "." : c.get("firstName") + " " + c.get("lastName"),
                    "email": (c.get("referralSource") === "willow" || (c.get("referralSource") === "wmc" && !this.props.isWMC)) ? "---" : c.get("email"),
                    "phone": (c.get("referralSource") === "willow" || (c.get("referralSource") === "wmc" && !this.props.isWMC)) ? "---" : c.get("phone"),
                    "archiveAllowed": !(c.get("referralSource") === "willow" || c.get("businessUserId") !== null),
                    "editAllowed": !(c.get("referralSource") === "willow" || c.get("businessUserId") !== null),
                    "linkAllowed": !((c.get("referralSource") === "willow" && c.get("prospectStatus") === "Contact") || c.get("businessUserId") !== null),
                    "source": c.get("referralSource") === "willow" ? "Willow" : this.props.companyName,
                    "prospectStatus": c.get("businessUserId") !== null ? "Team Member" : c.get("prospectStatus") === null ? "Contact" : c.get("prospectStatus"),
                    "isCoachHasAdvisor": isCoachHasAdvisor,
                    "isCoachNoAdvisor": isCoachNoAdvisor,
                    "isAdvisorHasCoach": isAdvisorHasCoach,
                    "isAdvisorNoCoach": isAdvisorNoCoach
                })
            }
            return filtered;
        }, []);
    };

    edit = async (data) => {
        await this.selectClient(data);
        this.props.history.push("/contact/edit");
    };

    view = async (data) => {
        await this.selectClient(data);
        this.props.history.push(/*data.isCoachNoAdvisor ? "/contact/notes" : */"/contact/instructions");
    };

    selectClient = async (data) => {
        this.props.setClientsDetailSelected({
            "contactId": data.contactId,
            "prospectId": data.prospectId,
            "consumerId": data.consumerId,
            "businessUserId": data.businessUserId,
            "firstName": data.firstName,
            "lastName": data.lastName,
            "prospectStatus": data.prospectStatus,
            "revenue": data.revenue,
            "probabilityOfClosing": data.probabilityOfClosing,
            "companyName": data.companyName,
            "isCoachHasAdvisor": data.isCoachHasAdvisor,
            "isCoachNoAdvisor": data.isCoachNoAdvisor,
            "isAdvisorHasCoach": data.isAdvisorHasCoach,
            "isAdvisorNoCoach": data.isAdvisorNoCoach,
            "referralSource": data.referralSource
        });
    };

    archive = (data) => {
        if(data.consumerId !== null) {
            if(data.prospectStatus === "Prospect") {
                this.props.tryArchiveProspect({...data, "id": data.consumerId});
            } else if(data.prospectStatus === "Client") {
                this.props.tryArchiveClient({...data, "id": data.consumerId});
            }
        }
        if(data.contactId !== null) {
            this.props.tryArchiveContact({...data, "id": data.contactId});
        }
    };

    createColumns = {
        "0": {"key": "id", "title": "Id", "titleShowOnDesktop": false, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "hidden", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": null, "onDoubleClick": null},
        "1": {"key": "name", "title": "Name", "titleShowOnDesktop": true, "canSort": true, "sort": "asc", "sortType": "string", "sortKey": "name", "component": "linkIfDataAllows", "thStyles": {}, "tdStyles": {"color": colors.action100}, "ifNull": "", "onClick": this.view, "onDoubleClick": null},
        "2": {"key": "email", "title": "Email", "titleShowOnDesktop": true, "canSort": true, "sort": null, "sortType": "string", "sortKey": "email", "component": "string", "thStyles": {}, "tdStyles": {}, "ifNull": '---', "onClick": null, "onDoubleClick": null},
        "3": {"key": "phone", "title": "Phone", "titleShowOnDesktop": true, "canSort": true, "sort": null, "sortType": "string", "sortKey": "phone", "component": "phone", "thStyles": {}, "tdStyles": {}, "ifNull": '---', "onClick": null, "onDoubleClick": null},
        "4": {"key": "prospectStatus", "title": "Relationship", "titleShowOnDesktop": true, "canSort": true, "sort": null, "sortType": "string", "sortKey": "prospectStatus", "component": "string", "thStyles": {}, "tdStyles": {}, "ifNull": '---', "onClick": null, "onDoubleClick": null},
        // "5": {"key": "source", "title": "Source", "titleShowOnDesktop": true, "canSort": true, "sort": null, "sortType": "string", "sortKey": "source", "component": "string", "thStyles": {}, "tdStyles": {}, "ifNull": '---', "onClick": null, "onDoubleClick": null},
        "5": {"key": "actions", "title": "Actions", "titleShowOnDesktop": false, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "menu", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": {"1": this.archive, "2": this.edit}, "onDoubleClick": null}
    };

    createData = async (data) => {
        return Promise.all(data.map(d => {
            return Promise.resolve({
                "id": d.contactId,
                "name": d.name,
                "email": d.email,
                "phone": d.phone,
                "prospectStatus": d.prospectStatus,
                // "source": d.source,
                "actions": {
                    "1": {"label": d.isArchived ? <><PlusCircleOutlined />&nbsp;Not Lost</> : <><MinusCircleOutlined />&nbsp;Lost</>, "active": d.archiveAllowed, "show": true},
                    // "2": {"label": <><EditOutlined />&nbsp;Edit Contact</>, "active": d.editAllowed, "show": true}
                },
                "allData": d
            })
        }));
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            if (this.state.data.size === 0) {
                return (<Message text={this.props.location.pathname === '/contacts' ? "No current contacts" : "No previous contacts"} />)
            } else {
                return (
                    <>
                        <TableWrapperV2
                            responsive={true}
                            tableColumns={this.createColumns}
                            tableData={this.state.data}
                            initSelected={null}
                        />
                        <Margin />
                    </>
                )
            }
        }
    }
}

const mapStateToProps = state => ({
    contactsAllFetched: state.enterprise.contacts.get("contactsAllFetched"),
    contactsAll: state.enterprise.contacts.get("contactsAll"),
    userId: state.common.user.get("userId"),
    companyName: state.common.wmc.get("wmc").get("companyName"),
    isWMC: state.common.wmc.get("isWMC"),
});

const mapDispatchToProps = dispatch => ({
    tryGetAllContacts: () => dispatch(tryGetAllContacts()),
    tryArchiveContact: (data) => dispatch(tryArchiveContact(data)),
    tryArchiveClient: (data) => dispatch(tryArchiveClient(data)),
    tryArchiveProspect: (data) => dispatch(tryArchiveProspect(data)),
    setClientsDetailSelected: (data) => dispatch(setClientsDetailSelected(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactsList));