import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import ContentEditable from 'react-contenteditable'

import {Container, LoadingCenter, ContentTitle, CourseSummaryContainer, Image, Text, PageTitle} from "../components/details";
import {FontTitle20, FontBody16} from '../../../common/components/fonts';
import {LogoLoading} from "../../../common/components/loading";
import { ButtonPrimary } from '../../../common/components/buttons';
import {CommonBackground, CommonModal} from '../../../common/components/modal';
import { images } from '../../../common/components/images';

import {setCourseCreate} from '../store/courses';
import UploadPhoto from "./uploadphoto";
import { UploadOutlined } from '@ant-design/icons';
import CourseSummaryModule from '../../../iframes/courses/containers/coursesummarymodules';
import CreateCourseTaskDisplay from './createcoursetaskdisplay';

class CourseDisplay extends React.Component {
    state={
        title: "",
        imgURL: "",
        description: "",
        form: [],
        isLoading: true,
        updatePaused: false,
        imageUpload: false,
        summaryModuleOpen: null
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "title": this.props.course.get("title"),
            "imgURL": this.props.course.get("imageUrl"),
            "description": this.props.course.get("description"),
            "form": this.props.task.get("pages").toJS(), 
            "isLoading": false})
    };

    componentDidUpdate(prevProps) {
        if (prevProps.task !== this.props.task && !this.state.updatePaused) {
            this.setState({"isLoading": true})
            return this.init()
        }
    }

    handleChangeTitle = async (evt) => {
        this.setState({"title": evt.target.value});
    };

    handleChangeDescription = async (evt) => {
        let str = evt.target.value;
        str = str.replace(/(<([^>]+)>)/ig, '');
        this.setState({"description": str});
    };

    setCourseData = async () => {
        const course = {
            "title": this.state.title,
            "description": this.state.description,
            "imageUrl": this.state.imgURL,
            "pages": this.props.task.get("pages").toJS(),
            "isActive": false,
            "isReadingMaterial": false,
            "isVideo": false,
            "categoryIds": this.props.course.get("categoryIds").toJS()
        }
        await this.props.setCourseCreate(course)
    }

    summaryModuleOpenStatus = (id) => {
        this.setState({"summaryModuleOpen": this.state.summaryModuleOpen === id ? null : id})
    };

    render() {
        if (this.state.isLoading) {
            return (<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else if (this.props.task.get("id") === null) {
            return (
                <Container>
                    <br />
                    <div style={{position: 'relative', margin: '0px 10px'}}>
                        <PageTitle>COURSE</PageTitle>
                        <CourseSummaryContainer>
                            <div style={{position: 'relative'}}>
                                <Image src={(this.state.imgURL !== null && this.state.imgURL !== "") ? this.state.imgURL : images.grayBackgroundImage} alt={""}/>
                                <div onClick={() => {return this.setState({"imageUpload": true})}} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', cursor: 'pointer', zIndex: 10}}><ButtonPrimary canSubmit={false} label={<>Cover <UploadOutlined /></>} /></div>
                            </div>
                            <FontTitle20>
                                <ContentTitle>
                                    <ContentEditable 
                                        className="content-editable" 
                                        html={this.state.title} 
                                        onChange={this.handleChangeTitle} 
                                        onBlur={() => {return this.setCourseData()}}
                                        placeholder={"* Click and add the title of the course here"}
                                    />
                                </ContentTitle>
                            </FontTitle20>
                            <Text active={true}><FontBody16>
                                <ContentTitle>
                                    <ContentEditable 
                                        className="content-editable" 
                                        html={this.state.description} 
                                        onChange={this.handleChangeDescription} 
                                        onBlur={() => {return this.setCourseData()}}
                                        placeholder={"* Here is the introduction and description of the course. Edit this text by clicking directly and begin typing"}
                                    />
                                </ContentTitle>
                            </FontBody16></Text>
                        </CourseSummaryContainer>
                        {this.props.tasks.map((a, index) => {
                            return(
                                <CourseSummaryModule
                                    key={index}
                                    index={index}
                                    task={{"task": a.toJS()}}
                                    summaryModuleOpen={this.state.summaryModuleOpen}
                                    summaryModuleOpenStatus={this.summaryModuleOpenStatus}
                                    numTasksTotal={this.props.tasks.size}
                                    preview={true}
                                />
                            )
                        })}
                        <br />
                        <br />
                        <br />
                    </div>       
                    {this.state.imageUpload &&
                        <>
                            <CommonBackground onClick={this.props.close}/>
                            <CommonModal style={{width: "fit-content"}}>
                                <UploadPhoto onClose={(img) => {this.setState({"imageUpload": false, "imgURL": img}); return this.setCourseData()}} url={this.state.imgURL}/>
                            </CommonModal>
                        </>
                    }
                </Container>
            )
        } else {
            return (
                <CreateCourseTaskDisplay />
            )
        }
    }
}

const mapStateToProps = state => ({
    task: state.enterprise.curriculum.curriculum.get("taskCreate"),
    tasks: state.enterprise.curriculum.curriculum.get("tasks"),
    course: state.enterprise.curriculum.curriculum.get("courseCreate"),
});

const mapDispatchToProps = dispatch => ({
    setCourseCreate: (course) => dispatch(setCourseCreate(course)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CourseDisplay))