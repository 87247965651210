import React, { useRef } from "react";
import styled from "styled-components";
import {colors} from "./colors";
import {FontHeader18, FontBody12, FontBody16} from "./fonts";
import {CaretDownOutlined} from "@ant-design/icons";
import Dropdown from "react-dropdown";
import Cleave from "cleave.js/dist/cleave-react-node";
import Switch from 'react-switch';
import ReactQuill from 'react-quill';
import {editorFormats, editorModules, Quill, QuillWhite} from "./richtexteditor";
import {Link} from 'react-router-dom';
import AvatarEditor from 'react-avatar-editor'
// import DatePicker from 'react-date-picker';
// import TimePicker from 'react-time-picker';
import TimeKeeper from 'react-timekeeper';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import TextareaAutosize from 'react-textarea-autosize';
import {fileTypeImage} from "./filetypes";

import {UserOutlined, IdcardOutlined, TeamOutlined} from '@ant-design/icons';

/* Domain */
import getDomainEnv from '../../domains/utils';
const env = getDomainEnv();

const Icon = styled.div`
    display: inline-block;
    span {
        font-size: 16px;
        padding: 8px 0px;
        color: ${colors.primary100};
        display: inline-block;
        vertical-align: top;
        margin-right: 5px;
    }
`;
const Label = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 4px 0px;
    color: ${props => props.color};
    text-align:left
`;
const Submit = styled.button`
    display: inline-block;
    cursor: pointer;
    background-color: ${props => props.backgroundColor};
    border-radius: 4px;
    border: none;
    padding: 0px 24px;
    margin: 0px;
    vertical-align: top;
    color: ${colors.primary100};
    &:active, &:focus {
        outline: none;
        border: none;
    }
`;

export const SubmitButton = ({ iconComponent: IconComponent, color, label, link, backgroundColor }) => (
    <Submit backgroundColor={backgroundColor}>
        {IconComponent !== null &&
            <Icon><IconComponent /></Icon>
        }
        <Label color={color}>
            <FontHeader18>
                {link !== undefined ? <Link style={{"color": color}} to={link}>{label}</Link> : label}
            </FontHeader18>
        </Label>
    </Submit>
);

const SubmitMyMoney = styled.button`
    display: inline-block;
    cursor: pointer;
    background-color: ${props => props.backgroundColor};
    border-radius: 4px;
    border: none;
    padding: 0px 24px;
    margin: 0px;
    vertical-align: top;
    color: ${colors.primary100};
    white-space: nowrap;
    transition: box-shadow .3s ease-in-out, background-color .3s ease-in-out;
    &:active, &:focus {
        outline: none;
        border: none;
    }
    &:hover:not(:disabled) {
        box-shadow: inset 0 0px 10px 2px rgba(220, 220, 220, .4);
    }
    &:active:not(:disabled) {
        box-shadow: inset 0 0px 10px 3px rgba(220, 220, 220, .8);
    }
    &:disabled {
        cursor: not-allowed;
        background-color: ${colors.primary30};
    }
    div {
        vertical-align: middle;
    }
`;
export const SubmitButtonMyMoney = ({ iconComponent: IconComponent, color, label, link, backgroundColor, onClick=(()=>{}), disabled=false }) => (
    <SubmitMyMoney backgroundColor={backgroundColor} onClick={onClick} disabled={disabled}>
        {IconComponent !== null &&
        <Icon><IconComponent style={{ color: color, fontSize: 20, margin: '-2px 5px -2px 0' }} /></Icon>
        }
        <Label color={color}>
            <FontHeader18>
                {link !== undefined ? <Link style={{"color": color}} to={link}>{label}</Link> : label}
            </FontHeader18>
        </Label>
    </SubmitMyMoney>
);


const EmptyIconButton = styled.button`
    display: inline-block;
    margin: 0;
    padding: 4px;
    cursor: pointer;
    background-color: ${props => props.backgroundColor};
    border: ${props => props.borderColor ? ('1px solid ' + props.borderColor) : 'none'};
    border-radius: 50%;
    font-size: ${props => props.size}px;
    line-height: ${props => props.size}px;
    vertical-align: middle;
    color: ${props => props.color};
    white-space: nowrap;
    transition: box-shadow .3s ease-in-out, background-color .3s ease-in-out;
    &:active, &:focus {
        outline: none;
        border: none;
    }
    &:hover:not(:disabled) {
        box-shadow: inset 0 0px 10px 2px rgba(220, 220, 220, .4);
    }
    &:active:not(:disabled) {
        box-shadow: inset 0 0px 10px 3px rgba(220, 220, 220, .8);
    }
    &:disabled {
        cursor: not-allowed;
        background-color: ${colors.primary30};
    }
`;
export const IconButton = ({ iconComponent: IconComponent, color, label, backgroundColor, borderColor, size=20, style, onClick=(()=>{}) }) => (
    <EmptyIconButton backgroundColor={backgroundColor} borderColor={borderColor} color={color} title={label} style={style} size={size} onClick={onClick}>
        <IconComponent style={{ verticalAlign: 'top' }} />
    </EmptyIconButton>
);



const SubmitButtonSpacer = styled.div`
    margin-top: 30px;
    text-align: right;
`;

const SubmitButtonSpacer2 = styled.div`
    text-align: center;
`;

export const SubmitInput = ({ iconComponent: IconComponent, backgroundColor, color, label, disabled }) => (
    <SubmitButtonSpacer>
        <Submit backgroundColor={backgroundColor} disabled={disabled} >
            {IconComponent !== null &&
                <Icon><IconComponent /></Icon>
            }
            <Label color={color}><FontHeader18>{label}</FontHeader18></Label>
        </Submit>
    </SubmitButtonSpacer>
);

export const SubmitInputCentered = ({ iconComponent: IconComponent, backgroundColor, color, label, disabled }) => (
    <SubmitButtonSpacer2>
        <Submit backgroundColor={backgroundColor} disabled={disabled} >
            {IconComponent !== null &&
                <Icon><IconComponent /></Icon>
            }
            <Label color={color}><FontHeader18>{label}</FontHeader18></Label>
        </Submit>
    </SubmitButtonSpacer2>
);


export const SubmitInputWithValidation = ({ isChanged, isValid, isSaving }) => {
    if(isChanged && isValid) {
        if(isSaving) {
            return (<SubmitInput iconComponent={null} backgroundColor={colors.primary30} disabled={true}
                                 color={colors.secondary100} label={"Saving"}/>)
        } else {
            return (<SubmitInput iconComponent={null} backgroundColor={colors.action100} disabled={false}
                                 color={colors.primary100} label={"Update"}/>)
        }
    } else {
        return(<SubmitInput iconComponent={null} backgroundColor={colors.border70} disabled={true}
                     color={colors.primary30} label={"Update"}/>)
    }
};




const PageButton = styled.button`
    display: inline-block;
    cursor: pointer;
    background-color: ${props => props.backgroundColor};
    border-radius: 4px;
    border: none;
    min-width: 100px;
    height: 75px;
    text-align: center;
    vertical-align: top;
    color: ${colors.primary100};
    &:active, &:focus {
        outline: none;
        border: none;
    }
    @media screen and (max-width: 748px) {
        min-width: 75px;
        height: 50px;
        padding: 0px 10px;
    }
`;

export const PageDataSelection = ({ backgroundColor, color, label, disabled }) => (
    <PageButton backgroundColor={backgroundColor} disabled={disabled} >
        <Label color={color}><FontHeader18>{label}</FontHeader18></Label>
    </PageButton>
);








export const InputSectionFilter = styled.div`
    text-align: center;
    input {
        text-align: center;
        background-color: ${colors.border70};
        border: 1px solid ${colors.primary30};
        color: ${colors.action100};
        border-radius: 4px;
        width: calc(100% - 20px);
        padding: 10px;
        font-family: ${env.STYLES.FONTS.body.fontFamily};
        font-size: 14px;
        line-height: 18px;
        font-weight: 900;
        &::placeholder {
            color: ${colors.primary30};
        }
        &:focus {
            outline: none;
        }
    }
`;
export const InputSection = styled.div`
    margin: 35px 0px 15px 0px;
    text-align: left;
    input, textarea, .ql-container {
        background-color: ${colors.border70};
        border: 1px solid ${colors.primary30};
        color: ${colors.primary100};
        border-radius: 4px;
        width: calc(100% - 40px);
        padding: 10px 20px;
        font-family: ${env.STYLES.FONTS.body.fontFamily};
        font-size: 16px;
        line-height: 22px;
        &::placeholder {
            color: ${colors.primary70};
        }
        &:focus {
            outline: none;
        }
    }
    & .ql-container {
        width: 100% !important;
    }
`;
export const InputSectionSelect = styled.div`
  text-align: left;
  select {
    background-color: ${colors.border70};
    border: 1px solid ${colors.primary30};
    color: ${colors.primary100};
    border-radius: 4px;
    width: 100%;
    padding: 10px 20px;
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    font-size: 16px;
    line-height: 22px;
    &::placeholder {
      color: ${colors.primary70};
    }
    &:focus {
      outline: none;
    }
  }
`;

export const InputSectionWidthAutoNoMargin = styled(InputSection)`
    margin: 0;
    text-align: center;
    input, textarea, .ql-container {
        width:auto;
    }
`;
export const InputSectionNoMargin = styled.div`
    margin: 0px;
    text-align: left;
    input, textarea, .ql-container {
        background-color: ${colors.border70};
        border: 1px solid ${colors.primary30};
        color: ${colors.primary100};
        border-radius: 4px;
        width: calc(100% - 40px);
        padding: 10px 20px;
        font-family: ${env.STYLES.FONTS.body.fontFamily};
        font-size: 16px;
        line-height: 22px;
        &::placeholder {
            color: ${colors.primary30};
        }
        &:focus {
            outline: none;
        }
    }
    & .ql-container {
        width: 100% !important;
    }
`;
export const InputLabel = styled.div`
    color: ${colors.secondary100};
    display: inline-block;
`;
export const Invalid = styled.div`
    ${props => props.valid !== true} {display: none;}
    ${props => props.valid !== false} {display: inline-block;}
    color: ${colors.alert100};
    margin-left: 10px;
`;
export const LinkStyled = styled.a`
    text-decoration: underline;
    color: ${colors.primary100};
`;

export const TextInput = ({title, valid, warning, id, onChange, placeholder, value, disabled, error, containerStyles={}, inputStyles={}}) => (
    <InputSection style={containerStyles}>
        <>
            {(title !== undefined && title !== null  && title !== "") && <InputLabel><FontBody16>{title}</FontBody16></InputLabel>}
            {(warning !== undefined && warning !== null  && warning !== "") && <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>}
            {(error !== undefined && error !== null  && error !== "") &&
                <>
                    {(error === "User with this email already exists." || error === "User already exists.")
                        ? <Invalid valid={false}><FontBody12>{error} <LinkStyled target={"_blank"} href={"https://"+env.URL.AUTH+"/login"}>Log in</LinkStyled>.</FontBody12></Invalid>
                        : <Invalid valid={false}><FontBody12>{error}</FontBody12></Invalid>
                    }
                </>
            }
        </>
        <input id={id} onChange={onChange} placeholder={placeholder} value={value} disabled={disabled} style={inputStyles} />
    </InputSection>
);

export const TextNoInput = ({ title, valid, warning, id, onChange, placeholder, value, disabled, error, containerStyles = {}, inputStyles = {} }) => (
    <InputSection style={containerStyles}>
        <>
            {(title !== undefined && title !== null && title !== "") && <InputLabel><FontBody16>{title}</FontBody16></InputLabel>}
            {(warning !== undefined && warning !== null && warning !== "") && <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>}
            {(error !== undefined && error !== null && error !== "") &&
                <>
                    {(error === "User with this email already exists." || error === "User already exists.")
                        ? <Invalid valid={false}><FontBody12>{error} <LinkStyled target={"_blank"} href={"https://" + env.URL.AUTH + "/login"}>Log in</LinkStyled>.</FontBody12></Invalid>
                        : <Invalid valid={false}><FontBody12>{error}</FontBody12></Invalid>
                    }
                </>
            }
        </>
    </InputSection>
);


const WhiteInputField = styled.input`
    background-color: ${colors.white} !important;
`;

export const TextInputWhite = ({title, valid, warning, id, onChange, placeholder, value, disabled}) => (
    <InputSection>
        <>
            {title !== undefined && <InputLabel><FontBody16>{title}</FontBody16></InputLabel>}
            {warning !== undefined && <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>}
        </>
        <WhiteInputField id={id} onChange={onChange} placeholder={placeholder} value={value} disabled={disabled} />
    </InputSection>
);

export const TextInputWhiteNoMargins = ({title, valid, warning, id, onChange, placeholder, value, disabled}) => (
    <InputSectionNoMargin>
        <>
            {title !== undefined && <InputLabel><FontBody16>{title}</FontBody16></InputLabel>}
            {warning !== undefined && <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>}
        </>
        <WhiteInputField id={id} onChange={onChange} placeholder={placeholder} value={value} disabled={disabled} />
    </InputSectionNoMargin>
);

const WhiteCleaveField = styled.div`
    & input {
        background-color: ${colors.white} !important;
    }
`;

export const MoneyInputWhite = ({title, valid, warning, id, onChange, placeholder, value, disabled}) => (
    <InputSection>
        <>
            {title !== undefined && <InputLabel><FontBody16>{title}</FontBody16></InputLabel>}
            {warning !== undefined && <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>}
        </>
        <WhiteCleaveField>
            <Cleave id={id} options={{"prefix": "$", "numeralPositveOnly": true, "numeral": true, "numeralThousandsGroupStyle": 'thousand'}} onChange={onChange} placeholder={placeholder} value={value} />
        </WhiteCleaveField>
    </InputSection>
);

export const MoneyInputWhiteV2 = ({title, valid, warning, id, onChange, placeholder, value, disabled, size}) => (
    <InputSectionWidthAutoNoMargin >
        <>
            {title !== undefined && <InputLabel><FontBody16>{title}</FontBody16></InputLabel>}
            {warning !== undefined && <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>}
        </>
        <WhiteCleaveField>
            <Cleave id={id} options={{"prefix": "$", "numeralPositveOnly": true, "numeral": true, "numeralThousandsGroupStyle": 'thousand'}} onChange={onChange} placeholder={placeholder} value={value} size={size} />
        </WhiteCleaveField>
    </InputSectionWidthAutoNoMargin>
);

export const MoneyInputGray = ({title, valid, warning, id, onChange, placeholder, value, disabled}) => (
    <InputSection>
        <>
            {title !== undefined && <InputLabel><FontBody16>{title}</FontBody16></InputLabel>}
            {warning !== undefined && <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>}
        </>
        <Cleave id={id} options={{"prefix": "$", "numeralPositveOnly": true, "numeral": true, "numeralThousandsGroupStyle": 'thousand'}} onChange={onChange} placeholder={placeholder} value={value} />
    </InputSection>
);

export const PercentInputFilter = ({title, id, onChange, placeholder, value, min, max, type, suffix}) => (
    <InputSectionFilter>
        <InputNumber
            defaultValue={placeholder}
            value={value}
            min={min}
            max={max}
            step={1}
            onChange={onChange}
            id={id}
            formatter={type === "$" ? (value => `$${value}${suffix}`) : (value => `${value}%`)}
            parser={type === "$" ? (value => value.replace('$', '').replace('k', '').replace('m', '')) : (value => value.replace('%', ''))}
        />
        <InputLabel><FontBody12>{title}</FontBody12></InputLabel>
    </InputSectionFilter>
);

export const PercentInputGray = ({title, valid, warning, id, onChange, placeholder, value, disabled}) => (
    <InputSection>
        <>
            {title !== undefined && <InputLabel><FontBody16>{title}</FontBody16></InputLabel>}
            {warning !== undefined && <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>}
        </>
        <InputNumber
            defaultValue={placeholder}
            value={value}
            min={0}
            max={100}
            step={1}
            onChange={onChange}
            id={id}
            formatter={value => `${value}%`}
            parser={value => value.replace('%', '')}
        />
    </InputSection>
);

export const NumberInputWhite = ({title, valid, warning, id, onChange, placeholder, value, disabled}) => (
    <InputSection>
        <>
            {title !== undefined && <InputLabel><FontBody16>{title}</FontBody16></InputLabel>}
            {warning !== undefined && <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>}
        </>
        <WhiteInputField id={id} type={"number"} step={1} onChange={onChange} placeholder={placeholder} value={value} />
    </InputSection>
);
export const NumberInput = ({title, valid, warning, id, onChange, placeholder, value, disabled}) => (
    <InputSection>
        <>
            {title !== undefined && <InputLabel><FontBody16>{title}</FontBody16></InputLabel>}
            {warning !== undefined && <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>}
        </>
        <input id={id} type={"number"} step={1} onChange={onChange} placeholder={placeholder} value={value} />
    </InputSection>
);


export const InputSectionLean = styled(InputSection)`
    margin: 0 10px 15px 0;
    input, textarea, .ql-container {
        width: 100%;
        padding: 2px 10px;
        box-sizing: border-box;
        &::placeholder {
            color: ${colors.primary30};
        }
    }
`;
export const TextInputLean = (opts) => (
    <InputSectionLean className={opts.className}>
        <input {...opts} className={null} />
    </InputSectionLean>
);



const Left = styled.div`
    width: calc(100% - 150px);
    display: inline-block;
    @media screen and (max-width: 460px) {
        width: 100%;
    }
`;
const Count = styled.div`
    top: 5px;
    right: 10px;
    display: inline-block;
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    font-size: 12px;
    line-height: 16px;
    ${props => props.count > props.minLength} {color: ${colors.alert100};}
    ${props => props.count <= props.minLength} {color: ${colors.progress100};}
    text-align: right;
    width: 150px;
    justify-content: space-between;
    @media screen and (max-width: 460px) {
        width: 100%;
        text-align: left;
    }
`;

const CountColor = styled.div`
    color: ${props => props.color};
`;
const TextArea = styled.textarea`
    min-height: ${props => props.minRows*22}px;
    resize: none;

`;

const TextAreaV2 = styled.textarea`
    min-height: ${props => props.minRows*22}px;
    resize: none;
    width: ${props => props.cols*22}px;
    
`;

const TextAreaWhite = styled.textarea`
    min-height: ${props => props.minRows*22}px;
    resize: none;
    background-color: ${colors.white} !important;
`;
const TextAreaAutosize = styled(TextareaAutosize)`
    resize: none;
`;

export const TextareaInput = ({title, valid, warning, id, onChange, placeholder, value, minLength, count, minRows, required}) => (
    <InputSection>
        <>
            <Left>
                <InputLabel><FontBody16>{title}</FontBody16></InputLabel>
                <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>
            </Left>
            {count === 0
                ? <>{required && <Count count={count} minLength={minLength}>{count.toString()+" of "+minLength.toString()}</Count>}</>
                : <Count count={count} minLength={minLength}>{count.toString()+" of "+minLength.toString()}</Count>
            }
        </>
        <TextArea id={id} onChange={onChange} placeholder={placeholder} value={value} minLength={minLength.toString()} minRows={minRows} />
    </InputSection>
);

export const TextareaInputV2 = ({title, valid, warning, id, onChange, placeholder, value, minLength, count, minRows, cols, required, maxLength}) => (
    <InputSection className={"textAreaInputDiv"}>
        <>
            <Left>
                <InputLabel><FontBody16>{title}</FontBody16></InputLabel>
                <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>
            </Left>
            {count === 0
                ? <>{required && <Count count={count} minLength={minLength}>{count.toString()+" of "+minLength.toString()}</Count>}</>
                : <Count count={count} minLength={minLength}>{count.toString()+" of "+minLength.toString()}</Count>
            }
        </>
        <TextAreaV2 className={"textAreaInput"} id={id} onChange={onChange} placeholder={placeholder} value={value} minLength={minLength.toString()} minRows={minRows} cols={cols} maxLength={maxLength}/>
    </InputSection>
);

export const TextareaInputNoCount = ({title, id, onChange, placeholder, value, minLength, minRows, valid, warning}) => (
    <InputSection>
        {title !== null && <InputLabel><FontBody16>{title}</FontBody16></InputLabel>}
        {warning !== null && <div><Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid></div>}
        <TextArea id={id} onChange={onChange} placeholder={placeholder} value={value} minLength={minLength.toString()} minRows={minRows} />
    </InputSection>
);

export const TextareaInputNoCountWhite = ({title, id, onChange, placeholder, value, minLength, minRows, valid, warning}) => (
    <InputSection>
        {title !== null && <InputLabel><FontBody16>{title}</FontBody16></InputLabel>}
        {warning !== null && <span><Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid></span>}
            <TextAreaWhite id={id} onChange={onChange} placeholder={placeholder} value={value} minLength={minLength.toString()} minRows={minRows} />
    </InputSection>
);

export const TextareaInputNoCountWhiteSubtitle = ({ title, id, onChange, placeholder, value, minLength, minRows, valid, warning, subtitle }) => (
    <InputSection>
        {title !== null && <InputLabel><FontBody16>{title}</FontBody16></InputLabel>}
        {warning !== null && <span><Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid></span>}
        {subtitle !== null && <FontBody12 style={{ color: colors.secondary100, marginBottom: '5px' }}>{subtitle}</FontBody12>}
        <TextAreaWhite id={id} onChange={onChange} placeholder={placeholder} value={value} minLength={minLength.toString()} minRows={minRows} />
    </InputSection>
);

const PlaceholderColor = styled.div`
    & textarea::placeholder {
        color: ${colors.primary70};
    }
`;
const Primary = styled.div`
    color: ${colors.primary100};
`;

export const TextareaInputAutosize = ({subtitle, id, onChange, placeholder, value, minLength, minRows, valid, warning, style}) => (
    <InputSection>
        {subtitle !== null && <InputLabel><FontHeader18><Primary style={style.subtitle === undefined ? {} : style.subtitle}>{subtitle}</Primary></FontHeader18></InputLabel>}
        {warning !== "" && <div><Invalid valid={valid}><FontBody12 style={style.warning === undefined ? {} : style.warning}>{warning}</FontBody12></Invalid></div>}
        <PlaceholderColor>
            <TextAreaAutosize style={style.textArea === undefined ? {} : style.textArea} id={id} onChange={onChange} placeholder={placeholder} value={value} minLength={minLength.toString()} minRows={minRows} />
        </PlaceholderColor>
    </InputSection>
);


// const Quill = styled.div`
//     .ql-editor {
//         padding: 0px;
//         min-height: ${props => props.minRows*22}px;
//     }
//     .ql-editor.ql-blank::before {
//         font-style: normal;
//         left: 20px;
//     }
// `;

// class QuillCharLimit extends React.Component {
//     constructor (props) {
//         super(props);
//         this.handleChange = this.handleChange.bind(this);
//         this.quillRef = null;      // Quill instance
//         this.reactQuillRef = null;
//         this.state = {editorHtml : ''};
//     }
//     componentDidMount() {
//         this.attachQuillRefs()
//     }
//
//     componentDidUpdate() {
//         this.attachQuillRefs()
//     }
//
//     attachQuillRefs = () => {
//         if (typeof this.reactQuillRef.getEditor !== 'function') return;
//         this.quillRef = this.reactQuillRef.getEditor();
//     };
//
//     handleChange (html) {
//         const limit = 10;
//         const quill = this.quillRef;
//         quill.on('text-change', function (delta, old, source) {
//             if (quill.getLength() > limit) {
//                 quill.deleteText(limit, quill.getLength());
//             }
//         });
//         this.setState({ editorHtml: html });
//     }
//
//
//     render () {
//         return  <ReactQuill
//             ref={(el) => { this.reactQuillRef = el }}
//             theme="snow"
//             onChange={this.handleChange}
//             value={this.state.editorHtml}
//         />
//     }
// }

export const TextQuillInput = ({title, valid, warning, id, onChange, placeholder, value, maxLength, count, minRows, required}) => (
    <InputSection>
        <>
            <Left>
                <InputLabel><FontBody16>{title}</FontBody16></InputLabel>
                <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>
            </Left>
            {count === 0
                ? <>{required && <CountBlack count={count} minLength={maxLength}>{count.toString()+" of "+maxLength.toString()}</CountBlack>}</>
                : <CountBlack count={count} minLength={maxLength}>{count.toString()+" of "+maxLength.toString()}</CountBlack>
            }
        </>
        <Quill bodyBorderColor={colors.primary30} minRows={minRows}>
            <ReactQuill
                id={id}
                onChange={onChange}
                placeholder={placeholder}
                readOnly={false}
                theme={"snow"}
                defaultValue={value}
                bounds={'.app'}
                modules={editorModules}
                formats={editorFormats}
            />
        </Quill>
    </InputSection>
);

export const TextQuillInputControlCount = ({countColorAbove, countColorBelow, title, valid, warning, id, onChange, placeholder, value, maxLength, count, minRows, required}) => (
    <InputSection>
        <>
            <Left>
                <InputLabel><FontBody16>{title}</FontBody16></InputLabel>
                <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>
            </Left>
            {count === 0
                ? <>{required && <Count count={count} minLength={maxLength}><CountColor color={countColorBelow}>{count.toString()+" of "+maxLength.toString()}</CountColor></Count>}</>
                : <>
                    {count < maxLength
                        ? <Count count={count} minLength={maxLength}><CountColor color={countColorBelow}>{count.toString()+" of "+maxLength.toString()}</CountColor></Count>
                        : <Count count={count} minLength={maxLength}><CountColor color={countColorAbove}>{count.toString()+" of "+maxLength.toString()}</CountColor></Count>
                    }
                </>
            }
        </>
        <Quill bodyBorderColor={colors.primary30} minRows={minRows}>
            <ReactQuill
                id={id}
                onChange={onChange}
                placeholder={placeholder}
                readOnly={false}
                theme={"snow"}
                defaultValue={value}
                bounds={'.app'}
                modules={editorModules}
                formats={editorFormats}
            />
        </Quill>
    </InputSection>
);

const CountBlack = styled.div`
    top: 5px;
    right: 10px;
    display: inline-block;
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    font-size: 12px;
    line-height: 16px;
    ${props => props.count <= props.minLength} {color: ${colors.alert100};}
    text-align: right;
    width: 150px;
    @media screen and (max-width: 460px) {
        width: 100%;
        text-align: left;
    }
`;

export const TextQuillInputWhite = ({title, valid, warning, id, onChange, placeholder, value, maxLength, count, minRows, required}) => (
    <InputSection>
        <>
            <Left>
                <InputLabel><FontBody16>{title}</FontBody16></InputLabel>
                <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>
            </Left>
            {count === 0
                ? <>{required && <CountBlack count={count} minLength={maxLength}>{count.toString()+" of "+maxLength.toString()}</CountBlack>}</>
                : <CountBlack count={count} minLength={maxLength}>{count.toString()+" of "+maxLength.toString()}</CountBlack>
            }
        </>
        <QuillWhite bodyBorderColor={colors.primary30} minRows={minRows}>
            <ReactQuill
                id={id}
                onChange={onChange}
                placeholder={placeholder}
                readOnly={false}
                theme={"snow"}
                defaultValue={value}
                bounds={'.app'}
                modules={editorModules}
                formats={editorFormats}
            />
        </QuillWhite>
    </InputSection>
);

export const PasswordInput = ({title, valid, warning, id, onChange, placeholder, value, containerStyles={}, inputStyles={}}) => (
    <InputSection style={containerStyles}>
        <>
            {(title !== undefined && title !== null  && title !== "") && <InputLabel><FontBody16>{title}</FontBody16></InputLabel>}
            {(warning !== undefined && warning !== null  && warning !== "") && <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>}
        </>
        <input type={"password"} id={id} onChange={onChange} placeholder={placeholder} value={value} style={inputStyles} />
    </InputSection>
);


const ToggleLabel = styled.div`
    vertical-align: top;
    margin: 4px 0px 0px 10px;
    display: inline-block;
`;

export const ToggleInput = ({title, id, onChange, value}) => (
    <InputSection>
        <Switch
            id={id}
            onChange={onChange}
            checked={value}
            offColor={colors.primary30}
            onColor={colors.action100}
        />
        <ToggleLabel><InputLabel><FontBody16>{title}</FontBody16></InputLabel></ToggleLabel>
    </InputSection>
);


const ToggleLabel2 = styled.div`
    vertical-align: top;
    margin: 4px 10px 0px 10px;
    display: inline-block;
`;

export const Toggle2Lables = ({leftLabel, rightLabel, id, onChange, value, offColor, onColor}) => (
    <>
        <ToggleLabel2><InputLabel><FontBody12>{leftLabel}</FontBody12></InputLabel></ToggleLabel2>
        <Switch
            id={id}
            onChange={onChange}
            checked={value}
            offColor={offColor}
            onColor={onColor}
            checkedIcon={false}
            uncheckedIcon={false}
        />
        <ToggleLabel2><InputLabel><FontBody12>{rightLabel}</FontBody12></InputLabel></ToggleLabel2>
    </>
);


const ReadOnly = styled.div`
    color: ${colors.primary100};
    width: calc(100% - 40px);
    padding: 10px 20px;
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    font-size: 16px;
    line-height: 22px;
`;

export const ReadOnlyInput = ({title, value}) => (
    <InputSection>
        <InputLabel><FontBody16>{title}</FontBody16></InputLabel>
        <ReadOnly>{value}</ReadOnly>
    </InputSection>
);

const ReadOnlyValue = styled.input`
    width: 100%;
    padding: 5px 0px;
    font-family: ${env.STYLES.FONTS.header.fontFamily};
    font-size: 16px;
    line-height: 22px;
    ${props => props.color === "progress100"} {color: ${colors.primary100};}
    ${props => props.color !== "progress100"} {color: ${colors.progress100};}
    border: none;
    &:focus {
        outline: none;
    }
`;
const ReadOnlyPhoneValue = styled.div`
    width: 100%;
    padding: 5px 0px;
    font-family: ${env.STYLES.FONTS.header.fontFamily};
    font-size: 16px;
    line-height: 22px;
    ${props => props.color === "progress100"} {color: ${colors.primary100};}
    ${props => props.color !== "progress100"} {color: ${colors.progress100};}
`;
export const ReadOnlyInputLabel = styled.div`
    margin-top: 15px;
    color: ${colors.primary70};
`;
export const ContactInputLabel = styled.div`
    margin-top: 15px;
    display: inline-block;
    color: ${colors.primary70};
`;
const ContactEditValue = styled.input`
    width: calc(100% - 10px);
    padding: 5px 5px;
    font-family: ${env.STYLES.FONTS.header.fontFamily};
    font-size: 16px;
    line-height: 22px;
    border: 1px solid ${colors.primary30};
    border-radius: 4px;
    &:focus {
        outline: none;
    }
`;

const FitContentButton = styled.div`
    width: fit-content;
`;
export const ReadOnlyValueInput = ({title, value, color}) => (
    <div>
        <ReadOnlyInputLabel><FontBody12>{title}</FontBody12></ReadOnlyInputLabel>
        <ReadOnlyValue onChange={() => {}} value={value} color={color} />
    </div>
);

import {ButtonSecondary, ButtonPrimary} from './buttons';

export const TextInputContact = ({title, valid, warning, id, onChange, placeholder, value, disabled, error}) => (
    <div>
        <div>
            <ContactInputLabel><FontBody12>{title}</FontBody12></ContactInputLabel>
            {warning !== undefined && <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>}
            {error !== null && <Invalid valid={false}><FontBody12>{error}</FontBody12></Invalid>}
        </div>
        <ContactEditValue id={id} onChange={onChange} placeholder={placeholder} value={value} disabled={disabled} />
    </div>
);

export const ButtonInput = ({action, passToAction, title, value}) => (
    <div>
        <ReadOnlyInputLabel><FontBody12>{title}</FontBody12></ReadOnlyInputLabel>
        <FitContentButton onClick={() => {action(passToAction)}}><ButtonSecondary canSubmit={true} label={value} /></FitContentButton>
    </div>
);

export const ButtonInputPrimary = ({action, passToAction, title, value}) => (
    <div>
        <ReadOnlyInputLabel><FontBody12>{title}</FontBody12></ReadOnlyInputLabel>
        <FitContentButton onClick={() => {action(passToAction)}}><ButtonPrimary canSubmit={true} label={value} /></FitContentButton>
    </div>
);

const ReadOnlyPhone = styled.div`
    & input {
        border: none;
        padding-bottom: 0px;
        color: ${colors.primary100};
        font-family: ${env.STYLES.FONTS.header.fontFamily};
        font-size: 16px;
        line-height: 22px;
        background-color: ${colors.white};
        cursor: text;
        &:focus {
            outline: none;
        }
    }
`;
export const ReadOnlyPhoneInput = ({title, value, containerStyles={}, inputStyles={}}) => (
    <div style={containerStyles} >
        <ReadOnlyInputLabel><FontBody12>{title}</FontBody12></ReadOnlyInputLabel>
        <ReadOnlyPhoneValue><ReadOnlyPhone>
            <Cleave style={inputStyles} disabled={true} id={"phone"} options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}} onChange={() => {}} placeholder={'(###) ###-####'} value={value} />
        </ReadOnlyPhone></ReadOnlyPhoneValue>
    </div>
);

const ReadOnlyPhoneNoStyle = styled.div`
    & input {
        border: none;
        cursor: text;
        color: ${colors.primary70};
        font-family: ${env.STYLES.FONTS.body.fontFamily};
        padding: 0px;
        margin: 0px;
        font-size: 16px;
        line-height: 22px;
        background-color: ${colors.white};
        &:focus {
            outline: none;
        }
        &::placeholder {
            color: ${colors.primary70};
        }
    }
`;
export const ReadOnlyPhoneInputNoStyle = ({id, value}) => (
    <ReadOnlyPhoneNoStyle>
        <Cleave disabled={true} id={id} options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}} onChange={() => {}} placeholder={'---'} value={value} />
    </ReadOnlyPhoneNoStyle>
);

export const PhoneInput = ({title, valid, warning, id, onChange, placeholder, value, options, error, containerStyles={}, inputStyles={}}) => (
    <InputSection style={containerStyles}>
        <>
            {title !== null && <InputLabel><FontBody16>{title}</FontBody16></InputLabel>}
            {(warning !== undefined && warning !== null  && warning !== "") && <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>}
            {(error !== null && error !== undefined) &&
                <Invalid valid={false}><FontBody12>{error} <LinkStyled href={"https://"+env.URL.AUTH+"/login"}>Log in</LinkStyled>.</FontBody12></Invalid>
            }
        </>
        <Cleave style={inputStyles} id={id} options={options} onChange={onChange} placeholder={placeholder} value={value} />
    </InputSection>
);


const DropdownContainer = styled.div`
    & .Dropdown-control {
        background-color: ${colors.border70};
        border: 1px solid ${colors.primary30};
        color: ${colors.primary100};
        border-radius: 4px;
        width: calc(100% - 40px);
        padding: 10px 20px;
        font-family: ${env.STYLES.FONTS.body.fontFamily};
        font-size: 16px;
        line-height: 22px;
        &:placeholder {
            color: ${colors.primary100};
        }
    }
    & .Dropdown-root {
        position: relative;
    }
    & .Dropdown-control {
        cursor: pointer;
    }
    & .Dropdown-menu {
        background-color: ${colors.border70};
        border-bottom: 1px solid ${colors.primary30};
        border-left: 1px solid ${colors.primary30};
        border-right: 1px solid ${colors.primary30};
        padding: 20px;
        font-family: ${env.STYLES.FONTS.body.fontFamily};
        font-size: 16px;
        line-height: 22px;
        position: absolute;
        width: calc(100% - 42px);
        z-index: 1;
        max-height: 200px;
        overflow-y: scroll;
        ${props => props.dropUpwards !== true} {
            border-top: 1px solid ${colors.primary30};
            bottom: 49px;
        }
    }
    & .Dropdown-placeholder {
        width: calc(100% - 16px);
        display: inline-block;
        color: ${colors.primary70};
    }
    & .Dropdown-placeholder.is-selected {
        width: calc(100% - 16px);
        display: inline-block;
        color: ${colors.primary100};
    }
    & .Dropdown-option {
        padding: 10px 0px;
        cursor: pointer;
        &:hover {
            color: ${colors.action100};
        }
    }
    & .Dropdown-arrow-wrapper {
        display: inline-block;
        vertical-align: top;
        margin-top: 3px;
        text-align: right;
    }
`;

export const DropdownInput = ({title, id, onChange, placeholder, value, options, dropUpwards, valid, warning}) => (
    <InputSection>
        {(title !== undefined && title !== null) && <InputLabel><FontBody16>{title}</FontBody16></InputLabel>}
        {warning !== undefined && <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>}
        <DropdownContainer dropUpwards={dropUpwards}>
            <Dropdown
                options={options}
                id={id}
                onChange={onChange}
                placeholder={placeholder}
                value={value}
                arrowClosed={<CaretDownOutlined />}
                arrowOpen={<CaretDownOutlined />}
            />
        </DropdownContainer>
    </InputSection>
);

const DropdownContainerWhite = styled(DropdownContainer)`
    & .Dropdown-control {
        background-color: ${colors.white};
    };
    & .Dropdown-menu {
        background-color: ${colors.white};
    };
`;

export const DropdownInputWhite = ({title, id, onChange, placeholder, value, options, dropUpwards, valid, warning}) => (
    <InputSection>
        {(title !== undefined && title !== null) && <InputLabel><FontBody16>{title}</FontBody16></InputLabel>}
        {warning !== undefined && <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>}
        <DropdownContainerWhite dropUpwards={dropUpwards}>
            <Dropdown
                options={options}
                id={id}
                onChange={onChange}
                placeholder={placeholder}
                value={value}
                arrowClosed={<CaretDownOutlined />}
                arrowOpen={<CaretDownOutlined />}
            />
        </DropdownContainerWhite>
    </InputSection>
);

const DropdownContainerStyled = styled.div`
    & .Dropdown-control {
        background-color: ${props => props.inputStyles.control.backgroundColor};
        border: ${props => props.inputStyles.control.border};
        border-radius: ${props => props.inputStyles.control.borderRadius};
        line-height: ${props => props.inputStyles.control.lineHeight};
        font-size: ${props => props.inputStyles.control.fontSize};
        box-shadow: ${props => props.inputStyles.control.boxShadow};
        color: ${colors.primary100};
        width: calc(100% - 40px);
        padding: 10px 20px;
        font-family: ${env.STYLES.FONTS.body.fontFamily};
        &::placeholder {
            color: ${colors.primary100};
        }
    }
    & .Dropdown-root {
        position: relative;
    }
    & .Dropdown-control {
        cursor: pointer;
    }
    & .Dropdown-menu {
        background-color: ${props => props.inputStyles.menu.backgroundColor};
        border-bottom: ${props => props.inputStyles.menu.borderBottom};
        border-left: ${props => props.inputStyles.menu.borderLeft};
        border-right: ${props => props.inputStyles.menu.borderRight};
        border-top: ${props => props.inputStyles.menu.borderTop};
        border-radius: ${props => props.inputStyles.menu.borderRadius};
        font-size: ${props => props.inputStyles.menu.fontSize};
        padding: 20px;
        font-family: ${env.STYLES.FONTS.body.fontFamily};
        line-height: 22px;
        position: absolute;
        width: calc(100% - 42px);
        z-index: 1;
        max-height: 200px;
        overflow-y: scroll;
        ${props => props.dropUpwards === true} {
            border-top: ${props => props.inputStyles.menu.borderTop};
            bottom: 49px;
        }
    }
    & .Dropdown-placeholder {
        width: calc(100% - ${props => props.inputStyles.placeholder.chevronWidth});
        display: inline-block;
        color: ${colors.primary70};
    }
    & .Dropdown-placeholder.is-selected {
        width: calc(100% - ${props => props.inputStyles.placeholder.chevronWidth});
        display: inline-block;
        color: ${colors.primary100};
    }
    & .Dropdown-option {
        padding: 10px 0;
        cursor: pointer;
        &:hover {
            color: ${colors.action100};
        }
    }
    & .Dropdown-arrow-wrapper {
        display: inline-block;
        vertical-align: top;
        margin-top: 3px;
        text-align: right;
    }
`;

export const DropdownInputStyled = ({title, id, onChange, placeholder, value, options, dropUpwards, valid, warning, inputStyles= {}, containerStyles={}}) => (
    <InputSection style={containerStyles}>
        {(title !== undefined && title !== null && title !== "") && <InputLabel><FontBody16>{title}</FontBody16></InputLabel>}
        {warning !== undefined && <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>}
        <DropdownContainerStyled dropUpwards={dropUpwards} inputStyles={inputStyles}>
            <Dropdown
                options={options}
                id={id}
                onChange={onChange}
                placeholder={placeholder}
                value={value}
                arrowClosed={<CaretDownOutlined />}
                arrowOpen={<CaretDownOutlined />}
            />
        </DropdownContainerStyled>
    </InputSection>
);

import {SearchOutlined} from "@ant-design/icons";

const Search = styled.input`
    width: 100%;
    padding: 5px 0px;
    font-family: ${env.STYLES.FONTS.header.fontFamily};
    font-size: 16px;
    line-height: 22px;
    color: ${colors.primary100};
    border: none;
    &:focus {
        outline: none;
    }
    ${props => props.placeholderColor === 'default'} {
        &::placeholder {
            color: ${props => props.placeholderColor} !important;
        }
    }
`;
const SearchIcon = styled.div`
    position: absolute;
    top: 15px;
    right: 18px;
    color: ${colors.primary100};
    cursor: pointer;
`;
const SearchIconStart = styled.div`
    position: absolute;
    top: 15px;
    left: 18px;
    color: ${colors.primary100};
    ${props => props.canClick !== true} {
        cursor: pointer;
    }
`;
const SearchIconEnd = styled.div`
    position: absolute;
    top: 15px;
    right: 18px;
    color: ${colors.primary100};
    ${props => props.canClick !== true} {
        cursor: pointer;
    }
`;
const SearchBar = styled.div`
    position: relative;
`;
const SearchResultsContainer = styled.div`
    position: relative;
`;
const SearchResults = styled.div`
    position: absolute;
    padding: 20px 0px;
    width: 100%;
    border: 1px solid ${colors.primary30};
    background-color: ${colors.border70};
    z-index: 11;
    max-height: 250px;
    overflow-y: scroll;
`;
const SearchResultsEmpty = styled.div`
    position: absolute;
    padding: 20px 0px;
    width: 100%;
    border: 1px solid ${colors.primary30};
    background-color: ${colors.border70};
    z-index: 11;
`;
const SearchResult = styled.div`
    padding: 10px 20px;
    cursor: pointer;
    &:hover {
        color: ${colors.action100};
    }
`;
const BackgroundSearch = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10;
`;

export class SearchInput extends React.Component {
    state = {"searchOpen": false, "options": [], "numOptions": 0, "searchKey": ""};

    componentDidMount() {
        this.setState({
            "searchKey": this.props.value,
            "options": this.props.options,
            "numOptions": this.props.options.length
        })
    }

    search = async (e) => {
        const value = e.target.value;
        if(value === "") {
            await this.setState({
                "searchKey": "",
                "options": this.props.options.filter(l => {
                    return(!l.isArchived && l.value.businessUserId === null)
                }),
                "numOptions": this.props.options.length,
                "searchOpen": true
            });
        } else {
            let options = this.props.options.filter(f => {
                if(value !== undefined) {
                    return(f.label.toLowerCase().includes(value.toLowerCase()))
                }
            });
            await this.setState({
                "searchKey": value,
                "options": options,
                "numOptions": options.length,
                "searchOpen": true
            });
        }
    };

    searchChange = async (e) => {
        this.search(e);
        this.props.onChange(null);
    };

    selectOption = async (o) => {
        this.props.onChange(o);
        this.setState({
            "searchOpen": false,
            "options": this.props.options,
            "numOptions": this.props.options.length,
            "searchKey": o.label,
        })
    };

    closeSearch = async () => {
        this.setState({
            "searchOpen": false,
            "options": this.props.options,
            "numOptions": this.props.options.length,
            "searchKey": "",
        });
        this.props.onChange(null);
    };

    render() {
        return (
            <InputSection>
                {(this.props.title !== undefined && this.props.title !== null) && <InputLabel className={"searchInputLabel"}><FontBody16>{this.props.title}</FontBody16></InputLabel>}
                <SearchBar>
                    <Search
                        autoComplete={"off"}
                        id={this.props.id}
                        onChange={this.searchChange}
                        onClick={this.search}
                        placeholder={this.props.placeholder}
                        placeholderColor={'default'}
                        value={this.state.searchKey}
                    />
                    <SearchIcon onClick={this.search}><SearchOutlined /></SearchIcon>
                </SearchBar>
                <SearchResultsContainer>
                    {(this.state.searchOpen && this.state.numOptions > 0) &&
                         <>
                            <SearchResults>
                                {this.state.options.map((o, index) => {
                                    return(<SearchResult onClick={async () => {this.selectOption(o)}} key={index}><FontBody16>{o.label}</FontBody16></SearchResult>)
                                })}
                            </SearchResults>
                            <BackgroundSearch onClick={this.closeSearch} />
                        </>
                    }
                    {/*{(this.state.searchOpen && this.state.numOptions === 0) &&*/}
                    {/*    <SearchResultsEmpty>*/}
                    {/*        <SearchResult onClick={() => {}}><FontBody16>New contact</FontBody16></SearchResult>*/}
                    {/*    </SearchResultsEmpty>*/}
                    {/*}*/}
                </SearchResultsContainer>
            </InputSection>
        )
    }
}

export class SearchInputUncontrolled extends React.Component {
    static defaultProps = {
        shouldShowResults: true,
        onReturnResults: () => {},
        onChange: () => {},
        onSearchMade: () => {},
        searchIconStart: false,
        searchIconEnd: true,
        searchIconOnClick: true,
        searchInputStyles: {placeholderColor: 'default'},
        containerStyles: {}
    };

    state = {"searchOpen": false, "options": [], "numOptions": 0};

    componentDidMount() {
        this.setState({
            "options": this.props.options,
            "numOptions": this.props.options.length
        })
    }

    search = async (e) => {
        const value = e.target.value;
        // Send search string to store
        this.props.onSearchMade(value)
        if(value === "") {
            await this.setState({
                "options": this.props.options.filter(l => {
                    return(!l.isArchived)
                }),
                "numOptions": this.props.options.length,
                "searchOpen": true
            });
        } else {
            let options = this.props.options.filter(f => {
                if(value !== undefined) {
                    return(f.label.toLowerCase().includes(value.toLowerCase()))
                }
            });
            await this.setState({
                "options": options,
                "numOptions": options.length,
                "searchOpen": true
            });
            this.props.onReturnResults(options);
        }
    };

    selectOption = async (o) => {
        this.props.onChange(o);
        this.setState({
            "searchOpen": false,
            "options": this.props.options,
            "numOptions": this.props.options.length
        })
    };

    closeSearch = async () => {
        this.setState({
            "searchOpen": false,
            "options": this.props.options,
            "numOptions": this.props.options.length
        });
    };

    render() {
        return (
            <InputSection style={this.props.containerStyles}>
                {(this.props.title !== undefined && this.props.title !== null) && <InputLabel><FontBody16>{this.props.title}</FontBody16></InputLabel>}
                <SearchBar>
                    {this.props.searchIconStart &&
                        <SearchIconStart canClick={this.props.searchIconOnClick} onClick={this.props.searchIconOnClick ? this.search : () => {}}><SearchOutlined /></SearchIconStart>
                    }
                    <Search
                        autoComplete={"off"}
                        id={this.props.id}
                        onChange={this.search}
                        onClick={this.search}
                        placeholder={this.props.placeholder}
                        placeholderColor={this.props.searchInputStyles.placeholderColor}
                        disabled={this.props.disabled}
                        style={this.props.searchInputStyles}
                    />
                    {this.props.searchIconEnd &&
                        <SearchIconEnd canClick={this.props.searchIconOnClick} onClick={this.props.searchIconOnClick ? this.search : () => {}}><SearchOutlined /></SearchIconEnd>
                    }
                </SearchBar>
                {this.props.shouldShowResults && <SearchResultsContainer>
                    {(this.state.searchOpen && this.state.numOptions > 0) &&
                    <>
                        <SearchResults>
                            {this.state.options.map((o, index) => {
                                return(<SearchResult onClick={async () => {this.selectOption(o)}} key={index}><FontBody16>{o.label}</FontBody16></SearchResult>)
                            })}
                        </SearchResults>
                        <BackgroundSearch onClick={this.closeSearch} />
                    </>
                    }
                    {/*{(this.state.searchOpen && this.state.numOptions === 0) &&*/}
                    {/*    <SearchResultsEmpty>*/}
                    {/*        <SearchResult onClick={() => {}}><FontBody16>New contact</FontBody16></SearchResult>*/}
                    {/*    </SearchResultsEmpty>*/}
                    {/*}*/}
                </SearchResultsContainer>}
            </InputSection>
        )
    }
}

export class SearchInputUncontrolledDisplay extends React.Component {
    state = {"searchOpen": false, "options": [], "numOptions": 0, "searchKey": ""};

    componentDidMount() {
        this.setState({
            "options": this.props.options,
            "numOptions": this.props.options.length,
            "searchKey": this.props.value,
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.title !== this.props.title) {
            this.setState({
                "options": this.props.options,
                "numOptions": this.props.options.length,
                "searchKey": this.props.value,
            })
        }
    }

    search = async (e) => {
        const value = e.target.value;
        if(value === "") {
            await this.setState({
                "searchKey": "",
                "options": this.props.options.filter(l => {
                    return(!l.isArchived)
                }),
                "numOptions": this.props.options.length,
                "searchOpen": true
            });
        } else {
            let options = this.props.options.filter(f => {
                if(value !== undefined) {
                    return(f.label.toLowerCase().includes(value.toLowerCase()))
                }
            });
            await this.setState({
                "searchKey": value,
                "options": options,
                "numOptions": options.length,
                "searchOpen": true
            });
        }
    };

    selectOption = async (o) => {
        this.props.onChange(o);
        this.setState({
            "searchKey": o.label,
            "searchOpen": false,
            "options": this.props.options,
            "numOptions": this.props.options.length
        })
    };

    closeSearch = async () => {
        this.setState({
            "searchKey": "",
            "searchOpen": false,
            "options": this.props.options,
            "numOptions": this.props.options.length
        });
    };

    render() {
        return (
            <InputSection>
                {(this.props.title !== undefined && this.props.title !== null) && <InputLabel><FontBody16>{this.props.title}</FontBody16></InputLabel>}
                <SearchBar>
                    <Search
                        autoComplete={"off"}
                        id={this.props.id}
                        onChange={this.search}
                        onClick={this.search}
                        placeholder={this.props.placeholder}
                        placeholderColor={'default'}
                        disabled={this.props.disabled}
                        value={this.state.searchKey}
                    />
                    <SearchIcon onClick={this.search}><SearchOutlined /></SearchIcon>
                </SearchBar>
                <SearchResultsContainer>
                    {(this.state.searchOpen && this.state.numOptions > 0) &&
                    <>
                        <SearchResults>
                            {this.state.options.map((o, index) => {
                                return(<SearchResult onClick={async () => {this.selectOption(o)}} key={index}><FontBody16>{o.label}</FontBody16></SearchResult>)
                            })}
                        </SearchResults>
                        <BackgroundSearch onClick={this.closeSearch} />
                    </>
                    }
                    {/*{(this.state.searchOpen && this.state.numOptions === 0) &&*/}
                    {/*    <SearchResultsEmpty>*/}
                    {/*        <SearchResult onClick={() => {}}><FontBody16>New contact</FontBody16></SearchResult>*/}
                    {/*    </SearchResultsEmpty>*/}
                    {/*}*/}
                </SearchResultsContainer>
            </InputSection>
        )
    }
}

const DropdownContainerMyMoney = styled.div`
    & .Dropdown-control {
        background-color: ${colors.border70};
        border: 1px solid ${colors.primary30};
        color: ${colors.primary100};
        border-radius: 4px;
        width: calc(100% - 40px);
        padding: 10px 20px;
        font-family: ${env.STYLES.FONTS.body.fontFamily};
        font-size: 16px;
        line-height: 22px;
        &:placeholder {
            color: ${colors.primary100};
        }
    }
    & .Dropdown-root {
        position: relative;
    }
    & .Dropdown-control {
        cursor: pointer;
    }
    & .Dropdown-menu {
        background-color: ${colors.border70};
        border-bottom: 1px solid ${colors.primary30};
        border-left: 1px solid ${colors.primary30};
        border-right: 1px solid ${colors.primary30};
        padding: 10px 20px;
        font-family: ${env.STYLES.FONTS.body.fontFamily};
        font-size: 16px;
        line-height: 22px;
        position: absolute;
        width: calc(100% - 42px);
        z-index: 10;
        max-height: 200px;
        overflow-y: scroll;
        ${props => props.dropUpwards !== true} {
            border-top: 1px solid ${colors.primary30};
            bottom: 49px;
        }
    }
    & .Dropdown-placeholder {
        width: calc(100% - 16px);
        display: inline-block;
        vertical-align: bottom;
        color: ${colors.primary70};
    }
    & .Dropdown-placeholder.is-selected {
        width: calc(100% - 16px);
        display: inline-block;
        color: ${colors.primary100};
    }
    & .Dropdown-option {
        padding: 10px 0px;
        cursor: pointer;
        &:hover {
            color: ${colors.action100};
        }
    }
    & .Dropdown-arrow-wrapper {
        display: inline-block;
        vertical-align: top;
        margin-top: 3px;
        text-align: right;
    }
`;
export const DropdownInputMyMoney = ({title, id, onChange, placeholder, value, options, dropUpwards, className}) => (
    <InputSection className={className}>
        {(title !== undefined && title !== null) && <InputLabel><FontBody16>{title}</FontBody16></InputLabel>}
        <DropdownContainerMyMoney dropUpwards={dropUpwards}>
            <Dropdown
                options={options}
                id={id}
                onChange={onChange}
                placeholder={placeholder}
                value={value}
                arrowClosed={<CaretDownOutlined />}
                arrowOpen={<CaretDownOutlined />}
            />
        </DropdownContainerMyMoney>
    </InputSection>
);

const IconAvatar = styled.div`
    font-size: 120px;
    color: ${colors.primary30};
    background-color: ${colors.border70};
    width: 240px;
    height: 180px;
    text-align: center;
    padding-top: 60px;
`;
const UploadButton = styled.div`
    input[type=file]::-webkit-file-upload-button {
        border: 1px solid transparent;
        background-color: ${colors.primary30};
        color: ${colors.primary100};
        padding: 5px 15px;
        border-radius: 4px;
        margin-top: 20px;
        cursor: pointer;
    }
`;

const ScaleButton = styled.div`
    input[type=range]::-webkit-slider-runnable-track {
        background-color: ${colors.primary30};
        border-radius: 4px;
    }
    input[type=range]:focus::-webkit-slider-thumb {
        height: 36px;
        width: 16px;
        border: 1px solid ${colors.action100};
        border-radius: 3px;
        background: ${colors.action100};
        cursor: pointer;
    }
    display: inline-block;
    vertical-align: top;
`;
const ImageBox = styled.div`
    margin-top: 35px;
`;
const RangeLabel = styled.div`
    display: inline-block;
    padding-top: 2px;
`;
const Range = styled.div`
    margin: 20px 0px;
`;

export class UploadAvatar extends React.Component {
    state = {
        image: undefined,
        allowZoomOut: false,
        position: { x: 0.5, y: 0.5 },
        scale: 1,
        rotate: 0,
        borderRadius: 0,
        preview: null,
        width: 190,
        height: 190,
    };

    componentDidMount() {
        this.setState({"image": this.props.photo});
    }

    handleNewImage = async e => {
        await this.setState({ image: e.target.files[0] });
        this.props.onChange(this.state.image);
    };

    handleScale = async e => {
        const scale = await parseFloat(e.target.value);
        await this.setState({ scale });
        this.editor.getImageScaledToCanvas().toBlob(blob => {this.props.onChange(blob);});
    };

    handlePositionChange = async position => {
        await this.setState({ position });
        this.editor.getImageScaledToCanvas().toBlob(blob => {this.props.onChange(blob);});
    };

    handleXPosition = e => {
        const x = parseFloat(e.target.value);
        this.setState({ position: { ...this.state.position, x } })
    };

    handleYPosition = e => {
        const y = parseFloat(e.target.value);
        this.setState({ position: { ...this.state.position, y } })
    };

    setEditorRef = (editor) => this.editor = editor;

    render() {
        return (
            <ImageBox>
                <AvatarEditor
                    scale={parseFloat(this.state.scale)}
                    width={this.state.width}
                    height={this.state.height}
                    position={this.state.position}
                    onPositionChange={this.handlePositionChange}
                    rotate={parseFloat(this.state.rotate)}
                    borderRadius={this.state.width / (100 / this.state.borderRadius)}
                    image={this.state.image}
                    className="editor-canvas"
                    ref={this.setEditorRef}
                    crossOrigin={'anonymous'}
                />
                <UploadButton><input name="newImage" type="file" onChange={this.handleNewImage} /></UploadButton>
                {this.state.image !== undefined &&
                    <Range>
                        <RangeLabel><FontBody16>Zoom:</FontBody16></RangeLabel>
                        <ScaleButton>
                            <input
                                name="scale"
                                type="range"
                                onChange={this.handleScale}
                                min={this.state.allowZoomOut ? '0.1' : '1'}
                                max="2"
                                step="0.01"
                                defaultValue="1"
                            />
                        </ScaleButton>
                        {/*X Position:*/}
                        {/*<input*/}
                        {/*    name="scale"*/}
                        {/*    type="range"*/}
                        {/*    onChange={this.handleXPosition}*/}
                        {/*    min="0"*/}
                        {/*    max="1"*/}
                        {/*    step="0.01"*/}
                        {/*    value={this.state.position.x}*/}
                        {/*/>*/}
                        {/*<br />*/}
                        {/*Y Position:*/}
                        {/*<input*/}
                        {/*    name="scale"*/}
                        {/*    type="range"*/}
                        {/*    onChange={this.handleYPosition}*/}
                        {/*    min="0"*/}
                        {/*    max="1"*/}
                        {/*    step="0.01"*/}
                        {/*    value={this.state.position.y}*/}
                        {/*/>*/}
                    </Range>
                }
            </ImageBox>
        )
    }
}


const CheckboxWithoutLabel = styled.label`
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 25px;
    width: 25px;
    &:hover input ~ span {
        background-color: ${colors.primary30};
    }
    @media screen and (max-width: 748px) {
        ${props => props.leftAlign === true} {margin: 10px auto;}
        ${props => props.leftAlign === false} {margin: 10px 0px;}
    }
    @media screen and (max-width: 460px) {
        ${props => props.leftAlign === true} {margin: 0px auto 50px auto;}
        ${props => props.leftAlign === false} {margin: 0px 0px 50px 0px;}
    }
    & input:checked ~ span {
        background-color: ${colors.action100};
    }
    & input:checked ~ span:after {
        display: block;
    }
}
`;
const CheckboxLabel = styled.label`
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 25px;
    width: 25px;
    &:hover input ~ span {
        background-color: ${colors.primary30};
    }
    @media screen and (max-width: 748px) {
        margin: 0px auto 10px auto;
    }
    & input:checked ~ span {
        background-color: ${colors.action100};
    }
    & input:checked ~ span:after {
        display: block;
    }
}
`;
const CheckboxInput = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`;
const CheckboxMark = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: ${colors.border70};
    border: 1px solid ${colors.secondary30};
    border-radius: 4px;
    &:after {
        content: "";
        position: absolute;
        display: none;
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid ${colors.white};
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;

export const Checkbox = ({ action, passToAction, question, checked, id }) => (
    <CheckboxWithoutLabel leftAlign={false}>
        <CheckboxInput id={id} type={"checkbox"} checked={checked} onChange={() => {action(passToAction, question, !checked)}} />
        <CheckboxMark />
    </CheckboxWithoutLabel>
);

const CheckboxMarkWhite = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid ${colors.primary30};
    background-color: ${colors.white};
    border-radius: 4px;
    &:after {
        content: "";
        position: absolute;
        display: none;
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid ${colors.white};
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;

const CheckboxMarkMustard = styled(CheckboxMarkWhite)`
    border: 1px solid ${colors.action100};
    background-color: ${colors.action100};
`;

const CheckboxForTable = styled.label`
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 25px;
    width: 25px;
    &:hover input ~ span {
        background-color: ${colors.primary30};
    }
    & input:checked ~ span {
        background-color: ${colors.action100};
    }
    & input:checked ~ span:after {
        display: block;
    }
}
`;

const RadioMarkWhite = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid ${colors.primary30};
    background-color: ${colors.white};
    border-radius: 25px;
    &:after {
        content: "";
        position: absolute;
        display: none;
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid ${colors.white};
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;
export class CheckBox extends React.Component {
    state = {"isChecked": false};
    componentDidMount() {
        this.setState({"isChecked": this.props.checked});
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.checked !== this.props.checked) {
            this.setState({"isChecked": this.props.checked});
        }
    }
    check = async () => {
        this.setState({"isChecked": !this.state.isChecked});
        this.props.action(this.props.allData)
    };
    render() {
        return(
            <CheckboxForTable>
                <CheckboxInput id={this.props.allData.id} type={"checkbox"} checked={this.state.isChecked} onChange={this.check} />
                <CheckboxMarkWhite />
            </CheckboxForTable>
        )
    }
}


export class RadioBox extends React.Component {
    state = {"isChecked": false};
    componentDidMount() {
        this.setState({"isChecked": this.props.checked});
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.checked !== this.props.checked) {
            this.setState({"isChecked": this.props.checked});
        }
    }
    check = async () => {
        if(!this.state.isChecked) {
            this.setState({"isChecked": !this.state.isChecked});
            this.props.action(this.props.allData)
        }
    };
    render() {
        return(
            <CheckboxForTable>
                <CheckboxInput id={this.props.allData.id} type={"checkbox"} checked={this.state.isChecked} onChange={this.check} />
                <RadioMarkWhite />
            </CheckboxForTable>
        )
    }
}

const EachCheckboxLabel = styled.div`
    display: inline-block;
    vertical-align: top;
    padding-left: 10px;
    padding-top: 2px;
    cursor: pointer;
    width: calc(100% - 35px);
`;
const CheckBoxSpacer = styled.div`
    margin-bottom: 15px;
    @media screen and (max-width: 460px) {
        margin-bottom: 20px;
    }
`;

export const CheckboxWithLabel = ({ action, checked, id, label, styleLabel, styleIcon }) => (
    <CheckBoxSpacer>
        <InputLabel>
            <CheckboxLabel>
                <CheckboxInput id={id} type={"checkbox"} checked={checked} onChange={() => {action(id, !checked)}} onClick={() => {action(id, !checked)}} />
                <CheckboxMark style={styleIcon === undefined ? {} : styleIcon} />
            </CheckboxLabel>
        </InputLabel>
        <EachCheckboxLabel style={styleLabel === undefined ? {} : styleLabel} onClick={() => {action(id, !checked)}}><FontBody16>{label}</FontBody16></EachCheckboxLabel>
    </CheckBoxSpacer>
);

export const CheckboxWithLabelNoSpacer = ({ action, checked, id, label, styleLabel, styleIcon, disabled=null }) => (
    <div>
        <InputLabel>
            <CheckboxLabel>
                <CheckboxInput id={id} type={"checkbox"} checked={checked} onChange={() => {action(id, !checked)}} onClick={() => {action(id, !checked)}} disabled={disabled}/>
                <CheckboxMark style={styleIcon === undefined ? {} : styleIcon} />
            </CheckboxLabel>
        </InputLabel>
        <EachCheckboxLabel style={styleLabel === undefined ? {} : styleLabel} onClick={() => {action(id, !checked)}}><FontBody16>{label}</FontBody16></EachCheckboxLabel>
    </div>
);

const Subtext = styled.div`
    margin-left: 37px;
    @media screen and (max-width: 640px) {
        margin-top: -15px;
    }
`;

const CheckboxMarkDisabled = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid ${colors.primary30};
    background-color: ${colors.border100};
    border-radius: 4px;
    &:after {
        content: "";
        position: absolute;
        display: none;
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid ${colors.white};
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;

const RadioMarkDisabled = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid ${colors.primary30};
    background-color: ${colors.border100};
    border-radius: 100%;
    &:after {
        content: "";
        position: absolute;
        display: none;
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid ${colors.white};
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;
const RadioMarkWhite2 = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid ${colors.primary30};
    background-color: ${colors.white};
    border-radius: 100%;
    &:after {
        content: "";
        position: absolute;
        display: none;
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid ${colors.white};
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;
const CheckboxLabelWhite = styled.label`
    display: block;
    position: relative;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 25px;
    width: 25px;
    ${props => props.disabled === true} {
        cursor: pointer;
        &:hover input ~ span {
            background-color: ${colors.primary30};
        }
        & input:checked ~ span {
            background-color: ${colors.action100};
        }
    }
    ${props => props.disabled === false} {
        & input:checked ~ span {
            background-color: ${colors.secondary70};
        }
    }
    @media screen and (max-width: 748px) {
        margin: 0px auto 10px auto;
    }
    & input:checked ~ span:after {
        display: block;
    }
}
`;

const EachCheckboxLabelWhite = styled.div`
    display: inline-block;
    vertical-align: top;
    padding-left: 10px;
    padding-top: 2px;
    width: calc(100% - 35px);
    ${props => props.disabled === true} {
        cursor: pointer;
    }
`;
export const CheckboxWithLabelWhite = ({ action, checked, id, label, subtext, disabled }) => (
    <CheckBoxSpacer>
        <InputLabel>
            <CheckboxLabelWhite disabled={disabled}>
                <CheckboxInput id={id} type={"checkbox"} checked={checked} onChange={disabled ? () => {} : () => {action(id, !checked)}} onClick={disabled ? () => {} : () => {action(id, !checked)}} />
                {disabled ? <CheckboxMarkDisabled /> : <CheckboxMarkWhite />}
            </CheckboxLabelWhite>
        </InputLabel>
        <EachCheckboxLabelWhite disabled={disabled} onClick={disabled ? () => {} : () => {action(id, !checked)}}><FontBody16>{label}</FontBody16></EachCheckboxLabelWhite>
        <Subtext><FontBody12>{subtext}</FontBody12></Subtext>
    </CheckBoxSpacer>
);

export const RadioBoxWithLabelWhite = ({ action, checked, id, label, subtext, disabled }) => (
    <CheckBoxSpacer>
        <InputLabel>
            <CheckboxLabelWhite disabled={disabled}>
                <CheckboxInput id={id} type={"checkbox"} checked={checked} onChange={disabled ? () => {} : () => {action(id, !checked)}} onClick={disabled ? () => {} : () => {action(id, !checked)}} />
                {disabled ? <RadioMarkDisabled /> : <RadioMarkWhite2 />}
            </CheckboxLabelWhite>
        </InputLabel>
        <EachCheckboxLabelWhite disabled={disabled} onClick={disabled ? () => {} : () => {action(id, !checked)}}><FontBody16>{label}</FontBody16></EachCheckboxLabelWhite>
        <Subtext><FontBody12>{subtext}</FontBody12></Subtext>
    </CheckBoxSpacer>
);

const RadioBoxMark = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: ${colors.border100};
    border-radius: 25px;
    &:after {
        content: "";
        position: absolute;
        display: none;
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid ${colors.white};
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;
export const RadioBoxWithLabel = ({ action, checked, id, label, subtext, styleIcon, styleLabel }) => (
    <CheckBoxSpacer>
        <InputLabel>
            <CheckboxLabel>
                <CheckboxInput id={id} type={"checkbox"} checked={checked} onChange={action} onClick={action} />
                <RadioBoxMark style={styleIcon === undefined ? {} : styleIcon} />
            </CheckboxLabel>
        </InputLabel>
        <EachCheckboxLabel style={styleLabel === undefined ? {} : styleLabel} onClick={action}><FontBody16>{label}</FontBody16></EachCheckboxLabel>
        {(subtext !== undefined && subtext !== null && subtext !== "") && <Subtext><FontBody12>{subtext}</FontBody12></Subtext>}
    </CheckBoxSpacer>
);


const RadioLabel = styled.label`
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: ${props => props.size}px;
    width: ${props => props.size}px;
    margin: 0px 2px;
    &:hover input ~ span {
        background-color: ${colors.primary30};
    }
    & input:checked ~ span {
        background-color: ${props => props.backgroundColor};
    }
    & input:checked ~ span:after {
        display: block;
    }
}
`;
const RadioInput = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`;
const RadioMark = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: ${props => props.size}px;
    width: ${props => props.size}px;
    background-color: ${props => props.backgroundColor};
    border-radius: 25px;
`;
const RadioNumber = styled.div`
    position: absolute;
    font-size: 15px;
    top: ${props => props.numberTop}px;
    left: ${props => props.numberLeft}px;
`;
const RadioLabelLeft = styled.div`
    display: inline-block;
    vertical-align: top;
    color: ${colors.secondary100};
    width: calc(100% - 90px);
`;
const RadioLabelRight = styled.div`
    display: inline-block;
    vertical-align: top;
    color: ${colors.secondary100};
    width: 90px;
    text-align: right;
`;
const RadioInputContainer = styled.div`
    margin: 15px auto 0px auto;
    width: ${props => props.width};
`;
const Radios = styled.div`
    width: ${props => props.width};
    margin: 0px auto;
    text-align: center;
`;
const RadioMark2 = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: ${props => props.size}px;
    width: ${props => (props.size * 2.5)}px;
    background-color: ${props => props.backgroundColor};
    border-radius: 25px;
    color: "FFFFFF";
    text-align: center;
`;
const RadioInput2 = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    color: "FFFFFF";
`;
const RadioNumber2 = styled.div`
    position: absolute;
    font-size: 20px;
    top: ${props => props.numberTop}px;
    left: ${props => props.numberLeft}px;
`;
const RadioLabel2 = styled.label`
    display: block;
    position: relative;
    cursor: pointer;
    color: ${props => props.color};
    font-size: 22px;
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: ${props => props.size}px;
    width: ${props => props.size * 2.5}px;
    margin: 0px 0px 0px 0px;
    &:hover input ~ span {
        background-color: ${colors.primary30};
    }
    & input:checked ~ span {
        background-color: ${props => props.backgroundColor};
    }
    & input:checked ~ span:after {
        display: block;
    }
    padding-top: 10px;
    text-align: center;
}
`;
const RadioButton2 = ({ action, checked, id, numberLeft, backgroundColor, size, numberTop }) => (
    <RadioLabel2 backgroundColor={backgroundColor} size={size} color={checked ? colors.white : colors.black}>
        <RadioInput2 id={id} type={"radio"} checked={checked} onChange={() => {action(id)}} />
        <RadioMark2 backgroundColor={backgroundColor} size={size} />
        <RadioNumber2 numberTop={numberTop} numberLeft={numberLeft}>{id}</RadioNumber2>
    </RadioLabel2>
);
const Radios2 = styled.div`
    width: calc(100% - 42px);
    margin: 0px 0px;
    text-align: center;
    padding: 10px 0px 10px 0px;
`;
export const InputSection2 = styled.div`
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    text-align: center;
    & div {
        margin: 0px 0px 0px 0px;
    }
    input, textarea, .ql-container {
        background-color: ${colors.border70};
        border: 1px solid ${colors.primary30};
        color: "FFFFFF";
        border-radius: 4px;
        width: calc(100% - 40px);
        padding: 0px 0px;
        font-family: ${env.STYLES.FONTS.body.fontFamily};
        font-size: 16px;
        line-height: 22px;
        &::placeholder {
            color: ${colors.primary30};
        }
        &:focus {
            outline: none;
        }
    }
    & .ql-container {
        width: 100% !important;
    }
`;

export const RadiosTwo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  font-size: 20px;
  align-items: flex-start;
`;
export const RadioButtonsTwo = ({ action, checked }) => (
    <InputSection2>
        <Radios2>
            <RadioButton2 id={"Infinite"} checked={checked === "Infinite"} action={action} numberTop={15} numberLeft={28} size={50} backgroundColor={(checked === "Infinite") ? "#6BC8D6" : colors.border100} />
            <RadioButton2 id={"Limited"} checked={checked === "Limited"} action={action} numberTop={15} numberLeft={27} size={50} backgroundColor={(checked === "Limited") ? "#6BC8D6" : colors.border100} />
        </Radios2>
    </InputSection2>
);
const RadioButton = ({ action, checked, id, numberLeft, backgroundColor, size, numberTop }) => (
    <RadioLabel backgroundColor={backgroundColor} size={size} >
        <RadioInput id={id} type={"radio"} checked={checked} onChange={() => {action(id)}} />
        <RadioMark backgroundColor={backgroundColor} size={size} />
        <RadioNumber numberTop={numberTop} numberLeft={numberLeft}>{id}</RadioNumber>
    </RadioLabel>
);
export const RadioButtonsFive = ({ label, low, high, action, checked }) => (
    <InputSection>
        <FontBody16>{label}</FontBody16>
        <RadioInputContainer width={"170px"}>
            <RadioLabelLeft><FontBody12>{low}</FontBody12></RadioLabelLeft>
            <RadioLabelRight><FontBody12>{high}</FontBody12></RadioLabelRight>
        </RadioInputContainer>
        <Radios width={"170px"}>
            <RadioButton id={1} checked={checked === 1} action={action} numberTop={6} numberLeft={11} size={30} backgroundColor={(checked === null || checked === 1) ? "#EA505C" : colors.border100} />
            <RadioButton id={2} checked={checked === 2} action={action} numberTop={6} numberLeft={11} size={30} backgroundColor={(checked === null || checked === 2) ? "#EA8C50" : colors.border100} />
            <RadioButton id={3} checked={checked === 3} action={action} numberTop={6} numberLeft={11} size={30} backgroundColor={(checked === null || checked === 3) ? "#F6C544" : colors.border100} />
            <RadioButton id={4} checked={checked === 4} action={action} numberTop={6} numberLeft={11} size={30} backgroundColor={(checked === null || checked === 4) ? "#C4CE36" : colors.border100} />
            <RadioButton id={5} checked={checked === 5} action={action} numberTop={6} numberLeft={11} size={30} backgroundColor={(checked === null || checked === 5) ? "#59AF2B" : colors.border100} />
        </Radios>
    </InputSection>
);

export const RadioButtonsTen = ({ label, low, high, action, checked }) => (
    <InputSection>
        <FontBody16>{label}</FontBody16>
        <RadioInputContainer width={"240px"}>
            <RadioLabelLeft><FontBody12>{low}</FontBody12></RadioLabelLeft>
            <RadioLabelRight><FontBody12>{high}</FontBody12></RadioLabelRight>
        </RadioInputContainer>
        <Radios width={"240px"}>
            <RadioButton id={1} checked={checked === 1} action={action} numberLeft={6} numberTop={2} size={20} backgroundColor={(checked === null || checked === 1) ? "#E53C40" : colors.border100} />
            <RadioButton id={2} checked={checked === 2} action={action} numberLeft={6} numberTop={2} size={20} backgroundColor={(checked === null || checked === 2) ? "#EA505C" : colors.border100} />
            <RadioButton id={3} checked={checked === 3} action={action} numberLeft={6} numberTop={2} size={20} backgroundColor={(checked === null || checked === 3) ? "#E86452" : colors.border100} />
            <RadioButton id={4} checked={checked === 4} action={action} numberLeft={6} numberTop={2} size={20} backgroundColor={(checked === null || checked === 4) ? "#EA8C50" : colors.border100} />
            <RadioButton id={5} checked={checked === 5} action={action} numberLeft={6} numberTop={2} size={20} backgroundColor={(checked === null || checked === 5) ? "#F1A74A" : colors.border100} />
            <RadioButton id={6} checked={checked === 6} action={action} numberLeft={6} numberTop={2} size={20} backgroundColor={(checked === null || checked === 6) ? "#F6C544" : colors.border100} />
            <RadioButton id={7} checked={checked === 7} action={action} numberLeft={6} numberTop={2} size={20} backgroundColor={(checked === null || checked === 7) ? "#E1C440" : colors.border100} />
            <RadioButton id={8} checked={checked === 8} action={action} numberLeft={6} numberTop={2} size={20} backgroundColor={(checked === null || checked === 8) ? "#C4CE36" : colors.border100} />
            <RadioButton id={9} checked={checked === 9} action={action} numberLeft={6} numberTop={2} size={20} backgroundColor={(checked === null || checked === 9) ? "#83CD34" : colors.border100} />
            <RadioButton id={10} checked={checked === 10} action={action} numberLeft={2} numberTop={2} size={20} backgroundColor={(checked === null || checked === 10) ? "#59AF2B" : colors.border100} />
        </Radios>
    </InputSection>
);



// const CalendarControl = styled.div`
//     .react-date-picker__wrapper {
//         border: 0px;
//     }
//     .react-date-picker__inputGroup__input {
//         padding: 5px 25px;
//     }
//     .react-date-picker__inputGroup__divider {
//         padding: 0px 5px;
//     }
// `;
//
// export const CalendarDay = ({title, id, onChange, value}) => (
//     <InputSection>
//         <Row>
//             <InputLabel><FontBody16>{title}</FontBody16></InputLabel>
//             <Invalid valid={true}><FontBody12>{"warning"}</FontBody12></Invalid>
//         </Row>
//         <CalendarControl>
//             <DatePicker
//                 id={id}
//                 onChange={onChange}
//                 value={value}
//                 calendarIcon={null}
//                 clearIcon={null}
//                 minDate={new Date()}
//                 disableCalendar={true}
//                 required={true}
//             />
//         </CalendarControl>
//     </InputSection>
// );


// const TimeControl = styled.div`
//     .react-time-picker__wrapper {
//         border: 0px;
//     }
//     .react-time-picker__inputGroup__input {
//         padding: 5px 25px;
//     }
//     .react-time-picker__inputGroup__divider {
//         padding: 0px 5px;
//     }
//     .react-time-picker__inputGroup__amPm {
//         background-color: ${colors.border70};
//         border: 1px solid ${colors.primary30};
//         color: ${colors.primary100};
//         border-radius: 4px;
//         padding: 5px 20px;
//         font-family: ${env.STYLES.FONTS.body.fontFamily};
//         font-size: 16px;
//         line-height: 22px;
//         &:placeholder {
//             color: ${colors.primary30};
//         }
//     }
// `;
//
// export const CalendarTime = ({title, id, onChange, value}) => (
//     <InputSection>
//         <Row>
//             <InputLabel><FontBody16>{title}</FontBody16></InputLabel>
//             <Invalid valid={true}><FontBody12>{"warning"}</FontBody12></Invalid>
//         </Row>
//         <TimeControl>
//             <TimePicker
//                 id={id}
//                 onChange={onChange}
//                 value={value}
//                 disableClock={true}
//                 clearIcon={null}
//                 clockIcon={null}
//                 minTime={"07:00:00"}
//                 maxTime={"20:00:00"}
//                 required={true}
//             />
//         </TimeControl>
//     </InputSection>
// );


const Row = styled.div`
    display: block;
`;

import "react-datepicker/dist/react-datepicker.css";
const DateWrapper = styled.div`
    & .react-datepicker-wrapper {
        width: 100%;
        & input {
            ${props => props.disabled === true} {cursor: pointer;}
            ${props => props.disabled !== true} {
                cursor: default;
                color: ${colors.primary30};
            }
        }
    }
    & .react-datepicker__day--keyboard-selected {
        background-color: white !important;
        color: black !important;
    }
`;

export const CalendarDay = ({title, id, onChange, value, disabled}) => (
    <InputSection>
        <Row>
            <InputLabel><FontBody16>{title}</FontBody16></InputLabel>
            <Invalid valid={true}><FontBody12>{"warning"}</FontBody12></Invalid>
        </Row>
        <DateWrapper disabled={disabled}>
            <DatePicker
                disabled={disabled}
                id={id}
                onChange={onChange}
                selected={value}
                minDate={new Date(moment().toString())}
            />
        </DateWrapper>
    </InputSection>
);
//new Date(date.toLocaleString('en-US', {timeZone: "UTC"})


export const CalendarDayPast = ({title, id, onChange, value, disabled, valid, warning}) => (
    <InputSection>
        <Row>
            <InputLabel><FontBody16>{title}</FontBody16></InputLabel>
            <Invalid valid={valid}><FontBody12>{warning}</FontBody12></Invalid>
        </Row>
        <DateWrapper disabled={disabled}>
            <DatePicker
                disabled={disabled}
                id={id}
                onChange={onChange}
                selected={value}
                maxDate={new Date(moment().toString())}
                dateFormat="MMM yyyy"
                showMonthYearPicker
            />
        </DateWrapper>
    </InputSection>
);

const ClockTimeControl = styled.div`
    background-color: ${colors.border70};
    border: 1px solid ${colors.primary30};
    color: ${colors.primary100};
    border-radius: 4px;
    width: calc(100% - 40px);
    padding: 10px 20px;
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    font-size: 16px;
    line-height: 22px;
    ${props => props.disabled === true} {cursor: pointer;}
    ${props => props.disabled !== true} {
        cursor: default;
        color: ${colors.primary30};
        pointer-events: none;
    }
`;
const ClockTimeCss = styled.div`
    position: ${props => props.absolute};
    z-index: ${props => props.zIndex};
    & .react-timekeeper {
        position: relative;
        @media screen and (max-width: 460px) {
            position: absolute;
            margin-bottom: 100px;
        }
    }
`;
const BackgroundClock = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: ${props => props.zIndex};
`;
const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;
const InfoTooltipTimeEnd = styled.div`
    color: ${colors.action100};
    margin-left: 5px;
    margin-top: 3px;
    font-size: 14px;
`;
import {InfoCircleOutlined} from '@ant-design/icons';

export class ClockTime extends React.Component {
    constructor(props) {
        super(props);
        this.state={visible: false}
    }
    handleOpen = e => {this.setState({"visible": true})};
    handleClose = e => {this.setState({"visible": false})};
    render() {
        return(
            <InputSection>
                <Row>
                    <InputLabel>
                        <Inline><FontBody16>{this.props.title}</FontBody16></Inline>
                        {this.props.endTooltip && <Inline><InfoTooltipTimeEnd title={"Meetings continue until the last person leaves"}><InfoCircleOutlined /></InfoTooltipTimeEnd></Inline>}
                    </InputLabel>
                    {this.props.valid
                        ?
                            <>
                                {this.props.validEnd
                                    ? <Invalid valid={true}><FontBody12>{this.props.warningEnd}</FontBody12></Invalid>
                                    : <Invalid valid={this.props.validEnd}><FontBody12>{this.props.warningEnd}</FontBody12></Invalid>
                                }
                            </>
                        :
                            <Invalid valid={this.props.valid}><FontBody12>{this.props.warning}</FontBody12></Invalid>
                    }

                </Row>
                <ClockTimeControl disabled={this.props.disabled} onClick={this.handleOpen}>{moment(this.props.value).format("LT")}</ClockTimeControl>
                {this.state.visible &&
                    <>
                        <ClockTimeCss
                            zIndex={this.props.zIndex === undefined ? 2 : this.props.zIndex}
                            absolute={this.props.absolute === undefined ? "relative" : "absolute"}
                        >
                            <TimeKeeper
                                id={this.props.id}
                                time={moment(this.props.value).format("h:mma")}
                                onChange={(newTime) => this.props.handleTimeChange(newTime, this.props.id)}
                                onDoneClick={this.handleClose}
                                switchToMinuteOnHourSelect
                            />
                        </ClockTimeCss>
                        <BackgroundClock
                            onClick={() => {this.setState({"visible": false})}}
                            zIndex={this.props.zIndex === undefined ? 1 : (this.props.zIndex-1)}
                        />
                    </>
                }
            </InputSection>
        )
    }
}


const CheckboxContainer = styled.div`
    width: calc(100% - 52px);
    border-radius: 4px;
    padding: 15px 25px;
    margin-bottom: 10px;
    cursor: pointer;
    ${props => props.checked !== false} {
        border: 1px solid ${colors.primary30};
        background-color: ${colors.border70};
    }
    ${props => props.checked !== true} {
        border: 1px solid ${colors.action100};
        background-color: ${colors.action100};
    }
`;
const CheckboxIcon = styled.img`
    width: 80px;
    height: 50px;
    display: inline-block;
    vertical-align: top;
`;
const CheckboxTitleBody = styled.div`
    display: inline-block;
    width: calc(100% - 80px);
    vertical-align: top;
`;

export const CheckboxWithIconTitleBody = ({id, icon, title, body, onClick, checked, style}) => (
    <CheckboxContainer onClick={() => onClick(id, !checked)} checked={checked}>
        <CheckboxIcon src={icon} style={style.optionIcon === undefined ? {} : style.optionIcon}  />
        <CheckboxTitleBody>
            <b><FontBody16 style={style.optionTitle === undefined ? {} : style.optionTitle} >{title}</FontBody16></b>
            <FontBody12 style={style.optionBody === undefined ? {} : style.optionBody} >{body}</FontBody12>
        </CheckboxTitleBody>
    </CheckboxContainer>
);

import { MenuOutlined } from '@ant-design/icons';

const MenuKeyPairs = styled.div`
    position: relative;
    text-align: left;
`;
const Menu = styled.div`
    position: relative;
`;
const MenuIcon = styled.div`
    cursor: pointer;
    text-align: center;
    padding: 0px 10px;
    @media screen and (max-width: 640px) {
        text-align: left;
        padding: 0px;
    }
`;
const MenuExpanded = styled.div`
    min-width: 200px;
    position: absolute;
    padding: 15px 0px;
    border-radius: 5px;
    background-color: ${colors.white};
    border: 1px solid ${colors.primary30};
    z-index: 4;
    right: 0;
    @media screen and (max-width: 640px) {
        right: auto;
        left: 0;
    }
    @media screen and (max-width: 460px) {
        right: 0;
        left: auto;
    }
`;
const MenuBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
`;
const MenuItem = styled.div`
    padding: 10px 30px;
    cursor: pointer;
`;
const MenuItemKeyPairs = styled.div`
    padding: 10px 30px;
    ${props => props.active === true} {
        cursor: default;
        & div {
            color: ${colors.secondary70};
        }
    }
    ${props => props.active === false} {
        cursor: pointer;
        & div {
            color: ${colors.action100};
        }
    }
`;

export class MenuInputKeyPairs extends React.Component {
    state={isVisible: false, isIconVisible: false};
    constructor(props) {
        super(props);
        this.isMountedVal = 0;
    }
    componentDidMount() {
        this.isMountedVal = 1;
        return this.init();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps !== this.props) {
            return this.init();
        }
    }
    componentWillUnmount() {
        this.isMountedVal = 0;
    }
    init = async () => {
        let shouldShowIcon = false;
        await Promise.all(Object.keys(this.props.actions).map(a => {
            if(this.props.labels[a].show) {
                shouldShowIcon = true
            }
        }));
        if (this.isMountedVal){
            this.setState({"isIconVisible": shouldShowIcon})
        }
    };
    selectAction = async (action) => {
        await this.setState({"isVisible": !this.state.isVisible});
        action(this.props.allData);
    };
    render() {
        if(!this.state.isIconVisible) {
            return null
        } else {
            return(
                <MenuKeyPairs>
                    <MenuIcon onClick={() => this.setState({"isVisible": !this.state.isVisible})}><MenuOutlined /></MenuIcon>
                    {this.state.isVisible &&
                    <>
                        <MenuExpanded>
                            {Object.keys(this.props.actions).sort().map((a, index) => {
                                if(this.props.labels[a].show) {
                                    return (
                                        <MenuItemKeyPairs
                                            active={this.props.labels[a].active}
                                            key={index}
                                            onClick={this.props.labels[a].active ? () => this.selectAction(this.props.actions[a]) : () => {}}
                                        >
                                            <FontBody16>{this.props.labels[a].label}</FontBody16>
                                        </MenuItemKeyPairs>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </MenuExpanded>
                        <MenuBackground onClick={() => this.setState({"isVisible": false})}/>
                    </>
                    }
                </MenuKeyPairs>
            )
        }
    }
}

const UserKeyPairs = styled.div`
    position: relative;
    text-align: left;
`;
const UserIcon = styled.div`
    cursor: pointer;
    text-align: left;
    padding: 0px 5px;
    @media screen and (max-width: 640px) {
        text-align: left;
        padding: 0px;
    }
`;
const UsersExpanded = styled.div`
    min-width: 250px;
    position: absolute;
    padding: 15px 0px;
    border-radius: 5px;
    background-color: ${colors.white};
    border: 1px solid ${colors.primary30};
    z-index: 4;
    right: 0;
    @media screen and (max-width: 640px) {
        right: auto;
        left: 0;
    }
    @media screen and (max-width: 460px) {
        right: 0;
        left: auto;
    }
`;

export class UsersInput extends React.Component {
    constructor(props) {
        super(props);
        this.state={isVisible: false};
    }
    selectAction = (action) => {
        this.setState({"isVisible": !this.state.isVisible});
    };
    render() {
        return(
            <UserKeyPairs>
                <UserIcon onClick={() => this.setState({"isVisible": !this.state.isVisible})}><TeamOutlined /></UserIcon>
                {this.state.isVisible &&
                <>
                    <UsersExpanded>
                        {this.props.users.map((a, index) => {
                            return (
                                <MenuItemKeyPairs active={false} key={index} >
                                    <FontBody16>{(index + 1)}. {a.firstName + " " + a.lastName/*(a.isLily === false ? a.lastName : a.lastName.charAt(0) + ".")*/}</FontBody16>
                                </MenuItemKeyPairs>
                                );
                        })}
                    </UsersExpanded>
                    <MenuBackground onClick={() => this.setState({"isVisible": false})}/>
                </>
                }
            </UserKeyPairs>
        )
    }
}

export class MenuInput extends React.Component {
    constructor(props) {
        super(props);
        this.state={isVisible: false};
    }
    selectAction = (index) => {
        this.props.actions[index](this.props.passToProps);
        this.setState({"isVisible": !this.state.isVisible});
    };
    render() {
        return(
            <Menu>
                <MenuIcon onClick={() => this.setState({"isVisible": !this.state.isVisible})}><MenuOutlined /></MenuIcon>
                {this.state.isVisible &&
                    <>
                        <MenuExpanded>
                            {this.props.labels.map((label, index) => (
                                <MenuItem key={index} onClick={() => {this.selectAction(index)}}><FontBody16>{label}</FontBody16></MenuItem>
                            ))}
                        </MenuExpanded>
                        <MenuBackground onClick={() => this.setState({"isVisible": false})}/>
                    </>
                }
            </Menu>
        )
    }
}

import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import InputNumber from 'rc-input-number';

const Minus = styled.div`
    display: inline-block;
    width: 60px;
    padding-top: 6px;
    vertical-align: top;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    color: ${colors.border100};
`;
const CurrentCountInput = styled.div`
    & .rc-input-number {
        position: relative;
    }
    & .rc-input-number-handler-wrap {
        position: absolute;
        left: 0;
        right: 0;
        & .rc-input-number-handler-up {
            position: absolute;
            right: 0;
        }
        & .rc-input-number-handler-down {
            position: absolute;
        }
    }
    & input {
        text-align: center;
        border: 1px solid ${colors.border100};
        width: calc(100% - 122px);
        padding: 5px 0px;
        display: inline-block;
        vertical-align: top;
        font-family: ${env.STYLES.FONTS.header.fontFamily};
        font-size: 18px;
        color: ${colors.action100};
        margin-left: 60px;
        &:active, &:focus {
            outline: none;
        }
    }
`;
const Plus = styled.div`
    display: inline-block;
    width: 60px;
    padding-top: 6px;
    vertical-align: top;
    text-align: center;
    font-size: 18px;
    color: ${colors.action100};
    cursor: pointer;
`;

export const PlusMinusCountUncontrolled = ({initialCount, count, onChange}) => (
    <CurrentCountInput>
        <InputNumber
            defaultValue={initialCount}
            value={count}
            min={0}
            step={1}
            onChange={onChange}
            upHandler={<Plus><PlusCircleFilled /></Plus>}
            downHandler={<Minus><MinusCircleFilled /></Minus>}
        />
    </CurrentCountInput>
);



// Single click only if no double click
// https://medium.com/trabe/prevent-click-events-on-double-click-with-react-with-and-without-hooks-6bf3697abc40
const cancellablePromise = promise => {
    let isCanceled = false;
    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            value => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
            error => reject({ isCanceled, error }),
        );
    });
    return {promise: wrappedPromise, cancel: () => (isCanceled = true),};
};
const delay = n => new Promise(resolve => setTimeout(resolve, n));
const useCancellablePromises = () => {
    const pendingPromises = useRef([]);
    const appendPendingPromise = promise => {pendingPromises.current = [...pendingPromises.current, promise];};
    const removePendingPromise = promise => {pendingPromises.current = pendingPromises.current.filter(p => p !== promise);};
    const clearPendingPromises = () => pendingPromises.current.map(p => p.cancel());
    return ({appendPendingPromise, removePendingPromise, clearPendingPromises,});
};
const useClickPreventionOnDoubleClick = (onClick, onDoubleClick) => {
    const api = useCancellablePromises();
    const handleClick = () => {
        api.clearPendingPromises();
        const waitForClick = cancellablePromise(delay(300));
        api.appendPendingPromise(waitForClick);
        return waitForClick.promise
            .then(() => {
                api.removePendingPromise(waitForClick);
                onClick();
            })
            .catch(errorInfo => {
                api.removePendingPromise(waitForClick);
                if (!errorInfo.isCanceled) {
                    throw errorInfo.error;
                }
            });
    };
    const handleDoubleClick = () => {
        api.clearPendingPromises();
        onDoubleClick();
    };
    return [handleClick, handleDoubleClick];
};
export const SingleOrDoubleClickButton = ({ onClick, onDoubleClick, container: Container, contents }) => {
    const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(onClick, onDoubleClick);
    return (<Container onClick={handleClick} onDoubleClick={handleDoubleClick}>{contents}</Container>);
};
// Example <SingleOrDoubleClickButton container={TD} key={keyString} style={{"cursor": "pointer", ...tdStyles}} onClick={() => {onClick(allData)}} onDoubleClick={() => onDoubleClick(allData)} contents={label} />




const FileImage = styled.img`
    width: 24px;
    margin-right: 10px;
    vertical-align: bottom;
    display: inline-block;
`;
const FileName = styled.div`
    vertical-align: bottom;
    display: inline-block;
    word-break: break-word;
`;

export const FileOnList = ({name, icon}) => (
    <div>
        <FileImage src={fileTypeImage(icon, true)} alt={""} />
        <FileName>{name}</FileName>
    </div>
);