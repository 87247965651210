import React from 'react';
import styled from 'styled-components';
import {colors} from '../../common/components/colors';
import {ButtonPrimary, ButtonInactive, ButtonTertiary, ButtonTertiaryAlert} from "../../common/components/buttons";

export const Width = styled.div`
    max-width: 536px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
`;
export const Border = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.primary30};
    padding-top: 30px;
    margin-bottom: 30px;
`;
export const SaveSection = styled.div`
    border-top: 1px solid ${colors.border100};
    text-align: left;
    padding-top: 20px;
    margin-top: 50px;
`;
export const InlineBlock = styled.div`
    display: inline-block;
`;
export const ButtonRight = styled.div`
    margin-right: 10px;
`;
export const SubmitInputWithValidation = ({ isChanged, isValid, isSaving, label }) => {
    if(isChanged && isValid) {
        if(isSaving) {
            return (<ButtonRight><ButtonInactive canSubmit={true} label={"Saving"} /></ButtonRight>)
        } else {
            return (<ButtonRight><ButtonPrimary canSubmit={true} label={label}/></ButtonRight>)
        }
    } else {
        return(<ButtonRight><ButtonInactive canSubmit={true} label={label}/></ButtonRight>)
    }
};
export const SubmitInputWithValidationAlert = ({ isChanged, isValid, isSaving, label }) => {
    if(isChanged && isValid) {
        if(isSaving) {
            return (<ButtonRight><ButtonTertiary canSubmit={true} label={"Saving"} /></ButtonRight>)
        } else {
            return (<ButtonRight><ButtonTertiaryAlert canSubmit={true} label={label}/></ButtonRight>)
        }
    } else {
        return(<ButtonRight><ButtonInactive canSubmit={true} label={label}/></ButtonRight>)
    }
};

export const Card = styled.div`
    width: 100%;
    vertical-align: top;
    padding: 30px;
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
`;


export const CardOrNot = styled.div`
    width: 100%;
    vertical-align: top;
    padding: 30px;
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    margin-top: 0px;
    @media screen and (max-width: 1440px) {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-top: 1px solid ${colors.primary30};    
        padding: 30px 0px 30px 0px;
        margin-top: 30px;
    }
    @media screen and (max-width: 1088px) {
        border-top: none;    
        margin-top: 0px;
        padding: 30px;
        -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    }
    @media screen and (max-width: 1020px) {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-top: 1px solid ${colors.primary30};    
        padding: 30px 0px 30px 0px;
        margin-top: 30px;
    }
`;

export const WidthRight = styled.div`
    width: calc(100% - 536px);
    display: inline-block;
    vertical-align: top;
    position: relative;
    min-height: 550px;
    @media screen and (max-width: 1440px) {
        display: block;
        width: 100%;
        min-height: auto;
    }
    @media screen and (max-width: 1088px) {
        display: inline-block;
        width: calc(100% - 536px);
        min-height: 550px;
    }
    @media screen and (max-width: 1020px) {
        display: block;
        width: 100%;
        min-height: auto;
    }
`;

export const Right = styled.div`
    width: 100%;
    max-width: 360px;
    margin: 0px;
    position: absolute;
    left: 120px;
    > div:first-child {
        width: calc(100% - 60px);
    }
    @media screen and (max-width: 1440px) {
        max-width: 536px;
        margin: 0px;
        right: 0px;
        left: 0px;
        position: relative;
        > div:first-child {
            width: 100%;
        }
    }
    @media screen and (max-width: 1088px) {
        max-width: 360px;
        margin: 0px;
        position: absolute;
        right: 0px;
        left: 60px;
        > div:first-child {
            width: calc(100% - 60px);
        }
    }
    @media screen and (max-width: 1020px) {
        max-width: 536px;
        margin: 0px;
        right: 0px;
        left: 0px;
        position: relative;
        > div:first-child {
            width: 100%;
        }
    }
`;