import React from 'react';
import styled from "styled-components";

import {colors} from '../../../common/components/colors';

export const Left = styled.div`
    display: inline-block;
    vertical-align: top;
    height: 360px;
    width: calc(100% - 368px);
    max-width: 720px;
    text-align: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 0px;
    position: relative;
    background-color: ${colors.secondary100};
    @media screen and (max-width: 1220px) {
        width: 100%;
        max-width: 100%;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    @media screen and (max-width: 1088px) {
        width: calc(100% - 368px);
        max-width: 720px;
        border-top-left-radius: 4px;
        border-top-right-radius: 0px;
    }
    @media screen and (max-width: 800px) {
        width: 100%;
        max-width: 100%;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
`;
export const Right = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 308px;
    height: auto;
    min-height: 212px;
    max-height: 212px;
    padding: 30px 30px 118px 30px;
    background-color: ${colors.border70};
    color: ${colors.primary100};
    border-top-right-radius: 4px;
    border-top-left-radius: 0px;
    position: relative;
    @media screen and (max-width: 1220px) {
        width: calc(100% - 60px);
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        padding: 30px;
        min-height: auto;
        max-height: auto;
    }
    @media screen and (max-width: 1088px) {
        width: 308px;
        border-top-right-radius: 4px;
        border-top-left-radius: 0px;
        padding: 30px 30px 118px 30px;
        min-height: 212px;
        max-height: 212px;
    }
    @media screen and (max-width: 800px) {
        width: calc(100% - 60px);
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        padding: 30px;
        min-height: auto;
        max-height: auto;
    }
`;
export const Month = styled.div`
    color: ${colors.primary100};
    @media screen and (max-width: 1220px) {
        display: none;
    }
    @media screen and (max-width: 1088px) {
        display: block;
    }
    @media screen and (max-width: 800px) {
        display: none;
    }
`;
export const Day = styled.div`
    color: ${colors.primary100};
    margin-top: -5px;
    margin-bottom: 25px;
    @media screen and (max-width: 1220px) {
        display: none;
    }
    @media screen and (max-width: 1088px) {
        display: block;
    }
    @media screen and (max-width: 800px) {
        display: none;
    }
`;
export const Title = styled.div`
    color: ${colors.primary100};
    @media screen and (max-width: 460px) {
        font-size: 16px;
    }
`;
export const Expert = styled.a`
    margin-top: 15px;
    display: block;
    width: fit-content;
    color: ${colors.primary100};
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;
export const Cost = styled.div`
    color: ${colors.primary100};
    position: absolute;
    bottom: 30px;
    left: 30px;
    @media screen and (max-width: 1220px) {
        display: none;
    }
    @media screen and (max-width: 1088px) {
        display: block;
    }
    @media screen and (max-width: 800px) {
        display: none;
    }
`;
export const FillContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;
export const HeroVidBackground = styled.iframe`
    position: relative;
    width: 100%;
    max-height: 360px;
    border: none;
    height: 100%;
`;

export const HeroImgBackground = styled.div`
    background-image: url(${props => props.imgObj});
    position: relative;
    background-size: cover;
    background-position: center;
    height: 360px;
`;
export const Icon = styled.div`
    display: inline-block;
    vertical-align: middle;
    & svg {
        height: 16px;
        width: 16px;
    }
`;