import {axiosReq, axiosReqJSON} from "../../../common/utils/axios";

import {setCareCoaches, setCareCoachesFetching, setCareCoachingParentCategoriesFetching, setCareCoachingParentCategories, setCareCoachingSubCategories, setCareCoachingSubCategoriesFetching} from '../store/coaching';
import {devConsumerCoachesFromCategory, devConsumerCareParentCategories, devConsumerCareSubCategories} from '../static/devdata';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

export const tryGetAllCoachesFromCategory = (a) => {
    return async dispatch => {
        dispatch(setCareCoachesFetching(true));
        let url = 'apiv1/public/coach/get-all-coaches-from-category';
        if(a.wmcId !== null) {
            url = 'apiv1/consumer/coach/get-all-wmc-coaches-for-categories';
        }
        const coachesAll = await axiosReq(url, "POST", {expertCategoryIds: a.ids}, devConsumerCoachesFromCategory(false));
        dispatch(setCareCoachesFetching(false));
        if(coachesAll.hasOwnProperty("error")) {
            await dispatch(setCareCoaches([]));
            return [];
        } else {
            await dispatch(setCareCoaches(coachesAll));
            return coachesAll;
        }
    }
};

export const tryGetCoachingParentCategories = () => {
    return async dispatch => {
        dispatch(setCareCoachingParentCategoriesFetching(true));
        const categories = await axiosReq('apiv1/consumer/courses/get-parent-categories', "GET", {}, devConsumerCareParentCategories);
        // REPLACE_APIS_FOR_TODAY_SHOW
        //const categories = await axiosReqJSON(env.CONSUMER.API_RESPONSES.LEARN_CATEGORIES);
        await dispatch(setCareCoachingParentCategories(categories));
        dispatch(setCareCoachingParentCategoriesFetching(false));
        return categories;
    }
};

export const tryGetCoachingSubCategories = (id) => {
    return async dispatch => {
        dispatch(setCareCoachingSubCategoriesFetching(true));
        const categories = await axiosReq('apiv1/consumer/courses/get-child-categories/' + id, "GET", {}, devConsumerCareSubCategories);
        // REPLACE_APIS_FOR_TODAY_SHOW
        // const categories = await axiosReqJSON(env.CONSUMER.API_RESPONSES.COACH_CATEGORIES[id.toString()]);
        await dispatch(setCareCoachingSubCategories(categories.childCategories));
        dispatch(setCareCoachingSubCategoriesFetching(false));
        return categories.childCategories;
    }
};