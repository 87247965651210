import React from 'react';
import styled from 'styled-components';

/* Containers */
import Affirmations from './containers/affirmations';
import Announcements from './containers/announcements';
import WillowBlog from './containers/willowblog';

/* Navs */
import {PageContent, PageFull, PageRight} from "../navbars/components/navlayout";
import NavPrimaryAll from "../navbars/containers/primaryall";
import {PageTabs} from '../components/clientsdetail';
import MeetingReminders from './containers/meetingreminders';
import AnnouncementOneModal from './containers/announcementonemodal';
import Welcome from './containers/welcome';

export const PageActions = styled.div`
    position: absolute;
    width: 280px;
    left: calc(100% + 60px);
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 768px) {
        position: relative;
        left: 0px;
        top: 0px;
        display: block;
        width: 100%;
        margin-left: 0px;
    }
`;

const PR = styled(PageRight)`
  @media screen and (min-width: 1440px) {
    display: flex;
    justify-content: space-between;
    gap: 25px;
  }
`;

const Homepage = () => (
    <>
        <NavPrimaryAll />
        <PageFull>
            <PR>
                <Announcements />
                <MeetingReminders />
                {/*<PageTabs>*/}
                {/*    <PageActions>*/}

                {/*    </PageActions>*/}
                {/*    /!*<PageContent>*!/*/}
                {/*    /!*    /!* <Welcome />*!/*/}
                {/*    /!*    <br /> *!/*!/*/}
                {/*    /!*    /!*<Affirmations />*!/*!/*/}
                {/*    /!*    <br />*!/*/}
                {/*    /!*    /!*<WillowBlog />*!/*!/*/}
                {/*    /!*</PageContent>*!/*/}
                {/*</PageTabs>*/}
            </PR>
        </PageFull>
        <AnnouncementOneModal />
    </>
);

export default Homepage;
