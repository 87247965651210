import React from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components'

/* Navs */
import {PageContent, PageFull, PageRight} from "../navbars/components/navlayout";
import NavPrimaryAll from "../navbars/containers/primaryall";
import NavSecondaryTop from '../navbars/containers/secondarytop';

/* Containers */

/* Containers */
import ForumCategories from '../../consumer/forum/containers/forumcategories';
import ForumCategoryOne from '../../consumer/forum/containers/forumcategoryone';
import ForumPost from '../../consumer/forum/containers/forumpost';
import ForumPostCreate from '../../consumer/forum/containers/forumcreate';

const PageContentContainer = styled.div`
    width: 80%;
    margin: 0px auto 120px auto;
    padding: 60px 20px 0px 20px;
    @media screen and (max-width: 748px) {
        padding: 20px 20px 0px 20px;
        width: calc(100% - 40px);
    }
`;

const Forum = () => (
    <>
        <NavPrimaryAll />
        <PageFull>
            <PageContentContainer>
                    {location.pathname === "/community" && <ForumCategories/>}
                    {(location.pathname.includes("/community/category")) && <ForumCategoryOne/>}
                    {location.pathname === "/community/posts/post" && <ForumPost/>}
                    {location.pathname === "/community/posts/create" && <ForumPostCreate/>}
                    {/* {location.pathname === ("/community/posts/edit" || "/community/posts/delete") && <ForumPostEdit/>} */}
                </PageContentContainer>
        </PageFull>
    </>
);

export default withRouter(Forum);
