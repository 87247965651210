import React, {useState} from 'react';
import styled from 'styled-components';
import {withRouter, Link, useLocation} from 'react-router-dom';
import {connect, useDispatch} from "react-redux";

/* Components */
import {colors} from '../../../common/components/colors';
import {FontBody16, FontBody18, FontHeader12} from '../../../common/components/fonts';

/* Middleware */

/* Domain */
import getDomainEnv from '../../../domains/utils';
import {images} from "../../../common/components/images";
import {
    DropdownOptions,
    SignOut,
    Option,
    Background
} from "../../../enterprise/navbars/components/primarytop";
import {Privacy} from "../../../iframes/checkout/components/product";
import {tryLogout} from "../../../routes/middleware/auth";
import {setSupportOpen} from "../../../enterprise/support/store/support";

const env = getDomainEnv();

const NavSection = styled.div`
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;


const HomeButton = styled.img`
  margin-top: 7px;

`;

const Button = styled.div`
  ${FontBody18}{
    position: relative;
  }

${props => props.status !== 'active'} {
  ${HomeButton}{
    position: relative;
    color: ${colors.black};
    font-weight: bold;
    content: '';
    background-image: url("https://docs.trustwillow.com/page selected.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
  }
}
  ${props => props.status !== 'active'} {
    color: ${colors.black};
    font-weight: bold;
    ${FontBody18}::after {
      content: '';
      background-image: url("https://docs.trustwillow.com/page selected.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
  }

  ${props => props.status !== 'inactive'} {
    color: ${colors.black};

  }

  color: ${colors.black};
  padding-left: 10px;
  width: fit-content;
  cursor: pointer;
`;

const IconTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  width: fit-content;
`;
const SidebarIcon = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

const User = styled.div`
  display: inline-block;
  width: fit-content;
  padding: 18px 40px;
  border-left: 1px solid ${colors.border70};
  text-align: left;
  vertical-align: top;
  position: relative;
  margin-left: 57vw;
  @media screen and (max-width: 1550px) {
    margin-left: 53vw;
  }
  @media screen and (max-width: 1440px) {
    margin-left: 49vw;
  }
  @media screen and (max-width: 1330px) {
    margin-left: 44vw;
  }
  @media screen and (max-width: 1180px) {
    margin-left: 38vw;
  }
`;

const SideIconActiveOrNot = ({data, status}) => {
    if (status === "active") {
        return (<SidebarIcon src={data.navSideIconActive} alt={""}/>)
    } else {
        return (<SidebarIcon src={data.navSideIconInactive} alt={""}/>)
    }
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .profile {
    float: right;
  }

`;

const LinkSection = ({data, status}) => (
    <IconTextContainer>
        {/*<SideIconActiveOrNot data={data} status={status}/>*/}
        {/*<SidebarIcon src={data.navSideIconInactive} alt={""}/>*/}
        <Button status={status}>
                <FontBody18><span className={'dl'} >{data.label}</span></FontBody18>
        </Button>
    </IconTextContainer>
)



const UserDropdownV2 = styled.div`
  border: 1px solid ${colors.primary30};
  background-color: ${colors.white};
  border-radius: 4px;
  position: absolute;
  margin-top: 72px;
  top: 75px;
  width: 290px;
  right: 10px;
  z-index: 11;
  @media screen and (max-width: 1088px) {
    display: none;
  }
`;

const Nav = styled.div`
  display: flex;
  flex-direction: row;
`;

const ProfileFooter = styled.div`
  display: flex;
  flex-direction: row;justify-content: space-around;
  color: grey !important;
  a {
    color: grey !important;

  }
  
`;

class Profile extends React.Component {

    render() {
       return <div onClick={(e) => {  this.props.history.push("/settings"); this.props.history.go(1); console.log(this.props.history.location)}}><Option><FontBody16>Profile</FontBody16></Option></div>

    }
}

const ProfileWithRouter = withRouter(Profile);
function NavTopMenu() {
    const dispatch = useDispatch();
    const location = useLocation();
    const [dropDown, setDropDown] = useState(false);

    return (
        <Container>
            <Nav>
                {env.CONSUMER.NAV.nav.map((navSection, navSectionIndex) => (
                    <NavSection key={navSectionIndex}>
                        {navSection.map((d, index) => {
                            let status = "inactive";
                            if (location.pathname.split("/")[1] === d.href.split("/")[1]) {
                                status = "active"
                            }
                            if (d.showDesktop) {
                                if (d.href === "modalSupport") {
                                    return (
                                        <div key={index} onClick={() => dispatch(setSupportOpen(true, 1))} style={{cursor: "default"}}>
                                            <LinkSection data={d} status={status}/>
                                        </div>
                                    )
                                }
                                 if (d.href === "/home") {
                                    return (
                                        <Link key={index} to={d.href} style={{cursor: "default"}}>
                                            <IconTextContainer>

                                                <Button status={status} >
                                                    <HomeButton className={'h'} src={images.consumerHomeIcon}/>

                                                </Button>
                                            </IconTextContainer>
                                        </Link>
                                    )

                                }
                                else {
                                    return (
                                        <Link key={index} to={d.href} style={{cursor: "default"}}>
                                            <LinkSection data={d} status={status}/>
                                        </Link>
                                    )
                                }
                            }
                        })}
                    </NavSection>
                ))}
                <User onClick={() => setDropDown(!dropDown)}>
                    <div className={"profile"}><img src={images.consumerProfileIcon}/></div>
                </User>
                {dropDown &&
                    <>
                        <Background onClick={() => setDropDown(!dropDown)}/>
                        <UserDropdownV2>
                            <DropdownOptions>
                                {/*<ProfileWithRouter/>*/}
                                <Link to={"/settings"} onClick={() => setDropDown(!dropDown)}><Option><FontBody16>Profile</FontBody16></Option></Link>
                                <div onClick={() => {dispatch(setSupportOpen(true, 1));setDropDown(!dropDown)}}><Option><FontBody16>Support</FontBody16></Option></div>
                            </DropdownOptions>
                            <ProfileFooter>
                                <FontHeader12 onClick={() => {
                                    dispatch(tryLogout())
                                }}>Log out</FontHeader12>
                                {/*<a target={"_blank"} href={"https://" + env.URL.DOMAIN + "/privacy"}><FontHeader12>Privacy*/}
                                {/*     Policy</FontHeader12></a>*/}
                            </ProfileFooter>
                        </UserDropdownV2>
                    </>
                }
        </Nav>

</Container>
)

}

export default NavTopMenu



