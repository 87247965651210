import {axiosReq} from "../../../common/utils/axios";

/* Utils */
import {adjForTZNoConvert} from "../../../common/utils/timezone";

/* Store */
import {setWebinarsAll, setWebinarsAllFetching, setWebinarRegistering, setWebinarRegistrationFetching} from "../store/webinars";

/* Static */
import {devIframeWebinars} from "../static/devdata";

const sessionModify = (session, expert) => {
    return async dispatch => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let sessionDateTimesChange = {...session, "start": session.flashSaleStartDateTime, "end": session.flashSaleEndDateTime, "expert": expert};
        return {...sessionDateTimesChange, ...adjForTZNoConvert(sessionDateTimesChange, timeZone)}
    }
};

export const tryGetWebinarsAll = (userId) => {
    return async dispatch => {
        dispatch(setWebinarsAllFetching(true));
        const sessions = await axiosReq('apiv1/consumer/coach/'+userId+'/get-all-flash-sales', "GET", {}, devIframeWebinars);
        const sessionsModified = await Promise.all(sessions.flashSales.map(async a => {
            let modified = await dispatch(sessionModify(a, sessions.expert));
            return Promise.resolve(modified)
        }));
        dispatch(setWebinarsAll(sessionsModified));
        return dispatch(setWebinarsAllFetching(false));
    }
};

export const tryPostWebinarRegistration = (saleId) => {
    return async dispatch => {
        dispatch(setWebinarRegistering(true));
        const registration = await axiosReq('apiv1/consumer/purchase/flash-sale/register', "POST", {'saleId': saleId}, {});
        let registrationResult;
        if(registration.hasOwnProperty("error")) {
            registrationResult = registration;
        } else {
            registrationResult = {"error": null}
        }
        dispatch(setWebinarRegistering(false));
        return registrationResult;
    }
}

export const tryCheckWebinarRegistration = (saleId) => {
    return async dispatch => {
        dispatch(setWebinarRegistrationFetching(true));
        const registration = await axiosReq('apiv1/consumer/purchase/flash-sale/registered/' + saleId, "GET", {}, {});
        dispatch(setWebinarRegistrationFetching(false));
        return registration;
    }
    
}