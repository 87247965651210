
export const devJourneyToDoGetAvailability = [
    "2023-12-31T14:00:00.000Z",
    "2023-12-31T15:00:00.000Z",
    "2023-12-31T16:00:00.000Z",
    "2023-12-31T17:00:00.000Z",
    "2024-01-01T14:00:00.000Z",
    "2024-01-01T15:00:00.000Z",
    "2024-01-01T16:00:00.000Z",
    "2024-01-01T17:00:00.000Z",
    "2024-01-01T18:00:00.000Z",
    "2024-01-01T19:00:00.000Z",
    "2024-01-01T20:00:00.000Z",
    "2024-01-01T21:00:00.000Z",
    "2024-01-01T22:00:00.000Z",
    "2024-01-02T14:00:00.000Z",
    "2024-01-02T15:00:00.000Z",
    "2024-01-02T16:00:00.000Z",
    "2024-01-02T17:00:00.000Z",
    "2024-01-02T18:00:00.000Z",
    "2024-01-02T19:00:00.000Z",
    "2024-01-02T20:00:00.000Z",
    "2024-01-02T21:00:00.000Z",
    "2024-01-02T22:00:00.000Z",
    "2024-01-06T14:00:00.000Z",
    "2024-01-06T15:00:00.000Z",
    "2024-01-06T16:00:00.000Z",
    "2024-01-06T17:00:00.000Z",
    "2024-01-06T18:00:00.000Z",
    "2024-01-06T19:00:00.000Z",
    "2024-01-06T20:00:00.000Z",
    "2024-01-06T21:00:00.000Z",
    "2024-01-06T22:00:00.000Z",
    "2024-01-09T14:00:00.000Z",
    "2024-01-09T15:00:00.000Z",
    "2024-01-09T16:00:00.000Z",
    "2024-01-09T17:00:00.000Z",
    "2024-01-09T18:00:00.000Z",
    "2024-01-09T19:00:00.000Z",
    "2024-01-09T20:00:00.000Z",
    "2024-01-09T21:00:00.000Z",
    "2024-01-09T22:00:00.000Z",
    "2023-12-21T14:00:00.000Z",
    "2023-12-21T15:00:00.000Z",
    "2023-12-21T16:00:00.000Z",
    "2023-12-21T17:00:00.000Z",
    "2023-12-21T18:00:00.000Z",
    "2023-12-21T19:00:00.000Z",
    "2023-12-21T20:00:00.000Z",
    "2023-12-21T21:00:00.000Z",
    "2023-12-21T22:00:00.000Z",
    "2024-01-03T14:00:00.000Z",
    "2024-01-03T15:00:00.000Z",
    "2024-01-03T16:00:00.000Z",
    "2024-01-03T17:00:00.000Z",
    "2024-01-03T18:00:00.000Z",
    "2024-01-03T19:00:00.000Z",
    "2024-01-03T20:00:00.000Z",
    "2024-01-03T21:00:00.000Z",
    "2024-01-03T22:00:00.000Z",
    "2024-01-05T14:00:00.000Z",
    "2024-01-05T15:00:00.000Z",
    "2024-01-05T16:00:00.000Z",
    "2024-01-05T17:00:00.000Z",
    "2024-01-05T18:00:00.000Z",
    "2024-01-05T19:00:00.000Z",
    "2024-01-05T20:00:00.000Z",
    "2024-01-05T21:00:00.000Z",
    "2024-01-05T22:00:00.000Z",
    "2024-01-04T14:00:00.000Z",
    "2024-01-04T15:00:00.000Z",
    "2024-01-04T16:00:00.000Z",
    "2024-01-04T17:00:00.000Z",
    "2024-01-04T18:00:00.000Z",
    "2024-01-04T19:00:00.000Z",
    "2024-01-04T20:00:00.000Z",
    "2024-01-04T21:00:00.000Z",
    "2024-01-04T22:00:00.000Z",
    "2023-12-30T14:00:00.000Z",
    "2023-12-30T15:00:00.000Z",
    "2023-12-30T16:00:00.000Z",
    "2023-12-30T17:00:00.000Z",
    "2023-12-30T18:00:00.000Z",
    "2023-12-30T19:00:00.000Z",
    "2023-12-30T20:00:00.000Z",
    "2023-12-30T21:00:00.000Z",
    "2023-12-30T22:00:00.000Z",
    "2024-01-08T14:00:00.000Z",
    "2024-01-08T15:00:00.000Z",
    "2024-01-08T16:00:00.000Z",
    "2024-01-08T17:00:00.000Z",
    "2024-01-08T18:00:00.000Z",
    "2024-01-08T19:00:00.000Z",
    "2024-01-08T20:00:00.000Z",
    "2024-01-08T21:00:00.000Z",
    "2024-01-08T22:00:00.000Z",
    "2023-12-24T14:00:00.000Z",
    "2023-12-24T15:00:00.000Z",
    "2023-12-24T16:00:00.000Z",
    "2023-12-24T17:00:00.000Z",
    "2023-12-24T18:00:00.000Z",
    "2023-12-24T19:00:00.000Z",
    "2023-12-24T20:00:00.000Z",
    "2023-12-24T21:00:00.000Z",
    "2023-12-24T22:00:00.000Z",
    "2023-12-22T14:00:00.000Z",
    "2023-12-22T15:00:00.000Z",
    "2023-12-22T16:00:00.000Z",
    "2023-12-22T17:00:00.000Z",
    "2023-12-22T18:00:00.000Z",
    "2023-12-22T19:00:00.000Z",
    "2023-12-22T20:00:00.000Z",
    "2023-12-22T21:00:00.000Z",
    "2023-12-22T22:00:00.000Z",
    "2023-12-29T14:00:00.000Z",
    "2023-12-29T15:00:00.000Z",
    "2023-12-29T16:00:00.000Z",
    "2023-12-29T17:00:00.000Z",
    "2023-12-29T18:00:00.000Z",
    "2023-12-29T19:00:00.000Z",
    "2023-12-29T20:00:00.000Z",
    "2023-12-29T21:00:00.000Z",
    "2023-12-29T22:00:00.000Z",
    "2023-12-27T14:00:00.000Z",
    "2023-12-27T15:00:00.000Z",
    "2023-12-27T16:00:00.000Z",
    "2023-12-27T17:00:00.000Z",
    "2023-12-27T18:00:00.000Z",
    "2023-12-27T19:00:00.000Z",
    "2023-12-27T20:00:00.000Z",
    "2023-12-27T21:00:00.000Z",
    "2023-12-27T22:00:00.000Z",
    "2023-12-23T14:00:00.000Z",
    "2023-12-23T15:00:00.000Z",
    "2023-12-23T16:00:00.000Z",
    "2023-12-23T17:00:00.000Z",
    "2023-12-23T18:00:00.000Z",
    "2023-12-23T19:00:00.000Z",
    "2023-12-23T20:00:00.000Z",
    "2023-12-23T21:00:00.000Z",
    "2023-12-23T22:00:00.000Z",
    "2023-12-28T14:00:00.000Z",
    "2023-12-28T15:00:00.000Z",
    "2023-12-28T16:00:00.000Z",
    "2023-12-28T17:00:00.000Z",
    "2023-12-28T18:00:00.000Z",
    "2023-12-28T19:00:00.000Z",
    "2023-12-28T20:00:00.000Z",
    "2023-12-28T21:00:00.000Z",
    "2023-12-28T22:00:00.000Z",
    "2023-12-25T14:00:00.000Z",
    "2023-12-25T15:00:00.000Z",
    "2023-12-25T16:00:00.000Z",
    "2023-12-25T17:00:00.000Z",
    "2023-12-25T18:00:00.000Z",
    "2023-12-25T19:00:00.000Z",
    "2023-12-25T20:00:00.000Z",
    "2023-12-25T21:00:00.000Z",
    "2023-12-25T22:00:00.000Z",
    "2023-12-26T14:00:00.000Z",
    "2023-12-26T15:00:00.000Z",
    "2023-12-26T16:00:00.000Z",
    "2023-12-26T17:00:00.000Z",
    "2023-12-26T18:00:00.000Z",
    "2023-12-26T19:00:00.000Z",
    "2023-12-26T20:00:00.000Z",
    "2023-12-26T21:00:00.000Z",
    "2023-12-26T22:00:00.000Z",
    "2024-01-07T14:00:00.000Z",
    "2024-01-07T15:00:00.000Z",
    "2024-01-07T16:00:00.000Z",
    "2024-01-07T17:00:00.000Z",
    "2024-01-07T18:00:00.000Z",
    "2024-01-07T19:00:00.000Z",
    "2024-01-07T20:00:00.000Z",
    "2024-01-07T21:00:00.000Z",
    "2024-01-07T22:00:00.000Z"
];