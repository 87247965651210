import React from 'react';
import moment from "moment-timezone";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import styled from "styled-components";

/* Components */
import { Flex, ListImage, ListTime, ListTitle, PageTitle, Extra54px, PageSubtitle, SwiperContainer, SlideContainer, SlideImage, SlideImageOverlay, ProgramTextContainer, ProgramTitle, ProgramTime } from '../components/silder';
import { LogoLoading } from "../../../common/components/loading";
import {FontTitle16, FontBody16} from "../../../common/components/fonts";
import {ButtonPrimary} from "../../../common/components/buttons";
import {colors} from "../../../common/components/colors";

/* Middleware */
import { tryGetUpcomingGroupPrograms, tryGetUpcomingGroupProgramsWMC } from '../middleware/programs'

/* Store */
import {setGroupProgramsUpcomingEmpty} from "../store/programs";

const SpaceBetween = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 20px;
    margin-top: 27px;
    color: ${colors.secondary100};
    ${props => props.direction !== "column"} {
        flex-direction: column;
    }
    ${props => props.direction !== "row"} {

    }
`;
const WriteToUsBtn = styled.div`
    min-width: 138px;
`;
const TextCenter = styled(FontBody16)`
    text-align: center;
    margin-bottom: 30px;
`;


class GroupPrograms extends React.Component {
    static defaultProps = {
        urlRequest: "/programs/request",
        entryPoint: "consumer",
        onOpenProgram: () => {}
    };

    state = {
        "isLoading": true,
        "groupPrograms": []
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.props.setGroupProgramsUpcomingEmpty();
        await this.props.tryGetUpcomingGroupPrograms();
        if(this.props.wmcId !== null && this.props.entryPoint === "consumer") {
            await this.props.tryGetUpcomingGroupProgramsWMC(this.props.wmcId);
        }
        this.setState({
            'groupPrograms': this.props.groupPrograms.filter(f => {return(f.get("isActive") && ((this.props.entryPoint !== "consumer" && f.get("isAssetManagement")) || (this.props.entryPoint === "consumer" && !f.get("isAssetManagement"))))}),
            'isLoading': false
        })
    };

    render() {
        if (this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <>
                    <Extra54px />
                    <PageTitle>GROUP PROGRAMS</PageTitle>
                    <PageSubtitle>Here is a calendar of upcoming programs you can enroll in. All programs are live and take place by remote video.</PageSubtitle>
                    {this.state.groupPrograms.size === 0 &&
                        <TextCenter>No available programs</TextCenter>
                    }
                    {this.state.groupPrograms.size > 0 &&
                        <>
                            {this.state.groupPrograms.map((program, index) => (
                                <Flex key={index} onClick={() => this.props.onOpenProgram(program.get("id"), program.get("title"))}>
                                    <ListImage src={program.get('imageUrl')} alt={""} />
                                    <div>
                                        <ListTitle>{program.get('title')}</ListTitle>
                                        <ListTime>{moment(program.get('startsOn')).format('ddd, MMMM Do YYYY').toString()}</ListTime>
                                    </div>
                                </Flex>
                            ))}
                        </>
                    }
                    {/*{this.state.groupPrograms.size > 0 &&*/}
                    {/*    <SwiperContainer>*/}
                    {/*        <Swiper*/}
                    {/*            className="mySwiper"*/}
                    {/*            noSwiping={false}*/}
                    {/*            slidesPerView={"auto"}*/}
                    {/*            loop={true}*/}
                    {/*            grabCursor={true}*/}
                    {/*            centeredSlides={true}*/}
                    {/*        >*/}
                    {/*            {this.state.groupPrograms.map((program, index) => (*/}
                    {/*                <SwiperSlide key={index} className="swiperslide" onClick={() => this.props.onOpenProgram(program.get("id"), program.get("title"))}>*/}
                    {/*                    {({ isActive, isPrev, isNext }) => (*/}
                    {/*                        <SlideContainer active={isActive} isPrev={isPrev} isNext={isNext}>*/}
                    {/*                            <SlideImage src={program.get('imageUrl')} alt={""} />*/}
                    {/*                            <SlideImageOverlay>*/}
                    {/*                                <ProgramTextContainer>*/}
                    {/*                                    <ProgramTitle>{program.get('title')}</ProgramTitle>*/}
                    {/*                                    <ProgramTime>{moment(program.get('startsOn')).format('ddd, MMMM Do YYYY').toString()}</ProgramTime>*/}
                    {/*                                </ProgramTextContainer>*/}
                    {/*                            </SlideImageOverlay>*/}
                    {/*                        </SlideContainer>*/}
                    {/*                    )}*/}
                    {/*                </SwiperSlide>*/}
                    {/*            ))}*/}
                    {/*        </Swiper>*/}
                    {/*    </SwiperContainer>*/}
                    {/*}*/}
                    {this.props.entryPoint === "consumer" &&
                        <SpaceBetween direction={"row"}>
                            <FontTitle16>Looking for another program?</FontTitle16>
                            <WriteToUsBtn onClick={() => {return this.props.history.push(this.props.urlRequest)}}>
                                <ButtonPrimary submit={false} label={"Write to us"} />
                            </WriteToUsBtn>
                        </SpaceBetween>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    groupPrograms: state.iframe.programs.programs.get("groupPrograms"),
    wmcId: state.common.user.get("prospectWmcId")
});

const mapDispatchToProps = dispatch => ({
    setGroupProgramsUpcomingEmpty: () => dispatch(setGroupProgramsUpcomingEmpty()),
    tryGetUpcomingGroupPrograms: () => dispatch(tryGetUpcomingGroupPrograms()),
    tryGetUpcomingGroupProgramsWMC: (wmcId) => dispatch(tryGetUpcomingGroupProgramsWMC(wmcId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GroupPrograms));
