import React, {useEffect, useState} from "react";
import {ActionBox, InlineItemLeft} from "../components/clientsdetail";
import {FontHeader16, FontTitle18} from "../../common/components/fonts";
import {Center} from "../components/contactcoaching";
import LazyLoad from "react-lazyload";
import {LogoLoadingSmall} from "../../common/components/loading";
import {ButtonPrimary} from "../../common/components/buttons";
import {useDispatch, useSelector} from "react-redux";
import InstructionsFlow from "./instructionsflow";
import {tryGetClientSessions} from "../meetings/middleware/sessions";
import styled from "styled-components";

const StyledActionBox = styled(ActionBox)`

  background-color: white !important;

`;
export default function SessionsBox() {
    const dispatch = useDispatch();
    const [meetings, setMeetings] = useState([]);
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(0);
    const clientID = useSelector(state => state.enterprise.clientsDetail.get("clientId") || state.enterprise.clientsDetail.get("client").get("prospectId"))
    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const conferences = await dispatch(tryGetClientSessions(clientID));
        setMeetings(conferences)
    }

    const handleOpen = ({id}) => {
        setId(id);
        setOpen(!open);
    }
    return (<StyledActionBox>
            <div>
                <InlineItemLeft><FontTitle18>Marketing Client Assessment</FontTitle18></InlineItemLeft>
            </div>
            <br/>
            <div className={'meeting-box'}>
                {meetings.length === 0 ? <>
                    <Center><FontHeader16>{"No meetings"}</FontHeader16></Center>
                </ > : <>
                    {meetings.map((a, aIndex) => (

                        <div onClick={() => handleOpen(a.id)} key={aIndex}><ButtonPrimary label={open ? 'X' : 'Start'}/>
                        </div>

                    ))}
                </>}
                {open && <InstructionsFlow pageNum={1} clientId={id}/>}
            </div>
        </StyledActionBox>);
}
