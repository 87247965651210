import { combineReducers } from 'redux';

/* Common */
import messagesByTokenReducer from "./store/messagesbytoken";

const smsTokenReducer = combineReducers({
    messages: messagesByTokenReducer
});

export default smsTokenReducer;
