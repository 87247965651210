const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {resolve(reader.result);};
    reader.onerror = error => reject(error);
});

export const testPhoto = async (photo, photoChanged) => {
    if(photoChanged) {return await toBase64(photo)} else {return null}
};

export const removeOldImageUrl = async (obj, keys) => {
    const target = {};
    for (const i in obj) {
        if (keys.indexOf(i) >= 0) continue;
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
        target[i] = obj[i];
    }
    return target;
};
