import React, {useEffect, useState} from "react";
import { useSelector} from 'react-redux';
import styled from "styled-components";

/* Components */
import { colors } from "../../../../common/components/colors";
import {
    FontBody16,
    FontHeader18,
    FontBody18,
    FontTitle24,
    FontTitle34,
    FontBody14
} from "../../../../common/components/fonts";
import { ButtonPrimary, ButtonInactive, ButtonSecondary } from "../../../../common/components/buttons";
import { images } from "../../../../common/components/images";

const Option = styled.div`
  border-radius: 30px;
  box-shadow: 0 10px 25px 0 rgb(0 0 0 / 10%);
  padding: 30px 30px 50px 30px;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 33%;
  max-width: 350px;
  @media screen and (max-width: 1260px) {
    width: 100%;
  }
`;

const Plan = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

const PlanDetails = styled(FontHeader18)`
    margin-bottom: 20px;
`;

const List = styled.ul`
  margin: 0;
  padding: 15px 0 15px 18px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${colors.border100};
`;

const CertImage = styled.img`
height: 200px;
width: 200px;
align-self: center;
`;

const Price = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonWide = styled.div`
  & > div {
    width: calc(100% - 46px);
    text-align: center;
  }
`;

export const PopupOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
`;

export const PopupContent = styled.div`
    background: white;
    border-radius: 20px;
    width: 40%;
    height: fit-content;
    overflow-y: auto; 
    margin-left: 300px;
    padding: 30px;
  z-index: 10;
    @media screen and (max-width: 1089px) {
        width: 100%;
        height: 100%;
        margin-left: 0;
        border-radius: 0;
    }
`;

const ButtonDiv = styled.div`
    display: flex;
    width: fit-content;
`;

const ButtonSide = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 20px;
`;

export default function FirmPlanClientAcquisition() {
    // state = {
    //     "isLoading": true, "currentPlanId": null, "show": null, "modalOpen": false, "badActor": false
    // };
    const [modalOpen, setModalOpen] = useState(false)
    const userId = useSelector((state) => state.common.user.get("userId"));
    const [badActor, setBadActor] = useState(false)
    const badActorDate = useSelector((state) => state.common.user.get("badActorDate"));



    // componentDidMount() {
    //     return this.init();
    // }

    useEffect(() => {
        init()
    },[])

    const init = async () => {
        // const c = this.props.clientAcquisition
        // let show = null;
        // let badActor = false;
        //
        // if (c.status === true) {
        //     if (c.isExpired) {
        //         show = "expiredClient"
        //     }
        //     show = "activeClient"
        // }
        // if (c.status === false) {
        //     show = "buyClient"
        // }
        // if (this.props.badActorDate) {
        //     badActor = true;
        // }
        //
        // await this.setState({
        //     "show": show,
        //     "isLoading": false,
        //     "badActor": badActor,
        // });
        if (badActorDate){
            setBadActor(true)
        }
    };

    const purchaseHelper = async () => {
        setModalOpen(true)
        window.location.href = 'https://pay.trustwillow.com/b/7sI8xWbYOf2hcucdQY';
    }

        return (
            <Option>
                {/*<Filler />*/}
                <FontTitle24 style={{ textAlign: "center" }}>Client Acquisition</FontTitle24>
                <CertImage src={images.employersIcon} />

                <FontBody16>
                    Tomorrow<span>&#39;</span>s prospects<span>&#39;</span> first impression of you comes from your online presence. Willow establishes your digital presence and promotes your expertise in working with Tomorrow’s clients. We demonstrate your ability to understand their goals, reflect their values, and genuinely care about their well-being. </FontBody16>
                <Plan>
                    <PlanDetails>Details:</PlanDetails>
                    <List>
                        <li><FontBody16>Reach & Acquire Tomorrow's Clients</FontBody16></li>
                        <li><FontBody16>Dedicated Digital Marketing Team</FontBody16></li>
                        <li><FontBody16>Easily Integrates with Compliance</FontBody16></li>
                        <li><FontBody16>Monthly Review</FontBody16></li>
                    </List>
                </Plan>
                {/*{this.state.show === "buyClient" &&*/}
                    <>
                        <Price style={{display:'flex',flexDirection:'column', marginBottom:'1em'}}>
                            <FontHeader18 style={{textDecoration: 'line-through', color: colors.secondary30, display:'flex',flexDirection:'row'}}>$5200
                                <FontBody18>/yr</FontBody18>
                            </FontHeader18>

                            <FontHeader18 style={{ display:'flex',flexDirection:'row'}}>$2800
                                <FontBody18>/yr</FontBody18>
                                <FontBody14 style={{color: colors.secondary30, alignSelf: 'center'}}>&nbsp; [First year only]</FontBody14>

                            </FontHeader18>

                        </Price>

                        <ButtonWide onClick={() => setModalOpen(true)}>
                            <ButtonPrimary label={"Get Started"} canSubmit={false} />
                        </ButtonWide>
                        {modalOpen &&
                            <PopupOverlay>
                                <PopupContent>
                                    {badActor ?
                                    <>
                                        <FontTitle34>FOLLOW UP REQUIRED</FontTitle34>
                                        <br />
                                        <FontBody16>Contact customer support</FontBody16>
                                        <br />
                                        <ButtonDiv onClick={() => setModalOpen(false)}>
                                            <ButtonPrimary label={"Close"} canSubmit={false} />
                                        </ButtonDiv>
                                    </>
                                        : <>
                                        <FontBody16>Do you have anything to disclose?</FontBody16>
                                        <ButtonSide>
                                            <ButtonDiv onClick={() => setBadActor(true)}>
                                                <ButtonSecondary label={"Yes"} />
                                            </ButtonDiv>
                                            <ButtonDiv onClick={() => purchaseHelper()}>
                                                <ButtonPrimary label={"No"} />
                                            </ButtonDiv>
                                        </ButtonSide>
                                        </>
                                    }
                                </PopupContent>
                            </PopupOverlay>}
                    </>
                {/*}*/}
                {/*{this.state.show === "expiredClient" &&*/}
                {/*    <>*/}
                {/*        <Price><FontHeader18>$4,800</FontHeader18><FontBody18>/yr</FontBody18></Price>*/}
                {/*        <ButtonWide onClick={() => { window.location.href = 'https://pay.trustwillow.com/b/7sI8xWbYOf2hcucdQY?client_reference_id=' + this.props.userId }}>*/}
                {/*            <ButtonPrimary label={"Renew Client Acquisition"} canSubmit={false} />*/}
                {/*        </ButtonWide>*/}
                {/*        <FontBody16 style={{ color: colors.alert100 }}>Expired: {this.props.clientAcquisition.renewalDate}</FontBody16>*/}
                {/*    </>*/}
                {/*}*/}
                {/*{this.state.show === "activeClient" &&*/}
                {/*    <>*/}
                {/*        <ButtonWide>*/}
                {/*            <ButtonInactive label={"Active Plan"} canSubmit={false} />*/}
                {/*        </ButtonWide>*/}
                {/*        <FontBody16>Active through: {this.props.clientAcquisition.renewalDate}</FontBody16>*/}
                {/*    </>*/}
                {/*}*/}
            </Option>
        );
    }


// const mapStateToProps = state => ({
//     userId: state.common.user.get("userId"),
//     badActorDate: state.common.user.get("badActorDate"),
// });

// const mapDispatchToProps = dispatch => ({
// })

// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FirmPlanClientAcquisition));
