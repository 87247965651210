import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment/moment';

/* Middleware */
import {tryGetBillingHistory} from '../middleware/billing';

/* Components */
import {colors} from "../../../common/components/colors";

import {LogoLoading} from "../../../common/components/loading";

import {SettingsCard, Left2, Right2, CurrentPlanRow, NoActivePlans, FlexRowJCSB, Grid} from "../components/billing";

import {FontBody16, FontHeader21} from "../../../common/components/fonts";

class BillingHistory extends React.Component {
    state={"isLoading": true, "history": []};

    componentDidMount() {
        return this.init()
    }

    init = async () => {
        await this.props.tryGetBillingHistory();
        this.setState({
            "history": this.props.history.toJS(),
            "isLoading": false
        });
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <SettingsCard backgroundColor={colors.backgroundColor7} zIndex={'1'}>
                    <FontHeader21>Billing History</FontHeader21>
                    {this.state.history.length === 0 &&
                        <CurrentPlanRow>
                            <NoActivePlans>
                                <FontBody16>No past payments</FontBody16>
                            </NoActivePlans>
                        </CurrentPlanRow>
                    }
                    {this.state.history.map(h => (
                        <Grid key={h.productId}>
                            <FontBody16>{moment(h.purchasedOn).format('ll')}</FontBody16>
                            <FontBody16>${(parseInt(h.amountPaid)/100).toFixed(2)}</FontBody16>
                            <FontBody16>{h.productTitle}</FontBody16>
                        </Grid>
                    ))}
                </SettingsCard>
            )
        }
    }

}

const mapStateToProps = state => ({
    history: state.enterprise.billing.billing.get("history")
});

const mapDispatchToProps = dispatch => ({
    tryGetBillingHistory: () => dispatch(tryGetBillingHistory())
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingHistory);
