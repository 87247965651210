import React from 'react';
import styled from 'styled-components';
import {colors} from '../../../common/components/colors';
import {FontTitle18} from "../../../common/components/fonts";
import {LogoLoadingSmall} from "../../../common/components/loading";

export const ActionBox = styled.div`
    border: 1px solid ${colors.border100};
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: calc(100% - 42px);
    border-radius: 6px;
    margin-bottom: 15px;
`;
export const FitWidthButton = styled.div`
    & div {
        width: calc(100% - 46px);
    }
    & div div {
        width: 100%;
        text-align: center;
    }
`;

export const CoachingLoading = () => (
    <ActionBox>
        <FontTitle18>Coaching</FontTitle18>
        <br />
        <br />
        <LogoLoadingSmall />
        <br />
    </ActionBox>
);
