import { combineReducers } from 'redux';

/* Reducers */
import commentsReducer from './store/comments';

const commentReducer = combineReducers({
    comments: commentsReducer,
});

export default commentReducer;
