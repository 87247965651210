import React from 'react';

/* Containers */
import CurrentPlan from './currentplan';
import PaymentMethod from './paymentmethod';
import History from './history';
import Cancel from './cancel';

class BillingFlow extends React.Component {
    state={showCancel: false};

    componentWillUnmount() {
        this.setState({"showCancel": false})
    }

    showCancel = async (status) => {
        this.setState({"showCancel": status})
    };

    render() {
        if(this.state.showCancel) {
            return(
                <Cancel
                    showCancel={this.showCancel}
                />
            )
        } else {
            return(
                <>
                    <CurrentPlan
                        showCancel={this.showCancel}
                    />
                    <PaymentMethod />
                    <History />
                </>
            )
        }
    }

}

export default BillingFlow;
