import React from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

/* Components */
import {FontTitle38, FontBody16, FontSubtitle18} from '../../../common/components/fonts';
import {colors} from "../../../common/components/colors";
import {images} from '../../../common/components/images';
import {LogoLoading} from "../../../common/components/loading";
import {TopLogo} from "../components/match";

/* Containers */
import SignupFlow from '../../signup/containers/flow';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

const Container = styled.div`
    max-width: 500px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 100px auto 0px auto;
    @media screen and (max-width: 460px) {
        margin: 40px auto 0px auto;
    }
`;

const NavBar = styled.div`
    margin: 45px 0px 25px 0px;
    text-align: center;
`;
const NavBarOptions = styled.div`
    margin: 0px 0px 50px 0px;
    display: flex;
    flex-direction: row;
    gap: 0px;
`;
const NavOption = styled(FontSubtitle18)`
    display: inline-flex;
    ${props => props.active !== true} {
        border-bottom: 7px solid ${colors.action100};
    }
    ${props => props.active !== false} {
        border-bottom: 7px solid ${colors.backgroundChatBubble};
    }
    padding: 20px 0px;
    width: 100%;
    justify-content: center;
    cursor: pointer;
`;
const LoginLink = styled.div`
    color: ${colors.white};
    text-decoration: underline;
    display: inline-block;
    cursor: pointer;
`;


class IFrameSignupMatchGetStarted extends React.Component {
    state = {
        "isLoading": true,
        "hrCode": "",
        "firstName": "",
        "lastName": "",
        "email": "",
        "wmcEmployeeId": "",
        "partnerLogo": null,
        "page": "signup"
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const searchParams = await new URLSearchParams(window.location.search);
        const hrCode = searchParams.get("registrationCode");
        const firstName = searchParams.get("firstName");
        const lastName = searchParams.get("lastName");
        const email = searchParams.get("email");
        const wmcEmployeeId = searchParams.get("id");
        const partner = env.CONSUMER.SIGN_UP.MATCH_PUBLIC_PAGE.CLIENT_DATA.HR_CODES[hrCode]
        await this.setState({
            "hrCode": partner === undefined ? (hrCode !== null ? hrCode : "") : partner.registrationCode,
            "firstName": firstName !== null ? firstName : "",
            "lastName": lastName !== null ? lastName : "",
            "email": email !== null ? decodeURIComponent(email) : "",
            "wmcEmployeeId": wmcEmployeeId !== null ? wmcEmployeeId : "",
            "partnerLogo": partner === undefined ? null : images[partner.logo],
            "partnerUrl": partner === undefined ? null : partner.url,
            'isLoading': false
        });
    };

    home = () => window.open("https://"+env.URL.DOMAIN,"_blank");
    partnerHome = () => window.open(this.state.partnerUrl, "_blank");

    login = () => {this.setState({"page": "login"})}
    signup = () => {this.setState({"page": "signup"})}

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <Container>
                    <TopLogo
                        partnerLogo={this.state.partnerLogo}
                        onClickPartnerLogo={this.partnerHome}
                        onClickWillowLogo={this.home}
                    />
                    <NavBar>
                        <FontTitle38>Welcome to Willow!</FontTitle38>
                        <FontBody16>We look forward to supporting you along your life journey. Create a password to get started.</FontBody16>
                    </NavBar>
                    <NavBarOptions>
                        <NavOption active={this.state.page === "login"} onClick={this.login}>Sign in</NavOption>
                        <NavOption active={this.state.page === "signup"} onClick={this.signup}>Create account</NavOption>
                    </NavBarOptions>
                    {this.state.page === "signup" &&
                        <SignupFlow
                            signupSystem={"page"}
                            hrCode={this.state.hrCode}
                            firstName={this.state.firstName}
                            lastName={this.state.lastName}
                            email={this.state.email}
                            wmcEmployeeId={this.state.wmcEmployeeId}
                            signupPageOnSubmit={() => {this.props.history.push("/get-matched")}}
                            signupSubmitLabel={"Get Started"}
                            hidePrefilledInputs={false}
                            signUpErrorLogin={<LoginLink onClick={this.login}>Login</LoginLink>}
                        />
                    }
                    {this.state.page === "login" &&
                        <SignupFlow
                            loginSystem={"page"}
                            loginForgotPasswordLink={() => {this.props.history.push("/password-reset")}}
                        />
                    }
                </Container>
            )
        }
    }

}

export default withRouter(IFrameSignupMatchGetStarted);