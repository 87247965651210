import React from 'react';
import styled from 'styled-components';

/* Components */
import {colors} from './colors';
import {FontBody24, FontTitle20} from './fonts';
import {ButtonPrimary, ButtonTertiary} from './buttons';

const Modal = styled.div`
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    padding: 50px;
    border-radius: 30px;
    background-color: ${colors.white};
    -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
    max-width: 390px;
    width: calc(100% - 100px);
    z-index: 20;
    @media screen and (max-width: 748px) {
        max-width: calc(100% - 100px);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transform: translate(0%, 0%);
        border-radius: 0;
        overflow-y: auto;
    }
`;
const ConfirmActions = styled.div`
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;
const ConfirmLeft = styled.div`
    display: inline-block;
    vertical-align: top;
    text-align: center;
`;
const ConfirmRight = styled.div`
    display: inline-block;
    vertical-align: top;
    text-align: center;
`;
const Button = styled.div`
    width: fit-content;
    margin: 0 auto;
    div:first-of-type {
        min-width: 93px;
        padding-top: 7px;
        padding-bottom: 7px;
        font-size: 20px;
    }
`;
const Message = styled(FontBody24)`
    text-align: center;
`;
const Close = styled.div`
    width: fit-content;
    margin-left: auto;
    position: relative;
    height: 36px;
`;
const CloseIcon = styled(FontBody24)`
    padding: 10px;
    position: absolute;
    top: -25px;
    right: -10px;
    line-height: 24px;
    cursor: pointer;
`;


export const FirmModalResponsive = styled.div`
    & > div {
        max-width: 600px;
        @media screen and (max-width: 1440px) {
            left: 62%;
        }
        @media screen and (max-width: 1088px) {
            left: 50%;
        }
        @media screen and (max-width: 748px) {
            left: 0;
            max-width: calc(100% - 100px);
        }
    }
`;

export class FirmModal extends React.Component {
    static defaultProps = {
        message: "",
        confirmShow: true,
        confirmLabel: "Yes",
        confirmAction: () => {},
        confirmButtonType: ButtonTertiary,
        cancelShow: true,
        cancelLabel: "No",
        cancelAction: () => {},
        cancelButtonType: ButtonPrimary,
        closeShow: false,
        closeAction: () => {},
        modalStyle: {}
    }

    render() {
        const ConfirmButtonType = this.props.confirmButtonType;
        const CancelButtonType = this.props.cancelButtonType;
        return (
            <Modal style={this.props.modalStyle}>
                {this.props.closeShow && <Close onClick={this.props.closeAction}><CloseIcon>x</CloseIcon></Close>}
                <Message>{this.props.message}</Message>
                {(this.props.cancelShow || this.props.confirmShow) &&
                    <ConfirmActions>
                        {this.props.cancelShow &&
                            <ConfirmLeft>
                                <Button onClick={this.props.cancelAction}>
                                    <CancelButtonType label={this.props.cancelLabel} canSubmit={true}/>
                                </Button>
                            </ConfirmLeft>
                        }
                        {this.props.confirmShow &&
                            <ConfirmRight>
                                <Button onClick={this.props.confirmAction}>
                                    <ConfirmButtonType label={this.props.confirmLabel} canSubmit={true}/>
                                </Button>
                            </ConfirmRight>
                        }
                    </ConfirmActions>
                }
            </Modal>
        )
    }
}

export default FirmModal;
