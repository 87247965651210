import {images} from "../../../common/components/images";

export const willowCertifiedRenewal = (certType) => ({
    "id": certType+"_certification_session_renewal",
    "productId": certType+"_certification_session_renewal",
    "orderIndex": 0,
    "description": "",
    "numberOfSessions": 0,
    "amount": 400*100,
    "isPrivate": false,
    "planType": "one_time",
    "recurringInterval": null,
    "subscriptionDurationInMonths": null,
    "status": "Active",
    "product": {
        "id": certType+"_certification_session_renewal",
        "title": certType === "can"
            ? "CERTIFIED ADVISOR FOR NEXTGEN™ Renewal"
            : "CERTIFIED ADVISOR FOR WOMEN™ Renewal",
        "description": "1 year",
        "coverPhoto": certType === "CAN"
            ? images.nextGenBadge
            : images.womenBadge,
        "serviceType": "online",
        "termsOfUseUrl": "https://trustwillow.com/terms-of-use",
        "privacyPolicyUrl": "",
        "downloadUrl": "",
        "sessionLength": 60,
        "status": "Active",
        "expert": {
            "id": null,
            "fullName": "Willow",
            "firstName": "Willow",
            "lastName": "",
            "storeHandle": "",
            "publicHandle": "",
            "profilePhotoUrl": ""
        }
    }
});
