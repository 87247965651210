import {images} from '../../../common/components/images';

/* Middleware - tryAuth */
export const devIframeAuthData = {
    "email": "jason.aronson@gmail.com",
    "first": "Jason",
    "last": "Aronson",
    "subscription": true,
    "platform": "consumer", // coach, advisor, advisor_and_coach, consumer, enterprise
    "userId": 24324,
    "uid": "74d1a449-ba40-4ad0-9989-f148807f22ed",
    "onboardingCompleted": true,
    "phone": 1111111111,
    "newUser": false,
    "companyName": "Company Name, LLC",
    "photo": images.devDataTeamJasonAvatar,
    // "wmc": null,
    "wmc": {
        "id": 1,
        "address1": "",
        "address2": "",
        "city": "Boston",
        "companyLogoUrl": images.logo,
        "companyName": "Willow Network, Inc.",
        "contactPersonCellPhone": "",
        "contactPersonEmail": "",
        "contactPersonFirstName": "",
        "contactPersonLastName": "",
        "contactPersonWorkPhone": "",
        "email": "",
        "facebookUrl": "",
        "linkedinUrl": "",
        "phone": "",
        "state": "MA",
        "twitterUrl": "",
        "zipCode": ""
    },
    "referralType": "willow", // "wmc" or "willow" or "coach"
    "expertCategories": [{"categoryId": 1, "categoryName": "Financial Coach"}],
};

export const devIframeProfileData = {
    id: '32',

    firstName: "Jason",
    lastName: "Aronson",
    companyName: 'Coaching, LLC',
    crd: 'test 5',

    photo: images.devDataTeamJasonAvatar,
    introVideoUrl: 'https://www.youtube.com/embed/RZAF1DAqHPk',
    facebookUrl: 'https://facebook.com',
    linkedinUrl: 'https://linkedin.com',
    twitterUrl: 'https://twitter.com',
    address1: 'test 3',
    address2: 'test 6',
    city: 'Boston',
    state: 'MA',
    zipCode: '23443',
    bio: '<p>In an age of pandemic, what true value do any of our backgrounds hold? It’s not like we can look at precedents and say, people who have done ABC tend to be really successful at accomplishing XYZ in a pandemic. My expf</p>',

    // BEG COACH ONLY FIELDS
    endorsement1: 'Test 1',
    endorsement2: 'Test 2',
    endorsement3: null,
    freeTime: 'Test 7',
    whyBecameCoach: 'Test 2',
    coachPhilosophy: 'Test 3',
    highlights: 'Test 1',

    coachingCertification: null,
    coachingCertificationYear: null,

    // END COACH ONLY FIELDS

    bookingAvailability: 'available',

    screeningCallLength: 30,
    defaultCallLength: 30,

    advancedDegree: null,
    advancedDegreeCity: null,
    advancedDegreeInstitution: null,
    advancedDegreeState: null,
    advancedDegreeYear: null,

    otherEducation: null,

    expertCategories: [],

    profileActive: true,
    profileShowEmailer: true,
    profileShowScheduler: true,
    schedulerActive: true,
    schedulerHandle: "sampleadvisor",
    storeActive: true,
    storeHandle: "sampleadvisor",
    storePhoto: null,
    storeShowEmailer: true,
    storeShowScheduler: true,
    storeTitle: "",
    website: ""
};
