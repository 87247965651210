import React from 'react';
import styled from "styled-components";

/* Components */
import {FontBody18, FontBody24, FontTitle40} from "../../../common/components/fonts";
import {ButtonPrimary} from "../../../common/components/buttons";
import {images} from "../../../common/components/images";
import {colors} from "../../../common/components/colors";

/* Containers */
import SchedulerFlow from "../../../iframes/scheduler/containers/flow";

const FitContent = styled.div`
    width: fit-content;
    margin: 0 auto 20px;
`;
const Category = styled(FontBody24)`
    color: ${colors.primary100};
    margin: 10px auto 50px auto;
    text-align: center;
`;
export const Photo = styled.img`
    width: 220px;
    height: 220px;
    border-radius: 220px;
    object-fit: cover;
    object-position: 0 0;
    margin: 0 auto 60px auto;
`;
const Section = styled.div`
    padding: 45px 20px;
    overflow-y: auto;
    height: calc(100% - 80px);
    text-align: center;
`;
const Icon = styled.img`
    height: 19px;
    width: 19px;
    margin: 3px 0 -3px 0;
`;

const EmailLink = styled(FontBody18)`
    & > a {
    color: ${colors.primary100};
    text-align: center;
    text-decoration: underline;
    }
`;

class MemberByCategoryIdLeftBar extends React.Component {
    static defaultProps = {
        expert: {
            photo: "",
            first: "",
            last: "",
            email: "",
            coachId: null
        },
        title: "",
        scheduleMeeting: () => {}
    }

    state = {"page": 1}

    scheduleMeeting = () => {
        this.props.scheduleMeeting(this.props.expert.photo, this.props.expert.first, this.props.expert.last, this.props.expert.coachId)
    }

    showCalendar = () => this.setState({"page": 2})
    showProfile = () => this.setState({"page": 1})

    render() {
        if(this.state.page === 1) {
            return(
                <Section>
                    <Photo src={this.props.expert.photo} alt={""} />
                    <FontTitle40 spaced={false}>{this.props.expert.first} {this.props.expert.last}</FontTitle40>
                    <Category>{this.props.title}</Category>
                    <FitContent onClick={this.showCalendar}>
                        <ButtonPrimary canSubmit={false} label={<><Icon src={images.firmIconCalendarWhite} alt={""} />&nbsp;Schedule Meeting</>} />
                    </FitContent>
                    <EmailLink><a href="mailto: {this.props.expert.email}">{this.props.expert.email}</a></EmailLink>
                </Section>
            )
        } else {
            return(
                <Section>
                    <SchedulerFlow
                        userId={this.props.expert.coachId}
                        userFirstName={this.props.expert.first}
                        userLastName={this.props.expert.last}
                        userPhotoUrl={this.props.expert.photo}
                        eventCustomMinutes={30}
                        confirmOnClose={this.showProfile}
                        useNewDesign={true}
                    />
                </Section>
            )
        }
    }
}

export default MemberByCategoryIdLeftBar;
