import React from 'react';
import styled from "styled-components";

/* Components */
import {colors} from "../../../common/components/colors";

/* Containers */
import ChangePassword from '../../../iframes/changepassword/containers/changepassword';

const SettingsCard = styled.div`
    background-color: ${colors.backgroundColor1};
    border-radius: 20px;
    padding: 20px 30px 30px 30px;
    max-width: 450px;
    width: calc(100% - 60px);
    margin: 0px auto 0px auto;
    & input {
        border-radius: 20px;
        background-color: ${colors.white};
        border: none;
        line-height: 25px;
    }
    & .Dropdown-control {
        border-radius: 20px;
        background-color: ${colors.white};
        border: none;
    }
    & .Dropdown-menu {
        background-color: ${colors.white};
        border: 1px solid ${colors.backgroundColor3};
        border-radius: 6px;
    }
    & form > div > div {
        color: ${colors.primary100};
    }
    & Button {
        margin: 20px 0px 0px auto;
    }
    & > div {
        margin-bottom: -20px;
    }
`;

class SettingsSecurity extends React.Component {
    render() {
        return(
            <SettingsCard>
                <ChangePassword
                    title={"Reset Password"}
                    isInsideCard={true}
                    hasBack={false}
                />
            </SettingsCard>
        )
    }
}

export default SettingsSecurity;
