import { combineReducers } from 'redux';

/* Reducers */
import messagesReducer from './store/messages';

const enailReducer = combineReducers({
    messages: messagesReducer,
});

export default enailReducer;
