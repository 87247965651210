import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';

/* Middleware */
import {tryGetSettingsEnterpriseClients, tryUpdateSettingsEnterpriseClients} from '../middleware/settingsclients';
import {testCheckbox} from "../utils/coachingmatches";

/* Components */
import {SavedSettings, SettingsCard, SettingsCardTitle} from "../../../common/components/cards";
import {TableWrapper} from "../../../common/components/table";
import {SubmitInput} from "../../../common/components/inputs";
import {colors} from "../../../common/components/colors";
import {FontTitle18} from "../../../common/components/fonts";
import {InlineBlock, SaveSection, Width, SubmitInputWithValidation} from "../../components/clientscreate";

/* Table data format - journey + goals */
const order = ["question", "specialist", "comfortable", "personal", "exclude"];
const title = ["", "I'm a specialist", "I'm comfortable discussing", "I have personal experience", "Don't send me these clients"];
const styles = [{}, {}, {}, {}, {}];
const format = [{element: "text", href: null, ifNull: null}, {element: "checkbox", href: null, ifNull: null}, {element: "checkbox", href: null, ifNull: null}, {element: "checkbox", href: null, ifNull: null}, {element: "checkbox", href: null, ifNull: null}];
const actionsJourney = (actions) => ([
    {action: null, passToAction: null, extra: null},
    {action: actions.changeJourney, passToAction: null, extra: null},
    {action: actions.changeJourney, passToAction: null, extra: null},
    {action: actions.changeJourney, passToAction: null, extra: null},
    {action: actions.changeJourney, passToAction: null, extra: null}
]);
const actionsGoals = (actions) => ([
    {action: null, passToAction: null, extra: null},
    {action: actions.changeGoal, passToAction: null, extra: null},
    {action: actions.changeGoal, passToAction: null, extra: null},
    {action: actions.changeGoal, passToAction: null, extra: null},
    {action: actions.changeGoal, passToAction: null, extra: null}
]);

/* Table data format - approach */
const order2 = ["question", "resonates", "exclude"];
const title2 = ["", "This resonates with me", "Don't send me these clients"];
const styles2 = [{}, {}, {}];
const format2 = [{element: "text", href: null, ifNull: null}, {element: "checkbox", href: null, ifNull: null}, {element: "checkbox", href: null, ifNull: null}];
const actions2 = (actions) => ([
    {action: null, passToAction: null, extra: null},
    {action: actions.changeApproach, passToAction: null, extra: null},
    {action: actions.changeApproach, passToAction: null, extra: null},
    {action: actions.changeApproach, passToAction: null, extra: null},
    {action: actions.changeApproach, passToAction: null, extra: null}
]);

const Left = styled.div`
    & > div {
        margin-top: -51px;
    }
`;

class SettingsClients extends React.Component {
    state={
        "journey": [
            {"id": 1, "question": "Going through a divorce", "specialist": false, "comfortable": false, "personal": false, "exclude": false},
            {"id": 2, "question": "A widow", "specialist": false, "comfortable": false, "personal": false, "exclude": false},
            {"id": 3, "question": "Caregiver for aging loved ones", "specialist": false, "comfortable": false, "personal": false, "exclude": false},
            {"id": 4, "question": "In charge of my family finances", "specialist": false, "comfortable": false, "personal": false, "exclude": false},
            {"id": 5, "question": "A single parent", "specialist": false, "comfortable": false, "personal": false, "exclude": false},
            {"id": 6, "question": "Navigating my career", "specialist": false, "comfortable": false, "personal": false, "exclude": false},
            {"id": 7, "question": "Starting out financially", "specialist": false, "comfortable": false, "personal": false, "exclude": false}
        ],
        "goal": [
            {"id": 1, "question": "Organizing my finances", "specialist": false, "comfortable": false, "personal": false, "exclude": false},
            {"id": 2, "question": "Budgeting and paying bills", "specialist": false, "comfortable": false, "personal": false, "exclude": false},
            {"id": 3, "question": "Improve my credit score", "specialist": false, "comfortable": false, "personal": false, "exclude": false},
            {"id": 4, "question": "Reducing debt", "specialist": false, "comfortable": false, "personal": false, "exclude": false},
            {"id": 5, "question": "Easing financial stress and anxiety", "specialist": false, "comfortable": false, "personal": false, "exclude": false},
            {"id": 6, "question": "Planning for after I'm gone", "specialist": false, "comfortable": false, "personal": false, "exclude": false},
            {"id": 7, "question": "Workplace benefits and insurance", "specialist": false, "comfortable": false, "personal": false, "exclude": false},
            {"id": 8, "question": "Saving for education and future expenses", "specialist": false, "comfortable": false, "personal": false, "exclude": false},
            {"id": 9, "question": "Improving or changing my career", "specialist": false, "comfortable": false, "personal": false, "exclude": false},
            {"id": 10, "question": "Considering a real estate purchase or rental", "specialist": false, "comfortable": false, "personal": false, "exclude": false},
            {"id": 11, "question": "Changing finances in a relationship", "specialist": false, "comfortable": false, "personal": false, "exclude": false}
        ],
        "approach": [
            {"id": 1, "question": "Understands the life of a military family", "resonates": false, "exclude": false},
            {"id": 2, "question": "Integrates spirituality into coaching", "resonates": false, "exclude": false},
            {"id": 3, "question": "Has a no-nonsense approach", "resonates": false, "exclude": false},
            {"id": 4, "question": "Knows what its like to be part of a minority community", "resonates": false, "exclude": false},
            {"id": 5, "question": "Emphasizes the emotional aspects of personal finance", "resonates": false, "exclude": false}
        ],
        "isSavingJourney": false, "isSavedJourney": false,
        "isSavingGoal": false, "isSavedGoal": false,
        "isSavingApproach": false, "isSavedApproach": false,
        "errorJourney": '',
        "errorGoal": '',
        "errorApproach": '',
        'isChangedJourney': false,
        'isChangedGoal': false,
        'isChangedApproach': false
    };

    componentDidMount() {
        Promise.resolve(this.props.tryGetSettingsEnterpriseClients())
            .then(async () => {
                await this.setState(this.props.settingsEnterpriseClients.toJS());
            })
    }
    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    handleChangeJourney = async (e, question, status) => {
        await this.setState({journey: testCheckbox(this.state.journey, e, question, status)});
        this.setState({'isChangedJourney': true});
    };
    handleChangeGoal = async (e, question, status) => {
        await this.setState({goal: testCheckbox(this.state.goal, e, question, status)});
        this.setState({'isChangedGoal': true});
    };
    handleChangeApproach = async (e, question, status) => {
        await this.setState({
            approach: this.state.approach.map(j => {
                if(e === j.id) {
                    let resonates = j.resonates;
                    let exclude = j.exclude;
                    if(question === "resonates" && status === true) {exclude = false;}
                    if(question === "exclude" && status === true) {resonates = false;}
                    return({
                        "id": j.id,
                        "question": j.question,
                        "resonates": (question==="resonates") ? status : resonates,
                        "exclude": (question==="exclude") ? status : exclude
                    });
                } else {return j;}
            })
        });
        this.setState({'isChangedApproach': true});
    };

    handleSubmitJourney = e => {
        e.preventDefault();
        this.setState({'isSavingJourney': true, 'errorJourney': ''});
        Promise.resolve(this.props.tryUpdateSettingsEnterpriseClients(this.state, "journey"))
            .then(() => {
                if(this.props.settingsEnterpriseClients.get("errorJourney") !== '') {
                    this.setState(this.props.settingsEnterpriseClients.toJS());
                }
                this.setState({'isSavingJourney': false, 'isSavedJourney': true, 'isChangedJourney': false});
                this.timeout = setTimeout(() => {this.setState({'isSavedJourney': false})}, 3000);
            })
    };
    handleSubmitGoal = e => {
        e.preventDefault();
        this.setState({'isSavingGoal': true, 'errorGoal': ''});
        Promise.resolve(this.props.tryUpdateSettingsEnterpriseClients(this.state, 'goal'))
            .then(() => {
                if(this.props.settingsEnterpriseClients.get("errorGoal") !== '') {
                    this.setState(this.props.settingsEnterpriseClients.toJS());
                }
                this.setState({'isSavingGoal': false, 'isSavedGoal': true, 'isChangedGoal': false});
                this.timeout = setTimeout(() => {this.setState({'isSavedGoal': false})}, 3000);
            })
    };
    handleSubmitApproach = e => {
        e.preventDefault();
        this.setState({'isSavingApproach': true, 'errorApproach': ''});
        Promise.resolve(this.props.tryUpdateSettingsEnterpriseClients(this.state, 'approach'))
            .then(() => {
                if(this.props.settingsEnterpriseClients.get("errorApproach") !== '') {
                    this.setState(this.props.settingsEnterpriseClients.toJS());
                }
                this.setState({'isSavingApproach': false, 'isSavedApproach': true, 'isChangedApproach': false});
                this.timeout = setTimeout(() => {this.setState({'isSavedApproach': false})}, 3000);
            })
    };

    render() {
        return(
            <>
                <>
                {/*<SettingsCard>*/}
                    <FontTitle18>Please review this list of journeys and share how they apply to you (required)</FontTitle18>
                    {this.state.isSavedJourney
                        ?
                            <>
                                {this.state.errorJourney === '' &&
                                    <SavedSettings height={"108px"} error={false} message={"Saved"}/>
                                }
                                {this.state.errorJourney !== '' &&
                                    <SavedSettings height={"108px"} error={true} message={this.state.errorJourney}/>
                                }
                            </>
                        :
                        <>
                            <form method={"post"} onSubmit={this.handleSubmitJourney}>
                                <TableWrapper responsive={true} title={""} tableData={{
                                    title: title,
                                    order: order,
                                    data: this.state.journey,
                                    styles: styles,
                                    format: format,
                                    actions: actionsJourney({changeJourney: this.handleChangeJourney})
                                }}/>
                                <Left>
                                    <SaveSection>
                                        <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChangedJourney} isValid={true} isSaving={this.state.isSavingJourney} /></InlineBlock>
                                    </SaveSection>
                                </Left>
                            </form>
                        </>
                    }
                {/*</SettingsCard>*/}
                </>
                <br />
                <br />
                <br />
                <br />
                <>
                    <FontTitle18>Please review this list of coaching goals and share how they apply to you (required)</FontTitle18>
                    {this.state.isSavedGoal
                        ?
                            <>
                                {this.state.errorGoal === '' &&
                                    <SavedSettings height={"108px"} error={false} message={"Saved"}/>
                                }
                                {this.state.errorGoal !== '' &&
                                    <SavedSettings height={"108px"} error={true} message={this.state.errorGoal}/>
                                }
                            </>
                        :
                        <>
                            <form method={"post"} onSubmit={this.handleSubmitGoal}>
                                <TableWrapper responsive={true} title={""} tableData={{
                                    title: title,
                                    data: this.state.goal,
                                    styles: styles,
                                    order: order,
                                    format: format,
                                    actions: actionsGoals({changeGoal: this.handleChangeGoal})
                                }}/>
                                <Left>
                                    <SaveSection>
                                        <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChangedGoal} isValid={true} isSaving={this.state.isSavingGoal} /></InlineBlock>
                                    </SaveSection>
                                </Left>
                            </form>
                        </>
                    }
                </>
                <br />
                <br />
                <br />
                <br />
                <>
                    <FontTitle18>Please review this list of approaches to coaching and show how they apply to you (optional)</FontTitle18>
                    {this.state.isSavedApproach
                        ?
                            <>
                                {this.state.errorApproach === '' &&
                                    <SavedSettings height={"108px"} error={false} message={"Saved"}/>
                                }
                                {this.state.errorApproach !== '' &&
                                    <SavedSettings height={"108px"} error={true} message={this.state.errorApproach}/>
                                }
                            </>
                        :
                        <>
                            <form method={"post"} onSubmit={this.handleSubmitApproach}>
                                <TableWrapper responsive={true} title={""} tableData={{
                                    title: title2,
                                    data: this.state.approach,
                                    styles: styles2,
                                    format: format2,
                                    order: order2,
                                    actions: actions2({changeApproach: this.handleChangeApproach})
                                }}/>
                                <Left>
                                    <SaveSection>
                                        <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChangedApproach} isValid={true} isSaving={this.state.isSavingApproach} /></InlineBlock>
                                    </SaveSection>
                                </Left>
                            </form>
                        </>
                    }
                </>
            </>
        )
    }

}

const mapStateToProps = state => ({
    settingsEnterpriseClients: state.enterprise.settings.get("settingsEnterpriseClients")
});

const mapDispatchToProps = dispatch => ({
    tryUpdateSettingsEnterpriseClients: (data, category) => dispatch(tryUpdateSettingsEnterpriseClients(data, category)),
    tryGetSettingsEnterpriseClients: () => dispatch(tryGetSettingsEnterpriseClients())
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsClients);
