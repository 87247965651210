import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";

/* Components */
import {colors} from '../../../common/components/colors';
import {FontHeader18, FontBody16} from "../../../common/components/fonts";
import {CloseOutlined, MailOutlined} from "@ant-design/icons";
import {LogoLoading} from "../../../common/components/loading";
import {FitWidthButton} from "../../team/components/teamhowitworks";

/* Middleware */
import {tryEmailWebinarParticipants, tryGetSessionLargeParticipants} from '../middleware/group';

/* Utils */
import {disableBodyScrolling, enableBodyScrolling} from '../../../common/utils/scrolling';
import { ButtonPrimary } from '../../../common/components/buttons';
import EmailerFlow from "./participantsemailer";

export const Close = styled.div`
    position: relative;
    cursor: pointer;
    width: 75px;
    color: ${colors.action100};
    @media screen and (max-width: 640px) {
        width: 20px;
    }
`;
const Background = styled.div`
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.2);
`;
const Modal = styled.div`
    position: relative;
    width: 488px;
    padding: 30px 40px;
    z-index: 11;
    background-color: ${colors.white};
    margin: 110px auto 0px auto;
    border-radius: 6px;
    @media screen and (max-width: 640px) {
        padding: 30px 20px;
        width: calc(100% - 40px);
        margin-top: 0px;
        border-radius: 0px;
    }
`;
const Left = styled.div`
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 75px);
    @media screen and (max-width: 640px) {
        width: calc(100% - 20px);
    }
`;
const Right = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 75px;
    @media screen and (max-width: 640px) {
        width: 20px;
    }
`;
const Text = styled.div`
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 640px) {
        display: none;
    }
`;
const Header = styled.div`
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.border100};
`;
const Row = styled.div`
    padding: 5px 0px;
`;
const Body = styled.div`
    overflow-y: auto;
    height: 440px;
    margin: 30px 0px 10px 0px;
    @media screen and (max-width: 640px) {
        height: calc(100vh - 120px);
    }
`;

class MeetingParticipants extends React.Component {
    state={"isLoading": true, "participants": [], 'emailerOpen': false, "error": null, "step": 1};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.props.tryGetSessionLargeParticipants(this.props.meetingId);
        disableBodyScrolling();
        await this.setState({
            "participants": this.props.participants.toJS(),
            "isLoading": false
        });
    };

    setEmailerOpen = async () => {
        this.setState({"emailerOpen": !this.state.emailerOpen})
    }

    componentWillUnmount() {
        enableBodyScrolling();
    }

    sendMessage = async (id, msg) => {
        const message = await this.props.tryEmailWebinarParticipants(id, msg)
        if (message.error === null) {
            this.setState({"step": 2})
        } else {
            this.setState({"error": message.error})
        }
    }

    render() {
        if(this.state.isLoading) {
            return(<LogoLoading />)
        } else {
            return(
                <Background>
                    <Modal>
                        <Header>
                            <Left>
                                <FontHeader18>Attendees</FontHeader18>
                            </Left>
                            <Right>
                                <Close onClick={() => {return this.props.onClose()}}>
                                    <FontHeader18><CloseOutlined /><Text>&nbsp;Close</Text></FontHeader18>
                                </Close>
                            </Right>
                        </Header>
                        {!this.state.emailerOpen ? 
                            <>
                                <Body>
                                    {this.state.participants.attendees.map((p, index) => (
                                        <Row key={index}>
                                            <FontBody16>{p.firstName + " " + p.lastName}</FontBody16>
                                        </Row>
                                    ))}
                                </Body>
                                <FitWidthButton>
                                    <div onClick={this.setEmailerOpen}>
                                        <ButtonPrimary submit={false} label={<><MailOutlined /> Email Participants</>} />
                                    </div>
                                </FitWidthButton>
                            </>
                        :
                        <>
                            <EmailerFlow 
                                id={this.props.webinarId}
                                inModal={true}
                                back={() => {return this.setEmailerOpen()}}
                                sendMessage={(id, msg) => {return this.sendMessage(id, msg)}}
                                error={this.state.error}
                                step={this.state.step}
                            />
                        </>
                        }
                    </Modal>
                </Background>
            )
        }
    }
}

const mapStateToProps = state => ({
    participants: state.enterprise.meetings.group.get("groupSessionParticipants"),
    webinarId: state.enterprise.meetings.group.get("groupSessionOne").get("id")

});

const mapDispatchToProps = dispatch => ({
    tryGetSessionLargeParticipants: (meetingId) => dispatch(tryGetSessionLargeParticipants(meetingId)),
    tryEmailWebinarParticipants: (webinarId, message) => dispatch(tryEmailWebinarParticipants(webinarId, message))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MeetingParticipants));