import React from 'react';
import { connect } from "react-redux";
import {withRouter} from 'react-router-dom';
import styled from "styled-components";

/* Containers */
import Team from './containers/teamall';
import TeamOne from "./containers/teamone";
import SupportTeam from "./containers/support";
import CoachingTeam from "./containers/coachingteam";
import SupportTeamOne from "./containers/supportteamone";
import CoachingTeamOne from "./containers/coachingteamone";
import ClientsList from "./containers/clientslist";
import SupportTeamList from "./containers/supportteamlist";
import Announcements from '../team/containers/announcements';
import AnnouncementModal from '../team/containers/announcementmodal';

/* Navs */
import {PageContent, PageFull, PageRight} from "../navbars/components/navlayout";
import {PageTabs} from '../components/clientsdetail';
import NavPrimaryAll from "../navbars/containers/primaryall";
import NavSecondaryTop from '../navbars/containers/secondarytop';

/* Components */
import {ArrowLeftOutlined} from "@ant-design/icons";
import {colors} from "../../common/components/colors";

const BackTop = styled.div`
    width: fit-content;
    color: ${colors.secondary70};
    cursor: pointer;
    position: relative;
    z-index: 2;
    text-align: left;
    display: block;
    padding-bottom: 20px;
    @media screen and (max-width: 748px) {
        margin-left: 60px;
    }
    @media screen and (max-width: 460px) {
        margin-left: 0px;
    }
`;
const Image = styled.img`
    max-width: 100px;
`;
export const PageActions = styled.div`
    position: absolute;
    width: 280px;
    left: calc(100% + 60px);
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 768px) {
        position: relative;
        left: 0px;
        top: 0px;
        display: block;
        width: 100%;
        margin-left: 0px;
    }
`;

const MyPartners = ({ wmc, wmcAdvisor, coach, location, history }) => (
    <>
        <NavPrimaryAll />
        <PageFull>
            <PageRight>

                {location.pathname === "/partners" &&
                    <>
                        <NavSecondaryTop title={"My Partners"} desc={"View your partnered advisors and assigned support teams"}/>
                        <PageContent>
                            <Team type={"wmc"}/>
                        </PageContent>
                    </>
                }

                {location.pathname === "/partners/employees" &&
                    <>
                        <BackTop onClick={() => {return history.goBack()}}><ArrowLeftOutlined /> Back</BackTop>
                        <Image src={wmc.get("companyLogoUrl")} alt={""} />
                        <NavSecondaryTop title={"Firm Employees"} />
                        <PageContent>
                            <Team type={"partners"}/>
                        </PageContent>
                    </>
                }

                {location.pathname === "/partners/employees/clients" &&
                    <>
                        {console.log(wmcAdvisor)}
                        <BackTop onClick={() => {return history.goBack()}}><ArrowLeftOutlined /> Back</BackTop>
                        <Image src={wmc.get("companyLogoUrl")} alt={""} />
                        <NavSecondaryTop title={"Clients Assigned By " + wmcAdvisor.get("first") + " " + wmcAdvisor.get("last")} />
                        <PageContent>
                            <ClientsList entry={"coach"}/>
                        </PageContent>
                    </>
                }

                {location.pathname === "/partners/employees/member" &&
                    <>
                        <BackTop onClick={() => {return history.goBack()}}><ArrowLeftOutlined /> Back</BackTop>
                        <Image src={wmc.get("companyLogoUrl")} alt={""} />
                        <NavSecondaryTop title={"Firm Employee"} />
                        <PageContent>
                            <TeamOne />
                        </PageContent>
                    </>
                }

                {location.pathname === "/partners/support" &&
                    <>
                        <BackTop onClick={() => {return history.goBack()}}><ArrowLeftOutlined /> Back</BackTop>
                        <Image src={wmc.get("companyLogoUrl")} alt={""} />
                        <NavSecondaryTop title={"Support Team"} />
                        <PageContent>
                            <SupportTeam />
                        </PageContent>
                    </>
                }

                {location.pathname === "/partners/support/member" &&
                    <>
                        <BackTop onClick={() => {return history.goBack()}}><ArrowLeftOutlined /> Back</BackTop>
                        <Image src={wmc.get("companyLogoUrl")} alt={""} />
                        <NavSecondaryTop title={"Support Member"} />
                        <PageContent>
                            <SupportTeamOne />
                        </PageContent>
                    </>
                }

                {location.pathname === "/firms" &&
                    <>
                        <NavSecondaryTop title={"My Firms"} desc={"View your assigned firms"}/>
                        <PageTabs>
                            <PageActions>
                                <Announcements />
                            </PageActions>
                            <PageContent>
                                <Team type={"wmc"}/>
                            </PageContent>
                        </PageTabs> 
                    </>
                }

                {location.pathname === "/firms/employees" &&
                    <>
                        <BackTop onClick={() => {return history.goBack()}}><ArrowLeftOutlined /> Back</BackTop>
                        <Image src={wmc.get("companyLogoUrl")} alt={""} />
                        <NavSecondaryTop title={"Firm Employees"} />
                        <PageContent>
                            <Team type={"partners"} advising={"wmc"}/>
                        </PageContent>
                    </>
                }

                {location.pathname === "/firms/experts" &&
                    <>
                        <BackTop onClick={() => {return history.goBack()}}><ArrowLeftOutlined /> Back</BackTop>
                        <Image src={wmc.get("companyLogoUrl")} alt={""} />
                        <NavSecondaryTop title={"Willow Experts"} />
                        <PageContent>
                            <CoachingTeam />
                        </PageContent>
                    </>
                }

                {location.pathname === "/firms/experts/member" &&
                    <>
                        <BackTop onClick={() => {return history.goBack()}}><ArrowLeftOutlined /> Back</BackTop>
                        <Image src={wmc.get("companyLogoUrl")} alt={""} />
                        <NavSecondaryTop title={"Willow Expert"} />
                        <PageContent>
                            <CoachingTeamOne />
                        </PageContent>
                    </>
                }

                {location.pathname === "/firms/experts/clients" &&
                    <>
                        <BackTop onClick={() => {return history.goBack()}}><ArrowLeftOutlined /> Back</BackTop>
                        <Image src={wmc.get("companyLogoUrl")} alt={""} />
                        <NavSecondaryTop title={"Clients Assigned To " + coach.get("first") + " " + coach.get("last")} />
                        <PageContent>
                            <ClientsList entry={"support"}/>
                        </PageContent>
                    </>
                }

                {location.pathname === "/firms/employees/member" &&
                    <>
                        <BackTop onClick={() => {return history.goBack()}}><ArrowLeftOutlined /> Back</BackTop>
                        <Image src={wmc.get("companyLogoUrl")} alt={""} />
                        <NavSecondaryTop title={"Firm Employee"} />
                        <PageContent>
                            <TeamOne />
                        </PageContent>
                    </>
                }

                {location.pathname === "/firms/support" &&
                    <>
                        <BackTop onClick={() => {return history.goBack()}}><ArrowLeftOutlined /> Back</BackTop>
                        <Image src={wmc.get("companyLogoUrl")} alt={""} />
                        <NavSecondaryTop title={"Support Team"} />
                        <PageContent>
                            <SupportTeam />
                        </PageContent>
                    </>
                }

                {location.pathname === "/firms/support/member" &&
                    <>
                        <BackTop onClick={() => {return history.goBack()}}><ArrowLeftOutlined /> Back</BackTop>
                        <Image src={wmc.get("companyLogoUrl")} alt={""} />
                        <NavSecondaryTop title={"Support Member"} />
                        <PageContent>
                            <SupportTeamOne />
                        </PageContent>
                    </>
                }
                <AnnouncementModal />
            </PageRight>
        </PageFull>
    </>
);

const mapStateToProps = state => ({
    wmc: state.enterprise.partners.partners.get("wmcSelected"),
    wmcAdvisor: state.enterprise.partners.partners.get("wmcAdvisorSelected"),
    coach: state.enterprise.partners.partners.get("coachSelected")
});

export default connect(mapStateToProps, null)(withRouter(MyPartners));
