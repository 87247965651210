import moment from 'moment/moment';

/* Components */
import {colors} from '../../../common/components/colors';

export const staticAvailability = (details, calendarType) => {
    if(moment(details.end).isBefore(moment())) {
        return({
            title: 'Available',
            backgroundColor: colors.secondary70,
            borderColor: colors.secondary70,
            textColor: colors.white,
            editable: false,
            startEditable: false,
            durationEditable: false,
            inThePast: true,
            type: 'available'
        });
    } else {
        if(calendarType === "willow") {
            return({
                title: 'Available',
                backgroundColor: colors.secondary100,
                borderColor: colors.secondary100,
                textColor: colors.white,
                editable: true,
                startEditable: true,
                durationEditable: true,
                inThePast: false,
                type: 'available'
            });
        } else {
            return({
                title: 'Available',
                backgroundColor: colors.secondary70,
                borderColor: colors.secondary70,
                textColor: colors.white,
                editable: false,
                startEditable: false,
                durationEditable: false,
                inThePast: true,
                type: 'available'
            });
        }
    }
};