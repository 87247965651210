import React from 'react';
import {withRouter} from 'react-router-dom';

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FontBody16} from '../../../common/components/fonts';
import {PageTitle, Container, Extra54px, Pill, PillBar} from '../components/settings';

/* Containers */
import SettingsGeneral from "./settingsgeneral";
import SettingsSecurity from "./settingssecurity";
import BillingFlow from "../../billing/containers/billing";

class Settings extends React.Component {
    state = {
        "isLoading": true,
        "currentTab": "", /* personal-info, security, billing */
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({"isLoading": false})
    };

    changeTab = async (tabToSwitchTo) => {
        await this.setState({"currentTab": tabToSwitchTo});
        await this.props.history.push("/settings"+tabToSwitchTo);
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <Container>
                    <Extra54px />
                    <PageTitle>{'ACCOUNT SETTINGS'}</PageTitle>
                    <PillBar>
                        <Pill active={this.state.currentTab === ''} onClick={() => {this.changeTab('');}}>
                            <FontBody16>{'Personal Info'}</FontBody16>
                        </Pill>
                        <Pill active={this.state.currentTab === '/security'} onClick={() => {this.changeTab('/security');}}>
                            <FontBody16>{'Security'}</FontBody16>
                        </Pill>
                        <Pill active={this.state.currentTab === '/billing'} onClick={() => {this.changeTab('/billing');}}>
                            <FontBody16>{'Billing'}</FontBody16>
                        </Pill>
                    </PillBar>
                    {this.state.currentTab === '' &&
                        <SettingsGeneral />
                    }
                    {this.state.currentTab === '/security' &&
                        <SettingsSecurity />
                    }
                    {this.state.currentTab === '/billing' &&
                        <BillingFlow customPlan={false} customPayment={false} customHistory={false}/>
                    }
                </Container>
            )
        }
    }
}

export default withRouter(Settings);
