import React from 'react';
import styled from "styled-components";
import DatePicker from "react-datepicker";
import {colors} from "../../../common/components/colors";
import {FontHeader18, FontBody16} from "../../../common/components/fonts";

export const Progress = styled.div`
    background-color: ${colors.border100};
    height: 10px;
    width: 100%;
`;
export const Title = styled.div`
    margin-bottom: ${props => props.spacer}px;
`;
export const Body = styled.div`
    color: ${colors.secondary100};
`;
export const Center = styled.div`
    text-align: center;
`;
export const AppointmentLeft = styled.div`
    width: 60%;
    height: 400px;
    margin: 80px auto 0px auto;
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 748px) {   
        display: block;
        width: 80%;
        margin: 0px auto;
        height: 310px;
        ${props => props.allCoaches === false} {
            margin: 80px auto 0px auto;
            padding-top: 50px;
        }
    }
    @media screen and (max-width: 460px) {   
        display: block;
        width: 96%;
        margin: 0px auto;
        ${props => props.allCoaches === false} {
            margin: 80px auto 0px auto;
            padding-top: 50px;
        }
    }
    & .react-datepicker {
        width: 96%;
        border: none;
        background-color: ${colors.secondary100};
        margin: 0px auto 20px auto;
    }
    & .react-datepicker__month-container {
        width: 100%;
    }
    & .react-datepicker__header {
        background-color: ${colors.secondary100};
        border: none;
    }
    & .react-datepicker__current-month {
        color: ${colors.white};
        margin-bottom: 10px;
        font-size: 1.1rem;
        font-weight: normal;
    }
    & .react-datepicker__navigation--previous {
        left: 18% !important;
    }
    & .react-datepicker__navigation--next {
        right: 18% !important;
    }
    & .react-datepicker__navigation--next, .react-datepicker__navigation--previous {
        outline: none;
    }
    & .react-datepicker__day-name {
        color: ${colors.white};
        margin: 0.375rem 0.75rem 0rem 0.75rem !important;
        @media screen and (max-width: 748px) {   
            margin: 0rem 0.5rem 0rem 0.5rem !important;
        }
        @media screen and (max-width: 460px) {   
            margin: 0rem 0.1875rem 0rem 0.1875rem !important;
        }
    }
    & .react-datepicker__day {
        margin: 0rem 0.75rem 0.75rem 0.75rem !important;
        @media screen and (max-width: 748px) {   
            margin: 0rem 0.5rem 0rem 0.5rem !important;
        }
        @media screen and (max-width: 460px) {   
            margin: 0rem 0.1875rem 0rem 0.1875rem !important;
        }
        &:hover {
            background-color: ${colors.action100} !important;
            color: ${colors.white} !important;
        }
    }
    & .react-datepicker__day--selected {
        background-color: ${colors.action100} !important;
        color: ${colors.white} !important;
    }
    & .react-datepicker__day--selected:focus {
        outline: none !important;
        border: none !important;
    }
    & .show {
        background-color: ${colors.secondary30};
        color: ${colors.secondary100};
        border-radius: 50px;
        outline: none !important;
        border: none !important;
        font-weight: bold;
        cursor: pointer;
    }
    & .hide {
        cursor: default;
        pointer-events: none;
        color: ${colors.secondary30};
    }
`;
export const AppointmentRight = styled.div`
    width: 40%;
    ${props => props.allCoaches === false} {height: 500px;}
    ${props => props.allCoaches === true} {height: 411px;}
    display: inline-block;
    @media screen and (max-width: 748px) {   
        display: block;
        width: 80%;
        margin: 0px auto;
        min-height: 50px !important;
        height: 100% !important;
        border-radius: 0px;
    }
    @media screen and (max-width: 460px) {   
        display: block;
        width: 96%;
        margin: 0px auto;
    }
    -webkit-box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.2);
    border-radius: 6px 6px 0px 0px;
    vertical-align: top;
    padding: 30px 0px;
    background-color: ${colors.white};
    & .react-datepicker {
        width: 100%;
        margin: 0px auto;
        border: none;
    }
    & .react-datepicker__time-container {
        width: 100%;
    }
    & .react-datepicker__header {
        background-color: ${colors.white};
        border: none;
        padding: 8px 10% 10px 10% !important;
    }
    & .react-datepicker-time__header {
        text-align: left;
    }
    & .react-datepicker__time-list {
       overflow-y: auto !important; 
       height: 400px !important;
        @media screen and (max-width: 748px) {   
            min-height: 50px !important;
            height: 100% !important;
            padding-bottom: 20px;
        }
    }
    & .react-datepicker__time-box {
        width: 100% !important;
    }
    & .react-datepicker__time-list-item {
        width: calc(80% - 4px) !important;
        margin: 6px 10%;
        background-color: ${colors.white} !important;
        border: 2px solid ${colors.border100} !important;
        color: ${colors.action100};
        font-weight: bold !important;
    }
    & .react-datepicker__time-list-item {
        padding: 10px 0px 0px 0px !important;
        height: 25px !important;
        border-radius: 4px !important;
        &:hover {
            background-color: ${colors.action100} !important;
            color: ${colors.white} !important;
        }
    }
    & .react-datepicker__time-list-item--selected {
        background-color: ${colors.action30} !important;
        border: 2px solid ${colors.action100} !important;
        color: ${colors.action100} !important;
        font-weight: bold !important;
    }
    & .hide {
        display: none;
    }
`;
export const AppointmentImage = styled.img`
    background-color: ${colors.primary30};
    height: 87px;
    width: 87px;
    border-radius: 100px;
    border: 2px solid ${colors.white};
    margin: -100px auto 0px auto;
    object-fit: cover;
    object-position: 0 0;
`;
export const AppointmentCTA = styled.div`
    color: ${colors.white};
    margin: 10px auto 40px auto;
`;
export const AppointmentTitle = styled.div`
    margin-bottom: 10px;
    text-align: left;
`;
export const AppointmentSubTitle = styled.div`
    margin-bottom: 30px;
    text-align: left;
`;
export const AppointmentCalendar = styled.div`
    background-color: ${colors.secondary100};
    padding-top: 50px;
    ${props => props.allCoaches === false} {height: 412px;}
    ${props => props.allCoaches === true} {height: 320px;}
    @media screen and (max-width: 748px) {       
        ${props => props.allCoaches === false} {
            margin-top: -50px;
            padding: 20px 0px !important;
            height: 340px;
        }
    }
`;
const MeetingTime = styled.div`
    margin: 0px 0px 20px 0px;
    color: ${colors.secondary100};
`;

const Appointment = ({meetingLength, expertPhoto, allCoaches, subtitle, expertName, title, selectedDay, minDate, handleChangeDay, handleColorDays, selectedTime, handleChangeTime, availableTimesToday, handleHideTimes}) => (
    <Center>
        {title !== "" && <AppointmentTitle><FontHeader18>{title}</FontHeader18></AppointmentTitle>}
        {subtitle !== "" && <AppointmentSubTitle><FontBody16>{subtitle}</FontBody16></AppointmentSubTitle>}
        <div>
            <AppointmentLeft allCoaches={allCoaches} >
                <AppointmentCalendar allCoaches={allCoaches}>
                    {expertPhoto !== null && <AppointmentImage src={expertPhoto} width={87} height={87} />}
                    {expertName !== null && <AppointmentCTA><FontHeader18>Find a time to meet {expertName}</FontHeader18></AppointmentCTA>}
                    <DatePicker
                        onChange={handleChangeDay}
                        selected={selectedDay}
                        minDate={minDate}
                        inline
                        dayClassName={handleColorDays}
                    />
                </AppointmentCalendar>
            </AppointmentLeft>
            <AppointmentRight allCoaches={allCoaches}>
                {meetingLength !== null &&
                    <MeetingTime><FontBody16>{"Book a "+meetingLength+"-minute meeting"}</FontBody16></MeetingTime>
                }
                <DatePicker
                    selected={selectedTime}
                    onChange={handleChangeTime}
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat={"h:mm aa"}
                    inline
                    timeCaption={"What time works best?"}
                    injectTimes={availableTimesToday}
                    timeClassName={handleHideTimes}
                />
            </AppointmentRight>
        </div>
    </Center>
);

export default Appointment;


