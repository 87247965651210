import { Map, List, fromJS } from 'immutable';

/* Immutable */

const COURSES = 'COURSES';
const TASKS = 'TASKS';
const COURSES_FETCHING = 'COURSES_FETCHING';
const COURSES_ADD = 'COURSES_ADD';
const COURSES_CREATING = 'COURSES_CREATING';
const TASK_CREATE = 'TASK_CREATE';
const COURSES_CREATE = 'COURSES_CREATE';
const COURSES_ADD_ELEMENT = 'COURSES_ADD_ELEMENT';
const COURSES_ONE = 'COURSES_ONE';
const COURSES_UPDATE = 'COURSES_UPDATE';
const COURSES_UPDATING = 'COURSES_UPDATING';
const COURSES_DELETE = 'COURSES_DELETE';
const COURSES_DELETING = 'COURSES_DELETING';
const COURSES_REPORT = 'COURSES_REPORT';
const COURSES_REPORT_FETCHING = 'COURSES_REPORT_FETCHING';
const COURSES_ASSIGNED = 'COURSES_ASSIGNED';
const COURSES_ASSIGNING = 'COURSES_ASSIGNING';
const CURRENT_PAGE_EDITING = 'CURRENT_PAGE_EDITING';
const COURSES_STORE_RESET = 'COURSES_STORE_RESET';

/* Actions */

export const setCourses = (coursesAll) => ({'type': COURSES, 'coursesAll': coursesAll});
export const setCoursesFetching = (status) => ({'type': COURSES_FETCHING, 'status': status});
export const setCoursesAdd = (newCourse) => ({'type': COURSES_ADD, 'newCourse': newCourse});
export const setCoursesCreating = (status) => ({'type': COURSES_CREATING, 'status': status});
export const setTaskCreate = (course) => ({'type': TASK_CREATE, 'course': course});
export const setCurrentPageEditing = (pageNum) => ({'type': CURRENT_PAGE_EDITING, 'pageNum': pageNum});
export const setTasksForCourse = (tasks) => ({'type': TASKS, 'tasks': tasks});
export const setCourseCreate = (course) => ({'type': COURSES_CREATE, 'course': course});
export const setCoursesAddElement = (element) => ({'type': COURSES_ADD_ELEMENT, 'element': element});
export const setCoursesOne = (course) => ({'type': COURSES_ONE, 'course': course});
export const setCoursesUpdate = (editedCourse) => ({'type': COURSES_UPDATE, 'editedCourse': editedCourse});
export const setCoursesUpdating = (status) => ({'type': COURSES_UPDATING, 'status': status});
export const setCoursesDeleted = (courseId) => ({'type': COURSES_DELETE, 'courseId': courseId});
export const setCoursesDeleting = (status) => ({'type': COURSES_DELETING, 'status': status});
export const setCoursesReport = (report) => ({'type': COURSES_REPORT, 'report': report});
export const setCoursesReportFetching = (status) => ({'type': COURSES_REPORT_FETCHING, 'status': status});
export const setCoursesAssigned = (courseId, consumerId) => ({'type': COURSES_ASSIGNED, 'courseId': courseId, 'consumerId': consumerId});
export const setCoursesAssigning = (status) => ({'type': COURSES_ASSIGNING, 'status': status});
export const setCoursesStoreReset = () => ({'type': COURSES_STORE_RESET});

/* Initial State */

const initialState = Map({
    coursesAll: List(),
    coursesFetching: false,
    taskCreate: Map({
        "title": null,
        "description": null,
        "imageUrl": null,
        "pages": List(),
        "isActive": false,
        "externalAuthor": Map()
    }),
    courseCreate: Map({
        "title": null,
        "description": null,
        "imageUrl": null,
        "isActive": false,
        "isReadingMaterial": false,
        "isVideo": false,
        "externalAuthor": Map(),
        "categoryIds": List()
    }),
    tasks: List(),
    coursesCreating: false,
    coursesUpdating: false,
    coursesDeleting: false,
    coursesReport: Map(),
    coursesReportFetching: false,
    coursesAssigning: false,
    coursesOne: Map(),
    pageEditing: 0
});

/* Reducer */

const curriculumReducer = (state=initialState, action) => {
    switch (action.type) {
        case COURSES:
            return state.merge({'coursesAll': fromJS(action.coursesAll)});
        case TASKS:
            return state.merge({'tasks': fromJS(action.tasks)});
        case COURSES_ONE:
            return state.merge({'coursesOne': fromJS(action.course)});
        case COURSES_FETCHING:
            return state.merge({'coursesFetching': action.status});
        case COURSES_ADD:
            return state; // FIX
        case COURSES_CREATING:
            return state.merge({'coursesCreating': action.status});
        case TASK_CREATE:
            return state.merge({'taskCreate': fromJS(action.course)});
        case COURSES_CREATE:
            return state.merge({'courseCreate': fromJS(action.course)});
        case COURSES_ADD_ELEMENT:
            return state.merge({"taskCreate": fromJS({
                ...state.get("taskCreate").toJS(),
                "pages": fromJS(action.element)
            })});
        case COURSES_UPDATE:
            return state; // FIX
        case COURSES_UPDATING:
            return state.merge({'coursesUpdating': action.status});
        case COURSES_DELETE:
            return state; // FIX
        case COURSES_DELETING:
            return state.merge({'coursesDeleting': action.status});
        case COURSES_REPORT:
            return state.merge({'coursesReport': fromJS(action.report)});
        case COURSES_REPORT_FETCHING:
            return state.merge({'coursesReportFetching': action.status});
        case COURSES_ASSIGNED:
            return state; // FIX
        case COURSES_ASSIGNING:
            return state.merge({'coursesAssigning': action.status});
        case CURRENT_PAGE_EDITING:
            return state.merge({'pageEditing': action.pageNum})
        case COURSES_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default curriculumReducer;
