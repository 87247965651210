import { Map, List, fromJS } from 'immutable';

/* Immutable */
const IFRAMES_GROUP_PROGRAMS_UPCOMING_EMPTY = 'IFRAMES_GROUP_PROGRAMS_UPCOMING_EMPTY';
const IFRAMES_GROUP_PROGRAMS_UPCOMING = 'IFRAMES_GROUP_PROGRAMS_UPCOMING';
const IFRAMES_GROUP_PROGRAMS_UPCOMING_FETCHING = 'IFRAMES_GROUP_PROGRAMS_UPCOMING_FETCHING';
const IFRAMES_GROUP_PROGRAM_DETAILS = 'IFRAMES_GROUP_PROGRAM_DETAILS';
const IFRAMES_GROUP_PROGRAM_DETAILS_FETCHING = 'IFRAMES_GROUP_PROGRAM_DETAILS_FETCHING';
const IFRAMES_GROUP_PROGRAM_REGISTRATION_FETCHING = 'IFRAMES_GROUP_PROGRAM_REGISTRATION_FETCHING';
const IFRAMES_GROUP_PROGRAM_REGISTRATION_IN_PROGRESS = 'IFRAMES_GROUP_PROGRAM_REGISTRATION_IN_PROGRESS';
const IFRAMES_GROUP_PROGRAM_DETAILS_EXISTING_REGISTRATION = 'IFRAMES_GROUP_PROGRAM_DETAILS_EXISTING_REGISTRATION';

/* Actions */
export const setGroupProgramsUpcomingEmpty = () => ({'type': IFRAMES_GROUP_PROGRAMS_UPCOMING_EMPTY});
export const setGroupProgramsUpcoming = (groupPrograms) => ({'type': IFRAMES_GROUP_PROGRAMS_UPCOMING, 'groupPrograms': groupPrograms});
export const setGroupProgramsUpcomingFetching = (status) => ({'type': IFRAMES_GROUP_PROGRAMS_UPCOMING_FETCHING, 'status': status});
export const setGroupProgramDetails = (groupProgram) => ({'type': IFRAMES_GROUP_PROGRAM_DETAILS, 'groupProgramDetails': groupProgram});
export const setGroupProgramDetailsFetching = (status) => ({'type': IFRAMES_GROUP_PROGRAM_DETAILS_FETCHING, 'status': status});
export const setGroupProgramRegistrationFetching = (status) => ({'type': IFRAMES_GROUP_PROGRAM_REGISTRATION_FETCHING, 'status': status});
export const setGroupProgramRegistrationInProgress = (status) => ({'type': IFRAMES_GROUP_PROGRAM_REGISTRATION_IN_PROGRESS, 'status': status});
export const setExistingRegistrationDetails = (existingRegistration) => ({'type': IFRAMES_GROUP_PROGRAM_DETAILS_EXISTING_REGISTRATION, 'existingRegistration': existingRegistration});

/* Initial State */
const initialState = Map({
    groupPrograms: List(),
    groupProgramsFetched: false,
    groupProgramsFetching: false,
    groupProgramDetails: Map(),
    existingRegistration: Map(),
    groupProgramDetailsFetched: false,
    groupProgramDetailsFetching: false,
    groupProgramRegistrationFetchStatus: false,
    groupProgramRegistrationInProgress: false,
    mySubscribedGroupPrograms: List()
});

/* Reducer */
const groupsReducer = (state=initialState, action) => {
    switch (action.type) {
        case IFRAMES_GROUP_PROGRAMS_UPCOMING_EMPTY:
            return state.merge({
                'groupPrograms': List(),
                'groupProgramsFetched': false
            });
        case IFRAMES_GROUP_PROGRAMS_UPCOMING:
            return state.merge({
                'groupPrograms': fromJS(state.get("groupPrograms").concat(fromJS(action.groupPrograms))),
                'groupProgramsFetched': true
            });
        case IFRAMES_GROUP_PROGRAMS_UPCOMING_FETCHING:
            return state.merge({'productsWillowFetching': action.status});
        case IFRAMES_GROUP_PROGRAM_DETAILS:
            return state.merge({
                'groupProgramDetails': fromJS(action.groupProgramDetails),
                'groupProgramDetailsFetched': true
            });
        case IFRAMES_GROUP_PROGRAM_DETAILS_FETCHING:
            return state.merge({
                'groupProgramDetailsFetching': fromJS(action.status)
            });
        case IFRAMES_GROUP_PROGRAM_REGISTRATION_FETCHING:
            return state.merge({
                'groupProgramRegistrationFetchStatus': fromJS(action.status)
            });
        case IFRAMES_GROUP_PROGRAM_DETAILS_EXISTING_REGISTRATION:
            return state.merge({
                'existingRegistration': fromJS(action.existingRegistration)
            });
        case IFRAMES_GROUP_PROGRAM_REGISTRATION_IN_PROGRESS:
            return state.merge({
                'groupProgramRegistrationInProgress': action.status
            });
        default:
            return state;
    }
};

export default groupsReducer;
