export const devRecurringAvailabilityData = {
    "id": 12,
    "availability_calendar_update_method": "willow", // "willow", "google_calendar", "microsoft_outlook", "calendly"
    "outlook_proxy": "testemail@outlook.com"
};

export const devRecurringAvailabilityUpdateData = (syncMethod) => ({
    "id": 12,
    "availability_calendar_update_method": syncMethod
});

export const devRecurringAvailabilityScheduleData = {
    "workSchedule": [
        {
            "dayOfWeek": "mon",
            "startTime": "12:00:00",
            "endTime": "13:00:00"
        },
        {
            "dayOfWeek": "tue",
            "startTime": "12:00:00",
            "endTime": "13:00:00"
        },
        {
            "dayOfWeek": "wed",
            "startTime": "12:00:00",
            "endTime": "13:00:00"
        },
        {
            "dayOfWeek": "thu",
            "startTime": "12:00:00",
            "endTime": "13:00:00"
        },
        {
            "dayOfWeek": "fri",
            "startTime": "12:00:00",
            "endTime": "13:00:00"
        }
    ]
};

export const devRecurringAvailabilityScheduleUpdateData = (workSchedule) => ({
    "workSchedule": workSchedule
});
