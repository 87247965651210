import React from 'react';
import {connect} from 'react-redux';
import styled from "styled-components";

/* Containers */
import Confirm from './confirm';
import Message from './message';
import SignupFlow from '../../signup/containers/flow';

import {
    tryPostMessagesAll
} from "../middleware/emailer";

/* Components */
import {LogoLoading} from "../../../common/components/loading";

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

class EmailerFlow extends React.Component {
    state={
        "step": 1,
        "firstName": "",
        "lastName": "",
        "email": "",
        "phone": "",
        "message": "",
        "subject": "",
        "loadedProduct": false,
        "isLoading": true,
        "error": null
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.eventsAll !== this.props.eventsAll) {
            return this.update();
        }
    }

    init = async () => {
        return this.update();
    };

    update = async () => {
        this.setState({"isLoading": false})
    };

    setStep = async (step) => {
        await this.setState({"step": step});
    };

    getMessage = async (msg, sub, valid) => {
        await this.setState({"message": msg, "subject": sub, "messageValid": valid});
        this.setStep(2)
    };

    sendMessage = async () => {
        const status = await this.props.tryPostMessagesAll("email", this.state.message, this.state.subject, this.props.id);
        if(status.error === null) {
            this.setState({"error": null});
            this.setStep(3);
        } else {
            this.setState({"error": status.error});
            this.setStep(1);
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if(this.state.step === 1) {
                return(
                    <Message
                        firstName={this.props.firstName}
                        lastName={this.props.lastName}
                        photo={this.props.photo}
                        inModal={this.props.inModal}
                        showCloseInsteadOfBack={this.props.showCloseInsteadOfBack}
                        close={this.props.close}
                        next={this.getMessage}
                        id={this.props.id}
                        message={this.state.message}
                        subject={this.state.subject}
                        error={this.state.error}
                    />
                )
            } else if(this.state.step === 2) {
                return (
                    <SignupFlow
                        back={() => {return this.setStep(1)}}
                        signupClose={() => {return this.sendMessage()}}
                    />
                )
            } else if(this.state.step === 3) {
                return(
                    <Confirm
                        inModal={this.props.inModal}
                        close={this.props.close}
                    />
                )
            } else {
                return null
            }
        }
    }

}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    tryPostMessagesAll: (mediaType, content, subject, id) => dispatch(tryPostMessagesAll(mediaType, content, subject, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailerFlow);