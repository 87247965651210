import {axiosReq} from "../../../common/utils/axios";

/* Static */
import { devEnterpriseCreateTask } from '../static/devdata';

export const tryCreateTask = (data) => {
    return async dispatch => {
        const task = await axiosReq('apiv1/coach/courses/tasks/create-task', "POST", data, devEnterpriseCreateTask);
        return task
    }
};

export const tryEditTask = (data, id) => {
    return async dispatch => {
        const task = await axiosReq('apiv1/coach/courses/tasks/' + id, "PUT", data, devEnterpriseCreateTask);
        return task
    }
};