import React from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';

/* Components */
import {FontTitle18, FontHeader16, FontBody21} from '../../../common/components/fonts';
import {ButtonTertiary} from '../../../common/components/buttons';
import {LogoLoading} from "../../../common/components/loading";
import {colors} from "../../../common/components/colors";
import {Width, SaveSection, SubmitInputWithValidation, InlineBlock, WidthRight, Right, Border} from '../components/clientscreate';

/* Containers */
import MeetingsCreateVideo from './meetingscreatevideo';
import MeetingsCreatePhoto from './meetingscreatephoto';
import MeetingsCreateHero from './meetingscreatehero';
import MeetingsCreateWhen from './meetingscreatewhen';
import MeetingsCreateTitle from './meetingscreatetitle';
import MeetingsCreateDescription from './meetingscreatedescription';
import MeetingsCreateMoney from './meetingscreatemoney';
import MeetingsCreatePublic from './meetingscreatepublic';
import MeetingsCreateType from './meetingscreatetype';
import MeetingsCreateVideoPlatform from './meetingscreatevideoplatform';
import MeetingsCreateVideoUrl from './meetingscreatevideourl';
import MeetingsCreateActive from './meetingscreateactive';

/* Middleware */
import {tryCreateSessionLarge} from '../middleware/group';
import {tryGetNoticesSessions} from "../../../common/containers/noticemeeting/middleware/noticecall";
import MeetingsCreateNumber from "./meetingscreatenumber";
import moment from "moment-timezone";
import {AudioFilled} from "@ant-design/icons";

const MeetingType = styled.div`
    padding: 40px;
    width: calc(100% - 80px);
    background-color: ${colors.border100};
    color: ${colors.primary100};
    margin-bottom: 30px;
    border-radius: 4px;
    max-width: 940px;
    @media screen and (max-width: 1440px) {
        max-width: 466px;
    }
    @media screen and (max-width: 1088px) {
        max-width: 880px;
    }
    @media screen and (max-width: 1020px) {
        max-width: 466px;
    }
`;

class MeetingsCreateLarge extends React.Component {
    state = {
        title: {
            title: "",
            titleValid: false
        },
        description: {
            description: '',
            descriptionValid: false,
            descriptionCount: 0,
            descriptionComponent: ''
        },
        hero: {
            activeChecked: true,
            deactiveChecked: false,
            image: {
                imgURL: "",
                imgURLValid: false,
                imgPreview: false,
            },
            video: {
                videoURL: '',
                videoURLValid: false,
                videoPreview: false
            }
        },
        registrationLimit: 0,
        when: {
            timeZone: '',
            startAlreadyPast: true,
            endAlreadyPast: true,
            atLeastOneHr: true,
            endAfterStart: true,
            day: new Date(),
            start: new Date(),
            end: new Date(),
            validTimes: true,
            isLoadingWhen: true,
            startInTZ: null,
            endInTZ: null,
        },
        money: {
            money: 0,
            moneyValid: true
        },
        participants: {
            number: 50,
            numberValid: true
        },
        videoPlatform: {
            platform: "willow", // willow, zoom
            url: null,
            urlValid: true
        },
        public: {
            activeChecked: true,
            deactiveChecked: false,
        },
        isGroupSession: {
            activeChecked: false,
            deactiveChecked: true,
        },
        active: {
            activeChecked: true,
            deactiveChecked: false,
        },
        isSaving: false,
        isSaved: false,
        error: '',
        isChanged: false,
        isValid: true,
        isLoading: true
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({"isLoading": false});
    };

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    handleChangeTitle = async (state) => {
        await this.setState({"title": state});
        this.checkValid();
    };

    handleChangeDescription = async (state) => {
        await this.setState({"description": state});
        this.checkValid();
    };

    handleChangeHero = async (state) => {
        await this.setState({"hero": state});
        this.checkValid();
    };

    // handleChangeImage = async (state) => {
    //     await this.setState({"image": state});
    //     this.checkValid();
    // };

    // handleChangeVideo = async (state) => {
    //     await this.setState({"video": state});
    //     this.checkValid();
    // };

    handleChangeWhen = async (state) => {
        await this.setState({"when": state});
        this.checkValid();
    };

    handleChangeMoney = async (state) => {
        await this.setState({"money": state});
        this.checkValid();
    };

    handleChangeParticipants = async (state) => {
        await this.setState({"participants": state});
        this.checkValid();
    };

    handleChangeType = async (state) => {
        await this.setState({"isGroupSession": state});
        this.checkValid();
    };

    handleChangePublic = async (state) => {
        await this.setState({"public": state});
        this.checkValid();
    };

    handleChangeVideoPlatform = async (state) => {
        await this.setState({
            "videoPlatform": {
                "platform": state.platform,
                "url": state.url,
                "urlValid": state.urlValid
            },
            "isGroupSession": {
                "activeChecked": false,
                "deactiveChecked": true
            }
        });
        this.checkValid();
    };

    handleChangeActive = async (state) => {
        await this.setState({"active": state});
        this.checkValid();
    };

    checkValid = () => {
        if(this.state.title.titleValid && this.state.money.moneyValid && this.state.participants.numberValid && this.state.description.descriptionValid &&
            this.state.hero.image.imgURLValid /*((this.state.hero.image.imgURLValid && this.state.hero.deactiveChecked) || (this.state.hero.video.videoURLValid && this.state.hero.activeChecked))*/ &&
            (this.state.videoPlatform.platform === "willow" || (this.state.videoPlatform.platform !== "willow" && this.state.videoPlatform.url !== null && this.state.videoPlatform.urlValid)) &&
            this.state.when.validTimes) {
            this.setState({'isValid': true, 'isChanged': true})
        } else {
            this.setState({'isValid': false, 'isChanged': true})
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({'isSaving': true, 'error': ''});
        const request = {
            "title": this.state.title.title,
            "description": this.state.description.description,
            "flashSaleStartDateTime": moment.utc(this.state.when.startInTZ).format("YYYY-MM-DD[T]HH:mm:ss[+]00:00"),
            "flashSaleEndDateTime": moment.utc(this.state.when.endInTZ).format("YYYY-MM-DD[T]HH:mm:ss[+]00:00"),
            "coverPhoto": this.state.hero.image.imgURL, //this.state.hero.deactiveChecked ? this.state.hero.image.imgURL : "",
            "announcementVideoUrl": this.state.hero.video.videoURL, //this.state.hero.activeChecked ? this.state.hero.video.videoURL : "",
            "ticketCost": typeof this.state.money.money === "number" ? this.state.money.money : parseInt(this.state.money.money.replace("$", "")),
            "purchaseLimitNumber": this.state.participants.number,
            "isOnlyForClients": !this.state.public.activeChecked,
            "type": this.state.isGroupSession.activeChecked ? "group_session" : "webinar",
            "zoomUrl": this.state.videoPlatform.url,
            "isActive": true //this.state.active.activeChecked,
        };
        console.log(request);
        await this.props.tryCreateSessionLarge(request);
        this.props.tryGetNoticesSessions();
        this.setState({'isSaving': false, 'isSaved': true, "isChanged": false});
        this.timeout = setTimeout(() => {this.props.history.push("/meetings")}, 3000);
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <form method={"post"} onSubmit={this.handleSubmit}>
                    {this.state.isSaved
                        ?
                        <>
                            {this.state.error === '' && <FontTitle18>Saved!</FontTitle18>}
                            {this.state.error !== '' && <FontTitle18>{this.state.error}</FontTitle18>}
                        </>
                        :
                        <>
                            <div>
                                <MeetingType>
                                    <FontBody21><AudioFilled />&nbsp;&nbsp;Webinar - schedule a group event</FontBody21>
                                </MeetingType>
                                <Width>
                                    <br />
                                    <FontTitle18>Details</FontTitle18>
                                    <MeetingsCreateTitle
                                        title={this.state.title}
                                        onValidate={this.handleChangeTitle}
                                    />
                                    <MeetingsCreateDescription
                                        description={this.state.description}
                                        onValidate={this.handleChangeDescription}
                                    />
                                    <Border />
                                    <FontTitle18>Hero</FontTitle18>
                                    <MeetingsCreateHero
                                        hero={this.state.hero}
                                        onValidate={this.handleChangeHero}
                                    />
                                    {/*<MeetingsCreatePhoto*/}
                                    {/*    image={this.state.hero.image}*/}
                                    {/*    onValidate={this.handleChangeImage}*/}
                                    {/*/>*/}
                                    {/*<MeetingsCreateVideo*/}
                                    {/*    video={this.state.hero.video}*/}
                                    {/*    onValidate={this.handleChangeVideo}*/}
                                    {/*/>*/}
                                    <Border />
                                    <FontTitle18>Platform</FontTitle18>
                                    <MeetingsCreateVideoPlatform
                                        videoPlatform={this.state.videoPlatform}
                                        onChange={this.handleChangeVideoPlatform}
                                    />
                                    {this.state.videoPlatform.platform === "willow" &&
                                        <MeetingsCreateType
                                            isGroupSession={this.state.isGroupSession}
                                            onValidate={this.handleChangeType}
                                        />
                                    }
                                    {this.state.videoPlatform.platform === "zoom" &&
                                        <MeetingsCreateVideoUrl
                                            videoPlatform={this.state.videoPlatform}
                                            onValidate={this.handleChangeVideoPlatform}
                                        />
                                    }
                                    <Border />
                                    <FontTitle18>Settings</FontTitle18>
                                    <MeetingsCreateMoney
                                        money={this.state.money}
                                        onValidate={this.handleChangeMoney}
                                    />
                                    <MeetingsCreateNumber
                                        participants={this.state.participants}
                                        onValidate={this.handleChangeParticipants}
                                    />
                                    <MeetingsCreatePublic
                                        public={this.state.public}
                                        onValidate={this.handleChangePublic}
                                    />
                                    {/*<MeetingsCreateActive*/}
                                    {/*    active={this.state.active}*/}
                                    {/*    onValidate={this.handleChangeActive}*/}
                                    {/*/>*/}
                                </Width>
                                <WidthRight>
                                    <Right>
                                        <MeetingsCreateWhen
                                            isCreate={true}
                                            when={this.state.when}
                                            onValidate={this.handleChangeWhen}
                                        />
                                    </Right>
                                </WidthRight>
                            </div>
                            <SaveSection>
                                <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                <InlineBlock><div onClick={() => {return this.props.history.push("/meetings/create")}}><ButtonTertiary canSubmit={false} label={"Back"} /></div></InlineBlock>
                            </SaveSection>
                        </>
                    }
                </form>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryCreateSessionLarge: (request) => dispatch(tryCreateSessionLarge(request)),
    tryGetNoticesSessions: () => dispatch(tryGetNoticesSessions()),
});

export default connect(null, mapDispatchToProps)(withRouter(MeetingsCreateLarge));
