import { axiosReq } from "../../../../common/utils/axios";
import axios from "axios";

/* DevData */
import { devActiveGroupPrograms, devGroupProgramDetails } from "../../../../iframes/programs/static/devdata";
import { devMyRegisteredPrograms, devIndustries } from "../../../../consumer/programs/static/devdata";
import { devRegistrationDetails } from "../../../../iframes/programs/static/devdata";
import devTrainingsMapping from '../static/devdata';

export const tryGetUpcomingTrainingsRegisteredFor = () => {
    return async dispatch => {
        return axiosReq('apiv1/consumer/group-program/subscriptions', "GET", {}, devMyRegisteredPrograms)
    }
};

export const tryGetAllIndustries = () => {
    return async dispatch => {
        return axiosReq('apiv1/consumer/group-program/industries/get-all', "GET", {}, devIndustries)
    }
}

export const tryGetAllTrainings = (onlyAssetManagement, industryId, states) => {
    let requestJson = {}
    if (onlyAssetManagement)
        requestJson["isAssetManagement"] = onlyAssetManagement
    if (industryId)
        requestJson["industryId"] = industryId
    if (states){
        requestJson["states"] = states
    }

    return async dispatch => {
        return axiosReq('apiv1/consumer/group-program/catalogue', "GET", requestJson, devActiveGroupPrograms)
    }
}

// export const tryGetTrainingDetails = (groupProgramId) => {
//     return async dispatch => {
//         return axiosReq('apiv1/consumer/group-program/get-active/' + groupProgramId, "GET", {}, devGroupProgramDetails);
//     }
// };

// export const tryGetTrainingRegisterInfo = (groupProgramId) => {
//     return async dispatch => {
//         return axiosReq('apiv1/consumer/group-program/subscription-exists/' + groupProgramId, "GET", {}, {}); // {devRegistrationDetails});
//     }
// };

// export const tryPostTrainingRegister = (groupProgramId) => {
//     return async dispatch => {
//         return await axiosReq('apiv1/consumer/group-program/subscribe', "POST", { "groupProgramId": groupProgramId }, devRegistrationDetails);
//     }
// };

// export const tryPostTrainingRequest = (message) => {
//     return async dispatch => {
//         await axiosReq('apiv1/feedback', "POST", { message: message }, null);
//     }
// };

export const tryGetTrainingMapping = () => {
    return async dispatch => {
        return axiosReq('apiv1/coach/group-program/with_quiz_and_video', "GET", {}, devTrainingsMapping);
    }
};