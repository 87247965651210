import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

/* Components */
import {ButtonSecondary, ButtonTertiary} from '../../../common/components/buttons';
import {Toggle2Lables} from "../../../common/components/inputs";
import {FontBody16, FontHeader16, FontTitle18} from '../../../common/components/fonts';
import {colors} from '../../../common/components/colors';
import {LogoLoading} from "../../../common/components/loading";
import {InlineBlock, SaveSection, SubmitInputWithValidation} from "../components/pagescontact";

/* Middleware */
import {tryGetExpertProfile, tryUpdateExpertProfile} from '../middleware/expertprofile';
import {CheckOutlined, CloseCircleOutlined, EditOutlined} from "@ant-design/icons";
import {FitContent} from "../components/myprofile";

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

export const Invalid = styled.div`
    color: ${colors.alert100};
`;

const RemoveMargin = styled.div`
    margin-bottom: -30px;
`;

const Red = styled.div`
    color: ${colors.alert100};
    display: inline-block;
    vertical-align: top;
`;

const Green = styled.div`
    color: ${colors.progress100};
    display: inline-block;
    vertical-align: top;
`;

const GrayColor = styled.div`
    color: ${colors.primary70};
`;

class PagesPublish extends React.Component {
    state = {
        "isLoading": true,
        "active": false,
        "activePrevious": false,
        "activeIsChanged": false,
        "activeIsChanging": false,
        "activeIsSaved": false,
        "error": "",
        "isEdit": false,
    };

    componentDidMount() {
        return this.initiate();
    }

    initiate = async () => {
        await this.props.tryGetExpertProfile();
        await this.setState({
            "active": this.props.profile.get(this.props.activeKey),
            "activePrevious": this.props.profile.get(this.props.activeKey),
            "isLoading": false,
        });
    };

    edit = async (status) => {
        this.setState({"isEdit": status});
    };

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    changeActive = async () => {
        await this.setState({"active": !this.state.active, "activeIsChanged": this.state.activePrevious !== !this.state.active});
    };

    resetActive = async () => {
        await this.setState({"active": this.state.activePrevious, "activeIsChanged": false, "isEdit": false});
    };

    submitActive = async e => {
        e.preventDefault();
        this.setState({'activeIsChanging': true, "error": ""});
        await this.props.tryUpdateExpertProfile({[this.props.activeKey]: this.state.active}, this.props.errorKey);
        if(this.props.profile.get(this.props.errorKey) === "") {
            this.setState({
                'activeIsChanging': false,
                "activePrevious": this.state.active,
                "activeIsSaved": true,
                "isEdit": false
            });
            this.timeout = setTimeout(() => {this.setState({'activeIsSaved': false, "activeIsChanged": false})}, 3000);
        } else {
            this.setState({'activeIsChanging': false, "error": this.props.profile.get(this.props.errorKey)});
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if(this.state.isEdit) {
                return(
                    <form method={"post"} onSubmit={this.submitActive}>
                        <FontTitle18>{this.props.title}</FontTitle18>
                        <GrayColor><FontBody16>{this.props.subtitle}</FontBody16></GrayColor>
                        <br />
                        <br />
                        <Toggle2Lables leftLabel={this.props.leftLabel} rightLabel={this.props.rightLabel} id={"toggle"} onChange={this.changeActive} value={this.state.active} offColor={colors.primary70} onColor={colors.progress100} />
                        <RemoveMargin />
                        <SaveSection>
                            <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.activeIsChanged} isValid={true} isSaving={this.state.activeIsChanging} /></InlineBlock>
                            <InlineBlock><span onClick={this.resetActive}><ButtonTertiary canSubmit={false} label={"Cancel"} /></span></InlineBlock>
                        </SaveSection>
                    </form>
                )
            } else {
                if(this.state.activeIsSaved) {
                    return(
                        <>
                            <br />
                            <FontHeader16><Green>Saved!</Green></FontHeader16>
                            <br />
                        </>
                    )
                } else if(this.state.error !== "") {
                    return(<FontBody16><Red>{this.state.error}</Red></FontBody16>)
                } else {
                    return(
                        <>
                            <FontTitle18>{this.props.title}</FontTitle18>
                            <GrayColor><FontBody16>{this.props.subtitle}</FontBody16></GrayColor>
                            <br />
                            <br />
                            {this.state.active
                                ? <><FontBody16><Green><CheckOutlined /></Green>&nbsp;Show Products Tab</FontBody16></>
                                : <><FontBody16><Red><CloseCircleOutlined /></Red>&nbsp;Show Products Tab</FontBody16></>
                            }
                            <br />
                            <br />
                            <FitContent><FontBody16 onClick={() => {return this.edit(true)}}><ButtonSecondary canSubmit={false} label={<><EditOutlined/>&nbsp;Change Product Options</>} /></FontBody16></FitContent>
                        </>
                    )
                }
            }
        }
    }
}

const mapStateToProps = state => ({
    profile: state.enterprise.shop.expertProfile.get("expertProfile")
});

const mapDispatchToProps = dispatch => ({
    tryGetExpertProfile: () => dispatch(tryGetExpertProfile()),
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PagesPublish));
