import React from "react";
import styled from 'styled-components';
import {connect} from 'react-redux';
import {fromJS} from 'immutable';

/* Components */
import { LogoLoading} from "../../../common/components/loading";
import { ButtonPrimary } from "../../../common/components/buttons";
import { colors } from "../../../common/components/colors";

/* Containers */
import ProfileAbout from './profilescheduler';
import IFrameAbout from '../../profile/containers/about';
import SchedulerFlow from "../../scheduler/containers/flow";

/* Middleware */
import {tryGetExpertProfileFromToken} from '../../profile/middleware/profile';
import IframeAbout from "../../profile/containers/about";

const ProfileMobile = styled.div`
    @media screen and (min-width: 1088px) {
        display: none;
    }
`
const Profile = styled.div`
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
    //height: 50vh;
    & > div {
        overflow-y: auto;
        overflow-x: hidden;
    }
    @media screen and (max-width: 1440px) {
        & > div > div:first-child {
          width: 450px;
          margin: 0;
        }
        & > div > div:last-child {
          max-width: 100%;
        }
    }
    @media screen and (max-width: 1088px) {
        & > div > div:first-child {
          width: 100%;
        }
        & > div > div:last-child {
          width: 100%;
        }
    }
`
const MobileContainerScheduler = styled.div`
    //height: 50vh;
    background-color: ${colors.white};
    overflow-y: auto;
    color: ${colors.primary100};
`
const Footer = styled.div`
    display: flex;
    justify-content: center;
    padding: 25px 0;
    width: 100%;
    background-color: ${colors.backgroundColor3};
    border-radius: 0 0 20px 20px;
`;
const FitContent = styled.div`
    width: fit-content;
    margin: 0 auto;
`;
const ProfileDesktop = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    @media screen and (max-width: 1088px) {
        display: none;
    }
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    //height: 50vh;
    & > div {
        overflow-y: auto;
        overflow-x: hidden;
    }
    @media screen and (max-width: 1440px) {
        & > div > div:first-child {
          width: 450px;
          margin: 0;
        }
        & > div > div:last-child {
          max-width: 400px;
        }
    }
    @media screen and (max-width: 1088px) {
        & > div > div:first-child {
          width: 100%;
        }
        & > div > div:last-child {
          width: 100%;
        }
    }
`
const FlexWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 80px 10px 35px 10px;
    justify-content: space-evenly;
    flex-direction: row-reverse;
    overflow: scroll;
    margin-top: -40px;
    background-color: rgba(57, 73, 80, 0.15);
    height: auto;
`
const ContainerProfile = styled.div`
    max-width: 600px;
    width: 55%;
    margin: 10px 10px;
`
const ContainerScheduler = styled.div`
    width: 500px;
    margin: 10px 10px;
    padding: 10px 10px 15px 10px;
    background-color: white;
    border-radius: 25px;
    height: fit-content;
    color: ${colors.primary100};
`
const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`;
const Section = styled(FlexCol)`
    gap: 20px;
`;
const SectionPadding = styled(Section)`
    padding: 70px 40px 100px;
    margin: -25px 0;
    background-color: ${colors.backgroundColor1};
`;

const Schedule = ({profile, onClose}) => (
    <SchedulerFlow
        userId={profile.get("id")}
        userFirstName={profile.get("firstName")}
        userLastName={profile.get("lastName")}
        userPhotoUrl={profile.get("photo")}
        purchaseHistoryId={null}
        isWillowSession={false}
        eventCustomMinutes={15}
        availabilityCloseBtn={false}
        availabilityOnClose={null}
        confirmOnClose={onClose}
        confirmOnScheduled={() => {}}
        showArrowClose={true}
        useNewDesign={true}
        allowTimeToBeSelected={false}
    />
)

class AdvisorProfileScheduler extends React.Component {
    static defaultProps = {
        "coachId": null,
        "onClose": () => {},
    };

    state = {
        "isLoading": true,
        "profile": null,
        "showScheduler": false,
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.coachId !== this.props.coachId) {
            return this.init();
        }
    }

    init = async () => {
        const profile = await this.props.tryGetExpertProfileFromToken(this.props.coachId);
        await this.setState({
            "profile": fromJS(profile),
            "isLoading": false
        });
    };

    toggleShowScheduler = () => this.setState({"showScheduler": !this.state.showScheduler});

    render() {
        if (this.state.isLoading) {
            return (<LogoLoading style={{marginTop: '35px'}} />)
        } else {
            return(
                <>
                    <ProfileMobile>
                        <Footer>
                            <FitContent onClick={this.toggleShowScheduler}>
                                <ButtonPrimary canSubmit={false} label={this.state.showScheduler ? "View profile" : "Book appointment"} />
                            </FitContent>
                        </Footer>
                    </ProfileMobile>
                    <ProfileDesktop>
                        <FlexWrap>
                            <ContainerScheduler>
                                <Schedule
                                    profile={this.state.profile}
                                    onClose={this.props.onClose}
                                />
                            </ContainerScheduler>
                        </FlexWrap>
                    </ProfileDesktop>
                </>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryGetExpertProfileFromToken: (id) => dispatch(tryGetExpertProfileFromToken(id)),
});

export default connect(null, mapDispatchToProps)(AdvisorProfileScheduler);