import { Map } from 'immutable';
import moment from "moment";

/* Immutable */

const SMS_DOWNLOADING = 'SMS_DOWNLOADING';
const SMS_SENDING = 'SMS_SENDING';
const SMS_PURCHASE_LINK = 'SMS_PURCHASE_LINK';
const SMS_OPEN = 'SMS_OPEN';
const SMS_STORE_RESET = 'SMS_STORE_RESET';

/* Actions */

export const setMessagesDownloading = (status) => ({'type': SMS_DOWNLOADING, 'status': status});
export const setMessagesSending = (status) => ({'type': SMS_SENDING, 'status': status});
export const setPurchaseLinkDownloading = (status) => ({'type': SMS_PURCHASE_LINK, 'status': status});
export const setMessagesSMSOpen = (open, recipientId, recipientType, recipientFirstName, recipientLastName, subject, content) => ({'type': SMS_OPEN, 'open': open, 'recipientId': recipientId, 'recipientType': recipientType, 'recipientFirstName': recipientFirstName, 'recipientLastName': recipientLastName, 'subject': subject, 'content': content});
export const setMessagesReset = () => ({'type': SMS_STORE_RESET});

/* Initial State */

const initialState = Map({
    isSending: false,
    isDownloading: false,
    isDownloadingPurchaseLink: false,
    isOpen: false,
    subject: null,
    body: null,
    recipientId: null,
    recipientType: null,
    recipientFirstName: null,
    recipientLastName: null,
    lastInitiated: null
});

/* Reducer */

const messagesReducer = (state=initialState, action) => {
    switch (action.type) {
        case SMS_DOWNLOADING:
            return state.merge({'isDownloading': action.status});
        case SMS_SENDING:
            return state.merge({'isSending': action.status});
        case SMS_PURCHASE_LINK:
            return state.merge({'isDownloadingPurchaseLink': action.status});
        case SMS_OPEN:
            return state.merge({
                'isOpen': action.open,
                'recipientId': action.recipientId,
                'recipientType': action.recipientType,
                'recipientFirstName': action.recipientFirstName,
                'recipientLastName': action.recipientLastName,
                'subject': action.subject,
                'body': action.content,
                'lastInitiated': moment()
            });
        case SMS_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default messagesReducer;
