import {images} from '../../../../common/components/images';
const randomNumber = () => {return Math.floor(Math.random() * 100)+1};

/* Domain */
import getDomainEnv from '../../../../domains/utils';
const env = getDomainEnv();

export const devEnterpriseListings = {
    "financialCoachingPlans": [
        {
            "id": 9,
            "title": "TestPlan 3",
            "description": "Lorem ipsum...",
            "coverPhoto": null,
            "termsOfUseUrl": "www.google.com",
            "privacyPolicyUrl": "www.google.com",
            "downloadUrl": "www.download.com",
            "sessionLength": 45,
            "serviceType": "event",
            "status": "Active",
            "plans": [
                {
                    "id": 7,
                    "orderIndex": 0,
                    "description": "Buy this plan for 2 meetings, 1 free",
                    "amount": 4000,
                    "planType": "one_time",
                    "recurringInterval": null
                },
                {
                    "id": 8,
                    "orderIndex": 1,
                    "description": "Buy this plan for year of meetings, 4 meetings per month, paid annualy, cancel anytime",
                    "amount": 50000,
                    "planType": "recurring",
                    "recurringInterval": "year"
                },
                {
                    "id": 9,
                    "orderIndex": 2,
                    "description": "Buy this plan for 3 monthly meetings, charged monthly for 4 months",
                    "amount": 2500,
                    "planType": "recurring",
                    "subscriptionDurationInMonths" : 4,
                    "recurringInterval": "month"
                }
            ]
        }
    ]
};

/*
{
    "id": 1,
    "title": "Q&A Call",
    "description": "<p>A 15-minute introductory phone call for you to learn more.</p>",
    "coverPhoto": null, //images.devDataTeamJasonAvatar,
    "durationInMonths": 1,
    "coachingSessionsQuota": 1,
    "coachingSessionsLength": 15,
    "totalPriceAmount": 0,
    "monthlyPriceAmount": 0,
    "orderIndex": 1,
    "status": "active",
    "terms": "https://"+env.URL.DOMAIN+"/terms-of-use",
    "privacy": "https://"+env.URL.DOMAIN+"/terms-of-use",
    "download": "https://"+env.URL.DOMAIN+"/",
    "serviceType": "event",
},
{
    "id": 2,
    "durationInMonths": 1,
    "coachingSessionsQuota": 2,
    "coachingSessionsLength": 120,
    "totalPriceAmount": 38900,
    "monthlyPriceAmount": 0,
    "title": "Clarity Meeting",
    "description": "<p>A 2-hour session designed to provide you with clear understanding of your financial picture. You’ll know which aspects of your personal finances are on track and what needs more attention. We’ll take a deep dive into your spending and show you where your money is going. You’ll receive customized recommendations related to savings, cash flow, debt, and protection that are designed to help you reach your goals.</p><br /><p>Please ensure that your spouse/partner is available on the date you select. We will email you pre-work to be returned 48 hours in advance of your scheduled meeting.</p>",
    "coverPhotoUrl": images.devDataTeamJasonAvatar,
    "orderIndex": 2,
    "status": "active",
    "terms": "https://"+env.URL.DOMAIN+"/terms-of-use",
    "privacy": null,
    "download": null,
    "serviceType": "download"
}
 */

export const devEnterpriseListing = {
    "id": 9,
    "title": "TestPlan 3",
    "description": "Lorem ipsum...",
    "coverPhoto": null,
    "termsOfUseUrl": "www.google.com",
    "privacyPolicyUrl": "www.google.com",
    "downloadUrl": "www.download.com",
    "sessionLength": 45,
    "serviceType": "event",
    "status": "Active",
    "plans": [
        {
            "id": 7,
            "orderIndex": 0,
            "description": "Buy this plan for 2 meetings, 1 free",
            "amount": 4000,
            "planType": "one_time",
            "recurringInterval": null
        },
        {
            "id": 8,
            "orderIndex": 1,
            "description": "Buy this plan for year of meetings, 4 meetings per month, paid annualy, cancel anytime",
            "amount": 50000,
            "planType": "recurring",
            "recurringInterval": "year"
        },
        {
            "id": 9,
            "orderIndex": 2,
            "description": "Buy this plan for 3 monthly meetings, charged monthly for 4 months",
            "amount": 2500,
            "planType": "recurring",
            "subscriptionDurationInMonths" : 4,
            "recurringInterval": "month"
        }
    ]
};

export const devEnterpriseListingsOrder = (listingIds, listings) => {
    return listings.toJS().map(listing => {
        return {...listing, "orderIndex": (listingIds.indexOf(listing.id)+1)}
    })
};

export const devEnterpriseListingUpdated = (data) => ({
    "id": 1,
    "durationInMonths": 1,
    "coachingSessionsQuota": 1,
    "coachingSessionsLength": data.coachingSessionsLength,
    "totalPriceAmount": 0,
    "monthlyPriceAmount": 0,
    "title": data.title,
    "description": data.description,
    "coverPhotoUrl": null,
    "orderIndex": 1,
    "status": data.status,
    "terms": "",
    "privacy": "",
    "download": null,
    "serviceType": "event",
});

export const devEnterpriseListingCreated = (data, listings) => ({
    "id": randomNumber(),
    "title": data.title,
    "description": data.description,
    "coverPhotoBase64": null,
    "termsOfUseUrl": "",
    "privacyPolicyUrl": "",
    "downloadUrl": "",
    "sessionLength": data.sessionLength,
    "productPlans": [],
    "serviceType": "event",
    "orderIndex": (listings.size+1),
    "status": "Active",
});

export const devServicesExpertProfileData = {
    id: '32',

    firstName: "Jason",
    lastName: "Aronson",
    companyName: 'Coaching, LLC',
    crd: 'test 5',

    photo: images.devDataTeamJasonAvatar,
    introVideoUrl: 'https://www.youtube.com/embed/RZAF1DAqHPk',
    facebookUrl: 'https://facebook.com',
    linkedinUrl: 'https://linkedin.com',
    twitterUrl: 'https://twitter.com',
    address1: 'test 3',
    address2: 'test 6',
    city: 'Boston',
    state: 'MA',
    zipCode: '23443',
    bio: '<p>In an age of pandemic, what true value do any of our backgrounds hold? It’s not like we can look at precedents and say, people who have done ABC tend to be really successful at accomplishing XYZ in a pandemic. My expf</p>',

    // BEG COACH ONLY FIELDS
    endorsement1: 'Test 1',
    endorsement2: 'Test 2',
    endorsement3: null,
    freeTime: 'Test 7',
    whyBecameCoach: 'Test 2',
    coachPhilosophy: 'Test 3',
    highlights: 'Test 1',

    coachingCertification: null,
    coachingCertificationYear: null,

    // END COACH ONLY FIELDS

    bookingAvailability: 'available',

    screeningCallLength: 30,
    defaultCallLength: 30,

    advancedDegree: null,
    advancedDegreeCity: null,
    advancedDegreeInstitution: null,
    advancedDegreeState: null,
    advancedDegreeYear: null,

    otherEducation: null,

    expertCategories: [],

    storeActive: false,
    storeShowScheduler: true,
    storeShowEmailer: true,
    storeTitle: "",
    storePhoto: null,
    storeHandle: "samplestore",

    publicHandle: "sampleadvisor",
    profileActive: true,
    profileShowScheduler: true,
    profileShowEmailer: true,
    website: "fromjason.c",

    schedulerActive: true,
    schedulerHandle: "sampleschedule",
    storeDescription: ""
};

export const devServicesExpertProfileUpdateData = (data) => ({
    'photo': images.devDataTeamJasonAvatar,
    // "highlights": data.highlights,
    "bio": data.bio,
    "whyBecameCoach": data.whyBecameCoach,
    // "coachPhilosophy": data.coachPhilosophy,
    "companyName": data.companyName,
    "crd": data.crd,
    "endorsement1": data.endorsement1,
    "endorsement2": data.endorsement2,
    "endorsement3": data.endorsement3,
    "freeTime": data.freeTime,
    "address1": data.address1,
    "address2": data.address2,
    "city": data.city,
    "state": data.state,
    "zipCode": data.zipCode,
    "introVideoUrl": data.introVideoUrl,
    "facebookUrl": data.facebookUrl,
    "linkedinUrl": data.linkedinUrl,
    "twitterUrl": data.twitterUrl,
    "bookingAvailability": data.bookingAvailability
});