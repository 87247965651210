import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setSignupMatchMyTeamFetching, setSignupMatchResultsSaving, setSignupMatchCoaches, setSignupMatchCoachesFetching, setSignupMatchCoachProfile, setSignupMatchCoachProfileFetching, setSignupMatchMyTeam} from '../store/match';

/* Static */
import {devCoachesFromCategory, devIframeSignupMatchCoachProfileData, devIframeSignupMatchTeamMembers} from "../static/match";

export const trySaveMatchingResults = (results) => {
    return async dispatch => {
        await dispatch(setSignupMatchResultsSaving(true));
        await axiosReq('apiv1/consumer/survey', "POST", {"survey": results}, {});
        await dispatch(setSignupMatchResultsSaving(false));
    }
};

export const tryGetAllCoachesFromCategory = (a) => {
    return async dispatch => {
        dispatch(setSignupMatchCoachesFetching(true));
        let url = 'apiv1/public/coach/get-all-coaches-from-category';
        if(a.wmcId !== null) {
            url = 'apiv1/consumer/coach/get-all-wmc-coaches-for-categories';
        }
        const coachesAll = await axiosReq(url, "POST", {expertCategoryIds: a.ids}, devCoachesFromCategory(false));
        dispatch(setSignupMatchCoachesFetching(false));
        return coachesAll;
    }
};

export const tryGetExpertProfileFromToken = (id) => {
    return async dispatch => {
        dispatch(setSignupMatchCoachProfileFetching(true));
        const profile = await axiosReq('apiv1/public/coach/'+id+'/profile', "GET", {}, devIframeSignupMatchCoachProfileData);
        await dispatch(setSignupMatchCoachProfile(profile));
        await dispatch(setSignupMatchCoachProfileFetching(false));
        return profile;
    }
};

const removeOneNotATeamMember = async (teamMember) => {
    let isNotATeamMember = false;
    await Promise.all(teamMember.expertCategories.map(async c => {
        if(c.categoryId === 52) {
            isNotATeamMember = true
        }
    }))
    return isNotATeamMember
};

const removeAllNotATeamMembers = async (allCoaches) => {
    return Promise.all(allCoaches.map(async a => {
        const isNotATeamMember = await removeOneNotATeamMember(a);
        return ({...a, "notATeamMember": isNotATeamMember});
    }))
};

const filterAllNotATeamMembers = async (allCoaches) => {
    const allCoachesWithFlag = await removeAllNotATeamMembers(allCoaches);
    return allCoachesWithFlag.filter(f => {return (!f.notATeamMember)})
}

export const tryGetAllTeamMembers = () => {
    return async dispatch => {
        dispatch(setSignupMatchMyTeamFetching(true));
        const team = await axiosReq('apiv1/consumer/coach/all-my-coaches', "GET", {}, devIframeSignupMatchTeamMembers);
        const teamAdj = await filterAllNotATeamMembers(team.allCoaches);
        dispatch(setSignupMatchMyTeam(teamAdj));
        dispatch(setSignupMatchMyTeamFetching(false));
        return teamAdj;
    }
};