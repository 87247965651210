import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import { List, Map } from "immutable";

/* Components */
import { FontBody21 } from "../../../../common/components/fonts";
import { ButtonPrimary } from "../../../../common/components/buttons";
import { LogoLoading } from "../../../../common/components/loading";

/* Containers */
import PreviewEventsRequired from "./previeweventsrequired";
import PreviewEventsElectives from "./previeweventselectives";

/* Middleware */
import {
    tryGetUpcomingTrainingsRegisteredFor,
    tryGetAllTrainings,
    tryGetAllIndustries,
    tryGetTrainingMapping,
} from "../middleware/trainings";
import { tryGetAllTasks } from "../../tasks/middleware/tasks";
import { certificationValidation } from "../../certification/middleware/certificationValidation";

/* Actions */
import { setSupportOpen } from "../../../support/store/support";

const Col = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
const FitContent = styled.div`
    width: fit-content;
`;
const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

class FirmTrainingsPreviewEvents extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    state = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        trainings: [],
        trainingsShown: [],
        industries: [],
        isLoading: true,
        selectedStateFilter: null,
        selectedIndustryFilter: null,
        validation: {
            currentProgress: null,
            completedCertifications: null,
        },
        showRequired: true,
        showModal: false,
    };

    requiredTraining = [
        {
            id: 62,
            message: "Required for all Advisor Certificates",
            requiredForCertificationProgramId: null,
            showAfterCompletedTrainingId: null,
        },
        {
            id: 64,
            message: "Required for Advisor for Women™ Certificate Program",
            requiredForCertificationProgramId: 3,
            showAfterCompletedTrainingId: null,
        },
        {
            id: 77,
            message: `Required for Advisor for Women™ Certificate Program. Can be taken after you complete training "Empowering Women Investors"`,
            requiredForCertificationProgramId: 3,
            showAfterCompletedTrainingId: 64,
        },
        {
            id: 56,
            message: "Required for Advisor for NextGen™ Certificate Program",
            requiredForCertificationProgramId: 4,
            showAfterCompletedTrainingId: null,
        },
        {
            //
            id: 75,
            message: `Required for Advisor for NextGen™ Certificate Program. Can be taken after you complete training "Engaging NextGen Investors"`,
            requiredForCertificationProgramId: 4,
            showAfterCompletedTrainingId: 56,
        },
    ];

    isPrerequisiteTrainingCompleted = (requiredTraining) => {
        if (!this.props.currentCertification || requiredTraining.showAfterCompletedTrainingId === null) return true;
        return this.props.completedTrainings.includes(requiredTraining.showAfterCompletedTrainingId);
    };

    getRequiredTraining = () => {
        const hideIfNotApplicableToCurrentCertProgress = (r) => {
            var isCompletedCertificateProgram = this.props.completedCertificateIds.includes(
                r.requiredForCertificationProgramId
            );
            if (isCompletedCertificateProgram) return true;
            if (this.props.currentCertification)
                return (
                    r.requiredForCertificationProgramId === null ||
                    r.requiredForCertificationProgramId === this.props.currentCertification.id
                );
            return r.requiredForCertificationProgramId === null;
        };

        const hideIfPrerequisiteTrainingIsNotCompleted = (r) => {
            if (!this.props.currentCertification || r.showAfterCompletedTrainingId === null) return true;

            return this.props.completedTrainings.includes(r.showAfterCompletedTrainingId);
        };

        const hideDisabledTrainingIds = (r) => !this.disabledTrainingIds.includes(r.id);

        return (
            this.requiredTraining
                .filter(hideIfNotApplicableToCurrentCertProgress)
                // .filter(hideIfPrerequisiteTrainingIsNotCompleted)
                .filter(hideDisabledTrainingIds)
        );
    };

    getRequiredTrainingIds = () => this.getRequiredTraining().map((r) => r.id);

    getElectiveTrainings = () => {
        const requiredTrainingIds = this.requiredTraining.map((t) => t.id);
        return this.state.trainingsShown
            .filter((t) => !requiredTrainingIds.includes(t.id))
            .filter((t) => !this.disabledTrainingIds.includes(t.id));
    };

    disabledTrainingIds = [];

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            return this.init();
        }
        if (prevState.selectedStateFilter !== this.state.selectedStateFilter) {
            return this.init();
        }
        if (prevState.selectedIndustryFilter !== this.state.selectedIndustryFilter) {
            return this.init();
        }
    }

    init = async () => {
        this.setState({ isLoading: true });
        const [trainingsRegistered, allTrainings, allTasks, allIndustries] = await Promise.all([
            //apiv1/consumer/group-program/subscriptions
            this.props.tryGetUpcomingTrainingsRegisteredFor(),

            //apiv1/consumer/group-program/catalogue
            this.props.tryGetAllTrainings(
                this.state.selectedIndustryFilter ? this.state.selectedIndustryFilter.value : null,
                this.state.selectedStateFilter ? this.state.selectedStateFilter.map((s) => s.value) : null
            ),

            //apiv1/consumer/courses/all-my-content
            this.props.tryGetAllTasks(),

            //apiv1/consumer/group-program/industries/get-all
            this.props.tryGetAllIndustries(),
        ]);
        const trainingsRegisteredIds = trainingsRegistered.map((t) => t.groupProgram.id);
        const trainingsUpcomingWithStatus = allTrainings.map((t) => {
            return {
                ...t,
                canRegister: !trainingsRegisteredIds.includes(t.id),
            };
        });

        const showRequired = this.props.location.pathname === "/my-certificates/my-trainings";
        const validation = await this.props.certificationValidation();

        this.setState({
            validation: validation,
            showRequired: showRequired,
            industries: allIndustries,
            trainings: trainingsUpcomingWithStatus,
            trainingsShown: await this.distinctLiveAndOndemandTrainings(trainingsUpcomingWithStatus, allTasks),
            isLoading: false,
        });

        if (this.props.showToolTip && this.props.toolTipPage === 3) {
            this.executeScroll();
        }
    };

    getLiveTrainingMapping = (trainings, programVideoMappings) => {
        return trainings
            .filter(
                (t) => t.isAssetManagement && programVideoMappings.matchProgramAndVideo[t.id.toString()] === undefined
            )
            .map((t) => ({
                ...t,
                courseId: null,
                hasCourse: false,
                isCourseCompleted: false,
                quizId: null,
                isQuizCompleted: false,
                isCourseAssigned: t.courseAssignmentId !== undefined && t.courseAssignmentId !== null,
            }));
    };

    distinctLiveAndOndemandTrainings = async (trainings, allTasks) => {
        const mapping = await this.props.tryGetTrainingMapping();
        if (this.props.location.pathname === "/my-certificates/my-trainings/live") {
            return getLiveTrainingMapping(trainings, mapping);
        }
        // pathname === "/my-certificates/my-trainings
        return trainings
            .filter((t) => t.isAssetManagement && mapping.matchProgramAndVideo[t.id.toString()] !== undefined)
            .map((t) => {
                const courseId = mapping.matchProgramAndVideo[t.id.toString()] ?? null;
                const quizId = mapping.matchVideoAndQuiz[courseId.toString()] ?? null;

                const isVideoTaskCompleted =
                    allTasks.filter((a) => a.course.id.toString() === courseId.toString() && a.isCompleted).length > 0;

                const isCourseAssignedToUser =
                    allTasks.filter(
                        (a) =>
                            a.course.id.toString() === courseId.toString() &&
                            !a.isCompleted &&
                            !(a.courseAssignmentId === undefined || a.courseAssignmentId === null)
                    ).length > 0;

                const isQuizTaskCompleted =
                    allTasks.filter((a) => a.course.id.toString() === quizId.toString() && a.isCompleted).length > 0;

                return {
                    ...t,
                    courseId: courseId,
                    hasCourse: true,
                    isCourseCompleted: isVideoTaskCompleted,
                    quizId: quizId,
                    isQuizCompleted: isQuizTaskCompleted,
                    isCourseAssigned: isCourseAssignedToUser,
                };
            });
    };

    executeScroll = () =>
        this.myRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
        });

    render() {
        if (this.state.isLoading) {
            return (
                <LoadingCenter>
                    <LogoLoading />
                </LoadingCenter>
            );
        } else if (this.state.trainingsShown.length === 0) {
            return (
                <Col>
                    <FontBody21>Contact us to request access to Willow's trainings for CE credits</FontBody21>
                    <FitContent
                        onClick={() => {
                            this.props.setSupportOpen(true, 4);
                        }}
                    >
                        <ButtonPrimary canSubmit={true} label={"Contact us"} />
                    </FitContent>
                </Col>
            );
        } else {
            if (this.props.hideCert) {
                return (
                    <>
                        {this.state.trainingsShown
                            .filter((t) => !this.disabledTrainingIds.includes(t.id))
                            .map((t, index) => (
                                <PreviewEventsElectives
                                    key={index}
                                    t={t}
                                    timeZone={this.state.timeZone}
                                    validation={this.state.validation}
                                />
                            ))}
                    </>
                );
            } else {
                return (
                    <>
                        {this.state.showRequired && (
                            <>
                                {this.getRequiredTraining()
                                    .filter((t) => !this.disabledTrainingIds.includes(t.id))
                                    .map((a, index) => {
                                        return (
                                            <PreviewEventsRequired
                                                key={index}
                                                index={index}
                                                myRef={this.myRef}
                                                isCompletedCertificationProgram={this.props.completedCertificateIds.includes(
                                                    a.requiredForCertificationProgramId
                                                )}
                                                t={this.state.trainingsShown.find((t) => t.id === a.id)}
                                                timeZone={this.state.timeZone}
                                                validation={this.state.validation}
                                                requiredMessage={a.message}
                                                canTrainingBeTaken={this.isPrerequisiteTrainingCompleted(a)}
                                            />
                                        );
                                    })}
                            </>
                        )}
                        {/*{!this.state.showRequired &&*/}
                        {/*    this.getElectiveTrainings().map((t, index) => (*/}
                        {/*        <PreviewEventsElectives*/}
                        {/*            key={index}*/}
                        {/*            t={t}*/}
                        {/*            timeZone={this.state.timeZone}*/}
                        {/*            validation={this.state.validation}*/}
                        {/*        />*/}
                        {/*    ))}*/}
                    </>
                );
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    tryGetUpcomingTrainingsRegisteredFor: () => dispatch(tryGetUpcomingTrainingsRegisteredFor()),
    tryGetAllTrainings: (industryId, states) => dispatch(tryGetAllTrainings(true, industryId, states)),
    tryGetAllTasks: () => dispatch(tryGetAllTasks()),
    tryGetAllIndustries: () => dispatch(tryGetAllIndustries()),
    setSupportOpen: (isOpen, page) => dispatch(setSupportOpen(isOpen, page)),
    certificationValidation: () => dispatch(certificationValidation()),
    tryGetTrainingMapping: () => dispatch(tryGetTrainingMapping()),
});

const mapStateToProps = (state) => ({
    showToolTip: state.enterprise.tooltip.get("showToolTip"),
    toolTipPage: state.enterprise.tooltip.get("toolTipPage"),
    hideCert:
        state.common.user.get("directoryListings") === Map()
            ? false
            : state.common.user.get("directoryListings").get("practiceManagementUntil") !== null,
    currentCertification: state.common.user.getIn(["certificationProgress", "certificationDefinition"], Map()).toJS(),
    completedCertificateIds: state.common.user
        .get("completedCertificates")
        .map((cert) => cert.get("certificationDefinitionId")),
    completedTrainings: state.common.user
        .getIn(["certificationProgress", "details", "trainingStep", "completedTrainings"], List())
        .toJS()
        .map((c) => c.webinarProgramId),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FirmTrainingsPreviewEvents));
