import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';

/* Components */
import {SavedSettings} from '../../../common/components/cards';
import {FontBody16, FontTitle18} from '../../../common/components/fonts';
import {ButtonSecondary, ButtonTertiary} from "../../../common/components/buttons";
import {TextInput, ReadOnlyInput} from "../../../common/components/inputs";
import {LogoLoading} from "../../../common/components/loading";
import {FitContent, InlineBlock, SaveSection, SubmitInputWithValidation} from "../components/myprofile";

/* Middleware */
import {tryUpdateExpertProfile} from '../middleware/expertprofile';
import validator from "validator";
import {EditIcon} from "../components/pagescontact";
import {EditOutlined} from "@ant-design/icons";

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

class ProfileName extends React.Component {
    state = {
        companyName: '', companyNameValid: true, companyNamePrevious: "",
        isSaving: false,
        isSaved: false,
        error: '',
        isValid: true,
        isChanged: false,
        isLoading: true,
        isEdit: false
    };

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    init = async () => {
        const companyName = this.props.profile.get("companyName");
        await this.setState({
            "companyName": (companyName === null || companyName === undefined) ? "" : companyName,
            "companyNamePrevious": (companyName === null || companyName === undefined) ? "" : companyName,
            "isLoading": false,
            "isChanged": false
        });
    };

    reset = async () => {
        await this.setState({
            "companyName": this.state.companyNamePrevious,
            "isChanged": false,
            "isEdit": false
        });
    };

    edit = async (status) => {
        this.setState({"isEdit": status});
    };

    handleChangeCompanyName = async e => {
        await this.setState({'companyName': e.target.value, 'companyNameValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''), 'isChanged': true});
        // this.checkValid();
    };

    checkValid = async () => {
        if(this.state.companyNameValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            await this.setState({'isSaving': true, "error": ""});
            const companyName = {
                companyName: this.state.companyName
            };
            await this.props.tryUpdateExpertProfile(companyName, "errorCompanyName");
            if(this.props.profile.get("errorCompanyName") === "") {
                await this.setState({
                    'isSaving': false,
                    "isSaved": true,
                    "companyNamePrevious": this.state.companyName,
                    "isEdit": false
                });
                this.timeout = setTimeout(() => {this.setState({'isSaved': false, "isChanged": false})}, 3000);
            } else {
                this.setState({'isSaved': true, 'isSaving': false, "error": this.props.profile.get("errorCompanyName")});
                this.timeout = setTimeout(() => {this.setState({'isSaved': false, "error": ""})}, 3000);
            }
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    {this.state.isSaved
                        ?
                            <>
                                {this.state.error === '' && <SavedSettings height={"99px"} error={false} message={"Saved"}/>}
                                {this.state.error !== '' && <SavedSettings height={"99px"} error={true} message={this.state.error}/>}
                            </>
                        :
                            <>
                                {!this.state.isEdit &&
                                    <>
                                        <FontTitle18>Name</FontTitle18>
                                        <ReadOnlyInput
                                            title={"My Name"}
                                            value={this.props.profile.get("firstName")+" "+this.props.profile.get("lastName")}
                                        />
                                        <ReadOnlyInput
                                            title={"Company Name"}
                                            value={this.state.companyName}
                                        />
                                        <br/>
                                        <FitContent><FontBody16 onClick={() => {return this.edit(true)}}><ButtonSecondary canSubmit={false} label={<><EditOutlined/>&nbsp;Change Name</>} /></FontBody16></FitContent>
                                    </>
                                }
                                {this.state.isEdit &&
                                    <form method={"post"} onSubmit={this.handleSubmit}>
                                        <div>
                                            <FontTitle18>Name</FontTitle18>
                                            <ReadOnlyInput
                                                title={"My Name"}
                                                value={this.props.profile.get("firstName")+" "+this.props.profile.get("lastName")}
                                            />
                                            <TextInput
                                                title={"Company Name"}
                                                valid={this.state.companyNameValid}
                                                warning={""}
                                                id={"companyName"}
                                                onChange={this.handleChangeCompanyName}
                                                placeholder={"Company Name"}
                                                value={this.state.companyName}
                                            />
                                        </div>
                                        <SaveSection>
                                            <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                            <InlineBlock><div onClick={this.reset}><ButtonTertiary canSubmit={false} label={"Cancel"} /></div></InlineBlock>
                                        </SaveSection>
                                    </form>
                                }
                            </>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    profile: state.enterprise.shop.expertProfile.get("expertProfile"),
});

const mapDispatchToProps = dispatch => ({
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileName);
