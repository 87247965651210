import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

/* Middleware */
import { tryGetCertifiedAdvisors } from './middleware/directory';
import { tryPostMixpanel } from '../../common/utils/tracking';

/* Components */
import Card from './containers/card';
import IFrameAdvisorDirectoryIntro from "./containers/intro";
import IFrameAdvisorDirectoryNav from "./containers/nav";
import { FontBody16 } from "../../common/components/fonts";
import {LogoLoading} from "../../common/components/loading";

const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 60px;
`;
export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 60px);
  padding: 0 30px;
  gap: 60px;
  flex-wrap: wrap;
`;
const LoadingCenter = styled.div`
    margin: 60px auto;
`;
const Container = styled.div`
  width: 100%;
  height: 100svh;
  background-color: #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Form = styled.form`
  width: fit-content;
  padding: 40px;
  border-radius: 10px;
  background-color: white;
`;

export default function IFrameAdvisorDirectory() {
    const [cardList, setCardList] = useState({
        list: [],
        isLoading: true
    });
    const [isAuthed, setIsAuthed] = useState(false);
    const [isErrored, setIsErrored] = useState(false);
    const [pass, setPass] = useState("");
    const dispatch = useDispatch();
    const timeoutRef = useRef();

    useEffect(() => {
        return clearTimeout(timeoutRef.current)
    }, []);

    const shuffle = async (array) => {
        let currentIndex = array.length, randomIndex;
        // While there remain elements to shuffle.
        while (currentIndex > 0) {
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
        return array;
    }

    const init = async () => {
        const searchParams = new URLSearchParams(window.location.search);
        let t1 = searchParams.get("t1"); // anonymousId
        if (t1 === null || t1 === "null" || t1 === undefined || t1 === "") {
            t1 = uuidv4();
        }
        let t2 = searchParams.get("t2"); // requestType
        if (t2 === null || t2 === "null" || t2 === undefined || t2 === "") {
            t2 = "DIR";
        }

        let utm_source = searchParams.get('utm_source');
        let utm_medium = searchParams.get('utm_medium');
        let utm_campaign = searchParams.get('utm_campaign');
        let utm_content = searchParams.get('utm_content');
        let utm_term = searchParams.get('utm_term');

        utm_source = (utm_source === null || utm_source === "null" || utm_source === "") ? "willow" : utm_source;
        utm_medium = (utm_medium === null || utm_medium === "null" || utm_medium === "") ? "organic" : utm_medium;
        utm_campaign = (utm_campaign === null || utm_campaign === "null" || utm_campaign === "") ? "advisor_directory_page" : utm_campaign;
        utm_content = (utm_content === null || utm_content === "null" || utm_content === "") ? "generic" : utm_content;
        utm_term = (utm_term === null || utm_term === "null" || utm_term === "") ? "generic" : utm_term;

        const data = {
            "t1": t1,
            "t2": t2,
            "utm_source": utm_source,
            "utm_medium": utm_medium,
            "utm_campaign": utm_campaign,
            "utm_content": utm_content,
            "utm_term": utm_term
        }

        sessionStorage.setItem("t1", t1);
        sessionStorage.setItem("t2", t2);
        await dispatch(tryPostMixpanel(null, t1, "clientAcquisitionViewDirectory", data));
        const advisors = await dispatch(tryGetCertifiedAdvisors());
        const advisorsShuffled = await shuffle(advisors);
        const advisorsCleaned = await advisorsShuffled.reduce((filtered, a) => {
            let photos = a.advisor.additionalProfileInformation.photos.filter(p => { return (p !== null) })
            let logos = a.advisor.additionalProfileInformation.logos.filter(l => { return (l !== null) })
            let date = a.advisor.badActorDate;
            let advisorRecord = {
                ...a,
                ...a.advisor,
                ...a.advisor.additionalProfileInformation,
                photos: photos.length > 0 ? photos[0] : null,
                logos: logos.length > 0 ? logos[0] : null,
                priority: false, // is sponsored advisor
                active: true, // is advisor active (expiration not hit)
                minAssets: parseInt(a.advisor.minimumInvestableAssets),
                gr: a.advisor.gender
            };
            if (!date) {
                filtered.push(advisorRecord)
            }
            return filtered;
        }, []);
        setCardList({
            list: advisorsCleaned,
            isLoading: false
        })
    }

    if(!isAuthed) {
        return(
            <Container>
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    if(pass.toLowerCase() === "demodirectory") {
                        setIsAuthed(true)
                        init();
                    } else {
                        setIsErrored(true)
                        timeoutRef.current = setTimeout(() => {
                            setIsErrored(false)
                        }, 3000)
                    }
                }}>
                    <b>Please enter the password</b>
                    <br />
                    <br />
                    <input
                        type={"text"}
                        placeholder={"Password"}
                        onChange={(e) => {setPass(e.target.value)}}
                    />
                    <button>Submit</button>
                    {isErrored && <div><br />Wrong password</div>}
                </Form>
            </Container>
        )
    }

    if(cardList.isLoading) {
        return(<LoadingCenter><LogoLoading /></LoadingCenter>)
    }

    return (
        <Page>
            <IFrameAdvisorDirectoryNav />
                <IFrameAdvisorDirectoryIntro />
            {/*<IFrameAdvisorDirectoryFilter handleCardListChange={handleCardListChange} />*/}
            <CardContainer>
                {cardList.list.length === 0
                    ? <div style={{ margin: "0 auto" }}><FontBody16>No advisors found</FontBody16></div>
                    : <>
                        {cardList.list.map((advisor, index) => (
                            <Card key={index} advisor={advisor} allAdvisors={cardList.list} />
                        ))}
                    </>
                }
            </CardContainer>
        </Page>
    );
};