import React from 'react';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import {connect} from "react-redux";

/* Components */
import { colors } from '../../../common/components/colors';
import { FontBody18 } from '../../../common/components/fonts';

/* Middleware */
import {tryLogout} from '../../../routes/middleware/auth';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const NavSection = styled.div`
    padding: 10px 10px;
    width: fit-content;
`;
const Button = styled.div`
    ${props => props.status !== 'active'} {color: ${colors.backgroundColor4}; font-weight: bold} 
    ${props => props.status !== 'inactive'} {color: ${colors.white};}
    color: ${colors.primary100};
    padding-left: 10px;
    width: fit-content;
    cursor: pointer;
`;
const LogOut = styled.div`
    border-style: solid;
    border-color: white;
    border-radius: 30px;
    border-width: thin;
    margin: 35px auto 0 auto;
    width: fit-content;
    padding: 10px 60px;
    text-align: center;
    color: ${colors.white};
    cursor: pointer;
`;
const IconTextContainer = styled.div`
    display:flex; 
    flex-direction:row;
    align-items: center;
    padding-left: 15px;
    width: fit-content;
`
const SidebarIcon = styled.img`
    width: 40px;
    height: 40px;
    cursor: pointer;
`
const SideIconActiveOrNot = ({data, status}) => {
    if(status === "active") {
        return(<SidebarIcon src={data.navSideIconActive} alt={""} />)
    } else {
        return(<SidebarIcon src={data.navSideIconInactive} alt={""} />)
    }
};

const LinkSection = ({data, status}) => (
    <IconTextContainer>
        <SideIconActiveOrNot data={data} status={status}/>
        <Button status={status}>
            <FontBody18>{data.label}</FontBody18>
        </Button>
    </IconTextContainer>
)

class NavMenu extends React.Component {
    static defaultProps = {
        logoutButtonStyle: {},
        onOpenSupport: () => {},
    }

    onOpenSupport = async () => {
        // await this.props.setSupportOpen(true, 1);
        // this.props.onOpenSupport();
    }

    render() {
        return(
            <>
                {env.CONSUMER.NAV.nav.map((navSection, navSectionIndex) => (
                    <NavSection key={navSectionIndex}>
                        {navSection.map((d, index) => {
                            let status = "inactive";
                            if (this.props.location.pathname.split("/")[1] === d.href.split("/")[1]) {status = "active"}
                            if(d.showDesktop) {
                                if(d.href === "modalSupport") {
                                    return(
                                        <div key={index} onClick={this.onOpenSupport} style={{cursor: "default"}}>
                                            <LinkSection data={d} status={status} />
                                        </div>
                                    )
                                } else {
                                    return(
                                        <Link key={index} to={d.href} style={{cursor: "default"}}>
                                            <LinkSection data={d} status={status} />
                                        </Link>
                                    )
                                }
                            }
                        })}
                    </NavSection>
                ))}
                <LogOut onClick={this.props.tryLogout}><FontBody18>Log Out</FontBody18></LogOut>
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    tryLogout: () => dispatch(tryLogout())
});

export default connect(null, mapDispatchToProps)(withRouter(NavMenu));