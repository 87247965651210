const consumerFAQ = [
    {
        "id": 1,
        "title": "Common Questions",
        "faqs": [
            {
                "id": 12,
                "title": "What’s the Stars, Please! experience?",
                "body": "<div>Every engagement is tailored to the needs and desires of each client.</div>"
            },
            {
                "id": 8,
                "title": "How does the matching process work?",
                "body": "<div>When joining the Stars, Please! platform, each psychic advisor demonstrates their areas of knowledge, experience and interests. When signing up, clients are asked to share information about their background and desires. The Stars, Please! matching process uses these inquiries to bring together both clients and psychic advisors.</div>"
            },
            {
                "id": 1,
                "title": "Are sessions audio or video?",
                "body": "<div>Both are available! Although video calls often provide a platform for a more immediate connection, we know that it may not always be possible to be on camera.</div>"
            },
            {
                "id": 5,
                "title": "I need technical support. Who do I contact?",
                "body": "<div><a data-link-type='internal' data-label='Reach out to us here' href='/support/contact'>Reach out to us here</a> and we’ll get back to you ASAP (within 24 hours) to help troubleshoot technical issues.</div>"
            },
            {
                "id": 2,
                "title": "What should I do if I feel uncomfortable working with my psychic advisor?",
                "body": "<div>While we hope that won’t happen, know that we’re here to support you. Please <a data-link-type='internal' data-label='contact us here' href='/support/contact'>contact us here</a> if you need assistance.</div>"
            },
        ]
    },
    {
        "id": 3,
        "title": "Account & Privacy",
        "faqs": [
            {
                "id": 18,
                "title": "How do I change my password?",
                "body": "<div>You can conveniently update your password at any time on your Dashboard. <a data-link-type='internal' data-label='click here' href='/settings/security'>click here</a>.</div>"
            },
            {
                "id": 19,
                "title": "How does Stars, Please! keep client information confidential?",
                "body": "<div>Stars, Please! is protected by 256-bit SSL encryption, firewalls, and monitoring. We take data security seriously. To view our full Privacy Policy <a data-link-type='internal' data-label='click here' href='/privacy'>click here</a>.</div>"
            },
            {
                "id": 20,
                "title": "Do you record coaching calls?",
                "body": "<div>Yes. Stars, Please! records all audio and video calls. The recordings are not publicly accessible and are used to ensure we’re providing high-quality service to Stars, Please! clients. From time to time, Stars, Please! may also review recordings to help us improve our platform. To view our full Privacy Policy <a data-link-type='internal' data-label='click here' href='/privacy'>click here</a>.</div>"
            },
            {
                "id": 21,
                "title": "How are you made aware they are on a recorded line?",
                "body": "<div>At the start of each call there is a recording or notification stating that lines are recorded. Clients must also review and accept the Privacy Policy.</div>"
            }
        ]
    }
];

export default consumerFAQ;