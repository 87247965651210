import React from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import moment from 'moment/moment';
import {withRouter, Link} from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import {Map} from 'immutable';

/* Components */
import {ActionBox, EditIcon, FitWidthButton, InlineItemLeft} from "../../components/clientsdetail";
import {FontHeader16, FontHeader21, FontTitle18, FontBody16} from "../../../common/components/fonts";
import {LogoLoading, LogoLoadingSmall} from "../../../common/components/loading";
import {ButtonPrimary, ButtonSecondary} from "../../../common/components/buttons";
import {Center, Photo, Border} from '../../components/contactcoaching';
import {ReadOnlyValueInput} from "../../../common/components/inputs";
import {colors} from '../../../common/components/colors';

/* Middleware */
import { tryGetAllSessions } from '../../meetings/middleware/sessions';
import { tryGetAllSessionsLarge } from '../../meetings/middleware/group';
import { tryGetSettingsPersonalInfo } from "../../middleware/settingspersonalinfo";

import {setSessionsModal} from '../../meetings/store/sessions';
import {setGroupSessionOne} from '../../meetings/store/group';

/* Components */
const Section = styled.div`
    padding: 20px;
    background-color: ${colors.border30};
    max-height: 100%;
    @media screen and (max-width: 460px) {
        padding: 5px;
    }
`;
const DateBox = styled.div`
    margin-bottom: 10px;
    padding-left: 15px;
`;
const Empty = styled.div`
    margin: 30px;
    color: ${colors.primary70};
    text-align: center;
`;
const LoadMore = styled.div`
    text-align: center;
    margin: 60px auto 0px auto;
    width: fit-content;
`;

class MeetingReminders extends React.Component {
    state={
        "isLoading": true,
        "meetings": [],
        "meetingsSorted": []
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps) {
    
    }

    componentWillUnmount() {
        
    }

    init = async () => {
        if(this.props.timeZone === "") {
            await this.props.tryGetSettingsPersonalInfo();
        }
        await this.props.tryGetAllSessions()
        await this.props.tryGetAllSessionsLarge()
        const allMeetings = await this.props.sessions.concat(this.props.groupSessionsAll);
        await this.setState({"meetings": allMeetings});
        return this.update()
    };

    update = async () => {
        await this.setState({"isLoading": true});
        await this.filterActivity();
        return this.setState({"isLoading": false});
    };

    filterActivity = async () => {
        await this.setState({"meetingsSorted": []});
        const activityOrdered = await this.state.meetings.sort((a, b) => {
            return moment(a.start).diff(moment(b.start))
        });
        const activityFiltered = await activityOrdered.filter((a) => {
            return ((moment().isBefore(moment(a.start))) && a.videoStatus !== "conference_canceled")
        });
        await this.setState({"meetingsSorted": activityFiltered});
    };

    onView = async (a) => {
        if(a.meetingType === "session") {
            await this.props.setSessionsModal(true, {...a, extendedProps: {remoteUserId: a.remoteUserId}}, "view", null, null, null, false);
            this.props.history.push("/meetings/details");
        } else {
            const eventCombine = {...a, "start": a.start, "end": a.end, "id": a.id, "title": a.title};
            await this.props.setGroupSessionOne(eventCombine);
            this.props.history.push("/meetings/page");
        }
    }


    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <ActionBox>
                    <div>
                        <InlineItemLeft><FontTitle18>Upcoming Events</FontTitle18></InlineItemLeft>
                    </div>
                    <br />
                    <div>
                    {this.state.meetingsSorted.length === 0 ?
                        <Center><FontHeader16>{"No upcoming meetings"}</FontHeader16></Center>
                    :
                        <>
                            {this.state.meetingsSorted.map((a, aIndex) => (
                                <LazyLoad offset={200} key={aIndex} placeholder={<LogoLoadingSmall />} >
                                    <div key={aIndex}>
                                        {aIndex < 3 &&
                                            <>
                                                {a.meetingType === 'session' ?
                                                    <>
                                                        <Center><FontHeader21>{(a.topic !== "" && a.topic !== null && a.topic !== undefined) ? a.topic : "Meeting with " + (a.remoteUserFirstName !== null ? a.remoteUserFirstName : "Contact")}</FontHeader21></Center>
                                                        <ReadOnlyValueInput title={"Date"} value={moment(a.start).format('MMM Do YYYY, h:mm a')} />
                                                        <ReadOnlyValueInput title={"Session Length"} value={moment(a.end).diff(a.start, 'minutes') + " minutes"} />
                                                        <FitWidthButton onClick={() => {return this.onView(a)}}><ButtonSecondary canSubmit={false} label={"View"} /></FitWidthButton>
                                                    </>
                                                :
                                                    <>
                                                        <Center><FontHeader21>{a.title}</FontHeader21></Center>
                                                        <ReadOnlyValueInput title={"Date"} value={moment(a.start).format('MMM Do YYYY, h:mm a')} />
                                                        <ReadOnlyValueInput title={"Webinar Length"} value={moment(a.end).diff(a.start, 'minutes') + " minutes"} />
                                                        <FitWidthButton onClick={() => {return this.onView(a)}}><ButtonSecondary canSubmit={false} label={"View"} /></FitWidthButton>
                                                    </>
                                                }
                                                <Border />
                                            </>
                                        }   
                                    </div>
                                </LazyLoad>
                            ))}
                            <FitWidthButton><Link to={"/meetings"}><ButtonPrimary canSubmit={false} label={"View All"} /></Link></FitWidthButton>
                        </>
                    }
                    </div>
                </ActionBox>
            )
        }
    }
}

const mapStateToProps = state => ({
    sessions: state.enterprise.sessions.get("sessions").toJS(),
    groupSessionsAll: state.enterprise.meetings.group.get("groupSessionsAll").toJS(),
    timeZone: state.enterprise.settingsPersonal.get("settingsPersonalInfo").get("timeZone"),
});

const mapDispatchToProps = dispatch => ({
    tryGetAllSessions: () => dispatch(tryGetAllSessions()),
    tryGetAllSessionsLarge: () => dispatch(tryGetAllSessionsLarge()),
    tryGetSettingsPersonalInfo: () => dispatch(tryGetSettingsPersonalInfo()),
    setGroupSessionOne: (session) => dispatch(setGroupSessionOne(session)),
    setSessionsModal: (status, session, action, who, details, guests, eventApi) => dispatch(setSessionsModal(status, session, action, who, details, guests, eventApi)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MeetingReminders));