import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';

/* Components */
import {UploadAvatar} from "../../../common/components/inputs";
import {SavedSettings} from '../../../common/components/cards';
import {FontTitle18, FontBody16} from '../../../common/components/fonts';
import {colors} from '../../../common/components/colors';
import {ButtonSecondary, ButtonTertiary} from "../../../common/components/buttons";
import {LogoLoading} from "../../../common/components/loading";
import {InlineBlock, SaveSection, SubmitInputWithValidation} from "../components/myprofile";
import {EditOutlined} from '@ant-design/icons';

/* Middleware */
import {tryUpdateExpertProfile} from '../middleware/expertprofile';

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

const FitContent = styled.div`
    width: fit-content;
`;

const Image = styled.img`
    width: 85px;
    height: 85px;
    object-fit: cover;
    object-position: 0 0;
    border-radius: 240px;
`;

const Blue = styled.div`
    color: ${colors.action100};
    cursor: pointer;
`;

class ProfilePhoto extends React.Component {
    state = {
        photo: '', photoComponent: '', photoChanged: false, photoBlob: '', photoPrevious: "", photoComponentPrevious: "",
        isSaving: false,
        isSaved: false,
        error: '',
        isValid: true,
        isChanged: false,
        isLoading: true,
        edit: false
    };

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    init = async () => {
        await this.setState({
            "photo": this.props.profile.get("photo"),
            "photoComponent": <UploadAvatar photo={this.props.profile.get("photo")} onChange={this.handleChangePhoto} />,
            "photoPrevious": this.props.profile.get("photo"),
            "photoComponentPrevious": <UploadAvatar photo={this.props.profile.get("photo")} onChange={this.handleChangePhoto} />,
            "isLoading": false
        });
    };

    handleChangePhoto = async e => {
        this.setState({'photoChanged': true, 'photoBlob': e, 'isChanged': true});
    };

    reset = async () => {
        this.setState({
            "photo": this.state.photoPrevious,
            "photoComponent": <UploadAvatar photo={this.state.photoPrevious} onChange={this.handleChangePhoto} />,
            "photoChanged": false,
            "isChanged": false
        });
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            await this.setState({'isSaving': true, "error": ""});
            const photo = {
                "photoBlob": this.state.photoBlob,
                "photoChanged": this.state.photoChanged
            };
            await this.props.tryUpdateExpertProfile(photo, "profilePhotoError");
            if(this.props.profile.get("profilePhotoError") === "") {
                this.setState({
                    'isSaving': false,
                    "isSaved": true,
                    "photo": this.props.profile.get("photo"),
                    "photoComponent": <UploadAvatar photo={this.props.profile.get("photo")} onChange={this.handleChangePhoto} />,
                    "photoBlob": "",
                    "photoChanged": false,
                    "photoPrevious": this.props.profile.get("photo"),
                    "photoComponentPrevious": <UploadAvatar photo={this.props.profile.get("photo")} onChange={this.handleChangePhoto} />,
                    "edit": false
                });
                this.timeout = setTimeout(() => {this.setState({'isSaved': false, "isChanged": false})}, 3000);
            } else {
                this.setState({'isSaved': true, 'isSaving': false, "error": this.props.profile.get("profilePhotoError")});
                this.timeout = setTimeout(() => {this.setState({'isSaved': false, "error": ""})}, 3000);
            }
        }
    };

    edit = async (status) => {
        this.setState({"edit": status});
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if(this.state.isSaved) {
                return(
                    <>
                        <FontTitle18>Profile Photo</FontTitle18>
                        {this.state.error === '' && <SavedSettings height={"99px"} error={false} message={"Saved"}/>}
                        {this.state.error !== '' && <SavedSettings height={"99px"} error={true} message={this.state.error}/>}
                    </>
                )
            } else {
                if(!this.state.edit) {
                    return(
                        <div>
                            <FontTitle18>Profile Photo</FontTitle18>
                            <br />
                            <Image src={this.state.photo} alt={"photo"} />
                            <br />
                            <br />
                            <FitContent><FontBody16 onClick={() => {return this.edit(true)}}><ButtonSecondary canSubmit={false} label={<><EditOutlined/>&nbsp;Change Profile Photo</>} /></FontBody16></FitContent>
                        </div>
                    )
                } else {
                    return(
                        <form method={"post"} onSubmit={this.handleSubmit}>
                            <div>
                                <FontTitle18>Profile Photo</FontTitle18>
                                {this.state.photoComponent}
                            </div>
                            {/*{this.state.isChanged &&*/}
                            <SaveSection>
                                <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                <InlineBlock><div onClick={() => {return this.edit(false)}}><ButtonTertiary canSubmit={false} label={"Cancel"} /></div></InlineBlock>
                            </SaveSection>
                            {/*}*/}
                        </form>
                    )
                }
            }
        }
    }
}

const mapStateToProps = state => ({
    profile: state.enterprise.shop.expertProfile.get("expertProfile"),
});

const mapDispatchToProps = dispatch => ({
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePhoto);
