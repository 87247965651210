import React from 'react';

/* Containers */
import Billing from './containers/billing';

/* Navs */
import {PageContent, PageFullSettings, PageRight} from "../navbars/components/navlayout";
import NavPrimaryAllSettings from "../navbars/containers/primaryallsettings";
import NavSecondaryTop from "../navbars/containers/secondarytop";

const BillingPage = () => (
    <>
        <NavPrimaryAllSettings />
        <PageFullSettings>
            <PageRight>
                <NavSecondaryTop title={"Billing"} />
                <PageContent>
                    <Billing />
                </PageContent>
            </PageRight>
        </PageFullSettings>
    </>
);

export default BillingPage;
