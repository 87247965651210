import React from 'react';
import {connect} from "react-redux";
import Board from 'react-trello';
import moment from 'moment/moment';
import {withRouter} from 'react-router-dom';
import {v4 as uuid4} from 'uuid';

// https://github.com/rcdexta/react-trello

/* Middleware */
import { tryGetProspectStages, tryUpdateProspectStage } from "../middleware/prospectsstages";
import { tryGetAllProspects, tryArchiveProspect } from '../middleware/prospects';
import { tryArchiveContact } from "../middleware/contacts";

/* Store */
import { setClientsDetailSelected } from "../store/clientsdetail";

/* Components */
import { LogoLoading } from "../../common/components/loading";
import { Notice, StyledBoard, FilterSection, OneFilter } from "../components/prospects";
import {Message} from "../../common/components/messages";
import ProspectCustomCard from '../components/prospectscard';
import ProspectsLaneHeader from '../components/prospectslaneheader';
import FilterDropdown from "../../common/components/filterdropdown";
import FilterRange from "../../common/components/filterrange";
import FilterRadio from "../../common/components/filterradio";
import {EditOutlined, MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";

/* Utils */
import {stringToMoney} from '../../common/utils/money';

const fitData = (stages, prospects, archive, edit, isWMC, userId) => ({
    lanes: stages.map(s => {
        let stageOpportunity = 0;
        let cards = prospects.reduce((filtered, p) => {
            if(s.get("id") === p.prospectStageId) {
                // console.log(p.name);
                // console.log(p.revenue);
                let nextSession = p.nextSession === null ? "Next:        ---" : "Next:        " + moment(p.nextSession.start).format("lll").toString();
                let lastSession = p.lastSession === null ? "Last:         ---" : "Last:         " + moment(p.lastSession.start).fromNow();
                // let advisor = p.advisorName === null ? "Advisor:   ---" : "Advisor:   " + p.advisorName;
                // let coach = p.coachName === null ? "Coach:     ---" : "Coach:     " + p.coachName;
                let assets = (p.revenue.toString() === "0.00" || p.revenue === "$0" || p.revenue.toString() === "0") ? (isWMC ? "Assets:    ---" : "Revenue: ---") : (isWMC ? ("Assets:   $"+stringToMoney(p.revenue)) : ("Revenue: $"+stringToMoney(p.revenue)));
                stageOpportunity = stageOpportunity + parseInt(p.revenue);
                filtered.push({
                    "laneId": s.get("id").toString(),
                    "id": p.prospectId.toString(),
                    "title": p.name,
                    "description": (p.isCoachHasAdvisor ? "" : (assets + "\n")) + nextSession + "\n" + lastSession, // + "\n" + advisor  + "\n" + coach,
                    "users": p.users,
                    "className": "react-trello-card",
                    "menuOptions": {"1": archive, "2": edit},
                    "isCoachHasAdvisor": p.isCoachHasAdvisor,
                    "menuLabels": {
                        "1": {
                            "label": p.isArchived 
                                ? <React.Fragment key={uuid4()}><PlusCircleOutlined key={uuid4()}/>&nbsp;Not Lost</React.Fragment> 
                                : <React.Fragment key={uuid4()}><MinusCircleOutlined key={uuid4()}/>&nbsp;Lost</React.Fragment>, 
                            "active": isWMC ? true : (p.advisor === null ? true : p.advisor.advisorId === userId), 
                            "show": true
                        },
                        "2": {
                            "label": <React.Fragment key={uuid4()}><EditOutlined key={uuid4()}/>&nbsp;Edit Contact</React.Fragment>, 
                            "active": isWMC ? true : (p.referralSource !== "willow" && (p.advisor === null ? true : p.advisor.advisorId === userId)), 
                            "show": true
                        }
                    },
                    "allData": p
                })
            }
            return filtered;
        }, []);
        return ({
            ...s.toJS(),
            "id": s.get("id").toString(),
            "label": cards.length.toString(),
            "cards": cards,
            "title": "Move prospects with drag-and-drop",
            "extra": {
                "title": s.get("title").charAt(0).toUpperCase() + s.get("title").slice(1),
                "revenue": isWMC ? ("Assets: $" + stringToMoney(stageOpportunity)) : ("Revenue: $" + stringToMoney(stageOpportunity))
            }
        })
    }).toJS()
});

const filterStages = (stages) => {
    if(stages.size > 0) {
        let customStages = stages.filter(s => {return (s.get("title") !== "client" && s.get("title") !== "undefined");});
        let undefinedStage = stages.filter(s => {return (s.get("title") === "undefined");}).get(0);
        return customStages.unshift(undefinedStage);
    } else {
        return stages;
    }
};

const uniqueInArrayById = (arr) => {
    return arr.reduce((filtered, c) => {
        const x = filtered.find(item => item.id === c.id);
        if (!x) {
            filtered.push(c);
        }
        return filtered;
    }, []);
};

class ProspectsAll extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "isLoading": true,
            "prospects": {},
            "filters": {
                "Status": {
                    "options": [{"id": 1, "name": "Current"}, {"id": 2, "name": "Lost"}],
                    "selected": [1]
                },
                "Probability": {
                    "options": {"min": 0, "max": 100},
                    "selected": {"min": 0, "max": 100}
                },
                "Assets": {
                    "options": {"min": 0, "max": 999},
                    "selected": {"min": 0, "max": 999}
                },
                "Revenue": {
                    "options": {"min": 0, "max": 999},
                    "selected": {"min": 0, "max": 999}
                },
                "View": {
                    "options": [{"id": 2, "name": "Primary Contact"}, {"id": 1, "name": "Company"}],
                    "selected": [2]
                }
            }
        };
    }

    componentDidMount() {
        return this.refresh();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.filters !== this.state.filters || prevProps.prospectsAll !== this.props.prospectsAll || prevProps.stages !== this.props.stages) {
            return this.update();
        }
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    refresh = async () => {
        if(!this.props.prospectStagesFetched) {
            await this.props.tryGetProspectStages();
            await this.props.tryGetAllProspects();
        }
        if(!this.props.prospectStagesFetched || !this.props.prospectsAllFetched) {
            await this.props.tryGetAllProspects();
        }
        return this.update();
    };

    update = async () => {
        this.setState({
            "prospects": this.filter(this.props.prospectsAll),
            "isLoading": false
        });
    };

    updateFilter = async (filterTitle, filterSelected) => {
        await this.setState({
            "filters": {
                ...this.state.filters,
                [filterTitle]: {
                    ...this.state.filters[filterTitle],
                    "selected": filterSelected
                }
            }
        });
    };

    eachFilter = (filterTitle) => {
        return this.state.filters[filterTitle].options.reduce((f, o) => {
            if(this.state.filters[filterTitle].selected.includes(o.id)) {
                if(o.name === "Current") {f.push(true)}
                if(o.name === "Lost") {f.push(false)}
            }
            return(f);
        }, []);
    };

    filter = (prospects) => {
        // Check filters
        let filterStatus = this.eachFilter("Status");
        // Filter prospects
        let prospectsFiltered = prospects.reduce((filtered, p) => {
            let multiple = this.props.isWMC ? 1000000 : 1000;
            let revenueAssetFilter = this.props.isWMC ? "Assetgs": "Revenue";
            let primaryContactName;
            if ((p.get("referralSource") === "willow" && !this.props.isWMC) ||(p.get("referralSource") === "wmc" && !this.props.isWMC)) {
                primaryContactName = p.get("firstName") + " " + p.get("lastName").charAt(0) + ".";
            } else {
                primaryContactName = p.get("firstName") + " " + p.get("lastName");
            }
            let companyName = p.get("companyName");
            let hasCompanyName = (p.get("companyName") !== "" && p.get("companyName") !== null && p.get("companyName") !== undefined);
            let primaryContactNameSelected = this.state.filters.View.selected[0] === 2;
            let advisorIsWMC = p.get("advisor") === null ? null : p.get("advisor").get("firstName") + " " + p.get("advisor").get("lastName");
            let advisorNotWMC = p.get("advisor") === null ? null : p.get("advisor").get("advisorId") === this.props.userId ? null : p.get("advisor").get("firstName") + " " + p.get("advisor").get("lastName");
            let isCoachHasAdvisor = (!this.props.isWMC && advisorNotWMC !== null);
            let isCoachNoAdvisor = (!this.props.isWMC && advisorNotWMC === null);
            let hasCoach = p.get("coaches") === undefined ? false : (p.get("coaches").size === 0 ? false : (p.get("coaches").filter(coach => {return(coach.get("wmcId") === null)}).size > 0));
            let isAdvisorHasCoach = (this.props.isWMC && hasCoach);
            let isAdvisorNoCoach = (this.props.isWMC && !hasCoach);
            let coachName = p.get("coaches") === undefined ? null : (p.get("coaches").size === 0 ? null : (p.get("coaches").size === 1 ? p.get("coaches").get(0).get("fullName") : "Multiple"));
            let users = p.get("advisor") === null ? [] : (p.get("advisor").get("advisorId") === this.props.userId ? [] : [{...p.get("advisor").toJS(), "id": p.get("advisor").get("advisorId")}]);
            if(p.get("coaches") !== null && p.get("coaches").size > 0) {
                let coachTeam = p.get("coaches").reduce((filt, d) => {
                    if(d.get("coachId") !== this.props.userId) {
                        filt.push({...d.toJS(), "id": d.get("coachId")})
                    }
                    return filt;
                }, []);
                users.push(...coachTeam);
            }
            let isActive = p.get("isActive");
            if(
                ((filterStatus.includes(!p.get("isArchived")) || filterStatus.length === 0) &&
                (parseInt(p.get("probabilityOfClosing")) >= this.state.filters.Probability.selected.min && parseInt(p.get("probabilityOfClosing")) <= this.state.filters.Probability.selected.max) &&
                (isCoachHasAdvisor || (parseInt(p.get("revenue")) >= (this.state.filters[revenueAssetFilter].selected.min*multiple) && parseInt(p.get("revenue")) <= (this.state.filters[revenueAssetFilter].selected.max*multiple)))) &&
                isActive
            ) {
                filtered.push({
                    ...p.toJS(),
                    "name": primaryContactNameSelected ? primaryContactName : (hasCompanyName ? companyName : primaryContactName),
                    // "advisorName": !this.props.isWMC ? advisorNotWMC : advisorIsWMC,
                    // "coachName": !this.props.isWMC ? this.props.userName : coachName,
                    "users": uniqueInArrayById(users),
                    "probability": parseInt(p.get("probabilityOfClosing")),
                    "isCoachHasAdvisor": isCoachHasAdvisor,
                    "isCoachNoAdvisor": isCoachNoAdvisor,
                    "isAdvisorHasCoach": isAdvisorHasCoach,
                    "isAdvisorNoCoach": isAdvisorNoCoach
                })
            }
            return filtered;
        }, []);
        // Finish
        return fitData(filterStages(this.props.stages), prospectsFiltered, this.archive, this.edit, this.props.isWMC, this.props.userId);
    };

    view = (cardId, metadata, laneId) => {
        this.props.prospectsAll.map(async p => {
            if(p.get("prospectId").toString() === cardId.toString()) {
                let hasCoach = p.get("coaches") === undefined ? false : (p.get("coaches").size === 0 ? false : (p.get("coaches").filter(coach => {return(coach.get("wmcId") === null)}).size > 0));
                let isAdvisorHasCoach = (this.props.isWMC && hasCoach);
                let isAdvisorNoCoach = (this.props.isWMC && !hasCoach);
                let advisorNotWMC = p.get("advisor") === null ? null : p.get("advisor").get("advisorId") === this.props.userId ? null : p.get("advisor").get("firstName") + " " + p.get("advisor").get("lastName");
                let isCoachHasAdvisor = (!this.props.isWMC && advisorNotWMC !== null);
                let isCoachNoAdvisor = (!this.props.isWMC && advisorNotWMC === null);
                let pAdj = {
                    ...p.toJS(),
                    "isCoachHasAdvisor": isCoachHasAdvisor,
                    "isCoachNoAdvisor": isCoachNoAdvisor,
                    "isAdvisorHasCoach": isAdvisorHasCoach,
                    "isAdvisorNoCoach": isAdvisorNoCoach
                };
                await this.selectClient(pAdj);
                this.props.history.push(/*isCoachNoAdvisor ? "/contact/notes" : */"/contact/instructions");
            }
        })
    };

    selectClient = async (data) => {
        this.props.setClientsDetailSelected({
            "contactId": data.contactId,
            "prospectId": data.prospectId,
            "consumerId": data.consumerId,
            "businessUserId": data.businessUserId === undefined ? null : data.businessUserId,
            "firstName": data.firstName,
            "lastName": data.lastName,
            "prospectStatus": data.prospectStageName === "client" ? "Client" : "Prospect", //"Prospect",
            "revenue": data.revenue,
            "probabilityOfClosing": data.probabilityOfClosing,
            "companyName": data.companyName,
            "isCoachHasAdvisor": data.isCoachHasAdvisor,
            "isCoachNoAdvisor": data.isCoachNoAdvisor,
            "isAdvisorHasCoach": data.isAdvisorHasCoach,
            "isAdvisorNoCoach": data.isAdvisorNoCoach,
            "referralSource": data.referralSource
        })
    };

    move = (fromLaneId, toLaneId, cardId, index) => {
        this.props.tryUpdateProspectStage({"prospectId": parseInt(cardId), "stageId": parseInt(toLaneId), "fromStatus": "Prospect", "toStatus": "Prospect"});
    };

    archive = async (data) => {
        if(this.props.isWMC) {
            if(data.consumerId !== null) {
                this.props.tryArchiveProspect({...data, "id": data.consumerId});
            }
            if(data.contactId !== null) {
                this.props.tryArchiveContact({...data, "id": data.contactId});
            }
        } else {
            if(data.advisor === null) {
                this.props.tryArchiveProspect({...data, "id": data.consumerId});
            } else {
                if(this.props.userId === data.advisor.advisorId) {
                    this.props.tryArchiveProspect({...data, "id": data.consumerId});
                } else {
                    this.setState({"showNotice": true});
                    this.timeout = setTimeout(() => {this.setState({'showNotice': false})}, 3000);
                }
            }
        }
    };

    edit = async (data) => {
        await this.selectClient(data);
        this.props.history.push("/contact/edit");
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading/>)
        } else {
            if (!this.props.prospectsAll) {
                return (<Message text={"Create your first prospect"} />)
            } else {
                return (
                    <>
                        <FilterSection>
                            <OneFilter><FilterDropdown
                                label={"Status"}
                                action={this.updateFilter}
                                title={"Status"}
                                list={this.state.filters.Status.options}
                                initSelected={this.state.filters.Status.selected}
                            /></OneFilter>
                            <OneFilter><FilterRange
                                label={"Chance to win prospect"}
                                action={this.updateFilter}
                                title={"Probability"}
                                minMax={this.state.filters.Probability.options}
                                initSelected={this.state.filters.Probability.selected}
                                type={"%"}
                                multiple={1}
                                suffix={""}
                            /></OneFilter>
                            <OneFilter><FilterRange
                                label={this.props.isWMC ? "Household size (millions)" : "Potential revenue (thousands)"}
                                action={this.updateFilter}
                                title={this.props.isWMC ? "Assets" : "Revenue"}
                                minMax={this.state.filters[(this.props.isWMC ? "Assets" : "Revenue")].options}
                                initSelected={this.state.filters[(this.props.isWMC ? "Assets" : "Revenue")].selected}
                                type={"$"}
                                multiple={this.props.isWMC ? 1000000 : 1000}
                                suffix={this.props.isWMC ? "m" : "k"}
                            /></OneFilter>
                            <OneFilter><FilterRadio
                                label={"View"}
                                action={this.updateFilter}
                                title={"Choose view"}
                                list={this.state.filters.View.options}
                                initSelected={this.state.filters.View.selected}
                            /></OneFilter>
                        </FilterSection>
                        <StyledBoard>
                            <Board
                                data={this.state.prospects}
                                cardDraggable={true}
                                laneDraggable={false}
                                hideCardDeleteIcon={true}
                                onCardClick={this.view}
                                onCardMoveAcrossLanes={this.move}
                                components={{Card: ProspectCustomCard, LaneHeader: ProspectsLaneHeader}}
                                laneDropClass={"dndReceiptLocation"}
                            />
                        </StyledBoard>
                        {this.state.showNotice &&
                            <Notice title={"Unable to archive"} body={"Clients and prospects of financial advisors cannot be archived."} />
                        }
                    </>
                )
            }
        }
    }
}

const mapStateToProps = state => ({
    stages: state.enterprise.prospects.get("prospectStages"),
    prospectsAllFetched: state.enterprise.prospects.get("prospectsAllFetched"),
    prospectStagesFetched: state.enterprise.prospects.get("prospectStagesFetched"),
    prospectsAll: state.enterprise.prospects.get("prospectsAll"),
    isWMC: state.common.wmc.get("isWMC"),
    userId: state.common.user.get("userId"),
    userName: state.common.user.get("first") + " " + state.common.user.get("last")
});
const mapDispatchToProps = dispatch => ({
    tryGetProspectStages: () => dispatch(tryGetProspectStages()),
    tryGetAllProspects: () => dispatch(tryGetAllProspects()),
    setClientsDetailSelected: (data) => dispatch(setClientsDetailSelected(data)),
    tryUpdateProspectStage: (data) => dispatch(tryUpdateProspectStage(data)),
    tryArchiveProspect: (data) => dispatch(tryArchiveProspect(data)),
    tryArchiveContact: (data) => dispatch(tryArchiveContact(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProspectsAll));

// min-height: 350px;
// height: calc(100vh - 250px);