import images from '../styles/images';

// object key = course category id
// array of numbers = coach expert category ids that match course category id
export const convertCourseIdToExpertId = {
    "31": [36],
    "33": [37],
    "34": [38],
    "45": [41],
    "6": [14],
    "7": [15],
    "8": [16],
    "9": [17],
    "10": [18],
    "12": [20],
    "13": [21],
    "11": [19],
    "15": [51],
    "38": [50],
    "37": [22],
    "17": [24],
    "27": [32],
    "28": [33],
    "29": [34],
    "40": [49],
    "16": [23],
    "18": [47],
    "39": [48],
    "20": [26],
    "21": [27],
    "22": [28],
    "24": [29],
    "25": [30],
    "26": [31],
    "30": [35],
    "1": [4]
};

// Object key = expert category ID
// value = course category parent
// findParentCourseCategoryFromExpertCategories
export const parentCourseCategory = {
    "36": "Career",
    "37": "Career",
    "38": "Career",
    "41": "Retirement",
    "14": "Financial Wellness",
    "15": "Financial Wellness",
    "16": "Financial Wellness",
    "17": "Financial Wellness",
    "18": "Financial Wellness",
    "20": "Financial Wellness",
    "21": "Financial Wellness",
    "19": "Financial Wellness",
    "51": "Dating & Marriage",
    "50": "Dating & Marriage",
    "22": "Dating & Marriage",
    "24": "Dating & Marriage",
    "32": "Family Building",
    "33": "Family Building",
    "34": "Family Building",
    "49": "Divorce",
    "23": "Divorce",
    "47": "Divorce",
    "48": "Divorce",
    "26": "Grief & Loss",
    "27": "Grief & Loss",
    "28": "Grief & Loss",
    "29": "Grief & Loss",
    "30": "Financial Wellness",
    "35": "Financial Wellness",
    "53": "LGBTQ+",
    "54": "NextGen",
}

export const expertSubCategories = [51, 50, 49, 48, 47, 41, 38, 37, 36, 35, 34, 33, 32, 30, 29, 28, 27, 26, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14];

// const coachingOptions = [
//     {
//         "id": 5,
//         "title": "Career & Retirement",
//         "subtitle": "",
//         "imageUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636737878/o9lmuiryvamia3mj2xh4.jpg",
//         "subcategories": [
//             {
//                 "id": 31,
//                 "title": "Career advancement",
//                 "subtitle": "",
//                 "coachCategories": [36]
//             },
//             {
//                 "id": 33,
//                 "title": "Entrepreneurship",
//                 "subtitle": "",
//                 "coachCategories": [37]
//             },
//             {
//                 "id": 34,
//                 "title": "Starting/running a business",
//                 "subtitle": "",
//                 "coachCategories": [38]
//             },
//             {
//                 "id": 35,
//                 "title": "Retirement",
//                 "subtitle": "",
//                 "coachCategories": [41]
//             },
//         ],
//     },
//     {
//         "id": 1,
//         "title": "Financial Wellness",
//         "subtitle": "",
//         "imageUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636737957/olqy4hj4viuqzj3dusgs.jpg",
//         "subcategories": [
//             {
//                 "id": 6,
//                 "title": "Spending plans",
//                 "subtitle": "",
//                 "coachCategories": [14]
//             },
//             {
//                 "id": 7,
//                 "title": "Debt & student loans",
//                 "subtitle": "",
//                 "coachCategories": [15]
//             },
//             {
//                 "id": 8,
//                 "title": "Savings",
//                 "subtitle": "",
//                 "coachCategories": [16]
//             },
//             {
//                 "id": 9,
//                 "title": "Home buying",
//                 "subtitle": "",
//                 "coachCategories": [17]
//             },
//             {
//                 "id": 10,
//                 "title": "Charity & giving",
//                 "subtitle": "",
//                 "coachCategories": [18]
//             },
//             {
//                 "id": 12,
//                 "title": "Insurance",
//                 "subtitle": "",
//                 "coachCategories": [20]
//             },
//             {
//                 "id": 13,
//                 "title": "Investing & 401k",
//                 "subtitle": "",
//                 "coachCategories": [21]
//             },
//             {
//                 "id": 11,
//                 "title": "Taxes",
//                 "subtitle": "",
//                 "coachCategories": [19]
//             },
//         ],
//     },
//     {
//         "id": 14,
//         "title": "Dating & Marriage",
//         "subtitle": "Whether you’re just starting a life with your partner or are beginning the next step in your life",
//         "imageUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636737920/awwp3tb36e4anjvsqjcl.jpg",
//         "subcategories": [
//             {
//                 "id": 15,
//                 "title": "Getting married",
//                 "subtitle": "",
//                 "coachCategories": [51]
//             },
//             {
//                 "id": 38,
//                 "title": "Combining finances",
//                 "subtitle": "",
//                 "coachCategories": [50]
//             },
//             {
//                 "id": 37,
//                 "title": "Marital concerns",
//                 "subtitle": "",
//                 "coachCategories": [22]
//             },
//             {
//                 "id": 17,
//                 "title": "Domestic violence",
//                 "subtitle": "",
//                 "coachCategories": [24]
//             },
//         ],
//     },
//     {
//         "id": 4,
//         "title": "Family Building",
//         "subtitle": "",
//         "imageUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636738005/wqkzwszhvfjafer8mw7m.jpg",
//         "subcategories": [
//             {
//                 "id": 27,
//                 "title": "Fertility & adoption",
//                 "subtitle": "",
//                 "coachCategories": [32]
//             },
//             {
//                 "id": 28,
//                 "title": "Childcare",
//                 "subtitle": "",
//                 "coachCategories": [33]
//             },
//             {
//                 "id": 29,
//                 "title": "Education",
//                 "subtitle": "",
//                 "coachCategories": [34]
//             },
//         ],
//     },
//     {
//         "id": 2,
//         "title": "Divorce",
//         "subtitle": "",
//         "imageUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636738032/yalogmlxuuls1jdrni74.jpg",
//         "subcategories": [
//             {
//                 "id": 40,
//                 "title": "Considering divorce",
//                 "subtitle": "",
//                 "coachCategories": [49]
//             },
//             {
//                 "id": 16,
//                 "title": "Getting divorced",
//                 "subtitle": "",
//                 "coachCategories": [23]
//             },
//             {
//                 "id": 18,
//                 "title": "Post-divorce",
//                 "subtitle": "",
//                 "coachCategories": [47]
//             },
//             {
//                 "id": 39,
//                 "title": "Co-parenting",
//                 "subtitle": "",
//                 "coachCategories": [48]
//             }
//         ],
//     },
//     {
//         "id": 3,
//         "title": "Grief & Loss",
//         "subtitle": "",
//         "imageUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636738052/qnghe6myev2trzymbjel.jpg",
//         "subcategories": [
//             {
//                 "id": 20,
//                 "title": "Widowhood",
//                 "subtitle": "",
//                 "coachCategories": [26]
//             },
//             {
//                 "id": 21,
//                 "title": "Loss of a child",
//                 "subtitle": "",
//                 "coachCategories": [27]
//             },
//             {
//                 "id": 22,
//                 "title": "Loss of a parent",
//                 "subtitle": "",
//                 "coachCategories": [28]
//             },
//             {
//                 "id": 24,
//                 "title": "Illness & disability",
//                 "subtitle": "",
//                 "coachCategories": [29]
//             }
//         ]
//     },
//     {
//         "id": 23,
//         "title": "Health",
//         "subtitle": "",
//         "imageUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635620631/hgiruik1pobi0ay3pmk4.jpg",
//         "subcategories": [
//             {
//                 "id": 25,
//                 "title": "Mental wellness",
//                 "subtitle": "",
//                 "coachCategories": [30]
//             },
//             {
//                 "id": 26,
//                 "title": "Nutrition",
//                 "subtitle": "",
//                 "coachCategories": [31]
//             },
//             {
//                 "id": 30,
//                 "title": "Health, dental, & vision insurance",
//                 "subtitle": "",
//                 "coachCategories": [35]
//             },
//         ],
//     },
// ];

// export default coachingOptionsV2;