import { Map, fromJS } from 'immutable';

/* Immutable */

const LISTINGS_ALL_FETCHING = 'LISTINGS_ALL_FETCHING';
const LISTINGS_ALL = 'LISTINGS_ALL';
const LISTINGS_ONE_FETCHING = 'LISTINGS_ONE_FETCHING';
const LISTINGS_ONE = 'LISTINGS_ONE';
const LISTINGS_ONE_UPDATING = 'LISTINGS_ONE_UPDATING';
const LISTINGS_ONE_UPDATED = 'LISTINGS_ONE_UPDATED';
const LISTINGS_ONE_DELETING = 'LISTINGS_ONE_DELETING';
const LISTINGS_ONE_DELETED = 'LISTINGS_ONE_DELETED';
const LISTINGS_ONE_CREATING = 'LISTINGS_ONE_CREATING';
const LISTINGS_ONE_CREATED = 'LISTINGS_ONE_CREATED';
const LISTINGS_ALL_REORDERING = 'LISTINGS_ALL_REORDERING';
const LISTINGS_ALL_REORDERED = 'LISTINGS_ALL_REORDERED';
const LISTINGS_STORE_RESET = 'LISTINGS_STORE_RESET';

/* Actions */

export const setListingsAllFetching = (status) => ({'type': LISTINGS_ALL_FETCHING, 'status': status});
export const setListingsAll = (listings) => ({'type': LISTINGS_ALL, 'listings': listings});
export const setListingsOneFetching = (status) => ({'type': LISTINGS_ONE_FETCHING, 'status': status});
export const setListingsOne = (listing) => ({'type': LISTINGS_ONE, 'listing': listing});
export const setListingOneDeleting = (status) => ({'type': LISTINGS_ONE_DELETING, 'status': status});
export const setListingOneDeleted = (listingId) => ({'type': LISTINGS_ONE_DELETED, 'listingId': listingId});
export const setListingsOneCreating = (status) => ({'type': LISTINGS_ONE_CREATING, 'status': status});
export const setListingsOneCreated = (data) => ({'type': LISTINGS_ONE_CREATED, 'data': data});
export const setListingOneUpdating = (status) => ({'type': LISTINGS_ONE_UPDATING, 'status': status});
export const setListingOneUpdated = (listingId, data) => ({'type': LISTINGS_ONE_UPDATED, 'listingId': listingId, 'data': data});
export const setListingAllReordering = (status) => ({'type': LISTINGS_ALL_REORDERING, 'status': status});
export const setListingAllReordered = (listingIds) => ({'type': LISTINGS_ALL_REORDERED, 'listingIds': listingIds});
export const setListingsStoreReset = () => ({'type': LISTINGS_STORE_RESET});

/* Initial State */

const initialState = Map({
    listingsAllFetching: false,
    listingsAll: Map(),
    listingsOneFetching: false,
    listingsOne: Map(),
    listingsAllReordering: false,
    listingsOneDeleting: false,
    listingsOneUpdating: false,
    listingsOneCreating: false
});

/* Reducer */

const listingsReducer = (state=initialState, action) => {
    switch (action.type) {
        case LISTINGS_ALL_FETCHING:
            return state.merge({'listingsAllFetching': action.status});
        case LISTINGS_ALL:
            return state.merge({'listingsAll': fromJS(action.listings)});
        case LISTINGS_ONE_FETCHING:
            return state.merge({'listingsOneFetching': action.status});
        case LISTINGS_ONE:
            return state.merge({'listingsOne': fromJS(action.listing)});
        case LISTINGS_ONE_DELETING:
            return state.merge({'listingsOneDeleting': action.status});
        case LISTINGS_ALL_REORDERING:
            return state.merge({'listingsAllReordering': action.status});
        case LISTINGS_ONE_UPDATING:
            return state.merge({'listingsOneUpdating': action.status});
        case LISTINGS_ONE_CREATING:
            return state.merge({'listingsOneCreating': action.status});
        case LISTINGS_ONE_UPDATED:
            return state.merge({
                "listingsAll": fromJS(state.get("listingsAll").map(l => {
                    if(l.get("id") === action.listingId) {
                        return(fromJS({...l.toJS(), ...action.data}))
                    } else {
                        return(fromJS(l))
                    }
                }))
            });
        case LISTINGS_ONE_CREATED:
            return state.merge({
                "listingsAll": state.get("listingsAll").push(fromJS(action.data))
            });
        case LISTINGS_ONE_DELETED:
            return state.merge({
                "listingsAll": fromJS(state.get("listingsAll").filter(l => {
                    return(l.get("id") !== action.listingId)
                }))
            });
        case LISTINGS_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default listingsReducer;
