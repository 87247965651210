import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

/* Components */
import {PageTitle} from "./care";
import {FontBody16} from '../../../common/components/fonts';
import {colors} from "../../../common/components/colors";
import BackBtn from "../../team/components/back";

const PageSubtitle = styled(FontBody16)`
    text-align: left;
    color: ${colors.secondary100};
    @media only screen and (max-width: 800px) {
        text-align: center;
    }
`;
const ContainerTop = styled.div`
    padding: 40px 0px 25px 0px;
    background-color: ${colors.white};
    border-radius: 0px 0px 40px 40px;
    width: 100%;
    position: relative;
    z-index: 1;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: 460px) {
        padding: 20px 0px 12px 0px;
        border-radius: 0px 0px 20px 20px;
    }
`
const ContainerTopCenter = styled.div`
    width: calc(100% - 120px);
    padding: 0px 60px;
    max-width: calc(100% - 120px);
    display: flex;
    flex-direction: column;
    gap: 20px; 
    margin: 0px auto;
    position: relative;
    @media screen and (max-width: 748px) {
        gap: 10px;
        width: calc(100% - 60px);
        padding: 0px 30px;
        max-width: calc(100% - 60px);
    }
`
const ContainerTopTitle = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 15px 0px;
    gap: 10px;
    & div {
        text-align: left;
    }
`

class CoachingCategoriesHeader extends React.Component {
    render() {
        return (
            <ContainerTop>
                <ContainerTopCenter>
                    <BackBtn onBack={() => {this.props.history.push(this.props.backUrl)}} />
                    <ContainerTopTitle>
                        <PageTitle>{this.props.title}</PageTitle>
                        <PageSubtitle>{this.props.subTitle}</PageSubtitle>
                    </ContainerTopTitle>
                </ContainerTopCenter>
            </ContainerTop>
        )
    }
}

export default withRouter(CoachingCategoriesHeader);