export const devMyRegisteredPrograms = [
    {
        "subscriptionId": 1,
        "subscribedOn": "2021-09-27T13:57:30.321708+00:00",
        "groupProgram": {
            "id": 3,
            "title": "Financial course for beginers",
            "description": "Will teach you about default things in personal finance",
            "imageUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635607046/image_1_muc4zo.png", //"https://res.cloudinary.com/blz-lilyriche/image/upload/v1619966654/hu6hld3xej6jwpeen5up",
            "isActive": true,
            "numberOfLectures": 0,
            "startsOn": "2022-10-11T14:00:00+00:00",
            "endedOn": null,
            "author": {
                "id": 12,
                "first_name": "Mark",
                "last_name": "Advisor"
            },
            "lectures": [
                {
                    "webinar": {
                        "id": 14,
                        "title": "Investing webinar",
                        "description": "Learn how to invest into this crazy worlsd",
                        "coverPhoto": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635607046/image_1_muc4zo.png",
                        "flashSaleStartDateTime": "2022-06-14T14:00:00+00:00",
                        "flashSaleEndDateTime": "2022-06-14T15:00:00+00:00",
                    }
                },
                {
                    "webinar": {
                        "id": 15,
                        "title": "Introduction",
                        "description": "Introduction to financial tools that you have at your disposal.\r\nYou will learn how to earn, save, manage and invest your money.",
                        "coverPhoto": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635607046/image_1_muc4zo.png",
                        "flashSaleStartDateTime": "2022-11-02T14:00:00+00:00",
                        "flashSaleEndDateTime": "2022-11-02T15:00:00+00:00",
                    }
                }
            ]
        }
    },
    {
        "subscriptionId": 2,
        "subscribedOn": "2022-09-27T13:57:30.321708+00:00",
        "groupProgram": {
            "id": 5,
            "title": "Financial course for beginers",
            "description": "Will teach you about default things in personal finance",
            "imageUrl": "https://picsum.photos/seed/4/400/300",
            "isActive": true,
            "numberOfLectures": 0,
            "startsOn": "2022-10-11T14:00:00+00:00",
            "endedOn": null,
            "author": {
                "id": 12,
                "first_name": "Mark",
                "last_name": "Advisor"
            },
            "lectures": [
                {
                    "webinar": {
                        "id": 14,
                        "title": "Investing webinar",
                        "description": "Learn how to invest into this crazy worlsd",
                        "coverPhoto": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635607046/image_1_muc4zo.png",
                        "flashSaleStartDateTime": "2022-12-25T14:00:00+00:00",
                        "flashSaleEndDateTime": "2022-12-25T15:00:00+00:00",
                    }
                },
                {
                    "webinar": {
                        "id": 15,
                        "title": "Introduction",
                        "description": "Introduction to financial tools that you have at your disposal.\r\nYou will learn how to earn, save, manage and invest your money.",
                        "coverPhoto": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635607046/image_1_muc4zo.png",
                        "flashSaleStartDateTime": "2022-12-02T14:00:00+00:00",
                        "flashSaleEndDateTime": "2022-12-02T15:00:00+00:00",
                    }
                }
            ]
        }
    }
];

export const devIndustries = [
    {"id": 1, "name": "Sample industry"},
    {"id": 2, "name": "Healthcare"},
    {"id": 3, "name": "Finance"},
    {"id": 4, "name": "Insurance"},
]


export const devMyRegisteredProgram =
{
    "subscriptionId": 1,
    "subscribedOn": "2021-09-27T13:57:30.321708+00:00",
    "groupProgram": {
        "id": 3,
        "title": "Financial course for beginers",
        "description": "Will teach you about default things in personal finance",
        "imageUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1619966654/hu6hld3xej6jwpeen5up",
        "isActive": true,
        "numberOfLectures": 0,
        "startsOn": "2021-10-11T14:00:00+00:00",
        "endedOn": null,
        "author": {
            "id": 12,
            "first_name": "Mark",
            "last_name": "Advisor"
        },
        "lectures": [
            {
                "id": 14,
                "title": "Investing webinar",
                "description": "Learn how to invest into this crazy worlsd",
                "coverPhoto": null,
                "flashSaleStartDateTime": "2021-11-25T14:00:00+00:00",
                "flashSaleEndDateTime": "2021-11-25T15:00:00+00:00",
            },
            {
                "id": 15,
                "title": "Introduction",
                "description": "Introduction to financial tools that you have at your disposal.\r\nYou will learn how to earn, save, manage and invest your money.",
                "coverPhoto": null,
                "flashSaleStartDateTime": "2021-11-02T14:00:00+00:00",
                "flashSaleEndDateTime": "2021-11-02T15:00:00+00:00",
            }
        ]
    }
};