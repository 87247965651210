import { Map, List, fromJS } from 'immutable';

/* Immutable */

const APPOINTMENTS_MEETINGS = 'APPOINTMENTS_MEETINGS';
const APPOINTMENTS_MEETINGS_FETCHING = 'APPOINTMENTS_MEETINGS_FETCHING';
const APPOINTMENTS_MEETINGS_CHANGED = 'APPOINTMENTS_MEETINGS_CHANGED';
const APPOINTMENTS_MEETINGS_STORE_RESET = 'APPOINTMENTS_MEETINGS_STORE_RESET';

/* Actions */

export const setTeamMemberMeetings = (meetings) => ({'type': APPOINTMENTS_MEETINGS, 'meetings': meetings});
export const setTeamMemberMeetingsFetching = (status) => ({'type': APPOINTMENTS_MEETINGS_FETCHING, 'status': status});
export const setTeamMemberMeetingsChanged = (datetime) => ({'type': APPOINTMENTS_MEETINGS_CHANGED, 'datetime': datetime});
export const setTeamMemberMeetingsStoreReset = () => ({'type': APPOINTMENTS_MEETINGS_STORE_RESET});

/* Initial State */

const initialState = Map({
    meetings: List(),
    meetingsFetched: false,
    meetingsFetching: false,
    meetingsChanged: null
});

/* Reducer */

const meetingsReducer = (state=initialState, action) => {
    switch (action.type) {
        case APPOINTMENTS_MEETINGS:
            return state.merge({
                'meetings': fromJS(action.meetings),
                'meetingsFetched': true
            });
        case APPOINTMENTS_MEETINGS_FETCHING:
            return state.merge({'meetingsFetching': action.status});
        case APPOINTMENTS_MEETINGS_CHANGED:
            return state.merge({'meetingsChanged': action.datetime});
        case APPOINTMENTS_MEETINGS_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default meetingsReducer;
