import React from 'react';
import {connect} from "react-redux";
import {List} from 'immutable';

/* Components */
import {FontBody14, FontBody16, FontTitle18, FontTitle24} from '../../../common/components/fonts';
import {DropdownInput} from '../../../common/components/inputs';
import {CloseOutlined, LineOutlined} from '@ant-design/icons';
import {ButtonPrimary, ButtonTertiary, ButtonLink, ButtonLinkInactive} from '../../../common/components/buttons';
import {ComposeWrapper, ConfirmTitle, ConfirmRight, ConfirmLeft, ConfirmBtnFitRight, ConfirmBtnFitLeft, ConfirmActions, Modal, Body, BodyEmpty, Buttons, Close, CloseButton, CloseMessage, Compose, ComposeArea, DropdownSize, Recipient, SendBtn, Sent, Templates, TextArea, To, ToFrom} from '../components/smscompose';
import PhonePrompt from './phoneprompt';

/* Middleware */
import {tryPostCoachClientMessages, tryGetCoachClientMessagesCompose, tryGetCoachCoachMessagesCompose, tryPostCoachCoachMessages, tryGetCoachClientPurchaseLink} from "../middleware/messages";
import {tryGetSettingsPersonalInfo} from '../../middleware/settingspersonalinfo';

/* Domains */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

class SMSCompose extends React.Component {
    state = {
        "body": '',
        "subject": '',
        "confirmModal": false,
        "switchModal": false,
        "isSent": false,
        "isMinimized": false,
        "history": List(),
        "textareaLines": 1,
        "textareaHeight": 145,
        "purchaseToken": null,
        "isLoading": true,
        "isOpen": false,
        "createSMSTemplates": () => {},
        "showPhonePrompt": false,
        "phone": '',
        "phoneSaved": false,
    };

    intervalID = 0;

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.setState({
            "createSMSTemplates": env.ENTERPRISE.EXPERT.TEMPLATES.SMS
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if(prevState.phoneSaved !== this.state.phoneSaved && this.state.phoneSaved) {
            this.setState({"showPhonePrompt": false})
        }
        if(prevProps.lastInitiated !== this.props.lastInitiated && this.props.recipientId !== this.state.recipientId) {
            if(this.state.isOpen) {
                return this.setState({"switchModal": true})
            } else {
                return this.open()
            }
        }
    }

    open = async () => {
        await this.setState({
            "isLoading": true,
            "subject": (this.props.subject === null ? '' : this.props.subject),
            "body": (this.props.body === null ? '' : this.props.body),
            "isOpen": this.props.isOpen,
            "isMinimized": false,
            "isSent": false,
            "recipientId": this.props.recipientId,
            "recipientType": this.props.recipientType,
            "recipientFirstName": this.props.recipientFirstName,
            "recipientLastName": this.props.recipientLastName,
            "purchaseToken": this.props.recipientType === "consumer" ? await this.props.tryGetCoachClientPurchaseLink(this.props.recipientId) : null,
        });
        await this.props.tryGetSettingsPersonalInfo()
        await this.setState({
            "phone": this.props.settingsPersonalInfo.get("phone"),
            "showPhonePrompt" : (this.props.settingsPersonalInfo.get("phone") === '' || this.props.settingsPersonalInfo.get("phone") === null || this.props.settingsPersonalInfo.get("phone") === "None")
        })
        await this.filter();
        this.intervalID = setInterval(() => {this.filter();}, 1000 * 60);
    };

    filter = async () => {
        this.setState({
            "history": this.props.recipientType === "consumer" ? await this.props.tryGetCoachClientMessagesCompose(this.props.recipientId) : await this.props.tryGetCoachCoachMessagesCompose(this.props.recipientId)
        });
    };

    close = async () => {
        await clearInterval(this.intervalID);
        await this.setState({body: '', subject: '', confirmModal: false, switchModal: false, textareaLines: 1, textareaHeight: 145, purchaseToken: null, isOpen: false, recipientId: null});
    };

    switch = async () => {
        await clearInterval(this.intervalID);
        await this.open();
        this.setState({"switchModal": false});
    };

    handleNewContent = e => this.setState({"body": e});
    handleNewSubject = e => this.setState({"subject": e.target.value});

    componentWillUnmount() {
        clearTimeout(this.timeout);
        clearInterval(this.intervalID);
    }

    handleSendSMS = async () => {
        let addMessage;
        if(this.state.recipientType === "consumer") {
            addMessage = await this.props.tryPostCoachClientMessages("sms", this.state.body, this.state.subject, this.state.recipientId)
        } else {
            addMessage = await this.props.tryPostCoachCoachMessages("sms", this.state.body, this.state.subject, this.state.recipientId)
        }
        const historyAdj = this.state.history;
        historyAdj.push(addMessage);
        await this.setState({body: '', subject: '', textareaLines: 1, textareaHeight: 145, history: historyAdj});
    };

    handleSelectTemplate = async (e) => {
        const templates = await this.state.createSMSTemplates(this.state.recipientFirstName);
        templates.map(async m => {
            if(m.value === e.value) {
                await this.setState({"body": m.body, "textareaLines": m.body.length === 0 ? 1 : Math.ceil(m.body.length / 22)});
                this.setState({"subject": m.label});
            }
        });
    };

    handleMinimize = async () => {
        this.setState({"isMinimized": !this.state.isMinimized});
    };

    handleUpdatePhone = async() => {
        await this.setState({"showPhonePrompt": false, "phoneSaved": true})
    }

    closeCheck = () => {
        if (this.state.body === "") {
            this.close()
        } else {
            this.setState({"confirmModal": true})
        }
    }

    render() {
        if(this.state.isOpen) {
            return (
                <>
                    <ComposeWrapper minimized={this.state.isMinimized}>
                        <Close>
                            <CloseMessage onClick={this.handleMinimize} ><FontBody14>New SMS</FontBody14></CloseMessage>
                            <CloseButton onClick={this.handleMinimize} ><LineOutlined /></CloseButton>
                            <CloseButton onClick={() => {this.closeCheck()}} ><CloseOutlined /></CloseButton>
                        </Close>
                        {!this.state.isMinimized &&
                            <Compose>
                                <To><ToFrom isFromMe={false} who={"To"} recipient={this.state.recipientFirstName+" "+this.state.recipientLastName}/></To>
                                {/*<ToFrom isFromMe={false} who={"From"} recipient={}/>*/}
                                {/*<Subject required value={this.state.subject} style={InlineFonts.FontHeader18} onChange={this.handleNewSubject} placeholder={"Subject"}/>*/}
                                <Body textareaHeight={this.state.textareaHeight}>
                                    {this.state.showPhonePrompt
                                        ?
                                        <PhonePrompt 
                                            handleUpdatePhone={this.handleUpdatePhone} 
                                        />
                                        :
                                        <>
                                        {this.state.history.size === 0 &&
                                            <BodyEmpty><FontTitle18>No messages yet</FontTitle18></BodyEmpty>
                                        }
                                        {this.state.history.map(m => (
                                            <div key={m.id}>
                                                <Recipient isFromMe={this.props.userId === m.fromUserId} content={m.content} datetime={m.formattedDatetime} sendPurchaseLink={m.content === "PURCHASE_LINK"} purchaseToken={this.state.purchaseToken} />
                                            </div>
                                        ))}
                                        </>
                                    }
                                </Body>
                                <ComposeArea id={'message-editor'}>
                                    <TextArea
                                        value={this.state.body}
                                        onChange={e => {this.setState({"body": e.target.value, "textareaLines": e.target.value.length === 0 ? 1 : Math.ceil(e.target.value.length / 22), "textareaHeight": document.getElementById('message-editor').offsetHeight,})}}
                                        placeholder={"Type a SMS message..."}
                                        rows={this.state.textareaLines}
                                    />
                                    {(this.state.body !== '' && !this.state.showPhonePrompt)
                                        ? <SendBtn><ConfirmBtnFitLeft onClick={this.handleSendSMS}><ButtonLink label={"Send"} canSubmit={false} /></ConfirmBtnFitLeft></SendBtn>
                                        : <SendBtn><ButtonLinkInactive label={"Send"} canSubmit={false} /></SendBtn>
                                    }
                                    <Buttons>
                                        {this.props.platform !== "consumer" &&
                                            <Templates>
                                                <DropdownSize>
                                                    <DropdownInput
                                                        dropUpwards={true}
                                                        id={"templates"}
                                                        placeholder={"Templates"}
                                                        value={"Templates"}
                                                        options={this.state.createSMSTemplates(this.state.recipientFirstName)}
                                                        onChange={this.handleSelectTemplate}
                                                    />
                                                </DropdownSize>
                                            </Templates>
                                        }
                                    </Buttons>
                                </ComposeArea>
                            </Compose>
                        }
                    </ComposeWrapper>
                    {this.state.confirmModal &&
                        <Modal>
                            <ConfirmTitle><FontTitle24>Are you sure?</FontTitle24></ConfirmTitle>
                            <FontBody16>Do you want to delete this draft SMS? This cannot be undone.</FontBody16>
                            <ConfirmActions>
                                <ConfirmLeft>
                                    <ConfirmBtnFitLeft onClick={() => {this.setState({"confirmModal": false})}}><ButtonTertiary label={"Cancel"} canSubmit={true} /></ConfirmBtnFitLeft>
                                </ConfirmLeft>
                                <ConfirmRight>
                                    <ConfirmBtnFitRight onClick={async () => {this.close()}}><ButtonPrimary label={"Delete"} canSubmit={true} /></ConfirmBtnFitRight>
                                </ConfirmRight>
                            </ConfirmActions>
                        </Modal>
                    }
                    {this.state.switchModal &&
                        <Modal>
                            <ConfirmTitle><FontTitle24>Are you sure?</FontTitle24></ConfirmTitle>
                            <FontBody16>Do you want to delete this draft SMS? This cannot be undone.</FontBody16>
                            <ConfirmActions>
                                <ConfirmLeft>
                                    <ConfirmBtnFitLeft onClick={() => {this.setState({"switchModal": false})}}><ButtonTertiary label={"Cancel"} canSubmit={true} /></ConfirmBtnFitLeft>
                                </ConfirmLeft>
                                <ConfirmRight>
                                    <ConfirmBtnFitRight onClick={async () => {this.switch()}}><ButtonPrimary label={"Delete"} canSubmit={true} /></ConfirmBtnFitRight>
                                </ConfirmRight>
                            </ConfirmActions>
                        </Modal>
                    }
                </>
            )
        } else if(this.state.isSent) {
            return(
                <Modal>
                    <Sent><FontTitle24>SMS sent!</FontTitle24></Sent>
                </Modal>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = state => ({
    platform: state.common.user.get("platform"),
    userId: state.common.user.get("userId"),
    userName: state.common.user.get("first")+" "+state.common.user.get("last"),
    isOpen: state.enterprise.sms.messages.get("isOpen"),
    subject: state.enterprise.sms.messages.get("subject"),
    body: state.enterprise.sms.messages.get("body"),
    recipientId: state.enterprise.sms.messages.get("recipientId"),
    recipientType: state.enterprise.sms.messages.get("recipientType"),
    recipientFirstName: state.enterprise.sms.messages.get("recipientFirstName"),
    recipientLastName: state.enterprise.sms.messages.get("recipientLastName"),
    lastInitiated: state.enterprise.sms.messages.get("lastInitiated"),
    settingsPersonalInfo: state.enterprise.settingsPersonal.get("settingsPersonalInfo"),
});

const mapDispatchToProps = dispatch => ({
    tryGetCoachClientMessagesCompose: (consumerId) => dispatch(tryGetCoachClientMessagesCompose(consumerId)),
    tryPostCoachClientMessages: (mediaType, content, subject, consumerId) => dispatch(tryPostCoachClientMessages(mediaType, content, subject, consumerId)),
    tryGetCoachCoachMessagesCompose: (consumerId) => dispatch(tryGetCoachCoachMessagesCompose(consumerId)),
    tryPostCoachCoachMessages: (mediaType, content, subject, consumerId) => dispatch(tryPostCoachCoachMessages(mediaType, content, subject, consumerId)),
    tryGetCoachClientPurchaseLink: (consumerId) => dispatch(tryGetCoachClientPurchaseLink(consumerId)),
    tryGetSettingsPersonalInfo: () => dispatch(tryGetSettingsPersonalInfo())
});

export default connect(mapStateToProps, mapDispatchToProps)(SMSCompose);
