import React from 'react';
import styled from 'styled-components';

/* Components */
import {colors} from "../../../common/components/colors";
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {FontBody16To14} from '../../../common/components/fonts';

const StyledContactButtons = styled.div`
  & > div {
    margin: 20px 40px;
  }
  @media screen and (max-width: 1680px) {
    & > div {
      margin: 15px 30px;
    }
  }
  @media screen and (max-width: 748px) {
    & > div {
      margin: 10px 20px;
    }
`;
const Icon = styled.div`
  display: inline-block;
  span {
    font-size: 16px;
    padding: 10px 0;
    color: ${colors.primary100};
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
  }
`;
const Label = styled.div`
  display: inline-block;
  vertical-align: top;
  padding: 6px;
  color: ${props => props.color};
  text-align: left;
`;

const Submit = styled.button`
  display: block;
  cursor: pointer;
  background-color: ${props => props.backgroundColor};
  border-radius: 4px;
  border: none;
  padding: 0 10px;
  vertical-align: baseline;
  font-weight: bold;
  color: ${colors.primary100};
  &:active, &:focus {
    outline: none;
    border: none;
  }
  &:disabled {
    //display: none;
    cursor: default;
    background-color: ${colors.secondary70};
  }
`;

const NavButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

ContactButtons.defaultProps = {
    disabledPrev: false,
    disabledNext: false,
    showBack: true,
    showNext: true,
    setStep: () => {}
}

export default function ContactButtons(props) {
    const disabledPrev = props.disabledPrev;
    const disabledNext = props.disabledNext;
    const showBack = props.showBack;
    const showNext = props.showNext;
    const setStep = props.setStep;

    return (
        <StyledContactButtons>
            <NavButtons>
                {showBack &&
                    <Submit
                        className={"prevButton"}
                        backgroundColor={"transparent"}
                        onClick={() => setStep(-1)}
                        disabled={disabledPrev}
                    >
                        <Icon><LeftOutlined style={{color: "#959CA3"}}/></Icon>
                        <Label color={colors.primary30}><FontBody16To14>{"Back"}</FontBody16To14></Label>
                    </Submit>
                }
                {showNext &&
                    <Submit
                        backgroundColor={colors.action100}
                        onClick={() => {setStep(1)}}
                        disabled={disabledNext}
                    >
                        <Label color={colors.white}><FontBody16To14>{"Next"}</FontBody16To14></Label>
                        <Icon> <RightOutlined style={{color: "#ffffff"}}/></Icon>
                    </Submit>
                }
            </NavButtons>
        </StyledContactButtons>
    )
}