import React from "react";
import styled from "styled-components";
import moment from 'moment/moment';

/* Components */
import {FontBody16, FontTitle30} from "../../../common/components/fonts";
import {ButtonPrimary, ButtonTertiary} from "../../../common/components/buttons";
import {ArrowRightOutlined, CloseOutlined, CheckCircleOutlined} from "@ant-design/icons";

/* Static */
import {colors} from "../../../common/components/colors";

const Title = styled.div`
    margin-bottom: 15px;
`;

export const Center = styled.div`
    margin-top: 30px;
    text-align: center;
    color: ${colors.primary100};
`;

export const SummaryIcon = styled.div`
    width: 100px;
    margin: 20px auto;
    height: 100px;
`;

export const SummaryTitle = styled.div`
    padding-bottom: 30px;
`;
export const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;

export const FitContent = styled.div`
    width: fit-content;
    cursor: pointer;
    margin: 0px auto;
`;

const GrayDark = styled.div`
    color: ${colors.secondary100};
    @media screen and (max-width: 460px) {
        margin-top: 20px;
        margin-bottom: 10px;
    }
`;

const Text100 = styled.div`
    font-size: 100px;
`;

const InlineBlock = styled.div`
    display: inline-block;
    padding: 10px;
`;

const Confirm = ({close, nextStep, showPaymentMethods}) => (
    <Center>
        <FontTitle30><Text100><CheckCircleOutlined /></Text100></FontTitle30>
        <SummaryTitle>
            <FontTitle30>Registration confirmed!</FontTitle30>
            <br />
            {showPaymentMethods
                ? <FontBody16>Add or edit payment methods to allow for easy purchasing during your livestream</FontBody16>
                : <FontBody16>Please check your email for a calendar invite with instructions on how to join the livestream</FontBody16>
            }
        </SummaryTitle>
        {showPaymentMethods
            ? <>
                <InlineBlock><span onClick={close}><ButtonTertiary canSubmit={false} label={"Close"} /></span></InlineBlock>
                <InlineBlock><span onClick={nextStep}><ButtonPrimary submit={false} label={<>Continue to payment methods&nbsp;<ArrowRightOutlined /></>} /></span></InlineBlock>
            </>
            : <InlineBlock><span onClick={close}><ButtonPrimary canSubmit={false} label={"Close"} /></span></InlineBlock>
        }
        <br />
        <br />
    </Center>
);

export default Confirm;