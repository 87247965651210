import React from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';

/* Containers */
import PagesPublish from "./productsoptions";

/* Components */
import {FontBody16, FontTitle18} from '../../../common/components/fonts';
import {FitContent} from "../components/portfolios";
import {ButtonSecondary} from "../../../common/components/buttons";
import {colors} from "../../../common/components/colors";
import {TagsOutlined} from "@ant-design/icons";

const Gray = styled.div`
    color: ${colors.secondary70};
`;

class ShopPageProducts extends React.Component {
    render() {
        return(
            <>
                <br />
                <FontTitle18>My Products</FontTitle18>
                <Gray><FontBody16>To change the products visible on your shop, go to the products page.</FontBody16></Gray>
                <br />
                <FitContent>
                    <FontBody16 onClick={() => {return this.props.history.push("/products")}}>
                        <ButtonSecondary canSubmit={false} label={<><TagsOutlined />&nbsp;View Products</>} />
                    </FontBody16>
                </FitContent>
                {/*<PagesPublish*/}
                {/*    title={"My Products"}*/}
                {/*    subtitle={""}*/}
                {/*    leftLabel={""}*/}
                {/*    rightLabel={"Show Products"}*/}
                {/*    activeKey={"profileActive"}*/}
                {/*    errorKey={"profileActiveError"}*/}
                {/*/>*/}
            </>
        )
    }
}

export default withRouter(ShopPageProducts);
