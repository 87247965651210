import React from 'react';
import {connect} from 'react-redux';
import styled from "styled-components";

/* Containers */
import Listings from './listings';
import ListingOne from "./listingone";

import IframeStoreVideoCall from "../../shop/containers/videocall";
import IframeStoreEmailer from "../../shop/containers/emailer";

/* Middleware */
import {tryGetListingsAll} from "../middleware/listings";

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {Container} from "../components/events";

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

class ListingsFlow extends React.Component {
    static defaultProps = {
        useCheckoutFunction: null
    };

    state={"step": 1, "listing": {}, "isLoading": true, "showScheduler": false, "showEmailer": false};

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.listingsAll !== this.props.listingsAll) {
            return this.update();
        }
    }

    init = async () => {
        await this.props.tryGetListingsAll(this.props.userId);
        return this.update();
    };

    update = async () => {
        if(this.props.listingId !== null) {
            const checkEvents = this.props.listingsAll.filter(f => {
                return(f.get("id").toString() === this.props.listingId.toString())
            });
            const eventIdExists = checkEvents.size === 1;
            if(eventIdExists) {
                this.setState({"listing": checkEvents.get(0), "step": 2, "isLoading": false})
            }
        } else {
            this.setState({"isLoading": false})
        }
    };

    changeShowScheduler = async (status) => {
        this.setState({"showScheduler": status});
    };

    changeShowEmailer = async (status) => {
        this.setState({"showEmailer": status});
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        // } else if (this.state.showScheduler) {
        //     return(<IframeStoreVideoCall changeShowScheduler={this.changeShowScheduler} profile={this.props.profile} />)
        // } else if (this.state.showEmailer) {
        //     return(<IframeStoreEmailer changeShowEmailer={this.changeShowEmailer} profile={this.props.profile} />)
        } else {
            if(this.state.step === 1) {
                return(
                    <>
                        <Listings
                            title={this.props.title}
                            description={this.props.description}
                            isPreview={this.props.isPreview}
                            forceImagesVertical={this.props.forceImagesVertical}
                            changeShowScheduler={this.changeShowScheduler}
                            changeShowEmailer={this.changeShowEmailer}
                            entryPoint={this.props.entryPoint}
                            close={this.props.close}
                            useCheckoutFunction={this.props.useCheckoutFunction}
                        />
                        {this.state.showScheduler &&
                            <IframeStoreVideoCall changeShowScheduler={this.changeShowScheduler} profile={this.props.profile} />
                        }
                        {this.state.showEmailer &&
                            <IframeStoreEmailer changeShowEmailer={this.changeShowEmailer} profile={this.props.profile} />
                        }
                    </>
                )
            } else if(this.state.step === 2) {
                return(
                    <Container>
                        <ListingOne
                            listing={this.state.listing}
                            isPreview={this.props.isPreview}
                            forceImagesVertical={this.props.forceImagesVertical}
                            useCheckoutFunction={this.props.useCheckoutFunction}
                        />
                    </Container>
                )
            } else {
                return null
            }
        }
    }

}

const mapStateToProps = state => ({
    listingsAll: state.iframe.listings.listings.get("listingsAll"),
    profile: state.iframe.listings.profile.get("profile"),
});

const mapDispatchToProps = dispatch => ({
    tryGetListingsAll: (id) => dispatch(tryGetListingsAll(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListingsFlow);