import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import styled from "styled-components";

/* Components */
import {TextInput, PhoneInput, DropdownInputStyled, CheckboxWithLabel} from "../../../../common/components/inputs";
import {FontTitle18} from "../../../../common/components/fonts";
import {ButtonInactive, ButtonPrimary} from "../../../../common/components/buttons";
import {colors} from "../../../../common/components/colors";
import {LogoLoading} from "../../../../common/components/loading";

/* Middleware */
import {tryUpdateSettingsPersonalInfo, tryGetSettingsPersonalInfo} from '../middleware/settings';
import {setLoggedIn} from '../../../../routes/store/user';

/* Utils */
import {timeZoneOptions} from '../../../../common/utils/timezone2';

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;
const Form = styled.form`
    padding: 50px 60px;
  @media screen and (max-width: 748px) {
    padding: 50px 0 50px 0;
  }
`;
const SaveStatus = styled.div`
    display: flex;
    flex-direction: row;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: right;
    align-items: center;
    margin-top: 40px;
`;
const ErrorText = styled(FontTitle18)`
    text-align: left;
    flex-grow: 1;
    color: ${colors.alert100};
`;

const inputsContainersStyles = {
    "margin": "0px 0px 30px 0px"
};
const inputsStyles = {
    "border": "none",
    "borderRadius": "40px",
    "lineHeight": "36px",
    "backgroundColor": colors.white,
    "boxShadow": "0 3px 10px 0 rgba(0, 0, 0, 0.15)",
    "fontSize": "24px"
};
const inputsStylesDropdown = {
    "control": {
        "border": "none",
        "borderRadius": "40px",
        "lineHeight": "36px",
        "backgroundColor": colors.white,
        "boxShadow": "0 3px 10px 0 rgba(0, 0, 0, 0.15)",
        "fontSize": "24px",
    },
    "placeholder": {
        "chevronWidth": "24px"
    },
    "menu": {
        "backgroundColor": colors.white,
        "borderTop": "1px solid "+colors.border100,
        "borderBottom": "1px solid "+colors.border100,
        "borderLeft": "1px solid "+colors.border100,
        "borderRight": "1px solid "+colors.border100,
        "borderRadius": "30px",
        "fontSize": "24px",
    }
};

const SubmitInputWithValidation = ({ isChanged, isValid, isSaving, label }) => {
    if(isChanged && isValid) {
        if(isSaving) {
            return (<ButtonInactive canSubmit={true} label={"Saving"} />)
        } else {
            return (<ButtonPrimary canSubmit={true} label={label}/>)
        }
    } else {
        return(<ButtonInactive canSubmit={true} label={label}/>)
    }
};

class FirmSettingsProfileEditContact extends React.Component {
    static defaultProps = {
        lastUpdated: null
    }

    state = {
        phone: '', phoneValid: true,
        email: '', emailValid: true,
        timeZone: '',
        isSaving: false,
        isSaved: false,
        error: '',
        isChanged: false,
        isValid: true,
        isLoading: true,
        smsNotificationConsent: false
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const user = await this.props.tryGetSettingsPersonalInfo();
        this.setState({
            ...user,
            "isLoading": false
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.lastUpdated !== this.props.lastUpdated && this.props.lastUpdated !== null) {
            this.init();
        }
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    handleChangeEmail = async e => {
        await this.setState({'email': e.target.value, 'emailValid': validator.isEmail(e.target.value), 'isChanged': true});
        this.checkValid();
    };

    handleChangePhone = async e => {
        await this.setState({'phone': e.target.value, 'phoneValid': validator.isMobilePhone(e.target.value, 'en-US'), 'isChanged': true});
        this.checkValid();
    };

    handleChangeTimeZone = async e => {
        await this.setState({'timeZone': e.value, 'isChanged': true});
        this.checkValid();
    };

    handleChangeSMS = async (id, status) => {
        await this.setState({'smsNotificationConsent' : status, 'isChanged': true});
        this.checkValid();
    }

    checkValid = () => {
        if((this.state.phone !== "" && this.state.phoneValid || this.state.phone === "") && this.state.emailValid && this.state.timeZone !== "") {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            this.setState({'isSaving': true});
            let phoneAdj = this.state.phone.toString().replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
            const result = await this.props.tryUpdateSettingsPersonalInfo({...this.state, "phone": phoneAdj === "" ? null : phoneAdj});
            this.setState({
                'error': result.error,
                'isSaving': false,
                'isSaved': true,
                'isChanged': false
            });
            this.timeout = setTimeout(() => {
                if(result.error !== null) {
                    this.init();
                } else {
                    console.log(this.props.user.toJS())
                    this.props.setLoggedIn({...this.props.user.toJS(), "phone": this.state.phone, "email": this.state.email, "timeZone": this.state.timeZone})
                    this.props.onSave();
                }
                this.setState({'error': null, 'isSaved': false});
            }, 3000);
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <Form method={"post"} onSubmit={this.handleSubmit}>
                    <PhoneInput
                        title={"Phone"}
                        valid={this.state.phoneValid}
                        warning={this.state.phone === "" ? "" : "Enter a valid mobile phone"}
                        id={"phone"}
                        onChange={this.handleChangePhone}
                        placeholder={'(###) ###-####'}
                        value={this.state.phone}
                        options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}}
                        containerStyles={inputsContainersStyles}
                        inputStyles={inputsStyles}
                    />
                    <TextInput
                        title={"Email"}
                        valid={this.state.emailValid}
                        warning={"Enter a valid email address"}
                        id={"email"}
                        onChange={this.handleChangeEmail}
                        placeholder={"Email"}
                        value={this.state.email}
                        containerStyles={inputsContainersStyles}
                        inputStyles={inputsStyles}
                    />
                    <DropdownInputStyled
                        title={"Timezone"}
                        options={timeZoneOptions}
                        id={'timeZone'}
                        onChange={this.handleChangeTimeZone}
                        placeholder={'Timezone'}
                        value={this.state.timeZone}
                        containerStyles={inputsContainersStyles}
                        inputStyles={inputsStylesDropdown}
                    />

                    <CheckboxWithLabel
                        action={this.handleChangeSMS}
                        checked={this.state.smsNotificationConsent}
                        id={'sms'}
                        label={'Do you want text notifications from Willow?'}
                    />

                    <SaveStatus>
                        {this.state.isSaved &&
                            <ErrorText>{this.state.error}</ErrorText>
                        }
                        <SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} />
                    </SaveStatus>
                </Form>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.common.user,
});

const mapDispatchToProps = dispatch => ({
    tryUpdateSettingsPersonalInfo: (data) => dispatch(tryUpdateSettingsPersonalInfo(data)),
    tryGetSettingsPersonalInfo: () => dispatch(tryGetSettingsPersonalInfo()),
    setLoggedIn: (user) => dispatch(setLoggedIn(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(FirmSettingsProfileEditContact);
