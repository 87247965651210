import React from "react";
import moment from 'moment/moment';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import styled from "styled-components";

/* Components */
import {colors} from "../../../common/components/colors";
import {Type} from "../components/progress";
import {FontBody14, FontHeader16, FontSubtitle24, FontTitle28, FontBody18} from '../../../common/components/fonts';
import {ButtonPrimary} from '../../../common/components/buttons';
import {Olive, Pointer} from "../components/coursepreview";

const SettingsCard = styled.div`
    -webkit-box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.1);
    background-color: ${colors.white};
    color: ${colors.primary100};
    ${props => props.isCompleted !== true} {
        padding: 25px 25px 90px 25px;
    }
    ${props => props.isCompleted !== false} {
        padding: 25px;
    }
    margin: -10px 35px 40px;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
    justify-content: flex-start;
    align-content: center;
    gap: 30px;
    width: calc(100% - 120px);
    position: relative;
`;
const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: calc(100% - 35% - 30px);
    flex-grow: 1;
    gap: 10px;
    justify-content: space-around;
    @media screen and (max-width: 768px) {
        max-width: 100%;
    }
`
const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    flex-wrap: wrap;
`
const CourseImage = styled.img`
    height: auto;
    max-height: 275px;
    min-width: 250px;
    width: 35%;
    object-fit: cover;
    border-radius: 25px;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;
const Description = styled.div`
    display: inline-flex;
    flex-direction: row;
    column-gap: 10px;
`;
const ActionButtons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-self: flex-end;
    flex-wrap: wrap;
`;
const CompletedBox = styled.div`
    padding: 20px 40px 20px 40px;
    @media screen and (max-width: 460px) {
        padding: 15px 20px;
    }
    background-color: ${colors.backgroundColor2};
    border-radius: 0px 0px 50px 50px;
    color: ${colors.white};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
`;


const ActionButton = ({data, viewCourse}) => (
    <Pointer onClick={() => {return viewCourse()}}>
        {!data.isCompleted
            ? <>
                {data.course.isReadingMaterial
                    ? <>
                        {data.course.isVideo
                            ? <ButtonPrimary submit={false} label={"Watch"} />
                            : <ButtonPrimary submit={false} label={"Read"} />
                        }
                    </>
                    : <ButtonPrimary submit={false} label={"Continue"} />
                }
            </>
            : <ButtonPrimary submit={false} label={"Review"} />
        }
    </Pointer>
)

class ProgressCourseOne extends React.Component {
    state={"showSMS": false};

    componentDidMount() {
    }

    openSMS = async (coachId) => {
        this.props.history.push("/my-team/"+coachId+"/messages");
    };

    viewCourse = async () => {
        if(this.props.data.course.isReadingMaterial) {
            if(this.props.data.course.isVideo) {
                return this.props.history.push("/learn/video/" + this.props.data.course.id)
            } else {
                return this.props.history.push("/learn/article/" + this.props.data.course.id)
            }
        } else {
            return this.props.history.push("/learn/course/" + this.props.data.course.id)
        }
    };

    render() {
        return(
            <>
            <Type>
                {this.props.data.course.isReadingMaterial
                    ? <>
                        {this.props.data.course.isVideo
                            ? <FontSubtitle24>VIDEO</FontSubtitle24>
                            : <FontSubtitle24>ARTICLE</FontSubtitle24>
                        }
                    </>
                    : <FontSubtitle24>COURSE</FontSubtitle24>
                }
            </Type>
            <SettingsCard isCompleted={this.props.data.isCompleted}>
                <CourseImage src={this.props.data.course.imageUrl} alt={""}/>
                <FlexCol>
                    <FontTitle28><Olive>{this.props.data.course.title}</Olive></FontTitle28>
                    <Description>
                        <FontBody14>{this.props.data.course.description}</FontBody14>
                    </Description>
                    <FlexRow>
                        {this.props.data.assignedByUser.id === this.props.userId &&
                            <ActionButton viewCourse={this.viewCourse} data={this.props.data} />
                        }
                        {this.props.data.assignedByUser.id !== this.props.userId &&
                            <FlexRow>
                                <FontBody18>Assigned by {this.props.data.assignedByUser.firstName + " " + this.props.data.assignedByUser.lastName}</FontBody18>
                                <ActionButtons>
                                    <Pointer onClick={() => {return this.openSMS(this.props.data.assignedByUser.id)}}>
                                        <ButtonPrimary submit={false} label={"Question?"} />
                                    </Pointer>
                                    <ActionButton viewCourse={this.viewCourse} data={this.props.data} />
                                </ActionButtons>
                            </FlexRow>
                        }
                    </FlexRow>
                </FlexCol>
                {this.props.data.isCompleted &&
                    <CompletedBox>
                        <FontHeader16>Completed on {moment(this.props.data.completedOn).format('LLL')}</FontHeader16>
                    </CompletedBox>
                }
            </SettingsCard>
            </>
        )
    }
}

const mapStateToProps = state => ({
    userId: state.common.user.get("userId")
});

export default connect(mapStateToProps, null)(withRouter(ProgressCourseOne));
