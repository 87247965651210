import {axiosReq} from "../../../common/utils/axios";
import {trackInitiateCheckout, trackPurchase} from '../../../common/utils/tracking';

/* Store */
import {setProgramDownloading, setProgramData, setProgramCheckoutDownloading, setProgramCheckoutData, setProgramCheckoutReceiptDownloading, setProgramCheckoutReceiptData} from "../store/program";

/* Static */
import {devProgramData, devProgramCheckoutData, devProgramReceiptData} from "../static/devdata";

export const tryGetProgramToken = (token) => {
    return async dispatch => {
        dispatch(setProgramDownloading(true));
        const program = await axiosReq('apiv1/consumer/purchase/'+token, "GET", {}, devProgramData);
        let didError = false;
        if(program.hasOwnProperty("error")) {
            didError = true;
        }
        await dispatch(setProgramData({...program, "token": token}));
        await dispatch(setProgramDownloading(false));
        return ({"didError": didError});
    }
};

export const tryPostCheckoutData = (programToken, subscriptionPlan) => {
    return async dispatch => {
        dispatch(setProgramCheckoutDownloading(true));
        const checkoutData = await axiosReq('apiv1/consumer/purchase/get-stripe-checkout-session/'+programToken+'/'+subscriptionPlan, "POST", {}, devProgramCheckoutData);
        if(checkoutData.hasOwnProperty("error")) {
            //
        }
        await dispatch(setProgramCheckoutData(checkoutData));
        dispatch(trackInitiateCheckout());
        return dispatch(setProgramCheckoutDownloading(false));
    }
};

export const tryGetThankYouToken = (token) => {
    return async dispatch => {
        dispatch(setProgramCheckoutReceiptDownloading(true));
        const program = await axiosReq('apiv1/consumer/purchase/receipt/'+token, "GET", {}, devProgramReceiptData);
        if(program.hasOwnProperty("error")) {
            //
        }
        dispatch(setProgramCheckoutReceiptData({...program, "token": token}));
        dispatch(trackPurchase());
        return dispatch(setProgramCheckoutReceiptDownloading(false));
    }
};

export const packageTitle = priceId => {
    if(priceId === 'price_1GvmnCBPDoWM9BMeC56nmCHI') {return({"title": "Concierge Program", "payment": "$165/month"})}
    else if(priceId === 'price_1GvmnCBPDoWM9BMe8Co90qGv') {return({"title": "Concierge Program", "payment": "$1,895"})}
    else if(priceId === 'price_1GvmloBPDoWM9BMeyEPFvE1L') {return({"title": "Momentum Program", "payment": "$278/month"})}
    else if(priceId === 'price_1GvmlnBPDoWM9BMeqKFyPtcd') {return({"title": "Momentum Month Program", "payment": "$795"})}
    else if(priceId === 'price_1Gs9GJBPDoWM9BMelrwi7FBe') {return({"title": "Financial Checkup", "payment": "$295"})}
};