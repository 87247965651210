import React from "react";
import styled from "styled-components";

/* Components */
import {colors} from "../../../common/components/colors";
import {FontBody12, FontBody16, FontTitle24, FontSubtitle24} from "../../../common/components/fonts";

/* Utility */
import { hexToRGB, brightness } from '../../../common/utils/colors';

export const Paragraph = styled.div`
    max-width: ${props => props.maxWidth};
    width: 100%;
    ${props => props.center !== true} {
        text-align: center;
        margin: 0px auto;
    }
`;
export const Title = styled.div`
    margin-bottom: ${props => props.spacer}px;
`;
export const Body = styled.div`
    color: ${colors.secondary100};
`;
export const ToDoSpacerNegative = styled.div`
    margin: ${props => props.pixels}px;
`;
export const Center = styled.div`
    text-align: center;
`;
export const QuillCard = styled.div`
    & .ql-editor {
        padding: 15px 20px;
        background-color: ${colors.border70};
        border: 1px solid ${colors.primary30};
        border-radius: 4px;
    }
`;
export const QuillBorder = styled.div`
    padding-bottom: 10px;
`;
export const ProfileLeft = styled.div`
    width: 200px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    & img {
        border-radius: 4px;
    }
    @media screen and (max-width: 748px) {   
        display: block;
        width: 100%;
    }
`;
export const ProfileRight = styled.div`
    width: calc(100% - 240px);
    display: inline-block;
    vertical-align: top;
    padding: 40px 20px 0px 20px;
    @media screen and (max-width: 748px) {   
        display: block;
        width: 100%;
        padding: 20px 0px 0px 0px;
        text-align: center;
    }
`;
export const ActionSection = styled.div`
    display: inline-block;
    vertical-align: middle;
    margin-top: 20px;
    @media screen and (max-width: 1440px) {
        display: block;
    }
`;
export const Left = styled.div`
    width: calc(100% - 40px);
    display: inline-block;
    vertical-align: bottom;
`;
export const Icon = styled.div`
    display: inline-block;
    vertical-align: top;
    ${props => props.active !== true} {
        color: ${colors.white};
    }
    ${props => props.active !== false} {
        color: ${colors.action100};
    }
    & svg {
        width: 16px;
        height: 16px;
        margin-top: 3px;
    }
`;
export const InlineButton = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-right: 7px;
    margin-bottom: 10px;
    @media screen and (max-width: 460px) {
        display: block;
        text-align: center;
        margin: 0px auto 10px auto;
        & div {
            margin: 0px auto;
        }
    }
`;

export const Bar = styled.div`
    width: 100%;
    margin-bottom: 40px;
    background-color: ${colors.border100};
`;
export const Button = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 10px 25px;
    ${props => props.active === true} {color: ${colors.primary30};}
    ${props => props.active === false} {color: ${colors.action100};}
    margin-right: 20px;
    cursor: pointer;
`;

export const SettingsCard = styled.div`
    -webkit-box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    background-color: ${colors.white};
    border-radius: 4px;
    padding: 47px;
    color: ${colors.primary100};
    max-width: ${props => props.maxWidth};
    margin: 0px auto 40px auto;
    @media screen and (max-width: 748px) {
        margin: 40px auto 0px auto;
    }
    @media screen and (max-width: 460px) {
        margin: 20px auto 0px auto;
        padding: 30px 20px;
    }
`;

export const AdjustListings = styled.div`
    & > div {
        width: 100%;
        padding: 0px;
        margin: 0px;
    }
`;

export const Image = styled.img`
    object-fit: cover;
    object-position: 0 0;
`;

// Actually used components in new design:
export const MemberProfileCard = styled.div`
    background-color: ${colors.white};
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    width: 500px;
    z-index: 11;
    @media screen and (max-width: 748px) {
        left: 0;
        width: auto;
    }
`;

export const Background = styled.div`
    background-color: rgba(0,0,0,0.5);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;
`;

export const BackSection = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const Back = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 5px;
`;

export const Profile = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 20px;
    margin-bottom: 10px;
`;

export const ProfileImage = styled.img`
    border-radius: 50%;
    height: 120px;
    margin-bottom: 10px;
    object-fit: cover;
    width: 120px;
    @media screen and (max-width: 460px) {
        height: 80px;
        width: 80px;
    }
`;

export const PillBar = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
`;

// TODO: change color 
export const Pill = styled.div`
    cursor: pointer;
    padding: 5px 10px;
    text-align: center;
    ${props => props.active === false} {
        background-color: ${colors.backgroundColor4};
        border-radius: 25px;
    }
`;

export const ExpandClick = styled(FontBody12)`
    cursor: pointer;
`;

export const FlexColRev = styled.div`
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
    height: auto;
`;

export const FlexRowJCB = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 300px;
`;
export const FlexRowJCSA = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;
export const FitContent = styled.div`
    width: fit-content;
`;

export const Footer = styled.div`
    background-color: ${colors.white};
    border-top: 1px solid ${colors.primary30};
    bottom: 0;
    display: flex;
    gap: 20px;
    justify-content: center;
    padding: 25px 0;
    border-radius: 30px 30px 0px 0px;
    position: sticky;
    right: 0;
    width: 100%;
    z-index: 12;
    @media screen and (max-width: 748px) {
        left: 0;
        width: auto;
    }
`;

export const FooterButton = styled.div`
    align-items: center;
    border-radius: 25px;
    box-shadow: 0px 3px 10px 0px #00000040;
    cursor: pointer;
    display: flex;
    gap: 5px;
    padding: 10px 20px;
    justify-content: center;
    text-align: center;
    background-color: ${colors.action100};
    color: ${colors.white};
    ${props => props.disabled === false} {
        cursor: default;
        background-color: ${colors.primary30};
    }
    max-width: 235px;
`;

export const Pointer16 = styled(FontBody16)`
    cursor: pointer;
    width: fit-content;
`;

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
`;

export const MyCareSection = styled.div`
    border-radius: 0px 0px 30px 30px;
    background-color: ${props => props.backgroundColor};
    color: ${props => brightness(hexToRGB(props.backgroundColor, false)) < 130 ? colors.white : colors.primary100};
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    height: 100%;
    // padding: ${props => props.fullPage ? "65px 40px 40px" : "80px 40px"};
    box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 12%);
    // &:first-child {
    //     padding-bottom: ${props => props.fullPage ? "40px" : "100px !important"};
    //     margin-bottom: ${props => props.fullPage ? "0px" : "-55px"};
    //     border-radius: ${props => props.fullPage && "0px"};
    // }
    // &:last-child {
    //     border-radius: ${props => props.fullPage && "30px"};
    //     padding-top: ${props => props.fullPage && "50px"};
    // }
`;

export const DownloadsSection = styled.div`
  border-radius: 0px 0px 30px 30px;
  background-color: ${props => props.backgroundColor};
  color: ${props => brightness(hexToRGB(props.backgroundColor, false)) < 130 ? colors.white : colors.primary100};
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 100%;
  box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 12%);
  padding: 40px !important;
  z-index: 1;
`;
export const OfflineSection = styled.div`
  border-radius: 0px 0px 30px 30px;
  background-color: ${props => props.backgroundColor};
  color: ${props => brightness(hexToRGB(props.backgroundColor, false)) < 130 ? colors.white : colors.primary100};
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 100%;
  box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 12%);
  padding: 65px 40px 40px 40px !important;
  margin-top: -25px !important;
`;

export const ProfileCardTop = styled.div`
    padding: 25px 25px 30px 25px;
    background: ${colors.white};
    border-radius: 0px 0px 30px 30px;
    position: relative;
    box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 12%);
`;

export const IconButton = styled.div`
    background-image: url(${props => props.image});
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    cursor: pointer;
    align-self: center;
    justify-self: center;
`; 

export const PageOptionBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 60px;
    margin-bottom: 20px;
    justify-content: space-evenly;
`
export const OptionActive = styled(FontTitle24)`
    font-weight: bold;
    text-decoration: underline;
    text-decoration-color: ${colors.action100};
    letter-spacing: 2px;
`
export const OptionInactive = styled(FontSubtitle24)`
    letter-spacing: 2px;
    line-height: 36px;
`