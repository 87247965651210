import {axiosReq} from "../../../utils/axios";
import {fromJS, Map} from 'immutable';

import {setWMCDownloading, setWMCCheck, setWMCFirmDownloading, setWMCFirm, setWMCFirmEmployeesDownloading, setWMCFirmEmployees} from '../store/wmc';

import {devWMC, devSettingsUsersEmployees} from '../static/devdata';

export const tryCheckWMCByIdWithReturn = (id) => {
    return async dispatch => {
        dispatch(setWMCDownloading(true));
        const wmc = await axiosReq('apiv1/wmc/by-employee/'+id, "GET", {}, devWMC);
        dispatch(setWMCDownloading(false));
        return fromJS(wmc) !== Map();
    }
};

export const tryCheckWMCById = (id) => {
    return async dispatch => {
        dispatch(setWMCDownloading(true));
        const wmc = await axiosReq('apiv1/wmc/by-employee/'+id, "GET", {}, devWMC);
        if(fromJS(wmc) !== Map()) {
            dispatch(setWMCCheck(true, wmc));
        } else {
            dispatch(setWMCCheck(false, Map()));
        }
        dispatch(setWMCDownloading(false));
    }
};

export const tryGetMCById = () => {
    return async (dispatch, getState) => {
        dispatch(setWMCFirmDownloading(true));
        const id = getState().common.user.get("wmc").get("id");
        const wmc = await axiosReq('apiv1/wmc/'+id, "GET", {}, devWMC);
        dispatch(setWMCFirm(wmc));
        dispatch(setWMCFirmDownloading(false));
    }
};

export const tryGetWMCEmployees = (wmcId) => {
    return async dispatch => {
        dispatch(setWMCFirmEmployeesDownloading(true));
        const employees = await axiosReq('apiv1/wmc/'+wmcId+'/employees', "GET", {}, devSettingsUsersEmployees);
        dispatch(setWMCFirmEmployees({"error": null, "employees": employees.employees}));
        return dispatch(setWMCFirmEmployeesDownloading(false));
    }
};
