import React from 'react';
import {withRouter} from 'react-router-dom';

/* Containers */
import ClientsList from './containers/clientslist';
import ClientsCreate from './containers/clientscreate';

/* Navs */
import {PageContent, PageFull, PageRight} from "../navbars/components/navlayout";
import NavPrimaryAll from "../navbars/containers/primaryall";
import NavSecondaryTop from '../navbars/containers/secondarytop';

/* Components */
import {PlusOutlined} from "@ant-design/icons";

const prospectButtons = [
    {
        "pathname": "/clients/create",
        "label": <><PlusOutlined /> Create</>,
        "buttonType": "primary"
    }
];

const prospectTabs = [
    // {
    //     title: "Current Clients",
    //     label: "Current",
    //     href: "/clients",
    //     alwaysActiveForSubRoutes: false
    // },
    // {
    //     title: "Previous Clients",
    //     label: "Previous",
    //     href: "/clients/previous",
    //     alwaysActiveForSubRoutes: false
    // }
];

const Clients = ({location}) => (
    <>
        <NavPrimaryAll />
        <PageFull>
            <PageRight>

                {(location.pathname.includes("/clients") && location.pathname !== "/clients/create") &&
                    <>
                        <NavSecondaryTop title={"Clients"} buttons={prospectButtons} tabs={prospectTabs} desc={"Manage your current and previous clients"}/>
                        <PageContent>
                            <ClientsList />
                        </PageContent>
                    </>
                }

                {location.pathname === "/clients/create" &&
                    <>
                        <NavSecondaryTop title={"Create Client"}/>
                        <PageContent>
                            <ClientsCreate />
                        </PageContent>
                    </>
                }

            </PageRight>
        </PageFull>
    </>
);
export default withRouter(Clients);
