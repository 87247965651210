import React from 'react';
import styled from 'styled-components';

/* Components */
import {FontHeader18, FontBody16, FontHeader16, FontSubtitle28} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";

/* Containers */
import ChangePassword from '../../changepassword/containers/changepassword';
import EmailInput from "./email";

export const Left = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 150px;
    @media screen and (max-width: 460px) {
        width: 100%;
        display: block;
    }
`;
export const Right = styled.div`
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 150px);
    text-align: right;
    margin-top: 8px;
    color: ${colors.primary70};
    @media screen and (max-width: 460px) {
        width: 100%;
        display: block;
        text-align: left;
    }
`;
export const LoginBtn = styled.div`
    color: ${colors.action100};
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
`;

const Container = styled.div`
    max-width: 768px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 0px auto 0px auto;
`;

const Message = styled(FontSubtitle28)`
    margin: 85px auto 50px auto;
    width: fit-content;
    letter-spacing: normal;
`;

const formStyleSystem = {
    showBackBtn: true,
    showTitle: true,
    showLoginLink: true,
    containerStyles: {},
    insideCard: false,
};

const pageStyleSystem = {
    showBackBtn: false,
    showTitle: false,
    showLoginLink: false,
    containerStyles: {backgroundColor: colors.backgroundColor1, borderRadius: "20px", padding: "30px 45px", width: "calc(100% - 90px)"},
    insideCard: true,
};

class ResetPassword extends React.Component {
    static defaultProps = {
        passwordChangeSuccess: () => {},
        back: () => {},
        login: () => {},
        token: "",
        resetSystem: "form" // form or page
    };

    state={
        ...formStyleSystem,
        "isLoading": true,
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            ...(this.props.styleSystem === "page" ? pageStyleSystem : formStyleSystem),
            'isLoading': false
        })
    };

    render() {
        return(
            <>
                <Message>Create a password</Message>
                <Container style={this.state.containerStyles}>
                    {(this.state.showTitle || this.state.showLoginLink) &&
                        <div>
                            <Left>{this.state.showTitle && <FontHeader18>Set Your Password</FontHeader18>}</Left>
                            <Right>{this.state.showLoginLink && <FontBody16>Know your password?&nbsp;<LoginBtn onClick={this.props.login}><FontHeader16>Login</FontHeader16></LoginBtn></FontBody16>}</Right>
                        </div>
                    }
                    <ChangePassword
                        title={""}
                        subTitle={""}
                        isInsideCard={this.state.insideCard}
                        onSuccess={this.props.passwordChangeSuccess}
                        onSuccessMessage={"Password successfully changed"}
                        hasBack={true}
                        back={this.props.back}
                        isIframe={true}
                        token={this.props.token}
                        styleSystem={this.props.styleSystem}
                    />
                </Container>
            </>
        )
    }
}

export default ResetPassword;
