import React from "react";
import ReactQuill from "react-quill";
import {connect} from "react-redux";
import {v4 as uuid4} from "uuid";
import {withRouter} from 'react-router-dom';
import styled from "styled-components";

/* Components */
import {FontBody16, FontHeader16, FontHeader18, FontHeader14, FontTitle24} from "../../../../common/components/fonts";
import {DeleteOutlined, EditOutlined, EyeOutlined, LinkOutlined, EyeInvisibleOutlined} from "@ant-design/icons";
import {colors} from "../../../../common/components/colors";
import {Listing, Title, Body, Inline, Description, Footer, LeftTitle, RightTitle, Type} from '../components/eventsone';
import {QuillSlimNoHeight} from "../../../../common/components/richtexteditor";
import {ButtonPrimary, ButtonTertiary} from "../../../../common/components/buttons";

/* Middleware */
import {tryGetListingsOne, tryUpdateListingStatus} from "../middleware/listings";

const Modal = styled.div`
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    padding: 30px;
    border-radius: 4px;
    background-color: ${colors.white};
    -webkit-box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    max-width: 300px;
    width: calc(100% - 80px);
    z-index: 20;
`;
const ConfirmTitle = styled.div`
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.border100};
    margin-bottom: 15px;
`;
const ConfirmActions = styled.div`
    margin-top: 20px;
`;
const ConfirmLeft = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
`;
const ConfirmRight = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
`;
const ConfirmBtnFitLeft = styled.div`
    width: fit-content;
`;
const ConfirmBtnFitRight = styled.div`
    width: fit-content;
    margin: 0px 0px 0px auto;
`;

class EventsOne extends React.Component {
    state={"confirmModal": false};

    edit = async (listingId) => {
        await this.props.tryGetListingsOne(listingId);
        this.props.history.push("/settings/availability/appointments/edit")
    };

    delete = async (listingId) => {
        this.props.tryUpdateListingStatus(listingId, {"status": "deleted"});
    };
    
    render() {
        return(
            <Listing key={this.props.l.get("id")}>
                <Body>
                    <Description hasPhoto={false}>
                        <Title><FontHeader18>{this.props.l.get("title")}</FontHeader18></Title>
                        <Type><FontBody16>{this.props.l.get("sessionLength")}-minutes</FontBody16></Type>
                        <QuillSlimNoHeight bodyBorderColor={colors.white}>
                            <ReactQuill
                                id={uuid4()}
                                readOnly={true}
                                theme={null}
                                value={this.props.l.get("description")}
                                bounds={'.app'}
                            />
                        </QuillSlimNoHeight>
                    </Description>
                </Body>
                <Footer>
                    <LeftTitle>
                        <Inline onClick={() => {return this.edit(this.props.l.get("id"))}}>
                            <FontHeader16><EditOutlined />&nbsp;Edit</FontHeader16>
                        </Inline>
                        <Inline onClick={() => {this.setState({"confirmModal": true})}}>
                            <FontHeader16><DeleteOutlined />&nbsp;Delete</FontHeader16>
                        </Inline>
                        <Inline onClick={() => {return this.props.setLinkEmbedId(this.props.l.get("id"))}}>
                            <FontHeader16><LinkOutlined />&nbsp;Link or Embed</FontHeader16>
                        </Inline>
                    </LeftTitle>
                    <RightTitle>
                        {this.props.l.get("status").toLowerCase() === "active"
                            ? <FontHeader14><EyeOutlined />&nbsp;Public</FontHeader14>
                            : <FontHeader14><EyeInvisibleOutlined />&nbsp;Private</FontHeader14>
                        }
                    </RightTitle>
                </Footer>
                {this.state.confirmModal &&
                    <Modal>
                        <ConfirmTitle><FontTitle24>Are you sure?</FontTitle24></ConfirmTitle>
                        <FontBody16>Do you want to delete this appointment? This cannot be undone.</FontBody16>
                        <ConfirmActions>
                            <ConfirmLeft>
                                <ConfirmBtnFitLeft onClick={() => {this.setState({"confirmModal": false})}}><ButtonTertiary label={"Cancel"} canSubmit={true} /></ConfirmBtnFitLeft>
                            </ConfirmLeft>
                            <ConfirmRight>
                                <ConfirmBtnFitRight onClick={async () => {return this.delete(this.props.l.get("id"))}}><ButtonPrimary label={"Delete"} canSubmit={true} /></ConfirmBtnFitRight>
                            </ConfirmRight>
                        </ConfirmActions>
                    </Modal>
                }
            </Listing>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    tryGetListingsOne: (listingId) => dispatch(tryGetListingsOne(listingId)),
    tryUpdateListingStatus: (listingId, data) => dispatch(tryUpdateListingStatus(listingId, data))
});

export default connect(null, mapDispatchToProps)(withRouter(EventsOne));
