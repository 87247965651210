import React from 'react';
import styled from 'styled-components';

/* Components */
import { FontSubtitle28, FontBody18, FontBody21, FontSubtitle40 } from "../../../../common/components/fonts";
import {LogoLoading} from "../../../../common/components/loading";
import { ButtonTertiary } from "../../../../common/components/buttons";

/* Containers */

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

const CategoryTitleAndButton = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center; 
`;
const Category = styled.div`
    margin: 60px 0 0 0;
    padding: 0 60px;
    @media screen and (max-width: 748px) {
    padding: 0;
    }
`;
const CategoryResponse = styled(FontBody21)`
    margin: 10px 0 0 0;
`;
const DeactivateButton = styled.div`
    margin: 20px 0 0 0;
    & div {
        margin-left: auto;
    }
`;

class FirmSettingsStripe extends React.Component {
    state = {
        "isLoading": true
    }

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "isLoading": false
        })
    }

    render() {
        if (this.state.isLoading) {
            return (<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <Category>
                        <CategoryTitleAndButton>
                            <FontSubtitle28 spaced={true}>BILLING</FontSubtitle28>
                        </CategoryTitleAndButton>
                        <CategoryResponse>
                            <FontBody18>Would you like to look at your payments?</FontBody18>
                            <DeactivateButton onClick={() => { window.location.href = 'https://pay.trustwillow.com/p/login/6oE5mK8P77CmcYUfYY' }}>
                                <ButtonTertiary label={"Manage Billing"} canSubmit={false} />
                            </DeactivateButton>
                        </CategoryResponse>
                    </Category>
                </>
            )
        }
    }
}

export default FirmSettingsStripe;