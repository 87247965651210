import React from 'react';
import {connect} from 'react-redux';

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FontTitle18} from "../../../common/components/fonts";
import {InlineBlock, Right, SaveSection, SubmitInputWithValidation, Width, WidthRight} from "../components/createcourse";
import {ButtonTertiary} from "../../../common/components/buttons";

/* Middleware */
import {tryCreateCourse} from '../middleware/courses';

/* Containers */
import CreateCourseTitle from "./createcoursetitle";
import CreateCourseDescription from "./createcoursedescription";
import CreateCoursePhoto from "./createcoursephoto";
import CreateCoursePages from "./createcoursepages";

class CreateCourse extends React.Component {
    state={
        title: {
            title: "",
            titleValid: false
        },
        description: {
            description: '',
            descriptionValid: false,
            descriptionCount: 0,
            descriptionComponent: ''
        },
        image: {
            imgURL: "",
            imgURLValid: false,
            imgPreview: false,
        },
        pages: {
            pages: []
        },
        isLoading: true,
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({"isLoading": false})
    };

    handleChangeTitle = async (state) => {
        await this.setState({"title": state});
        this.checkValid();
    };

    handleChangeDescription = async (state) => {
        await this.setState({"description": state});
        this.checkValid();
    };

    handleChangeImage = async (state) => {
        await this.setState({"image": state});
        this.checkValid();
    };

    handleChangePages = async (state) => {
        await this.setState({"pages": state});
        this.checkValid();
    };

    checkValid = () => {
        if(this.state.title.titleValid && this.state.description.descriptionValid && this.state.image.imgURLValid) {
            this.setState({'isValid': true, 'isChanged': true})
        } else {
            this.setState({'isValid': false, 'isChanged': true})
        }
    };

    onSubmit = async () => {
        await this.props.tryCreateCourse();
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <>
                    <div>
                        <Width>
                            <br />
                            <FontTitle18>Details</FontTitle18>
                            <CreateCourseTitle
                                title={this.state.title}
                                onValidate={this.handleChangeTitle}
                            />
                            <CreateCourseDescription
                                description={this.state.description}
                                onValidate={this.handleChangeDescription}
                            />
                            <CreateCoursePhoto
                                image={this.state.image}
                                onValidate={this.handleChangeImage}
                                title={"Image*"}
                            />
                        </Width>
                        <WidthRight>
                            <Right>
                                <CreateCoursePages
                                    pages={this.state.pages}
                                    onValidate={this.handleChangePages}
                                />
                            </Right>
                        </WidthRight>
                    </div>
                    <SaveSection>
                        <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                        <InlineBlock><div onClick={() => {return this.props.history.push("/curriculum")}}><ButtonTertiary canSubmit={false} label={"Back"} /></div></InlineBlock>
                    </SaveSection>
                </>
            )
        }
    }

}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    tryCreateCourse: (courseData) => dispatch(tryCreateCourse(courseData))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCourse);