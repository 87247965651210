import React from 'react';
import styled from 'styled-components';
import validator from "validator";

/* Components */
import {colors} from '../../../common/components/colors';
import {ArrowLeftOutlined, CloseOutlined} from '@ant-design/icons';
import {TextInput, TextareaInputNoCount} from "../../../common/components/inputs";
import {InlineBlock, SaveSection, SubmitInputWithValidation, ButtonRight} from "../components/messages";
import {ButtonTertiary} from "../../../common/components/buttons";
import {FontBody14, FontHeader18} from '../../../common/components/fonts';

const Red = styled.div`
    color: ${colors.alert100};
`;

const Container = styled.div`
    max-width: 768px;
    width: calc(100% - 20px);
    ${({inModal}) => inModal
        ? `margin: 20px auto 0px auto;`
        : `margin: 60px auto 0px auto;`
    }
`;

const BackTop = styled.div`
    color: ${colors.secondary70};
    cursor: pointer;
    position: relative;
    z-index: 25;
    ${props => props.back !== true} {text-align: left;}
    ${props => props.back !== false} {text-align: right;}
    display: block;
    @media screen and (max-width: 748px) {
        margin-left: 60px;
    }
    @media screen and (max-width: 460px) {
        margin-left: 0px;
    }
`;

const Header = styled.div`
    color: ${colors.primary100};
    padding-top: 35px;
    position: relative;
    margin-left: 10px;
    display: inline-block;
    font-size: 30px;
    @media screen and (max-width: 460px) {
        margin-top: 0px;
    }
`;

const Bottom = styled.div`
    margin-bottom: 10px;
`;

const ProfilePhoto = styled.img`
    background-color: ${colors.primary30};
    height: 87px;
    width: 87px;
    border-radius: 100px;
    border: 2px solid ${colors.white};
    object-fit: cover;
    object-position: 0 0;
`;

class Message extends React.Component {
    state={"loadedProduct": false, "message": "", "messageValid": false, "subject": "", "isSaving": false, "isSaved": false, "error": '', "isValid": false, "isChanged": false, "isLoading": false};

    componentDidMount() {
        this.setState({
            "message": this.props.message,
            "subject": this.props.subject,
        });
        if (this.props.message !== "") {
            this.setState({
                "messageValid": true,
                "isChanged": true
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    reset = async () => {
        await this.setState({
            "message": "",
            "isChanged": false,
        });
    };

    handleChangeMessage = async e => {
        await this.setState({'message': e.target.value, 'messageValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''), 'isChanged': true});
        this.checkValid();
    };

    handleChangeSubject = async e => {
        await this.setState({'subject': e.target.value, 'isChanged': true});
        this.checkValid();
    };

    checkValid = async () => {
        if(this.state.messageValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            await this.setState({'isSaving': true, "error": ""});
            await this.setState({'isSaving': false, "isSaved": true});
            this.timeout = setTimeout(() => {this.setState({'isSaved': false, "isChanged": false})}, 3000);
        }
    };

    render() {
        return (
            <Container inModal={this.props.inModal}>
                {this.props.showCloseInsteadOfBack
                        ? <BackTop onClick={this.props.close} back={false}><CloseOutlined /></BackTop>
                        : <>{!this.state.loadedProduct && <BackTop onClick={this.props.back} back={true}><ArrowLeftOutlined/>&nbsp;Back</BackTop>}</>
                }
                <InlineBlock>
                    {this.props.photo !== null && <ProfilePhoto src={this.props.photo} width={87} height={87} />}
                    <FontHeader18><Header>Message {this.props.firstName}</Header></FontHeader18>
                </InlineBlock>
                    <div>
                        <TextInput
                            title={"Subject"}
                            valid={true}
                            warning={""}
                            id={"message"}
                            onChange={this.handleChangeSubject}
                            placeholder={"Subject"}
                            value={this.state.subject}
                        />
                        <TextareaInputNoCount
                            title={"Body"}
                            valid={null}
                            warning={null}
                            id={"message"}
                            onChange={this.handleChangeMessage}
                            placeholder={"Write your message here..."}
                            value={this.state.message}
                            minLength={0}
                            minRows={5}
                        />
                    </div>
                    {this.props.error !== null &&
                        <FontBody14>
                            <Red>{this.props.error}</Red>
                        </FontBody14>
                    }
                    <Bottom>
                        <SaveSection>
                            <InlineBlock><ButtonRight><div onClick={this.props.close}><ButtonTertiary canSubmit={false} label={"Cancel"} /></div></ButtonRight></InlineBlock>
                            <InlineBlock><div onClick={() => (this.state.isValid) ? this.props.next(this.state.message, this.state.subject, this.state.messageValid) : this.handleSubmit}><SubmitInputWithValidation label={"Send"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></div></InlineBlock>
                        </SaveSection>
                    </Bottom>
            </Container>
        )
    }
}

export default Message;