import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setMessagesByTokenAddOne, setMessagesByTokenSendPending, setMessagesByTokenSendComplete, setMessagesByTokenActivity, setMessagesByTokenDownloadPending, setMessagesByTokenDownloadComplete} from "../store/messagesbytoken";

/* Static */
import { devMessagesAddData, devMessagesData } from '../static/devdata';

export const tryGetMessagesFromToken = (token) => {
    return async dispatch => {
        dispatch(setMessagesByTokenDownloadPending());
        const messages = await axiosReq('apiv1/coach/clients/messages/'+token, "GET", {}, devMessagesData);
        await dispatch(setMessagesByTokenActivity(messages));
        return dispatch(setMessagesByTokenDownloadComplete());
    }
};

export const tryPostMessagesFromToken = (mediaType, content, subject, token) => {
    return async dispatch => {
        dispatch(setMessagesByTokenSendPending());
        const messagesAdd = await axiosReq('apiv1/coach/clients/messages/'+token, "POST", {content: content, subject: subject, channel: mediaType}, devMessagesAddData(true, content, subject, mediaType));
        console.log(messagesAdd)
        await dispatch(setMessagesByTokenAddOne(messagesAdd));
        return dispatch(setMessagesByTokenSendComplete());
    }
};
