import React from 'react';
import styled from 'styled-components';

/* Components */
import {FontSubtitle28, FontBody18, FontBody21, FontSubtitle40} from "../../../../common/components/fonts";
import {colors} from "../../../../common/components/colors";
import {LogoLoading} from "../../../../common/components/loading";
import FirmModal from "../../../../common/components/modalv2";
import {ButtonTertiary} from "../../../../common/components/buttons";

/* Containers */
import ChangePassword from "../../../../iframes/changepassword/containers/changepassword";

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;
const CategoryTitleAndButton = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center; 
`;
const Category = styled.div`
    margin: 60px 0 0 0;
    padding: 0 60px;
    @media screen and (max-width: 748px) {
    padding: 0;
    }
`;
const CategoryResponse = styled(FontBody21)`
    margin: 10px 0 0 0;
`;
const DeactivateButton = styled.div`
    margin: 20px 0 0 0;
    & div {
        margin-left: auto;
    }
`;
const Title = styled(FontSubtitle40)`
  margin-bottom: 60px;
  margin-top: -30px;
`;
const TextAlign = styled.div`
    text-align: left;
`;

const FirmModalResponsive = styled.div`
    & > div {
        max-width: 600px;
        @media screen and (max-width: 1440px) {
            left: 62%;
        }
        @media screen and (max-width: 1088px) {
            left: 50%;
        }
        @media screen and (max-width: 748px) {
            left: 0;
            max-width: calc(100% - 100px);
        }
    }
`;

class FirmSettingsPassword extends React.Component {
    state = {
        "showModal": false,
        "isLoading": true
    }

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "isLoading": false
        })
    }

    openChangePassword = async () => {this.setState({"showModal": true})}
    closeChangePassword = async () => {this.setState({"showModal": false})}

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <Category>
                        <CategoryTitleAndButton>
                            <FontSubtitle28 spaced={true}>SECURITY</FontSubtitle28>
                        </CategoryTitleAndButton>
                        <CategoryResponse>
                            <FontBody18>Would you like to change your password?</FontBody18>
                            <DeactivateButton onClick={this.openChangePassword}>
                                <ButtonTertiary label={"Change Password"} canSubmit={false} />
                            </DeactivateButton>
                        </CategoryResponse>
                    </Category>
                    {this.state.showModal &&
                        <FirmModalResponsive>
                            <FirmModal
                                message={
                                    <TextAlign>
                                        <Title spaced={true}>CHANGE PASSWORD</Title>
                                        <ChangePassword
                                            hasBack={false}
                                            styleSystem={"page"}
                                            title={""}
                                        />
                                    </TextAlign>
                                }
                                cancelShow={false}
                                confirmShow={false}
                                closeShow={true}
                                closeAction={this.closeChangePassword}
                                modalStyle={{"backgroundColor": colors.backgroundColor4}}
                            />
                        </FirmModalResponsive>
                    }
                </>
            )
        }
    }
}

export default FirmSettingsPassword;