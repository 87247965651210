import React from 'react';
import {withRouter} from 'react-router-dom';

/* Navs */
import NavSide from "../navbars/containers/navside";
import {PageContentContainer, PageFull, PageContent} from "../navbars/components/navlayouts";
import NotesReport from "./containers/reports"

const FirmReports = ({location}) => {
    return (<>
        <NavSide />
        <PageFull>
            <PageContentContainer fullPage={true}>
                <PageContent>
                    {(location.pathname === "/reports") && <NotesReport />}
                </PageContent>
            </PageContentContainer>
        </PageFull>
    </>
)};

export default withRouter(FirmReports);