import React from 'react';

/* Components */
import { PageFull, PageContentContainer } from '../navbars/components/navlayout';
import ConsumerNav from '../navbars/containers/nav';

/* Containers */
import Settings from "./containers/settings";

const SettingsConsumer = () => (
    <>
        <ConsumerNav />
        <PageFull>
            <PageContentContainer>
                <Settings />
            </PageContentContainer>
        </PageFull>
    </>
);

export default SettingsConsumer;
