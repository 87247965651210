import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment/moment";
import { withRouter } from "react-router-dom";

/* Middleware */
import { tryGetRatingToken, tryPostRatingOfConsumer, tryPostRatingOfCoach } from "./middleware/rating";

/* Local */
import { images } from "../../common/components/images";

/* Common */
import { FontBody16, FontHeader16, FontTitle21 } from "../../common/components/fonts";
import {
    SubmitButton,
    RadioButtonsFive,
    TextareaInputNoCount,
    RadioButtonsTen,
    RadioButtonsTwo,
    Radios,
    RadiosTwo,
} from "../../common/components/inputs";
import { colors } from "../../common/components/colors";
import { LogoLoading } from "../../common/components/loading";
import AdvisorSessionStep from "../../enterprise/containers/contactadvisorsessionstep";
import { tryPostAdvisorSessionStep } from "../../enterprise/homepage/middleware/announcements";

const Logo = styled.div`
    height: 40px;
    padding: 30px 70px 30px 50px;
    text-align: center;
`;
export const Room = styled.div`
    height: 100%;
    width: 100%;
`;
export const RoomContent = styled.div`
    width: 100%;
    @media screen and (max-width: 1088px) {
        padding-bottom: 80px;
    }
    @media screen and (max-width: 748px) {
        height: calc(100% - 165px);
    }
    @media screen and (max-width: 460px) {
        padding-bottom: 0px;
    }
`;
export const RoomContentContainer = styled.div`
    width: 694px;
    margin: 0px auto 120px auto;
    padding: 00px 20px 0px 20px;
    @media screen and (max-width: 748px) {
        padding: 0px 20px 0px 20px;
        width: calc(100% - 40px);
    }
`;

export const Modal = styled.div`
    -webkit-box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    background-color: ${colors.white};
    border-radius: 4px;
    padding: 30px;
    color: ${colors.primary100};
    max-width: 500px;
    position: relative;
    margin: 30px auto 0px auto;
    @media screen and (max-width: 460px) {
        margin: 20px 0px;
        padding: 20px;
    }
`;
export const Body = styled.div`
    margin: 20px 0px;
`;
export const NoteTitle = styled.div`
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.border100};
    color: ${colors.primary100};
`;
const Submit = styled.div`
    max-width: 112px;
`;

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

class RatingRoom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCoach: props.location.pathname.includes("client-rating"),
            token: props.location.pathname.split("/")[2],
            fitForWillow: null,
            fitForCoach: null,
            explanation: "",
            recommendToFriend: null,
            isValid: false,
            alreadyRated: false,
            ratingSubmitted: false,
            ratingDownloading: false,
            name: "",
            datetime: null,
            isLoading: true,
            conferenceId: null,
            attendedSession: null,
            description: "",
        };
    }

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.ratingObject !== this.props.ratingObject) {
            return this.update();
        }
    }

    isAdvisorCertVetting = () =>
        this.state.description && this.state.description.includes("advisorCertVetting");

    init = async () => {
        await this.props.tryGetRatingToken(this.state.token, this.state.isCoach);
        await this.update();
    };

    update = async () => {
        this.setState({
            ratingSubmitted: this.props.ratingObject.get("ratingSubmitted"),
            ratingDownloading: this.props.ratingObject.get("ratingDownloading"),
            alreadyRated: this.props.ratingObject.get("alreadyRated"),
            name: this.props.rating.get("name"),
            datetime: this.props.rating.get("datetime"),
            isLoading: false,
            conferenceId: this.props.rating.get("conferenceId"),
            description: this.props.rating.get("description"),
        });
    };

    updateSessionStep = async (e, reason) => {
        let fit = e ? 5 : 1;
        await this.setState({ fitForWillow: fit, fitForCoach: fit, explanation: reason });
        console.log(fit);
        console.log("fitforcoach: " + this.state.fitForCoach);
        console.log("fitforwillow: " + this.state.fitForWillow);
        console.log("explanation: " + this.state.explanation);
        await this.testValid();
        console.log("testvalid" + this.state.isValid);
        await this.handleSubmit();
        console.log("submitted");
        this.props.history.push("/home");
    };

    changeFitForWillow = async (e) => {
        await this.setState({ fitForWillow: e });
        this.testValid();
    };
    changeFitForCoach = async (e) => {
        await this.setState({ fitForCoach: e });
        this.testValid();
    };
    changeRecommendedToFriend = async (e) => {
        await this.setState({ recommendToFriend: e });
        this.testValid();
    };
    handleChangeExplanation = async (e) => {
        await this.setState({ explanation: e.target.value });
    };

    handleSubmit = async () => {
        if (this.state.isCoach) {
            await this.props.tryPostRatingOfConsumer(this.state);
        } else {
            await this.props.tryPostRatingOfCoach(this.state);
        }
        this.setState({ ratingSubmitted: true });
    };

    testValid = () => {
        if (this.state.isCoach) {
            if (this.state.fitForWillow === null || this.state.fitForCoach === null) {
                this.setState({ isValid: false });
            } else {
                this.setState({ isValid: true });
            }
        } else {
            if (this.state.recommendToFriend === null) {
                this.setState({ isValid: false });
            } else {
                this.setState({ isValid: true });
            }
        }
    };

    getModal = () => {
        if (this.state.ratingDownloading)
            return (
                <Modal>
                    <FontTitle21>Loading!</FontTitle21>
                    <Body>
                        <FontBody16>We are getting your rating information.</FontBody16>
                    </Body>
                </Modal>
            );
        if (this.state.alreadyRated)
            return (
                <Modal>
                    <FontTitle21>Rating already submitted</FontTitle21>
                    <Body>
                        <FontBody16>
                            The Willow team greatly appreciates you taking the time to share your input!
                        </FontBody16>
                    </Body>
                    <SubmitButton
                        link={"/"}
                        iconComponent={null}
                        backgroundColor={colors.action100}
                        disabled={true}
                        color={colors.primary100}
                        label={"Dashboard"}
                    />
                </Modal>
            );
        if (this.state.ratingSubmitted)
            return (
                <Modal>
                    <FontTitle21>Thank you!</FontTitle21>
                    <Body>
                        <FontBody16>
                            The Willow team greatly appreciates you taking the time to share your input!
                        </FontBody16>
                    </Body>
                    <SubmitButton
                        link={"/"}
                        iconComponent={null}
                        backgroundColor={colors.action100}
                        disabled={true}
                        color={colors.primary100}
                        label={"Dashboard"}
                    />
                </Modal>
            );
        if (this.state.token !== null) {
            let modalContent = [];
            if (this.state.isCoach) {
                if (!this.isAdvisorCertVetting()) {
                    modalContent = [
                        <RadioButtonsFive
                            label={"They are a good fit for Willow:"}
                            low={"Bad fit"}
                            high={"Good fit"}
                            checked={this.state.fitForWillow}
                            action={this.changeFitForWillow}
                            key={"label1"}
                        />,
                        <RadioButtonsFive
                            label={"They are a good fit for me:"}
                            low={"Bad fit"}
                            high={"Good fit"}
                            checked={this.state.fitForCoach}
                            action={this.changeFitForCoach}
                            key={"label2"}
                        />,
                        <TextareaInputNoCount
                            title={"Please explain in a few words"}
                            id={"explanation"}
                            onChange={this.handleChangeExplanation}
                            placeholder={""}
                            value={this.state.explanation}
                            minLength={0}
                            minRows={3}
                            key={"label3"}
                        />,
                    ];
                } else {
                    modalContent = [
                        <AdvisorSessionStep
                            userId={this.props.rating.get("id")}
                            conferenceId={this.state.conferenceId}
                            advisorName={this.state.name}
                            updateSessionStep={this.updateSessionStep}
                            key={"userId"}
                        />,
                    ];
                }
            } else {
                modalContent = [
                    <RadioButtonsTen
                        label={
                            "How likely is it that you'd recommend " + this.state.name + " to a friend or colleague?"
                        }
                        low={"Not likely at all"}
                        high={"Extremely likely"}
                        checked={this.state.recommendToFriend}
                        action={this.changeRecommendedToFriend}
                        key={"recommend"}
                    />,
                    <br />,
                    <FontBody16>
                        Ratings are used internally for quality assurance. Responses are never shared with your coach.
                    </FontBody16>,
                    <br />,
                    // <br/>
                    // {/*<TextareaInputNoCount*/}
                    // {/*    title={"Please explain in a few words"}*/}
                    // {/*    id={"explanation"}*/}
                    // {/*    onChange={this.handleChangeExplanation}*/}
                    // {/*    placeholder={""}*/}
                    // {/*    value={this.state.explanation}*/}
                    // {/*    minLength={0}*/}
                    // {/*    minRows={3}*/}
                    // {/*/>*/}
                ];
            }
            return (
                <Modal key={this.state.token}>
                    <NoteTitle>
                        <FontTitle21>Rate {this.state.name}</FontTitle21>
                        <FontBody16>Appointment: {moment(this.state.datetime).format("ll")}</FontBody16>
                    </NoteTitle>
                    {modalContent}
                    {!this.isAdvisorCertVetting() && this.state.isValid && (
                        <Submit onClick={this.handleSubmit}>
                            <SubmitButton
                                backgroundColor={colors.action100}
                                iconComponent={null}
                                color={colors.primary100}
                                label={"Submit"}
                            />
                        </Submit>
                    )}
                    {!this.isAdvisorCertVetting() && !this.state.isValid && (
                        <SubmitButton
                            iconComponent={null}
                            backgroundColor={colors.border70}
                            disabled={true}
                            color={colors.primary30}
                            label={"Submit"}
                        />
                    )}
                </Modal>
            );
        }
        return (
            <Modal>
                <FontTitle21>Unable to find</FontTitle21>
                <Body>
                    <FontBody16>Hmm we could not find a rating that needs to be completed.</FontBody16>
                </Body>
                <SubmitButton
                    link={"/"}
                    iconComponent={null}
                    backgroundColor={colors.action100}
                    disabled={true}
                    color={colors.primary100}
                    label={"Dashboard"}
                />
            </Modal>
        );
    };

    render() {
        if (this.state.isLoading) {
            return (
                <Room>
                    <RoomContent>
                        <RoomContentContainer>
                            <LoadingCenter>
                                <LogoLoading />
                            </LoadingCenter>
                        </RoomContentContainer>
                    </RoomContent>
                </Room>
            );
        } else {
            return (
                <Room>
                    <RoomContent>
                        <RoomContentContainer>
                            <Logo>
                                <img src={images.logo} height="40" width="200" alt={""} />
                            </Logo>
                            {this.getModal()}
                        </RoomContentContainer>
                    </RoomContent>
                </Room>
            );
        }
    }
}
const mapStateToProps = (state) => ({
    rating: state.iframe.ratings.rating.get("rating"),
    ratingObject: state.iframe.ratings.rating,
    user: state.common.user.toJS(),
});
const mapDispatchToProps = (dispatch) => ({
    tryGetRatingToken: (token, isCoach) => dispatch(tryGetRatingToken(token, isCoach)),
    tryPostRatingOfCoach: (data) => dispatch(tryPostRatingOfCoach(data)),
    tryPostRatingOfConsumer: (data) => dispatch(tryPostRatingOfConsumer(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RatingRoom));
