import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

/* Components */
import { ActionBox, InlineItemLeft, InlineItemRight, EditIcon, DeactiveEditIcon } from "../components/clientsdetail";
import { FontTitle18 } from '../../common/components/fonts';
import { ReadOnlyValueInput, ReadOnlyPhoneInput } from '../../common/components/inputs';
import { EditOutlined } from '@ant-design/icons';
import { LogoLoadingSmall } from '../../common/components/loading';

/* Middleware */
import {tryGetOneContact} from "../middleware/contacts";
import {tryGetOneProspect} from "../middleware/prospects";
import {tryGetOneClient} from "../clients/middleware/clients";

class ContactInfo extends React.Component {
    state = {
        "isLoading": true,
        "contactInfo": {},
        "advisor": null
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.clientDetails !== this.props.clientDetails) {
            return this.init()
        }
    }

    init = async () => {
        const prospectStatus = this.props.clientsDetail.get("prospectStatus");
        const prospectId = this.props.clientsDetail.get("prospectId");
        const contactId = this.props.clientsDetail.get("contactId");
        await this.props.tryGetOneContact(contactId, "store");
        if(prospectStatus === "Prospect") {
            await this.props.tryGetOneProspect(prospectId, "store");
            return this.updateProspectOrClient("prospectsOne");
        } else if(prospectStatus === "Client") {
            await this.props.tryGetOneClient(prospectId, "store");
            return this.updateProspectOrClient("clientsOne");
        } else if(prospectStatus === "Contact") {
            return this.updateContact();
        }
    };

    updateProspectOrClient = async (store) => {
        await this.setState({
            "advisor": this.props[store].get("advisor"),
            "contactInfo": this.props.contactsOne,
            "isLoading": false
        });
    };

    updateContact = async () => {
        await this.setState({
            "advisor": null,
            "contactInfo": this.props.contactsOne,
            "isLoading": false
        });
    };

    edit = async () => {
        this.props.history.push("/contact/edit")
    };

    render() {
        if(this.state.isLoading) {
            return(
                <ActionBox>
                    <FontTitle18>Contact Info</FontTitle18>
                    <br />
                    <br />
                    <LogoLoadingSmall />
                    <br />
                </ActionBox>
            )
        } else {
            let canEdit = this.state.contactInfo.get("referralSource") === "willow" ? false : (this.props.isWMC ? true : (this.state.advisor === null ? true : (this.state.advisor.get("advisorId") === this.props.userId)));
            if(!(this.state.contactInfo.get("referralSource") === "willow" || (this.state.contactInfo.get("referralSource") === "wmc" && !this.props.isWMC))) {
                return(
                    <ActionBox>
                        <div>
                            <InlineItemLeft><FontTitle18>Contact Info</FontTitle18></InlineItemLeft>
                            <InlineItemRight>
                                {canEdit
                                    ? <EditIcon onClick={this.edit}><EditOutlined/></EditIcon>
                                    : <DeactiveEditIcon><EditOutlined/></DeactiveEditIcon>
                                }
                            </InlineItemRight>
                        </div>
                        <ReadOnlyValueInput title={"Email"} value={this.state.contactInfo.get("email")} />
                        <ReadOnlyPhoneInput title={"Mobile Phone"} value={this.state.contactInfo.get("phone")} />
                        <ReadOnlyValueInput title={"Time Zone"} value={this.state.contactInfo.get("timeZone").charAt(0).toUpperCase()+this.state.contactInfo.get("timeZone").slice(1)} />
                    </ActionBox>
                )
            } else {
                return(
                    <ActionBox>
                        <div>
                            <InlineItemLeft><FontTitle18>Contact Info</FontTitle18></InlineItemLeft>
                            <InlineItemRight><DeactiveEditIcon><EditOutlined /></DeactiveEditIcon></InlineItemRight>
                        </div>
                        <ReadOnlyValueInput title={"Email"} value={"Private"} />
                        <ReadOnlyValueInput title={"Mobile Phone"} value={"Private"} />
                        <ReadOnlyValueInput title={"Time Zone"} value={this.state.contactInfo.get("timeZone").charAt(0).toUpperCase()+this.state.contactInfo.get("timeZone").slice(1)} />
                    </ActionBox>
                )
            }
        }
    }
}

const mapStateToProps = state => ({
    contactsOne: state.enterprise.contacts.get("contactsOne"),
    clientsDetail: state.enterprise.clientsDetail.get("client"),
    prospectsOne: state.enterprise.prospects.get("prospectsOne"),
    clientsOne: state.enterprise.clientsList.get("clientsOne"),
    isWMC: state.common.wmc.get("isWMC"),
    userId: state.common.user.get("userId"),
});

const mapDispatchToProps = dispatch => ({
    tryGetOneContact: (contactId, storeOrReturn) => dispatch(tryGetOneContact(contactId, storeOrReturn)),
    tryGetOneProspect: (prospectId, storeOrReturn) => dispatch(tryGetOneProspect(prospectId, storeOrReturn)),
    tryGetOneClient: (prospectId, storeOrReturn) => dispatch(tryGetOneClient(prospectId, storeOrReturn)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactInfo));
