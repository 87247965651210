import React from 'react';
import validator from 'validator';

/* Components */
import {TextQuillInput} from "../../../common/components/inputs";

class MeetingsCreateDescription extends React.Component {
    state = {
        description: '',
        descriptionValid: true,
        descriptionCount: 0,
        descriptionComponent: ''
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        if(this.props.description.description.length !== 0) {
            await this.setState(this.props.description)
        }
        await this.updateDescription();
    };

    updateDescription = () => {
        this.setState({descriptionComponent: <TextQuillInput
                title={"Description*"}
                valid={this.state.descriptionValid}
                warning={"Required"}
                id={"description"}
                onChange={this.handleChangeDescription}
                placeholder={"Describe your meeting"}
                value={this.state.description}
                maxLength={3000}
                count={this.state.descriptionCount}
                minRows={8}
                required={true}
            />
        })
    };

    handleChangeDescription = async e => {
        await this.setState({
            'description': e,
            'descriptionValid': (!validator.isEmpty(e) && e !== '<p><br></p>' && e !== ''),
            'descriptionCount': (validator.isEmpty(e) || e === '<p><br></p>' || e === '') ? 0 : e.replace( /(<([^>]+)>)/ig, '').length,
        });
        await this.updateDescription();
        await this.props.onValidate(this.state);
    };

    render() {
        return(
            <>
                {this.state.descriptionComponent}
            </>
        )
    }
}

export default MeetingsCreateDescription;
