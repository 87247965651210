import React from 'react';
import { connect } from "react-redux";
import {Link, withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {FontBody16, fontFamily} from '../../../components/fonts'
import {colors} from '../../../components/colors'
import { images } from '../../../components/images';

/* Middleware */
import {tryGetSharedDriveList, tryCreateNewSharedDrive, tryGetSharedDriveFolderList, tryGetSharedDriveBetweenUser, tryDownloadFile, tryDeleteFile, tryDeleteFolder} from '../middleware/filevault';

/* Components */
import { Message } from "../../../components/messages";
import { TableWrapperV2 } from "../../../components/tablev2";
import { LogoLoading } from '../../../components/loading';

/* Containers */
import VaultNewFolder from './vaultnewfolder';
import VaultRename from './vaultrename';
import VaultDelete from './vaultdelete';
import VaultDeleteAll from './vaultdeleteall';
import VaultUpload from './vaultupload';
import VaultBreadcrumb from './vaultbreadcrumb';

/* Store */
import {setFolderOrFileSelected, setFolderOrFileRenameModal, setFolderOrFileDeleteModal, setFolderOrFileDeleteAllModal, setFolderOrFileUploadModal, setFolderNewModal, setFolderList} from '../store/filevault';
import {ButtonPrimary, ButtonInactiveLight} from "../../../components/buttons";
import {CloudUploadOutlined, PlusOutlined, DeleteOutlined, CloudDownloadOutlined, EditOutlined, LoginOutlined, ArrowLeftOutlined, ArrowUpOutlined} from "@ant-design/icons";

/* Domain */
import getDomainEnv from '../../../../domains/utils';
const env = getDomainEnv();

const Options = styled.div`
    ${props => props.alignLeft === false} {
        display: flex;
        justify-content: right;
    }
    margin-bottom: ${props => props.noBottomMargin ? "0px" : "30px"}
`;
const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    margin-top: 10px;
`;
const Btn = styled.div`
    width: fit-content;
`;
const Margin = styled.div`
    margin-bottom: 50px;
`;
const BackStyled = styled.div`
    margin-bottom: 10px;
    display: block;
    height: 22px;
    @media screen and (max-width: 1088px) {
        margin-bottom: 5px;
    }
`;
const BackButton = styled(Link)`
    cursor: pointer;
`;
const BackIcon = styled.div`
    display: inline-block;
    span {
        font-size: 14px;
        padding: 4px 0px;
        color: ${colors.primary30};
        display: inline-block;
        vertical-align: top;
        margin-right: 5px;
    }
`;
const BackLabel = styled.div`
    display: inline-block;
    vertical-align: top;
    color: ${colors.primary30};
`;

const IconButton = styled.div`
    background-image: url(${props => props.image});
    background-size: contain;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    cursor: pointer;
    align-self: center;
    justify-self: center;
`; 

const WhiteIcon = styled.img`
    width: 48px;
    height: 48px;
    cursor: pointer;
`;
const CircleOutline = styled.div`
    border: 2px solid ${colors.white};
    border-radius: 25px;
    color: ${colors.white};
    width: 40px;
    height: 40px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const RoundedBorder = styled.div`
    ${props => props.whiteBackground !== false} {
        background-color: ${colors.backgroundColor1};
        border-radius: 25px;
        padding: 10px;
    }
    & div {
        font-size: 18px;
        font-family: ${fontFamily.body};
    }
`;
const BreadcrumbFontSize = styled.div`
    ${props => props.bigFontSize !== true} {
        & div {
            font-size: 28px;
            font-family: ${fontFamily.title};
        }
    }
`;

class AllSharedVaultList extends React.Component {
    static defaultProps = {'showBackButton': true, 'showIconButtons': false, "showWhiteBackground": false, "consumer": false};

    constructor(props) {
        super(props);
        this.state = {"isLoading": true, "data": [], "folderId": null, "selected": [], "driveExists": false, "back": "/vaults"};
    }

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.folderListFetching && !this.props.folderListFetching || prevProps.folderList !== this.props.folderList) {
            this.update();
        }
    }

    componentWillUnmount() {
        this.props.setFolderList({});
    }

    init = async () => {
        if (!this.props.folderList.get('folderId')) {
            await this.props.tryGetSharedDriveFolderList(this.props.drive.get("driveId"), null, true);
        }
        await this.update();
    };

    update = async () => {
        this.setState({
            "data": await this.createData(this.props.folderList),
            "isLoading": false,
            "selected": []
        });
    };

    createData = async (data) => {
        let folders = await this.loopFileVault(data.get("folders"), "folder");
        let files = await this.loopFileVault(data.get("files"), "file");
        return folders.concat(files);
    };

    loopFileVault = async (list, type) => {
        return Promise.all(list.map(d => {
            return Promise.resolve({
                "isChecked": false,
                "id": (type + d.get("id")),
                "name": d.get("name"),
                "size": (d.get("size") === undefined || d.get("size") === null) ? null : parseInt(d.get("size")),
                "documentIcon": d.get("contentType"),
                "documentType": type,
                "actions": {
                    "1": {"label": <>{!this.props.showWhiteBackground && <><CloudDownloadOutlined />&nbsp;</>}Download</>, "active": type === "file", "show": type === "file"},
                    "2": {"label": <>{!this.props.showWhiteBackground && <><LoginOutlined />&nbsp;</>}Open</>, "active": type === "folder", "show": type === "folder"},
                    "3": {"label": <>{!this.props.showWhiteBackground && <><EditOutlined />&nbsp;</>}Rename</>, "active": true, "show": true},
                    "4": {"label": <>{!this.props.showWhiteBackground && <><DeleteOutlined />&nbsp;</>}Delete</>, "active": true, "show": true},
                },
                "team": (d.get("driveType") === "private_drive" || d.get("driveType") === "wmc_drive"),
                "allData": {...d.toJS(), "id": (type + d.get("id")), "documentType": type, "documentIcon": d.get("contentType")}
            })
        }));
    };

    /* DOCUMENT ACTIONS */

    isAnySelected = async (selected) => {
        this.setState({"selected": selected});
    };

    viewDoubleClick = async (data) => {
        if(data.documentType === "file") {
            this.download(data);
        } else {
            this.openFolder(data);
        }
    };

    openFolder = async (data) => {
        let id = parseInt(data.id.replace(data.documentType, ""));
        await this.props.tryGetSharedDriveFolderList(this.props.drive.get("driveId"), id, true);
        this.update();
    };

    rename = async (data) => {
        let id = parseInt(data.id.replace(data.documentType, ""));
        await this.props.setFolderOrFileSelected({...data, "id": id});
        this.props.setFolderOrFileRenameModal(true);
    };

    renameAll = async () => {
        this.state.selected.map(async s => {
            let data = await this.state.data.filter(d => {return(d.id === s)})[0];
            this.rename(data);
        });
    };

    download = async (data) => {
        let id = parseInt(data.id.replace(data.documentType, ""));
        // this.props.tryDownloadFile(id);
        this.tempDownload(id);
    };

    downloadAll = async () => {
        this.state.selected.map(async s => {
            let data = await this.state.data.filter(d => {return(d.id === s)})[0];
            let id = parseInt(data.id.replace(data.documentType, ""));
            // this.props.tryDownloadFile(id);
            this.tempDownload(id);
        });
    };

    tempDownload = (id) => {
        let tempLink = document.createElement("a");
        tempLink.href = "https://"+env.URL.API+"/filevault/file/download/"+id;
        tempLink.target = "_blank";
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
    };

    delete = async (data) => {
        let id = parseInt(data.id.replace(data.documentType, ""));
        await this.props.setFolderOrFileSelected({...data, "id": id});
        this.props.setFolderOrFileDeleteModal(true);
    };

    deleteAll = async () => {
        this.state.selected.map(async s => {
            let data = await this.state.data.filter(d => {return(d.id === s)})[0];
            let id = parseInt(data.id.replace(data.documentType, ""));
            if(data.documentType === "file") {
                await this.props.tryDeleteFile(id);
            } else {
                await this.props.tryDeleteFolder(id);
            }
        });
        await this.setState({"selected": [], "anyChecked": false})
    };

    thStyles = {"fontSize": "18px", "fontFamily": fontFamily.body};
    tdStyles = {"fontSize": "18px", "fontFamily": fontFamily.body};

    createColumns = {
        "0": {"key": "id", "title": "Id", "titleShowOnDesktop": false, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "hidden", "thStyles": this.thStyles, "tdStyles": this.tdStyles, "ifNull": "", "onClick": null, "onDoubleClick": null},
        "2": {"key": "isChecked", "title": "Select", "titleShowOnDesktop": false, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "checkbox", "thStyles": this.thStyles, "tdStyles": this.tdStyles, "ifNull": "", "onClick": this.isAnySelected, "onDoubleClick": null},
        "3": {"key": "name", "title": "Name", "titleShowOnDesktop": true, "canSort": true, "sort": "asc", "sortType": "string", "sortKey": "name", "component": "fileOrFolder", "thStyles": this.thStyles, "tdStyles": this.tdStyles, "ifNull": "", "onClick": this.isAnySelected, "onDoubleClick": this.viewDoubleClick},
        "4": {"key": "size", "title": "Size", "titleShowOnDesktop": true, "canSort": true, "sort": null, "sortType": "number", "sortKey": "size", "component": "fileSize", "thStyles": this.thStyles, "tdStyles": this.tdStyles, "ifNull": "", "onClick": null, "onDoubleClick": null},
        //"5": {"key": "users", "title": "Users", "titleShowOnDesktop": true, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "users", "thStyles": this.thStyles, "tdStyles": this.tdStyles, "ifNull": "", "onClick": null, "onDoubleClick": null},
        "6": {"key": "team", "title": "Team", "titleShowOnDesktop": false, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "team", "thStyles": this.thStyles, "tdStyles": this.tdStyles, "ifNull": "", "onClick": null, "onDoubleClick": null},
        "7": {"key": "actions", "title": "Actions", "titleShowOnDesktop": false, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "menu", "thStyles": this.thStyles, "tdStyles": this.tdStyles, "ifNull": "", "onClick": {"1": this.download, "2": this.openFolder, "3": this.rename, "4": this.delete}, "onDoubleClick": null},
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <>
                    {this.props.showBackButton && <BackStyled>
                        <BackButton to={this.state.back}>
                            <BackIcon><ArrowLeftOutlined/></BackIcon>
                            <BackLabel><FontBody16>Back</FontBody16></BackLabel>
                        </BackButton>
                    </BackStyled>}
                        <BreadcrumbFontSize bigFontSize={this.props.showWhiteBackground || this.props.consumer}>
                            <VaultBreadcrumb type={"shared"}/>
                        </BreadcrumbFontSize>
                        <Options alignLeft={true} noBottomMargin={this.props.consumer}>
                            <Inline>
                                <Btn onClick={() => {this.props.setFolderOrFileUploadModal(true)}}>
                                    {this.props.showIconButtons
                                        ? <>{this.props.showWhiteBackground || this.props.consumer
                                            ? <WhiteIcon src={images.firmUploadIcon} alt={""} />
                                            : <CircleOutline><ArrowUpOutlined/></CircleOutline>
                                        }</>
                                        : <ButtonPrimary canSubmit={false} label={<><CloudUploadOutlined /> Upload</>} />
                                    }
                                </Btn>
                            </Inline>
                            <Inline>
                                <Btn onClick={() => {this.props.setFolderNewModal(true)}}>
                                    {this.props.showIconButtons
                                        ? <>{this.props.showWhiteBackground || this.props.consumer
                                            ? <WhiteIcon src={images.firmAddIcon} alt={""} />
                                            : <CircleOutline><PlusOutlined/></CircleOutline>
                                        }</>
                                        : <ButtonInactiveLight canSubmit={false} label={<><PlusOutlined /> Folder</>} />
                                    }
                                </Btn>
                            </Inline>
                            {this.state.selected.length > 0 &&
                            <>
                                {(this.state.selected.length === 1 && this.state.selected[0].includes("file")) &&
                                    <Inline>
                                        <Btn onClick={this.downloadAll}>
                                            {this.props.showIconButtons
                                                ? <>{this.props.showWhiteBackground || this.props.consumer
                                                    ? <WhiteIcon src={images.firmDownloadIcon} alt={""} />
                                                    : <CircleOutline><CloudDownloadOutlined/></CircleOutline>
                                                }</>
                                                : <ButtonInactiveLight canSubmit={false} label={<><CloudDownloadOutlined /> Download</>} />
                                            }
                                        </Btn>
                                    </Inline>
                                }
                                {this.state.selected.length === 1 &&
                                    <Inline>
                                        <Btn onClick={this.renameAll}>
                                            {this.props.showIconButtons
                                                ? <>{this.props.showWhiteBackground || this.props.consumer
                                                    ? <WhiteIcon src={images.firmEditIcon} alt={""} />
                                                    : <CircleOutline><EditOutlined/></CircleOutline>
                                                }</>
                                                : <ButtonInactiveLight canSubmit={false} label={<><EditOutlined /> Rename</>} />
                                            }
                                        </Btn>
                                    </Inline>
                                }
                                <Inline>
                                    <Btn onClick={() => {this.props.setFolderOrFileDeleteAllModal(true)}}>
                                        {this.props.showIconButtons
                                            ? <>{this.props.showWhiteBackground || this.props.consumer
                                                ? <WhiteIcon src={images.firmDeleteIcon} alt={""} />
                                                : <CircleOutline><DeleteOutlined/></CircleOutline>
                                            }</>
                                            : <ButtonInactiveLight canSubmit={false} label={<><DeleteOutlined /> Delete</>} />
                                        }
                                    </Btn>
                                </Inline>
                            </>
                        }
                        </Options>
                        <RoundedBorder whiteBackground={this.props.showWhiteBackground}>
                            <TableWrapperV2
                                responsive={true}
                                tableColumns={this.createColumns}
                                tableData={this.state.data}
                                initSelected={null}
                                tableColor={this.props.showWhiteBackground ? colors.white : colors.backgroundColor1}
                                theadColor={this.props.showWhiteBackground ? colors.white : colors.backgroundColor1}
                                consumer={this.props.consumer}
                            />
                        </RoundedBorder>
                        <Margin />
                        {this.state.data.length === 0 && <Message text={"Folder is empty"} />}
                        <VaultRename />
                        <VaultNewFolder />
                        <VaultDelete />
                        <VaultDeleteAll deleteAll={this.deleteAll} />
                        <VaultUpload />

                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    drive: state.common.fileVault.get("sharedDrive"),
    folderList: state.common.fileVault.get("folderList"),
    folderListFetching: state.common.fileVault.get("sharedDriveFolderListFetching"),
    sharedDriveList: state.common.fileVault.get("sharedDriveList")
});

const mapDispatchToProps = dispatch => ({
    tryGetSharedDriveList: () => dispatch(tryGetSharedDriveList()),
    tryCreateNewSharedDrive: (name, users) => dispatch(tryCreateNewSharedDrive(name, users)),
    tryGetSharedDriveFolderList: (driveId, folderId, storeBreadcrumb) => dispatch(tryGetSharedDriveFolderList(driveId, folderId, storeBreadcrumb)),
    tryGetSharedDriveBetweenUser: (consumerId) => dispatch(tryGetSharedDriveBetweenUser(consumerId)),
    setFolderOrFileSelected: (status) => dispatch(setFolderOrFileSelected(status)),
    setFolderOrFileRenameModal: (selected) => dispatch(setFolderOrFileRenameModal(selected)),
    setFolderOrFileDeleteModal: (selected) => dispatch(setFolderOrFileDeleteModal(selected)),
    setFolderOrFileDeleteAllModal: (status) => dispatch(setFolderOrFileDeleteAllModal(status)),
    setFolderNewModal: (status) => dispatch(setFolderNewModal(status)),
    setFolderOrFileUploadModal: (status) => dispatch(setFolderOrFileUploadModal(status)),
    tryDownloadFile: (id) => dispatch(tryDownloadFile(id)),
    tryDeleteFile: (fileId) => dispatch(tryDeleteFile(fileId)),
    tryDeleteFolder: (folderId) => dispatch(tryDeleteFolder(folderId)),
    setFolderList: (folderList) => dispatch(setFolderList(folderList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AllSharedVaultList));