import React from 'react';
import {connect} from "react-redux";
import ReactQuill from "react-quill";
import styled from 'styled-components';

/* Components */
import {ReadOnlyInput} from "../../../common/components/inputs";
import {colors} from "../../../common/components/colors";
import {FontBody16} from "../../../common/components/fonts";
import {Quill} from "../../../common/components/richtexteditor";
import {CheckOutlined, CloseCircleOutlined} from "@ant-design/icons";
import {LogoLoading} from "../../../common/components/loading";

const Green = styled.div`
    color: ${colors.progress100};
    display: inline-block;
    vertical-align: top;
`;
const Red = styled.div`
    color: ${colors.alert100};
    display: inline-block;
    vertical-align: top;
`;
const Gray = styled.div`
    color: ${colors.secondary100};
    display: inline-block;
    vertical-align: top;
`;
const MarginBottomFix = styled.div`
    margin-bottom: -10px;
`;

class IntroductionDisplay extends React.Component {
    state={"isLoading": true, "title": "", "descriptionComponent": "", "hasDescription": false};

    componentDidMount() {
        return this.init();
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.profile.get("storeTitle") !== this.props.profile.get("storeTitle")) {
            return this.initTitle();
        }
        if(prevProps.profile.get("storeDescription") !== this.props.profile.get("storeDescription")) {
            return this.initDescription();
        }
    }

    init = async () => {
        await this.initTitle();
        await this.initDescription();
        this.setState({"isLoading": false});
    };

    initTitle = async () => {
        let title = this.props.profile.get("storeTitle");
        this.setState({
            "title": (title === null || title === undefined || title === "") ? null : title,
        })
    };

    initDescription = async () => {
        let description = this.props.profile.get("storeDescription");
        let descriptionAdj = (description === '<p><br></p>' || description === null || description === undefined || description === "") ? "" : description;
        await this.setState({"descriptionComponent": ""});
        this.setState({
            "hasDescription": !(description === '<p><br></p>' || description === null || description === undefined || description === ""),
            "descriptionComponent": <ReactQuill
                readOnly={true}
                theme={null}
                value={descriptionAdj}
                bounds={'.app'}
            />
        })
    };

    render() {
        if(this.state.isLoading) {
            return(<LogoLoading />)
        } else {
            return(
                <>
                    {this.state.title !== null
                        ?
                            <>
                                <ReadOnlyInput
                                    title={<><FontBody16><Green><CheckOutlined /></Green><Gray>&nbsp;Title</Gray></FontBody16></>}
                                    value={this.state.title}
                                />
                                <MarginBottomFix />
                            </>
                        :
                            <><br /><FontBody16><Red><CloseCircleOutlined /></Red><Gray>&nbsp;Title</Gray></FontBody16></>
                    }
                    {this.state.hasDescription
                        ?
                            <>
                                <br />
                                <FontBody16><Green><CheckOutlined /></Green><Gray>&nbsp;Description</Gray></FontBody16>
                                <Quill bodyBorderColor={colors.white}>
                                    {this.state.descriptionComponent}
                                </Quill>
                                <MarginBottomFix />
                            </>
                        :
                            <>
                                <br /><FontBody16><Red><CloseCircleOutlined /></Red><Gray>&nbsp;Description</Gray></FontBody16>
                                {this.state.title !== null &&
                                    <br />
                                }
                            </>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    profile: state.enterprise.services.expertProfile.get("expertProfile"),
});

export default connect(mapStateToProps, null)(IntroductionDisplay);
