// import {images} from '../../../../common/components/images';

// export const devLegalConfirmed = []

// export const devLegalConfirmedDate = {
//         "id": 4795,
//         "legalConfirmationDate": null,
//             //"2023-04-05T17:20:18.810Z" ,
//     }

// export const devRenewAfterLegalEnds =[
    // {
    //     "purchaseHistoryId":141,
    //     "amountPaid":100,
    //     "purchasedOn":"2023-04-01T17:20:18.810Z",
    //     "isCanceled":false,
    //     "canceledOn":null,
    //     "isSubscription":false,
    //     "subcriptionInterval":null,
    //     "subscriptionDuration":null,
    //     "isActive":false,
    //     "willowPlanId":"certification_session_cert400",
    //     "isOnTheFlyInvoice":false
    // },
    // {
    //     "purchaseHistoryId":142,
    //     "amountPaid":100,
    //     "purchasedOn":"2024-09-06T22:06:08Z",
    //     "isCanceled":false,
    //     "canceledOn":null,
    //     "isSubscription":false,
    //     "subcriptionInterval":null,
    //     "subscriptionDuration":null,
    //     "isActive":false,
    //     "willowPlanId":"certification_session_renewal",
    //     "isOnTheFlyInvoice":false
    // },
// ]

// export const devRenewBeforeLegalEnds = [
//     // {
//     //     "purchaseHistoryId":141,
//     //     "amountPaid":100,
//     //     "purchasedOn": "2022-04-01T17:20:18.810Z",
//     //     "isCanceled":false,
//     //     "canceledOn":null,
//     //     "isSubscription":false,
//     //     "subcriptionInterval":null,
//     //     "subscriptionDuration":null,
//     //     "isActive":false,
//     //     "willowPlanId":"certification_session_cert400",
//     //     "isOnTheFlyInvoice":false
//     // },
//     // {
//     //     "purchaseHistoryId":142,
//     //     "amountPaid":100,
//     //     "purchasedOn":"2024-09-06T22:06:08Z",
//     //     "isCanceled":false,
//     //     "canceledOn":null,
//     //     "isSubscription":false,
//     //     "subcriptionInterval":null,
//     //     "subscriptionDuration":null,
//     //     "isActive":false,
//     //     "willowPlanId":"certification_session_renewal",
//     //     "isOnTheFlyInvoice":false
//     // },
// ]


// export const devEnterpriseBillingHistoryData = [
//         {
//             "purchaseHistoryId":141,
//             "amountPaid":100,
//             "purchasedOn":"2022-09-05T17:20:18.810Z",
//             "isCanceled":false,
//             "canceledOn":null,
//             "isSubscription":false,
//             "subcriptionInterval":null,
//             "subscriptionDuration":null,
//             "isActive":false,
//             "willowPlanId":"certification_session_cert400",
//             "isOnTheFlyInvoice":false
//         },
//         {
//             "purchaseHistoryId":142,
//             "amountPaid":100,
//             "purchasedOn":"2022-09-06T22:06:08Z",
//             "isCanceled":false,
//             "canceledOn":null,
//             "isSubscription":false,
//             "subcriptionInterval":null,
//             "subscriptionDuration":null,
//             "isActive":false,
//             "willowPlanId":"certification_session_renewal",
//             "isOnTheFlyInvoice":false
//         },
//         {
//             "purchaseHistoryId":145,
//             "amountPaid":100,
//             "purchasedOn":"2020-10-04T23:54:03Z",
//             "isCanceled":false,
//             "canceledOn":null,
//             "isSubscription":false,
//             "subcriptionInterval":null,
//             "subscriptionDuration":null,
//             "isActive":false,
//             "willowPlanId":"practice_management_session",
//             "isOnTheFlyInvoice":false
//         },
//         {
//             "purchaseHistoryId":146,
//             "amountPaid":100,
//             "purchasedOn":"2020-10-05T00:05:51Z",
//             "isCanceled":false,
//             "canceledOn":null,
//             "isSubscription":false,
//             "subcriptionInterval":null,
//             "subscriptionDuration":null,
//             "isActive":false,
//             "willowPlanId":"practice_management_session",
//             "isOnTheFlyInvoice":false
//         },
//         {
//             "purchaseHistoryId":147,
//             "amountPaid":100,
//             "purchasedOn":"2020-10-05T00:06:13Z",
//             "isCanceled":false,
//             "canceledOn":null,
//             "isSubscription":false,
//             "subcriptionInterval":null,
//             "subscriptionDuration":null,
//             "isActive":false,
//             "willowPlanId":"advisor_session",
//             "isOnTheFlyInvoice":false
//         },
//         {
//             "purchaseHistoryId":148,
//             "amountPaid":200,
//             "purchasedOn":"2020-10-05T14:38:41Z",
//             "isCanceled":false,
//             "canceledOn":null,
//             "isSubscription":false,
//             "subcriptionInterval":null,
//             "subscriptionDuration":null,
//             "isActive":false,
//             "willowPlanId":"advisor_session",
//             "isOnTheFlyInvoice":false
//         },
//         {
//             "purchaseHistoryId":149,
//             "amountPaid":100,
//             "purchasedOn":"2020-10-05T14:39:03Z",
//             "isCanceled":false,
//             "canceledOn":null,
//             "isSubscription":false,
//             "subcriptionInterval":null,
//             "subscriptionDuration":null,
//             "isActive":false,
//             "willowPlanId":"practice_management_session",
//             "isOnTheFlyInvoice":false
//         }
//     ]
// ;
//
//
// export const devEnterpriseUserData =
//     {
//         "email": "ise@trustwillow.com",
//         "phone": null,
//         "first": "ise",
//         "last": "Okhiria",
//         "lastName": "Okhiria",
//         "businessTitle": null,
//         "isReferredByPartner": false,
//         "subscription": false,
//         "platform": "consumer",
//         "userId": 4632,
//         "uid": "f02db5db-40de-45e7-80ae-a54da2a826e4",
//         "newUser": true,
//         "timeZone": "eastern",
//         "isExclusive": false,
//         "wmcEmployeeId": null,
//         "selectedCategoryHistory": [],
//         "pms_sessions": 18,
//         "willow_sessions": 20,
//         "photo": "https://res.cloudinary.com/blz-lilyriche/image/upload/yb3esjcmdyfstf4udqpv.jpg",
//         "onboardingCompleted": true,
//         "isCoach": true,
//         "benefitsProviderId": null,
//         "expertCategories": [{"categoryId": 7, "categoryName": "Willow Support"}],
//         "wmc": {
//             "id": 80,
//             "companyName": "trustwillow.com",
//             "companyLogoUrl": "",
//             "welcomeAudioMessageForCallUrl": "",
//             "phone": "",
//             "email": "",
//             "address1": "",
//             "address2": "",
//             "city": "",
//             "state": null,
//             "zipCode": "",
//             "facebookUrl": "",
//             "twitterUrl": "",
//             "linkedinUrl": "",
//             "isErg": false,
//             "hrCode": "",
//             "defaultNumberOfSessions": 0,
//             "defaultNumberOfAdvisorSessions": 0,
//             "defaultNumberOfPracticeManagementSessions": 0,
//             "isReferredByPartner": false,
//             "loadingImage": null,
//             "actionColor": "",
//             "contactPersonFirstName": "",
//             "contactPersonLastName": "",
//             "contactPersonWorkPhone": "",
//             "contactPersonCellPhone": "",
//             "contactPersonEmail": ""
//         },
//         "companyName": "trustwillow.com",
//         "isErg": false,
//         "isLily": true,
//         "referralType": "coach",
//         "ergId": null,
//         "prospectWmcId": null,
//         "prospectId": 1470
//     }
//
// export const devEnterpriseBillingPaymentMethodData = {
//     "object": "list",
//     "data": [
//         {
//             "id": "pm_1IyTKnBPDoWM9BMeNhpU7YHr",
//             "object": "payment_method",
//             "billing_details": {
//                 "address": {
//                     "city": null,
//                     "country": null,
//                     "line1": null,
//                     "line2": null,
//                     "postal_code": "02129",
//                     "state": null
//                 },
//                 "email": "jason+expert@trustwillow.com",
//                 "name": "Jason Expert 44",
//                 "phone": "1135193519"
//             },
//             "card": {
//                 "brand": "amex",
//                 "checks": {
//                     "address_line1_check": null,
//                     "address_postal_code_check": "unavailable",
//                     "cvc_check": "unavailable"
//                 },
//                 "country": "US",
//                 "exp_month": 11,
//                 "exp_year": 2024,
//                 "fingerprint": "O4uyp1NZ4iHbrnSS",
//                 "funding": "credit",
//                 "generated_from": null,
//                 "last4": "1011",
//                 "networks": {
//                     "available": [
//                         "amex"
//                     ],
//                     "preferred": null
//                 },
//                 "three_d_secure_usage": {
//                     "supported": true
//                 },
//                 "wallet": null
//             },
//             "created": 1622775874,
//             "customer": "cus_JCUvZZWYsbDHjD",
//             "livemode": true,
//             "metadata": {},
//             "type": "card"
//         },
//         {
//             "id": "pm_1IyTFrBPDoWM9BMeHfKjTfN4",
//             "object": "payment_method",
//             "billing_details": {
//                 "address": {
//                     "city": null,
//                     "country": null,
//                     "line1": null,
//                     "line2": null,
//                     "postal_code": "02129",
//                     "state": null
//                 },
//                 "email": "jason+expert@trustwillow.com",
//                 "name": "Jason Aronson",
//                 "phone": "1135193519"
//             },
//             "card": {
//                 "brand": "amex",
//                 "checks": {
//                     "address_line1_check": null,
//                     "address_postal_code_check": "unchecked",
//                     "cvc_check": "unchecked"
//                 },
//                 "country": "US",
//                 "exp_month": 11,
//                 "exp_year": 2024,
//                 "fingerprint": "O4uyp1NZ4iHbrnSS",
//                 "funding": "credit",
//                 "generated_from": null,
//                 "last4": "1011",
//                 "networks": {
//                     "available": [
//                         "amex"
//                     ],
//                     "preferred": null
//                 },
//                 "three_d_secure_usage": {
//                     "supported": true
//                 },
//                 "wallet": null
//             },
//             "created": 1622775567,
//             "customer": "cus_JCUvZZWYsbDHjD",
//             "livemode": true,
//             "metadata": {},
//             "type": "card"
//         }
//     ],
//     "has_more": false,
//     "url": "/v1/payment_methods"
// };

export const profileDetailsData = {
    "id": 4795, // 0
    "firstName": "ise+test9", // ""
    "lastName": "o", // ""
    "photos": ["https://res.cloudinary.com/blz-lilyriche/image/upload/v1681740307/qedjzptdlhsipdviaaw7.png"], // []
    "intro": {"video": "link", "description": "bio",
        "animal" :"Dog",
        "hobby": "Movies/TV",
        "birthOrder": "Oldest",
        "zodiac": "Capricorn",
        "mbti": "ISTJ",}, // {video: "", description: ""}
    "expertise": [], // []
    "specialities": [], // []
    "services": [], // []
    "disclosure": "disclousre", // null
    "logos": ["https://res.cloudinary.com/blz-lilyriche/image/upload/v1681740308/e1octzlxn4o65msy8mdw.png"], // []
    "firmSec": "333", // null
    "advisorSec": "333", // null
    "firmName": "name", // null
    "firmBio": "firm", // null
    "firmLogo": "link",// null
    "firmAddress": {"address": "test"},// {},
    "clientInfo": ""

}

// export const devLegalConfirmData = {
//     "id": 4795,
//     "legalConfirmationDate": null
// }
