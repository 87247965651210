import React, {useState, useEffect, useMemo} from 'react';
import {useDispatch} from 'react-redux'
import styled from 'styled-components';
import {v4 as uuid4} from 'uuid';

/* Components */
import {colors} from "../../../common/components/colors";
import {LogoLoading} from "../../../common/components/loading";

/* Containers */
import ContactPage1 from './contactpage1';
// import ContactPage2 from "./contactpage2";
import ContactPage3 from "./contactpage3";
import ContactPage4 from "./contactpage4";
import ContactPage5 from "./contactpage5";
import ContactSuccess from "./contactsuccess";

/* Middleware */
import {tryPostCreateSessionAppointment} from '../../scheduler/middleware/scheduler';
import {
    trackMarketingStepZeroStart,
    trackMarketingStepOneCalendar,
    trackMarketingStepTwoJourney,
    trackMarketingStepThreeAssets,
    trackMergeAnonIdAndEmail,
    trackScheduleMarketingCall,
} from "../../../common/utils/tracking";

const Section = styled.div`
  background-color: ${colors.canvaDarkBlue};
  width: calc(100% - 180px);
  padding: 90px;
  height: calc(100% - 180px);
  overflow: auto;
  @media screen and (max-width: 1680px) {
    width: calc(100% - 120px);
      padding: 60px;
      height: calc(100% - 120px);
      gap: 20px;
  }
  @media screen and (max-width: 720px) {
    width: calc(100% - 60px);
      padding: 30px;
      height: calc(100% - 60px);
      gap: 10px;
  }
  @media screen and (max-width: 650px) {
      width: calc(100% - 40px);
      padding: 20px;
      height: calc(100% - 40px);
      gap: 10px;
  }
  @media screen and (max-width: 450px) {
      width: calc(100% - 20px);
      padding: 20px 10px;
      height: calc(100% - 40px);
      gap: 10px;
  }
`;
const LoadingCenter = styled.div`
    margin: 60px auto;
`;

Contact.defaultProps = {
    advisorId: null,
    firstName: "",
    utm: {
        "utm_source": null,
        "utm_medium": null,
        "utm_campaign": null,
        "utm_content": null,
        "utm_term": null,
        "isLoading": true
    }
}

export default function Contact(props) {
    const advisorId = props.advisorId;
    const utm = props.utm;
    const anonId = useMemo(() => uuid4(), []);
    const [step, setStep] = useState(1);
    const [maxStepReached, setMaxStepReached] = useState(1);
    const [datetime, setDatetime] = useState(null);
    const [journeys, setJourneys] = useState([]);
    const [certifications, setCertifications] = useState([]);
    const [journeyDetails, setJourneyDetails] = useState("");
    const [assets, setAssets] = useState("$500,000 - $1,000,000");
    const dispatch = useDispatch();
    const name = props.firstName;
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if(anonId !== null && anonId !== undefined && anonId !== "") {
            dispatch(trackMarketingStepZeroStart(anonId, utm));
        }
    }, [anonId])

    useEffect(() => {
        if(maxStepReached === 2)  {
            dispatch(trackMarketingStepOneCalendar(anonId, {...utm, "datetime": datetime}));
        } else if(maxStepReached === 3) {
            dispatch(trackMarketingStepTwoJourney(anonId, {...utm, "journey": journeyDetails}));
        } else if(maxStepReached === 4) {
            dispatch(trackMarketingStepThreeAssets(anonId, {...utm, "assets": assets}));
        }
    }, [maxStepReached])

    const updateStep = async (action) => {
        const newStep = step + action;
        setStep(newStep);
        if(newStep > maxStepReached) {
            setMaxStepReached(newStep);
        }
    }

    const updateDateTime = (datetime) => {
        setDatetime(datetime)
        return updateStep(1);
    }

    const onSubmit = async () => {
        setLoading(true);
        const advisorRequest = {
            // "certifications": certifications,
            // "journeys": journeys,
            "journeyDetails": journeyDetails,
            "assets": assets
        };
        console.log(advisorRequest);
        await dispatch(tryPostCreateSessionAppointment(datetime, 15, advisorId, null, false, "consumerSeekingAdvisorSession", null, advisorRequest))
        await dispatch(trackMergeAnonIdAndEmail(anonId));
        await dispatch(trackScheduleMarketingCall(utm));
        await updateStep(1);
        setLoading(false);
    }

    if(isLoading && step !== 5) {
        return(<LoadingCenter><LogoLoading /></LoadingCenter>);
    } else {
        return (
            <Section>
                <ContactPage1 show={step === 1} advisorId={advisorId} setDatetime={updateDateTime} />
                {/*<ContactPage2 show={step === 2} setStep={updateStep} setJourneys={setCertifications}/>*/}
                <ContactPage3 show={step === 2} setStep={updateStep} setJourneyDetails={setJourneyDetails}/>
                <ContactPage4 show={step === 3} setStep={updateStep} setAssets={setAssets}/>
                <ContactPage5 show={step === 4} setStep={updateStep} onSubmit={onSubmit}/>
                <ContactSuccess show={step === 5} advisorName={name}/>
            </Section>
        );
    }

}

