import React, { useState} from "react";
import {FontBody14, FontTitle32To27} from "../../common/components/fonts";
import {ButtonPrimary, ButtonTertiary} from "../../common/components/buttons";
import {useDispatch, useSelector} from "react-redux";
import {editorFormats, editorModules} from "../../common/components/richtexteditor";
import ReactQuill from "react-quill";
import {v4 as uuid4} from "uuid";
import {tryPostEnterpriseNote, tryUpdateEnterpriseNote} from "../notes/middleware/notes";
import {
    Close,
    CloseButton,
    CloseMessage,
    Compose,
    To,
} from "../sms/components/smscompose";
import {Body} from "../shop/components/faqs";
import {CloseOutlined, LineOutlined} from "@ant-design/icons";
import {setNoteModalMinimized} from "../notes/store/notes";
import styled from "styled-components";
import {colors} from "../../common/components/colors";
import {BtnContainer, BtnDiv} from "./instructionsflow";

const Container = styled.div`
  text-align: center;
  ${FontTitle32To27} {
    margin-top:5vh;
  }
  display:flex;
  flex-direction: column;
`;
const ComposeWrapperFullScreen = styled.div`
    border-radius: 6px 6px 0px 0px;
    z-index: 15;
    width: 75%;
    height: 100%;
    margin: 5vh auto;
    -webkit-box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
  .ql-editor{
    align-self: center;
    color:black;
    height: 60vh;

  }
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 460px) {
    margin: 0 auto;
  }
    box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    @media screen and (max-width: 1088px) {
        right: 60px;
    }
    @media screen and (max-width: 768px) {
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: ${colors.white};
        width: 100%;
        max-width: 100%;
    }
`;
export default function InstructionsPageFour({submit, handleNext, handleBack, page}) {
    const dispatch = useDispatch();
    const clientID = useSelector(state => state.enterprise.clientsDetail.get("clientId") || state.enterprise.clientsDetail.get("client").get("prospectId"));
    const clientName = useSelector(state => state.enterprise.clientsDetail.get("clientName"));
    const isWMC = useSelector(state => state.common.wmc.get("isWMC"));
    const [noteData, setNoteData] = useState({noteBody: '', noteTitle: 'Advertising Call', noteId: null});
    const clientNotesEdit = useSelector((state => state.enterprise.notes.notes.get("noteEdit")));
    const handleChangeBody = (e)  => setNoteData({...noteData, noteBody: e.target.value});

    const [quill, setQuill] = useState(<ReactQuill
        id={uuid4()}
        theme={"snow"}
        onChange={handleChangeBody}
        value={noteData.noteBody}
        modules={editorModules}
        formats={editorFormats}
        bounds={'.app'}
        placeholder={"Write your note here"}
    />);
    const noteModalMinimized = false;//useSelector(state => state.enterprise.notes.notes.get("noteModalMinimized"));

    const handleSubmit = async e => {
        e.preventDefault();
        if(noteData.noteBody !== '<p><br></p>' && noteData.noteBody !== '') {
            if(clientNotesEdit) {
                dispatch(tryUpdateEnterpriseNote(clientID, noteData.noteId, noteData.noteTitle,  noteData.noteBody, false, true, {}));
                await setNoteData({noteBody: '', noteTitle: '', noteId: null});
                handleNewQuill();
            } else {
                dispatch(tryPostEnterpriseNote(clientID, noteData.noteTitle, noteData.noteBody, false, true, {}));
                await setNoteData({noteBody: '', noteTitle: '', noteId: null});
                handleNewQuill();
            }
        }
    };

    const handleSaveEditNote = async () => {
        handleNext()
        dispatch(tryUpdateEnterpriseNote(clientID, noteData.noteId, noteData.noteTitle,  noteData.noteBody, false, true, {}));
        submit()
    };

    const handleSaveNewNote = async () => {
        handleNext()
        dispatch(tryPostEnterpriseNote(clientID, noteData.noteTitle, noteData.noteBody, false, true, {}));
        submit()
    };



    const handleNewQuill = () => {
        setQuill( <ReactQuill
                id={uuid4()}
                theme={"snow"}
                onChange={handleChangeBody}
                value={noteData.noteBody}
                modules={editorModules}
                formats={editorFormats}
                bounds={'.app'}
                placeholder={"Write your note here"}
            />);
    };
    const closeCheck = () => {
        if (noteData.noteTitle === '') {
            this.close()
        } else {
            this.setState({ "confirmModal": true })
        }

    }
    return (
        <Container>
            <FontTitle32To27>Notes</FontTitle32To27>

            <ComposeWrapperFullScreen minimized={noteModalMinimized}>
                <Close>
                    <CloseMessage onClick={() => {
                        dispatch(setNoteModalMinimized(!noteModalMinimized))
                    }}><FontBody14>New Note</FontBody14></CloseMessage>
                    <CloseButton onClick={() => {
                        dispatch(setNoteModalMinimized(!noteModalMinimized))
                    }}><LineOutlined /></CloseButton>
                    <CloseButton onClick={() => {
                        closeCheck()
                    }}><CloseOutlined /></CloseButton>
                </Close>
                {!noteModalMinimized &&
                    <Compose>
                        <To>Advertising Call</To>
                        <Body>{quill}</Body>
                    </Compose>
                }
            </ComposeWrapperFullScreen>
            <BtnContainer>
                <BtnDiv className={'back'} onClick={handleBack}>{page > 1 && page < 5 && <ButtonTertiary label={"Back"} canSubmit={false}/>}</BtnDiv>

                <BtnDiv className={'next'}
                    onClick={clientNotesEdit ? handleSaveEditNote : handleSaveNewNote}><ButtonPrimary
                    label={"Save"} canSubmit={false}/></BtnDiv>
            </BtnContainer>
            </Container>
    );
}