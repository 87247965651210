import { combineReducers } from 'redux';

/* Common */
import ratingReducer from './store/rating';

const ratingsReducer = combineReducers({
    rating: ratingReducer,
});

export default ratingsReducer;
