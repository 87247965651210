import React from "react";
import {FontTitle32To27, FontTitle38, FontTitle40} from "../../common/components/fonts";
import styled from "styled-components";

const Container = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  ${FontTitle40} {
    position:absolute;
    top:50%;
    left:45%;
  }
`;
export default function InstructionsPageFive() {
    return (
        <Container>
            <FontTitle40>Success!</FontTitle40>
        </Container>
    );
}