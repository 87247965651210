import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {Helmet} from "react-helmet";

/* Components */
import {FontTitle30} from '../../common/components/fonts';
import {colors} from '../../common/components/colors';
import {images} from '../../common/components/images';
import {LogoLoading} from "../../common/components/loading";

/* Containers */
import CheckoutFlow from './containers/flow';

/* Middleware */
import {tryGetProductFromPlanId} from './middleware/checkout';

/* Store */
import {setSignUpIsExclusive} from "../../routes/store/user";

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

const MessageCenter = styled.div`
    margin: 140px auto;
    text-align: center;
`;

const Background = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: ${colors.background100};
`;

class IFrameCheckout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {"isLoading": true, "planIdFound": false, "plan": null}
    }

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        let path = window.location.pathname;
        let pathAdj = path.charAt(path.length-1) === "/" ? path.slice(0, -1) : path;
        let pathSplit = pathAdj.split("/");
        if(pathSplit.length > 4) {
            return this.setState({"planIdFound": false, "plan": null, "isLoading": false});
        } else {
            let planId = pathSplit.length === 4 ? pathSplit[pathSplit.length - 1] : null;
            let quantity = null; // Placeholder for when quantity is added
            let product = await this.props.tryGetProductFromPlanId(planId, quantity);
            if(!product.hasOwnProperty("error")) {
                await this.props.setSignUpIsExclusive();
                return this.setState({"planIdFound": true, "plan": this.props.plan, "isLoading": false});
            } else {
                return this.setState({"planIdFound": false, "plan": null, "isLoading": false});
            }
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<Background><LoadingCenter><LogoLoading /></LoadingCenter></Background>)
        } else {
            if(this.state.planIdFound) {
                return(
                    <>
                        <Helmet>
                            <link rel={"icon"} type={"image/png"} href={images.favicon16px} sizes={"16x16"} />
                            <title>Checkout&nbsp;|&nbsp;Willow</title>
                        </Helmet>
                        <CheckoutFlow />
                    </>
                )
            } else {
                return(<Background><MessageCenter><FontTitle30>No checkout found</FontTitle30></MessageCenter></Background>)
            }
        }
    }

}

const mapStateToProps = state => ({
    plan: state.iframe.checkout.checkout.get("checkoutProduct"),
});

const mapDispatchToProps = dispatch => ({
    tryGetProductFromPlanId: (planId, quantity) => dispatch(tryGetProductFromPlanId(planId, quantity)),
    setSignUpIsExclusive: () => dispatch(setSignUpIsExclusive())
});

export default connect(mapStateToProps, mapDispatchToProps)(IFrameCheckout);