import React from 'react';
import styled from 'styled-components';

import { colors } from '../../../common/components/colors';
import { FontSubtitle38To30, FontSubtitle21, FontHeader16 } from '../../../common/components/fonts';
import {ButtonPrimary} from '../../../common/components/buttons';
import {CalendarOutlined} from "@ant-design/icons";


const NavTop = styled.div`
    background-color: ${colors.white};
    width: calc(100% - 60px);
    padding: 30px 0px 30px 60px;
    border-bottom: 1px solid ${colors.border100};
    @media screen and (max-width: 1088px) {
        width: calc(100% - 40px);
        padding: 23px 20px 23px 20px;
    }
    @media screen and (max-width: 748px) {
        display: none;
    }
`;
const NavTopMobile = styled.div`
    display: none;
    @media screen and (max-width: 748px) {
        display: block;
        background-color: ${colors.white};
        padding: 16px 20px 16px 20px;
        border-bottom: 1px solid ${colors.border100};
    }
`;
const Right = styled.div`
    width: calc(100% - 60px);
    display: inline-block;
    text-align: right;
    vertical-align: top;
    @media screen and (max-width: 640px) {
        display: block;
        text-align: left;
        padding: 10px 0px;
        width: 100%;
    }
`;
const Inline = styled.div`
    display: inline-block;
    margin-top: 3.5px;
    margin-left: 10px;
    @media screen and (max-width: 640px) {
        margin: 10px 0px 0px 0px;
        width: 100%;
    }
`;
const InlineBtn = styled.div`
    width: fit-content;
`;
const White = styled.div`
    color: ${colors.white};
`;

export const NavQuaternaryWrapper = ({ data }) => {
    const navItem = data.nav.filter(d => {return(location.pathname.includes(d.rootHref))})[0];
    return(
        <>
            <NavTop><FontSubtitle38To30>{navItem.navTopTitle}</FontSubtitle38To30></NavTop>
            <NavTopMobile><FontSubtitle21>{navItem.navTopTitle}</FontSubtitle21></NavTopMobile>
        </>
    );
};
