
// ---------------------------------------------------------------------------------------------------------------------
// PRODUCTS - GENERAL (START)
// ---------------------------------------------------------------------------------------------------------------------

const checkSessionsCompleted = async (sessions) => {
    const countCompleted = await Promise.all(sessions.map(s => {
        if(s.isWillowSession && !s.isCanceled && !s.isMissed && !s.isOngoing/* && !s.meetingIsTrial turned off as advisors do not have trial calls*/) {
            return(1);
        } else {
            return(0);
        }
    }));
    return (countCompleted.reduce((a, b) => a + b, 0));
};

const checkSessionsUpcoming = async (sessions) => {
    const countUpcoming = await Promise.all(sessions.map(s => {
        if(s.isWillowSession) {
            return(1);
        } else {
            return(0);
        }
    }));
    return (countUpcoming.reduce((a, b) => a + b, 0));
};

export const checkSessions = async (history, sessionsPrePurchased) => {
    const sessionsUpcoming = await checkSessionsUpcoming(history.upcomingCalls);
    const sessionsComplete = await checkSessionsCompleted(history.pastCalls);
    return({
        "sessionsCompleted": sessionsComplete,
        "sessionsUpcoming": sessionsUpcoming,
        "sessionsRemaining": sessionsPrePurchased + sessionsUpcoming,
        "sessionsSchedulable": sessionsPrePurchased,
    });
};

// ---------------------------------------------------------------------------------------------------------------------
// PRODUCTS - GENERAL (END)
// ---------------------------------------------------------------------------------------------------------------------
