import React from 'react';

import {
    SettingOutlined,
    ClockCircleOutlined,
    TagsOutlined,
    MessageOutlined,
    NotificationOutlined,
    LogoutOutlined,
    CalendarOutlined,
    FunnelPlotOutlined,
    UserOutlined,
    ShopOutlined,
    FolderAddOutlined,
    BulbOutlined
} from "@ant-design/icons";

const enterpriseNav = {
    logo: "/verify",
    main: [
        [
            {
                label: <><FunnelPlotOutlined />&nbsp;Prospects</>,
                href: "/prospects",
                onlyMobile: false,
                showWMC: true,
                showCoach: true,
                showCoachCategoryIds: "all"
            },
            {
                label: <><UserOutlined />&nbsp;Clients</>,
                href: "/clients",
                onlyMobile: false,
                showWMC: true,
                showCoach: true,
                showCoachCategoryIds: "all" // Don't show to support categories once created
            },
        ],
        [
            {
                label: <><CalendarOutlined />&nbsp;Meetings</>,
                href: "/meetings",
                onlyMobile: false,
                showWMC: true,
                showCoach: true,
                showCoachCategoryIds: "all"
            },
            {
                label: <><ClockCircleOutlined />&nbsp;Availability</>,
                href: "/availability",
                onlyMobile: false,
                showWMC: true,
                showCoach: true,
                showCoachCategoryIds: "all"
            },
        ],
        [
            {
                label: <><TagsOutlined />&nbsp;Products</>,
                href: "/products",
                onlyMobile: false,
                showWMC: false,
                showCoach: true,
                showCoachCategoryIds: "all" // Don't show to support categories once created
            },
            {
                label: <><ShopOutlined />&nbsp;Shop</>,
                href: "/shop",
                onlyMobile: false,
                showWMC: false,
                showCoach: true,
                showCoachCategoryIds: "all" // Don't show to support categories once created
            },
        ],
        [
            // {
            //     label: <><BulbOutlined />&nbsp;Curriculum</>,
            //     href: "/curriculum",
            //     onlyMobile: false,
            //     showWMC: false,
            //     showCoach: true,
            //     showCoachCategoryIds: "all"
            // },
            {
                label: <><FolderAddOutlined />&nbsp;Vault</>,
                href: "/vaults",
                onlyMobile: false,
                showWMC: true,
                showCoach: true,
                showCoachCategoryIds: "all"
            },
        ],
        [
            {
                label: <><SettingOutlined />&nbsp;Settings</>,
                href: "/settings/basic-info",
                onlyMobile: true,
                showWMC: true,
                showCoach: true,
                showCoachCategoryIds: "all"
            },
            // {
            //     label: <><ContainerOutlined />&nbsp;Billing</>,
            //     href: "/billing",
            //     onlyMobile: true,
            //     showWMC: true,
            //     showCoach: true,
            //     showCoachCategoryIds: "all"
            // },
            {
                label: <><MessageOutlined />&nbsp;Support</>,
                href: "modalSupport",
                onlyMobile: true,
                showWMC: true,
                showCoach: true,
                showCoachCategoryIds: "all"
            },
            {
                label: <><NotificationOutlined />&nbsp;Feedback</>,
                href: "modalFeedback",
                onlyMobile: true,
                showWMC: true,
                showCoach: true,
                showCoachCategoryIds: "all"
            },
            {
                label: <><LogoutOutlined />&nbsp;Log out</>,
                href: "/logout",
                onlyMobile: true,
                showWMC: true,
                showCoach: true,
                showCoachCategoryIds: "all"
            },
        ]
    ],
    settings: [
        {
            title: null,
            options: [
                {
                    label: <div style={{"fontSize": "16px", "fontWeight": "bold"}}>{"<- Home"}</div>,
                    href: "/verify",
                    onlyMobile: true,
                    showWMC: true,
                    showCoach: true,
                    showCoachCategoryIds: "all"
                },
            ],
            onlyMobile: true,
            showWMC: true,
            showCoach: true,
            showCoachCategoryIds: "all"
        },
        {
            title: "YOUR PREFERENCES",
            options: [
                {
                    label: "Basic info",
                    href: "/settings/basic-info",
                    onlyMobile: false,
                    showWMC: true,
                    showCoach: true,
                    showCoachCategoryIds: "all"
                },
                {
                    label: "Security",
                    href: "/settings/security",
                    onlyMobile: false,
                    showWMC: true,
                    showCoach: true,
                    showCoachCategoryIds: "all"
                },
            ],
            onlyMobile: false,
            showWMC: true,
            showCoach: true,
            showCoachCategoryIds: "all"
        },
        // {
        //     title: "ACCOUNT",
        //     options: [
        //         {
        //             label: "Billing",
        //             href: "/settings/billing",
        //             onlyMobile: false,
        //             showWMC: true,
        //             showCoach: true,
        //             showCoachCategoryIds: []
        //         }
        //     ],
        //     onlyMobile: false,
        //     showWMC: true,
        //     showCoach: true,
        //     showCoachCategoryIds: "all"
        // },
        {
            title: "TOOLS",
            options: [
                {
                    label: "Prospects",
                    href: "/settings/prospects",
                    onlyMobile: false,
                    showWMC: true,
                    showCoach: true,
                    showCoachCategoryIds: "all"
                },
                {
                    label: "Availability",
                    href: "/settings/availability/calendar",
                    onlyMobile: false,
                    showWMC: true,
                    showCoach: true,
                    showCoachCategoryIds: "all"
                },
            ],
            onlyMobile: false,
            showWMC: true,
            showCoach: true,
            showCoachCategoryIds: "all"
        }
    ]
};

export default enterpriseNav;
