import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from "styled-components";

/* Components */
import { LogoLoading } from "../../../common/components/loading";

/* Middleware */
import { tryCreateMyGoal } from '../middleware/progress'

/* Containers */
import FormsFlow from "../../../iframes/forms/containers/flow";
import GoalModalExit from "./goalexit";
import Success from "../../../iframes/success/containers/success";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const PageContent = styled.div`
    width: 100%;
    @media screen and (max-width: 1088px) {
        padding-bottom: 80px;
    }
`;
const PageContentContainer = styled.div`
    width: calc(100% - 120px);
    margin: 0 auto;
    padding: 60px 60px 120px 60px;
    @media screen and (max-width: 748px) {
        padding: 20px 20px 120px 20px;
        width: calc(100% - 40px);
    }
`;
const Container = styled.div`
    width: 100%;
    margin: 0px auto;
    position: relative;
    display: flex;
    gap: 30px;
    flex-direction: column;
`;
const LoadingCenter = styled.div`
    margin: 60px auto;
`;

class CreateGoalFlow extends React.Component {
    state = {
        "step": 1,
        "form": [],
        "isLoading": true,
        "exitModalStatus": false,
        "askedForCoach": false
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({ "isLoading": false })
    };

    setStep = async (step) => {
        return this.setState({ "step": step })
    };

    onReturnForm = async (form) => {
        await this.setState({ "form": form });

        /**
         * Note: HACK ON FORM TO SPEED UP DEV TIME
         * First page in form MUST have 2 elements and we need to read data from second element
        */
        let title = form[0]["elements"][1]["text"];

        let postData = {
            "title": title,
            "pages": form
        };

        this.setState({
            "askedForCoach": form[4].elements[2].options[0].active
        })

        await this.props.tryCreateMyGoal(postData);
        return this.setStep(2);
    };

    backToSectionPathname = () => {
        if(this.state.askedForCoach) {
            this.props.history.push("/my-team/find");
        } else {
            this.props.history.push("/progress/goals");
        }
    };

    exitModalStatus = () => {
        this.setState({"exitModalStatus": !this.state.exitModalStatus})
    };

    render() {
        if (this.state.isLoading) {
            return (<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if (this.state.step === 1) {
                return (
                    <>
                        <PageContent>
                            <PageContentContainer>
                                <Container>
                                    <FormsFlow
                                        form={env.CONSUMER.PROGRESS.MY_GOAL_FORM}
                                        skipAuth={true}
                                        onCompleteFormMethod={"return"}
                                        onReturnForm={this.onReturnForm}
                                        closeEarly={this.exitModalStatus}
                                        sectionPathmame={"/progress/goals"}
                                        formPathname={"/progress/goals/create"}
                                        connectPagesToRoutes={true}
                                        confirmOnClose={this.backToSectionPathname}
                                    />
                                </Container>
                            </PageContentContainer>
                        </PageContent>
                        {this.state.exitModalStatus &&
                            <GoalModalExit
                                cancel={this.exitModalStatus}
                                yes={this.backToSectionPathname}
                            />
                        }
                    </>
                )
            } else if (this.state.step === 2) {
                return (
                    <PageContent>
                        <PageContentContainer>
                            <Container>
                                <Success
                                    back={this.backToSectionPathname}
                                    title={"GOAL CREATED!"}
                                    subtitle={this.state.askedForCoach
                                        ? "Willow's coaches can deliver the support you need to achieve your goals. Click below to choose a coach."
                                        : "We’ll check in with you from time to time to help keep you on track and provide the support you need to achieve your goals."
                                    }
                                    buttonLabel={this.state.askedForCoach ? "Choose a coach" : "Close"}
                                />
                            </Container>
                        </PageContentContainer>
                    </PageContent>
                )
            } else {
                return null
            }
        }
    }

}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    tryCreateMyGoal: (myGoalData) => dispatch(tryCreateMyGoal(myGoalData))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateGoalFlow));