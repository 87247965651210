import styled from "styled-components";
import {colors} from "../../../common/components/colors";
import {FontBody14, FontBody16} from "../../../common/components/fonts";
import {images} from "../../../common/components/images";
import React from "react";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

export const Container = styled.div`
    max-width: 500px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    ${({inModal}) => inModal
        ? `
            // margin: 20px auto 0px 15%;
            @media screen and (max-width: 1440px) {
                margin: 20px auto 0px auto;
            }
        `
        : `
                padding-top: 180px;
                margin: 60px auto 30px 15%;
                @media screen and (max-width: 1440px) {
                    margin: 60px auto 30px auto;
                }
                @media screen and (max-width: 1220px) {
                    padding-top: 0px;
                    margin: 30px auto 0px auto;
                }
                @media screen and (max-width: 460px) {
                    margin: 20px auto 0px auto;
                }
            `
    }
    & input {
        width: calc(100% - 42px);
    }
    & input::placeholder {
        color: ${colors.primary70};
    }
`;

export const FullWidth = styled.div`
    & button {
        width: 100%;
    }
    & button div {
        width: calc(100% - 47px);
        min-height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 18px;
        ${({oneLevel}) => oneLevel
            ? `
                cursor: pointer;
            `
            : `
                background-color: ${colors.secondary70};
                border-color: ${colors.secondary70};
                cursor: default;
            `
        }
    }
`;

export const FullWidthNotButton = styled.div`
    & div {
        width: calc(100% - 47px);
        background-color: ${colors.secondary100};
        border-color: ${colors.secondary100};
    }
    & div div {
        width: 100%;
        min-height: 40px;
        text-align: center;
        font-size: 18px;
        cursor: default;
        background-color: ${colors.secondary100};
        border-color: ${colors.secondary100};
    }
    & div div img {
        width: 33px;
        height: 33px;
    }
`;

export const LoaderStyle = styled.div`
    text-align: center;
    width: 100%;
    & img {
        width: 57px;
        height: 57px;
    }
`;

export const FullWidthNotButtonText = styled.div`
    & div {
        width: calc(100% - 47px);
        background-color: ${colors.secondary100};
        border-color: ${colors.secondary100};
    }
    & div div {
        width: 100%;
        min-height: 40px;
        text-align: center;
        font-size: 18px;
        cursor: default;
        line-height: 40px;
        background-color: ${colors.secondary100};
        border-color: ${colors.secondary100};
        color: ${colors.secondary30};
    }
`;

export const CardInfoTitle = styled.div`
    ${props => props.inModal !== true} {
        margin: 25px 0px;
    }
    ${props => props.inModal !== false} {
        margin-top: 25px;
    }
`;
export const ModalTitle = styled.div`
    padding-left: 15px;
    width: calc(100% - 15px);
`;

export const Gray = styled.div`
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 132px);
    ${props => props.inModal !== true} {
        color: ${colors.primary100};
    }
    ${props => props.inModal !== false} {
        color: ${colors.secondary100};
    }
`;
export const Action = styled.div`
    text-align: right;
    display: inline-block;
    vertical-align: top;
    width: 132px;
    cursor: pointer;
    ${props => props.inModal !== true} {
        color: ${colors.secondary100};
        & > div {
            font-family: ${env.STYLES.FONTS.body.fontFamily};
        }
    }
    ${props => props.inModal !== false} {
        color: ${colors.action100};
    }
`;

export const Top = styled.div`
    & > div {
        margin-top: 25px;
        margin-bottom: 0px;
    }
    & input {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }
`;
export const Bottom = styled.div`
    & > div {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    & input {
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        border-top: 0px;
    }
`;


export const Half = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
    & > div {
        margin-top: 25px;
        margin-bottom: 0px;
    }
    @media screen and (max-width: 460px) {
        width: 100%;
        display: block;
        ${props => props.side !== "right"} {
            & > div {
                margin-top: 0px;
            }
        }
    }
    & input {
        ${props => props.side !== "left"} {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: 0px;
            width: calc(100% - 1px);
            @media screen and (max-width: 460px) {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
                width: calc(100% - 42px);
                border-right: 1px solid ${colors.primary30};
            }
        }
        ${props => props.side !== "right"} {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            margin-top: 22px;
            @media screen and (max-width: 460px) {
                margin-top: 0px;
                border-top: none;
                border-top-right-radius: 0px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 4px;
            }
        }
    }
`;

export const Card = styled.div`
    & > div {
        border-bottom-right-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }
`;

export const Phone = styled.div`
    & > div {
        margin-top: 25px;
        margin-bottom: 35px;
    }
`;

export const PhoneEmpty = styled.div`
    margin-bottom: 35px;
`;

export const Email = styled.div`
    & > div {
        margin-top: 25px;
    }
`;
export const EmailLow = styled.div`
    & > div {
        margin-top: 25px;
        margin-bottom: 35px;
    }
`;

export const Password = styled.div`
    & > div {
        margin-top: 25px;
        margin-bottom: 35px;
    }
`;


export const HalfStripe = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
    @media screen and (max-width: 460px) {
        width: 100%;
        display: block;
    }
    & > div {
        border-top: none !important;
        ${props => props.side !== "left"} {
            border-top-left-radius: 0px !important;
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
            border-right: 0px !important;
            width: calc(100% - 1px) !important;
            @media screen and (max-width: 460px) {
                border-radius: 0px !important;
                width: calc(100% - 42px) !important;
                border-right: 1px solid ${colors.primary30} !important;
            }
        }
        ${props => props.side !== "right"} {
            border-top-left-radius: 0px !important;
            border-top-right-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
            @media screen and (max-width: 460px) {
                border-top-right-radius: 0px !important;
                border-top-left-radius: 0px !important;
                border-bottom-left-radius: 4px !important;
            }
        }
    }
`;

export const StripeComponents = styled.div`    
    .StripeElement, .StripeElementIdeal, .StripeElementFpx {
        background-color: ${colors.border70};
        border: 1px solid ${colors.primary30};
        color: ${colors.primary100};
        border-radius: 4px;
        width: calc(100% - 42px);
        height: 22px;
        padding: 10px 20px;
        font-family: GraphicWebRegular;
        font-size: 16px;
        line-height: 22px;
        @media screen and (max-width: 460px) {
            padding: 12px 20px 8px 20px;
        }
        &::placeholder {
            color: ${colors.primary70};
            font-family: GraphicWebRegular;
            line-height: 22px;
        }
        &:focus {
            outline: none;
        }
    }
    
    .StripeElement.loading {
      height: 44px;
    }
    
    .StripeElementIdeal, .StripeElementFpx {
      padding: 0;
    }
    
`;

export const Error = styled.div`
    color: ${colors.alert100};
    text-align: center;
    padding: 15px 20px;
`;
export const Disclosure = styled.div`
    color: ${colors.secondary70};
    text-align: center;
    padding: 15px 20px;
`;

export const Left = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 150px;
    @media screen and (max-width: 460px) {
        width: 100%;
        display: block;
    }
`;
export const Right = styled.div`
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 150px);
    text-align: right;
    margin-top: 8px;
    color: ${colors.primary70};
    @media screen and (max-width: 460px) {
        width: 100%;
        display: block;
        text-align: left;
    }
`;
export const LoginBtn = styled.div`
    color: ${colors.action100};
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
`;

export const Red = styled.div`
    color: ${colors.alert100};
`;

export const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;

export const Mobile = styled.div`
    display: none;
    margin-top: 30px;
    @media screen and (max-width: 1220px) {
        display: block;
        margin-top: 0px;
        text-align: center;
    }
`;

export const Skip = styled.div`
    color: ${colors.action100};
    cursor: pointer;
`;

const TermsAndPrivacy = styled.div`
    margin-top: 10px;
`;

const Terms = styled.a`
    display: inline-block;
    vertical-align: top;
    margin-right: 15px;
    color: ${colors.primary70};
    text-decoration: none;
`;

const Privacy = styled.a`
    display: inline-block;
    vertical-align: top;
    color: ${colors.primary70};
    text-decoration: none;
`;

const PoweredBy = styled.a`
    text-decoration: none;
    color: ${colors.primary100};
    display: inline-block;
    vertical-align: top;
`;
const PoweredByLogoText = styled.div`
    color: ${colors.primary70};
    display: inline-block;
    vertical-align: top;
`;
const PoweredByLogo = styled.img`
    display: inline-block;
    vertical-align: top;
    height: 24px;
    width: auto;
`;

export const ProductTermPrivacy = ({termsOfUseUrl, privacyPolicyUrl}) => (
    <TermsAndPrivacy>
        <PoweredBy target={"_blank"} href={"https://www.trustwillow.com/"}>
            <FontBody16><PoweredByLogoText>Powered by</PoweredByLogoText>&nbsp;<PoweredByLogo src={images.logo} alt={""} /></FontBody16>
        </PoweredBy>
        <TermsAndPrivacy>
            <Terms href={termsOfUseUrl} target={"_blank"}><FontBody14>Terms</FontBody14></Terms>
            <Privacy href={privacyPolicyUrl} target={"_blank"}><FontBody14>Privacy</FontBody14></Privacy>
        </TermsAndPrivacy>
        <br />
        <br />
    </TermsAndPrivacy>
);

export const Box = styled.div`
    ${props => props.inModal !== true} {
        border-radius: 30px;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        padding: 5px 60px 20px 60px;
        width: calc(100% - 120px);
        margin: 20px 0px;
        @media only screen and (max-width: 460px) {
            padding: 5px 15px 20px 15px;
            width: calc(100% - 30px);
        }
    }
`;

export const PurchaseButton = styled.div`
    ${props => props.inModal !== true} {
        width: 75%;
        margin: 0px auto;
    }
`;

export const DisclosureText = styled(FontBody14)`
    & a {
        text-decoration: none;
        color: ${colors.secondary70};
        font-weight: 600;
    }
`;

export const DisclosureComponent = ({planType, pmtAuthorizeName, termsOfUseUrl=null}) => (
    <Disclosure>
        {planType === "one_time"
            ? <DisclosureText>By confirming your purchase, you allow {pmtAuthorizeName} to charge your card for this payment in accordance with their {(termsOfUseUrl === null || termsOfUseUrl === "") ? "terms" : <a href={termsOfUseUrl} target={"_blank"}>terms</a>}.</DisclosureText>
            : <DisclosureText>By confirming your subscription, you allow {pmtAuthorizeName} to charge your card for this payment and future payments in accordance with their {(termsOfUseUrl === null || termsOfUseUrl === "") ? "terms" : <a href={termsOfUseUrl} target={"_blank"}>terms</a>}.</DisclosureText>
        }
    </Disclosure>
);