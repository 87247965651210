import React from 'react';
import styled from 'styled-components';
import {colors} from '../../../common/components/colors';
import {ButtonPrimary, ButtonInactive, ButtonTertiary, ButtonTertiaryAlert} from "../../../common/components/buttons";

/* Utility */
import { hexToRGB, brightness } from '../../../common/utils/colors';

export const SaveSection = styled.div`
    border-top: 1px solid ${colors.border100};
    text-align: left;
    padding-top: 20px;
    margin-top: 50px;
`;
export const InlineBlock = styled.div`
    display: inline-block;
`;
export const ButtonRight = styled.div`
    margin-right: 10px;
`;
export const SubmitInputWithValidation = ({ isChanged, isValid, isSaving, label }) => {
    if(isChanged && isValid) {
        if(isSaving) {
            return (<ButtonRight><ButtonInactive canSubmit={true} label={"Saving"} /></ButtonRight>)
        } else {
            return (<ButtonRight><ButtonPrimary canSubmit={true} label={label}/></ButtonRight>)
        }
    } else {
        return(<ButtonRight><ButtonInactive canSubmit={true} label={label}/></ButtonRight>)
    }
};
export const SubmitInputWithValidationAlert = ({ isChanged, isValid, isSaving, label }) => {
    if(isChanged && isValid) {
        if(isSaving) {
            return (<ButtonRight><ButtonTertiary canSubmit={true} label={"Saving"} /></ButtonRight>)
        } else {
            return (<ButtonRight><ButtonTertiaryAlert canSubmit={true} label={label}/></ButtonRight>)
        }
    } else {
        return(<ButtonRight><ButtonInactive canSubmit={true} label={label}/></ButtonRight>)
    }
};

export const Container = styled.div`
    display: inline-flex;
    align-content: space-between;
    flex-direction: column;
    row-gap: 10px;
    border-radius: 25px;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color};
    cursor: default;
    border: ${props => props.border ? "3px solid" + props.border : 'none'};
    max-width: 280px;
    min-width: 25%;
    margin: 0px 5px;
`;
Container.defaultProps = {
    backgroundColor: colors.backgroundColor4,
    border: false,
    color: colors.white,
}
export const ContainerTop = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 20px 20px 0 20px;
    border-radius: 25px 25px 0px 0px;
    margin: -2px;
`;
export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
export const BookButton = styled.div`
    border-radius: 25px 0 25px 0;
    color: ${colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.action100};
    cursor: pointer;
    padding: 20px 0;
    box-shadow: 0px 3px 10px 0px #00000040;
`;
export const Button = styled.div`
    border-radius: 25px;
    background-color: ${props => props.primary ? colors.action100 : colors.backgroundColor3};
    color: ${colors.white};
    cursor: pointer;
    padding: 10px;
    text-align: center;
    width: 150px;
`;
export const Background = styled.div`
    background-color: rgba(0,0,0,0.6);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 12;
`;
export const VideoContainer = styled.div`
    width: 80%;
    z-index: 13;
    height: auto;
    background-color: ${colors.white};
    border-radius: 15px;
    padding: 10px 20px 20px 20px;
`;
export const VideoPlayer = styled.video`
    z-index: 13;
    width: 100%;
    height: auto;
    @media screen and (max-width: 460px) {
        width: 100%;
    }
`;
export const Flex = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 11;
`;

export const BackSection = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 15px;
    padding: 15px 0px 0px 0px;
    width: calc(100% - 25px);
    @media screen and (max-width: 460px) {
        padding: 15px 0px 0px 15px;
        width: calc(100% - 15px);
        margin-bottom: 15px;
    }
`;

export const Back = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
`;
export const ListItem = styled.div`
    align-items: center;
    background-color: ${props => props.backgroundColor};
    border-radius: ${props => props.even ? '0px 20px 0px 20px' : '20px 0px 20px 0px'};
    color: ${props => brightness(hexToRGB(props.backgroundColor, false)) < 130 ? colors.white : colors.primary100};
    cursor: pointer;
    gap: 15px;
    padding: 20px;
    text-align: center;
    display: inline-flex;
    justify-content: space-between;
    width: calc(100% - 40px);
    margin: 10px 0px;
`;