import { Map, List, fromJS } from 'immutable';

/* Immutable */

const APPOINTMENTS_DATA = 'APPOINTMENTS_DATA';
const APPOINTMENTS_DOWNLOADING = 'APPOINTMENTS_DOWNLOADING';
const APPOINTMENTS_STORE_RESET = 'APPOINTMENTS_STORE_RESET';

/* Actions */

export const setAppointmentsData = (data) => ({'type': APPOINTMENTS_DATA, 'data': data});
export const setAppointmentsDownloading = (status) => ({'type': APPOINTMENTS_DOWNLOADING, 'status': status});
export const setAppointmentsStoreReset = () => ({'type': APPOINTMENTS_STORE_RESET});

/* Initial State */

const initialState = Map({
    appointmentsData: List(),
    appointmentsDownloading: false,
});

/* Reducer */
const appointmentReducer = (state=initialState, action) => {
    switch (action.type) {
        case APPOINTMENTS_DATA:
            return state.merge({"appointmentsData": fromJS(action.data)});
        case APPOINTMENTS_DOWNLOADING:
            return state.merge({'appointmentsDownloading': action.status});
        case APPOINTMENTS_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default appointmentReducer;
