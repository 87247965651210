import React from 'react';
import styled from 'styled-components';
import {FontBody16To14, FontHeader14, FontSubtitle14, FontTitle24} from "../../common/components/fonts";
import {colors} from "../../common/components/colors";

/* Components */


export const Rectangle = styled.div`
    display:flex;
    flex-direction: column;
    gap: 20px;
    width: calc(30% - 60px);
    text-align: left;
    transition: opacity 1.25s ease-out 0s;
    background-color:white;
    box-shadow: 0 0.875rem 1.5rem 0 rgb(32 46 66 / 15%);
    padding: 30px;
    @media screen and (max-width: 1280px) {
      width: calc(45% - 60px);
    }
    @media screen and (max-width: 800px) {
      width: calc(100% - 60px);
    }
`;
export const Gap = styled.div`
    display:flex;
    flex-direction: column;
    gap: 5px;
`;
export const SponsoredText = styled(FontSubtitle14)`
  font-size:8px;
  color: rgba(152,158,166,0.588);
  margin-right: auto;
  order:2;
`;
const PriorityRectangle = styled(Rectangle)`
  border: 1px solid ${colors.action100};
  box-shadow: 0 0.875rem 1.5rem 0 ${colors.action30};
  ${SponsoredText} {}
`;
export const ImageImg = styled.img`
  width: 100%;
  max-width: 300px;
  object-fit: cover;
  height: 100%;
  max-height:300px;
  border-radius:50%;
  aspect-ratio: 1 / 1;
`;
export const NameText = styled(FontTitle24)`
    color:${colors.primary100};
`;
export const CertText = styled(FontHeader14)`
    color:${colors.primary70};
`;
export const BioText = styled(FontBody16To14)`
    color:${colors.primary70};
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @media screen and (max-width: 800px) {
      -webkit-line-clamp: 5;
    }
`;
export const BtnDiv = styled.div`
    display:flex;
    justify-content: center;
    & > button > div {
      border-color: ${colors.action100};
      background-color:${colors.action100};
    }
    & > button > div:hover {
      border-color: ${colors.action70};
      background-color:${colors.action70};
    }
`;
export const BtnDivInactive = styled.div`
    width: 100%;
    text-align: center;
`;
export const ImageBox = styled.div`
    display: flex;
    justify-content: center;
`;



