import {axiosReq} from "../../../../common/utils/axios";

/* Store */
import {setServicesExpertProfile, setServicesExpertProfileEdit, setServicesExpertProfileGet, setServicesExpertProfilePut} from "../store/expertprofile";

/* Static */
import {devServicesExpertProfileData, devServicesExpertProfileUpdateData} from "../static/devdata";

/* Utils */
import {testPhoto, removeOldImageUrl} from '../utils/images';

export const tryGetExpertProfile = () => {
    return async dispatch => {
        dispatch(setServicesExpertProfileGet(true));
        const profile = await axiosReq('apiv1/coach/settings/profile', "GET", {}, devServicesExpertProfileData);
        dispatch(setServicesExpertProfile(profile));
        return dispatch(setServicesExpertProfileGet(false));
    }
};

export const tryUpdateExpertProfile = (data, errorKey) => {
    return async (dispatch, getState) => {
        dispatch(setServicesExpertProfilePut(true));
        let photo = await testPhoto(data.photoBlob, data.photoChanged);
        let request = photo === null ? data : {...data, profile: photo};
        const profile = await axiosReq('apiv1/coach/settings/profile', "PUT", request, devServicesExpertProfileUpdateData(data));
        if(profile.hasOwnProperty("error")) {
            dispatch(setServicesExpertProfileEdit({...getState().enterprise.scheduler.expertProfile.get("expertProfile").toJS(), [errorKey]: profile.error}));
        } else {
            await dispatch(tryGetExpertProfile());
            const dataNoPhoto = await removeOldImageUrl(data, ["photo"]);
            dispatch(setServicesExpertProfileEdit({...dataNoPhoto, [errorKey]: "", "photo": getState().enterprise.scheduler.expertProfile.get("expertProfile").get("photo")}));
        }
        return dispatch(setServicesExpertProfilePut(false));
    }
};