import { combineReducers } from 'redux';

/* Common */
import profileReducer from './store/profile';
import listingReducer from "./store/listings";

const listingsReducer = combineReducers({
    profile: profileReducer,
    listings: listingReducer,
});

export default listingsReducer;
