import { axiosReq } from '../../common/utils/axios';
import Cookies from 'js-cookie';
import { Map } from "immutable";

/* Store */
import { setLoggedIn, setShowSupport, setUserStoreReset, setShowDemo } from '../store/user';
import { setWMCCheck } from '../../common/containers/company/store/wmc';

/* Middleware */
import {trackRegistration, trackLogin, trackLogout} from '../../common/utils/tracking';
import { tryGetNotRatedClients, tryGetNotRatedCoach } from '../../common/containers/noticerating/middleware/rating';
import { tryGetNoticesSessions } from '../../common/containers/noticemeeting/middleware/noticecall';
import { tryGetAllTeamMembers } from '../../consumer/team/middleware/team';
import { tryGetWmcsForCoach } from "../../enterprise/partnerships/middleware/coaching";

/* Static */
import { devAuthData } from '../static/devdata.js';

/* Domain */
import getDomainEnv from '../../domains/utils';
const env = getDomainEnv();

const logInConsumer = (user) => {
    return async dispatch => {
        let userAdj = {...user, "platform": "consumer"};
        await dispatch(setLoggedIn(userAdj));
        await dispatch(setShowSupport(true));
        await dispatch(tryGetNoticesSessions());
        // setInterval(() => {return(dispatch(tryGetNoticesSessions()))}, 1000 * 60 * 15);
        await dispatch(tryGetNotRatedCoach());
        setInterval(() => {return(dispatch(tryGetNotRatedCoach()))}, 1000 * 60 * 60 * 12);
        return dispatch(trackLogin());
    }
};

const logInEnterprise = (user, isWMC) => {
    return async dispatch => {
        let userAdj = {...user, "platform": "enterprise"};
        await dispatch(setLoggedIn(userAdj));
        await dispatch(setShowSupport(true));
        await dispatch(tryGetNoticesSessions());
        // setInterval(() => {return(dispatch(tryGetNoticesSessions()))}, 1000 * 60 * 15);
        if(!isWMC) {
            await dispatch(tryGetNotRatedClients());
            setInterval(() => {return(dispatch(tryGetNotRatedClients()))}, 1000 * 60 * 60 * 12);
        }
        return dispatch(trackLogin());
    }
};


export const tryAuth = (history) => {
    return async (dispatch, getState) => {
        let user = getState().common.user.toJS();
        let redirectNow = getState().common.user.get("redirectNow");
        let redirectFrom = getState().common.user.get("redirectFrom");
        if(user.email === null) {
            user = await axiosReq('apiv1/auth', "POST", {}, devAuthData);
        }
        if(user === 'logout') {
            return dispatch(tryLogout());
        } else {
            if (user.isCoach) {
                if(user.wmc !== null) {
                    await dispatch(setWMCCheck(true, user.wmc));
                    await dispatch(logInEnterprise({...user, "isSupport": false, "isExpertDemo": false, "isWMCDemo": false}, true));
                    const domain = await env.ENTERPRISE.COMPANY.CHECK_VANITY_WMC_ID(user.wmc.id);
                    const pathnameRequest = redirectNow ? redirectFrom : env.ENTERPRISE.COMPANY.AUTH.HOME_URL;
                    if(window.location.href.includes(domain)) {
                        return history.push(pathnameRequest);
                    } else {
                        return window.open("https://"+domain+pathnameRequest,"_self");
                    }
                } else {
                    await dispatch(setWMCCheck(false, Map()));
                    if(user.onboardingCompleted) {
                        const isSupport = user.expertCategories.filter(e => {return(e.categoryId === 7 || e.categoryId === 6)}).length > 0;
                        let wmcs = [];
                        if(env.ENTERPRISE.EXPERT.AUTH.CHECK_PARTNERED_WITH_COMPANY) {
                            wmcs = await dispatch(tryGetWmcsForCoach());
                            await dispatch(logInEnterprise({...user, "hasPartners": (wmcs.length > 0), "isSupport": isSupport, "isExpertDemo": user.userId === 8, "isWMCDemo": user.userId === 2530}, false));
                            return history.push(redirectNow ? redirectFrom : (isSupport ? env.ENTERPRISE.EXPERT.AUTH.SUPPORT_URL : (wmcs.length === 0 ? env.ENTERPRISE.EXPERT.AUTH.HOME_URL : env.ENTERPRISE.EXPERT.AUTH.PARTNER_URL)));
                        }
                    } else {
                        return window.location.replace("https://"+env.URL.AUTH+"/expert");
                    }
                }
            } else {
                await dispatch(logInConsumer(user));
                if(env.CONSUMER.AUTH.REQUIRE_TEAM_TO_ACCESS_DASHBOARD) {
                    const allCoaches = await dispatch(tryGetAllTeamMembers());
                    const hasCoach = allCoaches.length > 0;
                    return history.push(hasCoach ? env.CONSUMER.AUTH.HOME_URL : env.CONSUMER.AUTH.SIGN_UP_URL);
                } else {
                    return history.push(env.CONSUMER.AUTH.HOME_URL);
                }
            }
        }
    }
};

// export const tryAuthConsumer = (history) => {
//     return async dispatch => {
//         let user = await axiosReq('apiv1/auth', "POST", {}, devAuthData);
//         await dispatch(logInConsumer(user));
//         if(env.CONSUMER.AUTH.REQUIRE_TEAM_TO_ACCESS_DASHBOARD) {
//             const allCoaches = await dispatch(tryGetAllTeamMembers());
//             const hasCoach = allCoaches.length > 0;
//             return history.push(hasCoach ? env.CONSUMER.AUTH.HOME_URL : env.CONSUMER.AUTH.SIGN_UP_URL);
//         } else {
//             return history.push(env.CONSUMER.AUTH.HOME_URL);
//         }
//     }
// }

export const tryAuthOnly = (email, trackNewRegistration, forceAuth) => {
    return async dispatch => {
        console.log("tryAuthOnly called")
        const sessionId = Cookies.get("sessionid");
        if ((sessionId !== null && sessionId !== undefined) || forceAuth) {
            console.log("tryAuthOnly valid cookie or force auth")
            let user = await axiosReq('apiv1/auth', "POST", {}, devAuthData);
            if(user === 'logout') {
                return null;
            } else {
                if(email === null || user.email === email) {
                    await dispatch(setLoggedIn({
                        ...user,
                        "platform": user.isCoach ? 'enterprise' : 'consumer',
                    }));
                    if(user.wmc !== null) {
                        await dispatch(setWMCCheck(true, user.wmc));
                    } else {
                        await dispatch(setWMCCheck(false, Map()));
                    }
                    if(trackNewRegistration) {
                        await dispatch(trackRegistration())
                    }
                    await dispatch(trackLogin());
                    return user;
                } else {
                    return null;
                }
            }
        } else {
            return null
        }
    }
};

export const tryAuthUserDataUpdate = () => {
    return async dispatch => {
        let user = await axiosReq('apiv1/auth', "POST", {}, devAuthData);
        await dispatch(setLoggedIn({
            ...user,
            "platform": user.isCoach ? 'enterprise' : 'consumer',
        }));
    }
};

export const tryLogout = () => {
    return dispatch => {
        Cookies.remove('sessionid', {path: "/", domain: ".trustwillow.com"});
        dispatch(trackLogout());
        dispatch(setUserStoreReset());
        return window.location.replace("https://"+env.URL.AUTH+"/login");
    }
};

import { tryGetOneProspect } from '../../enterprise/middleware/prospects';
import { tryGetOneClient } from '../../enterprise/clients/middleware/clients';
import { tryGetOneContact } from '../../enterprise/middleware/contacts';
import { setClientsDetailSelected } from '../../enterprise/store/clientsdetail';
import {devAdvisors} from "../../iframes/advisordirectory/static/devdata";

export const setContact = (prospectId) => {
    return async (dispatch, getState) => {
        let prospect = await dispatch(tryGetOneProspect(prospectId, "return"));
        if(Object.keys(prospect).length === 1) {
            prospect = await dispatch(tryGetOneClient(prospectId, "return"));
        }
        if(prospect.contactId === undefined) {
            return dispatch(tryLogout());
        } else {
            const contact = await dispatch(tryGetOneContact(prospect.contactId, "return"));
            let data;
            let userId = getState().common.user.get("userId");
            let isWMC = getState().common.wmc.get("isWMC");
            let hasCoach = prospect.coaches === undefined ? false : (prospect.coaches.size === 0 ? false : (prospect.coaches.filter(coach => {return(coach.wmcId === null)}).size > 0));
            let isAdvisorHasCoach = (isWMC && hasCoach);
            let isAdvisorNoCoach = (isWMC && !hasCoach);
            let advisorNotWMC = prospect.advisor === null ? null : prospect.advisor.advisorId === userId ? null : prospect.advisor.firstName + " " + prospect.advisor.lastName;
            let isCoachHasAdvisor = (!isWMC && advisorNotWMC !== null);
            let isCoachNoAdvisor = (!isWMC && advisorNotWMC === null);
            if(contact.prospectStatus === "Client") {
                const client = await dispatch(tryGetOneClient(prospectId, "return"));
                data = {
                    ...client,
                    "prospectStatus": contact.prospectStatus,
                    "businessUserId": contact.businessUserId,
                    "isCoachHasAdvisor": isCoachHasAdvisor,
                    "isCoachNoAdvisor": isCoachNoAdvisor,
                    "isAdvisorHasCoach": isAdvisorHasCoach,
                    "isAdvisorNoCoach": isAdvisorNoCoach
                };
            } else {
                data = {
                    ...prospect,
                    "prospectStatus": contact.prospectStatus,
                    "businessUserId": contact.businessUserId,
                    "isCoachHasAdvisor": isCoachHasAdvisor,
                    "isCoachNoAdvisor": isCoachNoAdvisor,
                    "isAdvisorHasCoach": isAdvisorHasCoach,
                    "isAdvisorNoCoach": isAdvisorNoCoach
                };
            }
            dispatch(setClientsDetailSelected({
                "contactId": data.contactId,
                "prospectId": data.prospectId,
                "consumerId": data.consumerId,
                "businessUserId": data.businessUserId,
                "firstName": data.firstName,
                "lastName": data.lastName,
                "prospectStatus": data.prospectStatus,
                "revenue": data.revenue,
                "probabilityOfClosing": data.probabilityOfClosing,
                "companyName": data.companyName,
                "isCoachHasAdvisor": data.isCoachHasAdvisor,
                "isCoachNoAdvisor": data.isCoachNoAdvisor,
                "isAdvisorHasCoach": isAdvisorHasCoach,
                "isAdvisorNoCoach": isAdvisorNoCoach,
                "referralSource": data.referralSource
            }))
        }
    }
};

export const getAdvisorFromSearch = ({description}) => {
    return async (dispatch) => {
        return axiosReq('apiv1/search/advisor', 'POST', description, devAdvisors);
    }
}

export const savePhoneSMS = ({number}) => {
    return async (dispatch) => {
        return axiosReq('apiv1/settings/phone', 'POST', number, {});
    }
}
