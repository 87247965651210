import React from 'react';
import styled from 'styled-components';

/* Containers */
import {colors} from "../../../common/components/colors";
import {FontBody24, FontTitle40} from "../../../common/components/fonts";

const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto 20px auto;
    max-width: 700px;
  @media screen and (max-width: 1680px) {
    max-width: 600px;
  }
  @media screen and (max-width: 748px) {
    max-width: 550px;
    margin-bottom: 40px;
  }
`;

const ContactTitle = styled(FontTitle40)`
  font-size: 80px !important;
  @media screen and (max-width: 1680px) {
    font-size: 60px !important;
  }
  @media screen and (max-width: 748px) {
    font-size: 50px !important;
  }
  line-height: 1;
  color: ${props => props.color};
    text-align: center;
`;

const PreviewWhite = styled(FontBody24)`
  color: ${props => props.color}B3;
  line-height: 1.5;
  text-align: center;
  & strong {  
    color: ${props => props.color};
  }
  @media screen and (max-width: 1680px) {
    font-size: 20px !important;
  }
  @media screen and (max-width: 748px) {
    font-size: 18px !important;
  }
`;

ContactIntro.defaultProps = {
    title: "",
    subtitle: "",
}

export default function ContactIntro(props) {
    const title = props.title;
    const subtitle = props.subtitle;

    return(
        <Title>
            <ContactTitle color={colors.white}>{title}</ContactTitle>
            <PreviewWhite color={colors.white}>{subtitle}</PreviewWhite>
        </Title>
    );
}