import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';

/* Components */
import {InlineBlock, SaveSection, SubmitInputWithValidation} from "../../../enterprise/components/clientscreate";
import {FontHeader18, FontBody14, FontBody16, FontHeader16, FontTitle30, FontTitle38, FontBody21} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";
import {ButtonTertiary} from "../../../common/components/buttons";
import {images} from "../../../common/components/images";
import {trySendPasswordResetEmail} from '../middleware/passwordreset';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

export const Left = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 150px;
    @media screen and (max-width: 460px) {
        width: 100%;
        display: block;
    }
`;
export const Right = styled.div`
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 150px);
    text-align: right;
    margin-top: 8px;
    color: ${colors.primary70};
    @media screen and (max-width: 460px) {
        width: 100%;
        display: block;
        text-align: left;
    }
`;
export const LoginBtn = styled.div`
    color: ${colors.action100};
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
`;

const Container = styled.div`
    max-width: 768px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 0px auto 0px auto;
`;

export const HalfAlign = styled.div`
    display: inline-block;
    vertical-align: top;
    ${props => props.side !== "left"} {
        width: 49%;
        text-align: left;
        margin-right: 2%;
    }
    ${props => props.side !== "right"} {
        width: 49%;
        text-align: right;
    }
    margin-bottom: -15px;
`;

const FitContent = styled.div`
    width: fit-content;
`;
const SummaryTitle = styled.div`
    padding: 0px auto;
    text-align: center;
`;
export const Center = styled.div`
    margin-top: 30px;
    text-align: center;
    padding: 0px 10px;
`;
const SummaryIcon = styled.img`
    width: auto;
    margin: 60px auto 20px auto;
    height: 260px;
`;


class EmailSent extends React.Component {
    state={
        "isSaving": false,
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.email !== prevProps.email) {
            return this.init()
        }
    }

    init = async () => {
        
    };

    resendCode = async () => {
        return this.props.trySendPasswordResetEmail(this.props.email)
    }

    handleSubmit = async () => {
        this.props.next()
    }

    render() {
        return(
            <Container>
                <form method={"post"} onSubmit={this.handleSubmit}>
                    <div>
                        <Left><FontHeader18>Set Your Password</FontHeader18></Left>
                        <Right><FontBody16>Know your password?&nbsp;<LoginBtn onClick={this.props.login}><FontHeader16>Login</FontHeader16></LoginBtn></FontBody16></Right>
                    </div>
                    <Center>
                        <SummaryTitle><FontTitle38>EMAIL SENT!</FontTitle38></SummaryTitle>
                        <br />
                        <SummaryTitle><FontBody21>Check your email for the confirmation code</FontBody21></SummaryTitle>
                        <SummaryIcon src={images.completeIcon}/>
                    </Center>
                    <SaveSection>
                        <HalfAlign side={"left"}>
                            <InlineBlock><FitContent onClick={this.props.back}><ButtonTertiary canSubmit={false} label={"Back"} /></FitContent></InlineBlock>
                        </HalfAlign>
                        <HalfAlign side={"right"}>
                            <InlineBlock><SubmitInputWithValidation label={"Got it!"} isChanged={true} isValid={true} isSaving={this.state.isSaving} /></InlineBlock>
                        </HalfAlign>
                    </SaveSection>
                    <br />
                    <br />
                </form>
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => ({
    trySendPasswordResetEmail: (email) => dispatch(trySendPasswordResetEmail(email))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailSent);
