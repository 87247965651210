import React from 'react';
import styled from 'styled-components';
import validator from "validator";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

/* Components */
import {PasswordInput, TextInput} from "../../../common/components/inputs";
import {FontHeader18, FontBody14, FontBody16, FontHeader16} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";
import {ButtonInactive, ButtonPrimary, ButtonTertiary} from "../../../common/components/buttons";

/* Middleware */
import {tryScheduleLogin} from '../middleware/auth';
import {tryAuthOnly} from '../../../routes/middleware/auth';

const SaveSection = styled.div`
    border-top: 1px solid ${colors.border100};
    text-align: left;
    padding-top: 20px;
    margin-top: 50px;
    margin-bottom: 35px;
`;
const InlineBlock = styled.div`
    display: inline-block;
`;
const ButtonRight = styled.div`
    margin-right: 10px;
`;
const SubmitInputWithValidation = ({ isChanged, isValid, isSaving, label }) => {
    if(isChanged && isValid) {
        if(isSaving) {
            return (<ButtonRight><ButtonInactive canSubmit={true} label={"Saving"} /></ButtonRight>)
        } else {
            return (<ButtonRight><ButtonPrimary canSubmit={true} label={label}/></ButtonRight>)
        }
    } else {
        return(<ButtonRight><ButtonInactive canSubmit={true} label={label}/></ButtonRight>)
    }
};

export const Left = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 150px;
    @media screen and (max-width: 460px) {
        width: 100%;
        display: block;
    }
`;
export const Right = styled.div`
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 150px);
    text-align: right;
    margin-top: 8px;
    color: ${colors.primary70};
    @media screen and (max-width: 460px) {
        width: 100%;
        display: block;
        text-align: left;
    }
`;
export const LocalLink = styled.div`
    color: ${colors.action100};
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
`;

const Red = styled(FontBody14)`
    color: ${colors.alert100};
`;

const Container = styled.div`
    max-width: 768px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 0px auto 0px auto;
`;

export const HalfAlign = styled.div`
    display: inline-block;
    vertical-align: top;
    ${props => props.side !== "left"} {
        width: 49%;
        text-align: left;
        margin-right: 2%;
    }
    ${props => props.side !== "right"} {
        width: 49%;
        text-align: right;
    }
    margin-bottom: -15px;
`;

const FitContent = styled.div`
    width: fit-content;
`;

const OnlySubmit = styled.div`
    margin: 20px auto 0px auto;
    text-align: center;
    & button > div {
        min-width: 160px;
    }
`;

const formStyleSystem = {
    showBackBtn: false,
    showTitle: true,
    showCreateAccountLink: true,
    submitLabel: "Login",
    inputsEmailLabel: "Email",
    inputsEmailPlaceholder: "Email",
    inputsEmailWarning: "Enter a valid email address",
    inputsPasswordLabel: "Password",
    inputsPasswordPlaceholder: "Password",
    inputsPasswordWarning: "Required",
    inputsContainersStyles: {},
    inputsStyles: {},
    containerStyles: {},
    forgotPasswordStyles: {},
    alwaysAllowSubmit: false,
    onlyShowSubmit: false
};

const pageStyleSystem = {
    showBackBtn: false,
    showTitle: false,
    showCreateAccountLink: false,
    submitLabel: "Sign in",
    inputsEmailLabel: "",
    inputsEmailPlaceholder: "Email address",
    inputsEmailWarning: "",
    inputsPasswordLabel: "",
    inputsPasswordPlaceholder: "Password",
    inputsPasswordWarning: "",
    containerStyles: {backgroundColor: colors.backgroundColor2, borderRadius: "20px", padding: "30px 45px", width: "calc(100% - 90px)"},
    inputsContainersStyles: {margin: "0px 0px 20px 0px"},
    inputsStyles: {borderRadius: "25px", lineHeight: "36px", backgroundColor: colors.white},
    forgotPasswordStyles: {width: "fit-content", margin: "0px auto", color: colors.white, textDecoration: "underline", fontWeight: "bold", display: "block"},
    alwaysAllowSubmit: false, // true stops local validation
    onlyShowSubmit: true
};

class Login extends React.Component {
    static defaultProps = {
        email: "",
        loginShowUserExists: false,
        loginSuccess: () => {},
        viewSignUp: () => {},
        back: () => {},
        styleSystem: "form",
        forgotPasswordLink: () => {},
    };

    state={
        ...formStyleSystem,
        "password": "", "passwordValid": true,
        "email": "", "emailValid": true,
        "showUserExists": false,
        "isValid": true,
        "isChanged": false,
        "isSaving": false,
        "isLoading": true,
        "showError": false,
        "loginError": ""
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.email !== this.props.email) {
            return this.init();
        }
        if(prevProps.iframeAuthLoginError !== this.props.iframeAuthLoginError && this.props.iframeAuthLoginError !== null) {
            this.setState({"loginError": this.props.loginError, 'showError': true});
            this.timeout = setTimeout(() => {this.setState({'showError': false})}, 2000);
        }
    }

    init = async () => {
        this.setState({
            ...(this.props.styleSystem === "page" ? pageStyleSystem : formStyleSystem),
            "email": this.props.email,
            "showUserExists": this.props.loginShowUserExists,
            "isLoading": false
        })
    };

    setValid = (changed) => {
        const isPasswordValid = (!validator.isEmpty(this.state.password) && this.state.password !== '');
        const isEmailValid = validator.isEmail(this.state.email);
        this.setState({
            "passwordValid": changed === "password" ? isPasswordValid : this.state.passwordValid,
            "emailValid": changed === "email" ? isEmailValid : this.state.emailValid,
            'isValid': (isPasswordValid && isEmailValid)
        });
    };

    handleChangePassword = async e => {
        await this.setState({
            'password': e.target.value,
            'passwordValid': (!validator.isEmpty(this.state.password) && this.state.password !== ''),
            'isChanged': true,
            "showUserExists": false
        });
        this.setValid("password");
    };

    handleChangeEmail = async e => {
        await this.setState({
            'email': e.target.value,
            'emailValid': validator.isEmail(this.state.email),
            'isChanged': true,
            "showUserExists": false
        });
        this.setValid("email");
    };

    handleSubmit = async e => {
        e.preventDefault();
        await this.props.tryScheduleLogin(this.state.email.toLowerCase(), this.state.password);
        if(this.props.iframeAuthLoginError === null) {
            console.log('login no error')
            if(this.props.styleSystem === "page") {
                this.props.history.push("/verify");
            } else {
                await this.props.tryAuthOnly(null, false, true);
                await this.props.loginSuccess();
            }
        } else {
            await this.setState({"loginError": this.props.iframeAuthLoginError, 'showError': true});
            this.timeout = setTimeout(() => {this.setState({'showError': false})}, 2000);
        }
    };

    render() {
        return(
            <Container style={this.state.containerStyles}>
                <form method={"post"} onSubmit={this.handleSubmit}>
                    {(this.state.showTitle || this.state.showCreateAccountLink) &&
                        <div>
                            <Left>{this.state.showTitle && <FontHeader18>Log in</FontHeader18>}</Left>
                            <Right>{this.state.showCreateAccountLink && <FontBody16>Need an account?&nbsp;<LocalLink onClick={this.props.viewSignUp}><FontHeader16>Create account</FontHeader16></LocalLink></FontBody16>}</Right>
                        </div>
                    }
                    <TextInput
                        title={this.state.inputsEmailLabel}
                        valid={this.state.emailValid}
                        warning={this.state.inputsEmailWarning}
                        id={"email"}
                        onChange={this.handleChangeEmail}
                        placeholder={this.state.inputsEmailPlaceholder}
                        value={this.state.email}
                        containerStyles={this.state.inputsContainersStyles}
                        inputStyles={this.state.inputsStyles}
                    />
                    <PasswordInput
                        title={this.state.inputsPasswordLabel}
                        valid={this.state.passwordValid}
                        warning={this.state.inputsPasswordWarning}
                        id={"password"}
                        onChange={this.handleChangePassword}
                        placeholder={this.state.inputsPasswordPlaceholder}
                        value={this.state.password}
                        containerStyles={this.state.inputsContainersStyles}
                        inputStyles={this.state.inputsStyles}
                    />
                    <Red>{this.state.showUserExists ? "User already exists. Please login." : (this.state.showError ? this.state.loginError : "")}</Red>
                    <br />
                    <LocalLink onClick={this.props.forgotPasswordLink} style={this.state.forgotPasswordStyles}>
                        <FontBody14>Forgot your password?</FontBody14>
                    </LocalLink>
                    {this.state.onlyShowSubmit
                        ?
                            <OnlySubmit>
                                <SubmitInputWithValidation
                                    label={this.state.submitLabel}
                                    isChanged={this.state.alwaysAllowSubmit ? true : this.state.isChanged}
                                    isValid={this.state.alwaysAllowSubmit ? true : this.state.isValid}
                                    isSaving={this.state.isSaving}
                                />
                            </OnlySubmit>
                        :
                            <SaveSection>
                                <HalfAlign side={"left"}>
                                    {this.state.showBackBtn && <InlineBlock><FitContent onClick={this.props.back}><ButtonTertiary canSubmit={false} label={"Back"} /></FitContent></InlineBlock>}
                                </HalfAlign>
                                <HalfAlign side={"right"}>
                                    <InlineBlock>
                                        <SubmitInputWithValidation
                                            label={this.state.submitLabel}
                                            isChanged={this.state.alwaysAllowSubmit ? true : this.state.isChanged}
                                            isValid={this.state.alwaysAllowSubmit ? true : this.state.isValid}
                                            isSaving={this.state.isSaving}
                                        />
                                    </InlineBlock>
                                </HalfAlign>
                            </SaveSection>
                    }
                </form>
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    iframeAuthLoginError: state.iframe.signup.auth.get("iframeAuthLoginError")
});

const mapDispatchToProps = dispatch => ({
    tryScheduleLogin: (email, password) => dispatch(tryScheduleLogin(email, password)),
    tryAuthOnly: (email, trackNewRegistration, forceAuth) => dispatch(tryAuthOnly(email, trackNewRegistration, forceAuth))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
