import {images} from '../../../common/components/images';

/* Middleware - tryAuth */
export const devIframeAuthData = {
    "email": "jason.aronson@gmail.com",
    "first": "Jason",
    "last": "Aronson",
    "subscription": true,
    "platform": "coach", // coach, advisor, advisor_and_coach, consumer, enterprise
    "userId": 24324,
    "uid": "74d1a449-ba40-4ad0-9989-f148807f22ed",
    "onboardingCompleted": true,
    "phone": 1111111111,
    "newUser": false,
    "companyName": "Company Name, LLC",
    "photo": images.devDataTeamJasonAvatar,
    // "wmc": null,
    "wmc": {
        "id": 1,
        "address1": "",
        "address2": "",
        "city": "Boston",
        "companyLogoUrl": images.logo,
        "companyName": "Willow Network, Inc.",
        "contactPersonCellPhone": "",
        "contactPersonEmail": "",
        "contactPersonFirstName": "",
        "contactPersonLastName": "",
        "contactPersonWorkPhone": "",
        "email": "",
        "facebookUrl": "",
        "linkedinUrl": "",
        "phone": "",
        "state": "MA",
        "twitterUrl": "",
        "zipCode": ""
    },
    "referralType": "wmc", // "wmc" or "willow"
    "expertCategoryName": "Financial Coach",
    "expertCategoryId": 1
};

export const devHrCodeValidation = {
    "wmc": {
        "id": 1,
        "address1": "",
        "address2": "",
        "city": "Boston",
        "companyLogoUrl": images.logo,
        "companyName": "Willow Network, Inc.",
        "contactPersonCellPhone": "",
        "contactPersonEmail": "",
        "contactPersonFirstName": "",
        "contactPersonLastName": "",
        "contactPersonWorkPhone": "",
        "email": "",
        "facebookUrl": "",
        "linkedinUrl": "",
        "phone": "",
        "state": "MA",
        "twitterUrl": "",
        "zipCode": ""
    },
};

export const devIframeVerificationStatus = {
    "status": "verified" // "no_verification_code_sent", "not_verified", "verified"
};

export const devIframeSession = {
    id: 1,
    remoteUserName: "Gabby Coach",
    remoteUserId: 1,
    thisUserName: "Jason Consumer",
    thisUserPlatform: "consumer",
    start: "2020-06-14T13:00:00+00:00",
    end: "2020-06-14T13:30:00+00:00",
    phone: "617-380-4313",
    room: "277861",
    videoStatus: "conference_ongoing", // conference_canceled, conference_ended, conference_has_not_yet_started, conference_ongoing
    videoUrl: "924fcf54447903ab9ea558886af67543", // if status !== conference_ongoing then videoUrl = null
    videoAccessToken: "abc", // if status !== conference_ongoing then videoAccessToken = null
    title: 'Jason Consumer',
};

export const devIframeHandleData = (isFound, handle) => {
    if(isFound) {
        return({
            "id": 12,
            "publicHandle": handle,
            "expertCategoryName": "coach", // coach, advisor, etc
            "expertCategoryId": 1
        })
    } else {
        return({
            "error": "Public handle can not be found",
            "errorDetails": "Expert does not exist"
        })
    }
};

/* Middleware - tryGetSettingsEnterpriseProfile */
// coachingcert, advancedDegree, otherEducation
export const devIframeProfileData = {
    id: '32',

    firstName: "Jason",
    lastName: "Aronson",
    companyName: 'Coaching, LLC',
    crd: 'test 5',

    photo: images.devDataTeamJasonAvatar,
    introVideoUrl: 'https://www.youtube.com/embed/RZAF1DAqHPk',
    facebookUrl: 'https://facebook.com',
    linkedinUrl: 'https://linkedin.com',
    twitterUrl: 'https://twitter.com',
    address1: 'test 3',
    address2: 'test 6',
    city: 'Boston',
    state: 'MA',
    zipCode: '23443',
    bio: '<p>In an age of pandemic, what true value do any of our backgrounds hold? It’s not like we can look at precedents and say, people who have done ABC tend to be really successful at accomplishing XYZ in a pandemic. My expf</p>',

    // BEG COACH ONLY FIELDS
    endorsement1: 'Test 1',
    endorsement2: 'Test 2',
    endorsement3: null,
    freeTime: 'Test 7',
    whyBecameCoach: 'Test 2',
    coachPhilosophy: 'Test 3',
    highlights: 'Test 1',

    coachingCertification: null,
    coachingCertificationYear: null,

    // END COACH ONLY FIELDS

    bookingAvailability: 'available',

    screeningCallLength: 30,
    defaultCallLength: 30,

    advancedDegree: null,
    advancedDegreeCity: null,
    advancedDegreeInstitution: null,
    advancedDegreeState: null,
    advancedDegreeYear: null,

    otherEducation: null,

    expertCategories: [],

    profileActive: true,
    profileShowEmailer: true,
    profileShowScheduler: true,
    schedulerActive: true,
    schedulerHandle: "sampleadvisor",
    storeActive: true,
    storeHandle: "sampleadvisor",
    storePhoto: null,
    storeShowEmailer: true,
    storeShowScheduler: true,
    storeTitle: "",
    website: ""
};

export const devIframeLogin = (success) => {
    if(success) {
        return({"success": true})
    } else {
        return({"error": "Something happened."})
    }
};

export const devIframeEvents = {
    "coachingProducts": [
        {
            "id": 8,
            "title": "TestPlan 2",
            "description": "Lorem ipsum...",
            "coverPhoto": null,
            "termsOfUseUrl": "Lorem ipsum www.google.com",
            "privacyPolicyUrl": "www.google.com",
            "downloadUrl": "www.download.com",
            "sessionLength": 15,
            "serviceType": "event",
            "status": "Active",
            "plans": [
                {
                    "id": 4,
                    "orderIndex": 0,
                    "description": "Buy this plan for 2 meetings, 1 free",
                    "amount": 4000,
                    "planType": "one_time",
                    "recurringInterval": null
                },
                {
                    "id": 5,
                    "orderIndex": 1,
                    "description": "Buy this plan for year of meetings, 4 meetings per month, paid annualy, cancel anytime",
                    "amount": 50000,
                    "planType": "recurring",
                    "recurringInterval": "year"
                },
                {
                    "id": 6,
                    "orderIndex": 2,
                    "description": "Buy this plan for 3 monthly meetings, charged monthly for 4 months",
                    "amount": 2500,
                    "planType": "recurring",
                    "subscriptionDurationInMonths" : 4,
                    "recurringInterval": "month"
                }
            ]
        },
        {
            "id": 9,
            "title": "TestPlan 3",
            "description": "Lorem ipsum...",
            "coverPhoto": null,
            "termsOfUseUrl": "Lorem ipsum www.google.com",
            "privacyPolicyUrl": "www.google.com",
            "downloadUrl": "www.download.com",
            "sessionLength": 60,
            "serviceType": "event",
            "status": "Active",
            "plans": [
                {
                    "id": 7,
                    "orderIndex": 0,
                    "description": "Buy this plan for 2 meetings, 1 free",
                    "amount": 4000,
                    "planType": "one_time",
                    "recurringInterval": null
                },
                {
                    "id": 8,
                    "orderIndex": 1,
                    "description": "Buy this plan for year of meetings, 4 meetings per month, paid annualy, cancel anytime",
                    "amount": 50000,
                    "planType": "recurring",
                    "recurringInterval": "year"
                },
                {
                    "id": 9,
                    "orderIndex": 2,
                    "description": "Buy this plan for 3 monthly meetings, charged monthly for 4 months",
                    "amount": 2500,
                    "planType": "recurring",
                    "subscriptionDurationInMonths" : 4,
                    "recurringInterval": "month"
                }
            ]
        },
        {
            "id": 3,
            "title": "TestPlan 4",
            "description": "Lorem ipsum...",
            "coverPhoto": null,
            "termsOfUseUrl": "Lorem ipsum www.google.com",
            "privacyPolicyUrl": "www.google.com",
            "downloadUrl": "www.download.com",
            "sessionLength": 0,
            "serviceType": "download",
            "status": "Disabled",
            "plans": [
                {
                    "id": 90,
                    "orderIndex": 0,
                    "description": "Buy this plan for 2 meetings, 1 free",
                    "amount": 4000,
                    "planType": "one_time",
                    "recurringInterval": null
                },
                {
                    "id": 85,
                    "orderIndex": 1,
                    "description": "Buy this plan for year of meetings, 4 meetings per month, paid annualy, cancel anytime",
                    "amount": 50000,
                    "planType": "recurring",
                    "recurringInterval": "year"
                },
                {
                    "id": 95,
                    "orderIndex": 2,
                    "description": "Buy this plan for 3 monthly meetings, charged monthly for 4 months",
                    "amount": 2500,
                    "planType": "recurring",
                    "subscriptionDurationInMonths" : 4,
                    "recurringInterval": "month"
                }
            ]
        }
    ]
};


export const devIframeListingsCheckout = (plan) => ({
    "stripeCheckoutSessionId": "stripe_session_id",
    "stripeApiPublishableKey": "pk_test_bkOwcRohMhA7h6ywyefnAPP600gcIGexbD",
    "productPlan": {
        "id": plan.id,
        "productId": plan.productId,
        "orderIndex": plan.orderIndex,
        "description": plan.description,
        "numberOfSessions": plan.numberOfSessions,
        "amount": plan.amount,
        "isPrivate": plan.isPrivate,
        "planType": plan.planType,
        "recurringInterval": plan.recurringInterval,
        "subscriptionDurationInMonths": plan.subscriptionDurationInMonths,
        "status": plan.status
    }
});

export const devProductFromPlanId = {
    "id": 70,
    "productId": 12,
    "orderIndex": 0,
    "description": "",
    "numberOfSessions": 1,
    "amount": 38900,
    "isPrivate": false,
    "planType": "one_time",
    "recurringInterval": null,
    "subscriptionDurationInMonths": null,
    "status": "Active",
    "product": {
        "id": 12,
        "title": "Clarity Meeting",
        "description": "<p>A 2-hour session via video conference that provides an overview of your current financial picture. You\u2019ll finally understand which aspects of your personal finances are on track and what needs more attention. We\u2019ll take a deep dive into your spending and show you where your money is going.</p><p><br></p><p>You\u2019ll receive customized recommendations related to savings, cash flow, debt, and protection that are designed to build wealth, improve your money confidence, and financially prepare you for wherever life takes you.</p><p><br></p><p>You\u2019ll leave the meeting with a Wellness Plan that will strengthen your financial foundation.</p>",
        "coverPhoto": images.devDataStoreProduct,
        "serviceType": "online",
        "termsOfUseUrl": "",
        "privacyPolicyUrl": "",
        "downloadUrl": "",
        "sessionLength": 120,
        "status": "Active",
        "expert": {
            "id": 71,
            "fullName": "Megan Scipione",
            "firstName": "Megan",
            "lastName": "Scipione",
            "storeHandle": "meganscipione",
            "publicHandle": "meganscipione",
            "profilePhotoUrl": images.devDataCoachMeganAvatar
        }
    }
};

export const devQuickSubscribe = {
    "userId": 2998,
    "stripeCustomerId": "cus_IxP3iGlKPtvJdd"
};

//Can just use /forgot-password/checkout/<planId>
// Don't show user fields after auth
// Integrate forgot password flow into purchase funnel

export const devPurchaseSubscription = {
    "id": "sub_36VrPHS2vVxJMq",
    "object": "subscription",
    "application_fee_percent": null,
    "billing_cycle_anchor": 1387222772,
    "billing_thresholds": null,
    "cancel_at": null,
    "cancel_at_period_end": false,
    "canceled_at": 1445989053,
    "collection_method": "charge_automatically",
    "created": 1386790772,
    "current_period_end": 1447702772,
    "current_period_start": 1444678772,
    "customer": "cus_Ixrqsz7y2kE6KZ",
    "days_until_due": null,
    "default_payment_method": null,
    "default_source": null,
    "default_tax_rates": [],
    "discount": null,
    "ended_at": 1445989053,
    "items": {
        "object": "list",
        "data": [
            {
                "id": "si_18SfBn2eZvKYlo2C1fwOImYF",
                "object": "subscription_item",
                "billing_thresholds": null,
                "created": 1386790772,
                "metadata": {},
                "price": {
                    "id": "40",
                    "object": "price",
                    "active": true,
                    "billing_scheme": "per_unit",
                    "created": 1386694689,
                    "currency": "usd",
                    "livemode": false,
                    "lookup_key": null,
                    "metadata": {
                        "charset": "utf-8",
                        "content": "40"
                    },
                    "nickname": null,
                    "product": "prod_BTcfj5EqyqxDVn",
                    "recurring": {
                        "aggregate_usage": null,
                        "interval": "week",
                        "interval_count": 5,
                        "usage_type": "licensed"
                    },
                    "tiers_mode": null,
                    "transform_quantity": null,
                    "type": "recurring",
                    "unit_amount": 5465,
                    "unit_amount_decimal": "5465"
                },
                "quantity": 1,
                "subscription": "sub_36VrPHS2vVxJMq",
                "tax_rates": []
            }
        ],
        "has_more": false,
        "url": "/v1/subscription_items?subscription=sub_36VrPHS2vVxJMq"
    },
    "latest_invoice": null,
    "livemode": false,
    "metadata": {},
    "next_pending_invoice_item_invoice": null,
    "pause_collection": null,
    "pending_invoice_item_interval": null,
    "pending_setup_intent": null,
    "pending_update": null,
    "schedule": null,
    "start_date": 1386790772,
    "status": "active",
    "transfer_data": null,
    "trial_end": 1387222772,
    "trial_start": 1386790772
};

export const devPurchaseIntent = {
    "id": "pi_1ILy0IBPDoWM9BMenZTVbI4y",
    "object": "payment_intent",
    "amount": 100,
    "amount_capturable": 0,
    "amount_received": 0,
    "application": null,
    "application_fee_amount": null,
    "canceled_at": null,
    "cancellation_reason": null,
    "capture_method": "automatic",
    "charges": {
        "object": "list",
        "data": [],
        "has_more": false,
        "total_count": 0,
        "url": "/v1/charges?payment_intent=pi_1ILy0IBPDoWM9BMenZTVbI4y"
    },
    "client_secret": "pi_1ILy0IBPDoWM9BMenZTVbI4y_secret_ftekrEylUvAMSwrY09jBtz8tJ",
    "confirmation_method": "automatic",
    "created": 1613598974,
    "currency": "usd",
    "customer": "cus_IxsmtTFheSGvMA",
    "description": null,
    "invoice": null,
    "last_payment_error": null,
    "livemode": true,
    "metadata": {
        "is_front_end_flow": "True",
        "user_id": "3082",
        "plan_id": "15"
    },
    "next_action": null,
    "on_behalf_of": null,
    "payment_method": null,
    "payment_method_options": {
        "card": {
            "installments": null,
            "network": null,
            "request_three_d_secure": "automatic"
        }
    },
    "payment_method_types": ["card"],
    "receipt_email": null,
    "review": null,
    "setup_future_usage": null,
    "shipping": null,
    "source": null,
    "statement_descriptor": null,
    "statement_descriptor_suffix": null,
    "status": "requires_payment_method",
    "transfer_data": null,
    "transfer_group": null
};

export const devPurchaseHistoryData = [
    {
        "coachId": 1033,
        "amountPaid":100,
        "purchasedOn":"2021-03-25T15:48:31Z",
        "isSubscription":true,
        "subcriptionInterval":"year",
        "subscriptionDuration":null,
        "isActive":true,
        "isOnTheFlyInvoice": false,
        "productId": 1,
        "numberOfSessions": 4,
        "sessionLengthInMinutes": 45,
        "coverPhotoProduct": null,
        "productTitle": "Clarity Meeting",
        "productDescription": "<p>erflhbqlfjhbqwe</p>",
        "downloadUrl": "https://google.com",
        "serviceType": "online",
        "purchaseHistoryId":58,
    },
    {
        "coachId": 1032,
        "amountPaid":100,
        "purchasedOn":"2021-04-25T15:48:31Z",
        "isSubscription":true,
        "subcriptionInterval":"year",
        "subscriptionDuration":null,
        "isActive":true,
        "isOnTheFlyInvoice": false,
        "productId": 2,
        "numberOfSessions": 1,
        "sessionLengthInMinutes": 30,
        "coverPhotoProduct": images.devDataStoreProduct,
        "productTitle": "Clarity Meeting 2",
        "productDescription": "<p>erflhbqlfjhbqwe</p>",
        "downloadUrl": "https://google.com",
        "serviceType": "online",
        "purchaseHistoryId":57,
    },
    {
        "purchaseHistoryId": 504,
        "amountPaid": 100,
        "purchasedOn": "2024-01-30T19:36:14Z",
        "isCanceled": false,
        "canceledOn": null,
        "isSubscription": false,
        "subcriptionInterval": null,
        "subscriptionDuration": null,
        "isActive": false,
        "willowPlanId": null,
        "isOnTheFlyInvoice": false,
        "isCertificationProgram": true,
        "certificationProgram": {
            "id": 3,
            "name": "CERTIFIED ADVISOR FOR WOMEN\u2122",
            "designation": "CAW\u2122",
            "stripeProductId": "prod_NLn8TmpWvTRo5m",
            "stripePriceId": "price_1Mb5YaBPDoWM9BMehD4Bk77E",
            "stripePrices": [
                {
                    "price_amount": 1,
                    "price_id": "price_1MhbSIBPDoWM9BMeq4Q2vy5p"
                },
                {
                    "price_amount": 400,
                    "price_id": "price_1MbpuZBPDoWM9BMeT3xtqSFu"
                },
                {
                    "price_amount": 600,
                    "price_id": "price_1MgsM4BPDoWM9BMek42KK1Y5"
                },
                {
                    "price_amount": 800,
                    "price_id": "price_1MbpuMBPDoWM9BMeinsgHB7u"
                },
                {
                    "price_amount": 900,
                    "price_id": "price_1OCpUzBPDoWM9BMeched5UKR"
                },
                {
                    "price_amount": 1000,
                    "price_id": "price_1MgsMHBPDoWM9BMeYnAZ0mLI"
                },
                {
                    "price_amount": 1200,
                    "price_id": "price_1Mb5YaBPDoWM9BMehD4Bk77E"
                }
            ]
        }
    },
];

export const devPaymentMethodsData = {
    "object": "list",
    "data": [
        {
            "id": "pm_1IyTKnBPDoWM9BMeNhpU7YHr",
            "object": "payment_method",
            "billing_details": {
                "address": {
                    "city": null,
                    "country": null,
                    "line1": null,
                    "line2": null,
                    "postal_code": "02129",
                    "state": null
                },
                "email": "jason+expert@trustwillow.com",
                "name": "Jason Expert 44",
                "phone": "1135193519"
            },
            "card": {
                "brand": "amex",
                "checks": {
                    "address_line1_check": null,
                    "address_postal_code_check": "unavailable",
                    "cvc_check": "unavailable"
                },
                "country": "US",
                "exp_month": 11,
                "exp_year": 2024,
                "fingerprint": "O4uyp1NZ4iHbrnSS",
                "funding": "credit",
                "generated_from": null,
                "last4": "1011",
                "networks": {
                    "available": [
                        "amex"
                    ],
                    "preferred": null
                },
                "three_d_secure_usage": {
                    "supported": true
                },
                "wallet": null
            },
            "created": 1622775874,
            "customer": "cus_JCUvZZWYsbDHjD",
            "livemode": true,
            "metadata": {},
            "type": "card"
        },
        {
            "id": "pm_1IyTFrBPDoWM9BMeHfKjTfN4",
            "object": "payment_method",
            "billing_details": {
                "address": {
                    "city": null,
                    "country": null,
                    "line1": null,
                    "line2": null,
                    "postal_code": "02129",
                    "state": null
                },
                "email": "jason+expert@trustwillow.com",
                "name": "Jason Aronson",
                "phone": "1135193519"
            },
            "card": {
                "brand": "amex",
                "checks": {
                    "address_line1_check": null,
                    "address_postal_code_check": "unchecked",
                    "cvc_check": "unchecked"
                },
                "country": "US",
                "exp_month": 11,
                "exp_year": 2024,
                "fingerprint": "O4uyp1NZ4iHbrnSS",
                "funding": "credit",
                "generated_from": null,
                "last4": "1011",
                "networks": {
                    "available": [
                        "amex"
                    ],
                    "preferred": null
                },
                "three_d_secure_usage": {
                    "supported": true
                },
                "wallet": null
            },
            "created": 1622775567,
            "customer": "cus_JCUvZZWYsbDHjD",
            "livemode": true,
            "metadata": {},
            "type": "card"
        }
    ],
    "has_more": false,
    "url": "/v1/payment_methods"
};