import React from 'react';
import { withRouter } from 'react-router-dom';

/* Containers */
import Feedback from '../../feedback/containers/feedback';

class InsightQuestions extends React.Component {

    back = () => {
        this.props.history.goBack();
    };

    render() {
        return(
            <Feedback
                back={this.back}
                inputTitle={"LOOKING FOR OTHER CONTENT?"}
                inputPlaceholder={"Let us know how we can help you..."}
                inputButtonLabel={"Send"}
                successTitle={"MESSAGE SENT!"}
                successSubtitle={"Thanks for sharing your question. A member of our team will get back to you."}
                successButtonLabel={"Close"}
            />
        )
    }
}

export default withRouter(InsightQuestions);