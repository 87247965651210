import React from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {colors} from "../../../common/components/colors";
import ReactQuill from "react-quill";
import { QuillSlim } from '../../../common/components/richtexteditor';
import {v4 as uuid4} from "uuid";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

/* Containers */


/* Middleware */
import {tryGetIntroduceToken} from "../middleware/introducelinks";
import {tryGetCoachesAssignedToProspect} from "../middleware/coaching";

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FontBody16, FontBody14} from "../../../common/components/fonts";
import {
    CheckOutlined,
    CopyOutlined,
} from "@ant-design/icons";

const Button = styled.div`
    color: ${colors.action100};
    cursor: pointer;
`;
const ButtonDone = styled.div`
    color: ${colors.progress100};
`;
const FitContent = styled.div`
    text-align: center;
    padding-top: 15px;
    padding-bottom: 10px;
`;
const QuillCard = styled.div`
    .ql-container {
        margin-top: 10px;
        word-break: break-all;
    }
    & .ql-editor {
        background-color: ${colors.border70};
        border: 1px solid ${colors.primary30};
        border-radius: 4px;
        word-break: break-all;
    }
`;

const initState = {
    "isLoading": true,
    "url": null,
    "copySuccess": false,
    "token": null
};

class IntroduceLink extends React.Component {
    state=initState;

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.coachSelected !== this.props.coachSelected || prevProps.client !== this.props.client) {
            return this.init();
        }
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    init = async () => {
        const token = await this.props.tryGetIntroduceToken(this.props.prospectId)
        const coaches = await this.props.tryGetCoachesAssignedToProspect(this.props.prospectId, "return");
        const url = "https://"+env.URL.HOSTNAME+"/redirect/get-started"
        + "?prospectId=" + this.props.prospectId
        + "&wmcId=" + this.props.user.get("wmc").get("id")
        + "&token=" + token.token
        + "&coachHandle=" + coaches.filter(a => {return(a.coachId === this.props.coachId)})[0].publicHandle;
        this.setState({"token": token.token, "url": url, "isLoading": false})
    };

    handleCopy = () => {
        let tempInput = document.createElement("input");
        tempInput.value = this.state.url;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        this.setState({"copySuccess": true});
        this.timeout = setTimeout(() => {this.setState({'copySuccess': false})}, 3000);
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            if(this.state.token === null) {
                return(
                    <FontBody14>{this.props.clientName} has already been introduced to {this.props.coachFirst}'s and has viewed their profile on Willow.</FontBody14>
                )
            } else {
                return(
                    <>
                        <FontBody14>Send this link to {this.props.clientName} so they can get started with {this.props.coachFirst}</FontBody14>
                        <QuillCard>
                            <ReactQuill
                                id={uuid4()}
                                readOnly={true}
                                theme={null}
                                value={this.state.url}
                                bounds={'.app'}
                            />
                        </QuillCard>
                        {!this.state.copySuccess
                            ? <FitContent><span onClick={this.handleCopy}><Button><FontBody16><CopyOutlined />&nbsp;Copy</FontBody16></Button></span></FitContent>
                            : <FitContent><span><ButtonDone><FontBody16><CheckOutlined /> Copied!</FontBody16></ButtonDone></span></FitContent>
                        }
                    </>
                )
            }
        }
    }
}

const mapStateToProps = state => ({
    client: state.enterprise.clientsDetail.get("client"),
    user: state.common.user
});
const mapDispatchToProps = dispatch => ({
    tryGetIntroduceToken: (prospectId) => dispatch(tryGetIntroduceToken(prospectId)),
    tryGetCoachesAssignedToProspect: (prospectId, storeOrReturn) => dispatch(tryGetCoachesAssignedToProspect(prospectId, storeOrReturn))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IntroduceLink));
