import { combineReducers } from 'redux';

/* Common */
import profileReducer from './store/profile';

const shopReducer = combineReducers({
    profile: profileReducer,
});

export default shopReducer;
