import React, {useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useDispatch} from 'react-redux'
import styled from 'styled-components';

/* Components */
import {tryGetMyRoundtables} from "../middleware/roundtables";

const Img = styled.img`
  width: 100%;
`;

function FirmRoundtablesList({history}) {
    const [myRoundtables, setMyRoundtables] = useState([])
    const dispatch = useDispatch();

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        const res = await dispatch(tryGetMyRoundtables())
        setMyRoundtables(res)
    }

    return (
        <>
            <b>My Roundtables</b>
            <br />
            <br />
            {myRoundtables.map(r => (
                <div onClick={() => {history.push("/my-roundtables/"+r.id)}}>
                    <Img src={r.coverPhoto} alt={""}/>
                    <div>{r.title}</div>
                    <div>{r.time}</div>
                    <br />
                    <br />
                </div>
            ))}
        </>
    );
}

export default withRouter(FirmRoundtablesList);

