import styled from "styled-components";
import React from "react";

const Spacer = styled.div`
    height: 20px;
    width: 100%;
`;

const ElementSpacer = ({text, style}) => (
    <Spacer style={style}>{text}</Spacer>
);

export default ElementSpacer;