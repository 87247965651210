import React from "react";
import styled from "styled-components";
import ReactQuill from "react-quill";
import {v4 as uuid4} from "uuid";

/* Components */
import {colors} from "../../../common/components/colors";
import {FontSubtitle24, FontBody14} from "../../../common/components/fonts";
import {CloudDownloadOutlined, LeftOutlined} from "@ant-design/icons";
import {QuillSlimNoHeight} from "../../../common/components/richtexteditor";

/* Utils */
// import { disableIdScrolling, enableIdScrolling } from "../../../common/utils/scrolling";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const DownloadsCard = styled.div`
    ${props => props.product !== "download"} {
        background-color: ${colors.primary100};
        color: ${colors.white};
    }
    ${props => props.product !== "offline"} {
        background-color: ${colors.backgroundColor3};
    }
    //bottom: 0;
    //display: flex;
    //flex-direction: column;
    //overflow-y: auto;
    //position: fixed;
    //right: 0;
    //top: 0;
    //width: 500px;
    //z-index: 11;
    //@media screen and (max-width: 748px) {
    //    left: 0;
    //    width: auto;
    //}
`;
const BackSection = styled.div`
    display: flex;
    justify-content: flex-start;
`;
const Back = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 5px;
`;
const Profile = styled.div`
    align-items: start;
    display: flex;
    flex-direction: column;
    gap: 5px;
    & > div {
        letter-spacing: normal;
    }
`;
const Image = styled.img`
    margin: 20px 0px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    max-height: 360px;
`;
const Spacer = styled.div`
    height: 40px;
`;
const DownloadLink = styled.a`
    display: inline-block;
    vertical-align: top;
    width: fit-content;
    text-decoration: none;
    color: ${colors.action100};
    cursor: pointer;
    margin-bottom: 20px;
`;

const ProfileCardTop = styled.div`
    padding: 25px 25px 30px 25px;
    position: relative;
`;
const BodySection = styled.div`
    padding: 0px 40px;
    position: relative;
    margin-bottom: 150px;
`;
const ForceBackground = styled.div`     
    & .ql-editor {  
        font-family: ${env.STYLES.FONTS.body.fontFamily};
        font-size: 16px;
    }
    & > div {
        background-color: ${props => props.backgroundColor};
    }
`;

const colorPicker = (serviceType) => {
    if(serviceType === "download") {
        return colors.primary100;
    } else if(serviceType === "offline") {
        return colors.backgroundColor3;
    } else {
        return colors.primary100;
    }
};

export class MemberOtherProducts extends React.Component {
    static defaultProps = {
        // 'parentId': '',
        "sectionTitle": ""
    };

    // componentDidMount() {
    //     disableIdScrolling(this.props.parentId);
    // }
    //
    // componentWillUnmount() {
    //     enableIdScrolling(this.props.parentId);
    // }

    render() {
        return (
            <DownloadsCard product={this.props.product.serviceType}>
                {/*<ProfileCardTop>*/}
                {/*    <BackSection>*/}
                {/*        <Back onClick={() => this.props.onGoBack()}><LeftOutlined/></Back>*/}
                {/*    </BackSection>*/}
                {/*</ProfileCardTop>*/}
                {/*<BodySection>*/}
                    {/*<Profile>*/}
                    {/*    <FontSubtitle24>{this.props.product.productTitle}</FontSubtitle24>*/}
                    {/*    <FontBody14>{this.props.coach.firstName + " " + this.props.coach.lastName}</FontBody14>*/}
                    {/*</Profile>*/}
                    {/*<FontHeader21>{this.props.sectionTitle}</FontHeader21>*/}
                    {this.props.product.coverPhotoProduct !== null
                        ? <Image src={this.props.product.coverPhotoProduct} alt={""}/>
                        : <Spacer />
                    }
                    {this.props.product.serviceType === "download" &&
                        <DownloadLink target={"_blank"} href={this.props.product.downloadUrl}><CloudDownloadOutlined />&nbsp;Download</DownloadLink>
                    }
                    <ForceBackground backgroundColor={colorPicker(this.props.product.serviceType)}>
                        <QuillSlimNoHeight bodyBorderColor={colorPicker(this.props.product.serviceType)}>
                            <ReactQuill
                                id={uuid4()}
                                readOnly={true}
                                theme={null}
                                value={this.props.product.productDescription}
                                bounds={'.app'}
                            />
                        </QuillSlimNoHeight>
                    </ForceBackground>
                {/*</BodySection>*/}
            </DownloadsCard>
        )
    }
}
