import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {
    setListingsAllFetching,
    setListingsAll,
    setListingsOneFetching,
    setListingsOne,
    setListingOneDeleting,
    setListingOneDeleted,
    setListingAllReordering,
    setListingAllReordered,
    setListingOneUpdating,
    setListingOneUpdated,
    setListingsOneCreating,
    setListingsOneCreated,
    setListingOnePlanAdding,
    setListingOnePlanDeleting
} from "../store/listings";

/* Static */
import {
    devEnterpriseListings,
    devEnterpriseListing,
    devEnterpriseListingsOrder,
    devEnterpriseListingUpdated,
    devEnterpriseListingCreated
} from '../static/devdata';

/* Middleware */
import {tryGetListingsAll as tryGetListingsAllIframe} from "../../../iframes/listings/middleware/listings";

/* Utils */
import {testPhoto, removeOldImageUrl} from '../utils/images';

export const tryGetListingsAll = () => {
    return async dispatch => {
        dispatch(setListingsAllFetching(true));
        const listings = await axiosReq('apiv1/coach/settings/expert-products', "GET", {}, devEnterpriseListings);
        const listingAdj = await Promise.all(listings.financialCoachingPlans.map(async l => {
            let numberOfSessions = null;
            await Promise.all(l.plans.map(p => {
                if(p.hasOwnProperty("numberOfSessions")) {
                    numberOfSessions = p.numberOfSessions;
                }
                Promise.resolve();
            }));
            return Promise.resolve({...l, "numberOfSessions": numberOfSessions});
        }));
        await dispatch(setListingsAll(listingAdj));
        return dispatch(setListingsAllFetching(false));
    }
};

export const tryGetListingsOne = (listingId) => {
    return async dispatch => {
        dispatch(setListingsOneFetching(true));
        const listing = await axiosReq('apiv1/coach/settings/expert-products/'+listingId, "GET", {}, devEnterpriseListing);
        let numberOfSessions = null;
        await Promise.all(listing.plans.map(p => {
            if(p.hasOwnProperty("numberOfSessions")) {
                numberOfSessions = p.numberOfSessions;
            }
            Promise.resolve();
        }));
        await dispatch(setListingsOne({...listing, "numberOfSessions": numberOfSessions}));
        return dispatch(setListingsOneFetching(false));
    }
};


export const tryCreateListing = (data) => {
    return async (dispatch, getState) => {
        dispatch(setListingsOneCreating(true));
        let photo;
        let request;
        if(data.coverPhotoBase64 === "") {
            request = data;
        } else {
            photo = await testPhoto(data.coverPhotoBase64, true);
            request = photo === null ? data : {...data, coverPhotoBase64: photo};
        }
        const listing = await axiosReq('apiv1/coach/settings/expert-products', "POST", request, devEnterpriseListingCreated(data, getState().enterprise.services.listings.get("listingsAll")));
        await dispatch(setListingsOneCreated(listing));
        dispatch(tryGetListingsAllIframe(getState().enterprise.services.expertProfile.get("expertProfile").get("id")));
        dispatch(setListingsOneCreating(false));
        return listing;
    }
};

// export const tryReorderListings = (listingIds) => {
//     return async (dispatch, getState) => {
//         dispatch(setListingAllReordering(true));
//         const listings = await axiosReq('apiv1/coach/settings/financial-coaching-plans/order', "POST", {"ids": listingIds}, devEnterpriseListingsOrder(listingIds, getState().enterprise.services.listings.get("listingsAll")));
//         await dispatch(setListingAllReordered(listings));
//         return dispatch(setListingAllReordering(false));
//     }
// };

export const tryUpdateListingStatus = (listingId, data) => {
    return async (dispatch, getState) => {
        dispatch(setListingOneDeleting(true));
        await axiosReq('apiv1/coach/settings/expert-products/'+listingId+'/change-status', "PUT", data, {});
        await dispatch(setListingOneUpdated(listingId, data));
        dispatch(tryGetListingsAllIframe(getState().enterprise.services.expertProfile.get("expertProfile").get("id")));
        return dispatch(setListingOneDeleting(false));
    }
};

export const tryUpdateListing = (listingId, data) => {
    return async (dispatch, getState) => {
        dispatch(setListingOneUpdating(true));
        let photo;
        let request;
        if(data.coverPhotoBase64 === "") {
            request = data;
        } else {
            photo = await testPhoto(data.coverPhotoBase64, data.photoChanged);
            request = photo === null ? data : {...data, coverPhotoBase64: photo};
        }
        const listing = await axiosReq('apiv1/coach/settings/expert-products/'+listingId, "PUT", request, devEnterpriseListingUpdated(data));
        await dispatch(setListingOneUpdated(listingId, data));
        dispatch(tryGetListingsAllIframe(getState().enterprise.services.expertProfile.get("expertProfile").get("id")));
        return dispatch(setListingOneUpdating(false));
    }
};

export const tryUpdateListingAddPlan = (listingId, data) => {
    return async dispatch => {
        dispatch(setListingOnePlanAdding(true));
        await axiosReq('apiv1/coach/settings/expert-products/'+listingId+'/add-plan', "POST", data, {});
        return dispatch(setListingOnePlanAdding(false));
    }
};

export const tryUpdateListingDeletePlan = (planId, data) => {
    return async dispatch => {
        dispatch(setListingOnePlanDeleting(true));
        await axiosReq('apiv1/coach/settings/expert-product-plans/plan-status/'+planId, "PUT", data, {});
        return dispatch(setListingOnePlanDeleting(false));
    }
};