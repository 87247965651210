import React from "react";
import styled from "styled-components";

/* Components */
import {colors} from "../../../common/components/colors";
import {FontSubtitle28, FontBody16, FontHeader16} from "../../../common/components/fonts";

export const SettingsCard = styled.div`
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    background-color: ${colors.white};
    border-radius: 7px;
    border: 1px solid ${colors.primary30};
    padding: 30px;
    color: ${colors.primary100};
    max-width: ${props => props.maxWidth};
    margin: 0px auto 40px auto;
    @media screen and (max-width: 748px) {
        margin: 40px auto 0px auto;
    }
    @media screen and (max-width: 460px) {
        margin: 20px auto 0px auto;
        padding: 30px 20px;
    }
    ${props => props.active !== true} {
        &:hover {
            -webkit-box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
        }
    }
    
`;

export const Bar = styled.div`
    width: 100%;
    height: 1px;
    border-bottom: 1px solid ${colors.secondary30};
`;
export const ModuleTitle = styled.div`
    display: flex;
    cursor: pointer;
    flex-direction: row;
    gap: 30px;
    margin: 50px 10px 40px 10px;
`;
export const Circle = styled.div`
    width: 75px;
    height: 75px;
    min-width: 75px;
    border-radius: 75px;
    background-color: ${colors.backgroundColor3};
    color: ${colors.white};
    -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    display: flex;
`;
export const CircleWorkingOn = styled.div`
    width: 75px;
    height: 75px;
    min-width: 75px;
    border-radius: 100%;
    position: absolute;
    padding: 8px;
    top: -8px;
    left: -8px;
    background: 
        linear-gradient(transparent, transparent) content-box,
        linear-gradient(calc( ((18/5) * ${props => props.percentCompleted} - 90)*1deg), ${colors.backgroundColorProgressPartial} 50%, transparent 0) 0/min(100%,(50 - ${props => props.percentCompleted})*100%),
        linear-gradient(calc( ((18/5) * ${props => props.percentCompleted} - 90)*1deg), transparent 50%, ${colors.backgroundColor3} 0) 0/min(100%,(${props => props.percentCompleted} - 50)*100%),
        linear-gradient(to right, ${colors.backgroundColorProgressPartial} 50%, ${colors.backgroundColor3} 0);
    z-index: -1;
    transform: rotate(-38deg);
`;
export const CircleIncomplete = styled.div`
    position: relative;
    width: 75px;
    min-width: 75px;
    height: calc(75px - 23px);
    border-radius: 75px;
    background-color: ${colors.backgroundColorProgressInactive};
    text-align: center;
    padding: 11px 0px 12px 0px;
`;
export const Text = styled.div`   
    ${props => props.active !== true} {
        overflow: revert;
        display: revert;
        -webkit-line-clamp: revert;
        -webkit-box-orient: revert;
    }
    ${props => props.active !== false} {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
`;

export const Image = styled.img`
    height: 175px;
    width: 100%;
    vertical-align: top;
    display: inline-block;
    object-fit: cover;
    border-radius: 30px 0px 30px 0px;
`;


export const ImageArticle = styled.img`
    height: 350px;
    width: 100%;
    display: inline-block;
    object-fit: cover;
`;
export const BookmarkIcon = styled.img`
    height: 24px;
    width: 24px;
    vertical-align: top;
    object-fit: cover;
`;
export const ContentTypeIcon = styled.img`
    height: 24px;
    width: 24px;
    padding-top: 5px;
    vertical-align: top;
    object-fit: cover;
`;
export const VideoStartStopIcon = styled.img`
    width: 91px;
    height: 91px;
    cursor: pointer;
`;
export const Icon = styled.div`
    display: inline-block;
    vertical-align: top;
    ${props => props.active !== true} {
        color: ${colors.white};
    }
    ${props => props.active !== false} {
        color: ${colors.action100};
    }
    & svg {
        width: 16px;
        height: 16px;
        margin-top: 3px;
    }
    @media screen and (max-width: 460px) {
        display: none;
    }

`;

export const InlineBlockBtn = styled.div`
    cursor: pointer;
    text-align: center;
    width: fit-content;
    margin: 0px 0px 0px auto;
    & > div {
        width: calc(128px - 44px);
        margin: 30px 20px 0px auto;
    }
`;

export const OpenLink = styled.div`
    cursor: pointer;
`;
export const ContentBodySection = styled.div`
    padding: 0px 30px;
    width: calc(100% - 60px);
    margin-bottom: 20px;
`;

export const InlineBlock = styled.div`
    display: inline-block;
`;

export const Background = styled.div`
    background-color: rgba(0,0,0,0.6);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 12;
`;
export const VideoContainer = styled.video`
    z-index: 13;
    width: 80%;
    height: auto;
    @media screen and (max-width: 460px) {
        width: 100%;
    }
`;

export const PageTitle = styled(FontSubtitle28)`
    text-align: center;
    margin-bottom: 10px;
`;
export const Flex = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 11;
`;
export const VideoHotspot = styled.div`
    width: 100%;
    position: absolute;
    left: 0;
    top: 170px;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Subtitle = styled.div`
    color: ${colors.secondary100};
    margin-bottom: 20px;
`;

export const SpaceBetween = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const IntroSection = styled.div`
    background-color: ${colors.white};
    border-radius: 0px 90px 0px 0px;
    margin-top: -90px;
    padding: 25px 50px 50px 30px;
    width: calc(100% - 80px);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: ${colors.secondary100};
    &::before {
        content: "";
        position: absolute;
        background-color: transparent;
        top: -180px;
        height: 180px;
        width: 180px;
        left: 0px;
        border-top-left-radius: 90px;
        box-shadow: 0 -90px 0 0 ${colors.white};
        transform: rotate(180deg);
        transform: scaleY(-1);
    }
`;
export const Bookmark = styled.div`
    width: 24px;
    align-items: center;
    display: flex;
    height: auto;
    justify-content: center;
    cursor: pointer;
`;

export const ContentTitle = styled.div`
    position: relative;
    flex-grow: 1;
    z-index: 1;
    .content-editable {
        padding: 5px;
        cursor: text;
     }
    .content-editable:focus {
       outline: 2px solid ${colors.border100};
    }
    [contenteditable=true]:empty:before {
        content: attr(placeholder);
        color: #aaa;
    }
`;
export const AuthorDateSection = styled.div`
    flex-grow: 1;
`;
export const BackTop = styled.div`
    color: ${colors.secondary70};
    cursor: pointer;
    position: relative;
    z-index: 25;
    text-align: left;
    display: block;
    width: fit-content;
`;
export const TitleSection = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
`;

export const TypeSection = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
`;

export const LoadingCenter = styled.div`
    margin: 60px auto;
`;

export const BulletArea = styled.div`
    margin: 0px 0px 0px 40px;
    display: flex;
    gap: 25px;
    flex-direction: column;
`;

export const Bullet = styled.div`
    padding-right: 10px;
`;

export const Container = styled.div`
    width: 32%;
    margin: 0px auto;
    row-gap: 20px;
    gap: 30px;
    flex-direction: column;
    display: inline-flex;
    border: 10px solid black;
    border-radius: 25px;
    align-content: space-between;
    height: 45vw;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 0;
    }
`;

export const BackSection = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 20px;
    margin-left: 20px;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    color: ${colors.white};
    z-index: 10;
`;

export const Back = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    padding-right: 10px;
`;

export const BackSectionCourse = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 10px;
`;

export const BackCourse = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
`;
export const Pointer = styled.div`
    cursor: pointer;
    margin: 60px auto 80px auto;
    text-align: center;
    & > div {
        width: fit-content;
        text-align: center;
    }
`;

export const CourseSummaryContainer = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
    padding: 30px;
    width: calc(100% - 60px);
    background-color: ${colors.backgroundColor1};
    border-radius: 30px 0px 30px 0px
`;

export const ChevronLeftRight = styled.img`
    padding: 3.5px 7.5px 5.5px 7.5px;
    vertical-align: top;
    display: block;
    object-fit: contain;
`;
export const ChevronUpDown = styled.img`
    padding: 7.5px 4.5px 7.5px 4.5px;
    vertical-align: top;
    display: block;
    object-fit: contain;
`;
export const CheckMark = styled.img`
    vertical-align: top;
    display: block;
    object-fit: contain;
    align-self: center;
    justify-content: center;
    display: inline-flex;
    height: 22px;
    width: 30px;
    flex-grow: 1;
`;
export const TaskProgressCircle = styled.div`
    position: relative;
    ${props => props.showLineDown !== true} {
        &:before {
            content: "";
            display: block;
            height: calc(100% + 50px);
            position: absolute;
            left: 35px;
            top: 0px;
            transform: rotate(180deg);
            z-index: -1;
            ${props => props.lineStyleSolid !== true} {
                border-left: 2px solid ${colors.action100};
            }
            ${props => props.lineStyleSolid !== false} {
                border-left: 2px dashed ${colors.action100};
            }
        }
    }
`;
export const ProgressCircle = styled.div`
    position: relative;
    ${props => props.showLineDown !== true} {
        &:before {
            content: "";
            display: block;
            height: calc(100% + 26px);
            position: absolute;
            left: 7px;
            top: 4px;
            transform: rotate(180deg);
            z-index: -1;
            ${props => props.lineStyleSolid !== true} {
                border-left: 2px solid ${colors.action100};
            }
            ${props => props.lineStyleSolid !== false} {
                border-left: 2px dashed ${colors.action100};
            }
        }
    }
`;
export const CompleteBox = styled(FontHeader16)`
    margin-top: 30px;
    padding: 10px 25px;
    background-color: ${colors.backgroundColor2};
    color: ${colors.white};
    border-radius: 30px;
    margin-left: 20px;
`;
export const ProgressCircleIcon = styled.img`
    object-fit: contain;
    margin-top: 4px;
`;
export const Grow = styled.div`
    flex-grow: 1;
    display: inline-flex;
    align-items: center;
`;
export const GrowTopAlign = styled(FontBody16)`
    flex-grow: 1;
`;
export const FlexBullet = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;