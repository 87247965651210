import {images} from '../../../common/components/images';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

export const devCheckoutProducts = {
    "coachingProducts": [
        {
            "id": 63,
            "title": "TestPlan 2",
            "description": "Lorem ipsum... Lorem ipsum... ipsum... Lorem ipsum... ipsum... Lorem ipsum... ipsum... Lorem ipsum... ipsum... Lorem ipsum... ipsum... Lorem ipsum... ipsum... Lorem ipsum...",
            "coverPhoto": images.devDataStoreProduct,
            "termsOfUseUrl": "https://"+env.URL.DOMAIN+"/terms-and-conditions/",
            "privacyPolicyUrl": "https://"+env.URL.DOMAIN+"/privacy/",
            "downloadUrl": "www.download.com",
            "sessionLength": 15,
            "serviceType": "online",
            "status": "Active",
            "plans": [
                {
                    "id": 4,
                    "orderIndex": 0,
                    "description": "Buy this plan for 2 meetings, 1 free",
                    "amount": 4000,
                    "planType": "one_time",
                    "subscriptionDurationInMonths" : null,
                    "recurringInterval": null
                },
                // {
                //     "id": 5,
                //     "orderIndex": 1,
                //     "description": "Buy this plan for year of meetings, 4 meetings per month, paid annualy, cancel anytime",
                //     "amount": 50000,
                //     "planType": "recurring",
                //     "subscriptionDurationInMonths" : null,
                //     "recurringInterval": "year"
                // },
                // {
                //     "id": 6,
                //     "orderIndex": 2,
                //     "description": "Buy this plan for 3 monthly meetings, charged monthly for 4 months",
                //     "amount": 2500,
                //     "planType": "recurring",
                //     "subscriptionDurationInMonths" : 4,
                //     "recurringInterval": "month"
                // }
            ]
        },
        {
            "id": 9,
            "title": "TestPlan 3",
            "description": "Lorem ipsum...",
            "coverPhoto": images.devDataStoreProduct,
            "termsOfUseUrl": "Lorem ipsum www.google.com",
            "privacyPolicyUrl": "www.google.com",
            "downloadUrl": "www.download.com",
            "sessionLength": 60,
            "serviceType": "offline",
            "status": "Disabled",
            "plans": [
                {
                    "id": 7,
                    "orderIndex": 0,
                    "description": "Buy this plan for 2 meetings, 1 free",
                    "amount": 4000,
                    "planType": "one_time",
                    "subscriptionDurationInMonths" : null,
                    "recurringInterval": null
                },
                {
                    "id": 8,
                    "orderIndex": 1,
                    "description": "Buy this plan for year of meetings, 4 meetings per month, paid annualy, cancel anytime",
                    "amount": 50000,
                    "planType": "recurring",
                    "subscriptionDurationInMonths" : null,
                    "recurringInterval": "year"
                },
                {
                    "id": 9,
                    "orderIndex": 2,
                    "description": "Buy this plan for 3 monthly meetings, charged monthly for 4 months",
                    "amount": 2500,
                    "planType": "recurring",
                    "subscriptionDurationInMonths" : 4,
                    "recurringInterval": "month"
                }
            ]
        },
        // {
        //     "id": 3,
        //     "title": "Clarity Meeting",
        //     "description": "<p>A 2-hour session via video conference that provides an overview of your current financial picture. You’ll finally understand which aspects of your personal finances are on track and what needs more attention. We’ll take a deep dive into your spending and show you where your money is going.</p><br/><p>You’ll receive customized recommendations related to savings, cash flow, debt, and protection that are designed to build wealth, improve your money confidence, and financially prepare you for wherever life takes you.</p><br/><p>You’ll leave the meeting with a Wellness Plan that will strengthen your financial foundation.</p>",
        //     "coverPhoto": null, // images.devDataStorePortfolio,
        //     "termsOfUseUrl": "",
        //     "privacyPolicyUrl": "",
        //     "downloadUrl": "",
        //     "sessionLength": 120,
        //     "serviceType": "online",
        //     "status": "Active",
        //     "plans": [
        //         {
        //             "id": 90,
        //             "orderIndex": 0,
        //             "description": "",
        //             "amount": 38900,
        //             "planType": "one_time",
        //             "subscriptionDurationInMonths" : null,
        //             "recurringInterval": null
        //         }
        //     ]
        // },
        // {
        //     "id": 4,
        //     "title": "Clarity Meeting",
        //     "description": "<p>A 2-hour session via video conference that provides an overview of your current financial picture. You’ll finally understand which aspects of your personal finances are on track and what needs more attention. We’ll take a deep dive into your spending and show you where your money is going.</p><br/><p>You’ll receive customized recommendations related to savings, cash flow, debt, and protection that are designed to build wealth, improve your money confidence, and financially prepare you for wherever life takes you.</p><br/><p>You’ll leave the meeting with a Wellness Plan that will strengthen your financial foundation.</p>",
        //     "coverPhoto": null, //images.devDataStorePortfolio,
        //     "termsOfUseUrl": "",
        //     "privacyPolicyUrl": "",
        //     "downloadUrl": "",
        //     "sessionLength": 120,
        //     "serviceType": "online",
        //     "status": "Active",
        //     "plans": [
        //         {
        //             "id": 70,
        //             "orderIndex": 0,
        //             "description": "",
        //             "amount": 38900,
        //             "planType": "one_time",
        //             "subscriptionDurationInMonths" : null,
        //             "recurringInterval": null
        //         }
        //     ]
        // },
        // {
        //     "id": 5,
        //     "title": "Clarity Meeting",
        //     "description": "<p>A 2-hour session via video conference that provides an overview of your current financial picture. You’ll finally understand which aspects of your personal finances are on track and what needs more attention. We’ll take a deep dive into your spending and show you where your money is going.</p><br/><p>You’ll receive customized recommendations related to savings, cash flow, debt, and protection that are designed to build wealth, improve your money confidence, and financially prepare you for wherever life takes you.</p><br/><p>You’ll leave the meeting with a Wellness Plan that will strengthen your financial foundation.</p>",
        //     "coverPhoto": images.devDataStorePortfolio,
        //     "termsOfUseUrl": "",
        //     "privacyPolicyUrl": "",
        //     "downloadUrl": "",
        //     "sessionLength": 120,
        //     "serviceType": "online",
        //     "status": "Active",
        //     "plans": [
        //         {
        //             "id": 70,
        //             "orderIndex": 0,
        //             "description": "",
        //             "amount": 38900,
        //             "planType": "one_time",
        //             "subscriptionDurationInMonths" : null,
        //             "recurringInterval": null
        //         }
        //     ]
        // },
        {
            "id": 6,
            "title": "Clarity Meeting",
            "description": "<p>A 2-hour session via video conference that provides an overview of your current financial picture. You’ll finally understand which aspects of your personal finances are on track and what needs more attention. We’ll take a deep dive into your spending and show you where your money is going.</p><br/><p>You’ll receive customized recommendations related to savings, cash flow, debt, and protection that are designed to build wealth, improve your money confidence, and financially prepare you for wherever life takes you.</p><br/><p>You’ll leave the meeting with a Wellness Plan that will strengthen your financial foundation.</p>",
            "coverPhoto": images.devDataStorePortfolio,
            "termsOfUseUrl": "",
            "privacyPolicyUrl": "",
            "downloadUrl": "",
            "sessionLength": 120,
            "serviceType": "online",
            "status": "Active",
            "plans": [
                {
                    "id": 70,
                    "orderIndex": 0,
                    "description": "",
                    "amount": 38900,
                    "planType": "one_time",
                    "subscriptionDurationInMonths" : null,
                    "recurringInterval": null
                }
            ]
        }
    ]
};

export const devPurchaseSubscription = {
    "id": "sub_36VrPHS2vVxJMq",
    "object": "subscription",
    "application_fee_percent": null,
    "billing_cycle_anchor": 1387222772,
    "billing_thresholds": null,
    "cancel_at": null,
    "cancel_at_period_end": false,
    "canceled_at": 1445989053,
    "collection_method": "charge_automatically",
    "created": 1386790772,
    "current_period_end": 1447702772,
    "current_period_start": 1444678772,
    "customer": "cus_Ixrqsz7y2kE6KZ",
    "days_until_due": null,
    "default_payment_method": null,
    "default_source": null,
    "default_tax_rates": [],
    "discount": null,
    "ended_at": 1445989053,
    "items": {
        "object": "list",
        "data": [
            {
                "id": "si_18SfBn2eZvKYlo2C1fwOImYF",
                "object": "subscription_item",
                "billing_thresholds": null,
                "created": 1386790772,
                "metadata": {},
                "price": {
                    "id": "40",
                    "object": "price",
                    "active": true,
                    "billing_scheme": "per_unit",
                    "created": 1386694689,
                    "currency": "usd",
                    "livemode": false,
                    "lookup_key": null,
                    "metadata": {
                        "charset": "utf-8",
                        "content": "40"
                    },
                    "nickname": null,
                    "product": "prod_BTcfj5EqyqxDVn",
                    "recurring": {
                        "aggregate_usage": null,
                        "interval": "week",
                        "interval_count": 5,
                        "usage_type": "licensed"
                    },
                    "tiers_mode": null,
                    "transform_quantity": null,
                    "type": "recurring",
                    "unit_amount": 5465,
                    "unit_amount_decimal": "5465"
                },
                "quantity": 1,
                "subscription": "sub_36VrPHS2vVxJMq",
                "tax_rates": []
            }
        ],
        "has_more": false,
        "url": "/v1/subscription_items?subscription=sub_36VrPHS2vVxJMq"
    },
    "latest_invoice": null,
    "livemode": false,
    "metadata": {},
    "next_pending_invoice_item_invoice": null,
    "pause_collection": null,
    "pending_invoice_item_interval": null,
    "pending_setup_intent": null,
    "pending_update": null,
    "schedule": null,
    "start_date": 1386790772,
    "status": "active",
    "transfer_data": null,
    "trial_end": 1387222772,
    "trial_start": 1386790772
};

export const devPurchaseIntent = {
    "id": "pi_1ILy0IBPDoWM9BMenZTVbI4y",
    "object": "payment_intent",
    "amount": 100,
    "amount_capturable": 0,
    "amount_received": 0,
    "application": null,
    "application_fee_amount": null,
    "canceled_at": null,
    "cancellation_reason": null,
    "capture_method": "automatic",
    "charges": {
        "object": "list",
        "data": [],
        "has_more": false,
        "total_count": 0,
        "url": "/v1/charges?payment_intent=pi_1ILy0IBPDoWM9BMenZTVbI4y"
    },
    "client_secret": "pi_1ILy0IBPDoWM9BMenZTVbI4y_secret_ftekrEylUvAMSwrY09jBtz8tJ",
    "confirmation_method": "automatic",
    "created": 1613598974,
    "currency": "usd",
    "customer": "cus_IxsmtTFheSGvMA",
    "description": null,
    "invoice": null,
    "last_payment_error": null,
    "livemode": true,
    "metadata": {
        "is_front_end_flow": "True",
        "user_id": "3082",
        "plan_id": "15"
    },
    "next_action": null,
    "on_behalf_of": null,
    "payment_method": null,
    "payment_method_options": {
        "card": {
            "installments": null,
            "network": null,
            "request_three_d_secure": "automatic"
        }
    },
    "payment_method_types": ["card"],
    "receipt_email": null,
    "review": null,
    "setup_future_usage": null,
    "shipping": null,
    "source": null,
    "statement_descriptor": null,
    "statement_descriptor_suffix": null,
    "status": "requires_payment_method",
    "transfer_data": null,
    "transfer_group": null
};

export const devGetPaymentMethods = {
    "object": "list",
    "url": "/v1/payment_methods",
    "has_more": false,
    "data": [
        {
            "id": "pm_1Iaivt2eZvKYlo2CqrXPTlNb4",
            "object": "payment_method",
            "billing_details": {
                "address": {
                    "city": null,
                    "country": null,
                    "line1": null,
                    "line2": null,
                    "postal_code": "54546",
                    "state": null
                },
                "email": null,
                "name": "Jason Aronson",
                "phone": null
            },
            "card": {
                "brand": "visa",
                "checks": {
                    "address_line1_check": null,
                    "address_postal_code_check": null,
                    "cvc_check": "pass"
                },
                "country": "US",
                "exp_month": 8,
                "exp_year": 2025,
                "fingerprint": "Xt5EWLLDS7FJjR1c",
                "funding": "credit",
                "generated_from": null,
                "last4": "4242",
                "networks": {
                    "available": [
                        "visa"
                    ],
                    "preferred": null
                },
                "three_d_secure_usage": {
                    "supported": true
                },
                "wallet": null
            },
            "created": 1617115961,
            "customer": null,
            "livemode": false,
            "metadata": {},
            "type": "card"
        },
        {
            "id": "pm_1Iaivt2eZvKYlo2CqrXPTlNb3",
            "object": "payment_method",
            "billing_details": {
                "address": {
                    "city": null,
                    "country": null,
                    "line1": null,
                    "line2": null,
                    "postal_code": "54546",
                    "state": null
                },
                "email": null,
                "name": "Jason Aronson",
                "phone": null
            },
            "card": {
                "brand": "mastercard",
                "checks": {
                    "address_line1_check": null,
                    "address_postal_code_check": null,
                    "cvc_check": "pass"
                },
                "country": "US",
                "exp_month": 8,
                "exp_year": 2025,
                "fingerprint": "Xt5EWLLDS7FJjR1c",
                "funding": "credit",
                "generated_from": null,
                "last4": "2222",
                "networks": {
                    "available": [
                        "visa"
                    ],
                    "preferred": null
                },
                "three_d_secure_usage": {
                    "supported": true
                },
                "wallet": null
            },
            "created": 1617115961,
            "customer": null,
            "livemode": false,
            "metadata": {},
            "type": "card"
        },
        // {
        //     "id": "pm_1Iaivt2eZvKYlo2CqrXPTlNb2",
        //     "object": "payment_method",
        //     "billing_details": {
        //         "address": {
        //             "city": null,
        //             "country": null,
        //             "line1": null,
        //             "line2": null,
        //             "postal_code": "54546",
        //             "state": null
        //         },
        //         "email": null,
        //         "name": "Jason Aronson",
        //         "phone": null
        //     },
        //     "card": {
        //         "brand": "amex",
        //         "checks": {
        //             "address_line1_check": null,
        //             "address_postal_code_check": null,
        //             "cvc_check": "pass"
        //         },
        //         "country": "US",
        //         "exp_month": 8,
        //         "exp_year": 2025,
        //         "fingerprint": "Xt5EWLLDS7FJjR1c",
        //         "funding": "credit",
        //         "generated_from": null,
        //         "last4": "4242",
        //         "networks": {
        //             "available": [
        //                 "visa"
        //             ],
        //             "preferred": null
        //         },
        //         "three_d_secure_usage": {
        //             "supported": true
        //         },
        //         "wallet": null
        //     },
        //     "created": 1617115961,
        //     "customer": null,
        //     "livemode": false,
        //     "metadata": {},
        //     "type": "card"
        // },{
        //     "id": "pm_1Iaivt2eZvKYlo2CqrXPTlNb1",
        //     "object": "payment_method",
        //     "billing_details": {
        //         "address": {
        //             "city": null,
        //             "country": null,
        //             "line1": null,
        //             "line2": null,
        //             "postal_code": "54546",
        //             "state": null
        //         },
        //         "email": null,
        //         "name": "Jason Aronson",
        //         "phone": null
        //     },
        //     "card": {
        //         "brand": "discover",
        //         "checks": {
        //             "address_line1_check": null,
        //             "address_postal_code_check": null,
        //             "cvc_check": "pass"
        //         },
        //         "country": "US",
        //         "exp_month": 8,
        //         "exp_year": 2025,
        //         "fingerprint": "Xt5EWLLDS7FJjR1c",
        //         "funding": "credit",
        //         "generated_from": null,
        //         "last4": "4242",
        //         "networks": {
        //             "available": [
        //                 "visa"
        //             ],
        //             "preferred": null
        //         },
        //         "three_d_secure_usage": {
        //             "supported": true
        //         },
        //         "wallet": null
        //     },
        //     "created": 1617115961,
        //     "customer": null,
        //     "livemode": false,
        //     "metadata": {},
        //     "type": "card"
        // },
    ]
};

export const devDetachPaymentMethod = {
    "paymentMethodId": "stripePaymentMethodId"
};

export const devUpdatePaymentMethod = {
    'paymentMethodId': 'pm_1Iaivt2eZvKYlo2CqrXPTlNb4',
    'name': "a",
    'email': "b",
    'phone': '9999999999',
    'city': "c",
    'country': 'US', // https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
    'line1': "d",
    'line2': "e",
    'postalCode': "f",
    'state': "g",
    'expirationMonth': 9,
    'expirationYear': 2099
};

export const devSetupIntentSecret = {
    'clientSecret': "clientsecret"
};