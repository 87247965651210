import React from 'react';
import {connect} from "react-redux";

/* Middleware */
import {tryGetBillingPaymentMethod} from "../middleware/billing";
import {LogoLoading} from "../../../common/components/loading";

class BillingPaymentMethod extends React.Component {
    state={"isLoading": true, "paymentMethod": {}};

    componentDidMount() {
        return this.init()
    }

    init = async () => {
        await this.props.tryGetBillingPaymentMethod();
        this.setState({
            "paymentMethod": this.props.paymentMethod.toJS(),
            "isLoading": false
        });
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <>
                    <div>PAYMENT METHOD</div>
                    <div>
                        <span>{this.state.paymentMethod.card}</span>
                        <span>
                            <span>****&nbsp;</span>
                            <span>{this.state.paymentMethod.last4digits}</span>
                        </span>
                        <span>
                            <span>Expires&nbsp;</span>
                            <span>{this.state.paymentMethod.expirationMonth.toString().length === 1 ? "0"+this.state.paymentMethod.expirationMonth : this.state.paymentMethod.expirationMonth}</span>
                            <span>/</span>
                            <span>{this.state.paymentMethod.expirationYear}</span>
                        </span>
                    </div>
                </>
            )
        }
    }

}

const mapStateToProps = state => ({
    paymentMethod: state.enterprise.billing.billing.get("paymentMethod")
});

const mapDispatchToProps = dispatch => ({
    tryGetBillingPaymentMethod: () => dispatch(tryGetBillingPaymentMethod())
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingPaymentMethod);
