import React from 'react';
import styled from 'styled-components';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Map} from 'immutable';

/* Components */
import {colors} from '../../../../common/components/colors';
import {FontSubtitle21} from '../../../../common/components/fonts';

import getDomainEnv from '../../../../domains/utils';
const env = getDomainEnv();

const NavRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 60px;
    row-gap: 30px;
    background-color: ${colors.canvaBrown};
    color: ${colors.white};
    padding: 10px 60px;
    @media screen and (max-width: 748px) {
      padding: 10px 30px;
    }
    @media screen and (max-width: 450px) {
      padding: 10px 15px;
    }
    //padding-bottom: 40px;
    //margin-top: 53px;
`;
const Nav = styled(Link)`
    ${props => props.active !== "true"} {
        //border-bottom: 3px solid ${colors.action100};
        cursor: default;
        & div {
          font-family: ${env.STYLES.FONTS.title.fontFamily};
        }
    }
    ${props => props.active !== "false"} {
        //border-bottom: none;
        cursor: pointer;
        & div {
          font-family: ${env.STYLES.FONTS.body.fontFamily};
        }
    }
    padding: 10px 0;
    width: fit-content;
    color: ${colors.primary100};
`;
const Bar = styled.div`
    width: 2px;
    margin: 4px 0;
    background-color: ${colors.primary100};
`;

class FirmTrainingsNav extends React.Component {
    render() {
        if(this.props.hideCert) {
            return (<NavRow />)
        } else {
            return (
                <NavRow>
                    <Nav
                        active={(this.props.location.pathname === "/my-certificates/my-trainings").toString()}
                        to={"/my-certificates/my-trainings"}
                    >
                        <FontSubtitle21 spaced={false}>Required trainings</FontSubtitle21>
                    </Nav>
                    {/*<Bar />*/}
                    <Nav
                        active={(this.props.location.pathname === "/elective-trainings'").toString()}
                        to={"/elective-trainings"}
                    >
                        <FontSubtitle21 spaced={false}>Elective Trainings</FontSubtitle21>
                    </Nav>
                    {/*<Nav*/}
                    {/*    active={(this.props.location.pathname === "/my-certificates/my-trainings").toString()}*/}
                    {/*    to={"/my-certificates/my-trainings"}*/}
                    {/*>*/}
                    {/*    <FontSubtitle28 spaced={false}>On-Demand trainings</FontSubtitle28>*/}
                    {/*</Nav>*/}
                    {/*<Bar />*/}
                    {/*<Nav*/}
                    {/*    active={(this.props.location.pathname === "/my-trainings/live").toString()}*/}
                    {/*    to={"/my-trainings/live"}*/}
                    {/*>*/}
                    {/*    <FontSubtitle28 spaced={false}>Live trainings</FontSubtitle28>*/}
                    {/*</Nav>*/}
                </NavRow>
            )
        }
    }
}

const mapStateToProps = state => ({
    hideCert: state.common.user.get("directoryListings") === Map() ? false : state.common.user.get("directoryListings").get("practiceManagementUntil") !== null
})

export default connect(mapStateToProps, null)(withRouter(FirmTrainingsNav));