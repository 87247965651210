import React from 'react';
// import Lottie from 'react-lottie';
import styled from 'styled-components';

import {colors} from './colors';
import {FontTitle21} from './fonts';

const Box = styled.div`
    -webkit-box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    height: auto;
    width: 340px;
    background-color: ${colors.white};
    margin: 80px auto 0px auto;
    border-radius: 10px;
    text-align: center;
    padding: 40px 0px;
`;

/* Lottie Files */
// import * as animationData from '../static/loader.json'

// const Loading = ({ isStopped }) => {
//     const defaultOptions = {
//         loop: true,
//         autoplay: true,
//         animationData: animationData.default,
//         rendererSettings: {
//             preserveAspectRatio: 'xMidYMid slice'
//         }
//     };
//     return (
//         <Box>
//             <Lottie
//                 options={defaultOptions}
//                 height={135}
//                 width={225}
//                 isStopped={isStopped}
//                 isPaused={false}
//             />
//             <br />
//             <FontTitle21>Loading!</FontTitle21>
//         </Box>
//     )
// };

// export default Loading;



/* Local */
import {images} from "./images";

const Rotate = styled.div`
    text-align: center;
    @keyframes rotation {
      from {transform: rotate(0deg);}
      to {transform: rotate(359deg);}
    }
    & img {
        animation: rotation 5s infinite linear;
    }
`;

export const LogoLoading = () => (
    <Rotate><img src={images.logoIcon} height="200" width="200" alt={"Loading!"} /></Rotate>
);

export const LogoLoadingSmall = () => (
    <Rotate><img src={images.logoIcon} height="50" width="50" alt={"Loading!"} /></Rotate>
);
