import React from 'react';
import styled from "styled-components";
import ContentEditable from 'react-contenteditable'

import { FontBody16 } from "../../../common/components/fonts";
import { colors } from "../../../common/components/colors";
import { fontFamily } from "../../../common/components/fonts";


const OptionsContainer = styled.div`
    margin: 10px 0px;
`;

const Option = styled.div`
    border: 0px;
    border-radius: 20px 0px 20px 0px;
    cursor: pointer;
    outline: none;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
    margin-top: 20px;
    padding: 20px;
    text-align: left;
    font-family:${fontFamily.body};
    ${props => props.checked !== false} {
        background-color:#EBE5DA;
        color: ${colors.secondary100}
    }
    ${props => props.checked !== true} {
        background-color: ${colors.primary100};
        color: ${colors.white}
    }
    .content-editable:focus {
        outline: 2px solid ${colors.border100};
    }
    [contenteditable=true]:empty:before {
        content: attr(placeholder);
        color: #aaa;
    }
`;


class ElementMultiSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: props.options,
            isLoading: true,
            isValid: this.isValidOptionSelection(props.options),
            numSelected: 0
        }
    }

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.elementId !== this.props.elementId) {
            return this.init();
        }
    }

    isValidOptionSelection = async (options) => this.props.isRequired
        ? options && options.some(o => o.active)
        : true

    init = async () => {
        this.setState({
            "options": this.props.options,
            "isValid": await this.isValidOptionSelection(this.props.options)
        });
    };

    onOptionSelect = async (id, status) => {
        if (this.props.disabled) {
            if(this.props.maxSelectable === 1 || !this.props.maxSelectable) {
                await this.setState({
                    "options": this.state.options.map(d => {
                        if (d.id === id) {
                            return ({ ...d, active: true });
                        } else {
                            return ({...d, active: false});
                        }
                    })
                });
            } else {
                await this.setState({
                    "options": this.state.options.map(d => {
                        if (d.id === id) {
                            if(status) {
                                if((this.state.numSelected+1) <= this.props.maxSelectable) {
                                    return ({ ...d, active: status });
                                } else {
                                    return d;
                                }
                            } else {
                                return ({ ...d, active: status });
                            }
                        } else {
                            return d;
                        }
                    })
                });
            }
            await this.setState({
                "numSelected": this.state.options.filter(o => (o.active)).length
            });
            await this.save()
        }
    };

    getStyle = () => {
        if (this.props.style)
            return this.props.style
        else
            return {}
    }

    onChange = async (e, i) => {
        const newOptions = this.state.options
        newOptions.splice(i, 1, {...this.state.options[i], "label": e.target.value})
        this.setState({
            "options": newOptions
        })
    }

    save = async () => {
        let isValid = await this.isValidOptionSelection(this.state.options);
        return this.props.onSaveElement(this.props.elementId, { "options": this.state.options }, isValid)
    }

    render() {
        return (
            <>
                <OptionsContainer style={this.getStyle().options}>
                    {this.state.options.map((j, i) => (
                        <Option key={j.id} onClick={() => this.onOptionSelect(j.id, !j.active)} checked={j.active}>
                            <FontBody16>
                                <ContentEditable 
                                    html={j.label} 
                                    disabled={this.props.disabled} 
                                    onChange={(e) => {return this.onChange(e, i)}}
                                    className="content-editable"
                                    onBlur={(e) => {return this.save()}}
                                    placeholder={"Enter option label here"}
                                />
                            </FontBody16>
                        </Option>
                    ))}
                </OptionsContainer>
            </>
        )
    }
}

export default ElementMultiSelect;