import React from 'react';
import validator from 'validator';
import styled from 'styled-components';

/* Components */
import {TextInput} from "../../../common/components/inputs";
import {colors} from "../../../common/components/colors";
import {FontBody14} from "../../../common/components/fonts";

const Actions = styled.div`
    color: ${colors.action100};
    margin-top: -10px;
    & div {
        width: fit-content;
        cursor: pointer;
    }
`;
const HeroVid = styled.iframe`
    width: 100%;
    max-width: 600px;
    max-height: 480px;
    min-height: 440px;
    @media screen and (max-width: 800px) {
        min-height: 360px;
    }
    @media screen and (max-width: 460px) {
        min-height: 240px;
    }
`;

class MeetingsCreateVideo extends React.Component {
    state = {
        "videoURL": '',
        "videoURLValid": true,
        "videoPreview": false,
        "isLoadingVideo": true
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.setState({
            "videoURL": this.props.video.videoURL === null ? "" : this.props.video.videoURL,
            'videoURLValid': (validator.isURL(this.props.video.videoURL) || this.props.video.videoURL === ""),
            "isLoadingVideo": false
        });
    };

    handleChangeVideoURL = async e => {
        await this.setState({
            'videoURL': e.target.value,
            'videoURLValid': (validator.isURL(e.target.value) || e.target.value === ""),
            'videoPreview': false
        });
        await this.props.onValidate(this.state);
    };

    handleChangePreview = async () => {
        this.setState({"videoPreview": !this.state.videoPreview})
    };

    render() {
        if(this.state.isLoadingVideo) {
            return null
        } else {
            return(
                <>
                    <TextInput
                        title={this.props.title}
                        valid={this.state.videoURLValid}
                        warning={"There is a problem with your link"}
                        id={"videoURL"}
                        onChange={this.handleChangeVideoURL}
                        placeholder={"https://... (Youtube or Vimeo embed link)"}
                        value={this.state.videoURL}
                    />
                    {(this.state.videoURLValid && this.state.videoURL !== "") &&
                        <Actions>
                            <FontBody14 onClick={this.handleChangePreview}>{this.state.videoPreview ? "Hide preview" : "Show preview"}</FontBody14>
                        </Actions>
                    }
                    {(this.state.videoPreview && this.state.videoURLValid) &&
                        <HeroVid src={this.state.videoURL} />
                    }
                </>
            )
        }
    }
}

export default MeetingsCreateVideo;
