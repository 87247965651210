import React from 'react';
import {connect} from "react-redux";
import moment from 'moment/moment';

/* Middleware */
import {tryGetBillingHistory} from "../middleware/billing";
import {LogoLoading} from "../../../common/components/loading";

import {SettingsCard, Left, Right, InlineButton, Padding5, NoActivePlans, CurrentPlanRow} from "../components/billing";

import {FontBody16, FontHeader21, FontTitle18} from "../../../common/components/fonts";
import {ButtonPrimary} from "../../../common/components/buttons"; 

import colors from '../../../domains/trustwillow.com/styles/colors';
import { devIframeVerificationStatus } from '../../../iframes/listings/static/devdata';

class BillingCurrentPlan extends React.Component {
    state={"isLoading": true, "history": []};

    componentDidMount() {
        return this.init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.history !== this.props.history) {
            return this.update();
        }
    }

    init = async () => {
        await this.props.tryGetBillingHistory();
        return this.update();
    };

    update = async () => {
        this.setState({
            "history": this.props.history.toJS(),
            "currentPlans": this.props.history.filter(h => {return(h.get("isSubscription") && !h.get("isCanceled"))}).toJS(),
            "isLoading": false
        });
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <SettingsCard backgroundColor={colors.backgroundColor7} zIndex={'3'}>
                    <FontHeader21>Current Plans</FontHeader21>
                    {this.state.currentPlans.length === 0 &&
                        <CurrentPlanRow>
                            <NoActivePlans>
                                <FontBody16>No active plans</FontBody16>
                            </NoActivePlans>
                        </CurrentPlanRow>
                    }
                    {this.state.history.map((p, i) => (
                        (p.isSubscription && !p.isCanceled) && 
                        <div key={i}>
                            <Right>
                                <Padding5><FontTitle18>{p.productTitle}</FontTitle18></Padding5>
                                <FontBody16>${(parseInt(p.amountPaid)/100).toFixed(2) + " per " + p.subcriptionInterval}</FontBody16>
                                <FontBody16>Your plan renews on {moment(p.purchasedOn).add(1, 'years').format('ll')}</FontBody16>
                            </Right>
                            <Left>
                                <InlineButton onClick={() => {return this.props.showCancel(true, p.purchaseHistoryId, p.productTitle)}}>
                                    <ButtonPrimary submit={false} label={<>Cancel Plan</>} />
                                </InlineButton>
                            </Left>
                        </div>
                    ))}
                </SettingsCard>
            )
        }
    }

}

const mapStateToProps = state => ({
    history: state.enterprise.billing.billing.get("history")
});

const mapDispatchToProps = dispatch => ({
    tryGetBillingHistory: () => dispatch(tryGetBillingHistory())
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingCurrentPlan);
