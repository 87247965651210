import { combineReducers } from 'redux';

/* Reducers */
import billingReducer from './store/billing';

const billingsReducer = combineReducers({
    billing: billingReducer,
});

export default billingsReducer;
