import React from "react";
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import styled from "styled-components";
import {Widget} from '@typeform/embed-react';
import moment from "moment/moment";

/* Components */
import {LogoLoading} from "../../../../common/components/loading";

/* Middleware */
import {tryAnswerTask, tryGetOneTask, tryGetSurveyAttemptsToday, tryPostAssignTask} from "../middleware/tasks";
import {tryGetTrainingMapping} from '../../trainings/middleware/trainings';

/* Domain */
// import getDomainEnv from '../../../../domains/utils';
// const env = getDomainEnv();

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

class TrainingsRecordQuiz extends React.Component {
    state = {"isLoading": true, "courseIdQuiz": null, "courseQuiz": {}, "surveyId": null};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const courseIdQuiz = window.location.pathname.split('/')[3];
        await this.completeVideo(courseIdQuiz);
        const courseQuiz = await this.assignQuiz(courseIdQuiz);
        await this.checkQuizCompleted(courseIdQuiz, courseQuiz);
        await this.checkCanTakeSurveyAgain(courseIdQuiz);
        const surveyId = await this.surveyId(courseQuiz);
        await this.setState({
            "courseIdQuiz": courseIdQuiz,
            "courseQuiz": courseQuiz,
            "surveyId": surveyId,
            "isLoading": false
        });
    };

    checkCanTakeSurveyAgain = async (courseIdQuiz) => {
        const attempts = await this.props.tryGetSurveyAttemptsToday();
        const attemptsFailed = attempts.completedSurveys.filter(r => {
            let isToday = moment(r.completedOn).isSame(moment(), 'day')
            return isToday && r.quizResults.score < 70 && r.courseId.toString() === courseIdQuiz.toString();
        })
        if(attemptsFailed.length >= 2) {
            return this.props.history.push("/my-tasks/course/"+courseIdQuiz+"/failed");
        }
    }

    completeVideo = async (courseIdQuiz) => {
        const mapping = await this.props.tryGetTrainingMapping();
        const courseIdVideo = mapping.matchQuizAndVideo[courseIdQuiz.toString()] === undefined ? null : mapping.matchQuizAndVideo[courseIdQuiz.toString()];
        if(courseIdVideo !== null) {
            let courseVideo = await this.props.tryGetOneTask(courseIdVideo)
            if(!courseVideo.isCompleted) {
                this.props.tryAnswerTask(courseVideo.tasks[0].taskAssignmentId, courseVideo.tasks[0].task.pages, true, 0)
            }
        }
    }

    assignQuiz = async (courseIdQuiz) => {
        let courseQuiz = await this.props.tryGetOneTask(courseIdQuiz)
        if(courseQuiz.id === undefined) {
            await this.props.tryPostAssignTask(courseIdQuiz, this.props.userId);
            courseQuiz = await this.props.tryGetOneTask(courseIdQuiz)
            // Ensure email on assignment goes to: https://secure.trustwillow.com/redirect/my-tasks/course/<courseIdQuiz>/quiz
        }
        return courseQuiz;
    }

    checkQuizCompleted = async (courseIdQuiz, courseQuiz) => {
        if(courseQuiz.isCompleted) {
            return this.props.history.push("/my-tasks/course/"+courseIdQuiz+"/completed");
        }
    }

    surveyId = async (courseQuiz) => {
        const surveyUrl = courseQuiz.tasks[0].surveyUrl;
        const SurveyUrlSplit = surveyUrl.split("/");
        const surveyParams = SurveyUrlSplit[SurveyUrlSplit.length-1];
        const surveyParamsSplit = surveyParams.split("#");
        return surveyParamsSplit[0];
    }

    render() {
        if(this.state.isLoading) {
            return (<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <Widget
                    id={this.state.surveyId}
                    style={{width: '100%', height: 'calc(100vh - 4px)'}}
                    className={"my-form"}
                    hidden={{"user_id": this.props.userId, "course_id": this.state.courseIdQuiz}}
                />
            )
        }
    }
}

const mapStateToProps = state => ({
    userId: state.common.user.get("userId")
});

const mapDispatchToProps = dispatch => ({
    tryGetTrainingMapping: () => dispatch(tryGetTrainingMapping()),
    tryGetSurveyAttemptsToday: () => dispatch(tryGetSurveyAttemptsToday()),
    tryGetOneTask: (courseId) => dispatch(tryGetOneTask(courseId)),
    tryPostAssignTask: (courseId, userId) => dispatch(tryPostAssignTask(courseId, userId)),
    tryAnswerTask: (id, answers, isCompleted, lastPageCompleted) => dispatch(tryAnswerTask(id, answers, isCompleted, lastPageCompleted))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TrainingsRecordQuiz));
