export default {

    // REQUIRED - START //

    "favicon16px": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615072185/kssqtw8ay0unfojoy6xq.png",
    "favicon32px": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615072210/zidcbheodata5jukv3xo.png",
    "favicon256px": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615072225/ayutmwszjxnlfnh9jeen.png",

    "logo": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615060404/jiiwpkchukynjdspr2f3.png",
    "logoIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615061609/ltahv3jg7dbabxc0eblc.png",

    "error1": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615061902/j5wbelwjtfg4gjjvoom3.png",
    "error2": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615061948/tdg8knv9isuqiodkl6hx.png",
    "error3": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615061980/uzxpckc4cyd8mhuerllf.png",

    "calSyncBrand": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615065796/bap1uqa5cfa56vwr9tz8.png",

    // REQUIRED - END //

    //

    // DOMAIN SPECIFIC - START //

    "teamCategoryAccounting": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066562/qhe0k9m810nndhi7rzgv.png",
    "teamCategoryCoaching": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066590/mzd6rk3jfhk90tq94jvw.png",
    "teamCategoryLegal": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066607/uabd3mzkwveugsuax9d3.png",
    "teamCategoryPlanning": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066625/kgewwaflcpfm54lacdmc.png",

    "coachingStyleEmotional": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067604/upkssfnpmabw8ytzbkf0.svg",
    "coachingStyleMilitary": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067652/bohgv5c9z0vzos3zsjgt.svg",
    "coachingStyleNoNonsense": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067673/minz7ya97hhzm1lx2bwb.svg",
    "coachingStyleSpiritual": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067697/akagcyiorgfftlarqzah.svg",
    "coachingStyleUnderServed": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067718/ritld46iwlf7tz5nk7lf.svg",

    "willowTalkIconInfo": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068143/beaieaowozbjqmp6px5w.svg",
    "willowTalkIconLearn": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068171/irvodxwmeeccoctutpl3.svg",
    "willowTalkIconListen": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068190/iuvweq81kcttvtlfaaoi.svg",

    "consumerSignUpIconBalances": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068558/vjnldvirhkzcdv8cx5ek.svg",
    "consumerSignUpIconGoals": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068575/en6cxlxhgdehtcycqzzr.svg",
    "consumerSignUpIconGuidance": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068594/lrfq8o7oihlt8brafvlj.svg",
    "consumerSignUpIconVerify": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068629/fbkfvu7ijbkcs4a8qqoo.svg",
    "consumerSignUpIconBooked": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615068613/x54kvub91o1lypxavbzf.svg",

    "coachResourcesWillowLogo": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615071667/nmmozdrepyelums1nlx1.png",

    "willowTeamGabbyAvatar": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633402700/wiyhcppbfbllfzzndmzp.jpg",
    "willowTeamLacyAvatar": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1602605069/chyjbqe6ql6foprctnww.png",

    "emailEmojiCheck": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1619591388/wwnm8tvjbmv2foogepce.png",
    "emailEmojiBook": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1619591370/vgrtjwavld7dc4jcijwj.png",
    "emailEmojiBrain": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1619591354/dzfz6ia99h8dgy6trrfa.png",

    // DOMAIN SPECIFIC - END //

    //

    // DEPRECATED - START //

    "teamIconConnect": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066450/bkqwawv86gifdyhfaei9.png",
    "teamIconGuidelines": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066479/lwjbqqpathnjp3rpctqe.png",
    "teamIconVideo": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066498/qr7upesgeobgdgec3zw1.png",
    "teamIconReport": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066523/bgthg5uklxzc8fllsrpk.png",
    "teamIconWoman": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615066539/azjjtjtvi8xyxwzusg60.png",

    // DEPRECATED - END //

};