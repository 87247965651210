import React from "react";
import styled from 'styled-components';

/* Components */
import {PlusMinusCountUncontrolled} from "../../common/components/inputs";
import {FontBody12, FontBody14, FontHeader16} from "../../common/components/fonts";
import {ButtonInactive, ButtonPrimary} from "../../common/components/buttons";
import {SessionCount, Center, Gray, Green} from '../components/contactcoaching';

const FitContent = styled.div`
    width: fit-content;
    ${props => props.fullWidth !== true} {
        padding-top: 9px;
        width: 100%;
        & > div {
            width: calc(100% - 46px);
        }   
    }
`;

export class ChangeSessions extends React.Component {
    state={"count": 0, "isChanged": false, "isSaving": false, "isSaved": false};

    componentDidMount() {
        this.setState({"count": this.props.initialCount})
    };

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    onChange = async (e) => {
        await this.setState({"count": e});
        this.testChanged();
    };

    testChanged = async () => {
        if(this.state.count !== this.props.initialCount) {
            this.setState({"isChanged": true})
        } else {
            this.setState({"isChanged": false})
        }
    };

    save = async (coachId, count, coachName) => {
        if (count - this.props.initialCount === 0) {
            this.props.closeAction();
        }
        else if(this.props.alwaysShowSave) {
            const countAdj = this.props.useSessionDelta ? (count - this.props.initialCount) : count;
            await this.props.updateSessions(coachId, countAdj, coachName);
            this.props.closeAction();
        } else {
            await this.setState({'isSaving': true, 'isSaved': false});
            const countAdj = this.props.useSessionDelta ? (count - this.props.initialCount) : count;
            console.log(countAdj);
            await this.props.updateSessions(coachId, countAdj, coachName);
            this.setState({'isSaving': false, 'isSaved': true});
            this.timeout = setTimeout(() => {
                this.setState({'isSaving': false, 'isSaved': false, 'isChanged': false});
                this.props.closeAction();
            }, this.props.saveTimer);
        }
    };

    render() {
        return(
            <>
                {(!this.state.isSaved && !this.state.isSaving)
                    ?
                    <SessionCount>
                        <PlusMinusCountUncontrolled
                            initialCount={this.props.initialCount}
                            count={this.state.count}
                            onChange={this.onChange}
                        />
                        <Center><FontBody14>{this.props.subTitle}</FontBody14></Center>
                    </SessionCount>
                    :
                    <br />
                }
                <Center>
                    {((this.state.isChanged && !this.state.isSaving && !this.state.isSaved) || this.props.alwaysShowSave) &&
                    <>
                        <FitContent fullWidth={this.props.saveButtonFullWidth} onClick={() => {return this.save(this.props.expertId, this.state.count, this.props.expertName)}}>
                            <ButtonPrimary canSubmit={false} label={this.props.saveButtonLabel} />
                        </FitContent>
                        {(this.props.notifyFirstName !== null && this.props.showNoticeCoach) &&
                        <>
                            <br />
                            <Gray><FontBody12>{"Only "+this.props.notifyFirstName+" will be notified"}</FontBody12></Gray>
                        </>
                        }
                    </>
                    }
                    {(this.state.isChanged && this.state.isSaving && !this.state.isSaved) &&
                        <FitContent fullWidth={this.props.saveButtonFullWidth}><br/><ButtonInactive canSubmit={false} label={"Saving"} /></FitContent>
                    }
                    {(this.state.isChanged && !this.state.isSaving && this.state.isSaved) &&
                        <><br/><Green><FontHeader16>Saved!</FontHeader16></Green></>
                    }
                </Center>
            </>
        )
    }
}
