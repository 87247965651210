import {images} from '../../../common/components/images';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

export const devConsumerTeamMembers = {
    "allCoaches": [
        {
            "coachId":1032,
            "first":"Gabby",
            "last":"Baker",
            "phone":7742580278,
            "email":"gabby@"+env.URL.DOMAIN,
            "photo":images.devDataTeamGabbyAvatar,
            "bio":"<p>Gabby Baker CFP\u00ae, is a Financial Wellness and Empowerment Coach who helps people understand how to unlock their capacity for an abundant life.&nbsp;</p><p><br></p><p>Prior to opening her own practice, she spent over a decade in the investment management industry (with American Funds &amp; Fidelity Investments) where she worked closely with financial advisors and investors.</p><p><br></p><p>Gabby earned her BA in Political Science and Organizational &amp; Behavioral Management from Brown University and received her MBA from Georgetown University.</p>",
            "expertCategories":[
                {
                    "categoryId":2,
                    "categoryName":"Financial Life Coach"
                }/*,
                {
                    "categoryId":52,
                    "categoryName":"NotATeamMember"
                }*/
            ],
            "wmcId":9,
            "defaultCallLengthInMinutes": 45,
            "storeActive": true
        },
        {
            "coachId":1033,
            "first":"Lacy",
            "last":"Garcia",
            "phone":7742580278,
            "email":"lacy@"+env.URL.DOMAIN,
            "photo":images.devDataTeamLacyAvatar,
            "bio":"<p>Lacy is a Financial Wellness and Empowerment Coach who helps people understand how to unlock their capacity for an abundant life.&nbsp;</p><p><br></p><p>Prior to opening her own practice, she spent over a decade in the investment management industry (with American Funds &amp; Fidelity Investments) where she worked closely with financial advisors and investors.</p><p><br></p><p>Gabby earned her BA in Political Science and Organizational &amp; Behavioral Management from Brown University and received her MBA from Georgetown University.</p>",
            "expertCategories":[
                {
                    "categoryId":1,
                    "categoryName":"Willow Financial Coach"
                },
                {
                    "categoryId":4,
                    "categoryName":"Financial Coach"
                },
                {
                    "categoryId":3,
                    "categoryName":"Financial Coach2"
                },/*,
                {
                    "categoryId":52,
                    "categoryName":"NotATeamMember"
                }*/
            ],
            "wmcId":null,
            "defaultCallLengthInMinutes": 15,
            "storeActive": false
        },
        {
            "coachId":1034,
            "first":"Mary",
            "last":"Conners",
            "phone":7742580278,
            "email":"lacy@"+env.URL.DOMAIN,
            "photo":images.devDataTeamGabbyAvatar,
            "bio":"<p>Lacy is a Financial Wellness and Empowerment Coach who helps people understand how to unlock their capacity for an abundant life.&nbsp;</p><p><br></p><p>Prior to opening her own practice, she spent over a decade in the investment management industry (with American Funds &amp; Fidelity Investments) where she worked closely with financial advisors and investors.</p><p><br></p><p>Gabby earned her BA in Political Science and Organizational &amp; Behavioral Management from Brown University and received her MBA from Georgetown University.</p>",
            "expertCategories":[
                {
                    "categoryId":1,
                    "categoryName":"Willow Financial Coach"
                },
                {
                    "categoryId":4,
                    "categoryName":"Financial Coach"
                },
                {
                    "categoryId":3,
                    "categoryName":"Financial Coach2"
                },/*,
                {
                    "categoryId":52,
                    "categoryName":"NotATeamMember"
                }*/
            ],
            "wmcId":null,
            "defaultCallLengthInMinutes": 15,
            "storeActive": false
        }
    ]
};



export const devTeamMemberMeetingsData = {
    "program": {
        "package": "trial", // "1-month", "3-month", "6-month", "12-month", or "trial"
        "purchaseDate": "2020-03-06T10:00:00+00:00",
        "completedSessions": 3,
        "remainingSessions": 0,
        "hoursInCoaching": 1.5,
        "coachId": 1033
    },
    "upcomingCalls": [
        {
            id: 1,
            remoteUserName: "Gabby Coach",
            remoteUserId: 1032,
            thisUserName: "Jason Consumer",
            thisUserPlatform: "consumer",
            start: "2022-06-14T13:00:00+00:00",
            end: "2022-06-14T13:30:00+00:00",
            phone: "617-380-4313",
            room: "277861",
            videoStatus: "conference_ongoing", // conference_canceled, conference_ended, conference_has_not_yet_started, conference_ongoing
            videoUrl: "924fcf54447903ab9ea558886af67543", // if status !== conference_ongoing then videoUrl = null
            videoAccessToken: "abc", // if status !== conference_ongoing then videoAccessToken = null
            title: 'Jason Consumer',
            "remoteUserFirstName": "Gabby",
            "remoteUserLastName": "Baker",
            "remoteUserPhotoUrl": images.devDataTeamGabbyAvatar,
            "cancelToken": "abcd",
            "purchaseToken":"3447-L3mmvyh3N3RbPMrrXjCPdyyptXewmNer3kR60A-2187",
            "meetingIsTrial":false,
            "purchaseHistoryId":null,
            "isWillowSession": true
        },{
            id: 1,
            remoteUserName: "Gabby Coach",
            remoteUserId: 1032,
            thisUserName: "Jason Consumer",
            thisUserPlatform: "consumer",
            start: "2022-06-14T13:00:00+00:00",
            end: "2022-06-14T13:30:00+00:00",
            phone: "617-380-4313",
            room: "277861",
            videoStatus: "conference_ongoing", // conference_canceled, conference_ended, conference_has_not_yet_started, conference_ongoing
            videoUrl: "924fcf54447903ab9ea558886af67543", // if status !== conference_ongoing then videoUrl = null
            videoAccessToken: "abc", // if status !== conference_ongoing then videoAccessToken = null
            title: 'Jason Consumer',
            "remoteUserFirstName": "Gabby",
            "remoteUserLastName": "Baker",
            "remoteUserPhotoUrl": images.devDataTeamGabbyAvatar,
            "cancelToken": "abcd",
            "purchaseToken":"3447-L3mmvyh3N3RbPMrrXjCPdyyptXewmNer3kR60A-2187",
            "meetingIsTrial":false,
            "purchaseHistoryId":null,
            "isWillowSession": true
        },
        {
            id: 2,
            remoteUserName: "Lacy Garcia",
            remoteUserId: 32,
            thisUserName: "Jason Consumer",
            thisUserPlatform: "consumer",
            start: "2022-06-14T13:00:00+00:00",
            end: "2022-06-14T13:30:00+00:00",
            phone: "617-380-4313",
            room: "277861",
            videoStatus: "conference_ongoing", // conference_canceled, conference_ended, conference_has_not_yet_started, conference_ongoing
            videoUrl: "924fcf54447903ab9ea558886af67543", // if status !== conference_ongoing then videoUrl = null
            videoAccessToken: "abc", // if status !== conference_ongoing then videoAccessToken = null
            title: 'Jason Consumer',
            "remoteUserFirstName": "Lacy",
            "remoteUserLastName": "Garcia",
            "remoteUserPhotoUrl": images.devDataTeamLacyAvatar,
            "purchaseToken":"3447-L3mmvyh3N3RbPMrrXjCPdyyptXewmNer3kR60A-2187",
            "meetingIsTrial":true,
            "purchaseHistoryId": null //"wmcPaid",
        }
    ],
    "pastCalls": [
        {
            "id": 8,
            "date": "2022-04-08T05:00:00+00:00",
            "length": "60 min",
            "isOngoing": true,
            "isMissed": true,
            // Add start, end, remoteUserId
            "start": "2022-04-08T01:00:00+00:00",
            "end": "2022-04-08T07:00:00+00:00",
            "expected_end": "2022-04-08T07:00:00+00:00",
            "remoteUserId": 1032,
            "coachId":  1032,
            "remoteUserFirstName": "Gabby",
            "remoteUserLastName": "Baker",
            "remoteUserPhotoUrl": images.devDataTeamGabbyAvatar,
            "isCanceled":true,
            "purchaseToken":"3447-L3mmvyh3N3RbPMrrXjCPdyyptXewmNer3kR60A-2187",
            "meetingIsTrial":true,
            "purchaseHistoryId": null //"wmcPaid",
        },
        {
            "id": 4,
            "date": "2019-03-08T05:00:00+00:00",
            "length": "60 min",
            "isOngoing": true,
            "isMissed": false,
            // Add start, end, remoteUserId
            "start": "2019-03-08T01:00:00+00:00",
            "end": "2019-03-08T07:00:00+00:00",
            "expected_end": "2019-03-08T07:00:00+00:00",
            "remoteUserId": 1032,
            "coachId":  1032,
            "remoteUserFirstName": "Gabby",
            "remoteUserLastName": "Baker",
            "remoteUserPhotoUrl": images.devDataTeamGabbyAvatar,
            "isCanceled":false,
            "purchaseToken":"3447-L3mmvyh3N3RbPMrrXjCPdyyptXewmNer3kR60A-2187",
            "meetingIsTrial":true,
            "purchaseHistoryId":null,
        },
        {
            "id": 1,
            "date": "2020-08-10T17:00:00+00:00",
            "length": "30 min",
            "isOngoing": true,
            "isMissed": false,
            // Add start, end, remoteUserId
            "start": "2020-08-10T17:00:00+00:00",
            "end": "2020-08-10T17:45:00+00:00",
            "expected_end": "2020-08-10T17:45:00+00:00",
            "remoteUserId": 1032,
            "coachId":  1032,
            "remoteUserFirstName": "Gabby",
            "remoteUserLastName": "Baker",
            "remoteUserPhotoUrl": images.devDataTeamGabbyAvatar,
            "isCanceled":false,
            "purchaseToken":"3447-L3mmvyh3N3RbPMrrXjCPdyyptXewmNer3kR60A-2187",
            "meetingIsTrial":true,
            "purchaseHistoryId": null //"willowPaid",
        },
        {
            "id": 2,
            "date": "2020-03-06T10:00:00+00:00",
            "length": "30 min",
            "isOngoing": false,
            "isMissed": true,
            // Add start, end, remoteUserId
            "start": "2020-03-06T10:00:00+00:00",
            "end": "2020-03-06T13:00:00+00:00",
            "expected_end": "2020-03-06T13:00:00+00:00",
            "remoteUserId": 1032,
            "coachId":  1033,
            "remoteUserFirstName": "Gabby",
            "remoteUserLastName": "Baker",
            "remoteUserPhotoUrl": images.devDataTeamGabbyAvatar,
            "isCanceled":false,
            "purchaseToken":"3447-L3mmvyh3N3RbPMrrXjCPdyyptXewmNer3kR60A-2187",
            "meetingIsTrial":false,
            "purchaseHistoryId":58,
        },
        {
            "id": 3,
            "date": "2020-02-25T10:00:00+00:00",
            "length": "30 min",
            "isOngoing": false,
            "isMissed": false,
            // Add start, end, remoteUserId
            "start": "2020-02-25T10:00:00+00:00",
            "end": "2020-02-25T11:00:00+00:00",
            "expected_end": "2020-02-25T11:00:00+00:00",
            "remoteUserId": 1032,
            "coachId": 1033,
            "remoteUserFirstName": "Lacy",
            "remoteUserLastName": "Garcia",
            "remoteUserPhotoUrl": images.devDataTeamLacyAvatar,
            "isCanceled":false,
            "purchaseToken":"3447-L3mmvyh3N3RbPMrrXjCPdyyptXewmNer3kR60A-2187",
            "meetingIsTrial":false,
            "purchaseHistoryId":58,
        }
    ]
};

export const devEnterpriseMemberBillingHistoryData = [
    {
        "coachId": 1033,
        "amountPaid":100,
        "purchasedOn":"2021-03-25T15:48:31Z",
        "isSubscription":true,
        "subcriptionInterval":"year",
        "subscriptionDuration":null,
        "isActive":true,
        "isOnTheFlyInvoice": false,
        "productId": 1,
        "numberOfSessions": 4,
        "sessionLengthInMinutes": 45,
        "coverPhotoProduct": images.devDataStoreProduct,
        "productTitle": "Clarity Meeting",
        "productDescription": "<p>erflhbqlfjhbqwe</p>",
        "downloadUrl": "https://google.com",
        "serviceType": "download",
        "purchaseHistoryId":58,
    },
    {
        "coachId": 1033,
        "amountPaid":100,
        "purchasedOn":"2021-04-25T15:48:31Z",
        "isSubscription":true,
        "subcriptionInterval":"year",
        "subscriptionDuration":null,
        "isActive":true,
        "isOnTheFlyInvoice": false,
        "productId": 2,
        "numberOfSessions": 3,
        "sessionLengthInMinutes": 30,
        "coverPhotoProduct": images.devDataStoreProduct,
        "productTitle": "Clarity Meeting 2",
        "productDescription": "<p>erflhbqlfjhbqwe</p>",
        "downloadUrl": "https://google.com",
        "serviceType": "offline",
        "purchaseHistoryId":57,
    },
];

export const devEnterpriseWMCCoachesSessions = {
    "allCoaches":[
        {
            "coachId":1032,
            "wmcId":24,
            "first":"Danielle",
            "last":"Jacobs",
            "phone":6173829193,
            "email":"gwen+advise@"+env.URL.DOMAIN,
            "photo": images.devDataTeamDanielleAvatar,
            "defaultCallLengthInMinutes":30,
            "storeActive":true,
            "storeHandle":"danielle-jacobs",
            "bio":"Danielle Jacobs has been with the Jacobs Wealth Management since 2006. She believes her previous five years as a sales manager selling health products to the medical industry were an ideal foundation for her work as a financial advisor. She is effectively supported by a team of administration, group benefits and investment products specialists whose teamwork and professionalism help her build long-term relationships with her growing client base and provide excellent customer service. Danielle is a proud recipient of Yale University\u2019s MBA of Finance and was recently invited to join the Million Dollar Round Table.",
            "expertCategories":[
                {
                    "categoryId":2,
                    "categoryName":"Financial Advisor"
                }
            ],
            "remainingSessions":1,
            "completedSessions": 0
        },
        {
            "coachId":1033,
            "wmcId":null,
            "first":"Mary",
            "last":"Conner",
            "phone":6173839291,
            "email":"gwen+coaching@"+env.URL.DOMAIN,
            "photo": images.devDataTeamIsabelleAvatar,
            "defaultCallLengthInMinutes":30,
            "storeActive":true,
            "storeHandle":"mary-conner",
            "bio":"N/A",
            "expertCategories":[
                {
                    "categoryId":4,
                    "categoryName":"Financial Coach"
                }
            ],
            "remainingSessions":3,
            "completedSessions": 2
        }
    ]
};


export const devConsumerProductGeneral = {availableSessions: 2};