import React from 'react';
import styled from 'styled-components';
import {Helmet} from "react-helmet";

/* Components */
import {images} from '../../common/components/images';
import {LogoLoading} from "../../common/components/loading";

/* Containers */
import PasswordResetFlow from './containers/flow';

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

const Logo = styled.img`
    width: auto;
    height: 32px;
    cursor: pointer;
    margin: 0px auto;
    display: block;
`;

const Container = styled.div`
    max-width: 500px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 100px auto 0px auto;
    @media screen and (max-width: 460px) {
        margin: 40px auto 0px auto;
    }
`;

/* Domains */
import getDomainEnv from '../../domains/utils';
const env = getDomainEnv();

class IFramePasswordReset extends React.Component {
    state = {"isLoading": true};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({'isLoading': false})
    };

    home = () => window.open("https://"+env.URL.DOMAIN,"_self");

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <Helmet>
                        <link rel={"icon"} type={"image/png"} href={images.favicon16px} sizes={"16x16"} />
                        <meta charSet={"utf-8"} />
                        <title>Willow</title>
                        <link rel={"canonical"} href={window.location.href} />
                        <meta property={"og:type"} content={"website"} />
                        <meta property={"og:url"} content={window.location.href} />
                        <meta property={"og:site_name"} content={"Willow"} />
                    </Helmet>
                    <Container>
                        <Logo src={images.logo} alt={""} onClick={this.home} />
                        <PasswordResetFlow
                            resetSystem={"page"}
                            employerLogin={false}
                        />
                    </Container>
                </>
            )
        }
    }

}

export default IFramePasswordReset;