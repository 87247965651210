import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

/* Components */
import { Container, Extra54px, PageTitle, WriteToUsBtn, SmallCardRow, SmallCardRowImage, OpenLink, SpaceBetween, BackSection, Back, ChevronLeftRight, InlineBlock } from "../../learn/components/care";
import { LogoLoading } from "../../../common/components/loading";
import { FontTitle16, FontHeader16, FontBody16, FontBody12 } from '../../../common/components/fonts';
import { ButtonPrimary } from '../../../common/components/buttons';
import { SearchInputUncontrolled } from "../../../common/components/inputs";
import { colors } from "../../../common/components/colors";
import { images } from "../../../common/components/images";

/* Middleware */
import { trySearchForumPosts } from '../middleware/forum';
import { tryGetInsightParentCategories } from '../../learn/middleware/insights'
/* Store */
import { setCommunityForumSelected, setForumSearchTerm } from '../store/forum';

import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();


const SearchedPostTitle = styled(FontHeader16)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(100% - 24px);
    cursor: pointer;
`
const FlexCol = styled.div`
    max-width: calc(100% - 24px);
    display: flex;
    flex-direction: column;
`;

class ForumCategories extends React.Component {
    state = { "isLoading": true, "options": [], 'showSearchInput': true, "searchResults": [], 'term': "", "searchMade": false, "haltUpdateSearch": false };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.term !== "" && this.state.term === '' && !this.state.haltUpdateSearch) {
            return this.handleCloseSearch("updated");
        }
    }

    init = async () => {
        await this.props.tryGetInsightParentCategories();
        let mappedParentCategories = this.props.insightParentCategories.sort((a, b) => (a.get("title") > b.get("title")) ? 1 : ((b.get("title") > a.get("title")) ? -1 : 0))
        this.setState({
            "options": mappedParentCategories,
            "isLoading": false
        })
    };

    handleReturnResult = async (e) => {
        this.setState({ 'searchMade': true });
        await this.props.trySearchForumPosts(this.state.term);
    };

    searchMade = async (e) => {
        this.setState({ 'term': e });
        await this.props.setForumSearchTerm(e);
    };

    handleCloseSearch = async (trigger) => {
        if(trigger === "clicked") {
            await this.setState({"haltUpdateSearch": true});
        }
        await this.setState({'searchInput': "", 'searchMade': false, 'searchResults': [], 'term': ""});
        if(trigger === "clicked") {
            await this.setState({"haltUpdateSearch": false});
        } else {
            document.getElementById("search-insights").select();
        }
    };

    sendTo = async (category) => {
        await this.props.setCommunityForumCategorySelected(category.toJS());
        return this.props.history.push('/community/category');
    };

    isSearchMade = () => this.state.searchMade && this.state.term !== ""

    // selectPost = async (post) => {
    //     const profile = await this.props.setCommunityPostSelected(post);
    //     this.setState({ "selectedPost": post });
    //     await this.props.history.push('/community/posts/post');
    // }

    render() {
        if (this.state.isLoading) {
            return (<LogoLoading />)
        }
        let title = <>
            <Extra54px />
            <PageTitle>COMMUNITY</PageTitle>
        </>;
        if (this.isSearchMade()) {
            title = <>
                <BackSection>
                    <Back onClick={() => { return this.handleCloseSearch("clicked") }}>
                        <ChevronLeftRight src={images.chevronLeft} alt={""} />
                        <InlineBlock><FontBody16>{/*"Self-serve content"*/}</FontBody16></InlineBlock>
                    </Back>
                </BackSection>
                <PageTitle>SEARCH RESULTS</PageTitle>
            </>
        }

        let forumCategories = this.isSearchMade() ? null : this.state.options.map((a, index) => {
            return (
                <SmallCardRow
                    key={index} active={true}
                    onClick={() => { return this.sendTo(a) }}
                    even={index % 2 !== 0}
                >
                    <SmallCardRowImage src={a.get("photoUrl")} alt={""} />
                    <OpenLink active={true}><FontHeader16>{a.get("title")}</FontHeader16></OpenLink>
                </SmallCardRow>
            )
        });

        let searchResults = !this.isSearchMade() ? null :
        <>
                {this.props.searchResults && this.props.searchResults.size > 0 ?
                    <>
                        {this.props.searchResults.map((c, index) => {
                            return (
                                <SmallCardRow
                                    key={index} active={true}
                                    onClick={() => {return this.viewCourse(c)}}
                                    even={index % 2 !== 0}
                                >
                                    <FlexCol>
                                    <SearchedPostTitle>{c.get("title")}</SearchedPostTitle>
                                    <FontBody12>{c.get("author")}</FontBody12>
                                    </FlexCol>
                                </SmallCardRow>
                            )
                        })}
                    </>
                :
                <>
                    <br />
                    <SpaceBetween direction={"column"}>
                        <FontTitle16>No content found</FontTitle16>
                        <div onClick={() => {return this.props.history.push('/community/posts/create')}}>
                            <ButtonPrimary submit={false} label={"Create a post"} />
                        </div>
                    </SpaceBetween>
                </>
            }
        </>

        let searchInput = this.state.showSearchInput ?
            <SearchInputUncontrolled
                options={[]}
                id={'search-insights'}
                placeholder={'Search'}
                value={this.state.term}
                shouldShowResults={false}
                onReturnResults={this.handleReturnResult}
                onSearchMade={this.searchMade}
                searchIconStart={true}
                searchIconEnd={false}
                searchIconOnClick={false}
                containerStyles={{
                    margin: '0px'
                }}
                searchInputStyles={{
                    border: 'none',
                    padding: '12px 50px',
                    width: 'calc(100% - 100px)',
                    borderRadius: '15px',
                    backgroundColor: colors.border30,
                    placeholderColor: colors.primary30,
                    fontSize: '14px'
                }}
            /> : null;
        return (
            <Container>
                {title}
                {searchInput}
                <div>
                    <>
                        {searchResults}
                        {forumCategories}
                    </>
                    {/* {this.isSearchMade() ? null : 
                        <SpaceBetween direction={"row"}>
                            <FontTitle16>Do you want to ask a question?</FontTitle16>
                            <WriteToUsBtn onClick={() => { return this.props.history.push('/community/posts/create') }}>
                                <ButtonPrimary submit={false} label={"Create a post"} />
                            </WriteToUsBtn>
                        </SpaceBetween>
                    } */}
                </div>
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    insightParentCategories: state.consumer.learn.insights.get("insightParentCategories"),
    searchResults: state.consumer.forum.forum.get("forumSearchResults"),
    // parentCategories: state.consumer.care.coaching.get("parentCategories"),
    // subCategories: state.consumer.care.coaching.get("subCategories"),
});

const mapDispatchToProps = dispatch => ({
    tryGetInsightParentCategories: () => dispatch(tryGetInsightParentCategories()),
    setCommunityForumCategorySelected: (category) => dispatch(setCommunityForumSelected(category)),
    
    setForumSearchTerm: (term) => dispatch(setForumSearchTerm(term)),
    trySearchForumPosts: (term) => dispatch(trySearchForumPosts(term))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForumCategories));