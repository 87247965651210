import React from "react";
import {connect} from "react-redux";
import {Map} from "immutable";
import styled from 'styled-components';

/* Containers */
import IntroduceLink from "./introducelinks";

/* Middleware */
import {setClientAssignModalStatus} from '../store/coaching';

/* Components */
import {FontTitle30, FontBody14} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";
import {ButtonPrimary} from "../../../common/components/buttons";

const Modal = styled.div`
    margin: 0px auto;
    max-width: 380px;
    padding: 30px;
    border-radius: 6px;
    background-color: ${colors.white};
    position: fixed;
    top: 100px;
    left: 0px;
    right: 0px;
    ${props => props.big !== true} {max-height: 347px;}
    ${props => props.big !== false} {max-height: 280px;}
    height: 100%;
    @media screen and (max-width: 460px) {
        top: 20px;
        width: calc(100% - 20px);
        padding: 0px 10px;
    }
    z-index: 6;
`;
const Background = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.50);
    @media screen and (max-width: 460px) {
        background-color: ${colors.white};
    }
    z-index: 5;
`;

const FitContentFullWidth = styled.div`
    width: 100%;
    & > div {
        width: calc(100% - 47px);
        text-align: center;
        margin: 0px auto;
    }
`;
const Indent = styled.div`
    width: calc(100% - 40px);
    padding: 0px 20px;
`;
const Header = styled.div`
    border-bottom: 1px solid ${colors.secondary30};
    padding-bottom: 10px;
    margin-top: -10px;
    margin-bottom: 30px;
`;

class TeamIntroduceLinksModal extends React.Component {
    render() {
        if(this.props.clientAssignModalOpen) {
            return(
                <>
                    <Modal big={true}>
                        <Header>
                            <FontTitle30>Introduction Link</FontTitle30>
                        </Header>
                        <Indent>
                            <IntroduceLink
                                clientName={this.props.modalClientSelected.get("firstName")+" "+this.props.modalClientSelected.get("lastName")}
                                prospectId={this.props.modalClientSelected.get("prospectId")}
                                coachId={this.props.coachSelected.get("coachId")}
                                coachFirst={this.props.coachSelected.get("first")}
                            />
                            <br />
                            <br />
                            <FitContentFullWidth onClick={() => {return this.props.setClientAssignModalStatus(false, Map())}}>
                                <ButtonPrimary canSubmit={false} label={"Close"} />
                            </FitContentFullWidth>
                            <br />
                        </Indent>
                    </Modal>
                    <Background />
                </>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = state => ({
    coachSelected: state.enterprise.team.coaching.get("coachSelected"),
    modalClientSelected: state.enterprise.team.coaching.get("modalClientSelected"),
    clientAssignModalOpen: state.enterprise.team.coaching.get("clientAssignModalOpen"),
});

const mapDispatchToProps = dispatch => ({
    setClientAssignModalStatus: (status, client) => dispatch(setClientAssignModalStatus(status, client)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamIntroduceLinksModal);
