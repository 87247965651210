import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {v4 as uuid4} from 'uuid';
import {FontHeader21} from '../../../components/fonts'
import {colors} from '../../../components/colors'

/* Middleware */
import {tryGetSharedDriveList, tryCreateNewSharedDrive, tryGetSharedDriveFolderList, tryGetSharedDriveBetweenUser, tryDownloadFile, tryDeleteFile, tryDeleteFolder} from '../middleware/filevault';

/* Components */
import { Message } from "../../../components/messages";
import { TableWrapperV2 } from "../../../components/tablev2";
import { LogoLoading } from '../../../components/loading';

/* Containers */
import VaultNewFolder from './vaultnewfolder';
import VaultRename from './vaultrename';
import VaultDelete from './vaultdelete';
import VaultDeleteAll from './vaultdeleteall';
import VaultUpload from './vaultupload';
import VaultBreadcrumb from './vaultbreadcrumb';

/* Store */
import {setFolderOrFileSelected, setFolderOrFileRenameModal, setFolderOrFileDeleteModal, setFolderOrFileDeleteAllModal, setFolderOrFileUploadModal, setFolderNewModal} from '../store/filevault';
import {ButtonPrimary, ButtonInactiveLight} from "../../../components/buttons";
import {CloudUploadOutlined, PlusOutlined, DeleteOutlined, CloudDownloadOutlined, EditOutlined, LoginOutlined} from "@ant-design/icons";

/* Domain */
import getDomainEnv from '../../../../domains/utils';
const env = getDomainEnv();

const randomNumber = () => {return Math.floor(Math.random() * 100)+1};

const CreateDrive = styled.div`
    text-align: center;
    font-family: CooperLight;
    font-size: 30px;
`;
const ButtonCentered = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    width: fit-content;
`;
const ButtonLeft = styled.div`
    margin-top: 20px;
`;
const Gray = styled.div`
    color: ${colors.primary100};
    margin-bottom: 5px;
`;
const Options = styled.div`
    margin: 0px 0px 30px 0px;
`;
const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    margin-top: 10px;
`;
const Btn = styled.div`
    width: fit-content;
`;
const Margin = styled.div`
    margin-bottom: 50px;
`;

class SharedVault extends React.Component {
    constructor(props) {
        super(props);
        this.state = {"isLoading": true, "data": [], "folderId": null, "selected": [], "driveExists": false};
    }

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.folderListFetching && !this.props.folderListFetching || prevProps.folderList !== this.props.folderList) {
            this.update();
        }
    }

    init = async () => {
        await this.props.tryGetSharedDriveList();
        await this.setup();
    };

    setup = async () => {
        if (this.props.sharedDriveList !== undefined) {
            this.props.sharedDriveList.map((d) => {
                if (d.get("users") !== undefined) {
                    d.get("users").map(async (u) => {
                        if (u.get("userId") === this.props.coach.get("consumerId") && d.get("users").size === 2) {
                            this.setState({"driveExists": true});
                            await this.props.tryGetSharedDriveBetweenUser(this.props.coach.get("consumerId"));
                            if (this.props.drive.get("driveId") !== undefined) {
                                await this.props.tryGetSharedDriveFolderList(this.props.drive.get("driveId"), null, true);
                                await this.update();
                            }
                        }
                    })
                }
            });
        }
        if (!this.state.driveExists) {
            this.setState({"isLoading": false});
        }
    };

    createDrive = async () => {
        const driveName = this.props.coach.get("first") + " and " + this.props.userName + " " + randomNumber();
        await this.props.tryCreateNewSharedDrive(driveName, [this.props.coach.get("consumerId")]);
        await this.props.tryGetSharedDriveList();
        await this.props.tryGetSharedDriveBetweenUser(this.props.coach.get("consumerId"));
        await this.props.tryGetSharedDriveFolderList(this.props.drive.get("driveId"), null, true);
        await this.setState({"driveExists": true});
        let data = await this.createData(this.props.folderList);
        await this.setState({"data": data, "isLoading": false})
    };

    update = async () => {
        this.setState({
            "data": await this.createData(this.props.folderList),
            "isLoading": false,
            "selected": []
        });
    };

    createData = async (data) => {
        let folders = await this.loopFileVault(data.get("folders"), "folder");
        let files = await this.loopFileVault(data.get("files"), "file");
        return folders.concat(files);
    };

    loopFileVault = async (list, type) => {
        return Promise.all(list.map(d => {
            return Promise.resolve({
                "isChecked": false,
                "id": (type + d.get("id")),
                "name": d.get("name"),
                "size": (d.get("size") === undefined || d.get("size") === null) ? null : parseInt(d.get("size")),
                "documentIcon": d.get("contentType"),
                "documentType": type,
                "actions": {
                    "1": {"label": <><CloudDownloadOutlined />&nbsp;Download</>, "active": type === "file", "show": type === "file"},
                    "2": {"label": <><LoginOutlined />&nbsp;Open</>, "active": type === "folder", "show": type === "folder"},
                    "3": {"label": <><EditOutlined />&nbsp;Rename</>, "active": true, "show": true},
                    "4": {"label": <><DeleteOutlined />&nbsp;Delete</>, "active": true, "show": true},
                },
                "allData": {...d.toJS(), "id": (type + d.get("id")), "documentType": type, "documentIcon": d.get("contentType"), "team": false}
            })
        }));
    };

    /* DOCUMENT ACTIONS */

    isAnySelected = async (selected) => {
        this.setState({"selected": selected});
    };

    viewDoubleClick = async (data) => {
        if(data.documentType === "file") {
            this.download(data);
        } else {
            this.openFolder(data);
        }
    };

    openFolder = async (data) => {
        let id = parseInt(data.id.replace(data.documentType, ""));
        await this.props.tryGetSharedDriveFolderList(this.props.drive.get("driveId"), id, true);
        this.update();
    };

    rename = async (data) => {
        let id = parseInt(data.id.replace(data.documentType, ""));
        await this.props.setFolderOrFileSelected({...data, "id": id});
        this.props.setFolderOrFileRenameModal(true);
    };

    renameAll = async () => {
        this.state.selected.map(async s => {
            let data = await this.state.data.filter(d => {return(d.id === s)})[0];
            this.rename(data);
        });
    };

    download = async (data) => {
        let id = parseInt(data.id.replace(data.documentType, ""));
        // this.props.tryDownloadFile(id);
        this.tempDownload(id);
    };

    downloadAll = async () => {
        this.state.selected.map(async s => {
            let data = await this.state.data.filter(d => {return(d.id === s)})[0];
            let id = parseInt(data.id.replace(data.documentType, ""));
            // this.props.tryDownloadFile(id);
            this.tempDownload(id);
        });
    };

    tempDownload = (id) => {
        let tempLink = document.createElement("a");
        tempLink.href = "https://"+env.URL.API+"/filevault/file/download/"+id;
        tempLink.target = "_blank";
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
    };

    delete = async (data) => {
        let id = parseInt(data.id.replace(data.documentType, ""));
        await this.props.setFolderOrFileSelected({...data, "id": id});
        this.props.setFolderOrFileDeleteModal(true);
    };

    deleteAll = async () => {
        this.state.selected.map(async s => {
            let data = await this.state.data.filter(d => {return(d.id === s)})[0];
            let id = parseInt(data.id.replace(data.documentType, ""));
            if(data.documentType === "file") {
                await this.props.tryDeleteFile(id);
            } else {
                await this.props.tryDeleteFolder(id);
            }
        });
        await this.setState({"selected": [], "anyChecked": false})
    };

    createColumns = {
        "0": {"key": "id", "title": "Id", "titleShowOnDesktop": false, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "hidden", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": null, "onDoubleClick": null},
        "2": {"key": "isChecked", "title": "Select", "titleShowOnDesktop": false, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "checkbox", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": this.isAnySelected, "onDoubleClick": null},
        "3": {"key": "name", "title": "Name", "titleShowOnDesktop": true, "canSort": true, "sort": "asc", "sortType": "string", "sortKey": "name", "component": "fileOrFolder", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": this.isAnySelected, "onDoubleClick": this.viewDoubleClick},
        "4": {"key": "size", "title": "Size", "titleShowOnDesktop": true, "canSort": true, "sort": null, "sortType": "number", "sortKey": "size", "component": "fileSize", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": null, "onDoubleClick": null},
        "5": {"key": "team", "title": "Team", "titleShowOnDesktop": false, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "team", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": null, "onDoubleClick": null},
        "6": {"key": "actions", "title": "Actions", "titleShowOnDesktop": false, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "menu", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": {"1": this.download, "2": this.openFolder, "3": this.rename, "4": this.delete}, "onDoubleClick": null},
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <>
                    {(!this.state.driveExists) &&
                    <div>
                        <CreateDrive>Create a secure shared document vault between you and {this.props.coach.get("first") + " " + this.props.coach.get("last")}!</CreateDrive>
                        <ButtonCentered onClick={this.createDrive}><ButtonPrimary label={<><PlusOutlined /> Create Drive</>} canSubmit={true}/></ButtonCentered>
                    </div>
                    }
                    {(this.state.driveExists) &&
                    <>
                        <VaultBreadcrumb type={"shared"}/>
                        <Options>
                        <Inline><Btn onClick={() => {this.props.setFolderOrFileUploadModal(true)}}><ButtonPrimary canSubmit={false} label={<><CloudUploadOutlined /> Upload</>} /></Btn></Inline>
                        <Inline><Btn onClick={() => {this.props.setFolderNewModal(true)}}><ButtonInactiveLight canSubmit={false} label={<><PlusOutlined /> Folder</>} /></Btn></Inline>
                            {this.state.selected.length > 0 &&
                            <>
                                {(this.state.selected.length === 1 && this.state.selected[0].includes("file")) &&
                                    <Inline><Btn onClick={this.downloadAll}><ButtonInactiveLight canSubmit={false} label={<><CloudDownloadOutlined /> Download</>} /></Btn></Inline>
                                }
                                {this.state.selected.length === 1 &&
                                    <Inline><Btn onClick={this.renameAll}><ButtonInactiveLight canSubmit={false} label={<><EditOutlined /> Rename</>} /></Btn></Inline>
                                }
                                <Inline><Btn onClick={() => {this.props.setFolderOrFileDeleteAllModal(true)}}><ButtonInactiveLight canSubmit={false} label={<><DeleteOutlined /> Delete</>} /></Btn></Inline>
                            </>
                        }
                        </Options>
                        <TableWrapperV2
                            responsive={true}
                            tableColumns={this.createColumns}
                            tableData={this.state.data}
                            initSelected={null}
                        />
                        <Margin />
                        {this.state.data.length === 0 && <Message text={"Folder is empty"} />}
                        <VaultRename />
                        <VaultNewFolder />
                        <VaultDelete />
                        <VaultDeleteAll deleteAll={this.deleteAll} />
                        <VaultUpload />
                    </>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    coach: state.enterprise.team.coaching.get("coachSelected"),
    userName: state.common.user.get("first"),
    drive: state.common.fileVault.get("sharedDrive"),
    folderList: state.common.fileVault.get("folderList"),
    folderListFetching: state.common.fileVault.get("sharedDriveFolderListFetching"),
    sharedDriveList: state.common.fileVault.get("sharedDriveList")
});

const mapDispatchToProps = dispatch => ({
    tryGetSharedDriveList: () => dispatch(tryGetSharedDriveList()),
    tryCreateNewSharedDrive: (name, users) => dispatch(tryCreateNewSharedDrive(name, users)),
    tryGetSharedDriveFolderList: (driveId, folderId, storeBreadcrumb) => dispatch(tryGetSharedDriveFolderList(driveId, folderId, storeBreadcrumb)),
    tryGetSharedDriveBetweenUser: (consumerId) => dispatch(tryGetSharedDriveBetweenUser(consumerId)),
    setFolderOrFileSelected: (status) => dispatch(setFolderOrFileSelected(status)),
    setFolderOrFileRenameModal: (selected) => dispatch(setFolderOrFileRenameModal(selected)),
    setFolderOrFileDeleteModal: (selected) => dispatch(setFolderOrFileDeleteModal(selected)),
    setFolderOrFileDeleteAllModal: (status) => dispatch(setFolderOrFileDeleteAllModal(status)),
    setFolderNewModal: (status) => dispatch(setFolderNewModal(status)),
    setFolderOrFileUploadModal: (status) => dispatch(setFolderOrFileUploadModal(status)),
    tryDownloadFile: (id) => dispatch(tryDownloadFile(id)),
    tryDeleteFile: (fileId) => dispatch(tryDeleteFile(fileId)),
    tryDeleteFolder: (folderId) => dispatch(tryDeleteFolder(folderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SharedVault));