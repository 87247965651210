import React from 'react';
import styled from 'styled-components';

/* Components */
import {FontBody16, FontSubtitle28} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";
import {images} from "../../../common/components/images";
import {ButtonPrimary} from "../../../common/components/buttons";

const PageTitleSuccess = styled(FontSubtitle28)`
    text-align: center;
    margin-bottom: 30px;
`;
const PageSubtitle = styled(FontBody16)`
    text-align: center;
    color: ${colors.secondary100};
`;
const PageBody = styled(FontBody16)`
    text-align: center;
    color: ${colors.secondary100};
`;
const Center = styled.div`
    margin-top: 60px;
    text-align: center;
    color: ${colors.primary100};
`;
const SummaryIcon = styled.img`
    width: auto;
    margin: 60px auto 90px auto;
    height: 260px;
`;
const FitContent = styled.div`
    width: fit-content;
    cursor: pointer;
    margin: 0px auto 25px auto;
    & > div {
        min-width: 87px;
    }
`;

class Success extends React.Component {
    static defaultProps = {
        back: () => {},
        title: "",
        subtitle: "",
        icon: null,
        body: "",
        buttonLabel: "",
        buttonShow: true
    };

    render() {
        return(
            <Center>
                <PageTitleSuccess>{this.props.title.toUpperCase()}</PageTitleSuccess>
                {this.props.subtitle !== "" && <PageSubtitle>{this.props.subtitle}</PageSubtitle>}
                <SummaryIcon src={this.props.icon === null ? images.completeIcon : this.props.icon}/>
                {this.props.body !== "" && <PageBody>{this.props.body}</PageBody>}
                {this.props.buttonShow &&
                    <FitContent onClick={this.props.back}>
                        <ButtonPrimary submit={false} label={this.props.buttonLabel}/>
                    </FitContent>
                }
            </Center>
        )
    }
}

export default Success;
