import {axiosReq} from "../../common/utils/axios";
import {Map, List} from 'immutable';

/* Store */
import {
    setProspectStages,
    setProspectStagesReading,
    setProspectStagesCreating,
    setProspectStagesUpdating,
    setProspectStagesDeleting,
    setProspectStagesOrdering,
    setProspectStagesFetched,
    setAllProspectsEditExistingProspect,
    setAllProspectsRemoveProspect,
    setAllProspectsAddNewProspect
} from "../store/prospects";
import { setAllClientsAddNewClient, setAllClientsRemoveClient } from "../clients/store/clients";

/* Middleware */
import { tryGetOneClient } from '../clients/middleware/clients';
import { tryGetOneProspect } from './prospects';

/* Static */
import { devEnterpriseProspectStageData, devEnterpriseProspectStageCreateData,} from '../static/devdata';

const randomNumber = () => {return Math.floor(Math.random() * 100)+1};

export const tryGetProspectStages = () => {
    return async (dispatch, getState) => {
        dispatch(setProspectStagesReading(true));
        const stages = await axiosReq('apiv1/coach/settings/prospect-status-types', "GET", {}, devEnterpriseProspectStageData(getState().enterprise.prospects.get("prospectStages")));
        await dispatch(setProspectStages(stages.prospectStatusTypes, ''));
        await dispatch(setProspectStagesFetched());
        return dispatch(setProspectStagesReading(false));
    }
};

export const tryCreateProspectStage = (title) => {
    return async (dispatch, getState) => {
        dispatch(setProspectStagesCreating(true));
        const stage = await axiosReq('apiv1/coach/settings/prospect-status-types', "POST", {title: title}, devEnterpriseProspectStageCreateData(randomNumber(), title, getState().enterprise.prospects.get("prospectStages").size+1));
        const stageModified = getState().enterprise.prospects.get("prospectStages").push(Map(stage));
        await dispatch(setProspectStages(stageModified, ''));
        dispatch(setProspectStagesCreating(false));
        return stage.id;
    }
};

export const tryUpdateTitleProspectStage = (id, title) => {
    return async (dispatch, getState) => {
        dispatch(setProspectStagesUpdating(true));
        const stage = await axiosReq('apiv1/coach/settings/prospect-status-types/'+id, "PATCH", {title: title}, {});
        const stageModified = getState().enterprise.prospects.get("prospectStages").map(f => {
            if(f.get("id") === id) {
                return Map({...f.toJS(), "title": title})
            } else {
                return f;
            }
        });
        await dispatch(setProspectStages(stageModified, ''));
        return dispatch(setProspectStagesUpdating(false));
    }
};

export const tryUpdateOrderProspectStage = (ids) => {
    return async dispatch => {
        dispatch(setProspectStagesOrdering(true));
        const stage = await axiosReq('apiv1/coach/settings/prospect-status-types/order', "POST", {ids: ids}, {});
        return dispatch(setProspectStagesOrdering(false));
    }
};

export const tryDeleteProspectStage = (id) => {
    return async (dispatch, getState) => {
        dispatch(setProspectStagesDeleting(true));
        // Find undefined stage id
        const undefinedStageId = await getState().enterprise.prospects.get("prospectStages").filter(u => {return u.get("title") === "undefined";}).get(0).get("id");
        // Move all prospects in deleted stage to undefined
        const prospects = getState().enterprise.prospects.get("prospectsAll");
        await Promise.all(prospects.map(p => {
            if(p.get("prospectStageId").toString() === id.toString()) {
                Promise.resolve(dispatch(tryUpdateProspectStage({"prospectId": p.get("prospectId"), "stageId": undefinedStageId, "fromStatus": "Prospect", "toStatus": "Prospect"})));
            }
        }));
        await axiosReq('apiv1/coach/settings/prospect-status-types/'+id, "DELETE", {}, {});
        const stages = getState().enterprise.prospects.get("prospectStages");
        const stageModified = stages.filter(f => {return f.get("id") !== id});
        await dispatch(setProspectStages(stageModified, ''));
        return dispatch(setProspectStagesDeleting(false));
    }
};

const tryDelete = (data) => {
    return async dispatch => {
        if(data.toDelete.length > 0) {
            return Promise.all(data.toDelete.map(async d => {console.log("del");return Promise.resolve(dispatch(tryDeleteProspectStage(d.id)))}));
        }
    }
};
const tryAdd = (data) => {
    return async dispatch => {
        if(data.toAdd.length > 0) {
            return Promise.all(data.toAdd.map(async d => {console.log("add");return Promise.resolve(dispatch(tryCreateProspectStage(d.title)))}));
        }
    }
};
const tryUpdateTitle = (data) => {
    return async dispatch => {
        if(data.toUpdateTitle.length > 0) {
            return Promise.all(data.toUpdateTitle.map(async d => {console.log("title");return Promise.resolve(dispatch(tryUpdateTitleProspectStage(d.id, d.title)))}));
        }
    }
};
const tryReorder = (data) => {
    return async (dispatch, getState) => {
        // Order stages to have undefined then clients at bottom
        if(data.stages.length > 1) {
            console.log("order");
            console.log(data.stages);
            // Find the static stage ids
            let staticUndefined = data.stages.filter(u => {return(u.title === "undefined")})[0];
            let staticClient = data.stages.filter(u => {return(u.title === "client")})[0];
            let staticUndefinedId = staticUndefined !== undefined && staticUndefined.id;
            let staticClientId = staticClient !== undefined && staticClient.id;
            // Remove the static stages from the array
            let stagesNoStatic = data.stages.filter(u => {return(u.title !== "client" && u.title !== "undefined")});
            // Re-order non static stages
            return Promise.all(List(stagesNoStatic.map(async b => {
                // Fix title not matching, use ids. only important when toUpdateTitle changed, note change f.get("id") and b.id to title for dev server to work
                let test = getState().enterprise.prospects.get("prospectStages").filter(f => {return((f.get("id") === b.id || f.get("title") === b.title))}).get(0);
                return Promise.resolve(test !== undefined && test.get("id"));
            })))
                .then(async (result) => {
                    console.log(result);
                    staticUndefinedId && result.unshift(staticUndefinedId);
                    console.log(staticUndefinedId);
                    staticClientId && result.push(staticClientId);
                    console.log(staticClientId);
                    console.log(result);
                    return Promise.resolve(dispatch(tryUpdateOrderProspectStage(result)));
                })
        } else {
            return Promise.resolve();
        }
    }
};

export const trySaveProspectStages = (data) => {
    return async dispatch => {
        await dispatch(tryDelete(data));
        await dispatch(tryUpdateTitle(data));
        await dispatch(tryAdd(data));
        console.log("read");
        await dispatch(tryGetProspectStages());
        await dispatch(tryReorder(data));
    }
};

const convertProspectToClient = (data) => {
    return async dispatch => {
        const oneClient = await dispatch(tryGetOneClient(data.prospectId, "return"));
        dispatch(setAllClientsAddNewClient(oneClient));
        dispatch(setAllProspectsRemoveProspect(data.prospectId));
    }
};

const convertClientToProspect = (data) => {
    return async dispatch => {
        const oneProspect = await dispatch(tryGetOneProspect(data.prospectId, "return"));
        dispatch(setAllProspectsAddNewProspect(oneProspect));
        dispatch(setAllClientsRemoveClient(data.prospectId));
    }
};

const changeProspectStage = (data) => {
    return async dispatch => {
        const stageTitle = await dispatch(getProspectStageTitleById(data.stageId));
        dispatch(setAllProspectsEditExistingProspect(data.prospectId, {"prospectStageId": data.stageId, "prospectStageName": stageTitle}));
    }
};

export const tryUpdateProspectStage = (data) => {
    return async dispatch => {
        dispatch(setProspectStagesUpdating(true));
        await axiosReq('apiv1/coach/prospects/'+data.prospectId+'/prospect-status', "PUT", {coachProspectStatusTypeId: data.stageId}, {});
        if(data.fromStatus === "Prospect" && data.toStatus === "Client") {
            await dispatch(convertProspectToClient(data))
        } else if(data.fromStatus === "Client" && data.toStatus === "Prospect") {
            await dispatch(convertClientToProspect(data))
        } else if(data.fromStatus === "Prospect" && data.toStatus === "Prospect") {
            await dispatch(changeProspectStage(data));
        }
        return dispatch(setProspectStagesUpdating(false));
    }
};

export const getProspectStageTitleById = (id) => {
    return async (dispatch, getState) => {
        return getState().enterprise.prospects.get("prospectStages").filter(s => {return s.get("id") === id}).get(0).get("title");
    }
};

export const getProspectStageIdByTitle = (title) => {
    return async (dispatch, getState) => {
        return getState().enterprise.prospects.get("prospectStages").filter(s => {return s.get("title") === title}).get(0).get("id");
    }
};

export const stageOptions = (stages) => {
    const test = stages.map(s => {
        return({
            value: s.get("id"),
            label: s.get("title").charAt(0).toUpperCase() + s.get("title").slice(1)
        })
    });
    return test.filter(t => {
        return t.label !== "Client";
    }).toJS();
};

/*
GET     apiv1/coach/settings/prospect-status-types                  get all statuses            [QA]
POST    apiv1/coach/settings/prospect-status-types                  create new status           [QA]
GET     apiv1/coach/settings/prospect-status-types/prospectId       get one status              [IGNORE]
PATCH   apiv1/coach/settings/prospect-status-types/prospectId       update one status title     [QA]
DELETE  apiv1/coach/settings/prospect-status-types/prospectId       delete one status           [QA]
POST    apiv1/coach/settings/prospect-status-types/order            re-order statuses           [QA]
GET     apiv1/coach/prospects/prospectId/prospect-status            get one prospect status     [IGNORE]
PUT     apiv1/coach/prospects/prospectId/prospect-status            assign status to prospect   [QA]
 */