import { combineReducers } from 'redux';

/* Common */
import forumReducer from './store/forum';

const forumReducers = combineReducers({
    forum: forumReducer,
});

export default forumReducers;
