import React from 'react';
import styled from 'styled-components';
import validator from "validator";
import {connect} from 'react-redux';

/* Components */
import {TextInput, LinkStyled} from "../../../common/components/inputs";
import {InlineBlock, SaveSection, SubmitInputWithValidation} from "../../../enterprise/components/clientscreate";
import {
    FontHeader18,
    FontBody14,
    FontBody16,
    FontHeader16,
    FontBody12,
    FontSubtitle28,
    FontSubtitle24To20,
} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";
import {ButtonTertiary} from "../../../common/components/buttons";
import { Background, Button, Flex, VideoContainer, VideoPlayer, BackSection, Back } from '../../../consumer/home/components/home';
import { videos } from '../../../common/components/videos';
import { ChevronLeft } from "react-feather";
import { FlexRow } from '../../../common/components/layout';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

/* Middleware */
import {trySignUpTestExists, tryGetExistingInfo} from '../../signup/middleware/auth';
import {trySendPasswordResetEmail} from '../middleware/passwordreset';

export const Left = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 150px;
    @media screen and (max-width: 460px) {
        width: 100%;
        display: block;
    }
`;
export const Right = styled.div`
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 150px);
    text-align: right;
    margin-top: 8px;
    color: ${colors.primary70};
    @media screen and (max-width: 460px) {
        width: 100%;
        display: block;
        text-align: left;
    }
`;
export const LoginBtn = styled.div`
    color: ${colors.action100};
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
`;

const Red = styled(FontBody14)`
    color: ${colors.alert100};
`;

const Container = styled.div`
    max-width: 768px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 0px auto 0px auto;
`;

export const HalfAlign = styled.div`
    display: inline-block;
    vertical-align: top;
    ${props => props.side !== "left"} {
        width: 49%;
        text-align: left;
        margin-right: 2%;
    }
    ${props => props.side !== "right"} {
        width: 49%;
        text-align: right;
    }
    margin-bottom: -15px;
`;

const FitContent = styled.div`
    width: fit-content;
`;

const OnlySubmit = styled.div`
    margin: 3px auto 0px auto;
    text-align: center;
    & button > div {
        min-width: 160px;
    }
`;

const Intro = styled(FontBody14)`
    margin: 0px auto 40px auto;
    text-align: center;
    color: ${colors.white};
`;

const Cancel = styled(FontBody14)`
    margin: 20px auto 0px auto;
    text-align: center;
    color: ${colors.white};
    cursor: pointer;
    width: fit-content;
`;

const Message = styled(FontSubtitle28)`
    margin: 85px auto 50px auto;
    width: fit-content;
    letter-spacing: normal;
`;

const LocalLink = styled.div`
    color: ${colors.action100};
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
`;

const GrayLink = styled.div`
    color: ${colors.secondary70};
    display: inline-block;
    vertical-align: top;
    text-decoration: underline;
    font-size: 16px;
`;

const formStyleSystem = {
    showBackBtn: false,
    pageTitle: "",
    pageIntroText: "Enter the email address you registered with to be sent a password reset:",
    showTitle: true,
    showLoginLink: true,
    submitLabel: "Continue",
    inputsEmailLabel: "Email",
    inputsEmailPlaceholder: "Email",
    inputsEmailWarning: "Enter a valid email address",
    inputsContainersStyles: {},
    inputsStyles: {},
    errorMessage: "No user with this email exists.",
    containerStyles: {},
    alwaysAllowSubmit: false,
    onlyShowSubmit: false

};

const pageStyleSystem = (employerLogin) => ({
    showBackBtn: false,
    pageTitle: employerLogin ? "Set up your account!" : "Forgot your password?",
    pageIntroText: employerLogin ? "Enter the email address your employer provided. This may be a work or personal email address." : "Enter the email address you registered with to be sent a password reset:",
    showTitle: false,
    showLoginLink: false,
    submitLabel: employerLogin ? "Register" : "Send password reset",
    inputsEmailLabel: "",
    inputsEmailPlaceholder: employerLogin ? "Email address" : "Registered email address",
    inputsEmailWarning: "",
    containerStyles: {backgroundColor: colors.backgroundColor2, borderRadius: "20px", padding: "30px 45px", width: "calc(100% - 90px)"},
    inputsContainersStyles: {margin: "0px 0px 20px 0px"},
    inputsStyles: {borderRadius: "25px", lineHeight: "36px", backgroundColor: colors.white},
    errorMessage: employerLogin ? "Unable to find your account." : "No user with this email exists.",
    alwaysAllowSubmit: false, // true stops local validation
    onlyShowSubmit: true,
    needHelpStyles: {width: "fit-content", margin: "0px auto 20px auto", color: colors.white, textDecoration: "underline", fontWeight: "bold", display: "block"},
    wmcEmployee: employerLogin,
});

class EmailInput extends React.Component {
    static defaultProps = {
        getEmail: () => {},
        next: () => {},
        back: () => {},
        login: () => {},
        showBackBtn: false,
        styleSystem: "form",
        employerLogin: false,
    };

    state={
        ...formStyleSystem,
        "email": "", "emailValid": true,
        "userExists": true,
        "isChanged": false,
        "isSaving": false,
        "isLoading": true,
        "showHelpVideo": false,
        "wmcEmployee": false
    };

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        clearInterval(this.timeout);
    }

    init = async () => {
        this.setState({
            ...(this.props.styleSystem === "page" ? pageStyleSystem(this.props.employerLogin) : formStyleSystem),
            "showBackBtn": this.props.showBackBtn,
            'isLoading': false
        })
    };

    handleChangeEmail = async e => {
        const newEmail = e.target.value.trim();
        await this.setState({
            'email': newEmail,
            'emailValid': validator.isEmail(newEmail),
            'isChanged': true,
        });
        //tryGetExistingInfo(newEmail);
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        await this.checkIfWmc();
        await this.props.trySignUpTestExists(this.state.email, '');
        if (this.props.iframeAuthCheckHasAccountError === null) {
            this.setState({"userExists": false});
            this.timeout = setTimeout(() => {this.setState({"userExists": true})}, 4000);
        }
        else {
            this.checkIfWmc();
            {(!this.props.employerLogin || this.state.wmcEmployee)  &&
                await this.props.trySendPasswordResetEmail(this.state.email);
                await this.props.getEmail(this.state.email);
                await this.props.next(); 
            }            
        }
    };

    checkIfWmc = async () => {
        console.log("wmc check", this.state.wmcEmployee);
        await this.props.tryGetExistingInfo(this.state.email);
        console.log("results")
        console.log(this.props.iframeAuthUserExistingInfo)
        await this.setState({"wmcEmployee" : (this.props.iframeAuthUserExistingInfo.get("lilyProspectWmcId") !== null)});
        console.log("wmc check 2", this.state.wmcEmployee);
        this.setState({"errorMessage": this.state.wmcEmployee ? this.state.errorMessage : "Unable to find your email. Please use login link if you already have an account."})
    } 

    toggleVideo = () => this.setState({"showHelpVideo": !this.state.showHelpVideo});

    render() {
        return(
            <>
                {this.props.styleSystem === "page" && <Message>{this.state.pageTitle}</Message>}
                <Container style={this.state.containerStyles}>
                    <form method={"post"} onSubmit={this.handleSubmit}>
                        {(this.state.showTitle || this.state.showLoginLink) &&
                            <div>
                                <Left>{this.state.showTitle && <FontHeader18>Set Your Password</FontHeader18>}</Left>
                                <Right>{this.state.showLoginLink && <FontBody16>Know your password?&nbsp;<LoginBtn onClick={this.props.login}><FontHeader16>Login</FontHeader16></LoginBtn></FontBody16>}</Right>
                            </div>
                        }
                        {this.props.styleSystem === "page" && <Intro>{this.state.pageIntroText}</Intro>}
                        <TextInput
                            title={this.state.inputsEmailLabel}
                            valid={this.state.emailValid}
                            warning={this.state.inputsEmailWarning}
                            id={"email"}
                            onChange={this.handleChangeEmail}
                            placeholder={this.state.inputsEmailPlaceholder}
                            value={this.state.email}
                            containerStyles={this.state.inputsContainersStyles}
                            inputStyles={this.state.inputsStyles}
                        />
                        <Red>{!this.state.userExists ? this.state.errorMessage : ""}</Red>
                        <br />
                        {this.state.onlyShowSubmit
                            ?
                                <OnlySubmit>
                                    {this.props.employerLogin && <LocalLink onClick={this.toggleVideo} style={this.state.needHelpStyles}>
                                        <FontBody14>Need help?</FontBody14>
                                    </LocalLink>}
                                    <SubmitInputWithValidation
                                        label={this.state.submitLabel}
                                        isChanged={this.state.alwaysAllowSubmit ? true : this.state.isChanged}
                                        isValid={this.state.alwaysAllowSubmit ? true : this.state.emailValid}
                                        isSaving={this.state.isSaving}
                                    />
                                    {!this.props.employerLogin 
                                    ? <Cancel onClick={this.props.back}>Cancel</Cancel>
                                    : <>
                                        <br></br> 
                                        <FontBody16> Already Registered? <LinkStyled target={"_blank"} href={"https://"+env.URL.DOMAIN+"/login"}><GrayLink>Login Here</GrayLink></LinkStyled></FontBody16>
                                        </>
                                    }
                                    
                                </OnlySubmit>
                            :
                                <SaveSection>
                                    <HalfAlign side={"left"}>
                                        {this.state.showBackBtn && <InlineBlock><FitContent onClick={this.props.back}><ButtonTertiary canSubmit={false} label={"Back"} /></FitContent></InlineBlock>}
                                    </HalfAlign>
                                    <HalfAlign side={"right"}>
                                        <InlineBlock>
                                            <SubmitInputWithValidation
                                                label={this.state.submitLabel}
                                                isChanged={this.state.alwaysAllowSubmit ? true : this.state.isChanged}
                                                isValid={this.state.alwaysAllowSubmit ? true : this.state.emailValid}
                                                isSaving={this.state.isSaving}
                                            />
                                        </InlineBlock>
                                    </HalfAlign>
                                </SaveSection>
                        }
                    </form>
                </Container>
                {this.state.showHelpVideo && 
                    <Flex>
                        <VideoContainer>
                            <BackSection>
                                <Back onClick={this.toggleVideo}><ChevronLeft/></Back>
                            </BackSection>
                            <FlexRow style={{justifyContent: 'center', marginBottom: '20px'}}>
                                <FontSubtitle24To20>{'WELCOME TO WILLOW'}</FontSubtitle24To20>
                            </FlexRow>
                            <VideoPlayer
                                src={videos.onboardingLong}
                                controls={true}
                            />
                        </VideoContainer>
                        <Background onClick={this.toggleVideo} />
                    </Flex>
                }
            </>
        )
    }
}

const mapStateToProps = state => ({
    iframeAuthCheckHasAccountError: state.iframe.signup.auth.get("iframeAuthCheckHasAccountError"),
    iframeAuthUserExistingInfo: state.iframe.signup.auth.get("iframeAuthUserExistingInfo"),
});

const mapDispatchToProps = dispatch => ({
    trySignUpTestExists: (email, phone) => dispatch(trySignUpTestExists(email, phone)),
    trySendPasswordResetEmail: (email) => dispatch(trySendPasswordResetEmail(email)),
    tryGetExistingInfo: (email) => dispatch(tryGetExistingInfo(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailInput);
