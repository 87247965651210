import React from "react";
import styled from "styled-components";

/* Components */
import {colors} from "../../../common/components/colors";

/* Utility */
import { hexToRGB, brightness } from '../../../common/utils/colors';

export const SettingsCard = styled.div`
    background-color: ${props => props.backgroundColor};
    border-radius: 20px;
    color: ${props => props.backgroundColor ? (brightness(hexToRGB(props.backgroundColor, false)) < 130 ? colors.white : colors.primary100) : colors.white};
    padding: ${props => props.zIndex < 3 ? '55px 30px 20px 30px' : '20px 30px 20px 30px'};
    max-width: 1500px;
    width: calc(100% - 40px);
    margin: 30px auto 0px auto;
    z-index: ${props => props.zIndex};
    & input {
        border-radius: 20px;
        background-color: ${colors.white};
        border: none;
        line-height: 25px;
    }
    & .Dropdown-control {
        border-radius: 20px;
        background-color: ${colors.white};
        border: none;
    }
    & .Dropdown-menu {
        background-color: ${colors.white};
        border: 1px solid ${colors.backgroundColor3};
        border-radius: 6px;
    }
    & form > div > div {
        color: ${colors.primary100};
    }
    & Button {
        margin: 20px 0px 0px auto;
    }
    @media screen and (max-width: 748px) { 
        padding: ${props => props.zIndex < 3 ? '55px 20px 20px 20px' : '20px 20px 20px 20px'};
    }
`;

export const InlineButton = styled.div`
    display: inline-block;
    vertical-align: top;
    width: fit-content;
    margin-right: 7px;
    margin-bottom: 10px;
    @media screen and (max-width: 460px) {
        display: block;
        & div {
            margin: 0px auto;
        }
    }
`;


export const Left = styled.div`
    width: 200px;
    display: inline-block;
    vertical-align: middle;
    & img {
        border-radius: 4px;
    }
    @media screen and (max-width: 748px) {   
        display: block;
        width: 100%;
    }
    padding: 5px 0px;
`;
export const Right = styled.div`
    width: calc(100% - 240px);
    display: inline-block;
    vertical-align: middle;
    flex-direction: row;
    padding: 5px 0px;
    @media screen and (max-width: 748px) {   
        display: block;
        width: 100%;
        padding: 20px 0px 0px 0px;
    }
`;

export const Left2 = styled.div`
    width: calc(100% - 450px);
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    padding: 10px 20px;
    & img {
        border-radius: 4px; 
    }
    @media screen and (max-width: 748px) {   
        display: block;
        width: 100%;
        padding: 5px 0px 0px 0px;
    }
`;
export const Right2 = styled.div`
    width: fit-content;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    @media screen and (max-width: 748px) {   
        display: block;
        width: 100%;
        padding: 5px 0px 0px 0px;
    }
`;
export const Right3 = styled.div`
    width: fit-content;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    @media screen and (max-width: 748px) {   
        display: none;
    }
`;
export const Right4 = styled.div`
    display: none;
    @media screen and (max-width: 748px) {  
        display: block;
        width: fit-content;
        cursor: pointer;
        margin: 5px 0px;
        color: ${colors.action100}
    }
`;

export const Padding5 = styled.div`
    padding-bottom: 5px;
`;

export const NoActivePlans = styled.div`
    text-align: center;
    @media screen and (max-width: 640px) {
        padding-bottom: 20px;
    }
`;

export const CurrentPlanRow = styled.div`
    padding-top: 30px;
`;

export const FlexRowJCSB = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-around;
    align-items: center;
    padding-top: 20px;
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 0.75fr 2fr;
    grid-gap: 5px;
`;