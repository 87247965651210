import React from 'react';
// Convert form object to message/note readable in quill

export const createNote = async (form) => {
    let note = [];

    await Promise.all(form.map(async (f, fIndex) => {

        if(fIndex > 0) {
            note.push('<p><br /><br /></p>');
        }

        note.push('<p><b>Page&nbsp;'+(fIndex+1)+':&nbsp;'+f.header.title+'</b></p>');
        note.push('<p><br /></p>');

        await Promise.all(f.elements.map(async (e, eIndex) => {

            if(e.type === "textArea" || e.type === "textarea") {
                note.push('<p class="ql-indent-1"><b>Question '+(eIndex+1)+'.&nbsp;'+(e.title === undefined ? e.subtitle : e.title)+'</b></p>');
                note.push('<p class="ql-indent-1">('+e.placeholder+')</p>');
                // note.push('<p>(Textarea)</p>');
                note.push('<p class="ql-indent-2">'+e.text+'</p>');
            }

            if(e.type === "checkBox" || e.type === "checkbox") {
                note.push('<p class="ql-indent-1"><b>Question '+(eIndex+1)+'.&nbsp;'+(e.title === undefined ? e.subtitle : e.title)+'</b></p>');
                note.push('<p class="ql-indent-1">'+(e.body === undefined ? e.disclosure : e.body)+'</p>');
                // note.push('<p>(Checkbox)</p>');
                await Promise.all(e.options.map((c, cIndex) => {
                    if(c.active) {
                        note.push('<p class="ql-indent-2">'+(cIndex+1)+'.&nbsp;'+c.label+'</p>');
                    }
                }));
            }

            if(e.type === "radioBox" || e.type === "radiobox") {
                note.push('<p class="ql-indent-1"><b>Question '+(eIndex+1)+'.&nbsp;'+(e.title === undefined ? e.subtitle : e.title)+'</b></p>');
                note.push('<p class="ql-indent-1">'+(e.body === undefined ? e.disclosure : e.body)+'</p>');
                // note.push('<p>(radioBox)</p>');
                await Promise.all(e.options.map((c, cIndex) => {
                    if(c.active) {
                        note.push('<p class="ql-indent-2">'+(cIndex+1)+'.&nbsp;'+c.label+'</p>');
                    }
                }));
            }

            if(e.type === "specialCoaching" || e.type === "specialcoaching") {
                note.push('<p class="ql-indent-1"><b>Question '+(eIndex+1)+'.&nbsp;'+(e.title === undefined ? e.subtitle : e.title)+'</b></p>');
                note.push('<p class="ql-indent-1">'+e.body+'</p>');
                // note.push('<p>(Coaching Style)</p>');
                await Promise.all(e.options.map((s, sIndex) => {
                    if(s.active) {
                        note.push('<p class="ql-indent-2">'+(sIndex+1)+'.&nbsp;'+s.title+'</p>');
                    }
                }));
            }

        }));

    }));

    return (note.join(""));
};