
import Nav from "../navbars/containers/nav";
import {PageContentContainer, PageFull, PageContent} from "../navbars/components/navlayouts";
import React from "react";
import CertificationTrainingsList from "./containers/list";

import {withRouter} from "react-router-dom";
// import CertManage from "./containers/manage";
// import CertBenefits from "./containers/benefits";
// import FirmActivateCertification from "./containers/activatecertification";

function FirmCertification() {
    return (
        <>
            <Nav />
            <PageFull>
                <PageContentContainer fullPage={
                    location.pathname === "/my-certificates" ||
                    location.pathname === "/elective-trainings" ||
                    location.pathname.includes("/my-certificates/my-coaches") ||
                    location.pathname.includes("/my-certificates/profile")
                }>
                    <PageContent>

                        {/*{((location.pathname === "/my-certificates")  ||*/}
                        {/*    (location.pathname === "/my-certificates/manage") ||*/}
                        {/*    (location.pathname === "/my-certificates/benefits") ||*/}
                        {/*        (location.pathname.includes("/my-certificates/profile")))*/}
                        {/*    &&*/}
                            <CertificationTrainingsList />
                        {/*}*/}
                        {/*{((location.pathname === "/my-certificates/activate") || (location.pathname === "/my-certificates/activate/checkout") || (location.pathname === "/my-certificates/renew")|| (location.pathname === "/my-certificates/renew/checkout")) &&*/}
                        {/*     (<FirmActivateCertification />)}*/}


                    </PageContent>
                </PageContentContainer>
            </PageFull>
        </>
    );
}

export default withRouter(FirmCertification);

