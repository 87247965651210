import React from 'react';

/* Navs */
import Nav from "../navbars/containers/nav";
import {PageContentContainer, PageFull, PageContent} from "../navbars/components/navlayouts";

/* Containers */
// import FirmPlansFlow from './containers/flow';
import FirmPlansGrid from "./containers/plansgrid";


const FirmPlans = () => {
    return (
        <>
            <Nav />
            <PageFull>
                <PageContentContainer fullPage={true}>
                    <PageContent>

                        {/*<FirmPlansFlow />*/}
                        <FirmPlansGrid />

                    </PageContent>
                </PageContentContainer>
            </PageFull>
        </>
    );
}

export default FirmPlans;