import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import {connect} from "react-redux";

/* Middleware */
import {tryLogout} from '../../../routes/middleware/auth';

/* Components */
import { SettingOutlined, CaretDownOutlined } from '@ant-design/icons';
import { FontHeader16, FontHeader12, FontBody16 } from '../../../common/components/fonts';
import {NavRight, User, Background, DropdownOptions, Firm, Logo, NavTop, Option, Privacy, Settings,
    SignOut, UserDropdown, UserLeft, UserName, UserRight, UserSelection} from '../components/primarytop';

/* Containers */
import NavSecondaryMobile from './secondarymobile';

/* Static */
import {images} from '../../../common/components/images'

/* Store */
import {setSupportOpen} from "../../support/store/support";
import {setPlatformTo} from '../../../routes/store/user';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

class NavPrimaryTop extends React.Component {
    state = {"dropdownOpen": false, "showConsumer": false};

    componentDidMount() {
        if ((this.props.user.get("wmc") !== null || this.props.user.get("isCoach")) && this.props.user.get("isLily")) {
            this.setState({"showConsumer": true});
        }
    }

    openSupport = async (isOpen, page) => {
        this.setState({"dropdownOpen": !this.state.dropdownOpen});
        this.props.setSupportOpen(isOpen, page);
    };

    openConsumerDashboard = async () => {
        this.props.setPlatformTo("consumer")
    };

    render() {
        return(
            <NavTop>
                <Logo>
                    <Link to={this.props.data.logo}>
                        <img src={images.logo} height="32" width="160" alt={"Willow"} />
                    </Link>
                </Logo>
                <NavRight>
                    {/*<Search></Search>*/}
                    {/*<Link to={"/settings/basic-info"}><Settings><SettingOutlined /></Settings></Link>*/}
                    <User onClick={() => this.setState({"dropdownOpen": !this.state.dropdownOpen})}>
                        <UserSelection>
                            <UserLeft>
                                <UserName hasCompanyName={((this.props.user.get("wmc") !== null && this.props.user.get("wmc") !== undefined && this.props.user.get("wmc").size > 0) && this.props.user.get("wmc").get("companyName") !== "")}>
                                    <FontHeader16>{this.props.user.get("first")} {this.props.user.get("last")}</FontHeader16>
                                </UserName>
                                <Firm><FontBody16>{(this.props.user.get("wmc") !== null && this.props.user.get("wmc") !== undefined && this.props.user.get("wmc").size > 0) && this.props.user.get("wmc").get("companyName")}</FontBody16></Firm>
                            </UserLeft>
                            {/*<UserRight>*/}
                            {/*    <CaretDownOutlined />*/}
                            {/*</UserRight>*/}
                        </UserSelection>
                    </User>
                    {/*{this.state.dropdownOpen &&*/}
                    {/*    <UserDropdown>*/}
                    {/*        <DropdownOptions>*/}
                    {/*            /!*<div onClick={() => {return this.props.history.push("/billing")}}><Option><FontBody16>Account & Billing</FontBody16></Option></div>*!/*/}
                    {/*            <div onClick={() => {return this.openSupport(true, null)}}><Option><FontBody16>Support</FontBody16></Option></div>*/}
                    {/*            <div onClick={() => {return this.openSupport(true, 6)}}><Option><FontBody16>Feedback</FontBody16></Option></div>*/}
                    {/*            {this.state.showConsumer && <div onClick={() => {return this.openConsumerDashboard()}}><Option><FontBody16>Switch to Consumer Account</FontBody16></Option></div>}*/}
                    {/*        </DropdownOptions>*/}
                    {/*        <div>*/}
                    {/*            <SignOut onClick={() => {this.props.tryLogout()}}><FontHeader12>Log out</FontHeader12></SignOut>*/}
                    {/*            <Privacy><a target={"_blank"} href={"https://"+env.URL.DOMAIN+"/privacy"}><FontHeader12>Privacy Policy</FontHeader12></a></Privacy>*/}
                    {/*        </div>*/}
                    {/*    </UserDropdown>*/}
                    {/*}*/}
                </NavRight>
                <NavSecondaryMobile />
                {this.state.dropdownOpen && <Background onClick={() => this.setState({"dropdownOpen": !this.state.dropdownOpen})}/>}
            </NavTop>
        )
    }
}

const mapStateToProps3 = state => ({
    user: state.common.user
});

const mapDispatchToProps3 = dispatch => ({
    tryLogout: () => dispatch(tryLogout()),
    setSupportOpen: (isOpen, page) => dispatch(setSupportOpen(isOpen, page)),
    setPlatformTo: (platform) => dispatch(setPlatformTo(platform))
});

export default connect(mapStateToProps3, mapDispatchToProps3)(withRouter(NavPrimaryTop));
