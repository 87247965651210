import { combineReducers } from 'redux';

/* Common */
import programReducer from './store/program';

const checkoutWillowReducers = combineReducers({
    program: programReducer
});

export default checkoutWillowReducers;
