import React from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';

/* Components */
import {FontTitle18} from "../../../../common/components/fonts";
import {LogoLoading} from "../../../../common/components/loading";
import {Disconnect} from "../components/disconnectsync";

/* Middleware */
import {tryGetRecurringAvailabilitySchedule, tryUpdateRecurringAvailability, tryDisconnectCalendarIntegration} from "../middleware/recurring";

class DisconnectSync extends React.Component {
    static defaultProps = {
        onDisconnect: () => {}
    }

    state = {"isLoading": true, "method": "", "outlookProxy": null};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.props.tryGetRecurringAvailabilitySchedule();
        this.setState({
            "method": this.props.method,
            "outlookProxy": this.props.method === "microsoft_outlook" ? this.props.outlookProxy : null,
            "isLoading": false
        });
    };

    setDisconnect = async () => {
        await this.props.tryDisconnectCalendarIntegration();
        console.log('setDisconnect', this.props.method)
        await this.props.onDisconnect();
        if(!this.props.isWMC) {
            this.props.history.push("/settings/availability/calendar");
        }
    };

    getDisconnectType = () => {
        if (this.state.method === "google_calendar")
            return "google"
        if (this.state.method === "microsoft_outlook")
            return "outlook"
        if (this.state.method === "calendly")
            return "calendly"
        return "willow"
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading/>)
        } else {
            return (
                <>
                    <br/>
                    <br/>
                    <FontTitle18>Disconnect Calendar Sync</FontTitle18>
                    <Disconnect
                        disconnect={this.setDisconnect}
                        type={this.getDisconnectType()}
                        outlookProxy={this.state.outlookProxy}
                    />
                    <br/>
                    <br/>
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    method: state.enterprise.recurring.get("recurringAvailability").get("availability_calendar_update_method"),
    outlookProxy: state.enterprise.recurring.get("recurringAvailability").get("outlookProxy"),
    isWMC: state.common.wmc.get("isWMC")
});

const mapDispatchToProps = dispatch => ({
    tryDisconnectCalendarIntegration: () => dispatch(tryDisconnectCalendarIntegration()),
    tryUpdateRecurringAvailability: (syncMethod) => dispatch(tryUpdateRecurringAvailability(syncMethod)),
    tryGetRecurringAvailabilitySchedule: () => dispatch(tryGetRecurringAvailabilitySchedule()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DisconnectSync));
