import React from 'react';
import styled, {css, keyframes} from 'styled-components';

/* Components */
import {colors} from "../../../common/components/colors";

export const Level1 = styled.div`
  display: ${({toggle}) => toggle ? 'block' : 'none'};
  margin:0 auto;
  width: 700px;
  @media screen and (max-width: 1088px) {
    width: 580px !important;
  }
  @media screen and (max-width: 748px) {
    width: 520px !important;
  }
  @media screen and (max-width: 650px) {
    width: 100% !important;
  }
`;

const nextPage = keyframes`
  0% {
    opacity: 0;
    transform:rotateY(180deg);
  }
  100% {
    opacity: 1;
    transform:rotateY(0deg);
  }
`;

const backPage = keyframes`
  0% {
    opacity: 0;
    transform:rotateY(180deg);
  }
  100% {
    opacity: 1;
    transform:rotateY(0deg);
  }
`;

export const Level2 = styled.div`
  max-width: 768px;
  padding:0;
  margin: 0 auto;
  border-radius: 25px 25px 0 0;
  display: flex;
  animation: ${props => props.animate 
      ? css`${nextPage} 1s ease-in-out`
      : css`${backPage} 1s ease-in-out`
  };
  @media screen and (max-width: 748px) {
      width: 100% !important;
    }
`;

export const Level3 = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin: 0 auto 0 auto;
  max-width: 768px;
  padding: 0;
  width: 100%;
`;

export const ContactBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0 0 25px 25px;
  width: 100%;
  background-color: ${colors.white};
  margin: 0;
  text-align: center;
  padding: 0;
  min-height: 505px;
`;

export const ContactContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  justify-content: center;
  align-items: center;
`;
