import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';

/* Components */
import {FontBody16, FontTitle21} from "../../../common/components/fonts";
import {DropdownInput, Checkbox} from "../../../common/components/inputs";
import {ButtonPrimary, ButtonInactive} from "../../../common/components/buttons";
import {colors} from "../../../common/components/colors";

/* Middleware */
import {tryPostHelp} from '../middleware/help';

export const Body = styled.div`
    margin: 20px 0px; 
`;
export const ButtonWide = styled.div`
    width: 100%;
    margin-top: 50px;
    & > button {
        width: 100%;
    }
    & > button > div {
        width: calc(100% - 47px);
    }
`;
export const ButtonWideInactive = styled.div`
    width: 100%;
    margin-top: 50px;
    & > div {
        width: calc(100% - 47px);
        text-align: center;
    }
`;
const TextArea = styled.textarea`
    background-color: ${colors.border70};
    margin: 25px 0px 20px 0px;
    border-radius: 4px;
    padding: 15px 20px;
    resize: none;
    font-family: GraphicWebRegular;
    font-size: 16px;
    line-height: 22px;
    min-height: 110px;
    width: calc(100% - 42px);
    border: 1px solid ${colors.primary30};
    @media screen and (max-width: 460px) {
        padding: 10px 10px;
    }
    &:focus {
        outline: 0;
    }
`;
const CheckboxContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    & label {
        margin: 0px auto 0px auto;
    }
`;
const CheckboxRow = styled.div`
    margin-bottom: 25px;
    text-align: left;
    width: fit-content;
`;
const Section = styled.div`
    padding: 10px 20px;
    overflow-y: auto;
    height: calc(100% - 80px);
`;

const helpCoachCategoryOptions = [
    {value: 'team', label: 'My Team'},
    {value: 'clients', label: 'Clients'},
    {value: 'scheduling', label: 'Scheduling'},
    {value: 'account', label: 'My account'},
    {value: 'general', label: 'Something else'},
];
const helpConsumerCategoryOptions = [
    {value: 'scheduling', label: 'Scheduling'},
    {value: 'account', label: 'My account'},
    {value: 'general', label: 'Something else'},
];

class HelpContact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {submitted: false, message: '', questionType: '', urgent: false}
    }

    handleChange = e => this.setState({message: e.target.value});
    handleChangeQuestionType = e => {this.setState({'questionType': e.value});};
    handleChangeUrgent = (passToAction, question, e) => {this.setState({urgent: e})};
    handleSubmit = e => {
        e.preventDefault();
        if(this.state.message !== ''/* && this.state.questionType !== ''*/) {
            let urgentText = this.state.urgent ? 'URGENT ' : '';
            this.props.tryPostHelp(urgentText.concat(/*'CATEGORY: ', this.state.questionType, */"MESSAGE: ", this.state.message));
            this.setState({submitted: true, message: ''/*, questionType: ''*/});
        }
    };

    render() {
        return(
            <Section>
                <br />
                {this.state.submitted
                    ?
                        <>
                            <FontTitle21>Thank you!</FontTitle21>
                            <Body>
                                {this.state.urgent
                                    ? <FontBody16>We&#39;ll do our best to get back to you within about 4 hours.</FontBody16>
                                    : <FontBody16>We&#39;ll get back to you within 24 hours.</FontBody16>
                                }
                            </Body>
                        </>
                    :
                        <>
                            <FontTitle21>{this.props.isWMC ? "Technical question?" : "Have a question?"}</FontTitle21>
                            <form method={"post"} onSubmit={this.handleSubmit}>
                                {/*<DropdownInput*/}
                                {/*    title={"What is your question about?"}*/}
                                {/*    options={this.props.platform === "consumer" ? helpConsumerCategoryOptions : helpCoachCategoryOptions}*/}
                                {/*    id={'questionType'}*/}
                                {/*    onChange={this.handleChangeQuestionType}*/}
                                {/*    placeholder={'Please Select'}*/}
                                {/*    value={this.state.questionType}*/}
                                {/*/>*/}
                                <TextArea
                                    value={this.state.message}
                                    onChange={this.handleChange}
                                    placeholder={"How we can help"}
                                />
                                <CheckboxRow>
                                    <CheckboxContainer><Checkbox action={this.handleChangeUrgent} passToAction={null} question={null} checked={this.state.urgent} id={"urgent"} /></CheckboxContainer>
                                    <CheckboxContainer><FontBody16>Urgent</FontBody16></CheckboxContainer>
                                </CheckboxRow>
                                {this.state.message !== ""
                                    ? <ButtonWide><ButtonPrimary canSubmit={true} label={"Send"} /></ButtonWide>
                                    : <ButtonWideInactive><ButtonInactive canSubmit={false} label={"Send"} /></ButtonWideInactive>
                                }
                            </form>
                        </>
                }
            </Section>
        )
    }
}

const mapStateToProps = state => ({
    isWMC: state.common.wmc.get("isWMC"),
    platform: state.common.user.get("platform")
});
const mapDispatchToProps = dispatch => ({
    tryPostHelp: (message) => dispatch(tryPostHelp(message))
});
export default connect(mapStateToProps, mapDispatchToProps)(HelpContact);
