import { Map, List, fromJS } from 'immutable';

/* Immutable */

const PROSPECTS_CREATING = 'PROSPECTS_CREATING';
const PROSPECTS_CREATED_ERROR = 'PROSPECTS_CREATED_ERROR';
const PROSPECT_STAGES = 'PROSPECT_STAGES';
const PROSPECT_STAGES_READING = 'PROSPECT_STAGES_READING';
const PROSPECT_STAGES_CREATING = 'PROSPECT_STAGES_CREATING';
const PROSPECT_STAGES_UPDATING = 'PROSPECT_STAGES_UPDATING';
const PROSPECT_STAGES_DELETING = 'PROSPECT_STAGES_DELETING';
const PROSPECT_STAGES_ORDERING = 'PROSPECT_STAGES_ORDERING';
const PROSPECT_STAGES_FETCHED = 'PROSPECT_STAGES_FETCHED';
const PROSPECTS_ALL_FETCHING = 'PROSPECTS_ALL_FETCHING';
const PROSPECTS_ALL_FETCHED = 'PROSPECTS_ALL_FETCHED';
const PROSPECTS_ALL = 'PROSPECTS_ALL';
const PROSPECTS_ALL_REMOVE_PROSPECT = 'PROSPECTS_ALL_REMOVE_PROSPECT';
const PROSPECTS_ONE_FETCHING = 'PROSPECTS_ONE_FETCHING';
const PROSPECTS_ONE = 'PROSPECTS_ONE';
const PROSPECTS_ONE_EDIT_EXISTING = 'PROSPECTS_ONE_EDIT_EXISTING';
const PROSPECTS_ARCHIVING = 'PROSPECTS_ARCHIVING';
const PROSPECTS_UPDATING = 'PROSPECTS_UPDATING';
const PROSPECTS_UPDATING_ERROR = 'PROSPECTS_UPDATING_ERROR';
const PROSPECTS_ALL_EDIT_EXISTING_PROSPECT = 'PROSPECTS_ALL_EDIT_EXISTING_PROSPECT';
const PROSPECTS_ALL_ARCHIVE_EXISTING_PROSPECT = 'PROSPECTS_ALL_ARCHIVE_EXISTING_PROSPECT';
const PROSPECTS_ALL_UPDATING_NEXT_SESSION = 'PROSPECTS_ALL_UPDATING_NEXT_SESSION';
const PROSPECTS_ALL_ADD_NEW_PROSPECT = 'PROSPECTS_ALL_ADD_NEW_PROSPECT';
const PROSPECTS_ALL_SET_NEXT_SESSION = 'PROSPECTS_ALL_SET_NEXT_SESSION';
const PROSPECT_STAGES_RESET = 'PROSPECT_STAGES_RESET';
const PROSPECT_PROMOTION_MODAL = 'PROSPECT_PROMOTION_MODAL';
const PROSPECT_PROMOTION = 'PROSPECT_PROMOTION';

/* Actions */

export const setProspectCreating = (status) => ({'type': PROSPECTS_CREATING, 'status': status});
export const setProspectCreatedError = (error) => ({'type': PROSPECTS_CREATED_ERROR, 'error': error});
export const setProspectStages = (stages, error) => ({'type': PROSPECT_STAGES, 'stages': stages, 'error': error});
export const setProspectStagesFetched = () => ({'type': PROSPECT_STAGES_FETCHED});
export const setProspectStagesReading = (status) => ({'type': PROSPECT_STAGES_READING, 'status': status});
export const setProspectStagesCreating = (status) => ({'type': PROSPECT_STAGES_CREATING, 'status': status});
export const setProspectStagesUpdating = (status) => ({'type': PROSPECT_STAGES_UPDATING, 'status': status});
export const setProspectStagesDeleting = (status) => ({'type': PROSPECT_STAGES_DELETING, 'status': status});
export const setProspectStagesOrdering = (status) => ({'type': PROSPECT_STAGES_ORDERING, 'status': status});
export const setAllProspects = (prospects, isWMC) => ({'type': PROSPECTS_ALL, 'prospects': prospects, 'isWMC': isWMC});
export const setAllProspectsFetched = () => ({'type': PROSPECTS_ALL_FETCHED});
export const setAllProspectsFetching = (status) => ({'type': PROSPECTS_ALL_FETCHING, 'status': status});
export const setProspectsArchiving = (status) => ({'type': PROSPECTS_ARCHIVING, 'status': status});
export const setProspectsUpdating = (status) => ({'type': PROSPECTS_UPDATING, 'status': status});
export const setProspectsUpdatingError = (error) => ({'type': PROSPECTS_UPDATING_ERROR, 'error': error});
export const setProspectsOneFetching = (status) => ({'type': PROSPECTS_ONE_FETCHING, 'status': status});
export const setProspectsOne = (contact) => ({'type': PROSPECTS_ONE, 'contact': contact});
export const setProspectsOneEditExisting = (edit) => ({'type': PROSPECTS_ONE_EDIT_EXISTING, 'edit': edit});
export const setAllProspectsArchiveExistingProspect = (consumerId, isArchived) => ({'type': PROSPECTS_ALL_ARCHIVE_EXISTING_PROSPECT, 'consumerId': consumerId, 'isArchived': isArchived});
export const setAllProspectsEditExistingProspect = (prospectId, edit) => ({'type': PROSPECTS_ALL_EDIT_EXISTING_PROSPECT, 'prospectId': prospectId, 'edit': edit});
export const setAllProspectsAddNewProspect = (newProspect) => ({'type': PROSPECTS_ALL_ADD_NEW_PROSPECT, 'newProspect': newProspect});
export const setAllProspectsRemoveProspect = (prospectId) => ({'type': PROSPECTS_ALL_REMOVE_PROSPECT, 'prospectId': prospectId});
export const setAllProspectsAddNextSession = (prospectId, sessionId, sessionStart) => ({'type': PROSPECTS_ALL_UPDATING_NEXT_SESSION, 'prospectId': prospectId, 'sessionId': sessionId, 'sessionStart': sessionStart});
export const setAllProspectsSetNextSession = (consumerId, nextSession) => ({'type': PROSPECTS_ALL_SET_NEXT_SESSION, 'consumerId': consumerId, 'nextSession': nextSession});
export const setProspectStagesStoreReset = () => ({'type': PROSPECT_STAGES_RESET});
export const setPromotionModal = (status) => ({'type': PROSPECT_PROMOTION_MODAL, 'status': status});
export const setProspectPromoting = (status) => ({'type': PROSPECT_PROMOTION, 'status': status})

/* Initial State */

const initialState = Map({
    prospectStages: List(),
    prospectStagesCreating: false,
    prospectStagesReading: false,
    prospectStagesUpdating: false,
    prospectStagesDeleting: false,
    prospectStagesOrdering: false,
    prospectStagesFetched: false,
    prospectStagesError: '',
    prospectCreating: false,
    prospectCreatedError: Map(),
    prospectsAll: List(),
    prospectsAllFetching: false,
    prospectsAllFetched: false,
    prospectsArchiving: false,
    prospectsUpdating: false,
    prospectsUpdatingError: '',
    prospectsOneFetching: false,
    prospectsOneFetched: null,
    prospectsOne: Map(),
    promotionModal: false,
    prospectPromoting: false
});

/* Reducer */

const prospectsReducer = (state=initialState, action) => {
    switch (action.type) {
        case PROSPECT_STAGES:
            return state.merge({
                "prospectStages": fromJS(action.stages),
                "prospectStagesError": action.error
            });
        case PROSPECT_STAGES_READING:
            return state.merge({'prospectStagesCreating': action.status});
        case PROSPECT_STAGES_CREATING:
            return state.merge({'prospectStagesReading': action.status});
        case PROSPECT_STAGES_UPDATING:
            return state.merge({'prospectStagesUpdating': action.status});
        case PROSPECT_STAGES_DELETING:
            return state.merge({'prospectStagesDeleting': action.status});
        case PROSPECT_STAGES_ORDERING:
            return state.merge({'prospectStagesOrdering': action.status});
        case PROSPECTS_CREATING:
            return state.merge({'prospectCreating': action.status});
        case PROSPECTS_CREATED_ERROR:
            return state.merge({'prospectCreatedError': action.error});
        case PROSPECTS_ALL:
            return state.merge({
                'prospectsAll': fromJS(action.prospects.map(p => {
                    return(fromJS({
                        ...p,
                        "lastName": (!action.isWMC && p.referralSource === "willow") ? p.lastName.charAt(0)+"." : p.lastName
                    }))
                }))
            });
        case PROSPECT_STAGES_FETCHED:
            return state.merge({'prospectStagesFetched': true});
        case PROSPECTS_ALL_FETCHED:
            return state.merge({'prospectsAllFetched': true});
        case PROSPECTS_ALL_FETCHING:
            return state.merge({'prospectsAllFetching': action.status});
        case PROSPECTS_ALL_REMOVE_PROSPECT:
            return state.merge({
                'prospectsAll': fromJS(state.get("prospectsAll").filter(f => {return(f.get("prospectId") !== action.prospectId)}))
            });
        case PROSPECTS_ALL_ADD_NEW_PROSPECT:
            return state.merge({
                'prospectsAll': fromJS(state.get("prospectsAll").push(fromJS(action.newProspect)))
            });
        case PROSPECTS_ALL_EDIT_EXISTING_PROSPECT:
            return state.merge({
                'prospectsAll': fromJS(state.get("prospectsAll").map(p => {
                    if(p.get("prospectId") === action.prospectId) {
                        return(fromJS({...p.toJS(), ...action.edit}))
                    } else {
                        return(fromJS(p))
                    }
                }))
            });
        case PROSPECTS_ALL_ARCHIVE_EXISTING_PROSPECT:
            return state.merge({
                'prospectsAll': fromJS(state.get("prospectsAll").map(p => {
                    if(p.get("consumerId") === action.consumerId) {
                        return(fromJS({...p.toJS(), "isArchived": !action.isArchived}))
                    } else {
                        return(fromJS(p))
                    }
                }))
            });
        case PROSPECTS_ALL_UPDATING_NEXT_SESSION:
            return state.merge({
                'prospectsAll': fromJS(state.get("prospectsAll").map(p => {
                    if(p.get("prospectId") === action.prospectId) {
                        return(fromJS({
                            ...p.toJS(),
                            "nextSession": Map({
                                "sessionId": action.sessionId,
                                "start": action.sessionStart
                            })
                        }))
                    } else {
                        return(fromJS(p))
                    }
                }))
            });
        case PROSPECTS_ALL_SET_NEXT_SESSION:
            return state.merge({
                'prospectsAll': fromJS(state.get("prospectsAll").map(p => {
                    if(p.get("consumerId") === action.consumerId) {
                        return(fromJS({...p.toJS(), "nextSession": fromJS(action.nextSession)}))
                    } else {
                        return(fromJS(p))
                    }
                }))
            });
        case PROSPECTS_ARCHIVING:
            return state.merge({'prospectsArchiving': action.status});
        case PROSPECTS_UPDATING:
            return state.merge({'prospectsUpdating': action.status});
        case PROSPECTS_UPDATING_ERROR:
            return state.merge({'prospectsUpdatingError': action.error});
        case PROSPECTS_ONE:
            return state.merge({'prospectsOne': fromJS(action.contact)});
        case PROSPECTS_ONE_FETCHING:
            return state.merge({'prospectsOneFetching': action.status});
        case PROSPECTS_ONE_EDIT_EXISTING:
            return state.merge({
                'prospectsOne': fromJS({
                    ...state.get("prospectsOne").toJS(),
                    ...action.edit
                })
            });
        case PROSPECT_PROMOTION_MODAL:
            return state.merge({'promotionModal': action.status});
        case PROSPECT_PROMOTION:
            return state.merge({'prospectPromoting': action.status})
        case PROSPECT_STAGES_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default prospectsReducer;
