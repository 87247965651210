import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

/* Domain Routes */
import Consumer, {consumerRoutes} from './consumer';
import Enterprise, {enterpriseRoutes} from './enterprise';
import Iframes, {iframeRoutes} from './iframes';
import Redirects, {redirectRoutes} from './redirects';

export const luxaideRoutesPublic = iframeRoutes.concat(redirectRoutes);
export const luxaideRoutesSecure = consumerRoutes.concat(enterpriseRoutes).concat(iframeRoutes).concat(redirectRoutes);

const RoutesLuxAide = ({ platform }) => (
    <Switch>
        {platform === 'consumer' && <Route path={consumerRoutes} component={Consumer} />}
        {platform === 'enterprise' && <Route path={enterpriseRoutes} component={Enterprise} />}
        <Route path={iframeRoutes} component={Iframes} />
        <Route path={redirectRoutes} component={Redirects} />
    </Switch>
);

const mapStateToProps = state => ({
    platform: state.common.user.get('platform')
});

export default connect(mapStateToProps, null)(RoutesLuxAide);
