import React from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import validator from "validator";
import {Map} from 'immutable';

/* Components */
import { colors } from "../../../components/colors";
import {FontHeader21, FontHeader16, FontTitle18, FontBody16} from "../../../components/fonts";
import {CloseOutlined} from '@ant-design/icons';
import {TextInput} from "../../../components/inputs";
import {InlineBlock, SaveSection, SubmitInputWithValidation} from "../../../../enterprise/components/clientscreate";
import {ButtonTertiary} from "../../../components/buttons";

/* Middleware */
import { tryRenameDrive, tryGetSharedDriveList } from '../middleware/filevault';

/* Store */
import {setDriveRenameModal, setSharedDrive} from "../store/filevault";

/* Icons */
import {images} from '../../../components/images'

const Modal = styled.div`
    position: relative;
    margin: 0px auto;
    top: 110px;
    z-index: 10;
    background-color: ${colors.white};
    border: 1px solid ${colors.border100};
    border-radius: 6px;
    width: 100%;
    height: auto;
    max-width: 550px;
    @media screen and (max-width: 640px) {
        top: 80px;
        width: calc(100% - 20px);
    }
`;
const Fixed = styled.div`
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 8;
`;
const Background = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9;
`;
const Title = styled.div`
    padding: 25px;
`;
const Inline = styled.div`
    width: calc(100% - 77px);
    display: inline-block;
    vertical-align: top;
`;
const Image = styled.img`
    width: 32px;
    margin-right: 20px;
    vertical-align: top;
    display: inline-block;
`;
const Close = styled.div`
    width: 25px;
    vertical-align: top;
    display: inline-block;
    color: ${colors.secondary100};
    cursor: pointer;
`;
const Form = styled.form`
    padding: 0px 25px 25px 25px;
`;
const Success = styled.div`
    padding: 40px 25px;
    text-align: center;
`;

const initState = {"driveRenameModal": false, "sharedDrive": Map(), "name": "", "nameValid": true, "isValid": false, "isChanged": false, "isSaved": false, "isSaving": false};

class VaultRename extends React.Component {
    state=initState;

    componentDidMount() {
        this.setState({"driveRenameModal": this.props.driveRenameModal})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.driveRenameModal !== this.props.driveRenameModal && this.props.driveRenameModal) {
            this.setState({
                "driveRenameModal": this.props.driveRenameModal,
                "sharedDrive": this.props.sharedDrive,
                "name": this.props.sharedDrive.get("name"),
            })
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    timeout = 0;

    close = async () => {
        await this.props.setDriveRenameModal(false);
        await this.setState(initState);
        this.props.setSharedDrive(Map());
        await this.props.tryGetSharedDriveList();
    };

    handleChangeFolderName = async e => {
        await this.setState({
            'name': e.target.value,
            'nameValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''),
            'isChanged': true
        });
        this.checkValid();
    };

    checkValid = async () => {
        if(this.state.nameValid) {
            this.setState({"isValid": true})
        } else {
            this.setState({"isValid": false})
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            this.setState({'isSaving': true, 'error': ''});
            const name = this.state.name;
            const id = this.state.sharedDrive.get("driveId");
            await this.props.tryRenameDrive(id, name);
            this.setState({'isSaving': false, 'isSaved': true, "isChanged": false});
            this.timeout = setTimeout(this.close, 1000);
        }
    };

    render() {
        if(!this.state.driveRenameModal) {
            return null
        } else {
            return (
                <Fixed>
                    <Modal>
                        {this.state.isSaved
                            ? <Success><FontTitle18>{"Drive renamed!"}</FontTitle18></Success>
                            : <>
                                <Title>
                                    <Image src={images.vaultFolderPrivate} alt={""} />
                                    <Inline><FontHeader21>{"Rename drive"}</FontHeader21></Inline>
                                    <Close onClick={this.close}><FontHeader16><CloseOutlined /></FontHeader16></Close>
                                </Title>
                                <Form method={"post"} onSubmit={this.handleSubmit}>
                                    <TextInput
                                        title={"Name"}
                                        valid={this.state.nameValid}
                                        warning={"Required"}
                                        id={"name"}
                                        onChange={this.handleChangeFolderName}
                                        placeholder={"Drive name"}
                                        value={this.state.name}
                                    />
                                    <FontBody16>This will be seen by all members of the drive.</FontBody16>
                                    <SaveSection>
                                        <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                        <InlineBlock><div onClick={this.close}><ButtonTertiary canSubmit={false} label={"Cancel"} /></div></InlineBlock>
                                    </SaveSection>
                                </Form>
                            </>
                        }
                    </Modal>
                    {this.state.isSaved && <Background onClick={this.close} />}
                </Fixed>
            )
        }
    }
}

const mapStateToProps = state => ({
    driveRenameModal: state.common.fileVault.get("driveRenameModal"),
    sharedDrive: state.common.fileVault.get("sharedDrive")
});

const mapDispatchToProps = dispatch => ({
    setDriveRenameModal: (status) => dispatch(setDriveRenameModal(status)),
    setSharedDrive: (status) => dispatch(setSharedDrive(status)),
    tryRenameDrive: (driveId, name) => dispatch(tryRenameDrive(driveId, name)),
    tryGetSharedDriveList: () => dispatch(tryGetSharedDriveList())
});

export default connect(mapStateToProps, mapDispatchToProps)(VaultRename);