import React from "react";
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';

/* Components */
import {FontBody16, FontBody18, FontSubtitle28} from "../../../common/components/fonts";
import { colors } from '../../../common/components/colors';
import {LogoLoading} from "../../../common/components/loading";
import {RightOutlined} from "@ant-design/icons";

/* Containers */
import ProfileScheduler from "../../scheduling/containers/scheduler";
import {brightness, hexToRGB} from "../../../common/utils/colors";

const Container = styled.div`
    padding: 20px 50px;
    width: calc(100% - 100px);
    @media screen and (max-width: 750px) {
        width: 100%;
        padding: 20px 0 0 0;
    }
`;
const Title = styled(FontSubtitle28)`
    margin-bottom: 60px;
    text-align: center;
`;
const ScheduleListItem = styled.div`
    background-color: ${props => props.backgroundColor};
    border-radius: ${props => props.even ? '0px 20px 0px 20px' : '20px 0px 20px 0px'};
    color: ${props => brightness(hexToRGB(props.backgroundColor, false)) < 130 ? colors.white : colors.primary100};
    cursor: pointer;
    padding: 15px 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;
const ScheduleListItemNameTitle = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
`;
const RightOutlined32 = styled(RightOutlined)`
    font-size: 32px;
`;

class MemberAppointmentOptions extends React.Component {
    static defaultProps = {
        "category": null,
        "coach": {},
        "products": [],
        "onBack": () => {},
        "onClose": () => {},
        "onSchedule": () => {},
        "isNewCoach": false,
        "bookTrialCall": false
    };

    state = {
        "isLoading": true,
        "productsFiltered": [],
        "selectedProduct": null,
        "newAptScheduled": false
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        console.log("aptoptions", this.props)
        const productsFiltered = this.props.products.filter(s => s.sessionsSchedulable > 0);
        await this.setState({
            "selectedProduct": productsFiltered.length === 1 ? productsFiltered[0] : null,
            "productsFiltered": productsFiltered,
        });
        if(this.props.location.pathname === "/my-team/"+this.props.coach.id+"/appointment/book" && this.state.selectedProduct === null) {
            this.props.history.push("/my-team/" + this.props.coach.id)
        } else if(this.props.isNewCoach || this.props.bookTrialCall) {
            this.props.history.push("/my-team/"+this.props.coach.id+"/appointment/book")
        } else if(productsFiltered.length === 1) {
            await this.toggleSchedulerOpen(productsFiltered[0])
        } else if(productsFiltered.length === 0) {
            this.props.history.push("/my-team/"+this.props.coach.id+"/add-appointments")
        }
        this.setState({"isLoading": false});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.location.pathname === "/my-team/"+this.props.coach.id+"/appointment/book" && this.state.selectedProduct === null && !this.props.bookTrialCall && !this.state.newAptScheduled) {
            this.props.history.push("/my-team/" + this.props.coach.id+"/appointment")
        }
    }

    toggleSchedulerOpen = async (selectedProduct) => {
        await this.setState({"selectedProduct": selectedProduct});
        this.props.history.push("/my-team/"+this.props.coach.id+"/appointment/book")
    };

    getApptString = (product) => {
        const numSessions = product.package === 'trial' ? product.remainingSessions : product.sessionsSchedulable;
        return `${numSessions} appointment${numSessions === 1 ? '' : 's'} @ ${product.sessionLengthInMinutes}-minutes`;
    };

    render() {
        if (this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            if(!this.props.location.pathname.includes("book")) {
                return(
                    <Container>
                        <Title>SELECT AN APPOINTMENT</Title>
                        {this.state.productsFiltered.map((p, idx) => (
                            <ScheduleListItem
                                key={idx}
                                onClick={() => this.toggleSchedulerOpen(p)}
                                backgroundColor={colors.cellColors[idx % colors.cellColors.length]}
                                even={idx % 2 !== 0}
                            >
                                <ScheduleListItemNameTitle>
                                    <FontBody18>{p.productTitle + (p.package === 'trial' ? ' (Free Trial)' : '')}</FontBody18>
                                    <FontBody16>{this.getApptString(p)}</FontBody16>
                                </ScheduleListItemNameTitle>
                                <RightOutlined32/>
                            </ScheduleListItem>
                        ))}
                    </Container>
                )
            } else if(this.props.location.pathname.includes("book")) {
                return(
                    <ProfileScheduler
                        onClose={() => {this.props.history.push("/my-team/"+this.props.coach.id)}}
                        onBack={() => {this.props.history.goBack()}}
                        onSchedule={async () => {
                            await this.setState({"newAptScheduled": true})
                            this.props.onSchedule()
                        }}
                        profile={{
                            "id": this.props.coach.id,
                            "firstName": this.props.coach.firstName,
                            "lastName": this.props.coach.lastName,
                            "photo": this.props.coach.photo,
                            "defaultCallLength": (this.props.isNewCoach || this.props.bookTrialCall) ? 15 : (this.state.selectedProduct === null ? 0 : this.state.selectedProduct.sessionLengthInMinutes),
                        }}
                        purchaseHistoryId={(this.props.isNewCoach || this.props.bookTrialCall) ? null : (this.state.selectedProduct === null ? null : this.state.selectedProduct.purchaseHistoryId)}
                        isWillowSession={(this.props.isNewCoach || this.props.bookTrialCall) ? false : (this.state.selectedProduct === null ? false : this.state.selectedProduct.isWillowSession)}
                    />
                )
            } else {
                return null
            }
        }
    }
}

export default withRouter(MemberAppointmentOptions);