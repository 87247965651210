import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import moment from "moment-timezone";

/* Components */
import {FontTitle28, FontBody21} from '../../../../common/components/fonts';
import {LogoLoading} from "../../../../common/components/loading";
import {images} from "../../../../common/components/images";
import {colors} from "../../../../common/components/colors";

/* Containers */
import FirmSchedulingRescheduleCancel from '../../../../iframes/reschedulermodal/containers/reschedulecancel';

/* Middleware */
import {tryGetCoachingAppointments, tryGetCoachingGeneralSessions} from "../middleware/coaches";

/* Utils */
import {checkSessions} from "../utils/sessions";

const BottomSegment = styled.div`    
    position: relative;
    display: flex;
    flex-direction: column; //row;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 80px;
    column-gap: 200px;
`;
const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;
const CategoryTitleAndButton = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center; 
    color: ${colors.secondary100};
    margin-bottom: 12px;
`;
const CategoryResponse = styled(FontBody21)`
    line-height: 40px;
`;
const UpcomingRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 30px;
    row-gap: 10px;
    align-items: flex-end;
    flex-wrap: wrap;
    ${props => props.marginBotton !== true} {
        margin-bottom: 15px;
    }
`;
const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    row-gap: 10px;
    flex-wrap: wrap;
    align-items: flex-end;
`;
const IconButton = styled.img`
    width: 40px;
    height: 40px;
    cursor: pointer;
`;

class FirmCoachRecordOverview extends React.Component {
    static defaultProps = {
        profile: {
            id: null
        }
    }

    state = {
        "sessionsCompleted": 0,
        "sessionsUpcoming": 0,
        "sessionsUpcomingArray": [],
        "sessionsRemaining": 0,
        "sessionsSchedulable": 0,
        "sessionSelected": {},
        "showModal": false,
        "isLoading": true
    }

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const history = await this.props.tryGetCoachingAppointments();
        const historyAll = history.upcomingCalls.concat(history.pastCalls);
        const sessionsPrePurchased = await this.props.tryGetCoachingGeneralSessions();
        const appointments = await checkSessions(history, sessionsPrePurchased);
        await this.setState({
            ...appointments,
            "sessionsUpcomingArray": await this.filterUpcoming(historyAll),
            "showModal": false,
            "isLoading": false
        })
    }

    filterUpcoming = (data) => {
        const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return data.reduce((filtered, c) => {
            if(this.props.profile.id.toString() === c.remoteUserId.toString() && moment(c.start).isAfter(moment())) {
                let end = (c.expected_end === undefined || c.expected_end === null) ? c.end : c.expected_end;
                let d = c.start;
                filtered.push({
                    ...c,
                    "length": moment.duration(moment(end).diff(moment(c.start))).asMinutes().toString(),
                    "date": moment(d).format("ll").toString() + ", " + moment(d).format("h:mma").toString() + " - " + moment(end).format("h:mma").toString() + " " + moment(end).tz(browserTimeZone).format("z")
                })
            }
            return filtered;
        }, []);
    };

    executeCancel = async () => {return this.init()}
    executeReschedule = async () => {this.props.history.push("/my-certificates/my-coaches/"+this.props.profile.id+"/appointment")}
    hideModal = async () => this.setState({"sessionSelected": {}, "showModal": false});
    showModal = async (sessionSelected) => this.setState({"sessionSelected": sessionSelected, "showModal": true});

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return (
                <BottomSegment>
                    <div>
                        <FlexRow>
                            <div>
                                <CategoryTitleAndButton>
                                    <FontTitle28>Bookable</FontTitle28>
                                </CategoryTitleAndButton>
                                <CategoryResponse>
                                    {this.state.sessionsSchedulable === 0
                                        ? "No bookable appointments"
                                        : this.state.sessionsSchedulable+" appointment"+(this.state.sessionsSchedulable === 1 ? '' : 's')+" @ 60-minutes"
                                    }
                                </CategoryResponse>
                            </div>
                            <IconButton src={images.firmAddIconOutline} onClick={() => {window.location.href = 'https://pay.trustwillow.com/b/14k29y5Aq3jzcucdR9?client_reference_id='+this.props.userId}} />
                        </FlexRow>
                    </div>
                    <div>
                        <CategoryTitleAndButton>
                            <FontTitle28>Upcoming</FontTitle28>
                        </CategoryTitleAndButton>
                        <CategoryResponse>
                            {this.state.sessionsUpcomingArray.length > 0
                                ? <>
                                    {this.state.sessionsUpcomingArray.map((m, idx) => (
                                        <UpcomingRow key={idx} marginBottom={idx > 0}>
                                            <div>{m.date}</div>
                                            <IconButton src={images.firmEditIconOutlineGreen} onClick={() => this.showModal(m)}/>
                                        </UpcomingRow>
                                    ))}
                                </>
                                : "No upcoming appointments"
                            }
                        </CategoryResponse>
                    </div>
                    <div>
                        <CategoryTitleAndButton>
                            <FontTitle28>Completed</FontTitle28>
                        </CategoryTitleAndButton>
                        <CategoryResponse>
                            {this.state.sessionsCompleted === 0
                                ? "No completed appointments"
                                : this.state.sessionsCompleted+" appointment"+(this.state.sessionsCompleted === 1 ? '' : 's')+" @ 60-minutes"
                            }
                        </CategoryResponse>
                    </div>
                    <FirmSchedulingRescheduleCancel
                        hideModal={this.hideModal}
                        onExecuteCancel={this.executeCancel}
                        onExecuteReschedule={this.executeReschedule}
                        sessionSelected={this.state.sessionSelected}
                        showModal={this.state.showModal}
                    />
                </BottomSegment>
            )
        }
    }
}

const mapStateToProps = state => ({
    userId: state.common.user.get("userId"),
})

const mapDispatchToProps = dispatch => ({
    tryGetCoachingAppointments: () => dispatch(tryGetCoachingAppointments()),
    tryGetCoachingGeneralSessions: () => dispatch(tryGetCoachingGeneralSessions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FirmCoachRecordOverview));