import React from 'react';

/* Components */
import {PageActions, PageContent, PageTabs} from "../components/teamone";

/* Containers */
import TeamSupport from "./teamsupport";
import SupportMembers from "./supportmembers";
// import TeamHowItWorks from "./teamhowitworks";
import TeamMember from './teammember';

const TeamOne = () => (
    <PageTabs>
        {/*<PageActions>*/}
        {/*    <TeamHowItWorks />*/}
        {/*    <TeamSupport />*/}
        {/*    <SupportMembers />*/}
        {/*</PageActions>*/}
        <PageContent>
            <TeamMember />
        </PageContent>
    </PageTabs>
);

export default TeamOne;
