import { combineReducers } from 'redux';

/* Reducers */
import messagesReducer from './store/messages';

const smsReducer = combineReducers({
    messages: messagesReducer,
});

export default smsReducer;
