import React from 'react';
import styled from 'styled-components';
import {colors} from "../../../common/components/colors";

/* Components */
import {CommonBackground, CommonModal} from '../../../common/components/modal';

/* Containers */
import ListingsFlow from "../../listings/containers/flow";

const ProductModal = styled.div`
    margin: 0px auto;
    width: 748px;
    padding: 30px;
    border-radius: 6px;
    background-color: ${colors.white};
    position: absolute;
    top: 100px;
    left: 0px;
    right: 0px;
    @media screen and (max-width: 800px) {
        width: 100%;
        padding: 20px 0px;
        top: 0px;
        bottom: 0px;
        overflow-y: auto;
    }
    z-index: 21;
`;

class IframeStoreProducts extends React.Component {
    render() {
        return(
            <>
                <CommonBackground />
                <ProductModal>
                    <ListingsFlow
                        userId={this.props.profile.get("id")}
                        title={this.props.profile.get("storeTitle")}
                        description={this.props.profile.get("storeDescription")}
                        listingId={null}
                        isPreview={false}
                        forceImagesVertical={true}
                        entryPoint={"/schedule"}
                        close={() => {return this.props.changeShowProducts(false)}}
                    />
                </ProductModal>
            </>
        )
    }
}

export default IframeStoreProducts;
