import { combineReducers } from 'redux';

/* Common */
import profileReducer from './store/profile';
import scheduleReducer from "./store/scheduler";
import authReducer from "./store/auth";
import eventsReducer from "./store/events";

const schedulerReducer = combineReducers({
    profile: profileReducer,
    auth: authReducer,
    schedule: scheduleReducer,
    events: eventsReducer,
});

export default schedulerReducer;
