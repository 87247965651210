import React, {useEffect, useRef} from "react";
import {useLocation} from "react-router-dom";

export function useEffectUpdate(fn, inputs) {
    const didMountRef = useRef(false);
    useEffect(() => {
        if (didMountRef.current) {
            fn();
        } else {
            didMountRef.current = true;
        }
    }, inputs);
}


export function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}