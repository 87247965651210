import React from 'react';
import {connect} from 'react-redux';

/* Components */
import { FontHeader16} from '../../../common/components/fonts';
import { NavTopSupport } from '../components/primarytop';

const NavPrimaryTopSupport = ({user}) => {
    if(user.get("isSupport")) {
        return(
            <NavTopSupport>
                <FontHeader16>Support Account</FontHeader16>
            </NavTopSupport>
        )
    } else if(user.get("isWMCDemo")) {
        return(
            <NavTopSupport>
                <FontHeader16>Company Demo</FontHeader16>
            </NavTopSupport>
        )
    } else if(user.get("isExpertDemo")) {
        return(
            <NavTopSupport>
                <FontHeader16>Expert Demo</FontHeader16>
            </NavTopSupport>
        )
    } else {
        return null
    }
};

const mapStateToProps = state => ({
    user: state.common.user
});

export default connect(mapStateToProps, null)(NavPrimaryTopSupport);
