import React from 'react';
import {connect} from 'react-redux';
import {v4 as uuid4} from "uuid";
import ReactQuill from "react-quill";

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FillContainer, HeroContainer, HeroImgBackground, One, OneContainer, SpoofContainer, Title, WhiteContainer, QuillNoPadding} from "../components/courses";
import {FontBody14, FontHeader18} from "../../../common/components/fonts";

/* Middleware */
import {tryGetCourses} from '../middleware/courses';

class Courses extends React.Component {
    state={
        "isLoading": true,
        "courses": []
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.props.tryGetCourses();
        this.setState({
            "courses": this.props.coursesAll,
            "isLoading": false
        })
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <>
                    {this.state.courses.map((c, index) => (
                        <OneContainer key={index}>
                            <One onClick={() => {}}>
                                <HeroContainer>
                                    <SpoofContainer />
                                    <FillContainer>
                                        <HeroImgBackground imgObj={c.get("imageUrl")} />
                                    </FillContainer>
                                </HeroContainer>
                                <WhiteContainer>
                                    <FontHeader18><Title>{c.get("title")}</Title></FontHeader18>
                                    <FontBody14>
                                        <QuillNoPadding>
                                            <ReactQuill
                                                id={uuid4()}
                                                readOnly={true}
                                                theme={null}
                                                value={c.get("description")}
                                                bounds={'.app'}
                                            />
                                        </QuillNoPadding>
                                    </FontBody14>
                                </WhiteContainer>
                            </One>
                        </OneContainer>
                    ))}
                </>
            )
        }
    }

}

const mapStateToProps = state => ({
    coursesAll: state.enterprise.forms.courses.get("coursesAll")
});

const mapDispatchToProps = dispatch => ({
    tryGetCourses: () => dispatch(tryGetCourses())
});

export default connect(mapStateToProps, mapDispatchToProps)(Courses);