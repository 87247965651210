import React from 'react';
import {connect} from 'react-redux';

/* Components */
import {FontTitle21, FontTitle18} from "../../../common/components/fonts";
import {Page, ModalDelete, Title, Background, Center, CancelButton} from '../components/cancel';
import {ButtonLinkInactive} from "../../../common/components/buttons";

/* Containers */
import CheckoutCardsEditStripe from '../../../iframes/cards/containers/cardseditsform';

/* Middleware */
import {tryDeleteBillingPaymentMethod, tryGetBillingPaymentMethod, tryGetBillingPaymentMethodUpdate} from "../middleware/billing";

class BillingEdit extends React.Component {
    state={"isSaved": false, "isSaving": false};

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    timeout = 0;

    close = async () => {
        return this.props.showEdit(false)
    };

    onDeleteCard = async () => {
        await this.props.tryDeleteBillingPaymentMethod(this.props.cardEditing.id);
        this.onEditCard();
    };

    onEditCard = async request => {
        this.setState({'isSaving': true});
        await this.props.tryGetBillingPaymentMethodUpdate(request);
        await this.props.tryGetBillingPaymentMethod();
        this.setState({'isSaving': false, 'isSaved': true});
        this.timeout = setTimeout(this.close, 1000);
    };

    render() {
        return (
            <Page>
                <ModalDelete>
                    {this.state.isSaved
                        ? <Center><FontTitle18>Your changes have been saved</FontTitle18></Center>
                        :
                        <>
                            <Title><FontTitle21>Edit Card</FontTitle21></Title>
                            <CheckoutCardsEditStripe
                                onEditCard={this.onEditCard}
                                cardEditing={this.props.cardEditing}
                                onDeleteCard={this.onDeleteCard}
                                showDeleteButton={false}
                                onClose={this.close}
                            />
                        </>
                    }
                </ModalDelete>
                {this.state.isSaved ?
                    <Background onClick={this.close}/>
                    :
                    <Background />
                }
            </Page>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    tryGetBillingPaymentMethodUpdate: (request) => dispatch(tryGetBillingPaymentMethodUpdate(request)),
    tryDeleteBillingPaymentMethod: (id) => dispatch(tryDeleteBillingPaymentMethod(id)),
    tryGetBillingPaymentMethod: () => dispatch(tryGetBillingPaymentMethod())
});

export default connect(null, mapDispatchToProps)(BillingEdit);