import React from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import moment from 'moment/moment';
import {withRouter} from 'react-router-dom';
import {Map, List, fromJS} from 'immutable';
import LazyLoad from 'react-lazyload';

/* Components */
import {FontHeader16, FontBody16} from '../../common/components/fonts';
import {colors} from '../../common/components/colors';
import {ButtonTertiary} from "../../common/components/buttons";
import {LogoLoading, LogoLoadingSmall} from "../../common/components/loading";
import {Message} from "../../common/components/messages";

/* Containers */
import Meeting from './contactactivitymeeting';
// import Task from './contactactivitytask';
import Email from './contactactivityemail';
import SMS from './contactactivitysms';
// import Note from './contactactivitynote';

/* Middleware */
import {tryGetEnterpriseClientActivity, tryGetEnterpriseClientActivityFutureSessions} from '../middleware/clientsactivity';

/* Store */
import {setClientsActivity, setClientsActivityProspectIdLoaded, setClientsActivityPauseComponentUpdate} from '../store/clientsactivity';

/* Components */
const Section = styled.div`
    padding: 20px;
    background-color: ${colors.border30};
    max-height: 100%;
    @media screen and (max-width: 460px) {
        padding: 5px;
    }
`;
const DateBox = styled.div`
    margin-bottom: 10px;
    padding-left: 15px;
`;
const Empty = styled.div`
    margin: 30px;
    color: ${colors.primary70};
    text-align: center;
`;
const LoadMore = styled.div`
    text-align: center;
    margin: 60px auto 0px auto;
    width: fit-content;
`;
const Bar = styled.div`
    width: 100%;
    margin-bottom: 40px;
    background-color: ${colors.border100};
`;
const Button = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 10px 25px;
    ${props => props.active === true} {color: ${colors.primary70};}
    ${props => props.active === false} {color: ${colors.action100};}
    margin-right: 20px;
    cursor: pointer;
`;

class Activity extends React.Component {
    state={
        "isLoading": true,
        "activityByMonth": Map(),
        "isFirstLoad": false,
        "prospectStatus": null,
        "page": "all"
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if((prevProps.activity !== this.props.activity || prevProps.months !== this.props.months || prevProps.location.pathname !== this.props.location.pathname) && !this.props.pauseUpdate) {
            return this.update();
        }
        if(prevProps.prospectStatus !== this.props.prospectStatus && !this.props.pauseUpdate) {
            return this.init();
        }
    }

    componentWillUnmount() {

    }

    init = async () => {
        if(this.props.prospectStatus !== "Contact") {
            if(this.props.prospectId !== this.props.prospectIdLoaded) {
                await this.props.setClientsActivityPauseComponentUpdate(true);
                await this.getForwardActivity();
                await this.getMonthsActivity(moment(), true);
                // await this.getMonthsActivity(moment(), false);
                await this.props.setClientsActivityPauseComponentUpdate(false);
            }
            this.update();
        } else {
            this.setState({"prospectStatus": this.props.prospectStatus, "isLoading": false});
        }
    };

    getForwardActivity = async () => {
        const datetime = moment().endOf("month").subtract(new Date().getTimezoneOffset(), "minutes").add(1, "day").format("YYYY-MM-DD");
        await this.props.tryGetEnterpriseClientActivityFutureSessions(datetime, this.props.prospectId);
    };

    getMonthsActivity = async (date, append) => {
        const datetime = moment(date).endOf("month").subtract(new Date().getTimezoneOffset(), "minutes").format("YYYY-MM-DD");
        const activity = await this.props.tryGetEnterpriseClientActivity(datetime, null);
        await this.props.setClientsActivity(activity, append, datetime);
        await this.props.setClientsActivityProspectIdLoaded(this.props.prospectId);
    };

    getMoreActivity = async () => {
        const lastMonth = moment(this.props.months.get(this.props.months.size-1)).startOf("month").subtract(1, "day").format("YYYY-MM-DD");
        console.log(lastMonth);
        await this.getMonthsActivity(lastMonth, true);
    };

    update = async () => {
        await this.setState({"prospectStatus": this.props.prospectStatus, "isLoading": true});
        await this.filterActivity();
        return this.setState({"isLoading": false});
    };

    filterActivity = async () => {
        // Reset history
        await this.setState({"activityByMonth": List()});
        // Filter
        let activityFiltered = await this.props.activity.filter(a => {
                if (this.state.page === "all") {
                    return this.props.activity;
                // }
                // else if (this.state.page === "tasks") {
                //     return (a.get("activityType") === "currentTasks" || a.get("activityType") === "completedTasks")
                } else if (this.state.page === "email") {
                    return (a.get("activityType") === "email")
                } else if (this.state.page === "sms") {
                    return (a.get("activityType") === "sms")
                } else if (this.state.page === "meetings") {
                    return ((a.get("activityType") === "pastConferences" || a.get("activityType") === "ongoingConferences" ||
                            a.get("activityType") === "upcomingConferences" || a.get("activityType") === "canceledConferences"))
                // } else if (this.state.page === "notes") {
                //     return (a.get("activityType") === "userNotes")
                }
            });
        // Sort
        const activityOrdered = await activityFiltered.sort((a, b) => {
            return(b.get("orderDatetime") - a.get("orderDatetime"))
        });
        // Fit months
        const res = await this.categorize(activityOrdered);
        await this.setState({"activityByMonth": fromJS(res)});
    };

    categorize = async (activityOrdered) => {
        let categorized = {};
        await Promise.all(this.props.months.map(m => {
            categorized = {
                ...categorized,
                [moment(m).format("YYYY-MM")]: activityOrdered.filter(o => {
                    return (moment(o.get("orderDatetime")).format("YYYY-MM") === moment(m).format("YYYY-MM"))
                })
            };
            return Promise.resolve(fromJS(categorized));
        }));
        return categorized;
    };

    click = async (page) => {
        await this.setState({"page": page});
        await this.init();
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            if(this.state.prospectStatus === "Contact") {
                return(<Section><Message text={"Change relationship to prospect or client to start tracking activity, finances, and add coaching"} /></Section>)
            } else {
                if(this.state.activityByMonth.size === 0) {
                    return(<Section />)
                } else {
                    return(
                        <>
                            <Bar>
                                <Button active={this.state.page === "all"} onClick={() => this.click("all")}><FontHeader16>All</FontHeader16></Button>
                                {/*<Button active={this.state.page === "tasks"} onClick={() => this.click("tasks")}><FontHeader16>Tasks</FontHeader16></Button>*/}
                                <Button active={this.state.page === "email"} onClick={() => this.click("email")}><FontHeader16>Email</FontHeader16></Button>
                                <Button active={this.state.page === "sms"} onClick={() => this.click("sms")}><FontHeader16>SMS</FontHeader16></Button>
                                <Button active={this.state.page === "meetings"} onClick={() => this.click("meetings")}><FontHeader16>Meetings</FontHeader16></Button>
                                {/*<Button active={this.state.page === "notes"} onClick={() => this.click("notes")}><FontHeader16>Notes</FontHeader16></Button>*/}
                            </Bar>
                            <Section>
                                {Object.keys(this.state.activityByMonth.toJS()).sort((a, b) => {return(new Date(b) - new Date(a))}).map((m, mIndex) => (
                                    <div key={mIndex}>
                                        <DateBox><FontBody16>{moment(m).format("MMMM YYYY")}</FontBody16></DateBox>
                                        {this.state.activityByMonth.get(m).size === 0
                                            ? <Empty><FontHeader16>No activity this month</FontHeader16></Empty>
                                            : <>
                                                {this.state.activityByMonth.get(m).map((a, aIndex) => (
                                                    <LazyLoad offset={200} key={aIndex} placeholder={<LogoLoadingSmall />} >
                                                        <div>
                                                            {(a.get("activityType") === "upcomingConferences" || a.get("activityType") === "ongoingConferences" || a.get("activityType") === "pastConferences" || a.get("activityType") === "canceledConferences") && <Meeting data={a} forceUpdate={this.update} init={this.init} />}
                                                            {(a.get("activityType") === "email") && <Email data={a} />}
                                                            {(a.get("activityType") === "sms") && <SMS data={a} />}
                                                            {/*{(a.get("activityType") === "completedTasks" || a.get("activityType") === "currentTasks") && <Task data={a} />}*/}
                                                            {/*{(a.get("activityType") === "userNotes") && <Note data={a} />}*/}
                                                        </div>
                                                    </LazyLoad>
                                                ))}
                                            </>
                                        }
                                    </div>
                                ))}
                                <LoadMore onClick={this.getMoreActivity}><ButtonTertiary label={"Load more"} canSubmit={false} /></LoadMore>
                            </Section>
                        </>
                    )
                }
            }
        }
    }
}

const mapStateToProps = state => ({
    activity: state.enterprise.clientsActivity.get("clientActivity"),
    months: state.enterprise.clientsActivity.get("clientActivityMonths"),
    prospectIdLoaded: state.enterprise.clientsActivity.get("clientActivityProspectIdLoaded"),
    prospectStatus: state.enterprise.clientsDetail.get("client").get("prospectStatus"),
    prospectId: state.enterprise.clientsDetail.get("client").get("prospectId"),
    pauseUpdate: state.enterprise.clientsActivity.get("clientActivityPauseComponentUpdate")
});

const mapDispatchToProps = dispatch => ({
    tryGetEnterpriseClientActivity: (date, activityType) => dispatch(tryGetEnterpriseClientActivity(date, activityType)),
    tryGetEnterpriseClientActivityFutureSessions: (date, prospectId) => dispatch(tryGetEnterpriseClientActivityFutureSessions(date, prospectId)),
    setClientsActivityProspectIdLoaded: (contactId) => dispatch(setClientsActivityProspectIdLoaded(contactId)),
    setClientsActivity: (activity, append, date) => dispatch(setClientsActivity(activity, append, date)),
    setClientsActivityPauseComponentUpdate: (status) => dispatch(setClientsActivityPauseComponentUpdate(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Activity));