import { combineReducers } from 'redux';

/* Reducers */
import coursesReducer from './store/courses';

const formsReducer = combineReducers({
    courses: coursesReducer,
});

export default formsReducer;
