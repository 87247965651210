import React from 'react';
import styled from "styled-components";

/* Components */
import {ContactBottom, Level1, Level2, Level3} from "../components/contactcontainer";
import ContactTop from "../components/contacttop";

/* Containers */
import SignupFlow from '../../signup/containers/flow';
import {LinkStyled} from "../../../common/components/inputs";
import {FontBody12} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const GrayLink = styled.div`
    color: ${colors.secondary100};
    display: inline-block;
    vertical-align: top;
    text-decoration: underline;
    font-size: 10px;
`;
const SignupFlowContainer = styled.div`
  perspective: 1000px;
  background-color:transparent;
  & > div:nth-child(1) {
    border-radius: 0 0 20px 20px !important;
    background-color: white !important;
    color: black;
  }
  & .disclosureLabel, .disclosureLabel a {
    color: black !important;
    text-align: left;
  }
  //& .password-input {
  //  display:none;
  //}
  position: relative;
  & .prevButton {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0;
  }
`;

ContactPage5.defaultProps = {
    show: false,
    onSubmit: () => {},
}

export default function ContactPage5(props) {
    const show = props.show;
    const onSubmit = props.onSubmit;

    return(
        <Level1 toggle={show}>
            <Level2 animate={show}>
                <Level3>
                    <ContactTop title={"Receive your calendar invite by email"} />
                    <ContactBottom>
                        <SignupFlowContainer>
                            {/*<Title>Enter your first name and email address to complete</Title>*/}
                            {/*Put message give us email address so we can send you invite*/}
                            <SignupFlow // remove last name and password and checkbox
                                signupSystem={"page"}
                                signupPageOnSubmit={onSubmit}
                                signupSubmitLabel={"Schedule call & Send Invite"}
                                showhrCode={false}
                                showLastName={false}
                                showPassword={false}
                                showAttstationNoCheckbox={true}
                                attestationDisclosure={<FontBody12>By booking the call, you agree to our <LinkStyled target={"_blank"} href={"https://"+env.URL.DOMAIN+"/terms-of-use/"}><GrayLink>Terms of Use</GrayLink></LinkStyled> and <LinkStyled href={"https://"+env.URL.DOMAIN+"/privacy-policy/"} target={"_blank"}><GrayLink>Privacy Policy</GrayLink></LinkStyled>.</FontBody12>}
                                signupIsAdvertising={true}
                            />
                        </SignupFlowContainer>
                    </ContactBottom>
                </Level3>
            </Level2>
        </Level1>
    );
}