import React from 'react';
import styled from 'styled-components';
import {colors} from '../../common/components/colors';


export const PageTabs = styled.div`
    position: relative;
    width: calc(100% - 340px);
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

export const PageActions = styled.div`
    position: absolute;
    width: 280px;
    left: calc(100% + 60px);
    display: inline-block;
    vertical-align: top;
    top: 77px;
    @media screen and (max-width: 768px) {
        position: relative;
        left: 0px;
        top: 0px;
        display: block;
        width: 100%;
        margin-left: 0px;
    }
`;
export const ActionBox = styled.div`
    border: 1px solid ${colors.border100};
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: calc(100% - 42px);
    border-radius: 6px;
    margin-bottom: 15px;
`;
export const ActionItems = styled.div`
    margin-top: 15px;
`;
export const ActionIcon = styled.div`
    font-size: 14px;
    height: 19px;
    width: 25px;
    border-radius: 24px;
    background-color: ${colors.white};
    ${props => props.disabled === true} {
        color: ${colors.action100};
        border: 2px solid ${colors.action70};
    }
    ${props => props.disabled === false} {
        color: ${colors.secondary30};
        border: 2px solid ${colors.secondary30};
    }
    margin: 0px auto 5px auto;
    padding-top: 5px;
`;
// Note - ActionItem width = 40 when 5 actions
export const ActionItem = styled.div`
    display: inline-block;
    ${props => props.extraWidth === true} {width: 49px}
    ${props => props.extraWidth === false} {width: 61px}
    height: 53px;
    margin-right: 7px;
    vertical-align: top;
    text-align: center;
    ${props => props.disabled === true} {
        cursor: pointer;
        color: ${colors.primary100};
    }
    ${props => props.disabled === false} {
        cursor: default;
        color: ${colors.secondary70};
    }
    
`;
export const InlineItemLeft = styled.div`
    display: inline-block;
    width: calc(100% - 25px);
    vertical-align: top;
`;
export const InlineItemRight = styled.div`
    display: inline-block;
    vertical-align: top;
`;
export const DeactiveEditIcon = styled.div`
    color: ${colors.secondary70};
    cursor: default;
    padding: 4.5px;
`;
export const EditIcon = styled.div`
    color: ${colors.action100};
    cursor: pointer;
    padding: 4.5px;
`;
export const FitContent = styled.div`
    width: fit-content;
`;
export const FitContentButton = styled.div`
    width: fit-content;
    margin-right: 5px;
    display: inline-block;
    & div {
        cursor: pointer !important;
    }
`;
export const FitWidthButton = styled.div`
    & div {
        width: calc(100% - 46px);
    }
    & div div {
        width: 100%;
        text-align: center;
    }
`;

