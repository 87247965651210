import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';

/* Components */
import {SubmitInputWithValidation} from "../../../common/components/inputs";
import {colors} from "../../../common/components/colors";
import {FontTitle18} from '../../../common/components/fonts';
import {Width} from "../../components/clientscreate";

/* Middleware */
// import {tryUpdateExpertProfile} from '../../../deprecated/enterprise/middleware/expertprofile';

const Border = styled.div`
    height: 25px;
    border-bottom: 1px solid ${colors.border100};
    margin-bottom: 40px;
`;
const Image = styled.img`
    border: 5px solid ${colors.border100};
    padding: 20px;
    width: 100%;
`;

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {resolve(reader.result);};
    reader.onerror = error => reject(error);
});

class SettingsBrandingLogo extends React.Component {
    state = {
        photo: '', photoChanged: false, photoBlob: '',
        isSaving: false,
        isSaved: false,
        isChanged: false,
        error: ''
    };

    componentDidMount() {
        this.setState({
            ...this.state,
            ...this.props.wmcFirm.toJS(),
            "photo": this.props.wmcFirm.get("companyLogoUrl")
        });
    }

    handleNewImage = async e => {
        await this.setState({"newImage": e.target.files[0]});
        await this.setState({
            "photo": await toBase64(this.state.newImage),
            'photoChanged': true,
            'photoBlob': this.state.newImage,
            'isChanged': true
        });
        console.log(this.state)
    };

    handleSubmit = e => {
        e.preventDefault();
        if(this.state.isChanged) {
            this.setState({'isSaving': true});
            console.log("updating logo")
            // Promise.resolve(this.props.tryUpdateExpertProfile(this.state, "error"))
            //     .then(() => {
            //         this.setState({'error': this.props.settingsEnterpriseProfile.get("error")});
            //         this.setState({'isSaving': false, 'isSaved': true});
            //         this.timeout = setTimeout(() => {this.setState({'isSaved': false})}, 3000);
            //         this.setState({photoChanged: false, photoBlob: '', photoComponent: <UploadAvatar photo={this.props.settingsEnterpriseProfile.get("photo")} onChange={this.handleChangePhoto} />});
            //     })
        }
    };

    render() {
        return(
            <>
                <form method={"post"} onSubmit={this.handleSubmit}>
                    {this.state.isSaved
                        ?
                        <>
                            {this.state.error === '' && <FontTitle18>Saved!</FontTitle18>}
                            {this.state.error !== '' && <FontTitle18>{this.state.error}</FontTitle18>}
                        </>
                        :
                        <>
                            <Width>
                                <FontTitle18>Company Logo</FontTitle18>
                                <br />
                                <Image alt={"Logo"} src={this.state.photo} />
                                {/*<br />*/}
                                {/*<br />*/}
                                {/*<input name="newImage" type="file" onChange={this.handleNewImage} />*/}
                                {/*<br />*/}
                                {/*<SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={true} isSaving={this.state.isSaving} />*/}
                                <br />
                            </Width>
                        </>
                    }
                </form>
                <Border/>
            </>
        )
    }
}

const mapStateToProps = state => ({
    wmcFirm: state.common.wmc.get("wmcFirm")
});
const mapDispatchToProps = dispatch => ({
    // tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SettingsBrandingLogo);
