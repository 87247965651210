import { Map, fromJS } from 'immutable';

/* Immutable */

const LOGIN_AUTHENTICATED = 'LOGIN_AUTHENTICATED';
const SET_IS_LILY = 'SET_IS_LILY';
const SET_PLATFORM_TO = 'SET_PLATFORM_TO';
const SET_SIGN_UP = 'SET_SIGN_UP';
const SET_SIGN_UP_DONE = 'SET_SIGN_UP_DONE';
const SET_SIGN_UP_ERROR = 'SET_SIGN_UP_ERROR';
const SET_SIGN_UP_IS_EXCLUSIVE = 'SET_SIGN_UP_IS_EXCLUSIVE'
const SET_COMPANY_NAME = 'SET_COMPANY_NAME';
const SHOW_SUPPORT = 'SHOW_SUPPORT';
const USER_STORE_RESET = 'USER_STORE_RESET';
const SHOW_DEMO = 'SHOW_DEMO';
const SET_DEMO_ALREADY_SHOWN_ON_HOME = 'SET_DEMO_ALREADY_SHOWN_ON_HOME';
const SHOW_FIND_COACH = 'SHOW_FIND_COACH';
const SET_FIND_COACH_SHOWN = 'SET_FIND_COACH_SHOWN'
const SET_REDIRECT = 'SET_REDIRECT'

/* Actions */

export const setLoggedIn = (user) => ({'type': LOGIN_AUTHENTICATED, 'user': user});
export const setIsLily = () => ({'type': SET_IS_LILY});
export const setPlatformTo = (platform) => ({'type': SET_PLATFORM_TO, 'platform': platform});
export const setSigningUp = (status) => ({'type': SET_SIGN_UP, 'status': status});
export const setSignUpError = (error) => ({'type': SET_SIGN_UP_ERROR, 'error': error});
export const setSignUpDone = (done) => ({'type': SET_SIGN_UP_DONE, 'done': done});
export const setSignUpIsExclusive = () => ({'type': SET_SIGN_UP_IS_EXCLUSIVE});
export const setCompanyName = (companyName) => ({'type': SET_COMPANY_NAME, 'companyName': companyName});
export const setShowSupport = (status) => ({'type': SHOW_SUPPORT, 'status': status});
export const setUserStoreReset = () => ({'type': USER_STORE_RESET});
export const setShowDemo = (status) => ({'type': SHOW_DEMO, 'status': status});
export const setDemoAlreadyShownOnHome = (status) => ({'type': SET_DEMO_ALREADY_SHOWN_ON_HOME, 'status': status});

export const setShowFindCoach = (status) => ({'type': SHOW_FIND_COACH, 'status': status});
export const setFindCoachShown = (status) => ({'type': SET_FIND_COACH_SHOWN, 'status': status});
export const setRedirect = (redirectNow, from, to, token) => ({'type': SET_REDIRECT, 'redirectNow': redirectNow, 'from': from, 'to': to, 'token': token});

/* Initial State */

const initialState = Map({
    isUserAuthenticated: false,
    email: null,
    first: null,
    last: null,
    subscription: false,
    platform: null,
    userId: null,
    signingUp: false,
    signUpError: null,
    signUpDone: false,
    signUpIsExclusive: false,
    companyName: null,
    wmc: Map(),
    showSupport: false,
    hasPartners: false,
    showDemo: false,
    demoAlreadyShownOnHome: false,
    prospectWmcId: null,
    showFindCoachPrompt: false,
    findCoachPromptShown: false,
    isLily: false,
    redirectFrom: null,
    redirectTo: null,
    redirectToken: null,
    isReferredByPartner: false
});

/* Reducer */

const userReducer = (state=initialState, action) => {
    switch (action.type) {
        case LOGIN_AUTHENTICATED:
            return state.merge(fromJS({
                'isUserAuthenticated': true,
                ...action.user
            }));
        case SET_PLATFORM_TO:
            return state.merge({"platform": action.platform});
        case SET_IS_LILY:
            return state.merge({"isLily": true});
        case SET_SIGN_UP:
            return state.merge({"signingUp": action.status});
        case SET_SIGN_UP_ERROR:
            return state.merge({"signUpError": action.error});
        case SET_SIGN_UP_DONE:
            return state.merge({"signUpDone": action.done});
        case SET_SIGN_UP_IS_EXCLUSIVE:
            return state.merge({"signUpIsExclusive": true});
        case SHOW_SUPPORT:
            return state.merge({"showSupport": action.status});
        case SET_COMPANY_NAME:
            return state.merge({"companyName": action.companyName});
        case USER_STORE_RESET:
            return state.merge(initialState);
        case SHOW_DEMO:
            return state.merge({"showDemo": action.status});
        case SET_DEMO_ALREADY_SHOWN_ON_HOME:
            return state.merge({"demoAlreadyShownOnHome": action.status});
        case SHOW_FIND_COACH:
            return state.merge({"showFindCoachPrompt": action.status});
        case SET_FIND_COACH_SHOWN:
            return state.merge({"findCoachPromptShown": action.status});
        case SET_REDIRECT:
            return state.merge({
                "redirectNow": action.redirectNow,
                "redirectFrom": action.from,
                "redirectTo": action.to,
                "redirectToken": action.token
            });
        default:
            return state;
    }
};

export default userReducer;
