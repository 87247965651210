import styled from "styled-components";
import {FontSubtitle28} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";

export const PageTitle = styled(FontSubtitle28)`
    text-align: center;
`;
export const Extra54px = styled.div`
    height: 24px;
    width: 100%;
`;
export const BookButton = styled.div`
    width: fit-content;
    cursor: pointer;
    margin: 20px auto 0px auto;
`;
export const Container = styled.div`
    overflow-y: auto;
    background-color: ${colors.white};
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 0;
    top: 0;
    bottom: 0;
    width: 500px;
    z-index: 11;
    @media screen and (max-width: 748px) {
        left: 0;
        width: auto;
    }
`;
export const BackSection = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 30px;
    padding: 25px 0px 0px 25px;
    width: calc(100% - 25px);
    @media screen and (max-width: 460px) {
        padding: 15px 0px 0px 15px;
        width: calc(100% - 15px);
        margin-bottom: 15px;
    }
`;

export const Back = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
`;

export const ContainerJCFS = styled.div`
    justify-content: flex-start;
    row-gap: 30px;
    width: calc(100% - 100px);
    @media screen and (max-width: 1440px) {
        width: calc(100% - 80px);
    }
    @media screen and (max-width: 768px) {
        width: calc(100% - 60px);
    }
    @media screen and (max-width: 460px) {
        margin: 0px auto 20px auto;
        width: calc(100% - 40px);
    }
    margin: 0px auto 0px auto;
    display: flex;
    flex-direction: column;
    z-index: 0;
`;
export const ModifyListings = styled.div`
    width: 100%;
    max-width: 100% !important;
    & > div {
        padding: 0px;
        margin-top: 40px;
    }
`;