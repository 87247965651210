import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {Card, Title, Bookmarks, SettingsCardMissingInfo, SpaceBetweenMissingInfo} from "../components/bookmarkone";
import {FontSubtitle24To20, FontTitle16} from "../../../common/components/fonts";
import {ButtonPrimary} from "../../../common/components/buttons";

/* Middleware */
import {tryGetCourses} from '../../progress/middleware/progress';

/* Containers */
import BookmarkOne from './bookmarksone';

class CoursesListBookmarks extends React.Component {
    state = {"isLoading": true, "bookmarks": []};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.props.tryGetCourses();
        this.setState({
            "bookmarks": await this.props.courses.filter(a => {
                let isCourse = !a.get("course").get("isReadingMaterial");
                // let assignedToUserId = this.props.userId;
                // let assignedByUserId = a.get("assignedByUser").get("id");
                // let isComplete = a.get("isCompleted");
                let isActive = a.get("course").get("isActive");
                return (!isCourse && isActive);
            }),
            "isLoading": false
        });
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            if (this.state.bookmarks.size > 0) {
                return (
                    <Card>
                        <Title>
                            <FontSubtitle24To20>SAVED CONTENT</FontSubtitle24To20>
                        </Title>
                        <Bookmarks>
                            {this.state.bookmarks.map((a, index) => {
                                return(<BookmarkOne key={index} data={a.toJS()} />)
                            })}
                        </Bookmarks>
                    </Card>
                )
            } else {
                return (
                    <SettingsCardMissingInfo>
                        <SpaceBetweenMissingInfo direction={"column"}>
                            <FontTitle16>Nothing bookmarked.<br/>Find and save articles, videos, and courses.</FontTitle16>
                            <div onClick={() => {return this.props.history.push('/learn')}}>
                                <ButtonPrimary submit={false} label={"Browse topics"} />
                            </div>
                        </SpaceBetweenMissingInfo>
                    </SettingsCardMissingInfo>
                )            }
        }
    }
}

const mapStateToProps = state => ({
    courses: state.consumer.progress.progress.get("courses"),
    userId: state.common.user.get("userId")
});

const mapDispatchToProps = dispatch => ({
    tryGetCourses: () => dispatch(tryGetCourses())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CoursesListBookmarks));