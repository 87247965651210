import React from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";
import ReactQuill from "react-quill";
import { v4 as uuid4 } from "uuid";

/* Components */
import {colors} from '../../../common/components/colors';
import {FontBody14, FontBody16, FontHeader18, FontTitle24} from '../../../common/components/fonts';
import {ButtonPrimary, ButtonTertiary, ButtonInactive} from '../../../common/components/buttons';
import {CloseOutlined, ContainerOutlined, LineOutlined} from '@ant-design/icons';
import {editorFormats, editorModules} from "../../../common/components/richtexteditor";
import { CheckboxWithLabelNoSpacer } from '../../../common/components/inputs';
import { RadioButtonsTen, DropdownInput } from "../../../common/components/inputs";
import { Accordion } from '../components/accordion';
/* Middleware */
import { tryPostEnterpriseNote, tryUpdateEnterpriseNote, tryDeleteEnterpriseNote, tryGetEnterpriseSharedNotes } from "../middleware/notes";

/* Store */
import { setNoteModal, setNoteModalMinimized, setNoteConfirmModal, setNoteSelected } from "../store/notes";

export const NoteTitle = styled.div`
    padding: 15px 0px 10px 0px;
    ${props => props.isFromMe === true} {
        color: ${colors.secondary100};
    }
    ${props => props.isFromMe === false} {
        color: ${colors.white};
    }
`;
export const Date = styled.div`
    color: ${colors.primary30};
    margin-top: 20px;
`;
const ComposeWrapper = styled.div`
    border-radius: 6px 6px 0px 0px;
    z-index: 15;
    position: fixed;
    bottom: 0;
    right: 100px;
    max-width: 800px;
    width: 100%;
    -webkit-box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    @media screen and (max-width: 1088px) {
        right: 60px;
        max-width: 640px;
    }
    @media screen and (max-width: 768px) {
        -webkit-box-shadow: none;
        box-shadow: none;
        ${props => props.minimized !== false} {
            top: 0;
            height: 100%;
            overflow-y: auto;
        }
        background-color: ${colors.white};
        right: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
    }
`;
const Compose = styled.div`
    padding: 20px;
    background-color: ${colors.white};
    @media screen and (max-width: 768px) {
        padding: 10px;
        height: calc(100vh - 56px);
        width: calc(100% - 20px);
    }
`;
const Buttons = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const Close = styled.div`
    background-color: ${colors.primary100};
    width: 100%;
    border-radius: 6px 6px 0px 0px;
    height: 36px;
`;
const CloseMessage = styled.div`
    display: inline-block;
    color: ${colors.border100};
    padding: 7px 0px 0px 15px;
    width: calc(100% - 91px);
    cursor: pointer;
`;
const CloseButton = styled.div`
    padding: 10px;
    color: ${colors.border100};
    cursor: pointer;
    font-weight: bold;
    width: 18px;
    font-size: 14px;
    display: inline-block;
`;

const Subject = styled.input`
    border: 1px solid ${colors.primary30};
    padding: 4px 14px;
    width: calc(100% - 30px);
    margin: 5px 0px 10px 0px;
    &:active, &:focus {
        outline: none;
    }
    font-family: inherit;
    // font-size: inherit;
    line-height: inherit;
`;
const Body = styled.div`
    .ql-editor {
        min-height: 200px;
        max-height: 400px;
        @media screen and (max-width: 768px) {
            max-height: 235px;
        }
    } 
`;
const To = styled.div`
    margin-bottom: 5px;
`;
const Who = styled.div`
    display: inline-block;
    color: ${colors.primary30};
    width: 58px;
`;
const Receipt = styled.div`
    display: inline-block;
    ${props => props.isFromMe === true} {
        color: ${colors.primary100};
    }
    ${props => props.isFromMe === false} {
        color: ${colors.white};
    }
`;
const ToFrom = ({ who, recipient, isFromMe }) => (
    <div>
        <Who><FontBody16>{who + ":"}</FontBody16></Who>
        <Receipt isFromMe={isFromMe}><FontBody16>{recipient}</FontBody16></Receipt>
    </div>
);

const Modal = styled.div`
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    padding: 30px;
    border-radius: 4px;
    background-color: ${colors.white};
    -webkit-box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    max-width: 300px;
    width: calc(100% - 80px);
    z-index: 20;
`;
const ConfirmTitle = styled.div`
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.border100};
    margin-bottom: 15px;
`;
const ConfirmActions = styled.div`
    margin-top: 20px;
`;
const ConfirmLeft = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
`;
const ConfirmRight = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
`;
const ConfirmBtnFitLeft = styled.div`
    width: fit-content;
`;
const ConfirmBtnFitRight = styled.div`
    width: fit-content;
    margin: 0px 0px 0px auto;
`;
const Sent = styled.div`
    color: ${colors.progress100};
    text-align: center;
    padding: 30px 0px;
`;
const SendBtn = styled.div`
    display: inline-block;
    width: fit-content;
    vertical-align: top;
`;


class NoteCompose extends React.Component {
    constructor(props) {
        super(props);
        this.autosave = null;
        this.autosaveupdate = null;
        this.state = {
            noteEdit: false,
            switchModal: false,
            isOpen: false,
            hasChanged: false,
            edit: false,
            clientId: '',
            noteId: '',
            clientName: '',
            content: '',
            subject: '',
            quill: '',
            confirmModal: false,
            savedModal: false,
            autoSaved: false,
            notePrivate: true,
            score: {
                lifeJourneyId: null,
                lifeJourneyValue: null,
                journeyCategoryId: null,
                journeyCategoryValue: null,

                lifeJourneyDepth: null,
                trustLevel: null,
                growthOpportunity: null,
                riskOfLosing: null
            },
            fitForWillow: null,
        }
    }

    componentDidMount() { }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.lastInitiated !== this.props.lastInitiated && this.state.clientId !== this.props.noteSelected.get("clientId") && this.props.noteSelected.get("clientId") !== "") {
            if (this.state.isOpen) {
                return this.setState({ "switchModal": true })
            } else {
                return this.open()
            }
        }
    }

    open = async () => {
        await this.setState({ autoSaved: false, noteEdit: this.props.noteEdit, isOpen: this.props.noteModal, hasChanged: false, edit: this.props.noteSelected.get("edit"), clientId: this.props.noteSelected.get("clientId"), noteId: this.props.noteSelected.get("noteId"), clientName: this.props.noteSelected.get("name"), content: this.props.noteSelected.get("body"), subject: (this.props.noteSelected.get("title") === null ? '' : this.props.noteSelected.get("title")), quill: '' });
        this.handleNewQuill();
        await this.setState({ hasChanged: false });
        this.autosave = setInterval(async () => {
            this.handleAutoSaveCreateNote();
        }, 5000);
        console.log("lastinitiated", this.props.lastInitiated)
        console.log("noteSelected", this.props.noteSelected)
    };

    close = async () => {
        clearInterval(this.autosave);
        clearInterval(this.autosaveupdate);
        this.handleAutoSaveUpdateNote();
        await this.props.tryGetEnterpriseSharedNotes(this.props.client.get("consumerId"));
        await this.props.setNoteModal(false);
        await this.props.setNoteModalMinimized(false);
        await this.props.setNoteSelected({ "edit": false, "clientId": '', "noteId": '', "name": '', "title": '', "body": '', "datetime": '' }, false);
        await this.setState({ hasChanged: false, isOpen: false, clientId: '', clientName: '', content: '', subject: '', quill: '', confirmModal: false, switchModal: false });
    };

    getAvailableLifeJourneys = () => {
        // Hardcoded from apiresponses/coachcategories.js
        // Should be refactored to actual GET API calls
        return [
            {label: "Divorce", value: 2},
            {label: "Grief & Loss", value: 3},
            {label: "Family Building", value: 4},
            {label: "Dating & Marriage", value: 14},
        ]
    }

    getAvailableCategories = () => {
        // Hardcoded from apiresponses/coachcategories.js
        // Should be refactored to actual GET API calls
        switch (this.state.score.lifeJourneyId) {
            case 2:
                return [
                    {label: "Post-divorce", value: 18},
                    {label: "Getting divorced", value: 16},
                    {label: "Co-parenting", value: 39},
                    {label: "Considering divorce", value: 40},
                ]
            case 3:
                return [
                    {label: "Illness & disability", value: 24},
                    {label: "Loss of a child", value: 21},
                    {label: "Loss of a parent", value: 22},
                    {label: "Widowhood", value: 20},
                ]
            default:
                return []
        }
    }

    changeLifeJourneyId = (event) => {
        let value = event.value;
        let categoryId = null;
        let categoryValue = null;
        if(value === this.state.score.lifeJourneyId ){
            categoryId = this.state.score.journeyCategoryId;
            categoryValue = this.state.score.journeyCategoryValue;
        }

        //if score is given, note can't be private
        let isPrivateNote = this.state.notePrivate
        if (isPrivateNote)
            isPrivateNote = false;

        this.setState(prevState => ({
            notePrivate: isPrivateNote,
            score: {
                ...prevState.score,
                lifeJourneyId: value,
                lifeJourneyValue: event.label,
                journeyCategoryId: categoryId,
                journeyCategoryValue: categoryValue
            }
        }))
    }

    changeCategory = (event) => {
        //if score is given, note can't be private
        let isPrivateNote = this.state.notePrivate
        if (isPrivateNote)
            isPrivateNote = false;

        this.setState(prevState => ({
            notePrivate: isPrivateNote,
            score: {
                ...prevState.score,
                journeyCategoryId: event.value,
                journeyCategoryValue: event.label
            }
        }))
    }

    changeScoreValue = (value, label) => {
        //if score is given, note can't be private
        let isPrivateNote = this.state.notePrivate
        if (isPrivateNote)
            isPrivateNote = false;
    
        this.setState(prevState => ({
            notePrivate: isPrivateNote,
            score: {
                ...prevState.score,
                [label]: value
            }
        }));
    }

    changeFitForWillow = value => this.setState({ "fitForWillow": value });
    handleNewContent = e => this.setState({ "content": e, "hasChanged": true });
    handleNewSubject = e => this.setState({ "subject": e.target.value, "hasChanged": true });
    handleNewQuill = () => {
        this.setState({
            "quill": <ReactQuill
                id={uuid4()}
                theme={"snow"}
                onChange={this.handleNewContent}
                value={this.state.content}
                modules={editorModules}
                formats={editorFormats}
                bounds={'.app'}
                placeholder={"Write your note here..."}
            />
        });
    };

    componentWillUnmount() {
        clearInterval(this.autosave);
        clearInterval(this.autosaveupdate);
        clearInterval(this.timeout);
    }

    handleAutoSaveCreateNote = async () => {
        await this.setState({"autoSaved": true})
        if(!this.state.edit) {
            if(this.state.hasChanged && this.state.clientId !== "" && this.state.subject !== "") {
                const noteId = await this.props.tryPostEnterpriseNote(this.state.clientId, this.state.subject, this.state.content, false, this.state.notePrivate, this.state.score);
                await this.setState({"noteId": noteId, "hasChanged": false});
                clearInterval(this.autosave);
                this.autosaveupdate = setInterval(async () => {
                    this.handleAutoSaveUpdateNote();
                }, 5000);
            }
        } else {
            if (this.state.hasChanged && this.state.clientId !== "" && this.state.content !== "" && this.state.subject !== "") {
                this.autosaveupdate = setInterval(async () => {
                    this.handleAutoSaveUpdateNote();
                }, 5000);
            }
        }
    };

    handleAutoSaveUpdateNote = async () => {
        if (this.state.hasChanged && this.state.clientId !== "") {
            await this.props.tryUpdateEnterpriseNote(this.state.clientId, this.state.noteId, this.state.subject, this.state.content, false, this.state.notePrivate, this.state.score);
            this.setState({ hasChanged: false });
        }
    };

    handleSaveNewNote = async () => {
        if (this.state.autoSaved) {
            await this.handleAutoSaveUpdateNote();
            await this.close();
            await this.setState({ sentModal: true });
            this.timeout = setTimeout(() => { this.setState({ sentModal: false }) }, 3000);

        } else {
            return Promise.resolve(this.props.tryPostEnterpriseNote(this.state.clientId, this.state.subject, this.state.content, true, this.state.notePrivate, this.state.score))
                .then(async () => {
                    this.close();
                    this.setState({ sentModal: true });
                    this.timeout = setTimeout(() => { this.setState({ sentModal: false }) }, 3000);
                    // Append message to activity
                })
        }
    };

    handleSaveEditNote = async () => {
        return Promise.resolve(this.props.tryUpdateEnterpriseNote(this.state.clientId, this.state.noteId, this.state.subject, this.state.content, true, this.state.notePrivate, this.state.score))
            .then(async () => {
                this.close();
                this.setState({ sentModal: true });
                this.timeout = setTimeout(() => { this.setState({ sentModal: false }) }, 3000);
                // Append message to activity
            })
    };

    handleDeleteNote = async () => {
        await this.props.tryDeleteEnterpriseNote(this.props.client.get("consumerId"), this.props.noteConfirmDeleteId);
        this.props.setNoteConfirmModal(false, null);
    };

    handleSetPrivate = () => {
        if(!this.state.notePrivate){
            let score = {
                lifeJourneyId: null,
                lifeJourneyValue: null,
                journeyCategoryId: null,
                journeyCategoryValue: null,

                lifeJourneyDepth: null,
                trustLevel: null,
                growthOpportunity: null,
                riskOfLosing: null
            }
            this.setState({ 'notePrivate': !this.state.notePrivate, 'score': score})
        }
        else{
            this.setState({ 'notePrivate': !this.state.notePrivate })
        }
    }

    getNotePrivacyExplanation = () => {
        if(this.props.client.get("isCoachHasAdvisor")){
            if(this.state.notePrivate)
                return "This note cannot be seen by your client"
            else
                return "This note is visible to everybody"
        }
        else{
            return "This note is private only to you"
        }
    }

    switch = async () => {
        await clearInterval(this.autosave);
        await clearInterval(this.autosaveupdate);
        await this.open();
        this.setState({ "switchModal": false });
    };

    closeCheck = () => {
        if (this.state.subject === '') {
            this.close()
        } else {
            this.setState({ "confirmModal": true })
        }

    }

    render() {
        if (this.props.platform === "enterprise") {
            if (this.state.isOpen) {
                return (
                    <>
                        <ComposeWrapper minimized={this.props.noteModalMinimized}>
                            <Close>
                                <CloseMessage onClick={() => {
                                    this.props.setNoteModalMinimized(!this.props.noteModalMinimized)
                                }}><FontBody14>New Note</FontBody14></CloseMessage>
                                <CloseButton onClick={() => {
                                    this.props.setNoteModalMinimized(!this.props.noteModalMinimized)
                                }}><LineOutlined /></CloseButton>
                                <CloseButton onClick={() => {
                                    this.closeCheck()
                                }}><CloseOutlined /></CloseButton>
                            </Close>
                            {!this.props.noteModalMinimized &&
                                <Compose>
                                    <To><ToFrom isFromMe={false} who={"About"} recipient={this.state.clientName} /></To>
                                    <FontHeader18>
                                        <Subject required value={this.state.subject} onChange={this.handleNewSubject} placeholder={"Note titles"} />
                                    </FontHeader18>
                                    <Body>{this.state.quill}</Body>
                                    <div style={{
                                        // "maxWidth" : "480px",
                                        "maxHeight": "300px",
                                        "overflowY": "scroll"
                                    }}>
                                        <Accordion items={[
                                            {
                                                "title": "Score client",
                                                "content": <>
                                                    <FontBody16>Notes with scores, can't be set as private. Setting note as private will clear score selection</FontBody16>
                                                    <DropdownInput
                                                        dropUpwards={false}
                                                        id={"journey-dropdown"}
                                                        placeholder={"Life journey"}
                                                        value={this.state.score.lifeJourneyValue}
                                                        options={this.getAvailableLifeJourneys()}
                                                        onChange={this.changeLifeJourneyId}
                                                    />
                                                    <DropdownInput
                                                        dropUpwards={false}
                                                        id={"category-dropdown"}
                                                        placeholder={"Category"}
                                                        value={this.state.score.journeyCategoryValue}
                                                        options={this.getAvailableCategories()}
                                                        onChange={this.changeCategory}
                                                    />
                                                    <RadioButtonsTen
                                                        label={"Life journey depth:"}
                                                        low={"Beggining"} high={"End"}
                                                        checked={this.state.score.lifeJourneyDepth}
                                                        action={(v) => this.changeScoreValue(v, "lifeJourneyDepth")}
                                                        width={"90%"}
                                                        key={uuid4()}
                                                    />
                                                    <RadioButtonsTen
                                                        label={"Trust level:"}
                                                        low={"Negative"} high={"Positive"}
                                                        checked={this.state.score.trustLevel}
                                                        action={(v) => this.changeScoreValue(v, "trustLevel")}
                                                        key={uuid4()}
                                                    />
                                                    <RadioButtonsTen
                                                        label={"Growth opportunity:"}
                                                        low={"Small"} high={"High"}
                                                        checked={this.state.score.growthOpportunity}
                                                        action={(v) => this.changeScoreValue(v, "growthOpportunity")}
                                                        key={uuid4()}
                                                    />
                                                    <RadioButtonsTen
                                                        label={"Risk of loosing:"}
                                                        low={"High"} high={"Low"}
                                                        checked={this.state.score.riskOfLosing}
                                                        action={(v) => this.changeScoreValue(v, "riskOfLosing")}
                                                        key={uuid4()}
                                                    />
                                                </>
                                            }
                                        ]} />
                                    </div>
                                    <Buttons>
                                        {!(this.state.subject === '')
                                        ? <SendBtn><ConfirmBtnFitLeft
                                            onClick={this.state.noteEdit ? this.handleSaveEditNote : this.handleSaveNewNote}><ButtonPrimary
                                            label={"Save"} canSubmit={false}/></ConfirmBtnFitLeft></SendBtn>
                                        : <SendBtn><ButtonInactive label={"Save"} canSubmit={false}/></SendBtn>
                                        }
                                        {this.props.client.get("isCoachHasAdvisor") &&
                                            <CheckboxWithLabelNoSpacer
                                                action={this.handleSetPrivate}
                                                id={"private"}
                                                label={"Private from advisor"}
                                                checked={this.state.notePrivate}
                                            />
                                        }
                                    </Buttons>
                                    <br />
                                    <FontBody16>{this.getNotePrivacyExplanation()}</FontBody16>
                                </Compose>
                            }
                        </ComposeWrapper>
                        {this.state.confirmModal &&
                            <Modal>
                                <ConfirmTitle><FontTitle24>Are you sure?</FontTitle24></ConfirmTitle>
                                <FontBody16>Do you want to close this note? Your changes have been autosaved.</FontBody16>
                                <ConfirmActions>
                                    <ConfirmLeft>
                                        <ConfirmBtnFitLeft onClick={() => {
                                            this.setState({ "confirmModal": false })
                                        }}><ButtonTertiary label={"Cancel"} canSubmit={true} /></ConfirmBtnFitLeft>
                                    </ConfirmLeft>
                                    <ConfirmRight>
                                        <ConfirmBtnFitRight onClick={async () => {
                                            this.close()
                                        }}><ButtonPrimary label={"Close"} canSubmit={true} /></ConfirmBtnFitRight>
                                    </ConfirmRight>
                                </ConfirmActions>
                            </Modal>
                        }
                        {this.state.switchModal &&
                            <Modal>
                                <ConfirmTitle><FontTitle24>Are you sure?</FontTitle24></ConfirmTitle>
                                <FontBody16>Do you want to close this note?</FontBody16>
                                <ConfirmActions>
                                    <ConfirmLeft>
                                        <ConfirmBtnFitLeft onClick={() => { this.setState({ "switchModal": false }) }}><ButtonTertiary label={"Cancel"} canSubmit={true} /></ConfirmBtnFitLeft>
                                    </ConfirmLeft>
                                    <ConfirmRight>
                                        <ConfirmBtnFitRight onClick={async () => { this.switch() }}><ButtonPrimary label={"Close"} canSubmit={true} /></ConfirmBtnFitRight>
                                    </ConfirmRight>
                                </ConfirmActions>
                            </Modal>
                        }
                    </>
                )
            } else if (this.state.savedModal) {
                return (
                    <Modal>
                        <Sent><FontTitle24>Note saved!</FontTitle24></Sent>
                    </Modal>
                )
            } else if (this.props.noteConfirmDeleteModal) {
                return (
                    <Modal>
                        <ConfirmTitle><FontTitle24>Are you sure?</FontTitle24></ConfirmTitle>
                        <FontBody16>Do you want to delete this note? This cannot be undone.</FontBody16>
                        <ConfirmActions>
                            <ConfirmLeft>
                                <ConfirmBtnFitLeft onClick={() => { this.props.setNoteConfirmModal(false, null) }}><ButtonTertiary label={"Cancel"} canSubmit={true} /></ConfirmBtnFitLeft>
                            </ConfirmLeft>
                            <ConfirmRight>
                                <ConfirmBtnFitRight onClick={this.handleDeleteNote}><ButtonPrimary label={"Delete"} canSubmit={true} /></ConfirmBtnFitRight>
                            </ConfirmRight>
                        </ConfirmActions>
                    </Modal>
                )
            } else {
                return null
            }
        } else {
            return null
        }
    }
}

const mapStateToProps = state => ({
    noteModal: state.enterprise.notes.notes.get("noteModal"),
    noteModalMinimized: state.enterprise.notes.notes.get("noteModalMinimized"),
    noteSelected: state.enterprise.notes.notes.get("noteSelected"),
    lastInitiated: state.enterprise.notes.notes.get("lastInitiated"),
    noteEdit: state.enterprise.notes.notes.get("noteEdit"),
    noteConfirmDeleteModal: state.enterprise.notes.notes.get("noteConfirmDeleteModal"),
    noteConfirmDeleteId: state.enterprise.notes.notes.get("noteConfirmDeleteId"),
    client: state.enterprise.clientsDetail.get("client"),
    platform: state.common.user.get("platform")
});

const mapDispatchToProps = dispatch => ({
    setNoteModalMinimized: (minimized) => dispatch(setNoteModalMinimized(minimized)),
    setNoteModal: (status) => dispatch(setNoteModal(status)),
    tryPostEnterpriseNote: (clientId, title, body, withClose, notePrivate, score) => dispatch(tryPostEnterpriseNote(clientId, title, body, withClose, notePrivate, score)),
    tryUpdateEnterpriseNote: (clientId, noteId, title, body, withClose, notePrivate, score) => dispatch(tryUpdateEnterpriseNote(clientId, noteId, title, body, withClose, notePrivate, score)),
    setNoteSelected: (note, edit) => dispatch(setNoteSelected(note, edit)),
    setNoteConfirmModal: (status, noteId) => dispatch(setNoteConfirmModal(status, noteId)),
    tryDeleteEnterpriseNote: (clientId, noteId) => dispatch(tryDeleteEnterpriseNote(clientId, noteId)),
    tryGetEnterpriseSharedNotes: (consumerId) => dispatch(tryGetEnterpriseSharedNotes(consumerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoteCompose);
