import React from 'react';
import styled from 'styled-components';
import {colors} from "../../../common/components/colors";

// Note - ActionItem width = 40 when 5 actions
export const ActionItem = styled.div`
    display: inline-block;
    ${props => props.extraWidth === true} {width: 49px}
    ${props => props.extraWidth === false} {width: 61px}
    height: 53px;
    margin-left: 7px;
    vertical-align: top;
    text-align: center;
    ${props => props.disabled === true} {
        cursor: pointer;
        color: ${colors.primary100};
    }
    ${props => props.disabled === false} {
        cursor: default;
        color: ${colors.secondary70};
    }
    
`;
export const ActionIcon = styled.div`
    font-size: 14px;
    height: 19px;
    width: 25px;
    border-radius: 24px;
    background-color: ${colors.white};
    ${props => props.disabled === true} {
        color: ${colors.action100};
        border: 2px solid ${colors.action70};
    }
    ${props => props.disabled === false} {
        color: ${colors.secondary30};
        border: 2px solid ${colors.secondary30};
    }
    margin: 0px auto 5px auto;
    padding-top: 5px;
`;