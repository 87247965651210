import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setListingsAll, setListingsAllFetching} from "../store/listings";

/* Static */
import {devIframeListings} from "../static/devdata";

export const tryGetListingsAll = (userId) => {
    return async dispatch => {
        dispatch(setListingsAllFetching(true));
        const listings = await axiosReq('apiv1/public/coach/'+userId+'/expert-coaching-products', "GET", {}, devIframeListings);
        const listingAdj = await Promise.all(listings.coachingProducts.map(async l => {
            let numberOfSessions = null;
            await Promise.all(l.plans.map(p => {
                if(p.hasOwnProperty("numberOfSessions")) {
                    numberOfSessions = p.numberOfSessions;
                }
                Promise.resolve();
            }));
            return Promise.resolve({...l, "numberOfSessions": numberOfSessions});
        }));
        const events = listingAdj.filter(f => {return(f.serviceType !== "event")});
        dispatch(setListingsAll(events));
        dispatch(setListingsAllFetching(false));
        return events;
    }
};