import React from 'react';
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {v4 as uuid4} from 'uuid';
import styled from "styled-components";
import validator from "validator";

/* Store */
import {setShopItemFAQ} from "../store/shopitems";

/* Components */
import {TextInput, TextQuillInputControlCount} from "../../../common/components/inputs";
import {colors} from "../../../common/components/colors";
import {FontTitle18} from "../../../common/components/fonts";
import {InlineBlock, SaveSection, SubmitInputWithValidation} from "../../settings/scheduler/components/allservicespage";
import {ButtonTertiary} from "../../../common/components/buttons";

/* Middleware */
import {tryUpdateExpertProfile} from '../middleware/expertprofile';

export const Width = styled.div`
    max-width: 536px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
`;
const TitleMargin = styled.div`
    margin-top: -15px;
`;

class OneFAQ extends React.Component {
    state={
        "id": uuid4(),
        "title": "", "titleValid": true,
        "body": "", "bodyValid": true, "bodyCount": 0, "bodyComponent": "",
        "isSaving": false,
        "isSaved": false,
        "error": '',
        "isValid": true,
        "isChanged": false,
    };

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    init = async () => {
        if(Object.keys(this.props.faqs.toJS()).length > 0) {
            await this.setState({
                "id": this.props.faqs.get("id"),
                "title": this.props.faqs.get("title") === null ? "" : this.props.faqs.get("title"),
                "body": this.props.faqs.get("body"),
                "bodyCount": this.props.faqs.get("body").length,
            });
        }
        await this.updateBody();
        this.checkValid();
    };

    updateBody = () => {
        this.setState({
            bodyComponent: <TextQuillInputControlCount
                title={"Answer*"}
                valid={this.state.bodyValid}
                warning={"Required"}
                id={"body"}
                onChange={this.handleChangeBody}
                placeholder={"Add an answer"}
                value={this.state.body}
                maxLength={3000}
                count={this.state.bodyCount}
                minRows={8}
                required={true}
                countColorAbove={colors.white}
                countColorBelow={colors.white}
            />
        })
    };

    handleChangeBody = async e => {
        await this.setState({
            'body': e,
            'bodyValid': (!validator.isEmpty(e) && e !== '<p><br></p>' && e !== ''),
            'bodyCount': (validator.isEmpty(e) || e === '<p><br></p>' || e === '') ? 0 : e.replace( /(<([^>]+)>)/ig, '').length,
            'isChanged': true
        });
        await this.updateBody();
        this.checkValid();
    };

    handleChangeTitle = async e => {
        await this.setState({
            'title': e.target.value,
            'titleValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''),
            'isChanged': true
        });
        this.checkValid();
    };

    checkValid = () => {
        let titleValid = (!validator.isEmpty(this.state.title) && this.state.title !== '');
        let bodyValid = (!validator.isEmpty(this.state.body) && this.state.body !== '<p><br></p>' && this.state.body !== '');
        if(bodyValid && titleValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            this.setState({'isSaving': true});
            const data = {
                "id": this.state.id,
                "title": this.state.title,
                "body": this.state.body,
            };
            let faqs = this.props.profile.get("faqs").toJS();
            if(this.props.location.pathname === "/"+(this.props.isWMC ? "profile" : "shop")+"/faqs/create") {
                await faqs.push(data);
            } else {
                faqs = faqs.map(p => {
                    if(p.id === this.state.id) {
                        return(data)
                    } else {
                        return(p)
                    }
                })
            }
            const saveData = {
                // "bio": this.props.profile.get("bio"),
                // "whyBecameCoach": this.props.profile.get("whyBecameCoach"),
                // "freeTime": this.props.profile.get("freeTime"),
                // "endorsement1": this.props.profile.get("endorsement1"),
                // "endorsement2": this.props.profile.get("endorsement2"),
                // "endorsement3": this.props.profile.get("endorsement3"),
                "faqs": faqs
            };
            console.log(saveData);
            await this.props.tryUpdateExpertProfile(saveData, "onePortfolio");
            this.setState({'isSaving': false, 'isSaved': true});
            this.timeout = setTimeout(() => {
                this.setState({'isSaved': false});
                this.props.setShopItemFAQ({});
                this.props.history.push("/"+(this.props.isWMC ? "profile" : "shop")+"/faqs");
            }, 3000);
        }
    };

    render() {
        if(this.state.isSaved) {
            if(this.state.error === "") {
                if(this.props.location.pathname === "/"+(this.props.isWMC ? "profile" : "shop")+"/faqs/create") {
                    return(<FontTitle18>Created!</FontTitle18>)
                } else {
                    return(<FontTitle18>Saved!</FontTitle18>)
                }
            } else if(this.state.error !== '') {
                return(<FontTitle18>{this.state.error}</FontTitle18>)
            }
        } else {
            return(
                <Width>
                    <form method={"post"} onSubmit={this.handleSubmit}>
                        <TitleMargin>
                            <TextInput
                                title={"Question*"}
                                valid={this.state.titleValid}
                                warning={"Required"}
                                id={"title"}
                                onChange={this.handleChangeTitle}
                                placeholder={"Add a question"}
                                value={this.state.title}
                            />
                        </TitleMargin>
                        {this.state.bodyComponent}
                        <SaveSection>
                            <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                            <InlineBlock><Link to={"/"+(this.props.isWMC ? "profile" : "shop")+"/faqs"}><ButtonTertiary canSubmit={false} label={"Cancel"} /></Link></InlineBlock>
                        </SaveSection>
                    </form>
                </Width>
            )
        }
    }
}

const mapStateToProps = state => ({
    isWMC: state.common.wmc.get("isWMC"),
    faqs: state.enterprise.shop.shopItems.get("faq"),
    profile: state.enterprise.shop.expertProfile.get("expertProfile"),
});

const mapDispatchToProps = dispatch => ({
    setShopItemFAQ: (item) => dispatch(setShopItemFAQ(item)),
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OneFAQ));
