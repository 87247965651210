import React from 'react';
import styled from "styled-components";
import ContentEditable from 'react-contenteditable'
import {colors} from '../../../common/components/colors';

import {FontBody16} from "../../../common/components/fonts";

const HTML = styled(FontBody16)`
    margin-bottom: 10px;
    .content-editable:focus {
        outline: 2px solid ${colors.border100};
    }
    [contenteditable=true]:empty:before {
        content: attr(placeholder);
        color: #aaa;
    }
    cursor: text;
`;

class ElementHTMLEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            "text": "",
        }
    }

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.elementId !== this.props.elementId) {
            return this.init();
        }
    }

    init = async () => {
        this.setState({
            "text": this.props.text
        })
    }


    onChange = async e => {
        this.setState({
            "text": e.target.value
        })
    }

    save = async () => {
        let isValid = true;
        this.props.onSaveElement(this.props.elementId, {"text": encodeURI(this.state.text)}, isValid)
    }

    render() {
        return (
            <HTML style={this.props.style}>
                <ContentEditable
                    html={this.state.text}
                    disabled={this.props.disabled}
                    onChange={(e) => {return this.onChange(e)}}
                    className="content-editable"
                    onBlur={(e) => {return this.save()}}
                    placeholder={"Enter html here"}
                />
            </HTML>
        )
    }
}

export default ElementHTMLEdit;