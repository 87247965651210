import { Map } from 'immutable';

/* Immutable */

const IFRAME_SIGNUP_EXPERT_ERROR = 'IFRAME_SIGNUP_EXPERT_ERROR';
const IFRAME_SIGNUP_EXPERT_CATEGORIES_DOWNLOADING = 'IFRAME_SIGNUP_EXPERT_CATEGORIES_DOWNLOADING';
const IFRAME_SIGNUP_EXPERT_CREATING = 'IFRAME_SIGNUP_EXPERT_CREATING';
const IFRAME_SIGNUP_EXPERT_STORE_RESET = 'IFRAME_SIGNUP_EXPERT_STORE_RESET';

/* Actions */

export const setSignupExpertCreating = (status) => ({'type': IFRAME_SIGNUP_EXPERT_CREATING, 'status': status});
export const setSignupExpertCategoriesDownloading = (status) => ({'type': IFRAME_SIGNUP_EXPERT_CATEGORIES_DOWNLOADING, 'status': status});
export const setSignupExpertError = (error) => ({'type': IFRAME_SIGNUP_EXPERT_ERROR, 'error': error});
export const setSignupExpertStoreReset = () => ({'type': IFRAME_SIGNUP_EXPERT_STORE_RESET});

/* Initial State */

const initialState = Map({
    signupExpertCategoriesDownloading: false,
    signupExpertCreating: false,
    signupExpertError: "",
});

/* Reducer */

const iframeSignupExpertReducer = (state=initialState, action) => {
    switch (action.type) {
        case IFRAME_SIGNUP_EXPERT_CREATING:
            return state.merge({'signupExpertCreating': action.status});
        case IFRAME_SIGNUP_EXPERT_ERROR:
            return state.merge({'signupExpertError': action.error});
        case IFRAME_SIGNUP_EXPERT_CATEGORIES_DOWNLOADING:
            return state.merge({'signupExpertCategoriesDownloading': action.error});
        case IFRAME_SIGNUP_EXPERT_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default iframeSignupExpertReducer;
