import { Map, List, fromJS } from 'immutable';

/* Immutable */

const SETTINGS_USERS = 'SETTINGS_USERS';
const SETTINGS_USERS_ERROR = 'SETTINGS_USERS_ERROR';
const SETTINGS_USERS_DOWNLOADING = 'SETTINGS_USERS_DOWNLOADING';
const SETTINGS_USERS_UPDATING = 'SETTINGS_USERS_UPDATING';
const SETTINGS_USERS_CREATING = 'SETTINGS_USERS_CREATING';
const SETTINGS_USERS_SELECT = 'SETTINGS_USERS_SELECT';
const SETTINGS_USERS_STORE_RESET = 'SETTINGS_USERS_STORE_RESET';

/* Actions */

export const setSettingsUsers = (user) => ({'type': SETTINGS_USERS, 'user': user});
export const setSettingsUsersDownloading = (status) => ({'type': SETTINGS_USERS_DOWNLOADING, 'status': status});
export const setSettingsUsersUpdating = (status) => ({'type': SETTINGS_USERS_UPDATING, 'status': status});
export const setSettingsUsersCreating = (status) => ({'type': SETTINGS_USERS_CREATING, 'status': status});
export const setSettingsUsersError = (error) => ({'type': SETTINGS_USERS_ERROR, 'error': error});
export const setSettingsUsersSelect = (user) => ({'type': SETTINGS_USERS_SELECT, 'user': user});
export const setSettingsUsersStoreReset = () => ({'type': SETTINGS_USERS_STORE_RESET});

/* Initial State */

const initialState = Map({
    settingsUsers: List(),
    settingsUsersDownloading: false,
    settingsUsersUpdating: false,
    settingsUsersCreating: false,
    settingsUsersError: "",
    settingsUserSelected: Map()
});

/* Reducer */

const settingsUsersReducer = (state=initialState, action) => {
    switch (action.type) {
        case SETTINGS_USERS:
            return state.merge({'settingsUsers': fromJS(action.user)});
        case SETTINGS_USERS_SELECT:
            return state.merge({'settingsUserSelected': fromJS(action.user)});
        case SETTINGS_USERS_DOWNLOADING:
            return state.merge({'settingsUsersDownloading': action.status});
        case SETTINGS_USERS_UPDATING:
            return state.merge({'settingsUsersUpdating': action.status});
        case SETTINGS_USERS_CREATING:
            return state.merge({'settingsUsersCreating': action.status});
        case SETTINGS_USERS_ERROR:
            return state.merge({'settingsUsersError': action.error});
        case SETTINGS_USERS_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default settingsUsersReducer;
