import React from 'react';
import {connect} from "react-redux";
import styled from "styled-components";

/* Components */
import {TextInput} from "../../../../common/components/inputs";
import {FontBody18, FontBody21, FontSubtitle28, FontTitle18} from "../../../../common/components/fonts";
import {ButtonInactive, ButtonPrimary, ButtonTertiary} from "../../../../common/components/buttons";
import {colors} from "../../../../common/components/colors";
import {LogoLoading} from "../../../../common/components/loading";

/* Middleware */
import {tryGetAdvisorCFPNumber, tryGetAdvisorIWINumber,tryGetAdvisorCDFANumber, tryUpdateAdvisorCFPNumber, tryUpdateAdvisorIWINumber, tryUpdateAdvisorCDFANumber} from '../middleware/settings';

const CategoryTitleAndButton = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center; 
`;
const Category = styled.div`
    margin: 60px 0 0 0;
    padding: 0 60px;
    @media screen and (max-width: 748px) {
    padding: 0;
    }
`;
const CategoryResponse = styled(FontBody21)`
    margin: 10px 0 0 0;
`;
const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;
const Form = styled.form`
    padding: 30px 60px 50px 60px;
  @media screen and (max-width: 748px) {
    padding: 30px 0 50px 0;
  }
`;
const SaveStatus = styled.div`
    display: flex;
    flex-direction: row;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
`;
const ErrorText = styled(FontTitle18)`
    margin-top: 40px;
    text-align: left;
    flex-grow: 1;
    color: ${colors.alert100};
`;

const inputsContainersStyles = {
    "margin": "0px 0px 30px 0px"
};
const inputsStyles = {
    "border": "none",
    "borderRadius": "40px",
    "lineHeight": "36px",
    "backgroundColor": colors.white,
    "boxShadow": "0 3px 10px 0 rgba(0, 0, 0, 0.15)",
    "fontSize": "24px"
};

const SubmitInputWithValidation = ({ isChanged, isValid, isSaving, label }) => {
    if(isChanged && isValid) {
        if(isSaving) {
            return (<ButtonInactive canSubmit={true} label={"Saving"} />)
        } else {
            return (<ButtonPrimary canSubmit={true} label={label}/>)
        }
    } else {
        return(<ButtonInactive canSubmit={true} label={label}/>)
    }
};

class FirmSettingsProfileEditDesignations extends React.Component {
    static defaultProps = {
        showSkip: false,
        onSkip: () => {},
        onSave: () => {}
    }

    state = {
        cfp: '', cfpValid: true,
        iwi: '', iwiValid: true,
        cdfa: '', cdfaValid: true,
        isSaving: false,
        isSaved: false,
        error: '',
        isChanged: false,
        isValid: true,
        isLoading: true,
        smsNotificationConsent: false
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const cfp = await this.props.tryGetAdvisorCFPNumber();
        const iwi = await this.props.tryGetAdvisorIWINumber();
        const cdfa = await this.props.tryGetAdvisorCDFANumber();
        this.setState({
            "cfp": (cfp.cfpNumber === "NUMBERISBLANK" || cfp.cfpNumber === null) ? "" : cfp.cfpNumber,
            "iwi": (iwi.iwiNumber === "NUMBERISBLANK" || iwi.iwiNumber === null) ? "" : iwi.iwiNumber,
            "cdfa": (cdfa.cdfaIdNumber === "NUMBERISBLANK" || cdfa.cdfaIdNumber === null) ? "" : cdfa.cdfaIdNumber,
            "isLoading": false
        })
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    handleChangeCFP = async e => {
        await this.setState({'cfp': e.target.value, 'isChanged': true});
        this.checkValid();
    };

    handleChangeIWI = async e => {
        await this.setState({'iwi': e.target.value, 'isChanged': true});
        this.checkValid();
    };

    handleChangeCDFA = async e => {
        await this.setState({'cdfa': e.target.value, 'isChanged': true});
        this.checkValid();
    };

    checkValid = () => {
        // if(this.state.cfp !== "" && this.state.cfp !== null && this.state.cfp !== undefined && this.state.iwi !== "" && this.state.iwi !== null && this.state.iwi !== undefined) {
            this.setState({'isValid': true})
        // } else {
        //     this.setState({'isValid': false})
        // }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            this.setState({'isSaving': true});
            const reqDataCFP = {
                id: this.props.userId,
                cfpNumber: (this.state.cfp === "" || this.state.cfp === null || this.state.cfp === undefined) ? "NUMBERISBLANK" : this.state.cfp
            }
            await this.props.tryUpdateAdvisorCFPNumber(reqDataCFP);
            const reqDataIWI = {
                id: this.props.userId,
                iwiNumber: (this.state.iwi === "" || this.state.iwi === null || this.state.iwi === undefined) ? "NUMBERISBLANK" : this.state.iwi
            }
            await this.props.tryUpdateAdvisorIWINumber(reqDataIWI);
            const reqDataCDFA = {
                id: this.props.userId,
                cdfaIdNumber: (this.state.cdfa === "" || this.state.cdfa === null || this.state.cdfa === undefined) ? "NUMBERISBLANK" : this.state.cdfa
            }
            await this.props.tryUpdateAdvisorCDFANumber(reqDataCDFA);
            this.props.onSave()
            // this.timeout = setTimeout(() => {this.setState({'error': null, 'isSaved': false});}, 3000);
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <Category>
                        <CategoryTitleAndButton>
                            <FontSubtitle28 spaced={true}>CE CREDITS</FontSubtitle28>
                        </CategoryTitleAndButton>
                        <CategoryResponse>
                            <FontBody18>Enter your designations to earn CE credits</FontBody18>
                        </CategoryResponse>
                    </Category>
                    <Form method={"post"} onSubmit={this.handleSubmit}>
                        <TextInput
                            title={"CFP® Number"}
                            valid={this.state.cfpValid}
                            warning={"Enter a valid number"}
                            id={"cfp"}
                            onChange={this.handleChangeCFP}
                            placeholder={"#"}
                            value={this.state.cfp}
                            containerStyles={inputsContainersStyles}
                            inputStyles={inputsStyles}
                        />
                        <TextInput
                            title={"IWI Number (CIMA®, CIMC®, RMA®)"}
                            valid={this.state.iwiValid}
                            warning={"Enter a valid number"}
                            id={"iwi"}
                            onChange={this.handleChangeIWI}
                            placeholder={"#"}
                            value={this.state.iwi}
                            containerStyles={inputsContainersStyles}
                            inputStyles={inputsStyles}
                        />
                        <TextInput
                            title={"CDFA Number"}
                            valid={this.state.cdfaValid}
                            warning={"Enter a valid number"}
                            id={"cdfa"}
                            onChange={this.handleChangeCDFA}
                            placeholder={"#"}
                            value={this.state.cdfa}
                            containerStyles={inputsContainersStyles}
                            inputStyles={inputsStyles}
                        />
                        {this.state.isSaved &&
                            <ErrorText>{this.state.error}</ErrorText>
                        }
                        <SaveStatus>
                            {this.props.showSkip && <div onClick={this.props.onSkip}><ButtonTertiary canSubmit={false} label={"Skip"}/></div>}
                            <SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} />
                        </SaveStatus>
                    </Form>
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    userId: state.common.user.get("userId"),
})

const mapDispatchToProps = dispatch => ({
    tryUpdateAdvisorCFPNumber: (data) => dispatch(tryUpdateAdvisorCFPNumber(data)),
    tryUpdateAdvisorIWINumber: (data) => dispatch(tryUpdateAdvisorIWINumber(data)),
    tryUpdateAdvisorCDFANumber: (data) => dispatch(tryUpdateAdvisorCDFANumber(data)),
    tryGetAdvisorCFPNumber: () => dispatch(tryGetAdvisorCFPNumber()),
    tryGetAdvisorIWINumber: () => dispatch(tryGetAdvisorIWINumber()),
    tryGetAdvisorCDFANumber: () => dispatch(tryGetAdvisorCDFANumber()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FirmSettingsProfileEditDesignations);
