import { Map, List, fromJS } from 'immutable';

/* Immutable */

const TOOLTIP_UPDATE = 'TOOLTIP_UPDATE';


/* Actions */

export const setToolTipUpdate = (showToolTip, toolTipPage) => ({'type': TOOLTIP_UPDATE, 'showToolTip': showToolTip, 'toolTipPage': toolTipPage});
/* Initial State */

const initialState = Map({
    showToolTip: false, //true,
    toolTipPage: 1,
});

/* Reducer */

const toolTipReducer = (state=initialState, action) => {
    switch (action.type) {
        case TOOLTIP_UPDATE:
            return state.merge({'showToolTip': action.showToolTip, 'toolTipPage':action.toolTipPage});

        default:
            return state;
    }
};

export default toolTipReducer;
