import { combineReducers } from 'redux';

/* Reducers */
// import journeySessionsReducer from '../../deprecated/consumer/store/journeysessions';
import teamReducer from './team/reducers';
import billingsReducer from "./billing/reducers";
// import signUpReducer from "../deprecated/consumer2/signup/reducers";
import feedbackReducer from "./feedback/reducers";
import helpReducer from "./help/reducers";
import settingsReducers from "./settings/reducers";
import progressReducer from './progress/reducers';
import careReducers from './getcare/reducers';
import learnReducers from './learn/reducers';
import programReducer from './programs/reducers';
import appointmentsReducer from './appointments/reducers';
import checkoutReducers from './checkout/reducers';
import forumReducer from './forum/reducers';

/* Common */
// import journeyTasksReducer from "../../deprecated/common/store/tasks";
// import appointmentReducer from '../../deprecated/common/store/appointment';
// import toDoReducer from "../../deprecated/common/store/todos";
// import expertProfileReducer from '../../deprecated/common/store/expertprofile';

const rootReducer = combineReducers({
    // journeySessions: journeySessionsReducer,
    // appointment: appointmentReducer("consumerAppointments"),
    // journeyTasks: journeyTasksReducer("consumerTasks"),
    // toDos: toDoReducer("consumerToDos"),
    // expertProfile: expertProfileReducer("consumerExpertProfile"),
    // mwpExpertProfile: expertProfileReducer("mwpExpertProfile"),
    team: teamReducer,
    billing: billingsReducer,
    // signUp: signUpReducer,
    feedback: feedbackReducer,
    help: helpReducer,
    settings: settingsReducers,
    care: careReducers,
    learn: learnReducers,
    progress: progressReducer,
    programs: programReducer,
    appointments: appointmentsReducer,
    checkout: checkoutReducers,
    forum: forumReducer,
});

export default rootReducer;
