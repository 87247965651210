import styled from 'styled-components';
import {colors} from '../../../common/components/colors';

export const StyledBoard = styled.div`
    ${props => props.bottom !== true} {
        padding-bottom: 30px;
    }
    margin: 0 auto;
    .react-trello-board {
        background-color: transparent;
        padding: 0px;
        height: auto;
        overflow: visible;
    }
    .react-trello-lane {
        padding: 0px;
        border-radius: 0px;
        background-color: transparent;
        margin: 0px -1px 0px 0px;
    }
    .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
        overflow: visible;
    }
    .smooth-dnd-container.vertical {
        cursor: default !important;
    }
    .react-trello-card {
        ${props => props.bottom !== true} {
            border: 1px solid ${colors.background30};
            cursor: grab !important;
        }
        ${props => props.bottom !== false} {
            border: none;
            cursor: pointer !important;
        }
        margin: 4px;
        background-color: ${colors.white};
        font-family: GraphicWebRegular;
        font-size: 16px;
        padding: 2px;
        border-radius: 0;
    }
    .react-trello-card header {
        padding: 0px;
        border-bottom: none;
        color: ${colors.backgroundColor5};
        margin-bottom: 0px;
    }
`;

export const Element = styled.div`
    display: block;
    justify-content: space-between;
    color: ${colors.backgroundColor5};
    margin: 10px;
    cursor: pointer;
`;