import {axiosReq} from "../../../../common/utils/axios";

/* Store */
import {
    setListingsAllFetching,
    setListingsAll,
    setListingsOneFetching,
    setListingsOne,
    setListingOneDeleting,
    setListingOneDeleted,
    setListingAllReordering,
    setListingAllReordered,
    setListingOneUpdating,
    setListingOneUpdated,
    setListingsOneCreating,
    setListingsOneCreated
} from "../store/listings";

/* Static */
import {
    devEnterpriseListings,
    devEnterpriseListing,
    devEnterpriseListingsOrder,
    devEnterpriseListingUpdated,
    devEnterpriseListingCreated
} from '../static/devdata';

/* Middleware */
import {tryGetEventsAll} from "../../../../iframes/scheduler/middleware/events";

export const tryGetListingsAll = () => {
    return async dispatch => {
        dispatch(setListingsAllFetching(true));
        const listings = await axiosReq('apiv1/coach/settings/expert-products', "GET", {}, devEnterpriseListings);
        await dispatch(setListingsAll(listings.financialCoachingPlans));
        return dispatch(setListingsAllFetching(false));
    }
};

export const tryGetListingsOne = (listingId) => {
    return async dispatch => {
        dispatch(setListingsOneFetching(true));
        const listing = await axiosReq('apiv1/coach/settings/expert-products/'+listingId, "GET", {}, devEnterpriseListing);
        await dispatch(setListingsOne(listing));
        return dispatch(setListingsOneFetching(false));
    }
};


export const tryCreateListing = (data) => {
    return async (dispatch, getState) => {
        dispatch(setListingsOneCreating(true));
        const listing = await axiosReq('apiv1/coach/settings/expert-products', "POST", data, devEnterpriseListingCreated(data, getState().enterprise.scheduler.listings.get("listingsAll")));
        await dispatch(setListingsOneCreated(listing));
        dispatch(setListingsOneCreating(false));
        return listing;
    }
};

// export const tryReorderListings = (listingIds) => {
//     return async (dispatch, getState) => {
//         dispatch(setListingAllReordering(true));
//         const listings = await axiosReq('apiv1/coach/settings/financial-coaching-plans/order', "POST", {"ids": listingIds}, devEnterpriseListingsOrder(listingIds, getState().enterprise.scheduler.listings.get("listingsAll")));
//         await dispatch(setListingAllReordered(listings));
//         return dispatch(setListingAllReordering(false));
//     }
// };

export const tryUpdateListingStatus = (listingId, data) => {
    return async (dispatch, getState) => {
        dispatch(setListingOneDeleting(true));
        await axiosReq('apiv1/coach/settings/expert-products/'+listingId+'/change-status', "PUT", data, {});
        await dispatch(setListingOneUpdated(listingId, data));
        dispatch(tryGetEventsAll(getState().enterprise.scheduler.expertProfile.get("expertProfile").get("id")));
        return dispatch(setListingOneDeleting(false));
    }
};

export const tryUpdateListing = (listingId, data) => {
    return async dispatch => {
        dispatch(setListingOneUpdating(true));
        await axiosReq('apiv1/coach/settings/expert-products/'+listingId, "PUT", data, devEnterpriseListingUpdated(data));
        await dispatch(setListingOneUpdated(listingId, data));
        return dispatch(setListingOneUpdating(false));
    }
};