import React from 'react';
import moment from "moment";

/* Components */
import {Canceled, One, Title, OneContainer, FillContainer, SpoofContainer, HeroContainer, WhiteContainer, HeroImgBackground, Cost, HeroVidBackground} from "../components/structurepreview";
import {FontHeader14, FontHeader18, FontBody14} from "../../../common/components/fonts";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

class WebinarOnePreview extends React.Component {
    render() {
        let d = this.props.data;
        return(
            <OneContainer>
                <One onClick={() => {window.open("https://"+env.URL.DOMAIN+"/webinars/"+this.props.handle+"/"+d.get("id"),"_self")}}>
                    <HeroContainer>
                        <SpoofContainer />
                        <FillContainer>
                            {/*{(d.get("announcementVideoUrl") !== "" && d.get("announcementVideoUrl") !== null)*/}
                            {/*    ? <HeroVidBackground src={d.get("announcementVideoUrl")} />*/}
                            {/*    : <HeroImgBackground imgObj={d.get("coverPhoto")} />*/}
                            {/*}*/}
                            <HeroImgBackground imgObj={d.get("coverPhoto")} />
                        </FillContainer>
                    </HeroContainer>
                    <WhiteContainer>
                        <FontHeader18><Title>{d.get("title")}</Title></FontHeader18>
                        <Cost isCanceled={(this.props.isCanceled || this.props.isPast)}><FontHeader14>{moment(d.get("start")).format("LLLL")}</FontHeader14></Cost>
                        {this.props.isCanceled && <Canceled><FontBody14>Canceled</FontBody14></Canceled>}
                    </WhiteContainer>
                </One>
            </OneContainer>
        )
    }
}

export default WebinarOnePreview;