import { Map, List, fromJS } from 'immutable';

/* Immutable */

const RECURRING_AVAILABILITY = 'RECURRING_AVAILABILITY';
const RECURRING_AVAILABILITY_READ = 'RECURRING_AVAILABILITY_READ';
const RECURRING_AVAILABILITY_UPDATE = 'RECURRING_AVAILABILITY_UPDATE';
const RECURRING_AVAILABILITY_SCHEDULE = 'RECURRING_AVAILABILITY_SCHEDULE';
const RECURRING_AVAILABILITY_SCHEDULE_READ = 'RECURRING_AVAILABILITY_SCHEDULE_READ';
const RECURRING_AVAILABILITY_SCHEDULE_UPDATE = 'RECURRING_AVAILABILITY_SCHEDULE_UPDATE';
const RECURRING_AVAILABILITY_SYNCING = 'RECURRING_AVAILABILITY_SYNCING';
const RECURRING_AVAILABILITY_SYNC = 'RECURRING_AVAILABILITY_SYNC';
const RECURRING_AVAILABILITY_STORE_RESET = 'RECURRING_AVAILABILITY_STORE_RESET';

/* Actions */

export const setRecurringAvailability = (syncMethod) => ({'type': RECURRING_AVAILABILITY, 'syncMethod': syncMethod});
export const setRecurringAvailabilityReading = (status) => ({'type': RECURRING_AVAILABILITY_READ, 'status': status});
export const setRecurringAvailabilityUpdating = (status) => ({'type': RECURRING_AVAILABILITY_UPDATE, 'status': status});
export const setRecurringAvailabilitySchedule = (schedule) => ({'type': RECURRING_AVAILABILITY_SCHEDULE, 'schedule': schedule});
export const setRecurringAvailabilityScheduleReading = (status) => ({'type': RECURRING_AVAILABILITY_SCHEDULE_READ, 'status': status});
export const setRecurringAvailabilityScheduleUpdating = (status) => ({'type': RECURRING_AVAILABILITY_SCHEDULE_UPDATE, 'status': status});
export const setRecurringAvailabilitySyncing = (status) => ({'type': RECURRING_AVAILABILITY_SYNCING, 'status': status});
export const setRecurringAvailabilitySync = (lastSync) => ({'type': RECURRING_AVAILABILITY_SYNC, 'lastSync': lastSync});
export const setRecurringAvailabilityStoreReset = () => ({'type': RECURRING_AVAILABILITY_STORE_RESET});

/* Initial State */

const initialState = Map({
    recurringAvailability: Map({availability_calendar_update_method: "willow", error: ''}),
    recurringAvailabilityReading: false,
    recurringAvailabilityUpdating: false,
    recurringAvailabilitySchedule: Map({workSchedule: List(), error: ''}),
    recurringAvailabilityScheduleReading: false,
    recurringAvailabilityScheduleUpdating: false,
    recurringAvailabilitySync: null,
    recurringAvailabilitySyncing: false,
});

/* Reducer */

const recurringAvailabilityReducer = (state=initialState, action) => {
    switch (action.type) {
        case RECURRING_AVAILABILITY:
            return state.merge({'recurringAvailability': fromJS(action.syncMethod)});
        case RECURRING_AVAILABILITY_READ:
            return state.merge({'recurringAvailabilityReading': action.status});
        case RECURRING_AVAILABILITY_UPDATE:
            return state.merge({'recurringAvailabilityUpdating': action.status});
        case RECURRING_AVAILABILITY_SCHEDULE:
            return state.merge({'recurringAvailabilitySchedule': Map({"workSchedule": fromJS(action.schedule.workSchedule), "error": action.schedule.error})});
        case RECURRING_AVAILABILITY_SCHEDULE_READ:
            return state.merge({'recurringAvailabilityScheduleReading': action.status});
        case RECURRING_AVAILABILITY_SCHEDULE_UPDATE:
            return state.merge({'recurringAvailabilityScheduleUpdating': action.status});
        case RECURRING_AVAILABILITY_SYNCING:
            return state.merge({'recurringAvailabilitySyncing': action.status});
        case RECURRING_AVAILABILITY_SYNC:
            return state.merge({'recurringAvailabilitySync': action.lastSync});
        case RECURRING_AVAILABILITY_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default recurringAvailabilityReducer;
