import moment from "moment";

export const devCoachRatingClientData = {
    "token": 'abc',
    "clientId": 1,
    "clientName": "Patty Client",
    "datetime": moment(),
    "ratedOn": null,
    "coachPhotoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/yb3esjcmdyfstf4udqpv.jpg",
    "explanation": "",
    "description": "advisorCertVetting",
    "conferenceId": 2769
};

export const devCoachRatingCoachData = {
    "token": 'abc',
    "coachId": 1,
    "coachName": "Gabby Coach",
    "datetime": moment(),
    "ratedOn": null,
    "coachPhotoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/yb3esjcmdyfstf4udqpv.jpg",
    "explanation": "",
    "description": "advisorCertVetting",
    "conferenceId": 2769
};
