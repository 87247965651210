import React from 'react';
import styled from 'styled-components';

import {colors} from '../../../common/components/colors';
import {images} from "../../../common/components/images";

export const PageFull = styled.div`
    height: 100%;
    margin-left: 320px;
    width: calc(100% - 320px);
    min-height: 100vh;
    ${props => props.showBackground !== true} {
        background-image: url(${images.backgroundBlur});
        background-size: cover;
        background-repeat: no-repeat;
    }
    @media screen and (max-width: 1088px) {
        margin-left: 0;
        width: 100%;
        position: relative;
    }
`;

export const PageFullNoNav = styled.div`
    min-height: 100vh;
    width: 100%;
    @media screen and (max-width: 1088px) {
        position: relative;
        min-height: 100vh;
    }
`;

export const Background = styled.div`
    position: fixed;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: ${colors.background100};
`;

export const PageContent = styled.div`
    width: 100%;
    @media screen and (max-width: 1088px) {
        padding-bottom: 80px;
    }
`;

export const PageContentContainer = styled.div`
    max-width: 450px;
    width: 694px;
    margin: 0px auto 120px auto;
    padding: 60px 20px 0px 20px;
    @media screen and (max-width: 748px) {
        padding: 20px 20px 0px 20px;
        width: calc(100% - 40px);
    }
`;
export const PageContentContainer2 = styled.div`
    width: calc(100% - 120px);
    padding: 90px 60px 120px 60px;
    @media screen and (max-width: 748px) {
        width: calc(100% - 60px);
        padding: 30px 30px 120px 30px;
    }
    @media screen and (max-width: 450px) {
        width: calc(100% - 30px);
        padding: 30px 15px 120px 15px;
    }
`;
export const PageContentContainer3 = styled.div`
    height: 100%;
    @media screen and (max-width: 748px) {
        width: calc(100% - 60px);
        padding: 30px 30px 120px 30px;
    }
    @media screen and (max-width: 450px) {
        width: calc(100% - 30px);
        padding: 30px 15px 120px 15px;
    }
`;

export const PageContentContainer4 = styled.div`
    width: 100%;
    background-image: url(${images.backgroundBlur});
    background-size: cover;
    background-repeat: no-repeat;
    min-height:100vh;
`;

export const PageContentContainerScheduler = styled.div`
    width: calc(100% - 120px);
    padding: 60px;
    @media screen and (max-width: 1088px) {
        padding: 20px 20px 0px 20px;
        width: calc(100% - 40px);
        margin-bottom: 20px;
    }
    @media screen and (max-width: 748px) {
        padding: 30px 20px 0px 20px;
        width: calc(100% - 40px);
    }
    @media screen and (max-width: 460px) {
        padding: 20px 20px 0px 20px;
        width: calc(100% - 40px);
        margin-bottom: 20px;
    }
`;

export const PageFullShowHide = styled.div`
    ${props => props.show !== true} {
      height: 100%;
      margin-left: 320px;
      width: calc(100% - 320px);
      min-height: 100vh;
      ${props => props.showBackground !== true} {
        background-image: url(${images.backgroundBlur});
        background-size: cover;
        background-repeat: no-repeat;
      }
      @media screen and (max-width: 1088px) {
        margin-left: 0;
        width: 100%;
        position: relative;
      }  
    }
`;
export const PageContentContainer2ShowHide = styled.div`
    ${props => props.show !== true} {
        width: calc(100% - 120px);
        padding: 90px 60px 120px 60px;
        @media screen and (max-width: 748px) {
            width: calc(100% - 60px);
            padding: 30px 30px 120px 30px;
        }
        @media screen and (max-width: 450px) {
            width: calc(100% - 30px);
            padding: 30px 15px 120px 15px;
        }
    }
`;