import React from 'react';
import styled from 'styled-components';
import {connect} from "react-redux";

/* Components */
import {FontTitle40, FontBody24} from "../../../../common/components/fonts";
import {colors} from "../../../../common/components/colors";
import {images} from "../../../../common/components/images";
import {LogoLoading} from "../../../../common/components/loading";
import FirmModal from "../../../../common/components/modalv2";

/* Containers */
import FirmSettingsProfileEditPreview from './profilepreviewedit';

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;
const Photo = styled.img`
    width: 130px;
    height: 130px;
    border-radius: 130px;
    border: 1px solid ${colors.border100};
    object-fit: cover;
`;
const Name = styled(FontTitle40)`

`;
const User = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    @media screen and (max-width: 748px) {
      padding: 0;
    }
`;
const UserRecord = styled.div`
    display: flex;
    flex-direction: row;
    row-gap: 10px;
    column-gap: 80px;
    align-items: center;
    flex-wrap: wrap;
`;
const EditButton = styled.img`
  cursor: pointer;
  width: fit-content;
`;
const FirmModalResponsive = styled.div`
    & > div {
        max-width: 600px;
        @media screen and (max-width: 1440px) {
            left: 62%;
        }
        @media screen and (max-width: 1088px) {
            left: 50%;
        }
        @media screen and (max-width: 748px) {
            left: 0;
            max-width: calc(100% - 100px);
        }
    }
`;

class FirmSettingsUserPreview extends React.Component {
    static defaultProps = {
        "onSave": () => {},
        "lastUpdated": null,
        "user": {
            "first": "",
            "last": "",
            "photo": null,
            "companyName": ""
        }
    }

    state = {
        "showModal": false,
        "user": {
            "first": "",
            "last": "",
            "photo": null,
            "companyName": ""
        },
        "isLoading": true
    }

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "user": this.props.user.toJS(),
            "isLoading": false
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.lastUpdated !== this.props.lastUpdated && this.props.lastUpdated !== null) {
            this.init();
        }
        if(prevProps.user !== this.props.user) {
            this.init();
        }
    }

    openChangeNamePhoto = async () => {this.setState({"showModal": true})}
    closeChangeNamePhoto = async () => {this.setState({"showModal": false})}

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <User>
                        <UserRecord>
                            {this.state.user.photo !== null && <Photo src={this.state.user.photo} alt={""}/>}
                            <div>
                                <Name>{this.state.user.first} {this.state.user.last.replaceAll(this.state.user.businessTitle, '').replaceAll(',','')}</Name>
                                <FontBody24>{this.state.user.companyName}</FontBody24>
                            </div>
                        </UserRecord>
                        <EditButton onClick={this.openChangeNamePhoto} src={images.firmEditIconOnlyBlack} alt={"edit"}/>
                    </User>
                    {this.state.showModal &&
                        <FirmModalResponsive>
                            <FirmModal
                                message={<FirmSettingsProfileEditPreview onClose={this.closeChangeNamePhoto} onSave={this.props.onSave} lastUpdated={this.props.lastUpdated} />}
                                cancelShow={false}
                                confirmShow={false}
                                closeShow={true}
                                closeAction={this.closeChangeNamePhoto}
                                modalStyle={{"backgroundColor": colors.backgroundColor4}}
                            />
                        </FirmModalResponsive>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.common.user
})

export default connect(mapStateToProps, null)(FirmSettingsUserPreview);
