import React from 'react';
import styled from 'styled-components';
import {colors} from '../../../common/components/colors';
import {ButtonInactive, ButtonPrimary} from "../../../common/components/buttons";

export const Block = styled.div`
    margin-top: 30px;
`;
export const Width = styled.div`
    max-width: 800px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 1088px) {
        max-width: 500px;
    }
    @media screen and (max-width: 1020px) {
        max-width: 100%;
    }
`;
export const CardOrNot = styled.div`
    vertical-align: top;
    width: calc(100% - 60px);
    padding: 30px;
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    margin-top: 0px;
    @media screen and (max-width: 1610px) {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-top: 1px solid ${colors.primary30};    
        padding: 30px 0px 30px 0px;
        width: 100%;
    }
    @media screen and (max-width: 1088px) {
        border-top: none;    
        margin-top: 0px;
        padding: 30px;
        -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
        width: calc(100% - 60px);
    }
    @media screen and (max-width: 1020px) {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-top: 1px solid ${colors.primary30};    
        padding: 30px 0px 30px 0px;
        margin-top: 30px;
        width: 100%;
    }
`;
export const WidthRight = styled.div`
    width: calc(100% - 800px);
    display: inline-block;
    vertical-align: top;
    position: relative;
    min-height: 550px;
    @media screen and (max-width: 1610px) {
        display: block;
        width: 100%;
        min-height: auto;
    }
    @media screen and (max-width: 1088px) {
        display: inline-block;
        width: calc(100% - 500px);
        min-height: 550px;
    }
    @media screen and (max-width: 1020px) {
        display: block;
        width: 100%;
        min-height: auto;
    }
`;

export const Right = styled.div`
    width: 100%;
    max-width: 360px;
    margin: 0px;
    position: absolute;
    left: 120px;
    @media screen and (max-width: 1610px) {
        max-width: 740px;
        margin: 0px;
        right: 0px;
        left: 0px;
        position: relative;
    }
    @media screen and (max-width: 1088px) {
        max-width: 360px;
        margin: 0px;
        position: absolute;
        right: 0px;
        left: 60px;
    }
    @media screen and (max-width: 1020px) {
        max-width: 100%;
        margin: 0px;
        right: 0px;
        left: 0px;
        position: relative;
    }
`;
export const SaveSection = styled.div`
    text-align: right;
    padding-top: 20px;
`;
export const InlineBlock = styled.div`
    display: inline-flex;
`;
export const ButtonRight = styled.div`
    margin-right: 10px;
`;
export const SubmitInputWithValidation = ({ isChanged, isValid, isSaving, label }) => {
    if(isChanged && isValid) {
        if(isSaving) {
            return (<ButtonInactive canSubmit={true} label={"Sending"} />)
        } else {
            return (<ButtonPrimary canSubmit={true} label={label}/>)
        }
    } else {
        return(<ButtonInactive canSubmit={true} label={label}/>)
    }
};

export const FitContent = styled.div`
    width: fit-content;
`;
export const Gray = styled.div`
    margin-top: 34px;
    color: ${colors.secondary100};
`;
export const Text = styled.div`
    & p {
        font-size: 18px;
    }
`;

export const Red = styled.div`
    color: ${colors.alert100};
`;