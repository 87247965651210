import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

/* Components */
import {FontBody16, FontTitle30, FontTitle18} from "../../../common/components/fonts";
import {ButtonPrimary} from "../../../common/components/buttons";
import {colors} from "../../../common/components/colors";

/* Static */
import {images} from "../../../common/components/images";

/* Middleware */
import {tryGetVerifyPhone, tryPostResendVerifySMS} from '../../scheduler/middleware/auth';
import {ArrowLeftOutlined} from "@ant-design/icons";

const Gray = styled.div`
    color: ${colors.secondary70};
`;

const GrayDark = styled.div`
    color: ${colors.secondary100};
    @media screen and (max-width: 460px) {
        margin-top: 20px;
        margin-bottom: 10px;
    }
`;

const Green = styled.div`
    color: ${colors.progress100};
`;

const Top = styled.div`
    margin-top: 7px;
    margin-bottom: 7px;
`;

export const Center = styled.div`
    margin-top: 30px;
    text-align: center;
    padding: 0px 10px;
`;

export const SummaryIcon = styled.img`
    width: 150px;
    margin: 0px auto;
    height: 150px;
`;

export const SummaryTitle = styled.div`
    padding: 20px 0px 20px 0px;
    color: ${colors.primary100};
`;

const FitContent = styled.div`
    width: fit-content;
    margin: 0px auto;
    cursor: pointer;
`;

class Verify extends React.Component {
    state={"iframeAuthPhoneVerified": "not_verified", "isResent": false, "isSending": false, "sendLink": false};

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
        clearTimeout(this.timeout);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.iframeAuthPhoneVerified !== this.props.iframeAuthPhoneVerified) {
            this.setState({"iframeAuthPhoneVerified": this.props.iframeAuthPhoneVerified});
        }
    }

    init = async () => {
        await this.props.verifyCreateNewUser();
    };

    resend = async () => {
        this.setState({"isSending": true});
        await this.props.tryPostResendVerifySMS();
        this.setState({"isResent": true, "isSending": false});
        this.timeout = setTimeout(() => {
            clearTimeout(this.timeout);
            this.setState({'isResent': false})
        }, 3000);
    };

    render() {
        return(
            <Center>
                <SummaryIcon src={images.consumerSignUpIconVerify}/>
                {!this.state.sendLink &&
                <SummaryTitle>
                    <FontTitle30>Registered!</FontTitle30>
                </SummaryTitle>
                }
                {/* <SummaryTitle>
                    <FontTitle30>Last step: check your phone!</FontTitle30>
                    <GrayDark><FontBody16>Click the link in the SMS text we just sent you.</FontBody16></GrayDark>
                </SummaryTitle>
                
                {this.state.isSending &&
                    <Top>
                        <FontTitle18>Sending...</FontTitle18>
                        <br />
                        <br />
                    </Top>
                }
                {this.state.isResent &&
                    <Top>
                        <Green><FontTitle18>Sent!</FontTitle18></Green>
                        <br />
                        <br />
                    </Top>
                }
                {(!this.state.isResent && !this.state.isSending) &&
                    <>
                        <FitContent onClick={this.resend}>
                            <ButtonPrimary submit={false} label={"Resend"} />
                        </FitContent>
                        <br />
                        <br />
                    </>
                }
                <FitContent onClick={this.props.back}>
                    <Gray><FontBody16><ArrowLeftOutlined/>&nbsp;Back</FontBody16></Gray>
                </FitContent>
                <br />
                <br /> */}
            </Center>
        )
    }

}

const mapStateToProps = state => ({
    iframeAuthPhoneVerified: state.iframe.signup.auth.get("iframeAuthPhoneVerified")
});

const mapDispatchToProps = dispatch => ({
    tryGetVerifyPhone: () => dispatch(tryGetVerifyPhone()),
    tryPostResendVerifySMS: () => dispatch(tryPostResendVerifySMS())
});

export default connect(mapStateToProps, mapDispatchToProps)(Verify);