import React from 'react';
import styled from 'styled-components';
import {connect} from "react-redux";
import validator from 'validator';
import {withRouter} from 'react-router-dom';
import {Link} from "react-router-dom";

/* Components */
import {TextInput, RadioBoxWithLabel, InputLabel, NumberInput, TextQuillInputControlCount} from "../../../../common/components/inputs";
import {InlineBlock, SaveSection, SubmitInputWithValidation} from '../components/allservicespage';
import {FontBody16, FontTitle18} from '../../../../common/components/fonts';
import {ButtonTertiary} from "../../../../common/components/buttons";
import {colors} from "../../../../common/components/colors";

/* Middleware */
import {tryCreateListing, tryUpdateListingStatus} from '../middleware/listings';

export const Width = styled.div`
    max-width: 536px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
`;
const InlineCheckbox = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 33%;
    min-width: 260px;
    margin-top: 15px;
    @media screen and (max-width: 640px) {
        width: 100%;
        min-width: 100%;
        display: block;
    }
`;
const TitleMargin = styled.div`
    margin-top: -15px;
`;
const Type2 = styled.div`
    margin-top: 40px;
`;

class CreateEvent extends React.Component {
    state = {
        title: '', titleValid: true,
        description: '', descriptionValid: true, descriptionCount: 0, descriptionComponent: '',
        activeChecked: true, deactiveChecked: false,
        lengthSessions: 15, lengthSessionsValid: true,
        isSaving: false,
        isSaved: false,
        error: '',
        isValid: true,
        isChanged: false
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.updateDescription();
    };

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    updateDescription = () => {
        this.setState({
            descriptionComponent: <TextQuillInputControlCount
                title={"Description*"}
                valid={this.state.descriptionValid}
                warning={"Required"}
                id={"description"}
                onChange={this.handleChangeDescription}
                placeholder={"Add a description"}
                value={this.state.description}
                maxLength={3000}
                count={this.state.descriptionCount}
                minRows={8}
                required={true}
                countColorAbove={colors.white}
                countColorBelow={colors.white}
            />
        })
    };

    handleChangeDescription = async e => {
        await this.setState({
            'description': e,
            'descriptionValid': (!validator.isEmpty(e) && e !== '<p><br></p>' && e !== ''),
            'descriptionCount': (validator.isEmpty(e) || e === '<p><br></p>' || e === '') ? 0 : e.replace( /(<([^>]+)>)/ig, '').length,
            'isChanged': true
        });
        await this.updateDescription();
        this.checkValid();
    };

    handleChangeTitle = async e => {
        await this.setState({
            'title': e.target.value,
            'titleValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''),
            'isChanged': true
        });
        this.checkValid();
    };

    handleChangeLengthSessions = async e => {
        await this.setState({
            'lengthSessions': e.target.value < 1 ? 1 : e.target.value,
            'lengthSessionsValid': e.target.value < 1 ? true : (!validator.isEmpty(e.target.value.toString()) && e.target.value.toString() !== ''),
            'isChanged': true
        });
        this.checkValid();
    };

    handleActivate = async () => {
        await this.setState({"activeChecked": true, "deactiveChecked": false, 'isChanged': true});
        this.checkValid();
    };

    handleDeactivate = async () => {
        await this.setState({"activeChecked": false, "deactiveChecked": true, 'isChanged': true});
        this.checkValid();
    };

    checkValid = () => {
        let titleValid = (!validator.isEmpty(this.state.title) && this.state.title !== '');
        let descriptionValid = (!validator.isEmpty(this.state.description) && this.state.description !== '<p><br></p>' && this.state.description !== '');
        if(descriptionValid && titleValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            this.setState({'isSaving': true});
            const data = {
                "title": this.state.title,
                "description": this.state.description,
                "coverPhotoBase64": null,
                "termsOfUseUrl": "",
                "privacyPolicyUrl": "",
                "downloadUrl": "",
                "sessionLength": parseInt(this.state.lengthSessions),
                "serviceType": "event",
                "productPlans": []
            };
            const newListing = await this.props.tryCreateListing(data);
            const newStatus = {"status": this.state.activeChecked ? "active" : "disabled"};
            await this.props.tryUpdateListingStatus(newListing.id, newStatus);
            this.setState({'isSaving': false, 'isSaved': true});
            this.timeout = setTimeout(() => {
                this.setState({'isSaved': false});
                this.props.history.push("/settings/availability/appointments");
            }, 3000);
        }
    };

    render() {
        if(this.state.isSaved) {
            if(this.state.error === "") {
                return(<FontTitle18>Created!</FontTitle18>)
            } else if(this.state.error !== '') {
                return(<FontTitle18>{this.state.error}</FontTitle18>)
            }
        } else {
            return(
                <Width>
                    <FontTitle18>Create Appointment Type</FontTitle18>
                    <form method={"post"} onSubmit={this.handleSubmit}>
                        <TitleMargin>
                            <TextInput
                                title={"Title*"}
                                valid={this.state.titleValid}
                                warning={"Required"}
                                id={"title"}
                                onChange={this.handleChangeTitle}
                                placeholder={"Add a title"}
                                value={this.state.title}
                            />
                        </TitleMargin>
                        {this.state.descriptionComponent}
                        <NumberInput
                            title={"Appointment Length* (in minutes)"}
                            valid={this.state.lengthSessionsValid}
                            warning={"Required"}
                            id={"lengthSessions"}
                            onChange={this.handleChangeLengthSessions}
                            placeholder={30}
                            value={this.state.lengthSessions}
                        />
                        <Type2><InputLabel><FontBody16>Show on Scheduler Page*</FontBody16></InputLabel></Type2>
                        <div>
                            <InlineCheckbox>
                                <RadioBoxWithLabel
                                    id={6}
                                    action={this.handleActivate}
                                    checked={this.state.activeChecked}
                                    label={"Yes"}
                                    subtext={"Anyone can see this appointment."}
                                    disabled={false}
                                />
                            </InlineCheckbox>
                            <InlineCheckbox>
                                <RadioBoxWithLabel
                                    id={7}
                                    action={this.handleDeactivate}
                                    checked={this.state.deactiveChecked}
                                    label={"No"}
                                    subtext={"Only people with the unique link can see this appointment."}
                                    disabled={false}
                                />
                            </InlineCheckbox>
                        </div>
                        <SaveSection>
                            <InlineBlock><SubmitInputWithValidation label={"Create"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                            <InlineBlock><Link to={"/settings/availability/appointments"}><ButtonTertiary canSubmit={false} label={"Cancel"} /></Link></InlineBlock>
                        </SaveSection>
                    </form>
                </Width>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryCreateListing: (data) => dispatch(tryCreateListing(data)),
    tryUpdateListingStatus: (listingId, data) => dispatch(tryUpdateListingStatus(listingId, data))
});

export default connect(null, mapDispatchToProps)(withRouter(CreateEvent));
