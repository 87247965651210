import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux'
import styled from 'styled-components';


/* Components */
import {LogoLoading} from "../../common/components/loading";


/* Container */
import AdvisorProfileHeader from '../advisorprofile/containers/profileedit';

/* Middleware */
import {tryGetEndorsedArticles} from './middleware/articles';

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;


class IFrameAdvisorProfile extends React.Component {



    render() {
       
        return (
            <div>
                <AdvisorProfileHeader />
            </div>
        )
    }
}



export default withRouter(IFrameAdvisorProfile);
