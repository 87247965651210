import React from "react";
import {connect} from 'react-redux';
import styled from "styled-components";
import {withRouter} from 'react-router-dom';

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {colors} from "../../../common/components/colors";
import BackBtn from "../components/back";

/* Containers */
import ConsumerShop from '../../checkout/containers/shop';

const Container = styled.div`
    width: calc(100% - 120px);
    padding: 90px 60px 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 210px);
    background-color: ${colors.white};
    @media screen and (max-width: 750px) {
        max-width: 650px;
        width: 100%;
        padding: 0;
    }
`;
const AdjustShop = styled.div`
    & > div, & > div > div > div {
        position: relative !important;
        margin: 0 auto;
        width: 100%;
    }
    & > div > div > div > div:nth-child(2) {
        max-width: 578px;
    }
    & > div > div > div > div:nth-child(3) {
        max-width: 620px;
    }
`;

class MembersListAddAppointments extends React.Component {
    state = {
        "isLoading": true,
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.setState({
            "isLoading": false
        });
    };

    onBackToCart = async () => {
        this.props.history.goBack();
    }

    onCheckout = async () => {
        this.props.history.push("/my-team/add-appointments/checkout")
    }

    onCloseCheckoutSuccess = async () => {
        this.props.history.push("/my-team")
    }

    render() {
        if (this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <Container>
                    <BackBtn onBack={() => {this.props.history.goBack()}} />
                    <AdjustShop>
                        <ConsumerShop
                            onBack={() => {}}
                            onClose={() => {}}
                            onCheckout={this.onCheckout}
                            onBackToCart={this.onBackToCart}
                            onCloseCheckoutSuccess={this.onCloseCheckoutSuccess}
                            showCartBackground={false}
                            showCartBackBtn={false}
                        />
                    </AdjustShop>
                </Container>
            )
        }
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MembersListAddAppointments));
