import { axiosReq } from "../../../common/utils/axios";

import { setCommunityPosts, setCommunityPostsFetching, setCommunityForumParentCategoriesFetching, setCommunityForumParentCategories, setCommunityForumSubCategories, setCommunityForumSubCategoriesFetching, setCommunityPostCommentsFetching, setCommunityPostComments, setCommunityPostNewComment, setCommunityPostSelected, setForumSearchResults} from '../store/forum';
import { devConsumerPostsFromCategory, devConsumerCommunityParentCategories, devConsumerPostComments, getDevNewConsumerPostComment } from '../static/devdata';

export const tryGetAllPostsFromCategory = (categoryId) => {
    return async dispatch => {
        dispatch(setCommunityPostsFetching(true));
        const postsAll = await axiosReq('forum/posts/by-category/' + categoryId, "GET", {}, devConsumerPostsFromCategory);
        dispatch(setCommunityPostsFetching(false));
        if (postsAll.hasOwnProperty("error")) {
            await dispatch(setCommunityPosts([]));
            return [];
        } else {
            await dispatch(setCommunityPosts(postsAll));
            return postsAll;
        }
    }
};

export const tryGetForumParentCategories = () => {
    return async dispatch => {
        dispatch(setCommunityForumParentCategoriesFetching(true));
        const categories = await axiosReq('forum/categories', "GET", {}, devConsumerCommunityParentCategories);
        await dispatch(setCommunityForumParentCategories(categories.categories));
        dispatch(setCommunityForumParentCategoriesFetching(false));
        return categories.categories;
    }
};

export const tryGetForumCommentsByPostId = (postId) => {
    return async dispatch => {
        dispatch(setCommunityPostCommentsFetching(true));
        const postDetails = await axiosReq('forum/posts/details/' + postId, "GET", {}, devConsumerPostComments);
        let comments = postDetails.comments
        await dispatch(setCommunityPostComments(comments));
        dispatch(setCommunityPostCommentsFetching(false));
        return comments;
    }
}

export const tryCreateForumPostComment = (postId, comment) => {
    return async dispatch => {
        let devCommentResponse = getDevNewConsumerPostComment(comment)
        const createdComment = await axiosReq('forum/posts/details/' + postId + '/create-comment', "POST", { "comment": comment }, devCommentResponse)
        dispatch(setCommunityPostNewComment(createdComment))
        return createdComment
    }
}

export const tryCreateNewPost = (title, body, postCategoryId, isAnonymous) => {
    return async dispatch => {
        let postData = {
            "title": title,
            "body": body,
            "categoryId": postCategoryId,
            "isAnonymous": isAnonymous
        }
        console.log(postData)
        const newPost = await axiosReq('forum/my-posts', "POST", postData, devConsumerPostsFromCategory[0])
        dispatch(setCommunityPostSelected(newPost))
        return newPost
    }
}

export const trySearchForumPosts = (term) => {
    return async dispatch => {
        const searchResults = await axiosReq(
            'forum/search',
            "POST",
            {"term": term},
            devConsumerPostsFromCategory
                .filter(p => p.title.toLowerCase().includes(term.toLowerCase()))
        )
        dispatch(setForumSearchResults(searchResults))
        return searchResults
    }
}