import {axiosReq} from "../../../common/utils/axios";
import moment from "moment/moment";

/* Store */
import {
    setClientsActivityAddRecord,
    setClientsActivityUpdateRecord,
    setClientsActivityDeleteRecord,
    setTeamActivityNotesUpdateRecord,
    setTeamActivityNotesDeleteRecord,
    setTeamActivityNotes
} from "../../store/clientsactivity";
import {
    setNoteModal,
    setNotePosting,
    setNotesGetting,
    setNoteEditing,
    setNoteDeleting,
    setNoteConfirmModal,
    setNoteSelected,
} from "../store/notes";

/* Static */
import { devEnterpriseTeamNotesData, devEnterpriseClientNotesAddData, /*devEnterpriseClientNotesUpdateData, devEnterpriseClientNotesDeleteData*/ } from '../static/devdata';

export const tryPostEnterpriseNote = (clientId, title, body, withClose, notePrivate, score=null) => {
    return async dispatch => {
        dispatch(setNotePosting(true));
        const noteAdd = await axiosReq(
            'apiv1/coach/clients/'+clientId+'/notes', "POST",
            {
                "title": title,
                "body": body,
                "isPrivate": notePrivate,
                "score": score
            },
            devEnterpriseClientNotesAddData(title, body));
        if(withClose) {
            dispatch(setClientsActivityAddRecord({...noteAdd, "activityType": "userNotes", "orderDatetime": moment()}));
            dispatch(setNoteModal(false));
        }
        dispatch(setNotePosting(false));
        return noteAdd.id;
    }
};

export const tryUpdateEnterpriseNote = (clientId, noteId, title, body, withClose, notePrivate, score=null) => {
    return async (dispatch, getState) => {
        dispatch(setNoteEditing(true));
        const clientNotes = await axiosReq(
            'apiv1/coach/clients/'+clientId+'/notes',
            "PUT",
            {
                "id": noteId,
                "title": title,
                "body": body,
                "isPrivate": notePrivate,
                "score": score
            }, {}); //devEnterpriseClientNotesUpdateData(noteId, title, body, getState()));
        if(clientNotes.hasOwnProperty("error")) {

        }
        if(withClose) {
            dispatch(setClientsActivityUpdateRecord("userNotes", noteId, {"title": title, "body": body}));
            dispatch(setTeamActivityNotesUpdateRecord("userNotes", noteId, {"title": title, "body": body}));
            await dispatch(setNoteSelected({"edit": false, "clientId": null, "noteId": null, "title": null, "body": null, "datetime": null}, false));
            dispatch(setNoteEditing(false));
        }
        dispatch(setNoteEditing(false));
    }
};

export const tryDeleteEnterpriseNote = (clientId, noteId) => {
    return async (dispatch, getState) => {
        dispatch(setNoteDeleting(true));
        const clientNotes = await axiosReq('apiv1/coach/clients/'+clientId+'/notes', "DELETE", {"id": noteId}, {}); //devEnterpriseClientNotesDeleteData(noteId, getState()));
        if(clientNotes.hasOwnProperty("error")) {

        } else {
            dispatch(setClientsActivityDeleteRecord("userNotes", noteId));
            dispatch(setTeamActivityNotesDeleteRecord("userNotes", noteId));
        }
        dispatch(setNoteConfirmModal(false, null));
        return dispatch(setNoteDeleting(false));
    }
};

export const shouldEditNote = (note) => {
    return async (dispatch) => {
        if(note === null) {
            await dispatch(setNoteSelected({"edit": false, "clientId": '', "noteId": '', "title": '', "body": '', "datetime": ''}, false));
            dispatch(setNoteModal(true))
        } else {
            await dispatch(setNoteSelected(note, true));
            dispatch(setNoteModal(true))
        }
    }
};

export const tryGetEnterpriseSharedNotes = (consumerId) => {
    return async (dispatch) => {
        dispatch(setNotesGetting(true));
        const activity = await axiosReq('apiv1/coach/clients/'+consumerId+'/wmc-notes?showPrivate=true', "GET", {}, devEnterpriseTeamNotesData);
        dispatch(setNotesGetting(false));
        if(activity.hasOwnProperty("error")) {
            return dispatch(setTeamActivityNotes([]));
        } else {
            return dispatch(setTeamActivityNotes(activity.userNotes));
        }
    }
};


