import React from 'react';

/* Components */
import ConsumerNav from '../navbars/containers/nav';
import {PageFull, PageContentContainer} from '../navbars/components/navlayout';

/* Containers */
import Feedback from "./containers/feedback";

const FeedbackWrapper = () => (
    <>
        <ConsumerNav />
        <PageFull>
            <PageContentContainer>
                <Feedback
                    showBackButton={false}
                    back={() => {}}
                    inputTitle={"HAVE A SUGGESTION?"}
                    inputPlaceholder={"We love feedback! Tell us what you like or what we can do better."}
                    inputButtonLabel={"Send"}
                    successTitle={"FEEDBACK SENT!"}
                    successSubtitle={"Thanks for letting us know how we can help you through your life journeys. A member of our team will get back to you."}
                    successButtonLabel={"Close"}
                />
            </PageContentContainer>
        </PageFull>
    </>
);

export default FeedbackWrapper;