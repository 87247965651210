import {axiosReq} from "../../../utils/axios";
/* Store */
import {
    setFileDeleted,
    setFileDeleting,
    setFileDownloading,
    setFileRenamed,
    setFileRenaming,
    setFileUploaded,
    setFileUploading,
    setFolderDeleted,
    setFolderDeleting,
    setFolderList,
    setFolderListFetching,
    setFolderMoved,
    setFolderMoving,
    setFolderNameEdited,
    setFolderNameEditing,
    setFolderNew,
    setFolderNewCreating,
    setFolderTree,
    setFolderTreeFetching,
    setBreadcrumb,
    setSharedDriveCreated,
    setSharedDriveCreatingNew,
    setSharedDriveListFetching,
    setSharedDriveList,
    setSharedDriveFolderListFetching,
    setSharedDriveFolderList,
    setSharedDrive,
    setDriveRenaming,
    setDriveDeleting,
    setDriveRenamed,
    setDriveDeleted,
    setDriveAddingUser,
    setDriveAddedUser,
    setDriveRemovingUser,
    setDriveRemovedUser
} from "../store/filevault";
/* Static */
import {
    devEnterpriseCreateNewFile,
    devEnterpriseCreateNewFolder,
    devEnterpriseDriveList,
    devEnterpriseEditFolderName,
    devEnterpriseFileRenamed,
    devEnterpriseFolderList,
    devEnterpriseMoveFolder,
    devEnterpriseSharedDriveCreate,
    devEnterpriseSharedDriveList,
    devEnterpriseSharedDriveFolderList,
    devEnterpriseSharedDrive,
    devEnterpriseDriveRenamed
} from '../static/devdata';

/* Tree */

export const tryGetFolderTree = () => {
    return async dispatch => {
        dispatch(setFolderTreeFetching(true));
        const folderTree = await axiosReq('filevault/drive/list', "GET", {}, devEnterpriseDriveList);
        await dispatch(setFolderTree(folderTree));
        dispatch(setFolderTreeFetching(false));
        return folderTree;
    }
};



/* Folders */

export const tryGetFolderList = (folderId, traverseFolder, storeBreadcrumb) => {
    return async (dispatch, getState) => {
        dispatch(setFolderListFetching(true));
        let selectedFolder = '';
        if(folderId !== null) {
            selectedFolder = '/'+folderId
        }
        const currentFolder = await axiosReq('filevault/folder/list'+selectedFolder, "GET", {}, await devEnterpriseFolderList(traverseFolder, getState().common.fileVault.get("folderList").get("parentFolderId")));
        await dispatch(setFolderList(currentFolder));
        if(storeBreadcrumb) {
            await dispatch(setBreadcrumb(currentFolder));
        }
        return dispatch(setFolderListFetching(false));
    }
};

export const tryCreateNewFolder = (name, parentFolderId, driveId, storeOrReturn) => {
    return async dispatch => {
        dispatch(setFolderNewCreating(true));
        const newFolder = await axiosReq('filevault/folder/create', "POST", {"name": name, "parentFolderId": parentFolderId, "driveId": driveId}, devEnterpriseCreateNewFolder(name, parentFolderId));
        if(storeOrReturn === "store") {
            dispatch(setFolderNew(newFolder));
            return dispatch(setFolderNewCreating(false));
        } else {
            dispatch(setFolderNewCreating(false));
            return newFolder;
        }
    }
};

export const tryEditFolderName = (folderId, name) => {
    return async dispatch => {
        dispatch(setFolderNameEditing(true));
        const editedFolder = await axiosReq('filevault/folder/rename', "PUT", {"folderId": folderId, "name": name}, devEnterpriseEditFolderName(folderId, name));
        await dispatch(setFolderNameEdited(editedFolder));
        return dispatch(setFolderNameEditing(false));
    }
};

export const tryMoveFolder = (folderId, newParentFolderId) => {
    return async dispatch => {
        dispatch(setFolderMoving(true));
        const movedFolder = await axiosReq('filevault/folder/move', "POST", {"folderId": folderId, "newParentFolderId": newParentFolderId}, devEnterpriseMoveFolder(folderId, newParentFolderId));
        await dispatch(setFolderMoved(movedFolder));
        return dispatch(setFolderMoving(false));
    }
};

export const tryDeleteFolder = (folderId) => {
    return async dispatch => {
        dispatch(setFolderDeleting(true));
        await axiosReq('filevault/folder/delete/'+folderId, "DELETE", {}, {});
        await dispatch(setFolderDeleted(folderId));
        return dispatch(setFolderDeleting(false));
    }
};



/* Files */

export const tryDownloadFile = (fileId) => {
    return async dispatch => {
        dispatch(setFileDownloading(true));
        await axiosReq('filevault/file/download/'+fileId, "GET", {}, {});
        return dispatch(setFileDownloading(false));
    }
};

export const tryUploadFile = (parentFolderId, file, uploadedFiles, driveId, name) => {
    return async dispatch => {
        dispatch(setFileUploading(true));
        let formData = new FormData();
        await formData.append("file", file, file.name);
        await formData.append("driveId", driveId);
        if(parentFolderId !== null) {
            await formData.append("parentFolderId", parentFolderId);
        }
        const newFile = await axiosReq('filevault/file/upload', "POST_FORMDATA", formData, devEnterpriseCreateNewFile(name, parentFolderId, file.type, file.size));
        if(uploadedFiles) {
            await dispatch(setFileUploaded(newFile));
        }
        dispatch(setFileUploading(false));
    }
};

export const tryRenameFile = (fileId, newName) => {
    return async dispatch => {
        dispatch(setFileRenaming(true));
        const renamedFile = await axiosReq('filevault/file/rename', "PUT", {"fileId": fileId, "newName": newName}, devEnterpriseFileRenamed(fileId, newName));
        await dispatch(setFileRenamed(renamedFile));
        return dispatch(setFileRenaming(false));
    }
};

export const tryDeleteFile = (fileId) => {
    return async dispatch => {
        dispatch(setFileDeleting(true));
        await axiosReq('filevault/file/delete/'+fileId, "DELETE", {}, {});
        await dispatch(setFileDeleted(fileId));
        return dispatch(setFileDeleting(false));
    }
};

/* Shared Drive */

export const tryCreateNewSharedDrive = (name, users) => {
    return async dispatch => {
        dispatch(setSharedDriveCreatingNew(true));
        const sharedDrive = await axiosReq('filevault/drive/create', "POST", {"name": name, "users": users}, devEnterpriseSharedDriveCreate(name));
        await dispatch(setSharedDriveCreated(sharedDrive));
        return dispatch(setSharedDriveCreatingNew(false));
    }
};

export const tryGetSharedDriveList = () => {
    return async dispatch => {
        dispatch(setSharedDriveListFetching(true));
        const sharedList = await axiosReq('filevault/drive/list', "GET", {}, devEnterpriseSharedDriveList);
        await dispatch(setSharedDriveList(sharedList));
        return dispatch(setSharedDriveListFetching(false));
    }
};

export const tryGetSharedDriveFolderList = (driveId, folderId, storeBreadcrumb) => {
    return async dispatch => {
        dispatch(setSharedDriveFolderListFetching(true));
        let selectedFolder = '';
        if(folderId !== null) {
            selectedFolder = '/'+folderId
        }
        const sharedFolderList = await axiosReq('filevault/drive/'+driveId+'/folder/list'+selectedFolder, "GET", {}, devEnterpriseSharedDriveFolderList(folderId));
        await dispatch(setSharedDriveFolderList(sharedFolderList));
        if(storeBreadcrumb) {
            await dispatch(setBreadcrumb(sharedFolderList));
        }
        return dispatch(setSharedDriveFolderListFetching(false));
    }
}

export const tryGetSharedDriveBetweenUser = (consumerId) => {
    return async dispatch => {
        dispatch(setSharedDriveListFetching(true));
        const sharedList = await axiosReq('filevault/drive/get-shared-drive/' + consumerId, "GET", {}, devEnterpriseSharedDrive);
        await dispatch(setSharedDrive(sharedList));
        return dispatch(setSharedDriveListFetching(false));
    }
}

export const tryDeleteDrive = (driveId) => {
    return async dispatch => {
        dispatch(setDriveDeleting(true));
        await axiosReq('filevault/drive/delete/' + driveId, "DELETE", {}, {});
        await dispatch(setDriveDeleted(driveId));
        return dispatch(setDriveDeleting(false));
    }
    
}

export const tryRenameDrive = (driveId, name) => {
    return async dispatch => {
        dispatch(setDriveRenaming(true));
        const renamed = await axiosReq('filevault/drive/rename', "PUT", {"driveId": driveId, "name": name}, devEnterpriseDriveRenamed(driveId, name));
        await dispatch(setDriveRenamed(renamed));
        return dispatch(setDriveRenaming(false));
    }
}

export const tryAddUser = (driveId, newUsers) => {
    return async dispatch => {
        dispatch(setDriveAddingUser(true));
        const drive = await axiosReq('filevault/drive/add-users-to-drive', "POST", {"driveId": driveId, "newUsers": newUsers}, {});
        await dispatch(setDriveAddedUser(drive));
        return dispatch(setDriveAddingUser(false));
    }
    
}

export const tryRemoveUser = (driveId, userId) => {
    return async dispatch => {
        dispatch(setDriveRemovingUser(true));
        const drive = await axiosReq('filevault/drive/' + driveId + '/remove-user/' + userId, "DELETE", {}, {});
        await dispatch(setDriveRemovedUser(drive));
        return dispatch(setDriveRemovingUser(false));
    }
}
