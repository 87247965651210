import React from 'react';
import styled from 'styled-components';
import moment from 'moment/moment';

/* Components */
import {colors} from "../../../common/components/colors";
import { images } from '../../../common/components/images';
import {EditOutlined, DeleteOutlined, CalendarOutlined, LeftOutlined} from "@ant-design/icons";
import { FontSubtitle38To30, FontHeader18, FontSubtitle21, FontTitle24, FontBody18, FontBody16, FontBody14 } from '../../../common/components/fonts';
import { IconButton } from '../../../common/components/buttons';

/* Utility */
import { hexToRGB, brightness } from '../../../common/utils/colors';
import {ContainerTop} from "../../home/components/home";

const Appointments = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
`;
const CoachCell = styled.div`
    background-color: ${props => props.backgroundColor};
     ${props => props.shapeStartTopLeftRound !== true} {
        border-radius: ${props => props.even ? '0px 20px 0px 20px' : '20px 0px 20px 0px'};
    }
     ${props => props.shapeStartTopLeftRound !== false} {
        border-radius: ${props => props.even ? '20px 0px 20px 0px' : '0px 20px 0px 20px'};
    }
    box-sizing: border-box;
    ${props => props.enableClick === false} {
        cursor: pointer;
    }
    color: ${props => brightness(hexToRGB(props.backgroundColor, false)) < 130 ? colors.white : colors.primary100};
    padding: ${props => props.dashboard ? '20px 30px 30px 30px' :'25px'};
    width: 100%;
    row-gap: 20px;
    display: flex;
    flex-direction: column;
    text-align: start;
`;
const FlexRow = styled.div`
    column-gap: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const FlexRB = styled(FlexRow)`
    width: 100%;
    justify-content: space-between;
`;
const FlexRowTop = styled(FlexRow)`
    align-items: flex-start;
    column-gap: 15px;
`

const ProfileIcon = styled.img`
    border-radius: 50%;
    height: 50px;
    width: 50px;
    object-fit: cover;
`;
const FontSubtitle21CC = styled(FontSubtitle21)`
    align-self: center;
`;
export const ColumnFlex = styled.div`
    row-gap: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
`

const checkCanJoin = (start, expectedEnd) => {
    const after15MinAgo = moment().isAfter(moment(start).subtract(15, "minutes"));
    const beforeExpectedEnd = moment().isBefore(moment(expectedEnd));
    return(after15MinAgo && beforeExpectedEnd)
};

export class AppointmentsList extends React.Component {
    static defaultProps = {
        meetings: [],
        members: [],
        reschedule: () => {},
        shapeStartTopLeftRound: true,
        backgroundColor: null,
        oneAppointment: false,
        dashboard: false,
        hideEmpty: false
    };

    join = async (meeting, e) => {
        e.stopPropagation();
        window.open("https://live.trustwillow.com/room/"+meeting.videoUrl, '_blank').focus()
    };

    render() {
       if ((this.props.meetings.length === 0 || (!this.props.oneAppointment && this.props.members.length === 0)) && !this.props.hideEmpty) {
           return <FontSubtitle21CC>{'No appointments found'}</FontSubtitle21CC>;
       } else if(this.props.oneAppointment && this.props.members.length === 0 && !this.props.hideEmpty) {
           return (<FontBody16>{'No upcoming appointments'}</FontBody16>)
       } else {
           return (
               <Appointments>
                   {this.props.meetings.map((meeting, idx) => {
                        const member = this.props.members.find(m => m.id === meeting.remoteUserId);
                        const cellColor = this.props.backgroundColor === null ? colors.cellColors[idx % colors.cellColors.length] : this.props.backgroundColor;
                        if (member === undefined) {
                           return null
                        } else {
                            return (
                                <CoachCell
                                    key={idx}
                                    backgroundColor={this.props.dashboard ? colors.backgroundColor1 :cellColor}
                                    even={idx % 2 !== 0}
                                    onClick={(e) => {meeting.type === "upcoming" ? (checkCanJoin(meeting.start, meeting.expected_end) ? this.join(meeting, e) : this.props.reschedule(meeting, e)) : {}}}
                                    enableClick={meeting.type === "upcoming"}
                                    shapeStartTopLeftRound={this.props.shapeStartTopLeftRound}  
                                    dashboard={this.props.dashboard}
                                >
                                    <FlexRow>
                                        <ProfileIcon src={member.photo} alt={'Willow'}/>
                                        <div>
                                            <FontSubtitle21>{member.first + ' ' + member.last}</FontSubtitle21>
                                            <FontBody16>{member.title}</FontBody16>
                                        </div>
                                    </FlexRow>
                                    <FlexRB>
                                        <FontBody16>{meeting.date}</FontBody16>
                                        {(meeting.type === "upcoming" && !meeting.isCanceled) ?
                                        <>
                                            {(checkCanJoin(meeting.start, meeting.expected_end))
                                                ? <FontSubtitle21>Join</FontSubtitle21>
                                                : <IconButton image={(brightness(hexToRGB(cellColor, false)) < 130) ? images.rescheduleWhite : images.rescheduleDark} />
                                            }
                                        </> :
                                            <>
                                                {meeting.isCanceled && <FontBody14>Canceled</FontBody14>}
                                                {(meeting.isMissed && !meeting.isCanceled) && <FontBody14>Missed</FontBody14>}
                                                {(!meeting.isCanceled && !meeting.isMissed) && <FontBody14>Completed</FontBody14>}
                                            </>
                                        }
                                    </FlexRB>
                                </CoachCell>
                            )
                        }
                   })}
               </Appointments>
           );
       }
    }
};