import {axiosReq} from "../../common/utils/axios";
import {Map} from 'immutable';

/* Store */
import {
    setContactsCreating,
    setContactsUpdating,
    setContactsArchiving,
    setContactsError,
    setContactsGuestsDownloading,
    setAllContactsFetching,
    setAllContacts,
    setAllContactsFetched,
    setContactsOneFetching,
    setContactsOne,
    setAllContactsAddNewContact,
    setAllContactsArchiveExistingContact,
    setAllContactsEditExistingContact
} from "../store/contacts";
import {setAllProspectsEditExistingProspect} from "../store/prospects";
import {setAllClientsEditExistingClient} from "../clients/store/clients";
import {setClientsDetailSelectedEdit} from '../store/clientsdetail';

/* Static */
import {
    devEnterpriseEditContactData,
    devEnterpriseCreateContactData,
    devEnterpriseGuestsOnMeeting,
    devEnterpriseAllContacts,
    devEnterpriseOneContact
} from '../static/devdata';

export const tryGetAllContacts = () => {
    return async (dispatch, getState) => {
        dispatch(setAllContactsFetching(true));
        const contacts = await axiosReq('apiv1/contacts/get-all', "GET", {}, devEnterpriseAllContacts);
        const isWMC = getState().common.wmc.get("isWMC");
        await dispatch(setAllContacts(contacts, isWMC));
        dispatch(setAllContactsFetched());
        return dispatch(setAllContactsFetching(false));
    }
};

export const tryGetOneContact = (contactId, storeOrReturn) => {
    return async (dispatch, getState) => {
        dispatch(setContactsOneFetching(true));
        const contact = await axiosReq('apiv1/contacts/get-all/'+contactId, "GET", {}, devEnterpriseOneContact(getState(), contactId));
        const isWMC = getState().common.wmc.get("isWMC");
        console.log(contact)
        let contactAdj = {...contact, "lastName": (!isWMC && contact.referralSource === "willow") ? contact.lastName.charAt(0)+"." : contact.lastName};
        if(storeOrReturn === "store") {
            dispatch(setContactsOne(contactAdj));
            return dispatch(setContactsOneFetching(false));
        } else {
            dispatch(setContactsOneFetching(false));
            return contactAdj;
        }
    }
};

const formatContact = (data) => (Map({
    "contactId": data.contactId === undefined ? data.id : data.contactId,
    "firstName": data.firstName,
    "lastName": data.lastName,
    "isArchived": data.isArchived,
    "businessUserId": null,
    "prospectId": data.prospectId,
    "consumerId": data.consumerId,
    "email": data.email,
    "phone": data.phone,
    "prospectStatus": data.prospectId === null ? "Contact" : data.coachProspectStatusTitle === "client" ? "Client" : "Prospect",
    "memberOfGroups": null,
    "referralSource": data.referralSource,
    "timeZone": data.timeZone,
    "companyName": data.companyName
}));

export const tryCreateContact = (data) => {
    return async dispatch => {
        dispatch(setContactsCreating(true));
        const newContact = await axiosReq('apiv1/wmc/contact/add', "POST", {firstName: data.first, lastName: data.last, email: data.email, phone: data.phone, timeZone: data.timeZone, wmcId: data.wmcId, expertId: data.expertId, companyName: data.companyName}, devEnterpriseCreateContactData(data.first, data.last, data.email, data.phone, data.timeZone, data.wmcId, data.expertId, null, data.companyName));
        if(newContact.hasOwnProperty("error")) {
            dispatch(setContactsError(newContact.error));
        } else {
            dispatch(setContactsError(null));
            await dispatch(setAllContactsAddNewContact(formatContact(newContact)));
        }
        dispatch(setContactsCreating(false));
    }
};

export const tryEditContact = (data, prospectId) => {
    return async dispatch => {
        dispatch(setContactsUpdating(true));
        let request = {companyName: data.companyName, firstName: data.first, lastName: data.last, email: data.email, phone: data.phone, timeZone: data.timeZone};
        if(prospectId !== null) {
            request = {...request, prospectId: prospectId};
        }
        const editedContact = await axiosReq('apiv1/wmc/contact/'+data.contactId+'/update', "PATCH", request, devEnterpriseEditContactData(data.first, data.last, data.email, data.phone, data.timeZone, data.contactId, prospectId, data.stageTitle, data.companyName));
        if(editedContact.hasOwnProperty("error")) {
            dispatch(setContactsError(editedContact.error));
        } else {
            dispatch(setContactsError(null));
            await dispatch(setAllContactsEditExistingContact(editedContact.id, request, formatContact));
            if(data.prospectStatus === "Prospect") {
                await dispatch(setAllProspectsEditExistingProspect(data.prospectId, request));
            } else if(data.prospectStatus === "Client") {
                await dispatch(setAllClientsEditExistingClient(data.prospectId, request));
            }
            await dispatch(setClientsDetailSelectedEdit(request))
        }
        return dispatch(setContactsUpdating(false));
    }
};

export const tryArchiveContact = (data) => {
    return async dispatch => {
        dispatch(setContactsArchiving(true));
        await axiosReq('apiv1/wmc/contact/'+data.id+'/update', "PATCH", {"isArchived": !data.isArchived}, {});
        await dispatch(setAllContactsArchiveExistingContact(data.id, data.isArchived));
        return dispatch(setContactsArchiving(false));
    }
};

// export const tryGetConferenceGuests = (conferenceId) => {
//     return async dispatch => {
//         dispatch(setContactsGuestsDownloading(true));
//         const guests = await axiosReq('apiv1/wmc/contact/by-conference/'+conferenceId, "GET", {}, devEnterpriseGuestsOnMeeting);
//         dispatch(setContactsGuestsDownloading(true));
//         return guests.allContacts;
//     }
// };

export const getOneContactById = (contactId) => {
    return async (dispatch, getState) => {
        return getState().enterprise.contacts.get("contactsAll").filter(c => {return(c.get("contactId") === contactId)}).get(0).toJS();
    }
};

export const getOneContactByConsumerId = (consumerId) => {
    return async (dispatch, getState) => {
        if(getState().enterprise.contacts.get("contactsAll").size === 0) {
            await dispatch(tryGetAllContacts());
        }
        return getState().enterprise.contacts.get("contactsAll").filter(c => {return(c.get("consumerId") === consumerId)}).get(0).toJS();
    }
};