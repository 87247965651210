import React from 'react';
import validator from 'validator';

/* Components */
import {RadioBoxWithLabelWhite, InputLabel, NumberInputWhite, TextInputWhite} from "../../../common/components/inputs";
import {SaveSection, SubmitInputWithValidation} from '../../components/clientscreate';
import {FontBody14, FontBody16, FontTitle18, FontBody12, FontHeader16} from '../../../common/components/fonts';
import {CloudUploadOutlined} from '@ant-design/icons';
import {ButtonTertiary} from "../../../common/components/buttons";
import {Width, FitContent, PhotoButton, Inset, PhotoMargin, PhotoUpload, Section, SplitCenter, SplitLeft, SplitRight} from '../components/listings';

class ListingType extends React.Component {
    state = {
        numSessions: 1, numSessionsValid: true,
        lengthSessions: 30, lengthSessionsValid: true,
        file: '', fileChanged: false, fileBlob: '',
        serviceChecked: true, downloadChecked: false, tangibleChecked: false, serviceOfflineChecked: false,
        fileURL: "", fileURLValid: false,
        isValid: true,
        isChanged: true
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        if(this.props.data.length !== 0) {
            this.setState({...this.props.data})
        }
    };

    tryChangeFile = async () => {
        document.getElementById('selectedFileUpload').click()
    };

    handleChangeFile = async (e) => {
        await this.setState({
            'file': e.target.files[0],
            'fileChanged': true,
            'fileBlob': e,
            'isChanged': true
        });
        this.checkValid();
    };

    handleChangeNumSessions = async e => {
        await this.setState({
            'numSessions': e.target.value < 1 ? 1 : e.target.value,
            'numSessionsValid': e.target.value < 1 ? true : (!validator.isEmpty(e.target.value.toString()) && e.target.value.toString() !== ''),
            'isChanged': true
        });
        this.checkValid();
    };

    handleChangeLengthSessions = async e => {
        await this.setState({
            'lengthSessions': e.target.value < 1 ? 1 : e.target.value,
            'lengthSessionsValid': e.target.value < 1 ? true : (!validator.isEmpty(e.target.value.toString()) && e.target.value.toString() !== ''),
            'isChanged': true
        });
        this.checkValid();
    };

    handleSelectType = async (selectedType) => {
        await this.setState({
            "serviceChecked": selectedType === "sessions",
            "serviceOfflineChecked": selectedType === "offline",
            "downloadChecked": selectedType === "download",
            "tangibleChecked": selectedType === "tangible"
        });
        this.checkValid();
    };

    handleChangeFileURL = async e => {
        await this.setState({
            'fileURL': e.target.value,
            'fileURLValid': validator.isURL(e.target.value),
            'isChanged': true
        });
        this.checkValid();
    };


    checkValid = () => {
        if(this.state.serviceChecked) {
            if(this.state.lengthSessionsValid && this.state.numSessionsValid) {
                this.setState({'isValid': true})
            } else {
                this.setState({'isValid': false})
            }
        } else if(this.state.downloadChecked) {
            if(this.state.fileURLValid/*this.state.fileBlob !== ""*/) {
                this.setState({'isValid': true})
            } else {
                this.setState({'isValid': false})
            }
        } else {
            this.setState({'isValid': true})
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            return this.props.next(this.state)
        }
    };

    render() {
        return(
            <Width>
                <form method={"post"} onSubmit={this.handleSubmit}>
                    <Section>
                        <InputLabel><FontTitle18>What type of product are you providing?</FontTitle18></InputLabel>
                        <br />
                        <br />
                        <br />
                        <RadioBoxWithLabelWhite
                            id={1}
                            action={() => {return this.handleSelectType("sessions")}}
                            checked={this.state.serviceChecked}
                            label={"Online Meetings"}
                            subtext={"Video/phone meetings with buyers."}
                            disabled={false}
                        />
                        {this.state.serviceChecked &&
                            <Inset>
                                <NumberInputWhite
                                    title={"Number of Meetings*"}
                                    valid={this.state.numSessionsValid}
                                    warning={"Required"}
                                    id={"numSessions"}
                                    onChange={this.handleChangeNumSessions}
                                    placeholder={1}
                                    value={this.state.numSessions}
                                />
                                <NumberInputWhite
                                    title={"Length of Meetings* (in minutes)"}
                                    valid={this.state.lengthSessionsValid}
                                    warning={"Required"}
                                    id={"lengthSessions"}
                                    onChange={this.handleChangeLengthSessions}
                                    placeholder={30}
                                    value={this.state.lengthSessions}
                                />
                            </Inset>
                        }
                        <br />
                        <RadioBoxWithLabelWhite
                            id={3}
                            action={() => {return this.handleSelectType("offline")}}
                            checked={this.state.serviceOfflineChecked}
                            label={"Offline Service"}
                            subtext={"Delivered in-person or offline."}
                            disabled={false}
                        />
                        <br />
                        <RadioBoxWithLabelWhite
                            id={2}
                            action={() => {return this.handleSelectType("download")}}
                            checked={this.state.downloadChecked}
                            label={"File Download"}
                            subtext={"A digital file buyers can download."}
                            disabled={false}
                        />
                        {this.state.downloadChecked &&
                            <Inset>
                                <TextInputWhite
                                    title={"Document URL"}
                                    valid={this.state.fileURLValid}
                                    warning={""}
                                    id={"fileURL"}
                                    onChange={this.handleChangeFileURL}
                                    placeholder={"https://..."}
                                    value={this.state.fileURL}
                                />
                                {/*<br />*/}
                                {/*<PhotoMargin><FontBody16>Digital File</FontBody16></PhotoMargin>*/}
                                {/*<PhotoButton onClick={this.tryChangeFile}>*/}
                                {/*    <CloudUploadOutlined />*/}
                                {/*    <FontBody14>Add a file</FontBody14>*/}
                                {/*</PhotoButton>*/}
                                {/*<PhotoUpload id={"selectedFileUpload"} type={"file"} onChange={this.handleChangeFile} />*/}
                            </Inset>
                        }
                        {/*<br />*/}
                        {/*<RadioBoxWithLabelWhite*/}
                        {/*    id={4}*/}
                        {/*    action={() => {return this.handleSelectType("tangible")}}*/}
                        {/*    checked={this.state.tangibleChecked}*/}
                        {/*    label={"Tangible Item"}*/}
                        {/*    subtext={"A product you will ship to buyers."}*/}
                        {/*    disabled={false}*/}
                        {/*/>*/}
                        {/*{this.state.tangibleChecked &&*/}
                        {/*    <>*/}
                        {/*        <FontHeader16>Shipping</FontHeader16>*/}
                        {/*        <FontBody14>Set clear and realistic shipping expectations for shoppers</FontBody14>*/}
                        {/*    </>*/}
                        {/*}*/}
                    </Section>
                    <SaveSection>
                        <SplitLeft>
                            <FitContent onClick={this.props.back}><ButtonTertiary canSubmit={false} label={"Cancel"} /></FitContent>
                        </SplitLeft>
                        <SplitCenter><FontBody12> </FontBody12></SplitCenter>
                        <SplitRight>
                            <FitContent><SubmitInputWithValidation label={"Next"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={false} /></FitContent>
                        </SplitRight>
                    </SaveSection>
                </form>
            </Width>
        )
    }
}

export default ListingType;
