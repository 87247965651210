import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';
import validator from "validator";

/* Components */
import {SavedSettings} from '../../../common/components/cards';
import {FontBody16, FontTitle18} from '../../../common/components/fonts';
import {ButtonSecondary, ButtonTertiary} from "../../../common/components/buttons";
import {TextInput} from "../../../common/components/inputs";
import {LogoLoading} from "../../../common/components/loading";
import {FitContent, InlineBlock, SaveSection, SubmitInputWithValidation} from "../components/myprofile";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {Icon14, Specialty} from "../components/about";
import {Star} from "react-feather";
import {colors} from "../../../common/components/colors";

/* Middleware */
import {tryUpdateExpertProfile} from '../middleware/expertprofile';

const LoadingCenter = styled.div`
    margin: 60px auto;
`;
export const Btn = styled.div`
    color: ${colors.action100};
    cursor: pointer;
`;

class ProfileSkills extends React.Component {
    state = {
        newSkill: "", newSkillValid: true,
        skills: [], skillsPrevious: [],
        isSaving: false,
        isSaved: false,
        error: '',
        isValid: true,
        isChanged: false,
        isLoading: true,
        isEdit: false
    };

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.profile !== this.props.profile) {
            return this.init();
        }
    }

    init = async () => {
        await this.setState({
            "skills": this.props.profile.get("skills").toJS(),
            "skillsPrevious": this.props.profile.get("skills").toJS(),
            "isLoading": false,
            "isChanged": false
        });
    };

    reset = async () => {
        await this.setState({
            "skills": this.state.skillsPrevious,
            "newSkill": "",
            "newSkillValid": true,
            "isChanged": false,
            "isEdit": false
        });
    };

    edit = async (status) => {
        this.setState({"isEdit": status});
    };

    handleChangeNewSkill = async e => {
        await this.setState({
            'newSkill': e.target.value,
            'newSkillValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''),
            'isChanged': true
        });
        this.checkValid();
    };

    checkValid = async () => {
        if(this.state.newSkillValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    delete = async (item) => {
        let skills = this.props.profile.get("skills").toJS();
        const saveData = {
            // "bio": this.props.profile.get("bio"),
            // "whyBecameCoach": this.props.profile.get("whyBecameCoach"),
            // "freeTime": this.props.profile.get("freeTime"),
            // "endorsement1": this.props.profile.get("endorsement1"),
            // "endorsement2": this.props.profile.get("endorsement2"),
            // "endorsement3": this.props.profile.get("endorsement3"),
            "skills": await skills.filter(p => {return(p !== item)}),
        };
        await this.props.tryUpdateExpertProfile(saveData, "deleteSkill");
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            await this.setState({'isSaving': true, "error": ""});
            let skills = this.props.profile.get("skills").toJS();
            await skills.push(this.state.newSkill);
            const saveData = {
                // "bio": this.props.profile.get("bio"),
                // "whyBecameCoach": this.props.profile.get("whyBecameCoach"),
                // "freeTime": this.props.profile.get("freeTime"),
                // "endorsement1": this.props.profile.get("endorsement1"),
                // "endorsement2": this.props.profile.get("endorsement2"),
                // "endorsement3": this.props.profile.get("endorsement3"),
                "skills": skills
            };
            await this.props.tryUpdateExpertProfile(saveData, "errorSkills");
            if(this.props.profile.get("errorSkills") === "") {
                await this.setState({
                    'isSaving': false,
                    "isSaved": true,
                    "skillsPrevious": this.state.skills,
                    "newSkill": "",
                    "newSkillValid": true,
                    "isEdit": false
                });
                this.timeout = setTimeout(() => {this.setState({'isSaved': false, "isChanged": false})}, 3000);
            } else {
                this.setState({'isSaved': true, 'isSaving': false, "error": this.props.profile.get("errorSkills")});
                this.timeout = setTimeout(() => {this.setState({'isSaved': false, "error": ""})}, 3000);
            }
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    {this.state.isSaved
                        ?
                            <>
                                {this.state.error === '' && <SavedSettings height={"99px"} error={false} message={"Saved"}/>}
                                {this.state.error !== '' && <SavedSettings height={"99px"} error={true} message={this.state.error}/>}
                            </>
                        :
                            <>
                                {!this.state.isEdit &&
                                    <>
                                        <FontTitle18>Skills</FontTitle18>
                                        {this.state.skills.length === 0
                                            ?
                                                <>
                                                    <br />
                                                    <br />
                                                    <FontBody16>No skills</FontBody16>
                                                    <br/>
                                                    <FitContent><FontBody16 onClick={() => {return this.edit(true)}}><ButtonSecondary canSubmit={false} label={<><EditOutlined/>&nbsp;Add Skills</>} /></FontBody16></FitContent>
                                                </>
                                            :
                                                <>
                                                    <br />
                                                    <br />
                                                    {this.state.skills.map((e, indexE) => (
                                                        <Specialty key={indexE}><FontBody16><Icon14><Star /></Icon14>&nbsp;{e}</FontBody16></Specialty>
                                                    ))}
                                                    <br/>
                                                    <br/>
                                                    <FitContent><FontBody16 onClick={() => {return this.edit(true)}}><ButtonSecondary canSubmit={false} label={<><EditOutlined/>&nbsp;Change Skills</>} /></FontBody16></FitContent>
                                                </>
                                        }
                                    </>
                                }
                                {this.state.isEdit &&
                                    <>
                                        <FontTitle18>Skills</FontTitle18>
                                        {this.state.skills.length === 0
                                            ?
                                                <><br /><br /><FontBody16>No skills</FontBody16></>
                                            :
                                                <>
                                                    <br />
                                                    <br />
                                                    {this.state.skills.map((e, indexE) => (
                                                        <Specialty key={indexE}>
                                                            <FontBody16><Icon14><Star /></Icon14>&nbsp;{e}</FontBody16>
                                                            <FitContent>
                                                                <Btn>
                                                                    <FontBody16 onClick={() => {return this.delete(e)}}><DeleteOutlined />&nbsp;Delete</FontBody16>
                                                                </Btn>
                                                            </FitContent>
                                                        </Specialty>
                                                    ))}
                                                </>
                                        }
                                        <br/>
                                        <form method={"post"} onSubmit={this.handleSubmit}>
                                            <TextInput
                                                title={"Add Skill"}
                                                valid={this.state.newSkillValid}
                                                warning={""}
                                                id={"newSkill"}
                                                onChange={this.handleChangeNewSkill}
                                                placeholder={"Add skill"}
                                                value={this.state.newSkill}
                                            />
                                            <SaveSection>
                                                <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                                <InlineBlock><div onClick={this.reset}><ButtonTertiary canSubmit={false} label={"Cancel"} /></div></InlineBlock>
                                            </SaveSection>
                                        </form>
                                    </>
                                }
                            </>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    profile: state.enterprise.shop.expertProfile.get("expertProfile"),
});

const mapDispatchToProps = dispatch => ({
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSkills);
