import React from 'react';
import {withRouter} from 'react-router-dom';

/* Local */
import CreateEvent from "./containers/createevent";
import EditEvent from "./containers/editevent";
import EventsList from "./containers/eventslist";
import AllEventsPage from "./containers/alleventspage";
import PreviewScheduler from "./containers/previewscheduler";
import LinkEmbed from "./containers/linkembed";

/* Navs */
import {PageContent, PageFull, PageRight, Block, CardOrNot, Right, Width, WidthRight} from "../../navbars/components/navlayout";
import NavPrimaryAll from "../../navbars/containers/primaryall";
import NavSecondaryTop from "../../navbars/containers/secondarytop";

/* Components */
import {PlusOutlined} from "@ant-design/icons";
import {FontTitle18} from "../../../common/components/fonts";

class SchedulerPage extends React.Component {
    state={"preview": false, "linkEmbed": false, "linkEmbedId": null};

    previewStatus = async (status) => {
        this.setState({"preview": status})
    };

    linkEmbed = async (status) => {
        this.setState({
            "linkEmbedId": null,
            "linkEmbed": status,
        })
    };

    setLinkEmbedId = async (id) => {
        this.setState({
            "linkEmbedId": id.toString(),
            "linkEmbed": true
        })
    };

    render() {
        return(
            <>
                {/*<NavPrimaryAll />*/}
                {/*<PageFull>*/}
                {/*    <PageRight>*/}

                        {this.props.location.pathname === "/settings/availability/appointments" &&
                            <>
                                {/*<NavSecondaryTop title={"Calendar Sync"} desc={"Select a calendar and set your recurring weekly availability"} />*/}
                                {/*<NavSecondaryTop title={"Appointments"} desc={"Create, edit, and offer specific appointments"}/>*/}
                                <PageContent>
                                    <Block>
                                        <Width fullWidth={false}>
                                            <EventsList setLinkEmbedId={this.setLinkEmbedId} />
                                        </Width>
                                        <WidthRight fullWidth={false}>
                                            <Right fullWidth={false}>
                                                <CardOrNot>
                                                    <AllEventsPage
                                                        title={"Appointment Page"}
                                                        subtitle={null}
                                                        type={"page"}
                                                        url={"schedule"}
                                                        handleKey={"schedulerHandle"}
                                                        activeKey={"schedulerActive"}
                                                        errorKey={"schedulerHandleError"}
                                                        showLinkEmbed={true}
                                                        linkEmbed={this.linkEmbed}
                                                        showOpenLink={false}
                                                        showPreview={true}
                                                        preview={this.previewStatus}
                                                    />
                                                </CardOrNot>
                                            </Right>
                                        </WidthRight>
                                    </Block>
                                </PageContent>
                                <PreviewScheduler
                                    preview={this.state.preview}
                                    updatePreview={this.previewStatus}
                                />
                                {this.state.linkEmbed &&
                                    <LinkEmbed
                                        linkEmbed={this.linkEmbed}
                                        linkEmbedId={this.state.linkEmbedId}
                                    />
                                }
                            </>
                        }

                        {this.props.location.pathname === "/settings/availability/appointments/create" &&
                            <>
                                {/*<NavSecondaryTop title={"Create Appointment Type"} />*/}
                                {/*<PageContent>*/}
                                    <CreateEvent />
                                {/*</PageContent>*/}
                            </>
                        }

                        {this.props.location.pathname === "/settings/availability/appointments/edit" &&
                            <>
                                {/*<NavSecondaryTop title={"Edit Appointment Type"} />*/}
                                {/*<PageContent>*/}
                                    <EditEvent />
                                {/*</PageContent>*/}
                            </>
                        }

                    {/*</PageRight>*/}
                {/*</PageFull>*/}
            </>
        )
    }
}

export default withRouter(SchedulerPage);
