export default {
    body: {
        fontFamily: "GraphicWebRegular",
        fontFileName: "Graphik-Web-Regular-Regular.ttf",
        fontFormat: "truetype"
    },
    header: {
        fontFamily: "GraphicWebMedium",
        fontFileName: "Graphik-Web-Medium-Regular.ttf",
        fontFormat: "truetype"
    },
    subtitle: {
        fontFamily: "CooperLight",
        fontFileName: "Cooper-Lt-BT-Light.ttf",
        fontFormat: "truetype"
    },
    title: {
        fontFamily: "CooperBold",
        fontFileName: "Cooper-Lt-BT-Bold.ttf",
        fontFormat: "truetype"
    }
};