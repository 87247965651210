import {images} from '../../../common/components/images';

export const devEnterpriseBillingHistoryData = [
    {
        "coachId": 1033,
        "amountPaid":100,
        "purchasedOn":"2021-03-25T15:48:31Z",
        "isSubscription":true,
        "subcriptionInterval":"year",
        "subscriptionDuration":null,
        "isActive":true,
        "isOnTheFlyInvoice": false,
        "productId": 1,
        "numberOfSessions": 4,
        "sessionLengthInMinutes": 45,
        "coverPhotoProduct": null,
        "productTitle": "Clarity Meeting",
        "productDescription": "<p>erflhbqlfjhbqwe</p>",
        "downloadUrl": "https://google.com",
        "serviceType": "online"
    },
    {
        "coachId": 1032,
        "amountPaid":100,
        "purchasedOn":"2021-04-25T15:48:31Z",
        "isSubscription":true,
        "subcriptionInterval":"year",
        "subscriptionDuration":null,
        "isActive":true,
        "isOnTheFlyInvoice": false,
        "productId": 2,
        "numberOfSessions": 1,
        "sessionLengthInMinutes": 30,
        "coverPhotoProduct": images.devDataStoreProduct,
        "productTitle": "Clarity Meeting 2",
        "productDescription": "<p>erflhbqlfjhbqwe</p>",
        "downloadUrl": "https://google.com",
        "serviceType": "online"
    },
];

// export const devEnterpriseBillingPaymentMethodData = {
//     "card": "visa",
//     "last4digits": 4242,
//     "expirationMonth": 3,
//     "expirationYear": 2024
// };
//
// export const devEnterpriseBillingCurrentPlanData = {
//     "id": 70,
//     "productId": 12,
//     "orderIndex": 0,
//     "description": "",
//     "numberOfSessions": 1,
//     "amount": 38900,
//     "isPrivate": false,
//     "planType": "one_time",
//     "recurringInterval": null,
//     "subscriptionDurationInMonths": null,
//     "status": "Active",
//     "product": {
//         "id": 12,
//         "title": "Clarity Meeting",
//         "description": "<p>A 2-hour session via video conference that provides an overview of your current financial picture. You\u2019ll finally understand which aspects of your personal finances are on track and what needs more attention. We\u2019ll take a deep dive into your spending and show you where your money is going.</p><p><br></p><p>You\u2019ll receive customized recommendations related to savings, cash flow, debt, and protection that are designed to build wealth, improve your money confidence, and financially prepare you for wherever life takes you.</p><p><br></p><p>You\u2019ll leave the meeting with a Wellness Plan that will strengthen your financial foundation.</p>",
//         "coverPhoto": images.devDataStoreProduct,
//         "serviceType": "online",
//         "termsOfUseUrl": "",
//         "privacyPolicyUrl": "",
//         "downloadUrl": "",
//         "sessionLength": 120,
//         "status": "Active",
//         "expert": {
//             "id": 71,
//             "fullName": "Megan Scipione",
//             "firstName": "Megan",
//             "lastName": "Scipione",
//             "storeHandle": "meganscipione",
//             "publicHandle": "meganscipione",
//             "profilePhotoUrl": images.devDataCoachMeganAvatar
//         }
//     }
// };

export const devEnterpriseBillingPaymentMethodData = {
    "object": "list",
    "data": [
        {
            "id": "pm_1IyTKnBPDoWM9BMeNhpU7YHr",
            "object": "payment_method",
            "billing_details": {
                "address": {
                    "city": null,
                    "country": null,
                    "line1": null,
                    "line2": null,
                    "postal_code": "02129",
                    "state": null
                },
                "email": "jason+expert@trustwillow.com",
                "name": "Jason Expert 44",
                "phone": "1135193519"
            },
            "card": {
                "brand": "amex",
                "checks": {
                    "address_line1_check": null,
                    "address_postal_code_check": "unavailable",
                    "cvc_check": "unavailable"
                },
                "country": "US",
                "exp_month": 11,
                "exp_year": 2024,
                "fingerprint": "O4uyp1NZ4iHbrnSS",
                "funding": "credit",
                "generated_from": null,
                "last4": "1011",
                "networks": {
                    "available": [
                        "amex"
                    ],
                    "preferred": null
                },
                "three_d_secure_usage": {
                    "supported": true
                },
                "wallet": null
            },
            "created": 1622775874,
            "customer": "cus_JCUvZZWYsbDHjD",
            "livemode": true,
            "metadata": {},
            "type": "card"
        },
        {
            "id": "pm_1IyTFrBPDoWM9BMeHfKjTfN4",
            "object": "payment_method",
            "billing_details": {
                "address": {
                    "city": null,
                    "country": null,
                    "line1": null,
                    "line2": null,
                    "postal_code": "02129",
                    "state": null
                },
                "email": "jason+expert@trustwillow.com",
                "name": "Jason Aronson",
                "phone": "1135193519"
            },
            "card": {
                "brand": "amex",
                "checks": {
                    "address_line1_check": null,
                    "address_postal_code_check": "unchecked",
                    "cvc_check": "unchecked"
                },
                "country": "US",
                "exp_month": 11,
                "exp_year": 2024,
                "fingerprint": "O4uyp1NZ4iHbrnSS",
                "funding": "credit",
                "generated_from": null,
                "last4": "1011",
                "networks": {
                    "available": [
                        "amex"
                    ],
                    "preferred": null
                },
                "three_d_secure_usage": {
                    "supported": true
                },
                "wallet": null
            },
            "created": 1622775567,
            "customer": "cus_JCUvZZWYsbDHjD",
            "livemode": true,
            "metadata": {},
            "type": "card"
        }
    ],
    "has_more": false,
    "url": "/v1/payment_methods"
};

