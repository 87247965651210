import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

/* Domain */
import getDomainEnv from '../../domains/utils';
const env = getDomainEnv();

/* Components */
import {CoachingLoading} from '../components/contactcoaching';

/* Containers */
import {CoachAssignedToWMCAndProspect, NoCoachAssignedToWMC, NoCoachAssignedToProspect, NoCoachForWMCContact} from './contactcoachingadvisor';
import {ContactSourcedByAdvisor, ContactSourcedByFirm, ContactSourcedByCoachOrWillow} from './contactcoachingcoach';

/* Middleware */
import {
    tryGetCoachesAssignedToWMC,
    tryGetCoachesAssignedToProspect,
    tryPostUpdateProspectsSessions,
    tryGetCoachProgramCoachProspect,
    tryGetCoachProgramWMCProspect,
    tryPostAssignCoachToProspect
} from '../team/middleware/coaching';
import {tryCreateProspect, tryGetOneProspect} from "../middleware/prospects";
import {tryGetOneContact} from "../middleware/contacts";
import {tryGetOneClient} from "../clients/middleware/clients";
import {tryGetCoachClientPurchaseLink, tryGetClientGeneralSessions} from '../middleware/clientsprogram';

/* Store */
import {setContactsOne} from '../store/contacts';
import {setClientsDetailSelected } from '../store/clientsdetail';
import {setMessagesEmailOpen} from "../email/store/messages";
import {setSessionsModal} from "../meetings/store/sessions";
import {setCoachModalStatus, setClientModalStatus} from "../team/store/coaching";
import {Compose} from "../email/components/emailercompose";

class ContactCoaching extends React.Component {
    state = {"isLoading": true, "contact": null, "client": null, "prospectType": null, "generalSessions": 0};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const prospectStatus = this.props.client.get("prospectStatus");
        const prospectId = this.props.client.get("prospectId");
        const contactId = this.props.client.get("contactId");
        await this.props.tryGetOneContact(contactId, "store");
        let prospectData;
        if(prospectStatus === "Prospect") {
            await this.props.tryGetOneProspect(prospectId, "store");
            prospectData = "prospectsOne";
        } else if(prospectStatus === "Client") {
            await this.props.tryGetOneClient(prospectId, "store");
            prospectData = "clientsOne";
        }
        if(this.props.isWMC) {
            if(!this.props.allCoachesFetched) {
                await this.props.tryGetCoachesAssignedToWMC();
            }
            if(this.props.allCoaches.size > 0 && this.props.client.get("prospectId") !== null) {
                await this.props.tryGetCoachesAssignedToProspect(this.props.client.get("prospectId"), "store");
            }
        } else {
            if(this.props[prospectData].get("advisor").get("advisorId") === this.props.user.get("userId") || this.props[prospectData].get("wmcId") === null) {
                await this.props.tryGetCoachProgramCoachProspect(this.props.client.get("prospectId"), "store");
            } else {
                await this.props.tryGetCoachProgramWMCProspect(this.props.client.get("prospectId"), "store");
            }
        }
        const generalSessions = await this.props.tryGetClientGeneralSessions(prospectId)
        let sessions = 0;
        if (generalSessions.availableSessions !== null && generalSessions.availableSessions !== undefined) {
            sessions = generalSessions.availableSessions
        }
        this.setState({
            "prospectType": prospectData,
            "contact": this.props.contact,
            "client": this.props.client,
            "coachesAssigned": this.props.coachesAssigned.filter(c => {return(c.get("coachId") !== this.props.user.get("userId"))}),
            "generalSessions": sessions,
            "isLoading": false
        })
    };

    emailPurchaseLink = async () => {
        const consumerId = this.props.client.get("consumerId");
        const coachName = this.props.user.get("first")+" "+this.props.user.get("last");
        const clientFirstName = this.props.client.get("firstName");
        const clientLastName = this.props.client.get("referralSource") === "willow" ? (this.props.client.get("lastName").charAt(0).toUpperCase()+".") : this.props.client.get("lastName");
        const purchaseToken = await this.props.tryGetCoachClientPurchaseLink(consumerId);
        const subject = "Purchase a coaching program";
        const content = "<p>Dear "+clientFirstName+",</p><p><br></p><p>Congratulations on taking this important step on your financial journey!</p><p><br></p><p>Please follow this link to purchase your financial coaching program. I'm looking forward to working with you.</p><p><br></p><p><a target='_blank' href='https://"+env.URL.HOSTNAME+"/package/"+purchaseToken+"'>Purchase Link</a><p><br></p><p>"+coachName+"</p>"
        this.props.setMessagesEmailOpen(true, consumerId, "consumer", clientFirstName, clientLastName, false, subject, content);
    };

    scheduleSession = async () => {
        // FINISH THIS
        // open scheduler for coach with client
        await this.props.setSessionsModal(false, null, null, this.props.client.get("contactId"), null, null, true);
        this.props.history.push("/meetings/create/call");
    };

    requestMoreSessionsFromAdvisor = async () => {
        // FINISH THIS
        const businessUserId = this.props[this.state.prospectType].get("advisor").get("advisorId");
        const coachName = this.props.user.get("first")+" "+this.props.user.get("last");
        const advisorFirstName = this.props[this.state.prospectType].get("advisor").get("firstName");
        const jointClientName = this.props.client.get("firstName")+" "+this.props.client.get("lastName");
        const jointClientFirstName = this.props.client.get("firstName");
        const jointClientLastName = this.props.client.get("lastName");
        const subject = "Requesting more sessions";
        const content = "<p>Dear "+advisorFirstName+",</p><p><br></p><p>I've determined that "+jointClientName+" may benefit from additional coaching, and I suggest we add more sessions. If you agree, please log into the dashboard to add more sessions for "+jointClientFirstName+".</p><p><br></p><p>"+coachName+"</p>"
        this.props.setMessagesEmailOpen(true, businessUserId, "expert", jointClientFirstName, jointClientLastName, false, subject, content);
    };

    giftFreeSessions = async (coachId, numSessions, coachName) => {
        // Skipping for now
    };

    updateSessions = async (coachId, sessionsDelta) => {
        this.props.tryPostUpdateProspectsSessions(coachId, sessionsDelta, null, null);
    };

    settings = async () => {
        this.props.history.push("/team");
    };

    addCoachToWMC = async () => {
        // Send to Gabby's calendar with CTA to create a meeting to learn more
        this.props.history.push("/team");
    };


    addCoachToProspect = async (coachId, numSessions, coachName) => {
        const prospectStatus = this.props.client.get("prospectStatus");
        const prospectId = this.props.client.get("prospectId");
        const consumerId = this.props.client.get("consumerId");
        if(this.state.client.get("prospectId") === null) {
            const data = this.state.contact.toJS();
            const stageId = await this.props.stages.filter(s => {return s.get("title") === "undefined"}).get(0).get("id");
            const prospect = await this.props.tryCreateProspect({...data, "stageId": stageId, "first": data.firstName, "last": data.lastName, "fromContactId": data.contactId});
            this.props.setContactsOne({...this.state.contact.toJS(), "prospectId": prospect.prospectId});
            this.props.setClientsDetailSelected({...this.state.client.toJS(), "prospectId": prospect.prospectId});
        }
        this.props.tryPostAssignCoachToProspect(coachId, numSessions, coachName, consumerId, prospectId, prospectStatus);
    };

    assignCoach = async () => {
        await this.props.setClientModalStatus(true, this.props.client);
    }

    render() {
        if(this.state.isLoading) {
            return null
        } else {
            if(this.props.isWMC) {
                if(this.props.allCoaches.size === 0) {
                    return(
                        <NoCoachAssignedToWMC
                            addCoachToWMC={this.addCoachToWMC}
                            prospectStatus={this.props.client.get("prospectStatus")}
                        />
                    )
                } else {
                    if(this.props.client.get("prospectStatus") === "Contact") {
                        return(
                            <NoCoachForWMCContact />
                        )
                    } else {
                        if(this.state.coachesAssigned.size === 0) {
                            return(
                                <NoCoachAssignedToProspect
                                    allCoaches={this.props.allCoaches}
                                    updateSessions={this.updateSessions}
                                    addCoachToProspect={this.addCoachToProspect}
                                    settings={this.settings}
                                    prospectId={this.props.client.get("prospectId")}
                                    prospectStatus={this.props.client.get("prospectStatus")}
                                    setModal={this.assignCoach}
                                />
                            )
                        } else {
                            return(
                                <CoachAssignedToWMCAndProspect
                                    coachesAssigned={this.state.coachesAssigned}
                                    updateSessions={this.updateSessions}
                                    settings={this.settings}
                                    coachesAll={this.props.allCoaches.filter(
                                        s => {return(s.get("expertCategories").map(c => {return(c.get("categoryId") !== 6 && c.get("categoryId") !== 7)}).includes(true)
                                        )})}
                                    addCoachToClient={this.assignCoach}
                                    
                                />
                            )
                        }
                    }
                }
            } else {
                if(this.props[this.state.prospectType].get("advisor").get("advisorId") !== this.props.user.get("userId") && this.props[this.state.prospectType].get("advisor").get("wmcId") !== null) {
                    // if(this.props.coachProgram.get("advisorSource") === null) {
                        return(
                            <ContactSourcedByFirm
                                coachProgram={this.props.coachProgram.toJS()}
                                scheduleSession={this.scheduleSession}
                                generalSessions={this.state.generalSessions}
                            />
                        )
                    // } else {
                    //     return(
                    //         <ContactSourcedByAdvisor
                    //             coachProgram={this.props.coachProgram.toJS()}
                    //             requestMoreSessionsFromAdvisor={this.requestMoreSessionsFromAdvisor}
                    //             scheduleSession={this.scheduleSession}
                    //             generalSessions={this.state.generalSessions}
                    //         />
                    //     )
                    // }
                } else {
                    let coachProgramAdj = {...this.props.coachProgram.toJS(), "coachSource": {"coachId": this.props.user.get("userId"), "first": this.props.user.get("first"), "last": this.props.user.get("last"), "photo": this.props.user.get("photo")}};
                    return(
                        <ContactSourcedByCoachOrWillow
                            coachProgram={this.props.contact.get("referralSource") === "willow" ? this.props.coachProgram.toJS() : coachProgramAdj}
                            emailPurchaseLink={this.emailPurchaseLink}
                            scheduleSession={this.scheduleSession}
                            giftFreeSessions={this.giftFreeSessions}
                            coachName={this.props.user.get("first")+" "+this.props.user.get("last")}
                            generalSessions={this.state.generalSessions}
                        />
                    )
                }
            }
        }
    }
}

const mapStateToProps = state => ({
    allCoaches: state.enterprise.team.coaching.get("coachesAssignedToWMC"),
    allCoachesFetched: state.enterprise.team.coaching.get("coachesAssignedToWMCFetched"),
    coachesAssigned: state.enterprise.team.coaching.get("coachesAssignedToProspect"),
    coachProgram: state.enterprise.team.coaching.get("coachProgram"),
    client: state.enterprise.clientsDetail.get("client"),
    contact: state.enterprise.contacts.get("contactsOne"),
    prospectsOne: state.enterprise.prospects.get("prospectsOne"),
    clientsOne: state.enterprise.clientsList.get("clientsOne"),
    stages: state.enterprise.prospects.get("prospectStages"),
    isWMC: state.common.wmc.get("isWMC"),
    user: state.common.user,
});
const mapDispatchToProps = dispatch => ({
    tryGetCoachProgramCoachProspect: (prospectId, storeOrReturn) => dispatch(tryGetCoachProgramCoachProspect(prospectId, storeOrReturn)),
    tryGetCoachProgramWMCProspect: (prospectId, storeOrReturn) => dispatch(tryGetCoachProgramWMCProspect(prospectId, storeOrReturn)),
    tryGetCoachesAssignedToWMC: () => dispatch(tryGetCoachesAssignedToWMC()),
    tryGetCoachesAssignedToProspect: (prospectId, storeOrReturn) => dispatch(tryGetCoachesAssignedToProspect(prospectId, storeOrReturn)),
    tryPostUpdateProspectsSessions: (coachId, sessionsDelta, consumerId, prospectId) => dispatch(tryPostUpdateProspectsSessions(coachId, sessionsDelta, consumerId, prospectId)),
    tryPostAssignCoachToProspect: (coachId, numSessions, coachName, consumerId, prospectId, prospectStatus) => dispatch(tryPostAssignCoachToProspect(coachId, numSessions, coachName, consumerId, prospectId, prospectStatus)),
    tryCreateProspect: (data) => dispatch(tryCreateProspect(data)),
    setContactsOne: (contact) => dispatch(setContactsOne(contact)),
    tryGetOneProspect: (prospectId, storeOrReturn) => dispatch(tryGetOneProspect(prospectId, storeOrReturn)),
    tryGetOneClient: (prospectId, storeOrReturn) => dispatch(tryGetOneClient(prospectId, storeOrReturn)),
    setClientsDetailSelected: (client) => dispatch(setClientsDetailSelected(client)),
    tryGetOneContact: (contactId, storeOrReturn) => dispatch(tryGetOneContact(contactId, storeOrReturn)),
    setMessagesEmailOpen: (open, recipientId, recipientType, recipientFirstName, recipientLastName, isReply, subject, content) => dispatch(setMessagesEmailOpen(open, recipientId, recipientType, recipientFirstName, recipientLastName, isReply, subject, content)),
    setSessionsModal: (status, session, action, who, details, guests, eventApi) => dispatch(setSessionsModal(status, session, action, who, details, guests, eventApi)),
    setCoachModalStatus: (status, coach) => dispatch(setCoachModalStatus(status, coach)),
    setClientModalStatus: (status, client) => dispatch(setClientModalStatus(status, client)),
    tryGetCoachClientPurchaseLink: (consumerId) => dispatch(tryGetCoachClientPurchaseLink(consumerId)),
    tryGetClientGeneralSessions: (prospectId) => dispatch(tryGetClientGeneralSessions(prospectId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactCoaching));
