import React from "react";
import styled from "styled-components";

/* Components */
import {FontSubtitle21, FontSubtitle38To30} from "../../../../common/components/fonts";
import {colors} from "../../../../common/components/colors";

const NavTop = styled.div`
    background-color: ${colors.white};
    width: 100%;
    padding: 30px 0px;
    border-bottom: 1px solid ${colors.border100};
    ${props => props.alignCenter !== true} {text-align: center;}
    @media screen and (max-width: 1088px) {
        width: 100%;
        padding: 23px 0px 23px 0px;
    }
    @media screen and (max-width: 748px) {
        display: none;
    }
`;
const NavTopMobile = styled.div`
    display: none;
    @media screen and (max-width: 748px) {
        display: block;
        background-color: ${colors.white};
        padding: 18px 30px 18px 30px;
        border-bottom: 1px solid ${colors.border100};
        ${props => props.alignCenter !== true} {text-align: center;}
    }
`;

export const ConsumerNavTop = ({ title, alignCenter }) => (
    <>
        <NavTop alignCenter={alignCenter}><FontSubtitle38To30>{title}</FontSubtitle38To30></NavTop>
        <NavTopMobile alignCenter={alignCenter}><FontSubtitle21>{title}</FontSubtitle21></NavTopMobile>
    </>
);
