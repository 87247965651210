import { combineReducers } from 'redux';

/* Common */
import iframeSignupMatchesReducer from "./store/match";

const iframeSignupMatchReducer = combineReducers({
    signupMatch: iframeSignupMatchesReducer,
});

export default iframeSignupMatchReducer;
