import React from 'react';
import {connect} from 'react-redux';
import ReactQuill from "react-quill";
import styled from 'styled-components';

/* Components */
import {Container, Header, Name, SubName} from "../components/events";
import {FontTitle21, FontBody16} from "../../../common/components/fonts";
import {QuillSlimNoHeight} from "../../../common/components/richtexteditor";
import {colors} from "../../../common/components/colors";
import {LogoLoading} from "../../../common/components/loading";
import {InlineBtn, CTAs, Inline, Icon16} from '../../shop/components/header';
import { Mail, Video, Layers } from 'react-feather';
import {ButtonPrimary, ButtonInactiveLight} from '../../../common/components/buttons';

/* Containers */
import ListingOne from './listingone';
import {Message} from "../../../common/components/messages";
import { CloseOutlined } from '@ant-design/icons';

export const BtnSpacing = styled.div`
    margin-right: 15px;
`;

const BackTop = styled.div`
    color: ${colors.secondary70};
    padding: 5px 10px;
    display: block;
    cursor: pointer;
    position: relative;
    z-index: 25;
    margin-top: -50px;
    @media screen and (max-width: 748px) {
        margin-left: 60px;
    }
    @media screen and (max-width: 460px) {
        margin-left: 0px;
    }
    text-align: right;
`;

class Listings extends React.Component {
    static defaultProps = {
        useCheckoutFunction: null
    };

    state={"isLoading": true, "title": "", "descriptionComponent": "", "hasDescription": false};

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.title !== this.props.title || prevProps.description !== this.props.description) {
            this.initTitle();
            this.initDescription();
        }
        if(prevProps.listingsAll !== this.props.listingsAll) {
            return this.initListingsAll();
        }
    }

    init = async () => {
        await this.initTitle();
        await this.initDescription();
        await this.initListingsAll();
        this.setState({"isLoading": false});
    };

    initTitle = async () => {
        let title = this.props.title;
        this.setState({
            "title": (title === null || title === undefined || title === "") ? null : title,
        })
    };

    initDescription = async () => {
        let description = this.props.description;
        let descriptionAdj = (description === '<p><br></p>' || description === null || description === undefined || description === "") ? "" : description;
        await this.setState({"descriptionComponent": ""});
        this.setState({
            "hasDescription": !(description === '<p><br></p>' || description === null || description === undefined || description === ""),
            "descriptionComponent": <ReactQuill
                readOnly={true}
                theme={null}
                value={descriptionAdj}
                bounds={'.app'}
            />
        })
    };

    initListingsAll = async () => {
        this.setState({"listingsAll": this.props.listingsAll})
    };
    
    render() {
        if(this.state.isLoading) {
            return(<LogoLoading />)
        } else {
            return(
                <Container bigTop={this.props.entryPoint !== "/schedule"}>
                    {this.props.entryPoint === "/schedule" && <BackTop onClick={() => {return(this.props.close())}}><CloseOutlined/>&nbsp;Close</BackTop>}
                    {(this.state.title !== null || this.state.hasDescription || this.props.profile.get("profileShowEmailer") || this.props.profile.get("profileShowScheduler")) &&
                        <Header>
                            <Name>
                                {this.state.title !== null &&
                                    <FontTitle21>{this.state.title}</FontTitle21>
                                }
                                {this.state.hasDescription &&
                                    <SubName>
                                        <QuillSlimNoHeight bodyBorderColor={colors.white}>
                                            {this.state.descriptionComponent}
                                        </QuillSlimNoHeight>
                                    </SubName>
                                }
                                {((this.props.profile.get("profileShowEmailer") || this.props.profile.get("profileShowScheduler")) && this.props.entryPoint !== "/schedule") &&
                                <CTAs>
                                    {this.props.profile.get("profileShowEmailer") &&
                                        <InlineBtn onClick={() => {return this.props.changeShowEmailer(true)}}>
                                            {this.props.profile.get("profileShowScheduler")
                                                ? <ButtonInactiveLight submit={false} label={<><Icon16><Mail /></Icon16><Inline>&nbsp;&nbsp;Message</Inline></>} />
                                                : <ButtonPrimary submit={false} label={<><Icon16><Mail /></Icon16><Inline>&nbsp;&nbsp;Message</Inline></>} />
                                            }
                                        </InlineBtn>
                                    }
                                    {(this.props.profile.get("profileShowEmailer") && this.props.profile.get("profileShowScheduler")) &&
                                        <Inline><BtnSpacing/></Inline>
                                    }
                                    {this.props.profile.get("profileShowScheduler") &&
                                        <InlineBtn onClick={() => {return this.props.changeShowScheduler(true)}}><ButtonPrimary submit={false} label={<><Icon16><Video /></Icon16><Inline>&nbsp;&nbsp;Schedule Meeting</Inline></>} /></InlineBtn>
                                    }
                                </CTAs>
                                }
                            </Name>
                        </Header>
                    }
                    {this.state.listingsAll.size > 0 &&
                        <>
                            {this.state.listingsAll.map(l => {
                                if (l.get("status").toLowerCase() === "active") {
                                    return (
                                        <ListingOne
                                            key={l.get("id")}
                                            listing={l}
                                            selectPlanId={this.props.selectPlanId}
                                            isPreview={this.props.isPreview}
                                            forceImagesVertical={this.props.forceImagesVertical}
                                            useCheckoutFunction={this.props.useCheckoutFunction}
                                        />
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </>
                    }
                    {this.state.listingsAll.size === 0 &&
                        <><br /><Message text={"No products available"} /></>
                    }
                </Container>
            )
        }
    }
}

const mapStateToProps = state => ({
    listingsAll: state.iframe.listings.listings.get("listingsAll"),
    profile: state.iframe.scheduler.profile.get("profile")
});

export default connect(mapStateToProps, null)(Listings);
