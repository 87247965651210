import React from 'react';
import styled from 'styled-components';
import validator from "validator";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {v4 as uuid4} from 'uuid';

/* Components */
import {CheckboxWithLabel, DropdownInput, LinkStyled, PasswordInput, PhoneInput, TextInput} from "../../../common/components/inputs";
import {ButtonInactive, ButtonPrimary, ButtonTertiary} from "../../../common/components/buttons";
import {colors} from "../../../common/components/colors";
import {FontBody16, FontBody14, FontHeader16, FontBody12, FontHeader18} from "../../../common/components/fonts";

/* Utils */
import {timeZoneOptions, timeZoneOptionsByRegion} from "../../../common/utils/timezone2";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

/* Middleware */
import {trySignUpTestExists, tryRegisterUser} from "../middleware/auth";
import {trySignUpAssignAuthenticate} from '../../signupassign/middleware/auth';
import {tryAuthOnly} from "../../../routes/middleware/auth";

/* Static */
import ff50matching from '../static/ff50matching';

const SaveSection = styled.div`
    border-top: 1px solid ${colors.border100};
    text-align: left;
    padding-top: 20px;
    margin-top: 50px;
    margin-bottom: 35px;
`;
const InlineBlock = styled.div`
    display: inline-block;
`;
const ButtonRight = styled.div`
    margin-right: 10px;
`;
const SubmitInputWithValidation = ({ isChanged, isValid, isSaving, label }) => {
    if(isChanged && isValid) {
        if(isSaving) {
            return (<ButtonRight><ButtonInactive canSubmit={true} label={"Saving"} /></ButtonRight>)
        } else {
            return (<ButtonRight><ButtonPrimary canSubmit={true} label={label}/></ButtonRight>)
        }
    } else {
        return(<ButtonRight><ButtonInactive canSubmit={true} label={label}/></ButtonRight>)
    }
};

export const Left = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 150px;
    @media screen and (max-width: 460px) {
        width: 100%;
        display: block;
    }
`;
export const Right = styled.div`
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 150px);
    text-align: right;
    margin-top: 8px;
    color: ${colors.primary70};
    @media screen and (max-width: 460px) {
        width: 100%;
        display: block;
        text-align: left;
    }
`;
export const LoginBtn = styled.div`
    color: ${colors.action100};
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
`;

const Container = styled.div`
    max-width: 768px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 0px auto 0px auto;
`;

const FitContent = styled.div`
    width: fit-content;
`;

const Red = styled(FontBody14)`
    color: ${colors.alert100};
`;

const DisclosureCheckbox = styled(FontBody12)`
    color: ${colors.white};
    & a {
        color: ${colors.white};
    }
`;
const GrayLink = styled.div`
    color: ${colors.secondary70};
    display: inline-block;
    vertical-align: top;
    text-decoration: underline;
    font-size: 10px;
`;

const Disclosure = styled.div`
    margin: 10px 0px -40px 0px;
    color: ${colors.secondary70};
`;

const EmailDisclosure = styled(FontBody12)`
    margin-left: 45px;
    color: ${colors.white};
    margin-top: -15px;
    margin-bottom: 15px;
`;

const PasswordDisclosure = styled(FontBody12)`
    margin-left: 45px;
    color: ${colors.white};
    margin-top: -15px;
`;

const RegCode = styled(FontBody16)`
    width: fit-content;
    color: ${colors.action100};
    padding-top: 20px;
    margin-bottom: 10px;
    text-decoration: underline;
    margin-left: 10px;
    cursor: pointer;
`;

export const Half = styled.div`
    display: inline-block;
    vertical-align: top;
    ${props => props.side !== "full"} {
        width: 100%;
    }
    ${props => props.side !== "left"} {
        width: 49%;
        margin-right: 2%;
    }
    ${props => props.side !== "right"} {
        width: 49%;
    }
    & > div {
        margin-bottom: 0px;
    }
    @media screen and (max-width: 460px) {
        width: 100%;
        display: block;
    }
`;

export const HalfAlign = styled.div`
    display: inline-block;
    vertical-align: top;
    ${props => props.side !== "left"} {
        width: 49%;
        text-align: left;
        margin-right: 2%;
    }
    ${props => props.side !== "right"} {
        width: 49%;
        text-align: right;
    }
    margin-bottom: -15px;
`;

const OnlySubmit = styled.div`
    margin: 50px auto 0px auto;
    text-align: center;
    & button > div {
        min-width: 160px;
    }
`;

const PasswordBar = styled.div`
    height: 7px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 3px;
    margin-bottom: 15px;
`;

const PasswordSection = styled.div`
    ${props => props.valid !== true} {
        background-color: ${colors.action100};
    }
    ${props => props.valid !== false} {
        background-color: ${colors.backgroundChatBubble};
    }
    display: inline-flex;
    width: 100%;
`;

const formStyleSystem = {
    showBackBtn: false,
    showTitle: true,
    showLoginBtn: true,
    submitLabel: "Confirm",
    inputsNamesHorizontal: true,
    inputsFirstLabel: "First name*",
    inputsFirstPlaceholder: "First name",
    inputsFirstWarning: "Required",
    inputsLastLabel: "Last name*",
    inputsLastPlaceholder: "Last name",
    inputsLastWarning: "Required",
    inputsEmailLabel: "Email*",
    inputsEmailPlaceholder: "Email",
    inputsEmailWarning: "Enter a valid email address",
    inputsPhoneShow: true,
    inputsPhoneLabel: "Mobile phone",
    inputsPhonePlaceholder: "(###) ###-####",
    inputsPhoneWarning: "",
    inputsPasswordShow: false,
    inputsPasswordLabel: "Password*",
    inputsPasswordPlaceholder: "Password",
    inputsPasswordWarning: "Required",
    inputsHrCodeLabel: "Registration Code",
    inputsHrCodePlaceholder: "Registration code (optional)",
    inputsHrCodeWarning: "",
    inputsContainersStyles: {},
    inputsStyles: {},
    containerStyles: {},
    disclosureCheckbox: false,
    alwaysAllowSubmit: false,
    onlyShowSubmit: false,
};

const pageStyleSystem = {
    showBackBtn: false,
    showTitle: false,
    showLoginBtn: false,
    submitLabel: "Register",
    inputsNamesHorizontal: false,
    inputsFirstLabel: "",
    inputsFirstPlaceholder: "First name",
    inputsFirstWarning: "",
    inputsLastLabel: "",
    inputsLastPlaceholder: "Last name",
    inputsLastWarning: "",
    inputsEmailLabel: "",
    inputsEmailPlaceholder: "Email address",
    inputsEmailWarning: "",
    inputsPhoneShow: false,
    inputsPhoneLabel: "",
    inputsPhonePlaceholder: "",
    inputsPhoneWarning: "",
    inputsPasswordShow: true,
    inputsPasswordLabel: "",
    inputsPasswordPlaceholder: "Password",
    inputsPasswordWarning: "",
    inputsHrCodeLabel: "",
    inputsHrCodePlaceholder: "Registration code (optional)",
    inputsHrCodeWarning: "",
    containerStyles: {backgroundColor: colors.backgroundColor2, borderRadius: "20px", padding: "30px 45px", width: "calc(100% - 90px)"},
    inputsContainersStyles: {margin: "0px 0px 20px 0px"},
    inputsStyles: {borderRadius: "25px", lineHeight: "36px", backgroundColor: colors.white},
    disclosureCheckbox: true,
    alwaysAllowSubmit: false, // true stops local validation
    onlyShowSubmit: true,
};

class SignUp extends React.Component {
    static defaultProps = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        hrCode: "",
        wmcEmployeeId: null,
        signUpError: "",
        signUpErrorLogin: null,
        signUpHasAccount: false,
        trySignUpAssignAuthenticate: () => {},
        viewLogin: () => {},
        tryAuthOnly: () => {},
        close: () => {},
        back: () => {},
        signUpPageOnSubmit: null,
        signUpFormOnSubmit: () => {},
        signUpSubmitLabel: "Register",
        hidePrefilledInputs: true,
        showhrCode: true,
        showLastName: true,
        showPassword: true,
        showAttstationNoCheckbox: false,
        attestationDisclosure: "",
        signupIsAdvertising: false
    };
    
    state={
        ...formStyleSystem,
        "firstName": "", "firstNameValid": true, "firstNamePreset": false,
        "lastName": "", "lastNameValid": true, "lastNamePreset": false,
        "email": "", "emailValid": true, "emailPreset": false,
        "phone": "", "phoneValid": true, "phonePreset": false,
        "hrCode": "", "hrCodeValid": true, "hrCodePreset": false, // hrCodePreset
        "disclosureCheckStatus": false, "disclosureValid": true,
        "password": "", "passwordValid": true, "passwordSecurityNum": 0,
        "wmcEmployeeId": null,
        "showRegistrationCode": false,
        // "timeZone": '',
        "isValid": true,
        "isChanged": false,
        "isSaving": false,
        "isSaved": true,
        "isLoading": true,
        "showError": false,
        "showhrCode": true
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.firstName !== prevProps.firstName || this.props.lastName !== prevProps.lastName ||
            this.props.email !== prevProps.email || this.props.phone !== prevProps.phone) {
            return this.init();
        }
        if(prevProps.signUpError !== this.props.signUpError && this.props.signUpError !== null) {
            this.setState({"signUpError": this.props.signUpError, 'showError': true});
            this.timeout = setTimeout(() => {
                this.setState({'showError': false})
            }, 5000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    init = async () => {
        await this.setState({
            ...(this.props.styleSystem === "page" ? pageStyleSystem : formStyleSystem),
            "firstName": this.props.firstName,
            "lastName": this.props.lastName,
            "email": this.props.email,
            "phone": this.props.phone,
            "firstNamePreset": this.props.firstName !== "",
            "lastNamePreset": this.props.lastName !== "",
            "emailPreset": this.props.email !== "",
            "phonePreset": this.props.phone !== "",
            "showLoginBtn": this.props.showLoginBtn,
            "hrCode": (this.props.hrCode !== "" && this.props.hrCode !== null) ? this.props.hrCode : "",
            "hrCodePreset": (this.props.hrCode !== "" && this.props.hrCode !== null),
            "wmcEmployeeId": (this.props.wmcEmployeeId !== "" && this.props.wmcEmployeeId !== null) ? this.props.wmcEmployeeId : null,
            "submitLabel": this.props.signUpSubmitLabel,
            "isLoading": false,
            "showhrCode": this.props.showhrCode,
            "inputsPasswordShow": this.props.inputsPasswordShow,
        });
        this.initValid();
    };

    initValid = () => {
        const isFirstNameValid = (!validator.isEmpty(this.state.firstName) && this.state.firstName !== '');
        const isLastNameValid = (!validator.isEmpty(this.state.lastName) && this.state.lastName !== '');
        const isEmailValid = validator.isEmail(this.state.email);
        const isPhoneValid = validator.isMobilePhone(this.state.phone, 'en-US') || validator.isEmpty(this.state.phone);
        const isPasswordValid = (this.state.passwordValid && this.state.password !== "");
        if(isFirstNameValid && isLastNameValid && isEmailValid && isPasswordValid/*&& isPhoneValid*/) {
            this.setState({'isValid': true, 'isChanged': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    setValid = (changed) => {
        const isFirstNameValid = (!validator.isEmpty(this.state.firstName) && this.state.firstName !== '');
        const isLastNameValid = (!validator.isEmpty(this.state.lastName) && this.state.lastName !== '');
        const isEmailValid = validator.isEmail(this.state.email);
        const isPhoneValid = validator.isMobilePhone(this.state.phone, 'en-US') || validator.isEmpty(this.state.phone);
        const isDisclosureValid = this.state.disclosureCheckStatus;
        const isPasswordValid = (!validator.isEmpty(this.state.password) && this.state.password !== '');
        if(
            (isFirstNameValid && isEmailValid /*&& isPhoneValid*/) &&
            (!this.props.showLastName ? true : isLastNameValid) &&
            (this.props.showAttstationNoCheckbox ? true : (this.state.disclosureCheckbox ? this.state.disclosureCheckStatus : true)) &&
            (!this.props.showPassword ? true : (this.state.inputsPasswordShow ? isPasswordValid : true))
        ) {
            this.setState({
                "firstNameValid": changed === "firstName" ? isFirstNameValid : this.state.firstNameValid,
                "lastNameValid": changed === "lastName" ? isLastNameValid : this.state.lastNameValid,
                "emailValid": changed === "email" ? isEmailValid : this.state.emailValid,
                "phoneValid": true, // changed === "phone" ? isPhoneValid : this.state.phoneValid,
                "disclosureValid": changed === "disclosure" ? isDisclosureValid : this.state.disclosureValid,
                "passwordValid": changed === "password" ? isPasswordValid : this.state.passwordValid,
                'isValid': true
            })
        } else {
            this.setState({
                "firstNameValid": changed === "firstName" ? isFirstNameValid : this.state.firstNameValid,
                "lastNameValid": changed === "lastName" ? isLastNameValid : this.state.lastNameValid,
                "emailValid": changed === "email" ? isEmailValid : this.state.emailValid,
                "phoneValid": true, // changed === "phone" ? isPhoneValid : this.state.phoneValid,
                "disclosureValid": changed === "disclosure" ? isDisclosureValid : this.state.disclosureValid,
                "passwordValid": changed === "password" ? isPasswordValid : this.state.passwordValid,
                'isValid': false
            })
        }
    };

    handleChangeFirstName = async e => {
        await this.setState({
            'firstName': e.target.value,
            'firstNameValid': (!validator.isEmpty(this.state.firstName) && this.state.firstName !== ''),
            'isChanged': true
        });
        this.setValid("firstName");
    };

    handleChangeLastName = async e => {
        await this.setState({
            'lastName': e.target.value,
            'lastNameValid': (!validator.isEmpty(this.state.lastName) && this.state.lastName !== ''),
            'isChanged': true
        });
        this.setValid("lastName");
    };

    handleChangeEmail = async e => {
        await this.setState({
            'email': e.target.value,
            'emailValid': validator.isEmail(this.state.email),
            'isChanged': true
        });
        this.setValid("email");
    };

    handleChangePhone = async e => {
        await this.setState({
            'phone': e.target.value.replace('(', '').replace(')', '').replace(' ', '').replace('-', ''),
            'phoneValid': validator.isMobilePhone(this.state.phone, 'en-US') || validator.isEmpty(this.state.phone),
            'isChanged': true
        });
        this.setValid("phone");
    };

    handleChangeDisclosureCheckStatus = async e => {
        await this.setState({
            "disclosureCheckStatus": !this.state.disclosureCheckStatus,
            "disclosureValid": this.state.disclosureCheckStatus,
            'isChanged': true
        });
        this.setValid("disclosure");
    };

    handleChangePassword = async e => {
        const newPass = e.target.value;
        await this.setState({
            'password': newPass,
            'passwordValid': (!validator.isEmpty(newPass) && newPass !== ''),
            'passwordSecurityNum': await this.textPasswordSecurityNum(newPass),
            'isChanged': true,
        });
        this.setValid("password");
    };

    // 
    handleChangeHrCode = async e => {
        const code = e.target.value;
        await this.setState({
            'hrCode': code,
            'isChanged': true,
        });
        this.setValid("hrCode");
    };

    handleShowRegistrationCode = () => {
        this.setState({"showRegistrationCode": !this.state.showRegistrationCode})
    }

    textPasswordSecurityNum = async (password) => {
        let symbolFormat = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        let hasSymbol = symbolFormat.test(password);
        let empty = (validator.isEmpty(password) || password === '');
        let charValBelow6 = (password.length < 7);
        let charVal8 = (password.length > 7);
        let numValid = !validator.isAlpha(password);
        let letterValid = !validator.isNumeric(password);
        if(empty) {
            return 0
        } else if(charValBelow6) {
            return (1)
        } else {
            return ((hasSymbol === true ? 1 : 0) + (charVal8 === true ? 1 : 0) + (numValid === true ? 1 : 0) + (letterValid === true ? 1 : 0));
        }
    };

    // handleChangeTimeZone = e => {
    //     this.setState({'timeZone': e.value});
    //     this.setState({'isChanged': true});
    // };

    handleSubmit = async e => {
        e.preventDefault();

        // Fact Finding 50 Temp Start
        const isInFF50Matching = ff50matching.filter(f => {return(f.email === this.state.email.toLowerCase())});
        if(isInFF50Matching.length > 0) {
            const match = await this.props.trySignUpAssignAuthenticate(isInFF50Matching[0].prospectId, 27, isInFF50Matching[0].token);
            this.props.close();
        } else {
            const region = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const timeZone = timeZoneOptionsByRegion(region);
            const code = this.state.hrCode === "" ? null : this.state.hrCode
            if(this.props.styleSystem === "page") {
                await this.props.trySignUpTestExists(this.state.email.toLowerCase(), null);
                if (this.props.iframeAuthCheckHasAccountError !== null) {
                    await this.setState({
                        "signUpError": <>Account already exists. {this.props.signUpErrorLogin}</>,
                        'showError': true
                    });
                    this.timeout = setTimeout(() => {this.setState({'showError': false})}, 5000);
                } else {
                    const lastName = this.props.showLastName ? this.state.lastName : "AdvisorMatching";
                    const password = this.props.showPassword ? this.state.password : uuid4();
                    const register = await this.props.tryRegisterUser(this.state.firstName, lastName, this.state.email.toLowerCase(), this.state.phone, timeZone, password, code, this.state.wmcEmployeeId, this.props.signupIsAdvertising);
                    if(register.error === null) {
                        await this.setState({"signUpError": null});
                        // await this.props.trySendSignUpSuccessEmail(this.props.email);
                        if(this.props.signUpPageOnSubmit === null) {
                            await this.props.tryAuthOnly(null, true, true);
                            this.props.history.push("/verify");
                        } else {
                            await this.props.tryAuthOnly(null, true, true);
                            this.props.signUpPageOnSubmit();
                        }
                    } else {
                        await this.setState({
                            "signUpError": <>{register.error} {this.props.signUpErrorLogin}</>,
                            'showError': true
                        });
                        this.timeout = setTimeout(() => {this.setState({'showError': false})}, 5000);
                    }
                }
            } else {
                await this.props.signUpFormOnSubmit({
                    "firstName": this.state.firstName,
                    "lastName": this.state.lastName,
                    "email": this.state.email.toLowerCase(),
                    "phone": this.state.phone,
                    "timeZone": timeZone,
                    "password": this.state.password,
                    "hrCode": code,
                    "wmcEmployeeId": this.state.wmcEmployeeId
                });
            }
        }
        // Fact Finding 50 Temp End

        // Without fact finding fifty below
        // const region = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // const timeZone = timeZoneOptionsByRegion(region);
        // await this.props.signUpFormOnSubmit({
        //     "firstName": this.state.firstName,
        //     "lastName": this.state.lastName,
        //     "email": this.state.email,
        //     "phone": this.state.phone,
        //     "timeZone": timeZone
        // });
    };

    render() {
        return(
            <Container style={this.state.containerStyles}>
                <form method={"post"} onSubmit={this.handleSubmit}>
                    {(this.state.showTitle || this.props.showCreateAccountLink) &&
                        <div>
                            <Left>{this.state.showTitle && <FontHeader18>Your Information</FontHeader18>}</Left>
                            {this.state.showLoginBtn && <Right><FontBody16>Have an account?&nbsp;<LoginBtn onClick={this.props.viewLogin}><FontHeader16>Log in</FontHeader16></LoginBtn></FontBody16></Right>}
                        </div>
                    }
                    <div>
                        {(!this.state.firstNamePreset || !this.props.hidePrefilledInputs) &&
                            <Half side={this.state.inputsNamesHorizontal ? (this.state.lastNamePreset ? "full" : "left") : "full"}>
                                <TextInput
                                    title={this.state.inputsFirstLabel}
                                    valid={this.state.firstNameValid}
                                    warning={this.state.inputsFirstWarning}
                                    id={"firstName"}
                                    onChange={this.handleChangeFirstName}
                                    placeholder={this.state.inputsFirstPlaceholder}
                                    value={this.state.firstName}
                                    containerStyles={this.state.inputsContainersStyles}
                                    inputStyles={this.state.inputsStyles}
                                />
                            </Half>
                        }
                        {(this.props.showLastName && (!this.state.lastNamePreset || !this.props.hidePrefilledInputs)) &&
                            <Half side={this.state.inputsNamesHorizontal ? (this.state.firstNamePreset ? "full" : "right") : "full"} >
                                <TextInput
                                    title={this.state.inputsLastLabel}
                                    valid={this.state.lastNameValid}
                                    warning={this.state.inputsLastWarning}
                                    id={"lastName"}
                                    onChange={this.handleChangeLastName}
                                    placeholder={this.state.inputsLastPlaceholder}
                                    value={this.state.lastName}
                                    containerStyles={this.state.inputsContainersStyles}
                                    inputStyles={this.state.inputsStyles}
                                />
                            </Half>
                        }
                    </div>
                    {(!this.state.emailPreset || !this.props.hidePrefilledInputs) &&
                        <>
                            <TextInput
                                title={this.state.inputsEmailLabel}
                                valid={this.state.emailValid}
                                warning={this.state.inputsEmailWarning}
                                id={"email"}
                                onChange={this.handleChangeEmail}
                                placeholder={this.state.inputsEmailPlaceholder}
                                value={this.state.email}
                                containerStyles={this.state.inputsContainersStyles}
                                inputStyles={this.state.inputsStyles}
                            />
                            {/*<EmailDisclosure>We recommend using your personal email address.</EmailDisclosure>*/}
                        </>
                    }
                    {(this.state.inputsPhoneShow && (!this.state.phonePreset || !this.props.hidePrefilledInputs)) &&
                        <PhoneInput
                            title={this.state.inputsPhoneLabel}
                            valid={this.state.phoneValid}
                            warning={this.state.inputsPhoneWarning}
                            id={"phone"}
                            onChange={this.handleChangePhone}
                            placeholder={this.state.inputsPhonePlaceholder}
                            value={this.state.phone}
                            options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}}
                            containerStyles={this.state.inputsContainersStyles}
                            inputStyles={this.state.inputsStyles}
                        />
                    }
                    {/*{<DropdownInput*/}
                    {/*    title={"Time zone"}*/}
                    {/*    options={timeZoneOptions}*/}
                    {/*    id={'timeZone'}*/}
                    {/*    onChange={this.handleChangeTimeZone}*/}
                    {/*    placeholder={'Time Zone'}*/}
                    {/*    value={this.state.timeZone}*/}
                    {/*/>}*/}
                    {(this.props.showPassword && this.state.inputsPasswordShow) &&
                        <div className={"password-input"}>
                            <PasswordInput
                                title={this.state.inputsPasswordLabel}
                                valid={this.state.passwordValid}
                                warning={this.state.inputsPasswordWarning}
                                id={"password"}
                                onChange={this.handleChangePassword}
                                placeholder={this.state.inputsPasswordPlaceholder}
                                value={this.state.password}
                                containerStyles={this.state.inputsContainersStyles}
                                inputStyles={this.state.inputsStyles}
                            />
                            <PasswordDisclosure>
                                {(this.state.passwordSecurityNum > 0 && this.state.passwordSecurityNum <= 1) && <>Weak password</>}
                                {(this.state.passwordSecurityNum > 1 && this.state.passwordSecurityNum <= 2) && <>So-so password</>}
                                {(this.state.passwordSecurityNum > 2 && this.state.passwordSecurityNum <= 3) && <>Good password</>}
                                {(this.state.passwordSecurityNum > 3) && <>Great password!</>}
                            </PasswordDisclosure>
                            {/*<PasswordBar>*/}
                            {/*    <PasswordSection valid={(this.state.passwordSecurityNum > 0)} />*/}
                            {/*    <PasswordSection valid={(this.state.passwordSecurityNum > 1)} />*/}
                            {/*    <PasswordSection valid={(this.state.passwordSecurityNum > 2)} />*/}
                            {/*    <PasswordSection valid={(this.state.passwordSecurityNum > 3)} />*/}
                            {/*</PasswordBar>*/}
                        </div>
                    }
                    {this.state.showhrCode && !this.state.hrCodePreset &&
                        <>
                            {this.state.showRegistrationCode
                                ? <>
                                    <br />
                                    <TextInput
                                        title={this.state.inputsHrCodeLabel}
                                        valid={this.state.hrCodeValid}
                                        warning={this.state.inputsHrCodeWarning}
                                        id={"hrcode"}
                                        onChange={this.handleChangeHrCode}
                                        placeholder={this.state.inputsHrCodePlaceholder}
                                        value={this.state.hrCode}
                                        containerStyles={this.state.inputsContainersStyles}
                                        inputStyles={this.state.inputsStyles}
                                    />
                                    {/*<EmailDisclosure>Optional. Leave blank if you do not have one.</EmailDisclosure>*/}
                                </>
                                : <RegCode onClick={this.handleShowRegistrationCode}>Add optional registration code</RegCode>
                            }
                        </>
                    }
                    {this.state.showError && <Red>{this.state.signUpError}</Red>}
                    <br />
                    {this.props.showAttstationNoCheckbox
                        ? this.props.attestationDisclosure
                        : <>
                                {this.state.disclosureCheckbox ?
                                    <CheckboxWithLabel
                                        action={this.handleChangeDisclosureCheckStatus}
                                        id={"disclosureCheckbox"}
                                        label={<DisclosureCheckbox className={"disclosureLabel"}>By checking this box, I confirm I have read and agree to the <a href={"https://"+env.URL.DOMAIN+"/privacy-policy/"} target={"_blank"}><b>Privacy Policy</b></a> and <a target={"_blank"} href={"https://"+env.URL.DOMAIN+"/terms-of-use/"}><b>Terms of Use</b></a>.</DisclosureCheckbox>}
                                        checked={this.state.disclosureCheckStatus}
                                        styleLabel={{paddingTop: "0px", paddingLeft: "20px", width: "calc(100% - 45px)"}}
                                    />
                                    :
                                    <Disclosure>
                                        {this.props.signUpHasAccount
                                            ? <FontBody12>* Required</FontBody12>
                                            : <FontBody12>* Required. By confirming you agree to our <LinkStyled target={"_blank"} href={"https://"+env.URL.DOMAIN+"/terms-of-use/"}><GrayLink>Terms of Use</GrayLink></LinkStyled> and <LinkStyled href={"https://"+env.URL.DOMAIN+"/privacy-policy/"} target={"_blank"}><GrayLink>Privacy Policy</GrayLink></LinkStyled>.</FontBody12>
                                        }
                                    </Disclosure>
                                }
                            </>
                    }
                    {this.state.onlyShowSubmit
                        ?
                            <OnlySubmit>
                                <SubmitInputWithValidation
                                    label={this.state.submitLabel}
                                    isChanged={this.state.alwaysAllowSubmit ? true : this.state.isChanged}
                                    isValid={this.state.alwaysAllowSubmit ? true : this.state.isValid}
                                    isSaving={this.state.isSaving}
                                />
                            </OnlySubmit>
                        :
                            <SaveSection>
                                <HalfAlign side={"left"}>
                                    {this.state.showBackBtn && <InlineBlock><FitContent onClick={this.props.back}><ButtonTertiary canSubmit={false} label={"Back"} /></FitContent></InlineBlock>}
                                </HalfAlign>
                                <HalfAlign side={"right"}>
                                    <InlineBlock>
                                        <SubmitInputWithValidation
                                            label={this.state.submitLabel}
                                            isChanged={this.state.alwaysAllowSubmit ? true : this.state.isChanged}
                                            isValid={this.state.alwaysAllowSubmit ? true : this.state.isValid}
                                            isSaving={this.state.isSaving}
                                        />
                                    </InlineBlock>
                                </HalfAlign>
                            </SaveSection>
                    }
                </form>
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    iframeAuthCheckHasAccountError: state.iframe.signup.auth.get("iframeAuthCheckHasAccountError"),
});

const mapDispatchToProps = dispatch => ({
    trySignUpTestExists: (email, phone) => dispatch(trySignUpTestExists(email, phone)),
    tryRegisterUser: (firstName, lastName, email, phone, timeZone, password, hrCode, wmcEmployeeId) => dispatch(tryRegisterUser(firstName, lastName, email, phone, timeZone, password, hrCode, wmcEmployeeId)),
    trySignUpAssignAuthenticate: (prospectId, wmcId, token) => dispatch(trySignUpAssignAuthenticate(prospectId, wmcId, token)),
    tryAuthOnly: (email, trackNewRegistration, forceAuth) => dispatch(tryAuthOnly(email, trackNewRegistration, forceAuth)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUp));