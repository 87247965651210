import { combineReducers } from 'redux';

/* Common */
import profileReducer from './store/profile';
// import scheduleReducer from "./store/scheduler";
import authReducer from "./store/auth";
import eventsReducer from "./store/events";
import checkoutReducer from "./store/checkout";

const schedulerReducer = combineReducers({
    checkout: checkoutReducer,
    profile: profileReducer,
    auth: authReducer,
    // schedule: scheduleReducer,
    events: eventsReducer,
});

export default schedulerReducer;
