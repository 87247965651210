import {axiosReq} from "../../../utils/axios";

/* Store */
import { setNoticeCall, setNoticeCallDownloadPending, setNoticeCallDownloadComplete } from "../store/noticecall";

/* Static */
import {devNoticesSessionsData} from '../static/devdata';

export const tryGetNoticesSessions = () => {
    return async dispatch => {
        dispatch(setNoticeCallDownloadPending());
        const notices = await axiosReq('apiv1/notices/sessions', "GET", {}, devNoticesSessionsData);
        await dispatch(setNoticeCall(notices));
        return dispatch(setNoticeCallDownloadComplete());
    }
};