import {images} from '../../../common/components/images';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

export const devClientsForAdvisor = [
    {
        "prospectId":481,
        "contactId":288,
        "email":"jason.aronson+jim4@gmail.com",
        "phone":"8774455444",
        "firstName":"Jim4",
        "lastName":"Aronson",
        "isArchived":false,
        "sessionsRemaining":0
    },
    {
        "prospectId":418,
        "contactId":105,
        "email":"jason.aronson+nancy@gmail.com",
        "phone":"8572053568",
        "firstName":"Nancy",
        "lastName":"Aronson",
        "isArchived":false,
        "sessionsRemaining":1
    },
    {
        "prospectId":371,
        "contactId":5,
        "email":"jason.aronson+jim@gmail.com",
        "phone":"4133488692",
        "firstName":"Jim55",
        "lastName":"Aronson",
        "isArchived":false,
        "sessionsRemaining":2
    },
    {
        "prospectId":502,
        "contactId":312,
        "email":"jason.aronson+cjklsjdfkljdlsj@gmail.com",
        "phone":"6545465456",
        "firstName":"Jason client 7",
        "lastName":"Aronson",
        "isArchived":false,
        "sessionsRemaining":0
    },
    {
        "prospectId":544,
        "contactId":618,
        "email":"jason.aronson+test8coachemails@gmail.com",
        "phone":"4654554545",
        "firstName":"Jason",
        "lastName":"Test 8",
        "isArchived":false,
        "sessionsRemaining":2
    },
    {
        "prospectId":545,
        "contactId":619,
        "email":"jason.aronson+test945453454@gmail.com",
        "phone":"2342354345",
        "firstName":"Jason",
        "lastName":"Test 9",
        "isArchived":false,
        "sessionsRemaining":3
    }
];

export const devWMCsForCoach = [
    {
        "id":3,
        "companyName":"Willow Network, Inc. (Jason)",
        "companyLogoUrl":"https://docs."+env.URL.DOMAIN+"/Willow_logo_transparent.png",
        "welcomeAudioMessageForCallUrl":"",
        "phone":"",
        "email":"",
        "address1":"",
        "address2":"",
        "city":"",
        "state":"",
        "zipCode":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "linkedinUrl":"",
        "loadingImage":null,
        "actionColor":"",
        "contactPersonFirstName":"",
        "contactPersonLastName":"",
        "contactPersonWorkPhone":"",
        "contactPersonCellPhone":"",
        "contactPersonEmail":""
    }
];

export const devWMCAdvisorsForCoach = [
    {
        "coachId":900,
        "first":"Jason",
        "last":"Advisor 900",
        "phone":4135193519,
        "email":"jason@"+env.URL.DOMAIN,
        "photo": images.devDataTeamJasonAvatar,
        "defaultCallLengthInMinutes":30,
        "storeActive":true,
        "bio":"<p>Jason is a quant, full-stack developer, CFA Charterholder, and all-around data geek. He helped create Empirical Asset Management, a wealth management firm delivering emotion-free rules-based portfolios to investors, institutions, and advisors. At Empirical, Jason built the firm\u2019s research and trading platforms, back-office operations, managed equity portfolios including a machine learning driven multi-factor ESG strategy, and helped individuals and families achieve their financial goals.</p><p><br></p><p>Prior to Empirical, Jason was a quant working at Santander in solvency risk for the $20B commercial real estate loan portfolio. Prior to Santander, he worked in risk management and actuarial at Covery\u2019s where Jason built a product using clustering to assess the quality of insured doctors.</p><p><br></p><p>Jason holds a MS in Quantitative Finance from Bentley University and a BS in Computational Finance and Computer Science from Babson College.</p>",
        "expertCategories":[
            {
                "categoryId":2,
                "categoryName":"Financial Advisor"
            }
        ],
        "wmcId":3,
        "contactId":616
    }
];

export const devWillowRMForCoach = {
    "allCoaches":[
        {
            "coachId":35,
            "first":"Jason",
            "last":"Support 35",
            "phone":4135190010,
            "email":"jason+support@"+env.URL.DOMAIN,
            "photo": images.devDataTeamJasonAvatar,
            "defaultCallLengthInMinutes":30,
            "storeActive":false,
            "bio":"<p>a</p>",
            "expertCategories":[
                {
                    "categoryId":7,
                    "categoryName":"Willow Support"
                }
            ],
            "wmcId":null,
            "contactId":null
        }
    ]
}