import React from "react";
import moment from 'moment/moment';
import styled from "styled-components";
import ReactQuill from "react-quill";
import {v4 as uuid4} from "uuid";

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FontBody16, FontTitle24, FontTitle20, FontTitle40, FontBody18} from '../../../common/components/fonts';
import {images} from '../../../common/components/images';
import {colors} from "../../../common/components/colors";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const LoadingCenter = styled.div`
    margin: 60px auto;
`;
const ModuleTitle = styled.div`
    display: flex;
    cursor: pointer;
    flex-direction: row;
    gap: 20px;
    ${props => props.entryPoint !== "advisor"} {
        margin: 30px;
    }
    ${props => props.entryPoint === "advisor"} {
        margin: 30px 0 20px 0;
    }
`;
const Circle = styled.div`
    position: relative;
    width: 75px;
    min-width: 75px;
    height: calc(75px - 23px);
    border-radius: 75px;
    background-color: ${colors.backgroundColor4};
    text-align: center;
    padding: 11px 0px 12px 0px;
`;
const ChevronUpDown = styled.img`
    padding: 7.5px 4.5px 7.5px 4.5px;
    vertical-align: top;
    display: block;
    object-fit: contain;
`;
const Grow = styled.div`
    flex-grow: 1;
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`;
const Description = styled.div`
    margin-left: 125px;
    & > div {
        font-size: 20px;
    }
`;
const Olive = styled(FontBody16)`
    margin-top: 5px;
    color: ${colors.secondary100};
`;
const Image = styled.img`
    position: relative;
    width: 75px;
    height: 75px;
    border-radius: 8px;
    object-fit: cover;
`;
const QuillEdit = styled(FontBody18)`
  .ql-editor {
    padding: 0 !important;
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    ${props => props.entryPoint !== "advisor"} {
      font-size: 20px;
      line-height: 26px;
    }
    ${props => props.entryPoint === "advisor"} {
      font-size: 16px;
      line-height: 22px;
    }
  }
`;

class ProgramIntro extends React.Component {
    state={"isLoading": true};

    componentDidMount() {
        this.setState({
            "data": this.props.data,
            "isLoading": false
        })
    }

    render() {
        if (this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <ModuleTitle entryPoint={this.props.entryPoint} onClick={() => {this.props.webinarOpenStatus(this.state.data.get("id"))}}>
                        {this.state.data.get("coverPhoto") === null
                            ? <Circle><FontTitle40>{(this.props.order+1)}</FontTitle40></Circle>
                            : <Image src={this.state.data.get("coverPhoto")} />
                        }
                        <Grow>
                            {this.props.entryPoint === "advisor"
                                ? <FontTitle24>{this.state.data.get("title")}</FontTitle24>
                                : <FontTitle20>{this.state.data.get("title")}</FontTitle20>
                            }
                            {!this.props.isPromotionalProgram
                                ? <Olive>{moment(this.state.data.get("flashSaleStartDateTime")).format("MMM Do, YYYY")}, {moment(this.state.data.get("flashSaleStartDateTime")).format("h:mm")} - {moment(this.state.data.get("flashSaleEndDateTime")).format("LT")}</Olive>
                                : <Olive>&nbsp;</Olive>
                            }
                        </Grow>
                        <ChevronUpDown src={this.props.webinarOpen === this.state.data.get("id") ? images.chevronDown : images.chevronUp} alt={""}/>
                    </ModuleTitle>
                    {this.props.webinarOpen === this.state.data.get("id") &&
                        <Description>
                            <QuillEdit entryPoint={this.props.entryPoint}>
                                <ReactQuill
                                    id={uuid4()}
                                    readOnly={true}
                                    theme={null}
                                    value={this.state.data.get("description")}
                                    bounds={'.app'}
                                />
                            </QuillEdit>
                        </Description>
                    }
                </>
            )
        }
    }
}

export default ProgramIntro;
