import React from "react";
import styled from "styled-components";
import {withRouter, Link} from "react-router-dom";

/* Components */
import {FontBody16To14, FontTitle32To27} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";
import {ButtonTertiary, ButtonPrimary} from "../../../common/components/buttons";

const TitleSection = styled.div`
    ${props => props.hasLinks !== true} {
        margin-bottom: 30px;
        border-bottom: 1px solid ${colors.primary30};
        @media screen and (max-width: 768px) {
            border-bottom: none;
        }
    }
    ${props => props.hasLinks !== false} {
        padding-bottom: 15px;
        border-bottom: 1px solid ${colors.primary30};
        margin-bottom: 30px;
    }
`;

const Left = styled.div`
    ${props => props.hasRight !== true} {
        width: 300px;
    }
    ${props => props.hasRight !== false} {
        width: 100%;
    }
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 640px) {
        width: 100%;
    }
`;

const Right = styled.div`
    width: calc(100% - 300px);
    display: inline-block;
    text-align: right;
    vertical-align: top;
    @media screen and (max-width: 640px) {
        display: block;
        text-align: left;
        padding: 10px 0px;
        width: 100%;
    }
`;

const InlineButtons = styled.div`
    display: inline-block;
    margin-top: 3.5px;
    margin-right: 10px;
    width: fit-content;
`;

const Subtitle = styled.div`
    color: ${colors.primary70};
`;

const Links = styled.div`
    margin-top: 15px;
`;

const LinkStyled = styled(Link)`
    display: inline-block;
    text-align: center;
    cursor: pointer;
    padding: 10px 20px;
    ${props => props.status !== 'active'} {
        color: ${colors.primary100};
        border-bottom: 4px solid ${colors.action100};
        font-weight: 700;
    }
    ${props => props.status !== 'inactive'} {
        color: ${colors.secondary100};
    }
    @media screen and (max-width: 768px) {
        display: block;
        text-align: left;
        padding: 10px 0px;
    }
`;

class NavSecondaryTop extends React.Component {
    static defaultProps = {
        title: "",
        desc: "",
        buttons: [],
        tabs: []
    };

    render() {
        return(
            <TitleSection hasLinks={this.props.tabs.length > 0}>
                <div>
                    {this.props.title !== "" &&
                        <Left hasRight={this.props.buttons.length > 0}>
                            <FontTitle32To27>{this.props.title}</FontTitle32To27>
                        </Left>
                    }
                    {this.props.buttons.length > 0 &&
                        <Right>
                            {this.props.buttons.map((b, indexB) => {
                                return(
                                    <InlineButtons key={indexB}>
                                        <Link to={b.pathname}>
                                            {b.buttonType === "tertiary" && <ButtonTertiary canSubmit={false} label={b.label} />}
                                            {b.buttonType === "primary" && <ButtonPrimary canSubmit={false} label={b.label} />}
                                        </Link>
                                    </InlineButtons>
                                )
                            })}
                        </Right>
                    }
                </div>
                {this.props.desc !== "" &&
                    <Subtitle><FontBody16To14>{this.props.desc}</FontBody16To14></Subtitle>
                }
                {this.props.tabs.length > 0 &&
                    <Links>
                        {this.props.tabs.map((t, index) => {
                            let status = "inactive";
                            if(this.props.location.pathname === t.href || (this.props.location.pathname.includes(t.href) && t.alwaysActiveForSubRoutes)) {
                                status = "active"
                            }
                            return(
                                <LinkStyled key={index} status={status} to={t.href}>
                                    <FontBody16To14>{t.label}</FontBody16To14>
                                </LinkStyled>
                            )
                        })}
                    </Links>
                }
            </TitleSection>
        )
    }
}

export default withRouter(NavSecondaryTop);