import { Map, List, fromJS } from 'immutable';

/* Immutable */

const IFRAME_SIGNUP_MATCH_SAVE_RESULTS = 'IFRAME_SIGNUP_MATCH_SAVE_RESULTS';
const IFRAME_SIGNUP_MATCH_COACHES_FETCHING = 'IFRAME_SIGNUP_MATCH_COACHES_FETCHING';
const IFRAME_SIGNUP_MATCH_COACHES = 'IFRAME_SIGNUP_MATCH_COACHES';
const IFRAME_SIGNUP_MATCH_COACH_PROFILE_FETCHING = 'IFRAME_SIGNUP_MATCH_COACH_PROFILE_FETCHING';
const IFRAME_SIGNUP_MATCH_COACH_PROFILE = 'IFRAME_SIGNUP_MATCH_COACH_PROFILE';
const IFRAME_SIGNUP_MATCH_MY_TEAM = 'IFRAME_SIGNUP_MATCH_MY_TEAM';
const IFRAME_SIGNUP_MATCH_MY_TEAM_FETCHING = 'IFRAME_SIGNUP_MATCH_MY_TEAM_FETCHING';
const IFRAME_SIGNUP_MATCH_STORE_RESET = 'IFRAME_SIGNUP_MATCH_STORE_RESET';

/* Actions */

export const setSignupMatchResultsSaving = (status) => ({'type': IFRAME_SIGNUP_MATCH_SAVE_RESULTS, 'status': status});
export const setSignupMatchCoachesFetching = (status) => ({'type': IFRAME_SIGNUP_MATCH_COACHES_FETCHING, 'status': status});
export const setSignupMatchCoaches = (coaches) => ({'type': IFRAME_SIGNUP_MATCH_COACHES, 'coaches': coaches});
export const setSignupMatchCoachProfile = (coachProfile) => ({'type': IFRAME_SIGNUP_MATCH_COACH_PROFILE, 'coachProfile': coachProfile});
export const setSignupMatchCoachProfileFetching = (status) => ({'type': IFRAME_SIGNUP_MATCH_COACH_PROFILE_FETCHING, 'status': status});
export const setSignupMatchMyTeam = (team) => ({'type': IFRAME_SIGNUP_MATCH_MY_TEAM, 'team': team});
export const setSignupMatchMyTeamFetching = (status) => ({'type': IFRAME_SIGNUP_MATCH_MY_TEAM_FETCHING, 'status': status});
export const setSignupMatchStoreReset = () => ({'type': IFRAME_SIGNUP_MATCH_STORE_RESET});

/* Initial State */

const initialState = Map({
    signupMatchResultsSaving: false,
    signupMatchCoachesFetching: false,
    signupMatchCoaches: List(),
    signupMatchMyTeamFetching: false,
    signupMatchMyTeam: List(),
    signupMatchCoachProfileFetching: false,
    signupMatchCoachProfile: Map(),
});

/* Reducer */

const iframeSignupMatchReducer = (state=initialState, action) => {
    switch (action.type) {
        case IFRAME_SIGNUP_MATCH_SAVE_RESULTS:
            return state.merge({'signupMatchResultsSaving': action.status});
        case IFRAME_SIGNUP_MATCH_COACHES_FETCHING:
            return state.merge({'signupMatchCoachesFetching': action.status});
        case IFRAME_SIGNUP_MATCH_COACHES:
            return state.merge({'signupMatchCoaches': fromJS(action.coaches)});
        case IFRAME_SIGNUP_MATCH_COACH_PROFILE_FETCHING:
            return state.merge({'signupMatchCoachProfileFetching': action.status});
        case IFRAME_SIGNUP_MATCH_COACH_PROFILE:
            return state.merge({'signupMatchCoachProfile': fromJS(action.coachProfile)});
        case IFRAME_SIGNUP_MATCH_MY_TEAM_FETCHING:
            return state.merge({'signupMatchMyTeamFetching': action.status});
        case IFRAME_SIGNUP_MATCH_MY_TEAM:
            return state.merge({'signupMatchMyTeam': fromJS(action.team)});
        case IFRAME_SIGNUP_MATCH_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default iframeSignupMatchReducer;
