import styled from "styled-components";
import {colors} from "../../../common/components/colors";

export const Width = styled.div`
    max-width: 536px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
`;
export const PhotoButton = styled.div`
    width: 130px;
    height: 105px;
    border: 1px solid ${colors.secondary30};
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    padding-top: 45px;
    font-size: 30px;
    background-color: ${colors.white};
    margin: 20px 20px 0px 0px;
`;
export const PhotoUpload = styled.input`
    width: 130px;
    height: 150px;
    display: none;
`;
export const Section = styled.div`
    border: 1px solid ${colors.primary30};
    margin-bottom: 30px;
    padding: 30px;
    width: calc(100% - 60px);
    background-color: ${colors.white};
    @media screen and (max-width: 640px) {
        padding: 20px;
        width: calc(100% - 40px);
    }
    @media screen and (max-width: 460px) {
        padding: 10px;
        width: calc(100% - 20px);
    }
`;
export const FitContent = styled.div`
    width: fit-content;
    cursor: pointer;
`;
export const SplitLeft = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 110px;
`;
export const SplitCenter = styled.div`
    display: inline-block;
    vertical-align: top;
    text-align: center;
    color: ${colors.secondary70};
    width: calc(100% - 220px);
    padding: 11px 0px;
`;
export const SplitRight = styled.div`
    display: inline-block;
    vertical-align: top;
    text-align: right;
    width: 110px;
    & > div > div {
        margin-right: 0px;
    }
    & > div {
        margin: 0px 0px 0px auto;
    }
`;
export const PhotoMargin = styled.div`
    margin-bottom: -20px;
    color: ${colors.secondary100};
`;
export const Inset = styled.div`
    width: calc(100% - 80px);
    margin-left: 40px;
`;


export const Photo = styled.img`
    width: auto;
    height: auto;
    display: inline-block;
    vertical-align: top;
    margin: 20px 20px 0px 0px;
    border: 1px solid ${colors.secondary30};
    max-width: 100%;
`;
export const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;
export const InlineCheckbox = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 33%;
    min-width: 225px;
    margin-top: 15px;
    @media screen and (max-width: 640px) {
        width: 100%;
        min-width: 100%;
        display: block;
    }
`;
export const Type2 = styled.div`
    margin-top: 40px;
`;
export const Border = styled.div`
    border-top: 1px solid ${colors.primary30};
    margin-top: 20px;
    padding-top: 20px;
`;
export const Margin = styled.div`
    margin-bottom: -35px;
`;
export const Red = styled.div`
    color: ${colors.alert100};
`;