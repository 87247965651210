import styled from "styled-components";
import {colors} from "../../../common/components/colors";

export const Portfolios = styled.div`
    text-align: center;
    max-width: 800px;
    margin: 45px auto 60px auto;
    width: 100%;
`;
export const Portfolio = styled.div`
    margin-bottom: 20px;
    border: 1px solid ${colors.border100};
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 30px 40px;
    border-radius: 10px;
    @media screen and (max-width: 640px) {
        padding: 15px 20px;
    }
`;
export const Text = styled.div`
    text-align: left;
    margin: 15px 0px 0px 0px;
    & p {
        font-size: 18px;
    }
`;
export const Gray = styled.div`
    color: ${colors.secondary70};
`;
export const Image = styled.img`
    height: auto;
    max-height: 400px;
    width: 100%;
    max-width: 600px;
    object-fit: cover;
    object-position: 0 0;
    margin: 0px auto;
`;
export const LoadingCenter = styled.div`
    margin: 60px auto;
`;
export const FitContent = styled.div`
    width: fit-content;
`;
export const Border = styled.div`
    margin: 20px 0px;
    border-bottom: 1px solid ${colors.primary30};
`;
export const Btn = styled.div`
    color: ${colors.action100};
    cursor: pointer;
`;
export const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
`;
export const TextLeft = styled.div`
    text-align: left;
`;