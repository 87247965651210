import React from 'react';
import styled from 'styled-components';
import {Helmet} from "react-helmet";
import {withRouter} from 'react-router-dom';

/* Components */
import {colors} from '../../common/components/colors';
import {images} from '../../common/components/images';
import {FontSubtitle18, FontSubtitle28} from '../../common/components/fonts';
import {LogoLoading} from "../../common/components/loading";

/* Containers */
import SignupFlow from '../signup/containers/flow';

/* Domain */
import getDomainEnv from '../../domains/utils';
const env = getDomainEnv();

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

const Container = styled.div`
    max-width: 500px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 100px auto 0px auto;
    @media screen and (max-width: 460px) {
        margin: 40px auto 0px auto;
    }
`;

const Logo = styled.img`
    width: auto;
    height: 32px;
    cursor: pointer;
    margin: 0px auto;
    display: block;
`;

const NavBar = styled.div`
    margin: 45px 0px 50px 0px;
    display: flex;
    flex-direction: row;
    gap: 0px;
`;

const NavOption = styled(FontSubtitle18)`
    display: inline-flex;
    ${props => props.active !== true} {
        border-bottom: 7px solid ${colors.action100};
    }
    ${props => props.active !== false} {
        border-bottom: 7px solid ${colors.backgroundChatBubble};
    }
    padding: 20px 0px;
    width: 100%;
    justify-content: center;
    cursor: pointer;
`;

const Message = styled(FontSubtitle28)`
    margin: 50px auto;
    width: fit-content;
    letter-spacing: normal;
`;

class IFrameLogin extends React.Component {
    state = {"isLoading": true};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({'isLoading': false})
    };

    home = () => window.open("https://"+env.URL.DOMAIN,"_self");
    createAccount = () => this.props.history.push("/signup");

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <Helmet>
                        <link rel={"icon"} type={"image/png"} href={images.favicon16px} sizes={"16x16"} />
                        <meta charSet={"utf-8"} />
                        <title>Willow</title>
                        <link rel={"canonical"} href={window.location.href} />
                        <meta property={"og:type"} content={"website"} />
                        <meta property={"og:url"} content={window.location.href} />
                        <meta property={"og:site_name"} content={"Willow"} />
                    </Helmet>
                    <Container>
                        <Logo src={images.logo} alt={""} onClick={this.home} />
                        {/*<NavBar>*/}
                        {/*    <NavOption active={true}>Sign in</NavOption>*/}
                        {/*    <NavOption active={false} onClick={this.createAccount}>Create account</NavOption>*/}
                        {/*</NavBar>*/}
                        <Message>Welcome back!</Message>
                        <SignupFlow
                            loginSystem={"page"}
                            loginForgotPasswordLink={() => {this.props.history.push("/password-reset")}}
                        />
                    </Container>
                </>
            )
        }
    }

}

export default withRouter(IFrameLogin);