import React from "react";
import moment from 'moment/moment'
import ContentEditable from 'react-contenteditable'

/* Components */
import {Circle, CheckMark, CircleWorkingOn, CircleIncomplete, ModuleTitle, TaskProgressCircle, ProgressCircleIcon, CompleteBox, GrowTopAlign, FlexBullet, ProgressCircle, Grow, InlineBlockBtn, ChevronUpDown, LoadingCenter, BulletArea,} from "../components/courses";
import {LogoLoading} from "../../../common/components/loading";
import {FontBody16, FontTitle20, FontTitle40} from '../../../common/components/fonts';
import {ButtonPrimary} from '../../../common/components/buttons';
import {images} from '../../../common/components/images';

const CircleIcon = ({isCompleted, lastPageCompleted, taskNum, percentCompleted}) => {
    if(isCompleted) {
        return(
            <Circle>
                <CheckMark src={images.progressCheckMark} alt={""}/>
            </Circle>
        )
    } else if(!isCompleted && lastPageCompleted !== -1) {
        return(
            <>
                <CircleIncomplete>
                    <FontTitle40>{taskNum}</FontTitle40>
                </CircleIncomplete>
                <CircleWorkingOn percentCompleted={percentCompleted} />
            </>
        )
    } else if(!isCompleted && lastPageCompleted === -1) {
        return(
            <CircleIncomplete>
                <FontTitle40>{taskNum}</FontTitle40>
            </CircleIncomplete>
        )
    }
};

class CourseSummaryModules extends React.Component {
    static defaultProps = {
        index: null,
        task: null,
        summaryModuleOpen: null,
        summaryModuleOpenStatus: () => {},
        numTasksTotal: 1,
        startOpeningTask: () => {},
        preview: false,
    }
    
    state={
        "numTasksTotal": 1,
        "task": {},
        "lastPageCompleted": 0,
        "isCompleted": false,
        "isLoading": true
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.task !== this.props.task) {
            return this.init();
        }
    }

    init = async () => {
        const task = this.props.task;
        this.setState({
            "numTasksTotal": this.props.numTasksTotal,
            "task": task,
            "lastPageCompleted": (task.lastPageCompleted === null || task.lastPageCompleted === undefined) ? -1 : task.lastPageCompleted,
            "isCompleted": task.isCompleted !== undefined ? task.isCompleted : false,
            "isLoading": false
        })
    }

    render() {
        if (this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <ModuleTitle onClick={() => {this.props.summaryModuleOpenStatus(this.props.index)}}>
                        <TaskProgressCircle
                            showLineDown={(this.props.numTasksTotal !== (this.props.index+1) && this.state.lastPageCompleted !== -1 && this.props.summaryModuleOpen !== this.props.index)}
                            lineStyleSolid={this.state.isCompleted}
                        >
                            <CircleIcon
                                taskNum={this.props.index+1}
                                isCompleted={this.state.isCompleted}
                                lastPageCompleted={this.state.lastPageCompleted}
                                percentCompleted={((this.state.lastPageCompleted+1) / this.state.task.task.pages.length)*100}
                            />
                        </TaskProgressCircle>
                        <Grow><FontTitle20>{this.state.task.task.title}</FontTitle20></Grow>
                        <ChevronUpDown
                            src={this.props.summaryModuleOpen === this.props.index ? images.chevronUp : images.chevronDown}
                            alt={""}
                        />
                    </ModuleTitle>
                    {this.props.summaryModuleOpen === this.props.index &&
                        <>
                            <BulletArea>
                                <FontBody16>
                                    <ContentEditable 
                                        className="content-editable" 
                                        html={this.state.task.task.description}
                                        onChange={() => {}} 
                                        placeholder={""}
                                        disabled={true}
                                    />
                                </FontBody16>
                                {this.state.task.task.pages.map((a, index) => {
                                    return(
                                        <FlexBullet key={index}>
                                            <ProgressCircle
                                                showLineDown={!(index > this.state.lastPageCompleted)}
                                                lineStyleSolid={this.state.isCompleted}
                                            >
                                                <ProgressCircleIcon
                                                    src={index > this.state.lastPageCompleted ? images.progressCircleNotCompleted : images.progressCircleCompleted}
                                                    alt={""}
                                                />
                                            </ProgressCircle>
                                            <GrowTopAlign>
                                                <ContentEditable 
                                                    className="content-editable" 
                                                    html={a.header.title}
                                                    onChange={() => {}} 
                                                    placeholder={""}
                                                    disabled={true}
                                                />
                                            </GrowTopAlign>
                                        </FlexBullet>
                                    )
                                })}
                            </BulletArea>
                            {this.state.isCompleted &&
                                <CompleteBox>Completed {moment(this.state.task.completedOn).format('LL')}</CompleteBox>
                            }
                            {(!this.state.isCompleted && !this.props.preview) &&
                                <InlineBlockBtn onClick={() => {this.props.startOpeningTask(this.state.task)}}>
                                    <ButtonPrimary submit={false} label={"Start"} />
                                </InlineBlockBtn>
                            }
                        </>
                    }
                </>
            )
        }
    }
}

export default CourseSummaryModules;
