import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {useLocation, withRouter} from 'react-router-dom';

/* Components */
import {colors} from '../../common/components/colors';
import {LogoLoading} from "../../common/components/loading";

/* Containers */
import ChangePassword from "../changepassword/containers/changepassword";

/* Middleware */
import {trySignUpAssignAuthenticate} from './middleware/auth';

/* Domain */
import getDomainEnv from '../../domains/utils';
const env = getDomainEnv();

const LoadingCenter = styled.div`
    margin: 60px auto;
    @media screen and (max-width: 640px) {
        margin: 0px auto;
    }
    text-align: center;
`;

const Background = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: ${colors.background100};
`;
const SettingsCard = styled.div`
    -webkit-box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    background-color: ${colors.white};
    border-radius: 4px;
    padding: 47px;
    color: ${colors.primary100};
    max-width: ${props => props.maxWidth};
    margin: 0px auto 40px auto;
    @media screen and (max-width: 748px) {
        margin: 40px auto 0px auto;
    }
    @media screen and (max-width: 640px) {
        margin: 0px auto 0px auto;
    }
    @media screen and (max-width: 460px) {
        padding: 30px 20px;
    }
`;

class IFrameSignUpAssign extends React.Component {
    state = {"isLoading": true, "handle": null};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const prospectId = this.props.query.get("prospectId");
        const wmcId = this.props.query.get("wmcId");
        const token = this.props.query.get("token");
        const handle = this.props.query.get("coachHandle");
        if(prospectId === null || wmcId === null || token === null || handle === null) {
            this.props.history.push("/verify");
        } else {
            await this.setState({"handle": handle});
            const auth = await this.props.trySignUpAssignAuthenticate(prospectId, wmcId, token);
            if(!auth.error) {
                this.setState({"isLoading": false});
            } else {
                return this.openWillowProfile();
            }
        }
    };

    openWillowProfile = async () => {
        window.open("https://"+env.URL.DOMAIN+"/willow-profile/"+this.state.handle,"_self");
    };

    render() {
        return(
            <Background>
                <LoadingCenter>
                    {this.state.isLoading
                        ? <LogoLoading/>
                        : <SettingsCard maxWidth={"460px"}>
                            <ChangePassword
                                title={"Welcome to Willow!"}
                                subTitle={"Create a password for your account"}
                                isInsideCard={true}
                                onSuccess={() => {return this.openWillowProfile()}}
                                onSuccessMessage={"Next, schedule with your coach!"}
                                hasBack={false}
                            />
                        </SettingsCard>
                    }
                </LoadingCenter>
            </Background>
        )
    }

}

const mapStateToProps = state => ({
    // error: state.iframe.signUpAssign.auth.get("iframeAuthLoginError"),
});

const mapDispatchToProps = dispatch => ({
    trySignUpAssignAuthenticate: (prospectId, wmcId, token) => dispatch(trySignUpAssignAuthenticate(prospectId, wmcId, token))
});

const IFrameSignUpAssignContainer = connect(mapStateToProps, mapDispatchToProps)(withRouter(IFrameSignUpAssign));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function IFrameSignUpAssignContainerWithQuery() {
    let query = useQuery();
    return(
        <IFrameSignUpAssignContainer
            query={query}
        />
    )
}

export default IFrameSignUpAssignContainerWithQuery;