import styled from "styled-components";

import {colors} from "../../../common/components/colors";

export const Header = styled.div`
    padding: 30px 30px 00px 30px;
`;

export const Body = styled.div`
    overflow-y: auto;
    height: calc(100% - 60px);
    word-break: break-word;
    padding: 10px 30px 30px 30px;
`;

export const Back = styled.div`
    width: fit-content;
    padding: 3px;
    cursor: pointer;
    color: ${colors.secondary70};
    margin: 0px 0px 40px -6px;
    & svg {
        width: 17px;
        height: 17px;
        margin-bottom: -3px;
    }
`;

export const Title = styled.div`
    width: 100%;
`;

export const InlineLabel = styled.div`
    display: inline-block;
    vertical-align: top;
`;

export const LetterSpacing = styled.div`
    letter-spacing: 2px;
`;

export const Red = styled.div`
    color: ${colors.alert100};
    display: inline-block;
    vertical-align: top;
`;

export const IconDescription = styled.div`
    display: inline-block;
    vertical-align: top;
    color: ${colors.primary100};
    & svg {
        width: 17px;
        height: 17px;
        margin-bottom: -3px;
        margin-right: 3px;
    }
`;

export const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;

export const TopRight = styled.div`
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 17;
`;

export const SectionHeader = styled.div`
    width: 100%;
    margin: 20px 0px 10px 0px;
`;

export const DescriptionButton = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
    color: ${colors.secondary100};
    cursor: pointer;
    user-select: none;
    text-decoration: underline;
`;

export const CardImage = styled.img`
    display: inline-block;
    vertical-align: top;
    width: 70px;
    height: auto;
    border-radius: 6px;
`;

export const CardOne = styled.div`
    border-radius: 8px;
    border: 2px solid ${colors.border100};
    padding: 15px 20px;
    background-color: ${props => props.backgroundColor};
    ${props => props.canSelect !== true} {
        cursor: pointer;
        -webkit-box-shadow: 0px 4px 10px 0px ${colors.primary30};
        box-shadow: 0px 4px 10px 0px ${colors.primary30};
    }
    ${props => props.canSelect !== false} {
    
    }
    // &:hover {
    //     border: 2px solid ${colors.action100};
    //     -webkit-box-shadow: 0px 4px 10px 0px ${colors.action100};
    //     box-shadow: 0px 4px 10px 0px ${colors.action100};
    // }
    margin-top: 10px;
`;

export const Name = styled.div`
    margin-top: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 15px;
`;

export const Number = styled.div`
    margin-top: 20px;
`;

export const Left = styled.div`
    width: 70%;
    display: inline-block;
    vertical-align: top;
`;

export const RightTop = styled.div`
    width: 30%;
    display: inline-block;
    vertical-align: top;
    text-align: right;
    position: relative;
    & svg {
        position: absolute;    
        top: 9px;
        right: 1px;
        z-index: 18;
        color: ${colors.primary30};   
        &:hover {
            color: ${colors.action100};
        }
    }
`;

export const Right = styled.div`
    width: 30%;
    display: inline-block;
    vertical-align: bottom;
    text-align: right;
    margin-bottom: 4px;
`;

export const FitContent = styled.div`
    width: fit-content;
`;

export const Add = styled.div`
    width: fit-content;
    margin: 40px 0px 30px 0px;
    cursor: pointer;
    color: ${colors.secondary100};
    & svg {
        margin-bottom: -3px;
        width: 18px;
        height: 18px;
    }
`;
export const Delete = styled.div`
    width: fit-content;
    margin: 40px auto 30px auto;
    cursor: pointer;
    color: ${colors.secondary100};
    & svg {
        margin-bottom: -3px;
        width: 18px;
        height: 18px;
    }
`;

export const StripeComponents = styled.div`    
    .StripeElement, .StripeElementIdeal, .StripeElementFpx {
        background-color: ${colors.border70};
        border: 1px solid ${colors.primary30};
        color: ${colors.primary100};
        border-radius: 4px;
        padding: 10px 20px;
        font-family: GraphicWebRegular;
        font-size: 16px;
        line-height: 22px;
        @media screen and (max-width: 460px) {
            padding: 12px 20px 8px 20px;
        }
        &::placeholder {
            color: ${colors.primary70};
            font-family: GraphicWebRegular;
            line-height: 22px;
        }
        &:focus {
            outline: none;
        }
    }
    
    .StripeElement.loading {
      
    }
    
    .StripeElementIdeal, .StripeElementFpx {
      padding: 0;
    }
    
`;

export const HalfLeft = styled.div`
    width: 48%;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 2%;
`;

export const HalfRight = styled.div`
    width: 50%;
    display: inline-block;
    vertical-align: bottom;
`;

export const Form = styled.form`
    padding-top: 15px;
`;

export const Spacer = styled.div`
    height: 20px;
    width: 100%;
`;

export const ButtonWide = styled.div`
    margin-top: 10px;
    & > button, & > div {
        text-align: center;
        width: calc(100% - 47px);
        & > div {
            width: 100%;
        }
    }
`;

export const Hidden = styled.div`
    padding: 10px 20px;
    border: 1px solid ${colors.primary30};
    border-radius: 4px;
    color: ${colors.secondary70};
`;