import React from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

/* Containers */

/* Middleware */
import {setPromotionModal} from '../store/prospects';
import {tryPostProspectPromotion} from '../middleware/prospects';
import { tryGetAllContacts } from '../middleware/contacts';

/* Components */
import {LogoLoading} from "../../common/components/loading";
import {FontBody18, FontTitle30, FontHeader14, FontBody16} from "../../common/components/fonts";
import {SearchInput, SearchInputUncontrolled, SearchInputUncontrolledDisplay, TextInput} from "../../common/components/inputs";
import {colors} from "../../common/components/colors";
import {ButtonInactive, ButtonPrimary} from "../../common/components/buttons";
import {CloseOutlined, DeleteFilled} from "@ant-design/icons";

const Width = styled.div`
    max-width: 500px;
    margin-bottom: 30px;
`;


const SearchInputEdit = styled.div`
    & > div {
        margin: 25px 0px 15px 0px;
    }
    & input {
        width: calc(100% - 42px);
    }
    ${props => props.contactSelected !== false} {
        & input {
            -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
        }
        & input::placeholder {
            color: ${colors.primary70};
        }
    }
`;

const Modal = styled.div`
    margin: 0px auto;
    max-width: 380px;
    padding: 30px;
    border-radius: 6px;
    background-color: ${colors.white};
    position: fixed;
    top: 100px;
    left: 0px;
    right: 0px;
    ${props => props.big !== false} {max-height: 280px;}
    @media screen and (max-width: 460px) {
        top: 20px;
        width: calc(100% - 20px);
        padding: 0px 10px;
    }
    z-index: 6;
`;
const Background = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.50);
    @media screen and (max-width: 460px) {
        background-color: ${colors.white};
    }
    z-index: 5;
`;

const FitContentFullWidth = styled.div`
    width: 100%;
    & > div {
        width: calc(100% - 47px);
        text-align: center;
        margin: 0px auto;
    }
`;
const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;
const Right = styled.div`
    color: ${colors.secondary70};
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 10px;
    display: block;
    cursor: pointer;
    position: absolute;
    z-index: 25;
    right: 30px;
    top: 25px;
    @media screen and (max-width: 460px) {
        top: 0px;
        right: 5px;
    }
`;
const Left = styled.div`
    text-align: left;
    width: 100%;
`;
const Indent = styled.div`
    width: calc(100% - 40px);
    padding: 0px 20px;
`;
const Header = styled.div`
    border-bottom: 1px solid ${colors.secondary30};
    padding-bottom: 10px;
    margin-top: -10px;
    margin-bottom: 30px;
`;
const CoachName = styled.div`
    margin-left: 15px;
    margin-top: 10px;
    display: inline-block;
    vertical-align: top;
`;
export const Close = styled.div`
    position: relative;
    cursor: pointer;
    width: 75px;
    @media screen and (max-width: 640px) {
        width: 20px;
    }
`;
export const Margin67 = styled.div`
    margin-bottom: 25px;
`;
const Trash = styled.div`
    margin-left: 10px;
    display: inline-block;
    font-size: 16px;
    vertical-align: top;
    cursor: pointer;
    color: ${colors.secondary70};
`;
const Search = styled.div`
    display: inline-block;
    vertical-align: top;
`;

const whoOptions = (who) => {
    return who.map(w => {
        return({
            value: {
                wmcId: w.get("id"),
            },
            label: w.get("companyName")
        })
    }).toJS();
};

const initState = {
    "isLoading": false,
    'label': "",
    "whoValid": false,
    'isChanged': false,
    "page": 1,
    "category": [],
    "city": "",
    "state": "",
    "institution": "",
    "degreeYear": "",
    "degree": "",
    "cert": "",
    "certYear": "",
    "other": "",
    "wmcId": "",
    "companyName": "",
    "coachCategories": [{value: {categoryId: 4}, label: "Financial Coach"}, {value: {categoryId: 6}, label: "Relationship Manager"}],
    "searchCategories": [{value: {categoryId: 4}, label: "Financial Coach"}, {value: {categoryId: 6}, label: "Relationship Manager"}],
};

class ProspectPromotionModal extends React.Component {
    state=initState;

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.modalOpen !== this.props.modalOpen && this.props.modalOpen) {
            return this.init();
        }
    }

    init = async () => {
        await this.setState({
            "isLoading": false,
            'label': "",
            "whoValid": false,
            'isChanged': false,
            "page": 1,
            "category": [],
            "city": "",
            "state": "",
            "institution": "",
            "degreeYear": "",
            "degree": "",
            "cert": "",
            "certYear": "",
            "other": "",
            "wmcId": "",
            "companyName": "",
            "coachCategories": [{value: {categoryId: 1}, label: "Willow Financial Coach"}, {value: {categoryId: 2}, label: "Financial Advisor"}, {value: {categoryId: 4}, label: "Financial Coach"}],
            "searchCategories": [{value: {categoryId: 1}, label: "Willow Financial Coach"}, {value: {categoryId: 2}, label: "Financial Advisor"}, {value: {categoryId: 4}, label: "Financial Coach"}],
        });
    };

    handleChangeType = async (e) => {
        if(e === null) {
            await this.setState({'label': "", 'isChanged': false})
        } else {
            await this.setState({
                'label': e.label,
                'isChanged': true,
                'whoValid': true
            });
        }
    };

    handleAddCategory = async (e) => {
        let categories = this.state.category;
        let addCategory;
        categories.push(e);
        addCategory = await this.state.coachCategories.filter(s => {return(s.value.categoryId !== e.value.categoryId)});
        await this.setState({"category": categories, 'coachCategories': addCategory, 'isChanged': true, 'whoValid': true});
    };

    handleDeleteCategory = async (num) => {
        let categories = this.state.category;
        let addCategory;
        addCategory = await this.state.searchCategories.filter(s => {return(s.value.categoryId === this.state.category[num].value.categoryId)})[0];
        await categories.splice(num, 1);
        let cc = [...this.state.coachCategories];
        cc.push(addCategory)
        await this.setState({"category": categories, 'coachCategories': cc, 'isChanged': this.state.category.length > 0, 'whoValid': this.state.category.length > 0});
    };

    handleChangeCompany = async (e) => {
        if(e === null) {
            await this.setState({'companyName': "", 'wmcId': e.value.wmcId, 'whoValid': false, 'isChanged': false})
        } else {
            await this.setState({
                'companyName': e.label,
                'wmcId': e.value.wmcId,
                'isChanged': true,
                'whoValid': true
            });
        }
    };

    close = async () => {
        await this.props.tryGetAllContacts(); 
        await this.props.setPromotionModal(false);
        await this.setState({
            ...initState,
            "isLoading": false
        });
    };

    handleSubmit = async () => {
        let dataObject;
        if (this.state.category.length > 0) {
            dataObject = {"expertCategoryIds": this.state.category.map(a => {return(a.value.categoryId)})}
        }
        if (this.state.degree !== "") {
            dataObject = {...dataObject, "advancedDegree": this.state.degree}
        }
        if (this.state.institution !== "") {
            dataObject = {...dataObject, "advancedDegreeInstitution": this.state.institution}
        }
        if (this.state.city !== "") {
            dataObject = {...dataObject, "advancedDegreeCity": this.state.city}
        }
        if (this.state.state !== "") {
            dataObject = {...dataObject, "advancedDegreeState": this.state.state}
        }
        if (this.state.degreeYear !== "") {
            dataObject = {...dataObject, "advancedDegreeYear": this.state.degreeYear}
        }
        if (this.state.cert !== "") {
            dataObject = {...dataObject, "coachingCertification": this.state.cert}
        }
        if (this.state.certYear !== "") {
            dataObject = {...dataObject, "coachingCertificationYear": this.state.certYear}
        }
        if (this.state.other !== "") {
            dataObject = {...dataObject, "otherEducation": this.state.other}
        }
        await this.props.tryPostProspectPromotion(dataObject, this.props.clientSelected.get("prospectId"), this.state.label, this.state.wmcId)
        this.close()
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            if(this.props.modalOpen) {
                if(this.state.page === 1) {
                    return(
                        <>
                            <Modal big={true}>
                                <Header>
                                    <Left>
                                        <FontTitle30>Promotion</FontTitle30>
                                    </Left>
                                    <Right onClick={() => {return this.close()}}>
                                        <FontHeader14><CloseOutlined /><Inline>&nbsp;Close</Inline></FontHeader14>
                                    </Right>
                                </Header>
                                <Indent>
                                    {/* <FontBody14>Only the coach will be notified. By default, two sessions will be assigned.</FontBody14> */}
                                    <Margin67 />
                                    <div>
                                        <CoachName><FontBody18>{this.props.clientSelected.get("firstName")} {this.props.clientSelected.get("lastName")}</FontBody18></CoachName>
                                    </div>
                                    <Width>
                                        <SearchInputEdit contactSelected={this.state.whoValid}>
                                            <SearchInputUncontrolledDisplay
                                                title={this.props.clientSelected.get("firstName") + " " + this.props.clientSelected.get("lastName") + "'s new title"}
                                                options={[{label: "Advisor"}, {label: "Expert"}]}
                                                id={'type'}
                                                onChange={this.handleChangeType}
                                                placeholder={'Select Promotion'}
                                                value={this.state.label}
                                            />
                                        </SearchInputEdit>
                                    </Width>
                                    <FitContentFullWidth onClick={() => {return(this.state.label === "Expert" ? this.setState({"page": 2, "whoValid": false, "isChanged": false}) : this.setState({"page": 5, "whoValid": false, "isChanged": false}))}}>
                                        {this.state.whoValid
                                            ? <ButtonPrimary canSubmit={false} label={"Confirm"} />
                                            : <ButtonInactive canSubmit={false} label={"Confirm"} />
                                        }
                                    </FitContentFullWidth>
                                    <br />
                                </Indent>
                            </Modal>
                            <Background />
                        </>
                    )

                } else if(this.state.page === 2) {

                    return(
                        <>
                            <Modal big={true}>
                                <Header>
                                    <Left>
                                        <FontTitle30>Expert Category</FontTitle30>
                                    </Left>
                                    <Right onClick={() => {return this.close()}}>
                                        <FontHeader14><CloseOutlined /><Inline>&nbsp;Close</Inline></FontHeader14>
                                    </Right>
                                </Header>
                                <Indent>
                                    {/* <FontBody14>Only the coach will be notified. By default, two sessions will be assigned.</FontBody14> */}
                                    <Margin67 />
                                    <div>
                                        <CoachName><FontBody18>{this.props.clientSelected.get("firstName")} {this.props.clientSelected.get("lastName")}</FontBody18></CoachName>
                                    </div>
                                    <Width>
                                        <SearchInputEdit contactSelected={this.state.whoValid}>
                                            <SearchInputUncontrolled
                                                title={"What type of " + this.state.label.toLowerCase() + " is " + this.props.clientSelected.get("firstName") + " " + this.props.clientSelected.get("lastName") + "?"}
                                                options={this.state.coachCategories}
                                                id={'expertcategory'}
                                                onChange={this.handleAddCategory}
                                                placeholder={'Select Categories'}
                                                value={""}
                                            />
                                        </SearchInputEdit>
                                        {this.state.category.length > 0 &&
                                        this.state.category.map((g, index) => {
                                            return(
                                                <div key={index}>
                                                    <Search><FontBody16>{(index+1).toString()+". "+g.label}</FontBody16></Search>
                                                    <Trash onClick={() => this.handleDeleteCategory(index)}><DeleteFilled /></Trash>
                                                </div>
                                            )
                                        })
                                        }
                                    </Width>
                                    <FitContentFullWidth onClick={() => {return(this.setState({"page": 3, "whoValid": true}))}}>
                                        {this.state.whoValid
                                            ? <ButtonPrimary canSubmit={false} label={"Confirm"} />
                                            : <ButtonInactive canSubmit={false} label={"Confirm"} />
                                        }
                                    </FitContentFullWidth>
                                    <br />
                                </Indent>
                            </Modal>
                            <Background />
                        </>
                    )
                } else if(this.state.page === 3) {
                    return(
                        <>
                            <Modal big={true}>
                                <Header>
                                    <Left>
                                        <FontTitle30>Education</FontTitle30>
                                    </Left>
                                    <Right onClick={() => {return this.setState({"page": 4, "city": "", "state": "", "institution": "", "degreeYear": "", "degree": "",})}}>
                                        <FontHeader14>Skip</FontHeader14>
                                    </Right>
                                </Header>
                                <Indent>
                                    {/* <FontBody14>Only the coach will be notified. By default, two sessions will be assigned.</FontBody14> */}
                                    <Margin67 />
                                    <div>
                                        <CoachName><FontBody18>{this.props.clientSelected.get("firstName")} {this.props.clientSelected.get("lastName")}</FontBody18></CoachName>
                                    </div>
                                    <Width>
                                        
                                        <TextInput
                                            title={"Advanced Degree"}
                                            valid={true}
                                            warning={""}
                                            id={"degree"}
                                            onChange={(e) => {return(this.setState({'degree': e.target.value}))}}
                                            placeholder={"Degree"}
                                            value={this.state.degree}
                                        />
                                        <TextInput
                                            title={"Institution Name"}
                                            valid={true}
                                            warning={""}
                                            id={"school"}
                                            onChange={(e) => {return(this.setState({'institution': e.target.value}))}}
                                            placeholder={"Insitution Name"}
                                            value={this.state.institution}
                                        />
                                        <TextInput
                                            title={"Advanced Degree City"}
                                            valid={true}
                                            warning={""}
                                            id={"city"}
                                            onChange={(e) => {return(this.setState({'city': e.target.value}))}}
                                            placeholder={"City"}
                                            value={this.state.city}
                                        />
                                        <TextInput
                                            title={"Advanced Degree State"}
                                            valid={true}
                                            warning={""}
                                            id={"state"}
                                            onChange={(e) => {return(this.setState({'state': e.target.value}))}}
                                            placeholder={"State"}
                                            value={this.state.state}
                                        />
                                        <TextInput
                                            title={"Advanced Degree Year"}
                                            valid={!isNaN(this.state.degreeYear)}
                                            warning={"Must be a valid number"}
                                            id={"year"}
                                            onChange={(e) => {return(this.setState({'degreeYear': e.target.value, 'whoValid': true}))}}
                                            placeholder={"Degree Year"}
                                            value={this.state.degreeYear}
                                        />
                                    </Width>
                                    <FitContentFullWidth onClick={() => {return(this.setState({"whoValid": true, "page": 4}))}}>
                                        {(this.state.whoValid && (!isNaN(this.state.degreeYear) || this.state.degreeYear === ""))
                                            ? <ButtonPrimary canSubmit={false} label={"Confirm"} />
                                            : <ButtonInactive canSubmit={false} label={"Confirm"} />
                                        }
                                    </FitContentFullWidth>
                                    <br />
                                </Indent>
                            </Modal>
                            <Background />
                        </>
                    )
                } else if(this.state.page === 4) {

                    return(
                        <>
                            <Modal big={true}>
                                <Header>
                                    <Left>
                                        <FontTitle30>Certifications</FontTitle30>
                                    </Left>
                                    <Right onClick={() => {return this.close()}}>
                                        <FontHeader14><CloseOutlined /><Inline>&nbsp;Close</Inline></FontHeader14>
                                    </Right>
                                </Header>
                                <Indent>
                                    {/* <FontBody14>Only the coach will be notified. By default, two sessions will be assigned.</FontBody14> */}
                                    <Margin67 />
                                    <div>
                                        <CoachName><FontBody18>{this.props.clientSelected.get("firstName")} {this.props.clientSelected.get("lastName")}</FontBody18></CoachName>
                                    </div>
                                    <form method={"post"} onSubmit={this.handleSubmit}>
                                        <Width>
                                            <TextInput
                                                title={"Coaching Certification"}
                                                valid={true}
                                                warning={""}
                                                id={"cert"}
                                                onChange={(e) => {return(this.setState({'cert': e.target.value}))}}
                                                placeholder={"Certification"}
                                                value={this.state.cert}
                                            />
                                            <TextInput
                                                title={"Certification Year"}
                                                valid={!isNaN(this.state.certYear)}
                                                warning={"Must be a valid number"}
                                                id={"year"}
                                                onChange={(e) => {return(this.setState({'certYear': e.target.value, 'whoValid': true}))}}
                                                placeholder={"Degree Year"}
                                                value={this.state.certYear}
                                            />
                                            <TextInput
                                                title={"Other Certification / Education"}
                                                valid={true}
                                                warning={""}
                                                id={"other"}
                                                onChange={(e) => {return(this.setState({'other': e.target.value}))}}
                                                placeholder={"Other..."}
                                                value={this.state.other}
                                            />
                                        </Width>
                                        <FitContentFullWidth onClick={this.handleSubmit}>
                                            {!isNaN(this.state.certYear)
                                                ? <ButtonPrimary canSubmit={false} label={"Promote!"} />
                                                : <ButtonInactive canSubmit={false} label={"Promote!"} />
                                            }
                                        </FitContentFullWidth>
                                    </form>
                                    <br />
                                </Indent>
                            </Modal>
                            <Background />
                        </>
                    )
                } else if (this.state.page === 5) {
                    return(
                        <>
                            <Modal big={true}>
                                <Header>
                                    <Left>
                                        <FontTitle30>Assign Company</FontTitle30>
                                    </Left>
                                    <Right onClick={() => {return this.close()}}>
                                        <FontHeader14><CloseOutlined /><Inline>&nbsp;Close</Inline></FontHeader14>
                                    </Right>
                                </Header>
                                <Indent>
                                    {/* <FontBody14>Only the coach will be notified. By default, two sessions will be assigned.</FontBody14> */}
                                    <Margin67 />
                                    <div>
                                        <CoachName><FontBody18>{this.props.clientSelected.get("firstName")} {this.props.clientSelected.get("lastName")}</FontBody18></CoachName>
                                    </div>
                                    <Width>
                                        <SearchInputEdit contactSelected={this.state.whoValid}>
                                            <SearchInputUncontrolledDisplay
                                                title={"Which company would you like to promote " + this.props.clientSelected.get("firstName") + " " + this.props.clientSelected.get("lastName") + " to?"}
                                                options={whoOptions(this.props.wmcs)}
                                                id={'company'}
                                                onChange={this.handleChangeCompany}
                                                placeholder={'Select Company'}
                                                value={this.state.companyName}
                                            />
                                        </SearchInputEdit>
                                    </Width>
                                    <FitContentFullWidth onClick={() => {return(this.setState({"page": 3, "whoValid": true, "isChanged": true}) )}}>
                                        {this.state.whoValid
                                            ? <ButtonPrimary canSubmit={false} label={"Confirm"} />
                                            : <ButtonInactive canSubmit={false} label={"Confirm"} />
                                        }
                                    </FitContentFullWidth>
                                    <br />
                                </Indent>
                            </Modal>
                            <Background />
                        </>
                    )
                } else {
                    return null
                }

            } else {
                return null
            }
        }
    }
}

const mapStateToProps = state => ({
    clientSelected: state.enterprise.clientsDetail.get("client"),
    modalOpen: state.enterprise.prospects.get("promotionModal"),
    wmcs: state.enterprise.partners.partners.get("wmcsAssignedToCoach")
});
const mapDispatchToProps = dispatch => ({
    setPromotionModal: (status) => dispatch(setPromotionModal(status)),
    tryPostProspectPromotion: (dataObject, prospectId, coachOrAdvisor, wmcId) => dispatch(tryPostProspectPromotion(dataObject, prospectId, coachOrAdvisor, wmcId)),
    tryGetAllContacts: () => dispatch(tryGetAllContacts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProspectPromotionModal));
