import React from 'react';
import styled from "styled-components";
import {images} from '../../../common/components/images';
import { ButtonPrimary } from '../../../common/components/buttons';
import {CommonBackground, CommonModal} from '../../../common/components/modal';
import UploadPhoto from '../../../enterprise/curriculum/containers/uploadphoto';

const Image = styled.img`
    height: 150px;
    width: 100%;
    vertical-align: top;
    object-fit: cover;
    object-position: center center;
    margin-bottom: 10px;
    border-radius: 20px;
`;

class ElementImage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            "upload": false,
            "imageUrl": ""
        }
    }

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.elementId !== this.props.elementId) {
            return this.init();
        }
    }

    init = async () => {
        this.setState({"imageUrl": this.props.imageUrl})
    }

    save = async () => {
        let isValid = true;
        this.props.onSaveElement(this.props.elementId, {"imageUrl": this.state.imageUrl}, isValid)
    }

    render() {
        if (this.state.upload && !this.props.disabled) {
            return (
                <>
                    <CommonBackground/>
                    <CommonModal style={{width: "fit-content"}}>
                        <UploadPhoto onClose={(img) => {this.setState({"upload": false, "imageUrl": img}); return this.save()}} url={this.state.imageUrl}/>
                    </CommonModal>
                </>
            )
        } else if (!this.state.upload && !this.props.disabled) {
            return (
                <div style={{position: 'relative'}}>
                    <Image src={(this.state.imageUrl !== "" && this.state.imageUrl !== null && this.state.imageUrl !== undefined) ? this.state.imageUrl : images.grayBackgroundImage} style={this.props.style} />
                    <div onClick={() => {return this.setState({"upload": true})}} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', cursor: 'pointer', zIndex: 10}}><ButtonPrimary canSubmit={false} label={"Upload"} /></div>
                </div> 
            )
        } else {
            return (<Image src={this.props.imageUrl} style={this.props.style} />)
        }
    }
}

export default ElementImage;
