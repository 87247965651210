import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';

/* Components */
import {FontBody16, FontSubtitle28} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";
import {images} from "../../../common/components/images";
import {ButtonPrimary} from "../../../common/components/buttons";

/* Containers */
import Success from '../../../iframes/success/containers/success';

/* Middleware */
import {tryPostFeedback} from '../middleware/feedback';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const TextArea = styled.textarea`
    background-color: ${colors.border30};
    margin: 0px auto;
    border-radius: 20px;
    padding: 15px 20px;
    -webkit-box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15);
    resize: none;
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    font-size: 16px;
    line-height: 22px;
    min-height: 230px;
    border: none;
    width: calc(100% - 60px);
    @media screen and (max-width: 460px) {
        
    }
    &:focus {
        outline: 0;
    }
`;
const PageTitle = styled(FontSubtitle28)`
    text-align: center;
    margin-bottom: 10px;
    color: ${colors.white};
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-around;
    max-width: 450px;
    margin: 0px auto;
    position: relative;
    z-index: 2;
`;
const BackSection = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 10px;
`;
const Back = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
`;
const ChevronLeftRight = styled.img`
    padding: 3.5px 7.5px 5.5px 7.5px;
    vertical-align: top;
    display: block;
    object-fit: contain;
    filter: brightness(0) invert(1);
`;
const InlineBlock = styled.div`
    display: inline-block;
`;
const Extra24 = styled.div`
    height: 24px;
`;
const SaveSection = styled.div`
    padding: 20px 10px 0px 10px;
    & > div > div {
        min-width: 84px;
        margin-left: auto;
        text-align: center;
    }
`;
const Background = styled.div`
    position: absolute;
    left: 320px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    z-index: 1;
    width: calc(100% - 320px);
    height: 100%;
    background-position: center top;
    background-image: url(${images.feedbackBackgroundDesktop});
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (max-width: 1088px) {
        left: 0px;
        width: 100%;
        height: 100vh;
    }
    @media screen and (max-width: 748px) {
        background-image: url(${images.feedbackBackgroundMobile});
        left: 0px;
        width: 100%;
        height: 100vh;
    }
`;

class Feedback extends React.Component {
    static defaultProps = {
        showBackButton: true,
        back: () => {},
        inputTitle: "",
        inputPlaceholder: "",
        inputButtonLabel: "",
        successTitle: "",
        successSubtitle: "",
        successButtonLabel: ""
    };

    state = {"submitted": false, "message": ''};

    handleChange = e => this.setState({"message": e.target.value});

    handleSubmit = async () => {
        if(this.state.message !== '') {
            await this.props.tryPostFeedback(this.state.message);
            this.setState({"submitted": true, "message": ''});
        }
    };

    render() {
        if(this.state.submitted) {
            return(
                <Success
                    back={this.props.back}
                    title={this.props.successTitle}
                    subtitle={this.props.successSubtitle}
                    buttonLabel={this.props.successButtonLabel}
                />
            )
        } else {
            return(
                <>
                    <Background />
                    <Container>
                        <BackSection>
                            {this.props.showBackButton ?
                                <Back onClick={this.props.back}>
                                    <ChevronLeftRight src={images.chevronLeft} alt={""} />
                                    <InlineBlock><FontBody16>{/**/}</FontBody16></InlineBlock>
                                </Back>
                                : <Extra24 />
                            }
                        </BackSection>
                        <PageTitle>{this.props.inputTitle.toUpperCase()}</PageTitle>
                        <TextArea
                            value={this.state.message}
                            onChange={this.handleChange}
                            placeholder={this.props.inputPlaceholder}
                        />
                        <SaveSection>
                            <div onClick={this.handleSubmit}>
                                <ButtonPrimary label={this.props.inputButtonLabel} canSubmit={false} />
                            </div>
                        </SaveSection>
                    </Container>
                </>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryPostFeedback: (message) => dispatch(tryPostFeedback(message))
});

export default connect(null, mapDispatchToProps)(Feedback);
