import {v4 as uuid4} from "uuid";
import moment from "moment";

export const devEnterpriseClientNotesAddData = (title, body) => ({
    "id": uuid4(),
    "title": title,
    "body": body,
    "datetime": "2020-05-27T10:00:00+00:00"
});

// export const devEnterpriseClientNotesUpdateData = (id, title, body, getState) => {
//     const updatedNote = getState.enterprise.clientsNotes.get("clientNotes").map(a => {
//         if(a.get("id") === id) {
//             return ({id: id, title: title, body: body, datetime: moment().format().toString()})
//         } else {
//             return ({ id: a.get("id"), title: a.get("title"), body: a.get("body"), datetime: a.get("datetime")})
//         }
//     });
//     return ({"clientNotes": updatedNote.toJS()});
// };
//
// export const devEnterpriseClientNotesDeleteData = (id, getState) => {
//     const deletedNotes = getState.enterprise.clientsNotes.get("clientNotes").filter(a => {
//         return(a.get("id") !== id)
//     });
//     return ({"clientNotes": deletedNotes.toJS()});
// };

export const devEnterpriseTeamNotesData = {
    "userNotes": [
        {
            "id": uuid4(),
            "title": "Note Title!",
            "body": "This is a test note.",
            "datetime": "2020-09-16T18:47:23.895213+00:00",
            "author": "Candice McGarvey",
            "authorId": 24324,
            "numberOfComments": 2,
            "authorIsActive": true
        },
        {
            "id": 123,
            "title": "Test note",
            "body": "<p>Message 1 2 3</p>",
            "datetime": "2020-03-31T19:14:06.941825+00:00",
            "author": "Hannah Coach",
            "authorId": 1,
            "numberOfComments": 0,
            "authorIsActive": true
        }
    ]
};