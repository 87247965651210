export const devMarketingCoachProspects = {
    "allProspects": [
        {
            "id": 1,
            "name": "Jason Aronson",
            "assets": {
                "savings": "$100",
                "house": "$200"
            },
            "journeys": [
                "baby"
            ],
            "scheduledCall": "2022-11-21T22:00:00+00:00",
            "status": "pending"
        },
        {
            "id": 2,
            "name": "Jenna Aronson",
            "assets": [
                { "savings": "$500" }
            ],
            "journeys": [
                "baby"
            ],
            "scheduledCall": "2022-12-15T15:00:00+00:00",
            "status": "rejected"
        },
        {
            "id": 14,
            "name": "Jason Aronson",
            "assets": {
                "savings": "$100",
                "house": "$200"
            },
            "journeys": [
                "baby"
            ],
            "scheduledCall": "2022-11-21T22:00:00+00:00",
            "status": "pending"
        },
        {
            "id": 25,
            "name": "Jenna Aronson",
            "assets": [
                { "savings": "$500" }
            ],
            "journeys": [
                "baby"
            ],
            "scheduledCall": "2022-12-15T15:00:00+00:00",
            "status": "pending"
        }
    ]
}

export const profileDetailsData = {
    "id": 5174,
    "firstName": "Rhyme",
    "lastName": "Dochtermann",
    "photos": [
        "https://res.cloudinary.com/blz-lilyriche/image/upload/v1710167893/d8favlrbhjndjprig1yw.png"
    ],
    "intro": {
        "mbti": "ENTP",
        "hobby": "Who has time for hobbies?",
        "video": "",
        "animal": "Cat",
        "zodiac": "Gemini",
        "birthOrder": "Youngest",
        "description": ""
    },
    "expertise": [
        "test"
    ],
    "specialities": [],
    "services": [],
    "disclosure": "test",
    "logos": [
        "https://res.cloudinary.com/blz-lilyriche/image/upload/v1710167893/d8favlrbhjndjprig1yw.png"
    ],
    "firmSec": "test",
    "advisorSec": "test",
    "firmName": "Test",
    "firmBio": "test",
    "firmLogo": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1710167893/d8favlrbhjndjprig1yw.png",
    "firmAddress": {
        "address": "testt"
    },
    "clientInfo": "test",
    "goodClientFit": "ideal",
    "badClientFit": "bad",
    "statesCovered": [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY"
    ],
    "feeTypes": [
        1,
        5
    ],
    "feeDescription": "add",
    "minimumInvestableAssets": 300000
}

export const leadsData = {
    "leads": [
        {
            "id": 119,
            "email": "rhyme+clienttest@trustwillow.com",
            "firstName": "Test",
            "lastName": "Rhyme",
            "requestedSessionTime": "2024-04-18T16:51:44Z",
            "status": "Matched"
        },
        {
            "id": 120,
            "email": "rhyme+nextgen@trustwillow.com",
            "firstName": "secondRhyme",
            "lastName": "secondTest",
            "requestedSessionTime": "2024-04-14T16:57:00Z",
            "status": "Follow up sent"
        }
    ]
}

export const conversation = {
    "conversation": [
        {
            "aiMsg": "AI Initial question for first name",
            "userMsg": ""
        },
        {
            "aiMsg": "",
            "userMsg": "Rhyme"
        },
        {
            "aiMsg": "Thank you, Rhyme! Could you please tell me which state you live in? You can use the abbreviation for the state.",
            "userMsg": "MA"
        },
        {
            "aiMsg": "Massachusetts is a wonderful state with rich history and vibrant culture! Now, Rhyme, could you please provide your email address so I can send you the advisor matching information?",
            "userMsg": "rhyme@trustwillow.com"
        },
        {
            "aiMsg": "Thank you for providing your email, Rhyme. Let's talk about your current life stage. How would you classify your relationship status? Are you single, married, in a relationship/domestic partnership, divorced, or widowed? If you are in the divorce process, please let me know at which stage - planning a divorce, going through a divorce, or post-divorce.",
            "userMsg": "single"
        },
        {
            "aiMsg": "That's great, Rhyme! Being single can offer a lot of flexibility and freedom in making life choices. Next, do you have children younger than 18 or others who depend financially on you?",
            "userMsg": "no"
        },
        {
            "aiMsg": "That certainly gives you a bit more flexibility in planning and managing your finances. How about animal babies? Do you have any pets in your home? If so, what kind?",
            "userMsg": "a cat name mushu"
        },
        {
            "aiMsg": "Mushu is a delightful name for a cat! Pets certainly add a lot of joy to our lives. Rhyme, here at Willow, we like to ensure we match you with a financial advisor on more than just assets, so we'd love to learn more about you.\n\nWhat are your interests, hobbies, and passions?",
            "userMsg": "I like to knit and crochet"
        },
        {
            "aiMsg": "Knitting and crocheting are such rewarding hobbies, Rhyme! They're not only creative but also very meditative and relaxing. \n\nNext, let's get more into the business side of things. There are six more questions. Are you currently employed?",
            "userMsg": "yes"
        },
        {
            "aiMsg": "That's great to hear, Rhyme! Do you own a business?",
            "userMsg": "no"
        },
        {
            "aiMsg": "Understood. Could you please tell me what profession you are in?",
            "userMsg": "Software developer"
        },
        {
            "aiMsg": "Software development is a fascinating field with endless opportunities for creativity and problem-solving. It must be quite rewarding!\n\nNow, Rhyme, I need to ask about your net worth because financial advisors work with the investable financial assets of their clients. For your net worth, please include checking & savings accounts, brokerage accounts, and retirement accounts but exclude your primary home value. Here are the options to choose from:\n\n1. if your net worth is less than $50,000;\n2. if your net worth is between $50,000 and $250,000;\n3. if your net worth is between $250,000 and $500,000;\n4. if your net worth is between $500,000 and $750,000;\n5. if your net worth is between $750,000 and $1,000,000;\n6. if your net worth is between $1,000,000 and $2,500,000;\n7. if your net worth is between $2,500,000 and $5,000,000;\n8. if your net worth is greater than $5,000,000;\n\nPlease select the option that best describes your situation.",
            "userMsg": "5"
        },
        {
            "aiMsg": "Thank you for sharing that information, Rhyme. It helps us tailor our advisor matching process to your specific financial situation.\n\nDo you currently work with a financial advisor?",
            "userMsg": "no"
        },
        {
            "aiMsg": "Since you're looking for a financial advisor, could you share why you are seeking one now? What prompted this decision?",
            "userMsg": "I want financial advice"
        },
        {
            "aiMsg": "That's a wise decision, Rhyme! Seeking professional financial advice can really help in making informed decisions and achieving your financial goals.\n\nIf we could build your perfect advisor, especially for you, what would they be like?\nAre they introverted or extroverted, all business or incorporate more emotional aspects, virtual or in-person meetings, how do they charge for their services, something else?",
            "userMsg": "can i have my matches"
        },
        {
            "aiMsg": "Rhyme, before we proceed to match you with a financial advisor, I need a bit more information to ensure the best fit. Could you please describe your ideal financial advisor as mentioned in the previous question? Additionally, tell me about your current financial situation and goals. For example, are you looking to grow wealth, become more comfortable with your financial future, save for your children's education, purchase real estate, prepare for retirement, or manage debt? This will help us tailor the advisor search to your specific needs.",
            "userMsg": "I dont have any preferences and I have no goals"
        },
        {
            "aiMsg": "Thank you for letting me know, Rhyme. It's perfectly fine to start exploring financial advice without specific preferences or goals in mind. A good financial advisor can help you clarify and set these goals over time.\n\nHere's a summary of our discussion:\n- State: Massachusetts (MA)\n- Relationship Status: Single\n- Dependents: None\n- Pets: Cat named Mushu\n- Interests: Knitting and crocheting\n- Employment: Employed as a software developer\n- Net Worth: Between $750,000 and $1,000,000\n- Currently does not work with a financial advisor and is seeking financial advice without specific preferences or goals.\n\nTo see the Financial Advisors matched for you, click the button below or paste this URL into your browser address bar:\nhttps://secure.trustwillow.com/public/advisor-directory-redirect?t1=8a042477-122a-4b64-a9b6-ed8db6dfa23d&t2=AI&ropt=5&st=MA\n\nThank you for choosing Willow, and I wish you the best in finding the right financial advisor!",
            "userMsg": ""
        }
    ]
}

/*
    id, name, assets (array), journeys (array), upcoming call date (always show the first date time scheduled or first conference not canceled)
 */
