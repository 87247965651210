import { Map, fromJS, List } from 'immutable';

/* Immutable */

const FILE_VAULT_FOLDER_TREE_FETCHING = 'FILE_VAULT_FOLDER_TREE_FETCHING';
const FILE_VAULT_FOLDER_TREE = 'FILE_VAULT_FOLDER_TREE';
const FILE_VAULT_FOLDER_LIST_FETCHING = 'FILE_VAULT_FOLDER_LIST_FETCHING';
const FILE_VAULT_FOLDER_LIST = 'FILE_VAULT_FOLDER_LIST';
const FILE_VAULT_FOLDER_NEW_CREATING = 'FILE_VAULT_FOLDER_NEW_CREATING';
const FILE_VAULT_FOLDER_NEW = 'FILE_VAULT_FOLDER_NEW';
const FILE_VAULT_FOLDER_NAME_EDITING = 'FILE_VAULT_FOLDER_NAME_EDITING';
const FILE_VAULT_FOLDER_NAME_EDITED = 'FILE_VAULT_FOLDER_NAME_EDITED';
const FILE_VAULT_FOLDER_MOVING = 'FILE_VAULT_FOLDER_MOVING';
const FILE_VAULT_FOLDER_MOVED = 'FILE_VAULT_FOLDER_MOVED';
const FILE_VAULT_FOLDER_DELETING = 'FILE_VAULT_FOLDER_DELETING';
const FILE_VAULT_FOLDER_DELETED = 'FILE_VAULT_FOLDER_DELETED';
const FILE_VAULT_FILE_DOWNLOADING = 'FILE_VAULT_FILE_DOWNLOADING';
const FILE_VAULT_FILE_UPLOADING = 'FILE_VAULT_FILE_UPLOADING';
const FILE_VAULT_FILE_UPLOADED = 'FILE_VAULT_FILE_UPLOADED';
const FILE_VAULT_FILE_RENAMING = 'FILE_VAULT_FILE_RENAMING';
const FILE_VAULT_FILE_RENAMED = 'FILE_VAULT_FILE_RENAMED';
const FILE_VAULT_FILE_DELETING = 'FILE_VAULT_FILE_DELETING';
const FILE_VAULT_FILE_DELETED = 'FILE_VAULT_FILE_DELETED';
const FILE_VAULT_BREADCRUMB = 'FILE_VAULT_BREADCRUMB';
const FILE_VAULT_BREADCRUMB_UPDATE = 'FILE_VAULT_BREADCRUMB_UPDATE';
const FILE_VAULT_FOLDER_NEW_MODAL = 'FILE_VAULT_FOLDER_NEW_MODAL';
const FILE_VAULT_FOLDER_OR_FILE_RENAME_MODAL = 'FILE_VAULT_FOLDER_OR_FILE_RENAME_MODAL';
const FILE_VAULT_FOLDER_OR_FILE_DELETE_MODAL = 'FILE_VAULT_FOLDER_OR_FILE_DELETE_MODAL';
const FILE_VAULT_FOLDER_OR_FILE_DELETE_ALL_MODAL = 'FILE_VAULT_FOLDER_OR_FILE_DELETE_ALL_MODAL';
const FILE_VAULT_FOLDER_OR_FILE_UPLOAD_MODAL = 'FILE_VAULT_FOLDER_OR_FILE_UPLOAD_MODAL';
const FILE_VAULT_FOLDER_OR_FILE_SELECTED = 'FILE_VAULT_FOLDER_OR_FILE_SELECTED';
const FILE_VAULT_STORE_RESET = 'FILE_VAULT_STORE_RESET';
const FILE_VAULT_SHARED_DRIVE_CREATED = 'FILE_VAULT_SHARED_DRIVE_CREATED';
const FILE_VAULT_SHARED_DRIVE_CREATING = 'FILE_VAULT_SHARED_DRIVE_CREATING';
const FILE_VAULT_SHARED_DRIVE_LIST_FETCHING = 'FILE_VAULT_SHARED_DRIVE_LIST_FETCHING';
const FILE_VAULT_SHARED_DRIVE_LIST = 'FILE_VAULT_SHARED_DRIVE_LIST';
const FILE_VAULT_SHARED_DRIVE_FOLDER_LIST_FETCHING = 'FILE_VAULT_SHARED_DRIVE_FOLDER_LIST_FETCHING';
const FILE_VAULT_SHARED_DRIVE_FOLDER_LIST = 'FILE_VAULT_SHARED_DRIVE_FOLDER_LIST';
const FILE_VAULT_SHARED_DRIVE = 'FILE_VAULT_SHARED_DRIVE';
const FILE_VAULT_DRIVE_RENAMING = 'FILE_VAULT_DRIVE_RENAMING';
const FILE_VAULT_DRIVE_RENAMED = 'FILE_VAULT_DRIVE_RENAMED';
const FILE_VAULT_DRIVE_DELETING = 'FILE_VAULT_DRIVE_DELETING';
const FILE_VAULT_DRIVE_DELETED = 'FILE_VAULT_DRIVE_DELETED';
const FILE_VAULT_DRIVE_RENAME_MODAL = 'FILE_VAULT_DRIVE_RENAME_MODAL';
const FILE_VAULT_DRIVE_DELETE_MODAL = 'FILE_VAULT_DRIVE_DELETE_MODAL';
const FILE_VAULT_DRIVE_ADDING_USER = 'FILE_VAULT_DRIVE_ADDING_USER';
const FILE_VAULT_DRIVE_ADDED_USER = 'FILE_VAULT_DRIVE_ADDED_USER';
const FILE_VAULT_DRIVE_REMOVING_USER = 'FILE_VAULT_DRIVE_REMOVING_USER';
const FILE_VAULT_DRIVE_REMOVED_USER = 'FILE_VAULT_DRIVE_REMOVED_USER';
const FILE_VAULT_DRIVE_ADD_USER_MODAL = 'FILE_VAULT_DRIVE_ADDING_USER_MODAL';
const FILE_VAULT_DRIVE_REMOVE_USER_MODAL = 'FILE_VAULT_DRIVE_REMOVE_USER_MODAL';
const FILE_VAULT_DRIVE_SEARCH_RESULTS = 'FILE_VAULT_DRIVE_SEARCH_RESULTS';
const FILE_VAULT_DRIVE_SEARCH_STRING = 'FILE_VAULT_DRIVE_SEARCH_STRING';


/* Actions */

export const setFolderTreeFetching = (status) => ({'type': FILE_VAULT_FOLDER_TREE_FETCHING, 'status': status});
export const setFolderTree = (folderTree) => ({'type': FILE_VAULT_FOLDER_TREE, 'folderTree': folderTree});
export const setFolderListFetching = (status) => ({'type': FILE_VAULT_FOLDER_LIST_FETCHING, 'status': status});
export const setFolderList = (folderList) => ({'type': FILE_VAULT_FOLDER_LIST, 'folderList': folderList});
export const setFolderNewCreating = (status) => ({'type': FILE_VAULT_FOLDER_NEW_CREATING, 'status': status});
export const setFolderNew = (newFolder) => ({'type': FILE_VAULT_FOLDER_NEW, 'newFolder': newFolder});
export const setFolderNameEditing = (status) => ({'type': FILE_VAULT_FOLDER_NAME_EDITING, 'status': status});
export const setFolderNameEdited = (editedFolder) => ({'type': FILE_VAULT_FOLDER_NAME_EDITED, 'editedFolder': editedFolder});
export const setFolderMoving = (status) => ({'type': FILE_VAULT_FOLDER_MOVING, 'status': status});
export const setFolderMoved = (movedFolder) => ({'type': FILE_VAULT_FOLDER_MOVED, 'movedFolder': movedFolder});
export const setFolderDeleting = (status) => ({'type': FILE_VAULT_FOLDER_DELETING, 'status': status});
export const setFolderDeleted = (deletedFolder) => ({'type': FILE_VAULT_FOLDER_DELETED, 'deletedFolder': deletedFolder});
export const setFileDownloading = (status) => ({'type': FILE_VAULT_FILE_DOWNLOADING, 'status': status});
export const setFileUploading = (status) => ({'type': FILE_VAULT_FILE_UPLOADING, 'status': status});
export const setFileUploaded = (uploadedFile) => ({'type': FILE_VAULT_FILE_UPLOADED, 'uploadedFile': uploadedFile});
export const setFileRenaming = (status) => ({'type': FILE_VAULT_FILE_RENAMING, 'status': status});
export const setFileRenamed = (renamedFile) => ({'type': FILE_VAULT_FILE_RENAMED, 'renamedFile': renamedFile});
export const setFileDeleting = (status) => ({'type': FILE_VAULT_FILE_DELETING, 'status': status});
export const setFileDeleted = (deletedFile) => ({'type': FILE_VAULT_FILE_DELETED, 'deletedFile': deletedFile});
export const setFolderNewModal = (status) => ({'type': FILE_VAULT_FOLDER_NEW_MODAL, 'status': status});
export const setFolderOrFileRenameModal = (status) => ({'type': FILE_VAULT_FOLDER_OR_FILE_RENAME_MODAL, 'status': status});
export const setFolderOrFileDeleteModal = (status) => ({'type': FILE_VAULT_FOLDER_OR_FILE_DELETE_MODAL, 'status': status});
export const setFolderOrFileDeleteAllModal = (status) => ({'type': FILE_VAULT_FOLDER_OR_FILE_DELETE_ALL_MODAL, 'status': status});
export const setFolderOrFileUploadModal = (status) => ({'type': FILE_VAULT_FOLDER_OR_FILE_UPLOAD_MODAL, 'status': status});
export const setFolderOrFileSelected = (selected) => ({'type': FILE_VAULT_FOLDER_OR_FILE_SELECTED, 'selected': selected});
export const setBreadcrumb = (currentFolder) => ({'type': FILE_VAULT_BREADCRUMB, 'currentFolder': currentFolder});
export const setBreadcrumbUpdate = (breadcrumb) => ({'type': FILE_VAULT_BREADCRUMB_UPDATE, 'breadcrumb': breadcrumb});
export const setFileVaultStoreReset = () => ({'type': FILE_VAULT_STORE_RESET});
export const setSharedDriveCreatingNew = (status) => ({'type': FILE_VAULT_SHARED_DRIVE_CREATING, 'status': status});
export const setSharedDriveCreated = (sharedDrive) => ({'type': FILE_VAULT_SHARED_DRIVE_CREATED, 'sharedDrive': sharedDrive});
export const setSharedDriveListFetching = (status) => ({'type': FILE_VAULT_SHARED_DRIVE_LIST_FETCHING, 'status': status});
export const setSharedDriveList = (sharedDriveList) => ({'type': FILE_VAULT_SHARED_DRIVE_LIST, 'sharedDriveList': sharedDriveList});
export const setSharedDriveFolderListFetching = (status) => ({'type': FILE_VAULT_SHARED_DRIVE_FOLDER_LIST_FETCHING, 'status': status});
export const setSharedDriveFolderList = (sharedDriveFolderList) => ({'type': FILE_VAULT_SHARED_DRIVE_FOLDER_LIST, 'sharedDriveFolderList': sharedDriveFolderList});
export const setSharedDrive = (sharedDrive) => ({'type': FILE_VAULT_SHARED_DRIVE, 'sharedDrive': sharedDrive});
export const setDriveRenaming = (status) => ({'type': FILE_VAULT_DRIVE_RENAMING, 'status': status});
export const setDriveRenamed = (renamedDrive) => ({'type': FILE_VAULT_DRIVE_RENAMED, 'renamedDrive': renamedDrive});
export const setDriveDeleting = (status) => ({'type': FILE_VAULT_DRIVE_DELETING, 'status': status});
export const setDriveDeleted = (deletedDrive) => ({'type': FILE_VAULT_DRIVE_DELETED, 'deletedDrive': deletedDrive});
export const setDriveRenameModal = (status) => ({'type': FILE_VAULT_DRIVE_RENAME_MODAL, 'status': status});
export const setDriveDeleteModal = (status) => ({'type': FILE_VAULT_DRIVE_DELETE_MODAL, 'status': status});

export const setDriveAddingUser = (status) => ({'type': FILE_VAULT_DRIVE_ADDING_USER, 'status': status});
export const setDriveAddedUser = (drive) => ({'type': FILE_VAULT_DRIVE_ADDED_USER, 'drive': drive});
export const setDriveRemovingUser = (status) => ({'type': FILE_VAULT_DRIVE_REMOVING_USER, 'status': status});
export const setDriveRemovedUser = (drive) => ({'type': FILE_VAULT_DRIVE_REMOVED_USER, 'drive': drive});
export const setDriveAddUserModal = (status) => ({'type': FILE_VAULT_DRIVE_ADD_USER_MODAL, 'status': status});
export const setDriveRemoveUserModal = (status) => ({'type': FILE_VAULT_DRIVE_REMOVE_USER_MODAL, 'status': status});
export const setDriveSearchResult = (results) => ({'type': FILE_VAULT_DRIVE_SEARCH_RESULTS, 'results': results});
export const setDriveSearchString = (searchString) => ({'type': FILE_VAULT_DRIVE_SEARCH_STRING, 'searchString': searchString});

/* Initial State */

const initialState = Map({
    folderBreadcrumb: List(),
    folderTreeFetching: false,
    folderTree: Map(),
    folderListFetching: false,
    folderList: Map(),
    folderNewCreating: false,
    folderNameEditing: false,
    folderMoving: false,
    folderDeleting: false,
    fileDownloading: false,
    fileUploading: false,
    fileRenaming: false,
    fileDeleting: false,
    folderNewModal: false,
    folderOrFileSelected: Map(),
    folderOrFileRenameModal: false,
    folderOrFileDeleteModal: false,
    folderOrFileDeleteAllModal: false,
    folderOrFileUploadModal: false,
    sharedDriveCreating: false,
    sharedDrive: Map(),
    sharedDriveListFetching: false,
    sharedDriveList: List(),
    driveDeleting: false,
    driveRenaming: false,
    driveRenameModal: false,
    driveDeleteModal: false,
    driveAddingUser: false,
    driveRemovingUser: false,
    driveAddUserModal: false,
    driveRemoveUserModal: false,
    driveSearchResults: List(),
    driveSearchString: '',
});

/* Reducer */

const fileVaultReducer = (state=initialState, action) => {
    switch (action.type) {
        case FILE_VAULT_FOLDER_TREE_FETCHING:
            return state.merge({'folderTreeFetching': action.status});
        case FILE_VAULT_FOLDER_LIST_FETCHING:
            return state.merge({'folderListFetching': action.status});
        case FILE_VAULT_FOLDER_NEW_CREATING:
            return state.merge({'folderNewCreating': action.status});
        case FILE_VAULT_FOLDER_NAME_EDITING:
            return state.merge({'folderNameEditing': action.status});
        case FILE_VAULT_FOLDER_MOVING:
            return state.merge({'folderMoving': action.status});
        case FILE_VAULT_FOLDER_DELETING:
            return state.merge({'folderDeleting': action.status});
        case FILE_VAULT_FILE_DOWNLOADING:
            return state.merge({'fileDownloading': action.status});
        case FILE_VAULT_FILE_UPLOADING:
            return state.merge({'fileUploading': action.status});
        case FILE_VAULT_FILE_RENAMING:
            return state.merge({'fileRenaming': action.status});
        case FILE_VAULT_FILE_DELETING:
            return state.merge({'fileDeleting': action.status});
        case FILE_VAULT_FOLDER_TREE:
            return state.merge({'folderTree': fromJS(action.folderTree)});
        case FILE_VAULT_FOLDER_LIST:
            return state.merge({'folderList': fromJS(action.folderList)});
        case FILE_VAULT_FOLDER_NEW_MODAL:
            return state.merge({'folderNewModal': action.status});
        case FILE_VAULT_FOLDER_OR_FILE_UPLOAD_MODAL:
            return state.merge({'folderOrFileUploadModal': action.status});
        case FILE_VAULT_FOLDER_OR_FILE_RENAME_MODAL:
            return state.merge({'folderOrFileRenameModal': action.status});
        case FILE_VAULT_FOLDER_OR_FILE_DELETE_MODAL:
            return state.merge({'folderOrFileDeleteModal': action.status});
        case FILE_VAULT_FOLDER_OR_FILE_DELETE_ALL_MODAL:
            return state.merge({'folderOrFileDeleteAllModal': action.status});
        case FILE_VAULT_FOLDER_OR_FILE_SELECTED:
            return state.merge({'folderOrFileSelected': fromJS(action.selected)});
        case FILE_VAULT_FOLDER_NEW:
            return state.merge({
                "folderList": fromJS({
                    ...state.get("folderList").toJS(),
                    "folders": state.get("folderList").get("folders").push(fromJS({
                        "id": action.newFolder.id,
                        "name": action.newFolder.name,
                        "contentType": "folder"
                    }))
                })
            });
        case FILE_VAULT_FOLDER_NAME_EDITED:
            return state.merge({
                "folderList": fromJS({
                    ...state.get("folderList").toJS(),
                    "folders": fromJS(state.get("folderList").get("folders").map(f => {
                        if(f.get("id") === action.editedFolder.id) {
                            return(fromJS({...f.toJS(), "name": action.editedFolder.name}))
                        } else {
                            return(fromJS(f))
                        }
                    }))
                })
            });
        case FILE_VAULT_FILE_RENAMED:
            return state.merge({
                "folderList": fromJS({
                    ...state.get("folderList").toJS(),
                    "files": fromJS(state.get("folderList").get("files").map(f => {
                        if(f.get("id") === action.renamedFile.id) {
                            return(fromJS({...f.toJS(), "name": action.renamedFile.name}))
                        } else {
                            return(fromJS(f))
                        }
                    }))
                })
            });
        case FILE_VAULT_FOLDER_DELETED:
            return state.merge({
                "folderList": fromJS({
                    ...state.get("folderList").toJS(),
                    "folders": fromJS(state.get("folderList").get("folders").filter(f => {return(f.get("id") !== action.deletedFolder)}))
                })
            });
        case FILE_VAULT_FILE_DELETED:
            return state.merge({
                "folderList": fromJS({
                    ...state.get("folderList").toJS(),
                    "files": fromJS(state.get("folderList").get("files").filter(f => {return(f.get("id") !== action.deletedFile)}))
                })
            });
        case FILE_VAULT_FILE_UPLOADED:
            return state.merge({
                "folderList": fromJS({
                    ...state.get("folderList").toJS(),
                    "files": state.get("folderList").get("files").push(fromJS({
                        "id": action.uploadedFile.id,
                        "name": action.uploadedFile.name,
                        "contentType": action.uploadedFile.contentType,
                        "size": action.uploadedFile.size
                    }))
                })
            });
        case FILE_VAULT_BREADCRUMB:
            return state.merge({
                'folderBreadcrumb': state.get("folderBreadcrumb").push(fromJS({
                    "id": action.currentFolder.folderId === null ? "root" : action.currentFolder.folderId,
                    "parentId": (action.currentFolder.folderId !== null && action.currentFolder.parentFolderId === null) ? "root" : action.currentFolder.parentFolderId,
                    "name": action.currentFolder.folderId === null ? "Drive" : action.currentFolder.name
                }))
            });
        case FILE_VAULT_BREADCRUMB_UPDATE:
            return state.merge({'folderBreadcrumb': fromJS(action.breadcrumb)});
        case FILE_VAULT_STORE_RESET:
            return state.merge(initialState);
        case FILE_VAULT_SHARED_DRIVE_CREATED: 
            return state.merge({'sharedDrive': fromJS(action.sharedDrive)});
        case FILE_VAULT_SHARED_DRIVE_CREATING: 
            return state.merge({'sharedDriveCreating': action.status});
        case FILE_VAULT_SHARED_DRIVE_LIST_FETCHING:
            return state.merge({'sharedDriveListFetching': action.status});
        case FILE_VAULT_SHARED_DRIVE_LIST:
            return state.merge({'sharedDriveList': fromJS(action.sharedDriveList)});
        case FILE_VAULT_SHARED_DRIVE_FOLDER_LIST_FETCHING:
            return state.merge({'folderListFetching': action.status});
        case FILE_VAULT_SHARED_DRIVE_FOLDER_LIST:
            return state.merge({'folderList': fromJS(action.sharedDriveFolderList)});
        case FILE_VAULT_SHARED_DRIVE:
            return state.merge({'sharedDrive': fromJS(action.sharedDrive)}); 
        case FILE_VAULT_DRIVE_RENAMING:
            return state.merge({'driveRenaming': action.status});
        case FILE_VAULT_DRIVE_RENAMED:
            return state.merge({
                "sharedDrive": fromJS({
                    ...state.get("sharedDrive").toJS(),
                    "name": action.renamedDrive.name
                }),
                "sharedDriveList":
                    fromJS(state.get("sharedDriveList").map(f => {
                        if(f.get("driveId") === action.renamedDrive.driveId) {
                            return(fromJS({...f.toJS(), "name": action.renamedDrive.name}))
                        } else {
                            return(fromJS(f))
                        }
                    }))
            });
        case  FILE_VAULT_DRIVE_DELETING:
            return state.merge({'driveDeleting': action.status});
        case FILE_VAULT_DRIVE_DELETED:
            return state.merge({
                "sharedDriveList": 
                    fromJS(state.get("sharedDriveList").filter(f => {return(f.get("driveId") !== action.deletedDrive)}).sort())
            });
        case FILE_VAULT_DRIVE_RENAME_MODAL:
            return state.merge({'driveRenameModal': action.status});
        case FILE_VAULT_DRIVE_DELETE_MODAL:
            return state.merge({'driveDeleteModal': action.status});

        case FILE_VAULT_DRIVE_ADDING_USER:
            return state.merge({'driveAddingUser': action.status});
        case FILE_VAULT_DRIVE_ADDED_USER:
            return state.merge({
                "sharedDrive": fromJS({
                    ...state.get("sharedDrive").toJS(),
                    "users": action.drive.users
                }),
                "sharedDriveList":
                    fromJS(state.get("sharedDriveList").map(f => {
                        if(f.get("driveId") === action.drive.driveId) {
                            return(fromJS({...f.toJS(), "users": action.drive.users}))
                        } else {
                            return(fromJS(f))
                        }
                    }))
                });
        case  FILE_VAULT_DRIVE_REMOVING_USER:
            return state.merge({'driveRemovingUser': action.status});
        case FILE_VAULT_DRIVE_REMOVED_USER:
            return state.merge({
                "sharedDrive": fromJS({
                    ...state.get("sharedDrive").toJS(),
                    "users": action.drive.users
                }),
                "sharedDriveList":
                    fromJS(state.get("sharedDriveList").map(f => {
                        if(f.get("driveId") === action.drive.driveId) {
                            return(fromJS({...f.toJS(), "users": action.drive.users}))
                        } else {
                            return(fromJS(f))
                        }
                    }))
                });
        case FILE_VAULT_DRIVE_ADD_USER_MODAL:
            return state.merge({'driveAddUserModal': action.status});
        case FILE_VAULT_DRIVE_REMOVE_USER_MODAL:
            return state.merge({'driveRemoveUserModal': action.status});
        case FILE_VAULT_DRIVE_SEARCH_RESULTS:
            return state.merge({'driveSearchResults': fromJS(action.results)});
        case FILE_VAULT_DRIVE_SEARCH_STRING:
            return state.merge({'driveSearchString': action.searchString});
        default:
            return state;
    }
};

export default fileVaultReducer;
