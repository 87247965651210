import React from "react";
import moment from "moment";
import {connect} from "react-redux";

/* Components */
import {MessageFilled, MobileFilled} from "@ant-design/icons";
import {FontBody12, FontBody14, FontTitle18} from "../../common/components/fonts";
import {Card, Icon, Body, Title, Action, Content, DateItem, BottomBorder15, Attendees} from '../components/contactactivity';
import {LogoLoading} from "../../common/components/loading";

class SMS extends React.Component {
    state={isLoading: true, data: {}, quill: ''};

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.data !== this.props.data) {
            return this.init();
        }
    }

    init = async () => {
        await this.setState({"data": this.props.data});
        return this.setState({"isLoading": false});
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <Card>
                    <Icon>{this.state.data.get("channel") === "sms" ? <MobileFilled /> : <MessageFilled />}</Icon>
                    <Body>
                        <div>
                            <Title><FontTitle18>{this.state.data.get("channel") === "sms" ? "SMS" : "Video Chat"}</FontTitle18></Title>
                            <Action>
                                <DateItem><FontBody12>{moment(this.state.data.get("formattedDatetime")).format("MMM D, YYYY [at] h:mm a")}</FontBody12></DateItem>
                            </Action>
                        </div>
                        <Content>
                            {this.state.data.get("isFromLily") &&
                                <Attendees
                                    senderName={this.props.client.get("firstName")+" "+
                                    (!this.props.isWMC && this.props.client.get("referralSource") === "wmc" ? this.props.client.get("lastName").charAt(0)+"." : this.props.client.get("lastName"))}
                                    recipientName={this.props.user.get("first")+" "+this.props.user.get("last")}
                                />
                            }
                            {this.state.data.get("isFromAdvisor") &&
                                <Attendees
                                    senderName={this.props.user.get("first")+" "+this.props.user.get("last")}
                                    recipientName={this.props.client.get("firstName")+" "+
                                    (!this.props.isWMC && this.props.client.get("referralSource") === "wmc" ? this.props.client.get("lastName").charAt(0)+"." : this.props.client.get("lastName"))}
                                />
                            }
                            <BottomBorder15 />
                            <FontBody14>{this.state.data.get("content")}</FontBody14>
                        </Content>
                    </Body>
                </Card>
            )
        }
    }
}

const mapStateToProps = state => ({
    client: state.enterprise.clientsDetail.get("client"),
    user: state.common.user,
    isWMC: state.common.wmc.get("isWMC")
});

export default connect(mapStateToProps, null)(SMS);
