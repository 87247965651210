import React from 'react';
import styled from 'styled-components';

/* Containers */
import AppointmentContainer from "../../appointments/containers/appointment";
import AppointmentContainerV2 from "../../appointments/containers/appointmentv2";

/* Components */
import {colors} from '../../../common/components/colors';
import {ArrowLeftOutlined} from '@ant-design/icons';

const Container = styled.div`
    max-width: 768px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 0px auto 0px auto;
`;

const BackTop = styled.div`
    color: ${colors.secondary70};
    width: fit-content;
    display: block;
    cursor: pointer;
    position: relative;
    z-index: 25;
    ${props => props.useNewDesign === true} {
        padding: 5px 10px;
    }
    ${props => props.useNewDesign === false} {
        padding: 5px 0;
    }
    @media screen and (max-width: 748px) {
        margin-left: 60px;
    }
    @media screen and (max-width: 460px) {
        margin-left: 0px;
    }
`;

class Availability extends React.Component {
    static defaultProps = {
        useNewDesign: false,
    };
    render() {
        console.log('usenewdesign:', this.props.useNewDesign)
        return (
            <Container>
                <BackTop onClick={this.props.availabilityOnBack}><ArrowLeftOutlined/></BackTop>
                {this.props.useNewDesign ?
                    <AppointmentContainerV2
                        coachId={this.props.userId}
                        coachFirst={this.props.firstName}
                        coachLast={this.props.lastName}
                        coachImg={this.props.photo}
                        onSelectTime={this.props.onSelectTime}
                        meetingLength={this.props.meetingLength}
                        title={(`Select a time to meet with ${this.props.firstName}`).toUpperCase()}
                    /> : 
                    <AppointmentContainer
                        coachId={this.props.userId}
                        coachFirst={this.props.firstName}
                        coachLast={this.props.lastName}
                        coachImg={this.props.photo}
                        onSelectTime={this.props.onSelectTime}
                        meetingLength={this.props.meetingLength}
                    />
                }
            </Container>
        )
    }
}

export default Availability;
