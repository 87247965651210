import React from 'react';
import styled from "styled-components";
import { ButtonPrimary } from '../../../common/components/buttons';
import {CommonBackground, CommonModal} from '../../../common/components/modal';
import VideoUpload from '../../../enterprise/curriculum/containers/videoupload';

export const Video = styled.video`
    margin-bottom: 10px;
    border-radius: 20px;
`;

class ElementVideo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            "upload": false,
            "videoUrl": ""
        }
    }

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.elementId !== this.props.elementId) {
            return this.init();
        }
    }

    init = async () => {
        this.setState({"videoUrl": this.props.videoUrl})
    }

    save = async () => {
        let isValid = true;
        this.props.onSaveElement(this.props.elementId, {"videoUrl": this.state.videoUrl}, isValid)
    }

    render() {
        if (this.state.upload && !this.props.disabled) {
            return (
                <>
                    <CommonBackground/>
                    <CommonModal style={{width: "fit-content"}}>
                        <VideoUpload onClose={(img) => {this.setState({"upload": false, "videoUrl": img}); return this.save()}} url={this.state.videoUrl}/>
                    </CommonModal>
                </>
            )
        } else if (!this.state.upload && !this.props.disabled) {
            return (
                <div style={{position: 'relative'}}>
                    <Video src={this.state.videoUrl} style={this.props.style} width={"100%"} height={"auto"} controls={true} />
                    <div onClick={() => {return this.setState({"upload": true})}} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', cursor: 'pointer', zIndex: 10}}><ButtonPrimary canSubmit={false} label={"Upload"} /></div>
                </div> 
            )
        } else {
            return (<Video src={this.state.videoUrl} style={this.props.style} width={"100%"} height={"auto"} controls={true} />)
        }
    }
}

export default ElementVideo;
