import React from 'react';
import {v4 as uuid4} from 'uuid';
import ReactQuill from "react-quill";
import {connect} from "react-redux";

/* Components */
import {FontBody14, FontBody16, FontTitle24, FontHeader18} from '../../../common/components/fonts';
import {DropdownInput} from '../../../common/components/inputs';
import {CloseOutlined, LineOutlined} from '@ant-design/icons';
import {ButtonPrimary, ButtonTertiary, ButtonInactive} from '../../../common/components/buttons';
import {ToFrom, To, Templates, Subject, Sent, DropdownSize, Compose, CloseMessage, CloseButton, Close, Buttons, Body, Modal, ConfirmActions, ConfirmBtnFitLeft, ConfirmBtnFitRight, ConfirmLeft, ConfirmRight, ConfirmTitle, ComposeWrapper, makeContentReplySection} from '../components/emailercompose';

/* Containers */
import {editorFormats, editorModules} from "../../../common/components/richtexteditor";

/* Middleware */
import {tryPostCoachClientMessages, tryPostCoachCoachMessages, tryGetCoachClientPurchaseLink} from "../middleware/messages";

/* Domains */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

class EmailCompose extends React.Component {
    state={
        "body": '',
        "subject": '',
        "quill": '',
        "confirmModal": false,
        "switchModal": false,
        "isSent": false,
        "isMinimized": false,
        "isReply": false,
        "purchaseToken": null,
        "isLoading": true,
        "isOpen": false,
        "createEmailTemplates": () => {}
    };

    componentDidMount() {}

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.lastInitiated !== this.props.lastInitiated && this.props.recipientId !== this.state.recipientId) {
            if(this.state.isOpen) {
                return this.setState({"switchModal": true})
            } else {
                return this.open()
            }
        }
    }

    open = async () => {
        await this.setState({
            "isLoading": true,
            "subject": (this.props.subject === null ? '' : this.props.subject),
            "body": (this.props.body === null ? '' : (this.props.isReply ? makeContentReplySection(this.props.body) : this.props.body)),
            "isReply": this.props.isReply,
            "quill": '',
            "isOpen": this.props.isOpen,
            "isMinimized": false,
            "isSent": false,
            "recipientId": this.props.recipientId,
            "recipientType": this.props.recipientType,
            "recipientFirstName": this.props.recipientFirstName,
            "recipientLastName": this.props.recipientLastName,
            "purchaseToken": this.props.recipientType === "consumer" ? await this.props.tryGetCoachClientPurchaseLink(this.props.recipientId) : null,
            "wmcsAssignedToCoach": this.props.wmcsAssignedToCoach.map(w => {return(w.get("id"))}).toJS(),
            "createEmailTemplates": env.ENTERPRISE.EXPERT.TEMPLATES.EMAIL
        });
        this.handleNewQuill();
    };

    close = async () => {
        await this.setState({body: '', subject: '', quill: '', confirmModal: false, switchModal: false, isReply: false, purchaseToken: null, isOpen: false, recipientId: null});
    };

    switch = async () => {
        await this.open();
        this.setState({"switchModal": false});
    };

    handleNewContent = e => this.setState({"body": e});
    handleNewSubject = e => this.setState({"subject": e.target.value});
    handleNewQuill = () => {
        console.log(this.state.body);
        this.setState({
            "quill": <ReactQuill
                id={uuid4()}
                theme={"snow"}
                onChange={this.handleNewContent}
                value={this.state.body}
                modules={editorModules}
                formats={editorFormats}
                bounds={'.app'}
                placeholder={"Write your email here"}
            />,
            "isLoading": false
        });
    };

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    handleSendEmail = async () => {
        if(this.state.recipientType === "consumer") {
            await this.props.tryPostCoachClientMessages("email", this.state.body.replace("<p><br></p>", "<p/>"), this.state.subject, this.state.recipientId);
        } else {
            await this.props.tryPostCoachCoachMessages("email", this.state.body.replace("<p><br></p>", "<p/>"), this.state.subject, this.state.recipientId);
        }
        this.close();
        this.setState({isSent: true});
        this.timeout = setTimeout(() => {this.setState({isSent: false})}, 3000);
    };

    handleSelectTemplate = async (e) => {
        const templates = await this.state.createEmailTemplates(this.state.recipientFirstName, this.props.userName, this.state.purchaseToken, this.props.isWMC, this.state.wmcsAssignedToCoach);
        templates.map(async m => {
            if(m.value === e.value) {
                await this.setState({"body": m.body});
                this.setState({"subject": m.subject});
                this.handleNewQuill()
            }
        });
    };

    handleMinimize = async () => {
        this.setState({"isMinimized": !this.state.isMinimized});
    };

    render() {
        if(this.state.isOpen) {
            console.log(!(this.state.body === '' || this.state.subject === '' || this.state.body === '<p><br></p>'))
            return (
                <>
                    <ComposeWrapper minimized={this.state.isMinimized}>
                        <Close>
                            <CloseMessage onClick={this.handleMinimize} ><FontBody14>{this.state.isReply ? "Reply Email" : "New Email"}</FontBody14></CloseMessage>
                            <CloseButton onClick={this.handleMinimize} ><LineOutlined /></CloseButton>
                            <CloseButton onClick={() => {this.setState({"confirmModal": true})}} ><CloseOutlined /></CloseButton>
                        </Close>
                        {!this.state.isMinimized &&
                            <Compose>
                                <To><ToFrom isFromMe={false} who={"To"} recipient={this.state.recipientFirstName+" "+this.state.recipientLastName}/></To>
                                {/*<ToFrom isFromMe={false} who={"From"} recipient={this.props.userName}/>*/}
                                <FontHeader18>
                                    <Subject required value={this.state.subject} onChange={this.handleNewSubject} placeholder={"Subject"}/>
                                </FontHeader18>
                                <Body>{this.state.quill}</Body>
                                <Buttons>
                                    {this.props.platform === "consumer" &&
                                        <Templates>
                                            {!(this.state.body === '' || this.state.subject === '' || this.state.body === '<p><br></p>')
                                                ? <ConfirmBtnFitLeft onClick={this.handleSendEmail}><ButtonPrimary label={"Send"} canSubmit={false} /></ConfirmBtnFitLeft>
                                                : <ButtonInactive label={"Send"} canSubmit={false} />
                                            }
                                        </Templates>
                                    }
                                    {this.props.platform !== "consumer" &&
                                        <div>
                                            <Templates>
                                                {!(this.state.body === '' || this.state.subject === '' || this.state.body === '<p><br></p>')
                                                    ? <ConfirmBtnFitLeft onClick={this.handleSendEmail}><ButtonPrimary label={"Send"} canSubmit={false} /></ConfirmBtnFitLeft>
                                                    : <ButtonInactive label={"Send"} canSubmit={false} />
                                                }
                                            </Templates>
                                            <Templates>
                                                <DropdownSize>
                                                    <DropdownInput
                                                        dropUpwards={true}
                                                        id={"templates"}
                                                        placeholder={"Templates"}
                                                        value={"Templates"}
                                                        options={this.state.createEmailTemplates(this.state.recipientFirstName, this.props.userName, this.state.purchaseToken, this.props.isWMC, this.state.wmcsAssignedToCoach)}
                                                        onChange={this.handleSelectTemplate}
                                                    />
                                                </DropdownSize>
                                            </Templates>
                                        </div>
                                    }
                                </Buttons>
                            </Compose>
                        }
                    </ComposeWrapper>
                    {this.state.confirmModal &&
                        <Modal>
                            <ConfirmTitle><FontTitle24>Are you sure?</FontTitle24></ConfirmTitle>
                            <FontBody16>Do you want to delete this draft email? This cannot be undone.</FontBody16>
                            <ConfirmActions>
                                <ConfirmLeft>
                                    <ConfirmBtnFitLeft onClick={() => {this.setState({"confirmModal": false})}}><ButtonTertiary label={"Cancel"} canSubmit={true} /></ConfirmBtnFitLeft>
                                </ConfirmLeft>
                                <ConfirmRight>
                                    <ConfirmBtnFitRight onClick={async () => {this.close()}}><ButtonPrimary label={"Delete"} canSubmit={true} /></ConfirmBtnFitRight>
                                </ConfirmRight>
                            </ConfirmActions>
                        </Modal>
                    }
                    {this.state.switchModal &&
                        <Modal>
                            <ConfirmTitle><FontTitle24>Are you sure?</FontTitle24></ConfirmTitle>
                            <FontBody16>Do you want to delete this draft email? This cannot be undone.</FontBody16>
                            <ConfirmActions>
                                <ConfirmLeft>
                                    <ConfirmBtnFitLeft onClick={() => {this.setState({"switchModal": false})}}><ButtonTertiary label={"Cancel"} canSubmit={true} /></ConfirmBtnFitLeft>
                                </ConfirmLeft>
                                <ConfirmRight>
                                    <ConfirmBtnFitRight onClick={async () => {this.switch()}}><ButtonPrimary label={"Delete"} canSubmit={true} /></ConfirmBtnFitRight>
                                </ConfirmRight>
                            </ConfirmActions>
                        </Modal>
                    }
                </>
            )
        } else if(this.state.isSent) {
            return(
                <Modal>
                    <Sent><FontTitle24>Email sent!</FontTitle24></Sent>
                </Modal>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = state => ({
    platform: state.common.user.get("platform"),
    userName: state.common.user.get("first")+" "+state.common.user.get("last"),
    isWMC: state.common.wmc.get("isWMC"),
    wmcsAssignedToCoach: state.enterprise.partners.partners.get("wmcsAssignedToCoach"),
    isOpen: state.enterprise.email.messages.get("isOpen"),
    subject: state.enterprise.email.messages.get("subject"),
    body: state.enterprise.email.messages.get("body"),
    isReply: state.enterprise.email.messages.get("isReply"),
    recipientId: state.enterprise.email.messages.get("recipientId"),
    recipientType: state.enterprise.email.messages.get("recipientType"),
    recipientFirstName: state.enterprise.email.messages.get("recipientFirstName"),
    recipientLastName: state.enterprise.email.messages.get("recipientLastName"),
    lastInitiated: state.enterprise.email.messages.get("lastInitiated"),
});

const mapDispatchToProps = dispatch => ({
    tryPostCoachClientMessages: (mediaType, content, subject, consumerId) => dispatch(tryPostCoachClientMessages(mediaType, content, subject, consumerId)),
    tryPostCoachCoachMessages: (mediaType, content, subject, consumerId) => dispatch(tryPostCoachCoachMessages(mediaType, content, subject, consumerId)),
    tryGetCoachClientPurchaseLink: (consumerId) => dispatch(tryGetCoachClientPurchaseLink(consumerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailCompose);
