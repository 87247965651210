import React from "react";
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import styled from "styled-components";
import ReactPlayer from 'react-player';

/* Containers */

/* Components */
import {LogoLoading} from "../../../../common/components/loading";
import PopUp from "./popup";

/* Middleware */
import {tryGetOneTask, tryGetPurchaseHistory, tryPostAssignTask} from "../middleware/tasks";
import {tryAuthUserDataUpdate} from "../../../../routes/middleware/auth";
import {tryGetTrainingMapping} from '../../trainings/middleware/trainings';

/* Domain */
// import getDomainEnv from '../../../../domains/utils';
// const env = getDomainEnv();

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;
const PlayerWrapper = styled.div`
  //position: relative;
  //padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  background-color: #000000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  & .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;


class TrainingsRecordCourse extends React.Component {
    state = {"isLoading": true, "courseId": null, "course": {}, open: true, "isQuizCompleted": false};

    componentDidMount() {
        return this.init();
    }

    handleOpen = () => {
        this.setState({open: !this.state.open})
    }

    init = async () => {
        // Check if paying customer or promotional partner, send to paywall
        const subscription = await this.findSubscription();
        // const cannotAccessAllTrainings = (subscription === null && !this.props.isReferredByPartner && !this.props.isReferredByPartnerWMC);
        const cannotAccessAllTrainings = (!subscription && !this.props.isReferredByPartner && !this.props.isReferredByPartnerWMC);
        const hasAvailableTrainingSessions = this.props.pms_sessions > 0;
        const courseId = window.location.pathname.split('/')[3];
        let course = await this.props.tryGetOneTask(courseId)
        let isQuizCompleted = false;
        const mapping = await this.props.tryGetTrainingMapping();
        if(course.id === undefined) {
            // turned off since all advisors can access trainings now that paywall happens before signup
            // if(cannotAccessAllTrainings && !hasAvailableTrainingSessions) {
            //     return this.props.history.push("/my-certificates/my-trainings/add-trainings");
            // }
            await this.props.tryPostAssignTask(courseId, this.props.userId, cannotAccessAllTrainings);
            await this.props.tryAuthUserDataUpdate();
            course = await this.props.tryGetOneTask(courseId)
        } else {
            const quizId = mapping.matchVideoAndQuiz[courseId.toString()] === undefined ? null : mapping.matchVideoAndQuiz[courseId.toString()];
            if(quizId !== null) {
                const quizData = await this.props.tryGetOneTask(quizId);
                isQuizCompleted = quizData.isCompleted;
            }
        }
        await this.setState({
            "courseId": courseId,
            "course": course,
            "videoUrl": course.tasks[0].task.pages[0].elements[0].value,
            "isQuizCompleted": isQuizCompleted,
            "isLoading": false
        });
    };

    findSubscription = async () => {
        const purchases = await this.props.tryGetPurchaseHistory();
        return purchases.filter(p => {
            return (p.willowPlanId === "willow_ondemand" || p.willowPlanId === "willow_empowered" || p.willowPlanId === null)
        }).length > 0;
        // if(purchasesAdvisor.length === 0) {
        //     return null
        // } else {
        //     const purchasesAdvisorSorted = purchasesAdvisor.reduce((a, b) => a.purchasedOn > b.purchasedOn ? a : b)
        //     return purchasesAdvisorSorted.willowPlanId;
        // }
    }

    onEnded = async () => {
        const mapping = await this.props.tryGetTrainingMapping();
        if(this.state.isQuizCompleted) {
            this.props.history.push("/my-certificates/my-trainings");
        } else {
            this.props.history.push("/my-tasks/course/"+mapping.matchVideoAndQuiz[this.state.courseId.toString()]+"/quiz");
        }
    }

    render() {
        if(this.state.isLoading) {
            return (<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <PlayerWrapper>
                    {this.state.open && <PopUp handleOpen={this.handleOpen} isQuizCompleted={this.state.isQuizCompleted}/>}
                    <ReactPlayer
                        className={"react-player"}
                        url={this.state.videoUrl}
                        onEnded={this.onEnded}
                        width={'100%'}
                        height={'100vh'}
                        controls={true}
                    />
                </PlayerWrapper>
            )
            // return(
            //     <>
            //         <div style={{"padding":"56.25% 0 0 0", "position":"relative"}}>
            //             <iframe
            //                 src={this.state.videoUrl}
            //                 frameBorder={"0"}
            //                 allow={"autoplay; fullscreen; picture-in-picture"}
            //                 allowFullScreen={true}
            //                 style={{"position":"absolute", "top":0, "left":0, "width":"100%", "height":"100%"}}
            //                 title={"Integrating Coaching Into Your Practice"} />
            //         </div>
            //         <script src="https://player.vimeo.com/api/player.js" />
            //     </>
            // )
            // return (
            //     <CoursesFlow
            //         courseId={this.state.courseId}
            //         backFromArticle={() => {this.props.history.goBack()}}
            //         backFromVideo={() => {this.props.history.goBack()}}
            //         backFromCourseSummary={() => {this.props.history.push("/my-certificates/my-trainings")}}
            //         backFromCourseModule={() => {this.props.history.push("/my-certificates/my-trainings/"+this.state.programId+"/course/"+this.state.courseId)}}
            //         backFromCourseComplete={() => {this.props.history.push("/my-certificates/my-trainings/"+this.state.programId+"/course/"+this.state.courseId)}}
            //         openCourseTask={(taskId) => {this.props.history.push("/my-certificates/my-trainings/"+this.state.programId+"/course/"+this.state.courseId+"/module/"+taskId)}}
            //         openCourseComplete={() => {this.props.history.push("/my-certificates/my-trainings/"+this.state.programId+"/course/"+this.state.courseId+"/complete")}}
            //         coursePathname={"/my-certificates/my-trainings/"+this.state.programId+"/course/"+this.state.courseId}
            //         openMyCoaches={() => {this.props.history.push("/my-certificates/my-coaches")}}
            //         openFindACoach={() => {this.props.history.push("/my-certificates/my-coaches/browse")}}
            //         openViewCoach={(coachId) => {this.props.history.push("/my-certificates/my-coaches/"+coachId)}}
            //     />
            // )
        }
    }
}

const mapStateToProps = state => ({
    userId: state.common.user.get("userId"),
    isReferredByPartner: state.common.user.get("isReferredByPartner"),
    isReferredByPartnerWMC: state.common.wmc.get("wmc").get("isReferredByPartner"),
    pms_sessions: state.common.user.get("pms_sessions")
});

const mapDispatchToProps = dispatch => ({
    tryGetOneTask: (courseId) => dispatch(tryGetOneTask(courseId)),
    tryPostAssignTask: (courseId, userId, isCharged) => dispatch(tryPostAssignTask(courseId, userId, isCharged)),
    tryGetPurchaseHistory: () => dispatch(tryGetPurchaseHistory()),
    tryAuthUserDataUpdate: () => dispatch(tryAuthUserDataUpdate()),
    tryGetTrainingMapping: () => dispatch(tryGetTrainingMapping())
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TrainingsRecordCourse));
