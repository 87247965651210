import React, {useEffect, useMemo, useState, useRef} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';

/* Stripe */
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, useElements, useStripe, CardCvcElement, CardNumberElement, CardExpiryElement} from "@stripe/react-stripe-js";
const stripePromise = loadStripe("pk_live_SrxO9EO9za9GbiIrCpNIdriZ00IiNcMrBr");

/* Components */
import {Red, Spacer, ButtonWide, StripeComponents, HalfLeft, HalfRight, Form} from '../components/cards';
import {ButtonPrimary, ButtonInactive} from "../../../common/components/buttons";
import TextInput from "../../../common/components/textinput";
import {colors} from "../../../common/components/colors";
import {FontBody16, FontHeader16} from "../../../common/components/fonts";
import {LogoLoading} from "../../../common/components/loading";

/* Middleware */
import {tryGetPaymentMethodSetupIntent, tryGetPaymentMethods} from '../middleware/cards';

const useResponsiveFontSize = () => {
    const getFontSize = () => (window.innerWidth < 450 ? "16px" : "16px");  //"18px");
    const [fontSize, setFontSize] = useState(getFontSize);
    useEffect(() => {
        const onResize = () => {setFontSize(getFontSize());};
        window.addEventListener("resize", onResize);
        return () => {window.removeEventListener("resize", onResize);};
    });
    return fontSize;
};

const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    return useMemo(
        () => ({
            iconStyle: 'solid',
            style: {
                base: {
                    iconColor: colors.primary70,
                    fontSize,
                    color: colors.primary100,
                    letterSpacing: "0.025em",
                    fontFamily: "Source Code Pro, monospace",
                    "::placeholder": {
                        color: colors.primary70
                    }
                },
                invalid: {
                    iconColor: colors.alert100,
                    color: colors.primary100
                }
            },
        }),
        [fontSize]
    );
};

function CardComponents(props) {
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    const user = useSelector(state => state.common.user);

    const intentSecret = useRef("");

    // const [credit, setCredit] = useState(false);
    const [name, setName] = useState('');
    const [postal, setPostal] = useState('');
    const [creditNumber, setCreditNumber] = useState(false);
    const [creditExp, setCreditExp] = useState(false);
    const [creditCVV, setCreditCVV] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [isSavingCard, setIsSavingCard] = useState(false);
    const [error, setError] = useState(null);

    // useEffect(() => {
    //     if(credit) {
    //         setIsValid(true);
    //     } else {
    //         setIsValid(false);
    //     }
    // }, [credit]);

    useEffect(() => {
        if(creditNumber && creditExp && creditCVV && name !== "" && postal !== "") {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [creditNumber, creditExp, creditCVV, postal, name]);

    // const handleChangeCreditCard = e => setCredit(e.complete);
    const handleChangeName = e => setName(e.target.value);
    const handleChangePostal = e => setPostal(e.target.value);
    const handleChangeCardNumber = e => setCreditNumber(e.complete);
    const handleChangeExpiration = e => setCreditExp(e.complete);
    const handleChangeCVV = e => setCreditCVV(e.complete);

    const handleSubmit = async e => {
        e.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        setIsSavingCard(true);
        setError(null);

        if(intentSecret.current === "") {
            const setupIntent = await props.tryGetPaymentMethodSetupIntent();
            intentSecret.current = setupIntent.clientSecret;
        }

        const result = await stripe.confirmCardSetup(intentSecret.current, {
            payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    name: name,
                    email: user.get("email"),
                    phone: user.get("phone"),
                    address: {
                        postal_code: postal,
                    },
                },
            }
        });

        if (result.hasOwnProperty("error")) {
            setError(result.error.message);
            setIsSavingCard(false);
            return;
        }

        const paymentMethods = await props.tryGetPaymentMethods();
        const paymentMethod = await paymentMethods.filter(p => {return(p.id === result.setupIntent.payment_method)})[0];
        props.onSaveCard(paymentMethod);
        setIsSavingCard(false);

    };

    // if(isSavingCard) {
    //     return(<><br /><br /><LogoLoading /></>)
    // }

    return(
        <Form method={"post"} onSubmit={handleSubmit}>
            <StripeComponents>
                {/*<CardElement*/}
                {/*    options={options}*/}
                {/*    onChange={handleChangeCreditCard}*/}
                {/*/>*/}
                <FontHeader16>Card Number*</FontHeader16>
                <CardNumberElement
                    id={"cardNumber"}
                    options={options}
                    onChange={handleChangeCardNumber}
                />
                <Spacer />
                <div>
                    <HalfLeft>
                        <FontHeader16>Expiration*</FontHeader16>
                        <CardExpiryElement
                            id={"expiry"}
                            options={options}
                            onChange={handleChangeExpiration}
                        />
                    </HalfLeft>
                    <HalfRight>
                        <FontHeader16>CVV Code*</FontHeader16>
                        <CardCvcElement
                            id={"cvc"}
                            options={options}
                            onChange={handleChangeCVV}
                        />
                    </HalfRight>
                </div>
                <Spacer />
                <TextInput
                    id={"name"}
                    title={<FontHeader16>Card Holder*</FontHeader16>}
                    valid={true}
                    warning={"Required"}
                    placeholder={"Card Holder"}
                    value={name}
                    onChange={handleChangeName}
                />
                <Spacer />
                <TextInput
                    id={"postal"}
                    title={<FontHeader16>Billing Zip*</FontHeader16>}
                    valid={true}
                    warning={"Required"}
                    placeholder={"Zip Code"}
                    value={postal}
                    onChange={handleChangePostal}
                />
                {error !== null && <Red><FontBody16>{error}</FontBody16></Red>}
                <Spacer />
                <ButtonWide>
                    {isValid
                        ? <ButtonPrimary canSubmit={true} label={"Add Card"} />
                        : <ButtonInactive canSubmit={false} label={"Add Card"} />
                    }
                </ButtonWide>
            </StripeComponents>
        </Form>
    )
}

const CheckoutCardsAddStripe = ({onSaveCard, tryGetPaymentMethodSetupIntent, tryGetPaymentMethods}) => (
    <Elements stripe={stripePromise}>
        <CardComponents
            onSaveCard={onSaveCard}
            tryGetPaymentMethodSetupIntent={tryGetPaymentMethodSetupIntent}
            tryGetPaymentMethods={tryGetPaymentMethods}
        />
    </Elements>
);

const mapDispatchToState = dispatch => ({
    tryGetPaymentMethodSetupIntent: () => dispatch(tryGetPaymentMethodSetupIntent()),
    tryGetPaymentMethods: () => dispatch(tryGetPaymentMethods()),
});

export default connect(null, mapDispatchToState)(CheckoutCardsAddStripe);