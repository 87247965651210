import React from 'react';
import styled from "styled-components";

/* Components */
import { FontSubtitle40 } from '../../common/components/fonts';
import {colors} from "../../common/components/colors";

/* Containers */
import NavMenu from "./containers/navmenu";

const NavPage = styled.div`
    padding: 50px 80px 0 80px;
    background-color: ${colors.backgroundColor2};
    height: calc(100vh - 50px);
    @media screen and (max-width: 460px) {
        padding: 30px 40px 0 40px;
        height: calc(100vh - 30px);
    }
`;
const Title = styled(FontSubtitle40)`
    margin-bottom: 30px;
`;

class ConsumerNavPage extends React.Component {
    render() {
        return(
            <NavPage>
                <Title spaced={true}>MENU</Title>
                <NavMenu />
            </NavPage>
        )
    }
}

export default ConsumerNavPage;
