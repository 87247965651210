import React from 'react';
import {connect} from "react-redux";

/* Components */
import NavSecondaryTop from "../../navbars/containers/secondarytop";
import SettingsBrandingFirmInfo from './brandingfirminfo';
import SettingsBrandingRecording from './brandingrecording';
import SettingsBrandingLogo from './brandinglogo';

/* Middleware */
import {tryGetMCById} from '../../../common/containers/company/middleware/wmc';
import {LogoLoading} from "../../../common/components/loading";

class SettingsBranding extends React.Component {
    state = {isLoading: true};

    componentDidMount() {
        Promise.resolve(this.props.tryGetMCById())
            .then(() => {
                this.setState({"isLoading": false})
            })
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <>
                    <NavSecondaryTop title={"Branding"} />
                    <SettingsBrandingLogo />
                    {/*<SettingsBrandingRecording />*/}
                    <SettingsBrandingFirmInfo />
                </>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryGetMCById: () => dispatch(tryGetMCById())
});
export default connect(null, mapDispatchToProps)(SettingsBranding);
