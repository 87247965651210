import React from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {List} from 'immutable';

/* Containers */
import {ChangeSessions} from "../../containers/contactcoachingchangesessions";
import {ChangeSessionsUnlimited} from "../../containers/contactcoachingchangesessionsunlimited";

/* Middleware */
import {tryGetCoachesAssignedToProspect, tryPostAssignCoachToProspect, tryPostUpdateProspectsSessions} from "../middleware/coaching";
import {tryCreateProspect, tryGetOneProspect} from "../../middleware/prospects";
import {tryGetAllContacts} from "../../middleware/contacts";

/* Store */
import {setContactsOne} from "../../store/contacts";
import {setClientsDetailSelected} from "../../store/clientsdetail";
import {setSessionsModal} from "../../meetings/store/sessions";

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FontTitle18, FontHeader12, FontHeader21, FontHeader18, FontBody14} from "../../../common/components/fonts";
import {RadioButtonsTwo, SearchInput} from "../../../common/components/inputs";
import {colors} from "../../../common/components/colors";
import {CalendarOutlined, CheckSquareOutlined} from "@ant-design/icons";
import {ButtonInactive, ButtonSecondary, ButtonPrimary} from "../../../common/components/buttons";
import {Calendar, Map, Clock} from 'react-feather';
import TeamDetails from "./teamdetails";
import SchedulerFlow from "../../../iframes/scheduler/containers/flow";
import {CommonBackground, CommonModal} from "../../../common/components/modal";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const Center = styled.div`
    margin: 20px auto 0px auto;
    width: fit-content;
    text-align: center;
`;
const StepTitle = styled.div`
    margin-bottom: -20px;
`;

const Gray = styled.div`
    margin-bottom: 10px;
    color: ${colors.secondary70};
`;

const Width = styled.div`
    max-width: 500px;
`;

const Title = styled.div`
    margin: 20px 0px 5px 0px;
`;

const IconChar = styled.div`
    display: inline-block;
    vertical-align: top;
    font-family: CooperLight;
    font-size: 24px;
    ${props => props.active !== true} {
        color: ${colors.white};
    }
    ${props => props.active !== false} {
        color: ${colors.action100};
    }
    height: 26px;
`;
const Icon = styled.div`
    display: inline-block;
    vertical-align: top;
    ${props => props.active !== true} {
        color: ${colors.white};
    }
    ${props => props.active !== false} {
        color: ${colors.action100};
    }
    & svg {
        width: 16px;
        height: 16px;
        margin-top: 3px;
    }
`;

const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    @media screen and (max-width: 460px) {
        margin-right: 0px;
    }
`;

const SearchInputEdit = styled.div`
    ${props => props.contactSelected !== true} {
        & input {
            border-color: ${colors.primary30};
            background-color: ${colors.white};
        }
    }
    ${props => props.contactSelected !== false} {
        & input {
            border-color: ${colors.action100};
            background-color: ${colors.white};
            -webkit-box-shadow: 0px 4px 10px 0px rgba(107, 200, 214, 0.75);
            box-shadow: 0px 4px 10px 0px rgba(107, 200, 214, 0.75);
        }
        & input::placeholder {
            color: ${colors.primary70};
        }
    }
`;

const InlineButton = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-right: 7px;
    margin-bottom: 10px;
    @media screen and (max-width: 460px) {
        display: block;
        text-align: center;
        margin: 0px auto 10px auto;
        & div {
            margin: 0px auto;
        }
    }
`;

const Options = styled.div`
    ${props => props.active !== true} {
        opacity: 1;
    }
    ${props => props.active !== false} {
        opacity: 0.5;
    }
`;

export const ActionBox = styled.div`
    ${props => props.contactSelected !== true} {
        border: 1px solid ${colors.primary30};
        -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.30);
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.30);
        background-color: ${colors.white};
    }
    ${props => props.contactSelected !== false} {
        border: 1px solid ${colors.primary30};
        -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
        background-color: ${colors.border70};
    }   
    padding: 20px;
    width: calc(100% - 42px);
    border-radius: 6px;
    margin-bottom: 15px;
    max-width: calc(320px - 42px);
    min-height: calc(240px - 2px);
`;
const Modal = styled.div`
    margin: 0px auto;
    width: 748px;
    padding: 30px;
    border-radius: 6px;
    background-color: ${colors.white};
    position: fixed;
    top: 100px;
    left: 0px;
    right: 0px;
    @media screen and (max-width: 800px) {
        width: 100%;
        padding: 0px;
    }
    z-index: 6;
`;
const Background = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.50);
    @media screen and (max-width: 800px) {
        background-color: ${colors.white};
    }
    z-index: 5;
`;

const whoOptions = (who) => {
    return who.map(w => {
        return({
            contactType: w.get("prospectId") === null ? "Contact" : w.get("prospectStatus"),
            value: {
                consumerId: w.get("consumerId"),
                prospectId: w.get("prospectId"),
                contactId: w.get("contactId"),
                businessUserId: w.get("businessUserId")
            },
            label: w.get("isArchived") ? w.get("firstName") + " " + w.get("lastName") + " (previous)" : w.get("firstName") + " " + w.get("lastName"),
            isArchived: w.get("isArchived")
        })
    }).toJS();
};

class TeamIntroduce extends React.Component {
    state={
        "photo": null,
        "firstName": null,
        "lastName": null,
        "userId": null,
        "showScheduler": false,
        "isLoading": true,
        "coach": {},
        "contactsAll": [],
        'whoObject': {},
        "who": "",
        'whoLabel': "",
        "whoValid": false,
        "assigned": false,
        "remainingSessions": 0,
        'isChanged': false,
        'inf': null
    };

    componentDidMount() {
        return this.setupForm();
    }

    setupForm = async () => {
        if(!this.props.contactsAllFetched) {
            await this.props.tryGetAllContacts();
        }
        await this.setState({
            "coach": this.props.coach.toJS(),
            "contactsAll": this.props.contactsAll,
            "isLoading": false
        });
    };

    addCoach = async (coachId, count, coachName) => {
        await this.addCoachToProspect(coachId, count, coachName);
    };

    updateSessions = async (coachId, sessionsDelta) => {
        this.props.tryPostUpdateProspectsSessions(coachId, sessionsDelta, this.state.who, this.state.whoObject.value.prospectId);
    };

    addCoachToProspect = async (coachId, numSessions, coachName) => {
        const consumerId = this.state.whoObject.value.consumerId;
        const prospectId = this.state.whoObject.value.prospectId;
        await this.props.tryPostAssignCoachToProspect(coachId, numSessions, coachName, consumerId, prospectId, "Prospect");
        this.setState({'assigned': true});
    };

    handleResetSearchContents = async () => {
        let searchProspectsAddWho;
        if(this.state.who !== '') {
            if(this.state.whoObject.contactType === "Contact") {
                searchProspectsAddWho = await this.props.contactsAll.filter(s => {return(s.get("contactId") === this.state.who)}).get(0);
            } else {
                searchProspectsAddWho = await this.props.contactsAll.filter(s => {return(s.get("consumerId") === this.state.who)}).get(0);
            }
            this.setState({'contactsAll': this.state.contactsAll.push(searchProspectsAddWho)})
        }
    };

    handleDeleteWho = async () => {
        this.setState({'whoObject': {}, "who": "", 'whoLabel': "", "whoValid": false, 'isChanged': false, "assigned": false, "remainingSessions": 0})
    };

    handleChangeWho = async (e) => {
        await this.handleResetSearchContents();
        if(e === null) {
            await this.handleDeleteWho();
            await this.changeSessionAmount(null);
        } else {
            if(e.contactType === "Contact") {
                await this.setState({
                    'whoObject': e,
                    "who": e.value.contactId,
                    'whoLabel': e.label,
                    "whoValid": this.props.contactsAll.filter(p => {return p.get("contactId") === e.value.contactId}).size === 1,
                    'isChanged': true,
                    "assigned": false,
                    "remainingSessions": 0,
                    'contactsAll': this.state.contactsAll.filter(s => {return(s.get("contactId") !== e.value.contactId)})
                });
            } else {
                let assignedCoaches = await this.props.tryGetCoachesAssignedToProspect(e.value.prospectId, "return");
                let assignedCoach = assignedCoaches.filter(a => {return(a.coachId === this.props.coach.coachId)});
                await this.setState({
                    'whoObject': e,
                    "who": e.value.consumerId,
                    'whoLabel': e.label,
                    "whoValid": this.props.contactsAll.filter(p => {return p.get("consumerId") === e.value.consumerId}).size === 1,
                    'isChanged': true,
                    "assigned": assignedCoach.length > 0,
                    "remainingSessions": assignedCoach.length > 0 ? assignedCoach[0].remainingSessions : 0,
                    'contactsAll': this.state.contactsAll.filter(s => {return(s.get("consumerId") !== e.value.consumerId)})
                });
            }
        }
    };

    checkAssigned = async (e) => {
        if (e.get("prospectId") !== null) {
            let assignedCoaches = await this.props.tryGetCoachesAssignedToProspect(e.get("prospectId"), "return");
            let assignedCoach = assignedCoaches.filter(a => {return(a.coachId === this.props.coach.coachId)});
            return assignedCoach.length > 0;
        } else if (e.get("consumerId") !== this.props.userId && e.get("businessUserId") === null) {
            return false;
        } else {
            return true;
        }
        
    }

    createVideoMeeting = async (coach) => {
        await this.props.setSessionsModal(false, null, null, coach.contactId, null, [this.state.whoObject], true);
        this.props.history.push("/meetings/create/call");
    };

    createGuidelines = async () => {
        // await this.props.tryGetSharedDriveBetweenUser(this.props.coach.get("coachId"));
        // if (this.props.drive )
        // else {
        //  window.open("https://docs."+env.URL.DOMAIN+"/Willow+Coaching+Topics+for+Advised+Clients.pdf", "_blank");
        // }
        window.open("https://docs."+env.URL.DOMAIN+"/Willow+Coaching+Topics+for+Advised+Clients.pdf", "_blank");
    };

    changeSessionAmount = async (e) => {
        this.setState({'inf': e});
    };

    createVideoMeetingFromScheduler = async (coach) => {
        await this.setState({"photo": coach.photo, "firstName": coach.first, "lastName": coach.last, "userId": coach.consumerId});
        this.changeShowScheduler(true);
    };

    changeShowScheduler = async (status) => {
        this.setState({"showScheduler": status});
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <>
                    <StepTitle><FontTitle18>Select Client</FontTitle18></StepTitle>
                    <Width>
                        <SearchInputEdit contactSelected={this.state.whoValid}>
                            <SearchInput
                                title={null}
                                options={whoOptions(this.state.contactsAll.filter(
                                    s => {return(s.get("consumerId") !== this.props.userId
                                    && s.get("consumerId") !== null
                                    && s.get("businessUserId") === null
                                    && s.get("coachAssignments").filter(c => {return(c.get("coachId") === this.props.coach.get("coachId"))}) === List()
                                    )}))}
                                id={'who'}
                                onChange={this.handleChangeWho}
                                placeholder={'Search contacts'}
                                value={this.state.whoLabel}
                            />
                        </SearchInputEdit>
                    </Width>
                    <br />
                    <br />
                    <div style={{ pointerEvents: ((this.state.who === "") ? 'none' : 'auto')}}>
                        <Options active={this.state.who !== ""}>
                            <Inline>
                                <ActionBox contactSelected={this.state.whoValid}>
                                    <Gray><FontHeader12>STEP 1 (Optional)</FontHeader12></Gray>
                                    <Title><FontHeader21>Guidelines</FontHeader21></Title>
                                    <FontBody14>Meet with {this.state.coach.first} to set guidelines working with {(this.state.who !== "") ? this.state.whoLabel : "a prospect or client"}</FontBody14>
                                    <br />
                                    <Center onClick={() => {return this.createVideoMeetingFromScheduler(this.state.coach)}}>
                                        <ButtonSecondary submit={false} label={<><Icon active={false}><Calendar /></Icon>&nbsp;&nbsp;Create Meeting</>} />
                                    </Center>
                                </ActionBox>
                            </Inline>
                            <Inline>
                                <ActionBox contactSelected={this.state.whoValid}>
                                    <Gray><FontHeader12>STEP 2 (Optional)</FontHeader12></Gray>
                                    <Title><FontHeader21>Introduction</FontHeader21></Title>
                                    <FontBody14>Create a 3-way meeting to introduce {this.state.coach.first} to {(this.state.who !== "") ? this.state.whoLabel : "a prospect or client"}</FontBody14>
                                    <br />
                                    <Center onClick={() => {return this.createVideoMeeting(this.state.coach)}}>
                                        <ButtonSecondary submit={false} label={<><Icon active={false}><Calendar /></Icon>&nbsp;&nbsp;Create Meeting</>} />
                                    </Center>
                                </ActionBox>
                            </Inline>
                            {/*<Inline>*/}
                            {/*    <ActionBox contactSelected={this.state.whoValid}>*/}
                            {/*        <Gray><FontHeader12>STEP 2 (Optional)</FontHeader12></Gray>*/}
                            {/*        <Title><FontHeader21>Guidelines</FontHeader21></Title>*/}
                            {/*        <FontBody14>Set up guidelines between {this.state.coach.first} and {(this.state.who !== "") ? this.state.whoLabel : "a prospect or client"}</FontBody14>*/}
                            {/*        <br />*/}
                            {/*        <Center onClick={() => {return this.createGuidelines()}}>*/}
                            {/*            <ButtonSecondary submit={false} label={<><Icon active={false}><Map /></Icon>&nbsp;&nbsp;Set Guidelines</>} />*/}
                            {/*        </Center>*/}
                            {/*    </ActionBox>*/}
                            {/*</Inline>*/}
                            <Inline>
                                <ActionBox contactSelected={this.state.whoValid}>
                                    <Gray><FontHeader12>STEP 3</FontHeader12></Gray>
                                    <Title><FontHeader21>Sessions</FontHeader21></Title>
                                    <FontBody14>Choose how many meetings {this.state.coach.first} and {(this.state.who !== "") ? this.state.whoLabel : "a prospect or client"} can have</FontBody14>
                                    <br />
                                    <Center>
                                        <InlineButton onClick={() => {return this.changeSessionAmount("Limited")}}>
                                            {this.state.inf === "Limited"
                                                ? <ButtonPrimary submit={false} label={<><Icon active={true}><Clock /></Icon>&nbsp;&nbsp;Limit</>} />
                                                : <ButtonSecondary submit={false} label={<><Icon active={false}><Clock /></Icon>&nbsp;&nbsp;Limit</>} />
                                            }
                                        </InlineButton>
                                        <InlineButton onClick={() => {return this.changeSessionAmount("Infinite")}}>
                                            {this.state.inf === "Infinite"
                                                ? <ButtonPrimary submit={false} label={<><IconChar active={true}>&#x221E;</IconChar>&nbsp;Infinite</>} />
                                                : <ButtonSecondary submit={false} label={<><IconChar active={false}>&#x221E;</IconChar>&nbsp;Infinite</>} />
                                            }
                                        </InlineButton>
                                    </Center>
                                    <br/>
                                    {(this.state.inf === "Limited" && this.state.inf !== null) &&
                                        <ChangeSessions
                                            initialCount={this.state.remainingSessions}
                                            expertId={this.state.coach.coachId}
                                            expertName={this.state.coach.first+" "+this.state.coach.last}
                                            notifyFirstName={this.state.coach.first}
                                            updateSessions={this.state.assigned ? this.updateSessions : this.addCoach}
                                            subTitle={this.state.assigned ? "Remaining Sessions" : "Assign Sessions"}
                                            useSessionDelta={this.state.assigned}
                                        />
                                    }
                                    {(this.state.inf === "Infinite" && this.state.inf !== null) &&
                                        <ChangeSessionsUnlimited
                                            expertId={this.state.coach.coachId}
                                            expertName={this.state.coach.first+" "+this.state.coach.last}
                                            updateSessions={this.state.assigned ? this.updateSessions : this.addCoach}
                                            notifyFirstName={this.state.coach.first}
                                            selected={this.state.inf}
                                        />
                                    }
                                </ActionBox>
                            </Inline>
                        </Options>
                    </div>
                    {this.state.showScheduler &&
                        <>
                            <CommonBackground/>
                            <CommonModal>
                                <SchedulerFlow
                                    userId={this.state.userId}
                                    userFirstName={this.state.firstName}
                                    userLastName={this.state.lastName}
                                    userPhotoUrl={this.state.photo}
                                    eventCustomMinutes={30}
                                    availabilityCloseBtn={true}
                                    availabilityOnClose={() => {return this.changeShowScheduler(false)}}
                                    confirmOnClose={() => {return this.changeShowScheduler(false)}}
                                />
                            </CommonModal>
                        </>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    stages: state.enterprise.prospects.get("prospectStages"),
    userId: state.common.user.get("userId"),
    contactsAll: state.enterprise.contacts.get("contactsAll"),
    contactsAllFetched: state.enterprise.contacts.get("contactsAllFetched"),
    prospectsOne: state.enterprise.prospects.get("prospectsOne"),
    clientsOne: state.enterprise.clientsList.get("clientsOne"),
    coach: state.enterprise.team.coaching.get("coachSelected")
});
const mapDispatchToProps = dispatch => ({
    tryPostUpdateProspectsSessions: (coachId, sessionsDelta, consumerId, prospectId) => dispatch(tryPostUpdateProspectsSessions(coachId, sessionsDelta, consumerId, prospectId)),
    tryGetAllContacts: () => dispatch(tryGetAllContacts()),
    setSessionsModal: (status, session, action, who, details, guests, eventApi) => dispatch(setSessionsModal(status, session, action, who, details, guests, eventApi)),
    setContactsOne: (contact) => dispatch(setContactsOne(contact)),
    tryGetOneProspect: (prospectId, storeOrReturn) => dispatch(tryGetOneProspect(prospectId, storeOrReturn)),
    tryCreateProspect: (data) => dispatch(tryCreateProspect(data)),
    setClientsDetailSelected: (client) => dispatch(setClientsDetailSelected(client)),
    tryPostAssignCoachToProspect: (coachId, numSessions, coachName, consumerId, prospectId, prospectStatus) => dispatch(tryPostAssignCoachToProspect(coachId, numSessions, coachName, consumerId, prospectId, prospectStatus)),
    tryGetCoachesAssignedToProspect: (prospectId, storeOrReturn) => dispatch(tryGetCoachesAssignedToProspect(prospectId, storeOrReturn)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TeamIntroduce));
