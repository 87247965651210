import React from 'react';
import {connect} from 'react-redux';
import ReactQuill from "react-quill";
import styled from "styled-components";
import moment from "moment";
import {fromJS, List} from 'immutable';

/* Components */
import {Page} from "../components/structurepreview";
import {Container, Header, Name, SubName} from "../components/events";
import {FontTitle21, FontHeader16} from "../../../common/components/fonts";
import {QuillSlimNoHeight} from "../../../common/components/richtexteditor";
import {colors} from "../../../common/components/colors";
import {LogoLoading} from "../../../common/components/loading";
import {CTAs, Icon16, Inline, InlineBtn} from "../../shop/components/header";
import {Mail, Video} from "react-feather";
import {ButtonInactiveLight, ButtonPrimary, ButtonTertiary} from "../../../common/components/buttons";

/* Containers */
import WebinarOnePreview from './structurepreview';
import {Message} from "../../../common/components/messages";

export const BtnSpacing = styled.div`
    margin-right: 15px;
`;
export const Divider = styled.div`
    margin: 40px 0px 20px 0px;
`;
export const ButtonCenter = styled.div`
    cursor: pointer;
    width: fit-content;
    margin: 0px auto;
`;

class Webinars extends React.Component {
    state={"showHeader": true, "isLoading": true, "title": "", "descriptionComponent": "", "webinarsAllUpcoming": List(), "webinarsAllPast": List(), "hasDescription": false, "showPast": false};

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.title !== this.props.title || prevProps.description !== this.props.description) {
            this.initTitle();
            this.initDescription();
        }
        if(prevProps.webinarsAll !== this.props.webinarsAll) {
            this.initwebinarsAll();
        }
    }

    init = async () => {
        await this.initTitle();
        await this.initDescription();
        await this.initwebinarsAll();
        this.setState({"isLoading": false});
    };

    initTitle = async () => {
        let title = this.props.title;
        this.setState({"title": (title === null || title === undefined || title === "") ? null : title,})
    };

    initDescription = async () => {
        let description = this.props.description;
        let descriptionAdj = (description === '<p><br></p>' || description === null || description === undefined || description === "") ? "" : description;
        await this.setState({"descriptionComponent": ""});
        this.setState({
            "hasDescription": !(description === '<p><br></p>' || description === null || description === undefined || description === ""),
            "descriptionComponent": <ReactQuill
                readOnly={true}
                theme={null}
                value={descriptionAdj}
                bounds={'.app'}
            />
        })
    };

    changeShowPast = async () => {this.setState({"showPast": !this.state.showPast})};

    initwebinarsAll = async () => {
        let webinarsAllModified = await Promise.all(this.props.webinarsAll.map(l => {
            if (moment().isBefore(moment(l.get("start")))) {
                return Promise.resolve(fromJS({...l.toJS(), "status": "Upcoming"}))
            } else if (moment().isAfter(moment(l.get("start"))) && moment().isBefore(moment(l.get("end")))) {
                return Promise.resolve(fromJS({...l.toJS(), "status": "Ongoing"}))
            } else if (moment().isAfter(moment(l.get("end")))) {
                return Promise.resolve(fromJS({...l.toJS(), "status": "Past"}))
            }
        }));
        let webinarsAllUpcoming = await fromJS(webinarsAllModified).filter(w => {return(w.get("status") !== "Past" && !w.get("isOnlyForClients"))});
        let webinarsAllPast = await fromJS(webinarsAllModified).filter(w => {return(w.get("status") === "Past" && !w.get("isOnlyForClients"))});
        await this.setState({
            "webinarsAllUpcoming": webinarsAllUpcoming.sort((a, b) => {return(new Date(a.get("start")) - new Date(b.get("start")))}),
            "webinarsAllPast": webinarsAllPast.sort((a, b) => {return(new Date(a.get("start")) - new Date(b.get("start")))}),
        })
    };

    render() {
        if(this.state.isLoading) {
            return(<LogoLoading />)
        } else {
            return(
                <Container bigTop={true}>
                    {((this.state.title !== null || this.state.hasDescription || this.props.profile.get("profileShowEmailer") || this.props.profile.get("profileShowScheduler")) && this.props.showHeader) &&
                    <Header>
                        <Name>
                            {this.state.title !== null &&
                                <FontTitle21>{this.state.title}</FontTitle21>
                            }
                            {this.state.hasDescription &&
                                <SubName>
                                    <QuillSlimNoHeight bodyBorderColor={colors.white}>
                                        {this.state.descriptionComponent}
                                    </QuillSlimNoHeight>
                                </SubName>
                            }
                            {((this.props.profile.get("profileShowEmailer") || this.props.profile.get("profileShowScheduler"))) &&
                                <CTAs>
                                    {this.props.profile.get("profileShowEmailer") &&
                                        <InlineBtn onClick={() => {return this.props.changeShowEmailer(true)}}>
                                            {this.props.profile.get("profileShowScheduler")
                                                ? <ButtonInactiveLight submit={false} label={<><Icon16><Mail /></Icon16><Inline>&nbsp;&nbsp;Message</Inline></>} />
                                                : <ButtonPrimary submit={false} label={<><Icon16><Mail /></Icon16><Inline>&nbsp;&nbsp;Message</Inline></>} />
                                            }
                                        </InlineBtn>
                                    }
                                    {(this.props.profile.get("profileShowEmailer") && this.props.profile.get("profileShowScheduler")) &&
                                        <Inline><BtnSpacing/></Inline>
                                    }
                                    {this.props.profile.get("profileShowScheduler") &&
                                        <InlineBtn onClick={() => {return this.props.changeShowScheduler(true)}}><ButtonPrimary submit={false} label={<><Icon16><Video /></Icon16><Inline>&nbsp;&nbsp;Schedule Meeting</Inline></>} /></InlineBtn>
                                    }
                                </CTAs>
                            }
                        </Name>
                    </Header>
                    }
                    {this.state.webinarsAllUpcoming.size > 0 &&
                        <Page>
                            <Divider>
                                <FontHeader16>Upcoming Webinars:</FontHeader16>
                            </Divider>
                            {this.state.webinarsAllUpcoming.map(l => (
                                <WebinarOnePreview
                                    key={l.get("id")}
                                    data={l}
                                    isPast={l.get("status") === "Past"}
                                    isCanceled={!l.get("isActive")}
                                    handle={this.props.profile.get("publicHandle")}
                                />
                            ))}
                            {this.state.webinarsAllPast.size > 0 &&
                                <Divider>
                                    <ButtonCenter onClick={this.changeShowPast}>
                                        <ButtonTertiary canSubmit={false} label={this.state.showPast ? "Hide Past Webinars" : "Show Past Webinars"} />
                                    </ButtonCenter>
                                </Divider>
                            }
                            {(this.state.showPast && this.state.webinarsAllPast.size > 0) &&
                                <>
                                    <Divider>
                                        <FontHeader16>Past Webinars:</FontHeader16>
                                    </Divider>
                                    {this.state.webinarsAllPast.map(l => (
                                        <WebinarOnePreview
                                            key={l.get("id")}
                                            data={l}
                                            isPast={l.get("status") === "Past"}
                                            isCanceled={!l.get("isActive")}
                                            handle={this.props.profile.get("publicHandle")}
                                        />
                                    ))}
                                </>
                            }
                        </Page>
                    }
                    {this.props.webinarsAll.size === 0 &&
                        <><br /><Message text={"No webinars"} /></>
                    }
                </Container>
            )
        }
    }
}

const mapStateToProps = state => ({
    webinarsAll: state.iframe.webinars.webinars.get("webinarsAll"),
});

export default connect(mapStateToProps, null)(Webinars);
