import React from 'react';
import { Route } from 'react-router-dom';

const PublicRoute = ({ component: Component, data }) => {
    // Placeholder for tracking PageView or tracking identification of user
    return  <Route render={() => {
        return(<Component data={data} />)
    }}/>
};

export default PublicRoute;