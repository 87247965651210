import React from 'react';
import styled from "styled-components";

import {colors} from "../../../common/components/colors";
import {FontBody12, FontHeader16, FontTitle18} from "../../../common/components/fonts";

export const Card = styled.div`
    padding: 15px 25px;
    background-color: ${colors.white};
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    width: calc(100% - 80px);
    color: ${colors.primary100};
    margin: 0px auto 15px auto;
    border-radius: 4px;
    @media screen and (max-width: 1088px) {
        width: calc(100% - 40px);
        padding: 10px;
    }
`;
export const CardPage = styled.div`
    padding: 15px 25px;
    background-color: ${colors.white};
    width: calc(100% - 50px);
    color: ${colors.primary100};
    margin: 0px auto 15px auto;
    border-radius: 4px;
    @media screen and (max-width: 1088px) {
        width: calc(100% - 20px);
        padding: 10px;
    }
`;
export const Icon = styled.div`
    width: 30px;
    height: 30px;
    font-size: 18px;
    display: inline-block;
    vertical-align: top;
    padding-top: 4px;
    color: ${colors.secondary100};
`;
export const Body = styled.div`
    width: calc(100% - 30px);
    display: inline-block;
    vertical-align: top;
`;
export const Title = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 135px;
    color: ${colors.secondary100};
    @media screen and (max-width: 1440px) {
        display: block;
    }
`;
export const DateItem = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-top: 3px;
    width: 150px;
    @media screen and (max-width: 1440px) {
        margin-top: 4px;
    }
    @media screen and (max-width: 1088px) {
        margin-top: 7px;
    }
    @media screen and (max-width: 640px) {
        width: 100%;
    }
`;
export const Content = styled.div`
    margin: 20px 0px 10px 0px;
`;
export const BottomBorder5 = styled.div`
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid ${colors.border100};
`;
export const BottomBorder15 = styled.div`
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid ${colors.border100};
`;
export const BottomBorder = styled.div`
    margin-bottom: 25px;
    padding-bottom: 5px;
    border-bottom: 1px solid ${colors.border100};
`;
export const MarginBottom15 = styled.div`
    margin-bottom: 15px;
`;
export const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;
export const InlineToWide = styled.div`
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 460px) {
        width: 100%;
    }
`;
export const Action = styled.div`
    display: inline-block;
    vertical-align: bottom;
    width: calc(100% - 135px);
    text-align: right;
    @media screen and (max-width: 1440px) {
        display: block;
        text-align: left;
        margin-top: 15px;
        width: 100%;
    }
`;
export const ActionLink = styled.div`
    width: fit-content;
    padding: 0px 10px;
    color: ${colors.action100};
    cursor: pointer;
    margin-right: 15px;
    @media screen and (max-width: 1440px) {
        padding: 0px 10px 0px 0px;
    }
    @media screen and (max-width: 1088px) {
        padding: 3px 10px 3px 0px;
    }
`;
export const ActionNoLink = styled.div`
    width: fit-content;
    padding: 0px 10px;
    color: ${colors.secondary70};
    margin-right: 15px;
    @media screen and (max-width: 1440px) {
        padding: 0px 10px 0px 0px;
    }
    @media screen and (max-width: 1088px) {
        padding: 3px 10px 3px 0px;
    }
`;
export const EmailSenders = styled.div`
    margin-bottom: 15px;
`;
export const SenderIcon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: ${colors.primary70};
    color: ${colors.white};
    text-align: center;
    display: inline-block;
    vertical-align: top;
`;
export const Letter = styled.div`
    padding-top: 6px;
`;
export const SenderNames = styled.div`
    width: calc(100% - 60px);
    display: inline-block;
    vertical-align: top;
    padding-left: 10px;
`;
export const SenderRecipient = styled.div`
    color: ${colors.primary70};
`;
export const VideoLink = styled.a`
    color: ${colors.action100};
    cursor: pointer;
    overflow-wrap: break-word;
    word-wrap: break-word;
`;
export const Indent = styled.div`
    margin-left: 25px;
    @media screen and (max-width: 460px) {
        margin-left: 10px;
    }
`;
export const Modal = styled.div`
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    padding: 30px;
    border-radius: 4px;
    background-color: ${colors.white};
    -webkit-box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    max-width: 300px;
    width: calc(100% - 80px);
    z-index: 20;
`;
export const ConfirmTitle = styled.div`
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.border100};
    margin-bottom: 15px;
`;
export const ConfirmActions = styled.div`
    margin-top: 20px;
`;
export const ConfirmLeft = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
`;
export const ConfirmRight = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
`;
export const ConfirmBtnFitLeft = styled.div`
    width: fit-content;
`;
export const Center = styled.div`
    margin: 0px auto;
    padding: 60px 0px;
    width: 100%;
    background-color: ${colors.secondary30};
    @media screen and (max-width: 1638px) {
        padding: 0px;
    }
`;
export const ConfirmBtnFitRight = styled.div`
    width: fit-content;
    margin: 0px 0px 0px auto;
`;
export const Search = styled.div`
    display: inline-block;
    width: calc(100% - 88px);
    vertical-align: top;
    padding: 14px 20px;
`;
export const Constrain = styled.div`
    width: calc(1088px - 120px);
    margin: 0px auto 15px auto;
    padding: 40px 60px;
    background-color: ${colors.white};
    color: ${colors.primary100};
    border-radius: 4px;
    @media screen and (max-width: 1638px) {
        width: calc(100% - 60px);
        padding: 30px;
        border: 1px solid ${colors.secondary30};
    }
`;
export const Trash = styled.div`
    display: inline-block;
    font-size: 16px;
    padding: 17px 15px;
    vertical-align: top;
    cursor: pointer;
    color: ${colors.secondary70};
`;

export const Attendees = ({senderName, recipientName}) => (
    <EmailSenders>
        <SenderIcon><Letter><FontTitle18>{senderName.charAt(0).toUpperCase()}</FontTitle18></Letter></SenderIcon>
        <SenderNames>
            <FontHeader16>{senderName}</FontHeader16>
            <SenderRecipient><FontBody12>to {recipientName}</FontBody12></SenderRecipient>
        </SenderNames>
    </EmailSenders>
);