import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import {withRouter} from 'react-router-dom';

/* Components */
import {DropdownInput, TextInput, PhoneInput} from "../../../common/components/inputs";

/* Middleware */
import {tryUpdateEditUserAdvisor} from '../middleware/settingsusers';
import NavSecondaryTop from "../../navbars/containers/secondarytop";
import {InlineBlock, SaveSection, Width, SubmitInputWithValidation} from "../../components/clientscreate";
import {Link} from "react-router-dom";
import {ButtonTertiary} from "../../../common/components/buttons";
import {FontTitle18} from "../../../common/components/fonts";

/* Utils */
import {timeZoneOptions} from '../../../common/utils/timezone2';

class SettingsUsersEdit extends React.Component {
    state = {
        first: '', firstValid: true,
        last: '', lastValid: true,
        phone: '', phoneValid: true,
        email: '', emailValid: true,
        companyName: '', companyNameValid: true,
        timeZone: '',
        isSaving: false,
        isSaved: false,
        error: '',
        isChanged: false,
        isValid: true
    };

    componentDidMount() {
        const firstName = this.props.settingsUsers.get("settingsUserSelected").get("firstName");
        const lastName = this.props.settingsUsers.get("settingsUserSelected").get("lastName");
        this.setState({...this.state, ...this.props.settingsUsers.get("settingsUserSelected").toJS(), "first": firstName, "last": lastName})
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    handleChangeFirst = async e => {
        await this.setState({'first': e.target.value, 'firstValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''), 'isChanged': true});
        this.checkValid();
    };
    handleChangeLast = async e => {
        await this.setState({'last': e.target.value, 'lastValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''), 'isChanged': true});
        this.checkValid();
    };
    handleChangeEmail = async e => {
        await this.setState({'email': e.target.value, 'emailValid': validator.isEmail(e.target.value), 'isChanged': true});
        this.checkValid();
    };
    handleChangePhone = async e => {
        await this.setState({'phone': e.target.value, 'phoneValid': validator.isMobilePhone(e.target.value, 'en-US'), 'isChanged': true});
        this.checkValid();
    };
    handleChangeTimeZone = async e => {
        await this.setState({'timeZone': e.value, 'isChanged': true});
        this.checkValid();
    };

    checkValid = () => {
        if(this.state.firstValid && this.state.lastValid && this.state.phoneValid && this.state.emailValid && this.state.timeZone !== "") {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            this.setState({'isSaving': true, 'error': ''});
            await this.props.tryUpdateEditUserAdvisor(this.state);
            if(this.props.settingsUsers.get("settingsUsersError") !== "") {
                this.setState({'error': this.props.settingsUsers.get("settingsUsersError"), 'isSaving': false, 'isSaved': true, 'isChanged': false});
                this.timeout = setTimeout(() => {this.setState({'error': null, 'isSaving': false, 'isSaved': false, 'isChanged': true})}, 3000);
            } else {
                this.setState({'isSaving': false, 'isSaved': true, 'isChanged': false});
                this.timeout = setTimeout(() => {this.props.history.push("/settings/users")}, 3000);
            }
        }
    };

    render() {
        return(
            <>
                <NavSecondaryTop title={"Edit User"} />
                <form method={"post"} onSubmit={this.handleSubmit}>
                    {this.state.isSaved
                        ?
                        <>
                            {this.state.error === '' && <FontTitle18>Saved!</FontTitle18>}
                            {this.state.error !== '' && <FontTitle18>{this.state.error}</FontTitle18>}
                        </>
                        :
                        <>
                            <Width>
                                <TextInput
                                    title={"First Name"}
                                    valid={this.state.firstValid}
                                    warning={"Required"}
                                    id={"first"}
                                    onChange={this.handleChangeFirst}
                                    placeholder={"First Name"}
                                    value={this.state.first}
                                />
                                <TextInput
                                    title={"Last Name"}
                                    valid={this.state.lastValid}
                                    warning={"Required"}
                                    id={"last"}
                                    onChange={this.handleChangeLast}
                                    placeholder={"Last Name"}
                                    value={this.state.last}
                                />
                                <PhoneInput
                                    title={"Phone"}
                                    valid={this.state.phoneValid}
                                    warning={"Enter a valid mobile phone"}
                                    id={"phone"}
                                    onChange={this.handleChangePhone}
                                    placeholder={'(###) ###-####'}
                                    value={this.state.phone}
                                    options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}}
                                />
                                <TextInput
                                    title={"Email"}
                                    valid={this.state.emailValid}
                                    warning={"Enter a valid email address"}
                                    id={"email"}
                                    onChange={this.handleChangeEmail}
                                    placeholder={"Email"}
                                    value={this.state.email}
                                />
                                <DropdownInput
                                    title={"Time zone"}
                                    options={timeZoneOptions}
                                    id={'timeZone'}
                                    onChange={this.handleChangeTimeZone}
                                    placeholder={'Time Zone'}
                                    value={this.state.timeZone}
                                />
                            </Width>
                            <SaveSection>
                                <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                <InlineBlock><Link to={"/settings/users"}><ButtonTertiary canSubmit={false} label={"Cancel"} /></Link></InlineBlock>
                            </SaveSection>
                        </>
                    }
                </form>
            </>
        )
    }
}

const mapStateToProps = state => ({
    settingsUsers: state.enterprise.settingsUsers
});
const mapDispatchToProps = dispatch => ({
    tryUpdateEditUserAdvisor: (data) => dispatch(tryUpdateEditUserAdvisor(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SettingsUsersEdit));
