import {axiosReq} from "../../common/utils/axios";
import {devConsumerProductGeneral} from "../../consumer/team/static/devdata";

/* Store */
// import { setClientsPurchaseHistoryFetching } from "../store/clientsabout";

/* Static */

export const tryGetPurchaseHistory = (prospectId) => {
    return async dispatch => {
        // dispatch(setClientsPurchaseHistoryFetching(true));
        const purchases = await axiosReq('apiv1/coach/prospects/'+ prospectId + '/get-purchase-history', "GET", {}, purchaseHistoryExample);
        // dispatch(setClientsPurchaseHistoryFetching(false));
        return purchases;
    }
};

const purchaseHistoryExample = [
    {
        "purchaseHistoryId":52,
        "amountPaid":100,
        "purchasedOn":"2021-03-28T17:45:59Z",
        "isCanceled":false,
        "canceledOn":null,
        "isSubscription":false,
        "subcriptionInterval":null,
        "subscriptionDuration":null,
        "isActive":false,
        "isOnTheFlyInvoice":false,
        "productId":71,
        "numberOfSessions":1,
        "sessionLengthInMinutes":30,
        "coverPhotoProduct":null,
        "coachId":44,
        "productTitle":"test",
        "productDescription":"<p>test</p>",
        "downloadUrl":"",
        "serviceType":"online"
    }
];

export const tryGetClientGeneralSessions = (prospectId) => {
    return async dispatch => {
        return axiosReq('apiv1/coach/consumer/sessions/get-available-amount/'+prospectId, "GET", {}, devConsumerProductGeneral);
    }
};