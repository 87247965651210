import { Map, List, fromJS } from 'immutable';

/* Immutable */

const IFRAME_LISTINGS_ALL = 'IFRAME_LISTINGS_ALL';
const IFRAME_LISTINGS_ALL_FETCHING = 'IFRAME_LISTINGS_ALL_FETCHING';
const IFRAME_LISTINGS_STORE_RESET = 'IFRAME_LISTINGS_STORE_RESET';

/* Actions */

export const setListingsAll = (listings) => ({"type": IFRAME_LISTINGS_ALL, 'listings': listings});
export const setListingsAllFetching = (status) => ({"type": IFRAME_LISTINGS_ALL_FETCHING, 'status': status});
export const setListingsStoreReset = () => ({"type": IFRAME_LISTINGS_STORE_RESET});

/* Initial State */

const initialState = Map({
    listingsAll: List(),
    listingsAllFetching: false,
});

/* Reducer */

const listingsReducer = (state=initialState, action) => {
    switch (action.type) {
        case IFRAME_LISTINGS_ALL:
            return state.merge({'listingsAll': fromJS(action.listings)});
        case IFRAME_LISTINGS_ALL_FETCHING:
            return state.merge({'listingsFetching': action.status});
        case IFRAME_LISTINGS_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default listingsReducer;
