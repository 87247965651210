import React from 'react';
import styled from "styled-components";

import {colors} from '../../../common/components/colors';

export const Page = styled.div`
    background-color: ${colors.white};
    border: 1px solid ${colors.secondary30};
    max-width: 1088px;
    color: ${colors.primary100};
    margin: 0px auto 15px auto;
    border-radius: 4px;
`;