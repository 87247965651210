import React, {useState} from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

/* Components */
import {colors} from '../../../../common/components/colors';

import {LogoLoading} from "../../../../common/components/loading";

/* Containers */
import CertPreviewEvents from "./previewevents";

const Foreground = styled.div`    
    position: relative;
    z-index: 2;
`;

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;
const Background = styled.div`    
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.white};
    z-index: 1;
`;


function CertificationTrainingsList() {
    const [isLoading, setLoading] = useState(false);
    if (isLoading) {
        return (<LoadingCenter><LogoLoading/></LoadingCenter>)
    } else {
        return (
            <>
                <Foreground>

                    <CertPreviewEvents/>
                </Foreground>
                <Background/>
            </>
        )
    }
}

export default (withRouter(CertificationTrainingsList));