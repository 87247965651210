import styled from "styled-components";
import {colors} from "../../../../common/components/colors";

export const PreviewBar = styled.div`
    position: fixed;
    ${props => props.isSupport !== true} {
        top: 147px;
    }
    ${props => props.isSupport !== false} {
        top: 93px;
    }
    bottom: 0;
    padding: 0px 25px;
    -webkit-box-shadow: 0px 4px 10px 10px rgba(0,0,0,0.25);
    box-shadow: 0px 4px 10px 10px rgba(0,0,0,0.25);
    background-color: ${colors.white};
    transition: .5s ease;
    overflow-y: scroll;
    ${props => props.isActive !== true} {
        right: 0px;
        width: 768px;
    }
    ${props => props.isActive !== false} {
        right: -858px;
        width: 768px;
    }
    @media screen and (max-width: 1610px) {    
        ${props => props.isActive !== true} {
            right: 0px;
            width: 688px;
        }
        ${props => props.isActive !== false} {
            right: -758px;
            width: 688px;
        }
    }
    @media screen and (max-width: 1088px) {  
        top: 0px;  
        z-index: 50;
        overflow-y: auto;
        ${props => props.isActive !== true} {
            right: 0px;
            width: calc(100% - 50px);
        }
        ${props => props.isActive !== false} {
            right: -558px;
            width: 468px;
        }
    }
`;

export const Close = styled.div`
    margin-top: 20px;
    z-index: 25;
    position: relative;
    cursor: pointer;
    width: fit-content;
    padding: 15px;
    margin-left: auto;
    color: ${colors.action100};
`;