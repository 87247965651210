export default {

    alert100: '#FF9180',
    alert70: '#FFBDB3',
    alert30: '#FFDED9',
    alert5: '#ffe9e6',

    notification100: '#E3DA6A',
    notification70: '#EEE9A6',
    notification30: '#F7F4D2',
    notification15: '#FDF8E7',

    action100: '#6BC8D6',
    action70: '#97D9E2',
    action30: '#D3EFF3',
    action15: '#E9F7F9',

    progress100: '#469B77',
    progress70: '#90C3AD',
    progress30: '#C7E1D6',
    progress5: '#F6FAF8',

    border100: '#E9ECEF',
    border70: '#F2F4F5',
    border30: '#F8F9FA',

    primary100: '#4F5A65',
    primary70: '#959CA3',
    primary30: '#CACED1',

    secondary100: '#5E7281',
    secondary70: '#9EAAB3',
    secondary30: '#CFD5D9',

    background100: '#FDFBFB',
    background30: '#E9ECEF',
    background15: '#F8F9FA',

    white: '#FFFFFF',
    black: '#000000',
    brand: '#D3BB6F',

    stoplightStop100: '#ff644d',
    stoplightStop30: '#FFDED9',

    stoplightSlow100: '#aba221',
    stoplightSlow30: '#F7F4D2',

    stoplightGo100: '#469B77',
    stoplightGo30: '#C7E1D6',

    graphColors: [
        '#38726c',
        '#e9d758',
        '#ff8552',
        '#c14953',
        '#e06c9f',
        '#966b9d',
        '#2b3a67',
        '#2c91a0',
        '#b07156',
        '#bfd7ea',
        '#009FFD',
        '#5D5D74',
        '#BFC3C6',
    ]

};