/* Domain */
import getDomainEnv from '../../domains/utils';
const env = getDomainEnv();

export const images = {

    // In domain images file:
    // favicon16px
    // favicon32px
    // favicon256px
    // logo
    // logoIcon
    // error1
    // error2
    // error3
    // calSyncBrand


    "shareImage": "https://docs.trustwillow.com/share.svg",
    "shareImage1": "https://docs.trustwillow.com/Share+icon.png",
    "shareImage2": "https://docs.trustwillow.com/Sign+up+icon.png",
    "shareImage3": "https://docs.trustwillow.com/Rewards+icon.png",

    "careIcon": "https://docs.trustwillow.com/Care+icon.png",
    "employeesIcon": "https://docs.trustwillow.com/Employees+icon.png",
    "willowBadge": "https://docs.trustwillow.com/Group+703.png",
    "vaultFilePDF": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615063589/clqgxxwq2bdxgb500nzv.png",
    "vaultFileGeneric": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615063605/taswjblqcieozdjw4t7l.png",
    "vaultFileImage": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615063619/zgjrdnlhldkpitlnkty6.png",
    "vaultFileExcel": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615063635/ijfxvpsxqjvgtgyxn8xr.png",
    "vaultFileWord": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615063661/aha8l0ej6be56ek0igzp.png",
    "vaultFilePowerPoint": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615063649/nbjf3dgifyxjxhklryz5.png",
    "vaultFileFolderPrivate": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615063722/vznfs61z3ogytyfrs0pr.png",
    "vaultFileFolderShared": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615063708/xj9nhnkj146n9tqa4dav.png",
    "vaultFolderShared": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615063695/bs3xys5atpywimee0bzr.png",
    "vaultFolderPrivate": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615063678/pmuoilds4hpkdfysm16w.png",

    "calSyncBrandMicrosoft": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615064719/eokuzwfqdso4kbvuzqk5.png",
    "calSyncBrandGoogle": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615064705/oi5cbp7jdwiltgcbwsdf.png",
    "calSyncBrandCalendly": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1704815141/assets/calendly_zaw808.png",
    "calSyncIconCopy": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1616812271/szqiymglu7jbyxsjv3xo.png",
    "calSyncIconTrash": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615064735/rrledz9pdevtsmxz7ddp.png",

    "prospectIconDrag": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615064516/agt1ql6ojkeomplx3dzw.png",

    "creditCardVisa": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1620670084/qlsoueko7jncsmenei1w.png",
    "creditCardMastercard": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1620670115/euzijqbd1zkcfxq3kvrr.png",
    "creditCardAmex": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1620670135/ntkcpk9unwmtkpduwvj3.png",
    "creditCardGeneral": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1620670155/e79jl1hqznrqcqzvildh.png",

    "schedulerIconBooked": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067379/l3bgdqusna4htkvniink.png",
    "schedulerIconNotBooked": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615067391/lskp3tbe3burzpao4ca0.png",

    "contentArticleIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633115938/hlfg06w2dogs3skxcyzv.png",
    "contentVideoIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633115953/ijidygcdytrr2aidmrzl.png",
    "contentCourseIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633115971/fjae46ctizjqxvxlowcn.png",
    "contentVideoStartStopIcon":"https://res.cloudinary.com/blz-lilyriche/image/upload/v1633803352/cph4gckudrvkmfqurzdx.png",

    "courseTitleIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1642531790/asnpjwqffy72jvqu3hov.png",
    "courseSubtitleIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1642531825/qbuzvumqhifnphtw4fzc.png",
    "courseBodyIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1642531858/zg0ltg0mbipidi51culx.png",
    "courseDisclosureIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1642540589/hs9q7mptpox0e41vtzdj.png",
    "courseVideoIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1642530488/fpr21fjsej291y0mxi07.png",
    "courseHTMLIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1661361535/hy5809hp57nvizadntyd.png",
    "courseImageIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1642530390/jnhwaabal6eo4w9yiyc6.png",
    "courseBorderIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1642530600/yytvf8y4fgjxyhjltwbc.png",
    "courseSpacerIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1642530642/zs3f2zzhg6tj8ofqdukn.png",
    "courseSliderIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1656423393/hxfyrhqu7ipoorojqezm.png",
    "courseOpenResponseIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1656421574/cv6nyht2jxglmbhxckia.png",
    "courseMultipleChoiceIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1656421793/h6cwsey4nruaeh1av5ox.png",
    "courseUploadIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1656422144/qboh0p2g8w4yyiwltqll.png",
    "courseCalendarIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1656467276/nnn8awrgefrj3gxehl3t.png",

    "grayBackgroundImage": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1642541406/rtj49owi8zszjzrmylud.png",

    "chevronDown": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634180253/kk6pn5hwmysfkkdvcakt.png",
    "chevronUp": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634180286/wwemihea2xnxuwrzpigj.png",
    "chevronLeft": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634180305/ybjyazahitqi0wi8zpmm.png",
    "chevronLeftWhite": "https://docs.trustwillow.com/ChevronLeftWhite.png",
    "chevronRightDark": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634650973/avonqd9hcbtkbfsrfxoc.png",
    "chevronRightWhite": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634651098/lfoi0m9ycdyjayz0net6.png",

    "progressCircleCompleted": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634181567/qzd03z3pxdqwefgnnxc4.png",
    "progressCircleNotCompleted": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634181603/darnkfxz8u1xm2p0hsll.png",
    "progressCheckMark": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634223344/wub2urjsefdbuytrpfud.png",
    "progressCircleCompletedNavy": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1642541051/vhc701jy1f59pcmsparh.png",
    "progressCircleNotCompletedNavy": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1642541159/ntbzwz5oxn16sqcl680v.png",

    "videoCamWhite": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634999081/eue47mozgxhk4vindhpb.png",
    "messagesWhite": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634302432/ort1zmotggfqasgihs1s.png",
    "addWhite": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634303293/krdepga0whjn0lobgty1.png",
    "uploadWhite": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634303364/f10iflv2umud8cqjfwey.png",
    "rescheduleDark": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634303401/hrkpbkdckp0c72ajzjys.png",
    "rescheduleWhite": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634303432/jmq0wpfecosrwzovsbvj.png",

    "completeIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634830497/mthyrsojppaktxgr9wrz.png",
    "failedIcon": "https://docs.trustwillow.com/errorIcon2.png",

    "calendarIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635447834/fo2uyochddlcuiywruvp.png",

    "helpCircle": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635522681/mrhpzsqcfffefxhrqhlx.png",
    "faq": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635522846/n7hj6js6rwtw3njf7eiq.png",
    "OldestIcon": "https://docs.trustwillow.com/Oldest icon.png",
    "OnlyChildIcon": "https://docs.trustwillow.com/Only child.png",
    "YoungestIcon": "https://docs.trustwillow.com/Youngest icon.png",
    "AmphibianIcon": "https://docs.trustwillow.com/Amphibian icon.png",
    "BearIcon": "https://docs.trustwillow.com/Bear icon.png",
    "BirdIcon": "https://docs.trustwillow.com/Bird icon.png",
    "CatIcon": "https://docs.trustwillow.com/Cat icon.png",
    "ChickenIcon": "https://docs.trustwillow.com/Chicken icon.png",
    "CowIcon": "https://docs.trustwillow.com/Cow icon.png",
    "MiddleIcon": "https://docs.trustwillow.com/Middle icon.png",
    "DogIcon": "https://docs.trustwillow.com/Dog icon.png",
    "DolphinIcon": "https://docs.trustwillow.com/Dolphin icon.png",
    "ElephantIcon": "https://docs.trustwillow.com/Elephant.png",
    "FishIcon": "https://docs.trustwillow.com/Fish icon.png",
    "FoxIcon": "https://docs.trustwillow.com/Fox icon.png",
    "GiraffeIcon": "https://docs.trustwillow.com/Giraffe icon.png",
    "HorseIcon": "https://docs.trustwillow.com/Horse icon.png",
    "LionIcon": "https://docs.trustwillow.com/Lion icon.png",
    "MonkeyIcon": "https://docs.trustwillow.com/Monkey icon.png",
    "OtterIcon": "https://docs.trustwillow.com/Otter icon.png",
    "PigIcon": "https://docs.trustwillow.com/Pig icon.png",
    "RabbitIcon": "https://docs.trustwillow.com/Rabbit icon.png",
    "ReptilesIcon": "https://docs.trustwillow.com/Reptiles icon.png",
    "SharkIcon": "https://docs.trustwillow.com/Shark icon.png",
    "SquirrelIcon": "https://docs.trustwillow.com/Squirrel icon.png",
    "TigerIcon": "https://docs.trustwillow.com/Tiger icon.png",
    "TurtleIcon": "https://docs.trustwillow.com/Turtle icon.png",
    "WhaleIcon": "https://docs.trustwillow.com/Whale icon.png",
    "ENFJIcon": "https://docs.trustwillow.com/ENFJ icon.png",
    "ENFPIcon": "https://docs.trustwillow.com/ENFP icon.png",
    "ENTJIcon": "https://docs.trustwillow.com/ENTJ icon.png",
    "ENTPIcon": "https://docs.trustwillow.com/ENTP icon.png",
    "ESFJIcon": "https://docs.trustwillow.com/ESFJ icon.png",
    "ESFPIcon": "https://docs.trustwillow.com/ESFP icon.png",
    "ESTJIcon": "https://docs.trustwillow.com/ESTJ icon.png",
    "ESTPIcon": "https://docs.trustwillow.com/ESTP icon.png",
    "INFJIcon": "https://docs.trustwillow.com/INFJ icon.png",
    "INFPIcon": "https://docs.trustwillow.com/INFP icon.png",
    "INTJIcon": "https://docs.trustwillow.com/INTJ icon.png",
    "INTPIcon": "https://docs.trustwillow.com/INTP icon.png",
    "ISFJIcon": "https://docs.trustwillow.com/ISFJ icon.png",
    "ISFPIcon": "https://docs.trustwillow.com/ISFP icon.png",
    "ISTJIcon": "https://docs.trustwillow.com/ISTJ icon.png",
    "ISTPIcon": "https://docs.trustwillow.com/ISTP icon.png",
    "AquariusIcon": "https://docs.trustwillow.com/Aquarius icon.png",
    "AriesIcon": "https://docs.trustwillow.com/Aries icon.png",
    "CancerIcon": "https://docs.trustwillow.com/Cancer icon.png",
    "CapricornIcon": "https://docs.trustwillow.com/Capricorn.png",
    "GeminiIcon": "https://docs.trustwillow.com/Gemini icon.png",
    "LeoIcon": "https://docs.trustwillow.com/Leo icon.png",
    "LibraIcon": "https://docs.trustwillow.com/Libra icon.png",
    "PiscesIcon": "https://docs.trustwillow.com/Pisces icon.png",
    "SagittariusIcon": "https://docs.trustwillow.com/Sagitarrius icon.png",
    "ScorpioIcon": "https://docs.trustwillow.com/Scorpio.png",
    "TaurusIcon": "https://docs.trustwillow.com/Taurus icon.png",
    "VirgoIcon": "https://docs.trustwillow.com/Virgo icon.png",
    "ActivismIcon": "https://docs.trustwillow.com/Activism.png",
    "ArtIcon": "https://docs.trustwillow.com/Art icon.png",
    "BakingIcon": "https://docs.trustwillow.com/Baking icon.png",
    "CoffeeTeaIcon": "https://docs.trustwillow.com/Coffee_tea icon.png",
    "CookingIcon": "https://docs.trustwillow.com/Cooking icon.png",
    "CraftsIcon": "https://docs.trustwillow.com/Crafts icon.png",
    "DancingIcon": "https://docs.trustwillow.com/Dancing icon.png",
    "ExerciseIcon": "https://docs.trustwillow.com/Exercise icon.png",
    "FaithSpiritualityIcon": "https://docs.trustwillow.com/faith_spirituality icon.png",
    "FriendsIcon": "https://docs.trustwillow.com/Friends icon.png",
    "GamingIcon": "https://docs.trustwillow.com/Gaming.png",
    "GardeningIcon": "https://docs.trustwillow.com/Gardening icon.png",
    "GymIcon": "https://docs.trustwillow.com/Gym icon.png",
    "KnittingSewingIcon": "https://docs.trustwillow.com/knitting_sewing icon.png",
    "MoviesTVIcon": "https://docs.trustwillow.com/Movies_TV icon.png",
    "NutritionIcon": "https://docs.trustwillow.com/Nutrition.png",
    "OutdoorsIcon": "https://docs.trustwillow.com/Outdoors icon.png",
    "PerformanceArtsIcon": "https://docs.trustwillow.com/Performance arts.png",
    "PhotographyIcon": "https://docs.trustwillow.com/Photography icon.png",
    "PuzzlesIcon": "https://docs.trustwillow.com/Puzzles icon.png",
    "ReadingIcon": "https://docs.trustwillow.com/Reading icon.png",
    "SportsIcon": "https://docs.trustwillow.com/Sports icon.png",
    "TravelIcon": "https://docs.trustwillow.com/Travel icon.png",
    "WalkingHikingIcon": "https://docs.trustwillow.com/walking_hiking icon.png",
    "WhoHasTimeForHobbiesIcon": "https://docs.trustwillow.com/Who has time for Hobbies_ icon.png",
    "WineSpiritsIcon": "https://docs.trustwillow.com/Wine_spirits icon.png",


    // DEVDATA - START //

    "devDataCoachMichelleAvatar": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1594389741/npd6aks7yaopcrxu5duc.png",
    "devDataCoachJenniferAvatar": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1604496165/rlc3qe7gkj0deanxavtp.png",
    "devDataCoachMeganAvatar": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1604365307/frfaoi99ihoeugsqx96l.png",
    "devDataAdvisorCarlAvatar": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615072698/zvxepl8xhpotcusgmyi5.jpg",

    "devDataTeamJasonAvatar": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1611689464/gxackkrbdt93lu5j2vvf.jpg",
    "devDataTeamIsabelleAvatar": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1616769850/kmwrc0xpnfqbwfqeqjnw.png",
    "devDataTeamDanielleAvatar": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1616768186/yt0dr8qaeeljjwta4pbe.jpg",
    "devDataTeamGabbyAvatar": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633402700/wiyhcppbfbllfzzndmzp.jpg",
    "devDataTeamLacyAvatar": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1602605069/chyjbqe6ql6foprctnww.png",

    "devDataStoreHero": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615071265/eydyjpg7lvxbkdonpbuw.jpg",
    "devDataStorePortfolio": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615071894/cim1lxbflsu9mvmixnlv.png",
    "devDataStoreProduct": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615072582/shqog59xptiewlhclinc.jpg",
    "devDataStoreCertCFA": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615071763/c9oaurxkuz6uhcfcvwgz.png",
    "devDataStoreCertFinra": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615071800/oawntibdmqadbuuflsmm.jpg",
    "devDataStoreSchool": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615071820/nawdbsh5aoxfc1winxo5.jpg",
    "devDataStoreJobStateStreet": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615071850/gtqpahuu43jr77dhcqn4.jpg",
    "devDataStoreJobInkberry": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615071869/ddte3gpgdxu0jldewb2o.jpg",

    "devDataWebinarCoverPhoto": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1619966654/hu6hld3xej6jwpeen5up",

    // DEVDATA - END //

    //

    // DEPRECATED - START //

    "videoQuality1": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615062100/bw2ynxt7wh3mawfxcrqc.png",
    "videoQuality2": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615062149/ojl8u5dxeooaaxe9yagj.png",
    "videoQuality3": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615062163/uljzicuezrlf89lefinr.png",
    "videoQuality4": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615062175/lh9tubykrsbogk8lafzy.png",
    "videoQuality5": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615062191/ymogd5zhf9tqbrq6bi9d.png",
    "videoCamOff": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615062436/dp3slllaz0z2wm0njggh.png",
    "videoCamOn": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615062467/mquxja7kbxeudoch5bcf.png",
    "videoChatOff": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615062483/t2dpqcgf9eqg9tvpztzb.png",
    "videoChatOn": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615062504/ewjg6tg8vlt5o71w3an7.png",
    "videoEnd": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615062776/c9fsl6pwcgpzop6bclu1.png",
    "videoMicOff": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615062803/tcndubbbs2zafug1sbgv.png",
    "videoMicOn": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615062838/vqwpflnszj5qgofokii4.png",
    "videoOptionsOff": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615062861/mr7dywedltdxynhmz3cy.png",
    "videoOptionsOn": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615062923/jjck4mfde4fv6b4sbua3.png",
    "videoShareOff": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615062946/j8rllhlawt4rutvkhtrg.png",
    "videoShareOn": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615062977/sytxvtrk7ohm338kxwiu.png",

    // DEPRECATED - END //

    ...env.STYLES.IMAGES

};