import React from 'react';
import {connect} from 'react-redux';

/* Components */
import {FontTitle21, FontTitle18} from "../../../common/components/fonts";
import {Page, ModalDelete, Title, Background, Center, CancelButton} from '../components/cancel';
import {ButtonLinkInactive} from "../../../common/components/buttons";

/* Containers */
import CheckoutCardsAddStripe from '../../../iframes/cards/containers/cardsaddstripe';

/* Middleware */
import {tryGetBillingPaymentMethod} from "../middleware/billing";

class BillingAdd extends React.Component {
    state={"isSaved": false, "isSaving": false};

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    timeout = 0;

    close = async () => {
        return this.props.showAdd(false)
    };

    onSaveCard = async e => {
        this.setState({'isSaving': true});
        await this.props.tryGetBillingPaymentMethod();
        this.setState({'isSaving': false, 'isSaved': true});
        this.timeout = setTimeout(this.close, 1000);
    };

    render() {
        return (
            <Page>
                <ModalDelete>
                    {this.state.isSaved
                        ? <Center><FontTitle18>Your card has been saved</FontTitle18></Center>
                        :
                        <>
                            <Title><FontTitle21>Add Card</FontTitle21></Title>
                            <CheckoutCardsAddStripe
                                onSaveCard={this.onSaveCard}
                            />
                            <br />
                            <Center>
                                <CancelButton onClick={() => {return this.props.showAdd(false)}}>
                                    <ButtonLinkInactive submit={false} label={"Cancel"}/>
                                </CancelButton>
                            </Center>
                        </>
                    }
                </ModalDelete>
                {this.state.isSaved ?
                    <Background onClick={this.close}/>
                    :
                    <Background />
                }
            </Page>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    tryGetBillingPaymentMethod: () => dispatch(tryGetBillingPaymentMethod())
});

export default connect(null, mapDispatchToProps)(BillingAdd);