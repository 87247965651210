import React from 'react';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import moment from "moment";

import { UserOutlined, CalendarOutlined, ReadOutlined, SettingOutlined, DollarCircleOutlined, NodeIndexOutlined, TeamOutlined, FolderAddOutlined } from '@ant-design/icons';

import { colors } from '../../../common/components/colors';
import { FontHeader18, FontBody14, FontBody18, FontSubtitle18, FontBody16 } from '../../../common/components/fonts';

import {images} from '../../../common/components/images'

import {setNavPageStatus} from "../../../common/containers/navexpanded/store/nav";
import {connect} from "react-redux";

import {tryGetAllTeamMembers} from '../../../consumer/team/middleware/team';

import {tryLogout} from '../../../routes/middleware/auth';

const NavSide = styled.div`
    -webkit-box-shadow: 6px 0px 18px 0px rgba(0, 0, 0, 0.06);
    box-shadow: 6px 0px 18px 0px rgba(0, 0, 0, 0.06);
    width: 320px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 4;
    background-color: ${colors.backgroundColor2};
    @media screen and (max-width: 1088px) {
        display: none;
    }
`;
const Container = styled.div`
    height: auto;
    position: relative;
`;
const LogoDate = styled.div`
    height: 40px;
    padding: 40px 10px 20px 25px;

    display:flex; 
    flex-direction:row;
    align-items: center;
`;
const NavSection = styled.div`
    padding: 10px 10px;
    width: fit-content;
    
`;
const Sub = styled.div`
    margin: 30px 40px 0px 40px;
`;
const Button = styled.div`
    ${props => props.status !== 'active'} {color: ${colors.backgroundColor4}; font-weight: bold} 
    ${props => props.status !== 'inactive'} {color: ${colors.white};}
    color: ${colors.primary100};
    padding-left: 10px;
    width: fit-content;
    cursor: pointer;
`;

const LogOut = styled.div`
    border-style: solid;
    border-color: white;
    border-radius: 30px;
    border-width: thin;

    margin: auto;
    margin-top: 20px;
    width: fit-content;
    padding: 10px 60px;

    text-align: center;
    color: ${colors.white};
    
    cursor: pointer;
`;
const WillowLogo = styled.img`
    width: 35px;
    height: auto;
`;
const Date = styled.div`
    color: ${colors.white};
    letter-spacing: 4px;
    text-transform: uppercase;

    padding-left: 15px;
    cursor: default;
`;
const IconTextContainer = styled.div`
    display:flex; 
    flex-direction:row;
    align-items: center;
    padding-left: 15px;
    
    width: fit-content;
`
const SidebarIcon = styled.img`
    width: 40px;
    height: 40px;
    cursor: pointer;
`
const SideIconActiveOrNot = ({data, status}) => {
    if(status === "active") {
        return(<SidebarIcon src={data.navSideIconActive} alt={""} />)
    } else {
        return(<SidebarIcon src={data.navSideIconInactive} alt={""} />)
    }
};

class NavPrimary extends React.Component {
    state={"showMyMoney": false};

    componentDidMount() {
        if (this.props.userCoaches.size === 0) {
            this.props.tryGetAllTeamMembers();
        }
        else if (this.props.userCoaches.size > 0) {
            this.checkShowMyMoney();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.userCoaches !== this.props.userCoaches && this.props.userCoaches.size > 0) {
            return this.checkShowMyMoney();
        }
    }

    checkShowMyMoney = async () => {
        await this.setState({
            "showMyMoney": (this.props.userCoaches.filter(a => {
                return(a.get("expertCategories").map(b => {
                    return(b.get("categoryId") === 1 || b.get("categoryId") === 2 || b.get("categoryId") === 4)
                }).includes(true))
            }).size > 0)
        });
    };

    render() {
        return(
            <NavSide>
                <Container>
                    <LogoDate>
                        <WillowLogo src={images.logoIcon} />
                        <Date><FontSubtitle18>{moment().format('MMMM D, YYYY')}</FontSubtitle18></Date>
                    </LogoDate>
                    {this.props.data.nav.map((navSection, navSectionIndex) => (
                        <NavSection key={navSectionIndex}>
                            {navSection.map((d, index) => {
                                let status = "inactive";
                                if (this.props.location.pathname.split("/")[1] === d.navSideHref.split("/")[1]) {status = "active"}
                                if (d.navSideHref !== "/my-financial-profile" || (d.navSideHref === "/my-financial-profile" && this.state.showMyMoney)) {
                                    return(
                                        <Link key={index} to={d.navSideHref} style={{cursor: "default"}}>
                                            <IconTextContainer>
                                                <SideIconActiveOrNot data={d} status ={status}/>
                                                <Button status={status}><FontBody18>{d.navSideTitle}</FontBody18></Button>
                                            </IconTextContainer>
                                        </Link>
                                    )
                                } else {
                                    return null;
                                }
                            })}
                        </NavSection>
                    ))}
                    <LogOut onClick={this.props.tryLogout}><FontBody18>Log Out</FontBody18></LogOut>
                </Container>
            </NavSide>
        )
    }
}

const mapStateToProps2 = state => ({
    userCoaches: state.consumer.team.team.get("members")
});

const mapDispatchToProps2 = dispatch => ({
    tryGetAllTeamMembers: () => dispatch(tryGetAllTeamMembers()),
    tryLogout: () => dispatch(tryLogout())
});

export const NavPrimaryWrapper = connect(mapStateToProps2, mapDispatchToProps2)(withRouter(NavPrimary));

// export const NavPrimaryWrapper = withRouter(NavPrimary);

const NavBottom = styled.div`
    display: none;
    -webkit-box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.1);
    height: 49px;
    width: calc(100% - 40px);
    padding: 15px 20px;
    z-index: 3;
    text-align: center;
    position: fixed;
    bottom: 0;
    right: 0;
    border-radius: 20px 20px 0px 0px;
    background-color: ${colors.white};
    @media screen and (max-width: 1088px) {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 13px 20px 5px 20px;
    }
    @media screen and (max-width: 460px) {
        width: calc(100% - 0px);
        padding: 7px 0px 0px 0px;
    }
`;
const ButtonMobile = styled.div`
    height: 30px;
    display: none;
    cursor: pointer;
    text-align: center;
    ${props => props.status !== 'active'} {color: ${colors.primary100};}
    ${props => props.status !== 'inactive'} {color: ${colors.primary100};}
    @media screen and (max-width: 1088px) {
        display: inline-block;
        // width: 20%;
        & div {
            font-size: 16px;
            line-height: 16px;
        }
    }
    @media screen and (max-width: 460px) {
        // width: 24%;
        & div {
            font-size: 14px;
            line-height: 14px;
        }
    }
`;
const Icon = styled.div`
    height: 30px;
    span {
        font-size: 24px;
        padding: 2px 0px;
        display: block;
        vertical-align: top;
        margin: 0px auto;
    }
`;
const Invert = styled.div`
    -moz-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1);
`;

const IconImage = styled.img`
    width: 40px;
    height: 30px;
`;

const MobileIconOrImage = ({data, pathname}) => {
    if(data.navMobileIcon !== null && data.navMobileImageInactive === null) {
        return(data.navMobileIcon)
    } else if(data.navMobileIcon === null && data.navMobileImageInactive !== null) {
        if(pathname.includes(data.rootHref)) {
            return(<IconImage src={data.navMobileImageActive} alt={""} />)
        } else {
            return(<IconImage src={data.navMobileImageInactive} alt={""} />)
        }
    } else {
        return(<div>Fix</div>)
    }
};

class NavPrimaryMobile extends React.Component {
    state={"showMyMoney": false};

    componentDidMount() {
        if (this.props.userCoaches.size === 0) {
            this.props.tryGetAllTeamMembers()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.userCoaches !== this.props.userCoaches && this.props.userCoaches.size > 0) {
            return this.checkShowMyMoney();
        }
    }

    checkShowMyMoney = async () => {
        await this.setState({
            "showMyMoney": (this.props.userCoaches.filter(a => {
                return(a.get("expertCategories").map(b => {
                    return(b.get("categoryId") === 1 || b.get("categoryId") === 2 || b.get("categoryId") === 4)
                }).includes(true))
            }).size > 0)
        });
    };

    render() {
        return(
            <NavBottom>
                {this.props.data.nav.map((navSection, navSectionIndex) => (
                    <React.Fragment key={navSectionIndex}>
                        {navSection.map((d, index) => {                            
                            let status = "inactive";
                            if(d.navMobileTitle === "Settings") {
                                if(this.props.location.pathname.split("/")[1] === "feedback" || this.props.location.pathname.split("/")[1] === "support" || this.props.location.pathname.split("/")[1] === "settings") {status = "active"}
                                return(
                                    <Link key={index} to={"/settings/personal-info"} onClick={() => {this.props.setNavPageStatus(!this.props.pageStatusActive)}}>
                                        <ButtonMobile status={status}>
                                            <Icon><SettingOutlined /></Icon>
                                            <FontBody14>{d.navMobileTitle}</FontBody14>
                                        </ButtonMobile>
                                    </Link>)
                            } 
                            if(d.navMobileHref === null) {
                                return null
                            }
                            else {
                                if(this.props.location.pathname.split("/")[1] === d.navMobileHref.split("/")[1]) {status = "active"}
                                return(
                                    <Link key={index} to={d.navMobileHref}>
                                        <ButtonMobile status={status}>
                                            <Icon>
                                                {d.navMobileIconInvert
                                                    ? <Invert><MobileIconOrImage data={d} pathname={this.props.location.pathname} /></Invert>
                                                    : <MobileIconOrImage data={d} pathname={this.props.location.pathname}  />
                                                }
                                            </Icon>
                                            <FontBody14>{d.navMobileTitle}</FontBody14>
                                        </ButtonMobile>
                                    </Link>
                                )
                            }
                        })}
                    </React.Fragment>
                ))}
            </NavBottom>
        )
    }
}
const mapStateToProps = state => ({
    pageStatusActive: state.common.nav.get('pageStatusActive'),
    userCoaches: state.consumer.team.team.get("members")
});
const mapDispatchToProps = dispatch => ({
    setNavPageStatus: (status) => dispatch(setNavPageStatus(status)),
    tryGetAllTeamMembers: () => dispatch(tryGetAllTeamMembers())
});
export const NavPrimaryMobileWrapper = connect(mapStateToProps, mapDispatchToProps)(withRouter(NavPrimaryMobile));
