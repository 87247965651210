import React from 'react';
import styled from 'styled-components';

/* Components */
import { FontSubtitle16, FontSubtitle14, FontTitle22} from '../../../common/components/fonts';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const MemberListItemNameTitle = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
`;
const ProfileIcon = styled.img`
    border-radius: 50%;
    height: 80px;
    width: 80px;
    object-fit: cover;
`;
const ContainerCoachCellTop = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding: 25px 20px;
    align-items: center;
    border-radius: 30px 30px 0px 30px;
    z-index: 0;
`;

class HeaderCoach extends React.Component {
    static defaultProps = {
        forceSortCoaches: false,
        data: {
            photo: "",
            first: "",
            last: "",
            id: null,
            expertCategories: []
        }
    };

    /* Temporary function to check if coach id is of the coaches we want to show for video */
    checkInCoaches = (coachId) => {
        return /*coachId === 3452 || coachId === 1 || */coachId === 40 || coachId === 49 || coachId === 3452 || coachId === 37 || coachId === 76;
    }

    /* Temporary function to get certain coaches' title(s) */
    getCoachTitle = (coachId) => {
        if (coachId === 40 || coachId === 3452 || coachId === 37) {
            return 'Financial Coach';
        } else if (coachId === 49) {
            return 'Financial Coach, Life Coach';
        } else if (coachId === 76) {
            return 'Financial Coach, Divorce Coach'
        }
    }

    /* Temporary function to get certain coaches' specialties */
    getCoachSpecialties = (coachId) => {
        if (coachId === 40 ) {
            return 'Specialties: Financial, Life, Grief & Loss, Divorce';
        } else if (coachId === 3452) {
            return 'Specialties: Financial, Life, Business, Executive';
        } else if (coachId === 49) {
            return 'Specialties: Financial, Life, Grief & Loss';
        } else if (coachId === 76 || coachId === 37) {
            return 'Specialties: Financial, Life, Divorce';
        }
    }

    render() {
        return (
            <ContainerCoachCellTop>
                <ProfileIcon src={this.props.data.photo} alt={"Willow"} />
                <MemberListItemNameTitle>
                    <FontTitle22>{this.props.data.first + ' ' + this.props.data.last}</FontTitle22>
                    {(this.props.forceSortCoaches && this.checkInCoaches(this.props.data.id)) ?
                        <>
                            <FontSubtitle14>{this.getCoachTitle(this.props.data.id)}</FontSubtitle14>
                            <FontSubtitle14>{this.getCoachSpecialties(this.props.data.id)}</FontSubtitle14>
                        </>
                        : <FontSubtitle16>
                            {env.CONSUMER.CARE.PRINT_EXPERT_CATEGORIES(this.props.data.expertCategories, true)}
                        </FontSubtitle16>
                    }
                </MemberListItemNameTitle>
            </ContainerCoachCellTop>
        )
    }

}

export default HeaderCoach;
