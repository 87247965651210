import {v4 as uuid4} from "uuid";

const matchingForm = [
    {
        "pageNum": 0,
        "header": {
            "title": "Help your financial coach prepare",
        },
        "actions": {
            "pageNumVisible": true,
            "backLabel": "",
            "nextLabel": "Continue",
            "canContinueAutomatically": false,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "checkbox",
                "title": "What challenges are the client facing?",
                "body": "(check all that apply)",
                "maxCheckable": null,
                "isValid": false,
                "isRequired": true,
                "options": [
                    {"id": 1, "active": false, "label": "Navigating a divorce"},
                    {"id": 2, "active": false, "label": "Divorced"},
                    {"id": 3, "active": false, "label": "Widowhood"},
                    {"id": 4, "active": false, "label": "Caregiver for aging loved ones"},
                    {"id": 5, "active": false, "label": "In charge of my family finances"},
                    {"id": 6, "active": false, "label": "Single parent"},
                    {"id": 7, "active": false, "label": "Navigating career"},
                    {"id": 8, "active": false, "label": "Starting out financially"},
                    {"id": 9, "active": false, "label": "Planning for retirement"},
                    {"id": 10, "active": false, "label": "Saving for other goals"},
                    {"id": 11, "active": false, "label": "Looking to grow wealth"}
                ]
            }
        ]
    },
    {
        "pageNum": 1,
        "header": {
            "title": "Help your financial coach prepare",
        },
        "actions": {
            "pageNumVisible": true,
            "backLabel": "Back",
            "nextLabel": "Continue",
            "canContinueAutomatically": false,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "checkbox",
                "title": "What are your client's coaching goals?",
                "body": "(check all that apply)",
                "maxCheckable": null,
                "isValid": false,
                "isRequired": true,
                "options": [
                    {"id": 1, "active": false, "label": "Organize finances"},
                    {"id": 2, "active": false, "label": "Budgeting and paying bills"},
                    {"id": 3, "active": false, "label": "Improve credit score"},
                    {"id": 4, "active": false, "label": "Reduce debt"},
                    {"id": 5, "active": false, "label": "Easing financial stress and anxiety"},
                    {"id": 6, "active": false, "label": "Estate planning"},
                    {"id": 7, "active": false, "label": "Workplace benefits and insurance"},
                    {"id": 8, "active": false, "label": "Saving for education and future expenses"},
                    {"id": 9, "active": false, "label": "Improving or changing career"},
                    {"id": 10, "active": false, "label": "Considering a real estate purchase or rental"},
                    {"id": 11, "active": false, "label": "Changing finances in a relationship"}
                ]
            }
        ]
    },
    {
        "pageNum": 2,
        "header": {
            "title": "Help your financial coach prepare",
        },
        "actions": {
            "pageNumVisible": true,
            "backLabel": "Back",
            "nextLabel": "Continue",
            "canContinueAutomatically": true,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "specialcoaching",
                "title": "Our goal is to bring you the best match with a coach. Do any of these profiles match your client's coaching preferences?",
                "body": "(optional, choose any)",
                "isRequired": false,
                "options": [
                    {
                        "id": 1,
                        "active": false,
                        "icon": "coachstyle_military",
                        "title": "Understands the life of a military family",
                        "body": "They know the nuances between personal finance for civilians and those who are on active duty or have military backgrounds."
                    },
                    {
                        "id": 2,
                        "active": false,
                        "icon": "coachstyle_nononsense",
                        "title": "Has a no-nonsense approach",
                        "body": "They're not afraid of straight talk and will deliver their message with tough love if needed."
                    },
                    {
                        "id": 3,
                        "active": false,
                        "icon": "coachstyle_spiritual",
                        "title": "Integrates spirituality into their coaching",
                        "body": "They are comfortable talking about matters of faith and the intertwines with money"
                    },
                    {
                        "id": 4,
                        "active": false,
                        "icon": "coachstyle_emotional",
                        "title": "Emphasizes the emotional aspects of personal finance",
                        "body": "They'll help me reflect on my feelings about money and can understand that there is a lot beneath the surface when it comes to my history with money."
                    },
                    {
                        "id": 5,
                        "active": false,
                        "icon": "coachstyle_underserved",
                        "title": "Knows what it's like to be part of a minority community",
                        "body": "They understand not all money experiences are the same and they’re ready to help me forge my path forward."
                    },
                ]
            }
        ]
    },
    {
        "pageNum": 3,
        "header": {
            "title": "Help your financial coach prepare",
        },
        "actions": {
            "pageNumVisible": true,
            "backLabel": "Back",
            "nextLabel": "Submit",
            "canContinueAutomatically": true,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "checkbox",
                "title": "Does the client have a gender preference for their coach?",
                "body": "(select one)",
                "isRequired": true,
                "maxCheckable": 1,
                "isValid": false,
                "options": [
                    {"id": 1, "active": false, "label": "Female"},
                    {"id": 2, "active": false, "label": "Male"},
                    {"id": 3, "active": false, "label": "No Preference"},
                ]
            }
        ]
    }
];

export default matchingForm;