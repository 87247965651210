import { Map, fromJS } from 'immutable';

/* Immutable */

const ANNOUNCEMENTS = 'ANNOUNCEMENTS';
const ANNOUNCEMENTS_GETTING = 'ANNOUNCEMENTS_GET';
const ANNOUNCEMENTS_POSTING = 'ANNOUNCEMENTS_POSTING';
const ANNOUNCEMENT_CREATED = 'ANNOUNCEMENTS_CREATED';
const ANNOUNCEMENT_ONE_MODAL_OPEN = 'ANNOUNCEMENT_ONE_MODAL_OPEN';
const ANNOUNCEMENT_ALL_MODAL_OPEN = 'ANNOUNCEMENT_ALL_MODAL_OPEN';
const ANNOUNCEMENTS_STORE_RESET = 'ANNOUNCEMENTS_STORE_RESET';

/* Actions */

export const setAnnouncements = (announcements, number) => ({'type': ANNOUNCEMENTS, 'announcements': announcements, 'number': number});
export const setAnnouncementsFetching = (status) => ({'type': ANNOUNCEMENTS_GETTING, 'status': status});
export const setAnnouncementsPosting = (status) => ({'type': ANNOUNCEMENTS_POSTING, 'status': status});
export const setAnnouncementCreated = (announcement) => ({'type': ANNOUNCEMENT_CREATED, 'announcement': announcement});
export const setAnnouncementOneModalOpen = (status, announcement) => ({'type': ANNOUNCEMENT_ONE_MODAL_OPEN, 'status': status, 'announcement': announcement});
export const setAnnouncementAllModalOpen = (status) => ({'type': ANNOUNCEMENT_ALL_MODAL_OPEN, 'status': status});
export const setAnnouncementsStoreReset = () => ({'type': ANNOUNCEMENTS_STORE_RESET});

/* Initial State */

const initialState = Map({
    announcements: Map(),
    announcementsGetting: false,
    announcementsPosting: false,
    announcementsAllFetched: false,
    announcementsFetched: false,
    announcementAllModalOpen: false,
    announcementOneModalOpen: false,
    announcementCreated: Map(),
    announcementSelected: Map(),
});

/* Reducer */

const announcementsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ANNOUNCEMENTS:
            return state.merge({
                'announcements': fromJS(action.announcements),
                'announcementsFetched': true,
                'announcementsAllFetched': action.number === 'all'
            });
        case ANNOUNCEMENTS_GETTING:
            return state.merge({'announcementsGetting': action.status});
        case ANNOUNCEMENTS_POSTING:
            return state.merge({'announcementsPosting': action.status});
        case ANNOUNCEMENT_CREATED:
            return state.merge({
                'announcementCreated': fromJS(action.announcement),
            });
        case ANNOUNCEMENT_ONE_MODAL_OPEN:
            return state.merge({'announcementOneModalOpen': action.status, 'announcementSelected': action.announcement});
        case ANNOUNCEMENT_ALL_MODAL_OPEN:
            return state.merge({'announcementAllModalOpen': action.status});
        case ANNOUNCEMENTS_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default announcementsReducer;
