import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {Helmet} from "react-helmet";
import moment from 'moment/moment';

/* Components */
import {FontTitle30} from '../../common/components/fonts';
import {colors} from '../../common/components/colors';
import {images} from '../../common/components/images';
import {LogoLoading} from "../../common/components/loading";

/* Containers */
import ReschedulerFlow from './containers/flow';

/* Middleware */
import {tryGetSessionFromToken} from './middleware/reschedule';

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

const MessageCenter = styled.div`
    margin: 140px auto;
    text-align: center;
`;

const Background = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: ${colors.background100};
`;

const Container = styled.div`
    max-width: 768px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 60px auto 0px auto;
`;

class IFrameReschedule extends React.Component {
    state = {"isLoading": true, "sessionFound": false};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        let path = window.location.pathname;
        let pathAdj = path.charAt(path.length-1) === "/" ? path.slice(0, -1) : path;
        let pathSplit = pathAdj.split("/");
        if(pathSplit.length !== 4) {
            return this.setState({"sessionFound": false, "isLoading": false});
        } else {
            let token = pathSplit[pathSplit.length - 1];
            await this.props.tryGetSessionFromToken(token);
            return this.setState({
                "sessionFound": (this.props.rescheduleSession !== "error" && moment(this.props.rescheduleSession.get("start")).isAfter(moment())),
                "token": token,
                "isLoading": false
            });
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<Background><LoadingCenter><LogoLoading /></LoadingCenter></Background>)
        } else {
            if(this.state.sessionFound) {
                return(
                    <>
                        <Helmet>
                            <link rel={"icon"} type={"image/png"} href={images.favicon16px} sizes={"16x16"} />
                            <title>Reschedule&nbsp;|&nbsp;Willow</title>
                        </Helmet>
                        <Container>
                            <ReschedulerFlow
                                userId={this.props.rescheduleSession.get("remoteUserId")}
                                userFirstName={this.props.rescheduleSession.get("remoteUserFirstName")}
                                userLastName={this.props.rescheduleSession.get("remoteUserLastName")}
                                userPhotoUrl={this.props.rescheduleSession.get("remoteUserPhotoUrl")}
                                eventCustomMinutes={moment(this.props.rescheduleSession.get("end")).diff(moment(this.props.rescheduleSession.get("start")), "minutes")}
                                eventInitialTime={moment(this.props.rescheduleSession.get("start"))}
                                token={this.state.token}
                                purchaseHistoryId={this.props.rescheduleSession.get("purchaseHistoryId")}
                                useLocalTimeZone={true}
                                isWillowSession={this.props.rescheduleSession.get("isWillowSession")}
                            />
                        </Container>
                    </>
                )
            } else {
                return(<Background><MessageCenter><FontTitle30>No meeting found</FontTitle30></MessageCenter></Background>)
            }
        }
    }

}

const mapStateToProps = state => ({
    rescheduleSession: state.iframe.rescheduler.reschedule.get("rescheduleSession"),
});

const mapDispatchToProps = dispatch => ({
    tryGetSessionFromToken: (token) => dispatch(tryGetSessionFromToken(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IFrameReschedule);
