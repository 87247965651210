import React from 'react';
import moment from "moment/moment";
import styled from 'styled-components';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import ContentEditable from 'react-contenteditable';

import ReactQuill from 'react-quill';

import {Container, ContentBodySection, LoadingCenter, ContentTypeIcon, AuthorDateSection, IntroSection, TypeSection, TitleSection, ContentTitle, ImageArticle, VideoHotspot, VideoStartStopIcon, Flex, VideoContainer, Background} from "../components/details";
import {FontBody14, FontBody18, FontTitle34, FontHeader18} from '../../../common/components/fonts';
import {images} from '../../../common/components/images';
import {colors} from '../../../common/components/colors';
import {LogoLoading} from "../../../common/components/loading";
import { ButtonPrimary } from '../../../common/components/buttons';
import {CommonBackground, CommonModal} from '../../../common/components/modal';


import {setTaskCreate, setCoursesAddElement, setCourseCreate} from '../store/courses';
import FormsFlow from "../../../iframes/forms/containers/flow";
import UploadPhoto from "./uploadphoto";
import UploadVideo from './videoupload';
import { UploadOutlined } from '@ant-design/icons';

/* Utils */
import {disableBodyScrolling, reenableBodyScrolling} from "../../../common/utils/scrolling";
/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const AuthorLink = styled.div`
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    font-size: 14px;
    line-height: 18px;
    width: fit-content;
    text-decoration: underline;
`;

class CourseDisplay extends React.Component {
    state={
        title: "",
        author: "",
        imgURL: "",
        videoURL: "",
        description: "",
        form: [],
        isLoading: true,
        updatePaused: false,
        imageUpload: false,
        videoUpload: false,
        videoOpen: false
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        let name = ""
        if (this.props.course.get("externalAuthor").get("firstName")) {
            name = this.props.course.get("externalAuthor").get("firstName") + " " + this.props.course.get("externalAuthor").get("lastName")
        } else {
            name = this.props.course.get("externalAuthor").get("lastName")
        }
        this.setState({
            "title": this.props.course.get("title"),
            "author": name,
            "imgURL": this.props.course.get("imageUrl"),
            "description": this.props.course.get("description"),
            "form": this.props.course.get("pages").toJS(), 
            "isLoading": false})
        console.log(this.state.description);
        console.log(typeof this.state.description);

    };


    componentDidUpdate(prevProps) {
        if (prevProps.course !== this.props.course && !this.state.updatePaused) {
            this.setState({"isLoading": true})
            return this.init()
        }
    }

    handleChangeTitle = async (evt) => {
        this.setState({"title": evt.target.value});
    };

    handleChangeAuthor = async (evt) => {
        this.setState({"author": evt.target.value});
    };

    handleChangeDescription = async (evt) => {
        let str = evt.target.value;
        str = str.replace(/(<([^>]+)>)/ig, '');
        this.setState({"description": str});
    };

    openVideo = async () => {
        this.setState({"videoOpen": !this.state.videoOpen})
    };

    setTaskData = async (type) => {
        const task = {
            "title": this.state.title,
            "description": this.state.description,
            "imageUrl": this.state.imgURL,
            "pages": type === "video" ? 
                [{...this.props.course.get("pages").toJS()[0], 
                    "elements": [{id: 0, "type": "hidden", "value": this.state.videoURL}, 
                    ...this.props.course.get("pages").toJS()[0].elements.slice(1)]}]
                :
                this.props.course.get("pages").toJS(),
            "isActive": false,
            "externalAuthor": {
                "firstName": this.state.author,
                "lastName": "",
                "additionalInformation": {}
            },
            "id": this.props.course.get("id")
        }
        await this.props.setTaskCreate(task)
        const course = {
            ...task,
            "isReadingMaterial": true,
            "isVideo": this.props.type === 'video',
            "categoryIds": this.props.courseCreated.get("categoryIds").toJS()
        }
        await this.props.setCourseCreate(course)
    }

    onReturnForm = (form) => {
        return this.setState({"form": form})
    }

    autoSave = async (answers, isCompleted, lastPageCompleted) => {
        await this.setState({"updatePaused": true})
        await this.props.setCoursesAddElement(answers)
        this.setState({"updatePaused": false})
    };

    render() {
        if (this.state.isLoading) {
            return (<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return (
                <Container>
                    <div style={{position: 'relative'}}>
                        {this.props.type === 'article'
                        ?
                        <>
                            <ImageArticle src={(this.state.imgURL !== null && this.state.imgURL !== "") ? this.state.imgURL : images.grayBackgroundImage} alt={"Cover Photo"}/>
                            <div onClick={() => {return this.setState({"imageUpload": true})}} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', cursor: 'pointer', zIndex: 10}}><ButtonPrimary canSubmit={false} label={<>Cover <UploadOutlined /></>} /></div>
                        </>
                        :
                        <>
                            <ImageArticle src={(this.state.imgURL !== null && this.state.imgURL !== "") ? this.state.imgURL : images.grayBackgroundImage} alt={"Cover Photo"}/>
                            <div onClick={() => {return this.setState({"imageUpload": true})}} style={{position: 'absolute', top: '15%', left: '50%', transform: 'translate(-50%, -50%)', cursor: 'pointer', zIndex: 10}}><ButtonPrimary canSubmit={false} label={<>Cover <UploadOutlined /></>} /></div>
                            <div onClick={() => {return this.setState({"videoUpload": true})}} style={{position: 'absolute', top: '35%', left: '50%', transform: 'translate(-50%, -50%)', cursor: 'pointer', zIndex: 10}}><ButtonPrimary canSubmit={false} label={<>Video <UploadOutlined /></>} /></div>
                            <VideoHotspot onClick={this.openVideo}>
                                <VideoStartStopIcon src={images.contentVideoStartStopIcon} alt={""} />
                            </VideoHotspot>
                        </>
                        }
                    </div>       
                    <IntroSection>
                        <TitleSection>
                            <FontTitle34>
                                <ContentTitle>
                                    <ContentEditable 
                                        className="content-editable" 
                                        html={this.state.title} 
                                        onChange={this.handleChangeTitle} 
                                        onBlur={() => {return this.setTaskData("any")}}
                                        placeholder={"* Click to add title"}
                                    />
                                </ContentTitle>
                            </FontTitle34>
                        </TitleSection>
                        <TypeSection>
                            <ContentTypeIcon src={images.contentArticleIcon} alt={""} />
                            <AuthorDateSection>
                                <FontHeader18><b><ContentTitle>
                                    <ContentEditable 
                                        className="content-editable" 
                                        html={this.state.author} 
                                        onChange={this.handleChangeAuthor} 
                                        onBlur={() => {return this.setTaskData("any")}}
                                        placeholder={"* Author name"}
                                    />
                                </ContentTitle></b></FontHeader18>
                                <FontBody14><i>{moment().format("MMMM D, YYYY")}</i></FontBody14>
                                <AuthorLink>Schedule an appointment</AuthorLink>
                            </AuthorDateSection>
                        </TypeSection>
                        <FontBody18><ContentTitle>
                            <ContentEditable 
                                className="content-editable" 
                                html={this.state.description} 
                                onChange={this.handleChangeDescription} 
                                onBlur={() => {return this.setTaskData("any")}}
                                placeholder={"* Description"}
                            />
                        </ContentTitle></FontBody18>
                    </IntroSection>
                    <ContentBodySection>
                        <FormsFlow
                            form={this.state.form}
                            skipAuth={true}
                            onCompleteFormMethod={"return"}
                            onReturnForm={this.onReturnForm}
                            formHasOnePage={true}
                            editDisabled={false}
                            autoSaveOn={true}
                            autoSaveTry={this.autoSave}
                            allowFormToChange={true}
                        />
                    </ContentBodySection>
                    {this.state.imageUpload &&
                        <>
                            <CommonBackground onClick={this.props.close}/>
                            <CommonModal style={{width: "fit-content"}}>
                                <UploadPhoto onClose={(img) => {this.setState({"imageUpload": false, "imgURL": img}); return this.setTaskData("any")}} url={this.state.imgURL}/>
                            </CommonModal>
                        </>
                    }
                    {this.state.videoUpload &&
                        <>
                            <CommonBackground onClick={this.props.close}/>
                            <CommonModal style={{width: "fit-content"}}>
                                <UploadVideo onClose={(img) => {this.setState({"videoUpload": false, "videoURL": img}); return this.setTaskData("video")}} url={this.state.videoURL}/>
                            </CommonModal>
                        </>
                    }
                    {this.state.videoOpen &&
                        <Flex>
                            <VideoContainer
                                src={this.state.videoURL}
                                controls={true}
                            />
                            <Background onClick={this.openVideo} />
                        </Flex>
                    }
                </Container>
            )
        }
    }
}

const mapStateToProps = state => ({
    course: state.enterprise.curriculum.curriculum.get("taskCreate"),
    courseCreated: state.enterprise.curriculum.curriculum.get("courseCreate"),
});

const mapDispatchToProps = dispatch => ({
    setTaskCreate: (course) => dispatch(setTaskCreate(course)),
    setCoursesAddElement: (element) => dispatch(setCoursesAddElement(element)),
    setCourseCreate: (course) => dispatch(setCourseCreate(course))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CourseDisplay))