import { combineReducers } from 'redux';

/* Common */
import rescheduleReducer from "./store/reschedule";

const schedulerReducer = combineReducers({
    reschedule: rescheduleReducer,
});

export default schedulerReducer;
