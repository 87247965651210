import { combineReducers } from 'redux';

/* Common */
import checkoutReducer from './store/checkout';

const checkoutReducers = combineReducers({
    checkout: checkoutReducer,
});

export default checkoutReducers;
