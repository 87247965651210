import React from 'react';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/* Components */
import { LogoLoading } from "../../../common/components/loading";
import {Message} from "../../../common/components/messages";
import {Container, Extra54px, PageTitle, Pill, PillBar, BookButton} from "../components/myprograms";
import {FontBody16} from "../../../common/components/fonts";
import {ButtonPrimary} from "../../../common/components/buttons";

/* Middleware */
import { tryGetMySubscribedGroupPrograms, tryGetMySubscribedGroupProgram } from '../middleware/programs'

/* Containers */
import {ProgramsList} from "./myprogramslist";

class MyGroupPrograms extends React.Component {
    state = {
        "isLoading": true,
        "groupPrograms": [],
        "programs": {
            "upcoming": [],
            "past": []
        },
        "currentTab": null, /* upcoming, past */
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.props.tryGetMySubscribedGroupPrograms();
        this.setState({
            'groupPrograms': this.props.myGroupPrograms.toJS(),
            'programs': {
                "upcoming": await this.filterPrograms("upcoming"),
                "past": await this.filterPrograms("past")
            },
            "currentTab": 'upcoming',
            'isLoading': false
        })
    };

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.location.pathname !== this.props.location.pathname) {
            if(this.props.location.pathname === "/my-programs") {
                return this.props.history.goBack()
            } else {
                let path = this.props.location.pathname.split("/");
                let tab = path[(path.length -1)];
                return this.setState({"currentTab": tab})
            }
        }
    }

    filterPrograms = async (tab) => {
        const programsAll = this.props.myGroupPrograms.toJS();
        let lecturesAll = await Promise.all(programsAll.map(p => {
            return Promise.resolve(Promise.all(p.groupProgram.lectures.map(l => {
                return Promise.resolve({
                    ...l,
                    ...l.webinar,
                    "author": p.groupProgram.author,
                    "programTitle": p.groupProgram.title
                })
            })));
        }));
        let filtered = lecturesAll.flat().filter(a => {
            if(tab === "upcoming") {
                return(moment().isBefore(moment(a.flashSaleStartDateTime)))
            } else {
                return(moment().isAfter(moment(a.flashSaleStartDateTime)))
            }
        });
        return filtered.sort((a, b) => {return(new Date(a.flashSaleStartDateTime) - new Date(b.flashSaleStartDateTime))});
    };

    changeTab = async (tabToSwitchTo) => {
        await this.props.history.push("/my-programs/"+tabToSwitchTo);
    };

    joinGroupProgram = async () => {
        this.props.history.push("/programs")
    };

    render() {
        if (this.state.isLoading) {
            return (<LogoLoading />)
        } else if (this.state.groupPrograms.size === 0) {
            return(<Message text={"No programs found"} />)
        } else {
            return (
                <>
                    <Container id={this.state.containerId}>
                        <Extra54px />
                        <PageTitle>{'MY PROGRAMS'}</PageTitle>
                        <PillBar>
                            <Pill active={this.state.currentTab === 'upcoming'} onClick={() => {this.changeTab('upcoming');}}>
                                <FontBody16>{'Upcoming'}</FontBody16>
                            </Pill>
                            <Pill active={this.state.currentTab === 'past'} onClick={() => {this.changeTab('past');}}>
                                <FontBody16>{'Past'}</FontBody16>
                            </Pill>
                        </PillBar>
                        <ProgramsList
                            programs={this.state.currentTab === 'upcoming' ? this.state.programs['upcoming'] : this.state.programs['past']}
                        />
                        <BookButton onClick={this.joinGroupProgram}>
                            <ButtonPrimary canSubmit={false} label={'Join a program'} />
                        </BookButton>
                    </Container>
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    myGroupPrograms: state.consumer.programs.groupPrograms.get("mySubscribedGroupPrograms")
});

const mapDispatchToProps = dispatch => ({
    tryGetMySubscribedGroupPrograms: () => dispatch(tryGetMySubscribedGroupPrograms()),
    tryGetMySubscribedGroupProgram: (subscriptionId) => dispatch(tryGetMySubscribedGroupProgram(subscriptionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyGroupPrograms));