import React from "react";

/* Containers */
import NavPrimaryTopSupport from "./primarytopsupport";
import NavPrimaryTop from "./primarytop";
import NavPrimaryMobile from "./primarymobile";
import NavPrimarySide from "./primaryside";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const NavPrimaryAll = () => (
    <>
        <NavPrimaryTopSupport />
        <NavPrimaryTop data={env.ENTERPRISE.NAV} />
        <NavPrimaryMobile data={env.ENTERPRISE.NAV} />
        <NavPrimarySide data={env.ENTERPRISE.NAV} />
    </>
);

export default NavPrimaryAll;