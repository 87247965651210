export const devEnterpriseAnnouncements = {
    "announcements": [
        {
            "id": 3,
            "wmc": {
                "id":100,
                "companyName":'Company A'
            },
            "title": "Update your profile",
            "body": "Please update your profile by 6/20",
            "created": "2021-06-12T15:13:44.274Z",
            "sender": {
                "coachId": 1,
                "fullName": "Coach Jason"
            }
        },
        {
            "id": 4,
            "wmc": {
                "id": 110,
                "companyName":'Willow'
             },
            "title": "New pilot",
            "body": "<p>Schedule with Gabby Baker to learn more</p>",
            "created": "2021-07-11T15:13:44.274Z",
            "sender": {
                "coachId": 2,
                "fullName": "Lacy Garcia"
            }
        },
    ]
};