import {images} from '../../../common/components/images';
import {colors} from '../../../common/components/colors';
import {v4 as uuid4} from "uuid";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const devMatchingFormArticle = [
    {
        "pageNum": 0,
        "header": {
            "title": "",
        },
        "actions": {
            "backShowOnTop": false,
            "pageNumVisible": false,
            "backLabel": "",
            "nextLabel": "",
            "canContinueAutomatically": false,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "body",
                "text": "This MOOC identifies and explores a number of challenges to the finance professional arising from the ever changing business environment. Increasingly, financial decisions must take account of strategic consequences. The first module, Exploring Strategic Position, Choice and Action helps in doing that – setting a strategic context for planning for, monitoring and controlling projects and activities. An on-going set of challenges is found in the evolution of International Reporting Standards. This MOOC will be right for you if you are interested in engaging with some of the challenges facing finance professionals, even if you are not one yourself. If you are thinking about returning to study this will provide a taster of academic endeavours through reading articles, critiquing ideas, and blending theory with reality.",
                "style": {
                    "textAlign": "left",
                },
            }
        ]
    }
];

const devMatchingFormVideo = [
    {
        "pageNum": 0,
        "header": {
            "title": "",
        },
        "actions": {
            "backShowOnTop": false,
            "pageNumVisible": false,
            "backLabel": "",
            "nextLabel": "",
            "canContinueAutomatically": false,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "hidden",
                "value": 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4'
            },
            {
                "id": uuid4(),
                "type": "subtitle",
                "text": "Brief description",
                "style": {
                    "textAlign": "left",
                    "color": colors.secondary100
                },
            },
            {
                "id": uuid4(),
                "type": "body",
                "text": "Welcome to the Divorce Journey. This program is geared towards helping you understand the divorce process, having clear expectations and making sure that you’re setting yourself and your family up for success post divorce.",
                "style": {
                    "textAlign": "left",
                },
            },
            {
                "id": uuid4(),
                "type": "spacer",
            },
            {
                "id": uuid4(),
                "type": "subtitle",
                "text": "Transcript",
                "style": {
                    "textAlign": "left",
                    "color": colors.secondary100
                },
            },
            {
                "id": uuid4(),
                "type": "body",
                "text": "This MOOC identifies and explores a number of challenges to the finance professional arising from the ever changing business environment. Increasingly, financial decisions must take account of strategic consequences. The first module, Exploring Strategic Position, Choice and Action helps in doing that – setting a strategic context for planning for, monitoring and controlling projects and activities. An on-going set of challenges is found in the evolution of International Reporting Standards. This MOOC will be right for you if you are interested in engaging with some of the challenges facing finance professionals, even if you are not one yourself. If you are thinking about returning to study this will provide a taster of academic endeavours through reading articles, critiquing ideas, and blending theory with reality.",
                "style": {
                    "textAlign": "left",
                },
            }
        ]
    }
];

const devMatchingForm = [
    {
        "pageNum": 0,
        "header": {
            "title": "Welcome to your first lesson",
        },
        "actions": {
            "backShowOnTop": true,
            "pageNumVisible": false,
            "backLabel": "",
            "nextLabel": "Let's Begin!",
            "canContinueAutomatically": true,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "title",
                "text": "Welcome to your first week!",
                "style": {
                    "textAlign": "left",
                    "color": colors.secondary100
                },
            },
            {
                "id": uuid4(),
                "type": "image",
                "imageUrl": '',
                "style": {
                    "textAlign": "center",
                    "objectFit": "contain"
                }
            },
            {
                "id": uuid4(),
                "type": "body",
                "text": "<p>This MOOC identifies and explores a number of challenges to <a href='google.com'>test</a> the finance professional arising from the ever changing business environment. Increasingly, financial decisions must take account of strategic consequences. The first module, Exploring Strategic Position, Choice and Action helps in doing that – setting a strategic context for planning for, monitoring and controlling projects and activities. An on-going set of challenges is found in the evolution of International Reporting Standards. This MOOC will be right for you if you are interested in engaging with some of the challenges facing finance professionals, even if you are not one yourself. If you are thinking about returning to study this will provide a taster of academic endeavours through reading articles, critiquing ideas, and blending theory with reality.</p>",
                "style": {
                    "textAlign": "left",
                },
            },
            {
                "id": uuid4(),
                "type": "body",
                "text": "Let's begin!",
                "style": {
                    "textAlign": "center",
                },
            },

        ]
    },
    {
        "pageNum": 1,
        "header": {
            "title": "Video #1",
        },
        "actions": {
            "backShowOnTop": true,
            "pageNumVisible": false,
            "backLabel": "",
            "nextLabel": "Continue",
            "canContinueAutomatically": true,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "video",
                "videoUrl": 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4',
                "videoControls": true,
                "style": {
                    "margin": "0px auto",
                }
            },
        ]
    },
    {
        "pageNum": 2,
        "header": {
            "title": "Types of Income",
        },
        "actions": {
            "backShowOnTop": true,
            "pageNumVisible": false,
            "backLabel": "",
            "nextLabel": "Continue",
            "canContinueAutomatically": true,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "subtitle",
                "text": "1. Earned Income",
                "style": {
                    "textAlign": "center",
                    "color": colors.secondary100
                },
            },
            {
                "id": uuid4(),
                "type": "body",
                "text": "The first type of income is the most common: earned income. As I mentioned earlier, this is income that you receive in exchange for doing work for someone else. When you first start out on your financial journey, this is the only type of income that you’ll be able to earn. You will have to trade your time for money.\ Examples of earned income include:\ Working as an hourly employee at a company.\ Working as a salaried employee at a company.\ Working as an hourly consultant for clients/companies.\ Working as an hourly or fixed-rate freelancer for clients/companies.",
                "style": {
                    "textAlign": "left",
                }
            },
            {
                "id": uuid4(),
                "type": "subtitle",
                "text": "2. Capital Gains Income",
                "style": {
                    "textAlign": "center",
                    "color": colors.secondary100
                },
            },
            {
                "id": uuid4(),
                "type": "body",
                "text": "The next type of income that you can earn is called capital gains income. This is income you receive from selling an investment for more than the amount you paid for it (this is also sometimes called “portfolio income”). The formula to calculate capital gains is simple:\ Capital gains  = Selling price – Purchase price",
                "style": {
                    "textAlign": "left",
                }
            },
            {
                "id": uuid4(),
                "type": "subtitle",
                "text": "3. Passive Income",
                "style": {
                    "textAlign": "center",
                    "color": colors.secondary100
                },
            },
            {
                "id": uuid4(),
                "type": "body",
                "text": "The final type of income that you can earn is called passive income. This is income that you earn from owning assets that requires no work on your part.",
                "style": {
                    "textAlign": "left",
                }
            },
        ]
    },
    {
        "pageNum": 3,
        "header": {
            "title": "Assessment #1",
        },
        "actions": {
            "backShowOnTop": true,
            "pageNumVisible": false,
            "backLabel": "",
            "nextLabel": "Submit",
            "canContinueAutomatically": false,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "checkBox",
                "subtitle": "What are the three types of income?",
                "disclosure": "(select 3)",
                "warning": "Required",
                "isRequired": true,
                "maxCheckable": 3,
                "isValid": false,
                "options": [
                    {"id": 1, "active": true, "label": "Passive"},
                    {"id": 2, "active": true, "label": "Earned"},
                    {"id": 3, "active": false, "label": "Active"},
                    {"id": 4, "active": false, "label": "Job"},
                    {"id": 5, "active": false, "label": "Capital Gains"},
                ],
                "style": {
                    "subtitle": {},
                    "disclosure": {},
                    "warning": {},
                    "options": {},
                    "optionLabel": {},
                    "optionIcon": {}
                }
            },
            {
                "id": uuid4(),
                "type": "radioBox",
                "subtitle": "What is the most common type of income?",
                "disclosure": "",
                "warning": "Required",
                "isRequired": true,
                "isValid": false,
                "options": [
                    {"id": 1, "active": false, "label": "Passive"},
                    {"id": 2, "active": false, "label": "Earned"},
                    {"id": 3, "active": false, "label": "Active"},
                    {"id": 4, "active": true, "label": "Job"},
                    {"id": 5, "active": false, "label": "Capital Gains"},
                ],
                "style": {
                    "subtitle": {},
                    "disclosure": {},
                    "warning": {},
                    "options": {},
                    "optionLabel": {},
                    "optionIcon": {}
                }
            },
            {
                "id": uuid4(),
                "type": "textArea",
                "subtitle": "What was your biggest takewaway?",
                "placeholder": "Answer here",
                "isRequired": true,
                "isValid": false,
                "text": "",
                "minLength": 0,
                "minRows": 3,
                "style": {
                    "subtitle": {},
                    "textArea": {},
                    "warning": {},
                }
            },
        ]
    }
];

const devTasks = [
    {
        "taskAssignmentId": 12,
        "task": {
            "id": 42,
            "title": 'Week 1',
            "description": "This week you will learn this week",
            "imageUrl": "image url",
            "pages": devMatchingForm,
            "isActive": true
        },
        "isCompleted": false,
        "lastPageCompleted": 2,
        "completedOn": '2021-03-08T05:00:00+00:00',
        "answers": devMatchingForm
    },
    {
        "taskAssignmentId": 13,
        "task": {
            "id": 43,
            "title": 'Week 2',
            "description": "This week you will learn this week",
            "imageUrl": "image url",
            "pages": devMatchingForm,
            "isActive": true
        },
        "isCompleted": false,
        "lastPageCompleted": 0,
        "completedOn": '2021-03-08T05:00:00+00:00',
        "answers": devMatchingForm
    },
    {
        "taskAssignmentId": 14,
        "task": {
            "id": 45,
            "title": 'Week 3',
            "description": "This week you will learn this week",
            "imageUrl": "image url",
            "pages": devMatchingFormVideo,
            "isActive": true
        },
        "isCompleted": false,
        "lastPageCompleted": null,
        "completedOn": '2021-03-08T05:00:00+00:00',
        "answers": devMatchingFormVideo
    }
];

export const devConsumerCourseOneUnassigned = {
    "course": {
        "id":1,
        "title":"Get support through your divorce",
        "description":"Welcome to the Divorce Journey. This program is geared towards helping you understand the divorce process, having clear expectations and making sure that you\u2019re setting yourself and your family up for success post divorce.",
        "imageUrl":"https://res.cloudinary.com/blz-lilyriche/image/upload/v1615072582/shqog59xptiewlhclinc.jpg",
        "isActive":true,
        "isReadingMaterial":false,
        "isGoal":false,
        "isVideo":false,
        "createdOn": "2021-03-08T05:00:00+00:00",
        "externalAuthor": {
            "additionalInformation": {},
            "firstName": "Willow",
            "lastName": "Editorial Team"
        },
        "isPracticeSelfServe":true,
        "ce_credits":null,
        "documentUrl":"https://docs.trustwillow.com/Integrating+Coaching+Into+Your+Practice+CE+Seminar+Overview.pdf",
        "documentTitle":"Training Summary PDF",
        "programIdCFP":"",
        "programIdCIMA":""
    },
    "tasks":devTasks,
    "coach":{
        "coachId":845,
        "wmcId":null,
        "first":"Lacy",
        "last":"Garcia",
        "phone":6172793685,
        "email":"lacy@trustwillow.com",
        "photo":"https://res.cloudinary.com/blz-lilyriche/image/upload/v1602605069/chyjbqe6ql6foprctnww.png",
        "defaultCallLengthInMinutes":30,
        "storeActive":false,
        "storeHandle":"lacy",
        "publicHandle":"lgarcia",
        "schedulerHandle":"lacy",
        "bio":"<p>Lacy is the Founder &amp; CEO of Willow, a financial technology and coaching platform focused on helping financial advisors grow, retain and better serve female clients.</p><p><br></p><p>She is daughter of a Cuban refugee, which shaped her money mindset early on and eventually led her to pursue a career in financial services. As a divorced, single mother, she has experienced the pain points from the perspective of both a financial advisor and a female client. </p><p><br></p><p>She brings deep experience in wealth management to Willow having served as Vice President, Head of Marketing and a Private Banker at Deltec Bank &amp; Trust Limited. Prior to Deltec, she was a Vice President at Weber Shandwick where she advised a global financial institution on its local market reputation work post financial crisis, helped launch the Center for Women and Business at Bentley University and supported the Massachusetts Conference for Women. Prior to that, she was the Director of Marketing &amp; Communications for WHERE (Acquired by PayPal). Lacy also led Babson College\u2019s Centennial Celebrations as the Managing Director of the Centennial Office and member of the President\u2019s Cabinet.</p><p><br></p><p>Lacy holds a BS in Political Science from Davidson College, an MS in Education, magna cum laude from Hunter College, and an MBA from Simmons School of Management, where she received a merit scholarship. Lacy was named an Emerging Leader by The Boston Foundation (2008) and is actively involved with several nonprofits that focus on women\u2019s empowerment.</p>",
        "benefitsProviderId":null,
        "expertCategories":[
            {
                "categoryId":6,
                "categoryName":"Willow Relationship Manager"
            }
        ]
    },
    "categories":[
        {
            "id":2,
            "title":"Divorce"
        }
    ],
    "id": 1, //course assignment,
    "assignedToUser":
        {
            "id": 12,
            "firstName": "Jason",
            "lastName": "Aronson"
        },
    "assignedByUser":
        {
            "id": 13,
            "firstName": "Lacy",
            "lastName": "Garcia"
        },
    "isCompleted": false,
    "lastUpdatedOn": '2021-03-08T05:00:00+00:00',
    "completedOn": null,
};

// export const devConsumerCourseOne = {
//     "id": 1, //course assignment,
//     "course": {
//         "id": 1,
//         "title": "Financial Planning for Young Adults",
//         "description": "Financial Planning for Young Adults (FPYA), developed in partnership with the CFP Board, is designed to provide an introduction to basic financial planning concepts for young adults. The FPYA course is organized across eight separate modules within a 4-week window. Topics covered include financial goal setting, saving and investing, budgeting, financial risk, borrowing and credit. Because financial planning is such a personal topic, you will be encouraged to define your own financial goals and objectives while we discuss concepts and provide tools which can be applied in helping you reach those goals.",
//         "imageUrl": images.logoIcon,
//         "isActive": true,
//         "isReadingMaterial":false,
//         "isGoal":false,
//         "isVideo":false,
//          "createdOn": "2021-03-08T05:00:00+00:00"
//     },
//     "assignedToUser":
//         {
//             "id": 12,
//             "firstName": "Jason",
//             "lastName": "Aronson"
//         },
//     "assignedByUser":
//         {
//             "id": 13,
//             "firstName": "Lacy",
//             "lastName": "Garcia"
//         },
//     "isCompleted": false,
//     "lastUpdatedOn": '2021-03-08T05:00:00+00:00',
//     "completedOn": null,
//     "tasks": devTasks,
//     "coach":{
//         "coachId":845,
//         "wmcId":null,
//         "first":"Lacy",
//         "last":"Garcia",
//         "phone":6172793685,
//         "email":"lacy@trustwillow.com",
//         "photo":"https://res.cloudinary.com/blz-lilyriche/image/upload/v1602605069/chyjbqe6ql6foprctnww.png",
//         "defaultCallLengthInMinutes":30,
//         "storeActive":false,
//         "storeHandle":"lacy",
//         "publicHandle":"lgarcia",
//         "schedulerHandle":"lacy",
//         "bio":"<p>Lacy is the Founder &amp; CEO of Willow, a financial technology and coaching platform focused on helping financial advisors grow, retain and better serve female clients.</p><p><br></p><p>She is daughter of a Cuban refugee, which shaped her money mindset early on and eventually led her to pursue a career in financial services. As a divorced, single mother, she has experienced the pain points from the perspective of both a financial advisor and a female client. </p><p><br></p><p>She brings deep experience in wealth management to Willow having served as Vice President, Head of Marketing and a Private Banker at Deltec Bank &amp; Trust Limited. Prior to Deltec, she was a Vice President at Weber Shandwick where she advised a global financial institution on its local market reputation work post financial crisis, helped launch the Center for Women and Business at Bentley University and supported the Massachusetts Conference for Women. Prior to that, she was the Director of Marketing &amp; Communications for WHERE (Acquired by PayPal). Lacy also led Babson College\u2019s Centennial Celebrations as the Managing Director of the Centennial Office and member of the President\u2019s Cabinet.</p><p><br></p><p>Lacy holds a BS in Political Science from Davidson College, an MS in Education, magna cum laude from Hunter College, and an MBA from Simmons School of Management, where she received a merit scholarship. Lacy was named an Emerging Leader by The Boston Foundation (2008) and is actively involved with several nonprofits that focus on women\u2019s empowerment.</p>",
//         "benefitsProviderId":null,
//         "expertCategories":[
//             {
//                 "categoryId":6,
//                 "categoryName":"Willow Relationship Manager"
//             }
//         ]
//     }
// };

export const devArticleWithCoach = {
    "id": 78, 
    "courseAssignmentId": 78, 
    "course": {
        "id": 145, 
        "createdOn": "2022-01-03T19:23:44.307520+00:00", 
        "title": "How to Combine Finances", 
        "description": "This article discusses combining finances when merging your life with someone else.", 
        "externalAuthor": {
            "lastName": "Bishop", 
            "firstName": "Allison", 
            "additionalInformation": {}
        }, 
        "imageUrl": "https://docs.trustwillow.com/everton-vila-AsahNlC0VhQ-unsplash-scaled.jpg",
        "isActive": true, 
        "isReadingMaterial": true, 
        "isGoal": false, 
        "isVideo": false
    }, 
    "isCompleted": false, 
    "lastUpdatedOn": "2022-02-09T20:10:47.290Z", 
    "completedOn": null, 
    "coach": {
        "coachId": 37, 
        "wmcId": null, 
        "first": "Allison", 
        "last": "Bishop", 
        "wmcEmployeeId": null, 
        "phone": 2078058400, 
        "email": "allison@allisonbishop.com", 
        "photo": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1617399212/tdps392zkczs5lgkgltv.png", 
        "defaultCallLengthInMinutes": 45, 
        "storeActive": true, 
        "storeHandle": "abishop", 
        "publicHandle": "abishop", 
        "schedulerHandle": "abishop", 
        "bio": "N/A", 
        "benefitsProviderId": null, 
        "expertCategories": [
            {"categoryId": 1, "categoryName": "Willow Financial Coach"}, 
            {"categoryId": 14, "categoryName": "Spending plans"}, 
            {"categoryId": 15, "categoryName": "Debt & student loans"}, 
            {"categoryId": 16, "categoryName": "Savings"}, 
            {"categoryId": 17, "categoryName": "Home buying"}, 
            {"categoryId": 18, "categoryName": "Charity & gifting"}, 
            {"categoryId": 19, "categoryName": "Taxes"}, 
            {"categoryId": 20, "categoryName": "Insurance"}, 
            {"categoryId": 21, "categoryName": "Investing & 401(k)"}, 
            {"categoryId": 22, "categoryName": "Marital concerns"}, 
            {"categoryId": 23, "categoryName": "Getting divorced"}, 
            {"categoryId": 33, "categoryName": "Childcare"}, 
            {"categoryId": 34, "categoryName": "Education"}, 
            {"categoryId": 41, "categoryName": "Retirement"},
            {"categoryId": 47, "categoryName": "Post-divorce"}, 
            {"categoryId": 48, "categoryName": "Co-parenting"}, 
            {"categoryId": 49, "categoryName": "Considering divorce"}, 
            {"categoryId": 50, "categoryName": "Combining finances"}, 
            {"categoryId": 51, "categoryName": "Getting married"}
        ]
    }, 
    "assignedByUser": {
        "id": 3932, 
        "firstName": "Emily", 
        "lastName": "Smith"
    }, 
    "tasks": [{
        "taskAssignmentId": 111, 
        "task": {
            "id": 157, 
            "title": "A: How to combine finances", 
            "description": "A: How to combine finances", 
            "imageUrl": "", 
            "isActive": true, 
            "pages": [{
                "header": {"title": "How to Combine Finances"}, 
                "actions": {
                    "backLabel": "", 
                    "nextLabel": "", 
                    "backShowOnTop": false, 
                    "pageNumVisible": false, 
                    "canContinueAutomatically": false
                }, 
                "pageNum": 0, 
                "elements": [{
                    "id": 0, 
                    "text": "Article text here :)", 
                    "type": "body", 
                    "style": {"textAlign": "left"}
                }]
            }], 
            "lastPageCompleted": null}
        }]
    }

export const devArticleWithoutCoach = {
    "course": {
        "id": 221, 
        "createdOn": "2022-02-24T21:02:01.753072+00:00", 
        "title": "How To Tell Your Partner You Want a Prenup", 
        "description": "This article discusses some ways to discuss wanting a prenuptial agreement with your partner.", 
        "externalAuthor": {
            "lastName": "Willow", 
            "firstName": "Willow", 
            "additionalInformation": {}
        }, 
        "imageUrl": "https://docs.trustwillow.com/partner.png", 
        "isActive": true, 
        "isReadingMaterial": true, 
        "isGoal": false, 
        "isVideo": false
    }, 
    "tasks": [{
        "task": {
            "id": 235, 
            "title": "A: How to tell your partner you want a prenup", 
            "description": "A: How to tell your partner you want a prenup", 
            "imageUrl": "", 
            "isActive": true, 
            "pages": [{
                "header": {
                    "title": "How to tell your partner you want a prenup"
                }, 
                "actions": {
                    "backLabel": "", 
                    "nextLabel": "", 
                    "backShowOnTop": false, 
                    "pageNumVisible": false, 
                    "canContinueAutomatically": false
                }, 
                "pageNum": 0, 
                "elements": [{
                    "id": 0, 
                    "text": "Article Text Here :)", 
                    "type": "body", 
                    "style": {"textAlign": "left"}
                }]
            }]
        }
    }], 
    "coach": {
        "coachId": 845, 
        "wmcId": null, 
        "first": "Lacy", 
        "last": "Garcia", 
        "wmcEmployeeId": "", 
        "phone": 6172793685, 
        "email": "lacy@trustwillow.com", 
        "photo": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1602605069/chyjbqe6ql6foprctnww.png", 
        "defaultCallLengthInMinutes": 30, 
        "storeActive": false, 
        "storeHandle": "lacy", 
        "publicHandle": "lgarcia", 
        "schedulerHandle": "lacy", 
        "bio": "<p>Lacy is the Founder &amp; CEO of Willow, a financial technology and coaching platform focused on helping financial advisors grow, retain and better serve female clients.</p><p><br></p><p>She is daughter of a Cuban refugee, which shaped her money mindset early on and eventually led her to pursue a career in financial services. As a divorced, single mother, she has experienced the pain points from the perspective of both a financial advisor and a female client. </p><p><br></p><p>She brings deep experience in wealth management to Willow having served as Vice President, Head of Marketing and a Private Banker at Deltec Bank &amp; Trust Limited. Prior to Deltec, she was a Vice President at Weber Shandwick where she advised a global financial institution on its local market reputation work post financial crisis, helped launch the Center for Women and Business at Bentley University and supported the Massachusetts Conference for Women. Prior to that, she was the Director of Marketing &amp; Communications for WHERE (Acquired by PayPal). Lacy also led Babson College\u2019s Centennial Celebrations as the Managing Director of the Centennial Office and member of the President\u2019s Cabinet.</p><p><br></p><p>Lacy holds a BS in Political Science from Davidson College, an MS in Education, magna cum laude from Hunter College, and an MBA from Simmons School of Management, where she received a merit scholarship. Lacy was named an Emerging Leader by The Boston Foundation (2008) and is actively involved with several nonprofits that focus on women\u2019s empowerment.</p>", 
        "benefitsProviderId": null, 
        "expertCategories": [{
            "categoryId": 6, 
            "categoryName": "Willow Relationship Manager"}
        ]}, 
    "categories": [{
        "id": 15, 
        "title": "Getting married"
    }]
}

export const devCompleteTask =
    {
        "assignedTaskId": 12,
        "answers": {

        },
        "isCompleted": true
    }

