import React from "react";
import styled from "styled-components";

/* Components */
import {FontBody24} from "../../../common/components/fonts";
import {images} from "../../../common/components/images";

const Back = styled(FontBody24)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-self: flex-start;
    align-items: flex-start;
    cursor: pointer;
`
const ChevronLeftRight = styled.img`
    padding: 11px 35px 0 0;
    vertical-align: top;
    display: block;
    object-fit: cover;
    height: 15px;
`;

const BackBtn = ({onBack = () => {}}) => (
    <Back onClick={onBack}><ChevronLeftRight src={images.chevronLeft} alt={""} />Back</Back>
)

export default BackBtn;