import 'moment-timezone/builds/moment-timezone-with-data-2012-2022';
import moment from 'moment-timezone';
import {axiosReq} from "../../../common/utils/axios";

/* Components */
import {colors} from '../../../common/components/colors';

/* Middleware */
import { createProspectFromContact, getOneProspectByConsumerId, tryGetOneProspect } from "../../middleware/prospects";
import { getOneClientByConsumerId, tryGetOneClient } from "../../clients/middleware/clients";
import { getOneContactByConsumerId } from '../../middleware/contacts';
import { adjForTZ } from '../../../common/utils/timezone';

/* Store */
import {setSessionsAddingGuestToSession, setSessionsDelete, setSessionsAddGuestToSession, setSessions, setSessionsReading, setSessionsDeleting, setOneSessionReading, setOneSession, setSessionsAddNewSession, setSessionCreating} from "../store/sessions";
import {setClientsActivityUpdateRecord, setClientsActivityUpdateRecordAddMeetingGuest, setClientsActivityProspectIdLoaded} from "../../store/clientsactivity";
import {setAllProspectsAddNextSession, setAllProspectsSetNextSession} from "../../store/prospects";
import {setAllClientsAddNextSession, setAllClientsSetNextSession} from "../../clients/store/clients";

/* Static */
import {
    devEnterpriseConferenceGuest,
    devEnterpriseScheduleSessionsData,
    devEnterpriseScheduleSessionsDeleteData,
    devEnterpriseScheduleOneSessionData,
    devEnterpriseCreatedSessionData,
    devClientPastUpcomingConferenecs
} from '../static/devdata';

export const listGuestNames = (guests, startComma) => {
    if(guests.length > 0) {
        return((startComma ? ", " : "") + guests.map(g => {
            if(g.referralType === "willow") {
                return(g.firstName+" "+g.lastName.charAt(0).toUpperCase()+".");
            } else {
                return(g.firstName+" "+g.lastName);
            }
        }).join(", "));
    } else {
        return("");
    }
};

const hideNames = (details, isWMC) => {
    const title = details.title;
    const guests = details.guests;
    const referralSource = details.remoteUserReferralSource;
    const firstName = details.remoteUserFirstName;
    const name = details.remoteUserName;
    const lastInitial = details.remoteUserLastName === null ? null : details.remoteUserLastName.charAt(0).toUpperCase();
    // if(title === "Internal meeting") {
    //     return(title)
    // } else {
        if(referralSource === "willow" || (!isWMC && referralSource === "wmc")) {
            return(firstName + " " + lastInitial + "." + listGuestNames(guests, true))
        } else {
            if(name === "Contact") {
                return(listGuestNames(guests, false))
            } else {
                return(name + listGuestNames(guests, true))
            }
        }
    // }
};

const staticSessions = (details, isWMC) => ({
    "backgroundColor": moment(details.end).isBefore(moment()) ? colors.border100 : colors.white,
    "borderColor": moment(details.end).isBefore(moment()) ? colors.primary70 : colors.action100,
    "textColor": colors.primary100,
    "editable": false,
    "inThePast": moment(details.end).isBefore(moment()),
    "meetingType": 'session',
    "end": details.expected_end === null ? details.end : details.expected_end,
    "title": hideNames(details, isWMC)
});

export const tryGetAllSessions = () => {
    return async (dispatch, getState) => {
        dispatch(setSessionsReading(true));
        const timeZone = getState().enterprise.settingsPersonal.get("settingsPersonalInfo").get("timeZone");
        const isWMC = getState().common.wmc.get("isWMC");
        const sessions = await axiosReq('apiv1/coach/schedule/sessions', "GET", {}, devEnterpriseScheduleSessionsData);
        const sessionsModified = await sessions.sessions.map(a => {return {...adjForTZ(a, timeZone), ...staticSessions(a, isWMC)}});
        await dispatch(setSessions(sessionsModified));
        return dispatch(setSessionsReading(false));
    }
};

export const tryGetOneSession = (consumerId, sessionId) => {
    return async (dispatch, getState) => {
        dispatch(setOneSessionReading(true));
        const isWMC = getState().common.wmc.get("isWMC");
        const timeZone = getState().enterprise.settingsPersonal.get("settingsPersonalInfo").get("timeZone");
        let suffix = '/sessions/'+sessionId;
        if(consumerId !== null) {
            suffix = '/'+consumerId+'/sessions/'+sessionId;
        }
        const sessions = await axiosReq('apiv1/coach/clients'+suffix, "GET", {}, devEnterpriseScheduleOneSessionData);
        const sessionsModified = {...adjForTZ(sessions, timeZone), ...staticSessions(sessions, isWMC)};
        await dispatch(setOneSession(sessionsModified));
        return dispatch(setOneSessionReading(false));
    }
};

const tryInviteContactToConference = (contactId, conferenceId) => {
    return async dispatch => {
        dispatch(setSessionsAddingGuestToSession(true));
        const newGuest = await axiosReq('apiv1/wmc/contact/'+contactId+'/invite/'+conferenceId, "POST", {}, devEnterpriseConferenceGuest);
        await dispatch(setSessionsAddGuestToSession(newGuest.guest, conferenceId));
        await dispatch(setClientsActivityUpdateRecordAddMeetingGuest(conferenceId, newGuest.guest));
        dispatch(setSessionsAddingGuestToSession(false));
    }
};

export const inviteGuestsToMeeting = (guests, conferenceId) => {
    return async dispatch => {
        if(guests.length > 0) {
            return Promise.all(guests.map(async g => {return Promise.resolve(dispatch(tryInviteContactToConference(g.value.contactId, conferenceId)));}));
        }
    }
};

// Used for Enterprise's meeting create form only
// apiv1/coach/client-sessions/<int:clientid>      # expert->lily       # Creates a normal meeting when lily is provided
// apiv1/coach/client-sessions                     # expert->expert     # Creates blank meeting when no lily, experts added as guests
export const tryCreateSession = (start, end, topic, description, who, guests, purchaseHistoryId, isWillowSession) => {
    return async (dispatch, getState) => {
        console.log(isWillowSession)
        dispatch(setSessionCreating(true));
        const isWMC = getState().common.wmc.get("isWMC");
        let consumerId = who.value.consumerId;
        let businessUserId = who.value.businessUserId;
        // if(consumerId === null && businessUserId === null) {
        //     const newProspect = await dispatch(createProspectFromContact(who.value.contactId));
        //     consumerId = newProspect.consumerId;
        // } else if(consumerId === null && businessUserId !== null) {
        if(consumerId === null || businessUserId !== null) {
            consumerId = null;
            guests.push(who);
        }
        let suffix = "";
        if(consumerId !== null) {
            suffix = "/" + consumerId
        }
        const conference = await axiosReq('apiv1/coach/client-sessions'+suffix, "POST", {"start": moment.utc(start).format("YYYY-MM-DD[T]HH:mm:ss[+]00:00"), "expected_end": moment.utc(end).format("YYYY-MM-DD[T]HH:mm:ss[+]00:00"), "topic": topic, "description": description, "purchaseHistoryId": purchaseHistoryId, "isWillowSession": purchaseHistoryId === "gensession" ? true : false}, devEnterpriseCreatedSessionData);
        const timeZone = getState().enterprise.settingsPersonal.get("settingsPersonalInfo").get("timeZone");
        await dispatch(setSessionsAddNewSession({...adjForTZ(conference, timeZone), ...staticSessions(conference, isWMC)}));
        await dispatch(inviteGuestsToMeeting(guests, conference.id));
        if(consumerId !== null) {
            dispatch(setAllClientsAddNextSession(who.value.prospectId, conference.id, conference.start));
            dispatch(setAllProspectsAddNextSession(who.value.prospectId, conference.id, conference.start));
            const prospectLoaded = getState().enterprise.clientsActivity.get("setClientsActivityProspectIdLoaded");
            if(prospectLoaded !== null) {
                dispatch(setClientsActivityProspectIdLoaded(null));
            }
        }
        return dispatch(setSessionCreating(false));
    }
};

export const tryDeleteSessions = (consumerId, sessionId) => {
    return async (dispatch, getState) => {
        dispatch(setSessionsDeleting(true));
        await axiosReq('apiv1/coach/schedule/sessions', "DELETE", {sessionId: sessionId, reschedule: false}, devEnterpriseScheduleSessionsDeleteData(sessionId, getState()));
        dispatch(setSessionsDelete(sessionId));
        if(consumerId !== null) {
            dispatch(setClientsActivityUpdateRecord("upcomingConferences", sessionId, {"activityType": "canceledConferences", "videoStatus": "conference_canceled"}));
            const isWMC = getState().common.wmc.get("isWMC");
            let prospectStatus;
            let prospectId;
            if(isWMC) {
                const contact = await dispatch(getOneContactByConsumerId(consumerId));
                prospectStatus = contact.prospectStatus;
                prospectId = contact.prospectId;
            } else {
                const tryProspect = await dispatch(getOneProspectByConsumerId(consumerId));
                console.log(tryProspect);
                if(tryProspect.size > 0) {
                    prospectStatus = "Prospect";
                    prospectId = tryProspect.get(0).get("prospectId");
                } else {
                    const tryClient = await dispatch(getOneClientByConsumerId(consumerId));
                    console.log(tryClient);
                    prospectStatus = "Client";
                    prospectId = tryClient.get(0).get("prospectId");
                }
            }
            if(prospectStatus === "Prospect") {
                const prospect = await dispatch(tryGetOneProspect(prospectId, "return"));
                dispatch(setAllProspectsSetNextSession(consumerId, prospect.nextSession));
            } else if(prospectStatus === "Client") {
                const client = await dispatch(tryGetOneClient(prospectId, "return"));
                dispatch(setAllClientsSetNextSession(consumerId, client.nextSession));
            }
        }
        dispatch(setSessionsDeleting(false));
    }
};

export const tryGetClientSessions = (clientID) => {
    return async (dispatch, getState) => {
        const conferences =  await axiosReq(`apiv1/coach/clients/${clientID}/all-sessions`,"GET", {},devClientPastUpcomingConferenecs);
       const updated = conferences.upcomingConferences.filter(c => !c.isCanceled && !c.missed && c.isAdvertising);
        return updated;
    }
}