import React from "react";
import styled from "styled-components";

/* Components */
import {FontHeader21} from "../../../common/components/fonts";
import {images} from "../../../common/components/images";

const LogoContainer = styled.div`
    text-align: center;
`;

const Plus = styled(FontHeader21)`
    margin: 0px 10px;
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 460px) {
        display: block;
    }
`;

const Logo = styled.img`
    width: auto;
    height: 32px;
    ${props => props.areLinks !== true} {
      cursor: pointer;
    }
    ${props => props.areLinks !== false} {
      cursor: default;
    }
    margin: 0px auto;
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 460px) {
        display: block;
    }
`;
export const TopLogo = ({partnerLogo = null, willowLogoIcon = false, onClickPartnerLogo = () => {}, onClickWillowLogo = () => {}, areLinks = true}) => (
    <LogoContainer>
        {partnerLogo !== null &&
            <>
                <Logo areLinks={areLinks} src={partnerLogo} alt={""} onClick={onClickPartnerLogo}/>
                <Plus>+</Plus>
            </>
        }
        <Logo areLinks={areLinks} src={willowLogoIcon ? images.logoIcon : images.logo} alt={""} onClick={onClickWillowLogo}/>
    </LogoContainer>
)