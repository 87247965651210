import React from 'react';

/* Components */
import { Background, PageContent } from '../../../consumer/navbars/components/navlayout';

/* Containers */
import Flow from "./containers/flow";

const ConsumerSignUp = () => (
    <>
        <PageContent>
            <Flow />
        </PageContent>
        <Background />
    </>
);

export default ConsumerSignUp;