import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import {withRouter} from 'react-router-dom';

/* Containers */
import SchedulerAvatar from './avatar';
import SchedulerAvatarChange from './avatarchange';

/* Components */
import {ButtonTertiary} from '../../../../common/components/buttons';
import {FontTitle18, FontBody16, FontBody14} from '../../../../common/components/fonts';
import {LogoLoading} from "../../../../common/components/loading";
import {CheckOutlined, ExportOutlined, PlaySquareOutlined, CodeOutlined, CopyOutlined, EditOutlined, InfoCircleOutlined, LinkOutlined} from "@ant-design/icons";
import {SavedSettings} from "../../../../common/components/cards";
import {InlineBlock, SaveSection, SubmitInputWithValidationAlert, SubmitInputWithValidation, EditIcon, InlineItemLeft, InlineItemRight, Invalid, Button, ButtonDone, FitContent, Info, InputSection, Label, LoadingCenter, ReadOnlyLabel, Red, RemoveMargin} from "../components/allservicespage";

/* Middleware */
import {tryGetExpertProfile, tryUpdateExpertProfile} from '../middleware/expertprofile';
import {Border} from "../../../listings/components/allservicespage";

/* Domain */
import getDomainEnv from '../../../../domains/utils';
const env = getDomainEnv();

class AllEventsPage extends React.Component {
    state = {
        isLoading: true,
        handle: '',
        handlePrevious: '',
        handlePreviousIsValid: false,
        handleValid: true,
        handleWarning: "",
        handleIsChanged: false,
        handleIsValid: false,
        handleIsSaving: false,
        handleIsSaved: false,
        handleEdit: false,
        handleExists: false,
        copySuccess: false
    };

    componentDidMount() {
        return this.initiate();
    }

    initiate = async () => {
        await this.props.tryGetExpertProfile();
        const handle = this.props.profile.get(this.props.handleKey);
        await this.setState({
            "handleExists": handle !== null,
            "handle": handle === null ? "" : handle,
            "handlePrevious": handle === null ? "" : handle,
            "handlePreviousIsValid": handle !== null,
            "isLoading": false,
        });
    };

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    changeHandle = async e => {
        await this.setState({
            'handle': e.target.value,
            "handleIsChanged": this.state.handlePrevious !== e.target.value,
            'handleIsValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')
        });
    };

    edit = async (status) => {
        this.setState({"handleEdit": status});
    };

    submitHandle = async e => {
        e.preventDefault();
        this.setState({'handleIsSaving': true, "handleWarning": ""});
        await this.props.tryUpdateExpertProfile({[this.props.handleKey]: this.state.handle}, this.props.errorKey);
        if(this.props.profile.get(this.props.errorKey) === "") {
            this.setState({'handleExists': true, 'handleIsSaving': false, 'handleIsSaved': true, "handle": this.state.handle, "handlePrevious": this.state.handle, "handlePreviousIsValid": true, "handleIsChanged": false});
            this.timeout = setTimeout(() => {this.setState({'handleIsSaved': false, "handleEdit": false})}, 3000);
        } else {
            this.setState({'handleIsSaving': false, "handleIsValid": false, "handleWarning": this.props.profile.get(this.props.errorKey)});
        }
    };

    handleCancel = () => {
        this.setState({"handle": this.state.handlePrevious, "handleEdit": false});
    };

    handleCopy = () => {
        let tempInput = document.createElement("input");
        tempInput.value = "https://"+env.URL.DOMAIN+"/"+this.props.url+"/"+this.props.profile.get(this.props.handleKey);
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        this.setState({"copySuccess": true});
        this.timeout = setTimeout(() => {this.setState({'copySuccess': false})}, 3000);
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if(this.state.handleIsSaved) {
                return(
                    <>
                        {this.props.title !== null && <FontTitle18>{this.props.title}</FontTitle18>}
                        {this.props.subtitle !== null && <FontBody16>{this.props.subtitle}</FontBody16>}
                        <br />
                        {this.state.handleWarning === '' && <SavedSettings height={"30px"} error={false} message={"Saved"}/>}
                        {this.state.handleWarning !== '' && <SavedSettings height={"30px"} error={true} message={this.state.handleWarning}/>}
                    </>
                )
            } else {
                if(!this.state.handleExists) {
                    return(
                        <>
                            {this.props.title !== null && <FontTitle18>{this.props.title}</FontTitle18>}
                            {this.props.subtitle !== null && <FontBody16>{this.props.subtitle}</FontBody16>}
                            <SchedulerAvatarChange handleEdit={this.edit} />
                            <br />
                            <br />
                            <Border />
                            <br />
                            <br />
                            <form method={"post"} onSubmit={this.submitHandle}>
                                <ReadOnlyLabel><FontBody16>Create handle<Info title={"Minimum 3 letters or numbers. Symbols are not allowed."}><InfoCircleOutlined /></Info></FontBody16></ReadOnlyLabel>
                                <InputSection
                                    id={"handle"}
                                    onChange={this.changeHandle}
                                    placeholder={"Handle"}
                                    value={this.state.handle}
                                />
                                {this.state.handleWarning !== "" && <Label><Invalid>{this.state.handleWarning}</Invalid></Label>}
                                <br />
                                <br />
                                <RemoveMargin />
                                <SaveSection>
                                    <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.handleIsChanged} isValid={this.state.handleIsValid} isSaving={this.state.handleIsSaving} /></InlineBlock>
                                </SaveSection>
                            </form>
                        </>
                    )
                } else {
                    if(this.state.handleEdit) {
                        return(
                            <>
                                {this.props.title !== null && <FontTitle18>{this.props.title}</FontTitle18>}
                                {this.props.subtitle !== null && <FontBody16>{this.props.subtitle}</FontBody16>}
                                <SchedulerAvatarChange handleEdit={this.edit} />
                                <br />
                                <br />
                                <Border />
                                <br />
                                <br />
                                <form method={"post"} onSubmit={this.submitHandle}>
                                    <ReadOnlyLabel><FontBody16>My handle<Info title={"Minimum 3 letters or numbers. Symbols are not allowed."}><InfoCircleOutlined /></Info></FontBody16></ReadOnlyLabel>
                                    <InputSection
                                        id={"handle"}
                                        onChange={this.changeHandle}
                                        placeholder={"Handle"}
                                        value={this.state.handle}
                                    />
                                    {this.state.handleWarning !== "" && <Label><Invalid>{this.state.handleWarning}</Invalid></Label>}
                                    <br />
                                    <br />
                                    <Red><FontBody14>Caution: visitors will no longer be able to access your {this.props.type} at the old URL.</FontBody14></Red>
                                    <RemoveMargin />
                                    <SaveSection>
                                        <InlineBlock><SubmitInputWithValidationAlert label={"Change"} isChanged={this.state.handleIsChanged} isValid={this.state.handleIsValid} isSaving={this.state.handleIsSaving} /></InlineBlock>
                                        <InlineBlock><span onClick={this.handleCancel}><ButtonTertiary canSubmit={false} label={"Cancel"} /></span></InlineBlock>
                                    </SaveSection>
                                </form>
                            </>
                        )
                    } else {
                        return(
                            <>
                                <div>
                                    <InlineItemLeft>
                                        {this.props.title !== null && <FontTitle18>{this.props.title}</FontTitle18>}
                                        {this.props.subtitle !== null && <FontBody16>{this.props.subtitle}</FontBody16>}
                                    </InlineItemLeft>
                                    <InlineItemRight><EditIcon onClick={() => {return this.edit(true)}}><EditOutlined/></EditIcon></InlineItemRight>
                                </div>
                                <SchedulerAvatar />
                                <br />
                                <Label>{env.URL.DOMAIN}/{this.props.url}/{this.state.handle}</Label>
                                <br />
                                {!this.state.copySuccess
                                    ? <FitContent><span onClick={this.handleCopy}><Button><FontBody16><CopyOutlined />&nbsp;Copy</FontBody16></Button></span></FitContent>
                                    : <FitContent><span><ButtonDone><FontBody16><CheckOutlined /> Copied!</FontBody16></ButtonDone></span></FitContent>
                                }
                                <br />
                                {this.props.showPreview &&
                                    <FitContent onClick={() => {return this.props.preview(true)}}><Button><FontBody16><PlaySquareOutlined />&nbsp;Preview</FontBody16></Button></FitContent>
                                }
                                {this.props.showOpenLink &&
                                    <FitContent><a href={"https://"+env.URL.DOMAIN+"/"+this.props.url+"/"+this.state.handle} target={"_blank"}><Button><FontBody16><ExportOutlined />&nbsp;Visit Page</FontBody16></Button></a></FitContent>
                                }
                                <br />
                                {this.props.showLinkEmbed &&
                                    <FitContent onClick={() => {return this.props.linkEmbed(true)}}><Button><FontBody16><LinkOutlined />&nbsp;Link or Embed</FontBody16></Button></FitContent>
                                }
                            </>
                        )
                    }
                }
            }
        }
    }
}

const mapStateToProps = state => ({
    profile: state.enterprise.scheduler.expertProfile.get("expertProfile")
});

const mapDispatchToProps = dispatch => ({
    tryGetExpertProfile: () => dispatch(tryGetExpertProfile()),
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AllEventsPage));
