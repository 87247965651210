import React from 'react';
import styled from "styled-components";
import DatePicker from "react-datepicker";

/* Components */
import {colors} from "./colors";
import {fontFamily} from "./fonts";

const AppointmentTop = styled.div`
display: inline-block;
vertical-align: top;
width: 100%;

& .react-datepicker {
    margin: auto;
    width: 100%;
    border: none;
    background-color: ${colors.white};
    margin-top: 10px;
}
& .react-datepicker__month-container {
    width: 100%;
}
& .react-datepicker__header {
    background-color: ${colors.white};
    border: none;
    font-weight: bold;
}
& .react-datepicker__current-month {
    color: ${colors.primary100};
    margin-bottom: 10px;
    font-size: 22px;
    font-family: ${fontFamily.subtitle};
    font-weight: normal;
}
& .react-datepicker__navigation--previous {
    left: 3% !important;
    color: ${colors.primary100};
}
& .react-datepicker__navigation--next {
    right: 3% !important;
    color: ${colors.primary100};
}
& .react-datepicker__navigation--next, .react-datepicker__navigation--previous {
    outline: none;
}
& .react-datepicker__day-name {
    color: ${colors.primary100};
    margin: 1% 3% 0% 3% !important;
    font-family: ${fontFamily.body};
    font-size: 14px;
    @media screen and (max-width: 1290px) {
        margin: 1% 5% 0% 5%;
    }
}
& .react-datepicker__day {
    margin: 0% 3% 3% 3% !important;
    color: ${colors.primary100};
    border-radius: 50%;
    font-family: ${fontFamily.body};
    font-size: 14px;
    &:hover {
        outline: 2px solid ${colors.action100} !important;
        background-color: ${colors.white};
        color: ${colors.black} !important;
        border-radius: 50%;
    }
    @media screen and (max-width: 1290px) {
        margin: 0% 5% 3% 5%;
    }
}
& .react-datepicker__day--selected {
    background-color: ${colors.action100} !important;
    color: ${colors.white} !important;
}
& .react-datepicker__day--keyboard-selected {
    background-color: ${colors.white} !important;
    color: ${colors.primary100} !important;
}
& .react-datepicker__day--selected:focus {
    outline: none !important;
    border: none !important;
}
& .react-datepicker__day--outside-month {
    visibility: hidden;
}
& .react-datepicker__day--highlighted {
    background-color: ${colors.backgroundColor3};
    color: ${colors.white};
    font-weight: bold;
}
`;

/**
export class HomeCalendar extends React.Component {
    state = {
        selectedDay: new Date()
    }

    handleChangeDay = async date => {
    await this.setState({
        'selectedDay': date,
        });
    };

    render () {
        return (
            <AppointmentTop>
                <DatePicker    
                onChange={this.handleChangeDay}
                selected={this.selectedDay}
                inline
                />
            </AppointmentTop>
        )
    };

}
 */

const Calendar = ({selectedDay, onDateChange = () => {}, highlightDates = []}) => {
    return (
        <>
        <AppointmentTop>
                <DatePicker    
                onChange={onDateChange}
                selected={selectedDay}
                highlightDates={highlightDates}
                inline
                />
            </AppointmentTop>
        </>
    );
}

export default Calendar;