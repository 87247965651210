import React from 'react';
import ReactQuill from "react-quill";
import {connect} from 'react-redux';
import {fromJS} from 'immutable';
import moment from 'moment/moment';
import styled from "styled-components";

import {GreenCategoryTitles, Container, Title, EachSectionTitle, Icon24, Location, EachSection, Body, Spacing, Inline, GrayColor, SubTitle, Description, Card, ImagePlaceholder, Specialty, SectionSub, BorderBottom, Icon14, Left, Right, Icon16, CardWhite, TitleList, LeftSub, RightSub, ImageSub} from "../components/about";
import {BigSpacing} from "../../../enterprise/shop/components/experience";
import {
    FontHeader21,
    FontBody16,
    FontBody18,
    FontHeader18,
    FontHeader16,
    FontSubtitle16
} from "../../../common/components/fonts";
import {MapPin, Briefcase, Shield, BookOpen, Monitor, Award, Star, Heart, CheckSquare} from 'react-feather';

/* Middleware */
import {tryGetExpertProfileFromToken} from "../middleware/profile";

import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const pickMonth = (num) => {
    if(num === 1) {return("Jan")}
    else if(num === 2) {return("Feb")}
    else if(num === 3) {return("Mar")}
    else if(num === 4) {return("Apr")}
    else if(num === 5) {return("May")}
    else if(num === 6) {return("Jun")}
    else if(num === 7) {return("Jul")}
    else if(num === 8) {return("Aug")}
    else if(num === 9) {return("Sep")}
    else if(num === 10) {return("Oct")}
    else if(num === 11) {return("Nov")}
    else if(num === 12) {return("Dec")}
};

const Bullet = styled.div`
    display: inline-block;
`;
const BulletSpacing = styled.div`
    padding-left: 15px;
`;

class IframeAbout extends React.Component {
    static defaultProps = {
        viewVerticalOnly: false,
        fetchProfileId: null,
        viewFromAdvisorDashboard: false
    };

    state={"isLoading": true, "profile": {}};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        let profile;
        if(this.props.fetchProfileId !== null) {
            const data = await this.props.tryGetExpertProfileFromToken(this.props.fetchProfileId);
            profile = fromJS(data);
        } else {
            profile = this.props.profile;
        }
        this.setState({"profile": profile, "isLoading": false});
        console.log(profile)
    };

    render() {
        if(this.state.isLoading) {
            return null
        } else {
            let p = this.state.profile;
            let expertCategories = env.CONSUMER.CARE.PRINT_EXPERT_CATEGORIES(p.get("expertCategories").toJS(), false);
            return(
                <Container viewVerticalOnly={this.props.viewVerticalOnly}>
                    <Left viewVerticalOnly={this.props.viewVerticalOnly}>
                        <Title>
                            {!this.props.viewFromAdvisorDashboard
                                ? <FontHeader21>About Me</FontHeader21>
                                : <GreenCategoryTitles>About</GreenCategoryTitles>
                            }
                        </Title>
                        <Body>
                            <ReactQuill
                                readOnly={true}
                                theme={null}
                                value={p.get("bio")}
                                bounds={'.app'}
                            />
                        </Body>
                        <br />
                        {this.props.viewFromAdvisorDashboard && <br />}
                        {(expertCategories.length > 0 && !this.props.viewFromAdvisorDashboard) &&
                        <>
                            <TitleList><FontHeader16>Specialties</FontHeader16></TitleList>
                            {expertCategories.map(e => (
                                <Specialty key={e}><FontBody16><Icon14><Star /></Icon14>&nbsp;{e}</FontBody16></Specialty>
                            ))}
                            <br/>
                            <br/>
                        </>
                        }
                        {p.get("skills").size > 0 &&
                        <>
                            <TitleList extraBottomMargin={this.props.viewFromAdvisorDashboard}>
                                {!this.props.viewFromAdvisorDashboard
                                    ? <FontHeader16>Skills</FontHeader16>
                                    : <GreenCategoryTitles>Skills</GreenCategoryTitles>
                                }
                            </TitleList>
                            {p.get("skills").map((s, indexS) => (
                                <Specialty key={indexS}><FontBody16>{!this.props.viewFromAdvisorDashboard && <><Icon14><CheckSquare /></Icon14>&nbsp;</>}{s}</FontBody16></Specialty>
                            ))}
                            <br/>
                            <br/>
                        </>
                        }
                        {this.props.viewFromAdvisorDashboard &&
                            <>
                                <br />
                                <br />
                            </>
                        }
                        {p.get("interests").size > 0 &&
                        <>
                            <TitleList extraBottomMargin={this.props.viewFromAdvisorDashboard}>
                                {!this.props.viewFromAdvisorDashboard
                                    ? <FontHeader16>Interests</FontHeader16>
                                    : <GreenCategoryTitles>Interests</GreenCategoryTitles>
                                }
                            </TitleList>
                            {p.get("interests").map((i, indexI) => (
                                <Specialty key={indexI}><FontBody16>{!this.props.viewFromAdvisorDashboard && <><Icon14><Heart /></Icon14>&nbsp;</>}{i}</FontBody16></Specialty>
                            ))}
                            <br/>
                            <br/>
                        </>
                        }
                        <br/>
                        <br/>
                        <EachSectionTitle>
                            {!this.props.viewFromAdvisorDashboard
                                ? <><Icon24><Shield /></Icon24>&nbsp;&nbsp;<Inline><FontHeader21>Licenses, Certifications</FontHeader21></Inline></>
                                : <GreenCategoryTitles>Licenses & Certifications</GreenCategoryTitles>
                            }
                        </EachSectionTitle>
                        <EachSection>
                            <SectionSub>
                            {p.get("licenses").map((l, indexL) => (
                                <div key={indexL}>
                                    <div>
                                        <RightSub>
                                            <SubTitle><FontHeader18>{l.get("title")}</FontHeader18></SubTitle>
                                            {l.get("institute") !== null && <BulletSpacing><FontBody18>{l.get("institute")}</FontBody18></BulletSpacing>}
                                            <Spacing />
                                            {(l.get("endYear") !== null && l.get("endMonth")) &&
                                                <BulletSpacing>
                                                    <FontBody16>
                                                        <GrayColor>
                                                            {l.get("endMonth") !== null && <span>{pickMonth(l.get("endMonth"))}</span>}
                                                            {(l.get("endMonth") !== null && l.get("endYear") !== null) && <span>,&nbsp;</span>}
                                                            {l.get("endYear") !== null && <span>{l.get("endYear")}</span>}
                                                        </GrayColor>
                                                    </FontBody16>
                                                </BulletSpacing>
                                            }
                                            {l.get("licenseNumber") !== null && <BulletSpacing><GrayColor><FontBody16>{l.get("licenseNumber")}</FontBody16></GrayColor></BulletSpacing>}
                                        </RightSub>
                                    </div>
                                    {indexL + 1 !== p.get("licenses").size && <BigSpacing />}
                                </div>
                            ))}
                            </SectionSub>
                        </EachSection>
                        <EachSectionTitle>
                            {!this.props.viewFromAdvisorDashboard
                                ? <><Icon24><Briefcase /></Icon24>&nbsp;&nbsp;<Inline><FontHeader21>Experience</FontHeader21></Inline></>
                                : <GreenCategoryTitles>Experience</GreenCategoryTitles>
                            }
                        </EachSectionTitle>
                        <EachSection>
                            {p.get("jobs").map((j, indexJ) => (
                                <SectionSub key={indexJ}>
                                    <div>
                                        <LeftSub>
                                            {(j.get("photo") === null || j.get("photo") === undefined)
                                                ? <ImagePlaceholder />
                                                : <ImageSub src={j.get("photo")} alt={""} />
                                            }
                                        </LeftSub>
                                        <RightSub>
                                            <SubTitle><FontHeader18>{j.get("title")}</FontHeader18></SubTitle>
                                            {j.get("company") !== null && <FontBody18>{j.get("company")}</FontBody18>}
                                            <Spacing />
                                            {(j.get("start") !== null || j.get("end") !== null) &&
                                            <FontBody16><GrayColor>
                                                {j.get("startMonth") !== null && <span>{pickMonth(j.get("startMonth"))}</span>}
                                                {(j.get("startMonth") !== null && j.get("startYear") !== null) && <span>,&nbsp;</span>}
                                                {j.get("startYear") !== null && <span>{j.get("startYear")}</span>}
                                                {((j.get("startMonth") !== null || j.get("startYear") !== null) && (j.get("endMonth") !== null || j.get("endYear") !== null || j.get("endOngoing"))) &&
                                                <span>&nbsp;-&nbsp;</span>
                                                }
                                                {j.get("endOngoing")
                                                    ?
                                                    <span>Ongoing</span>
                                                    :
                                                    <>
                                                        {j.get("endMonth") !== null && <span>{pickMonth(j.get("endMonth"))}</span>}
                                                        {(j.get("endMonth") !== null && j.get("endYear") !== null) && <span>,&nbsp;</span>}
                                                        {j.get("endYear") !== null && <span>{j.get("endYear")}</span>}
                                                    </>
                                                }
                                            </GrayColor></FontBody16>
                                            }
                                            {((j.get("city") !== null && j.get("city") !== "") || (j.get("state") !== null && j.get("state") !== "")) &&
                                            <FontBody16>
                                                {(j.get("city") !== null && j.get("city") !== "") &&
                                                <GrayColor>{j.get("city")}</GrayColor>
                                                }
                                                {((j.get("city") !== null && j.get("city") !== "") && (j.get("state") !== null && j.get("state") !== "")) &&
                                                <GrayColor>,&nbsp;</GrayColor>
                                                }
                                                {(j.get("state") !== null && j.get("state") !== "") &&
                                                <GrayColor>{j.get("state")}</GrayColor>
                                                }
                                            </FontBody16>
                                            }
                                            {(j.get("body") !== null && j.get("body") !== "<p><br></p>" && j.get("body") !== "") &&
                                            <Description>
                                                <Body>
                                                    <ReactQuill
                                                        readOnly={true}
                                                        theme={null}
                                                        value={j.get("body")}
                                                        bounds={'.app'}
                                                    />
                                                </Body>
                                            </Description>
                                            }
                                        </RightSub>
                                    </div>
                                </SectionSub>
                            ))}
                        </EachSection>
                        <EachSectionTitle>
                            {!this.props.viewFromAdvisorDashboard
                                ? <><Icon24><BookOpen /></Icon24>&nbsp;&nbsp;<Inline><FontHeader21>Education</FontHeader21></Inline></>
                                : <GreenCategoryTitles>Education</GreenCategoryTitles>
                            }
                        </EachSectionTitle>
                        <EachSection>
                            {p.get("degrees").map((d, indexD) => (
                                <SectionSub key={indexD}>
                                    <div>
                                        <LeftSub>
                                            {(d.get("photo") === null || d.get("photo") === undefined)
                                                ? <ImagePlaceholder />
                                                : <ImageSub src={d.get("photo")} alt={""} />
                                            }
                                        </LeftSub>
                                        <RightSub>
                                            <SubTitle><FontHeader18>{d.get("title")}</FontHeader18></SubTitle>
                                            {d.get("degree") !== null && <FontBody18>{d.get("degree")}</FontBody18>}
                                            <Spacing />
                                            {(d.get("start") !== null || d.get("end") !== null) &&
                                            <FontBody16><GrayColor>
                                                {d.get("startMonth") !== null && <span>{pickMonth(d.get("startMonth"))}</span>}
                                                {(d.get("startMonth") !== null && d.get("startYear") !== null) && <span>,&nbsp;</span>}
                                                {d.get("startYear") !== null && <span>{d.get("startYear")}</span>}
                                                {((d.get("startMonth") !== null || d.get("startYear") !== null) && (d.get("endMonth") !== null || d.get("endYear") !== null || d.get("endOngoing"))) &&
                                                <span>&nbsp;-&nbsp;</span>
                                                }
                                                {d.get("endOngoing")
                                                    ?
                                                    <span>Ongoing</span>
                                                    :
                                                    <>
                                                        {d.get("endMonth") !== null && <span>{pickMonth(d.get("endMonth"))}</span>}
                                                        {(d.get("endMonth") !== null && d.get("endYear") !== null) && <span>,&nbsp;</span>}
                                                        {d.get("endYear") !== null && <span>{d.get("endYear")}</span>}
                                                    </>
                                                }
                                            </GrayColor></FontBody16>
                                            }
                                            {((d.get("city") !== null && d.get("city") !== "") || (d.get("state") !== null && d.get("state") !== "")) &&
                                            <FontBody16>
                                                {(d.get("city") !== null && d.get("city") !== "") &&
                                                <GrayColor>{d.get("city")}</GrayColor>
                                                }
                                                {((d.get("city") !== null && d.get("city") !== "") && (d.get("state") !== null && d.get("state") !== "")) &&
                                                <GrayColor>,&nbsp;</GrayColor>
                                                }
                                                {(d.get("state") !== null && d.get("state") !== "") &&
                                                <GrayColor>{d.get("state")}</GrayColor>
                                                }
                                            </FontBody16>
                                            }
                                            {(d.get("body") !== null && d.get("body") !== "<p><br></p>" && d.get("body") !== "") &&
                                            <Description>
                                                <Body>
                                                    <ReactQuill
                                                        readOnly={true}
                                                        theme={null}
                                                        value={d.get("body")}
                                                        bounds={'.app'}
                                                    />
                                                </Body>
                                            </Description>
                                            }
                                        </RightSub>
                                    </div>
                                </SectionSub>
                            ))}
                        </EachSection>
                    </Left>
                    {!this.props.viewFromAdvisorDashboard &&
                    <Right viewVerticalOnly={this.props.viewVerticalOnly}>
                        <Location><br /><br />< br/><Title><FontHeader21>Location</FontHeader21></Title></Location>
                        <Card>
                            <FontBody16><Icon16><MapPin /></Icon16>&nbsp;&nbsp;{p.get("city")}, {p.get("state")}</FontBody16>
                            <br/>
                            <FontBody16><Icon16><Monitor /></Icon16>&nbsp;&nbsp;Available remotely</FontBody16>
                        </Card>
                        <br/>
                        <br/>
                        <CardWhite>
                            <FontBody16><Icon16><Award /></Icon16>&nbsp;&nbsp;Member since {moment(p.get("signUpDate")).format("MMM, YYYY")}</FontBody16>
                        </CardWhite>
                    </Right>
                    }
                </Container>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryGetExpertProfileFromToken: (id) => dispatch(tryGetExpertProfileFromToken(id)),
});

export default connect(null, mapDispatchToProps)(IframeAbout);

/*
address1: 'test 3',
address2: 'test 6',
city: 'Boston',
state: 'MA',
zipCode: '23443',

freeTime: 'Test 7',
whyBecameCoach: 'Test 2',
coachPhilosophy: 'Test 3',

advancedDegree: null,
advancedDegreeCity: null,
advancedDegreeInstitution: null,
advancedDegreeState: null,
advancedDegreeYear: null,

otherEducation: null,
 */