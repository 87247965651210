import React from 'react';

/* Components */
import { NavPrimaryWrapper, NavPrimaryMobileWrapper } from '../navbars/navprimary';
import {NavSecondaryWrapper} from "../navbars/navsecondary";
import { PageFull, Background, PageContent, PageContentContainer } from '../../../consumer/navbars/components/navlayout';

/* Containers */
import Billing from "../../../consumer/billing/containers/billing";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const BillingPage = () => (
    <>
        <NavPrimaryWrapper data={env.CONSUMER.NAV} />
        <PageFull>
            <div>
                <NavSecondaryWrapper data={env.CONSUMER.NAV} />
                <PageContent>
                    <PageContentContainer>
                        <Billing />
                    </PageContentContainer>
                </PageContent>
                <Background />
            </div>
            <NavPrimaryMobileWrapper data={env.CONSUMER.NAV} />
        </PageFull>
    </>
);

export default BillingPage;
