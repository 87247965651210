import React from 'react';
import styled from 'styled-components';
import { DeleteOutlined } from '@ant-design/icons';

import { colors } from '../../../components/colors';
import { fontFamily } from '../../../components/fonts';
import { FontSubtitle21, FontTitle16 } from '../../../components/fonts';
import { TextInputLean } from '../../../components/inputs';
import { ButtonPrimary } from "../../../components/buttons";

//
// Layout
//
export const MyMoneyTab = styled.section`
	width: 100%;
	min-height: 300px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
`;
const MyMoneyCol = styled.div`
	flex: 1 1 320px;
	margin-bottom: 20px;
`;
export const MyMoneyFormWrapper = styled(MyMoneyCol)`
`;
export const MyMoneyGraphWrapper = styled(MyMoneyCol)`
	margin-left: 20px;
	@media screen and (max-width: 700px) {
		margin-left: 0;
	}
`;
export const MyMoneySnapshotCol = styled(MyMoneyCol)`
	margin: 0 23px;
	@media screen and (max-width: 680px) {
		margin: 0;
	}
`;
//
// Elements
//
export const MyMoneyTabHeading = styled.h4`
	display: none;
	flex: 0 0 100%;
	margin: 0 0 10px;
	font-family: ${fontFamily.header};
	line-height: 1.4em;
`;
export const MyMoneyTabDescrip = styled.p`
	display: none;
	flex: 0 0 100%;
	margin: 0 0 20px;
	font-family: ${fontFamily.body};
	line-height: 1.4em;
`;
const Title = styled(FontSubtitle21)`
	position: relative;
	display: block;
	margin-bottom: 20px;
	display: flex;
	justify-content: ${props => props.alignRight ? 'flex-end' : props.center ? 'center' :'space-between'};
	.dropdownContainer {
		flex: 1;
		margin: 0 20px 0 0; 
	}
	@media screen and (max-width: 748px) {
        text-align: ${props => props.center ? 'center' : 'left'};
		display: ${props => props.center ? 'inherit' : 'flex'};
		padding: ${props => props.padded ? '0px 10px' : '0px'};
    }
`;
export const MyMoneySectionTitle = ({ children, alignRight=false, center, padded }) => (
    <Title alignRight={alignRight} center={center} padded={padded}>{children}</Title>
);

export const LineItemList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`;
export const LineItem = styled.li`
	position: relative;
	margin: 0;
	padding: 0;
	display: flex;
	.totalLabel {
		flex: 3 3 180px;
		font-family: ${fontFamily.header};
		padding-left: 10px;
	}
	.totalValue {
		flex: 1 1 90px;
		font-family: ${fontFamily.header};
		text-align: right;
		padding-right: 26px;
	}
`;
export const LineItemTotalLabel = styled.span`
	flex: 3 3 180px;
	font-family: ${fontFamily.header};
	padding-top: 13px;
	padding-left: 12px;
`;
export const LineItemTotalValue = styled.span`
	flex: 1 1 90px;
	font-family: ${fontFamily.header};
	text-align: right;
	padding-top: 13px;
	padding-right: 26px;
`;
function UnstyledLineItemNameInput(opts) {
    return (<TextInputLean {...opts} />);
}
function UnstyledLineItemValueInput(opts) {
    return (<TextInputLean type="number" {...opts} />);
}
function UnstyledLineItemDateInput(opts) {
    return (<TextInputLean type="date" placeholder="mm/dd/yy" required {...opts} />);
}
function UnstyledLineItemCreditScoreInput(opts) {
    return (<TextInputLean type="number" placeholder="713" min="350" max="850" {...opts} />);
}
export const LineItemNameInput = styled(UnstyledLineItemNameInput)`
	flex: 3 3 180px;
	&::before {
		content: '${props => props.label}';
		font-size: .8em;
		font-family: ${fontFamily.body};
		color: ${colors.primary100};
		text-transform: uppercase;
	}
	& input {
		border-radius: 20px 0px 20px 20px;
		border: none;
	}
`;
export const LineItemValueInput = styled(UnstyledLineItemValueInput)`
	position: relative;
	flex: 1 1 90px;
	margin-right: 0;
	input {
		text-align: right;
		border-radius: 20px 20px 20px 0px;
		border: none;
		padding: 2px 5px 2px 10px;
	}
	&::after {
		content: '$';
		position: absolute;
		bottom: 4px;
		left: 7px;
		opacity: .5;
	}
	&::before {
		content: '${props => props.label}';
		font-size: .8em;
		font-family: ${fontFamily.body};
		color: ${colors.primary100};
		text-transform: uppercase;
	}
`;
export const LineItemDateInput = styled(UnstyledLineItemDateInput)`
	flex: 0 0 124px;
	margin-left: 5px;
	input[type="date"] {
		height: 28px;
		max-width: 124px;
		padding-right: 2px;
		box-sizing: border-box;
		border-radius: 20px 20px 20px 0px;
		::-webkit-datetime-edit-year-field { padding-right: 0; }
		::-webkit-inner-spin-button { display: none; }
		::-webkit-calendar-picker-indicator {
			position: absolute;
			width: 100%;
			margin-left: 0;
			background: transparent;
			left: 0;
		}
		:invalid::-webkit-datetime-edit {
			color: ${colors.primary30};
		}
	}
	&::before {
		content: '${props => props.label}';
		font-size: .8em;
		font-family: ${fontFamily.body};
		color: ${colors.primary100};
		text-transform: uppercase;
	}
`;
export const LineItemCreditScoreInput = styled(UnstyledLineItemCreditScoreInput)`
	position: relative;
	flex: 1 1 90px;
	margin-right: 0;
	input {
		padding-right: 2px;
		border-radius: 20px 0px 20px 20px;
	}
	&::before {
		content: '${props => props.label}';
		font-size: .8em;
		font-family: ${fontFamily.body};
		color: ${colors.primary100};
		text-transform: uppercase;
	}
`;
export const CardTitleInput = styled.input`
	flex: 1;
	display: inline-block;
	width: auto;
	min-width: 100px;
	padding: 0 0 0 4px;
	margin: 0 5px 0 -4px;
	border: 0;
	font-size: 1em;
	border-radius: 1px;
	transition: box-shadow .3s ease-in-out;
	:focus {
		outline: none;
		box-shadow: 0 0 4px 1px ${colors.action100};
	}
	background: ${props => props.useNewDesign ? 'none': colors.white};
`;
export const DeleteButton = styled(DeleteOutlined)`
	display: block;
	position: absolute;
	top: 50%;
	left: -60px;
	margin-top: -12px;
	font-size: 22px;
	color: ${colors.secondary70};
	cursor: pointer;
	transition: color .3s ease-in-out;
	&:hover {
		color: ${colors.alert100};
	}
	@media screen and (max-width: 800px) {
		left: -21px;
	}
`;

//
// First Time Experience
//
const Tooltip = styled.div`
	position: absolute;
	top: calc(100% + 10px);
	right: 58px;
	width: 200px;
	margin-right: -100px;
	padding: 20px;
	z-index: 11;
	font-size: 13px;
	line-height: 1.2em;
	font-family: ${fontFamily.body};
	border-radius: 4px;
	background: ${colors.action100}AA;
	box-shadow:
		0 2.8px 2.2px rgba(0, 0, 0, 0.02),
		0 6.7px 5.3px rgba(0, 0, 0, 0.028),
		0 12.5px 10px rgba(0, 0, 0, 0.035),
		0 22.3px 17.9px rgba(0, 0, 0, 0.042),
		0 41.8px 33.4px rgba(0, 0, 0, 0.05),
		0 100px 80px rgba(0, 0, 0, 0.07);
	animation: 1.2s ease-in-out 0.9s infinite alternate bounce;
	&::after {
		content: '';
		position: absolute;
		top: -8px;
		left: 50%;
		margin-left: -3px;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-bottom: 8px solid ${colors.action100}AA;
		filter: brightness(105%);
	}
	.tooltipTitle {
		font-family: ${fontFamily.header};
		margin-bottom: 5px;
	}
	.tooltipText {
	}
	.tooltipClose {
		position: absolute;
		top: 5px;
		right: 5px;
		width: 20px;
		height: 20px;
		z-index: 1;
		font-size: 19px;
		opacity: .25;
		text-align: center;
		cursor: pointer;
		&:hover {
			opacity: 1;
		}
	}
	.tooltipBlur {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		backdrop-filter: blur(3px) brightness(105%);
	}
	@media(max-width: 780px) {
		right: 0;
		margin-right: 0;
	}
	@keyframes bounce {
		from { margin-top: 0; }
		to { margin-top: 8px; }
	}
`;
export const FteTooltip = ({ title, text, onClose }) => (
    <Tooltip>
        <div className="tooltipBlur" />
        {onClose && <div className="tooltipClose" onClick={onClose}>&times;</div>}
        {title && <div className="tooltipTitle">{title}</div>}
        {text && <div className="tooltipText">{text}</div>}
    </Tooltip>
);

export const SpaceBetweenMissingInfo = styled.div`
    display: inline-flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
    color: ${colors.secondary100};
    ${props => props.direction !== "column"} {
        flex-direction: column;
    }
    ${props => props.direction !== "row"} {

    }
`;

export const SettingsCardMissingInfo = styled.div`
    -webkit-box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.1);
    background-color: ${colors.white};
    color: ${colors.primary100};
    max-width: ${props => props.maxWidth};
    margin: 0px auto 40px auto;
    position: relative;
    padding: 40px 20px;
    width: calc(100% - 40px);
`;

export const GetCoachCTA = ({history}) => (
    <SettingsCardMissingInfo>
        <SpaceBetweenMissingInfo direction={"column"}>
            <FontTitle16>Find a Coach to get started!</FontTitle16>
            <div onClick={() => {return history.push('/my-team/find')}}>
                <ButtonPrimary submit={false} label={"Coaches"} />
            </div>
        </SpaceBetweenMissingInfo>
    </SettingsCardMissingInfo>
)