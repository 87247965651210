import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setTeamMemberProductsGeneralFetching, setTeamMemberProductsGeneral, setTeamMemberProductsWillow, setTeamMemberProductsWillowFetching, setTeamMemberProductsCoach, setTeamMemberProductsCoachFetching, setTeamMemberProductsWMC, setTeamMemberProductsWMCFetching} from "../store/products";

/* Static */
import {devTeamMemberMeetingsData, devEnterpriseMemberBillingHistoryData, devEnterpriseWMCCoachesSessions, devConsumerProductGeneral} from "../static/devdata";

const tryGetTeamMemberProductsWillow = () => {
    return async dispatch => {
        dispatch(setTeamMemberProductsWillowFetching(true));
        const product = await axiosReq('apiv1/consumer/journey/sessions', "GET", {}, devTeamMemberMeetingsData);
        await dispatch(setTeamMemberProductsWillow(product.program));
        dispatch(setTeamMemberProductsWillowFetching(false));
        return product;
    }
};

const tryGetTeamMemberProductsCoach = () => {
    return async dispatch => {
        dispatch(setTeamMemberProductsCoachFetching(true));
        const history = await axiosReq('apiv1/consumer/purchase/get-purchase-history', "GET", {}, devEnterpriseMemberBillingHistoryData);
        await dispatch(setTeamMemberProductsCoach(history));
        await dispatch(setTeamMemberProductsCoachFetching(false));
        return history;
    }
};

// const tryGetTeamMemberProductsWMC = () => {
//     return async dispatch => {
//         dispatch(setTeamMemberProductsWMCFetching(true));
//         const coaches = await axiosReq('apiv1/consumer/coach/all-wmc-coaches', "GET", {}, devEnterpriseWMCCoachesSessions);
//         dispatch(setTeamMemberProductsWMC(coaches.allCoaches));
//         dispatch(setTeamMemberProductsWMCFetching(false));
//         return coaches.allCoaches;
//     }
// };

export const tryGetTeamMemberProductsGeneral = () => {
    return async dispatch => {
        dispatch(setTeamMemberProductsGeneralFetching(true));
        const sessions = await axiosReq('apiv1/consumer/sessions/get-available-amount', "GET", {}, devConsumerProductGeneral);
        console.log(sessions.availableSessions);
        dispatch(setTeamMemberProductsGeneral(sessions.availableSessions));
        dispatch(setTeamMemberProductsGeneralFetching(false));
        return sessions.availableSessions;
    }
};

/* Utils */
// import {checkRemainingSessionsProductsDomain} from '../utils/productsdomain';
// import {checkRemainingSessionsProductsWMC} from '../utils/productsfirm';
import {checkRemainingSessionsProductsCoach} from '../utils/productscoach';
import {checkRemainingSessionsProductsGeneral} from '../utils/productsgeneral';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

// ---------------------------------------------------------------------------------------------------------------------
// PRODUCTS - COMBINE (START)
// ---------------------------------------------------------------------------------------------------------------------

export const tryGetCombinedProducts = (referralType, coachId) => {
    return async (dispatch, getState) => {

        console.log("coachId", coachId);

        // Sessions
        let data2 = await dispatch(tryGetTeamMemberProductsWillow());
        let pastCalls = data2.pastCalls;
        let upcomingCalls = data2.upcomingCalls;


        // Products (Domain)
        // let productsDomain;
        // let productsDomainState = {};
        // if(env.CONSUMER.TEAM.PRODUCTS.DOMAIN_SOLD.SHOW) {
        //     const userId = getState().common.user.get("userId");
        //     productsDomain = data2.program;
        //     productsDomain = await checkRemainingSessionsProductsDomain(pastCalls, upcomingCalls, productsDomain, coachId, referralType, userId);
        //     productsDomain = {
        //         ...productsDomain,
        //         "productTitle": env.CONSUMER.TEAM.PRODUCTS.DOMAIN_SOLD.PRODUCT_TITLE,
        //         "sessionLengthInMinutes": env.CONSUMER.TEAM.PRODUCTS.DOMAIN_SOLD.PAID_SESSION_MINUTES,
        //     };
        //     productsDomainState = {
        //         "productsDomain": productsDomain,
        //         "hasProductsDomain": (referralType === "willow" && (productsDomain.coachId === coachId || productsDomain.coachId === null)),
        //     }
        // }

        // Products (Firm)
        // let productsWMC;
        // let productsWMCState = {};
        // if(env.CONSUMER.TEAM.PRODUCTS.COMPANY_SOLD.SHOW) {
        //     productsWMC = await dispatch(tryGetTeamMemberProductsWMC());
        //     productsWMC = await checkRemainingSessionsProductsWMC(upcomingCalls, productsWMC, coachId);
        //     if (productsWMC.length > 0) {
        //         productsWMC[0] = {
        //             ...productsWMC[0],
        //             "productTitle": env.CONSUMER.TEAM.PRODUCTS.COMPANY_SOLD.PRODUCT_TITLE,
        //             "sessionLengthInMinutes": env.CONSUMER.TEAM.PRODUCTS.COMPANY_SOLD.PAID_SESSION_MINUTES
        //         }
        //     }
        //     productsWMCState = {
        //         "productsWMC": productsWMC,
        //         "hasProductsWMC": (productsWMC.length > 0),
        //     }
        // }

        // Products (Coaches)
        let productsCoach;
        let productsCoachState = {};
        if(env.CONSUMER.TEAM.PRODUCTS.EXPERT_SOLD.SHOW) {
            productsCoach = await dispatch(tryGetTeamMemberProductsCoach());
            productsCoach = await checkRemainingSessionsProductsCoach(pastCalls, upcomingCalls, productsCoach, coachId);
            productsCoachState = {
                "productsCoach": productsCoach,
                "hasProductsCoach": (productsCoach.length > 0),
            }
        }

        // Products (General)
        let productsGeneral;
        let productsGeneralState = {};
        if(env.CONSUMER.TEAM.PRODUCTS.GENERAL.SHOW) {
            productsGeneral = await dispatch(tryGetTeamMemberProductsGeneral());
            productsGeneral = await checkRemainingSessionsProductsGeneral(pastCalls, upcomingCalls, productsGeneral);
            productsGeneral = {
                ...productsGeneral,
                "productTitle": env.CONSUMER.TEAM.PRODUCTS.GENERAL.PRODUCT_TITLE,
                "sessionLengthInMinutes": env.CONSUMER.TEAM.PRODUCTS.GENERAL.PAID_SESSION_MINUTES
            };
            productsGeneralState = {
                "productsGeneral": productsGeneral,
                "hasProductsGeneral": (productsGeneral.sessionsRemaining > 0)
            };
        }

        // Combine products
        // const allProducts = [productsDomainState.productsDomain, productsGeneralState.productsGeneral, ...productsCoachState.productsCoach, ...productsWMCState.productsWMC];
        const allProducts = [productsGeneralState.productsGeneral, ...productsCoachState.productsCoach];

        // Check no products
        // let hasNoProducts = true;
        // if(productsDomainState.hasProductsDomain !== null) {
        //     hasNoProducts = hasNoProducts && !productsDomainState.hasProductsDomain;
        // }
        // if(productsCoachState.hasProductsCoach !== null) {
        //     hasNoProducts = hasNoProducts && !productsCoachState.hasProductsCoach;
        // }
        // if(productsWMCState.hasProductsWMC !== null) {
        //     hasNoProducts = hasNoProducts && !productsWMCState.hasProductsWMC;
        // }
        // if(productsGeneralState.hasProductsGeneral !== null) {
        //     hasNoProducts = hasNoProducts && !productsGeneralState.hasProductsGeneral;
        // }

        // Return results
        const results = {
            "allProductsUnfiltered": allProducts,
            "allProducts": allProducts.filter(p => (p.sessionsRemaining > 0 || p.remainingSessions > 0 || p.serviceType === 'offline' || p.serviceType === 'download')),
            "allSessionProducts": allProducts.filter(p => ((p.sessionsRemaining > 0 || p.remainingSessions > 0) && p.serviceType === "online")),
            "hasNoSessionProducts": allProducts.filter(p => ((p.sessionsCompleted > 0 || p.completedSessions > 0) && p.serviceType === "online")).length === 0
        };
        return(results);

    }
};

// ---------------------------------------------------------------------------------------------------------------------
// PRODUCTS - COMBINE (END)
// ---------------------------------------------------------------------------------------------------------------------
