import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {v4 as uuid4} from 'uuid';
import Board from 'react-trello';

/* Components */
import {colors} from '../../../common/components/colors';
import {images} from '../../../common/components/images';
import {LogoLoading} from "../../../common/components/loading";
import {FontBody16, FontSubtitle24To20} from "../../../common/components/fonts";
import {Container, ContainerTop, FlexRow} from "../components/createcourse";
import {StyledBoard, Element} from '../components/layers';
import {EyeOutlined, EyeInvisibleOutlined, CaretDownFilled, CaretUpFilled, DeleteOutlined} from '@ant-design/icons'
import { SearchInputUncontrolled } from '../../../common/components/inputs';

/* Utils */
import {articleElements} from '../static/elementtypes';

/* Store */
import {setCoursesAddElement, setCourseCreate} from '../store/courses';

/* Containers */
import LayerPanelElement from './layerpanelelement';

/* Middleware */
import { tryGetInsightParentCategories, tryGetInsightSubCategories } from '../../../consumer/learn/middleware/insights';

const whoOptions = (who) => {
    return who.map(w => {
        return({
            value: {
                categoryId: w.categoryId
            },
            label: w.categoryName
        })
    });
};

class LayersPanel extends React.Component {
    state={
        isLoading: true, 
        hidden: false,
        elements: [],
        selected: null,
        data: {},
        constData: [],
        categories: [],
        selectedCategories: [],
        selectCategories: false,
    }

    componentDidMount() {
        this.setPreviousCategories()
        this.getCategories()
        return this.init()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.task !== this.props.task) {
            return this.init()
        }
    }

    init = async () => {
        let reqs = [{"name": "Cover", "image": "", "id": "img", "type": null, "key": uuid4(), "complete": (this.props.task.get("imageUrl") !== "" && this.props.task.get("imageUrl") && this.props.task.get("imageUrl") !== images.grayBackgroundImage)}]
        if (this.props.type === 'video') {
            reqs.push({"name": "Video", "image": "", "id": "video", "type": null, "key": uuid4(), "complete": (this.props.task.get("pages").get(0).get("elements").get(0).get("value") !== "" && this.props.task.get("pages").get(0).get("elements").get(0).get("value") !== null)})
        }
        reqs.push(
            {"name": "Title", "image": "", "id": "title", "type": null, "key": uuid4(), "complete": (this.props.task.get("title") !== "" && this.props.task.get("title") && this.props.task.get("title") !== "Untitled")}, 
            {"name": "Author", "image": "", "id": "author", "type": null, "key": uuid4(), "complete": (this.props.task.get("externalAuthor").get("firstName") !== "" && this.props.task.get("externalAuthor").get("firstName") !== null)},
            {"name": "Description", "image": "", "id": "desc", "type": null, "key": uuid4(), "complete": (this.props.task.get("description") !== "" && this.props.task.get("description") && this.props.task.get("description") !== "Description")}
        )
        const elements = 
            this.props.task.get(
                "pages").get(0).get(
                    "elements").toJS().filter(e => {return e.type !== 'hidden'}).map(e => {
                        return ({...e, ...this.typeToElement(e.type), "key": uuid4(), "complete": null})
            })
        const data = {
            lanes: 
                [{
                    id: 'lane1',
                    cards: elements.map(e => {return ({id: e.id, title: e.name})})
                }]
          }
        const constData = reqs.map(e => {
            return ({id: e.id, title: e.name, complete: e.complete})
        })
        this.setState({"selected": elements[elements.length - 1], "data": data, "constData": constData, "isLoading": false})
        
    }

    getCategories = async  () => {
        const parentCategories = await this.props.tryGetInsightParentCategories()
        const subCategories = await Promise.all(parentCategories.map(o => this.getChildCategories(o)));
        let h = []
        subCategories.forEach(c => {
            h.push(c.childCategories)
        })
        const formattedSubCategories = h.flat().map(c => {return ({categoryId: c.id, categoryName: c.title})})
        this.setState({"categories": formattedSubCategories})
    }

    setPreviousCategories = async () => {
        let cats = []
        if (this.props.course.get("categories")) {
            cats = await this.props.course.get("categories").map(c => {return ({categoryId: c.get("id"), categoryName: c.get("title")})}).toJS()
        }
        this.setState({'selectedCategories': whoOptions(cats)})

        const categoryIds = this.state.selectedCategories.map(c => c.value.categoryId)
        this.props.setCourseCreate({...this.props.course.toJS(), "categoryIds": categoryIds})
    }

    getChildCategories = async (o) => {
        return await this.props.tryGetInsightSubCategories(o.id)
    }

    setHidden = () => {
        this.setState({"hidden": !this.state.hidden})
    }

    typeToElement = (type) => {
        return articleElements.filter(a => {return a.type === type})[0]
    }

    deleteElement = async (cardId, laneId) => {
        await this.arrangeElements(cardId, null, false)
    }

    handleDragEnd = async (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
        await this.arrangeElements(cardId, position, true)
    }

    arrangeElements = async (cardId, position, move) => {
        const pages = this.props.task.get("pages")
        let elements = pages.get(0).get("elements").toJS()
        if (move) {
            if (this.props.type === 'video') {
                position = position + 1
            }
            const el = await elements.filter(e => {return cardId === e.id})[0] //get element to be reordered
            elements = await elements.filter(e => {return cardId !== e.id}) //remove from list
            await elements.splice(position, 0, el)
        } else {
            elements = await elements.filter(e => {return cardId !== e.id})
        }
        elements = await elements.map((e, i) => {
            return {...e, "id": i}
        })
        const page = {
            ...pages.get(0).toJS(),
            "elements": elements,
        }
        await this.props.setCoursesAddElement([page])
    }

    handleChangeCategories = async (e) => {
        const categoryIds = this.state.selectedCategories.map(c => c.value.categoryId)
        this.props.setCourseCreate({...this.props.course.toJS(), "categoryIds": [...categoryIds, e.value.categoryId]})
        await this.setState({selectedCategories: [...this.state.selectedCategories, e]})
    };

    deleteCategory = async (i) => {
        let categories = this.state.selectedCategories
        categories.splice(i, 1)
        const categoryIds = categories.map(c => c.value.categoryId)
        this.props.setCourseCreate({...this.props.course.toJS(), "categoryIds": categoryIds})
        await this.setState({selectedCategories: categories})
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <>
                    <Container backgroundColor={colors.white} border={colors.backgroundColor2} style={{rowGap: 0}}>
                        <ContainerTop style={{backgroundColor: colors.backgroundColor2, borderRadius: this.state.hidden ? '25px' : '25px 25px 0px 0px', paddingBottom: '10px'}}>
                            <FlexRow>
                                <FontSubtitle24To20>{'LAYERS'}</FontSubtitle24To20>
                                <div style={{cursor: 'pointer'}} onClick={() => {this.setHidden()}}><FontBody16>{this.state.hidden ? <><EyeOutlined />&nbsp;&nbsp;Show</> : <><EyeInvisibleOutlined />&nbsp;&nbsp;Hide</>}</FontBody16></div>
                            </FlexRow>
                        </ContainerTop>
                        {!this.state.hidden &&
                        <div style={{marginLeft: '20px'}}>
                            <br />
                            {this.state.constData.map((e, i) => {
                                return (
                                    <div key={i} style={{display: 'inline-flex', alignItems: 'center', justifyContent: "space-between", maxWidth: '250px', minWidth: '230px', color: colors.backgroundColor5, margin: "4px auto"}}>
                                        <FontBody16 style={{display: 'flex', alignItems: 'center'}}>    
                                            {e.complete ? <img src={images.progressCircleCompletedNavy} alt="Complete" /> : <img src={images.progressCircleNotCompletedNavy} alt="Incomplete" />}
                                            &nbsp;&nbsp;
                                            {e.title}
                                        </FontBody16>
                                    </div>
                                )
                            })}
                            <div key={uuid4()} style={{display: 'inline-flex', alignItems: 'center', justifyContent: "space-between", maxWidth: '250px', minWidth: '230px', color: colors.backgroundColor5, margin: "4px auto"}}>
                                <FontBody16 style={{display: 'flex', alignItems: 'center'}}>    
                                    <img src={this.state.selectedCategories.length > 0 ? images.progressCircleCompletedNavy : images.progressCircleNotCompletedNavy} alt="Incomplete" />
                                    <div onClick={() => {return this.setState({"selectCategories": !this.state.selectCategories})}} style={{cursor: 'pointer'}}>
                                        &nbsp;
                                        Select Categories
                                        &nbsp;
                                        {this.state.selectCategories ? <CaretUpFilled /> : <CaretDownFilled />}
                                    </div>
                                </FontBody16>
                            </div>
                            {this.state.selectCategories &&
                                <>
                                    <SearchInputUncontrolled
                                        title={"Categories"}
                                        options={whoOptions(this.state.categories)}
                                        id={'categories'}
                                        onChange={this.handleChangeCategories}
                                        placeholder={'Search categories'}
                                        value={""}
                                        containerStyles={{width: 'calc(100% - 20px)', marginTop: '15px', color: colors.backgroundColor5}}
                                    />
                                    {this.state.selectedCategories.map((c, i) => (
                                            <Element key={i} style={{cursor: 'default'}}>
                                                <FontBody16 style={{display: 'flex', alignItems: 'center'}}>    
                                                    <div style={{cursor: 'pointer'}} onClick={() => {this.deleteCategory(i)}}><DeleteOutlined /></div>
                                                    &nbsp;&nbsp;
                                                    {c.label}
                                                </FontBody16>
                                            </Element>
                                        )
                                    )}
                                </>
                                }
                            <StyledBoard bottom={true}>
                                <Board
                                    data={this.state.data}
                                    cardDraggable={true}
                                    onCardDelete={this.deleteElement}
                                    laneDraggable={false}
                                    hideCardDeleteIcon={false}
                                    components={{Card: LayerPanelElement}}
                                    handleDragEnd={this.handleDragEnd}
                                />
                            </StyledBoard>
                        </div>
                        }
                    </Container>
                </>
            )
        }
    }

}

const mapStateToProps = state => ({
    task: state.enterprise.curriculum.curriculum.get("taskCreate"),
    course: state.enterprise.curriculum.curriculum.get("courseCreate"),
});

const mapDispatchToProps = dispatch => ({
    setCoursesAddElement: (element) => dispatch(setCoursesAddElement(element)),
    tryGetInsightParentCategories: () => dispatch(tryGetInsightParentCategories()),
    tryGetInsightSubCategories: (id) => dispatch(tryGetInsightSubCategories(id)),
    setCourseCreate: (course) => dispatch(setCourseCreate(course))
});

export default connect(mapStateToProps, mapDispatchToProps)(LayersPanel);