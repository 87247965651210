import {axiosReq} from "../../../common/utils/axios";
import moment from "moment";
import {fromJS} from "immutable";

/* Store */
import { setEmailSending, setPurchaseLinkDownloading } from "../store/messages";
import { setClientsActivityAddRecord } from "../../store/clientsactivity";

/* Static */
import { devMessagesAddData, devMessagesDataConferenceId, devMessagesDataPurchaseLink} from '../static/devdata';


/* Supporting Functions */

export const tryGetCoachClientPurchaseLink = (consumerId) => {
    return async dispatch => {
        dispatch(setPurchaseLinkDownloading(true));
        const pastSessions = await axiosReq('apiv1/coach/clients/'+consumerId+'/program', "GET", {}, devMessagesDataConferenceId);
        let purchaseLink;
        const numPastCalls = fromJS(pastSessions).get("pastCalls").size;
        if(numPastCalls > 0) {
            const sessionId = fromJS(pastSessions).get("pastCalls").get(-1).get("id");
            purchaseLink = await axiosReq('apiv1/coach/clients/'+sessionId+'/get-purchase-link', "POST", {}, devMessagesDataPurchaseLink);
        } else {
            purchaseLink = {"purchaseToken": null, "purchaseUrl": null};
        }
        dispatch(setPurchaseLinkDownloading(false));
        return purchaseLink.purchaseToken;
    }
};

export const tryPostCoachClientMessages = (mediaType, content, subject, consumerId) => {
    return async dispatch => {
        dispatch(setEmailSending(true));
        const messagesAdd = await axiosReq('apiv1/coach/clients/'+consumerId+'/messages', "POST", {content: content, subject: subject, channel: mediaType}, devMessagesAddData(true, content, subject, mediaType));
        dispatch(setClientsActivityAddRecord({...messagesAdd, "activityType": mediaType, "orderDatetime": moment()}));
        return dispatch(setEmailSending(false));
    }
};

export const tryPostCoachCoachMessages = (mediaType, content, subject, consumerId) => {
    return async dispatch => {
        dispatch(setEmailSending(true));
        await axiosReq('apiv1/coach/coach/'+consumerId+'/messages', "POST", {content: content, subject: subject, channel: mediaType}, devMessagesAddData(true, content, subject, mediaType));
        return dispatch(setEmailSending(false));
    }
};