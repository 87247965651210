import React from 'react';
import styled from "styled-components";
import { colors } from './colors';
import { images } from './images';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-around;
    margin: auto auto 100px auto;
    max-width: 450px;
    padding-bottom: 10px;
`;

export const ContainerJCFS = styled(Container)`
    background-color: ${colors.white};
    justify-content: flex-start;
    row-gap: 15px;
    padding: 0px 50px;
    width: calc(100% - 100px);
    margin: 0 0 40px 0;
`;
export const Background = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.50);
    z-index: 20;
`;
export const SidebarRight = styled.div`
    background-color: ${colors.white};
    bottom: 0;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    width: 500px;
    z-index: 21;
    @media screen and (max-width: 500px) {
        left: 0;
        width: auto;
    }
`;

export const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`;
export const FlexColPadding = styled(FlexCol)`
    padding: 20px;
`;
export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export const PillBar = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
`;
export const Pill = styled.div`
    cursor: pointer;
    padding: 5px 10px;
    text-align: center;
    ${props => props.active === false} {
        background-color: ${colors.backgroundColor4};
        border-radius: 25px;
    }
`;

export const TextAlignCenter = styled.div`
    text-align: center;
    margin-bottom: 20px;
`;

export const Pointer = styled.div`
    cursor: pointer;
`;