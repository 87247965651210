import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {
    setIframeAuthTestUserExists,
    setIframeAuthCheckHasAccountError,
    setIframeAuthLogin,
    setIframeAuthLoginError,
    setIframeAuthSigningUp,
    setIframeAuthSigningUpError,
    setIframeAuthNameSaving,
    setIframeAuthPhoneVerified,
    setIframeAuthPhoneVerifying,
    setIframeAuthPhoneVerifyResending,
    setIframeAuthTopicPhoneSaving,
    setIframeAuthUpdateUserError,
    setIframeAuthUpdateUser,
    setIframeAuthCreateUser
} from "../store/auth";
import {setLoggedIn} from "../../../routes/store/user";

/* Static */
import {
    devIframeAuthData,
    devIframeVerificationStatus,
    devIframeLogin,
    devUpdateUserData
} from "../static/devdata";

// export const trySignUpTestExists = (email, phone) => {
//     return async dispatch => {
//         dispatch(setIframeAuthTestUserExists(true));
//         const signUp = await axiosReq('apiv1/consumer/exists', "POST", {"email": email, "phone": phone}, {});
//         if(signUp.alreadyRegistered) {
//             dispatch(setIframeAuthCheckHasAccountError("User already exists."));
//         } else {
//             dispatch(setIframeAuthCheckHasAccountError(null));
//         }
//         return dispatch(setIframeAuthTestUserExists(false));
//     }
// };

// export const tryScheduleLogin = (email, password) => {
//     return async dispatch => {
//         dispatch(setIframeAuthLogin(true));
//         const login = await axiosReq('api-login', "POST", {"email": email, "password": password}, devIframeLogin(false));
//         if(login.hasOwnProperty("error")) {
//             await dispatch(setIframeAuthLoginError(login.error));
//         } else {
//             await dispatch(setIframeAuthLoginError(null));
//         }
//         dispatch(setIframeAuthLogin(false));
//     }
// };

export const tryGetVerifyPhone = () => {
    return async dispatch => {
        dispatch(setIframeAuthPhoneVerifying(true));
        const verified = await axiosReq('apiv1/consumer/settings/onboarding/phone-verification-status', "GET", {}, devIframeVerificationStatus);
        await dispatch(setIframeAuthPhoneVerified(verified.status)); // Output status = "no_verification_code_sent", "not_verified", "verified"
        dispatch(setIframeAuthPhoneVerifying(false));
    }
};

export const tryPostResendVerifySMS = () => {
    return async dispatch => {
        dispatch(setIframeAuthPhoneVerifyResending(true));
        await axiosReq('apiv1/consumer/settings/onboarding/resend-verification-sms', "POST", {}, {});
        dispatch(setIframeAuthPhoneVerifyResending(false));
    }
};

// export const tryPostFirstLastName = (firstName, lastName) => {
//     return async dispatch => {
//         dispatch(setIframeAuthNameSaving(true));
//         const name = await axiosReq('apiv1/consumer/settings/onboarding/name', "POST", {"firstName": firstName, "lastName": lastName}, {});
//         dispatch(setIframeAuthNameSaving(false));
//         if(name.hasOwnProperty("error")) {
//             return({"error": "Issue with setting first and last name for new user."})
//         }
//         return({"error": null});
//     }
// };

// export const tryPostTopicAndPhone = (topic, phone) => {
//     return async dispatch => {
//         dispatch(setIframeAuthTopicPhoneSaving(true));
//         const topicPhone = await axiosReq('apiv1/consumer/settings/onboarding/topic-and-phone', "POST", {"topic": topic, "phone": phone}, {});
//         dispatch(setIframeAuthTopicPhoneSaving(false));
//         if(topicPhone.hasOwnProperty("error")) {
//             return({"error": "Phone number already registered."})
//         }
//         return({"error": null});
//     }
// };


// export const tryUpdateUser = (firstName, lastName, email, phone, timeZone) => {
//     return async dispatch => {
//         dispatch(setIframeAuthUpdateUser(true));
//         const user = await axiosReq('apiv1/settings/general', "PUT", {"companyName": "", "first": firstName, "last": lastName, "phone": phone, "email": email, "timeZone": timeZone}, devUpdateUserData(firstName, lastName, phone, email, timeZone));
//         if(user.hasOwnProperty("error")) {
//             await dispatch(setIframeAuthUpdateUserError(user.error));
//         } else {
//             await dispatch(setIframeAuthUpdateUserError(null));
//         }
//         dispatch(setIframeAuthUpdateUser(false));
//     }
// };

// export const tryRegisterUser = (firstName, lastName, email, phone, timeZone) => {
//     return async (dispatch, getState) => {
//         dispatch(setIframeAuthCreateUser(true));
//         const userId = getState().common.user.get("userId");
//         if (userId === null) {
//             let body = {'firstName': firstName, 'lastName': lastName, 'email': email, 'phoneNumber': phone, 'timeZone': timeZone};
//             const user = await axiosReq('apiv1/public/subscription/quick-subscribe', "POST", body, {});
//             dispatch(setIframeAuthCreateUser(false));
//             if(user.hasOwnProperty("error")) {
//                 return({"error": "Issue registering user."})
//             } else {
//                 return({"error": null});
//             }
//         } else {
//             dispatch(setIframeAuthCreateUser(false));
//             return({"error": "User logged in"});
//         }
//
//     }
// };
