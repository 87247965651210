import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';

/* Components */
import {LogoLoading} from "../../../../common/components/loading";

/* Middleware */
import {tryGetExpertProfile} from '../middleware/expertprofile';

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

const Image = styled.img`
    width: 85px;
    height: 85px;
    border-radius: 6px;
    object-fit: cover;
    object-position: 0 0;
`;

class SchedulerAvatar extends React.Component {
    state = {
        photo: '',
        isLoading: true,
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.props.tryGetExpertProfile();
        await this.setState({
            "photo": this.props.profile.get("photo"),
            "isLoading": false
        });
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <br />
                    <Image src={this.state.photo} alt={"photo"} />
                    <br />
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    profile: state.enterprise.scheduler.expertProfile.get("expertProfile"),
});

const mapDispatchToProps = dispatch => ({
    tryGetExpertProfile: () => dispatch(tryGetExpertProfile())
});

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerAvatar);
