import React from 'react';
import moment from "moment-timezone";
import styled from 'styled-components';

/* Components */
import { FontSubtitle40, FontBody18 } from '../../../../common/components/fonts';
import { colors } from '../../../../common/components/colors';

/* Utils */
import {timeZoneGreetings} from "../../../../common/utils/timezone2";

const Welcome = styled.div`
    color: ${colors.primary100};
`;
const Subtitle = styled(FontBody18)`
    margin-top: 10px;
`;

class FirmDashboardWelcome extends React.Component {
    render() {
        return (
            <Welcome>
                <FontSubtitle40 spaced={true}>{timeZoneGreetings(moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone)).toUpperCase()}</FontSubtitle40>
                <Subtitle>Here’s the latest with your team at Willow</Subtitle>
            </Welcome>
        )
    }
}

export default FirmDashboardWelcome;
