import React from 'react';

/* Containers */
import CurrentPlan from './currentplan';
import CurrentPlanOriginal from './currentplan_original';
import PaymentMethod from './paymentmethod';
import PaymentMethodOriginal from './paymentmethod_original';
import History from './history';
import HistoryOriginal from './history_original';
import Cancel from './cancel';
import Add from './add';
import Edit from './edit';
import DeleteModal from './deletemodal';

/* Components */
import { FlexCol } from '../../../common/components/layout';


class BillingFlow extends React.Component {
    static defaultProps = {
        customPlan: true,
        customHistory: true,
        customPayment: true
      };

    state={"showCancel": false, "planId": "", "planName": "", "showEdit": false, "showAdd": false, "showDelete": false, "paymentId": "", "cardEditing": {}};

    showCancel = async (status, id, name) => {
        this.setState({"showCancel": status, "planId": id, "planName": name})
    };

    showDelete = async (status, id) => {
        this.setState({"showDelete": status, "paymentId": id})
    };

    showAdd = async (status) => {
        this.setState({"showAdd": status})
    };

    showEdit = async (status, cardEditing) => {
        this.setState({"showEdit": status, "cardEditing": cardEditing})
    };


    render() {
        return(
            <FlexCol style={{ marginTop: '35px' }}>
                {this.props.customPlan
                    ? <CurrentPlan showCancel={this.showCancel} />
                    : <CurrentPlanOriginal showCancel={this.showCancel} />
                }
                {this.props.customPayment
                    ? <PaymentMethod showDelete={this.showDelete} showAdd={this.showAdd} showEdit={this.showEdit} />
                    : <PaymentMethodOriginal showDelete={this.showDelete} showAdd={this.showAdd} showEdit={this.showEdit} />
                }
                {this.props.customHistory
                    ? <History />
                    : <HistoryOriginal/>
                } 
               
                {this.state.showAdd && <Add showAdd={this.showAdd} />}
                {this.state.showEdit && <Edit showEdit={this.showEdit} cardEditing={this.state.cardEditing} />}
                {this.state.showCancel && <Cancel showCancel={this.showCancel} planId={this.state.planId} planName={this.state.planName}/>}
                {this.state.showDelete && <DeleteModal showDelete={this.showDelete} id={this.state.paymentId}/>}
            </FlexCol>
        )
    }

}

export default BillingFlow;
