import React from 'react';
import styled from 'styled-components';

/* Components */
import {colors} from "../../../common/components/colors";
import {FontBody24} from '../../../common/components/fonts';
import {ContactTitle} from "./components";

const Show = styled.div`
  display: ${({toggle}) => toggle ? 'flex' : 'none'};
  flex-direction: column;
  gap: 20px;
`;
const PreviewWhite = styled(FontBody24)`
  color: ${props => props.color}B3;
  line-height: 2;
  margin: 20px 0 40px 0;
  & strong {
    color: ${props => props.color};
  }
  @media screen and (max-width: 1680px) {
    font-size: 20px !important;
  }
  @media screen and (max-width: 748px) {
    font-size: 16px !important;
  }
`;

ContactSuccess.defaultProps = {
    show:  false,
    advisorName: "",
}

export default function ContactSuccess(props) {
    const show = props.show;
    const advisorName = props.advisorName;

    return (
        <Show toggle={show}>
            <ContactTitle color={colors.white}>Confirmed!</ContactTitle>
            <PreviewWhite color={colors.white}>A member of the Willow Matching team will be reaching out to you shortly to review your matches with you and answer any questions you may have. In the meantime, please reach out to us at <a style={{color: "#ffffffb3", "textDecoration": "underline"}} href={"mailto:members@trustwillow.com"}>members@trustwillow.com</a>.</PreviewWhite>
        </Show>
    );
}