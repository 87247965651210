import {axiosReq} from "../../../common/utils/axios";

import {setCheckoutPaymentMethods, setCheckoutPaymentMethodsFetching, setCheckoutPurchaseHistory, setCheckoutPurchaseHistoryFetching, setCheckoutRegisterUserFetching, setCheckoutRegisterUser, setCheckoutPurchaseFetching, setCheckoutRepurchaseFetching, setCheckoutPurchaseIntentFetching, setCheckoutProductFetching, setCheckoutProduct, setCheckoutStripeIdFetching, setCheckoutStripeId} from '../store/checkout';

import {devProductFromPlanId, devQuickSubscribe, devPurchaseSubscription, devPurchaseIntent, devPurchaseHistoryData, devPaymentMethodsData, devHrCodeValidation} from '../static/devdata';
import {clientSessions} from '../static/clientsessions';
import {advisorSessions} from '../static/advisorsessions';
import {willowSessions} from '../static/willowsessions';
import {trainingSessions} from '../static/trainingsessions';
import {willowProgram} from '../static/willowprogram';
import {willowOnDemand} from '../static/willowondemand';
import {willowEmpowered} from '../static/willowempowered';
import {willowCertified} from '../static/willowcertified';
import {willowCertifiedRenewal} from '../static/willowcertifiedrenewal';
import {willowCertifiedFree} from '../static/willowcertifiedfree';
import {clientAcquisition} from '../static/clientacquisition';

export const tryGetProductFromPlanId = (planId) => {
    return async dispatch => {
        dispatch(setCheckoutProductFetching(true));
        let product = await axiosReq('apiv1/consumer/coach/products/by-plan/'+planId, "GET", {}, devProductFromPlanId);
        await dispatch(setCheckoutProduct(product));
        dispatch(setCheckoutProductFetching(false));
        return product;
    }
};

export const tryGetProductClientSession = (quantity) => {
    return async dispatch => {
        dispatch(setCheckoutProductFetching(true));
        let product = await clientSessions(quantity === null ? 1 : quantity);
        await dispatch(setCheckoutProduct(product));
        dispatch(setCheckoutProductFetching(false));
        return product;
    }
};

export const tryGetProductAdvisorSession = (quantity) => {
    return async dispatch => {
        dispatch(setCheckoutProductFetching(true));
        let product = await advisorSessions(quantity === null ? 1 : quantity);
        await dispatch(setCheckoutProduct(product));
        dispatch(setCheckoutProductFetching(false));
        return product;
    }
};

export const tryGetProductTrainingSession = (quantity) => {
    return async dispatch => {
        dispatch(setCheckoutProductFetching(true));
        let product = await trainingSessions(quantity === null ? 1 : quantity);
        await dispatch(setCheckoutProduct(product));
        dispatch(setCheckoutProductFetching(false));
        return product;
    }
};

export const tryGetProductWillowSession = (quantity) => {
    return async dispatch => {
        dispatch(setCheckoutProductFetching(true));
        let product = await willowSessions(quantity === null ? 1 : quantity);
        await dispatch(setCheckoutProduct(product));
        dispatch(setCheckoutProductFetching(false));
        return product;
    }
};

export const tryGetProductWillowProgram = (title, price) => {
    return async dispatch => {
        dispatch(setCheckoutProductFetching(true));
        let product = await willowProgram(title, price);
        await dispatch(setCheckoutProduct(product));
        dispatch(setCheckoutProductFetching(false));
        return product;
    }
};

export const tryGetProductWillowOnDemand = () => {
    return async dispatch => {
        dispatch(setCheckoutProductFetching(true));
        let product = await willowOnDemand();
        await dispatch(setCheckoutProduct(product));
        dispatch(setCheckoutProductFetching(false));
        return product;
    }
};

export const tryGetProductWillowEmpowered = () => {
    return async dispatch => {
        dispatch(setCheckoutProductFetching(true));
        let product = await willowEmpowered();
        await dispatch(setCheckoutProduct(product));
        dispatch(setCheckoutProductFetching(false));
        return product;
    }
};

export const tryGetProductClientAcquisition = (price) => { 
    return async dispatch => {
        dispatch(setCheckoutProductFetching(true));
        console.log("product fetched true")
        let product = await clientAcquisition(price);
        console.log("product willowcertified price")
        await dispatch(setCheckoutProduct(product));
        console.log("checkout product")
        dispatch(setCheckoutProductFetching(false));
        console.log("product feteched false")
        return product;
    }
};

export const tryGetProductCertification = (certType, price) => {
    return async dispatch => {
        dispatch(setCheckoutProductFetching(true));
        console.log("product fetched true")
        let product = await willowCertified(certType, price);
        console.log("product willowcertified price")
        await dispatch(setCheckoutProduct(product));
        console.log("checkout product")
        dispatch(setCheckoutProductFetching(false));
        console.log("product feteched false")
        return product;
    }
};

export const tryGetProductCertificationFree = (certType) => {
    return async dispatch => {
        dispatch(setCheckoutProductFetching(true));
        console.log("product fetched true")
        let product = await willowCertifiedFree(certType);
        console.log("product willowcertified free")
        await dispatch(setCheckoutProduct(product));
        console.log("checkout product")
        dispatch(setCheckoutProductFetching(false));
        console.log("product feteched false")
        return product;
    }
};

export const tryGetProductCertificationRenewal = (certType) => {
    return async dispatch => {
        dispatch(setCheckoutProductFetching(true));
        let product = await willowCertifiedRenewal(certType);
        await dispatch(setCheckoutProduct(product));
        dispatch(setCheckoutProductFetching(false));
        return product;
    }
};

// export const tryRegisterUserGetStripeId = (register, planId) => {
//     return async dispatch => {
//         dispatch(setCheckoutStripeIdFetching(true));
//         let body = register === null ? {} : {'firstName': register.firstName, 'lastName': register.lastName, 'email': register.email, 'phone_number': register.phone};
//         const stripeId = await axiosReq('apiv1/public/coaching-products/purchase-by-plan-id/'+planId, "POST", body, {});
//         await dispatch(setCheckoutStripeId(stripeId));
//         return dispatch(setCheckoutStripeIdFetching(false));
//     }
// };

export const tryRegisterUserForPurchase = (firstName, lastName, email, phone, timeZone, hrCode, coachId) => {
    return async (dispatch, getState) => {
        dispatch(setCheckoutRegisterUserFetching(true));
        let body;
        if (hrCode !== "" && hrCode !== null) {
            body = {'firstName': firstName, 'lastName': lastName, 'email': email, 'phoneNumber': phone, 'timeZone': timeZone, "hrCode": hrCode, ...coachId}
        } else {
            body = {'firstName': firstName, 'lastName': lastName, 'email': email, 'phoneNumber': phone, 'timeZone': timeZone, ...coachId}
        }
        const signUpIsExclusive = getState().common.user.get("signUpIsExclusive");
        if(signUpIsExclusive) {
            body = {...body, "isExclusive": true};
        }
        const stripe = await axiosReq('apiv1/public/subscription/quick-subscribe', "POST", body, devQuickSubscribe);
        await dispatch(setCheckoutRegisterUser(stripe.userId));
        return dispatch(setCheckoutRegisterUserFetching(false));
    }
};

export const tryValidateCode = (code) => {
    return async dispatch => {
        dispatch(setCheckoutPurchaseFetching(true));
        const isValid = await axiosReq('apiv1/public/subscription/get-wmc-by-code', "POST", {'hrCode': code}, devHrCodeValidation);
        dispatch(setCheckoutPurchaseFetching(false));
        if (isValid.hasOwnProperty("id")) {
            return code
        } else {
            return null
        }
    }
};

export const tryPurchase = (paymentMethodId, planId) => {
    return async dispatch => {
        dispatch(setCheckoutPurchaseFetching(true));
        const purchase = await axiosReq('apiv1/consumer/purchase/subscription-with-stripe-payment', "POST", {'paymentMethodId': paymentMethodId, 'planId': planId}, devPurchaseSubscription);
        dispatch(setCheckoutPurchaseFetching(false));
        return purchase;
    }
};

export const tryRepurchase = (paymentMethodId, invoiceId) => {
    return async dispatch => {
        dispatch(setCheckoutRepurchaseFetching(true));
        const repurchase = await axiosReq('apiv1/consumer/purchase/subscription-with-stripe-payment-by-invoice', "POST", {'paymentMethodId': paymentMethodId, 'invoiceId': invoiceId}, devPurchaseSubscription);
        // /retry-invoice = customerId, paymentMethodId, invoiceId
        dispatch(setCheckoutRepurchaseFetching(false));
        return repurchase;
    }
};

const certTypePrice = {
    "CAN": {
        "certificationProgramId": 4,
        "certificationPriceStripeIds": {
            "cert1": "price_1NxsiVBPDoWM9BMe1NpJbvKX",
            "cert400": "price_1NxsiVBPDoWM9BMeCSi74Oaj",
            "cert600": "price_1NxsiVBPDoWM9BMePcnRTOfA",
            "cert800": "price_1NxsiVBPDoWM9BMeOf6NGXAg",
            "cert900": "price_1O1x1QBPDoWM9BMeYtxISeb6",
            "cert1000": "price_1NxsiVBPDoWM9BMeR9vNZVho",
            "cert1200": "price_1NxsiVBPDoWM9BMe8gQX4VCf",
            "renewal": "price_1Nxsm9BPDoWM9BMeW6cL2fH5"
        }
    },
    "CAW": {
        "certificationProgramId": 3,
        "certificationPriceStripeIds": {
            "cert1": "price_1MhbSIBPDoWM9BMeq4Q2vy5p",
            "cert400": "price_1MbpuZBPDoWM9BMeT3xtqSFu",
            "cert600": "price_1MgsM4BPDoWM9BMek42KK1Y5",
            "cert800": "price_1MbpuMBPDoWM9BMeinsgHB7u",
            "cert900": "price_1OCpUzBPDoWM9BMeched5UKR",
            "cert1000": "price_1MgsMHBPDoWM9BMeYnAZ0mLI",
            "cert1200": "price_1Mb5YaBPDoWM9BMehD4Bk77E",
            "renewal": "price_1MbpyFBPDoWM9BMefdTS7RWz"
        }
    }
}

const isCertPlanIdAndPriceId = async (planId, quantity) => {
    if(planId.includes("certification_session")) {
        let certType = planId.substring(0, 3).toUpperCase();
        let split = planId.split("_");
        let price = split[split.length - 1];
        return({
            "certificationProgramId": certTypePrice[certType].certificationProgramId,
            "certificationPriceStripeId": certTypePrice[certType].certificationPriceStripeIds[price],
        })
    } else {
        return({
            "quantity": quantity === null ? 1 : quantity,
            'planId': planId
        })
    }
}

export const tryPurchaseIntent = (planId, paymentMethodId, quantity, webinarProgramId, metadata) => {
    return async dispatch => {
        dispatch(setCheckoutPurchaseIntentFetching(true));
        const setPlanId = await isCertPlanIdAndPriceId(planId, quantity);
        const setPaymentMethodId = paymentMethodId === null ? {} : {'paymentMethodId': paymentMethodId};
        const setWebinarProgramId = webinarProgramId === null ? {} : {'programId': webinarProgramId};
        const intent = await axiosReq('apiv1/consumer/purchase/generate-payment-intent', "POST", {...setPlanId, 'metadata': metadata, ...setPaymentMethodId, ...setWebinarProgramId}, devPurchaseIntent);
        dispatch(setCheckoutPurchaseIntentFetching(false));
        return intent;
    }
};

export const tryGetPurchaseHistory = () => {
    return async dispatch => {
        dispatch(setCheckoutPurchaseHistoryFetching(true));
        const history = await axiosReq('apiv1/consumer/purchase/get-purchase-history', "GET", {}, devPurchaseHistoryData);
        await dispatch(setCheckoutPurchaseHistory(history));
        await dispatch(setCheckoutPurchaseHistoryFetching(false));
        return history;
    }
};

export const tryGetPaymentMethods = () => {
    return async dispatch => {
        dispatch(setCheckoutPaymentMethodsFetching(true));
        const paymentMethod = await axiosReq('apiv1/consumer/settings/payments/get-payment-methods', "GET", {}, devPaymentMethodsData);
        await dispatch(setCheckoutPaymentMethods(paymentMethod));
        await dispatch(setCheckoutPaymentMethodsFetching(false));
        return paymentMethod.data;
    }
};

// import { loadStripe } from "@stripe/stripe-js";
// export const tryGetListingsCheckout = (planId) => {
//     return async dispatch => {
//         dispatch(setListingsCheckoutFetching(true));
//         const session = await axiosReq('apiv1/consumer/purchase/stripe-checkout-session-by-plan/'+planId, "POST", {}, devIframeListingsCheckout({}));
//         dispatch(setListingsCheckoutFetching(false));
//         const stripePromise = loadStripe(session.stripeApiPublishableKey);
//         const stripe = await stripePromise;
//         const result = await stripe.redirectToCheckout({sessionId: session.stripeCheckoutSessionId});
//         if (result.error) {
//             console.log(result.error.message);
//         }
//     }
// };

// apiv1/consumer/coach/<int:coach_id>/get-all-products
// apiv1/consumer/coach/products/<int:product_id>