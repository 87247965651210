import { Map } from 'immutable';

/* Immutable */

const IFRAME_SIGNUP_WMC_USER_ERROR = 'IFRAME_SIGNUP_WMC_USER_ERROR';
const IFRAME_SIGNUP_WMC_USER_CREATING = 'IFRAME_SIGNUP_WMC_USER_CREATING';
const IFRAME_SIGNUP_WMC_USER_STORE_RESET = 'IFRAME_SIGNUP_WMC_USER_STORE_RESET';

/* Actions */

export const setSignupWMCUserCreating = (status) => ({'type': IFRAME_SIGNUP_WMC_USER_CREATING, 'status': status});
export const setSignupWMCUserError = (error) => ({'type': IFRAME_SIGNUP_WMC_USER_ERROR, 'error': error});
export const setSignupWMCUserStoreReset = () => ({'type': IFRAME_SIGNUP_WMC_USER_STORE_RESET});

/* Initial State */

const initialState = Map({
    signupWMCUserCreating: false,
    signupWMCUserError: "",
});

/* Reducer */

const iframeSignupWMCUserReducer = (state=initialState, action) => {
    switch (action.type) {
        case IFRAME_SIGNUP_WMC_USER_CREATING:
            return state.merge({'signupWMCUserCreating': action.status});
        case IFRAME_SIGNUP_WMC_USER_ERROR:
            return state.merge({'signupWMCUserError': action.error});
        case IFRAME_SIGNUP_WMC_USER_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default iframeSignupWMCUserReducer;
