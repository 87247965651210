import React from 'react';
import styled from 'styled-components';
import {Link, withRouter} from "react-router-dom";

/* Components */
import {colors} from '../../../common/components/colors';
import {FontBody18, FontTitle21, FontBody16} from '../../../common/components/fonts';
import {InlineBlock, SaveSection} from "../components/clientscreate";
import {ButtonTertiary} from "../../../common/components/buttons";
import {AudioFilled, VideoCameraFilled} from "@ant-design/icons";

const Box = styled.div`
    border: 6px solid transparent;
    background-color: ${colors.border100};
    border-radius: 10px;
    padding: 30px;
    max-width: 200px;
    width: calc(100% - 72px);
    color: ${colors.primary100};
    display: inline-block;
    cursor: pointer;
    margin: 0px 30px 30px 0px;
    &:hover {
        border: 6px solid ${colors.action100};
    }
    @media screen and (max-width: 640px) {
        margin: 0px auto 30px auto;
        display: block;
    }
`;
const Icon = styled.div`
    width: 60px;
    height: 60px;
    font-size: 40px;
    display: inline-block;
    vertical-align: top;
    padding-top: 4px;
    color: ${colors.secondary100};
`;

class MeetingsCreate extends React.Component {
    render() {
        return(
            <>
                <br />
                <FontBody18>Which type of meeting?</FontBody18>
                <br />
                <Box onClick={() => {return this.props.history.push("/meetings/create/call")}}>
                    <Icon><VideoCameraFilled /></Icon>
                    <FontTitle21>Video Call</FontTitle21>
                    <FontBody16>Schedule a meeting</FontBody16>
                </Box>
                <Box onClick={() => {return this.props.history.push("/meetings/create/webinar")}}>
                    <Icon><AudioFilled /></Icon>
                    <FontTitle21>Webinar</FontTitle21>
                    <FontBody16>Schedule a group event</FontBody16>
                </Box>
                <SaveSection>
                    <InlineBlock><Link to={"/meetings"}><ButtonTertiary canSubmit={false} label={"Cancel"} /></Link></InlineBlock>
                </SaveSection>
            </>
        )
    }
}

export default withRouter(MeetingsCreate);
