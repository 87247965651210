import React from 'react';
import styled from 'styled-components';
import {Link, withRouter} from 'react-router-dom';
import {connect} from "react-redux";

/* Components */
import {colors} from '../../../../common/components/colors';
import {FontBody24, FontSubtitle40} from '../../../../common/components/fonts';
import {images} from '../../../../common/components/images';
import {ButtonPrimary, ButtonTertiary} from '../../../../common/components/buttons';

/* Containers */
import FirmCoachRecordNav from '../components/recordnav';

/* Middleware */
import {tryGetCoachingGeneralSessions} from "../middleware/coaches";

const TopSegment = styled.div`
    background-image: url(${images.firmBackgroundTop});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    left: -60px;
    right: 0;
    top: -90px;
    bottom: 0;
    padding: 90px 60px 0 60px;
    width: 100%;
    @media screen and (max-width: 748px) {
        left: -30px;
        right: -30px;
        top: -30px;
        bottom: 0;
        padding: 30px 30px 0 30px;
    }
`;
const Back = styled(Link)`
  width: fit-content;
  color: ${colors.primary100};
`;
const CoachPreview = styled.div`
  margin: 40px 0 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 60px;
`;
const IntroWithNav = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 90px;
`;
const Intro = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 60px;
  justify-content: space-between;
`;
const AddAppointmentsButton = styled(Link)`
  margin-top: 30px;
  width: fit-content;
  & div {
    font-size: 20px;
  }
`;
const AddAppointmentsButtonA = styled.div`
  margin-top: 30px;
  width: fit-content;
  & div {
    font-size: 20px;
  }
`;
const CoachName = styled(FontSubtitle40)`
  margin: 30px 0 10px 0;
  word-break: break-word;
`;
const CoachSubtitle = styled(FontBody24)`
  margin-bottom: 5px;
`;
const Photo = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 200px;
  border: 1px solid ${colors.border100};
  object-fit: cover;
  object-position: center center;
`;
const Spacer = styled.div`
    margin-bottom: 40px;
`;
const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 30px;
`;

class FirmCoachRecordIntro extends React.Component {
    static defaultProps = {
        profile: {
            id: null,
            photo: "",
            firstName: "",
            lastName: ""
        },
        categories: "",
        isNew: false
    }

    state = {"sessionsBookable": 0}

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const sessionsPrePurchased = await this.props.tryGetCoachingGeneralSessions();
        this.setState({"sessionsBookable": sessionsPrePurchased})
    }

    checkBuySessions = () => {
        if(this.state.sessionsBookable === 0) {
            window.location.href = 'https://pay.trustwillow.com/b/14k29y5Aq3jzcucdR9?client_reference_id='+this.props.userId
        } else {
            this.props.history.push("/my-certificates/my-coaches/"+this.props.profile.id+"/appointment")
        }
    }

    render() {
        return (
            <TopSegment>
                <FontBody24><Back to={"/my-certificates/my-coaches"}><img src={images.chevronLeft} alt={"<"} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Back</Back></FontBody24>
                <CoachPreview>
                    <Photo src={this.props.profile.photo} alt={""} />
                    <IntroWithNav>
                        <Intro>
                            <div>
                                <CoachName spaced={true}>{this.props.profile.firstName.toUpperCase()} {this.props.profile.lastName.toUpperCase()}</CoachName>
                                <CoachSubtitle>{this.props.categories}</CoachSubtitle>
                            </div>
                            <Buttons>
                                <AddAppointmentsButtonA onClick={this.checkBuySessions}>
                                    <ButtonPrimary canSubmit={false} label={"Book appointment"}/>
                                </AddAppointmentsButtonA>
                                {!this.props.isNew &&
                                    <AddAppointmentsButton to={"/my-certificates/my-coaches/"+this.props.profile.id+"/messages"}>
                                        <ButtonTertiary canSubmit={false} label={"Message"}/>
                                    </AddAppointmentsButton>
                                }
                            </Buttons>
                        </Intro>
                        <div>
                            {this.props.isNew
                                ? <Spacer />
                                : <FirmCoachRecordNav coachId={this.props.profile.id} />
                            }
                        </div>
                    </IntroWithNav>
                </CoachPreview>
            </TopSegment>
        )
    }
}

const mapStateToProps = state => ({
    userId: state.common.user.get("userId"),
})

const mapDispatchToProps = dispatch => ({
    tryGetCoachingGeneralSessions: () => dispatch(tryGetCoachingGeneralSessions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FirmCoachRecordIntro));