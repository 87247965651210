import React from 'react';
import validator from 'validator';

/* Components */
import {TextInput} from "../../../common/components/inputs";
import {LogoLoading} from "../../../common/components/loading";

class MeetingsCreateVideoUrl extends React.Component {
    state = {
        platform: "willow",
        url: null,
        urlValid: true,
        isLoading: true
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            'platform': this.props.videoPlatform.platform,
            "url": this.props.videoPlatform.url,
            "urlValid": this.props.videoPlatform.urlValid,
            "isLoading": false
        });
    };

    handleChangeVideoUrl = async e => {
        await this.setState({
            'platform': this.props.videoPlatform.platform,
            'url': e.target.value,
            'urlValid': validator.isURL(e.target.value),
        });
        await this.props.onValidate(this.state);
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <TextInput
                    title={"Zoom URL*"}
                    valid={this.state.urlValid}
                    warning={"Required"}
                    id={"url"}
                    onChange={this.handleChangeVideoUrl}
                    placeholder={"https://..."}
                    value={this.state.url}
                />
            )
        }
    }
}

export default MeetingsCreateVideoUrl;
