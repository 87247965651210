import React from 'react';

/* Navs */
import Nav from "../navbars/containers/nav";
import {PageContentContainer, PageFull, PageContent} from "../navbars/components/navlayouts";
import ReferralPage from './containers/referralpage';

const FirmReferral = () => (
    <>
        <Nav />
        <PageFull>
            <PageContentContainer fullPage={true}>
                <PageContent>
                    <ReferralPage />
                </PageContent>
            </PageContentContainer>
        </PageFull>
    </>
);

export default FirmReferral;