import React from 'react';
import { Switch } from 'react-router-dom';

/* Containers */
import PublicRoute from '../../../routes/containers/routepublic';

/* Pages - Iframes */
import IFrameSchedule from '../../../iframes/scheduler/page';
import IFrameReschedule from '../../../iframes/rescheduler/page';
import IFrameProfile from '../../../iframes/profile/page';
import IFrameWillowProfile from '../../../iframes/willowprofile/page';
import IFrameShop from '../../../iframes/shop/page';
import IFrameListings from '../../../iframes/listings/page';
import IFrameWebinars from '../../../iframes/webinars/page';
import IFrameCheckout from '../../../iframes/checkout/page';
import IFrameSMS from '../../../iframes/smsbytoken/page';
import IframeRatings from '../../../iframes/ratings/page';
import IframeCheckoutWillowPackages from '../../../iframes/checkoutwillow/containers/packageroom';
import IframeCheckoutWillowReceipt from '../../../iframes/checkoutwillow/containers/receiptroom';
import IframeError from "../../../iframes/error/page";
import IframeExpertCreateUser from "../../../iframes/signupexpert/page";
import IframeWMCCreateUser from "../../../iframes/signupwmcuser/page";
import IframeMatching from '../../../iframes/matching/page';

export const iframeRoutes = [
        '/public/schedule/:handle',
        '/public/schedule/:handle/:eventId',
        '/public/schedule/:handle/:eventId/*',
        '/public/reschedule/:token',
        '/public/products/:handle',
        '/public/products/:handle/:listingId',
        '/public/products/:handle/:listingId/*',
        '/public/webinars/:handle',
        '/public/webinars/:handle/:webinarId',
        '/public/webinars/:handle/:webinarId/*',
        '/public/profile/:handle',
        '/public/profile/:handle/*',
        '/public/willow-profile/:handle',
        '/public/willow-profile/:handle/*',
        '/public/shop/:handle',
        '/public/shop/:handle/products',
        '/public/shop/:handle/products/*',
        '/public/shop/:handle/testimonials',
        '/public/shop/:handle/testimonials/*',
        '/public/shop/:handle/faqs',
        '/public/shop/:handle/faqs/*',
        '/public/shop/:handle/portfolio',
        '/public/shop/:handle/portfolio/*',
        '/public/shop/:handle/about',
        '/public/shop/:handle/about/*',
        '/public/shop/:handle/webinars',
        '/public/shop/:handle/webinars/*',
        '/public/checkout/:planId',
        '/message-room/:roomId',
        '/rating/:roomId',
        '/client-rating/:roomId',
        '/package/:roomId',
        '/thank-you/:roomId',
        '/error',
        '/private/expert/create-user',
        '/private/wmc/create-user',
        '/private/coach-matching'
];

const RoutesTrustWillowIframes = () => (
    <Switch>
        <PublicRoute exact path={'/public/schedule/:handle'} component={IFrameSchedule} />
        <PublicRoute exact path={'/public/schedule/:handle/:eventId'} component={IFrameSchedule} />
        <PublicRoute exact path={'/public/schedule/:handle/:eventId/*'} component={IFrameSchedule} />
        <PublicRoute exact path={'/public/reschedule/:token'} component={IFrameReschedule} />
        <PublicRoute exact path={'/public/products/:handle'} component={IFrameListings} />
        <PublicRoute exact path={'/public/products/:handle/:listingId'} component={IFrameListings} />
        <PublicRoute exact path={'/public/products/:handle/:listingId/*'} component={IFrameListings} />
        <PublicRoute exact path={'/public/webinars/:handle'} component={IFrameWebinars} />
        <PublicRoute exact path={'/public/webinars/:handle/:webinarId'} component={IFrameWebinars} />
        <PublicRoute exact path={'/public/webinars/:handle/:webinarId/*'} component={IFrameWebinars} />
        <PublicRoute exact path={'/public/profile/:handle'} component={IFrameProfile} />
        <PublicRoute exact path={'/public/profile/:handle/*'} component={IFrameProfile} />
        <PublicRoute exact path={'/public/willow-profile/:handle'} component={IFrameWillowProfile} />
        <PublicRoute exact path={'/public/willow-profile/:handle/*'} component={IFrameWillowProfile} />
        <PublicRoute exact path={'/public/shop/:handle'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/products'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/products/*'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/testimonials'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/testimonials/*'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/faqs'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/faqs/*'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/portfolio'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/portfolio/*'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/about'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/about/*'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/webinars'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/webinars/*'} component={IFrameShop} />
        <PublicRoute exact path={'/public/checkout/:planId'} component={IFrameCheckout} />
        <PublicRoute exact path={'/message-room/:roomId'} component={IFrameSMS} />
        <PublicRoute exact path={'/rating/:roomId'} component={IframeRatings} />
        <PublicRoute exact path={'/client-rating/:roomId'} component={IframeRatings} />
        <PublicRoute exact path={'/package/:roomId'} component={IframeCheckoutWillowPackages} />
        <PublicRoute exact path={'/thank-you/:roomId'} component={IframeCheckoutWillowReceipt} />
        <PublicRoute exact path={'/error'} component={IframeError} />
        <PublicRoute exact path={'/private/expert/create-user'} component={IframeExpertCreateUser} />
        <PublicRoute exact path={'/private/wmc/create-user'} component={IframeWMCCreateUser} />
        <PublicRoute exact path={'/private/coach-matching'} component={IframeMatching} />
    </Switch>
);

export default RoutesTrustWillowIframes;
