import { Map } from 'immutable';
import moment from "moment";

/* Immutable */

const NOTE_MODAL = 'NOTE_MODAL';
const NOTE_MODAL_MINIMIZE = 'NOTE_MODAL_MINIMIZE';
const NOTE_POSTING = 'NOTE_POSTING';
const NOTES_GETTING = 'NOTES_GETTING';
const NOTE_EDITING = 'NOTE_EDITING';
const NOTE_DELETING = 'NOTE_DELETING';
const NOTE_SELECTED = 'NOTE_SELECTED';
const NOTE_CONFIRM_MODAL = 'NOTE_CONFIRM_MODAL';
const NOTES_STORE_RESET = 'NOTES_STORE_RESET';

/* Actions */

export const setNoteModal = (status) => ({'type': NOTE_MODAL, 'status': status});
export const setNoteModalMinimized = (status) => ({'type': NOTE_MODAL_MINIMIZE, 'status': status});
export const setNotePosting = (status) => ({'type': NOTE_POSTING, 'status': status});
export const setNotesGetting = (status) => ({'type': NOTES_GETTING, 'status': status});
export const setNoteEditing = (status) => ({'type': NOTE_EDITING, 'status': status});
export const setNoteDeleting = (status) => ({'type': NOTE_DELETING, 'status': status});
export const setNoteSelected = (note, edit) => ({'type': NOTE_SELECTED, 'note': note, 'edit': edit});
export const setNoteConfirmModal = (status, noteId) => ({'type': NOTE_CONFIRM_MODAL, 'status': status, 'noteId': noteId});
export const setClientsNotesStoreReset = () => ({'type': NOTES_STORE_RESET});

/* Initial State */

const initialState = Map({
    noteModal: false,
    noteModalMinimized: false,
    notePosting: false,
    notesGetting: false,
    noteEditing: false,
    noteDeleting: false,
    noteEdit: false,
    noteSelected: Map({
        clientId: null,
        name: null,
        id: null,
        title: null,
        body: null,
        datetime: null
    }),
    noteConfirmDeleteModal: false,
    noteConfirmDeleteId: null,
    lastInitiated: null
});

/* Reducer */

const clientsNotesReducer = (state=initialState, action) => {
    switch (action.type) {
        case NOTE_MODAL:
            return state.merge({'noteModal': action.status, 'lastInitiated': moment()});
        case NOTE_MODAL_MINIMIZE:
            return state.merge({'noteModalMinimized': action.status});
        case NOTE_POSTING:
            return state.merge({'notePosting': action.status});
        case NOTES_GETTING:
            return state.merge({'notesGetting': action.status});
        case NOTE_EDITING:
            return state.merge({'noteEditing': action.status});
        case NOTE_DELETING:
            return state.merge({'noteDeleting': action.status});
        case NOTE_SELECTED:
            return state.merge({'noteEdit': action.edit, 'noteSelected': Map(action.note)});
        case NOTE_CONFIRM_MODAL:
            return state.merge({'noteConfirmDeleteModal': action.status, 'noteConfirmDeleteId': action.noteId});
        case NOTES_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default clientsNotesReducer;
