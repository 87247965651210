import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {Link, withRouter} from 'react-router-dom';

/* Components */
import {colors} from "../../common/components/colors";
import {FontBody16, FontBody16To14, FontSubtitle21, FontBody12, FontHeader16} from "../../common/components/fonts";
import {ArrowLeftOutlined, EditOutlined} from "@ant-design/icons";
import {DeactiveEditIcon, EditIcon} from "../components/clientsdetail";
import {LogoLoadingSmall} from "../../common/components/loading";

/* Middleware */
import {tryGetOneContact} from "../middleware/contacts";
import {tryGetOneProspect} from "../middleware/prospects";
import {tryGetOneClient} from "../clients/middleware/clients";

/* Static */
const navTertiaryClients = (isCoachNoAdvisor) => ({
    back: {
        label: "Back",
        href: "/verify"
    },
    tabs: [
        // isCoachNoAdvisor ?
        // {} :
        {
            label: "Instructions",
            href: "/contact/instructions"
        },
        {
            label: "Notes", // isCoachNoAdvisor ? "My Notes" : "Shared Notes",
            href: "/contact/notes"
        },
        {
            label: "Messages",
            href: "/contact/messages"
        },
        {
            label: "Meetings",
            href: "/contact/meetings"
        },
        {
            label: "Shared Vault",
            href: "/contact/vault"
        },
        {
            label: "Finances",
            href: "/contact/finances"
        },
        // {
        //     label: "Activity",
        //     href: "/contact/activity"
        // },
    ]
});

const BackStyled = styled.div`
    margin-bottom: 10px;
    display: block;
    height: 22px;
    @media screen and (max-width: 1088px) {
        margin-bottom: 5px;
    }
`;
const BackButton = styled(Link)`
    cursor: pointer;
`;
const BackIcon = styled.div`
    display: inline-block;
    span {
        font-size: 14px;
        padding: 4px 0px;
        color: ${colors.primary30};
        display: inline-block;
        vertical-align: top;
        margin-right: 5px;
    }
`;
const BackLabel = styled.div`
    display: inline-block;
    vertical-align: top;
    color: ${colors.primary30};
`;
const NavTop = styled.div`
    height: calc(160px - 38px);
    margin-bottom: 30px;
    border-bottom: 1px solid ${colors.border100};
    @media screen and (max-width: 1440px) {
        height: auto;
        border-bottom: none;
    }
`;
const Links = styled.div`
    ${props => props.displayLargeResolution === false} {
        margin-top: 15px;
        display: block;
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
    ${props => props.displayLargeResolution === true} {
        margin-top: 15px;
        @media screen and (min-width: 768px) {
            display: none;
        }
    }
`;
const LinkStyled = styled(Link)`
    display: inline-block;
    text-align: center;
    cursor: pointer;
    padding: 10px 20px;
    ${props => props.status !== 'active'} {
        color: ${colors.primary100};
        border-bottom: 4px solid ${colors.action100};
        font-weight: 700;
    }
    ${props => props.status !== 'inactive'} {
        color: ${colors.secondary100};
    }
    @media screen and (max-width: 1440px) {
        display: block;
        text-align: left;
        padding: 10px 0px;
    }
`;
const Inline = styled.div`
    display: inline-block;
    margin-right: 10px;
`;


const ContactNavLinks = ({ location, displayLargeResolution, clientsDetail, user }) => (
    <Links displayLargeResolution={displayLargeResolution}>
        {navTertiaryClients((clientsDetail.get("isCoachNoAdvisor") || user.get("isSupport")), clientsDetail.get("isAdvisorNoCoach")).tabs.map((d, index) => {
            if(Object.keys(d).length > 0) {
                let status = "inactive";
                if(location.pathname === d.href) {status = "active"}
                if (d.label !== 'Finances') {
                    return (<LinkStyled key={index} status={status}
                                        to={d.href}><FontBody16To14>{d.label}</FontBody16To14></LinkStyled>)
                } else {
                    if(!user.wmc) {
                        return (<LinkStyled key={index} status={status}
                                            to={d.href}><FontBody16To14>{d.label}</FontBody16To14></LinkStyled>)
                    }
                }
            }
        })}
    </Links>
);

const mapStateToPropsContactNav = state => ({
    clientsDetail: state.enterprise.clientsDetail.get("client"),
    user: state.common.user
});

export const ContactNavLinksOnly = connect(mapStateToPropsContactNav, null)(withRouter(ContactNavLinks));



class ContactNav extends React.Component {
    state={
        "isLoading": true,
        "back": "/prospects",
        "contactInfo": null
    };

    componentDidMount() {
        return this.refresh();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.clientsDetail !== this.props.clientsDetail) {
            return this.refresh();
        }
    }

    refresh = async () => {
        const prospectStatus = this.props.clientsDetail.get("prospectStatus");
        const prospectId = this.props.clientsDetail.get("prospectId");
        const contactId = this.props.clientsDetail.get("contactId");
        await this.props.tryGetOneContact(contactId, "store");
        if(prospectStatus === "Prospect") {
            await this.props.tryGetOneProspect(prospectId, "store");
            return this.updateProspectOrClient("prospectsOne");
        } else if(prospectStatus === "Client") {
            await this.props.tryGetOneClient(prospectId, "store");
            return this.updateProspectOrClient("clientsOne");
        } else if(prospectStatus === "Contact") {
            return this.updateContact();
        }
    };

    updateProspectOrClient = async (store) => {
        const prospectStatus = this.props.clientsDetail.get("prospectStatus");
        let name;
        if (this.props.contactsOne.get("referralSource") === "wmc" && !this.props.isWMC) {
            name = await this.props.contactsOne.get("firstName")+" "+this.props.contactsOne.get("lastName").charAt(0)+"."
        } else {
            name = await this.props.contactsOne.get("firstName")+" "+this.props.contactsOne.get("lastName")
        }
        await this.setState({
            "advisor": this.props[store].get("advisor"),
            "back": prospectStatus === "Client" ? '/clients' : (prospectStatus === "Prospect" ? "/prospects" : "/contacts"),
            "contactInfo": this.props.contactsOne,
            "name": name,
            "isLoading": false,
            "companyName": this.props.clientsDetail.get("companyName")
        });
        
    };

    updateContact = async () => {
        const prospectStatus = this.props.clientsDetail.get("prospectStatus");
        await this.setState({
            "advisor": null,
            "back": prospectStatus === "Client" ? '/clients' : (prospectStatus === "Prospect" ? "/prospects" : "/contacts"),
            "contactInfo": this.props.contactsOne,
            "name": this.props.contactsOne.get("firstName")+" "+this.props.contactsOne.get("lastName"),
            "isLoading": false,
            "companyName": this.props.clientsDetail.get("companyName")
        });
    };

    edit = async () => {
        this.props.history.push("/contact/edit")
    };

    render() {
        if(this.state.isLoading) {
            return (
                <NavTop>
                    <br />
                    <LogoLoadingSmall />
                    <br />
                </NavTop>
            )
        } else {
            let canEdit = this.state.contactInfo.get("referralSource") === "willow" ? false : (this.props.isWMC ? true : (this.state.advisor === null ? true : (this.state.advisor.get("advisorId") === this.props.userId)));
            return (
                <NavTop>
                    <BackStyled>
                        <BackButton to={/*this.state.back*/"/clients"}>
                            <BackIcon><ArrowLeftOutlined/></BackIcon>
                            <BackLabel><FontBody16>Back</FontBody16></BackLabel>
                        </BackButton>
                    </BackStyled>
                    <div>
                        <Inline><FontSubtitle21>{this.state.name}</FontSubtitle21></Inline>
                        {(this.state.companyName !== "" && this.state.companyName !== null && this.state.companyName !== undefined) &&
                            <>
                                <Inline><FontBody12>@</FontBody12></Inline>
                                <Inline><FontSubtitle21>{this.state.companyName}</FontSubtitle21></Inline>
                            </>
                        }
                        {/*<Inline>*/}
                        {/*    {canEdit*/}
                        {/*        ? <EditIcon onClick={this.edit}><EditOutlined/></EditIcon>*/}
                        {/*        : <DeactiveEditIcon><EditOutlined/></DeactiveEditIcon>*/}
                        {/*    }*/}
                        {/*</Inline>*/}
                    </div>
                    <ContactNavLinksOnly displayLargeResolution={this.props.displayLargeResolution} />
                </NavTop>
            )
        }
    }
}

const mapStateToProps = state => ({
    clientsDetail: state.enterprise.clientsDetail.get("client"),
    contactsOne: state.enterprise.contacts.get("contactsOne"),
    prospectsOne: state.enterprise.prospects.get("prospectsOne"),
    clientsOne: state.enterprise.clientsList.get("clientsOne"),
    isWMC: state.common.wmc.get("isWMC"),
    userId: state.common.user.get("userId"),
});

const mapDispatchToProps = dispatch => ({
    tryGetOneContact: (contactId, storeOrReturn) => dispatch(tryGetOneContact(contactId, storeOrReturn)),
    tryGetOneProspect: (prospectId, storeOrReturn) => dispatch(tryGetOneProspect(prospectId, storeOrReturn)),
    tryGetOneClient: (prospectId, storeOrReturn) => dispatch(tryGetOneClient(prospectId, storeOrReturn)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactNav));
//
// const Bar = styled.div`
//     width: 100%;
//     margin-bottom: 40px;
//     background-color: ${colors.border100};
// `;
// const Button = styled(Link)`
//     display: inline-block;
//     vertical-align: top;
//     padding: 10px 25px;
//     ${props => props.active === true} {color: ${colors.primary70};}
//     ${props => props.active === false} {color: ${colors.action100};}
//     margin-right: 20px;
//     cursor: pointer;
//     width: fit-content;
// `;
//
// export const ActivitySubBar = ({page}) => (
//     <Bar>
//         <Button active={page === "/contact/activity"} to={"/contact/activity"}><FontHeader16>All</FontHeader16></Button>
//         <Button active={page === "/contact/tasks"} to={"/contact/tasks"}><FontHeader16>Tasks</FontHeader16></Button>
//         <Button active={page === "/contact/emails"} to={"/contact/emails"}><FontHeader16>Emails</FontHeader16></Button>
//         <Button active={page === "/contact/sms"} to={"/contact/sms"}><FontHeader16>SMS</FontHeader16></Button>
//         <Button active={page === "/contact/chats"} to={"/contact/chats"}><FontHeader16>Chats</FontHeader16></Button>
//         <Button active={page === "/contact/meetings"} to={"/contact/meetings"}><FontHeader16>Meetings</FontHeader16></Button>
//     </Bar>
// );
