import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from "styled-components";

/* Containers */
import ProgramsList from './programslist';
import ProgramOne from "./program";
import Success from "../../success/containers/success";
import CheckoutFlow from "../../checkout/containers/flow";

/* Middleware */
import {tryRegisterUserForProgram} from "../middleware/programs";

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {Container} from "../components/flow";

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

class ProgramsFlow extends React.Component {
    static defaultProps = {
        entryPoint: "consumer",
        programId: null,
        openToCheckout: false,
        shouldAllowToTakeCert: true,
        urlList: "/programs",
        urlCourse: null,
        urlQuiz: null,
        isCourseCompleted: null,
        isCourseAssigned: null,
        isQuizCompleted: null,
        courseData: null,
        urlRequest: "/programs/request",
        urlDetails: "/programs/details",
        urlCheckout: "/programs/checkout",
        urlRegistrationComplete: "/programs/registration-completed",
        urlRegistrationCompleteClose: "/home",
        onInitProductData: () => {},
    };

    state={
        "step": 1,
        "programId": null,
        "isLoading": true,
        "error": null,
        "userId": null,
        "programPrice": 0,
        "programTitle": "",
        "hrCode": "",
        "openToCheckout": this.props.openToCheckout
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.userId !== this.props.userId) {
            return this.setState({"userId": this.props.userId})
        }
        if(prevProps.location.pathname !== this.props.location.pathname) {
            let programId = this.state.programId === null ? "null" : this.state.programId.toString();
            if(this.props.location.pathname === this.props.urlList) {
                return this.setStep(1);
            } else if(this.props.location.pathname === this.props.urlDetails.replace("null", programId)) {
                return this.setStep(2);
            } else if(this.props.location.pathname === this.props.urlCheckout.replace("null", programId)) {
                return this.setStep(3);
            } else if(this.props.location.pathname === this.props.urlRegistrationComplete) {
                return this.setStep(4);
            }
        }
    }

    init = async () => {
        console.log(this.state.error)
        if(this.props.entryPoint === "advisor") {
            await this.setState({"programId": this.props.programId});
            await this.setStep(2);
        } else {
            if(this.props.programId !== null) {
                await this.onOpenProgram(this.props.programId);
            }
        }
        this.setState({
            "userId": this.props.userId,
            "isLoading": false
        });
    };

    setStep = (step) => {this.setState({"step": step})};
    setViewList = () => {this.props.history.push(this.props.urlList)};
    setViewDetails = () => {
        let programId = this.state.programId === null ? "null" : this.state.programId.toString();
        this.props.history.push(this.props.urlDetails.replace("null", programId))
    };
    setViewCheckout = () => {
        let programId = this.state.programId === null ? "null" : this.state.programId.toString();
        if(!this.state.openToCheckout) {
            this.props.history.push(this.props.urlCheckout.replace("null", programId))
        }
        if(this.state.openToCheckout) {
            this.setStep(3);
            this.setState({"openToCheckout": false})
        }
    };
    setViewSuccess = () => {this.props.history.push(this.props.urlRegistrationComplete)};

    onOpenProgramsList = async () => {
        await this.setState({"programId": null});
        return this.setViewList();
    };

    onOpenProgram = async (groupProgramId, groupProgramTitle) => {
        await this.setState({"programId": groupProgramId, "programTitle": groupProgramTitle});
        return this.setViewDetails();
    };

    onTryRegister = async (price) => { 
        const hasCode = false; //check user for code
        if(price === 0 || price === null) {
            if(this.state.userId === null) {
                await this.setState({"programPrice": 0});
                return this.setViewCheckout();
            } else {
                return this.onRegister();
            }
        } else {
            if (hasCode) {
                return this.onRegister();
            } else {
                await this.setState({"programPrice": price});
                return this.setViewCheckout();
            }
        }
    };

    onRegister = async () => {
        this.setState({"error": null});
        const registration = await this.props.tryRegisterUserForProgram(this.state.programId, this.state.hrCode);
        console.log(registration)
        if (registration.error != null) {
            await this.setState({"error": registration.error});
            return this.setViewDetails();
        // } else if (Object.keys(registration).length === 0) {
        //     await this.setState({"error": "Invalid payment"});
        //     return this.setViewDetails();
        } else {
            return this.onRegisterSuccess();
        }
    };

    onRegisterSuccess = async () => {
        this.setState({"error": null})
        return this.setViewSuccess();
    };

    onRegisterConfirmationClose = async () => {
        if(this.props.entryPoint === "consumer") {
            this.props.history.push(this.props.urlRegistrationCompleteClose)
        } else {
            await this.setViewList();
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if(this.state.step === 1) {
                return(
                    <Container entryPoint={this.props.entryPoint}>
                        <ProgramsList
                            entryPoint={this.props.entryPoint}
                            urlRequest={this.props.urlRequest}
                            onOpenProgram={this.onOpenProgram}
                        />
                    </Container>
                )
            } else if(this.state.step === 2) {
                return(
                    <Container entryPoint={this.props.entryPoint}>
                        <ProgramOne
                            entryPoint={this.props.entryPoint}
                            programId={this.state.programId}
                            back={this.onOpenProgramsList}
                            error={this.state.error}
                            onTryRegister={this.onTryRegister}
                            openToCheckout={this.state.openToCheckout}
                            onInitProductData={this.props.onInitProductData}
                            urlRequest={this.props.urlRequest}
                            urlCourse={this.props.urlCourse}
                            urlQuiz={this.props.urlQuiz}
                            isQuizCompleted={this.props.isQuizCompleted}
                            isCourseCompleted={this.props.isCourseCompleted}
                            isCourseAssigned={this.props.isCourseAssigned}
                            courseData={this.props.courseData}
                            shouldAllowToTakeCert={this.props.shouldAllowToTakeCert}
                        />
                    </Container>
                )
            } else if (this.state.step === 3) {
                return (
                    <Container entryPoint={this.props.entryPoint}>
                        <CheckoutFlow
                            planId={"willow_program"}
                            inModal={true}
                            fromDashboard={this.props.entryPoint === "consumer" || this.props.entryPoint === "advisor"}
                            onBack={this.setViewDetails}
                            onPaymentSuccess={this.onRegister}
                            webinarProgramId={this.state.programId}
                            webinarProductTitle={this.state.programTitle}
                            webinarProductPrice={this.state.programPrice}
                            skipSuccessPage={true}
                            hideProduct={this.props.entryPoint === "advisor"}
                            // promptHrCode={true}
                            // getHrCode={(code) => {return this.setState({'hrCode': code})}}
                        />
                    </Container>
                )
            } else if (this.state.step === 4) {
                return (
                    <Container entryPoint={this.props.entryPoint}>
                        <Success
                            back={this.onRegisterConfirmationClose}
                            title={"REGISTRATION CONFIRMED"}
                            subtitle={"Please check your email for a calendar invite with instructions on how to join the program."}
                            buttonLabel={"Close"}
                        />
                    </Container>
                )
            } else {
                return null
            }
        }
    }

}

const mapStateToProps = state => ({
    groupPrograms: state.iframe.programs.programs.get("groupPrograms"),
    userId: state.common.user.get("userId")
});

const mapDispatchToProps = dispatch => ({
    tryRegisterUserForProgram: (groupProgramId, hrCode) => dispatch(tryRegisterUserForProgram(groupProgramId, hrCode))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProgramsFlow));