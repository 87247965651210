import React from 'react';
import styled from 'styled-components';
import {useHistory} from "react-router-dom";

/* Components */
import {colors} from "../../../common/components/colors";
import {FontBody21} from '../../../common/components/fonts';
import {ContactBottom, ContactContent, Level1, Level2, Level3} from "../components/contactcontainer";
import ContactTop from "../components/contacttop";

const Submit = styled.button`
  display: block;
  cursor: pointer;
  background-color: ${colors.primary100};
  border: none;
  border-radius: 10px;
  padding: 15px;
  margin: 10px auto auto auto;
  vertical-align: baseline;
  color: ${colors.white};
  &:hover {
    background-color: ${colors.action100};
  }
`;

ContactSuccess.defaultProps = {
    show:  false,
    advisorName: "",
}

export default function ContactSuccess(props) {
    const show = props.show;
    const history = useHistory();

    const sendToDashboard = () => {
        history.push("/verify");
        history.go();
    }

    return (
        <Level1 toggle={show}>
            <Level2 animate={show}>
                <Level3>
                    <ContactTop title={"Matching call scheduled!"} />
                    <ContactBottom>
                        <ContactContent>
                            <FontBody21>For additional support with your life journey including Financial Life Coaching, access Willow's dashboard.</FontBody21>
                            <Submit onClick={sendToDashboard}>Go to Dashboard</Submit>
                        </ContactContent>
                    </ContactBottom>
                </Level3>
            </Level2>
        </Level1>
    );
}