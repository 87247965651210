import { combineReducers } from 'redux';

/* Common */
import feedbackReducers from './store/feedback';

const feedbacksReducers = combineReducers({
    feedback: feedbackReducers,
});

export default feedbacksReducers;
