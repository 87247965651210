import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";

/* Components */
import {colors} from '../../../common/components/colors';
import {FontBody16To14, FontHeader18, FontBody16} from "../../../common/components/fonts";
import {DropdownInput} from "../../../common/components/inputs";
import {ButtonPrimary, ButtonLinkProgress} from "../../../common/components/buttons";
import {CheckOutlined, CloseOutlined, CopyOutlined} from "@ant-design/icons";
import {LogoLoading} from "../../../common/components/loading";

/* Middleware */
import {tryGetExpertProfile} from '../middleware/expertprofile';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

export const Close = styled.div`
    position: relative;
    cursor: pointer;
    width: 75px;
    color: ${colors.action100};
    @media screen and (max-width: 640px) {
        width: 20px;
    }
`;
const Background = styled.div`
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.2);
`;
const Modal = styled.div`
    position: relative;
    width: 488px;
    padding: 30px 40px;
    z-index: 11;
    background-color: ${colors.white};
    margin: 220px auto 0px auto;
    border-radius: 6px;
    @media screen and (max-width: 640px) {
        padding: 30px 20px;
        width: calc(100% - 40px);
        margin-top: 0px;
        border-radius: 0px;
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        top: 0;
    }
`;
const DropdownCSS = styled.div`
    & .myOptionClassNameGeneral:hover {
        background-color: ${colors.action30};
        color: ${colors.action100};
            font-weight: 700;
    }
    & .myOptionClassName {
        padding-left: 10px;
        width: calc(100% - 10px);
        &:hover {        
            background-color: ${colors.action30};
            color: ${colors.action100};
            font-weight: 700;
        }
    }
    & .Dropdown-title {
        padding: 10px 0px;
        font-weight: 700;
        color: ${colors.secondary100};
    }
    & .Dropdown-group:focus {
        outline: none;
    }
`;
const Left = styled.div`
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 75px);
    @media screen and (max-width: 640px) {
        width: calc(100% - 20px);
    }
`;
const Right = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 75px;
    @media screen and (max-width: 640px) {
        width: 20px;
    }
`;
const Text = styled.div`
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 640px) {
        display: none;
    }
`;
const FitContent = styled.div`
    margin-top: 20px;
    width: fit-content;
`;
const Wrap = styled.div`
    word-break: break-all;
    padding: 30px;
    border: 1px solid ${colors.primary30};
    border-radius: 4px;
    color: ${colors.primary100};
    @media screen and (max-width: 640px) {
        padding: 15px;
    }
    & a {
        color: ${colors.primary100};
    }
`;
const Links = styled.div`
    margin: 30px 0px;
    display: block;
    border-bottom: 1px solid ${colors.primary30};
    @media screen and (max-width: 640px) {
        border-bottom: none;
    }
`;
const LinkStyled = styled.div`
    display: inline-block;
    text-align: center;
    cursor: pointer;
    padding: 10px 20px;
    ${props => props.status !== 'active'} {
        color: ${colors.primary100};
        border-bottom: 4px solid ${colors.action100};
        font-weight: 700;
    }
    ${props => props.status !== 'inactive'} {
        color: ${colors.secondary100};
    }
    @media screen and (max-width: 640px) {
        display: block;
        text-align: left;
        padding: 10px 0px;
    }
`;

const tabs = [
    {"label": "Link", "value": "link"},
    {"label": "Button", "value": "button"},
    {"label": "Embed", "value": "iframe"}
];


const Copy = ({copySuccess, string, handleCopy}) => (
    <FitContent onClick={!copySuccess ? () => {return handleCopy(string)} : () => {}}>
        {!copySuccess
            ? <ButtonPrimary submit={false} label={<FontBody16><CopyOutlined/>&nbsp;Copy</FontBody16>}/>
            : <ButtonLinkProgress submit={false} label={<FontBody16><CheckOutlined/> Copied!</FontBody16>}/>
        }
    </FitContent>
);

class MeetingLinkEmbed extends React.Component {
    state={"dropdownOptions": [], "eventSelectedId": null, "tab": "link", "isLoading": false};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.props.tryGetExpertProfile();
        let dropdownOptions = [{"value": null, "label": 'All Webinars Page', "className": 'myOptionClassNameGeneral'}];
        let dropdownOptionsAddOnUpcoming = await this.props.eventsAll.toJS().reduce((filtered, e) => {
            if(e.isActive && !e.inThePast) {
                filtered.push({"value": e.id.toString(), "label": e.title, "className": 'myOptionClassName'})
            }
            return filtered;
        }, []);
        dropdownOptions.push({"type": "group", "name": "Upcoming:", "items": dropdownOptionsAddOnUpcoming});
        let dropdownOptionsAddOnCompleted = await this.props.eventsAll.toJS().reduce((filtered, e) => {
            if(e.isActive && e.inThePast) {
                filtered.push({"value": e.id.toString(), "label": e.title, "className": 'myOptionClassName'})
            }
            return filtered;
        }, []);
        dropdownOptions.push({"type": "group", "name": "Completed:", "items": dropdownOptionsAddOnCompleted});
        let dropdownOptionsAddOnCanceled = await this.props.eventsAll.toJS().reduce((filtered, e) => {
            if(!e.isActive) {
                filtered.push({"value": e.id.toString(), "label": e.title, "className": 'myOptionClassName'})
            }
            return filtered;
        }, []);
        dropdownOptions.push({"type": "group", "name": "Canceled:", "items": dropdownOptionsAddOnCanceled});
        await this.setState({
            "dropdownOptions": dropdownOptions,
            "eventSelectedId": this.props.linkEmbedId,
            "isLoading": false
        });
    };

    selectEvent = async (selected) => {
        this.setState({"eventSelectedId": selected.value})
    };

    selectTab = async (status) => {
        this.setState({"tab": status})
    };

    handleCopy = (copy) => {
        let tempInput = document.createElement("input");
        tempInput.value = copy;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        this.setState({"copySuccess": true});
        this.timeout = setTimeout(() => {this.setState({'copySuccess': false})}, 2000);
    };

    render() {
        if(this.state.isLoading) {
            return(<LogoLoading />)
        } else {
            return(
                <Background>
                    <Modal>
                        <div>
                            <Left>
                                <FontHeader18>Direct Links & Embedding</FontHeader18>
                            </Left>
                            <Right>
                                <Close onClick={() => {return this.props.linkEmbed(false)}}>
                                    <FontHeader18><CloseOutlined /><Text>&nbsp;Close</Text></FontHeader18>
                                </Close>
                            </Right>
                        </div>
                        <DropdownCSS>
                            <DropdownInput
                                title={"Webinars"}
                                options={this.state.dropdownOptions}
                                id={'eventSelectedId'}
                                onChange={this.selectEvent}
                                placeholder={'All Webinars Page'}
                                value={this.state.eventSelectedId}
                            />
                        </DropdownCSS>
                        <Links>
                            {tabs.map((d, index) => {
                                let status = "inactive";
                                if(this.state.tab === d.value) {status = "active";}
                                return(<LinkStyled key={index} status={status} onClick={() => {return this.selectTab(d.value)}}><FontBody16To14>{d.label}</FontBody16To14></LinkStyled>)
                            })}
                        </Links>
                        {this.state.tab === "link" &&
                        <>
                            <Wrap><FontBody16>
                                <a
                                    href={"https://"+env.URL.DOMAIN+"/webinars/"+this.props.profile.get("publicHandle")+(this.state.eventSelectedId === null ? "" : ("/"+this.state.eventSelectedId))}
                                    target={"_blank"}
                                >
                                    {"https://"+env.URL.DOMAIN+"/webinars/"+this.props.profile.get("publicHandle")+(this.state.eventSelectedId === null ? "" : ("/"+this.state.eventSelectedId))}
                                </a>
                            </FontBody16></Wrap>
                            <Copy
                                copySuccess={this.state.copySuccess}
                                string={"https://"+env.URL.DOMAIN+"/webinars/"+this.props.profile.get("publicHandle")+(this.state.eventSelectedId === null ? "" : ("/"+this.state.eventSelectedId))}
                                handleCopy={this.handleCopy}
                            />
                        </>
                        }
                        {this.state.tab === "iframe" &&
                        <>
                            <Wrap><FontBody16>
                                <span>{"<iframe src='"}</span>
                                <span>{"https://"+env.URL.DOMAIN+"/webinars/"}</span>
                                <span>{this.props.profile.get('publicHandle')}</span>
                                <span>{(this.state.eventSelectedId === null ? "" : ("/"+this.state.eventSelectedId))}</span>
                                <span>{"' title='Register Now' width='100%' height='800' />"}</span>
                            </FontBody16></Wrap>
                            <Copy
                                copySuccess={this.state.copySuccess}
                                string={"<iframe src='https://"+env.URL.DOMAIN+"/webinars/"+this.props.profile.get('publicHandle')+(this.state.eventSelectedId === null ? "" : ("/"+this.state.eventSelectedId))+"' title='Register Now' width='100%' height='800' />"}
                                handleCopy={this.handleCopy}
                            />
                        </>
                        }
                        {this.state.tab === "button" &&
                        <>
                            <Wrap><FontBody16>
                                <span>{"<a href='"}</span>
                                <span>{"https://"+env.URL.DOMAIN+"/webinars/"}</span>
                                <span>{this.props.profile.get('publicHandle')}</span>
                                <span>{(this.state.eventSelectedId === null ? "" : ("/"+this.state.eventSelectedId))}</span>
                                <span>{"' target='_blank' class='willow-embed-button' style='background: #6bc8d6; color: #fff; padding: 8px 12px; border: 0px;border-radius: 4px; text-decoration: none; display: inline-block;'>Register Now</a>"}</span>
                            </FontBody16></Wrap>
                            <Copy
                                copySuccess={this.state.copySuccess}
                                string={"<a href='https://"+env.URL.DOMAIN+"/webinars/"+this.props.profile.get('publicHandle')+(this.state.eventSelectedId === null ? "" : ("/"+this.state.eventSelectedId))+"' target='_blank' class='willow-embed-button' style='background: #6bc8d6; color: #fff; padding: 8px 12px; border: 0px;border-radius: 4px; text-decoration: none; display: inline-block;'>Register Now</a>"}
                                handleCopy={this.handleCopy}
                            />
                        </>
                        }
                    </Modal>
                </Background>
            )
        }
    }
}

const mapStateToProps = state => ({
    eventsAll: state.enterprise.meetings.group.get("groupSessionsAll"),
    profile: state.enterprise.meetings.expertProfile.get("expertProfile")
});

const mapDispatchToProps = dispatch => ({
    tryGetExpertProfile: () => dispatch(tryGetExpertProfile())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MeetingLinkEmbed));