import React, {useEffect, useState} from "react";
import { withRouter } from 'react-router-dom';
import {connect, useDispatch} from 'react-redux';
import styled from "styled-components";
import moment from "moment";
import {Map, List} from 'immutable';

/* Components */
import { colors } from "../../../../common/components/colors";
import {ADVISOR_FOR_WOMEN_CERT_ID, ADVISOR_FOR_NEXTGEN_CERT_ID} from "../../../../common/constants";
import {FontBody24, FontHeader18, FontSubtitle40} from "../../../../common/components/fonts";

/* Containers */
import FirmPlanWomen from "./firmplanwomen";
import FirmPlanNextGen from "./firmplannextgen";
import FirmPlanClientAcquisition from "./firmplanclientacquisition";

/* Store */
import { setSupportOpen } from "../../../support/store/support";
import { useSelector } from "react-redux";
import {tryGetStatus} from "../middleware/plans";
import FirmPlanContent from "./firmplancontent";
import {certificationValidation} from "../../certification/middleware/certificationValidation";
import {LoadingCenter} from "../../../shop/components/faqs";
import {LogoLoading} from "../../../../common/components/loading";

const Subtitle = styled(FontBody24)`
    margin: 30px 0 0 0;
    min-height: 108px;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  & > div {
    width: 25%;
  }
  
  @media (max-width: 748px) {
    & > div {
      width: 100%;
    }
  }
`;
const Upgrade = styled.div`
    display: inline-block;
    text-decoration: underline;
    font-weight: bold;
    color: ${colors.primary100};
    cursor: pointer;
`;

export const Option = styled.div`
  border-radius: 30px;
  box-shadow: 0 10px 25px 0 rgb(0 0 0 / 10%);
  padding: 30px 30px 50px 30px;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 33%;
  max-width: 350px;
  @media screen and (max-width: 1260px) {
    width: 100%;
  }
`;

export const Plan = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const PlanDetails = styled(FontHeader18)`
    margin-bottom: 20px;
`;

export const PlansList = styled.ul`
  margin: 0;
  padding: 15px 0 15px 18px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${colors.border100};
`;

export const CertImage = styled.img`
height: 200px;
width: 200px;
align-self: center;
`;

export const Price = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ButtonWide = styled.div`
  & > div {
    width: calc(100% - 46px);
    text-align: center;
  }
`;

export default function FirmPlansGrid() {
    const [certWomen, setWomen] = useState(null)
    const [certNextGen, setNextGen] = useState(null)
    const [certWomenStatus, setWomenStatus] = useState(null)
    const [certNextGenStatus, setNextGenStatus] = useState(null)
    const [clientAcq, setClientAcq] = useState(null)
    const [isLoading, setLoading] = useState(true);
    const dispatch = useDispatch();

    const contactUs = () => {

    }

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        const cert = await dispatch(certificationValidation())
        setWomen(cert.certificationStatus.womenCertPurchasedOn || cert.certificationStatus.womenCertCompletedOn)
        setWomenStatus(cert.certificationStatus.womenCertStatus)
        setNextGenStatus(cert.certificationStatus.nextGenCertStatus)
        setNextGen(cert.certificationStatus.nextGenCertPurchasedOn || cert.certificationStatus.nextGenCertCompletedOn)
        setClientAcq(cert.certificationStatus.clientAcqPurchasedOn)
        setLoading(false)

    }
    if (isLoading) {
        return (<LoadingCenter><LogoLoading /></LoadingCenter>)
    } else {
        return (
            <>
                <FontSubtitle40 spaced={true}>CHOOSE A PLAN</FontSubtitle40>
                <Subtitle>Looking to deploy Willow across your advisory firm? Please contact our enterprise sales
                    team. <Upgrade onClick={contactUs}><b>Contact us</b></Upgrade>.</Subtitle>
                <FlexRow>
                    {certWomen === null && <FirmPlanWomen  certNextGen={certNextGen} certNextGenStatus={certNextGenStatus}/>}
                    {certNextGen === null && <FirmPlanNextGen  certWomen={certWomen} certWomenStatus={certWomenStatus} />}
                    {clientAcq === null && <FirmPlanClientAcquisition clientAcquisition={clientAcq}/>}
                    <FirmPlanContent />
                </FlexRow>
            </>
        )
    }
}
        // if (status.certPurchases !== List()) {
        //     let womenCertPurchases = status.certPurchases.filter(c => {
        //         return (c.get("certificationDefinitionId") === ADVISOR_FOR_WOMEN_CERT_ID)
        //     })
        //     if (womenCertPurchases.size > 0) {
        //         let womenCertPurchasesSorted = womenCertPurchases.sort((a, b) => {
        //             return (new Date(b.get("purchasedOn")) - new Date(a.get("purchasedOn")))
        //         });
        //         let womenCertPurchasedExpiration = moment(womenCertPurchasesSorted.get(0).get("purchasedOn")).add(1, "years");
        //         if (moment().isAfter(womenCertPurchasedExpiration)) {
        //             setWomen(certWomen => ({
        //                 ...certWomen,
        //                 isExpired: true
        //             }))
        //         }
        //         certWomen.renewalDate = womenCertPurchasedExpiration.format("MM/DD/YYYY");
        //     }
        //     let nextGenCertPurchases = status.certPurchases.filter(c => {
        //         return (c.get("certificationDefinitionId") === ADVISOR_FOR_WOMEN_CERT_ID)
        //     })
        //     if (nextGenCertPurchases.size > 0) {
        //         let nextGenCertPurchasesSorted = nextGenCertPurchases.sort((a, b) => {
        //             return (new Date(b.get("purchasedOn")) - new Date(a.get("purchasedOn")))
        //         });
        //         let nextGenCertPurchasedExpiration = moment(nextGenCertPurchasesSorted.get(0).get("purchasedOn")).add(1, "years");
        //         if (moment().isAfter(nextGenCertPurchasedExpiration)) {
        //             setNextGen(certNextGen => ({
        //                 ...certNextGen,
        //                 isExpired: true
        //             }))
        //         }
        //         setNextGen(certNextGen => ({
        //             ...certNextGen,
        //             renewalDate: nextGenCertPurchasedExpiration.format("MM/DD/YYYY")
        //         }))
        //     }
        // }
        // if (status.renewals !== Map()) {
        //     if (status.renewals.get('clientAcquisitionUntil') !== null) { // clientAcq purchase
        //         setClientAcq(clientAcq => ({
        //             ...clientAcq,
        //             status: "complete"
        //         }))
        //         if (moment().isAfter(moment(status.renewals.clientAcquisitionUntil))) {
        //             setClientAcq(clientAcq => ({
        //                 ...clientAcq,
        //                 isExpired: true
        //             }))
        //         }
        //         setClientAcq(clientAcq => ({
        //             ...clientAcq,
        //             renewalDate: moment(status.renewals.get('clientAcquisitionUntil')).format("MM/DD/YYYY")
        //         }))
        //     }
        // }
        // if (status.completed.length !== 0) { // completed certifications
        //     status.completed.forEach((item, key) => {
        //         let certificateType = item.get('certificationDefinitionId');
        //         if (certificateType === ADVISOR_FOR_WOMEN_CERT_ID) {
        //             setWomen(certWomen => ({
        //                 ...certWomen,
        //                 status: "completed"
        //             }))
        //         }
        //         if (certificateType === ADVISOR_FOR_NEXTGEN_CERT_ID) {
        //             setNextGen(certNextGen => ({
        //                 ...certNextGen,
        //                 status: "completed"
        //             }))
        //         }
        //     });
        // }
        // if (status.progress !== null) { // in progress certifications
        //     let certificationProgress = status.progress.get('certificationDefinition');
        //     if (certificationProgress.get('id') === ADVISOR_FOR_WOMEN_CERT_ID && certWomen.status !== "completed") {
        //         setWomen(certWomen => ({
        //             ...certWomen,
        //             status: "inProgress"
        //         }))
        //     }
        //     if (certificationProgress.get('id') === ADVISOR_FOR_NEXTGEN_CERT_ID && certNextGen.status !== "completed") {
        //         setNextGen(certNextGen => ({
        //             ...certNextGen,
        //             status: "inProgress"
        //         }))
        //     }



// const mapDispatchToProps = dispatch => ({
//     setSupportOpen: (isOpen, page) => dispatch(setSupportOpen(isOpen, page)),
// })
//
// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FirmPlansGrid));
