import React from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';

/* Components */
import {ActionBox, Background, Section} from '../components/support';
import {StarFilled, CustomerServiceFilled, ToolFilled, ReadOutlined, NotificationOutlined} from '@ant-design/icons';
import {ActionBarModal, ActionBarLeftBar, SelectionModal} from "../components/actionbar";
import FloatingButton from "../components/floatingbutton";
import {CommonBackground, CommonModal} from "../../../common/components/modal";
import {images} from "../../../common/components/images";

/* Containers */
import HelpFAQsModal from './faqsmodal';
import HelpFAQsLeftBar from './faqsleftbar';
import HelpContactModal from './contactmodal';
import HelpContactLeftBar from './contactleftbar';
import FeedbackModal from './feedbackmodal';
import FeedbackLeftBar from './feedbackleftbar';
import MemberByCategoryIdModal from './membermodal';
import MemberByCategoryIdLeftBar from './memberleftbar';
import SchedulerFlow from "../../../iframes/scheduler/containers/flow";
// import EmailerFlow from "../../../iframes/emailer/containers/flow";

/* Domains */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

/* Store */
import {setSupportOpen} from '../store/support';

/* Middleware */
import {tryGetCoachesAssignedToWMC} from "../../team/middleware/coaching";

class SupportMembers extends React.Component {
    state = {
        "photo": null,
        "firstName": null,
        "lastName": null,
        "userId": null,
        "showScheduler": false,
        // "showEmailer": false,
        "isLoading": true,
        "isOpen": false,
        "page": 1,
        "expertRM": {},
        "expertRMExists": false,
        "expertGeneral": {},
        "expertGeneralExists": false,
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.isOpen !== this.props.isOpen) {
            this.setState({"isOpen": this.props.isOpen})
        }
        if(prevProps.page !== this.props.page) {
            this.setState({"page": this.props.page})
        }
        if(prevProps.isWMC !== this.props.isWMC) {
            return this.init();
        }
        if(prevProps.user.get("platform") !== this.props.user.get("platform")) {
            return this.init();
        }
    }

    init = async () => {
        if(this.props.isWMC && this.props.user.get("platform") !== null && this.props.user.get("platform") !== "consumer") {
            await this.findExpertByCategoryId("expertRM", 6);
            await this.findExpertByCategoryId("expertGeneral", 7);
        }
        this.setState({"isOpen": this.props.isOpen, "isLoading": false});
    };

    findExpertByCategoryId = async (expertType, categoryId) => {
        await console.log("flow")
        if(!this.props.allCoachesFetched) {
            await this.props.tryGetCoachesAssignedToWMC()
        }
        let exists = false;
        let expert = {};
        await Promise.all(this.props.allCoaches.map(async a => {
            await Promise.all(a.get("expertCategories").map(async c => {
                if(c.get("categoryId") === categoryId) {
                    exists = true;
                    expert = a.toJS();
                }
                return Promise.resolve()
            }));
            return Promise.resolve()
        }));
        await this.setState({[expertType]: expert, [(expertType+"Exists")]: exists});
    };

    scheduleMeeting = async (photo, firstName, lastName, userId) => {
        await this.setState({"photo": photo, "firstName": firstName, "lastName": lastName, "userId": userId});
        this.changeShowScheduler(true);
    };

    // writeMessage = async (photo, firstName, lastName, userId) => {
    //     await this.setState({"photo": photo, "firstName": firstName, "lastName": lastName, "userId": userId});
    //     this.changeShowEmailer(true);
    // };

    changeShowScheduler = async (status) => {
        this.setState({"showScheduler": status});
    };

    // changeShowEmailer = async (status) => {
    //     this.setState({"showEmailer": status});
    // };

    page = async (page) => {
        this.props.setSupportOpen(true, page);
    };

    close = async () => {
        this.props.setSupportOpen(!this.state.isOpen, 1);
    };

    faqAction = async (page, isOpen) => {
        let selectPage = page === null ? this.state.page : parseInt(page);
        this.props.setSupportOpen(isOpen, selectPage);
    };

    render() {
        // console.log(this.state.isWMC)
        // console.log(this.state.expertGeneralExists)
        // console.log(this.state.expertGeneral)
        if(this.props.showSupport) {
            return(
                <>
                    {/*{!this.props.isWMC &&*/}
                    {/*    <FloatingButton*/}
                    {/*        close={this.close}*/}
                    {/*        isOpen={this.state.isOpen}*/}
                    {/*    />*/}
                    {/*}*/}
                    {this.state.isOpen &&
                        <>
                            <Background onClick={this.close} />
                            <ActionBox leftNav={true}>
                                    <>
                                        <ActionBarLeftBar
                                            currentPage={this.state.page}
                                            changePage={this.page}
                                            closeSupport={this.close}
                                        />
                                    {/*    <ActionBarModal*/}
                                    {/*        currentPage={this.state.page}*/}
                                    {/*        changePage={this.page}*/}
                                    {/*        closeSupport={this.close}*/}
                                    {/*    />*/}
                                    </>
                                {this.state.page === 1 &&
                                    <Section leftNav={this.props.isWMC}>
                                        {(this.props.isWMC && this.state.expertRMExists) &&
                                            <SelectionModal
                                                onClick={this.page}
                                                page={2}
                                                icon={this.props.isWMC ? images.firmSupportRM : <StarFilled />}
                                                label={"Relationship Manager"}
                                                leftBar={this.props.isWMC}
                                            />
                                        }
                                        {(this.props.isWMC && this.state.expertGeneralExists) &&
                                            <SelectionModal
                                                onClick={this.page}
                                                page={3}
                                                icon={this.props.isWMC ? images.firmSupportGeneral : <CustomerServiceFilled />}
                                                label={"General Support"}
                                                leftBar={this.props.isWMC}
                                            />
                                        }
                                        <SelectionModal
                                            onClick={this.page}
                                            page={4}
                                            icon={this.props.isWMC ? images.firmSupportGeneral : <ToolFilled />}
                                            label={"General Support"}
                                            leftBar={this.props.isWMC}
                                        />
                                        {(this.props.isWMC || this.props.isCoach) &&
                                            <SelectionModal
                                                onClick={this.page}
                                                page={5}
                                                icon={this.props.isWMC ? images.firmSupportFAQ : <ReadOutlined />}
                                                label={"FAQs"}
                                                leftBar={this.props.isWMC}
                                            />
                                        }
                                        <SelectionModal
                                            onClick={this.page}
                                            page={6}
                                            icon={this.props.isWMC ? images.firmSupportFeedback : <NotificationOutlined />}
                                            label={"Feedback"}
                                            leftBar={this.props.isWMC}
                                        />
                                    </Section>
                                }
                                {(this.state.page === 2 || this.state.page === 3) &&
                                    <>
                                        {this.props.isWMC
                                            ? <MemberByCategoryIdLeftBar
                                                expert={this.state.page === 2 ? this.state.expertRM : this.state.expertGeneral}
                                                title={this.state.page === 2 ? "Relationship Manager" : "General Support"}
                                            />
                                            : <MemberByCategoryIdModal
                                                expert={this.state.page === 2 ? this.state.expertRM : this.state.expertGeneral}
                                                title={this.state.page === 2 ? "Relationship Manager" : "General Support"}
                                                scheduleMeeting={this.scheduleMeeting}
                                                // writeMessage={this.writeMessage}
                                            />
                                        }
                                    </>
                                }
                                {this.state.page === 4 &&
                                    <>
                                        {this.props.isWMC
                                            ? <HelpContactLeftBar />
                                            : <HelpContactModal />
                                        }
                                    </>
                                }
                                {this.state.page === 5 &&
                                    <>
                                        {this.props.isWMC
                                            ? <HelpFAQsLeftBar data={env.ENTERPRISE.COMPANY.FAQS} action={this.faqAction}/>
                                            : <HelpFAQsModal data={env.ENTERPRISE.EXPERT.FAQS} action={this.faqAction}/>
                                        }
                                    </>
                                }
                                {this.state.page === 6 &&
                                    <>
                                        {this.props.isWMC
                                            ? <FeedbackLeftBar />
                                            : <FeedbackModal />
                                        }
                                    </>
                                }
                            </ActionBox>
                            {this.state.showScheduler &&
                                <>
                                    <CommonBackground />
                                    <CommonModal>
                                        <SchedulerFlow
                                            userId={this.state.userId}
                                            userFirstName={this.state.firstName}
                                            userLastName={this.state.lastName}
                                            userPhotoUrl={this.state.photo}
                                            eventCustomMinutes={30}
                                            availabilityCloseBtn={true}
                                            availabilityOnClose={() => {return this.changeShowScheduler(false)}}
                                            confirmOnClose={() => {return this.changeShowScheduler(false)}}
                                        />
                                    </CommonModal>
                                </>
                            }
                            {/*{this.state.showEmailer &&*/}
                            {/*    <>*/}
                            {/*        <CommonBackground />*/}
                            {/*        <CommonModal>*/}
                            {/*            <EmailerFlow*/}
                            {/*                id={this.state.userId}*/}
                            {/*                firstName={this.state.firstName}*/}
                            {/*                lastName={this.state.lastName}*/}
                            {/*                photo={this.state.photo}*/}
                            {/*                inModal={true}*/}
                            {/*                showCloseInsteadOfBack={true}*/}
                            {/*                close={() => {return this.changeShowEmailer(false)}}*/}
                            {/*            />*/}
                            {/*        </CommonModal>*/}
                            {/*    </>*/}
                            {/*}*/}
                        </>
                    }
                </>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = state => ({
    allCoaches: state.enterprise.team.coaching.get("coachesAssignedToWMC"),
    allCoachesFetched: state.enterprise.team.coaching.get("coachesAssignedToWMCFetched"),
    isWMC: state.common.wmc.get("isWMC"),
    isCoach: state.common.user.get("isCoach"),
    user: state.common.user,
    showSupport: state.common.user.get("showSupport"),
    isOpen: state.enterprise.support.support.get("isOpen"),
    page: state.enterprise.support.support.get("page")
});

const mapDispatchToProps = dispatch => ({
    setSupportOpen: (isOpen, page) => dispatch(setSupportOpen(isOpen, page)),
    tryGetCoachesAssignedToWMC: () => dispatch(tryGetCoachesAssignedToWMC()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SupportMembers));
