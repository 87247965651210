import React from 'react';
import styled from 'styled-components';
import {connect} from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment/moment';

import {CloseOutlined, StarOutlined} from '@ant-design/icons';

import { colors } from '../../../components/colors';
import { FontTitle21, FontBody16 } from '../../../components/fonts';

export const Content = styled.div`
    width: calc(100% - 40px);
    display: inline-block;
`;
export const Close = styled.div`
    display: inline-block;
    width: 40px;
    text-align: right;
    font-size: 20px;
    cursor: pointer;
`;
export const NoticeRating = styled.div`
    min-height: 30px;
    padding: 35px 60px 25px 60px;
    background-color: ${colors.notification100};
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: ${props => props.zIndex};
    @media screen and (max-width: 1088px) {
        padding: 15px 20px;
        text-align: center;
    }
`;

export const Title = styled.div`
    display: inline-block;
    width: calc(100% - 465px);
    max-width: 519px;
    margin-bottom: 10px;
    @media screen and (max-width: 1440px) {
        display: block;
        width: 100%;
    }
    @media screen and (max-width: 1088px) {
        display: block;
        width: 100%;
        margin: 0px auto 10px auto;
    }
    @media screen and (max-width: 748px) {
        display: block;
    }
`;

export const CTAs = styled.div`
    width: 540px;
    display: inline-block;
    padding: 4px 0px;
    vertical-align: top;
    @media screen and (max-width: 1440px) {
        display: block;
        width: 100%;
    }
`;

export const CTA = styled.div`
    text-align: center;
    padding-right: 60px;
    display: inline-block;
    @media screen and (max-width: 1088px) {
        display: inline-block;
        padding: 0px 10px;
    }
    @media screen and (max-width: 748px) {
        display: block;
    }
`;

export const Icon = styled.div`
    display: inline-block;
    span {
        font-size: 18px;
        padding: 2px 0px;
        color: ${colors.primary100};
        display: inline-block;
        vertical-align: top;
        margin-right: 5px;
    }
`;

export const Subtitle = styled.div`
    display: inline-block;
    vertical-align: top;
    text-decoration: ${props => props.underline};
    color: ${colors.primary100};
`;

export const Pointer = styled.div`
    cursor: pointer;
`;

class NoticeRateCall extends React.Component {
    state = {"isShown": false};

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.platform !== this.props.platform || prevProps.ratingsRemaining !== this.props.ratingsRemaining) {
            return this.init();
        }
    }

    init = async () => {
        if(this.props.ratingsRemaining.size > 0) {
            this.setState({"isShown": true})
        } else {
            this.setState({"isShown": false})
        }
    };

    render() {
        if(!this.state.isShown) {
            return null
        } else {
            return(
                <>
                    {this.props.ratingsRemaining.map((r, index) => {
                        if(moment(r.get("datetime")).isAfter(moment().subtract(7, 'days'))) {
                            return(
                                <NoticeRating zIndex={this.props.platform === 'consumer' ? 10 : index+10} key={r.get("token")}>
                                    <Content>
                                        <Title><FontTitle21>How was your appointment with {this.props.platform === 'consumer' ? r.get("coachName") : r.get("clientName")}?</FontTitle21></Title>
                                        <CTAs>
                                            <CTA><Link to={this.props.platform === 'consumer' ? ("/rating/" + r.get("token")) : ("/client-rating/" + r.get("token"))}>
                                                <FontBody16>
                                                    <Icon><StarOutlined/></Icon>
                                                    <Subtitle underline={"underline"}>Rate my {moment(r.get("datetime")).format("MMM Do")} appointment</Subtitle>
                                                </FontBody16>
                                            </Link></CTA>
                                        </CTAs>
                                    </Content>
                                    <Close onClick={() => {this.setState({"isShown": false})}}><CloseOutlined /></Close>
                                </NoticeRating>
                            )
                        } else {
                            return null
                        }
                    })}
                </>
            )
        }
    }

}
const mapStateToProps = state => ({
    ratingsRemaining: state.common.rating.get("ratingNotRatedClients"),
    platform: state.common.user.get("platform")
});
export const NoticeRateCallWrapper = connect(mapStateToProps, null)(withRouter(NoticeRateCall));
