import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

/* Components */
import {FontTitle30, FontBody21, FontSubtitle21,} from "../../../../common/components/fonts";
import { colors } from "../../../../common/components/colors";
import { images } from "../../../../common/components/images";

/* Middleware */
import { setToolTipUpdate } from "../../tooltip/store/tooltip";
import {trackStepFour, trackStepOne, trackStepThree, trackStepTwo, trackStepZero} from "../../../../common/utils/tracking";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const WidgetContent = styled.div`
  margin-top: 20px;
  padding: 10px;
  justify-content: center;
  align-items: center;
`;
const Icon = styled.div`
  background-image: url(${(props) => props.image});
  background-size: contain;
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  align-self: center;
  justify-self: center;
  @media only screen and (max-width: 735px) {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
  }
`;
const Strikethrough = styled(FontBody21)`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) => props.strike !== true} {
    text-decoration: line-through;
  }
`;
const CubeRow = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 12px;
  @media only screen and (max-width: 735px) {
    padding: 20px 0 20px 0;
  }
`;
const Cursor = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
`;
const Cube = styled.div`
  border-radius: 10px;
  background-color: ${(props) => props.backgroundColor};
  width: 100%;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    color: white;
    background-color: ${colors.action100};
    font-weight: bolder;

    ${Icon} {
      background-image: url(${images.checkboxEmptyGold});
    }
  }

  &:active {
    color: ${colors.primary100};
    background-color: ${colors.action100};
    font-weight: bold;

    ${Icon} {
      background-image: url(${images.checkboxEmptyBlue});
    }
  }
`;
const CubeCompleted = styled.div`
  border-radius: 10px;
  background-color: ${(props) => props.backgroundColor ?? colors.canvaGreen};
  width: 100%;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const CubeItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  justify-content: space-between;
  padding: 20px;
  max-height: 36px;
  gap: 5px;

  @media only screen and (max-width: 735px) {
    max-height: none;
    }

  ${Strikethrough} {
    padding-left: 40px;
  }
`;
const CubeItemText = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-shrink: 2;
  @media only screen and (max-width: 735px) {
    flex-wrap: nowrap;
  }
`;

const CenteredFontSubtitle21 = styled(FontSubtitle21)`
  text-align: center;
`;

const InProgressCertification = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const titles = [
        "Watch three trainings",
        "Complete one coach session",
        "Submit client & peer references",
        "Complete your advisor profile",
    ];
    const links = [
        "/my-certificates/my-trainings",
        "/my-certificates/my-coaches/browse",
        "my-certificates?step=3",
        "/my-certificates/profile",
    ];

    const showToolTip = useSelector((state) =>
        state.enterprise.tooltip.get("showToolTip")
    );
    const toolTipPage = useSelector((state) =>
        state.enterprise.tooltip.get("toolTipPage")
    );

    const tracking = async (index) => {
        switch (index) {
            case 0:
                await dispatch(
                    trackStepOne({
                        mainTrainingsCompleted:
                        props.currentProgress?.step1Pieces.mainTrainingsCompleted,
                    })
                );
                history.push(links[index]);
                break;
            case 1:
                await dispatch(
                    trackStepTwo({
                        sessionCompleted:
                        props.currentProgress?.step2Pieces.sessionCompleted,
                    })
                );
                history.push(links[index]);
                break;
            case 2:
                await dispatch(
                    trackStepThree({
                        peerCompleted: props.currentProgress?.step3Pieces.peerCompleted,
                        clientCompleted: props.currentProgress?.step3Pieces.clientCompleted,
                        peerRedo: props.currentProgress?.step3Pieces.peerRedo,
                        clientRedo: props.currentProgress?.step3Pieces.clientRedo,
                    })
                );
                history.push(links[index]);
                break;
            case 3:
                await dispatch(
                    trackStepFour({
                        profileCompleted:
                        props.currentProgress?.step4Pieces.profileCompleted,
                        calendarCompleted:
                        props.currentProgress?.step4Pieces.calendarCompleted,
                    })
                );
                history.push(links[index]);
                break;
            default:
                return;
        }
    };

    const handleRenew = () => {
        history.push("/my-certificates/renew");
    };

    const backToolTip = () => {
        setToolTipUpdate(true, 2);
    };

    // const nextToolTip = () => {
    //     history.push("/my-certificates/activate");
    // };

    if (props.currentProgress === null)
        return (
            <WidgetContent>
                <CenteredFontSubtitle21>
                    You're not enrolled in a certificate!
                </CenteredFontSubtitle21>
            </WidgetContent>
        );

    return (
        <WidgetContent>
            <CenteredFontSubtitle21>
                {/*{props.currentProgress?.certificationDefinition?.name} ({props.currentProgress?.certificationDefinition?.designation})*/}
                {props.currentProgress?.certificationDefinition?.name === "CERTIFIED ADVISOR FOR WOMEN™"
                    ? "Advisor for Women™ Certificate"
                    : props.currentProgress?.certificationDefinition?.name === "CERTIFIED ADVISOR FOR NEXTGEN™"
                        ? "Advisor for NextGen™ Certificate"
                        : props.currentProgress?.certificationDefinition?.name
                }
            </CenteredFontSubtitle21>
            <CubeRow>
                {props.currentProgress?.stepsCompleted?.map((step, index) => {
                    if (step) {
                        return (
                            <CubeCompleted backgroundColor={colors.white} key={index}>
                                <CubeItem>
                                    <CubeItemText>
                                        <FontTitle30>{index + 1}</FontTitle30>
                                        <Strikethrough strike={true}>{titles[index]}</Strikethrough>
                                    </CubeItemText>
                                    <Icon image={images.checkboxCheckedWhite} />
                                </CubeItem>
                            </CubeCompleted>
                        );
                    } else {
                        return (
                            <Cube
                                backgroundColor={colors.white}
                                key={index}
                                onClick={() => tracking(index)}
                            >
                                <CubeItem>
                                    <CubeItemText>
                                        <FontTitle30>{index + 1}</FontTitle30>
                                        <Strikethrough strike={false}>
                                            {titles[index]}
                                        </Strikethrough>
                                    </CubeItemText>
                                    <Icon image={images.checkboxEmptyWhite} />
                                </CubeItem>
                            </Cube>
                        );
                    }
                })}
            </CubeRow>
        </WidgetContent>
    );
};

export default InProgressCertification;