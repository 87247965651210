import {images} from '../../../common/components/images';
const randomNumber = () => {return Math.floor(Math.random() * 100)+1};

export const devEnterpriseListings = {
    "financialCoachingPlans": [
        {
            "id": 9,
            "title": "TestPlan 3",
            "description": "Lorem ipsum...",
            "coverPhoto": null,
            "termsOfUseUrl": "www.google.com",
            "privacyPolicyUrl": "www.google.com",
            "downloadUrl": "www.download.com",
            "sessionLength": 0,
            "serviceType": "download",
            "status": "Active",
            "plans": [
                {
                    "id": 76,
                    "orderIndex": 3,
                    "description": "I charge $50 per hour. Pricing varies based on project scope. Logo design packages start at $800.",
                    "amount": 0,
                    "planType": "one_time",
                    "subscriptionDurationInMonths" : null,
                    "recurringInterval": null
                }
            ]
        },
        {
            "id": 39,
            "title": "Clarity meeting",
            "description": "Lorem ipsum...",
            "coverPhoto": null,
            "termsOfUseUrl": "www.google.com",
            "privacyPolicyUrl": "www.google.com",
            "downloadUrl": "www.download.com",
            "sessionLength": 120,
            "serviceType": "online",
            "status": "Active",
            "plans": [
                {
                    "id": 7,
                    "orderIndex": 0,
                    "description": "",
                    "amount": 4000,
                    "planType": "one_time",
                    "recurringInterval": null,
                    "subscriptionDurationInMonths" : null,
                    "numberOfSessions": 3
                },
                {
                    "id": 8,
                    "orderIndex": 1,
                    "description": "",
                    "amount": 50000,
                    "planType": "recurring",
                    "recurringInterval": "year",
                    "subscriptionDurationInMonths" : null,
                    "numberOfSessions": 3
                },
                {
                    "id": 9,
                    "orderIndex": 2,
                    "description": "",
                    "amount": 2500,
                    "planType": "recurring",
                    "subscriptionDurationInMonths" : 4,
                    "recurringInterval": "month",
                    "numberOfSessions": 3
                }
            ]
        },
        {
            "id": 344,
            "title": "Help with homework",
            "description": "Lorem ipsum...",
            "coverPhoto": null,
            "termsOfUseUrl": "",
            "privacyPolicyUrl": "",
            "downloadUrl": "www.download.com",
            "sessionLength": 0,
            "serviceType": "offline",
            "status": "Active",
            "plans": [
                {
                    "id": 534,
                    "orderIndex": 3,
                    "description": "",
                    "amount": 0,
                    "planType": "one_time",
                    "subscriptionDurationInMonths" : null,
                    "numberOfSessions": 3
                },
                {
                    "id": 7,
                    "orderIndex": 0,
                    "description": "",
                    "amount": 4000,
                    "planType": "one_time",
                    "recurringInterval": null,
                    "subscriptionDurationInMonths" : null,
                    "numberOfSessions": 3
                },
                {
                    "id": 8,
                    "orderIndex": 1,
                    "description": "",
                    "amount": 50000,
                    "planType": "recurring",
                    "recurringInterval": "year",
                    "subscriptionDurationInMonths" : null,
                    "numberOfSessions": 3
                },
                {
                    "id": 9,
                    "orderIndex": 2,
                    "description": "",
                    "amount": 2525,
                    "planType": "recurring",
                    "subscriptionDurationInMonths" : 4,
                    "recurringInterval": "month",
                    "numberOfSessions": 3
                }
            ]
        }
    ]
};

export const devEnterpriseListing = {
    "id": 9,
    "title": "TestPlan 3",
    "description": "Lorem ipsum...",
    "coverPhoto": null,
    "termsOfUseUrl": "www.google.com",
    "privacyPolicyUrl": "www.google.com",
    "downloadUrl": "www.download.com",
    "sessionLength": 0,
    "serviceType": "download",
    "status": "Active",
    "plans": [
        {
            "id": 7,
            "orderIndex": 0,
            "description": "Buy this plan for 2 meetings, 1 free",
            "amount": 4000,
            "planType": "one_time",
            "recurringInterval": null,
            "subscriptionDurationInMonths" : null,
            "numberOfSessions": 2
        },
        {
            "id": 8,
            "orderIndex": 1,
            "description": "Buy this plan for year of meetings, 4 meetings per month, paid annualy, cancel anytime",
            "amount": 50000,
            "planType": "recurring",
            "recurringInterval": "year",
            "subscriptionDurationInMonths" : null,
            "numberOfSessions": 2
        },
        {
            "id": 9,
            "orderIndex": 2,
            "description": "Buy this plan for 3 monthly meetings, charged monthly for 4 months",
            "amount": 2500,
            "planType": "recurring",
            "subscriptionDurationInMonths" : 4,
            "recurringInterval": "month",
            "numberOfSessions": 2
        }
    ]
};

export const devEnterpriseListingsOrder = (listingIds, listings) => {
    return listings.toJS().map(listing => {
        return {...listing, "orderIndex": (listingIds.indexOf(listing.id)+1)}
    })
};

export const devEnterpriseListingUpdated = (data) => ({
    "id": 1,
    "durationInMonths": 3,
    "coachingSessionsQuota": 4,
    "coachingSessionsLength": data.coachingSessionsLength,
    "totalPriceAmount": 30095,
    "monthlyPriceAmount": 10095,
    "title": data.title,
    "description": data.description,
    "coverPhotoUrl": data.coverPhotoUrl,
    "orderIndex": 1,
    "status": data.status,
    "terms": "",
    "privacy": "",
    "download": null,
    "serviceType": "download",
});

export const devEnterpriseListingCreated = (data, listings) => ({
    "id": randomNumber(),
    "title": data.title,
    "description": data.description,
    "coverPhotoBase64": null,
    "termsOfUseUrl": "https://google.com",
    "privacyPolicyUrl": "https://google.com",
    "downloadUrl": "https://google.com",
    "sessionLength": data.sessionLength,
    "productPlans": [],
    "serviceType": "download",
    "orderIndex": (listings.size+1),
    "status": "Active",
});

export const devServicesExpertProfileData = {
    id: '32',

    firstName: "Jason",
    lastName: "Aronson",
    companyName: 'Coaching, LLC',
    crd: 'test 5',

    photo: images.devDataTeamJasonAvatar,
    introVideoUrl: 'https://www.youtube.com/embed/RZAF1DAqHPk',
    facebookUrl: 'https://facebook.com',
    linkedinUrl: 'https://linkedin.com',
    twitterUrl: 'https://twitter.com',
    address1: 'test 3',
    address2: 'test 6',
    city: 'Boston',
    state: 'MA',
    zipCode: '23443',
    bio: '<p>In an age of pandemic, what true value do any of our backgrounds hold? It’s not like we can look at precedents and say, people who have done ABC tend to be really successful at accomplishing XYZ in a pandemic. My expf</p>',

    // BEG COACH ONLY FIELDS
    endorsement1: 'Test 1',
    endorsement2: 'Test 2',
    endorsement3: null,
    freeTime: 'Test 7',
    whyBecameCoach: 'Test 2',
    coachPhilosophy: 'Test 3',
    highlights: 'Test 1',

    coachingCertification: null,
    coachingCertificationYear: null,

    // END COACH ONLY FIELDS

    bookingAvailability: 'available',

    screeningCallLength: 30,
    defaultCallLength: 30,

    advancedDegree: null,
    advancedDegreeCity: null,
    advancedDegreeInstitution: null,
    advancedDegreeState: null,
    advancedDegreeYear: null,

    otherEducation: null,

    expertCategories: [],

    storeActive: false,
    storeShowScheduler: true,
    storeShowEmailer: true,
    storeTitle: "",
    storePhoto: null,
    storeHandle: "samplestore",

    publicHandle: "sampleadvisor",
    profileActive: true,
    profileShowScheduler: true,
    profileShowEmailer: true,
    website: "fromjason.c",

    schedulerActive: true,
    schedulerHandle: "sampleschedule",
    storeDescription: ""
};

export const devServicesExpertProfileUpdateData = (data) => ({
    'photo': images.devDataTeamJasonAvatar,
    // "highlights": data.highlights,
    "bio": data.bio,
    "whyBecameCoach": data.whyBecameCoach,
    // "coachPhilosophy": data.coachPhilosophy,
    "companyName": data.companyName,
    "crd": data.crd,
    "endorsement1": data.endorsement1,
    "endorsement2": data.endorsement2,
    "endorsement3": data.endorsement3,
    "freeTime": data.freeTime,
    "address1": data.address1,
    "address2": data.address2,
    "city": data.city,
    "state": data.state,
    "zipCode": data.zipCode,
    "introVideoUrl": data.introVideoUrl,
    "facebookUrl": data.facebookUrl,
    "linkedinUrl": data.linkedinUrl,
    "twitterUrl": data.twitterUrl,
    "bookingAvailability": data.bookingAvailability
});