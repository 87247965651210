import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import { CloudUploadOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import { colors } from '../../../components/colors';
import { IconButton, SubmitButtonMyMoney } from '../../../components/inputs';
import { MyMoneyCard } from '../../../components/cards';
import {
    MyMoneyTab,
    MyMoneyTabHeading,
    MyMoneyTabDescrip,
    MyMoneyFormWrapper,
    MyMoneyGraphWrapper,
    MyMoneySectionTitle,
    DeleteButton,
    LineItemHeader,
    LineItemList,
    LineItem,
    LineItemNameInput,
    LineItemValueInput,
    LineItemDateInput,
    GetCoachCTA,
} from '../components/mymoney';
import Sunburst from '../../../components/sunburst';
import { FontSubtitle21 } from '../../../components/fonts';
import { TextAlignCenter } from '../../../components/layout';

import {
    setMymoneySavingAddlineitem,
    setMymoneySavingUpdatelineitem,
    setMymoneySavingDeletelineitem,
} from '../store/mymoney';
import {
    tryUpdateMyMoneySavingsGoals,
    tryGetMyMoneySavingsGoals
} from '../middleware/mymoney';

const PLACEHOLDERS = [
    'Paris Vacation',
    'New Car',
    'Kitchen Renovation',
    'Second Home',
    'College for Grace',
    'Cash Cushion',
];

class MoneySaving extends Component {
    state = {
        focusedLineitem: null,
        savedTime: moment(),
        savedTimeFromNow: '',
        unsavedChanges: null,
        hasCoach: true,
        isLoading: true
    };

    componentDidMount() {
        return this.init()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.unsavedChanges !== this.state.unsavedChanges) {
            this.timeoutID = setTimeout(this.handleSave, 1500);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
        this.timerID = 0;
        clearTimeout(this.timeoutID);
    }

    init = async () => {
        const empty ={};
        const goals = await this.props.tryGetMyMoneySavingsGoals();
        console.log(goals)
        // if (goals.hasOwnProperty('error') || goals.savingsGoals === null || goals.savingsGoals === undefined || balanceSheet.balanceSheet === empty) {
        //     return this.setState({hasCoach: false, isLoading: false});
        // }
        
            return this.setState({isLoading: false});
        
    }

    
    refreshTimer = async () => {
        await this.setState({
            savedTimeFromNow: 'Autosaved ' + this.state.savedTime.fromNow(),
        });
    }

    handleSave = async (e) => {
        this.timerID && await clearInterval(this.timerID);
        await this.props.tryUpdateMyMoneySavingsGoals();
        await this.setState({savedTime: moment(), unsavedChanges: false})
        this.refreshTimer();
        this.timerID = setInterval(() => this.refreshTimer(), 45000);
    }
    handleAddLineItem(e) {
        this.props.setMymoneySavingAddlineitem();
        this.setState({unsavedChanges: true,})
    }
    handleLineItemUpdate(i, field, e) {
        this.props.setMymoneySavingUpdatelineitem(i, field, field === 'amount' ? parseFloat(e.currentTarget.value) : e.currentTarget.value);
        this.setState({unsavedChanges: true,})
    }
    handleLineItemFocus(lid) {
        this.setState({ focusedLineitem: lid });
    }
    handleLineItemBlur() {
        this.setState({ focusedLineitem: null });
    }
    handleLineItemDelete(i) {
        this.setState({ focusedLineitem: null });
        this.props.setMymoneySavingDeletelineitem(i);
        this.setState({unsavedChanges: true,})
    }

    buildSunburstData(goals) {
        return {
            name: 'root',
            children: goals.map((line, i) => ({
                name: line.title,
                size: line.amount,
                color: colors.action100,
            }))
        };
    }


    renderCard({ lineItems=[], color=colors.action100 }) {
        return (
            <MyMoneyCard color={color} noTitle={true} useNewDesign={this.props.useNewDesign} backgroundColor={this.props.useNewDesign ? colors.backgroundColor3 : null}>
                <LineItemList>
                    {lineItems.map((line, i) => (
                        <LineItem key={`LI-${line.lid}`}>
                            <LineItemNameInput
                                defaultValue={line.title}
                                onChange={this.handleLineItemUpdate.bind(this, i, 'title')}
                                onFocus={this.handleLineItemFocus.bind(this, line.lid)}
                                onBlur={this.handleLineItemBlur.bind(this)}
                                label={i === 0 ? 'Title' : null}
                                placeholder={PLACEHOLDERS[i % PLACEHOLDERS.length]}
                            />
                            <LineItemValueInput
                                defaultValue={line.amount}
                                onChange={this.handleLineItemUpdate.bind(this, i, 'amount')}
                                onFocus={this.handleLineItemFocus.bind(this, line.lid)}
                                onBlur={this.handleLineItemBlur.bind(this)}
                                label={i === 0 ? 'Cost' : null}
                                min="0"
                            />
                            <LineItemDateInput
                                defaultValue={line.target}
                                onChange={this.handleLineItemUpdate.bind(this, i, 'target')}
                                onFocus={this.handleLineItemFocus.bind(this, line.lid)}
                                onBlur={this.handleLineItemBlur.bind(this)}
                                label={i === 0 ? 'Target Date' : null}
                            />
                            {this.state.focusedLineitem === line.lid && <DeleteButton onMouseDown={this.handleLineItemDelete.bind(this, i)} />}
                        </LineItem>
                    ))}
                    <IconButton
                        backgroundColor={colors.action100}
                        iconComponent={PlusOutlined}
                        color={colors.white}
                        label="Add Line Item"
                        style={{ display: 'block', margin: '5px auto 0' }}
                        onClick={this.handleAddLineItem.bind(this)}
                    />
                </LineItemList>
            </MyMoneyCard>
        );
    }

    render() {
        if (this.state.isLoading) {
            return null;
        }
        else if (!this.state.hasCoach) {
            return <GetCoachCTA history={this.props.history}/>
        } else {
            return (
                <MyMoneyTab>
                    {this.props.useNewDesign && <TextAlignCenter>
                        {/*<FontSubtitle21>Savings Goals</FontSubtitle21>*/}
                        {/*<br/>*/}
                    </TextAlignCenter>}
                    <MyMoneyTabHeading>Get specific about your savings goals!</MyMoneyTabHeading>
                    <MyMoneyTabDescrip>Name your goals and note your plans to achieve them below.</MyMoneyTabDescrip>
                    <MyMoneyFormWrapper>
                        <MyMoneySectionTitle padded={true} center={true}>
                            {/* <SubmitButtonMyMoney
                                iconComponent={CloudUploadOutlined}
                                backgroundColor={colors.progress100}
                                color={colors.white}
                                label={this.props.unsaved ? 'Save' : 'Saved'}
                                onClick={this.handleSave.bind(this)}
                                disabled={!this.props.unsaved}
                            /> */}
                            {this.state.unsavedChanges !== null && <span>{this.state.savedTimeFromNow}</span>}
                        </MyMoneySectionTitle>
                        {this.renderCard.call(this, { lineItems: this.props.goals })}
                    </MyMoneyFormWrapper>
                    <MyMoneyGraphWrapper>
                        <Sunburst data={this.buildSunburstData(this.props.goals)} explanationText="of your goals are for " />
                    </MyMoneyGraphWrapper>
                </MyMoneyTab>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        goals: state.common.mymoney.getIn([ 'saving', 'goals' ]).toJS(),
        unsaved: state.common.mymoney.getIn([ 'saving', 'unsaved' ]),
    };
};
const mapDispatchToProps = dispatch => ({
    setMymoneySavingAddlineitem: () => dispatch(setMymoneySavingAddlineitem()),
    setMymoneySavingUpdatelineitem: (i, field, value) => dispatch(setMymoneySavingUpdatelineitem(i, field, value)),
    setMymoneySavingDeletelineitem: (i) => dispatch(setMymoneySavingDeletelineitem(i)),
    tryGetMyMoneySavingsGoals: () => dispatch(tryGetMyMoneySavingsGoals()),
    tryUpdateMyMoneySavingsGoals: () => dispatch(tryUpdateMyMoneySavingsGoals()),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MoneySaving));