import React from 'react';
import {v4 as uuid4} from "uuid";

/* Components */
import {RadioBoxWithLabelWhite, InputLabel, TextareaInputNoCountWhite} from "../../../common/components/inputs";
import {SaveSection, SubmitInputWithValidation} from '../../components/clientscreate';
import {FontBody12, FontTitle18, FontBody16, FontBody14} from '../../../common/components/fonts';
import {ButtonTertiary} from "../../../common/components/buttons";
import {Width, FitContent, Inset, Section, SplitCenter, SplitLeft, SplitRight, FitAdd, Inline, Btn, Name, Break, OnePlan} from '../components/listings';
import ListingPricePlan from "./listingpriceplan";
import {PlusOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {Red} from "../components/plans";
import validator from "validator";

class ListingPrice extends React.Component {
    state = {
        plans: [], deletedPlans: [], newPlans: [],
        showAddPlan: false,
        description: '', descriptionValid: true,
        onlinePurchaseChecked: false, freeChecked: true, customPriceChecked: false,
        isValid: true,
        isChanged: false,
        edit: true
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.data !== this.props.data) {
            return this.init();
        }
    }

    init = async () => {
        if(Object.keys(this.props.data).length > 0) {
            this.setState({...this.props.data});
        } else {
            await this.addPlan();
            this.checkValid();
        }
    };

    editPlan = async (planId, updates, open) => {
        await this.setState({
            "plans": this.state.plans.map(p => {
                if(p.id === planId) {
                    return({...p, ...updates, "planOpen": open})
                } else {
                    return(p)
                }
            }),
            "newPlans": this.state.plans.map(p => {
                if(p.id === planId) {
                    return({...p, ...updates, "planOpen": open})
                } else {
                    return(p)
                }
            }),
            "showAddPlan": !open,
            "isValid": !open,
            "isChanged": !open
        });
    };

    addPlan = async () => {
        let plans = this.state.plans;
        let newPlans = this.state.newPlans;
        const plan = {
            id: uuid4(),
            planOpen: true,
            upfrontChecked: false, scheduledChecked: false, subscriptionChecked: false,
            upfrontPrice: 0, upfrontPriceValid: true,
            scheduledMonthlyPrice: 0, scheduledMonthlyPriceValid: true,
            scheduledNumMonths: 1, scheduledNumMonthsValid: true,
            subscriptionFrequency: "month",
            subscriptionPrice: 0, subscriptionPriceValid: true,
            isValid: false,
            newPlan: true,
            deletedPlan: false
        };
        plans.push(plan);
        newPlans.push(plan);
        await this.setState({
            "plans": plans,
            "newPlans": newPlans,
            "showAddPlan": false,
            "isValid": false,
            "isChanged": true
        });
    };

    deletePlan = async (planId) => {
        let plans = await this.state.plans.filter(p => {return(p.id !== planId)});
        let deletedPlans = this.state.deletedPlans;
        let newPlans = this.state.newPlans;
        if(!this.props.createPlan) {
            newPlans = await this.state.newPlans.filter(p => {return(p.id !== planId)});
            let deletePlan = await this.state.plans.filter(p => {return(p.id === planId)})[0];
            deletedPlans.push(deletePlan);
        }
        await this.setState({
            "plans": plans,
            "deletedPlans": deletedPlans,
            "newPlans": newPlans,
            "showAddPlan": true,
            "isValid": plans.length > 0,
            "isChanged": plans.length > 0
        });
        if(plans.length === 0) {
            await this.addPlan();
        }
    };

    handleChangeDescription = async e => {
        await this.setState({
            'description': e.target.value,
            'descriptionValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''),
            'isChanged': true
        });
        this.checkValid();
    };

    handleAllowFreePurchase = async () => {
        await this.setState({"freeChecked": true, "onlinePurchaseChecked": false, "customPriceChecked": false});
        this.checkValid();
    };

    handleAllowOnlinePurchase = async () => {
        await this.setState({"freeChecked": false, "onlinePurchaseChecked": true, "customPriceChecked": false});
        this.checkValid();
    };

    handleAllowCustomPrice = async () => {
        await this.setState({"freeChecked": false, "onlinePurchaseChecked": false, "customPriceChecked": true});
        this.checkValid();
    };

    checkValid = async () => {
        if(this.state.freeChecked) {
            this.setState({'isValid': true})
        }
        if(this.state.onlinePurchaseChecked) {
            this.setState({'isValid': this.state.plans.filter(p => {return(p.planOpen)}).length === 0})
        }
        if(this.state.customPriceChecked) {
            if(this.state.description === "" || this.state.description === null || this.state.description === undefined || this.state.description === "<p><br></p>") {
                this.setState({'isValid': false})
            } else {
                this.setState({'isValid': true})
            }
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            await this.props.save(this.state);
            return this.props.next()
        }
    };

    render() {
        return(
            <Width>
                <form method={"post"} onSubmit={this.handleSubmit}>
                    <Section>
                        <InputLabel><FontTitle18>How would you like to charge?</FontTitle18></InputLabel>
                        <br />
                        <br />
                        {/*<br />*/}
                        {/*<RadioBoxWithLabelWhite*/}
                        {/*    id={6}*/}
                        {/*    action={this.handleAllowFreePurchase}*/}
                        {/*    checked={this.state.freeChecked}*/}
                        {/*    label={"Free"}*/}
                        {/*    subtext={"Offer this product for free."}*/}
                        {/*    disabled={false}*/}
                        {/*/>*/}
                        <br />
                        <RadioBoxWithLabelWhite
                            id={5}
                            action={this.handleAllowOnlinePurchase}
                            checked={this.state.onlinePurchaseChecked}
                            label={"Online Purchase"}
                            subtext={"Buyers pay online by credit card."}
                            disabled={false}
                        />
                        {this.state.onlinePurchaseChecked &&
                            <Inset>
                                {this.state.plans.map(p => {
                                    if(p.planOpen) {
                                        return(
                                            <div key={p.id}>
                                                <ListingPricePlan
                                                    data={p}
                                                    save={this.editPlan}
                                                    isFirstPlan={this.state.plans.length === 1}
                                                    cancel={this.deletePlan}
                                                    downloadChecked={this.props.downloadChecked}
                                                />
                                            </div>
                                        )
                                    } else {
                                        return(
                                            <OnePlan key={p.id}>
                                                <FontBody16>
                                                    <Name>
                                                        {/*{p.freeChecked && <Break>Free</Break>}*/}
                                                        {p.upfrontChecked && <Break>{p.upfrontPrice} upfront</Break>}
                                                        {p.scheduledChecked && <Break>{p.scheduledMonthlyPrice}/month for {p.scheduledNumMonths} {p.scheduledNumMonths === 1 ? "month" : "months"}</Break>}
                                                        {p.subscriptionChecked && <Break>{p.subscriptionPrice}/{p.subscriptionFrequency}</Break>}
                                                        {/*{p.customPriceChecked && <Break>{p.description}</Break>}*/}
                                                    </Name>
                                                    {this.state.edit && <Inline><Btn onClick={() => {return this.editPlan(p.id, {}, true)}}><EditOutlined />&nbsp;Edit</Btn></Inline>}
                                                    <Inline><Btn onClick={() => {return this.deletePlan(p.id)}}><DeleteOutlined />&nbsp;Delete</Btn></Inline>
                                                </FontBody16>
                                            </OnePlan>
                                        )
                                    }
                                })}
                                {this.state.showAddPlan &&
                                    <FitAdd onClick={this.addPlan}><FontBody14><PlusOutlined />&nbsp;Payment Option</FontBody14></FitAdd>
                                }
                            </Inset>
                        }
                        {/*<br />*/}
                        {/*<RadioBoxWithLabelWhite*/}
                        {/*    id={5}*/}
                        {/*    action={this.handleAllowCustomPrice}*/}
                        {/*    checked={this.state.customPriceChecked}*/}
                        {/*    label={"Online Invoicing"}*/}
                        {/*    subtext={"Send invoices to clients to pay online."}*/}
                        {/*    disabled={false}*/}
                        {/*/>*/}
                        {this.state.customPriceChecked &&
                            <Inset>
                                <TextareaInputNoCountWhite
                                    title={"Description"}
                                    valid={this.state.descriptionValid}
                                    warning={null}
                                    id={"description"}
                                    onChange={this.handleChangeDescription}
                                    placeholder={"Describe how you charge for your product."}
                                    value={this.state.description}
                                    minLength={0}
                                    minRows={4}
                                />
                                <Red><FontBody16>WARNING! Clients must contact you to purchase.</FontBody16></Red>
                                <br />
                            </Inset>
                        }
                    </Section>
                    <SaveSection>
                        <SplitLeft>
                            <FitContent onClick={this.props.back}><ButtonTertiary canSubmit={false} label={"Back"} /></FitContent>
                        </SplitLeft>
                        <SplitCenter><FontBody12> </FontBody12></SplitCenter>
                        <SplitRight>
                            <FitContent><SubmitInputWithValidation label={"Done!"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={false} /></FitContent>
                        </SplitRight>
                    </SaveSection>
                </form>
            </Width>
        )
    }
}

export default ListingPrice;
