import React from "react";
import styled from "styled-components";

/* Components */
import {FontBody16, FontTitle30} from "../../../common/components/fonts";
import {ButtonPrimary} from "../../../common/components/buttons";

/* Static */
import {images} from "../../../common/components/images";
import {colors} from "../../../common/components/colors";

export const Center = styled.div`
    margin-top: 30px;
    text-align: center;
    color: ${colors.primary100};
`;

export const SummaryIcon = styled.img`
    width: 100px;
    margin: 20px auto;
    height: 100px;
`;

export const SummaryTitle = styled.div`
    padding-bottom: 30px;
`;

export const FitContent = styled.div`
    width: fit-content;
    cursor: pointer;
    margin: 0px auto;
`;

const GrayDark = styled.div`
    color: ${colors.secondary100};
    @media screen and (max-width: 460px) {
        margin-top: 20px;
        margin-bottom: 10px;
    }
`;

const Confirm = ({confirmOnClose, confirmCloseBtn, confirmCloseBtnLabel}) => (
    <Center>
        <SummaryIcon src={images.logoIcon}/>
        <SummaryTitle>
            <FontTitle30>Form Submitted!</FontTitle30>
            <GrayDark><FontBody16>Your answers to each question have been submitted.</FontBody16></GrayDark>
        </SummaryTitle>
        {confirmCloseBtn &&
            <FitContent onClick={confirmOnClose}>
                <ButtonPrimary submit={false} label={confirmCloseBtnLabel} />
            </FitContent>
        }
        <br />
        <br />
    </Center>
);

export default Confirm;