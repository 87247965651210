import 'moment-timezone/builds/moment-timezone-with-data-2012-2022';
import moment from 'moment-timezone';
import moment2 from 'moment/moment';

export const formatTZ = (timeZone) => {
    if(timeZone === "eastern") {return("America/New_York")}
    else if(timeZone === "central") {return("America/Chicago")}
    else if(timeZone === "mountain") {return("America/Denver")}
    else if(timeZone === "pacific") {return("America/Los_Angeles")}
};

export const adjForTZ = (event, timeZone) => {
    let adjStart = moment.utc(event.start).tz(formatTZ(timeZone)).format().toString();
    let adjEnd = moment.utc(event.end).tz(formatTZ(timeZone)).format().toString();
    return({...event, start: adjStart, end: adjEnd});
};

export const adjForTZNoConvert = (event, timeZone) => {
    let adjStart = moment.utc(event.start).tz(timeZone).format().toString();
    let adjEnd = moment.utc(event.end).tz(timeZone).format().toString();
    return({...event, start: adjStart, end: adjEnd});
};

export const adjForDST = (start, timeZone) => {
    console.log('in timezome, start', start, ' and timeZone', timeZone);
    let startUTC = moment.utc(start).tz(formatTZ(timeZone));
    let nowUTC = moment.utc(moment()).tz(formatTZ(timeZone));
    console.log('in timezone, startUTC', startUTC);
    if (nowUTC.isDST() && !startUTC.isDST()) {
        console.log('nowUTC.isDST() && !startUTC.isDST()');
        return moment2(start).add(1, "hour");
    } else if (!nowUTC.isDST() && startUTC.isDST()) {
        return moment2(start).subtract(1, "hour");
    } else {
        return start;
    }
};
