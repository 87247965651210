import axios from "axios";
import { throwError } from "./error";

/* Domain */
import getDomainEnv from "../../domains/utils";
const env = getDomainEnv();

const testAuthenticated = (err) => {
    // This should be refactored to check for http response status
    // If error status is 401, then is authentication issue, go to login
    // Every other error that is 400 is some error with explanation, we should display error to user (toast notifications?).
    // If error is not 400 nor 401 then something is broken, go to error page.
    const isAuthenticationErrorMessage = (message) =>
        [
            "user must be authenticated",
            "user must be an expert",
            "user must be expert",
            "user must a expert",
            "user must be lily",
            "user must a lily",
            "user must be an lily",
            "user must be a lily",
            "user is not lily",
            "user must be advisor",
            "user is not advisor",
            "user is not active",
        ].includes(message.replace(".", "").toLowerCase());

    const userHasNoAccess =
        err.response &&
        err.response.data &&
        err.response.data.errorDetails &&
        isAuthenticationErrorMessage(err.response.data.errorDetails);

    if (userHasNoAccess) {
        if (env.ENVIRONMENT === "DEVELOPMENT")
            window.location.replace("http://" + env.URL.HOSTNAME + "/login");
        else
            return window.location.replace(
                "https://" + env.URL.AUTH + "/login"
            );
    } else {
        return throwError(err);
    }
};

axios.defaults.withCredentials = true;
axios.interceptors.request.use((config) => {
    if (
        config.baseURL !== undefined &&
        config.baseURL.startsWith("https") &&
        env.ENVIRONMENT === "DEVELOPMENT"
    )
        config.baseURL = config.baseURL.replace("https", "http");
    return config;
});

axios.interceptors.response.use(
    (res) => res.data,
    (err) => {
        if (err.request.responseURL.endsWith("apiv1/auth")) {
            return Promise.resolve("logout");
        }
        return testAuthenticated(err);
    }
);

export const axiosReq = (url, method, json, devRes) => {
    let apiUrl = "https://" + env.URL.API + "/" + url;
    if (env.ENVIRONMENT === "DEVELOPMENT")
        apiUrl = "http://" + env.URL.API + "/" + url;

    //COMMENT THESE 2 LINES IF YOU USE LOCAL DEV SERVER
    if (env.ENVIRONMENT === "DEVELOPMENT") return devRes;

    if (method === "GET") {
        return axios.get(apiUrl, { params: json }).then((data) => data);
    } else if (method === "POST") {
        return axios.post(apiUrl, json).then((data) => {
            if (apiUrl.endsWith("apiv1/auth")) {
                console.log("POST REQUEST");
                console.log(data);
            }
            return data;
        });
    } else if (method === "POST_FORMDATA") {
        return axios
            .post(apiUrl, json, {
                ContentType: "multipart/form-data",
            })
            .then((data) => data);
    } else if (method === "PUT") {
        return axios.put(apiUrl, json).then((data) => data);
    } else if (method === "PATCH") {
        return axios.patch(apiUrl, json).then((data) => data);
    } else if (method === "DELETE") {
        return axios.delete(apiUrl, { data: json }).then((data) => data);
    }
};

export const axiosReqJSON = (url) => {
    const config = {
        headers: { "Content-Type": "application/json" },
    };
    return axios
        .get(url, config)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return testAuthenticated(err);
        });
};