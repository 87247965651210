import styled from "styled-components";
import {Link} from "react-router-dom";

/* Components */
import {colors} from "../../../common/components/colors";

export const NavPage = styled.div`
    @media screen and (min-width: 1088px) {
        display: none !important;
    }
    ${props => props.status !== true} {
        display: block;
    }
    ${props => props.status !== false} {display: none;}
    padding: 30px 30px 0px 30px;
    background-color: ${colors.background100};
    @media screen and (max-width: 460px) {
        padding: 30px 15px 0px 15px;
    }
    border-bottom: 1px solid ${colors.border100};
`;

export const Main = styled.div`
    > * { 
        &:last-child {
            margin-bottom: 25px;
        } 
    }
`;

export const LinkStyled = styled(Link)`
    ${props => props.status !== 'active'} {
        color: ${colors.primary100};
        font-weight: bold;
    }
    ${props => props.status !== 'inactive'} {
        color: ${colors.secondary100};
    }
    padding: 5px 0px;
    cursor: pointer;
    display: block;
    & :hover {
        color: ${colors.primary100};
        font-weight: bold;
    }
`;

export const ModalStyled = styled.div`
    color: ${colors.secondary100};
    padding: 5px 0px;
    cursor: pointer;
    display: block;
    & :hover {
        color: ${colors.primary100};
        font-weight: bold;
    }
`;