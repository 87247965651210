import React from 'react';
import {withRouter} from 'react-router-dom';
import styled from "styled-components";

/* Containers */
import Team from './containers/teamall';
import TeamOne from "./containers/teamone";
import TeamIntroduceModal from "./containers/teamintroducemodal";
import AnnouncementModal from './containers/announcementmodal';
import Announcements from './containers/announcements';

/* Navs */
import {PageContent, PageFull, PageRight} from "../navbars/components/navlayout";
import NavPrimaryAll from "../navbars/containers/primaryall";
import NavSecondaryTop from "../navbars/containers/secondarytop";
import {PageTabs} from '../components/clientsdetail';

/* Components */
import {PlusOutlined} from "@ant-design/icons";

export const PageActions = styled.div`
    position: absolute;
    width: 280px;
    left: calc(100% + 60px);
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 768px) {
        position: relative;
        left: 0px;
        top: 0px;
        display: block;
        width: 100%;
        margin-left: 0px;
    }
`;

const teamButtons = [
    {
        "pathname": "/experts",
        "label": <><PlusOutlined /> Build My Team</>,
        "buttonType": "primary"
    }
];

const MyTeam = ({location}) => (
    <>
        <NavPrimaryAll />
        <PageFull>
            <PageRight>

                {location.pathname === "/team" &&
                    <>
                        <NavSecondaryTop title={"My Team"} buttons={teamButtons} desc={"View your team of Willow experts"}/>
                        <PageTabs>
                            <PageActions>
                                <Announcements />
                            </PageActions>
                            <PageContent>
                                <Team/>
                            </PageContent>
                        </PageTabs> 
                    </>
                }

                {location.pathname === "/team/member" &&
                    <>
                        <NavSecondaryTop title={"Team Member"} />
                        <PageContent>
                            <TeamOne />
                        </PageContent>
                    </>
                }

                <TeamIntroduceModal />
                <AnnouncementModal />
            </PageRight>
        </PageFull>
    </>
);

export default withRouter(MyTeam);
