import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";

/* Components */
import {FontBody14, FontTitle18} from "../../../common/components/fonts";
import {Message} from "../../../common/components/messages";
import {ButtonPrimary} from "../../../common/components/buttons";
import {PlusOutlined} from "@ant-design/icons";
import {colors} from "../../../common/components/colors";


const Left = styled.div`
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 160px);
`;
const TitleSection = styled.div`
    margin-bottom: 35px;
`;
const Center = styled.div`
    margin: 0px auto 10px auto;
    text-align: center;
    width: fit-content;
`;
const TextCenter = styled.div`
    margin: -35px auto 25px auto;
    text-align: center;
    width: fit-content;
`;
const Background = styled.div`
    background-color: ${colors.border30};
    padding: 20px;
    @media screen and (max-width: 640px) {    
        padding: 10px;
    }
    margin-bottom: 30px;
`;
const MarginLess = styled.div`
    margin-top: -30px;
`;
const Gray = styled.div`
    color: ${colors.primary70};
`;

const CreateFirstService = () => (
    <>
        {/*<TitleSection>*/}
        {/*    <Left><FontTitle18>Services</FontTitle18></Left>*/}
        {/*    /!*<Right><Link to={"/listings/create"}><ButtonPrimary canSubmit={false} label={<><PlusOutlined /> Create</>} /></Link></Right>*!/*/}
        {/*</TitleSection>*/}
        <Background>
            <MarginLess />
            <Message text={"Create your first product!"} />
            <TextCenter><Gray><FontBody14>These are products that prospects and clients can purchase</FontBody14></Gray></TextCenter>
            <Center><Link to={"/products/create"}><ButtonPrimary canSubmit={false} label={<><PlusOutlined /> Create</>} /></Link></Center>
        </Background>
    </>
);

export default CreateFirstService;