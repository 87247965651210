import React from 'react';
import styled from 'styled-components';
import validator from "validator";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

/* Components */
import {CheckboxWithLabel, PasswordInput, PhoneInput, TextInput} from "../../../common/components/inputs";
import {ButtonInactive, ButtonPrimary} from "../../../common/components/buttons";
import {colors} from "../../../common/components/colors";
import {FontBody14, FontBody12} from "../../../common/components/fonts";

/* Middleware */
import {trySignUpTestExists, tryRegisterAdvisorAndWMC} from "../middleware/signup";

/* Utils */
import {timeZoneOptionsByRegion} from "../../../common/utils/timezone2";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const SubmitInputWithValidation = ({ isChanged, isValid, isSaving, label }) => {
    if(isChanged && isValid) {
        if(isSaving) {
            return (<ButtonInactive canSubmit={true} label={"Saving"} />)
        } else {
            return (<ButtonPrimary canSubmit={true} label={label}/>)
        }
    } else {
        return(<ButtonInactive canSubmit={true} label={label}/>)
    }
};
const Container = styled.div`
    max-width: 768px;
    width: calc(100% - 20px);
    padding: 0 10px;
    margin: 0 auto 0 auto;
`;
const Red = styled(FontBody14)`
    color: ${colors.alert100};
`;
const DisclosureCheckbox = styled(FontBody12)`
    color: ${colors.white};
    & a {
        color: ${colors.white};
    }
`;
const PasswordDisclosure = styled(FontBody12)`
    margin-left: 45px;
    color: ${colors.white};
    margin-top: -15px;
`;

const OnlySubmit = styled.div`
    margin: 50px auto 0 auto;
    text-align: center;
    & button > div {
        min-width: 160px;
    }
`;
const email_blacklist = ['yahoo.co.uk', '.edu'];

class AdvisorSignUp extends React.Component {
    static defaultProps = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        wmcEmployeeId: null,
        signUpErrorLogin: null,
        hidePrefilledInputs: false,
        registerComplete: () => {},
        isReferredByPartner: false,
        partnerPrepaidAdvisorSessions: 0,
        partnerPrepaidTrainingSessions: 0,
    };

    state={
        inputsPhoneShow: false,
        containerStyles: {backgroundColor: colors.backgroundColor2, borderRadius: "20px", padding: "30px 45px", width: "calc(100% - 90px)"},
        inputsContainersStyles: {margin: "0px 0px 20px 0px"},
        inputsStyles: {borderRadius: "25px", lineHeight: "36px", backgroundColor: colors.white},
        "firstName": "", "firstNameValid": true, "firstNamePreset": false,
        "lastName": "", "lastNameValid": true, "lastNamePreset": false,
        "email": "", "emailValid": true, "emailPreset": false,
        "phone": "", "phoneValid": true, "phonePreset": false,
        "disclosureCheckStatus": false, "disclosureValid": true,
        "password": "", "passwordValid": true, "passwordSecurityNum": 0,
        "signUpError": null,
        "isValid": true,
        "isChanged": false,
        "isSaving": false,
        "isSaved": true,
        "isLoading": true,
    };

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    init = async () => {
        await this.setState({
            "firstName": this.props.firstName,
            "lastName": this.props.lastName,
            "email": this.props.email,
            "phone": this.props.phone,
            "firstNamePreset": this.props.firstName !== "",
            "lastNamePreset": this.props.lastName !== "",
            "emailPreset": this.props.email !== "",
            "phonePreset": this.props.phone !== "",
            "isLoading": false
        });
        this.setValid();
        console.log(this.props)
    };
    setValid = () => {
        const personalDomains = ['outlook.com', 'gmail.com', 'yahoo.com', 'inbox.com', 'icloud.com', 'mail.com', 'gmx.com', 'yandex.com', 
                                'proton.me']
        const isFirstNameValid = (!validator.isEmpty(this.state.firstName) && this.state.firstName !== '');
        const isLastNameValid = (!validator.isEmpty(this.state.lastName) && this.state.lastName !== '');
        const personalEmail = personalDomains.includes(this.state.email.split("@")[1]);
        const isEmailValid = (validator.isEmail(this.state.email, {host_blacklist: email_blacklist}) && !personalEmail);
        const isPhoneValid = true // validator.isMobilePhone(this.state.phone, 'en-US') || validator.isEmpty(this.state.phone);
        const isDisclosureValid = this.state.disclosureCheckStatus;
        const isPasswordValid = (!validator.isEmpty(this.state.password) && this.state.password !== '');
        this.setState({
            "firstNameValid": isFirstNameValid,
            "lastNameValid": isLastNameValid,
            "emailValid": isEmailValid,
            "personalEmail": personalEmail,
            "phoneValid": isPhoneValid,
            "disclosureValid": isDisclosureValid,
            "passwordValid": isPasswordValid,
            'isValid': (isFirstNameValid && isLastNameValid && isEmailValid && isPhoneValid && isDisclosureValid && isPasswordValid)
        })
    };

    handleChangeFirstName = async e => {
        await this.setState({
            'firstName': e.target.value,
            'firstNameValid': (!validator.isEmpty(this.state.firstName) && this.state.firstName !== ''),
            'isChanged': true
        });
        this.setValid();
    };

    handleChangeLastName = async e => {
        await this.setState({
            'lastName': e.target.value,
            'lastNameValid': (!validator.isEmpty(this.state.lastName) && this.state.lastName !== ''),
            'isChanged': true
        });
        this.setValid();
    };

    handleChangeEmail = async e => {
        await this.setState({
            'email': e.target.value,
            'emailValid': validator.isEmail(this.state.email),
            'isChanged': true
        });
        this.setValid();
    };

    handleChangePhone = async e => {
        await this.setState({
            'phone': e.target.value.replace('(', '').replace(')', '').replace(' ', '').replace('-', ''),
            'phoneValid': validator.isMobilePhone(this.state.phone, 'en-US') || validator.isEmpty(this.state.phone),
            'isChanged': true
        });
        this.setValid();
    };

    handleChangeDisclosureCheckStatus = async e => {
        await this.setState({
            "disclosureCheckStatus": !this.state.disclosureCheckStatus,
            "disclosureValid": this.state.disclosureCheckStatus,
            'isChanged': true
        });
        this.setValid();
    };

    handleChangePassword = async e => {
        const newPass = e.target.value;
        await this.setState({
            'password': newPass,
            'passwordValid': (!validator.isEmpty(newPass) && newPass !== ''),
            'passwordSecurityNum': await this.textPasswordSecurityNum(newPass),
            'isChanged': true,
        });
        this.setValid();
    };

    textPasswordSecurityNum = async (password) => {
        let symbolFormat = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        let hasSymbol = symbolFormat.test(password);
        let empty = (validator.isEmpty(password) || password === '');
        let charValBelow6 = (password.length < 7);
        let charVal8 = (password.length > 7);
        let numValid = !validator.isAlpha(password);
        let letterValid = !validator.isNumeric(password);
        if(empty) {
            return 0
        } else if(charValBelow6) {
            return (1)
        } else {
            return ((hasSymbol === true ? 1 : 0) + (charVal8 === true ? 1 : 0) + (numValid === true ? 1 : 0) + (letterValid === true ? 1 : 0));
        }
    };

    showErrorMessage = (message) => {
        this.setState({"signUpError": message});
        this.timeout = setTimeout(() => {this.setState({'signUpError': null})}, 5000);
    }

    // registerAdvisor = async () => {
    //     const region = Intl.DateTimeFormat().resolvedOptions().timeZone;
    //     const timeZone = timeZoneOptionsByRegion(region);
    //     return this.props.tryRegisterAdvisor(this.state.firstName, this.state.lastName, this.state.email.toLowerCase(), this.state.phone, timeZone, this.state.password, this.props.wmcEmployeeId);
    // }

    // registerWmc = async () => {
    //     const email = this.state.email.toLowerCase();
    //     const webDomain = email.split("@")[1]
    //     return this.props.tryRegisterWmc(webDomain, webDomain, this.state.firstName, this.state.lastName, email)
    // }

    registerAdvisorAndWmc = async () => {
        const region = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timeZone = timeZoneOptionsByRegion(region);
        return this.props.tryRegisterAdvisorAndWMC(this.state.firstName, this.state.lastName, this.state.email.toLowerCase(), this.state.phone, timeZone, this.state.password, this.props.isReferredByPartner, this.props.wmcEmployeeId, this.props.partnerPrepaidAdvisorSessions, this.props.partnerPrepaidTrainingSessions);
    }

    handleSubmit = async e => {
        e.preventDefault();
        const register = await this.registerAdvisorAndWmc();
        if(register.hasOwnProperty("error")) {
            this.showErrorMessage(register.error)
        } else {
            this.props.registerComplete(register);
            this.setState({"isValid":false})
        }
        // const hasAccount = await this.props.trySignUpTestExists(this.state.email.toLowerCase(), null);
        // if (hasAccount) {
        //     this.showErrorMessage("Account already exists.")
        // } else {
        //     const register = await this.registerAdvisor();
        //     if(register.hasOwnProperty("error")) {
        //         if(register.error.includes("is not setup with Willow")) {
        //             await this.registerWmc();
        //             await this.registerAdvisor();
        //             this.props.registerComplete();
        //         } else {
        //             this.showErrorMessage(register.error)
        //         }
        //     } else {
        //         this.props.registerComplete();
        //     }
        // }
    }

    render() {
        return(
            <Container style={this.state.containerStyles}>
                <form method={"post"} onSubmit={this.handleSubmit}>
                    {(!this.state.firstNamePreset || !this.props.hidePrefilledInputs) &&
                        <TextInput
                            title={""}
                            valid={this.state.firstNameValid}
                            warning={""}
                            id={"firstName"}
                            onChange={this.handleChangeFirstName}
                            placeholder={"First name"}
                            value={this.state.firstName}
                            containerStyles={this.state.inputsContainersStyles}
                            inputStyles={this.state.inputsStyles}
                        />
                    }
                    {(!this.state.lastNamePreset || !this.props.hidePrefilledInputs) &&
                        <TextInput
                            title={""}
                            valid={this.state.lastNameValid}
                            warning={""}
                            id={"lastName"}
                            onChange={this.handleChangeLastName}
                            placeholder={"Last name"}
                            value={this.state.lastName}
                            containerStyles={this.state.inputsContainersStyles}
                            inputStyles={this.state.inputsStyles}
                        />
                    }
                    {(!this.state.emailPreset || !this.props.hidePrefilledInputs) &&
                        <TextInput
                            title={""}
                            valid={this.state.emailValid}
                            warning={""}
                            id={"email"}
                            onChange={this.handleChangeEmail}
                            placeholder={"Email address"}
                            value={this.state.email}
                            containerStyles={this.state.inputsContainersStyles}
                            inputStyles={this.state.inputsStyles}
                        />
                    }
                    {this.state.personalEmail && <PasswordDisclosure style={{color: colors.alert100, paddingBottom: '20px'}}>Company email required</PasswordDisclosure>}
                    {(this.state.inputsPhoneShow && (!this.state.phonePreset || !this.props.hidePrefilledInputs)) &&
                        <PhoneInput
                            title={""}
                            valid={this.state.phoneValid}
                            warning={""}
                            id={"phone"}
                            onChange={this.handleChangePhone}
                            placeholder={"(   )    -    "}
                            value={this.state.phone}
                            options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}}
                            containerStyles={this.state.inputsContainersStyles}
                            inputStyles={this.state.inputsStyles}
                        />
                    }
                    <PasswordInput
                        title={""}
                        valid={this.state.passwordValid}
                        warning={""}
                        id={"password"}
                        onChange={this.handleChangePassword}
                        placeholder={"Password"}
                        value={this.state.password}
                        containerStyles={this.state.inputsContainersStyles}
                        inputStyles={this.state.inputsStyles}
                    />
                    <PasswordDisclosure>
                        {(this.state.passwordSecurityNum > 0 && this.state.passwordSecurityNum <= 1) && <>Weak password</>}
                        {(this.state.passwordSecurityNum > 1 && this.state.passwordSecurityNum <= 2) && <>So-so password</>}
                        {(this.state.passwordSecurityNum > 2 && this.state.passwordSecurityNum <= 3) && <>Good password</>}
                        {(this.state.passwordSecurityNum > 3) && <>Great password!</>}
                    </PasswordDisclosure>
                    <br />
                    {this.state.signUpError !== null && <Red>{this.state.signUpError} {this.props.signUpErrorLogin}</Red>}
                    <br />
                    <CheckboxWithLabel
                        action={this.handleChangeDisclosureCheckStatus}
                        id={"disclosureCheckbox"}
                        label={<DisclosureCheckbox>By checking this box, I confirm I have read and agree to the <a href={"https://"+env.URL.DOMAIN+"/privacy-policy/"} target={"_blank"}><b>Privacy Policy</b></a> and <a target={"_blank"} href={"https://"+env.URL.DOMAIN+"/terms-of-use/"}><b>Terms of Use</b></a>.</DisclosureCheckbox>}
                        checked={this.state.disclosureCheckStatus}
                        styleLabel={{paddingTop: "0px", paddingLeft: "20px", width: "calc(100% - 45px)"}}
                    />
                    <OnlySubmit>
                        <SubmitInputWithValidation
                            label={"Get Started"}
                            isChanged={this.state.isChanged}
                            isValid={this.state.isValid}
                            isSaving={this.state.isSaving}
                        />
                    </OnlySubmit>
                </form>
            </Container>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    tryRegisterAdvisorAndWMC: (firstName, lastName, email, phone, timeZone, password, isReferredByPartner, wmcEmployeeId, partnerPrepaidAdvisorSessions, partnerPrepaidTrainingSessions) => dispatch(tryRegisterAdvisorAndWMC(firstName, lastName, email, phone, timeZone, password, isReferredByPartner, wmcEmployeeId, partnerPrepaidAdvisorSessions, partnerPrepaidTrainingSessions)),
    trySignUpTestExists: (email, phone) => dispatch(trySignUpTestExists(email, phone)),
    // tryRegisterAdvisor: (firstName, lastName, email, phone, timeZone, password, wmcEmployeeId) => dispatch(tryRegisterAdvisor(firstName, lastName, email, phone, timeZone, password, wmcEmployeeId)),
    // tryRegisterWmc: (companyName, webDomain, firstName, lastName, email) => dispatch(tryRegisterWmc(companyName, webDomain, firstName, lastName, email)),
});

export default connect(null, mapDispatchToProps)(withRouter(AdvisorSignUp));