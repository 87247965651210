import { Map, List, fromJS } from 'immutable';

/* Immutable */

const BILLING_HISTORY = 'BILLING_HISTORY';
const BILLING_HISTORY_FETCHING = 'BILLING_HISTORY_FETCHING';
const BILLING_PAYMENT_METHOD = 'BILLING_PAYMENT_METHOD';
const BILLING_PAYMENT_METHOD_FETCHING = 'BILLING_PAYMENT_METHOD_FETCHING';
const BILLING_PAYMENT_METHOD_UPDATE = 'BILLING_PAYMENT_METHOD_UPDATE';
const BILLING_PAYMENT_METHOD_UPDATING = 'BILLING_PAYMENT_METHOD_UPDATING';
const BILLING_CURRENT_PLAN = 'BILLING_CURRENT_PLAN';
const BILLING_CURRENT_PLAN_FETCHING = 'BILLING_CURRENT_PLAN_FETCHING';
const BILLING_CURRENT_PLAN_CANCELING = 'BILLING_CURRENT_PLAN_CANCELING';
const BILLING_STORE_RESET = 'BILLING_STORE_RESET';

/* Actions */

export const setBillingHistory = (history) => ({'type': BILLING_HISTORY, 'history': history});
export const setBillingHistoryFetching = (status) => ({'type': BILLING_HISTORY_FETCHING, 'status': status});
export const setBillingPaymentMethod = (paymentMethod) => ({'type': BILLING_PAYMENT_METHOD, 'paymentMethod': paymentMethod});
export const setBillingPaymentMethodFetching = (status) => ({'type': BILLING_PAYMENT_METHOD_FETCHING, 'status': status});
export const setBillingPaymentMethodUpdate = (paymentMethod) => ({'type': BILLING_PAYMENT_METHOD_UPDATE, 'paymentMethod': paymentMethod});
export const setBillingPaymentMethodUpdating = (status) => ({'type': BILLING_PAYMENT_METHOD_UPDATING, 'status': status});
export const setBillingCurrentPlan = (currentPlan) => ({'type': BILLING_CURRENT_PLAN, 'currentPlan': currentPlan});
export const setBillingCurrentPlanFetching = (status) => ({'type': BILLING_CURRENT_PLAN_FETCHING, 'status': status});
export const setBillingCurrentPlanCanceling = (status) => ({'type': BILLING_CURRENT_PLAN_CANCELING, 'status': status});
export const setBillingStoreReset = () => ({'type': BILLING_STORE_RESET});

/* Initial State */

const initialState = Map({
    history: List(),
    historyFetching: false,
    paymentMethod: Map(),
    paymentMethodFetching: false,
    currentPlan: Map(),
    currentPlanFetching: false,
    currentPlanCanceling: false,
});

/* Reducer */

const billingReducer = (state=initialState, action) => {
    switch (action.type) {
        case BILLING_HISTORY:
            return state.merge({'history': fromJS(action.history)});
        case BILLING_HISTORY_FETCHING:
            return state.merge({'historyFetching': action.status});
        case BILLING_PAYMENT_METHOD:
            return state.merge({'paymentMethod': fromJS(action.paymentMethod)});
        case BILLING_PAYMENT_METHOD_FETCHING:
            return state.merge({'paymentMethodFetching': action.status});
        case BILLING_PAYMENT_METHOD_UPDATE:
            return state.merge({'paymentMethod': fromJS(action.paymentMethod)});
        case BILLING_PAYMENT_METHOD_UPDATING:
            return state.merge({'paymentMethodUpdating': action.status});
        case BILLING_CURRENT_PLAN:
            return state.merge({'currentPlan': fromJS(action.currentPlan)});
        case BILLING_CURRENT_PLAN_FETCHING:
            return state.merge({'currentPlanFetching': action.status});
        case BILLING_CURRENT_PLAN_CANCELING:
            return state.merge({'currentPlanCanceling': action.status});
        case BILLING_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default billingReducer;
