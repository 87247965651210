import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import ReactQuill from "react-quill";

/* Components */
import {
    FontBody16,
    FontBody18,
    FontHeader16,
    FontHeader18,
    FontHeader21,
    FontTitle18
} from '../../../../common/components/fonts';
import {ButtonSecondary} from "../../../../common/components/buttons";
import {LogoLoading} from "../../../../common/components/loading";
import {EditOutlined, PlusOutlined, DeleteOutlined} from "@ant-design/icons";
import {Gray, Image, Portfolio, Text, Portfolios, LoadingCenter, FitContent, Border, Btn, Inline, TextLeft} from "../../components/portfolios";
import {Quill} from "../../../../common/components/richtexteditor";
import {colors} from "../../../../common/components/colors";

/* Store */
import {setShopItemExperience} from "../../store/profileitems";

/* Middleware */
import {tryUpdateExpertProfile} from "../../middleware/expertprofile";
import {
    Description,
    GrayColor,
    ImagePlaceholder,
    ImageSub,
    LeftSub,
    RightSub, SectionSub,
    Spacing,
    SubTitle
} from "../../components/experience";

const pickMonth = (num) => {
    if(num === 1) {return("Jan")}
    else if(num === 2) {return("Feb")}
    else if(num === 3) {return("Mar")}
    else if(num === 4) {return("Apr")}
    else if(num === 5) {return("May")}
    else if(num === 6) {return("Jun")}
    else if(num === 7) {return("Jul")}
    else if(num === 8) {return("Aug")}
    else if(num === 9) {return("Sep")}
    else if(num === 10) {return("Oct")}
    else if(num === 11) {return("Nov")}
    else if(num === 12) {return("Dec")}
};

class ProfileExperience extends React.Component {
    state = {"jobs": []};

    componentDidMount() {
        return this.init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.profile !== this.props.profile) {
            return this.init()
        }
    }

    init = async () => {
        this.setState({
            "jobs": this.props.profile.get("jobs").toJS(),
            "isLoading": false
        });
    };

    add = async () => {
        await this.props.setShopItemExperience({});
        this.props.history.push("/settings/profile/about/experience/create");
    };

    edit = async (item) => {
        await this.props.setShopItemExperience(item);
        this.props.history.push("/settings/profile/about/experience/edit");
    };

    delete = async (item) => {
        let jobs = this.props.profile.get("jobs").toJS();
        const saveData = {
            // "bio": this.props.profile.get("bio"),
            // "whyBecameCoach": this.props.profile.get("whyBecameCoach"),
            // "freeTime": this.props.profile.get("freeTime"),
            // "endorsement1": this.props.profile.get("endorsement1"),
            // "endorsement2": this.props.profile.get("endorsement2"),
            // "endorsement3": this.props.profile.get("endorsement3"),
            "jobs": await jobs.filter(p => {return(p.id !== item.id)}),
        };
        await this.props.tryUpdateExpertProfile(saveData, "deleteExperience");
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <FontTitle18>Work Experience</FontTitle18>
                    <br/>
                    <br/>
                    <>
                        {this.state.jobs.map(j => (
                            <SectionSub key={j.id}>
                                <div>
                                    <LeftSub>
                                        {(j.photo === null || j.photo === undefined)
                                            ? <ImagePlaceholder />
                                            : <ImageSub src={j.photo} alt={""} />
                                        }
                                    </LeftSub>
                                    <RightSub>
                                        <SubTitle><FontHeader18>{j.title}</FontHeader18></SubTitle>
                                        {j.company !== null && 
                                            <FontBody18>{j.company}</FontBody18>
                                        }
                                        <Spacing />
                                        {(j.start !== null || j.end !== null) &&
                                            <FontBody16><GrayColor>
                                                {j.startMonth !== null && <span>{pickMonth(j.startMonth)}</span>}
                                                {(j.startMonth !== null && j.startYear !== null) && <span>,&nbsp;</span>}
                                                {j.startYear !== null && <span>{j.startYear}</span>}
                                                {((j.startMonth !== null || j.startYear !== null) && (j.endMonth !== null || j.endYear !== null || j.endOngoing)) &&
                                                    <span>&nbsp;-&nbsp;</span>
                                                }
                                                {j.endOngoing
                                                    ?
                                                        <span>Ongoing</span>
                                                    :
                                                        <>
                                                            {j.endMonth !== null && <span>{pickMonth(j.endMonth)}</span>}
                                                            {(j.endMonth !== null && j.endYear !== null) && <span>,&nbsp;</span>}
                                                            {j.endYear !== null && <span>{j.endYear}</span>}
                                                        </>
                                                }
                                            </GrayColor></FontBody16>
                                        }
                                        {((j.city !== null && j.city !== "") || (j.state !== null && j.state !== "")) &&
                                            <FontBody16>
                                                {(j.city !== null && j.city !== "") &&
                                                    <GrayColor>{j.city}</GrayColor>
                                                }
                                                {((j.city !== null && j.city !== "") && (j.state !== null && j.state !== "")) &&
                                                    <GrayColor>,&nbsp;</GrayColor>
                                                }
                                                {(j.state !== null && j.state !== "") &&
                                                    <GrayColor>{j.state}</GrayColor>
                                                }
                                            </FontBody16>
                                        }
                                        {(j.body !== null && j.body !== "" && j.body !== "<p><br></p>") &&
                                            <Description>
                                                <Quill bodyBorderColor={colors.white}>
                                                    <ReactQuill
                                                        readOnly={true}
                                                        theme={null}
                                                        value={j.body}
                                                        bounds={'.app'}
                                                    />
                                                </Quill>
                                            </Description>
                                        }
                                    </RightSub>
                                </div>
                                <Border />
                                <TextLeft>
                                    <Inline>
                                        <FitContent>
                                            <Btn>
                                                <FontBody16 onClick={() => {return this.edit(j)}}><EditOutlined/>&nbsp;Edit</FontBody16>
                                            </Btn>
                                        </FitContent>
                                    </Inline>
                                    <Inline>
                                        <FitContent>
                                            <Btn>
                                                <FontBody16 onClick={() => {return this.delete(j)}}><DeleteOutlined />&nbsp;Delete</FontBody16>
                                            </Btn>
                                        </FitContent>
                                    </Inline>
                                </TextLeft>
                            </SectionSub>
                        ))}
                    </>
                    <br />
                    <FitContent>
                        <FontBody16 onClick={() => {return this.add()}}>
                            <ButtonSecondary canSubmit={false} label={<><PlusOutlined />&nbsp;Add Work Experience</>} />
                        </FontBody16>
                    </FitContent>
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    isWMC: state.common.wmc.get("isWMC"),
    profile: state.enterprise.profile.expertProfile.get("expertProfile"),
});

const mapDispatchToProps = dispatch => ({
    setShopItemExperience: (item) => dispatch(setShopItemExperience(item)),
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileExperience));
