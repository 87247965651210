import { Map, fromJS } from 'immutable';

/* Immutable */

const IFRAME_PROFILE = 'IFRAME_PROFILE';
const IFRAME_PROFILE_FETCHING = 'IFRAME_PROFILE_FETCHING';
const IFRAME_PROFILE_HANDLE = 'IFRAME_PROFILE_HANDLE';
const IFRAME_PROFILE_HANDLE_FETCHING = 'IFRAME_PROFILE_HANDLE_FETCHING';
const IFRAME_PROFILE_STORE_RESET = 'IFRAME_PROFILE_STORE_RESET';

/* Actions */

export const setProfile = (profile) => ({"type": IFRAME_PROFILE, 'profile': profile});
export const setProfileFetching = (status) => ({"type": IFRAME_PROFILE_FETCHING, 'status': status});
export const setProfileHandle = (handle) => ({"type": IFRAME_PROFILE_HANDLE, 'handle': handle});
export const setProfileHandleFetching = (status) => ({"type": IFRAME_PROFILE_HANDLE_FETCHING, 'status': status});
export const setProfileStoreReset = () => ({"type": IFRAME_PROFILE_STORE_RESET});

/* Initial State */

const initialState = Map({
    profile: Map(),
    profileFetching: false,
    profileHandle: Map({"id": null, "publicHandle": null, "error": null, "errorDetail": null}),
    profileHandleFetching: false,
});

/* Reducer */

const willowProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case IFRAME_PROFILE:
            return state.merge({'profile': fromJS(action.profile)});
        case IFRAME_PROFILE_FETCHING:
            return state.merge({'profileFetching': action.status});
        case IFRAME_PROFILE_HANDLE:
            return state.merge({'profileHandle': fromJS({...state.get("profileHandle").toJS(), ...action.handle})});
        case IFRAME_PROFILE_HANDLE_FETCHING:
            return state.merge({'profileHandleFetching': action.status});
        case IFRAME_PROFILE_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default willowProfileReducer;
