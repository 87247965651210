import React from 'react';
import styled from 'styled-components';
import moment from 'moment/moment';
import {withRouter} from 'react-router-dom';

/* Components */
import {FontHeader16, FontBody16, FontHeader26, FontSubtitle40, FontBody24, FontSubtitle24} from "../../../common/components/fonts";
import {colors} from '../../../common/components/colors';

import {images} from "../../../common/components/images";

import {LogoLoading} from "../../../common/components/loading";



const TopSegment = styled.div`
    background-image: url(${images.firmBackgroundTop});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    left: 0px;
    right: 0;
    top: 0px;
    bottom: 0;
    padding: 90px 60px 0 60px;
    width: (calc 100% - 120px);
    @media screen and (max-width: 748px) {
        left: -30px;
        right: -30px;
        top: -30px;
        bottom: 0;
        padding: 30px 30px 0 30px;
    }
`;
const Foreground = styled.div`    
    position: relative;
    z-index: 2;
`;
const Subtitle = styled(FontBody24)`
    margin: 30px 0 0 0;
    min-height: 108px;
`;
const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;
const Background = styled.div`    
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.white};
    z-index: 1;
`;

const BoxContainer = styled.div`
    display: flex; 
    gap: 50px;
    flex-wrap: wrap;
    align-items: center;
   `;


const Card = styled.div`
    height: 150px;
    width: 300px;
    background-color: #777A69;
    filter: drop-shadow(0px 2px 5px grey);
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: flex;
    flex-direction: row;
    `;   
    
    
const CardImage = styled.img`
    height: 150px;
    width: 100px;
    border-top-left-radius: 15px;
    background-side: cover;
    background-repeat: no-repeat;
`;

const CardText = styled.div`
    flex-grow: 1;
    padding: 10px;
`;

const NumberTitle = styled(FontHeader26)`
    color: white;
    font-weight: bold;
    text-align: center;   
`;

const ValueText = styled(FontBody16)`
    color: white;
    text-align: center;
   
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
`;
const ColName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: calc(50% - 110px);
`;
const RowCTA = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(50% + 110px);
`;
const ColCTA = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
  text-align: center;
  max-width: 200px;
  width: 100%;
  & > div {
    margin: 0 auto;
  }
`;


class IFrameValueCard extends React.Component {

    static defaultProps = {
        articles: {
            "image": images.logo,
            "id": null,
            "category": "",
            "Title": "",
            "Advisor": "",
            "status": ""
        },
        selectAccept: () => {},
        index: 0,
    }

    state = {
        "isLoading": true,
    }

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.setState({
            "isLoading": false
        })
    
    }

lookingForMore = () => {
    this.props.setSupportOpen(true, 6);
    // this.props.history.push("/support")
}
 

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return (
                <>
                            
                             <Card onClick={() => {this.props.history.push("/my-growth/articles/edit")}}>
                             
                                <Col>
                                <CardText>
                             <NumberTitle>1</NumberTitle>
                             <ValueText>lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</ValueText>
                             </CardText>
                             </Col>
                             </Card>                                 
                </>
               
            )
        }
    }
}

export default withRouter(IFrameValueCard);