export default {
    "13": "https://docs.trustwillow.com/apiresponses/learncontent13.json",
    "25": "https://docs.trustwillow.com/apiresponses/learncontent25.json",
    "11": "https://docs.trustwillow.com/apiresponses/learncontent11.json",
    "31": "https://docs.trustwillow.com/apiresponses/learncontent31.json",
    "35": "https://docs.trustwillow.com/apiresponses/learncontent35.json",
    "45": "https://docs.trustwillow.com/apiresponses/learncontent45.json",
    "33": "https://docs.trustwillow.com/apiresponses/learncontent33.json",
    "27": "https://docs.trustwillow.com/apiresponses/learncontent27.json",
    "24": "https://docs.trustwillow.com/apiresponses/learncontent24.json",
    "34": "https://docs.trustwillow.com/apiresponses/learncontent34.json",
    "9": "https://docs.trustwillow.com/apiresponses/learncontent9.json",
    "3": "https://docs.trustwillow.com/apiresponses/learncontent3.json",
    "28": "https://docs.trustwillow.com/apiresponses/learncontent28.json",
    "29": "https://docs.trustwillow.com/apiresponses/learncontent29.json",
    "23": "https://docs.trustwillow.com/apiresponses/learncontent23.json",
    "26": "https://docs.trustwillow.com/apiresponses/learncontent26.json",
    "6": "https://docs.trustwillow.com/apiresponses/learncontent6.json",
    "30": "https://docs.trustwillow.com/apiresponses/learncontent30.json",
    "43": "https://docs.trustwillow.com/apiresponses/learncontent43.json",
    "18": "https://docs.trustwillow.com/apiresponses/learncontent18.json",
    "1": "https://docs.trustwillow.com/apiresponses/learncontent1.json",
    "8": "https://docs.trustwillow.com/apiresponses/learncontent8.json",
    "2": "https://docs.trustwillow.com/apiresponses/learncontent2.json",
    "14": "https://docs.trustwillow.com/apiresponses/learncontent14.json",
    "15": "https://docs.trustwillow.com/apiresponses/learncontent15.json",
    "37": "https://docs.trustwillow.com/apiresponses/learncontent37.json",
    "38": "https://docs.trustwillow.com/apiresponses/learncontent38.json",
    "17": "https://docs.trustwillow.com/apiresponses/learncontent17.json",
    "16": "https://docs.trustwillow.com/apiresponses/learncontent16.json",
    "39": "https://docs.trustwillow.com/apiresponses/learncontent39.json",
    "40": "https://docs.trustwillow.com/apiresponses/learncontent40.json",
    "10": "https://docs.trustwillow.com/apiresponses/learncontent10.json",
    "21": "https://docs.trustwillow.com/apiresponses/learncontent21.json",
    "22": "https://docs.trustwillow.com/apiresponses/learncontent22.json",
    "20": "https://docs.trustwillow.com/apiresponses/learncontent20.json",
    "4": "https://docs.trustwillow.com/apiresponses/learncontent4.json",
    "7": "https://docs.trustwillow.com/apiresponses/learncontent7.json",
    "12": "https://docs.trustwillow.com/apiresponses/learncontent12.json",
    "44": "https://docs.trustwillow.com/apiresponses/learncontent44.json"
}