import React from 'react';

/* Components */
import {PageActions, PageContent, PageTabs} from "../components/teamone";

/* Containers */
import TeamMember from './teammember';
import SupportMember from './supportmember';

const TeamOne = () => (
    <PageTabs>
        <PageContent>
            <TeamMember /> 
        </PageContent>
    </PageTabs>
);

export default TeamOne;
