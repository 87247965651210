import React from 'react'
import {connect} from 'react-redux';
import moment from 'moment/moment';
import {Link} from 'react-router-dom';

/* Full Calendar */
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from "@fullcalendar/daygrid";
import '@fullcalendar/core/main.css';
import '@fullcalendar/timegrid/main.css';

/* Containers */
import ChangeAvailability from './changeavailability';

/* Store */
import {setManualAvailabilityModal } from '../store/manual';

/* Middleware */
import {
    tryDeleteManualAvailability,
    tryGetScheduler,
    tryPostManualAvailability,
    tryPutManualAvailability
} from '../middleware/manual';
import {tryGetRecurringAvailability} from '../../settings/recurring/middleware/recurring';

/* Components */
import {Message} from "../../../common/components/messages";
import {ButtonPrimary} from "../../../common/components/buttons";
import {ForcedStyles, ActionBoxRight, TertiaryButton, FitContent, Action, Alert, AlertRed} from "../components/manualcalendar";
import {FontHeader16} from '../../../common/components/fonts';
import {LogoLoading} from "../../../common/components/loading";

class AvailabilityManualCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {fullCalendar: "", showTooltip: false};
        this.updateCalendar = this.updateCalendar.bind(this);
    }
    intervalID = 0;
    componentDidMount() {
        return this.init();
    }
    init = async () => {
        await this.props.tryGetRecurringAvailability();
        let data = await this.props.tryGetScheduler("availability");
        this.updateCalendar(data.toJS(), 'componentDidMount');
        this.intervalID = setInterval(async () => {
            let dataUpdate = await this.props.tryGetScheduler("availability");
            this.updateCalendar(dataUpdate.toJS(), 'componentDidMount');
        }, 1000 * 60 * 5);
    };
    handleShowTooltip = status => {
        this.setState({"showTooltip": status})
    };
    handleCreateAvailability = async info => {
        await this.setState({fullCalendar: ""});
        await this.props.tryPostManualAvailability(info.start, info.end);
        await this.updateCalendar(this.props.availability, 'handleSelect', info.start);
        await this.props.showRightBar(true);
    };
    handleAdd = async () => {
        await this.props.setManualAvailabilityModal(true, {"start": new Date(moment()), "end": new Date(moment().add(1, 'hours'))}, "create");
        await this.props.showRightBar(false);
    };
    // handleAddBusy = async () => {
    //     this.props.setManualAvailabilityModal(true, {"start": new Date(moment()), "end": new Date(moment().add(1, 'hours'))}, "setBusy");
    //     await this.props.showRightBar(false);
    // };
    handleSelect = async info => {
        console.log("handleSelect");
        console.log(info);
        if(!moment(info.end).isBefore(moment())) {
            await this.props.setManualAvailabilityModal(true, info, "create");
            await this.props.showRightBar(false);
        }
    };
    handleClick = async info => {
        console.log("handleClick");
        if(!moment(info.event.end).isBefore(moment())) {
            await this.props.setManualAvailabilityModal(true, info.event, "update");
            await this.props.showRightBar(false);
        }
    };
    handleCancel = async () => {
        await this.props.showRightBar(true);
    };
    handleResizeAndDrop = async info => {
        console.log("handleResizeAndDrop");
        if(!moment(info.event.end).isBefore(moment())) {
            await this.props.setManualAvailabilityModal(true, info.event, "update");
            await this.props.showRightBar(false);
        } else {
            this.setState({fullCalendar: ""});
            await this.props.availability.filter(a => {
                if(a.id === info.event.id) {
                    this.props.tryPutManualAvailability(a.id, a.start, a.end);
                }
            });
            this.updateCalendar(this.props.availability, 'handleSelect', info.start);
        }
    };
    handleUpdateAvailability = async info => {
        await this.setState({fullCalendar: ""});
        await this.props.tryPutManualAvailability(info.id, info.start, info.end);
        await this.updateCalendar(this.props.availability, 'handleSelect', info.start);
        await this.props.showRightBar(true);
    };
    handleDeleteAvailability = async info => {
        await this.setState({fullCalendar: ""});
        await this.props.tryDeleteManualAvailability(info.id);
        await this.updateCalendar(this.props.availability, 'handleSelect', info.start);
        await this.props.showRightBar(true);
    };
    updateCalendar(props, entry, dateInView) {
        this.setState({fullCalendar: <FullCalendar
                header={{left: 'prev,today,next', center: 'title', right: 'timeGridDay,timeGridWeek,dayGridMonth'}}
                titleRangeSeparator={"\u2013"}
                defaultView={'timeGridWeek'}
                height={"parent"}
                view={["DAY", "WEEK", "MONTH"]}
                timezone={"local"}
                slotDuration={'00:30:00'}
                minTime={"00:00:00"}
                maxTime={"24:00:00"}
                scrollTime={'07:00:00'}
                allDaySlot={false}
                longPressDelay={500}
                defaultDate={dateInView}
                displayEventTime={false}
                columnHeaderHtml={(date) => {
                    let eachDay = moment(date).format("YYYY-MM-DD[T]00:00:00Z");
                    let today = moment().format("YYYY-MM-DD[T]00:00:00Z");
                    let day = moment(date).format("ddd");
                    let dayNum = moment(date).format("D");
                    if(eachDay === today) {return "<div><div>"+day+"</div><div>"+dayNum+"</div></div>"}
                    else {return "<div><div>"+day+"</div><div class='currentDay'>"+dayNum+"</div></div>"}
                }}
                eventRender={function (event) {
                    if(!event.event._def.extendedProps.inThePast) {
                        event.el.classList.add("pointer");
                    }
                }}
                events={props}
                plugins={[timeGridPlugin, interactionPlugin, dayGridPlugin]}
                selectable={!this.props.preview}
                select={this.props.calendarType === "willow" ? this.handleSelect : () => {}}
                eventClick={this.props.calendarType === "willow" ? this.handleClick : () => {}}
                eventDrop={this.handleResizeAndDrop}
                eventResize={this.handleResizeAndDrop}
            />
        })
    };
    componentWillUnmount() {
        this.props.setManualAvailabilityModal(false, null, null);
        clearInterval(this.intervalID);
    };
    render() {
        if(this.state.fullCalendar === "") {
            return (<LogoLoading />)
        } else {
            // if(this.props.preview) {
            //     return(
            //         <>
            //             <ActionBoxRight>
            //                 <FitContent onClick={this.props.changePage}><ButtonPrimary label={"Close preview"} canSubmit={false} /></FitContent>
            //             </ActionBoxRight>
            //             <ForcedStyles>{this.state.fullCalendar}</ForcedStyles>
            //         </>
            //     )
            // } else {
            if(this.props.availabilityModal) {
                return(<ChangeAvailability action={this.props.availabilityModalAction} extraCancelAction={this.handleCancel} createAvailability={this.handleCreateAvailability} updateAvailability={this.handleUpdateAvailability} deleteAvailability={this.handleDeleteAvailability} />)
            } else {
                return(
                    <>
                        {this.props.calendarType === "willow"
                            ? <AlertRed><FontHeader16>Calendar Sync Disabled. Click on the calendar to add availability each day.</FontHeader16></AlertRed>
                            : <Alert>
                                {this.props.calendarType === "willow_recurring"
                                    ? <FontHeader16>Weekly Availability Set!</FontHeader16>
                                    : <FontHeader16>Calendar Sync Enabled!</FontHeader16>
                                }
                            </Alert>
                        }
                        {this.props.calendarType === "willow" &&
                            // ?
                            <ActionBoxRight>
                                <TertiaryButton><Link to={"/settings/availability/calendar"}><Action><FontHeader16>Enable Auto-Sync</FontHeader16></Action></Link></TertiaryButton>
                                <TertiaryButton onClick={this.handleAdd}><FontHeader16>Add Availability</FontHeader16></TertiaryButton>
                            </ActionBoxRight>
                            // :
                            // <ActionBoxRight>
                            //     <TertiaryButton><Link to={"/settings/availability/calendar"}><Action><FontHeader16>Auto-Sync Settings</FontHeader16></Action></Link></TertiaryButton>
                            //     {/*<TertiaryButton onClick={this.handleAddBusy}><FontHeader16>Set Busy Time</FontHeader16></TertiaryButton>*/}
                            // </ActionBoxRight>
                        }
                        <ForcedStyles>
                            {this.state.fullCalendar}
                        </ForcedStyles>
                    </>
                )
            }
            // }
        }
    }
}
const mapStateToProps = state => ({
    availabilityModal: state.enterprise.availability.get("manualAvailabilityModal"),
    availabilityModalAction: state.enterprise.availability.get("manualAvailabilityModalAction"),
    availability: state.enterprise.availability.get("manualAvailability").toJS(),
    calendarType: state.enterprise.recurring.get("recurringAvailability").get("availability_calendar_update_method")
});
const mapDispatchToProps = dispatch => ({
    tryGetScheduler: (type) => dispatch(tryGetScheduler(type)),
    tryPostManualAvailability: (start, end) => dispatch(tryPostManualAvailability(start, end)),
    tryPutManualAvailability: (id, start, end) => dispatch(tryPutManualAvailability(id, start, end)),
    tryDeleteManualAvailability: (id) => dispatch(tryDeleteManualAvailability(id)),
    setManualAvailabilityModal: (status, availability, action) => dispatch(setManualAvailabilityModal(status, availability, action)),
    tryGetRecurringAvailability: () => dispatch(tryGetRecurringAvailability())
});
export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityManualCalendar);




