import {v4 as uuid4} from 'uuid';
import moment from "moment/moment";

export const devManualAvailabilityData = {
    "availabilityTimeslots": [
        {
            id: uuid4(),
            start: "2020-09-19T13:00:00+00:00",
            end: "2020-09-19T15:00:00+00:00"
        },
        {
            id: uuid4(),
            start: "2020-09-20T16:00:00+00:00",
            end: "2020-09-20T22:00:00+00:00"
        }
    ]
};

export const devManualAvailabilityAddData = (start, end) => ({
    "availabilityTimeslots": [
        {
            id: uuid4(),
            start: "2020-05-15T12:00:00+00:00",
            end: "2020-05-15T18:00:00+00:00"
        },
        {
            id: uuid4(),
            start: "2020-05-16T16:00:00+00:00",
            end: "2020-05-16T22:00:00+00:00"
        },
        {
            id: uuid4(),
            start: moment(start).format().toString(),
            end: moment(end).format().toString()
        }
    ]
});

export const devManualAvailabilityUpdateData = (id, start, end, getState) => {
    const updatedAvailability = getState.enterprise.availability.get("manualAvailability").map(a => {
        if(a.get("id") === id) {
            return ({id: id, start: start, end: end})
        } else {
            return ({ id: a.get("id"), start: a.get("start"), end: a.get("end")})
        }
    });
    return ({"availabilityTimeslots": updatedAvailability.toJS()});
};

export const devManualAvailabilityDeleteData = (id, getState) => {
    const deletedAvailability = getState.enterprise.availability.get("manualAvailability").filter(a => {
        return(a.get("id") !== id)
    });
    return ({"availabilityTimeslots": deletedAvailability.toJS()});
};