import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {useLocation, useHistory} from 'react-router-dom';
import Cookies from "js-cookie";

/* Components */
import {LogoLoading} from "../../common/components/loading";
import CheckoutFlow from "../checkout/containers/flow";
import SignupFlow from "../signup/containers/flow";
import {images} from "../../common/components/images";
import {FontBody18} from "../../common/components/fonts";
import Success from "../success/containers/success";

/* Middleware */
import {tryPostAdvisorSpoofCertificationPurchase} from "./middleware/spoofpay";

/* Domain */
import getDomainEnv from '../../domains/utils';
const env = getDomainEnv();

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
    @media screen and (max-width: 640px) {
        margin: 0 auto;
    }
`;
const Container = styled.div`
    max-width: 500px;
    width: calc(100% - 20px);
    padding: 0 10px;
    margin: 60px auto 0 auto;
    @media screen and (max-width: 460px) {
        margin: 40px auto 0 auto;
    }
`;
const Logo = styled.img`
    width: auto;
    height: 32px;
    margin: 0 auto;
    display: block;
`;
const Message = styled(FontBody18)`
    margin: 20px auto;
    text-align: center;
`;

export default function IFrameSignUpAdvisorPay() {
    const [advisorData, setAdvisorData] = useState({
        "isReferredByPartner": false,
        "wmcEmployeeId": null,
        "discountCode": "certification_session_cert1200",
        "partnerPrepaidAdvisorSessions": 0,
        "partnerPrepaidTrainingSessions": 0,
        "redirectToVerify": false,
        "isLoading": true
    })
    const [paid, setPaid] = useState(false)
    const [login, setLogin] = useState(false)
    const [alreadyUser, setAlreadyUser] = useState(false)
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        checkIfUserLoggedIn();
        const query = new URLSearchParams(location.search);
        const certType = (query.get("certification") === undefined || query.get("certification") === null) ? "CAW" : query.get("certification").toUpperCase();
        const redirectToVerify = query.get("redirectToVerify");
        const partnerCode = query.get("partnerCode");
        const promotion = env.ENTERPRISE.COMPANY.SIGN_UP.IS_REFERRED_BY_PARTNER[partnerCode]
        const discountCode = promotion === undefined ? "certification_session_cert1200" : (promotion.discount === undefined ? "certification_session_cert1200" : promotion.discount);
        const wmcEmployeeId = promotion === undefined ? "" : (promotion.wmcEmployeeId === undefined ? "" : "partner:"+promotion.wmcEmployeeId);
        const utm_source = query.get("utm_source") === undefined ? "" : "utm_source:"+query.get("utm_source");
        const utm_medium = query.get("utm_medium") === undefined ? "" : "utm_medium:"+query.get("utm_medium");
        const utm_campaign = query.get("utm_campaign") === undefined ? "" : "utm_campaign:"+query.get("utm_campaign");
        const utm_content = query.get("utm_content") === undefined ? "" : "utm_content:"+query.get("utm_content");
        const utm_term = query.get("utm_term") === undefined ? "" : "utm_term:"+query.get("utm_term");
        const partner = query.get("partner") === undefined ? "" : "partner:"+query.get("partner");
        const wmcEmployeeIdPlusUTM = wmcEmployeeId+" "+partner+" "+utm_source+" "+utm_medium+" "+utm_campaign+" "+utm_content+" "+utm_term;
        setAdvisorData({
            "isReferredByPartner": promotion === undefined ? false : promotion.isReferredByPartner,
            "wmcEmployeeId": wmcEmployeeIdPlusUTM,
            "discountCode": certType+"_"+discountCode,
            "partnerPrepaidAdvisorSessions": promotion === undefined ? 0 : (promotion.assignAdvisorSessionsAfterSignup === undefined ? 0 : promotion.assignAdvisorSessionsAfterSignup),
            "partnerPrepaidTrainingSessions": promotion === undefined ? 0 : (promotion.assignTrainingSessionsAfterSignup === undefined ? 0 : promotion.assignTrainingSessionsAfterSignup),
            "redirectToVerify": redirectToVerify !== null ? (redirectToVerify === "true") : false,
            "isLoading": false
        })
    }, [])

    const onPay = async () => {
        if(advisorData.wmcEmployeeId.includes("blackrock") || advisorData.wmcEmployeeId.includes("freecerttrial")) {
            const attach = {
                'certificationProgramId': advisorData.discountCode.split("_")[0] === "CAN" ? 4 : 3,
                'willowSessionAmount': 0, // 1 is default
                'trainingSessionAmount': 0 // 3 is default
            }; // already assigned by purchase
            await dispatch(tryPostAdvisorSpoofCertificationPurchase(attach))
        }
        return history.push("/verify")
        // if(advisorData.redirectToVerify) {
        //     history.push("/advisor-verify")
        // } else {
        //     setPaid(true)
        // }
    }

    const onGoToLogin = () => {
        setLogin(true)
    }

    const checkIfUserLoggedIn = async () => {
        const sessionId = Cookies.get("sessionid");
        if(sessionId !== null && sessionId !== undefined) {
            setAlreadyUser(true)
            return onGoToLogin()
        }
    }

    if(advisorData.isLoading) {
        return(
            <LoadingCenter><LogoLoading /></LoadingCenter>
        )
    }

    // if(paid) {
    //     return(
    //         <Success
    //             title={"CHECK YOUR EMAIL TO ACTIVATE YOUR ACCOUNT"}
    //             subtitle={"We sent a verification link to your email that will remain valid for 24 hours."}
    //             body={<i>If you do not receive the email within a few minutes, please check your spam folder.</i>}
    //             buttonShow={false}
    //         />
    //     )
    // }

    if(login) {
        return(
            <Container>
                <Logo src={images.logo} alt={""} />
                <Message>You already have an account.<br />Log in to {alreadyUser ? "continue" : "complete your purchase"}.</Message>
                <SignupFlow
                    loginSystem={"page"}
                    loginForgotPasswordLink={() => {history.push("/password-reset")}}
                />
            </Container>
        )
    }

    return(
        <CheckoutFlow
            planId={advisorData.discountCode}
            showBackBtn={false}
            advisorData={advisorData}
            onPaymentSuccess={onPay}
            showUserLogin={false}
            redirectExistingUserToLogin={true}
            onRedirectExistingUserToLogin={onGoToLogin}
            allowPublicDomainSignups={false}
        />
    )

}