import React from 'react';

/* Components */
import {CardImage, CardOne, Name, Number, Left, Right, RightTop, Inline, LetterSpacing, FitContent} from "../components/cards";
import {images} from '../../../common/components/images';
import {colors} from '../../../common/components/colors';
import {FontHeader18, FontBody21, FontBody18, FontBody14} from '../../../common/components/fonts';
import {Settings} from 'react-feather';

const cardBrand = (brand) => {
    if(brand === "visa") {
        return(images.creditCardVisa)
    } else if(brand === "amex") {
        return(images.creditCardAmex)
    } else if(brand === "mastercard") {
        return(images.creditCardMastercard)
    } else {
        return(images.creditCardGeneral)
    }
    // amex, diners, discover, jcb, mastercard, unionpay, visa, or unknown
};

class CheckoutCardsOne extends React.Component {
    static defaultProps = {
        backgroundColor: colors.white
    };

    state={"isLoading": true, "data": {}};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await console.log(this.props.data)
        await this.setState({
            "data": this.props.data,
            "cardImage": cardBrand(this.props.data.card.brand),
            "canEdit": this.props.canEdit,
            "isLoading": false
        });
        console.log(this.props.data)
    };

    render() {
        if(this.state.isLoading) {
            return null
        } else {
            return(
                <CardOne backgroundColor={this.props.backgroundColor} canSelect={this.props.canSelect} onClick={() => {return this.props.onCardSelect(this.state.data)}}>
                    <div>
                        <Left>
                            <CardImage src={this.state.cardImage} alt={""} />
                        </Left>
                        <RightTop>
                            {this.state.canEdit &&
                                <FitContent onClick={(e) => {e.stopPropagation();return this.props.onViewCardEdit(this.state.data)}}><Settings /></FitContent>
                            }
                        </RightTop>
                    </div>
                    <Number>
                        <Inline><LetterSpacing><FontBody21>****&nbsp;****&nbsp;****&nbsp;</FontBody21></LetterSpacing></Inline>
                        <Inline><FontBody18>{this.state.data.card.last4}</FontBody18></Inline>
                    </Number>
                    <div>
                        <Left>
                            <FontHeader18><Name>{this.state.data.billing_details.name}</Name></FontHeader18>
                        </Left>
                        <Right>
                            <FontBody14>Exp: {this.state.data.card.exp_month}/{this.state.data.card.exp_year}</FontBody14>
                        </Right>
                    </div>
                </CardOne>
            )
        }
    }
}

export default CheckoutCardsOne;
