import { Map } from 'immutable';
import moment from "moment";

/* Immutable */

const COMMENT_SELECT = 'COMMENT_SELECT';
const COMMENT_MODAL = 'COMMENT_MODAL';
const COMMENT_MODAL_MINIMIZE = 'COMMENT_MODAL_MINIMIZE';
const COMMENT_POSTING = 'COMMENT_POSTING';
const COMMENTS_GETTING = 'COMMENTS_GETTING';

/* Actions */
export const setCommentSelected = (note) => ({'type': COMMENT_SELECT, 'note': note});
export const setCommentModal = (status) => ({'type': COMMENT_MODAL, 'status': status});
export const setCommentModalMinimized = (status) => ({'type': COMMENT_MODAL_MINIMIZE, 'status': status});
export const setCommentPosting = (status) => ({'type': COMMENT_POSTING, 'status': status});
export const setCommentsGetting = (status) => ({'type': COMMENTS_GETTING, 'status': status});

/* Initial State */

const initialState = Map({
    commentSelected: Map({
        id: null,
        title: null,
        body: null,
        datetime: null,
        author: null,
        numberOfComments: null
    }),
    commentModal: false,
    commentModalMinimized: false,
    commentPosting: false,
    commentsGetting: false,
    lastInitiated: null
});

/* Reducer */

const commentsReducer = (state=initialState, action) => {
    switch (action.type) {
        case COMMENT_SELECT:
            return state.merge({'commentSelected': Map(action.note)});
        case COMMENT_MODAL:
            return state.merge({'commentModal': action.status, 'lastInitiated': moment()});
        case COMMENT_MODAL_MINIMIZE:
            return state.merge({'commentModalMinimized': action.status});
        case COMMENT_POSTING:
            return state.merge({'commentPosting': action.status});
        case COMMENTS_GETTING:
            return state.merge({'commentsGetting': action.status});
        default:
            return state;
    }
};

export default commentsReducer;
