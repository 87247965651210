import React from "react";
import { withRouter } from 'react-router-dom';
import styled from "styled-components";

/* Components */
import {LogoLoading} from "../../../../common/components/loading";
import Success from "../../../../iframes/success/containers/success";

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

class FirmTaskCourseQuizCompleted extends React.Component {
    state = {"isLoading": true};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.setState({"isLoading": false});
    };

    render() {
        if(this.state.isLoading) {
            return (<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <Success
                    title={"CE CREDIT EARNED!"}
                    subtitle={"You have already completed the quiz and earned 1 CE credit"}
                    buttonLabel={"All Trainings"}
                    back={() => {this.props.history.push("/my-certificates/my-trainings")}}
                />
            )
        }
    }
}

export default withRouter(FirmTaskCourseQuizCompleted);
