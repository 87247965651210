import React from 'react';
import styled from "styled-components";
import moment from "moment/moment";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {v4 as uuid4} from "uuid";

/* Components */
import {colors} from "../../../common/components/colors";
import { FontTitle24, FontBody18 } from '../../../common/components/fonts';
import Calendar from "../../../common/components/calendar"
import {ButtonPrimary} from "../../../common/components/buttons";

/* Containers */
import {AppointmentsList} from "../../appointments/containers/appointmentslist";
import {ProgramsList} from "../../programs/containers/myprogramslist";
// import MeetingRescheduler from "../../scheduling/containers/rescheduler";
// import BookAppointments from "../../appointments/containers/bookappointments";
import SchedulingRescheduleCancel from "../../../iframes/reschedulermodal/containers/reschedulecancel";

/* Middleware */
import {tryGetTeamMemberMeetings} from "../../appointments/middleware/meetings";
import {tryGetAllTeamMembers} from "../../team/middleware/team";
import {tryGetMySubscribedGroupPrograms} from "../../programs/middleware/programs";
import {setTeamMemberMeetingsChanged} from "../../appointments/store/meetings";

/* Store  */
import {setSelectedProgramId} from "../../programs/store/programs";

/* Utils */
import {disableIdScrolling, enableIdScrolling} from "../../../common/utils/scrolling";

import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const DashboardContainer = styled.div`
    border-radius: 30px;
    background-color: ${colors.white};
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    padding: 30px;
    justify-content: center;
    gap: 20px;
    display: flex;
    flex-direction: column;
`;
const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
const NoEvents = styled(FontBody18)`
  text-align: center;
`;
const Events = styled.div`
  margin: 0 40px;
  display: flex;
  gap: 25px;
  flex-direction: column;
  @media screen and (max-width: 748px) {
      margin: 0 20px;
  }
  @media screen and (max-width: 450px) {
      margin: 0 10px;
  }
`;
const BookButton = styled.div`
    width: fit-content;
    cursor: pointer;
    margin: 0 auto 0 auto;
`;


class HomeAppointments extends React.Component {
    
    state = {
        "selectedDay": new Date(),
        "highlightedDates": [],
        "events": [],
        "members": [],
        "containerId": uuid4(),
        "selectedMeeting": {},
        "selectedDayEvents": [],
        "selectedDayEventsMeetings": [],
        "selectedDayEventsPrograms": [],
        "isLoading": true,
        "allCoaches": [],
        "sessionSelected": {},
        'showRescheduler': false,
    }

    componentDidMount() {
        this.init();
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if(prevProps.meetingsChanged !== this.props.meetingsChanged) {
    //         return this.init();
    //     }
    // }

    init = async () => {

        // Meetings
        const meetings = await this.props.tryGetTeamMemberMeetings();
        const meetingsAll = meetings.upcomingCalls.concat(meetings.pastCalls);
        const meetingsAllDates = await this.meetingDates(meetingsAll);
        const meetingsAllTags = await this.addTimeInfo(meetingsAll);

        // Programs
        const programs = await this.props.tryGetMySubscribedGroupPrograms();
        const programsDates = await this.programDates(programs);
        const programsAllTags = await this.programTags(programs);

        // Combine
        const eventsTags = meetingsAllTags.concat(programsAllTags.flat());
        const eventsDates = programsDates.flat().concat(meetingsAllDates);

        // Members (book appointments
        const membersAll = await this.props.tryGetAllTeamMembers();
        const members = membersAll.filter((m) => {return(m.wmcId === null)});
        
        await this.setState({
            "members": await this.setUpMembers(members),
            "events": eventsTags,
            "highlightedDates": eventsDates.map(e => {return(new Date(e))}),
            "allCoaches": membersAll,
            "isLoading": false
        })
        await this.onDateChange(this.state.selectedDay);
    }

    componentWillUnmount() {
        enableIdScrolling(this.state.containerId);
    }

    addTimeInfo = (meetings) => {
        return meetings.reduce((filtered, c) => {
            let isPast = moment(c.end).isBefore(moment());
            let isUpcoming = moment(c.start).isAfter(moment());
            let isOngoing = (moment(c.start).isBefore(moment()) && moment(c.end).isAfter(moment()));
            let isMissed = !c.isMissed === undefined ? false : c.isMissed;
            let end = (c.expected_end === undefined || c.expected_end === null) ? c.end : c.expected_end;
            let d = c.start;
            filtered.push({
                ...c,
                "type": ((isUpcoming || isOngoing) ? "upcoming" : (isMissed ? "missed" : "past")),
                "length": moment.duration(moment(end).diff(moment(c.start))).asMinutes().toString(),
                "date": moment(d).format("ll").toString() + ", " + moment(d).format("h:mma").toString() + " - " + moment(end).format("h:mma").toString(),
                "tag": "meeting"
            })
            return filtered;
        }, []);
    };

    meetingDates = async (meetings) => {
        return Promise.all(meetings.map(m => {return(m.start)}))
    }

    lectureDates = async (lectures) => {
        return Promise.all(lectures.map(l => {return(l.webinar.flashSaleStartDateTime)}))
    }

    lectureListExtract = async (lectures, program) => {
        return Promise.all(lectures.map(l => {return({
            ...l.webinar,
            "programTitle": program.groupProgram.title,
            "program": program,
            "tag": "program"
        })}))
    }

    programDates = async (programs) => {
        return Promise.all(programs.map(p => {return(this.lectureDates(p.groupProgram.lectures))}))
    }

    programTags = async (programs) => {
        return Promise.all(programs.map(async p => {
            return this.lectureListExtract(p.groupProgram.lectures, p);
        }))
    }

    onDateChange = async (selectedDay) => {
        const events = this.state.events.filter(e => {
            if(e.tag === "meeting") {
                return(moment(e.start).format("YYYY-MM-DD") === moment(selectedDay).format("YYYY-MM-DD"));
            } else {
                return(moment(e.flashSaleStartDateTime).format("YYYY-MM-DD") === moment(selectedDay).format("YYYY-MM-DD"));
            }
        })
        await this.setState({
            "selectedDay": selectedDay,
            "selectedDayEvents": events,
            "selectedDayEventsMeetings": events.filter(e => {return (e.tag === "meeting")}),
            "selectedDayEventsPrograms": events.filter(e => {return (e.tag === "program")})
        })
    }

    showRescheduler = async (meeting, event) => {
        this.setState({
            "selectedMeeting": meeting,
            "showRescheduler": true
        });
    };
    hideRescheduler = async (meeting, event) => {
        this.setState({
            "showRescheduler": false,
            "selectedMeeting": {},
        });
    };

    setUpMembers = (data) => {
        return data.map(coach => {
            let title = env.CONSUMER.CARE.PRINT_EXPERT_CATEGORIES(coach.expertCategories, true);
            return { "id": coach.coachId, "first": coach.first, "last": coach.last, "title": title, "photo": coach.photo };
        });
    }

    bookAppointment = async () => {
        this.props.history.push("/my-team/appointment")
        // await this.setState({'bookSelected': !this.state.bookSelected,})
        // if (this.state.bookSelected) {
        //     disableIdScrolling(this.state.containerId);
        // } else {
        //     enableIdScrolling(this.state.containerId);
        // }
    };

    // sendToGetCare = async () => {
    //     await enableIdScrolling(this.state.containerId);
    //     return this.props.history.push("/my-team/find")
    // }

    executeCancel = async () => {
        console.log('resched confirmOnCancelled')
        await this.init();
    }

    executeReschedule = async () => {
        console.log('resched confirmOnScheduled')
        await this.init();
        this.props.history.push("/my-team/"+this.state.selectedMeeting.remoteUserId+"/appointment")
    }

    render() {
        return(
            <DashboardContainer id={this.state.containerId}>
                <FontTitle24>My Appointments</FontTitle24>
                <FlexRow>
                    {!this.state.isLoading &&
                        <Calendar
                            selectedDay={this.state.selectedDay}
                            onDateChange={this.onDateChange}
                            highlightDates={this.state.highlightedDates}
                        />
                    }
                </FlexRow>
                {this.state.selectedDayEvents.length > 0 ?
                    <Events>
                        <AppointmentsList
                            meetings={this.state.selectedDayEventsMeetings}
                            members={this.state.allCoaches.map(a => {return({...a, "id": a.coachId})})}
                            reschedule={this.showRescheduler}
                            shapeStartTopLeftRound={false}
                            hideEmpty={true}
                        />
                        <ProgramsList
                            programs={this.state.selectedDayEventsPrograms}
                            shapeStartTopLeftRound={false}
                            dashboard={true}
                            hideEmpty={true}
                        />
                    </Events>
                    : <>
                        <NoEvents>{'No coaching or programs scheduled for ' + moment(this.state.selectedDay).format('MMMM Do')}</NoEvents>
                        <BookButton onClick={this.bookAppointment}>
                            <ButtonPrimary canSubmit={false} label={'Book an appointment'} />
                        </BookButton>
                    </>
                }
                <SchedulingRescheduleCancel
                    hideModal={this.hideRescheduler}
                    onExecuteCancel={this.executeCancel}
                    onExecuteReschedule={this.executeReschedule}
                    sessionSelected={this.state.selectedMeeting}
                    showModal={this.state.showRescheduler}
                />
                {/*{this.state.showRescheduler &&*/}
                {/*    <MeetingRescheduler*/}
                {/*        close={this.showRescheduler}*/}
                {/*        meeting={this.state.selectedMeeting}*/}
                {/*    />*/}
                {/*}*/}
                {/*{this.state.bookSelected &&*/}
                {/*    <BookAppointments*/}
                {/*        members={this.state.members}*/}
                {/*        onGoBack={this.bookAppointment}*/}
                {/*        sendToGetCare={this.sendToGetCare}*/}
                {/*    />}*/}
            </DashboardContainer>
        )
    }
}

const mapStateToProps = state => ({
    meetings: state.consumer.appointments.meetings.get("meetings"),
    meetingsChanged: state.consumer.appointments.meetings.get("meetingsChanged"),
});

const mapDispatchToProps = dispatch => ({
    tryGetAllTeamMembers: () => dispatch(tryGetAllTeamMembers()),
    tryGetTeamMemberMeetings: () => dispatch(tryGetTeamMemberMeetings()),
    tryGetMySubscribedGroupPrograms: () => dispatch(tryGetMySubscribedGroupPrograms()),
    setSelectedProgramId: (id) => dispatch(setSelectedProgramId(id)),
    setTeamMemberMeetingsChanged: (datetime) => dispatch(setTeamMemberMeetingsChanged(datetime)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeAppointments));