import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';

/* Components */
import {FontTitle18} from '../../../common/components/fonts';
import {LogoLoading} from "../../../common/components/loading";
import {CardOrNot} from '../components/createcourse';
import {ButtonSecondary} from "../../../common/components/buttons";

const AddButton = styled.div`
    width: fit-content;
    cursor: pointer;
    margin-top: 50px;
`;

class CreateCoursePages extends React.Component {
    state = {
        isLoading: true,
        pages: []
    };

    componentDidMount() {
        return this.init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.pages.pages !== this.props.pages.pages) {
            return this.init();
        }
    }

    init = async () => {
        this.setState({
            "pages": this.props.pages.pages,
            "isLoading": false
        });
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <CardOrNot>
                    <FontTitle18>Pages</FontTitle18>
                    {this.state.pages.map((p, index) => (
                        <div key={index}>
                            Page title
                        </div>
                    ))}
                    <AddButton onClick={() => {}}>
                        <ButtonSecondary label={"+ Add page"} canSubmit={false} />
                    </AddButton>
                </CardOrNot>
            )
        }
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCoursePages);
