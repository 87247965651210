export const iconNames = [
    "OldestIcon",
    "OnlyChildIcon",
    "YoungestIcon",
    "AmphibianIcon",
    "BearIcon",
    "BirdIcon",
    "CatIcon",
    "ChickenIcon",
    "CowIcon",
    "MiddleIcon",
    "DogIcon",
    "DolphinIcon",
    "ElephantIcon",
    "FishIcon",
    "FoxIcon",
    "GiraffeIcon",
    "HorseIcon",
    "LionIcon",
    "MonkeyIcon",
    "OtterIcon",
    "PigIcon",
    "RabbitIcon",
    "ReptilesIcon",
    "SharkIcon",
    "SquirrelIcon",
    "TigerIcon",
    "TurtleIcon",
    "WhaleIcon",
    "ENFJIcon",
    "ENFPIcon",
    "ENTJIcon",
    "ENTPIcon",
    "ESFJIcon",
    "ESFPIcon",
    "ESTJIcon",
    "ESTPIcon",
    "INFJIcon",
    "INFPIcon",
    "INTJIcon",
    "INTPIcon",
    "ISFJIcon",
    "ISFPIcon",
    "ISTJIcon",
    "ISTPIcon",
    "AquariusIcon",
    "AriesIcon",
    "CancerIcon",
    "CapricornIcon",
    "GeminiIcon",
    "LeoIcon",
    "LibraIcon",
    "PiscesIcon",
    "SagittariusIcon",
    "ScorpioIcon",
    "TaurusIcon",
    "VirgoIcon",
    "ActivismIcon",
    "ArtIcon",
    "BakingIcon",
    "CoffeeTeaIcon",
    "CookingIcon",
    "CraftsIcon",
    "DancingIcon",
    "ExerciseIcon",
    "FaithSpiritualityIcon",
    "FriendsIcon",
    "GamingIcon",
    "GardeningIcon",
    "GymIcon",
    "KnittingSewingIcon",
    "MoviesTVIcon",
    "NutritionIcon",
    "OutdoorsIcon",
    "PerformanceArtsIcon",
    "PhotographyIcon",
    "PuzzlesIcon",
    "ReadingIcon",
    "SportsIcon",
    "TravelIcon",
    "WalkingHikingIcon",
    "WhoHasTimeForHobbiesIcon",
    "WineSpiritsIcon",
    "Aries",
    "Taurus",
    "Gemini",
    "Cancer",
    "Leo",
    "Virgo",
    "Libra",
    "Scorpio",
    "Sagittarius",
    "Capricorn",
    "Aquarius",
    "Pisces"
];