import { Map, List, fromJS } from 'immutable';

/* Immutable */

const WMCS_ASSIGNED_TO_COACH = 'WMCS_ASSIGNED_TO_COACH';
const WMCS_ASSIGNED_TO_COACH_FETCHING = 'WMCS_ASSIGNED_TO_COACH_FETCHING';
const WMCS_ASSIGNED_TO_COACH_FETCHED = 'WMCS_ASSIGNED_TO_COACH_FETCHED';
const PARTNERS_ASSIGNED_TO_COACH = 'PARTNERS_ASSIGNED_TO_COACH';
const PARTNERS_ASSIGNED_TO_COACH_FETCHING = 'PARTNERS_ASSIGNED_TO_COACH_FETCHING';
const PARTNERS_ASSIGNED_TO_COACH_FETCHED = 'PARTNERS_ASSIGNED_TO_COACH_FETCHED';

const SUPPORT_ASSIGNED_TO_WMC = 'SUPPORT_ASSIGNED_TO_WMC';
const SUPPORT_ASSIGNED_TO_WMC_FETCHING = 'SUPPORT_ASSIGNED_TO_WMC_FETCHING';
const SUPPORT_ASSIGNED_TO_WMC_FETCHED = 'SUPPORT_ASSIGNED_TO_WMC_FETCHED';

const CLIENTS_ASSIGNED_TO_ADVISOR = 'CLIENTS_ASSIGNED_TO_ADVISOR';
const CLIENTS_ASSIGNED_TO_ADVISOR_FETCHING = 'CLIENTS_ASSIGNED_TO_ADVISOR_FETCHING';
const CLIENTS_ASSIGNED_TO_ADVISOR_FETCHED = 'CLIENTS_ASSIGNED_TO_ADVISOR_FETCHED';

const COACHES_ASSIGNED_TO_WMC = 'COACHES_ASSIGNED_TO_WMC';
const COACHES_ASSIGNED_TO_WMC_FETCHING = 'COACHES_ASSIGNED_TO_WMC_FETCHING';
const COACHES_ASSIGNED_TO_WMC_FETCHED = 'COACHES_ASSIGNED_TO_WMC_FETCHED';
const CLIENTS_ASSIGNED_TO_COACH = 'CLIENTS_ASSIGNED_TO_COACH';
const CLIENTS_ASSIGNED_TO_COACH_FETCHING = 'CLIENTS_ASSIGNED_TO_COACH_FETCHING';
const CLIENTS_ASSIGNED_TO_COACH_FETCHED = 'CLIENTS_ASSIGNED_TO_COACH_FETCHED';

const COACHES_ASSIGN_TO_PROSPECT_FETCHING = 'COACHES_ASSIGN_TO_PROSPECT_FETCHING';
const COACHES_ASSIGNED_TO_PROSPECT = 'COACHES_ASSIGNED_TO_PROSPECT';
const COACHES_ASSIGNED_TO_PROSPECT_FETCHING = 'COACHES_ASSIGNED_TO_PROSPECT_FETCHING';
const COACHES_UPDATE_PROSPECTS_SESSIONS_UPDATING = 'COACHES_UPDATE_PROSPECTS_SESSIONS_UPDATING';
const COACHES_PROGRAM = 'COACHES_PROGRAM';
const COACHES_PROGRAM_FETCHING = 'COACHES_PROGRAM_FETCHING';

const PARTNERS_SELECT_ONE = 'PARTNERS_SELECT_ONE';
const WMCS_SELECT_ONE = 'WMCS_SELECT_ONE';
const WMCS_ADVISOR_SELECT_ONE = 'WMCS_ADVISOR_SELECT_ONE';
const SUPPORT_SELECT_ONE = 'SUPPORT_SELECT_ONE';
const COACH_SELECT_ONE = 'COACH_SELECT_ONE';

const COACH_CATEGORY_MODAL = 'COACH_CATEGORY_MODAL';
const COACH_CATEGORY = 'COACH_CATEGORY';

const COACHES_STORE_RESET = 'COACHES_STORE_RESET';

/* Actions */

export const setSelectPartner = (partner) => ({'type': PARTNERS_SELECT_ONE, 'partner': partner});
export const setSelectWmc = (wmc) => ({'type': WMCS_SELECT_ONE, 'wmc': wmc});
export const setSelectWmcAdvisor = (wmcAdvisor) => ({'type': WMCS_ADVISOR_SELECT_ONE, 'wmcAdvisor': wmcAdvisor});
export const setSelectSupport = (support) => ({'type': SUPPORT_SELECT_ONE, 'support': support});
export const setSelectCoach = (coach) => ({'type': COACH_SELECT_ONE, 'coach': coach});

export const setSupportAssignedToWmc = (support) => ({'type': SUPPORT_ASSIGNED_TO_WMC, 'support': support});
export const setSupportForWmcFetching = (status) => ({'type': SUPPORT_ASSIGNED_TO_WMC_FETCHING, 'status': status});
export const setSupportForWmcFetched = (status) => ({'type': SUPPORT_ASSIGNED_TO_WMC_FETCHED, 'status': status});

export const setWmcsAssignedToCoach = (wmcs) => ({'type': WMCS_ASSIGNED_TO_COACH, 'wmcs': wmcs});
export const setWmcsForCoachFetching = (status) => ({'type': WMCS_ASSIGNED_TO_COACH_FETCHING, 'status': status});
export const setWmcsForCoachFetched = (status) => ({'type': WMCS_ASSIGNED_TO_COACH_FETCHED, 'status': status});

export const setPartnersAssignedToCoach = (partners) => ({'type': PARTNERS_ASSIGNED_TO_COACH, 'partners': partners});
export const setPartnersForCoachFetching = (status) => ({'type': PARTNERS_ASSIGNED_TO_COACH_FETCHING, 'status': status});
export const setPartnersForCoachFetched = (status) => ({'type': PARTNERS_ASSIGNED_TO_COACH_FETCHED, 'status': status});

export const setClientsAssignedToCoach = (clients) => ({'type': CLIENTS_ASSIGNED_TO_COACH, 'clients': clients});
export const setClientsForCoachFetching = (status) => ({'type': CLIENTS_ASSIGNED_TO_COACH_FETCHING, 'status': status});
export const setClientsForCoachFetched = (status) => ({'type': CLIENTS_ASSIGNED_TO_COACH_FETCHED, 'status': status});

export const setClientsAssignedToAdvisor = (clients) => ({'type': CLIENTS_ASSIGNED_TO_ADVISOR, 'clients': clients});
export const setClientsForAdvisorFetching = (status) => ({'type': CLIENTS_ASSIGNED_TO_ADVISOR_FETCHING, 'status': status});
export const setClientsForAdvisorFetched = (status) => ({'type': CLIENTS_ASSIGNED_TO_ADVISOR_FETCHED, 'status': status});

export const setCoachesAssignedToWmc = (coaches) => ({'type': COACHES_ASSIGNED_TO_WMC, 'coaches': coaches});
export const setCoachesForWmcFetching = (status) => ({'type': COACHES_ASSIGNED_TO_WMC_FETCHING, 'status': status});
export const setCoachesForWmcFetched = (status) => ({'type': COACHES_ASSIGNED_TO_WMC_FETCHED, 'status': status});

export const setCategoryModal = (status) => ({'type': COACH_CATEGORY_MODAL, 'status': status});
export const setCoachCategoryChanging = (status) => ({'type': COACH_CATEGORY, 'status': status})

export const setCoachesAssignedToProspect = (coaches) => ({'type': COACHES_ASSIGNED_TO_PROSPECT, 'coaches': coaches});
export const setCoachesAssignedToProspectFetching = (status) => ({'type': COACHES_ASSIGNED_TO_PROSPECT_FETCHING, 'status': status});
export const setCoachAssignToProspectFetching = (status) => ({'type': COACHES_ASSIGN_TO_PROSPECT_FETCHING, 'status': status});
export const setUpdateProspectsSessionsFetching = (status) => ({'type': COACHES_UPDATE_PROSPECTS_SESSIONS_UPDATING, 'status': status});
export const setCoachProgram = (program) => ({'type': COACHES_PROGRAM, 'program': program});
export const setCoachProgramFetching = (status) => ({'type': COACHES_PROGRAM_FETCHING, 'status': status});
export const setCoachesStoreReset = () => ({'type': COACHES_STORE_RESET});

/* Initial State */

const initialState = Map({
    wmcsAssignedToCoach: List(),
    wmcsAssignedToCoachFetching: false,
    wmcsAssignedToCoachFetched: false,
    coachAssigningToProspectUpdating: false,
    coachesAssignedToProspect: List(),
    coachesAssignedToProspectFetching: false,
    coachesUpdateProspectsSessionsUpdating: false,
    coachProgram: Map(),
    partnerSelected: Map(),
    wmcSelected: Map(),
    wmcAdvisorSelected: Map(),
    supportSelected: Map(),
    coachSelected: Map(),
    coachProgramFetching: false,
    partnersAssignedToCoach: List(),
    partnersAssignedToCoachFetching: false,
    partnersAssignedToCoachFetched: false,
    supportAssignedToWmc: List(),
    supportAssignedToWmcFetching: false,
    supportAssignedToWmcFetched: false,
    coachesAssignedToWmc: List(),
    coachesAssignedToWmcFetching: false,
    coachesAssignedToWmcFetched: false,
    clientsAssignedToCoach: List(),
    clientsAssignedToCoachFetching: false,
    clientsAssignedToCoachFetched: false,
    clientsAssignedToAdvisor: List(),
    clientsAssignedToAdvisorFetching: false,
    clientsAssignedToAdvisorFetched: false,
    categoryModal: false,
    coachCategoryChanging: false
});

/* Reducer */

const partnersReducer = (state=initialState, action) => {
    switch (action.type) {
        case WMCS_ASSIGNED_TO_COACH:
            return state.merge({'wmcsAssignedToCoach': fromJS(action.wmcs)});
        case WMCS_ASSIGNED_TO_COACH_FETCHING:
            return state.merge({'wmcsAssignedToCoachFetching': action.status});
        case WMCS_ASSIGNED_TO_COACH_FETCHED:
            return state.merge({'wmcsAssignedToCoachFetched': action.status});
        case PARTNERS_ASSIGNED_TO_COACH:
            return state.merge({'partnersAssignedToCoach': fromJS(action.partners)});
        case PARTNERS_ASSIGNED_TO_COACH_FETCHING:
            return state.merge({'partnersAssignedToCoachFetching': action.status});
        case PARTNERS_ASSIGNED_TO_COACH_FETCHED:
            return state.merge({'partnersAssignedToCoachFetched': action.status});
        case SUPPORT_ASSIGNED_TO_WMC:
            return state.merge({'supportAssignedToWmc': fromJS(action.support)});
        case SUPPORT_ASSIGNED_TO_WMC_FETCHING:
            return state.merge({'supportAssignedToWmcFetching': action.status});
        case SUPPORT_ASSIGNED_TO_WMC_FETCHED:
            return state.merge({'supportAssignedToWmcFetched': action.status});
        case COACHES_ASSIGNED_TO_WMC:
            return state.merge({'coachesAssignedToWmc': fromJS(action.coaches)});
        case COACHES_ASSIGNED_TO_WMC_FETCHING:
            return state.merge({'coachesAssignedToWmcFetching': action.status});
        case COACHES_ASSIGNED_TO_WMC_FETCHED:
                return state.merge({'coachesAssignedToWmcFetched': action.status});
        case CLIENTS_ASSIGNED_TO_COACH:
                return state.merge({'clientsAssignedToCoach': fromJS(action.clients)});
        case CLIENTS_ASSIGNED_TO_COACH_FETCHING:
                return state.merge({'clientsAssignedToCoachFetching': action.status});
        case CLIENTS_ASSIGNED_TO_COACH_FETCHED:
                return state.merge({'clientsAssignedToCoachFetched': action.status});
        case CLIENTS_ASSIGNED_TO_ADVISOR:
                return state.merge({'clientsAssignedToAdvisor': fromJS(action.clients)});
        case CLIENTS_ASSIGNED_TO_ADVISOR_FETCHING:
                return state.merge({'clientsAssignedToAdvisorFetching': action.status});
        case CLIENTS_ASSIGNED_TO_ADVISOR_FETCHED:
                return state.merge({'clientsAssignedToAdvisorFetched': action.status});
        case COACHES_ASSIGN_TO_PROSPECT_FETCHING:
            return state.merge({'coachAssigningToProspectUpdating': action.status});
        case COACHES_ASSIGNED_TO_PROSPECT:
            return state.merge({'coachesAssignedToProspect': fromJS(action.coaches)});
        case COACHES_ASSIGNED_TO_PROSPECT_FETCHING:
            return state.merge({'coachesAssignedToProspectFetching': action.status});
        case COACHES_UPDATE_PROSPECTS_SESSIONS_UPDATING:
            return state.merge({'coachesUpdateProspectsSessionsUpdating': action.status});
        case COACHES_PROGRAM:
            return state.merge({'coachProgram': fromJS(action.program)});
        case COACHES_PROGRAM_FETCHING:
            return state.merge({'coachProgramFetching': action.status});
        case PARTNERS_SELECT_ONE:
            return state.merge({'partnerSelected': fromJS(action.partner)});
        case WMCS_SELECT_ONE:
            return state.merge({'wmcSelected': fromJS(action.wmc)});
        case WMCS_ADVISOR_SELECT_ONE:
            return state.merge({'wmcAdvisorSelected': fromJS(action.wmcAdvisor)});
        case SUPPORT_SELECT_ONE:
            return state.merge({'supportSelected': fromJS(action.support)}); 
        case COACH_SELECT_ONE:
            return state.merge({'coachSelected': fromJS(action.coach)}); 
        case COACH_CATEGORY_MODAL:
            return state.merge({'categoryModal': action.status});
        case COACH_CATEGORY:
            return state.merge({'coachCategoryChanging': action.status})
        case COACHES_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default partnersReducer;
