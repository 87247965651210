import {axiosReq} from "../../../common/utils/axios";

export const trySubmitForm = (formData, userId) => {
    return async (dispatch, getState) => {
        // dispatch(setMessagesSendPending());
        const platform = getState().common.user.get("platform");
        const url = platform === "consumer" ? 'apiv1/consumer/coach/'+userId+'/messages' : 'apiv1/coach/coach/'+userId+'/messages';
        const message = await axiosReq(url, "POST",  {content: JSON.stringify(formData), subject: "Form Submission", channel: "form"}, {}) //devMessagesAddData(true, content, subject, mediaType));
        // let messageResult;
        // if(message.hasOwnProperty("error")) {
        //     messageResult = message;
        // } else {
        //     messageResult = {"error": null}
        // }
        // dispatch(setMessagesSendComplete());
        // return(messageResult)
    }
};
