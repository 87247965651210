import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {tryGetCertifiedAdvisors} from "../../iframes/advisordirectory/middleware/directory";
import {FontBody16, FontSubtitle38To30, FontTitle32To27} from "../../common/components/fonts";
import {CardContainer} from "../../iframes/advisordirectory/page";
import {images} from "../../common/components/images";
import {ButtonPrimary, ButtonTertiary} from "../../common/components/buttons";
import {BioText, CertText, Gap, ImageBox, ImageImg, NameText, Rectangle} from "../components/advisorpreviewcard";
import SchedulerFlow from "../../iframes/scheduler/containers/flow";
import styled from "styled-components";
import {BtnContainer, BtnDiv} from "./instructionsflow";

const RectangleSized = styled(Rectangle)`
  height:80%;
  img {
    max-height:150px;
    max-width:150px;
  }
`;


export const InstructionCard = ({a,datetimeNextPage,saveAdvisorId, next}) => {
    const [calendarOpen, setOpen] = useState(false);
    const handleCalendar = () => {
        setOpen(!calendarOpen)
        //next()
    }
    return (<RectangleSized>
        <ImageBox>
            <ImageImg src={a.photos === null ? images.logoIcon : a.photos} />
        </ImageBox>
        <Gap>
            <NameText>{a.firstName+" "+a.lastName}</NameText>
            {/*<CertText>{a.name+" ("+a.designation+")"}</CertText>*/}
            <CertText>{a.name}</CertText>
        </Gap>
        <BioText>{a.intro.description}</BioText>
        <BioText>Expertise: <ul>{a.expertise.map(exp => <li key={exp}>{exp}</li>)} </ul></BioText>
        <BioText>Firm: {a.firmName}</BioText>
        <BioText>Description: {a.firmBio}</BioText>

        {!calendarOpen && <div onClick={handleCalendar} ><ButtonPrimary label={'Make an appointment'}/></div>}
        {calendarOpen &&             <SchedulerFlow
            userId={a.advisorId}
            userFirstName={a.advisor.firstName}
            userLastName={a.advisor.lastName}
            eventCustomMinutes={15}
            useNewDesign={true}
            availabilityCloseBtn={true}
            availabilityOnClose={() => {setOpen(false)}}
            availabilityCalendarOnlySelectTime={(datetime) => {datetimeNextPage(datetime); saveAdvisorId(a.advisorId)}}
        />}

    </RectangleSized>);
};

const Container = styled.div`
  ${CardContainer} {
    margin: 1vh auto;
  }
  ${FontSubtitle38To30} {
    text-align: center;
    margin-top: 2vh;
  }
  .next {
    bottom:35px;
  }
`;
export default function InstructionsPageTwo(props) {
    const advisors = props.advisors;

    return (
        <Container>
            <FontSubtitle38To30 spaced={false}>Recommended Advisors</FontSubtitle38To30>
            <br/>
            <CardContainer>
                {advisors.length === 0
                    ? <div style={{margin:"0 auto"}}><FontBody16>No advisors found</FontBody16></div>
                    : <>
                        {advisors.map((advisor, index) => (
                            <InstructionCard key={index+advisor.advisorId} a={advisor} datetimeNextPage={props.setDatetime} saveAdvisorId={props.setId} next={props.handleNext}/>
                        ))}
                    </>
                }
            </CardContainer>
            <BtnContainer>
                <BtnDiv className={'back'} onClick={props.handleBack}>{props.page > 1 && props.page < 5 && <ButtonTertiary label={"Back"} canSubmit={false}/>}</BtnDiv>
                {/*<BtnDiv className={'next'} onClick={props.handleNext}>{props.page < 5 && <ButtonPrimary label={"Next"} canSubmit={false}/>}</BtnDiv>*/}
            </BtnContainer>
        </Container>

    );

}