import React, {useState} from "react";
import {MoneyInputGray, NumberInput, NumberInputWhite, TextInputLean} from "../../common/components/inputs";
import {ButtonPrimary, ButtonTertiary} from "../../common/components/buttons";
import styled from "styled-components";
import {FontSubtitle38To30, FontTitle32To27} from "../../common/components/fonts";
import {BtnContainer, BtnDiv} from "./instructionsflow";
import {savePhoneSMS} from "../../routes/middleware/auth";
import {useDispatch} from "react-redux";

const Container = styled.div`
  justify-content: center;
  display:flex;
  text-align: center;
  height: 95%;
  position: relative;
  flex-direction: row;
  
  & #phone, input, #phone.input {
    position:absolute;
    top:50%;
    left:25%;
    
    width:50%;
  }
  & .save-button {    
    position:absolute;
    top:50%;
    left:80%;
  }
  & ${FontTitle32To27} {
    display:block;
    text-align: center;
    margin-top: 5vh;

  }

  @media screen and (max-width: 460px) {
    & .save-button,  & #phone, input, #phone.input {
      margin: 0 auto;
    }
  }
`;
export default function InstructionsPageThree({handleBack, page, handleNext}) {
    const dispatch = useDispatch();

    const [number, setNumber] = useState("");
    const handleSubmit = () => {
        dispatch(savePhoneSMS(number));
    };
    const handleChange = (e) => {
        setNumber(e.target.value);
    }
    return (
        <>
        <Container>
            <FontTitle32To27>Consumer Phone Number (optional)</FontTitle32To27>
            <NumberInput type="tel" id="phone" name="phone" placeholder="123-456-7890"
                           onChange={handleChange}/>
                <div className={'save-button'} onClick={handleSubmit}><ButtonPrimary label={"Save"} canSubmit={false}/> </div>

        </Container>
            <BtnContainer>
                {/*<BtnDiv className={'back'} onClick={handleBack}>{page > 1 && page < 5 && <ButtonTertiary label={"Back"} canSubmit={false}/>}</BtnDiv>*/}
                <BtnDiv className={'next'} onClick={handleNext}>{page < 5 && <ButtonPrimary label={"Next"} canSubmit={false}/>}</BtnDiv>
            </BtnContainer>
        </>
    );
}