import {images} from '../../../common/components/images';

export const devEnterpriseExistingCourses = [
    {
        "id": 70,
        "title": "Week #1",
        "description": "<p>Here is a description of this amazing course!</p>",
        "imageUrl": images.devDataStoreProduct,
        "tasks": [],
        "isActive": true,
    },
    {
        "id": 71,
        "title": "Week #2",
        "description": "<p>Here is a description of this amazing course!</p>",
        "imageUrl": images.devDataStoreProduct,
        "tasks": [],
        "isActive": true,
    },
    {
        "id": 74,
        "title": "Week #3",
        "description": "<p>Here is a description of this amazing course! is a description of this amazing course! is a description of this amazing course! is a description of this amazing course! is a description of this amazing course!</p>",
        "imageUrl": images.devDataStoreProduct,
        "tasks": [],
        "isActive": true,
    },
    {
        "id": 75,
        "title": "Week #4",
        "description": "<p>Here is a description of this amazing course!</p>",
        "imageUrl": images.devDataStoreProduct,
        "tasks": [],
        "isActive": false,
    },
    {
        "id": 76,
        "title": "Week #5",
        "description": "<p>Here is a description of this amazing course!</p>",
        "imageUrl": images.devDataStoreProduct,
        "tasks": [],
        "isActive": true,
    }
];

export const devEnterpriseNewCourse = {
    "id": 1,
    "title": "course title",
    "description": "course description",
    "imageUrl": "imageUrl",
    "isActive": true,
    "tasks": [ ],
    "categories": [
        {"id": 40,
        "parentCategoryIds": [44],
        "title": "considering divorce"}
    ]
  }

// export const devEnterpriseEditedCourse = (courseId, courseData, getState) => {
//     const course = getState().enterprise.curriculum.curriculum.get("coursesAll").filter(c => {return(c.get("id") === courseId)}).get(0);
//     return({
//         ...course.toJS(),
//         ...courseData
//     })
// };

export const devEnterpriseCourseReport = {
    "id": 70,

};

export const devEnterpriseCreateTask = {
    "id": 1,
    "title": "task title",
    "description": "task description",
    "imageUrl": "image",
    "pages": [
        {"header": {"title": ""}, 
        "actions": {"backLabel": "", "nextLabel": "", "backShowOnTop": false, "pageNumVisible": false, "canContinueAutomatically": false}, 
        "pageNum": 0, 
        "elements": [{"id": 0, "text": "* Here is the body of the article. Edit this text by clicking here directly and begin typing.", "type": "body", "style": {"textAlign": "left"}}],
        "pageType": "page"
    }

    ],
    "isActive": true
  }

  export const devEnterpriseAddTaskToCourse = {
    "id": 1,
    "title": "course title",
    "description": "course description",
    "imageUrl": "imageUrl",
    "isActive": true,
    "tasks": [
        devEnterpriseCreateTask,
        //...
    ],
    "externalAuthor": {
        "firstName": "First",
        "lastName": "Last",
        "additionalInformation": {}
    },
    "categoryIds": [1, 2, 3],
    "categories": [
        {"id": 40,
        "parentCategoryIds": [44],
        "title": "considering divorce"}
    ]
  }