import React from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

/* Components */
import {FontTitle18, FontBody16} from '../../common/components/fonts';
import {colors} from '../../common/components/colors';
import {ButtonPrimary} from "../../common/components/buttons";
import {LogoLoadingSmall} from "../../common/components/loading";
import {ReadOnlyValueInput} from "../../common/components/inputs";
import { ActionBox, InlineItemLeft, InlineItemRight, EditIcon } from "../components/clientsdetail";

/* Middleware */
import {setPromotionModal} from '../store/prospects';
import {setCategoryModal} from '../partnerships/store/coaching';
import { tryGetAllContacts } from '../middleware/contacts';
import { tryGetCoachCategories } from '../middleware/clientsabout';

/* Store */
import {UserAddOutlined, EditOutlined} from "@ant-design/icons";

/* Components */
const ButtonCentered = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: fit-content;
`;

class PromoteProspect extends React.Component {
    state={
        "isLoading": true,
        "client": null,
        "contactRecord": null,
        "isAdvisor": false,
        "categories": []
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.promotionModal !== this.props.promotionModal || prevProps.categoryModal !== this.props.categoryModal) {
            return this.init();
        }
    }

    componentWillUnmount() {

    }

    init = async () => {
        await this.props.tryGetAllContacts();
        const cr = this.props.contactRecord.filter(a => {return(a.get("contactId") === this.props.client.get("contactId"))}).get(0)
        let isAdvisor; 
        let categories;
        if (cr.get("businessUserDetails") !== null && cr.get("businessUserId") !== null) {
            // categories = cr.get("businessUserDetails").get("businessUserCategories").map(a => {return(a.get("categoryName"))})
            categories = await this.props.tryGetCoachCategories(cr.get("businessUserId"))
            console.log(categories)
            categories = await categories.map(a => {return(a.categoryName)})
            isAdvisor = cr.get("businessUserDetails").get("isBusinessUserWmcAdvisor")
        }
        await this.setState({"client": this.props.client, "isLoading": false, "contactRecord": cr, "isAdvisor": isAdvisor, "categories": categories})
    };

    promoteModal = async () => {
        await this.props.setPromotionModal(true);
    }

    categoryModal = async () => {
        await this.props.setCategoryModal(true);
    }

    render() {
        if(this.state.isLoading) {
            return (
                <ActionBox>
                    <FontTitle18>Admin Tools</FontTitle18>
                    <br />
                    <LogoLoadingSmall />
                    <br />
                </ActionBox>
            )
        } else {
            return(
                <ActionBox>
                    <div>
                        <InlineItemLeft><FontTitle18>Admin Tools</FontTitle18></InlineItemLeft>
                        {this.state.contactRecord.get("businessUserId") !== null && 
                            <InlineItemRight>
                                <EditIcon onClick={this.categoryModal}><EditOutlined/></EditIcon>
                            </InlineItemRight>
                        }
                    </div>
                    {this.state.contactRecord.get("businessUserId") !== null ?
                        <>
                            <ReadOnlyValueInput title={"Status"} value={!this.state.isAdvisor ? "Coach" : "Advisor"} />
                            {!this.state.isAdvisor && <ReadOnlyValueInput title={"Categories"} value={this.state.categories.join(", ")} />}
                        </> 
                         :
                        <ButtonCentered onClick={this.promoteModal}><ButtonPrimary label={<><UserAddOutlined /> Promote Prospect</>} canSubmit={true}/></ButtonCentered>}
                </ActionBox>
            )
        }
    }
}

const mapStateToProps = state => ({
    client: state.enterprise.clientsDetail.get("client"),
    contactRecord: state.enterprise.contacts.get("contactsAll"),
    promotionModal: state.enterprise.prospects.get("promotionModal"),
    categoryModal: state.enterprise.partners.partners.get("categoryModal")
});

const mapDispatchToProps = dispatch => ({
    setPromotionModal: (status) => dispatch(setPromotionModal(status)),
    tryGetAllContacts: () => dispatch(tryGetAllContacts()),
    setCategoryModal: (status) => dispatch(setCategoryModal(status)),
    tryGetCoachCategories: (coachId) => dispatch(tryGetCoachCategories(coachId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PromoteProspect));