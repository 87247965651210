import moment from "moment";
import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setRescheduleSessionDeleting, setRescheduleSessionDeletingError, setRescheduleSessionCreating, setRescheduleSessionCreatingError, setRescheduleSession, setRescheduleSessionFetching} from "../store/reschedule";

/* Static */
import {devIframeSession, devIframeSessionFromToken} from "../static/devdata";

export const tryGetSessionFromToken = (token) => {
    return async dispatch => {
        dispatch(setRescheduleSessionFetching(true));
        const session = await axiosReq('apiv1/public/sessions/'+token, "GET", {}, devIframeSessionFromToken);
        if(session.hasOwnProperty("error")) {
            dispatch(setRescheduleSession("error"));
        } else {
            dispatch(setRescheduleSession(session));
        }
        return dispatch(setRescheduleSessionFetching(false));
    }
};

// Used for iframe scheduler only
// Platform set by user logged in to determine if expert->expert or consumer->expert
export const tryPostCreateRescheduleSessionAppointment = (start, meetingLength, token, purchaseHistoryId, isWillowSession) => {
    return async (dispatch, getState) => {
        dispatch(setRescheduleSessionCreating(true));
        // const platform = getState().common.user.get("platform");
        const startTime = moment.utc(start).format("YYYY-MM-DD[T]HH:mm:ss[+]00:00");
        const endTime = moment.utc(start).add(meetingLength, "minutes").format("YYYY-MM-DD[T]HH:mm:ss[+]00:00");
        // const url = platform === "consumer" ? 'apiv1/consumer/sessions/create/'+scheduleId : 'apiv1/coach/coach/'+scheduleId+'/sessions';
        const url = 'apiv1/public/sessions/'+token;
        const session = await axiosReq(url, "POST", {"start": startTime, "expected_end": endTime, "topic": "", "description": "", "purchaseHistoryId": purchaseHistoryId, "isWillowSession": isWillowSession}, devIframeSession);
        if(session.hasOwnProperty("error")) {
            await dispatch(setRescheduleSessionCreatingError(session.error));
        } else {
            await dispatch(setRescheduleSessionCreatingError(null));
        }
        dispatch(setRescheduleSessionCreating(false));
        return session.cancelToken;
    }
};

export const tryPostDeleteSessionAppointment = (token) => {
    return async dispatch => {
        dispatch(setRescheduleSessionDeleting(true));
        const session = await axiosReq('apiv1/public/sessions/'+token, "DELETE", {}, {});
        if(session.hasOwnProperty("error")) {
            await dispatch(setRescheduleSessionDeletingError(session.error));
        } else {
            await dispatch(setRescheduleSessionDeletingError(null));
        }
        dispatch(setRescheduleSessionDeleting(false));
    }
};