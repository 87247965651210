import React from 'react';
import { Switch } from 'react-router-dom';

/* Containers */
import SecureRoute from '../../../routes/containers/routesecure';

/* Pages - Enterprise */
import EnterpriseProspects from '../../../enterprise/pages/prospects';
import EnterpriseClients from '../../../enterprise/clients/page';
import EnterpriseClientsDetail from '../../../enterprise/pages/clientsdetail';
import EnterpriseListings from '../../../enterprise/listings/page';
import EnterpriseMeetings from '../../../enterprise/meetings/page';
import EnterpriseSettings from '../../../enterprise/settings/page';
import EnterpriseMyTeam from '../../../enterprise/team/page';
import EnterpriseExperts from '../../../enterprise/experts/page';
import EnterpriseResources from '../../../enterprise/resources/page';
import EnterpriseContacts from '../../../enterprise/pages/contacts';
import EnterpriseMyShop from '../../../enterprise/shop/page';
import EnterpriseMyProfile from '../../../enterprise/profile/page';
import EnterpriseAvailability from '../../../enterprise/availability/page';
import EnterpriseSharedVault from '../../../enterprise/vault/page';
import EnterpriseBilling from '../../../enterprise/billing/page';
import EnterprisePartners from '../../../enterprise/partnerships/page';
import EnterpriseHome from "../../../enterprise/homepage/page";
import EnterpriseForms from "../../../enterprise/forms/page";

export const enterpriseRoutes = [
    '/contacts',
    '/contacts/previous',
    '/contacts/create',
    '/prospects',
    '/prospects/create',
    '/clients',
    '/clients/previous',
    '/clients/create',
    '/contact/activity',
    '/contact/notes',
    '/contact/tasks',
    '/contact/emails',
    '/contact/sms',
    '/contact/chats',
    '/contact/meetings',
    '/contact/messages',
    '/contact/finances',
    '/contact/instructions',
    '/contact/vault',
    '/contact/edit',
    '/home',
    '/meetings',
    '/meetings/create',
    '/meetings/create/call',
    '/meetings/create/webinar',
    '/meetings/edit/webinar',
    '/meetings/details',
    '/meetings/page',
    '/vaults',
    '/vaults/drive',
    '/vaults/create',
    '/shop',
    '/shop/products',
    '/shop/portfolio',
    '/shop/portfolio/create',
    '/shop/portfolio/edit',
    '/shop/testimonials',
    '/shop/testimonials/create',
    '/shop/testimonials/edit',
    '/shop/faqs',
    '/shop/faqs/create',
    '/shop/faqs/edit',
    '/shop/about',
    '/shop/about/experience/create',
    '/shop/about/experience/edit',
    '/shop/about/education/create',
    '/shop/about/education/edit',
    '/shop/about/license/create',
    '/shop/about/license/edit',
    '/profile',
    '/profile/about',
    '/profile/about/experience/create',
    '/profile/about/experience/edit',
    '/profile/about/education/create',
    '/profile/about/education/edit',
    '/profile/about/license/create',
    '/profile/about/license/edit',
    '/availability',
    '/team',
    '/team/member',
    '/experts',
    '/resources',
    '/products',
    '/products/create',
    '/products/edit',
    '/settings/basic-info',
    '/settings/security',
    '/settings/branding',
    '/settings/coaching',
    '/settings/users',
    '/settings/users/create',
    '/settings/users/edit',
    '/settings/prospects',
    '/settings/availability/calendar',
    '/settings/availability/appointments',
    '/settings/availability/appointments/create',
    '/settings/availability/appointments/edit',
    '/settings/billing',
    '/partners',
    '/partners/employees',
    '/partners/employees/member',
    '/partners/employees/clients',
    '/partners/support',
    '/partners/support/member',
    '/firms',
    '/firms/employees',
    '/firms/employees/member',
    '/firms/experts',
    '/firms/experts/member',
    '/firms/experts/clients',
    '/firms/support',
    '/firms/support/member',
    '/curriculum',
    '/curriculum/create',
    '/curriculum/edit',
    '/curriculum/preview'
];

const RoutesTrustWillowEnterprise = () => (
    <Switch>
        <SecureRoute exact path={'/contacts'} component={EnterpriseContacts}/>
        <SecureRoute exact path={'/contacts/previous'} component={EnterpriseContacts}/>
        <SecureRoute exact path={'/contacts/create'} component={EnterpriseContacts}/>
        <SecureRoute exact path={'/prospects'} component={EnterpriseProspects}/>
        <SecureRoute exact path={'/prospects/create'} component={EnterpriseProspects}/>
        <SecureRoute exact path={'/clients'} component={EnterpriseClients}/>
        <SecureRoute exact path={'/clients/previous'} component={EnterpriseClients}/>
        <SecureRoute exact path={'/clients/create'} component={EnterpriseClients}/>
        <SecureRoute exact path={'/contact/activity'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/notes'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/tasks'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/emails'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/sms'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/chats'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/meetings'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/messages'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/finances'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/instructions'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/vault'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/edit'} component={EnterpriseContacts}/>
        <SecureRoute exact path={'/home'} component={EnterpriseHome} />
        <SecureRoute exact path={'/meetings'} component={EnterpriseMeetings}/>
        <SecureRoute exact path={'/meetings/create'} component={EnterpriseMeetings}/>
        <SecureRoute exact path={'/meetings/create/call'} component={EnterpriseMeetings}/>
        <SecureRoute exact path={'/meetings/create/webinar'} component={EnterpriseMeetings}/>
        <SecureRoute exact path={'/meetings/edit/webinar'} component={EnterpriseMeetings}/>
        <SecureRoute exact path={'/meetings/details'} component={EnterpriseMeetings}/>
        <SecureRoute exact path={'/meetings/page'} component={EnterpriseMeetings}/>
        <SecureRoute exact path={'/vaults'} component={EnterpriseSharedVault}/>
        <SecureRoute exact path={'/vaults/drive'} component={EnterpriseSharedVault}/>
        <SecureRoute exact path={'/vaults/create'} component={EnterpriseSharedVault}/>
        <SecureRoute exact path={'/shop'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/products'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/portfolio'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/portfolio/create'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/portfolio/edit'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/testimonials'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/testimonials/create'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/testimonials/edit'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/faqs'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/faqs/create'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/faqs/edit'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/about'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/about/experience/create'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/about/experience/edit'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/about/education/create'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/about/education/edit'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/about/license/create'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/about/license/edit'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/profile'} component={EnterpriseMyProfile}/>
        <SecureRoute exact path={'/profile/about'} component={EnterpriseMyProfile}/>
        <SecureRoute exact path={'/profile/about/experience/create'} component={EnterpriseMyProfile}/>
        <SecureRoute exact path={'/profile/about/experience/edit'} component={EnterpriseMyProfile}/>
        <SecureRoute exact path={'/profile/about/education/create'} component={EnterpriseMyProfile}/>
        <SecureRoute exact path={'/profile/about/education/edit'} component={EnterpriseMyProfile}/>
        <SecureRoute exact path={'/profile/about/license/create'} component={EnterpriseMyProfile}/>
        <SecureRoute exact path={'/profile/about/license/edit'} component={EnterpriseMyProfile}/>
        <SecureRoute exact path={'/availability'} component={EnterpriseAvailability}/>
        <SecureRoute exact path={'/team'} component={EnterpriseMyTeam}/>
        <SecureRoute exact path={'/team/member'} component={EnterpriseMyTeam}/>
        <SecureRoute exact path={'/experts'} component={EnterpriseExperts}/>
        <SecureRoute exact path={'/resources'} component={EnterpriseResources}/>
        <SecureRoute exact path={'/products'} component={EnterpriseListings}/>
        <SecureRoute exact path={'/products/create'} component={EnterpriseListings}/>
        <SecureRoute exact path={'/products/edit'} component={EnterpriseListings}/>
        <SecureRoute exact path={'/settings/basic-info'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/security'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/branding'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/coaching'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/users'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/users/create'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/users/edit'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/prospects'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/availability/calendar'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/availability/appointments'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/availability/appointments/create'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/availability/appointments/edit'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/billing'} component={EnterpriseBilling}/>
        <SecureRoute exact path={'/partners'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/partners/employees'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/partners/employees/member'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/partners/employees/clients'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/partners/support'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/partners/support/member'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/firms'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/firms/employees'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/firms/employees/member'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/firms/experts'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/firms/experts/member'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/firms/experts/clients'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/firms/support'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/firms/support/member'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/curriculum'} component={EnterpriseForms}/>
        <SecureRoute exact path={'/curriculum/create'} component={EnterpriseForms}/>
        <SecureRoute exact path={'/curriculum/edit'} component={EnterpriseForms}/>
        <SecureRoute exact path={'/curriculum/preview'} component={EnterpriseForms}/>
    </Switch>
);

export default RoutesTrustWillowEnterprise;
