import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { List } from 'immutable';
import validator from "validator";

/* Components */
import { ActionBox, EditIcon, DeactiveEditIcon, InlineItemLeft, InlineItemRight, FitContentButton } from "../components/clientsdetail";
import { FontTitle18 } from '../../common/components/fonts';
import {
    CheckboxWithLabel,
    DropdownInput,
    MoneyInputGray,
    PercentInputGray,
    ReadOnlyValueInput
} from '../../common/components/inputs';
import { ButtonPrimary, ButtonInactive } from '../../common/components/buttons';
import { EditOutlined } from "@ant-design/icons";
import { LogoLoadingSmall } from "../../common/components/loading";

/* Middleware */
import { tryGetProspectStages, tryUpdateProspectStage, getProspectStageIdByTitle, stageOptions } from '../middleware/prospectsstages';
import { tryGetOneProspect, createProspectFromContact, tryEditProspect } from '../middleware/prospects';
import { tryGetOneClient, tryEditClient } from '../clients/middleware/clients';
import { tryGetOneContact } from "../middleware/contacts";

/* Store */
import { setClientsDetailSelectedEdit } from '../store/clientsdetail';

/* Utils */
import {stringToMoney} from '../../common/utils/money';

const formatCapitalizeFirst = (stage) => {
    return stage.charAt(0).toUpperCase() + stage.slice(1)
};

class ContactRelationship extends React.Component {
    state = {
        "isLoading": true,
        "isEditing": false,
        "prospectStatus": null,
        "contactId": null,
        "referralSource": null,
        "prospectId": null,
        "isValid": true,
        "isChanged": false,
        "stageId": '',
        'stageTitleBeforeChange': null,
        "stageTitle": '',
        "stageValid": false,
        "prospectChecked": false,
        "clientChecked": false,
        "contactChecked": false,
        "advisor": null,
        "revenue": "$0",
        "revenueStyled": "$0",
        "probabilityOfClosing": 0,
        "wmcId": null
    };

    componentDidMount() {
        return this.refresh();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.clientsDetail !== this.props.clientsDetail) {
            return this.refresh()
        }
    }

    refresh = async () => {
        const prospectStatus = this.props.clientsDetail.get("prospectStatus");
        const prospectId = this.props.clientsDetail.get("prospectId");
        const contactId = this.props.clientsDetail.get("contactId");
        await this.props.tryGetOneContact(contactId, "store");
        if(prospectStatus === "Prospect") {
            await this.props.tryGetOneProspect(prospectId, "store");
            return this.updateProspectOrClient("prospectsOne");
        } else if(prospectStatus === "Client") {
            await this.props.tryGetOneClient(prospectId, "store");
            return this.updateProspectOrClient("clientsOne");
        } else if(prospectStatus === "Contact") {
            return this.updateContact();
        }
    };

    updateProspectOrClient = async (store) => {
        await this.setState({
            "contactId": this.props.contactsOne.get("contactId"),
            "referralSource": this.props.contactsOne.get("referralSource"),
            "prospectStatus": store === "prospectsOne" ? "Prospect" : "Client",
            "prospectId": this.props[store].get("prospectId"),
            "stageId": this.props[store].get("prospectStageId"),
            'stageTitleBeforeChange': this.props[store].get("prospectStageName"),
            'stageTitle': this.props[store].get("prospectStageName"),
            "stageValid": true,
            "prospectChecked": this.props[store].get("prospectStageName") !== "client",
            "clientChecked": this.props[store].get("prospectStageName") === "client",
            "contactChecked": false,
            "advisor": this.props[store].get("advisor"),
            "isLoading": false,
            "revenue": this.props[store].get("revenue"),
            "revenueStyled": this.props[store].get("revenue") === "0.00" ? "---" : "$"+stringToMoney(this.props[store].get("revenue")),
            "probabilityOfClosing": this.props[store].get("probabilityOfClosing"),
            "wmcId": this.props[store].get("wmcId"),
        });
        console.log(this.state.revenueStyled)
    };

    updateContact = async () => {
        await this.setState({
            "contactId": this.props.contactsOne.get("contactId"),
            "referralSource": this.props.contactsOne.get("referralSource"),
            "prospectStatus": "Contact",
            "prospectId": null,
            "stageId": null,
            'stageTitleBeforeChange': null,
            'stageTitle': null,
            "stageValid": true,
            "prospectChecked": false,
            "clientChecked": false,
            "contactChecked": true,
            "advisor": null,
            "isLoading": false,
            "revenue": "$0",
            "revenueStyled": "---",
            "probabilityOfClosing": 0,
            "wmcId": null
        });
    };

    edit = async () => {
        if(this.props.stages === List()) {
            await this.props.tryGetProspectStages();
        }
        this.setState({"isEditing": true})
    };

    close = async () => {this.setState({"isEditing": false});};

    save = async () => {
        if(!this.state.contactChecked) {
            if(this.state.prospectStatus === "Contact") {
                const newProspect = await this.props.createProspectFromContact(
                    this.state.contactId,
                    {
                        "probabilityOfClosing": this.state.probabilityOfClosing,
                        "revenue": this.state.revenue
                    }
                );
                await this.props.tryUpdateProspectStage({
                    "prospectId": newProspect.prospectId,
                    "stageId": this.state.stageId,
                    "fromStatus": "Prospect",
                    "toStatus": this.state.prospectChecked ? "Prospect" : "Client"
                });
                this.props.setClientsDetailSelectedEdit({
                    "consumerId": newProspect.consumerId,
                    "prospectId": newProspect.prospectId,
                    "prospectStatus": this.state.prospectChecked ? "Prospect" : "Client"
                });
            } else {
                await this.props.tryUpdateProspectStage({
                    "prospectId": this.state.prospectId,
                    "stageId": this.state.stageId,
                    "fromStatus": this.state.prospectStatus,
                    "toStatus": this.state.prospectChecked ? "Prospect" : "Client"
                });
                await this.props.tryEditProspect({
                    "prospectId": this.state.prospectId,
                    "probabilityOfClosing": this.state.probabilityOfClosing,
                    "revenue": this.state.revenue
                });
                await this.props.tryEditClient({
                    "prospectId": this.state.prospectId,
                    "probabilityOfClosing": this.state.probabilityOfClosing,
                    "revenue": this.state.revenue
                });
                this.props.setClientsDetailSelectedEdit({
                    "prospectStatus": this.state.prospectChecked ? "Prospect" : "Client",
                    "revenue": this.state.revenue,
                    "probabilityOfClosing": this.state.probabilityOfClosing
                });
            }
        }
        this.close();
    };

    checkValid = () => {
        if(this.state.prospectChecked && this.state.stageValid) {
            this.setState({'isValid': true})
        } else if(!this.state.prospectChecked && (this.state.clientChecked || this.state.contactChecked)) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleChangeStage = async e => {
        await this.setState({
            'stageId': e.value,
            'stageTitle': e.label,
            "stageValid": (!validator.isEmpty(e.label) && e.label !== ''),
            'isChanged': true
        });
        this.checkValid();
    };

    handleChangeRevenue = async e => {
        let revNoDollarSign = e.target.value.charAt(0) === "$" ? e.target.value.substring(1) : e.target.value;
        await this.setState({
            'revenue': e.target.value,
            'isChanged': true,
            'revenueStyled': "$"+stringToMoney(revNoDollarSign.replaceAll(",", ""))
        });
        this.checkValid();
    };

    handleChangeProbabilityOfClosing = async e => {
        if(e === undefined || e === null) {
            await this.setState({
                'probabilityOfClosing': 0,
                'isChanged': true
            });
        } else {
            if(validator.isNumeric(e.toString())) {
                this.setState({
                    'probabilityOfClosing': (parseInt(e) > 100 ? 100 : (parseInt(e) < 0 ? 0 : parseInt(e))),
                    'isChanged': true
                });
            } else {
                await this.setState({
                    'probabilityOfClosing': 0,
                    'isChanged': true
                });
            }
        }
        this.checkValid();
    };

    handleMakeProspect = async (id, status) => {
        await this.setState({
            prospectChecked: status,
            clientChecked: false,
            contactChecked: false,
            stageId: '',
            stageTitle: '',
            stageValid: false,
            isChanged: true
        });
        this.checkValid();
    };

    handleMakeClient = async (id, status) => {
        await this.setState({
            prospectChecked: false,
            clientChecked: status,
            contactChecked: false,
            stageId: status ? await this.props.getProspectStageIdByTitle("client") : '',
            stageTitle: status ? 'client' : '',
            stageValid: false,
            isChanged: true
        });
        this.checkValid();
    };

    handleMakeContact = async (id, status) => {
        await this.setState({
            prospectChecked: false,
            clientChecked: false,
            contactChecked: status,
            stageId: '',
            stageTitle: '',
            stageValid: false,
            isChanged: true
        });
        this.checkValid();
    };

    render() {
        if(this.state.isLoading) {
            return(
                <ActionBox>
                    <FontTitle18>Relationship</FontTitle18>
                    <br />
                    <br />
                    <LogoLoadingSmall />
                    <br />
                </ActionBox>
            )
        } else {
            let canEdit = true; // this.state.referralSource === "willow" ? false : (this.props.isWMC ? true : (this.state.advisor.get("advisorId") === this.props.userId));
            if(!this.state.isEditing) {
                return(
                    <ActionBox>
                        <div>
                            <InlineItemLeft><FontTitle18>Relationship</FontTitle18></InlineItemLeft>
                            <InlineItemRight>
                                {canEdit
                                    ? <EditIcon onClick={this.edit}><EditOutlined/></EditIcon>
                                    : <DeactiveEditIcon><EditOutlined/></DeactiveEditIcon>
                                }
                            </InlineItemRight>
                        </div>
                        {/*<ReadOnlyValueInput title={"Source"} value={this.state.referralSource === "willow" ? (this.state.wmcId === null ? "Willow" : (this.state.advisor.get("firstName")+" "+this.state.advisor.get("lastName"))) : this.props.isWMC ? this.props.companyName : (this.state.advisor === null ? (this.props.user.get("first") + " " + this.props.user.get("last")) : (this.state.advisor.get("firstName")+" "+this.state.advisor.get("lastName")))} />*/}
                        <ReadOnlyValueInput title={"Status"} value={this.state.prospectStatus}/>
                        {(this.state.prospectStatus !== "Client" && this.state.prospectStatus !== "Contact") &&
                            <>
                                {console.log(this.state.revenueStyled)}
                                <ReadOnlyValueInput title={"Stage"} value={formatCapitalizeFirst(this.state.stageTitleBeforeChange)}/>
                                <ReadOnlyValueInput title={this.props.isWMC ? "Assets" : "Revenue"} value={this.state.revenueStyled} />
                                <ReadOnlyValueInput title={"Probability to win"} value={parseInt(this.state.probabilityOfClosing) === 0 ? "---" : (parseInt(this.state.probabilityOfClosing).toFixed(0)+"%")}/>
                            </>
                        }
                    </ActionBox>
                )
            } else {
                return(
                    <ActionBox>
                        <FontTitle18>Relationship</FontTitle18>
                        {(!this.props.user.get("isSupport") || (this.props.user.get("isSupport") && this.state.prospectStatus === "Contact")) &&
                            <>
                                <br />
                                <br />
                                <CheckboxWithLabel
                                    id={1}
                                    action={this.handleMakeProspect}
                                    checked={this.state.prospectChecked}
                                    label={"Prospect"}
                                />
                            </>
                        }
                        {this.state.prospectChecked &&
                            <>
                                <DropdownInput
                                    title={"Stage*"}
                                    options={stageOptions(this.props.stages)}
                                    id={'stage'}
                                    onChange={this.handleChangeStage}
                                    placeholder={'Stage'}
                                    value={formatCapitalizeFirst(this.state.stageTitle)}
                                />
                                <MoneyInputGray
                                    title={this.props.isWMC ? "Assets" : "Revenue"}
                                    valid={true}
                                    warning={""}
                                    id={"revenue"}
                                    onChange={this.handleChangeRevenue}
                                    placeholder={"$0"}
                                    value={this.state.revenue}
                                />
                                <PercentInputGray
                                    title={"Probability to win"}
                                    valid={true}
                                    warning={""}
                                    id={"probabilityOfClosing"}
                                    onChange={this.handleChangeProbabilityOfClosing}
                                    placeholder={0}
                                    value={this.state.probabilityOfClosing}
                                />
                            </>
                        }
                        {!this.props.user.get("isSupport") &&
                            <>
                                <br />
                                <CheckboxWithLabel
                                    id={2}
                                    action={this.handleMakeClient}
                                    checked={this.state.clientChecked}
                                    label={"Client"}
                                />
                            </>
                        }
                        {this.state.prospectStatus === "Contact" &&
                            <>
                                <br />
                                <CheckboxWithLabel
                                    id={3}
                                    action={this.handleMakeContact}
                                    checked={this.state.contactChecked}
                                    label={"Contact"}
                                />
                            </>
                        }
                        <br />
                        <>
                            {(this.state.isChanged && this.state.isValid)
                                ? <FitContentButton onClick={this.save}><ButtonPrimary canSubmit={false} label={"Save"} /></FitContentButton>
                                : <FitContentButton><ButtonInactive canSubmit={false} label={"Save"} /></FitContentButton>
                            }
                            <FitContentButton onClick={this.close}><ButtonInactive canSubmit={false} label={"Cancel"} /></FitContentButton>
                        </>
                    </ActionBox>
                )
            }
        }
    }
}

const mapStateToProps = state => ({
    stages: state.enterprise.prospects.get("prospectStages"),
    prospectsOne: state.enterprise.prospects.get("prospectsOne"),
    clientsOne: state.enterprise.clientsList.get("clientsOne"),
    contactsOne: state.enterprise.contacts.get("contactsOne"),
    clientsDetail: state.enterprise.clientsDetail.get("client"),
    companyName: state.common.wmc.get("wmc").get("companyName"),
    isWMC: state.common.wmc.get("isWMC"),
    userId: state.common.user.get("userId"),
    user: state.common.user,
});

const mapDispatchToProps = dispatch => ({
    tryGetProspectStages: () => dispatch(tryGetProspectStages()),
    tryUpdateProspectStage: (data) => dispatch(tryUpdateProspectStage(data)),
    tryGetOneProspect: (prospectId, storeOrReturn) => dispatch(tryGetOneProspect(prospectId, storeOrReturn)),
    tryGetOneClient: (prospectId, storeOrReturn) => dispatch(tryGetOneClient(prospectId, storeOrReturn)),
    tryGetOneContact: (contactId, storeOrReturn) => dispatch(tryGetOneContact(contactId, storeOrReturn)),
    createProspectFromContact: (contactId, extraDataObject) => dispatch(createProspectFromContact(contactId, extraDataObject)),
    getProspectStageIdByTitle: (title) => dispatch(getProspectStageIdByTitle(title)),
    setClientsDetailSelectedEdit: (edit) => dispatch(setClientsDetailSelectedEdit(edit)),
    tryEditProspect: (data) => dispatch(tryEditProspect(data)),
    tryEditClient: (data) => dispatch(tryEditClient(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactRelationship));
