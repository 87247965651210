import React from 'react';
import {connect} from "react-redux";
// import validator from "validator";

/* Middleware */
import {tryGetBillingPaymentMethod/*, tryGetBillingPaymentMethodUpdate*/} from "../middleware/billing";

/* Components */
import {SettingsCard, Left2, Right, Right2, Right3, Right4, CurrentPlanRow, InlineButton, Left, FlexRowJCSB} from "../components/billing";
import {FontBody16, FontHeader21, FontTitle18} from "../../../common/components/fonts";
import {ButtonPrimary} from "../../../common/components/buttons";
import {ButtonLinkSecondary, ButtonTertiary} from "../../../common/components/buttons";
import {CloseOutlined, EditOutlined} from "@ant-design/icons";
import {LogoLoading} from "../../../common/components/loading";
import {colors} from "../../../common/components/colors";
import {Pointer} from "../../../common/components/layout";
// import {PhoneInput, TextInput, SearchInputUncontrolledDisplay} from "../../../common/components/inputs";
// import {InlineBlock, SaveSection, Width, SubmitInputWithValidation} from "../../../enterprise/components/clientscreate";

/* Domain */
// import getDomainEnv from '../../../domains/utils';
// const env = getDomainEnv();

class BillingPaymentMethod extends React.Component {
    state={
        "isLoading": true, 
        "paymentMethod": {}, 
        // "isEditing": false,
        // "paymentId": "",
        // "email": "", "emailValid": false,
        // "phone": "", "phoneValid": false,
        // "line1": "", "line1Valid": false,
        // "line2": "", "line2Valid": false,
        // "country": "", "countryValid": false, "countryLabel": "",
        // "countries": [{value: {abbr: "CA"}, label: "Canada"}, {value: {abbr: "MX"}, label: "Mexico"}, {value: {abbr: "GB"}, label: "United Kingdom"}, {value: {abbr: "US"}, label: "United States"}],
        // "state": "", "stateValid": false,
        // "city": "", "cityValid": false,
        // "zip": "", "zipValid": false,
        // "expMonth": "", "monthValid": false,
        // "expYear": "", "yearValid": false,
        // "exp": "", "expValid": false,
        // "isChanged": false,
        // "isValid": false,
        // "isSaving": false,
        // "isSaved": false
    };

    componentDidMount() {
        return this.init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.paymentMethod !== this.props.paymentMethod) {
            return this.update();
        }
    }

    init = async () => {
        await this.props.tryGetBillingPaymentMethod();
        return this.update();
    };

    update = async () => {
        this.setState({
            "paymentMethod": this.props.paymentMethod.toJS(),
            "isLoading": false
        });
    };

    // reset = async () => {
    //     await this.setState({
    //         "isLoading": true,
    //         "paymentMethod": {},
    //         "isEditing": false,
    //         "paymentId": "",
    //         "email": "", "emailValid": false,
    //         "phone": "", "phoneValid": false,
    //         "line1": "", "line1Valid": false,
    //         "line2": "", "line2Valid": false,
    //         "country": "", "countryValid": false, "countryLabel": "",
    //         "countries": [{value: {abbr: "CA"}, label: "Canada"}, {value: {abbr: "MX"}, label: "Mexico"}, {value: {abbr: "GB"}, label: "United Kingdom"}, {value: {abbr: "US"}, label: "United States"}],
    //         "state": "", "stateValid": false,
    //         "city": "", "cityValid": false,
    //         "zip": "", "zipValid": false,
    //         "expMonth": "", "monthValid": false,
    //         "expYear": "", "yearValid": false,
    //         "exp": "", "expValid": false,
    //         "isChanged": false,
    //         "isValid": false,
    //         "isSaving": false,
    //         "isSaved": false
    //     });
    //     await this.init();
    // };
    //
    // updateMethod = async () => {
    //     let exp = this.state.exp.split('/');
    //     let month = exp[0];
    //     let year = exp[1];
    //     let data = {
    //         'paymentMethodId': this.state.paymentId,
    //         'email': this.state.email,
    //         'phone': this.state.phone,
    //         'city': this.state.city,
    //         'country': this.state.country,
    //         'line1': this.state.line1,
    //         'line2': this.state.line2,
    //         'postalCode': this.state.zip,
    //         'state': this.state.state,
    //         'expirationMonth': month,
    //         'expirationYear': year
    //     };
    //     // await this.props.tryGetBillingPaymentMethodUpdate(data)
    //     this.reset();
    // };
    //
    // showUpdate = async (status, id) => {
    //     if (id !== "") {
    //         this.state.paymentMethod.data.map(h => {
    //             if (h.id === id) {
    //                 this.setState({
    //                     "email": h.billing_details.email,
    //                     "phone": h.billing_details.phone,
    //                     "line1": h.billing_details.address.line1,
    //                     "line2": h.billing_details.address.line2,
    //                     "country": h.billing_details.address.country,
    //                     "state": h.billing_details.address.state,
    //                     "city": h.billing_details.address.city,
    //                     "zip": h.billing_details.address.postal_code,
    //                     "expMonth": h.card.exp_month,
    //                     "expYear": h.card.exp_year,
    //                     "exp": h.card.exp_month + "/" + h.card.exp_year,
    //                     "isValid": true,
    //                     "emailValid": true, "phoneValid": true, "expValid": true, "zipValid": true,
    //                     "line1Valid": true, "line2Valid": true, "countryValid": true, "cityValid": true,
    //                     "stateValid": true
    //                 })
    //             }
    //         })
    //     }
    //     await this.setState({
    //         "isEditing": status,
    //         "paymentId": id
    //     });
    // };
    //
    // handleChangeEmail = async e => {
    //     this.setState({'email': e.target.value});
    //     await this.setState({'emailValid': validator.isEmail(e.target.value)});
    //     this.setState({'isChanged': true});
    //     this.checkValid();
    // };
    // handleChangePhone = async e => {
    //     this.setState({'phone': e.target.value});
    //     await this.setState({'phoneValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
    //     this.setState({'isChanged': true});
    //     this.checkValid();
    // };
    // handleChangeLine1 = async e => {
    //     this.setState({'line1': e.target.value});
    //     await this.setState({'line1Valid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
    //     this.setState({'isChanged': true});
    //     this.checkValid();
    // };
    // handleChangeLine2 = async e => {
    //     this.setState({'line2': e.target.value});
    //     await this.setState({'line2Valid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
    //     this.setState({'isChanged': true});
    //     this.checkValid();
    // };
    // handleChangeCountry = async e => {
    //     this.setState({'country': e.value.abbr, 'countryLabel': e.label});
    //     await this.setState({'countryValid': true});
    //     this.setState({'isChanged': true});
    //     this.checkValid();
    // };
    // handleChangeState = async e => {
    //     this.setState({'state': e.target.value});
    //     await this.setState({'stateValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
    //     this.setState({'isChanged': true});
    //     this.checkValid();
    // };
    // handleChangeCity = async e => {
    //     this.setState({'city': e.target.value});
    //     await this.setState({'cityValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
    //     this.setState({'isChanged': true});
    //     this.checkValid();
    // };
    // handleChangeZip = async e => {
    //     this.setState({'zip': e.target.value});
    //     await this.setState({'zipValid': (!validator.isEmpty(e.target.value) && e.target.value !== '') && validator.isPostalCode(e.target.value, "any")});
    //     this.setState({'isChanged': true});
    //     this.checkValid();
    // };
    // handleChangeExp = async e => {
    //     this.setState({'exp': e.target.value});
    //     await this.setState({'expValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
    //     this.setState({'isChanged': true});
    //     this.checkValid();
    // };
    //
    // checkValid = () => {
    //     if(this.state.emailValid /* && this.state.phoneValid */ && this.state.line1Valid && this.state.line2Valid && this.state.countryValid && this.state.stateValid && this.state.cityValid && this.state.zipValid && this.state.expValid) {
    //         this.setState({'isValid': true})
    //     } else {
    //         this.setState({'isValid': false})
    //     }
    // };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <>
                    <SettingsCard backgroundColor={colors.backgroundColor7} zIndex={'2'}>
                        <FontHeader21>Payment Methods</FontHeader21>
                        <Right>{/* <CurrentPlanRow /> */}
                        {/*!this.state.isEditing &&*/ this.state.paymentMethod.data.map((h, i) => (
                            <FlexRowJCSB key={h.id}>
                                <FontBody16>******{h.card.last4}</FontBody16>
                                <Pointer onClick={() => this.props.showEdit(true, h)}><FontBody16><EditOutlined /></FontBody16></Pointer>
                                <Pointer onClick={() => this.props.showDelete(true, h.id)}><FontBody16><CloseOutlined /></FontBody16></Pointer>
                            </FlexRowJCSB>
                        ))}
                        {/*{(this.state.isEditing) &&*/}
                        {/*    <>*/}
                        {/*        {this.state.isSaved*/}
                        {/*            ?*/}
                        {/*                <FontTitle18>Updated!</FontTitle18>*/}
                        {/*            :*/}
                        {/*            <>*/}
                        {/*                <TextInput title={"Email"} valid={this.state.emailValid} warning={"Required"} id={"email"} onChange={this.handleChangeEmail} placeholder={"me@"+env.URL.DOMAIN} value={this.state.email} />*/}
                        {/*                <PhoneInput title={"Phone Number"} valid={this.state.phoneValid} warning={"Enter a valid mobile phone"} id={"phone"} onChange={this.handleChangePhone} placeholder={'(###) ###-####'} value={this.state.phone} options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}}/>*/}
                        {/*                <TextInput title={"Address Line 1"} valid={this.state.line1Valid} warning={""} id={"line1"} onChange={this.handleChangeLine1} placeholder={"Address Line 1"} value={this.state.line1} />*/}
                        {/*                <TextInput title={"Address Line 2"} valid={this.state.line2Valid} warning={""} id={"line2"} onChange={this.handleChangeLine2} placeholder={"Address Line 2"} value={this.state.line2} />*/}
                        {/*                <SearchInputUncontrolledDisplay title={"Country"} options={this.state.countries} id={'country'} onChange={this.handleChangeCountry} placeholder={'Country'} value={this.state.countryLabel}/>*/}
                        {/*                <TextInput title={"State"} valid={this.state.stateValid} warning={""} id={"state"} onChange={this.handleChangeState} placeholder={"State"} value={this.state.state} />*/}
                        {/*                <TextInput title={"City"} valid={this.state.cityValid} warning={""} id={"city"} onChange={this.handleChangeCity} placeholder={"City"} value={this.state.city} />*/}
                        {/*                <TextInput title={"Postal Code"} valid={this.state.zipValid} warning={"Required"} id={"zip"} onChange={this.handleChangeZip} placeholder={"12345"} value={this.state.zip} />*/}
                        {/*                <TextInput title={"Expiration Date"} valid={this.state.expValid} warning={"Required"} id={"exp"} onChange={this.handleChangeExp} placeholder={"MM/YYYY"} value={this.state.exp} />*/}
                        {/*                <SaveSection>*/}
                        {/*                    <InlineBlock onClick={this.updateMethod}><SubmitInputWithValidation label={"Update"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>*/}
                        {/*                    <InlineBlock><div onClick={() => {return(this.showUpdate(false, ""))}}><ButtonTertiary canSubmit={false} label={"Cancel"} /></div></InlineBlock>*/}
                        {/*                </SaveSection>*/}
                        {/*            </>*/}
                        {/*        }*/}
                        {/*    </>*/}
                        {/*}*/}
                        {/* <CurrentPlanRow /> */}
                        </Right>
                        <Left>
                            <InlineButton onClick={() => {return this.props.showAdd(true)}}>
                                <ButtonPrimary submit={false} label={"Add Card"} />
                            </InlineButton>
                        </Left>
                    </SettingsCard>
                </>
            )
        }
    }

}

const mapStateToProps = state => ({
    paymentMethod: state.enterprise.billing.billing.get("paymentMethod")
});

const mapDispatchToProps = dispatch => ({
    tryGetBillingPaymentMethod: () => dispatch(tryGetBillingPaymentMethod()),
    // tryGetBillingPaymentMethodUpdate: (data) => dispatch(tryGetBillingPaymentMethodUpdate())
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingPaymentMethod);
