import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

const Navbar = styled.div`
  background-color: #394950;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 60px);
  padding: 24px 30px;
`;

export default function IFrameAdvisorDirectoryNav() {
    return (
        <Navbar>
            <Link to={"/public/advisor-directory"}>
                <img src="https://docs.trustwillow.com/logotransparentwhite.png" height="32" width="160" alt="Willow"/>
            </Link>
        </Navbar>
    );
};