import React from 'react';
import {connect} from 'react-redux';
import {v4 as uuid4} from "uuid";
import ReactQuill from "react-quill";
import {withRouter} from 'react-router-dom';

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FillContainer, HeroContainer, HeroImgBackground, One, OneContainer, SpoofContainer, Title, WhiteContainer, QuillNoPadding, SmallWhiteContainer, ActiveLink} from "../components/courses";
import {FontBody14, FontHeader18} from "../../../common/components/fonts";
import {ButtonLinkProgress, ButtonTertiaryAlert} from "../../../common/components/buttons";
import {EditOutlined} from '@ant-design/icons';

/* Middleware */
import {tryGetCoursesOne, tryUpdateCourse} from '../middleware/courses';

class CoursesOne extends React.Component {
    state={
        "course": null,
        "isLoading": true,
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "course": this.props.course.toJS(),
            "isLoading": false
        })
    };

    activateOrDeactivate = async () => {
        await this.props.tryUpdateCourse(this.state.course.id, {...this.state.course, "isActive": !this.state.course.isActive})
        this.setState({"course": {...this.state.course, "isActive": !this.state.course.isActive}})
    }

    onView = async () => {
        await this.props.tryGetCoursesOne(this.state.course.id)

        return this.props.history.push('/curriculum/edit')
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <>
                    <OneContainer>
                        <One>
                            <HeroContainer onClick={this.onView}>
                                <SpoofContainer />
                                <FillContainer>
                                    <HeroImgBackground imgObj={this.state.course.imageUrl} />
                                </FillContainer>
                            </HeroContainer>
                            <WhiteContainer onClick={this.onView}>
                                <FontHeader18><Title>{this.state.course.title}</Title></FontHeader18>
                                <FontBody14>
                                    <QuillNoPadding>
                                        <ReactQuill
                                            id={uuid4()}
                                            readOnly={true}
                                            theme={null}
                                            value={this.state.course.description}
                                            bounds={'.app'}
                                        />
                                    </QuillNoPadding>
                                </FontBody14>
                            </WhiteContainer>
                            <SmallWhiteContainer>
                                    {this.state.course.isActive ?
                                        <ActiveLink onClick={() => {this.activateOrDeactivate()}}><ButtonLinkProgress label={"Active"}/></ActiveLink>
                                        :
                                        <div onClick={() => {this.activateOrDeactivate()}}><ButtonTertiaryAlert label={"Inactive"}/></div>
                                    }
                            </SmallWhiteContainer>
                        </One>
                    </OneContainer>
                </>
            )
        }
    }

}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => ({
    tryGetCoursesOne: id => dispatch(tryGetCoursesOne(id)),
    tryUpdateCourse: (courseId, courseData) => dispatch(tryUpdateCourse(courseId, courseData))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CoursesOne));