import React from "react";
import styled from "styled-components";

/* Components */
import {colors} from "../../../common/components/colors";
import {FontBody18, FontSubtitle40} from "../../../common/components/fonts";


export const SettingsCardMissingInfo = styled.div`
    -webkit-box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.1);
    background-color: ${colors.white};
    color: ${colors.primary100};
    max-width: 300px;
    margin: 0px auto 40px auto;
    position: relative;
    padding: 40px 20px;
    width: calc(100% - 40px);
    border-radius: 20px;
`;

export const Extra54px = styled.div`
    height: 14px;
    width: 100%;
`;
export const SpaceBetweenMissingInfo = styled.div`
    display: inline-flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
    color: ${colors.secondary100};
    ${props => props.direction !== "column"} {
        flex-direction: column;
    }
    ${props => props.direction !== "row"} {

    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: flex-start;
    margin: -40px auto 0px auto;
    padding-top: 40px;
    position: relative;
    min-height: 70vh;
`;
export const PageTitle = styled(FontSubtitle40)`
    text-align: left;
    letter-spacing: 5.6px;
`;
export const Bar = styled.div`
    width: 100%;
    height: 1px;
    border-bottom: 1px solid ${colors.secondary30};
`;

export const Text = styled.div`
    margin: 10px 0px;
    
    ${props => props.active !== true} {
        overflow: revert;
        display: revert;
        -webkit-line-clamp: revert;
        -webkit-box-orient: revert;
    }
    ${props => props.active !== false} {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
`;

export const Image = styled.img`
    height: 150px;
    width: 100%;
    vertical-align: top;
    display: inline-block;
    margin: 10px 0px;
    object-fit: contain;
`;

export const Icon = styled.div`
    display: inline-block;
    vertical-align: top;
    ${props => props.active !== true} {
        color: ${colors.white};
    }
    ${props => props.active !== false} {
        color: ${colors.action100};
    }
    & svg {
        width: 16px;
        height: 16px;
        margin-top: 3px;
    }
    @media screen and (max-width: 460px) {
        display: none;
    }

`;

export const InlineBlock = styled.div`
    display: inline-block;
    margin-right: 10px;
    margin-top: 10px;
`;

export const SpaceBetween = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const TopBox = styled.div`
    cursor: pointer;
`;

export const Type = styled.div`
    padding: 25px 40px;
    width: calc(100% - 80px);
`;

export const OpenLink = styled.div`
    ${props => props.active !== true} {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
    ${props => props.active !== false} {
        cursor: default;
    }
    
`;

export const BackTop = styled.div`
    color: ${colors.secondary70};
    cursor: pointer;
    position: relative;
    z-index: 25;
    text-align: left;
    display: block;
    width: fit-content;
`;

export const LoadingCenter = styled.div`
    margin: 60px auto;
`;

export const Bullet = styled.div`
    padding: 10px;
`;

export const ContainerTop = styled.div`
    padding: 90px 60px 45px;
    background-color: ${colors.white};
    border-radius: 0px 0px 40px 40px;
    width: calc(100% - 120px);
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    z-index: 1;
    position: relative;
    @media screen and (max-width: 748px) {
        width: calc(100% - 60px);
        padding: 30px 30px 45px 30px;
    }
    @media screen and (max-width: 450px) {
        width: calc(100% - 30px);
        padding: 30px 15px 45px 15px;
    }
`

export const PageSubtitle = styled(FontBody18)`
    width: fit-content;
`

export const BackSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    margin: 25px 0px 0px 20px;
`;

export const Back = styled.div`
    align-items: center;
    align-content: center;
    cursor: pointer;
    display: flex;
    gap: 10px;
`;

export const ContainerApptNav = styled.div`
    padding: 60px 45px 20px;
    margin-top: -40px;
    background-color: ${colors.secondary70};
    border-radius: 0px 0px 40px 40px;
    width: calc(100% - 90px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    height: fit-content;
    justify-content: space-evenly;
`