import React from 'react';
import validator from 'validator';

/* Components */
import {TextInput} from "../../../common/components/inputs";
import {LogoLoading} from "../../../common/components/loading";

class CourseCreateTitle extends React.Component {
    state = {
        title: '',
        titleValid: true
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "title": this.props.title.title,
            "isLoading": false
        });
    };

    handleChangeTitle = async e => {
        await this.setState({
            'title': e.target.value,
            'titleValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''),
        });
        await this.props.onValidate(this.state);
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <TextInput
                    title={"Title*"}
                    valid={this.state.titleValid}
                    warning={"Required"}
                    id={"title"}
                    onChange={this.handleChangeTitle}
                    placeholder={"Meeting Name"}
                    value={this.state.title}
                />
            )
        }
    }
}

export default CourseCreateTitle;
