import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import styled from 'styled-components';

/* Components */
import {FontBody16, FontBody21} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";
import {Section, Body, ContactTitle, PreviewBlack} from './components';
import {images} from "../../../common/components/images";
import {iconNames} from "../../../common/components/icons";
import parse from 'html-react-parser'
const Title = styled(ContactTitle)`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: ${({ faded }) => faded ? `fadeIn 1s ease-in-out` : `fadeIn 1s ease-in-out`};
`;
const Name = styled(FontBody16)`
    font-size: 28px;
    line-height: 1.2;
    @media screen and (max-width: 768px) {
        font-size: 24px;
    }
`;
const Links = styled.div`
  display: flex;
  width:fit-content;
  height:100%;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-shrink: 3;
  flex-grow:2;
  position: relative;
  column-gap:40px;
  row-gap:0px;
  @media screen and (max-width: 1680px) {
    column-gap: 20px;
  }
  @media screen and (max-width: 980px) {
    column-gap: 40px;
  }
  @media screen and (max-width: 768px) {
    column-gap: 20px;
    flex-wrap: wrap;
  }
  align-items: center;
  justify-content: center;
`;
const ImageIconBox = styled.div`
  text-align: center;
  cursor:pointer;
`;
const ImageIcon = styled.img`
  width:100px;
  height:100px;
  cursor: pointer;
`;
const Tooltip = styled.div`
  background-color: ${colors.primary100};
  width:100%;
  position: absolute;
  bottom: -40px;
  min-height:35px;
  border-radius:10px;
  padding:0.25em 0;
  justify-content: center;
  align-content: center;
  display:flex;
  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    left: ${props => props.pos};
    right: auto;
    top: 0;
    border: 15px solid transparent;
    border-top: 0;
    border-bottom: 15px solid ${colors.primary100};
    transform: translate(-50%, calc(-100% - 0px));
  }
  ${FontBody16} {
    color:${colors.white};
    align-self: center;
    width: calc(100% - 40px);
  }
  @media screen and (max-width: 768px) {
    gap: 20px;
    flex-wrap: wrap;
    &::before {
      display: none;
    }
  }
`;
const Relative = styled(PreviewBlack)`
  position: relative;
  line-height: 2;
  margin-top: 20px;
`;
const Cursor = styled(FontBody21)`
  cursor: pointer;
  width: fit-content;
  margin: 0 0 0 auto;
  //position: absolute;
  //bottom: -32px;
  //right: 0;
`;

const Fixed = styled.div`
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${colors.black}B3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const VideoBox = styled.div`
  position: relative;
  aspect-ratio: 16 / 9;
  width: 80%;
  height: auto;
`;
const Close = styled.div`
  position: absolute;
  top: -20px;
  right: -20px;
  padding: 10px;
  font-size: 50px;
  line-height: 50px;
  width: 50px;
  @media screen and (max-width: 720px) {
    top: -15px;
    right: -15px;
    padding: 5px;
    font-size: 40px;
    line-height: 40px;
    width: 40px;
  }
  background-color: ${colors.action100};
  color: ${colors.white};
  border-radius: 100%;
  z-index: 1;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

Intro2.defaultProps = {
    name: "",
    intro: {
        description: "",
        video: null,
        animal: "",
        zodiac: "",
        birthOrder: "",
        hobby: "",
        mbti: "",
    }
}
export default function Intro2(props) {
    const greetings = ['Hello', 'Hola', 'Howdy', 'Welcome']
    const [greeting, setGreeting] = useState('Hello')
    const [faded, setFaded] = useState(false);

    const [showVideo, setShowVideo] = useState(false)
    const videoRef = useRef(null)

    const [hoverAnimal, setHoverAnimal] = useState(false)
    const [hoverHobby, setHoverHobby] = useState(false)
    const [hoverMBTI, setHoverMBTI] = useState(false)
    const [hoverZodiac, setHoverZodiac] = useState(false)
    const [hoverBirthOrder, setHoverBirthOrder] = useState(false)

    const [readMore, openReadMore] = useState(false);

    const isAnimal = (props.intro.animal !== "" && props.intro.animal !== undefined)
    const isZodiac = (props.intro.zodiac !== "" && props.intro.zodiac !== undefined)
    const isBirthOrder = (props.intro.birthOrder !== "" && props.intro.birthOrder !== undefined)
    const isHobby = (props.intro.hobby !== "" && props.intro.hobby !== undefined)
    const isMBTI = (props.intro.mbti !== "" && props.intro.mbti !== undefined)

    const animal = isAnimal ? images[iconNames.filter(icon => icon.includes(props.intro.animal))[0]] : ""
    const zodiac = isZodiac ? images[iconNames.filter(icon => icon.includes(props.intro.zodiac))[0]] : ""
    const birthOrder = isBirthOrder ? images[iconNames.filter(icon => icon.includes(props.intro.birthOrder.replace(" ", "")))[0]] : ""
    let hobby = isHobby ? images[iconNames.filter(icon => icon.includes(props.intro.hobby))[0]] : ""
    const mbti = isMBTI ? images[iconNames.filter(icon => icon.includes(props.intro.mbti))[0]] : ""

    switch (props.intro.hobby) {
        case "Who has time for hobbies ?":
            hobby = images["WhoHasTimeForHobbiesIcon"];
            break;
        case "Knitting/Sewing":
            hobby = images["KnittingSewingIcon"];
            break;
        case "Coffee/Tea":
            hobby = images["CoffeeTeaIcon"];
            break;
        case "Movies/TV":
            hobby = images["MoviesTVIcon"];
            break;
        default:
            break;
    }


    const shuffle = useCallback(() => {
        const index = Math.floor(Math.random() * greetings.length);
        setGreeting(greetings[index]);
        setFaded(false);
    }, []);

    useEffect(() => {
        const intervalID = setInterval(shuffle, 3000);
        setFaded(true);
        return () => clearInterval(intervalID);
    }, [shuffle])

    const openVideo = () => {
        setShowVideo(true);
    }
    const closeVideo = () => {
        videoRef.current.pause();
        setShowVideo(false);
    }

    return (
        <Section backgroundColor={colors.white} style={{position:"relative"}}>
            <Body>
                <Title faded={faded}>{greeting}</Title>
                <Name>I'm {props.name}!</Name>
                {(isMBTI || isHobby || isAnimal || isBirthOrder || isZodiac) &&
                    <Links>

                        {isMBTI &&
                            <ImageIconBox onMouseEnter={() => setHoverMBTI(true)} onMouseLeave={() => setHoverMBTI(false)}>
                                <ImageIcon leftOffset={0} src={mbti} alt={"MBTI"}  />
                            </ImageIconBox>
                        }
                        {isHobby &&
                            <ImageIconBox onMouseEnter={() => setHoverHobby(true)} onMouseLeave={() => setHoverHobby(false)}>
                                <ImageIcon leftOffset={0} src={hobby} alt={"Hobby"} />
                            </ImageIconBox>
                        }
                        {isAnimal &&
                            <ImageIconBox onMouseEnter={() => setHoverAnimal(true)} onMouseLeave={() => setHoverAnimal(false)}>
                                <ImageIcon leftOffset={0} src={animal} alt={"Animal"} />
                            </ImageIconBox>
                        }
                        {isBirthOrder &&
                            <ImageIconBox onMouseEnter={() => setHoverBirthOrder(true)} onMouseLeave={() => setHoverBirthOrder(false)}>
                                <ImageIcon leftOffset={0} src={birthOrder} alt={"Birth Order"} />
                            </ImageIconBox>
                        }
                        {isZodiac &&
                            <ImageIconBox onMouseEnter={() => setHoverZodiac(true)} onMouseLeave={() => setHoverZodiac(false)}>
                                <ImageIcon leftOffset={0} src={zodiac} alt={"Zodiac"} />
                            </ImageIconBox>
                        }

                        {hoverMBTI &&
                            <Tooltip pos={zodiac !== "" ? '7%' : '12%'}>
                                <FontBody16>Myers-Briggs Personality Trait: {props.intro.mbti}</FontBody16>
                            </Tooltip>
                        }
                        {hoverHobby &&
                            <Tooltip pos={zodiac !== "" ? '29%' : '37%'}>
                                <FontBody16>Favorite Hobby: {props.intro.hobby}</FontBody16>
                            </Tooltip>
                        }
                        {hoverAnimal &&
                            <Tooltip pos={zodiac !== "" ? '50%' : '63%'}>
                                <FontBody16>Favorite Animal: {props.intro.animal}</FontBody16>
                            </Tooltip>
                        }
                        {hoverBirthOrder &&
                            <Tooltip pos={zodiac !== "" ? '71%' : '87%'}>
                                <FontBody16>Birth Order: {props.intro.birthOrder}</FontBody16>
                            </Tooltip>
                        }
                        {hoverZodiac &&
                            <Tooltip pos={'92%'}>
                                <FontBody16>Zodiac: {props.intro.zodiac}</FontBody16>
                            </Tooltip>
                        }

                    </Links>
                }
                {/* .match(/[^\n]+(?:\r?\n|$)/g) - splits lines by new lines, but also preserves the newlines */}

                {(props.intro.description.length < 280 || readMore)
                    ? <Relative color={colors.primary100}>
                        {/*{<span style={{"whiteSpace": "pre-wrap"}}>*/}
                            {/*{props.intro.description.match(/[^\n]+(?:\r?\n|$)/g).join('')}</span>}&nbsp;&nbsp;*/}
                        {parse(props.intro.description)}
                        {readMore && <Cursor onClick={() => openReadMore(false)}> <span style={{textDecoration: "underline"}}>Show less</span></Cursor>}
                    </Relative>
                    : <Relative color={colors.primary100}>
                        {/*{<span style={{"whiteSpace": "normal"}}>{props.intro.description.substring(0, 280).match(/[^\n]+(?:\r?\n|$)/g).join('')}</span>}. . .&nbsp;&nbsp;*/}
                        {parse(props.intro.description.substring(0, 280))}
                        {!readMore && <Cursor onClick={() => openReadMore(true)}> <span style={{textDecoration: "underline"}}>Read more</span></Cursor>}
                    </Relative>
                }
                {(props.intro.video !== null && props.intro.video !== "" && props.intro.video !== undefined) &&
                   <ImageIcon
                       onClick={openVideo} leftOffset={3}
                       src={images.videoIcon} alt={"video icon"}/>
                }
                {showVideo &&
                    <Fixed onClick={closeVideo}>
                        <VideoBox onClick={closeVideo}>
                            <Close onClick={closeVideo}>&times;</Close>
                            <video ref={videoRef} src={props.intro.video} width={"100%"} height={"auto"} controls={true} />
                        </VideoBox>
                    </Fixed>
                }
            </Body>
        </Section>
    )
}
