import React from 'react';
import {withRouter} from 'react-router-dom';

/* Containers */
import AllSharedVaultList from '../../common/containers/vault/containers/allsharedvaultlist';
import VaultCreate from '../../common/containers/vault/containers/vaultcreate';
import AllSharedVault from "../../common/containers/vault/containers/allsharedvault";

/* Navs */
import {PageContent, PageFull, PageRight} from "../navbars/components/navlayout";
import NavPrimaryAll from "../navbars/containers/primaryall";
import NavSecondaryTop from '../navbars/containers/secondarytop';

/* Components */
import {PlusOutlined} from "@ant-design/icons";

const vaultButtons = [
    {
        "pathname": "/vaults/create",
        "label": <><PlusOutlined /> Create Drive</>,
        "buttonType": "primary"
    }
];

const Vault = () => (
    <>
        <NavPrimaryAll />
        <PageFull>
            <PageRight>

                    {location.pathname === "/vaults" &&
                        <>
                            <NavSecondaryTop title={"Vaults"} buttons={vaultButtons} desc={"Store and share your documents securely"}/>
                            <PageContent>
                                <AllSharedVault />
                            </PageContent>
                        </>
                    }

                    {location.pathname === "/vaults/drive" &&
                        <PageContent>
                            <AllSharedVaultList />
                        </PageContent>
                    }

                    {location.pathname === "/vaults/create" &&
                        <>
                            <NavSecondaryTop title={"Create Drive"} />
                            <PageContent>
                                <VaultCreate />
                            </PageContent>
                        </>
                    }

            </PageRight>
        </PageFull>
    </>
);

export default withRouter(Vault);