import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';

/* Components */
import {FontBody16, FontTitle21} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";
import {ButtonInactive, ButtonPrimary} from "../../../common/components/buttons";

/* Middleware */
import {tryPostFeedback} from '../middleware/feedback';


export const Body = styled.div`
    margin: 20px 0px; 
`;
export const ButtonWide = styled.div`
    width: 100%;
    margin-top: 10px;
    & > button {
        width: 100%;
    }
    & > button > div {
        width: calc(100% - 47px);
    }
`;
export const ButtonWideInactive = styled.div`
    width: 100%;
    margin-top: 10px;
    & > div {
        width: calc(100% - 47px);
        text-align: center;
    }
`;
const TextArea = styled.textarea`
    background-color: ${colors.border70};
    margin: 25px 0px 20px 0px;
    border-radius: 4px;
    padding: 15px 20px;
    resize: none;
    font-family: GraphicWebRegular;
    font-size: 16px;
    line-height: 22px;
    min-height: 110px;
    width: calc(100% - 42px);
    border: 1px solid ${colors.primary30};
    @media screen and (max-width: 460px) {
        padding: 10px 10px;
    }
    &:focus {
        outline: 0;
    }
`;
const Section = styled.div`
    padding: 10px 20px;
    overflow-y: auto;
    height: calc(100% - 80px);
`;

class FeedbackModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {submitted: false, message: ''}
    }

    handleChange = e => this.setState({message: e.target.value});
    handleSubmit = e => {
        e.preventDefault();
        if(this.state.message !== '') {
            this.props.tryPostFeedback(this.state.message);
            this.setState({submitted: true, message: ''});
        }
    };

    render() {
        return(
            <Section>
                <br />
                {this.state.submitted
                    ?
                    <>
                        <FontTitle21>Thank you!</FontTitle21>
                        <Body><FontBody16>Feedback is so important to us. Thanks for taking the time to share your thoughts and suggestions!</FontBody16></Body>
                    </>
                    :
                    <>
                        <FontTitle21>Have feedback?</FontTitle21>
                        <form method={"post"} onSubmit={this.handleSubmit}>
                            <TextArea
                                value={this.state.message}
                                onChange={this.handleChange}
                                placeholder={"We love feedback! Tell us what you like or what we can do better."}
                            />
                            {this.state.message !== ""
                                ? <ButtonWide><ButtonPrimary canSubmit={true} label={"Send"} /></ButtonWide>
                                : <ButtonWideInactive><ButtonInactive canSubmit={false} label={"Send"} /></ButtonWideInactive>
                            }
                        </form>
                    </>
                }
            </Section>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    tryPostFeedback: (message) => dispatch(tryPostFeedback(message))
});

export default connect(null, mapDispatchToProps)(FeedbackModal);
