import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setMeetingsExpertProfile, setMeetingsExpertProfileGet} from "../store/expertprofile";

/* Static */
import {devServicesExpertProfileData} from "../static/devdata";

export const tryGetExpertProfile = () => {
    return async dispatch => {
        dispatch(setMeetingsExpertProfileGet(true));
        const profile = await axiosReq('apiv1/coach/settings/profile', "GET", {}, devServicesExpertProfileData);
        dispatch(setMeetingsExpertProfile(profile));
        return dispatch(setMeetingsExpertProfileGet(false));
    }
};
