import React from 'react';
import images from '../styles/images';

const consumerNav = {
    logo: "/home",
    nav: [
        [
            {
                href: "/home",
                label: "Home",
                navSideIconActive: images.consumerHomeIcon,
                navSideIconInactive: images.consumerHomeIcon,
                showDesktop: true,
                showMobile: true,
                navBottomIconInactive: images.navHomeInactive,
                navBottomIconActive: images.navHomeActive,
            }
        ],
        [
            {
                href: "/my-team",
                label: "Coaching",
                navSideIconActive: images.navTeamIconActive,
                navSideIconInactive: images.navTeamIconInactive,
                showDesktop: true,
                showMobile: true,
                navBottomIconInactive: images.navTeamInactive,
                navBottomIconActive: images.navTeamActive,
            },
            {
                href: "/learn",
                label: "Learning Center",
                navSideIconActive: images.navSelfServeIconActive,
                navSideIconInactive: images.navSelfServeIconInactive,
                showDesktop: false,
                showMobile: false,
                navBottomIconInactive: images.navLearnInactive,
                navBottomIconActive: images.navLearnActive,
            },
            {
                href: "/community",
                label: "Community",
                navSideIconActive: images.navCommunityIconActive,
                navSideIconInactive: images.navCommunityIconInactive,
                showDesktop: false,
                showMobile: false,
                navBottomIconInactive: images.navProgramsInactive,
                navBottomIconActive: images.navProgramsActive,
            },
            {
                href: "/programs",
                label: "Programs",
                navSideIconActive: images.navProgramsIconActive,
                navSideIconInactive: images.navProgramsIconInactive,
                showDesktop: false,
                showMobile: false,
                navBottomIconInactive: images.navProgramsInactive,
                navBottomIconActive: images.navProgramsActive,
            },
        ],
        [
            {
                href: "/progress",
                label: "Progress",
                navSideIconActive: images.navProgressIconActive,
                navSideIconInactive: images.navProgressIconInactive,
                showDesktop: true,
                showMobile: false,
                navBottomIconInactive: images.navProgressInactive,
                navBottomIconActive: images.navProgressActive,
            },
            // {
            //     href: "/my-financial-profile",
            //     label: "My Finances",
            //     navSideIconActive: images.navFinanceIconActive,
            //     navSideIconInactive: images.navFinanceIconInactive,
            //     showDesktop: true,
            //     showMobile: false,
            //     navBottomIconInactive: images.navFinanceIconInactive,
            //     navBottomIconActive: images.navFinanceIconActive,
            // },
        ],
        [
            {
                href: "/menu",
                label: "Menu",
                navSideIconActive: images.navMoreInactive,
                navSideIconInactive: images.navMoreInactive,
                showDesktop: false,
                showMobile: true,
                navBottomIconInactive: images.navMoreInactive,
                navBottomIconActive: images.navMoreInactive,
            },
            {
                href: "/settings",
                label: "Settings",
                navSideIconActive: images.navSettingsIconActive,
                navSideIconInactive: images.navSettingsIconInactive,
                showDesktop: false,
                showMobile: false,
                navBottomIconInactive: images.navSettingsIconInactive,
                navBottomIconActive: images.navSettingsIconActive,
            },
            {
                href: "modalSupport",
                label: "Support",
                navSideIconActive: images.navHelpIconActive,
                navSideIconInactive: images.navHelpIconInactive,
                showDesktop: false,
                showMobile: false,
                navBottomIconInactive: images.navHelpIconActive,
                navBottomIconActive: images.navSettingsIconActive,

            },
        ]
    ]
};

export default consumerNav;
