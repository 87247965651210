import React from "react";
import styled from "styled-components";

export const PageTabs = styled.div`
    position: relative;
    width: 100%;
    display: inline-block;
    vertical-align: top;
`;

export const PageActions = styled.div`
    position: absolute;
    width: 280px;
    left: calc(100% + 60px);
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 768px) {
        position: relative;
        left: 0px;
        top: 0px;
        display: block;
        width: 100%;
        margin-left: 0px;
    }
`;

export const PageContent = styled.div`
    width: 100%;
    @media screen and (max-width: 748px) {
        height: calc(100% - 165px);
    }
`;
