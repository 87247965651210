import React from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import moment from "moment";

/* Components */
import {FontHeader16} from '../../common/components/fonts';
import {colors} from '../../common/components/colors';
import {ButtonPrimary} from "../../common/components/buttons";
import {LogoLoading, LogoLoadingSmall} from "../../common/components/loading";
import {Message} from "../../common/components/messages";
import {PlusOutlined} from "@ant-design/icons";

/* Containers */
import TeamNote from './contactactivityteamnote';
import TeamForm from './contactactivityteamform';

/* Middleware */
import {tryGetEnterpriseSharedNotes} from "../notes/middleware/notes";
import {tryGetEnterpriseClientActivity} from "../middleware/clientsactivity";

/* Store */
import {setNoteModal, setNoteSelected} from '../notes/store/notes';

/* Components */
const Section = styled.div`
    padding: 20px;
    background-color: ${colors.border30};
    max-height: 100%;
    @media screen and (max-width: 460px) {
        padding: 5px;
    }
`;
const Empty = styled.div`
    margin: 30px 30px 20px 30px;
    color: ${colors.primary70};
    text-align: center;
`;
const ButtonCentered = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    width: fit-content;
`;

class ContactNotes extends React.Component {
    state={
        "isLoading": true,
        "prospectStatus": null,
        "notes": [],
        "survey": null
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.prospectStatus !== this.props.prospectStatus || prevProps.clientCoaches !== this.props.clientCoaches || prevProps.prospectCoaches !== this.props.prospectCoaches) {
            return this.init();
        }
        if(prevProps.lastInitiated !== this.props.lastInitiated) {
            return this.init();
        }
    }

    init = async () => {
        if(this.props.prospectStatus !== "Contact") {
            if (this.props.prospectStatus === "Client") {
                if(Object.keys(this.props.clientCoaches.toJS()).length > 0) {
                    if(this.props.clientCoaches.get("survey") !== null && this.props.clientCoaches.get("survey") !== undefined) {
                        console.log(this.props.clientCoaches.get("survey"))
                        console.log(this.props.clientCoaches.get("survey").toJS())
                        console.log(JSON.stringify(this.props.clientCoaches.get("survey")))
                        console.log(JSON.stringify(this.props.clientCoaches.get("survey").toJS()))
                        this.setState({
                            "survey": {
                                "content": JSON.stringify(this.props.clientCoaches.get("survey").toJS()),
                                "subject": "Background Information",
                                "datetime": null
                            }
                        })
                    }
                }
            } else {
                if(Object.keys(this.props.prospectCoaches.toJS()).length > 0) {
                    if(this.props.prospectCoaches.get("survey") !== null && this.props.prospectCoaches.get("survey") !== undefined) {
                        console.log(this.props.prospectCoaches.get("survey"))
                        console.log(this.props.prospectCoaches.get("survey").toJS())
                        console.log(JSON.stringify(this.props.prospectCoaches.get("survey")))
                        console.log(JSON.stringify(this.props.prospectCoaches.get("survey").toJS()))
                        this.setState({
                            "survey": {
                                "content": JSON.stringify(this.props.prospectCoaches.get("survey").toJS()),
                                "subject": "Background Information",
                                "datetime": null
                            }
                        })
                    }
                }
            }
            await this.props.tryGetEnterpriseSharedNotes(this.props.consumerId);
            this.update();
        } else {
            this.setState({"prospectStatus": this.props.prospectStatus, "isLoading": false});
        }
    };

    update = async () => {
        const datetime = moment().endOf("month").subtract(new Date().getTimezoneOffset(), "minutes").format("YYYY-MM-DD");
        const forms = await this.props.tryGetEnterpriseClientActivity(datetime, "form");
        const formsAdj = await Promise.all(forms.map(f => {
            return Promise.resolve({...f, "datetime": f.formattedDatetime})
        }));
        const merged = [...formsAdj, ...this.props.teamNotes.toJS()];
        console.log(merged);
        const sorted = merged.sort((a, b) => {return(new Date(b.datetime) - new Date(a.datetime))});
        console.log(sorted);
        this.setState({"notes": sorted, "isLoading": false});
    };

    createNote = async () => {
        if(this.props.prospectStatus !== "Contact") {
            const note = {
                "edit": false,
                "clientId": this.props.client.get("consumerId"),
                "noteId": null,
                "name": this.props.client.get("firstName") + " " + this.props.client.get("lastName"),
                "title": '',
                "body": '',
                "datetime": ''
            };
            this.props.setNoteSelected(note, false);
            this.props.setNoteModal(true);
            // Open note modal that stays open on whole site, ask if ok to close if trying to open another
        }
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            if(this.state.prospectStatus === "Contact") {
                return(<Section><Message text={"Change relationship to prospect or client to write notes."} /></Section>)
            } else {
                return(
                    <Section>
                        {this.state.notes.length === 0 ?
                            <>
                                {this.props.isWMC
                                    ?
                                        <>
                                            {this.props.isAdvisorHasCoach
                                                ?
                                                    <>
                                                        <Empty><FontHeader16>Your team has not written any {/*shared notes*/} notes about {this.props.client.get("firstName")} {this.props.client.get("lastName")}</FontHeader16></Empty>
                                                        <br/>
                                                    </>
                                                :
                                                    <>
                                                        <Empty><FontHeader16>Assign team members to work with {this.props.client.get("firstName")} {this.props.client.get("lastName")} to see notes{/*shared notes*/}</FontHeader16></Empty>
                                                        <br/>
                                                    </>
                                            }
                                        </>
                                    :
                                        <>
                                            <Empty><FontHeader16>Create the first note about {this.props.client.get("firstName")}</FontHeader16></Empty>
                                            <ButtonCentered onClick={this.createNote}><ButtonPrimary label={<><PlusOutlined /> Create Note</>} canSubmit={true}/></ButtonCentered>
                                            <br/><br/><br/>
                                        </>
                                }
                            </>
                        :
                            <>
                                {(this.state.notes.map((a, aIndex) => (
                                    <LazyLoad offset={200} key={aIndex} placeholder={<LogoLoadingSmall/>}>
                                        <div>
                                            {a.hasOwnProperty("channel")
                                                ? <TeamForm data={a}/>
                                                : <TeamNote data={a}/>
                                            }
                                        </div>
                                    </LazyLoad>
                                )))}
                            </>
                        }
                        {(this.state.survey !== null && !this.props.isWMC) &&
                            <TeamForm data={this.state.survey}/>
                        }
                    </Section>
                )
            }
        }
    }
}

const mapStateToProps = state => ({
    isAdvisorHasCoach: state.enterprise.clientsDetail.get("client").get("isAdvisorHasCoach"),
    prospectStatus: state.enterprise.clientsDetail.get("client").get("prospectStatus"),
    consumerId: state.enterprise.clientsDetail.get("client").get("consumerId"),
    client: state.enterprise.clientsDetail.get("client"),
    teamNotes: state.enterprise.clientsActivity.get("teamNotes"),
    isWMC: state.common.wmc.get("isWMC"),
    clientCoaches: state.enterprise.clientsList.get("clientsOne"),
    prospectCoaches: state.enterprise.prospects.get("prospectsOne"),
    lastInitiated: state.enterprise.notes.notes.get("lastInitiated")
});

const mapDispatchToProps = dispatch => ({
    tryGetEnterpriseClientActivity: (date, activityType) => dispatch(tryGetEnterpriseClientActivity(date, activityType)),
    tryGetEnterpriseSharedNotes: (consumerId) => dispatch(tryGetEnterpriseSharedNotes(consumerId)),
    setNoteSelected: (note, edit) => dispatch(setNoteSelected(note, edit)),
    setNoteModal: (status) => dispatch(setNoteModal(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactNotes));