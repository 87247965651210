
// ---------------------------------------------------------------------------------------------------------------------
// PRODUCTS - COACH (START)
// ---------------------------------------------------------------------------------------------------------------------

const countOccurrences = (arr, val) => {
    return arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
}

const remainingSessionsByPurchaseHistoryId = async (sessions) => {
    let filtered = await sessions.filter(s => {return(!s.isCanceled && !s.isMissed)})
    let purchaseHistoryIds = await Promise.all(filtered.map(p => {return(p.purchaseHistoryId)}));
    let uniques = await purchaseHistoryIds.filter((value, index, self) => {return self.indexOf(value) === index;});
    let sessionsById = await Promise.all(uniques.map(u => {return Promise.resolve([u, countOccurrences(purchaseHistoryIds, u)])}));
    return Object.fromEntries(sessionsById);
};

export const checkRemainingSessionsProductsCoach = async (pastCalls, upcomingCalls, productsCoach, coachId) => {
    let sessionsComplete = await remainingSessionsByPurchaseHistoryId(pastCalls);
    let sessionsUpcoming = await remainingSessionsByPurchaseHistoryId(upcomingCalls);
    let result = await Promise.all(productsCoach.map(p => {
        const sessionsCompleteByProduct = sessionsComplete[p.purchaseHistoryId] === undefined ? 0 : sessionsComplete[p.purchaseHistoryId];
        const sessionsUpcomingByProduct = sessionsUpcoming[p.purchaseHistoryId] === undefined ? 0 : sessionsUpcoming[p.purchaseHistoryId];
        if(p.serviceType === "online") {
            return({
                ...p,
                "sessionsCompleted": sessionsCompleteByProduct,
                "sessionsUpcoming": sessionsUpcomingByProduct,
                "sessionsRemaining": p.numberOfSessions - sessionsCompleteByProduct,
                "sessionsSchedulable": p.numberOfSessions - sessionsCompleteByProduct - sessionsUpcomingByProduct,
                "isWillowSession": false
            })
        } else {
            return(p)
        }
    }));
    return result.filter(p => {return(coachId === p.coachId)});
};

// ---------------------------------------------------------------------------------------------------------------------
// PRODUCTS - COACH (END)
// ---------------------------------------------------------------------------------------------------------------------
