import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';

/* Components */
import {SavedSettings} from '../../../../common/components/cards';
import {FontBody16, FontTitle18} from '../../../../common/components/fonts';
import {ButtonSecondary, ButtonTertiary} from "../../../../common/components/buttons";
import {ReadOnlyInput, TextareaInputNoCount} from "../../../../common/components/inputs";
import {LogoLoading} from "../../../../common/components/loading";
import {FitContent, InlineBlock, SaveSection, SubmitInputWithValidation} from "../../components/myprofile";

/* Middleware */
import {tryUpdateExpertProfile} from '../../middleware/expertprofile';
import validator from "validator";
import {EditIcon} from "../../components/pagescontact";
import {EditOutlined} from "@ant-design/icons";

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

class ProfileHighlights extends React.Component {
    state = {
        highlights: '', highlightsValid: true, highlightsPrevious: "",
        isSaving: false,
        isSaved: false,
        error: '',
        isValid: true,
        isChanged: false,
        isLoading: true,
        isEdit: false
    };

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    init = async () => {
        const highlights = this.props.profile.get("highlights");
        await this.setState({
            "highlights": (highlights === null || highlights === undefined) ? "" : highlights,
            "highlightsPrevious": (highlights === null || highlights === undefined) ? "" : highlights,
            "isLoading": false,
            "isChanged": false
        });
    };

    edit = async (status) => {
        this.setState({"isEdit": status});
    };

    reset = async () => {
        await this.setState({
            "highlights": this.state.highlightsPrevious,
            "isChanged": false,
            "isEdit": false
        });
    };

    handleChangeHighlights = async e => {
        await this.setState({'highlights': e.target.value, 'highlightsValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''), 'isChanged': true});
        // this.checkValid();
    };

    checkValid = async () => {
        // if(this.state.highlightsValid) {
            this.setState({'isValid': true})
        // } else {
        //     this.setState({'isValid': false})
        // }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            await this.setState({'isSaving': true, "error": ""});
            const highlights = {
                // "bio": this.props.profile.get("bio"),
                highlights: this.state.highlights
            };
            await this.props.tryUpdateExpertProfile(highlights, "errorHighlights");
            if(this.props.profile.get("errorHighlights") === "") {
                await this.setState({
                    'isSaving': false,
                    "isSaved": true,
                    "highlightsPrevious": this.state.highlights,
                    "isEdit": false
                });
                this.timeout = setTimeout(() => {this.setState({'isSaved': false, "isChanged": false})}, 3000);
            } else {
                this.setState({'isSaved': true, 'isSaving': false, "error": this.props.profile.get("errorHighlights")});
                this.timeout = setTimeout(() => {this.setState({'isSaved': false, "error": ""})}, 3000);
            }
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    {this.state.isSaved
                        ?
                            <>
                                {this.state.error === '' && <SavedSettings height={"99px"} error={false} message={"Saved"}/>}
                                {this.state.error !== '' && <SavedSettings height={"99px"} error={true} message={this.state.error}/>}
                            </>
                        :
                            <>
                                {!this.state.isEdit &&
                                    <>
                                        <FontTitle18>Introduction</FontTitle18>
                                        <ReadOnlyInput
                                            title={"Brief Introduction"}
                                            value={this.state.highlights}
                                        />
                                        <br/>
                                        <FitContent><FontBody16 onClick={() => {return this.edit(true)}}><ButtonSecondary canSubmit={false} label={<><EditOutlined/>&nbsp;Change Introduction</>} /></FontBody16></FitContent>
                                    </>
                                }
                                {this.state.isEdit &&
                                    <form method={"post"} onSubmit={this.handleSubmit}>
                                        <div>
                                            <FontTitle18>Introduction</FontTitle18>
                                            <TextareaInputNoCount
                                                title={"Brief Introduction"}
                                                id={"highlights"}
                                                onChange={this.handleChangeHighlights}
                                                placeholder={""}
                                                value={this.state.highlights}
                                                minLength={0}
                                                minRows={2}
                                                valid={null}
                                                warning={null}
                                            />
                                        </div>
                                        <SaveSection>
                                            <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                            <InlineBlock><div onClick={this.reset}><ButtonTertiary canSubmit={false} label={"Cancel"} /></div></InlineBlock>
                                        </SaveSection>
                                    </form>
                                }
                            </>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    profile: state.enterprise.profile.expertProfile.get("expertProfile"),
});

const mapDispatchToProps = dispatch => ({
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileHighlights);
