import React from 'react';
import styled from 'styled-components';
import { FontBody16, FontHeader24, FontTitle24 } from '../../../../common/components/fonts';
import { colors } from '../../../../common/components/colors';

const PopupOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PopupContent = styled.div`
    background: white;
    padding: 0px 30px;
    border-radius: 20px;
    width: 40%;
    height: 80%;
    overflow-y: auto; 
    margin-left: 300px;
    @media screen and (max-width: 1089px) {
        width: 100%;
        height: 100%;
        margin-left: 0;
        border-radius: 0;
    }
`;
const Message = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px;
`;

const AiMsg = styled.div`
    background-color: ${colors.action100};
    color: white;
    padding: 10px;
    border-radius: 10px;
    max-width: 70%;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: left;
`;

const UserMsg = styled.div`
    background-color: ${colors.backgroundColor3};
    color: white;
    padding: 10px;
    border-radius: 10px;
    max-width: 70%;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: inherit;
    background-color: white;
    padding: 20px 0px;
    @media screen and (max-width: 1089px) {
       padding: 15px 0px;
    }
`;

const MessageDiv = styled.div`
    align-content: center;
    padding-top: 85px;
`;

const Button = styled.div`
    align-content: center;
    cursor: pointer;
    @media screen and (max-width: 1089px) {
       padding: 0px 50px 0px 0px;
    }
`;

const HeaderTitle = styled.div`
    text-align: left;
`;

const Popup = ({ conversation, onClose, name }) => (
    <PopupOverlay>
        <PopupContent>
            <Header>
                <HeaderTitle>
                    <FontTitle24>Conversation with {name}</FontTitle24>
                </HeaderTitle>
                <Button onClick={onClose}>
                    <FontHeader24>X</FontHeader24>
                </Button>
            </Header>
            <MessageDiv>
                {conversation?.conversation?.length > 0 ?
                    <>
                        {conversation.conversation.map((item, index) => (
                            <Message key={index}>
                                {item.aiMsg === "" ?
                                    <></>
                                    : <AiMsg><FontBody16>{item.aiMsg}</FontBody16></AiMsg>}
                                {item.userMsg === "" ?
                                    <></>
                                    : <UserMsg><FontBody16>{item.userMsg}</FontBody16></UserMsg>
                                }
                            </Message>
                        ))}
                    </>
                    : <>
                        <FontBody16>No Conversation Found</FontBody16>
                    </>
                }
            </MessageDiv>
        </PopupContent>
    </PopupOverlay>
);

export default Popup;