import images from '../styles/images';

const expertResources = [
    {
        "id": 2146454,
        "title": "Partner: Willow Research",
        "showWMCPartners": null,
        "faqs": [
            {
                "id": 41424537,
                "title": "Coaching Guide for Clients",
                "body": "<div>Willow's coach's guide to the Willow Research program. <a data-link-type='external' data-label='click here' target='_blank' href='http://docs.trustwillow.com/Research-Project-Coaching-Guide.pdf'>click here</a>.</div>"
            },
            {
                "id": 4142457,
                "title": "Roadmap To Financial Wellness",
                "body": "<div>Willow's client roadmap for Willow's Research program. <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Willow-Roadmap-to-Financial-Wellness-Part1.pdf'>click here</a>.</div>"
            },
        ]
    },
    {
        "id": 123,
        "title": "Partner: Her Traditions",
        "showWMCPartners": [25],
        "faqs": [
            {
                "id": 1654555,
                "title": "Coaching Guide for Clients",
                "body": "<div>Willow's coach's guide to the York Traditions Financial Life Coaching Pilot. <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Coaching-Guide_York.pdf'>click here</a>.</div>"
            },
            {
                "id": 16545545,
                "title": "Roadmap To Financial Wellness",
                "body": "<div>Willow's client roadmap for the York Traditions Financial Life Coaching Pilot. <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Willow-Roadmap-to-Financial-Wellness-Part1.pdf'>click here</a>.</div>"
            },
            {
                "id": 165455457,
                "title": "Traditions Bank - Make Your Mark",
                "body": "<div>Video with background and context about Traditions Bank and Her Traditions. <a data-link-type='external' data-label='click here' target='_blank' href='https://vimeo.com/531303667'>click here</a>.</div>"
            },
            {
                "id": 165455453,
                "title": "Her Traditions FPI Tool",
                "body": "<div>Screenshots of the process to complete the Financial Personality Indicator. <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Her-Traditions-FPI-screenshots.pdf'>click here</a>.</div>"
            },
            {
                "id": 16545,
                "title": "FPI: Freedom Finders",
                "body": "<div>Overview and explanation of this Financial Personality Indicator. <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/FreedomFinder.pdf'>click here</a>.</div>"
            },
            {
                "id": 165425,
                "title": "FPI: Opportunity Seekers",
                "body": "<div>Overview and explanation of this Financial Personality Indicator. <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/OpportunitySeeker.pdf'>click here</a>.</div>"
            },
            {
                "id": 165453,
                "title": "FPI: Relationship Protectors",
                "body": "<div>Overview and explanation of this Financial Personality Indicator. <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/RelationshipProtector.pdf'>click here</a>.</div>"
            },
            {
                "id": 165454,
                "title": "FPI: Security Keepers",
                "body": "<div>Overview and explanation of this Financial Personality Indicator. <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/SecurityKeeper.pdf'>click here</a>.</div>"
            },
            {
                "id": 165455,
                "title": "FPI: Blended",
                "body": "<div>Overview and explanation of this Financial Personality Indicator. <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Blended.pdf'>click here</a>.</div>"
            },
        ]
    },
    {
        "id": 38129,
        "title": "Technology Tools",
        "showWMCPartners": null,
        "faqs": [
            {
                "id": 100,
                "title": "Client Account Demo",
                "body": "<div>Watch this video to take a look into the client dashboard. <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Client-Account-Demo.mp4'>click here</a>.</div>"
            },
            {
                "id": 101,
                "title": "Willow Connect Technology Guide",
                "body": "<div>A comprehensive how-to guide to the Willow technology platform. <a data-link-type='external' data-label='click here' target='_blank' href='http://docs.trustwillow.com/Willow-Connect-Technology-Guide.pdf'>click here</a>.</div>"
            },
        ]
    },
    {
        "id": 143,
        "title": "Client Tools",
        "showWMCPartners": null,
        "faqs": [
            {
                "id": 23543,
                "title": "NEW: 7 Coaching Questions",
                "body": "<div>Taken from ”The Coaching Habit: Say Less, Ask More & Change the Way You Lead Forever” by Michael Bungay Stanier, these questions are a great way to get started and familiarize yourself with a client. <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/7+Coaching+Questions.pdf'>click here</a>.</div>"
            },
            {
                "id": 1,
                "title": "Commitment Statement",
                "body": "<div>Help clients make a promise to invest in themselves and commit to their financial wellness! Use the template within your Email tab to personalize and send to prospects.  <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/editable_coach_commitment_statement__1_.pdf'>click here</a>.</div>"
            },
            {
                "id": 2,
                "title": "SWOT Analysis",
                "body": "<div>Use this helpful tool to identify your client's financial strengths, weaknesses, opportunities and threats. All clients should walk away from their engagement with a Willow Financial Coach knowing the status of their financial picture.  <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/editable_swot__1_.pdf'>click here</a>.</div>"
            },
            {
                "id": 3,
                "title": "My Accountability Plan",
                "body": "<div>These simple steps will help keep clients on track with the plans you collaborate to make. <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/editable_accountability_plan__1_.pdf'>click here</a>.</div>"
            },
            {
                "id": 5,
                "title": "Cash Cushion Checklist",
                "body": "<div>All clients should have cash cushion emergency funds. Use this tool in your conversations to help clients put their cash reserves plan into action.   <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Editable-Cash-Cushion-Checklist.pdf'>click here</a>.</div>"
            },
            {
                "id": 6,
                "title": "Risk Management Framework for Women",
                "body": "<div>Use this framework to examine how clients are protecting themselves, their family and their wealth. Protection is a critical conversation all coaches should have with clients. <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Risk-Management-Framework-for-Women.pdf'>click here</a>.</div>"
            },
            {
                "id": 7,
                "title": "The Credit Inventory Worksheet",
                "body": "<div>All clients should understand their credit history, how to request credit reports and what makes up their credit score. Show clients where their credit stands using this straightforward guide. <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Editable-Credit-Inventory-Worksheet.pdf'>click here</a>.</div>"
            },
            {
                "id": 8,
                "title": "My Financial Wellness Roadmap",
                "body": "<div>Document the action steps you and your client determine here! <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Willow-Roadmap-to-Financial-Wellness-Part1.pdf'>click here</a>.</div>"
            },
            {
                "id": 9,
                "title": "Subscriptions Inventory",
                "body": "<div>Subscriptions make spending money easy. Help clients understand how much they're paying and to take action if their subscription spending is not aligned to what's important in their lives. <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Willow-subscriptions-worksheet.pdf'>click here</a>.</div>"
            },
            {
                "id": 31,
                "title": "Get STILL workbook",
                "body": "<div>When clients and prospects feel overwhelmed and don't know where to start, share this workbook to help them get unstuck. May be attached to a follow-up email. <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Get-STILL.pdf'>click here</a>.</div>"
            },
            {
                "id": 35,
                "title": "Divorcing Woman's Checklist",
                "body": "<div>For clients considering or beginning the divorce process. <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Willow_Divorce_Checklist.pdf'>click here</a>.</div>"
            },
            {
                "id": 36,
                "title": "Holiday Spending Checklist",
                "body": "<div>From gifts and decorations to food and celebrations, the holidays can be an expensive time of year. Share these 5 tips with clients to keep holiday spending in check! <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Willow+Holiday+Spending+Checklist.pdf'>click here</a>.</div>"
            },
        ]
    },
    {
        "id": 2,
        "title": "Marketing",
        "showWMCPartners": null,
        "faqs": [
            {
                "id": 14,
                "title": "Willow Financial Coach Logo",
                "body": "<div>Here is a logo that you can add to your LinkedIn and Website! <a data-link-type='external' data-label='click here' target='_blank' href='"+images.coachResourcesWillowLogo+"'>click here</a>.</div>"
            },
            {
                "id": 22,
                "title": "Asking for a friend... Video Series",
                "body": "<div>Become a featured speaker on Willow&#39;s social media by answering questions and demonstrating your expertise. <ul><li><a data-link-type='external' data-label='View guidelines' target='_blank' href='https://docs.trustwillow.com/Asking-for-a-friend-guidelines.pdf'>View guidelines</a></li><br><li><a data-link-type='external' data-label='View video demo' target='_blank' href='https://docs.trustwillow.com/Asking-friend-demo.mp4'>View video demo</a></li></ul>.</div>"
            },
            {
                "id": 25,
                "title": "How to post on Social Media",
                "body": "<div><p><a data-link-type='external' data-label='View Instructions' target='_blank' href='https://docs.trustwillow.com/how-to-promote-on-Social-Media.pdf'>View Instructions</a></p></div>"
            },
            {
                "id": 23,
                "title": "July 23 Webinar",
                "body": "<div><p>Our first webinar received great feedback! Thank you for the participation and support. We&#39;re looking forward to many more.  Attendees of <strong>The 2020 Midpoint: Financial Wellness &amp; Your Path Forward&nbsp;</strong>learned a 4-step process to look back on the first half of the year, heard practical strategies from Willow Financial Coaches and discovered powerful ways to renew their commitment for the final six months of 2020. </p><ul><li><a data-link-type='external' data-label='View Webinar Workbook' target='_blank' href='https://docs.trustwillow.com/Willow-Midyear-Webinar-Workbook.pdf'>View Webinar Workbook</a></li><br><li><a data-link-type='external' data-label='Webinar Video' target='_blank' href='https://youtu.be/izwr6622JkY'>Webinar Video</a></li></ul></div>"
            },
            {
                "id": 30,
                "title": "August 13 Webinar",
                "body": "<div><p>Attendees of <strong>Becoming Financially Aware &amp; What women need to know (divorce and workplace benefits edition)</strong> learned 5 things to be aware of during divorce and when considering workplace benefits. Thank your for your support and participation! </p><ul><li><a data-link-type='external' data-label='View Webinar Workbook' target='_blank' href='https://docs.trustwillow.com/Willow-Aug13-Webinar-Workbook.pdf'>View Webinar Workbook</a></li><br><li><a data-link-type='external' data-label='Webinar Video' target='_blank' href='https://www.youtube.com/watch?v=qo30hYxENPI'>Webinar Video</a></li></ul></div>"
            },
        ]
    },
    {
        "id": 3,
        "title": "Coaching Practices",
        "showWMCPartners": null,
        "faqs": [
            {
                "id": 3453435,
                "title": "Coaching The Willow Way",
                "body": "<div><p>What does it mean to coach the Willow way?</p> <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Coaching-The-Willow-Way.pdf'>click here</a></div>"
            },
            {
                "id": 3446634543534756476585667355,
                "title": "March 2023 Willow Coach Meeting",
                "body": "<div><p>Learn how to access free CE seminars, about new opportunities including coaching financial advisors, and discussion of the Willow Financial Advisor Standards of Client Interaction.</p> <a data-link-type='external' data-label='click here' target='_blank' href='https://vimeo.com/799710470/bfb5699b5e'>click here</a></div>"
            },
            {
                "id": 345,
                "title": "Spring 2021 Willow Coaching Call",
                "body": "<div><p>Listen for a business update and to learn about the Financial Life Coaching curriculum and Willow Connect platform enhancements. The spring research project is also discussed.</p> <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Willow-Coach-Update-May26-2021.mp4'>click here</a></div>"
            },
            {
                "id": 33,
                "title": "Willow Connect Overview November 12",
                "body": "<div><p>Video overview of Willow Connect for coaches</p> <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/WC-Overview-Nov10.mp4'>click here</a></div>"
            },
            {
                "id": 34,
                "title": "Community & Best Practices Session November 20",
                "body": "<div><p>Recording of the November 20 Willow Financial Coach Community & Best Practice session</p> <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Coach-BP-Nov20-recording.mp4'>click here</a></div>"
            },
            {
                "id": 32,
                "title": "Coach Video Update August 28",
                "body": "<div><p>Video update for coaches</p> <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Aug-28-Coach-Update.mp4'>click here</a></div>"
            },
            {
                "id": 28,
                "title": "How to refer your own clients to Willow",
                "body": "<div>With your own personalized Willow webpage and pricing, you can refer clients directly to you on the platform. Interested in streamlining your operations and costs? We&#39;d love to work with you to set up your own customized Willow coaching experience, complete with automated communications and marketing promotions! <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Personalize-the-Willow-platform-for-your-business.pdf'>click here</a>.</div>"
            },
            {
                "id": 27,
                "title": "Willow Coach Playbook",
                "body": "<div><p>Your guide to the coaching experience - welcome to Willow!</p> <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Willow-Coach-Playbook-July31.mp4'>click here</a></div>"
            },
            {
                "id": 22,
                "title": "Prospective client sign-up process - emails & texts",
                "body": "<div><p>View the communications sent to prospective clients before and after calls, and the steps each prospect takes as they set their complimentary coaching call.</p><ul><li><a data-link-type='external' data-label='Call Sign Up' target='_blank' href='https://docs.trustwillow.com/Sign_up_process_82420.pdf'>Call Sign Up</a></li><br><li><a data-link-type='external' data-label='Email Sequence' target='_blank' href='https://docs.trustwillow.com/Prospect-Email-Plans-82420.pdf'>Email Sequence</a></li></ul></div>"
            },
            {
                "id": 20,
                "title": "First Call Guide",
                "body": "<div>In order to help you win new clients, we have put together a guide for your first phone call.  Specific talking points about Willow are included to prepare you for questions you may receive.  <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Willow+1st+Client+Call+Guide+March+2022.pdf'>click here</a>.</div>"
            },
            {
                "id": 21,
                "title": "UPDATED Willow Coaching Programs",
                "body": "<div>To help you introduce pricing information and explain available programs to future clients, here is a breakdown of the options.  FOR INTERNAL USE ONLY - not to be shared with prospects. Direct prospects to Pricing page on Willow website for more info. <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Coaching-Programs-Nov2020.pdf'>click here</a>.</div>"
            },
            {
                "id": 29,
                "title": "UPDATED Client purchase process",
                "body": "<div>View the steps each prospect takes as they complete the purchase process and become a client! <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Purchase-Process-Dec9.pdf'>click here</a>.</div>"
            },
            {
                "id": 18,
                "title": "Coach Dashboard Introduction Video",
                "body": "<div>Here is a video from May 29, 2020 introducing the Willow Coach Dashboard and how to use it. <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Coach-Welcome.html'>click here</a>.</div>"
            },
            {
                "id": 19,
                "title": "Coach Launch Prep Call Recording",
                "body": "<div>Video recording of the coach launch prep call on June 17, 2020. <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Coach-Webinar-2.html'>click here</a>.</div>"
            },
            {
                "id": 24,
                "title": "Coach Video Update July 16",
                "body": "<div><p>Video update for coaches -&nbsp; thanks for all you do!</p> <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Coach-video-update-july16.mp4'>click here</a></div>"
            },
            {
                "id": 26,
                "title": "Coach Video Update August 1",
                "body": "<div><p>Video update for coaches</p> <a data-link-type='external' data-label='click here' target='_blank' href='https://docs.trustwillow.com/Aug-1-Coach-Update.mp4'>click here</a></div>"
            },
        ]
    }
];

export default expertResources;
