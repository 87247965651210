import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import styled from "styled-components";

/* Components */
import {CloseOutlined} from "@ant-design/icons";

/* Containers */
import Elements from './elements';
import FormActions, {NavActionTop} from './actions';
import Confirm from "./confirm";
import SignupFlow from "../../signup/containers/flow";

/* Store */
import {LogoLoading} from "../../../common/components/loading";

/* Middleware */
import {trySubmitForm} from "../middleware/forms";

const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;

class FormsFlow extends React.Component {
    static defaultProps = {
        form: [],
        skipAuth: false,
        onCompleteFormMethod: "submit", // "submit" or "return"
        submitFormToUserId: null, // number if "submit" or null if "return"
        onReturnForm: () => {},
        confirmCloseBtn: false,
        confirmCloseBtnLabel: <><Inline><CloseOutlined /></Inline>&nbsp;Close</>,
        confirmOnClose: () => {},
        autoSaveOn: false,
        autoSaveTry: () => {},
        formHasOnePage: false,
        lastPageCompleted: null,
        closeEarly: () => {},
        sectionPathname: "",
        formPathname: "",
        connectPagesToRoutes: false,
        editDisabled: true,
        allowFormToChange: false,
    };

    state = {
        "step": 1,
        "form": [],
        "pageNum": -1,
        "pageComplete": false,
        "isLoading": true,
        "answers": [],
        "pauseUpdates": false
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.location.pathname !== this.props.location.pathname && !this.state.pauseUpdates) {
            const prevPath = prevProps.location.pathname.split("/");
            const thisPath = this.props.location.pathname.split("/");
            // may cause issues later with skipping pages, be on lookout
            if (Math.abs(parseInt(thisPath[thisPath.length - 1]) - parseInt(prevPath[prevPath.length - 1])) > 1 && prevPath[prevPath.length - 1] === "0") {
                return this.props.confirmOnClose()
            }
            if (Math.abs(parseInt(thisPath[thisPath.length - 1]) - parseInt(prevPath[prevPath.length - 1])) > 1) {
                return this.init()
            }
            if(parseInt(thisPath[thisPath.length - 1]) < parseInt(prevPath[prevPath.length - 1])) {
                return this.actionLastPage();
            } else if(parseInt(thisPath[thisPath.length - 1]) > parseInt(prevPath[prevPath.length - 1])) {
                return this.actionNextPage()
            } else if(this.props.location.pathname === this.props.formPathname) {
                if(this.props.connectPagesToRoutes) {
                    return this.props.history.push(this.props.sectionPathname)
                }
            }
        }
        if (prevProps.form !== this.props.form && this.props.allowFormToChange) {
            return this.init()
        }
    }

    init = async () => {
        if(this.props.formHasOnePage) {
            await this.setState({"isLoading": true, "pauseUpdates": true, "pageNum": -1, "form": this.props.form});
            await this.actionOnlyPage();
        } else {
            await this.setState({"isLoading": true, "pauseUpdates": true, "pageNum": 0, "form": this.props.form});
            if(this.props.lastPageCompleted !== null) {
                await this.addToHistoryStack();
                await this.actionResumePage();
            } else {
                await this.actionFirstPage();
            }
        }
        await this.setState({"pauseUpdates": false, "isLoading": false});
    };

    setStep = async (step) => {
        console.log("setStep", this.state)
        this.setState({"step": step})
    };

    addToHistoryStack = async () => {
        console.log("addToHistoryStack", this.state)
        if(this.props.connectPagesToRoutes) {
            for (let page = 0; page < (this.props.lastPageCompleted+2); page++) {
                this.props.history.push(this.props.formPathname + "/page/" + page)
            }
        }
    };

    actionResumePage = async () => {
        console.log("actionResumePage", this.state)
        if(this.props.autoSaveOn && !this.props.editDisabled) {
            await this.props.autoSaveTry(this.state.form, false, this.props.lastPageCompleted);
        }
        await this.setState({"pageNum": (this.props.lastPageCompleted + (!this.props.editDisabled ? 0 : 1))});
        this.scrollToTop()
        await this.isPageComplete();
    };

    setUrlToLastPage = async () => {
        console.log("setUrlToLastPage", this.state)
        if(this.props.location.pathname === (this.props.formPathname+"/page/0")) {
            this.props.closeEarly();
        } else {
            if(this.props.connectPagesToRoutes) {
                this.props.history.goBack();
            } else {
                this.actionLastPage();
            }
        }
    };

    setUrlToNextPage = async () => {
        console.log("setUrlToNextPage", this.state)
        if(this.props.connectPagesToRoutes) {
            this.props.history.push(this.props.formPathname + "/page/" + (this.state.pageNum + 1))
        } else {
            this.actionNextPage();
        }
    };

    actionLastPage = async () => {
        console.log("actionLastPage", this.state)
        if(this.props.autoSaveOn && !this.props.editDisabled) {
            await this.props.autoSaveTry(this.state.form, false, this.state.pageNum - 1);
        }
        await this.setState({"pageNum": this.state.pageNum - 1});
        this.scrollToTop()
        await this.isPageComplete();
    };

    actionFirstPage = async () => {
        console.log("actionFirstPage", this.state)
        if(this.props.connectPagesToRoutes) {
            this.props.history.push(this.props.formPathname + "/page/0");
        }
        await this.setState({"pageNum": this.state.pageNum + 1});
        this.scrollToTop()
        await this.isPageComplete();
    };

    actionNextPage = async () => {
        console.log("actionNextPage", this.state)
        if(this.props.autoSaveOn) {
            await this.props.autoSaveTry(this.state.form, false, this.state.pageNum + (!this.props.editDisabled ? 1 : 0));
        }
        await this.setState({"pageNum": this.state.pageNum + 1});
        this.scrollToTop()
        await this.isPageComplete();
    };

    actionOnlyPage = async () => {
        console.log("actionOnlyPage", this.state)
        await this.setState({"pageNum": this.state.pageNum + 1});
        this.scrollToTop()
        await this.isPageComplete();
    };

    scrollToTop = () => {
        if(this.props.editDisabled) {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    updateForm = async (elementId, data, isValid) => {
        console.log("updateForm", this.state)
        return this.state.form.map(pageObj => {
            if(pageObj.pageNum !== this.state.pageNum) {
                return pageObj;
            } else {
                return ({
                    ...pageObj,
                    "elements": pageObj.elements.map(element => {
                        if(element.id !== elementId) {
                            return element;
                        } else {
                            return ({...element, "isValid": isValid, ...data})
                        }
                    })
                })
            }
        })
    }

    onSaveElement = async (elementId, data, isValid) => {
        console.log("onSaveElement", this.state)
        const form = await this.updateForm(elementId, data, isValid);
        console.log("onSaveElement2", this.state)
        await this.setState({"form": form});
        console.log("onSaveElement3", this.state)
        if(this.props.autoSaveOn) {
            await this.props.autoSaveTry(form, false, (this.state.pageNum + (!this.props.editDisabled ? 0 : -1)));
        }
        await this.isPageComplete();
    };

    isPageComplete = async () => {
        console.log("isPageComplete", this.state)
        if (this.state.form[this.state.pageNum].actions.canContinueAutomatically) {
            this.setState({"pageComplete": true});
        } else {
            let elementsIsValid = [];
            await Promise.all(this.state.form[this.state.pageNum].elements.map(element => {
                if(Object.keys(element).includes("isRequired")) {
                    if (!element.isRequired) {
                        elementsIsValid.push(true)
                    } else {
                        if (Object.keys(element).includes("isValid")) {
                            if (element.isValid) {
                                elementsIsValid.push(true)
                            } else {
                                elementsIsValid.push(false)
                            }
                        } else {
                            elementsIsValid.push(true)
                        }
                    }
                }
                return Promise.resolve();
            }));
            await this.setState({"pageComplete": !elementsIsValid.includes(false)});
        }
    };

    actionCompleteForm = async () => {
        console.log("actionCompleteForm", this.state)
        if(this.props.skipAuth) {
            return this.onComplete();
        } else {
            return this.setStep(2);
        }
    };

    signupClose = async () => {
        console.log("signupClose", this.state)
        await this.onComplete();
    };

    onComplete = async () => {
        console.log("onComplete", this.state)
        if(this.props.autoSaveOn) {
            await this.props.autoSaveTry(this.state.form, true, this.state.pageNum);
        }
        if(this.props.onCompleteFormMethod === "submit") {
            await this.props.trySubmitForm(this.state.form, this.props.submitFormToUserId);
            return this.setStep(3);
        } else if(this.props.onCompleteFormMethod === "return") {
            return this.props.onReturnForm(this.state.form);
        }
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            if(this.state.step === 1) {
                return(
                    <div>
                        {this.state.form[this.state.pageNum].actions.backShowOnTop &&
                            <NavActionTop
                                back={this.setUrlToLastPage}
                            />
                        }
                        <Elements
                            elements={this.state.form[this.state.pageNum].elements}
                            onSaveElement={this.onSaveElement}
                            disabled={this.props.editDisabled}
                        />
                        <FormActions
                            actions={this.state.form[this.state.pageNum].actions}
                            currentPageNum={this.state.pageNum+1}
                            totalPages={this.state.form.length}
                            pageComplete={this.state.pageComplete}
                            actionLastPage={this.setUrlToLastPage}
                            actionNextPage={this.setUrlToNextPage}
                            actionCompleteForm={this.actionCompleteForm}
                        />
                    </div>
                )
            } else if(this.state.step === 2) {
                return (
                    <SignupFlow
                        back={() => {return this.setStep(1)}}
                        signupClose={this.signupClose}
                        signupShowLoginBtn={true}
                    />
                )
            } else if(this.state.step === 3) {
                return (
                    <Confirm
                        confirmCloseBtn={this.props.confirmCloseBtn}
                        confirmCloseBtnLabel={this.props.confirmCloseBtnLabel}
                        confirmOnClose={this.props.confirmOnClose}
                    />
                )
            } else {
                return null
            }
        }
    }
}

const mapDispatchToProps = dispatch => ({
    trySubmitForm: (formData, userId) => dispatch(trySubmitForm(formData, userId))
});

export default connect(null, mapDispatchToProps)(withRouter(FormsFlow));
