import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { colors } from '../../../components/colors'

/* Components */
import { TableWrapperV2 } from "../../../components/tablev2";

/* Middleware */
import { tryGetSharedDriveFolderList } from '../middleware/filevault';

/* Store */
import { setBreadcrumbUpdate } from '../store/filevault';

/* Domain */
import getDomainEnv from '../../../../domains/utils';
const env = getDomainEnv();

class VaultSearchResults extends React.Component {
    static defaultProps = { results: [], onClick: () => {}, allBreadcrumbs: {}, }
    state = { "results": [], viewClicked: false }

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.results !== this.props.results && !this.state.viewClicked) {
            this.update();
        }
    }

    init = async () => {
        await this.update();
    }

    update = async () => {
        this.setState({
            "results": await this.createDataForSearchTable(this.props.results),
        })
    }

    view = async (data) => {
        if (data.type === 'File') {
            this.tempDownload(data.id);
        } else {
            await this.setState({ viewClicked: true, })
            await this.props.selectDrive(data);
            if (data.type === 'Folder') {
                await this.openFolder(data);
            }
            this.props.history.push("/vaults/drive");
        }

    }

    openFolder = async (data) => {
        let folderId = data.id;
        let driveId = data.driveId;
        await this.props.tryGetSharedDriveFolderList(driveId, folderId, false);
        await this.props.setBreadcrumbUpdate(this.props.allBreadcrumbs[folderId])
    }


    tempDownload = (id) => {
        let tempLink = document.createElement("a");
        tempLink.href = "https://"+env.URL.API+"/filevault/file/download/"+id;
        tempLink.target = "_blank";
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
    };

    createDataForSearchTable = async (data) => {
        return Promise.all(data.map((d, index) => {
            return Promise.resolve({
                "id": index,
                "label": d.label,
                "type": d.type,
                "allData": { ...d, linkAllowed: true },
            })
        }));
    }

    createColumnsForSearchTable = {
        "0": {"key": "id", "title": "Id", "titleShowOnDesktop": false, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "hidden", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": this.view, "onDoubleClick": null},
        "1": {"key": "label", "title": "Name", "titleShowOnDesktop": true, "canSort": true, "sort": "asc", "sortType": "string", "sortKey": "label", "component": "linkIfDataAllows", "thStyles": {}, "tdStyles": {"color": colors.action100}, "ifNull": "", "onClick": this.view, "onDoubleClick": null},
        "2": {"key": "type", "title": "Type", "titleShowOnDesktop": true, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "string", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": null, "onDoubleClick": null}, 
    }

    render() {
        return (
            <TableWrapperV2
                responsive={true}
                tableColumns={this.createColumnsForSearchTable}
                tableData={this.state.results}
                initSelected={null}
            />
        )
    }
}

const mapStateToProps = state => ({
    folderBreadcrumb: state.common.fileVault.get("folderBreadcrumb"),
    drive: state.common.fileVault.get("sharedDrive"),
    driveList: state.common.fileVault.get("sharedDriveList"),
    folderList: state.common.fileVault.get("folderList"),
});

const mapDispatchToProps = dispatch => ({
    tryGetSharedDriveFolderList: (driveId, folderId, storeBreadcrumb) => dispatch(tryGetSharedDriveFolderList(driveId, folderId, storeBreadcrumb)),
    setBreadcrumbUpdate: (breadcrumbs) => dispatch(setBreadcrumbUpdate(breadcrumbs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VaultSearchResults));
