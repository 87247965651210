import React from 'react';
import styled from 'styled-components';

/* Components */
import {FontBody16, FontSubtitle28} from "../../../common/components/fonts";

const Intro = styled.div`
  width: calc(100% - 60px);
  padding: 0 30px; 
  display:flex;
  flex-direction:column;
  gap: 10px;
`;

export default function IFrameAdvisorDirectoryIntro() {
    return (
        <Intro>
            <FontSubtitle28 spaced={true}>DISCIPLINED INDIVIDUALS</FontSubtitle28>
        </Intro>
    );
};