import styled from "styled-components";

import {colors} from "../../../common/components/colors";

export const Page = styled.div`
    background-color: ${colors.white};
    max-width: 960px;
    color: ${colors.primary100};
    margin: 0px auto 15px auto;
`;

export const OneContainer = styled.div`
    padding: 16px;
    display: inline-block;
    vertical-align: top;
`;
export const One = styled.div`
    width: 288px;
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    &:hover {
        -webkit-box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    }
    position: relative;
`;

export const HeroContainer = styled.div`
    position: relative;
`;
export const SpoofContainer = styled.div`
    height: 140px;
`;
export const FillContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;
export const HeroVidBackground = styled.iframe`
    position: relative;
    width: 100%;
    max-height: 138px;
    border: none;
    height: 100%;
    border: 1px solid ${colors.border100};
`;

export const HeroImgBackground = styled.div`
    background-image: url(${props => props.imgObj});
    position: relative;
    background-size: cover;
    background-position: center;
    height: 138px;
    border: 1px solid ${colors.border100};
`;
export const WhiteContainer = styled.div`
    background-color: ${colors.white};
    padding: 24px 16px;
    min-height: 104px;
    max-height: 104px;
    height: 104px;
`;
export const SmallWhiteContainer = styled.div`
    background-color: ${colors.white};
    padding: 24px 16px;
    min-height: 39px;
    max-height: 39px;
    height: 39px;
    padding-top: 0;
    cursor: default;
`;
export const Title = styled.div`
    color: ${colors.primary100};
    @media screen and (max-width: 460px) {
        font-size: 16px;
    }
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 12px;
`;
export const QuillNoPadding = styled.div`
    .ql-editor {
        padding: 0px;
        max-height: 55px;
        overflow-y: clip;
    }
    cursor: default;
`;

export const ActiveLink = styled.div`
    &:hover {
        text-decoration: underline;
    }
    cursor: pointer;
    color: ${colors.progress100};
`;