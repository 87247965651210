import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

import {colors} from '../../../common/components/colors';
import {FontBody16, FontTitle21, FontBody21, FontHeader18} from '../../../common/components/fonts';

import {CaretDownOutlined, CaretRightOutlined} from '@ant-design/icons';



const DropdownBox = styled.div`
    border: 1px solid ${colors.primary30};
    background-color: ${colors.white};
    border-radius: 10px;
    margin: ${props => props.marginTopBottom} 0px ${props => props.marginTopBottom} 0px;
`;
const DropdownTitle = styled.div`
    padding: 15px 20px;
    color: ${colors.primary100};
    cursor: pointer !important;
    background-color: ${colors.border100};
`;
const Title = styled.div`
    display: inline-block;
    vertical-align: top;
    text-align: left;
    width: calc(100% - 16px);
`;
const Icon = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-top: 3px;
    text-align: right;
`;
const Divider = styled.div`
    border-top: 1px solid ${colors.border100};
    padding: 15px 20px;
`;
const LinkStyle = styled.div`
    display: inline-block;
    & a {
        color: ${colors.action100};
        text-decoration: underline !important;
    }
`;
const PageStyle = styled.span`
    display: inline-block;
    word-break: break-word;
    & div {
        display: inline-block;
        color: ${colors.action100};
        text-decoration: underline !important;
        cursor: pointer;
        word-break: break-word;
    }
`;

class OneFAQModal extends React.Component {
    constructor(props) {
        super(props);
        this.state={expanded: false}
    }

    handleClick = e => {this.setState({expanded: !this.state.expanded})};

    htmlParserTransform = (node, index) => {
        if (node.type === "tag" && node.name === "a") {
            const {href} = node.attribs;
            if(node.attribs["data-link-type"] === 'external') {
                return (<LinkStyle key={index}><FontBody16><a target={node.attribs["target"]} href={href}>{node.attribs["data-label"]}</a></FontBody16></LinkStyle>)
            } else if(node.attribs["data-link-type"] === 'internal') {
                return (<LinkStyle key={index}><FontBody16 onClick={() => {return this.props.action(null, false)}}><Link to={href}>{node.attribs["data-label"]}</Link></FontBody16></LinkStyle>)
            }
        } else if(node.type === "tag" && node.name === "span") {
            if(node.attribs["data-link-type"] === 'page') {
                return (<PageStyle key={index} onClick={() => {return this.props.action(node.attribs["data-label-action-props"], true)}}><FontBody16>{node.attribs["data-label"]}</FontBody16></PageStyle>)
            }
        } else if(node.type === "tag" && node.name === "br") {
            return(<br />)
        }
    };

    render() {
        return(
            <DropdownBox marginTopBottom={"10px"}>
                <DropdownTitle onClick={this.handleClick}>
                    <Title><FontBody16>{this.props.title}</FontBody16></Title>
                    <Icon>
                        {this.state.expanded
                            ? <CaretDownOutlined/>
                            : <CaretRightOutlined/>
                        }
                    </Icon>
                </DropdownTitle>
                {this.state.expanded &&
                    <Divider><FontBody16>{ReactHtmlParser(this.props.body, {transform: this.htmlParserTransform})}</FontBody16></Divider>
                }
            </DropdownBox>
        )
    }
}


const Section = styled.div`
    margin-bottom: 40px;
`;

const SectionTop = styled.div`
  padding: 10px 20px;
  overflow-y: auto;
  height: calc(100% - 80px);
`;

const HelpFAQsModal = ({ data, action }) => (
    <SectionTop>
        <br />
        {data.map(section => {
            return(
                <Section key={section.id}>
                    <FontTitle21>{section.title}</FontTitle21>
                    {section.faqs.map(question => {
                        return(
                            <div key={question.id}>
                                <OneFAQModal title={question.title} body={question.body} action={action}/>
                            </div>
                        )
                    })}
                </Section>
            )
        })}
    </SectionTop>
);
export default HelpFAQsModal;