import React from 'react';
import styled from "styled-components";
import { withRouter } from 'react-router-dom';

/* Components */
import {FontBody21To16} from '../../../common/components/fonts';
import { colors } from '../../../common/components/colors';
import {ContainerApptNav} from '../components/progress'

/* Containers */
import MoneySpending from "../../../common/containers/mymoney/containers/moneyspending";
import MoneySnapshot from "../../../common/containers/mymoney/containers/moneysnapshot";
import MoneySaving from "../../../common/containers/mymoney/containers/moneysaving";

const ContainerPadded = styled.div`
    padding: 0px 60px 70px;
    @media screen and (max-width: 748px) {
        padding: 0px 30px 70px;
    }
    @media screen and (max-width: 480px) {
        padding: 0px 15px 70px;
    }
`
const NavOption = styled(FontBody21To16)`
    ${props => props.active !== true} {
        border-bottom: 3px solid ${colors.white};
        cursor: default;
    }
    ${props => props.active !== false} {
        border-bottom: none;
        cursor: pointer;
    }
    width: fit-content;
    color: ${colors.white};
}
`;

const Bar = styled.div`
    width:2px;
    margin: 4px 0;
    background-color: ${colors.white}
`;

class ProgressFinances extends React.Component {
    state= {currentTab: "spending"}

    // type: 'spending', 'snapshot', or 'saving'
    selectMoney = (type) => {
        this.props.history.push("/progress/finances"+(type === "" ? "" : "/"+type))
    }

    changeTab = async (tabToSwitchTo) => {
        await this.setState({"currentTab": tabToSwitchTo});
    };

    render() {
        return (
            <>
                <ContainerApptNav>
                    {/*<NavOption active={this.props.location.pathname === "/progress/finances"} onClick={() => {this.selectMoney('');}}>*/}
                    <NavOption active={this.state.currentTab === "spending"} onClick={() => {this.changeTab('spending');}}>
                        {'Monthly Spending Plan'}
                    </NavOption>
                    <Bar />
                    {/*<NavOption active={this.props.location.pathname === "/progress/finances/snapshot"} onClick={() => {this.selectMoney('snapshot');}}>*/}
                    <NavOption active={this.state.currentTab === "snapshot"} onClick={() => {this.changeTab('snapshot');}}>
                        {'Money Snapshot'}
                    </NavOption>
                    <Bar />
                    {/*<NavOption active={this.props.location.pathname === "/progress/finances/saving"} onClick={() => {this.selectMoney('saving');}}>*/}
                    <NavOption active={this.state.currentTab === "saving"} onClick={() => {this.changeTab('saving');}}>
                        {'Savings Goals'}
                    </NavOption>
                </ContainerApptNav>
                <ContainerPadded>
                    {this.state.currentTab === "spending" //this.props.location.pathname === "/progress/finances"
                        ?
                        <MoneySpending useNewDesign={true}/>
                        :
                        this.state.currentTab === "snapshot" // this.props.location.pathname === "/progress/finances/snapshot"
                            ?
                            <MoneySnapshot useNewDesign={true}/>
                            :
                            this.state.currentTab === "saving" // this.props.location.pathname === "/progress/finances/saving"
                                && <MoneySaving useNewDesign={true}/>
                    }
                </ContainerPadded>
            </>
        )
    }
}

export default withRouter(ProgressFinances);