import React from 'react';
import {connect} from "react-redux";

/* Components */
import { LogoLoading } from "../../../common/components/loading";
import { Background, Button, Flex, VideoContainer, VideoPlayer, BackSection, Back } from '../components/home';
import { FontBody16, FontSubtitle24To20 } from '../../../common/components/fonts';
import { ChevronLeft } from "react-feather";
import { videos } from '../../../common/components/videos';
import { FlexRow } from '../../../common/components/layout';

/* Middleware */
// import { tryAuthConsumer } from '../../../routes/middleware/auth';

class WelcomeVideo extends React.Component {
    // static defaultProps = { 'showDemo': false };
    state = { 
        'isLoading': true, 
        'videoOpen': false, 
        'videoLink': videos.onboardingShort,
    };

    componentDidMount() {
        this.init();
    }

    init = async () => {
        this.setState({ 'isLoading': false, 'videoOpen': this.props.showDemo, })
    }

    openVideo = async () => {
        this.setState({"videoOpen": !this.state.videoOpen})
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <>
                    {this.state.videoOpen && !this.props.demoAlreadyShownOnHome && 
                        <Flex>
                            <VideoContainer style={{height: '92%', width: 'auto'}}>
                                <BackSection>
                                    <Back onClick={this.openVideo}><ChevronLeft/></Back>
                                </BackSection>
                                <FlexRow style={{justifyContent: 'center', marginBottom: '20px'}}>
                                    <FontSubtitle24To20 style={{textAlign: 'center'}}>{'WELCOME TO WILLOW'}</FontSubtitle24To20>
                                </FlexRow>
                                <VideoPlayer
                                    style={{height: '85%', width: 'auto'}}
                                    src={this.state.videoLink}
                                    controls={true}
                                />
                            </VideoContainer>
                            <Background onClick={this.openVideo} />
                        </Flex>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    showDemo: state.common.user.get("showDemo"),
    demoAlreadyShownOnHome: state.common.user.get("demoAlreadyShownOnHome"),
});

export default connect(mapStateToProps, null)(WelcomeVideo);