import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/* Middleware */
import {tryAuthOnly, setContact} from "../middleware/auth";
import {setRedirect} from "../store/user";

class RedirectAfterLogin extends React.Component {
    componentDidMount() {
        (() => {
            return (async () => {
                let path = window.location.pathname;
                let url = this.props.data.redirect;
                let token = this.props.data.token;
                await this.props.setRedirect(true, path, url, token);
                if(token !== null) {
                    let pathAdj = path.charAt(path.length-1) === "/" ? path.slice(0, -1) : path;
                    let pathSplit = pathAdj.split("/");
                    let token = pathSplit[pathSplit.length + this.props.data.token];
                    if(url === "/contact/notes") {
                        await this.props.setContact(token);
                    } else if(url === "/my-tasks/course/:courseId/quiz") {
                        url = "/my-tasks/course/"+token+"/quiz";
                    } else if(url === "/my-tasks/course/:courseId/failed") {
                        url = "/my-tasks/course/"+token+"/failed";
                    } else if(url === "/my-tasks/course/:courseId/passed") {
                        url = "/my-tasks/course/"+token+"/passed";
                    } else if(url === "/my-tasks/course/:courseId/completed") {
                        url = "/my-tasks/course/"+token+"/completed";
                    } else {
                        url = this.props.data.redirect + token;
                    }
                }
                const user = await this.props.tryAuthOnly(null, false, true);
                if(user !== null) {
                    await this.props.setRedirect(false, null, null, null);
                    if(url === "/availability") {
                        if(this.props.isWMC) {
                            url = "/my-certificates/profile/calendar"
                        } else {
                            url = "/settings/availability/calendar"
                        }
                    }
                    this.props.history.push(url);
                } else {
                    this.props.history.push("/login");
                }
            })()
        })()
    }
    render() {return null}
}

const mapStateToProps = state => ({
    isWMC: state.common.wmc.get("isWMC")
})

const mapDispatchToProps = dispatch => ({
    tryAuthOnly: (email, trackNewRegistration, forceAuth) => dispatch(tryAuthOnly(email, trackNewRegistration, forceAuth)),
    setContact: (prospectId) => dispatch(setContact(prospectId)),
    setRedirect: (redirectNow, from, to, token) => dispatch(setRedirect(redirectNow, from, to, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RedirectAfterLogin));