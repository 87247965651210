// Either an object or {}
import {images} from "../../../components/images";

const randomNumber = () => {return Math.floor(Math.random() * 100)+1};

export const devWMC = {
    'id': "wmc",
    'companyName': "wmc",
    'companyLogoUrl': images.logo,
    'phone': "wmc",
    'email': "wmc",
    'address1': "wmc",
    'address2': "wmc",
    'city': "wmc",
    'state': "wmc",
    'zipCode': "wmc",
    'facebookUrl': "wmc",
    'twitterUrl': "wmc",
    'linkedinUrl': "wmc",
    'contactPersonFirstName': "wmc",
    'contactPersonLastName': "wmc",
    'contactPersonWorkPhone': "wmc",
    'contactPersonCellPhone': "wmc",
    'contactPersonEmail': "wmc",
};

export const devSettingsUsersEmployeesCreate = (data) => ({
    "id": randomNumber(),
    "email": data.email,
    "firstName": data.first,
    "lastName": data.last,
    "phone": data.phone,
    "timeZone": data.timeZone,
    "photo": images.devDataTeamJasonAvatar,
    "bio": '<p>In an age of pandemic, what true value do any of our backgrounds hold? It’s not like we can look at precedents and say, people who have done ABC tend to be really successful at accomplishing XYZ in a pandemic. My expf</p>',
    "whyBecameCoach": 'Test 2',
    "companyName": 'Coaching, LLC',
    "crd": 'test 5',
    "endorsement1": 'Test 1',
    "endorsement2": 'Test 2',
    "endorsement3": null,
    "freeTime": 'Test 7',
    "address1": 'test 3',
    "address2": 'test 6',
    "city": 'Boston',
    "state": 'MA',
    "zipCode": '23443',
    "introVideoUrl": 'https://google.com',
    "facebookUrl": 'https://facebook.com',
    "linkedinUrl": 'https://linkedin.com',
    "twitterUrl": 'https://twitter.com',
    "bookingAvailability": data.bookingAvailability === undefined ? "available" : data.bookingAvailability,
    "screeningCallLength": 30,
    "defaultCallLength": 30,
});

export const devSettingsUsersEmployees = {
    "employees": [
        devSettingsUsersEmployeesCreate({first: "Jason", last: "Aronson", phone: "4135193519", email: "jason.aronson@gmail.com", timeZone: "central", id: 1, "bookingAvailability": "available"}),
        devSettingsUsersEmployeesCreate({first: "Jason", last: "Aronson", phone: "4135193519", email: "jason.aronson@gmail.com", timeZone: "central", id: 2, "bookingAvailability": "not_available"}),
        devSettingsUsersEmployeesCreate({first: "Jason", last: "Aronson", phone: "4135193519", email: "jason.aronson@gmail.com", timeZone: "central", id: 3, "bookingAvailability": "available"})
    ]
};
