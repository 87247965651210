import React from 'react';
import styled from 'styled-components';

/* Components */
import {colors} from '../../../common/components/colors';
import {ButtonPrimary, ButtonInactive, ButtonTertiary, ButtonTertiaryAlert} from "../../../common/components/buttons";

export const SaveSection = styled.div`
    border-top: 1px solid ${colors.border100};
    text-align: left;
    padding-top: 20px;
    margin-top: 50px;
`;
export const InlineBlock = styled.div`
    display: inline-block;
`;
const ButtonRight = styled.div`
    margin-right: 10px;
`;
export const SubmitInputWithValidation = ({ isChanged, isValid, isSaving, label }) => {
    if(isChanged && isValid) {
        if(isSaving) {
            return (<ButtonRight><ButtonInactive canSubmit={true} label={"Saving"} /></ButtonRight>)
        } else {
            return (<ButtonRight><ButtonPrimary canSubmit={true} label={label}/></ButtonRight>)
        }
    } else {
        return(<ButtonRight><ButtonInactive canSubmit={true} label={label}/></ButtonRight>)
    }
};
export const SubmitInputWithValidationAlert = ({ isChanged, isValid, isSaving, label }) => {
    if(isChanged && isValid) {
        if(isSaving) {
            return (<ButtonRight><ButtonTertiary canSubmit={true} label={"Saving"} /></ButtonRight>)
        } else {
            return (<ButtonRight><ButtonTertiaryAlert canSubmit={true} label={label}/></ButtonRight>)
        }
    } else {
        return(<ButtonRight><ButtonInactive canSubmit={true} label={label}/></ButtonRight>)
    }
};



export const InlineItemLeft = styled.div`
    display: inline-block;
    width: calc(100% - 25px);
    vertical-align: top;
`;
export const InlineItemRight = styled.div`
    display: inline-block;
    vertical-align: top;
`;
export const EditIcon = styled.div`
    color: ${colors.action100};
    cursor: pointer;
    padding: 4.5px;
`;




export const LoadingCenter = styled.div`
    margin: 60px auto;
`;

export const Label = styled.div`
    padding: 3px 3px 3px 0px;
    background-color: ${colors.white};
    border: none;
    color: ${colors.primary100};
    font-family: GraphicWebRegular;
    font-size: 16px;
    line-height: 22px;
    word-break: break-word;
    @media screen and (max-width: 460px) {
        font-size: 14px;
        line-height: 20px;
    }
`;

export const ReadOnlyLabel = styled.div`
    color: ${colors.secondary100};
`;

export const Invalid = styled.div`
    color: ${colors.alert100};
`;

export const Info = styled.div`
    color: ${colors.action100};
    margin-left: 5px;
    margin-top: 3px;
    font-size: 14px;
    display: inline-block;
`;

export const InputSection = styled.input`
    background-color: ${colors.border70};
    border: 1px solid ${colors.primary30};
    color: ${colors.primary100};
    border-radius: 4px;
    width: calc(100% - 40px);
    padding: 10px 20px;
    font-family: GraphicWebRegular;
    font-size: 16px;
    line-height: 22px;
    &::placeholder {
        color: ${colors.primary30};
    }
    &:focus {
        outline: none;
    }
`;
export const Red = styled.div`
    color: ${colors.alert100};
`;
export const Button = styled.div`
    color: ${colors.action100};
    cursor: pointer;
`;
export const ButtonDone = styled.div`
    color: ${colors.progress100};
`;
export const FitContent = styled.div`
    width: fit-content;
    margin-left: 20px;
    width: calc(100% - 20px);
`;
export const RemoveMargin = styled.div`
    margin-bottom: -30px;
`;
export const Border = styled.div`
    border-bottom: 1px dashed ${colors.primary30};
`;