import React from 'react';
import styled from "styled-components";
import validator from "validator";

/* Components */
import {FontBody14, FontBody16} from '../../common/components/fonts';
import {colors} from "../../common/components/colors";
import {CameraOutlined} from '@ant-design/icons';

const PhotoButton = styled.div`
    width: 130px;
    height: 105px;
    border: 1px solid ${colors.secondary30};
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    padding-top: 45px;
    font-size: 30px;
    margin: 20px 20px 0px 0px;
    color: ${colors.primary100};
    ${props => props.grayBackground !== true} {
        background-color: ${colors.border30};
    }
    ${props => props.grayBackground !== false} {
        background-color: ${colors.white};
    }
`;
const PhotoUpload = styled.input`
    width: 130px;
    height: 150px;
    display: none;
`;
const Photo = styled.img`
    width: auto;
    height: auto;
    max-height: ${props => props.photoMaxHeight}px;
    display: block;
    border: 1px solid ${colors.secondary30};
    max-width: 100%;
    object-fit: cover;
    object-position: 0 0;
`;
const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;
const PhotoMargin = styled.div`
    margin-bottom: -20px;
    color: ${colors.secondary100};
`;
const Alert = styled.div`
    color: ${colors.alert100};
`;


class PhotoUploadSection extends React.Component {
    state = {photo: '', photoChanged: false, photoBlob: '', error: ''};

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.photo !== this.props.photo) {
            return this.init();
        }
    }

    init = async () => {
        await this.setState({
            "photo": (this.props.photo === "" || this.props.photo === null || this.props.photo === undefined) ? "" : this.props.photo
        });
    };

    tryChangePhoto = async () => {
        document.getElementById('selectedFile').click()
    };

    tryRemovePhoto = async () => {
        await this.setState({
            photo: '',
            photoFile: '',
            photoChanged: true,
            photoBlob: '',
            error: ''
        });
        this.props.action(this.state);
    };

    // handleFetchImageBlob = () => {
    //     this.editor.getImageScaledToCanvas().toBlob(blob => {this.props.onChange(blob);});
    // };

    handleChangePhoto = async (e) => {
        // await this.handleFetchImageBlob();
        if (e.target.files[0].size > 2500000) {
            await this.setState({
                photo: '',
                photoFile: '',
                photoChanged: false,
                photoBlob: '',
                error: "Must be smaller than 2.5MB."
            });
        } else {
            await this.setState({
                'photo': URL.createObjectURL(e.target.files[0]),
                'photoFile': e.target.files[0],
                'photoChanged': true,
                'photoBlob': e,
                'error': ""
            });
            this.props.action(this.state);
        }
    };

    render() {
        if(this.state.photo === "") {
            return(
                <>
                    <PhotoMargin><FontBody16>{this.props.labelTitle}<Alert>{(this.state.error !== "" ? " " + this.state.error : "")}</Alert></FontBody16></PhotoMargin>
                    <PhotoButton grayBackground={this.props.grayBackground} onClick={this.tryChangePhoto}>
                        <CameraOutlined />
                        <FontBody14>{this.props.labelAdd}</FontBody14>
                    </PhotoButton>
                    <PhotoUpload id={"selectedFile"} type={"file"} onChange={this.handleChangePhoto} accept={".png,.jpeg,.jpg,.gif"} />
                </>
            )
        } else {
            return(
                <>
                    <FontBody16>{this.props.labelTitle}</FontBody16>
                    <Photo photoMaxHeight={this.props.photoMaxHeight} src={this.state.photo} alt={"Photo"} />
                    <Inline>
                        <PhotoButton grayBackground={this.props.grayBackground} onClick={this.tryChangePhoto}>
                            <CameraOutlined />
                            <FontBody14>{this.props.labelChange}</FontBody14>
                        </PhotoButton>
                        <PhotoUpload id={"selectedFile"} type={"file"} onChange={this.handleChangePhoto} accept={".png,.jpeg,.jpg,.gif"} />
                    </Inline>
                    <Inline>
                        <PhotoButton grayBackground={this.props.grayBackground} onClick={this.tryRemovePhoto}>
                            <CameraOutlined />
                            <FontBody14>{this.props.labelRemove}</FontBody14>
                        </PhotoButton>
                    </Inline>
                </>
            )
        }
    }
}

export default PhotoUploadSection;

/*
Example:
<PhotoUploadSection
    labelTitle={"Photo"}
    labelAdd={"Add a photo"}
    labelChange={"Change photo"}
    labelRemove={"Remove photo"}
    grayBackground={true}
    photoMaxHeight={400}
    action={this.updatePhoto}
    photo={this.state.photo}
    isUrl={!this.state.photoChanged}
/>
 */