import React from 'react';
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import styled from 'styled-components';
import {Map} from 'immutable';

/* Components */
import {images} from "../../../../common/components/images";
import { FontTitle24, FontBody18, FontBody14 } from '../../../../common/components/fonts';
import {colors} from "../../../../common/components/colors";

/* Middleware */
import {tryGetAllClients, tryGetCoachingAppointments, tryGetUpcomingTrainingsRegisteredFor} from "../middleware/home";
import {tryGetAllTasks} from "../../tasks/middleware/tasks";
import {certificationValidation} from "../../certification/middleware/certificationValidation";
import {ToolTipCreator} from "../components/tooltips";
import {trackTasksBecomeMember} from "../../../../common/utils/tracking";

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;
const DashboardContainer = styled.div`
    border-radius: 30px;
    background-color: ${colors.white};
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    padding: 30px;
    justify-content: center;
    gap: 20px;
    display: flex;
    flex-direction: column;
`;
const Item = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 25px;
    position: relative;
`;
const Icon = styled.div`
    background-image: url(${props => props.image});
    background-size: contain;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    align-self: center;
    justify-self: center;
`;
const IconPointer = styled(Icon)`
    width: 18px;
    height: 18px;
`;
const Strikethrough = styled(FontBody18)`
    ${props => props.strike !== true} {
        text-decoration: line-through;
    }
`;
const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
`;
const ViewAll = styled(Link)`
    color: ${colors.primary100};
    text-decoration: underline;
`;

class FirmDashboardTasks extends React.Component {
    state = {
        "hasMeetingsBooked": false,
        "hasMeetingsCompleted": false,
        "hasProgramsBooked": false,
        "hasClients": false,
        "activeTasks": [],
        "completedTasks": [],
        "isLoading": true,
         "certificationCompleted": false,
    }

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const clients = await this.props.tryGetAllClients();
        const clientsActive = await clients.filter(f => {return(f.isActive)})
        if(this.props.isLily) {
            const meetings = await this.props.tryGetCoachingAppointments();
            const meetingsCompleted = await meetings.pastCalls.filter(f => {return(!f.isMissed && !f.isCanceled)});
            const programs = await this.props.tryGetUpcomingTrainingsRegisteredFor();
            const validation = await this.props.certificationValidation();
            await this.setState({
                "hasMeetingsBooked": (meetings.upcomingCalls.length > 0),
                "hasMeetingsCompleted": (meetingsCompleted.length > 0),
                "hasProgramsBooked": (programs.length > 0),
                "hasClients": (clientsActive.length > 0),
                "certificationCompleted": validation.completedCertifications !== null,
            })
        } else {
            await this.setState({
                "hasClients": (clientsActive.length > 0)
            })
        }
        const allTasks = await this.props.tryGetAllTasks();
        const completedTasks = allTasks.filter(a => {return(a.course.isPracticeSelfServe && a.isCompleted)})
        const activeTasks = allTasks.filter(a => {return(a.course.isPracticeSelfServe && !a.isCompleted)})
        await this.setState({
            "completedTasks": completedTasks,
            "activeTasks": activeTasks,
            "isLoading": false
        })
    }

    becomeMember = async () => {
        // await this.props.trackTasksBecomeMember();
        this.props.history.push('/my-certificates');
    }
    render() {
        if(this.state.isLoading) {
            return(
                <DashboardContainer>
                    <Header>
                        <FontTitle24>Tasks</FontTitle24>
                        {/*<FontBody14><ViewAll to={"/my-tasks"}>view all</ViewAll></FontBody14>*/}
                    </Header>
                </DashboardContainer>
            )
        } else {
            return(
                <>
                    <DashboardContainer>
                        <Header>
                            <FontTitle24>Tasks</FontTitle24>
                            {/*<FontBody14><ViewAll to={"/my-tasks"}>view all</ViewAll></FontBody14>*/}
                        </Header>
                        {this.state.activeTasks.map((t, tIndex) => (
                            <FlexRow key={tIndex} onClick={() => this.props.history.push("/my-tasks/course/"+t.course.id+"/"+(t.course.isVideo ? "video" : "quiz"))}>
                                <Item>
                                    <Icon image={images.checkBoxEmpty}/>
                                    <Strikethrough strike={false}>Finish {t.course.title}</Strikethrough>
                                </Item>
                                <IconPointer image={images.chevronRightDark}/>
                            </FlexRow>
                        ))}
                        {this.props.hideCert
                            ? <>
                                <FlexRow onClick={() => this.props.history.push('/my-certificates/my-trainings')} >
                                    <Item>
                                        <Icon image={images.checkBoxEmpty}/>
                                        <Strikethrough strike={false}>{'Watch On-Demand Trainings'}</Strikethrough>
                                    </Item>
                                    <IconPointer image={images.chevronRightDark}/>
                                </FlexRow>
                                <FlexRow onClick={() => this.props.history.push('/my-certificates/my-coaches')} >
                                    <Item>
                                        <Icon image={images.checkBoxEmpty}/>
                                        <Strikethrough strike={false}>{'Book 1-on-1 Advisor Coaching'}</Strikethrough>
                                    </Item>
                                    <IconPointer image={images.chevronRightDark}/>
                                </FlexRow>
                            </>
                            : <>{!this.state.certificationCompleted &&
                                <FlexRow onClick={this.becomeMember} >
                                    <Item>
                                        {/*<ToolTipCreator*/}
                                        {/*    text={"Get certified"}*/}
                                        {/*    show={true}*/}
                                        {/*    currentStep={1}*/}
                                        {/*/>*/}
                                        <Icon image={images.checkBoxEmpty}/>
                                        <Strikethrough strike={false}>{'Start earning your certificate'}</Strikethrough>
                                    </Item>
                                    <IconPointer image={images.chevronRightDark}/>
                                </FlexRow>
                            }</>
                        }
                        {(!this.state.hasMeetingsBooked && this.state.certificationCompleted) &&
                            <FlexRow onClick={() => this.props.history.push('/my-certificates/my-coaches')} >
                                <Item>
                                    <Icon image={this.state.hasMeetingsBooked ? images.checkBoxChecked : images.checkBoxEmpty}/>
                                    {/*<Strikethrough strike={this.state.hasMeetingsBooked}>{this.state.hasMeetingsCompleted ? 'Book your next coaching appointment' : 'Book your first coaching appointment'}</Strikethrough>*/}
                                    <Strikethrough strike={this.state.hasMeetingsBooked}>Book a coaching appointment{/* for CE credits*/}</Strikethrough>
                                </Item>
                                <IconPointer image={images.chevronRightDark}/>
                            </FlexRow>
                        }
                        {/*Turned off as trainings are all video-based now*/}
                        {/*{(!this.state.hasProgramsBooked && this.state.activeTasks.length === 0) &&*/}
                        {/*    <FlexRow onClick={() => this.props.history.push('/my-certificates/my-trainings')} >*/}
                        {/*        <Item>*/}
                        {/*            <Icon image={this.state.hasProgramsBooked ? images.checkBoxChecked : images.checkBoxEmpty}/>*/}
                        {/*            <Strikethrough strike={this.state.hasProgramsBooked}>{'Book a training for CE credits'}</Strikethrough>*/}
                        {/*        </Item>*/}
                        {/*        <IconPointer image={images.chevronRightDark}/>*/}
                        {/*    </FlexRow>*/}
                        {/*}*/}
                        {/*{!this.state.hasClients &&*/}
                        {/*    <FlexRow onClick={() => this.props.history.push('/my-clients')} >*/}
                        {/*        <Item>*/}
                        {/*            <Icon image={this.state.hasClients ? images.checkBoxChecked : images.checkBoxEmpty}/>*/}
                        {/*            <Strikethrough strike={this.state.hasClients}>{'Deliver coaching to your clients in need'}</Strikethrough>*/}
                        {/*        </Item>*/}
                        {/*        <IconPointer image={images.chevronRightDark}/>*/}
                        {/*    </FlexRow>*/}
                        {/*}*/}

                    </DashboardContainer>
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    isLily: state.common.user.get("isLily"),
    hideCert: state.common.user.get("directoryListings") === Map() ? false : state.common.user.get("directoryListings").get("practiceManagementUntil") !== null
});

const mapDispatchToProps = dispatch => ({
    tryGetCoachingAppointments: () => dispatch(tryGetCoachingAppointments()),
    tryGetUpcomingTrainingsRegisteredFor: () => dispatch(tryGetUpcomingTrainingsRegisteredFor()),
    tryGetAllClients: () => dispatch(tryGetAllClients()),
    tryGetAllTasks: () => dispatch(tryGetAllTasks()),
    certificationValidation: () => dispatch(certificationValidation()),
    trackTasksBecomeMember: () => dispatch(trackTasksBecomeMember())
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FirmDashboardTasks));