import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import { CloudUploadOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import { colors } from '../../../components/colors';
import { IconButton, SubmitButtonMyMoney } from '../../../components/inputs';
import { MyMoneyCard, MyMoneyCardTitle } from '../../../components/cards';
import {
    MyMoneyTab,
    MyMoneyTabHeading,
    MyMoneyTabDescrip,
    MyMoneySnapshotCol,
    MyMoneySectionTitle,
    DeleteButton,
    LineItemList,
    LineItem,
    LineItemNameInput,
    LineItemValueInput,
    LineItemCreditScoreInput,
    LineItemDateInput,
    LineItemTotalLabel,
    LineItemTotalValue,
    GetCoachCTA,
} from '../components/mymoney';

import {
    setMymoneySnapshotAddlineitem,
    setMymoneySnapshotUpdatelineitem,
    setMymoneySnapshotDeletelineitem,
    setMymoneySnapshotUpdatecreditscore,
} from '../store/mymoney';
import {
    tryUpdateMyMoneyBalanceSheet,
    tryGetMyMoneyBalanceSheet
} from "../middleware/mymoney";
import { FontSubtitle21 } from '../../../components/fonts';
import { TextAlignCenter } from '../../../components/layout';

class MoneySnapshot extends Component {
    state = {
        focusedLineitem: null,
        liabilities: 0,
        assets: 0,
        total: 0,
        isLoading: true,
        savedTime: moment(),
        savedTimeFromNow: '',
        unsavedChanges: null,
        hasCoach: true,
    };

    timerID = 0;

    componentDidMount() {
        return this.init()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.client !== this.props.client) {
            return this.init()
        }
        if (prevState.unsavedChanges !== this.state.unsavedChanges) {
            this.timeoutID = setTimeout(this.handleSave, 1500);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
        this.timerID = 0;
        clearTimeout(this.timeoutID);
    }

    init = async () => {
        const empty ={};
        const balanceSheet = await this.props.tryGetMyMoneyBalanceSheet();
        console.log(balanceSheet)

        // if (balanceSheet.hasOwnProperty('error') || balanceSheet.balanceSheet === null ||balanceSheet.balanceSheet === undefined || balanceSheet.balanceSheet === empty) {
        //     return this.setState({hasCoach: false, isLoading: false});
        // }
        
            const liabilities = this.props.liabilities.map(a => {return a.lineItems}).map(a => {return a.map(b => {return parseInt(b.amount)}).reduce((a, b) => a + b, 0)}).reduce((a, b) => a + b, 0);
            const assets = this.props.assets.map(a => {return a.lineItems}).map(a => {return a.map(b => {return parseInt(b.amount)}).reduce((a, b) => a + b, 0)}).reduce((a, b) => a + b, 0);
            const total = assets - liabilities;
            
            return this.setState({"liabilities": liabilities, "assets": assets, "total": total, "isLoading": false})
    }

    refreshTimer = async () => {
        await this.setState({
            savedTimeFromNow: 'Autosaved ' + this.state.savedTime.fromNow(),
        });
    }

    handleSave = async (e) => {
        this.timerID && await clearInterval(this.timerID);
        await this.props.tryUpdateMyMoneyBalanceSheet();
        await this.setState({savedTime: moment(), unsavedChanges: false})
        this.refreshTimer();
        this.timerID = setInterval(() => this.refreshTimer(), 45000);
    }
    handleAddLineItem(catType, cid, e) {
        this.props.setMymoneySnapshotAddlineitem(catType, cid);
            this.setState({unsavedChanges: true,})
    }
    handleLineItemUpdate(catType, cid, i, field, e) {
        this.props.setMymoneySnapshotUpdatelineitem(catType, cid, i, field, field === 'amount' ? parseFloat(e.currentTarget.value) : e.currentTarget.value);
        this.setState({unsavedChanges: true,})
    }
    handleLineItemFocus(cid, lid) {
        this.setState({ focusedLineitem: `${cid},${lid}` });
    }
    handleLineItemBlur() {
        this.setState({ focusedLineitem: null });
    }
    handleLineItemDelete(catType, cid, i) {
        this.setState({ focusedLineitem: null });
        this.props.setMymoneySnapshotDeletelineitem(catType, cid, i);
        this.setState({unsavedChanges: true,})
    }
    handleCreditScoreUpdate(field, e) {
        this.props.setMymoneySnapshotUpdatecreditscore(field, field === 'score' ? parseInt(e.currentTarget.value) : e.currentTarget.value);
        this.setState({unsavedChanges: true,})
    }

    renderCard({ cid='CATEGORY_ID', title='New Category', lineItems=[], catType='assets', color=colors.action100, backgroundColor }) {
        return (
            <MyMoneyCard key={`Cat-${cid}`} color={color} useNewDesign={this.props.useNewDesign} backgroundColor={this.props.useNewDesign ? (backgroundColor ? backgroundColor : colors.primary100) : null}>
                <MyMoneyCardTitle useNewDesign={this.props.useNewDesign}>
                    <span>{title}</span>
                    <IconButton
                        backgroundColor={colors.action100}
                        iconComponent={PlusOutlined}
                        color={colors.white}
                        label="Add Line Item"
                        style={{ margin: '-3px 0', flex: 0 }}
                        onClick={this.handleAddLineItem.bind(this, catType, cid)}
                    />
                </MyMoneyCardTitle>
                <LineItemList>
                    {lineItems.map((line, i) => (
                        <LineItem key={`LI-${line.lid}`}>
                            <LineItemNameInput
                                defaultValue={line.title}
                                onChange={this.handleLineItemUpdate.bind(this, catType, cid, i, 'title')}
                                onFocus={this.handleLineItemFocus.bind(this, cid, line.lid)}
                                onBlur={this.handleLineItemBlur.bind(this)}
                                placeholder={'New '+title.substr(0,title.length-1) }
                            />
                            <LineItemValueInput
                                defaultValue={line.amount}
                                onChange={this.handleLineItemUpdate.bind(this, catType, cid, i, 'amount')}
                                onFocus={this.handleLineItemFocus.bind(this, cid, line.lid)}
                                onBlur={this.handleLineItemBlur.bind(this)}
                                min="0"
                            />
                            {this.state.focusedLineitem === `${cid},${line.lid}` && <DeleteButton onMouseDown={this.handleLineItemDelete.bind(this, catType, cid, i)} />}
                        </LineItem>
                    ))}
                </LineItemList>
                <LineItem>
                    <LineItemTotalLabel>Total</LineItemTotalLabel>
                    <LineItemTotalValue>{'$' + lineItems.reduce(((total, line) => total + line.amount), 0).toLocaleString()}</LineItemTotalValue>
                </LineItem>
            </MyMoneyCard>
        );
    }
    renderTotal({ cid='TOTAL', lineItems=[], color='#000000' }) {
        return (
            <MyMoneyCard key={`Cat-${cid}`} color={color} useNewDesign={this.props.useNewDesign} backgroundColor={this.props.useNewDesign ? colors.backgroundColor6 : null}>
                <MyMoneyCardTitle useNewDesign={this.props.useNewDesign}>
                    <span>{"Net Worth"}</span>
                </MyMoneyCardTitle>
                <LineItemList>
                    {lineItems.map((line, i) => (
                        <LineItem key={`LI-${cid}-${line.lid}`}>
                            <LineItemNameInput
                                defaultValue={line.title}
                                placeholder={''}
                                disabled={true}
                            />
                            <LineItemValueInput
                                defaultValue={line.amount}
                                disabled={true}
                            />
                        </LineItem>
                    ))}
                </LineItemList>
                <LineItem>
                    <LineItemTotalLabel>Total</LineItemTotalLabel>
                    <LineItemTotalValue>{'$' + this.state.total.toLocaleString()}</LineItemTotalValue>
                </LineItem>
            </MyMoneyCard>
        );
    }
    render() {
        if (this.state.isLoading) {
            return null;
        } else if (!this.state.hasCoach) {
            return <GetCoachCTA history={this.props.history}/>
        } else {
            const totals = [
                {"lid": 1, "title": "Total Assets", "amount": this.state.assets.toFixed(0)},
                {"lid": 2, "title": "Total Liabilities", "amount": this.state.liabilities.toFixed(0)},
            ]
            return (
                <MyMoneyTab>
                    {this.props.useNewDesign && <TextAlignCenter>
                        {/*<FontSubtitle21>Money Snapshot</FontSubtitle21>*/}
                        {/*<br/>*/}
                    </TextAlignCenter>}
                    <MyMoneyTabHeading>Clarity brings confidence!</MyMoneyTabHeading>
                    <MyMoneyTabDescrip>To get organized and calculate your net worth, the Willow My Money Snapshot keeps your financial life in order.</MyMoneyTabDescrip>
                    <MyMoneySnapshotCol>
                        <MyMoneySectionTitle center={true}>
                            {/* <SubmitButtonMyMoney
                                iconComponent={CloudUploadOutlined}
                                backgroundColor={colors.progress100}
                                color={colors.white}
                                label={this.props.unsaved ? 'Save' : 'Saved'}
                                onClick={this.handleSave.bind(this)}
                                disabled={!this.props.unsaved}
                            /> */}
                            {this.state.unsavedChanges !== null && <span>{this.state.savedTimeFromNow}</span>}
                        </MyMoneySectionTitle>
                        <MyMoneySectionTitle padded={true}>
                            <span>What I Have</span>
                        </MyMoneySectionTitle>
                        {this.props.assets.map((cat, i) => this.renderCard.call(this, {...cat, catType: 'assets', color: '#38726c', backgroundColor: colors.backgroundColor3 }))}
                    </MyMoneySnapshotCol>
                    <MyMoneySnapshotCol>
                        <MyMoneySectionTitle padded={true}>
                            <span>What I Owe</span>
                        </MyMoneySectionTitle>
                        {this.props.liabilities.map((cat, i) => this.renderCard.call(this, {...cat, catType: 'liabilities', color: '#c14953' }))}
                        <MyMoneySectionTitle padded={true}>
                            <span>Summary</span>
                        </MyMoneySectionTitle>
                        <MyMoneyCard useNewDesign={this.props.useNewDesign} backgroundColor={this.props.useNewDesign ? colors.backgroundColor6 : null}>
                            <MyMoneyCardTitle useNewDesign={this.props.useNewDesign}>
                                <span>My Credit Score</span>
                            </MyMoneyCardTitle>
                            <LineItem>
                                <LineItemCreditScoreInput
                                    defaultValue={this.props.credtScore.score}
                                    onChange={this.handleCreditScoreUpdate.bind(this, 'score')}
                                    label="My Score"
                                />
                                <LineItemDateInput
                                    defaultValue={this.props.credtScore.lastChecked}
                                    onChange={this.handleCreditScoreUpdate.bind(this, 'lastChecked')}
                                    label="Last Checked"
                                />
                            </LineItem>
                        </MyMoneyCard>
                        {this.renderTotal.call(this, { lineItems: totals })}
                    </MyMoneySnapshotCol>
                </MyMoneyTab>

            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        assets: state.common.mymoney.getIn([ 'snapshot', 'assets' ]).toJS(),
        liabilities: state.common.mymoney.getIn([ 'snapshot', 'liabilities' ]).toJS(),
        credtScore: state.common.mymoney.getIn([ 'snapshot', 'credtScore' ]).toJS(),
        unsaved: state.common.mymoney.getIn([ 'snapshot', 'unsaved' ]),
        client: state.enterprise.clientsDetail.get("client")
    };
};
const mapDispatchToProps = dispatch => ({
    setMymoneySnapshotAddlineitem: (catType, cid) => dispatch(setMymoneySnapshotAddlineitem(catType, cid)),
    setMymoneySnapshotUpdatelineitem: (catType, cid, i, field, val) => dispatch(setMymoneySnapshotUpdatelineitem(catType, cid, i, field, val)),
    setMymoneySnapshotDeletelineitem: (catType, cid, i) => dispatch(setMymoneySnapshotDeletelineitem(catType, cid, i)),
    setMymoneySnapshotUpdatecreditscore: (field, val) => dispatch(setMymoneySnapshotUpdatecreditscore(field, val)),
    tryUpdateMyMoneyBalanceSheet:  () => dispatch(tryUpdateMyMoneyBalanceSheet()),
    tryGetMyMoneyBalanceSheet: () => dispatch(tryGetMyMoneyBalanceSheet())
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MoneySnapshot));