import React from "react";
import styled from "styled-components";

/* Components */
import {FontBody12, FontHeader16} from "../../common/components/fonts";
import {ButtonInactive, ButtonPrimary} from "../../common/components/buttons";
import {Center, Gray, Green} from '../components/contactcoaching';

const FitContent = styled.div`
    width: fit-content;
    ${props => props.fullWidth !== true} {
        padding-top: 45px;
        width: 100%;
        & > div {
            width: calc(100% - 46px);
        }   
    }
`;

export class ChangeSessionsUnlimited extends React.Component {
    state={"count": 0, "isSaving": false, "isSaved": false, "isSavedOnce": false};

    componentDidMount() {
        this.setState({"count": 100000})
    };

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.selected === "Limited" && this.props.selected === "Infinite") {
            this.setState({"isSavedOnce": false});
        }
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    save = async (coachId, count, coachName) => {
        if(this.props.alwaysShowSave) {
            await this.props.updateSessions(coachId, count, coachName);
            this.props.closeAction();
        } else {
            console.log(count);
            await this.setState({'isSaving': true, 'isSaved': false});
            await this.props.updateSessions(coachId, count, coachName);
            this.setState({'isSaving': false, 'isSaved': true});
            this.timeout = setTimeout(() => {
                this.setState({'isSaving': false, 'isSaved': false, 'isSavedOnce': true});
                this.props.closeAction();
            }, this.props.saveTimer);
        }
    };

    render() {
        return(
            <>
                <Center>
                    {((!this.state.isSaving && !this.state.isSaved && !this.state.isSavedOnce) || this.props.alwaysShowSave) &&
                    <>
                        <FitContent fullWidth={this.props.saveButtonFullWidth} onClick={() => {return this.save(this.props.expertId, this.state.count, this.props.expertName)}}>
                            <ButtonPrimary canSubmit={false} label={this.props.saveButtonLabel} />
                        </FitContent>
                        {(this.props.notifyFirstName !== null && this.props.showNoticeCoach) &&
                            <>
                                <br />
                                <Gray><FontBody12>{"Only "+this.props.notifyFirstName+" will be notified"}</FontBody12></Gray>
                            </>
                        }
                    </>
                    }
                    {(this.state.isSaving && !this.state.isSaved && !this.state.isSavedOnce) &&
                        <FitContent fullWidth={this.props.saveButtonFullWidth}><br/><ButtonInactive canSubmit={false} label={"Saving"} /></FitContent>
                    }
                    {(!this.state.isSaving && this.state.isSaved && !this.state.isSavedOnce) &&
                        <><br/><Green><FontHeader16>Saved!</FontHeader16></Green></>
                    }
                </Center>
            </>
        )
    }
}
