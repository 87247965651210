import React from 'react';
import styled from "styled-components";

import {FontBody18, FontTitle24} from "../../../common/components/fonts";

const Image = styled.img`
    height: 150px;
    width: 100%;
    vertical-align: top;
    object-fit: cover;
    object-position: center center;
    margin-bottom: 10px;
`;

const Title = styled(FontTitle24)`
    margin-bottom: 10px;
`;

const Body = styled(FontBody18)`
    margin-bottom: 10px;
`;

const ElementImageBox = ({title, body, style, imageUrl}) => (
    <>
        {imageUrl !== null && imageUrl !== undefined && <Image style={style.image} src={imageUrl} alt={""}/>}
        {title !== null && <Title style={style.title}>{title}</Title>}
        {body !== null && <Body style={style.body}>{body}</Body>}
    </>
);

export default ElementImageBox