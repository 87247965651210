import {v4 as uuid4} from "uuid";

export default [
    {
        "pageNum": 0,
        "header": {
            "title": "Help your financial coach prepare",
        },
        "actions": {
            "pageNumVisible": true,
            "backLabel": "",
            "nextLabel": "Continue",
            "canContinueAutomatically": false,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "checkBox",
                "subtitle": "What Life Journey are you on?",
                "disclosure": "(check all that apply)",
                "warning": "Required",
                "maxCheckable": null,
                "isValid": false,
                "isRequired": true,
                "options": [
                    {"id": 1, "active": false, "label": "I'm going through a divorce"},
                    {"id": 2, "active": false, "label": "I'm a widow"},
                    {"id": 3, "active": false, "label": "I'm a caregiver for aging loved ones"},
                    {"id": 4, "active": false, "label": "I'm navigating a career change (exit or re-entry)"},
                    {"id": 5, "active": false, "label": "I'm in charge of my family finances"},
                    {"id": 6, "active": false, "label": "I'm a single parent"},
                    {"id": 7, "active": false, "label": "I'm part of a non-traditional relationship"},
                    {"id": 8, "active": false, "label": "I'm still starting out financially"},
                    {"id": 9, "active": false, "label": "I'm an entrepreneur"},
                    {"id": 10, "active": false, "label": "I'm navigating career challenges as an executive"},
                    {"id": 11, "active": false, "label": "Other"}
                ],
                "style": {
                    "subtitle": {},
                    "disclosure": {},
                    "warning": {},
                    "options": {},
                    "optionLabel": {},
                    "optionIcon": {}
                }
            }
        ]
    },
    {
        "pageNum": 1,
        "header": {
            "title": "Help your financial coach prepare",
        },
        "actions": {
            "pageNumVisible": true,
            "backLabel": "Back",
            "nextLabel": "Continue",
            "canContinueAutomatically": false,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "checkBox",
                "subtitle": " What are your Top 3 Financial Coaching goals?  ",
                "disclosure": "(Please only check your top 3)",
                "warning": "Required",
                "maxCheckable": 3,
                "isValid": false,
                "isRequired": true,
                "options": [
                    {"id": 1, "active": false, "label": "Financial organization"},
                    {"id": 2, "active": false, "label": "Cash flow management/budgeting"},
                    {"id": 3, "active": false, "label": "Debt management"},
                    {"id": 4, "active": false, "label": "Managing credit"},
                    {"id": 5, "active": false, "label": "Bill pay"},
                    {"id": 6, "active": false, "label": "Easing financial stress/anxiety"},
                    {"id": 7, "active": false, "label": "Insurance"},
                    {"id": 8, "active": false, "label": "Career decisions coaching/salary negotiations"},
                    {"id": 9, "active": false, "label": "Workplace benefits"},
                    {"id": 10, "active": false, "label": "Children expense planning"},
                    {"id": 11, "active": false, "label": "Education planning (i.e. college planning)"},
                    {"id": 12, "active": false, "label": "Relationships & marriage preparation (i.e. prenups, combining finances)"},
                    {"id": 13, "active": false, "label": "Fertility planning"},
                    {"id": 14, "active": false, "label": "Estate planning"},
                    {"id": 15, "active": false, "label": "Real estate decisions"},
                    {"id": 16, "active": false, "label": "Finding employment"},
                    {"id": 17, "active": false, "label": "Other"},
                ],
                "style": {
                    "subtitle": {},
                    "disclosure": {},
                    "warning": {},
                    "options": {},
                    "optionLabel": {},
                    "optionIcon": {}
                }
            }
        ]
    },
    {
        "pageNum": 2,
        "header": {
            "title": "Help your financial coach prepare",
        },
        "actions": {
            "pageNumVisible": true,
            "backLabel": "Back",
            "nextLabel": "Continue",
            "canContinueAutomatically": false,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "radioBox",
                "subtitle": "Do you have a preferred gender for your Financial Coach?",
                "disclosure": "", //"(select one)",
                "warning": "Required",
                "isRequired": true,
                "maxCheckable": 1,
                "isValid": false,
                "options": [
                    {"id": 1, "active": false, "label": "Female"},
                    {"id": 2, "active": false, "label": "Male"},
                    {"id": 3, "active": false, "label": "No Preference"},
                ],
                "style": {
                    "subtitle": {},
                    "disclosure": {},
                    "warning": {},
                    "options": {},
                    "optionLabel": {},
                    "optionIcon": {}
                }
            }
        ]
    },
    {
        "pageNum": 3,
        "header": {
            "title": "Help your financial coach prepare",
        },
        "actions": {
            "pageNumVisible": true,
            "backLabel": "Back",
            "nextLabel": "Continue",
            "canContinueAutomatically": false,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "radioBox",
                "subtitle": "What is your current employment status? ",
                "disclosure": "", //"(select one)",
                "warning": "Required",
                "isRequired": true,
                "maxCheckable": 1,
                "isValid": false,
                "options": [
                    {"id": 1, "active": false, "label": "I’m employed full-time"},
                    {"id": 2, "active": false, "label": "I’m employed part-time"},
                    {"id": 3, "active": false, "label": "I’m part of the gig economy (freelancer, contractor)"},
                    {"id": 4, "active": false, "label": "I’m in between jobs at the moment"},
                    {"id": 5, "active": false, "label": "I’m retired"},
                    {"id": 6, "active": false, "label": "I’m semi-retired"},
                    {"id": 7, "active": false, "label": "I’m a full-time homemaker"},
                ],
                "style": {
                    "subtitle": {},
                    "disclosure": {},
                    "warning": {},
                    "options": {},
                    "optionLabel": {},
                    "optionIcon": {}
                }
            }
        ]
    },
    {
        "pageNum": 4,
        "header": {
            "title": "Help your financial coach prepare",
        },
        "actions": {
            "pageNumVisible": true,
            "backLabel": "Back",
            "nextLabel": "Continue",
            "canContinueAutomatically": false,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "radioBox",
                "subtitle": "Which coach persona profile resonates most with you?",
                "disclosure": "", //"(select one)",
                "warning": "Required",
                "isRequired": true,
                "maxCheckable": 1,
                "isValid": false,
                "options": [
                    {"id": 1, "active": false, "label": "Emphasizes the emotional aspects of personal finance"},
                    {"id": 2, "active": false, "label": "Integrates spirituality into their coaching"},
                    {"id": 3, "active": false, "label": "Has a no-nonsense approach"},
                    {"id": 4, "active": false, "label": "Knows what it’s like to be part of a minority community"},
                    {"id": 5, "active": false, "label": "Understands the life of a military family"},
                ],
                "style": {
                    "subtitle": {},
                    "disclosure": {},
                    "warning": {},
                    "options": {},
                    "optionLabel": {},
                    "optionIcon": {}
                }
            }
        ]
    },
    {
        "pageNum": 5,
        "header": {
            "title": "Help your financial coach prepare",
        },
        "actions": {
            "pageNumVisible": true,
            "backLabel": "Back",
            "nextLabel": "Continue",
            "canContinueAutomatically": false,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "radioBox",
                "subtitle": "What is your Financial Personality Type?",
                "disclosure": "", //"(select one)",
                "warning": "Required",
                "isRequired": true,
                "maxCheckable": 1,
                "isValid": false,
                "options": [
                    {"id": 1, "active": false, "label": "Security Keeper"},
                    {"id": 2, "active": false, "label": "Relationship Protector"},
                    {"id": 3, "active": false, "label": "Opportunity Seeker"},
                    {"id": 4, "active": false, "label": "Freedom Finder"},
                    {"id": 5, "active": false, "label": "Blended"},
                ],
                "style": {
                    "subtitle": {},
                    "disclosure": {},
                    "warning": {},
                    "options": {},
                    "optionLabel": {},
                    "optionIcon": {}
                }
            }
        ]
    },
    {
        "pageNum": 6,
        "header": {
            "title": "Help your financial coach prepare",
        },
        "actions": {
            "pageNumVisible": true,
            "backLabel": "Back",
            "nextLabel": "Finish",
            "canContinueAutomatically": true,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "textArea",
                "subtitle": "If your company banks with Traditions and is sponsoring Willow, please provide your company's name. (Optional)",
                "placeholder": "Company name",
                "text": "",
                "isValid": false,
                "isRequired": false,
                "minLength": 0,
                "minRows": 1,
                "style": {
                    "subtitle": {},
                    "textArea": {},
                    "warning": {},
                }
            }
        ]
    },
];