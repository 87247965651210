import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

/* Components */
import {InputSectionSelect, MoneyInputWhiteV2} from '../../../common/components/inputs';
import {ContactBottom, ContactContent, Level1, Level2, Level3} from "../components/contactcontainer";
import ContactTop from "../components/contacttop";
import ContactButtons from '../components/contactbuttons';
import {colors} from "../../../common/components/colors";
import {FontBody21, FontBody21To16, FontHeader24} from "../../../common/components/fonts";

const Subtitle = styled.ul`
  color: ${colors.primary70};
  padding-top: 10px;
  text-align: left;
  margin: 0 10px;
`;
const Title = styled(FontHeader24)`
  text-align: left;
  margin-top: 20px !important;
`;
const Instructions = styled.div`
  margin: 20px auto 0 auto !important;
`;
const StyledMoneyInputWhite = styled.div`
  width: 100% !important;
  & > div {
    margin: 0;
  }
  #invest {
    font-size: 20px;
    line-height: 1.5;
    @media screen and (max-width: 748px) {
      font-size: 16px;
    }
  }
`;

ContactPage4.defaultProps = {
    show:  false,
    setStep:  () => {},
    setAssets:  () => {},
}

export default function ContactPage4(props) {
    const show = props.show;
    const setStep = props.setStep;
    const setAssets = props.setAssets;
    const [number, setNumber] = useState(0);
    const [numberValid, setNumberValid] = useState(false);

    // const setValidateNumber = (number) => {
    //     let num = Number(number.toString().replace(/[^a-zA-Z0-9 ]/g, ''));
    //     setNumber(num)
    //     setNumberValid(num > 0)
    // }
    //
    // useEffect(() => {
    //     if(numberValid) {
    //         setAssets(number)
    //     }
    // }, [numberValid])

    return (
        <Level1 toggle={show} >
            <Level2 animate={show}>
                <Level3>
                    <ContactTop title={"What are your investment assets?"} />
                    <ContactBottom>
                        <ContactContent>
                            <StyledMoneyInputWhite>
                                <InputSectionSelect>
                                    <select
                                        name={"assets"}
                                        id={"invest"}
                                        onChange={(e) => {setAssets(e.target.value)}}
                                        defaultValue={"$500,000 - $1,000,000"}
                                    >
                                        <option value={"$0 - $50,000"}>$0 - $50,000</option>
                                        <option value={"$50,000 - $250,000"}>$50,000 - $250,000</option>
                                        <option value={"$250,000 - $500,000"}>$250,000 - $500,000</option>
                                        <option value={"$500,000 - $1,000,000"}>$500,000 - $1,000,000</option>
                                        <option value={"$1,000,000 - $5,000,000"}>$1,000,000 - $5,000,000</option>
                                        <option value={"$5,000,000 - $10,000,000"}>$5,000,000 - $10,000,000</option>
                                        <option value={"$10,000,000+"}>$10,000,000+</option>
                                    </select>
                                </InputSectionSelect>
                                {/*<MoneyInputWhiteV2  // make this a dropdown*/}
                                {/*    id={"invest"}*/}
                                {/*    value={number}*/}
                                {/*    valid={numberValid}*/}
                                {/*    onChange={e => {setValidateNumber(e.target.value)}}*/}
                                {/*    size={50}*/}
                                {/*/>*/}
                                <Instructions>
                                    <Title>Include:</Title>
                                    <Subtitle>
                                        <li><FontBody21To16>Checking & savings accounts</FontBody21To16></li>
                                        <li><FontBody21To16>Taxable investment & IRA accounts</FontBody21To16></li>
                                    </Subtitle>
                                    <Title>Exclude:</Title>
                                    <Subtitle>
                                        <li><FontBody21To16>401(k) and pension accounts</FontBody21To16></li>
                                        <li><FontBody21To16>Your primary home & mortgage</FontBody21To16></li>
                                    </Subtitle>
                                    {/*    Show a badge that shows this is trustworthy*/}
                                </Instructions>
                            </StyledMoneyInputWhite>
                        </ContactContent>
                        <ContactButtons disabledNext={false} setStep={setStep} />
                    </ContactBottom>
                </Level3>
            </Level2>
        </Level1>
    );
}