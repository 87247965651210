import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import styled from "styled-components";

/* Components */
import {TextInput, UploadAvatar} from "../../../../common/components/inputs";
import {FontTitle18, FontBody16, FontSubtitle40} from "../../../../common/components/fonts";
import {ButtonInactive, ButtonPrimary} from "../../../../common/components/buttons";
import {colors} from "../../../../common/components/colors";
import {LogoLoading} from "../../../../common/components/loading";
import {images} from "../../../../common/components/images";
import {setLoggedIn} from '../../../../routes/store/user';

/* Middleware */
import {tryUpdateSettingsPersonalInfo, tryGetSettingsPersonalInfo, tryUpdateExpertProfile, tryGetExpertProfile} from '../middleware/settings';

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;
const Form = styled.form`
    text-align: left;
`;
const SaveStatus = styled.div`
    display: flex;
    flex-direction: row;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: right;
    align-items: center;
    margin-top: 40px;
`;
const ErrorText = styled(FontTitle18)`
    text-align: left;
    flex-grow: 1;
    color: ${colors.alert100};
`;

const inputsContainersStyles = {
    "margin": "0px 0px 30px 0px"
};
const inputsStyles = {
    "border": "none",
    "borderRadius": "40px",
    "lineHeight": "36px",
    "backgroundColor": colors.white,
    "boxShadow": "0 3px 10px 0 rgba(0, 0, 0, 0.15)",
    "fontSize": "24px"
};
const PhotoSection = styled.div`
    margin-bottom: 30px;
`;
const PhotoEdit = styled.div`
    & > div {
      margin: 0;
    }
  & canvas {
    border-radius: 30px; 
  }
`;
const PhotoTitle = styled(FontBody16)`
    color: ${colors.secondary100}
`;
const Image = styled.img`
    width: 240px;
    height: 240px;
    object-fit: cover;
    object-position: 0 0;
    border-radius: 240px;
    cursor: pointer;
    position: relative;
`;
const Relative = styled.div`
  position: relative;
`;
const Title = styled(FontSubtitle40)`
  margin-bottom: 60px;
  margin-top: -30px;
`;
const EditIcon = styled.img`
    position: absolute;
    z-index:11;
    top: 90px;
    left: 90px;
    height: 60px;
    width: 60px;
    cursor: pointer;
    filter: brightness(0) invert(1);
`;

const SubmitInputWithValidation = ({ isChanged, isValid, isSaving, label }) => {
    if(isChanged && isValid) {
        if(isSaving) {
            return (<ButtonInactive canSubmit={true} label={"Saving"} />)
        } else {
            return (<ButtonPrimary canSubmit={true} label={label}/>)
        }
    } else {
        return(<ButtonInactive canSubmit={true} label={label}/>)
    }
};

class FirmSettingsProfileEditPreview extends React.Component {
    static defaultProps = {
        onSave: () => {},
        lastUpdated: null
    }

    state = {
        photo: '', photoComponent: '', photoBlob: '', photoPrevious: "", photoComponentPrevious: "", photoChanged: false,
        // bio: "", // for some reason required by api
        editPhoto: false,
        first: '', firstValid: true,
        last: '', lastValid: true,
        businessTitle: '',
        isSaving: false,
        isSaved: false,
        error: '',
        isChanged: false,
        isValid: true,
        isLoading: true,
        showPhotoEditIcon: false
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const user = await this.props.tryGetSettingsPersonalInfo();
        const profile = await this.props.tryGetExpertProfile();
        if(user.last && user.last.includes(profile.businessTitle)) {user.last = user.last.replaceAll(profile.businessTitle, '').replaceAll(',','');}

        this.setState({
            ...user,
            "photo": profile.photo,
            "photoComponent": <UploadAvatar photo={profile.photo} onChange={this.handleChangePhoto} />,
            "photoPrevious": profile.photo,
            "photoComponentPrevious": <UploadAvatar photo={profile.photo} onChange={this.handleChangePhoto} />,
            "photoBlob": "",
            // "bio": profile.bio,
            "isLoading": false
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.lastUpdated !== this.props.lastUpdated && this.props.lastUpdated !== null) {
            this.init();
        }
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    handleChangeFirst = async e => {
        await this.setState({'first': e.target.value, 'firstValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''), 'isChanged': true});
        this.checkValid();
    };

    handleChangeLast = async e => {
        await this.setState({'last': e.target.value, 'lastValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''), 'isChanged': true});
        this.checkValid();
    };

    handleChangePhoto = async e => {
        this.setState({'photoChanged': true, 'photoBlob': e, 'isChanged': true});
    };

    handleChangeEditPhoto = async e => {
        this.setState({'editPhoto': true});
    };

    checkValid = () => {
        if(this.state.firstValid && this.state.lastValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            this.setState({'isSaving': true});
            const resultName = await this.props.tryUpdateSettingsPersonalInfo({...this.state});
            let error = resultName.error;
            if(this.state.photoChanged) {
                const photo = {
                    // "bio": this.state.bio,
                    "photoBlob": this.state.photoBlob,
                };
                const resultPhoto = await this.props.tryUpdateExpertProfile(photo);
                error = resultPhoto.error !== null ? resultPhoto.error : resultName.error;
            }
            this.setState({
                'error': error,
                'isSaving': false,
                'isSaved': true,
                'isChanged': false,
                'editPhoto': false
            });
            this.timeout = setTimeout(async () => {
                if(error !== null) {
                    this.init();
                } else {
                    this.props.setLoggedIn({...this.props.user.toJS(), "photo": this.state.photo, "first": this.state.first, "last": this.state.last})
                    this.props.onClose();
                    this.props.onSave();
                }
                this.setState({'error': null, 'isSaved': false});
            }, 2000);
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <Form method={"post"} onSubmit={this.handleSubmit}>
                    <Title spaced={true}>CHANGE PROFILE</Title>
                    <PhotoSection>
                        <PhotoTitle>Photo</PhotoTitle>
                        {this.state.editPhoto
                            ? <PhotoEdit>{this.state.photoComponent}</PhotoEdit>
                            : <Relative>
                                <Image
                                    src={this.state.photo}
                                    alt={"photo"}
                                    onClick={this.handleChangeEditPhoto}
                                    onMouseEnter={() => this.setState({"showPhotoEditIcon": true})}
                                    onMouseLeave={() => this.setState({"showPhotoEditIcon": false})}
                                />
                                {this.state.showPhotoEditIcon &&
                                    <EditIcon
                                        src={images.firmEditIconOnlyBlack}
                                        alt={"Edit"}
                                        onMouseEnter={() => this.setState({"showPhotoEditIcon": true})}
                                        onMouseLeave={() => this.setState({"showPhotoEditIcon": false})}
                                    />
                                }
                            </Relative>
                        }
                    </PhotoSection>
                    <TextInput
                        title={"First Name"}
                        valid={this.state.firstValid}
                        warning={"Required"}
                        id={"first"}
                        onChange={this.handleChangeFirst}
                        placeholder={"First Name"}
                        value={this.state.first}
                        containerStyles={inputsContainersStyles}
                        inputStyles={inputsStyles}
                    />
                    <TextInput
                        title={"Last Name"}
                        valid={this.state.lastValid}
                        warning={"Required"}
                        id={"last"}
                        onChange={this.handleChangeLast}
                        placeholder={"Last Name"}
                        value={this.state.last}
                        containerStyles={inputsContainersStyles}
                        inputStyles={inputsStyles}
                    />

                    <SaveStatus>
                        {this.state.isSaved &&
                            <ErrorText>{this.state.error}</ErrorText>
                        }
                        <SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} />
                    </SaveStatus>
                </Form>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.common.user,
});

const mapDispatchToProps = dispatch => ({
    tryUpdateSettingsPersonalInfo: (data) => dispatch(tryUpdateSettingsPersonalInfo(data)),
    tryGetSettingsPersonalInfo: () => dispatch(tryGetSettingsPersonalInfo()),
    tryUpdateExpertProfile: (data) => dispatch(tryUpdateExpertProfile(data)),
    tryGetExpertProfile: () => dispatch(tryGetExpertProfile()),
    setLoggedIn: (user) => dispatch(setLoggedIn(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(FirmSettingsProfileEditPreview);
