import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import ReactQuill from "react-quill";

/* Components */
import {FontBody16, FontTitle18} from '../../../common/components/fonts';
import {ButtonSecondary} from "../../../common/components/buttons";
import {LogoLoading} from "../../../common/components/loading";
import {EditOutlined, PlusOutlined, DeleteOutlined} from "@ant-design/icons";
import {Body, Card, Name, NameText, Quote, QuoteEnd, Gray, Portfolios, LoadingCenter, FitContent, Border, Btn, Inline, TextLeft}from "../components/testimonials";

/* Store */
import {setShopItemTestimonial} from "../store/shopitems";

/* Middleware */
import {tryUpdateExpertProfile} from "../middleware/expertprofile";

class ProfileEndorsements extends React.Component {
    state = {"testimonials": [], "profile": {}, "quillComponent": ""};

    componentDidMount() {
        return this.init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.profile !== this.props.profile) {
            return this.init()
        }
    }

    init = async () => {
        await this.setState({"profile": this.props.profile});
        await this.initDescription();
        await this.setState({"isLoading": false});
    };

    initDescription = async () => {
        await this.setState({"quillComponent": ""});
        this.setState({
            "quillComponent": [this.props.profile.get("testimonials").map((e, index) => {
                if(typeof e === "string") {
                    return null
                } else {
                    return(
                        <Card key={index}>
                            <Quote>&ldquo;</Quote>
                            <Body>
                                <ReactQuill
                                    readOnly={true}
                                    theme={null}
                                    value={e.get("body")}
                                    bounds={'.app'}
                                />
                            </Body>
                            <QuoteEnd>&bdquo;</QuoteEnd>
                            <Name><Gray>&ndash;</Gray>&nbsp;<NameText>{e.get("person")}</NameText></Name>
                            <Border />
                            <TextLeft>
                                <Inline>
                                    <FitContent>
                                        <Btn>
                                            <FontBody16 onClick={() => {return this.edit(e)}}><EditOutlined/>&nbsp;Edit</FontBody16>
                                        </Btn>
                                    </FitContent>
                                </Inline>
                                <Inline>
                                    <FitContent>
                                        <Btn>
                                            <FontBody16 onClick={() => {return this.delete(e)}}><DeleteOutlined />&nbsp;Delete</FontBody16>
                                        </Btn>
                                    </FitContent>
                                </Inline>
                            </TextLeft>
                        </Card>
                    )
                }
            })]
        })
    };

    add = async () => {
        await this.props.setShopItemTestimonial({});
        this.props.history.push("/"+(this.props.isWMC ? "profile" : "shop")+"/testimonials/create");
    };

    edit = async (item) => {
        await this.props.setShopItemTestimonial(item);
        this.props.history.push("/"+(this.props.isWMC ? "profile" : "shop")+"/testimonials/edit");
    };

    delete = async (item) => {
        let testimonials = this.props.profile.get("testimonials").toJS();
        const saveData = {
            // "bio": this.props.profile.get("bio"),
            // "whyBecameCoach": this.props.profile.get("whyBecameCoach"),
            // "freeTime": this.props.profile.get("freeTime"),
            // "endorsement1": this.props.profile.get("endorsement1"),
            // "endorsement2": this.props.profile.get("endorsement2"),
            // "endorsement3": this.props.profile.get("endorsement3"),
            "testimonials": await testimonials.filter(p => {return(p.id !== item.get("id"))}),
        };
        await this.props.tryUpdateExpertProfile(saveData, "deleteTestimonial");
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <FontTitle18>My Testimonials</FontTitle18>
                    <br />
                    <FitContent>
                        <FontBody16 onClick={() => {return this.add()}}>
                            <ButtonSecondary canSubmit={false} label={<><PlusOutlined />&nbsp;Add Testimonial</>} />
                        </FontBody16>
                    </FitContent>
                    <br/>
                    <Portfolios>
                        {this.state.quillComponent}
                    </Portfolios>
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    isWMC: state.common.wmc.get("isWMC"),
    profile: state.enterprise.shop.expertProfile.get("expertProfile"),
});

const mapDispatchToProps = dispatch => ({
    setShopItemTestimonial: (item) => dispatch(setShopItemTestimonial(item)),
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileEndorsements));
