import React from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import {v4 as uuid4} from 'uuid';

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FontTitle18, FontBody16} from "../../../common/components/fonts";
import { colors } from '../../../common/components/colors';

/* Middleware */
import {tryCreateCourse, tryAddTasksToCourse, tryUpdateCourse} from '../middleware/courses';
import {tryCreateTask, tryEditTask} from '../middleware/tasks'

import {setTaskCreate, setTasksForCourse, setCourseCreate, setCurrentPageEditing} from '../store/courses';

/* Containers */
import ArticleElements from './articleelements';
import ArticleDisplay from './createarticledisplay';
import CourseDisplay from './createcoursedisplay'
import LayerPanel from './layerspanel';

/* Utils */
import { ButtonPrimary, ButtonTertiary, ButtonInactive } from '../../../common/components/buttons';
import { images } from '../../../common/components/images';
import { CommonBackground, CommonModal } from '../../../common/components/modal';
import CourseLayers from './courselayers';
import {ListItem} from '../components/createcourse';
import { CloseOutlined } from '@ant-design/icons';

class CreateCourse extends React.Component {

    state={
        isLoading: true,
        task: {},
        isValid: false,
        contentType: null,
    };

    componentDidMount() {
        if (this.props.isEdit) {
            if (this.props.coursesOne.get("isVideo")) {
                return this.init('video');
            } else if (this.props.coursesOne.get("isReadingMaterial")) {
                return this.init('article');
            } else {
                return this.init('course');
            }
            
        } else {
            this.setState({"task": this.props.task, "isLoading": false})
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.task !== prevProps.task || this.props.course !== prevProps.course) {
            return this.checkValid()
        }
    }

    init = async (contentType) => {
        if (this.props.isEdit) {
            await this.props.setCurrentPageEditing(0)
            await this.props.setCourseCreate(this.props.coursesOne.toJS())
            if (contentType === 'course') {
                await this.props.setTasksForCourse(this.props.coursesOne.get("tasks").toJS())
                const task = {
                    ...this.props.coursesOne.toJS(),
                    "pages": [],
                    "id": null,
                }
                await this.props.setTaskCreate(task)
            } else {
                const task = this.props.coursesOne.get("tasks").get(0).toJS()
                await this.props.setTaskCreate({...task, "externalAuthor": this.props.coursesOne.get("externalAuthor").toJS()})
            }
            this.setState({"task": this.props.task, "contentType": contentType, "isLoading": false})
            await this.checkValid()
        } else {
            await this.props.setCurrentPageEditing(0)
            await this.props.setTasksForCourse([]) // reset tasks
            const course = {
                "title": "",
                "description": "",
                "imageUrl": "",
                "isActive": false,
                "isReadingMaterial": contentType !== 'course',
                "isVideo": contentType === 'video',
                "categoryIds": [],
                "categories": [],
            }
            await this.props.setCourseCreate(course) // set course as empty
            if (contentType === 'course') {
                const task = {
                    "title": "",
                    "description": "",
                    "imageUrl": "",
                    "pages": [],
                    "isActive": true,
                    "externalAuthor": {
                        "firstName": "",
                        "lastName": "",
                        "additionalInformation": {}
                    },
                    "id": null,
                }
                await this.props.setTaskCreate(task) // set task as empty for introduction
            } else {
                await this.createNewTask(contentType, "", 0)
            }
            this.setState({"task": this.props.task, "contentType": contentType, "isLoading": false})
        }
    };

    createNewTask = async (contentType, title, pageNum) => {
        let page = {
            "header": {"title": ""}, 
            "actions": {"backLabel": "", "nextLabel": "", "backShowOnTop": false, "pageNumVisible": false, "canContinueAutomatically": false}, 
            "pageNum": 0, 
            "elements": [{"id": 0, "text": "Here is the body of the article. Edit this text by clicking here directly and begin typing.", "type": "body", "style": {"textAlign": "left"}}],
            "pageType": "page"
        }
        if (contentType === 'video') {
            // video gets element with type hidden for cover video
            page = {...page, "elements": [{"id": 0, "type": "hidden", "value": ""}, {"id": 1, "text": "Here is the body of the article. Edit this text by clicking here directly and begin typing.", "type": "body", "style": {"textAlign": "left"}}],}
        } else if (contentType === 'course') {
            // course tasks start with specific page to get title, image, description for that task (this is uneditable)
            page = {...page, 
                "header": {"title": "Article " + (pageNum + 1)}, 
                "pageNum": pageNum, 
                "elements": [{"id": 0, "text": title + " Title", "type": "title"}, {"id": 1, "text": "", "type": "image"}, {"id": 2, "text": "Here is the description for this week's module. Edit this text by clicking here directly and begin typing.", "type": "body", "style": {"textAlign": "left"}}],
                "actions": {"backLabel": "", "nextLabel": "Next", "backShowOnTop": false, "pageNumVisible": false, "canContinueAutomatically": true}, 
            }
        }
        let pages = [page]
        const task = {
            "title": title,
            "description": contentType === 'course' ? title + " description" : "",
            "imageUrl": "",
            "pages": pages,
            "isActive": true,
            "externalAuthor": {
                "firstName": contentType === 'course' ? "Willow" + " description" : "",
                "lastName": "",
                "additionalInformation": {}
            },
            "id": uuid4()
        }
        await this.props.setTaskCreate(task)
        return task
    }

    onSubmit = async () => {
        await this.checkValid()
        if (!this.state.isValid) {
            return this.init(this.state.contentType)
        } else {
            await this.saveAndQuit(true)
        }
    };

    saveAndQuit = async (isActive) => {
        let c = this.props.course.toJS()
        delete c.categories
        if (!c.title) {
            c = {...c, "title": "Untitled"}
        }
        if (!c.imageUrl) {
            c = {...c, "imageUrl": images.grayBackgroundImage}
        }
        if (!c.categoryIds) {
            c = {...c, "categoryIds": []}
        }
        if (this.state.contentType === 'course') {
            if (this.props.task.get("id")) {
                await this.setSelected(this.props.task) // ensure last edited has been updated
            }
            let modifiedTasks = this.props.tasks
            //modifiedTasks.shift() // remove first task because it is the introduction
            if (this.props.isEdit) {
                // separate already created tasks versus tasks to create
                const taskIds = await Promise.all(modifiedTasks.map(t => this.checkTasks(t)))
                await this.props.tryUpdateCourse(this.props.coursesOne.get("id"), c) // update course
                if (taskIds.length > 0) {
                    await this.props.tryAddTasksToCourse(taskIds, this.props.coursesOne.get("id")) // assign tasks to course
                }
            } else {
                const tasks = await Promise.all(modifiedTasks.map(t => this.makeTask(t))); // create all tasks
                const taskIds = await Promise.all(tasks.map(t => t.id)); // get all task ids
                const course = await this.props.tryCreateCourse({...c, "isActive": isActive}) // make course
                if (taskIds.length > 0) {
                    await this.props.tryAddTasksToCourse(taskIds, course.id) // assign tasks to course
                }
            }
        } else {
            let task;
            let t = this.props.task.toJS()
            if (!t.title) {
                t = {...t, "title": "Untitled"}
            }
            if (!t.imageUrl) {
                t = {...t, "imageUrl": images.grayBackgroundImage}
            }
            if (!t.description) {
                t = {...t, "description": "Description"}
            }
            console.log(t)
            if (this.props.isEdit) {
                task = await this.props.tryEditTask(t, this.props.task.get("id"));
            } else {
                task = await this.props.tryCreateTask(t);
            }
            delete c.pages
            let course;
            if (this.props.isEdit) {
                course = await this.props.tryUpdateCourse(this.props.coursesOne.get("id"), c)
            } else {
                course = await this.props.tryCreateCourse({...c, "isActive": isActive})
                await this.props.tryAddTasksToCourse([task.id], course.id)
            }            
        }
        return this.props.history.push("/curriculum");
        
    };

    checkTasks = async (t) => {
        const oldTaskIds = await Promise.all(this.props.coursesOne.get("tasks").map(t => t.get("id"))); // get all task ids
        const included = await oldTaskIds.includes(t.get("id")) 
        if (included) {
            await this.updateTask(t)
            return t.get("id")
        } else {
            const task = await this.makeTask(t)
            return task.id
        }
    }

    makeTask = async (t) => {
        t = t.toJS()
        if (!t.title) {
            t = {...t, "title": "Untitled"}
        }
        if (!t.imageUrl) {
            t = {...t, "imageUrl": images.grayBackgroundImage}
        }
        if (!t.description) {
            t = {...t, "description": "Description"}
        }
        return await this.props.tryCreateTask(t);
    }

    updateTask = async (t) => {
        t = t.toJS()
        if (!t.title) {
            t = {...t, "title": "Untitled"}
        }
        if (!t.imageUrl) {
            t = {...t, "imageUrl": images.grayBackgroundImage}
        }
        if (!t.description) {
            t = {...t, "description": "Description"}
        }
        return await this.props.tryEditTask(t, t.id);
    }

    checkValid = async () => {
        const course = this.props.course.toJS()
        const task = this.props.task.toJS()
        // valid if title, image, description, categoryId, and author for reading materials
        if (
            course.title !== null && course.title !== "" && course.title !== undefined && course.title !== "Untitled"
            && course.imageUrl !== null && course.imageUrl !== "" && course.imageUrl !== undefined && course.imageUrl !== images.grayBackgroundImage
            && course.description !== null && course.description !== "" && course.description !== undefined && course.description !== "Description"
            && course.categoryIds !== [] && course.categoryIds.length > 0
            && (this.state.contentType === 'course' || (course.externalAuthor === undefined ? false : course.externalAuthor.firstName !== null && course.externalAuthor.firstName !== ""))
        ) {
            if (this.state.contentType === 'course') {
                // check at least 1 module
                this.props.tasks.size > 0 ? this.setState({"isValid": true}) : this.setState({"isValid": false})
            } else if ((task.pages[0].elements[0].value !== "" && task.pages[0].elements[0].value !== null && task.pages[0].elements[0].value !== undefined) || this.state.contentType !== 'video') {
                // check video valid for video type
                (task.pages[1].elements !== [] && task.pages[1].elements.length > 0) ?
                this.setState({"isValid": true})
                :
                this.setState({"isValid": false})
            } else {
                this.setState({"isValid": false})
            }
        } else {
            this.setState({"isValid": false})
        }
    }

    addWeek = async () => {
        await this.props.setCurrentPageEditing(0)
        const task = await this.createNewTask(this.state.contentType, "Week " + (this.props.tasks.size + 1), 0)
        await this.props.setTasksForCourse([...this.props.tasks, task])
        await this.checkValid()
    }

    deleteWeek = async (index) => {
        let taskList = this.props.tasks.toJS()
        let prevTask;
        if (index === 0) {
            prevTask = {
                "title": "",
                "description": "",
                "imageUrl": "",
                "pages": [],
                "isActive": true,
                "externalAuthor": {
                    "firstName": "",
                    "lastName": "",
                    "additionalInformation": {}
                },
                "id": null
            }
        } else {
            prevTask = taskList[index - 1]
        }
        await taskList.splice(index, 1)
        await this.props.setTasksForCourse(taskList)
        await this.props.setTaskCreate(prevTask)
        await this.checkValid()
    }

    addPage = async (type) => {
        console.log(this.props.task.get("pages").toJS())
        let pagesList = [...this.props.task.get("pages").toJS()]
        let addedPage = { // learning page starts with just a body
            "header": {"title": "Article " + (this.props.task.get("pages").size + 1)}, 
            "actions": {"backLabel": "Back", "nextLabel": "Next", "backShowOnTop": false, "pageNumVisible": false, "canContinueAutomatically": true}, 
            "pageNum": this.props.task.get("pages").size, 
            "elements": [{"id": 0 + (this.props.task.get("pages").toJS().length * 1000), "text": "Edit this text by clicking here directly and begin typing.", "type": "body", "style": {"textAlign": "left"}}],
            "pageType": "page"
        }
        if (type === 'quiz') { // quiz page starts with a header + multiple choice
            addedPage =
                {...addedPage,
                "header": {"title": "Quiz"}, 
                "elements": [
                    {"id": this.props.task.get("pages").toJS().length * 1000, "text": "", "type": "title"},
                    {"id": 1 + (this.props.task.get("pages").toJS().length * 1000), 
                    "type": "multiSelect", 
                    "isValid": true,
                    "options": [
                        {"id": 1, "label": "", "active": true},
                        {"id": 2, "label": "", "active": false},
                        {"id": 3, "label": "", "active": false},
                        {"id": 4, "label": "", "active": false}
                    ],
                    "isRequired": true,
                    "maxSelectable": 1
                    }
                ],
                "pageType": "quiz"
                }
        }
        await pagesList.push(addedPage)
        let task = this.props.task.toJS()
        task = {...task, "pages": pagesList}
        await this.setSelected(task)
    }

    deletePage = async (index) => {
        let pagesList = this.props.task.get("pages").toJS()
        pagesList.splice(index, 1)
        let task = this.props.task.toJS()
        task = {...task, "pages": pagesList}
        await this.setSelected(task)
    }

    setSelected = async (task) => {
        await this.props.setTaskCreate(task)
        await this.updateTasks()
    }

    updateTasks = async () => {
        if (this.props.task.get("id")) {
            let newTasks = this.props.tasks.toJS()
            const tk = this.props.task.toJS()
            const i = newTasks.indexOf(newTasks.find(t => t.id === tk.id))
            newTasks = await newTasks.filter(t => {return t.id !== tk.id}) //remove from list
            await newTasks.splice(i, 0, tk).shift()
            await this.props.setTasksForCourse(newTasks)
        }
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            if (this.state.contentType === null) {
                return (
                    <>
                        <CommonBackground />
                        <CommonModal style={{width: '348px'}}>
                            <div onClick={() => {this.props.history.push('/curriculum')}} style={{width: '100%', textAlign: 'right', cursor: 'pointer'}}><CloseOutlined/></div>
                            <FontTitle18>Choose a content type</FontTitle18>
                            <br />
                            <div>
                                <ListItem even={false} backgroundColor={colors.backgroundColor1} onClick={() => {this.init('article')}}>
                                    <div><img src={images.contentArticleIcon} /></div>
                                    <FontBody16>Article</FontBody16>
                                </ListItem>
                                <br />
                                <ListItem onClick={() => {this.init('video')}} even={true} backgroundColor={colors.backgroundColor3}>
                                    <div><img src={images.contentVideoIcon} /></div>
                                    <FontBody16>Video</FontBody16>
                                </ListItem>
                                <br />
                                <ListItem onClick={() => {this.init('course')}}  even={false} backgroundColor={colors.backgroundColor5}>
                                    <div><img src={images.contentCourseIcon} /></div>
                                    <FontBody16>Course</FontBody16>
                                </ListItem>
                            </div>
                        </CommonModal>
                    </>
                )
            } else {
                return (
                    <>
                        <br />
                        <div style={{display: 'inline-flex', width: '100%', alignItems: "center", justifyContent: "space-between", paddingBottom: "30px"}}>
                            <FontTitle18><img src={this.state.contentType === 'article' ? images.contentArticleIcon : (this.state.contentType === "video" ? images.contentVideoIcon : images.contentCourseIcon)} alt="" /> 
                                {this.state.contentType.charAt(0).toUpperCase() + this.state.contentType.slice(1)} Content
                            </FontTitle18>
                            <div style={{display: 'inline-flex', alignItems: "center"}}>
                                <div onClick={() => {this.saveAndQuit(false)}}>
                                    <ButtonTertiary label={'Save & Quit'} />
                                </div>
                                &nbsp;&nbsp;
                                <div >
                                    {this.state.isValid ? <div onClick={this.onSubmit} ><ButtonPrimary label={'Publish'}  /> </div>: <ButtonInactive label={'Publish'} />}
                                </div>
                            </div>
                        </div>
                        <br />
                        <div style={{display: 'flex', alignItems: 'self-start'}}>
                            <ArticleElements type={this.state.contentType}/>
                            {this.state.contentType === "course" ? 
                                <CourseDisplay type={this.state.contentType}/> 
                                : 
                                <ArticleDisplay type={this.state.contentType}/>}
                            {this.state.contentType === "course" ? 
                                <CourseLayers 
                                    type={this.state.contentType} 
                                    addWeek={this.addWeek} 
                                    setSelected={(task) => {this.setSelected(task)}}
                                    addPage={(type) => {this.addPage(type)}}
                                    deletePage={(index) => {this.deletePage(index)}}
                                    deleteWeek={(index) => {this.deleteWeek(index)}}
                                /> 
                                : 
                                <LayerPanel type={this.state.contentType}/>}
                            
                        </div>
                    </>
                )
            }
        }
    }
}

const mapStateToProps = state => ({
    task: state.enterprise.curriculum.curriculum.get("taskCreate"),
    coursesOne: state.enterprise.curriculum.curriculum.get("coursesOne"),
    tasks: state.enterprise.curriculum.curriculum.get("tasks"),
    course: state.enterprise.curriculum.curriculum.get("courseCreate"),
});

const mapDispatchToProps = dispatch => ({
    tryCreateCourse: (courseData) => dispatch(tryCreateCourse(courseData)),
    setTaskCreate: (course) => dispatch(setTaskCreate(course)),
    tryCreateTask: (data) => dispatch(tryCreateTask(data)),
    tryEditTask: (data, id) => dispatch(tryEditTask(data, id)),
    tryUpdateCourse: (courseId, courseData) => dispatch(tryUpdateCourse(courseId, courseData)),
    tryAddTasksToCourse: (tasks, courseId) => dispatch(tryAddTasksToCourse(tasks, courseId)),
    setTasksForCourse: (tasks) => dispatch(setTasksForCourse(tasks)),
    setCourseCreate: (course) => dispatch(setCourseCreate(course)),
    setCurrentPageEditing: (pageNum) => dispatch(setCurrentPageEditing(pageNum))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateCourse));