import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from "styled-components";

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FontTitle18, FontTitle30, FontBody16, FontBody12} from "../../../common/components/fonts";
import {images} from "../../../common/components/images";
import {TopLogo} from '../components/match';

/* Containers */
import FormsFlow from "../../forms/containers/flow";

/* Middleware */
import {tryGetAllCoachesFromCategory, tryGetAllTeamMembers, trySaveMatchingResults} from '../middleware/match';
import {tryAuthOnly} from '../../../routes/middleware/auth';

/* Store */
import {setSignupMatchCoaches} from "../store/match";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

const Container = styled.div`
    max-width: 768px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 60px auto 0px auto;
`;

const Center = styled.div`
    text-align: center;
`;

class MatchingForm extends React.Component {
    static defaultProps = {
        matchingFormSelected: "matchingFormStandard"
    }

    state={
        "form": [],
        "matchingFormSelected": "matchingFormStandard",
        "partnerLogo": null,
        "isLoading": true,
        "step": 1,
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const user = await this.props.tryAuthOnly(null, false, false);
        if(user === null) {
            this.props.history.push("/get-started")
        } else {
            const team = await this.props.tryGetAllTeamMembers()
            if(team.length > 0) {
                this.props.history.push("/verify")
            } else {
                const partner = env.CONSUMER.SIGN_UP.MATCH_PUBLIC_PAGE.CLIENT_DATA.WMC_IDS[this.props.wmcId]
                await this.setState({
                    "partnerLogo": partner === undefined ? null : images[partner.logoIcon],
                    "matchingFormSelected": partner === undefined ? "matchingFormStandard" : partner.matchingFormSelected,
                    "isLoading": false
                })
            }
        }
    };

    matchJourneysTraditions = async (form) => {
        let expertCategories = [];
        const options = form[0].elements[0].options;
        if(options[0].active) {
            expertCategories.push(47, 23, 48, 49, 22); // "I'm going through a divorce"
        } else {
            expertCategories.push(21, 30, 19, 17, 14, 35, 16, 18, 15, 20); // All the rest of them have these + other
        }
        if(options[1].active) {
            expertCategories.push(29, 27, 28, 26, 41); // "I'm a widow"
        }
        if(options[2].active) {
            expertCategories.push(29, 28, 41); // "I'm a caregiver for aging loved ones"
        }
        if(options[3].active) {
            expertCategories.push(36, 37, 41); // "I'm navigating a career change (exit or re-entry)"
        }
        if(options[4].active) {
            expertCategories.push(50, 33, 34, 36, 37, 41); // "I'm in charge of my family finances"
        }
        if(options[5].active) {
            expertCategories.push(32, 33, 34, 41, 47, 48, 36, 37); // "I'm a single parent"
        }
        if(options[6].active) {
            expertCategories.push(32, 33, 34, 48, 41, 50, 36, 37); // "I'm part of a non-traditional relationship"
        }
        if(options[7].active) {
            expertCategories.push(41, 51, 22, 50, 32, 33, 34, 36, 37, 47, 23, 48, 49); // "I'm still starting out financially"
        }
        if(options[8].active) {
            expertCategories.push(36, 37, 41); // "I'm an entrepreneur"
        }
        if(options[9].active) {
            expertCategories.push(36, 37, 41, 29, 27, 28, 26, 47, 23, 48, 49, 22, 24, 23, 33, 34); // "I'm navigating career challenges as an executive"
        }
        console.log("allcat", expertCategories)
        return expertCategories.filter((value, index, self) => {return self.indexOf(value) === index;});
    }

    onReturnForm = async (form) => {
        await this.setState({"step": 2});
        await this.props.trySaveMatchingResults(form);
        // Match journey
        let expertCategories = [];
        if(this.state.matchingFormSelected === "matchingFormTraditions") {
            console.log("matchingFormTraditions")
            expertCategories = await this.matchJourneysTraditions(form);
            console.log(expertCategories)
        } else {
            expertCategories = env.CONSUMER.CARE.EXPERT_SUB_CATEGORIES;
        }
        // Match coaches with availability in journeys
        const coachesAll = await this.props.tryGetAllCoachesFromCategory({"ids": expertCategories, "wmcId": this.props.wmcId})
        // Match gender
        console.log("coachesAll", coachesAll)
        const requestFemale = form[2].elements[0].options[0].active;
        const requestMale = form[2].elements[0].options[1].active;
        console.log("female", requestFemale)
        console.log("male", requestMale)
        const coachesFiltered = await coachesAll.filter(c => {
            if(requestFemale) {
                return (c.gender === "Female")
            } else if(requestMale) {
                return (c.gender === "Male")
            } else {
                return true
            }
        })
        // Store results
        console.log("coachesFiltered", coachesFiltered)
        await this.props.setSignupMatchCoaches(coachesFiltered);
        this.props.history.push("/get-scheduled");
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if (this.state.step === 1) {
                return(
                    <Container>
                        <TopLogo partnerLogo={this.state.partnerLogo} willowLogoIcon={true} />
                        <br />
                        <Center>
                            <FontTitle30>Get matched!</FontTitle30>
                            <FontBody16>Tell us about yourself and we'll recommend the best coach for you.</FontBody16>
                            <FontBody12>(Takes about 3-minutes)</FontBody12>
                        </Center>
                        <br />
                        <br />
                        <FormsFlow
                            form={env.CONSUMER.SIGN_UP.MATCH_PUBLIC_PAGE.MATCHING_FORMS[this.state.matchingFormSelected]}
                            skipAuth={true}
                            onCompleteFormMethod={"return"}
                            onReturnForm={this.onReturnForm}
                        />
                    </Container>
                )
            } else if (this.state.step === 2) {
                return(
                    <Container>
                        <Center>
                            <FontTitle18>Determining match...</FontTitle18>
                            <LoadingCenter><LogoLoading /></LoadingCenter>
                        </Center>
                    </Container>
                )
            }
        }
    }

}

const mapStateToProps = state => ({
    wmcId: state.common.user.get("prospectWmcId")
});

const mapDispatchToProps = dispatch => ({
    setSignupMatchCoaches: (coaches) => dispatch(setSignupMatchCoaches(coaches)),
    trySaveMatchingResults: (results) => dispatch(trySaveMatchingResults(results)),
    tryGetAllCoachesFromCategory: (a) => dispatch(tryGetAllCoachesFromCategory(a)),
    tryAuthOnly: (email, trackNewRegistration, forceAuth) => dispatch(tryAuthOnly(email, trackNewRegistration, forceAuth)),
    tryGetAllTeamMembers: () => dispatch(tryGetAllTeamMembers())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MatchingForm));