import React from 'react';
import styled from 'styled-components';

/* Containers */
import AppointmentContainer from "../../appointments/containers/appointment";
import AppointmentContainerV2 from "../../appointments/containers/appointmentv2";

/* Components */
import {colors} from '../../../common/components/colors';
import {ArrowLeftOutlined, CloseOutlined, LeftOutlined} from '@ant-design/icons';
import {LogoLoading} from "../../../common/components/loading";

const Container = styled.div`
    max-width: 768px;
    ${props => props.calendarOnly !== true} {
      width: 100%;
      padding: 0;
    }
    ${props => props.calendarOnly !== false} {
      width: calc(100% - 20px);
      padding: 0px 10px;
    }
    margin: 0px auto 0px auto;
`;

const BackTop = styled.div`
    color: ${colors.secondary70};
    width: fit-content;
    display: block;
    cursor: pointer;
    position: relative;
    z-index: 25;
    ${props => props.useNewDesign === true} {
        padding: 5px 10px;
    }
    ${props => props.useNewDesign === false} {
        padding: 5px 0;
    }
    @media screen and (max-width: 748px) {
        margin-left: 60px;
    }
    @media screen and (max-width: 460px) {
        margin-left: 0px;
    }
`;

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

class Availability extends React.Component {
    state={
        "isLoading": true,
        "userId": null,
        "userFirstName": null,
        "userLastName": null,
        "userPhotoUrl": null,
        "onSelectTime": () => {},
        "availabilityOnBack": () => {},
        "meetingLength": null,
        "availabilityError": null
    };

    static defaultProps = {
        useNewDesign: false,
        allowTimeToBeSelected: true
    };

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        clearTimeout(this.timeout)
    }

    init = async () => {
        await this.setState({
            "userId": this.props.userId,
            "userFirstName": this.props.userFirstName,
            "userLastName": this.props.userLastName,
            "userPhotoUrl": this.props.userPhotoUrl,
            "availabilityOnBack": this.props.availabilityOnBack,
            "onSelectTime": this.props.onSelectTime,
            "meetingLength": this.props.meetingLength,
            "availabilityError": this.props.availabilityError,
            "isLoading": false
        });
    };

    findCoach = () => {
        if (this.props.availabilityCloseBtn) {
            return this.props.availabilityOnClose()
        } else if (this.props.availabilityBackBtn) {
            return this.state.availabilityOnBack()
        }
    }

    render() {
        if(this.state.isLoading) {
            return (<LoadingCenter><LogoLoading/></LoadingCenter>)
        } else {
            return (
                <Container calendarOnly={this.props.availabilityCalendarOnly}>
                    {this.props.availabilityCloseBtn && !this.props.showArrowClose &&
                        <BackTop onClick={this.props.availabilityOnClose}><CloseOutlined /></BackTop>
                    }
                    {this.props.availabilityCloseBtn && this.props.showArrowClose &&
                        <BackTop onClick={this.props.availabilityOnClose}><LeftOutlined /></BackTop>
                    }
                    {this.props.availabilityBackBtn &&
                        <BackTop onClick={this.state.availabilityOnBack}><ArrowLeftOutlined/></BackTop>
                    }
                    {this.props.useNewDesign ? 
                        <AppointmentContainerV2
                            coachId={this.state.userId}
                            coachFirst={this.state.userFirstName}
                            coachLast={this.state.userLastName}
                            coachImg={this.state.userPhotoUrl}
                            onSelectTime={this.state.onSelectTime}
                            meetingLength={this.state.meetingLength}
                            error={this.state.availabilityError}
                            title={(`Select a time to meet with ${this.props.userFirstName}`).toUpperCase()}
                            closeSidebar={this.props.confirmOnClose}
                            allowTimeToBeSelected={this.props.allowTimeToBeSelected}
                            calendarOnly={this.props.availabilityCalendarOnly}
                        /> :
                        <AppointmentContainer
                            coachId={this.state.userId}
                            coachFirst={this.state.userFirstName}
                            coachLast={this.state.userLastName}
                            coachImg={this.state.userPhotoUrl}
                            onSelectTime={this.state.onSelectTime}
                            meetingLength={this.state.meetingLength}
                            error={this.state.availabilityError}
                        />
                    }
                </Container>
            )
        }
    }
}

export default Availability;