import React from "react";
import styled from "styled-components";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { colors } from "../../../common/components/colors";
import { fontFamily } from "../../../common/components/fonts";


const CalendarContainer = styled.div`
    margin: 20px auto 0px auto;
    border-radius: 20px 0px 20px 0px;
    width: fit-content;
    & .react-datepicker {
        width: 100%;
        border: none;
        background-color: #EBE5DA;
        margin: 0px auto 0px auto;
    }
    & .react-datepicker__month-container {
        width: 100%;
    }
    & .react-datepicker__header {
        background-color: #EBE5DA;
        border: none;
    }
    & .react-datepicker__month {
        line-height: 2.5rem;
    }
    & .react-datepicker__current-month {
        font-family: ${fontFamily.title};
        color: ${colors.primary100};
        margin-bottom: 10px;
        font-size: 1.1rem;
        font-weight: normal;
    }
    & .react-datepicker__navigation--previous {
        left: 5% !important;
    }
    & .react-datepicker__navigation--next {
        right: 5% !important;
    }
    & .react-datepicker__navigation--next, .react-datepicker__navigation--previous {
        outline: none;
    }
    & .react-datepicker__day-name {
        font-family: ${fontFamily.subtitle};
        color: ${colors.primary100};
        margin: 0.375rem 0.75rem 0rem 0.75rem !important;
        @media screen and (max-width: 748px) {   
            margin: 0rem 0.45rem 0rem 0.45rem !important;
        }
        @media screen and (max-width: 460px) {   
            margin: 0rem 0.45rem 0rem 0.45rem !important;
        }
    }
    & .react-datepicker__day {
        color: ${colors.secondary100} !important;
        font-family: ${fontFamily.body};
        margin: 0rem 0.75rem 0.75rem 0.75rem !important;
        @media screen and (max-width: 748px) {   
            margin: 0rem 0.45rem 0rem 0.45rem !important;
        }
        @media screen and (max-width: 460px) {   
            margin: 0rem 0.45rem 0rem 0.45rem !important;
        }
        &:hover {
            background-color: ${colors.action100} !important;
            border-radius: 50%;
            color: ${colors.white} !important;
        }
    }
    & .react-datepicker__day--keyboard-selected {
        background-color: ${colors.action100} !important;
        color: ${colors.white} !important;
        border-radius: 50%;
    }
    & .react-datepicker__day--selected {
        background-color: ${colors.action100} !important;
        color: ${colors.white} !important;
        border-radius: 50%;
    }
    & .react-datepicker__day--selected:focus {
        outline: none !important;
        border: none !important;
        border-radius: 50%;
    }
    & .show {
        background-color: ${colors.secondary30};
        color: ${colors.secondary100};
        border-radius: 50%;
        outline: none !important;
        border: none !important;
        font-weight: bold;
        cursor: pointer;
    }
    & .hide {
        cursor: default;
        pointer-events: none;
        color: ${colors.secondary30};
    }
    & .react-datepicker__day--outside-month {
        visibility: hidden;
    }
`;

const CalendarInnerContainer = styled.div`
    background-color: #EBE5DA;
    padding-top: 20px;
    padding-bottom: 10px;
    border-radius: 20px 0px 20px 0px;
    }
`;


class ElementCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.calendarContainer = React.createRef();

        this.state = {
            "text": "",
            "value": new Date(),
            "size": 300
        }
    }

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.elementId !== this.props.elementId) {
            return this.init();
        }
    }

    init = async () => {
        console.log(this.props.value)
        this.setState({
            "value": new Date(this.props.value.toString())
        })
    }


    onChange = async newValue => {
        let isValid = true;
        this.setState({
            "value": newValue
        })
        console.log(newValue)
        this.props.onSaveElement(this.props.elementId, {"value": newValue}, isValid)
    }

    getSize = () => {
        let size = 260
        if (this.calendarContainer.current) {
            let newWidth = 0.9 * this.calendarContainer.current.offsetWidth - 40
            if (newWidth > size)
                size = newWidth
        }
        return size;
    }

    render() {
        return <CalendarContainer style={this.props.containerStyle} ref={this.calendarContainer}>
            <CalendarInnerContainer>
                <DatePicker
                    inline
                    selected={this.state.value}
                    onChange={this.onChange}
                    size={this.getSize()}
                    fontSize={14}
                    hideAdjacentDates={true}
                    peekNextMonth={false}
                    weekends={[]}
                />
            </CalendarInnerContainer>
        </CalendarContainer>
    }
}

export default ElementCalendar;