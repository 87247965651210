import React from "react";
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import styled from "styled-components";

/* Components */
import {LogoLoading} from "../../../../common/components/loading";
import Success from "../../../../iframes/success/containers/success";
import FirmSettingsProfileEditDesignations from '../../settings/containers/profiledesignations';

/* Middleware */
import {tryAnswerTask, tryGetOneTask} from "../middleware/tasks";
import {tryGetAdvisorCFPNumber, tryGetAdvisorIWINumber, tryGetAdvisorCDFANumber, tryUpdateAdvisorCFPNumber, tryUpdateAdvisorIWINumber,tryUpdateAdvisorCDFANumber,} from "../../settings/middleware/settings";

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;
const Page = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Modal = styled.div`
  z-index: 5;
  border-radius: 25px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
  background-color: white;
`;
const Close = styled.div`
  width: 100%;
  text-align: right;
`;

class TrainingsRecordCompleted extends React.Component {
    state = {"isLoading": true, "showNumbersModal": false};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const courseIdQuiz = window.location.pathname.split('/')[3];
        await this.completeQuiz(courseIdQuiz);
        await this.checkDesignationNumbers();
        await this.setState({"isLoading": false});
    };

    checkDesignationNumbers = async () => {
        const cfp = await this.props.tryGetAdvisorCFPNumber();
        const iwi = await this.props.tryGetAdvisorIWINumber();
        const cdfa = await this.props.tryGetAdvisorCDFANumber();
        if(cfp.cfpNumber === null || iwi.iwiNumber === null || cdfa.cdfaIdNumber === null) {
            await this.setState({"showNumbersModal": true});
        }
    }

    completeQuiz = async (courseIdQuiz) => {
        let courseQuiz = await this.props.tryGetOneTask(courseIdQuiz)
        if(!courseQuiz.isCompleted) {
            this.props.tryAnswerTask(courseQuiz.tasks[0].taskAssignmentId, courseQuiz.tasks[0].task.pages, true, 0)
        }
    }

    closeModal = async (e) => {
        e.preventDefault();
        await this.props.tryUpdateAdvisorCFPNumber({id: this.props.userId, cfpNumber: "NUMBERISBLANK"});
        await this.props.tryUpdateAdvisorIWINumber({id: this.props.userId, iwiNumber: "NUMBERISBLANK"});
        await this.props.tryUpdateAdvisorCDFANumber({id: this.props.userId, cdfaIdNumber: "NUMBERISBLANK"});
        this.setState({"showNumbersModal": false})
    }

    closeModalSave = async (e) => {
        this.setState({"showNumbersModal": false})
    }

    render() {
        if(this.state.isLoading) {
            return (<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <Success
                        title={"YOU PASSED!"}
                        subtitle={"You have earned 1 CE credit."}
                        buttonLabel={"View All CE Seminars"}
                        back={() => {this.props.history.push("/my-certificates/my-trainings")}}
                    />
                    {this.state.showNumbersModal &&
                        <Page>
                            <Modal>
                                <FirmSettingsProfileEditDesignations
                                    showSkip={true}
                                    onSkip={this.closeModal}
                                    onSave={this.closeModalSave}
                                />
                            </Modal>
                        </Page>
                    }
                </>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryUpdateAdvisorCFPNumber: (data) => dispatch(tryUpdateAdvisorCFPNumber(data)),
    tryUpdateAdvisorIWINumber: (data) => dispatch(tryUpdateAdvisorIWINumber(data)),
    tryUpdateAdvisorCDFANumber: (data) => dispatch(tryUpdateAdvisorCDFANumber(data)),
    tryGetAdvisorCFPNumber: () => dispatch(tryGetAdvisorCFPNumber()),
    tryGetAdvisorIWINumber: () => dispatch(tryGetAdvisorIWINumber()),
    tryGetAdvisorCDFANumber: () => dispatch(tryGetAdvisorCDFANumber()),
    tryGetOneTask: (courseId) => dispatch(tryGetOneTask(courseId)),
    tryAnswerTask: (id, answers, isCompleted, lastPageCompleted) => dispatch(tryAnswerTask(id, answers, isCompleted, lastPageCompleted))
})

export default connect(null, mapDispatchToProps)(withRouter(TrainingsRecordCompleted));
