import React from 'react';
import validator from 'validator';

/* Components */
import {NumberInput} from "../../../common/components/inputs";
import {LogoLoading} from "../../../common/components/loading";

class MeetingsCreateNumber extends React.Component {
    state = {
        number: 0,
        numberValid: true,
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "number": this.props.participants.number,
            "isLoading": false
        });
    };

    handleChangeNumber = async e => {
        await this.setState({
            'number': e.target.value < 1 ? 1 : e.target.value,
            'numberValid': e.target.value <= 50,
        });
        await this.props.onValidate(this.state);
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <NumberInput
                    title={"Max Participants*"}
                    valid={this.state.numberValid}
                    warning={"Max. participants allowed: 50"}
                    id={"number"}
                    onChange={this.handleChangeNumber}
                    placeholder={50}
                    value={this.state.number}
                />
            )
        }
    }
}

export default MeetingsCreateNumber;
