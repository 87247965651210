import styled from "styled-components";
import {colors} from "../../../common/components/colors";

export const Container = styled.div`
    ${props => props.fullScreen !== true} {
        margin-bottom: 100px;
    }
    ${props => props.fullScreen !== false} {
        margin-bottom: 80px;
    }
`;
export const Left = styled.div`
    ${props => props.fullScreen !== true} {
        max-width: 800px;
        width: 100%;
        display: block;
        margin: 0px auto;
    }
    ${props => props.fullScreen !== false} {
        text-align: right;
        width: calc(100% - 600px);
        display: inline-block;
        vertical-align: top;
        @media screen and (max-width: 1088px) {
            display: block;
            width: 100%;
            max-width: 600px;
            margin: 0px auto;
        }
        @media screen and (max-width: 460px) {
            width: 100%;
            display: block;
        }
    }
`;
export const Right = styled.div`
    ${props => props.fullScreen !== true} {
        max-width: 800px;
        width: 100%;
        display: block;
        margin: 0px auto;
    }
    ${props => props.fullScreen !== false} {
        width: 600px;
        display: inline-block;
        vertical-align: top;
        @media screen and (max-width: 1088px) {
            margin: 0px auto;
            display: block;
            width: 100%;
            max-width: 600px;
        }
        @media screen and (max-width: 460px) {
            width: 100%;
            display: block;
        }
    }
`;
export const Body = styled.div`
    ${props => props.fullScreen !== true} {
        padding: 20px 60px 0px 60px;
        width: calc(100% - 120px);
    }
    ${props => props.fullScreen !== false} {
        padding: 20px 10px 0px 10px;
        width: 100%;
    }
    @media screen and (max-width: 1088px) {
        padding: 20px 10px 0px 10px;
        width: 100%;
    }
    @media screen and (max-width: 640px) {
        width: calc(100% - 20px);
        padding: 20px 10px 0px 10px;
    }
    & p {
        font-family: GraphicWebRegular;
        font-size: 16px;
        line-height: 24px;
    }
`;



export const Title = styled.div`
    width: fit-content;
    margin-bottom: 10px;
    @media screen and (max-width: 460px) {
        width: 100%;
    }
`;
export const Type = styled.div`
    margin-bottom: 15px;
`;
export const Prices = styled.div`
    margin: 25px 0px;
    color: ${colors.secondary100};
`;
export const Image = styled.img`
    width: 100%;
    max-width: 100%;
    border-radius: 4px;
    height: auto;
    object-fit: cover;
    object-position: 0 0;
    ${props => props.fullScreen !== true} {
        max-height: 400px;
    }
    ${props => props.fullScreen !== false} {
        max-height: 600px;
    }
    @media screen and (max-width: 1440px) {
        max-height: 400px;
    }
    @media screen and (max-width: 640px) {
        max-height: 300px;
    }
`;
export const Header = styled.div`
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 3px solid ${colors.border70};
`;
export const Name = styled.div`
    padding-left: 10px;
    @media screen and (max-width: 460px) {
        padding-left: 0px;
    }
`;
export const SubName = styled.div`
    color: ${colors.secondary100};
`;

export const Purchase = styled.div`
    margin-top: 25px;
`;
export const MarginRadio = styled.div`
    margin-top: -3px;
    word-break: break-word;
`;

export const Icon16 = styled.div`
    display: inline-block;
    vertical-align: top;
    height: 24px;
    & svg {
        width: 20px;
        height: 20px;
        margin-top: 2px;
    }
`;

export const Details = styled.div`
    width: fit-content;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-top: -10px;
    color: ${colors.primary70};
`;
