import {axiosReq} from "../../../common/utils/axios";

/* DevData */
import {devEndorsedCoachArticles} from "../static/devdataarticles";

export const tryGetEndorsedArticles = () => {
    return async dispatch => {
        const res = await axiosReq('apiv1/public/coach/<int:advisor_id>/endoursed-courses', "GET", {}, devEndorsedCoachArticles);
        return res.allArticles;
    }
}