import React from 'react';
import {connect} from 'react-redux';

import SettingsPersonalInfo from './settingspersonalinfo';
// import SettingsNotifications from '../../deprecated/coach/containers/settingsnotifications';
// import SettingsSecurity from "./settingssecurity";
import SwitchToCoachButton from "./switchtocoachbutton";

const SettingsGeneral = ({ platform, user }) => (
    <>
        <SettingsPersonalInfo />
        {/* <SettingsSecurity /> */}
        {platform !== 'consumer' &&
            <>
                {/*<SettingsNotifications />*/}
            </>
        }
        {(((user.get("wmc") !== null && user.get("wmc").size !== 0) || user.get("isCoach")) && user.get("isLily")) &&
            <SwitchToCoachButton />
        }
    </>
);
const mapStateToProps = state => ({
    platform: state.common.user.get("platform"),
    user: state.common.user
});
export default connect(mapStateToProps, null)(SettingsGeneral);
