import React from "react";
import styled from "styled-components";
import ReactQuill from "react-quill";
import {v4 as uuid4} from "uuid";

import {colors} from "../../../common/components/colors";
import {FontBody14, FontBody16, FontSubtitle28, FontHeader18, FontBody24} from "../../../common/components/fonts";
import {images} from "../../../common/components/images";
import {QuillSlimNoHeight} from "../../../common/components/richtexteditor";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

export const Container = styled.div`
    max-width: 500px;
    color: ${colors.primary100};
    ${({inModal}) => inModal
        ? ``
        : `
            width: calc(100% - 20px);
            padding: 0px 10px;
            margin: 60px 15% 30px auto;
            @media screen and (max-width: 1440px) {
                margin: 60px auto 30px auto;
            }
            @media screen and (max-width: 1220px) {
                margin: 60px auto 0px auto;
            }
            @media screen and (max-width: 460px) {
                margin: 20px auto 0px auto;
            }
        `
    }
`;

export const Image = styled.img`
    height: 100%;
    max-height: 440px;
    width: 100%;
    max-width: 440px;
    object-fit: cover;
    object-position: 0 0;
    border-radius: 6px;
    @media screen and (max-width: 1220px) {
        max-height: 240px;
        max-width: 240px;
        margin-bottom: 34px;
    }
`;

export const EmptyImage = styled.div`
    height: 440px;
    width: 440px;
    @media screen and (max-width: 1220px) {
        display: none;
    }
`;

export const Avatar = styled.img`
    height: 40px;
    width: 40px;
    border-radius: 40px;
    margin-left: 10px;
`;

export const Desktop = styled.div`
    ${props => props.inModal !== true} {
        display: block;
        text-align: center;
        padding-left: 0px;
        width: auto;
    }
    ${props => props.inModal !== false} {
        padding-left: 30px;
        width: calc(100% - 30px);
    }
    @media screen and (max-width: 1220px) {
        display: none;
    }
`;
export const Mobile = styled.div`           
    display: none;
    @media screen and (max-width: 1220px) {
        display: block;
        text-align: center;
    }
`;

export const Arrow = styled.div`
    color: ${colors.primary70};
    margin-top: 11px;
`;

export const Gray = styled.div`
    color: ${colors.primary70};
`;

export const GrayBottom = styled.div`
    color: ${colors.primary70};
    margin-top: -6px;
    @media screen and (max-width: 1220px) {
        display: inline-block;
        vertical-align: top;
        margin-top: 0px;
        margin-left: 3px;
        font-size: 14px;
    }
`;

export const GrayTop = styled.div`
    color: ${colors.primary70};
    margin-top: 6px;
    @media screen and (max-width: 1220px) {
        display: inline-block;
        vertical-align: top;
        margin-top: 0px;
        font-size: 14px;
    }
`;

export const Back = styled.div`
    margin-top: 8px;
    margin-left: 10px;
`;

export const DollarSign = styled.div`
    margin-right: 0;
    ${props => props.inModal !== false} {
        margin-right: 4px;
        margin-top: -3px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    }
`;

export const Price = styled.div`
    ${props => props.inModal !== true} {
        margin-top: 25px;
        font-family: ${env.STYLES.FONTS.title.fontFamily};
        font-size: 26px;
    }
    ${props => props.inModal !== false} {
        font-family: ${env.STYLES.FONTS.body.fontFamily};
        font-size: 30px;
    }
    margin-bottom: 40px;
    @media screen and (max-width: 1220px) {
        margin-bottom: 0px;
        padding-bottom: 20px;
        ${props => props.inModal !== true} {
            border-bottom: none;
            margin-top: 25px;
        }
        ${props => props.inModal !== false} {
            border-bottom: 1px solid ${colors.border100};
            margin-top: 0px;
            font-size: 26px;
        }
    }
`;

export const TermsAndPrivacy = styled.div`
    margin-top: 120px;
    ${props => props.inModal !== true} {
        display: none;
    }
    ${props => props.inModal !== false} {
        display: block;
    }
`;

export const Terms = styled.a`
    display: inline-block;
    vertical-align: top;
    margin-right: 15px;
    color: ${colors.primary70};
    text-decoration: none;
`;

export const Privacy = styled.a`
    display: inline-block;
    vertical-align: top;
    color: ${colors.primary70};
    text-decoration: none;
`;

export const Money = styled.div`
    font-weight: 600;
    letter-spacing: -1px;
    color: ${colors.primary100};
    @media screen and (max-width: 1220px) {
        ${props => props.inModal !== false} {
            font-size: 30px;
        }
    }
`;

export const BackButton = styled.div`
    cursor: pointer;
    width: fit-content;
    margin-bottom: 35px;
`;

export const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;
export const InlineRecurring = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-left: 7px;
    @media screen and (max-width: 1220px) {
        margin-top: 14px;
    }
`;
export const PriceRecurring = styled(FontBody24)`
    display: inline-block;
    font-size: 22px;
`;
export const PoweredBy = styled.a`
    text-decoration: none;
    color: ${colors.primary100};
    display: inline-block;
    vertical-align: top;
`;
export const PoweredByLogoText = styled.div`
    color: ${colors.primary70};
    display: inline-block;
    vertical-align: top;
`;
export const PoweredByLogo = styled.img`
    display: inline-block;
    vertical-align: top;
    height: 24px;
    width: auto;
`;

export const Border = styled.div`
    margin: 0px 20px;
    width: 1px;
    background-color: ${colors.secondary30};
    height: 24px;
`;
export const Invoice = styled.div`
    margin-top: 10px;
    width: calc(100% - 30px);
    font-weight: normal;
`;


export const ProductPrice = ({description, amount, planType, recurringInterval, subscriptionDurationInMonths, inModal}) => (
    <Price inModal={inModal}>
        <Inline>
            <Money inModal={inModal}>
                {parseInt(amount) === 0
                    ? <>
                        {description === "" ? <Inline>Free</Inline> : <Inline><Invoice>{description}</Invoice></Inline>}
                    </>
                    : <>
                        <Inline><DollarSign inModal={inModal}>$</DollarSign></Inline>
                        <Inline>{parseInt(amount) === 0 ? "Free" : (parseInt(amount) / 100).toFixed(((parseInt(amount) / 100) % 1 !== 0) ? 2 : 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Inline>
                    </>
                }
            </Money>
        </Inline>
        {(planType === "recurring" && subscriptionDurationInMonths === null) &&
            <>
                {inModal
                    ? <PriceRecurring>/{recurringInterval === "year" ? "yr" : (recurringInterval === "quarter" ? "qtr" : "month")}</PriceRecurring>
                    : <InlineRecurring>
                        <GrayTop>per</GrayTop>
                        <GrayBottom>{recurringInterval}</GrayBottom>
                    </InlineRecurring>
                }
            </>
        }
        {(planType === "recurring" && subscriptionDurationInMonths !== null) &&
            <>
                {inModal
                    ? <PriceRecurring>&nbsp;{recurringInterval}ly}&nbsp;for&nbsp;{subscriptionDurationInMonths}&nbsp;{recurringInterval}s</PriceRecurring>
                    : <InlineRecurring>
                        <GrayTop>{recurringInterval}ly</GrayTop>
                        <GrayBottom>for&nbsp;{subscriptionDurationInMonths}&nbsp;{recurringInterval}s</GrayBottom>
                    </InlineRecurring>
                }
            </>
        }
    </Price>
);

const ProductDescription = styled(FontBody16)`
    margin-bottom: -10px;
    & .ql-editor {
        text-align: center;
    }
`;

export const ProductTitle = ({planType, title, inModal, description}) => {
    if(inModal) {
        return(
            <>
                <FontSubtitle28>{(planType === "one_time" ? (title) : ("Subscribe to "+title)).toUpperCase()}</FontSubtitle28>
                {(description !== null && description !== "" && title === "Willow Financial Life Coaching") &&
                    <ProductDescription>
                        <QuillSlimNoHeight bodyBorderColor={colors.white}>
                            <ReactQuill
                                id={uuid4()}
                                readOnly={true}
                                theme={null}
                                value={description}
                                bounds={'.app'}
                            />
                        </QuillSlimNoHeight>
                    </ProductDescription>
                }
            </>
        )
    } else {
        return(
            <>
                <Gray><FontHeader18>{planType === "one_time" ? (title) : ("Subscribe to "+title)}</FontHeader18></Gray>
            </>
        )
    }
};

export const ProductImage = ({coverPhoto, inModal}) => {
    if(inModal) {
        return null
    } else if(coverPhoto === null || coverPhoto === undefined || coverPhoto === "") {
            return(<EmptyImage />)
    } else {
        return(<Image src={coverPhoto} alt={""} />);
    }
};

export const ProductTermPrivacy = ({termsOfUseUrl, privacyPolicyUrl, inModal}) => (
    <TermsAndPrivacy inModal={inModal}>
        <PoweredBy target={"_blank"} href={"https://www.trustwillow.com/"}>
            <FontBody16><PoweredByLogoText>Powered by</PoweredByLogoText>&nbsp;<PoweredByLogo src={images.logo} alt={""} /></FontBody16>
        </PoweredBy>
        <Inline><Border /></Inline>
        <Terms href={termsOfUseUrl} target={"_blank"}><FontBody14>Terms</FontBody14></Terms>
        <Privacy href={privacyPolicyUrl} target={"_blank"}><FontBody14>Privacy</FontBody14></Privacy>
    </TermsAndPrivacy>
);

export const BackSection = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 30px;
    padding: 5px 0px 0px 5px;
    width: calc(100% - 5px);
    @media screen and (max-width: 460px) {
        padding: 5px 0px 0px 5px;
        width: calc(100% - 5px);
        margin-bottom: 15px;
    }
`;

export const BackSectionIcon = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
`;