import React from 'react';
import {connect} from 'react-redux';

/* Containers */
import Cards from './cards';
import CardsAdd from './cardsadd';
import CardsEdit from './cardsedit';

/* Middleware */
import {tryGetPaymentMethods} from "../middleware/cards";

class CheckoutFlow extends React.Component {
    state={"isLoading": true, "page": 1, "secondsAvailable": 0, "cardSelected": {}, "cardEditing": {}};

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.paymentMethods !== this.props.paymentMethods) {
            this.updatePaymentMethods();
        }
    }

    componentWillUnmount() {
        clearInterval(this.timeout);
    }

    timeout = 0;

    init = async () => {
        await this.props.tryGetPaymentMethods()
        await this.setState({"secondsAvailable": 300, "paymentMethods": this.props.paymentMethods.toJS(), "isLoading": false})
        this.updateSecondsAvailable()
    }

    updateSecondsAvailable = async () => {
        this.timeout = setInterval(() => {
            if(this.state.secondsAvailable === 0) {
                clearInterval(this.timeout);
                this.onClose();
            } else {
                this.setState({"secondsAvailable": this.state.secondsAvailable - 1});
            }
        }, 1000);
    };

    onClose = async () => {
        this.setState({"secondsAvailable": 0});
        this.props.close()
    };

    updatePaymentMethods = async () => {
        const cardSelected = this.props.paymentMethods.toJS().filter(p => {return(p.id === this.state.cardSelected.id)})[0];
        await this.setState({
            "paymentMethods": this.props.paymentMethods.toJS(),
            "cardSelected": (Object.keys(this.state.cardSelected).length > 0
                ? cardSelected === undefined ? {} : cardSelected
                : {}
            )
        });
    };

    onViewCards = async () => {
        await this.setState({"cardEditing": {}, "page": 1});
    };

    onViewCardAdd = async () => {
        await this.setState({"cardEditing": {}, "page": 2});
    };

    onViewCardEdit = async (cardEditing) => {
        await this.setState({"cardEditing": cardEditing, "page": 3});
    };

    render() {
        if(this.state.isLoading) {
            return null
        } else {
            if(this.state.page === 1) {
                return(
                    <Cards
                        onBack={this.props.back}
                        secondsAvailable={this.state.secondsAvailable}
                        paymentMethods={this.state.paymentMethods}
                        onCardSelect={this.props.onCardSelect}
                        onViewCardAdd={this.onViewCardAdd}
                        onViewCardEdit={this.onViewCardEdit}
                    />
                )
            } else if(this.state.page === 2) {
                return(
                    <CardsAdd
                        onBack={this.onViewCards}
                        secondsAvailable={this.state.secondsAvailable}
                        onCardSelect={this.props.onCardSelect}
                    />
                )
            } else if(this.state.page === 3) {
                return(
                    <CardsEdit
                        onBack={this.onViewCards}
                        secondsAvailable={this.state.secondsAvailable}
                        cardEditing={this.state.cardEditing}
                    />
                )
            } else {
                return null;
            }
        }
    }
}

const mapStateToProps = state => ({
    userId: state.common.user.get("userId"),
    paymentMethods: state.iframe.cards.cards.get("paymentMethods"),
    paymentMethodFetched: state.iframe.cards.cards.get("paymentMethodFetched"),
});

const mapDispatchToProps = dispatch => ({
    tryGetPaymentMethods: () => dispatch(tryGetPaymentMethods())
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutFlow);
