import React from 'react';
import styled from "styled-components";

/* Components */
import {InputLabel, RadioBoxWithLabel} from "../../../common/components/inputs";
import {LogoLoading} from "../../../common/components/loading";
import {FontBody16} from "../../../common/components/fonts";

export const InlineCheckbox = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 33%;
    min-width: 225px;
    margin-top: 15px;
    @media screen and (max-width: 640px) {
        width: 100%;
        min-width: 100%;
        display: block;
    }
`;
export const Type2 = styled.div`
    margin-top: 40px;
`;

class MeetingsCreateVideoPlatform extends React.Component {
    state = {
        platform: "willow",
        url: null,
        urlValid: true,
        isLoading: true
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "platform": this.props.videoPlatform.platform,
            "url": this.props.videoPlatform.url,
            "urlValid": true,
            "isLoading": false
        });
    };

    handleChange = async (platform) => {
        await this.setState({
            "platform": platform,
            "url": null,
            "urlValid": true,
        });
        await this.props.onChange(this.state);
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <>
                    <Type2><InputLabel><FontBody16>Video Platform*</FontBody16></InputLabel></Type2>
                    <div>
                        <InlineCheckbox>
                            <RadioBoxWithLabel
                                id={7}
                                action={() => {return this.handleChange("willow")}}
                                checked={this.state.platform === "willow"}
                                label={"Willow"}
                                subtext={""}
                                disabled={false}
                            />
                        </InlineCheckbox>
                        <InlineCheckbox>
                            <RadioBoxWithLabel
                                id={6}
                                action={() => {return this.handleChange("zoom")}}
                                checked={this.state.platform === "zoom"}
                                label={"Zoom"}
                                subtext={""}
                                disabled={false}
                            />
                        </InlineCheckbox>
                    </div>
                </>
            )
        }
    }
}

export default MeetingsCreateVideoPlatform;
