import { Map, List, fromJS } from 'immutable';

/* Immutable */

const PAYMENT_METHOD = 'PAYMENT_METHOD';
const PAYMENT_METHOD_ADD = 'PAYMENT_METHOD_ADD';
const PAYMENT_METHOD_FETCHING = 'PAYMENT_METHOD_FETCHING';
const PAYMENT_METHOD_SETUP_INTENT_FETCHING = 'PAYMENT_METHOD_SETUP_INTENT_FETCHING';
const PAYMENT_METHOD_UPDATE = 'PAYMENT_METHOD_UPDATE';
const PAYMENT_METHOD_UPDATING = 'PAYMENT_METHOD_UPDATING';
const PAYMENT_METHOD_DELETING = 'PAYMENT_METHOD_DELETING';
const PAYMENT_METHOD_DELETE = 'PAYMENT_METHOD_DELETE';
const PAYMENT_METHOD_STORE_RESET = 'PAYMENT_METHOD_STORE_RESET';

/* Actions */

export const setPaymentMethods = (paymentMethods) => ({'type': PAYMENT_METHOD, 'paymentMethods': paymentMethods});
export const setPaymentMethodAdd = (paymentMethod) => ({'type': PAYMENT_METHOD_ADD, 'paymentMethod': paymentMethod});
export const setPaymentMethodsFetching = (status) => ({'type': PAYMENT_METHOD_FETCHING, 'status': status});
export const setPaymentMethodSetupIntentFetching = (status) => ({'type': PAYMENT_METHOD_SETUP_INTENT_FETCHING, 'status': status});
export const setPaymentMethodUpdate = (updates) => ({'type': PAYMENT_METHOD_UPDATE, 'updates': updates});
export const setPaymentMethodUpdating = (status) => ({'type': PAYMENT_METHOD_UPDATING, 'status': status});
export const setPaymentMethodDeleting = (status) => ({'type': PAYMENT_METHOD_DELETING, 'status': status});
export const setPaymentMethodDelete = (id) => ({'type': PAYMENT_METHOD_DELETE, 'id': id});
export const setPaymentMethodStoreReset = () => ({'type': PAYMENT_METHOD_STORE_RESET});

/* Initial State */

const initialState = Map({
    paymentMethods: List(),
    paymentMethodFetched: false,
    paymentMethodFetching: false,
    paymentMethodSetupIntentFetching: false,
    paymentMethodUpdating: false,
    paymentMethodDeleting: false,
});

/* Reducer */

const cardsReducer = (state=initialState, action) => {
    switch (action.type) {
        case PAYMENT_METHOD:
            return state.merge({
                'paymentMethods': fromJS(action.paymentMethods),
                "paymentMethodFetched": true
            });
        case PAYMENT_METHOD_FETCHING:
            return state.merge({'paymentMethodFetching': action.status});
        case PAYMENT_METHOD_SETUP_INTENT_FETCHING:
            return state.merge({'paymentMethodSetupIntentFetching': action.status});
        case PAYMENT_METHOD_ADD:
            return state.merge({
                'paymentMethods': fromJS(state.get("paymentMethods").push(fromJS(action.paymentMethod)))
            });
        case PAYMENT_METHOD_UPDATE:
            return state.merge({
                'paymentMethods': fromJS(state.get("paymentMethods").map(f => {
                    if(f.get("id") === action.updates.paymentMethodId) {
                        return(fromJS({
                            ...f.toJS(),
                            "card": fromJS({
                                ...f.get("card").toJS(),
                                "exp_month": action.updates.expirationMonth,
                                "exp_year": action.updates.expirationYear,
                            }),
                            "billing_details": fromJS({
                                ...f.get("billing_details").toJS(),
                                "name": action.updates.name,
                                "address": fromJS({
                                    ...f.get("billing_details").get("address").toJS(),
                                    "postal_code": action.updates.postalCode,
                                }),
                            })
                        }))
                    } else {
                        return(f)
                    }
                }))
            });
        case PAYMENT_METHOD_DELETE:
            return state.merge({
                'paymentMethods': fromJS(state.get("paymentMethods").filter(f => {return(f.get("id") !== action.id)}))
            });
        case PAYMENT_METHOD_UPDATING:
            return state.merge({'paymentMethodUpdating': action.status});
        case PAYMENT_METHOD_DELETING:
            return state.merge({'paymentMethodDeleting': action.status});
        case PAYMENT_METHOD_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default cardsReducer;
