import React from 'react';

/* Components */
import {CommonBackground, CommonModal} from "../../../common/components/modal";

/* Containers */
import EmailerFlow from "../../emailer/containers/flow";

class IframeStoreEmailer extends React.Component {
    render() {
        return(
            <>
                <CommonBackground/>
                <CommonModal>
                    <EmailerFlow
                        id={this.props.profile.get("id")}
                        firstName={this.props.profile.get("firstName")}
                        lastName={this.props.profile.get("lastName")}
                        photo={this.props.profile.get("photo")}
                        inModal={true}
                        showCloseInsteadOfBack={true}
                        close={() => {return this.props.changeShowEmailer(false)}}
                    />
                </CommonModal>
            </>
        )
    }
}

export default IframeStoreEmailer;