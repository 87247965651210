import React from 'react';
import styled from "styled-components";

/* Components */
import {MessageSquare} from "react-feather";
import {CloseOutlined} from "@ant-design/icons";
import {colors} from "../../../common/components/colors";

const SupportButton = styled.div`
    position: fixed;
    top: auto;
    right: 40px;
    left: auto;
    bottom: 40px;
    border-radius: 40px;
    width: 60px;
    height: 60px;
    border: 1px solid ${colors.secondary100};
    cursor: pointer;
    color: ${colors.white};
    background-color: ${colors.secondary100};
    @media screen and (max-width: 640px) {
        display: none;
    }
    z-index: 5;
    & > div {
        padding: 0px 30px 0px 24px;
        font-size: 30px;
        line-height: 60px;
    }
`;

const CloseIconX = styled.div`
    margin-left: -8px;
`;
const CloseIcon = styled.div`
    margin-left: -4px;
    fill: #5E7281;
    z-index: 5;
    position: absolute;
    & svg {
        fill: ${colors.secondary100};
        height: 30px;
        width: 30px;
        margin-left: -6px;
        margin-top: 17px;
    }
`;
const CloseIconTwo = styled.div`
    margin-left: -6px;
    margin-top: 1px;
    z-index: 1;
    position: absolute;
    & svg {
        height: 30px;
        width: 30px;
    }
`;

const FloatingButton = ({close, isOpen}) => (
    <SupportButton onClick={close}>
        {!isOpen
            ? <>
                <CloseIcon><MessageSquare /></CloseIcon>
                <CloseIconTwo><MessageSquare /></CloseIconTwo>
            </>
            : <><CloseIconX><CloseOutlined /></CloseIconX></>
        }
    </SupportButton>
);

export default FloatingButton;