import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from "styled-components";
import Cookies from 'js-cookie';
import {v4 as uuid4} from 'uuid';

/* Containers */
import Availability from './availability';
import Product from './product';
import PaymentCard from './paymentcard';
import PaymentNone from './paymentnone';
import Receipt from './receipt';
import Login from './login';
import HRCode from './hrcode';
import Success from "../../success/containers/success";
// import Confirm from './confirm';

/* Middleware */
import {trySignUpTestExists} from "../middleware/auth";
import {tryAuthOnly} from "../../../routes/middleware/auth";
import {
    tryGetPaymentMethods,
    tryRegisterUserForPurchase,
    tryGetProductWillowEmpowered,
    tryGetPurchaseHistory,
    tryGetProductFromPlanId,
    tryGetProductWillowProgram,
    tryGetProductWillowSession,
    tryGetProductWillowOnDemand,
    tryValidateCode,
    tryGetProductAdvisorSession,
    tryGetProductTrainingSession,
    tryGetProductClientSession,
    tryGetProductClientAcquisition,
    tryGetProductCertification,
    tryGetProductCertificationRenewal,
    tryGetProductCertificationFree
} from "../middleware/checkout";
import {tryRegisterAdvisorAndWMC} from "../../signupadvisor/middleware/signup";

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {Left, Right, LoadingCenter, Subtitle} from '../components/flow';
import {ExportOutlined} from "@ant-design/icons";

/* Utils */
import {timeZoneOptionsByRegion} from "../../../common/utils/timezone2";
import {trackCheckoutProducts, trackPurchaseProducts} from '../../../common/utils/tracking';

const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;

class CheckoutFlow extends React.Component {
    static defaultProps = {
        planId: null,
        inModal: false,
        fromDashboard: false,
        onBack: () => {},
        onClose: () => {},
        onPaymentSuccess: () => {},
        onCloseCheckoutSuccess: () => {},
        quantity: null,
        webinarProductTitle: "",
        webinarProductPrice: 0,
        webinarProgramId: null,
        skipSuccessPage: false,
        promptHrCode: false,
        getHrCode: () => {},
        hideProduct: false,
        showBackBtn: true,
        showUserLogin: true,
        redirectExistingUserToLogin: false,
        onRedirectExistingUserToLogin: () => {},
        successSubtitle: "Thank you for your purchase! Next, schedule an appointment.",
        successButtonLabel: "Book",
        metadata: null,
        advisorData: {
            isReferredByPartner: false,
            wmcEmployeeId: null,
            partnerPrepaidAdvisorSessions: 0,
            partnerPrepaidTrainingSessions: 0
        },
        allowPublicDomainSignups: true
    };

    state={
        "step": 1,
        "selectedTime": null,
        "firstName": "",
        "lastName": "",
        "email": "",
        "meetingLength": 0,
        "meetingTitle": "",
        "meetingDescription": "",
        "loadedProduct": false,
        "userLoggedIn": false,
        "planType": "",
        "productType": "",
        "isLoading": true,
        "purchaseHistoryId": null,
        "paymentMethods": [],
        "code": "",
        "isAdvisor": false
    };

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        clearInterval(this.purchHistoryLoop);
    }

    init = async () => {
        if(this.props.planId === "willow_session") {
            await this.props.tryGetProductWillowSession(this.props.quantity)
        } else if(this.props.planId === "advisor_session") {
            await this.props.tryGetProductAdvisorSession(this.props.quantity)
        } else if(this.props.planId === "practice_management_session") {
            await this.props.tryGetProductTrainingSession(this.props.quantity)
        } else if(this.props.planId === "client_session") {
            await this.props.tryGetProductClientSession(this.props.quantity)
        } else if(this.props.planId === "willow_program") {
            await this.props.tryGetProductWillowProgram(this.props.webinarProductTitle, this.props.webinarProductPrice)
        } else if(this.props.planId === "willow_ondemand") {
            await this.props.tryGetProductWillowOnDemand()
        } else if(this.props.planId === "willow_empowered") {
            await this.props.tryGetProductWillowEmpowered()
        } else if(this.props.planId.includes("client_acquisition")) {
            let split = this.props.planId.split("_");
            let price = split[split.length - 1];
            await this.props.tryGetProductClientAcquisition(price)
        } else if(this.props.planId.includes("certification_session")) {
            let certType = this.props.planId.substring(0, 3);
            let split = this.props.planId.split("_");
            let price = split[split.length - 1];
            if(price === "cert1200") {
                await this.props.tryGetProductCertification(certType, 1200)
            } else if(price === "cert1000") {
                await this.props.tryGetProductCertification(certType, 1000)
            } else if(price === "cert900") {
                await this.props.tryGetProductCertification(certType, 900)
            } else if(price === "cert800") {
                await this.props.tryGetProductCertification(certType, 800)
            } else if(price === "cert600") {
                await this.props.tryGetProductCertification(certType, 600)
            } else if(price === "cert400") {
                await this.props.tryGetProductCertification(certType, 400)
            } else if(price === "cert1") {
                await this.props.tryGetProductCertification(certType, 1)
            } else if(price === "cert0") {
                await this.props.tryGetProductCertificationFree(certType)
            } else if(price === "renewal") {
                await this.props.tryGetProductCertificationRenewal(certType)
            }
        } else if(this.props.planId !== null) {
            await this.props.tryGetProductFromPlanId(this.props.planId);
        }
        if(
            this.props.planId === "advisor_session" ||
            this.props.planId === "client_session" ||
            this.props.planId === "willow_ondemand" ||
            this.props.planId === "willow_empowered" ||
            this.props.planId.includes("certification_session")
        ) {
            await this.setState({"isAdvisor": true})
        }
        await this.codeOrCard();
        await this.props.getHrCode("")
        const user = await this.props.tryAuthOnly(null, false, false);
        if(user !== null) {
            await this.setState({
                "userLoggedIn": true,
                "planType": await this.planType(),
                "productType": this.props.plan.get("product").get("serviceType"), // online, offline, download (store info for next steps like planType()
                "paymentMethods": await this.props.tryGetPaymentMethods(),
                "isLoading": false
            });
        } else {
            await this.setState({
                "userLoggedIn": false,
                "planType": await this.planType(),
                "productType": this.props.plan.get("product").get("serviceType"), // online, offline, download (store info for next steps like planType()
                "paymentMethods": {},
                "isLoading": false
            });
        }
        if(user !== null) {
            this.props.trackCheckoutProducts({
                "productType": this.props.plan.get("product").get("serviceType"),
                "productTitle": this.props.plan.get("product").get("title"),
                "planType": this.state.planType,
                "planId": this.props.planId,
                "planQuantity": this.props.quantity,
                "planPrice": this.props.plan.get("amount"),
                "planMonthsToCharge": this.props.plan.get("subscriptionDurationInMonths") === null ? "forever" : this.props.plan.get("subscriptionDurationInMonths")
            });
        }
    };

    setStep = async (step) => {
        await this.setState({"step": step});
    };

    checkUserExists = async (firstName, lastName, email, phone) => {
        await this.props.trySignUpTestExists(email, phone);
        if (this.props.iframeAuthTestUserExistsError !== null) {
            if(this.props.redirectExistingUserToLogin) {
                await this.props.onRedirectExistingUserToLogin()
            }
            return {"status": true, "user": this.props.user.toJS()};
        } else {
            const region = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const timeZone = timeZoneOptionsByRegion(region);
            if(this.state.isAdvisor) {
                await this.props.tryRegisterAdvisorAndWMC(firstName, lastName, email, phone, timeZone, uuid4(), this.props.advisorData.isReferredByPartner, this.props.advisorData.wmcEmployeeId, this.props.advisorData.partnerPrepaidAdvisorSessions, this.props.advisorData.partnerPrepaidTrainingSessions);
            } else {
                let coachId = (this.state.planType === "free" || this.state.planType === "invoicing" )
                    ? {"coachId": this.props.plan.get("product").get("expert").get("id")}
                    : {};
                await this.props.tryRegisterUserForPurchase(firstName, lastName, email, phone, timeZone, this.state.code, coachId);
            }
            await this.props.tryAuthOnly(null, true, true);
            return {"status": false, "user": this.props.user.toJS()};
        }
    };

    loginSuccess = async () => {
        await this.init();
        return this.setState({'step': 1});
    };

    planType = async () => {
        let planType = this.props.plan.get("planType");
        let amount = this.props.plan.get("amount");
        let description = this.props.plan.get("description");
        let subscriptionDurationInMonths = this.props.plan.get("subscriptionDurationInMonths");
        if (this.state.code !== "") {
            return "free"
        }
        if(planType === "one_time") {
            if(amount === 0 && description === "") {
                return("free")
            } else if(amount === 0 && description !== "") {
                return("invoicing")
            } else if(amount !== 0) {
                return("flatFee")
            }
        } else if(planType === "recurring") {
            if(subscriptionDurationInMonths === null) {
                return("subscription")
            } else {
                return("limitedPayments")
            }
        }
    };

    getPaymentHistoryId = async () => {
        if(this.state.planType === "free" || this.state.planType === "invoicing") {
            await this.setState({"purchaseHistoryId": "expertFree"});
        } else {
            this.purchHistoryLoop = setInterval(async () => {
                const purchaseHistory = await this.props.tryGetPurchaseHistory();
                const sorted = purchaseHistory.sort((a, b) => {return(new Date(b.purchasedOn) - new Date(a.purchasedOn))});
                if(sorted[0].purchaseHistoryId !== undefined) {
                    this.setState({"purchaseHistoryId": sorted[0].purchaseHistoryId});
                    clearInterval(this.purchHistoryLoop);
                }
            }, 1000);
        }
    };

    paymentSuccessful = async () => {
        this.props.trackPurchaseProducts({
            "productType": this.props.plan.get("product").get("serviceType"),
            "productTitle": this.props.plan.get("product").get("title"),
            "planType": this.state.planType,
            "planId": this.props.planId,
            "planQuantity": this.props.quantity,
            "planPrice": this.props.plan.get("amount"),
            "planMonthsToCharge": this.props.plan.get("subscriptionDurationInMonths") === null ? "forever" : this.props.plan.get("subscriptionDurationInMonths")
        });
        await this.props.onPaymentSuccess();
        if(!this.props.skipSuccessPage) {
            return this.setStep(3)
        }
    };

    checkCode = async (code) => {
        const hrCode = await this.props.tryValidateCode(code)
        if (hrCode !== null) {
            this.setState({"code": code})
            return true
        } else {
            this.setState({'code': ""})
            return false
        }
    }

    codeSuccess = async () => {
        this.setState({'planType': 'free'})
        await this.props.getHrCode(this.state.code)
        if (this.state.userLoggedIn) {
            return this.paymentSuccessful()
        } else {
            return this.setStep(1)
        }
        
    };

    codeOrCard = () => {
        if (this.props.promptHrCode) {
            this.setState({'step': 0})
        } else {
            this.setState({'step': 1})
        }
    }

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if (this.state.step === 0) {
                return (
                    <>
                        <Left inModal={this.props.inModal}>
                            {!this.props.hideProduct &&
                                <Product
                                    inModal={this.props.inModal}
                                    onBack={this.props.onBack}
                                    showPrice={false}
                                    showBackBtn={this.props.showBackBtn}
                                />
                            }
                        </Left>
                        <Right inModal={this.props.inModal}>
                            <HRCode
                                userLoggedIn={this.state.userLoggedIn}
                                inModal={this.props.inModal}
                                product={this.props.plan.get("product")}
                                fromDashboard={this.props.fromDashboard}
                                skip={() => {return this.setStep(1)}}
                                codeSuccess={this.codeSuccess}
                                checkCode={(code) => {return this.checkCode(code)}}
                            />
                        </Right>
                    </>
                )
            } else if(this.state.step === 1 || this.state.step === 2) {
                return (
                    <>
                        <Left inModal={this.props.inModal}>
                            {!this.props.hideProduct &&
                                <Product
                                    inModal={this.props.inModal}
                                    onBack={() => {return this.props.promptHrCode ? this.setState({'step': 0}) : this.props.onBack()}}
                                    showPrice={/*this.state.planType !== 'free' && */this.state.planType !== 'invoicing'}
                                    showBackBtn={this.props.showBackBtn}
                                />
                            }
                        </Left>
                        <Right inModal={this.props.inModal}>
                            {this.state.step === 1 &&
                                <>
                                    {(this.state.planType === "free" || this.state.planType === "invoicing")
                                        ? <PaymentNone
                                            userLoggedIn={this.state.userLoggedIn}
                                            showUserLogin={this.props.showUserLogin}
                                            checkUserExists={this.checkUserExists}
                                            paymentSuccessful={this.paymentSuccessful}
                                            login={() => {return this.setStep(2)}}
                                            inModal={this.props.inModal}
                                            product={this.props.plan.get("product")}
                                            fromDashboard={this.props.fromDashboard}
                                            allowPublicDomainSignups={this.props.allowPublicDomainSignups}
                                        />
                                        : <PaymentCard
                                            userLoggedIn={this.state.userLoggedIn}
                                            showUserLogin={this.props.showUserLogin}
                                            checkUserExists={this.checkUserExists}
                                            paymentSuccessful={this.paymentSuccessful}
                                            login={() => {return this.setStep(2)}}
                                            inModal={this.props.inModal}
                                            planId={this.props.plan.get("id")}
                                            planType={this.props.plan.get("planType")}
                                            product={this.props.plan.get("product")}
                                            paymentMethods={this.state.paymentMethods}
                                            quantity={this.props.quantity}
                                            webinarProgramId={this.props.webinarProgramId}
                                            fromDashboard={this.props.fromDashboard}
                                            metadata={this.props.metadata}
                                            allowPublicDomainSignups={this.props.allowPublicDomainSignups}
                                        />
                                    }
                                </>
                            }
                            {this.state.step === 2 &&
                                <Login
                                    email={this.state.email}
                                    signup={() => {return this.setState({'step': 1})}}
                                    loginSuccess={this.loginSuccess}
                                    inModal={this.props.inModal}
                                />
                            }
                        </Right>
                    </>
                )
            } else if(this.state.step === 3) {
                if(this.props.inModal) {
                    return(
                        <Success
                            back={this.props.onCloseCheckoutSuccess}
                            title={'PURCHASE COMPLETE!'}
                            subtitle={<Subtitle>{this.props.successSubtitle}</Subtitle>}
                            buttonLabel={this.props.successButtonLabel}
                        />
                    )
                } else {
                    return(
                        <Receipt
                            getPaymentHistoryId={this.getPaymentHistoryId}
                            planType={this.state.planType}
                            plan={this.props.plan}
                            product={this.props.plan.get("product")}
                            setStep={this.setStep}
                        />
                    )
                }
            } else if(this.state.step === 4) {
                return(
                    <Availability
                        userId={this.props.plan.get("product").get("expert").get("id")}
                        userFirstName={this.props.plan.get("product").get("expert").get("firstName")}
                        userLastName={this.props.plan.get("product").get("expert").get("lastName")}
                        userPhotoUrl={this.props.plan.get("product").get("expert").get("profilePhotoUrl")}
                        eventCustomTitle={this.props.plan.get("product").get("title")}
                        eventCustomDescription={this.props.plan.get("product").get("description")}
                        eventCustomMinutes={this.props.plan.get("product").get("sessionLength")}
                        confirmCloseBtnLabel={<><Inline><ExportOutlined /></Inline>&nbsp;Dashboard</>}
                        confirmOnClose={() => {return this.props.history.push("/verify")}}
                        purchaseHistoryId={this.state.purchaseHistoryId}
                    />
                )
            } else {
                return null
            }
        }
    }

}

const mapStateToProps = state => ({
    iframeAuthTestUserExistsError: state.iframe.checkout.auth.get("iframeAuthTestUserExistsError"),
    plan: state.iframe.checkout.checkout.get("checkoutProduct"),
    user: state.common.user
});

const mapDispatchToProps = dispatch => ({
    tryGetProductFromPlanId: (planId) => dispatch(tryGetProductFromPlanId(planId)),
    tryGetProductWillowSession: (quantity) => dispatch(tryGetProductWillowSession(quantity)),
    tryGetProductAdvisorSession: (quantity) => dispatch(tryGetProductAdvisorSession(quantity)),
    tryGetProductTrainingSession: (quantity) => dispatch(tryGetProductTrainingSession(quantity)),
    tryGetProductClientSession: (quantity) => dispatch(tryGetProductClientSession(quantity)),
    tryGetProductWillowProgram: (title, price) => dispatch(tryGetProductWillowProgram(title, price)),
    trySignUpTestExists: (email, phone) => dispatch(trySignUpTestExists(email, phone)),
    tryRegisterUserForPurchase: (firstName, lastName, email, phone, timeZone, hrCode, coachId) => dispatch(tryRegisterUserForPurchase(firstName, lastName, email, phone, timeZone, hrCode, coachId)),
    tryAuthOnly: (email, trackNewRegistration, forceAuth) => dispatch(tryAuthOnly(email, trackNewRegistration, forceAuth)),
    tryGetPurchaseHistory: () => dispatch(tryGetPurchaseHistory()),
    tryGetPaymentMethods: () => dispatch(tryGetPaymentMethods()),
    tryValidateCode: (code) => dispatch(tryValidateCode(code)),
    trackCheckoutProducts: (checkoutDetails) => dispatch(trackCheckoutProducts(checkoutDetails)),
    trackPurchaseProducts: (purchaseDetails) => dispatch(trackPurchaseProducts(purchaseDetails)),
    tryGetProductWillowOnDemand: () => dispatch(tryGetProductWillowOnDemand()),
    tryGetProductWillowEmpowered: () => dispatch(tryGetProductWillowEmpowered()),
    tryGetProductClientAcquisition: (price) => dispatch(tryGetProductClientAcquisition(price)),
    tryGetProductCertification: (certType, price) => dispatch(tryGetProductCertification(certType, price)),
    tryGetProductCertificationRenewal: (certType) => dispatch(tryGetProductCertificationRenewal(certType)),
    tryGetProductCertificationFree: (certType) => dispatch(tryGetProductCertificationFree(certType)),
    tryRegisterAdvisorAndWMC: (firstName, lastName, email, phone, timeZone, password, isReferredByPartner, wmcEmployeeId, partnerPrepaidAdvisorSessions, partnerPrepaidTrainingSessions) => dispatch(tryRegisterAdvisorAndWMC(firstName, lastName, email, phone, timeZone, password, isReferredByPartner, wmcEmployeeId, partnerPrepaidAdvisorSessions, partnerPrepaidTrainingSessions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckoutFlow));
