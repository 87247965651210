import React from 'react';
import styled from "styled-components";

/* Components */
import {InputLabel, RadioBoxWithLabel} from "../../../common/components/inputs";
import {LogoLoading} from "../../../common/components/loading";
import {FontBody16} from "../../../common/components/fonts";

export const InlineCheckbox = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 33%;
    min-width: 225px;
    margin-top: 15px;
    @media screen and (max-width: 640px) {
        width: 100%;
        min-width: 100%;
        display: block;
    }
`;
export const Type2 = styled.div`
    margin-top: 40px;
`;

class MeetingsCreateType extends React.Component {
    state = {
        activeChecked: true,
        deactiveChecked: false,
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "activeChecked": this.props.isGroupSession.activeChecked,
            "deactiveChecked": this.props.isGroupSession.deactiveChecked,
            "isLoading": false
        });
    };

    handleActivate = async () => {
        await this.setState({"activeChecked": true, "deactiveChecked": false});
        await this.props.onValidate(this.state);
    };

    handleDeactivate = async () => {
        await this.setState({"activeChecked": false, "deactiveChecked": true});
        await this.props.onValidate(this.state);
    };


    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <>
                    <Type2><InputLabel><FontBody16>Interaction*</FontBody16></InputLabel></Type2>
                    <div>
                        <InlineCheckbox>
                            <RadioBoxWithLabel
                                id={7}
                                action={this.handleDeactivate}
                                checked={this.state.deactiveChecked}
                                label={"Webinar"}
                                subtext={"Only hosts have audio/video controls."}
                                disabled={false}
                            />
                        </InlineCheckbox>
                        <InlineCheckbox>
                            <RadioBoxWithLabel
                                id={6}
                                action={this.handleActivate}
                                checked={this.state.activeChecked}
                                label={"Group session"}
                                subtext={"Everyone has audio/video controls."}
                                disabled={false}
                            />
                        </InlineCheckbox>
                    </div>
                </>
            )
        }
    }
}

export default MeetingsCreateType;
