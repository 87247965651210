import createEmailTemplates from './templates/emails';
import createSMSTemplates from "./templates/sms";
import consumerFAQ from "./faqs/faqconsumer";
import expertFAQ from "./faqs/faqexpert";
import companyFAQ from "./faqs/faqcompany";
import expertResources from "./resources/resourcesexpert";
import enterpriseNav from "./nav/naventerprise";
import consumerNav from "./nav/navconsumer";
import consumerSignUpForm from "./forms/consumersignupform";
import createMyGoalForm from "./forms/creategoalform"
import matchingForms from './forms/matchingforms';
import clientData from './clients/clientdata';
import {promotionalCodes, shouldAllowPurchase, ceritificationDiscounts} from "./clients/advisordata";
import images from "./styles/images";
import colors from "./styles/colors";
import fonts from "./styles/fonts";
import {expertSubCategories, convertCourseIdToExpertId, parentCourseCategory} from './care/coachingcategories';
import printExpertCategories from "./care/printexpertcategories";
import coachCategories from "./apiresponses/coachcategories";
import learnCategories from "./apiresponses/learncategories";
import learnContent from "./apiresponses/learncontent";
// import {matchProgramAndVideo, matchQuizAndVideo, matchVideoAndQuiz} from "./trainings/mapping";
import {checkVanityWmcId} from "./styles/checkvanityurl";

export default {
    ENVIRONMENT: (window.location.hostname === 'secure.trustwillow.com' ? "PRODUCTION" : "STAGING"),
    URL: {
        DOMAIN: "trustwillow.com",
        HOSTNAME: 'secure.trustwillow.com',
        API: "api.trustwillow.com",
        AUTH: "secure.trustwillow.com",
    },
    TRACKING: {
        SENTRY_DSN_STAGING: 'https://75ae0b618f8d4fb1bff505295d68b7cb@o376668.ingest.sentry.io/5285866',
        SENTRY_DSN_PRODUCTION: 'https://9f5ce0653c6f40868c6525a9ff21bc3e@o376668.ingest.sentry.io/5285584',
        GOOGLE_TAG_MANAGER_ID: 'GTM-TJM54K2',
        GOOGLE_ANALYTICS_ID: 'UA-160759311-1',
        MIXPANEL_ID: '354d35eaef17984d89709d6b7f7a11a8',
        FACEBOOK_ID: '538048736856238',
        FULLSTORY_ORG_ID: 'S90AH',
        TABOOLA: '1317879'
    },
    HEAD: {
        TITLE: "Willow",
        FAVICON_16: images.favicon16px,
        FAVICON_32: images.favicon32px,
        FAVICON_256: images.favicon256px,
        COLOR: colors.primary100,
        BACKGROUND_COLOR: colors.white,
        FONT_BODY: {FAMILY: fonts.body.fontFamily, URL: fonts.body.fontFileName, FORMAT: fonts.body.fontFormat},
        FONT_HEADER: {FAMILY: fonts.header.fontFamily, URL: fonts.header.fontFileName, FORMAT: fonts.header.fontFormat},
        FONT_SUBTITLE: {FAMILY: fonts.subtitle.fontFamily, URL: fonts.subtitle.fontFileName, FORMAT: fonts.subtitle.fontFormat},
        FONT_TITLE: {FAMILY: fonts.title.fontFamily, URL: fonts.title.fontFileName, FORMAT: fonts.title.fontFormat},
    },
    STYLES: {
        IMAGES: images,
        COLORS: colors,
        FONTS: fonts,
    },
    ENTERPRISE: {
        NAV: enterpriseNav,
        EXPERT: {
            AUTH: {
                CHECK_PARTNERED_WITH_COMPANY: true,
                SUPPORT_URL: "/firms",
                PARTNER_URL: "/home",
                HOME_URL: "/home",
            },
            FAQS: expertFAQ,
            TEMPLATES: {
                EMAIL: createEmailTemplates,
                SMS: createSMSTemplates,
            },
            RESOURCES: {
                TITLE: "Coaching Resources",
                DATA: expertResources
            },
            PROFILE: {
                PRINT_EXPERT_CATEGORIES: printExpertCategories
            }
        },
        COMPANY: {
            AUTH: {
                HOME_URL: "/dashboard",
            },
            CHECK_VANITY_WMC_ID: checkVanityWmcId,
            FAQS: companyFAQ,
            CARE: {
                PARENT_COURSE_CATEGORIES: parentCourseCategory,
                PRINT_EXPERT_CATEGORIES: printExpertCategories
            },
            // TRAININGS: {
            //     MATCH_PROGRAM_TO_VIDEO: matchProgramAndVideo,
            //     MATCH_VIDEO_TO_QUIZ: matchVideoAndQuiz,
            //     MATCH_QUIZ_TO_VIDEO: matchQuizAndVideo,
            // },
            SIGN_UP: {
                IS_REFERRED_BY_PARTNER: promotionalCodes
            },
            PLANS: {
                SHOULD_ALLOW_PURCHASE: shouldAllowPurchase,
                CERTIFICATION_DISCOUNT: ceritificationDiscounts
            }
        }
    },
    CONSUMER: {
        AUTH: {
            REQUIRE_TEAM_TO_ACCESS_DASHBOARD: false,
            HOME_URL: "/home",
            SIGN_UP_URL: "/signup",
        },
        NAV: consumerNav,
        FAQS: consumerFAQ,
        SIGN_UP: {
            MATCH_AND_SCHEDULE: {
                MATCHING_TITLE: "Help Your Financial Coach Prepare",
                MATCHING_FORM: consumerSignUpForm,
                SCHEDULE_TITLE: "Schedule a Free 15-Minute Call",
                SCHEDULE_SESSION_MINUTES: 15
            },
            MATCH_PUBLIC_PAGE: {
                MATCHING_FORMS: matchingForms,
                CLIENT_DATA: clientData,
            }
        },
        PROGRESS: {
            MY_GOAL_FORM: createMyGoalForm
        },
        TEAM: {
            PRODUCTS: {
                DOMAIN_SOLD: {
                    SHOW: true,
                    PRODUCT_TITLE: "Willow Financial Life Coaching",
                    TRIAL_TITLE: "Free 15-minute call",
                    TRIAL_SESSION_MINUTES: 15,
                    TRIAL_CHANGE_BUTTON_TITLE: "Change coach",
                    PAID_SESSION_MINUTES: 45
                },
                COMPANY_SOLD: {
                    SHOW: true,
                    PRODUCT_TITLE: "Willow Financial Life Coaching",
                    PAID_SESSION_MINUTES: 45
                },
                EXPERT_SOLD: {
                    SHOW: true
                },
                GENERAL: {
                    SHOW: true,
                    PRODUCT_TITLE: "Willow Financial Life Coaching",
                    PAID_SESSION_MINUTES: 45
                },
            }
        },
        CARE: {
            EXPERT_SUB_CATEGORIES: expertSubCategories,
            CONVERT_COURSE_ID_TO_EXPERT_ID: convertCourseIdToExpertId,
            PRINT_EXPERT_CATEGORIES: printExpertCategories
        },
        API_RESPONSES: {
            COACH_CATEGORIES: coachCategories,
            LEARN_CATEGORIES: learnCategories,
            LEARN_CONTENT: learnContent
        }
    }
};