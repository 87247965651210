import React from 'react';

/* Components */
import {PageActions, PageContent, PageTabs} from "../components/teamone";

/* Containers */
import CoachMember from './coachmember';

const CoachingTeamOne = () => (
    <PageTabs>
        <PageContent>
            <CoachMember /> 
        </PageContent>
    </PageTabs>
);

export default CoachingTeamOne;