import React from "react";
import moment from "moment";
import {connect} from 'react-redux';

/* Components */
import {ActionBox, FitWidthButton} from "../components/clientsdetail";
import {FontBody12, FontHeader21, FontTitle18} from "../../common/components/fonts";
import {ReadOnlyValueInput} from "../../common/components/inputs";
import {ButtonInactive, ButtonPrimary, ButtonSecondary} from "../../common/components/buttons";
import {Center, Photo, Logo, SourceTitle, Gray, Cursor} from '../components/contactcoaching';

/* Containers */
// import {ChangeSessions} from "./contactcoachingchangesessions";

/* Static */
import {images} from "../../common/components/images";
import {tryGetTrialCompleted} from "../middleware/clientstrialcomplete";



export const ContactSourcedByFirm = ({coachProgram, scheduleSession, generalSessions}) => (
    <ContactSourcedByWrapper
        scheduleMeeting={scheduleSession}
        generalSessions={generalSessions}
        boxTitle={"Firm"}
        sourceLogo={coachProgram.wmcDetails?.logoUrl}
        sourceTitle={coachProgram.wmcDetails?.companyName}
    />
);

export const ContactSourcedByAdvisor = ({coachProgram, requestMoreSessionsFromAdvisor, scheduleSession, generalSessions}) => (
    <ActionBox>
        <FontTitle18>Assigned By</FontTitle18>
        <SourceTitle><FontBody12>Source</FontBody12></SourceTitle>
        {coachProgram.advisorSource.photo !== null && <Photo><img src={coachProgram.advisorSource.photo} alt={""} /></Photo>}
        <Center><FontHeader21>{coachProgram.advisorSource.first+" "+coachProgram.advisorSource.last}</FontHeader21></Center>
        <ReadOnlyValueInput title={"Session Length"} value={coachProgram.sessionLength} />
        {coachProgram.remainingSessions > 1000
            ? <ReadOnlyValueInput title={"Number of Sessions"} value={"Infinite"} />
            : <ReadOnlyValueInput title={"Number of Sessions"} value={coachProgram.remainingSessions+" Remaining ("+(coachProgram.remainingSessions+coachProgram.completedSessions)+" Total)"} />
        }
        {/*<ReadOnlyValueInput title={"Completed"} value={coachProgram.hoursInCoaching+" Hours"} />*/}
        <ReadOnlyValueInput title={"General Sessions"} value={generalSessions} />
        <br />
        <FitWidthButton onClick={() => {return scheduleSession()}}><ButtonPrimary canSubmit={false} label={"Schedule Session"} /></FitWidthButton>
        {coachProgram.remainingSessions <= 1000 &&
            <><br /><FitWidthButton onClick={() => {requestMoreSessionsFromAdvisor()}}><ButtonSecondary canSubmit={false} label={"Request More Sessions"} /></FitWidthButton></>
        }
    </ActionBox>
);


const renamePackage = (program) => {
    if(program === '1-month' || program === '1-month with one call') {return ("Financial Checkup");}
    if(program === '3-month' || program === '3-month subscription' || program === '6-month') {return ('Momentum Program');}
    if(program === '12-month' || program === '12-month subscription') {return ('Concierge Program');}
    else {return program;}
};


const ProgramDetails = ({coachProgram}) => (
    <>
        <ReadOnlyValueInput title={"Program"} value={renamePackage(coachProgram.package)} />
        <ReadOnlyValueInput title={"Session Length"} value={coachProgram.sessionLength} />
        <ReadOnlyValueInput title={"Number of Sessions"} value={coachProgram.remainingSessions+" Remaining ("+(coachProgram.remainingSessions+coachProgram.completedSessions)+" Total)"} />
        <ReadOnlyValueInput title={"Last Purchase"} value={moment(coachProgram.purchaseDate).format("ll").toString()} />
    </>
);


class ContactSourcedBy extends React.Component {
    static defaultProps = {
        scheduleMeeting: () => {},
        tryGetTrialCompleted: () => {},
        generalSessions: "0",
        boxTitle: "Coaching",
        sourceLogo: images.logoIcon,
        sourceTitle: "Willow",
    }

    state = {
        "isTrialComplete": false,
        "isLoading": true
    }

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const prospectId = this.props.client.get("prospectId");
        this.setState({
            "isTrialComplete": await this.props.tryGetTrialCompleted(prospectId),
            "isLoading": false
        })
    }

    render() {
        if(this.state.isLoading) {
            return null
        } else {
            return(
                <ActionBox>
                    <FontTitle18>{this.props.boxTitle}</FontTitle18>
                    <SourceTitle><FontBody12>Source</FontBody12></SourceTitle>
                    {this.props.sourceLogo !== null && <Logo><img src={this.props.sourceLogo} alt={""} /></Logo>}
                    <Center><FontHeader21>{this.props.sourceTitle}</FontHeader21></Center>
                    <ReadOnlyValueInput title={"Trial Appointment"} value={this.state.isTrialComplete ? "Completed" : "Not completed"} />
                    <ReadOnlyValueInput title={"Remaining"} value={this.props.generalSessions+" appointments"} />

                    <br />
                    <FitWidthButton onClick={() => {return this.props.scheduleMeeting()}}>
                        <ButtonPrimary canSubmit={false} label={this.state.isTrialComplete ? "Book appointment" : "Book trial appointment"} />
                    </FitWidthButton>
                </ActionBox>
            )
        }
    }
}

const mapStateToProps = state => ({
    client: state.enterprise.clientsDetail.get("client"),
})

const mapDispatchToProps = dispatch => ({
    tryGetTrialCompleted: (prospectId) => dispatch(tryGetTrialCompleted(prospectId))
})

const ContactSourcedByWrapper = connect(mapStateToProps, mapDispatchToProps)(ContactSourcedBy);



const ContactSourcedByCoach = ({photo, firstName, lastName, isPaying, trialCompleted, coachProgram, emailPurchaseLink, scheduleMeeting, generalSessions}) => (
    <ActionBox>
        <FontTitle18>Coaching</FontTitle18>
        <SourceTitle><FontBody12>Source</FontBody12></SourceTitle>
        {photo !== null && <Photo><img src={photo} alt={""} /></Photo>}
        <Center><FontHeader21>{firstName+" "+lastName}</FontHeader21></Center>
        {/*{isPaying*/}
        {/*    ? <ProgramDetails coachProgram={coachProgram} />*/}
        {/*    : <ReadOnlyValueInput title={"Program"} value={"No Program"} />*/}
        {/*}*/}
        <ReadOnlyValueInput title={"Remaining"} value={generalSessions+" appointments"} />
        <br />
        {/*{trialCompleted*/}
        {/*    ? <FitWidthButton onClick={() => {return emailPurchaseLink()}}><ButtonSecondary canSubmit={false} label={"Email Purchase Link"} /></FitWidthButton>*/}
        {/*    : <Gray><FontBody12>After your first meeting, you will be able to send a purchase link.</FontBody12></Gray>*/}
        {/*}*/}
        {/*<br />*/}
        {/*<FitWidthButton onClick={() => {return scheduleMeeting()}}><ButtonPrimary canSubmit={false} label={(isPaying || generalSessions > 0) ? "Schedule Session" : "Schedule Free Session"} /></FitWidthButton>*/}
        <FitWidthButton onClick={() => {return scheduleMeeting()}}><ButtonPrimary canSubmit={false} label={"Book appointment"} /></FitWidthButton>
    </ActionBox>
);



export class ContactSourcedByCoachOrWillow extends React.Component {
    // state={"changeSessions": false, "showCancel": false};
    //
    // componentWillUnmount() {
    //     clearInterval(this.timeout)
    // }
    //
    // cancel = async () => {
    //     await this.setState({"changeSessions": false, "showCancel": false});
    // };
    //
    // changeSessions = async () => {
    //     await this.setState({"changeSessions": true, "showCancel": true});
    // };
    //
    // save = async (coachId, count, coachName) => {
    //     await this.setState({"showCancel": false});
    //     await this.props.giftFreeSessions(coachId, count);
    //     this.timeout = setTimeout(() => {this.setState({"changeSessions": false})}, 3000);
    // };

    render() {
        if(this.props.coachProgram.advisorSource === null && this.props.coachProgram.coachSource === null) {
            return(
                <ContactSourcedByWrapper
                    scheduleMeeting={this.props.scheduleSession}
                    generalSessions={this.props.generalSessions}
                    boxTitle={"Coaching"}
                    sourceLogo={images.logoIcon}
                    sourceTitle={"Willow"}
                />
            )
        } else {
            return(
                <ContactSourcedByCoach
                    photo={this.props.coachProgram.coachSource.photo}
                    firstName={this.props.coachProgram.coachSource.first}
                    lastName={this.props.coachProgram.coachSource.last}
                    isPaying={this.props.coachProgram.package !== "trial"}
                    trialCompleted={this.props.coachProgram.package === "trial" ? this.props.coachProgram.trialCallComplete : true}
                    coachProgram={this.props.coachProgram}
                    emailPurchaseLink={this.props.emailPurchaseLink}
                    scheduleMeeting={this.props.scheduleSession}
                    generalSessions={this.props.generalSessions}
                />
            )
        }
    }

    /*
        <>
            <FitWidthButton onClick={this.changeSessions}><ButtonSecondary canSubmit={false} label={"Give Free Sessions"} /></FitWidthButton>
            {this.state.changeSessions &&
                <>
                    <br />
                    <ChangeSessions
                        initialCount={this.props.coachProgram.remainingSessions}
                        expertId={this.props.coachProgram.coachSource.coachId}
                        expertName={this.props.coachName}
                        notifyFirstName={null}
                        updateSessions={this.save}
                        subTitle={"Update Sessions Remaining"}
                        useSessionDelta={true}
                    />
                    {this.state.showCancel &&
                        <>
                            <br />
                            <Center onClick={this.cancel}><Cursor><ButtonInactive canSubmit={false} label={"Cancel"} /></Cursor></Center>
                        </>
                    }
                </>
            }
        </>
     */

}
