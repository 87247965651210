import React from 'react';
import styled from "styled-components";

/* Navs */
import {PageContent, PageFull, PageRight} from "../navbars/components/navlayout";
import NavPrimaryAll from "../navbars/containers/primaryall";
import NavSecondaryTop from "../navbars/containers/secondarytop";

/* Components */
import {FontBody16, FontHeader21} from "../../common/components/fonts";
import {colors} from "../../common/components/colors";
import {images} from "../../common/components/images";

const Scheduler = styled.div`
    max-width: 720px;
    width: 100%;
    margin-top: 50px;
`;
const Subtitle = styled.div`
    color: ${colors.secondary100};
    margin-top: 10px;
`;

/* Containers */
import SchedulerFlow from "../../iframes/scheduler/containers/flow";

class ExpertsPage extends React.Component {
    render() {
        return (
            <>
                <NavPrimaryAll/>
                <PageFull>
                    <PageRight>
                        <NavSecondaryTop title={"Build My Team"} desc={"Work with Willow experts to help support your clients"}/>
                        <PageContent>
                            <br/>
                            <FontHeader21>To get started, schedule a meeting with us.</FontHeader21>
                            <Subtitle><FontBody16>Support your clients' total financial wealth & health with Willow's experts!</FontBody16></Subtitle>
                            <Scheduler>
                                <SchedulerFlow
                                    userId={845}
                                    userFirstName={"Lacy"}
                                    userLastName={"Garcia"}
                                    userPhotoUrl={images.willowTeamLacyAvatar}
                                    eventCustomMinutes={30}
                                    confirmCloseBtn={false}
                                />
                            </Scheduler>
                        </PageContent>
                    </PageRight>
                </PageFull>
            </>
        )
    }
}

export default ExpertsPage;
