import React from 'react';
import { Switch } from 'react-router-dom';

/* Containers */
import PublicRoute from '../../../routes/containers/routepublic';

/* Pages - Redirects */
import RedirectAfterLogin from '../../../routes/containers/routeredirectafterlogin';
import RedirectWithoutLogin from '../../../routes/containers/routeredirectwithoutlogin';
import IFrameSignUpAssign from '../../../iframes/signupassign/page';

export const redirectRoutes = [
        '/redirect/availability',
        '/redirect/prospect/:prospectId/notes',
        '/redirect/get-started',
        '/redirect/my-tasks/:taskId',
        '/redirect/my-tasks/course/:courseId/quiz',
        '/redirect/my-tasks/course/:courseId/failed',
        '/redirect/my-tasks/course/:courseId/passed',
        '/redirect/my-tasks/course/:courseId/completed',
        '/video-room/:roomId',
        '/public/my-willow-page/shop/:handle',
        '/public/my-willow-page/coach/:handle',
        '/public/my-willow-page/advisor/:handle',
        '/public/my-willow-page/schedule/:handle',
        '/public/my-willow-page/firm/:firmId',
        '/package/:roomId',
        '/thank-you/:roomId',
];

const RoutesTrustWillowRedirects = () => (
    <Switch>
        <PublicRoute exact path={'/redirect/availability'} component={RedirectAfterLogin} data={{"redirect": '/availability', "token": null}}/>
        <PublicRoute exact path={'/redirect/prospect/:prospectId/notes'} component={RedirectAfterLogin} data={{"redirect": '/contact/notes', "token": -2}}/>
        <PublicRoute exact path={'/redirect/my-tasks/:taskId'} component={RedirectAfterLogin} data={{"redirect": '/my-tasks/', "token": -1}}/>
        <PublicRoute exact path={'/redirect/my-tasks/course/:courseId/quiz'} component={RedirectAfterLogin} data={{"redirect": '/my-tasks/course/:courseId/quiz', "token": -2}}/>
        <PublicRoute exact path={'/redirect/my-tasks/course/:courseId/failed'} component={RedirectAfterLogin} data={{"redirect": '/my-tasks/course/:courseId/failed', "token": -2}}/>
        <PublicRoute exact path={'/redirect/my-tasks/course/:courseId/passed'} component={RedirectAfterLogin} data={{"redirect": '/my-tasks/course/:courseId/passed', "token": -2}}/>
        <PublicRoute exact path={'/redirect/my-tasks/course/:courseId/completed'} component={RedirectAfterLogin} data={{"redirect": '/my-tasks/course/:courseId/completed', "token": -2}}/>
        <PublicRoute exact path={'/redirect/get-started'} component={IFrameSignUpAssign} />
        <PublicRoute exact path={'/video-room/:roomId'} component={RedirectWithoutLogin} data={{"redirect": 'https://live.trustwillow.com/room/', "token": true}} />
        <PublicRoute exact path={'/public/my-willow-page/shop/:handle'} component={RedirectWithoutLogin} data={{"redirect": 'https://secure.trustwillow.com/public/shop/', "token": true}} />
        <PublicRoute exact path={'/public/my-willow-page/coach/:handle'} component={RedirectWithoutLogin} data={{"redirect": 'https://secure.trustwillow.com/public/profile/', "token": true}} />
        <PublicRoute exact path={'/public/my-willow-page/advisor/:handle'} component={RedirectWithoutLogin} data={{"redirect": 'https://secure.trustwillow.com/public/profile/', "token": true}} />
        <PublicRoute exact path={'/public/my-willow-page/schedule/:handle'} component={RedirectWithoutLogin} data={{"redirect": 'https://secure.trustwillow.com/public/schedule/', "token": true}} />
        <PublicRoute exact path={'/public/my-willow-page/firm/:firmId'} component={RedirectWithoutLogin} data={{"redirect": 'https://secure.trustwillow.com/public/profile/lgarcia', "token": false}} />
        <PublicRoute exact path={'/package/:roomId'} component={RedirectWithoutLogin} data={{"redirect": 'https://secure.trustwillow.com', "token": false}} />
        <PublicRoute exact path={'/thank-you/:roomId'} component={RedirectWithoutLogin} data={{"redirect": 'https://secure.trustwillow.com', "token": false}} />
    </Switch>
);

export default RoutesTrustWillowRedirects;
