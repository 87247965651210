import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setIframeAuthLoggingIn, setIframeAuthLoginError} from "../store/auth";

/* Middleware */
import {tryAuthOnly} from '../../../routes/middleware/auth';
import {initiateFullstoryVars} from "../../../common/utils/tracking";

/* Static */
import {devIframeAuthData} from '../static/devdata';

export const trySignUpAssignAuthenticate = (prospectId, wmcId, token) => {
    return async dispatch => {
        dispatch(setIframeAuthLoggingIn(true));
        const auth = await axiosReq("apiv1/coach/prospects/consume-token", "POST", {"prospectId": prospectId, "wmcId": wmcId, "token": token}, devIframeAuthData);
        dispatch(setIframeAuthLoggingIn(false));
        if(auth.hasOwnProperty("error")) {
            return ({"error": true});
            // dispatch(setIframeAuthLoginError(auth.error));
        } else {
            await dispatch(tryAuthOnly(null, true, false));
            await dispatch(initiateFullstoryVars({"signupAssignToken": token}));
            return ({"error": false});
            // dispatch(setIframeAuthLoginError(null));
        }
        // return dispatch(setIframeAuthLoggingIn(false));
    }
};