import React from 'react';
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import moment from "moment-timezone";
import styled from 'styled-components';

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {ButtonPrimary} from "../../../common/components/buttons";
import {images} from "../../../common/components/images";
import {FontBody24, FontBody18} from '../../../common/components/fonts';
import { CloseOutlined } from '@ant-design/icons';

/* Containers */
// import BookAppointments from '../../appointments/containers/bookappointments';
// import MemberOne from "../../team/containers/memberone";
import HomeAppointments from './appointments';
import ConsumerDashPrograms from './programs';
import ConsumerTeam from './team';
import { HomeTasks } from './hometodos';
import WelcomeVideo from './welcomevideo';
import ConsumerDashboardWelcome from "../components/welcome";

/* Middleware */
import {tryGetTeamMemberMeetings} from '../../appointments/middleware/meetings';
import {tryGetAllTeamMembers} from '../../team/middleware/team';
import { tryGetMyGoals } from '../../progress/middleware/progress';

/* Store */
import {setDemoAlreadyShownOnHome, setFindCoachShown} from '../../../routes/store/user'

import getDomainEnv from '../../../domains/utils';
import { TallBackground, TallModal } from '../../../common/components/modal';
const env = getDomainEnv();

const FlexWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
  flex-wrap: wrap;
`;
const ColumnFlexA = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(60% - 40px);
    gap: 40px;
    @media screen and (max-width: 1440px) {
        width: 100%;
    }
`;
const ColumnFlexB = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
    gap: 40px;
    @media screen and (max-width: 1440px) {
        width: 100%;
    }
`;
const ButtonCentered = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
`;

const MobileHidden = styled.div`
    @media screen and (max-width: 800px) {
        display: none;
    }
`;
const CloseSection = styled.div`
    cursor: pointer;
    width: 100%;
    text-align: right;
`;

class HomeContainer extends React.Component {
    state = {
        'isLoading': true,
        'members': [],
        'showBook': false,
        // 'selectedMember': null,
        'showTimedPrompt': false
    }
    
    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.meetingsChanged !== this.props.meetingsChanged) {
            console.log('homecontainer component did update meetingschanged')
            return this.init();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
        this.props.setDemoAlreadyShownOnHome(true);
        this.props.setFindCoachShown(true)
    }

    timeout = 0;

    init = async () => {
        await this.props.tryGetAllTeamMembers();
        let members = this.props.members.filter((m) => {return(m.get("wmcId") === null && !(m.get("notATeamMember")))});
        await this.props.tryGetTeamMemberMeetings();
        this.setState({
            'members': await this.setUpMembers(members.toJS()),
            'isLoading': false,
        });
        this.timeout = setTimeout(this.showTimedPrompt, 3000);
    }

    showTimedPrompt = () => {
        if (!this.props.user.get("findCoachPromptShown") && this.props.user.get("showFindCoachPrompt") && this.state.members.length === 0) {
            this.setState({'showTimedPrompt': true})
        }
    }

    closePrompt = async () => {
        await this.props.setFindCoachShown(true)
        this.setState({'showTimedPrompt': false})
    }

    setUpMembers = async (data) => {
        return data.map(coach => {
            let title = env.CONSUMER.CARE.PRINT_EXPERT_CATEGORIES(coach.expertCategories, true);
            return { ...coach, "id": coach.coachId, "first": coach.first, "last": coach.last, "title": title, "photo": coach.photo };
        });
    }

    // Organize all meetings by upcoming or past
    setUpMeetings = (data) => {
        let obj = {'upcoming': [], 'past': [],};
        this.addTimeInfo(data).forEach(m => {obj[m.type].push(m);})
        return obj;
    }

    // Add type to each meeting datum
    addTimeInfo = (meetings) => {
        return meetings.reduce((filtered, c) => {
            let isUpcoming = moment(c.get("start")).isAfter(moment());
            let isOngoing = (moment(c.get("start")).isBefore(moment()) && moment(c.get("end")).isAfter(moment()));
            filtered.push({
                ...c.toJS(),
                "type": ((isUpcoming || isOngoing) ? "upcoming" : "past"),
            })
            return filtered;
        }, []);
    };

    // toggleShowBook = () => {
    //     if(this.state.members.length > 0) {
    //         this.setState({ 'showBook': !this.state.showBook });
    //     } else {
    //         this.props.history.push("/my-team/find");
    //     }
    // }

    toggleSelectedMember = (member) => {
        this.props.history.push("/my-team/"+member.coachId)
        // this.setState({ "selectedMember": member });
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <>
                    <WelcomeVideo />
                    <FlexWrap>
                        <ColumnFlexA>
                            <ConsumerDashboardWelcome
                                user={this.props.user}
                            />
                            <HomeTasks
                                newUser={this.props.user.get("newUser")}
                                meetings={this.setUpMeetings(this.props.meetings)}
                                goals={this.props.myGoals}
                                isExclusive={this.props.user.get("isExclusive")}
                            />
                            <ConsumerTeam
                                members={this.state.members}
                                selectMember={this.toggleSelectedMember}
                            />
                            {/*<ConsumerDashPrograms />*/}
                        </ColumnFlexA>
                        <ColumnFlexB>
                            <HomeAppointments />
                        </ColumnFlexB>
                    </FlexWrap>
                    {/*{this.state.showBook &&*/}
                    {/*    <BookAppointments*/}
                    {/*        members={this.state.members}*/}
                    {/*        onGoBack={this.toggleShowBook}*/}
                    {/*    />*/}
                    {/*}*/}
                    {/*{this.state.selectedMember &&*/}
                    {/*    <MemberOne*/}
                    {/*        coachId={this.state.selectedMember.coachId}*/}
                    {/*        onClose={(member, e) => {this.toggleSelectedMember(null, e)}}*/}
                    {/*    />*/}
                    {/*}*/}
                    {(this.state.showTimedPrompt && !this.props.demoAlreadyShownOnHome) &&
                        <>
                            <TallBackground/>
                            <TallModal>
                                <CloseSection onClick={() => {this.closePrompt()}}><CloseOutlined /></CloseSection>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <MobileHidden style={{width: '50%'}}>
                                        <img src={"https://docs.trustwillow.com/lacytest2.webp"} width={'100%'} />
                                    </MobileHidden>
                                    <div style={{width: '50%', textAlign: 'center'}}>
                                        <img src={images.logoIcon} width={'20%'}/>
                                        <br />
                                        <br />
                                        <br />
                                        <FontBody24>As Seen on NBC's TODAY Show</FontBody24>
                                        <br />
                                        <img src={"https://docs.trustwillow.com/nbctoday-pp682lhsc89v48doekiz50jcd7xabng4euffkimq68.webp"} width={'30%'} />
                                        <br />
                                        <br />
                                        <br />
                                        <div style={{width: '80%', margin: '0 auto'}}>
                                            <FontBody18>Get access to education and financial life coaching now.</FontBody18>
                                        </div>
                                        <br />
                                        <ButtonCentered onClick={() => {this.props.history.push('/my-team/find')}}>
                                            <ButtonPrimary label={"Find a coach"}/>
                                        </ButtonCentered>
                                        <br />
                                    </div>
                                    
                                </div>
                            </TallModal>
                        </>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    members: state.consumer.team.team.get("members"),
    meetings: state.consumer.appointments.meetings.get("meetings"),
    meetingsChanged: state.consumer.appointments.meetings.get("meetingsChanged"),
    user: state.common.user,
    demoAlreadyShownOnHome: state.common.user.get("demoAlreadyShownOnHome"),
    myGoals: state.consumer.progress.progress.get("myGoals"),
});

const mapDispatchToProps = dispatch => ({
    tryGetAllTeamMembers: () => dispatch(tryGetAllTeamMembers()),
    tryGetTeamMemberMeetings: () => dispatch(tryGetTeamMemberMeetings()),
    setDemoAlreadyShownOnHome: (status) => dispatch(setDemoAlreadyShownOnHome(status)),
    tryGetMyGoals: () => dispatch(tryGetMyGoals()),
    setFindCoachShown: (status) => dispatch(setFindCoachShown(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeContainer));
