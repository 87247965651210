import React from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import moment from "moment";
import styled from "styled-components";

/* Components */
import {FontHeader16, FontTitle18} from '../../../common/components/fonts';
import {CalendarFilled, CloseOutlined, VideoCameraFilled} from '@ant-design/icons';
import {MarginBottom15, Content, Constrain, BottomBorder, Body, Icon, Title, CardPage, Center} from "../components/contactactivity";
import {MeetingNoActions, MeetingBodyFuture, MeetingBodyPast, MeetingActionJoin, MeetingActionOptions, MeetingAttendees, ModalConfirmation, ModalInvite, ModalSaved} from '../components/contactactivitymeeting';
import {LogoLoading} from "../../../common/components/loading";

/* Middleware */
import {tryGetAllContacts} from "../../middleware/contacts";
import {inviteGuestsToMeeting, tryDeleteSessions} from "../middleware/sessions";

/* Store */
import {setSessionsModal} from "../store/sessions";
import {setClientsActivityPauseComponentUpdate} from '../../store/clientsactivity';
import {NavTertiaryNoLinks} from "../components/navtertiary";

const Background = styled.div`
    padding: 20px;
    min-height: calc(100vh - 320px);
    max-height: 100%;
    @media screen and (max-width: 460px) {
        padding: 15px 5px;
    }
`;

export class MeetingRecordFromCalendar extends React.Component {
    static defaultProps = {
        onRescheduleSession: () => {},
        onCancelSession: () => {}
    };

    state = {isLoading: true, data: {}, status: null, reschedule: false, cancel: false, invite: false, guests: [], searchProspects: [], isSaved: false};

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.data !== this.props.data && !this.props.pauseUpdate) {
            return this.init();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    timeout = 0;

    init = async () => {
        await this.setState({"data": this.props.data});
        const videoStatus = this.state.data.get("videoStatus");
        const start = this.state.data.get("start");
        const end = this.state.data.get("expected_end") === null ? this.state.data.get("end") : this.state.data.get("expected_end");
        if(videoStatus === "conference_canceled") {
            await this.setState({"status": "Canceled"})
        } else if (moment().isBefore(moment(start))) {
            await this.setState({"status": "Upcoming"})
        } else if (moment().isAfter(moment(start)) && moment().isBefore(moment(end))) {
            await this.setState({"status": "Ongoing"})
        } else if (moment().isAfter(moment(end))) {
            await this.setState({"status": "Past"})
        }
        if(!this.props.contactsAllFetched && !this.props.contactsAllFetching) {
            await this.props.tryGetAllContacts();
        }
        this.setState({"searchProspects": this.props.contactsAll});
        return this.setState({"isLoading": false});
    };

    rescheduleExecute = async () => {
        await this.setState({"reschedule": false});
        const contactId = await this.props.contactsAll.filter(c => {
            return(c.get("consumerId") === this.state.data.get("remoteUserId"))
        }).get(0).get("contactId");
        await this.props.setSessionsModal(false, null, null, contactId, {"topic": this.state.data.get("topic"), "description": this.state.data.get("description")}, null, true);
        await this.props.tryDeleteSessions(this.state.data.get("remoteUserId"), this.state.data.get("id"));
        await this.props.onRescheduleSession();
        this.props.history.push("/meetings/create/call");
    };

    cancelExecute = async () => {
        this.setState({"cancel": false});
        await this.props.tryDeleteSessions(this.state.data.get("remoteUserId"), this.state.data.get("id"));
        await this.props.onCancelSession();
    };

    inviteGuest = async () => {
        await this.props.setClientsActivityPauseComponentUpdate(true);
        await this.props.inviteGuestsToMeeting(this.state.guests, this.state.data.get("id"));
        await this.props.setClientsActivityPauseComponentUpdate(false);
        await this.setState({"invite": false, "guests": [], "searchProspects": this.props.contactsAll, 'isSaved': true});
        this.timeout = setTimeout(async () => {
            await this.setState({'isSaved': false});
            this.props.forceUpdate();
        }, 3000);
    };

    handleAddGuest = async (e) => {
        let guests = this.state.guests;
        guests.push(e);
        let searchProspectsLessGuest = this.state.searchProspects.filter(s => {return(s.get("contactId") !== e.value.contactId)});
        await this.setState({'guests': guests, 'searchProspects': searchProspectsLessGuest});
    };

    handleDeleteGuest = async (num) => {
        let guests = this.state.guests;
        let searchProspectsAddGuest = await this.props.contactsAll.filter(s => {return(s.get("contactId") === this.state.guests[num].value.contactId)}).get(0);
        guests.splice(num, 1);
        this.setState({"guests": guests, 'searchProspects': this.state.searchProspects.push(searchProspectsAddGuest)});
    };

    invite = () => {this.setState({"invite": true})};
    inviteClose = () => {this.setState({"invite": false})};

    reschedule = () => {this.setState({"reschedule": true})};
    rescheduleClose = () => {this.setState({"reschedule": false})};

    cancel = () => {this.setState({"cancel": true})};
    cancelClose = () => {this.setState({"cancel": false})};

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <>
                    <NavTertiaryNoLinks title={"Meeting Page"} />
                    <Background>
                        <CardPage>
                            <Icon><VideoCameraFilled/></Icon>
                            <Body>
                                <Title><FontTitle18>Video Call</FontTitle18></Title>
                                {this.state.status === "Ongoing" &&
                                    <MeetingActionJoin
                                        videoUrl={this.state.data.get("videoUrl")}
                                        start={this.state.data.get("start")}
                                    />
                                }
                                {this.state.status === "Upcoming" &&
                                    <MeetingActionOptions
                                        invite={this.invite}
                                        reschedule={this.reschedule}
                                        cancel={this.cancel}
                                        start={this.state.data.get("start")}
                                        isWMC={this.props.isWMC}
                                        numParticipants={(1 + this.state.data.get("guests").size + (this.state.data.get("remoteUserId") === null ? 0 : 1))}
                                    />
                                }
                                {(this.state.status === "Past" || this.state.status === "Canceled") &&
                                    <MeetingNoActions
                                        start={this.state.data.get("start")}
                                    />
                                }
                            </Body>
                        </CardPage>
                        <Center>
                            <Constrain>
                                <MarginBottom15>
                                    <FontHeader16>{this.state.data.get("topic")}</FontHeader16>
                                </MarginBottom15>
                                <MeetingAttendees
                                    thisUserName={this.state.data.get("thisUserName")}
                                    remoteUserName={(this.state.data.get("remoteUserReferralSource") === "willow" || (this.state.data.get("remoteUserReferralSource") === "wmc" && !this.props.isWMC)) ? this.state.data.get("remoteUserFirstName")+" "+this.state.data.get("remoteUserLastName").charAt(0).toUpperCase()+"." : this.state.data.get("remoteUserName")}
                                    status={this.state.status}
                                    missed={(this.state.data.get("missedByClient") || this.state.data.get("missedByCoach"))}
                                    guests={this.state.data.get("guests")}
                                    isWMC={this.props.isWMC}
                                />
                                <BottomBorder/>
                                {(this.state.status === "Ongoing" || this.state.status === "Upcoming") &&
                                    <MeetingBodyFuture
                                        title={this.state.status}
                                        data={this.state.data}
                                    />
                                }
                                {(this.state.status === "Past" || this.state.status === "Missed" || this.state.status === "Canceled") &&
                                    <MeetingBodyPast
                                        title={this.state.status}
                                        data={this.state.data}
                                    />
                                }
                            </Constrain>
                        </Center>
                    </Background>
                    {this.state.reschedule &&
                        <ModalConfirmation
                            actionTitle={"reschedule"}
                            leftAction={this.rescheduleClose}
                            rightAction={this.rescheduleExecute}
                            rightLabel={"Reschedule"}
                            eventTitle={"meeting"}
                            within24Hours={moment().isAfter(moment(this.state.data.get("start")).subtract({hours: 24})) && moment().isBefore(moment(this.state.data.get("start")))}
                        />
                    }
                    {this.state.cancel &&
                        <ModalConfirmation
                            actionTitle={"delete"}
                            leftAction={this.cancelClose}
                            rightAction={this.cancelExecute}
                            rightLabel={"Delete"}
                            eventTitle={"meeting"}
                            within24Hours={moment().isAfter(moment(this.state.data.get("start")).subtract({hours: 24})) && moment().isBefore(moment(this.state.data.get("start")))}
                        />
                    }
                    {this.state.invite &&
                        <ModalInvite
                            inviteClose={this.inviteClose}
                            inviteGuest={this.inviteGuest}
                            searchProspects={this.state.searchProspects}
                            guests={this.state.guests}
                            handleAddGuest={this.handleAddGuest}
                            handleDeleteGuest={this.handleDeleteGuest}
                            numParticipants={(1 + this.state.data.get("guests").size + (this.state.data.get("remoteUserId") === null ? 0 : 1))}
                            isWMC={this.props.isWMC}
                        />
                    }
                    {this.state.isSaved && <ModalSaved />}
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    contactsAllFetching: state.enterprise.contacts.get("contactsAllFetching"),
    contactsAllFetched: state.enterprise.contacts.get("contactsAllFetched"),
    contactsAll: state.enterprise.contacts.get("contactsAll"),
    isWMC: state.common.wmc.get("isWMC"),
    pauseUpdate: state.enterprise.clientsActivity.get("clientActivityPauseComponentUpdate")
});

const mapDispatchToProps = dispatch => ({
    tryDeleteSessions: (consumerId, sessionId) => dispatch(tryDeleteSessions(consumerId, sessionId)),
    inviteGuestsToMeeting: (contactId, conferenceId) => dispatch(inviteGuestsToMeeting(contactId, conferenceId)),
    setSessionsModal: (status, session, action, who, details, guests, eventApi) => dispatch(setSessionsModal(status, session, action, who, details, guests, eventApi)),
    tryGetAllContacts: () => dispatch(tryGetAllContacts()),
    setClientsActivityPauseComponentUpdate: (status) => dispatch(setClientsActivityPauseComponentUpdate(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MeetingRecordFromCalendar));