import React from 'react';
import {connect} from 'react-redux';
import ReactQuill from "react-quill";

import {Container, Portfolios, Portfolio, Image, Text, Gray} from "../components/portfolio";
import {FontTitle30, FontHeader21, FontBody18, FontHeader16} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";
import {Quill} from "../../../common/components/richtexteditor";

const pickMonth = (num) => {
    if(num === 1) {return("Jan")}
    else if(num === 2) {return("Feb")}
    else if(num === 3) {return("Mar")}
    else if(num === 4) {return("Apr")}
    else if(num === 5) {return("May")}
    else if(num === 6) {return("Jun")}
    else if(num === 7) {return("Jul")}
    else if(num === 8) {return("Aug")}
    else if(num === 9) {return("Sep")}
    else if(num === 10) {return("Oct")}
    else if(num === 11) {return("Nov")}
    else if(num === 12) {return("Dec")}
};

class IframePortfolio extends React.Component {
    render() {
        return(
            <Container>
                <FontTitle30>Portfolio Highlights:</FontTitle30>
                <Portfolios>
                    {this.props.profile.get("portfolio").map((f, indexF) => (
                        <Portfolio key={indexF}>
                            <FontHeader21>{f.get("title")}</FontHeader21>
                            <Gray><FontHeader16>
                                {f.get("startMonth") !== null && <span>{pickMonth(f.get("startMonth"))}</span>}
                                {(f.get("startMonth") !== null && f.get("startYear") !== null) && <span>,&nbsp;</span>}
                                {f.get("startYear") !== null && <span>{f.get("startYear")}</span>}
                                {((f.get("startMonth") !== null || f.get("startYear") !== null) && (f.get("endMonth") !== null || f.get("endYear") !== null || f.get("endOngoing"))) &&
                                    <span>&nbsp;-&nbsp;</span>
                                }
                                {f.get("endOngoing")
                                    ?
                                    <span>Ongoing</span>
                                    :
                                    <>
                                        {f.get("endMonth") !== null && <span>{pickMonth(f.get("endMonth"))}</span>}
                                        {(f.get("endMonth") !== null && f.get("endYear") !== null) && <span>,&nbsp;</span>}
                                        {f.get("endYear") !== null && <span>{f.get("endYear")}</span>}
                                    </>
                                }
                            </FontHeader16></Gray>
                            {f.get("photo") !== null &&
                                <>
                                    <br />
                                    <Image src={f.get("photo")} alt={""} />
                                </>
                            }
                            <Text>
                                <Quill bodyBorderColor={colors.white}>
                                    <ReactQuill
                                        readOnly={true}
                                        theme={null}
                                        value={f.get("body")}
                                        bounds={'.app'}
                                    />
                                </Quill>
                            </Text>
                        </Portfolio>
                    ))}
                </Portfolios>
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    profile: state.iframe.shop.profile.get("profile"),
});

export default connect(mapStateToProps, null)(IframePortfolio);