import styled from "styled-components";

import {colors} from "../../../common/components/colors";

export const Header = styled.div`
    padding: 30px 30px 00px 30px;
`;

export const Body = styled.div`
    overflow-y: auto;
    height: calc(100% - 50px);
    word-break: break-word;
    padding: 0px 30px 30px 30px;
`;

export const Back = styled.div`
    width: fit-content;
    padding: 3px;
    cursor: pointer;
    color: ${colors.secondary70};
    margin: 0px 0px 40px -6px;
    & svg {
        width: 17px;
        height: 17px;
        margin-bottom: -3px;
    }
`;

export const Title = styled.div`
    width: 100%;
`;

export const InlineLabel = styled.div`
    display: inline-block;
    vertical-align: top;
`;

export const Red = styled.div`
    color: ${colors.alert100};
    display: inline-block;
    vertical-align: top;
`;

export const IconDescription = styled.div`
    display: inline-block;
    vertical-align: top;
    color: ${colors.primary100};
    & svg {
        width: 17px;
        height: 17px;
        margin-bottom: -3px;
        margin-right: 3px;
    }
`;

export const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;

export const TopRight = styled.div`
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 17;
`;

export const SectionHeader = styled.div`
    width: 100%;
    margin: 20px 0px 10px 0px;
`;

export const DescriptionButton = styled.a`
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
    color: ${colors.secondary100};
    cursor: pointer;
    user-select: none;
    text-decoration: underline;
`;
