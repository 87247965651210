import { Map } from 'immutable';

/* Immutable */

const IFRAME_RATING_ERROR = 'IFRAME_RATING_ERROR';
const IFRAME_RATING_ALREADY_SENT = 'IFRAME_RATING_ALREADY_SENT';
const IFRAME_RATING_OF_COACH = 'IFRAME_RATING_OF_COACH';
const IFRAME_RATING_OF_CONSUMER = 'IFRAME_RATING_OF_CONSUMER';
const IFRAME_RATING_DOWNLOAD = 'IFRAME_RATING_DOWNLOAD';
const IFRAME_RATING_SUBMITTED = 'IFRAME_RATING_SUBMITTED';
const IFRAME_RATING_CREATING = 'IFRAME_RATING_CREATING';
const IFRAME_RATING_STORE_RESET = 'IFRAME_RATING_STORE_RESET';

/* Actions */

export const setRateError = () => ({'type': IFRAME_RATING_ERROR});
export const setAlreadyRated = (status) => ({'type': IFRAME_RATING_ALREADY_SENT, 'status': status});
export const setRateConsumer = (rating) => ({'type': IFRAME_RATING_OF_CONSUMER, 'rating': rating});
export const setRateCoach = (rating) => ({'type': IFRAME_RATING_OF_COACH, 'rating': rating});
export const setRatingDownload = (status) => ({'type': IFRAME_RATING_DOWNLOAD, 'status': status});
export const setRatingCreating = (status) => ({'type': IFRAME_RATING_CREATING, 'status': status});
export const setRatingSubmitted = (status) => ({'type': IFRAME_RATING_SUBMITTED, 'status': status});
export const setRatingStoreReset = () => ({'type': IFRAME_RATING_STORE_RESET});

/* Initial State */

const initialState = Map({
    rating: Map({
        token: null,
        id: null,
        name: null,
        datetime: null
    }),
    alreadyRated: false,
    ratingDownloading: false,
    ratingCreating: false,
    ratingSubmitted: false,
});

/* Reducer */

const ratingReducer = (state=initialState, action) => {
    switch (action.type) {
        case IFRAME_RATING_OF_CONSUMER:
            return state.merge({
                "rating": Map({
                    "token": action.rating.token,
                    "id": action.rating.clientId,
                    "name": action.rating.clientName,
                    "datetime": action.rating.datetime,
                    "description": action.rating.description,
                    "clientId": action.rating.clientId,
                    "clientName": action.rating.clientName,
                    "ratedOn": action.rating.ratedOn,
                    "coachPhotoUrl": action.rating.coachPhotoUrl,
                    "explanation": action.rating.explanation,
                    "conferenceId": action.rating.conferenceId

                })
            });
        case IFRAME_RATING_OF_COACH:
            return state.merge({
                "rating": Map({
                    "token": action.rating.token,
                    "id": action.rating.coachId,
                    "name": action.rating.coachName,
                    "datetime": action.rating.datetime
                })
            });
        case IFRAME_RATING_ERROR:
            return state.merge({
                "rating": Map({
                    "token": null,
                    "id": null,
                    "name": null,
                    "datetime": null
                })
            });
        case IFRAME_RATING_ALREADY_SENT:
            return state.merge({'alreadyRated': action.status});
        case IFRAME_RATING_SUBMITTED:
            return state.merge({'ratingSubmitted': action.status});
        case IFRAME_RATING_CREATING:
            return state.merge({'ratingCreating': action.status});
        case IFRAME_RATING_DOWNLOAD:
            return state.merge({'ratingDownloading': action.status});
        case IFRAME_RATING_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default ratingReducer;
