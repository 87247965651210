import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Map } from 'immutable';
import styled from 'styled-components';
import validator from 'validator';

/* Components */
import { ActionBox, ActionItem, ActionItems, ActionIcon } from "../components/clientsdetail";
import { FontTitle18, FontBody12} from '../../common/components/fonts';
import {CalendarFilled, PhoneFilled, MailFilled, MobileFilled, FileTextFilled, VideoCameraFilled, CalendarOutlined} from '@ant-design/icons';
import { LogoLoadingSmall } from "../../common/components/loading";
import { ButtonSecondary } from '../../common/components/buttons';

/* Middleware */
import {tryGetOneContact} from "../middleware/contacts";

/* Store */
import {setMessagesSMSOpen} from "../sms/store/messages";
import {setMessagesEmailOpen} from "../email/store/messages";
import { setSessionsModal } from '../meetings/store/sessions';
import { setNoteModal, setNoteSelected } from "../notes/store/notes";

const ActionButton = styled.div`
    & > div {
        width: calc(100% - 47px);
        text-align: center;
    }
    width: 100%;
    cursor: pointer;
    margin: 0px auto;
`;

class ContactActions extends React.Component {
    state = {"isLoading": true, "contact": Map(), "contactsOne": Map(), "phoneValid": true, "attemptSMS": false};

    componentDidMount() {
        return this.refresh();
    }

    componentWillUnmount() {
        clearInterval(this.timeout);
    }

    refresh = async () => {
        const contactId = this.props.contact.get("contactId");
        await this.props.tryGetOneContact(contactId, "store");
        const phone = this.props.contactsOne.get("phone");
        this.setState({
            "contact": this.props.contact, 
            "contactsOne": this.props.contactsOne, 
            "isLoading": false,
            "phoneValid": phone === null ? false : await validator.isMobilePhone(phone.toString(), 'en-US')
        })
    };

    createVideoMeeting = async () => {
        await this.props.setSessionsModal(false, null, null, this.state.contact.get("contactId"), null, null, true);
        this.props.history.push("/meetings/create/call");
    };

    // createPhoneMeeting = async () => {
    //     await this.props.setSessionsModal(false, null, null, this.state.contact.get("consumerId"), null, null);
    //     this.props.history.push("/meetings/create");
    // };

    createEmail = async () => {
        if(this.props.isWMC) {
            window.open("mailto:"+this.state.contactsOne.get("email"), "_blank");
        } else {
            if(this.state.contact.get("prospectStatus") !== "Contact") {
                const consumerId = this.state.contact.get("consumerId");
                const firstName = this.state.contact.get("firstName");
                const lastName = (this.state.contact.get("referralSource") === "willow" || (this.state.contact.get("referralSource") === "wmc" && !this.props.isWMC)) ? (this.state.contact.get("lastName").charAt(0).toUpperCase()+".") : this.state.contact.get("lastName");
                this.props.setMessagesEmailOpen(true, consumerId, "consumer", firstName, lastName, false, null, null);
            }
        }
    };

    createSMS = async () => {
        this.setState({"attemptSMS": true});
        if(this.state.phoneValid && this.state.contact.get("prospectStatus") !== "Contact") {
            const consumerId = this.state.contact.get("consumerId");
            const firstName = this.state.contact.get("firstName");
            const lastName = (this.state.contact.get("referralSource") === "willow" || (this.state.contact.get("referralSource") === "wmc" && !this.props.isWMC)) ? (this.state.contact.get("lastName").charAt(0).toUpperCase()+".") : this.state.contact.get("lastName");
            this.props.setMessagesSMSOpen(true, consumerId, "consumer", firstName, lastName, null, null);
        }
        this.timeout = setTimeout(() => {this.setState({"attemptSMS": false})}, 4000);
    };

    createNote = async () => {
        if(this.state.contact.get("prospectStatus") !== "Contact") {
            const note = {
                "edit": false,
                "clientId": this.state.contact.get("consumerId"),
                "noteId": null,
                "name": (this.state.contact.get("referralSource") === "willow" || (this.state.contact.get("referralSource") === "wmc" && !this.props.isWMC)) ? (this.state.contact.get("firstName") + " " + this.state.contact.get("lastName").charAt(0).toUpperCase()+".") : this.state.contact.get("firstName") + " " + this.state.contact.get("lastName"),
                "title": '',
                "body": '',
                "datetime": ''
            };
            this.props.setNoteSelected(note, false);
            this.props.setNoteModal(true);
            // Open note modal that stays open on whole site, ask if ok to close if trying to open another
        }
    };

    render() {
        if(this.state.isLoading) {
            return (
                <ActionBox>
                    <FontTitle18>Create</FontTitle18>
                    <br />
                    <LogoLoadingSmall />
                    <br />
                </ActionBox>
            )
        } else {
            if(this.props.isWMC) {
                return(
                    <ActionBox>
                        <FontTitle18>Create</FontTitle18>
                        <ActionItems>
                            <ActionButton onClick={this.createVideoMeeting}>
                                <ButtonSecondary canSubmit={false} label={<><CalendarOutlined />&nbsp;Schedule Meeting</>} />
                            </ActionButton>
                        </ActionItems>
                    </ActionBox>
                )
            } else {
                return(
                    <ActionBox>
                        <FontTitle18>Create</FontTitle18>
                        <ActionItems>
                            <ActionItem disabled={false} onClick={this.createVideoMeeting} extraWidth={true}>
                                <ActionIcon disabled={false}><CalendarFilled /></ActionIcon>
                                <FontBody12>Meeting</FontBody12>
                            </ActionItem>
                            {/*<ActionItem onClick={this.createVideoMeeting}>*/}
                            {/*    <ActionIcon><VideoCameraFilled /></ActionIcon>*/}
                            {/*    <FontBody12>Video</FontBody12>*/}
                            {/*</ActionItem>*/}
                            {/*<ActionItem onClick={this.createPhoneMeeting}>*/}
                            {/*    <ActionIcon><PhoneFilled /></ActionIcon>*/}
                            {/*    <FontBody12>Call</FontBody12>*/}
                            {/*</ActionItem>*/}
                            <ActionItem disabled={this.state.contact.get("prospectStatus") === "Contact"} onClick={this.createEmail} extraWidth={false}>
                                <ActionIcon disabled={this.state.contact.get("prospectStatus") === "Contact"}><MailFilled /></ActionIcon>
                                <FontBody12>Email</FontBody12>
                            </ActionItem>
                            <ActionItem disabled={this.state.contact.get("prospectStatus") === "Contact" || !this.state.phoneValid} onClick={this.createSMS} extraWidth={false}>
                                <ActionIcon disabled={this.state.contact.get("prospectStatus") === "Contact" || !this.state.phoneValid}><MobileFilled /></ActionIcon>
                                <FontBody12>SMS</FontBody12>
                            </ActionItem>
                            <ActionItem disabled={this.state.contact.get("prospectStatus") === "Contact"} onClick={this.createNote} extraWidth={false}>
                                <ActionIcon disabled={this.state.contact.get("prospectStatus") === "Contact"}><FileTextFilled /></ActionIcon>
                                <FontBody12>Note</FontBody12>
                            </ActionItem>
                        </ActionItems>
                        {(!this.state.phoneValid && this.state.attemptSMS)
                            && 
                            <>
                                <br></br>
                                <FontBody12>SMS Disabled: Client does not have a phone number added to their account</FontBody12>
                            </>
                        }
                    </ActionBox>
                )
            }
        }
    }
}

const mapStateToProps = state => ({
    contact: state.enterprise.clientsDetail.get("client"),
    contactsOne: state.enterprise.contacts.get("contactsOne"),
    isWMC: state.common.wmc.get("isWMC")
});
const mapDispatchToProps = dispatch => ({
    setSessionsModal: (status, session, action, who, details, guests, eventApi) => dispatch(setSessionsModal(status, session, action, who, details, guests, eventApi)),
    setMessagesEmailOpen: (open, recipientId, recipientType, recipientFirstName, recipientLastName, isReply, subject, content) => dispatch(setMessagesEmailOpen(open, recipientId, recipientType, recipientFirstName, recipientLastName, isReply, subject, content)),
    setMessagesSMSOpen: (open, recipientId, recipientType, recipientFirstName, recipientLastName, subject, content) => dispatch(setMessagesSMSOpen(open, recipientId, recipientType, recipientFirstName, recipientLastName, subject, content)),
    setNoteSelected: (note, edit) => dispatch(setNoteSelected(note, edit)),
    setNoteModal: (status) => dispatch(setNoteModal(status)),
    tryGetOneContact: (contactId, storeOrReturn) => dispatch(tryGetOneContact(contactId, storeOrReturn)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactActions));
