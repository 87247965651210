import styled from "styled-components";
import {colors} from "../../../../common/components/colors";

export const Listing = styled.div`
    background-color: ${colors.white};
    padding: 25px 25px 15px 25px;
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.05);
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.05);
    color: ${colors.primary100};
    margin: 0px auto 20px auto;
    border-radius: 4px;
    @media screen and (max-width: 640px) {    
        padding: 15px 15px 7px 15px;
    }
`;
export const Description = styled.div`
    ${props => props.hasPhoto !== true} {
        width: calc(100% - 170px);
        padding: 0px 0px 0px 20px;
        display: inline-block;
        vertical-align: top;
        @media screen and (max-width: 640px) {
            padding: 0px;
            width: 100%;
            margin-top: 20px;
        }
    }
    ${props => props.hasPhoto !== false} {
        width: 100%;
        display: block;
    }
`;
export const Title = styled.div`
    width: fit-content;
    @media screen and (max-width: 460px) {
        width: 100%;
    }
`;
export const Right = styled.div`
    display: inline-block;
    vertical-align: bottom;
    width: 160px;
`;
export const Left = styled.div`
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 160px);
`;
export const Type = styled.div`
    margin-bottom: 15px;
`;
export const Center = styled.div`
    margin: -20px auto 10px auto;
    text-align: center;
    width: fit-content;
`;
export const Inline = styled.div`
    vertical-align: top;
    display: inline-block;
    margin: 0px 10px;
    color: ${colors.action100};
    cursor: pointer;
    padding: 5px 10px;
    @media screen and (max-width: 640px) {
        display: block;
        width: 100%;
        margin: 5px 0px;
    }
`;
export const Background = styled.div`
    background-color: ${colors.border30};
    padding: 20px;
    @media screen and (max-width: 640px) {    
        padding: 10px;
    }
    margin-bottom: 30px;
`;

export const Footer = styled.div`
    margin-top: 30px;
    border-top: 1px solid ${colors.border100};
    padding-top: 10px;
`;
export const LeftTitle = styled.div`
    width: calc(100% - 70px);
    vertical-align: middle;
    display: inline-block;
    @media screen and (max-width: 640px) {
        display: block;
        width: 100%;
    }
`;
export const RightTitle = styled.div`
    width: 70px;
    vertical-align: bottom;
    color: ${colors.secondary70};
    display: inline-block;
    text-align: right;
    padding: 5px 0px;
    @media screen and (max-width: 640px) {
        display: block;
        width: 100%;
        text-align: left;
        margin: 5px 0px;
        padding: 5px 10px;
    }
`;
export const Body = styled.div`
    padding: 0px 20px;
    width: calc(100% - 40px);
    @media screen and (max-width: 640px) {
        width: calc(100% - 20px);
        padding: 0px 10px;
    }
`;