import React from 'react';
import styled from 'styled-components';

import {images} from "../../../../common/components/images";

export const PageFull = styled.div`
    min-height: 100vh;
    margin-left: 320px;
    width: calc(100% - 320px);
    background-image: url(${images.firmBackground});
    background-size: cover;
    background-repeat: no-repeat;
    @media screen and (max-width: 1088px) {
        margin-left: 0;
        width: 100%;
        position: relative;
        min-height: 100vh;
    }
`;

export const PageContentContainer = styled.div`
    ${props => props.fullPage !== true} {
        width: calc(100% - 120px);
        padding: 90px 60px 120px 60px;
        @media screen and (max-width: 748px) {
            width: calc(100% - 60px);
            padding: 30px 30px 0 30px;
        }
        @media screen and (max-width: 450px) {
            width: calc(100% - 30px);
            padding: 30px 15px 0 15px;
        }
    }
    ${props => props.fullPage !== false} {
        width: 100%;
        padding: 0;
    }
`;

export const PageContent = styled.div`
    width: 100%;
    @media screen and (max-width: 1088px) {
        padding-bottom: 80px;
    }
`;