import React from 'react';
import styled from 'styled-components';
import moment from "moment";
import {Link} from 'react-router-dom';

/* Components */
import { colors } from '../../../common/components/colors';
import { FontSubtitle18 } from '../../../common/components/fonts';
import {images} from '../../../common/components/images'

/* Containers */
import NavMenu from './navmenu';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const NavSide = styled.div`
    -webkit-box-shadow: 6px 0 18px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 6px 0 18px 0 rgba(0, 0, 0, 0.06);
    width: 320px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 4;
    background-color: ${colors.backgroundColor2};
    display: block;
    @media screen and (max-width: 1088px) {
        display: none;
    }
`;
const Container = styled.div`
    height: auto;
    position: relative;
`;
const LogoDate = styled(Link)`
    height: 40px;
    padding: 40px 10px 20px 25px;
    display:flex; 
    flex-direction:row;
    align-items: center;
`;
const WillowLogo = styled.img`
    width: 35px;
    height: auto;
`;
const Date = styled.div`
    color: ${colors.white};
    letter-spacing: 4px;
    text-transform: uppercase;
    padding-left: 15px;
    cursor: default;
`;

class ConsumerNavSide extends React.Component {
    render() {
        return(
            <NavSide>
                <Container>
                    <LogoDate to={env.CONSUMER.AUTH.HOME_URL}>
                        <img src={images.logoTransparentWhite} height="32" width="160" alt={"Willow"} />
                        {/*<WillowLogo src={images.logoIcon} />*/}
                        {/*<Date><FontSubtitle18>{moment().format('MMMM D, YYYY')}</FontSubtitle18></Date>*/}
                    </LogoDate>
                    <NavMenu />
                </Container>
            </NavSide>
        )
    }
}

export default ConsumerNavSide;