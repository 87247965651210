import React from 'react'
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled, { ThemeConsumer } from "styled-components";

/* Components */
import {AllContent, ArticleBox, FeaturedMaterial, FeaturedMaterialDivider, CenterHighlighted, FlexRow, SmallFeatured, SmallFeaturedRow, SmallFeaturedCol, SmallFeaturedRowImage, NavMenuDesktop, PageTitle} from "../components/care";
import {FontSubtitle28, FontTitle24, FontHeader16, FontBody14, FontBody12, FontTitle20} from "../../../common/components/fonts";
import ContentHighlighted from './contenthighlighted';
import SmallFeaturedArticle from './smallfeatured';
import {LogoLoading} from "../../../common/components/loading";

/* Middleware */
import {tryGetInsights} from '../middleware/insights';

/* Store */
import {insightSubCategorySelected} from '../store/insights';

/* Utils */
import {trackViewLearnParentJourneys, trackViewLearnSubJourneys} from "../../../common/utils/tracking";

const Header = styled(FontSubtitle28)`
    color: #394950;
    text-align: left;
    margin: 25px 25px 15px;
`;

const SubcategoryNav = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin-left: 25px;
`;

const ClickedSubcategory = styled(FontBody14)`
    color: #394950; 
    font-weight: bold; 
    border-bottom: 2px solid #394950; 
    cursor: pointer;
`;

 const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 800px) {
        flex-direction: column;
        justify-content: start;
    }
`

const ClickAll = styled(FontBody12)`
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`

class SelectedContent extends React.Component {
    static defaultProps = {
        category: null,
        subcategories: [],
        subcategory: null,
        categoryIndex: null,
    }

    state = {
        "isLoading": true,
        "category": this.props.category, 
        "categoryIndex": this.props.categoryIndex, 
        "subcategories": this.props.subcategories, 
        "subcategory": this.props.subcategory, 
        "moreArticles": [this.props.courses.get(0).toJS(), this.props.courses.get(1).toJS(), this.props.courses.get(2).toJS()],
        "highlightedArticle": this.props.courses.get(0),
    };

    componentDidMount() {
        console.log("mounted")
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.category != this.props.category || prevProps.subcategory != this.props.subcategory) {
            return this.init();
        }
        if (prevState.subcategory != this.state.subcategory) {
            console.log(this.state.subcategory)
            return this.props.handler(this.state)
        }
    }

    init = async () => {
        await this.setState({"category": this.props.category, "categoryIndex": this.props.categoryIndex, "subcategories": this.props.subcategories, "subcategory": this.props.subcategory})
        console.log("init")
        console.log(this.state.subcategory)
        if (this.state.subcategory !== null)
        {   
            console.log("am i here?")
            this.props.insightSubCategorySelected(this.state.subcategory)
            await this.props.tryGetInsights(this.props.insightSubCategory.get("id"))
            console.log(this.props.insights)
            const shuffled = this.props.insights.sort(() => 0.5 - Math.random());
            let selected = shuffled.slice(1, shuffled.length);
            this.setState({"moreArticles": selected.toJS(), "highlightedArticle": shuffled.get(0), "isLoading": false})
            console.log(this.state.isLoading)
        } else {
            const shuffled = this.props.courses.sort(() => 0.5 - Math.random());
            let selected = shuffled.slice(1, 4);
            this.setState({"moreArticles": [selected.get(0).toJS(), selected.get(1).toJS(), selected.get(2).toJS()], "highlightedArticle": shuffled.get(0), "isLoading": false})
        }
        if (!this.state.subcategories.filter(s => JSON.stringify(s) === JSON.stringify(this.state.subcategory)).length > 0)
        {
            this.setState({"subcategory": null})
        }
    }

    chooseSubcategory = async (a) => {
        this.setState({"subcategory": a})
        //this.props.trackViewLearnSubJourneys({"parentJourneyId": this.state.category.id, "parentJourneyTitle": this.state.category.title});
    }

    sendTo = async (a) => {
        return this.props.history.push('/learn/content');
        //return this.props.history.push('/learn/content');
    };

    render () {
        if(this.state.isLoading) {
            return (
                <ArticleBox style ={{backgroundColor: this.props.colors[this.props.categoryIndex]}}>
                    <LogoLoading />
                </ArticleBox>
            )
        } else {
            return (
                <ArticleBox style ={{backgroundColor: this.props.colors[this.props.categoryIndex]}}>
                    <Header>{this.props.category.title.toUpperCase()}</Header>
                    <SubcategoryNav style={{justifyContent: 'start', padding: 'auto'}}>
                        {this.state.subcategory == null
                        ?
                            <ClickedSubcategory>All</ClickedSubcategory>
                        :
                            <FontBody14 style={{cursor: 'pointer'}} onClick={() => {this.setState({"subcategory": null})}}>All</FontBody14>
                        }
                        {
                            this.state.subcategories &&
                                this.state.subcategories.map((a, index) => {
                                    if (JSON.stringify(this.state.subcategory) === JSON.stringify(a)) {
                                        return (
                                            <ClickedSubcategory key={index}>{a.title}</ClickedSubcategory>
                                        )
                                    } else {
                                        return (
                                            <FontBody14 key={index} style={{cursor: 'pointer'}} onClick={() => {this.chooseSubcategory(a)}}>{a.title}</FontBody14>
                                        )
                                    }
                                })
                        }
                    </SubcategoryNav>
                    <AllContent>
                        <FeaturedMaterial>
                            <CenterHighlighted>
                                <ContentHighlighted data={this.state.highlightedArticle.toJS()}/>
                            </CenterHighlighted>
                        </FeaturedMaterial>
                        <FeaturedMaterial>
                            <SmallFeatured>
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'end', marginBottom: '5px'}}>
                                    {this.state.subcategory == null
                                    ?
                                        <FontTitle20 style={{textAlign: 'left'}}>More {this.props.category.title}</FontTitle20>
                                    : 
                                        <FontTitle20 style={{textAlign: 'left'}}>More {this.state.subcategory.title}</FontTitle20>
                                    }
                                    <ClickAll onClick={this.props.sendTo}>See all</ClickAll>
                                </div>
                                <SmallFeaturedArticle data={this.state.moreArticles[0]}/>
                                <SmallFeaturedArticle data={this.state.moreArticles[1]}/>
                                <SmallFeaturedArticle data={this.state.moreArticles[2]}/>
                            </SmallFeatured>
                        </FeaturedMaterial>
                        <FeaturedMaterialDivider style={{margin: '10px auto'}}></FeaturedMaterialDivider>
                        <FontTitle24 style={{margin:'10px 25px'}}>Most Recent</FontTitle24>
                        <Row style={{width: '100%', justifyContent: 'space-between'}}>
                            <SmallFeaturedArticle data={/*this.state.subcategory != null ? this.props.insights.get(0).toJS() : */this.props.courses.get(0).toJS()}/>  
                            <SmallFeaturedArticle data={/*this.state.subcategory != null ? this.props.insights.get(1).toJS() : */this.props.courses.get(1).toJS()}/>
                            <SmallFeaturedArticle data={/*this.state.subcategory != null ? this.props.insights.get(2).toJS() : */this.props.courses.get(2).toJS()}/>
                        </Row>
                        {/*<FeaturedMaterialDivider style={{margin: '10px auto'}}></FeaturedMaterialDivider>
                        <FontTitle24 style={{margin:'10px 25px'}}>Most Popular</FontTitle24>
                        <Row style={{padding: '0px 15px', width: '100%', justifyContent: 'space-between'}}>
                            <SmallFeaturedArticle data={this.props.popular.get(0) ? this.props.popular.get(0).toJS() : this.props.courses.get(Math.floor(Math.random() * this.props.courses.size)).toJS()}/>  
                            <SmallFeaturedArticle data={this.props.popular.get(1) ? this.props.popular.get(1).toJS() : this.props.courses.get(Math.floor(Math.random() * this.props.courses.size)).toJS()}/>
                            <SmallFeaturedArticle data={this.props.popular.get(2) ? this.props.popular.get(2).toJS() : this.props.courses.get(Math.floor(Math.random() * this.props.courses.size)).toJS()}/>
                                </Row>*/}
                    </AllContent>
                </ArticleBox>
            )
        }
    }
}

const mapStateToProps = state => ({
    insightSubCategory: state.consumer.learn.insights.get("insightSubCategorySelected"),
    insights: state.consumer.learn.insights.get("insights")
})

const mapDispatchToProps = dispatch => ({
    tryGetCoachingSubCategories: (parentId) => dispatch(tryGetCoachingSubCategories(parentId)),
    tryGetInsights: (id, limit) => dispatch(tryGetInsights(id, limit)),
    insightSubCategorySelected: (category) => dispatch(insightSubCategorySelected(category)),
    tryGetInsightSubCategories: (id) => dispatch(tryGetInsightSubCategories(id)),
    trackViewLearnSubJourneys: (contentDetails) => dispatch(trackViewLearnSubJourneys(contentDetails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SelectedContent));