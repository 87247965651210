import {axiosReq} from "../../common/utils/axios";
// import moment from 'moment/moment';
import {fromJS} from "immutable";
import {devConsumerProductGeneral} from "../../consumer/team/static/devdata";

/* Store */
// import { setClientsProgram, setClientsProgramPurchaseLink, setClientsProgramPurchaseLinkDownloading, setClientsProgramDownloadPending, setClientsProgramDownloadComplete, setClientsProgramPurchaseSending, setClientsProgramPurchaseSent } from "../store/clientsprogram";

/* Static */
// import { devEnterpriseClientProgramData, devEnterpriseClientPurchaseLink } from '../static/devdata';

// export const tryGetEnterpriseClientProgram = () => {
//     return async (dispatch, getState) => {
//         dispatch(setClientsProgramDownloadPending());
//         let clientId = getState().enterprise.clientsDetail.get("client").get("consumerId");
//         const program = await axiosReq('apiv1/coach/clients/'+clientId+'/program', "GET", {}, devEnterpriseClientProgramData);
//         await dispatch(setClientsProgram(program));
//         return dispatch(setClientsProgramDownloadComplete());
//     }
// };
//
// export const tryGetEnterpriseClientPurchaseLink = (conferenceId) => {
//     return async dispatch => {
//         dispatch(setClientsProgramPurchaseLinkDownloading(true));
//         if(conferenceId === null) {
//             dispatch(setClientsProgramPurchaseLink({"purchaseToken": null, "purchaseUrl": null}));
//             return dispatch(setClientsProgramPurchaseLinkDownloading(false));
//         }
//         const purchaseLink = await axiosReq('apiv1/coach/clients/'+conferenceId+'/get-purchase-link', "POST", {}, devEnterpriseClientPurchaseLink);
//         dispatch(setClientsProgramPurchaseLink(purchaseLink));
//         return dispatch(setClientsProgramPurchaseLinkDownloading(false));
//     }
// };
//
// export const tryPostEnterpriseClientEmailPurchase = (conferenceId) => {
//     return async dispatch => {
//         dispatch(setClientsProgramPurchaseSending(true));
//         await axiosReq('apiv1/coach/clients/'+conferenceId+'/email-purchase-link', "POST", {}, {});
//         dispatch(setClientsProgramPurchaseSent(moment()));
//         dispatch(setClientsProgramPurchaseSending(false));
//     }
// };

export const devMessagesDataConferenceId = {
    "pastCalls": [
        {
            "id": 1,
            "date": "2020-04-12T10:00:00+00:00",
            "length": "30 min"
        }
    ]
};

export const devMessagesDataPurchaseLink = {
    "purchaseToken": "123",
    "purchaseUrl": "https://google.com/123"
};

export const tryGetCoachClientPurchaseLink = (consumerId) => {
    return async dispatch => {
        // dispatch(setPurchaseLinkDownloading(true));
        const pastSessions = await axiosReq('apiv1/coach/clients/'+consumerId+'/program', "GET", {}, devMessagesDataConferenceId);
        let purchaseLink;
        const numPastCalls = fromJS(pastSessions).get("pastCalls").size;
        if(numPastCalls > 0) {
            const sessionId = fromJS(pastSessions).get("pastCalls").get(-1).get("id");
            purchaseLink = await axiosReq('apiv1/coach/clients/'+sessionId+'/get-purchase-link', "POST", {}, devMessagesDataPurchaseLink);
        } else {
            purchaseLink = {"purchaseToken": null, "purchaseUrl": null};
        }
        // dispatch(setPurchaseLinkDownloading(false));
        return purchaseLink.purchaseToken;
    }
};

export const tryGetClientGeneralSessions = (prospectId) => {
    return async dispatch => {
        return axiosReq('apiv1/coach/consumer/sessions/get-available-amount/'+prospectId, "GET", {}, devConsumerProductGeneral);
    }
};