import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';

/* Components */
import {colors} from "../../../common/components/colors";
import {LogoLoading} from "../../../common/components/loading";

/* Containers */
import ProfileLocation from './profilelocation';
import ProfileBackground from './profilebackground';
import ProfileEducation from './profileeducation';
import ProfileCertifications from './profilecertifications';
import ProfileSpecialties from './profilespecialties';
import ProfileSkills from './profileskills';
import ProfileInterests from './profileinterests';
import ProfileExperience from './profileexperience';

/* Middleware */
import {tryGetExpertProfile} from '../middleware/expertprofile';

const Border = styled.div`
    height: 25px;
    border-bottom: 1px solid ${colors.primary30};
    margin-bottom: 40px;
`;

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

class ShopPageAbout extends React.Component {
    state = {"isLoading": true};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.props.tryGetExpertProfile();
        this.setState({"isLoading": false});
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <br />
                    <ProfileBackground />
                    <Border />
                    <ProfileSpecialties />
                    <Border />
                    <ProfileSkills />
                    <Border />
                    <ProfileInterests />
                    <Border />
                    <ProfileExperience />
                    <Border />
                    <ProfileEducation />
                    <Border />
                    <ProfileCertifications />
                    <Border />
                    <ProfileLocation />
                    <br />
                </>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryGetExpertProfile: () => dispatch(tryGetExpertProfile()),
});

export default connect(null, mapDispatchToProps)(ShopPageAbout);
