import {v4 as uuid4} from "uuid";
import {images} from "../../../common/components/images";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

export const devEnterpriseConferenceGuest = {
    "guest": {
        "advisor": {
            "email": "jason+test@"+env.URL.DOMAIN,
            "firstName": "Jason",
            "id": 900,
            "lastName": "Aronson",
            "phone": 4135193519,
            "timeZone": "eastern",
        },
        "businessUserId": null,
        "coachProspectStatusTitle": "undefined",
        "consumerId": 969,
        "email": "jason.aronson+nancy@gmail.com",
        "expertId": 900,
        "firstName": "Nancy",
        "id": 105,
        "isArchived": false,
        "lastName": "Aronson",
        "phone": "4132628977",
        "prospectId": 418,
        "referralType": "wmc",
        "timeZone": "eastern",
        "wmcId": 3,
    }
};

/* Middleware - tryGetEnterpriseScheduleSessions */
export const devEnterpriseScheduleSessionsData = {
    "sessions": [
        {
            id: uuid4(),
            remoteUserName: "Jason",
            remoteUserId: 1,
            thisUserName: "Jason Aronson",
            remoteUserFirstName: "Jason",
            remoteUserLastName: "Aronson",
            remoteUserReferralSource: "wmc",
            thisUserPlatform: "enterprise",
            start: "2021-10-25T04:00:00+00:00",
            end: "2021-10-25T04:30:00+00:00",
            expected_end: "2021-10-25T04:45:00+00:00",
            phone: "617-380-4313",
            room: "277861",
            videoStatus: "conference_ongoing", // conference_canceled, conference_ended, conference_has_not_yet_started, conference_ongoing
            videoUrl: "924fcf54447903ab9ea558886af67543", // if status !== conference_ongoing then videoUrl = null
            videoAccessToken: "abc", // if status !== conference_ongoing then videoAccessToken = null
            title: 'Jason Aronson',
            guests: []
        },
        {
            id: uuid4(),
            remoteUserName: "Jason",
            remoteUserFirstName: "Jason",
            remoteUserLastName: "Aronson",
            remoteUserReferralSource: "wmc",
            remoteUserId: 1,
            thisUserName: "Jason Aronson",
            thisUserPlatform: "enterprise",
            start: "2022-05-25T18:00:00+00:00",
            end: "2022-05-25T18:30:00+00:00",
            expected_end: "2022-05-25T21:30:00+00:00",
            phone: "617-380-4313",
            room: "277861",
            videoStatus: "conference_ongoing", // conference_canceled, conference_ended, conference_has_not_yet_started, conference_ongoing
            videoUrl: "924fcf54447903ab9ea558886af67543", // if status !== conference_ongoing then videoUrl = null
            videoAccessToken: "abc", // if status !== conference_ongoing then videoAccessToken = null
            title: 'Jason Aronson',
            guests: [
                {
                    "advisor": {
                        "email": "jason+test@"+env.URL.DOMAIN,
                        "firstName": "Jason",
                        "id": 900,
                        "lastName": "Aronson",
                        "phone": 4135193519,
                        "timeZone": "eastern",
                    },
                    "businessUserId": null,
                    "coachProspectStatusTitle": "undefined",
                    "consumerId": 969,
                    "email": "jason.aronson+nancy@gmail.com",
                    "expertId": 900,
                    "firstName": "Nancy",
                    "id": 105,
                    "isArchived": false,
                    "lastName": "Aronson",
                    "phone": "4132628977",
                    "prospectId": 418,
                    "referralType": "wmc",
                    "timeZone": "eastern",
                    "wmcId": 3,
                }
            ]
        }
    ]
};

/* Middleware - tryDeleteEnterpriseScheduleAvailability */
export const devEnterpriseScheduleSessionsDeleteData = (id, getState) => {
    const deletedSessions = getState.enterprise.sessions.get("sessions").filter(a => {
        return(a.get("id") !== id)
    });
    return ({"sessions": deletedSessions.toJS()});
};

/* Middleware - tryGetEnterpriseScheduleSessions */
export const devEnterpriseScheduleOneSessionData = {
    id: uuid4(),
    remoteUserName: "Jason",
    remoteUserId: 968,
    remoteUserFirstName: "Jason",
    remoteUserLastName: "Aronson",
    remoteUserReferralSource: "wmc",
    thisUserName: "Jason Aronson",
    thisUserPlatform: "enterprise",
    start: "2021-06-30T18:00:00+00:00",
    end: "2021-06-30T18:30:00+00:00",
    expected_end: "2021-06-30T21:30:00+00:00",
    phone: "617-380-4313",
    room: "277861",
    videoStatus: "conference_ongoing", // conference_canceled, conference_ended, conference_has_not_yet_started, conference_ongoing
    videoUrl: "924fcf54447903ab9ea558886af67543", // if status !== conference_ongoing then videoUrl = null
    videoAccessToken: "abc", // if status !== conference_ongoing then videoAccessToken = null
    title: 'Jason Aronson',
    guests: []
};

export const devEnterpriseCreatedSessionData = {
    "id": uuid4(),
    "start":"2020-11-30T21:47:00+00:00",
    "end":"2020-11-30T22:32:00+00:00",
    "expected_end":"2020-11-30T22:47:00+00:00",
    "phone":"617-380-4313",
    "videoStatus":"conference_has_not_yet_started",
    "title":"Nancy A.",
    "videoLinkSent":false,
    "videoLinkClicked":false,
    "topic":"test",
    "purchaseToken":"969-cL_PKYsLMHAChLCpV5vCz9W2wetHElPEOcJOdw-900",
    "description":"",
    "missedByClient":true,
    "missedByCoach":true,
    "wmcId":3,
    "isInternalCall":false,
    "remoteUserId":969,
    "remoteUserName":"Nancy Aronson",
    "remoteUserFirstName": "Jason",
    "remoteUserLastName": "Aronson",
    "remoteUserReferralSource": "wmc",
    "thisUserId":900,
    "thisUserName":"Jason Aronson",
    "thisUserPlatform":"advisor",
    "room":"280061",
    "videoAccessToken":null,
    "videoUrl":"969-QfjguWbGOZQ55k8zPzUpHNobLJ_gPpZ8KrfCZA-900",
    "guests": []
};

export const devEnterpriseCreateGroupSessionData = {
    "flashSale": {
        "id": 2,
        "title": "fdgfdg",
        "description": "<p>dfsgfdg</p>",
        "twilioRoomName": "5a9811d7-b9d8-4919-ac92-b98403a94cf5",
        "coverPhoto": images.devDataWebinarCoverPhoto,
        "ticketCost": 0,
        "announcementVideoUrl": "https://www.youtube.com/embed/RZAF1DAqHPk",
        "isActive": true,
        "isOnlyForClients": true,
        "purchaseLimitNumber": 100,
        "flashSaleStartDateTime": "2021-05-04T14:45:00+00:00",
        "flashSaleEndDateTime": "2021-05-04T15:45:00+00:00",
        "videoUrl": "https://"+env.URL.HOSTNAME+"/flash-sale/5a9811d7-b9d8-4919-ac92-b98403a94cf5/start",
    },
    "expert": {
        'id': 1,
        'full_name': "Jason Aronson",
        'storeHandle': "jason-aronson-store",
        'publicHandle': "jason-aronson-public"
    }
};

export const devEnterpriseEditGroupSessionWhenData = {
    "id":1,
    "title":"dsfdfa",
    "description":"<p>dsfdsf</p>",
    "twilioRoomName":"3f6e31ad-0ad3-461a-b349-d6ab58dbdf77",
    "coverPhoto":null,
    "ticketCost":0,
    "announcementVideoUrl":"",
    "isActive":true,
    "isOnlyForClients":true,
    "purchaseLimitNumber":100,
    "flashSaleStartDateTime":"2022-02-11T14:42:00+00:00",
    "flashSaleEndDateTime":"2022-02-11T15:42:00+00:00",
    "registrationCount":0,
    "videoUrl":"https://"+env.URL.HOSTNAME+"/flash-sale/3f6e31ad-0ad3-461a-b349-d6ab58dbdf77/start",
    "type": "group_session"
}

export const devEnterpriseDownloadGroupSessionData = {
    "expert": {
        'id': 1,
        'full_name': "Jason Aronson",
        'storeHandle': "jason-aronson-store",
        'publicHandle': "jason-aronson-public"
    },
    "flashSales":[
        {
            "id":1,
            "title":"dsfdfa",
            "description":"<p>dsfdsf</p>",
            "twilioRoomName":"3f6e31ad-0ad3-461a-b349-d6ab58dbdf77",
            "coverPhoto":null,
            "ticketCost":0,
            "announcementVideoUrl":"",
            "isActive":true,
            "isOnlyForClients":true,
            "purchaseLimitNumber":100,
            "flashSaleStartDateTime":"2022-02-12T14:42:00+00:00",
            "flashSaleEndDateTime":"2022-02-12T15:42:00+00:00",
            "registrationCount":0,
            "videoUrl":"https://"+env.URL.HOSTNAME+"/flash-sale/3f6e31ad-0ad3-461a-b349-d6ab58dbdf77/start",
            "type": "group_session"
        },
        {
            "id":2,
            "title":"fdgfdg",
            "description":"<p>dfsgfdg</p>",
            "twilioRoomName":"5a9811d7-b9d8-4919-ac92-b98403a94cf5",
            "coverPhoto": images.devDataWebinarCoverPhoto,
            "ticketCost":55,
            "announcementVideoUrl":"https://www.youtube.com/embed/RZAF1DAqHPk",
            "isActive":true,
            "isOnlyForClients":false,
            "purchaseLimitNumber":24,
            "flashSaleStartDateTime":"2021-12-20T14:45:00+00:00",
            "flashSaleEndDateTime":"2021-12-20T15:45:00+00:00",
            "registrationCount":2,
            "videoUrl":"https://"+env.URL.HOSTNAME+"/flash-sale/5a9811d7-b9d8-4919-ac92-b98403a94cf5/start",
            "type": "webinar"
        }
    ]
};

export const devServicesExpertProfileData = {
    id: '32',

    firstName: "Jason",
    lastName: "Aronson",
    companyName: 'Coaching, LLC',
    crd: 'test 5',

    photo: images.devDataTeamJasonAvatar,
    introVideoUrl: 'https://www.youtube.com/embed/RZAF1DAqHPk',
    facebookUrl: 'https://facebook.com',
    linkedinUrl: 'https://linkedin.com',
    twitterUrl: 'https://twitter.com',
    address1: 'test 3',
    address2: 'test 6',
    city: 'Boston',
    state: 'MA',
    zipCode: '23443',
    bio: '<p>In an age of pandemic, what true value do any of our backgrounds hold? It\'s not like we can look at precedents and say, people who have done ABC tend to be really successful at accomplishing XYZ in a pandemic. My expf</p>',

    // BEG COACH ONLY FIELDS
    endorsement1: 'Test 1',
    endorsement2: 'Test 2',
    endorsement3: null,
    freeTime: 'Test 7',
    whyBecameCoach: 'Test 2',
    coachPhilosophy: 'Test 3',
    highlights: 'Test 1',

    coachingCertification: null,
    coachingCertificationYear: null,

    // END COACH ONLY FIELDS

    bookingAvailability: 'available',

    screeningCallLength: 30,
    defaultCallLength: 30,

    advancedDegree: null,
    advancedDegreeCity: null,
    advancedDegreeInstitution: null,
    advancedDegreeState: null,
    advancedDegreeYear: null,

    otherEducation: null,

    expertCategories: [],

    storeActive: false,
    storeShowScheduler: true,
    storeShowEmailer: true,
    storeTitle: "",
    storePhoto: null,
    storeHandle: "samplestore",

    publicHandle: "sampleadvisor",
    profileActive: true,
    profileShowScheduler: true,
    profileShowEmailer: true,
    website: "fromjason.c",

    schedulerActive: true,
    schedulerHandle: "sampleschedule",
    storeDescription: ""
};

export const devEnterpriseDownloadGroupSessionParticipantsData = {
    'id': 12,
    'title': "fdgfdg",
    "description":"<p>dfsgfdg</p>",
    "twilioRoomName":"5a9811d7-b9d8-4919-ac92-b98403a94cf5",
    "coverPhoto": images.devDataWebinarCoverPhoto,
    "ticketCost":55,
    "announcementVideoUrl":"https://www.youtube.com/embed/RZAF1DAqHPk",
    "isActive":true,
    "isOnlyForClients":false,
    "purchaseLimitNumber":24,
    "flashSaleStartDateTime":"2021-05-07T14:45:00+00:00",
    "flashSaleEndDateTime":"2021-05-07T15:45:00+00:00",
    'flashSaleStartedOn': "2021-05-07T14:45:00+00:00",
    'flashSaleEndedOn': "2021-05-07T15:45:00+00:00",
    "videoUrl":"https://"+env.URL.HOSTNAME+"/flash-sale/5a9811d7-b9d8-4919-ac92-b98403a94cf5/start",
    'attendees': [
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
        {
            'id': 542,
            'registeredOnForSale': "2021-05-02T15:45:00+00:00",
            'firstName': "Jason",
            'lastName': "Aronson",
            'email': "jason@trustwillow.com",
            'phone': 5444545464,
            'timeZone': "eastern"
        },
    ],
};

export const devClientPastUpcomingConferenecs = {
    "pastConferences": [
        {
    "id": 1,
    "start": "payload_start",
    "end": "payload_end",
    "expected_end": "payload_expected_end",
    "phone": "TWILIO_PHONE_NUMBER_FORMATTED",
    "videoStatus": "conference.get_status_for_video_call",
    "isCanceled": false,
    "title": "Internal meeting",
    "videoLinkSent": false,
    "videoLinkClicked": false,
    "topic": "conference.topic",
    "purchaseToken": "conference.lily_purchase_external_id",
    "purchaseHistoryId": "conference.related_to_purchase_id",
    "description": "conference.description",
    "missed": false,
    "isAdvertising": true,
    "missedByClient": false,
    "missedByCoach": false,
    "wmcId": "wmc_id",
    "isWillowSession": true,
    "isInternalCall": false,
    "meetingIsTrial": false,
}
    ],
    "upcomingConferences": [
        {
            "id": 1,
            "start": "payload_start",
            "end": "payload_end",
            "expected_end": "payload_expected_end",
            "phone": "TWILIO_PHONE_NUMBER_FORMATTED",
            "videoStatus": "conference.get_status_for_video_call",
            "isCanceled": false,
            "title": "Internal meeting",
            "videoLinkSent": false,
            "videoLinkClicked": false,
            "topic": "conference.topic",
            "purchaseToken": "conference.lily_purchase_external_id",
            "purchaseHistoryId": "conference.related_to_purchase_id",
            "description": "conference.description",
            "missed": false,
            "isAdvertising": true,
            "missedByClient": false,
            "missedByCoach": false,
            "wmcId": "wmc_id",
            "isWillowSession": true,
            "isInternalCall": false,
            "meetingIsTrial": false,
        }
    ]
}

// {
//     "id": conference.pk,
//     "start": payload_start,
//     "end": payload_end,
//     "expected_end": payload_expected_end,
//     "phone": TWILIO_PHONE_NUMBER_FORMATTED,
//     "videoStatus": conference.get_status_for_video_call,
//     "isCanceled": conference.is_canceled,
//     "title": "Internal meeting",
//     "videoLinkSent": bool(conference.advisor_sent_video_call_invitation_to_lily_on),
//     "videoLinkClicked": bool(conference.lily_first_visited_video_call_link_on),
//     "topic": conference.topic,
//     "purchaseToken": conference.lily_purchase_external_id,
//     "purchaseHistoryId": conference.related_to_purchase_id,
//     "description": conference.description,
//     "missed": conference.missed,
//     "isAdvertising": conference.is_advertising_session_purchase,
//     "missedByClient": conference.is_missed_by_lily,
//     "missedByCoach": conference.is_missed_by_expert,
//     "wmcId": wmc_id,
//     "isWillowSession": conference.is_willow_session_used,
//     "isInternalCall": not bool(conference.lily),
//     "meetingIsTrial": conference.redemption_type == ConferenceRedemptionType.FREE_TRIAL.value,
// }

