import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

/* Components */
import {Container, LoadingCenter} from "../components/courses";
import {LogoLoading} from "../../../common/components/loading";

/* Containers */
import FormsFlow from "../../forms/containers/flow";
import TaskExit from "./coursemoduleexit";

/* Middleware */
import {tryAnswerTask} from "../middleware/courses";

const PageContent = styled.div`
    width: 100%;
    @media screen and (max-width: 1088px) {
        padding-bottom: 80px;
    }
`;
const PageContentContainer = styled.div`
    width: calc(100% - 120px);
    margin: 0 auto;
    padding: 60px 60px 120px 60px;
    @media screen and (max-width: 748px) {
        padding: 20px 20px 120px 20px;
        width: calc(100% - 40px);
    }
`;

class CoursesTask extends React.Component {
    static defaultProps = {
        back: () => {},
        openCourseComplete: () => {},
        task: {},
        coursePathname: ""
    };

    state={
        "isLoading": true,
        "pages": [],
        "exitModalStatus": false,
        "lastPageCompleted": 0
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const task = this.props.task;
        console.log("taskOpened", task)
        await this.setState({
            "pages": task.hasOwnProperty("answers") ? task.answers : task.task.pages,
            "lastPageCompleted": task.lastPageCompleted,
            "isLoading": false
        })
        console.log(this.state)
    }

    exitModalStatus = () => {
        this.setState({"exitModalStatus": !this.state.exitModalStatus})
    };

    autoSave = (answers, isCompleted, lastPageCompleted) => {
        console.log("autosave called")
        console.log(answers)
        this.props.tryAnswerTask(this.props.task.taskAssignmentId, answers, isCompleted, lastPageCompleted);
    };

    render() {
        if (this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <PageContent>
                        <PageContentContainer>
                            <Container>
                                <FormsFlow
                                    form={this.state.pages}
                                    skipAuth={true}
                                    onCompleteFormMethod={"return"}
                                    onReturnForm={this.props.openCourseComplete}
                                    closeEarly={this.exitModalStatus}
                                    sectionPathname={this.props.coursePathname}
                                    formPathname={this.props.coursePathname+"/module/"+this.props.task.taskAssignmentId}
                                    lastPageCompleted={this.state.lastPageCompleted}
                                    autoSaveOn={true}
                                    autoSaveTry={this.autoSave}
                                    connectPagesToRoutes={true}
                                    confirmOnClose={this.props.back}
                                />
                            </Container>
                        </PageContentContainer>
                    </PageContent>
                    {this.state.exitModalStatus &&
                        <TaskExit
                            cancel={this.exitModalStatus}
                            yes={this.props.back}
                        />
                    }
                </>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryAnswerTask: (id, answers, isCompleted, lastPageCompleted) => dispatch(tryAnswerTask(id, answers, isCompleted, lastPageCompleted))
});

export default connect(null, mapDispatchToProps)(CoursesTask);
