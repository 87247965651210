import React, {useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useDispatch} from 'react-redux'
import styled from 'styled-components';

/* Components */
import {tryGetAllRoundtablesOneDetail, tryGetMyRoundtableData, tryPostJoinRoundtable} from "../middleware/roundtables";

const Img = styled.img`
  width: 100%;
`;

function FirmRoundtablesRecord() {
    const [myRoundtableData, setMyRoundtableData] = useState({
        "id": null,
        "title": null,
        "time": null,
        "isCompleted": false,
        "maxNumberOfAttendees": 0,
        "coverPhoto": null,
        "numberOfAttendees": 0,
        "driveId": null,
        "joined": null
    },)
    const dispatch = useDispatch();

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        let path = window.location.pathname;
        let pathAdj = path.charAt(path.length-1) === "/" ? path.slice(0, -1) : path;
        let pathSplit = pathAdj.split("/");
        let roundtableId = pathSplit[pathSplit.length-1]
        const resPublic = await dispatch(tryGetAllRoundtablesOneDetail(roundtableId))
        const res = await dispatch(tryGetMyRoundtableData(roundtableId))
        console.log("resPublic", resPublic)
        console.log("res", res)
        setMyRoundtableData({
            ...resPublic,
            "hasJoined": Object.keys(res).length > 0
        })
    }

    return (
        <>
            <b>{myRoundtableData.title}</b>
            <br />
            <br />
            <Img src={myRoundtableData.coverPhoto} alt={""}/>
            <div>{myRoundtableData.time}</div>
            {!myRoundtableData.hasJoined &&
                <div onClick={() => {dispatch(tryPostJoinRoundtable(myRoundtableData.id))}}>JOIN</div>
            }
        </>
    );
}

export default withRouter(FirmRoundtablesRecord);

