import React from 'react';
import styled from 'styled-components';

/* Components */
import {ButtonInactive, ButtonPrimary, ButtonTertiary} from "../../../common/components/buttons";
import {colors} from "../../../common/components/colors";
import {FontBody16} from "../../../common/components/fonts";
import {images} from "../../../common/components/images";

const FitContent = styled.div`
    width: fit-content;
`;

export const BackSectionCourse = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 30px;
`;

export const BackCourse = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
`;
export const ChevronLeftRight = styled.img`
    padding: 3.5px 7.5px 5.5px 7.5px;
    vertical-align: top;
    display: block;
    object-fit: contain;
`;

export const HalfAlign = styled.div`
    display: inline-block;
    vertical-align: top;
    ${props => props.side !== "left"} {
        width: 38%;
        text-align: left;
        @media screen and (max-width: 460px) {
            width: 49%;
            margin-right: 2%;
        }
    }
    ${props => props.side !== "center"} {
        width: 24%;
        text-align: center;
        padding: 8px 0px;
        @media screen and (max-width: 460px) {
            display: none;
        }
    }
    ${props => props.side !== "right"} {
        width: 38%;
        text-align: right;
        @media screen and (max-width: 460px) {
            width: 49%;
        }
    }
    // margin-bottom: -15px;
`;

export const Width = styled.div`
    max-width: 536px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
`;
export const Border = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.primary30};
    padding-top: 30px;
    margin-bottom: 30px;
`;
export const SaveSection = styled.div`
    ${props => props.show !== true} {
        // border-top: 1px solid ${colors.border100};
        text-align: left;
        padding-top: 20px;
        margin-top: 50px;
        display: flex;
        flex-direction: row;
        padding-bottom: 100px;
    }
    ${props => props.show !== false} {
        height: 100px;
    }
`;
export const InlineBlock = styled.div`
    display: inline-block;
`;
export const ButtonRight = styled.div`
    margin-right: 10px;
    width: fit-content;
`;
const NextButton = ({ nextEnabeld, nextLabel, nextAction}) => {
    if(nextEnabeld) {
        return (<ButtonRight onClick={nextAction}><ButtonPrimary canSubmit={false} label={nextLabel}/></ButtonRight>)
    } else {
        return(<ButtonRight onClick={nextAction}><ButtonInactive canSubmit={false} label={nextLabel}/></ButtonRight>)
    }
};

const NavActionBottom = ({backAction, backLabel, nextLabel, nextAction, numPage, numPages, nextEnabeld, numPagesVisible}) => (
    <>
        <SaveSection show={backLabel !== "" || numPagesVisible || nextLabel !== ""}>
            <HalfAlign side={"left"}>
                {backLabel !== "" &&
                    <InlineBlock><FitContent onClick={backAction}><ButtonTertiary canSubmit={false} label={backLabel} /></FitContent></InlineBlock>
                }
            </HalfAlign>
            <HalfAlign side={"center"}>
                {numPagesVisible && <FontBody16>{numPage} of {numPages}</FontBody16>}
            </HalfAlign>
            <HalfAlign side={"right"}>
                {nextLabel !== "" &&
                    <InlineBlock><NextButton nextAction={nextEnabeld ? nextAction : () => {}} nextLabel={nextLabel} nextEnabeld={nextEnabeld} /></InlineBlock>
                }
            </HalfAlign>
        </SaveSection>
    </>
);

export const NavActionTop = ({back}) => (
    <BackSectionCourse>
        <BackCourse onClick={() => {return back()}}>
            <ChevronLeftRight src={images.chevronLeft} alt={""} />
            <InlineBlock><FontBody16>{/*this.state.backLabel*/}</FontBody16></InlineBlock>
        </BackCourse>
    </BackSectionCourse>
);

class FormActions extends React.Component {
    state={
        actions: {
            backLabel: "",
            nextLabel: "",
            pageNumVisible: false,
            canContinueAutomatically: false
        },
        pageComplete: false,
        currentPageNum: 1,
        totalPages: 1,
        backAction: () => {},
        actionNextPage: () => {},
        actionCompleteForm: () => {},
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.currentPageNum !== this.props.currentPageNum || prevProps.pageComplete !== this.props.pageComplete) {
            return this.init();
        }
    }

    init = async () => {
        await this.setState({
            actions: this.props.actions,
            pageComplete: this.props.pageComplete,
            currentPageNum: this.props.currentPageNum,
            totalPages: this.props.totalPages,
            actionLastPage: this.props.actionLastPage,
            actionNextPage: this.props.actionNextPage,
            actionCompleteForm: this.props.actionCompleteForm,
        });
    };

    render() {
        return(
            <NavActionBottom
                backAction={this.state.actionLastPage}
                backLabel={this.state.actions.backLabel}
                nextLabel={this.state.actions.nextLabel}
                nextAction={(this.state.currentPageNum === this.state.totalPages) ? this.state.actionCompleteForm : this.state.actionNextPage}
                nextEnabeld={(this.state.actions.canContinueAutomatically || this.state.pageComplete)}
                numPage={this.state.currentPageNum}
                numPages={this.state.totalPages}
                numPagesVisible={this.state.actions.pageNumVisible}
            />
        )
    }
}

export default FormActions;