import React from 'react';
import styled from 'styled-components';
import {connect} from "react-redux";
import ReactQuill from "react-quill";
import {v4 as uuid4} from "uuid";

/* Components */
import {colors} from '../../../common/components/colors';
import {FontBody14, FontBody16, FontTitle24} from '../../../common/components/fonts';
import {ButtonPrimary, ButtonTertiary, ButtonInactive} from '../../../common/components/buttons';
import {CloseOutlined, LineOutlined} from '@ant-design/icons';
import {editorFormats, editorModules} from "../../../common/components/richtexteditor";

/* Middleware */
import {tryPostComment} from "../middleware/comments";

/* Store */
import {setCommentModal, setCommentModalMinimized, setCommentSelected} from "../store/comments";

export const NoteTitle = styled.div`
    padding: 15px 0px 10px 0px;
    ${props => props.isFromMe === true} {
        color: ${colors.secondary100};
    }
    ${props => props.isFromMe === false} {
        color: ${colors.white};
    }
`;
export const Date = styled.div`
    color: ${colors.primary30};
    margin-top: 20px;
`;
const ComposeWrapper = styled.div`
    border-radius: 6px 6px 0px 0px;
    z-index: 15;
    position: fixed;
    bottom: 0;
    right: 100px;
    max-width: 800px;
    width: 100%;
    -webkit-box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    @media screen and (max-width: 1088px) {
        right: 60px;
        max-width: 640px;
    }
    @media screen and (max-width: 768px) {
        -webkit-box-shadow: none;
        box-shadow: none;
        ${props => props.minimized !== false} {
            top: 0;
            height: 100%;
            overflow-y: auto;
        }
        background-color: ${colors.white};
        right: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
    }
`;
const Compose = styled.div`
    padding: 20px;
    background-color: ${colors.white};
    @media screen and (max-width: 768px) {
        padding: 10px;
        height: calc(100vh - 56px);
        width: calc(100% - 20px);
    }
`;
const Buttons = styled.div`
    margin-top: 20px;
`;

const Close = styled.div`
    background-color: ${colors.primary100};
    width: 100%;
    border-radius: 6px 6px 0px 0px;
    height: 36px;
`;
const CloseMessage = styled.div`
    display: inline-block;
    color: ${colors.border100};
    padding: 7px 0px 0px 15px;
    width: calc(100% - 91px);
    cursor: pointer;
`;
const CloseButton = styled.div`
    padding: 10px;
    color: ${colors.border100};
    cursor: pointer;
    font-weight: bold;
    width: 18px;
    font-size: 14px;
    display: inline-block;
`;
const Body = styled.div`
    .ql-editor {
        min-height: 200px;
    } 
`;
const Modal = styled.div`
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    padding: 30px;
    border-radius: 4px;
    background-color: ${colors.white};
    -webkit-box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    max-width: 300px;
    width: calc(100% - 80px);
    z-index: 20;
`;
const ConfirmTitle = styled.div`
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.border100};
    margin-bottom: 15px;
`;
const ConfirmActions = styled.div`
    margin-top: 20px;
`;
const ConfirmLeft = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
`;
const ConfirmRight = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
`;
const ConfirmBtnFitLeft = styled.div`
    width: fit-content;
`;
const ConfirmBtnFitRight = styled.div`
    width: fit-content;
    margin: 0px 0px 0px auto;
`;
const Sent = styled.div`
    color: ${colors.progress100};
    text-align: center;
    padding: 30px 0px;
`;
const SendBtn = styled.div`
    display: inline-block;
    width: fit-content;
    vertical-align: top;
`;


class CommentCompose extends React.Component {
    constructor(props) {
        super(props);
        this.state = {switchModal: false, isOpen: false, comment: false, noteId: '', content: '', quill: '', confirmModal: false, savedModal: false}
    }

    componentDidMount() {
        return this.open();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.lastInitiated !== this.props.lastInitiated && this.state.noteId !== this.props.note.get("noteId")) {
            if(this.state.isOpen) {
                return this.setState({"switchModal": true})
            } else {
                return this.open()
            }
        }
    }

    open = async () => {
        await this.setState({isOpen: this.props.commentModal, noteId: this.props.note.get("noteId"), quill: '', content: ""});
        this.handleNewQuill();
    };

    close = async () => {
        await this.props.setCommentModal(false);
        await this.props.setCommentModalMinimized(false);
        await this.props.setCommentSelected({"noteId": '', "name": '', "title": '', "body": '', "datetime": '', "numberOfComments": ''});
        await this.setState({isOpen: false, switchModal: false, noteId: '', content: '', quill: '', confirmModal: false});
    };

    handleNewContent = e => this.setState({"content": e});

    handleNewQuill = () => {
        this.setState({"quill": <ReactQuill
                id={uuid4()}
                theme={"snow"}
                onChange={this.handleNewContent}
                value={this.state.content}
                modules={editorModules}
                formats={editorFormats}
                bounds={'.app'}
                placeholder={"Write your comment here..."}
            />});
    };

    componentWillUnmount() {
        clearInterval(this.timeout);
    }

    handleSaveNewNote = async () => {
        await this.props.tryPostComment(this.state.noteId, this.state.content);
        this.close();
        this.setState({sentModal: true});
    };

    switch = async () => {
        await this.open();
        this.setState({"switchModal": false});
    };

    render() {
        if (this.props.platform === "enterprise") {
            if (this.state.isOpen) {
                return (
                    <>
                        <ComposeWrapper minimized={this.props.commentModalMinimized}>
                            <Close>
                                <CloseMessage onClick={() => {
                                    this.props.setCommentModalMinimized(!this.props.commentModalMinimized)
                                }}><FontBody14>New Comment</FontBody14></CloseMessage>
                                <CloseButton onClick={() => {
                                    this.props.setCommentModalMinimized(!this.props.commentModalMinimized)
                                }}><LineOutlined/></CloseButton>
                                <CloseButton onClick={() => {
                                    this.setState({"confirmModal": true})
                                }}><CloseOutlined/></CloseButton>
                            </Close>
                            {!this.props.commentModalMinimized &&
                            <Compose>
                                <Body>{this.state.quill}</Body>
                                <Buttons>
                                    {!(this.state.content === '' || this.state.content === '<p><br></p>')
                                        ? <SendBtn><ConfirmBtnFitLeft
                                            onClick={this.handleSaveNewNote}><ButtonPrimary
                                            label={"Save"} canSubmit={false}/></ConfirmBtnFitLeft></SendBtn>
                                        : <SendBtn><ButtonInactive label={"Save"} canSubmit={false}/></SendBtn>
                                    }
                                </Buttons>
                            </Compose>
                            }
                        </ComposeWrapper>
                        {this.state.confirmModal &&
                        <Modal>
                            <ConfirmTitle><FontTitle24>Are you sure?</FontTitle24></ConfirmTitle>
                            <FontBody16>Do you want to delete this comment? This cannot be undone.</FontBody16>
                            <ConfirmActions>
                                <ConfirmLeft>
                                    <ConfirmBtnFitLeft onClick={() => {
                                        this.setState({"confirmModal": false})
                                    }}><ButtonTertiary label={"Cancel"} canSubmit={true}/></ConfirmBtnFitLeft>
                                </ConfirmLeft>
                                <ConfirmRight>
                                    <ConfirmBtnFitRight onClick={async () => {
                                        this.close()
                                    }}><ButtonPrimary label={"Delete"} canSubmit={true}/></ConfirmBtnFitRight>
                                </ConfirmRight>
                            </ConfirmActions>
                        </Modal>
                        }
                        {this.state.switchModal &&
                        <Modal>
                            <ConfirmTitle><FontTitle24>Are you sure?</FontTitle24></ConfirmTitle>
                            <FontBody16>Do you want to close this comment?</FontBody16>
                            <ConfirmActions>
                                <ConfirmLeft>
                                    <ConfirmBtnFitLeft onClick={() => {this.setState({"switchModal": false})}}><ButtonTertiary label={"Cancel"} canSubmit={true} /></ConfirmBtnFitLeft>
                                </ConfirmLeft>
                                <ConfirmRight>
                                    <ConfirmBtnFitRight onClick={async () => {this.switch()}}><ButtonPrimary label={"Close"} canSubmit={true} /></ConfirmBtnFitRight>
                                </ConfirmRight>
                            </ConfirmActions>
                        </Modal>
                        }
                    </>
                )
            } else if (this.state.savedModal) {
                return (
                    <Modal>
                        <Sent><FontTitle24>Note saved!</FontTitle24></Sent>
                    </Modal>
                )
            } else {
                return null
            }
        } else {
            return null
        }
    }
}

const mapStateToProps = state => ({
    commentModal: state.enterprise.comments.comments.get("commentModal"),
    lastInitiated: state.enterprise.comments.comments.get("lastInitiated"),
    commentModalMinimized: state.enterprise.comments.comments.get("commentModalMinimized"),
    note: state.enterprise.comments.comments.get("commentSelected"),
    client: state.enterprise.clientsDetail.get("client"),
    platform: state.common.user.get("platform"),
});

const mapDispatchToProps = dispatch => ({
    setCommentModalMinimized: (minimized) => dispatch(setCommentModalMinimized(minimized)),
    setCommentModal: (status) => dispatch(setCommentModal(status)),
    tryPostComment: (noteId, content) => dispatch(tryPostComment(noteId, content)),
    setCommentSelected: (note) => dispatch(setCommentSelected(note)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentCompose);
