import React from 'react';

/* Components */
import {CommonBackground, CommonModal} from '../../../common/components/modal';

/* Containers */
import SchedulerFlow from "../../scheduler/containers/flow";

class IframeStoreVideoCall extends React.Component {
    render() {
        return(
            <>
                <CommonBackground />
                <CommonModal>
                    <SchedulerFlow
                        userId={this.props.profile.get("id")}
                        userFirstName={this.props.profile.get("firstName")}
                        userLastName={this.props.profile.get("lastName")}
                        userPhotoUrl={this.props.profile.get("photo")}
                        eventCustomMinutes={this.props.profile.get("defaultCallLength")}
                        availabilityCloseBtn={true}
                        availabilityOnClose={() => {return this.props.changeShowScheduler(false)}}
                        confirmOnClose={() => {return this.props.changeShowScheduler(false)}}
                    />
                </CommonModal>
            </>
        )
    }
}

export default IframeStoreVideoCall;
