import { combineReducers } from 'redux';

/* Reducers */
import meetingsReducer from './store/meetings';

const teamReducer = combineReducers({
    meetings: meetingsReducer,
});

export default teamReducer;
