import { combineReducers } from 'redux';

/* Reducers */
import expertProfileReducer from './store/expertprofile';
import listingsReducer from './store/listings';

const schedulerReducer = combineReducers({
    expertProfile: expertProfileReducer,
    listings: listingsReducer
});

export default schedulerReducer;
