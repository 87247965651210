
// ---------------------------------------------------------------------------------------------------------------------
// PRODUCTS - GENERAL (START)
// ---------------------------------------------------------------------------------------------------------------------

const checkSessionsCompleted = async (sessions) => {
    const countCompleted = await Promise.all(sessions.map(s => {
        if(s.isWillowSession && !s.isCanceled && !s.isMissed && !s.isOngoing && !s.meetingIsTrial) {
            return(1);
        } else {
            return(0);
        }
    }));
    return (countCompleted.reduce((a, b) => a + b, 0));
};

const checkSessionsUpcoming = async (sessions) => {
    const countUpcoming = await Promise.all(sessions.map(s => {
        if(s.isWillowSession) {
            return(1);
        } else {
            return(0);
        }
    }));
    return (countUpcoming.reduce((a, b) => a + b, 0));
};

export const checkRemainingSessionsProductsGeneral = async (pastCalls, upcomingCalls, productsGeneral) => {
    const sessionsUpcoming = await checkSessionsUpcoming(upcomingCalls);
    const sessionsComplete = await checkSessionsCompleted(pastCalls);
    const remainingSessions = productsGeneral;
    return({
        "sessionsCompleted": sessionsComplete,
        "sessionsUpcoming": sessionsUpcoming,
        "sessionsRemaining": remainingSessions + sessionsUpcoming,
        "sessionsSchedulable": remainingSessions,
        "remainingSessions": remainingSessions,
        "serviceType": "online",
        "purchaseHistoryId": null,
        "isWillowSession": true
    });
};

// ---------------------------------------------------------------------------------------------------------------------
// PRODUCTS - GENERAL (END)
// ---------------------------------------------------------------------------------------------------------------------
