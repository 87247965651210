import { Map } from 'immutable';

/* Immutable */

const NAV_PAGE_STATUS = 'NAV_PAGE_STATUS';
const NAV_STORE_RESET = 'NAV_STORE_RESET';

/* Actions */

export const setNavPageStatus = (status) => ({'type': NAV_PAGE_STATUS, 'status': status});
export const setNavStoreReset = () => ({'type': NAV_STORE_RESET});

/* Initial State */

const initialState = Map({
    pageStatusActive: false
});

/* Reducer */

const navReducer = (state=initialState, action) => {
    switch (action.type) {
        case NAV_PAGE_STATUS:
            return state.merge({
                'pageStatusActive': action.status
            });
        case NAV_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default navReducer;
