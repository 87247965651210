import React from "react";
import styled from "styled-components";

/* Components */
import {colors} from "../../../common/components/colors";
import {FontSubtitle28} from "../../../common/components/fonts";

export const SettingsCard = styled.div`
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    background-color: ${colors.white};
    border-radius: 7px;
    border: 1px solid ${colors.primary30};
    padding: 30px;
    color: ${colors.primary100};
    max-width: ${props => props.maxWidth};
    margin: 0px auto 40px auto;
    @media screen and (max-width: 748px) {
        margin: 40px auto 0px auto;
    }
    @media screen and (max-width: 460px) {
        margin: 20px auto 0px auto;
        padding: 30px 20px;
    }
    ${props => props.active !== true} {
        &:hover {
            -webkit-box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }
    
`;

export const SmallCard = styled.div`
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    background-color: ${colors.white};
    border-radius: 25px;
    border: 1px solid ${colors.primary30};
    color: ${colors.primary100};
    width: 170px;
    margin: 0px 10px 30px 10px;
    @media screen and (max-width: 425px) {
        width: 135px;
    }
    ${props => props.active !== true} {
        &:hover {
            -webkit-box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }
    display: inline-flex;
    justify-content: center;
    align-items: center;
    
`;

export const Bar = styled.div`
    width: 100%;
    height: 1px;
    border-bottom: 1px solid ${colors.secondary30};
`;

export const Center = styled.div`
    text-align: center;
`;

export const Text = styled.div`
    margin: 10px 0px;
    
    ${props => props.active !== true} {
        overflow: revert;
        display: revert;
        -webkit-line-clamp: revert;
        -webkit-box-orient: revert;
    }
    ${props => props.active !== false} {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
`;

export const Image = styled.img`
    height: 150px;
    width: 100%;
    vertical-align: top;
    display: inline-block;
    object-fit: cover;
    border-radius: 25px 25px 0 0;
    @media screen and (max-width: 425px) {
        height: 120px;
    }
`;

export const Icon = styled.div`
    display: inline-block;
    vertical-align: top;
    ${props => props.active !== true} {
        color: ${colors.white};
    }
    ${props => props.active !== false} {
        color: ${colors.action100};
    }
    & svg {
        width: 16px;
        height: 16px;
        margin-top: 3px;
    }
    @media screen and (max-width: 460px) {
        display: none;
    }

`;

export const InlineBlock = styled.div`
    display: inline-block;
    margin-right: 10px;
    margin-top: 10px;
`;

export const SpaceBetween = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const TopBox = styled.div`
    cursor: pointer;
`;

export const OpenLink = styled.div`
    ${props => props.active !== true} {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
    ${props => props.active !== false} {
        cursor: default;
    }
    
`;

export const BackTop = styled.div`
    color: ${colors.secondary70};
    cursor: pointer;
    position: relative;
    text-align: left;
    display: block;
    width: fit-content;
`;

export const LoadingCenter = styled.div`
    margin: 60px auto;
`;

export const BulletArea = styled.div`
    margin: 0px 20px;
`;

export const Bullet = styled.div`
    padding: 10px;
`;

export const FAQSection = styled.div`
    border-top: 1px solid ${colors.primary30};
    padding: 20px;
`;

export const MovingCard = styled.div`
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    display: inline-block;
    background-color: ${colors.white};
    border-radius: 7px;
    border: 1px solid ${colors.primary30};
    padding: 30px;
    color: ${colors.primary100};
    max-width: ${props => props.maxWidth};
    min-height: 300px;
    vertical-align: middle;
    width: 80%;
    margin: 0px 10px;
    @media screen and (max-width: 748px) {
        min-height: revert;
        margin: 0px 5px;
    }
    @media screen and (max-width: 460px) {
        padding: 30px 10px;
    }
    ${props => props.active !== true} {
        &:hover {
            -webkit-box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }
    
`;

export const LeftMovingCard = styled.div`
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    filter: brightness(0.7);
    display: inline-block;
    background-color: ${colors.white};
    border: 1px solid ${colors.primary30};
    border-left: revert;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    color: ${colors.primary100};
    height: 300px;
    vertical-align: middle;
    @media screen and (max-width: 748px) {
        min-height: revert;
    }
    ${props => props.active !== true} {
        &:hover {
            -webkit-box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }
    ${props => props.full !== false} {
        width: 5%;
    }
    ${props => props.full !== true} {
        width: 3%;
    }
    
`;

export const RightMovingCard = styled.div`
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    display: inline-block;
    filter: brightness(0.7);
    background-color: ${colors.white};
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    border: 1px solid ${colors.primary30};
    border-right: revert;
    color: ${colors.primary100};
    width: 5%;
    height: 300px;
    vertical-align: middle;
    @media screen and (max-width: 748px) {
        min-height: revert;
    }
    ${props => props.active !== true} {
        &:hover {
            -webkit-box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }
    ${props => props.full !== false} {
        width: 5%;
    }
    ${props => props.full !== true} {
        width: 3%;
    }
    
`;

export const PageTitle = styled(FontSubtitle28)`
    text-align: left;
    margin-bottom: 10px;
    @media screen and (max-width: 425px) {
        text-align: center;
    }
`;

export const BackSection = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 10px;
`;

export const Back = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
`;
export const ChevronLeftRight = styled.img`
    padding: 3.5px 7.5px 5.5px 7.5px;
    vertical-align: top;
    display: block;
    object-fit: contain;
`;