import {axiosReq} from "../../../common/utils/axios";
import moment from "moment-timezone";

/* Store */
import {setGroupSessionParticipantsDownloading, setGroupSessionParticipants, setGroupSessionCreating, setGroupSessionsDownloading, setGroupSessions, setGroupSessionUpdating, setGroupSessionUpdated, setGroupSessionCreated, setGroupSessionEmailComplete, setGroupSessionEmailSending, setGroupSessionWhenUpdated, setGroupSessionWhenUpdating} from "../store/group";

/* Static */
import {devEnterpriseCreateGroupSessionData, devEnterpriseDownloadGroupSessionData, devEnterpriseDownloadGroupSessionParticipantsData, devEnterpriseEditGroupSessionWhenData} from '../static/devdata';

/* Utils */
import {adjForTZ} from "../../../common/utils/timezone";

/* Components */
import {colors} from "../../../common/components/colors";

const staticGroupSessions = (details) => ({
    "backgroundColor": (moment(details.end).isBefore(moment()) || !details.isActive) ? colors.border100 : colors.white,
    "borderColor": (moment(details.end).isBefore(moment()) || !details.isActive) ? colors.primary70 : colors.action100,
    "textColor": colors.primary100,
    "editable": false,
    "inThePast": (moment(details.end).isBefore(moment()) || !details.isActive),
    "meetingType": 'groupSession',
});

const sessionModify = (session, expert) => {
    return async (dispatch, getState) => {
        const timeZone = getState().common.user.get("timeZone");
        let sessionDateTimesChange = {};
        if (expert !== null) {
            sessionDateTimesChange = {...session, "start": session.flashSaleStartDateTime, "end": session.flashSaleEndDateTime, "expert": expert};
        }
        else {
            sessionDateTimesChange = {...session, "start": session.flashSaleStartDateTime, "end": session.flashSaleEndDateTime}
        }
        return {...staticGroupSessions(sessionDateTimesChange), ...adjForTZ(sessionDateTimesChange, timeZone)}
    }
};

export const tryGetAllSessionsLarge = () => {
    return async dispatch => {
        dispatch(setGroupSessionsDownloading(true));
        const sessions = await axiosReq('apiv1/coach/settings/flash-sales', "GET", {}, devEnterpriseDownloadGroupSessionData);
        const sessionsModified = await Promise.all(sessions.flashSales.map(async a => {
            let modified = await dispatch(sessionModify(a, sessions.expert));
            return Promise.resolve(modified)
        }));
        await dispatch(setGroupSessions(sessionsModified));
        dispatch(setGroupSessionsDownloading(false));
    }
};

export const tryCreateSessionLarge = (request) => {
    return async dispatch => {
        dispatch(setGroupSessionCreating(true));
        const session = await axiosReq('apiv1/coach/settings/flash-sales', "POST", request, devEnterpriseCreateGroupSessionData);
        let modified = await dispatch(sessionModify({...session.flashSale, "registrationCount":0}, session.expert));
        await dispatch(setGroupSessionCreated(modified));
        dispatch(setGroupSessionCreating(false));
    }
};

export const tryEditSessionLarge = (request) => {
    return async dispatch => {
        dispatch(setGroupSessionUpdating(true));
        const session = await axiosReq('apiv1/coach/settings/flash-sales/'+request.id, "PUT", request, devEnterpriseCreateGroupSessionData);
        let modified = await dispatch(sessionModify(session.flashSale, session.expert));
        await dispatch(setGroupSessionUpdated(modified, modified.id));
        dispatch(setGroupSessionUpdating(false));
    }
};

export const tryEditSessionWhenLarge = (request) => {
    return async dispatch => {
        dispatch(setGroupSessionWhenUpdating(true));
        const session = await axiosReq('apiv1/coach/settings/flash-sales/reschedule/'+request.id, "POST", request, devEnterpriseEditGroupSessionWhenData);
        let modified = await dispatch(sessionModify(session, null)); 
        await dispatch(setGroupSessionWhenUpdated(modified, modified.id));
        dispatch(setGroupSessionWhenUpdating(false));
    }
}

export const tryGetSessionLargeParticipants = (meetingId) => {
    return async dispatch => {
        dispatch(setGroupSessionParticipantsDownloading(true));
        const session = await axiosReq('apiv1/coach/settings/flash-sales/'+meetingId, "GET", {}, devEnterpriseDownloadGroupSessionParticipantsData);
        let modified = await dispatch(sessionModify(session, session.expert));
        await dispatch(setGroupSessionParticipants(modified));
        dispatch(setGroupSessionParticipantsDownloading(false));
    }
};

export const tryEmailWebinarParticipants = (webinarId, message) => {
    return async dispatch => {
        await dispatch(setGroupSessionEmailSending());
        const status = await axiosReq('apiv1/coach/settings/flash-sales/notify', "POST", {flashSaleId: webinarId, message: message}, {});
        let messageResult;
        if(status.hasOwnProperty("error")) {
            messageResult = status;
        } else {
            messageResult = {"error": null}
        }        
        await dispatch(setGroupSessionEmailComplete());
        return(messageResult)
    }
};
