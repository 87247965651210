import {axiosReq} from "../../../utils/axios";

/* Store */
import imagesReducer, {setImagesUploading} from "../store/images";

/* Static */
import {devEnterpriseUploadImageGetUrl} from "../static/devdata";

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {resolve(reader.result);};
    reader.onerror = error => reject(error);
});

export const tryPostImage = (file) => {
    return async dispatch => {
        dispatch(setImagesUploading(true));
        let base64 = file;
        if(file instanceof File) {
            base64 = await toBase64(file);
        }
        const image = await axiosReq('apiv1/coach/images/upload', "POST", {"image": base64}, devEnterpriseUploadImageGetUrl);
        dispatch(setImagesUploading(false));
        if(image.hasOwnProperty("error")) {
            return image.error;
        } else {
            return image.image;
        }
    }
};
