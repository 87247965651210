import React from 'react';
import DirectoryForm from "../../certification/components/directoryform";

export default function FirmMarketingProfile(props) {
    const init = () => {

    }
    return (
        <>
            <DirectoryForm isClientAcq={true} onSave={init} setPublish={props.setPublish}/>
        </>
    )
}