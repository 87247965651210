import { combineReducers } from 'redux';

/* Common */
import willowProfileReducer from './store/profile';

const willowProfilesReducer = combineReducers({
    profile: willowProfileReducer,
});

export default willowProfilesReducer;
