import styled from "styled-components";

/* Components */
import {colors} from "../../../common/components/colors";

export const NavTopSupport = styled.div`
    position: relative;
    width: 100%;
    -webkit-box-shadow: 0 4px 2px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0 4px 2px 0x rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid ${colors.border100};
    z-index: 5;
    text-align: center;
    color: ${colors.white};
    background-color: ${colors.brand};
    padding: 15px 0px;
`;

export const NavTop = styled.div`
    position: relative;
    width: 100%;
    -webkit-box-shadow: 0 4px 2px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0 4px 2px 0x rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid ${colors.border100};
    z-index: 5;
    background-color: ${colors.white};
`;

export const NavRight = styled.div`
    text-align: right;
    width: calc(100% - 220px);
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 1088px) {
        display: none;
    }
    position: relative;
`;

export const Settings = styled.div`
    cursor: pointer;
    display: inline-block;
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: ${colors.secondary70};
    padding: 10px;
    margin-right: 30px;
    margin-top: 19px;
`;

export const User = styled.div`
    display: inline-block;
    width: 290px;
    padding: 18px 40px;
    border-left: 1px solid ${colors.border70};
    text-align: left;
    vertical-align: top;
    position: relative;
    cursor: pointer;
`;

export const UserSelection = styled.div`
    cursor: pointer;
`;

export const UserName = styled.div`
    color: ${colors.primary100};
    ${props => props.hasCompanyName === true} {padding-top: 16px;}
    ${props => props.hasCompanyName === false} {padding-top: 0px;}
`;

export const Firm = styled.div`
    color: ${colors.secondary70};
`;

export const UserLeft = styled.div`
    display: inline-block;
    width: calc(100% - 22px);
    vertical-align: top;
`;

export const UserRight = styled.div`
    display: inline-block;
    width: 22px;
    height: 22px;
    vertical-align: top;
    color: ${colors.primary70};
    font-size: 22px;
    margin-top: 17px;
    text-align: right;
    font-weight: bold;
`;

export const UserDropdown = styled.div`
    border: 1px solid ${colors.primary30};
    background-color: ${colors.white};
    border-radius: 4px;
    position: absolute;
    margin-top: 15px;
    width: 290px;
    right: 40px;
    z-index: 11;
    @media screen and (max-width: 1088px) {
        display: none;
    }
`;

export const DropdownOptions = styled.div`
    padding: 10px 0px;
    border-bottom: 1px solid ${colors.primary30};
`;

export const Option = styled.div`
    padding: 15px 25px;
    cursor: pointer;
    text-align: left;
    width: calc(100% - 50px);
    color: ${colors.primary100};
`;

export const SignOut = styled.div`
    display: inline-block;
    color: ${colors.action100};
    padding: 15px 25px;
    width: 50px;
    cursor: pointer;
`;

export const Privacy = styled.div`
    display: inline-block;
    margin-left: calc(100% - 230px);
    width: 80px;
    text-align: right;
    padding: 15px 25px;
    & a {
        color: ${colors.action100};
    }
`;

export const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
`;

export const Logo = styled.div`
    width: 160px;
    height: 32px;
    background-color: ${colors.white};
    vertical-align: top;
    display: inline-block;
    padding: 26px 30px;
    @media screen and (max-width: 460px) {
        padding: 26px 15px;
    }
`;
