import React from 'react';

/* Containers */
import HelpFAQ from "./containers/helpfaq";

/* Navs */
import {PageContent, PageFull, PageRight} from "../navbars/components/navlayout";
import NavPrimaryAll from "../navbars/containers/primaryall";
import NavSecondaryTop from '../navbars/containers/secondarytop';

/* Domain */
import getDomainEnv from '../../domains/utils';
const env = getDomainEnv();

const Resources = () => (
    <>
        <NavPrimaryAll />
        <PageFull>
            <PageRight>

                <NavSecondaryTop title={env.ENTERPRISE.EXPERT.RESOURCES.TITLE} />
                <PageContent>
                    <HelpFAQ data={env.ENTERPRISE.EXPERT.RESOURCES.DATA} />
                </PageContent>

            </PageRight>
        </PageFull>
    </>
);

export default Resources;
