import React from 'react';
import styled from 'styled-components';
import moment from 'moment/moment';

/* Containers */
import FirmSettingsProfileEdit from "./profilecontact";
import FirmSettingsPassword from "./profilepassword";
import FirmSettingsUserPreview from "./profilepreview";
import FirmSettingsProfileEditDesignations from "./profiledesignations";
import FirmSettingsStripe from "./profilestripe";

const Contained = styled.div`
  max-width: 1000px;
`;

class FirmSettingsProfile extends React.Component {
    state={"lastUpdated": null}

    update = async () => {
        this.setState({"lastUpdated": moment()})
    }

    render() {
        return(
            <Contained>
                <FirmSettingsUserPreview lastUpdated={this.state.lastUpdated} onSave={this.update} />
                <FirmSettingsProfileEdit lastUpdated={this.state.lastUpdated} onSave={this.update} />
                <FirmSettingsProfileEditDesignations />
                <FirmSettingsStripe />
                <FirmSettingsPassword /> 
            </Contained>
        )
    }
}

export default FirmSettingsProfile;