import React from 'react';
import {connect} from 'react-redux';
import ReactQuill from "react-quill";
import {v4 as uuid4} from "uuid";
import styled from 'styled-components';

/* Components */
import {Container, Body, Listing, Title, Type, Left, Right, Image, Header, Name, SubName, Spacing} from "../components/events";
import {FontBody16, FontTitle30, FontHeader18} from "../../../common/components/fonts";
import {QuillSlimNoHeight} from "../../../common/components/richtexteditor";
import {colors} from "../../../common/components/colors";
import {Message} from "../../../common/components/messages";
import {CloseOutlined} from '@ant-design/icons';
import { Mail, Video, Layers } from 'react-feather';
import {ButtonPrimary, ButtonInactiveLight} from '../../../common/components/buttons';
import {InlineBtn, CTAs, Inline, Icon16} from '../../shop/components/header';

const BackTop = styled.div`
    color: ${colors.secondary70};
    width: fit-content;
    padding: 5px 10px;
    display: block;
    cursor: pointer;
    margin-left: auto;
    margin-right: 0px;
    margin-bottom: 15px;
    position: relative;
    z-index: 25;
    @media screen and (max-width: 460px) {
        margin-left: 0px;
        margin-bottom: 25px;
    }
`;

export const BtnSpacing = styled.div`
    margin-right: 15px;
`;

class Events extends React.Component {
    state={"eventsAll": [], "hasEvents": false};

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevProps.eventsAllFetched && this.props.eventsAllFetched) {
            return this.init();
        }
        if(prevProps.eventsAll !== this.props.eventsAll) {
            return this.init();
        }
    }

    init = async () => {
        await this.setState({
            "eventsAll": this.props.eventsAll.toJS(),
            "hasEvents": this.props.eventsAll.size > 0
        });
        console.log(this.state)
    };

    render() {
        return(
            <Container>
                {this.props.eventCloseBtn && <BackTop onClick={this.props.eventOnClose}><CloseOutlined />&nbsp;Close</BackTop>}
                <Header>
                    <Left>
                        <Image src={this.props.photo} alt={""} />
                    </Left>
                    <Right>
                        <Name>
                            <FontTitle30>{this.props.firstName+" "+this.props.lastName}</FontTitle30>
                            <FontBody16><SubName>{this.props.profile.get("companyName")}</SubName></FontBody16>
                            <Spacing />
                            <SubName><FontBody16>Select a meeting below to schedule time with {this.props.firstName}</FontBody16></SubName>
                            {(this.props.profile.get("profileShowEmailer") || this.props.profile.get("storeActive")) &&
                                <CTAs>
                                    {this.props.profile.get("profileShowEmailer") &&
                                        <InlineBtn onClick={() => {return this.props.changeShowEmailer(true)}}>
                                            {this.props.profile.get("storeActive")
                                                ? <ButtonInactiveLight submit={false} label={<><Icon16><Mail /></Icon16><Inline>&nbsp;&nbsp;Message</Inline></>} />
                                                : <ButtonPrimary submit={false} label={<><Icon16><Mail /></Icon16><Inline>&nbsp;&nbsp;Message</Inline></>} />
                                            }
                                        </InlineBtn>
                                    }
                                    {(this.props.profile.get("profileShowEmailer") && this.props.profile.get("storeActive")) &&
                                        <Inline><BtnSpacing/></Inline>
                                    }
                                    {this.props.profile.get("storeActive") &&
                                        <InlineBtn onClick={() => {return this.props.changeShowProducts(true)}}><ButtonPrimary submit={false} label={<><Icon16><Layers /></Icon16><Inline>&nbsp;&nbsp;Products</Inline></>} /></InlineBtn>
                                    }
                                </CTAs>
                            }
                        </Name>
                    </Right>
                </Header>
                {this.state.hasEvents &&
                    <>
                        {this.state.eventsAll.map(l => (
                            <Listing onClick={() => {return this.props.selectEvent(l)}} key={l.id}>
                                <Body>
                                    <Title><FontHeader18>{l.title}</FontHeader18></Title>
                                    <Type><FontBody16>{l.sessionLength}-minutes</FontBody16></Type>
                                    <QuillSlimNoHeight bodyBorderColor={colors.white}>
                                        <ReactQuill
                                            id={uuid4()}
                                            readOnly={true}
                                            theme={null}
                                            value={l.description}
                                            bounds={'.app'}
                                        />
                                    </QuillSlimNoHeight>
                                </Body>
                            </Listing>
                        ))}
                    </>
                }
                {!this.state.hasEvents &&
                    <><br /><Message text={"No meetings available"} /></>
                }
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    eventsAll: state.iframe.scheduler.events.get("eventsAll"),
    eventsAllFetched: state.iframe.scheduler.events.get("eventsAllFetched"),
    profile: state.iframe.scheduler.profile.get("profile")
});

export default connect(mapStateToProps, null)(Events);
