import React from 'react';
import styled from 'styled-components';

/* Components */
import { colors } from '../../../common/components/colors';
import { FontBody18} from '../../../common/components/fonts';
import {ChevronDown, ChevronUp} from 'react-feather';
import CoachProfile from '../containers/coachprofile'

const BookButton = styled.div`
    border-radius: 0 0 20px 20px;
    color: ${colors.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.backgroundColor3};
    cursor: pointer;
    padding: 20px;
    width: calc(100% - 44px);
    border: 2px solid ${props => props.backgroundColor};
    border-top: 0 solid white;
    position: relative;
    ${props => props.isOpen !== true} {
        @media screen and (max-width: 1088px) {
            display: none;
        }
    }
`;

export const FooterCoach = ({backgroundColor, index, isOpen}) => (
    <BookButton
        backgroundColor={
            backgroundColor === null
                ? colors.cellColors[index % colors.cellColors.length]
                : backgroundColor
        }
        isOpen={isOpen}
    >
        {isOpen
            ? <>
                <FontBody18>{'Close'}</FontBody18>
                <ChevronUp style={{color: 'white', padding: '10px'}}/>
            </>
            : <>
                <FontBody18>{'View profile and availability'}</FontBody18>
                <ChevronDown style={{color: 'white', padding: '10px'}}/>
            </>
        }
    </BookButton>
)