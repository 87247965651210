import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/* Middleware */
import { tryAuth } from '../middleware/auth';

class Verify extends React.Component {
    componentDidMount() {
        return this.props.tryAuth(this.props.history)
    }
    render() {
        return null;
    }
}

const mapDispatchToProps = dispatch => ({
    tryAuth: (history) => dispatch(tryAuth(history)),
});

export default connect(null, mapDispatchToProps)(withRouter(Verify));