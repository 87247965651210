import {axiosReq} from "../../common/utils/axios";
import moment from "moment/moment";

/* Devdata */
import {devTeamMemberMeetingsData} from "../../consumer/team/static/devdata";

export const tryGetTrialCompleted = (prospectId) => {
    return async dispatch => {
        const sessions = await axiosReq('apiv1/coach/consumer/journey/sessions/' + prospectId, "GET", {}, devTeamMemberMeetingsData);
        const trialCall = await sessions.pastCalls.filter(a => {
            return (
                a.meetingIsTrial &&
                a.videoStatus !== "conference_canceled" && // canceled
                !(moment().isBefore(moment(a.start))) && // upcoming
                !(moment().isAfter(moment(a.start)) && moment().isBefore(moment(a.end))) && // ongoing
                moment().isAfter(moment(a.end)) && // past
                !a.isMissed
            )
        })
        return (trialCall.length > 0)
    }
}