import {images} from '../../../common/components/images';

export const willowCertified = (certType, price) => ({
    "id": certType+"_certification_session_cert"+price.toString(),
    "productId": certType+"_certification_session_cert"+price.toString(),
    "orderIndex": 0,
    "description": "",
    "numberOfSessions": 1,
    "amount": price*100,
    "isPrivate": false,
    "planType": "one_time",
    "recurringInterval": null,
    "subscriptionDurationInMonths": null,
    "status": "Active",
    "product": {
        "id": certType+"_certification_session_cert"+price.toString(),
        "title": certType === "can"
            ? "CERTIFIED ADVISOR FOR NEXTGEN™"
            : "CERTIFIED ADVISOR FOR WOMEN™",
        "description": "1 year",
        "coverPhoto": certType === "CAN"
            ? images.nextGenBadge
            : images.womenBadge,
        "serviceType": "online",
        "termsOfUseUrl": "https://www.trustwillow.com/terms-of-use/",
        "privacyPolicyUrl": "https://www.trustwillow.com/privacy-policy/",
        "downloadUrl": "",
        "sessionLength": 60,
        "status": "Active",
        "expert": {
            "id": null,
            "fullName": "Willow",
            "firstName": "Willow",
            "lastName": "",
            "storeHandle": "",
            "publicHandle": "",
            "profilePhotoUrl": ""
        }
    }
});
