import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from "styled-components";

/* Components */
import { LogoLoading } from "../../../common/components/loading";
import {FontTitle16, FontBody16, FontSubtitle28, FontBody21To16} from "../../../common/components/fonts";
import { ButtonPrimary } from "../../../common/components/buttons";
import { SmallGoalPreview } from "../components/goalone";
import {colors} from "../../../common/components/colors";

/* Middleware */
import { tryGetMyGoals } from '../middleware/progress';
import {ContainerApptNav, SettingsCardMissingInfo} from "../components/progress";

const SettingsCard = styled.div`
    -webkit-box-shadow: 0 3px 10px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 10px 3px rgba(0, 0, 0, 0.1);
    background-color: ${colors.white};
    color: ${colors.primary100};
    max-width: ${props => props.maxWidth};
    margin: 50px 35px;
    position: relative;
    padding: 40px;
    border-radius: 30px;
    margin-bottom: 20%;
    @media screen and (max-width: 748px) {
        padding: 30px;
        margin: 50px 25px;
    }
    @media screen and (max-width: 480px) {
        padding: 20px;
        margin: 50px 15px;
    }
`;
const SpaceBetweenMissingInfo = styled.div`
    display: flex;
    align-items: flex-start;
    text-align: left;
    justify-content: center;
    width: 100%;
    color: ${colors.secondary100};
    flex-direction: column;
    gap: 25px;
`;
const SpaceBetweenMissingInfoNone = styled.div`
    display: flex;
    align-items: flex-start;
    text-align: center;
    justify-content: center;
    width: 100%;
    color: ${colors.secondary100};
    flex-direction: column;
    gap: 25px;
`;
const FitContent = styled.div`
    margin: 0 auto;
    width: fit-content;
`;
const PageTitle = styled(FontSubtitle28)`
    text-align: left;
    margin-bottom: 35px;
`;
const FlexWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify content: flex-start;
    align-items: center;
    gap: 25px;
    width: 100%;
    margin-top: 15px;
    flex-wrap: wrap;
`
const NavOption = styled(FontBody21To16)`
    ${props => props.active !== true} {
        border-bottom: 3px solid ${colors.white};
        cursor: default;
    }
    ${props => props.active !== false} {
        border-bottom: none;
        cursor: pointer;
    }
    width: fit-content;
    color: ${colors.white};
}
`;

const Bar = styled.div`
    width:2px;
    margin: 4px 0;
    background-color: ${colors.white}
`;

class CoursesListGoals extends React.Component {
    state = { "isLoading": true, "hasJourneys": true, "currentTab": "all"};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.props.tryGetMyGoals();
        this.setState({
            "hasMyGoals": this.props.myGoals.size !== 0,
            "isLoading": false
        })
    };

    createGoal = () => {
        this.props.history.push('/progress/goals/create');
    }

    changeTab = async (tabToSwitchTo) => {
        await this.setState({"currentTab": tabToSwitchTo});
    };

    render() {
        if (this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <>
                    <ContainerApptNav>
                        <NavOption active={this.state.currentTab === "all"} onClick={() => {this.changeTab('all');}}>
                            {'All goals'}
                        </NavOption>
                        {/*<Bar />*/}
                        {/*<NavOption active={this.state.currentTab === "snapshot"} onClick={() => {this.changeTab('snapshot');}}>*/}
                        {/*    {'Money Snapshot'}*/}
                        {/*</NavOption>*/}
                    </ContainerApptNav>
                    {this.state.hasMyGoals &&
                        <SettingsCard>
                            <PageTitle>GOALS</PageTitle>
                            <SpaceBetweenMissingInfo>
                                <FontBody16>
                                    Setting goals helps keep you on track and helps us support you towards achieving those goal.
                                </FontBody16>
                                <div onClick={() => this.createGoal()}>
                                    <ButtonPrimary submit={false} label={"Create a goal"}/>
                                </div>
                                {/*<FontBody16>
                                Goal check-ins help keep you on track and help us support you towards achieving your goal.
                            </FontBody16>*/}
                                <FlexWrap>
                                    {this.props.myGoals.map((goal, index) => (
                                        <SmallGoalPreview key={index} goal={goal}/>
                                    ))}
                                </FlexWrap>
                            </SpaceBetweenMissingInfo>
                        </SettingsCard>
                    }
                    {!this.state.hasMyGoals &&
                        <SettingsCardMissingInfo>
                            <SpaceBetweenMissingInfoNone>
                                <FontTitle16>No current goals.<br/>Create a goal and track your progress!</FontTitle16>
                                <FitContent onClick={() => this.createGoal()}>
                                    <ButtonPrimary submit={false} label={"Create goal"}/>
                                </FitContent>
                            </SpaceBetweenMissingInfoNone>
                        </SettingsCardMissingInfo>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.common.user,
    myGoals: state.consumer.progress.progress.get("myGoals")
});

const mapDispatchToProps = dispatch => ({
    tryGetMyGoals: () => dispatch(tryGetMyGoals())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CoursesListGoals));