import { combineReducers } from 'redux';

/* Common */
import programsReducer from "./store/programs";

const programReducer = combineReducers({
    programs: programsReducer,
});

export default programReducer;
