import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';

/* Components */
import {FontTitle40, FontBody24, fontFamily} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";
import {ButtonInactive, ButtonPrimary} from "../../../common/components/buttons";

/* Middleware */
import {tryPostFeedback} from '../middleware/feedback';

export const ButtonWide = styled.div`
    & > button > div {
        padding: 13px 65px;
        margin: 0 auto;
    }
    margin: 0 auto;
    text-align: center;
`;
export const ButtonWideInactive = styled.div`
    & > button, & > div {
        padding: 13px 65px;
        margin: 0 auto;
    }
    margin: 0 auto;
    text-align: center;
`;
const TextArea = styled.textarea`
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.12);
    background-color: ${colors.white};
    margin: 40px 0 150px 0;
    border-radius: 30px;
    padding: 30px 40px;
    resize: none;
    font-family: ${fontFamily.body};
    font-size: 24px;
    line-height: 28px;
    min-height: 260px;
    border: none;
    width: calc(100% - 80px);
    @media screen and (max-width: 460px) {
        padding: 10px 15px;
        width: calc(100% - 30px);
    }
    &:focus {
        outline: 0;
    }
`;
const Section = styled.div`
    margin-top: 40px;
    overflow: visible;
    height: calc(100% - 132px);
`;
const ThankYou = styled(FontBody24)`
    margin-top: 80px;
    text-align: center;
`;

class FeedbackLeftBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {submitted: false, message: ''}
    }

    handleChange = e => this.setState({message: e.target.value});
    handleSubmit = e => {
        e.preventDefault();
        if(this.state.message !== '') {
            this.props.tryPostFeedback(this.state.message);
            this.setState({submitted: true, message: ''});
        }
    };

    render() {
        return(
            <Section>
                <FontTitle40 spaced={false}>Have feedback?</FontTitle40>
                <form method={"post"} onSubmit={this.handleSubmit}>
                    <TextArea
                        value={this.state.message}
                        onChange={this.handleChange}
                        placeholder={"We love feedback! Tell us what you like or what we can do better."}
                    />
                    {this.state.message !== ""
                        ? <ButtonWide><ButtonPrimary canSubmit={true} label={"Send"} /></ButtonWide>
                        : <ButtonWideInactive><ButtonInactive canSubmit={false} label={"Send"} /></ButtonWideInactive>
                    }
                </form>
                {this.state.submitted && <ThankYou>Thank you for your feedback!</ThankYou>}
            </Section>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    tryPostFeedback: (message) => dispatch(tryPostFeedback(message))
});

export default connect(null, mapDispatchToProps)(FeedbackLeftBar);
