import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';

/* Components */
import {TextInput, ReadOnlyInput, SubmitInputWithValidation} from "../../../common/components/inputs";
import {colors} from "../../../common/components/colors";
import {FontTitle18} from "../../../common/components/fonts";
import {SavedSettings, SettingsCard, SettingsCardTitle} from '../../../common/components/cards';

/* Middleware */
import {tryUpdateSettingsEnterpriseTerms, tryGetSettingsEnterpriseTerms} from '../middleware/settingsterms';

class SettingsEnterpriseTerms extends React.Component {
    state = {
        rate: '',
        maxClients: '', maxClientsValid: true,
        isSaving: false,
        isSaved: false,
        error: '',
        isChanged: false
    };

    componentDidMount() {
        Promise.resolve(this.props.tryGetSettingsEnterpriseTerms())
            .then(() => {
                this.setState(this.props.settingsEnterpriseTerms.toJS())
            })
    }

    handleChangeMaxClients = e => {
        this.setState({'maxClients': e.target.value});
        this.setState({'maxClientsValid': (validator.isInt(e.target.value, {min: 0}))});
        this.setState({'isChanged': true});
    };

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    handleSubmit = e => {
        e.preventDefault();
        if(this.state.maxClientsValid) {
            this.setState({'isSaving': true, 'error': ''});
            Promise.resolve(this.props.tryUpdateSettingsEnterpriseTerms(this.state))
                .then(() => {
                    if(this.props.settingsEnterpriseTerms.get("error") !== '') {
                        this.setState(this.props.settingsEnterpriseTerms.toJS());
                    }
                    this.setState({'isSaving': false, 'isSaved': true});
                    this.timeout = setTimeout(() => {this.setState({'isSaved': false})}, 3000);
                })
        }
    };

    render() {
        return(
            <>
            {/*<SettingsCard>*/}
            {/*    <SettingsCardTitle title={"Prospects from Willow"} />*/}
                <FontTitle18>Prospects from Willow</FontTitle18>
                {/*{this.state.isSaved*/}
                {/*    ?*/}
                {/*        <>*/}
                {/*            {this.state.error === '' &&*/}
                {/*            <SavedSettings height={"119px"} error={false} message={"Saved"}/>*/}
                {/*            }*/}
                {/*            {this.state.error !== '' &&*/}
                {/*            <SavedSettings height={"119px"} error={true} message={this.state.error}/>*/}
                {/*            }*/}
                {/*        </>*/}
                {/*    :*/}
                {/*    <>*/}
                        {/*<form method={"post"} onSubmit={this.handleSubmit}>*/}
                            <ReadOnlyInput
                                title={"Hourly rate"}
                                value={"$" + this.state.rate}
                            />
                            {/*<TextInput*/}
                            {/*    title={"Maximum leads per month"}*/}
                            {/*    valid={this.state.maxClientsValid}*/}
                            {/*    warning={"Enter a valid number"}*/}
                            {/*    id={"maxClients"}*/}
                            {/*    onChange={this.handleChangeMaxClients}*/}
                            {/*    placeholder={"Number"}*/}
                            {/*    value={this.state.maxClients}*/}
                            {/*/>*/}
                            {/*<SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.maxClientsValid} isSaving={this.state.isSaving} />*/}
                        {/*</form>*/}
                        <br />
                        <br />
                        <br />
                    {/*</>*/}
                {/*}*/}
            {/*</SettingsCard>*/}
            </>
        )
    }
}

const mapStateToProps = state => ({
    settingsEnterpriseTerms: state.enterprise.settings.get("settingsEnterpriseTerms")
});
const mapDispatchToProps = dispatch => ({
    tryUpdateSettingsEnterpriseTerms: (data) => dispatch(tryUpdateSettingsEnterpriseTerms(data)),
    tryGetSettingsEnterpriseTerms: () => dispatch(tryGetSettingsEnterpriseTerms())
});
export default connect(mapStateToProps, mapDispatchToProps)(SettingsEnterpriseTerms);
