import { Map, List, fromJS } from 'immutable';

/* Immutable */

const CLIENTS_PROGRAM = 'CLIENTS_PROGRAM';
const CLIENTS_PROGRAM_DOWNLOAD_PENDING = 'CLIENTS_PROGRAM_DOWNLOAD_PENDING';
const CLIENTS_PROGRAM_DOWNLOAD_COMPLETE = 'CLIENTS_PROGRAM_DOWNLOAD_COMPLETE';
const CLIENTS_PROGRAM_PURCHASE_SENDING = 'CLIENTS_PROGRAM_PURCHASE_SENDING';
const CLIENTS_PROGRAM_PURCHASE_SENT = 'CLIENTS_PROGRAM_PURCHASE_SENT';
const CLIENTS_PROGRAM_PURCHASE_LINK = 'CLIENTS_PROGRAM_PURCHASE_LINK';
const CLIENTS_PROGRAM_PURCHASE_LINK_DOWNLOADING = 'CLIENTS_PROGRAM_PURCHASE_LINK_DOWNLOADING';
const CLIENTS_PROGRAM_STORE_RESET = 'CLIENTS_PROGRAM_STORE_RESET';

/* Actions */

export const setClientsProgram = (program) => ({'type': CLIENTS_PROGRAM, 'program': program});
export const setClientsProgramDownloadPending = () => ({'type': CLIENTS_PROGRAM_DOWNLOAD_PENDING});
export const setClientsProgramDownloadComplete = () => ({'type': CLIENTS_PROGRAM_DOWNLOAD_COMPLETE});
export const setClientsProgramPurchaseSent = (datetime) => ({'type': CLIENTS_PROGRAM_PURCHASE_SENT, 'datetime': datetime});
export const setClientsProgramPurchaseSending = (status) => ({'type': CLIENTS_PROGRAM_PURCHASE_SENDING, "status": status});
export const setClientsProgramPurchaseLink = (purchaseLink) => ({'type': CLIENTS_PROGRAM_PURCHASE_LINK, "purchaseLink": purchaseLink});
export const setClientsProgramPurchaseLinkDownloading = (status) => ({'type': CLIENTS_PROGRAM_PURCHASE_LINK_DOWNLOADING, "status": status});
export const setClientsProgramStoreReset = () => ({'type': CLIENTS_PROGRAM_STORE_RESET});

/* Initial State */

const initialState = Map({
    clientProgram: Map(),
    clientProgramDownloading: false,
    clientProgramPurchaseSending: false,
    clientProgramPurchaseSent: null,
    clientProgramPurchaseLink: Map({
        purchaseToken: null,
        purchaseUrl: null
    }),
    clientProgramPurchaseLinkDownloading: false
});

/* Reducer */

const clientsProgramReducer = (state=initialState, action) => {
    switch (action.type) {
        case CLIENTS_PROGRAM:
            return state.merge({"clientProgram": fromJS(action.program)});
        case CLIENTS_PROGRAM_DOWNLOAD_PENDING:
            return state.merge({'clientProgramDownloading': true});
        case CLIENTS_PROGRAM_DOWNLOAD_COMPLETE:
            return state.merge({'clientProgramDownloading': false});
        case CLIENTS_PROGRAM_PURCHASE_SENT:
            return state.merge({'clientProgramPurchaseSent': action.datetime});
        case CLIENTS_PROGRAM_PURCHASE_SENDING:
            return state.merge({'clientProgramPurchaseSending': action.status});
        case CLIENTS_PROGRAM_PURCHASE_LINK:
            return state.merge({'clientProgramPurchaseLink': fromJS(action.purchaseLink)});
        case CLIENTS_PROGRAM_PURCHASE_LINK_DOWNLOADING:
            return state.merge({'clientProgramPurchaseLinkDownloading': action.status});
        case CLIENTS_PROGRAM_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default clientsProgramReducer;
