import { Map, List, fromJS } from 'immutable';

/* Immutable */

const TEAM_MEMBERS = 'TEAM_MEMBERS';
const TEAM_MEMBERS_FETCHING = 'TEAM_MEMBERS_FETCHING';
const TEAM_MEMBERS_STORE_RESET = 'TEAM_MEMBERS_STORE_RESET';

/* Actions */

export const setTeamMembers = (members) => ({'type': TEAM_MEMBERS, 'members': members});
export const setTeamMembersFetching = (status) => ({'type': TEAM_MEMBERS_FETCHING, 'status': status});
export const setTeamMembersStoreReset = () => ({'type': TEAM_MEMBERS_STORE_RESET});

/* Initial State */

const initialState = Map({
    members: List(),
    membersFetching: false,
});

/* Reducer */

const teamReducer = (state=initialState, action) => {
    switch (action.type) {
        case TEAM_MEMBERS:
            return state.merge({'members': fromJS(action.members)});
        case TEAM_MEMBERS_FETCHING:
            return state.merge({'membersFetching': action.status});
        case TEAM_MEMBERS_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default teamReducer;
