import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import styled from 'styled-components';

/* Components */
import {colors} from "../../../common/components/colors";
import {images} from "../../../common/components/images";
import {ButtonPrimary} from "../../../common/components/buttons";

/* Middleware */
import {tryPostFeedback} from '../../feedback/middleware/feedback';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const TextArea = styled.textarea`
    background-color: ${colors.border30};
    margin: 0px auto;
    border-radius: 20px;
    padding: 15px 20px;
    -webkit-box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15);
    resize: none;
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    font-size: 16px;
    line-height: 22px;
    min-height: 230px;
    border: none;
    width: calc(100% - 40px);
    @media screen and (max-width: 460px) {
        
    }
    &:focus {
        outline: 0;
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-around;
    max-width: 450px;
    margin: 0px auto;
    position: relative;
    z-index: 2;
    width: 100%;
`;
const SaveSection = styled.div`
    padding: 20px 10px 0px 10px;
    & > div > div {
        min-width: 84px;
        margin-left: auto;
        text-align: center;
    }
`;
const Background = styled.div`
    position: absolute;
    left: 320px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    z-index: -1;
    width: calc(100% - 320px);
    height: 100%;
    background-position: center top;
    background-image: url(${images.feedbackBackgroundDesktopInverted});
    //background-image: url(${images.feedbackBackgroundDesktop});
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (max-width: 1088px) {
        left: 0px;
        width: 100%;
        height: 100vh;
    }
    @media screen and (max-width: 748px) {
        background-image: url(${images.feedbackBackgroundMobileInverted});
        //background-image: url(${images.feedbackBackgroundMobile});
        left: 0px;
        width: 100%;
        height: 100vh;
    }
`;

class Feedback extends React.Component {
    static defaultProps = {
        showBackButton: true,
        back: () => {},
        inputTitle: "",
        inputPlaceholder: "",
        inputButtonLabel: "",
        successTitle: "",
        successSubtitle: "",
        successButtonLabel: ""
    };

    state = {"submitted": false, "message": ''};

    handleChange = e => this.setState({"message": e.target.value});

    handleSubmit = async () => {
        if(this.state.message !== '') {
            await this.props.tryPostFeedback(this.state.message);
            this.props.history.push("/support/feedback/message-sent");
        }
    };

    render() {
        return(
            <>
                <Background />
                <Container>
                    <TextArea
                        value={this.state.message}
                        onChange={this.handleChange}
                        placeholder={this.props.inputPlaceholder}
                    />
                    <SaveSection>
                        <div onClick={this.handleSubmit}>
                            <ButtonPrimary label={this.props.inputButtonLabel} canSubmit={false} />
                        </div>
                    </SaveSection>
                </Container>
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    tryPostFeedback: (message) => dispatch(tryPostFeedback(message))
});

export default connect(null, mapDispatchToProps)(withRouter(Feedback));
