import React from "react";

import styled from "styled-components";
import {colors} from "../../../common/components/colors";
import {FontBody18, fontFamily} from "../../../common/components/fonts";

export const MemberProfileCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    ${props => props.viewInBar !== true} {
        background-color: ${colors.white};
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        width: 500px;
        z-index: 11;
        @media screen and (max-width: 748px) {
            left: 0;
            width: auto;
        }
    }
    ${props => props.viewInBar !== false} {
        height: calc(100vh - 246px);
        @media screen and (max-width: 748px) {
            height: calc(100vh - 146px);
        }
    }
`;

export const Background = styled.div`
    ${props => props.viewInBar !== true} {
        background-color: rgba(0, 0, 0, 0.5);
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 10;
    }
    ${props => props.viewInBar !== false} {
        display: none;
    }
`;
export const Point = styled.div`
    width: 0;
    height: 0;
    position: absolute;
    z-index: 1;
    ${props => props.isFromMe !== true} {
        border-left: 15px solid transparent;
        border-right: 10px solid transparent;
        border-top: 50px solid ${colors.backgroundColor1};
        bottom: -13px;
        right: 6px;
        transform: rotate(-60deg);
    }
    ${props => props.isFromMe !== false} {
        border-right: 15px solid transparent;
        border-left: 10px solid transparent;
        border-top: 50px solid ${colors.backgroundChatBubble};
        bottom: -13px;
        left: 6px;
        transform: rotate(60deg);
    }
`;
export const ComposeArea = styled.div`
    padding: 40px 0px 20px 0px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 16;
    display: flex;
    justify-content: flex-end;
`;
export const BackSection = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 30px;
    padding: 25px 0px 0px 25px;
    width: calc(100% - 25px);
    @media screen and (max-width: 460px) {
        padding: 15px 0px 0px 15px;
        width: calc(100% - 15px);
        margin-bottom: 15px;
    }
`;

export const Back = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
`;

export const HistorySection = styled.div`
    flex: 1 1 auto;
    flex-grow: 1;
    padding: 0px 25px;
    flex-direction: column;
    display: flex;
    gap: 14px;
    overflow-y: auto;
    background-color: ${colors.white};
    justify-content: center;
    @media screen and (max-width: 748px) {
        ${props => props.viewInBar !== false} {
            padding: 0 !important;
        }
    }
`;
export const BodyEmpty = styled.div`
    text-align: center;
    margin-top: 40px;
    color: ${colors.primary30};
`;

export const ToSection = styled.div`
    ${props => props.viewInBar !== true} {    
        margin: 0 0 30px 10px;
    }
    ${props => props.viewInBar !== false} {
        margin: 0 0 30px 0;
    }
    color: ${colors.secondary70};
    padding-left: 25px;
    width: calc(100% - 25px);
    @media screen and (max-width: 748px) {
      ${props => props.viewInBar !== false} {
        margin: 0 0 15px 0;
        padding-left: 0;
        width: 100%;
      }
    }
    @media screen and (max-width: 460px) {
        ${props => props.viewInBar !== true} {
            margin: 0 0 15px 10px;
            padding-left: 15px;
            width: calc(100% - 15px);
        }
        ${props => props.viewInBar !== false} {
            margin: 0 0 15px 0;
            padding-left: 0;
            width: 100%;
        }
    }
    display: flex;
    justify-content: flex-start;
`;
export const InlineBlock = styled.div`
    display: inline-block;
`;

export const ConfirmBtnFitLeft = styled.div`
    width: fit-content;
`;


export const MessageContainer = styled.div`
    max-width: 250px;
    @media screen and (max-width: 460px) {
        max-width: 200px;
    }
    position: relative;
    padding: 20px;
    border-radius: 25px;
    min-width: 10px;
    ${props => props.isFromMe !== true} {
        background-color: ${colors.backgroundColor1};
        color: ${colors.primary100};
        align-items: flex-end;
    }
    ${props => props.isFromMe !== false} {
        background-color: ${colors.backgroundChatBubble};
        color: ${colors.primary100};
        align-items: flex-start;
    }
    & a {
        text-decoration: underline;
        color: ${colors.primary100};
    }
`;
export const MessageFrom = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px 0px;
    ${props => props.isFirst !== true} {
        margin-top: auto;
    }
    ${props => props.isFromMe !== true} {
        align-items: flex-end;
    }
    ${props => props.isFromMe !== false} {
        align-items: flex-start;
    }
`;
export const Time = styled.div`
    margin-top: 4px;
    ${props => props.isFromMe !== true} {
        text-align: right;
        margin-right: 20px;
    }
    ${props => props.isFromMe !== false} {
        text-align: left;
        margin-left: 20px;
    }
    ${props => props.show !== true} {
        color: ${colors.secondary100};
    }
    ${props => props.show !== false} {
        color: ${colors.white};
    }
`;

export const TextArea = styled.textarea`
    background-color: ${colors.white};
    display: inline-block;
    resize: none;
    padding: 0px;
    font-family: ${fontFamily.body};
    font-size: 18px;
    line-height: 21px;
    margin-left: 10px;
    width: 100%;
    max-height: 126px;
    vertical-align: top;
    border: none;
    &::placeholder {
        color: ${colors.backgroundColor6};
    }
    &:focus {
        outline: 0;
    }
`;
export const TextAreaContainer = styled.div`
    padding: 13px 0px 12px 15px;
    flex-grow: 1;
    @media screen and (max-width: 460px) {
        padding: 12px 0px 13px 0px;
    }
`;
export const BoxShadow = styled.div`
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
    padding: 5px 7px;
    border-radius: 25px;
    width: calc(100% - 64px);
    margin: 0px auto;
    display: flex;
    gap: 20px;
    flex-direction: row;
    @media screen and (max-width: 748px) {
      ${props => props.viewInBar !== false} {
          width: calc(100% - 14px);
      }
    }
`;
export const SendBtn = styled.div`
    display: inline-block;
    width: 91px;
    vertical-align: top;
    & div {
        font-size: 18px;
    }
`;
export const MessageBody16 = styled(FontBody18)`
    overflow-wrap: break-word;
    position: relative;
    z-index: 2;
`;