import { axiosReq } from "../../../common/utils/axios";

// Store
import {
    setGroupProgramDetails, setGroupProgramsUpcoming, setGroupProgramsUpcomingFetching, setGroupProgramRegistrationFetching,
    setGroupProgramDetailsFetching, setGroupProgramRegistrationInProgress, setExistingRegistrationDetails,
} from '../store/programs'

// Static
import { devActiveGroupPrograms, devGroupProgramDetails, devRegistrationDetails } from "../static/devdata";

export const tryGetUpcomingGroupProgramsWMC = (wmcId) => {
    return async dispatch => {
        dispatch(setGroupProgramsUpcomingFetching(true));
        const groupPrograms = await axiosReq('apiv1/consumer/group-program/wmc/'+wmcId, "GET", {}, devActiveGroupPrograms);
        await dispatch(setGroupProgramsUpcoming(groupPrograms));
        await dispatch(setGroupProgramsUpcomingFetching(false));
        return groupPrograms
    }
};

export const tryGetUpcomingGroupPrograms = () => {
    return async dispatch => {
        dispatch(setGroupProgramsUpcomingFetching(true));
        const groupPrograms = await axiosReq('apiv1/consumer/group-program/get-active', "GET", {}, devActiveGroupPrograms);
        await dispatch(setGroupProgramsUpcoming(groupPrograms));
        await dispatch(setGroupProgramsUpcomingFetching(false));
        return groupPrograms
    }
};

export const tryGetProgramDetails = (groupProgramId) => {
    return async dispatch => {
        dispatch(setGroupProgramDetailsFetching(true));
        const groupProgramDetails = await axiosReq('apiv1/consumer/group-program/get-active/' + groupProgramId, "GET", {}, devGroupProgramDetails);
        await dispatch(setGroupProgramDetails(groupProgramDetails));
        return dispatch(setGroupProgramDetailsFetching(false));
    }
};

export const tryGetProgramRegistrationInfo = (groupProgramId) => {
    return async dispatch => {
        dispatch(setGroupProgramRegistrationFetching(true));
        const existingRegistration = await axiosReq('apiv1/consumer/group-program/subscription-exists/' + groupProgramId, "GET", {}, {}); // {devRegistrationDetails});
        await dispatch(setExistingRegistrationDetails(existingRegistration));
        return dispatch(setGroupProgramRegistrationFetching(false));
    }
};

export const tryRegisterUserForProgram = (groupProgramId, hrCode) => {
    return async dispatch => {
        dispatch(setGroupProgramRegistrationInProgress(true));
        let body;
        if (hrCode !== null && hrCode !== "") {
            body = {"groupProgramId": groupProgramId, "hrCode": hrCode}
        } else {
            body = {"groupProgramId": groupProgramId}
        }
        const registration = await axiosReq('apiv1/consumer/group-program/subscribe', "POST", body, devRegistrationDetails);
        await dispatch(setGroupProgramRegistrationInProgress(false));
        return registration;
    }
};