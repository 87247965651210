import React from "react";
import {connect} from 'react-redux';
import moment from "moment-timezone";
import styled from "styled-components";
import {withRouter} from 'react-router-dom';

/* Components */
import {FontBody16, FontSubtitle21, FontSubtitle24} from "../../../common/components/fonts";
import {FlexRow, FlexColRev, ExpandClick, Pointer16, MyCareSection, FlexRowJCB, FlexRowJCSA, IconButton, FitContent, FooterButton, DownloadsSection, OfflineSection} from '../components/member';
import {LogoLoading} from "../../../common/components/loading";
import {colors} from "../../../common/components/colors";
import {ButtonSecondary} from "../../../common/components/buttons";
import {images} from "../../../common/components/images";

/* Containers */
import { MemberOtherProducts } from "./otherproducts";
// import MeetingRescheduler from "../../scheduling/containers/rescheduler";
import SchedulingRescheduleCancel from "../../../iframes/reschedulermodal/containers/reschedulecancel";

/* Middleware */
import {tryGetTeamMemberMeetings} from '../../appointments/middleware/meetings';
import {setTeamMemberMeetingsChanged} from "../../appointments/store/meetings";

class MyCare extends React.Component {
    static defaultProps = { "member": null, "products": [], 'parentId': '', "showShop": () => {}, "onReschedule": () => {}};
    state = { 
        "expanded": false,
        "isLoading": true,
        "upcomingMeetings": [],
        "upcomingMeetingsSmall": [],
        "selectedMeeting": {}, 
        "showRescheduler": false, 
        "remainingMeetings": [],
        "completedMeetings": [],
        "downloads": [],
        "offlines": [], 
        "showDownloads": false,
        "showOfflines": false,
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.meetingsChanged !== this.props.meetingsChanged) {
            console.log('membermycare component did update meetingschanged');
            return this.init();
        }
    }

    init = async () => {
        await this.props.tryGetTeamMemberMeetings();
        console.log("this.props.meetings", this.props.meetings)
        const upcomingMeetings = await this.filter(this.props.meetings);
        console.log("upcoming meetings", upcomingMeetings)
        const downloads = await this.props.products.filter(p => p.serviceType === "download");
        console.log('down', downloads)
        const offlines = await this.props.products.filter(p => p.serviceType === "offline");
        console.log('off', offlines)
        const meetingData = await this.setUpMeetingData();
        await this.setState({
            "upcomingMeetings": upcomingMeetings,
            "upcomingMeetingsSmall": upcomingMeetings.slice(0, Math.min(upcomingMeetings.length, 3)),
            "remainingMeetings": meetingData.remainingMeetings,
            "completedMeetings": meetingData.completedMeetings,
            "downloads": downloads,
            "offlines": offlines,
            "isLoading": false,
        });
    };

    setUpMeetingData = async () => {
        const productsArr = this.props.products;
        console.log('productsArr', productsArr)
        // Mapping of session length to number of remaining sessions
        const remainingMap = this.sessionLengthToNumMeetings(productsArr);
        console.log("remainingMap", remainingMap)
        // Mapping of session length to number of completed sessions
        const completedMap = this.sessionLengthToNumMeetings(productsArr, 'sessionsCompleted');
        let remainingMeetings = [];
        let completedMeetings = [];
        remainingMap.forEach((value, key) => {
            const meetingString = `${value} appointment${value === 1 ? '' : 's'} @ ${key}-minutes`;
            remainingMeetings.push(meetingString);
        });
        console.log("remainingMeetings", remainingMeetings)
        completedMap.forEach((value, key) => {
            const meetingString = `${value} appointment${value === 1 ? '' : 's'} @ ${key}-minutes`;
            completedMeetings.push(meetingString);
        });
        console.log('REMAIN:', remainingMeetings)
        console.log('COMPLETED', completedMeetings)
        return ({
            'remainingMeetings': remainingMeetings,
            'completedMeetings': completedMeetings,
        });
    };

    // Creates a mapping of session length to number of remaining or upcoming sessions from the given array of products
    sessionLengthToNumMeetings = (productsArr, prop = 'sessionsSchedulable') => {
        let mapping = new Map();
        for (const p of productsArr) {
            if (!p[prop]) {
                continue;
            }
            const duration = p.sessionLengthInMinutes;
            // console.log('duration:', duration)
            const existingKey = mapping.get(duration)
            // console.log('existingKey:', existingKey)
            // console.log('p[prop]', p[prop])
            mapping.set(duration, p[prop] + (existingKey ? existingKey : 0))
            // console.log('mapping.get(duration)', mapping.get(duration))
        }
        return mapping;
    };

    filter = (data) => {
        return data.reduce((filtered, c) => {
            if(this.props.member.id === c.get("remoteUserId") && moment(c.get("start")).isAfter(moment())) {
                let end = (c.get("expected_end") === undefined || c.get("expected_end") === null) ? c.get("end") : c.get("expected_end");
                let d = c.get('start');
                filtered.push({
                    ...c.toJS(),
                    "length": moment.duration(moment(end).diff(moment(c.get("start")))).asMinutes().toString(),
                    "date": moment(d).format("ll").toString() + ", " + moment(d).format("h:mma").toString() + " - " + moment(end).format("h:mma").toString()
                })
            }
            return filtered;
        }, []);
    };

    hideRescheduler = async () => this.setState({"showRescheduler": false, "selectedMeeting": {}});
    reschedule = async (data) => {
        await this.setState({"selectedMeeting": data, "showRescheduler": true});
        console.log("within reschedule", this.state)
    };

    toggleExpanded = () => this.setState({"expanded": !this.state.expanded});

    toggleShowDownloads = (product) => this.setState({
        'showDownloads': !this.state.showDownloads
    });

    toggleShowOfflines = (product) => this.setState({
        'showOfflines': !this.state.showOfflines
    });

    executeCancel = async () => {
        console.log('resched confirmOnCancelled')
        await this.props.onCancel();
        return this.init();
    }

    executeReschedule = async () => {
        console.log('resched confirmOnScheduled')
        await this.props.onReschedule();
        await this.init();
        this.props.history.push("/my-team/"+this.state.selectedMeeting.remoteUserId+"/appointment")
    }

    render() {
        if (this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            console.log('remainingMeetings:', this.state.remainingMeetings)
            return (
                <>
                    <FlexColRev>
                        {this.state.offlines.length > 0 && <OfflineSection backgroundColor={colors.backgroundColor3}>
                            <FontSubtitle21>{'Other services'}</FontSubtitle21>
                            {this.state.offlines.map((prod, idx) => (
                                <div key={idx+"offline"}>
                                    <FlexRow>
                                        <Pointer16 onClick={() => this.toggleShowOfflines(prod)}>{prod.productTitle}</Pointer16>
                                        {this.state.showOfflines && <Pointer16 onClick={() => this.toggleShowOfflines(prod)}>X</Pointer16>}
                                    </FlexRow>
                                    {this.state.showOfflines &&
                                        <MemberOtherProducts
                                            onGoBack={() => this.toggleShowOfflines()}
                                            coach={this.props.member}
                                            sectionTitle={'Other services'}
                                            product={prod}
                                            parentId={this.props.parentId}
                                        />
                                    }
                                </div>
                            ))}
                        </OfflineSection>}
                        {this.state.downloads.length > 0 && <DownloadsSection backgroundColor={colors.primary100}>
                            <FontSubtitle21>{'Downloads'}</FontSubtitle21>
                            {this.state.downloads.map((prod, idx) => (
                                <div key={idx+"download"}>
                                    <FlexRow>
                                        <Pointer16 onClick={() => {this.toggleShowDownloads(prod);}}>{prod.productTitle}</Pointer16>
                                        {this.state.showDownloads && <Pointer16 onClick={() => {this.toggleShowDownloads(prod);}}>X</Pointer16>}
                                    </FlexRow>
                                    {this.state.showDownloads &&
                                        <MemberOtherProducts
                                            onGoBack={() => this.toggleShowDownloads()}
                                            coach={this.props.member}
                                            sectionTitle={'Downloads'}
                                            product={prod}
                                            parentId={this.props.parentId}
                                        />
                                    }
                                </div>
                            ))}
                        </DownloadsSection>}
                        <MyCareSection backgroundColor={colors.backgroundColor1}>
                            <FontSubtitle24 spaced={false}>{'Appointments'}</FontSubtitle24>
                            <div>
                                <FontSubtitle21>{'Upcoming'}</FontSubtitle21>
                                {this.state.upcomingMeetings.length > 0 ? <div>
                                    {(this.state.expanded ? this.state.upcomingMeetings : this.state.upcomingMeetingsSmall).map((m, idx) => {
                                        return <FlexRowJCB key={idx}>
                                            <FontBody16>{m.date}</FontBody16>
                                            <IconButton image={images.rescheduleDark} onClick={() => this.reschedule(m)}/>
                                        </FlexRowJCB>
                                    })}
                                </div> : <FontBody16>{'No upcoming appointments'}</FontBody16>}
                                {this.state.upcomingMeetings.length > 3 && <ExpandClick onClick={() => this.toggleExpanded()}>{this.state.expanded ? 'Show less' : 'Show all'}</ExpandClick>}
                            </div>
                            <div>
                                <FontSubtitle21>{'Remaining'}</FontSubtitle21>
                                {this.state.remainingMeetings.length > 0 ? <div>
                                    {this.state.remainingMeetings.map((m, idx) => (
                                        <FontBody16 key={idx}>{m}</FontBody16>
                                    ))}
                                </div> : <FontBody16>{'No remaining appointments'}</FontBody16>}
                            </div>
                            <div>
                                <FontSubtitle21>{'Completed'}</FontSubtitle21>
                                {this.state.completedMeetings.length > 0 ? <div>
                                    {this.state.completedMeetings.map((m, idx) => (
                                        <FontBody16 key={idx}>{m}</FontBody16>
                                    ))}
                                </div> : <FontBody16>{'No completed appointments'}</FontBody16>}
                            </div>
                            <FitContent onClick={this.props.showShop}>
                                <ButtonSecondary canSubmit={false} label={"Purchase Appointments"} />
                            </FitContent>
                        </MyCareSection>
                    </FlexColRev>
                    <SchedulingRescheduleCancel
                        hideModal={this.hideRescheduler}
                        onExecuteCancel={this.executeCancel}
                        onExecuteReschedule={this.executeReschedule}
                        sessionSelected={this.state.selectedMeeting}
                        showModal={this.state.showRescheduler}
                    />
                    {/*{this.state.showRescheduler &&*/}
                    {/*    <MeetingRescheduler*/}
                    {/*        close={this.hideRescheduler}*/}
                    {/*        meeting={this.state.selectedMeeting}*/}
                    {/*    />*/}
                    {/*}*/}
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    meetings: state.consumer.appointments.meetings.get("meetings"),
    meetingsChanged: state.consumer.appointments.meetings.get("meetingsChanged"),
});

const mapDispatchToProps = dispatch => ({
    tryGetTeamMemberMeetings: () => dispatch(tryGetTeamMemberMeetings()),
    setTeamMemberMeetingsChanged: (datetime) => dispatch(setTeamMemberMeetingsChanged(datetime)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyCare));
