import { Map, fromJS } from 'immutable';

/* Immutable */

const SETTINGS_PERSONAL_INFO = 'SETTINGS_PERSONAL_INFO';
const SETTINGS_PERSONAL_INFO_READ = 'SETTINGS_PERSONAL_INFO_READ';
const SETTINGS_PERSONAL_INFO_UPDATE = 'SETTINGS_PERSONAL_INFO_UPDATE';
const SETTINGS_STORE_RESET = 'SETTINGS_STORE_RESET';

/* Actions */

export const setSettingsPersonalInfo = (personalInfo) => ({'type': SETTINGS_PERSONAL_INFO, 'personalInfo': personalInfo});
export const setSettingsPersonalInfoReading = (status) => ({'type': SETTINGS_PERSONAL_INFO_READ, 'status': status});
export const setSettingsPersonalInfoUpdating = (status) => ({'type': SETTINGS_PERSONAL_INFO_UPDATE, 'status': status});
export const setSettingsStoreReset = () => ({'type': SETTINGS_STORE_RESET});

/* Initial State */

const initialState = Map({
    settingsPersonalInfo: Map({first: '', last: '', phone: '', email: '', timeZone: '', error: ''}),
    settingsPersonalInfoReading: false,
    settingsPersonalInfoUpdating: false
});

/* Reducer */

const settingsReducer = (state=initialState, action) => {
    switch (action.type) {
        case SETTINGS_PERSONAL_INFO:
            return state.merge({'settingsPersonalInfo': fromJS(action.personalInfo)});
        case SETTINGS_PERSONAL_INFO_READ:
            return state.merge({'settingsPersonalInfoReading': action.status});
        case SETTINGS_PERSONAL_INFO_UPDATE:
            return state.merge({'settingsPersonalInfoUpdating': action.status});
        case SETTINGS_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default settingsReducer;
