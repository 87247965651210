import React from 'react';

/* Components */
import {Page} from '../components/structure';

/* Containers */
import IframeMeetingPageHeader from "./header";
import IframeMeetingPagRegister from "./register";
import IframeMeetingPagBody from "./body";
import IframeMeetingPagPoweredBy from "./poweredby";

class IframeMeetingPageStructure extends React.Component {
    static defaultProps = {
        canRegister: true,
        coachView: false
    };

    render() {
        return(
            <Page>
                <IframeMeetingPageHeader
                      data={this.props.data}
                      handle={this.props.handle}
                />
                {!this.props.coachView && 
                <IframeMeetingPagRegister
                    data={this.props.data}
                    isPast={this.props.isPast}
                    isCanceled={this.props.isCanceled}
                    register={() => {return this.props.register()}}
                    canRegister={this.props.canRegister}
                    registered={this.props.registered}
                    error={this.props.error}
                    joinUrl={this.props.joinUrl}
                    isOngoing={this.props.isOngoing}
                />
                }
                <IframeMeetingPagBody
                    data={this.props.data}
                />
                <IframeMeetingPagPoweredBy />
            </Page>
        )
    }
}

export default IframeMeetingPageStructure;