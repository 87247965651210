import React from 'react';
import styled from "styled-components";

/* Components */
import {RadioBoxWithLabel} from "../../../common/components/inputs";
import {LogoLoading} from "../../../common/components/loading";

/* Containers */
import MeetingsCreateVideo from './meetingscreatevideo';
import MeetingsCreatePhoto from './meetingscreatephoto';
import validator from "validator";

export const InlineCheckbox = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 33%;
    min-width: 225px;
    margin-top: 15px;
    @media screen and (max-width: 640px) {
        width: 100%;
        min-width: 100%;
        display: block;
    }
`;

export const Checkboxes = styled.div`
    margin-top: 20px;
    margin-bottom: -30px;
    @media screen and (max-width: 640px) {
        margin-bottom: 0px;
    }
`;

class MeetingsCreateHero extends React.Component {
    state = {
        activeChecked: true,
        deactiveChecked: false,
        image: {
            imgURL: "",
            imgURLValid: false,
            imgPreview: false,
        },
        video: {
            videoURL: '',
            videoURLValid: false,
            videoPreview: false
        },
        isLoadingHero: true
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.setState({"activeChecked": this.props.hero.activeChecked, "deactiveChecked": this.props.hero.deactiveChecked});
        await this.storeImageAndVideo();
        await this.setState({"isLoadingHero": false});
    };

    handleActivate = async () => {
        await this.setState({"activeChecked": true, "deactiveChecked": false});
        await this.storeImageAndVideo();
        await this.props.onValidate(this.state);
    };

    handleDeactivate = async () => {
        await this.setState({"activeChecked": false, "deactiveChecked": true});
        await this.storeImageAndVideo();
        await this.props.onValidate(this.state);
    };

    storeImageAndVideo = async () => {
        await this.setState({
            "image": {
                "imgURL": this.props.hero.image.imgURL === null ? "" : this.props.hero.image.imgURL,
                'imgURLValid': validator.isURL(this.props.hero.image.imgURL), //(validator.isURL(this.props.hero.image.imgURL) || this.props.hero.image.imgURL === ""),
                "imgPreview": false
            },
            "video": {
                "videoURL": this.props.hero.video.videoURL === null ? "" : this.props.hero.video.videoURL,
                'videoURLValid': (validator.isURL(this.props.hero.video.videoURL) || this.props.hero.video.videoURL === ""),
                "videoPreview": false
            },
        });
    };

    handleChangeImage = async (state) => {
        await this.setState({"image": state});
        await this.props.onValidate(this.state);
    };

    handleChangeVideo = async (state) => {
        await this.setState({"video": state});
        await this.props.onValidate(this.state);
    };

    render() {
        if(this.state.isLoadingHero) {
            return (<LogoLoading />)
        } else {
            return(
                <>
                    {/*<Checkboxes>*/}
                    {/*    <InlineCheckbox>*/}
                    {/*        <RadioBoxWithLabel*/}
                    {/*            id={7}*/}
                    {/*            action={this.handleActivate}*/}
                    {/*            checked={this.state.activeChecked}*/}
                    {/*            label={"Video"}*/}
                    {/*            subtext={null}*/}
                    {/*            disabled={false}*/}
                    {/*        />*/}
                    {/*    </InlineCheckbox>*/}
                    {/*    <InlineCheckbox>*/}
                    {/*        <RadioBoxWithLabel*/}
                    {/*            id={6}*/}
                    {/*            action={this.handleDeactivate}*/}
                    {/*            checked={this.state.deactiveChecked}*/}
                    {/*            label={"Image"}*/}
                    {/*            subtext={null}*/}
                    {/*            disabled={false}*/}
                    {/*        />*/}
                    {/*    </InlineCheckbox>*/}
                    {/*</Checkboxes>*/}
                    {/*{this.state.activeChecked*/}
                    {/*    ? <MeetingsCreateVideo*/}
                    {/*        video={this.state.video}*/}
                    {/*        onValidate={this.handleChangeVideo}*/}
                    {/*        title={null}*/}
                    {/*    />*/}
                    {/*    : <MeetingsCreatePhoto*/}
                    {/*        image={this.state.image}*/}
                    {/*        onValidate={this.handleChangeImage}*/}
                    {/*        title={null}*/}
                    {/*    />*/}
                    {/*}*/}
                    <MeetingsCreatePhoto
                        image={this.state.image}
                        onValidate={this.handleChangeImage}
                        title={"Image*"}
                    />
                    <MeetingsCreateVideo
                        video={this.state.video}
                        onValidate={this.handleChangeVideo}
                        title={"Video"}
                    />
                    <br />
                </>
            )
        }
    }
}

export default MeetingsCreateHero;
