import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import styled from "styled-components";

/* Middleware */
import { tryGetAllClients, tryArchiveClient } from "../middleware/clients";
import { tryArchiveContact } from "../../middleware/contacts";

/* Store */
import { setClientsDetailSelected } from "../../store/clientsdetail";

/* Components */
import { colors } from "../../../common/components/colors";
import { FontBody16, FontTitle24 } from "../../../common/components/fonts";
import { Message } from "../../../common/components/messages";
import { TableWrapperV2 } from "../../../common/components/tablev2";
import { LogoLoading } from '../../../common/components/loading';
import {EditOutlined, MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import FilterDropdown from "../../../common/components/filterdropdown";

const Modal = styled.div`
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    padding: 30px;
    border-radius: 4px;
    background-color: ${colors.white};
    -webkit-box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    max-width: 300px;
    width: calc(100% - 80px);
    z-index: 20;
`;
const Title = styled.div`
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.border100};
    margin-bottom: 15px;
`;
const Margin = styled.div`
    margin-bottom: 50px;
`;

const FilterSection = styled.div`
    margin: 20px 0px;
`;

const OneFilter = styled.div`
    margin: 5px 20px 5px 0px;
    display: inline-block;
`;

const uniqueInArrayById = (arr) => {
    return arr.reduce((filtered, c) => {
        const x = filtered.find(item => item.id === c.id);
        if (!x) {
            filtered.push(c);
        }
        return filtered;
    }, []);
};

class ClientsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "isLoading": true, 
            "data": [], 
            "showNotice": false,
            "filters": {
                "Status": {
                    "options": [{"id": 1, "name": "Current"}, {"id": 2, "name": "Lost"}],
                    "selected": [1]
                },
            }
        };
    }

    componentDidMount() {
        if(!this.props.clientsAllFetched) {
            return this.refresh();
        } else {
            return this.update();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.clientsCreating !== this.props.clientsCreating) {
            return this.refresh()
        } else if(prevState.filters !== this.state.filters || prevProps.clientsAll !== this.props.clientsAll) {
            return this.update();
        }
    }

    componentWillUnmount() {
        console.log("unmounted")
        clearInterval(this.timeout)
    }

    refresh = async () => {
        await this.props.tryGetAllClients();
        return this.update();
    };

    update = async () => {
        await this.setState({
            "data": await this.createData(this.filter(this.props.clientsAll)),
            "isLoading": false
        });
        console.log(this.state.data)
    };

    updateFilter = async (filterTitle, filterSelected) => {
        await this.setState({
            "filters": {
                ...this.state.filters,
                [filterTitle]: {
                    ...this.state.filters[filterTitle],
                    "selected": filterSelected
                }
            }
        });
    };

    eachFilter = (filterTitle) => {
        return this.state.filters[filterTitle].options.reduce((f, o) => {
            if(this.state.filters[filterTitle].selected.includes(o.id)) {
                if(o.name === "Current") {f.push(true)}
                if(o.name === "Lost") {f.push(false)}
            }
            return(f);
        }, []);
    };

    filter = (data) => {
        // Check filters
        let filterStatus = this.eachFilter("Status");
        let clientsFiltered = data.reduce((filtered, c) => {  
            console.log(c)          
            let advisorIsWMC = c.get("advisor") === null ? null : c.get("advisor").get("firstName") + " " + c.get("advisor").get("lastName");
            let advisorNotWMC = c.get("advisor") === null ? null : c.get("advisor").get("advisorId") === this.props.userId ? null : c.get("advisor").get("firstName") + " " + c.get("advisor").get("lastName");
            let isCoachHasAdvisor = (!this.props.isWMC && advisorNotWMC !== null);
            let isCoachNoAdvisor = (!this.props.isWMC && advisorNotWMC === null);
            let hasCoach = c.get("coaches") === undefined ? false : (c.get("coaches").size === 0 ? false : (c.get("coaches").filter(coach => {return(coach.get("wmcId") === null)}).size > 0));
            let isAdvisorHasCoach = (this.props.isWMC && hasCoach);
            let isAdvisorNoCoach = (this.props.isWMC && !hasCoach);
            let coachName = c.get("coaches") === undefined ? null : (c.get("coaches").size === 0 ? null : (c.get("coaches").size === 1 ? c.get("coaches").get(0).get("fullName") : "Multiple"));
            let users = c.get("advisor") === null ? [] : (c.get("advisor").get("advisorId") === this.props.userId ? [] : [{...c.get("advisor").toJS(), "id": c.get("advisor").get("advisorId")}]);
            if(c.get("coaches") !== null && c.get("coaches").size > 0) {
                let coachTeam = c.get("coaches").reduce((filt, d) => {
                    if(d.get("coachId") !== this.props.userId) {
                        filt.push({...d.toJS(), "id": d.get("coachId")})
                    }
                    return filt;
                }, []);
                users.push(...coachTeam);
            }
            let isActive = c.get("isActive");
            if ((filterStatus.includes(!c.get("isArchived")) || filterStatus.length === 0) && isActive) {
                filtered.push({
                    ...c.toJS(),
                    "name": ((c.get("referralSource") === "willow" && !this.props.isWMC) || (c.get("referralSource") === "wmc" && !this.props.isWMC)) ? c.get("firstName") + " " + c.get("lastName").charAt(0) + "." : c.get("firstName") + " " + c.get("lastName"),
                    // "advisorName": !this.props.isWMC ? advisorNotWMC : advisorIsWMC,
                    // "coachName": !this.props.isWMC ? this.props.userName : coachName,
                    "users": uniqueInArrayById(users),
                    "lastSession": c.get("lastSession") === null ? null : c.get("lastSession").get("start"),
                    "nextSession": c.get("nextSession") === null ? null : c.get("nextSession").get("start"),
                    "editAllowed": this.props.isWMC ? true : (c.get("referralSource") !== "willow" && (c.get("advisor") === null ? true : c.get("advisor").get("advisorId") === this.props.userId)),
                    "archiveAllowed": this.props.isWMC ? true : (c.get("advisor") === null ? true : (c.get("advisor").get("advisorId") === this.props.userId)),
                    "isCoachHasAdvisor": isCoachHasAdvisor,
                    "isCoachNoAdvisor": isCoachNoAdvisor,
                    "isAdvisorHasCoach": isAdvisorHasCoach,
                    "isAdvisorNoCoach": isAdvisorNoCoach,
                    "isAdvisorOrInvestor": this.props.isWMC  ? "Advisor" : "Investor"
                })
            }
            return filtered;
        }, []);

        return clientsFiltered;
    };

    edit = async (data) => {
        await this.selectClient(data);
        this.props.history.push("/contact/edit");
    };

    view = async (data) => {
        await this.selectClient(data);
        this.props.history.push(/*data.isCoachNoAdvisor ? "/contact/notes" : */"/contact/instructions");
    };

    selectClient = async (data) => {
        this.props.setClientsDetailSelected({
            "contactId": data.contactId,
            "prospectId": data.prospectId,
            "consumerId": data.consumerId,
            "businessUserId": data.businessUserId === undefined ? null : data.businessUserId,
            "firstName": data.firstName,
            "lastName": data.lastName,
            "prospectStatus": data.prospectStageName === "client" ? "Client" : "Prospect", //"Client",
            "revenue": data.revenue,
            "probabilityOfClosing": data.probabilityOfClosing,
            "companyName": data.companyName,
            "isCoachHasAdvisor": data.isCoachHasAdvisor,
            "isCoachNoAdvisor": data.isCoachNoAdvisor,
            "isAdvisorHasCoach": data.isAdvisorHasCoach,
            "isAdvisorNoCoach": data.isAdvisorNoCoach,
            "referralSource": data.referralSource
        })
    };

    archive = (data) => {
        if(this.props.isWMC) {
            if(data.consumerId !== null) {
                this.props.tryArchiveClient({...data, "id": data.consumerId});
            }
            if(data.contactId !== null) {
                this.props.tryArchiveContact({...data, "id": data.contactId});
            }
        } else {
            if(data.advisor === null) {
                this.props.tryArchiveClient({...data, "id": data.consumerId});
            } else {
                if(this.props.userId === data.advisor.advisorId) {
                    this.props.tryArchiveClient({...data, "id": data.consumerId});
                } else {
                    this.setState({"showNotice": true});
                    this.timeout = setTimeout(() => {this.setState({'showNotice': false})}, 3000);
                }
            }
        }
    };

    createColumns = {
        "0": {"key": "id", "title": "Id", "titleShowOnDesktop": false, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "hidden", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": null, "onDoubleClick": null},
        "1": {"key": "name", "title": "Name", "titleShowOnDesktop": true, "canSort": true, "sort": "asc", "sortType": "string", "sortKey": "name", "component": "link", "thStyles": {}, "tdStyles": {"color": colors.action100}, "ifNull": "", "onClick": this.view, "onDoubleClick": null},
        "2": {"key": "isAdvisorOrInvestor", "title": "Type", "titleShowOnDesktop": true, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "isAdvisorOrInvestor", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": null, "onDoubleClick": null},
        "3": {"key": "nextSession", "title": "Next meeting", "titleShowOnDesktop": true, "canSort": true, "sort": null, "sortType": "date", "sortKey": "nextSession", "component": "datetime", "thStyles": {}, "tdStyles": {}, "ifNull": '---', "onClick": null, "onDoubleClick": null},
        "4": {"key": "lastSession", "title": "Last meeting", "titleShowOnDesktop": true, "canSort": true, "sort": null, "sortType": "date", "sortKey": "lastSession", "component": "datetimeFromNow", "thStyles": {}, "tdStyles": {}, "ifNull": '---', "onClick": null, "onDoubleClick": null},
        // "5": {"key": "users", "title": "Team", "titleShowOnDesktop": true, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "users", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": null, "onDoubleClick": null},
        // "4": {"key": "advisorName", "title": "Advisor", "titleShowOnDesktop": true, "canSort": true, "sort": null, "sortType": "string", "sortKey": "advisorName", "component": "string", "thStyles": {}, "tdStyles": {}, "ifNull": '---', "onClick": null, "onDoubleClick": null},
        // "5": {"key": "coachName", "title": "Coach", "titleShowOnDesktop": true, "canSort": true, "sort": null, "sortType": "string", "sortKey": "coachName", "component": "string", "thStyles": {}, "tdStyles": {}, "ifNull": '---', "onClick": null, "onDoubleClick": null},
        "5": {"key": "actions", "title": "Actions", "titleShowOnDesktop": false, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "menu", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": {"1": this.archive, "2": this.edit}, "onDoubleClick": null},

    };

    createData = async (data) => {
        return Promise.all(data.map(d => {
            return Promise.resolve({
                "id": d.contactId,
                "name": d.name,
                "nextSession": d.nextSession,
                "lastSession": d.lastSession,
                "users": d.users,
                // "advisorName": d.advisorName,
                // "coachName": d.coachName,
                "actions": {
                    "1": {"label": d.isArchived ? <><PlusCircleOutlined />&nbsp;Not Lost</> : <><MinusCircleOutlined />&nbsp;Lost</>, "active": d.archiveAllowed, "show": true},
                    "2": {"label": <><EditOutlined />&nbsp;Edit Contact</>, "active": d.editAllowed, "show": false}
                },
                "isAdvisorOrInvestor": d.isAdvisorOrInvestor,
                "allData": d
            })
        }));
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            if (!this.props.clientsAll) {
                return (<Message text={"Create your first client"} />)
            } else {
                return (
                    <>
                        <FilterSection>
                            <OneFilter>
                                <FilterDropdown
                                label={"Status"}
                                action={this.updateFilter}
                                title={"Status"}
                                list={this.state.filters.Status.options}
                                initSelected={this.state.filters.Status.selected}
                            />
                            </OneFilter>
                        </FilterSection>
                        <TableWrapperV2
                            responsive={true}
                            tableColumns={this.createColumns}
                            tableData={this.state.data}
                            initSelected={null}
                        />
                        {this.state.data.length === 0 && <Message text={"No current clients"} />}
                        <Margin />
                        {this.state.showNotice &&
                            <Modal>
                                <Title><FontTitle24>Unable to archive</FontTitle24></Title>
                                <FontBody16>Clients and prospects of financial advisors cannot be archived.</FontBody16>
                            </Modal>
                        }
                    </>
                )
            }
        }
    }
}

const mapStateToProps = state => ({
    clientsAllFetched: state.enterprise.clientsList.get("clientsAllFetched"),
    clientsAll: state.enterprise.clientsList.get("clientsAll"),
    clientsCreating: state.enterprise.clientsList.get("clientsCreating"),
    isWMC: state.common.wmc.get("isWMC"),
    userId: state.common.user.get("userId"),
    userName: state.common.user.get("first") + " " + state.common.user.get("last")
});

const mapDispatchToProps = dispatch => ({
    setClientsDetailSelected: (data) => dispatch(setClientsDetailSelected(data)),
    tryGetAllClients: () => dispatch(tryGetAllClients()),
    tryArchiveClient: (data) => dispatch(tryArchiveClient(data)),
    tryArchiveContact: (data) => dispatch(tryArchiveContact(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientsList));