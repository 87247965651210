import React from 'react';
import {connect} from 'react-redux';
import styled from "styled-components";
import moment from "moment/moment";
import Cookies from 'js-cookie';

/* Containers */
import Webinars from './webinars';
import WebinarOne from "./structure";

import IframeStoreVideoCall from "../../shop/containers/videocall";
import IframeStoreEmailer from "../../shop/containers/emailer";
import SignupFlow from '../../signup/containers/flow';
import Confirm from './confirm';
import CardsFlow from '../../cards/containers/flow';

/* Middleware */
import {tryGetWebinarsAll, tryPostWebinarRegistration, tryCheckWebinarRegistration} from "../middleware/webinars";
import {tryAuthOnly} from '../../../routes/middleware/auth';

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {Container} from "../components/events";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

class WebinarsFlow extends React.Component {
    static defaultProps = {
        webinarsAllShowHeader: true
    }

    state={"step": 1, "webinar": {}, "status": "", "isLoading": true, "showScheduler": false, "showEmailer": false, "error": null, "registered": false, "joinUrl": ""};

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.webinarsAll !== this.props.webinarsAll) {
            return this.update();
        }
    }

    init = async () => {
        await this.props.tryGetWebinarsAll(this.props.userId);
        return this.update();
    };

    setStep = async (step) => {
        await this.setState({"step": step});
    };

    update = async () => {
        await this.setState({"isLoading": true})
        if(this.props.webinarId !== null) {
            const checkEvents = this.props.webinarsAll.filter(f => {
                return(f.get("id").toString() === this.props.webinarId.toString())
            });
            const eventIdExists = checkEvents.size === 1;
            if(eventIdExists) {
                const webinar = checkEvents.get(0);
                if (moment().isBefore(moment(webinar.get("start")))) {
                    await this.setState({"status": "Upcoming"})
                } else if (moment().isAfter(moment(webinar.get("start"))) && moment().isBefore(moment(webinar.get("end")))) {
                    await this.setState({"status": "Ongoing"})
                } else if (moment().isAfter(moment(webinar.get("end")))) {
                    await this.setState({"status": "Past"})
                }
                await this.preCheckIfRegistered(webinar)
                await this.setState({"webinar": webinar, "step": 2, "isLoading": false})
            }
        } else {
            this.setState({"isLoading": false})
        }
    };

    changeShowScheduler = async (status) => {
        this.setState({"showScheduler": status});
    };

    changeShowEmailer = async (status) => {
        this.setState({"showEmailer": status});
    };

    signupClose = async () => {
        const status = await this.props.tryPostWebinarRegistration(this.state.webinar.get("id"));
        if(status.error === null) {
            this.setState({"error": null});
            if (moment().isBefore(moment(this.state.webinar.get("end"))) && moment().isAfter(moment(this.state.webinar.get("start")).subtract(5, 'minutes'))) {
                const registration = await this.props.tryCheckWebinarRegistration(this.state.webinar.get("id"))
                window.open(registration.registration.videoUrl)
                return this.setStep(4);
            } else {
                this.setStep(4);
            }
            
        } else {
            await this.setState({"isLoading": true})
            await this.setState({"error": status.error});
            await this.setStep(2)
            await this.update;
            await this.setState({"isLoading": false})
        }
    }

    preCheckIfRegistered = async (webinar) => {
        const user = await this.props.tryAuthOnly(null, false, false);
        if(user !== null) {
            const registered = await this.props.tryCheckWebinarRegistration(webinar.get("id"))
            this.setState({'registered': registered.isRegistered, "joinUrl": registered.registration === null ? "" : registered.registration.videoUrl})
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if(this.state.step === 1) {
                return(
                    <>
                        <Webinars
                            title={this.props.title}
                            description={this.props.description}
                            changeShowScheduler={this.changeShowScheduler}
                            changeShowEmailer={this.changeShowEmailer}
                            profile={this.props.profile}
                            showHeader={this.props.webinarsAllShowHeader}
                        />
                        {this.state.showScheduler &&
                            <IframeStoreVideoCall changeShowScheduler={this.changeShowScheduler} profile={this.props.profile} />
                        }
                        {this.state.showEmailer &&
                            <IframeStoreEmailer changeShowEmailer={this.changeShowEmailer} profile={this.props.profile} />
                        }
                    </>
                )
            } else if(this.state.step === 2) {
                return(
                    <Container>
                        <WebinarOne
                            data={this.state.webinar}
                            isPast={this.state.status === "Past"}
                            isCanceled={!this.state.webinar.get("isActive")}
                            register={() => {return this.setStep(3)}}
                            registered={this.state.registered}
                            error={this.state.error}
                            handle={this.props.profile.get("publicHandle")}
                            joinUrl={this.state.joinUrl}
                            isOngoing={this.state.status === "Ongoing"}
                        />
                    </Container>
                )
            } else if (this.state.step === 3) {
                return (
                    <Container>
                        <SignupFlow
                            back={() => {return this.setStep(2)}}
                            signupClose={() => {return this.signupClose()}}
                        />
                    </Container>
                )
            } else if (this.state.step === 4) {
                return (
                    <Container>
                        <Confirm
                            close={this.update}
                            nextStep={() => {return this.setStep(5)}}
                            showPaymentMethods={false}
                        />
                    </Container>
                )
            } else if (this.state.step === 5) {
                return (
                    <Container>
                        <CardsFlow
                            close={this.update}
                            back={() => {return this.setStep(4)}}
                            onCardSelect={() => {}}
                        />
                    </Container>
                )
            } else {
                return null
            }
        }
    }

}

const mapStateToProps = state => ({
    webinarsAll: state.iframe.webinars.webinars.get("webinarsAll"),
});

const mapDispatchToProps = dispatch => ({
    tryGetWebinarsAll: (id) => dispatch(tryGetWebinarsAll(id)),
    tryPostWebinarRegistration: (saleId) => dispatch(tryPostWebinarRegistration(saleId)),
    tryAuthOnly: (email, trackNewRegistration, forceAuth) => dispatch(tryAuthOnly(email, trackNewRegistration, forceAuth)),
    tryCheckWebinarRegistration: (saleId) => dispatch(tryCheckWebinarRegistration(saleId))
});

export default connect(mapStateToProps, mapDispatchToProps)(WebinarsFlow);