import React from 'react';
import {withRouter} from "react-router-dom";
import styled from "styled-components";
import {connect} from "react-redux";

/* Containers */
import ProfilePageHome from "./containers/pagehome";
import ProfilePageAbout from "./containers/pageabout";
import PagesHandle from "./containers/sidebarhandle";
import PreviewProfile from "./containers/previewprofile";
import AboutExperienceOne from './containers/about/oneexperience';
import AboutEducationOne from './containers/about/oneeducation';
import AboutLicenseOne from './containers/about/onelicense';

/* Navs */
import {PageContent, PageFull, PageRight, Block, CardOrNot, Right, Width, WidthRight} from "../navbars/components/navlayout";
import NavPrimaryAll from "../navbars/containers/primaryall";
import NavSecondaryTop from '../navbars/containers/secondarytop';

/* Components */
import {FontHeader16} from "../../common/components/fonts";
import {colors} from "../../common/components/colors";

const Bar = styled.div`
    width: 100%;
    margin-bottom: 40px;
    background-color: ${colors.border100};
`;
const Button = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 10px 25px;
    ${props => props.active === true} {color: ${colors.primary70};}
    ${props => props.active === false} {color: ${colors.action100};}
    margin-right: 20px;
    cursor: pointer;
    @media screen and (max-width: 460px) {
        display: block;
    }
`;

const CenterPageFull = styled(PageFull)`
  margin-left: 0 !important;

`;

class ProfilePage extends React.Component {
    state={"preview": false};

    previewStatus = async (status) => {
        this.setState({"preview": status})
    };

    render() {
        return(
            <>
                {/*<NavPrimaryAll />*/}
                <CenterPageFull>
                    {/*<PageRight>*/}

                        {(this.props.location.pathname === "/settings/profile/about/experience/create" || this.props.location.pathname === "/settings/profile/about/experience/edit") &&
                            <>
                                <NavSecondaryTop title={this.props.location.pathname === "/settings/profile/about/experience/create" ? "Create Experience" : "Edit Experience"} />
                                <PageContent>
                                    <AboutExperienceOne />
                                </PageContent>
                            </>
                        }

                        {(this.props.location.pathname === "/settings/profile/about/education/create" || this.props.location.pathname === "/settings/profile/about/education/edit") &&
                            <>
                                <NavSecondaryTop title={this.props.location.pathname === "/settings/profile/about/education/create" ? "Create Education" : "Edit Education"} />
                                <PageContent>
                                    <AboutEducationOne />
                                </PageContent>
                            </>
                        }

                        {(this.props.location.pathname === "/settings/profile/about/license/create" || this.props.location.pathname === "/settings/profile/about/license/edit") &&
                            <>
                                <NavSecondaryTop title={this.props.location.pathname === "/settings/profile/about/license/create" ? "Create License, Certification" : "Edit License, Certification"} />
                                <PageContent>
                                    <AboutLicenseOne />
                                </PageContent>
                            </>
                        }

                        {(this.props.location.pathname === "/settings/profile" || this.props.location.pathname === "/settings/profile/about") &&
                            <>
                                <NavSecondaryTop title={"My Profile"} desc={this.props.isWMC ? "Give your team a better sense of who you are" : "Give your partners and their clients a better sense of who you are"}/>
                                <PageContent>
                                    <Block>
                                        <Width fullWidth={false}>

                                            <Bar>
                                                <Button active={this.props.location.pathname === "/settings/profile"} onClick={() => this.props.history.push("/settings/profile")}><FontHeader16>Intro</FontHeader16></Button>
                                                <Button active={this.props.location.pathname === "/settings/profile/about"} onClick={() => this.props.history.push("/settings/profile/about")}><FontHeader16>About</FontHeader16></Button>
                                            </Bar>

                                            {this.props.location.pathname === "/settings/profile" && <ProfilePageHome/>}
                                            {this.props.location.pathname === "/settings/profile/about" && <ProfilePageAbout/>}

                                        </Width>
                                        <WidthRight fullWidth={false}>
                                            <Right fullWidth={false}>
                                                <CardOrNot>
                                                    <PagesHandle
                                                        title={"Profile Page"}
                                                        subtitle={null}
                                                        type={"page"}
                                                        url={"profile"}
                                                        handleKey={"publicHandle"}
                                                        activeKey={"profileActive"}
                                                        errorKey={"profileHandleError"}
                                                        showLinkEmbed={false}
                                                        showOpenLink={true}
                                                        showPreview={true}
                                                        preview={this.previewStatus}
                                                    />
                                                </CardOrNot>
                                            </Right>
                                        </WidthRight>
                                    </Block>
                                </PageContent>
                                <PreviewProfile
                                    preview={this.state.preview}
                                    updatePreview={this.previewStatus}
                                />
                            </>
                        }

                    {/*</PageRight>*/}
                </CenterPageFull>
            </>
        )
    }
}

const mapStateToProps = state => ({
    isWMC: state.common.wmc.get("isWMC")
});

export default  connect(mapStateToProps, null)(withRouter(ProfilePage));