import { axiosReq } from "../../common/utils/axios";
import { Map } from "immutable";

/* Middleware */
import { tryUpdateProspectStage, getProspectStageIdByTitle } from "./prospectsstages";
import { tryEditContact, getOneContactById } from "./contacts";

/* Store */
import { setAllProspectsAddNewProspect, setAllProspectsArchiveExistingProspect, setAllProspectsEditExistingProspect, setProspectsOneFetching, setProspectsOne, setProspectsUpdating, setProspectsUpdatingError, setProspectCreatedError, setProspectCreating, setAllProspectsFetching, setAllProspects, setProspectsArchiving, setAllProspectsFetched, setProspectPromoting } from "../store/prospects";
import { setAllContactsAddNewContact } from "../store/contacts";

/* Static */
import { devEnterpriseCreateProspectData, devEnterpriseProspectsListV3, devEnterpriseProspectV3 } from "../static/devdata";
import {tryGetAllClients} from "../clients/middleware/clients";

export const tryGetAllProspects = () => {
    return async (dispatch, getState) => {
        dispatch(setAllProspectsFetching(true));
        const prospects = await axiosReq('apiv1/coach/prospects/with-meetings', "GET", {}, devEnterpriseProspectsListV3);
        const isWMC = getState().common.wmc.get("isWMC");
        await dispatch(setAllProspects(prospects.prospects, isWMC));
        dispatch(setAllProspectsFetched());
        return dispatch(setAllProspectsFetching(false));
    }
};

export const tryGetOneProspect = (prospectId, storeOrReturn) => {
    return async (dispatch, getState) => {
        dispatch(setProspectsOneFetching(true));
        const prospect = await axiosReq('apiv1/coach/prospects/with-meetings/'+prospectId, "GET", {}, devEnterpriseProspectV3);
        const isWMC = getState().common.wmc.get("isWMC");
        let prospectAdj = {...prospect, "lastName": (!isWMC && prospect.referralSource === "willow") ? prospect.lastName.charAt(0)+"." : prospect.lastName};
        if(storeOrReturn === "store") {
            dispatch(setProspectsOne(prospectAdj));
            return dispatch(setProspectsOneFetching(false));
        } else {
            dispatch(setProspectsOneFetching(false));
            return prospectAdj;
        }
    }
};

const formatProspectsContact = (data, isWMC, inputData, user) => (Map({
    "contactId": data.contactId,
    "coachAssignments": [],
    "firstName": data.firstName,
    "lastName": data.lastName,
    "isArchived": data.isArchived,
    "businessUserId": null,
    "prospectId": data.prospectId,
    "consumerId": data.consumerId,
    "email": inputData.email,
    "phone": inputData.phone,
    "prospectStatus": "Prospect",
    "memberOfGroups": null,
    "referralSource": isWMC ? "wmc" : user.first + " " + user.last,
    "timeZone": inputData.timeZone,
    "companyName": data.companyName,
    "coaches": []
}));

const formatProspect = (data, isWMC, user, undefinedStageId) => (Map({
    "advisor": isWMC ? Map({"advisorId": user.userId, "firstName": user.first, "lastName": user.last}) : null,
    "coach": isWMC ? null : user.first + " " + user.last,
    "consumerId": data.consumerId,
    "contactId": data.contactId,
    "firstName": data.firstName,
    "lastName": data.lastName,
    "isArchived": data.isArchived,
    "lastSession": null,
    "nextSession": null,
    "memberOfGroups": null,
    "prospectId": data.prospectId,
    "prospectStageId": undefinedStageId,
    "prospectStageName": "undefined",
    "revenue": data.revenue,
    "probabilityOfClosing": data.probabilityOfClosing,
    "companyName": data.companyName,
    "coaches": []
}));

export const tryCreateProspect = (data) => {
    return async (dispatch, getState) => {
        dispatch(setProspectCreating(true));
        let revNoDollarSign = data.revenue.toString().charAt(0) === "$" ? data.revenue.toString().substring(1) : data.revenue.toString();
        let request = {companyName: data.companyName, firstName: data.first, lastName: data.last, email: data.email, phone: data.phone, timeZone: data.timeZone, probabilityOfClosing: (data.probabilityOfClosing === undefined ? "0.00" : data.probabilityOfClosing.toString()), revenue: (data.revenue === undefined ? "0.00" : revNoDollarSign.replaceAll(",", ""))};
        if(data.fromContactId !== undefined) {
            request = {...request, contactId: data.fromContactId};
        }
        const newProspect = await axiosReq('apiv1/coach/prospects', "PUT", request, devEnterpriseCreateProspectData(data.first, data.last, data.email, data.phone, data.timeZone, (data.revenue === undefined ? "0.00" : revNoDollarSign.replaceAll(",", "")), (data.probabilityOfClosing === undefined ? "0.00" : data.probabilityOfClosing.toString()), data.companyName));
        if(newProspect.hasOwnProperty("error")) {
            dispatch(setProspectCreatedError(newProspect.error));
        } else {
            dispatch(setProspectCreatedError(null));
            const isWMC = getState().common.wmc.get("isWMC");
            const user = getState().common.user.toJS();
            const undefinedStageId = await dispatch(getProspectStageIdByTitle("undefined"));
            await dispatch(setAllProspectsAddNewProspect(formatProspect(newProspect, isWMC, user, undefinedStageId)));
            await dispatch(tryUpdateProspectStage({"prospectId": newProspect.prospectId, "stageId": data.stageId, "fromStatus": "Prospect", "toStatus": "Prospect"}));
            dispatch(setAllContactsAddNewContact(formatProspectsContact(newProspect, isWMC, data, user)));
        }
        dispatch(setProspectCreating(false));
        return(newProspect);
    }
};

export const tryCreateProspectWmc = (data) => {
    return async (dispatch, getState) => {
        dispatch(setProspectCreating(true));
        let revNoDollarSign = data.revenue.toString().charAt(0) === "$" ? data.revenue.toString().substring(1) : data.revenue.toString();
        let request = {companyName: data.companyName, firstName: data.first, lastName: data.last, email: data.email, phone: data.phone, timeZone: data.timeZone, probabilityOfClosing: (data.probabilityOfClosing === undefined ? "0.00" : data.probabilityOfClosing.toString()), revenue: (data.revenue === undefined ? "0.00" : revNoDollarSign.replaceAll(",", ""))};
        if(data.fromContactId !== undefined) {
            request = {...request, contactId: data.fromContactId};
        }
        const newProspect = await axiosReq('apiv1/wmc/prospects', "PUT", request, devEnterpriseCreateProspectData(data.first, data.last, data.email, data.phone, data.timeZone, (data.revenue === undefined ? "0.00" : revNoDollarSign.replaceAll(",", "")), (data.probabilityOfClosing === undefined ? "0.00" : data.probabilityOfClosing.toString()), data.companyName));
        if(newProspect.hasOwnProperty("error")) {
            dispatch(setProspectCreatedError(newProspect.error));
        } else {
            dispatch(setProspectCreatedError(null));
            const isWMC = getState().common.wmc.get("isWMC");
            const user = getState().common.user.toJS();
            const undefinedStageId = await dispatch(getProspectStageIdByTitle("undefined"));
            await dispatch(setAllProspectsAddNewProspect(formatProspect(newProspect, isWMC, user, undefinedStageId)));
            await dispatch(tryUpdateProspectStage({"prospectId": newProspect.prospectId, "stageId": data.stageId, "fromStatus": "Prospect", "toStatus": "Prospect"}));
            dispatch(setAllContactsAddNewContact(formatProspectsContact(newProspect, isWMC, data, user)));
        }
        dispatch(setProspectCreating(false));
        return(newProspect);
    }
};

export const tryEditProspect = (data) => {
    return async dispatch => {
        dispatch(setProspectsUpdating(true));
        let probabilityOfClosing = data.probabilityOfClosing === undefined ? "0.00" : data.probabilityOfClosing.toString();
        let revenue = data.revenue === undefined ? "0.00" : (data.revenue.toString().charAt(0) === "$" ? data.revenue.toString().substring(1) : data.revenue.toString()).replaceAll(",", "");
        const request = {"probabilityOfClosing": probabilityOfClosing, "revenue": revenue};
        const editedProspect = await axiosReq('apiv1/coach/prospects/'+data.prospectId, "PUT", request, {});
        if(editedProspect.hasOwnProperty("error")) {
            dispatch(setProspectsUpdatingError(editedProspect.error));
        } else {
            dispatch(setProspectsUpdatingError(null));
            await dispatch(setAllProspectsEditExistingProspect(data.prospectId, request));
        }
        dispatch(setProspectsUpdating(false));
    }
};

export const tryArchiveProspect = (data) => {
    return async dispatch => {
        dispatch(setProspectsArchiving(true));
        await axiosReq('apiv1/coach/clients/'+data.id+'/status', "PUT", {"isArchived": !data.isArchived}, {});
        await dispatch(setAllProspectsArchiveExistingProspect(data.id, data.isArchived));
        return dispatch(setProspectsArchiving(false));
    }
};

export const createProspectFromContact = (contactId, extraDataObject) => {
    return async dispatch => {
        const data = await dispatch(getOneContactById(contactId));
        const undefinedStageId = await dispatch(getProspectStageIdByTitle("undefined"));
        const newProspectId = await dispatch(tryCreateProspect({...data, ...extraDataObject, "stageId": undefinedStageId, "first": data.firstName, "last": data.lastName, "fromContactId": contactId}));
        dispatch(tryEditContact({...data, "first": data.firstName, "last": data.lastName}, newProspectId.prospectId));
        return ({"consumerId": newProspectId.consumerId, "prospectId": newProspectId.prospectId});
    }
};

export const tryPostProspectPromotion = (dataObject, prospectId, coachOrAdvisor, wmcId) => {
    return async dispatch => {
        dispatch(setProspectPromoting(true));
        if (coachOrAdvisor === "Expert") {
            await axiosReq('apiv1/willow/promote-prospect-to-coach', "POST", {...dataObject, "prospectId": prospectId}, {});
        } else {
            await axiosReq('apiv1/willow/promote-prospect-to-advisor', "POST", {...dataObject, "prospectId": prospectId, "wmcId": wmcId}, {});
        }
        return dispatch(setProspectPromoting(false));
    }
};

export const getOneProspectByConsumerId = (consumerId) => {
    return async (dispatch, getState) => {
        if(getState().enterprise.prospects.get("prospectsAll").size === 0) {
            await dispatch(tryGetAllProspects());
        }
        return getState().enterprise.prospects.get("prospectsAll").filter(c => {return(c.get("consumerId") === consumerId)});
    }
};

/*
GET     apiv1/coach/prospects/with-meetings                         get all prospects           [QA]
PUT     apiv1/coach/prospects                                       create a new prospect       [QA]
PUT     apiv1/coach/clients/prospectId/status                       archive a prospect          [QA]
PUT     apiv1/coach/prospects/prospectId                            update a prospect           [QA]          (id, email, firstName, lastName)
POST    apiv1/coach/prospects/prospectId/invitations                send prospect an email      [IGNORE]      (id)
 */