import React from 'react';
import styled from "styled-components";
import {Link} from 'react-router-dom';

/* Components */
import {FontBody16, FontTitle18} from "../../../common/components/fonts";
import {LogoLoading} from "../../../common/components/loading";
import {ButtonSecondary} from "../../../common/components/buttons";
import {colors} from "../../../common/components/colors";

const ReadOnlyLabel = styled.div`
    color: ${colors.secondary100};
`;

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

const FitContent = styled.div`
    width: fit-content;
`;

class AvailabilityOptionBox extends React.Component {
    state = {"isLoading": true};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({"isLoading": false});
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <FontTitle18>{this.props.title}</FontTitle18>
                    <br />
                    <ReadOnlyLabel><FontBody16>{this.props.subTitle}</FontBody16></ReadOnlyLabel>
                    <br />
                    <FitContent><Link to={this.props.href}><ButtonSecondary canSubmit={false} label={this.props.label}/></Link></FitContent>
                </>
            )
        }
    }
}

export default AvailabilityOptionBox;
