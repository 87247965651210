import {axiosReq} from "../../../../common/utils/axios";

/* DevData */
import {devConsumerProductGeneral, devConsumerTeamMembers, devTeamMemberMeetingsData} from "../../../../consumer/team/static/devdata";
import {devEnterpriseCoachesAssignedToWMCData} from "../../../static/devdata";
import {devIframeProfileData} from "../../../../iframes/profile/static/devdata";

/* My Coaches */

export const tryGetAllMyCoaches = () => {
    return async (dispatch, getState) => {
        if(getState().common.user.get("isLily")) {
            const team = await axiosReq('apiv1/consumer/coach/all-my-coaches', "GET", {}, devConsumerTeamMembers);
            return team.allCoaches;
        } else {
            return []
        }
    }
};

/* Browse */

export const tryGetAllCoaches = () => {
    return async dispatch => {
        const coaches = await axiosReq('apiv1/wmc/all-coaches', "GET", {}, devEnterpriseCoachesAssignedToWMCData);
        return coaches.allCoaches;
    }
};

/* Profile */

export const tryGetCoachProfile = (coachId) => {
    return async dispatch => {
        return axiosReq('apiv1/public/coach/'+coachId+'/profile', "GET", {}, devIframeProfileData);
    }
};

/* Book Appointment */

export const tryPostCreateProspectRecord = (coachId) => {
    return async dispatch => {
        return axiosReq('apiv1/coach/coach/become-prospect', "POST", {"coachId": coachId}, {});
    }
}

export const tryDeleteAppointment = (cancelToken) => {
    return async dispatch => {
        return axiosReq('apiv1/public/sessions/'+cancelToken, "DELETE", {}, {});
    }
}

/* Overview */

export const tryGetCoachingAppointments = () => {
    return async dispatch => {
        return axiosReq('apiv1/consumer/journey/sessions', "GET", {}, devTeamMemberMeetingsData);
    }
}

export const tryGetCoachingGeneralSessions = () => {
    return async dispatch => {
        const sessionsPrePurchased = await axiosReq('apiv1/consumer/sessions/get-available-amount', "GET", {}, devConsumerProductGeneral);
        return sessionsPrePurchased.availableSessions
    }
};


/* ADD APPOINTMENTS */

export const tryPostAssignCoachWillowSessions = (sessions) => {
    return async (dispatch, getState) => {
        const clientId = getState().common.user.get("userId");
        return axiosReq('apiv1/coach/clients/'+clientId+'/assign-willow-sessions', "POST", {"willowSessionAmount": sessions}, {});
    }
};
