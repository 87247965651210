export const stringToMoney = (moneyString) => {
    let moneyNumber = parseFloat(moneyString);
    if(moneyNumber < 1000) {
        return(moneyNumber.toFixed(0))
    } else if(moneyNumber < 1000000) {
        return((moneyNumber / 1000).toFixed(0) + "K")
    } else if(moneyNumber < 1000000000) {
        return((moneyNumber / 1000000).toFixed(1) + "M")
    } else if(moneyNumber < 1000000000000) {
        return((moneyNumber / 1000000000).toFixed(1) + "B")
    } else {
        return((moneyNumber / 1000000000000).toFixed(1) + "T")
    }
};