import React from 'react';
import styled from 'styled-components';
import {colors} from '../../../common/components/colors';
import {FontTitle18} from "../../../common/components/fonts";
import {LogoLoadingSmall} from "../../../common/components/loading";

export const ActionBox = styled.div`
    ${props => props.leftNav !== true} {
        background-color: ${colors.white};
        max-width: calc(600px - 100px);
        width: calc(100% - 100px);
        padding: 50px;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        @media screen and (max-width: 640px) {
            max-height: 100%;
            max-width: calc(100% - 100px);
            width: calc(100% - 100px);
            right: 0;
            bottom: 0;
            top: 0;
            left: 0;
        }
    }
    ${props => props.leftNav !== false} {
        border: 1px solid ${colors.border100};
        -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
        background-color: ${colors.white};
        max-width: 340px;
        width: 100%;
        border-radius: 6px;
        margin-bottom: 15px;
        position: fixed;
        right: 40px;
        bottom: 100px;
        height: 100%;
        max-height: 520px;
        z-index: 10;
        @media screen and (max-width: 640px) {
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            border: none;
            box-shadow: none;
            -webkit-box-shadow: none;
            border-radius: 0px;
            right: 0px;
            bottom: 0px;
            top: 0px;
            left: 0px;
        }
    }
`;
export const Background = styled.div`
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
`;
export const FitWidthButton = styled.div`
    & div {
        width: calc(100% - 46px);
    }
    & div div {
        width: 100%;
        text-align: center;
    }
`;
export const CoachingLoading = () => (
    <ActionBox>
        <FontTitle18>Coaching</FontTitle18>
        <br />
        <br />
        <LogoLoadingSmall />
        <br />
    </ActionBox>
);

export const Logo = styled.div`
    text-align: center;
    margin: 30px 0px 10px 0px;
    & img {
        width: 75px;
        height: auto;
    }
`;

export const Section = styled.div`
    ${props => props.leftNav !== true} {
      padding: 0 20px;
      margin-top: 40px;
      overflow-y: auto;
      height: calc(100% - 132px);
    }
    ${props => props.leftNav !== false} {
      padding: 10px 20px;
      overflow-y: auto;
      height: calc(100% - 80px);
    }
`;