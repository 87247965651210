import {Map} from 'immutable';
import moment from 'moment/moment';

/* Immutable */

const EMAIL_SENDING = 'EMAIL_SENDING';
const EMAIL_DOWNLOADING = 'EMAIL_DOWNLOADING';
const EMAIL_EMAIL_OPEN = 'EMAIL_EMAIL_OPEN';
const EMAIL_STORE_RESET = 'EMAIL_STORE_RESET';

/* Actions */

export const setEmailSending = (status) => ({'type': EMAIL_SENDING, 'status': status});
export const setPurchaseLinkDownloading = (status) => ({'type': EMAIL_DOWNLOADING, 'status': status});
export const setMessagesEmailOpen = (open, recipientId, recipientType, recipientFirstName, recipientLastName, isReply, subject, content) => ({'type': EMAIL_EMAIL_OPEN, 'open': open, 'recipientId': recipientId, 'recipientType': recipientType, 'recipientFirstName': recipientFirstName, 'recipientLastName': recipientLastName, 'isReply': isReply, 'subject': subject, 'content': content});
export const setMessagesReset = () => ({'type': EMAIL_STORE_RESET});

/* Initial State */

const initialState = Map({
    isSending: false,
    isDownloading: false,
    isOpen: false,
    subject: null,
    body: null,
    isReply: false,
    recipientId: null,
    recipientType: null,
    recipientFirstName: null,
    recipientLastName: null,
    lastInitiated: null
});

/* Reducer */

const messagesReducerEmailer = (state=initialState, action) => {
    switch (action.type) {
        case EMAIL_SENDING:
            return state.merge({'isSending': action.status});
        case EMAIL_DOWNLOADING:
            return state.merge({'isDownloading': action.status});
        case EMAIL_EMAIL_OPEN:
            return state.merge({
                'isOpen': action.open,
                'recipientId': action.recipientId,
                'recipientType': action.recipientType,
                'recipientFirstName': action.recipientFirstName,
                'recipientLastName': action.recipientLastName,
                'isReply': action.isReply,
                'subject': action.subject,
                'body': action.content,
                'lastInitiated': moment()
            });
        case EMAIL_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default messagesReducerEmailer;
