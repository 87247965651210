import React from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import moment from 'moment/moment';
import {withRouter} from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import {fromJS} from 'immutable';

/* Components */
import {FontHeader16, FontBody16} from '../../common/components/fonts';
import {colors} from '../../common/components/colors';
import {ButtonTertiary} from "../../common/components/buttons";
import {LogoLoading, LogoLoadingSmall} from "../../common/components/loading";
import {Message} from "../../common/components/messages";

/* Containers */
import Meeting from './contactactivitymeeting';

/* Middleware */
import {tryGetEnterpriseClientActivity, tryGetEnterpriseClientActivityFutureSessions} from '../middleware/clientsactivity';

/* Components */
const Section = styled.div`
    padding: 20px;
    background-color: ${colors.border30};
    max-height: 100%;
    @media screen and (max-width: 460px) {
        padding: 5px;
    }
`;
const DateBox = styled.div`
    margin-bottom: 10px;
    padding-left: 15px;
`;
const Empty = styled.div`
    margin: 30px;
    color: ${colors.primary70};
    text-align: center;
`;
const LoadMore = styled.div`
    text-align: center;
    margin: 60px auto 0px auto;
    width: fit-content;
`;

class ActivityMeetings extends React.Component {
    state={
        "isLoading": true,
        "activityByMonth": {},
        "isFirstLoad": false,
        "prospectStatus": null,
        "activity": [],
        "months": [],
        "pauseUpdate": false
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if((prevProps.prospectId !== this.props.prospectId ||
            prevProps.prospectStatus !== this.props.prospectStatus ||
            prevProps.location.pathname !== this.props.location.pathname ||
            prevProps.activity !== this.props.activity ||
            prevProps.sessions !== this.props.sessions)
            && !this.state.pauseUpdate) {
            return this.init();
        }
    }

    init = async () => {
        if(this.props.prospectStatus !== "Contact") {
            await this.setState({"pauseUpdate": true});
            const future = await this.getForwardActivity();
            const current = await this.getMonthsActivity(moment());
            await this.setState({
                "activity": [...future.activity, ...current.activity],
                "months": [...future.months, current.month],
                "pauseUpdate": false
            });
            this.update();
        } else {
            this.setState({"prospectStatus": this.props.prospectStatus, "isLoading": false});
        }
    };

    getForwardActivity = async () => {
        const datetime = moment().endOf("month").subtract(new Date().getTimezoneOffset(), "minutes").add(1, "day").format("YYYY-MM-DD");
        const future = await this.props.tryGetEnterpriseClientActivityFutureSessions(datetime, this.props.prospectId);
        return ({"activity": future.activity, "months": future.months});
    };

    getMonthsActivity = async (date) => {
        const datetime = moment(date).endOf("month").subtract(new Date().getTimezoneOffset(), "minutes").format("YYYY-MM-DD");
        const activity = await this.props.tryGetEnterpriseClientActivity(datetime, "meeting");
        return ({"activity": activity, "month": datetime});
    };

    getMoreActivity = async () => {
        await this.setState({"pauseUpdate": true});
        const lastMonth = moment(this.state.months[this.state.months.length-1]).startOf("month").subtract(1, "day").format("YYYY-MM-DD");
        const current = await this.getMonthsActivity(lastMonth);
        await this.setState({
            "activity": [...this.state.activity, ...current.activity],
            "months": [...this.state.months, current.month],
            "pauseUpdate": false
        });
        this.update();
    };

    update = async () => {
        await this.setState({"prospectStatus": this.props.prospectStatus, "isLoading": true});
        await this.filterActivity();
        return this.setState({"isLoading": false});
    };

    filterActivity = async () => {
        // Reset history
        await this.setState({"activityByMonth": []});
        // Filter
        let activityFiltered = await this.state.activity.filter(a => {
            return ((a.activityType === "pastConferences" || a.activityType === "ongoingConferences" ||
                a.activityType === "upcomingConferences" || a.activityType === "canceledConferences"))
        });
        // Sort
        const activityOrdered = await activityFiltered.sort((a, b) => {
            return(b.orderDatetime - a.orderDatetime)
        });
        // Fit months
        const res = await this.categorize(activityOrdered);
        await this.setState({"activityByMonth": res});
    };

    categorize = async (activityOrdered) => {
        let categorized = {};
        await Promise.all(this.state.months.map(m => {
            categorized = {
                ...categorized,
                [moment(m).format("YYYY-MM")]: activityOrdered.filter(o => {
                    return (moment(o.orderDatetime).format("YYYY-MM") === moment(m).format("YYYY-MM"))
                })
            };
            return Promise.resolve(categorized);
        }));
        return categorized;
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            if(this.state.prospectStatus === "Contact") {
                return(<Section><Message text={"Change relationship to prospect or client to start tracking meetings"} /></Section>)
            } else {
                if(this.state.activityByMonth.length === 0) {
                    return(<Section />)
                } else {
                    return(
                        <Section>
                            {Object.keys(this.state.activityByMonth).sort((a, b) => {return(new Date(b) - new Date(a))}).map((m, mIndex) => (
                                <div key={mIndex}>
                                    <DateBox><FontBody16>{moment(m).format("MMMM YYYY")}</FontBody16></DateBox>
                                    {this.state.activityByMonth[m].length === 0
                                        ? <Empty><FontHeader16>No meetings this month</FontHeader16></Empty>
                                        : <>
                                            {this.state.activityByMonth[m].map((a, aIndex) => (
                                                <LazyLoad offset={200} key={aIndex} placeholder={<LogoLoadingSmall />} >
                                                    <div>
                                                        {(a.activityType === "upcomingConferences" || a.activityType === "ongoingConferences" || a.activityType === "pastConferences" || a.activityType === "canceledConferences") && <Meeting data={fromJS(a)} forceUpdate={this.update} init={this.init}/>}
                                                    </div>
                                                </LazyLoad>
                                            ))}
                                        </>
                                    }
                                </div>
                            ))}
                            <LoadMore onClick={this.getMoreActivity}><ButtonTertiary label={"Load more"} canSubmit={false} /></LoadMore>
                        </Section>
                    )
                }
            }
        }
    }
}

const mapStateToProps = state => ({
    activity: state.enterprise.clientsActivity.get("clientActivity"),
    prospectStatus: state.enterprise.clientsDetail.get("client").get("prospectStatus"),
    prospectId: state.enterprise.clientsDetail.get("client").get("prospectId"),
    sessions: state.enterprise.sessions.get("sessions")
});

const mapDispatchToProps = dispatch => ({
    tryGetEnterpriseClientActivity: (date, activityType) => dispatch(tryGetEnterpriseClientActivity(date, activityType)),
    tryGetEnterpriseClientActivityFutureSessions: (date, prospectId) => dispatch(tryGetEnterpriseClientActivityFutureSessions(date, prospectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ActivityMeetings));