import React from 'react';
import styled from "styled-components";

/* Components */
import {colors} from "../../../common/components/colors";

export const ForcedStyles = styled.div`
    color: ${colors.primary100} !important;
    height: calc(100vh - 260px);
    @media screen and (max-width: 1088px) {
        margin-bottom: 20px;
        height: calc(100vh - 265px);
    }
    @media screen and (max-width: 640px) {
        height: calc(100vh - 300px);
    }
    @media screen and (max-width: 460px) {
        height: calc(100vh);
    }
    
    .fc-view-container {
        background-color: ${colors.border30};
        border: 1px solid ${colors.primary30};
        border-bottom: none !important;
    }
    .fc-unthemed {
        margin-bottom: 20px;
    }
    .fc-unthemed td, .fc-unthemed th {
        border-color: ${colors.border100};
    }
    & table {
        font-family: GraphicWebRegular;
        font-size: 18px;
        line-height: 24px;
        @media screen and (max-width: 748px) {
            font-size: 14px;
            line-height: 20px;
        }
        & .fc-widget-header {
            border-left: 0px transparent;
            border-top: 0px transparent;
            border-right: 0px transparent;
        }
        & .fc-body .fc-widget-content:first-child  {
            border: 1px solid ${colors.primary30};
            border-left: none !important;
        }
        & .fc-axis.fc-time.fc-widget-content {
            border-color: inherit;
            font-weight: bold;
        }
        & .fc-time-grid-container {
            min-height: 350px;
            height: calc(100vh - 250px);
        }
    }
    
    & .pointer {
        cursor: pointer;
    }
    
    & h2 {
        font-family: GraphicWebRegular;
        font-size: 21px;
        line-height: 21px;
    }
    
    & .fc-header-toolbar button {
        background-color: ${colors.white};
        color: ${colors.primary70};
        border-color: ${colors.primary30};
        font-size: 16px;
        line-height: 16px;
        font-family: GraphicWebMedium;
        &:active {
            outline: 0;
            background-color: ${colors.white};
            color: ${colors.primary70};
            border-color: ${colors.primary30};
            box-shadow: none !important;
        }
        &:focus {
            box-shadow: none !important;
        }
    }
    
    & .fc-header-toolbar {
        @media screen and (max-width: 460px) {
            display: flex !important;
            flex-flow: row wrap !important;
            & .fc-left {
                order: 2 !important;
            }
            & .fc-center {
                order: 1 !important;
                width: 100% !important;
                margin-bottom: 20px;
            }
            & .fc-right {
                order 3 !important;
            }
        }
    }
    
    & .fc-today-button {
        color: ${colors.primary70} !important;
        padding: 6px 20px;
        border: 1px solid ${colors.primary30} !important;
        font-size: 16px !important;
        line-height: 16px !important;
        @media screen and (max-width: 640px) {
            padding: 4px 6px 5px 6px;
        }
        &:hover:enabled {
            background-color: ${colors.border100};
        }
    }
    & .fc-next-button, .fc-prev-button {
        padding: 5px 10px;
        height: 30px;
        border-color: ${colors.primary30} !important;
        @media screen and (max-width: 1440px) {        
            padding: 5px 4px;
        }
        @media screen and (max-width: 640px) {
            font-size: 16px !important;
            line-height: 22px !important;   
            padding: 0px;
            height: 30px;
        }
        &:hover {
            background-color: ${colors.border100};
        }
    }
    & .fc-icon {
        line-height: 0.85;
        font-size: 1.4em;
        color: ${colors.primary70};
    }
    & .fc-next-button {
        border-left: 1px solid ${colors.primary30} !important;
    }
    & .fc-prev-button {
        border-right: 1px solid ${colors.primary30} !important;
    }
    & .fc-timeGridDay-button, .fc-timeGridWeek-button, .fc-dayGridMonth-button {
        border-color: ${colors.primary30} !important;
        padding: 6px 20px;
        height: 30px;
        @media screen and (max-width: 640px) {
            font-size: 16px !important;
            line-height: 22px !important;
            padding: 4px 6px 5px 6px;
            height: 30px;
        }
        &:hover {
            background-color: ${colors.border100};
        }
    }
    & .fc-header-toolbar .fc-button-active {
        background-color: ${colors.border100};
        color: ${colors.primary70};
        border-color: ${colors.primary30} !important;
        padding: 6px 20px;
        height: 30px;
        @media screen and (max-width: 640px) {
            font-size: 18px !important;
            line-height: 24px !important;
            padding: 2px 6px 5px 6px;
            height: 30px;
        }
    }
    
    & .fc-day-header {
        font-size: 16px;
        font-weight: bold;
        padding: 4px 0px;
        border-right: 1px solid ${colors.primary30} !important;
        & span div div:nth-child(1) {
            line-height: 16px;
        }
        & span div div:nth-child(2) {
            line-height: 30px;
        }
        @media screen and (max-width: 748px) {
            font-size: 16px;
            & span div div:nth-child(1) {
                line-height: 16px;
            }
            & span div div:nth-child(2) {
                line-height: 30px;
            }
        }
    }
    
    & .fc-day-header.fc-today {
        & span div div:nth-child(2) {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            background-color: ${colors.action100};
            margin: 0px auto;
            @media screen and (max-width: 748px) {
                width: 30px;
                height: 30px;
                border-radius: 30px;
            }
        }
    }
    
    & .fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-content, .fc-unthemed .fc-popover, .fc-unthemed .fc-list-view, .fc-unthemed .fc-list-heading td {
        border-color: ${colors.primary30} !important;
    }
    
    & .fc-event {
        border: 2.5px solid ${colors.secondary100};
        background-color: ${colors.secondary100};
        font-weight: bold;
        padding-left: 3px;
    }
`;

export const Title = styled.div`
    display: inline-block;
    width: calc(100% - 25px);
`;

export const ActionBox = styled.div`
    padding: 0px 30px 30px 30px;
`;

export const ActionBoxRight = styled.div`
    text-align: right;
    padding-bottom: 30px;
    @media screen and (max-width: 460px) {
        text-align: left !important;
    }
`;

export const Close = styled.div`
    cursor: pointer;
    margin-top: 20px;
    padding: 7px 15px;
    color: ${colors.primary100};
    width: 50px;
    display: inline-block;
`;

export const TertiaryButton = styled.div`
    cursor: pointer;
    margin-left: 30px;
    padding: 7px 0px;
    width: fit-content;
    display: inline-block;
    color: ${colors.action100};
    @media screen and (max-width: 460px) {
        margin-left: 0px;
        display: block;
    }
`;

export const Success = styled.span`
    color: ${colors.progress100};
`;

export const FitContent = styled.div`
    width: fit-content;
`;

export const Action = styled.div`
    color: ${colors.action100};
`;

export const AlertRed = styled.div`
    padding: 10px 15px;
    width: calc(100% - 30px);
    background-color: ${colors.alert5};
    border: 1px solid ${colors.alert100};
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
    margin-bottom: 30px;
    border-radius: 4px;
`;

export const Alert = styled.div`
    padding: 10px 15px;
    width: calc(100% - 30px);
    background-color: ${colors.progress5};
    border: 1px solid ${colors.progress100};
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
    margin-bottom: 30px;
    border-radius: 4px;
`;
