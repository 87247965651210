import { combineReducers } from 'redux';

/* Common */
import authReducer from "./store/auth";

const signUpAssignReducer = combineReducers({
    auth: authReducer,
});

export default signUpAssignReducer;
