import React from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import {fromJS} from 'immutable';
import styled from "styled-components";

/* Components */
import {Tags, Tag, BookmarkIcon, Container, Extra54px, WriteToUsBtn, FeaturedMaterial, FeaturedMaterialDivider, ArticleBox, Header, HeaderMobile, HoverDropdown, PageTitle, PageSubtitle, BackSection, Back, SmallCardRowImage, OpenLink, HoverLink, SmallCardRow, InlineBlock, SpaceBetween, ChevronLeftRight, CenterHighlighted, NavMenuDivider, NavMenuDesktop, NavMenuItemText, NavMenuMobile, CenterContent, LatestSection, HighlightedTextContainer, MobileIcon, TagsMobile, FlexRow, AllContent, SmallFeatured, SmallFeaturedRow, SmallFeaturedCol, SmallFeaturedRowImage, NavMenuItem, DropdownItem, DropdownDivider} from "../components/care";
import {LogoLoading} from "../../../common/components/loading";
import {FontTitle16, FontHeader12, FontHeader16, FontBody16, FontTitle21, FontHeader21, FontTitle24, FontTitle34, FontTitle40, FontSubtitle40, FontBody18, FontTitle28, FontHeader14} from '../../../common/components/fonts';
import {ButtonPrimary} from '../../../common/components/buttons';
import {SearchInputUncontrolled} from "../components/input";
import {colors} from "../../../common/components/colors";
import {images} from "../../../common/components/images";
import { MenuOutlined } from '@ant-design/icons';


/* Containers */
import ListBookmarks from "./bookmarks";
import ContentHighlighted from './contenthighlighted';
import SmallFeaturedArticle from './smallfeatured';
import SelectedContent from './selectedcontent'
import ContentOverviewStyles from './contentoverviewstyles';

/* Store */
import {insightParentCategorySelected, insightSubCategorySelected, setInsightsSearchTerm} from '../store/insights';

/* Middleware */
import {tryGetInsightParentCategories, trySearchInsights, tryGetInsightSubCategories, tryGetInsights, tryGetAllInsights, tryGetMostPopular} from '../middleware/insights';

/* Utils */
import {trackViewLearnParentJourneys, trackViewLearnSubJourneys} from "../../../common/utils/tracking";

const ContainerTop = styled.div`
    padding-bottom: 25px;
    background-color: ${colors.white};
    border-radius: 0px 0px 25px 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    z-index: 1;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: 800px) {
        display: none;
    }
`

class InsightCategories extends React.Component {
    static defaultProps = {
        searchMade: false,
        searchTerm: ""
    }

    state = {
        "isLoading": true, 
        'tagSearched': false, 
        "options": [], 
        "searchResults": [], 
        'term': "", 
        "searchMade": false, 
        "haltUpdateSearch": false, 
        "highlighted": null, 
        "latest": [], 
        "subcategoriesOpen": false,
        "insights": [],
        "mostPopular": [],
        "showMobileMenu": false,
        "category": null,
        "categoryArticles": [],
        "categoryIndex": null,
        "showSubcategories": null,
        "popularByCategory": [],
        "subcategories": [],
        "selectedSubcategory": null,
        "subArticles": []
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.term !== "" && this.state.term === '' && !this.state.haltUpdateSearch) {
            return this.handleCloseSearch("updated");
        }
        if (prevState.selectedSubcategory !== this.state.selectedSubcategory)
        {
            return this.init();
        }
        // if(prevState.category != null && !this.state.subcategories.includes(this.state.selectedSubcategory) && prevState.category != this.state.category) {
        // //         //console.log(prevState.category, this.state.category, prevState.category != this.state.category)
        // // //     // console.log(prevState.selectedSubcategory, this.state.selectedSubcategory, prevState.selectedSubcategory == this.state.selectedSubcategory)
        // // //     // console.log(prevState.category)
        //     this.setState({"selectedSubcategory": null})

        // }
    }

    init = async () => {
        await this.props.tryGetInsightParentCategories();
        const sortedOpts = this.props.insightParentCategories.sort((a,b) => (a.get("title") > b.get("title")) ? 1 : ((b.get("title") > a.get("title")) ? -1 : 0)).toJS()

        const popular = await this.props.tryGetMostPopular(3)

        //const categoryArticles = await Promise.all(sortedOpts.map(o => this.getChildCategories(o)));
        //console.log(categoryArticles)
        // const categoryPopular = await Promise.all(popular.map(o => this.getChildCategories(o)))

        await this.props.tryGetAllInsights(10)

        const sortedCourses = await this.props.insights.sortBy((a) => a.get('createdOn')).reverse()
        const insightsNoCourses = await sortedCourses.filter(i => {return (i.get("isReadingMaterial") || i.get("isVideo"))})

        const shuffled = sortedCourses.sort(() => 0.5 - Math.random());
        let selected = shuffled.slice(0, 3);

        console.log(selected)

        // console.log("popular", categoryPopular)
        this.setState({
            "options": sortedOpts,
            "highlighted": [selected.get(0), selected.get(1), selected.get(2)],
            "latest": sortedCourses.slice(0, 3),
            "insights": insightsNoCourses.slice(5, 9),
            "mostPopular": fromJS(popular),
            //"categoryArticles": fromJS(categoryArticles),
            "isLoading": false,
        });
        this.props.trackViewLearnParentJourneys();
        if (this.props.searchMade && this.props.searchTerm) {
            this.setState({
                "searchMade": this.props.searchMade,
                "term": this.props.searchTerm
            })
        }
    };

    getChildCategories = async (o) => {
        return await this.props.tryGetInsights(o.id, 8)
    }

    viewCategory = async (category) => {
        //const categoryCourses = await this.props.insights.filter(i => return (i.get()))
        await this.setState({"category": category});
    }

    sendTo = async () => {
        await this.props.insightParentCategorySelected(this.state.category);
        if (this.state.selectedSubcategory !== null) {
            await this.props.insightSubCategorySelected(this.state.selectedSubcategory)
        } else {
            await this.props.insightSubCategorySelected(null)
        }
        // console.log("subcats", this.props.subCategories)
        // console.log("subcats2", this.props.insightSubCategorySelected(this.state.selectedSubcategory))
        //return this.props.history.push('/learn/insights/details');
        return this.props.history.push('/learn/content');
    };

    handleChange = async (e) => {
        this.setState({"term": e.target.value})
    }

    handleReturnResult = async (e) => {
        if(this.props.location.pathname !== '/learn/search') {
            this.props.history.push('/learn/search');
        }
        this.setState({'searchMade': true});
        await this.props.trySearchInsights(this.state.term);
    };

    handleTagSearched = async (tag) => {
        await this.searchMade(tag);
        await this.handleReturnResult(tag);
        this.setState({"tagSearched": true});
    }

    searchMade = async (e) => {
        this.setState({'term': e});
        await this.props.setInsightsSearchTerm(e);
    };

    handleCloseSearch = async (trigger) => {
        if(trigger === "clicked") {
            await this.setState({"tagSearched": false});
            await this.setState({"haltUpdateSearch": true});
        }
        if(this.props.location.pathname !== '/learn') {
            this.props.history.push('/learn');
        }
        await this.setState({'searchInput': "", 'searchMade': false, 'searchResults': [], 'term': ""});
        if(trigger === "clicked") {
            await this.setState({"haltUpdateSearch": false});
        } else {
            document.getElementById("search-insights").select();
        }
    };

    createSearchInput = async () => {
        this.setState({
            'searchInput': <SearchInputUncontrolled
                options={[]}
                id={'search-insights'}
                placeholder={this.state.term != null ? this.state.term :'Search'}
                value={this.state.term}
                shouldShowResults={false}
                onReturnResults={this.handleReturnResult}
                onSearchMade={this.searchMade}
                searchIconOnClick={true}
            />
        })
    };

    viewCourse = async (course) => {
        // if(this.state.tagSearched || (this.state.searchMade && this.state.term !== "")) {
            const mainCategory = this.state.options.filter(f => {return (f.id === course.get("categories").get(0).get("parentCategoryIds").get(0))})
            await this.props.insightParentCategorySelected(mainCategory[0]);
        // }
        if(course.get("isReadingMaterial")) {
            if(course.get("isVideo")) {
                return this.props.history.push("/learn/video/"+course.get("id"))
            } else {
                return this.props.history.push("/learn/article/"+course.get("id"))
            }
        } else {
            return this.props.history.push("/learn/course/"+course.get("id"))
        }
    };

    chooseCategory = async (a, index) => {
        let subcategories = await this.getSubcategories(a.id);
        let categoryArticles = await this.getChildCategories(a)
        await this.setState({'category': a, 'categoryIndex': index, "categoryArticles": fromJS(categoryArticles), "subcategories": subcategories})
    }

    chooseSubcategory = async (a) => {
        this.setState({"selectedSubcategory": a})
        console.log("ehllo")
        this.props.insightSubCategorySelected(this.state.selectedSubcategory)
        //this.props.trackViewLearnSubJourneys({"parentJourneyId": this.state.category.id, "parentJourneyTitle": this.state.category.title});
    }

    getSubcategories = async (id) => {
        let subcategories = await this.props.tryGetInsightSubCategories(id)
        return subcategories.childCategories
    };

    viewSubcategories = async (id) => {
        let subcategories = await this.getSubcategories(id)
        await this.setState({"showSubcategories": id, "subcategories": subcategories})
    };

    handleSubcategories = async (state) => {
        await this.setState({"category": state.category, "categoryIndex": state.categoryIndex, "selectedSubcategory": state.subcategory, "subcategories": state.subcategories})
        await this.props.insightSubCategorySelected(this.state.selectedSubcategory)
    }

    selectSubcategories = (article) => {
        const selected = article.get("categories").filter(i => {console.log(i.get("title") === this.state.selectedSubcategory.title); return (i.get("title") === this.state.selectedSubcategory.title)})
        if (selected.size > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            if(this.props.location.pathname === "/learn/bookmarks") {
                return(
                    <>
                        <ContainerTop>
                            <Header>
                                <BackSection style={{margin: '25px'}}>
                                    <Back onClick={() => {return this.props.history.push("/learn")}}>
                                        <ChevronLeftRight src={images.chevronLeft} alt={""} />
                                        <InlineBlock><FontBody16>Back</FontBody16></InlineBlock>
                                    </Back>
                                </BackSection>
                                <PageTitle style={{textAlign: 'left', margin: '5px 30px'}}>SAVED CONTENT</PageTitle>
                            </Header>
                        </ContainerTop>
                        <HeaderMobile style={{padding: '30px'}}>
                            <BackSection>
                                <Back onClick={() => {return this.handleCloseSearch("clicked")}}>
                                    <ChevronLeftRight src={images.chevronLeft} alt={""} />
                                    <InlineBlock><FontBody16>Back</FontBody16></InlineBlock>
                                </Back>
                            </BackSection>
                            <Extra54px></Extra54px>
                            <FlexRow>
                                <PageTitle>SAVED CONTENT</PageTitle>
                            </FlexRow>
                        </HeaderMobile>
                        <Extra54px />
                        <ListBookmarks/>
                    </>
                )
            } else {
            return (
                <div style={{backgroundColor: (this.state.category == null && !this.state.searchMade) ? colors.backgroundColor1 : colors.white, width: '100%'}}>
                    <Container style={{maxWidth: 'revert', gap: '10px', padding: '40px'}}>
                        {(this.state.searchMade && this.state.term !== "")
                        ?
                            <>
                                <Header>
                                    <BackSection>
                                        <Back onClick={() => {return this.handleCloseSearch("clicked")}}>
                                            <ChevronLeftRight src={images.chevronLeft} alt={""} />
                                            <InlineBlock><FontBody16>Back</FontBody16></InlineBlock>
                                        </Back>
                                    </BackSection>
                                    <Extra54px></Extra54px>
                                    <FlexRow>
                                        <PageTitle>SEARCH RESULTS</PageTitle>
                                    </FlexRow>
                                    <Extra54px></Extra54px>
                                    <SearchInputUncontrolled
                                        change={this.handleChange}
                                        onReturnResults={this.handleReturnResult}
                                        onSearchMade={this.searchMade}    
                                        value={this.state.term}
                                        options={[]}
                                        id={'search-insights'}
                                        >
                                    </SearchInputUncontrolled>
                                </Header>
                                <HeaderMobile>
                                    <BackSection>
                                        <Back onClick={() => {return this.handleCloseSearch("clicked")}}>
                                            <ChevronLeftRight src={images.chevronLeft} alt={""} />
                                            <InlineBlock><FontBody16>Back</FontBody16></InlineBlock>
                                        </Back>
                                    </BackSection>
                                    <Extra54px></Extra54px>
                                    <FlexRow>
                                        <PageTitle>SEARCH RESULTS</PageTitle>
                                    </FlexRow>
                                    <Extra54px></Extra54px>
                                    <SearchInputUncontrolled
                                        change={this.handleChange}
                                        onReturnResults={this.handleReturnResult}
                                        onSearchMade={this.searchMade}    
                                        value={this.state.term}
                                        options={[]}
                                        id={'search-insights'}
                                        >
                                    </SearchInputUncontrolled>
                                </HeaderMobile>
                                <Extra54px></Extra54px>
                                {this.props.searchResults.size > 0 
                                    ?
                                    <>
                                        {this.props.searchResults.map((c, index) => {
                                            return (
                                                <SmallCardRow
                                                    key={index} active={true}
                                                    onClick={() => {return this.viewCourse(c)}}
                                                    even={index % 2 !== 0}
                                                >
                                                    <SmallCardRowImage src={c.get("imageUrl")} alt={""}/>
                                                    <OpenLink active={true}><FontHeader16>{c.get("title")}</FontHeader16></OpenLink>
                                                </SmallCardRow>
                                            )
                                        })}
                                    </>
                                    :
                                    <>
                                        <SpaceBetween direction={"column"}>
                                            <FontTitle16>No content found</FontTitle16>
                                            <div onClick={() => {return this.props.history.push('/learn/request')}}>
                                                <ButtonPrimary submit={false} label={"Request content"} />
                                            </div>
                                        </SpaceBetween>
                                    </>
                                }
                                <SpaceBetween direction={"row"} style={{marginTop: '15px'}}>
                                    <FontTitle16>Looking for other content?</FontTitle16>
                                    <WriteToUsBtn onClick={() => {return this.props.history.push('/learn/request')}}>
                                        <ButtonPrimary submit={false} label={"Write to us"} />
                                    </WriteToUsBtn>
                                </SpaceBetween>
                            </>
                        :
                            <>
                                <Header>
                                    <Extra54px></Extra54px>
                                    <FlexRow>
                                        <PageTitle>LEARNING CENTER</PageTitle>
                                        <SearchInputUncontrolled
                                            change={this.handleChange}
                                            onReturnResults={this.handleReturnResult}
                                            onSearchMade={this.searchMade}    
                                            value={this.state.term}
                                            options={[]}
                                            id={'search-insights'}
                                        >
                                        </SearchInputUncontrolled>
                                    </FlexRow>
                                    <PageSubtitle>Self-Serve Content</PageSubtitle>
                                    <NavMenuDesktop>
                                        <div
                                        >
                                            <NavMenuItem hoverColor={this.state.category == null ? colors.white : colors.action100}><NavMenuItemText background={this.state.categoryIndex == null && colors.action100} onClick={() => {return this.setState({'category': null, 'categoryIndex': null, 'selectedSubcategory': null})}} style={{margin: '0px 7px', padding: '0px 7px'}}>FEATURED</NavMenuItemText></NavMenuItem>
                                        </div>
                                        {this.state.options.map((a, index) => {
                                            return(
                                                <div
                                                    key={index}
                                                    style={{display: 'flex', flexDirection: 'row'}}
                                                >
                                                    <NavMenuItem 
                                                        style={{borderLeft: `1px solid ${colors.action100}`}}
                                                        hoverColor={colors.action100}
                                                        onMouseEnter={() => {this.viewSubcategories(a.id)}}
                                                        onMouseLeave={() => {this.setState({'showSubcategories': null, "subcategories": null})}}
                                                        onClick={() => {this.chooseCategory(a, index)}}>
                                                            <NavMenuItemText background={this.state.categoryIndex == index && '#EADDD9'} 
                                                                             style={{margin: '0px 7px', padding: '0px 7px'}}>
                                                                                {a.title.toUpperCase()}
                                                            </NavMenuItemText>
                                                            {(this.state.showSubcategories == a.id) &&
                                                                <HoverDropdown>
                                                                    {this.state.subcategories.map((i, index) => {
                                                                        return (
                                                                            <>
                                                                                {index > 0 &&
                                                                                    <DropdownDivider></DropdownDivider>
                                                                                }
                                                                                <DropdownItem onClick={() => {console.log("dropdown"); this.chooseSubcategory(i)}}><FontBody16>{i.title}</FontBody16></DropdownItem>
                                                                            </>
                                                                        );
                                                                    })}
                                                                </HoverDropdown>
                                                            }
                                                    </NavMenuItem>
                                                </div>
                                            )
                                        })}
                                    </NavMenuDesktop>
                                    <Tags style={{justifyContent: 'flex-end'}}>
                                        <Tag style={{backgroundColor: 'white', color:'black'}} onClick={() => {this.props.history.push("/learn/bookmarks")}}><BookmarkIcon src={images.BookmarksActive} alt={""} />Saved</Tag>
                                    </Tags>    
                                </Header>
                                <HeaderMobile>
                                    <PageTitle>LEARNING CENTER</PageTitle>
                                    <PageSubtitle style={{margin: '10px auto 15px'}}>Self-Serve Content</PageSubtitle>
                                    <FlexRow>
                                        <SearchInputUncontrolled
                                            change={this.handleChange}
                                            onReturnResults={this.handleReturnResult}
                                            onSearchMade={this.searchMade}    
                                            value={this.state.term}
                                            options={[]}
                                            id={'search-insights'}
                                            >
                                        </SearchInputUncontrolled>
                                        <Tag style={{backgroundColor: 'white', color:'black', width: 'fit-content', marginLeft: '5px'}} onClick={() => {this.props.history.push("/learn/bookmarks")}}><BookmarkIcon src={images.BookmarksActive} alt={""} /></Tag>
                                    </FlexRow>
                                    <MobileIcon style={{margin: '10px'}}>
                                    <MenuOutlined onClick={() => {return this.setState({'showMobileMenu': !this.state.showMobileMenu})}}/>
                                    {this.state.showMobileMenu &&
                                        <HoverDropdown style={{margin: 'auto', borderRadius: '25px'}}>
                                            <DropdownItem 
                                                onClick={() => {return this.setState({'category': null, 'categoryIndex': null, 'subcategory': null})}}
                                                style={{margin: '10px'}}
                                            >
                                                <FontHeader21>Featured</FontHeader21>
                                            </DropdownItem>
                                            {this.state.options.map((a, index) => {
                                                return(
                                                    <>
                                                    <DropdownDivider></DropdownDivider>
                                                    <div
                                                        key={index}
                                                        onClick={() => {this.chooseCategory(a, index)}}
                                                        style={{margin: '10px'}}
                                                    >
                                                        <DropdownItem><FontHeader21>{a.title}</FontHeader21></DropdownItem>
                                                    </div>
                                                    </>
                                                )
                                            })}
                                        </HoverDropdown>
                                    }
                                </MobileIcon>
                                </HeaderMobile>
                                {this.state.category != null
                                ?
                                    <>
                                        <SelectedContent
                                            category={this.state.category}
                                            categoryIndex={this.state.categoryIndex}
                                            courses={this.state.categoryArticles}
                                            popular={this.state.popularByCategory}
                                            subcategory={this.state.selectedSubcategory}
                                            subcategories={this.state.subcategories}
                                            subArticles={this.state.subArticles}
                                            handler={this.handleSubcategories}
                                            sendTo={this.sendTo}
                                            colors={colors.learnCenterBackgrounds}
                                        >
                                        </SelectedContent>
                                    </>
                                :
                                    <>
                                        <ArticleBox style={{backgroundColor: 'white'}}>
                                            <AllContent>
                                                <FeaturedMaterial>
                                                    <FontTitle24 style={{marginBottom: '10px'}}>Featured</FontTitle24>
                                                    <CenterHighlighted>
                                                        <ContentHighlighted data={this.state.highlighted[0].toJS()}/>
                                                        <ContentHighlighted data={this.state.highlighted[1].toJS()}/>
                                                        <ContentHighlighted data={this.state.highlighted[2].toJS()}/>
                                                    </CenterHighlighted>
                                                </FeaturedMaterial>
                                                <FeaturedMaterial>
                                                    <FontTitle24>Most Searched</FontTitle24>
                                                    <Tags style={{paddingRight: '20px'}}>
                                                        <Tag style={{backgroundColor: colors.action100}} onClick={() => {this.handleTagSearched("LGBTQ+")}}>LGBTQ+</Tag>
                                                        <Tag style={{backgroundColor: colors.action100}} onClick={() => {this.handleTagSearched("NextGen")}}>NextGen</Tag>
                                                    </Tags>
                                                    <FeaturedMaterialDivider></FeaturedMaterialDivider>
                                                    {/*<LatestSection>
                                                        <FontTitle21>Latest from Willow</FontTitle21>
                                                        {this.state.latest.map((a, i) => {
                                                            return (
                                                                <HoverLink key={i} style={{cursor: 'pointer'}}><br /><FontBody16 onClick={() => {return this.viewCourse(a)}}>{a.get("title")}</FontBody16></HoverLink>
                                                            )
                                                        })}
                                                        <br />
                                                    </LatestSection>*/}
                                                    <SmallFeatured>
                                                        <FontTitle24 style={{textAlign: 'left'}}>Most Recent From Willow</FontTitle24>
                                                        <SmallFeaturedArticle data={this.state.latest.get(0).toJS()}/>
                                                        <SmallFeaturedArticle data={this.state.latest.get(1).toJS()}/>
                                                        <SmallFeaturedArticle data={this.state.latest.get(2).toJS()}/>
                                                    </SmallFeatured>
                                                    <FeaturedMaterialDivider></FeaturedMaterialDivider>
                                                    {/*<div style={{width: 'fit-content', margin: '0 auto'}}>
                                                        <FontTitle21>Most Popular</FontTitle21>
                                                        {
                                                            this.state.mostPopular.map((m, i) => {
                                                                return (
                                                                    <HoverLink key={i} style={{cursor: 'pointer'}}><br /><FontBody18 onClick={() => {return this.viewCourse(m)}}>{(i + 1) + ". " + m.get("title")}</FontBody18></HoverLink>
                                                                    
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <br />
                                                    <br />*/}
                                                    {/*<div>
                                                        {this.state.options.map((p, i) => {
                                                            return (
                                                                <div key={i}>
                                                                    <hr />
                                                                    <br />
                                                                    <br />
                                                                    <PageTitle spaced={true} onClick={() => {return this.sendTo(p)}} style={{cursor: 'pointer'}}>{p.title.toUpperCase()}</PageTitle>
                                                                    <br />
                                                                    <ContentOverviewStyles viewCourse={this.viewCourse} style={i} data={this.state.categoryArticles.get(i)} title={p.title} seeMore={() => {return this.sendTo(p)}}/>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>*/}
                                                    <SmallFeatured>
                                                        <FontTitle24 style={{textAlign: 'left'}}>Most Popular</FontTitle24>
                                                        <SmallFeaturedArticle data={this.state.mostPopular.get(0).toJS()}/>
                                                        <SmallFeaturedArticle data={this.state.mostPopular.get(1).toJS()}/>
                                                        <SmallFeaturedArticle data={this.state.mostPopular.get(2).toJS()}/>
                                                    </SmallFeatured>
                                                </FeaturedMaterial>
                                            </AllContent>
                                        </ArticleBox>
                                    </>
                                }
                                {/*<Tags>
                                    <Tag onClick={() => {this.handleTagSearched("LGBTQ+")}}>LGBTQ+</Tag>
                                    <Tag onClick={() => {this.handleTagSearched("NextGen")}}>NextGen</Tag>
                                    <Tag onClick={() => {this.props.history.push("/learn/bookmarks")}}><BookmarkIcon src={images.progressBookmarksInactive} alt={""} /> Bookmarks</Tag>
                                </Tags>*/}
                            </>
                        }
                    </Container>
                </div>
            )
            }
        }
    }
}

const mapStateToProps = state => ({
    insightParentCategories: state.consumer.learn.insights.get("insightParentCategories"),
    searchResults: state.consumer.learn.insights.get("insightsSearchResults"),
    subCategories: state.consumer.learn.insights.get("insightSubCategories"),
    insights: state.consumer.learn.insights.get("insights")
});

const mapDispatchToProps = dispatch => ({
    tryGetInsightParentCategories: () => dispatch(tryGetInsightParentCategories()),
    insightParentCategorySelected: (category) => dispatch(insightParentCategorySelected(category)),
    insightSubCategorySelected: (category) => dispatch(insightSubCategorySelected(category)),
    trySearchInsights: (term) => dispatch(trySearchInsights(term)),
    setInsightsSearchTerm: (term) => dispatch(setInsightsSearchTerm(term)),
    trackViewLearnParentJourneys: () => dispatch(trackViewLearnParentJourneys()),
    trackViewLearnSubJourneys: (contentDetails) => dispatch(trackViewLearnSubJourneys(contentDetails)),
    tryGetInsightSubCategories: (id) => dispatch(tryGetInsightSubCategories(id)),
    tryGetInsights: (id, limit) => dispatch(tryGetInsights(id, limit)),
    tryGetAllInsights: (limit) => dispatch(tryGetAllInsights(limit)),
    tryGetMostPopular: (limit) => dispatch(tryGetMostPopular(limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InsightCategories));