import React from 'react';

/* Containers */
import ConsumerNavSide from './navside';
import ConsumerNavBottom from './navbottom';
import ConsumerNavTop from "./navtop";
import styled from "styled-components";
import ConsumerNavBottomUpdated from "./navbottomupdated";
const Container = styled.div`
`;
const ConsumerNav = ({hideBottomNav=false}) => (
    <Container>
        {/*<ConsumerNavSide />*/}
        {!hideBottomNav && <ConsumerNavBottom />}
        {/*{!hideBottomNav && <ConsumerNavBottomUpdated />}*/}
        <ConsumerNavTop />
    </Container>
)

export default ConsumerNav;