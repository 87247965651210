
export const devCoachRatingClientNotice = {
    "unrated": [
        {
            "token":"Efvg&7i-7iJSJghYuagd_fG-30",
            "clientid":50,
            "clientName":"Jane Fonda",
            "datetime":"2020-10-18T12:30:00+00:00"
        },
        {
            "token":"Abcdsfsdaf",
            "clientid":214,
            "clientName":"Laura Sanders",
            "datetime":"2020-10-18T12:30:00+00:00"
        },
        {
            "token":"dsfgfvnbvcbfgd",
            "clientid":24,
            "clientName":"Kate Becket",
            "datetime":"2020-10-18T12:30:00+00:00"
        }
    ]
};

export const devClientRatingCoachNotice = {
    "token":"Efvg&7i-7iJSJghYuagd_fG-30",
    "coachId":50,
    "coachName":"Jane Fonda",
    "datetime":"2020-06-02T12:30:00+00:00"
};
