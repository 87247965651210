import styled from "styled-components";
import {colors} from "./colors";

export const CommonBackground = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.50);
    @media screen and (max-width: 800px) {
        background-color: ${colors.white};
    }
    z-index: 20;
`;

export const CommonModal = styled.div`
    margin: 0px auto;
    width: 748px;
    padding: 30px;
    border-radius: 6px;
    background-color: ${colors.white};
    position: fixed;
    top: 100px;
    left: 0px;
    right: 0px;
    @media screen and (max-width: 800px) {
        width: 100%;
        padding: 20px 0px;
        top: 0px;
        bottom: 0px;
        overflow-y: auto;
    }
    z-index: 21;
`;

export const TallBackground = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.50);
    @media screen and (max-width: 450px) {
        background-color: ${colors.white};
    }
    z-index: 20;
`;

export const TallModal = styled.div`
    margin: 0px auto;
    width: 748px;
    padding: 30px;
    border-radius: 6px;
    background-color: ${colors.white};
    position: fixed;
    top: 50px;
    left: 0px;
    right: 0px;
    @media screen and (max-width: 800px) {
        width: 428px;
    }
    @media screen and (max-width: 450px) {
        width: 100%;
        padding: 20px 0px;
        top: 0px;
        bottom: 0px;
        overflow-y: auto;
    }
    z-index: 21;
`;