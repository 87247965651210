import { Map, List, fromJS } from 'immutable';

/* Immutable */

const SETTINGS_ENTERPRISE_TERMS = 'SETTINGS_ENTERPRISE_TERMS';
const SETTINGS_ENTERPRISE_TERMS_READ = 'SETTINGS_ENTERPRISE_TERMS_READ';
const SETTINGS_ENTERPRISE_TERMS_UPDATE = 'SETTINGS_ENTERPRISE_TERMS_UPDATE';
const SETTINGS_ENTERPRISE_VACATION = 'SETTINGS_ENTERPRISE_VACATION';
const SETTINGS_ENTERPRISE_VACATION_READ = 'SETTINGS_ENTERPRISE_VACATION_READ';
const SETTINGS_ENTERPRISE_VACATION_UPDATE = 'SETTINGS_ENTERPRISE_VACATION_UPDATE';
const SETTINGS_ENTERPRISE_CALENDAR_SETUP = 'SETTINGS_ENTERPRISE_CALENDAR_SETUP';
const SETTINGS_ENTERPRISE_CALENDAR_SYNC = 'SETTINGS_ENTERPRISE_CALENDAR_SYNC';
const SETTINGS_ENTERPRISE_CALENDAR_SYNC_READ = 'SETTINGS_ENTERPRISE_CALENDAR_SYNC_READ';
const SETTINGS_ENTERPRISE_CALENDAR_SYNC_UPDATE = 'SETTINGS_ENTERPRISE_CALENDAR_SYNC_UPDATE';
const SETTINGS_ENTERPRISE_CALENDAR_SYNC_SCHEDULE = 'SETTINGS_ENTERPRISE_CALENDAR_SYNC_SCHEDULE';
const SETTINGS_ENTERPRISE_CALENDAR_SYNC_SCHEDULE_READ = 'SETTINGS_ENTERPRISE_CALENDAR_SYNC_SCHEDULE_READ';
const SETTINGS_ENTERPRISE_CALENDAR_SYNC_SCHEDULE_UPDATE = 'SETTINGS_ENTERPRISE_CALENDAR_SYNC_SCHEDULE_UPDATE';
const SETTINGS_ENTERPRISE_NOTIFICATIONS = 'SETTINGS_ENTERPRISE_NOTIFICATIONS';
const SETTINGS_ENTERPRISE_NOTIFICATIONS_READ = 'SETTINGS_ENTERPRISE_NOTIFICATIONS_READ';
const SETTINGS_ENTERPRISE_NOTIFICATIONS_UPDATE = 'SETTINGS_ENTERPRISE_NOTIFICATIONS_UPDATE';
const SETTINGS_ENTERPRISE_BANK = 'SETTINGS_ENTERPRISE_BANK';
const SETTINGS_ENTERPRISE_BANK_READ = 'SETTINGS_ENTERPRISE_BANK_READ';
const SETTINGS_ENTERPRISE_BANK_UPDATE = 'SETTINGS_ENTERPRISE_BANK_UPDATE';
const SETTINGS_ENTERPRISE_CLIENTS = 'SETTINGS_ENTERPRISE_CLIENTS';
const SETTINGS_ENTERPRISE_CLIENTS_READ = 'SETTINGS_ENTERPRISE_CLIENTS_READ';
const SETTINGS_ENTERPRISE_CLIENTS_UPDATE = 'SETTINGS_ENTERPRISE_CLIENTS_UPDATE';
const SETTINGS_ENTERPRISE_STORE_RESET = 'SETTINGS_ENTERPRISE_STORE_RESET';

/* Actions */

export const setSettingsEnterpriseTerms = (terms) => ({'type': SETTINGS_ENTERPRISE_TERMS, 'terms': terms});
export const setSettingsEnterpriseTermsReading = (status) => ({'type': SETTINGS_ENTERPRISE_TERMS_READ, 'status': status});
export const setSettingsEnterpriseTermsUpdating = (status) => ({'type': SETTINGS_ENTERPRISE_TERMS_UPDATE, 'status': status});
export const setSettingsEnterpriseVacation = (vacation) => ({'type': SETTINGS_ENTERPRISE_VACATION, 'vacation': vacation});
export const setSettingsEnterpriseVacationReading = (status) => ({'type': SETTINGS_ENTERPRISE_VACATION_READ, 'status': status});
export const setSettingsEnterpriseVacationUpdating = (status) => ({'type': SETTINGS_ENTERPRISE_VACATION_UPDATE, 'status': status});
export const setSettingsEnterpriseCalendarSetup = (setup) => ({'type': SETTINGS_ENTERPRISE_CALENDAR_SETUP, 'setup': setup});
export const setSettingsEnterpriseCalendarSync = (syncMethod) => ({'type': SETTINGS_ENTERPRISE_CALENDAR_SYNC, 'syncMethod': syncMethod});
export const setSettingsEnterpriseCalendarSyncReading = (status) => ({'type': SETTINGS_ENTERPRISE_CALENDAR_SYNC_READ, 'status': status});
export const setSettingsEnterpriseCalendarSyncUpdating = (status) => ({'type': SETTINGS_ENTERPRISE_CALENDAR_SYNC_UPDATE, 'status': status});
export const setSettingsEnterpriseCalendarSyncSchedule = (schedule) => ({'type': SETTINGS_ENTERPRISE_CALENDAR_SYNC_SCHEDULE, 'schedule': schedule});
export const setSettingsEnterpriseCalendarSyncScheduleReading = (status) => ({'type': SETTINGS_ENTERPRISE_CALENDAR_SYNC_SCHEDULE_READ, 'status': status});
export const setSettingsEnterpriseCalendarSyncScheduleUpdating = (status) => ({'type': SETTINGS_ENTERPRISE_CALENDAR_SYNC_SCHEDULE_UPDATE, 'status': status});
export const setSettingsEnterpriseNotifications = (notifications) => ({'type': SETTINGS_ENTERPRISE_NOTIFICATIONS, 'notifications': notifications});
export const setSettingsEnterpriseNotificationsReading = (status) => ({'type': SETTINGS_ENTERPRISE_NOTIFICATIONS_READ, 'status': status});
export const setSettingsEnterpriseNotificationsUpdating = (status) => ({'type': SETTINGS_ENTERPRISE_NOTIFICATIONS_UPDATE, 'status': status});
export const setSettingsEnterpriseBank = (bank) => ({'type': SETTINGS_ENTERPRISE_BANK, 'bank': bank});
export const setSettingsEnterpriseBankReading = (status) => ({'type': SETTINGS_ENTERPRISE_BANK_READ, 'status': status});
export const setSettingsEnterpriseBankUpdating = (status) => ({'type': SETTINGS_ENTERPRISE_BANK_UPDATE, 'status': status});
export const setSettingsEnterpriseClients = (clients) => ({'type': SETTINGS_ENTERPRISE_CLIENTS, 'clients': clients});
export const setSettingsEnterpriseClientsReading = (status) => ({'type': SETTINGS_ENTERPRISE_CLIENTS_READ, 'status': status});
export const setSettingsEnterpriseClientsUpdating = (status) => ({'type': SETTINGS_ENTERPRISE_CLIENTS_UPDATE, 'status': status});
export const setSettingsEnterpriseStoreReset = () => ({'type': SETTINGS_ENTERPRISE_STORE_RESET});

/* Initial State */

const initialState = Map({
    settingsEnterpriseTerms: Map({rate: '', maxClients: '', error: ''}),
    settingsEnterpriseTermsReading: false,
    settingsEnterpriseTermsUpdating: false,
    settingsEnterpriseVacation: Map({vacation: false, error: ''}),
    settingsEnterpriseVacationReading: false,
    settingsEnterpriseVacationUpdating: false,
    settingsEnterpriseNotifications: Map({emailAllowed: false, smsAllowed: false, error: ''}),
    settingsEnterpriseNotificationsReading: false,
    settingsEnterpriseNotificationsUpdating: false,
    settingsEnterpriseBank: Map({name: '', routing: '', account: '', type: '', error: ''}),
    settingsEnterpriseBankReading: false,
    settingsEnterpriseBankUpdating: false,
    settingsEnterpriseClients: Map({
        "journey": List([
            Map({"id": 1, "question": "Going through a divorce", "specialist": false, "comfortable": false, "personal": false, "exclude": false}),
            Map({"id": 2, "question": "A widow", "specialist": false, "comfortable": false, "personal": false, "exclude": false}),
            Map({"id": 3, "question": "Caregiver for aging loved ones", "specialist": false, "comfortable": false, "personal": false, "exclude": false}),
            Map({"id": 4, "question": "In charge of my family finances", "specialist": false, "comfortable": false, "personal": false, "exclude": false}),
            Map({"id": 5, "question": "A single parent", "specialist": false, "comfortable": false, "personal": false, "exclude": false}),
            Map({"id": 6, "question": "Navigating my career", "specialist": false, "comfortable": false, "personal": false, "exclude": false}),
            Map({"id": 7, "question": "Starting out financially", "specialist": false, "comfortable": false, "personal": false, "exclude": false})
        ]),
        "goal": List([
            Map({"id": 1, "question": "Organizing my finances", "specialist": false, "comfortable": false, "personal": false, "exclude": false}),
            Map({"id": 2, "question": "Budgeting and paying bills", "specialist": false, "comfortable": false, "personal": false, "exclude": false}),
            Map({"id": 3, "question": "Improve my credit score", "specialist": false, "comfortable": false, "personal": false, "exclude": false}),
            Map({"id": 4, "question": "Reducing debt", "specialist": false, "comfortable": false, "personal": false, "exclude": false}),
            Map({"id": 5, "question": "Easing financial stress and anxiety", "specialist": false, "comfortable": false, "personal": false, "exclude": false}),
            Map({"id": 6, "question": "Planning for after I'm gone", "specialist": false, "comfortable": false, "personal": false, "exclude": false}),
            Map({"id": 7, "question": "Workplace benefits and insurance", "specialist": false, "comfortable": false, "personal": false, "exclude": false}),
            Map({"id": 8, "question": "Saving for education and future expenses", "specialist": false, "comfortable": false, "personal": false, "exclude": false}),
            Map({"id": 9, "question": "Improving or changing my career", "specialist": false, "comfortable": false, "personal": false, "exclude": false}),
            Map({"id": 10, "question": "Considering a real estate purchase or rental", "specialist": false, "comfortable": false, "personal": false, "exclude": false}),
            Map({"id": 11, "question": "Changing finances in a relationship", "specialist": false, "comfortable": false, "personal": false, "exclude": false})
        ]),
        "approach": List([
            Map({"id": 1, "question": "Understands the life of a military family", "resonates": false, "exclude": false}),
            Map({"id": 2, "question": "Integrates spirituality into coaching", "resonates": false, "exclude": false}),
            Map({"id": 3, "question": "Has a no-nonsense approach", "resonates": false, "exclude": false}),
            Map({"id": 4, "question": "Knows what its like to be part of a minority community", "resonates": false, "exclude": false}),
            Map({"id": 5, "question": "Emphasizes the emotional aspects of personal finance", "resonates": false, "exclude": false})
        ]),
        "errorJourney": '',
        "errorGoal": '',
        "errorApproach": ''
    }),
    settingsEnterpriseClientsReading: false,
    settingsEnterpriseClientsUpdating: false,
    settingsEnterpriseCalendarSetup: false,
    settingsEnterpriseCalendarSync: Map({availability_calendar_update_method: "willow", error: ''}),
    settingsEnterpriseCalendarSyncReading: false,
    settingsEnterpriseCalendarSyncUpdating: false,
    settingsEnterpriseCalendarSyncSchedule: Map({workSchedule: List(), error: ''}),
    settingsEnterpriseCalendarSyncScheduleReading: false,
    settingsEnterpriseCalendarSyncScheduleUpdating: false
});

/* Reducer */

const settingsEnterpriseReducer = (state=initialState, action) => {
    switch (action.type) {
        case SETTINGS_ENTERPRISE_TERMS:
            return state.merge({'settingsEnterpriseTerms': fromJS(action.terms)});
        case SETTINGS_ENTERPRISE_TERMS_READ:
            return state.merge({'settingsEnterpriseTermsReading': action.status});
        case SETTINGS_ENTERPRISE_TERMS_UPDATE:
            return state.merge({'settingsEnterpriseTermsUpdating': action.status});
        case SETTINGS_ENTERPRISE_VACATION:
            return state.merge({'settingsEnterpriseVacation': fromJS(action.vacation)});
        case SETTINGS_ENTERPRISE_VACATION_READ:
            return state.merge({'settingsEnterpriseVacationReading': action.status});
        case SETTINGS_ENTERPRISE_VACATION_UPDATE:
            return state.merge({'settingsEnterpriseVacationUpdating': action.status});
        case SETTINGS_ENTERPRISE_CALENDAR_SETUP:
            return state.merge({'settingsEnterpriseCalendarSetup': action.setup});
        case SETTINGS_ENTERPRISE_CALENDAR_SYNC:
            return state.merge({'settingsEnterpriseCalendarSync': fromJS(action.syncMethod)});
        case SETTINGS_ENTERPRISE_CALENDAR_SYNC_READ:
            return state.merge({'settingsEnterpriseCalendarSyncReading': action.status});
        case SETTINGS_ENTERPRISE_CALENDAR_SYNC_UPDATE:
            return state.merge({'settingsEnterpriseCalendarSyncUpdating': action.status});
        case SETTINGS_ENTERPRISE_CALENDAR_SYNC_SCHEDULE:
            return state.merge({'settingsEnterpriseCalendarSyncSchedule': Map({"workSchedule": fromJS(action.schedule.workSchedule), "error": action.schedule.error})});
        case SETTINGS_ENTERPRISE_CALENDAR_SYNC_SCHEDULE_READ:
            return state.merge({'settingsEnterpriseCalendarSyncScheduleReading': action.status});
        case SETTINGS_ENTERPRISE_CALENDAR_SYNC_SCHEDULE_UPDATE:
            return state.merge({'settingsEnterpriseCalendarSyncScheduleUpdating': action.status});
        case SETTINGS_ENTERPRISE_NOTIFICATIONS:
            return state.merge({'settingsEnterpriseNotifications': fromJS(action.notifications)});
        case SETTINGS_ENTERPRISE_NOTIFICATIONS_READ:
            return state.merge({'settingsEnterpriseNotificationsReading': action.status});
        case SETTINGS_ENTERPRISE_NOTIFICATIONS_UPDATE:
            return state.merge({'settingsEnterpriseNotificationsUpdating': action.status});
        case SETTINGS_ENTERPRISE_BANK:
            return state.merge({'settingsEnterpriseBank': fromJS(action.bank)});
        case SETTINGS_ENTERPRISE_BANK_READ:
            return state.merge({'settingsEnterpriseBankReading': action.status});
        case SETTINGS_ENTERPRISE_BANK_UPDATE:
            return state.merge({'settingsEnterpriseBankUpdating': action.status});
        case SETTINGS_ENTERPRISE_CLIENTS:
            return state.merge({'settingsEnterpriseClients': fromJS(action.clients)});
        case SETTINGS_ENTERPRISE_CLIENTS_READ:
            return state.merge({'settingsEnterpriseClientsReading': action.status});
        case SETTINGS_ENTERPRISE_CLIENTS_UPDATE:
            return state.merge({'settingsEnterpriseClientsUpdating': action.status});
        case SETTINGS_ENTERPRISE_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default settingsEnterpriseReducer;
