import { combineReducers } from 'redux';

/* Common */
import messagesReducer from "./store/emailer";

const emailerReducer = combineReducers({
    messages: messagesReducer
});

export default emailerReducer;
