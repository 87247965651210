import React from "react";
import styled from "styled-components";
import moment from 'moment/moment';

/* Components */
import {FontBody16, FontTitle30} from "../../../common/components/fonts";
import {ButtonPrimary, ButtonLinkInactive} from "../../../common/components/buttons";
import {ArrowLeftOutlined, CloseOutlined} from "@ant-design/icons";

/* Static */
import {images} from "../../../common/components/images";
import {colors} from "../../../common/components/colors";

const Title = styled.div`
    margin-bottom: 15px;
`;

export const Center = styled.div`
    text-align: center;
    color: ${colors.primary100};
`;

export const SummaryIcon = styled.img`
    width: 100px;
    margin: 20px auto;
    height: 100px;
`;

export const SummaryTitle = styled.div`
    padding-bottom: 30px;
`;
export const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;

export const FitContent = styled.div`
    width: fit-content;
    cursor: pointer;
    margin: 0px auto;
`;

const GrayDark = styled.div`
    color: ${colors.secondary100};
    @media screen and (max-width: 460px) {
        margin-top: 20px;
        margin-bottom: 10px;
    }
`;

const BackTop = styled.div`
    color: ${colors.secondary70};
    width: fit-content;
    padding: 5px 10px;
    display: block;
    cursor: pointer;
    position: relative;
    z-index: 25;
    @media screen and (max-width: 748px) {
        margin-left: 60px;
    }
    @media screen and (max-width: 460px) {
        margin-left: 0px;
    }
`;

const Confirm = ({onClose, onCloseBtn, reschedule, selectedTime, meetingLength, showCancel, cancel}) => (
    <Center>
        {onCloseBtn &&
            <BackTop onClick={onClose}><CloseOutlined/>&nbsp;Close</BackTop>
        }
        <SummaryIcon src={images.schedulerIconBooked}/>
        <SummaryTitle>
            <FontTitle30>Meeting Scheduled!</FontTitle30>
            <GrayDark><FontBody16>{moment(selectedTime).format("LLLL")} ({meetingLength}-minutes)</FontBody16></GrayDark>
        </SummaryTitle>
        <FitContent onClick={reschedule}>
            <ButtonPrimary submit={false} label={<><Inline><ArrowLeftOutlined /></Inline>&nbsp;Reschedule</>} />
        </FitContent>
        <br />
        {showCancel &&
            <>
                <FitContent onClick={cancel}>
                    <ButtonLinkInactive submit={false} label={"Cancel"}/>
                </FitContent>
                <br />
            </>
        }
        <br />
    </Center>
);

export default Confirm;