import { combineReducers } from 'redux';

/* Common */
import profileReducer from './store/profile';

const profilesReducer = combineReducers({
    profile: profileReducer,
});

export default profilesReducer;
