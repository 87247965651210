import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import moment from 'moment/moment'
import {colors} from "../../../../common/components/colors";
import {FontBody21To16, FontSubtitle16} from "../../../../common/components/fonts";
import {ButtonPrimary, ButtonTertiary} from "../../../../common/components/buttons";
import {setToolTipUpdate} from "../../tooltip/store/tooltip";
import {CloseIcon, useOnClickOutside} from "../../certification/containers/steplist";
import {Close} from "../../../shop/components/preview";

const ToolTipBody = styled.div`
  border-radius: 10px;
  background-color: ${colors.white};
  position: absolute;
  width: 300px;
  display: ${props => props.show ? 'flex' : 'none'};

  & div {
    max-width: 300px;
  }

  flex-direction: column;
  z-index: 11;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 21px solid transparent;
    border-top-color: ${colors.background30};;
    border-bottom: 0;
    border-left: 0;
    margin-left: -10.5px;
    margin-bottom: -21px;
  }

  ${FontSubtitle16} {
    align-self: center;
    margin: 20px 20px 5px 20px;
    width: calc(100% - 40px);
  }



`;


const StyledButtonPrimary = styled.div`
  div {
    line-height: 12px;
  }

  margin: 10px 30px;
`;
ToolTipCreator.defaultProps = {
    text: "",
    style: {},
    show: false,
    linkToBack: () => {
    },
    linkToNext: () => {
    },
}


export function ToolTipCreator(props) {
    const dispatch = useDispatch();
    const showToolTip = useSelector(state => state.enterprise.tooltip.get('showToolTip'));
    const toolTipPage = useSelector(state => state.enterprise.tooltip.get('toolTipPage'));
    const [showCheck, setShowCheck] = useState(false);
    const nextTooltip = () => {
        dispatch(setToolTipUpdate(true, toolTipPage + 1));
    }

    const ref = useRef(null);

    const backToolTip = () => {
        dispatch(setToolTipUpdate(true, toolTipPage - 1));
    }

    const exitToolTip = () => {
        dispatch(setToolTipUpdate(false, 1))
    }

    useOnClickOutside(ref, () => {
        setShowCheck(true)
    });


    return (

        <ToolTipBody style={props.style} show={props.show} ref={ref}>
            {!showCheck &&
                <>
                    <FontSubtitle16>{props.text}</FontSubtitle16>
                    <div style={{display: "flex", padding: "0 0 10px 0"}}>
                        <StyledButtonPrimary onClick={() => {
                            backToolTip();
                            props.linkToBack();
                        }}><ButtonTertiary label={"Back"} canSubmit={true}/></StyledButtonPrimary>
                        <StyledButtonPrimary onClick={() => {
                            nextTooltip();
                            props.linkToNext();
                        }}> <ButtonPrimary label={"Next"} canSubmit={true}/> </StyledButtonPrimary>
                    </div>
                </>}
            {showCheck &&
                <>
                    <FontSubtitle16>Would you like to continue the tutorial?</FontSubtitle16>
                    <div style={{display: "flex", padding: "0 0 10px 0"}}>
                        <StyledButtonPrimary onClick={() => {
                            exitToolTip();
                        }}><ButtonTertiary label={"No"} canSubmit={true}/></StyledButtonPrimary>
                        <StyledButtonPrimary onClick={() => {
                            setShowCheck(false)
                        }}> <ButtonPrimary label={"Yes"} canSubmit={true}/> </StyledButtonPrimary>
                    </div>
                </>
            }

        </ToolTipBody>
    );
}