import React from "react";
import Slider from "rc-slider";
import 'rc-slider/assets/index.css';

class ElementSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value ?? 0
        }
    }

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.elementId !== this.props.elementId) {
            return this.init();
        }
    }

    init = async () => {
        this.setState({
            "value": this.props.value
        })
    }

    sliderChange = async value => {
        let isValid = true;
        this.setState({
            "value": this.props.value
        })
        this.props.onSaveElement(this.props.elementId, {"value": value}, isValid)
    }

    render() {
        return <div style={this.props.containerStyle}>
            <Slider
                min={0}
                defaultValue={this.props.value}
                onAfterChange={this.sliderChange}
                handleStyle={this.props.handleStyle}
                trackStyle={this.props.trackStyle}
                railStyle={this.props.railStyle}
            />
        </div>
    }
}

export default ElementSlider;