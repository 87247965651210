import React from "react";
import { withRouter } from 'react-router-dom';

/* Components */
import {CourseImage, TitleBox, OneCourse} from "../components/bookmarkone";
import {FontBody16} from '../../../common/components/fonts';

class Bookmarksone extends React.Component {

    viewCourse = async () => {
        if(this.props.data.course.isReadingMaterial) {
            if(this.props.data.course.isVideo) {
                return this.props.history.push("/learn/video/" + this.props.data.course.id)
            } else {
                return this.props.history.push("/learn/article/" + this.props.data.course.id)
            }
        } else {
            return this.props.history.push("/learn/course/" + this.props.data.course.id)
        }
    };

    render() {
        return(
            <OneCourse onClick={this.viewCourse}>
                <CourseImage src={this.props.data.course.imageUrl} alt={""}/>
                <TitleBox>
                    <FontBody16>{this.props.data.course.title}</FontBody16>
                </TitleBox>
            </OneCourse>
        )
    }
}

export default withRouter(Bookmarksone);
