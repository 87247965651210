import { Map, fromJS, List } from 'immutable';

/* Immutable */

const WMC_CHECK = 'WMC_CHECK';
const WMC_DOWNLOADING = 'WMC_DOWNLOADING';
const WMC_FIRM = 'WMC_FIRM';
const WMC_FIRM_DOWNLOADING = 'WMC_FIRM_DOWNLOADING';
const WMC_FIRM_EMPLOYEES_DOWNLOADING = 'WMC_FIRM_EMPLOYEES_DOWNLOADING';
const WMC_FIRM_EMPLOYEES = 'WMC_FIRM_EMPLOYEES';
const WMC_STORE_RESET = 'WMC_STORE_RESET';

/* Actions */

export const setWMCCheck = (isWMC, wmc) => ({'type': WMC_CHECK, 'wmc': wmc, 'isWMC': isWMC});
export const setWMCDownloading = (status) => ({'type': WMC_DOWNLOADING, 'status': status});
export const setWMCFirmDownloading = (status) => ({'type': WMC_FIRM_DOWNLOADING, 'status': status});
export const setWMCFirm = (wmc) => ({'type': WMC_FIRM, 'wmc': wmc});
export const setWMCFirmEmployeesDownloading = (status) => ({'type': WMC_FIRM_EMPLOYEES_DOWNLOADING, 'status': status});
export const setWMCFirmEmployees = (employees) => ({'type': WMC_FIRM_EMPLOYEES, 'employees': employees});
export const setWMCStoreReset = () => ({'type': WMC_STORE_RESET});

/* Initial State */

const initialState = Map({
    wmc: Map(),
    isWMC: false,
    wmcDownloading: false,
    wmcFirmDownloading: false,
    wmcFirm: Map(),
    wmcFirmEmployees: Map({
        error: null,
        employees: List()
    }),
    wmcFirmEmployeesDownloading: false
});

/* Reducer */

const wmcReducer = (state=initialState, action) => {
    switch (action.type) {
        case WMC_CHECK:
            return state.merge({
                "isWMC": action.isWMC,
                "wmc": fromJS(action.wmc)
            });
        case WMC_FIRM:
            return state.merge({"wmcFirm": fromJS(action.wmc)});
        case WMC_FIRM_EMPLOYEES:
            return state.merge({"wmcFirmEmployees": Map({
                "error": action.employees.error,
                "employees": fromJS(action.employees.employees)
            })});
        case WMC_DOWNLOADING:
            return state.merge({"wmcDownloading": action.status});
        case WMC_FIRM_DOWNLOADING:
            return state.merge({"wmcFirmDownloading": action.status});
        case WMC_FIRM_EMPLOYEES_DOWNLOADING:
            return state.merge({"wmcFirmEmployeesDownloading": action.status});
        case WMC_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default wmcReducer;
