import styled from "styled-components";
import {colors} from "../../../common/components/colors";

export const NavTopMobile = styled.div`
    display: none;
    @media screen and (max-width: 1088px) {
        vertical-align: top;
        padding: 32px 32px 31px 32px;
        display: inline-block;
        cursor: pointer;
        margin-left: calc(100% - 335px);
    }
    @media screen and (max-width: 460px) {
        margin-left: calc(100% - 305px);
    }
`;

export const MenuIcon = styled.div`
    width: fit-content;
    display: inline-block;
    text-align: right;
    color: ${colors.action100};
`;

export const Title = styled.div`
    @media screen and (max-width: 748px) {
        display: inline-block;
        vertical-align: top;
        margin: 6px 0px;
        width: calc(100% - 35px);
    }
`;
