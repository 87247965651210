import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

/* Components */
import {FontBody16} from '../../../common/components/fonts';
import {colors} from '../../../common/components/colors';
import {LogoLoading} from "../../../common/components/loading";

/* Middleware */
import {tryGetExpertProfile} from '../middleware/expertprofile';
import {CheckOutlined, CloseCircleOutlined} from "@ant-design/icons";

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

const Red = styled.div`
    color: ${colors.alert100};
    display: inline-block;
    vertical-align: top;
`;

const Green = styled.div`
    color: ${colors.progress100};
    width: fit-content;
    display: inline-block;
    vertical-align: top;
`;

const GrayColor = styled.div`
    color: ${colors.primary70};
    display: inline-block;
    vertical-align: top;
`;

class SideBarPublishDisplay extends React.Component {
    state = {
        "isLoading": true,
        "active": false,
        "error": "",
    };

    componentDidMount() {
        return this.initiate();
    }

    initiate = async () => {
        await this.props.tryGetExpertProfile();
        await this.setState({
            "active": this.props.profile.get("profileActive"),
            "isLoading": false,
        });
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    <br />
                    {this.state.active
                        ? <><FontBody16><Green><CheckOutlined /></Green><GrayColor>&nbsp;Published</GrayColor></FontBody16></>
                        : <><FontBody16><Red><CloseCircleOutlined /></Red><GrayColor>&nbsp;Published</GrayColor></FontBody16></>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    profile: state.enterprise.profile.expertProfile.get("expertProfile")
});

const mapDispatchToProps = dispatch => ({
    tryGetExpertProfile: () => dispatch(tryGetExpertProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideBarPublishDisplay));
