import React from 'react';
import styled from 'styled-components';
import { Map } from 'immutable';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import moment from "moment-timezone";

/* Components */
import { RightOutlined } from '@ant-design/icons';
import { colors } from '../../../common/components/colors';
import { Container, CTA, WriteToUsBtn, GutterTitle, PageTitle, BackSection, Back, InlineBlock, SpaceBetween, Olive, ChevronLeftRight } from "../../learn/components/care";
import { FontBody16, FontBody18, FontSubtitle18, FontTitle16, FontSubtitle21, FontHeader18 } from '../../../common/components/fonts';
import { LogoLoading } from "../../../common/components/loading";
import { images } from "../../../common/components/images";
import { ButtonPrimary } from "../../../common/components/buttons";

/* Middleware */
import { tryGetAllPostsFromCategory } from '../middleware/forum';

/* Store */
import { setCommunityPostSelected, setSubcategorySelected, setCommunityForumSelected } from '../store/forum';

/* Containers */

//#region Utility
import { hexToRGB, brightness } from '../../../common/utils/colors';

import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const NavOptions = styled.div`
    padding: 20px 0;
    @media screen and (max-width: 460px) {
        width: calc(100% + 40px);
        margin-left: -20px;
    }
    a:not(:last-of-type) > div {
        border-bottom: 2px solid ${colors.border70};
    }
    a > div {
        color: ${colors.primary100};
        cursor: pointer;
        padding: 20px 20px 30px 20px;
    }
`;
const NavMenuItem = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
`;
const MemberListItem = styled.div`
    align-items: center;
    border: 2px solid ${colors.border100};
    cursor: pointer;
    display: grid;
    grid-column-gap: 15px;
    grid-template-columns: 0.5fr 4.5fr 0.5fr;
    padding: 20px;
`;
const MemberListItemNameTitle = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
`;
const ProfileIcon = styled.img`
    border-radius: 50%;
    height: 50px;
    width: 50px;
    object-fit: cover;
`;

const RightOutlined32 = styled(RightOutlined)`
    font-size: 32px;
    color: ${colors.primary30};
`;
const CareTeamInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 45px;
    justify-content: space-around;
    text-align: center;
`;
const MemberList = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 40px;
`;

const FlexRow = styled.div`
    display: flex;
    justify-content: left;
    gap: 15px;
    align-items: center;
`;

const CategoryOneContainer = styled(Container)`
    max-width: 750px;
`;


const ProfileImage = styled.img`
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 60px;
    width: 60px;
`;


const ProfileIconInitial = styled(FontHeader18)`
    background-color: ${colors.primary30};
    border-radius: 50%;
    color: ${colors.white};
    cursor: pointer;
    display: inline-block;
    height: 60px;
    line-height: 60px;
    text-align: center;
    vertical-align: middle;
    width: 60px;
`;

const ContainerTop = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding: 25px 20px;
    align-items: flex-start;
`;
const BookButton = styled.div`
    border-radius: 0px 0px 20px 20px;
    color: ${colors.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.backgroundColor3};
    cursor: pointer;
    padding: 20px;
    width: calc(100% - 44px);
    border: 2px solid ${props => props.backgroundColor};
    position: relative;
    & > div, & > span {
        z-index: 1;
    }
    &::before {
        content: "";
        position: absolute;
        background-color: transparent;
        top: -90px;
        height: 90px;
        width: 30px;
        right: 0px;
        border-top-left-radius: 60px;
        box-shadow: 0 -60px 0 0 ${colors.backgroundColor3};
        transform: rotate(180deg);
    }
`;
const PageSubtitle = styled(SpaceBetween)`
    justify-content: center;
`;
const CtaTitle = styled(FontTitle16)`
    color: ${colors.secondary100};
`;
const PostPreviewInner = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    gap: 15px;
    mask-image: linear-gradient(180deg,#000 70%,transparent);
`;
const Border = styled.div`
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
    border-radius: 20px;
`;
//#endregion


class ForumCategoryOne extends React.Component {
    static defaultProps = {
        backgroundColor: null
    };

    state = { step: 1, category: Map(), posts: [], postsShow: [], selectedPost: null, isLoading: true, forceSortPosts: false };

    componentDidMount() {
        this.init();
    }

    back = () => {
        this.props.setSubcategorySelected(null)
        this.props.history.push('/community')
    }

    viewPosts = async (subCategory) => {
        await this.props.tryGetAllPostsFromCategory(subCategory.get("id"))
        await this.setState({ "category": subCategory })
        await this.props.setSubcategorySelected(subCategory)
        await this.props.history.push("/community/category/subcategory");
    }

    toggleSelectPost = async (post) => {
        this.setState({ "selectedPost": post });
    }

    selectPost = async (post) => {
        const profile = await this.props.setCommunityPostSelected(post);
        this.setState({ "selectedPost": post });
        await this.props.history.push('/community/posts/post');
    }

    backToForumRoot = () => {
        this.props.setCommunityForumCategorySelected({});
        this.props.history.push('/community')
    }

    // TODO: probably unnecessary
    init = async () => {
        await this.props.tryGetAllPostsFromCategory(this.props.category.get("id"))
        this.setState({ 'posts': this.props.posts, 'isLoading': false });
    }

    getProfileImage = (author) => {
        // Author doesn't exist return Anonymous
        if (!author || !author.fullName) {
            return <ProfileIconInitial>A</ProfileIconInitial>
        }

        let author_profile_image = author.profilePhoto
        if (author_profile_image)
            return <ProfileImage src={author_profile_image} />

        let authorFirstLetter = "A"
        if (author.fullName)
            authorFirstLetter = author.fullName.substring(0, 1)

        if (authorFirstLetter) {
            return <ProfileIconInitial>{authorFirstLetter}</ProfileIconInitial>
        }
    }

    getAuthorFullName = (author) => {
        if(author && author.fullName)
            return author.fullName
        else
            return 'Anonymous'
    }

    getPostShortenedDescription = (post) => {
        let postBody = post.get('body')
        if (postBody.length <= 200)
            return postBody
        return postBody.substring(0, 197) + '...'
    }

    render() {
        if (this.state.isLoading) {
            return (<LogoLoading />)
        }
        else {
            let posts = this.props.posts.isEmpty()
                ? <>
                    <SpaceBetween direction={"column"}>
                        <FontTitle16>No questions for this topic. Be first to create one.</FontTitle16>
                    </SpaceBetween>
                </>
                : this.props.posts.map((p, idx) => {
                    return (
                        <a key={idx} onClick={() => this.selectPost(p)}>
                            <Border>
                                <FlexRow>
                                    {this.getProfileImage(p.get('author').toJS())}
                                    <div>
                                        <FontSubtitle21>{this.getAuthorFullName(p.get('author').toJS())}</FontSubtitle21>
                                        <FontBody16>{moment(p.get('createdOn')).format('ll').toString()}</FontBody16>
                                    </div>
                                </FlexRow>
                                <PostPreviewInner>
                                    <FontSubtitle21>{p.get('title')}</FontSubtitle21>
                                    <FontBody18>{this.getPostShortenedDescription(p)}</FontBody18>
                                </PostPreviewInner>
                            </Border>
                        </a>
                    )
                })
            return (
                <CategoryOneContainer>
                    <BackSection>
                        <Back onClick={this.backToForumRoot}>
                            <ChevronLeftRight src={images.chevronLeft} alt={"Back to Community"} />
                            <InlineBlock><FontBody16>{/*"Self-serve content"*/}</FontBody16></InlineBlock>
                        </Back>
                    </BackSection>
                    <PageTitle>{this.props.category.get("title").toUpperCase()}</PageTitle>
                    {
                        this.props.isCoach ? null :
                        <PageSubtitle direction={"row"}>
                            <CtaTitle>Select a topic below or create a post</CtaTitle>
                            <WriteToUsBtn onClick={() => { return this.props.history.push('/community/posts/create') }}>
                                <ButtonPrimary submit={false} label={"Create a post"} />
                            </WriteToUsBtn>
                        </PageSubtitle>
                    }
                    <NavOptions>
                        {posts}
                    </NavOptions>
                </CategoryOneContainer>
            )
        }
    }
}

const mapStateToProps = state => ({
    category: state.consumer.forum.forum.get("forumOptionSelected"),
    posts: state.consumer.forum.forum.get("posts"),
    isCoach: state.common.user.get('isCoach'),
});

const mapDispatchToProps = dispatch => ({
    setCommunityForumCategorySelected: (category) => dispatch(setCommunityForumSelected(category)),
    tryGetAllPostsFromCategory: (id) => dispatch(tryGetAllPostsFromCategory(id)),
    setCommunityPostSelected: (post) => dispatch(setCommunityPostSelected(post)),
    setSubcategorySelected: (category) => dispatch(setSubcategorySelected(category))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForumCategoryOne));