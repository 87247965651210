import React from 'react';

/* Components */
import {Red, TopRight, IconDescription, Inline} from "../components/terms";
import {FontHeader16, FontBody16} from "../../../common/components/fonts";
import {Clock} from 'react-feather';

class CheckoutTermsTimeLeft extends React.Component {
    state={"isLoading": true,  "secondsAvailable": 0, "formattedTime": "0:00"};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const time = await this.format(this.props.secondsAvailable)
        this.setState({
            "secondsAvailable": this.props.secondsAvailable,
            "formattedTime": time,
            "isLoading": false,
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.secondsAvailable !== this.state.secondsAvailable) {
            this.updateTime(this.props.secondsAvailable)
        }
    }

    updateTime = async (time) => {
        const newTime = await this.format(time)
        await this.setState({"formattedTime": newTime, "secondsAvailable": time})
    }

    format = (time) => {
        var hrs = ~~(time / 3600);
        var mins = ~~((time % 3600) / 60);
        var secs = ~~time % 60;

        var ret = "";
        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }

    render() {
        if(this.state.isLoading) {
            return null
        } else {
            return(
                <TopRight>
                    <Inline><FontHeader16><IconDescription><Clock />&nbsp;Time left:&nbsp;</IconDescription></FontHeader16></Inline>
                    <Inline><FontBody16><Red>{this.state.formattedTime}</Red></FontBody16></Inline>
                </TopRight>
            )
        }
    }
}

export default CheckoutTermsTimeLeft;