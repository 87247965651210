import React from 'react';

const expertCategoriesToShow = [
    57, // Career Coach
    12, // Life Coach
    9,  // Business Coach
    4,  // Financial Coach
    1   // Willow Financial Coach
];

const printExpertCategories = (coachExpertCategories, returnString) => {
    // Flatten coach expert categories
    const coachExpertCategoriesFlattened = coachExpertCategories.map(f => f.categoryId);
    // Return only non suppressed (overlapped) categories
    let remainingExpertCategories = coachExpertCategoriesFlattened.filter(c => expertCategoriesToShow.includes(c));
    if (remainingExpertCategories.includes(1) && remainingExpertCategories.includes(4)) {
        remainingExpertCategories = remainingExpertCategories.filter(c => c !== 4)
    }
    // Check if overlaps exist
    if(remainingExpertCategories !== [] && remainingExpertCategories !== null) {
        const categories = remainingExpertCategories.map(c => {
            if(c === 1) {
                return "Financial Coach"
            } else {
                let category = coachExpertCategories.filter(e => e.categoryId === c);
                return category[0].categoryName
            }
        });
        if(returnString) {
            return categories.join(", ")
        } else {
            return categories
        }
    } else {
        if(returnString) {
            return <br />
        } else {
            return null
        }
    }
};

export default printExpertCategories;