import React from 'react';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";

/* Components */
import {images} from "../../../common/components/images";
import {colors} from "../../../common/components/colors";
import {FontTitle24, FontSubtitle18, FontBody16To14, FontSubtitle14, FontHeader14} from '../../../common/components/fonts';
import { ButtonPrimary } from '../../../common/components/buttons';
import {tryPostMixpanel} from "../../../common/utils/tracking";

const Rectangle = styled.div`
    display:flex;
    flex-direction: column;
    gap: 20px;
    width: calc(30% - 60px);
    text-align: left;
    transition: opacity 1.25s ease-out 0s;
    background-color:white;
    box-shadow: 0 0.875rem 1.5rem 0 rgb(32 46 66 / 15%);
    padding: 30px;
    @media screen and (max-width: 1280px) {
      width: calc(45% - 60px);
    }
    @media screen and (max-width: 800px) {
      width: calc(100% - 60px);
    }
`;
const Gap = styled.div`
    display:flex;
    flex-direction: column;
    gap: 5px;
`;
const SponsoredText = styled(FontSubtitle14)`
  font-size:8px;
  color: rgba(152,158,166,0.588);
  margin-right: auto;
  order:2;
`;
const PriorityRectangle = styled(Rectangle)`
  border: 1px solid ${colors.action100};
  box-shadow: 0 0.875rem 1.5rem 0 ${colors.action30};
  ${SponsoredText} {}
`;
const ImageImg = styled.img`
  width: 100%;
  max-width: 300px;
  object-fit: cover;
  height: 100%;
  max-height:300px;
  border-radius:50%;
  aspect-ratio: 1 / 1;
  object-position: center top;
`;
const NameText = styled(FontTitle24)`
    color:${colors.primary100};
`;
const CertText = styled(FontHeader14)`
    color:${colors.primary70};
`;
const BioText = styled(FontBody16To14)`
    color:${colors.primary70};
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @media screen and (max-width: 800px) {
      -webkit-line-clamp: 5;
    }
`;
const BtnDiv = styled.div`
    display:flex;
    justify-content: center;
    & > button > div {
      border-color: ${colors.action100};
      background-color:${colors.action100};
    }
    & > button > div:hover {
      border-color: ${colors.action70};
      background-color:${colors.action70};
    }
`;
const BtnDivInactive = styled.div`
    width: 100%;
    text-align: center;
`;
const ImageBox = styled.div`
    display: flex;
    justify-content: center;
`;

Card.defaultProps = {
    advisor: {
        "id":900,
        "firstName":"",
        "lastName":"",
        "photos": "",
        "intro":{
            "video":"",
            "description":""
        },
        "expertise":[],
        "specialities":[],
        "services":[],
        "disclosure":null,
        "logos":[],
        "firmSec":null,
        "advisorSec":null,
        "firmName":null,
        "firmBio":null,
        "firmLogo":null,
        "firmAddress":{},
        "legalConfirmationDate": null,
        "priority": false,
        "active": true,
    },
}

export default function Card(props) {
    const a = props.advisor;
    let history = useHistory();

    const viewProfile = async () => {
        history.push("/public/advisor-profile-v2/"+a.id);
    };

    return (
        <Rectangle>
            <ImageBox>
                <ImageImg src={a.photos === null ? images.logoIcon : a.photos} />
            </ImageBox>
            <Gap>
                <NameText>{a.firstName+" "+a.lastName}</NameText>
                {/*<CertText>{a.name+" ("+a.designation+")"}</CertText>*/}
                <CertText>
                    {a.name === "CERTIFIED ADVISOR FOR WOMEN™"
                        ? <span>Advisor for Women™</span>
                        : a.name === "CERTIFIED ADVISOR FOR NEXTGEN™"
                            ? <span>Advisor for NextGen™</span>
                            : <span>{a.name}</span>
                    }
                </CertText>
            </Gap>
            <BioText>{a.intro.description}</BioText>
            {a.active
                ? <BtnDiv onClick={viewProfile}>
                    <ButtonPrimary canSubmit={true} label={"View Profile"}>
                </ButtonPrimary></BtnDiv>
                : <BtnDivInactive>Inactive</BtnDivInactive>
            }
        </Rectangle>
    );
}