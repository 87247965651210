import React from 'react';
import styled from 'styled-components';
import { colors } from './colors';
import 'react-quill/dist/quill.snow.css';


export const Quill = styled.div`
    background-color: ${colors.white};
    border-radius: 4px;
    padding-top: 5px;
    & > div {
        border: 1px solid ${props => props.bodyBorderColor} !important;
    }
    .ql-toolbar {
        border-top: 0px solid transparent !important;
        border-left: 0px solid transparent !important;
        border-right: 0px solid transparent !important;
        border-bottom: 1px solid ${colors.border100} !important;
    }
    .ql-container {
        border: 0px solid transparent !important;
        min-height: 78px;
        padding: 0px !important;
    }
`;
export const QuillWhite = styled.div`
    background-color: ${colors.white};
    border-radius: 4px;
    margin-top: 5px;
    & > div {
        border: 1px solid ${props => props.bodyBorderColor} !important;
    }
    .ql-toolbar {
        border-top: 0px solid transparent !important;
        border-left: 0px solid transparent !important;
        border-right: 0px solid transparent !important;
        border-bottom: 1px solid ${colors.border100} !important;
    }
    .ql-container {
        background-color: ${colors.white} !important;
        border: 0px solid transparent !important;
        min-height: 78px;
        padding: 0px !important;
    }
`;
export const QuillSlim = styled.div`
    background-color: ${colors.white};
    & > div {
        border: 1px solid ${props => props.bodyBorderColor} !important;
    }
    .ql-editor {
        padding: 0px !important;
        font-family: MontserratRegular;
        font-size: 14px;
        line-height: 21px;
    }
    .ql-toolbar {
        border-top: 0px solid transparent !important;
        border-left: 0px solid transparent !important;
        border-right: 0px solid transparent !important;
        border-bottom: 1px solid ${colors.border100} !important;
    }
    .ql-container {
        margin-top: 10px;
        border: 0px solid transparent !important;
        min-height: 78px;
    }
`;
export const QuillSlimNoHeight = styled.div`
    background-color: ${colors.white};
    & > div {
        border: 1px solid ${props => props.bodyBorderColor} !important;
    }
    .ql-editor {
        padding: 0px !important;
        font-family: MontserratRegular;
        font-size: 14px;
        line-height: 21px;
    }
    .ql-toolbar {
        border-top: 0px solid transparent !important;
        border-left: 0px solid transparent !important;
        border-right: 0px solid transparent !important;
        border-bottom: 1px solid ${colors.border100} !important;
    }
    .ql-container {
        margin-top: 10px;
        border: 0px solid transparent !important;
    }
`;
export const editorModules = {
    toolbar: [
        [
            { 'size': ['large', false, 'small'] },
            { 'color': [] },
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            {'list': 'ordered'},
            {'list': 'bullet'},
            {'indent': '-1'},
            {'indent': '+1'},
            'link',
            'image',
            'clean'
        ]
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
};
export const editorFormats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color'
];

export const editorModulesLimited = {
    toolbar: [
        [
            // { 'size': ['large', false, 'small'] },
            // { 'color': [colors.action100, colors.backgroundColor1, colors.backgroundColor2, colors.backgroundColor3, colors.backgroundColor4, colors.backgroundColor5, colors.backgroundColor6, colors.border100, colors.black, colors.primary100, colors.secondary100] },
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            {'list': 'ordered'},
            {'list': 'bullet'},
            {'indent': '-1'},
            {'indent': '+1'},
            'clean'
        ]
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
};
export const editorFormatsLimited = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
];
// export default class Editor extends React.Component {
//     constructor (props) {
//         super(props);
//         this.state = { editorHtml: '', theme: 'snow' };
//         this.handleChange = this.handleChange.bind(this)
//     }
//
//     handleChange (html) {
//         this.setState({ editorHtml: html });
//     }
//
//     render () {
//         return (
//             <Quill>
//                 <ReactQuill
//                     theme={this.state.theme}
//                     onChange={this.handleChange}
//                     value={this.state.editorHtml}
//                     modules={Editor.modules}
//                     formats={Editor.formats}
//                     bounds={'.app'}
//                     placeholder={this.props.placeholder}
//                 />
//             </Quill>
//         )
//     }
// }
