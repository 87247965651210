import React from 'react';
import styled from 'styled-components';

/* Components */
import {ButtonInactive, ButtonPrimary} from "../../../common/components/buttons";

export const SaveSection = styled.div`
    text-align: left;
    padding-top: 20px;
`;
export const InlineBlock = styled.div`
    display: inline-block;
`;
const ButtonRight = styled.div`
    margin-right: 10px;
`;
export const SubmitInputWithValidation = ({ isChanged, isValid, isSaving, label }) => {
    if(isChanged && isValid) {
        if(isSaving) {
            return (<ButtonRight><ButtonInactive canSubmit={true} label={"Saving"} /></ButtonRight>)
        } else {
            return (<ButtonRight><ButtonPrimary canSubmit={true} label={label}/></ButtonRight>)
        }
    } else {
        return(<ButtonRight><ButtonInactive canSubmit={true} label={label}/></ButtonRight>)
    }
};