import React from 'react';
import styled from 'styled-components';

import {CheckboxWithLabel} from '../../../common/components/inputs';
import {FontBody14, FontHeader18} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";

const Subtitle = styled(FontHeader18)`
    margin-bottom: 10px;
`;

const Disclosure = styled(FontBody14)`
    margin-bottom: 10px;
`;

const Warning = styled(FontBody14)`
    color: ${colors.alert100};
    margin-bottom: 10px;
`;

const Options = styled.div`
    margin: 10px 0px;
`;

class ElementCheckbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {options: [], checked: 0, isValid: true}
    }

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.elementId !== this.props.elementId) {
            return this.init();
        }
    }

    init = async () => {
        let countActive = 0;
        let isValid = {};
        await Promise.all(this.props.options.map(d => {
            if(d.active) {
                isValid = {"isValid": true};
                countActive += 1;
            }
            return Promise.resolve();
        }));
        this.setState({"options": this.props.options, "checked": countActive, ...isValid});
    };

    onSelect = async (id, status) => {
        let checkedNum = this.state.checked;
        await this.setState({
            "options": this.state.options.map(d => {
                if(d.id === id) {
                    if(this.state.checked < this.props.maxCheckable || d.active === true) {
                        if(d.active === true) {
                            checkedNum -= 1;
                        } else {
                            checkedNum += 1;
                        }
                        return ({...d, active: status});
                    } else {
                        return d;
                    }
                } else {
                    return d;
                }
            })
        });
        await this.setState({"isValid": checkedNum > 0, "checked": checkedNum});
        let isValid = this.props.isRequired ? this.state.isValid : true;
        return this.props.onSaveElement(this.props.elementId, {"options": this.state.options}, isValid)
    };

    render() {
        return(
            <>
                <Subtitle style={this.props.style.subtitle}>{this.props.subtitle}</Subtitle>
                <Disclosure style={this.props.style.disclosure}>{this.props.disclosure}</Disclosure>
                {(this.props.isRequired && !this.state.isValid) &&
                    <Warning style={this.props.style.warning}>{this.props.warning}</Warning>
                }
                <Options style={this.props.style.options}>
                    {this.state.options.map(j => (
                        <CheckboxWithLabel
                            key={j.id}
                            action={this.onSelect}
                            checked={j.active}
                            id={j.id}
                            label={j.label}
                            styleIcon={this.props.style.optionIcon}
                            styleLabel={this.props.style.optionLabel}
                        />
                    ))}
                </Options>
            </>
        )
    }
}
export default ElementCheckbox;
