import React from 'react';
import {connect} from 'react-redux';

import {tryLogout} from '../middleware/auth';

class LogoutRoom extends React.Component {
    componentDidMount() {
        this.props.tryLogout()
    }
    render() {
        return null;
    }
}

const mapDispatchToProps = dispatch => ({
    tryLogout: () => dispatch(tryLogout())
});

export default connect(null, mapDispatchToProps)(LogoutRoom);