import React from 'react';
import {connect} from 'react-redux';
import styled from "styled-components";
import moment from 'moment/moment';

/* Containers */
import Availability from './availability';
import Confirm from './confirm';
import Error from './error';
import Delete from './delete';
import DeleteV2 from './deletev2';
import DeleteSuccess from './deletesuccess';
import Menu from './menu';
import Success from '../../success/containers/success';

/* Middleware */
import {tryPostCreateRescheduleSessionAppointment, tryPostDeleteSessionAppointment} from "../middleware/reschedule";

/* Components */
import {LogoLoading} from "../../../common/components/loading";

/* Utility */
import {adjForDST} from '../../../common/utils/timezone';
import { timeZoneOptionsByRegion } from '../../../common/utils/timezone2';

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

class ReschedulerFlow extends React.Component {
    static defaultProps = {
        userId: null, // scheduleId
        userFirstName: null, // scheduleFirstName
        userLastName: null, // scheduleLastName
        userPhotoUrl: null, // schedulePhoto
        eventCustomMinutes: null, // scheduleDefaultEventLength
        eventCancelable: true, // scheduleShowCancel
        eventCancelOnly: false, // scheduleInit
        eventInitialTime: null, // scheduleInitMeetingDate
        token: null, // token
        confirmOnScheduled: () => {},
        confirmOnCanceled: () => {},
        onCloseBtn: false,
        onClose: () => {},
        useNewDesign: false,
        purchaseHistoryId: null,
        isWillowSession: false,
        useLocalTimeZone: false,
    };

    state={
        "step": 2,
        "selectedTime": null,
        "meetingLength": 0,
        "meetingTitle": "",
        "meetingDescription": "",
        "token": null,
        "isLoading": true,
        "showCancel": false,
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.eventCustomMinutes !== this.props.eventCustomMinutes) {
            this.setState({'meetingLength': this.props.eventCustomMinutes})
        }
    }
    init = async () => {
        this.setState({
            "meetingLength": this.props.eventCustomMinutes,
            "selectedTime": this.props.eventInitialTime,
            "meetingTitle": "",
            "meetingDescription": "",
            "step": this.props.eventCancelOnly ? 4 : 2, // this.props.scheduleInit === "reschedule" ? 2 : 4,
            "token": this.props.token,
            "showCancel": this.props.eventCancelable,
            "isLoading": false
        })
    };

    setStep = async (step) => {
        await this.setState({"step": step});
    };

    onSelectTime = async (data) => {
        let tzone = (this.props.user.get("timeZone") === null || this.props.user.get("timeZone") === undefined) ? timeZoneOptionsByRegion(Intl.DateTimeFormat().resolvedOptions().timeZone) : this.props.user.get("timeZone")
        // let tzone = this.props.useLocalTimeZone ? timeZoneOptionsByRegion(Intl.DateTimeFormat().resolvedOptions().timeZone) : this.props.user.get("timeZone")
        let selectedTime = adjForDST(data.selectedTime, tzone);
        await this.setState({"isLoading": true, "selectedTime": selectedTime});
        const token = await this.props.tryPostCreateRescheduleSessionAppointment(this.state.selectedTime, this.state.meetingLength, this.state.token, this.props.purchaseHistoryId, this.props.isWillowSession);
        if(this.props.rescheduleSessionCreatingError !== null) {
            await this.setState({"selectedTime": this.props.eventInitialTime, "error": "rescheduled"});
            await this.setStep(3);
        } else {
            await this.props.confirmOnScheduled(data);
            await this.setState({"error": null});
            // await this.setStep(this.props.useNewDesign ? 6 : 2);
            // await this.props.onClose();
            await this.setState({"token": token});
            this.setStep(this.props.useNewDesign ? 6 : 2);
        }
        this.setState({"isLoading": false})
    };

    deleteSession = async () => {
        await this.setState({"isLoading": true});
        await this.props.tryPostDeleteSessionAppointment(this.state.token);
        if(this.props.rescheduleSessionDeletingError !== null) {
            await this.setState({"error": "canceled"});
            await this.setStep(3);
        } else {
            await this.props.confirmOnCanceled();
            await this.setState({"error": null});
            await this.setStep(5);
        }
        this.setState({"isLoading": false})
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if(this.state.step === 1) {
                return(
                    <Availability
                        userId={this.props.userId}
                        firstName={this.props.userFirstName}
                        lastName={this.props.userLastName}
                        photo={this.props.userPhotoUrl}
                        onSelectTime={this.onSelectTime}
                        meetingLength={this.state.meetingLength}
                        meetingTitle={this.state.meetingTitle}
                        meetingDescription={this.state.meetingDescription}
                        availabilityOnBack={() => {return this.setStep(2)}}
                        useNewDesign={this.props.useNewDesign}
                    />
                )
            } else if(this.state.step === 2) {
                return(
                    this.props.useNewDesign ?
                    <Menu
                        reschedule={() => {return this.setStep(1)}}
                        selectedTime={this.state.selectedTime}
                        meetingLength={this.state.meetingLength}
                        showCancel={this.state.showCancel}
                        cancel={() => {return this.setStep(4)}}
                        onCloseBtn={this.props.onCloseBtn}
                        onClose={this.props.onClose}
                    /> :
                    <Confirm
                        reschedule={() => {return this.setStep(1)}}
                        selectedTime={this.state.selectedTime}
                        meetingLength={this.state.meetingLength}
                        showCancel={this.state.showCancel}
                        cancel={() => {return this.setStep(4)}}
                        onCloseBtn={this.props.onCloseBtn}
                        onClose={this.props.onClose}
                    />
                )
            } else if(this.state.step === 3) {
                return(
                    <Error
                        error={this.state.error}
                        back={() => {return this.setStep(this.props.eventCancelOnly ? 4 : 2)}}
                    />
                )
            } else if(this.state.step === 4) {
                return(
                    this.props.useNewDesign ?
                    <DeleteV2
                        deleteSession={this.deleteSession}
                        selectedTime={this.state.selectedTime}
                        meetingLength={this.state.meetingLength}
                        showBack={!this.props.eventCancelOnly}
                        back={() => {return this.setStep(2)}}
                    /> :
                    <Delete
                        deleteSession={this.deleteSession}
                        selectedTime={this.state.selectedTime}
                        meetingLength={this.state.meetingLength}
                        showBack={!this.props.eventCancelOnly}
                        back={() => {return this.setStep(2)}}
                    />
                )
            } else if(this.state.step === 5) {
                return(
                    <DeleteSuccess
                        selectedTime={this.state.selectedTime}
                        meetingLength={this.state.meetingLength}
                        onCloseBtn={this.props.onCloseBtn}
                        onClose={this.props.onClose}
                    />
                )
            } else if (this.state.step === 6) {
                return (
                    <Success
                        back={this.props.onClose}
                        title={'APPOINTMENT RESCHEDULED'}
                        subtitle={`${moment(this.state.selectedTime).format("LLLL")} (${this.state.meetingLength}-minutes)`}
                        buttonLabel={'Close'}
                    />
                )
            } else {
                return null
            }
        }
    }

}

const mapStateToProps = state => ({
    rescheduleSessionCreatingError: state.iframe.rescheduler.reschedule.get("rescheduleSessionCreatingError"),
    rescheduleSessionDeletingError: state.iframe.rescheduler.reschedule.get("rescheduleSessionDeletingError"),
    user: state.common.user,
});

const mapDispatchToProps = dispatch => ({
    tryPostCreateRescheduleSessionAppointment: (start, meetingLength, token, purchaseHistoryId, isWillowSession) => dispatch(tryPostCreateRescheduleSessionAppointment(start, meetingLength, token, purchaseHistoryId, isWillowSession)),
    tryPostDeleteSessionAppointment: (token) => dispatch(tryPostDeleteSessionAppointment(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReschedulerFlow);
