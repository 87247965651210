
// Null or object
import {v4 as uuid4} from "uuid";

export const devMyMoneyIncomeStatement = {
    "incomeStatement": {
        activePlan: 'EXAMPLE',
        plans: [
            {
                pid: 'EXAMPLE',
                title: 'Example #1',
                income: [
                    { lid: uuid4(), title: 'Salary', amount: 5108 },
                    { lid: uuid4(), title: 'Etsy Store', amount: 95 },
                ],
                expenses: [
                    {
                        cid: uuid4(),
                        title: 'Debt Payments',
                        lineItems: [
                            { lid: uuid4(), title: 'Mortgage', amount: 2300 },
                            { lid: uuid4(), title: 'Student Loan', amount: 247 },
                            { lid: uuid4(), title: 'Car Loan', amount: 322 },
                            { lid: uuid4(), title: 'MasterCard', amount: 205 },
                        ],
                    },
                    {
                        cid: uuid4(),
                        title: 'Utilities',
                        lineItems: [
                            { lid: uuid4(), title: 'Cable & Internet', amount: 105 },
                            { lid: uuid4(), title: 'Phone', amount: 49 },
                            { lid: uuid4(), title: 'Electricity', amount: 77 },
                            { lid: uuid4(), title: 'Water', amount: 28 },
                        ],
                    },
                    {
                        cid: uuid4(),
                        title: 'Food',
                        lineItems: [
                            { lid: uuid4(), title: 'Groceries', amount: 700 },
                            { lid: uuid4(), title: 'Dining Out', amount: 800 },
                            { lid: uuid4(), title: 'Bars', amount: 250 },
                        ],
                    },
                    {
                        cid: uuid4(),
                        title: 'Shopping',
                        lineItems: [
                            { lid: uuid4(), title: 'Clothes', amount: 120 },
                            { lid: uuid4(), title: 'Beauty', amount: 50 },
                            { lid: uuid4(), title: 'Subscriptions', amount: 60 },
                        ],
                    },
                    {
                        cid: uuid4(),
                        title: 'Transportation',
                        lineItems: [
                            { lid: uuid4(), title: 'Gas', amount: 220 },
                            { lid: uuid4(), title: 'Car Insurance', amount: 110 },
                        ],
                    },
                    {
                        cid: uuid4(),
                        title: 'Other',
                        lineItems: [
                            { lid: uuid4(), title: 'Pet Supplies', amount: 60 },
                            { lid: uuid4(), title: 'Charity', amount: 20 },
                        ],
                    },
                ],
            },
        ],
        unsaved: false,
    }
};


// Null or object
export const devMyMoneyBalanceSheet = {
    "balanceSheet": {
        assets: [
            {
                cid: 'ACCOUNTS',
                title: 'Bank Accounts',
                lineItems: [
                    { lid: uuid4(), title: 'Checking Account', amount: 20000 },
                    { lid: uuid4(), title: 'Savings Account', amount: 0 },
                ]
            },
            {
                cid: 'INVESTMENTS',
                title: 'Investments',
                lineItems: [
                    { lid: uuid4(), title: '401k', amount: 30000 },
                    { lid: uuid4(), title: 'IRA', amount: 0 },
                    { lid: uuid4(), title: '529 Plan', amount: 0 },
                ]
            },
            {
                cid: 'ASSETS',
                title: 'Assets',
                lineItems: [
                    { lid: uuid4(), title: 'House', amount: 50000 },
                    { lid: uuid4(), title: 'Car', amount: 0 },
                ]
            },
            {
                cid: 'CUSHION',
                title: 'Emergency Fund',
                lineItems: [
                    { lid: uuid4(), title: 'Cash Cushion Account', amount: 0 },
                ]
            },
        ],
        liabilities: [
            {
                cid: 'CREDITCARDS',
                title: 'Credit Cards',
                lineItems: [
                    { lid: uuid4(), title: 'MasterCard', amount: 2000 },
                    { lid: uuid4(), title: 'Visa', amount: 0 },
                ]
            },
            {
                cid: 'LOANS',
                title: 'Loans',
                lineItems: [
                    { lid: uuid4(), title: 'Mortgage', amount: 325000 },
                    { lid: uuid4(), title: 'Student Loan', amount: 0 },
                    { lid: uuid4(), title: 'Car Loan', amount: 0 },
                ]
            },
        ],
        credtScore: {
            score: null,
            lastChecked: null,
        },
        unsaved: false,
    }
};


// Null or object
export const devMyMoneySavingsGoals = {
    "savingsGoals": {
        goals: [
            { lid: uuid4(), title: 'Car', amount: 20, target: '' },
            { lid: uuid4(), title: 'Boat', amount: 30, target: '' },
            { lid: uuid4(), title: '', amount: 40, target: '' },
            { lid: uuid4(), title: '', amount: 0, target: '' },
            { lid: uuid4(), title: '', amount: 0, target: '' },
            { lid: uuid4(), title: '', amount: 0, target: '' },
        ],
        unsaved: false,
    }
};
