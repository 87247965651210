import { Map, fromJS } from 'immutable';

/* Immutable */

const SHOP_ITEM_PORTFOLIO = 'SHOP_ITEM_PORTFOLIO';
const SHOP_ITEM_TESTIMONIAL = 'SHOP_ITEM_TESTIMONIAL';
const SHOP_ITEM_FAQ = 'SHOP_ITEM_FAQ';
const SHOP_ITEM_EXPERIENCE = 'SHOP_ITEM_EXPERIENCE';
const SHOP_ITEM_EDUCATION = 'SHOP_ITEM_EDUCATION';
const SHOP_ITEM_LICENSE = 'SHOP_ITEM_LICENSE';
const SHOP_ITEM_STORE_RESET = 'SHOP_ITEM_STORE_RESET';

/* Actions */

export const setShopItemPortfolio = (item) => ({'type': SHOP_ITEM_PORTFOLIO, 'item': item});
export const setShopItemTestimonial = (item) => ({'type': SHOP_ITEM_TESTIMONIAL, 'item': item});
export const setShopItemFAQ = (item) => ({'type': SHOP_ITEM_FAQ, 'item': item});
export const setShopItemExperience = (item) => ({'type': SHOP_ITEM_EXPERIENCE, 'item': item});
export const setShopItemEducation = (item) => ({'type': SHOP_ITEM_EDUCATION, 'item': item});
export const setShopItemLicense = (item) => ({'type': SHOP_ITEM_LICENSE, 'item': item});
export const setShopItemStoreReset = () => ({'type': SHOP_ITEM_STORE_RESET});

/* Initial State */

const initialState = Map({
    portfolio: Map(),
    testimonial: Map(),
    faq: Map(),
    experience: Map(),
    education: Map(),
    license: Map()
});

/* Reducer */

const shopItemReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOP_ITEM_PORTFOLIO:
            return state.merge({'portfolio': fromJS(action.item)});
        case SHOP_ITEM_TESTIMONIAL:
            return state.merge({'testimonial': fromJS(action.item)});
        case SHOP_ITEM_FAQ:
            return state.merge({'faq': fromJS(action.item)});
        case SHOP_ITEM_EXPERIENCE:
            return state.merge({'experience': fromJS(action.item)});
        case SHOP_ITEM_EDUCATION:
            return state.merge({'education': fromJS(action.item)});
        case SHOP_ITEM_LICENSE:
            return state.merge({'license': fromJS(action.item)});
        case SHOP_ITEM_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default shopItemReducer;
