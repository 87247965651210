import 'moment-timezone/builds/moment-timezone-with-data-2012-2022';
import moment from 'moment-timezone';
import {axiosReq} from "../../../common/utils/axios";

/* Middleware */
import { tryGetSettingsPersonalInfo } from '../../middleware/settingspersonalinfo';
import { adjForTZ } from '../../../common/utils/timezone';
import { tryGetRecurringAvailability } from '../../settings/recurring/middleware/recurring';

/* Store */
import {
    setManualAvailability,
    setManualAvailabilityReading,
    setManualAvailabilityCreating,
    setManualAvailabilityUpdating,
    setManualAvailabilityDeleting,
    setManualAvailabilityModal,
} from "../store/manual";

/* Utils */
import { staticAvailability } from "../utils/customizecalendar";
import { setOneHrMin } from '../utils/minimumtime';

/* Static */
import {
    devManualAvailabilityData,
    devManualAvailabilityAddData,
    devManualAvailabilityUpdateData,
    devManualAvailabilityDeleteData
} from '../static/devdata';

const tryGetManualAvailability = () => {
    return async (dispatch, getState) => {
        dispatch(setManualAvailabilityReading(true));
        const timeZone = getState().enterprise.settingsPersonal.get("settingsPersonalInfo").get("timeZone");
        const availability = await axiosReq('apiv1/coach/schedule/availability', "GET", {}, devManualAvailabilityData);
        const calendarType = getState().enterprise.recurring.get("recurringAvailability").get("availability_calendar_update_method");
        const availabilityModified = await availability.availabilityTimeslots.map(a => {return {...adjForTZ(a, timeZone), ...staticAvailability(a, calendarType)}});
        await dispatch(setManualAvailability(availabilityModified));
        return dispatch(setManualAvailabilityReading(false));
    }
};

export const tryGetScheduler = () => {
    return async (dispatch, getState) => {
        if(getState().enterprise.settingsPersonal.get("settingsPersonalInfo").get("timeZone") === "") {
            await dispatch(tryGetSettingsPersonalInfo());
        }
        await dispatch(tryGetRecurringAvailability());
        await dispatch(tryGetManualAvailability());
        return getState().enterprise.availability.get("manualAvailability");
    }
};


export const tryPostManualAvailability = (start, end) => {
    return async (dispatch, getState) => {
        dispatch(setManualAvailabilityCreating(true));
        const timeZone = getState().enterprise.settingsPersonal.get("settingsPersonalInfo").get("timeZone");
        const time = await setOneHrMin(start, end);
        const startModified = moment(time.start).format().toString();
        const endModified = moment(time.end).format().toString();
        const availability = await axiosReq('apiv1/coach/schedule/availability', "POST", {start: startModified, end: endModified}, devManualAvailabilityAddData(startModified, endModified));
        const calendarType = getState().enterprise.recurring.get("recurringAvailability").get("availability_calendar_update_method");
        const availabilityModified = await availability.availabilityTimeslots.map(a => {return {...adjForTZ(a, timeZone), ...staticAvailability(a, calendarType)}});
        await dispatch(setManualAvailability(availabilityModified));
        dispatch(setManualAvailabilityCreating(false));
        return dispatch(setManualAvailabilityModal(false, null, null));
    }
};

export const tryPutManualAvailability = (id, start, end) => {
    return async (dispatch, getState) => {
        dispatch(setManualAvailabilityUpdating(true));
        const timeZone = getState().enterprise.settingsPersonal.get("settingsPersonalInfo").get("timeZone");
        const startModified = moment(start).format().toString();
        const endModified = moment(end).format().toString();
        const availability = await axiosReq('apiv1/coach/schedule/availability', "PUT", {id: id, start: startModified, end: endModified}, devManualAvailabilityUpdateData(id, startModified, endModified, getState()));
        const calendarType = getState().enterprise.recurring.get("recurringAvailability").get("availability_calendar_update_method");
        const availabilityModified = await availability.availabilityTimeslots.map(a => {return {...adjForTZ(a, timeZone), ...staticAvailability(a, calendarType)}});
        await dispatch(setManualAvailability(availabilityModified));
        dispatch(setManualAvailabilityUpdating(false));
        return dispatch(setManualAvailabilityModal(false, null, null));
    }
};

export const tryDeleteManualAvailability = (id) => {
    return async (dispatch, getState) => {
        dispatch(setManualAvailabilityDeleting(true));
        const timeZone = getState().enterprise.settingsPersonal.get("settingsPersonalInfo").get("timeZone");
        const availability = await axiosReq('apiv1/coach/schedule/availability', "DELETE", {id: id}, devManualAvailabilityDeleteData(id, getState()));
        const calendarType = getState().enterprise.recurring.get("recurringAvailability").get("availability_calendar_update_method");
        const availabilityModified = await availability.availabilityTimeslots.map(a => {return {...adjForTZ(a, timeZone), ...staticAvailability(a, calendarType)}});
        await dispatch(setManualAvailability(availabilityModified));
        dispatch(setManualAvailabilityDeleting(false));
        return dispatch(setManualAvailabilityModal(false, null, null));
    }
};
