import React, {useEffect, useState} from "react";
import { withRouter } from 'react-router-dom';
import {connect, useSelector} from 'react-redux';
import styled from "styled-components";


/* Components */
import { colors } from "../../../../common/components/colors";
import { FontBody16, FontHeader18, FontBody18, FontTitle24 } from "../../../../common/components/fonts";
import {ButtonPrimary, ButtonInactive, ButtonSecondary, ButtonTertiary} from "../../../../common/components/buttons";
import { images } from "../../../../common/components/images";

import {Option, Plan, PlanDetails, CertImage, PlansList, Price, ButtonWide} from "./plansgrid";


export default function FirmPlanNextGen(props) {
    const [isLoading, setLoading] = useState(false);
    const [title, setTitle] = useState("Advisor for NextGen™ Certificate")
    const [certCoupon, setCertCoupon] = useState(false)
    const [noBuy, setNoBuy] = useState(false)
    const userId = useSelector((state) => state.common.user.get("userId"));

    useEffect(() => {
        init()
    },[])

    const init = async () => {
        const women = props.certWomenStatus;

        // "buyCertificateFirstTime & buyCertificateRenew https://pay.trustwillow.com/b/5kAaG42oebQ51Py00d
        // "noPurchaseUntilOtherCertIsDone"
        // "noPurchaseUntilExpirationDate"

        if (women === "completed" || props.certWomenStatus === "completed") {

            setCertCoupon(true)
        }

        if (women === "in_progress") {
            setNoBuy(true)
        }
    }
    // const w = this.props.certWomen;
    // const n = this.props.certNextGen;
    //
    // let show = null
    // "buyCertificateFirstTime & buyCertificateRenew https://pay.trustwillow.com/b/7sI01q9QGbQ5eCkfZf
    // "noPurchaseUntilOtherCertIsDone"
    // "noPurchaseUntilExpirationDate"

    // let title = "Advisor for NextGen™ Certificate"

    // if(n.status === "in_progress" && w.status !== "completed") {
    //     show = "noPurchaseUntilOtherCertIsDone"
    // } else if(w.status === "in_progress" || w.status === "completed") {
    //     if(w.renewalDate === null) {
    //         show = "buyCertificateRenew"
    //     } else {
    //         show = w.isExpired ? "buyCertificateRenew" : "noPurchaseUntilExpirationDate"
    //     }
    // } else if(w.status === "no_purchase") {
    //     show = "buyCertificateFirstTime"
    // }

    // await this.setState({
    //     // "currentPlanId": currentPlanId,
    //     "title": title,
    //     "show": show,
    //     "isLoading": false
    // });


    return (
        <Option>
            <FontTitle24 style={{textAlign: "center"}}>{title}</FontTitle24>
            <CertImage src={images.nextGenBadge}/>
            <FontBody16>Demonstrate that you are a top advisor who is committed to supporting the unique needs of
                Millennial and GenZ clients.</FontBody16>
            <Plan>
                <PlanDetails>Details:</PlanDetails>
                <PlansList>
                    <li><FontBody16>Digital Certificate Badge</FontBody16></li>
                    <li><FontBody16>Quarterly practice management & best practice sharing sessions</FontBody16></li>
                    <li><FontBody16>Additional training & networking opportunities</FontBody16></li>
                </PlansList>
            </Plan>

            <>

                    <>
                        <Price style={{display:'flex',flexDirection:'column',marginBottom:'1em'}}>
                            <FontHeader18 style={{textDecoration: 'line-through', color: colors.secondary30, display:'flex',flexDirection:'row'}}>$800
                                <FontBody18>/yr</FontBody18>
                            </FontHeader18>

                            <FontHeader18 style={{ display:'flex',flexDirection:'row'}}>$600
                                <FontBody18>/yr</FontBody18>
                            </FontHeader18>

                        </Price>
                        {certCoupon ?
                        <ButtonWide onClick={() => {
                            window.location.href = `https://pay.trustwillow.com/b/7sI01q9QGbQ5eCkfZf?prefilled_promo_code=SECONDCERT`
                        }}>
                            <ButtonPrimary label={"Get Started"} canSubmit={false}/>
                        </ButtonWide>
                            :
                            <ButtonWide>
                            <ButtonInactive label={"Get started"} canSubmit={false}/>
                            </ButtonWide>
                        }
                        </>

            </>
        </Option>
    );
}
                {/*{this.state.show === "buyCertificateFirstTime" &&*/}
                {/*    <>*/}
                {/*        <Price><FontHeader18>$800</FontHeader18><FontBody18>/yr</FontBody18></Price>*/}
                {/*        <ButtonWide onClick={() => {window.location.href = 'https://pay.trustwillow.com/b/7sI01q9QGbQ5eCkfZf?client_reference_id='+userId}}>*/}
                {/*            <ButtonPrimary label={"Get Started"} canSubmit={false} />*/}
                {/*        </ButtonWide>*/}
                {/*    </>*/}
                {/*}*/}
                {/*{this.state.show === "buyCertificateRenew" &&*/}
                {/*    <>*/}
                {/*        <Price><FontHeader18>$800</FontHeader18><FontBody18>/yr</FontBody18></Price>*/}
                {/*        <ButtonWide onClick={() => {window.location.href = 'https://pay.trustwillow.com/b/7sI01q9QGbQ5eCkfZf?client_reference_id='+this.props.userId}}>*/}
                {/*            <ButtonPrimary label={"Renew Certificate"} canSubmit={false} />*/}
                {/*        </ButtonWide>*/}
                {/*    <FontBody16 style={{ color: colors.alert100 }}>Expired: {this.props.certNextGen.renewalDate}</FontBody16>*/}
                {/*    </>*/}
                {/*}*/}
                {/*{this.state.show === "noPurchaseUntilOtherCertIsDone" &&*/}
                {/*    <FontBody16>Finish your other certificate to purchase this one</FontBody16>*/}
                {/*}*/}
                {/*{this.state.show === "noPurchaseUntilExpirationDate" &&*/}
                {/*    <>*/}
                {/*        <ButtonWide>*/}
                {/*            <ButtonInactive label={"Active Certificate"} canSubmit={false} />*/}
                {/*        </ButtonWide>*/}
                {/*    <FontBody16>Active through: {this.props.certNextGen.renewalDate}</FontBody16>*/}
                {/*    </>*/}
                {/*}*/}




// const mapStateToProps = state => ({
//     userId: state.common.user.get("userId"),
// });

// const mapDispatchToProps = dispatch => ({
//
// })

// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FirmPlanNextGen));
