export const devConsumerInsightParentCategories = [
    {
        "id": 1,
        "title": "Career",
        "subtitle": "Something about careers",
        "description": "Test",
        "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089764/kqvetvetvbeuffyetota.png",
        "parentCategoryIds": null
    },
    {
        "id": 2,
        "title": "Retirement",
        "subtitle": "Something about finances",
        "description": "Test",
        "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089788/jgum0huzefh5a2zjiznh.png",
        "parentCategoryIds": null
    },
    {
        "id": 3,
        "title": "Dating & Marriage",
        "subtitle": "Something about finances",
        "description": "Test",
        "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089807/tceqco11kedlukuz0f9z.png",
        "parentCategoryIds": null
    },
    {
        "id": 4,
        "title": "Divorce",
        "subtitle": "Something about finances",
        "description": "Test",
        "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089822/fjkbuzjzwhs10ct7cvll.png",
        "parentCategoryIds": null
    },
    {
        "id": 5,
        "title": "Family Building",
        "subtitle": "Something about finances",
        "description": "Test",
        "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089838/t0ykpoigjmrqovjk3iop.png",
        "parentCategoryIds": null
    },
    {
        "id": 6,
        "title": "Health",
        "subtitle": "Something about finances",
        "description": "Test",
        "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089853/jno2lqxcniwz4r6zolaj.png",
        "parentCategoryIds": null
    },
    {
        "id": 7,
        "title": "Grief & Loss",
        "subtitle": "Something about finances",
        "description": "Test",
        "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636738052/qnghe6myev2trzymbjel.jpg",
        "parentCategoryIds": null
    },
    {
        "id": 8,
        "title": "Financial",
        "subtitle": "Something about finances",
        "description": "test",
        "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633089868/sob4gvhdrpgbk73kcxcg.png",
        "parentCategoryIds": null
    }
];

export const devConsumerInsightSubCategories = 
    // {
    //     "id": 3,
    //     "title": "Saving",
    //     "subtitle": "Saving",
    //     "description": "Test",
    //     "photoUrl": "https://picsum.photos/200/300",
    //     "parentCategoryIds": 1
    // },
    // {
    //     "id": 4,
    //     "title": "Investing",
    //     "subtitle": "Investing",
    //     "description": "Test",
    //     "photoUrl": "https://picsum.photos/200/300",
    //     "parentCategoryIds": 1
    // }
    {
        "parentCategory": {
            "id": 44, 
            "title": "Career", 
            "subtitle": "Career", 
            "description": "Career", 
            "photoUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1636737878/o9lmuiryvamia3mj2xh4.jpg", 
            "parentCategoryIds": []
        }, 
        "childCategories": [
            {
                "id": 31, 
                "title": "Career advancement", 
                "subtitle": "Career advancement", 
                "description": "Career advancement", 
                "photoUrl": "https://static.thenounproject.com/png/275155-200.png", 
                "parentCategoryIds": [44]
            }, 
            {
                "id": 33, 
                "title": "Entrepreneurship",
                "subtitle": "Entrepreneurship",
                "description": "Entrepreneurship",
                "photoUrl": "https://static.thenounproject.com/png/275155-200.png", 
                "parentCategoryIds": [44]
            }, 
            {
                "id": 34, 
                "title": "Starting/running a business", 
                "subtitle": "Starting/running a business", 
                "description": "Starting/running a business", 
                "photoUrl": "https://static.thenounproject.com/png/275155-200.png", 
                "parentCategoryIds": [44]
            }
        ]
    }

const article = {
    "id": 2,
    "title": "Article title 2 7 Tips to Manage Student Loans for Undergraduate and Graduate Students",
    "description": "Read this article to learn MOOC identifies and explores a number of challenges to the finance professional arising from the ever changing business environment. Increasingly, financial decisions must take account of strategic consequences. The first module, Exploring Strategic Position, Choice and Action helps in doing that – setting a strategic context for planning for, monitoring and controlling projects and activities. An on-going set of challenges is found in the evolution of International Reporting Standards. This MOOC will be right for you if you are interested in engaging with some of the challenges facing finance professionals, even if you are not one yourself. If you are thinking about returning to study this will provide a taster of academic endeavours through reading articles, critiquing ideas, and blending theory with reality.",
    "imageUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633545151/m7ktfteujdez6csegs7a.png",
    "isActive": true,
    "coach": {
        "coachId": 12,
        "wmcId": 1,
        "first": "Mark",
        "last": "Advisor",
        "phone": 5005550002,
        "email": "",
        "photo": "",
        "defaultCallLengthInMinutes": 33,
        "storeActive": false,
        "storeHandle": null,
        "publicHandle": "asdfa",
        "schedulerHandle": null,
        "bio": "",
        "benefitsProviderId": null,
        "expertCategories": [
        ]
    },
    "externalAuthor": {
        "additionalInformation": {},
        "firstName": "Willow",
        "lastName": "Editorial Team"
    },
    "isReadingMaterial":true,
    "isVideo": false,
    "isGoal":false,
    "categories":[
        {
            "id":31,
            "parentCategoryIds": [44],
            "title":"Career advancement"
        }
    ],
    "createdOn": "2022-02-08T05:00:00+00:00"
};

export const devConsumerInsightsFive = [
    article, article, article, article, article
]
 

export const devConsumerInsights = [
    {
        "id": 4,
        "title": "Course title 4",
        "description": "This MOOC identifies and explores a number of challenges to the finance professional arising from the ever changing business environment. Increasingly, financial decisions must take account of strategic consequences. The first module, Exploring Strategic Position, Choice and Action helps in doing that – setting a strategic context for planning for, monitoring and controlling projects and activities. An on-going set of challenges is found in the evolution of International Reporting Standards. This MOOC will be right for you if you are interested in engaging with some of the challenges facing finance professionals, even if you are not one yourself. If you are thinking about returning to study this will provide a taster of academic endeavours through reading articles, critiquing ideas, and blending theory with reality.",
        "imageUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633121318/gfosspvgtbtvw9fipjhq.png",
        "isActive": true,
        "coach": {
            "coachId": 12,
            "wmcId": 1,
            "first": "Mark",
            "last": "Advisor",
            "phone": 5005550002,
            "email": "",
            "photo": "",
            "defaultCallLengthInMinutes": 33,
            "storeActive": false,
            "storeHandle": null,
            "publicHandle": "asdfa",
            "schedulerHandle": null,
            "bio": "",
            "benefitsProviderId": null,
            "expertCategories": [
            ]
        },
        "externalAuthor": {
            "additionalInformation": {},
            "firstName": "Willow",
            "lastName": "Editorial Team"
        },
        "isReadingMaterial":false,
        "isVideo": false,
        "isGoal":false,
        "categories":[
            {
                "id":31,
                "parentCategoryIds": [44],
                "title":"Career advancement"
            }
        ],
        "createdOn": "2022-03-08T05:00:00+00:00"
    },
    article,
    {
        "id": 1,
        "title": "Video title 1 What Are Different Cash Flow Scenarios?",
        "description": "Watch this video to learn MOOC identifies and explores a number of challenges to the finance professional arising from the ever changing business environment. Increasingly, financial decisions must take account of strategic consequences. The first module, Exploring Strategic Position, Choice and Action helps in doing that – setting a strategic context for planning for, monitoring and controlling projects and activities. An on-going set of challenges is found in the evolution of International Reporting Standards. This MOOC will be right for you if you are interested in engaging with some of the challenges facing finance professionals, even if you are not one yourself. If you are thinking about returning to study this will provide a taster of academic endeavours through reading articles, critiquing ideas, and blending theory with reality.",
        "imageUrl": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1633180583/pzejm02wp9hq6r46m8jo.png",
        "isActive": true,
        "coach": {
            "coachId": 12,
            "wmcId": 1,
            "first": "Mark",
            "last": "Advisor",
            "phone": 5005550002,
            "email": "",
            "photo": "",
            "defaultCallLengthInMinutes": 33,
            "storeActive": false,
            "storeHandle": null,
            "publicHandle": "asdfa",
            "schedulerHandle": null,
            "bio": "",
            "benefitsProviderId": null,
            "expertCategories": [
            ]
        },
        "externalAuthor": {
            "additionalInformation": {},
            "firstName": "Willow",
            "lastName": "Editorial Team"
        },
        "isReadingMaterial":true,
        "isVideo": true,
        "isGoal":false,
        "categories":[
            {
                "id":31,
                "parentCategoryIds": [44],
                "title":"Career advancement"
            }
        ],
        "createdOn": "2021-01-08T05:00:00+00:00"
    },
    article, article,
    {
        "id": 4,
        "title": "Course title 2",
        "description": "Course description",
        "imageUrl": "https://picsum.photos/200/300",
        "isActive": true,
        "coach": {
            "coachId": 12,
            "wmcId": 1,
            "first": "Mark",
            "last": "Advisor",
            "phone": 5005550002,
            "email": "",
            "photo": "",
            "defaultCallLengthInMinutes": 33,
            "storeActive": false,
            "storeHandle": null,
            "publicHandle": "asdfa",
            "schedulerHandle": null,
            "bio": "",
            "benefitsProviderId": null,
            "expertCategories": [
            ]
        },
        "externalAuthor": {
            "additionalInformation": {},
            "firstName": "Willow",
            "lastName": "Editorial Team"
        },
        "isReadingMaterial":true,
        "isVideo": false,
        "isGoal":false,
        "categories":[
            {
                "id":31,
                "parentCategoryIds": [44],
                "title":"Career advancement"
            }
        ],
        "createdOn": "2022-01-08T05:00:00+00:00"
    },
    {
        "id": 10,
        "title": "Course title 3",
        "description": "Course description",
        "imageUrl": "https://picsum.photos/200/300",
        "isActive": true,
        "coach": {
            "coachId": 12,
            "wmcId": 1,
            "first": "Mark",
            "last": "Advisor",
            "phone": 5005550002,
            "email": "",
            "photo": "",
            "defaultCallLengthInMinutes": 33,
            "storeActive": false,
            "storeHandle": null,
            "publicHandle": "asdfa",
            "schedulerHandle": null,
            "bio": "",
            "benefitsProviderId": null,
            "expertCategories": [
            ]
        },
        "externalAuthor": {
            "additionalInformation": {},
            "firstName": "Willow",
            "lastName": "Editorial Team"
        },
        "isReadingMaterial":true,
        "isVideo": false,
        "isGoal":false,
        "categories":[
            {
                "id":31,
                "parentCategoryIds": [44],
                "title":"Career advancement"
            }
        ],
        "createdOn": "2022-01-08T05:00:00+00:00"
    },
    article, article, article
];

export const devConsumerInsightsSearch = [
    {
        "id": 4,
        "title": "Course title 2",
        "description": "Course description",
        "imageUrl": "https://picsum.photos/200/300",
        "isActive": true,
        "coach": {
            "coachId": 12,
            "wmcId": 1,
            "first": "Mark",
            "last": "Advisor",
            "phone": 5005550002,
            "email": "",
            "photo": "",
            "defaultCallLengthInMinutes": 33,
            "storeActive": false,
            "storeHandle": null,
            "publicHandle": "asdfa",
            "schedulerHandle": null,
            "bio": "",
            "benefitsProviderId": null,
            "expertCategories": [
            ]
        },
        "externalAuthor": {
            "additionalInformation": {},
            "firstName": "Willow",
            "lastName": "Editorial Team"
        },
        "isReadingMaterial":false,
        "isVideo": false,
        "isGoal":false,
        "categories":[
            {
                "id":31,
                "parentCategoryIds": [44],
                "title":"Career advancement"
            }
        ],
        "createdOn": "2022-01-08T05:00:00+00:00"
    },
    {
        "id": 10,
        "title": "Course title 3",
        "description": "Course description",
        "imageUrl": "https://picsum.photos/200/300",
        "isActive": true,
        "coach": {
            "coachId": 12,
            "wmcId": 1,
            "first": "Mark",
            "last": "Advisor",
            "phone": 5005550002,
            "email": "",
            "photo": "",
            "defaultCallLengthInMinutes": 33,
            "storeActive": false,
            "storeHandle": null,
            "publicHandle": "asdfa",
            "schedulerHandle": null,
            "bio": "",
            "benefitsProviderId": null,
            "expertCategories": [
            ]
        },
        "externalAuthor": {
            "additionalInformation": {},
            "firstName": "Willow",
            "lastName": "Editorial Team"
        },
        "isReadingMaterial":false,
        "isVideo": false,
        "isGoal":false,
        "categories":[
            {
                "id":31,
                "parentCategoryIds": [44],
                "title":"Career advancement"
            }
        ],
        "createdOn": "2022-01-08T05:00:00+00:00"
    }
];
