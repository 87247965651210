import {axiosReq} from "../../../common/utils/axios";

import {setTeamMembers, setTeamMembersFetching} from "../store/team";

import {devConsumerTeamMembers} from '../static/devdata';

const removeOneNotATeamMember = async (teamMember) => {
    let isNotATeamMember = false;
    await Promise.all(teamMember.expertCategories.map(async c => {
        if(c.categoryId === 52) {
            isNotATeamMember = true
        }
    }))
    return isNotATeamMember
};

const removeAllNotATeamMembers = async (allCoaches) => {
    return Promise.all(allCoaches.map(async a => {
        const isNotATeamMember = await removeOneNotATeamMember(a);
        return ({...a, "notATeamMember": isNotATeamMember});
    }))
};

const filterAllNotATeamMembers = async (allCoaches) => {
    const allCoachesWithFlag = await removeAllNotATeamMembers(allCoaches);
    return allCoachesWithFlag.filter(f => {return (!f.notATeamMember)})
}

export const tryGetAllTeamMembers = () => {
    return async dispatch => {
        dispatch(setTeamMembersFetching(true));
        const team = await axiosReq('apiv1/consumer/coach/all-my-coaches', "GET", {}, devConsumerTeamMembers);
        const teamAdj = await filterAllNotATeamMembers(team.allCoaches);
        dispatch(setTeamMembers(teamAdj));
        dispatch(setTeamMembersFetching(false));
        return teamAdj;
    }
};