import {v4 as uuid4} from 'uuid';
import {images} from '../../../common/components/images';

export const devIframeHandleData = (isFound, handle) => {
    if(isFound) {
        return({
            "id": 12,
            "publicHandle": handle,
            "expertCategoryName": "coach", // coach, advisor, etc
            "expertCategoryId": 1
        })
    } else {
        return({
            "error": "Public handle can not be found",
            "errorDetails": "Expert does not exist"
        })
    }
};

/* Middleware - tryGetSettingsEnterpriseProfile */
// coachingcert, advancedDegree, otherEducation
export const devIframeProfileData = {
    id: '32',

    firstName: "Megan",
    lastName: "Scipione",
    companyName: 'Inkberry Financial',
    crd: 'test 5',

    photo: images.devDataCoachMeganAvatar,
    introVideoUrl: "", //'https://www.youtube.com/embed/RZAF1DAqHPk',
    facebookUrl: 'https://facebook.com',
    linkedinUrl: 'https://linkedin.com',
    twitterUrl: 'https://twitter.com',
    address1: 'test 3',
    address2: 'test 6',
    city: 'Boston',
    state: 'MA',
    zipCode: '23443',
    bio: '<p>Megan Scipione, CFA is a financial wellness coach based in Massachusetts. She teaches professional women how to optimize their financial lives to build wealth and reduce stress. She is a true believer in planning ahead, optimism, and intentionality. Her professional background includes 20+ years of experience in the financial services industry, focused specifically on improving retirement outcomes. Megan is a graduate of Tulane University and lives with her husband and two teenage daughters in suburban Boston.</p>',

    // BEG COACH ONLY FIELDS
    endorsement1: "<p>I worked with Megan to get my finances on the right track. Now, even though I am furloughed from work due to Covid-19, I feel calm and I am not stressed about money. If this had happened two years ago, I'd be panicked. - Riane</p>",
    endorsement2: "<p>Megan helped get my husband and me on the same page about dealing with our finances and planning for our future. The real world examples and scenarios for each recommendation helped us bring it back to our own financial plans and see how important it was to making our plans real. - Melissa</p>",
    endorsement3: "<p>Megan was tremendously helpful to me as I took steps towards financial wellness. She listened well, offered helpful guidance and road maps, and responded quickly to questions between sessions. I highly recommend her to anyone who wants more financial wellness.- Glen</p>",
    testimonials: [
        {
            "id": uuid4(),
            "body": "<p>I worked with Megan to get my finances on the right track. Now, even though I am furloughed from work due to Covid-19, I feel calm and I am not stressed about money. If this had happened two years ago, I'd be panicked.</p>",
            "person": "Riane"
        },
        {
            "id": uuid4(),
            "body": "<p>Megan helped get my husband and me on the same page about dealing with our finances and planning for our future. The real world examples and scenarios for each recommendation helped us bring it back to our own financial plans and see how important it was to making our plans real.</p>",
            "person": "Melissa"
        },
        {
            "id": uuid4(),
            "body": "<p>Megan was tremendously helpful to me as I took steps towards financial wellness. She listened well, offered helpful guidance and road maps, and responded quickly to questions between sessions. I highly recommend her to anyone who wants more financial wellness.</p>",
            "person": "Glen"
        },
        // {
        //     "body": "<p>Megan helped get my husband and me on the same page about dealing with our finances and planning for our future. The real world examples and scenarios for each recommendation helped us bring it back to our own financial plans and see how important it was to making our plans real.</p>",
        //     "person": "Melissa"
        // },
        // {
        //     "body": "<p>Megan was tremendously helpful to me as I took steps towards financial wellness. She listened well, offered helpful guidance and road maps, and responded quickly to questions between sessions. I highly recommend her to anyone who wants more financial wellness.</p>",
        //     "person": "Glen"
        // }
    ],
    freeTime: 'Test 7',
    whyBecameCoach: 'Test 2',
    coachPhilosophy: 'Test 3',
    highlights: 'I help busy professionals optimize their finances so they can achieve their money goals. Be confident that your personal finances are on the right track.',

    coachingCertification: null,
    coachingCertificationYear: null,

    // END COACH ONLY FIELDS

    bookingAvailability: 'available',

    screeningCallLength: 30,
    defaultCallLength: 15,

    advancedDegree: null,
    advancedDegreeCity: null,
    advancedDegreeInstitution: null,
    advancedDegreeState: null,
    advancedDegreeYear: null,

    otherEducation: null,

    expertCategories: [
        {
            "categoryId": 123,
            "categoryName": "Financial Coach"
        },
        {
            "categoryId": 5434,
            "categoryName": "Career Coach"
        }
    ],

    portfolio: [
        {
            "id": uuid4(),
            "photo": images.devDataStorePortfolio,
            "title": "Financial Wellness Roadmap",
            "startMonth": null,
            "startYear": null,
            "endMonth": null,
            "endYear": null,
            "endOngoing": true,
            "body": "<p>Worked with a client to conquer their money worries and learn to organize their finances with a simple framework designed to help them accomplish their goals. My client had an established career in their prime income years. They needed help optimizing the management of their household finances to reduce stress and position their family to confidently navigate all life milestones. I provided guidelines on how and why to budget, pay off and avoid debt, save, and invest.</p>"
        },
        // {
        //     "photo": images.devDataStorePortfolio,
        //     "title": "Financial Wellness Roadmap",
        //     "datetime": "February 13, 2021",
        //     "body": "Worked with a client to conquer their money worries and learn to organize their finances with a simple framework designed to help them accomplish their goals. My client had an established career in their prime income years. They needed help optimizing the management of their household finances to reduce stress and position their family to confidently navigate all life milestones. I provided guidelines on how and why to budget, pay off and avoid debt, save, and invest."
        // }
    ],

    degrees: [
        {
            "id": uuid4(),
            photo: images.devDataStoreSchool,
            title: "Tulane University",
            body: "<p>Description</p>",
            degree: null,
            "startMonth": 4,
            "startYear": 2009,
            "endMonth": 9,
            "endYear": 2010,
            "endOngoing": true,
            city: "Boston",
            state: "MA",
        }
    ],

    jobs: [
        {
            "id": uuid4(),
            photo: images.devDataStoreJobInkberry,
            company: "Inkberry Financial",
            title: "Financial Wellness Coach and Consultant",
            body: "I teach busy professionals a framework for managing household finances, so that they can confidently navigate through every life stage and achieve money goals.",
            "startMonth": 4,
            "startYear": 2009,
            "endMonth": 9,
            "endYear": 2010,
            "endOngoing": true,
            city: "Boston",
            state: "MA",
        },
        {
            "id": uuid4(),
            photo: images.devDataStoreJobStateStreet,
            company: "State Street Global Advisors",
            title: "Vice President, Relationship Manager",
            body: "Relationship Manager responsible for Institutional clients.",
            "startMonth": null,
            "startYear": 2009,
            "endMonth": null,
            "endYear": null,
            "endOngoing": false,
            city: null,
            state: null,
        }
    ],

    licenses: [
        {
            "id": uuid4(),
            photo: images.devDataStoreCertCFA,
            // photo: null, //licdn.com/dms/image/C510BAQFnaGYkNVvCbw/company-logo_100_100/0/1519912453241?e=1620259200&v=beta&t=-IZjv5PMQkDegqJHSbE0trQmxHx1PFUVi7cTlh4ij2A",
            title: "Chartered Financial Analyst",
            institute: "CFA Institute",
            "endMonth": null,
            "endYear": null,
            licenseNumber: null
        },
        {
            "id": uuid4(),
            photo: images.devDataStoreCertFinra,
            title: "Series 3, 7, 63 (expired)",
            institute: "FINRA",
            "endMonth": null,
            "endYear": null,
            licenseNumber: null
        }
    ],


    faqs: [
        {
            "id": uuid4(),
            title: "Are you a financial advisor?",
            body: "<p>I am a financial wellness coach. I show clients how to organize and manage their household finances so that they are in a position to build wealth over time. I do not manage clients’ investments, nor do I provide investment advice.</p>",
        },
        {
            "id": uuid4(),
            title: "Who are your typical clients?",
            body: "<p>Typical clients are busy professionals who are successful in their chosen fields but who lack the time and interest to put together a household financial plan that will allow them to achieve the dual objectives of living their best lives today and securing their future.</p>",
        },
        {
            "id": uuid4(),
            title: "Are you going to sell me stuff?",
            body: "<p>No. As a financial wellness coach, I do not sell investment products or insurance. I provide unbiased guidance and I do not receive compensation from third parties or others as it relates to my engagement with any individual client.</p>",
        },
        {
            "id": uuid4(),
            title: "How much work will this be for me?",
            body: "<p>Before the Clarity meeting, your prep work includes filling out several online forms. For most clients, all it takes is 30-60 minutes of prep. Ongoing coaching is a bigger time  commitment, with homework between regularly scheduled meetings.</p>",
        },
        {
            "id": uuid4(),
            title: " How can I afford this?",
            body: "<p>Most Americans - even high income earners -  are living paycheck to paycheck because they do not have a plan for their money. The Clarity meeting will provide that plan and show you that you have room in your budget for expert advice that will transform your finances.</p>",
        },
        {
            "id": uuid4(),
            title: "Should my spouse be involved?",
            body: "<p>Yes! If you are married, both spouses are expected to participate in the Clarity meeting and ongoing coaching . If you’re not currently on the “same page” financially speaking, a Clarity meeting is a great way to level set. Winning with money requires couples to work as a team.</p>",
        },
    ],

    interests: ["Ellevate Network"],
    skills: ["Financial Guidance", "Savings Plans"],

    profileActive: true,
    profileShowEmailer: true,
    profileShowScheduler: true,
    schedulerActive: true,
    schedulerHandle: "sampleadvisor",
    storeActive: true,
    storeHandle: "sampleadvisor",
    storePhoto: null,
    storeShowEmailer: true,
    storeShowScheduler: true,
    storeTitle: "My Store!",
    storeDescription: "<p>Here is a description of my services</p>",
    website: "https://doeraydesign.com/",
    instagramUrl: "https://instagram.com/doeraydesign",
    blogUrl: "https://google.com",
    heroImageUrl: images.devDataStoreHero,
    publicHandle: "meganscipione",
    signUpDate:"2020-10-22T14:55:00.857405+00:00",
    isWMCEmployee: false
};
