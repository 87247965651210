import { combineReducers } from 'redux';

/* Reducers */
import userReducer from '../routes/store/user';
import navReducer from './containers/navexpanded/store/nav';
// import messagesReducer from './messages';
import noticeCallReducer from "./containers/noticemeeting/store/noticecall";
// import feedbackReducer from './feedback';
// import helpReducer from './help';
// import settingsReducer from './settings';
import ratingReducer from "./containers/noticerating/store/rating";
import mymoneyReducer from "./containers/mymoney/store/mymoney";
import wmcReducer from './containers/company/store/wmc';
import fileVaultReducer from './containers/vault/store/filevault';
import imagesReducer from './containers/imageupload/store/images';

const commonReducer = combineReducers({
    user: userReducer,
    nav: navReducer,
    noticeCall: noticeCallReducer,
    rating: ratingReducer,
    // messages: messagesReducer,
    // feedback: feedbackReducer,
    // help: helpReducer,
    // settings: settingsReducer,
    mymoney: mymoneyReducer,
    wmc: wmcReducer,
    fileVault: fileVaultReducer,
    images: imagesReducer
});

export default commonReducer;
