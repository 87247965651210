import React from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import {Map} from 'immutable';

/* Components */
import { colors } from "../../../components/colors";
import {FontHeader21, FontHeader16, FontTitle18, FontBody16} from "../../../components/fonts";
import {CloseOutlined} from '@ant-design/icons';
import {InlineBlock, SaveSection, SubmitInputWithValidation} from "../../../../enterprise/components/clientscreate";
import {ButtonTertiary} from "../../../components/buttons";

/* Middleware */
import { tryDeleteFolder, tryDeleteFile } from '../middleware/filevault';

/* Store */
import {setFolderOrFileDeleteModal, setFolderOrFileSelected} from "../store/filevault";

/* Icons */
import {images} from '../../../components/images';

const Modal = styled.div`
    position: relative;
    margin: 0px auto;
    top: 110px;
    z-index: 10;
    background-color: ${colors.white};
    border: 1px solid ${colors.border100};
    border-radius: 6px;
    width: 100%;
    height: auto;
    max-width: 550px;
    @media screen and (max-width: 640px) {
        top: 80px;
        width: calc(100% - 20px);
    }
`;
const Fixed = styled.div`
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 8;
`;
const Background = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9;
`;
const Title = styled.div`
    padding: 25px;
`;
const Inline = styled.div`
    width: calc(100% - 77px);
    display: inline-block;
    vertical-align: top;
`;
const Image = styled.img`
    width: 32px;
    margin-right: 20px;
    vertical-align: top;
    display: inline-block;
`;
const Close = styled.div`
    width: 25px;
    vertical-align: top;
    display: inline-block;
    color: ${colors.secondary100};
    cursor: pointer;
`;
const Form = styled.form`
    padding: 0px 25px 25px 25px;
`;
const Success = styled.div`
    padding: 40px 25px;
    text-align: center;
`;
const CTA = styled.div`
    word-break: break-all;
`;

const initState = {"folderOrFileDeleteModal": false, "folderOrFileSelected": Map(), "documentType": "File", "folderName": "", "isSaved": false, "isSaving": false};

class VaultDelete extends React.Component {
    state=initState;

    componentDidMount() {
        this.setState({"folderOrFileDeleteModal": this.props.folderOrFileDeleteModal})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.folderOrFileDeleteModal !== this.props.folderOrFileDeleteModal && this.props.folderOrFileDeleteModal) {
            this.setState({
                "folderOrFileDeleteModal": this.props.folderOrFileDeleteModal,
                "folderOrFileSelected": this.props.folderOrFileSelected,
                "folderName": this.props.folderOrFileSelected.get("name"),
                "documentType": this.props.folderOrFileSelected.get("documentType").charAt(0).toUpperCase()+this.props.folderOrFileSelected.get("documentType").substring(1)
            })
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    timeout = 0;

    close = async () => {
        await this.props.setFolderOrFileDeleteModal(false);
        await this.setState(initState);
        this.props.setFolderOrFileSelected(Map());
    };

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({'isSaving': true});
        if(this.state.documentType === "File") {
            await this.props.tryDeleteFile(this.state.folderOrFileSelected.get("id"));
        } else {
            await this.props.tryDeleteFolder(this.state.folderOrFileSelected.get("id"));
        }
        this.setState({'isSaving': false, 'isSaved': true});
        this.timeout = setTimeout(this.close, 1000);
    };

    render() {
        if(!this.state.folderOrFileDeleteModal) {
            return null
        } else {
            return (
                <Fixed>
                    <Modal>
                        {this.state.isSaved
                            ? <Success><FontTitle18>{this.state.documentType+" deleted!"}</FontTitle18></Success>
                            : <>
                                <Title>
                                    <Image src={this.state.documentType === "File" ? images.vaultFileGeneric : images.vaultFolderPrivate} alt={""} />
                                    <Inline><FontHeader21>{"Delete "+this.state.documentType}</FontHeader21></Inline>
                                    <Close onClick={this.close}><FontHeader16><CloseOutlined /></FontHeader16></Close>
                                </Title>
                                <Form method={"post"} onSubmit={this.handleSubmit}>
                                    <br />
                                    <CTA><FontBody16>Are you sure you want to delete <b>{this.state.folderName}</b>?</FontBody16></CTA>
                                    <SaveSection>
                                        <InlineBlock><SubmitInputWithValidation label={"Delete"} isChanged={true} isValid={true} isSaving={this.state.isSaving} /></InlineBlock>
                                        <InlineBlock><div onClick={this.close}><ButtonTertiary canSubmit={false} label={"Cancel"} /></div></InlineBlock>
                                    </SaveSection>
                                </Form>
                            </>
                        }
                    </Modal>
                    {this.state.isSaved && <Background onClick={this.close} />}
                </Fixed>
            )
        }
    }
}

const mapStateToProps = state => ({
    folderOrFileDeleteModal: state.common.fileVault.get("folderOrFileDeleteModal"),
    folderOrFileSelected: state.common.fileVault.get("folderOrFileSelected")
});

const mapDispatchToProps = dispatch => ({
    setFolderOrFileDeleteModal: (status) => dispatch(setFolderOrFileDeleteModal(status)),
    setFolderOrFileSelected: (status) => dispatch(setFolderOrFileSelected(status)),
    tryDeleteFile: (fileId) => dispatch(tryDeleteFile(fileId)),
    tryDeleteFolder: (folderId) => dispatch(tryDeleteFolder(folderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VaultDelete);