import React from 'react';
import {withRouter} from 'react-router-dom';

/* Components */
import {PageFull} from '../navbars/components/navlayout';
import NavProgress from "./components/navprogress";
import {PageTitle, Container, ContainerTop} from "./components/progress";
import ConsumerNav from '../navbars/containers/nav';

/* Containers */
import ProgressListToDo from './containers/progresstodo';
import ProgressAppointments from './containers/progressappointments';
import ProgressListGoals from './containers/progressgoals';
import CreateGoalFlow from "./containers/creategoal";
import ProgressFinances from "./containers/progressfinances";
import {PageFullV2} from "../home/page";
// import UpdateGoalFlow from "./containers/updategoal";

const Courses = ({location}) => {
    if(location.pathname.includes("/progress/goals/create")) {
        return(<CreateGoalFlow />)
    // } else if(location.pathname.includes("/progress/goals/update")) {
    //     return(<UpdateGoalFlow />)
    } else {
        return (
            <>
                <ConsumerNav />
                <PageFullV2 showBackground={true}>
                    <ContainerTop>
                        <PageTitle>MY PROGRESS</PageTitle>
                        {/*<PageSubtitle>You're doing great! Keep it up!</PageSubtitle>*/}
                        <NavProgress />
                    </ContainerTop>
                    <Container>        
                        {/*{location.pathname === "/progress" && <ProgressListToDo />}*/}
                        {location.pathname === "/progress/appointments" && <ProgressAppointments />}
                        {/*{location.pathname === "/progress/goals" && <ProgressListGoals />}*/}
                        {location.pathname.includes("/progress/finances") && <ProgressFinances />}
                        {/* {location.pathname === "/progress/goals/check-in" && <UpdateGoalFlow />} */}
                    </Container>
                </PageFullV2>
            </>
        );
    }
};

export default withRouter(Courses);

