import React from "react";
import ReactQuill from "react-quill";
import {v4 as uuid4} from "uuid";
import moment from "moment";
import {connect} from "react-redux";
import styled from "styled-components";

/* Components */
import {QuillSlim} from "../../common/components/richtexteditor";
import {colors} from "../../common/components/colors";
import {FileTextFilled} from "@ant-design/icons";
import {FontBody12, FontHeader16, FontTitle18} from "../../common/components/fonts";
import {Card, Icon, Body, Title, Action, Inline, ActionLink, BottomBorder5, Content} from '../components/contactactivity';
import {LogoLoading} from "../../common/components/loading";

import {DownOutlined, UpOutlined} from '@ant-design/icons';

/* Middleware */
import {tryGetEnterpriseComments, shouldCommentNote} from "../comments/middleware/comments";
import {shouldEditNote} from "../notes/middleware/notes";

/* Store */
import {setNoteConfirmModal} from "../notes/store/notes";

export const QuillMedium = styled.div`
    background-color: ${colors.border30};
    margin-top: 20px;
    margin-bottom: 10px;
    border: 1px solid ${props => props.bodyBorderColor} !important;
    .ql-editor {
        margin: 10px;
        padding: 20px;
        font-family: GraphicWebRegular;
        font-size: 14px;
        line-height: 21px;
    }
    .ql-toolbar {
        border-top: 0px solid transparent !important;
        border-left: 0px solid transparent !important;
        border-right: 0px solid transparent !important;
        border-bottom: 1px solid ${colors.border100} !important;
    }
    .ql-container {
        margin-top: 10px;
        border: 0px solid transparent !important;
        min-height: 70px;
    }
`;
export const Comments = styled.div`
    width: calc(100% - 10px);
    display: inline-block;
    vertical-align: top;
    padding: 5px;
`;

export const CommentHeader = styled.div`
    display: inline-block;
    vertical-align: bottom;
    width: calc(100% - 200px);
    padding-left: 15px;
    padding-top: 15px;
    @media screen and (max-width: 1440px) {
        text-align: left;
    }
    @media screen and (max-width: 460px) {
        width: 100%;
        padding-left: 5px;
    }
`;
export const DateItem = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-top: 3px;
    text-align: left;
    width: 150px;
    @media screen and (max-width: 1440px) {
        margin-top: 8px;
    }
    @media screen and (max-width: 1088px) {
        margin-top: 3px;
    }
`;
export const CommentLabel = styled.div`
    display: inline-block;
    vertical-align: top;
    padding-right: 10px;
`;
export const InlineAction = styled.div`
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 150px);
`;
export const NoCommentsLabel = styled.div`
    padding: 10px;
    text-align: center;
    text-color: ${colors.primary70};
`;
class TeamNote extends React.Component {
    state={isLoading: true, data: {}, quill: '', commentsShown: false, comments: [], commentsCount: 0};

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.data !== this.props.data || prevProps.commentModal !== this.props.commentModal) {
            return this.init();
        }
    }

    init = async () => {
        await this.setState({
            "data": this.props.data,
            'quill' : <ReactQuill
                id={uuid4()}
                readOnly={true}
                theme={null}
                value={this.props.data.body}
                bounds={'.app'}
            />
        });
        const commentsList = await this.props.tryGetEnterpriseComments(this.state.data.id);
        this.setState({"comments": commentsList});
        return this.setState({"isLoading": false});
    };

    onClickFunc = async () => {
        await this.props.shouldCommentNote(
            {...this.state.data,
            "noteId": this.state.data.id,
            "comment": true,
            "numberOfComments": this.state.data.numberOfComments});
    };

    reloadComments = async () => {
        this.setState({'commentsShown': !this.state.commentsShown});
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <Card>
                    <Icon><FileTextFilled /></Icon>
                    <Body>
                        <div>
                            <Title><FontTitle18>{this.state.data.author}</FontTitle18></Title>
                            <Action>
                                <InlineAction>
                                    {this.state.data.authorId === this.props.userId &&
                                        <>
                                            <Inline><ActionLink onClick={() => this.props.shouldEditNote({...this.state.data, "clientId": this.props.client.get("consumerId"), "noteId": this.state.data.id, "name": this.props.client.get("firstName")+" "+this.props.client.get("lastName"), "edit": true})}><FontHeader16>Edit</FontHeader16></ActionLink></Inline>
                                            <Inline><ActionLink onClick={() => this.props.setNoteConfirmModal(true, this.state.data.id)}><FontHeader16>Delete</FontHeader16></ActionLink></Inline>
                                        </>
                                    }
                                    <Inline><ActionLink onClick={() => this.onClickFunc()}><FontHeader16>Respond</FontHeader16></ActionLink></Inline>
                                </InlineAction>
                                <DateItem><FontBody12>{moment(this.state.data.datetime).format("MMM D, YYYY [at] h:mm a")}</FontBody12></DateItem>
                            </Action>
                        </div>
                        <Content>
                            <BottomBorder5>
                                <FontHeader16>{this.state.data.title}</FontHeader16>
                            </BottomBorder5>
                            <QuillSlim bodyBorderColor={colors.white}>{this.state.quill}</QuillSlim>
                        </Content>
                    </Body>
                    <Comments>
                        <ActionLink onClick={() => this.reloadComments()}>
                            <FontHeader16>
                                Comments ( {this.state.comments.length} ) {this.state.commentsShown ? <DownOutlined/> : <UpOutlined/>}
                            </FontHeader16>
                        </ActionLink>
                        {this.state.commentsShown && <div>
                            {(this.state.comments.length === 0) ? <NoCommentsLabel><FontHeader16>Be the first to comment!</FontHeader16></NoCommentsLabel>
                            :this.state.comments.map((comment) =>
                            <QuillMedium key={uuid4()} bodyBorderColor={colors.secondary30}>
                                <CommentHeader>
                                    <CommentLabel><FontHeader16>{comment.author} left a comment</FontHeader16></CommentLabel>
                                    <DateItem><FontBody12>{moment(comment.created).format("MMM D, YYYY [at] h:mm a")}</FontBody12></DateItem>
                                </CommentHeader>
                                <ReactQuill id={uuid4()} readOnly={true} theme={null} value={comment.content} bounds={'.app'}/>
                            </QuillMedium>)}
                        </div>}

                    </Comments>

                </Card>
            )
        }
    }
}

const mapStateToProps = state => ({
    client: state.enterprise.clientsDetail.get("client"),
    commentModal: state.enterprise.comments.comments.get("commentModal"),
    activity: state.enterprise.clientsActivity.get("clientActivity"),
    userId: state.common.user.get("userId"),
});

const mapDispatchToProps = dispatch => ({
    shouldEditNote: (note) => dispatch(shouldEditNote(note)),
    setNoteConfirmModal: (status, noteId) => dispatch(setNoteConfirmModal(status, noteId)),
    shouldCommentNote: (note) => dispatch(shouldCommentNote(note)),
    tryGetEnterpriseComments: (noteId) => dispatch(tryGetEnterpriseComments(noteId))
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamNote);

