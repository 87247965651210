import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

/* Components */
import {FontTitle18, FontBody16} from '../../../common/components/fonts';
import {LogoLoading} from "../../../common/components/loading";
import {Icon14, Specialty} from "../components/about";
import {Star} from "react-feather";
import {EditOutlined, DeleteOutlined} from "@ant-design/icons";
import {FitContent, InlineBlock, SaveSection, SubmitInputWithValidation} from "../components/myprofile";
import {ButtonSecondary, ButtonTertiary} from "../../../common/components/buttons";
import {colors} from "../../../common/components/colors";
import {SearchInputUncontrolledDisplay} from "../../../common/components/inputs";
import {SavedSettings} from '../../../common/components/cards';

import {tryUpdateExpertCategories, tryGetCoachCategories} from '../middleware/expertprofile';

const LoadingCenter = styled.div`
    margin: 60px auto;
`;
export const Btn = styled.div`
    color: ${colors.action100};
    cursor: pointer;
`;
const Alert = styled.div`
    color: ${colors.alert100}
`;

const whoOptions = (who) => {
    return who.map(w => {
        return({
            value: {
                categoryId: w.categoryId
            },
            label: w.categoryName
        })
    });
};

class ProfileSpecialties extends React.Component {
    state = {
        "expertCategories": [], 
        "isLoading": true, 
        "newCategory": "", 
        "newCategoryValid": true, 
        "categoriesPrevious": [], 
        "isChanged": false,
        "isEdit": false,
        "isValid": false,
        'isSaving': false,
        "isSaved": false,
        "allCategories": [],
        "newCategoryLabel": "",
        "pauseUpdate": false
    };

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.profile !== this.props.profile && !this.state.pauseUpdate) {
            return this.init();
        }
    }

    init = async () => {
        await this.setState({
            "expertCategories": this.props.profile.get("expertCategories").toJS(),
            "categoriesPrevious": this.props.profile.get("expertCategories").toJS(),
            "allCategories": await this.props.tryGetCoachCategories(),
            "isLoading": false,
            "isChanged": false,
        });
    };

    reset = async () => {
        await this.setState({
            "expertCategories": this.state.categoriesPrevious,
            "newCategory": "",
            "newCategoryValid": true,
            "isChanged": false,
            "isEdit": false
        });
    };

    edit = async (status) => {
        this.setState({"isEdit": status});
    };

    handleChangeNewCategory = async e => {
        await this.setState({
            'newCategory': e,
            'newCategoryValid': true,
            'isChanged': true,
            'newCategoryLabel': e.label
        });
        this.checkValid();
    };

    checkValid = async () => {
        if(this.state.newCategoryValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    delete = async (num) => {
        let categories = await whoOptions(this.props.profile.get("expertCategories").toJS());
        await categories.splice(num, 1)
        let categoryIds = await categories.map(a => {return(a.value.categoryId)})
        await this.setState({"pauseUpdate": true})
        await this.props.tryUpdateExpertCategories(categoryIds);
        await this.setState({"pauseUpdate": false})
        this.init();
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            await this.setState({'isSaving': true, "error": ""});
            let categories;
            if (this.props.profile.get("expertCategories") !== null && this.props.profile.get("expertCategories") !== undefined) {
                categories = whoOptions(this.props.profile.get("expertCategories").toJS());
                await categories.push(this.state.newCategory);
            } else {
                categories = [this.state.newCategory]
            }
            let categoryIds = await categories.map(a => {return(a.value.categoryId)})
            await this.setState({"pauseUpdate": true})
            await this.props.tryUpdateExpertCategories(categoryIds);
            await this.setState({"pauseUpdate": false})
            await this.setState({
                'isSaving': false,
                "isSaved": true,
                // "categoriesPrevious": this.state.expertCategories,
                "newCategory": "",
                "newCategoryValid": true,
                "expertCategories": this.props.profile.get("expertCategories").toJS(),
                "categoriesPrevious": this.props.profile.get("expertCategories").toJS(),
                "isEdit": false,
                "newCategoryLabel": ""
            });
            this.init();
            this.timeout = setTimeout(() => {this.setState({'isSaved': false, "isChanged": false})}, 3000);
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    {this.state.isSaved
                        ?
                            <>
                                {this.state.error === '' && <SavedSettings height={"99px"} error={false} message={"Saved"}/>}
                                {this.state.error !== '' && <SavedSettings height={"99px"} error={true} message={this.state.error}/>}
                            </>
                        :
                            <>
                                {!this.state.isEdit &&
                                    <>
                                        <FontTitle18>Specialties</FontTitle18>
                                        {this.state.expertCategories.length === 0 ?
                                            <>
                                                <br />
                                                <br />
                                                <FontBody16>No specialties</FontBody16>
                                                <br/>
                                                <FitContent><FontBody16 onClick={() => {return this.edit(true)}}><ButtonSecondary canSubmit={false} label={<><EditOutlined/>&nbsp;Add Specialities</>} /></FontBody16></FitContent>
                                            </>
                                        :
                                            <>
                                                <br />
                                                <br />
                                                {this.state.expertCategories.map(e => (
                                                    <Specialty key={e.categoryId}><FontBody16><Icon14><Star /></Icon14>&nbsp;{e.categoryName}</FontBody16></Specialty>
                                                ))}
                                                <br />
                                                <br />
                                                <FitContent onClick={() => {return this.edit(true)}}>
                                                    <ButtonSecondary canSubmit={false} label={<><EditOutlined />&nbsp;Change Specialties</>} />
                                                </FitContent>
                                            </>
                                        }
                                    </>
                                }
                                {this.state.isEdit &&
                                    <>
                                        <FontTitle18>Specialties</FontTitle18>
                                        {this.state.expertCategories.length === 0
                                            ?
                                                <><br /><br /><FontBody16>No specialties</FontBody16></>
                                            :
                                                <>
                                                    <br />
                                                    <br />
                                                    {this.state.expertCategories.length === 1 ?
                                                        <>
                                                            {this.state.expertCategories.map((e, indexE) => (
                                                                <Specialty key={indexE}>
                                                                    <FontBody16><Icon14><Star /></Icon14>&nbsp;{e.categoryName}</FontBody16>
                                                                </Specialty>
                                                            ))}
                                                             <FontBody16><Alert>* Must have at least one specialty</Alert></FontBody16>
                                                        </>
                                                        :
                                                        this.state.expertCategories.map((e, indexE) => (
                                                            <Specialty key={indexE}>
                                                                <FontBody16><Icon14><Star /></Icon14>&nbsp;{e.categoryName}</FontBody16>
                                                                <FitContent>
                                                                    <Btn>
                                                                        <FontBody16 onClick={() => {return this.delete(indexE)}}><DeleteOutlined />&nbsp;Delete</FontBody16>
                                                                    </Btn>
                                                                </FitContent>
                                                            </Specialty>
                                                        ))
                                                    }
                                                </>
                                        }
                                        <br/>
                                        <form method={"post"} onSubmit={this.handleSubmit}>
                                            <SearchInputUncontrolledDisplay
                                                title={"Add Specialty"}
                                                options={whoOptions(this.state.allCategories)}
                                                id={'newCategory'}
                                                onChange={this.handleChangeNewCategory}
                                                placeholder={'Select specialties'}
                                                value={this.state.newCategoryLabel}
                                            />
                                            <SaveSection>
                                                <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                                <InlineBlock><div onClick={this.reset}><ButtonTertiary canSubmit={false} label={"Cancel"} /></div></InlineBlock>
                                            </SaveSection>
                                        </form>
                                    </>
                                }
                            </>
                        }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    profile: state.enterprise.shop.expertProfile.get("expertProfile"),
});

const mapDispatchToProps = dispatch => ({
    tryUpdateExpertCategories: (data) => dispatch(tryUpdateExpertCategories(data)),
    tryGetCoachCategories: () => dispatch(tryGetCoachCategories())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileSpecialties));
