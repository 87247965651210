import React from "react";
import styled from "styled-components";

/* Components */
import {colors} from "../../../common/components/colors";

/* Containers */
import IframeAbout from "../../../iframes/profile/containers/about";

export const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Section = styled(FlexCol)`
    gap: 20px;
`;

const SectionPadding = styled(Section)`
    padding: 70px 40px 100px;
    margin: -25px 0;
    background-color: ${colors.backgroundColor1};
`;

const ProfileAbout = (props) => {
    return (
        <SectionPadding>
            <IframeAbout profile={props.member} viewVerticalOnly={true}/>
        </SectionPadding>
    );
};

export default ProfileAbout;