import React from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from "styled-components";

/* Components */
import {Container, CTA, WriteToUsBtn, GutterTitle, PageTitle, Header, HeaderMobile, FlexRow, Extra54px, BackSection, Back, InlineBlock, SpaceBetween, Olive, ChevronLeftRight, OpenLink} from "../components/care";
import {LogoLoading} from "../../../common/components/loading";
import {SearchInputUncontrolled} from "../components/input";
import {FontBody16, FontTitle16, FontHeader16} from '../../../common/components/fonts';
import {ButtonPrimary} from "../../../common/components/buttons";
import {images} from "../../../common/components/images";
import SmallFeaturedArticle from './smallfeatured';

/* Middleware */
import {tryGetInsights, tryGetInsightSubCategories, trySearchInsights} from '../middleware/insights';

/* Store */
import {setInsightsSearchTerm} from '../store/insights';


/* Containers */
// import ContentHighlighted from './contenthighlightedfull';
// import ContentOne from './contentone';
// import InsightCategories from './contentoverview';

class LearnContentAll extends React.Component {
    state = {
            "content": [], 
            "isLoading": true, 
            "coursesAvailable": true,
            "options": [], 
            "searchResults": [], 
            'term': "", 
            "searchMade": false, 
            "haltUpdateSearch": false, 
        };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        console.log(this.props)
        if (this.props.insightSubCategorySelected != null) {
            console.log("hello", this.props.insightSubCategorySelected)
            await this.props.tryGetInsights(this.props.insightSubCategorySelected.get("id"));
        } else {
            console.log("hi", this.props.insightParentCategorySelected)
            await this.props.tryGetInsights(this.props.insightParentCategorySelected.get("id"));
        }
        const activeContent = await this.props.insights.filter(a => {return a.get("isActive") === true});
        const sortedContent = await activeContent.reverse();
        this.setState({
            "content": sortedContent,
            "coursesAvailable": (this.props.insights.size !== 0),
            "isLoading": false
        })
        console.log(this.props.insights)
        console.log("courses available", this.state.coursesAvailable)
    };

    back = () => {
        return this.props.history.push('/learn');
    };

    handleReturnResult = async (e) => {
        if(this.props.location.pathname !== '/learn/content/search') {
            console.log("push to search")
            this.props.history.push('/learn/content/search');
        }
        this.setState({'searchMade': true});
        await this.props.trySearchInsights(this.state.term);
    };

    handleCloseSearch = async (trigger) => {
        if(trigger === "clicked") {
            await this.setState({"tagSearched": false});
            await this.setState({"haltUpdateSearch": true});
            console.log("trigger clicked")
        }
        if(this.props.location.pathname !== '/learn/content') {
            this.props.history.push('/learn/content');

        }
        await this.setState({'searchInput': "", 'searchMade': false, 'searchResults': [], 'term': ""});
        if(trigger === "clicked") {
            await this.setState({"haltUpdateSearch": false});
        } else {
            document.getElementById("search-insights").select();
        }
    };

    searchMade = async (e) => {
        this.setState({'term': e});
        await this.props.setInsightsSearchTerm(e);
        console.log("in searchMade")
    };

    handleChange = async (e) => {
        console.log("handleChange", e.target.value)
        this.setState({"term": e.target.value})
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <div style={{backgroundColor: 'white'}}>
                    <Container style={{maxWidth: 'revert', gap: '10px', padding: '40px'}}>
                        {(this.state.searchMade && this.state.term !== "")
                        ?
                            <>
                                <Header>
                                    <BackSection>
                                        <Back onClick={() => {return this.handleCloseSearch("clicked")}}>
                                            <ChevronLeftRight src={images.chevronLeft} alt={""} />
                                            <InlineBlock><FontBody16>Back</FontBody16></InlineBlock>
                                        </Back>
                                    </BackSection>
                                    <Extra54px></Extra54px>
                                    <FlexRow>
                                        <PageTitle>SEARCH RESULTS</PageTitle>
                                    </FlexRow>
                                    <Extra54px></Extra54px>
                                    <SearchInputUncontrolled
                                        change={this.handleChange}
                                        onReturnResults={this.handleReturnResult}
                                        onSearchMade={this.searchMade}    
                                        value={this.state.term}
                                        options={[]}
                                        id={'search-insights'}
                                        >
                                    </SearchInputUncontrolled>
                                </Header>
                                <HeaderMobile>
                                    <BackSection>
                                        <Back onClick={() => {return this.handleCloseSearch("clicked")}}>
                                            <ChevronLeftRight src={images.chevronLeft} alt={""} />
                                            <InlineBlock><FontBody16>Back</FontBody16></InlineBlock>
                                        </Back>
                                    </BackSection>
                                    <Extra54px></Extra54px>
                                    <FlexRow>
                                        <PageTitle>SEARCH RESULTS</PageTitle>
                                    </FlexRow>
                                    <Extra54px></Extra54px>
                                    <SearchInputUncontrolled
                                        change={this.handleChange}
                                        onReturnResults={this.handleReturnResult}
                                        onSearchMade={this.searchMade}    
                                        value={this.state.term}
                                        options={[]}
                                        id={'search-insights'}
                                        >
                                    </SearchInputUncontrolled>
                                </HeaderMobile>
                                <Extra54px></Extra54px>
                                {this.props.searchResults.size > 0 
                                    ?
                                    <>
                                        {this.props.searchResults.map((c, index) => {
                                            return (
                                                <SmallFeaturedArticle data={c.toJS()}></SmallFeaturedArticle>
                                            )
                                        })}
                                    </>
                                    :
                                    <>
                                        <SpaceBetween direction={"column"}>
                                            <FontTitle16>No content found</FontTitle16>
                                            <div onClick={() => {return this.props.history.push('/learn/request')}}>
                                                <ButtonPrimary submit={false} label={"Request content"} />
                                            </div>
                                        </SpaceBetween>
                                    </>
                                }
                                <SpaceBetween direction={"row"} style={{marginTop: '15px'}}>
                                    <FontTitle16>Looking for other content?</FontTitle16>
                                    <WriteToUsBtn onClick={() => {return this.props.history.push('/learn/request')}}>
                                        <ButtonPrimary submit={false} label={"Write to us"} />
                                    </WriteToUsBtn>
                                </SpaceBetween>
                            </>
                        :
                            <>
                                <Header>
                                    <BackSection>
                                        <Back onClick={() => {return this.back()}}>
                                            <ChevronLeftRight src={images.chevronLeft} alt={""} />
                                            <InlineBlock><FontBody16>Back</FontBody16></InlineBlock>
                                        </Back>
                                    </BackSection>
                                    <Extra54px></Extra54px>
                                    <FlexRow style={{margin: 'auto'}}>
                                        <PageTitle>{this.props.insightSubCategorySelected !== null ? this.props.insightSubCategorySelected.get("title").toUpperCase() : this.props.insightParentCategorySelected.get("title").toUpperCase()}</PageTitle>
                                    </FlexRow>
                                    <Extra54px></Extra54px>
                                    <SearchInputUncontrolled
                                        change={this.handleChange}
                                        onReturnResults={this.handleReturnResult}
                                        onSearchMade={this.searchMade}    
                                        value={this.state.term}
                                        options={[]}
                                        id={'search-insights'}
                                    >
                                    </SearchInputUncontrolled>
                                </Header>
                                <HeaderMobile>
                                    <BackSection>
                                        <Back onClick={() => {return this.back()}}>
                                            <ChevronLeftRight src={images.chevronLeft} alt={""} />
                                            <InlineBlock><FontBody16>Back</FontBody16></InlineBlock>
                                        </Back>
                                    </BackSection>
                                    <Extra54px></Extra54px>
                                    <FlexRow>
                                        <PageTitle>{/*this.props.insightSubCategorySelected.size != 0 ? this.props.insightSubCategorySelected.get("title").toUpperCase() : */} {this.props.insightParentCategorySelected.get("title").toUpperCase()}</PageTitle>
                                    </FlexRow>
                                    <Extra54px></Extra54px>
                                    <SearchInputUncontrolled
                                        change={this.handleChange}
                                        onReturnResults={this.handleReturnResult}
                                        onSearchMade={this.searchMade}    
                                        // value={this.state.term}
                                        options={[]}
                                        // id={'search-insights'}
                                    >
                                    </SearchInputUncontrolled>
                                </HeaderMobile>
                                <div>
                                    {this.state.coursesAvailable ?
                                        <>
                                            <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                                                {this.state.content.map((a, index) => {
                                                    console.log(a);
                                                    return(
                                                    <SmallFeaturedArticle data={a.toJS()}></SmallFeaturedArticle>
                                                    )
                                                })}
                                            </div>
                                            <CTA style={{marginTop: '25px', marginBottom: '50px'}}>
                                                <SpaceBetween direction={"row"}>
                                                    <FontTitle16>Looking for other content?</FontTitle16>
                                                    <WriteToUsBtn onClick={() => {return this.props.history.push('/learn/request')}}>
                                                        <ButtonPrimary submit={false} label={"Write to us"} />
                                                    </WriteToUsBtn>
                                                </SpaceBetween>
                                            </CTA>
                                        </>
                                        :
                                        <>
                                            <br />
                                            <SpaceBetween direction={"column"}>
                                                <FontTitle16>No content found</FontTitle16>
                                                <div onClick={() => {return this.props.history.push('/learn/request')}}>
                                                    <ButtonPrimary submit={false} label={"Request content"} />
                                                </div>
                                            </SpaceBetween>
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </Container>
                </div>
            )
        }
    }
}

const mapStateToProps = state => ({
    insightSubCategorySelected: state.consumer.learn.insights.get("insightSubCategorySelected"),
    insightParentCategorySelected: state.consumer.learn.insights.get("insightParentCategorySelected"),
    searchResults: state.consumer.learn.insights.get("insightsSearchResults"),
    insights: state.consumer.learn.insights.get("insights")
});

const mapDispatchToProps = dispatch => ({
    tryGetInsights: (id, limit) => dispatch(tryGetInsights(id, limit)),
    tryGetInsightSubCategories: (id) => dispatch(tryGetInsightSubCategories(id)),
    trySearchInsights: (term) => dispatch(trySearchInsights(term)),
    setInsightsSearchTerm: (term) => dispatch(setInsightsSearchTerm(term))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LearnContentAll));