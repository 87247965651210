import React from 'react';
import styled from 'styled-components';
import moment from 'moment/moment';
import { withRouter } from 'react-router-dom';

/* Components */
import {colors} from "../../../common/components/colors";
import { images } from '../../../common/components/images';
import {FontSubtitle21, FontBody16, FontBody14, FontSubtitle18, FontBody18} from '../../../common/components/fonts';
import { IconButton } from '../../../common/components/buttons';

/* Utility */
import { hexToRGB, brightness } from '../../../common/utils/colors';

const Appointments = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
`;
const CoachCell = styled.div`
    background-color: ${props => props.backgroundColor};
     ${props => props.shapeStartTopLeftRound !== true} {
        border-radius: ${props => props.even ? '0px 20px 0px 20px' : '20px 0px 20px 0px'};
    }
     ${props => props.shapeStartTopLeftRound !== false} {
        border-radius: ${props => props.even ? '20px 0px 20px 0px' : '0px 20px 0px 20px'};
    }
    box-sizing: border-box;
    ${props => props.enableClick === false} {
        cursor: pointer;
    }
    color: ${props => brightness(hexToRGB(props.backgroundColor, false)) < 130 ? colors.white : colors.primary100};
    padding: ${props => props.dashboard ? '20px 30px 30px 30px' :'25px'};
    width: 100%;
    row-gap: 20px;
    display: flex;
    flex-direction: column;
    text-align: start;
`;
const CoachCell2 = styled.div`
    background-color: ${props => props.backgroundColor};
    box-sizing: border-box;
    border-radius: 0px 30px 0px 30px; 
    color: ${props => brightness(hexToRGB(props.backgroundColor, false)) < 130 ? colors.white : colors.primary100};
    padding: 25px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: start;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    align-items: flex-start;
    justify-content: space-between;
`;
const FlexRow = styled.div`
    column-gap: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const FlexRow2 = styled.div`
    column-gap: 25px;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    height: 100%;
    align-self: flex-start;
    align-items: center;
`;
const FlexRB = styled(FlexRow)`
    width: 100%;
    justify-content: space-between;
`;
const ProfileIcon = styled.img`
    border-radius: 50%;
    height: 50px;
    width: 50px;
    object-fit: cover;
`;
const ProfileIcon2 = styled.img`
    border-radius: 50%;
    height: 90px;
    width: 90px;
    object-fit: cover;
    cursor: pointer;
`
const FontSubtitle21CC = styled(FontSubtitle21)`
    align-self: center;
`;
const FlexRowDash = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    column-gap: 20px;
`;
const ColumnFlex = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    flex-grow: 1;
`
const ProgramCover = styled.img`
    border-radius: 10%;
  
    width: 120px;
    object-fit: cover;
    min-width: 120px;
`;
const CoachRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 30px;
    @media screen and (max-width: 600px) {
        flex-wrap: wrap;
    }
`
const Join = styled(FontBody18)`
    border-radius: 20px;
    cursor: pointer;
    border: 2px solid;
    padding: 3px 10px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
`
const checkCanJoin = (start, expectedEnd) => {
    const after15MinAgo = moment().isAfter(moment(start).subtract(15, "minutes"));
    const beforeExpectedEnd = moment().isBefore(moment(expectedEnd));
    return(after15MinAgo && beforeExpectedEnd)
};

class ProgressAppointmentsList extends React.Component {
    static defaultProps = {
        meetings: [],
        members: [],
        programs: [],
        reschedule: () => {},
        shapeStartTopLeftRound: true,
        backgroundColor: null,
        oneAppointment: false,
        dashboard: false,
        hideEmpty: false,
        newDesign: false
    };

    join = async (meeting, e) => {
        e.stopPropagation();
        window.open("https://live.trustwillow.com/room/"+meeting.videoUrl, '_blank').focus()
    };

    goToCoachPage = async(member) => {
        this.props.history.push("/my-team/"+member.id)
    }

    render() {
        if ((this.props.meetings.length === 0 || (!this.props.oneAppointment && this.props.members.length === 0)) && !this.props.hideEmpty) {
            return <FontSubtitle21CC>{'No appointments found'}</FontSubtitle21CC>;
        } else if(this.props.oneAppointment && this.props.members.length === 0 && !this.props.hideEmpty) {
            return (<FontBody16>{'No upcoming appointments'}</FontBody16>)
        } else {
            console.log("else branch");
            console.log(this.props);
            return (
                <Appointments>
                    {this.props.meetings.map((meeting, idx) => {
                        const member = this.props.members.find(m => m.id === meeting.remoteUserId);
                        const cellColor = this.props.backgroundColor === null ? colors.cellColors[idx % colors.cellColors.length] : this.props.backgroundColor;
                        if (member === undefined) {
                            console.log("NULL 1 member undefined")
                            return null
                        } else if (!this.props.newDesign) {
                            return (
                                <CoachCell
                                    key={idx}
                                    backgroundColor={this.props.dashboard ? colors.backgroundColor1 : cellColor}
                                    even={idx % 2 !== 0}
                                    onClick={(e) => {meeting.type === "upcoming" ? (checkCanJoin(meeting.start, meeting.expected_end) ? this.join(meeting, e) : this.props.reschedule(meeting, e)) : {}}}
                                    enableClick={meeting.type === "upcoming"}
                                    shapeStartTopLeftRound={this.props.shapeStartTopLeftRound}
                                    dashboard={this.props.dashboard}
                                >
                                    <FlexRow>
                                        <ProfileIcon src={member.photo} alt={'Willow'}/>
                                        <div>
                                            <FontSubtitle21>{member.first + ' ' + member.last}</FontSubtitle21>
                                            <FontBody16>{member.title}</FontBody16>
                                        </div>
                                    </FlexRow>
                                    <FlexRB>
                                        <FontBody16>{meeting.date}</FontBody16>
                                        {meeting.type === "upcoming" ?
                                            <>
                                                {(checkCanJoin(meeting.start, meeting.expected_end))
                                                    ? <FontSubtitle21>Join</FontSubtitle21>
                                                    : <IconButton 
                                                        image={(brightness(hexToRGB(cellColor, false)) < 130) ? images.rescheduleWhite : images.rescheduleDark}/>
                                                }
                                            </> :
                                            <>
                                                {meeting.isCanceled && <FontBody14>Canceled</FontBody14>}
                                                {(meeting.isMissed && !meeting.isCanceled) && <FontBody14>Missed</FontBody14>}
                                                {(!meeting.isCanceled && !meeting.isMissed) && <FontBody14>Completed</FontBody14>}
                                            </>
                                        }
                                    </FlexRB>
                                </CoachCell>
                            )
                        } else {
                            return (
                                <CoachCell2
                                    key={idx}
                                    backgroundColor={this.props.dashboard ? colors.backgroundColor1 : cellColor}
                                    even={idx % 2 !== 0}
                                    shapeStartTopLeftRound={this.props.shapeStartTopLeftRound}
                                >
                                    <CoachRow >
                                        <ProfileIcon2 src={member.photo} alt={'Willow'} onClick={() => {this.goToCoachPage(member)}}/>
                                        <ColumnFlex>
                                            <FontSubtitle21>{member.first + ' ' + member.last}</FontSubtitle21>
                                            <FontBody18>{member.title}</FontBody18>
                                            <FlexRow2>
                                                <FontBody18>{meeting.date}</FontBody18>
                                                {meeting.type === "upcoming" ?
                                                    <>
                                                        {(checkCanJoin(meeting.start, meeting.expected_end))
                                                            ? <Join onClick={(e) => {this.join(meeting, e) }}>Join</Join>
                                                            : <IconButton
                                                                image={(brightness(hexToRGB(cellColor, false)) < 130) ? images.rescheduleWhite : images.rescheduleDark}
                                                                onClick={(e) => {this.props.reschedule(meeting, e)}}
                                                            />
                                                        }
                                                    </> :
                                                    <>
                                                        {meeting.isCanceled && <FontBody14>Canceled</FontBody14>}
                                                        {(meeting.isMissed && !meeting.isCanceled) && <FontBody14>Missed</FontBody14>}
                                                        {(!meeting.isCanceled && !meeting.isMissed) && <FontBody14>Completed</FontBody14>}
                                                    </>
                                                }
                                            </FlexRow2>
                                        </ColumnFlex>
                                    </CoachRow>
                                </CoachCell2>
                            )
                        }
                    })}
                </Appointments>
            );
        }
    }
};

export default withRouter(ProgressAppointmentsList);