import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import {Link, withRouter} from 'react-router-dom';
import 'moment-timezone/builds/moment-timezone-with-data-2012-2022';
import moment from "moment-timezone";
import styled from 'styled-components';

/* Components */
import {DropdownInput, TextInput, SearchInput, SearchInputUncontrolled, PhoneInput, CalendarDay, ClockTime} from "../../../common/components/inputs";
import {FontTitle18, FontBody16, FontBody12, FontHeader16, FontBody21} from '../../../common/components/fonts';
import {ButtonTertiary, ButtonSecondary} from '../../../common/components/buttons';
import {LogoLoading} from "../../../common/components/loading";
import {colors} from "../../../common/components/colors";
import {DeleteFilled, VideoCameraFilled} from "@ant-design/icons";
import {Width, Border, SaveSection, SubmitInputWithValidation, InlineBlock, CardOrNot, WidthRight, Right} from '../components/clientscreate';

/* Middleware */
import {tryCreateSession} from '../middleware/sessions';
import {tryGetSettingsPersonalInfo} from '../../middleware/settingspersonalinfo';
import {tryGetAllContacts} from '../../middleware/contacts';
import {tryGetNoticesSessions} from "../../../common/containers/noticemeeting/middleware/noticecall";
import {tryGetCoachProgramWMCProspect, tryGetCoachProgramCoachProspect} from '../../team/middleware/coaching';
import {tryGetOneProspect} from "../../middleware/prospects";
import {tryGetOneClient} from "../../clients/middleware/clients";
import {tryGetPurchaseHistory, tryGetClientGeneralSessions} from '../../middleware/coachingproducts';

/* Store */
// import {setSessionsModal} from "../store/sessions";

/* Utils */
import {timeZoneOptions} from '../../../common/utils/timezone2';
import {formatTZ} from '../../../common/utils/timezone';

const calcOffset = (tz) => {
    if(tz === "eastern") {return 4}
    else if(tz === "central") {return 5}
    else if(tz === "mountain") {return 6}
    else if(tz === "pacific") {return 7}
};

const calcTimeZoneOffset = (startTZ, endTZ) => {
    let startOffset = calcOffset(startTZ);
    let endOffset = calcOffset(endTZ);
    return (endOffset - startOffset);
};

const InlineClockLeft = styled.div`
    width: 263px;
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
    @media screen and (max-width: 640px) {
        margin-right: 0px;
        display: block;
        width: 100%;
    }
`;
const InlineClockRight = styled.div`
    width: 263px;
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 640px) {
        display: block;
        width: 100%;
    }
`;


const Search = styled.div`
    display: inline-block;
    width: calc(100% - 88px);
    vertical-align: top;
    padding: 14px 20px;
`;
const Trash = styled.div`
    display: inline-block;
    font-size: 16px;
    padding: 17px 15px;
    vertical-align: top;
    cursor: pointer;
    color: ${colors.secondary70};
`;
const AddButton = styled.div`
    width: fit-content;
`;
const Green = styled.div`
    color: ${colors.progress100};
`;
const Red = styled.div`
    color: ${colors.alert100};
`;

const MeetingType = styled.div`
    padding: 40px;
    width: calc(100% - 80px);
    background-color: ${colors.border100};
    color: ${colors.primary100};
    margin-bottom: 30px;
    border-radius: 4px;
    max-width: 940px;
    @media screen and (max-width: 1440px) {
        max-width: 466px;
    }
    @media screen and (max-width: 1088px) {
        max-width: 880px;
    }
    @media screen and (max-width: 1020px) {
        max-width: 466px;
    }
`;

const whoOptions = (who, isWMC) => {
    return who.map(w => {
        return({
            contactType: (w.get("prospectId") === null && w.get("businessUserId") === null) ? "Contact" : (w.get("prospectStatus") !== null ? w.get("prospectStatus") : "Team Member"),
            value: {
                consumerId: w.get("consumerId"),
                prospectId: w.get("prospectId"),
                contactId: w.get("contactId"),
                businessUserId: w.get("businessUserId")
            },
            label: (w.get("prospectId") === null && w.get("businessUserId") === null) ? w.get("firstName") + " " + w.get("lastName") 
                : 
                ((!isWMC && w.get("referralSource") === "wmc") ? 
                    w.get("firstName") + " " + w.get("lastName").charAt(0) + "."
                    : 
                    w.get("firstName") + " " + w.get("lastName")),
            isArchived: w.get("isArchived")
        })
    }).toJS();
};

class MeetingsCreateSmall extends React.Component {
    state = {
        topic: '', topicValid: true,
        description: '', descriptionValid: true,
        phone: '', phoneValid: true,
        email: '', emailValid: true,
        timeZone: '',
        who: '', whoLabel: '', whoValid: false, whoObject: {},
        guests: [], guestsShow: false,
        isSaving: false,
        isSaved: false,
        error: '',
        isChanged: false,
        isValid: true,
        startAlreadyPast: true,
        endAlreadyPast: true,
        atLeastOneHr: true,
        endAfterStart: true,
        day: new Date(),
        start: new Date(),
        end: new Date(),
        validTimes: true,
        isLoading: true,
        searchProspects: [],
        // canBook: true,
        // bookableSessions: 0,
        // clientOfAdvisor: false,
        purchaseHistoryId: null,
        initialProductTitle: "Free Session",
        whoReferralSource: "",
        purchaseOptions: [{"value": null, "label": "Free Session"}],
        purchaseDropdown: ""
    };

    componentDidMount() {
        return this.setupForm()
    }

    setupForm = async () => {
        if(this.props.timeZone === "") {
            await this.props.tryGetSettingsPersonalInfo();
        }
        await this.setState({"timeZone": this.props.timeZone});
        if(!this.props.contactsAllFetched) {
            await this.props.tryGetAllContacts();
        }
        if(this.props.sessionSelected === null) {
            let end = (this.props.user.get("isCoach") && !this.props.user.get("isSupport"))
                ? new Date(moment(moment().add(24, "hours").add(45, 'minutes').format("YYYY-MM-DD[T]") + moment(moment().add(24, "hours").add(1, "minute").add(45, 'minutes')).format("HH:mm:00")).tz(formatTZ(this.state.timeZone)).toISOString())
                : new Date(moment(moment().add(24, "hours").add(1, 'hours').format("YYYY-MM-DD[T]") + moment(moment().add(24, "hours").add(1, "minute").add(1, 'hours')).format("HH:mm:00")).tz(formatTZ(this.state.timeZone)).toISOString());
            await this.setState({
                "day": new Date(moment(moment().add(24, "hours").format("YYYY-MM-DD[T]") + moment().add(1, "minute").format("HH:mm:00")).tz(formatTZ(this.state.timeZone)).toISOString()),
                "start": new Date(moment(moment().add(24, "hours").format("YYYY-MM-DD[T]") + moment().add(1, "minute").format("HH:mm:00")).tz(formatTZ(this.state.timeZone)).toISOString()),
                "end": end,
            });
        } else {
            await this.setState({
                "day": new Date(moment(moment(this.props.sessionSelected.get("start")).format("YYYY-MM-DD[T]")+moment(this.props.sessionSelected.get("start")).format("HH:mm:00")).tz(formatTZ(this.state.timeZone)).toISOString()),
                "start": new Date(moment(moment(this.props.sessionSelected.get("start")).format("YYYY-MM-DD[T]")+moment(this.props.sessionSelected.get("start")).format("HH:mm:00")).tz(formatTZ(this.state.timeZone)).toISOString()),
                "end": new Date(moment(moment(this.props.sessionSelected.get("end")).format("YYYY-MM-DD[T]")+moment(this.props.sessionSelected.get("end")).format("HH:mm:00")).tz(formatTZ(this.state.timeZone)).toISOString()),
            });
        }
        if(this.props.sessionSelectedDetails !== null) {
            await this.setState({
                "topic": this.props.sessionSelectedDetails.get("topic"),
                "description": this.props.sessionSelectedDetails.get("description")
            });
        }
        await this.setState({"searchProspects": this.props.contacts});
        if(this.props.sessionSelectedWho !== null) {
            const prospectSelected = await this.props.contacts.filter(p => {return p.get("contactId") === this.props.sessionSelectedWho}).get(0);
            await this.setState({
                "who": prospectSelected.get("consumerId"),
                "whoLabel": prospectSelected.get("firstName") + " " + prospectSelected.get("lastName"),
                "whoObject": {
                    contactType: prospectSelected.get("prospectStatus") === null ? "Contact" : prospectSelected.get("prospectStatus"),
                    value: {
                        consumerId: prospectSelected.get("consumerId") === undefined ? null : prospectSelected.get("consumerId"),
                        prospectId: prospectSelected.get("prospectId") === undefined ? null : prospectSelected.get("prospectId"),
                        contactId: prospectSelected.get("contactId") === undefined ? null : prospectSelected.get("contactId"),
                        businessUserId: prospectSelected.get("businessUserId") === undefined ? null : prospectSelected.get("businessUserId")
                    },
                    label: prospectSelected.get("isArchived") ? prospectSelected.get("firstName") + " " + prospectSelected.get("lastName") + " (previous)" : prospectSelected.get("firstName") + " " + prospectSelected.get("lastName"),
                    isArchived: prospectSelected.get("isArchived")
                }
            });
            await this.handleChangeWho(this.state.whoObject, "init");
        }
        if (this.props.sessionGuests !== null) {
            await this.setState({"guests": this.props.sessionGuests.toJS(), "guestsShow": true});
        }
        await this.checkValid();
        await this.validateTimes();
        this.setState({"isLoading": false});
    };

    componentWillUnmount() {
        // this.props.setSessionsModal(false, null, null, null, null, null);
        clearInterval(this.timeout)
    }

    handleChangeTopic = async e => {
        this.setState({'topic': e.target.value});
        await this.setState({'topicValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeDescription = async e => {
        this.setState({'description': e.target.value});
        // await this.setState({'descriptionValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        // this.setState({'isChanged': true});
        // this.checkValid();
    };
    handleChangeEmail = async e => {
        this.setState({'email': e.target.value});
        await this.setState({'emailValid': validator.isEmail(e.target.value)});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangePhone = async e => {
        this.setState({'phone': e.target.value});
        await this.setState({'phoneValid': validator.isMobilePhone(e.target.value, 'en-US')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeTimeZone = async e => {
        // const hoursOffset = await calcTimeZoneOffset(this.state.timeZone, e.value);
        await this.setState({
            'timeZone': e.value,
            // "start": new Date(moment(this.state.start).subtract(hoursOffset, "hours").tz(formatTZ(this.state.timeZone)).toISOString()),
            // "end": new Date(moment(this.state.end).subtract(hoursOffset, "hours").tz(formatTZ(this.state.timeZone)).toISOString()),
            'isChanged': true
        });
        this.validateTimes();
    };
    handleChangePurchaseHistoryId = async e => {
        await this.setState({'purchaseHistoryId': e.value, 'isChanged': true});
    };

    handleSetPurchaseOptions = async (purchaseHistory) => {
        let purchaseOptions = this.state.purchaseOptions;
        await Promise.all(purchaseHistory.map(p => {
            purchaseOptions.unshift({"value": p.purchaseHistoryId, "label": p.productTitle});
        }));
        console.log(purchaseOptions)
        await this.setState({
            'purchaseHistoryId': purchaseOptions[0].value,
            "initialProductTitle": purchaseOptions[0].label
        });
        return purchaseOptions;
    };

    handleChangeWho = async (e, entry) => {
        if(entry !== "init") {
            await this.handleDeleteWhoUpdateSearchProspects();
        }
        if(e === null) {
            await this.handleDeleteWho();
        } else {
            let whoValue;
            let whoValid;
            let searchProspectsLessWho;
            // let program;
            // let bookableSessions = 0;
            let prospectData;
            // let canBook = true;
            // let clientOfAdvisor = false;
            // let clientOfCoach = false;
            let purchaseOptions = this.state.purchaseOptions;
            let generalSessions = [];
            let purchases = [];
            if(e.contactType === "Contact") {
                whoValue = e.value.contactId;
                console.log(whoValue);
                whoValid = this.props.contacts.filter(p => {return p.get("contactId") === e.value.contactId}).size === 1;
                console.log(whoValid);
                searchProspectsLessWho = this.state.searchProspects.filter(s => {return(s.get("contactId") !== e.value.contactId)});
                console.log(searchProspectsLessWho);
            } else {
                whoValue = e.value.consumerId;
                whoValid = this.props.contacts.filter(p => {return p.get("consumerId") === e.value.consumerId}).size === 1;
                searchProspectsLessWho = this.state.searchProspects.filter(s => {return(s.get("consumerId") !== e.value.consumerId)});
                // TEMPORARILY TURNED OFF UNTIL COMPLETED CAN'T SCHEDULE UNLESS COACH HAS SESSIONS WITH ADVISORS CLIENTS
                if(!this.props.isWMC) {
                    if(e.contactType === "Prospect") {
                        await this.props.tryGetOneProspect(e.value.prospectId, "store");
                        prospectData = "prospectsOne";
                    } else if(e.contactType === "Client") {
                        await this.props.tryGetOneClient(e.value.prospectId, "store");
                        prospectData = "clientsOne";
                    }
                    if (prospectData != null && this.props[prospectData].get("advisor") != null)
                        if(this.props[prospectData].get("advisor").get("advisorId") === this.props.userId) {
                            console.log(1)
                            // program = await this.props.tryGetCoachProgramCoachProspect(e.value.prospectId, "return");
                            purchases = await this.props.tryGetPurchaseHistory(e.value.prospectId);
                            // purchaseOptions = await this.handleSetPurchaseOptions(purchases);
                            // clientOfCoach = true;
                        // } else {
                        //     console.log(2)
                        //     program = await this.props.tryGetCoachProgramWMCProspect(e.value.prospectId, "return");
                        //     clientOfAdvisor = true;
                        //     canBook = (program.bookableSessions > 0 || this.props.user.get("isSupport"));
                        }
                    // console.log(program)
                    // bookableSessions = program.bookableSessions;
                    // console.log(canBook)
                    const numSessions = await this.props.tryGetClientGeneralSessions(e.value.prospectId)
                    console.log("numsessions", numSessions)
                    if (numSessions.availableSessions !== null && numSessions.availableSessions !== undefined && numSessions.availableSessions > 0) {
                        // generalSessions = numSessions.availableSessions
                        generalSessions = [{purchaseHistoryId: "gensession", productTitle: numSessions.availableSessions === 1 ? "Book the last paid session" : "Book 1 of "+numSessions.availableSessions+" paid sessions"}];
                        // canBook = true
                    }
                    purchaseOptions = await this.handleSetPurchaseOptions([...purchases, ...generalSessions, ]);
                }
            }
            console.log("purchaseOptions", purchaseOptions)
            console.log("beforedropdowncreated", this.state)
            await this.setState({
                'whoObject': e,
                "whoReferralSource": !this.props.isWMC && prospectData != null ? this.props[prospectData].get("referralSource") : "",
                "who": whoValue,
                'whoLabel': e.label,
                "whoValid": whoValid,
                'isChanged': true,
                'searchProspects': searchProspectsLessWho,
                // 'canBook': canBook,
                // "bookableSessions": bookableSessions,
                // "clientOfAdvisor": clientOfAdvisor,
                "purchaseDropdown": //!clientOfCoach ?
                    <DropdownInput
                        title={"Appointment Type*"}
                        options={purchaseOptions}
                        id={'purchaseHistoryId'}
                        onChange={this.handleChangePurchaseHistoryId}
                        placeholder={this.state.initialProductTitle}
                        value={this.state.purchaseHistoryId}
                    />
                    // : <DropdownInput
                    //     title={"Associate With Purchase*"}
                    //     options={purchaseOptions}
                    //     id={'purchaseHistoryId'}
                    //     onChange={this.handleChangePurchaseHistoryId}
                    //     placeholder={'Free Session'}
                    //     value={this.state.purchaseHistoryId}
                    // />
            });
        }
        this.checkValid();
    };



    handleDeleteWhoUpdateSearchProspects = async () => {
        let searchProspectsAddWho;
        if(this.state.who !== '') {
            if(this.state.whoObject.contactType === "Contact") {
                searchProspectsAddWho = await this.props.contacts.filter(s => {return(s.get("contactId") === this.state.who)}).get(0);
            } else {
                searchProspectsAddWho = await this.props.contacts.filter(s => {return(s.get("consumerId") === this.state.who)}).get(0);
            }
            this.setState({'searchProspects': this.state.searchProspects.push(searchProspectsAddWho)})
        }
    };
    handleDeleteWho = async () => {
        this.setState({
            'whoObject': {},
            "who": "",
            "whoReferralSource": "",
            'whoLabel': "",
            "whoValid": false,
            'isChanged': false,
            // "canBook": false,
            // "bookableSessions": 0,
            // "clientOfAdvisor": false,
            "purchaseOptions": [{"value": null, "label": "Free Session"}],
            "purchaseHistoryId": null,
            "purchaseDropdown": ""
        })
    };

    checkValid = () => {
        if(this.state.topic !== "" && this.state.topicValid && this.state.descriptionValid && this.state.phoneValid && this.state.emailValid && this.state.whoValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleDayChange = async day => {
        if(day !== null) {
            await this.setState({
                "day" : day,
                'start': new Date(moment(moment(day).format("YYYY-MM-DD[T]")+moment(this.state.start).format("HH:mm:00")).tz(formatTZ(this.state.timeZone)).toISOString()),
                'end': new Date(moment(moment(day).format("YYYY-MM-DD[T]")+moment(this.state.end).format("HH:mm:00")).tz(formatTZ(this.state.timeZone)).toISOString())
            });
            this.validateTimes();
        }
    };

    validateTimes = async () => {
        if(moment(this.state.end).isBefore(moment(this.state.start))) {
            await this.setState({endAfterStart: false})
        } else {
            await this.setState({endAfterStart: true})
        }
        // if(moment.duration(moment(this.state.end).diff(moment(this.state.start))).asMinutes() < 60) {
        //     await this.setState({atLeastOneHr: false})
        // } else {
        //     await this.setState({atLeastOneHr: true})
        // }
        const hoursOffset = await calcTimeZoneOffset(this.props.timeZone, this.state.timeZone);
        if(moment(this.state.start).subtract(hoursOffset, "hours").isBefore(moment())) {
            await this.setState({startAlreadyPast: false})
        } else {
            await this.setState({startAlreadyPast: true})
        }
        if(moment(this.state.end).subtract(hoursOffset, "hours").isBefore(moment())) {
            await this.setState({endAlreadyPast: false})
        } else {
            await this.setState({endAlreadyPast: true})
        }
        if(this.state.endAfterStart /* && this.state.atLeastOneHr */ && this.state.endAlreadyPast && this.state.startAlreadyPast) {
            this.setState({validTimes: true})
        } else {
            this.setState({validTimes: false})
        }
    };

    timeWithZero = (t) => {
        if(t.length === 1) {return("0"+t)}
        else {return(t)}
    };

    handleTimeChange = async (newTime, period) => {
        let hour = this.timeWithZero(newTime.hour.toString());
        let minute = this.timeWithZero(newTime.minute.toString());
        if(period === "start") {
            let hourEnd = this.timeWithZero((newTime.hour+1).toString());
            let minuteEnd = this.timeWithZero(newTime.minute.toString());
            await this.setState({
                "start" : new Date(moment(moment(this.state.day).format("YYYY-MM-DD[T]")+hour+":"+minute).tz(formatTZ(this.state.timeZone)).toISOString()),
                "end" : new Date(moment(moment(this.state.day).format("YYYY-MM-DD[T]")+hourEnd+":"+minuteEnd).tz(formatTZ(this.state.timeZone)).toISOString())
            });
        } else {
            await this.setState({
                "end" : new Date(moment(moment(this.state.day).format("YYYY-MM-DD[T]")+hour+":"+minute).tz(formatTZ(this.state.timeZone)).toISOString())
            });
        }
        this.validateTimes();
    };

    bookMeeting = async () => {
        if(this.state.topicValid && this.state.descriptionValid && this.state.phoneValid && this.state.emailValid && this.state.whoValid) {
            this.setState({'isSaving': true, 'error': ''});
            const hoursOffset = await calcTimeZoneOffset(this.props.timeZone, this.state.timeZone);
            const startInTZ = new Date(moment(this.state.start).subtract(hoursOffset, "hours").tz(formatTZ(this.state.timeZone)).toISOString());
            const endInTZ = new Date(moment(this.state.end).subtract(hoursOffset, "hours").tz(formatTZ(this.state.timeZone)).toISOString());
            await this.props.tryCreateSession(startInTZ, endInTZ, this.state.topic, this.state.description, this.state.whoObject, this.state.guests, this.state.purchaseHistoryId, this.state.isWillowSession);
            this.props.tryGetNoticesSessions();
            this.setState({'isSaving': false, 'isSaved': true, isChanged: false});
            this.timeout = setTimeout(() => {this.props.history.push("/meetings")}, 3000);
        }
    }

    handleSubmit = async e => {
        e.preventDefault();
        console.log(this.state.purchaseHistoryId)
        if (this.state.purchaseHistoryId === "gensession" || this.state.purchaseHistoryId === "Charge against general sessions") {
            console.log("call charge against gen sessions api")
            await this.bookMeeting()
        } else {
            await this.bookMeeting()
        }
    };

    handleShowGuests = async () => {
        this.setState({"guestsShow": true})
    };
    handleAddGuest = async (e) => {
        let guests = this.state.guests;
        guests.push(e);
        let searchProspectsLessGuest;
        if(e.contactType === "Contact") {
            searchProspectsLessGuest = this.state.searchProspects.filter(s => {return(s.get("contactId") !== e.value.contactId)});
        } else {
            searchProspectsLessGuest = this.state.searchProspects.filter(s => {return(s.get("consumerId") !== e.value.consumerId)});
        }
        await this.setState({'guests': guests, 'isChanged': true, 'searchProspects': searchProspectsLessGuest});
        console.log(this.state);
    };
    handleDeleteGuest = async (num) => {
        let guests = this.state.guests;
        let searchProspectsAddGuest;
        if(guests[num].contactType === "Contact") {
            searchProspectsAddGuest = await this.props.contacts.filter(s => {return(s.get("contactId") === this.state.guests[num].value.contactId)}).get(0);
        } else {
            searchProspectsAddGuest = await this.props.contacts.filter(s => {return(s.get("consumerId") === this.state.guests[num].value.consumerId)}).get(0);
        }
        guests.splice(num, 1);
        this.setState({"guests": guests, "guestsShow": (guests.length !== 0), 'searchProspects': this.state.searchProspects.push(searchProspectsAddGuest)});
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <form method={"post"} onSubmit={this.handleSubmit}>
                    {this.state.isSaved
                        ?
                        <>
                            {this.state.error === '' && <FontTitle18>Saved!</FontTitle18>}
                            {this.state.error !== '' && <FontTitle18>{this.state.error}</FontTitle18>}
                        </>
                        :
                        <>
                            <div>
                                <MeetingType>
                                    <FontBody21><VideoCameraFilled />&nbsp;&nbsp;Video Call - schedule a meeting</FontBody21>
                                </MeetingType>
                                <Width>
                                    <br />
                                    <FontTitle18>Details</FontTitle18>
                                    <TextInput
                                        title={"Title*"}
                                        valid={this.state.topicValid}
                                        warning={"Required"}
                                        id={"topic"}
                                        onChange={this.handleChangeTopic}
                                        placeholder={"Meeting Name"}
                                        value={this.state.topic}
                                    />
                                    <TextInput
                                        title={"Description"}
                                        valid={this.state.descriptionValid}
                                        warning={"Required"}
                                        id={"description"}
                                        onChange={this.handleChangeDescription}
                                        placeholder={"Enter your meeting description (optional)"}
                                        value={this.state.description}
                                    />
                                    <Border />
                                    <FontTitle18>Invite</FontTitle18>
                                    <SearchInput
                                        title={this.props.isWMC ? "Primary Contact*" : "Contact*"}
                                        options={whoOptions(this.state.searchProspects.filter(s => {return(s.get("consumerId") !== this.props.userId && s.get("businessUserId") !== this.props.userId && s.get("isActive"))}), this.props.isWMC)}
                                        id={'who'}
                                        onChange={this.handleChangeWho}
                                        placeholder={'Search contacts'}
                                        value={this.state.whoLabel}
                                    />
                                    {(!this.props.isWMC && this.state.who !== "" && !this.props.user.get("isSupport")) &&
                                    <div>
                                        {/*{this.state.clientOfAdvisor &&*/}
                                        {/*<>*/}
                                        {/*    {this.state.canBook*/}
                                        {/*        ? <Green>*/}
                                        {/*            {this.state.bookableSessions === 1*/}
                                        {/*                ? <FontBody12>1 session remaining</FontBody12>*/}
                                        {/*                : <FontBody12>{this.state.bookableSessions+" sessions remaining"}</FontBody12>*/}
                                        {/*            }*/}
                                        {/*        </Green>*/}
                                        {/*        : */}
                                        {/*        <>*/}
                                        {/*            <Red><FontBody12>All available assigned sessions have been scheduled or completed. You can request more from their advisor.</FontBody12></Red>*/}
                                        {/*            <>{this.state.purchaseDropdown}</>*/}
                                        {/*        </>*/}
                                        {/*    }*/}
                                        {/*</>*/}
                                        {/*}*/}
                                        {/*{(!this.state.clientOfAdvisor && this.state.whoReferralSource === "willow") &&*/}
                                        {/*<>*/}
                                        {/*    {this.state.bookableSessions > 1*/}
                                        {/*        ? <Green>*/}
                                        {/*            {this.state.bookableSessions === 1*/}
                                        {/*                ? <FontBody12>1 session remaining</FontBody12>*/}
                                        {/*                : <FontBody12>{this.state.bookableSessions+" paid sessions remaining"}</FontBody12>*/}
                                        {/*            }*/}
                                        {/*        </Green>*/}
                                        {/*        : <Red><FontBody12>Schedule a free session</FontBody12></Red>*/}
                                        {/*    }*/}
                                        {/*</>*/}
                                        {/*}*/}
                                        {/*{(!this.state.clientOfAdvisor && this.state.whoReferralSource === "coach") &&*/}
                                        <>{this.state.purchaseDropdown}</>
                                        {/*}*/}
                                    </div>
                                    }
                                    {(!this.state.guestsShow && (this.props.isWMC || this.props.user.get("isSupport"))) &&
                                    <AddButton onClick={this.handleShowGuests}>
                                        <ButtonSecondary canSubmit={false} label={"Add guests"} />
                                    </AddButton>
                                    }
                                    {(this.state.guestsShow && (this.props.isWMC || this.props.user.get("isSupport"))) &&
                                    <>
                                        <SearchInputUncontrolled
                                            title={"Guests & Team Members"}
                                            options={whoOptions(this.state.searchProspects.filter(s => {return(s.get("consumerId") !== this.props.userId && s.get("businessUserId") !== this.props.userId && s.get("isActive"))}), this.props.isWMC)}
                                            id={'guests'}
                                            onChange={this.handleAddGuest}
                                            placeholder={'Search contacts (optional)'}
                                            value={""}
                                            disabled={this.state.guests.length === 2}
                                        />
                                        {this.state.guests.length > 0 &&
                                        this.state.guests.map((g, index) => {
                                            return(
                                                <div key={index}>
                                                    <Search><FontBody16>{(index+1).toString()+". "+g.label}</FontBody16></Search>
                                                    <Trash onClick={() => this.handleDeleteGuest(index)}><DeleteFilled /></Trash>
                                                </div>
                                            )
                                        })
                                        }
                                        {this.state.guests.length === 2 &&
                                        <Search><FontBody12>Max 2 guests</FontBody12></Search>
                                        }
                                    </>
                                    }
                                </Width>
                                <WidthRight><Right>
                                    <CardOrNot>
                                        <FontTitle18>When</FontTitle18>
                                        <CalendarDay disabled={false} id={"date"} title={"Date*"} onChange={this.handleDayChange} value={this.state.day} />
                                        {/*<>*/}
                                        {/*    <InlineClockLeft>*/}
                                        <ClockTime endTooltip={false} zIndex={2} absolute={true} disabled={false} id={"start"} title={"Start time*"} valid={this.state.endAfterStart} warning={"After the end time"} validEnd={this.state.startAlreadyPast} warningEnd={"has already past"} handleTimeChange={this.handleTimeChange} value={this.state.start} />
                                        {/*</InlineClockLeft>*/}
                                        {/*<InlineClockRight>*/}
                                        <ClockTime endTooltip={true} zIndex={3} absolute={true} disabled={false} id={"end"} title={"End time*"} valid={this.state.atLeastOneHr} warning={"Minimum 1 hour"} validEnd={this.state.endAlreadyPast} warningEnd={"has already past"} handleTimeChange={this.handleTimeChange} value={this.state.end} />
                                        {/*</InlineClockRight>*/}
                                        {/*</>*/}
                                        <DropdownInput
                                            title={"Time zone*"}
                                            options={timeZoneOptions}
                                            id={'timeZone'}
                                            onChange={this.handleChangeTimeZone}
                                            placeholder={'Time Zone'}
                                            value={this.state.timeZone}
                                        />
                                    </CardOrNot>
                                </Right></WidthRight>
                                {/*<RadioButtonsTwo*/}
                                {/*    action={this.changeSelected}*/}
                                {/*    checked={true}*/}
                                {/*    id={"audioOnly"}*/}
                                {/*    label={"Phone"}*/}
                                {/*    low={"Off"}*/}
                                {/*    high={"On"}*/}
                                {/*/>*/}
                            </div>
                            <SaveSection>
                                <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                <InlineBlock><div onClick={() => {return this.props.history.push("/meetings/create")}}><ButtonTertiary canSubmit={false} label={"Back"} /></div></InlineBlock>
                                {/*<InlineBlock><Link to={"/meetings"}><ButtonTertiary canSubmit={false} label={"Cancel"} /></Link></InlineBlock>*/}
                            </SaveSection>
                        </>
                    }
                </form>
            )
        }
    }
}
const mapStateToProps = state => ({
    contactsAllFetched: state.enterprise.contacts.get("contactsAllFetched"),
    contacts: state.enterprise.contacts.get("contactsAll"),
    prospectsOne: state.enterprise.prospects.get("prospectsOne"),
    clientsOne: state.enterprise.clientsList.get("clientsOne"),
    timeZone: state.enterprise.settingsPersonal.get("settingsPersonalInfo").get("timeZone"),
    sessionSelected: state.enterprise.sessions.get("sessionSelected"),
    sessionSelectedWho: state.enterprise.sessions.get("sessionSelectedWho"),
    sessionSelectedDetails: state.enterprise.sessions.get("sessionSelectedDetails"),
    isWMC: state.common.wmc.get("isWMC"),
    userId: state.common.user.get("userId"),
    user: state.common.user,
    sessionGuests: state.enterprise.sessions.get("sessionGuests"),
});
const mapDispatchToProps = dispatch => ({
    tryCreateSession: (start, end, topic, description, who, guests, purchaseHistoryId) => dispatch(tryCreateSession(start, end, topic, description, who, guests, purchaseHistoryId)),
    tryGetAllContacts: () => dispatch(tryGetAllContacts()),
    tryGetOneProspect: (prospectId, storeOrReturn) => dispatch(tryGetOneProspect(prospectId, storeOrReturn)),
    tryGetOneClient: (prospectId, storeOrReturn) => dispatch(tryGetOneClient(prospectId, storeOrReturn)),
    // setSessionsModal: (status, session, action, who, details, guests) => dispatch(setSessionsModal(status, session, action, who, details, guests)),
    tryGetSettingsPersonalInfo: () => dispatch(tryGetSettingsPersonalInfo()),
    tryGetNoticesSessions: () => dispatch(tryGetNoticesSessions()),
    tryGetCoachProgramCoachProspect: (prospectId, storeOrReturn) => dispatch(tryGetCoachProgramCoachProspect(prospectId, storeOrReturn)),
    tryGetCoachProgramWMCProspect: (prospectId, storeOrReturn) => dispatch(tryGetCoachProgramWMCProspect(prospectId, storeOrReturn)),
    tryGetPurchaseHistory: (prospectId) => dispatch(tryGetPurchaseHistory(prospectId)),
    tryGetClientGeneralSessions: (prospectId) => dispatch(tryGetClientGeneralSessions(prospectId))
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MeetingsCreateSmall));
