import React from 'react';
import {connect} from 'react-redux';
import styled from "styled-components";
import {withRouter} from 'react-router-dom';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

/* Middleware */
import {tryGetThankYouToken, packageTitle} from '../middleware/program';

/* Common */
import {images} from "../../../common/components/images";
import {FontBody16, FontTitle21, FontBody21} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";
import {ButtonPrimary} from "../../../common/components/buttons";

const Logo = styled.div`
    height: 40px;
    padding: 15px 70px 15px 50px;
    text-align: center;
    background-color: ${colors.white};
    border-bottom: 10px solid ${colors.border100};
`;
const Room = styled.div`
    height: 100%;
    width: 100%;
`;
const RoomContent = styled.div`
    width: 100%;
    @media screen and (max-width: 1088px) {
        padding-bottom: 80px;
    }
    @media screen and (max-width: 748px) {
        height: calc(100% - 165px);
    }
    @media screen and (max-width: 460px) {
        padding-bottom: 0px;
    }
`;
const RoomContentContainer = styled.div`
    margin: 20px auto 120px auto;
    padding: 00px 20px 0px 20px;
    @media screen and (max-width: 748px) {
        padding: 0px 20px 0px 20px;
        width: calc(100% - 40px);
    }
`;

export const Modal = styled.div`
    -webkit-box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    background-color: ${colors.white};
    border-radius: 4px;
    padding: 40px;
    color: ${colors.primary100};
    max-width: 360px;
    position: relative;
    margin: 30px auto 0px auto;
    @media screen and (max-width: 460px) {
        margin: 20px 0px;
        padding: 20px;
    }
    text-align: center;
`;
export const Body = styled.div`
    margin: 20px 0px; 
`;
export const FitContent = styled.div`
    width: fit-content;
    margin: 0px auto;
`;
export const NoteTitle = styled.div`
    padding-bottom: 20px;
    border-bottom: 1px dashed ${colors.primary30};
    color: ${colors.primary100};
    text-align: center;
    margin-bottom: 30px;
`;
const Image = styled.img`
    width: 150px;
    height: 120px;
    margin: 0px auto 10px auto;
`;

class ReceiptRoom extends React.Component {
    constructor(props) {
        super(props);
        this.state={"isLoading": true, "token": null}
    }

    componentDidMount() {
        const token = this.props.location.pathname.split('/')[2];
        this.props.tryGetThankYouToken(token)
            .then(() => {
                this.setState({"isLoading": false, "token": token});
            })
    }

    submit = () => {
        // this.props.history.push('/coach')
        window.open("https://"+env.URL.HOSTNAME+"/verify","_self");
    };

    render() {
        return (
            <Room>
                <RoomContent>
                    <Logo><img src={images.logo} height="40" width="200" alt={""}/></Logo>
                    <RoomContentContainer>
                        {this.state.isLoading
                            ?
                            <Modal>
                                <FontTitle21>Loading!</FontTitle21>
                            </Modal>
                            :
                            <>
                                {this.state.token === null
                                    ?
                                    <Modal>
                                        <FontTitle21>No token</FontTitle21>
                                    </Modal>
                                    :
                                    <Modal>
                                        <NoteTitle>
                                            <Image src={images.consumerSignUpIconBooked} />
                                            <FontBody21>{packageTitle(this.props.priceId).title}</FontBody21>
                                            <FontBody16>{packageTitle(this.props.priceId).payment}</FontBody16>
                                        </NoteTitle>
                                        <FontTitle21>Thank you!</FontTitle21>
                                        <br />
                                        <br />
                                        <FontBody16>Next, schedule sessions from your dashboard.</FontBody16>
                                        <br />
                                        <FitContent onClick={this.submit}>
                                            <ButtonPrimary submit={false} label={"View Dashboard"} />
                                        </FitContent>
                                    </Modal>
                                }
                            </>
                        }
                    </RoomContentContainer>
                </RoomContent>
            </Room>
        )
    }
}
const mapStateToProps = state => ({
    priceId: state.iframe.checkoutWillow.program.get("programReceiptData").get("priceId")
});
const mapDispatchToProps = dispatch => ({
    tryGetThankYouToken: (token) => dispatch(tryGetThankYouToken(token))
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReceiptRoom));
