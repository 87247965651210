import React from 'react';
import validator from 'validator';

/* Components */
import {MoneyInputGray} from "../../../common/components/inputs";
import {LogoLoading} from "../../../common/components/loading";

class MeetingsCreateMoney extends React.Component {
    state = {
        money: 0,
        moneyValid: true
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "money": this.props.money.money,
            "isLoading": false
        });
    };

    handleChangeMoney = async e => {
        await this.setState({
            'money': e.target.value,
            'moneyValid': (!validator.isEmpty(e.target.value.toString()) && e.target.value.toString() !== '' && e.target.value !== 0 && e.target.value.toString() !== "$"),
        });
        await this.props.onValidate(this.state);
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <MoneyInputGray
                    title={"Ticket Price*"}
                    valid={this.state.moneyValid}
                    warning={"Required"}
                    id={"money"}
                    onChange={this.handleChangeMoney}
                    placeholder={0}
                    value={this.state.money}
                />
            )
        }
    }
}

export default MeetingsCreateMoney;
