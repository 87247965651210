import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';

/* Components */
import {DropdownInput, TextInput, PhoneInput} from "../../../common/components/inputs";
import NavSecondaryTop from "../../navbars/containers/secondarytop";

/* Middleware */
import {tryUpdateSettingsPersonalInfo, tryGetSettingsPersonalInfo} from '../../middleware/settingspersonalinfo';
import {FontTitle18} from "../../../common/components/fonts";
import {InlineBlock, SaveSection, Width, SubmitInputWithValidation} from "../../components/clientscreate";

/* Store */
// import {setCompanyName} from '../../common/store/user';

/* Utils */
import {timeZoneOptions} from '../../../common/utils/timezone2';
import {tryLogout} from "../../../routes/middleware/auth";
import {ButtonTertiary} from "../../../common/components/buttons";

class SettingsBasicInfo extends React.Component {
    state = {
        first: '', firstValid: true,
        last: '', lastValid: true,
        phone: '', phoneValid: true,
        email: '', emailValid: true,
        // companyName: '', companyNameValid: true,
        timeZone: '',
        isSaving: false,
        isSaved: false,
        error: '',
        isChanged: false,
        isValid: true
    };

    componentDidMount() {
        Promise.resolve(this.props.tryGetSettingsPersonalInfo())
            .then(() => {
                if(this.props.settingsPersonalInfo.get("first") === "Willow" && this.props.settingsPersonalInfo.get("last") === "") {
                    this.setState({...this.props.settingsPersonalInfo.toJS(), "first": ""})
                } else {
                    this.setState(this.props.settingsPersonalInfo.toJS())
                }
            })
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    handleChangeFirst = async e => {
        this.setState({'first': e.target.value});
        await this.setState({'firstValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeLast = async e => {
        this.setState({'last': e.target.value});
        await this.setState({'lastValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    // handleChangeCompanyName = async e => {
    //     this.setState({'companyName': e.target.value});
    //     await this.setState({'companyNameValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
    //     this.setState({'isChanged': true});
    //     this.checkValid();
    // };
    handleChangeEmail = async e => {
        this.setState({'email': e.target.value});
        await this.setState({'emailValid': validator.isEmail(e.target.value)});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangePhone = async e => {
        this.setState({'phone': e.target.value});
        await this.setState({'phoneValid': validator.isMobilePhone(e.target.value, 'en-US')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeTimeZone = e => {
        this.setState({'timeZone': e.value});
        this.setState({'isChanged': true});
    };

    checkValid = () => {
        if(this.state.firstValid && this.state.lastValid && this.state.phoneValid && this.state.emailValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleSubmit = e => {
        e.preventDefault();
        // if(this.state.firstValid && this.state.lastValid && this.state.phoneValid && this.state.emailValid && this.state.companyName) {
        if(this.state.firstValid && this.state.lastValid && this.state.phoneValid && this.state.emailValid) {
            this.setState({'isSaving': true, 'error': ''});
            Promise.resolve(this.props.tryUpdateSettingsPersonalInfo(this.state))
                .then(() => {
                    if(this.props.settingsPersonalInfo.get("error") !== '') {
                        this.setState(this.props.settingsPersonalInfo.toJS());
                    }
                    // } else {
                    //     this.props.setCompanyName(this.state.companyName);
                    // }
                    this.setState({'isSaving': false, 'isSaved': true, isChanged: false});
                    this.timeout = setTimeout(() => {this.setState({'isSaved': false})}, 3000);
                })
        }
    };

    render() {
        return(
            <>
                <NavSecondaryTop title={"Basic info"} />
                <form method={"post"} onSubmit={this.handleSubmit}>
                    {this.state.isSaved
                        ?
                            <>
                                {this.state.error === '' && <FontTitle18>Saved!</FontTitle18>}
                                {this.state.error !== '' && <FontTitle18>{this.state.error}</FontTitle18>}
                            </>
                        :
                            <>
                                <Width>
                                    <FontTitle18>Personal info</FontTitle18>
                                    <TextInput
                                        title={"First Name"}
                                        valid={this.state.firstValid}
                                        warning={"Required"}
                                        id={"first"}
                                        onChange={this.handleChangeFirst}
                                        placeholder={"First Name"}
                                        value={this.state.first}
                                    />
                                    <TextInput
                                        title={"Last Name"}
                                        valid={this.state.lastValid}
                                        warning={"Required"}
                                        id={"last"}
                                        onChange={this.handleChangeLast}
                                        placeholder={"Last Name"}
                                        value={this.state.last}
                                    />
                                    {/*{this.props.platform !== "consumer" &&*/}
                                    {/*    <TextInput*/}
                                    {/*        title={"Company Name"}*/}
                                    {/*        valid={this.state.companyNameValid}*/}
                                    {/*        warning={"Required"}*/}
                                    {/*        id={"companyName"}*/}
                                    {/*        onChange={this.handleChangeCompanyName}*/}
                                    {/*        placeholder={"Company Name"}*/}
                                    {/*        value={this.state.companyName}*/}
                                    {/*    />*/}
                                    {/*}*/}
                                    <PhoneInput
                                        title={"Mobile Phone"}
                                        valid={this.state.phoneValid}
                                        warning={"Enter a valid mobile phone"}
                                        id={"phone"}
                                        onChange={this.handleChangePhone}
                                        placeholder={'(###) ###-####'}
                                        value={this.state.phone}
                                        options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}}
                                    />
                                    <TextInput
                                        title={"Email"}
                                        valid={this.state.emailValid}
                                        warning={"Enter a valid email address"}
                                        id={"email"}
                                        onChange={this.handleChangeEmail}
                                        placeholder={"Email"}
                                        value={this.state.email}
                                    />
                                    <DropdownInput
                                        title={"Time zone"}
                                        options={timeZoneOptions}
                                        id={'timeZone'}
                                        onChange={this.handleChangeTimeZone}
                                        placeholder={'Time Zone'}
                                        value={this.state.timeZone}
                                    />
                                </Width>
                                <SaveSection>
                                    <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                </SaveSection>
                                <div onClick={this.props.tryLogout}><ButtonTertiary label={"Log out"}/></div>
                            </>
                    }
                </form>
            </>
        )
    }
}

const mapStateToProps = state => ({
    platform: state.common.user.get("platform"),
    settingsPersonalInfo: state.enterprise.settingsPersonal.get("settingsPersonalInfo")
});
const mapDispatchToProps = dispatch => ({
    tryUpdateSettingsPersonalInfo: (data) => dispatch(tryUpdateSettingsPersonalInfo(data)),
    tryGetSettingsPersonalInfo: () => dispatch(tryGetSettingsPersonalInfo()),
    tryLogout: () => dispatch(tryLogout()),
    // setCompanyName: (companyName) => dispatch(setCompanyName(companyName))
});
export default connect(mapStateToProps, mapDispatchToProps)(SettingsBasicInfo);
