import { Map, List, fromJS } from 'immutable';

/* Immutable */

const EVENTS_ALL = 'EVENTS_ALL';
const EVENTS__ALL_FETCHING = 'EVENTS__ALL_FETCHING';
const EVENTS_STORE_RESET = 'EVENTS_STORE_RESET';

/* Actions */

export const setEventsAll = (events) => ({"type": EVENTS_ALL, 'events': events});
export const setEventsAllFetching = (status) => ({"type": EVENTS__ALL_FETCHING, 'status': status});
export const setEventsStoreReset = () => ({"type": EVENTS_STORE_RESET});

/* Initial State */

const initialState = Map({
    eventsAll: List(),
    eventsAllFetching: false,
    eventsAllFetched: false,
});

/* Reducer */

const eventsReducer = (state=initialState, action) => {
    switch (action.type) {
        case EVENTS_ALL:
            return state.merge({
                'eventsAll': fromJS(action.events),
                'eventsAllFetched': true
            });
        case EVENTS__ALL_FETCHING:
            return state.merge({'eventsAllFetching': action.status});
        case EVENTS_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default eventsReducer;
