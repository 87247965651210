import { combineReducers } from 'redux';

/* Common */
import helpReducers from './store/help';

const helpsReducers = combineReducers({
    help: helpReducers,
});

export default helpsReducers;
