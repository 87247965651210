import React from 'react';

import SettingsEnterpriseTerms from './coachingleads';
import SettingsClients from "./coachingmatches";
import NavSecondaryTop from "../../navbars/containers/secondarytop";

const SettingsCoaching = () => (
    <>
        <NavSecondaryTop title={"Coaching"} />
        <SettingsEnterpriseTerms />
        <SettingsClients />
    </>
);
export default SettingsCoaching;