import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {Helmet} from 'react-helmet';

/* Components */
import {FontTitle30} from '../../common/components/fonts';
import {colors} from '../../common/components/colors';
import {images} from '../../common/components/images';
import {LogoLoading} from "../../common/components/loading";

/* Containers */
import IframeProfileStructure from '../profile/containers/structure';

/* Middleware */
import {tryGetExpertIdFromPublicHandle, tryGetExpertProfileFromToken} from './middleware/profile';
import {tryCheckWMCById} from "../../common/containers/company/middleware/wmc";
import {tryAuthOnly} from "../../routes/middleware/auth";

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

const MessageCenter = styled.div`
    margin: 140px auto;
    text-align: center;
`;

const Background = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: ${colors.background100};
`;

class IFrameProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {"isLoading": true, "expertFound": false, "requestingProfile": false}
    }

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        //await this.props.tryAuthOnly(null, false);
        let path = window.location.pathname;
        let pathAdj = path.charAt(path.length-1) === "/" ? path.slice(0, -1) : path;
        let pathSplit = pathAdj.split("/");
        if(pathSplit.length > 4) {
            return this.setState({"expertFound": false, "isLoading": false});
        } else {
            let handle;
            if(pathSplit.length === 4) {
                handle = pathSplit[pathSplit.length - 1];
            }
            await this.props.tryGetExpertIdFromPublicHandle("store", handle);
            if(this.props.profileHandle.get("error") === null) {
                const userId = this.props.profileHandle.get("id");
                await this.props.tryGetExpertProfileFromToken(userId);
                await this.props.tryCheckWMCById(userId);
                return this.setState({"expertFound": true /*this.props.profile.get("profileActive")*/, "isLoading": false});
            } else {
                return this.setState({"expertFound": false, "isLoading": false});
            }
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<Background><LoadingCenter><LogoLoading /></LoadingCenter></Background>)
        } else {
            if(this.state.expertFound) {
                return (
                    <>
                        <Helmet>
                            <link rel={"icon"} type={"image/png"} href={images.favicon16px} sizes={"16x16"} />
                            <meta charSet={"utf-8"} />
                            <title>{this.props.profile.get("firstName")}&nbsp;{this.props.profile.get("lastName")}&nbsp;|&nbsp;Willow</title>
                            <link rel={"canonical"} href={window.location.href} />
                            <meta name={"description"} content={this.props.profile.get("highlights")} />
                            <meta property={"og:type"} content={"website"} />
                            <meta property={"og:title"} content={this.props.profile.get("firstName")+" "+this.props.profile.get("lastName")+" | Willow"} />
                            <meta property={"og:description"} content={this.props.profile.get("highlights")} />
                            <meta property={"og:image"} content={this.props.profile.get("heroImageUrl")} />
                            <meta property={"og:url"} content={window.location.href} />
                            <meta property={"og:site_name"} content={"Willow"} />
                        </Helmet>
                        <IframeProfileStructure
                            profile={this.props.profile}
                            hideCompany={true}
                            alwaysShowContactBtns={true}
                            IframeStoreStructure={"firmPaid"}
                        />
                    </>
                )
            } else {
                return(<Background><MessageCenter><FontTitle30>No profile found</FontTitle30></MessageCenter></Background>)
            }
        }
    }

}

const mapStateToProps = state => ({
    profile: state.iframe.willowProfile.profile.get("profile"),
    profileHandle: state.iframe.willowProfile.profile.get("profileHandle"),
});

const mapDispatchToProps = dispatch => ({
    tryGetExpertProfileFromToken: (id) => dispatch(tryGetExpertProfileFromToken(id)),
    tryGetExpertIdFromPublicHandle: (type, handle) => dispatch(tryGetExpertIdFromPublicHandle(type, handle)),
    tryCheckWMCById: (id) => dispatch(tryCheckWMCById(id)),
    tryAuthOnly: (email, trackNewRegistration) => dispatch(tryAuthOnly(email, trackNewRegistration))
});

export default connect(mapStateToProps, mapDispatchToProps)(IFrameProfile);