import styled from "styled-components";

/* Components */
import {colors} from "../../../common/components/colors";

export const ContainerFixed = styled.div`
    width: 189px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    background-color: ${colors.background100};
    border-right: 1px solid ${colors.border100};
    padding: 40px 30px;
    @media screen and (max-width: 1088px) {
        display: none;
    }
`;

export const NavSide = styled.div`
    ${props => props.isSupport !== true} {top: 140px;}
    ${props => props.isSupport !== false} {top: 86px;}
    width: 189px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 4;
    background-color: ${colors.background100};
    border-right: 1px solid ${colors.border100};
    padding: 40px 30px;
    @media screen and (max-width: 1088px) {
        display: none;
    }
`;

export const Container = styled.div`
    height: auto;
    position: relative;
`;

export const Main = styled.div`
    > * { 
        &:last-child {
            margin-bottom: 25px;
        } 
    }
`;

export const Button = styled.div`
    ${props => props.status !== 'active'} {
        color: ${colors.primary100};
        font-weight: bold;
    }
    ${props => props.status !== 'inactive'} {
        color: ${colors.secondary100};
    }
    padding: 5px 0px;
    cursor: pointer;
    & :hover {
        color: ${colors.primary100};
        font-weight: bold;
    }
`;

export const DesktopSideLink = styled.div`
    ${props => props.onlyMobile === true} {display: block;}
    ${props => props.onlyMobile === false} {display: none;}
`;

export const FeedbackBtn = styled.div`
    bottom: 40px;
    position:absolute;
    padding: 5px 0px;
    width: calc(100% - 60px);
    cursor: pointer;
    color: ${colors.action100};
`;
