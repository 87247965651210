import React from 'react';
import ReactQuill from "react-quill";
import {v4 as uuid4} from "uuid";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

/* Middleware */
// import {tryGetListingsCheckout} from '../middleware/listings';

/* Components */
import {Left, Right, Body, Title, Type, Image, Prices, Purchase, Icon16, Container, Details, MarginRadio} from "../components/listingone";
import {FontBody14, FontBody16, FontHeader21, FontTitle30, FontHeader18, FontTitle24} from "../../../common/components/fonts";
import {QuillSlimNoHeight} from "../../../common/components/richtexteditor";
import {colors} from "../../../common/components/colors";
import {ButtonPrimary} from "../../../common/components/buttons";
import {ArrowRight} from 'react-feather';
import {RadioBoxWithLabel} from "../../../common/components/inputs";

class ListingOne extends React.Component {
    static defaultProps = {
        useCheckoutFunction: null
    };

    state={"listing": {}, "plans": [], "isPreview": false, "hasMultiplePayments": false, "planSelected": null, "isLoading": true};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "listing": this.props.listing,
            "plans": this.props.listing.get("plans").map(p => {
                let centsAreZero = parseInt(p.get("amount").toString().slice(-2)) === 0;
                return({
                    ...p.toJS(),
                    "amountAdj": (parseInt(p.get("amount"))/100).toFixed(centsAreZero ? 0 : 2),
                    "isFree": (p.get("planType") === "one_time" && parseInt(p.get("amount")) === 0 && p.get("description") === ""),
                    "isInvoiced": (p.get("planType") === "one_time" && parseInt(p.get("amount")) === 0 && p.get("description") !== ""),
                    "isUpfront": (p.get("planType") === "one_time" && parseInt(p.get("amount")) > 0),
                    "isLimitedPayments": (p.get("planType") === "recurring" && p.get("subscriptionDurationInMonths") !== null),
                    "isSubscription": (p.get("planType") === "recurring" && p.get("subscriptionDurationInMonths") === null),
                })
            }).toJS(),
            "hasMultiplePayments": this.props.listing.get("plans").size > 1,
            "planSelected": this.props.listing.get("plans").size > 1 ? this.props.listing.get("plans").get(0).get("id") : null,
            "isPreview": this.props.isPreview,
            "forceImagesVertical": this.props.forceImagesVertical,
            "isLoading": false
        })
    };

    selectPurchase = async (planId) => {
        await this.selectPlan(planId);
        this.selectCheckout();
    };

    selectPlan = async (planId) => {
        this.setState({"planSelected": planId})
    };

    selectCheckout = async () => {
        if(this.props.useCheckoutFunction === null) {
            this.props.history.push("/public/checkout/"+this.state.planSelected);
        } else {
            return this.props.useCheckoutFunction(this.state.planSelected);
        }
    };

    // selectEvent = async (planId) => {
    //     // Handle contact me, purchase, download CTAs and one per.
    //     console.log(planId);
    //     // this.props.tryGetListingsCheckout(planId)
    //     // this.props.selectPlanId(planId)
    //     this.props.history.push("/public/checkout/"+planId);
    // };

    render() {
        if(this.state.isLoading) {
            return(<div>test</div>)
        } else {
            return(
                <Container fullScreen={this.state.listing.get("coverPhoto") === null || this.state.forceImagesVertical}>
                    {this.state.listing.get("coverPhoto") !== null &&
                        <Left fullScreen={this.state.isPreview || this.state.forceImagesVertical}>
                            <Image src={this.state.listing.get("coverPhoto")} alt={""} fullScreen={this.state.isPreview || this.state.forceImagesVertical}/>
                        </Left>
                    }
                    <Right fullScreen={this.state.isPreview || this.state.listing.get("coverPhoto") === null || this.state.forceImagesVertical}>
                        <Body fullScreen={!this.state.isPreview}>
                            <Title><FontTitle30>{this.state.listing.get("title")}</FontTitle30></Title>
                            {/*<Details>*/}
                            {/*    {this.state.listing.get("serviceType") === "online" &&*/}
                            {/*        <FontBody16>What you get: Online Meetings</FontBody16>*/}
                            {/*    }*/}
                            {/*    {this.state.listing.get("serviceType") === "offline" &&*/}
                            {/*        <FontBody16>What you get: Offline Service</FontBody16>*/}
                            {/*    }*/}
                            {/*    {this.state.listing.get("serviceType") === "download" &&*/}
                            {/*        <FontBody16>What you get: Digital File</FontBody16>*/}
                            {/*    }*/}
                            {/*</Details>*/}
                            <QuillSlimNoHeight bodyBorderColor={colors.white}>
                                <ReactQuill
                                    id={uuid4()}
                                    readOnly={true}
                                    theme={null}
                                    value={this.state.listing.get("description")}
                                    bounds={'.app'}
                                />
                            </QuillSlimNoHeight>
                            <Prices>
                                {this.state.plans.map((p, pIndex) => {
                                    if(p.isFree || p.isInvoiced) {
                                        return(
                                            <div key={p.id}>
                                                <FontHeader21>{p.isFree ? "Free" : p.description}</FontHeader21>
                                                <Purchase onClick={() => {return this.selectPurchase(p.id)}}><ButtonPrimary submit={false} label={<>Get Started&nbsp;&nbsp;<Icon16><ArrowRight /></Icon16></>} /></Purchase>
                                            </div>
                                        )
                                    } else if((p.isUpfront || p.isLimitedPayments || p.isSubscription) && !this.state.hasMultiplePayments) {
                                        return(
                                            <div key={p.id}>
                                                {p.isUpfront &&
                                                    <FontHeader21>${p.amountAdj}</FontHeader21>
                                                }
                                                {p.isLimitedPayments &&
                                                    <FontHeader21>${p.amountAdj}/{p.recurringInterval}&nbsp;for&nbsp;{p.subscriptionDurationInMonths}&nbsp;{p.subscriptionDurationInMonths > 1 ? "months" : "month"}</FontHeader21>
                                                }
                                                {p.isSubscription &&
                                                    <FontHeader21>${p.amountAdj}/{p.recurringInterval}</FontHeader21>
                                                }
                                                <Purchase onClick={() => {return this.selectPurchase(p.id)}}><ButtonPrimary submit={false} label={<>Get Started&nbsp;&nbsp;<Icon16><ArrowRight /></Icon16></>} /></Purchase>
                                            </div>
                                        )
                                    } else if((p.isUpfront || p.isLimitedPayments || p.isSubscription) && this.state.hasMultiplePayments) {
                                        return(
                                            <div key={p.id}>
                                                {p.isUpfront &&
                                                    <RadioBoxWithLabel
                                                        id={p.id}
                                                        action={() => {return this.selectPlan(p.id)}}
                                                        checked={this.state.planSelected === p.id}
                                                        label={<MarginRadio><FontHeader21>${p.amountAdj}</FontHeader21></MarginRadio>}
                                                        subtext={null}
                                                    />
                                                }
                                                {p.isLimitedPayments &&
                                                    <RadioBoxWithLabel
                                                        id={p.id}
                                                        action={() => {return this.selectPlan(p.id)}}
                                                        checked={this.state.planSelected === p.id}
                                                        label={<MarginRadio><FontHeader21>${p.amountAdj}/{p.recurringInterval}&nbsp;for&nbsp;{p.subscriptionDurationInMonths}&nbsp;{p.subscriptionDurationInMonths > 1 ? "months" : "month"}</FontHeader21></MarginRadio>}
                                                        subtext={null}
                                                    />
                                                }
                                                {p.isSubscription &&
                                                    <RadioBoxWithLabel
                                                        id={p.id}
                                                        action={() => {return this.selectPlan(p.id)}}
                                                        checked={this.state.planSelected === p.id}
                                                        label={<MarginRadio><FontHeader21>${p.amountAdj}/{p.recurringInterval}</FontHeader21></MarginRadio>}
                                                        subtext={null}
                                                    />
                                                }
                                                {((pIndex + 1) === this.state.plans.length) &&
                                                    <Purchase onClick={() => {return this.selectCheckout()}}><ButtonPrimary submit={false} label={<>Get Started&nbsp;&nbsp;<Icon16><ArrowRight /></Icon16></>} /></Purchase>
                                                }
                                            </div>
                                        )
                                    }
                                })}
                            </Prices>
                        </Body>
                    </Right>
                </Container>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    // tryGetListingsCheckout: (planId) => dispatch(tryGetListingsCheckout(planId))
});

export default connect(null, mapDispatchToProps)(withRouter(ListingOne));

/*
{"coverPhoto" => null}
{"description" => "Lorem ipsum..."}
{"downloadUrl" => "www.download.com"}
{"id" => 3}
{"numberOfSessions" => null}
{"plans" =>
    {"amount" => 4000}
    {"description" => "Buy this plan for 2 meetings, 1 free"}
    {"id" => 90}
    {"orderIndex" => 0}
    {"planType" => "one_time"}
    {"recurringInterval" => null}
}
{"privacyPolicyUrl" => "www.google.com"}
{"serviceType" => "download"}
{"sessionLength" => 0}
{"status" => "Active"}
{"termsOfUseUrl" => "Lorem ipsum www.google.com"}
{"title" => "TestPlan 4"}
 */