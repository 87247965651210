import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import {Link, withRouter} from 'react-router-dom';

/* Components */
import {DropdownInput, TextInput, PhoneInput, MoneyInputGray, PercentInputGray} from "../../common/components/inputs";
import {Width, SaveSection, SubmitInputWithValidation, InlineBlock, CardOrNot, WidthRight, Right} from '../components/clientscreate';
import {FontTitle18} from '../../common/components/fonts';
import {ButtonTertiary} from '../../common/components/buttons';

/* Middleware */
import {tryCreateProspect, tryCreateProspectWmc} from '../middleware/prospects';
import {tryGetSettingsPersonalInfo} from "../middleware/settingspersonalinfo";
import {stageOptions} from "../middleware/prospectsstages";

/* Utils */
import {timeZoneOptions} from '../../common/utils/timezone2';

class ProspectsCreate extends React.Component {
    state = {
        first: '', firstValid: true,
        last: '', lastValid: true,
        phone: '', phoneValid: true,
        email: '', emailValid: true,
        companyName: '',
        revenue: "$0",
        probabilityOfClosing: 0,
        timeZone: '',
        stageId: '',
        stageTitle: '',
        isSaving: false,
        isSaved: false,
        error: '',
        isChanged: false,
        isValid: true
    };

    componentDidMount() {
        return this.init()
    }

    init = async () => {
        if(this.props.timeZone === "") {
            await this.props.tryGetSettingsPersonalInfo();
        }
        this.setState({"timeZone": this.props.timeZone});
    };

    componentWillUnmount() {clearInterval(this.timeout)}

    timeout = 0;

    handleChangeFirst = async e => {
        this.setState({'first': e.target.value});
        await this.setState({'firstValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeLast = async e => {
        this.setState({'last': e.target.value});
        await this.setState({'lastValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeEmail = async e => {
        this.setState({'email': e.target.value});
        await this.setState({'emailValid': validator.isEmail(e.target.value)});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangePhone = async e => {
        this.setState({'phone': e.target.value.replace('(', '').replace(')', '').replace(' ', '').replace('-', '')});
        await this.setState({'phoneValid': validator.isMobilePhone(e.target.value, 'en-US')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeTimeZone = e => {
        this.setState({'timeZone': e.value});
        this.setState({'isChanged': true});
    };
    handleChangeCompanyName = async e => {
        this.setState({
            'companyName': e.target.value,
            'isChanged': true
        });
        this.checkValid();
    };
    handleChangeStage = async e => {
        await this.setState({'stageId': e.value});
        await this.setState({'stageTitle': e.label});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeRevenue = async e => {
        this.setState({'revenue': e.target.value});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeProbabilityOfClosing = async e => {
        if(e === undefined || e === null) {
            this.setState({'probabilityOfClosing': 0});
        } else {
            if(validator.isNumeric(e.toString())) {
                this.setState({'probabilityOfClosing': (parseInt(e) > 100 ? 100 : (parseInt(e) < 0 ? 0 : parseInt(e)))});
                this.setState({'isChanged': true});
                this.checkValid();
            } else {
                this.setState({'probabilityOfClosing': 0});
            }
        }
    };

    checkValid = () => {
        const isFirstValid = (!validator.isEmpty(this.state.first) && this.state.first !== '');
        const isLastValid = (!validator.isEmpty(this.state.last) && this.state.last !== '');
        const isEmailValid = validator.isEmail(this.state.email);
        const isPhoneValid = validator.isMobilePhone(this.state.phone, 'en-US') || validator.isEmpty(this.state.phone) || this.state.phone === '';
        const isStageValid = (!validator.isEmpty(this.state.stageTitle) && this.state.stageTitle !== '');
        if(isFirstValid && isLastValid && isEmailValid && isPhoneValid && isStageValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.firstValid && this.state.lastValid && this.state.phoneValid && this.state.emailValid) {
            this.setState({'isSaving': true, 'error': ''});
            // if (this.props.isWMC) {
            //     await this.props.tryCreateProspectWmc(this.state);
            // } else {
            //     await this.props.tryCreateProspect(this.state);
            // }
            await this.props.tryCreateProspect(this.state);
            if(this.props.prospectCreatedError !== null) {
                this.setState({'error': this.props.prospectCreatedError, 'isSaving': false, 'isSaved': true, isChanged: false});
                this.timeout = setTimeout(() => {this.setState({'error': null, 'isSaving': false, 'isSaved': false, isChanged: true})}, 3000);
            } else {
                this.setState({'isSaving': false, 'isSaved': true, isChanged: false});
                this.timeout = setTimeout(() => {this.props.history.push("/prospects")}, 3000);
            }
        }
    };

    render() {
        return(
            <form method={"post"} onSubmit={this.handleSubmit}>
                {this.state.isSaved
                    ?
                        <>
                            {this.state.error === '' && <FontTitle18>Saved!</FontTitle18>}
                            {this.state.error !== '' && <FontTitle18>{this.state.error}</FontTitle18>}
                        </>
                    :
                        <>
                            {/*<InlineBlock>*/}
                                <div>
                                    <Width>
                                        <FontTitle18>About</FontTitle18>
                                        <TextInput
                                            title={"First Name*"}
                                            valid={this.state.firstValid}
                                            warning={"Required"}
                                            id={"first"}
                                            onChange={this.handleChangeFirst}
                                            placeholder={"First Name"}
                                            value={this.state.first}
                                        />
                                        <TextInput
                                            title={"Last Name*"}
                                            valid={this.state.lastValid}
                                            warning={"Required"}
                                            id={"last"}
                                            onChange={this.handleChangeLast}
                                            placeholder={"Last Name"}
                                            value={this.state.last}
                                        />
                                        <TextInput
                                            title={"Company Name"}
                                            valid={true}
                                            warning={""}
                                            id={"companyName"}
                                            onChange={this.handleChangeCompanyName}
                                            placeholder={"Company Name"}
                                            value={this.state.companyName}
                                        />
                                        <PhoneInput
                                            title={"Mobile phone"}
                                            valid={this.state.phoneValid}
                                            warning={""}
                                            id={"phone"}
                                            onChange={this.handleChangePhone}
                                            placeholder={'(###) ###-####'}
                                            value={this.state.phone}
                                            options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}}
                                        />
                                        <TextInput
                                            title={"Email*"}
                                            valid={this.state.emailValid}
                                            warning={"Enter a valid email address"}
                                            id={"email"}
                                            onChange={this.handleChangeEmail}
                                            placeholder={"Email"}
                                            value={this.state.email}
                                        />
                                        <DropdownInput
                                            title={"Time zone*"}
                                            options={timeZoneOptions}
                                            id={'timeZone'}
                                            onChange={this.handleChangeTimeZone}
                                            placeholder={'Time Zone'}
                                            value={this.state.timeZone}
                                        />
                                </Width>
                                <WidthRight><Right>
                                    <CardOrNot>
                                        <FontTitle18>Details</FontTitle18>
                                        <DropdownInput
                                            title={"Stage*"}
                                            options={stageOptions(this.props.stages)}
                                            id={'stage'}
                                            onChange={this.handleChangeStage}
                                            placeholder={'Stage'}
                                            value={this.state.stageTitle}
                                        />
                                        <MoneyInputGray
                                            title={this.props.isWMC ? "Assets" : "Revenue"}
                                            valid={true}
                                            warning={""}
                                            id={"revenue"}
                                            onChange={this.handleChangeRevenue}
                                            placeholder={"$0"}
                                            value={this.state.revenue}
                                        />
                                        <PercentInputGray
                                            title={"Probability to win"}
                                            valid={true}
                                            warning={""}
                                            id={"probabilityOfClosing"}
                                            onChange={this.handleChangeProbabilityOfClosing}
                                            placeholder={0}
                                            value={this.state.probabilityOfClosing}
                                        />
                                    </CardOrNot>
                                </Right></WidthRight>
                            </div>
                            <SaveSection>
                                <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                <InlineBlock><Link to={"/prospects"}><ButtonTertiary canSubmit={false} label={"Cancel"} /></Link></InlineBlock>
                            </SaveSection>
                        </>
                }
            </form>
        )
    }
}
const mapStateToProps = state => ({
    prospectCreatedError: state.enterprise.prospects.get("prospectCreatedError"),
    stages: state.enterprise.prospects.get("prospectStages"),
    timeZone: state.enterprise.settingsPersonal.get("settingsPersonalInfo").get("timeZone"),
    isWMC: state.common.wmc.get("isWMC")
});
const mapDispatchToProps = dispatch => ({
    tryCreateProspect: (data) => dispatch(tryCreateProspect(data)),
    tryCreateProspectWmc: (data) => dispatch(tryCreateProspectWmc(data)),
    tryGetSettingsPersonalInfo: () => dispatch(tryGetSettingsPersonalInfo())
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProspectsCreate));
