import React, {useEffect, useState, useMemo} from 'react';
import styled from "styled-components";

/* Components */
import {colors} from "../../../common/components/colors";
import {FontHeader18, FontBody18} from "../../../common/components/fonts";
import moment from "moment";

const Col = styled.div`
    display: flex;
    flex-direction: column;
    gap: 35px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
`;
const Day = styled.div`
  background-color: ${colors.canvaGreen};
  color: ${colors.white};
  text-align: center;
  min-width: 83px;
  width: 83px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 11px 0;
  border-radius: 10px 0 0 10px;
`;
const Times = styled.div`
  background-color: ${colors.white};
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 3px 20px;
  overflow-x: auto;
  border-radius: 0 10px 10px 0;

`;
const Time = styled(FontBody18)`
  background-color: ${colors.white};
  border-radius: 10px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  text-align: center;
  height: 100%;
  padding: 0 11px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  line-height: 18px !important;
  &:hover {
    background-color: ${colors.action100};
    color: ${colors.white};
  }
`;
const See = styled(FontBody18)`
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: underline;
`;
const Inline = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

AppointmentV3.defaultProps = {
    sessions: [],
    handleSelectDayTime: () => {},
}

export default function AppointmentV3 (props) {
    const sessions = props.sessions;

    const [visibleDays, setViewable] = useState(3)

    const groupedSessions = useMemo(() => {
        const groupedSessions = sessions.reduce((sessionsGroupedByDate, s) => {
            const day = moment(s).format('YY MM DD');
            if(!sessionsGroupedByDate[day])
                sessionsGroupedByDate[day] = [];
            sessionsGroupedByDate[day].push(s);
            return sessionsGroupedByDate;
        }, {});
        return Object.keys(groupedSessions).sort().map(group => groupedSessions[group]);
    });
    const numberOfAvailableDays = groupedSessions.length;

    const handleSeeMore = () => setViewable(visibleDays + 3);
    const handleSeeLess = () => setViewable(visibleDays - 3);

    const getDay = (key, sessions) => (
        <Row key={key}>
            <Day>
                <FontHeader18>{moment(sessions[0]).format('ddd')}</FontHeader18>
                <FontBody18>{moment(sessions[0]).format('MMM DD')}</FontBody18>
            </Day>
            <Times>
                {sessions.map((t,idx) => (<Time key={idx} onClick={() => props.handleChangeTime(t)}>{moment(t).format('h:mmA')}</Time>))}
            </Times>
        </Row>
    );

    return (
        <Col>
            {groupedSessions.slice(0, visibleDays).map((gs, idx) => getDay(idx, gs))}
            <Inline>
                {numberOfAvailableDays > visibleDays && <See onClick={() => handleSeeMore()}>See more</See>}
                {visibleDays > 3 && <See onClick={() => handleSeeLess()}>See less</See>}
            </Inline>
        </Col>
    )
}
