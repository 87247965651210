import React from "react";
import {connect} from "react-redux";
import styled from 'styled-components';
import validator from "validator";

/* Middleware */
import {setAnnouncementModalStatus} from '../store/coaching';
import {tryCreateAnnouncement, tryCreateAnnouncementSupport} from '../../homepage/middleware/announcements';
import {tryGetWmcsForCoach} from "../../partnerships/middleware/coaching";

/* Components */
import {FontTitle30, FontBody14, FontHeader16} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";
import {ButtonPrimary, ButtonInactive} from "../../../common/components/buttons";
import {CloseOutlined} from '@ant-design/icons';
import {TextInput, TextareaInput, SearchInput, TextQuillInput} from "../../../common/components/inputs";
import {LogoLoading} from "../../../common/components/loading";

const Modal = styled.div`
    margin: 0px auto;
    max-width: 500px;
    padding: 30px;
    border-radius: 6px;
    background-color: ${colors.white};
    position: fixed;
    top: 100px;
    left: 0px;
    right: 0px;
    height: 100%;
    ${props => props.big !== true} {
        max-height: 550px;
        height: fit-content;
        overflow-y: auto;
    }
    ${props => props.big !== false} {
        max-height: 200px;
        height: fit-content;
    }
    @media screen and (max-width: 460px) {
        top: 20px;
        width: calc(100% - 20px);
        padding: 0px 10px;
    }
    z-index: 6;
`;
const Background = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.50);
    @media screen and (max-width: 460px) {
        background-color: ${colors.white};
    }
    z-index: 5;
`;

const FitContentFullWidth = styled.div`
    width: 100%;
    & > div {
        width: calc(100% - 47px);
        text-align: center;
        margin: 0px auto;
    }
`;
const Indent = styled.div`
    width: calc(100% - 40px);
    padding: 0px 20px;
`;
const Header = styled.div`
    border-bottom: 1px solid ${colors.secondary30};
    padding-bottom: 10px;
    margin-top: -10px;
    margin-bottom: 30px;
`;

const SendButton = styled.div`
    position: absolute;
    bottom: 30px;
    left: 30px;
    width: calc(100% - 60px);
    text-align: center;
`;
const Close = styled.div`
    width: 25px;
    vertical-align: top;
    display: inline-block;
    color: ${colors.secondary100};
    cursor: pointer;
`;
const Inline = styled.div`
    width: calc(100% - 25px);
    display: inline-block;
    vertical-align: top;
`;
const Green = styled.div`
    color: ${colors.progress100};
    text-align: center;
    width: calc(100% - 25px);
`;

const whoOptions = (who) => {
    return who.map(w => {
        return({
            value: {
                wmcId: w.get("id"),
                businessUserId: null
            },
            label: w.get("companyName"),
            isArchived: false
        })
    }).toJS();
};

class TeamIntroduceLinksModal extends React.Component {
    state={
        isLoading: true,
        announcement: "", announcementValid: false,
        title: "", titleValid: false,
        wmcId: null, wmcLabel: "",
        allValid: false,
        isSent: false
    }

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        
    }

    init = async () => {
        if (this.props.isSupport) {
            if(!this.props.allWmcsFetched) {
                await this.props.tryGetWmcsForCoach()
            }
        }
        this.setState({"isLoading": false})
    };

    handleAnnouncement = async (e) => {
        if(e === null) {
            await this.handleDeleteAnnouncement();
        } else {
            await this.setState({"announcement": e, 'announcementValid': (!validator.isEmpty(e) && e !== '')});
        }
        return this.checkValid()
    }

    handleDeleteAnnouncement = async () => {
        this.setState({'announcement': null})
    };

    handleTitle = async (e) => {
        if(e === null) {
            await this.handleDeleteTitle();
        } else {
            await this.setState({"title": e.target.value, 'titleValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        }
        return this.checkValid()
    }

    handleDeleteTitle = async () => {
        this.setState({'title': null})
    };

    handleDeleteWmc = async () => {
        this.setState({'wmc': null, 'wmcLabel': ""})
    };

    handleChangeWmc = async (e) => {
        if(e === null) {
            await this.handleDeleteWmc();
        } else {
            await this.setState({
                'wmc': e,
                "wmcLabel": e.label,
            });        }
        return this.checkValid()
    };

    checkValid = async () => {
        const titleValid = this.state.title !== ''
        const announcementValid = this.state.announcement !== ''
        let wmcValid;
        if (this.props.isSupport) {
            wmcValid = this.state.wmcLabel !== ''
        } else {
            wmcValid = true
        }
        this.setState({'allValid': titleValid && announcementValid && wmcValid})
    }

    handleSubmit = async() => {
        await this.props.tryCreateAnnouncement(this.state.title, this.state.announcement)
        this.setState({"isLoading": true, "announcement": "", "announcementValid": false, "title": "", "titleValid": false})
        await this.props.setAnnouncementModalStatus(false)
        this.setState({isSent: true});
        this.timeout = setTimeout(() => {this.setState({isSent: false})}, 3000);
    }

    handleSubmitSupport = async() => {
        await this.props.tryCreateAnnouncementSupport(this.state.title, this.state.announcement, this.state.wmc.value.wmcId)
        this.setState({"isLoading": true, "announcement": "", "announcementValid": false, "title": "", "titleValid": false, "wmc": null, "wmcLabel": ""})
        await this.props.setAnnouncementModalStatus(false)
        this.setState({isSent: true});
        this.timeout = setTimeout(() => {this.setState({isSent: false})}, 3000);
    }

    render() {
        if(this.props.announcementModalOpen) {
            if (this.state.isLoading) {
                return (<LogoLoading />)
            } else {
                return(
                    <>
                        <Modal big={true}>
                            <Header>
                                <Inline><FontTitle30>{"Create Announcement"}</FontTitle30></Inline>
                                <Close onClick={() => {return this.props.setAnnouncementModalStatus(false)}}><FontHeader16><CloseOutlined /></FontHeader16></Close>
                            </Header>
                            <Indent>
                                {this.props.isSupport ?
                                <>
                                    <>
                                        <FontBody14>This announcement will be sent to all coaches within selected WMC.</FontBody14>
                                        <SearchInput
                                            title={"Wealth Management Company"}
                                            options={whoOptions(this.props.allWmcs)}
                                            id={'wmc'}
                                            onChange={this.handleChangeWmc}
                                            placeholder={'Search companies'}
                                            value={this.state.wmcLabel}
                                        />
                                        <TextInput
                                            title={"Title"}
                                            valid={this.state.titleValid}
                                            warning={"Required"}
                                            id={"message"}
                                            onChange={this.handleTitle}
                                            placeholder={"Title"}
                                            value={this.state.title}
                                        />
                                        <TextQuillInput
                                            title={"Body"}
                                            valid={this.state.announcementValid}
                                            warning={"Required"}
                                            id={"message"}
                                            onChange={this.handleAnnouncement}
                                            placeholder={"Wrgite your announcement here..."}
                                            value={this.state.announcement}
                                            minLength={0}
                                            count={0}
                                            minRows={3}
                                            required={false}
                                        />
                                    </>
                                    <br />
                                    <br />
                                    <FitContentFullWidth onClick={() => {return this.handleSubmitSupport()}}>
                                        {this.state.allValid
                                            ? <ButtonPrimary canSubmit={false} label={"Send"} />
                                            : <ButtonInactive canSubmit={false} label={"Send"} />
                                        }
                                    </FitContentFullWidth>
                                    <br />
                                </>
                                :
                                <>
                                    <>
                                        <FontBody14>This announcement will be sent to all of your partnered coaches.</FontBody14>
                                        <TextInput
                                            title={"Title"}
                                            valid={this.state.titleValid}
                                            warning={"Required"}
                                            id={"message"}
                                            onChange={this.handleTitle}
                                            placeholder={"Title"}
                                            value={this.state.title}
                                        />
                                        <TextQuillInput
                                            title={"Body"}
                                            valid={this.state.announcementValid}
                                            warning={"Required"}
                                            id={"message"}
                                            onChange={this.handleAnnouncement}
                                            placeholder={"Write your announcement here..."}
                                            value={this.state.announcement}
                                            minLength={0}
                                            count={0}
                                            minRows={3}
                                            required={false}
                                        />
                                    </>
                                    <br />
                                    <br />
                                    <FitContentFullWidth onClick={() => {return this.handleSubmit()}}>
                                        {this.state.allValid
                                            ? <ButtonPrimary canSubmit={false} label={"Send"} />
                                            : <ButtonInactive canSubmit={false} label={"Send"} />
                                        }
                                    </FitContentFullWidth>
                                    <br />
                                </>
                                }
                            </Indent>
                        </Modal>
                        <Background />
                    </>
                )
            }
        } else {
            if (this.state.isSent) {
                return(
                    <>
                        <Modal big={false}>
                            <Green><FontTitle30>{"Announcement sent!"}</FontTitle30></Green>
                        </Modal>
                        <Background />
                    </>
                )
            } else {
                return null
            }
        }
    }
}

const mapStateToProps = state => ({
    announcementModalOpen: state.enterprise.team.coaching.get("announcementModalOpen"),
    isSupport: state.common.user.get("isSupport"),
    allWmcs: state.enterprise.partners.partners.get("wmcsAssignedToCoach"),
    allWmcsFetched: state.enterprise.partners.partners.get("wmcsAssignedToCoachFetched"),
});

const mapDispatchToProps = dispatch => ({
    setAnnouncementModalStatus: (status) => dispatch(setAnnouncementModalStatus(status)),
    tryCreateAnnouncement: (title, body) => dispatch(tryCreateAnnouncement(title, body)),
    tryCreateAnnouncementSupport: (title, body, wmcId) => dispatch(tryCreateAnnouncementSupport(title, body, wmcId)),
    tryGetWmcsForCoach: () => dispatch(tryGetWmcsForCoach()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamIntroduceLinksModal);
