import React from 'react';
import styled from "styled-components";

/* Components */
import NavSecondaryTop from "../../navbars/containers/secondarytop";

/* Containers */
import ChangePassword from '../../../iframes/changepassword/containers/changepassword';

const Width = styled.div`
    max-width: 536px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
`;

class SettingsSecurity extends React.Component {
    render() {
        return(
            <>
                <NavSecondaryTop title={"Security"} />
                <Width>
                    <ChangePassword
                        hasBack={false}
                    />
                </Width>
            </>
        )
    }
}

export default SettingsSecurity;
