import React from 'react';
import styled from 'styled-components';

/* Components */
import {colors} from "../../../common/components/colors";
import {RightOutlined} from '@ant-design/icons';
import {FontBody16To14} from '../../../common/components/fonts';

const Icon = styled.div`
  display: inline-block;
  span {
    font-size: 16px;
    padding: 10px 0;
    color: ${colors.primary100};
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
  }
`;
const Label = styled.div`
  display: inline-block;
  vertical-align: top;
  padding: 6px;
  color: ${props => props.color};
  text-align: left;
`;

const Submit = styled.button`
  display: block;
  background-color: ${props => props.backgroundColor};
  border-radius: 25px;
  border: none;
  padding: 8px 24px;
  vertical-align: baseline;
  font-weight: bold;
  color: ${colors.primary100};
  cursor: pointer;
  &:active, &:focus {
    outline: none;
    border: none;
  }
  &:disabled {
    background-color: ${colors.secondary100};
  }
`;

const NavButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export default function ContactButtons() {
    return (
        <NavButtons>
            <Submit backgroundColor={colors.action100}>
                <Label color={colors.white}><FontBody16To14>{"Confirm"}</FontBody16To14></Label>
                <Icon> <RightOutlined style={{color: "#ffffff"}}/></Icon>
            </Submit>
        </NavButtons>
    )
}