import React from 'react';
import validator from 'validator';
import {v4 as uuid4} from 'uuid';

/* Components */
import {
    TextInput,
    RadioBoxWithLabel,
    TextareaInputNoCount,
    MoneyInputGray,
    NumberInput, DropdownInput,
} from "../../../common/components/inputs";
import {ButtonLinkInactive, ButtonLink, ButtonPrimary, ButtonSecondary, ButtonTertiary} from "../../../common/components/buttons";
import {FontBody16} from "../../../common/components/fonts";
import {FitContent, Inset, Section, Inline, Border, Margin, Red} from '../components/plans';

const frequency = [
    {"label": "Monthly", "value": "month"},
    {"label": "Quarterly", "value": "quarter"},
    {"label": "Yearly", "value": "year"}
];

class ListingPricePlan extends React.Component {
    state = {
        id: uuid4(),
        planOpen: false,
        upfrontChecked: true, scheduledChecked: false, subscriptionChecked: false,
        upfrontPrice: 0, upfrontPriceValid: true,
        scheduledMonthlyPrice: 0, scheduledMonthlyPriceValid: true,
        scheduledNumMonths: 1, scheduledNumMonthsValid: true,
        subscriptionFrequency: "month",
        subscriptionPrice: 0, subscriptionPriceValid: true,
        isValid: false,
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        if(this.props.data.length !== 0) {
            this.setState({...this.props.data})
        }
    };

    handleChangeSubscriptionPrice = async e => {
        await this.setState({
            'subscriptionPrice': e.target.value,
            'subscriptionPriceValid': (!validator.isEmpty(e.target.value.toString()) && e.target.value.toString() !== '' && e.target.value !== 0 && e.target.value.toString() !== "$"),
            'isChanged': true
        });
        this.checkValid();
    };

    handleChangeSubscriptionFrequency = async (selected) => {
        this.setState({"subscriptionFrequency": selected.value})
    };

    handleChangeUpfrontPrice = async e => {
        await this.setState({
            'upfrontPrice': e.target.value,
            'upfrontPriceValid': (!validator.isEmpty(e.target.value.toString()) && e.target.value.toString() !== '' && e.target.value !== 0 && e.target.value.toString() !== "$"),
            'isChanged': true
        });
        this.checkValid();
    };

    handleChangeScheduledMonthlyPrice = async e => {
        await this.setState({
            'scheduledMonthlyPrice': e.target.value,
            'scheduledMonthlyPriceValid': (!validator.isEmpty(e.target.value.toString()) && e.target.value.toString() !== '' && e.target.value !== 0 && e.target.value.toString() !== "$"),
            'isChanged': true
        });
        this.checkValid();
    };

    handleChangeScheduledNumMonths = async e => {
        if(e.target.value < 1) {
            await this.setState({'scheduledNumMonths': 1, 'scheduledNumMonthsValid': true, 'isChanged': true});
        } else {
            await this.setState({
                'scheduledNumMonths': e.target.value,
                'scheduledNumMonthsValid': (!validator.isEmpty(e.target.value.toString()) && e.target.value.toString() !== ''),
                'isChanged': true
            });
        }
        this.checkValid();
    };

    handleAllowUpfrontPurchase = async () => {
        await this.setState({"upfrontChecked": true, "scheduledChecked": false, "subscriptionChecked": false});
        this.checkValid();
    };

    handleAllowMonthlyPurchase = async () => {
        await this.setState({"upfrontChecked": false, "scheduledChecked": true, "subscriptionChecked": false});
        this.checkValid();
    };

    handleAllowSubscriptionPurchase = async () => {
        await this.setState({"upfrontChecked": false, "scheduledChecked": false, "subscriptionChecked": true});
        this.checkValid();
    };

    checkValid = () => {
        if(this.state.upfrontChecked) {
            if(this.state.upfrontPrice === 0 || this.state.upfrontPrice === "$" || this.state.upfrontPrice === "$0") {
                this.setState({'isValid': false})
            } else {
                this.setState({'isValid': true})
            }
        }
        if(this.state.scheduledChecked) {
            if(this.state.scheduledMonthlyPrice === 0 || this.state.scheduledMonthlyPrice === "$" || this.state.scheduledMonthlyPrice === "$0") {
                this.setState({'isValid': false})
            } else {
                this.setState({'isValid': true})
            }
        }
        if(this.state.subscriptionChecked) {
            if(this.state.subscriptionPrice === 0 || this.state.subscriptionPrice === "$" || this.state.subscriptionPrice === "$0") {
                this.setState({'isValid': false})
            } else {
                this.setState({'isValid': true})
            }
        }
    };

    handleSubmit = async e => {
        if(this.state.isValid) {
            return this.props.save(this.state.id, this.state, false)
        }
    };

    handleCancel = async e => {
        return this.props.cancel(this.state.id)
    };

    render() {
        return(
            <>
                <Section>
                    <FontBody16>Create one (or more) payment types</FontBody16>
                    <br />
                    <br />
                    <RadioBoxWithLabel
                        id={5}
                        action={this.handleAllowUpfrontPurchase}
                        checked={this.state.upfrontChecked}
                        label={"Flat Fee"}
                        subtext={"Buyers pay the full price upfront."}
                        disabled={false}
                    />
                    {this.state.upfrontChecked &&
                        <Inset>
                            <MoneyInputGray
                                title={"Total Price*"}
                                valid={this.state.upfrontPriceValid}
                                warning={"Required"}
                                id={"upfrontPrice"}
                                onChange={this.handleChangeUpfrontPrice}
                                placeholder={0}
                                value={this.state.upfrontPrice}
                            />
                            <div>
                                <Inline>
                                    {this.state.isValid
                                        ? <FitContent onClick={this.handleSubmit}><ButtonSecondary canSubmit={false} label={"Save"} /></FitContent>
                                        : <FitContent><ButtonTertiary canSubmit={false} label={"Save"} /></FitContent>
                                    }
                                </Inline>
                                {!this.props.isFirstPlan &&
                                <Inline><FitContent onClick={this.handleCancel}><ButtonLinkInactive canSubmit={false} label={"Cancel"} /></FitContent></Inline>
                                }
                            </div>
                        </Inset>
                    }
                    {/*<br />*/}
                    {/*<RadioBoxWithLabel*/}
                    {/*    id={4}*/}
                    {/*    action={this.handleAllowMonthlyPurchase}*/}
                    {/*    checked={this.state.scheduledChecked}*/}
                    {/*    label={"Limited Payments"}*/}
                    {/*    subtext={"Buyers pay for a specified number of months."}*/}
                    {/*    disabled={this.props.downloadChecked}*/}
                    {/*/>*/}
                    {/*{this.state.scheduledChecked &&*/}
                    {/*    <Inset>*/}
                    {/*        <MoneyInputGray*/}
                    {/*            title={"Price Per Month*"}*/}
                    {/*            valid={this.state.scheduledMonthlyPriceValid}*/}
                    {/*            warning={"Required"}*/}
                    {/*            id={"scheduledMonthlyPrice"}*/}
                    {/*            onChange={this.handleChangeScheduledMonthlyPrice}*/}
                    {/*            placeholder={0}*/}
                    {/*            value={this.state.scheduledMonthlyPrice}*/}
                    {/*        />*/}
                    {/*        <NumberInput*/}
                    {/*            title={"Pay for X Months*"}*/}
                    {/*            valid={this.state.scheduledNumMonthsValid}*/}
                    {/*            warning={"Required"}*/}
                    {/*            id={"scheduledNumMonths"}*/}
                    {/*            onChange={this.handleChangeScheduledNumMonths}*/}
                    {/*            placeholder={3}*/}
                    {/*            value={this.state.scheduledNumMonths}*/}
                    {/*        />*/}
                    {/*        <div>*/}
                    {/*            <Inline>*/}
                    {/*                {this.state.isValid*/}
                    {/*                    ? <FitContent onClick={this.handleSubmit}><ButtonSecondary canSubmit={false} label={"Save"} /></FitContent>*/}
                    {/*                    : <FitContent><ButtonTertiary canSubmit={false} label={"Save"} /></FitContent>*/}
                    {/*                }*/}
                    {/*            </Inline>*/}
                    {/*            {!this.props.isFirstPlan &&*/}
                    {/*            <Inline><FitContent onClick={this.handleCancel}><ButtonLinkInactive canSubmit={false} label={"Cancel"} /></FitContent></Inline>*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    </Inset>*/}
                    {/*}*/}
                    <br />
                    <RadioBoxWithLabel
                        id={4}
                        action={this.handleAllowSubscriptionPurchase}
                        checked={this.state.subscriptionChecked}
                        label={"Subscription"}
                        subtext={"Buyers pay a subscription with automatic renewals."}
                        disabled={this.props.downloadChecked}
                    />
                    {this.state.subscriptionChecked &&
                        <Inset>
                            <DropdownInput
                                title={"Payment Frequency"}
                                options={frequency}
                                id={'subscriptionFrequency'}
                                onChange={this.handleChangeSubscriptionFrequency}
                                placeholder={'month'}
                                value={this.state.subscriptionFrequency}
                            />
                            <MoneyInputGray
                                title={"Price Per Period*"}
                                valid={this.state.subscriptionPriceValid}
                                warning={"Required"}
                                id={"subscriptionPrice"}
                                onChange={this.handleChangeSubscriptionPrice}
                                placeholder={0}
                                value={this.state.subscriptionPrice}
                            />
                            <div>
                                <Inline>
                                    {this.state.isValid
                                        ? <FitContent onClick={this.handleSubmit}><ButtonSecondary canSubmit={false} label={"Save"} /></FitContent>
                                        : <FitContent><ButtonTertiary canSubmit={false} label={"Save"} /></FitContent>
                                    }
                                </Inline>
                                {!this.props.isFirstPlan &&
                                    <Inline><FitContent onClick={this.handleCancel}><ButtonLinkInactive canSubmit={false} label={"Cancel"} /></FitContent></Inline>
                                }
                            </div>
                        </Inset>
                    }
                </Section>
            </>
        )
    }
}

export default ListingPricePlan;
