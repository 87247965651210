import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setSignupWMCUserCreating, setSignupWMCUserError} from "../store/signupwmcuser";

/* Static */
import {devSignupWMCUserCreate} from "../static/devdata";

export const tryCreateUserAdvisor = (data) => {
    return async dispatch => {
        dispatch(setSignupWMCUserCreating(true));
        const createUser = await axiosReq('apiv1/wmc/add-employee', "POST", {firstName: data.first, lastName: data.last, phone: data.phone, email: data.email, timeZone: data.timeZone/*, wmcId: data.wmcId*/}, devSignupWMCUserCreate(data));
        if(createUser.hasOwnProperty("error")) {
            dispatch(setSignupWMCUserError(createUser.error));
        } else {
            dispatch(setSignupWMCUserError(""));
        }
        return dispatch(setSignupWMCUserCreating(false));
    }
};