import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";

/* Containers */
import RecurringSettings from "./recurringsettings";
import ForceSync from "./forcesync";
import DisconnectSync from "./disconnectsync";

/* Components */
import {Bar} from "../components/recurringstatus";
import {LogoLoading} from "../../../../common/components/loading";
import AvailabilityManualCalendar from "../../../availability/containers/manualcalendar";

export const Width = styled.div`
    max-width: 800px;
    width: 100%;
    @media screen and (max-width: 1088px) {
        max-width: 100%;
    }
`;

class AvailabilityRecurringStatus extends React.Component {
    static defaultProps = {
        onDisconnect: () => {}
    }

    state={"isLoading": true, "method": "willow"};

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.method !== this.props.method) {
            return this.init();
        }
    }

    init = async () => {
        this.setState({
            "method": this.props.method,
            "isLoading": false
        })
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <>
                <Width>
                    <RecurringSettings onSave={this.props.onDisconnect} />
                        {(this.state.method !== "willow" && this.state.method !== "willow_recurring") &&
                    <><Bar /><ForceSync /></>
                    }
                    {this.state.method !== "willow" &&
                        <><Bar /><DisconnectSync onDisconnect={this.props.onDisconnect} /></>
                    }
                </Width>

                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    method: state.enterprise.recurring.get("recurringAvailability").get("availability_calendar_update_method")
});

export default connect(mapStateToProps, null)(AvailabilityRecurringStatus);