import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import validator from "validator";

/* Components */
import {ButtonPrimary, ButtonInactive} from "../../../common/components/buttons";
import {FontBody14, FontHeader16, FontBody16, FontHeader21, FontBody21} from "../../../common/components/fonts";
import {DisclosureText, ProductTermPrivacy, FullWidthNotButton, LoginBtn, Left, Right, PhoneEmpty, Mobile, Container, Email, Top, Bottom, Phone, FullWidth, Disclosure, LoaderStyle, FullWidthNotButtonText} from '../components/payment';
import {PhoneInput, TextInput} from "../../../common/components/inputs";
import {LogoLoadingSmall} from "../../../common/components/loading";

/* Middleware */

function PaymentNone(props) {
    const user = useSelector(state => state.common.user);
    const allowPublicDomainSignups = props.allowPublicDomainSignups;
    const [emailIsPersonal, setEmailIsPersonal] = useState(false);
    const [inModal, setInModal] = useState(false);
    const [fromDashboard, setFromDashboard] = useState(false);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [showUserLogin, setShowUserLogin] = useState(true);
    const [userExists, setUserExists] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [firstNameValid, setFirstNameValid] = useState(true);
    const [lastName, setLastName] = useState("");
    const [lastNameValid, setLastNameValid] = useState(true);
    const [email, setEmail] = useState("");
    const [emailValid, setEmailValid] = useState(true);
    const [phone, setPhone] = useState("");
    const [phoneValid, setPhoneValid] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isPurchasing, setIsPurchasing] = useState(false);

    const [error, setError] = useState(null);

    useEffect(() => {
        const init = async () => {
            setShowUserLogin(props.showUserLogin);
            setInModal(props.inModal);
            setFromDashboard(props.fromDashboard);
            setUserLoggedIn(props.userLoggedIn);
            // setIsLoading(false);
        };
        init();
    }, []);

    useEffect(() => {
        setFirstNameValid((!validator.isEmpty(firstName) && firstName !== ''));
    }, [firstName]);

    useEffect(() => {
        setLastNameValid((!validator.isEmpty(lastName) && lastName !== ''));
    }, [lastName]);

    useEffect(() => {
        setEmailValid(validator.isEmail(email));
        const personalDomains = ['outlook.com', 'gmail.com', 'yahoo.com', 'inbox.com', 'icloud.com', 'mail.com', 'gmx.com', 'yandex.com', 'proton.me']
        const isPersonalEmail = personalDomains.includes(email.split("@")[1]);
        setEmailIsPersonal(isPersonalEmail)
    }, [email]);

    useEffect(() => {
        setPhoneValid(validator.isMobilePhone(phone, 'en-US'));
    }, [phone]);

    useEffect(() =>{
        setUserExists(false);
    }, [firstName, lastName, email, phone]);

    useEffect(() => {
        if(!userLoggedIn && firstNameValid && lastNameValid && emailValid /*&& phoneValid*/) {
            setIsValid(true);
        } else if(userLoggedIn) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [userLoggedIn, firstNameValid, lastNameValid, emailValid, phoneValid, userExists]);

    const handleChangeFirstName = e => setFirstName(e.target.value);
    const handleChangeLastName = e => setLastName(e.target.value);
    const handleChangeEmail = e => setEmail(e.target.value);
    const handleChangePhone = e => setPhone(e.target.value);
    const handleNotUserLoggedIn = e => setUserLoggedIn(false);

    const handleSubmit = async e => {
        e.preventDefault();

        setIsPurchasing(true);
        setError(null);

        if(!userLoggedIn) {
            let userEmail = email;
            let userPhone = parseInt(phone.replace('(', '').replace(')', '').replace(' ', '').replace('-', ''));
            const checkUser = await props.checkUserExists(firstName, lastName, userEmail, userPhone);
            if (checkUser.statusExists) {
                setUserExists(true);
                setIsValid(false);
                setIsPurchasing(false);
                return;
            }
        }

        props.paymentSuccessful();
        setIsPurchasing(false);
    };

    // if(isLoading) {
    //     return(<LoadingCenter><LogoLoading /></LoadingCenter>)
    // }

    return(
        <form method={"post"} onSubmit={handleSubmit}>
            <Container inModal={inModal}>
                {(!inModal || (inModal && !fromDashboard)) &&
                    <>
                        <div>
                            <Left><FontHeader21>Confirm</FontHeader21></Left>
                            {showUserLogin &&
                                <>
                                    {!userLoggedIn
                                        ? <Right><FontBody16>Have an account?&nbsp;<LoginBtn onClick={props.login}><FontHeader16>Log in</FontHeader16></LoginBtn></FontBody16></Right>
                                        : <Right><LoginBtn onClick={handleNotUserLoggedIn}><FontHeader16>Not {user.get("first")}?</FontHeader16></LoginBtn></Right>
                                    }
                                </>
                            }
                        </div>
                        {!userLoggedIn
                            ?
                                <Email>
                                    <TextInput
                                        title={"Email"}
                                        valid={userExists ? false : ((!allowPublicDomainSignups && emailIsPersonal) ? false : (email === "" ? true : emailValid))}
                                        warning={userExists ? "Email registered, log in to purchase" : ((!allowPublicDomainSignups && emailIsPersonal) ? "Company email required" : "Enter a valid email address")}
                                        id={"email"}
                                        onChange={handleChangeEmail}
                                        placeholder={"Email"}
                                        value={email}
                                    />
                                </Email>
                            :
                                <Email><FontBody21>Welcome back {user.get("first")}!</FontBody21></Email>
                        }
                        {!userLoggedIn &&
                            <>
                                <Top>
                                    <TextInput
                                        title={"Name"}
                                        valid={(firstName === "" && lastName === "") ? true : (firstNameValid && lastNameValid)}
                                        warning={"Required"}
                                        id={"firstName"}
                                        onChange={handleChangeFirstName}
                                        placeholder={"First name"}
                                        value={firstName}
                                    />
                                </Top>
                                <Bottom>
                                    <TextInput
                                        title={undefined}
                                        valid={true}
                                        warning={undefined}
                                        id={"lastName"}
                                        onChange={handleChangeLastName}
                                        placeholder={"Last name"}
                                        value={lastName}
                                    />
                                </Bottom>
                            </>
                        }
                        <PhoneEmpty />
                        {/*{!userLoggedIn*/}
                        {/*    ?*/}
                        {/*        <Phone>*/}
                        {/*            <PhoneInput*/}
                        {/*                title={"Mobile phone"}*/}
                        {/*                valid={phone === "" ? true : phoneValid}*/}
                        {/*                warning={"Enter a valid mobile phone"}*/}
                        {/*                id={"phone"}*/}
                        {/*                onChange={handleChangePhone}*/}
                        {/*                placeholder={'(###) ###-####'}*/}
                        {/*                value={phone}*/}
                        {/*                options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}}*/}
                        {/*            />*/}
                        {/*        </Phone>*/}
                        {/*    :*/}
                        {/*        <PhoneEmpty />*/}
                        {/*}*/}
                    </>
                }
                {isPurchasing
                    ? <LoaderStyle><LogoLoadingSmall /></LoaderStyle>
                    : <>
                        {isValid
                            ? <FullWidth oneLevel={isValid}><ButtonPrimary canSubmit={true} label={"Continue"}/></FullWidth>
                            : <FullWidthNotButtonText><ButtonInactive canSubmit={false} label={"Continue"}/></FullWidthNotButtonText>
                        }
                    </>
                }
                <Disclosure>
                    <DisclosureText>By continuing, you agree to work with {props.product.get("expert").get("fullName")} in accordance with their {(props.product.get("termsOfUseUrl") === null || props.product.get("termsOfUseUrl") === "") ? "terms" : <a href={props.product.get("termsOfUseUrl")} target={"_blank"}>terms</a>}.</DisclosureText>
                </Disclosure>
                {!inModal &&
                    <Mobile>
                        <ProductTermPrivacy
                            termsOfUseUrl={props.product.get("termsOfUseUrl")}
                            privacyPolicyUrl={props.product.get("privacyPolicyUrl")}
                        />
                    </Mobile>
                }
            </Container>
        </form>
    )
}

export default PaymentNone;