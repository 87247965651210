import React from "react";
import {connect} from 'react-redux';
import styled from 'styled-components';

/* Components */
import {FontHeader21, FontHeader16} from "../../../common/components/fonts";
import { PlusOutlined } from "@ant-design/icons";
import {LogoLoading} from "../../../common/components/loading";
import { colors } from "../../../common/components/colors";

/* Middleware */
import {tryGetSharedDriveList, tryCreateNewSharedDrive} from '../../../common/containers/vault/middleware/filevault';

/* Store */
import {setSharedDrive} from '../../../common/containers/vault/store/filevault';
import AllSharedVaultList from "../../../common/containers/vault/containers/allsharedvaultlist";

const randomNumber = () => {return Math.floor(Math.random() * 100)+1};

const CreateDrive = styled.div`
    text-align: center;
    font-family: CooperLight;
    font-size: 30px;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    background: ${colors.primary100};
    color: ${colors.action100};
    padding: 80px 30px 125px;
    margin: -30px 0;
    height: 100%;
`;
const Container2 = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    background: ${colors.backgroundColor1};
    padding: 80px 40px;
    margin: -30px 0;
    height: 100%;
`;

const Container3 = styled.div`
    display: flex;
    flex-direction: column;
    background: ${colors.backgroundColor1};
    padding: 50px 30px 80px;
    margin-top: 30px;
    border-radius: 50px 50px 0px 0px;
    align-self: center;
    width: -webkit-fill-available;
    height: 100%;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    @media screen and (max-width: 1088px) {
        padding-bottom: 50px;
    }
`
const CreateDriveButton = styled.div`
    display: flex;
    align-items: center;
    column-gap: 5px;
    align-self: center;
    background-color: ${colors.action100};
    border-radius: 25px;
    color: ${colors.white};
    cursor: pointer;
    padding: 15px 30px;
    margin-top: 10px;
    box-shadow: 0px 3px 10px 0px #00000040;
`;

class SharedDrive extends React.Component {
    state = {
        "drive": null,
        "isLoading": true,
    };
    static defaultProps = {"member": null, "fullPage": false};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.props.tryGetSharedDriveList();
        await Promise.all(this.props.driveList.toJS().map(async drive => {
            if (drive.driveType === 'shared_drive' && drive.users) {
                for (const user of drive.users) {
                    if (this.props.member.id == user.userId) { //RETURN TO === LATER!!!
                        await this.props.setSharedDrive({
                            "driveId": drive.driveId,
                            "driveType": drive.driveType,
                            "files": drive.files,
                            "folders": drive.folders,
                            "name": drive.name,
                            "parentFolderId": drive.parentFolderId
                        });
                    }
                }
            }
        }))
        this.setState({"isLoading": false, });
    }

    createDrive = async () => {
        const driveName = this.props.userName + " and " + this.props.member.firstName + " " + randomNumber();
        await this.props.tryCreateNewSharedDrive(driveName, [this.props.member.id]);
        this.init();
    }

    render() {
        if (this.state.isLoading) {
            return (<LogoLoading />)
        } else if (this.props.fullPage) {
            return (
                <Container3>
                {!this.props.drive.toJS().driveId
                    ?
                    <>
                        <CreateDrive>
                            <FontHeader21>Create a secure shared document vault between you and {this.props.member.firstName + " " + this.props.member.lastName}!</FontHeader21>
                        </CreateDrive>
                        <CreateDriveButton onClick={this.createDrive}><PlusOutlined /><FontHeader16>{' Create Drive'}</FontHeader16></CreateDriveButton>
                    </>
                    :
                        <AllSharedVaultList showBackButton={false} showIconButtons={true} consumer={true}/>
                }
                </Container3>
            )
        } else if (!this.props.drive.toJS().driveId) {
            return (
                <Container2>
                    <CreateDrive>
                        <FontHeader21>Create a secure shared document vault between you and {this.props.member.firstName + " " + this.props.member.lastName}!</FontHeader21>
                    </CreateDrive>
                    <CreateDriveButton onClick={this.createDrive}><PlusOutlined /><FontHeader16>{' Create Drive'}</FontHeader16></CreateDriveButton>
                </Container2>
            )
        } else {
            return (
                <Container>
                    <AllSharedVaultList showBackButton={false} showIconButtons={true}/>
                </Container>
            )
        }
    }

}

const mapStateToProps = state => ({
    driveList: state.common.fileVault.get("sharedDriveList"),
    drive: state.common.fileVault.get("sharedDrive"),
    userName: state.common.user.get("first"),
});

const mapDispatchToProps = dispatch => ({
    tryCreateNewSharedDrive: (name, users) => dispatch(tryCreateNewSharedDrive(name, users)),
    tryGetSharedDriveList: () => dispatch(tryGetSharedDriveList()),
    setSharedDrive: (sharedDrive) => dispatch(setSharedDrive(sharedDrive)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SharedDrive);
