import moment from "moment/moment";
import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setCourseOneChanged} from "../store/courses";

/* Static */
import { devCompleteTask, devConsumerCourseOneUnassigned, devArticleWithCoach, devArticleWithoutCoach } from "../static/devdata";

export const tryGetCourseOne = (courseId) => {
    return async dispatch => {
        return axiosReq('apiv1/consumer/courses/details/' + courseId, "GET", {}, devConsumerCourseOneUnassigned); // devArticleWithCoach
    }
};

export const tryAnswerTask = (id, answers, isCompleted, lastPageCompleted) => {
    return async dispatch => {
        const course = await axiosReq('apiv1/consumer/courses/answer-task', "POST", {"taskAssignmentId": id, "answers": answers, "isCompleted": isCompleted, "lastPageCompleted": lastPageCompleted}, devCompleteTask);
        dispatch(setCourseOneChanged(moment()));
        return course;
    }
};

export const tryPostAssignCourse = (courseId, userId) => {
    return async dispatch => {
        return await axiosReq('apiv1/coach/courses/consumers/assign-course', "POST", {"courseId": courseId, "assignedToUserId": userId}, {});
    }
};

export const tryPostUnassignCourse = (courseAssignmentId) => {
    return async dispatch => {
        await axiosReq('apiv1/coach/courses/consumers/unassign-course', "POST", {"courseAssignmentId": courseAssignmentId}, {});
    }
};
