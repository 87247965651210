import React from 'react';
import ReactQuill from "react-quill";
import {v4 as uuid4} from "uuid";
import styled, { ThemeConsumer } from "styled-components";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

/* Components */
import {colors} from "../../../common/components/colors";

export const Body = styled.div`
    background-color: ${colors.white};
    margin-bottom: 10px;
    & > div {
        border: 1px solid ${colors.white} !important;
    }
    .ql-editor {
        padding: 0px !important;
        font-family: ${env.STYLES.FONTS.body.fontFamily};
        font-size: 18px;
        line-height: 27px;
    }
    .ql-toolbar {
        border-top: 0px solid transparent !important;
        border-left: 0px solid transparent !important;
        border-right: 0px solid transparent !important;
        border-bottom: 1px solid ${colors.border100} !important;
    }
    .ql-container {
        margin-top: 10px;
        border: 0px solid transparent !important;
    }
    div a {
        color: ${colors.action100};
    }
`;

const ElementBody = ({text, style}) => (
    <Body style={style}>
        <ReactQuill
            id={uuid4()}
            readOnly={true}
            theme={null}
            value={text}
            bounds={'.app'}
        />
    </Body>
);

export default ElementBody;