import { Map, fromJS } from 'immutable';

/* Immutable */

const IFRAME_AUTH__LOGGING_IN = 'IFRAME_AUTH__LOGGING_IN';
const IFRAME_AUTH__LOGIN_ERROR = 'IFRAME_AUTH__LOGIN_ERROR';
const IFRAME_AUTH__STORE_RESET = 'IFRAME_AUTH__STORE_RESET';

/* Actions */

export const setIframeAuthLoggingIn = (status) => ({"type": IFRAME_AUTH__LOGGING_IN, 'status': status});
export const setIframeAuthLoginError = (error) => ({"type": IFRAME_AUTH__LOGIN_ERROR, 'error': error});
export const setIframeAuthStoreReset = () => ({"type": IFRAME_AUTH__STORE_RESET});

/* Initial State */

const initialState = Map({
    iframeAuthLoggingIn: false,
    iframeAuthLoginError: null,
});

/* Reducer */

const iframeAuthReducer = (state=initialState, action) => {
    switch (action.type) {
        case IFRAME_AUTH__LOGGING_IN:
            return state.merge({'iframeAuthLoggingIn': action.status});
        case IFRAME_AUTH__LOGIN_ERROR:
            return state.merge({'iframeAuthLoginError': action.error});
        case IFRAME_AUTH__STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default iframeAuthReducer;
