import { Map, List, fromJS } from 'immutable';

/* Immutable */

const IFRAME_SMS_BY_TOKEN_ACTIVITY = 'IFRAME_SMS_BY_TOKEN_ACTIVITY';
const IFRAME_SMS_BY_TOKEN_ADD_ONE = 'IFRAME_SMS_BY_TOKEN_ADD_ONE';
const IFRAME_SMS_BY_TOKEN_DOWNLOAD_PENDING = 'IFRAME_SMS_BY_TOKEN_DOWNLOAD_PENDING';
const IFRAME_SMS_BY_TOKEN_DOWNLOAD_COMPLETE = 'IFRAME_SMS_BY_TOKEN_DOWNLOAD_COMPLETE';
const IFRAME_SMS_BY_TOKEN_SEND_PENDING = 'IFRAME_SMS_BY_TOKEN_SEND_PENDING';
const IFRAME_SMS_BY_TOKEN_SEND_COMPLETE = 'IFRAME_SMS_BY_TOKEN_SEND_COMPLETE';
const IFRAME_SMS_BY_TOKEN_STORE_RESET = 'IFRAME_SMS_BY_TOKEN_STORE_RESET';

/* Actions */

export const setMessagesByTokenActivity = (messages) => ({'type': IFRAME_SMS_BY_TOKEN_ACTIVITY, 'messages': messages});
export const setMessagesByTokenAddOne = (message) => ({'type': IFRAME_SMS_BY_TOKEN_ADD_ONE, 'message': message});
export const setMessagesByTokenDownloadPending = () => ({'type': IFRAME_SMS_BY_TOKEN_DOWNLOAD_PENDING});
export const setMessagesByTokenDownloadComplete = () => ({'type': IFRAME_SMS_BY_TOKEN_DOWNLOAD_COMPLETE});
export const setMessagesByTokenSendPending = () => ({'type': IFRAME_SMS_BY_TOKEN_SEND_PENDING});
export const setMessagesByTokenSendComplete = () => ({'type': IFRAME_SMS_BY_TOKEN_SEND_COMPLETE});
export const setMessagesByTokenReset = () => ({'type': IFRAME_SMS_BY_TOKEN_STORE_RESET});

/* Initial State */

const initialState = Map({
    messageThreadId: null,
    messagesThisUserId: null,
    messagesConsumerName: null,
    messagesCoachName: null,
    messagesActivity: List(),
    messagesThisUserPlatform: null,
    messagesDownloading: false,
    messagesSending: false,
});

/* Reducer */

const messagesReducerByToken = (state=initialState, action) => {
    switch (action.type) {
        case IFRAME_SMS_BY_TOKEN_ACTIVITY:
            return state.merge({
                'messagesActivity': fromJS(action.messages.messages),
                'messageThreadId': action.messages.messageThreadId,
                'messagesThisUserId': action.messages.userId,
                'messagesThisUserPlatform': action.messages.userPlatform,
                'messagesConsumerName': action.messages.consumerName,
                'messagesCoachName': action.messages.coachName,
            });
        case IFRAME_SMS_BY_TOKEN_ADD_ONE:
            return state.update('messagesActivity', List(), (msgs) => msgs.push(Map(action.message)));
        case IFRAME_SMS_BY_TOKEN_DOWNLOAD_PENDING:
            return state.merge({'messagesDownloading': true});
        case IFRAME_SMS_BY_TOKEN_DOWNLOAD_COMPLETE:
            return state.merge({'messagesDownloading': false});
        case IFRAME_SMS_BY_TOKEN_SEND_PENDING:
            return state.merge({'messagesSending': true});
        case IFRAME_SMS_BY_TOKEN_SEND_COMPLETE:
            return state.merge({'messagesSending': false});
        case IFRAME_SMS_BY_TOKEN_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default messagesReducerByToken;
