import React from 'react';

/* Containers */
import NavSide from './navside';
import NavTop from './navtop';
import styled from "styled-components";


const FirmNav = () => (
    <>
        <NavSide />
        <NavTop />
    </>
)

export default FirmNav;