import {axiosReq} from "../../../common/utils/axios";

export const trySignUpTestExists = (email, phone) => {
    return async dispatch => {
        const signUp = await axiosReq('apiv1/consumer/exists', "POST", {"email": email, "phone": phone}, {});
        return(signUp.alreadyRegistered);
    }
};

// export const tryRegisterWmc = (companyName, webDomain, firstName, lastName, email) => {
//     return async dispatch => {
//         let body = {"companyName": companyName, "webDomain": webDomain, "contactFirstName": firstName, "contactLastName": lastName, "contactEmail": email}
//         return axiosReq('apiv1/public/wmc/register', "POST", body, {});
//     }
// };

// export const tryRegisterAdvisor = (firstName, lastName, email, phone, timeZone, password, wmcEmployeeId) => {
//     return async dispatch => {
//         let body = {'firstName': firstName, 'lastName': lastName, 'email': email, 'phoneNumber': phone, 'timeZone': timeZone, 'password': password, "isExclusive": false};
//         if (wmcEmployeeId !== "" && wmcEmployeeId !== null) {
//             body = {...body, 'wmcEmployeeId': wmcEmployeeId};
//         }
//         return axiosReq('apiv1/wmc/advisor-signup', "POST", body, {});
//     }
// };

export const tryRegisterAdvisorAndWMC = (firstName, lastName, email, phone, timeZone, password, isReferredByPartner, wmcEmployeeId, partnerPrepaidAdvisorSessions, partnerPrepaidTrainingSessions) => {
    return async dispatch => {
        let body = {
            // wmcContactFirstName: firstName,
            // wmcContactLastName: lastName,
            // wmcContactEmail: email,
            // wmcCompanyName: email.split("@")[1],
            // wmcWebDomain: email.split("@")[1],
            advisorFirstName: firstName,
            advisorLastName: lastName,
            advisorEmail: email,
            advisorPassword: password,
            advisorTimeZone: timeZone,
            isReferredByPartner: isReferredByPartner,
            wmcEmployeeId: wmcEmployeeId,
            partnerPrepaidAdvisorSessions: partnerPrepaidAdvisorSessions,
            partnerPrepaidTrainingSessions: partnerPrepaidTrainingSessions
        };
        return axiosReq('apiv1/public/wmc-advisor/register', "POST", body, {});
    }
};

/* ADD APPOINTMENTS */

// export const tryPostAssignCoachWillowSessions = (userId, sessions) => {
//     return async dispatch => {
//         return axiosReq('apiv1/coach/clients/'+userId+'/assign-willow-sessions', "POST", {"willowSessionAmount": sessions}, {});
//     }
// };
//
// export const tryPostAssignCoachTrainingSessions = (userId, sessions) => {
//     return async dispatch => {
//         return axiosReq('apiv1/coach/clients/'+userId+'/assign-training-sessions', "POST", {"trainingSessionAmount": sessions}, {});
//     }
// };
