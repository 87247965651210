import React from "react";
import styled from "styled-components";
import {Link, withRouter} from "react-router-dom";
import {connect} from 'react-redux';

/* Components */
import {FontBody24, FontSubtitle40} from "../../../common/components/fonts";
import { ButtonTertiary, ButtonPrimary } from "../../../common/components/buttons";
import {colors} from "../../../common/components/colors";

/* Containers */
import MemberOnePageHeaderNav from "./membernav";

const CoachPreview = styled.div`
  margin: 40px 0 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 60px;
`;
const IntroWithNav = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
`;
const Intro = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 60px;
  justify-content: space-between;
`;
const AddAppointmentsButton = styled(Link)`
  margin-top: 30px;
  width: fit-content;
  & div {
    font-size: 20px;
  }
`;
const AddAppointmentsButtonA = styled.div`
  margin-top: 30px;
  width: fit-content;
  & div {
    font-size: 20px;
  }
`;
const CoachName = styled(FontSubtitle40)`
  margin: 30px 0 10px 0;
  word-break: break-word;
`;
const CoachSubtitle = styled(FontBody24)`
  margin-bottom: 5px;
`;
const Photo = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 200px;
  border: 1px solid ${colors.border100};
`;
const Spacer = styled.div`
    margin-top: 50px;
`;
const Spacer90 = styled.div`
    margin-top: 90px;
`;
const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 30px;
`;

class MemberOnePageHeader extends React.Component {
    static defaultProps = {
        profile: {
            id: null,
            photo: "",
            firstName: "",
            lastName: ""
        },
        memberCategory: null,
        sessionsSchedulable: true,
        hasNoSessionProducts: true,
        hasNoUpcomingTrialCall: true,
        isNew: false
    };


    checkBuySessions = () => {
        if(this.props.sessionsSchedulable || (this.props.hasNoSessionProducts && this.props.hasNoUpcomingTrialCall)) {
            this.props.history.push("/my-team/"+this.props.profile.id+"/appointment")
        } else {
            window.location.href = 'https://pay.trustwillow.com/b/8wM15u7Iy4nD0Lu8wR?client_reference_id='+this.props.userId
        }
    }

    render() {
        return(
            <>
                <CoachPreview>
                    <Photo src={this.props.profile.photo} alt={""} />
                    <IntroWithNav>
                        <Intro>
                            <div>
                                <CoachName spaced={true}>{this.props.profile.firstName.toUpperCase()} {this.props.profile.lastName.toUpperCase()}</CoachName>
                                <CoachSubtitle>{this.props.memberCategory}</CoachSubtitle>
                            </div>
                            <Buttons>
                                <AddAppointmentsButtonA onClick={this.checkBuySessions}>
                                    <ButtonPrimary canSubmit={false} label={"Book appointment"}/>
                                </AddAppointmentsButtonA>
                                {!this.props.isNew &&
                                    <AddAppointmentsButton to={"/my-team/"+this.props.profile.id+"/messages"}>
                                        <ButtonTertiary canSubmit={false} label={"Message"}/>
                                    </AddAppointmentsButton>
                                }
                            </Buttons>
                        </Intro>
                        {!this.props.isNew && <Spacer90><MemberOnePageHeaderNav coachId={this.props.profile.id} /></Spacer90>}
                    </IntroWithNav>
                </CoachPreview>
                {this.props.isNew && <Spacer />}
            </>
        )
    }
}

const mapStateToProps = state => ({
    userId: state.common.user.get("userId"),
});

export default connect(mapStateToProps, null)(withRouter(MemberOnePageHeader));
