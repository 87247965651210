import { combineReducers } from 'redux';

/* Reducers */
import expertProfileReducer from './store/expertprofile';
import profileItemsReducer from './store/profileitems';

const profilePageReducer = combineReducers({
    expertProfile: expertProfileReducer,
    profileItems: profileItemsReducer
});

export default profilePageReducer;
