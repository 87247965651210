import React from 'react';
import styled from 'styled-components';
import {connect} from "react-redux";

import {colors} from '../../common/components/colors';
import {FontHeader16} from '../../common/components/fonts';
import {Message} from "../../common/components/messages";
import {LogoLoading} from "../../common/components/loading";

import IncomeStatement from '../../common/containers/mymoney/containers/moneyspending';
import BalanceSheet from '../../common/containers/mymoney/containers/moneysnapshot';
import SavingsGoals from '../../common/containers/mymoney/containers/moneysaving';

const Bar = styled.div`
    width: 100%;
    margin-bottom: 40px;
    background-color: ${colors.border100};
`;
const Button = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 10px 25px;
    ${props => props.active === true} {color: ${colors.primary70};}
    ${props => props.active === false} {color: ${colors.action100};}
    margin-right: 20px;
    cursor: pointer;
`;

class Finances extends React.Component {
    state = {"page": "incomeStatement", "relationship": null, "isLoading": true};

    componentDidMount() {
        return this.update();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.relationship !== this.props.relationship) {
            return this.update();
        }
    }

    update = async () => {
        this.setState({"relationship": this.props.relationship, "isLoading": false})
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <>
                    <Bar>
                        <Button active={this.state.page === "incomeStatement"} onClick={() => this.setState({"page": "incomeStatement"})}><FontHeader16>Monthly Spending Plan</FontHeader16></Button>
                        <Button active={this.state.page === "balanceSheet"} onClick={() => this.setState({"page": "balanceSheet"})}><FontHeader16>My Money Snapshot</FontHeader16></Button>
                        <Button active={this.state.page === "savingsGoals"} onClick={() => this.setState({"page": "savingsGoals"})}><FontHeader16>What I'm Saving For</FontHeader16></Button>
                    </Bar>
                    {this.state.relationship === "Contact"
                        ? <Message text={"Change relationship to prospect or client to start tracking activity, finances, and add coaching"} />
                        : <div>
                            {this.state.page === "incomeStatement" && <IncomeStatement />}
                            {this.state.page === "balanceSheet" && <BalanceSheet />}
                            {this.state.page === "savingsGoals" && <SavingsGoals />}
                        </div>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    relationship: state.enterprise.clientsDetail.get("client").get("prospectStatus")
});
export default connect(mapStateToProps, null)(Finances);