import React from 'react';
import {connect} from "react-redux";
import {List} from 'immutable';
import {v4 as uuid4} from "uuid";
import moment from 'moment/moment';

/* Components */
import {FontBody16, FontBody12, FontHeader16, FontTitle18} from '../../../common/components/fonts';
import {ChevronLeft} from "react-feather";
import {ButtonPrimary, ButtonInactive} from '../../../common/components/buttons';
import {Back, BackSection, Background, ConfirmBtnFitLeft, MemberProfileCard, HistorySection, BodyEmpty, ToSection, ComposeArea, SendBtn, TextArea, InlineBlock, MessageBody16, MessageContainer, MessageFrom, Point, BoxShadow, Time, TextAreaContainer} from '../components/smscompose';
import {LogoLoading} from "../../../common/components/loading";

/* Containers */
import PhonePrompt from './phoneprompt.js';

/* Middleware */
import {tryGetMessages, tryPostMessage, tryGetSettingsPersonalInfo} from "../middleware/messages";

/* Utils */
import {disableBodyScrolling, enableBodyScrolling} from "../../../common/utils/scrolling";

class SMSById extends React.Component {
    static defaultProps = {
        onClose: () => {},
        showClose: true,
        recipientId: null,
        recipientFirstName: "",
        recipientLastName: "",
        controlScroll: true,
        from: "coach",
        to: "consumer",
        viewInBar: true
    };

    state = {
        "body": '',
        "history": List(),
        "textareaLines": 1,
        "textareaHeight": 43,
        "elementId": uuid4(),
        "isLoading": true,
        'showPhonePrompt': false,
        "personalInfo": {
            "first": '',
            "last": '',
            "phone": '',
            "email": '',
            "companyName": '',
            "timeZone": ''
        },
        "phoneSaved": false,
    };

    intervalID = 0;

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        if(this.props.controlScroll) {
            enableBodyScrolling();
        }
        clearInterval(this.intervalID);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.phoneSaved !== this.state.phoneSaved && this.state.phoneSaved) {
            this.setState({"showPhonePrompt": false})
        }
    }

    init = async () => {
        if(this.props.controlScroll) {
            disableBodyScrolling();
        }
        await this.setState({
            "recipientId": this.props.recipientId,
            "recipientFirstName": this.props.recipientFirstName,
            "recipientLastName": this.props.recipientLastName,
        });
        const personalInfo = await this.props.tryGetSettingsPersonalInfo()
        await this.setState({
            "personalInfo": personalInfo,
            "showPhonePrompt" : (personalInfo.phone === '' || personalInfo.phone === null || personalInfo.phone === "None")
        })
        await this.filter();
        this.intervalID = setInterval(() => {this.filter();}, 1000 * 60);
    };

    filter = async () => {
        const history = await this.props.tryGetMessages(this.props.from, this.props.to, this.state.recipientId);
        const historyAdj = history.map(h => {return({...h, showTime: false})});
        this.setState({
            "history": historyAdj,
            "isLoading": false,
        });
        const messageHistoryArea = document.getElementById("messageHistoryArea");
        messageHistoryArea.scrollTop = messageHistoryArea.scrollHeight - messageHistoryArea.clientHeight;
    };

    handleChangeBody = async e => {
        await this.setState({
            "body": e.target.value,
            "textareaLines": Math.min(4, e.target.value.length === 0 ? 1 : Math.ceil(e.target.value.length / 38)),
            "textareaHeight": parseInt(getComputedStyle(document.getElementById('message-editor')).height)
        });
    };

    handleSendSMS = async () => {
        let addMessage;
        addMessage = await this.props.tryPostMessage(this.props.from, this.props.to, "sms", this.state.body, "", this.state.recipientId);
        const historyAdj = this.state.history;
        historyAdj.push({...addMessage, showTime: false});
        await this.setState({body: '', textareaLines: 1, textareaHeight: 43, history: historyAdj});
        const messageHistoryArea = document.getElementById("messageHistoryArea");
        messageHistoryArea.scrollTop = messageHistoryArea.scrollHeight - messageHistoryArea.clientHeight;
    };

    handleShowTime = async (message) => {
        this.setState({
            "history": this.state.history.map(h => {
                if(message.id === h.id) {
                    return({...h, showTime: !h.showTime});
                } else {
                    return h
                }
            })
        })
    };

    handleUpdatePhone = async() => {
        await this.setState({"showPhonePrompt": false, "phoneSaved": true})
    }

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading/>)
        } else {
            return (
                <>
                    <MemberProfileCard id={this.state.elementId} hasFooter={false} viewInBar={this.props.viewInBar}>
                        {this.props.showClose &&
                            <BackSection>
                                <Back onClick={this.props.onClose}>
                                    <ChevronLeft/>
                                </Back>
                            </BackSection>
                        }
                        <ToSection viewInBar={this.props.viewInBar}>
                            <InlineBlock><FontHeader16>To:</FontHeader16></InlineBlock>&nbsp;&nbsp;&nbsp;
                            <InlineBlock><FontBody16>{this.state.recipientFirstName+" "+this.state.recipientLastName}</FontBody16></InlineBlock>
                        </ToSection>
                        <HistorySection textareaHeight={this.state.textareaHeight} id={"messageHistoryArea"} viewInBar={this.props.viewInBar}>
                            {this.state.showPhonePrompt 
                                ?
                                <PhonePrompt 
                                    handleUpdatePhone={this.handleUpdatePhone}
                                    personalInfo={this.state.personalInfo}
                                />
                                :
                                <>
                                {this.state.history.size === 0 &&
                                    <BodyEmpty>
                                        <FontTitle18>No messages yet</FontTitle18>
                                    </BodyEmpty>
                                }
                                {this.state.history.map((m, index) => (
                                    <MessageFrom key={m.id} isFirst={index === 0} isFromMe={this.props.userId === m.fromUserId}>
                                        <MessageContainer onClick={() => {return this.handleShowTime(m)}} isFromMe={this.props.userId === m.fromUserId} >
                                            <MessageBody16>{m.content}</MessageBody16>
                                            <Point isFromMe={this.props.userId === m.fromUserId} />
                                        </MessageContainer>
                                        <Time show={m.showTime} id={"messageTimeStamp"+index} isFromMe={this.props.userId === m.fromUserId}>
                                            <FontBody12>{moment(m.formattedDatetime).format('lll').toString()}</FontBody12>
                                        </Time>
                                    </MessageFrom>
                                ))}
                                </>
                            }

                        </HistorySection>
                        <ComposeArea id={'message-editor'}>
                            <BoxShadow viewInBar={this.props.viewInBar}>
                                <TextAreaContainer>
                                    <TextArea
                                        value={this.state.body}
                                        onChange={this.handleChangeBody}
                                        placeholder={"Message..."}
                                        rows={this.state.textareaLines}
                                    />
                                </TextAreaContainer>
                                {(this.state.body !== '' && !this.state.showPhonePrompt)
                                    ? <SendBtn>
                                        <ConfirmBtnFitLeft onClick={this.handleSendSMS}>
                                            <ButtonPrimary label={"Send"} canSubmit={false} />
                                        </ConfirmBtnFitLeft>
                                    </SendBtn>
                                    : <SendBtn>
                                        <ButtonInactive label={"Send"} canSubmit={false} />
                                    </SendBtn>
                                }
                            </BoxShadow>
                        </ComposeArea>
                    </MemberProfileCard>
                    <Background onClick={this.props.onClose} viewInBar={this.props.viewInBar}/>
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    userId: state.common.user.get("userId"),
});

const mapDispatchToProps = dispatch => ({
    tryGetMessages: (from, to, coachId) => dispatch(tryGetMessages(from, to, coachId)),
    tryPostMessage: (from, to, mediaType, content, subject, coachId) => dispatch(tryPostMessage(from, to, mediaType, content, subject, coachId)),
    tryGetSettingsPersonalInfo: () => dispatch(tryGetSettingsPersonalInfo())
});

export default connect(mapStateToProps, mapDispatchToProps)(SMSById);
