import React from "react";
import styled from 'styled-components';

/* Containers */
import NavPrimaryTop from "./primarytop";
import NavPrimaryMobile from "./primarymobile";
import NavPrimarySideSettings from "./primarysidesettings";

/* Components */
import {FontTitle30} from "../../../common/components/fonts";
import {colors} from '../../../common/components/colors';

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

const SettingsTop = styled.div`
    padding: 20px 0px;
    text-align: center;
    background-color: ${colors.secondary100};
    color: ${colors.white};
`;

const NavPrimaryAllSettings = () => (
    <>
        <NavPrimaryTop data={env.ENTERPRISE.NAV} />
        <NavPrimaryMobile data={env.ENTERPRISE.NAV} showSettings={true} />
        <SettingsTop><FontTitle30>Settings</FontTitle30></SettingsTop>
        <NavPrimarySideSettings data={env.ENTERPRISE.NAV} />
    </>
);

export default NavPrimaryAllSettings;