import React from 'react';
import {connect} from 'react-redux';
import styled from "styled-components";
import {Link} from "react-router-dom";

/* Middleware */
import {tryGetWmcsForCoach, tryGetSupportForWmc} from "../middleware/coaching";

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {PageContent, PageTabs} from "../components/teamone";
import {Message} from "../../../common/components/messages";

/* Containers */
import SupportTeamList from "./supportteamlist";

const InlineBtn = styled.div`
    width: fit-content;
    margin: -25px auto 0px auto;
`;

class Team extends React.Component {
    state={"isLoading": true, "allSupport": []};

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.allSupport !== this.props.allSupport) {
            return this.update();
        }
    }

    init = async () => {
        if(!this.props.allSupportFetched) {
            await this.props.tryGetSupportForWmc(this.props.wmc.get("id"))
        }
        this.update();
    };

    update = async () => {
        this.setState({"allSupport": this.props.allSupport, "isLoading": false});
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
                return(
                    <PageTabs>
                        <PageContent>
                            {this.state.allSupport.size === 0
                                ? <>
                                    <Message text={"No support members yet"}/>
                                </>
                                : <SupportTeamList />
                            }
                        </PageContent>
                    </PageTabs>
                )
        }
    }

}

const mapStateToProps = state => ({
    allSupport: state.enterprise.partners.partners.get("supportAssignedToWmc"),
    allSupportFetched: state.enterprise.partners.partners.get("supportAssignedToWmcFetched"),
    wmc: state.enterprise.partners.partners.get("wmcSelected")
});

const mapDispatchToProps = dispatch => ({
    tryGetSupportForWmc: (wmcId) => dispatch(tryGetSupportForWmc(wmcId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Team);
