import { Map, fromJS } from 'immutable';

/* Immutable */

const SHOP_EXPERT_PROFILE = 'SHOP_EXPERT_PROFILE';
const SHOP_EXPERT_PROFILE_EDIT = 'SHOP_EXPERT_PROFILE_EDIT';
const SHOP_EXPERT_PROFILE_STATUS_GET = 'SHOP_EXPERT_PROFILE_STATUS_GET';
const SHOP_EXPERT_PROFILE_STATUS_PUT = 'SHOP_EXPERT_PROFILE_STATUS_PUT';
const SHOP_EXPERT_PROFILE_STORE_RESET = 'SHOP_EXPERT_PROFILE_STORE_RESET';

/* Actions */

export const setShopExpertProfile = (profile) => ({'type': SHOP_EXPERT_PROFILE, 'profile': profile});
export const setShopExpertProfileEdit = (edit) => ({'type': SHOP_EXPERT_PROFILE_EDIT, 'edit': edit});
export const setShopExpertProfileGet = (status) => ({'type': SHOP_EXPERT_PROFILE_STATUS_GET, 'status': status});
export const setShopExpertProfilePut = (status) => ({'type': SHOP_EXPERT_PROFILE_STATUS_PUT, 'status': status});
export const setShopExpertProfileStoreReset = () => ({'type': SHOP_EXPERT_PROFILE_STORE_RESET});

/* Initial State */

const initialState = Map({
    expertProfile: Map({
        introVideoUrl: '',
        facebookUrl: '',
        linkedinUrl: '',
        twitterUrl: '',
        youtubeUrl: '',
        id: '',
        photo: '',
        address1: '',
        address2: '',
        bio: '',
        whyBecameCoach: '',
        companyName: '',
        endorsement1: '',
        endorsement2: '',
        endorsement3: '',
        freeTime: '',
        crd: '',
        city: '',
        state: '',
        zipCode: '',
        error: '',
        bookingAvailability: '',
        firstName: "",
        lastName: "",
        coachPhilosophy: '',
        highlights: '',
        coachingCertification: null,
        coachingCertificationYear: null,
        screeningCallLength: 30,
        defaultCallLength: 30,
        advancedDegree: null,
        advancedDegreeCity: null,
        advancedDegreeInstitution: null,
        advancedDegreeState: null,
        advancedDegreeYear: null,
        otherEducation: null,
        expertCategories: [],
        storeActive: false,
        storeShowScheduler: false,
        storeShowEmailer: false,
        storeTitle: "",
        storeDescription: "",
        storePhoto: null,
        storeHandle: "",
        publicHandle: "",
        profileActive: false,
        profileShowScheduler: false,
        profileShowEmailer: false,
        website: "",
        schedulerActive: false,
        schedulerHandle: ""
    }),
    expertProfileReading: false,
    expertProfileUpdating: false,
    expertProfileFetched: false
});

/* Reducer */

const shopExpertProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOP_EXPERT_PROFILE:
            return state.merge({
                'expertProfile': fromJS(action.profile),
                'expertProfileFetched': true
            });
        case SHOP_EXPERT_PROFILE_EDIT:
            return state.merge({'expertProfile': fromJS({
                    ...state.get("expertProfile").toJS(),
                    ...action.edit
                })});
        case SHOP_EXPERT_PROFILE_STATUS_GET:
            return state.merge({'expertProfileReading': action.status});
        case SHOP_EXPERT_PROFILE_STATUS_PUT:
            return state.merge({'expertProfileUpdating': action.status});
        case SHOP_EXPERT_PROFILE_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default shopExpertProfileReducer;
