import React, { useState } from 'react';
import styled from "styled-components";

const AccordionItem = (props) => {
    return <AccordionLi>
        <AccordionButton onClick={props.onToggle} isOpen={props.active}>
            {props.title}
            <AccordionControlButtonSpan>{props.active ? "-" : "+"}</AccordionControlButtonSpan>
        </AccordionButton>
        <AccordionItemContentWrapper isOpen={props.active}>
            <AccordionItemContent>
                {props.content}
            </AccordionItemContent>
        </AccordionItemContentWrapper>
    </AccordionLi>

}

export const Accordion = (props) => {
    const [clicked, setClicked] = useState(null);

    const handleToggle = (index) => {
        if (clicked === index) {
            return setClicked("A");
        }
        setClicked(index);
    };

    return <AccordionUl>
        {props.items.map((item, index) => (
            <AccordionItem
                key={index}
                title={item.title}
                content={item.content}
                onToggle={() => handleToggle(index)}
                active={clicked === index}
            />
        ))}
    </AccordionUl>
}

const AccordionUl = styled.ul`
    list-style: none;
    padding-inline-start: 0px;
`

const AccordionLi = styled.li``

const AccordionButton = styled.button`
    font-size: 14px;
    font-weight: 700;
    background-color: #E9E9E9;
    color: #394950;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 15px 8px;
    cursor: pointer;
    border: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`

const AccordionControlButtonSpan = styled.span`font-size: 16px;`

const AccordionItemContentWrapper = styled.div`
    overflow: hidden;
    ${props => props.isOpen} {
        height: 0;
    }
    ${props => !props.isOpen} {
        height: auto;
    }
`
const AccordionItemContent = styled.div`
    background-color: #f7f7f7;
    padding: 20px;
`
