import {axiosReq} from "../../../common/utils/axios";

/* Store */
import {setSettingsEnterpriseTermsReading, setSettingsEnterpriseTermsUpdating, setSettingsEnterpriseTerms} from "../store/settings";

/* Static */
import {devSettingsEnterpriseTermsData, devSettingsEnterpriseTermsUpdateData} from '../../static/devdata';

export const tryGetSettingsEnterpriseTerms = () => {
    return async dispatch => {
        dispatch(setSettingsEnterpriseTermsReading(true));
        const terms = await axiosReq('apiv1/coach/settings/terms', "GET", {}, devSettingsEnterpriseTermsData);
        dispatch(setSettingsEnterpriseTerms(terms));
        return dispatch(setSettingsEnterpriseTermsReading(false));
    }
};

export const tryUpdateSettingsEnterpriseTerms = (data) => {
    return async (dispatch, getState) => {
        dispatch(setSettingsEnterpriseTermsUpdating(true));
        const terms = await axiosReq('apiv1/coach/settings/terms', "PUT", {maxClients: data.maxClients}, devSettingsEnterpriseTermsUpdateData(data));
        if(terms.hasOwnProperty("error")) {
            dispatch(setSettingsEnterpriseTerms({...getState().enterprise.settingsPersonal.get("settingsPersonalInfo").toJS(), "error": terms.error}));
        } else {
            dispatch(setSettingsEnterpriseTerms({...data, "error": ""}));
        }
        return dispatch(setSettingsEnterpriseTermsUpdating(false));
    }
};








