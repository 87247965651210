import React from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';

/* Components */
import {FontTitle18} from '../../../common/components/fonts';

/* Containers */
import ListingType from './listingtype';
import ListingDetails from './listingdetails';
import ListingPrice from './listingprice';

/* Middleware */
import {tryCreateListing, tryUpdateListingStatus} from '../middleware/listings';

class CreateListing extends React.Component {
    state = {
        page: "type",
        type: {
            "downloadChecked": false
        },
        details: {},
        price: {},
        isSaving: false,
        isSaved: false,
        error: '',
        isValid: true,
        isChanged: false
    };

    componentDidMount() {}

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    updateListingType = async (data) => {
        await this.setState({"type": data, "page": "details"});
        console.log(this.state)
    };

    updateListingDetails = async (data) => {
        await this.setState({"details": data, "page": "price"});
        console.log(this.state)
    };

    updateListingPrice = async (data) => {
        await this.setState({"price": data});
        console.log(this.state)
    };

    updatePage = async (page) => {this.setState({"page": page})};

    createPlans = async () => {
        let sessionBased = this.state.type.serviceChecked ? {"numberOfSessions": this.state.type.numSessions} : {"numberOfSessions": 0};
        if(this.state.price.freeChecked) {
            return([{
                "description": "",
                "orderIndex": 0,
                "amount": 0,
                ...sessionBased
            }])
        } else if(this.state.price.customPriceChecked) {
            return([{
                "description": this.state.price.description,
                "orderIndex": 0,
                "amount": 0,
                ...sessionBased
            }])
        } else if(this.state.price.onlinePurchaseChecked) {
            return Promise.all(this.state.price.plans.map((p, index) => {
                if(p.upfrontChecked) {
                    return Promise.resolve({
                        "description": "",
                        "orderIndex": index,
                        "amount": typeof p.upfrontPrice === "string" ? (parseInt(p.upfrontPrice.substring(1))*100) : p.upfrontPrice*100,
                        ...sessionBased
                    });
                }
                if(p.scheduledChecked) {
                    return Promise.resolve({
                        "description": "",
                        "orderIndex": index,
                        "amount": typeof p.scheduledMonthlyPrice === "string" ? (parseInt(p.scheduledMonthlyPrice.substring(1))*100) : p.scheduledMonthlyPrice*100,
                        "subscriptionDurationInMonths" : p.scheduledNumMonths,
                        "recurringInterval": "month",
                        ...sessionBased
                    });
                }
                if(p.subscriptionChecked) {
                    return Promise.resolve({
                        "description": "",
                        "orderIndex": index,
                        "amount": typeof p.subscriptionPrice === "string" ? (parseInt(p.subscriptionPrice.substring(1))*100) : p.subscriptionPrice*100,
                        "recurringInterval": p.subscriptionFrequency,
                        ...sessionBased
                    });
                }
            }));
        }
    };

    handleSubmit = async () => {
        if(this.state.isValid) {
            console.log(this.state);
            this.setState({'isSaving': true});
            const data = {
                "title": this.state.details.title,
                "description": this.state.details.description,
                "coverPhotoBase64": this.state.details.photo,
                "termsOfUseUrl": this.state.details.terms,
                "privacyPolicyUrl": this.state.details.privacy,
                "downloadUrl": this.state.type.downloadChecked ? this.state.type.fileURL : "", // this.state.type.downloadChecked ? this.state.type.fileBlob : "",
                "sessionLength": this.state.type.serviceChecked ? this.state.type.lengthSessions : 0,
                "serviceType": (this.state.type.serviceChecked ? "online" : (this.state.type.downloadChecked ? "download" : (this.state.type.serviceOfflineChecked ? "offline" : "tangible"))),
                "productPlans": await this.createPlans()
            };
            console.log(data);
            const newListing = await this.props.tryCreateListing(data);
            const newStatus = {"status": this.state.details.activeChecked ? "active" : "disabled"};
            await this.props.tryUpdateListingStatus(newListing.id, newStatus);
            this.setState({'isSaving': false, 'isSaved': true});
            this.timeout = setTimeout(() => {
                this.setState({'isSaved': false});
                this.props.history.push("/products");
            }, 3000);
        }
    };

    render() {
        if(this.state.isSaved) {
            if(this.state.error === "") {
                return(<FontTitle18>Created!</FontTitle18>)
            } else if(this.state.error !== '') {
                return(<FontTitle18>{this.state.error}</FontTitle18>)
            }
        } else {
            return(
                <>
                    {this.state.page === "type" &&
                        <ListingType
                            back={() => {this.props.history.push("/products")}}
                            next={this.updateListingType}
                            data={this.state.type}
                        />
                    }
                    {this.state.page === "details" &&
                        <ListingDetails
                            back={() => {return this.updatePage("type")}}
                            next={this.updateListingDetails}
                            data={this.state.details}
                        />
                    }
                    {this.state.page === "price" &&
                        <ListingPrice
                            back={() => {return this.updatePage("details")}}
                            save={this.updateListingPrice}
                            next={this.handleSubmit}
                            data={this.state.price}
                            downloadChecked={this.state.type.downloadChecked}
                            createPlan={true}
                        />
                    }
                </>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryCreateListing: (data) => dispatch(tryCreateListing(data)),
    tryUpdateListingStatus: (listingId, data) => dispatch(tryUpdateListingStatus(listingId, data))
});

export default connect(null, mapDispatchToProps)(withRouter(CreateListing));
