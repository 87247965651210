import React from "react";
import styled from "styled-components";

/* Components */
import {colors} from "../../../common/components/colors";

export const SettingsCard = styled.div`
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    background-color: ${colors.white};
    border-radius: 7px;
    border: 1px solid ${colors.primary30};
    padding: 30px;
    color: ${colors.primary100};
    max-width: ${props => props.maxWidth};
    margin: 0px auto 40px auto;
    @media screen and (max-width: 800px) {
        margin: 40px auto 0px auto;
    }
    @media screen and (max-width: 460px) {
        margin: 20px auto 0px auto;
        padding: 30px 20px;
    }
    ${props => props.active !== true} {
        &:hover {
            -webkit-box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
        }
    }
    
`;

export const Bar = styled.div`
    width: 100%;
    height: 1px;
    border-bottom: 1px solid ${colors.secondary30};
`;

export const Text = styled.div`
    margin: 10px 0px;
    
    ${props => props.active !== true} {
        overflow: revert;
        display: revert;
        -webkit-line-clamp: revert;
        -webkit-box-orient: revert;
    }
    ${props => props.active !== false} {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
`;

export const SmallContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    position: relative;
`;

export const HighlightedContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    position: relative;
    cursor: pointer;
    margin-bottom: 35px;
    width: 100%;
`;
export const HighlightedContainerFull = styled.div`
    display: flex;
    justify-content: left;
    position: relative;
    cursor: pointer;
    margin-bottom: 35px;
    background-color: ${colors.border30};
    border-radius: 20px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    align-items: center;
    @media screen and (max-width: 800px) {
        flex-direction: column;
        background-color: white;
        border-radius: 0px;
        padding: 0px;
        margin: 0px auto 35px auto;
        max-width: 450px;
        box-shadow: 0px 0px;
    }
`;
export const TitleBox = styled.div`
    padding: 20px;
    @media screen and (max-width: 460px) {
        padding: 15px 20px;
    }
    position: relative;
    background-color: ${colors.border30};
    border-radius: 20px;
    width: 80%;
    margin: -55px auto 0px auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
`;
export const TitleBox2 = styled.div`
    padding: 30px 40px;
    @media screen and (max-width: 460px) {
        padding: 15px 20px;
    }
    position: relative;
    max-width: calc(400px - 80px);
    width: calc(100% - 80px);
    margin: -1px auto 0px auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
`;
export const TitleBox3 = styled.div`
    padding: 30px 40px;
    @media screen and (max-width: 460px) {
        padding: 15px 20px;
    }
    position: relative;
    width: calc(100% - 80px);
    margin: 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width: 800px) {
        margin: -55px auto 0px auto;
        background-color: ${colors.border30};
        border-radius: 20px;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    }
    
`;

export const ImageHighlighted = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`;
export const ImageHighlightedFull = styled.img`
    height: auto;
    max-height: 275px;
    width: 50%;
    max-width: 450px;
    object-fit: cover;
    @media screen and (max-width: 800px) {
        width: 100%;
    }
`;
export const ImageOne = styled.img`
    height: 90px;
    width: 84px;
    vertical-align: top;
    display: inline-block;
    object-fit: cover;
    object-position: center top;
    border-radius: 6px;
`;
export const Image = styled.img`
    height: 150px;
    width: 100%;
    vertical-align: top;
    display: inline-block;
    margin: 10px 0px;
    object-fit: contain;
`;
export const IconImage = styled.img`
    height: 100%;
    width: 24px;
    vertical-align: top;
    display: inline-block;
    object-fit: contain;
`;

export const Olive = styled.div`
    color: ${colors.secondary100};
`;
export const Description = styled.div`
    display: inline-flex;
    flex-direction: row;
    column-gap: 10px;
`;
export const OneTitle = styled.div`
    display: inline-flex;
    flex-grow: 1;
`;
export const Shorten = styled.div`
    height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;


export const OneContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    cursor: pointer;
    gap: 10px;
    justify-content: left;
    margin: 00px 25px 20px 25px;
`;

export const Icon = styled.div`
    display: inline-block;
    vertical-align: top;
    ${props => props.active !== true} {
        color: ${colors.white};
    }
    ${props => props.active !== false} {
        color: ${colors.action100};
    }
    & svg {
        width: 16px;
        height: 16px;
        margin-top: 3px;
    }
    @media screen and (max-width: 460px) {
        display: none;
    }

`;

export const InlineBlock = styled.div`
    display: inline-block;
    margin-right: 10px;
    margin-top: 10px;
`;

export const SpaceBetween = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const TopBox = styled.div`
    cursor: pointer;
`;

export const OpenLink = styled.div`
    ${props => props.active !== true} {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
    ${props => props.active !== false} {
        cursor: default;
    }
    
`;

export const BackTop = styled.div`
    color: ${colors.secondary70};
    cursor: pointer;
    position: relative;
    z-index: 25;
    text-align: left;
    display: block;
    width: fit-content;
`;

export const LoadingCenter = styled.div`
    margin: 60px auto;
`;

export const BulletArea = styled.div`
    margin: 0px 20px;
`;

export const Bullet = styled.div`
    padding: 10px;
`;

