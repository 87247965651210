import React from "react";
import styled from "styled-components";

/* Components */
import {colors} from "../../../common/components/colors";
import {ButtonInactive, ButtonPrimary} from "../../../common/components/buttons";
import {FontHeader18} from "../../../common/components/fonts";

export const Validation = styled.div`
    width: calc(100% - 80px);
    margin:10px 40px 0px 40px;
`;
export const Test = styled.div`
    width: 50%;
    font-family: GraphicWebRegular;
    font-size: 16px;
    line-height: 22px;
    display: inline-block;
    padding: 5px 0px;
    @media screen and (max-width: 748px) {
        display: block;
    }
    @media screen and (max-width: 460px) {
        width: 100%;
    }
`;
export const Icon = styled.div`
    display: inline-block;
    ${props => props.status === true} {color: ${colors.primary100};}
    ${props => props.status === false} {color: ${colors.primary100};}
`;
export const SaveSection = styled.div`
    border-top: 1px solid ${colors.border100};
    text-align: left;
    padding-top: 20px;
    margin-top: 50px;
`;
export const InlineBlock = styled.div`
    display: inline-block;
`;
const ButtonRight = styled.div`
    margin-right: 10px;
`;

export const SubmitInputWithValidation = ({ isChanged, isValid, isSaving, label }) => {
    if(isChanged && isValid) {
        if(isSaving) {
            return (<ButtonRight><ButtonInactive canSubmit={true} label={"Saving"} /></ButtonRight>)
        } else {
            return (<ButtonRight><ButtonPrimary canSubmit={true} label={label}/></ButtonRight>)
        }
    } else {
        return(<ButtonRight><ButtonInactive canSubmit={true} label={label}/></ButtonRight>)
    }
};

const SubmitButtonSpacer = styled.div`
    margin-top: 30px;
    text-align: right;
`;
const Label = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 4px 0px;
    color: ${props => props.color};
    text-align:left
`;
const Submit = styled.button`
    display: inline-block;
    cursor: pointer;
    background-color: ${props => props.backgroundColor};
    border-radius: 4px;
    border: none;
    padding: 0px 24px;
    margin: 0px;
    vertical-align: top;
    color: ${colors.primary100};
    &:active, &:focus {
        outline: none;
        border: none;
    }
`;

const SubmitInput = ({ iconComponent: IconComponent, backgroundColor, color, label, disabled }) => (
    <SubmitButtonSpacer>
        <Submit backgroundColor={backgroundColor} disabled={disabled} >
            {IconComponent !== null &&
            <Icon><IconComponent /></Icon>
            }
            <Label color={color}><FontHeader18>{label}</FontHeader18></Label>
        </Submit>
    </SubmitButtonSpacer>
);

export const SubmitInputWithValidationRight = ({ isChanged, isValid, isSaving }) => {
    if(isChanged && isValid) {
        if(isSaving) {
            return (<SubmitInput iconComponent={null} backgroundColor={colors.primary30} disabled={true}
                                 color={colors.secondary100} label={"Saving"}/>)
        } else {
            return (<SubmitInput iconComponent={null} backgroundColor={colors.action100} disabled={false}
                                 color={colors.primary100} label={"Update"}/>)
        }
    } else {
        return(<SubmitInput iconComponent={null} backgroundColor={colors.border70} disabled={true}
                            color={colors.primary30} label={"Update"}/>)
    }
};
