import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import styled from 'styled-components';

/* Components */
import {TextInput} from "../../../common/components/inputs";
import {FontBody14} from '../../../common/components/fonts';
import {colors} from "../../../common/components/colors";
import {CloudUploadOutlined} from '@ant-design/icons';

/* Middleware */
import {tryPostImage} from "../../../common/containers/imageupload/middleware/images";

const Actions = styled.div`
    color: ${colors.action100};
    margin-top: -10px;
    & div {
        width: fit-content;
        cursor: pointer;
    }
`;
const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-right: 25px;
`;
const HeroImg = styled.img`
    width: 100%;
    object-fit: cover;
    object-position: 0 0;
    max-width: 600px;
    max-height: 480px;
`;
const PhotoUpload = styled.input`
    width: 130px;
    height: 15px;
    display: none;
`;

class CreateCoursePhoto extends React.Component {
    state = {
        "imgURL": '',
        "imgURLValid": true,
        "imgPreview": false,
        "isLoadingImg": true
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({
            "imgURL": this.props.image.imgURL === null ? "" : this.props.image.imgURL,
            'imgURLValid': (validator.isURL(this.props.image.imgURL) || this.props.image.imgURL === ""),
            "isLoadingImg": false
        });
    };

    handleChangeImgURL = async e => {
        await this.setState({
            'imgURL': e.target.value,
            'imgURLValid': validator.isURL(e.target.value), //(validator.isURL(e.target.value) || e.target.value === ""),
            'imgPreview': false
        });
        await this.props.onValidate(this.state);
    };

    handleChangePreview = async () => {
        this.setState({"imgPreview": !this.state.imgPreview})
    };

    tryChangePhoto = async () => {
        document.getElementById('selectedFile').click()
    };

    handleChangeUploadedImage = async (e) => {
        if (e.target.files[0].size > 2500000) {
            await this.setState({
                'imgURLValid': false,
                'imgPreview': false,
                "imgURL": ""
            });
        } else {
            await this.setState({
                "imgURL": await this.props.tryPostImage(e.target.files[0]),
                'imgURLValid': true,
                'imgPreview': false
            });
            await this.props.onValidate(this.state);
        }
    };

    render() {
        if(this.state.isLoadingImg) {
            return null
        } else {
            return(
                <>
                    <TextInput
                        title={this.props.title}
                        valid={this.state.imgURLValid}
                        warning={"Must be smaller than 2.5MB."}
                        id={"imgURL"}
                        onChange={this.handleChangeImgURL}
                        placeholder={"https://..."}
                        value={this.state.imgURL}
                    />
                    <Actions>
                        <Inline>
                            <FontBody14 onClick={this.tryChangePhoto}><CloudUploadOutlined />&nbsp;Upload</FontBody14>
                            <PhotoUpload id={"selectedFile"} type={"file"} onChange={this.handleChangeUploadedImage} accept={".png,.jpeg,.jpg,.gif"} />
                        </Inline>
                        {(this.state.imgURLValid && this.state.imgURL !== "") &&
                        <Inline><FontBody14 onClick={this.handleChangePreview}>{this.state.imgPreview ? "Hide preview" : "Show preview"}</FontBody14></Inline>
                        }
                    </Actions>
                    {(this.state.imgPreview && this.state.imgURLValid) &&
                    <HeroImg src={this.state.imgURL} alt={""} />
                    }
                </>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryPostImage: (file) => dispatch(tryPostImage(file))
});

export default connect(null, mapDispatchToProps)(CreateCoursePhoto);
