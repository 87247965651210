import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

/* Components */
import FirmDashboardWelcome from '../components/welcome';

/* Containers */
import FirmDashboardTasks from './tasks';
// import FirmDashboardClients from './clients';
// import FirmDashboardCredits from './credits';
import FirmDashboardAppointments from './appointments';
import FirmDashboardTrainings from './trainings';
import FirmDashboardCertification from './certification';
import { setToolTipUpdate } from "../../tooltip/store/tooltip";
import { useDispatch, useSelector } from "react-redux";
// import NPSRecommendTypeForm from "../../../../common/components/npstypeform";
// import SatisfactionTypeForm from "../../../../common/components/sattypeform";

const Desktop = styled.div`
    @media screen and (max-width: 1440px) {
        display: none;
    }
`;

const Mobile = styled.div`
    @media screen and (min-width: 1440px) {
        display: none;
    }
`;


const FlexWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
    flex-wrap: wrap;
`;
const ColumnFlexA = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(60% - 40px);
    gap: 40px;
    @media screen and (max-width: 1440px) {
        width: 100%;
    }
`;
const ColumnFlexB = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
    gap: 40px;
    @media screen and (max-width: 1440px) {
        width: 100%;
    }
`;

export default function FirmDashboardHome() {
    const myRef = useRef(null)
    const dispatch = useDispatch();
    const showToolTip = useSelector(state => state.enterprise.tooltip.get('showToolTip'));
    const toolTipPage = useSelector(state => state.enterprise.tooltip.get('toolTipPage'));

    useEffect(() => {
        if (showToolTip && toolTipPage === 2 && myRef !== null) {
            executeScroll();
        }
        // init();
    }, [toolTipPage]);
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: "smooth", block: "center" })

    const startTooltip = () => {
        dispatch(setToolTipUpdate(true, 2));
    }

    const stopToolTip = () => {
        dispatch(setToolTipUpdate(false, 1));
    }

    return (
        <>
            <Desktop>
                <FlexWrap>
                    <ColumnFlexA className={"leftRow"}>
                        <FirmDashboardWelcome className={"welcome"} />
                        {/*<FirmDashboardTasks />*/}
                        <FirmDashboardCertification ref={myRef} />
                    </ColumnFlexA>
                    <ColumnFlexB className={"rightRow"}>
                        {/*<FirmDashboardCredits />*/}
                        {/*<FirmDashboardClients />*/}
                        <FirmDashboardAppointments />
                        <FirmDashboardTrainings />
                    </ColumnFlexB>
                </FlexWrap>
            </Desktop>
            <Mobile>
                <FlexWrap>
                    <ColumnFlexA className={"leftRow"}>
                        <FirmDashboardWelcome className={"welcome"} />
                        {/*<FirmDashboardTasks />*/}
                        <FirmDashboardCertification ref={myRef} />
                    </ColumnFlexA>
                    <ColumnFlexB className={"rightRow"}>
                        {/*<FirmDashboardCredits />*/}
                        {/*<FirmDashboardClients />*/}
                        <FirmDashboardAppointments />
                        <FirmDashboardTrainings />
                    </ColumnFlexB>
                </FlexWrap>
            </Mobile>
            {/*<NPSRecommendTypeForm />*/}
            {/*<SatisfactionTypeForm />*/}
        </>
    )

}

