import React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

/* Components */
import {FontBody18} from "../../../common/components/fonts";
import {Checkbox} from "../../../common/components/inputs";
import {colors} from "../../../common/components/colors";
import {ButtonPrimary} from "../../../common/components/buttons";
import {images} from "../../../common/components/images";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

/* Middleware */
import {tryPostHelp} from '../middleware/help';

const TextArea = styled.textarea`
    background-color: ${colors.border30};
    margin: 0px auto;
    border-radius: 20px;
    padding: 15px 20px;
    -webkit-box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15);
    resize: none;
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    font-size: 16px;
    line-height: 22px;
    min-height: 230px;
    border: none;
    width: calc(100% - 40px);
    @media screen and (max-width: 460px) {
        
    }
    &:focus {
        outline: 0;
    }
`;
const Background = styled.div`
    position: absolute;
    left: 320px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    z-index: -1;
    width: calc(100% - 320px);
    height: 100%;
    background-position: center top;
    background-image: url(${images.feedbackBackgroundDesktopInverted});
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (max-width: 1088px) {
        left: 0px;
        width: 100%;
        height: 100vh;
    }
    @media screen and (max-width: 748px) {
        background-image: url(${images.feedbackBackgroundMobileInverted});
        left: 0px;
        width: 100%;
        height: 100vh;
    }
`;
const SaveSection = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 0px 10px;
    margin-top: 45px;
`;
const CheckboxRow = styled.div`
    display: inline-flex;
    flex-grow: 1;
    flex-direction: row;
    gap: 10px;
`;
const CheckboxContainer = styled.div`
    display: inline-flex;
    align-items: center;
    min-width: 35px;
    & > label {
        margin-bottom: 0px !important;
        width: 33px;
        height: 33px;
        & > span {
            width: 33px;
            height: 33px;
            border-radius: 4px;
            background-color: ${colors.white};
            border-color: ${colors.secondary70};
        }
    }
    & input:checked ~ span:after {
        top: 8px;
        left: 12px;
    }
`;
const CheckboxLabel = styled.div`
    display: inline-flex;
    align-items: center;
`;
const ButtonSend = styled.div`
    display: inline-flex;
    & > div {
        min-width: 84px;
        margin-left: auto;
        text-align: center;
    }
`;

class HelpContact extends React.Component {

    state = {"message": '', "urgent": false};

    handleChange = e => this.setState({"message": e.target.value});

    handleChangeUrgent = e => this.setState({"urgent": e});

    handleSubmit = async () => {
        if(this.state.message !== '') {
            let urgentText = this.state.urgent ? 'URGENT ' : '';
            await this.props.tryPostHelp(urgentText.concat("MESSAGE: ", this.state.message));
            this.props.history.push("/support/contact/message-sent");
        }
    };

    render() {
        return(
            <>
                <Background />
                <div>
                    <TextArea
                        value={this.state.message}
                        onChange={this.handleChange}
                        placeholder={"Tell us how we can help."}
                    />
                    <SaveSection>
                        <CheckboxRow>
                            <CheckboxContainer><Checkbox action={this.handleChangeUrgent} passToAction={null} question={null} checked={this.state.urgent} id={"urgent"} /></CheckboxContainer>
                            <CheckboxLabel><FontBody18>Urgent</FontBody18></CheckboxLabel>
                        </CheckboxRow>
                        <ButtonSend onClick={this.handleSubmit}>
                            <ButtonPrimary label={"Send"} canSubmit={false} />
                        </ButtonSend>
                    </SaveSection>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    platform: state.common.user.get("platform")
});

const mapDispatchToProps = dispatch => ({
    tryPostHelp: (message) => dispatch(tryPostHelp(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HelpContact));
