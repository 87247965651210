import React from 'react';
import styled from "styled-components";

import {colors} from '../../../common/components/colors';

export const Width = styled.div`
    padding: 40px 30px;
    width: calc(100% - 60px);
    background-color: ${colors.white};
`;
export const PoweredBy = styled.a`
    text-decoration: none;
    color: ${colors.primary100};
    display: inline-block;
    vertical-align: top;
    width: fit-content;
`;
export const Gray = styled.div`
    display: inline-block;
    vertical-align: top;
    color: ${colors.primary70};
`;
export const PoweredByLogo = styled.img`
    display: inline-block;
    vertical-align: top;
    height: 24px;
    width: auto;
`;