import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import {withRouter} from 'react-router-dom';
import styled from "styled-components";

/* Components */
import {DropdownInput, TextInput, PhoneInput} from "../../common/components/inputs";
import {FontTitle18, FontTitle32To27} from "../../common/components/fonts";
import {colors} from "../../common/components/colors";
import {InlineBlock, SaveSection, Width, SubmitInputWithValidation} from "./components/signupwmcuser";

/* Middleware */
import {tryCreateUserAdvisor} from './middleware/signupwmcuser';

/* Utils */
import {timeZoneOptions} from '../../common/utils/timezone2';

const TitleSection = styled.div`
    padding-bottom: 15px;
    border-bottom: 1px solid ${colors.primary30};
    margin-bottom: 30px;
`;

const Left = styled.div`
    width: 100%;
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 640px) {
        width: 100%;
    }
`;

class PrivateWMCCreateUser extends React.Component {
    state = {
        first: '', firstValid: true,
        last: '', lastValid: true,
        phone: '', phoneValid: true,
        email: '', emailValid: true,
        // wmcId: '', wmcIdValid: true,
        timeZone: '',
        isSaving: false,
        isSaved: false,
        error: '',
        isChanged: false,
        isValid: true
    };

    componentDidMount() {}
    componentWillUnmount() {}

    handleChangeFirst = async e => {
        this.setState({'first': e.target.value});
        await this.setState({'firstValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeLast = async e => {
        this.setState({'last': e.target.value});
        await this.setState({'lastValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    // handleChangeWMCId = async e => {
    //     this.setState({'wmcId': e.target.value});
    //     await this.setState({'wmcIdValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
    //     this.setState({'isChanged': true});
    //     this.checkValid();
    // };
    handleChangeEmail = async e => {
        this.setState({'email': e.target.value});
        await this.setState({'emailValid': validator.isEmail(e.target.value)});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangePhone = async e => {
        this.setState({'phone': e.target.value.replace('(', '').replace(')', '').replace(' ', '').replace('-', '')});
        await this.setState({'phoneValid': validator.isMobilePhone(e.target.value, 'en-US')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeTimeZone = async e => {
        await this.setState({'timeZone': e.value});
        this.setState({'isChanged': true});
        this.checkValid();
    };

    checkValid = () => {
        if(this.state.firstValid && this.state.lastValid && this.state.phoneValid && this.state.emailValid && this.state.timeZone !== ""/* && this.state.wmcIdValid*/) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            this.setState({'isSaving': true, 'error': ''});
            await this.props.tryCreateUserAdvisor(this.state);
            if(this.props.error !== "") {
                this.setState({'error': this.props.error, 'isSaving': false, 'isSaved': true, isChanged: false});
            } else {
                this.setState({'isSaving': false, 'isSaved': true, isChanged: false});
            }
        }
    };

    render() {
        return(
            <>
                <TitleSection>
                    <Left>
                        <FontTitle32To27>Private WMC Create User</FontTitle32To27>
                    </Left>
                </TitleSection>
                <form method={"post"} onSubmit={this.handleSubmit}>
                    {this.state.isSaved
                        ?
                        <>
                            {this.state.error === '' && <FontTitle18>Created!</FontTitle18>}
                            {this.state.error !== '' && <FontTitle18>{this.state.error}</FontTitle18>}
                        </>
                        :
                        <>
                            <Width>
                                <TextInput
                                    title={"First Name"}
                                    valid={this.state.firstValid}
                                    warning={"Required"}
                                    id={"first"}
                                    onChange={this.handleChangeFirst}
                                    placeholder={"First Name"}
                                    value={this.state.first}
                                />
                                <TextInput
                                    title={"Last Name"}
                                    valid={this.state.lastValid}
                                    warning={"Required"}
                                    id={"last"}
                                    onChange={this.handleChangeLast}
                                    placeholder={"Last Name"}
                                    value={this.state.last}
                                />
                                <PhoneInput
                                    title={"Mobile Phone"}
                                    valid={this.state.phoneValid}
                                    warning={"Enter a valid mobile phone"}
                                    id={"phone"}
                                    onChange={this.handleChangePhone}
                                    placeholder={'(###) ###-####'}
                                    value={this.state.phone}
                                    options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}}
                                />
                                <TextInput
                                    title={"Email"}
                                    valid={this.state.emailValid}
                                    warning={"Enter a valid email address"}
                                    id={"email"}
                                    onChange={this.handleChangeEmail}
                                    placeholder={"Email"}
                                    value={this.state.email}
                                />
                                <DropdownInput
                                    title={"Time zone"}
                                    options={timeZoneOptions}
                                    id={'timeZone'}
                                    onChange={this.handleChangeTimeZone}
                                    placeholder={'Time Zone'}
                                    value={this.state.timeZone}
                                />
                                {/*<TextInput*/}
                                {/*    title={"WMC Id"}*/}
                                {/*    valid={this.state.wmcIdValid}*/}
                                {/*    warning={"Required"}*/}
                                {/*    id={"wmcId"}*/}
                                {/*    onChange={this.handleChangeWMCId}*/}
                                {/*    placeholder={"WMC Id"}*/}
                                {/*    value={this.state.wmcId}*/}
                                {/*/>*/}
                            </Width>
                            <SaveSection>
                                <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                            </SaveSection>
                        </>
                    }
                </form>
            </>
        )
    }
}

const mapStateToProps = state => ({
    error: state.iframe.signupWMCUsers.signupWMCUser.get("signupWMCUserError")
});

const mapDispatchToProps = dispatch => ({
    tryCreateUserAdvisor: (data) => dispatch(tryCreateUserAdvisor(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrivateWMCCreateUser));
