import {fromJS, Map, List} from 'immutable';

/* Immutable */
const CARE_SET_COACHING_SELECTED = 'CARE_SET_COACHING_SELECTED';
const CARE_SET_SUB_COACHING_SELECTED = 'CARE_SET_SUB_COACHING_SELECTED';
const CARE_SET_COACHES = 'CARE_SET_COACHES';
const CARE_SET_COACHES_FETCHING = 'CARE_SET_COACHES_FETCHING';
const CARE_PARENT_CATEGORIES_FETCHING = 'CARE_PARENT_CATEGORIES_FETCHING';
const CARE_PARENT_CATEGORIES = 'CARE_PARENT_CATEGORIES';
const CARE_SUB_CATEGORIES_FETCHING = 'CARE_SUB_CATEGORIES_FETCHING';
const CARE_SUB_CATEGORIES = 'CARE_SUB_CATEGORIES';
const CARE_COACHING_STORE_RESET = 'CARE_COACHING_STORE_RESET';

/* Actions */
export const setCareCoachingSelected = (category) => ({'type': CARE_SET_COACHING_SELECTED, 'category': category});
export const setCareSubCoachingSelected = (category) => ({'type': CARE_SET_SUB_COACHING_SELECTED, 'category': category});
export const setCareCoaches = (coaches) => ({'type': CARE_SET_COACHES, 'coaches': coaches});
export const setCareCoachesFetching = (status) => ({'type': CARE_SET_COACHES_FETCHING, 'status': status});
export const setCareCoachingParentCategoriesFetching = (status) => ({'type': CARE_PARENT_CATEGORIES_FETCHING, 'status': status});
export const setCareCoachingParentCategories = (categories) => ({'type': CARE_PARENT_CATEGORIES, 'categories': categories});
export const setCareCoachingSubCategoriesFetching = (status) => ({'type': CARE_SUB_CATEGORIES_FETCHING, 'status': status});
export const setCareCoachingSubCategories = (categories) => ({'type': CARE_SUB_CATEGORIES, 'categories': categories});
export const setCareStoreReset = () => ({'type': CARE_COACHING_STORE_RESET});

/* Initial State */
const initialState = Map({
    coachingOptionSelected: Map(),
    coachingSubCategorySelected: Map(),
    coaches: List(),
    coachesFetching: false,
    parentCategoriesFetching: false,
    parentCategories: List(),
    subCategoriesFetching: false,
    subCategories: List(),
});

/* Reducer */
const coachingReducer = (state=initialState, action) => {
    switch (action.type) {
        case CARE_SET_COACHING_SELECTED:
            return state.merge({'coachingOptionSelected': fromJS(action.category)});
        case CARE_SET_SUB_COACHING_SELECTED:
            return state.merge({'coachingSubCategorySelected': fromJS(action.category)});
        case CARE_SET_COACHES:
            return state.merge({'coaches': fromJS(action.coaches)});
        case CARE_SET_COACHES_FETCHING:
            return state.merge({'coachesFetching': action.status});
        case CARE_PARENT_CATEGORIES_FETCHING:
            return state.merge({'parentCategoriesFetching': action.status});
        case CARE_PARENT_CATEGORIES:
            return state.merge({'parentCategories': fromJS(action.categories)});
        case CARE_SUB_CATEGORIES_FETCHING:
            return state.merge({'subCategoriesFetching': action.status});
        case CARE_SUB_CATEGORIES:
            return state.merge({'subCategories': fromJS(action.categories)});
        case CARE_COACHING_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default coachingReducer;
