import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import styled from 'styled-components';

/* Components */
import {TextInput, TextQuillInputControlCount} from "../../../common/components/inputs";
import {SavedSettings} from '../../../common/components/cards';
import {FontTitle18} from '../../../common/components/fonts';
import {colors} from '../../../common/components/colors';
import {ButtonTertiary} from "../../../common/components/buttons";
import {LogoLoading} from "../../../common/components/loading";
import {InlineBlock, SaveSection, SubmitInputWithValidation} from "../components/introduction";

/* Middleware */
import {tryUpdateExpertProfile} from '../middleware/expertprofile';

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

class Introduction extends React.Component {
    state = {
        storeTitle: '', storeTitleValid: true, storeTitlePrevious: "",
        description: '', descriptionValid: true, descriptionCount: 0, descriptionComponent: '', descriptionPrevious: "", descriptionCountPrevious: 0,
        isSaving: false,
        isSaved: false,
        error: '',
        isValid: true,
        isChanged: false,
        isLoading: true
    };

    componentDidMount() {
        return this.init();
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    init = async () => {
        const description = this.props.profile.get("storeDescription");
        await this.setState({
            "storeTitle": this.props.profile.get("storeTitle"),
            "storeTitlePrevious": this.props.profile.get("storeTitle"),
            "description": (description === null || description === undefined) ? "" : description,
            "descriptionPrevious": (description === null || description === undefined) ? "" : description,
            "descriptionCount": (description === null || description === undefined || description === '') ? 0 : description.replace( /(<([^>]+)>)/ig, '').length,
            "descriptionCountPrevious": (description === null || description === undefined || description === '') ? 0 : description.replace( /(<([^>]+)>)/ig, '').length,
        });
        await this.updateDescription();
        this.setState({"isLoading": false});
    };

    handleChangeStoreTitle = async e => {
        await this.setState({'storeTitle': e.target.value, 'storeTitleValid': (!validator.isEmpty(e.target.value) && e.target.value !== ''), 'isChanged': true});
        // this.checkValid();
    };

    handleChangeDescription = async e => {
        await this.setState({
            'description': e,
            'descriptionValid': (!validator.isEmpty(e) && e !== '<p><br></p>' && e !== ''), // && e.replace( /(<([^>]+)>)/ig, '').length > 500)
            'descriptionCount': (validator.isEmpty(e) || e === '<p><br></p>' || e === '') ? 0 : e.replace( /(<([^>]+)>)/ig, '').length,
            "isChanged": true
        });
        await this.updateDescription();
        // this.checkValid();
    };

    updateDescription = async () => {
        await this.setState({
            'descriptionComponent': <TextQuillInputControlCount
                title={"Description"}
                valid={this.state.descriptionValid}
                warning={""}
                id={"description"}
                onChange={this.handleChangeDescription}
                placeholder={"Describe how you help customers"}
                value={this.state.description}
                maxLength={500}
                count={this.state.descriptionCount}
                minRows={8}
                required={true}
                countColorAbove={colors.white}
                countColorBelow={colors.white}
            />
        })
    };

    checkValid = async () => {
        if(this.state.descriptionValid && this.state.storeTitleValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    reset = async () => {
        await this.setState({
            "storeTitle": this.state.storeTitlePrevious,
            "description": this.state.descriptionPrevious,
            "descriptionCount": this.state.descriptionCountPrevious,
            "descriptionComponent": "",
            "isChanged": false
        });
        await this.updateDescription();
        this.props.handleEdit(false);
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.isValid) {
            await this.setState({'isSaving': true, "error": ""});
            const intro = {
                "storeTitle": this.state.storeTitle,
                "storeDescription": this.state.description
            };
            await this.props.tryUpdateExpertProfile(intro, "shopIntroError");
            if(this.props.profile.get("shopIntroError") === "") {
                this.setState({
                    'isSaving': false,
                    "isSaved": true,
                    "storeTitlePrevious": this.state.storeTitle,
                    "descriptionPrevious": this.state.description,
                    "descriptionCountPrevious": this.state.descriptionCount,
                });
                this.timeout = setTimeout(() => {
                    this.setState({'isSaved': false, "isChanged": false});
                    this.props.handleEdit(false);
                }, 3000);
            } else {
                this.setState({'isSaved': true, 'isSaving': false, "error": this.props.profile.get("shopIntroError")});
                this.timeout = setTimeout(() => {this.setState({'isSaved': false, "error": ""})}, 3000);
            }
        }
    };

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return(
                <>
                    {this.state.isSaved
                        ?
                            <>
                                {this.state.error === '' && <SavedSettings height={"99px"} error={false} message={"Saved"}/>}
                                {this.state.error !== '' && <SavedSettings height={"99px"} error={true} message={this.state.error}/>}
                            </>
                        :
                            <>
                                <form method={"post"} onSubmit={this.handleSubmit}>
                                    <div>
                                        <TextInput
                                            title={"Title"}
                                            valid={this.state.storeTitleValid}
                                            warning={""}
                                            id={"storeTitle"}
                                            onChange={this.handleChangeStoreTitle}
                                            placeholder={"Add a title"}
                                            value={this.state.storeTitle}
                                        />
                                        {this.state.descriptionComponent}
                                    </div>
                                    {/*{this.state.isChanged &&*/}
                                        <SaveSection>
                                            <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                            <InlineBlock><div onClick={this.reset}><ButtonTertiary canSubmit={false} label={this.state.isChanged ? "Undo" : "Cancel"} /></div></InlineBlock>
                                        </SaveSection>
                                    {/*}*/}
                                </form>
                            </>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    profile: state.enterprise.services.expertProfile.get("expertProfile"),
});

const mapDispatchToProps = dispatch => ({
    tryUpdateExpertProfile: (data, errorKey) => dispatch(tryUpdateExpertProfile(data, errorKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Introduction);
