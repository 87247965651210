import {axiosReq} from "../../../utils/axios";

import {
    tryMymoneySpendingSave,
    tryMymoneySpendingDownloading,
    tryMymoneySpendingUpdating,
    tryMymoneySnapshotSave,
    tryMymoneySnapshotDownloading,
    tryMymoneySnapshotUpdating,
    tryMymoneySavingSave,
    tryMymoneySavingDownloading,
    tryMymoneySavingUpdating
} from '../store/mymoney';
import {devMyMoneyIncomeStatement, devMyMoneyBalanceSheet, devMyMoneySavingsGoals} from '../static/devdata';

import {defaultMymoneyState} from '../static/mymoneydefaultstate';

const createUrl = (state) => {
    if(state.common.user.get("platform") === "consumer") {
        return("consumer");
    } else {
        return("coach/consumer/"+state.enterprise.clientsDetail.get("client").get("prospectId"));
    }
};

export const tryGetMyMoneyIncomeStatement = () => {
    return async (dispatch, getState) => {
        dispatch(tryMymoneySpendingDownloading(true));
        const incomeStatement = await axiosReq('apiv1/'+createUrl(getState())+'/finances/income-statement', "GET", {}, devMyMoneyIncomeStatement);
        if(incomeStatement.hasOwnProperty('incomeStatement') && Object.keys(incomeStatement.incomeStatement).length > 0) {
            dispatch(tryMymoneySpendingSave(incomeStatement.incomeStatement));
        } else {
            dispatch(tryMymoneySpendingSave(defaultMymoneyState.spendingPlan));
        }
        dispatch(tryMymoneySpendingDownloading(false));
        return incomeStatement;
    }
};

export const tryUpdateMyMoneyIncomeStatement = () => {
    return async (dispatch, getState) => {
        dispatch(tryMymoneySpendingUpdating(true));
        const data = getState().common.mymoney.get("spendingPlan");
        const incomeStatement = await axiosReq('apiv1/'+createUrl(getState())+'/finances/income-statement', "PUT", {"incomeStatement": data}, devMyMoneyIncomeStatement);
        if(incomeStatement.incomeStatement !== null) {
            dispatch(tryMymoneySpendingSave(incomeStatement.incomeStatement));
        }
        dispatch(tryMymoneySpendingUpdating(false));
    }
};

export const tryGetMyMoneyBalanceSheet = () => {
    return async (dispatch, getState) => {
        dispatch(tryMymoneySnapshotDownloading(true));
        const balanceSheet = await axiosReq('apiv1/'+createUrl(getState())+'/finances/balance-sheet', "GET", {}, devMyMoneyBalanceSheet);
        if(balanceSheet.hasOwnProperty('balanceSheet') && Object.keys(balanceSheet.balanceSheet).length > 0) {
            dispatch(tryMymoneySnapshotSave(balanceSheet.balanceSheet));
        } else {
            dispatch(tryMymoneySnapshotSave(defaultMymoneyState.snapshot));
        }
        dispatch(tryMymoneySpendingDownloading(false));
        return balanceSheet;
    }
};

export const tryUpdateMyMoneyBalanceSheet = () => {
    return async (dispatch, getState) => {
        dispatch(tryMymoneySnapshotUpdating(true));
        const data = getState().common.mymoney.get("snapshot");
        const balanceSheet = await axiosReq('apiv1/'+createUrl(getState())+'/finances/balance-sheet', "PUT", {"balanceSheet": data}, devMyMoneyBalanceSheet);
        if(balanceSheet.balanceSheet !== null) {
            dispatch(tryMymoneySnapshotSave(balanceSheet.balanceSheet));
        }
        dispatch(tryMymoneySnapshotUpdating(false));
    }
};

export const tryGetMyMoneySavingsGoals = () => {
    return async (dispatch, getState) => {
        dispatch(tryMymoneySavingDownloading(true));
        const savingsGoals = await axiosReq('apiv1/'+createUrl(getState())+'/finances/savings-goals', "GET", {}, devMyMoneySavingsGoals);
        if(savingsGoals.hasOwnProperty('savingsGoals') && Object.keys(savingsGoals.savingsGoals).length > 0) {
            dispatch(tryMymoneySavingSave(savingsGoals.savingsGoals));
        } else {
            dispatch(tryMymoneySavingSave(defaultMymoneyState.saving));
        }
        dispatch(tryMymoneySavingDownloading(false));
        return savingsGoals;
    }
};

export const tryUpdateMyMoneySavingsGoals = () => {
    return async (dispatch, getState) => {
        dispatch(tryMymoneySavingUpdating(true));
        const data = getState().common.mymoney.get("saving");
        const savingsGoals = await axiosReq('apiv1/'+createUrl(getState())+'/finances/savings-goals', "PUT", {"savingsGoals": data}, devMyMoneySavingsGoals);
        if(savingsGoals.savingsGoals !== null) {
            dispatch(tryMymoneySavingSave(savingsGoals.savingsGoals));
        }
        dispatch(tryMymoneySavingUpdating(false));
    }
};










