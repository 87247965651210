import React from 'react';
import {withRouter} from 'react-router-dom';

/* Static */
import ProspectsAll from "../containers/prospects";
import ProspectsCreate from "../containers/prospectscreate";

/* Navs */
import {PageContent, PageFull, PageRight} from "../navbars/components/navlayout";
import NavPrimaryAll from "../navbars/containers/primaryall";
import NavSecondaryTop from '../navbars/containers/secondarytop';

/* Components */
import {EditOutlined, PlusOutlined} from "@ant-design/icons";

const prospectButtons = [
    {
        "pathname": "/settings/prospects",
        "label": <><EditOutlined /> Stages</>,
        "buttonType": "tertiary"
    },
    {
        "pathname": "/prospects/create",
        "label": <><PlusOutlined /> Create</>,
        "buttonType": "primary"
    }
];

const Prospects = ({location}) => (
    <>
        <NavPrimaryAll />
        <PageFull>
            <PageRight>

                {location.pathname === '/prospects' &&
                    <>
                        <NavSecondaryTop title={"Prospects"} buttons={prospectButtons} desc={"Manage your prospects - potential clients"}/>
                        <PageContent>
                            <ProspectsAll />
                        </PageContent>
                    </>
                }

                {location.pathname === '/prospects/create' &&
                    <>
                        <NavSecondaryTop title={"Create Prospect"} />
                        <PageContent>
                            <ProspectsCreate />
                        </PageContent>
                    </>
                }

            </PageRight>
        </PageFull>
    </>
);
export default withRouter(Prospects);
