import React, {useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useDispatch} from 'react-redux'
import styled from 'styled-components';

/* Components */
import {tryGetAllRoundtables} from "../middleware/roundtables";

const Img = styled.img`
  width: 100%;
`;

function FirmRoundtablesAll({history}) {
    const [allRoundtables, setAllRoundtables] = useState([])
    const dispatch = useDispatch();

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        const res = await dispatch(tryGetAllRoundtables())
        setAllRoundtables(res)
    }

    return (
        <>
            <b>All Roundtables</b>
            <br />
            <br />
            {allRoundtables.map(r => (
                <div onClick={() => {history.push("/my-roundtables/"+r.id)}}>
                    <Img src={r.coverPhoto} alt={""}/>
                    <div>{r.title}</div>
                    <div>{r.time}</div>
                    <br />
                    <br />
                </div>
            ))}
        </>
    );
}

export default withRouter(FirmRoundtablesAll);

