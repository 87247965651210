import React from "react";
import { withRouter } from 'react-router-dom';

/* Components */
import {ImageHighlighted, Description, IconImage, Shorten, TitleBox, HighlightedContainer, Olive} from "../components/courseone";
import {FontTitle24, FontBody14} from '../../../common/components/fonts';
import {images} from '../../../common/components/images';

class LearnContentHighlighted extends React.Component {

    viewCourse = async (data) => {
        if(data.isReadingMaterial) {
            if(data.isVideo) {
                return this.props.history.push("/learn/video/" + data.id)
            } else {
                return this.props.history.push("/learn/article/" + data.id)
            }
        } else {
            return this.props.history.push("/learn/course/" + data.id)
        }
    };

    render() {
        return(
            <HighlightedContainer onClick={() => {this.viewCourse(this.props.data)}} style={{marginBottom: this.props.noMargin ? '0' : '35px'}}>
                <ImageHighlighted src={this.props.data.imageUrl} alt={""}/>
                <TitleBox>
                    <FontTitle24><Olive>{this.props.data.title}</Olive></FontTitle24>
                    <Description>
                        {this.props.data.isReadingMaterial
                            ? <>
                                {this.props.data.isVideo
                                    ? <IconImage src={images.contentVideoIcon} alt={""}/>
                                    : <IconImage src={images.contentArticleIcon} alt={""}/>
                                }
                            </>
                            : <IconImage src={images.contentCourseIcon} alt={""}/>
                        }
                        <Shorten><FontBody14>{this.props.data.description}</FontBody14></Shorten>
                    </Description>
                </TitleBox>
            </HighlightedContainer>
        )
    }
}

export default withRouter(LearnContentHighlighted);
