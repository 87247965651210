import React from 'react';
import styled from "styled-components";

import {colors} from '../../../common/components/colors';

export const Body = styled.div`
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 1220px) {
        display: flex;
        flex-direction: column;
    }
    @media screen and (max-width: 1088px) {
        display: flex;
        flex-direction: row;
    }
    @media screen and (max-width: 800px) {
        display: flex;
        flex-direction: column;
    }
`;
export const Left = styled.div`
    display: inline-block;
    vertical-align: top;
    min-height: auto;
    width: calc(100% - 498px);
    max-width: 720px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0px;
    position: relative;
    padding: 48px 30px 60px 100px;
    background-color: ${colors.white};
    @media screen and (max-width: 1440px) {
        width: calc(100% - 428px);
        max-width: 720px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 0px;
        padding: 48px 30px 60px 30px;
    }
    @media screen and (max-width: 1220px) {
        display: flex;
        flex-direction: column;
        order: 2;
        width: calc(100% - 60px);
        max-width: 100%;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        padding: 14px 30px 60px 30px;
    }
    @media screen and (max-width: 1088px) {
        display: inline-flex;
        width: calc(100% - 428px);
        max-width: 720px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 0px;
        padding: 48px 30px 60px 30px;
    }
    @media screen and (max-width: 800px) { 
        display: flex;
        flex-direction: column;
        order: 2;
        width: calc(100% - 60px);
        max-width: 100%;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        padding: 14px 30px 60px 30px;
    }
    & .ql-editor {
        padding-left: 0px;
        padding-right: 0px;
        font-family: GraphicWebRegular;
    }
`;
export const Right = styled.div`
    display: inline-flex;
    order: 2;
    vertical-align: top;
    width: 308px;
    height: auto;
    padding: 60px 30px 60px 30px;
    background-color: ${colors.white};
    color: ${colors.primary100};
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 0px;
    position: relative;
    @media screen and (max-width: 1220px) {
        display: flex;
        flex-direction: column;
        order: 1;
        width: calc(100% - 60px);
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        padding: 30px 30px 0px 30px;
    }
    @media screen and (max-width: 1088px) {
        display: inline-flex;
        order: 2;
        width: 308px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 0px;
        padding: 60px 30px 60px 30px;
    }
    @media screen and (max-width: 800px) {
        display: flex;
        flex-direction: column;
        order: 1;
        width: calc(100% - 60px);
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        padding: 30px 30px 0px 30px;
    }
`;
export const DateTime = styled.div`
    color: ${colors.primary100};
    margin-top: 15px;
    margin-bottom: 20px;
`;
export const Title = styled.div`
    color: ${colors.primary100};
    @media screen and (max-width: 460px) {
        font-size: 16px;
    }
`;
export const Expert = styled.a`
    margin-top: 15px;
    display: block;
    width: fit-content;
    color: ${colors.primary100};
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;
export const Cost = styled.div`
    color: ${colors.primary100};
    position: absolute;
    bottom: 30px;
    left: 30px;
`;
export const Block = styled.div`
    display: block;
`;
export const Padding = styled.div`
    width: calc(100% - 24px);
    padding-left: 24px;
`;
export const Icon = styled.div`
    display: inline-block;
    vertical-align: middle;
    & svg {
        height: 16px;
        width: 16px;
    }
`;
export const MiniTitle = styled.div`
    display: none;
    @media screen and (max-width: 1220px) {
        display: block;
    }
    @media screen and (max-width: 1088px) {
        display: none;
    }
    @media screen and (max-width: 800px) {
        display: block;
    }
`;
export const Relative = styled.div`
    position: relative;
    width: 100%;
    height: 360px;
`;
export const FillContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;
export const HeroVidBackground = styled.iframe`
    position: relative;
    width: 100%;
    max-height: 360px;
    border: none;
    height: 100%;
`;