import React from 'react';

/* Components */
import {Header, Body, Add} from "../components/cards";
import {FontBody14} from "../../../common/components/fonts";
import {Plus} from "react-feather";

/* Containers */
import Title from "./commontitle";
import TimeLeft from "./commontimeleft";
import CardsOne from "./cardsone";

class CheckoutCards extends React.Component {
    state={"isLoading": true, "paymentMethods": [], "page": 1};

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.setState({
            "paymentMethods": this.props.paymentMethods,
            "isLoading": false
        });
        console.log(this.props.paymentMethods)
    };

    render() {
        if(this.state.isLoading) {
            return null
        } else {
            return(
                <>
                    <Header>
                        <Title
                            title={"Cards"}
                            onBack={this.props.onBack}
                        />
                        <TimeLeft
                            secondsAvailable={this.props.secondsAvailable}
                        />
                    </Header>
                    <Body>
                        {this.props.paymentMethods.map(p => (
                            <CardsOne
                                key={p.id}
                                data={p}
                                canSelect={true}
                                onCardSelect={() => {return this.props.onCardSelect(p)}}
                                canEdit={true}
                                onViewCardEdit={this.props.onViewCardEdit}
                            />
                        ))}
                        <FontBody14><Add onClick={this.props.onViewCardAdd}><Plus />&nbsp;<u>Add new card</u></Add></FontBody14>
                    </Body>
                </>
            )
        }
    }
}

export default CheckoutCards;
