import React from "react";
import {
    FontBody16,
    FontBody21To16,
    FontSubtitle14, FontSubtitle16To14,
    FontSubtitle24To20,
    FontTitle32To27
} from "../../common/components/fonts";
import styled from "styled-components";
import {ButtonPrimary, ButtonTertiary} from "../../common/components/buttons";
import {BtnContainer, BtnDiv} from "./instructionsflow";

const StyledPage = styled.div`
  justify-content: space-between;
  .styled-div {
    margin:0 auto;
    width:90%;
    @media screen and (max-width: 460px) {
      margin: 0 auto;
    }
  }
  ${FontTitle32To27} {
    text-align: center;
    margin: 50px 0;
  }
`;
export default function InstructionsPageOne({handleBack, handleNext, page}) {
    const questions = [
        {"What has prompted you to consider working with a financial professional?" : [
            "Life journey (which one? ___________)\n",
                "Emotional/mental health\n",
                "Referral from coach\n",
                "Recommendation from a friend\n",
                "Something in the news\n",
                "Other"
            ]},
        {"Tell me about you and your hopes for your financial future (open ended)" : []},
        {"Do you have any investments?": [
            "Yes",
                "No"
            ]},
        {"If yes, how did you select those investments?" : [
            "401k program\n",
            "I researched it myself",
            "A financial advisor - if so, why didn\'t that relationship continue?",
            "A friend chose the investments for me",
            "Other"]},
    {"How much do you have in your investments? (what are their top-of-mind investments?)" : []},
        {"What other investments do you own? (include stocks, bonds, mutual funds, ETFs, and don\'t forget to include IRAs, pensions, 401ks, employer stocks, real estate, CDs or cash equivalents.)" : []},
        {"Do you anticipate any additional assets in the future?": []},
        {"What kind of services would you like assistance with?": [
            'Retirement planning\n',
                'Investing',
                'Estate planning\n',
                'Budgeting/cash flow\n',
                'Insurance',
                'Other'
            ]},
        {"What are you looking for in an advisor? (Suggest they let the advisor know the answer to this question up front)" : [
                "Someone who 'gets me'",
                "An educator",
                "A sounding board",
                "Guide",
                "Someone to whom I can outsource decisions"
            ]},
        {"Do you have: ": [
                "Updated will",
                "Life insurance",
                "Emergency fund/cash buffer/cash cushion"
            ]},
        {"How could a financial advisor improve your financial life (Be prepared to provide some suggestions)": [
                "Updated will",
                "Life insurance",
                "Emergency fund/cash buffer/cash cushion"
            ]}

    ]
    return (
        <StyledPage>
            <div className={'styled-div'}>
            <FontTitle32To27 spaced={false}>Coach Call Script </FontTitle32To27>
                <br/> <br/>
            <ol>
                {questions.map((q,index) => {
                   return(
                        <li key={index}><FontBody21To16>{Object.keys(q)[0]}</FontBody21To16>
                            <ul key={index}>
                       {q[Object.keys(q)[0]].map((a, ind) =>
                           <li key={ind}><FontSubtitle16To14>{a}</FontSubtitle16To14></li>
                           )}
                            </ul>
                            </li>
                   )})}


            </ol>
            </div>
            <BtnContainer>
                <BtnDiv className={'back'} onClick={handleBack}>{page > 1 && page < 5 && <ButtonTertiary label={"Back"} canSubmit={false}/>}</BtnDiv>
                <BtnDiv className={'next'} onClick={handleNext}>{page < 5 && <ButtonPrimary label={"Next"} canSubmit={false}/>}</BtnDiv>
            </BtnContainer>

        </StyledPage>
    );

}