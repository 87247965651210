import React, {useEffect, useRef, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import styled from 'styled-components';

/* Navs */
import Nav from "../navbars/containers/nav";
import {PageContentContainer, PageFull, PageContent} from "../navbars/components/navlayouts";
import {setToolTipUpdate} from "../tooltip/store/tooltip";

/* Containers */
import FirmDashboardHome from './containers/home';
import {CloseIcon, StyledModal, useOnClickOutside} from "../certification/containers/steplist";
import {images} from "../../../common/components/images";
import {FontBody16, FontTitle28} from "../../../common/components/fonts";
import {ButtonPrimary, ButtonTertiary} from "../../../common/components/buttons";
import {colors} from "../../../common/components/colors";

const UpdatedStyledModal = styled(StyledModal)`
  box-shadow: 0 0 0 3000px rgba(1, 1, 1, 0.5);
`;
const MiddleText = styled(FontBody16)`
  margin: 10px 0 30px 0;
`;

const ModalButtons = styled.div`
  width: fit-content;
  cursor: pointer;
  margin: 0 10px;
  ${FontBody16} {
    color: ${colors.primary30};
  }
`;

const FirmDashboard = () => {
    const [isNew, setNew] = useState(false);
    const ref = useRef();
    const [childBackground, setChildBackground] = useState(false);
    const dispatch = useDispatch();
    const progressCertification = useSelector(state => state.common.user.get("progressCertification"));
    const completedCertificates = useSelector(state => state.common.user.get("completedCertificates"));
    const showToolTip = useSelector(state => state.enterprise.tooltip.get('showToolTip'));
    const toolTipPage = useSelector(state => state.enterprise.tooltip.get('toolTipPage'));

    useOnClickOutside(ref, () => {
        setChildBackground(true)
    });

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
            setNew(progressCertification === null && (completedCertificates === null ? true : completedCertificates.size === 0));
    }
    const startTooltip = (e) => {
        dispatch(setToolTipUpdate(true, 2));
        e.stopPropagation()
    }

    const stopToolTip = (e) => {
        dispatch(setToolTipUpdate(false, 1));
        e.stopPropagation()
    }

    return (
        <>
            {isNew && toolTipPage === 1 && showToolTip &&
                <UpdatedStyledModal onClick={(e) => e.stopPropagation()} ref={ref}>
                    <FontTitle28>Looking to earn a certificate?</FontTitle28>
                    <MiddleText>Start our 1-minute tutorial to learn how to earn a certificate.</MiddleText>
                    <div style={{display: 'flex', alignItems: "center", justifyContent: "space-between"}}>
                        <ModalButtons onClick={stopToolTip}><FontBody16>Skip</FontBody16></ModalButtons>
                        <ModalButtons onClick={startTooltip}> <ButtonPrimary canSubmit={true}
                                                                             label={"Start Tutorial"}/></ModalButtons>
                    </div>

                </UpdatedStyledModal>}

            {isNew && toolTipPage === 9 && showToolTip &&
                <UpdatedStyledModal onClick={(e) => e.stopPropagation()} ref={ref}>
                    <FontTitle28>Welcome to Willow!</FontTitle28>
                    <MiddleText>Enjoy the Willow Platform for all of your advisor related needs!</MiddleText>
                    <div style={{display: 'flex', alignItems: "center", justifyContent: "space-between"}}>
                        <ModalButtons onClick={stopToolTip}><FontBody16>Back</FontBody16></ModalButtons>
                        <ModalButtons onClick={startTooltip}> <ButtonPrimary canSubmit={true}
                                                                             label={"Restart tutorial"}/></ModalButtons>
                    </div>

                </UpdatedStyledModal>}

            <Nav/>
            <PageFull>
                <PageContentContainer fullPage={true}>
                    <PageContent>
                        <FirmDashboardHome/>
                    </PageContent>
                </PageContentContainer>
            </PageFull>
        </>
    );
}

export default FirmDashboard;