const consumerFAQ = [
    {
        "id": 1,
        "title": "Common Questions",
        "faqs": [
            {
                "id": 1,
                "title": "Are calls with coaches audio or video?",
                "body": "<div>Both are available! Although video calls often provide a platform for a more immediate connection, we know that it may not always be possible to be on camera. You will start out with audio and your coach may ask to switch the call to video once you and your coach connect. Once the video starts you can hang up the phone and start talking! The Willow video capability sits within our platform so you don’t need to open another window (how convenient is that ?)</div>"
            },
            {
                "id": 2,
                "title": "What should I do if I feel uncomfortable working with a coach?",
                "body": "<div>While we hope that won’t happen, know that we’re here to support you. Please <a data-link-type='internal' data-label='contact us here' href='/support/contact'>contact us here</a> if you need assistance.</div>"
            },
            {
                "id": 5,
                "title": "I need technical support. Who do I contact?",
                "body": "<div><a data-link-type='internal' data-label='Reach out to us here' href='/support/contact'>Reach out to us here</a> and we’ll get back to you ASAP (within 24 hours) to help troubleshoot technical issues.</div>"
            },
            {
                "id": 7,
                "title": "Why do we ask about life journeys?",
                "body": "<div>Research shows that people tend to engage most with their money when going through life transitions and major moments. While financial challenges can arise for all genders during such moments, we know there are unique challenges experienced by women. That’s why Willow is committed to helping women navigate their life journeys. By learning about the journeys of our coaches and clients, we’re able to connect people that we hope can relate to each other - which fosters loyalty and motivation in coaching engagements!</div>"
            },
            /*{
                "id": 8,
                "title": "How does the coach to client matching process work?",
                "body": "<div>When joining the Willow platform, each coach completes a matrix of questions to demonstrate their areas of knowledge, expertise and personal interests. As prospective coaching clients start engaging with the Willow website and request a referral to a coach, they are also asked to share information about their background and coaching goals. The Willow proprietary matching process uses the answers from these inquiries to bring together both clients and coaches. Willow leverages technology to build and enhance human relationships!</div>"
            },
            {
                "id": 12,
                "title": "What’s the Willow Financial Coaching experience?",
                "body": "<div>Every coaching engagement should be tailored to the needs and desires of each client.While the details may differ, the overall experience provided to Willow Financial Coaching clients should always reflect our core values of trust, transparency, empathy and respect. When clients come to Willow seeking financial coaching, they know they will be matched thoughtfully with a financial coach. They also know their engagement will encompass the 6 steps outlined in the coaching experience. To view the full Willow Financial Coaching experience <a data-link-type='external' data-label='click here' target='_blank' href='http://www.trustwillow.com/static/core/documents/the_willow_coaching_experience_no_blurb.pdf'>click here</a>.</div>"
            }*/
        ]
    },
    {
        "id": 3,
        "title": "Account & Privacy",
        "faqs": [
            {
                "id": 18,
                "title": "How do I change my password?",
                "body": "<div>You can conveniently update your password at any time on your Dashboard. <a data-link-type='internal' data-label='click here' href='/settings/security'>click here</a>.</div>"
            },
            {
                "id": 19,
                "title": "How does Willow keep client information confidential?",
                "body": "<div>When using the Willow My Money Snapshot tool, you have the opportunity to sync your financial accounts and view all of your personal finances in one spot. It’s a convenient tool to bring various parts of your financial life together! The My Money Snapshot tool is protected with Bank-Level Security including 256-bit SSL encryption, firewalls, and is monitoring. We really do take data security seriously. To view our full Privacy Policy <a data-link-type='external' data-label='click here' target='_blank'  href='https://trustwillow.com/privacy-policy'>click here</a>.</div>"
            },
            {
                "id": 20,
                "title": "Do you record coaching calls?",
                "body": "<div>Yes. Willow records all audio and video calls between coaches and clients. The recordings are not publicly accessible and are used to ensure we’re providing high-quality service to Willow clients. From time to time, Willow may also review recordings to help us improve our platform. To view our full Privacy Policy <a data-link-type='external' data-label='click here' target='_blank'  href='https://trustwillow.com/privacy-policy'>click here</a>.</div>"
            },
            {
                "id": 21,
                "title": "How are you made aware they are on a recorded line?",
                "body": "<div>At the start of each call there is a recording stating that lines are recorded. Clients must also review and accept the Privacy Policy.</div>"
            }
        ]
    }
];

export default consumerFAQ;