import React from "react";
import {FolderAddOutlined, SettingOutlined, ShopOutlined} from "@ant-design/icons";

const consumerNav = {
    logo: "/my-team",
    nav: [
        {
            rootHref: "/my-shops",
            navSideLocation: "main",
            navSideTitle: "My Shops",
            navSideHref: "/my-shops",
            navMobileTitle: "My Shops",
            navMobileHref: "/my-shops",
            navMobileIcon: <ShopOutlined />,
            navMobileIconInvert: true,
            navTopTitle: "My Shops",
            navTopSubtitle: null,
            navTopTabs: null,
            navPageTabs: null
        },
        {
            rootHref: "/vaults",
            navSideLocation: "main",
            navSideTitle: "Vaults",
            navSideHref: "/vaults",
            navMobileTitle: "Vaults",
            navMobileHref: "/vaults",
            navMobileIcon: <FolderAddOutlined />,
            navMobileIconInvert: true,
            navTopTitle: "Vaults",
            navTopSubtitle: null,
            navTopTabs: null
        },
        {
            rootHref: "/settings",
            navSideLocation: "sub",
            navSideTitle: "Settings",
            navSideHref: "/settings/general",
            navMobileTitle: "Settings",
            navMobileHref: "/settings/menu",
            navMobileIcon: <SettingOutlined />,
            navMobileIconInvert: false,
            navTopTitle: "My Settings",
            navTopSubtitle: null,
            navTopTabs: [
                {
                    navTopTitle: "General Settings",
                    navTopSubtitle: null,
                    navTabTitle: "General",
                    navTabHref: "/settings/general"
                },
                {
                    navTopTitle: "Billing",
                    navTopSubtitle: null,
                    navTabTitle: "Billing",
                    navTabHref: "/settings/billing"
                },
            ],
            navPageTabs: [
                {
                    navTopTitle: "Settings",
                    navTopSubtitle: null,
                    navTabTitle: "Settings",
                    navTabHref: "/settings/general"
                },
                {
                    navTopTitle: "Support",
                    navTopSubtitle: null,
                    navTabTitle: "Support",
                    navTabHref: "/support/faq"
                },
                {
                    navTopTitle: "Feedback",
                    navTopSubtitle: null,
                    navTabTitle: "Feedback",
                    navTabHref: "/feedback"
                }
            ]
        },
        {
            rootHref: "/support",
            navSideLocation: "sub",
            navSideTitle: "Help",
            navSideHref: "/support/faq",
            navMobileTitle: null,
            navMobileHref: null,
            navMobileIcon: null,
            navMobileIconInvert: false,
            navTopTitle: "Help",
            navTopSubtitle: null,
            navTopTabs: [
                {
                    navTopTitle: "FAQs",
                    navTopSubtitle: null,
                    navTabTitle: "FAQs",
                    navTabHref: "/support/faq"
                },
                {
                    navTopTitle: "Contact Us",
                    navTopSubtitle: null,
                    navTabTitle: "Contact Us",
                    navTabHref: "/support/contact"
                }
            ],
            navPageTabs: null
        },
        {
            rootHref: "/feedback",
            navSideLocation: "sub",
            navSideTitle: "Feedback",
            navSideHref: "/feedback",
            navMobileTitle: null,
            navMobileHref: null,
            navMobileIcon: null,
            navMobileIconInvert: false,
            navTopTitle: "Feedback",
            navTopSubtitle: null,
            navTopTabs: null,
            navPageTabs: null
        }
    ]
};

export default consumerNav;