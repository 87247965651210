import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {v4 as uuid4} from 'uuid';
import {FontHeader21} from '../../../components/fonts'
import {colors} from '../../../components/colors'

/* Middleware */
import {tryGetSharedDriveList, tryCreateNewSharedDrive, tryGetSharedDriveFolderList, tryGetSharedDriveBetweenUser, tryDeleteDrive} from '../middleware/filevault';

/* Components */
import { Message } from "../../../components/messages";
import { TableWrapperV2 } from "../../../components/tablev2";
import { LogoLoading } from '../../../components/loading';
import VaultSearchResults from './vaultsearchresults';
import VaultSearch from './vaultsearch';

/* Containers */
import DriveDelete from "./drivedelete";
import DriveRename from "./driverename";
import DriveAddUser from "./driveadduser";
import DriveRemoveUser from "./driveremoveuser";

/* Store */
import {setSharedDrive, setDriveDeleteModal, setDriveRenameModal, setDriveRemoveUserModal, setDriveAddUserModal, setDriveSearchString} from '../store/filevault';
import {ButtonPrimary, ButtonInactiveLight} from "../../../components/buttons";
import {PlusOutlined, DeleteOutlined, EditOutlined, LoginOutlined, UserAddOutlined, UserDeleteOutlined} from "@ant-design/icons";

const randomNumber = () => {return Math.floor(Math.random() * 100)+1};

const CreateDrive = styled.div`
    text-align: center;
    font-family: CooperLight;
    font-size: 30px;
`;
const ButtonCentered = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    width: fit-content;
`;
const ButtonLeft = styled.div`
    margin-top: 20px;
`;
const Gray = styled.div`
    color: ${colors.primary100};
    margin-bottom: 5px;
`;
const Options = styled.div`
    margin: 0px 0px 30px 0px;
`;
const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    margin-top: 10px;
`;
const Btn = styled.div`
    width: fit-content;
`;
const Margin = styled.div`
    margin-bottom: 50px;
`;

const Drive = styled.div`
    display: block;
    vertical-align: top;
    margin-top: 4px;
    cursor: pointer;
    color: ${colors.secondary100};
`;

class AllSharedVaultList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {"isLoading": true, "data": [], "folderId": null, "selected": [], "drive": "none", "allBreadcrumbs": {},};
    }

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.driveListFetching && !this.props.driveListFetching || prevProps.driveList !== this.props.driveList) {
            this.update();
        }
    }

    init = async () => {
        await this.props.setDriveSearchString('');
        await this.props.tryGetSharedDriveList();
        await this.setup();
    };

    setup = async () => {
        this.setState({ 'allBreadcrumbs': this.setAllBreadcrumbs(this.props.driveList.toJS()) })
        this.update();
    }

    update = async () => {
        this.setState({
            "data": await this.createData(this.filter(this.props.driveList)),
            "isLoading": false,
            "selected": []
        });
    };

    createData = async (data) => {
        return Promise.all(data.map(d => {
            return Promise.resolve({
                "id": d.driveId,
                "name": d.name,
                "actions": {
                    "1": {"label": <><LoginOutlined />&nbsp;Open</>, "active": true, "show": true},
                    "2": {"label": <><EditOutlined />&nbsp;Rename</>, "active": !(d.driveType === "private_drive" || d.driveType === "wmc_drive"), "show": !(d.driveType === "private_drive" || d.driveType === "wmc_drive")},
                    "3": {"label": <><DeleteOutlined />&nbsp;Delete</>, "active": !(d.driveType === "private_drive" || d.driveType === "wmc_drive"), "show": !(d.driveType === "private_drive" || d.driveType === "wmc_drive")},
                    "4": {"label": <><UserAddOutlined />&nbsp;Add Users</>, "active": !this.props.consumer && d.users !== undefined, "show": !this.props.consumer && d.users !== undefined},
                    "5": {"label": <><UserDeleteOutlined />&nbsp;Remove User</>, "active": !this.props.consumer && !(d.driveType === "private_drive" || d.driveType === "wmc_drive" || d.users.length === 1), "show": !this.props.consumer && !(d.driveType === "private_drive" || d.driveType === "wmc_drive" || d.users.length === 1)},
                },
                "users": (d.driveType === "private_drive" || d.driveType === "wmc_drive" || d.users.length === 1) ? null : d.users,
                "allData": d
            })
        }));
    };

    filter = (drives) => {
        return drives.reduce((filtered, d) => {
                filtered.push({
                    ...d.toJS(),
                    "name": d.get("name"),
                    "linkAllowed": true,
                })
            return filtered;
        }, []);
    };

    view = async (data) => {
        await this.selectDrive(data);
        this.props.history.push("/vaults/drive");
    };

    rename = async (data) => {
        await this.props.setSharedDrive(data);
        this.props.setDriveRenameModal(true);
    }

    delete = async (data) => {
        await this.props.setSharedDrive(data);
        this.props.setDriveDeleteModal(true);
    }

    addUsers = async (data) => {
        await this.props.setSharedDrive(data);
        this.props.setDriveAddUserModal(true);
    }

    removeUsers = async (data) => {
        await this.props.setSharedDrive(data);
        this.props.setDriveRemoveUserModal(true);
    }

    selectDrive = async (data) => {
        this.props.setSharedDrive({
            "driveId": data.driveId,
            "driveType": data.driveType,
            "files": data.files,
            "folders": data.folders,
            "name": data.name,
            "parentFolderId": data.parentFolderId
        });
    };

    setAllBreadcrumbs = (driveList) => {
        let allBreadcrumbs = {};
        function addBreadcrumbs(folderList, breadcrumbs = [{ id: "root", parentId: null, name: "Drive" }]) {
            for (const folder of folderList) {
                allBreadcrumbs[folder.id] = breadcrumbs.concat({ id: folder.id, parentId: breadcrumbs[breadcrumbs.length - 1].id, name: folder.name })
                if (folder['folders'].length > 0) {
                    addBreadcrumbs(folder['folders'], allBreadcrumbs[folder.id]);
                }
            }
        }
        for (const drive of driveList) {
            addBreadcrumbs(drive.folders);
        }
        return allBreadcrumbs;
    }

    createColumns = {
        "0": {"key": "id", "title": "Id", "titleShowOnDesktop": false, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "hidden", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": this.view, "onDoubleClick": null},
        "1": {"key": "name", "title": "Drive Name", "titleShowOnDesktop": true, "canSort": true, "sort": "asc", "sortType": "string", "sortKey": "name", "component": "linkIfDataAllows", "thStyles": {}, "tdStyles": {"color": colors.action100}, "ifNull": "", "onClick": this.view, "onDoubleClick": null},
        "2": {"key": "users", "title": "Users", "titleShowOnDesktop": true, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "users", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": null, "onDoubleClick": null},
        "3": {"key": "actions", "title": "Actions", "titleShowOnDesktop": false, "canSort": false, "sort": null, "sortType": null, "sortKey": null, "component": "menu", "thStyles": {}, "tdStyles": {}, "ifNull": "", "onClick": {"1": this.view, "2": this.rename, "3": this.delete, "4": this.addUsers, "5": this.removeUsers}, "onDoubleClick": null}
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <>
                        <VaultSearch driveList={this.props.driveList}/>
                        {this.props.searchResults && this.props.searchString.length > 0
                            ? 
                            <VaultSearchResults results={this.props.searchResults.toJS()} selectDrive={this.selectDrive} allBreadcrumbs={this.state.allBreadcrumbs}/> 
                            :
                            <TableWrapperV2
                                responsive={true}
                                tableColumns={this.createColumns}
                                tableData={this.state.data}
                                initSelected={null}
                            />
                        }
                        <Margin />
                        {!this.props.consumer &&
                            <>
                                <DriveAddUser />
                                <DriveRemoveUser />
                            </>
                        }
                        <DriveDelete />
                        <DriveRename />
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    driveList: state.common.fileVault.get("sharedDriveList"),
    driveListFetching: state.common.fileVault.get("sharedDriveListFetching"),
    drive: state.common.fileVault.get("sharedDrive"),
    searchResults: state.common.fileVault.get("driveSearchResults"),
    searchString: state.common.fileVault.get("driveSearchString"),
});

const mapDispatchToProps = dispatch => ({
    tryGetSharedDriveList: () => dispatch(tryGetSharedDriveList()),
    setSharedDrive: (sharedDrive) => dispatch(setSharedDrive(sharedDrive)),
    tryDeleteDrive: (driveId) => dispatch(tryDeleteDrive(driveId)),
    setDriveDeleteModal: (status) => dispatch(setDriveDeleteModal(status)),
    setDriveRenameModal: (status) => dispatch(setDriveRenameModal(status)),
    setDriveAddUserModal: (status) => dispatch(setDriveAddUserModal(status)),
    setDriveRemoveUserModal: (status) => dispatch(setDriveRemoveUserModal(status)),
    setDriveSearchString: (searchString) => dispatch(setDriveSearchString(searchString)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AllSharedVaultList));