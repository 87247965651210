import React from 'react';
import styled from 'styled-components';

/* Components */
import {DropdownInput} from "../../../../common/components/inputs";
import {colors} from "../../../../common/components/colors";
import {FontBody16, FontBody12} from "../../../../common/components/fonts";
import {ButtonTertiary, ButtonPrimary, ButtonInactive} from "../../../../common/components/buttons";

/* Static */
import {images} from "../../../../common/components/images";

export const SetupBox = styled.div`
    margin: 35px 0px 15px 0px;
`;

export const SetupSchedule = styled.div``;

export const SetupEachRow = styled.div`
    margin-bottom: 10px;
    margin-top: -40px;
    @media screen and (max-width: 748px) {
        margin-top: 0px;
    }
`;
const SetupDay = styled.div`
    display: inline-block;
    width: 150px;
    margin-right: 48px;
    @media screen and (max-width: 748px) {
        display: block;
        width: 100%;
    }
`;

const SetupFrom = styled.div`
    display: inline-block;
    width: 150px;
    & div:first-of-type {
        margin: 0px;
    }
`;

const SetupTo = styled.div`
    display: inline-block;
    width: 150px;
    & div:first-of-type {
        margin: 0px;
    }
`;

const SetupDayAction = styled.div`
    vertical-align: top;
    ${props => props.errorShown === false} {margin: 33px 0px 0px 20px;}
    ${props => props.errorShown === true} {margin: 15px 0px 0px 20px;}
    display: inline-block;
    @media screen and (max-width: 640px) {
        display: block;
        margin: 15px 0px 0px 94px;
        width: calc(100% - 94px);
    }
`;

const SetupLabel = styled.div`
    display: inline-block;
    padding: 0px 10px;
    @media screen and (max-width: 640px) {
        width: 35px;
        padding: 0px;
    }
`;

const SetupLabelPlusFrom = styled.div`
    display: inline-block;
    width: 198px;
    @media screen and (max-width: 640px) {
        display: block;
        width: 100%;
        margin: 10px 0px;
    }
`;

const SetupLabelPlusTo = styled.div`
    display: inline-block;
    width: 182px;
    @media screen and (max-width: 640px) {
        display: block;
        width: 100%;
        margin: 10px 0px;
    }
`;

const SetupImg = styled.img`
    display: inline-block;
    height: 17px;
    cursor: pointer;
`;

export const SetupAddHours = styled.div`
    display: inline-block;
    margin: 15px 0px;
    cursor: pointer;
    color: ${colors.action100};
`;

const SetupSetHours = styled.div`
    display: inline-block;
    @media screen and (max-width: 748px) {
        display: block;
        margin: 10px 0px 30px 0px;
    }
`;

const Warning = styled.span`
    color: ${colors.alert100};
`;

const Copy = styled.span`
    margin-left: 5px;
`;
const Delete = styled.span`
    margin-left: 15px;
`;

const dayOptions = [
    {value: 'mon', label: 'Monday'},
    {value: 'tue', label: 'Tuesday'},
    {value: 'wed', label: 'Wednesday'},
    {value: 'thu', label: 'Thursday'},
    {value: 'fri', label: 'Friday'},
    {value: 'sat', label: 'Saturday'},
    {value: 'sun', label: 'Sunday'},
];

const typeOptions = [
    {value: '07:00:00', label: '7:00 AM'},
    {value: '07:30:00', label: '7:30 AM'},
    {value: '08:00:00', label: '8:00 AM'},
    {value: '08:30:00', label: '8:30 AM'},
    {value: '09:00:00', label: '9:00 AM'},
    {value: '09:30:00', label: '9:30 AM'},
    {value: '10:00:00', label: '10:00 AM'},
    {value: '10:30:00', label: '10:30 AM'},
    {value: '11:00:00', label: '11:00 AM'},
    {value: '11:30:00', label: '11:30 AM'},
    {value: '12:00:00', label: '12:00 PM'},
    {value: '12:30:00', label: '12:30 PM'},
    {value: '13:00:00', label: '1:00 PM'},
    {value: '13:30:00', label: '1:30 PM'},
    {value: '14:00:00', label: '2:00 PM'},
    {value: '14:30:00', label: '2:30 PM'},
    {value: '15:00:00', label: '3:00 PM'},
    {value: '15:30:00', label: '3:30 PM'},
    {value: '16:00:00', label: '4:00 PM'},
    {value: '16:30:00', label: '4:30 PM'},
    {value: '17:00:00', label: '5:00 PM'},
    {value: '17:30:00', label: '5:30 PM'},
    {value: '18:00:00', label: '6:00 PM'},
    {value: '18:30:00', label: '6:30 PM'},
    {value: '19:00:00', label: '7:00 PM'},
    {value: '19:30:00', label: '7:30 PM'},
    {value: '20:00:00', label: '8:00 PM'},
    {value: '20:30:00', label: '8:30 PM'},
    {value: '21:00:00', label: '9:00 PM'}
];

export const SetupEachDay = ({data, handleTimeChange, handleChangeDay, setSkipDay, setCopyDay, showDelete}) => (
    <SetupEachRow>
        <SetupDay><FontBody16>
            <DropdownInput
                title={null}
                options={dayOptions}
                id={data.get("id")+'Day'}
                onChange={(e) => handleChangeDay(e.value, data.get("id"))}
                placeholder={null}
                value={data.get("dayOfWeek")}
            />
        </FontBody16></SetupDay>
        <SetupSetHours>
            <SetupLabelPlusFrom>
                <SetupLabel><FontBody12>from</FontBody12></SetupLabel>
                <SetupFrom>
                    {!data.get("startAfterEnd") && <Warning>After end time</Warning>}
                    <DropdownInput
                        title={null}
                        options={typeOptions}
                        id={data.get("id")+'Start'}
                        onChange={(e) => handleTimeChange(e.value, data.get("endTime"), data.get("id"))}
                        placeholder={null}
                        value={data.get("startTime")}
                    />
                </SetupFrom>
            </SetupLabelPlusFrom>
            <SetupLabelPlusTo>
                <SetupLabel><FontBody12>to</FontBody12></SetupLabel>
                <SetupTo>
                    {!data.get("atLeastOneHr") && <Warning>Minimum 1 hour</Warning>}
                    <DropdownInput
                        title={null}
                        options={typeOptions}
                        id={data.get("id")+'End'}
                        onChange={(e) => handleTimeChange(data.get("startTime"), e.value, data.get("id"))}
                        placeholder={null}
                        value={data.get("endTime")}
                    />
                </SetupTo>
            </SetupLabelPlusTo>
            <SetupDayAction errorShown={!data.get("atLeastOneHr") || !data.get("startAfterEnd")}>
                <Copy onClick={() => {setCopyDay(data.get("id"))}}>
                    <SetupImg src={images.calSyncIconCopy} height={17} width={17} />
                </Copy>
                {showDelete &&
                <Delete onClick={() => {setSkipDay(data.get("id"))}}>
                    <SetupImg src={images.calSyncIconTrash} height={17} width={17} />
                </Delete>}
            </SetupDayAction>
        </SetupSetHours>
    </SetupEachRow>
);

export const ButtonRight = styled.div`
    margin-right: 10px;
`;

export const SubmitInputWithValidation = ({ isChanged, isValid, isSaving, label }) => {
    if(isChanged && isValid) {
        if(isSaving) {
            return (<ButtonRight><ButtonInactive canSubmit={true} label={"Saving"} /></ButtonRight>)
        } else {
            return (<ButtonRight><ButtonPrimary canSubmit={true} label={label}/></ButtonRight>)
        }
    } else {
        return(<ButtonRight><ButtonInactive canSubmit={true} label={label}/></ButtonRight>)
    }
};

export const Width = styled.div`
    max-width: 700px;
    width: 100%;
`;

const SetupOptionsContainer = styled.div`
    margin: 30px 0px;
`;

const InlineBox = styled.div`
    display: inline-block;
`;

export const SetupOptions = ({cancel, submit, submitLabel, isValid, cancelLabel}) => (
    <SetupOptionsContainer>
        {isValid
            ?
            <InlineBox onClick={() => submit()}>
                <SubmitInputWithValidation isChanged={true} isValid={isValid} isSaving={false} label={submitLabel} />
            </InlineBox>
            :
            <InlineBox>
                <SubmitInputWithValidation isChanged={true} isValid={isValid} isSaving={false} label={submitLabel} />
            </InlineBox>
        }
        {cancel !== null &&
        <InlineBox onClick={() => cancel()}>
            <ButtonTertiary canSubmit={false} label={cancelLabel} />
        </InlineBox>
        }
    </SetupOptionsContainer>
);

export const AlertRed = styled.div`
    padding: 10px 15px;
    width: calc(100% - 30px);
    background-color: ${colors.alert5};
    border: 1px solid ${colors.alert100};
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
    margin-bottom: 30px;
    border-radius: 4px;
`;

export const Alert = styled.div`
    padding: 10px 15px;
    width: calc(100% - 30px);
    background-color: ${colors.progress5};
    border: 1px solid ${colors.progress100};
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
    margin-bottom: 30px;
    border-radius: 4px;
`;
