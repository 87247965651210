import { Map, List, fromJS } from 'immutable';

/* Transformation */

import {listGuestNames} from '../middleware/sessions';

/* Immutable */

const SESSION_CREATING = 'SESSION_CREATING';
const SESSIONS = 'SESSIONS';
const SESSIONS_ADD_NEW_SESSION = 'SESSIONS_ADD_NEW_SESSION';
const SESSIONS_ADD_GUEST_TO_SESSION = 'SESSIONS_ADD_GUEST_TO_SESSION';
const SESSIONS_ADDING_GUEST_TO_SESSION = 'SESSIONS_ADDING_GUEST_TO_SESSION';
const SESSIONS_READING = 'SESSIONS_READING';
const SESSIONS_DELETING = 'SESSIONS_DELETING';
const SESSIONS_DELETE = 'SESSIONS_DELETE';
const SESSIONS_MODAL = 'SESSIONS_MODAL';
const SESSIONS_ONE_READING = 'SESSIONS_ONE_READING';
const SESSIONS_ONE = 'SESSIONS_ONE';
const SESSIONS_STORE_RESET = 'SESSIONS_STORE_RESET';

/* Actions */

export const setSessionCreating = (status) => ({'type': SESSION_CREATING, 'status': status});
export const setSessions = (sessions) => ({'type': SESSIONS, 'sessions': sessions});
export const setSessionsAddNewSession = (newSession) => ({'type': SESSIONS_ADD_NEW_SESSION, 'newSession': newSession});
export const setSessionsAddGuestToSession = (newGuest, sessionId) => ({'type': SESSIONS_ADD_GUEST_TO_SESSION, 'newGuest': newGuest, 'sessionId': sessionId});
export const setSessionsAddingGuestToSession = (status) => ({'type': SESSIONS_ADDING_GUEST_TO_SESSION, 'status': status});
export const setSessionsReading = (status) => ({'type': SESSIONS_READING, 'status': status});
export const setSessionsDeleting = (status) => ({'type': SESSIONS_DELETING, 'status': status});
export const setSessionsDelete = (sessionId) => ({'type': SESSIONS_DELETE, 'sessionId': sessionId});
export const setSessionsModal = (status, session, action, who, details, guests, eventApi) => ({'type': SESSIONS_MODAL, 'who': who, 'status': status, 'session': session, 'action': action, 'details': details, 'guests': guests, 'eventApi': eventApi});
export const setOneSession = (session) => ({'type': SESSIONS_ONE, 'session': session});
export const setOneSessionReading = (status) => ({'type': SESSIONS_ONE_READING, 'status': status});
export const setSessionsStoreReset = () => ({'type': SESSIONS_STORE_RESET});

/* Initial State */

const initialState = Map({
    sessions: List(),
    sessionsReading: false,
    sessionsDeleting: false,
    sessionsModal: false,
    sessionModalAction: null,
    sessionSelected: null,
    sessionSelectedWho: null,
    sessionSelectedDetails: null,
    sessionOne: Map(),
    sessionOneReading: false,
    sessionCreating: false,
    sessionsAddingGuest: false,
    sessionGuests: List(),
    sessionFromEventApi: true
});

/* Reducer */

const sessionsReducer = (state=initialState, action) => {
    switch (action.type) {
        case SESSIONS:
            return state.merge({"sessions": fromJS(action.sessions)});
        case SESSIONS_ADD_NEW_SESSION:
            return state.merge({
                "sessions": fromJS(state.get("sessions").push(fromJS(action.newSession)))
            });
        case SESSIONS_ADD_GUEST_TO_SESSION:
            return state.merge({
                'sessions': fromJS(state.get("sessions").map(s => {
                    if(s.get("id") === action.sessionId) {
                        let newGuests = fromJS(s.get("guests").push(fromJS(action.newGuest)));
                        return fromJS({
                            ...s.toJS(),
                            "title": s.get("title") === "" ? listGuestNames(newGuests.toJS(), false) : (s.get("remoteUserName") + listGuestNames(newGuests.toJS(), true)),
                            "guests": newGuests
                        });
                    } else {
                        return(fromJS(s))
                    }
                })),
                'sessionOne': state.get("sessionOne").size > 0
                    ? state.get("sessionOne").get("id") === action.sessionId
                        ? fromJS({
                            ...state.get("sessionOne").toJS(),
                            "title": (state.get("sessionOne").get("title") === "Internal meeting" || state.get("sessionOne").get("title") === "")
                                ? listGuestNames(state.get("sessionOne").get("guests").push(fromJS(action.newGuest)).toJS(), false)
                                : state.get("sessionOne").get("remoteUserName") + listGuestNames(state.get("sessionOne").get("guests").push(fromJS(action.newGuest)).toJS(), true),
                            "guests": fromJS(state.get("sessionOne").get("guests").push(fromJS(action.newGuest)))
                        })
                        : fromJS(state.get("sessionOne"))
                    : Map()
            });
        case SESSIONS_ADDING_GUEST_TO_SESSION:
            return state.merge({'sessionsAddingGuest': action.status});
        case SESSIONS_READING:
            return state.merge({'sessionsReading': action.status});
        case SESSIONS_DELETING:
            return state.merge({'sessionsDeleting': action.status});
        case SESSIONS_DELETE:
            return state.merge({
                "sessions": state.get("sessions").size === 0
                    ? state.get("sessions")
                    : fromJS(state.get("sessions").filter(s => {
                        return !(s.get("id") === action.sessionId);
                    }))
            });
        case SESSIONS_MODAL:
            return state.merge({
                'sessionsModal': action.status,
                'sessionSelected': fromJS(action.session),
                'sessionModalAction': action.action,
                "sessionSelectedWho": action.who,
                "sessionSelectedDetails": fromJS(action.details),
                "sessionGuests": fromJS(action.guests),
                "sessionFromEventApi": action.eventApi
            });
        case SESSIONS_ONE:
            return state.merge({"sessionOne": fromJS(action.session)});
        case SESSIONS_ONE_READING:
            return state.merge({'sessionOneReading': action.status});
        case SESSIONS_STORE_RESET:
            return state.merge(initialState);
        default:
            return state;
    }
};

export default sessionsReducer;
