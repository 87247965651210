import React, {useEffect, useState} from "react";
import moment from "moment/moment";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {Pagination} from "swiper";
import styled from "styled-components";
// import { useHistory } from "react-router-dom";

/* Components */
import {Swiper, SwiperSlide} from "swiper/react";
import {images} from "../../../../common/components/images";
import {
    FontBody16,
    FontBody18,
    FontHeader18,
    FontSubtitle21,
    FontTitle24,
    FontTitle34
} from "../../../../common/components/fonts";
// import { ButtonPrimary } from "../../../../common/components/buttons";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import FirmPlanWomen from "../../plans/containers/firmplanwomen";
import FirmPlanNextGen from "../../plans/containers/firmplannextgen";
import {useSelector} from "react-redux";
import {ButtonWide, CertImage, Option, Plan, PlanDetails, PlansList, Price} from "../../plans/containers/plansgrid";
import {ButtonPrimary} from "../../../../common/components/buttons";
import CompletedCertifications, {
    CenteredFontBody18,
    CenteredFontSubtitle21,
    CertifiedBadge,
    WidgetContent
} from "../../dashboard/containers/certificationcompleted";
import {SlideContainer, SwiperContainer} from "../../../../iframes/programs/components/silder";
import {colors} from "../../../../common/components/colors";
import {PopupContent, PopupOverlay} from "../../plans/containers/firmplanclientacquisition";
import {Link} from "react-router-dom";
import dispatch from "d3-selection/src/selection/dispatch";
import {tryPostHelp} from "../../../support/middleware/help";

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;


const Ad = styled.div`
  border-radius: 30px;
  padding: 30px 30px 50px 30px;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 33%;
  max-width: 350px;
  box-shadow: 0 5px 25px 0 rgb(0 0 0 / 10%);
  margin-right:20px;
  @media screen and (max-width: 1260px) {
    width: 100%;
  }
`
const ButtonDiv = styled.div`
    display: flex;
    width: fit-content;
`;
export default function NextCertificationAd(props) {
    const purchased = props.purchased;
    const [certWomen, setWomen] = useState(null)
    const [certNextGen, setNextGen] = useState(null)
    const [clientAcq, setClientAcq] = useState(null)
    const [toBuy, setToBuy] = useState("");
    const [modal, setModal] = useState(false)


    useEffect(() => {
        init()
    },[])

    const init = async () => {
        setWomen(purchased.womenCertPurchasedOn || purchased.womenCertCompletedOn)
        setNextGen(purchased.nextGenCertPurchasedOn || purchased.nextGenCertCompletedOn)
        setClientAcq(purchased.clientAcqPurchasedOn)
        if (certWomen === null){
            setToBuy("women")
        }
        else {
            setToBuy("nextgen")
        }

    }
    const sendLearnMore = () => {
        setModal(true)
        dispatch( tryPostHelp("interested in client content"))
    }
    const openPdf = async() => {
        await tryPostHelp("opened usage guide for client content")
    }
    return (
        <Container>
            <Ad>
                <FontTitle24 style={{textAlign: "center", marginBottom: 0}}>{toBuy === "women" ? "Advisor for Women™ Certificate" : "Advisor for NextGen™ Certificate"}</FontTitle24>
                <CompletedCertifications completedCertifications={props.completedCertifications} completePage={true}/>
            </Ad>
            {certWomen === null || certNextGen === null &&
            <Ad>
                <FontTitle24 style={{textAlign: "center"}}>{toBuy !== "women" ? "Advisor for Women™ Certificate" : "Advisor for NextGen™ Certificate"}</FontTitle24>
                <CertImage src={toBuy !== "women" ? images.womenBadge : images.nextGenBadge}/>
                <FontBody16>{toBuy !== "women" ? "Demonstrate that you are a top advisor who is committed to supporting the unique needs of women clients."
                    :"Demonstrate that you are a top advisor who is committed to supporting the unique needs of Millennial and GenZ clients."}</FontBody16>
                {/*<Plan>*/}
                {/*    <PlanDetails>Details:</PlanDetails>*/}
                {/*    <PlansList>*/}
                {/*        <li><FontBody16>Digital Certificate Badge</FontBody16></li>*/}
                {/*        <li><FontBody16>Quarterly practice management & best practice sharing sessions</FontBody16></li>*/}
                {/*        <li><FontBody16>Additional training & networking opportunities</FontBody16></li>*/}
                {/*    </PlansList>*/}
                {/*</Plan>*/}

                <>

                            {/*<Price>*/}
                            {/*    <FontHeader18>$600</FontHeader18>*/}
                            {/*    <FontBody18>/yr</FontBody18>*/}
                            {/*</Price>*/}
                    <Price style={{textDecoration: 'line-through', color: colors.secondary30}}>
                        <FontHeader18>$800</FontHeader18>
                        <FontBody18>/yr</FontBody18>
                    </Price>
                    <Price >
                        <FontHeader18>$600</FontHeader18>
                        <FontBody18>/yr</FontBody18>
                    </Price>
                            <ButtonWide onClick={() => {
                                window.location.href = `${toBuy === "women" ? `https://pay.trustwillow.com/b/5kAaG42oebQ51Py00d?prefilled_promo_code=SECONDCERT` : `https://pay.trustwillow.com/b/7sI01q9QGbQ5eCkfZf?prefilled_promo_code=SECONDCERT`}`
                            }}>
                                <ButtonPrimary label={"Get Started"} canSubmit={false}/>
                            </ButtonWide>

</>

                    {/*{noBuy &&*/}
                    {/*    <FontBody16>Finish your other certificate to purchase this one</FontBody16>*/}
                    {/*}*/}

                </Ad>
            }
            <Ad>
                <FontTitle24 style={{textAlign: "center"}}>{"Willow Content & Marketing Services"}</FontTitle24>
                <CertImage src={images.willowContentServicesIcon}/>
                <FontBody16>At Willow we want to serve our advisors in the ways that best support their business.
                    Talk to our team about creating a custom content solutions bundle.
                    Additionally, Willow can create custom materials and/or packages to meet your specific goals and objectives.</FontBody16>
                <a href={"https://docs.trustwillow.com/Willow+Content+%26+Events_Int+(2).pdf"} target={"_blank"} onClick={() => openPdf()}><FontBody16>Willow Content & Services Usage Guide</FontBody16></a>

                {/*<Plan>*/}
                {/*    <PlanDetails>Details:</PlanDetails>*/}
                {/*    <PlansList>*/}
                {/*        <li><FontBody16>Digital Certificate Badge</FontBody16></li>*/}
                {/*        <li><FontBody16>Quarterly practice management & best practice sharing sessions</FontBody16></li>*/}
                {/*        <li><FontBody16>Additional training & networking opportunities</FontBody16></li>*/}
                {/*    </PlansList>*/}
                {/*</Plan>*/}

                <>

                    {/*<Price>*/}
                    {/*    <FontHeader18>$600</FontHeader18>*/}
                    {/*    <FontBody18>/yr</FontBody18>*/}
                    {/*</Price>*/}
                    <ButtonWide onClick={() => sendLearnMore()}>
                        <ButtonPrimary label={"Get Started"} canSubmit={false}/>
                    </ButtonWide>

                </>

                {/*{noBuy &&*/}
                {/*    <FontBody16>Finish your other certificate to purchase this one</FontBody16>*/}
                {/*}*/}
                {modal &&
                    <PopupOverlay>
                        <PopupContent>

                            <>
                                <FontTitle34>Willow Content & Services</FontTitle34>
                                <br />
                                {/*<FontBody16><a href={"https://docs.trustwillow.com/Willow+Content+%26+Events_Int+(2).pdf"} target={"blank"}>Willow Content & Services Usage Guide</a></FontBody16>*/}
                                <br />
                                <FontBody16>Thank you for your interest in growing your relationship with Willow! Your customer success representative has been notified and will be in touch with you shortly to set a date to hear more about your content needs!</FontBody16>
                                <br />
                                <ButtonDiv onClick={() => setModal(false)}>
                                    <ButtonPrimary label={"Close"} canSubmit={false} />
                                </ButtonDiv>
                            </>

                        </PopupContent>
                    </PopupOverlay>}

            </Ad>

        </Container>
    );
};

