import React from 'react';
import {connect} from 'react-redux';
import {v4 as uuid4} from "uuid";
import ReactQuill from "react-quill";

/* Components */
import {LogoLoading} from "../../../common/components/loading";
import {FillContainer, HeroContainer, HeroImgBackground, One, OneContainer, SpoofContainer, Title, WhiteContainer, QuillNoPadding, SmallWhiteContainer} from "../components/courses";
import {FontBody14, FontHeader18} from "../../../common/components/fonts";
import {ButtonLinkProgress, ButtonTertiaryAlert} from "../../../common/components/buttons";
import {EditOutlined} from '@ant-design/icons';
import CoursesOne from './coursesone';

/* Middleware */
import {tryGetCourses} from '../middleware/courses';

class Courses extends React.Component {
    state={
        "isLoading": true,
        "courses": []
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.props.tryGetCourses();
        this.setState({
            "courses": this.props.coursesAll,
            "isLoading": false
        })
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return (
                <>
                    {this.state.courses.map((c, index) => (
                        <CoursesOne key={index} course={c} />
                    ))}
                </>
            )
        }
    }

}

const mapStateToProps = state => ({
    coursesAll: state.enterprise.curriculum.curriculum.get("coursesAll")
});

const mapDispatchToProps = dispatch => ({
    tryGetCourses: () => dispatch(tryGetCourses())
});

export default connect(mapStateToProps, mapDispatchToProps)(Courses);