import React from 'react';
import styled from 'styled-components';
import {v4 as uuid4} from 'uuid';
import {FontBody16, FontSubtitle24To20} from "../../../common/components/fonts";

/* Components */
import {MovableCardWrapper, CardHeader} from 'react-trello/dist/styles/Base';
import {DeleteOutlined, DragOutlined} from '@ant-design/icons'

class LayerPanelElement extends React.Component {
    state={
        className: "",
        style: {},
        cardDraggable: true,
        allData: null,
        id: null,
        focused: false
    };

    componentDidMount() {
        this.setState({
            className: this.props.className,
            style: this.props.style,
            cardDraggable: this.props.cardDraggable,
            allData: this.props.allData,
            id: this.props.id
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.children !== this.props.children) {
            this.setState({
                className: this.props.className,
                style: this.props.style,
                cardDraggable: this.props.cardDraggable
            })
        }
    }

    render() {
        return (
            <MovableCardWrapper
                data-id={this.state.id}
                style={this.state.style}
                className={this.state.className}
                draggable={this.state.cardDraggable}
                handleDragEnd={this.props.handleDragEnd}
            >
                <CardHeader draggable={this.state.cardDraggable}>
                    <div style={{display: 'inline-flex', alignItems: 'center', justifyContent: "space-between", width: "100%"}}>
                        <FontBody16>    
                            <DragOutlined />
                            &nbsp;&nbsp;
                            {this.props.title}
                        </FontBody16>
                        <div style={{cursor: 'pointer'}} onClick={() => {return this.props.onDelete(this.state.id, "lane1")}}><DeleteOutlined/>&nbsp;&nbsp;</div>
                    </div>
                </CardHeader>
            </MovableCardWrapper>
        )
    }
}

export default LayerPanelElement;