import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import moment from "moment";
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

/* Components */
import {colors} from '../../../../common/components/colors';
import {FontSubtitle18} from '../../../../common/components/fonts';
import {images} from '../../../../common/components/images'

/* Containers */
import FirmNavMenu from './navmenu';

/* Domain */
import getDomainEnv from '../../../../domains/utils';
const env = getDomainEnv();

const NavCover = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(1, 1, 1, 1);
  z-index: 10;
  width: 320px;

  // * {
  //   color: ${colors.primary100};
  // }

  box-shadow: inset 1000px 1000px grey;


`;
const NavSide = styled.div`
  -webkit-box-shadow: 6px 0 18px 0 rgba(0, 0, 0, 0.06);
  width: 320px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 4;
  background-color: ${colors.primary100};
  display: block;
  @media screen and (max-width: 1088px) {
    display: none;
  }
`;
const Container = styled.div`
  height: auto;
  position: relative;

`;
const LogoDate = styled(Link)`
  height: 40px;
  padding: 55px 10px 25px 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const WillowLogo = styled.img`
  width: 35px;
  height: auto;
`;
const Date = styled.div`
  color: ${colors.white};
  letter-spacing: 4px;
  text-transform: uppercase;
  padding-left: 15px;
  cursor: default;
`;

function FirmNavSide() {
    const [isNew, setNew] = useState(false);
    const showToolTip = useSelector(state => state.enterprise.tooltip.get('showToolTip'));
    const toolTipPage = useSelector(state => state.enterprise.tooltip.get('toolTipPage'));
    const progressCertification = useSelector(state => state.common.user.get("progressCertification"));
    const completedCertificates = useSelector(state => state.common.user.get("completedCertificates"));
    const dispatch = useDispatch();

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        setNew(progressCertification === null && (completedCertificates === null ? true : completedCertificates.size === 0));
    }

    return (
        <NavSide>
            <Container>
                {(isNew && showToolTip && toolTipPage > 0) ?
                    <NavCover>

                        <LogoDate to={env.ENTERPRISE.COMPANY.AUTH.HOME_URL}>
                            <img src={images.logoTransparentWhite} height="32" width="160" alt={"Willow"}/>
                            {/*<WillowLogo src={images.logoIcon} />*/}
                            {/*<Date><FontSubtitle18>{moment().format('MMMM D, YYYY')}</FontSubtitle18></Date>*/}
                        </LogoDate>

                        <FirmNavMenu/>
                    </NavCover> :
                    <>
                        <LogoDate to={env.ENTERPRISE.COMPANY.AUTH.HOME_URL}>
                            <img src={images.logoTransparentWhite} height="32" width="160" alt={"Willow"}/>
                            {/*<WillowLogo src={images.logoIcon} />*/}
                            {/*<Date><FontSubtitle18>{moment().format('MMMM D, YYYY')}</FontSubtitle18></Date>*/}
                        </LogoDate>

                        <FirmNavMenu/>
                    </>}

            </Container>
        </NavSide>
    )

}

export default FirmNavSide;