import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

/* Components */
import { FontHeader16 } from '../../../../common/components/fonts';
import {colors} from "../../../../common/components/colors";
import {images} from '../../../../common/components/images'

/* Containers */
import FirmNavMenu from "./navmenu";

/* Domain */
import getDomainEnv from '../../../../domains/utils';
const env = getDomainEnv();

const NavTopActive = styled.div`
    display: none;
    @media screen and (max-width: 1088px) {
        display: block;
    }
`;
const NavTopComponent = styled.div`
    position: relative;
    width: 100%;
    z-index: 5;
    background-color: ${colors.primary100};
`;
const Logo = styled.div`
    width: 160px;
    height: 32px;
    background-color: ${colors.primary100};
    vertical-align: top;
    display: inline-block;
    padding: 26px 30px;
    @media screen and (max-width: 460px) {
        padding: 26px 15px;
    }
`;
const NavTopMobile = styled.div`
    vertical-align: top;
    padding: 32px 32px 31px 32px;
    display: inline-block;
    cursor: pointer;
    margin-left: calc(100% - 335px);
    @media screen and (max-width: 460px) {
        margin-left: calc(100% - 305px);
    }
`;
const MenuIcon = styled.div`
    width: fit-content;
    display: inline-block;
    text-align: right;
    color: ${colors.action100};
`;
const Menu = styled.div`
    background-color: ${colors.primary100};
    padding-bottom: 60px;
    position: relative;
    z-index: 2;
`;


class NavTop extends React.Component {
    state = {"menuOpen": false};

    onChangeMenu = () => this.setState({"menuOpen": !this.state.menuOpen})

    render() {
        return(
            <NavTopActive>
                <NavTopComponent>
                    <Logo>
                        <Link to={env.ENTERPRISE.COMPANY.AUTH.HOME_URL}>
                            <img src={images.logoTransparentWhite} height="32" width="160" alt={"Willow"} />
                        </Link>
                    </Logo>
                    <NavTopMobile onClick={this.onChangeMenu}>
                        <MenuIcon>
                            <FontHeader16>Menu</FontHeader16>
                        </MenuIcon>
                    </NavTopMobile>
                </NavTopComponent>
                {this.state.menuOpen &&
                    <Menu>
                        <FirmNavMenu
                            logoutButtonStyle={{"margin": "35px 0 0 35px"}}
                            onOpenSupport={this.onChangeMenu}
                        />
                    </Menu>
                }
            </NavTopActive>
        )
    }
}

export default NavTop;
