import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import styled from 'styled-components'

/* Components */
import {FontHeader21, FontBody16, FontBody12} from "../../../common/components/fonts";
import {ButtonPrimary} from "../../../common/components/buttons";
import {colors} from "../../../common/components/colors";
import {LogoLoading} from "../../../common/components/loading";
import {ActionIcon, ActionItem} from "../components/teamlist";
import {CalendarFilled, MailFilled, MobileFilled, RightOutlined, DashOutlined} from "@ant-design/icons";

/* Store */
import {setMessagesSMSOpen} from "../../sms/store/messages";
import {setMessagesEmailOpen} from "../../email/store/messages";
import {setSessionsModal} from "../../meetings/store/sessions";
import {setSelectCoach, setCoachModalStatus} from "../store/coaching";
import SchedulerFlow from "../../../iframes/scheduler/containers/flow";
import {CommonBackground, CommonModal} from "../../../common/components/modal";

/* Domain */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

export const ActionBox = styled.div`
    border: 1px solid ${colors.secondary30};
    padding: 25px 0px 0px 0px;
    max-width: calc(700px - 2px);
    width: 100%;
    border-radius: 6px;
    margin-bottom: 20px;
    background-color: ${colors.border70};
    ${props => props.hide !== true} {display: none;}
    ${props => props.hide !== false} {display: inline-block;}
    vertical-align: top;
    margin-right: 20px;
    @media screen and (max-width: 768px) {
        text-align: center;
    }
    @media screen and (max-width: 460px) {
        margin-right: 10px;
    }
`;
export const Photo = styled.img`
    display: inline-block;
    vertical-align: top;
    width: 150px;
    height: 100%;
    max-height: 150px;
    object-fit: cover;
    object-position: 0 0;
    border-radius: 6px;
    margin-right: 30px;
    @media screen and (max-width: 768px) {
        display: block;
        margin: 0px auto;
    }
`;
const TitleSection = styled.div`
    display: inline-block;
    vertical-align: top;
    padding-top: 25px;
    width: calc(100% - 180px);
    @media screen and (max-width: 768px) {
        width: 100%;
        display: block;
    }
`;
const Top = styled.div`
    padding: 0px 30px 30px 30px;
    @media screen and (max-width: 460px) {    
        padding: 0px 20px 30px 20px;
    }
`;
const Category = styled.div`
    color: ${colors.primary100};
    margin-bottom: 16px;
`;
const ActionSection = styled.div`
    display: inline-block;
    vertical-align: middle;
    @media screen and (max-width: 768px) {
        display: block;
        margin-top: 20px;
    }
`;
const ExpandSection = styled.div`
    display: inline-block;
    vertical-align: bottom;
    color: ${colors.primary100};
`;
const Left = styled.div`
    width: 100%;
    display: inline-block;
    vertical-align: bottom;
`;
const IntroButton = styled.div`
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    width: fit-content;
    @media screen and (max-width: 768px) {
        display: block;
        margin: 0px auto;
    }
`;
const Right = styled.div`
    width: 40px;
    display: inline-block;
    vertical-align: bottom;
    text-align: right;
    color: ${colors.primary70};
    cursor: pointer;
`;
const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
`;
const More = styled.div`
    font-size: 18px;
    margin-top: -1px;
`;
const Modal = styled.div`
    margin: 0px auto;
    width: 748px;
    padding: 30px;
    border-radius: 6px;
    background-color: ${colors.white};
    position: fixed;
    top: 100px;
    left: 0px;
    right: 0px;
    @media screen and (max-width: 800px) {
        width: 100%;
        padding: 0px;
    }
    z-index: 6;
`;
const Background = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.50);
    @media screen and (max-width: 800px) {
        background-color: ${colors.white};
    }
    z-index: 5;
`;

class TeamList extends React.Component {
    state = {
        "photo": null,
        "firstName": null,
        "lastName": null,
        "userId": null,
        "showScheduler": false,
        "isLoading": true,
        "showAll": false,
        "allCoaches": [],
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        await this.setState({
            "allCoaches": await Promise.all(this.props.allCoaches.map(async a => {
                let isWillowSupport = false;
                await Promise.all(a.get("expertCategories").map(e => {
                    if(e.get("categoryId") === 6 || e.get("categoryId") === 7) {
                        isWillowSupport = true;
                    }
                }));
                return({...a.toJS(), "expanded": false, "consumerId": a.get("coachId"), "initPage": "partnerships", "isWillowSupport": isWillowSupport})
            })),
            "isLoading": false
        })
        console.log(this.state)
    };

    selectCoach = async (oneCoach) => {
        await this.props.setSelectCoach(oneCoach);
        this.props.history.push("/team/member");
    };

    createVideoMeeting = async (c) => {
        // console.log(contactId);
        // await this.props.setSessionsModal(false, null, null, contactId, null, null);
        // this.props.history.push("/meetings/create");
        console.log(c);
        this.scheduleMeeting(c.photo, c.first, c.last, c.consumerId)
    };

    createEmail = async (c) => {
        this.props.setMessagesEmailOpen(true, c.coachId, "expert", c.first, c.last, false, null, null);
    };

    createSMS = async (c) => {
        this.props.setMessagesSMSOpen(true, c.coachId, "expert", c.first, c.last, null, null);
    };

    scheduleMeeting = async (photo, firstName, lastName, userId) => {
        await this.setState({"photo": photo, "firstName": firstName, "lastName": lastName, "userId": userId});
        this.changeShowScheduler(true);
    };

    changeShowScheduler = async (status) => {
        this.setState({"showScheduler": status});
    };

    render() {
        if(this.state.isLoading) {
            return (<LogoLoading />)
        } else {
            return(
                <>
                    {/*<br />*/}
                    {/*<FontTitle18>{"Team Members ("+this.state.allCoaches.size+")"}</FontTitle18>*/}
                    {/*<br />*/}
                    {this.state.allCoaches.map((c, index) => (
                        <ActionBox key={index} hide={c.isWillowSupport}>
                            <Top>
                                <Photo src={c.photo} alt={""} />
                                <TitleSection>
                                    <FontHeader21>{c.first+" "+c.last}</FontHeader21>
                                    <Category>
                                        <FontBody16>{env.ENTERPRISE.EXPERT.PROFILE.PRINT_EXPERT_CATEGORIES(c.expertCategories, true)}</FontBody16>
                                    </Category>
                                    <div>
                                        <Left>
                                            <IntroButton>
                                                <div onClick={/*() => {return this.selectCoach(c)}*/() => {return this.props.setCoachModalStatus(true, c)}}>
                                                    <ButtonPrimary label={"Assign Clients"} canSubmit={false} />
                                                </div>
                                            </IntroButton>
                                            <ActionSection>
                                                <ActionItem disabled={false} onClick={() => {return this.createVideoMeeting(c)}} extraWidth={true}>
                                                    <ActionIcon disabled={false}><CalendarFilled /></ActionIcon>
                                                    <FontBody12>Meeting</FontBody12>
                                                </ActionItem>
                                                <ActionItem disabled={false} onClick={() => {return this.createEmail(c)}} extraWidth={false}>
                                                    <ActionIcon disabled={false}><MailFilled /></ActionIcon>
                                                    <FontBody12>Email</FontBody12>
                                                </ActionItem>
                                                <ActionItem disabled={false} onClick={() => {return this.createSMS(c)}} extraWidth={false}>
                                                    <ActionIcon disabled={false}><MobileFilled /></ActionIcon>
                                                    <FontBody12>SMS</FontBody12>
                                                </ActionItem>
                                                <ActionItem disabled={false} onClick={() => {return this.selectCoach(c)}} extraWidth={false}>
                                                    <ActionIcon disabled={false}><More><DashOutlined /></More></ActionIcon>
                                                    <FontBody12>More</FontBody12>
                                                </ActionItem>
                                            </ActionSection>
                                        </Left>
                                        {/*<Right>*/}
                                        {/*    <ExpandSection >*/}
                                        {/*        <FontHeader21><RightOutlined /></FontHeader21>*/}
                                        {/*    </ExpandSection>*/}
                                        {/*</Right>*/}
                                    </div>
                                </TitleSection>
                            </Top>
                        </ActionBox>
                    ))}
                    {this.state.showScheduler &&
                        <>
                            <CommonBackground/>
                            <CommonModal>
                                <SchedulerFlow
                                    userId={this.state.userId}
                                    userFirstName={this.state.firstName}
                                    userLastName={this.state.lastName}
                                    userPhotoUrl={this.state.photo}
                                    eventCustomMinutes={30}
                                    availabilityCloseBtn={true}
                                    availabilityOnClose={() => {return this.changeShowScheduler(false)}}
                                    confirmOnClose={() => {return this.changeShowScheduler(false)}}
                                />
                            </CommonModal>
                        </>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    allCoaches: state.enterprise.team.coaching.get("coachesAssignedToWMC")
});

const mapDispatchToProps = dispatch => ({
    setSessionsModal: (status, session, action, who, details, guests) => dispatch(setSessionsModal(status, session, action, who, details, guests)),
    setMessagesEmailOpen: (open, recipientId, recipientType, recipientFirstName, recipientLastName, isReply, subject, content) => dispatch(setMessagesEmailOpen(open, recipientId, recipientType, recipientFirstName, recipientLastName, isReply, subject, content)),
    setMessagesSMSOpen: (open, recipientId, recipientType, recipientFirstName, recipientLastName, subject, content) => dispatch(setMessagesSMSOpen(open, recipientId, recipientType, recipientFirstName, recipientLastName, subject, content)),
    setSelectCoach: (oneCoach) => dispatch(setSelectCoach(oneCoach)),
    setCoachModalStatus: (status, coach) => dispatch(setCoachModalStatus(status, coach)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TeamList));
