import {axiosReq} from "../../../../common/utils/axios";

/* Data */
import {devShopExpertProfileUpdateData, devShopExpertProfileData} from "../../../profile/static/devdata";
import {devSettingsPersonalInfoData} from "../../../static/devdata";
import {devDataCDFANumber, devDataCFPNumber, devDataIWINumber} from "../static/devdata";
import {devSettingsPersonalInfoUpdateData} from "../../../../consumer/settings/static/devdata";

/* Utils */
import {testPhoto} from '../utils/images';

export const tryGetSettingsPersonalInfo = () => {
    return async dispatch => {
        return axiosReq('apiv1/settings/general', "GET", {}, devSettingsPersonalInfoData);
    }
};

export const tryUpdateSettingsPersonalInfo = (data) => {
    return async dispatch => {
        const personalInfo = await axiosReq('apiv1/settings/general', "PUT", {companyName: data.companyName, first: data.first, last: data.last, phone: data.phone, email: data.email, timeZone: data.timeZone, smsNotificationConsent: data.smsNotificationConsent}, devSettingsPersonalInfoUpdateData(data));
        if(personalInfo.hasOwnProperty("error")) {
            return({"error": personalInfo.error});
        } else {
            return ({"error": null});
        }
    }
};

export const tryGetExpertProfile = () => {
    return async dispatch => {
        return axiosReq('apiv1/coach/settings/profile', "GET", {}, devShopExpertProfileData);
    }
};

export const tryUpdateExpertProfile = (data) => {
    return async dispatch => {
        let photo = await testPhoto(data.photoBlob);
        console.log(photo);
        let request = photo === null ? data : {...data, profile: photo};
        const profile = await axiosReq('apiv1/coach/settings/profile', "PUT", request, devShopExpertProfileUpdateData(data));
        if(profile.hasOwnProperty("error")) {
            return({error: profile.error});
        } else {
           return({error: null});
        }
    }
};

export const tryGetAdvisorCFPNumber = () => {
    return async dispatch => {
        return axiosReq('apiv1/coach/settings/cfp-number', "GET", {}, devDataCFPNumber);
    }
};

export const tryGetAdvisorIWINumber = () => {
    return async dispatch => {
        return axiosReq('apiv1/coach/settings/iwi-number', "GET", {}, devDataIWINumber);
    }
};

export const tryGetAdvisorCDFANumber = () => {
    return async dispatch => {
        return axiosReq('apiv1/coach/settings/cdfa-number', "GET", {}, devDataCDFANumber);
    }
};

export const tryUpdateAdvisorCFPNumber = (data) => {
    return async dispatch => {
        return axiosReq('apiv1/coach/settings/cfp-number', "PUT", data, devDataCFPNumber);
    }
};

export const tryUpdateAdvisorIWINumber = (data) => {
    return async dispatch => {
        return axiosReq('apiv1/coach/settings/iwi-number', "PUT", data, devDataIWINumber);
    }
};

export const tryUpdateAdvisorCDFANumber = (data) => {
    return async dispatch => {
        return axiosReq('apiv1/coach/settings/cdfa-number', "PUT", data, devDataCDFANumber);
    }
};