import {axiosReq} from "../../../utils/axios";

/* Store */
import { setNotRatedClients, setRatingDownload, setRateError } from "../store/rating";
import {tryCheckWMCByIdWithReturn} from '../../company/middleware/wmc';

/* Static */
import {devCoachRatingClientNotice, devClientRatingCoachNotice} from '../static/devdata';

export const tryGetNotRatedClients = () => {
    return async dispatch => {
        dispatch(setRatingDownload(true));
        const rating = await axiosReq('apiv1/coach/clients/ratings/purchase/notrated', "GET", {}, devCoachRatingClientNotice);
        if(rating.hasOwnProperty("error")) {
            dispatch(setRateError())
        } else {
            dispatch(setNotRatedClients(rating.unrated));
        }
        return dispatch(setRatingDownload(false));
    }
};

export const tryGetNotRatedCoach = () => {
    return async (dispatch, getState) => {
        if(!getState().common.user.get("isLily")) {
            return null;
        }
        dispatch(setRatingDownload(true));
        const rating = await axiosReq('apiv1/consumer/ratings/purchase/notrated', "GET", {}, devClientRatingCoachNotice);
        if(rating.hasOwnProperty("error")) {
            dispatch(setRateError())
        } else {
            if(rating.coachId !== undefined) {
                // Test if WMC advisor or coach, suppress if advisor
                const isWMC = await dispatch(tryCheckWMCByIdWithReturn(rating.coachId));
                if(!isWMC) {
                    dispatch(setNotRatedClients([rating]));
                }
            }
        }
        return dispatch(setRatingDownload(false));
    }
};