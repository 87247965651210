import React from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import {Link, withRouter} from 'react-router-dom';

/* Components */
import {DropdownInput, TextInput, PhoneInput} from "../../../common/components/inputs";
import {Width, SaveSection, SubmitInputWithValidation, InlineBlock, Card} from '../../components/clientscreate';
import {FontTitle18} from '../../../common/components/fonts';
import {ButtonTertiary} from '../../../common/components/buttons';

/* Middleware */
import {tryCreateClient} from '../middleware/clients';
import {tryGetProspectStages} from '../../middleware/prospectsstages';

/* Utils */
import {timeZoneOptions} from '../../../common/utils/timezone2';

class ClientsCreate extends React.Component {
    state = {
        first: '', firstValid: true,
        last: '', lastValid: true,
        phone: '', phoneValid: true,
        email: '', emailValid: true,
        companyName: '',
        timeZone: '',
        isSaving: false,
        isSaved: false,
        error: '',
        isChanged: false,
        isValid: true,
        stageId: '',
        stageTitle: 'client',
    };

    componentDidMount() {
        return this.init()
    }

    init = async () => {
        await this.props.tryGetProspectStages();
        this.setState({
            "stageId": this.props.stages.filter(s => {return s.get("title") === "client"}).get(0).get("id"),
            "timeZone": this.props.timeZone
        });
    };

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    handleChangeFirst = async e => {
        this.setState({'first': e.target.value});
        await this.setState({'firstValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeLast = async e => {
        this.setState({'last': e.target.value});
        await this.setState({'lastValid': (!validator.isEmpty(e.target.value) && e.target.value !== '')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeEmail = async e => {
        this.setState({'email': e.target.value});
        await this.setState({'emailValid': validator.isEmail(e.target.value)});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangePhone = async e => {
        this.setState({'phone': e.target.value.replace('(', '').replace(')', '').replace(' ', '').replace('-', '')});
        await this.setState({'phoneValid': validator.isMobilePhone(e.target.value, 'en-US')});
        this.setState({'isChanged': true});
        this.checkValid();
    };
    handleChangeTimeZone = e => {
        this.setState({'timeZone': e.value});
        this.setState({'isChanged': true});
    };
    handleChangeCompanyName = async e => {
        this.setState({
            'companyName': e.target.value,
            'isChanged': true
        });
        this.checkValid();
    };

    checkValid = () => {
        const isFirstValid = (!validator.isEmpty(this.state.first) && this.state.first !== '');
        const isLastValid = (!validator.isEmpty(this.state.last) && this.state.last !== '');
        const isEmailValid = validator.isEmail(this.state.email);
        const isPhoneValid = validator.isMobilePhone(this.state.phone, 'en-US') || validator.isEmpty(this.state.phone) || this.state.phone === '';
        const timeZoneValid = (!validator.isEmpty(this.state.timeZone) && this.state.timeZone !== '');
        if(isFirstValid && isLastValid && isEmailValid && isPhoneValid && timeZoneValid) {
            this.setState({'isValid': true})
        } else {
            this.setState({'isValid': false})
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        if(this.state.firstValid && this.state.lastValid && this.state.phoneValid && this.state.emailValid) {
            this.setState({'isSaving': true, 'error': ''});
            await this.props.tryCreateClient(this.state);
            if(this.props.clientsError !== null) {
                this.setState({'error': this.props.clientsError, 'isSaving': false, 'isSaved': true, isChanged: false});
                this.timeout = setTimeout(() => {this.setState({'error': null, 'isSaving': false, 'isSaved': false, isChanged: true})}, 3000);
            } else {
                this.setState({'isSaving': false, 'isSaved': true, isChanged: false});
                this.timeout = setTimeout(() => {this.props.history.push("/clients")}, 3000);
            }
        }
    };

    render() {
        return(
            <form method={"post"} onSubmit={this.handleSubmit}>
                {this.state.isSaved
                    ?
                        <>
                            {this.state.error === '' && <FontTitle18>Saved!</FontTitle18>}
                            {this.state.error !== '' && <FontTitle18>{this.state.error}</FontTitle18>}
                        </>
                    :
                        <>
                            <Width>
                                <FontTitle18>About</FontTitle18>
                                <TextInput
                                    title={"First Name*"}
                                    valid={this.state.firstValid}
                                    warning={"Required"}
                                    id={"first"}
                                    onChange={this.handleChangeFirst}
                                    placeholder={"First Name"}
                                    value={this.state.first}
                                />
                                <TextInput
                                    title={"Last Name*"}
                                    valid={this.state.lastValid}
                                    warning={"Required"}
                                    id={"last"}
                                    onChange={this.handleChangeLast}
                                    placeholder={"Last Name"}
                                    value={this.state.last}
                                />
                                <TextInput
                                    title={"Company Name"}
                                    valid={true}
                                    warning={""}
                                    id={"companyName"}
                                    onChange={this.handleChangeCompanyName}
                                    placeholder={"Company Name"}
                                    value={this.state.companyName}
                                />
                                <PhoneInput
                                    title={"Mobile phone"}
                                    valid={this.state.phoneValid}
                                    warning={""}
                                    id={"phone"}
                                    onChange={this.handleChangePhone}
                                    placeholder={'(###) ###-####'}
                                    value={this.state.phone}
                                    options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}}
                                />
                                <TextInput
                                    title={"Email*"}
                                    valid={this.state.emailValid}
                                    warning={"Enter a valid email address"}
                                    id={"email"}
                                    onChange={this.handleChangeEmail}
                                    placeholder={"Email"}
                                    value={this.state.email}
                                />
                                <DropdownInput
                                    title={"Time zone*"}
                                    options={timeZoneOptions}
                                    id={'timeZone'}
                                    onChange={this.handleChangeTimeZone}
                                    placeholder={'Time Zone'}
                                    value={this.state.timeZone}
                                />
                            </Width>
                            <SaveSection>
                                <InlineBlock><SubmitInputWithValidation label={"Save"} isChanged={this.state.isChanged} isValid={this.state.isValid} isSaving={this.state.isSaving} /></InlineBlock>
                                <InlineBlock><Link to={"/clients"}><ButtonTertiary canSubmit={false} label={"Cancel"} /></Link></InlineBlock>
                            </SaveSection>
                        </>
                }
            </form>
        )
    }
}
const mapStateToProps = state => ({
    clientsError: state.enterprise.clientsList.get("clientsError"),
    stages: state.enterprise.prospects.get("prospectStages"),
    timeZone: state.common.user.get("timeZone")
});
const mapDispatchToProps = dispatch => ({
    tryCreateClient: (data) => dispatch(tryCreateClient(data)),
    tryGetProspectStages: () => dispatch(tryGetProspectStages()),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientsCreate));
