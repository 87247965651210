import React from 'react';
import {withRouter} from 'react-router-dom';

/* Containers */
import ContactsList from '../containers/contactslist';
import ContactsCreate from '../containers/contactscreate';
import ContactsEdit from '../containers/contactsedit';

/* Navs */
import {PageContent, PageFull, PageRight} from "../navbars/components/navlayout";
import NavPrimaryAll from "../navbars/containers/primaryall";
import NavSecondaryTop from '../navbars/containers/secondarytop';

/* Components */
import {PlusOutlined} from "@ant-design/icons";

const contactButtons = [
    {
        "pathname": "/contacts/create",
        "label": <><PlusOutlined /> Create</>,
        "buttonType": "primary"
    }
];

const contactTabs = [
    {
        title: "Current Contacts",
        label: "Current",
        href: "/contacts",
        alwaysActiveForSubRoutes: false
    },
    {
        title: "Previous Contacts",
        label: "Previous",
        href: "/contacts/previous",
        alwaysActiveForSubRoutes: false
    }
];

const Clients = ({location}) => (
    <>
        <NavPrimaryAll />
        <PageFull>

            <PageRight>

                {(location.pathname.includes("/contacts") && location.pathname !== '/contacts/create' && location.pathname !== "/contacts/edit") &&
                    <>
                        <NavSecondaryTop title={"Contacts"} buttons={contactButtons} tabs={contactTabs} desc={"View your Willow contacts"}/>
                        <PageContent>
                            <ContactsList />
                        </PageContent>
                    </>
                }

                {location.pathname === "/contacts/create" &&
                    <>
                        <NavSecondaryTop title={"Create Contact"}/>
                        <PageContent>
                            <ContactsCreate />
                        </PageContent>
                    </>
                }

                {location.pathname === "/contact/edit" &&
                    <>
                        <NavSecondaryTop title={"Edit Contact"}/>
                        <PageContent>
                            <ContactsEdit />
                        </PageContent>
                    </>
                }

            </PageRight>
        </PageFull>
    </>
);
export default withRouter(Clients);
