import {v4 as uuid4} from "uuid";

const consumerSignUpForm = [
    {
        "pageNum": 0,
        "header": {
            "title": "Help your financial coach prepare",
        },
        "actions": {
            "pageNumVisible": true,
            "backLabel": "",
            "nextLabel": "Continue",
            "canContinueAutomatically": false,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "checkBox",
                "subtitle": "What challenges are you facing?",
                "disclosure": "(check all that apply)",
                "warning": "Required",
                "maxCheckable": null,
                "isValid": false,
                "isRequired": true,
                "options": [
                    {"id": 1, "active": false, "label": "I'm navigating a divorce"},
                    {"id": 2, "active": false, "label": "I'm divorced"},
                    {"id": 3, "active": false, "label": "I'm a widow"},
                    {"id": 4, "active": false, "label": "I'm a caregiver for aging loved ones"},
                    {"id": 5, "active": false, "label": "I'm in charge of my family finances"},
                    {"id": 6, "active": false, "label": "I'm a single parent"},
                    {"id": 7, "active": false, "label": "I'm navigating my career"},
                    {"id": 8, "active": false, "label": "I'm starting out financially"},
                    {"id": 9, "active": false, "label": "I'm planning for retirement"},
                    {"id": 10, "active": false, "label": "I'm saving for other goals"},
                    {"id": 11, "active": false, "label": "I'm looking to grow my wealth"}
                ],
                "style": {
                    "subtitle": {},
                    "disclosure": {},
                    "warning": {},
                    "options": {},
                    "optionLabel": {},
                    "optionIcon": {}
                }
            }
        ]
    },
    {
        "pageNum": 1,
        "header": {
            "title": "Help your financial coach prepare",
        },
        "actions": {
            "pageNumVisible": true,
            "backLabel": "Back",
            "nextLabel": "Continue",
            "canContinueAutomatically": false,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "checkBox",
                "subtitle": "What are your coaching goals?",
                "disclosure": "(check all that apply)",
                "warning": "Required",
                "maxCheckable": null,
                "isValid": false,
                "isRequired": true,
                "options": [
                    {"id": 1, "active": false, "label": "Organize my finances"},
                    {"id": 2, "active": false, "label": "Budgeting and paying bills"},
                    {"id": 3, "active": false, "label": "Improve my credit score"},
                    {"id": 4, "active": false, "label": "Reduce debt"},
                    {"id": 5, "active": false, "label": "Easing financial stress and anxiety"},
                    {"id": 6, "active": false, "label": "Planning for after I'm gone"},
                    {"id": 7, "active": false, "label": "Workplace benefits and insurance"},
                    {"id": 8, "active": false, "label": "Saving for education and future expenses"},
                    {"id": 9, "active": false, "label": "Improving or changing my career"},
                    {"id": 10, "active": false, "label": "Considering a real estate purchase or rental"},
                    {"id": 11, "active": false, "label": "Changing finances in a relationship"}
                ],
                "style": {
                    "subtitle": {},
                    "disclosure": {},
                    "warning": {},
                    "options": {},
                    "optionLabel": {},
                    "optionIcon": {}
                }
            }
        ]
    },
    {
        "pageNum": 2,
        "header": {
            "title": "Help your financial coach prepare",
        },
        "actions": {
            "pageNumVisible": true,
            "backLabel": "Back",
            "nextLabel": "Continue",
            "canContinueAutomatically": false,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "textArea",
                "isValid": false,
                "isRequired": true,
                "subtitle": "Share your questions",
                "placeholder": "Tell your financial coach what they can help you with",
                "text": "",
                "minLength": 0,
                "minRows": 3,
                "style": {
                    "subtitle": {},
                    "textArea": {},
                    "warning": {},
                }
            }
        ]
    },
    {
        "pageNum": 3,
        "header": {
            "title": "Help your financial coach prepare",
        },
        "actions": {
            "pageNumVisible": true,
            "backLabel": "Back",
            "nextLabel": "Continue",
            "canContinueAutomatically": true,
        },
        "elements": [
            {
                "id": uuid4(),
                "type": "specialCoaching",
                "subtitle": "Our goal is to bring you the best match with a coach. Do any of these profiles resonate with you?",
                "disclosure": "(optional, choose any)",
                "isRequired": false,
                "options": [
                    {
                        "id": 1,
                        "active": false,
                        "icon": "coachstyle_military",
                        "title": "Understands the life of a military family",
                        "body": "They know the nuances between personal finance for civilians and those who are on active duty or have military backgrounds."
                    },
                    {
                        "id": 2,
                        "active": false,
                        "icon": "coachstyle_nononsense",
                        "title": "Has a no-nonsense approach",
                        "body": "They're not afraid of straight talk and will deliver their message with tough love if needed."
                    },
                    {
                        "id": 3,
                        "active": false,
                        "icon": "coachstyle_spiritual",
                        "title": "Integrates spirituality into their coaching",
                        "body": "They are comfortable talking about matters of faith and the intertwines with money"
                    },
                    {
                        "id": 4,
                        "active": false,
                        "icon": "coachstyle_emotional",
                        "title": "Emphasizes the emotional aspects of personal finance",
                        "body": "They'll help me reflect on my feelings about money and can understand that there is a lot beneath the surface when it comes to my history with money."
                    },
                    {
                        "id": 5,
                        "active": false,
                        "icon": "coachstyle_underserved",
                        "title": "Knows what it's like to be part of a minority community",
                        "body": "They understand not all money experiences are the same and they’re ready to help me forge my path forward."
                    }
                ],
                "style": {
                    "subtitle": {},
                    "disclosure": {},
                    "options": {},
                    "optionIcon": {},
                    "optionTitle": {},
                    "optionBody": {}
                }
            }
        ]
    }
];

export default consumerSignUpForm;