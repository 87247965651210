import styled from "styled-components";
import {colors} from "../../../common/components/colors";

export const Title = styled.div`
    max-width: 1320px;
    width: calc(100% - 120px);
    padding: 0px 60px;
    margin: 60px auto 0px auto;
    color: ${colors.primary100};
    @media screen and (max-width: 1440px) {
        max-width: 1008px;
        width: calc(100% - 80px);
        padding: 0px 40px;
    }
    @media screen and (max-width: 768px) {
        width: calc(100% - 40px);
        padding: 0px 20px;
    }
    @media screen and (max-width: 460px) {
        margin: 20px auto 0px auto;
        width: calc(100% - 20px);
        padding: 0px 10px;
    }
`;
export const Container = styled.div`
    max-width: 1320px;
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    width: calc(100% - 120px);
    padding: 10px 60px;
    margin: 40px auto 40px auto;
    text-align: center;
    color: ${colors.primary100};
    @media screen and (max-width: 1440px) {
        grid-template-columns: repeat(2, 1fr);
        max-width: 1008px;
        width: calc(100% - 80px);
        padding: 10px 40px;
    }
    @media screen and (max-width: 1088px) {
        grid-template-columns: repeat(1, 1fr);
        max-width: 768px;
        width: calc(100% - 60px);
        padding: 10px 30px;
    }
    @media screen and (max-width: 768px) {
        width: calc(100% - 40px);
        padding: 10px 20px;
    }
    @media screen and (max-width: 460px) {
        margin: 20px auto 0px auto;
        width: calc(100% - 20px);
        padding: 10px 10px;
    }
`;
export const Card = styled.div`
    vertical-align: top;
    padding: 20px 30px;
    border-radius: 6px;
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid ${colors.secondary70};
    background-color: ${colors.primary100};
    @media screen and (max-width: 768px) {
        padding: 10px 20px;
    }
    @media screen and (max-width: 460px) {
        padding: 10px;
    }
`;
export const Quote = styled.div`
    color: ${colors.secondary70};
    font-weight: 900;
    font-size: 70px;
    margin: 10px 0px -40px 10px;
    text-align: left;
`;
export const QuoteEnd = styled.div`
    color: ${colors.secondary70};
    font-weight: 900;
    font-size: 70px;
    margin: -50px 10px 0px auto;
    text-align: right;
`;
export const Body = styled.div`
    color: ${colors.white};
    & p {
        font-family: GraphicWebRegular;
        font-size: 18px;
        line-height: 27px;
    }
`;
export const Name = styled.div`
    margin-top: 40px;
    text-align: right;
    font-family: CooperLight;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 5px;
    margin-right: 5px;
`;
export const Gray = styled.div`
    color: ${colors.secondary70};
    font-size: 50px;
    display: inline-block;
    vertical-align: top;
    font-weight: 900;
    line-height: 27px;
`;
export const NameText = styled.div`
    display: inline-block;
    vertical-align: middle;
    color: ${colors.white};
`;

/*
‒
U+02012
&#x2012;
&#8210;
–
U+02013
&#x2013;
&#8211;
 */