import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import styled from "styled-components";

/* Components */
import {CoachingLoading, ActionBox, FitWidthButton} from '../components/teamsupport';
import {FontBody16, FontHeader21, FontTitle18} from "../../../common/components/fonts";
import {ButtonSecondary, ButtonLink} from "../../../common/components/buttons";
import {CalendarOutlined} from '@ant-design/icons';

/* Static */
import {colors} from "../../../common/components/colors";
import {images} from "../../../common/components/images";
import SchedulerFlow from "../../../iframes/scheduler/containers/flow";
import {CommonBackground, CommonModal} from "../../../common/components/modal";

const Center = styled.div`
    text-align: center;
`;
const FitContent = styled.div`
    width: fit-content;
    margin: -10px auto 0px auto;
`;
const Border = styled.div`
    margin: 20px 0px 30px 0px;
    height: 2px;
    background-color: ${colors.border100};
`;
const Category = styled.div`
    color: ${colors.primary100};
    margin-bottom: 16px;
`;

export const Photo = styled.img`
    display: inline-block;
    vertical-align: top;
    width: 150px;
    height: 150px;
    border-radius: 6px;
    object-fit: cover;
    object-position: 0 0;
`;
const Modal = styled.div`
    margin: 0px auto;
    width: 748px;
    padding: 30px;
    border-radius: 6px;
    background-color: ${colors.white};
    position: fixed;
    top: 100px;
    left: 0px;
    right: 0px;
    @media screen and (max-width: 800px) {
        width: 100%;
        padding: 0px;
    }
    z-index: 6;
`;
const Background = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.50);
    @media screen and (max-width: 800px) {
        background-color: ${colors.white};
    }
    z-index: 5;
`;

const HardCodedTeam = ({photo, firstName, lastName, title, userId, scheduleMeeting}) => (
    <>
        <Center><Photo src={photo} alt={""} /></Center>
        <br />
        <Center><FontHeader21>{firstName} {lastName}</FontHeader21></Center>
        <Center><Category><FontBody16>{title}</FontBody16></Category></Center>
        <FitContent onClick={() => {return scheduleMeeting(photo, firstName, lastName, userId)}}>
            <ButtonLink canSubmit={false} label={<><CalendarOutlined />&nbsp;Meeting</>} />
        </FitContent>
    </>
);

class SupportMembers extends React.Component {
    state = {
        "photo": null,
        "firstName": null,
        "lastName": null,
        "userId": null,
        "showScheduler": false,
        "isLoading": true,
    };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        this.setState({"isLoading": false})
    };

    scheduleMeeting = async (photo, firstName, lastName, userId) => {
        await this.setState({"photo": photo, "firstName": firstName, "lastName": lastName, "userId": userId});
        this.changeShowScheduler(true);
    };

    changeShowScheduler = async (status) => {
        this.setState({"showScheduler": status});
    };

    render() {
        if(this.state.isLoading) {
            return(<CoachingLoading />)
        } else {
            return(
                <>
                    <ActionBox>
                        <FontTitle18>Need Help?</FontTitle18>
                        <br />
                        <br />
                        <HardCodedTeam
                            photo={images.willowTeamLacyAvatar}
                            firstName={"Lacy"}
                            lastName={"Garcia"}
                            title={"Relationship Manager"}
                            userId={845}
                            scheduleMeeting={this.scheduleMeeting}
                        />
                        <Border />
                        {/*<HardCodedTeam*/}
                        {/*    photo={images.devDataTeamGabbyAvatar}*/}
                        {/*    firstName={"Gabby"}*/}
                        {/*    lastName={"Baker, CFP®"}*/}
                        {/*    title={"Coaching & Curriculum"}*/}
                        {/*    userId={1032}*/}
                        {/*    scheduleMeeting={this.scheduleMeeting}*/}
                        {/*/>*/}
                        {/*<Border />*/}
                        <FitWidthButton><Link to={"/support/faq"}><ButtonSecondary label={"Read FAQs"} canSubmit={false} /></Link></FitWidthButton>
                        <br />
                    </ActionBox>
                    {this.state.showScheduler &&
                        <>
                            <CommonBackground/>
                            <CommonModal>
                                <SchedulerFlow
                                    userId={this.state.userId}
                                    userFirstName={this.state.firstName}
                                    userLastName={this.state.lastName}
                                    userPhotoUrl={this.state.photo}
                                    eventCustomMinutes={30}
                                    availabilityCloseBtn={true}
                                    availabilityOnClose={() => {return this.changeShowScheduler(false)}}
                                    confirmOnClose={() => {return this.changeShowScheduler(false)}}
                                />
                            </CommonModal>
                        </>
                    }
                </>
            )
        }
    }
}

export default withRouter(SupportMembers);
