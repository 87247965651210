import React from "react";
import ReactQuill from "react-quill";
import {connect} from "react-redux";
import {v4 as uuid4} from "uuid";
import {withRouter} from 'react-router-dom';
import styled from "styled-components";

/* Components */
import {
    FontBody12,
    FontBody14,
    FontBody16,
    FontHeader14,
    FontHeader16,
    FontHeader18,
    FontTitle24
} from "../../../common/components/fonts";
import {
    CalendarFilled,
    FileFilled,
    ShoppingFilled,
    CloudDownloadOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    LinkOutlined,
    EyeInvisibleOutlined
} from "@ant-design/icons";
import {colors} from "../../../common/components/colors";
import {LinkStyled} from "../../../common/components/inputs";
import {Inset, Listing, Title, Body, Inline, Header, Description, DownloadLink, Footer, GrayLink, LeftTitle, NoPhoto, PrimaryPhoto, RightTitle, Type} from '../components/servicesone';
import {QuillSlimNoHeight} from "../../../common/components/richtexteditor";
import {ButtonPrimary, ButtonTertiary} from "../../../common/components/buttons";

/* Middleware */
import {tryGetListingsOne, tryUpdateListingStatus} from "../middleware/listings";

const Modal = styled.div`
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    padding: 30px;
    border-radius: 4px;
    background-color: ${colors.white};
    -webkit-box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.20);
    max-width: 300px;
    width: calc(100% - 80px);
    z-index: 20;
`;
const ConfirmTitle = styled.div`
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.border100};
    margin-bottom: 15px;
`;
const ConfirmActions = styled.div`
    margin-top: 20px;
`;
const ConfirmLeft = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
`;
const ConfirmRight = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
`;
const ConfirmBtnFitLeft = styled.div`
    width: fit-content;
`;
const ConfirmBtnFitRight = styled.div`
    width: fit-content;
    margin: 0px 0px 0px auto;
`;

class ServicesOne extends React.Component {
    state={"confirmModal": false};

    edit = async (listingId) => {
        await this.props.tryGetListingsOne(listingId);
        this.props.history.push("/products/edit")
    };

    delete = async (listingId) => {
        this.props.tryUpdateListingStatus(listingId, {"status": "deleted"});
    };

    render() {
        return(
            <Listing key={this.props.l.get("id")}>
                {/*<Header>*/}
                {/*    <FontHeader18>*/}
                {/*        {this.props.l.get("serviceType") === "online" &&*/}
                {/*            <>*/}
                {/*                {this.props.l.get("numberOfSessions") === 1*/}
                {/*                    ? <><CalendarFilled />&nbsp;&nbsp;Online Meeting</>*/}
                {/*                    : <><CalendarFilled />&nbsp;&nbsp;Online Meetings</>*/}
                {/*                }*/}
                {/*            </>*/}
                {/*        }*/}
                {/*        {this.props.l.get("serviceType") === "download" &&*/}
                {/*            <><FileFilled />&nbsp;&nbsp;File Download</>*/}
                {/*        }*/}
                {/*        {this.props.l.get("serviceType") === "offline" &&*/}
                {/*            <><ShoppingFilled />&nbsp;&nbsp;Offline Service</>*/}
                {/*        }*/}
                {/*    </FontHeader18>*/}
                {/*</Header>*/}
                <br />
                <Body>
                    {this.props.l.get("coverPhoto") !== null
                        ? <PrimaryPhoto src={this.props.l.get("coverPhoto")} alt={"Photo"}/>
                        : <NoPhoto><FontBody12>No Photo</FontBody12></NoPhoto>
                    }
                    <Description hasPhoto={true/*this.props.l.get("coverPhotoUrl") !== null*/}>
                        <Title><FontHeader18>{this.props.l.get("title")}</FontHeader18></Title>
                        <Type>
                            <FontBody16>
                                {this.props.l.get("serviceType") === "online" &&
                                    <>
                                        {this.props.l.get("numberOfSessions") === 1 &&
                                            <span>1&nbsp;meeting&nbsp;@&nbsp;{this.props.l.get("sessionLength")}-minutes</span>
                                        }
                                        {this.props.l.get("numberOfSessions") > 1 &&
                                            <span>{this.props.l.get("numberOfSessions")}&nbsp;meetings&nbsp;@&nbsp;{this.props.l.get("sessionLength")}-minutes&nbsp;each</span>
                                        }
                                    </>
                                }
                                {this.props.l.get("serviceType") === "download" &&
                                    <DownloadLink target={"_blank"} href={this.props.l.get("downloadUrl")}><CloudDownloadOutlined />&nbsp;Download</DownloadLink>
                                }
                                {this.props.l.get("serviceType") === "offline" &&
                                    <span>Details below</span>
                                }
                            </FontBody16>
                        </Type>
                        <QuillSlimNoHeight bodyBorderColor={colors.white}>
                            <ReactQuill
                                id={uuid4()}
                                readOnly={true}
                                theme={null}
                                value={this.props.l.get("description")}
                                bounds={'.app'}
                            />
                        </QuillSlimNoHeight>
                        <br />
                        <br />
                        {this.props.l.get("plans").size > 0 &&
                            <div>
                                <FontHeader14>Purchase options:</FontHeader14>
                                <Inset>
                                    {this.props.l.get("plans").map(p => {
                                        let centsAreZero = parseInt(p.get("amount").toString().slice(-2)) === 0;
                                        let amountAdj = (parseInt(p.get("amount"))/100).toFixed(centsAreZero ? 0 : 2);
                                        return (
                                            <li key={p.get("id")}>
                                                {(p.get("planType") === "one_time" && parseInt(p.get("amount")) === 0 && p.get("description") === "") &&
                                                    <FontBody14>Free</FontBody14>
                                                }
                                                {(p.get("planType") === "one_time" && parseInt(p.get("amount")) === 0 && p.get("description") !== "") &&
                                                    <FontBody14>{p.get("description")}</FontBody14>
                                                }
                                                {(p.get("planType") === "one_time" && parseInt(p.get("amount")) > 0) &&
                                                    <FontBody14>${amountAdj} upfront</FontBody14>
                                                }
                                                {(p.get("planType") === "recurring" && p.get("subscriptionDurationInMonths") !== null) &&
                                                    <FontBody14>${amountAdj}/{p.get("recurringInterval")}&nbsp;for&nbsp;{p.get("subscriptionDurationInMonths")}&nbsp;{p.get("subscriptionDurationInMonths") > 1 ? "months" : "month"}</FontBody14>
                                                }
                                                {(p.get("planType") === "recurring" && p.get("subscriptionDurationInMonths") === null) &&
                                                    <FontBody14>${amountAdj}/{p.get("recurringInterval")}</FontBody14>
                                                }
                                            </li>
                                        )
                                    })}
                                </Inset>
                            </div>
                        }
                        {(this.props.l.get("termsOfUseUrl") !== null && this.props.l.get("termsOfUseUrl") !== undefined && this.props.l.get("termsOfUseUrl") !== "" && this.props.l.get("privacyPolicyUrl") !== null && this.props.l.get("privacyPolicyUrl") !== undefined && this.props.l.get("privacyPolicyUrl") !== "") &&
                            <div>
                                <br />
                                {(this.props.l.get("termsOfUseUrl") !== null && this.props.l.get("termsOfUseUrl") !== undefined && this.props.l.get("termsOfUseUrl") !== "") &&
                                    <LinkStyled target={"_blank"} href={this.props.l.get("termsOfUseUrl")}><GrayLink>Terms of Service</GrayLink></LinkStyled>
                                }
                                {(this.props.l.get("termsOfUseUrl") !== null && this.props.l.get("termsOfUseUrl") !== undefined && this.props.l.get("termsOfUseUrl") !== "" && this.props.l.get("privacyPolicyUrl") !== null && this.props.l.get("privacyPolicyUrl") !== undefined && this.props.l.get("privacyPolicyUrl") !== "") &&
                                    <GrayLink>,&nbsp;</GrayLink>
                                }
                                {(this.props.l.get("privacyPolicyUrl") !== null && this.props.l.get("privacyPolicyUrl") !== undefined && this.props.l.get("privacyPolicyUrl") !== "") &&
                                    <LinkStyled href={this.props.l.get("privacyPolicyUrl")} target={"_blank"}><GrayLink>Privacy Policy</GrayLink></LinkStyled>
                                }
                            </div>
                        }
                    </Description>
                </Body>
                <Footer>
                    <LeftTitle>
                        <Inline onClick={() => {return this.edit(this.props.l.get("id"))}}>
                            <FontHeader16><EditOutlined />&nbsp;Edit</FontHeader16>
                        </Inline>
                        <Inline onClick={() => {this.setState({"confirmModal": true})}}>
                            <FontHeader16><DeleteOutlined />&nbsp;Delete</FontHeader16>
                        </Inline>
                        <Inline onClick={() => {return this.props.setLinkEmbedId(this.props.l.get("id"))}}>
                            <FontHeader16><LinkOutlined />&nbsp;Link or Embed</FontHeader16>
                        </Inline>
                    </LeftTitle>
                    <RightTitle>
                        {this.props.l.get("status").toLowerCase() === "active"
                            ? <FontHeader14><EyeOutlined />&nbsp;Public</FontHeader14>
                            : <FontHeader14><EyeInvisibleOutlined />&nbsp;Private</FontHeader14>
                        }
                    </RightTitle>
                </Footer>
                {this.state.confirmModal &&
                    <Modal>
                        <ConfirmTitle><FontTitle24>Are you sure?</FontTitle24></ConfirmTitle>
                        <FontBody16>Do you want to delete this product? This cannot be undone.</FontBody16>
                        <ConfirmActions>
                            <ConfirmLeft>
                                <ConfirmBtnFitLeft onClick={() => {this.setState({"confirmModal": false})}}><ButtonTertiary label={"Cancel"} canSubmit={true} /></ConfirmBtnFitLeft>
                            </ConfirmLeft>
                            <ConfirmRight>
                                <ConfirmBtnFitRight onClick={async () => {return this.delete(this.props.l.get("id"))}}><ButtonPrimary label={"Delete"} canSubmit={true} /></ConfirmBtnFitRight>
                            </ConfirmRight>
                        </ConfirmActions>
                    </Modal>
                }
            </Listing>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    tryGetListingsOne: (listingId) => dispatch(tryGetListingsOne(listingId)),
    tryUpdateListingStatus: (listingId, data) => dispatch(tryUpdateListingStatus(listingId, data))
});

export default connect(null, mapDispatchToProps)(withRouter(ServicesOne));
