import styled from "styled-components";
import {colors} from "../../../common/components/colors";

export const Container = styled.div`
    max-width: 768px;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 0px auto 0px auto;
`;

export const Listing = styled.div`
    cursor: pointer;
    background-color: ${colors.white};
    padding: 25px 15px 35px 15px;
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid ${colors.border100};
    color: ${colors.primary100};
    margin: 0px auto 20px auto;
    border-radius: 6px;
    &:hover {
        -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.45);
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.45);
    }
    @media screen and (max-width: 640px) {    
        padding: 15px 5px 25px 5px;
    }
`;
export const Title = styled.div`
    width: fit-content;
    @media screen and (max-width: 460px) {
        width: 100%;
    }
`;
export const Type = styled.div`
    margin-bottom: 15px;
`;
export const Body = styled.div`
    padding: 0px 20px;
    width: calc(100% - 40px);
    @media screen and (max-width: 640px) {
        width: calc(100% - 20px);
        padding: 0px 10px;
    }
    & p {
        cursor: pointer;
    }
`;
export const Image = styled.img`
    width: 150px;
    border-radius: 4px;
    height: 150px;
    object-fit: cover;
    object-position: 0 0;
`;
export const Header = styled.div`
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 3px solid ${colors.border70};
`;
export const Name = styled.div`
    padding-left: 10px;
    @media screen and (max-width: 460px) {
        padding-left: 0px;
    }
`;
export const Right = styled.div`
    display: inline-block;
    vertical-align: bottom;
    width: calc(100% - 170px);
    @media screen and (max-width: 460px) {
        width: 100%;
        display: block;
        text-align: center;
    }
`;
export const Left = styled.div`
    display: inline-block;
    vertical-align: bottom;
    width: 170px;
    @media screen and (max-width: 460px) {
        width: 100%;
        display: block;
        text-align: center;
    }
`;
export const SubName = styled.div`
    color: ${colors.secondary100};
    font-size: 24px;
`;

export const Spacing = styled.div`
    padding-bottom: 10px;
`;